import React from 'react';

import { I18n } from 'aws-amplify';

import './quotation-item-summary.css';

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';

import FormatCurrency from '../../ui/format-currency';

const ptBR = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2
});

class QuotationItemSummary extends React.Component {

    constructor(props) {

        super();


        this.state = {
            errors: {},
            task: {},
        };

        this.delItem = this.onDelItem.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;

        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);

            nextState.file = {};
        }

        this.setState(nextState);
    }

    onDelItem(item, e) {

        e.preventDefault();

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            quotationId: this.props.quotation.id,
            ...item
        };

        this.props.del_quotation_item(data);
    }

    getItemType(value) {

        return (this.props.bp.eventQuotationItemType && this.props.bp.eventQuotationItemType.find((item) => item.code == value)) || {};
    }

    getItemCategory(value) {

        return (this.props.bp.eventSegments && this.props.bp.eventSegments.find((item) => item.code == value)) || {};
    }

    render() {

        const { project = {} } = this.props;

        const quotation = this.props.quotation || {};
        const currency = this.props.currency || {};

        const canEdit = this.props.auth.is_partner && quotation.isOpen;

        const canEditSytle = canEdit ? {} : { display: 'none' };

        return (
            <section className="quotation-item-summary">

                <p className="title is-6">{I18n.get("Itens")}</p>

                <br />

                <div className="columns head">

                    <div className="column is-5">
                        {I18n.get("Item")}
                    </div>


                    <div className="column is-2">
                        {I18n.get("Quantidade")}
                    </div>


                    <div className="column is-2">
                        {I18n.get("Valor unit.")}
                    </div>


                    <div className={`column ${canEdit ? 'is-2' : 'is-3'}`}>
                        {I18n.get("Total")}
                    </div>


                    <div className="column is-1" style={canEditSytle}>

                    </div>

                </div>

                {quotation.items && quotation.items.map((item, index) => {

                    return (

                        <div className="columns">

                            <div className="column is-5">

                                <p style={{ fontSize: '110%' }}>
                                    {item.description}
                                </p>
                                <p style={{ fontSize: '80%' }}>
                                    {this.getItemType(item.itemType).label}

                                    {item.file && item.file.url && <a href={item.file.url} target="_blank" title={I18n.get("Baixar")} className="is-pulled-right" >

                                        <span className="is-pulled-right">
                                            {item.file.name} <i className="fa fa-download"></i>
                                        </span>

                                    </a>}
                                </p>

                            </div>


                            <div className="column is-2 has-text-right">
                                <p>
                                    {item.quantity}
                                </p>
                            </div>


                            <div className="column is-2 has-text-right">
                                <p style={{ fontSize: '110%' }}>
                                    <FormatCurrency amount={item.price} currency={currency} />
                                </p>
                                <p style={{ fontSize: '80%' }}>
                                    {item.doubleTaxation ? <span>{I18n.get("Item bi-tributado")}</span> : <span>&nbsp;</span>}
                                </p>
                            </div>


                            <div className={`column ${canEdit ? 'is-2' : 'is-3'} has-text-right`}>
                                <p style={{ fontSize: '110%' }}>
                                    <FormatCurrency amount={item.subtotal} currency={currency} />
                                </p>
                                <p style={{ fontSize: '80%' }}>
                                    &nbsp;
                                </p>
                            </div>


                            <div className="column is-1 has-text-centered" style={canEditSytle}>
                                <br />
                                <a onClick={(e) => this.delItem(item, e)} style={{ color: '#434343' }}>
                                    <i className="fa fa-trash"></i>
                                </a>
                            </div>

                        </div>
                    );

                })}

                {quotation.items && quotation.items.length == 0 && <>


                    <div className="columns">

                        <div className="column is-12" style={{ fontSize: '85%' }}>
                            {I18n.get("Nenhum item adicionado.")}
                        </div>

                    </div>

                </>}

                <div className="columns head">

                    <div className={`column ${canEdit ? 'is-2' : 'is-3'} is-offset-9 has-text-right`}>
                        <FormatCurrency amount={quotation.itemsTotal} currency={currency} />
                    </div>

                    <div className="column is-1" style={canEditSytle}>

                    </div>

                </div>

            </section>
        );
    }
}

export default QuotationItemSummary;
