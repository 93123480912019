import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import FillForm from '../page-templates/fillForm';

export default class WallPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            // ...
        };
    }

    render() {



        return (
            <FillForm>

                <div className="hero-body wall wall-reference">
                    <div className="container">

                        <div className="columns">

                            <div className="column is-6 wall-reference-art">

                                <div className="wall-reference-art-1 wall-reference-art-circles algorithm-1"></div>
                                <div className="wall-reference-art-2 wall-reference-art-circles algorithm-2"></div>
                                <div className="wall-reference-art-3 wall-reference-art-circles algorithm-1"></div>
                                <div className="wall-reference-art-4 wall-reference-art-circles algorithm-1"></div>
                                <div className="wall-reference-art-5 wall-reference-art-circles algorithm-2"></div>
                                <div className="wall-reference-art-6 wall-reference-art-circles algorithm-2"></div>
                                <div className="wall-reference-art-7 wall-reference-art-circles algorithm-1"></div>

                            </div>

                            <div className="column is-6 wall-reference-content">

                                <h1 className="subtitle is-4">
                                    {this.props.reference && this.props.reference.name}
                                </h1>
                                <p className="subtitle is-6">{I18n.get("Nós e nosso algoritmo agradecemos pelo seu tempinho dedicado para avaliar")} {this.props.reference &&  this.props.reference.partnerName}. =)</p>
                                <Link to='/' className="wall-reference-back-button">{I18n.get("HOME")}</Link>

                            </div>

                        </div>

                    </div>
                  </div>

                  </FillForm>
        );
    }
}

