import { BP_ASSETS_CLIENTS_GET } from './actions';

const api_reset = () => {
    return (dispatch, getState) => {

        dispatch({
            type: BP_ASSETS_CLIENTS_GET,
            payload: {
                
                clientAssets: {
                    assets: []
                },
                clientBundle: {
                    bundles: []
                }
            }
        })
    };
}

export default api_reset;