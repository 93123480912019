import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import MoneyField from '../../form/money-field';
import SelectField from '../../form/select-field';

import BudgetItemForm from './_bpitch-budget-item';

import { scrollToTop } from '../../../utils/ui';

import { CurrencySymbols } from '../../../model';

class BudgetForm extends React.Component {
    constructor(props) {

        super(props);

        const { bPitch = {} } = props;

        this.state = {
            fields: {
                currencyCode: {
                    onChange: this.onChangeForm.bind(this, 'currencyCode'),
                    // onBlur: this.onSave.bind(this),
                    successIcon: false,
                    markAsRequired: true

                },
                budget: {
                    onChange: this.onChangeForm.bind(this, 'budget'),
                    // onBlur: this.onSave.bind(this),
                    successIcon: false,
                    markAsRequired: true
                },
            },
            errors: {},
            form: {
                currencyCode: 'BRL', 
                ...bPitch 
            },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving() );
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.props.bPitch,
            ...this.state.form
        };

        return data;
    }

    onSaving(){

        clearTimeout(this.t);
        this.t = setTimeout(() => this.onSave(), 1000)
    }

    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_bpitch(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    onAddBudgetItem(item, e) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.budgetItems = [...nextState.form.budgetItems, item];
            nextState.errors.budgetItems = null;

            return nextState;
        }, () => {

            this.onSave();
        });

    }

    onDelBudgetItem(item, e) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.budgetItems = nextState.form.budgetItems.filter((v) => v !== item);
            nextState.errors.budgetItems = null;

            return nextState;
        }, () => {

            this.onSave();
        });

    }

    render() {


        const total = this.state.form.budgetItems && this.state.form.budgetItems.reduce((acc, item) => acc + parseFloat(item.perc), 0);

        const budgetSplitTotalError = this.state.errors['budgetSplitTotal'];

        const currencyValues = [
            { code: 'BRL', label: `${I18n.get('Real')} (R$)` },
            { code: 'USD', label: `${I18n.get('Dólar')} ($)` },
            { code: 'EUR', label: `${I18n.get('Euro')} (€)` },
            { code: 'COP', label: `${I18n.get('Pesos Colombianos')} ($)` },
            { code: 'MXN', label: `${I18n.get('Pesos Mexicanos')} ($)` },
        ];

        const currency = this.props.bp.currencies && this.props.bp.currencies.find(item => item.code == this.state.form.currencyCode)

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Budget")}</h1>

                <div className="bp-event-section" style={{ marginTop: '3rem', maxWidth: '40rem' }}>

                    <div className="columns">

                        <div className="column is-6">
                            <SelectField
                             {...this.state.fields.currencyCode} 
                             label={I18n.get("Moeda")}
                             value={this.state.form.currencyCode} 
                             error={this.state.errors.currencyCode} 
                             values={currencyValues} />
                        </div>

                        <div className="column is-6">
                            <MoneyField
                             {...this.state.fields.budget} 
                             label={I18n.get("Total")}
                             currency={currency} 
                             value={this.state.form.budget}
                              error={this.state.errors.budget} />
                        </div>

                    </div>


                    <br /><br /><br /><br />

                    <div className="columns">

                        <div className="column is-6">
                            <p style={{ fontWeight: 'bold' }}>{I18n.get("Budget Split")}</p>
                            <span style={{ fontSize: '80%', color: '#909090' }}>{I18n.get("Ex.: Mídia, Produção, Fees, etc")}</span>

                        </div>

                        <div className="column is-6">
                            <p>{total}%</p>
                            {budgetSplitTotalError && budgetSplitTotalError.errorMessage && <>
                                <p className="help is-danger" style={{ marginTop: '0' }}>
                                    {budgetSplitTotalError.errorMessage}
                                </p>
                            </>}
                        </div>
                    </div>

                    {this.state.form.budgetItems && this.state.form.budgetItems.map((item) => {

                        return (
                            <div className="columns" style={{ borderBottom: 'solid 1px #eeeeee' }}>

                                <div className="column is-6">

                                    <div className="field">
                                        <label className="label is-small">{I18n.get("Item22")}</label>
                                        <div className="control">
                                            {item.name}
                                        </div>
                                    </div>

                                </div>

                                <div className="column is-4">

                                    <div className="field">
                                        <label className="label is-small">{I18n.get("Percentual")}</label>
                                        <div className="control">
                                            {item.perc}%
                                        </div>
                                    </div>

                                </div>

                                <div className="column is-2 has-text-right">
                                    <div className="field is-narrow">
                                        <div className="control">
                                            <a onClick={this.onDelBudgetItem.bind(this, item)} className='button is-rounded is-black is-outlined'>
                                                <span className="icon is-small">
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        );
                    })}

                    <br /><br /><br />

                    <BudgetItemForm onAddBudgetItem={this.onAddBudgetItem.bind(this)} />

                    <br /><br />

                </div>
            </div>
        );
    }
}

export default BudgetForm;
