import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { usePartners } from '../../../../../hooks/usePartners/usePartners.hook';
import { useProjects } from '../../../../../hooks/useProjects/useProjects.hook';
import { useSessionStorage } from '../../../../../hooks/useSessionStorage/useSessionStorage.hook';
import {
  getPartners as getPartnersState,
  updateProjectStep,
} from '../../../../../store/actions/partners';
import { I18n } from 'aws-amplify';
import { Row, Col, Tooltip } from 'antd';
import LoggedPage from '../../../../../../components/page-templates/fillForm-logged';
import Content from '../../../../../components/UI/Content';
import { H2Border } from '../../../../../components/UI/Titles';
import Loading from '../../../../../components/UI/Loading';
import Main from '../../components/Main';
import { ButtonBpool } from '../../../../../components/UI/ButtonBpool';
import { ModalConfirm } from '../../../../../components/UI/ModalConfirm/ModalConfirm';
import IcoPlus from '../../../../../../svg/plus.svg';
import { StepsSelectPartner } from '../components/StepsSelectPartner';
import { Card } from '../components/Card';

import {
  KEY_PARTNERS_RATES,
  KEY_PARTNER_WINNER,
} from '../constants/keys.storage';

import * as S from './styles';

export const SelectPartnerStep3 = () => {
  let params = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  const { getPartners, updatePartner } = usePartners();
  const { getProjectReasons } = useProjects();
  const { get, remove } = useSessionStorage();

  const [projectId, setProjectId] = useState('');
  const [error, setError] = useState('');
  const [projectReasons, setProjectReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledFinalize, setDisabledFinalize] = useState(true);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);

  const { data: dataPartners } = useSelector((state) => state.partners);

  const loadPartners = async (partnerWinner) => {
    try {
      setIsLoading(true);
      const response = await getPartners({ id: projectId });
      const responseReasons = await getProjectReasons({ projectId });

      if (response?.success && responseReasons?.success) {
        const reasons = responseReasons?.data;
        const objReasonFinded = reasons?.find(
          (item) => item.partnerId === partnerWinner
        );
        const objReasonUpdated = { ...objReasonFinded, isWinner: true };
        const filteredItems = reasons?.filter(
          (item) => item.partnerId !== partnerWinner
        );
        const arrReasons = [...filteredItems, objReasonUpdated];

        dispatch(getPartnersState(response?.data));
        setProjectReasons(arrReasons);
      } else {
        dispatch(getPartnersState([]));
        setError(I18n.get('Erro parceiros. (101)'));
      }
      setIsLoading(false);
    } catch (error) {
      dispatch(getPartnersState([]));
      setError(I18n.get('Erro parceiros. (101)'));
    }
  };

  const emptyStorage = async () => {
    await remove({ key: KEY_PARTNER_WINNER });
    await remove({ key: KEY_PARTNERS_RATES });
  };

  const finalize = async () => {
    setIsFinalizing(true);
    const arrRatings = await get({ key: KEY_PARTNERS_RATES });

    const response = await updatePartner(arrRatings);

    if (response?.success) {
      setOpenModalConfirm(true);
      dispatch(updateProjectStep(3));
    } else {
      setError(I18n.get('Erro ao finalizar. (101)'));
    }
    setIsFinalizing(false);
  };

  const disableFinalize = async () => {
    const ratingStorage = await await get({ key: KEY_PARTNERS_RATES });

    if (ratingStorage && ratingStorage?.reasons2?.length >= 3) {
      setDisabledFinalize(false);
    }
  };

  const enableFinalize = () => {
    setDisabledFinalize(false);
  };

  const handleBack = async () => {
    const partnerWinner = await get({ key: KEY_PARTNER_WINNER });

    history.push(
      `/cliente/projetos/v2/selecionar-parceiro/${partnerWinner?.projectId}/passo-2/${partnerWinner?.partnerId}`
    );
  };

  const handleCloseModalConfirm = async () => {
    await emptyStorage();
    history.push(`/cliente/projetos/v2`);
  };

  const initial = async () => {
    const partnerWinner = await get({ key: KEY_PARTNER_WINNER });

    if (projectId) {
      loadPartners(partnerWinner?.partnerId);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    disableFinalize();

    initial();
  }, [projectId]);

  useEffect(() => {
    setProjectId(params.projectId);
  }, [params]);

  return (
    <>
      <LoggedPage>
        <Content>
          <div className="container">
            <Row>
              <Col sm={12}>
                <H2Border>{I18n.get('Projetos')}</H2Border>
              </Col>
            </Row>

            <Row>
              <Col sm={24}>

                <S.WrapperSteps>
                  <StepsSelectPartner active={3} projectId={params.projectId} disabledTabs={[1, 2]} />
                </S.WrapperSteps>
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <Main bgColor="#fff" padding="30px">
                  <Row>
                    <Col xs={24} sm={12} offset={6}>
                      <S.RowCenter>
                        <S.H4>{I18n.get('Justificativa')}</S.H4>
                        <p>
                          {I18n.get(
                            'Para nos ajudar a melhorar sempre e contar com os melhores parceiros, faça uma avaliação rápida. Nós realmente apreciamos isso!'
                          )}
                        </p>
                      </S.RowCenter>
                    </Col>
                  </Row>

                  <Row>
                    <S.WrapperCards>
                      {isLoading ? (
                        <div style={{ width: "100%" }}>
                          <Loading
                            text={I18n.get('Carregando...')}
                            sizeText={14}
                            sizeIcon={16}
                            align="center"
                          />
                        </div>
                      ) : (
                        dataPartners?.map((card) => (
                          <Card
                            key={card.id}
                            step={3}
                            dataCard={card}
                            projectId={params?.projectId}
                            reasons={projectReasons}
                            cbFinalize={enableFinalize}
                          />
                        ))
                      )}
                    </S.WrapperCards>
                  </Row>

                  <Row>
                    <Col lg={24}>
                      <S.FooterContent className="buttonsContainer">
                        {error ? (
                          <S.FooterError>{error}</S.FooterError>
                        ) : null}
                        <S.FooterButtons>
                          <ButtonBpool
                            text={I18n.get("Voltar")}
                            theme="secondary"
                            onClick={handleBack}
                          />
                          <ButtonBpool
                            text={I18n.get("Finalizar")}
                            theme="primary"
                            loading={isFinalizing}
                            onClick={finalize}
                            disabled={disabledFinalize}
                          />
                        </S.FooterButtons>
                      </S.FooterContent>
                    </Col>
                  </Row>
                </Main>
              </Col>
            </Row>
          </div>
        </Content>
      </LoggedPage>

      <ModalConfirm
        visible={openModalConfirm}
        bodyText={I18n.get("Parabéns pela escolha!")}
        subBodyText={I18n.get(
          "Estamos encaminhando o projeto para o partner selecionado e vocês já podem começar a detalhar o briefing!"
        )}
        okText={I18n.get("Ok")}
        handleClickOk={handleCloseModalConfirm}
      />
    </>
  );
};
