import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { CLIENT_API } from "../../actions/client/actions";

import LoggedPage from "../page-templates/logged";

import FormAddbrand from "../../modules/Register/components/formAddBrand";
import FormAddMsgGlobal from "../../modules/Register/components/formAddMsgGlobal";
import EditableFormTable from "../../modules/Register/components/tableBrandProduct";

import styled from "styled-components";

import Menu from "./_menu";

import BrandUsersForm from "./brands-users-form";

import { Icon, notification } from "antd";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBrand: (data) => {
      dispatch(CLIENT_API.create_brand_v2(data));
    },
    delBrand: (data) => {
      dispatch(CLIENT_API.delete_brand_v2(data));
    },
    updateBrand: (data) => {
      return dispatch(CLIENT_API.update_brand_v2(data));
    },
    updateMsg: (data) => {
      return dispatch(CLIENT_API.update_msg(data));
    },
    save: (data) => {
      dispatch(CLIENT_API.update_person(data));
    },
    addProject: (data) => {
      dispatch(CLIENT_API.update_product(data));
    },
    delProduct: (data) => {
      dispatch(CLIENT_API.delete_product(data));
    },
    get: (data) => {
      dispatch(CLIENT_API.get(data));
    },
    addUser: (data) => {
      dispatch(CLIENT_API.create_user(data));
    },
    delUser: (data) => {
      dispatch(CLIENT_API.delete_user(data));
    },
    reset_erros: () => {
      dispatch(CLIENT_API.reset_erros());
    },
    load_client: () => {
      return dispatch(CLIENT_API.get());
    },
  };
};

const Box = styled.div`
  background: #fff;
  padding: 20px;
`;

const LineBox = styled.div`
  border: solid 1px #ccc;
  padding: 0 20px 20px;
  margin-top: 30px;

  legend {
    position: relative;
    top: -15px;
    span {
      padding: 7px;
      background: #fff;
      border: solid 1px #ccc;
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
    }
  }
`;
class BrandsUsersAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      viewErros: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props?.client?.products &&
      prevProps?.client?.products &&
      this.props?.client?.products.length >
        prevProps?.client?.products.length &&
      this.state.viewErros
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("O produto foi adicionado ao seu cadastro"),
        duration: 3,
      });
    }
    if (
      this.props.client?.products &&
      prevProps?.client?.products &&
      this.props.client?.products.length < prevProps?.client?.products.length &&
      this.state.viewErros
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("O produto foi removido do seu cadastro"),
        duration: 3,
      });

      // this.props.close();
    }

    // if (this.props?.client?.products && prevProps?.client?.products && this.props?.client?.products != prevProps?.client?.products && this.state.viewErros) {

    //     notification.success({
    //         message: I18n.get("Atualizado com sucesso!"),
    //         description: I18n.get('O produto foi atualizado'),
    //         duration: 3,
    //     });
    // };
  }

  viewErrors = () => {
    this.setState({
      viewErros: true,
    });

    setTimeout(() => {
      this.props.reset_erros();
      this.setState({
        viewErros: false,
      });
    }, 7000);
  };

  handleUpdateMsg = (data) => { 
    this.props.updateMsg(data).then((res) => {
      if(res) {
        notification.success({
          message: I18n.get("Mensagem adicionada!"),
          description: I18n.get("A mensagem para produto global foi adicionada com sucesso!"),
          duration: 3,
        });
        this.props.load_client();
      } else {
        notification.error({
          message: I18n.get('Ops, algo deu errado!'),
          description: I18n.get("Tente novamente mais tarde"),
          duration: 3,
        });
      }
    });
  };

  
  render() {
    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/home">{I18n.get("Admin")}</Link>
            </li>
            <li className="is-active">
              <Link to="/cadastro">{I18n.get("Cadastro")}</Link>
            </li>
          </ul>
        </nav>

        <div className="columns brands-users-admin content-client">
          <div className="column is-3">
            <Menu {...this.props} active={3} />
          </div>

          <div className="column is-8">
            <h1 className="title">{I18n.get("Marcas e produtos")}</h1>

            <div className="form-mobile">
              {/* <BrandUsersForm {...this.props} isAdmin={true} /> */}

              <Box>
                <LineBox>
                  <legend> <span>{I18n.get("Adicionar marca e produto")}</span></legend>
                  <FormAddbrand
                    clientId={this.props.client?.clientId}
                    add={this.props.addBrand}
                    errors={this.props.client.errors && this.props.client.errors}
                    productsItens={
                      this.props.client && this.props.client.products
                    }
                    brands={this.props.client && this.props.client.brands}
                    sectorsFlat={
                      this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                    }
                    tc={this.props.client?.company?.tc}
                  />
                </LineBox>
                <br />
                <LineBox>
                    <legend> <span>{I18n.get("Mensagem para projetos globais")}</span></legend>
                    <FormAddMsgGlobal 
                      add={this.handleUpdateMsg}
                      msg={this.props.client?.customization?.produtoGlobalMessage}
                    />
                </LineBox>

                <br />
                <br />
                {this.state.viewErros &&
                  this.props.client?.errorsDelete &&
                  Object.keys(this.props.client?.errorsDelete).length > 0 && (
                    <div className="error-display">
                      <ul>
                        {Object.keys(this.props.client.errorsDelete).map(
                          (item, index) => {
                            return (
                              <li key={index}>
                                <Icon type="close-circle" theme="filled" />{" "}
                                {
                                  this.props.client.errorsDelete[item]
                                    .errorMessage
                                }
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                <EditableFormTable
                  brands={this.props.client && this.props.client.brands}
                  productsItens={
                    this.props.client && this.props.client.products
                  }
                  sectorsFlat={
                    this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                  }
                  delBrand={this.props.delBrand}
                  updateBrand={this.props.updateBrand}
                  clientId={this.props.client?.clientId}
                  viewErros={this.viewErrors}
                  activeMultipleMarket={
                    !this.props.client?.customization?.allowMultipleMarket
                  }
                />
              </Box>
            </div>
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsUsersAdmin);
