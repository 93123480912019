import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { PARTNER_API } from "../../actions/partner/actions";

import LoggedPage from "../page-templates/logged";

import Menu from "./_menu";

import FormCompany from "../../modules/Register/components/formCompany";

import { TitleBorder } from "../../modules/components/Titles";

import { Row, Col } from "antd";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (data) => {
      dispatch(PARTNER_API.update_company(data));
    },
    create_tag: (data) => {
      dispatch(PARTNER_API.create_tags(data));
    },
    new_company: (data) => {
      dispatch(PARTNER_API.new_company(data));
    },
  };
};
class CompanyAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  render() {
    let partnerManagerRanges = [];
    let partnerRevenueRanges = [];

    switch (this.props.partner.signupLocale) {
      case "es":
        partnerManagerRanges = this.props.bp.partnerManagerRanges_es;
        partnerRevenueRanges = this.props.bp.partnerRevenueRanges_es;
        break;

      case "en":
        partnerManagerRanges = this.props.bp.partnerManagerRanges_en;
        partnerRevenueRanges = this.props.bp.partnerRevenueRanges_en;
        break;

      default:
        partnerManagerRanges = this.props.bp.partnerManagerRanges;
        partnerRevenueRanges = this.props.bp.partnerRevenueRanges;
        break;
    }

    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/home">{I18n.get("Admin")}</Link>
            </li>
            <li className="is-active">
              <Link to="/cadastro">{I18n.get("Cadastro")}</Link>
            </li>
          </ul>
        </nav>

        <div className="columns partner-step company-admin">
          <div className="column is-3">
            <Menu {...this.props} />
          </div>

          <div className="column is-9">
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Cadastro - A Empresa")}</TitleBorder>
              </Col>
            </Row>

            <FormCompany
              partner={this.props.partner && this.props.partner}
              partnerId={this.props.partner && this.props.partner.partnerId}
              action={this.props.save}
              isNewCompany={this.props.new_company}
              partnerManagerRanges={this.props.bp && partnerManagerRanges}
              partnerRevenueRanges={this.props.bp && partnerRevenueRanges}
              partnerTeamSizeRanges={
                this.props.bp && this.props.bp.partnerTeamSizeRanges
              }
              createTag={this.props.create_tag}
              errors={
                this.props.partner &&
                this.props.partner.errors &&
                this.props.partner.errors
              }
              diversity={this.props.bp.diversity && this.props.bp.diversity}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
            />
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdmin);
