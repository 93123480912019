import React from "react";
import { I18n } from "aws-amplify";
import { Logged } from "../../../../components/Logged";
import { BoxContent } from "../../../../components/BoxContent";
import { TitlePage } from "../../../../components/TitlePage";
import { menuItems } from "../menu";
import * as S from "./styles";

export const ProfileAddress = () => {
  return (
    <Logged>
      <div className="container">
        <BoxContent
          menuTitle={I18n.get("SOBRE A EMPRESA")}
          menu={menuItems}
          active={1}
        >
          <TitlePage title={I18n.get("Endereço")} />
          <div>ProfileAddress</div>
        </BoxContent>
      </div>
    </Logged>
  );
};
