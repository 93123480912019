import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import MultUploadFile from '../../components/MultUploadFile';
import styled from 'styled-components';
import { TextCounter } from "./counterText";

import {
    Form,
    Button,
    Icon,
    Input,
    InputNumber,
    Checkbox,
    Row,
    Col,
    Tooltip,
    Select,
    Modal
} from 'antd';



const { TextArea, Search } = Input;
const { Option } = Select;

const FieldCustom = styled.div`

`

const BoxAddFild = styled(Search)`
    button {
        position:relative;

        i {
            display: none;
        }
    }
`;

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
      margin-right: 20px;
  }
`;

class FormCourse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            images: [],
            formState: [],
            listImg: []
        }
    }

    removeUrl = (url) => {
        const arr = this.state.videos.filter(e => e !== url);

        this.setState({
            videos: arr
        })
    }

    addUrl = () => {
        const valueInput = this.props.form.getFieldValue('videos');

        if (valueInput) {
            this.setState({
                videos: [
                    ...this.state.videos,
                    valueInput
                ]
            })
        }

        this.props.form.resetFields('videos');
    }

    getDataUploadMult = (files) => {
        this.setState({
            images: files
        });


        setTimeout(() => {
            // this.sendDataApi();
        }, 1);
    }

    componentWillMount = () => {
        this.props.form.resetFields();

        this.setState({
            listImg: []
        });
    }

    componentDidMount = () => {
        this.props.form.resetFields();

        this.setState({
            listImg: []
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    formState: values,
                })

                const data = {
                    ...values,
                    partnerId: this.props.partnerId,
                    images: this.state.images,
                    videos: this.state.videos
                }



                setTimeout(() => {
                    if (Object.keys(this.props.item).length == 0) {
                        this.props.action(data);
                    } else {
                        data.id = this.props.item.id;

                        this.props.update(data);
                    }
                }, 50);
            }
        });
    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        }

        if (this.props.item && this.props.item !== {} && this.props.item != prevProps.item) {

            this.props.form.resetFields();
            let listImg = this.props.item && this.props.item.images && this.props.item.images.map((item, index) => {
                return {
                    uid: index,
                    name: 'image.png',
                    status: 'done',
                    url: item
                }
            });

            this.setState({
                listImg: listImg,
                images: this.props.item.images || [],
                videos: this.props.item.videos || []
            });

            if (!this.props.item.images) {
                this.setState({
                    listImg: []
                });
            }
        }
    }


    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={12}>
                        <Form.Item
                            label={I18n.get("Nome")}>
                            {getFieldDecorator('name', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.name ? this.props.item.name : null
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={12}>
                        <Form.Item
                            label={I18n.get("Duração")}>
                            {getFieldDecorator('duration', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.duration ? this.props.item.duration : null
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={12}>
                        <Form.Item
                            label={I18n.get("Para quem é?")}>
                            {getFieldDecorator('targetPeople', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.targetPeople ? this.props.item.targetPeople : null
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={12}>
                        <Form.Item label={I18n.get("Formato")}>
                            {getFieldDecorator('format', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.format ? this.props.item.format : null
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={24}>
                        <Form.Item label={I18n.get("Descrição do curso ou treinamento")} className="textAreaCounter">
                            {getFieldDecorator('description', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.format || ""
                            })(
                                <TextArea rows={4} />
                            )}
                        </Form.Item>
                        <TextCounter
                            counter={getFieldValue('description') && getFieldValue('description').length || 0}
                            limit={250}
                            min={100}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form.Item label={I18n.get("Imagens")}>
                            {getFieldDecorator('images', {
                                rules: [{ required: false, message: 'ops!' }],
                            })(
                                <MultUploadFile
                                    getDataUpload={this.getDataUploadMult}
                                    name="images"
                                    uploadId={this.props.partnerId}
                                    actionUpload={this.props.upload}
                                    fileListGet={this.state.listImg || []}
                                    isEdit={true}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={12}>

                        <Form.Item label={I18n.get("Vídeos")}>
                            {getFieldDecorator('videos', {
                                rules: [{ required: false, message: 'ops!' }],
                            })(
                                <BoxAddFild
                                    className="InputPlus"
                                    placeholder=""
                                    onSearch={() => this.addUrl()}
                                    enterButton
                                />
                            )}
                            <p className="helptext">{I18n.get("(Youtube, Vimeo ou Dropbox)")}</p>
                        </Form.Item>

                        <ul className="ListDinamic">
                            {this.state.videos &&
                                this.state.videos.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={item} target="_blank">
                                                <Tooltip title={item}><Icon type="video-camera" /> <span className="txt">{item}</span></Tooltip>
                                            </a>
                                            <Icon type="delete" onClick={() => this.removeUrl(item)} theme="filled" className="iconList" />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>

                <RowSubmitModal>
                    <Col sm={24}>

                        <Button shape="round" onClick={() => this.props.cancel()}>{I18n.get("Cancelar")}</Button>

                        <Button type="primary" shape="round" htmlType="submit">
                            {Object.keys(this.props.item).length == 0 ? "Adicionar" : "Atualizar"}
                        </Button>
                    </Col>
                </RowSubmitModal>

            </Form>
        );
    }
}

const MainExpertiseForm = Form.create({})(FormCourse);

export default MainExpertiseForm;
