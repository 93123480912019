import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperRegister";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";

import { clientStatus } from "../../../model";

import { CLIENT_API } from "../../../actions/client/actions";

import FormCompanyClient from "../components/formCompanyClient";

import { TitleBorder, TitleMiddleLine } from "../../components/Titles";

import styled from "styled-components";

import { Row, Col, Input, Select, Button, Form } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: 60px;
  }
`;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (data) => {
      dispatch(CLIENT_API.update_company(data));
    },
    get: (data) => {
      dispatch(CLIENT_API.get(data));
    },
  };
};

class FullCompanyClient extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.props.get(this.props.match.params.clientId);
  };
  render() {
    const { client } = this.props;

    // if (this.props.client && this.props.client.status == clientStatus.Approved) {
    //     return (<Redirect to="/parceiro/obrigado" />);
    // }

    //TODO: Find better way... GAMBI!
    let menu = [...constants.MENU_REGISTER_CLIENT];
    for (let key in menu) {
      menu[key].label = I18n.get(menu[key].label);
    }

    console.log("AQUI");

    return (
      <AppWrapperSimple>
        <SubHeader
          titlePage=""
          itensMenu={menu}
          urlId={this.props.match.params.clientId}
          activeItem={2}
        />
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Cadastro - A Empresa")}</TitleBorder>
                <p className="descriptionPage">
                  {I18n.get(
                    "Queremos saber mais sobre sua empresa para nosso algoritimo buscar o melhor parceiro. Não se preocupe. Essas informações ficam só aqui, são totalmente confidenciais."
                  )}
                </p>
              </Col>
            </Row>

            <FormCompanyClient
              client={this.props.client && this.props.client}
              clientId={this.props.match.params.clientId}
              action={this.props.save}
              errors={this.props.client.errors && this.props.client.errors}
              revenueRanges={
                this.props.bp && this.props.client.signupLocale === "es"
                  ? this.props.bp.clientRevenueRanges_es
                  : this.props.bp.clientRevenueRanges
              }
              employeesRanges={
                this.props.bp.clientEmployeesRanges &&
                this.props.bp.clientEmployeesRanges
              }
              paymentDefaultTermDays={
                this.props.bp.clientPaymentDefaultTermDays &&
                this.props.bp.clientPaymentDefaultTermDays
              }
              countryCodes={this.props.bp && this.props.bp.countryCodes}
              isRegister={true}
            />

            <Row>
              <Col sm={3}>
                <Link
                  to={`/cliente/cadastre-se/responsavel/${this.props.match.params.clientId}`}
                >
                  <Button type="primary" shape="round">
                    {I18n.get("Voltar")}
                  </Button>
                </Link>
              </Col>
              <Col sm={18}></Col>
              <Col sm={3}>
                <Link
                  to={`/cliente/cadastre-se/marcas-e-usuarios/${this.props.match.params.clientId}`}
                >
                  <Button type="primary" shape="round">
                    {I18n.get("Avançar")}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Main>
        </ContainerPage>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FullCompanyClient);
