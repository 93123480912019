import styled from "styled-components";

export const ContentBpay = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const TitleContent = styled.div`
  padding: 0 15px;
  margin: 0 0 30px 0;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
`;
