import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PROJECT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_preferred_partners = (data) => {

    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/client-project/${data.projectId}/preferredPartners`, config)
                .then(response => {
                    dispatch({
                        type: PROJECT_UPDATE,
                        payload: {
                            preferredPartners: response.data,
                            hasPreferredPartners: !!response.data.length,
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    })
                })
                .catch(error => {
                    dispatch({
                        type: PROJECT_UPDATE,
                        payload: {
                            preferredPartners: [],
                            hasPreferredPartners: false,
                            errors: {},
                            task: {
                                result: false
                            }
                        }
                    })
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_preferred_partners;