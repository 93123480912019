import styled from "styled-components";

export const Wrapper = styled.div`
  border: none;
  background-color: #ffffff;
  width: 100%;
  height: 470px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
`;

export const Imgs = styled.div`
  position: relative;
`;

export const ImgBg = styled.div`
  position: relative;
  z-index: 1;

  img {
    width: 100%;
  }
`;

export const ImgLogo = styled.div`
  position: absolute;
  z-index: 2;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  left: 15px;
  top: 70px;
  border: 3px solid #ffffff;
  
  img {
    border-radius: 50%;
    width: 63px;
    height: 63px;
  }
`;

export const Infos = styled.div`
  padding: 60px 15px 15px 15px;
  height: calc(100% - 170px);
`;

export const CardTitle = styled.h3`
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  color: #2F2F2F;
  margin-bottom: 15px;
`;

export const ContentInfos = styled.div`
  height: 140px;
`;

export const Info = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const LabelInfo = styled.span`
  font-weight: 700;
  font-weight: 600;
`;

export const TextInfo = styled.span`
  margin-left: 5px;
`;

export const Status = styled.div`
  font-size: 14px;
  line-height: 20px;

  span {
    font-weight: 400;
  }

  span.titleLabel {
    font-weight: 600;
    margin-right: 5px;
  }

  img {
    margin-right: 5px;
  }
`;

export const ViewProject = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;



