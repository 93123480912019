import styled from "styled-components";
import { Table as TableAntd } from "antd";

export const Table = styled(TableAntd)`
  .ant-table-thead > tr > th {
    background: #000000;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    vertical-align: middle;
  }

  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters {
    :hover {
      background: #222;
    }
  }
`;

export const RowTab = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
`;

export const BTdrawer = styled.div`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: #1883ff;
  text-decoration: underline;
  cursor: pointer;
`;

export const RowElipsis = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Status = styled.div`
  line-height: 14px;
  text-align: center;
  background: #1883ff;
  padding: 5px 6.5px;
  border-radius: 4px;
  width: 100%;
  display: block;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
  }
`;

export const LoadingIcon = styled.div`
  background: transparent;
  border: none;
  padding: 4px 5px;

  i {
    font-size: 22px;
    color: #000;
  }
`;

export const ButtonIcon = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 4px 5px;

  i {
    font-size: 22px;
    color: #000;
    ${(props) => (props.disabled ? "opacity: 0.35;" : "opacity: 1;")};
  }
`;

export const TitleDrawer = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  padding: 32px 0;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 32px;

  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const StatusWrapper = styled.div``;
