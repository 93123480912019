import { LOGIN, LOGOUT } from "../actions/auth/actions";

const initialState = {};

export default function supplierReducer(state = initialState, action) {
  if (action.type == LOGIN || action.type == LOGOUT) return initialState;

  if (action.type.indexOf("supplier_") == -1) return state;

  return Object.assign({}, state, action.payload);
}
