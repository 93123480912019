import React, { useEffect } from "react";
import { I18n } from "aws-amplify";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSupplier } from "../../../../../../hooks/useSupplier/useSupplier.hook";
import { Logged } from "../../../../components/Logged";
import { BoxContent } from "../../../../components/BoxContent";
import { TitlePage } from "../../../../components/TitlePage";
import { DataBank } from "../../../../../../components/Shared/DataBank";
import { NotificationBPool } from "../../../../../../components/UI/NotificationBPool";
import { menuItems } from "../menu";
import * as S from "./styles";

export const ProfileDataBank = () => {
  let location = useLocation();
  const state = useSelector((state) => state);
  const {
    getBillingDataSupplier,
    addBillingDataSupplier,
    updateBillingDataSupplier,
    deleteBillingDataSupplier,
  } = useSupplier();
  const prioritizeMain = (array) => {
    array.sort((a, b) => {
      if (a.isMain && !b.isMain) {
        return -1;
      } else if (!a.isMain && b.isMain) {
        return 1;
      }
      return 0;
    });
    return array;
  };

  const { data, isLoading, error, refetch } = useQuery(
    "getBillingDataSupplier",
    () => getBillingDataSupplier(),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data) => {
        // const sortData = prioritizeMain(data?.data);
      },
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os itens de filtro"),
          duration: 3,
        });
      },
    }
  );

  const getParameterValue = (search, parameterName) => {
    const params = new URLSearchParams(search);
    return params.get(parameterName) || "";
  };

  const verifyLocale =
    state?.auth?.locale !== "pt-BR" && state?.auth?.locale !== "pt";

  return (
    <Logged>
      <div className="container">
        <BoxContent
          menuTitle={I18n.get("SOBRE A EMPRESA")}
          menu={menuItems}
          active={2}
        >
          <TitlePage title={I18n.get("Dados Bancários")} />
          <div>{I18n.get("Acompanhe suas contas cadastradas")}</div>
          <DataBank
            data={data?.data?.length ? data?.data : []}
            isLoading={isLoading}
            countryCode={verifyLocale ? "LATAM" : "BRA"}
            handleRefresh={refetch}
            addBillingData={addBillingDataSupplier}
            updateBillingData={updateBillingDataSupplier}
            deleteBillingData={deleteBillingDataSupplier}
          />
        </BoxContent>
      </div>
    </Logged>
  );
};
