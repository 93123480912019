import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ReferenceForm } from '../../pages/ReferencesPartner/ReferenceForm';
import { ReferencesList } from '../../pages/ReferencesPartner/ReferencesList';

function ReferencesPartner() {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/references-partner/form/:tokenId"
          component={ReferenceForm}
        />
        <Route
          exact
          path="/references-partner/list"
          component={ReferencesList}
        />
      </Switch>
    </>
  );
}

export default ReferencesPartner;
