import add_project_idea from './create-project'
import create_scope_idea from './create-scope-idea'
import get_project from './get-project'
import upload from './upload';
import get_products from './get-products'
import enable_product from './enable-product'

export const CLIENT_UPDATE_PROJECT = 'client_whatif_update_project';
export const CLIENT_SCOPE_PROJECT = 'client_whatif_create_scope';
export const CLIENT_PROJECT_GET = 'client_whatif_get_project';
export const CLIENT_PRODUCTS_GET = 'client_whatif_get_products';






export const CLIENT_WHATIF_API = {
    add_project_idea: add_project_idea,
    create_scope_idea: create_scope_idea,
    upload: upload,
    get_project: get_project,
    get_products: get_products,
    enable_product: enable_product
}
