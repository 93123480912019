import React, { Fragment } from 'react';
import { I18n } from 'aws-amplify';
import ReactTooltip from 'react-tooltip'

export default class Timeline extends React.Component {
  constructor(props) {

    super();
  }

  render() {

    const items = this.props.checkpoints && this.props.checkpoints.map((item, index) => {

      const { id, ratings, delivered, name, description, comment = 'Nenhum' } = item;



      return (
        <div key={index} className={`timeline-item ${index === 0 ? 'timeline-item-first' : ''}  ${index === (this.props.checkpoints.length - 1) ? 'timeline-item-last' : ''}`}>
          <div className="timeline-marker"></div>
          <div className="timeline-content">
            <p className="heading">{item.date}</p>
            <p className="timeline-name">
              <strong>{name}</strong> <br />{description}
              {delivered && (
                <Fragment>
                  <a className="delivered-alert yes-delivered" data-tip='' data-for={`delivered-success-${id}`}>
                    <i className="fas fa-check-circle"></i>
                  </a>
                  <ReactTooltip id={`delivered-success-${id}`}>
                    <div className="tooltip-bp-content">
                      <h4>{I18n.get("Avaliação")}</h4>

                      <ul>
                        {ratings.map((rating) => {
                          return (
                            <li>
                              <span>{rating.label}:</span>
                              <strong>{I18n.get("nota:")}</strong> {rating.value}
                            </li>
                          );
                        })}
                        <li>{I18n.get("Comentário para BPool:")} {comment} </li>
                      </ul>
                    </div>
                  </ReactTooltip>
                </Fragment>
              )}

              {delivered == false && (
                <Fragment>
                  <a className="delivered-alert no-delivered" data-tip='' data-for={`delivered-error-${id}`}>
                    <i className="fas fa-times-circle"></i>
                  </a>
                  <ReactTooltip id={`delivered-error-${id}`}>
                    <div className="tooltip-bp-content">
                      <h4>{I18n.get("Não entregou!")}</h4>

                      <p>{item.message}</p>
                    </div>
                  </ReactTooltip>
                </Fragment>
              )}
            </p>
          </div>
        </div>
      );

    });

    return (
      <div className="columns">
        <div className="column is-6 is-offset-6 container-schedule">
          <div className="timeline">
            <header className="timeline-header">
              <span className="tag is-primary">{I18n.get("Início")}</span>
            </header>
            {items}
            <div className="timeline-header">
              <span className="tag is-primary">{I18n.get("Fim")}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
