import styled from 'styled-components';

export const ContentInfos = styled.div``;

export const P = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #000000;

  a {
    font-size: 16px;
    text-decoration: underline;
    color: #000000;
    margin: 0 3px;
  }
`;
