import { PARTNER_SAVE_PROJECT } from './actions';

export const save_projetc_step1 = (data) => {

    return (dispatch, getState) => {

 

        dispatch({
            type: PARTNER_SAVE_PROJECT,
            payload: {
                projectStep1: data
            }
        });
    }
}

export default save_projetc_step1;
