import styled from "styled-components";
import { I18n } from "aws-amplify";

import { Input } from "antd";

const { TextArea } = Input;

export const StyleForm = styled.form`
  width: 500px;
  margin: 60px auto;
`;

export const StyleMain = styled.div`
  max-width: 630px;
  margin: 50px auto 0;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  a {
    margin-left: 10px;
  }
`;

export const ContentAttachments = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 50px 0 0 0;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
    margin: 50px 15px 0 15px;
  }
`;

export const SelectAttachment = styled.button`
  margin: 0 10px;
  border: none;
  border-radius: 10px;
  border-color: #434343;
  padding: 0;
  display: flex;
  width: 150px;
  height: 150px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @keyframes color {
    0% {
      background-color: #efefef;
    }
    50% {
      background-color: #000;
    }
    100% {
      background-color: #000;
    }
  }

  &.recording {
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    img {
      width: 50px;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
    height: 100px;
  }
`;

export const ContentAboutProject = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5px;
  }
`;

export const BoxSelectAttachment = styled.div`
  @media (max-width: 768px) {
    display: flex;
    border: solid 2px;
    width: calc(100% - 20px);
    padding: 0 5px;
  }

  button {
    background-color: #f0f0f0;
    width: 210px;
    height: 120px;
  }

  button:after {
    content: "${I18n.get("Upload")}";
    font-size: 18px;
    font-weight: 700;
    margin-left: 25px;
  }
`;

export const AttachmentList = styled.div`
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
`;

export const Loading = styled.div`
  text-align: center;
  padding: 10px;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  @keyframes color {
    0% {
      background-color: #fff;
    }
    50% {
      background-color: #fafafa;
    }
    100% {
      background-color: #fafafa;
    }
  }
`;

export const AttachmentSelected = styled.div`
  color: #1883ff;
  font-size: 16px;
  line-height: 24px;
  margin: 5px 0 0 10px;
  width: 100%;
  border-bottom: solid 1px #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 14px;
    color: #1883ff;
    padding: 5px 0;

    &:hover {
      color: #000;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const InputAboutProject = styled.textarea`
  border-radius: 17.5px;
  background: #e7e7e7;
  border: none;
  height: 150px;
`;
