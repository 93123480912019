import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_USER_CREATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_create_user = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.post(`${Config.API_BASE_URL}/bp/users`, data, config)
                .then(response => {
    
                    dispatch({
                        type: BP_USER_CREATE,
                        payload: {
                            user: {
                            },
                            task: { result: true }
                        }
                    })
                })
                .catch(error => {
    
                    dispatch({
                        type: BP_USER_CREATE,
                        payload: {
                            user: {
                            },
                            task: { 
                                result: false,
                                errors: error.response.data
                            }
                        }
                    })
                });

            }).catch(error => {
                dispatch({ type: EXPIRED });
            });
    };
}

export default api_create_user;