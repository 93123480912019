import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";
import { scrollToTop } from "../../utils/ui";

import {
  CustomEventBriefingType,
  CustomEventBriefingTypeNames,
} from "../../model";

class EventTypeForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {},
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeEventType(value, e) {
    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form["briefingType"] = value;

        return nextState;
      },
      () => {
        this.onSaving();

        this.props.onEventTypeSelect();
      }
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const eventTypes = [
      CustomEventBriefingType.Communication,
      CustomEventBriefingType.Event,
      CustomEventBriefingType.Shopper,
      CustomEventBriefingType.Incentive,
      CustomEventBriefingType.Digital,
      CustomEventBriefingType.Promotional,
    ];

    return (
      <div>
        <h1 className="title-bpool medium">
          {I18n.get("Escolha o tipo de projeto")}
        </h1>

        <br />

        <p>
          {I18n.get(
            "O tipo de projeto vai nos ajudar a determinar quais são as informações mais importantes para o seu briefing e escolha de parceiros"
          )}
        </p>

        <br />
        <br />

        {eventTypes.map((item) => {
          return (
            <div style={{ marginTop: "2rem", width: 250 }}>
              <a onClick={this.onChangeEventType.bind(this, item)}>
                <ButtonBpool
                  text={I18n.get(CustomEventBriefingTypeNames[item])}
                  className=""
                  theme="secondary"
                  full
                />
              </a>
            </div>
          );

          if (item == CustomEventBriefingType.Event) {
            return (
              <div style={{ marginTop: "2rem" }}>
                <a
                  onClick={this.onChangeEventType.bind(this, item)}
                  className="button bt-bpool black is-dark"
                  style={{ width: "280px" }}
                >
                  {I18n.get(CustomEventBriefingTypeNames[item])}
                </a>
              </div>
            );
          } else {
            return (
              <div style={{ marginTop: "2rem" }}>
                <a
                  className="button bt-bpool black is-dark"
                  style={{ opacity: 0.5, width: "280px" }}
                >
                  {I18n.get(CustomEventBriefingTypeNames[item])}
                </a>
              </div>
            );
          }
        })}

        <br />
        <br />
      </div>
    );
  }
}

export default EventTypeForm;
