import React, { useState, useEffect } from "react";
import * as S from "./styles";
import MainTitle from "../MainTitle/index";
import Button from "../Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { I18n } from "aws-amplify";

const Profile = ({ auth, profile, staff }) => {
  const name = profile.responsible && profile.responsible.name;
  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    if (name && name.indexOf("@") > -1) setFirstName(name.split("@")[0]);
    else if (name) setFirstName(name.split(" ")[0]);
  }, [name]);

  return (
    <div>
      <MainTitle text={I18n.get("Perfil")} />
      {profile.user && profile.responsible && staff && (
        <S.ProfileBoard>
          <div className="team">
            <h2>{I18n.get("Equipe Bpool")}</h2>
            {staff.map((res, index) => (
              <div key={index}>
                <img src={res.photo} />
                <div>
                  <h3>{res.name}</h3>
                  <p>{res.role}</p>
                  <a href={"mailto:" + res.email}>
                    <p class="link-email">{res.email}</p>
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="name">
              {profile.user.picture === 0 && (
                <img src={profile.user.picture} alt={profile.user.name} />
              )}
              {profile.user.picture !== 0 && (
                <div className="profile-pic">
                  <p>{profile.user.name.charAt(0).toUpperCase()}</p>
                </div>
              )}
              <div>
                <h2>{profile.user.name}</h2>
                <p>{profile.user.city}</p>
              </div>
            </div>
            <div className="responsible">
              <h2>{I18n.get("Responsável")}</h2>
              <div>
                <div className="profile-pic">
                  <p>{firstName.charAt(0).toUpperCase()}</p>
                </div>
                <div>
                  <h3>{firstName}</h3>
                  {/* <p>Cargo aqui</p> */}
                </div>
              </div>
              <div>
                <a href={"mailto:" + profile.responsible.email}>
                  <p class="link-email">
                    <strong>{I18n.get("Email")}: </strong>
                    {profile.responsible.email}
                  </p>
                </a>
                <p>
                  <strong>{I18n.get("Cidade")}: </strong>
                  {profile.responsible.city}
                </p>
              </div>
            </div>
          </div>
          {auth.is_client && (
            <Button
              text={I18n.get("Ver Perfil")}
              href="/#/cliente/responsavel"
            />
          )}
          {auth.is_partner && (
            <Button
              text={I18n.get("Ver Perfil")}
              href="/#/partner/edit-profile"
            />
          )}
        </S.ProfileBoard>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(Profile));
