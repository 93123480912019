import styled from 'styled-components';

export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const Logo = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoImage = styled.div`
  width: 113px;
  height: 113px;
  border-radius: 50%;
  /* border: 5px solid #ffffff;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 25%); */

  img {
    border: 1px solid #000000;
    border-radius: 50%;
  }

  .button-remove-upload {
    margin-left: 0;
    z-index: 99;
    margin-top: 0;
    top: 120px;
  }
`;

export const TitleSectionForm = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
  width: max-content;
`;

export const RequirementsPassword = styled.ul`
  margin-top: 10px;
  li {
    color: #515151;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    list-style: initial;
    margin-left: 15px;

    :first-child {
      list-style: none;
      margin-left: 0;
    }
  }
`;

export const ConfirmPassError = styled.div`
  margin-top: 10px;
  color: #ff0000;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
`;

export const ErrorText = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #ff0000;
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const SizeLogo = styled.div`
  position: relative;
  left: 0;
  top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #999999;
  text-align: center;

  strong {
    font-size: 10px;
    color: #999999;
  }
`;

export const InputsContent = styled.div`
  width: 100%;
  display: block;
  position: relative;
  float: left;
`;

export const RowInput = styled.div`
  width: calc(50% - 15px);
  height: 80px;
  position: relative;
  float: left;
`;
