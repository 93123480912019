import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';

import Chart from "react-google-charts";

import moment from 'moment';

const TimelineChart = (props) => {

    const { auth = {}, items = [] } = props;

    const { locale = 'pt' } = auth;

    const sorted = items.sort((a, b) => (a[1] > b[1]) - (a[1] < b[1]));

    return (
        <div>


            <p>{I18n.get("TIMELINE")}</p>

            <br />

            <Chart
                width={'100%'}
                height={'400px'}
                chartType="Timeline"
                loader={<div>{I18n.get('Loading Chart')}</div>}
                chartLanguage={locale}
                data={[
                    [
                        // { type: 'string', label: 'Task ID' },
                        { type: 'string', label: I18n.get("Task Name") },
                        { type: 'date', label: I18n.get("Start Date") },
                        { type: 'date', label: I18n.get("End Date") },
                        // { type: 'number', label: 'Duration' },
                        // { type: 'number', label: 'Percent Complete' },
                        // { type: 'string', label: 'Dependencies' }
                    ],
                    ...sorted
                ]}
                options={{
                    //height: items.length * 60,
                    gantt: {
                        //trackHeight: 30,
                    },
                    colors: [
                        '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980',
                        '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980'
                    ],
                }}
                rootProps={{ 'data-testid': '2' }}
            />

        </div>
    );
}

export default TimelineChart;
