import React from "react";
import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";

import ExtraList from "./extras/_list";

import Steps from "./steps";
import StepsCreative from "../../components/client-project-definition/steps-creative";

import { scrollToTop } from "../../utils/ui";

import {ExtrasInProgress} from "../../v2/pages/Proposal/Extras/index";


class ProjectOnGoingExtra extends React.Component {
  constructor(props) {
    super();
    this.state = {
      task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  submitForm(e) {}

  render() {
    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container" style={{paddingTop: 0}}>
            <div className="columns is-mobile">
              <div
                className="column is-6"
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="title">
                  {this.props?.project && this.props?.project?.status  != 6 ? I18n.get("Projeto em Andamento") : I18n.get("Definições do Projeto") }
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6">
                {this.props?.project && this.props?.project?.status == 6 && (
                  <>
                    <StepsCreative
                      active={5}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                    />
                  </>
                )}
                {this.props?.project && this.props?.project?.status  != 6 && (
                  <>
                    {this.props.isCreative && (
                      <>
                        <Steps
                          active={6}
                          project={this.props.project}
                          hidden={this.props.auth.is_partner ? [5] : []}
                        />
                      </>
                    )}
                    {this.props.isPartnerSelectionBidding && (
                      <>
                        <Steps active={6} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []} />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="client-step client-step-1 project-ongoing">
          <div className="container client-container-1 container-content" style={{paddingTop: 0}}>
            {/* <ExtraList {...this.props} /> */}
            <ExtrasInProgress />
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(ProjectOnGoingExtra);
