import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div`
  .ant-steps-item-content {
    font-family: Helvetica, sans-serif;

    .title-custom {
      font-size: 14px;
      font-weight: bold;
      font-family: "Helvetica Neue LT Std";
    }

    .ant-steps-item-title {
      font-size: 14px;
      font-weight: bold;
      font-family: "Helvetica Neue LT Std";
    }

    .ant-steps-item-description {
      font-size: 14px;
    }
  }

  .ant-steps {
    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background: #15b467;
        border-color: #15b467;

        .ant-steps-icon {
          color: #ffffff !important;
        }
      }
    }
  }
`;

export const ButtonResend = styled.div`
  button {
    border: none;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    margin-top: 8px;
    color: #1883ff;
  }
`;

export const FormResend = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 8px;
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-step {
    height: 20px;
    margin-top: 10px !important;

    li {
      height: 100%;
      margin-top: 10px !important;

      :nth-child(3) {
        margin-top: 20px !important;
      }

      :nth-child(5) {
        margin-top: 20px !important;
      }

      :nth-child(7) {
        margin-top: 20px !important;
      }

      :nth-child(9) {
        margin-top: 20px !important;
      }

      :first-child {
        margin-top: 0 !important;
      }

      :last-child {
        width: 100% !important;
      }
    }
  }
`;
