import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { I18n } from "aws-amplify";
import { useBpayAdmin } from "../../../../../hooks/useBpay/useBpayAdmin.hook";
import { Input } from "../../../../../components/UI/Input";
import { SelectOne } from "../../../../../components/UI/SelectOne";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { Dialog } from "../../../../../components/UI/Dialog";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";

import { createQueryString, parseQueryString } from "./utils/resolvers";
import * as S from "./styles";

export const Filters = ({ handleSelectedsBpays }) => {
  const { adminBpayListSetup, exportRepasse, exportFinancial } = useBpayAdmin();
  let history = useHistory();
  let location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValueDebounced] = useDebounce(searchTerm, 1000);
  const [searchTermPoNumber, setSearchTermPoNumber] = useState("");
  const [searchValueDebouncedPoNumber] = useDebounce(searchTermPoNumber, 1000);
  const [searchTermSku, setSearchTermSku] = useState("");
  const [searchValueDebouncedSku] = useDebounce(searchTermSku, 1000);
  const [filters, setFilters] = useState({});
  const [selectedsBpays, setSelectedsBpays] = useState([]);
  const [listClients, setListClients] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const [isExporting, setIsExporting] = useState(false);

  const { data, isLoading, error, refetch } = useQuery(
    "setupBpayAdmin",
    () => adminBpayListSetup(),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os itens de filtro"),
          duration: 3,
        });
      },
    }
  );

  const handleChangeClient = (clientId) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (clientId === "0") {
      delete objQueries.clientId;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, clientId });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleChangeStatus = (status) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (status === 0) {
      delete objQueries.status;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, status });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleChangeCountry = (country) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (country === "") {
      delete objQueries.country;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, country });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleSearch = (searchProjectNameFilter) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!searchProjectNameFilter) {
      delete objQueries.searchProjectNameFilter;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({
        ...objQueries,
        searchProjectNameFilter,
      });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleSearchPoNumber = (poNumber) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!poNumber) {
      delete objQueries.poNumber;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, poNumber });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleSearchSku = (sku) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!sku) {
      delete objQueries.sku;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, sku });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleExportRepasseOrFinancial = async (type) => {
    try {
      setIsExporting(true);
      const bPaysIds = selectedsBpays?.map((item) => item?.id);
      if (type === "repasse") {
        await exportRepasse(bPaysIds);
      }
      if (type === "financial") {
        await exportFinancial(bPaysIds);
      }
      setDialog({
        visible: true,
        text: I18n.get("Solicitação realizada com sucesso"),
        subText: I18n.get("Você receberá a planilha por e-mail"),
      });
    } catch (error) {
      setDialog({
        visible: true,
        error: true,
        text: I18n.get("Erro na solicitação"),
        subText: I18n.get("Ocorreu algum erro, tente novamente"),
      });
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchValueDebounced]);

  useEffect(() => {
    handleSearchPoNumber(searchTermPoNumber);
  }, [searchValueDebouncedPoNumber]);

  useEffect(() => {
    handleSearchSku(searchTermSku);
  }, [searchValueDebouncedSku]);

  useEffect(() => {
    const objQueries = parseQueryString(location?.search);
    setFilters(objQueries);
  }, [location]);

  useEffect(() => {
    setSelectedsBpays(handleSelectedsBpays);
  }, [handleSelectedsBpays]);

  useEffect(() => {
    const listClientsInt = data?.data?.clients?.map((item) => ({
      code: item?.code,
      label: item?.label,
    }));
    setListClients(
      listClientsInt?.length
        ? [{ code: "0", label: I18n.get("Todos") }, ...listClientsInt]
        : []
    );
    const listStatusInt = data?.data?.status?.map((item) => ({
      code: Number(item?.status),
      label: item?.statusDescription,
    }));
    setListStatus(
      listStatusInt?.length
        ? [{ code: 0, label: I18n.get("Todos") }, ...listStatusInt]
        : []
    );
    const listCountryInt = data?.data?.countries?.map((item) => ({
      code: item?.code,
      label: item?.label,
    }));
    setListCountry(listCountryInt?.length ? [...listCountryInt] : []);
  }, [data]);

  return (
    <>
      <S.Wrapper>
        <S.ContentFilters>
          <div style={{}}>
            <SelectOne
              placeholder={I18n.get("Cliente")}
              options={listClients}
              value={filters?.clientId ? filters?.clientId : "0"}
              onChange={(value) => handleChangeClient(value)}
              error=""
              loading={isLoading}
            />
          </div>
          <div style={{}}>
            <SelectOne
              placeholder={I18n.get("Status")}
              options={listStatus}
              value={filters?.status ? Number(filters?.status) : 0}
              onChange={(value) => handleChangeStatus(value)}
              error=""
              loading={isLoading}
            />
          </div>
          <div style={{}}>
            <SelectOne
              placeholder={I18n.get("País")}
              options={listCountry}
              value={filters?.country ? filters?.country : ""}
              onChange={(value) => handleChangeCountry(value)}
              error=""
              loading={isLoading}
            />
          </div>
          <div style={{}}>
            <Input
              placeholder={I18n.get("Nome do projeto")}
              label
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
              searchInput
            />
          </div>
          <div style={{}}>
            <Input
              placeholder={I18n.get("PO")}
              label
              value={searchTermPoNumber}
              onChange={(value) => setSearchTermPoNumber(value)}
              searchInput
            />
          </div>
          <div style={{}}>
            <Input
              placeholder={I18n.get("SKU")}
              label
              value={searchTermSku}
              onChange={(value) => setSearchTermSku(value)}
              searchInput
            />
          </div>
        </S.ContentFilters>
        <S.ContentButtons>
          <ButtonBpool
            text={I18n.get("Faturamento")}
            theme="tertiary"
            loading={isExporting}
            onClick={() => handleExportRepasseOrFinancial("financial")}
            disabled={selectedsBpays?.length ? false : true}
          />
          <div style={{ marginLeft: 16 }}>
            <ButtonBpool
              text={I18n.get("Realizar repasse")}
              theme="primary"
              loading={isExporting}
              onClick={() => handleExportRepasseOrFinancial("repasse")}
              disabled={selectedsBpays?.length ? false : true}
            />
          </div>
        </S.ContentButtons>
      </S.Wrapper>

      <Dialog
        visible={dialog.visible}
        text={dialog.text}
        subText={dialog.subText}
        loading={false}
        theme="white"
        error={dialog?.error}
        buttons={{
          ok: {
            text: I18n.get("Fechar"),
            func: () => setDialog({ visible: false }),
          },
          cancel: {},
        }}
      />
    </>
  );
};
