import axios from 'axios';
import { Auth } from 'aws-amplify';
import Config from '../../config';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_diversity = () => {
    return () => {
        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/client-project/diversity/setup`, config)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                });
        }).catch(error => {
            //dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_diversity;