import React from 'react';

import { I18n } from 'aws-amplify';

const Dropdown = ({ trigger, children, isOpen, style={}, contentStyle={} }) => {

    const className = 'dropdown is-right ' + (isOpen ? 'is-active' : '');

    return (
        <div class={className} style={style}>
            <div class="dropdown-trigger">
                {trigger}
            </div>
            <div class="dropdown-menu" role="menu">
                <div class="dropdown-content" style={{...{ width: '350px' }, ...contentStyle}}>

                    <div className="dropdown-item">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Dropdown;