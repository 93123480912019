import React from 'react';
import { Route, Switch } from 'react-router-dom';

// FULL
import { EditAboutYou } from '../../pages/LeaderEditProfile/pages/About-you';
import { EditIndustry } from '../../pages/LeaderEditProfile/pages/Industry';
import { EditCasesAndAwards } from '../../pages/LeaderEditProfile/pages/CasesAndAwards';
import { EditPassword } from '../../pages/LeaderEditProfile/pages/ChangePassword';

function LeaderEditProfile() {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/leader/edit-profile/about-you"
          component={EditAboutYou}
        />
        <Route
          exact
          path="/leader/edit-profile/industry"
          component={EditIndustry}
        />
        <Route
          exact
          path="/leader/edit-profile/cases-and-awards"
          component={EditCasesAndAwards}
        />
        <Route
          exact
          path="/leader/edit-profile/change-password"
          component={EditPassword}
        />
      </Switch>
    </>
  );
}

export default LeaderEditProfile;
