import React from 'react';

import { I18n } from 'aws-amplify';

const Card = (props) => {

    const items = props.items;

    return (
        <div className="card">
            <div className="card-content">
                <h3 className="title is-4">{I18n.get("Segmentos")}</h3>

                <div className="content">

                    <table className="table is-narrow">
                        <tbody>
                            <tr>
                                <th>{I18n.get("Nome")}</th>
                                <th>{I18n.get("Anos")}</th>
                                <th>{I18n.get("Marcas")}</th>
                            </tr>
                            {items && items.map((item, index) =>
                                (<tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.years}</td>
                                    <td>{item.brands}</td>
                                </tr>)
                            )}
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </div>
    );
};

export default Card;