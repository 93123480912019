import React from "react";

import * as S from "./styles";

export const ToggleCheckbox = ({
  onChangeCb,
  id,
  checked
}) => {
  return (
    <S.Wrapper>
      <input
        type="checkbox"
        id={id}
        onChange={(e) => onChangeCb(e, id)}
        checked={checked}
      />
    </S.Wrapper>
  );
};
