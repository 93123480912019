import React from 'react';

import { I18n } from 'aws-amplify';

import Dropzone from 'react-dropzone';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import iconUpload from '../../svg/icon-upload.svg';

import FileUploadList from '../../modules/components/FileUploadList';

class QuotationUpload extends React.Component {
    constructor(props) {

        super();

        const quotation = props.quotation || {};

        this.state = {
            form: {
                files: quotation.files || [],
            },
            task: {},
            upload_task: {},
            errors: {}
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        if (task != prev) {

            //console.dir({ task: task, prev: prev });

            var nextState = Object.assign({}, prevState, { task: task });

            const quotation = this.props.quotation || {};

            Object.keys(this.state.form).forEach((item) => {

                if (FormUtils.isFormField(nextState.form[item])) {

                    nextState.form[item].error = this.props.errors[item];

                    if ('checked' in nextState.form[item]) {
                        nextState.form[item].checked = quotation[item];
                    }
                    else {
                        nextState.form[item].value = quotation[item];
                    }
                }
                else {
                    nextState.form[item] = quotation[item];
                }
            });

            // atualiza os arquivos
            if (task.result) {

                nextState.form.files = quotation.files || [];
            }

            this.setState(nextState);
        }
    }

    delFile(item) {

        var data = {
            projectId: this.props.match.params.projectId,
            quotationId: this.props.quotation.id,
            ...item
        };

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.delete_universal_quotation_file(data);
        });

    }

    getDataUploadMult = (files) => {

        const { projectId } = this.props.match.params;

        let file = files[0];

        delete file['uid'];

        const data = {
            projectId: projectId,
            quotationId: this.props.quotation.id,
            filesSend: file
        }

        this.props.upload_universal_quotation_file(data)
            .then(response => {

                this.setState((prevState) => {

                    var nextState = Object.assign({}, prevState);

                    nextState.form.files.push(response.data);
                    nextState.upload_task = { result: true };

                    return nextState;
                });

            })
            .catch(error => {

                this.setState({ upload_task: { result: false } });

            });
    }


    render() {
        
        const { projectId } = this.props.match.params;

        const quotation = this.props.quotation || {};

        const canEdit = this.props.auth.is_partner && quotation.isOpen;

        let filesNormalize = quotation.files && quotation.files.map((item) => {
            return {
                ...item,
                uid: item.id,
            }
        })

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Proposta criativa/técnica")}</h1>

                <br /><br />

                <div style={{ fontSize: '80%', marginTop: '50px' }}>

                    {filesNormalize && filesNormalize.map((item, index) =>
                        (<div key={index} class="columns" style={{ borderBottom: "1px solid black" }}>
                            <div class="column is-two-thirds is-clearfix upload-item" >
                                <a href={item.url} target="_blank">{item.name}</a>
                            </div>
                            <div class="column ">
                                {item.uploadedDateFmt}
                                {!this.state.upload_task.busy
                                    && !this.state.task.busy && <div className="is-pulled-right">

                                        {item.uploadedByUsername == this.props.auth.username && canEdit && <a className="delete" onClick={this.delFile.bind(this, item)} ></a>}
                                    </div>}
                            </div>
                        </div>)
                    )}

                </div>

                <br /><br />

                {canEdit && <>

                    <div className="columns">

                        <div className="column is-6">

                            <FileUploadList
                                getDataUpload={this.getDataUploadMult}
                                name="files"
                                uploadId={projectId}
                                get2={this.props.get2 || false}
                                fileListGet={filesNormalize || []}
                                disabledList={true}
                                limit={1}
                                output="object"
                            />

                        </div>

                    </div>

                </>}


            </div>
        );

    }
}

export default QuotationUpload;
