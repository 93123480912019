import React from "react";
import { I18n } from "aws-amplify";
import { NavLink, withRouter } from "react-router-dom";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";
import { QueryString } from "../../utils/querystring";

import EventTypeForm from "./_event-type";
import BriefingForm from "./_event-briefing";
import AboutForm from "./_event-about";
import ScheduleForm from "./_event-schedule";
import AudienceForm from "./_event-audience";
import DigitalPlatformForm from "./_event-digital-platform";
import LocationForm from "./_event-location";
import ServicesForm from "./_event-services";
import ProductShopperForm from "./_event-product-shopper";
import ProductIncentiveForm from "./_event-product-incentive";
import FoodBeveragesForm from "./_event-food-beverages";
import EventLogisticsForm from "./_event-logistics-event";
import MktLogisticsForm from "./_event-logistics-mkt";
import StaffForm from "./_event-staff";
import AttractionsForm from "./_event-attractions";
import PromoForm from "./_event-promo";
import BudgetForm from "./_event-budget";
import ResultsForm from "./_event-results";
import QuotationForm from "./_event-quotation";
import UploadForm from "./_event-upload";

import { eventMenuMap } from "./_event-menu";

import "./event-customize-form.css";

import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";

import {
  CustomEventBriefingType,
  CustomEventBriefingTypeNames,
} from "../../model";

class EventCustomizeForm extends React.Component {
  constructor(props) {
    super();

    var queryString = QueryString.extract(props.location.search);

    this.state = {
      form: {
        isSubmit: false,
      },
      step: queryString.s && parseInt(queryString.s),
      task: {},
    };
  }

  componentDidMount() {
    var data = {
      clientId: this.props.client.clientId,
      projectId: this.props.match.params.projectId,
    };

    this.props.getPreferredPartners(data);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value =
            this.props[item] || nextState.form[item].defaultValue;
        } else if (typeof nextState.form !== "undefined") {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }
  }

  show(index, e) {
    e.preventDefault();

    this.setState({ step: index });
  }

  getData() {
    var data = {
      clientId: this.props.clientId,
      ...this.props.project,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (FormUtils.isFormField(this.state.form[item])) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  handleBundleBtnClick = () => {
    this.props.onBundleBtnClick();
  };

  handleResumeBtnClick = () => {
    const cart = this.props.cartAssets;
    this.props.onResumeBtnClick(cart);
  };

  onEventTypeSelect() {
    this.setState({ step: 0 });
  }

  render() {
    const { customEvent = {} } = this.props;

    const steps = customEvent && eventMenuMap[customEvent.briefingType];

    let CheckBudget = "";

    if (customEvent != null) {
      CheckBudget =
        customEvent.budgetPerson || customEvent.budgetTotal ? true : false;
    } else if (customEvent == null) {
      CheckBudget = false;
    } else {
      CheckBudget = false;
    }

    let component = null;
    let choose = {};

    switch (this.state.step) {
      case 0:
        component = <BriefingForm {...this.props} />;
        break;

      // case 1: component = (<AboutForm {...this.props} />); break;

      case 1:
        choose = {
          [CustomEventBriefingType.Event]: <AboutForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <AboutForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: <AboutForm {...this.props} />,
          [CustomEventBriefingType.Digital]: <AboutForm {...this.props} />,
          [CustomEventBriefingType.Communication]: (
            <BudgetForm {...this.props} />
          ),
          [CustomEventBriefingType.Promotional]: <BudgetForm {...this.props} />,
        };

        component = choose[customEvent.briefingType];
        break;

      // case 2: component = (<ScheduleForm {...this.props} />); break;

      case 2:
        choose = {
          [CustomEventBriefingType.Event]: <ScheduleForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <ScheduleForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: <ScheduleForm {...this.props} />,
          [CustomEventBriefingType.Digital]: <ScheduleForm {...this.props} />,
          [CustomEventBriefingType.Communication]: (
            <ResultsForm {...this.props} />
          ),
          [CustomEventBriefingType.Promotional]: (
            <QuotationForm {...this.props} />
          ),
        };

        component = choose[customEvent.briefingType];
        break;

      // case 3: component = (<AudienceForm {...this.props} />); break;

      case 3:
        choose = {
          [CustomEventBriefingType.Event]: <AudienceForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <AudienceForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: <AudienceForm {...this.props} />,
          [CustomEventBriefingType.Digital]: <AudienceForm {...this.props} />,
          [CustomEventBriefingType.Communication]: (
            <QuotationForm {...this.props} />
          ),
          [CustomEventBriefingType.Promotional]: <UploadForm {...this.props} />,
        };

        component = choose[customEvent.briefingType];
        break;

      // case 4: component = (customEvent.isDigital) ? (<DigitalPlatformForm {...this.props} />) : (<LocationForm {...this.props} />); break;

      case 4:
        choose = {
          [CustomEventBriefingType.Event]: <LocationForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <LocationForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: <LocationForm {...this.props} />,
          [CustomEventBriefingType.Digital]: (
            <DigitalPlatformForm {...this.props} />
          ),
          [CustomEventBriefingType.Communication]: (
            <UploadForm {...this.props} />
          ),
        };

        component = choose[customEvent.briefingType];
        break;

      case 5:
        choose = {
          [CustomEventBriefingType.Event]: <ServicesForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: (
            <ProductShopperForm {...this.props} />
          ),
          [CustomEventBriefingType.Incentive]: (
            <ProductIncentiveForm {...this.props} />
          ),
          [CustomEventBriefingType.Digital]: <ServicesForm {...this.props} />,
        };

        component = choose[customEvent.briefingType];
        break;

      case 6:
        choose = {
          [CustomEventBriefingType.Event]: (
            <FoodBeveragesForm {...this.props} />
          ),
          [CustomEventBriefingType.Shopper]: (
            <FoodBeveragesForm {...this.props} />
          ),
          [CustomEventBriefingType.Incentive]: (
            <FoodBeveragesForm {...this.props} />
          ),
          [CustomEventBriefingType.Digital]: (
            <AttractionsForm {...this.props} />
          ),
        };

        component = choose[customEvent.briefingType];
        break;

      case 7:
        choose = {
          [CustomEventBriefingType.Event]: (
            <EventLogisticsForm {...this.props} />
          ),
          [CustomEventBriefingType.Shopper]: (
            <MktLogisticsForm {...this.props} />
          ),
          [CustomEventBriefingType.Incentive]: (
            <MktLogisticsForm {...this.props} />
          ),
          [CustomEventBriefingType.Digital]: <PromoForm {...this.props} />,
        };

        component = choose[customEvent.briefingType];
        break;

      case 8:
        choose = {
          [CustomEventBriefingType.Event]: <StaffForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <StaffForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: <StaffForm {...this.props} />,
          [CustomEventBriefingType.Digital]: <BudgetForm {...this.props} />,
        };

        component = choose[customEvent.briefingType];
        break;

      case 9:
        choose = {
          [CustomEventBriefingType.Event]: <AttractionsForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: (
            <AttractionsForm {...this.props} />
          ),
          [CustomEventBriefingType.Incentive]: (
            <AttractionsForm {...this.props} />
          ),
          [CustomEventBriefingType.Digital]: <ResultsForm {...this.props} />,
        };

        component = choose[customEvent.briefingType];
        break;

      case 10:
        choose = {
          [CustomEventBriefingType.Event]: <PromoForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <PromoForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: <PromoForm {...this.props} />,
          [CustomEventBriefingType.Digital]: <QuotationForm {...this.props} />,
        };

        component = choose[customEvent.briefingType];
        break;

      case 11:
        choose = {
          [CustomEventBriefingType.Event]: <BudgetForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <BudgetForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: <BudgetForm {...this.props} />,
          [CustomEventBriefingType.Digital]: <UploadForm {...this.props} />,
        };

        component = choose[customEvent.briefingType];
        break;

      case 12:
        choose = {
          [CustomEventBriefingType.Event]: <ResultsForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <ResultsForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: <ResultsForm {...this.props} />,
          [CustomEventBriefingType.Digital]: null,
        };

        component = choose[customEvent.briefingType];
        break;

      case 13:
        choose = {
          [CustomEventBriefingType.Event]: <QuotationForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <QuotationForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: (
            <QuotationForm {...this.props} />
          ),
          [CustomEventBriefingType.Digital]: null,
        };

        component = choose[customEvent.briefingType];
        break;

      case 14:
        choose = {
          [CustomEventBriefingType.Event]: <UploadForm {...this.props} />,
          [CustomEventBriefingType.Shopper]: <UploadForm {...this.props} />,
          [CustomEventBriefingType.Incentive]: <UploadForm {...this.props} />,
          [CustomEventBriefingType.Digital]: null,
        };

        component = choose[customEvent.briefingType];
        break;

      default:
        break;
    }

    return (
      <form onSubmit={this.submitForm.bind(this)}>
        <div className="columns columns-for-np">
          <div
            className="column left-column-for-np is-3 is-fullheight"
            style={{ paddingLeft: "3rem", backgroundColor: "#EEEEEE" }}
          >
            {/* <h1 className="title-bpool medium">
                        Briefing
                    </h1> */}
            <aside className="menu">
              <ul className="bp-menu-list">
                <li>
                  <a
                    onClick={this.show.bind(this, null)}
                    className={
                      this.state.step == null
                        ? "bp-is-active"
                        : "bp-is-inactive"
                    }
                  >
                    {I18n.get("Tipo")}{" "}
                    {customEvent && (
                      <span>
                        {" "}
                        -{" "}
                        {I18n.get(
                          CustomEventBriefingTypeNames[customEvent.briefingType]
                        )}
                      </span>
                    )}
                  </a>
                </li>
                {steps &&
                  steps.map((item, index) => {
                    const className =
                      this.state.step == index
                        ? "bp-is-active"
                        : "bp-is-inactive";

                    let disabledStep = "";

                    if (
                      !CheckBudget &&
                      customEvent.isDigital === true &&
                      index > 8
                    ) {
                      disabledStep = "disabledLink";
                    }

                    if (
                      !CheckBudget &&
                      customEvent.isDigital === false &&
                      index > 11
                    ) {
                      disabledStep = "disabledLink";
                    }

                    if (this.state.step == null) {
                      disabledStep = "disabledLink";
                    }

                    return (
                      <li>
                        <a
                          onClick={this.show.bind(this, index)}
                          className={`${className} ${disabledStep}`}
                        >
                          {I18n.get(item)}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </aside>
          </div>

          <div
            className="column right-column-for-np is-9"
            style={{ backgroundColor: "#ffffff" }}
          >
            {this.state.step == null && (
              <div className="ContainerEvent">
                <EventTypeForm
                  {...this.props}
                  onEventTypeSelect={this.onEventTypeSelect.bind(this)}
                />
              </div>
            )}

            <div className="ContainerEvent">{component}</div>
            <br />
            <br />

            <div className="ContainerEvent">
              {this.state.step != null && this.state.step != 0 && (
                <a
                  onClick={this.show.bind(this, this.state.step - 1)}
                  className="is-pulled-left"
                >
                  <ButtonBpool
                    text={I18n.get("Voltar")}
                    className=""
                    theme="secundary"
                    full
                  />
                </a>
              )}

              {this.state.step != null &&
                this.state.step < (steps && steps.length - 1) && (
                  <a
                    onClick={this.show.bind(this, this.state.step + 1)}
                    className="bp-event-anchor"
                  >
                    <ButtonBpool
                      text={I18n.get("Próximo")}
                      className=""
                      theme="primary"
                      full
                    />
                  </a>
                )}

              {this.state.step == (steps && steps.length - 1) && (
                <NavLink
                  to={`/cliente/projetos/${this.props.match.params.projectId}/passo-3`}
                  className="bp-event-anchor"
                >
                  {/* <button className="button bt-bpool black">{I18n.get("Próximo")} 1</button> */}
                  <ButtonBpool
                    text={I18n.get("Próximo")}
                    className=""
                    theme="primary"
                    full
                  />
                </NavLink>
              )}
            </div>

            <br />
            <br />
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(EventCustomizeForm);
