import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import TextAreaField from "../form/textarea-field";

import { scrollToTop } from "../../utils/ui";

import { CustomEventBriefingType } from "../../model";

import { Textarea } from "../../v2/components/UI/Textarea";

const evt = CustomEventBriefingType.Event;
const shp = CustomEventBriefingType.Shopper;
const ict = CustomEventBriefingType.Incentive;
const com = CustomEventBriefingType.Communication;
const dig = CustomEventBriefingType.Digital;

class ResultsForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        resultsDesc: {
          labels: {
            [evt]: I18n.get("Como você vai medir o sucesso do evento?"),
            [shp]: I18n.get("Como você vai medir o sucesso do projeto?"),
            [ict]: I18n.get("Como você vai medir o sucesso do projeto?"),
            [com]: I18n.get("Como você vai medir o sucesso do projeto?"),
            [dig]: I18n.get("Como você vai medir o sucesso do projeto?"),
          },
          onChange: this.onChangeForm.bind(this, "resultsDesc"),
          // onBlur: this.onSave.bind(this),
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};

    console.log("this.props.customEvent", customEvent.briefingType);

    return (
      <div>
        <h1 className="title-bpool medium">
          {I18n.get("Mensuração de Resultados (KPIs)")}
        </h1>

        <div className="bp-event-section">
          {/* <TextAreaField
            {...this.state.fields.resultsDesc}
            labelKey={customEvent.briefingType}
            value={this.state.form.resultsDesc}
            error={this.state.errors.resultsDesc}
          /> */}

          <Textarea
            label={
              this.state.fields.resultsDesc.labels[customEvent.briefingType]
            }
            defaultValue={this.state.form.resultsDesc}
            value={this.state.form.resultsDesc}
            onChange={(value) => this.state.fields.resultsDesc.onChange(value)}
            error={this.state.errors.resultsDesc}
          />
        </div>
      </div>
    );
  }
}

export default ResultsForm;
