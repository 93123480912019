import React from 'react';
import { I18n } from 'aws-amplify';

const Terms = (props) => {

   return (
      <div className="termsContainer" style={{ height: '50vh' }}>
         <div className="termsContent" style={{ height: '45vh' }}>
            <p>
               <strong>BUENAS PRÁCTICAS DE COMPETENCIA</strong>
            </p>
            <p>
               &nbsp;
            </p>
            <p>
               Como líder del pitch <b>{props.projectName}</b>, estoy de acuerdo en hacer el mejor esfuerzo para:
            </p>
            <ul>
               <li style={{ marginTop: '1.5rem' }}>
                  - Seguir el proceso y embudo de competencia de BPitch, respetando las fechas propuestas en la cronología;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                  - Comunicar posibles cambios de fechas y horarios a mi B.Manager BPool, con la mayor anticipación posible;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                  - Responder a las posibles dudas de los participantes del proceso de pitch de manera ágil y ética, siempre dando igualdad de condiciones a todos;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                  - Respetar los criterios y pesos que definí en la plataforma como forma de determinar al ganador, segundo y tercer lugar;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                  - Al final del proceso, dar feedback de manera constructiva a través de scorecards y comentarios abiertos a los no ganadores del pitch.
               </li>
            </ul>
         </div>
      </div>
   );
}

export default Terms;