import React from "react";
import { I18n } from "aws-amplify";

import { codeToLabel } from "../../utils/helpers";

import Steps from "./_partner-steps";

import LinkButton from "../ui/link_button";

import { Task } from "../../utils/task";

import Responsbible from "./_partner-details-responsible";
import Company from "./_partner-details-company";
import Address from "./_partner-details-address";
import Specialisms from "./_partner-details-specialisms";
//import MarketExpertises from './_common-marketExpertises';
import Clients from "./_partner-details-clients";
import Cases from "./_common-cases";
import Awards from "./_common-awards";
import Leaders from "./_partner-details-leaders";
import References from "./_partner-details-references";

import SignupLink from "./_partner-details-signuplink";

import {
  Icon,
  Button,
  Select,
  Tooltip,
  Row,
  Col,
  Input,
  Modal,
  message,
} from "antd";

const { Option } = Select;

export default class PartnerDetailsView extends React.Component {
  constructor(props) {
    super();

    this.state = {
      newClient: "",
      name: "",
      email: "",
      nameAlias: "",
      phoneNumber: "",
      visible: false,
      loading: false,
    };
  }

  handleVisible = () => {
    this.setState({ visible: !this.state.visible });
  };

  handleUpdateMaster = () => {
    this.setState({ loading: true });
    const data = {
      id: this.props.match.params.partnerId,
      name: this.state.name,
      email: this.state.email,
      nameAlias: this.state.nameAlias,
      phoneNumber: this.state.phoneNumber,

      prefix: "partner",
    };

    this.props.update_master(data).then((response) => {
      if (response.data.success) {
        this.props.get_partner(this.props.match.params.partnerId);
        this.setState({
          visible: false,
          name: "",
          email: "",
          nameAlias: "",
          phoneNumber: "",
          loading: false,
        });
      } else {
        message.error(response.data.errors.Email.errorMessage);
        this.setState({
          loading: false,
        });
      }
    });
  };

  handleClient = (e) => {
    this.setState({
      newClient: e,
    });
  };

  sendClient = () => {
    const data = {
      partnerId: this.props.match.params.partnerId,
      clientId: this.state.newClient,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.updateClients(data).then(() => {
        this.setState({
          newClient: "",
        });
      });
    });
  };

  handleUpdateStats = (id) => {
    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.updateStats(id);
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.bp.task || {};
    const prev = (prevProps && prevProps.bp.task) || {};

    if (task != prev) {
      this.setState({ task: task });
    }
  }
  componentDidMount() {}

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.partnerStatusesMap) return "";

    return this.props.bp.partnerStatusesMap[value].label;
  }

  render() {
    if (!this.props.bp || !this.props.bp.partner) return null;

    const item = this.props.bp.partner;

    return (
      <section className="section">
        <Steps {...this.props}></Steps>

        <div className="field is-grouped">
          <div className="control">
            <label className="label">&nbsp;</label>
            <LinkButton
              to={`/bpool/parceiros/${item.id}/editar`}
              /*disabled={!this.props.auth.is_master}*/ label={I18n.get(
                "Editar status"
              )}
            ></LinkButton>
          </div>

          {/* <div className="control">
                        <label className="label">&nbsp;</label>
                        <LinkButton to={`/bpool/parceiros/${item.id}/avaliar`} disabled={item.status != PartnerStatus.Evaluation} label="Avaliação"></LinkButton>
                    </div> */}

          <div className="control">
            <label className="label">&nbsp;</label>
            <LinkButton
              to={`/bpool/parceiros/${item.id}/pre-selecao`}
              /*disabled={item.status != PartnerStatus.BackgroundChecked}*/ label={I18n.get(
                "Pré-seleção"
              )}
            ></LinkButton>
          </div>

          {/* <div className="control">
                        <label className="label">&nbsp;</label>
                        <LinkButton to={`/bpool/parceiros/${item.id}/aprovacao-final`} disabled={item.status != PartnerStatus.Evaluation} label="Aprovação final"></LinkButton>
                    </div> */}
        </div>

        {(item.status === 1 || item.status === 5) && (
          <div className="columns">
            <div className="column is-12">
              <SignupLink {...this.props} />
            </div>
          </div>
        )}

        {item.status === 7 && (
          <div className="columns">
            <div className="column is-3">
              <div className="card uplift ">
                <div className="card-content break-word">
                  <h3>{I18n.get("Adicionar a lista de cliente")}</h3>

                  <Select
                    onSelect={(e) => this.handleClient(e)}
                    value={this.state.newClient || ""}
                  >
                    {this.props?.bp?.clientsAdd?.map((item, index) => {
                      return (
                        <Option key={index} value={item.code}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>

                  <p className="btn-submit">
                    <Button
                      onClick={() => this.sendClient()}
                      disabled={!this.state.newClient}
                      loading={this.state?.task?.busy}
                      className="button bt-bpool black pull-right"
                    >
                      {I18n.get("Adicionar")}
                    </Button>
                  </p>
                </div>
              </div>
            </div>

            <div className="column is-3">
              <div className="card uplift " style={{ height: "100%" }}>
                <div className="card-content break-word">
                  <h3>{I18n.get("Atualizar estatística")}</h3>
                  <br />
                  <br />
                  <p className="btn-submit">
                    <Button
                      onClick={() =>
                        this.handleUpdateStats(
                          this.props.match.params.partnerId
                        )
                      }
                      loading={this.state?.task?.busy}
                      className="button bt-bpool black"
                    >
                      {I18n.get("Atualizar")}
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="columns">
          <div className="column is-12">
            <Responsbible {...this.props} onVisible={this.handleVisible} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Company {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Address {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <div className="card">
              <div className="card-content">
                <div className="content">
                  <h3>{I18n.get("Documentos essenciais")}</h3>

                  <div className="list-documentos">
                    <Row>
                      <Col sm={6}>
                        <strong>{I18n.get("Certidão negativa")}</strong>
                        <br />
                        <br />
                        <Tooltip
                          title={
                            this.props?.bp?.partner?.company
                              ?.docNegativeCertificate?.name || ""
                          }
                        >
                          <a
                            className="button bt-bpool black"
                            disabled={
                              !this.props?.bp?.partner?.company
                                ?.docNegativeCertificate
                            }
                            href={
                              this.props?.bp?.partner?.company
                                ?.docNegativeCertificate?.url
                            }
                          >
                            <Icon type="file-pdf" />{" "}
                            <span>{I18n.get("Baixar arquivo")}</span>
                          </a>
                        </Tooltip>
                      </Col>
                      <Col sm={6}>
                        <strong>{I18n.get("Cartão CNPJ")}</strong>
                        <br />
                        <br />
                        <Tooltip
                          title={
                            this.props?.bp?.partner?.company?.docCnpjCard
                              ?.name || ""
                          }
                        >
                          <a
                            className="button bt-bpool black"
                            disabled={
                              !this.props?.bp?.partner?.company?.docCnpjCard
                            }
                            href={
                              this.props?.bp?.partner?.company?.docCnpjCard?.url
                            }
                          >
                            <Icon type="file-pdf" />{" "}
                            <span>{I18n.get("Baixar arquivo")}</span>
                          </a>
                        </Tooltip>
                      </Col>
                      <Col sm={6}>
                        <strong>{I18n.get("Contrato social")}</strong>
                        <br />
                        <br />
                        <Tooltip
                          title={
                            this.props?.bp?.partner?.company?.docSocialContract
                              ?.name || ""
                          }
                        >
                          <a
                            className="button bt-bpool black"
                            disabled={
                              !this.props?.bp?.partner?.company
                                ?.docSocialContract
                            }
                            href={
                              this.props?.bp?.partner?.company
                                ?.docSocialContract?.url
                            }
                          >
                            <Icon type="file-pdf" />{" "}
                            <span>{I18n.get("Baixar arquivo")}</span>
                          </a>
                        </Tooltip>
                      </Col>
                      <Col sm={6}>
                        <strong>{I18n.get("Declaração correntista")}</strong>
                        <br />
                        <br />
                        <Tooltip
                          title={
                            this.props?.bp?.partner?.company
                              ?.docAccountStatement?.name || ""
                          }
                        >
                          <a
                            className="button bt-bpool black"
                            disabled={
                              !this.props?.bp?.partner?.company
                                ?.docAccountStatement
                            }
                            href={
                              this.props?.bp?.partner?.company
                                ?.docAccountStatement?.url
                            }
                          >
                            <Icon type="file-pdf" />{" "}
                            <span>{I18n.get("Baixar arquivo")}</span>
                          </a>
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Specialisms {...this.props} />
          </div>
        </div>

        {item.company &&
          this.props.bp.diversity &&
          !item.company.diversityPreferNotDeclare &&
          ((item.company.diversityOwned &&
            item.company.diversityOwned.length > 0) ||
            (item.company.diversityLeadership &&
              item.company.diversityLeadership.length > 0)) && (
            <div className="columns">
              <div className="column is-12">
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      <div className="PartnerRepresentatividade boxPreview">
                        <div className="boxCard">
                          <h3>
                            {I18n.get("Representatividade e diversidade")}
                          </h3>
                          <p>
                            {I18n.get(
                              "Esta é uma empresa que celebra a representatividade e a diversidade e declara ter na sua composição"
                            )}
                          </p>

                          {item.company.diversityLeadership &&
                            item.company.diversityLeadership.length > 0 && (
                              <div className="setor">
                                <p>
                                  <b>{I18n.get("Em cargos de liderança")}</b>
                                </p>
                                <ul className="paList">
                                  {item.company.diversityLeadership.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          {" "}
                                          {codeToLabel(
                                            item,
                                            this.props.bp.diversity
                                          )}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            )}

                          {item.company.diversityOwned &&
                            item.company.diversityOwned.length > 0 && (
                              <div
                                className="setor"
                                style={{ marginTop: "50px" }}
                              >
                                <p>
                                  <b>{I18n.get("No time de colaboradores")}</b>
                                </p>
                                <ul className="paList">
                                  {item.company.diversityOwned.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          {" "}
                                          {codeToLabel(
                                            item,
                                            this.props.bp.diversity
                                          )}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="divisor" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/*<div className="columns">

                    <div className="column is-12">

                        <MarketExpertises items={item.MarketExpertises} />

                    </div>

                </div>*/}

        <div className="columns">
          <div className="column is-12">
            <Clients {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Cases {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Awards {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Leaders {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <References {...this.props} />
          </div>
        </div>

        {item.workAbroad && (
          <div className="columns">
            <div className="column is-12">
              <section>
                <div className="card">
                  <div className="card-content">
                    <h3 className="title is-4">
                      {I18n.get("Atendimento de clientes fora do seu país")}
                    </h3>

                    <div className="content">
                      <table className="table-bpool no-opacity">
                        <tbody>
                          <tr>
                            <th>{I18n.get("País")}</th>
                            <th>{I18n.get("Justificativa")}</th>
                          </tr>
                          {item.workAbroad &&
                            item.workAbroad.map((item, index) => (
                              <tr key={index}>
                                <td>{item.country.name}</td>
                                <td>
                                  {item.workType.map((p, index) => {
                                    let justifuItem =
                                      this.props.bp.partnerWorkAbroad &&
                                      this.props.bp.partnerWorkAbroad.filter(
                                        (i) => i.code == p
                                      );

                                    return (
                                      <span className="spanAdmin" key={index}>
                                        {" "}
                                        - {justifuItem && justifuItem[0].label}
                                      </span>
                                    );
                                  })}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}

        {/* <div className="columns">

                    <div className="column is-12">

                        <Video {...this.props} />

                    </div>

                </div>

                <div className="columns">

                    <div className="column is-12">

                        <Score {...this.props} />

                    </div>

                </div> */}

        <Modal
          title={I18n.get("Trocar usuário master")}
          visible={this.state.visible}
          okText={I18n.get("Salvar")}
          cancelText={I18n.get("Cancelar")}
          destroyOnClose={true}
          onOk={this.handleUpdateMaster}
          onCancel={this.handleVisible}
          footer={[
            <Button
              className="button bt-bpool black"
              loading={this.state.loading}
              onClick={this.handleUpdateMaster}
              disabled={!this.state.name || !this.state.email}
            >
              {I18n.get("Salvar")}
            </Button>,
          ]}
        >
          <p>
            <Input
              placeholder={I18n.get("Nome")}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </p>
          <br />
          <p>
            <Input
              placeholder={I18n.get("E-mail")}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </p>
          <br />
          <p>
            <Input
              placeholder={I18n.get("Apelido")}
              onChange={(e) => this.setState({ nameAlias: e.target.value })}
            />
          </p>
          <br />
          <p>
            <Input
              placeholder={I18n.get("Telefone")}
              addonBefore={
                this.props.bp.partner &&
                this.props.bp.partner.country &&
                this.props.bp.partner.country.prefix
              }
              onChange={(e) => this.setState({ phoneNumber: e.target.value })}
            />
          </p>
        </Modal>
      </section>
    );
  }
}
