import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Row, Col, message } from "antd";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";
import { Loggedv2 } from "../../components/Loggedv2";
import { ButtonExpandBoxSelect } from "../../components/ButtonExpandBoxSelect";
import { useRegistrationFlow } from "../../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { urlsFull as urls } from "../../utils/stepsUrls";
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from "../../utils/flowToStorage";
import { statusIdToName } from "../../components/Loggedv2/statusIdToName";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";

import * as S from "./styles";

const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const PAGE_INDEX = 11;

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  const { getIndustry, updateIndustry } = useRegistrationFlow();
  const { getStatusBarFull } = useRegistrationFlowFull();
  let history = useHistory();

  const [options, setOptions] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [isLoadPage, setIsLoadPage] = useState(false);
  const [sendLoadingPage, setSendLoadingPage] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [errorsPage, setErrorsPage] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const loadInit = async () => {
    setIsLoadPage(true);

    const response = await getIndustry({
      admin: contextPage === "admin" ? true : false,
    });

    setIsLoadPage(false);

    setOptions(response?.data);
  };

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const setYear = async (item) => {
    const editOptions = options;

    setErrorsPage([]);
    const indiceCategorie = editOptions.findIndex(
      (cat) => cat.code == item.sectionCode
    );

    const selectCategorie = editOptions[indiceCategorie];

    let typeUser = [];

    if (item.item.type == 1) {
      typeUser = selectCategorie.childrenCompany;
    } else {
      typeUser = selectCategorie.childrenMasterUser;
    }

    const subSelected = typeUser.find((i) => i.code == item.item.code);

    const subSelectedIndex = typeUser.findIndex(
      (i) => i.code == item.item.code
    );

    subSelected.years = item.years;

    typeUser[subSelectedIndex] = subSelected;

    if (item.item.type == 1) {
      const cleanItem = editOptions[indiceCategorie].childrenCompany.filter(
        (cln) =>
          cln.code != typeUser[subSelectedIndex].code &&
          cln.type != typeUser[subSelectedIndex].type
      );

      selectCategorie.childrenCompany = [...cleanItem, ...typeUser];
    } else {
      const cleanItem = editOptions[indiceCategorie].childrenMasterUser.filter(
        (cln) =>
          cln.code != typeUser[subSelectedIndex].code &&
          cln.type != typeUser[subSelectedIndex].type
      );

      selectCategorie.childrenMasterUser = [...cleanItem, ...typeUser];
    }

    editOptions[indiceCategorie] = selectCategorie;

    setOptions([...editOptions]);
  };

  const setMonth = async (item) => {
    const editOptions = options;

    setErrorsPage([]);
    const indiceCategorie = editOptions.findIndex(
      (cat) => cat.code == item.sectionCode
    );

    const selectCategorie = editOptions[indiceCategorie];

    let typeUser = [];

    if (item.item.type == 1) {
      typeUser = selectCategorie.childrenCompany;
    } else {
      typeUser = selectCategorie.childrenMasterUser;
    }

    const subSelected = typeUser.find((i) => i.code == item.item.code);

    const subSelectedIndex = typeUser.findIndex(
      (i) => i.code == item.item.code
    );

    subSelected.months = item.months;

    typeUser[subSelectedIndex] = subSelected;

    if (item.item.type == 1) {
      const cleanItem = editOptions[indiceCategorie].childrenCompany.filter(
        (cln) =>
          cln.code != typeUser[subSelectedIndex].code &&
          cln.type != typeUser[subSelectedIndex].type
      );

      selectCategorie.childrenCompany = [...cleanItem, ...typeUser];
    } else {
      const cleanItem = editOptions[indiceCategorie].childrenMasterUser.filter(
        (cln) =>
          cln.code != typeUser[subSelectedIndex].code &&
          cln.type != typeUser[subSelectedIndex].type
      );

      selectCategorie.childrenMasterUser = [...cleanItem, ...typeUser];
    }

    editOptions[indiceCategorie] = selectCategorie;

    setOptions([...editOptions]);
  };

  const handleSelectItem = async (item) => {
    const editOptions = options;

    const indiceCategorie = editOptions.findIndex(
      (cat) => cat.code == item.sectionCode
    );

    const selectCategorie = editOptions[indiceCategorie];

    let typeUser = [];

    if (item.item.type == 1) {
      typeUser = selectCategorie.childrenCompany;
    } else {
      typeUser = selectCategorie.childrenMasterUser;
    }

    const subSelected = typeUser.find((i) => i.code == item.item.code);

    const subSelectedIndex = typeUser.findIndex(
      (i) => i.code == item.item.code
    );

    if (!subSelected.selected) {
      subSelected.selected = true;
    } else {
      subSelected.selected = false;
    }

    typeUser[subSelectedIndex] = subSelected;

    if (item.item.type == 1) {
      const cleanItem = editOptions[indiceCategorie].childrenCompany.filter(
        (cln) =>
          cln.code != typeUser[subSelectedIndex].code &&
          cln.type != typeUser[subSelectedIndex].type
      );

      selectCategorie.childrenCompany = [...cleanItem, ...typeUser];
    } else {
      const cleanItem = editOptions[indiceCategorie].childrenMasterUser.filter(
        (cln) =>
          cln.code != typeUser[subSelectedIndex].code &&
          cln.type != typeUser[subSelectedIndex].type
      );

      selectCategorie.childrenMasterUser = [...cleanItem, ...typeUser];
    }

    editOptions[indiceCategorie] = selectCategorie;

    setOptions([...editOptions]);
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarFull();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setSendLoadingPage(true);

    const sendIndustries = {
      adminAction: contextPage === "admin" ? true : false,
      id: contextPage === "admin" ? partnerId : null,
      industries: [...industries],
    };

    const response = await updateIndustry({
      admin: contextPage === "admin" ? true : false,
      form: sendIndustries,
    });
    if (contextPage === "full") {
      await newGetStepbar();
    }

    if (response.success) {
      if (contextPage === "full") {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: urls[PAGE_INDEX + 1],
        });
      }
      if (contextPage === "edit" || contextPage === "admin") {
        sendOk(true);
      }
    } else {
      if (contextPage === "full") {
        setOpenModalConfirm(true);
      }
      setErrorsPage(response?.errors);
    }
    setSendLoadingPage(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  useEffect(() => {
    const sendApi = [];

    options.map((cat) => {
      const catItens = [...cat.childrenCompany, ...cat.childrenMasterUser];

      catItens.map((subEnabled) => {
        if (subEnabled.selected) {
          sendApi.push({
            code: subEnabled.code,
            type: subEnabled.type,
            years: subEnabled.years,
            months: subEnabled.months,
          });
        }
      });
    });

    setIndustries(sendApi);
  }, [options]);

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        {/* <ScrollUpDown /> */}

        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("Industry")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  "Select the industries your company or agency leadership have expertise on"
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>
        <Row>
          {isLoadPage && (
            <Col xs={24} style={{ marginTop: 40 }}>
              <div>
                <Loading
                  sizeIcon={18}
                  text={I18n.get("Loading informations")}
                  sizeText={14}
                  align="center"
                />
              </div>
            </Col>
          )}

          {errorsPage && (
            <S.Error>{errorsPage?.Industry?.errorMessage}</S.Error>
          )}
          {options.map((item) => {
            const subs = [
              ...item?.childrenCompany,
              ...item?.childrenMasterUser,
            ];

            let isActive = subs.findIndex((i) => i.selected === true) != -1;

            return (
              <Col key={item.id} xs={24} style={{ marginTop: 40 }}>
                <ButtonExpandBoxSelect
                  sectionCode={item?.code}
                  label={item?.label}
                  description={item?.description}
                  active={isActive}
                  subs={subs}
                  full
                  setYear={(e) => setYear(e)}
                  setMonth={(e) => setMonth(e)}
                  handleSelectItem={(e) => handleSelectItem(e)}
                  error={Boolean(errorsPage.length)}
                />
              </Col>
            );
          })}
        </Row>

        <Row style={{ marginTop: 30 }}>
          {contextPage === "full" ? (
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get("Back")}
                theme="secondary"
                full
                onClick={handleBackPage}
              />
            </Col>
          ) : null}
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={
              contextPage === "edit" || contextPage === "admin"
                ? { span: 12, offset: 12 }
                : { span: 12, offset: 0 }
            }
          >
            <ButtonBpool
              text={
                contextPage === "edit" || contextPage === "admin"
                  ? I18n.get("Save")
                  : I18n.get("Save and Next")
              }
              theme="primary"
              full
              loading={sendLoadingPage}
              onClick={send}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get("Missing required information")}
        subBodyText={I18n.get(
          "Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?"
        )}
        okText={I18n.get("Yes")}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get("Close")}
        typeModal="isConfirm"
        // isDanger
      />
    </>
  );
};
