import React from 'react';

import * as S from './styles';

const ButtonIconLabel = ({ icon, label, bgColor, handleInput }) => {
  return (
    <S.Wrapper>
      <S.Button onClick={handleInput}>
        <S.Icon bgColor={bgColor}>
          <img alt="Icon" src={icon} />
        </S.Icon>
        <S.Label>{label}</S.Label>
      </S.Button>
    </S.Wrapper>
  );
};

export default ButtonIconLabel;
