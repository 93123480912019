import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";

import styled from "styled-components";

import { Result } from "antd";

import logo from "../../image/logoWOtext.svg";

import { SUPPLIER_API } from "../../actions/supplier/actions";
import { SupplierBillingStatusType } from "../../model";

import SupplierBillingInvoiceForm from "./supplier-billing-invoice-form";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_token: (data) => {
      return dispatch(SUPPLIER_API.get_token(data));
    },
    update_invoice: (data) => {
      return dispatch(SUPPLIER_API.update_invoice(data));
    },
  };
};

const Title = styled.div`
  h3 {
    font-weight: 700;
  }

  div.subscript {
    background-color: #000;
    width: 100px;
    height: 10px;
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
  }
`;

class SupplierBillingInvoicePage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      supplierLocale: null,
      supplierBilling: null,
      mustAbleOnlyBankAccountFields: false,
    };
  }

  async componentDidMount() {
    const data = {
      publicKey: this.props.match.params.id,
    };

    await this.props
      .get_token(data)
      .then(({ token, supplierLocale, task, error, supplierBilling }) => {
        I18n.setLanguage(supplierLocale);

        this.setState({
          token: token,
          task: task,
          error: error,
          supplierLocale: supplierLocale,
          supplierBilling: supplierBilling,

          // Quando o status for "BankAccountIsWrong", deve-se habilitar apenas os campos de conta bancária.
          // Demais campos, estarão carregados como readonly.
          mustAbleOnlyBankAccountFields:
            supplierBilling?.status ===
            SupplierBillingStatusType.BankAccountIsWrong,
        });
      });
  }

  render() {
    if (!this.state.supplierLocale) {
      console.error(
        `Erro ocorreu: Não foi carregado o "supplierLocale" na requisição.`
      );
      return null;
    }

    return (
      <div>
        <section className={`hero logged-page is-fullheight`}>
          <div className={`hero-head is-fixed`}>
            <nav className="navbar is-main navbar-main">
              <div className="container">
                <div
                  id="navbarMenu"
                  className="navbar-menu navbar-menu-new is-active"
                >
                  <div className="navbar-start"></div>
                  <div className="navbar-logo">
                    <div className="navbar-item">
                      <a href="/">
                        <img src={logo} alt="BPool" className="logo" />
                      </a>
                    </div>
                  </div>

                  <div className="navbar-end" style={{ zIndex: 999 }}></div>
                </div>
              </div>
            </nav>
          </div>
          <div className="container" style={{ marginTop: "7rem" }}>
            <Title>
              <h3>{I18n.get("Faturamento Extraordinário")}</h3>
              <div className="subscript" />
            </Title>

            {this.state.error == "NotFound" && (
              <>
                <Result
                  status="warning"
                  title={I18n.get("Faturamento inexistente.")}
                  subTitle={I18n.get(
                    "Qualquer dúvida, favor enviar um e-mail para financeiro@bpool.co"
                  )}
                />
              </>
            )}

            {this.state.error == "HasInvoice" && (
              <>
                <Result
                  status="warning"
                  title={I18n.get("Faturamento já enviado.")}
                  subTitle={I18n.get(
                    "Caso queria alterar alguma informação ou enviar mais algum documento, favor enviar um e-mail para financeiro@bpool.co"
                  )}
                />
              </>
            )}

            {this.state.token && (
              <>
                <SupplierBillingInvoiceForm
                  {...this.props}
                  action={this.props.update_invoice}
                  token={this.state.token}
                  supplierLocale={this.state.supplierLocale}
                  supplierBilling={this.state.supplierBilling}
                  mustAbleOnlyBankAccountFields={
                    this.state.mustAbleOnlyBankAccountFields
                  }
                />
              </>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierBillingInvoicePage);
