import React from 'react';

export const Icon1 = ({ active }) => (
  <svg width="46" height="47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="23.059"
      cy="23.19"
      rx="22.5"
      ry="22.987"
      fill={active ? '#1883ff' : '#E7E7E7'}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.547 10.42c-6.624 0-11.988 5.492-11.988 12.26 0 6.767 5.364 12.26 11.988 12.26 6.636 0 12.012-5.493 12.012-12.26 0-6.768-5.376-12.26-12.012-12.26Zm.01 22.068c-5.304 0-9.6-4.39-9.6-9.808 0-5.419 4.296-9.808 9.6-9.808 5.304 0 9.6 4.39 9.6 9.808 0 5.419-4.296 9.808-9.6 9.808Zm6-12.873c0 1.017-.804 1.838-1.8 1.838s-1.8-.82-1.8-1.838c0-1.018.804-1.84 1.8-1.84s1.8.822 1.8 1.84Zm-10.2 1.838c.996 0 1.8-.82 1.8-1.838 0-1.018-.804-1.84-1.8-1.84s-1.8.822-1.8 1.84c0 1.017.804 1.838 1.8 1.838Zm-1.931 7.97c.96-2.501 3.336-4.291 6.132-4.291s5.172 1.79 6.132 4.29H16.426Z"
      fill="#fff"
    />
  </svg>
);

export const Icon2 = ({ active }) => (
  <svg width="46" height="47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="23.248"
      cy="23.19"
      rx="22.5"
      ry="22.987"
      fill={active ? '#1883ff' : '#E7E7E7'}
    />
    <ellipse cx="27.948" cy="19.614" rx="1.8" ry="1.839" fill="#fff" />
    <ellipse cx="19.552" cy="19.614" rx="1.8" ry="1.839" fill="#fff" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.736 10.42c-6.624 0-11.988 5.492-11.988 12.26 0 6.767 5.364 12.26 11.988 12.26 6.636 0 12.012-5.493 12.012-12.26 0-6.768-5.376-12.26-12.012-12.26Zm.014 22.068c-5.304 0-9.6-4.39-9.6-9.808 0-5.419 4.296-9.808 9.6-9.808 5.304 0 9.6 4.39 9.6 9.808 0 5.419-4.296 9.808-9.6 9.808Zm6.143-3.066c-.96-2.513-3.348-4.291-6.143-4.291-2.797 0-5.184 1.778-6.145 4.291h2.005c.84-1.459 2.364-2.452 4.14-2.452 1.776 0 3.311.993 4.14 2.452h2.003Z"
      fill="#fff"
    />
  </svg>
);

export const Icon3 = ({ active }) => (
  <svg width="46" height="47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="23.436"
      cy="23.19"
      rx="22.5"
      ry="22.987"
      fill={active ? '#1883ff' : '#E7E7E7'}
    />
    <ellipse cx="28.136" cy="19.614" rx="1.8" ry="1.839" fill="#fff" />
    <ellipse cx="19.738" cy="19.614" rx="1.8" ry="1.839" fill="#fff" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.924 10.42c-6.625 0-11.989 5.492-11.989 12.26 0 6.767 5.364 12.26 11.989 12.26 6.636 0 12.011-5.493 12.011-12.26 0-6.768-5.376-12.26-12.012-12.26Zm.014 22.068c-5.304 0-9.6-4.39-9.6-9.808 0-5.419 4.296-9.808 9.6-9.808 5.304 0 9.6 4.39 9.6 9.808 0 5.419-4.296 9.808-9.6 9.808Zm-2.353-5.542a4.487 4.487 0 0 0 2.352.637c.876 0 1.656-.22 2.364-.65.603.696.612.712.62.724.007.012.012.02.592.686a6.487 6.487 0 0 1-3.576 1.08 6.468 6.468 0 0 1-3.564-1.067c.18-.209 1.068-1.239 1.212-1.41Z"
      fill="#fff"
    />
  </svg>
);

export const Icon4 = ({ active }) => (
  <svg width="46" height="47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="22.623"
      cy="23.19"
      rx="22.5"
      ry="22.987"
      fill={active ? '#1883ff' : '#E7E7E7'}
    />
    <ellipse cx="27.323" cy="19.614" rx="1.8" ry="1.839" fill="#fff" />
    <ellipse cx="18.925" cy="19.614" rx="1.8" ry="1.839" fill="#fff" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.111 10.42c-6.624 0-11.988 5.492-11.988 12.26 0 6.767 5.364 12.26 11.988 12.26 6.636 0 12.012-5.493 12.012-12.26 0-6.768-5.376-12.26-12.012-12.26Zm.014 22.068c-5.304 0-9.6-4.39-9.6-9.808 0-5.419 4.296-9.808 9.6-9.808 5.304 0 9.6 4.39 9.6 9.808 0 5.419-4.296 9.808-9.6 9.808Zm6.143-7.356c-.96 2.513-3.348 4.29-6.143 4.29-2.797 0-5.184-1.777-6.145-4.29h2.005c.84 1.458 2.364 2.451 4.14 2.451 1.776 0 3.311-.992 4.14-2.451h2.003Z"
      fill="#fff"
    />
  </svg>
);

export const Icon5 = ({ active }) => (
  <svg width="46" height="47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="22.811"
      cy="23.19"
      rx="22.5"
      ry="22.987"
      fill={active ? '#1883ff' : '#E7E7E7'}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.299 10.42c-6.625 0-11.989 5.492-11.989 12.26 0 6.767 5.364 12.26 11.989 12.26 6.636 0 12.011-5.493 12.011-12.26 0-6.768-5.376-12.26-12.012-12.26Zm.014 22.068c-5.304 0-9.6-4.39-9.6-9.808 0-5.419 4.296-9.808 9.6-9.808 5.304 0 9.6 4.39 9.6 9.808 0 5.419-4.296 9.808-9.6 9.808Zm5.998-12.873c0 1.017-.804 1.838-1.8 1.838s-1.8-.82-1.8-1.838c0-1.018.804-1.84 1.8-1.84s1.8.822 1.8 1.84Zm-10.198 1.838c.995 0 1.8-.82 1.8-1.838 0-1.018-.805-1.84-1.8-1.84-.997 0-1.8.822-1.8 1.84 0 1.017.803 1.838 1.8 1.838Zm10.33 3.679c-.96 2.5-3.335 4.29-6.131 4.29-2.796 0-5.172-1.79-6.132-4.29h12.264Z"
      fill="#fff"
    />
  </svg>
);
