import LogoParceiro from '../../components/Card/parceiro-logo.jpg';

export const realizadas = [
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Realizadas',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Realizadas',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Realizadas',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Realizadas',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Realizadas',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Realizadas',
    logo: LogoParceiro,
  },
];
