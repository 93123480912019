import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 32px;
  border-top: 1px solid #e8e8e8;

  h3 {
    margin-top: 32px;
    font-weight: 600;
    font-size: 24px;
  }
`;

export const Form = styled.div`
  margin-top: 32px;
`;

export const ListCertificates = styled.div`
  margin-top: 32px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const CancelEdit = styled.div`
  margin-right: 16px;

  button {
    background: transparent;
    border: none;
    border-bottom: 1px solid #515152;
    color: #515152;
    font-size: 14px;
    cursor: pointer;
  }
`;
