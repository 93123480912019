import styled from "styled-components";
import * as MediaQuery from "../../../utils/mediaQuery";

export const ContainerNews = styled.div`
   display: flex;
   flex: 0 1 100%;
   flex-wrap: wrap;
   justify-content: flex-start;
   gap: 12px;
`;

export const DivNews = styled.a`
   background-color: #FFF;
   flex: 0 1 32%;   
   border-radius: 10px;
   overflow: hidden;
   margin-top: 40px; 
   cursor: pointer;

   img {
      width: 100%;
   }
   ${() =>
   MediaQuery.IS_MOBILE() &&
   `
   flex: 0 1 100%;   
   `}
`;

export const DivContent = styled.div`
   padding: 10px 30px;
`;

export const ContentCategory = styled.p`
   font-size: 16px;
   line-height: 19px;
   color: #1883FF;
   font-weight: 600;
   margin-top: 10px;
`;

export const ContentTitle = styled.h1`
   font-size: 24px;
   line-height: 28px;
   font-weight: 600;
   color: #2F2F2F ;
   margin: 20px 0;
   cursor: pointer;
`;

export const ContentText = styled.p`
   font-size: 16px;
   line-height: 24px;
   margin: 22px 0;
   cursor: pointer;

   a{
      font-weight: 600;
      text-decoration: underline;
      color: #000000;
      margin-left: 7px;
   }
`;

export const DivImageNews = styled.div`
   height: 220px;
   overflow: hidden;
   border-radius: 10px 10px 0 0;

   picture {
      display: flex;
      height: 100%;
      align-items: center;

      img {
         flex: 1;
         height: 100%;
         object-fit: cover;
      }
   }
`;

export const LoaderDiv = styled.div`
   z-index: 99999999;
   position: absolute;
`;
