import { CHAT_NOTIFICATION } from './actions';

export const api_call = (data) => {

    return (dispatch, getState) => {

        dispatch({
            type: CHAT_NOTIFICATION,
            payload: data
        })        

    }
}

export default api_call;