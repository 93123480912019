import { Auth, Hub, I18n } from 'aws-amplify';

import { UPDATE } from './actions';

export const api_call = ({ locale }) => {
    return (dispatch, getState) => {

        const state = getState();

        Auth.currentAuthenticatedUser()
            .then((user) => {



                Auth.updateUserAttributes(user, {
                    'locale': locale
                });

                dispatch({
                    type: UPDATE,
                    payload: {
                        locale: locale
                    }
                });

                I18n.setLanguage(locale);

                Hub.dispatch(
                    'bp',
                    {
                        event: 'localeChange',
                        data: locale,
                    });

                // Força a atualizacao do cache local de atributos do cognito
                Auth.currentAuthenticatedUser({ bypassCache: true })
                    .then((user) => {

                    })
            });

    }
}

export default api_call;
