import React, { Component } from "react";
import { I18n } from 'aws-amplify';
import {
    Row,
    Col,
    Form,
    Icon,
    Spin,
    Input,
    Select,
    Button
} from 'antd';

import styled from 'styled-components';

const { Option } = Select;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`

class Exceptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            send: false
        }
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            this.setState({
                formState: values,
                send: true
            })

            if (!err) {
                this.props.action(values);
            }
        });
    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        }

        if (this.props.errors != prevProps.errors && Object.keys(this.props.errors).length == 0) {
            this.props.form.resetFields("partnerId");
            this.props.form.resetFields("defaultCommission");
        }

        if(this.props.exceptions.length > prevProps.exceptions.length && this.state.send){
            this.props.form.resetFields("partnerId");
            this.props.form.resetFields("defaultCommission");
        }
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const { partners } = this.props

        if (!partners) {
            return (
                <BoxSpin>
                    <Spin />
                </BoxSpin>
            )
        }


        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={4}>
                        <Form.Item
                            label={I18n.get("Parceiro")}>
                            {getFieldDecorator('partnerId', {
                                rules: [{ required: false, message: 'ops!' }],
                            })(
                                <Select
                                    className="SelectMin"
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >

                                    {
                                        partners.map((item,index) => {
                                            return <Option value={item.code} disabled={!item.enabled} className="SelectMin">{item.label}</Option>
                                        })
                                    }

                                    

                                </Select>
                            )}
                        </Form.Item>
                    </Col>
             


                    <Col sm={3}>
                        <Form.Item
                            label={I18n.get("Porcentagem")}>
                            {getFieldDecorator('defaultCommission', {
                                rules: [{ required: false, message: 'ops!' }],
                            })(
                                <Input 
                                    placeholder={I18n.get("% Padrão")}
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={1}>
                        <Form.Item className="labelClean" label="&nbsp;">
                            <Button
                                type="primary"
                                shape="round"
                                htmlType="submit"
                                disabled={!partners}
                                // loading={this.props.task.busy}
                            >
                                {I18n.get("Adicionar")}
                            </Button>
                        </Form.Item>
                    </Col>                    

                </Row>

            </Form>
        )
    }
}



const FormExceptions = Form.create({})(Exceptions);

export default FormExceptions;

