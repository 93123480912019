import styled, { css } from "styled-components";
import { Tag as TagAnt } from "antd";

const TagColorModifier = {
  primary: () => css`
    color: #ffffff;
    background-color: #1883ff;
    border-color: #1883ff;
  `,
  yellow: () => css`
    color: #c29800;
    background-color: #f1f5d5;
    border-color: #f1f5d5;
  `,
  green: () => css`
    color: #18ad89;
    background-color: #ebfffa;
    border-color: #ebfffa;
  `,
};

export const Tag = styled(TagAnt)`
  ${(props) =>
    props.theme ? TagColorModifier[props.theme] : TagColorModifier["primary"]}
`;
