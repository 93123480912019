import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { I18n } from "aws-amplify";

import FieldInput from "../../../../../components/UI/FieldInput";
import FieldDataPicker from "../../../../../components/UI/FieldDataPicker";
import { Button } from "../../../../../components/UI/Button";

import { Row, Col } from "antd";

const FormMilestones = ({ addMilestones, loading, isDisabled }) => {
  const [name, setName] = useState(null);
  const [estimate, setEstimate] = useState(null);
  const [errors, setErros] = useState({});

  const handleSend = () => {
    const data = {
      estimatedDate: estimate,
      name: name,
    };

    // tet
    addMilestones(data).then((response) => {
      if (response?.success) {
        setName("");
        setEstimate("");
      } else {
        setErros(response?.errors?.Milestone);
      }
    });
  };

  return (
    <S.StyleMain>
      <Row>
        <Col sm={9}>
          <S.Label>{I18n.get("Nome da Macro entrega")}</S.Label>
          <FieldInput
            onChange={(e) => {
              setName(e?.target?.value);
              setErros({});
            }}
            disabled={isDisabled}
            value={name}
            error={errors?.field == "Name" ? errors?.errorMessage : null}
          />
        </Col>
        <Col sm={9}>
          <S.Label>{I18n.get("Data estimada")}</S.Label>

          <FieldDataPicker
            onChange={(e) => {
              setEstimate(e);
              setErros({});
            }}
            disabled={isDisabled}
            value={estimate}
            error={
              errors?.field == "EstimatedDate" ? errors?.errorMessage : null
            }
          />
        </Col>
        <Col sm={6}>
          <S.ButtonSubmit>
            <Button
              text={I18n.get("Adicionar")}
              onClick={() => handleSend()}
              theme="primary"
              loading={loading}
              isDisabled={isDisabled || !name || !estimate}
            />
          </S.ButtonSubmit>
        </Col>
      </Row>
    </S.StyleMain>
  );
};

export default FormMilestones;
