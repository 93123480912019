import React from "react";
import { I18n } from "aws-amplify";

import Steps from "./_steps";

import LinkButton from "../../ui/link_button";

import { Modal, Button, Input, message } from "antd";

import Responsbible from "./_details-responsible";
import Terms from "./_details-terms";
import Company from "./_details-company";
import Address from "./_details-address";
import Brands from "./_details-brands";
import Exception from "./_details-exceptions";
import SignupLink from "./_details-singuplink";
import Settings from "./_details-settings";

export default class ClientDetailsView extends React.Component {
  constructor(props) {
    super();

    this.state = {
      name: "",
      email: "",
      nameAlias: "",
      phoneNumber: "",
      visible: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.props.get_exceptions(this.props.match.params.clientId);
  }

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.clientStatusesMap) return "";

    return this.props.bp.clientStatusesMap[value].label;
  }

  handleVisible = () => {
    this.setState({ visible: !this.state.visible });
  };

  handleUpdateMaster = () => {
    this.setState({ loading: true });
    const data = {
      id: this.props.match.params.clientId,
      name: this.state.name,
      email: this.state.email,
      nameAlias: this.state.nameAlias,
      phoneNumber: this.state.phoneNumber,
      prefix: "client",
    };

    this.props.update_master(data).then((response) => {
      if (response.data.success) {
        this.props.get_client(this.props.match.params.clientId);
        this.setState({
          visible: false,
          name: "",
          email: "",
          nameAlias: "",
          phoneNumber: "",
          loading: false,
        });
      } else {
        message.error(response.data.errors.Email.errorMessage);

        this.setState({
          loading: false,
        });
        this.setState({
          loading: false,
        });
      }
    });
  };
  render() {
    if (!this.props.bp || !this.props.bp.client) return null;

    const item = this.props.bp.client;

    return (
      <section className="section">
        <Steps {...this.props}></Steps>

        <div className="field is-grouped">
          <div className="control">
            <label className="label">&nbsp;</label>
            <LinkButton
              to={`/bpool/clientes/${item.id}/editar`}
              disabled={!this.props.auth.is_master}
              label={I18n.get("Editar status")}
            ></LinkButton>
          </div>

          <div className="control">
            <label className="label">&nbsp;</label>
            <LinkButton
              to={`/bpool/clientes/${item.id}/customizar`}
              disabled={!this.props.auth.is_master_or_bpool}
              label={I18n.get("Customizar")}
            ></LinkButton>
          </div>
        </div>

        {this.props.bp.client.status === 1 && (
          <div className="columns">
            <div className="column is-12">
              <SignupLink {...this.props} />
            </div>
          </div>
        )}

        <div className="columns">
          <div className="column is-12">
            <Responsbible {...this.props} onVisible={this.handleVisible} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Terms {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Company {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Address {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Exception {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Brands {...this.props} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Settings {...this.props} />
          </div>
        </div>
        <Modal
          title={I18n.get("Trocar usuário master")}
          visible={this.state.visible}
          okText={I18n.get("Salvar")}
          cancelText={I18n.get("Cancelar")}
          destroyOnClose={true}
          onOk={this.handleUpdateMaster}
          onCancel={this.handleVisible}
          footer={[
            <Button
              className="button bt-bpool black"
              loading={this.state.loading}
              onClick={this.handleUpdateMaster}
              disabled={!this.state.name || !this.state.email}
            >
              {I18n.get("Salvar")}
            </Button>,
          ]}
        >
          <p>
            <Input
              placeholder={I18n.get("Nome")}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </p>
          <br />
          <p>
            <Input
              placeholder={I18n.get("E-mail")}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </p>
          <br />
          <p>
            <Input
              placeholder={I18n.get("Apelido")}
              onChange={(e) => this.setState({ nameAlias: e.target.value })}
            />
          </p>
          <br />
          <p>
            <Input
              placeholder={I18n.get("Telefone")}
              onChange={(e) => this.setState({ phoneNumber: e.target.value })}
              addonBefore={
                this.props.bp.client &&
                this.props.bp.client.country &&
                this.props.bp.client.country.prefix
              }
            />
          </p>
        </Modal>
      </section>
    );
  }
}
