import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import ErrorPane from '../error-pane';

import { Task } from '../../utils/task';

import { BP_API } from '../../actions/bp/actions';

import Card from '../ui/card';

import constants from '../../constants';

import TextField from '../form/text-field';
import PasswordField from '../form/password-field';
import MaskedField from '../form/masked-field';
import SelectField from '../form/select-field';
import SubmitButtonField from '../form/submit-button-field';

const mapStateToProps = state => {
    return state.bp;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(BP_API.create_user(data));
        }
    };
};

class NewUserForm extends React.Component {
    constructor(props) {

        const item = props.bp && props.bp.user || {};
        const errors = item.errors || {};

        super();

        this.state = {
            form: {
                profile: {
                    label: I18n.get("Perfil"),
                    value: item.profile,
                    values: props.bp.profiles,
                    onChange: this.onChangeForm.bind(this, 'profile'),
                    successIcon: false,
                },
                name: {
                    label: I18n.get("Nome completo"),
                    value: item.name,
                    onChange: this.onChangeForm.bind(this, 'name'),
                    successIcon: false,
                },
                email: {
                    label: I18n.get("E-mail"),
                    value: item.email,
                    onChange: this.onChangeForm.bind(this, 'email'),
                    successIcon: false,
                },
                global: {
                    error: errors.global
                }
            },
            task: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {
            this.setState({ task: task });
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData() {

        var data = {};

        Object.keys(this.state.form).forEach((item) => {

            if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
                data[item] = this.state.form[item].value
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    onSave(e) {
        e.preventDefault();

        this.props.save(this.getData());
    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function() {
            this.props.save(this.getData());
        });
    }

    render() {

        return (
            <Card {...this.props} title={I18n.get("Novo usuário")}>

                <form onSubmit={this.submitForm.bind(this)}>

                    <SelectField {...this.state.form.profile} />

                    <TextField {...this.state.form.name} />

                    <TextField {...this.state.form.email} />

                    {this.state.task.errors && Object.keys(this.state.task.errors).length > 0 && <article className="message is-danger">
                        <div className="message-body">
                            <ul className="has-text-danger">
                                {Object.keys(this.state.task.errors).map((item, index) =>
                                    (<li key={index}>{this.state.task.errors[item].errorMessage} </li>)
                                )}
                            </ul>
                        </div>
                    </article>}

                    <SubmitButtonField
                        label={I18n.get("Salvar")}
                        onClick={this.submitForm.bind(this)}
                        task={this.state.task}
                    />

                </form>

            </Card>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewUserForm);
