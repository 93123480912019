
export function getQuotationStatus(auth, item = {}) {

    const reviseStr = (item.revise === true) ? ' (Revisar)' : '';

    if (item.isApproved === true)
        return 'Aprovado';

    if (item.isApproved === false)
        return "Não aprovado";

    if (auth.is_client || auth.is_client_user) {
        if (item.isOpen) {
            return `Em Aberto${reviseStr}`;
        }
        else {
            return "Aguardando Aprovação";
        }
    }
    else {
        if (item.isOpen) {
            return "Em Aberto";
        }
        else {
            return "Aguardando Aprovação Cliente";
        }
    }

    return '--';
}

export function getFileExtensionIcon(fileUrl) {
    const re = /(?:\.([^.]+))?$/;

    var lowerFileUrl = fileUrl.toLowerCase();
    var extension = re.exec(lowerFileUrl);

    switch (extension[1]) {
        case 'doc':
        case 'docx':
            return 'far fa-file-word';
        case 'xls':
        case 'xlsx':
            return 'far fa-file-excel';
        case 'ppt':
        case 'pptx':
            return 'far fa-file-powerpoint';
        case 'pdf':
            return 'far fa-file-pdf';
        case 'png':
        case 'gif':
        case 'bmp':
        case 'jpeg':
            return 'far fa-file-image';
        case '7z':
        case 'zip':
        case 'gz':
        case 'rar':
            return 'far fa-file-archive';
        default:
            return 'far fa-file';
    }
}
