import { NOTIFICATIONS } from './actions';

const api_call = () => {
  return (dispatch, getState) => {
    const payload = { action: 'notification-all-not-seen' };

    window.sendWsMessage(JSON.stringify(payload));
  };
};

export default api_call;
