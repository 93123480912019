import styled from 'styled-components';

export const ModalBackground = styled.div`
  border: none;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  background-color: #393939;
`;

export const FlexRowDiv = styled.div`
    display: flex;
    flex-flow: row nowrap
    height: calc(100vh - 200px);
    width: 100%;
    position: relative;

    & > .collapse-chat-button {
        position: absolute;

        top: 10px;
        left: 0;

        &.is-open{
            left: 320px;
            @media (max-width: 780px){
                left: calc(100% - 30px);
            }
        }

        width: 30px;
        height: 30px;
        background: #fff;
        color: #000;
        border: 1px solid #eee;
        line-height: 30px;
        z-index: 9999;

        i {
            position: relative;
            left: 8px;
        }
    }

`;

export const ChatListContainer = styled.section`
    padding: 20px;
    width: 350px;
    height:100%
    display: none;
    margin-left: 30px;
    background: #D8D8D8;

    &.is-open {
        display: block;
        height: 100%;
        padding: 0;

        @media (max-width: 780px) {
            width: 100%;
        }
    }
    max-height: 100%;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //     display: none;
    // }

    & > span {
        font-weight: 700;
        font-size: 1rem;
    }

    & > .chat-list {
        padding: 30px 0 20px 20px;
        height: calc(100% - 60px);
        overflow: auto;

        & > .chat-list-item {
            padding: 0;
            align-items: center;

            width: 100%;
            margin: 15px 4px;
            border-radius: 35px 0 0 35px;
            border: none;
            /* background: #eeeeee; */

            .chat-list-title {
                font-weight: 700!important;
                font-size: 14px;
                text-align: left;
                position: relative;
                top: 7px;
            }

            .nome-responsavel {
                font-size: 12px!important;
            }


            & > .avatar-big {
                height: 55px;
                width: 56px;
                background: #C4C4C4;
                border-color: #C4C4C4;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
            }

            &.active {
                color: #ffffff;
                background: #1883FF;

                 .nome-responsavel {
                    font-size: 12px;
                    color: #ffffff;
                }
            }
        }
    }



`;

export const ConversationContainer = styled.section`
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;

  &.is-open {
    width: calc(100% - 320px);
    border-left: 1px solid rgba(0, 0, 0, 0.11);
  }

  @media (max-width: 780px) {
    &.is-open {
      width: 100%;
      display: none;
    }
  }
`;

export const HeaderChatListContacts = styled.div`
  background: #464646;
  height: 75px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CloseChat = styled.button`
  background: transparent;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 10px;
  cursor: pointer;

  div {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
  }
`;

export const InputSearchContacts = styled.input`
  border: none;
  border-radius: 26px 0 0 26px;
  height: 52px;
  width: calc(100% - 40px);
  padding: 10px;
  line-height: 52px;
  background: #e7e7e7;
  position: relative;
  z-index: 99;

  :read-only {
    background-color: #e7e7e7;
  }
`;

export const HeaderChatMessage = styled.div`
  background: #464646;
  color: #fff;
  height: 75px;
  padding: 0 30px;
  display: flex;
  align-items: center;
`;

export const HeaderChatLogo = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 20px;
  position: relative;
  bottom: -30px;
  background: #c4c4c4;
  border-color: #c4c4c4;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 25%);
  border-radius: 50%;
  z-index: 97;

  img {
    border-radius: 30px;
  }
`;

export const HeaderChatContentInfos = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const HeaderChatInfos = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 7px 0;

  span {
    font-weight: 400;
  }
`;

export const HeaderChatActions = styled.div`
  width: 120px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonImg = styled.button`
  cursor: pointer;
  border: 0;
  background: none;
  width: 50px;
  padding: 0;

  img {
    width: 50px;
    border-radius: 25px;
  }
`;

export const MessageListContainer = styled.section`
  position: relative;
  height: calc(100% - 175px);
  background: #d8d8d8;

  @media (max-width: 768px) {
    height: calc(100% - 175px);
  }

  & > .message-list {
    padding: 5% 5% 0 5%;
    background: #d8d8d8;

    & > .message-group {
      & .message-info {
        & .message-text {
          border-radius: 14px;
          border: 1px solid #eeeeee;
          font-size: 1rem;
        }
      }
    }
  }

  & {
    .chat-file-link {
      font-size: 14px;
      color: #000;

      i {
        color: #1883ff;
        font-size: 22px;
      }
    }
  }
`;

export const TextComposerContainer = styled.section`
  width: 100%;
  min-height: 100px;

  @media (max-width: 768px) {
    min-height: 100px;
  }

  &,
  & > .text-composer {
    background: #eeeeee;
  }

  & .text-composer {
    padding: 20px 15px;
    background: #d8d8d8;
    border-color: #d8d8d8;

    @media (max-width: 768px) {
      padding: 20px 15px;
    }

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: space-between;

    & .row-input {
      width: 100%;
      align-items: center;
      background: #e3e3e3;
      border-radius: 10px;
      padding: 10px;
    }

    & textarea {
      padding: 0 15px;
      min-height: 36px !important;
      line-height: 36px !important;
      max-height: 96px !important;
      font-size: 0.8em !important;
      border-radius: 10px;

      @media (max-width: 780px) {
        min-height: 70px !important;
      }
    }
  }
`;

export const LoadingMessagesContainer = styled.section`
  width: 100%;
  height: 10%;
  opacity: 0.6;
  background: #eeeeee;
  position: absolute;
  bottom: 0;

  font-size: 25px;
  z-index: 9999;

  display: none;
  &.active {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
`;

export const LoadingIconAudioContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin-right: 5px;
  border-radius: 17.5px;

  i {
    color: #000000;
  }
`;
