import React from "react";
import { I18n } from "aws-amplify";
import * as S from "./styles";
import { Popover } from "antd";

const Steps = ({ itens, active, disabledTabs }) => {
  return (
    <S.StyleSteps>
      {itens.map((item) => {
        return (
          <S.StyleStep
            to={item.url}
            disabled={disabledTabs.indexOf(item.key) != -1}
            style={{ pointerEvents: disabledTabs.indexOf(item.key) != -1 ? "none" : "" }}
          >
            <S.StyleNumber
              active={active == item.key}
              disabled={disabledTabs.indexOf(item.key) != -1}
            >
              {item.key}
            </S.StyleNumber>
            <S.StyleLabel active={active == item.key}>
              {item.label}
            </S.StyleLabel>
          </S.StyleStep>
        );
      })}
    </S.StyleSteps>
  );
};

export default Steps;
