import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { LEADER_AWARD_CREATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_leader_create_award = (data) => {
    return (dispatch, getState) => {

        return axios.put(`${Config.API_BASE_URL}/leader/${data.Award.leaderId}/award`, data)
        .then(response => {

            dispatch({
                type: LEADER_AWARD_CREATE,
                payload: {
                    ...response.data,
                    errors: {},
                    task: {
                        result: true
                    }
                }
            });

        })
        .catch(error => {

            dispatch({
                type: LEADER_AWARD_CREATE,
                payload: {
                    errors: error.response.data,
                    task: {
                        result: false
                    }
                }
            });
        });
    };
}

export default api_leader_create_award;
