import React from "react";
import { I18n } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { Task } from "../../../utils/task";

import { notification } from "antd";

import MilestoneList from "../../shared/milestone-list";
import MilestoneForm from "../../shared/milestone-form";

import ViewMacros from "../../../modules/components/ViewMacros";

class ScheduleForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      projectId: props.match.params.projectId,
      form: {
        isSubmit: false,
      },
      task: {},
      errors: {},
    };

    this.milestoneFormRef = React.createRef();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.project && this.props.project.task;

    if (!prevState) return;
    if (!task) return;

    var nextState = Object.assign({}, prevState);

    if (task.id != prevState.task.id) {
      nextState.task = task;

      this.setState(nextState);
    }
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      extraId: this.props.match.params.extraId,
      quotationId: this.props.quotation.id,
    };

    return data;
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  createMilestone(values) {
    const { projectId, extraId } = this.props.match.params;

    var data = {
      projectId,
      extraId,
      quotationId: this.props.quotation.id,
      ...values,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.create_quotation_milestone(data);
    });
  }

  deleteMilestone(info) {
    const { projectId, extraId } = this.props.match.params;

    var data = {
      projectId,
      extraId,
      quotationId: this.props.quotation.id,
      id: info.milestoneId,
    };

    this.props.delete_quotation_milestone(data).then(({ task, errors }) => {
      if (task.result) {
        notification.success({
          message: I18n.get("Removido com sucesso!"),
          description: I18n.get("Macro entrega foi removida"),
          duration: 3,
        });
      }

      this.setState({ errors: errors });
    });
  }

  deleteFileMilestone = (info) => {
    const { projectId, extraId } = this.props.match.params;

    var data = {
      projectId,
      extraId,
      quotationId: this.props.quotation.id,
      milestoneId: info.milestoneId,
      fileId: info.fileId,
    };

    var task = new Task();

    task.start();

    this.props.delete_file_milestone_extra(data);
  };

  uploadFileMilestone = (info) => {
    const { projectId, extraId } = this.props.match.params;

    var data = {
      projectId,
      extraId,
      quotationId: this.props.quotation.id,
      milestoneId: info.milestoneId,
      files: info.files,
    };

    var task = new Task();

    task.start();

    this.props.upload_milestone_extra(data);
  };

  confirmMilestone = (info) => {
    const { projectId, extraId } = this.props.match.params;

    var data = {
      projectId,
      extraId,
      quotationId: this.props.quotation.id,
      milestoneId: info.milestoneId,
      files: info.files,
      prefix: info.prefix,
      confirm: info.confirm,
    };

    this.props.confirm_milestone_extra(data);
  };

  render() {
    let { quotation = {} } = this.props;

    let { milestones = [] } = quotation;

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    const { projectId, extraId } = this.props.match.params;

    return (
      <div>
        <h1 className="title">{I18n.get("Cronograma")}</h1>

        <br />
        <br />

        <div className="container-calendar">
          <div className="has-text-centered schedule-title">
            {I18n.get("Datas e Entregas")}
            <br />
          </div>

          <div style={{ padding: "1rem" }}>
            {canEdit && (
              <>
                <p>
                  {I18n.get(
                    "Por favor defina de 2 a 5 macro entregas que serão necessárias neste projeto com uma estimativa de data para cada um destes momentos. Isto servirá de suporte para as avaliações que serão feitas durante o projeto."
                  )}
                </p>

                <MilestoneForm
                  ref={this.milestoneFormRef}
                  milestones={milestones}
                  onCreate={this.createMilestone.bind(this)}
                  disabled={!canEdit}
                />
              </>
            )}

            <MilestoneList
              username={this.props.auth.username}
              isPartner={this.props.auth.is_partner}
              projectId={projectId}
              milestones={milestones}
              onDelete={this.deleteMilestone.bind(this)}
              deleteFileMilestone={this.deleteFileMilestone}
              canDelete={canEdit}
              uploadMilestone={this.uploadFileMilestone}
              upload_milestone={this.uploadFileMilestone}
              confirmMilestone={this.confirmMilestone}
              loading={this.state.task}
            />
          </div>
        </div>

        <br />
        <br />
      </div>
    );
  }
}

export default withRouter(ScheduleForm);
