import React, { Fragment } from "react";

import { I18n } from "aws-amplify";

import ProjectCart from "../../containers/project-cart-container";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import ReviewStep1 from "../_project-review-1";
import AssetBundle from "../_project-asset-bundle";
import ReviewStep4 from "../_project-review-4";
import ReviewStep5 from "../_project-review-5";
import ReviewStep5Client from "../_project-review-5-client";

import GlobalFileBox from "../../v2/pages/Client/CreateProject/components/GlobalFileBox";

import ViewMacros from "../../modules/components/ViewMacrosResume";

import TextAreaField from "../form/textarea-field";
import SubmitButtonField from "../form/submit-button-field";

import DownloadButton from "../form/download-button-field";

import { Row, Collapse, Col, Icon, Modal, Button } from "antd";

import AgreementForm from "./_agreement-form";

import WYSIWYGEditor from "../form/wysiwyg-editor";
const { Panel } = Collapse;

class ProjectDetailsForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {
        partnerNotes: {
          label: "",
          value: props.partnerNotes,
          onChange: this.onChangeForm.bind(this, "partnerNotes"),
          error: props.errors.partnerNotes,
          successIcon: false,
          visited: true,
        },
        clientNotes: {
          label: "",
          value: props.clientNotes,
          onChange: this.onChangeForm.bind(this, "clientNotes"),
          error: props.errors.clientNotes,
          successIcon: false,
          visited: true,
        },
      },
      task: {},
      sendInfo: false,
      loadingAproved: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value = this.props[item] || "";
        } else if (typeof nextState.form !== "undefined") {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }

    if (this.props.errors != prevProps.errors && this.props.errors) {
      this.setState({
        sendInfo: true,
      });
    }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].value = value;

      return nextState;
    });
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (
        typeof this.state.form[item] === "object" &&
        "value" in this.state.form[item]
      ) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  submitForm(hasApproved, e) {
    e.preventDefault();

    var data = this.getData();

    data.isSubmit = hasApproved;

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.update_definitions(data);
    });
  }

  getSelected() {
    return {
      bundleId: this.props.bundleId,
      variationId: this.props.variationId,
    };
  }

  selectBundle(e) {}

  onShowAgreement(e) {
    this.setState({ showAgreement: true });
  }

  onApprove() {
    var approve_task = new Task();

    approve_task.start();

    this.setState({ approve_task: approve_task });

    const data = Object.assign({}, this.getData(), { isSubmit: true });

    return this.props.update_definitions(data).then(({ task, errors }) => {
      this.setState({ approve_task: task, errors: errors });

      return { task, errors };
    });
  }

  saveGlobal = async (data) => {
    const dataSend = {
      ...data,
      projectId: this.props.match.params.projectId,
    };
    const response = await this.props.update_global(dataSend);

    var data = {
      clientId: this.props.client.clientId,
      projectId: this.props.match.params.projectId,
    };

    await this.props.get_project(data);
  };

  handleThreasholdAuthorization = async (authorization) => {
    this.setState({ loadingAproved: true });

    let data = {
      projectId: this.props.match.params.projectId,
      authorized: authorization,
    };

    const response = await this.props.projectThreasholdAuthorization(data);

    if (response) {
      this.setState({ loadingAproved: false });
    } else {
      this.setState({ loadingAproved: false });
    }
  };

  render() {
    const variationAssets = this.props.getGroupVariationAssets(
      this.props.bundle && this.props.bundle.specialism
    );

    const brandSelected = this.props.client?.brands?.find(
      (b) => b.id === this.props?.brandId
    );

    const productSelected = brandSelected?.products?.find(
      (p) => p.id === this.props.productId
    );

    const assetsCheck = this.props?.bundle.variations[0].assets;

    let hasIsManHour = false;

    for (let i = 0; i < assetsCheck.length; i++) {
      if (assetsCheck[i].isManHour === true) {
        hasIsManHour = true;
        break;
      }
    }

    const disabledBtn = hasIsManHour && !this.props.descriptiveHourMan;

    return (
      <div className="project-details-review" style={{ marginTop: "-90px" }}>
        {this.props.isRequiredAprovation &&
          !this.props.isApproved &&
          this.props.auth.is_client_user &&
          !this.props.success && (
            <Row>
              <Col sm={24}>
                <p className="alertIsRequiredAprovation">
                  {I18n.get(
                    "O escopo está sendo ajustado pelo parceiro e ainda precisará ser aprovado pelo time de procurement. Assim que aprovado você será notificado."
                  )}
                </p>
              </Col>
            </Row>
          )}

        {this.props.isRequiredAprovation &&
          this.props.isApproved &&
          this.props.auth.is_client_user &&
          !this.props.clientTermsUpdatedOn && (
            <Row>
              <Col sm={24}>
                <p className="alertIsRequiredAprovation">
                  {I18n.get(
                    "O escopo foi revisado e aprovado pelo time de procurement. Por favor, revise todas as informações para começar o projeto."
                  )}
                </p>
              </Col>
            </Row>
          )}

        {this.props.isRequiredAprovation &&
          this.props.isApproved &&
          this.props.auth.is_client && (
            <Row>
              <Col sm={24}>
                <p className="alertIsRequiredAprovation">
                  {I18n.get(
                    "Você aprovou esse escopo! Agora o responsável pelo projeto irá avaliar o briefing e milestones para iniciar o projeto."
                  )}
                </p>
              </Col>
            </Row>
          )}

        {/* isReEvaluate */}
        {this.props.isRequiredAprovation &&
          !this.props.isApproved &&
          this.props.isReEvaluate &&
          this.props.auth.is_client && (
            <Row>
              <Col sm={24}>
                <p className="alertIsRequiredAprovation">
                  {I18n.get(
                    "Você reprovou este escopo. O parceiro irá revisar de acordo com os seus comentários e enviar novamente para sua aprovação."
                  )}
                </p>
              </Col>
            </Row>
          )}
        {this.props.projectType === 0 &&
          productSelected &&
          productSelected?.global &&
          !this.props.globalAuthorization?.url && (
            <Row>
              <Col sm={24}>
                <GlobalFileBox
                  title={I18n.get("Atenção!")}
                  description={I18n.get(
                    "Esta é uma marca Global. Anexe aqui a aprovação de global para este projeto."
                  )}
                  username={this.props.auth.username}
                  action={this.saveGlobal}
                  file={this.props.globalAuthorization}
                />
              </Col>
            </Row>
          )}
        <Row>
          <Col sm={12}>
            <h1 className="title title-3">
              <span>3.</span> {I18n.get("Review do Projeto")}
            </h1>
          </Col>
          <Col sm={12} className="btns-inline">
            {this.props.pdfBundle && (
              <DownloadButton
                wColor
                LinkToDownload={this.props.pdfBundle}
                text={I18n.get("Download")}
              />
            )}

            {this.props.auth.is_client &&
              this.props.isRequiredAprovation &&
              !this.props.isReEvaluate &&
              !this.props.isApproved && (
                <Fragment>
                  <Button
                    onClick={() => this.handleThreasholdAuthorization(true)}
                    task={this.state.task}
                    className="button bt-bpool"
                    loading={this.state.loadingAproved}
                    style={{ margin: "0 15px" }}
                  >
                    {I18n.get("Aprovar procurement")}
                  </Button>

                  <Button
                    className="button bt-bpool black"
                    onClick={() => this.handleThreasholdAuthorization(false)}
                    loading={this.state.loadingAproved}
                  >
                    {I18n.get("Declinar procurement")}
                  </Button>
                </Fragment>
              )}

            {(this.props.isRequiredAprovation && this.props.isApproved) ||
            !this.props.isRequiredAprovation ? (
              <>
                {!this.props.success && (
                  <Fragment>
                    {(this.props.auth.is_client ||
                      this.props.auth.is_client_user) && (
                      <Fragment>
                        {this.props.partnerHasSubmittedDefinitions === true && (
                          <SubmitButtonField
                            label={I18n.get("DECLINAR ESCOPO")}
                            className="button bt-bpool grey"
                            onClick={this.submitForm.bind(this, false)}
                            task={this.state.task}
                          />
                        )}

                        <SubmitButtonField
                          label={I18n.get("Aprovar")}
                          onClick={this.onShowAgreement.bind(this)}
                          task={this.state.task}
                          disabled={
                            this.props.partnerHasSubmittedDefinitions !==
                              true ||
                            (this.props.projectType === 0 &&
                              productSelected?.global &&
                              !this.props.globalAuthorization) ||
                            (this.props.isRequiredAprovation &&
                              !this.props.isApproved)
                          }
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </>
            ) : null}
            {this.props.auth.is_partner && (
              <Fragment>
                <SubmitButtonField
                  label={I18n.get("Submeter o escopo final do projeto")}
                  onClick={this.submitForm.bind(this, true)}
                  task={this.state.task}
                  disabled={
                    this.props.partnerHasSubmittedDefinitions || disabledBtn
                  }
                />
              </Fragment>
            )}
          </Col>
        </Row>

        {this.state.sendInfo && Object.keys(this.props.errors).length > 0 && (
          <Row>
            <Col sm={24}>
              <div className="error-list-bpool errors-top">
                <ul>
                  {this.state.sendInfo &&
                    Object.keys(this.props.errors).map((item) => {
                      return (
                        <li>
                          <i className="fas fa-exclamation"></i>{" "}
                          {this.props.errors[item].errorMessage}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </Col>
          </Row>
        )}

        {(this.props.auth.is_client || this.props.auth.is_client_user) && (
          <Row>
            <Col sm={24} className="alertTop">
              <div className="alert-bpool">
                <p>
                  {I18n.get(
                    "Com o detalhamento do seu briefing o parceiro faz uma revisão no pacote selecionado (modificando assets caso necessário), sugere alguns milestones para o projeto e submete o escopo final. A plataforma te notificará assim que o parceiro submeter o escopo final e nesse momento o botão de aprovação de projeto é liberado."
                  )}
                </p>
              </div>
            </Col>
          </Row>
        )}

        {this.props.auth.is_partner && (
          <Row>
            <Col sm={24} className="alertTop">
              <div className="alert-bpool">
                <p>
                  {I18n.get(
                    "Nessa etapa a revisão detalhada do briefing do cliente, esclarecimentos e ajustes deverão ter sido feitos, bem como a proposta de milestones do projeto. Você deve agora revisar o projeto e submeter o escopo final para aprovação do cliente. Caso precise editar o pacote de assets baseado no entendimento mais profundo do briefing e conversas com o cliente, isso pode ser feito abaixo."
                  )}
                </p>
              </div>
            </Col>
          </Row>
        )}

        <Row>
          <Col sm={12}>
            <h1 className="title-bpool medium">{I18n.get("Informações")}</h1>

            <ReviewStep1 {...this.props} />

            <br />
            <br />

            <h1 className="title-bpool medium">
              {I18n.get("Escopo Criativo")}
            </h1>

            <div className="reviewed-media uplift-review listBundle">
              <AssetBundle
                {...this.props.bundle}
                {...this.props}
                assets={this.props.assets}
                bp={this.props.bp}
                selected={this.getSelected()}
                isExpanded={true}
                cartId={this.props.cartId}
                createCart={this.props.createCart}
                cartAssets={this.props.cartAssets}
                addItem={this.props.addItem}
                delItem={this.props.delItem}
                clearCart={this.props.clearCart}
                checkoutVariation={this.props.checkoutVariation}
                variationAssets={variationAssets}
                IsPartner={this.props.auth.is_partner}
                canExcludeAllAssets={true}
                IsDefinition={true}
                IsClient={this.props.auth.is_client_or_client_user == true}
                PricePartner={this.props.pricePartner}
                PricePartnerTax={this.props.pricePartnerTax}
                PricePartnerOtherTax={this.props.pricePartnerOtherTax}
                PriceClientWithDiscount={this.props.priceClientWithDiscount}
                PriceClientWithDiscountTax={
                  this.props.priceClientWithDiscountTax
                }
                PriceClientWithDiscountOtherTax={
                  this.props.priceClientWithDiscountOtherTax
                }
                PriceBPool={this.props.priceBPoolComission}
                PriceBPoolTax={this.props.priceBPoolComissionTax}
                PriceBPoolOtherTax={this.props.priceBPoolComissionOtherTax}
                pdfBundle={this.props.pdfBundle || null}
                isFull={true}
                currency={this.props.currency}
                projectStatus={this.props.status}
                assetTax={this.props.assetTax || 0}
                otherTax={this.props.otherTax || 0}
                projectAssets={this.props.project?.bundle?.variations}
              />
            </div>

            <br />
            <br />

            {this.props.auth.is_client_or_client_user && (
              <>
                <h1 className="title-bpool medium">
                  {I18n.get("Sobre o Parceiro")}
                </h1>

                <ReviewStep5 {...this.props} />
              </>
            )}

            {!this.props.auth.is_client_or_client_user && (
              <>
                <h1 className="title-bpool medium">
                  {I18n.get("Sobre o Cliente")}
                </h1>

                <ReviewStep5Client {...this.props} />
              </>
            )}

            <br />
            <br />

            <h1 className="title-bpool medium">
              {I18n.get("Observações importantes do Parceiro")}
            </h1>
            {this.props.auth.is_partner && (
              <TextAreaField
                placeholder={I18n.get("digite aqui seus comentários…")}
                {...this.state.form.partnerNotes}
              />
            )}

            <div className="card project-details-review-card">
              {!this.props.auth.is_partner && (
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      {/* <pre style={{ backgroundColor: '#ffffff', padding: '0px' }}>{this.props.partnerNotes}</pre> */}
                      <p>{this.props.partnerNotes}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <br />
            <br />

            <h1 className="title-bpool medium">
              {I18n.get("Observações importantes do Cliente")}
            </h1>
            {(this.props.auth.is_client || this.props.auth.is_client_user) && (
              <TextAreaField
                placeholder={I18n.get("digite aqui seus comentários…")}
                {...this.state.form.clientNotes}
              />
            )}

            <div className="card project-details-review-card">
              {!(
                this.props.auth.is_client || this.props.auth.is_client_user
              ) && (
                <div className="card">
                  <div className="card-content">
                    <div className="content">
                      <pre
                        style={{
                          backgroundColor: "#ffffff",
                          padding: "0px",
                          width: "100%",
                        }}
                      >
                        {this.props.clientNotes}
                      </pre>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <br />
            <br />
          </Col>
          <Col sm={12}>
            <h1 className="title-bpool medium">{I18n.get("Detalhes")}</h1>

            <div className="uplift">
              <div className="card">
                <div className="card-content">
                  <ReviewStep4 {...this.props} />
                </div>
              </div>
            </div>

            <br />
            <br />

            <h1 className="title-bpool medium">{I18n.get("Briefing")}</h1>
            <div className="card">
              <div className="card-content painelbriefing">
                <Collapse accordion>
                  {this.props.briefings &&
                    Object.keys(this.props.briefings).map((code, index) => {
                      const item = this.props.briefings[code];

                      const file =
                        this.props.files &&
                        this.props.files.find((f) => item.fileId == f.id);

                      return (
                        <Panel header={item.label} key={index}>
                          {file && (
                            <p className="fileBriefing">
                              <strong>{I18n.get("Arquivo:")}</strong> <br />
                              <a href={file.url} download>
                                <Icon type="file-text" theme="filled" />{" "}
                                {file.name}
                              </a>
                            </p>
                          )}
                          <br />
                          <WYSIWYGEditor
                            content={item.content}
                            readOnly={true}
                          />
                        </Panel>
                      );
                    })}
                </Collapse>
              </div>
            </div>

            <br />
            <br />

            <h1 className="title-bpool medium">{I18n.get("Macro entregas")}</h1>

            <div className="card project-details-review-card">
              <div className="card-content">
                <div className="content">
                  <ViewMacros
                    project={this.props.project}
                    create={this.props.create_milistones}
                    delete={this.props.delete_milistones}
                    update={this.props.update_milestones_mensal}
                    isPartner={this.props.auth.is_partner}
                    errors={this.props.errors && this.props.errors}
                    isReview={true}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <br />

        {this.state.sendInfo && Object.keys(this.props.errors).length > 0 && (
          <Row>
            <Col sm={24}>
              <div className="error-list-bpool">
                <ul>
                  {this.state.sendInfo &&
                    Object.keys(this.props.errors).map((item) => {
                      return (
                        <li>
                          <i className="fas fa-exclamation"></i>{" "}
                          {this.props.errors[item].errorMessage}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </Col>
          </Row>
        )}

        <Row>
          <Col sm={24} className="btns-inline">
            {this.props.auth.is_client &&
              this.props.isRequiredAprovation &&
              !this.props.isApproved && (
                <Fragment>
                  <Button
                    onClick={() => this.handleThreasholdAuthorization(true)}
                    task={this.state.task}
                    className="button bt-bpool"
                    loading={this.state.loadingAproved}
                    style={{ margin: "0 15px" }}
                  >
                    {I18n.get("Aprovar procurement")}
                  </Button>

                  <Button
                    className="button bt-bpool black"
                    onClick={() => this.handleThreasholdAuthorization(false)}
                    loading={this.state.loadingAproved}
                  >
                    {I18n.get("Declinar procurement")}
                  </Button>
                </Fragment>
              )}

            {(this.props.isRequiredAprovation && this.props.isApproved) ||
            !this.props.isRequiredAprovation ? (
              <>
                {!this.props.success && (
                  <Fragment>
                    {(this.props.auth.is_client ||
                      this.props.auth.is_client_user) && (
                      <Fragment>
                        {this.props.partnerHasSubmittedDefinitions === true && (
                          <SubmitButtonField
                            label={I18n.get("DECLINAR ESCOPO")}
                            className="button bt-bpool grey"
                            onClick={this.submitForm.bind(this, false)}
                            task={this.state.task}
                          />
                        )}

                        <SubmitButtonField
                          label={I18n.get("Aprovar")}
                          onClick={this.onShowAgreement.bind(this)}
                          task={this.state.task}
                          disabled={
                            this.props.partnerHasSubmittedDefinitions !==
                              true ||
                            (this.props.projectType === 0 &&
                              productSelected?.global &&
                              !this.props.globalAuthorization) ||
                            (this.props.isRequiredAprovation &&
                              !this.props.isApproved)
                          }
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </>
            ) : null}
            {this.props.auth.is_partner && (
              <Fragment>
                <SubmitButtonField
                  label={I18n.get("Submeter o escopo final do projeto")}
                  onClick={this.submitForm.bind(this, true)}
                  task={this.state.task}
                  disabled={
                    this.props.partnerHasSubmittedDefinitions || disabledBtn
                  }
                />
              </Fragment>
            )}
          </Col>
        </Row>

        <Modal
          visible={this.state.showAgreement}
          width={600}
          footer={null}
          onCancel={(e) => this.setState({ showAgreement: false })}
        >
          <AgreementForm
            {...this.props}
            onApprove={this.onApprove.bind(this)}
            closeModal={(e) => this.setState({ showAgreement: false })}
          />
        </Modal>
      </div>
    );
  }
}

export default ProjectCart(ProjectDetailsForm);
