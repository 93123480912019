import styled from "styled-components";

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const WrapperBoxInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
`;

export const Box = styled.div`
  width: 100%;
  max-width: 346px;
  min-height: 185px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.101557);
  border-radius: 10px;
  padding: 15px;
`;

export const BoxTitleInfos = styled.div`
  font-family: "HelveticaNeue", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #2f2f2f;
`;

export const BoxInfos = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;

  ul {
    margin: 0;
    padding: 0;

    li {
      font-family: "HelveticaNeue", sans-serif;
      list-style: none;
      font-size: 16px;
      line-height: 28px;
      color: #000000;

      strong {
        font-weight: 700;
      }
      span {
        padding-left: 5px;
        font-weight: 400;
      }
    }
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 150px;

  a,
  button {
    margin-left: 10px;
  }
`;

export const WrapperSteps = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  div {
    ::before {
      width: 85%;
      height: 2px;
      background: #000;
      position: absolute;
      top: 9px;
      content: "";
      display: block;
      left: 55%;
      margin-left: -45%;
    }
  }
`;
