import styled from 'styled-components';

export const TitleSectionForm = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-bottom: solid 1px #000;
  margin-bottom: 40px;
  width: max-content;
`;

export const WrapperRadioGroup = styled.div`
  .ant-radio-wrapper {
    .ant-radio-inner {
      border: solid 2px #ccc;

      ::after {
        background-color: #1883ff;
      }
    }
  }
  .ant-radio-wrapper-checked {
    .ant-radio-inner {
      border: solid 2px #1883ff;
    }
  }
`;
