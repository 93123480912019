import styled from 'styled-components';

export const FixedChat = styled.button`
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: none;
  background: #1883ff;
  position: fixed;
  right: 18px;
  bottom: ${(props) => `${props.height / 2}px;`};
  margin-top: -20px;
  height: 65px !important;
  width: 65px;
  border-radius: 100px !important;
  gap: 5px;
  z-index: 97;
  cursor: pointer;
`;

export const ChatWrapper = styled.div`
  width: 1200px;
  height: 520px;
`;

export const ContainterInputSearch = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ImgInputSearch = styled.div`
  width: 40px;
  background: #e7e7e7;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 0 26px 26px 0;
`;

export const PopupCalendarOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
`;

export const PopupCalendar = styled.div`
  position: absolute;
  width: 225px;
  height: 86px;
  background: #ffffff;
  top: 0;
  margin-top: 70px;
  margin-left: -170px;
  z-index: 999;
  border-radius: 10px 0px 10px 10px;
  border: 1px solid;
  border-color: #dbdbdb;

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    padding: 10px 15px;

    li {
      a {
        font-size: 16px;
        text-decoration: none;
        color: #000000;

        img {
          margin-right: 10px;
        }
      }

      button {
        font-size: 16px;
        text-decoration: none;
        color: #000000;
        background: transparent;
        border: none;
        padding: 0;
        cursor: pointer;

        img {
          margin-right: 10px;
        }
      }
    }
  }
`;

export const ChatEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #ffffff;
  }
`;
