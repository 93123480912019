import React, { Fragment } from 'react';

import { I18n } from 'aws-amplify';

import Briefing from '../_project-briefing';
import DownloadButton from '../form/download-button-field';

import EventDetails from '../_project-details-eventCustom';
import BPitchDetails from '../_project-details-bpitch';

class BriefingForm extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {

        const { disableTitle = false } = this.props;

        return (
            <div>

                {
                    !disableTitle && (
                        <Fragment>
                            <h1 className="title title-2">{I18n.get("Briefing")}</h1>
                            <br /> <br />
                        </Fragment>

                    )
                }

                {this.props.isCreative && <Briefing {...this.props} />}

                {this.props.isCustomEvent && <div>

                    <EventDetails {...this.props} />

                </div>}


                {this.props.isBPitch && <div>

                    <BPitchDetails {...this.props} />

                </div>}


                <br /><br />

                {this.props.pdfBriefing && this.props.pdfBriefing.length > 0 &&
                    <DownloadButton LinkToDownload={this.props.pdfBriefing} />}

                <br /><br />

            </div>
        );
    }
}

export default BriefingForm;
