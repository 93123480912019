import React from 'react';

import { I18n } from 'aws-amplify';

export default class Carousel extends React.Component {
    constructor(props) {
        super();
        const carouselClassId = `carousel-${new Date().getTime()}`;
        this.state = {
            carouselClassId: carouselClassId
        };
    }

    static defaultProps = {
        size: 1,
    }

    attach(){
        if (this.props.items && this.props.items.length) {
            window.bulmaExtensions.bulmaCarousel.attach(`.${this.state.carouselClassId}`, {
                size: this.props.size
            });
        }
    }

    componentDidMount() {
        this.attach();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.attach();
    }

    render() {
        var items = this.props.items || [];
        if (!items.length) {
            return (
                <figure className="image">
                    <img src='https://bpool.co/images/noBanner.png' />
                </figure>
            );
        }
        if (items.length == 1) {
            return (
                <div>
                    {items[0]}
                </div>
            );
        }
        return (
            <div className={`carousel ${this.state.carouselClassId} carousel-animated carousel-animate-slide`}>
                <div className="carousel-container">
                    {items.map((item, index) => {
                        const className = 'carousel-item ' + (index == 0 ? 'is-active' : '');
                        return (<div key={index} className={className}>
                            {item}
                        </div>);
                    })}
                </div>
                <div className="carousel-navigation is-centered">
                    <div className="carousel-nav-left">
                        <i className="fas fa-chevron-left" aria-hidden="true"></i>
                    </div>
                    <div className="carousel-nav-right">
                        <i className="fas fa-chevron-right" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        )
    };
}
