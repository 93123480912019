import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Info = styled.div`
  width: 100%;
  ${(props) => (props?.sameLine ? "display: flex;" : null)};
  ${(props) => (props?.sameLine ? "align-items: center;" : null)};

  label {
    line-height: 20px;
    ${(props) => (props?.bigger ? "font-size: 20px;" : "font-size: 14px;")};
    margin-bottom: 4px;
    font-weight: 600;
  }
  p {
    line-height: 20px;
    ${(props) => (props?.bigger ? "font-size: 20px;" : "font-size: 14px;")};
    margin: 0;
    ${(props) => (props?.sameLine ? "margin-left: 8px;" : null)};
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-infos {
    height: 20px;
    margin-bottom: 4px;

    li {
      height: 100%;
    }
  }
`;
