import styled from "styled-components";

export const Wrapper = styled.div``;

export const Title = styled.div`
  margin-bottom: 24px;

  h3 {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
  }
`;
