import React from "react";
import { Select, InputNumber, Button } from "antd";
import FormatCurrency from "../ui/format-currency";
import { I18n } from "aws-amplify";

const { Option } = Select;

class ItemAssetCart extends React.Component {
  state = {
    initTotal: this.props.item.total,
    newTotal: this.props.item.total,
    loading: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.item != this.props.item) {
      this.setState({
        initTotal: this.props.item.total,
        newTotal: this.props.item.total,
      });
    }
  }

  handleAddItens = async (item) => {
    this.setState({ loading: true });
    const cleanArray = this.props.cart.filter(
      (i) => i.asset.code != item.asset.code
    );

    const newArray = cleanArray.map((c) => {
      return {
        code: c.asset.code,
        quantity: c.total,
      };
    });
    const data = {
      projectId: this.props.projectId,
      assets: [
        ...newArray,
        {
          code: item.asset.code,
          quantity: this.state.newTotal,
        },
      ],
    };

    await this.props.addItem(data).then((response) => {
      if (response) {
        this.props.getProject();
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  deleteItem = async (item) => {
    this.setState({ loading: true });
    const cleanArray = this.props.cart.filter(
      (i) => i.asset.code != item.asset.code
    );

    const newArray = cleanArray.map((c) => {
      return {
        code: c.asset.code,
        quantity: c.total,
      };
    });
    const data = {
      projectId: this.props.projectId,
      assets: [
        ...newArray,
        {
          code: item.asset.code,
          quantity: 0,
        },
      ],
    };

    await this.props.addItem(data).then((response) => {
      if (response) {
        this.props.getProject();
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { item, currency, delItem, addItem } = this.props;

    return (
      <div
        data-code={item.asset.code}
        data-label={item.asset.label}
        data-price={item.asset.price}
        data-multiple={item.total}
        data-complexity={item.asset.complexity}
        data-complexity-id={item.asset.complexityId}
        // data-price-with-tax={item.asset.price}
        // data-tax-perc={assetTax}
      >
        <div className="customize-cart-dropdown-item">
          <span className="cart-item-delete">
            <a onClick={(e) => delItem(item, e)}>X</a>
          </span>

          <span className="cart-item-name">
            <span data-label={item.asset.label} style={{ fontSize: 14 }}>
              {item.asset.label}
            </span>

            {item.asset.complexityId && (
              <>
                <br />{" "}
                <span className="complexBadge2">{item.asset.complexityId}</span>{" "}
                {item.asset.complexity}
              </>
            )}
          </span>

          <span className="cart-item-total">
            <span
              className="cart-item-total-num2"
              style={{ margin: "0 15px", width: 65 }}
            >
              {/* {item.total} */}

              <InputNumber
                size="large"
                min={1}
                value={this.state.newTotal}
                onChange={(e) => this.setState({ newTotal: e })}
              />
            </span>
          </span>
          <span className="cart-item-amount">
            <FormatCurrency amount={item.asset.price} currency={currency} />
          </span>
        </div>
        {this.state.newTotal != this.state.initTotal && (
          <div className="btnAtualizar">
            <a
              className="button bt-bpool black full"
              onClick={() => this.setState({ newTotal: this.state.initTotal })}
            >
              {I18n.get("Cancelar")}
            </a>
            <Button
              className="button bt-bpool primary full"
              onClick={() => this.handleAddItens(item)}
              loading={this.state.loading}
            >
              {I18n.get("Atualizar quantidade")}
            </Button>
          </div>
        )}
        <div className="borderBottomItem" />
      </div>
    );
  }
}

export default ItemAssetCart;
