import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import New from './_user-new';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_users: role => {
            dispatch(BP_API.get_users(role));
        },
    };
};

class UserNewAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li><NavLink to="/bpool/usuarios" className="is-black">{I18n.get("Usuários")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/usuarios/novo" className="is-black">{I18n.get("Novo")}</NavLink></li>
                    </ul>
                </nav>

            <div className="columns">

                <div className="column is-6">

                    <New {...this.props} />

                </div>

            </div>

        </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNewAdmin);
