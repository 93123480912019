import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  ${(props) => (props?.hasMenu ? null : "background-color: #fff;")};
  ${(props) => (props?.hasMenu ? "display: grid;" : null)};
  ${(props) => (props?.hasMenu ? "grid-template-columns: 315px 1fr;" : null)};
`;

export const Content = styled.div`
  padding: 24px;
  ${(props) => (props?.hasMenu ? "background-color: #fff;" : null)};
`;

export const MenuWrapper = styled.div`
  padding: 0 24px 24px 24px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 20px;
    background-color: #000000;
    color: #ffffff;
    margin-bottom: 24px;
  }

  ul {
    li {
      margin-top: 24px;

      a {
        color: #000000;
        font-size: 16px;
      }
      :first-child {
        margin-top: 0;
      }
    }
  }
`;
