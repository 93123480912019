import React from 'react';

import { I18n } from 'aws-amplify';

import TooltipField from './tooltip-field';

import MarkAsRequiredOrOptional from './mark-as-required-or-optional';

export default class CheckboxField extends React.Component {
    constructor(props) {
        super();
    }

    static defaultProps = {
        checkmarkStyle: {
        }
    }

    onChange(e) {

        this.props.onChange(e);

        // Workaround para o blur
        if (this.props.onBlur) {
            setTimeout(() => {
                this.props.onBlur();
            }, 200);
        }
    }

    render() {

        const { checkmarkStyle } = this.props;

        const createCheckbox = (item, checked) => {

            const Checkbox = (<label className="checkbox" style={{ marginLeft: '0.5em', minHeight: '20px' }}>
                <input type="checkbox" name={this.props.name} onChange={this.onChange.bind(this)} value={item.code} checked={checked} className={isError ? "is-danger" : ""} disabled={this.props.disabled || false} />
                <span className="checkmark" style={checkmarkStyle}></span>
                <p>&nbsp;{item.label}</p>
            </label>);

            return Checkbox;
        }

        const isError = !!this.props.value && this.props.error;

        return (<div className="field">
            <label className="label is-small">
                {this.props.label} <MarkAsRequiredOrOptional {...this.props} />
                {this.props.labels && this.props.labels[this.props.labelKey]}
                <TooltipField tooltip={this.props.tooltip} />
            </label>
            <div className="control" style={{ paddingTop: '1rem' }}>
                {this.props.values && this.props.values.map((item, index) => {

                    const checked = Array.isArray(this.props.value) && this.props.value.indexOf(item.code) > -1;

                    if (!item.field) {

                        const itemStyle = {
                            display: this.props.isHorizontal ? 'inline-block' : 'block',
                            clear: 'both'
                        }

                        return (<div key={index} style={itemStyle}>

                            {createCheckbox(item, checked)}

                            {checked && item.children && item.children.map((sub) => {

                                const subChecked = Array.isArray(this.props.value) && this.props.value.indexOf(sub.code) > -1;

                                return (<div style={{ paddingLeft: '2.5rem' }}>{createCheckbox(sub, subChecked)}</div>);
                            })}
                        </div>);
                    }
                    else {
                        return (
                            <div key={index} style={{ clear: 'both' }}>
                                <div style={{ float: 'left', clear: 'none', paddingTop: '0.25rem', minWidth: '50px' }}>
                                    {createCheckbox(item, checked)}
                                </div>

                                <div style={{ float: 'left', clear: 'none', paddingLeft: '1rem' }}>
                                    {item.field}
                                </div>
                            </div>);
                    }

                })}

            </div>

            {isError && <p className="help is-danger">
                <br style={{ clear: 'both' }} />
                {this.props.error.errorMessage}
            </p>}
        </div>)
    }
}
