import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const usePo = () => {
  const getPo = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${id}/review`,
      config
    );

    return data;
  };

  const getBillings = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/billing/?projectId=${id}`,
      config
    );

    return data;
  };

  const getNewBillings = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${id}/billings`,
      config
    );

    return data;
  };

  const addPo = async (dataPo) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/billing`,
      dataPo,
      config
    );

    return data;
  };

  const addNewPo = async (dataPo) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project/billings`,
      dataPo,
      config
    );

    return data;
  };

  const updateCodePo = async (dataPo) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/client-project/${dataPo?.projectId}/billings/updatePOCode`,
      dataPo,
      config
    );

    return data;
  };

  const startProject = async (projectId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/start`,
      projectId,
      config
    );

    return data;
  };

  return {
    getPo,
    getBillings,
    getNewBillings,
    addPo,
    addNewPo,
    updateCodePo,
    startProject,
  };
};
