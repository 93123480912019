import { ADD_CHAT_NOTIFICATION, UPDATE_CHAT_NOTIFICATION } from '..';

export const addChatNotification = (payload) => ({
  type: ADD_CHAT_NOTIFICATION,
  payload,
});

export const updateChatNotification = (payload) => ({
  type: UPDATE_CHAT_NOTIFICATION,
  payload,
});
