import React from 'react';

import { I18n } from 'aws-amplify';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import BasicInfoForm from './basic-info-form';

import SubHeader from './__sub-header';

import {
    Row,
    Col,
} from 'antd';


class NewProjectStep1 extends React.Component {
    constructor(props) {
        super();

        this.state = {};
    }

    render() {

        const logo = this.props.client && this.props.client.company && this.props.client.company.logo;

        const { isCreative, isEvent, isBPitch, customEvent = {}, bPitch = {}, isCustomEvent } = this.props;

        const subHeaderProps = { isCreative, isEvent, isBPitch, customEvent, bPitch, isCustomEvent };

        return (
            <FillForm>
                <div className="hero-body has-background-white for-np uplift-hero-1">
                    <SubHeader projectId={this.props.projectId} active={1} logo={logo} {...subHeaderProps} />
                </div>
                <div className="client-step client-step-1 uplift new-project-page-custom-bg uplift-fullheight-bg has-background-white topPadding">
                    <div className="">
                        <div className="container client-container-1 uplift-step-1">
                            <div className="column is-12">
                                <Row>
                                    <Col sm={24}>
                                        <h1 className="title-bpool big">{I18n.get("Sobre o Projeto")}</h1>
                                    </Col>
                                </Row>
                                <BasicInfoForm {...this.props} />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(NewProjectStep1);
