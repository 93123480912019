import React from 'react';

import { I18n } from 'aws-amplify';

export default class TooltipField extends React.Component {

    constructor(props) {
        super();
        this.state = {
            active: true,
        };
        this.closeTooltip = this.closeTooltip.bind(this);
    }

    closeTooltip() {
        this.setState({ active: false });
    }

    render() {

        const tooltiptxt = this.props.tooltip || '';
        const hasTooltip = tooltiptxt && tooltiptxt.length > 1;
        const multi = tooltiptxt && tooltiptxt.length > 60 ? 'is-tooltip-multiline' : '';
        const wq = this.props.withoutquestionmark && this.props.withoutquestionmark === "true" ? true : false;
        const isFixed = this.props.isfixed && this.props.isfixed === "true" ? 'is-tooltip-active' : '';

        const classfigure = this.props.classfigure && this.props.classfigure.length > 0 ? this.props.classfigure : 'fas fa-question-circle';
        const classfigurecolor = this.props.classfigurecolor && this.props.classfigurecolor.length > 0 ? this.props.classfigurecolor : '';

        const pStyle = {
            color: classfigurecolor
          };

        let ori = '';

        switch(this.props.orientation || '') {
            case 'top':
                ori = 'is-tooltip-top';
                break;
            case 'bottom':
                ori = 'is-tooltip-bottom';
                break;
            case 'left':
                ori = 'is-tooltip-left';
                break;
            case 'right':
                ori = 'is-tooltip-right';
                break;
            default:
                ori = 'is-tooltip-right';
        }

        if(hasTooltip) {
            return (
                <div className={`icon tooltip ${ori} ${multi} ${isFixed} ${this.props.canClose && 'can-close'} ${this.props.canClose && !this.state.active && 'is-closed'}`} data-tooltip={tooltiptxt} >
                    <span className="tooltip-arrow"></span>
                    { this.props.canClose && <span className="tooltip-close" onClick={this.closeTooltip}></span> }
                    {!wq && <i className={classfigure} style={pStyle} ></i>}
                </div>
            );
          } else {
            return (
              <div>
              </div>
            );

          }
    }
}

