import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-upload.ant-upload-drag {
    height: 100px;
    background: #ffffff;
  }

  .ant-upload.ant-upload-drag .ant-upload-btn {
    padding: 30px;
  }

  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .ant-upload-list.ant-upload-list-text {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;

    :before,
    :after {
      display: none;
    }
  }

  .ant-upload-list-item-info {
    background-color: #fafafa;
    margin: 0 5px;

    span {
      padding: 0 5px;

      .anticon-loading {
        display: none;
      }
    }

    .ant-upload-list-item-name {
      margin-left: 15px;
    }
  }
`;

export const ContentUpload = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const Infos = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
`;

export const IconUpload = styled.div`
  margin-right: 15px;

  svg {
    width: 36px;
    height: 36px;
    color: #0095ff;
  }
`;

export const IconUploadTexts = styled.div`
  text-align: left;
`;

export const ButtonContent = styled.div`
  display: flex;
  flex: 1;

  button {
    cursor: pointer;
    width: max-content;
    height: 40px;
    padding: 0 15px;
    background: #0095ff;
    color: #fff;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 700;
    border: none;
    position: relative;
    z-index: 1;
  }
`;

export const FileInternal = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  padding: 0 12px 0 12px;
  transition: background-color 0.3s;
  margin: 10px 5px 0 5px;

  button {
    background-color: transparent;
    border: none;
    padding: 0 5px;
    font-size: 14px;
    cursor: pointer;
  }

  :hover {
    background-color: #e6f7ff;
  }
`;
