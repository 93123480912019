import React from "react";
import { I18n } from "aws-amplify";
import FlagRS from "./flag-rs.jpg";
import * as S from "./styles";

export const SosRSAdmin = ({ visible }) => {
  if (visible) {
    return (
      <S.Wrapper>
        <div className="sos-flag">
          <img src={FlagRS} alt="Bandeira do Rio Grande do Sul" />
        </div>
        <div className="sos-infos">
          <h3>{`${I18n.get("SOS Rio Grande do Sul")} - ${I18n.get("Sim")}`}</h3>
        </div>
      </S.Wrapper>
    );
  } else {
    return (
      <div style={{ marginBottom: 16 }}>
        <h3>{`${I18n.get("SOS Rio Grande do Sul")} - ${I18n.get("Não")}`}</h3>
      </div>
    );
  }
};
