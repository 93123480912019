import React, { Fragment, useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { PROJECTS_API } from "../../../../../store/actions/projects";
import * as S from "./styles";
import { Row, Col } from "antd";
import { H3Border, H5Border } from "../../../../../components/UI/Titles";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { Button } from "../../../../../components/UI/Button";
import Loading from "../../../../../components/UI/Loading";
import {
  getActionClient,
  getActionPartner,
} from "../../../../../helpers/utils";

function ExpandProject({ id, closeLine, isClient }) {
  const [projectDetails, setProjectDetails] = useState({});

  let history = useHistory();

  useEffect(() => {
    if (Object.keys(projectDetails).length == 0) {
      async function getProject() {
        const data = {
          projectId: id,
        };
        PROJECTS_API.get_project(data).then((response) => {
          setProjectDetails(response?.payload?.project?.data);
        });
      }
      getProject();
    }
  }, []);

  const project = isClient
    ? getActionClient(projectDetails)
    : getActionPartner(projectDetails);

  return (
    <S.Expand>
      {Object.keys(projectDetails).length == 0 && (
        <Row>
          <Col lg={24}>
            <Loading />
          </Col>
        </Row>
      )}
      {Object.keys(projectDetails).length > 0 && (
        <Fragment>
          <Row>
            <Col lg={20}>
              <H3Border>
                {I18n.get("Nome")}: {projectDetails?.projectName}
              </H3Border>
            </Col>
            <Col lg={4}></Col>
          </Row>
          <Row>
            <Col lg={24}>
              <Button
                // icon={IcoPlus}
                isDisabled={!project?.redirectUrl}
                theme="primary"
                text={project?.label}
                outline={true}
                shape="square"
                onClick={() => history.push(project?.redirectUrl)}
              />
            </Col>
          </Row>
          <Row className="contentInfo">
            <Col lg={7}>
              <H5Border>{I18n.get("Informações")}</H5Border>

              <div className="info">
                <p>
                  <b>{I18n.get("Type")}:</b>{" "}
                  {projectDetails?.projectTypeDescription}
                </p>
                <p>
                  <b>{I18n.get("Name")}:</b> {projectDetails?.projectName}
                </p>
                <p>
                  <b>{I18n.get("Number")}:</b> {projectDetails?.projectSKU}
                </p>
                <p>
                  <b>{I18n.get("Product")}:</b> {projectDetails?.brandName} /{" "}
                  {projectDetails?.projectName}
                </p>
              </div>
            </Col>
            <Col lg={7}>
              <H5Border>{I18n.get("Escopo Criativo")}</H5Border>

              <p>
                <b>{I18n.get("Type")}:</b> {projectDetails?.bundle?.title}
              </p>
              <p>
                <b>{I18n.get("Client Value")}:</b>{" "}
                <span className="blueText">
                  {projectDetails?.priceClientWithDiscountTotalFmt}
                </span>
              </p>

              <p>
                <b>{I18n.get("Partner Value")}:</b>{" "}
                <span className="blueText">
                  {projectDetails?.pricePartnerFmt}
                </span>
              </p>

              <p>
                <b>{I18n.get("BPool Value")}:</b>{" "}
                <span className="blueText">
                  {projectDetails?.priceBPoolComissionTotalFmt}
                </span>
              </p>
              <p>
                <b>{I18n.get("Prazo")}:</b> até 30 dias
              </p>
              <p>
                <b>{I18n.get("Modelo")}:</b>{" "}
                <span className="blueText">***A*** #to-do</span>
              </p>
            </Col>
            <Col lg={10}>
              <H5Border>{I18n.get("Detalhes")}</H5Border>

              <p>
                <b>{I18n.get("Start Forecast")}:</b>{" "}
                {projectDetails?.estimatedStartDate}
              </p>
              <p>
                <b>{I18n.get("End forecast")}:</b>{" "}
                {projectDetails?.estimatedDurationInMonths
                  ? projectDetails?.estimatedDurationInMonths
                  : "0"}{" "}
                {I18n.get("mês(es) e")}{" "}
                {projectDetails?.estimatedDurationInWeeks
                  ? projectDetails?.estimatedDurationInWeeks
                  : "0"}{" "}
                {I18n.get("semana(s)")}
              </p>
              <p>
                <b>{I18n.get("Selection Deadline")}:</b>{" "}
                {projectDetails?.estimatedDecisionInWeeksFmt}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={14}>
              <p>
                <b>{I18n.get("Term Exclusivity")}:</b>{" "}
                {projectDetails?.nonCompeteInDaysFmt}
              </p>
            </Col>

            <Col lg={10}>
              <div className="buttonsContainer">
                <a href={projectDetails?.pdfProject} target="_blank" download>
                  <ButtonBpool
                    text={I18n.get("Download")}
                    className=""
                    theme="primary"
                  />
                </a>

                <ButtonBpool
                  text={I18n.get("Voltar")}
                  onClick={closeLine}
                  theme="secondary"
                  outline={true}
                />
              </div>
            </Col>
          </Row>
        </Fragment>
      )}
    </S.Expand>
  );
}

export default ExpandProject;
