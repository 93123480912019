import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { useHistory, useParams } from "react-router-dom";
import { useBpay } from "../.../../../../../hooks/useBpay/useBpay.hook";
import Content from "../../../../components/UI/Content";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import { Breadcrumb } from "../../../../components/UI/Breadcrumb";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { StepsVertical } from "../../components/StepsVertical";
import { BoxContent } from "../../components/BoxContent";

import * as S from "./styles";

export const NewBPay = ({ children, buttons }) => {
  return (
    <LoggedPage>
      <Content style={{ paddingTop: 58 }}>
        <Breadcrumb
          title="BPay"
          breadcrumbs={[
            { name: I18n.get("Home"), route: "/" },
            { name: I18n.get("BPay"), route: "/cliente/bpay" },
            { name: I18n.get("Novo BPay"), route: "/cliente/bpay/new/data" },
          ]}
          buttons={<></>}
        />
        <div className="container">
          <S.ContentBpay>
            <BoxContent>{children}</BoxContent>
            <div>
              <StepsVertical />
            </div>
          </S.ContentBpay>

          {buttons ? <S.ButtonsPage>{buttons}</S.ButtonsPage> : null}
        </div>
      </Content>
    </LoggedPage>
  );
};
