import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import LoggedPage from '../page-templates/logged';

import Menu from './_menu';

import CasesAwardsForm from './cases-awards-form';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class CasesAwardsAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <div className="columns">

                    <div className="column is-3">
                        <Menu {...this.props} />
                    </div>

                    <div className="column is-8">

                        <h1 className="title-bpool medium">{I18n.get("Cases e Prêmios")}</h1>

                        <CasesAwardsForm {...this.props} />
                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CasesAwardsAdmin);
