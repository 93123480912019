// Criar uma tabela com antdesign

import React, { useState, useEffect } from "react";
import { Table, Skeleton } from "antd";
import { I18n } from "aws-amplify";
import { useMeetings } from "../../../../hooks/useMeetings/useMeetings.hook";

const TableMeets = ({ data }) => {
  const { getMeetingsAdmin, getTimezones } = useMeetings();

  const [dataMeets, setDataMeets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timezones, setTimezones] = useState([]);

  const columns = [
    {
      title: I18n.get("Data Hora UTC"),
      dataIndex: "hourUtc",
      key: "hourUtc",
    },
    {
      title: I18n.get("Projeto"),
      dataIndex: "projectName",
      key: "hourUtc",
    },
    {
      title: I18n.get("Hora Cliente"),
      dataIndex: "clientHour",
      key: "clientHour",
      render: (clientHour, record) => {
        return (
          <div>
            {clientHour} <br />
            {record.offsetClient}
          </div>
        );
      },
    },
    {
      title: I18n.get("Cliente"),
      dataIndex: "clientName",
      key: "clientName",
      render: (clientName, record) => {
        return (
          <div>
            {clientName} <br />
            {record.clientResponsable}
          </div>
        );
      },
    },
    {
      title: I18n.get("Hora Parceiro"),
      dataIndex: "partnerHour",
      key: "partnerHour",
      render: (partnerHour, record) => {
        return (
          <div>
            {record.partnerDate} {partnerHour} <br />
            {record.offsetPartner}
          </div>
        );
      },
    },
    {
      title: I18n.get("Parceiro"),
      dataIndex: "partnerName",
      key: "partnerName",
      render: (partnerName, record) => {
        return (
          <div>
            {partnerName} <br />
            {record.partnerResponsable}
          </div>
        );
      },
    },
    {
      title: I18n.get("URL Meeting"),
      key: "url",
      dataIndex: "url",
      render: (url) => (
        <a href={url} target="_blank">
          {url}
        </a>
      ),
    },
  ];

  const initTimezone = async () => {
    const offset = await getTimezones();

    if (offset.success) {
      setTimezones(offset.data);
    }
  };

  const setOffset = (id) => {
    const offset = timezones.filter((item) => item.value === id);
    return offset[0].text;
  };

  useEffect(() => {
    const fetchMeetings = async () => {
      setLoading(true);
      const data = {
        date: new Date(),
      };
      const response = await getMeetingsAdmin(data);
      setDataMeets(response);
      setLoading(false);
    };

    fetchMeetings();
    initTimezone();
  }, []);

  const dataTable =
    (dataMeets &&
      dataMeets.map((item) => {
        return {
          code: item?.id,
          hourUtc: `${item?.meetingUTCDateFullStart}  -  ${item?.clientHour}`,
          projectName: item?.projectName,
          clientHour: `${item?.meetingUTCDateFullStart}${" "}${
            item?.clientHour
          }`,
          clientName: item?.clientName,
          offsetClient: setOffset(item?.clientTimeZone),
          clientResponsable: item?.clientResponsableName,
          partnerHour: item?.partnerHour,
          partnerName: item?.partnerName,
          partnerDate: item?.partnerDate,
          offsetPartner: setOffset(item?.partnerTimeZone),
          partnerResponsable: item?.partnerResponsableName,
          url: item?.url,
        };
      })) ||
    [];

  return (
    <>
      {dataMeets && dataMeets.length === 0 ? (
        <Skeleton active paragraph={{ rows: 10 }} />
      ) : (
        <Table
          columns={columns}
          dataSource={dataTable}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 800 }}
        />
      )}
    </>
  );
};

export default TableMeets;
