import {
    UPDATE,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    AUTH_PASSWORD_SET,
    AUTH_FORGOT_PASSWORD,
    AUTH_PASSWORD_RESET,
    LOGOUT,
    EXPIRED
} from '../actions/auth/actions';

export default function authReducer(state = {}, action) {

    //console.dir(action);
    
    switch (action.type) {

        case UPDATE:
          return Object.assign({}, state, action.payload);        

        case LOGIN_SUCCESS:
          return Object.assign({}, state, action.payload);

        case LOGIN_FAILURE:
          return Object.assign({}, state, action.payload);

        case AUTH_PASSWORD_SET:
            return Object.assign({}, state, action.payload);

        case AUTH_FORGOT_PASSWORD:
            return Object.assign({}, state, action.payload);

        case AUTH_PASSWORD_RESET:
            return Object.assign({}, state, action.payload);

        case LOGOUT:
            return Object.assign({}, state, action.payload);

        case EXPIRED:
            return {
                init: false,
                task: {}
            }

        default:
            return state;
    }
}
