import React from 'react';
import { I18n } from 'aws-amplify';
import Carousel from '../../ui/carousel';
import ReactPlayer from 'react-player';

class CaseFull extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    getPosterImage() {

        if (!this.props.photos || !this.props.photos.length) 
            return 'https://bpool.co/images/noBanner.png';

        return this.props.photos[0];
    }


    render() {

        const carouselItems = [];

        this.props.photos && this.props.photos.forEach((item, index) => {

            const el = (<figure key={index} className="image caseDisplay">
                <img src={item} />
            </figure>)

            carouselItems.push(el);
        })

        this.props.videos && this.props.videos.forEach((item, index) => {

            const el = (<ReactPlayer url={item} width="640px" height="320px" style={{ margin: '0 auto' }} />)

            carouselItems.push(el);
        })

        return (
            <div style={{ textAlign: 'left' }}>

                <br />

                <nav className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <p>
                                <strong>{this.props.partner.company && this.props.partner.company.name}</strong> <br />
                                <small>{this.props.partner.company && this.props.partner.company.city} - {this.props.partner.company && this.props.partner.company.state}</small> <br />
                            </p>
                        </div>
                    </div>

                    <div className="level-right">
                        <p className="level-item">

                            {/* <span className="icon"><i className="fas fa-video"></i></span> &nbsp;&nbsp;
                            <span className="icon"><i className="fas fa-phone"></i></span> */}

                        </p>
                    </div>
                </nav>

                <br />

                <Carousel items={carouselItems} />

                <p>
                    <strong>{this.props.brand}</strong>  - <small>{this.props.product} </small>
                </p>

                <br />

                <p>
                    <strong>{I18n.get("Desafio")}</strong> <br />
                    {this.props.challenge}
                </p>

                <br />

                <p>
                    <strong>{I18n.get("Solução")}</strong> <br />
                    {this.props.solution}
                </p>

                <br />

                <p>
                    <strong>{I18n.get("Resultado")}</strong> <br />
                    {this.props.result}
                </p>

                <br />

            </div>
        );
    }
}

export default CaseFull;