import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

import { LEADER_API } from '../../actions/leader/actions';

import LandingPage from '../page-templates/landing';

import Steps from './steps';

import ErrorPane from '../error-pane';

import CasesCmp from './cases-cmp';
import AwardsCmp from './awards-cmp';

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp }, state.leader);
};

const mapDispatchToProps = dispatch => {
    return {
        get_company_info: id => {
            dispatch(LEADER_API.get_company_info(id));
        },
        addCase: data => {
            dispatch(LEADER_API.create_case(data));
        },
        delCase: data => {
            dispatch(LEADER_API.delete_case(data));
        },
        updateCase: data => {
            dispatch(LEADER_API.update_case(data));
        },
        addAward: data => {
            dispatch(LEADER_API.create_award(data));
        },
        delAward: data => {
            dispatch(LEADER_API.delete_award(data));
        },
        upload: data => {
            return LEADER_API.upload(data);
        },
        submit: data => {
            dispatch(LEADER_API.update(data));
        },
    };
};

class PerformanceForm extends React.Component {
    constructor(props) {
        super();
        this.state = {
            leaderId: props.leaderId,
            active: 0,
        };

        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        this.props.get_company_info(this.props.leaderId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onActiveChange(active) {
        this.setState({
            active: active
        });
    }

    submitForm(e) {
        e.preventDefault();

        const data = Object.assign({}, this.state, { isSubmit: true });
        this.props.submit(data);
    }

    render() {

        const options = ["Cases de Empresa", "Cases Pessoais", "Prêmios da Empresa", "Prêmios Pessoais"];

        return (
            <form onSubmit={this.submitForm}>

                <div className="step-4-header">
                    {options.map((option, index) =>
                        (
                            <button
                                type="button"
                                key={index}
                                onClick={this.onActiveChange.bind(this, index)}
                                className={'button ' + (this.state.active == index ? "is-black" : "is-leaked")}>
                                {option}
                            </button>
                        )
                    )}
                </div>

                {this.state.active == 0 && <CasesCmp {...this.props} type={1} title={I18n.get("Você participou de algum desses cases da agência?")} />}

                {this.state.active == 1 && <CasesCmp {...this.props} type={2} title={I18n.get("Cases Pessoais")} />}

                {this.state.active == 2 && <AwardsCmp {...this.props} type={1} title={I18n.get("Você participou de algum desses prêmios da agência?")} />}

                {this.state.active == 3 && <AwardsCmp {...this.props} type={2} title={I18n.get("Prêmios Pessoais")} />}

            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PerformanceForm);
