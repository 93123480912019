import React from "react";
import { useQuery } from "react-query";
import { I18n } from "aws-amplify";
import { useBpay } from "../../../../../hooks/useBpay/useBpay.hook";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";

import IconTotal1 from "../../../svgs/icon-total-1.svg";
import IconTotal2 from "../../../svgs/icon-total-2.svg";

import * as S from "./styles";

export const Totals = () => {
  const { getTotals } = useBpay();

  const { data, isLoading, error } = useQuery(
    "bpaysTotals",
    () => getTotals(),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os totais"),
          duration: 3,
        });
      },
    }
  );

  const renderValue = (value) => {
    if (value) {
      return value;
    } else if (value === 0) {
      return "0";
    } else {
      return "---";
    }
  };

  return (
    <S.ListOfTotals>
      <S.Skeleton
        loading={isLoading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-total",
          width: "100%",
        }}
      >
        <S.Total>
          <S.TotalIcon>
            <img alt="icon total 1" src={IconTotal1} />
          </S.TotalIcon>
          <S.TotalInfos>
            <h4>{I18n.get("Total BPays")}</h4>
            <p>{renderValue(data?.amountBPays)}</p>
          </S.TotalInfos>
        </S.Total>
      </S.Skeleton>
      <S.Skeleton
        loading={isLoading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-total",
          width: "100%",
        }}
      >
        <S.Total>
          <S.TotalIcon>
            <img alt="icon total 2" src={IconTotal2} />
          </S.TotalIcon>
          <S.TotalInfos>
            <h4>{I18n.get("Valor Total BPayments Solicitados")}</h4>
            <p>{renderValue(data?.totalValueBPaySuppliersAll)}</p>
          </S.TotalInfos>
        </S.Total>
      </S.Skeleton>
      <S.Skeleton
        loading={isLoading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-total",
          width: "100%",
        }}
      >
        <S.Total>
          <S.TotalIcon>
            <img alt="icon total 3" src={IconTotal1} />
          </S.TotalIcon>
          <S.TotalInfos>
            <h4>{I18n.get("Valor BPayments finalizados")}</h4>
            <p>{renderValue(data?.totalValueBPaySuppliersFinished?.value)}</p>
            <p className="sub-title">
              <strong>
                {renderValue(data?.totalValueBPaySuppliersFinished?.totalBPays)}
              </strong>
              <span style={{ marginLeft: 3 }}>
                {I18n.get("BPayments no total")}
              </span>
            </p>
          </S.TotalInfos>
        </S.Total>
      </S.Skeleton>
      <S.Skeleton
        loading={isLoading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-total",
          width: "100%",
        }}
      >
        <S.Total>
          <S.TotalIcon>
            <img alt="icon total 4" src={IconTotal1} />
          </S.TotalIcon>
          <S.TotalInfos>
            <h4>{I18n.get("Valor BPayments em andamento")}</h4>
            <p>{renderValue(data?.totalValueBPaySuppliersInProgress?.value)}</p>
            <p className="sub-title">
              <strong>
                {renderValue(
                  data?.totalValueBPaySuppliersInProgress?.totalBPays
                )}
              </strong>
              <span style={{ marginLeft: 3 }}>
                {I18n.get("BPayments no total")}
              </span>
            </p>
          </S.TotalInfos>
        </S.Total>
      </S.Skeleton>
    </S.ListOfTotals>
  );
};
