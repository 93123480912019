import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { DASHBOARD_INFOS } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const api_get_dashboard_infos = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .get(
            `${Config.API_BASE_URL}/Dashboard/${data.prefix}/${
              data.isMonth ? "dashdetailsbymonth/" : "dashdetailsbyyear/"
            }${data.fromDate}/${data.toDate}/${data?.month}/${
              data?.year
            }?billingtype=${data.billingtype}&projectType=${data.projectType}`,
            config
          )
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return error;
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default api_get_dashboard_infos;
