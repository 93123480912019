import styled from "styled-components";
import * as MediaQuery from "../../../utils/mediaQuery";

export const FaqBoard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 30px 50px;
  text-align: center;
  height: calc(100% - 96px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-self: flex-start;

    ${() =>
      MediaQuery.IS_MOBILE() &&
      `
            flex-direction: column;
            align-items: flex-start;
        `}
  }

  .tutorials {
    margin: 42px 0 30px;
    display: flex;
    flex-direction: row;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    a {
      display: flex;
      flex-direction: column;
      text-align: left;
      flex: 1 1 32%;

      div {
        padding: 30px 15px;
        span {
          font-size: 16px;
          color: #1883ff;
          font-weight: 600;
        }

        p {
          color: #2f2f2f;
          font-weight: 600;
          font-size: 20px;
          line-height: 23px;
        }
      }
    }
  }

  .faq {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 42px 0 30px;

    div {
      flex: 0 1 30%;
      width: 30%;
      text-align: left;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        border-bottom: 1px solid #d9d9d9;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
      h2 {
        color: #2f2f2f;
        font-size: 20px;
        line-height: 23px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 15px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      ${() =>
        MediaQuery.IS_MOBILE() &&
        `
            flex: 0 1 100%;
            border-bottom: 1px solid #d9d9d9;
            margin-bottom: 20px;
            padding-bottom: 20px;
            `}
    }
  }

  .fluxogramas {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 35px;

    > div {
      width: 50%;

      ${() =>
        MediaQuery.IS_MOBILE() &&
        `
                width: 100%;
            `}
    }

    div > div {
      border-bottom: 1px solid #d9d9d9;
      justify-content: space-between;
      align-items: center;
      display: flex;
      padding: 20px 25px 20px 5px;

      p:nth-child(2) {
        flex: 5;
        margin-left: 15px;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p:nth-child(3) {
        flex: 5;
        margin-left: 15px;
        max-width: 85px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .extension-image {
        flex: 1;
        height: 22px;
        width: 18px;
      }
      .download-icon {
        flex: 1;
        height: 16px;
        max-width: 16px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      text-align: left;
    }

    ${() =>
      MediaQuery.IS_MOBILE() &&
      `
            flex-direction: column;
            margin: 30px 0;
            gap: 0;
        `}
  }
`;
