import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = state => {
  return state;
};
const mapDispatchToProps = dispatch => {
  return {}
};

class Steps extends React.Component {
    constructor(props) {
        super();
        this.state = {
            items: [
                {
                    index: 1,
                    title: I18n.get("Informações"),
                    color: "#000",
                },
                {
                    index: 2,
                    title: I18n.get("Selecione seu Projeto"),
                    color: "#000",
                },
                {
                    index: 3,
                    title: I18n.get("Sobre Parceiro"),
                    color: "#000",
                },
                {
                    index: 4,
                    title: I18n.get("Checkout"),
                    color: "#000",
                },
                {
                    index: 5,
                    title: I18n.get("Review do Projeto"),
                    color: "#000",
                }
            ]
        };
    }

    onLinkClick(e){
        // Desabilita o steps na tela inicial de criacao do projeto ate obter o projectId
        if (!this.props.projectId){
            e.preventDefault();
        }
    }

    render() {
        const { items } = this.state
        const { active, projectId } = this.props

    let name = '';

    if (this.props.client && this.props.client.nameAlias) {

      name = this.props.client.nameAlias;
    }
    else if (this.props.client && this.props.client.name) {

      name = this.props.client && this.props.client.name;

      let parts = name.split(' ');

      if (parts.length > 0) {
        name = parts[0];
      }
    }

        return (
            <div>
            <ul id="partner-signup-steps" className="partner-signup-newproj-steps">
                {items.map((item) => {

                    const lineStyle = {
                    };
                    return (<li key={item.index} className={(active && item.index === active && "progress-bar--item is-active") || "progress-bar--item"}>
                        <h3>{item.index}</h3>
                        <Link onClick={this.onLinkClick.bind(this)} to={`/cliente/projetos/${projectId}/passo-${item.index}`} className={`progress-bar--menu-item ${item.index != 1 && projectId === undefined ? 'link-disable' : ''}`}>
                            <span style={lineStyle}></span>
                            <strong className="is-not-mobile">{item.title}</strong>
                        </Link>
                    </li>)
                })}
            </ul>
        </div>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
