import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";
import * as S from "./styles";
import { Icon } from "antd";

import TableBP from "../../../../../components/UI/Table";
import { ModalBp } from "../../../../../components/UI/ModalBp/ModalBp";
import ExpandDetails from "../ExpandDetails";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";

import { FormatCurrency, compareByAlph } from "../../../../../helpers/utils";
import selectColumnSearchProps from "../../../../../helpers/selectTableFilters";
import inputTableFilters from "../../../../../helpers/inputTableFilters";

import {
  getActionClient,
  getActionPartner,
} from "../../../../../helpers/utils";

function openExpand(expand, item) {
  let newExpand = [];

  const index = expand.indexOf(item.key);

  if (index > -1) {
    newExpand = expand.filter((e) => e !== item.key);
    return newExpand;
  } else {
    newExpand = [...expand, item.key];
    return newExpand;
  }

  // return [1, 2];
}

const TableProject = ({
  data,
  isBig,
  pagination,
  onChange,
  editFilter,
  filters,
  reload,
}) => {
  const state = useSelector((state) => state);
  const { deleteProject } = useProjects();
  const [expand, setExpand] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState({
    open: false,
    message: "",
    id: "",
  });
  const [isDeletingProject, setIsDeletingProject] = useState("");

  const typeArray = [
    {
      code: "0",
      label: "Marketplace",
    },
    {
      code: "2",
      label: "Proposal",
    },
  ];

  const dataTable =
    data &&
    data.map((item, index) => {
      // const action = this.getAction(item);

      return {
        key: index + 1,
        type: item.projectTypeDescriptionFront,
        projectName: item.projectName,
        brand: item.brandName,
        product: item.productName,
        partnerName: item.partnerName || "--",
        clientName: item.clientName || "--",
        estimatedStartDate: item.estimatedStartDate || "--",
        projectTotal: item.priceClientWithDiscountTotalFmt,
        partnerId: item.partnerId,
        id: item.id,
        statusDescription: item.statusDescription,
        statusId: item.status,
        item: item,
        priceClientProjectExtraTotal: item.priceClientProjectExtraTotal,
      };
    });

  const columns = [
    {
      title: I18n.get("Tipo"),
      dataIndex: "type",
      key: "type",
      width: 70,
      render: (type, record) => <S.StyledSpanTag>{type}</S.StyledSpanTag>,
      sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => compareByAlph(a.type, b.type),
      sorter: (a, b) => compareByAlph(a.type, b.type),
      ...selectColumnSearchProps("type", typeArray, editFilter, "type"),
    },
    {
      title: I18n.get("Nome"),
      dataIndex: "projectName",
      key: "projectName",
      width: 70,
      render: (projectName, record) => {
        return <Link className="textLink">{projectName}</Link>;
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => compareByAlph(a.projectName, b.projectName),
      ...inputTableFilters("projectName", dataTable, editFilter, "projectName"),
    },
    {
      title: I18n.get("Marca"),
      dataIndex: "brand",
      key: "brand",
      width: 70,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => compareByAlph(a.partnerName, b.partnerName),
      ...selectColumnSearchProps(
        "brand",
        filters?.brands,
        editFilter,
        "brandId"
      ),
      sorter: (a, b) => compareByAlph(a.brand, b.brand),
    },
    // {
    //   title: I18n.get("Produto"),
    //   dataIndex: "product",
    //   key: "product",
    //   width: 125,
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => compareByAlph(a.product, b.product),
    //   ...selectColumnSearchProps(
    //     "product",
    //     filters?.products,
    //     editFilter,
    //     "productId"
    //   ),
    // },
    {
      title: I18n.get(state.auth.profile === "Client" ? "Parceiro" : "Cliente"),
      dataIndex: state.auth.profile === "Client" ? "partnerName" : "clientName",
      key: "clientName",
      width: 70,
      render: (name, record) => {
        return <span>{name}</span>;
      },
      sortDirections: ["descend", "ascend"],
      sorter:
        state.auth.profile === "Client"
          ? (a, b) => compareByAlph(a.partnerName, b.partnerName)
          : (a, b) => compareByAlph(a.clientName, b.clientName),
      ...selectColumnSearchProps(
        state.auth.profile === "Client" ? "partnerName" : "clientName",

        state.auth.profile === "Client" ? filters?.partners : filters?.clients,
        editFilter,
        "personFilterId"
      ),
    },
    // {
    //   title: I18n.get("Início"),
    //   dataIndex: "estimatedStartDate",
    //   width: 110,
    //   key: "estimatedStartDate",
    //   render: (estimatedStartDate, record) => {
    //     return <span>{estimatedStartDate}</span>;
    //   },
    //   sorter: (a, b) =>
    //     compareByAlph(a.estimatedStartDate, b.estimatedStartDate),
    // },
    {
      title: I18n.get("$$"),
      dataIndex: "projectTotal",
      key: "projectTotal",
      width: 70,
      sorter: (a, b) => compareByAlph(a.projectTotal, b.projectTotal),
    },
    {
      title: I18n.get("Extras"),
      dataIndex: "priceClientProjectExtraTotal",
      key: "priceClientProjectExtraTotal",
      width: 70,
      render: (priceClientProjectExtraTotal, record) => {
        return <span>{priceClientProjectExtraTotal || "---"}</span>;
      },
      sorter: (a, b) =>
        compareByAlph(
          a.priceClientProjectExtraTotal,
          b.priceClientProjectExtraTotal
        ),
    },
    {
      title: I18n.get("Status"),
      dataIndex: "statusDescription",
      key: "statusDescription",
      width: 120,
      sorter: (a, b) => compareByAlph(a.statusDescription, b.statusDescription),
      ...selectColumnSearchProps(
        "statusDescription",
        filters?.status,
        editFilter,
        "statusId"
      ),
      render: (statusDescription, record) => {
        const project = state.auth.is_client_or_client_user
          ? getActionClient(record?.item)
          : getActionPartner(record?.item);

        return (
          <S.StyledDivStatus
            draftCanDelete={
              record?.item?.status === 1
                ? state?.auth?.username === record?.item?.createdByUsername ||
                  state?.auth?.username ===
                    record?.item?.partnerResponsibleUsername
                  ? true
                  : false
                : false
            }
          >
            <Link to={project?.redirectUrl}>
              {state?.auth.is_client_or_client_user ? (
                <S.StyledSpanStatus>{statusDescription}</S.StyledSpanStatus>
              ) : record?.item?.status === 6 ? (
                <S.StyledSpanStatus>
                  {I18n.get("Briefing & Cronograma")}
                </S.StyledSpanStatus>
              ) : (
                <S.StyledSpanStatus>{statusDescription}</S.StyledSpanStatus>
              )}
            </Link>
            {record?.item?.status === 1 ? (
              state?.auth?.username === record?.item?.createdByUsername ||
              state?.auth?.username ===
                record?.item?.partnerResponsibleUsername ? (
                <S.DeleteProject
                  onClick={() =>
                    setOpenModalConfirm({
                      open: true,
                      message: `${I18n.get(
                        "Você deseja mesmo deletar o projeto"
                      )} ${record?.item?.projectName} ?`,
                      id: record?.item?.id,
                    })
                  }
                  type="primary"
                  className="btnDangerList"
                  disabled={isDeletingProject === record?.item?.id}
                >
                  <Icon type="delete" theme="filled" />
                </S.DeleteProject>
              ) : null
            ) : null}
          </S.StyledDivStatus>
        );
      },
    },
  ];

  const handleClickOkModalConfirm = async () => {
    const idDelete = openModalConfirm?.id;

    setIsDeletingProject(idDelete);

    const response = await deleteProject({
      projectId: idDelete,
    });

    if (response?.success) {
      setOpenModalConfirm({ open: false, message: "", id: "" });
      reload();
    }

    setIsDeletingProject("");
  };

  return (
    <>
      <TableBP
        columns={columns}
        data={dataTable}
        isBig={isBig}
        expandedRowRender={(record) => (
          <ExpandDetails
            id={record.id}
            closeLine={() => setExpand(openExpand(expand, record))}
            isClient={state.auth.is_client_or_client_user}
          />
        )}
        expandedRowKeys={expand}
        pagination={pagination}
        onExpand={(expanded, record) => setExpand(openExpand(expand, record))}
        scroll={{ x: 1080 }}
      />

      <ModalBp
        visible={openModalConfirm?.open}
        bodyText={I18n.get(openModalConfirm?.message)}
        subBodyText={I18n.get("Esta ação é irreversível.")}
        okText={I18n.get("Yes")}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() =>
          setOpenModalConfirm({ open: false, message: "", id: "" })
        }
        cancelText={I18n.get("Cancel")}
        typeModal="isConfirm"
        isLoading={isDeletingProject}
        isAlert
      />
    </>
  );
};

export default TableProject;
