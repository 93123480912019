import { ITENS_DELETE } from './actions';

export const delete_detail = () => {

    return (dispatch, getState) => {

        dispatch({
            type: ITENS_DELETE,
            payload: {
                ideaDetail: undefined
            }
        });
    }
}

export default delete_detail;
