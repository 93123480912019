import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { Icon, Row, Col, Pagination } from "antd";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useReview } from "../../../../../hooks/useReview/useReview.hook";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import Loading from "../../../Loading";
import { Input } from "../../../Input";
import { ItemAsset } from "../IncludedItems/ItemAsset/itemAsset";
import { CartTotals } from "../CartTotals";

import * as S from "./styles";

export const AssetsCart = ({
  isPartner,
  isCart,
  removeTotals,
  title,
  listDataIncludedItems,
  handleListDataIncludedItems,
  handleChanged,
  cartTotals,
  filters,
  reload,
  assetTax,
  otherTax,
}) => {
  let params = useParams();
  const { getAssets } = useReview();
  const { addAssetToProject } = useProjects();
  const [listAssets, setListAssets] = useState([]);
  const [paginationAssets, setPaginationAssets] = useState({});
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoadingAddItemCart, setIsLoadingAddItemCart] = useState("");
  const [expandedDescription, setExpandedDescription] = useState("");

  const [searchValueDebounced] = useDebounce(searchTerm, 1000);

  const handleEditQuantity = ({ code, quantity }) => {
    handleChanged(true);
    const hasItemCode = listDataIncludedItems?.find(
      (item) => item?.code === code
    );

    if (hasItemCode) {
      const updated = {
        ...hasItemCode,
        quantity,
      };

      handleListDataIncludedItems(
        listDataIncludedItems.map((item) =>
          item?.code === code ? updated : item
        )
      );
    }
  };

  const onClickAddItem = (asset) => {
    handleChanged(true);
    const assetsDefaults = listDataIncludedItems?.length
      ? listDataIncludedItems
      : [];

    const hasItemCode = assetsDefaults?.find((item) => {
      if (item?.code) {
        return item?.code === asset?.code ? asset : null;
      }
      if (item?.asset) {
        return item?.asset === asset?.code ? asset : null;
      }
    });

    if (hasItemCode) {
      const assetToBeAdd = {
        ...asset,
        quantity: hasItemCode?.quantity + 1,
      };
      handleListDataIncludedItems(
        listDataIncludedItems.map((item) =>
          item?.code === asset?.code ? assetToBeAdd : item
        )
      );
    } else {
      const assetToBeAdd = {
        ...asset,
        quantity: 1,
      };
      const assetsUpdated = [...assetsDefaults, assetToBeAdd];
      handleListDataIncludedItems(assetsUpdated);
    }
  };

  const onClickAddItemCart = async ({ asset }) => {
    setIsLoadingAddItemCart(asset?.code);

    const objSend = listDataIncludedItems?.map((item) => ({
      code: item?.asset ? item?.asset : item?.code,
      quantity: item?.quantity ? Number(item?.quantity) : 1,
    }));

    const dataSend = [
      ...objSend,
      {
        code: asset?.asset ? asset?.asset : asset?.code,
        quantity: 1,
      },
    ];

    let data = {
      projectId: params.projectId,
      assets: dataSend,
    };

    const response = await addAssetToProject({ assets: data });
    if (response?.success) {
      reload();
    }
    setIsLoadingAddItemCart("");
  };

  const onClickDeleteItem = async (asset) => {
    handleChanged(true);
    if (listDataIncludedItems?.length) {
      const items = listDataIncludedItems;

      const filteredItems = items?.filter((item) => {
        if (item?.code) {
          return item?.code !== asset?.code;
        } else if (item?.asset) {
          return item?.asset !== asset?.asset;
        }
      });

      if (isCart) {
        setIsLoadingAddItemCart(asset?.code);
        let data = {
          projectId: params.projectId,
          assets: filteredItems,
        };

        const response = await addAssetToProject({ assets: data });
        if (response?.success) {
          reload();
        }
        setIsLoadingAddItemCart("");
      } else {
        handleListDataIncludedItems(filteredItems);
      }
    } else {
      return;
    }
  };

  const paginationGetAssets = async ({
    page,
    pageSize,
    searchTerm,
    filters,
  }) => {
    setIsLoadingAssets(true);

    const data = {
      page: page,
      pageSize: pageSize || 20,
      label: searchTerm || "",
      ...filters,
      projectId: params.projectId,
    };

    const response = await getAssets(data);
    if (response?.success) {
      setListAssets(response?.data?.result);
      setPaginationAssets(response?.data?.pagination);
    }

    setIsLoadingAssets(false);
  };

  useEffect(() => {
    paginationGetAssets({
      page: 1,
      pageSize: 20,
      searchTerm,
      filters: filters || {},
    });
  }, [searchValueDebounced, filters]);

  const totalComplexities = ({ complexityId, complexity, complexDesc }) => {
    let total = 0;

    if (complexityId) total += 1;
    if (complexity) total += 1;
    if (complexDesc) total += 1;

    return total;
  };

  return (
    <>
      <div>
        <Row>
          <Col xs={10} sm={10}>
            {title ? <S.TitleModal>{title}</S.TitleModal> : null}
            <S.TitleTotal>
              <span>
                {I18n.get("Total de assets")}
                {`:`}
              </span>
              {isLoadingAssets ? (
                <Loading
                  sizeIcon={18}
                  text={""}
                  sizeText={14}
                  align="left"
                  style={{
                    width: 50,
                    marginLeft: 5,
                  }}
                />
              ) : (
                <span style={{ color: "#1883ff", marginLeft: 5 }}>
                  {paginationAssets?.totalItems}
                </span>
              )}
            </S.TitleTotal>
            <S.SearchArea>
              <Input
                placeholder={I18n.get("Buscar asset")}
                error=""
                onChange={(value) => setSearchTerm(value)}
                value={searchTerm}
                noShowError
                sizeInput="small"
              />
            </S.SearchArea>
            <S.ListAssets>
              {isLoadingAssets ? (
                <Loading
                  sizeIcon={18}
                  text={"Loading..."}
                  sizeText={14}
                  align="left"
                />
              ) : listAssets?.length ? (
                listAssets?.map((asset, index) => (
                  <S.ItemListAsset key={index}>
                    <S.LeftAsset>
                      <S.LabelAndValue>
                        <S.LabelAsset>
                          {asset?.isManHour ? (
                            <Icon
                              type="clock-circle"
                              style={{ paddingRight: 7.5 }}
                            />
                          ) : null}
                          <span>{asset?.label}</span>
                        </S.LabelAsset>
                        <S.ValueAsset>{asset?.priceFmt}</S.ValueAsset>
                      </S.LabelAndValue>

                      <S.DescriptionAssetTitle style={{ marginTop: 10 }}>
                        {I18n.get("Asset Description")}:
                      </S.DescriptionAssetTitle>
                      <S.DescriptionAsset
                        expanded={Boolean(expandedDescription === asset?.code)}
                      >
                        {asset?.description}
                      </S.DescriptionAsset>
                      <S.ExpandButton
                        onClick={() =>
                          setExpandedDescription(
                            expandedDescription === asset?.code
                              ? ""
                              : asset?.code
                          )
                        }
                      >
                        {expandedDescription === asset?.code
                          ? I18n.get("Ver menos")
                          : I18n.get("Ver mais")}
                      </S.ExpandButton>

                      <S.ComplexityAsset
                        totalComplexities={totalComplexities({
                          complexityId: asset?.complexityId,
                          complexity: asset?.complexity,
                          complexDesc: asset?.complexDesc,
                        })}
                      >
                        {asset?.complexityId ? (
                          <div style={{ gridArea: "item1" }}>
                            <span>{I18n.get("Complexidade ID")}: </span>
                            {asset?.complexityId}
                          </div>
                        ) : null}
                        {asset?.complexity ? (
                          <div style={{ gridArea: "item2" }}>
                            <span>{I18n.get("Complexidade")}: </span>
                            {asset?.complexity}
                          </div>
                        ) : null}
                        {asset?.complexDesc ? (
                          <div style={{ gridArea: "item3" }}>
                            <S.DescriptionAssetTitle>
                              {I18n.get("Complexidade descrição")}:
                            </S.DescriptionAssetTitle>
                            <S.DescriptionAsset
                              expanded={Boolean(
                                expandedDescription === `${asset?.code}-complex`
                              )}
                            >
                              {asset?.complexDesc}
                            </S.DescriptionAsset>
                            <S.ExpandButton
                              onClick={() =>
                                setExpandedDescription(
                                  expandedDescription ===
                                    `${asset?.code}-complex`
                                    ? ""
                                    : `${asset?.code}-complex`
                                )
                              }
                            >
                              {expandedDescription === `${asset?.code}-complex`
                                ? I18n.get("Ver menos")
                                : I18n.get("Ver mais")}
                            </S.ExpandButton>
                          </div>
                        ) : null}
                      </S.ComplexityAsset>
                    </S.LeftAsset>
                    <S.PlusContent>
                      {isLoadingAddItemCart === asset?.code ? (
                        <Loading
                          sizeIcon={18}
                          text={""}
                          sizeText={14}
                          align="right"
                        />
                      ) : (
                        <button
                          onClick={
                            isCart
                              ? () => onClickAddItemCart({ asset })
                              : () => onClickAddItem(asset)
                          }
                          style={{
                            background: isLoadingAddItemCart ? "#ccc" : "",
                          }}
                          disabled={isLoadingAddItemCart ? true : false}
                        >
                          {isLoadingAddItemCart ? (
                            <Icon type="plus" color="#ccc" />
                          ) : (
                            <Icon type="plus" />
                          )}
                        </button>
                      )}
                    </S.PlusContent>
                  </S.ItemListAsset>
                ))
              ) : (
                <div>{I18n.get("Nenhum asset.")}</div>
              )}
            </S.ListAssets>

            <div style={{ marginTop: 15 }}>
              <Pagination
                size="small"
                pageSize={20}
                total={paginationAssets?.totalItems}
                onChange={(page, pageSize) =>
                  paginationGetAssets({ page, pageSize })
                }
              />
            </div>
          </Col>
          <Col xs={1} sm={1}>
            <S.SeparatorVertical />
          </Col>
          <Col xs={13} sm={13}>
            <S.TitleModal>{I18n.get("Itens inclusos")}</S.TitleModal>
            <S.ListAndTotals cartTotals={cartTotals ? true : false}>
              <S.ListAssetsAdded>
                {listDataIncludedItems?.length ? (
                  listDataIncludedItems.map((item, index) => (
                    <ItemAsset
                      key={index}
                      allItems={listDataIncludedItems}
                      item={item}
                      hasEdit
                      handleEditQuantity={handleEditQuantity}
                      onClickDeleteItem={onClickDeleteItem}
                      isCart={isCart}
                      reload={reload}
                    />
                  ))
                ) : (
                  <div>{I18n.get("Nenhum asset incluso.")}</div>
                )}
              </S.ListAssetsAdded>
              {cartTotals && !removeTotals ? (
                <S.CartTotals>
                  <CartTotals
                    isPartner={isPartner}
                    data={cartTotals}
                    isLoading={isLoadingAddItemCart ? true : false}
                    assetTax={assetTax}
                    otherTax={otherTax}
                  />
                </S.CartTotals>
              ) : null}
            </S.ListAndTotals>
          </Col>
        </Row>
      </div>
    </>
  );
};
