import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperRegister";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";
import { PARTNER_API } from "../../../actions/partner/actions";
import FormAddReference from "../components/formAddReference";
import FormAddClient from "../components/formAddClient";
import ModalTerms from "../components/modalTerms";
import { TitleBorder, TitleMiddleLine } from "../../components/Titles";
import { PartnerStatus } from "../../../model";
import styled from "styled-components";

import TermsPartnerPT from "../components/_termsPartnerPT";
import TermsPartnerES from "../components/_termsPartnerES";
import TermsPartnerEN from "../components/_termsPartnerEN";

import {
  Row,
  Col,
  Input,
  Table,
  Icon,
  notification,
  Select,
  Tooltip,
  Modal,
  Button,
  Form,
  Drawer,
} from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Btns = styled.p`
  displa: block;
  width: 50px;
  a {
    margin-left: 10px;
  }
`;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: 60px;
  }

  .BtnAction {
    float: right;
    margin-bottom: 15px;
  }
`;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClient: (data) => {
      return dispatch(PARTNER_API.create_client(data));
    },
    updateClient: (data) => {
      return dispatch(PARTNER_API.update_client2(data));
    },
    delClient: (data) => {
      return dispatch(PARTNER_API.delete_client(data));
    },
    addReference: (data) => {
      dispatch(PARTNER_API.create_reference(data));
    },
    updateReference: (data) => {
      dispatch(PARTNER_API.update_reference(data));
    },
    delReference: (data) => {
      dispatch(PARTNER_API.delete_reference(data));
    },
    update: (data) => {
      dispatch(PARTNER_API.update(data));
    },
  };
};
class FullClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleClient: false,
      visibleReference: false,
      editReference: false,
      sendRegister: false,
      visibleTerms: false,
      selectedItemReference: null,
      openModal: false,
    };
  }

  handleFinish = () => {
    let data = {
      isSubmit: false,
      partnerId: this.props.match.params.partnerId,
    };

    this.props.update(data);

    this.setState({
      openModal: true,
      sendRegister: true,
      visibleTerms: true,
    });
  };

  showModal = () => {
    this.setState({
      visibleTerms: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visibleTerms: false,
      sendRegister: false,
    });
  };

  closeClient = () => {
    this.setState({
      visibleClient: false,
      visibleReference: false,
      selectedItemReference: null,
      selectedItemClient: null,
    });
  };

  getSector = (sectorId) => {
    const items =
      this.props.bp.sectorsFlat &&
      this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);

    if (items && items.length) {
      return items[0].label;
    } else {
      return {};
    }
  };

  finishPreRegister = () => {
    this.setState({
      sendRegister: true,
    });
    const data = {
      ...this.props.partner,
      isSubmit: true,
    };

    this.props.save(data);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.partner.clients &&
      prevProps.partner.clients &&
      this.props.partner.clients.length > prevProps.partner.clients.length &&
      !this.state.sendRegister
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("O cliente foi adicionado ao seu cadastro"),
        duration: 3,
      });

      this.closeClient();
    }

    if (
      this.props.partner.clients &&
      prevProps.partner.clients &&
      this.props.partner.clients.length < prevProps.partner.clients.length &&
      !this.state.sendRegister
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("O cliente foi removido do seu cadastro"),
        duration: 3,
      });
    }

    if (
      this.props.partner.clients &&
      prevProps.partner.clients &&
      this.props.partner.clients != prevProps.partner.clients &&
      this.state.selectedItemClient &&
      !this.state.sendRegister
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("A cliente foi atualizado"),
        duration: 3,
      });

      this.setState({
        selectedItemClient: null,
      });

      this.closeClient();
    }

    if (
      this.props.partner.references &&
      prevProps.partner.references &&
      this.props.partner.references.length > prevProps.partner.references.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("A referência foi adicionada ao seu cadastro"),
        duration: 3,
      });

      this.closeClient();
    }

    if (
      this.props.partner.references &&
      prevProps.partner.references &&
      this.props.partner.references.length < prevProps.partner.references.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("A referência foi removida do seu cadastro"),
        duration: 3,
      });
    }

    if (
      this.props.partner.references &&
      prevProps.partner.references &&
      this.props.partner.references != prevProps.partner.references &&
      this.state.selectedItemReference
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("A referência foi atualizada"),
        duration: 3,
      });

      this.setState({
        selectedItemReference: null,
      });

      this.closeClient();
    }

    if (
      this.props.partner.errors != prevProps.partner.errors &&
      this.state.openModal
    ) {
      if (
        Object.keys(this.props.partner.errors).lenght === 1 &&
        this.props.partner.errors[0].field === "Terms"
      ) {
        this.setState({
          visibleTerms: true,
          openModal: false,
        });
      }
    }
  }

  showEditReference = (item) => {
    this.setState({
      selectedItemReference: item,
      editReference: true,
      visibleReference: true,
    });
  };

  showEditClient = (item) => {
    this.setState({
      selectedItemClient: item,
      visibleClient: true,
    });
  };

  updateClient = (data) => {
    this.props.updateClient(data);
  };

  addClient = (data) => {
    this.props.addClient(data);
  };

  updateReference = (data) => {
    this.props.updateReference(data);
  };

  addReference = (data) => {
    this.props.addReference(data);
  };

  render() {
    if (
      this.props.partner &&
      this.props.partner.status == PartnerStatus.Approved
    ) {
      return <Redirect to="/parceiro/cadastro/sucesso" />;
    }

    let dataType1 =
      this.props.partner.clients &&
      this.props.partner.clients.map((item, index) => {
        return {
          key: index,
          name: item.name,
          brand: item.brand,
          product: item.product,
          category: this.getSector(item.category),
          billing: item.billing && item.billing.label,
          non: item.active ? "Sim" : "Não",
          item: item,
        };
      });

    const columnsClients = [
      {
        title: I18n.get("Empresa"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: I18n.get("Marca"),
        dataIndex: "brand",
        key: "brand",
      },
      {
        title: I18n.get("Produto"),
        dataIndex: "product",
        key: "product",
      },
      {
        title: I18n.get("Categoria"),
        dataIndex: "category",
        key: "category",
      },
      {
        title: I18n.get("Faturamento"),
        dataIndex: "billing",
        key: "billing",
      },
      {
        title: I18n.get("Non-compete"),
        dataIndex: "non",
        key: "non",
      },
      {
        title: "",
        dataIndex: "action",
        key: "item",
        render: (text, record) => {
          let data = {
            id: record.item.id,
            partnerId: this.props.match.params.partnerId,
          };

          return (
            <Btns>
              <a onClick={() => this.props.delClient(data)}>
                <Icon type="delete" theme="filled" />
              </a>
              <a onClick={() => this.showEditClient(record.item)}>
                <Icon type="edit" theme="filled" />
              </a>
            </Btns>
          );
        },
        // render: (record) => this.props.delMarketExpertise(record.item.id),
      },
    ];

    let errorMap = {};

    Object.keys(this.props.partner.errors).map((item) => {
      const error = this.props.partner.errors[item];
      const key = error.step || "global";

      if (error.field != "Terms") {
        if (!errorMap[key]) {
          errorMap[key] = [];
        }
        errorMap[key].push(error.errorMessage);
      }
    });

    //TODO: Find better way... GAMBI!
    let menuRegister = [...constants.MENU_REGISTER];
    for (let key in menuRegister) {
      menuRegister[key].label = I18n.get(menuRegister[key].label);
    }

    return (
      <AppWrapperSimple>
        <SubHeader
          titlePage=""
          itensMenu={menuRegister}
          urlId={this.props.match.params.partnerId}
          activeItem={7}
        />
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Cadastro - Clientes")}</TitleBorder>

                {/* <p className="descriptionPage">
                                    Clientes e referências representam reputação em nosso mercado. Tudo o que a sua empresa (durante sua existência) e você (durante sua carreira) construíram é levado em conta pelo nosso algoritmo.
                                </p> */}
              </Col>
            </Row>

            <Fragment>
              <Row>
                <Col sm={24}>
                  <TitleMiddleLine>
                    {I18n.get(
                      "Indique pelo menos 3 principais clientes com quem você ou sua empresa trabalhou nos últimos 2 anos"
                    )}
                  </TitleMiddleLine>
                </Col>
                <Col sm={24}>
                  <p className="BtnAction">
                    <Button
                      type="primary"
                      onClick={() =>
                        this.setState({
                          visibleClient: true,
                          sendRegister: false,
                        })
                      }
                      shape="round"
                    >
                      {I18n.get("+ Adicionar cliente")}
                    </Button>
                  </p>
                </Col>
              </Row>

              <Row>
                <Col sm={24}>
                  <Table
                    scroll={{ x: 640 }}
                    locale={{ emptyText: I18n.get("Sem informação") }}
                    columns={columnsClients}
                    dataSource={dataType1}
                  />
                </Col>
              </Row>
            </Fragment>

            <Row className="RowSection">
              <Col sm={8}></Col>
            </Row>

            {this.state.sendRegister && Object.keys(errorMap).length > 0 && (
              <Row className="">
                <Col sm={24}>
                  <div className="ulErrors">
                    {Object.keys(errorMap).map((item, index) => {
                      let itemNav = constants.MENU_REGISTER.find(
                        (i) => i.activeMenu == item
                      );

                      if (!errorMap[item].length == 0) {
                        return (
                          <div className="sectionError" key={index}>
                            <h3>
                              <Link
                                to={`${itemNav?.link && itemNav?.link}${
                                  this.props.match.params.partnerId
                                }`}
                              >
                                <Icon type="link" /> {itemNav.label}
                              </Link>
                            </h3>

                            <ul>
                              {errorMap[item].map((errorMessage) => (
                                <li>
                                  <Link
                                    to={`${itemNav?.link && itemNav?.link}${
                                      this.props.match.params.partnerId
                                    }`}
                                  >
                                    <Icon type="exclamation-circle" />{" "}
                                    {errorMessage}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Col>
              </Row>
            )}

            <Row className="RowSection">
              <Col sm={8}></Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Link
                  to={`/parceiro/cadastro/lideranca/${this.props.match.params.partnerId}`}
                >
                  <Button shape="round">{I18n.get("Voltar")}</Button>
                </Link>
              </Col>
              <Col sm={13}>
                <Link
                  target="_blank"
                  to={`/parceiro/cadastro/preview-card/${this.props.match.params.partnerId}`}
                >
                  <Button
                    type="primary"
                    shape="round"
                    style={{ float: "right" }}
                  >
                    <Icon type="eye" theme="filled" />
                    {I18n.get("Visualizar Perfil")}
                  </Button>
                </Link>
              </Col>
              <Col sm={8}>
                <Button
                  style={{ float: "right" }}
                  onClick={() => this.handleFinish()}
                  type="primary"
                  shape="round"
                >
                  {I18n.get("FINALIZAR CADASTRO")}
                </Button>
                {/* <Button style={{ float: "right" }} onClick={() => this.setState({ visibleTerms: true, sendRegister: true })} type="primary" shape="round">FINALIZAR CADASTRO</Button> */}
              </Col>
            </Row>
          </Main>
        </ContainerPage>

        <Drawer
          title={
            this.state.selectedItemClient
              ? I18n.get("Editar Cliente")
              : I18n.get("Adicionar Cliente")
          }
          placement="right"
          closable={true}
          onClose={() => this.closeClient()}
          destroyOnClose={true}
          visible={this.state.visibleClient}
          width="50%"
        >
          <FormAddClient
            partner={this.props.partner && this.props.partner}
            partnerId={this.props.match.params.partnerId}
            action={this.addClient}
            update={this.updateClient}
            onCancel={this.closeClient}
            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
            partnerClientBilling={
              this.props.bp.partnerClientBilling &&
              this.props.bp.partnerClientBilling
            }
            errors={this.props.partner.errors && this.props.partner.errors}
            item={this.state.selectedItemClient}
            forceRender={true}
          />
        </Drawer>

        <Drawer
          title={
            this.state.selectedItemReference
              ? I18n.get("Editar referência")
              : I18n.get("Adicionar referência")
          }
          placement="right"
          closable={true}
          onClose={() => this.closeClient()}
          destroyOnClose={true}
          visible={this.state.visibleReference}
          width="50%"
        >
          <FormAddReference
            partner={this.props.partner && this.props.partner}
            partnerId={this.props.match.params.partnerId}
            update={this.updateReference}
            action={this.addReference}
            onCancel={this.closeClient}
            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
            partnerReferenceRelation={
              this.props.bp.partnerReferenceRelation &&
              this.props.bp.partnerReferenceRelation
            }
            errors={this.props.partner.errors && this.props.partner.errors}
            item={this.state.selectedItemReference}
            forceRender={true}
          />
        </Drawer>

        <Modal
          title={I18n.get("Termos Contratuais BPool")}
          visible={
            this.state.visibleTerms &&
            this.props.partner.errors.terms &&
            Object.keys(errorMap).length == 0
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          width="60%"
          footer={[
            <ModalTerms
              partnerId={this.props.match.params.partnerId}
              action={this.props.update}
              close={this.handleCancel}
              locale={this.props.partner.signupLocale}
              partner={this.props.partner}
            />,
          ]}
        >
          <div className="containerTerms2">
            <div style={{ height: "500px" }}>
              <iframe
                src={`${this.props.partner.partnerTerm}`}
                height="500px"
                width="100%"
              ></iframe>
            </div>
          </div>
        </Modal>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FullClients);
