import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Task } from "../../../utils/task";

import { BP_API } from "../../../actions/bp/actions";

import LoggedPage from "../../page-templates/loggedAdmin";

import { CardSimple } from "../../../modules/components/Cards";
import FormFilterSupplierBilling from "../../../modules/Admin/components/filterSupplierBillingAdmin";
import TableBillings from "../../../modules/Admin/components/tableSupplierBillingAdmin";
import FormSupplierBilling from "../../../modules/Admin/components/formSupplierBilling";

import SupplierBillingDetails from "./supplier-billing-details";
import { SupplierBillingCard } from "./supplier-billing-details";

import styled from "styled-components";

import { Row, Col, Button, Modal, Icon } from "antd";

const { confirm } = Modal;

const NoData = styled.div`
  padding: 50px;
  text-align: center;

  h3 {
    font-weight: 700;
    margin-bottom: 30px;
  }

  i {
    font-size: 40px;
  }

  .txt {
    position: relative;
    left: 30px;
    top: -15px;
  }
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_domain: () => {
      return dispatch(BP_API.get_domain());
    },
    get_supplier_billing_filters: () => {
      return dispatch(BP_API.get_supplier_billing_filters());
    },
    get_supplier_billing_list: (data) => {
      return dispatch(BP_API.get_supplier_billing_list(data));
    },
    get_brands: (id) => {
      return dispatch(BP_API.get_brands(id));
    },
    create_supplier_billing: (data) => {
      return dispatch(BP_API.create_supplier_billing(data));
    },
    supplier_billing_notify: (data) => {
      return dispatch(BP_API.supplier_billing_notify(data));
    },
    delete_supplier_billing: (data) => {
      return dispatch(BP_API.delete_supplier_billing(data));
    },
    update_nfs2: (data) => {
      return BP_API.update_nfs_ex(data);
    },
    generate_financial: (data) => {
      dispatch(BP_API.generate_financial_nfs(data));
    },
  };
};

class SupplierBillingListAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      historySearch: {},
      loadTask: {},
      task: {},
      listPo: [],
      showSupplierBillingModal: false,
      selected: null,
    };
  }

  handleFilters = (data) => {
    var loadTask = new Task();

    loadTask.start();

    const dataPage = {
      pageSize: 30,
      page: 1,
      ...data,
      
    }

    this.setState({ loadTask: loadTask, historySearch: dataPage });

    this.props.get_supplier_billing_list(dataPage).then(({ task }) => {
      this.setState({ loadTask: task });
    });
  };

  reloadPage = () => {
    this.props
      .get_supplier_billing_list(this.state.historySearch)
      .then(({ task }) => {
        this.setState({ selected: null });
      });
  };

  componentDidMount() {
    this.props.get_domain();
    this.props.get_supplier_billing_filters();
  }

  createSupplierBilling = (data) => {
    return this.props.create_supplier_billing(data).then(({ task, errors }) => {
      this.props.get_supplier_billing_list(this.state.historySearch);

      return { task, errors };
    });
  };

  closeSupplierBillingModal = (e) => {
    this.setState({ showSupplierBillingModal: false });
  };

  onSelectAction = (e, record) => {
    this.setState({ selected: record });
  };

  sendNf = async (nf) => {
    const dataSend = {
      id: this.state.selected.id,
      bPoolNFSe: nf,
    };

    await this.props.update_nfs2(dataSend).then((response) => {
      if (response?.status == 200) {
        this.props.get_supplier_billing_list(this.state.historySearch);
      }
    });

    return true;
  };

  onConfirmResendAction = (e, record) => {
    const resendNotification = () => {
      const data = {
        supplierBillingId: record.id,
      };

      return this.props.supplier_billing_notify(data);
    };

    confirm({
      title: I18n.get(
        "Deseja enviar o e-mail de instruções de faturamento para o fornecedor abaixo?"
      ),
      content: <SupplierBillingCard data={record} />,
      width: 720,
      okText: I18n.get("Enviar"),
      cancelText: I18n.get("Cancelar"),
      onOk: () => resendNotification(),
    });
  };

  onConfirmDeleteAction = (e, record) => {
    const deleteSupplierBilling = () => {
      const data = {
        supplierBillingId: record.id,
      };

      return this.props.delete_supplier_billing(data).then(() => {
        this.props.get_supplier_billing_list(this.state.historySearch);
      });
    };

    confirm({
      title: I18n.get("Deseja excluir o Faturamento Extraordinário abaixo?"),
      content: <SupplierBillingCard data={record} />,
      width: 720,
      okText: I18n.get("Excluir"),
      cancelText: I18n.get("Cancelar"),
      okType: "danger",
      onOk: () => deleteSupplierBilling(),
    });
  };

  getPos = (arr) => {
    this.setState({
      listPo: arr,
    });
  };

  handlegenerate = (type = 1) => {
    //1 = Faturamento
    //2 = Repasse

    const dataSend = {
      ids: this.state.listPo,
      type: type,
    };

    this.props.generate_financial(dataSend);
  };

  render() {
    const { bp = {} } = this.props;
    const { adminSupplierBillingFilter = {}, adminSupplierBillingList = [] } =
      bp;
    const { clients = [] } = adminSupplierBillingFilter;


    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li className="is-active">
              <NavLink to="/bpool/faturamento" className="is-black">
                {I18n.get("Faturamento")}
              </NavLink>
            </li>
          </ul>
        </nav>

        <Row gutter={6}>
          <Col sm={24}>
            <CardSimple>
              <FormFilterSupplierBilling
                {...this.props}
                filters={adminSupplierBillingFilter}
                action={this.handleFilters}
                createAction={(e) =>
                  this.setState({ showSupplierBillingModal: true })
                }
                task={this.state.loadTask}
              />
            </CardSimple>
          </Col>
        </Row>

        <br />

        <Row gutter={6}>
          <Col sm={24}>
            <CardSimple>
              {adminSupplierBillingList?.data && !adminSupplierBillingList?.data.length && (
                <NoData>
                  <h3 className="title-bpool medium">
                    {I18n.get("Escolha as informações para a busca!")}
                  </h3>

                  {!this.state.loadTask.busy && (
                    <p className="icone">
                      <Icon type="search" />
                    </p>
                  )}

                  {this.state.loadTask.busy && (
                    <p className="icone">
                      <Icon type="loading" />
                    </p>
                  )}
                </NoData>
              )}

              {adminSupplierBillingList?.data && (
                <div>
                  {this.state.task.busy && (
                    <NoData>
                      <p className="icone">
                        <Icon type="loading" />{" "}
                        <span className="txt">Atualizando</span>
                      </p>
                    </NoData>
                  )}

                  <p className="BtnEx">
                    <Button
                      disabled={this.state.listPo.length == 0}
                      className="button bt-bpool black pull-right"
                      onClick={() => this.handlegenerate(1)}
                    >
                      {I18n.get("Faturamento")}
                    </Button>

                    <Button
                      disabled={this.state.listPo.length == 0}
                      className="button bt-bpool black pull-right"
                      onClick={() => this.handlegenerate(2)}
                    >
                      {I18n.get("Repasse")}
                    </Button>
                  </p>

                  <TableBillings
                    data={adminSupplierBillingList?.data}
                    items={adminSupplierBillingList?.pagination?.totalItems}
                    selectAction={this.onSelectAction}
                    resendAction={this.onConfirmResendAction}
                    deleteAction={this.onConfirmDeleteAction}
                    listPo={this.state.listPo}
                    getPos={this.getPos}
                    pagination={true}
                    handleFilters={this.handleFilters}
                    historySearch={this.state.historySearch}

                    {...this.props}
                  />
                </div>
              )}
            </CardSimple>
          </Col>
        </Row>

        <Modal
          title={I18n.get("Novo Faturamento Extraordinário")}
          visible={this.state.showSupplierBillingModal}
          footer={false}
          onCancel={this.closeSupplierBillingModal}
          width={720}
        >
          <FormSupplierBilling
            {...this.props}
            clients={clients}
            action={this.createSupplierBilling}
          />
        </Modal>

        {this.state.selected && (
          <>
            <Modal
              title={I18n.get("Faturamento Extraordinário")}
              visible={true}
              footer={false}
              onCancel={(e) => this.setState({ selected: null })}
              width={720}
            >
              <SupplierBillingDetails
                data={this.state.selected}
                setValue={this.sendNf}
                reloadPage={this.reloadPage}
              />
            </Modal>
          </>
        )}
      </LoggedPage>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierBillingListAdmin);
