import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'

import LoggedPage from '../page-templates/loggedAdmin';

import Status from '../../status';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class Dashboard extends React.Component {

    constructor(props) {

        super();
        this.partner_chart = React.createRef();

        this.state = {
        };
    }

    componentDidMount() {
        this.props.get_dashboard();
    }


    render() {

        if (!this.props.bp || !this.props.bp.dashboard) return null;

        const dashboard = this.props.bp.dashboard;

        return (
            <section className="info-tiles">
                <div className="tile is-ancestor has-text-centered">
                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            <p className="title">{this.props.bp.dashboard.partnerCount}</p>
                            <p className="title-bpool medium">{I18n.get("Parceiros")}</p>
                        </article>
                    </div>
                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            <p className="title">{this.props.bp.dashboard.clientCount}</p>
                            <p className="title-bpool medium">{I18n.get("Clientes")}</p>
                        </article>
                    </div>
                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            <p className="title">{this.props.bp.dashboard.clientProjectCount}</p>
                            <p className="title-bpool medium">{I18n.get("Projetos")}</p>
                        </article>
                    </div>
                </div>

                <div className="columns">

                    <div className="column is-4">

                        <div className="box">

                            <table className="table is-narrow is-fullwidth">
                                <tbody>
                                    {dashboard.partnerStatusGroups.map((item, index) =>
                                        (<tr key={index}>
                                            <td>
                                                <NavLink to={`/bpool/parceiros?status=${item.id}`} className="is-black">{I18n.get(Status.PartnerStatus[item.id])}</NavLink>
                                            </td>
                                            <td className="has-text-right">
                                                <p>{item.count}</p>
                                            </td>
                                        </tr>)
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div className="column is-4">

                        <div className="box">

                            <table className="table is-narrow is-fullwidth">
                                <tbody>
                                    {dashboard.clientStatusGroups.map((item, index) =>
                                        (<tr key={index}>
                                            <td>
                                                <NavLink to={`/bpool/clientes?status=${item.id}`} className="is-black">{I18n.get(Status.ClientStatus[item.id])}</NavLink>
                                            </td>
                                            <td className="has-text-right">
                                                <p>{item.count}</p>
                                            </td>
                                        </tr>)
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div className="column is-4">

                        <div className="box">

                            <table className="table is-narrow is-fullwidth">
                                <tbody>
                                    {dashboard.clientProjectStatusGroups.map((item, index) =>
                                        (<tr key={index}>
                                            <td>
                                                <NavLink to={`/bpool/projetos?status=${item.id}`} className="is-black">{I18n.get(Status.ProjectStatus[item.id])}</NavLink>
                                            </td>
                                            <td className="has-text-right">
                                                <p>{item.count}</p>
                                            </td>
                                        </tr>)
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>

            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
