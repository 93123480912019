import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import ScheduleForm from './schedule-form';

import Steps from './steps';

import { scrollToTop } from '../../utils/ui';

class ProjectOnGoingSchedule extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount() {

        scrollToTop();
    }

    submitForm(e) {

    }


    render() {


        return (
            <FillForm>
                <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">

                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-6" style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
                                <p className="title">{I18n.get("Projeto em Andamento")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-6">
                                {this.props.isCreative && <>
                                    <Steps active={4} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []} />
                                </>}
                                {this.props.isPartnerSelectionBidding && <>
                                    <Steps active={3} project={this.props.project} hidden={this.props.auth.is_partner ? [4] : []} />
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-ongoing">

                    <div className="container client-container-1 container-content">

                        <ScheduleForm {...this.props} />

                        <br />
                        <br />


                    </div>

                    <div className="columns">

                        <div className="column">
                        </div>

                        <div className="column">

                            <div className="field is-grouped is-grouped-centered container-content p-btns">
                                <p className="control is-expanded" onClick={this.scrollToTop}>
                                    <Link to={`/projetos/${this.props.match.params.projectId}/arquivos`}
                                        className="button bt-bpool grey is-fullwidth previous">{I18n.get("Voltar")}</Link>
                                </p>
                                <p className="control is-expanded" onClick={this.scrollToTop}>

                                    {this.props.auth.is_partner && <>

                                        <Link to={`/projetos/${this.props.match.params.projectId}/extras`} className="button bt-bpool black is-fullwidth next">{I18n.get("PRÓXIMO")}</Link>
                                    </>}

                                    {!this.props.auth.is_partner && <>

                                        <Link to={`/projetos/${this.props.match.params.projectId}/faturamento`} className="button bt-bpool black is-fullwidth next">{I18n.get("PRÓXIMO")}</Link>
                                    </>}
                                </p>
                            </div>

                        </div>

                        <div className="column">
                        </div>


                    </div>


                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(ProjectOnGoingSchedule);
