import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { Row, Col } from 'antd';


const HeaderPage = styled.div`
    padding: 40px 0;
    border-bottom: solid 1px #000;
    color: #353535;
    margin-bottom: 40px;

    h2 {
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 13px;
        font-weight: 600;
        line-height: 21px;
    }
`;



export class SimpleHeader extends PureComponent {
    render() {
        const title = this.props.title || "";
        const description = this.props.description || "";

        return (
            <HeaderPage>
                <Row>
                    <Col sm={24}>
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </Col>
                </Row>
            </HeaderPage>
        );
    }
}


