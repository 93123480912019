import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import LandingPage from "../page-templates/landingClean";

import { AUTH_API } from "../../actions/auth/actions";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      dispatch(AUTH_API.set_password(data));
    },
  };
};

class FirstAccessForm extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      password1: "",
      password2: "",
    };

    this.changeEmail = this.onChangeEmail.bind(this);
    this.changePassword1 = this.onChangePassword1.bind(this);
    this.changePassword2 = this.onChangePassword2.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentWillUnmount() {}

  componentDidMount() {
    let lang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    lang =
      lang ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage;

    let language = lang.split("-")[0];

    if (language == "pt" || language == "es" || language == "en") {
      I18n.setLanguage(language);
    } else {
      I18n.setLanguage("pt");
    }
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value?.trim() });
  }

  onChangePassword1(e) {
    this.setState({ password1: e.target.value });
  }

  onChangePassword2(e) {
    this.setState({ password2: e.target.value });
  }

  submitForm(e) {
    e.preventDefault();
    this.props.onSubmit(this.state);
  }

  render() {
    //console.dir(this.props);

    if (this.props.auth && !this.props.auth.new_password_required) {
      return <Redirect to="/home" />;
    }

    return (
      <LandingPage>
        <div className="column is-4 is-offset-4">
          <h3 className="title has-text-grey">{I18n.get("Primeiro acesso")}</h3>
          <p className="subtitle has-text-grey">
            {I18n.get("Por favor escolha uma senha.")}
          </p>
          <div className="box">
            <form onSubmit={this.submitForm}>
              <div className="field">
                <div className="control">
                  <input
                    onChange={this.changeEmail}
                    className="input is-large"
                    type="email"
                    placeholder={I18n.get("Email")}
                    readOnly="readonly"
                    autoFocus=""
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <input
                    onChange={this.changePassword1}
                    className="input is-large"
                    type="password"
                    placeholder={I18n.get("Senha")}
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <input
                    onChange={this.changePassword2}
                    className="input is-large"
                    type="password"
                    placeholder={I18n.get("Confirmação senha")}
                  />
                </div>
              </div>

              <button className="button is-block is-info is-large is-fullwidth">
                {I18n.get("Entrar")}
              </button>
            </form>
          </div>
          <p className="has-text-grey">
            {/* <Link to="/ajuda">Ajuda</Link> */}
          </p>
        </div>
      </LandingPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstAccessForm);
