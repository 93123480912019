import React, { PureComponent } from "react";
import { I18n } from 'aws-amplify';
import { Icon, Row, Col, Tag, Tooltip } from 'antd';

import { CardBorder } from "../../components/Cards";
import Carousel from "../../components/Carousel";
import ListLinks from "../../components/ListLinks";
import { h4, TitleMiddle } from "../../components/Titles";

import NotRegistered from "../../components/NotRegistered";

import styled from "styled-components";

const Box = styled.div`

.ant-row-flex {
    margin-bottom: 20px;
}
p {
    font-size: 13px;
    line-height: 16px;
}

h4 {
    font-size: 14px;
    font-weight: bold;
    border-bottom: solid 1px #ccc;
    margin-bottom: 15px;
}

h3 {
    margin: 10px 0 20px;
}

.Sizing {
    width: 100%;
    max-width: 800px;
    margin: 50px auto;
}
    .ItemImg {
        margin: 0 2px;
        overflow: hidden;
        display: block;
        padding: 2px;
        max-width: 122px;

        img {
            width: calc(100% - 4px);
            display: block;
        }
    }
`

class ItemCourse extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const {
            name,
            duration,
            targetPeople,
            format,
            description,
            videos,
            images,
        } = this.props.item;

        return (
            <Box>
                <Row className="" type="flex">
                    <Col sm={24}>
                        <h3>{name}</h3>
                    </Col>
                </Row>
                <Row className="" type="flex">
                    <Col sm={8}>
                        <CardBorder
                            Bcolor="#f2f2f2"
                        >
                            <h4>{I18n.get("Duração")}</h4>
                            <p>{duration}</p>
                        </CardBorder>
                    </Col>
                    <Col sm={8}>
                        <CardBorder
                            Bcolor="#f2f2f2"
                        >
                            <h4>{I18n.get("Para quem é?")}</h4>
                            <p>{targetPeople}</p>
                        </CardBorder>
                    </Col>
                    <Col sm={8}>
                        <CardBorder
                            Bcolor="#f2f2f2"
                        >
                            <h4>{I18n.get("Formato")}</h4>
                            <p>{format}</p>
                        </CardBorder>
                    </Col>
                </Row>
                <Row className="" type="flex">
                    <Col sm={24}>
                        <CardBorder
                            Bcolor="#f2f2f2"
                        >
                            <h4>{I18n.get("Descrição do curso ou treinamento")}</h4>
                            <p>{description}</p>
                        </CardBorder>
                    </Col>
                </Row>

                <Row className="" type="flex">
                    <Col sm={16}>
                        <CardBorder
                            Bcolor="#f2f2f2"
                        >
                            <h4>{I18n.get("Fotos")}</h4>
                            {images.length == 0 &&
                                <NotRegistered
                                    text="Nenhuma foto foi cadastrada!"
                                />
                            }
                            {images.length > 0 &&
                                <div className="Sizing">
                                    <Carousel
                                        dots={false}
                                        infinite={false}
                                        slidesToShow={images.length == 1 ? 1 : 3}
                                        slidesToScroll={1}
                                        variableWidth={false}
                                    >
                                        {images.map((item, index) => {
                                            return (
                                                <div key={index} className="ItemImg">
                                                    <img src={item} width={images.length == 1 ? "122px" : "90%"} />
                                                </div>
                                            )
                                        })}

                                    </Carousel>
                                </div>
                            }
                        </CardBorder>
                    </Col>
                    <Col sm={8}>
                        <CardBorder
                            Bcolor="#f2f2f2"
                        >
                            <h4>{I18n.get("Vídeos")}</h4>

                            {videos.length == 0 &&
                                <NotRegistered
                                    text="Nenhum vídeo foi cadastrado!"
                                />
                            }
                            {videos.length > 0 &&
                                <ListLinks data={videos} icon="youtube" />
                            }
                        </CardBorder>
                    </Col>
                </Row>


            </Box>
        );
    }
}

export default ItemCourse;
