import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import { Task } from '../../utils/task';


import styled from 'styled-components';

import { CardSimple } from '../../modules/components/Cards';
import FormFilterClient from '../../modules/Admin/components/filterClientsAdmin';
import TableClients from '../../modules/Admin/components/tableClientsAdmin';

import ClientList from './client/_list';

import {
    Row,
    Col,
    notification,
    Icon
} from 'antd';

const NoData = styled.div`
    padding: 50px;
    text-align: center;

    h3 {
        font-weight: 700;
        margin-bottom: 30px;
    }

    i {
        font-size: 40px;
    }

    .txt {
        position: relative;
        left: 30px;
        top: -15px;
    }
`;

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_clients: data => {
            dispatch(BP_API.get_clients(data));
        },
        get_filters_clients: () => {
            dispatch(BP_API.get_filters_clients());
        },
        get_clients_list: data => {
            dispatch(BP_API.get_clients_list(data));
        },
        api_update_trained: data => {
            return  dispatch(BP_API.api_update_trained(data));
        }
    };
};

class ClientListAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
            task: {}
        };
    }

    handleFilters = (data) => {
        var task = new Task();

        task.start();

        this.setState({ task: task, historySearch: data }, function () {
            this.props.get_clients_list(data);
        });

    }

    getUpdate = () => {
        var task = new Task();

        task.start();

        this.setState({ task: task}, function () {
            this.props.get_clients_list(this.state.historySearch);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {
            this.setState({ task: task });
        }

    }

    componentDidMount() {
        // this.props.reset();
        this.props.get_filters_clients();
    }

    render() {
        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/clientes" className="is-black">{I18n.get("Clientes")}</NavLink></li>
                    </ul>
                </nav>


                <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            <FormFilterClient
                                filters={this.props.bp && this.props.bp.adminClientFilters}
                                action={this.handleFilters}
                                task={this.state.task}
                            />
                        </CardSimple>
                    </Col>
                </Row>
                <br />

                <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            {!this.props.bp.adminClientsList &&
                                <NoData>
                                    <h3 className="title-bpool medium">{I18n.get("Escolha as informações para a busca!")}</h3>

                                    {!this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="search" />
                                        </p>
                                    }

                                    {this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="loading" />
                                        </p>
                                    }
                                </NoData>

                            }

                            {this.props.bp.adminClientsList &&
                                <div>
                                    {this.state.task.busy &&
                                        <NoData>
                                            <p className="icone">
                                                <Icon type="loading" /> <span className="txt">Atualizando</span>
                                            </p>
                                        </NoData>
                                    }
                                    <TableClients
                                        data={this.props.bp.adminClientsList && this.props.bp.adminClientsList}
                                        getUpdate={this.getUpdate}
                                        {...this.props}
                                    />
                                </div>
                            }
                        </CardSimple>
                    </Col>
                </Row>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientListAdmin);
