import React from 'react';

import { I18n } from 'aws-amplify';

import NumberFormat from 'react-number-format';

import TooltipField from './tooltip-field';


export default class PercentageField extends React.Component {

    constructor(props) {
        super();

        this.state = {
            focused: false,
            visited: props.visited
        };
    }

    static defaultProps = {
    }

    onBlur() {
        this.setState({ focused: false });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onFocus() {
        this.setState({ focused: true, visited: true });
    }

    onChange(e){

        this.props.onChange(e);
    }

    render() {

        const className = this.props.className || 'field';
        const inputClassName = this.props.inputClassName || 'input';

        const { value = '' , decimalScale = 2 } = this.props;

        const isError = !this.state.focused && this.state.visited && this.props.error;

        return (<div className={className}>
            {this.props.label && <label className="label is-small">
                {this.props.label} <TooltipField {...this.props} />
            </label>}
            <div className="control has-icons-right">
                <NumberFormat 
                        value={value} 
                        thousandSeparator='.' 
                        decimalSeparator=',' 
                        prefix={''} 
                        decimalScale={decimalScale} 
                        fixedDecimalScale={true}
                        onChange={(e) => this.onChange(e)}
                        onBlur={() => this.onBlur()}
                        onFocus={() => this.onFocus()}
                        className={isError ? inputClassName + " is-danger" : inputClassName}
                        readOnly={this.props.readOnly || false}
                        disabled={this.props.disabled || false}
                    />
                    {/* Sobrepoe o zIndex do bulma para icones que acaba gerando problemas de sobreposcao */}
                <span className="icon is-small is-right" style={{ zIndex: 'auto' }}>
                    <strong>%</strong>
                </span>
            </div>
            {isError && <p className="help is-danger">
                {this.props.error.errorMessage}
            </p>}
        </div>)
    }
}

