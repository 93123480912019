import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import { I18n } from 'aws-amplify';
import { notification } from 'antd';
import Config from '../../config';

import { BP_ASSETS_PARTNERS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_asset_approve = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            const reason = {
                reasonApprovedDeclined: data.reasonApprovedDeclined
            };

            return axios.put(`${Config.API_BASE_URL}/bp/partner-asset/${data.partnerAssetId}/approve/${data.approved}`, reason, config)
                .then(response => {
                    dispatch({
                        type: BP_ASSETS_PARTNERS_GET,
                        payload: {
                            assetsPartners: response.data,
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    });

                    let msg = data.approved ? I18n.get("aprovado") : I18n.get("reprovado");

                    notification.success({
                        message: I18n.get("Asset @@msg@@ com sucesso!").replace("@@msg@@", msg),
                        description: I18n.get("Você @@msg@@ o asset, o parceiro será informado.").replace("@@msg@@", msg),
                        duration: 3
                    });
                })
                .catch(error => {
                    dispatch({
                        type: BP_ASSETS_PARTNERS_GET,
                        payload: {
                            errors: {},

                            task: {
                                result: false
                            }
                        }
                    })
                });

        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_asset_approve;
