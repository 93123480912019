import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col, message } from "antd";
import { Loggedv2 } from "../components/Loggedv2";
import Main from "../../../components/UI/Main";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import Loading from "../../../components/UI/Loading";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";
import { ButtonExpandSelect } from "../components/ButtonExpandSelect";
import { useRegistrationFlow } from "../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";

import * as S from "./styles";
import { urls } from "../utils/stepsUrls";
import {
  toGetFlowStorage,
  toOrganizeSteps,
  toflowToStorage,
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
  toRemoveAnStep,
  toUpdateIsStartupInSteps,
} from "../utils/flowToStorage";
import { statusIdToName } from "../components/Loggedv2/statusIdToName";

const MESSAGE_ERROR_GET_PAGE = "There was an error, try again.";
const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const PAGE_INDEX = 3;

export const BusinessType = () => {
  const { getStatusBarPre, getBusinessType, updateBusinessType } =
    useRegistrationFlow();
  let history = useHistory();

  const [isLoadPage, setIsLoadPage] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [selectsData, setSelectsData] = useState([]);
  const [selectsDataDefaults, setSelectsDataDefaults] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [errorsPage, setErrorsPage] = useState([]);
  const [limitItens, setLimitItens] = useState([]);

  const loadInit = async () => {
    setIsLoadPage(true);

    const response = await getBusinessType({ admin: false });

    if (response.success) {
      const arrData = response.data.map((item) => ({
        ...item,
        selected: item.disciplineChildren.some(
          (item) => item.selected === true
        ),
      }));
      setSelectsData(arrData);
    } else {
      message.error(I18n.get(MESSAGE_ERROR_GET_PAGE));
    }

    setIsLoadPage(false);
  };

  useEffect(() => {
    filterItens();
  }, [selectsData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  const newGetStepbar = async ({ isStartup }) => {
    const responseStepbar = await getStatusBarPre();
    if (!isStartup) {
      const idStepIndustry = "63a44ef7081d04bd13b73159";
      const flow = await toGetFlowStorage({
        key: "@BPOOL-registration-flow-steps",
      });
      const hasIndustry = flow.steps.find((step) => step.id === idStepIndustry);

      if (!hasIndustry) {
        const stepsOrganizeds = await toOrganizeSteps({
          arrSteps: responseStepbar.data.statusBarRegistration,
          contextStepBar: "pre",
          pageIndex: PAGE_INDEX,
          urls,
        });

        const stepsOrganizedsWithVisiteds = stepsOrganizeds.map((step) => ({
          ...step,
          visited:
            flow.steps.find((item) => item.id === step.id)?.visited || false,
        }));

        const toStorage = {
          stepbarId: "stepbar-pre",
          steps: stepsOrganizedsWithVisiteds,
        };

        await toflowToStorage({
          key: "@BPOOL-registration-flow-steps",
          stepbar: toStorage,
        });
      }
    }

    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);

    const arrSelecteds = [];
    selectsData.map((item) =>
      item.disciplineChildren.map((sub) =>
        sub.selected === true ? arrSelecteds.push(sub.code) : null
      )
    );

    const selecteds = selectsData.filter((item) => item.selected === true);
    const isStartup = selecteds.find(
      (select) => select.code === "MARTECADTECH"
    );

    if (isStartup) {
      const idStepIndustry = "63a44ef7081d04bd13b73159";
      await toRemoveAnStep({ stepToRemove: idStepIndustry });
      await toUpdateIsStartupInSteps({ status: true });
    }

    const dataSend = {
      adminAction: false,
      codes: arrSelecteds,
    };

    const response = await updateBusinessType({ admin: false, partnerId: null, dataSend: dataSend });

    await newGetStepbar({ isStartup: Boolean(isStartup) });

    if (response?.errors?.length) {
      setErrorsPage(response?.errors);
      setOpenModalConfirm(true);
    } else {
      await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
      const indexUrlIsStartup = PAGE_INDEX + 2;
      history.push({
        pathname: urls[isStartup ? indexUrlIsStartup : PAGE_INDEX + 1],
      });
    }

    setIsSending(false);
  };

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const handleChangeSelects = (arrChanged) => {
    const dataTemp = [...selectsData];
    const indexObj = dataTemp.findIndex(
      (item) => item.code === arrChanged.code
    );

    if (arrChanged.code === "MARTECADTECH") {
      dataTemp[indexObj].disciplineChildren = arrChanged.arr;

      const test = dataTemp.map((item) => {
        if (item.code === "MARTECADTECH") {
          return {
            ...item,
            selected: item.disciplineChildren.some(
              (item) => item.selected === true
            ),
          };
        } else {
          return {
            ...item,
            disciplineChildren: item.disciplineChildren.map((subItem) => ({
              ...subItem,
              selected: false,
            })),
            selected: false,
          };
        }
      });

      setSelectsData(test);
    } else {
      dataTemp[indexObj].disciplineChildren = arrChanged.arr;

      const test = dataTemp.map((item) => {
        if (item.code === "MARTECADTECH") {
          return {
            ...item,
            disciplineChildren: item.disciplineChildren.map((subItem) => ({
              ...subItem,
              selected: false,
            })),
            selected: false,
          };
        } else {
          return {
            ...item,
            selected: item.disciplineChildren.some(
              (item) => item.selected === true
            ),
          };
        }
      });

      setSelectsData(test);
    }
  };

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const filterItens = () => {
    const limitItens = [];

    const filterArray = selectsData.map((item) => {
      const filterArrayChildren = item?.disciplineChildren.map((children) => {
        if (children.selected) {
          limitItens.push(children);
        }
      });
    });

    setLimitItens(limitItens);
    return null;
  };

  const disabled = limitItens.length;

  return (
    <Loggedv2
      pageIndex={PAGE_INDEX}
      statusBarStepsClickable
      statusChangeInfo={changeInfo}
      hookGetItems={getStatusBarPre}
      stepbarId="stepbar-pre"
      urls={urls}
      contextStepBar="pre"
    >
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("BusinessType")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get("Tell us about your speciatly services.")}
                <br />
                <strong>
                  {I18n.get(
                    "You may select up to 4 specialities in each category or across Agency and Studio. But once you select Martech & Adtech you will not be able to select either of the previous categories."
                  )}
                </strong>
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>
        <Row>
          {isLoadPage ? (
            <Col xs={24} style={{ marginTop: 40 }}>
              <div>
                <Loading
                  sizeIcon={18}
                  text={I18n.get("Loading informations")}
                  sizeText={14}
                  align="center"
                />
              </div>
            </Col>
          ) : (
            selectsData.map((item) => (
              <Col key={item.id} xs={24} style={{ marginTop: 40 }}>
                <ButtonExpandSelect
                  data={item}
                  expanded={item.expanded}
                  onChange={(changed) => handleChangeSelects(changed)}
                  error={Boolean(errorsPage.length)}
                  // isDisabled={disabled}
                  isLimit={disabled > 3}
                />
              </Col>
            ))
          )}
        </Row>

        {/* {disabled == 4 && (
          <Row style={{ marginTop: 30 }}>
            <Col xs={24} sm={12}>
              <p style={{ color: "#f00" }}>
                {I18n.get(
                  "You may select up to 4 specialities in each category."
                )}
              </p>
            </Col>
          </Row>
        )} */}

        <Row style={{ marginTop: 30 }}>
          <Col xs={24} sm={12}>
            <ButtonBpool
              text={I18n.get("Back")}
              theme="secondary"
              full
              onClick={handleBackPage}
            />
          </Col>
          <Col xs={24} sm={12}>
            <ButtonBpool
              text={I18n.get("Next")}
              theme="primary"
              full
              loading={isSending}
              onClick={send}
              disabled={disabled == 0}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get("Missing required information")}
        subBodyText={I18n.get(
          "Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?"
        )}
        okText={I18n.get("Yes")}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get("Close")}
        typeModal="isConfirm"
        // isDanger
      />
    </Loggedv2>
  );
};
