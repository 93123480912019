import React from 'react';

import { I18n } from 'aws-amplify';

const Card = (props) => {

    if (!props.bp || !props.bp.partner) return null;

    const item = props.bp.partner;

    var linkSignupPre = "https://app.bpool.co/#/parceiro/verifica-pre-cadastro/" + item.verificationToken;

    var linkSignupFull = "https://app.bpool.co/#/parceiro/verifica-email/" + item.verificationToken;

    var link = "";

    if (item.status === 1)
        link = linkSignupPre;
    else if (item.status === 5)
        link = linkSignupFull;

    return (
        <div className="card">
            <div className="card-content">
                <h3 className="title is-4">{I18n.get("Responsável")}</h3>
                <div className="content">
                    <a target="_blank" href={ link }>{ link }</a>
                </div>
            </div>
        </div>
    );
};

export default Card;
