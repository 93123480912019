import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AlertCompletion = styled.div`
  background-color: #c6f1da;
  padding: 16px;
  border-radius: 8px;
  span {
    margin-left: 8px;
    font-size: 14px;
    color: #36694e;
  }
`;
