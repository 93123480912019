import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { connect } from 'react-redux';

import WhatifWrapper from '../components/WhatifWrapper';
import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import MegaSearch from '../components/MegaSearch';

import CardIdea from '../components/CardIdea';

import { IDEA_WHATIF_API } from '../../../actions/idea-whatif/actions';
import { CLIENT_WHATIF_API } from '../../../actions/client-whatif/actions';
import { MENU_CLIENT_WHATIF } from '../constants';

import {
    Row,
    Col
} from 'antd';

const Main = styled.div`
  margin: 0 auto 100px;
  padding-top: 80px;

  .NavTabs {
      margin-bottom: 70px;
      border-top: solid 1px #d3d3d3;
      border-bottom: solid 1px #d3d3d3;
      display: inline-block;
      li {
          display: inline-block;

          a {
              display: block;
              color: #353535;
              height: 40px;
              font-size: 12px;
              font-weight: 700;
              line-height: 42px;
              backgorund: transparent;
              padding: 0 25px;
              transition: all .3s ease;

              &:hover, &:focus {
                background: #ccc;
              }

              &.active {
                  background: #353535;
                  color: #fff;
              }
          }
      }
  }
`;

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        api_get_ideas: id => {
            dispatch(IDEA_WHATIF_API.api_get_ideas(id));
        },
        get_products: () => {
            dispatch(CLIENT_WHATIF_API.get_products());
        },
        search_ideas: (data) => {
            dispatch(IDEA_WHATIF_API.search_ideas(data));
        }
    };
};


class SearchIdea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientIdStatus: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.client != prevProps.client || this.state.clientIdStatus != prevState.clientIdStatus) {
            const { client: { id } } = this.props;
            this.props.api_get_ideas(id);

        }
    }

    componentDidMount = () => {
        this.setState({
            clientIdStatus: true
        });

        this.props.get_products();
    }

    componentWillMount = () => {
        this.setState({
            clientIdStatus: false
        });
    }

    handleSearch = (data) => {

        data.clientId = this.props.client.clientId;

 

        this.props.search_ideas(data);
    }

    render() {
        if (!this.props.client) {
            return null
        }
 
        const {
            ideaWhatIf: {
                ideas
            },
            clientWhatIf: {
                products
            }

        } = this.props;



        return (
            <WhatifWrapper
                grey
            >
                <SubHeader titlePage="Buscar Ideias de Pró-atividade e Conteúdo" itensMenu={MENU_CLIENT_WHATIF} activeItem={2} />
                <MegaSearch
                    products={products}
                    actionSearch={this.handleSearch}
                />
                <ContainerPage>

                    <Main>
                        <Row>
                            {ideas && ideas.map((item, index) => {
 
                                return (
                                    <Col sm={6} key={index}>
                                        <CardIdea
                                            status="draft"
                                            idea={item}
                                            path={`/cliente/ideias/recebida/${item.whatIfIdeaId}`}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                    </Main>
                </ContainerPage>
            </WhatifWrapper>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchIdea);
