import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import { Icon, Drawer, Button, Tooltip, Tag, Row, Col } from "antd";

import ReactPlayer from "react-player";

import { codeToLabel } from "../../utils/helpers";

import Carousel from "./Carousel";

import ItemPortfolio from "../Register/components/ItemPortfolio";
import ItemCausa from "../Register/components/ItemCause";
import ItemCase from "../Register/components/ItemCase";
import ItemAward from "../Register/components/ItemAward";

import TableCourse from "../Register/components/tableCourse";

import IcoInfo from "../../image/icot3-dark.svg";
import IcoClass from "../../image/class.svg";
import IcoHabilidades from "../../image/ico-habilidades.png";
import IcoIdiomas from "../../image/ico-idiomas.png";
import IcoMarcas from "../../image/ico-marcas.png";
import IcoPortifolio from "../../image/ico-portfolio-new.svg";
import IcoTags from "../../image/ico-new-tags.png";
import IcoClients from "../../image/ico-new-clientes.svg";
import IcoEspecialidades from "../../image/ico-new-especialidades.svg";
import IcoCausas from "../../image/ico-new-causas.svg";
import IcoCases from "../../image/ico-new-cases.svg";
import IcoPremios from "../../image/ico-new-premios.svg";
import IcoRef from "../../image/ico-referencias.png";
import IcoLider from "../../image/ico-lideres.svg";
import Logo from "../../image/no-photo.png";
import Dphoto from "../../image/dphoto.png";
import DiversityIcon from "../../svg/ico-diversidade.svg";
// import DiversityIcon from '../../svg/ico-diversidade.svg';

const MainInfos = styled.div`
  .BoxIntro {
    margin-bottom: 50px;
  }

  .itemLider {
    box-sizing: border-box;
    padding: 0 5px;
    height: 100%;

    img {
      width: 90%;
      margin-bottom: 10px;
    }

    .box {
      border-radius: 0;
      background: #fff;
      border: solid 1px #000;
      margin: 0 3px;
      height: 100%;
    }

    p {
      font-size: 13px;
    }

    .lineEmail {
      border-top: #eeeeee solid 1px;
      padding-top: 5px;
    }
  }

  .boxReference {
    .info {
      color: #353535;
      font-size: 13px;
      border-right: solid 2px #707070;
    }
    .comment {
      padding: 0 20px;
      color: #353535;
      font-size: 13px;
    }
  }

  .itemWork {
    width: 172px;
    padding: 0 20px;
    cursor: pointer;
    opacity: 1;
    transition: all 0.5s ease;
    position: relative;

    &:hover {
      opacity: 0.5;
    }

    .thumbWork {
      text-align: center;
      background: #f7f7f7;
      border-radius: 10px;
      overflow: hidden;
    }

    img {
      height: 125px;
      max-width: 100%;
      display: inline-block;
    }

    h4 {
      color: #3d3d3d;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      margin-top: 15px;
    }
  }

  .paList {
    span:last-child {
      i {
        display: none;
      }
    }
  }

  .divisor {
    border-bottom: solid 1px #707070;
    margin: 30px 0;
  }

  .titleName {
    padding-left: 240px;
  }
  .PartnerName {
    padding: 50px 0 0px;
    overflow: hidden;

    h2 {
      font-size: 20px;
      font-weight: 600;
    }

    h4 {
      font-size: 13px;
      font-weight: 600;
      line-height: 25px;
    }

    .ThumbAvatar {
      float: left;
      margin-right: 50px;
      position: relative;
      top: -40px;
    }
  }

  .boxPreview {
    margin-bottom: 60px;
    background: no-repeat left top;
    background-size: 40px;

    &.PartnerInfos {
      background-image: url(${IcoInfo});
      margin-bottom: 0;
    }

    &.PartnerCourses {
      background-image: url(${IcoClass});
      margin-bottom: 0;
      margin-top: 40px;
      padding: 10px 0 15px;
    }

    &.PartnerCausas {
      margin-bottom: 0;
      padding: 10px 0 15px;
      background-image: url(${IcoCausas});
    }

    &.PartnerCases {
      margin-bottom: 0;
      padding: 10px 0 15px;
      background-image: url(${IcoCases});
    }

    &.PartnerRef {
      margin-bottom: 0;
      padding: 10px 0 15px;
      background-image: url(${IcoRef});

      h3 {
        margin-bottom: 30px;
      }

      .boxReference {
        margin-bottom: 20px;

        a {
          color: #000;
        }
      }
    }

    &.PartnerPremios {
      margin-bottom: 0;
      padding: 10px 0 15px;
      background-image: url(${IcoPremios});
    }

    &.PartnerLider {
      margin-bottom: 0;
      padding: 10px 0 15px;
      background-image: url(${IcoLider});
    }

    &.PartnerTags {
      margin-bottom: 0;
      padding: 10px 0 15px;
      background-image: url(${IcoTags});

      .boxCard {
        margin-bottom: 0;
      }
    }

    &.PartnerClients {
      margin-bottom: 0;
      padding: 10px 0;
      background-image: url(${IcoClients});

      .boxCard {
        margin-bottom: 0;
      }
    }

    &.PartnerEspecialidades {
      margin-bottom: 0;
      padding: 10px 0;
      background-image: url(${IcoEspecialidades});

      .boxCard {
        margin-bottom: 0;
      }
    }

    &.PartnerRepresentatividade {
      margin-bottom: 0;
      padding: 10px 0;
      background-image: url(${DiversityIcon});

      .setor {
        margin-top: 30px;
      }

      .boxCard {
        margin-bottom: 0;
      }
    }

    &.PartnerHabilidades {
      background-image: url(${IcoHabilidades});
    }

    &.PartnerIdiomas {
      background-image: url(${IcoIdiomas});
    }

    &.PartnerMarcas {
      background-image: url(${IcoMarcas});
    }

    &.PartnerPortfolio {
      background-image: url(${IcoPortifolio});
      min-height: 76px;
      background-size: 43px;
      margin-bottom: 0;

      h3 {
        margin-bottom: 0px;
        padding-top: 30px;
      }

      a {
        display: inline-block;
        padding: 2px 0;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        color: #353535;
        transition: all 0.4s ease;

        i {
          color: #000;
          margin-right: 5px;
          font-size: 16px;
          position: relative;
          top: 1px;
          transition: all 0.4s ease;
          padding: 5px;
          border-radius: 50%;
        }

        &:hover,
        &:focus {
          color: #000;

          i {
            background: #fca99c;
          }
        }
      }
    }
  }

  .boxCard {
    padding: 0 0 0 90px;
    margin-bottom: 30px;

    h3 {
      font-size: 15px;
      font-weight: 700;
      line-height: 15px;
      margin-bottom: 10px;
    }

    ul {
      li {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
      }
    }

    p {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;

      &.space {
        margin: 12px 0;

        .link {
          margin: 0 0 0 10px;
        }
      }
    }
  }

  .ItemCase {
    margin-bottom: 80px;
    h4 {
      font-size: 13px;
      font-weight: 700;
      line-height: 37px;
    }

    p {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
    }

    .ItemImg {
      margin: 0 1px;
    }

    .CarouselBox {
      margin-top: 50px;
    }
  }
`;

class PartnerInfos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPortfolio: false,
      ItemPortfolio: 0,
      showCausa: false,
      ItemCausa: 0,
      showCase: false,
      ItemCase: 0,
      showAward: false,
      ItemAward: 0,
    };
  }

  render() {
    const {
      isClient,
      infos: { company },
    } = this.props;

    let specialismValues =
      this.props.specialismsBP &&
      this.props.infos &&
      this.props.infos.specialisms &&
      this.props.infos.specialisms.map((item) => {
        const specialism =
          (this.props.specialismsBP &&
            this.props.specialismsBP.find((s) => s.code == item)) ||
          {};

        return specialism;
      });

    const leadersOrder =
      this.props.infos.leaders &&
      this.props.infos.leaders.sort(function (a, b) {
        return a.order - b.order;
      });

    const casesOrder =
      this.props.infos.cases &&
      this.props.infos.cases.sort(function (a, b) {
        return a.order - b.order;
      });

    return (
      <Fragment>
        <MainInfos>
          <div className="PartnerName boxPreview">
            {/* {isClient &&
                        <Avatar className="ThumbAvatar" size={205} icon="user" src={imageLogo ? imageLogo : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRjxdE_fZ5XjTyLxIQ9fDoHn4OysIuHiLCHOYDcozZWcgCu-SIX"} />
                    } */}
            <div className="titleName">
              {(company?.diversityLeadership?.length > 0 ||
                company?.diversityShareHolders?.length > 0) && (
                <span className="iconDiv maior" style={{ margin: "10px" }}>
                  <img
                    className="pull-right"
                    src={DiversityIcon}
                    width="60px"
                  />
                </span>
              )}

              <h2>{company && company.name}</h2>
              <h4>{company && company.city}</h4>
              {/* <RatingPreview value={3.5} /> */}
            </div>
          </div>

          <div className="BoxIntro">
            {company && company.introVideo && (
              <ReactPlayer
                url={company.introVideo}
                playing={true}
                loop={true}
                controls={false}
                muted={true}
                pip={false}
                width="100%"
                style={{ width: "100%", margin: "0 auto", minHeight: "500px" }}
              />
            )}

            {company && !company.introVideo && company.introImage && (
              <img src={company.introImage} />
            )}
          </div>

          <div className="PartnerInfos boxPreview">
            <div className="boxCard">
              <h3>{I18n.get("Sobre")}</h3>
              <p>{company && company.description}</p>

              {/* <p className="space">Minha empresa tem <strong>{company && company.isNewCompany ? "MENOS" : "MAIS"} de 6 meses.</strong></p> */}
              <p className="space">
                <strong>{I18n.get("Site:")}</strong>
                <strong className="link">
                  <a href={company && company.website} target="_blank">
                    <Icon type="link" /> {company && company.website}
                  </a>
                </strong>
              </p>
              {company && company.linkedin && (
                <p className="space">
                  <strong>{I18n.get("linkedin:")}</strong>
                  <strong className="link">
                    <a href={company.linkedin} target="_blank">
                      <Icon type="link" /> {company.linkedin}
                    </a>
                  </strong>
                </p>
              )}
            </div>
          </div>

          <div className="divisor" />

          {this.props.infos.specialisms &&
            this.props.infos.specialisms.indexOf("TRE_COACH") > -1 && (
              <div>
                <div>
                  <div className="PartnerCourses boxPreview">
                    <div className="boxCard">
                      <h3>{I18n.get("Treinamento e Coaching")}</h3>
                    </div>
                  </div>

                  <TableCourse
                    pagination={false}
                    data={
                      this.props.infos.specCoursesTraining &&
                      this.props.infos.specCoursesTraining
                    }
                    sectorsFlat={
                      this.props.sectorsFlat && this.props.sectorsFlat
                    }
                    partnerId={this.props.infos && this.props.infos.partnerId}
                    isView={true}
                  />
                </div>
                <div className="divisor" />
              </div>
            )}

          {company && company.tags && company.tags?.length > 0 && (
            <div>
              <div className="PartnerTags boxPreview">
                <div className="boxCard">
                  <p>
                    {company.tags.map((item, index) => {
                      return <span key={index}>#{item} &nbsp;</span>;
                    })}
                  </p>
                </div>
              </div>
              <div className="divisor" />
            </div>
          )}

          {this.props.infos &&
            this.props.infos.clients &&
            this.props.infos.clients?.length > 0 && (
              <div>
                <div className="PartnerClients boxPreview">
                  <div className="boxCard">
                    <h3>{I18n.get("Clientes")}</h3>
                    <p className="paList">
                      {this.props.infos.clients &&
                        this.props.infos.clients.map((item, index) => {
                          return (
                            <Tag color="#434343" key={index}>
                              {item.brand} | {item.name}
                            </Tag>
                          );
                        })}
                    </p>
                  </div>
                </div>
                <div className="divisor" />
              </div>
            )}

          {specialismValues && specialismValues?.length > 0 && (
            <div>
              <div className="PartnerEspecialidades boxPreview">
                <div className="boxCard">
                  <h3>{I18n.get("Especialidades")}</h3>
                  <ul className="paList">
                    {specialismValues &&
                      specialismValues.map((item, index) => {
                        return (
                          <li key={index}>
                            {index + 1}) {item.label}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div className="divisor" />
            </div>
          )}

          {company &&
            this.props.diversity &&
            !company.diversityPreferNotDeclare &&
            (company.diversityOwned?.length > 0 ||
              company.diversityLeadership?.length > 0) && (
              <div>
                <div className="PartnerRepresentatividade boxPreview">
                  <div className="boxCard">
                    <h3>{I18n.get("Representatividade")}</h3>
                    <p>
                      {I18n.get(
                        "Esta é uma empresa que celebra a representatividade e a diversidade e declara ter na sua composição"
                      )}
                      :
                    </p>

                    {/* diversityShareHolders */}

                    {company.diversityShareHolders?.length > 0 && (
                      <div className="setor">
                        <p>
                          <b>{I18n.get("Sócios_register")}</b>
                        </p>
                        <ul className="paList">
                          {company.diversityShareHolders.map((item, index) => {
                            return (
                              <li key={index}>
                                {index + 1}){" "}
                                {codeToLabel(item, this.props.diversity)}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}

                    {company.diversityLeadership?.length > 0 && (
                      <div className="setor">
                        <p>
                          <b>{I18n.get("Em cargos de liderança")}</b>
                        </p>
                        <ul className="paList">
                          {company.diversityLeadership.map((item, index) => {
                            return (
                              <li key={index}>
                                {index + 1}){" "}
                                {codeToLabel(item, this.props.diversity)}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}

                    {company.diversityOwned?.length > 0 && (
                      <div className="setor">
                        <p>
                          <b>{I18n.get("No time de colaboradores")}</b>
                        </p>
                        <ul className="paList">
                          {company.diversityOwned.map((item, index) => {
                            return (
                              <li key={index}>
                                {index + 1}){" "}
                                {codeToLabel(item, this.props.diversity)}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <div className="divisor" />
              </div>
            )}

          {this.props.infos.leaders && this.props.infos.leaders?.length > 0 && (
            <div>
              <div className="PartnerLider boxPreview">
                <div className="boxCard">
                  <h3>{I18n.get("Conheça os Líderes")}</h3>
                </div>
              </div>

              <div className="liderSlideMain">
                <Carousel
                  dots={false}
                  infinite={false}
                  slidesToShow={1}
                  slidesToScroll={1}
                  variableWidth={false}
                >
                  <div className="itemLider">
                    <div className="box">
                      <Row gutter={2}>
                        <Col sm={6}>
                          <p>
                            <img
                              src={this.props.infos.profilePicture || Dphoto}
                            />
                          </p>
                        </Col>
                        <Col sm={16}>
                          <p>
                            <strong>{this.props.infos.name}</strong>
                          </p>
                          <p>{I18n.get("Responsável")}</p>
                          <p>{this.props.infos.email}</p>
                          <div className="boxResumeScroll">
                            <p>{this.props.infos.resume}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {leadersOrder.map((item, index) => {
                    return (
                      <div className="itemLider" key={index}>
                        <div className="box">
                          <Row gutter={2}>
                            <Col sm={6}>
                              <p>
                                <img src={item.profilePicture || Dphoto} />
                              </p>
                            </Col>
                            <Col sm={16}>
                              <p>
                                <strong>{item.name}</strong>
                              </p>
                              <p>{item.expertise.label}</p>
                              <p>{item.email}</p>

                              <div className="boxResumeScroll">
                                <p>{item.resume}</p>
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={2} className="lineEmail">
                            <Col sm={24}>
                              {item.resume && (
                                <Tooltip
                                  placement="bottomLeft"
                                  title={item.resume}
                                ></Tooltip>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>

              <div className="divisor" />
            </div>
          )}

          {this.props.infos.causes && this.props.infos.causes?.length > 0 && (
            <div>
              <div className="PartnerCausas boxPreview">
                <div className="boxCard">
                  <h3>{I18n.get("Causas")}</h3>
                </div>
              </div>

              <div>
                <Carousel
                  dots={false}
                  infinite={false}
                  slidesToShow={3}
                  slidesToScroll={3}
                  variableWidth={false}
                >
                  {this.props.infos.causes &&
                    this.props.infos.causes.map((item, index) => {
                      return (
                        <div
                          className="itemWork"
                          key={index}
                          onClick={() =>
                            this.setState({
                              ItemCausa: index,
                              showCausa: true,
                            })
                          }
                        >
                          <div className="thumbWork">
                            <div className="mask">
                              <p>{I18n.get("Visualizar")}</p>
                            </div>

                            {item.videos?.length > 0 && (
                              <ReactPlayer
                                playing={true}
                                loop={true}
                                controls={false}
                                muted={true}
                                url={item.videos[0]}
                                width="100%"
                                height="125px"
                                style={{ margin: "0 auto" }}
                              />
                            )}

                            {item.videos?.length <= 0 && (
                              <img
                                src={
                                  item.images && item.images?.length > 0
                                    ? item.images[0]
                                    : Logo
                                }
                              />
                            )}
                          </div>

                          <h4>{item.name}</h4>
                        </div>
                      );
                    })}
                </Carousel>
              </div>

              <div className="divisor" />
            </div>
          )}
          {this.props.infos.portfolios &&
            this.props.infos.portfolios?.length > 0 && (
              <div>
                <div className="PartnerPortfolio boxPreview">
                  <div className="boxCard">
                    <h3>{I18n.get("Portfolio")}</h3>
                  </div>
                </div>
                <div>
                  <Carousel
                    dots={false}
                    infinite={false}
                    slidesToShow={3}
                    slidesToScroll={3}
                    variableWidth={false}
                  >
                    {this.props.infos.portfolios.map((item, index) => {
                      return (
                        <div
                          className="itemWork"
                          key={index}
                          onClick={() =>
                            this.setState({
                              ItemPortfolio: index,
                              showPortfolio: true,
                            })
                          }
                        >
                          <div className="thumbWork">
                            <div className="mask">
                              <p>{I18n.get("Visualizar")}</p>
                            </div>

                            {item.videos?.length > 0 && (
                              <ReactPlayer
                                playing={true}
                                loop={true}
                                controls={false}
                                muted={true}
                                url={item.videos[0]}
                                width="100%"
                                height="125px"
                                style={{ margin: "0 auto" }}
                              />
                            )}

                            {item.videos?.length <= 0 && (
                              <img
                                src={
                                  item.images && item.images?.length > 0
                                    ? item.images[0]
                                    : Logo
                                }
                              />
                            )}
                          </div>

                          <h4>{item.title}</h4>
                          <p>{item.brand}</p>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>

                <div className="divisor" />
              </div>
            )}

          {this.props.infos.cases && this.props.infos.cases?.length > 0 && (
            <div>
              <div className="PartnerCases boxPreview">
                <div className="boxCard">
                  <h3>{I18n.get("Cases")}</h3>
                </div>
              </div>

              <div>
                <Carousel
                  className="slideNumber"
                  dots={true}
                  infinite={false}
                  slidesToShow={3}
                  slidesToScroll={3}
                  variableWidth={false}
                  customPaging={function (i) {
                    return <a className="customDots">{i + 1}</a>;
                  }}
                >
                  {casesOrder.map((item, index) => {
                    let autor =
                      item.typeName && item.typeName == "Personal"
                        ? "Líder"
                        : company.name.replace(/\s/g, "");
                    return (
                      <div
                        className="itemWork"
                        key={index}
                        onClick={() =>
                          this.setState({
                            ItemCase: index,
                            showCase: true,
                          })
                        }
                      >
                        <div className="thumbWork">
                          <div className="mask">
                            <p>{I18n.get("Visualizar")}</p>
                          </div>

                          {item.videos?.length > 0 && (
                            <ReactPlayer
                              playing={true}
                              loop={true}
                              controls={false}
                              muted={true}
                              url={item.videos[0]}
                              width="100%"
                              height="125px"
                              style={{ margin: "0 auto" }}
                            />
                          )}

                          {item.videos?.length <= 0 && (
                            <img
                              src={
                                item.photos && item.photos?.length > 0
                                  ? item.photos[0]
                                  : Logo
                              }
                            />
                          )}
                        </div>

                        <h4>{item.title}</h4>
                        <p>{item.brand}</p>
                        <p className="slideAutor">
                          #case<span>{autor}</span>
                        </p>
                      </div>
                    );
                  })}
                </Carousel>
              </div>

              <div className="divisor" />
            </div>
          )}

          {this.props.infos.awards && this.props.infos.awards?.length > 0 && (
            <div>
              <div className="PartnerPremios boxPreview">
                <div className="boxCard">
                  <h3>{I18n.get("Prêmios")}</h3>
                </div>
              </div>

              <div>
                <Carousel
                  className="slideNumber"
                  dots={true}
                  infinite={false}
                  slidesToShow={3}
                  slidesToScroll={3}
                  variableWidth={false}
                  customPaging={function (i) {
                    return <a className="customDots">{i + 1}</a>;
                  }}
                >
                  {this.props.infos.awards &&
                    this.props.infos.awards.map((item, index) => {
                      let autor =
                        item.typeName && item.typeName == "Personal"
                          ? "Líder"
                          : company.name.replace(/\s/g, "");

                      return (
                        <div
                          className="itemWork"
                          key={index}
                          onClick={() =>
                            this.setState({
                              ItemAward: index,
                              showAward: true,
                            })
                          }
                        >
                          <div className="thumbWork">
                            <div className="mask">
                              <p>{I18n.get("Visualizar")}</p>
                            </div>

                            {item.videos?.length > 0 && (
                              <ReactPlayer
                                playing={true}
                                loop={true}
                                controls={false}
                                muted={true}
                                url={item.videos[0]}
                                width="100%"
                                height="125px"
                                style={{ margin: "0 auto" }}
                              />
                            )}

                            {item.videos?.length <= 0 && (
                              <img
                                src={
                                  item.images && item.images?.length > 0
                                    ? item.images[0]
                                    : Logo
                                }
                              />
                            )}
                          </div>

                          <h4>{item.name}</h4>
                          <p>{item.jobTitle}</p>
                          <p className="slideAutor">
                            #awards<span>{autor}</span>
                          </p>
                        </div>
                      );
                    })}
                </Carousel>
              </div>
            </div>
          )}
          {/* <div className="divisor" />

                <div className="PartnerRef boxPreview">
                    <div className="boxCard">
                        <h3>Recomendações</h3>

                        {this.props.infos.references && this.props.infos.references.map((item, index) => {


                            return (
                                <Row gutter={1} className="boxReference">
                                    <Col sm={8}>
                                        <div className="info">
                                            <p><a href={item.linkedInUrl || "#"} target="_blank"><strong>{item.name}</strong></a></p>
                                            <p>Relação: <b>{item.relation.label}</b></p>
                                        </div>
                                    </Col>
                                    <Col sm={16}>
                                        <div className="comment">
                                            {item.ratingsComment || "Aguardando os comentários da indicação"}
                                        </div>
                                    </Col>
                                </Row>
                            )

                        })}
                    </div>
                </div> */}

          <Drawer
            destroyOnClose={true}
            title={I18n.get("Portfolio")}
            placement="right"
            closable={true}
            onClose={() =>
              this.setState({
                showPortfolio: false,
              })
            }
            visible={this.state.showPortfolio}
            width="60%"
          >
            <ItemPortfolio
              isPresentation={this.props.isPresentation}
              item={
                this.props.infos.portfolios &&
                this.props.infos.portfolios[this.state.ItemPortfolio]
              }
            />
          </Drawer>

          <Drawer
            destroyOnClose={true}
            title={I18n.get("Causa")}
            placement="right"
            closable={true}
            onClose={() =>
              this.setState({
                showCausa: false,
              })
            }
            visible={this.state.showCausa}
            width="60%"
          >
            <ItemCausa
              isPresentation={this.props.isPresentation}
              item={
                this.props.infos.causes &&
                this.props.infos.causes[this.state.ItemCausa]
              }
            />
          </Drawer>

          <Drawer
            destroyOnClose={true}
            title={I18n.get("Prêmios")}
            placement="right"
            closable={true}
            onClose={() =>
              this.setState({
                showAward: false,
              })
            }
            visible={this.state.showAward}
            width="60%"
          >
            <ItemAward
              isPresentation={this.props.isPresentation}
              item={
                this.props.infos.awards &&
                this.props.infos.awards[this.state.ItemAward]
              }
              category={
                this.props.infos.awards &&
                this.props.infos.awards?.length > 0 &&
                this.props.infos.awards[this.state.ItemAward].category &&
                this.props.sectorsFlat &&
                codeToLabel(
                  this.props.infos.awards[this.state.ItemAward].category,
                  this.props.sectorsFlat
                )
              }
            />
          </Drawer>

          <Drawer
            destroyOnClose={true}
            title={I18n.get("Case")}
            placement="right"
            closable={true}
            onClose={() =>
              this.setState({
                showCase: false,
              })
            }
            visible={this.state.showCase}
            width="60%"
          >
            <ItemCase
              isPresentation={this.props.isPresentation}
              case={
                this.props.infos.cases &&
                this.props.infos.cases[this.state.ItemCase]
              }
              specialismsBP={this.props.specialismsBP}
              category={
                this.props.infos.cases &&
                this.props.infos.cases?.length > 0 &&
                this.props.infos.cases[this.state.ItemCase].category &&
                this.props.sectorsFlat &&
                codeToLabel(
                  this.props.infos.cases[this.state.ItemCase].category,
                  this.props.sectorsFlat
                )
              }
              specialisms={this.props.specialisms}
            />
          </Drawer>
        </MainInfos>
      </Fragment>
    );
  }
}

export default PartnerInfos;
