import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 32px;

  h3 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin: 32px 0 0 0;
  }
`;
