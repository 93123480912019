import React from "react";
import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";

import AssetBundle from "./_project-asset-bundle";

class ReviewStep2 extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  getBundle() {
    return this.props.bundle || {};
  }

  getSelected() {
    return {
      bundleId: this.props.bundleId,
      variationId: this.props.variationId,
    };
  }

  render() {
    const bundle = this.getBundle();

    return (
      <div>
        <h1 className="title-bpool medium">
          <span>2.</span>
          {I18n.get("Escopo Criativo")}
        </h1>

        <div className="reviewed-media">
          <AssetBundle
            {...bundle}
            {...this.props}
            bp={this.props.bp}
            selected={this.getSelected()}
            isExpanded={true}
            assetTax={this.props.assetTax || 0}
          />
        </div>
      </div>
    );
  }
}

export default ReviewStep2;
