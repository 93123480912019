export const errorObjToArr = async (errors) => {
  return Object.values(errors).map((error) => error);
};

export const hasError = ({ field, arr }) => {
  if (arr?.length) {
    const err = arr?.find(
      (item) => item?.field?.toLowerCase() === field.toLowerCase()
    );

    if (err) {
      return err.errorMessage;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const clearErrorInput = ({ field, arr }) => {
  if (!Array.isArray(arr)) {
    console.error('arr não é um array');
    return arr;
  }

  const arrUpdated = arr.filter(
    (item) => item && item.field && item.field.toLowerCase() !== field.toLowerCase()
  );

  return arrUpdated;
};

export const clearItemErrorTab = ({ tab, arr }) => {
  const arrUpdated = arr?.filter(
    (item) => item?.tab?.toLowerCase() !== tab.toLowerCase()
  );

  return arrUpdated;
};
