import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Checkbox } from "antd";
import PasswordChecklist from "react-password-checklist";
import { Input } from "../../../../../../components/UI/Input";
import { ModalTerms } from "./modalTerms";

import * as S from "../../styles";

export const FormCadastro = ({
  inputsForm,
  handleChangeInputForm,
  termsAccept,
  handleChangeTerms,
  handleChangePasswordIsValid,
}) => {
  const [openTerms, setOpenTerms] = useState(false);

  const renderRegisterForm = () => {
    return (
      <Row style={{ margin: "16px 0px" }}>
        <Col xs={24} md={12} style={{ paddingLeft: 0 }}>
          <Input
            password
            placeholder={I18n.get("Senha")}
            required
            value={inputsForm.passWord}
            onChange={(value) => handleChangeInputForm("passWord", value)}
            error=""
          />
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={8}
            value={inputsForm?.passWord}
            valueAgain={inputsForm?.confirmPassWord}
            messages={{
              minLength: I18n.get("Password has at least 8 characters."),
              specialChar: I18n.get("Password has special characters."),
              number: I18n.get("Password has a number."),
              capital: I18n.get("Password has a capital letter."),
              match: I18n.get("Passwords match."),
            }}
            onChange={(isValid) => handleChangePasswordIsValid(isValid)}
          />
        </Col>
        <Col xs={24} md={12} style={{ paddingRight: 0 }}>
          <Input
            password
            placeholder={I18n.get("Confirmar senha")}
            required
            value={inputsForm.confirmPassWord}
            onChange={(value) =>
              handleChangeInputForm("confirmPassWord", value)
            }
            error=""
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <h3 style={{ marginTop: 16 }}>{I18n.get("Cadastro")}</h3>
      <p>
        {I18n.get(
          "Para prosseguir, você deve preencher uma senha para finalizar seu cadastro."
        )}
      </p>
      <p>
        {I18n.get(
          "Você poderá a partir dele acompanhar seus pagamentos na mesma tela."
        )}
      </p>
      {renderRegisterForm()}
      <Row style={{ marginTop: 32 }}>
        <Col xs={24} md={24} style={{ paddingRight: 0, paddingLeft: 0 }}>
          <S.CheckboxTerms>
            <Checkbox checked={termsAccept} onChange={handleChangeTerms} />
            <span style={{ marginLeft: 5 }}>
              {I18n.get(
                "Para finalizar o envio da nota fiscal e o cadastro você deve aceitar os"
              )}
              <button onClick={() => setOpenTerms(true)}>
                {I18n.get("termos e condições")}.
              </button>
            </span>
          </S.CheckboxTerms>
        </Col>
      </Row>

      <ModalTerms
        openTerms={openTerms}
        handleClickCancel={() => setOpenTerms(false)}
      />
    </>
  );
};
