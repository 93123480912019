import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div`
  width: 270px;
  height: 100%;
  background-color: #fff;
`;

export const Content = styled.div`
  padding: 30px 15px;

  .ant-steps-item-content {
    font-family: Helvetica, sans-serif;

    .ant-steps-item-title {
      font-size: 14px;
      font-weight: bold;
      font-family: "Helvetica Neue LT Std";
    }

    .ant-steps-item-description {
      font-size: 14px;
    }
  }

  .disabled {
    .ant-steps-item-container[role="button"] {
      cursor: not-allowed !important;
      pointer-events: none !important;
    }
  }

  .enabled {
    .ant-steps-item-container[role="button"] {
    }
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-step {
    height: 20px;
    margin-top: 10px !important;

    li {
      height: 100%;
      margin-top: 10px !important;

      :nth-child(3) {
        margin-top: 20px !important;
      }

      :nth-child(5) {
        margin-top: 20px !important;
      }

      :first-child {
        margin-top: 0 !important;
      }

      :last-child {
        width: 100% !important;
      }
    }
  }
`;
