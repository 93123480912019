import React, { Fragment } from "react";
import { I18n } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";

import * as JsSearch from "js-search";

import Carousel from "../../modules/components/Carousel";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import CheckboxField from "../form/checkbox-field";
import TextField from "../form/text-field";

import AssetBundle from "../_project-asset-bundle";

import { scrollToTop } from "../../utils/ui";

import { ProjectType } from "../../model";

import icoWhatsapp from "../../image/ico-whatsapp.png";

import { Row, Col } from "antd";

class AssetBundleForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {
        bundleId: props.bundleId,
        variationId: props.variationId,
        assetType: {
          label: I18n.get("Tipo"),
          value: [],
          defaultValue: [],
          checked: false,
          error: props.errors.assetType,
          successIcon: false,
        },
        assetSpecialism: {
          label: I18n.get("Especialidade"),
          value: [],
          defaultValue: [],
          checked: false,
          error: props.errors.assetSpecialism,
          successIcon: false,
        },
        search: {
          label: "",
          placeholder: I18n.get("Digite o que procura"),
          value: props.search || "",
          onChange: this.onChangeSearch.bind(this),
          error: props.errors.search,
          successIcon: false,
        },
        isSubmit: false,
      },
      //assetSpecialismShowAll: true,
      //assetTypeShowAll: true,
      specialismMap: {},
      assetTypeMap: {},
      page: 1,
      task: {},
      bundles: [],
      cloneBundles: [],
    };

    //this.onDrop = this.onDrop.bind(this)
  }

  componentDidMount() {
    this.setState({
      bundles: this.props.project.bundles,
      cloneBundles: this.props.project.bundles,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];

          if ("checked" in nextState.form[item]) {
            nextState.form[item].checked = this.props[item];
          } else {
            nextState.form[item].value = this.props[item];
          }
        } else {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }

    if (this.props.project.bundles != prevProps.project.bundles) {
      this.setState({
        bundles: this.props.project.bundles,
        cloneBundles: this.props.project.bundles,
      });
    }
  }

  getCounter(arr) {
    if (!arr) return {};

    return arr.reduce(function (countMap, item) {
      countMap[item] = (countMap[item] || 0) + 1;

      return countMap;
    }, {});
  }

  onChangeSearch(e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState, { page: 1 });

      nextState.form["search"].value = value;

      return nextState;
    });

    setTimeout(() => {
      if (this.state.form.search.value) {
        var search = new JsSearch.Search("bundleId");

        search.addIndex("title");
        search.addDocuments(this.state.bundles);

        let bundleFilters = search.search(this.state.form.search.value);

        this.setState({
          bundles: bundleFilters.length == 0 ? [] : bundleFilters,
          cloneBundles: bundleFilters.length == 0 ? [] : bundleFilters,
        });
      } else {
        this.setState({
          bundles: this.props.project.bundles,
          cloneBundles: this.props.project.bundles,
        });
      }
    }, 50);
  }

  onChangeAssetSpecialismCheckbox(code, e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState, { page: 1 });

      nextState.form["assetSpecialism"].value = prevState.form[
        "assetSpecialism"
      ].value.filter((item) => item != code);

      if (checked) {
        nextState.form["assetSpecialism"].value.push(code);
      }

      return nextState;
    });

    setTimeout(() => {
      let bundleFilters = [];

      if (this.state.form.assetSpecialism.value.length > 0) {
        var search = new JsSearch.Search("bundleId");
        search.addIndex("specialism");

        search.addDocuments(this.state.bundles);

        bundleFilters = search.search(
          this.state.form.assetSpecialism.value.join(" ")
        );

        this.setState({
          bundles: bundleFilters,
        });
      } else {
        this.setState({
          bundles: this.state.cloneBundles,
        });
      }
    }, 200);
  }

  onChangeAssetTypeCheckbox(code, e) {
    // this.setState({
    //     bundles: this.props.bp.bundles
    // })

    const checked = e.target.checked;

    // this.setState({
    //     form: {
    //         ...this.state.form,
    //         assetType:{
    //             ...this.state.form.assetType,
    //             value: [code]
    //         }
    //     }
    // })

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState, { page: 1 });

      nextState.form["assetType"].value = prevState.form[
        "assetType"
      ].value.filter((item) => item != code);

      if (checked) {
        // nextState.form['assetType'].value.push(code);
        nextState.form["assetType"].value = [code];
      }

      //nextState.assetTypeShowAll = nextState.form['assetType'].value.length == 0;

      return nextState;
    });

    setTimeout(() => {
      let bundleFilters = this.props.project.bundles;

      if (this.state.form.assetType.value.length > 0) {
        var search = new JsSearch.Search("bundleId");
        search.addIndex("assetType");

        search.addDocuments(this.props.project.bundles);

        bundleFilters = search.search(code);

        this.setState({
          bundles: bundleFilters,
        });
      } else {
        this.setState({
          bundles: this.state.cloneBundles,
        });
      }
    }, 200);
  }

  onChangeAssetSpecialismShowAllCheckbox(e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.assetSpecialismShowAll = checked;

      if (checked) {
        nextState.form.assetSpecialism.value = [];
      }

      return nextState;
    });
  }

  onChangeAssetTypeShowAllCheckbox(e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.assetTypeShowAll = checked;

      if (checked) {
        nextState.form.assetType.value = [];
      }

      return nextState;
    });
  }

  setPage(page, e) {
    this.setState({ page: page });
  }

  getAsset(code) {
    if (!code) return {};
    if (!this.props.project.assets) return;

    const item = this.props.project.assets.filter(
      (asset) => asset.code == code
    );

    if (item && item.length) {
      return item[0];
    } else {
      return {};
    }
  }

  selectBundle(variationId, bundleId) {
    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form["bundleId"] = bundleId;
        nextState.form["variationId"] = variationId;

        return nextState;
      },
      function () {
        var data = this.getData();

        this.props.save(data);

        this.props.history.push(
          `/cliente/projetos/${this.props.match.params.projectId}/passo-3`
        );
      }
    );
  }

  resetBundle() {
    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form["bundleId"] = "";
        nextState.form["variationId"] = "";

        return nextState;
      },
      function () {
        var data = this.getData();

        this.props.save(data);
      }
    );
  }

  onBlur(item) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      FormUtils.clearError(nextState, item);

      return nextState;
    });
  }

  getData() {
    var data = {
      clientId: this.props.clientId,
      ...this.props.project,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (FormUtils.isFormField(this.state.form[item])) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  onSave(item) {
    if (this.props.isAdmin) return;

    // zera o erro
    if (item && typeof this.state.form[item] === "object") {
      this.state.form[item].error = null;
    }

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  getSelected() {
    return {
      bundleId: this.state.form.bundleId,
      variationId: this.state.form.variationId,
    };
  }

  handleCustomBtnClick = () => {
    scrollToTop();
    this.props.onCustomBtnClick();
  };

  renderBtn = () => {
    if (this.props.client.signupLocale === "pt") {
      return (
        <a
          className="button bt-bpool black"
          href="https://api.whatsapp.com/send?phone=+5511974721502"
          target="blank"
        >
          <img src={icoWhatsapp} /> <span> {I18n.get("Clique aqui")} </span>
        </a>
      );
    } else if (this.props.client.signupLocale === "en") {
      return (
        <a
          className="button bt-bpool black"
          href="https://api.whatsapp.com/send?phone=+16469189446"
          target="blank"
        >
          <img src={icoWhatsapp} /> <span> {I18n.get("Clique aqui")} </span>
        </a>
      );
    } else {
      return (
        <a
          className="button bt-bpool black"
          href="https://api.whatsapp.com/send?phone=+525539884303"
          target="blank"
        >
          <img src={icoWhatsapp} /> <span> {I18n.get("Clique aqui")} </span>
        </a>
      );
    }
  };

  render() {
    var variationAssets = this.props.getGroupVariationAssets(
      this.props.bundle && this.props.bundle.specialism
    );

    const assetSpecialisms =
      this.props.bp.assetSpecialisms &&
      this.props.bp.assetSpecialisms.sort(
        (a, b) => (a.label > b.label) - (a.label < b.label)
      );
    const assetTypes =
      this.props.bp.assetTypes &&
      this.props.bp.assetTypes.sort(
        (a, b) => (a.label > b.label) - (a.label < b.label)
      );

    var specialismsBag = [];
    var assetTypesBag = [];

    let { bundles } = this.state;

    this.state.bundles &&
      this.state.bundles.map((item) => {
        if (item.specialism) specialismsBag.push(...item.specialism);
      });

    this.props.project.bundles &&
      this.props.project.bundles.map((item) => {
        if (item.assetType) assetTypesBag.push(...item.assetType);
      });

    var specialismMap = this.getCounter(specialismsBag);
    var assetTypeMap = this.getCounter(assetTypesBag);

    return (
      <form onSubmit={this.submitForm.bind(this)}>
        <Row gutter={1} className="topFilter">
          <Col sm={10}>
            <h1 className="title-bpool medium">
              2. {I18n.get("Selecione o tipo de Projeto")}
            </h1>
          </Col>
          <Col sm={2}></Col>
          <Col sm={12}>
            <TextField {...this.state.form.search} />
          </Col>
        </Row>

        {assetTypes && assetTypes.length > 0 && (
          <div className="slide-types">
            <Carousel
              dots={false}
              // dotsClass="slick-dots"
              infinite={false}
              // speed={500}
              slidesToShow={7}
              slidesToScroll={1}
              centerMode={false}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: false,
                  },
                },
                {
                  breakpoint: 810,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                  },
                },
                {
                  breakpoint: 615,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                  },
                },
                {
                  breakpoint: 530,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                  },
                },
              ]}
            >
              {assetTypes &&
                assetTypes.map((item, index) => {
                  if (assetTypeMap[item.code] > 0) {
                    return (
                      <div
                        key={index}
                        className={`itemTypeSlide ${
                          this.state.form.assetType.value.indexOf(item.code) >
                          -1
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="circType">
                          {item.icon && (
                            <img
                              src={`https://assets.bpool.co/app/${item.icon}.png`}
                              className={`${
                                item.icon == "type-estrategia"
                                  ? "estrategia"
                                  : ""
                              } `}
                            />
                          )}
                          {!item.icon && (
                            <img
                              src={`https://assets.bpool.co/app/type-custom.png`}
                              class="iconCustom"
                              width="38px"
                            />
                          )}
                        </div>
                        {/* <div className="is-pulled-right">{assetTypeMap[item.code]}</div> */}
                        <CheckboxField
                          {...this.state.form.assetType}
                          label={item.label}
                          checked={
                            this.state.form.assetType.value.indexOf(item.code) >
                            -1
                          }
                          value={item.code}
                          onChange={(e) =>
                            this.onChangeAssetTypeCheckbox(item.code, e)
                          }
                        />
                      </div>
                    );
                  }
                })}
            </Carousel>
          </div>
        )}

        <div className="columns columns-for-np">
          <div className="column left-column-for-np is-2">
            <p className="regular-control-text">
              {I18n.get(
                "Selecione o seu pré-projeto. Você pode também editar itens de alguns pacotes, criar um pacote personalizado ou solicitar um pré-projeto por áudio."
              )}
            </p>

            <br />
            <hr style={{ backgroundColor: "#000000" }} />

            <h4 className="title-bpool medium">{I18n.get("Especialidade")}</h4>

            {assetSpecialisms &&
              assetSpecialisms.map((item, index) => (
                <Fragment>
                  {specialismMap[item.code] > 0 && (
                    <div key={index}>
                      <div className="is-pulled-right">
                        {" "}
                        {specialismMap[item.code]}
                      </div>
                      <CheckboxField
                        {...this.state.form.assetSpecialism}
                        label={item.label}
                        checked={
                          this.state.form.assetSpecialism.value.indexOf(
                            item.code
                          ) > -1
                        }
                        value={item.code}
                        onChange={(e) =>
                          this.onChangeAssetSpecialismCheckbox(item.code, e)
                        }
                      />
                    </div>
                  )}
                </Fragment>
              ))}

            <br />
            <hr style={{ backgroundColor: "#000000" }} />

            <h1 className="title-bpool medium">
              {I18n.get("Pré-escopo por áudio")}
            </h1>

            <p className="regular-control-text">
              {I18n.get(
                "Você também pode montar este pré-escopo por áudio. Envie uma mensagem de voz para nosso time explicando sua necessidade que faremos uma sugestão com as melhores alternativas na plataforma."
              )}
            </p>
            <p className="btnWhatsapp">{this.renderBtn()}</p>

            <br />

            {this.props.projectType === ProjectType.Creative && (
              <div>
                <hr style={{ backgroundColor: "#000000" }} />

                <h4 className="title-bpool medium">
                  {I18n.get("Personalizado")}
                </h4>
                <p className="regular-control-text">
                  {I18n.get(
                    "Não encontrou o pacote conforme suas necessidades?"
                  )}
                </p>

                <div className="field has-text-centered">
                  <label className="label is-small">&nbsp;</label>
                  <p className="control is-expanded centered-button">
                    <button
                      type="button"
                      className="button bt-bpool black"
                      onClick={this.handleCustomBtnClick}
                    >
                      {I18n.get("Clique aqui")}
                    </button>
                  </p>
                </div>

                <hr style={{ backgroundColor: "#000000" }} />
              </div>
            )}
          </div>

          <div className="column right-column-for-np is-9 is-offset-1">
            <nav className="level bp-level">
              <div className="level-left">
                <div className="level-item">
                  {this.props.bundleId && (
                    <h5>{I18n.get("Pacote selecionado.")}</h5>
                  )}

                  {!this.props.bundleId && bundles && (
                    <h5>
                      {" "}
                      <span className="numberTag">{bundles.length}</span>{" "}
                      {I18n.get("pacotes encontrados")}
                    </h5>
                  )}
                </div>
              </div>

              <div className="level-right">
                <div className="level-item">
                  {/* <TextField {...this.state.form.search} /> */}
                </div>
              </div>
            </nav>

            <div className="listBundle">
              {this.props.bundle && (
                <AssetBundle
                  {...this.props.bundle}
                  assets={this.props?.project?.assets}
                  bp={this.props.bp}
                  selected={this.getSelected()}
                  selectBundle={this.selectBundle.bind(this)}
                  resetBundle={this.resetBundle.bind(this)}
                  cartId={this.props.cartId}
                  createCart={this.props.createCart}
                  cartAssets={this.props.cartAssets}
                  addItem={this.props.addItem}
                  delItem={this.props.delItem}
                  clearCart={this.props.clearCart}
                  checkoutVariation={this.props.checkoutVariation}
                  variationAssets={variationAssets}
                  IsShoppingCart={true}
                  isFull={true}
                  currency={this.props.currency}
                  showConvert={
                    this.props.currency && this.props.currency.code == "BRL"
                  }
                  projectStatus={this.props.status}
                  assetTax={this.props.assetTax || 0}
                  otherTax={this.props.otherTax || 0}
                  isBundlesCart={true}
                />
              )}
              {!this.props.bundle &&
                bundles &&
                bundles.map((item, index) => {
                  variationAssets = this.props.getGroupVariationAssets(
                    item.specialism
                  );

                  return (
                    <AssetBundle
                      key={item.bundleId}
                      {...item}
                      bp={this.props.bp}
                      selected={this.getSelected()}
                      selectBundle={this.selectBundle.bind(this)}
                      resetBundle={this.resetBundle.bind(this)}
                      cartId={this.props.cartId}
                      createCart={this.props.createCart}
                      cartAssets={this.props.cartAssets}
                      addItem={this.props.addItem}
                      delItem={this.props.delItem}
                      clearCart={this.props.clearCart}
                      checkoutVariation={this.props.checkoutVariation}
                      variationAssets={variationAssets}
                      IsShoppingCart={true}
                      currency={this.props.currency}
                      showConvert={
                        this.props.currency && this.props.currency.code == "BRL"
                      }
                      projectStatus={this.props.status}
                      assetTax={this.props.assetTax || 0}
                      otherTax={this.props.otherTax || 0}
                      assets={this.props?.project?.assets}
                      isBundlesCart={true}
                    />
                  );
                })}
            </div>

            <Link
              to={`/cliente/projetos/${this.props.match.params.projectId}/passo-1`}
              className="button bt-bpool grey"
            >
              {I18n.get("Voltar")}
            </Link>
            <Link
              to={`/cliente/projetos/${this.props.match.params.projectId}/passo-3`}
              className="button bt-bpool pull-right"
            >
              {I18n.get("Próximo")}
            </Link>

            <br />
            {/*
                        {!this.props.bundleId && <nav className="pagination is-rounded" role="navigation" aria-label="pagination">
                            <ul className="pagination-list">
                                {pagination.pages.map((page, index) => {

                                    const className = 'pagination-link' + (this.state.page == page ? ' is-current' : '');

                                    return (<li key={index} className='is-black'>
                                        <a className={className} aria-label={`Página ${page}`} onClick={this.setPage.bind(this, page)}>{page}</a>
                                    </li>);
                                })}
                            </ul>
                        </nav>} */}

            <br />
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(AssetBundleForm);
