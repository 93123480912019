import React from 'react';

import { I18n } from 'aws-amplify';

import BPitchDetails from './_project-details-bpitch';

import NumberFormat from 'react-number-format';

const BRL = ({ amount }) => <NumberFormat value={amount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} />;

class ReviewStep2 extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {

        const { project = {} } = this.props;

        const { bPitch = {} } = this.props;

        return (
            <div>
                <br />
                <br />
                <h1 className="title-bpool small">
                    {I18n.get("Escopo do projeto")}</h1>

                {project && project.isBPitch && <>

                    <BPitchDetails {...this.props} defaultActiveKey={[]} />

                </>}

            </div>
        );
    }
}

export default ReviewStep2;
