import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import { I18n } from "aws-amplify";
import { useForm } from "react-hook-form";
import { Row, Col, Radio } from "antd";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";

import Content from "../../../../components/UI/Content";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { H2Border } from "../../../../components/UI/Titles";
import { SelectOne } from "../../../../components/UI/SelectOne";

import StepsCreateProject from "../components/StepsCreateProject";
import { ReviewProject } from "../components/ReviewProject";
import Main from "../components/Main";
import TopInformation from "../components/TopInformation";
import GlobalFileBox from "../../../Client/CreateProject/components/GlobalFileBox";
import Loading from "../../../../components/UI/Loading";
import RadioGroup from "../../../../components/UI/RadioGroup";
import FlagRS from "./imgs/flag-rs.jpg";
import constants from "../../../../../constants";
import { useProjects } from "../../../../hooks/useProjects/useProjects.hook";

import * as S from "./styles";

const STEP = "step4";

export const CreateProjectStep4 = () => {
  let params = useParams();
  let history = useHistory();
  const { reset } = useForm();
  const state = useSelector((state) => state);
  const auth = useSelector((state) => state.auth);
  const {
    getProjectByStepAndId,
    postProjectByStep,
    putProjectByStep,
    getPreferredPartners,
    removeGlobal,
    putGlobal,
  } = useProjects();

  const getCategoryValues = () => {
    const categories =
      state?.bp?.sectorsFlat &&
      state?.bp?.sectorsFlat.filter((item) => !item.isUnspecified);
    return categories?.length > 0 ? categories : [];
  };

  const getDiversityValues = () => {
    const diversities = state?.bp?.diversity;
    return diversities?.length > 0 ? diversities : [];
  };
  const [projectDetails, setProjectDetails] = useState({});
  const [loaderGetProject, setLoaderGetProject] = useState(false);

  const [regionDisabled, setRegionDisabled] = useState(true);
  const [specificRegionOnly, setSpecificRegionOnly] = useState(false);
  const [specificRegion, setSpecificRegion] = useState("");

  const [sosRS, setSosRS] = useState(false);

  const [expertiseDisabled, setExpertiseDisabled] = useState(true);
  const [sameCategoryOnly, setSameCategoryOnly] = useState(false);
  const [hasAdditionalCategories, setHasAdditionalCategories] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);

  const [diversityDisabled, setDiversityDisabled] = useState(true);
  const [diversity, setDiversity] = useState(0);
  const [diversitySpecific, setDiversitySpecific] = useState([]);
  const [diversitySpecificOwner, setDiversitySpecificOwner] = useState([]);

  const [loader, setLoader] = useState(false);
  const [projectErrors, setProjectErrors] = useState({});

  const [typePartner, setTypePartner] = useState(1);
  const [partnersList, setPartnersList] = useState([]);
  const [isLoadingPartners, setIsLoadingPartners] = useState(false);
  const [selectPartnerId, setSelectPartnerId] = useState("");
  const [alreadyWorkedPartnerId, setAlreadyWorkedPartnerId] = useState("");

  useEffect(() => {
    console.log("sosRS", sosRS);
  }, [sosRS]);

  const sendToServer = async (sendGlobal) => {
    try {
      if (!sendGlobal) {
        setLoader(true);
      }

      const id = params.projectId;

      let infosToServer = {};

      if (typePartner === 1) {
        infosToServer = {
          id, //string
          clientId: projectDetails.clientId,
          productId: projectDetails.productId,
          specificRegionOnly, //boolean
          sosrs: sosRS,
          specificRegion, //string

          sameCategoryOnly, //boolean
          categoryIds, //array string
          hasAdditionalCategories, //boolean

          diversity,
          diversitySpecific, //array string
          diversitySpecificOwner,

          suggestAlreadyWorkedPartner: false, //boolean
          alreadyWorkedPartnerId: "", //string
          selectPartner: false, //boolean
          selectPartnerId: selectPartnerId, //string
          status: 0, //number
        };
      } else if (typePartner === 2) {
        infosToServer = {
          id, //string
          clientId: projectDetails.clientId,
          productId: projectDetails.productId,

          //upload

          suggestAlreadyWorkedPartner: true, //boolean
          alreadyWorkedPartnerId: selectPartnerId, //string
          selectPartner: true, //boolean
          selectPartnerId: selectPartnerId, //preencher com id do partner
          status: 0, //number
        };
      } else if (typePartner === 3) {
        infosToServer = {
          id, //string
          clientId: projectDetails.clientId,
          productId: projectDetails.productId,
          specificRegionOnly, //boolean
          sosrs: sosRS,
          specificRegion, //string

          sameCategoryOnly, //boolean
          categoryIds, //array string
          hasAdditionalCategories, //boolean

          diversity,
          diversitySpecific, //array string
          diversitySpecificOwner,

          suggestAlreadyWorkedPartner: true, //boolean
          alreadyWorkedPartnerId: alreadyWorkedPartnerId, //preencher com id do partner
          selectPartner: false, //boolean
          selectPartnerId: "",
          status: 0, //number
        };
      }

      const response = await putProjectByStep(infosToServer, STEP);
      if (response.success) {
        if (!sendGlobal) {
          history.push(`/cliente/projetos/v2/checkout/${id}`);
        }
      } else {
        if (!sendGlobal) {
          setProjectErrors(response.errors);
        }
        setLoader(false);
      }
    } catch (error) {
      if (!sendGlobal) {
        setProjectErrors(error);
      }
    } finally {
      setLoader(false);
    }
  };

  const onChangeSpecificRegionOnly = (checked) => {
    setSpecificRegionOnly(checked === 0 ? true : false);

    if (checked === 1) {
      setSpecificRegion("");
    }

    checked === 0 ? setRegionDisabled(false) : setRegionDisabled(true);
  };

  const onChangeSameCategoryOnly = (checked) => {
    setSameCategoryOnly(checked === 0 ? true : false);
  };

  const onChangeHasAdditionalCategories = (checked) => {
    setHasAdditionalCategories(checked === 0 ? true : false);

    if (checked === 1) {
      setCategoryIds([]);
    }

    checked === 0 ? setExpertiseDisabled(false) : setExpertiseDisabled(true);
  };

  const saveGlobal = async (data) => {
    const dataSend = {
      file: { ...data },
      projectId: projectDetails?.id,
    };

    if (data?.id) {
      const response = await putGlobal(dataSend);
      if (response?.success) {
        sendToServer(true);
        getProjectStep4(true);
      }
    } else {
      const response = await removeGlobal(dataSend);
      if (response?.success) {
        sendToServer(true);
        getProjectStep4(true);
      }
    }
  };

  const onChangeRadioDiversity = (checked) => {
    if (checked === 0) {
      setDiversity(2);
      setDiversityDisabled(false);
    }

    if (checked === 1) {
      setDiversity(1);
      setDiversityDisabled(false);
    }

    if (checked === 2) {
      setDiversity(0);
      setDiversitySpecificOwner([]);
      setDiversitySpecific([]);
      setDiversityDisabled(true);
    }
  };

  const handleChangeDiversities = ({ type, values }) => {
    if (type === "diversitySpecificOwner") {
      setDiversitySpecificOwner(values);
    } else {
      setDiversitySpecific(values);
    }
  };

  const handleChangeExpertise = (value) => {
    setCategoryIds(value);
  };

  const loadPreferredPartners = async () => {
    setIsLoadingPartners(true);
    const response = await getPreferredPartners({
      projectId: params.projectId,
    });
    if (response?.status === 200) {
      const list = response?.data?.map((item) => ({
        code: item?.code,
        label: item?.label,
      }));
      setPartnersList(list?.length ? list : []);
    } else {
      setPartnersList([]);
    }
    setIsLoadingPartners(false);
  };

  const loadRadios = async (data) => {
    if (data?.suggestAlreadyWorkedPartner && data?.alreadyWorkedPartnerId) {
      setTypePartner(3);
    } else if (data?.selectPartner && data?.alreadyWorkedPartnerId) {
      setTypePartner(2);
    } else {
      setTypePartner(1);
    }
  };

  const optionDefaultDiversity = (value) => {
    if (value === 2) {
      return 0;
    } else if (value === 1) {
      return 1;
    } else if (value === 0) {
      return 2;
    } else {
      return 1;
    }
  };

  const getProjectStep4 = async (sendGlobal) => {
    try {
      setLoaderGetProject(sendGlobal ? false : true);
      const id = params.projectId;

      const { data } = await getProjectByStepAndId(id, STEP);
      if (!sendGlobal) {
        await loadRadios(data);
      }
      setProjectDetails(data);
      setSelectPartnerId(data?.selectPartnerId);
      setAlreadyWorkedPartnerId(data?.alreadyWorkedPartnerId);

      if (!sendGlobal) {
        loadPreferredPartners();
      }

      onChangeSpecificRegionOnly(data.specificRegionOnly ? 0 : 1);
      if (data.specificRegionOnly) {
        setRegionDisabled(false);
        setSpecificRegion(data.specificRegion);
      }
      onChangeSameCategoryOnly(data.sameCategoryOnly ? 0 : 1);
      onChangeHasAdditionalCategories(data.hasAdditionalCategories ? 0 : 1);
      if (data.hasAdditionalCategories) {
        setExpertiseDisabled(false);
        setCategoryIds(data.categoryIds);
      }

      setDiversity(data?.diversity);
      setDiversitySpecific(data?.diversitySpecific);
      setDiversitySpecificOwner(data?.diversitySpecificOwner);
      const diversityDefault = optionDefaultDiversity(data?.diversity);
      if (diversityDefault === 0 || diversityDefault === 1) {
        setDiversityDisabled(false);
      }
    } catch (error) {
      console.log("error step 4", error);
    } finally {
      setLoaderGetProject(false);
    }
  };

  const onChangeSelectTypePartner = async (e) => {
    const type = e.target.value;
    setTypePartner(type);
  };

  const onChangePartnerSelect = (codePartner) => {
    if (typePartner === 3) {
      setAlreadyWorkedPartnerId(codePartner);
    } else {
      setSelectPartnerId(codePartner);
    }
  };

  useEffect(() => {
    if (projectDetails) {
      reset({
        regionId: projectDetails?.specificRegion,
        expertiseId: projectDetails?.categoryIds,
        diversityId: projectDetails?.diversitySpecific,
      });
      console.log("projectDetails");

      if (projectDetails.sosrs === null) {
        setSosRS(true);
      } else if (projectDetails.sosrs === true) {
        setSosRS(true);
      } else {
        setSosRS(false);
      }
    }
  }, [projectDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectStep4();
  }, []);

  const visibleGlobal = projectDetails?.productRequiredGlobalAuthorization;
  const globalAuthorization = projectDetails?.globalAuthorization;

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Novo Projeto")}</H2Border>
            </Col>
            <Col sm={12} justify="end"></Col>
          </Row>
          <Row>
            <Col sm={24}>
              <S.StyleMain>
                <StepsCreateProject
                  active={4}
                  projectId={params.projectId || ""}
                />
              </S.StyleMain>
              <div>
                <Row>
                  <Col xs={24} sm={17}>
                    <Main padding="30px" style={{ background: "#fff" }}>
                      <Row>
                        <Col xs={24} sm={24}>
                          <TopInformation>
                            <>
                              <h3>{I18n.get("Novos Parceiros")}</h3>

                              <p>
                                {I18n.get(
                                  "Para que possamos sugerir os parceiros que mais se encaixam com seu projeto, selecione os itens de sua preferência:"
                                )}
                              </p>
                            </>
                          </TopInformation>

                          <S.ContainerRadios>
                            <Row>
                              {loaderGetProject || isLoadingPartners ? (
                                <Col xs={24} sm={24}>
                                  <Loading
                                    text={I18n.get("Carregando...")}
                                    sizeText={14}
                                    sizeIcon={16}
                                    align="start"
                                  />
                                </Col>
                              ) : (
                                <Col xs={24} sm={24}>
                                  <Radio.Group
                                    onChange={onChangeSelectTypePartner}
                                    defaultValue={typePartner}
                                    value={typePartner}
                                  >
                                    <Radio value={1}>
                                      {I18n.get(
                                        "Quero conhecer novos parceiros criativos"
                                      )}
                                    </Radio>
                                    <Radio
                                      value={2}
                                      disabled={
                                        partnersList?.length ? false : true
                                      }
                                    >
                                      {I18n.get(
                                        "Quero selecionar um partner com quem já trabalhei antes"
                                      )}
                                    </Radio>
                                    <Radio
                                      value={3}
                                      style={{ marginBottom: 0 }}
                                      disabled={
                                        partnersList?.length ? false : true
                                      }
                                    >
                                      {I18n.get(
                                        "Quero conhecer dois novos parceiros e indicar um terceiro"
                                      )}
                                    </Radio>
                                  </Radio.Group>
                                </Col>
                              )}
                            </Row>
                          </S.ContainerRadios>

                          {typePartner === 2 || typePartner === 3 ? (
                            <>
                              <S.ContainerSelects style={{ marginTop: 20 }}>
                                <Row>
                                  <Col xs={24} sm={24}>
                                    {isLoadingPartners ? (
                                      <Loading
                                        text={I18n.get("Carregando...")}
                                        sizeText={14}
                                        sizeIcon={16}
                                        align="start"
                                      />
                                    ) : partnersList?.length === 0 ? (
                                      <S.ListPartnersEmpty>
                                        {I18n.get(
                                          "Você não tem nenhum parceiro preferencial para selecionar."
                                        )}
                                      </S.ListPartnersEmpty>
                                    ) : (
                                      <SelectOne
                                        options={partnersList}
                                        value={
                                          selectPartnerId ||
                                          alreadyWorkedPartnerId
                                        }
                                        onChange={(value) =>
                                          onChangePartnerSelect(value)
                                        }
                                        placeholder={I18n.get(
                                          "Selecione um parceiro abaixo"
                                        )}
                                        error=""
                                        disabled={
                                          partnersList?.length === 0
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                  </Col>
                                </Row>
                              </S.ContainerSelects>
                            </>
                          ) : null}

                          {typePartner === 2 ? (
                            <>
                              <S.ContainerSelects>
                                <Row>
                                  <Col xs={24} sm={24}>
                                    <div style={{ marginTop: 20 }}>
                                      {visibleGlobal && (
                                        <GlobalFileBox
                                          title={I18n.get("Atenção!")}
                                          description={I18n.get(
                                            "Esta é uma marca Global. Anexe aqui a aprovação de global para este projeto."
                                          )}
                                          username={auth?.username}
                                          action={(data) => saveGlobal(data)}
                                          file={
                                            projectDetails?.globalAuthorization
                                          }
                                        />
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </S.ContainerSelects>
                            </>
                          ) : null}

                          {typePartner === 1 || typePartner === 3 ? (
                            <>
                              {state?.client?.country?.code === "BRA" ? (
                                <S.ContainerSelects>
                                  <S.ContentSelect>
                                    <Row>
                                      <Col xs={24} sm={24}>
                                        <S.TextSOS>
                                          <strong>
                                            {I18n.get("SOS Rio Grande do Sul")}
                                          </strong>
                                          <img
                                            src={FlagRS}
                                            alt="Bandeira do Rio Grande do Sul"
                                          />
                                        </S.TextSOS>
                                      </Col>
                                      <Col xs={24} sm={24}>
                                        <div>
                                          {I18n.get(
                                            "Neste momento delicado que vive o RS, quer dar preferência por parceiros daquele estado para apoiar a economia local?"
                                          )}
                                        </div>
                                      </Col>
                                      <Col xs={24} sm={24}>
                                        <div style={{ marginTop: 16 }}>
                                          <strong>
                                            {I18n.get(
                                              "Se sim, vamos procurar inserir ao menos um parceiro do RS na sua seleção"
                                            )}
                                          </strong>
                                        </div>
                                      </Col>
                                      {loaderGetProject ? (
                                        <Loading
                                          text={I18n.get("Carregando...")}
                                          sizeText={14}
                                          sizeIcon={16}
                                          align="start"
                                        />
                                      ) : (
                                        <RadioGroup
                                          columns={2}
                                          options={[
                                            I18n.get("Sim"),
                                            I18n.get("Não"),
                                          ]}
                                          optionDefault={
                                            projectDetails.sosrs === null
                                              ? 0
                                              : projectDetails.sosrs
                                              ? 0
                                              : 1
                                          }
                                          onChange={(checked) =>
                                            setSosRS(
                                              checked === 0 ? true : false
                                            )
                                          }
                                          style={{ margin: "15px 0" }}
                                        />
                                      )}
                                    </Row>
                                  </S.ContentSelect>
                                </S.ContainerSelects>
                              ) : null}

                              <S.ContainerSelects>
                                <S.ContentSelect>
                                  <Row>
                                    <Col xs={24} sm={24}>
                                      <div>
                                        {I18n.get(
                                          "Gostaria somente de parceiros de alguma região específica?"
                                        )}
                                      </div>
                                    </Col>
                                    {loaderGetProject ? (
                                      <Loading
                                        text={I18n.get("Carregando...")}
                                        sizeText={14}
                                        sizeIcon={16}
                                        align="start"
                                      />
                                    ) : (
                                      <RadioGroup
                                        columns={2}
                                        options={[
                                          I18n.get("Sim"),
                                          I18n.get("Não"),
                                        ]}
                                        optionDefault={
                                          projectDetails.specificRegionOnly
                                            ? 0
                                            : 1
                                        }
                                        onChange={onChangeSpecificRegionOnly}
                                        style={{ margin: "15px 0" }}
                                      />
                                    )}
                                    <Col xs={24} sm={24}>
                                      {!regionDisabled ? (
                                        loaderGetProject ? (
                                          <Loading
                                            text="Loading..."
                                            sizeText={14}
                                            sizeIcon={16}
                                            align="start"
                                          />
                                        ) : (
                                          <SelectOne
                                            options={
                                              constants.STATES_AND_REGIONS
                                            }
                                            value={specificRegion}
                                            onChange={(e) =>
                                              setSpecificRegion(e)
                                            }
                                            placeholder={I18n.get(
                                              "Selecione a região específica"
                                            )}
                                            error={
                                              projectErrors?.specificRegion
                                                ?.errorMessage
                                            }
                                          />
                                        )
                                      ) : null}
                                    </Col>
                                  </Row>
                                </S.ContentSelect>
                              </S.ContainerSelects>

                              <S.ContainerSelects>
                                <S.ContentSelect>
                                  <S.SelectsTitle>
                                    {I18n.get("Expertise")}
                                  </S.SelectsTitle>
                                  <Row>
                                    <Col xs={24} sm={24}>
                                      <div>
                                        {I18n.get(
                                          "Busco somente parceiros com expertise na categoria da minha marca:"
                                        )}
                                      </div>
                                    </Col>
                                    {loaderGetProject ? (
                                      <Loading
                                        text={I18n.get("Carregando...")}
                                        sizeText={14}
                                        sizeIcon={16}
                                        align="start"
                                      />
                                    ) : (
                                      <RadioGroup
                                        columns={2}
                                        options={[
                                          I18n.get("Sim"),
                                          I18n.get("Não"),
                                        ]}
                                        optionDefault={
                                          projectDetails.sameCategoryOnly
                                            ? 0
                                            : 1
                                        }
                                        onChange={onChangeSameCategoryOnly}
                                        style={{ margin: "15px 0" }}
                                      />
                                    )}
                                    <Col xs={24} sm={24}>
                                      <div>
                                        {I18n.get(
                                          "Busco parceiros com outros expertises?"
                                        )}
                                      </div>
                                    </Col>
                                    {loaderGetProject ? (
                                      <Loading
                                        text={I18n.get("Carregando...")}
                                        sizeText={14}
                                        sizeIcon={16}
                                        align="start"
                                      />
                                    ) : (
                                      <RadioGroup
                                        columns={2}
                                        options={[
                                          I18n.get("Sim"),
                                          I18n.get("Não"),
                                        ]}
                                        optionDefault={
                                          projectDetails.hasAdditionalCategories
                                            ? 0
                                            : 1
                                        }
                                        onChange={
                                          onChangeHasAdditionalCategories
                                        }
                                        style={{ margin: "15px 0" }}
                                      />
                                    )}
                                    {!expertiseDisabled ? (
                                      loaderGetProject ? (
                                        <Loading
                                          text={I18n.get("Carregando...")}
                                          sizeText={14}
                                          sizeIcon={16}
                                          align="start"
                                        />
                                      ) : (
                                        <Col xs={24} sm={24}>
                                          {/* <Controller
                                  style={{ width: "100%" }}
                                  control={control}
                                  name="expertiseId"
                                  render={({ field }) => (
                                    <SelectOne
                                      options={getCategoryValues()}
                                      value={categoryIds}
                                      multiple={true}
                                      onChange={(e) => onSelectItem(e, categoryIds, setCategoryIds)}
                                      placeholder={I18n.get("Select the Expertise")}
                                      error={
                                        projectErrors?.categoryIds?.errorMessage
                                      }
                                    />
                                  )}
                                /> */}
                                          <S.WrapperSelectOne>
                                            <SelectOne
                                              options={getCategoryValues()}
                                              value={categoryIds}
                                              defaultValue=""
                                              onChange={handleChangeExpertise}
                                              required
                                              placeholder={I18n.get(
                                                "Selecione a Expertise"
                                              )}
                                              error={
                                                projectErrors?.categoryIds
                                                  ?.errorMessage
                                              }
                                              multiple
                                            />
                                          </S.WrapperSelectOne>
                                        </Col>
                                      )
                                    ) : null}
                                  </Row>
                                </S.ContentSelect>
                              </S.ContainerSelects>

                              <S.ContainerSelects>
                                <S.ContentSelect>
                                  <S.SelectsTitle>
                                    {I18n.get("Diversidade & Inclusão")}
                                  </S.SelectsTitle>
                                  <Row>
                                    <Col xs={24} sm={24}>
                                      <div>
                                        {I18n.get(
                                          "Quero conhecer pelo menos um partner que tenha diversidade na equipe de liderança ou nos acionistas"
                                        )}
                                      </div>
                                    </Col>
                                    {loaderGetProject ? (
                                      <Loading
                                        text={I18n.get("Carregando...")}
                                        sizeText={14}
                                        sizeIcon={16}
                                        align="start"
                                      />
                                    ) : (
                                      <RadioGroup
                                        columns={3}
                                        options={[
                                          I18n.get("Sim, faço questão"),
                                          I18n.get("Sim, mas não obrigatório"),
                                          I18n.get("Não"),
                                        ]}
                                        optionDefault={optionDefaultDiversity(
                                          projectDetails.diversity
                                        )}
                                        onChange={onChangeRadioDiversity}
                                        style={{ margin: "15px 0" }}
                                      />
                                    )}
                                    {!diversityDisabled ? (
                                      loaderGetProject ? (
                                        <Loading
                                          text={I18n.get("Carregando...")}
                                          sizeText={14}
                                          sizeIcon={16}
                                          align="start"
                                        />
                                      ) : (
                                        <>
                                          <Col xs={24} sm={24}>
                                            <SelectOne
                                              options={getDiversityValues()}
                                              value={diversitySpecificOwner}
                                              multiple={true}
                                              onChange={(e) =>
                                                handleChangeDiversities({
                                                  type: "diversitySpecificOwner",
                                                  values: e,
                                                })
                                              }
                                              placeholder={I18n.get(
                                                "Representantes de Owner"
                                              )}
                                              error={
                                                diversitySpecificOwner ||
                                                diversitySpecific
                                                  ? null
                                                  : projectErrors
                                                      ?.diversitySpecificOwner
                                                      ?.errorMessage
                                              }
                                            />
                                          </Col>
                                          <Col
                                            xs={24}
                                            sm={24}
                                            style={{ marginTop: 30 }}
                                          >
                                            <SelectOne
                                              options={getDiversityValues()}
                                              value={diversitySpecific}
                                              multiple={true}
                                              onChange={(e) =>
                                                handleChangeDiversities({
                                                  type: "diversitySpecific",
                                                  values: e,
                                                })
                                              }
                                              placeholder={I18n.get(
                                                "Representantes de Owner ou Led"
                                              )}
                                              error={
                                                diversitySpecific ||
                                                diversitySpecificOwner
                                                  ? null
                                                  : projectErrors
                                                      ?.diversitySpecific
                                                      ?.errorMessage
                                              }
                                            />
                                          </Col>
                                        </>
                                      )
                                    ) : null}
                                  </Row>
                                </S.ContentSelect>
                              </S.ContainerSelects>
                            </>
                          ) : null}

                          <S.DisplayErrors>
                            <Row>
                              <Col lg={24}>
                                {Object.keys(projectErrors).length > 0 && (
                                  <>
                                    <h3>
                                      {I18n.get(
                                        "Ops... Alguns campos precisam ser informados."
                                      )}
                                    </h3>
                                    <br />
                                  </>
                                )}

                                {Object.keys(projectErrors).map(
                                  (item, index) => {
                                    return (
                                      <p>
                                        {" "}
                                        <span class="icon has-text-danger">
                                          <i class="fas fa-exclamation"></i>
                                        </span>
                                        {projectErrors[item].errorMessage}
                                      </p>
                                    );
                                  }
                                )}

                                {Object.keys(projectErrors).length > 0 && (
                                  <div>
                                    <p>
                                      <Link
                                        to={`/cliente/projetos/v2/checkout/${params.projectId}`}
                                      >
                                        <ButtonBpool
                                          text={I18n.get(
                                            "Continuar e relatar mais tarde"
                                          )}
                                          className=""
                                          theme="primary"
                                        />
                                      </Link>
                                    </p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </S.DisplayErrors>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={24}>
                          <S.FooterButtons className="buttonsContainer">
                            <ButtonBpool
                              text={I18n.get("Voltar")}
                              theme="secondary"
                              onClick={() =>
                                history.push(
                                  `/cliente/projetos/v2/demandas/${params.projectId}`
                                )
                              }
                            />
                            <ButtonBpool
                              text={I18n.get("Próximo")}
                              className=""
                              theme="primary"
                              onClick={() => sendToServer(false)}
                              loading={loader}
                            />
                          </S.FooterButtons>
                        </Col>
                      </Row>
                    </Main>
                  </Col>
                  <Col xs={24} sm={7}>
                    <Main
                      padding="30px"
                      style={{ background: "#fafafa", padding: "0" }}
                    >
                      <ReviewProject project={projectDetails} />
                    </Main>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
