import React, { useEffect } from "react";
import { I18n } from "aws-amplify";
import { useSelector } from 'react-redux';
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import Loading from "../../../../../components/UI/Loading";

import Calendar from "./svgs/calendar.svg";
import Check from "./svgs/check.svg";
import Wallet from "./svgs/wallet.svg";
import Time from "./svgs/time.svg";
import Clock from "./svgs/clock.svg";
import Warning from "./svgs/warning.svg";
import * as S from "../styles";

export const Details = ({ isLoading, data, style }) => {
  const auth = useSelector((state) => state.auth);

  const getNonCompete = (days) => {
    var txt = I18n.get("Nenhuma");

    if (days == 0)
      txt = I18n.get(
        "Caso você seja selecionado, não poderá trabalhar com concorrentes diretos da mesma categoria durante o projeto."
      );

    if (days > 0)
      txt = I18n.get(
        "Caso você seja selecionado, não poderá trabalhar com concorrentes diretos da mesma categoria durante o projeto @@days@@ dias a partir do encerramento do projeto"
      ).replace("@@days@@", days);

    return txt;
  }

  return (
    <S.BoxItem style={style ? style : {}}>
      <S.BoxItemTitle>
        <p>{I18n.get("Detalhes")}</p>
        <span />
      </S.BoxItemTitle>

      {isLoading ? (
        <Loading
          text={I18n.get("Carregando...")}
          sizeText={14}
          sizeIcon={16}
          color="#000000"
          align="left"
        />
      ) : (
        <>
          <S.IcoRow style={{ marginTop: 0 }}>
            <S.Ico>
              <img src={Calendar} alt="Calendar" />
            </S.Ico>
            <S.IcoInfos>
              <p>
                <span>{`${I18n.get("Previsão do Projeto")}`}</span>
              </p>
              <p>
                <span>{`${I18n.get("Início")}: `}</span>{data?.estimatedStartDate}
              </p>
              <p>
                <span>{`${I18n.get("Término")}: `}</span>{data?.estimatedEndDate}
              </p>
            </S.IcoInfos>
          </S.IcoRow>

          <S.IcoRow>
            <S.Ico>
              <img src={Check} alt="ico Check" />
            </S.Ico>
            <S.IcoInfos>
              <p>
                <span>{`${I18n.get("Prazo de seleção do parceiro")}`}</span>
              </p>
              <p>
                {data?.estimatedDecisionInWeeks}
              </p>
            </S.IcoInfos>
          </S.IcoRow>

          <S.IcoRow>
            <S.Ico>
              <img src={Wallet} alt="ico Wallet" />
            </S.Ico>
            <S.IcoInfos>
              <p>
                <span>{`${I18n.get("Prazo pagamento do cliente")}`}</span>
              </p>
              <p>
                {data?.paymentTermDays}
              </p>
              <p>
                <span className="small">{I18n.get("A contar da data do faturamento.")}</span>
              </p>
            </S.IcoInfos>
          </S.IcoRow>

          <S.IcoRow>
            <S.Ico>
              <img src={Time} alt="ico Time" />
            </S.Ico>
            <S.IcoInfos>
              <p>
                <span>{`${I18n.get("Termo de Exclusividade")}`}</span>
              </p>
              <p>
                {getNonCompete(data?.nonCompeteInDays)}
              </p>
            </S.IcoInfos>
          </S.IcoRow>

          {auth?.is_partner && data?.additionalRule ? (
            <S.AdditionalInfos>
              <div>
                <img src={Warning} alt="ico Warning" />
              </div>
              <S.AdditionalInfosTexts>
                <p className="strong">{I18n.get("Additional Client Rules")}</p>
                <p>{I18n.get("Download to consult additional rules")}</p>
              </S.AdditionalInfosTexts>
              <S.AdditionalInfosAction>
                <a href={data?.additionalRule?.url}>
                  <ButtonBpool
                    text={I18n.get("Download")}
                    theme="primary"
                    onClick={() => true}
                  />
                </a>
              </S.AdditionalInfosAction>
            </S.AdditionalInfos>
          ) : null}
        </>
      )}
    </S.BoxItem>
  );
};
