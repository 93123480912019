import styled from "styled-components";
import { Modal } from "antd";

export const Wrapper = styled.div``;

export const MainModal = styled(Modal)`
  &.ant-modal {
    width: 495px !important;

    .ant-modal-body {
      padding: 30px 24px 24px 24px;
    }

    .ant-modal-content {
      background: ${(props) =>
        props.theme === "white" ? "#ffffff" : "#000000"};
      color: ${(props) => (props.theme === "white" ? "#000000" : "#ffffff")};
    }

    .ant-modal-footer {
      padding: 30px 30px 45px 30px;
      border: none;
    }

    .ant-modal-footer > div {
      display: flex;
      justify-content: center;
    }

    .ant-modal-footer > div > button {
      border-radius: 10px;
      padding: 0 50px;
      height: 50px;
      font-weight: 700;
      font-size: 14px;
      margin: 0 10px;
    }

    .ant-modal-footer > div > button:last-of-type {
      background-color: #1883ff;
      border-color: #1883ff;
      color: #fff;
    }

    .ant-modal-footer > div > button:first-of-type {
      border: solid 1px #1883ff;
      background: #ffffff;
      color: #1883ff;
    }

    .ant-modal-footer > div > button {
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
`;

export const Icone = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: ${(props) => (props.theme === "white" ? "#000000" : "#ffffff")};
`;

export const SubText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${(props) => (props.theme === "white" ? "#000000" : "#ffffff")};
  margin-top: 16px;
`;
