import axios from 'axios';
import { Auth } from 'aws-amplify';
import Config from '../../../../config';

axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';

export const usePartnerScore = () => {

  const getPartners = async () => {

    const session = await Auth.currentSession();

    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/BP/partner-score`,
      config
    );

    return data;
  };

  

  return {
    getPartners,
  };

};
