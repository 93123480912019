import get_partner from './get';
import create_partner from './create';
import confirm_partner from './confirm';
import update_step1 from './update-step1';
import add_case from './add-case';
import update_case from './edit-case';
import remove_case from './remove-case';
import upload from './upload';
import get_products from './get-products';
import get_detail_products from './get-detail-product';

export const PARTNER_GET = 'partner_whatif_get';
export const PARTNER_CREATE = 'partner_whatif_create';
export const PARTNER_UPDATE = 'partner_whatif_update';
export const PARTNER_UPDATE_STEP1 = 'partner_whatif_update_step1';
export const PARTNER_ADD_CASE = 'partner_whatif_add_case';
export const PARTNER_REMOVE_CASE = 'partner_whatif_add_case';
export const PARTNER_UPDATE_CASE = 'partner_whatif_update_case';
export const PARTNER_PRODUCTS_GET = 'partner_whatif_get_products';


export const PARTNER_WHATIF_API = {
    get: get_partner,
    create: create_partner,
    confirm: confirm_partner,
    update_step1: update_step1,
    add_case: add_case,
    update_case: update_case,
    remove_case: remove_case,
    upload: upload,
    get_products: get_products,
    get_detail_products: get_detail_products
}
