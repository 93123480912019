import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';
import { PROJECT_API } from '../../actions/project/actions';
import FileUploadList from '../../modules/components/FileUploadList';

import iconUpload from '../../svg/icon-upload.svg';
import Dropzone from 'react-dropzone';

import { scrollToTop } from '../../utils/ui';


class QuotationForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            form: {
                files: props.files || [],
                isSubmit: false,
            },
            task: {},
            upload_task: {}
        }

        this.upload = this.onUpload.bind(this);
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.project && this.props.project.task;

        if (!prevState) return;
        if (!task) return;

        var nextState = Object.assign({}, prevState);

        if (task.id != prevState.task.id) {

            nextState.task = task;

            if (task.result) {

                nextState.form.files = this.props.files || [];

            }

            this.setState(nextState);
        }
    }

    componentWillUnmount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onUpload(file, e) {

        if (!file) return;

        if (e) e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            var upload_task = new Task();

            upload_task.start();

            this.setState({ upload_task: upload_task }, function () {

                const data = { projectId: this.props.match.params.projectId, name: file.name, base64: e.target.result };

                this.props.upload_file(data)
                    .then(response => {

                        this.setState((prevState) => {

                            var nextState = Object.assign({}, prevState);

                            nextState.form.files.push(response.data);
                            nextState.upload_task = { result: true };

                            return nextState;
                        });

                    })
                    .catch(error => {

                        this.setState({ upload_task: { result: false } });

                    });
            });
        };
        reader.readAsDataURL(file);
    }

    delFile(item) {

        var data = {
            projectId: this.props.match.params.projectId,
            ...item
        };

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.delete_file(data);
        });


    }

    onDrop(files) {

        files = files.map(file => {

            this.upload(file);
        });
    }

    onCancel() {
    }

    getDataUploadMult = (files) => {

        let file = files[0];

        delete file['uid'];

        const data = {
            ...this.props.project,
            filesSend: file
        }

        this.props.upload_filev2(data);
    }



    render() {
        let filesNormalize = this.props.files && this.props.files.map((item) => {
            return {
                ...item,
                uid: item.id,
            }
        })

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Upload de arquivos")}</h1>

                <div className="bp-event-section">


                    <div className="regular-control-text" style={{ marginTop: '1rem' }}>{I18n.get(
                        "Faça upload de arquivos que possam ajudar os parceiros criativos na elaboração das propostas (guides de marca, pesquisas, referências de ações anteriores, etc)."
                    )}</div>

                    <div style={{ fontSize: '80%', marginTop: '50px' }}>

                        {this.props.files && this.props.files.map((item, index) =>
                            (<div key={index} class="columns" style={{ borderBottom: "1px solid black" }}>
                                <div class="column is-two-thirds is-clearfix upload-item" >
                                    <a href={item.url} target="_blank">{item.name}</a>
                                </div>
                                <div class="column ">
                                    {item.uploadedDateFmt}
                                    {!this.state.upload_task.busy
                                        && !this.state.task.busy && <div className="is-pulled-right">

                                            {item.uploadedByUsername == this.props.auth.username
                                                && <a className="delete" onClick={this.delFile.bind(this, item)} ></a>}
                                        </div>}
                                </div>
                            </div>)
                        )}

                    </div>

                    <br /><br />

                    <div style={{ maxWidth: '50%' }}>

                        <FileUploadList
                            getDataUpload={this.getDataUploadMult}
                            name="files"
                            uploadId={this.props.project.projectId}
                            get2={this.props.get2 || false}
                            fileListGet={filesNormalize || []}
                            disabledList={true}
                            limit={1}
                            output="object"
                        />

                        {/* <Dropzone style={{}}
                            multiple={false}
                            onDrop={this.onDrop.bind(this)}
                            onFileDialogCancel={this.onCancel.bind(this)}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <button className="button bt-bpool black is-black is-fullwidth upload-button-style" type="button" disabled={this.state.upload_task.busy}>
                                        {this.state.upload_task.busy && <a className="button is-loading is-black" style={{ background: 'transparent' }}></a>}
                                        {!this.state.upload_task.busy && <><span>{I18n.get("Upload")}</span>
                                            <div className="upload-button-icon"><img src={iconUpload} alt="Upload" /></div></>}
                                    </button>
                                </div>
                            )}
                        </Dropzone> */}

                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                </div>

            </div>
        );
    }
}

export default QuotationForm;
