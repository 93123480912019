import React, { useState } from "react";
import { I18n } from "aws-amplify";

import { useUpload } from "../../../../../hooks/useUpload/useUpload.hook";
import { OutlineUpload } from "../../../../../components/UI/OutlineUpload";
import * as S from "./styles";

const GlobalFileBox = (props) => {
  const [globalFile, setGlobalfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { uploadFile } = useUpload();

  const fileToUpload = async (file) => {
    setGlobalfile(file);

    setLoading(true);

    const globalUpload = await uploadFile({
      file: file,
      idUser: props.username,
      fieldId: `globalFile`,
    });

    await props.action(globalUpload);
    setLoading(false);
  };

  const removeFile = async () => {
    setLoading(true);
    setGlobalfile(null);
    await props.action({});

    setLoading(false);
  };

  return (
    <S.Wrapper>
      {props?.title && <h2>{props?.title}</h2>}
      {props?.description && <p>{props?.description}</p>}
      <OutlineUpload
        id="fileGlobal"
        loading={loading}
        label={I18n.get("Aprovação Global")}
        fileToUpload={(id, file) => {
          fileToUpload(file);
        }}
        file={props?.file || globalFile}
        fileDefault={props?.file || globalFile}
        removeFileDefault={() => removeFile()}
      />
    </S.Wrapper>
  );
};

export default GlobalFileBox;
