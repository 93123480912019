import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Form, Checkbox, Select, Button } from "antd";

import styled from "styled-components";

const FormBox = styled(Form)``;

class ProductTmarket extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formState: values,
        });

        this.props.action(values);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (this.props.errors != prevProps.errors && this.props.errors) {
      Object.keys(this.props.errors).map((field) => {
        this.props.form.setFields({
          [field]: {
            value: this.state.formState[field],
            errors: [new Error(this.props.errors[field].errorMessage)],
          },
        });
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { filters } = this.props;

    const { item = {} } = this.props;

    return (
      <FormBox onSubmit={this.handleSubmit}>
        <Row>
          <Col sm={24}>
            <Form.Item>
              {getFieldDecorator("tMarket", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: this.props.tMarket,
              })(
                <Checkbox.Group>
                  <span>
                    <Checkbox value={1}>1</Checkbox>
                  </span>
                  <span>
                    <Checkbox value={2}>2</Checkbox>
                  </span>
                </Checkbox.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Form.Item className="labelClean" label="&nbsp;">
              <Button
                className="button bt-bpool black"
                shape="round"
                htmlType="submit"
                disabled={getFieldValue("tMarket") == ""}
                loading={this.props?.task?.busy}
              >
                {I18n.get("Salvar")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </FormBox>
    );
  }
}

const FormProductTmarket = Form.create({})(ProductTmarket);

export default FormProductTmarket;
