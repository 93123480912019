import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import RadioMultiField from "../form/radio-multi-field";
import CheckboxMultiField from "../form/checkbox-multi-field";
import TextAreaField from "../form/textarea-field";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

import { Input } from "../../v2/components/UI/Input";
import { Textarea } from "../../v2/components/UI/Textarea";

class ServicesForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        cateringType: {
          label: I18n.get(
            "Vai precisar de algum desses serviços de Catering abaixo?"
          ),
          onChange: this.onChangeFormCheckboxMulti.bind(this, "cateringType"),
          // onBlur: this.onSave.bind(this),
        },
        foodType: {
          label: I18n.get("Qual o tipo de alimentação?"),
          onChange: this.onChangeFormCheckboxMulti.bind(this, "foodType"),
          // onBlur: this.onSave.bind(this),
        },
        foodRestriction: {
          label: I18n.get("Tem alguma restriçao alimentar?"),
          onChange: this.onChangeForm.bind(this, "foodRestriction"),
          // onBlur: this.onSave.bind(this),
        },
        foodRestrictionDesc: {
          label: I18n.get("Por favor descreva a restriçao alimentar"),
          onChange: this.onChangeForm.bind(this, "foodRestrictionDesc"),
          // onBlur: this.onSave.bind(this),
        },
        drinkRestriction: {
          label: I18n.get("Tem alguma restriçao de bebidas?"),
          onChange: this.onChangeForm.bind(this, "drinkRestriction"),
          // onBlur: this.onSave.bind(this),
        },
        drinkRestrictionDesc: {
          label: I18n.get("Por favor descreva a restrição de bebidas"),
          onChange: this.onChangeForm.bind(this, "drinkRestrictionDesc"),
          // onBlur: this.onSave.bind(this),
        },
        cateringDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "cateringDesc"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckbox(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop].value = [...nextState.form[prop].value, value];
        } else {
          nextState.form[prop].value = nextState.form[prop].value.filter(
            (v) => v !== value
          );
        }

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const transportTypeValues = constants.EVENT_TRANSPORT_TYPES.map((item) => {
      return item;
    });

    const isTransportDescEnabled =
      Array.isArray(this.state.form.transportType) &&
      (this.state.form.transportType.indexOf("UBER_TAXI") > -1 ||
        this.state.form.transportType.indexOf("SHUTTLE") > -1);

    const isThemedCousineEnabled =
      Array.isArray(this.state.form.cateringType) &&
      this.state.form.cateringType.indexOf("THEMED_COUSINE") > -1;

    const cateringTypeTypeValues = constants.EVENT_CATERING_TYPES.map(
      (item) => {
        if (item.code == "THEMED_COUSINE") {
          item.field = (
            <Input
              onChange={(value) =>
                this.state?.fields?.cateringDesc.onChange(value)
              }
              value={this.state.form.cateringDesc}
              disabled={!isThemedCousineEnabled}
              error={this.state.errors.cateringDesc?.errorMessage}
            />
          );
        }

        return item;
      }
    );

    const foodTypeValues = constants.EVENT_FOOD_TYPES.map((item) => {
      return item;
    });

    //TODO: Find better way... GAMBI!
    let yesAndNo = [...constants.YESNO];
    for (let key in yesAndNo) {
      yesAndNo[key].label = I18n.get(yesAndNo[key].label);
    }

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("A & B")}</h1>

        <div className="bp-event-section bpForce mini">
          <CheckboxMultiField
            {...this.state.fields.cateringType}
            value={this.state.form.cateringType}
            error={this.state.errors.cateringType}
            values={cateringTypeTypeValues}
          />

          <br style={{ clear: "both" }} />
          <br />

          <CheckboxMultiField
            {...this.state.fields.foodType}
            value={this.state.form.foodType}
            error={this.state.errors.foodType}
            values={foodTypeValues}
          />

          <br style={{ clear: "both" }} />
          <br />

          <RadioMultiField
            {...this.state.fields.foodRestriction}
            value={this.state.form.foodRestriction}
            error={this.state.errors.foodRestriction}
            values={yesAndNo}
          />

          <div className="text-field-margin">
            {/* <TextAreaField
              {...this.state.fields.foodRestrictionDesc}
              value={this.state.form.foodRestrictionDesc}
              error={this.state.errors.foodRestrictionDesc}
              disabled={this.state.form.foodRestriction !== "true"}
            /> */}

            <Textarea
              label={this.state.fields.foodRestrictionDesc.label}
              defaultValue={this.state.form.foodRestrictionDesc}
              value={this.state.form.foodRestrictionDesc}
              onChange={(value) =>
                this.state?.fields?.foodRestrictionDesc.onChange(value)
              }
              error={this.state?.errors?.foodRestrictionDesc?.errorMessage}
              disabled={this.state.form.foodRestriction !== "true"}
            />
          </div>

          <br style={{ clear: "both" }} />
          <br />

          <RadioMultiField
            {...this.state.fields.drinkRestriction}
            value={this.state.form.drinkRestriction}
            error={this.state.errors.drinkRestriction}
            values={yesAndNo}
          />

          <div className="text-field-margin">
            {/* <TextAreaField
              {...this.state.fields.drinkRestrictionDesc}
              value={this.state.form.drinkRestrictionDesc}
              error={this.state.errors.drinkRestrictionDesc}
              disabled={this.state.form.drinkRestriction !== "true"}
            /> */}

            <Textarea
              label={this.state.fields.drinkRestrictionDesc.label}
              defaultValue={this.state.form.drinkRestrictionDesc}
              value={this.state.form.drinkRestrictionDesc}
              onChange={(value) =>
                this.state?.fields?.drinkRestrictionDesc.onChange(value)
              }
              error={this.state?.errors?.drinkRestrictionDesc?.errorMessage}
              disabled={this.state.form.drinkRestriction !== "true"}
            />
          </div>
          <br style={{ clear: "both" }} />
          <br />
        </div>
      </div>
    );
  }
}

export default ServicesForm;
