import React from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import ProjectNfse from './project-nfse';

import Steps from './steps';

import { scrollToTop } from '../../utils/ui';

class ProjectOnGoingNfse extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount(){
        scrollToTop();
    }

    render() {


        return (
            <FillForm>
                <div className="hero-body has-background-white project-ongoing-header">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-4">
                                <p className="title">{I18n.get("Projeto em Andamento")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-8">
                                <Steps active={7} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []}  />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-ongoing">

                    <div className="container client-container-1 project-ongoing-review">

                    <ProjectNfse {...this.props} />

                        <br />
                        <br />


                    </div>

                    <div className="columns">

                                <div className="column">
                                </div>

                                <div className="column">

                                                <div className="field is-grouped is-grouped-centered">
                                                    <p className="control is-expanded" onClick={this.scrollToTop}>
                                                    <Link to={`/projetos/${this.props.match.params.projectId}/resumo`} 
                                                        className="button is-black is-outlined is-fullwidth previous">{I18n.get("Voltar")}</Link>
                                                    </p>
                                                    
                                                </div>

                                </div>

                                <div className="column">
                                </div>


                    </div>



                </div>

         </FillForm>
        );
    }
}

export default ProjectContainer(ProjectOnGoingNfse);
