import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TooltipField from '../form/tooltip-field';


const mapStateToProps = state => {
    return state;
};
const mapDispatchToProps = dispatch => {
    return {}
};

class Steps extends React.Component {
    constructor(props) {
        super();
        this.state = {
            items: [
                {
                    index: 1,
                    title: I18n.get("Sobre Você"),
                    color: "#F0E2A1",
                    link: "/lider/passo-1"
                },
                {
                    index: 2,
                    title: I18n.get("Segmentos & Expertises"),
                    color: "#FFC5B9",
                    link: "/lider/passo-2"
                },
                {
                    index: 3,
                    title: I18n.get("Cases & Prêmios"),
                    color: "#A8D7EB",
                    link: "/lider/passo-3"
                },
            ]
        };
    }

    render() {
        const { items } = this.state
        const { active } = this.props



        let name = '';

        if (this.props.leader && this.props.leader.nameAlias) {

            name = this.props.leader.nameAlias;
        }
        else if (this.props.leader && this.props.leader.name) {

            name = this.props.leader && this.props.leader.name;

            let parts = name.split(' ');

            if (parts.length > 0) {
                name = parts[0];
            }
        }

        let txt = '';

        return (
            <div>
            <ul id="partner-signup-steps" className="leader-signup-steps">
                {items.map(function(item) {

            switch(active) {
                case 1:
                    txt = I18n.get("Nosso algoritmo quer saber mais sobre você, que está na liderança. Lembre-se de que cada informação é valiosa para ele encontrar projetos que tenham a ver com sua empresa.");
                    break;
                case 2:
                    txt = I18n.get("Experiência sempre conta pontos. O algoritmo está curioso para conhecer os mercados em que você atuou e quais suas especialidades.");
                    break;
                default:
                    txt = '';
                    break;
            }

                    const lineStyle = {
                        background: item.color,
                    };
                    return (<li key={item.index} className={(active && item.index === active && "progress-bar--item is-active") || "progress-bar--item"}>
                        <h3>{item.index}
                        {active && item.index === active && <TooltipField tooltip={txt} orientation="bottom" canClose={true} withoutquestionmark="true" isfixed="true" />}
                        </h3>
                        <Link to={item.link} className="progress-bar--menu-item">
                            <span style={lineStyle}></span>
                            <strong className="is-not-mobile">{item.title}</strong>
                        </Link>
                    </li>)
                })}
            </ul>
        </div>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
