import React from "react";

import styled from "styled-components";
import ProjectContainer from "../../containers/project-partner-container";


import FillForm from "../page-templates/fillForm-logged";

import BriefingForm from "../../components/client-project-definition/briefing-form-suggestion";

import {
  
  Select,
  
} from "antd";

const Container = styled.div`
  margin-top: 120px;
  margin-bottom: 50px;
`;

class NewProjectStep1Briefing extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    // if (!this.props.partner.partnerId) return null;

    return (
      <FillForm>
        <div className="client-step client-step-1 mob-container project-definition">
          <div className="container client-container-1 client-container-build-project uplift-step-2">
            <Container>
              <BriefingForm {...this.props} isSuggestion={true} />
            </Container>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(NewProjectStep1Briefing);
