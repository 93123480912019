import React, { useState, useEffect } from 'react';
import LoggedPage from '../../page-templates/fillForm-logged'
import MainTitle from '../../../components/boxes-home/MainTitle';
import NewsContainer from "../../../components/boxes-home/NewsContainer";
import * as S from './styles';
import { useMediaQuery } from './../../../utils/mediaQuery';
import { I18n, Auth } from 'aws-amplify';
import Button from '../../boxes-home/Button';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import LoadingPage from '../loading';
import axios from 'axios';

const NewsListScreen = ({auth}) => { 
    useMediaQuery();
    const [selectedCategory, setSelectedCategory] = useState('Todas');
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [amount, setAmount] = useState(6);
    const [news, setNews] = useState([]);
    const [localNews, setLocalNews] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const {locale} = auth;
    const userType = (auth.is_client ? '_is_client' : '_is_partner');

    async function getCategories() {
        Auth.currentSession().then((session) => {
            const token = session && session.accessToken.jwtToken;
            let authHeader = { headers: { Authorization: 'Bearer ' + token } };
            axios.get(locale == 'pt' ? "https://cms.bpool.co/categories?_locale=pt-BR" : `https://cms.bpool.co/categories?_locale=${locale}`, authHeader)
            .then((res) => res.data)
            .then((data) => {
                setCategories(data);
            }).catch(error => {
                console.log(error)
            });
        })
    }

    async function getNews() {
        setLoading(true)
        Auth.currentSession().then((session) => {
            const token = session && session.accessToken.jwtToken;
            let authHeader = { headers: { Authorization: 'Bearer ' + token } };
            axios.get(locale == 'pt' ? `https://cms.bpool.co/news?_locale=pt-BR&_sort=published_at:DESC&${userType}=true` : `https://cms.bpool.co/news?_locale=${locale}&_sort=published_at:DESC&${userType}=true`, authHeader)
            .then(res => res.data)
            .then((data) => {
                setLocalNews(data)
                setNews(data)
                localStorage.setItem("news", JSON.stringify(data))
                setLoading(false)
            })
        })
    }

    async function getFilteredNews(cat) {
        setAmount(6)
        setLoading(true)
        setSelectedCategory(cat)
        setNews([])
        cat == 'Todas' 
            ? setNews(localNews)
            : localNews.map((result) => {
                    (result.category && 
                        result.category.name == cat) && 
                            setNews(news => [...news, result]) 
            })
        setLoading(false);
    }

    async function getSearchedNews() {
        setAmount(6);
        setNews([]) 
        searchValue == null  || searchValue == '' || searchValue == undefined
            ? setNews(localNews)
            : localNews.map((result) =>{
                (result.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
                || result.content.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) && 
                    setNews(news => [...news, result])
            })
    }

    useEffect(() => {
        getCategories();
        getNews();
    }, [locale]);

    useEffect(() => {
        const timeoutId = setTimeout(() => getSearchedNews(), 400);
        return () => clearTimeout(timeoutId);
    }, [searchValue])

    return (
        <LoggedPage>
            {!loading &&
                <S.DivNewsScreen>
                    <div className="container">
                        <MainTitle text="News" size="48px"/>
                        <S.CategoriesList>
                            <S.Category onClick={() => getFilteredNews('Todas')}>
                                {selectedCategory == 'Todas' 
                                    ? <strong>{I18n.get("Todas")}</strong> 
                                    : I18n.get("Todas")
                                }                                
                            </S.Category>
                            {categories && categories.map((res, index) =>
                                selectedCategory == res.name 
                                    ? <strong><S.Category key={index} onClick={() => getFilteredNews(res.name)}>{res.name}</S.Category></strong> 
                                    : <S.Category key={index} onClick={() => getFilteredNews(res.name)}>{res.name}</S.Category>
                            )}
                        </S.CategoriesList>
                        <S.SearchBox>
                            <S.SearchInput placeholder={I18n.get("Busca")} onChange={(e) => setSearchValue(e.target.value)}/>
                        </S.SearchBox>
                        <S.DivNewsContainer>
                            {news == null || news.length == 0 
                                ? <S.DivNotFound><p>{I18n.get("Nada encontrado")}</p></S.DivNotFound>
                                : <NewsContainer news={news.slice(0, amount)}/>
                            }
                        </S.DivNewsContainer>
                        {amount < news.length &&
                            <S.LoadButtonDiv onClick={() => setAmount(amount => amount + 6)}>
                                <Button text={I18n.get("Carregar mais")} />
                            </S.LoadButtonDiv>
                        }
                    </div>
                </S.DivNewsScreen>
            }
            {loading &&
                <S.LoaderDiv>
                    <LoadingPage/>
                </S.LoaderDiv>
            }
        </LoggedPage>
    )
}

const mapStateToProps = state => {
    return state;
};

export default connect(
    mapStateToProps,
)(withRouter(NewsListScreen));
