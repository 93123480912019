import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Steps, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { I18n } from 'aws-amplify';
import Loading from '../Loading';
import { ModalBp } from '../ModalBp/ModalBp';
import {
  flowToStorage,
  updateFlowStorage,
  toUpdateFlowStorageSteps,
} from '../../../pages/RegistrationFlow/utils/flowToStorage';
import { translations } from './translations'

import * as S from './styles';

const { Step } = Steps;

// INTEGRAÇÃO
// 2 gets (1 para tela application e 1 para as proximas, pq ele está logado)

// no outro get (telas ja loagado) não precisa enviar step
// guardar no storage um breadcrumb se o cara navegou pelas telas... se não navegou usar o storage, se navegou usar o status que vem do back (error)

// status 3 - wait / 2 - error / 1 - process

const MESSAGE_NOT_CLICKABLE =
  'You need to complete this step to navigate the bar.';
const MESSAGE_NOT_ACESS_PAGE = 'You cannot access this page at the moment.';

export const StepsVertical = ({
  isLoading,
  steps,
  clickable,
  page,
  urls,
  changedInfos,
  contextStepBar,
  token,
}) => {
  const auth = useSelector((state) => state.auth);
  let history = useHistory();
  let location = useLocation();
  const [openModal, setOpenModal] = useState({ open: false, url: '' });

  const whatUrlClick = (value) => {
    if (contextStepBar === 'full') {
      return urls[value + 7];
    }

    if (contextStepBar === 'pre') {
      return urls[value];
    }

    if (contextStepBar === 'leader') {
      const urlsLeader = steps?.map((step) => step?.url);
      return `${urlsLeader[value]}`;
    }
  };

  const onChange = (value) => {
    console.log('onChange', value);
    if (contextStepBar === 'pre' && value === 0) {
      message.error(I18n.get(MESSAGE_NOT_ACESS_PAGE));
    } else {
      if (clickable) {
        if (changedInfos) {
          setOpenModal({ open: true, url: whatUrlClick(value) });
        } else {
          toUpdateFlowStorageSteps({ screen: page });

          history.push(whatUrlClick(value));
        }
      } else {
        message.error(I18n.get(MESSAGE_NOT_CLICKABLE));
      }
    }
  };

  const translateDescriptions = (language, stringKey) => {
    const translation = translations[language];

    if (!translation) {
      throw new Error(`Unsupported language: ${language}`);
    }

    const string = translation[stringKey];

    if (!string) {
      throw new Error(`Translation not found: ${stringKey}`);
    }

    return string;
  };

  const [locale, setLocale] = useState("pt")

  const setLocaleInternal = (loc) => {
    setLocale(loc ? loc : "pt");
  };

  const getLocaleInAuthOrParams = ({ locationSearch, authLocale }) => {
    if (authLocale) {
      setLocaleInternal(authLocale);
    } else {
      const locale = locationSearch?.split("=")[1];
      setLocaleInternal(locale);
    }
  };

  useEffect(() => {
    getLocaleInAuthOrParams({ locationSearch: location?.search, authLocale: auth?.locale });
  }, [auth, location]);

  const renderDescription = (step) => {
    if (step.url === location.pathname) {
      return translateDescriptions(locale, "active");
    } else {
      if (step.visited) {
        if (step.status === 'error') {
          return translateDescriptions(locale, "error");
        } else if (step.status === 'finish') {
          return translateDescriptions(locale, "completed")
        } else {
          return translateDescriptions(locale, "tobeCompleted")
        }
      } else {
        return step.status === 'finish' ? translateDescriptions(locale, "completed") : translateDescriptions(locale, "tobeCompleted");
      }
    }
  };

  const renderIcon = ({ step }) => {
    return (
      <S.Icon className="icon-custom">
        <span>{step}</span>
      </S.Icon>
    );
  };

  const renderIconFinish = () => {
    return (
      <S.IconFinish className="icon-finish-custom">
        <CheckOutlined />
      </S.IconFinish>
    );
  };

  const renderIconError = ({ step }) => {
    return (
      <S.IconError className="icon-error-custom">
        <span>{step}</span>
      </S.IconError>
    );
  };

  const icon = ({ status, step }) => {
    if (status === 'error') {
      return renderIconError({ step });
    } else if (status === 'finish') {
      return renderIconFinish();
    } else {
      return renderIcon({ step });
    }
  };

  const handleModalConfirm = () => {
    history.push(openModal.url);
  };

  const handleModalCancel = () => {
    setOpenModal({ open: false, url: '' });
  };

  const renderIdStep = ({ step, pathnameUrl }) => {
    if (step.url === pathnameUrl) {
      return 'status-active';
    } else if (
      step.title === 'Application' &&
      pathnameUrl === '/registration-flow/application'
    ) {
      return 'status-active';
    } else {
      return 'status-not-active';
    }
  };

  const hasCurrent = ({ steps }) => {
    const indexActualStep = steps.findIndex(
      (item) => item.status === 'process'
    );

    return indexActualStep;
  };

  const isStepActive = ({ step, pathnameUrl }) => {
    if (step.url === pathnameUrl) {
      return 'status-active';
    } else {
      return 'status-not-active';
    }
  };

  return (
    <>
      {isLoading ? (
        <div>
          <Loading
            sizeIcon={18}
            text={I18n.get('Loading informations')}
            sizeText={14}
            align="flex-start"
          />
        </div>
      ) : (
        <>
          <S.Wrapper>
            <Steps
              onChange={onChange}
              current={hasCurrent({ steps })}
              direction="vertical"
            >
              {steps?.map((step, index) => (
                <Step
                  style={{ cursor: 'default' }}
                  key={step?.key}
                  title={step?.title}
                  description={renderDescription(step)}
                  status={step.status}
                  disabled={step?.title === 'Application' ? true : false}
                  className={
                    step.visited
                      ? `step-visited ${isStepActive({
                        step,
                        pathnameUrl: location.pathname,
                      })}`
                      : isStepActive({ step, pathnameUrl: location.pathname })
                  }
                  id={isStepActive({ step, pathnameUrl: location.pathname })}
                />
              ))}
            </Steps>
          </S.Wrapper>

          <ModalBp
            visible={openModal.open}
            bodyText={I18n.get('Do you really want to leave the page?')}
            subBodyText={I18n.get(
              'You changed information on the page and did not save. If you leave, you will lose.'
            )}
            okText={I18n.get('Yes')}
            handleClickOk={handleModalConfirm}
            cancelText={I18n.get('Cancel')}
            onCancel={handleModalCancel}
            typeModal="isConfirm"
            isAlert
          />
        </>
      )}
    </>
  );
};
