import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div`
  background-color: #ffffff;
  padding-bottom: 32px;
  border-bottom: 1px solid;
  border-color: #e8e8e8;
  margin-bottom: 32px;
`;

export const Info = styled.div`
  label {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
  p {
    color: #000000;
    font-size: 16px;
    line-height: 22px;
    margin-top: 4px;
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-infos {
    margin-top: 4px;
    height: 22px;

    li {
      height: 100%;
    }
  }
`;
