import styled from "styled-components";
import icoSearch from '../../../image/ico-search.svg';

export const DivNewsScreen = styled.div`
    font-family: 'HelveticaNeue', sans-serif;
    background-color: #F3F3F3;
    padding: 125px 0 90px;

    > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
`;

export const DivNewsContainer = styled.div`
    display: flex;
    flex: 0 1 100%;
`;

export const CategoriesList = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin-bottom: 40px;
`;

export const Category = styled.div`
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
`;

export const LoadButtonDiv = styled.div`
    flex: 0 1 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;

export const SearchBox = styled.div`
    background-color: #E7E7E7;
    border-radius: 26px;
    width: 320px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-flex;
        background: url(${icoSearch});
    }
`;

export const SearchInput = styled.input`
    background-color: transparent;
    border: none;

    &::placeholder {
        color: #818181;
        font-size: 16px;
        line-height: 20px;
    }
`;

export const DivNotFound = styled.div`
   height: 90vh;
   display: flex;
   align-items: flex-start;
   flex: 0 1 100%;
   justify-content: center;
`;

export const LoaderDiv = styled.div`
   z-index: 99999999;
   position: absolute;
`;