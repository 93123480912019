import { Auth } from 'aws-amplify';

import { AUTH_PASSWORD_SET } from './actions';

export const api_set_password = ({ email, password, password1, password2 }) => {
    return (dispatch, getState) => {

            const state = getState();

            const user = state.auth.user;

            var userAttributes = user.challengeParam.userAttributes;

            //delete userAttributes.email_verified;
            
            user.completeNewPasswordChallenge(password1, userAttributes,
                {
                    onSuccess: function (res) {

                       dispatch({
                            type: AUTH_PASSWORD_SET,
                            payload: { 
                                new_password_required: false
                            }
                        });
                    },
                    onFailure: function (err) {
                        throw (err);
                    }
                }
            );
    }
}

export default api_set_password;