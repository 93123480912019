import React from "react";
import { I18n } from "aws-amplify";
import { NavLink } from "react-router-dom";

import { Task } from "../../utils/task";

import ExtrasContainer from "../../containers/extras-container";

import FillForm from "../page-templates/fillForm-logged";


import StepsCreative from "../../components/client-project-definition/steps-creative";

import Steps from "./steps";

import BillingItem from "../shared/billing-item";

import ProjectPOForm from "../shared/project-po-form";

import {NewExtraPo} from "../../v2/pages/Proposal/Extras/pages/NewExtraPo";

class ExtraPOPage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      delete_task: {},
      submit_task: {},
    };
  }

  componentDidMount() {}

  onDelete(billingId, e) {
    const { projectId, extraId } = this.props.match.params;

    e.preventDefault();

    var data = {
      projectId: projectId,
      extraId: extraId,
      billingId: billingId,
    };

    var delete_task = new Task();

    delete_task.start();

    this.setState({ delete_task: delete_task });

    this.props.delete_billing(data).then(({ task, errors }) => {
      this.setState({ delete_task: task, errors: errors });
    });
  }

  submitForm(e) {}

  handleEditPo = (data) => {
    return this.props.update_po(data).then((response) => {
      if (response) {
        let data = {
          projectId: this.props.match.params.projectId,
          extraId: this.props.match.params.extraId,
        };

        this.props.get_extra(data);

        return true;
      } else {
        return false;
      }
    });
  };

  render() {
    const { billings = [] } = this.props;

    const { auth = {} } = this.props;

    const canDelete = auth.is_master || auth.is_client_or_client_user;

    const displayPO = auth.is_master || auth.is_client_or_client_user;

    const { quotation = {}, currency = {}, billingCurrency = {} } = this.props;

    const enablePO = quotation && quotation.isApproved === true;

    const endExtraEnabled =
      auth.is_client_or_client_user &&
      this.props.quotation &&
      this.props.quotation.isApproved != null;

    return (
      <FillForm>
        <div className="hero-body has-background-white">
        <div className="container">
            <div className="columns is-mobile">
              <div className="column is-4">
                <p className="title">
                  {this.props?.project && this.props?.project?.status != 6
                    ? I18n.get("Projeto em Andamento")
                    : I18n.get("Definições do Projeto")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-8">
                {this.props?.project && this.props?.project?.status == 6 && (
                  <>
                    <StepsCreative
                      active={5}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                    />
                  </>
                )}

                {this.props?.project && this.props?.project?.status != 6 && (
                  <Steps
                    active={6}
                    project={this.props.project}
                    hidden={this.props.auth.is_partner ? [5] : []}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="client-step client-step-1">
          <div
            className="container client-container-1"
            style={{ paddingTop: "0px" }}
          >
            <div className="columns" style={{ margin: 0 }}>
              

            <div className="column is-12">
                  <NewExtraPo />
            </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ExtrasContainer(ExtraPOPage);
