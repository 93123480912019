import React, { useState, useEffect} from 'react';
import MainTitle from '../../../boxes-home/MainTitle';
import NewsContainer from '../../../boxes-home/NewsContainer';
import NewsInternal from '../../../boxes-home/NewsInternal';
import LoggedPage from '../../../page-templates/fillForm-logged'
import * as S from './styles';
import { useMediaQuery } from '../../../../utils/mediaQuery';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n, Auth } from 'aws-amplify';
import axios from 'axios';

const NewsScreen = ({auth}) => {
    useMediaQuery();
    const [loading, setLoading] = useState(true);
    const [news, setNews] = useState([]);
    const {locale} = auth;
    const userType = (auth.is_client ? '_is_client' : '_is_partner');

    async function getNews() {
        const localNews = localStorage.getItem("news");

        !localNews ? 
            Auth.currentSession().then((session) => {
                const token = session && session.accessToken.jwtToken;
                let authHeader = { headers: { Authorization: 'Bearer ' + token } };
                (axios.get(locale == 'pt' ? `https://cms.bpool.co/news?_locale=pt-BR&_start=0&_limit=3&_sort=published_at:DESC&${userType}=true` : `https://cms.bpool.co/news?_locale=${locale}&_start=0&_limit=3&_sort=published_at:DESC&${userType}=true`, authHeader)
                .then(res => res.data)
                .then((data) => {
                    setNews(data)
                }))
            })
        : (setNews(JSON.parse(localStorage.getItem("news"))))
        setLoading(false)
        
    }

    useEffect (() => {
        getNews();
        window.scrollTo(0, 0);
    }, [locale])

    return (
        <LoggedPage>
            {!loading && news &&
                <S.DivNewsInternal>
                    <div className="container">
                        <NewsInternal />
                        <MainTitle text={I18n.get("Mais notícias")} marginLeft="0"/>
                        <S.DivNewsContainer>
                        {news == null || news.length == 0 
                            ? <div><p>{I18n.get("Nada encontrado")}</p></div>
                            : <NewsContainer news={news.slice(0,3)}/>
                        }
                        </S.DivNewsContainer>
                        <S.LinkAllNews href={auth.is_client ? "/#/cliente/news" : "/#/parceiro/news"}>{I18n.get("Ver todas notícias")}</S.LinkAllNews>
                    </div>
                </S.DivNewsInternal>
            }
        </LoggedPage>
    )
}

const mapStateToProps = state => {
    return state;
};

export default connect(
    mapStateToProps,
)(withRouter(NewsScreen));