import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { Row, Col, message } from 'antd';
import Main from '../../../../components/UI/Main';
import { ButtonBpool } from '../../../../components/UI/ButtonBpool';
import Loading from '../../../../components/UI/Loading';
import TabsInternal from '../../../../components/UI/TabsInternal';
import { useRegistrationFlow } from '../../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook';
import { useRegistrationFlowFull } from '../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook';
import { ModalBp } from '../../../../components/UI/ModalBp/ModalBp';
import { ButtonsSelects } from './tabs/buttonsSelects';
import { CaseForm } from './tabs/caseForm';
import { ItemCases } from './tabs/itemCases';
import Tooltip from '../../../../components/UI/Tooltip';
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
  toGetFlowStorage,
} from '../../utils/flowToStorage';
import { statusIdToName } from '../../components/Loggedv2/statusIdToName';
import { urlsFull as urls } from '../../utils/stepsUrls';
import { clearItemErrorTab } from '../../utils/errorsInput';
import * as S from './styles';

const MESSAGE_ERROR_GET_PAGE = 'There was an error, try again.';
const MESSAGE_ERROR_BACK_PAGE = 'Error in back page.';
const PAGE_INDEX = 12;

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  const {
    getSpecialityProofAndEndersement,
    postSpecialityProofAndEndersement,
  } = useRegistrationFlow();
  const { getStatusBarFull, getIndustriesSelect } = useRegistrationFlowFull();
  let history = useHistory();

  const [isLoadPage, setIsLoadPage] = useState(false);
  const [dataPage, setDataPage] = useState([]);
  const [dataPageTemp, setDataPageTemp] = useState([]);
  const [changeInfo, setChangeInfo] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [cases, setCases] = useState([]);
  const [formEditingTemp, setFormEditingTemp] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [errorsPage, setErrorsPage] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const specialityProofsIsSelected = ({ item }) => {
    const hasSelecteds = item.partnerSpecialityProofs
      .map((itemSpeciality) => {
        if (itemSpeciality.selected === true) {
          return itemSpeciality.code;
        }
      })
      .filter(Boolean);

    if (hasSelecteds.length) {
      return hasSelecteds;
    } else {
      return [];
    }
  };

  const hasErrorByTab = (code) => {
    const errorTab = inputsErrorsForm.find(
      (item) => item.tab.toLowerCase() === code.toLowerCase()
    );

    return errorTab;
  };

  const rangeIsSelected = ({ item }) => {
    const hasSelectedRange =
      item &&
      item?.range &&
      item?.range.find((itemRange) => itemRange.selected);

    if (hasSelectedRange) {
      return hasSelectedRange.code;
    } else {
      return null;
    }
  };

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const handleType = async (type) => {
    const responseCategories = await getIndustriesSelect({
      admin: contextPage === 'admin' ? true : false,
      type,
    });
    if (responseCategories.success) {
      const categories = responseCategories.data.map((category) => ({
        code: category.id,
        label: category.description,
      }));
      setIndustries(categories);
    }
  };

  const loadInit = async () => {
    setIsLoadPage(true);

    const responseCategories = await getIndustriesSelect({
      admin: contextPage === 'admin' ? true : false,
      type: 1,
    });
    if (responseCategories.success) {
      const categories = responseCategories.data.map((category) => ({
        code: category.id,
        label: category.description,
      }));
      setIndustries(categories);
    }

    const response = await getSpecialityProofAndEndersement({
      admin: contextPage === 'admin' ? true : false,
    });

    if (response.success) {
      setDataPage(response.data);

      const mountDataPageTemp = [];
      response.data.map((item, index) =>
        mountDataPageTemp.push({
          order: index + 1,
          code: item.code,
          range: rangeIsSelected({ item }),
          tags: specialityProofsIsSelected({ item }),
        })
      );
      setDataPageTemp(mountDataPageTemp);
      const casesTemp = [];

      response.data.map((itemData) =>
        itemData.cases.map((itemCase) =>
          casesTemp.push({ ...itemCase, codeBusiness: itemData.code })
        )
      );

      setCases(casesTemp);
    } else {
      message.error(I18n.get(MESSAGE_ERROR_GET_PAGE));
    }

    setIsLoadPage(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  const formatItemData = (itemData) => {
    const itemFormatted = {
      code: itemData.code,
      tags: itemData.tags,
      range: itemData.range,
    };
    return itemFormatted;
  };

  const formatItemCase = (itemCase) => {
    const itemFormatted = {
      companyName: itemCase.companyName,
      link: itemCase.link,
      countryCode: itemCase.countryCode,
      brand: itemCase.brand,
      type: itemCase.type,
      year: itemCase.year,
      industry: itemCase.industry,
      name: itemCase.name,
    };
    return itemFormatted;
  };

  const caseIsSameCode = ({ item, dataCode }) => {
    if (item.codeBusiness === dataCode) {
      return formatItemCase(item);
    }
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarFull();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);

    const formToSend = dataPageTemp.map((itemData) => ({
      adminAction: contextPage === 'admin' ? true : false,
      partnerId: contextPage === 'edit' || contextPage === 'admin' ? partnerId : null,
      ...formatItemData(itemData),
      cases: cases
        .map((itemCase) =>
          caseIsSameCode({ item: itemCase, dataCode: itemData.code })
        )
        .filter(Boolean),
    }));
    const response = await postSpecialityProofAndEndersement({
      admin: contextPage === 'admin' ? true : false,
      form: formToSend,
    });
    if (contextPage === 'full') {
      await newGetStepbar();
    }

    if (response.success) {
      if (contextPage === 'full') {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: urls[PAGE_INDEX + 1],
        });
      }
      if (contextPage === 'edit' || contextPage === 'admin') {
        sendOk(true);
      }
    } else {
      if (contextPage === 'full') {
        setOpenModalConfirm(true);
      }
      const errors = [];
      Object.keys(response?.errors).forEach(function (key, index) {
        errors.push({ ...response?.errors[key], tab: key });
      });
      setInputsErrorsForm(errors);
    }
    setIsSending(false);
  };

  const handleBackPage = async () => {
    const flow = await toGetFlowStorage({
      key: '@BPOOL-registration-flow-steps',
    });
    const isStartup = flow.steps[3].isStartup;
    const indexUrlIsStartup = PAGE_INDEX - 2;

    const url = urls[isStartup ? indexUrlIsStartup : PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const handleChangePartnerSpecialityProofs = ({ code, subs }) => {
    const finded = dataPageTemp.find((item) => item.code === code);

    if (finded) {
      const remove = dataPageTemp.filter((item) => item.code !== code);
      const updated = [...remove, { ...finded, tags: subs }];

      updated.sort((a, b) => a.order - b.order);
      setDataPageTemp(updated);
    }
  };

  const handleChangeRange = ({ code, range }) => {
    const finded = dataPageTemp.find((item) => item.code === code);

    if (finded) {
      const remove = dataPageTemp.filter((item) => item.code !== code);
      const updated = [...remove, { ...finded, range }];

      updated.sort((a, b) => a.order - b.order);
      setDataPageTemp(updated);
    }
  };

  useEffect(() => {
    if (dataPageTemp?.length) {
      const hasSpecialityProofs = dataPageTemp[tabIndex]?.tags?.map(
        (obj) => obj?.tags?.length
      );

      if (hasSpecialityProofs?.length > 0) {
        const tabItemName = `Speciality_${dataPageTemp[tabIndex]?.code}`;
        const arrErrorsUpdated = clearItemErrorTab({
          tab: tabItemName.toLowerCase(),
          arr: inputsErrorsForm,
        });
        setInputsErrorsForm(arrErrorsUpdated);
      }

      const hasRangeByTab = dataPageTemp[tabIndex]?.range;

      if (hasRangeByTab) {
        const tabItemName = `Range_${dataPageTemp[tabIndex]?.code}`;
        const arrErrorsUpdated = clearItemErrorTab({
          tab: tabItemName.toLowerCase(),
          arr: inputsErrorsForm,
        });
        setInputsErrorsForm(arrErrorsUpdated);
      }

      const hasCaseByTab = cases.filter(
        (itemCase) => itemCase.codeBusiness === dataPageTemp[tabIndex]?.code
      );
      if (hasCaseByTab?.length) {
        const tabItemName = `Case_${dataPageTemp[tabIndex]?.code}`;
        const arrErrorsUpdated = clearItemErrorTab({
          tab: tabItemName.toLowerCase(),
          arr: inputsErrorsForm,
        });
        setInputsErrorsForm(arrErrorsUpdated);
      }
    }
  }, [dataPageTemp, tabIndex, cases]);

  const partnerSpecialityProofsDefaults = (code) => {
    const finded = dataPageTemp.find((item) => item.code === code);

    if (finded) {
      const proofs = finded.tags;
      return proofs;
    } else {
      return [];
    }
  };

  const rangeDefaults = (code) => {
    const finded = dataPageTemp.find((item) => item.code === code);

    if (finded) {
      return finded.range ? [finded.range] : [];
    } else {
      return [];
    }
  };

  const handleSaveCase = ({ formCase, codeBusiness }) => {
    const formCaseWithCode = { ...formCase, codeBusiness };
    setCases([...cases, formCaseWithCode]);
    setChangeInfo(true);
    onChangeInfo(true);
  };

  const updateCase = async ({ formCase, codeBusiness }) => {
    let casesTemp = [...cases];
    const objIndex = casesTemp.findIndex((item) => item.id === formCase.id);
    casesTemp[objIndex] = { ...formCase, codeBusiness };
    setCases(casesTemp);
  };

  const handleUpdateCase = async ({ formCase, codeBusiness }) => {
    await updateCase({ formCase, codeBusiness });
    setFormEditingTemp(null);
    setChangeInfo(true);
    onChangeInfo(true);
  };

  const cancelEditCase = async () => {
    setFormEditingTemp(null);
  };

  const handleDeleteCase = (id) => {
    const finded = cases.find((item) => item.id === id);

    if (finded) {
      const removed = cases.filter((item) => item.id !== id);
      setCases(removed);
      setChangeInfo(true);
      onChangeInfo(true);
    }
  };

  const handleEditCase = (id) => {
    const finded = cases.find((item) => item.id === id);

    if (finded) {
      setFormEditingTemp(finded);
    }
  };

  const casesByCode = (code) => {
    const findedCases = cases.filter((item) => item.codeBusiness === code);

    return findedCases;
  };

  const caseFormComp = (item) => {
    return (
      <CaseForm
        onSave={(formCase) =>
          handleSaveCase({ formCase, codeBusiness: item?.code })
        }
        onEditing={formEditingTemp}
        onUpdate={(formCase) =>
          handleUpdateCase({ formCase, codeBusiness: item?.code })
        }
        onCancelEdit={cancelEditCase}
        industries={industries}
        partnerSpecialityProofs={partnerSpecialityProofsDefaults(item?.code)}
        requiredSpecialityProofs={item.partnerSpecialityProofs.length > 0}
        range={rangeDefaults(item?.code)}
        isMartecAdtech={dataPage?.range?.length === null ? true : false}
        handleType={handleType}
      />
    );
  };

  const renderTabs = () => {
    return dataPage?.map((item) => ({
      label: item?.label,
      component: (
        <div style={{ marginTop: 30 }}>
          {item.partnerSpecialityProofs.length > 0 && (
            <>
              <S.TitleSectionForm>
                <strong
                  style={{
                    color: hasErrorByTab(`Speciality_${item?.code}`)
                      ? '#ff0000'
                      : null,
                  }}
                >
                  {I18n.get("Let's be more specific in your speciality. Add as many tags as relevant")}
                </strong>
              </S.TitleSectionForm>
              <ButtonsSelects
                data={item?.partnerSpecialityProofs}
                defaultValues={partnerSpecialityProofsDefaults(item?.code)}
                onChange={(subs) =>
                  handleChangePartnerSpecialityProofs({
                    code: item?.code,
                    subs,
                  })
                }
              />
            </>
          )}
          {dataPage[0]?.range && dataPage[0]?.range?.length ? (
            <>
              <S.TitleSectionForm>
                {/* error */}
                <strong
                  style={{
                    color: hasErrorByTab(`Range_${item?.code}`)
                      ? '#ff0000'
                      : null,
                  }}
                >
                  {item?.description}
                </strong>
                {item?.resume ? (
                  <S.ContentTooltip>
                    <Tooltip textTooltip={item?.resume} placement="right" />
                  </S.ContentTooltip>
                ) : null}
              </S.TitleSectionForm>
              <ButtonsSelects
                data={item?.range}
                defaultValues={rangeDefaults(item?.code)}
                isSingle
                onChange={(range) =>
                  handleChangeRange({ code: item?.code, range })
                }
              />
            </>
          ) : null}

          <S.TitleSectionForm>
            <span>*</span>
            <strong>{I18n.get('Case references')}</strong>
          </S.TitleSectionForm>
          <S.SubTitleSectionForm>
            {I18n.get(
              'Please include at least one case for each specialty service selected. Mark COMPANY if the case has been executed by the company and LEADERSHIP if the case belongs to your personal portifolio'
            )}
          </S.SubTitleSectionForm>

          {hasErrorByTab(`Case_${item?.code}`) ? (
            <S.ErrorCase>
              {hasErrorByTab(`Case_${item?.code}`)?.errorMessage}
            </S.ErrorCase>
          ) : null}

          <br />

          {caseFormComp(item)}

          <ItemCases
            data={casesByCode(item?.code)}
            onDelete={(id) => handleDeleteCase(id)}
            onEdit={(id) => handleEditCase(id)}
          />
        </div>
      ),
      missingInfo: hasErrorByTab(`Case_${item?.code}`) ? true : false,
    }));
  };

  function arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  const compareSpecialityProofs = ({ specialitiesSaved, specialitiesTemp }) => {
    const getCodesSaveds = specialitiesSaved.map((item) =>
      item.map((itemSub) => itemSub.code)
    );
    const mergedSaveds = [];
    getCodesSaveds.map((item) =>
      item.map((itemSub) => mergedSaveds.push(itemSub))
    );

    const mergedTemp = [];
    specialitiesTemp.map((item) =>
      item.map((itemSub) => mergedTemp.push(itemSub))
    );

    const isEqual = arrayEquals(mergedSaveds, mergedTemp);
    setChangeInfo(!isEqual);
    onChangeInfo(true);
  };

  const compareRange = ({ rangeSaved, rangeTemp }) => {
    const getRangesSaveds = rangeSaved?.map(
      (item) => item && item.find((itemSub) => itemSub.selected === true)
    );
    const mergedSaveds = [];
    getRangesSaveds.map((item) => mergedSaveds.push(item?.code));

    const isEqual = arrayEquals(mergedSaveds, rangeTemp);
    setChangeInfo(!isEqual);
    onChangeInfo(true);
  };

  useEffect(() => {
    if (dataPage?.length) {
      compareSpecialityProofs({
        specialitiesSaved: dataPage.map(
          (dataPageItem) => dataPageItem?.partnerSpecialityProofs
        ),
        specialitiesTemp: dataPageTemp.map(
          (dataPageTempItem) => dataPageTempItem.tags
        ),
      });
      compareRange({
        rangeSaved: dataPage.map((dataPageItem) => dataPageItem?.range),
        rangeTemp: dataPageTemp.map(
          (dataPageTempItem) => dataPageTempItem.range
        ),
      });
    }
  }, [dataPage, dataPageTemp]);

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get('Speciality proof & Endersement')}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  'For each of the selected speciality add least 1 case.'
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>
        <Row>
          {isLoadPage ? (
            <Col xs={24} style={{ marginTop: 40 }}>
              <div>
                <Loading
                  sizeIcon={18}
                  text={I18n.get('Loading informations')}
                  sizeText={14}
                  align="center"
                />
              </div>
            </Col>
          ) : (
            <Col xs={24} style={{ marginTop: 40 }}>
              {dataPage.length ? (
                <TabsInternal
                  tabItens={renderTabs()}
                  tabActual={(tabIndex) => setTabIndex(tabIndex)}
                />
              ) : (
                <S.TextEmpty>
                  {I18n.get(
                    'You need to fill in some item in the previous step to fill in this screen.'
                  )}
                </S.TextEmpty>
              )}
            </Col>
          )}
        </Row>

        <Row style={{ marginTop: 30 }}>
          {contextPage === 'full' ? (
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get('Back')}
                theme="secondary"
                full
                onClick={handleBackPage}
              />
            </Col>
          ) : null}
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={
              contextPage === 'edit' || contextPage === 'admin'
                ? { span: 12, offset: 12 }
                : { span: 12, offset: 0 }
            }
          >
            <ButtonBpool
              text={
                contextPage === 'edit' || contextPage === 'admin'
                  ? I18n.get('Save')
                  : I18n.get('Save and Next')
              }
              theme="primary"
              full
              loading={isSending}
              onClick={send}
            />
          </Col>
        </Row>
      </Main>
      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get('Missing required information')}
        subBodyText={I18n.get(
          'Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?'
        )}
        okText={I18n.get('Yes')}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get('Close')}
        typeModal="isConfirm"
        isDanger
      />
    </>
  );
};
