export const COUNTER_INCREMENT = 'COUNTER_INCREMENT';
export const COUNTER_DECREMENT = 'COUNTER_DECREMENT';
export const COUNTER_RESET = 'COUNTER_RESET';

// PROJECTS
export const PROJECTS_GET = 'PROJECTS_GET';
export const PROJECT_GET = 'PROJECT_GET';
export const FILTERS_GET = 'FILTERS_GET';

// export const PROJECT_CRATE = "PROJECT_CREATE";

// CHAT NOTIFICATIONS
export const ADD_CHAT_NOTIFICATION = 'ADD_CHAT_NOTIFICATION';
export const UPDATE_CHAT_NOTIFICATION = 'UPDATE_CHAT_NOTIFICATION';

export const OPEN_CHAT_MODAL = 'OPEN_CHAT_MODAL';
export const CLOSE_CHAT_MODAL = 'CLOSE_CHAT_MODAL';

// QUICK MEETINGS
export const OPEN_QUICK_MEETING = 'OPEN_QUICK_MEETING';
export const CLOSE_QUICK_MEETING = 'CLOSE_QUICK_MEETING';

// PARTNERS (SELECT PARTNERS)
export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNER_BY_ID = 'GET_PARTNER_BY_ID';
export const UPDATE_PROJECT_STEP = 'UPDATE_PROJECT_STEP';

// STATUS BAR
export const GET_STATUS_BAR = 'GET_STATUS_BAR';
