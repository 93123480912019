import React from "react";
import { Link } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Breadcrumb as BreadcrumbAntd } from "antd";

import * as S from "./styles";

export const Breadcrumb = ({ title, breadcrumbs, buttons }) => {
  return (
    <S.Wrapper>
      <div className="container">
        <S.Content>
          <div>
            <S.Title>{title ? title : "Page"}</S.Title>

            <BreadcrumbAntd style={{ margin: "16px 0 0 0" }}>
              {breadcrumbs?.length ? (
                breadcrumbs.map((breadcrumb, index) => {
                  return (
                    <BreadcrumbAntd.Item key={index}>
                      <Link to={breadcrumb.route} className="breadcrumb-link">
                        {I18n.get(breadcrumb.name)}
                      </Link>
                    </BreadcrumbAntd.Item>
                  );
                })
              ) : (
                <div />
              )}
            </BreadcrumbAntd>
          </div>

          {buttons ? <S.ButtonsContent>{buttons}</S.ButtonsContent> : null}
        </S.Content>
      </div>
    </S.Wrapper>
  );
};
