import React from 'react';

import { I18n } from 'aws-amplify';

const Steps = (props) => {



    if (!props.bp || !props.bp.project) return null;

    const item = props.bp.project;

    function getClassName(step) {



        if (item.status === step) {
            return 'is-active';
        }
        else if (item.status >= step) {
            return 'is-completed bp-is-completed';
        }
        else {
            return 'bp-is-pending';
        }
    }

    const items = [
        {
            index: 1,
            title: I18n.get("Rascunho"),
            desc: 'Preenchendo o projeto.'
        },
        {
            index: 2,
            title: I18n.get("Criado"),
            desc: 'Aguardando confirmação BPool.'
        },
        {
            index: 3,
            title: I18n.get("Parceiros convidados"),
            desc: 'Aguardando aceite dos Parceiros.'
        },
        {
            index: 4,
            title: I18n.get("Parceiros disponíveis"),
            desc: 'Aguardando escolha pelo Cliente.'
        },
        {
            index: 5,
            title: I18n.get("Parceiro escolhido"),
            desc: 'Aguardando definições.'
        },
        {
            index: 6,
            title: I18n.get("Aguardando PO"),
            desc: 'Escopo aprovado, aguardando PO'
        },
        {
            index: 7,
            title: I18n.get("Começou!"),
            desc: 'Projeto em andamento'
        },
        {
            index: 20,
            title: I18n.get("Finalizado"),
            desc: ''
        },
        {
            index: 90,
            title: I18n.get("Cancelado"),
            desc: ''
        }
    ];


    return (
        <div className="steps">
            {items.map((step, index) =>
                (<div key={index} className={'step-item ' + getClassName(step.index)}>
                    <div className="step-marker"></div>
                    <div className="step-details">
                        <p className="step-title">{step.title}</p>
                        <p>{step.desc}</p>
                    </div>
                </div>)
            )}
        </div>
    )
};

export default Steps;
