import { I18n } from "aws-amplify";
import moment, { now } from "moment";
import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
import { PROJECT_API } from "../../actions/project/actions";
import constants from "../../constants";
import SubmitButtonField from "../form/submit-button-field";
import { TimePicker } from "antd";

import { useRegistrationFlowLeader } from "../../v2/hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook";

const { getTimezonesId } = useRegistrationFlowLeader();

const mapStateToProps = (state) => {
  return Object.assign({}, state.meetings, state);
};

const mapDispatchToProps = (dispatch) => {
  return {
    create_meeting: (data) => {
      return dispatch(PROJECT_API.create_meeting(data));
    },
    update_meeting: (data) => {
      return dispatch(PROJECT_API.update_meeting(data));
    },
  };
};

class PartnerSchedulingForm extends React.Component {
  constructor(props) {
    super();

    this.timepickerFormat = "HH:mm";
    this.timepickerNow = moment(
      moment().format(this.timepickerFormat),
      this.timepickerFormat
    );
    this.timepickerNext = moment(
      moment().add(1, "h").format(this.timepickerFormat),
      this.timepickerFormat
    );

    this.state = {
      startTime: this.timepickerNow,
      endTime: this.timepickerNext,
      selectedDay: null,
      errors: {},
      form: {
        recipient: [],
      },
      meetingId: null,
      partnerChecked: false,
      loadingButton: props.loadingButton,
      partners: props.partners || [],
      loadingButtonSend: false,
      timezoneSelected: null
    };
    this.handleDayClick = this.handleDayClick.bind(this);
  }

  

 componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (this.props.partners != prevProps.partners) {
      this.setState({ partners: this.props.partners });
    }
  }

  handleGettimezone = async () => {
    const offset = await getTimezonesId(this.props?.auth?.logged_user?.attributes['custom:timezone']).then((response) => { 
      this.setState({ timezoneSelected: response });
      return response
    });
    return offset || "--";
  }
  
 componentDidMount() {
    const offsetTeste = this.handleGettimezone().then((response) => response);
  }

  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  }

 

  handleCheckBoxElement = (event) => {
    var partners = this.state.partners;
    var pId = this.props.projectId;

    partners.forEach((partner) => {
      if (partner.partnerId === event.target.value) {
        let responsable = partner?.projectInvitations.find(
          (ff) => ff.projectId == pId
        )?.responsable;

        partner.isChecked = event.target.checked;
        partner.partnerName = responsable?.name;
        partner.partnerEmail = responsable?.email;
        partner.partnerCompany = partner.company.name;
      }
    });

    this.setState({ partners });
  };

  handleEdit = (item) => {
    let { meetingDate } = item;
    var data = moment(meetingDate, "DD/MM/YYYY h").format("YYYY-MM-DD");

    this.setState({
      selectedDay: new Date(data + "T00:00:00"),
      startTime: moment(item.startTime, this.timepickerFormat),
      endTime: moment(item.endTime, this.timepickerFormat),
      meetingId: item.meetingId,
    });
  };

  onChangeTimeStart(value) {
    if (value) {
      let currentStartTime = moment(
        this.state.startTime,
        this.timepickerFormat
      );
      let newStartTime = moment(value, this.timepickerFormat);
      let endTime = moment(this.state.endTime, this.timepickerFormat);
      endTime = newStartTime.add(endTime.diff(currentStartTime, "hours"), "h");
      this.setState({
        startTime: moment(
          value.format(this.timepickerFormat),
          this.timepickerFormat
        ),
        endTime: moment(
          endTime.format(this.timepickerFormat),
          this.timepickerFormat
        ),
      });
    }
  }
  onChangeTimeEnd(value) {
    if (value) {
      this.setState({
        endTime: moment(
          value.format(this.timepickerFormat),
          this.timepickerFormat
        ),
      });
    }
  }

  onTimeKeyDown(e) {
    if ((e.keyCode == 48 || e.keyCode == 96) && e.target.value == "0") {
      e.preventDefault();
    } else if (
      ((e.keyCode > 48 && e.keyCode <= 57) ||
        (e.keyCode > 96 && e.keyCode <= 105)) &&
      e.target.value == "0"
    ) {
      e.preventDefault();
    }
  }

  submitSelectedTime(event) {
    event.preventDefault();

    this.setState({ loadingButton: true });

    const data = {
      projectId: this.props.projectId,
      meetingDate: this.state.selectedDay,
      startTime: this.state.startTime.format(this.timepickerFormat),
      endTime: this.state.endTime.format(this.timepickerFormat),
      meetingId: this.state.meetingId,
    };

    if (this.state.meetingId) {
      this.props.update_meeting(data).then(() => {
        this.setState({
          meetingId: null,
          startTime: this.timepickerNow,
          endTime: this.timepickerNext,
          selectedDay: null,
          loadingButton: false,
        });
      });
    } else {
      this.props.create_meeting(data).then(() => {
        this.setState({
          meetingId: null,
          startTime: this.timepickerNow,
          endTime: this.timepickerNext,
          selectedDay: null,
          loadingButton: false,
        });
      });
    }
  }

  handleDelete(meeting) {
    this.props.setMeetings([meeting]);
    this.props.onDeleteConfirmation();
    this.setState({
      meetingId: null,
    });
  }

  submitForm() {
    this.props.onConfirmation();
    this.props.setMeetings(this.props.meetings);
    this.props.setPartnersSelected(
      this.state.partners.filter((partner) => partner.isChecked)
    );
  }

  render() {
    let meetingsLength =
      !this.props.meetings ||
      (this.props.meetings && this.props.meetings.length < 5);
    let meetingAvailable =
      this.props.meetings &&
      this.props.meetings.find(({ status }) => status == 0);

    let isDisabled = true;

    if (this.state.meetingId) {
      //EDIT
      isDisabled = false;
    } else {
      // CREATE

      isDisabled = !(
        this.state.startTime &&
        this.state.endTime &&
        this.state.selectedDay &&
        moment(this.state.endTime, "HH:mm").isAfter(
          moment(this.state.startTime, "HH:mm")
        )
      );
    }

    const isDisabledCheckbox = typeof meetingsLength === "undefined";

// TODO

    const isDisabledSend = !(
      this.props.meetings &&
      this.props.meetings.length > 0 &&
      this.props.meetings.some((meeting) => meeting.meetingStatus != 1) &&
      this.state.partners && this.state.partners.length > 0 &&
      this.state.partners.some((partner) => partner.isChecked)
    );

    
    
    return (
      <div className="scheduling-container">
        <div className="scheduling-pickers-form">
          <div className="pickers-container">
            <DayPicker
              selectedDays={this.state.selectedDay}
              onDayClick={this.handleDayClick.bind(this)}
              months={constants.DAYPICKER_MONTHS}
              weekdaysLong={constants.WEEKDAYS_mONLONG}
              weekdaysShort={constants.WEEKDAYS_SHORT}
              disabledDays={{
                before: new Date(),
              }}
            />
            <div className="container-group">
              <p className="text-picker">
                {I18n.get(
                  "Conciliar agendas nem sempre é fácil, né? Sugerimos então que você procure oferecer o maior"
                )}
                <b>{I18n.get("número de alternativas possível")}</b>{" "}
                {I18n.get("para que os parceiros escolham a melhor")}.
                {I18n.get("Lembre-se também de sugerir janelas")}{" "}
                <b>{I18n.get("de 45-60")}</b>{" "}
                {I18n.get("minutos para cada reunião")}.
              </p>

        

              <p className="alertTimezone">
                {I18n.get("Atenção. Sua agenda esta com o fuso horário de")}{" "}<b>{this.state.timezoneSelected?.text || "--"}</b>
              </p>

              <div className="timepicker-container">
                <TimePicker
                  defaultValue={this.timepickerNow}
                  format={this.timepickerFormat}
                  allowEmpty={false}
                  className="custom-timepicker"
                  onChange={this.onChangeTimeStart.bind(this)}
                  value={this.state.startTime}
                />
                <span style={{ margin: "0 10px 0 0" }}>{I18n.get("às")}</span>
                <TimePicker
                  defaultValue={this.timepickerNext}
                  format={this.timepickerFormat}
                  allowEmpty={false}
                  className="custom-timepicker"
                  onChange={this.onChangeTimeEnd.bind(this)}
                  value={this.state.endTime}
                />
              </div>
              <SubmitButtonField
                label={
                  this.state.meetingId
                    ? I18n.get("Atualizar opção")
                    : I18n.get("Adicionar opção")
                }
                onClick={this.submitSelectedTime.bind(this)}
                className="button blue no-webkit"
                disabled={isDisabled}
                task={{ busy: this.state.loadingButton }}
              />
            </div>
          </div>
        </div>
        <div className="scheduling-partners-form">
          <div className="available-dates">
            <p style={{ fontSize: "15px", fontWeight: "bold" }}>
              {I18n.get("Datas disponíveis para videocall")}
            </p>
            <ul>
              {this.props.meetings &&
                this.props.meetings.map((item) => {
                  return (
                    <li key={`meeting_${item.meetingId}`}>
                      <span className="tit-list">
                        {item.meetingDate} {item.startTime} - {item.endTime}
                      </span>
                      {item.meetingStatus === 0 ? (
                        <>
                          <a
                            className="no-webkit button bt-bpool bsmall"
                            key={`meeting_${item.meetingId}_edit`}
                            type="button"
                            onClick={() => this.handleEdit(item)}
                            style={{ border: "none", cursor: "pointer" }}
                          >
                            {I18n.get("Editar")}
                          </a>{" "}
                          <a
                            className="no-webkit button bt-bpool bsmall black"
                            key={`meeting_${item.meetingId}_delete`}
                            type="button"
                            onClick={() => this.handleDelete(item)}
                            style={{ border: "none", cursor: "pointer" }}
                          >
                            {I18n.get("Cancelar")}
                          </a>
                        </>
                      ) : (
                        <>
                          <span style={{ fontSize: "12px" }}>
                            <i>{I18n.get("(Horário agendado)")}</i>
                          </span>
                          <a
                            className="no-webkit button bt-bpool bsmall"
                            key={`meeting_${item.meetingId}_link`}
                            target="_blank"
                            href={`https://meeting.bpool.co/${item.meetingId}`}
                            type="button"
                            style={{ border: "none", cursor: "pointer" }}
                          >
                            {I18n.get("Link videocall")}
                          </a>
                        </>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="partners-container">
            <div>
              <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                {I18n.get("Enviar para os Parceiros")}
              </p>
              {this.state.partners &&
                this.state.partners.length > 0 &&
                this.state.partners.map((partner) => {
                  var labelStyle = partner.isChecked
                    ? { fontSize: "13px", color: "#1883FF" }
                    : { fontSize: "13px" };

                  const viewCard =
                    this.props.partnerIdsOptedInProp &&
                    this.props.partnerIdsOptedInProp.length > 0 &&
                    this.props.partnerIdsOptedInProp.find(
                      (a) => a == partner.id
                    );

                  if (!viewCard) return null;

                  const invitation = partner.projectInvitations.find(
                    (a) => a.projectId == this.props.projectId
                  );

                  return (
                    <div key={"container_partner_" + partner.partnerId}>
                      <input
                        key={"partner_" + partner.partnerId}
                        type="checkbox"
                        name="partners"
                        onChange={this.handleCheckBoxElement.bind(this)}
                        value={partner.partnerId}
                        checked={partner.isChecked}
                        disabled={
                          isDisabledCheckbox || invitation?.accepted === false
                        }
                        style={{
                          marginRight: "10px",
                          width: "15px",
                          height: "15px",
                          verticalAlign: "middle",
                        }}
                      />
                      <label
                        htmlFor={"partner_" + partner.partnerId}
                        style={labelStyle}
                      >
                        {partner.company.name}
                      </label>
                    </div>
                  );
                })}
            </div>
            <SubmitButtonField
              label={I18n.get("Enviar")}
              onClick={() => this.submitForm()}
              className="button blue no-webkit"
              disabled={isDisabledSend}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSchedulingForm);
