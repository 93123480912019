import styled from "styled-components";
import { Dropdown as DropdownAntd } from "antd";

export const Wrapper = styled.div`
  position: relative;
  z-index: 9;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 9px -6px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 6px 9px -6px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 6px 9px -6px rgba(0, 0, 0, 0.15);

  ul {
    display: inline-flex;

    li {
      margin-left: 6px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      :first-child {
        margin-left: 0;
      }

      a {
        padding: 16.8px 18px;
        color: #4a4a4a;
        font-size: 16px;
        i {
          margin-left: 4px;
        }
        :hover {
          background-color: #f2f2f2;
        }
      }
      @media screen and (max-width: 1275px) {
        margin-left: 2px;
        a {
          font-size: 14px;
          padding: 16.8px 14px;
        }
      }
      @media (min-width: 1276px) and (max-width: 1475px) {
        margin-left: 2px;
        a {
          font-size: 16px;
          padding: 16.8px 14px;
        }
      }
    }

    .active {
      background-color: #f2f2f2;
    }
  }
`;

export const Dropdown = styled(DropdownAntd)`
  ul {
    li {
      background-color: red;
    }
  }
`;
