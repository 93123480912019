export class FormUtils {

    constructor() {
    }

    static getData(state, props) {

        var data = { ...props };

        Object.keys(state.form).forEach((item) => {

            if (typeof state.form[item] === "object" && 'value' in state.form[item]) {
                data[item] = state.form[item].value
            }
            else {
                data[item] = state.form[item];
            }
        });

        data.task = state.task;

        return data;
    }

    static clearError(nextState, item) {

        if (item && typeof (nextState.form[item]) === "object") {

            nextState.form[item].error = null;
        }
    }

    static updateErrors(nextState, errors) {

        Object.keys(errors).forEach((item) => {

            if (typeof nextState.form[item] === "object") {
                nextState.form[item].error = errors[item];
            }

        });

        return nextState;
    }

    static resetForm(nextState) {

        Object.keys(nextState.form).forEach((item) => {

            if (typeof nextState.form[item] === "object") {
                nextState.form[item].value = '';
            }

        });
    }

    static isFormField(obj) {
        return (typeof obj === "object" && obj !== null && 'value' in obj);
    }

    static getNestedValue(scope, str) {
        let obj = scope, arr;

        try {
            arr = str.split(/[\[\]\.]/) // split by [,],.
                .filter(el => el)             // filter out empty one
                .map(el => el.replace(/^['"]+|['"]+$/g, '')); // remove string quotation
            arr.forEach(el => obj = obj[el])
        } catch (e) {
            obj = undefined;
        }

        return obj;
    }

    static paginate(items, page_size, page_number) {

        if (!items || items.length < page_size) {

            return {
                page_number: 1,
                hasNext: false,
                hasPrev: false,
                items: items,
                pages: [1],
                count: items.length
            };
        }


        const page = items.slice((page_number - 1) * page_size, page_number * page_size);

        const total = Math.ceil(items.length / page_size);

        const hasNext = (total > page_number) ? true : false;
        const hasPrev = (page_number > 1) ? true : false;

        var pages = [];

        for (var c = 1; c <= total; c++) {
            pages.push(c);
        }

        return {
            page_number: page_number,
            hasNext: hasNext,
            hasPrev: hasPrev,
            items: page,
            pages: pages,
            count: items.length
        };
    }
}