import styled from 'styled-components';

import { Row } from 'antd';

export const RowMain = styled(Row)`
  .ant-row {
    margin-bottom: 15px;
  }
`;

export const List = styled.ul``;
export const ListItem = styled.li`
  margin-bottom: 15px;
`;
export const NameItem = styled.p`
  font-weight: 600;
`;
export const ChildrensItem = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  color: #979797;
`;

export const ContentItemCase = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const TitleSectionForm = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
  width: max-content;
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const ItemInfo = styled.div``;

export const ItemLabel = styled.div`
  font-weight: bold;
`;

export const ItemValue = styled.div``;

export const RequirementsPassword = styled.ul`
  margin-top: 10px;
  li {
    color: #515151;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    list-style: initial;
    margin-left: 15px;

    :first-child {
      list-style: none;
      margin-left: 0;
    }
  }
`;

export const ConfirmPassError = styled.div`
  margin-top: 10px;
  color: #ff0000;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
`;

export const ErrorText = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #ff0000;
`;
