import { PROJECT_RESET } from './actions';

const api_reset_project = () => {
    return (dispatch, getState) => {

        dispatch({
            type: PROJECT_RESET,
            payload: {}
        })
    };
}

export default api_reset_project;