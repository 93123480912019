import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { I18n } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSupplier } from "../../../../hooks/useSupplier/useSupplier.hook";
import { NotificationBPool } from "../../../../components/UI/NotificationBPool";
import { Logged } from "../../components/Logged";
import { BoxContent } from "../../components/BoxContent";
import { TitlePage } from "../../components/TitlePage";
import { AlertBpayments } from "./AlertBpayments";
import { BPaymentsData } from "./BPaymentsData";
import { Filters } from "./Filters";
import { TableSupplier } from "./TableSupplier";
import { createQueryString, parseQueryString } from "./Filters/utils/resolvers";
import * as S from "./styles";

export const Initial = () => {
  let location = useLocation();
  const { getSupplier } = useSupplier();

  const state = useSelector((state) => state);

  const { data, isLoading, error, refetch } = useQuery(
    ["supplierList", createQueryString(parseQueryString(location?.search))],
    () => getSupplier(createQueryString(parseQueryString(location?.search))),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar a listagem"),
          duration: 3,
        });
      },
    }
  );

  return (
    <Logged>
      <div className="container">
        <BoxContent>
          <TitlePage
            title={I18n.get("Acompanhar faturamento")}
            subTitle={I18n.get(
              "Acompanhe por aqui o histórico do seu faturamento."
            )}
          />
          <AlertBpayments data={data?.setup} />
          <BPaymentsData isLoading={isLoading} data={data?.setup} />
          <Filters isLoading={isLoading} data={data?.setup} />
          <TableSupplier
            isLoading={isLoading}
            data={data?.data}
            refetch={refetch}
          />
        </BoxContent>
      </div>
    </Logged>
  );
};
