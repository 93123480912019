import React from "react";
import { I18n } from "aws-amplify";
import { Icon } from "antd";

import * as S from "./styles";

export const AttachmentsItem = ({ attachment }) => {
  return (
    <S.Wrapper>
      <S.Content>
        <S.Name>
          <span>{attachment?.name || I18n.get("Arquivo")}</span>
        </S.Name>
        <S.View>
          <a href={attachment.url} target="_blank" rel="noopener noreferrer">
            <Icon type="eye" />
          </a>
        </S.View>
      </S.Content>
    </S.Wrapper>
  );
};
