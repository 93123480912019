import React from 'react';

import { I18n } from 'aws-amplify';

import { NavLink } from "react-router-dom";

const LinkButton = (props) => {

    if (props.disabled) {
        return (<a className="button bt-bpool black" disabled>{props.label}</a>)
    }

    return (
        <NavLink to={props.to} className="button bt-bpool black">{props.label}</NavLink>
    )
};

export default LinkButton;