import React from 'react';

import { I18n } from 'aws-amplify';

class AwardItem extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }
 
    details(e) {

    }

    render() {

        return (<div className="card" style={{height: "200px"}}>
            <div className="card-content">

                <h1 className="title-bpool medium" style={{fontSize: "15px"}}><strong>{this.props.name}</strong></h1>

                <p>
                    <small>{this.props.product} </small> <br />
                    {this.props.brand}<br />
                    {this.props.year} <br />
                </p>
            </div>
        </div>);
    }
}

export default AwardItem;