import React from 'react';

import { I18n } from 'aws-amplify';

import TooltipField from './tooltip-field';

import MarkAsRequiredOrOptional from './mark-as-required-or-optional';

export default class CheckboxField extends React.Component {
    constructor(props) {
        super();

        this.state = {
            focused: false,
            visited: props.visited
        };
    }

    onBlur() {
        this.setState({ focused: false });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onClick() {
        this.setState({ focused: false });

        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    onFocus() {
        this.setState({ focused: true, visited: true });
    }

    render() {

        const { checkmarkStyle } = this.props;

        const isError = !this.state.focused && this.state.visited && this.props.error;

        const field = this.props.field;

        const Checkbox = (<label className="checkbox">
            <input
                type="checkbox"
                value={this.props.value}
                checked={this.props.checked || false}
                onChange={this.props.onChange}
                className={isError ? "is-danger" : ""}
                //onBlur={() => this.onBlur()}
                onClick={() => this.onClick()}
                onFocus={() => this.onFocus()}
                disabled={this.props.disabled}
            />
            <span className="checkmark" style={checkmarkStyle}></span>
            <p className="break-word">
                {this.props.label} <MarkAsRequiredOrOptional {...this.props} />
                <TooltipField tooltip={this.props.tooltip} />
            </p>
        </label>);

        return (<div className={`field ${this.props.disabled ? "field-disabled" : ""}`} style={this.props.fieldStyle}>
            <div className="control">

                {!field && Checkbox}

                {field && <div style={{ clear: 'both' }}>
                    <div style={{ float: 'left', clear: 'none', paddingTop: '0.25rem' }}>
                        {Checkbox}
                    </div>

                    <div style={{ float: 'left', clear: 'none', paddingLeft: '1rem' }}>
                        {field}
                    </div>
                </div>}

            </div>
            {isError && <p className="help is-danger">
                {this.props.error.errorMessage}
            </p>}

        </div>)
    }
}
