import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../../config";

import { PROJECT_GET } from "..";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const api_get_projects_filters = (data) => {
  return Auth.currentSession()
    .then((session) => {
      const token = session && session.idToken.jwtToken;

      var config = { headers: { Authorization: "Bearer " + token } };
      return axios
        .get(`${Config.API_BASE_URL}/v2/client-project/Filter`, config)
        .then((response) => {
          const {
            data: { data },
          } = response;
          return {
            type: PROJECT_GET,
            payload: {
              filters: { ...data },
              errors: {},
              task: {
                result: true,
              },
            },
          };
        })
        .catch((error) => {
          return {
            type: PROJECT_GET,
            payload: {
              errors: {},
              task: {
                result: false,
              },
            },
          };
        });
    })
    .catch((error) => {});
};
