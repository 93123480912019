import React from 'react';

import { I18n } from 'aws-amplify';

export default class Details extends React.Component {

    constructor(props) {
        super();
    }

    getSector(sectorId) {

        const items = this.props.sf.filter((item) => item.code == sectorId);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }

    render() {

    const item = this.props.item;

    if (!item) return null;

    return (
        <section>

            <div className="card">
                <div className="card-content">

                    <div className="columns">

                        <div className="column is-4">

                            <div className="field">
                                <label className="label">{I18n.get("Marca")}</label>
                                <div className="control">
                                    {item.brand}
                              </div>
                            </div>

                            <div className="field">
                                <label className="label">{I18n.get("Desafio")}</label>
                                <div className="control">
                                    {item.challenge}
                                </div>
                            </div>

                        </div>

                        <div className="column is-4">

                            <div className="field">
                                <label className="label">{I18n.get("Produto")}</label>
                                <div className="control">
                                    {item.product}
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">{I18n.get("Solução")}</label>
                                <div className="control">
                                    {item.solution}
                                </div>
                            </div>

                        </div>

                        <div className="column is-4">

                            <div className="field">
                                <label className="label">{I18n.get("Categoria")}</label>
                                <div className="control">
                                    {this.getSector(item.category).label}
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">{I18n.get("Resultado")}</label>
                                <div className="control">
                                    {item.result}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="columns">

                        <div className="column is-12">

                            <h1 className="subtitle is-5">{I18n.get("Videos")}</h1>

                            {item.videos && item.videos.map((item, index) =>
                            ([
                                <a key={index} href={item} target="_blank">{item}</a>,
                                <br/>
                            ])
                            )}

                        </div>

                    </div>

                    <div className="columns">

                        <div className="column is-12">

                            <h1 className="subtitle is-5">{I18n.get("Fotos")}</h1>

                            {item.photos && item.photos.map((item, index) =>
                            ([
                                <a key={index} href={item} target="_blank"><img className="image is-64x64" src={item} /></a>
                            ])
                            )}

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );

    }
}