import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_ASSETS_CLIENTS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_client_bundle = (data) => {

    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.put(`${Config.API_BASE_URL}/bp/client/bundle-update/${data.clientId}`, data, config)
            .then(response => {

                dispatch({
                    type: BP_ASSETS_CLIENTS_GET,
                    payload: {
                        clientBundle: response.data,
                        task: { result: true }
                    }
                })
            })
            .catch(error => {

                dispatch({
                    type: BP_ASSETS_CLIENTS_GET,
                    payload: {
                      errors: error.response.data,
                      task: { result: false }
                    }
                })

            });
}).catch(error => { 
//  dispatch({ type: EXPIRED }); 
 }); 
 };
}


export default api_update_client_bundle;