import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fafafa;
  padding: 5px 15px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.div`
  span {
    font-size: 13px;
    font-weight: 400;
  }
`;

export const View = styled.div`
  margin-right: 10px;
  a {
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #1883ff;
    }
  }
`;
