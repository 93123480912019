import styled from 'styled-components';

export const Aside = styled.aside`
  ul {
    li {
      .active {
        span {
          font-weight: bold !important;
          color: #2883ed;
        }
      }
    }
  }
`;
