import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, message } from "antd";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import { useReview } from "../../../hooks/useReview/useReview.hook";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import Content from "../../../components/UI/Content";
import { H2Border } from "../../../components/UI/Titles";
import Main from "../../../components/UI/Main";
import { AssetBundle } from "../../../components/UI/AssetBundle";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import Loading from "../../../components/UI/Loading";
import { ConvertValuesCreativeScope } from "../../../../v2/pages/ProjectsList/Invitations/ProjectDetails/utils/convertValuesCreativeScope";
import IcoForecast from "./svgs/ico-step5-forecast.svg";
import IcoCheck from "./svgs/ico-step5-check.svg";
import IcoPayment from "./svgs/ico-step5-payment.svg";
import IcoTime from "./svgs/ico-step5-time.svg";

import * as S from "./styles";

export const FinalizePartner = () => {
  let params = useParams();
  const auth = useSelector((state) => state.auth);
  const { getProjectById } = useProjects();
  const { getReviewDefinition } = useReview();
  const [listOfAssets, setListOfAssets] = useState([]);
  const [dataValuesCreativeScope, setDataValuesCreativeScope] = useState({});
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorInGet, setErrorInGet] = useState(false);
  const [projectProcurement, setProjectProcurement] = useState(null);
  const [statusProject, setStatusProject] = useState(null);
  const [currencyProject, setCurrencyProject] = useState({});
  const [globalAuthorization, setGlobalAuthorization] = useState(null);

  const handleConvertedValuesCreativeScope = async ({ values, currency }) => {
    const data = {
      ...values,
      currency,
    };
    const objDataValuesCreativeScope = ConvertValuesCreativeScope({
      data,
      isInvite: false,
    });
    setDataValuesCreativeScope(objDataValuesCreativeScope);

    const listAssetsUpdated = listOfAssets.map((item1) => {
      const matchingItem = values?.bundle?.variationResponse?.assets?.find(
        (item2) => item2.code === item1.code
      );

      if (matchingItem) {
        return {
          ...item1,
          priceFmt: matchingItem.priceFmt,
          priceTotalFmt: matchingItem.priceTotalFmt,
        };
      }

      return item1;
    });
    setListOfAssets(listAssetsUpdated);
  };

  const loadReview = async () => {
    setIsLoading(true);
    try {
      if (params?.projectId) {
        const response = await getReviewDefinition({ id: params?.projectId });
        if (response?.success) {
          setProject(response?.data);
          setListOfAssets(response?.data?.bundle?.variations[0]?.assets);
          const objDataValuesCreativeScope = ConvertValuesCreativeScope({
            data: response?.data,
            isInvite: false,
          });
          setDataValuesCreativeScope(objDataValuesCreativeScope);
        }

        const responseProject = await getProjectById({
          projectId: params?.projectId,
        });
        setStatusProject({
          status: responseProject?.data?.status,
          partnerHasSubmittedDefinitions:
            responseProject?.data?.partnerHasSubmittedDefinitions,
        });
        setProjectProcurement({
          isRequiredAprovation: responseProject?.data.isRequiredAprovation,
          isReEvaluate: responseProject?.data.isReEvaluate,
          isApproved: responseProject?.data.isApproved,
        });
        setCurrencyProject(responseProject?.data?.currency);
        setGlobalAuthorization(responseProject?.data?.globalAuthorization);
      }
    } catch (error) {
      setErrorInGet(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadReview();
  }, []);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Resumo")}</H2Border>
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                {/* <Row>
                  <Col xs={24} sm={12} offset={6}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get('Resumo')}</S.H4>
                      </S.TitleWithIcon>
                    </S.RowCenter>
                  </Col>
                </Row> */}

                {isLoading ? (
                  <div style={{ width: "100%" }}>
                    <Loading
                      text={I18n.get("Carregando...")}
                      sizeText={14}
                      sizeIcon={16}
                      align="center"
                    />
                  </div>
                ) : (
                  <Row>
                    <Col sm={24}>
                      <S.ContentReview>
                        <Row>
                          <Col xs={12} sm={12}>
                            <S.WrapperReview>
                              <S.TitleReview>
                                {I18n.get("Informações")}
                              </S.TitleReview>
                              <S.BoxReview>
                                <ul>
                                  <li>
                                    <strong>{I18n.get("Tipo:")}</strong>
                                    <span>
                                      {project?.projectTypeDescription}
                                    </span>
                                  </li>
                                  <li>
                                    <strong>
                                      {I18n.get("Nome do Projeto:")}
                                    </strong>
                                    <span>{project?.projectName}</span>
                                  </li>
                                  <li>
                                    <strong>
                                      {I18n.get("Número do Projeto:")}
                                    </strong>
                                    <span>{project?.projectSKU}</span>
                                  </li>
                                  <li>
                                    <strong>{I18n.get("Produto:")}</strong>
                                    <span>
                                      {project?.brandName} -{" "}
                                      {project?.productName}
                                    </span>
                                  </li>
                                </ul>
                              </S.BoxReview>
                            </S.WrapperReview>

                            <S.WrapperReview>
                              <S.TitleReview>
                                {I18n.get("Escopo Criativo")}
                              </S.TitleReview>
                              <S.BoxReview>
                                <AssetBundle
                                  isInvite={false}
                                  isClient={auth?.is_client_or_client_user}
                                  listOfAssets={
                                    listOfAssets?.length ? listOfAssets : []
                                  }
                                  dataValues={dataValuesCreativeScope}
                                  convertedValues={
                                    handleConvertedValuesCreativeScope
                                  }
                                  reload={loadReview}
                                  isModalConfirm={true}
                                  descriptiveHourMan={
                                    project?.descriptiveHourMan
                                  }
                                  disabledEdit
                                  isCart
                                  currencyProject={currencyProject}
                                />
                              </S.BoxReview>
                            </S.WrapperReview>
                          </Col>
                          <Col xs={12} sm={12}>
                            <S.WrapperReview>
                              <S.TitleReview>
                                {I18n.get("Detalhes")}
                              </S.TitleReview>
                              <S.BoxReviewDetails spacing="extra-large">
                                <ul>
                                  <li>
                                    <div style={{ width: 26 }}>
                                      <img
                                        src={IcoForecast}
                                        alt="Ico Forecast"
                                      />
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: 25,
                                        width: "calc(100% - 26px)",
                                      }}
                                    >
                                      <strong>
                                        {I18n.get("Previsão do Projeto")}:
                                      </strong>
                                      <br />

                                      <p>
                                        <b>{I18n.get("Início")}:</b>{" "}
                                        {project?.estimatedStartDate}
                                      </p>
                                      <p>
                                        <b>{I18n.get("Término")}:</b>{" "}
                                        {project?.estimatedDurationInMonths
                                          ? project?.estimatedDurationInMonths
                                          : "0"}{" "}
                                        {I18n.get("mes(es) e")}{" "}
                                        {project?.estimatedDurationInWeeks
                                          ? project?.estimatedDurationInWeeks
                                          : "0"}{" "}
                                        {I18n.get("semana(s)")}
                                      </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div style={{ width: 26 }}>
                                      <img src={IcoCheck} alt="Ico Check" />
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: 25,
                                        width: "calc(100% - 26px)",
                                      }}
                                    >
                                      <strong>
                                        {I18n.get(
                                          "Prazo de seleção do parceiro"
                                        )}
                                        :
                                      </strong>
                                      <span>
                                        {project?.estimatedDecisionInWeeks}{" "}
                                        {I18n.get("semana(s)")}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div style={{ width: 26 }}>
                                      <img src={IcoPayment} alt="Ico Payment" />
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: 25,
                                        width: "calc(100% - 26px)",
                                      }}
                                    >
                                      <strong>
                                        {I18n.get("Prazo pagamento do cliente")}
                                        :
                                      </strong>
                                      <span>
                                        {I18n.get(
                                          "90 dias corridos a partir da data de faturamento."
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div style={{ width: 26 }}>
                                      <img src={IcoTime} alt="Ico Time" />
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: 25,
                                        width: "calc(100% - 26px)",
                                      }}
                                    >
                                      <strong>
                                        {I18n.get("Termo de Exclusividade")}:
                                      </strong>
                                      <span>
                                        {project?.nonCompeteInDaysFmt}
                                      </span>
                                    </div>
                                  </li>
                                  {globalAuthorization ? (
                                    <li>
                                      <div style={{ width: 26 }}>
                                        <img src={IcoCheck} alt="Ico Check" />
                                      </div>
                                      <div
                                        style={{
                                          marginLeft: 25,
                                          width: "calc(100% - 26px)",
                                        }}
                                      >
                                        <strong>
                                          {I18n.get("Aprovação Global")}:
                                        </strong>
                                        <span>
                                          <a
                                            target="_blank"
                                            style={{
                                              marginTop: "10px",
                                              display: "block",
                                              fontSize: 14,
                                              color: "#000",
                                              textDecoration: "underline",
                                            }}
                                            href={globalAuthorization?.url}
                                          >
                                            {globalAuthorization?.name}
                                          </a>
                                        </span>
                                      </div>
                                    </li>
                                  ) : null}
                                </ul>
                              </S.BoxReviewDetails>
                            </S.WrapperReview>
                          </Col>
                          <Col xs={12} sm={12}>
                            <div style={{ marginTop: 30 }}>
                              <a href={project?.pdfProject} target="_blank">
                                <ButtonBpool
                                  text={I18n.get("Baixar PDF")}
                                  className=""
                                  theme="primary"
                                  disabled={project?.pdfProject ? false : true}
                                />
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </S.ContentReview>
                    </Col>
                  </Row>
                )}
                <S.RowFooter>
                  <S.ButtonsFooter>
                    <Link
                      to={`/${
                        auth?.is_partner ? "parceiro" : "cliente"
                      }/projetos/v2`}
                    >
                      <ButtonBpool
                        text={I18n.get("Voltar para Projetos")}
                        theme="secondary"
                        className="btnPrev"
                        onClick={() => true}
                      />
                    </Link>
                  </S.ButtonsFooter>
                </S.RowFooter>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
