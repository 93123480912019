import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col, message, Select } from "antd";
import { Loggedv2 } from "../../components/Loggedv2";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Checkbox from "../../../../components/UI/Checkbox";
import Loading from "../../../../components/UI/Loading";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { useRegistrationFlow } from "../../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { UploadCertified } from "./uploadCertified";
import { useUpload } from "../../../../hooks/useUpload/useUpload.hook";

import { urlsFull as urls } from "../../utils/stepsUrls";
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from "../../utils/flowToStorage";
import { statusIdToName } from "../../components/Loggedv2/statusIdToName";
import { inputsState } from "./inputsState";

import * as S from "./styles";

const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const MESSAGE_ERROR = "There was an error, try again.";
const PAGE_INDEX = 9;

const { Option } = Select;

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  const {
    getDiversityAndInclusionSetup,
    getDiversityAndInclusion,
    putDiversityAndInclusion,
  } = useRegistrationFlow();
  const { getStatusBarFull } = useRegistrationFlowFull();
  let history = useHistory();
  const { uploadFile } = useUpload();
  const [isLoading, setIsLoading] = useState(false);
  const [dataPage, setDataPage] = useState([]);
  const [inputsFormInitals, setInputsFormInitals] = useState(inputsState);
  const [inputsForm, setInputsForm] = useState(inputsState);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileDefaultGet, setFileDefaultGet] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [dropdownEtniasOwned, setDropdownEtniasOwned] = useState(null);
  const [dropdownEtniasLed, setDropdownEtniasLed] = useState(null);
  const [dropdownEtniasOwnedDefault, setDropdownEtniasOwnedDefault] = useState(
    []
  );
  const [dropdownEtniasLedDefault, setDropdownEtniasLedDefault] = useState([]);

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const onChangeCheckboxes = (checked, id, type) => {
    if (type === "owned") {
      const find = inputsForm.diversityOwned.find((item) => item === id);
      if (find) {
        const updateArr = inputsForm.diversityOwned.filter(
          (item) => item !== id
        );
        setInputsForm({
          ...inputsForm,
          diversityOwned: updateArr,
          diversityPreferNotDeclare: false,
        });
      } else {
        setInputsForm({
          ...inputsForm,
          diversityOwned: [...inputsForm.diversityOwned, id],
          diversityPreferNotDeclare: false,
        });
      }
    } else if (type === "led") {
      const find = inputsForm.diversityLeadership.find((item) => item === id);
      if (find) {
        const updateArr = inputsForm.diversityLeadership.filter(
          (item) => item !== id
        );
        setInputsForm({
          ...inputsForm,
          diversityLeadership: updateArr,
          diversityPreferNotDeclare: false,
        });
      } else {
        setInputsForm({
          ...inputsForm,
          diversityLeadership: [...inputsForm.diversityLeadership, id],
          diversityPreferNotDeclare: false,
        });
      }
    } else if (!type) {
      if (id === "diversityPreferNotDeclare") {
        setInputsForm({
          ...inputsForm,
          [id]: checked,
          diversityOwned: [],
          diversityLeadership: [],
        });
      } else {
        setInputsForm({
          ...inputsForm,
          [id]: checked,
        });
      }
    }
  };

  const isSelectedCheckbox = (id, type) => {
    if (type === "owned") {
      const find = inputsForm.diversityOwned.find((item) => item === id);
      return find ? true : false;
    } else if (type === "led") {
      const find = inputsForm.diversityLeadership.find((item) => item === id);
      return find ? true : false;
    }
  };

  const prepareDescriptions = (description) => {
    const desc = description.split("LED:");
    const descOwned = desc[0].split("OWNED:");

    return { descriptionOwned: descOwned[1], descriptionLed: desc[1] };
  };

  const formatInitDiversitiesArr = ({ arr, type }) => {
    const separate = arr.map((item) => item.split("_"));
    const arrFiltered = separate.map((item) =>
      item.filter((itemFilter) => (item?.length === 2 ? null : itemFilter[1]))
    );
    const arrFlat = arrFiltered.flat();

    const pushed = [];
    separate.map((item) =>
      item.filter((itemFilter) =>
        item?.length === 2
          ? pushed.push({ code: item[0], value: itemFilter })
          : null
      )
    );
    const cleanArr = pushed.filter((item) =>
      item.code === item.value ? null : item
    );

    let arrFinal = [];
    cleanArr.map((item) => {
      const finded = arrFinal.find((itemFind) => itemFind.code === item.code);

      if (finded) {
        const filteredItems = arrFinal.filter(
          (itemFilter) => itemFilter.code !== item.code
        );
        filteredItems.push({
          code: item.code,
          value: [...finded.value, `${item.code}_${item.value}`],
        });
        arrFinal = filteredItems;
      } else {
        arrFinal.push({
          code: item.code,
          value: [`${item.code}_${item.value}`],
        });
      }
    });

    const obj = Object.fromEntries(
      arrFinal.map((item) => [item.code, item.value])
    );

    if (type === "owned") {
      setDropdownEtniasOwned(obj);
    } else if (type === "led") {
      setDropdownEtniasLed(obj);
    }

    return arrFlat;
  };

  const loadInit = async () => {
    setIsLoading(true);

    const responseSetup = await getDiversityAndInclusionSetup({
      admin: contextPage === "admin" ? true : false,
    });
    const response = await getDiversityAndInclusion({
      admin: contextPage === "admin" ? true : false,
    });

    const childrensArr = [];

    if (responseSetup.success) {
      const dataFormatted = responseSetup.data.map((item) => ({
        code: item.code,
        enabled: item.enabled,
        label: item.label,
        selected: item.selected,
        ...prepareDescriptions(item.description),
        children: item.children
          ? item.children.map((childItem) => ({
              label: childItem.label,
              code: childItem.code,
            }))
          : null,
      }));
      setDataPage(dataFormatted);

      responseSetup.data.map((item) => ({
        children: item.children
          ? item.children.map((childItem) =>
              childrensArr.push({
                label: childItem.label,
                code: childItem.code,
              })
            )
          : null,
      }));
    }
    if (response.success) {
      const data = {
        ...response.data.company,
      };

      const diversityOwnedArr = formatInitDiversitiesArr({
        arr: data?.diversityOwned,
        type: "owned",
      });
      const diversityLeadershipArr = formatInitDiversitiesArr({
        arr: data?.diversityLeadership,
        type: "led",
      });
      setInputsFormInitals({
        ...data,
        diversityOwned: diversityOwnedArr,
        diversityLeadership: diversityLeadershipArr,
      });
      setInputsForm({
        ...data,
        diversityOwned: diversityOwnedArr,
        diversityLeadership: diversityLeadershipArr,
      });

      const defaultsEtniasOwnedFiltered = childrensArr.map((item) =>
        data?.diversityOwned.filter((itemData) => item.code === itemData)
      );

      const defaultsEtniasLedFiltered = childrensArr.map((item) =>
        data?.diversityLeadership.filter((itemData) => item.code === itemData)
      );

      const defaultsEtniasOwned = defaultsEtniasOwnedFiltered.flat();
      const defaultsEtniasLed = defaultsEtniasLedFiltered.flat();

      setDropdownEtniasOwnedDefault(defaultsEtniasOwned);
      setDropdownEtniasLedDefault(defaultsEtniasLed);

      if (data?.diversityFile) {
        setFileDefaultGet({
          url: data.diversityFile.url,
          name: data.diversityFile.name,
          size: data.diversityFile.size,
        });
      }
    }

    setIsLoading(false);
  };

  const handleChangeEtnia = ({ type, value, code }) => {
    if (type === "owned") {
      if (dropdownEtniasOwned) {
        setDropdownEtniasOwned({ ...dropdownEtniasOwned, [code]: value });
      } else {
        setDropdownEtniasOwned({ [code]: value });
      }
    }

    if (type === "led") {
      if (dropdownEtniasLed) {
        setDropdownEtniasLed({ ...dropdownEtniasLed, [code]: value });
      } else {
        setDropdownEtniasLed({ [code]: value });
      }
    }
  };

  const renderSelectDefault = ({ arr, code, type }) => {
    const separate = arr.map((item) => item.split(`${code}_`));
    const arrFiltered = separate.map((item) =>
      item.filter((itemFilter) => (item?.length === 2 ? itemFilter[1] : null))
    );
    const arrFlat = arrFiltered.flat();
    const arrFormat = arrFlat.map((item) => `${code}_${item}`);

    return arrFormat;
  };

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const hasError = (fieldInput) => {
    if (inputsErrorsForm.length) {
      const err = inputsErrorsForm.find(
        (item) => item.field.toLowerCase() === fieldInput.toLowerCase()
      );

      if (err) {
        return err.errorMessage;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const etniasFormatToSend = (obj) => {
    const selecteds = [];
    Object.keys(obj).forEach(function (key, index) {
      obj[key].map((item) => selecteds.push(item));
    });

    return selecteds;
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarFull();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);

    let responseCertifiedUpload = null;
    if (fileUpload) {
      responseCertifiedUpload = await uploadFile({
        file: fileUpload,
        idUser: "usertestingnow",
        fieldId: `certified`,
      });
    }

    const objToSend = {
      adminAction: contextPage === "admin" ? true : false,
      id: contextPage === "admin" ? partnerId : null,
      ...inputsForm,
      diversityOwned: dropdownEtniasOwned
        ? [
            ...inputsForm?.diversityOwned,
            ...etniasFormatToSend(dropdownEtniasOwned),
          ]
        : [...inputsForm?.diversityOwned],
      diversityLeadership: dropdownEtniasLed
        ? [
            ...inputsForm?.diversityLeadership,
            ...etniasFormatToSend(dropdownEtniasLed),
          ]
        : [...inputsForm?.diversityLeadership],
      diversityFile: responseCertifiedUpload
        ? {
            url: responseCertifiedUpload.url,
            size: fileUpload.size,
            name: fileUpload.name,
          }
        : fileDefaultGet
        ? fileDefaultGet
        : null,
    };

    const response = await putDiversityAndInclusion({
      admin: contextPage === "admin" ? true : false,
      form: objToSend,
    });
    if (contextPage === "full") {
      await newGetStepbar();
    }

    if (response.success) {
      if (contextPage === "full") {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: urls[PAGE_INDEX + 1],
        });
      }
      if (contextPage === "edit" || contextPage === "admin") {
        sendOk(true);
      }
    } else {
      if (contextPage === "full") {
        setOpenModalConfirm(true);
      }
      const errors = [];
      Object.keys(response?.errors).forEach(function (key, index) {
        errors.push(response?.errors[key]);
      });
      setInputsErrorsForm(errors);
    }

    setIsSending(false);
  };

  //validation
  useEffect(() => {
    const lengthDiversityOwned = inputsForm.diversityOwned.length;
    const lengthInitialDiversityOwned = inputsFormInitals.diversityOwned.length;

    const lengthDiversityLeadership = inputsForm.diversityLeadership.length;
    const lengthInitialDiversityLeadership =
      inputsFormInitals.diversityLeadership.length;

    if (
      inputsForm.selfDeclaration !== inputsFormInitals.selfDeclaration ||
      inputsForm.diversityPreferNotDeclare !==
        inputsFormInitals.diversityPreferNotDeclare ||
      lengthDiversityOwned !== lengthInitialDiversityOwned ||
      lengthDiversityLeadership !== lengthInitialDiversityLeadership
    ) {
      setChangeInfo(true);
      onChangeInfo(true);
    } else {
      setChangeInfo(false);
      onChangeInfo(false);
    }
  }, [inputsForm]);

  const fileToUpload = ({ file }) => {
    setFileUpload(file);
  };

  const removeFileDefault = () => {
    setFileUpload(null);
    setFileDefaultGet(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("D&I Qualifications")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  "Please select qualifications below if  your company is actively working toward gender equality in the workplace."
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        <Row>
          <Col xs={24} style={{ marginTop: 40 }}>
            <div>
              {isLoading ? (
                <S.RowLoading>
                  <Loading
                    sizeIcon={18}
                    text={I18n.get("Loading informations")}
                    sizeText={14}
                    align="flex-start"
                  />
                </S.RowLoading>
              ) : (
                dataPage.map((item) => (
                  <S.WrapperItemQualifications key={item.code}>
                    <S.LabelQualifications>
                      <span>{item.label}</span>
                    </S.LabelQualifications>
                    <S.DescriptionsQualifications>
                      <S.ColQualifications>
                        <div>
                          <p>{I18n.get("OWNED:")}</p>
                          <S.ItemDescription style={{ padding: 0 }}>
                            {item.descriptionOwned}
                          </S.ItemDescription>
                          <S.RowCheck>
                            <S.CheckBoxContent>
                              <Checkbox
                                id={item.code}
                                onChangeCb={(e, id) =>
                                  onChangeCheckboxes(
                                    e.target.checked,
                                    id,
                                    "owned"
                                  )
                                }
                                checked={isSelectedCheckbox(item.code, "owned")}
                                checkboxSize={18}
                              />
                              <S.LabelCheckBox>
                                {I18n.get("Owned")}
                              </S.LabelCheckBox>
                            </S.CheckBoxContent>
                            {item.children ? (
                              <Select
                                mode="multiple"
                                style={{
                                  width: "100%",
                                }}
                                placeholder={I18n.get("Select etinia")}
                                defaultValue={
                                  dropdownEtniasOwnedDefault.length
                                    ? renderSelectDefault({
                                        arr: dropdownEtniasOwnedDefault,
                                        code: item?.code,
                                        type: "owned",
                                      })
                                    : []
                                }
                                onChange={(value) =>
                                  handleChangeEtnia({
                                    type: "owned",
                                    value,
                                    code: item?.code,
                                  })
                                }
                                optionLabelProp="label"
                                className="select-etnias"
                              >
                                {item.children.map((itemChild, index) => (
                                  <Option
                                    key={`${index}-${itemChild}`}
                                    value={itemChild.code}
                                    label={itemChild.label}
                                  >
                                    <div className="demo-option-label-item">
                                      {itemChild.label}
                                    </div>
                                  </Option>
                                ))}
                              </Select>
                            ) : null}
                          </S.RowCheck>
                        </div>
                      </S.ColQualifications>
                      <S.ColQualifications style={{ marginLeft: 4 }}>
                        <div>
                          <p>{I18n.get("LED:")}</p>
                          <S.ItemDescription style={{ padding: 0 }}>
                            {item.descriptionLed}
                          </S.ItemDescription>
                          <S.RowCheck>
                            <S.CheckBoxContent>
                              <Checkbox
                                id={item.code}
                                onChangeCb={(e, id) =>
                                  onChangeCheckboxes(
                                    e.target.checked,
                                    id,
                                    "led"
                                  )
                                }
                                checked={isSelectedCheckbox(item.code, "led")}
                                checkboxSize={18}
                              />
                              <S.LabelCheckBox>
                                {I18n.get("Led")}
                              </S.LabelCheckBox>
                            </S.CheckBoxContent>
                            {item.children ? (
                              <Select
                                mode="multiple"
                                style={{
                                  width: "100%",
                                }}
                                placeholder={I18n.get("Select etinia")}
                                defaultValue={
                                  dropdownEtniasLedDefault.length
                                    ? renderSelectDefault({
                                        arr: dropdownEtniasLedDefault,
                                        code: item?.code,
                                        type: "led",
                                      })
                                    : []
                                }
                                onChange={(value) =>
                                  handleChangeEtnia({
                                    type: "led",
                                    value,
                                    code: item?.code,
                                  })
                                }
                                optionLabelProp="label"
                                className="select-etnias"
                              >
                                {item.children.map((itemChild, index) => (
                                  <Option
                                    key={`${index}-${itemChild}`}
                                    value={itemChild.code}
                                    label={itemChild.label}
                                  >
                                    <div className="demo-option-label-item">
                                      {itemChild.label}
                                    </div>
                                  </Option>
                                ))}
                              </Select>
                            ) : null}
                          </S.RowCheck>
                        </div>
                      </S.ColQualifications>
                    </S.DescriptionsQualifications>
                  </S.WrapperItemQualifications>
                ))
              )}
              <S.WrapperNoneOfTheAbove>
                <S.Col100>
                  <S.LabelQualifications
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>{I18n.get("None of the above")}</span>
                    <S.CheckboxNone>
                      <Checkbox
                        id="diversityPreferNotDeclare"
                        onChangeCb={(e, id) => {
                          onChangeCheckboxes(e.target.checked, id);
                          const newError = inputsErrorsForm.filter(
                            (i) => i.field != "DiversityPreferNotDeclare"
                          );

                          setInputsErrorsForm(newError);
                        }}
                        checked={inputsForm.diversityPreferNotDeclare}
                        checkboxSize={18}
                      />
                    </S.CheckboxNone>
                  </S.LabelQualifications>
                  {hasError("diversityOwned") ? (
                    <S.ErrorNotAbove>
                      {I18n.get(
                        "You must select a diversity above, or select None of the above"
                      )}
                    </S.ErrorNotAbove>
                  ) : null}
                </S.Col100>
              </S.WrapperNoneOfTheAbove>
              <S.WrapperSelfDeclaration>
                <S.Col100 style={{ display: "flex", alignItems: "center" }}>
                  <S.CheckboxNone style={{ margin: 0 }}>
                    <Checkbox
                      id="selfDeclaration"
                      onChangeCb={(e, id) => {
                        onChangeCheckboxes(e.target.checked, id);
                        const newError = inputsErrorsForm.filter(
                          (i) => i.field != "SelfDeclaration"
                        );

                        setInputsErrorsForm(newError);
                      }}
                      checked={inputsForm.selfDeclaration}
                      checkboxSize={18}
                    />
                  </S.CheckboxNone>
                  <S.LabelItemSelf
                    style={{
                      color: hasError("SelfDeclaration") ? "#ff0000" : null,
                    }}
                  >
                    <span>{I18n.get("Self declaration")}</span>
                    <span style={{ fontWeight: 400 }}>
                      {I18n.get(
                        "I state tath the information provided is correct to the best of my knowledge"
                      )}
                    </span>
                  </S.LabelItemSelf>
                </S.Col100>
              </S.WrapperSelfDeclaration>

              <UploadCertified
                fileToUpload={(file) => fileToUpload({ file })}
                fileDefault={fileDefaultGet}
                removeFileDefault={removeFileDefault}
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          {contextPage === "full" ? (
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get("Back")}
                theme="secondary"
                full
                onClick={handleBackPage}
              />
            </Col>
          ) : null}
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={
              contextPage === "edit" || contextPage === "admin"
                ? { span: 12, offset: 12 }
                : { span: 12, offset: 0 }
            }
          >
            <ButtonBpool
              text={
                contextPage === "edit" || contextPage === "admin"
                  ? I18n.get("Save")
                  : I18n.get("Save and Next")
              }
              theme="primary"
              full
              loading={isSending}
              onClick={send}
            />
          </Col>
        </Row>
      </Main>
      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get("Missing required information")}
        subBodyText={I18n.get(
          "Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?"
        )}
        okText={I18n.get("Yes")}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get("Close")}
        typeModal="isConfirm"
        // isDanger
      />
    </>
  );
};
