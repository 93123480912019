import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import LoggedPage from "../page-templates/logged";

import { CLIENT_API } from "../../actions/client/actions";
import { CLIENT_USER_API } from "../../actions/client-user/actions";

import Menu from "./_menu";

import FormClientUser from "../../modules/Register/components/formUserClient";

import ResponsibleForm from "./responsible-form";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    update: (data) => {
     return dispatch(CLIENT_USER_API.updateLogado(data));
    },
  };
};

class ResponsibleAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  
  
  render() {
    const client_user = this.props.client && this.props.client.users && this.props.client.users.find((user) => user.username === this.props.auth.username) || null;


    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/home">{I18n.get("Admin")}</Link>
            </li>
            <li className="is-active">
              <Link to="/cadastro">{I18n.get("Cadastro")}</Link>
            </li>
          </ul>
        </nav>

        <div className="columns">
          <div className="column is-12">
            <h1 className="title">{I18n.get("Dados do responsável")}</h1>

            {/* <ResponsibleForm {...this.props} isAdmin={true} /> */}

            <FormClientUser
              idContext={this.props.client && this.props.client.clientId}
              update={this.props.update}
              item={client_user && client_user}
              errors={this.props.client_user && this.props.client_user.errors}
              isUser={true}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
              isUserClient={true}
              token={client_user?.verificationToken}
              locale={this.state.locale}
              edit={true}
            />
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleAdmin);
