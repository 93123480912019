import { LOGIN, LOGOUT } from '../actions/auth/actions';

const initialState = {
  errors: {},
  task: {},
};

export default function wikisReducer(state = initialState, action) {
  if (action.type == LOGIN || action.type == LOGOUT) return initialState;

  if (action.type.indexOf('wikis_') == -1) return state;

  return Object.assign({}, state, action.payload);
}
