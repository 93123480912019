import styled from "styled-components";

export const StyleMain = styled.div`
  background: #fff;
  padding: 60px 30px;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 200px;

  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;
