import styled from "styled-components";

export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const TitleSection = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #010202;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  position: relative;
`;

export const HeaderImage = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 10px;
  background: #fafafa;
  min-height: 120px;
  img {
    border-radius: 10px;
  }
`;

export const BackLogo = styled.div`
  border: 5px solid;
  border-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 25%);
`;

export const LogoImage = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -40px;

  img {
    height: calc(117px * 0.62);
    border-radius: 50%;
  }
`;

export const IntroVideoOrImage = styled.div``;

export const EmptyItem = styled.div``;

export const Specialities = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
    margin-bottom: 20px;
    min-height: 30px;
    display: flex;
    align-items: center;

    span {
      top: 3px;
      left: 15px;
      position: relative;
    }
  }
`;

export const CustomersShow = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    list-style: none;
    background: #000000;
    padding: 5px 15px;
    border-radius: 10px;
    margin: 0 20px 20px 0;

    :last-child {
      margin-right: 0;
    }

    span {
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const WrapperItemWithTitle = styled.div`
  margin-top: 30px;
`;

export const TitleSectionResume = styled.div`
  margin-bottom: 20px;

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #010202;
  }

  button {
    margin-left: 50px;
  }
`;

export const TitleButtonSectionResume = styled.button`
  width: fit-content;
  margin: 0 0 20px 0;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #010202;
  }

  .anticon {
    margin-left: 50px;
    color: #1883ff;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
`;

export const IconsItemWithTitle = styled.div`
  span {
    svg {
      transform: ${(props) =>
        props.expand ? "rotate(0deg)" : "rotate(180deg)"};
      transition: all 0.25s;
    }
  }
`;

export const ContentItemWithTitle = styled.div`
  width: 100%;
  height: ${(props) =>
    props.titleExpand ? (props.expanded ? "auto" : "0px") : "auto"};
  overflow: ${(props) =>
    props.titleExpand ? (props.expanded ? "visible" : "hidden") : "visible"};
  opacity: ${(props) =>
    props.titleExpand ? (props.expanded ? "1" : "0") : "1"};
  transition: all 0.5s;
`;

export const AboutText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #010202;
`;

export const WrapperItemCarousel = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .prev {
    margin-right: 15px;
  }

  .next {
    margin-left: 15px;
  }
`;

export const ContainerItemCarousel = styled.div`
  width: calc(100% - 90px);
`;

export const ContentItemCarousel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 15px;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  overflow-x: hidden;
`;

export const BtArrowCarousel = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeaderItem = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  scroll-snap-align: start;
  /* width: ${(props) => props.maxItemsShow}; */
  width: auto;
`;

export const CaseItem = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  scroll-snap-align: start;
  width: 50%;
`;

export const LeaderProfilePicture = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);

  img {
    border-radius: 50%;
    width: 65px;
    height: 65px;
  }
`;

export const LeaderItemInfos = styled.div`
  margin-left: 15px;

  strong {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2f2f2f;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .leader-mail {
    font-weight: 400;
  }
`;

export const InfosItemCase = styled.div`
  background: rgba(7, 0, 0, 0.6);
  color: #fbfbfb;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
`;

export const ImageEmpty = styled.div`
  width: 300px;
  height: 156px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 50px;
    height: 50px;
    color: #6b6666;
  }
`;

export const ItemDiversityView = styled.div`
  line-height: 22px;
  margin-top: 16px;

  strong {
  }
  span {
  }
  img {
    cursor: pointer;
    margin-left: 8px;
    top: -2px;
    position: relative;
  }
`;
