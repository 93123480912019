import React from "react";
import { I18n } from "aws-amplify";
import { Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";

const handleSearch = (selectedKeys, confirm, dataIndex, editFilter) => {
  editFilter(dataIndex, selectedKeys?.length ? selectedKeys[0] : "");
  confirm();
};

const handleReset = (clearFilters, editFilter, reference) => {
  editFilter(reference, "");

  clearFilters();
};

const inputColumnSearchProps = (dataIndex, options, editFilter, reference) => {
  let searchText = "";
  let searchedColumn = "";

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={I18n.get("Filtrar")}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, editFilter)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() =>
            handleSearch(
              selectedKeys,
              confirm,
              dataIndex,
              editFilter,
              options,
              reference
            )
          }
          // icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {I18n.get("Buscar")}
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, editFilter, reference)}
          size="small"
          style={{ width: 90 }}
        >
          {I18n.get("Cancelar")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  };
};

export default inputColumnSearchProps;
