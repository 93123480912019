import React from 'react';

import { I18n } from 'aws-amplify';

import constants from '../../constants';

import Responsbible from './_reference-details-responsible';
import Ratings from './_reference-details-ratings';


export default class ReferenceDetailsView extends React.Component {

    constructor(props) {
        super();
    }

    componentDidMount() {
    }

    render() {

        const item = this.props.item;

        if (!item) return null;

        const hasReview = item.ratings && item.ratings.length > 0;



        let url = `https://app.bpool.co/#/referencia/convite/${item.verificationToken}`;



        return (
            <section>

                <h3 className="title is-3">{I18n.get("Detalhes da referência")}</h3>

                <div className="columns">

                    <div className="column is-12">

                    {!hasReview && <>

                        <a href={url}>{url}</a> <br/><br/>

                    </>}

                        <Responsbible item={item} />

                    </div>

                </div>

                {hasReview && <><div className="columns">

                        <div className="column is-12">

                            <Ratings item={item} />

                        </div>

                    </div>
                </>}

            </section>
        );
    }
}
