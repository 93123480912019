import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import styled from 'styled-components';

import '../../App.css';

import logo from '../../image/logoWOtext.svg';
import Footer from '../../components/page-templates/footer';
import TopMenu from '../../components/page-templates/_top-menu';
import SubMenu from '../../components/page-templates/_sub-menu';

import ChatNavBarItem from '../../components/shared/chat-navbar-item';

import Loading from '../../components/pages/loading';



const ContainerPage = styled.div`
    .logged-page {
        background: #fff;

        &.page-grey {
            background: #f7f7f7;
            height: 100%;
            position: relative;
            width: 100%;
        }
    }

    .page-whatif {
        height: 100%;
    }
`

class WhatifWrapper extends Component {

    constructor(props) {
        super();
        this.state = {
        };
    }

    render() {

        const isInit = (!this.props.auth || this.props.auth.init);

        if (isInit) {
            return (<Loading />);
        }

        const isNotLogged = (this.props.auth && !this.props.auth.logged_user);
        const IsLogout = (this.props.auth && this.props.auth.is_logout);

        if (isNotLogged) {

            const login = {
                pathname: '/login',
                state: {
                    returnUrl: (IsLogout ? '' : this.props.location.pathname),
                }
            }

            return (<Redirect to={login}></Redirect>);
        }        

        return (
            <ContainerPage>
                <section className={`hero is-fullheight logged-page preview-page-container ${this.props.grey ? "page-grey" : ""}`}>
                    {/* <section className="hero is-fullheight logged-page preview-page-container"> */}
                    <div className="hero-head">
                        <nav className="navbar is-main navbar-main">
                            <div className="container">
                                <div className="navbar-brand is-expanded">
                                    <a className="navbar-item" href="/">
                                        <img src={logo} alt="BPool" />
                                    </a>
                                    <span className="navbar-burger burger" data-target="navbarMenu">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                </div>
                                <div id="navbarMenu" className="navbar-menu is-active">
                                    <div className="navbar-start">
                                        <div className="navbar-item">
                                        </div>
                                    </div>

                                    <div className="navbar-end" style={{ zIndex: 999 }}>
                                        <TopMenu {...this.props} />
                                        <ChatNavBarItem {...this.props} />
                                    </div>
                                </div>
                            </div>
                        </nav>
                        {/* <SubMenu {...this.props} /> */}
                    </div>
                    <div className="hero-body page-whatif">
                        {this.props.children}
                    </div>

                </section>

                <Footer {...this.props} />

            </ContainerPage>

        );
    }
}

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WhatifWrapper));
