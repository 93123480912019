import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useProjects = () => {
  const getProjectByIdOld = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/client-project/${projectId}`,
      config
    );

    return data;
  };

  const getProjectById = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}`,
      config
    );

    return data;
  };

  const getProjectByStepAndId = async (projectId, step) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/${step}`,
      config
    );

    return data;
  };

  const postProjectByStep = async (dataProject, step) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project/${step}`,
      dataProject,
      config
    );

    return data;
  };

  const putProjectByStep = async (dataProject, step) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/${step}`,
      dataProject,
      config
    );

    return data;
  };

  const getProjectsToCombo = async ({ statusProject }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      statusProject
        ? `${Config.API_BASE_URL}/v2/client-project-meeting/client/project?status=${statusProject}`
        : `${Config.API_BASE_URL}/v2/client-project-meeting/client/project`,
      config
    );

    return data;
  };

  const getProjectReasons = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/client/reasons`,
      config
    );

    return data;
  };

  // Assets
  const getAssetsStep2 = async (projectId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/step2`,
      config
    );

    return data;
  };

  const addAssetToProject = async ({ assets }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/AddAsset`,
      assets,
      config
    );

    return data;
  };

  const addAssetBundleToProject = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/AddAssetBundle`,
      dataForm,
      config
    );

    return data;
  };

  const updateProjectStep5 = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/step5`,
      form,
      config
    );

    return data;
  };

  const putAssetDescription = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/asset-description`,
      form,
      config
    );

    return data;
  };

  const getPartnerProjectById = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.get(
      `${Config.API_BASE_URL}/partner/projects/${projectId}`,
      config
    );

    return data;
  };

  const getPartnerInvites = async (queryString) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      queryString
        ? `${Config.API_BASE_URL}/v2/partner/invitations?${queryString}`
        : `${Config.API_BASE_URL}/v2/partner/invitations`,
      config
    );

    return data;
  };

  const getUsersPartnerInvites = async (partnerId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/users/operation/${partnerId}`,
      config
    );

    return data;
  };

  const updateInviteAcceptOrNot = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Partner/invitations`,
      dataForm,
      config
    );

    return data;
  };

  const mensalFee = async ({ projectId, status }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.get(
      `${Config.API_BASE_URL}/client-project/${projectId}/mensalfee/${status}`,
      config
    );

    return data;
  };

  const convertCurrency = async ({ projectId, currency }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/convert-currency/${currency}`,
      config
    );

    return data;
  };

  const putGlobal = async (dataProject, step) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const sendData = {
      file: dataProject?.file,
    };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/global-Authorization-upload`,
      dataProject,
      config
    );

    return data;
  };

  const removeGlobal = async (dataProject, step) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const sendData = {
      file: dataProject?.file,
    };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/global-Authorization-upload-remove`,
      dataProject,
      config
    );

    return data;
  };

  const getPreferredPartners = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.get(
      `${Config.API_BASE_URL}/client-project/${projectId}/preferredPartners`,
      config
    );

    return data;
  };

  const finalizeProject = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/client-project/${dataForm?.projectId}/end`,
      dataForm,
      config
    );

    return data;
  };

  const deleteProject = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}`,
      {
        data: { projectId },
        ...config,
      }
    );

    return data;
  };

  const createProjectPartner = async (dataProject) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project/partner`,
      dataProject,
      config
    );

    return data;
  };

  const updateProjectPartner = async (dataProject) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/partner`,
      dataProject,
      config
    );

    return data;
  };

  const clientsWorkedWith = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/partner/clients-Worked-With`,
      config
    );

    return data;
  };

  const clientBrands = async ({ clientId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.get(
      `${Config.API_BASE_URL}/partner/projects/clientBrands/${clientId}`,
      config
    );

    return data;
  };

  const sendSuggestion = async (dataProject) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/partner/send-suggestion`,
      dataProject,
      config
    );

    return data;
  };

  return {
    getProjectByIdOld,
    getProjectById,
    getProjectByStepAndId,
    postProjectByStep,
    putProjectByStep,
    getProjectsToCombo,
    getProjectReasons,
    getAssetsStep2,
    addAssetToProject,
    addAssetBundleToProject,
    updateProjectStep5,
    putAssetDescription,
    getPartnerProjectById,
    getPartnerInvites,
    getUsersPartnerInvites,
    updateInviteAcceptOrNot,
    mensalFee,
    convertCurrency,
    putGlobal,
    getPreferredPartners,
    finalizeProject,
    removeGlobal,
    deleteProject,
    createProjectPartner,
    updateProjectPartner,
    clientsWorkedWith,
    clientBrands,
    sendSuggestion,
  };
};
