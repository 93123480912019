import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Textarea } from "../../UI/Textarea";
import { ButtonBpool } from "../../UI/ButtonBpool";
import { I18n } from "aws-amplify";
import * as S from "./styles";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";

import { notification } from "antd";

export const TextareaHourDescription = ({
  defaultValue,
  getProject,
  isClient,
}) => {
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  let params = useParams();

  const { putAssetDescription } = useProjects();

  const sendDescription = async () => {
    setLoading(true);
    const data = {
      projectId: params?.projectId,
      descriptiveHourMan: description || defaultValue,
    };
    const response = await putAssetDescription(data);

    if (response.success) {
      getProject();

      // TODO: IF SUCESSO
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
      });
    } else {
      notification.error({
        message: I18n.get("ops! Algo deu errado."),
      });
    }

    setLoading(false);
  };

  return (
    <S.StyleBox>
      <Textarea
        id="overview-company"
        label={I18n.get("Descritivo Hora/Homem")}
        rows={4}
        // counterChar
        defaultValue={defaultValue}
        onChange={(value) => setDescription(value)}
        required
        disabled={isClient}
      // error={hasError("description")}
      // clearError={() => {
      //   const errorsArr = clearErrorInput({
      //     field: "description",
      //     arr: inputsErrorsForm,
      //   });
      //   setInputsErrorsForm(errorsArr);
      // }}
      />

      {!isClient && (
        <S.SaveButton>
          <ButtonBpool
            text={I18n.get("Salvar Descritivo")}
            theme="primary"
            loading={loading}
            onClick={() => sendDescription()}
          />
        </S.SaveButton>
      )}
    </S.StyleBox>
  );
};
