import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import BpayProvider from "../../pages/Bpay/context/BpayProvider";
import { Route, Switch } from "react-router-dom";
import { BpayList } from "../../pages/Bpay/pages/List";
import { NewBpayData } from "../../pages/Bpay/pages/NewBpay/Data";
import { NewBpayPo } from "../../pages/Bpay/pages/NewBpay/Po";
import { NewBpaySuppliers } from "../../pages/Bpay/pages/NewBpay/Suppliers";
import { BpayDetails } from "../../pages/Bpay/pages/Details";
import { BpayFinalized } from "../../pages/Bpay/pages/Finalized";

const queryClient = new QueryClient();

function Bpay() {
  return (
    <QueryClientProvider client={queryClient}>
      <BpayProvider>
        <Switch>
          <Route exact path="/cliente/bpay" component={BpayList} />
          <Route exact path="/cliente/bpay/new/data" component={NewBpayData} />
          <Route
            exact
            path="/cliente/bpay/new/data/:bpayId"
            component={NewBpayData}
          />
          <Route
            exact
            path="/cliente/bpay/new/suppliers/:bpayId"
            component={NewBpaySuppliers}
          />
          <Route
            exact
            path="/cliente/bpay/new/po/:bpayId"
            component={NewBpayPo}
          />
          <Route
            exact
            path="/cliente/bpay/details/:bpayId"
            component={BpayDetails}
          />
          <Route
            exact
            path="/cliente/bpay/finalized/:bpayId"
            component={BpayDetails}
          />
        </Switch>
      </BpayProvider>
    </QueryClientProvider>
  );
}

export default Bpay;
