import React, { Component } from "react";

import LoggedPage from '../../../components/page-templates/fillForm-logged';
import HeaderPage from "../components/HeaderPageTabs";

import { Redirect } from 'react-router-dom';

import MenuWiki from "./MenuWikis";

import IcoMenu from '../../../image/ico-menu.svg';
import { Layout } from 'antd';

const { Content, Sider } = Layout;


class LayoutWikis extends Component {

    state = {
        collapsed: false,
        menuActive: null,
        termActive: null,
        term: null
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    handleSelectMenu = (filter) => {
        this.setState({
            menuActive: filter,
            termActive: null,
            urlSearch: null
        })
    }

    handleSearch = (title) => {
        let term = this.props.searchData.find(i => i.title == title)
        
        const urlResolver = `/${this.props.auth.is_client ? "cliente": "parceiro"}/wikis/${this.props.activeTab}/${term.category}/${term.id}`
        
        this.setState({
            urlSearch: urlResolver
        });


        this.props.history.push(urlResolver);
    }
    
    componentWillMount = () => {
        this.setState({
            urlSearch: null
        });
    }
    
    handleSelect = (value) => {
        const urlResolver = `/${this.props.auth.is_client ? "cliente": "parceiro"}/wikis/resultados/?=${value}`
        this.props.history.push(urlResolver);
    }

    render() {
        let scopeSections = this.props?.scope.find(sec => sec.name == this.props.match.params.scope);
        return (
            <LoggedPage>
                
                <HeaderPage
                    {...this.props}
                    scope={this.props.scope}
                    selectTerm={this.handleSearch}
                    activeTab={this.props?.match?.params?.scope}
                    isClient={this.props?.auth.is_client}
                    action={this.handleSelect}
                />
                <Layout className="bp-layout">
                    {!this.props.noMenu &&
                        <Sider trigger={null} width={270} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                            <p onClick={() => this.setState({collapsed: !this.state.collapsed})} className="menu-slider">
                                <img src={IcoMenu} />
                            </p>

                            <MenuWiki 
                                {...this.props}
                                itens={scopeSections?.sections || []}
                                active={this.props.match.params.category}
                                activeTab={this.props?.match?.params?.scope}
                                isClient={this.props?.auth.is_client}
                                />

                        </Sider>
                    }
                    
                    <Layout style={{ minHeight: '100vh' }}>
                        <Content style={{ margin: '0', minHeight: '100vh' }}>
                            <div style={{ padding: 24, background: '#f6f6f6', minHeight: 360, minHeight: '100vh' }}>
                                {this.props.children}
                            </div>
                        </Content>
                    </Layout>
                </Layout>

            </LoggedPage>
        );
    }
}

export default LayoutWikis;