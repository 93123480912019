import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import { ConvertBpCurrenciesInList } from "./utils/convertBpCurrenciesInList";
import { SelectOne } from "../SelectOne";
import Loading from "../Loading";
import { IncludedItems } from "./components/IncludedItems";
import { TableValues } from "./components/TableValues";
import { TextareaHourDescription } from "../../../../v2/components/Shared/TextareaHourDescription";

import * as S from "./styles";

export const AssetBundle = ({
  isClient,
  isInvite,
  listOfAssets,
  dataValues,
  convertedValues,
  reload,
  descriptiveHourMan,
  isModalConfirm,
  disabledEdit,
  removeAll,
  isCart,
  currencyProject,
  showTooltipOtherTax,
}) => {
  const { convertCurrency } = useProjects();
  let params = useParams();
  const [currencyListDefault, setCurrencyListDefault] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyValue, setCurrencyValue] = useState(null);
  const [isLoadingSelect, setIsLoadingSelect] = useState(false);
  const state = useSelector((state) => state);

  const onChangeCurrency = async (currency) => {
    setIsLoadingSelect(true);
    const projectId = params?.projectId;
    const response = await convertCurrency({ projectId, currency });
    if (response?.success) {
      setCurrencyValue(currency);
      const currencySelected = currencyListDefault?.find(
        (item) => item?.code === currency
      );

      await convertedValues({
        values: response?.data,
        currency: currencySelected,
      });
    }
    setIsLoadingSelect(false);
  };

  const loadInit = async ({ currencies, currencyDefault }) => {
    setCurrencyListDefault(currencies);
    const list = ConvertBpCurrenciesInList(currencies);
    setCurrencyList(list);

    if (currencyDefault) {
      setCurrencyValue(currencyDefault?.code);
    }
  };

  useEffect(() => {
    loadInit({
      currencies: state?.bp?.currencies,
      currencyDefault: dataValues?.currency,
    });
  }, [state, dataValues]);

  const handleUpdateProject = async () => {
    await reload();
    await loadInit({
      currencies: state?.bp?.currencies,
      currencyDefault: dataValues?.currency,
    });
  };

  const hasIsManHour =
    listOfAssets &&
    listOfAssets.length > 0 &&
    listOfAssets.some((asset) => asset?.isManHour === true);

  return (
    <S.Wrapper>
      <S.AssetBundleHead>
        <div>
          <S.Text>
            <span className="strong">{I18n.get("Pacote customizado")}:</span>{" "}
            <span className="blue strong">
              {listOfAssets?.length
                ? `${listOfAssets?.length} asset${listOfAssets?.length > 1 ? "s" : ""
                }`
                : `---`}
            </span>
          </S.Text>
        </div>
        {convertedValues ? (
          <S.ContentSelect>
            {isLoadingSelect ? (
              <Loading
                sizeIcon={18}
                text={I18n.get("Carregando...")}
                sizeText={14}
                align="center"
              />
            ) : currencyProject?.code !== "BRL" ? (
              <SelectOne
                options={currencyList}
                value={currencyValue}
                onChange={(value) => onChangeCurrency(value)}
                placeholder={I18n.get("Moeda")}
                error=""
              />
            ) : null}
          </S.ContentSelect>
        ) : null}
      </S.AssetBundleHead>

      <IncludedItems
        isCart={isCart}
        isModalConfirm={isModalConfirm}
        data={listOfAssets?.length ? listOfAssets : []}
        reload={reload}
        disabledEdit={disabledEdit}
        removeAll={removeAll}
        cartTotals={dataValues?.cartTotals}
        taxes={dataValues?.taxes}
      />

      <TableValues
        dataValues={dataValues}
        isClient={isClient}
        showTooltipOtherTax={showTooltipOtherTax}
      />

      {hasIsManHour && !isInvite && (
        <div style={{ marginTop: 20 }}>
          <TextareaHourDescription
            defaultValue={descriptiveHourMan || ""}
            getProject={handleUpdateProject}
            isClient={isClient}
          />
        </div>
      )}
    </S.Wrapper>
  );
};
