import styled from "styled-components";

export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const WrapperItemQualifications = styled.div``;

export const LabelQualifications = styled.div`
  border: 1px solid;
  border-color: #f0f0f0;
  border-radius: 2px;
  margin-top: 14px;
  padding: 12px 0 12px 15px;

  span {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #222;
  }
`;

export const DescriptionsQualifications = styled.div`
  display: flex;
  margin-top: 2px;

  div {
    p {
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      color: #222;
    }
  }
`;

export const ItemDescription = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #333;
  min-height: 120px;
`;

export const CheckBoxContent = styled.div`
  padding: 0;
  display: flex;
`;

export const ColQualifications = styled.div`
  width: calc(50% - 2px);
  border: 1px solid;
  border-color: #f0f0f0;
  border-radius: 2px;

  div {
    padding: 15px;
  }

  .select-etnias {
    padding: 0;
    .ant-select-selection {
      padding: 0;
      top: 0;
      min-height: 40px;
      border-radius: 4px;
      background: #fff;
      border: 1px solid;
      border-color: #ccc;

      .ant-select-selection__rendered {
        padding: 5px 0;

        ul {
          .ant-select-selection__choice {
            border-radius: 4px;
            padding: 0 20px 0 10px;
          }
        }

        .ant-select-selection__placeholder {
          padding: 0;
          color: #6e6e6e;
        }
      }
    }
    .ant-select-selection__choice__content {
      padding: 0;
    }
  }
`;

export const RowCheck = styled.div`
  display: flex;
  padding: 0 !important;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50px;

  label {
    margin: 0;

    span .ant-checkbox-inner {
      border-radius: 2px;
      border: solid 1px;
    }
  }
`;

export const LabelCheckBox = styled.div`
  padding: 0 !important;
  margin-left: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2f2f2f;
`;

export const WrapperNoneOfTheAbove = styled.div``;

export const Col100 = styled.div``;

export const CheckboxNone = styled.div`
  margin-left: 30px;

  label {
    margin: 0;

    span .ant-checkbox-inner {
      border-radius: 2px;
      border: solid 1px;
    }
  }
`;

export const WrapperSelfDeclaration = styled.div`
  margin-top: 50px;
`;

export const LabelItemSelf = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  justify-content: center;

  height: 60px;
  width: 100%;

  span {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const DescriptionSelfDeclaration = styled.div``;

export const WrapperUploadCertified = styled.div`
  margin-top: 50px;

  .error {
    border-color: #ff0000;
  }
`;

export const LabelUploadCertified = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #2f2f2f;
`;

export const ContainerUploadCertified = styled.div`
  border-radius: 15px;
  border: 2px dashed;
  border-color: #ccc;
  margin-top: 15px;
`;

export const IconAndTextUploadCertified = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentUploadCertified = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconUploadCertified = styled.div`
  span {
    font-size: 40px;
    color: #1883ff;
  }

  .error {
    color: #ff0000;
  }
`;

export const TextUploadCertified = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  height: 100%;
  justify-content: center;

  strong {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #121212;
  }

  span {
    font-weight: 400;
    font-size: 9.14851px;
    line-height: 12px;
    color: #868686;
  }

  .error {
    color: #ff0000;
  }
`;

export const FileUploaded = styled.div`
  .uploaded {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #121212;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #ff0000;
    margin-left: 10px;
    &.view {
      color: #000;
    }

    i {
      padding-right: 3px;
    }
  }
`;

export const ButtonUploadCertified = styled.div`
  input {
    display: none;
  }

  label {
    cursor: pointer;
    background-color: #1883ff;
    border-color: #1883ff;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 30px;
  }
`;

export const RowLoading = styled.div`
  margin-top: 14px;
`;

export const ErrorNotAbove = styled.div`
  color: #ff0000;
  font-size: 14px;
  margin: 10px 0 0 0;
`;
