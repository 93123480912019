import { CHAT_NOTIFICATION_RESET } from './actions';

export const api_call = () => {

    return (dispatch, getState) => {

        dispatch({
            type: CHAT_NOTIFICATION_RESET,
            payload: []
        })        

    }
}

export default api_call;