import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'aws-amplify';
import { Row, Col, Tag } from 'antd';
import { compareByAlph } from '../../../../helpers/utils';
import TableBP from '../../../../components/UI/Table';
import Loading from '../../../../components/UI/Loading';
import { ExpandDetails } from './ExpandDetails';

import * as S from "./styles";

export const TableReferences = ({ data }) => {
  const [expand, setExpand] = useState([]);
  const referenceRatings = useSelector((state) => state?.bp?.referenceRatings);
  
  const renderText = (text) => {
    if (text) {
      return <S.Text>{text}</S.Text>;
    } else {
      return <S.Text>---</S.Text>;
    }
  };

  const columns = [
    {
      title: I18n.get('Name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => <S.Text>{renderText(name)}</S.Text>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => compareByAlph(a.name, b.name),
      // width: "200px",
    },
    {
      title: I18n.get('E-mail'),
      dataIndex: 'email',
      key: 'email',
      render: (email, record) => <S.Text>{renderText(email)}</S.Text>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => compareByAlph(a.email, b.email),
      // width: "200px",
    },
    {
      title: I18n.get('LinkedIn'),
      dataIndex: 'linkedInUrl',
      key: 'linkedInUrl',
      render: (linkedInUrl, record) => <S.Text>{renderText(linkedInUrl)}</S.Text>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => compareByAlph(a.linkedInUrl, b.linkedInUrl),
      width: "200px",
    },
    {
      title: I18n.get('Year'),
      dataIndex: 'year',
      key: 'year',
      render: (year, record) => <S.Text>{renderText(year)}</S.Text>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => compareByAlph(a.year, b.year),
      // width: "100px",
    },
    {
      title: I18n.get('Relationship'),
      dataIndex: 'relationship',
      key: 'relationship',
      render: (relationship, record) => <S.Text>{renderText(relationship)}</S.Text>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => compareByAlph(a.relationship, b.relationship),
      // width: "120px",
    },
  ]

  return (
    <>
      <div className="card">
        <div className="card-content">
          <Row gutter={1}>
            <Col sm={24}>
              <h3 className="title is-4">{I18n.get("References")}</h3>
              {data.length > 0 ? (
                <TableBP
                  columns={columns}
                  data={data}
                  isBig={true}
                  pagination={false}
                  expandedRowRender={(record) => (
                    <ExpandDetails
                      data={record}
                      referenceRatings={referenceRatings}
                      close={() => setExpand(record)}
                    />
                  )}
                  // expandedRowKeys={expand}
                  onExpand={(expanded, record) => setExpand(record)}
                />
              ) : (
                <div>{I18n.get("Has no references.")}</div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
