import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import { BP_API } from '../../actions/bp/actions';
import { Task } from '../../utils/task';

import LoggedPage from '../page-templates/loggedAdmin';
import { CardSimple } from '../../modules/components/Cards';
import { TitleBorder } from '../../modules/components/Titles';

import { Heatmap, Line, Column, Area } from '@ant-design/charts';

import { formatCurrency } from '../../utils/helpers'

import FormFilterDash from '../../modules/Admin/components/filterDashAdminB';

import styled from "styled-components";


import {
    Row,
    Col,
    Card,
    Icon,
    Tooltip,
} from 'antd';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_countrys: () => {
            dispatch(BP_API.get_countrys());
        },
        financial: (data) => {
            dispatch(BP_API.financial(data));
        },  
        financial_historic: (data) => {
            dispatch(BP_API.financial_historic(data));
        },    
    };
};


const CardDisplay = styled(Card)`
    margin-bottom: 20px;
    height: 100%;

    .DescriptionPage {
        font-size: 14px;
    }

    .LabelPeriodo {
        font-size: 12px;
        font-weight: bold;
    }

    &.TableBox {
        min-height: 335px;
    }

    .GraphCard {
        opacity: 1;
        transition: opacity 2s ease;
    }

    .hidden {
        opacity: 0;
    }

    .show {
        opacity: 1;
    }

    .LabelCard {
        text-transform: uppercase;
        font-size: 10px;
        margin-bottom: -10px;
    }

    h2 {
         font-size: 14px;
         text-transform: uppercase;
         margin-bottom: 20px;
         padding-left: 15px;
         border-left: solid 5px #000;
         span {
             display: block;
             color: #999;
             font-size: 11px;
         }

         &.title-span {
             line-height: 18px;
         }
    }

    .NumberCard {
        display: block;
        text-align: center;
        font-size: 22px;
        line-height: 60px;
    }

    .DescriptionCard {
        span {font-weight: bold;}
        border-top: solid 1px #b5b5b5;
        padding-top: 10px;
        margin-top: 15px;
        font-size: 12px;
    }

    .BtnCard {
        border-top: solid 1px #ccc;
        padding-top: 15px;
        margin-top: 10px;
        display: none;
    }

    .LoadingCard {
        text-align: center;
        margin: 36px 0 33px;

        .noDate {
            display: block;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
        }

        i {
            font-size: 50px;
            opacity: .4;
        }

        &.LabelG {
            position: absolute;
            top: 38%;
            left: 0;
            width: 100%;

            &.noDateView {
                top: 32%;
            }

        }
    }
`;


let noData = () => {

    return (<p className="LoadingCard LabelG noDateView">
                <Icon type="inbox" />
                <span className="noDate">{I18n.get("Sem informações")}</span>
    </p>)
};

const chartFinancialArea = (data) => {
     var config = {
    data: data,
    xField: 'month',
    yField: 'value',
    seriesField: 'type',
    label: {},
    padding: [100, 100, 100, 100],
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 1,
      },
    },
    tooltip: { showMarkers: false },
    
    state: {
      active: {
        style: {
          shadowColor: 'yellow',
          shadowBlur: 4,
          stroke: 'transparent',
          fill: 'red',
        },
      },
    },
    yAxis: {
        label: {
            formatter: function formatter(v) {
                return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                    return ''.concat(s, ',');
                });
            },
        },
        },
    xAxis: {
        mask: 'MM/YYYY',
        label: {
            style: { fontSize: 12 },
            autoRotate: true,
            autoHide: false
        }
    },
    theme: {
      geometries: {
        point: {
          diamond: {
            active: {
              style: {
                shadowColor: '#FCEBB9',
                shadowBlur: 2,
                stroke: '#F6BD16',
              },
            },
          },
        },
      },
    },
    interactions: [{ type: 'marker-active' }],
  };

    return <Line {...config} />;
}

const chartFinancialColumnGrouped = (data) => {

    var config = {

        data: data || [],
        isGroup: true,
        xField: 'name',
        yField: 'value',
        padding: [70, 70, 120, 70],
        seriesField: 'type',
        height: 500,
    
        xAxis: {
            label: {
                style: { fontSize: 10 },
                autoRotate: true,
                autoHide: false
            }
        },

        yAxis: {
             style: { 
                fontSize: 12,
                paddingBottom: 30,
                border: "solid 1px #000"
            },
            label: {
                style: { 
                    fontSize: 12,
                    paddingBottom: 30 
                },
                autoHide: false,
                formatter: function formatter(v) {
                    return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                      return ''.concat(s, ',');
                    });
                  }
            }
        },

        label: {
          position: 'top',
        },
      };

      return <Column {...config} />;

};

class DashboardAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount = () => {
        this.props.get_countrys();

        const DataRanger = new Date;
        const toDate = moment(DataRanger).endOf('month').format("YYYY-MM-DD");
        const fromDate = moment(DataRanger).startOf('month').add(-1, 'M').format("YYYY-MM-DD");

     
        const dataInit = {
            countryCode: "BRA",
            from: fromDate, 
            to: toDate
        }

        const dataHistoric = {
            countryCode: "BRA",
        }

        this.props.financial(dataInit)
        this.props.financial_historic(dataHistoric)
        


    }

    renderCallBack(arr) {

        if (arr == undefined) {
            return (
                <p className="LoadingCard LabelG">
                    <Icon type="loading" />
                </p>
            )
        } else if (arr && arr.length == 0) {
            return (
                <p className="LoadingCard LabelG noDateView">
                    <Icon type="inbox" />
                    <span className="noDate">{I18n.get("Sem informações")}</span>
                </p>
            );
        }

    }

    handleFilters = (data) =>{
       
        this.props.financial(data)

        const dataHistoric = {
            countryCode: data.countryCode
        }

        this.props.financial_historic(dataHistoric)

    }

    render() {

        const { financial,financialHistoric } = this.props.bp;


        const dataArea = financialHistoric && financialHistoric.map((item) =>{
            return {
                type: item.type,
                value:  item.value,
                month: moment(item.month).format("MM/YYYY")
            }
        });

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/dashboard" className="is-black">{I18n.get("Dashboard")} B</NavLink></li>
                    </ul>
                </nav>

                <Row>
                    <Col sm={24}>
                        <CardSimple>
                            <FormFilterDash
                                filters={this.props.bp && this.props.bp.adminCountrys}
                                action={this.handleFilters}
                                task={this.state.task}
                            />
                        </CardSimple>
                    </Col>
                </Row>
            
            
                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>
                            {I18n.get("Histórico Anual")}                            
                        </TitleBorder>
                    </Col>
                </Row>

                <Row>
                    <Col sm={24}>
                        <CardDisplay>
                            { financialHistoric && chartFinancialArea(dataArea) }                            
                        </CardDisplay>
                    </Col>
                </Row>


                 <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>                            
                            {I18n.get("Total Período")}    
                        </TitleBorder>
                    </Col>
                </Row>

                <Row type="flex" style={{ marginBottom: "40px" }}>
                   <Col md={4} style={{marginBottom: "20px"}}>
                        <CardDisplay>
                            <h2 className="title-span">{I18n.get("GMV")}
                            </h2>

                            <p className="NumberCard">
                                {formatCurrency(financial && financial.totalGMV) || 0}
                            </p>                                    
                        </CardDisplay>
                    </Col>
                    <Col md={4} style={{marginBottom: "20px"}}>
                        <CardDisplay>
                            <h2 className="title-span">{I18n.get("Profit")}
                            </h2>

                            <p className="NumberCard">
                                {formatCurrency(financial && financial.totalPROFIT) || 0}
                            </p>                                    
                        </CardDisplay>
                    </Col>
                    <Col md={4} style={{marginBottom: "20px"}}>
                        <CardDisplay>
                            <h2 className="title-span">{I18n.get("GMV Projetos")}
                            </h2>

                            <p className="NumberCard">
                                {formatCurrency(financial && financial.projectsGMV) || 0}
                            </p>                                    
                        </CardDisplay>
                    </Col>
                    <Col md={4} style={{marginBottom: "20px"}}>
                        <CardDisplay>
                            <h2 className="title-span">{I18n.get("Prof. Projetos")}
                            </h2>

                            <p className="NumberCard">
                                {formatCurrency(financial && financial.projectsPROFIT) || 0}
                            </p>                                    
                        </CardDisplay>
                    </Col>
                     <Col md={4} style={{marginBottom: "20px"}}>
                        <CardDisplay>
                            <h2 className="title-span">{I18n.get("GMV Extras")}
                            </h2>

                            <p className="NumberCard">
                                {formatCurrency(financial && financial.extrasGMV) || 0}
                            </p>                                    
                        </CardDisplay>
                    </Col>
                    <Col md={4} style={{marginBottom: "20px"}}>
                        <CardDisplay>
                            <h2 className="title-span">{I18n.get("Prof. Extras")}
                            </h2>

                            <p className="NumberCard">
                                {formatCurrency(financial && financial.extrasPROFIT) || 0}
                            </p>                                    
                        </CardDisplay>
                    </Col>
                </Row>

                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>                            
                            {I18n.get("Total por Cliente")}  
                        </TitleBorder>
                    </Col>
                </Row>

                <Row>
                    <Col sm={24}>
                        <CardDisplay>
                            
                            { financial?.financialClient && chartFinancialColumnGrouped(financial.financialClient) }

                            { !(financial?.financialClient) &&  noData() }

                            {/* {this.renderCallBack(financialClientData)} */}
                        </CardDisplay>
                    </Col>
                </Row>



                 <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>
                            {I18n.get("Total por Parceiro")} 
                        </TitleBorder>
                    </Col>
                </Row>

                <Row>
                    <Col sm={24}>
                        <CardDisplay>
                           
                        { financial?.financialPartner && chartFinancialColumnGrouped(financial.financialPartner) }

                        { !(financial?.financialPartner) &&  noData() }

                            {/* {this.renderCallBack(financialPartnerData)} */}
                        </CardDisplay>
                    </Col>
                </Row>

                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>                           
                           {I18n.get("Total por Marca")} 
                        </TitleBorder>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <CardDisplay>

                        { financial?.financialClientBrand && chartFinancialColumnGrouped(financial.financialClientBrand) }

                        { !(financial?.financialClientBrand) &&  noData() }
                           
                            {/* {this.renderCallBack(financialAveragePartnerData)} */}
                        </CardDisplay>
                    </Col>
                </Row>




                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>                            
                            {I18n.get("Total por Usuário")} 
                        </TitleBorder>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <CardDisplay>
                            
                        { financial?.financialClientUser && chartFinancialColumnGrouped(financial.financialClientUser) }

                        { !(financial?.financialClientUser) &&  noData() }

                        {/* {this.renderCallBack(financialClientUserData)} */}

                        </CardDisplay>
                    </Col>
                </Row>


                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>                            
                            {I18n.get("Clientes vs Parceiros")} 
                        </TitleBorder>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <CardDisplay>
                            {financial && financial.financialClientVsPartner.length > 0 &&
                             <Heatmap 
                             data={financial && financial.financialClientVsPartner || []}
                             xField='clientName'
                             yField='partnerName'
                             colorField= 'value'
                           
                             label={{
                                 offse:"-2",
                                 style:{
                                     fill: '#fff',
                                     shadowBlur: 2,
                                     shadowColor: 'rgba(0, 0, 0, .45)',
                                    }
                                    
                                }
                            }
                            />
                        }
                        </CardDisplay>
                    </Col>
                </Row>
            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdmin);
