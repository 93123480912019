import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Button, message, Upload, Icon } from "antd";

import * as S from "./styles";

export const UploadLogo = ({
  defaultImg,
  imgUploadStatus,
  fileToUpload,
  styleButton,
  accept,
}) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [imageUrlInternal, setImageUrlInternal] = useState("");

  const validateFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        if (file.size <= 5000000) {
          setImageUrlInternal(_loadedImageUrl);
          setFile(file);
          imgUploadStatus("ok");
          fileToUpload(file);
        } else {
          imgUploadStatus("error");
        }
      });
    });
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const removeImage = () => {
    setImageUrlInternal("");
    setFile(null);
    fileToUpload(null);
    imgUploadStatus("removed");
  };

  useEffect(() => {
    if (defaultImg) {
      setImageUrlInternal(defaultImg);
    }
  }, [defaultImg]);

  return (
    <>
      <S.ContentUploadLogo>
        {imageUrlInternal ? (
          <S.ButtonRemove
            onClick={removeImage}
            className="button-remove-upload"
          >
            <Icon type="delete" />
            {I18n.get("Remove")}
          </S.ButtonRemove>
        ) : null}
        <S.ButtonUpload styleButton={styleButton}>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={(file) => validateFile(file)}
            accept={accept}
          >
            {imageUrlInternal ? (
              <img
                src={imageUrlInternal}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </S.ButtonUpload>
      </S.ContentUploadLogo>
    </>
  );
};
