import React from "react";
import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";
import ReviewStep1 from "./_project-review-1";
import ReviewStep3 from "./_project-review-3";

import FormatCurrency from "../ui/format-currency";

class ReviewStep2 extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  getProductName() {
    var name = "";

    if (!this.props.client.brands) return name;

    this.props.client.brands.forEach((brand) => {
      brand.products.forEach((product) => {
        if (product.id == this.props.productId) {
          name = `${brand.name} - ${product.name}`;
        }
      });
    });

    return name;
  }

  getBundle() {
    return this.props.bundle || {};
  }

  getVariation() {
    const bundle = this.getBundle();

    const item =
      bundle.variations &&
      bundle.variations.filter((item) => item.id == this.props.variationId)[0];

    return item || {};
  }

  getSpecialim(code) {
    if (!code) return {};
    if (!this.props.bp.assetSpecialisms) return {};

    const item = this.props.bp.assetSpecialisms.filter((s) => s.code == code);

    if (item && item.length) {
      return item[0];
    } else {
      return {};
    }
  }

  getAssetType(code) {
    if (!code) return {};
    if (!this.props.bp.assetTypes) return {};

    const item = this.props.bp.assetTypes.filter((t) => t.code == code);

    if (item && item.length) {
      return item[0];
    } else {
      return {};
    }
  }

  render() {
    let assetTax = this.props.client?.customization?.assetTax ?? 0;

    let currency = this.props.currency;

    const bundle = this.getBundle();
    const variation = this.getVariation();

    const assetSpecialisms =
      bundle.specialism &&
      bundle.specialism.map((item) => this.getSpecialim(item).label);
    const assetTypes =
      bundle.assetType &&
      bundle.assetType.map((item) => this.getAssetType(item).label);

    return (
      <div>
        <h1 className="title-bpool small">{I18n.get("Escopo Criativo")}</h1>

        <div className="card reviewed-card">
          <div className="card-content">
            <h1 className="title-bpool medium">
              {/* {bundle.title} */}
              {I18n.get("Pacote customizado")}
            </h1>
            <span style={{ backgroundColor: "#F0E2A1" }} className="card-model">
              {I18n.get("Modelo")} {variation.variationType}
            </span>

            <p>
              <strong>{I18n.get("Especialidade:")}</strong>{" "}
              {assetSpecialisms && assetSpecialisms.join(", ")}
            </p>
            <p>
              <strong>{I18n.get("Tipo:")}</strong>{" "}
              {assetTypes && assetTypes.join(", ")}
            </p>

            <br />
            <br />

            <p className="priceScope">
              {I18n.get("Total")}

              <br />
              <span>
                {this.props?.project?.priceClientWithDiscountWithOtherTaxFmt}
              </span>
              <br />
              {/* {this.props?.project?.otherTax > 0
                ? `(${I18n.get("incluyendo gross up")} ${
                    this.props?.project?.otherTax
                  }%)`
                : ""} */}
            </p>
          </div>
        </div>

        {this.props.enableLinkButton && (
          <>
            <Link
              to={`/cliente/projetos/${this.props.match.params.projectId}/passo-2`}
              className="button is-link-to-return"
            >
              {I18n.get("Redefinir")}
            </Link>
          </>
        )}
      </div>
    );
  }
}

export default ReviewStep2;
