import React from 'react';
import { I18n } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { Task } from '../../utils/task';

import { notification } from 'antd';

import MilestoneList from '../shared/milestone-list';
import MilestoneForm from '../shared/milestone-form';

class ScheduleForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            projectId: props.match.params.projectId,
            form: {
                isSubmit: false,
            },
            task: {},
            errors: {}
        };

        this.milestoneFormRef = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.project && this.props.project.task;

        if (!prevState) return;
        if (!task) return;

        var nextState = Object.assign({}, prevState);

        if (task.id != prevState.task.id) {

            nextState.task = task;

            this.setState(nextState);
        }
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            quotationId: this.props.quotation.id,
        };

        return data;
    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    createMilestone(values) {

        const { projectId } = this.props.match.params;

        var data = {
            projectId,
            quotationId: this.props.quotation.id,
            ...values,
        };

        this.props.create_universal_quotation_milestone(data)
            .then(({ task, errors }) => {

                if (task.result) {

                    notification.success({
                        message: I18n.get(I18n.get('Adicionado com sucesso!')),
                        description: I18n.get('Macro entrega foi adicionada'),
                        duration: 3
                    });


                    if (this.milestoneFormRef && this.milestoneFormRef.current) {
                        this.milestoneFormRef.current.resetFields();
                    } 
                }
                
                this.setState({ errors: errors });

            });
    }

    deleteMilestone(milestoneId) {

        const { projectId } = this.props.match.params;

        var data = {
            projectId,
            quotationId: this.props.quotation.id,
            id: milestoneId,
        };

        this.props.delete_universal_quotation_milestone(data)
            .then(({ task, errors }) => {

                if (task.result) {

                    notification.success({
                        message: I18n.get('Removido com sucesso!'),
                        description: I18n.get('Macro entrega foi removida'),
                        duration: 3
                    });
                }

                this.setState({ errors: errors });

            });

    }

    render() {

        const { quotation = {} } = this.props;

        const { milestones = [] } = quotation;

        const canEdit = this.props.auth.is_partner && quotation.isOpen;

        return (
            <div>

                <h1 className="title is-5">{I18n.get("Cronograma")}</h1>

                <br /><br />

                <div className="container-calendar">

                    <div style={{ padding: '1rem' }}>

                        {canEdit && <>

                            <p>
                                {I18n.get('Por favor defina de 2 a 5 macro entregas que serão necessárias neste projeto com uma estimativa de data para cada um destes momentos. Isto servirá de suporte para as avaliações que serão feitas durante o projeto.')}
                            </p>

                            <MilestoneForm  ref={this.milestoneFormRef} milestones={milestones} onCreate={this.createMilestone.bind(this)} disabled={!canEdit} />

                        </>}

                        <MilestoneList {...this.props} loading={{}} milestones={milestones} onDelete={this.deleteMilestone.bind(this)} canDelete={canEdit} />


                    </div>

                </div>

                <br />
                <br />
            </div>
        );
    }
}

export default withRouter(ScheduleForm);
