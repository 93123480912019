import React from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';

import iconCriacao from '../../image/icot1-dark.svg';
class Review extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    getExpertise() {

        if (this.props.sameCategoryOnly) {
            return '.';
        }
        else {
            return ' ' + this.getCategories();
        }
    }

    getCategories() {

        if (!this.props.bp.sectorsFlat) return [];
        if (this.props.sameCategoryOnly) return [];
        if (!this.props.categoryIds) return [];


        return this.props.categoryIds && this.props.categoryIds.map((categoryId) => {

            const category = this.props.bp.sectorsFlat.filter((item) => item.code == categoryId)[0];

            return category.label;
        });
    }

    render() {

        const step = this.props.isCreative ? 4 : 3;

        const hasPartner = this.props.partnerId || this.props.selectPartnerId;

        
        let partnerWorked = this.props?.preferredPartners && this.props?.preferredPartners.find(partner => partner.code == this.props?.alreadyWorkedPartnerId);

        let selectedPartner = this.props?.preferredPartners && this.props?.preferredPartners.find(partner => partner.code == this.props?.selectPartnerId);


        return (
            <div>
                <h1 className="title-bpool small">{I18n.get("Sobre o Parceiro")}</h1>

                <div className="card-box">
                    <div className="card-row">
                        <div className="card">
                            <div className="card-intro-icon not-for-mobile">
                                <img src={iconCriacao} style={{width:"27px"}} width="80"  />
                            </div>
                            <div className="card-content">
                                <p>
                                    <strong>{I18n.get("Parceiro:")}</strong>
                                    <span className="flag-yellow">
                                        {hasPartner && this.props.partnerName}
                                        {!hasPartner && <text>{I18n.get("A definir")}</text>}
                                    </span>
                                </p>
                                {!hasPartner &&
                                    <p>
                                        <strong>{I18n.get("Perfil:")} </strong>

                                        {this.props.sameCategoryOnly && <span>{I18n.get("Busco somente parceiros criativos com expertise na categoria da minha marca.")}</span>}

                                        {!this.props.sameCategoryOnly && <span>{I18n.get("Busco parceiros criativos com expertise nas categorias:")} {this.getCategories().join(', ')} </span>}

                                        
                                    </p>}
                                {partnerWorked && 
                                    <p><b>{partnerWorked.label}</b> {I18n.get("deve estar entre as opções.")}</p>
                                }
                                {selectedPartner &&
                                    <p> 
                                        <b>{selectedPartner.label}</b> <br />
                                        <em>{I18n.get("Você selecionou um parceiro no qual já trabalhou.")}</em>
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.enableLinkButton && <>

                    <Link to={`/cliente/projetos/${this.props.match.params.projectId}/passo-${step}`} className="button is-link-to-return">{I18n.get("Redefinir")}</Link>

                </>}
            </div>
        );
    }
}

export default Review;
