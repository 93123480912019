import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { AUTH_API } from "../../../../../actions/auth/actions";
import Main from "../../../../components/UI/Main";
import Loading from "../../../../components/UI/Loading";
import { Unlogged } from "../../../../components/UI/Unlogged";

import * as S from "./styles";

export const Completed = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const redirectToLogin = async () => {
    setTimeout(() => {
      history.push({
        pathname: "/registration-flow/login",
      });
    }, 5000);
  };

  const init = async () => {
    await redirectToLogin();

    localStorage.removeItem("@BPOOL-registration-flow-user");
    localStorage.removeItem("@BPOOL-registration-flow");
    dispatch(AUTH_API.logout());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Unlogged>
      <div className="container">
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 6 }}>
            <Main bgColor="#fff" padding="30px">
              <Row>
                <Col xs={24}>
                  <S.RowCenter>
                    <S.TitleWithIcon>
                      <S.H4>{I18n.get("Registration Completed")}</S.H4>
                    </S.TitleWithIcon>
                  </S.RowCenter>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <S.ContentLoader>
                    <Loading sizeIcon={30} align="center" />
                  </S.ContentLoader>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <S.Text>
                    {I18n.get(
                      "Congratulations! your registration has been completed. You will now be redirected to login."
                    )}
                  </S.Text>
                </Col>
              </Row>
            </Main>
          </Col>
        </Row>
      </div>
    </Unlogged>
  );
};
