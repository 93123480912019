import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import SelectSearchField from '../../form/select-search-field';
import CheckboxMultiField from '../../form/checkbox-multi-field';
import RadioMultiField from '../../form/radio-multi-field';
import SelectField from '../../form/select-field';

import PartnerContactForm from '../_partner-contact-form';

import constants from '../../../constants';

import { Modal, Button, List } from 'antd';

import { scrollToTop } from '../../../utils/ui';

import FieldIsOptionalMark from '../../ui/field-is-optional-mark';

import './_bpitch-partner.css';

class DetailsForm extends React.Component {
    constructor(props) {

        super(props);

        const { bPitch = {}, client = {}, project = {} } = props;

        const { selectPartnerIds, sameCategoryOnly, categoryIds = [], specificRegionOnly, specificRegion } = project;

        this.state = {
            fields: {
                selectPartnerIds: {
                    onChange: this.onChangeFormCheckboxMulti.bind(this, 'selectPartnerIds'),
                    // onBlur: this.onSave.bind(this, 'selectPartnerIds'),
                    successIcon: false,
                    markAsOptional: true
                },
                sameCategoryOnly: {
                    onChange: this.onChangeForm.bind(this, 'sameCategoryOnly'),
                    // onBlur: this.onSave.bind(this, 'sameCategoryOnly'),
                    successIcon: false,
                    values: [
                        { code: 'true', label: I18n.get("Sim") },
                        { code: 'false', label: I18n.get("Não") },
                    ],
                    markAsRequired: true
                },
                categoryIds: {
                    onChange: this.onChangeFormValue.bind(this, 'categoryIds'),
                    isMulti: true,
                    // onBlur: this.onSave.bind(this, 'categoryIds'),
                    successIcon: false,
                    markAsOptional: true
                },
                specificRegionOnly: {
                    onChange: this.onChangeForm.bind(this, 'specificRegionOnly'),
                    // onBlur: this.onSave.bind(this, 'specificRegionOnly'),
                    successIcon: false,
                    values: [
                        { code: 'true', label: I18n.get("Preciso de parceiro localizado numa praça especifica") },
                        { code: 'false', label: I18n.get("Aberto a qualquer região / trabalho remoto") },
                    ],
                    markAsRequired: true
                },
                specificRegion: {
                    onChange: this.onChangeForm.bind(this, 'specificRegion'),
                    // onBlur: this.onSave.bind(this, 'specificRegion'),
                    values: constants.STATES_AND_REGIONS,
                    markAsRequired: true
                },
            },
            errors: {},
            form: { selectPartnerIds, sameCategoryOnly, categoryIds, specificRegionOnly, specificRegion },
            task: {},
            submit_task: {}
        }

        this.partnerContactFormRef = React.createRef();
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.fields).forEach((item) => {

                if (this.props[item]) {
                    nextState.form[item] = this.props[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving() );
    }

    onChangeFormValue(prop, value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving() );
    }


    onChangeFormCheckboxMulti(prop, e) {

        const value = e.target.value;
        const checked = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (checked) {
                nextState.form[prop] = [...nextState.form[prop], value];
            }
            else {
                nextState.form[prop] = nextState.form[prop].filter((v) => v !== value);
            }

            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving() );
    }


    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.state.form
        };

        return data;
    }

    onSaving(){

        clearTimeout(this.t);
        this.t = setTimeout(() => this.onSave(), 1000)
    }

    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_bpitch_partner(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    createPartnerContact(values) {

        const { projectId } = this.props.match.params;

        var data = {
            projectId,
            ...values,
        };

        this.props.create_partner_contact(data)
            .then(({ task, errors }) => {

                if (task.result) {

                    if (this.partnerContactFormRef && this.partnerContactFormRef.current) {
                        this.partnerContactFormRef.current.resetFields();
                    }

                    this.setState({ errors: errors, onAddPartnerContact: null })
                }
                else {

                    this.setState({ errors: errors });
                }

            });
    }

    deletePartnerContact(partnerContactId) {

        const { projectId } = this.props.match.params;

        var data = {
            projectId,
            id: partnerContactId,
        };

        this.props.delete_partner_contact(data)
            .then(({ task, errors }) => {

                if (task.result) {

                }

                this.setState({ errors: errors });

            });

    }

    render() {

        const { bp = {}, preferredPartners = [], partnerContacts = [] } = this.props;

        const preferredPartnersValues = preferredPartners.map((item) => ({ ...item, data: item }));

        const { sectorsFlat = [] } = bp;

        const sectorsValues = sectorsFlat.filter((item) => !item.isUnspecified);

        const clientCountry = this.props.clientCountry || {};

        const specificRegionValues = clientCountry.code === 'BRA' ? constants.STATES_AND_REGIONS : constants.STATES_AND_REGIONS_LATAM;

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Participantes BPitch")}</h1>

                <div className="bp-event-section" style={{ marginTop: '3rem', maxWidth: '35rem' }}>

                    <CheckboxMultiField
                        {...this.state.fields.selectPartnerIds}
                        label={I18n.get("Parceiros com o que já trabalhei que gostaria que participassem do pitch")}
                        value={this.state.form.selectPartnerIds}
                        error={this.state.errors.selectPartnerIds}
                        values={preferredPartnersValues}
                    />

                    <br />
                    <div className="field">
                        <label className="label is-small">{I18n.get("Gostaria de adicionar alguém ao Bpitch?")} <FieldIsOptionalMark /></label>
                        <div className="control" style={{ paddingTop: '0.5rem' }}>

                            <ul style={{ fontSize: '80%'  }}>
                                {partnerContacts && partnerContacts.map((item) => {
                                    return (
                                        <li className="partner-contact-item">

                                            <div>
                                                <strong>{item.companyName}</strong> &middot; <span style={{ color: '#696969' }}>{item.name} ({item.email}) {item.phoneNumber} </span>
                                            </div>

                                            <a onClick={this.deletePartnerContact.bind(this, item.id)} className='button is-rounded is-small'>
                                                <span className="icon is-small">
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </a>
                                        </li>
                                    )
                                })}
                                <li style={{ marginTop: '0.5rem' }}>
                                    <Button type='primary' size="small" onClick={(e) => this.setState({ onAddPartnerContact: true })}>{I18n.get("Adicionar Parceiro")}</Button>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <br />

                    <RadioMultiField
                        {...this.state.fields.sameCategoryOnly}
                        label={I18n.get("Expertise no meu mercado de atuação é essencial")}
                        value={this.state.form.sameCategoryOnly}
                        error={this.state.errors.sameCategoryOnly}
                    />

                    <br />

                    <SelectSearchField
                        {...this.state.fields.categoryIds}
                        label={I18n.get("Expertise outros mercados")}
                        value={this.state.form.categoryIds}
                        error={this.state.errors.categoryIds}
                        values={sectorsValues}
                    />

                    <br />

                    <RadioMultiField
                        {...this.state.fields.specificRegionOnly}
                        label={I18n.get("Preciso de Região Específica")}
                        value={this.state.form.specificRegionOnly}
                        error={this.state.errors.specificRegionOnly}
                    />

                    <br />

                    {new String(this.state.form.specificRegionOnly) == 'true' && <>

                        <SelectField
                            {...this.state.fields.specificRegion}
                            label={I18n.get("Região Específica")}
                            value={this.state.form.specificRegion}
                            error={this.state.errors.specificRegion}
                            values={specificRegionValues}
                        />

                        <br />
                    </>}

                </div>

                <Modal visible={this.state.onAddPartnerContact} footer={null} onCancel={(e) => this.setState({ onAddPartnerContact: null })}>

                    <PartnerContactForm ref={this.partnerContactFormRef} {...this.props} errors={this.state.errors} onCreate={this.createPartnerContact.bind(this)} />

                </Modal>
            </div >
        );
    }
}

export default DetailsForm;
