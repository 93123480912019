import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import TextField from '../form/text-field';
import RadioMultiField from '../form/radio-multi-field';
import CheckboxMultiField from '../form/checkbox-multi-field';
import TextAreaField from '../form/textarea-field';

import constants from '../../constants';

import { scrollToTop } from '../../utils/ui';

class ProductForm extends React.Component {
    constructor(props) {

        super();

        const customEvent = props.customEvent || {};

        this.state = {
            fields: {
                productCompetitors: {
                    label: I18n.get("Quais os principais concorrentes?"),
                    onChange: this.onChangeForm.bind(this, 'productCompetitors'),
                    // onBlur: this.onSave.bind(this),
                },
                productProsAndCons: {
                    label: I18n.get("Quais são os pontos favoráveis e desfavoráveis em relação à concorrência?"),
                    onChange: this.onChangeForm.bind(this, 'productProsAndCons'),
                    // onBlur: this.onSave.bind(this),
                },
                productConcept: {
                    label: I18n.get("Qual o conceito e posicionamento do seu produto?"),
                    onChange: this.onChangeForm.bind(this, 'productConcept'),
                    // onBlur: this.onSave.bind(this),
                },
            },
            errors: {},
            form: { ...customEvent },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const customEvent = this.props.customEvent || {};

            Object.keys(this.state.form).forEach((item) => {

                if (item in this.state.fields) {
                    return; // local state is king
                }

                nextState.form[item] = customEvent[item];
            });

            this.setState(nextState);
        }
    }

    componentWillUnmount(){
        this.props.update_custom_event(this.getData())
    }


    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving() );
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.props.customEvent,
            ...this.state.form
        };

        return data;
    }

    onSaving(){

        clearTimeout(this.t);
        this.t = setTimeout(() => this.onSave(), 1000)
    }


    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_custom_event(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }


    render() {

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Produto")}</h1>

                <div className="bp-event-section">

                    <TextAreaField {...this.state.fields.productCompetitors} value={this.state.form.productCompetitors} error={this.state.errors.productCompetitors} />

                    <br />

                    <TextAreaField {...this.state.fields.productProsAndCons} value={this.state.form.productProsAndCons} error={this.state.errors.productProsAndCons} />

                    <br />

                    <TextAreaField {...this.state.fields.productConcept} value={this.state.form.productConcept} error={this.state.errors.productConcept} />

                </div>

            </div>
        );
    }
}

export default ProductForm;
