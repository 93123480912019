import React from 'react';

import { I18n } from 'aws-amplify';

import Modal from '../ui/modal';

import ReferenceDetails from './_reference-details';

import TableReference from '../../modules/Admin/components/tableReference';

class Cmp extends React.Component {

    constructor(props) {

        super();

        this.state = {
            selected: null,
        };

        this.select = this.onSelect.bind(this);
        this.closeModal = this.onCloseModal.bind(this);
    }

    onSelect = (item, e) => {
        this.setState({ selected: item });
    }

    onCloseModal(e) {
        e.preventDefault();

        this.setState({ selected: null });
    }

    render() {

        if (!this.props.bp) return null;
        if (!this.props.bp.partner) return null;

        const item = this.props.bp.partner;

        return (
            <section>
                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Referências")}</h3>

                        <div className="content">

                            <TableReference
                                data={item && item.references}
                                viewReference={this.onSelect}

                            />
                        </div>
                    </div>
                </div>

                <Modal isOpen={!!this.state.selected} closeModal={this.closeModal}>

                    <ReferenceDetails item={this.state.selected} />

                </Modal>
            </section>
        );
    }
}

export default Cmp;
