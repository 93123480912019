import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { CLIENT_API } from '../../actions/client/actions';

import { ClientStatus } from '../../model';

import { Task } from '../../utils/task';

import FillForm from '../page-templates/fillForm';

import MasterUsersForm from './master-users-form';

import DownloadButton from '../form/download-button-field';

import Steps from './steps';

import NakedModal from '../ui/naked-modal';

import Terms from './_terms';

import CheckboxField from '../form/checkbox-field';
import TextField from '../form/text-field';
import SubmitButtonField from '../form/submit-button-field';

import { scrollToTop } from '../../utils/ui';

const mapStateToProps = state => {
    return Object.assign(state, state.client);
};

const mapDispatchToProps = dispatch => {
    return {
        update_terms: data => {
            return dispatch(CLIENT_API.update_terms(data));
        },
    };
};

class ClientSignupStep6 extends React.Component {

    constructor(props) {

        super();

        this.state = {
            termsIsOpen: false,
            form: {
                terms: {
                    label: I18n.get("Li e estou de acordo"),
                    value: props.terms,
                    checked: props.terms,
                    onChange: this.onChangeFormCheckbox.bind(this, 'terms'),
                    error: props.errors.terms,
                    successIcon: false,
                },
                delegateTerms: {
                    placeholder: I18n.get("E-mail Responsável"),
                    value: props.delegateTerms,
                    onChange: this.onChangeForm.bind(this, 'delegateTerms'),
                    error: props.errors.delegateTerms,
                    successIcon: false,
                },
            },
            task: {},
            errors: {},
            submit_task: {},
            terms_task: {},
            showDelegate: false
        };

        this.closeModal = this.onCloseModal.bind(this);
    }

    componetDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.client && this.props.client.task;

        if (!prevState) return;
        if (!task) return;

        var nextState = Object.assign({}, prevState);

        if (task.id != prevState.task.id) {

            nextState.task = task;

            this.setState(nextState);
        }
    }

    onCloseModal(e) {

        if (e) e.preventDefault();

        this.setState({ termsIsOpen: false });
    }

    onCompleteClick(e) {

        e.preventDefault();

        if (this.props.terms) {

            var task = new Task();

            task.start();

            this.setState({ submit_task: task });

            const form = this.refs.form;

            // this.setState({ termsIsOpen: true });

            form.submitForm(e);
        }
        else {

            this.setState((prevState) => {

                var nextState = Object.assign({}, prevState);

                nextState.termsIsOpen = true;

                nextState.form.terms.checked = this.props.terms;
                nextState.form.terms.value = this.props.terms;

                return nextState;
            });
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onChangeFormCheckbox(prop, e) {

        const checked = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].checked = checked;
            nextState.form[prop].value = checked;

            return nextState;
        });
    }

    getData() {

        const data = {
            clientId: this.props.clientId,
        };

        return data;
    }

    onSubmitForm(delegate, e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ terms_task: task });

        var data = {}

        if (delegate) {

            data = {
                clientId: this.props.clientId,
                delegateTerms: this.state.form.delegateTerms.value,
            }
        }
        else {

            data = {
                clientId: this.props.clientId,
                terms: this.state.form.terms.value
            }

        }

        this.props.update_terms(data)
            .then(({ task, errors }) => {

                this.setState({ terms_task: task, errors: errors });

                if (task.result) {

                    // this.closeModal();

                    const form = this.refs.form;

                    form.submitForm(e);
                }
            });

    }

    render() {

        var errorMap = {
        };

        Object.keys(this.props.client.errors).map((item) => {

            const error = this.props.client.errors[item];

            const key = error.step || 'global';

            if (!errorMap[key]) {
                errorMap[key] = []
            }

            errorMap[key].push(error.errorMessage)
        });


        if (this.props.client && this.props.client.status > ClientStatus.Incomplete) {
            return (<Redirect to="/cliente/obrigado" />);
        }

        return (
            <FillForm>
                <div className="hero-body has-background-white">
                    <div className="container">
                        <div className="column is-12">
                            <Steps active={6} />
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-6 content-client">

                    <div className="container client-container-6">

                        <div className="column is-8 is-offset-2">

                            <h1 className="title">
                                <span>5.</span>{I18n.get("Acesso Master")}</h1>

                            <p>{I18n.get("Se você quiser, indique abaixo outros usuários master que terão acesso irrestrito a todos os níveis da plataforma. Serão responsáveis por inclusão ou exclusão de cadastros bem como acessar todo painel de controle, avaliações, gastos por marcas, etc.")} <b>{I18n.get("Como responsável pelo cadastro, você já é usuário Master.")}</b>
                            </p>

                            <br /><br />

                            <MasterUsersForm ref="form" {...this.props} isAdmin={true} />

                            <br />

                            {Object.keys(errorMap).length > 0 && <div className="content is-small">

                                <br /><br />

                                <h4>{I18n.get("Falta mais um pouquinho...")}</h4>

                                {Object.keys(errorMap).map((item) => {

                                    return (
                                        <p>
                                            <span class="icon has-text-danger">
                                                <i class="fas fa-exclamation"></i>
                                            </span>
                                            <Link to={'/cliente/passo-' + item}>{I18n.get("Passo")} {item}</Link>
                                            <ul>
                                                {errorMap[item].map((errorMessage) => (<li>{errorMessage}</li>))}
                                            </ul>
                                        </p>
                                    );
                                })}

                            </div>}

                            <div className="field is-grouped is-grouped-centered">
                                <p className="control is-expanded">
                                    <Link to="/cliente/passo-5" className="button is-black is-outlined is-fullwidth previous">{I18n.get("Voltar")}</Link>
                                </p>
                                <p className="control is-expanded" onClick={this.scrollToTop}>
                                    <SubmitButtonField
                                        className="button is-black is-fullwidth next"
                                        label={I18n.get("FINALIZAR")}
                                        onClick={this.onCompleteClick.bind(this)}
                                        task={this.state.submit_task}
                                        suppressLabel={true}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <NakedModal wide={true} isOpen={this.state.termsIsOpen}>

                    <div className="card" style={{ margin: '0.5rem' }}>
                        <header className="card-header">
                            <p className="card-header-title" style={{ fontSize: '130%' }}>{I18n.get("Termos Contratuais BPool")}</p>
                            <a href="#" onClick={this.closeModal} className="card-header-icon" aria-label="more options">
                                <span className="icon">
                                    <i className="fas fa-times" aria-hidden="true"></i>
                                </span>
                            </a>
                        </header>
                        <div className="card-content" style={{ maxWidth: '960px' }}>
                            <div className="content">

                                <form>

                                    <Terms />
                                    <br />


                                    <p className="pright">
                                        <DownloadButton LinkToDownload="https://bpool.co/termos/Cliente_Termo_Uso_20191101.pdf" text="Download" />
                                    </p>

                                    <br />

                                    <div class="label is-small pright" >
                                        {I18n.get("*Você pode incluir seu próprio email como responsável caso queira analisar posteriormente o Termo de Uso")}
                                    </div>



                                    <div className="columns">
                                        <div className="column is-6">

                                            <CheckboxField {...this.state.form.terms} />

                                            <SubmitButtonField
                                                className="button is-black is-fullwidth next"
                                                label={I18n.get("ACEITAR")}
                                                onClick={this.onSubmitForm.bind(this, false)}
                                                task={this.state.terms_task}
                                                suppressLabel={true}
                                                disabled={!this.state.form.terms.value}
                                            />
                                        </div>
                                        <div className="column is-6" style={{ paddingLeft: '0.5rem' }}>

                                            <TextField {...this.state.form.delegateTerms} />

                                            <div style={{ marginTop: '22px' }} />

                                            <SubmitButtonField
                                                className="button is-black is-fullwidth next"
                                                label={I18n.get("ENVIAR PARA RESPONSÁVEL")}
                                                onClick={this.onSubmitForm.bind(this, true)}
                                                task={this.state.terms_task}
                                                suppressLabel={true}
                                                disabled={!this.state.form.delegateTerms.value}
                                            />

                                            {this.props.delegateTermsSentDate && <p style={{ fontWeight: 'semibold', fontSize: '80%' }}>{I18n.get("Contrato enviado para assinatura ao responsável")} {this.props.delegateTerms} em {this.props.delegateTermsSentDate}.
                                            </p>}

                                        </div>
                                    </div>


                                    <p className="has-text-danger">
                                        <ul>
                                            {Object.keys(this.state.errors).map((item) => {

                                                return (<li>{this.state.errors[item].errorMessage}</li>)
                                            })}
                                        </ul>
                                    </p>


                                </form>

                            </div>
                        </div>
                    </div>

                </NakedModal>


            </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSignupStep6);
