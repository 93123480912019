import React from 'react';

import { I18n } from 'aws-amplify';

import IconDownload from '../../svg/arrow-circle-down-solid.svg';

export default class DownloadButtonField extends React.Component {

    constructor(props) {
        super();
    }

    static defaultProps = {
        btnClassName: 'button bt-bpool',
        style: {}
    }

    render() {

        const link = this.props.LinkToDownload || "";
        const text = this.props.text || "DOWNLOAD";

        return (<div className="field is-narrow">
            {/* {className + " " + btnColor} */}
            <a href={link} target="_blank" className={this.props.btnClassName}>
                {text}
            </a>
        </div>);

    }

}
