import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useInProgress } from "../../../../hooks/useInProgress/useInProgress.hook";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { Dialog } from "../../../../components/UI/Dialog";
import { Drawer } from "./drawer";

import iconCheck from "./svgs/check.svg";
import * as S from "./styles";

export const RequestProjectCompletion = ({ reloadPage }) => {
  let params = useParams();
  const auth = useSelector((state) => state.auth);
  const { getCompletion, completeProject } = useInProgress();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({ open: false });
  const [openDialog, setOpenDialog] = useState({
    visible: false,
    text: "",
    type: "",
  });

  const completionOrWorkFiles = (files) => {
    let filesCompletion = [];
    let filesWork = [];

    if (files) {
      files.forEach((file) => {
        if (file?.projectCompletionFile) {
          filesCompletion.push(file);
        } else {
          filesWork.push(file);
        }
      });
    }

    return filesCompletion;
  };

  const handleClickFinalizeProject = async () => {
    setIsCompleting(true);
    try {
      console.log("handleClickFinalizeProject");
    } catch (error) {
      console.log("error");
    } finally {
      setIsCompleting(false);
    }
  };

  const loadInfos = async () => {
    setIsLoading(true);
    try {
      const response = await getCompletion({ projectId: params?.projectId });
      if (response?.success) {
        const filesCompletions = completionOrWorkFiles(response?.data?.files);
        setData({ ...response?.data, files: filesCompletions });
      } else {
        throw new Error("Error");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickCompleteProject = async () => {
    setIsCompleting(true);
    try {
      const response = await completeProject(params?.projectId);
      if (response?.success) {
        setOpenDialog({
          visible: true,
          text: I18n.get("Solicitação de aprovação enviada com sucesso!"),
          type: "partner-success",
        });
        await loadInfos();
        reloadPage();
      } else {
        throw new Error("Error");
      }
    } catch (error) {
      console.log("error");
    } finally {
      setIsCompleting(false);
    }
  };

  const handleCloseDrawer = async ({ reload }) => {
    setOpenDrawer({ open: false });
    if (reload) {
      await loadInfos();
      reloadPage();
    }
  };

  useEffect(() => {
    loadInfos();
  }, []);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  if (isLoading) {
    return <></>;
  } else {
    return (
      <>
        {data?.status === 7 ? (
          <S.Wrapper>
            {auth?.is_client_or_client_user ? null : (
              <ButtonBpool
                text={I18n.get("Solicitar a conclusão projeto")}
                className=""
                theme="primary"
                loading={isCompleting}
                onClick={
                  data?.files?.length || data?.projectCompletionUrls?.length
                    ? () => handleClickCompleteProject()
                    : () => setOpenDrawer({ open: true })
                }
              />
            )}
          </S.Wrapper>
        ) : data?.status === 9 ? (
          <S.Wrapper>
            {auth?.is_client_or_client_user ? (
              <Link
                to={`/cliente/projetos/v2/${params?.projectId}/in-progress/finalize`}
              >
                <ButtonBpool
                  text={I18n.get("Aprovar finalização do projeto")}
                  className=""
                  theme="primary"
                  loading={false}
                  onClick={() => true}
                />
              </Link>
            ) : (
              <S.AlertCompletion>
                <img src={iconCheck} alt="icon check" />
                <span>{I18n.get("Conclusão do projeto solicitado")}</span>
              </S.AlertCompletion>
            )}
          </S.Wrapper>
        ) : null}

        <Drawer
          open={openDrawer.open}
          handleCloseDrawer={handleCloseDrawer}
          urlsCompletion={
            data?.projectCompletionUrls && data?.projectCompletionUrls?.length
              ? data?.projectCompletionUrls
              : []
          }
          filesCompletionProject={
            data?.files && data?.files?.length ? data?.files : []
          }
          projectId={params?.projectId}
          reload={reloadPage}
          soliciteConclusion={handleClickCompleteProject}
        />

        <Dialog
          visible={openDialog.visible}
          text={openDialog.text}
          loading={false}
          buttons={
            openDialog.type === "client-confirm"
              ? {
                  cancel: {
                    text: I18n.get("Voltar"),
                    func: () =>
                      setOpenDialog({ visible: false, text: "", type: "" }),
                  },
                  ok: {
                    text: I18n.get("Confirmar"),
                    func: () => handleClickFinalizeProject(),
                  },
                }
              : {
                  ok: {
                    text: I18n.get("Voltar"),
                    func: () =>
                      setOpenDialog({ visible: false, text: "", type: "" }),
                  },
                }
          }
        />
      </>
    );
  }
};
