import styled from "styled-components";

export const ContentUploadLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input[type="file"] {
    display: none;
  }
  label {
    padding: 20px 10px;
    width: 200px;
    background-color: #333;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin-top: 10px;
    cursor: pointer;
  }
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-align: center;

  color: #4a4a4a;
  ${(props) => (props.statusUploadImg === "error" ? "color: #ff0000;" : null)};
  ${(props) => (props.statusUploadImg === "ok" ? "color: #4a4a4a;" : null)};
`;

export const ButtonUpload = styled.div`
  width: ${(props) =>
    props.styleButton?.width ? props.styleButton?.width : "113px"};
  height: ${(props) =>
    props.styleButton?.height ? props.styleButton?.height : "113px"};
  border-radius: 50%;

  .avatar-uploader {
    width: 100%;
    height: 100%;
    margin-right: 10px;

    .ant-upload {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    img {
      object-fit: cover
      height: 100%;
    }
  }

  button {
    font-size: 12px;
    cursor: pointer;
    background: #1883ff;
    color: #ffffff;
    border-radius: 12px;
    font-weight: 600;
    border: none;
    padding: 0 5px;
    line-height: 30px;

    :hover,
    :active,
    :focus {
      background: #1883ff;
      color: #ffffff;
    }
  }
`;

export const ButtonRemove = styled.button`
  background: transparent;
  border: none;
  padding: 0%;
  cursor: pointer;
  position: absolute;
  margin-left: -185px;
  margin-top: 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #ff0000;

  i {
    padding-right: 3px;
  }
`;
