import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { useBpayAdmin } from "../../../../../../../hooks/useBpay/useBpayAdmin.hook";
import { Drawer } from "../../../../../../../components/UI/Drawer";
import { ButtonBpool } from "../../../../../../../components/UI/ButtonBpool";
import { Input } from "../../../../../../../components/UI/Input";
import { Dialog } from "../../../../../../../components/UI/Dialog";
import { NotificationBPool } from "../../../../../../../components/UI/NotificationBPool";
import * as S from "../styles";

export const DrawerNFSe = ({ drawer, handleCloseDrawer }) => {
  const { updateNFSe } = useBpayAdmin();
  const [nfseValue, setNfseValue] = useState("");
  const [isSendingNFSe, setIsSendingNFSe] = useState(false);

  const handleOpenDrawer = async ({ nfse }) => {
    if (nfse) {
      if (nfse === "---") {
        setNfseValue("");
      } else {
        setNfseValue(nfse);
      }
    } else {
      setNfseValue("");
    }
  };

  const handleUpdateNFSe = async () => {
    try {
      setIsSendingNFSe(true);
      const objSend = {
        id: drawer?.bpayId,
        nfSe: nfseValue,
      };
      await updateNFSe(objSend);
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("NFSe atualizada com sucesso"),
        duration: 3,
      });
      handleCloseDrawer();
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao atualizar a NFSe."),
        duration: 3,
      });
    } finally {
      setIsSendingNFSe(false);
    }
  };

  useEffect(() => {
    if (drawer?.open === true) {
      handleOpenDrawer({
        nfse: drawer?.nfse,
      });
    }
  }, [drawer?.open]);

  return (
    <>
      <Drawer
        open={drawer.open}
        handleClose={handleCloseDrawer}
        width={525}
        buttons={
          <>
            <ButtonBpool
              text={I18n.get("Fechar")}
              onClick={handleCloseDrawer}
              theme="tertiary"
            />
          </>
        }
      >
        <S.H3>
          {drawer?.nfse
            ? drawer?.nfse === "---"
              ? I18n.get("Adicionar NFSe")
              : I18n.get("Atualizar NFSe")
            : I18n.get("Adicionar NFSe")}
        </S.H3>

        <Row style={{ borderBottom: "1px solid #e8e8e8" }}>
          <Col xs={24}>
            <S.NfseWrapper>
              <S.Title>
                <h3>{I18n.get("NFSe")}</h3>
              </S.Title>
              <S.NfseContent style={{ marginTop: 24 }}>
                <div style={{ width: 280 }}>
                  <Input
                    placeholder={I18n.get("NFSe")}
                    value={nfseValue}
                    onChange={(value) => setNfseValue(value)}
                    error=""
                  />
                </div>
                <ButtonBpool
                  text={I18n.get(
                    drawer?.nfse
                      ? drawer?.nfse === "---"
                        ? I18n.get("Adicionar")
                        : I18n.get("Atualizar")
                      : I18n.get("Adicionar")
                  )}
                  theme="tertiary"
                  disabled={nfseValue ? false : true}
                  loading={isSendingNFSe}
                  onClick={handleUpdateNFSe}
                />
              </S.NfseContent>
            </S.NfseWrapper>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
