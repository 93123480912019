import styled from "styled-components";
import { Drawer as DrawerAntd } from "antd";

export const Drawer = styled(DrawerAntd)`
  .body-drawer {
    padding: 15px 0 60px 0;
  }
`;

export const ButtonsContent = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 30px 15px;
  background: #fff;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;
