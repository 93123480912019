import React from "react";
import { I18n } from "aws-amplify";
import { Redirect } from "react-router-dom";

import { ClientProjectStatus } from "../../model";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";

import QuotationForm from "./quotation-form";

import Steps from "./steps";

import { scrollToTop } from "../../utils/ui";

import { getQuotationStatus } from "../../utils/extras";

import SelectField from "../form/select-field";

class ProjectDefintionStep3 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      fields: {
        quotationIdx: {
          onChange: this.onChangeQuotation.bind(this),
          successIcon: false,
        },
      },
      form: {
        quotationIdx: -1,
      },
      errors: {},
      task: {},
    };
  }

  componentDidMount() {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_project(data);

    scrollToTop();
  }

  onChangeQuotation(e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["quotationIdx"] = value;

      return nextState;
    });
  }

  submitForm(e) {}

  render() {
    const { partner = {}, project = {}, settings = {} } = this.props;

    const isSelectedPartner =
      project.selectPartner && project.selectPartnerId == partner.partnerId;

    if (project && isSelectedPartner) {
      return (
        <Redirect
          to={`/projetos/${this.props.match.params.projectId}/detalhamento-passo-2`}
        />
      );
    }

    // const invitation =
    //   (partner.projectInvitations &&
    //     partner.projectInvitations.find(
    //       (item) => item.projectId == this.props.match.params.projectId
    //     )) ||
    //   {};

      const invitation =
      (partner.projectInvitations &&
        partner.projectInvitations
          .filter((item) => item.projectId === this.props.match.params.projectId)
          .reverse()
          .find(() => true)) ||
      {};

    const disableQuotation =
      invitation.expired === true ||
      invitation.accepted === false ||
      isSelectedPartner;

    const disableMessaging = settings && !settings.enableMessaging;

    const { eventQuotationHistory = [] } = project || {};

    const quotations =
      eventQuotationHistory &&
      eventQuotationHistory
        .filter((item) => item.id == partner.id)
        .map((item, index) => ({
          code: index,
          label: `${getQuotationStatus(this.props.auth, item)} - ${
            item.updatedOn
          }`,
        }));

    let quotation = {};

    if (
      this.state.form.quotationIdx != "" &&
      this.state.form.quotationIdx >= 0
    ) {
      quotation = eventQuotationHistory[this.state.form.quotationIdx];
    } else {
      quotation =
        this.props.eventQuotations &&
        this.props.eventQuotations.find(
          (item) => item.id == this.props.partner.id
        );
    }

    if (project && project.status == ClientProjectStatus.DefinitionsSet) {
      return (
        <Redirect
          to={`/projetos/${this.props.match.params.projectId}/detalhamento-obrigado`}
        />
      );
    }

    if (
      this.props.auth.is_partner &&
      project &&
      project.partnerHasSubmittedDefinitions
    ) {
      return (
        <Redirect
          to={`/projetos/${this.props.match.params.projectId}/detalhamento-obrigado`}
        />
      );
    }


    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-6">
                <p className="title">
                  {I18n.get("Parceiro Convidado")}
                  <br />
                  <span className="tag is-warning">
                    {project && project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6">
                <Steps
                  active={3}
                  projectId={this.props.match.params.projectId}
                  disabled={disableQuotation ? [3] : []}
                  hidden={disableMessaging ? [5] : []}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="client-step client-step-1"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <div className="container client-container-1">
            <br />
            <br />

            {invitation.accepted === true && (
              <>
                {this.props.auth.is_partner && (
                  <>
                    <p style={{ fontWeight: "bold" }}>
                      {I18n.get(
                        "Insira abaixo uma proposta resumida que atenda o briefing enviado."
                      )}
                    </p>
                    <p style={{ marginTop: "0.50rem", fontSize: "90%" }}>
                      {I18n.get(
                        "Se sua boutique for selecionada, você precisará enviar uma proposta detalhada numa etapa futura."
                      )}
                    </p>
                  </>
                )}

                <br />
                <br />

                <div className="columns">
                  <div className="column is-5">
                    <p className="title">
                      {I18n.get("Orçamento")}

                      {this.state.form.quotationIdx != "" &&
                        this.state.form.quotationIdx >= 0 && (
                          <>&nbsp;-&nbsp;{I18n.get("Histórico")}</>
                        )}
                    </p>
                  </div>

                  <div className="column is-2">
                    <SelectField
                      {...this.state.fields.quotationIdx}
                      label={I18n.get("Histórico de Orçamentos")}
                      value={this.state.form.quotationIdx}
                      error={this.state.errors.quotationIdx}
                      values={quotations}
                    />
                  </div>
                </div>

                <br />
                <br />

                <QuotationForm
                  {...this.props}
                  quotation={quotation}
                  partnerId={this.props.partner.id}
                />
              </>
            )}

            {invitation.accepted === null && (
              <>
                <p>
                  {I18n.get(
                    "Por favor aprove as condições do Briefing para que possa detalhar sua proposta aqui."
                  )}
                </p>
              </>
            )}
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(ProjectDefintionStep3);
