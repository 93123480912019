import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import randomize from "randomatic";
import axios from "axios";
import {
  ChatList,
  ChatListItem,
  Message,
  MessageGroup,
  MessageList,
  MessageText,
  Row,
  SendButton,
  TextComposer,
  TextInput,
  ThemeProvider,
  Title,
  Subtitle,
} from "@livechat/ui-kit";
import { I18n } from "aws-amplify";
import moment from "moment";
import constants from "../../../../constants";
import { getFileExtensionIcon } from "../../../../components/shared/_utils";
import MicRecorder from "mic-recorder-to-mp3";
import ReactAudioPlayer from "react-audio-player";
import {
  ChatListContainer,
  ConversationContainer,
  FlexRowDiv,
  LoadingMessagesContainer,
  HeaderChatListContacts,
  CloseChat,
  InputSearchContacts,
  HeaderChatMessage,
  HeaderChatLogo,
  HeaderChatContentInfos,
  HeaderChatInfos,
  HeaderChatActions,
  ButtonImg,
  MessageListContainer,
  ModalBackground,
  TextComposerContainer,
  LoadingIconAudioContainer,
} from "../../../../components/shared/chat-components";

import { notification, Avatar } from "antd";
import { v4 as uuidv4 } from "uuid";
import Config from "../../../../config";

import { CHAT_API } from "../../../../actions/chat/actions";

import ChatFileUpload from "../../../../modules/components/ChatFileUpload";
import IconButton from "../../../../components/form/icon-button";
import UploadFileSvg from "../../../../svg/upload-file-2.svg";
import MicrophoneIcon from "../../../../svg/icon-microphone.svg";
import MicrophoneIconStop from "../../../../svg/chat-icon-stop-audio.svg";
import ChatIconSearch from "../../../../svg/chat-icon-search.svg";
import ChatIconClose from "../../../../svg/chat-icon-close.svg";
import PopupChatCalendar from "../../../../svg/popup-chat-calendar.svg";
import PopupChatPlus from "../../../../svg/popup-chat-plus.svg";

import calendarIcon from "../../../../image/calendar.jpg";
import meetingIcon from "../../../../image/meeting.jpg";

import * as S from "./styles";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const ChatPartner = ({ onClickCloseModal }) => {
  const chat = useSelector((state) => state.chat);
  const auth = useSelector((state) => state.auth);
  const partner = useSelector((state) => state.partner);
  const get2 = useSelector((state) => state.get2);
  const chatModal = useSelector((state) => state.chatModal);

  const dispatch = useDispatch();

  const [firstChatSelected, setFirstChatSelected] = useState(false);
  const [activeClient, setActiveClient] = useState({
    clientId: false,
    clientName: false,
    logo: "",
  });
  const [activeProject, setActiveProject] = useState({
    projectId: false,
    projectName: false,
  });
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [chatListOpen, setChatListOpen] = useState(true);
  const [activeChat, setActiveChat] = useState({
    topic: "",
    partnerId: "",
    userOther: {
      userOtherId: "",
      userOtherName: "",
      companyName: "",
      companyLogo: "",
    },
  });
  const [mp3Audio, setMp3Audio] = useState({
    isRecording: false,
    blobURL: "",
    isBlocked: false,
    loading: false,
  });
  const [fileUrl, setFileUrl] = useState("");
  const [messagesGroups, setMessagesGroups] = useState([]);
  let sameUser = false;
  let oldUser = false;
  let messagesList = [];

  const [backupChatTopics, setBackupChatTopics] = useState([]);
  const [hasBackupChatTopics, setHasBackupChatTopics] = useState(false);

  const [inputSearch, setInputSearch] = useState("");

  const [isOpenPopupChat, setIsOpenPopupChat] = useState(false);
  const [chatEmpty, setChatEmpty] = useState(true);

  useEffect(() => {
    if (chat.chatTopics.length > 0) {
      setChatEmpty(false);
    } else {
      setChatEmpty(true);
    }
  }, [chat]);

  const chatTopicAll = async (topic) => {
    dispatch(CHAT_API.chat_topic_all(topic));
  };

  const onActiveChatList = async (topic, name, companyName, companyLogo) => {
    setLoadingMessage(true);
    const topicParts = topic.split("/");
    const partnerId = auth.partnerId;
    const userOtherId = topicParts[2];
    const userOtherName = name;

    setActiveChat({
      topic,
      partnerId,
      userOther: { userOtherId, userOtherName, companyName, companyLogo },
    });

    await chatTopicAll(topic);

    setLoadingMessage(false);
  };

  const selectFirstChatTopic = (topic, chatTopics) => {
    const firstChat = chatTopics && chatTopics[0];

    if (!topic && !firstChatSelected && firstChat) {
      onActiveChatList(
        firstChat?.Topic,
        firstChat?.Name,
        firstChat?.CompanyName,
        firstChat?.CompanyLogo
      );

      setFirstChatSelected(true);
    }
  };

  const selectChatTopicByNotification = (topic, chatTopics) => {
    const conversation = chatTopics.find((item) => item.Topic === topic);

    if (conversation) {
      onActiveChatList(
        conversation.Topic,
        conversation.Name,
        conversation.CompanyName,
        conversation.CompanyLogo
      );

      setFirstChatSelected(true);
    }
  };

  const getChatInitial = async () => {
    if (chatModal.topic) {
      selectChatTopicByNotification(chatModal.topic, chat.chatTopics);
    } else {
      if (chat?.chatTopics?.length && !firstChatSelected) {
        selectFirstChatTopic(activeChat.topic, chat.chatTopics);
      }
    }
  };

  const renderMessageGroup = (messages, isOwn) => {
    let textAlign = isOwn
      ? { textAlign: "right", background: "#4699FA", color: "#FFF" }
      : { textAlign: "left", background: "#FFF" };
    let authorName = isOwn
      ? I18n.get("Eu")
      : activeChat.userOther.userOtherName;
    return (
      <MessageGroup className="message-group" isOwn={isOwn} onlyFirstWithMeta>
        {messages.map((message) => {
          let messageDate = moment(message.createdAt);
          let messageShowDate = messageDate.isBefore(new Date(), "day")
            ? messageDate.startOf("hour").fromNow()
            : messageDate.format("HH:mm");

          // ? messageDate.format('DD/MM HH:mm')
          // : messageDate.format('HH:mm');

          if (message.message.search(constants.CHAT_MESSAGE_TYPE_MEET) >= 0) {
            let cleanMessage = message.message.replace(
              constants.CHAT_MESSAGE_TYPE_MEET,
              ""
            );
            return (
              <Message
                key={`message_${message.messageId}`}
                className="message-info"
                // authorName={authorName}
                date={messageShowDate}
                isOwn={isOwn}
              >
                <span>
                  {I18n.get(
                    "Por favor, acesse a videochamada através do link:"
                  )}{" "}
                  <a href={cleanMessage} target="_blank">
                    {I18n.get("VIDEOCHAMADA")}
                  </a>
                </span>
              </Message>
            );
          } else if (
            message.message.search(constants.CHAT_MESSAGE_TYPE_FILE) >= 0
          ) {
            let icon_type = getFileExtensionIcon(message.message);

            let cleanMessage = message.message.replace(
              constants.CHAT_MESSAGE_TYPE_FILE,
              ""
            );
            return (
              <Message
                key={`message_${message.messageId}`}
                className="message-info"
                // authorName={authorName}
                date={messageShowDate}
                isOwn={isOwn}
              >
                <a
                  className="chat-file-link"
                  href={cleanMessage}
                  target="_blank"
                >
                  <span>{I18n.get("Baixe o arquivo clicando aqui:")}</span>{" "}
                  <i className={icon_type}></i>
                </a>
              </Message>
            );
          } else if (
            message.message.search(constants.CHAT_MESSAGE_TYPE_AUDIO) >= 0
          ) {
            let icon_type = getFileExtensionIcon(message.message);

            let cleanMessage = message.message.replace(
              constants.CHAT_MESSAGE_TYPE_AUDIO,
              ""
            );
            return (
              <Message
                key={`message_${message.messageId}`}
                className="message-info"
                // authorName={authorName}
                date={messageShowDate}
                isOwn={isOwn}
              >
                <div style={{ width: 300 }}>
                  <ReactAudioPlayer
                    src={cleanMessage}
                    controls
                    style={{ width: "100%" }}
                  />
                </div>
              </Message>
            );
          } else {
            return (
              <Message
                key={`message_${message.messageId}`}
                className="message-info"
                // authorName={authorName}
                date={messageShowDate}
                isOwn={isOwn}
              >
                <MessageText className="message-text" style={textAlign}>
                  {message.message}
                </MessageText>
              </Message>
            );
          }
        })}
      </MessageGroup>
    );
  };

  const getMessages = () => {
    let messages = chat && chat.chatActiveMessages;
    let internalMessagesGroups = [];

    if (messages && messages.length > 0) {
      messages.map((message, index) => {
        let isOwn = message.from === partner?.username;
        sameUser = message.from === oldUser;
        oldUser = message.from;

        messagesList.push(message);

        if (!sameUser) {
          internalMessagesGroups.push(renderMessageGroup(messagesList, isOwn));
          messagesList = [];
          oldUser = false;
        }

        if (chat.chatActiveMessages.length === index + 1) {
          internalMessagesGroups.push(renderMessageGroup(messagesList, isOwn));
          messagesList = [];
        }
      });
      console.log("CHAT internalMessagesGroups", internalMessagesGroups);
      setMessagesGroups(internalMessagesGroups);
    }
  };

  const sendMessageWs = async ({ topic, message }) => {
    dispatch(CHAT_API.send_chat_message({ topic, message }));
  };

  const sendChatMessage = async (message) => {
    setLoadingMessage(true);

    const topic = activeChat.topic;

    await sendMessageWs({ topic, message });

    if (loadingMessage) {
      setTimeout(() => {
        setLoadingMessage(false);
      }, 100);
    }
  };

  const permissionMic = async () => {
    // Mic
    navigator.getUserMedia(
      { audio: true },
      () => {
        setMp3Audio({
          ...mp3Audio,
          isBlocked: false,
        });
      },
      () => {
        setMp3Audio({
          ...mp3Audio,
          isBlocked: true,
        });
      }
    );
  };

  const onClickSendAudio = async () => {
    await permissionMic();

    if (mp3Audio.isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setMp3Audio({
            ...mp3Audio,
            isRecording: true,
          });
        })
        .catch((e) => console.error(e));
    }
  };

  const getDataUploadAudio = async (dataForm) => {
    const { field, url } = dataForm;

    if (url) {
      sendChatMessage(`${constants.CHAT_MESSAGE_TYPE_AUDIO}${url}`);
    }
  };

  const sendAudioToServer = async (file) => {
    const EndpointGetAuthToUpload = get2
      ? Config.UPLOAD_URL2
      : Config.UPLOAD_URL;

    let fileSplit = file.name.split(".");
    let fileSafe = file.name.replace(/[^a-z0-9]/gi, "-");
    let fileNew = `${fileSafe}_${uuidv4()}`;
    if (fileSplit.length > 1) {
      fileNew = fileNew + "." + fileSplit[fileSplit.length - 1];
    }

    let id = auth.partnerId; //Dependenddo do contexto, será ClientId ou PartnerId ou ProjectId

    let key = `${id}/${fileNew}`; //Caminho final do arquivo - contexto/nome-unico-arquivo.txt

    //Access Token vindo do Cognito
    let authHeader = {
      headers: { Authorization: "Bearer " + auth.jwtToken },
    };

    //EndPoint para pegar autorização para subir o arquivo. passar a chave (key) na queryString
    let preSignUrl = `${EndpointGetAuthToUpload}?k=${key}${
      get2 ? "&t=pleader" : ""
    }`;

    axios.get(preSignUrl, authHeader).then((resp) => {
      let { url, fields } = resp.data;

      let formData = new FormData();

      //Dados recebidos para autorizar o upload do arquivo
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });

      //arquivo

      formData.append("file", file);

      var config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      //tendo sucesso, fazer POST do arquivo com os dados de autorização
      axios
        .post(url, formData, config)
        .then(async (response) => {
          let urlClean =
            "https://" + resp.data.url.split("s3.amazonaws.com/")[1] + "/";

          let dataForm = {
            field: "audio_file",
            url: urlClean + resp.data.fields.key,
          };

          await getDataUploadAudio(dataForm);

          setLoadingMessage(false);
          setMp3Audio({
            ...mp3Audio,
            isRecording: false,
            loading: false,
          });
        })
        .catch((error) => {
          notification.error({
            message: "ops! Algo deu errado.",
            description: "Tente novamente por favor.",
          });
        });
    });
  };

  const onClickStopAudio = async () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);

        setLoadingMessage(true);

        setMp3Audio({
          ...mp3Audio,
          blobURL,
          isRecording: false,
          loading: true,
        });

        const file = new File(buffer, "audio.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });

        sendAudioToServer(file);
      })
      .catch((e) => console.log(e));
  };

  function separateString(str, char) {
    return str.substr(0, 3) + char + str.substr(3, 4) + char + str.substr(7, 3);
  }

  const onClickVideoCall = () => {
    const ramdomStr = randomize("a", 10);
    const strMeeting = separateString(ramdomStr, "-");

    sendChatMessage(
      `${constants.CHAT_MESSAGE_TYPE_MEET}https://meeting.bpool.co/${strMeeting}`
    );
  };

  const getDataUpload = (dataForm) => {
    const { field, url } = dataForm;
    setFileUrl(url);

    sendChatMessage(`${constants.CHAT_MESSAGE_TYPE_FILE}${url}`);
  };

  useEffect(() => {
    getChatInitial();

    if (chat && chat.chatActiveMessages) {
      getMessages();
    }

    if (!hasBackupChatTopics) {
      setBackupChatTopics(chat.chatTopics);
      setHasBackupChatTopics(true);
    }
  }, [chat]);

  const [searchValueDebounced] = useDebounce(inputSearch, 1000);

  const search = (term) => {
    if (hasBackupChatTopics) {
      const arrFiltered = backupChatTopics.filter(
        (item) =>
          item.CompanyName.toUpperCase().indexOf(term.toUpperCase()) > -1
      );

      dispatch(CHAT_API.receive_chat_topics(arrFiltered));
    } else {
      const arrFiltered = chat.chatTopics.filter(
        (item) =>
          item.CompanyName.toUpperCase().indexOf(term.toUpperCase()) > -1
      );

      dispatch(CHAT_API.receive_chat_topics(arrFiltered));
    }
  };

  useEffect(() => {
    search(searchValueDebounced);
  }, [searchValueDebounced]);

  return (
    <>
      <S.ChatWrapper>
        <ModalBackground className="pageChat">
          <ThemeProvider>
            <FlexRowDiv className="mainChat">
              <ConversationContainer className={chatListOpen ? "is-open" : ""}>
                {!chatEmpty ? (
                  <>
                    <HeaderChatMessage>
                      {!chatEmpty ? (
                        <>
                          {activeChat?.userOther?.companyLogo && (
                            <Avatar
                              className="avatar-big"
                              size="large"
                              src={activeChat?.userOther?.companyLogo}
                              style={{
                                height: "52px",
                                width: "56px",
                                marginRight: "15px",
                              }}
                            />
                          )}
                          {activeChat?.userOther?.companyLogo === null && (
                            <Avatar
                              className="avatar-big"
                              style={{
                                backgroundColor: "#000",
                                verticalAlign: "middle",
                                fontSize: 30,
                                lineHeight: "60px",
                                height: "52px",
                                width: "56px",
                                marginRight: "15px",
                              }}
                              size="large"
                            >
                              {activeChat?.userOther?.companyName
                                .charAt(0)
                                .toUpperCase()}
                            </Avatar>
                          )}
                          <HeaderChatContentInfos>
                            <HeaderChatInfos>
                              <div>{activeChat?.userOther?.companyName}</div>
                              <div>
                                <span>
                                  {activeChat?.userOther?.userOtherName}
                                </span>
                              </div>
                            </HeaderChatInfos>
                            <HeaderChatActions>
                              <ButtonImg
                                onClick={() => setIsOpenPopupChat(true)}
                              >
                                <img alt="Calendário" src={calendarIcon} />
                              </ButtonImg>
                              {isOpenPopupChat ? (
                                <S.PopupCalendarOverlay
                                  onClick={() => setIsOpenPopupChat(false)}
                                />
                              ) : null}
                              {isOpenPopupChat ? (
                                <S.PopupCalendar>
                                  <ul>
                                    <li>
                                      <a href="#">
                                        <img
                                          src={PopupChatPlus}
                                          alt="Novo Agendamento"
                                        />
                                        Novo Agendamento
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <img
                                          src={PopupChatCalendar}
                                          alt="Ir para Minha Agenda"
                                        />
                                        <span></span>
                                        Ir para Minha Agenda
                                      </a>
                                    </li>
                                  </ul>
                                </S.PopupCalendar>
                              ) : null}
                              <ButtonImg onClick={() => onClickVideoCall()}>
                                <img alt="Meeting" src={meetingIcon} />
                              </ButtonImg>
                            </HeaderChatActions>
                          </HeaderChatContentInfos>
                        </>
                      ) : null}
                    </HeaderChatMessage>
                    <MessageListContainer>
                      <MessageList className="message-list" active>
                        {messagesGroups}
                      </MessageList>
                      {/* <LoadingMessages active={loadingMessage} /> */}
                    </MessageListContainer>
                    <TextComposerContainer>
                      <TextComposer
                        className="text-composer"
                        onSend={(value) => sendChatMessage(value)}
                      >
                        <Row className="row-input" align="center">
                          <ChatFileUpload
                            icon={UploadFileSvg}
                            style={{
                              margin: "0 10px 0 5px",
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                            }}
                            startLoading={() => {
                              setLoadingMessage(true);
                            }}
                            stopLoading={() => {
                              setLoadingMessage(false);
                            }}
                            getDataUpload={getDataUpload}
                            idContext={`${activeProject?.projectId}/${partner?.partnerId}`}
                          />
                          {mp3Audio.loading ? (
                            <LoadingIconAudioContainer>
                              <i className="fas fa-spinner fa-pulse"></i>
                            </LoadingIconAudioContainer>
                          ) : (
                            <IconButton
                              style={{
                                marginRight: "5px",
                                width: "24px",
                                height: "24px",
                                background: "transparent",
                                border: "none",
                              }}
                              svgIcon={
                                mp3Audio.isRecording
                                  ? MicrophoneIconStop
                                  : MicrophoneIcon
                              }
                              alt="Audio"
                              onClick={
                                mp3Audio.isRecording
                                  ? () => onClickStopAudio()
                                  : () => onClickSendAudio()
                              }
                            />
                          )}
                          <TextInput
                            className="text-input"
                            placeholder={I18n.get("Escreva uma mensagem...")}
                            maxRows={4}
                            minRows={1}
                          />
                          <SendButton
                            className="text-send-button"
                            fit
                            onClick={(e) => console.log("enviar")}
                          />
                        </Row>
                      </TextComposer>
                    </TextComposerContainer>
                  </>
                ) : (
                  <S.ChatEmpty>
                    <p>{I18n.get("Nenhum chat selecionado.")}</p>
                  </S.ChatEmpty>
                )}
              </ConversationContainer>
              <ChatListContainer
                className={chatListOpen ? "is-open" : ""}
                // ref={setScrollFromRef}
              >
                <HeaderChatListContacts>
                  <CloseChat type="button" onClick={() => onClickCloseModal()}>
                    <img src={ChatIconClose} alt="Fechar o chat" />
                  </CloseChat>
                  <S.ContainterInputSearch>
                    <InputSearchContacts
                      disabled={chatEmpty}
                      placeholder="Buscar contato/conversa"
                      onChange={(e) => setInputSearch(e.target.value)}
                    />
                    <S.ImgInputSearch>
                      <img src={ChatIconSearch} alt="Buscar Contato" />
                    </S.ImgInputSearch>
                  </S.ContainterInputSearch>
                </HeaderChatListContacts>
                {chat.chatTopics && (
                  <>
                    <ChatList className="chat-list">
                      {chat.chatTopics.map((topic, index) => {
                        let listItemClassName = "chat-list-item";

                        if (activeChat.topic === topic.Topic) {
                          listItemClassName = [
                            listItemClassName,
                            "active",
                          ].join(" ");
                        }

                        return (
                          <ChatListItem
                            key={`${topic.Topic}_${index}`}
                            className={listItemClassName}
                            onClick={() =>
                              onActiveChatList(
                                topic.Topic,
                                topic.Name,
                                topic.CompanyName,
                                topic.CompanyLogo
                              )
                            }
                          >
                            {topic.CompanyLogo && (
                              <Avatar
                                className="avatar-big"
                                size="large"
                                src={topic.CompanyLogo}
                              />
                            )}
                            {topic.CompanyLogo === null && (
                              <Avatar
                                className="avatar-big"
                                style={{
                                  backgroundColor: "#000",
                                  verticalAlign: "middle",
                                  fontSize: 30,
                                  lineHeight: "60px",
                                }}
                                size="large"
                              >
                                {topic.Name.charAt(0).toUpperCase()}
                              </Avatar>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: 15,
                              }}
                            >
                              <Title
                                key={`title_${topic.CompanyName}_${index}`}
                                ellipsis
                                className="chat-list-title"
                              >
                                {topic.CompanyName}
                              </Title>
                              <Subtitle
                                key={`subtitle${topic.Name}_${index}`}
                                ellipsis
                                className="chat-list-subtitle"
                                style={{ marginTop: 12, fontSize: 12 }}
                              >
                                {topic.Name}
                              </Subtitle>
                            </div>
                          </ChatListItem>
                        );
                      })}
                    </ChatList>
                  </>
                )}
              </ChatListContainer>
            </FlexRowDiv>
          </ThemeProvider>
        </ModalBackground>
      </S.ChatWrapper>
    </>
  );
};

export default ChatPartner;
