import React from 'react';
import { Tooltip as TooltipAntd } from 'antd';
import iconDefault from './help.svg';

import * as S from './styles';

const Tooltip = ({ icon, textIcon, textTooltip, placement }) => {
  const text = <span>{textTooltip}</span>;

  const renderIconOrText = () => {
    if (textIcon) {
      return <S.Button>{textIcon}</S.Button>;
    } else {
      if (icon) {
        return (
          <S.Button>
            <img src={icon} alt="Help icon" />
          </S.Button>
        );
      } else {
        return (
          <S.Button>
            <img src={iconDefault} alt="Help icon" />
          </S.Button>
        );
      }
    }
  };

  return (
    <S.Wrapper>
      <TooltipAntd placement={placement} title={text}>
        {renderIconOrText()}
      </TooltipAntd>
    </S.Wrapper>
  );
};

export default Tooltip;
