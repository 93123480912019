import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import RatingPreview from './RatingPreview';

import { IdeaWhatIfStatus } from '../../../model';

import {
    Avatar,
    Icon
} from 'antd';

class CardIdea extends PureComponent {
    state = {}
    render() {
        if (!this.props.idea.partner) return null;

        const {
            name,
            partner,
            whatIfIdeaId,
            partner: {
                imageLogo,
                imageIntro
            },
            status
        } = this.props.idea;

 

        const MainCard = styled.div`
            background: #fff;
            color: #353535;
            margin-bottom: 40px;
            height: 100%;

            .CardHeader {
                background: #ccc url(${ imageIntro});
                background-size: cover;
                height: 60px;
                position: relative;

                .statusIcon {
                    text-align: right;
                    padding-right: 15px;
                    padding-top: 10px;
                }

                a {
                    display: inline-block;
                }

                span {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    margin-left: 4px;


                    i {
                        position: relative;
                        top: 3px;
                    }

                    &.draft {
                        background: #ffb942;

                        i {
                            top: 3px;
                            left: 1px;
                        }
                    }

                    &.created {
                        background: #40A9FF;
                        i {
                            left: 0px;
                            top: 4px;
                        }
                    }

                    &.approved {
                        background: #2f7708;

                        i {
                            left: 1px;
                            top: 4px;
                        }
                    }

                    &.disapproved {
                        background: #e50d0d;

                        i {
                            left: 1px;
                            top: 4px;
                        }
                    }

                    &.view {
                        background: #000;
                    }


                }
            }
            .PartnerName {
                margin-bottom: 20px;
                h2 {
                    font-size: 12px;
                    font-weight: 600;
                    margin-top: 5px;
                }

                h4 {
                    font-size: 11px;
                    font-weight: 600;
                    line-height: 10px;
                }
            }

            .CardContent {
                padding: 0 20px 20px;
            }

            .CardThumb {
                margin-top: -40px;
            }

            .CardInfo {
                padding: 0 15px;
            }

            h3 {
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
            }
            `;
        return (
            <MainCard>
                <div
                    className="CardHeader"
                    style={{ backgroundImage: partner.imageIntro }}
                >
                    <p className="statusIcon">
                        {status == IdeaWhatIfStatus.Draft &&
                            <span className="draft"><i className="fas fa-pen" /></span>
                        }
                        {status == IdeaWhatIfStatus.Created &&
                            <span className="created"><i className="fas fa-paper-plane" /></span>
                        }
                        {status == IdeaWhatIfStatus.Approved || status == IdeaWhatIfStatus.ApprovedWithPO &&
                            <span className="approved"><i className="fas fa-check" /></span>
                        }
                        {status == IdeaWhatIfStatus.Disapproved &&
                            <span className="disapproved"><i className="fas fa-times" /></span>
                        }

                        <Link to={this.props.path}>
                            <span className="view"><Icon type="eye" /></span>
                        </Link>
                    </p>
                </div>
                <div className="CardContent">
                    <p className="CardThumb">
                        <Avatar className="LogoAvatar" size={64} icon="user" src={imageLogo ? imageLogo : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRjxdE_fZ5XjTyLxIQ9fDoHn4OysIuHiLCHOYDcozZWcgCu-SIX"} />
                    </p>

                    <div className="CardInfo">

                        <div className="PartnerName boxPreview">
                            <div className="boxCard">
                                <h2>{partner && partner.name}</h2>
                                <h4>{partner && partner.occupation}</h4>
                                {/* <RatingPreview value={3.5} /> */}
                            </div>
                        </div>

                        <h3>{name}</h3>
                    </div>
                </div>
            </MainCard>
        );
    }
}

export default CardIdea;
