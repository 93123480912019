import styled from "styled-components";

export const Wrapper = styled.div``;

export const ContentButtons = styled.div`
  /* margin-top: 2px; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const ContentFilters = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
`;

export const CheckboxProjects = styled.div`
  display: flex;
  flex-direction: column;

  .checkbox-my-projects {
    display: flex;
    flex-direction: row;

    label {
      margin: 0px 8px 0px 0px;

      .ant-checkbox-inner {
        border-radius: 4px;
      }
    }
  }

  strong {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
  }

  .ant-radio-wrapper-checked {
    .ant-radio-checked {
      .ant-radio-inner {
        :after {
          top: 4px;
          left: 4px;
        }
      }
    }
  }
`;
