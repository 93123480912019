import React from "react";
import { I18n } from "aws-amplify";

import IcoInicio from "../image/icot6-dark.svg";
import IcoStatus from "../image/icot8-dark.svg";
import IcoPrazo from "../image/icot7-dark.svg";
import IcoTermino from "../image/ico-new-termino.svg";

import { Button, Icon } from "antd";

import NumberFormat from "react-number-format";

import { ViewPay } from "../utils/helpers";

const BRL = ({ amount }) => (
  <NumberFormat
    value={amount}
    displayType={"text"}
    thousandSeparator="."
    decimalSeparator=","
    prefix={"R$ "}
    decimalScale={2}
    fixedDecimalScale={true}
  />
);

class ReviewStep4 extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  getNonCompete(days) {
    var txt = I18n.get("Nenhuma");

    if (days == 0)
      txt = I18n.get(
        "Caso você seja selecionado, não poderá trabalhar com concorrentes diretos da mesma categoria durante o projeto."
      );

    if (days > 0)
      txt = I18n.get(
        "Caso você seja selecionado, não poderá trabalhar com concorrentes diretos da mesma categoria durante o projeto @@days@@ dias a partir do encerramento do projeto"
      ).replace("@@days@@", days);

    return txt;
  }

  showMessage() {
    if (this.getMessage(this.props?.clientCountry?.name) !== "") return true;

    return false;
  }

  getMessage(country) {
    var txt = I18n.get("");

    switch (country) {
      case "ARG":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura electrónica de Monotributista por monto neto o de Responsable inscripto, por monto neto del proyecto más iva."
        );
        break;
      case "CHL":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura de exportación por monto neto del proyecto SIN IVA."
        );
        break;
      case "COL":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura electrónica de empresas solo COLOMBIANAS."
        );
        break;
      case "CRI":
        txt = I18n.get("Tipo de factura solicitada: Factura SIN IVA.");
        break;
      case "ECU":
        txt = I18n.get("Tipo de factura solicitada: Factura electrónica de empresas solo ECUATORIANAS.");
        break;
      case "MEX":
        txt = I18n.get("Tipo de factura solicitada: Factura electrónicas de sociedades mexicanas. Agencias fuera de México deben emitir factura de exportación sin IVA.");
        break;
      case "PAN":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura de exportación por monto neto del proyecto SIN IVA."
        );
        break;
      case "URY":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura  electrónica de sociedades uruguayas."
        );
        break;
      case "PRT":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura de exportación por monto neto del proyecto SIN IVA."
        );
        break;
    }

    return txt;
  }

  
  render() {
    return (
      <div>
        <div className="card-boxes">
          <div className="card-row">
            <div className="card">
              <div className="card-intro-icon not-for-mobile">
                <img src={IcoInicio} width="56" />
              </div>
              <div className="card-content">
                <p>
                  <strong>{I18n.get("Previsão do Projeto")}</strong>
                </p>
                <p>
                  <strong>{I18n.get("Início:")}</strong>{" "}
                  {this.props.estimatedStartDate}
                  <br />
                  <strong>{I18n.get("Término:")}</strong>{" "}
                  {this.props.estimatedDurationInMonths
                    ? this.props.estimatedDurationInMonths
                    : "0"}{" "}
                  {I18n.get("mês(es) e")}{" "}
                  {this.props.estimatedDurationInWeeks
                    ? this.props.estimatedDurationInWeeks
                    : "0"}{" "}
                  {I18n.get("semana(s)")}
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-intro-icon not-for-mobile">
                <img src={IcoStatus} width="56" />
              </div>
              <div className="card-content">
                <p>
                  <strong>{I18n.get("Prazo de seleção do parceiro")}</strong>
                </p>
                <p>
                  {this.props.estimatedDecisionInWeeks} {I18n.get("semana(s)")}
                </p>
              </div>
            </div>
          </div>
          <div className="card-row">
            <div className="card">
              <div className="card-intro-icon not-for-mobile">
                <img src={IcoPrazo} width="56" />
              </div>
              <div className="card-content">
                <p>
                  <strong>{I18n.get("Prazo pagamento do cliente")}</strong>
                </p>
                <p>
                  {this.props.paymentTermDays} {I18n.get("dias corridos")}
                </p>
                <p>
                  <span className="paymentWarn">
                    {I18n.get("A contar da data do faturamento.")}
                  </span>
                </p>

                {this.props?.paymentOnlyAfterDelivery &&
                  this.props?.auth?.is_partner &&
                  this.props?.partner?.company?.country?.code == "BRA" && (
                    <p className="alertPay">
                      {I18n.get(
                        "IMPORTANTE: este cliente autoriza faturamento após a entrega do trabalho. Consulte as condições de receber o pagamento antecipado da BPool clicando"
                      )}{" "}
                      {""} <a onClick={() => ViewPay()}>{I18n.get("aqui")}</a>
                    </p>
                  )}
              </div>
            </div>
            <div className="card">
              <div className="card-intro-icon not-for-mobile">
                <img src={IcoTermino} width="56" />
              </div>
              <div className="card-content">
                <p>
                  <strong>{I18n.get("Termo de Exclusividade")}</strong>
                </p>
                <p>{this.getNonCompete(this.props.nonCompeteInDays)}</p>
              </div>
            </div>

            {!this.props.auth.is_partner &&
              this.props.globalAuthorization &&
              this.props.globalAuthorization.url && (
                <div className="card">
                  <div className="card-intro-icon not-for-mobile">
                    <img src={IcoStatus} width="56" />
                  </div>
                  <div className="card-content">
                    <p>
                      <strong>{I18n.get("Aprovação Global")}</strong>
                    </p>
                    {this.props.globalAuthorization &&
                      this.props.globalAuthorization.url && (
                        <a
                          target="_blank"
                          style={{
                            marginTop: "10px",
                            display: "block",
                            fontSize: 14,
                            color: "#000",
                            textDecoration: "underline",
                          }}
                          href={this.props.globalAuthorization.url}
                        >
                          {this.props.globalAuthorization.name}
                        </a>
                      )}
                  </div>
                </div>
              )}
            {this.props.auth.is_partner &&
              this.props.isInvite &&
              (this.props.statusInvite == 11 ||
                this.props.statusInvite == 10 ||
                this.props.statusInvite == 1 ||
                this.props.statusInvite == 2) && (
                <>
                  <div className="card">
                    <div className="card-intro-icon not-for-mobile">
                      <Icon type="clock-circle" style={{ fontSize: "28px" }} />
                    </div>
                    <div className="card-content">
                      <p>
                        <strong>{I18n.get("Status da seleção")}</strong>
                      </p>
                      {this.props.statusInvite == 10 && (
                        <p>
                          {I18n.get(
                            "Definido: Sua empresa não foi selecionada"
                          )}
                        </p>
                      )}
                      {this.props.statusInvite == 11 && (
                        <p>
                          {I18n.get(
                            "Definido: Sua empresa foi selecionada. Inicie o projeto na aba de Projetos"
                          )}
                        </p>
                      )}

                      {this.props.statusInvite &&
                        (this.props.statusInvite == 1 ||
                          this.props.statusInvite == 2) && (
                          <p>
                            {I18n.get(
                              "Pendente: O cliente ainda não definiu com quem vai trabalhar"
                            )}
                          </p>
                        )}
                    </div>
                  </div>

                  {this.props.statusInvite &&
                    (this.props.statusInvite == 11 ||
                      this.props.statusInvite == 10) && (
                      <div className="feedback-box">
                        <div className="main-feed">
                          <div>
                            {this.props?.inviteRates?.comment && (
                              <p>
                                <strong>{I18n.get("Feedback")}: </strong>{" "}
                                {this.props.inviteRates?.comment}
                              </p>
                            )}

                            {this.props?.inviteRates &&
                              this.props?.inviteRates?.grades &&
                              this.props?.inviteRates?.grades.map((item) => {
                                return (
                                  <p
                                    key={item?.reason?.code}
                                    className="rate-feed"
                                  >
                                    <strong>{item?.reason?.label}:</strong>{" "}
                                    {item?.grade} / 5
                                  </p>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}

                  {this.props?.partner?.country?.bpRegion === "LATAM" && this.props.clientBillingCountry && (
                    <div className="messageDocumento">
                      <p>{this.getMessage(this.props?.clientBillingCountry)}</p>
                    </div>
                  )}
                </>
              )}

            {this.props.additionalRule && this.props.auth.is_partner && (
              <div className="displayDocumento">
                <p className="IconeAlert">
                  <Icon type="warning" />
                </p>
                <h3>{I18n.get("Regras adicionais do cliente")}</h3>
                <p>
                  {I18n.get("Faça download para verificar regras adicionais")}
                </p>

                <a href={this.props.additionalRule?.url}>
                  <Button
                    className="button bt-bpool primary big"
                    icon="download"
                  >
                    {I18n.get("Download")}
                  </Button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewStep4;
