import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { BP_PARTNER_UPDATE } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const update_partner_prov = (data) => {
  return (dispatch, getState) => {
    Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .put(
            `${Config.API_BASE_URL}/bp/partners/updatestatustemporario/${data.partnerId}`,
            data,
            config
          )
          .then((response) => {
            dispatch({
              type: BP_PARTNER_UPDATE,
              payload: {
                partner: response.data,
                task: { result: true },
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: BP_PARTNER_UPDATE,
              payload: {
                task: { errors: error.response.data },
              },
            });
          });
      })
      .catch((error) => {
        dispatch({ type: EXPIRED });
      });
  };
};

export default update_partner_prov;
