import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import Main from "../../../components/UI/Main";
import Loading from "../../../components/UI/Loading";
import { Unlogged } from "../../../components/UI/Unlogged";
import { useRegistrationFlow } from "../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";

import * as S from "./styles";

export const VerifyPreRegistry = () => {
  const location = useLocation();
  let history = useHistory();
  const { verifyPreRegistryToken } = useRegistrationFlow();
  const [token, setToken] = useState("");

  const prepareToken = (url) => {
    const arrayOfStrings = url.split("/");
    const tkn = arrayOfStrings[arrayOfStrings.length - 1];
    setToken(tkn);
  };

  const postVerifyPreRegistry = async () => {
    await verifyPreRegistryToken(token);

    setTimeout(() => {
      history.push({
        pathname: "/registration-flow/login",
      });
    }, 2500);
  };

  useEffect(() => {
    if (token) {
      postVerifyPreRegistry();
      localStorage.removeItem("@BPOOL-registration-flow-user");
    }
  }, [token]);

  useEffect(() => {
    prepareToken(location?.pathname);
  }, [location]);

  return (
    <Unlogged>
      <div className="container">
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 6 }}>
            <Main bgColor="#fff" padding="30px">
              <Row>
                <Col xs={24}>
                  <S.RowCenter>
                    <S.TitleWithIcon>
                      <S.H4>{I18n.get("Checking Pre-Registration")}</S.H4>
                    </S.TitleWithIcon>
                  </S.RowCenter>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <S.ContentLoader>
                    <Loading sizeIcon={30} align="center" />
                  </S.ContentLoader>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <S.Text>
                    {I18n.get(
                      "Wait a moment, we are verifying your pre-registration and soon you will be redirected to login to continue."
                    )}
                  </S.Text>
                </Col>
              </Row>
            </Main>
          </Col>
        </Row>
      </div>
    </Unlogged>
  );
};
