import React, { useState, useEffect } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Icon } from 'antd';

import * as S from "./styles";
import { I18n } from "aws-amplify";

const { Dragger } = Upload;

export const UploadInternal = ({ multiple, cbFiles, filesDefault }) => {
  const [files, setFiles] = useState(multiple ? [] : {});

  const onDrop = async ({ onSuccess, onError, file }) => {
    if (multiple) {
      setFiles([...files, file]);
      cbFiles([...files, file]);
    } else {
      setFiles(file);
      cbFiles(file);
    }
  };

  const onRemoveFile = async (e) => {
    if (multiple) {
      const filesUpdateds = files.filter(item => item?.uid !== e?.uid);
      setFiles(filesUpdateds);
      cbFiles(filesUpdateds);
    } else {
      setFiles({});
      cbFiles({});
    }
  };

  const formatFilesDefault = () => {
    if (multiple && filesDefault?.length > 0) {
      const filesDefaultFormatted = filesDefault?.map(item => ({
        uid: item?.id,
        lastModified: Date.now(),
        lastModifiedDate: new Date(),
        name: item?.name,
        type: item?.type,
        url: item?.url,
        webkitRelativePath: "",
      }));
      setFiles(filesDefaultFormatted);
    } else if (!multiple && filesDefault?.id) {
      const filesDefaultFormatted = {
        uid: filesDefault?.id,
        lastModified: Date.now(),
        lastModifiedDate: new Date(),
        name: filesDefault?.name,
        type: filesDefault?.type,
        url: filesDefault?.url,
        webkitRelativePath: "",
      }
      setFiles(filesDefaultFormatted);
    }
  };

  useEffect(() => {
    if (filesDefault?.length > 0 || filesDefault?.name) {
      formatFilesDefault();
    } else {
      setFiles(multiple ? [] : {});
    }
  }, [filesDefault]);

  return (
    <S.Wrapper>
      <Dragger
        fileList={multiple ? filesDefault?.length ? files : files : false}
        multiple={multiple}
        customRequest={(e) => onDrop(e)}
        onRemove={(e) => onRemoveFile(e)}
      >
        <S.ContentUpload>
          <S.Infos>
            <S.IconUpload>
              <UploadOutlined />
            </S.IconUpload>
            <S.IconUploadTexts>
              <p className="ant-upload-text" style={{ fontWeight: 700, fontSize: 14 }}>{I18n.get("Drag & drop your file or")}</p>
              <p className="ant-upload-text">{I18n.get("JPG, PNG or PDF")}</p>
            </S.IconUploadTexts>
          </S.Infos>
          <S.ButtonContent>
            <button>{I18n.get(multiple ? "Select files" : "Select file")}</button>
          </S.ButtonContent>
        </S.ContentUpload>
      </Dragger>

      {!multiple && files?.name ? (
        <S.FileInternal>
          <span>{files?.name}</span>
          <button onClick={onRemoveFile}>
            <Icon type="delete" />
          </button>
        </S.FileInternal>
      ) : null}
    </S.Wrapper>
  );
};
