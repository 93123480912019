import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openQuickMeeting } from "../../../store/actions/quickMeeting";
import { I18n } from "aws-amplify";
import { Radio, Icon } from "antd";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import { Input } from "../../../components/UI/Input";
import { Chemistry, Briefing, Presentation } from "./screens";
import ChatIconSearch from "../../../../svg/chat-icon-search.svg";
import { CarouselCards } from "./components/CarouselCards";

import * as S from "./styles";

const selects = [
  {
    id: 1,
    label: "Chemistry",
    value: 4,
    disabled: true,
  },
  {
    id: 2,
    label: "Briefing",
    value: 5,
    disabled: true,
  },
  {
    id: 3,
    label: "Presentation",
    value: 6,
    disabled: true,
  },
];

const Meetings = () => {
  const tabs = [
    {
      label: I18n.get("Chemistry"),
      key: 4,
      disabled: false,
      type: 1,
    },
    {
      label: I18n.get("Briefing"),
      key: 5,
      disabled: false,
      type: 2,
    },
    {
      label: I18n.get("Presentation"),
      key: 6,
      disabled: false,
      type: 3,
    },
  ];

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [valueSelect, setValueSelect] = useState(4);
  const [valueSearch, setValueSearch] = useState("");

  const handleOpenQuickMeeting = () => {
    dispatch(openQuickMeeting({ projectId: null, partnerId: null }));
  };

  const onChange = (e) => {
    setValueSelect(e.target.value);
  };

  const renderComponent = () => {
    const tabComponent = tabs.find((item) => item.key === valueSelect);

    return <CarouselCards type={tabComponent.type} search={valueSearch} />;
  };

  return (
    <div>
      <S.ContainerSearchProjects>
        {auth?.is_partner ||
        auth?.is_partner_operation ||
        auth?.is_partner_leader ||
        auth?.is_partner_financial ||
        auth?.is_partner_user ? (
          <div />
        ) : (
          <ButtonBpool
            text={I18n.get("Quick Meeting")}
            theme="primary"
            onClick={() => handleOpenQuickMeeting()}
          />
        )}
        <S.ContainerSearchInput>
          <Input
            placeholder={I18n.get("Buscar Meetings")}
            label
            value={valueSearch}
            onChange={(value) => setValueSearch(value)}
            error=""
          />
        </S.ContainerSearchInput>
      </S.ContainerSearchProjects>

      <S.WrapperRadioGroup>
        <Radio.Group
          onChange={onChange}
          value={valueSelect}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {selects.map((select) => (
            <S.ContentCheckbox>
              <Radio key={select.id} value={select.value}>
                {select.label}
              </Radio>
            </S.ContentCheckbox>
          ))}
        </Radio.Group>
      </S.WrapperRadioGroup>

      {renderComponent()}
    </div>
  );
};

export default Meetings;
