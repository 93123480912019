import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import TextAreaField from '../../form/textarea-field';
import CheckboxField from '../../form/checkbox-field';

import { scrollToTop } from '../../../utils/ui';

class CostForm extends React.Component {
    constructor(props) {

        super(props);

        const { bPitch = {} } = props;

        this.state = {
            fields: {
                competition: {
                    onChange: this.onChangeForm.bind(this, 'competition'),
                    // onBlur: this.onSave.bind(this),
                    successIcon: false,
                    markAsRequired: true
                }
            },
            errors: {},
            form: { ...bPitch },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving());
    }

    onChangeFormCheckbox(prop, e) {

        const value = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving());
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.props.bPitch,
            ...this.state.form
        };

        return data;
    }

    onSaving() {

        clearTimeout(this.t);
        this.t = setTimeout(() => this.onSave(), 1000)
    }

    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_bpitch(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    render() {

        
        const bPitch = this.props.bPitch || {};

        const { isCompetitionInfoViaUpload } = bPitch;

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Concorrência")}</h1>

                <div className="bp-event-section" style={{ marginTop: '3rem', maxWidth: '40rem' }}>

                    <TextAreaField
                        {...this.state.fields.competition}
                        markAsRequired={!isCompetitionInfoViaUpload}
                        markAsOptional={isCompetitionInfoViaUpload}
                        label={I18n.get("Conte-nos um pouco do cenário competitivo")} 
                        value={this.state.form.competition}
                        error={this.state.errors.competition} />

                    <br />

                </div>
            </div>
        );
    }
}

export default CostForm;
