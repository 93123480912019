import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperSimple";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";

import { LEADER_API } from "../../../actions/leader/actions";
import { BP_API } from "../../../actions/bp/actions";

import FormResponsible from "../components/formResponsible";

import { TitleBorder, TitleMiddleLine } from "../../components/Titles";

import styled from "styled-components";

import FormSkeleton from "../../components/FormSkeleton";

import { QueryString } from "../../../utils/querystring";

import { Row, Col, Input, Select, Button, Form } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: 60px;
  }
`;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_domain: () => {
      dispatch(BP_API.get_domain());
    },
    save: (data) => {
      dispatch(LEADER_API.update_person(data));
    },
    get_leader: (id) => {
      dispatch(LEADER_API.get(id));
    },
    confirm: (data) => {
      dispatch(LEADER_API.confirm(data));
    },
  };
};

class LeaderAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.get_domain();
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    this.setState({ locale: locale });

    I18n.setLanguage(locale);

    let data = {
      verificationToken: this.props.match.params.token,
      locale: locale,
    };

    this.props.confirm(data);
  };

  render() {
    if (this.props.leader && this.props.leader.completedOn) {
      return <Redirect to="/parceiro/obrigado" />;
    }

    //TODO: Find better way... GAMBI!
    let menu = [...constants.MENU_REGISTER_LEADERS];
    for (let key in menu) {
      menu[key].label = I18n.get(menu[key].label);
    }

    return (
      <AppWrapperSimple>
        <SubHeader
          titlePage=""
          itensMenu={menu}
          urlId={
            this.props.match.params.leaderId +
            "/" +
            this.props.match.params.token
          }
          activeItem={1}
          locale={this.state.locale}
        />
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Líder - Sobre você")}</TitleBorder>
                <p className="descriptionPage">
                  {I18n.get(
                    "Nosso algoritmo quer saber mais sobre você, que está na liderança. Lembre-se de que cada informação é valiosa para ele encontrar projetos que tenham a ver com sua empresa."
                  )}
                </p>
              </Col>
            </Row>

            <FormResponsible
              partner={this.props.leader && this.props.leader}
              partnerId={this.props.match.params.leaderId}
              action={this.props.save}
              errors={this.props.leader.errors && this.props.leader.errors}
              isLeader={true}
              locale={this.state.locale}
              get2={true}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
            />

            <Row>
              <Col sm={3}></Col>
              <Col sm={18}></Col>
              <Col sm={3}>
                <Link
                  to={`/lider/cadastro/expertise/${this.props.match.params.leaderId}/${this.props.match.params.token}?locale=${this.state.locale}`}
                >
                  <Button type="primary" shape="round">
                    {I18n.get("Avançar")}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Main>
        </ContainerPage>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderAbout);
