import { CHAT_TOPIC_NEW_MESSAGE } from './actions';

export const api_call = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: CHAT_TOPIC_NEW_MESSAGE,
      payload: data,
    });
  };
};

export default api_call;
