import styled from "styled-components";
import Carousel from "react-elastic-carousel";

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const BannerPartner = styled.div``;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -60px;
`;

export const LogoPartner = styled.div`
  display: flex;
  width: 117px;
  height: 117px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.101557);

  img {
    width: 109px;
    height: 109px;
    border-radius: 50%;
  }
`;

export const SubtitleWithButton = styled.div`
  display: flex;

  button {
    background: transparent;
    border: none;
    margin-left: 60px;
    cursor: pointer;

    img {
      transition: transform 150ms ease;
    }
  }
`;

export const Subtitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  margin-bottom: 30px;
`;

export const P = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
`;

export const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;

  transition: height 150ms ease;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    list-style: none;
    background: #000000;
    border-radius: 10px;
    padding: 0 30px;
    margin: 20px 20px 0 0;
    height: 35px;
  }
`;

export const Specialties = styled.ul`
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    img {
      margin-right: 30px;
    }

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const ItemsList = styled.ul`
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    img {
      margin-right: 30px;
    }

    a {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      text-decoration-line: underline;
      color: #1883ff;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const WrapperLeaders = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
`;

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  background: #000000;
  margin: 50px 0;
`;

export const ActionButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 150px;

  a,
  button {
    margin-left: 10px;
  }
`;

const StyledCarousel = styled(Carousel)`
  margin: 0;

  .rec-slider-container {
    margin: 0 -10px;
  }

  .rec-slider {
    margin: 0;
  }

  button {
    width: 22px;
    height: 22px;
    left: 0px;
    top: 0px;

    background: #c4c4c4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    :hover,
    :focus {
      box-shadow: 0 0 1px 3px #2f2f2f;
    }
  }

  .rec-dot_active {
    background: #2f2f2f;
  }

  .rec-pagination {
    display: none;
    margin-top: 30px;
  }

  .rec-arrow {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0 0 0;
    position: relative;
    z-index: 5;
    background: #000;
    color: #fff;

    :hover {
      background: #222;
      color: #fff;
    }
  }
`;

export const VideoOrImageWrapper = styled.div``;

export const VideoOrImageContainer = styled.div`
  width: 100%;
  height: 270px;
`;

export { StyledCarousel as Carousel };

export const CaseItem = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  scroll-snap-align: start;
  width: 33.333333%;
`;

export const EmptyItem = styled.div``;

export const BtArrowCarousel = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerItemCarousel = styled.div`
  width: calc(100% - 90px);
`;

export const ContentItemCarousel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 15px;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  overflow-x: hidden;
`;

export const WrapperItemWithTitle = styled.div`
  margin-top: 30px;
`;

export const WrapperItemCarousel = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .prev {
    margin-right: 15px;
  }

  .next {
    margin-left: 15px;
  }
`;

export const TitleSectionResume = styled.div`
  margin-bottom: 20px;

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #010202;
  }

  button {
    margin-left: 50px;
  }
`;

export const TitleButtonSectionResume = styled.button`
  width: fit-content;
  margin: 0 0 20px 0;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #010202;
  }

  .anticon {
    margin-left: 50px;
    color: #1883ff;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
`;

export const IconsItemWithTitle = styled.div`
  span {
    svg {
      transform: ${(props) =>
        props.expand ? "rotate(0deg)" : "rotate(180deg)"};
      transition: all 0.25s;
    }
  }
`;

export const ContentItemWithTitle = styled.div`
  width: 100%;
  height: ${(props) =>
    props.titleExpand ? (props.expanded ? "auto" : "0px") : "auto"};
  overflow: ${(props) =>
    props.titleExpand ? (props.expanded ? "visible" : "hidden") : "visible"};
  opacity: ${(props) =>
    props.titleExpand ? (props.expanded ? "1" : "0") : "1"};
  transition: all 0.5s;
`;

export const InfosItemCase = styled.div`
  background: rgba(7, 0, 0, 0.6);
  color: #fbfbfb;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
`;

export const ImageEmpty = styled.div`
  width: 300px;
  height: 156px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 50px;
    height: 50px;
    color: #6b6666;
  }
`;

export const LeaderItem = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  scroll-snap-align: start;
  /* width: ${(props) => props.maxItemsShow}; */
  width: auto;
`;

export const LeaderItemInfos = styled.div`
  margin-left: 15px;

  strong {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2f2f2f;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .leader-mail {
    font-weight: 400;
  }
`;

export const LeaderProfilePicture = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);

  img {
    border-radius: 50%;
    width: 65px;
    height: 65px;
  }
`;

export const Specialities = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
    margin-bottom: 20px;
    min-height: 30px;
    display: flex;
    align-items: center;

    span {
      top: 3px;
      left: 15px;
      position: relative;
    }
  }
`;

export const ItemDiversityView = styled.div`
  line-height: 22px;
  margin-top: 16px;

  strong {
  }
  span {
  }
  img {
    cursor: pointer;
    margin-left: 8px;
    top: -2px;
    position: relative;
  }
`;
