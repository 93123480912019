import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';
import { notification } from 'antd';

import Config from '../../config';

import { BP_LOGIN_CLIENTS_USERS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_reset_user_client = (clientId, username) => {

    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/bp/logins/resetuserclient/${clientId}/${username}`, config)
                .then(response => {

                    dispatch({
                        type: BP_LOGIN_CLIENTS_USERS_GET,
                        payload: {
                            loginClientsUsers: response.data
                        }
                    });

                })
                .then(response => {

                    notification["success"]({
                        message: 'Senha resetada com sucesso!',
                        description: "",
                    });

                })
                .catch(error => {

                    notification["error"]({
                        message: 'Ops! Algo deu errado.',
                        description: "",
                    });

                    throw (error);

                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_reset_user_client;
