import React, { Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import styled from 'styled-components';

import LoggedPage from '../page-templates/logged';

import Menu from './_menu';

import SpecialismForm from './specialism-form';

import { TitleBorder, TitleMiddleLine } from '../../modules/components/Titles';
import { CardBorder } from '../../modules/components/Cards';
import { PARTNER_API } from '../../actions/partner/actions';

import {
    Row,
    Col,
    Input,
    notification,
    Select,
    Tag,
    Icon,
    Button,
    Drawer,
    Table,
    Tooltip,
    Form
} from 'antd';

import FormSpecialisms from '../../modules/Register/components/formSpecialisms';
import FormCourse from '../../modules/Register/components/formCourse';
import TableCourse from '../../modules/Register/components/tableCourse';

const RowSpace = styled(Row)`
    margin: 20px 0;
`

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(PARTNER_API.update_specialism(data));
        },
        new_company: data => {
            dispatch(PARTNER_API.new_company(data));
        },
        create_course: data => {
            dispatch(PARTNER_API.create_course(data));
        },
        update_course: data => {
            dispatch(PARTNER_API.update_course(data));
        },
        delete_course: data => {
            dispatch(PARTNER_API.delete_course(data));
        },
    };
};


class SpecialismAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
            selectedItem: {},
            edit: false,
            show: false
        };
    }

    hideCourse = () => {
        this.setState({
            selectedItem: {},
            edit: false,
            show: false
        });
    }

    showAddCourse = () => {
        this.setState({
            selectedItem: {},
            edit: false,
            show: true
        });
    }

    showEditCourse = (item) => {
        this.setState({
            selectedItem: item,
            edit: true,
            show: true
        });
    }

    updateCourse = (data) => {
        this.props.update_course(data);
    }

    addCourse = (data) => {
        this.props.create_course(data);
    }

    handleEditCourse = (item) => {
        setTimeout(() => {
            this.showEditCourse(item);
        }, 500);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partner.specCoursesTraining && prevProps.partner.specCoursesTraining && this.props.partner.specCoursesTraining.length > prevProps.partner.specCoursesTraining.length) {

            notification.success({
                message: I18n.get(I18n.get('Adicionado com sucesso!')),
                description: I18n.get('O curso foi adicionado ao seu cadastro'),
                duration: 3,
            });

            this.hideCourse();
        }

        if (this.props.partner.specCoursesTraining && prevProps.partner.specCoursesTraining && this.props.partner.specCoursesTraining.length < prevProps.partner.specCoursesTraining.length) {

            notification.success({
                message: I18n.get('Removido com sucesso!'),
                description: I18n.get('O curso foi removido do seu cadastro'),
                duration: 3,
            });
        }

        if (this.props.partner.specCoursesTraining && prevProps.partner.specCoursesTraining && this.props.partner.specCoursesTraining != prevProps.partner.specCoursesTraining && Object.keys(this.state.selectedItem).length > 0) {

            notification.success({
                message: I18n.get('Atualizado com sucesso!'),
                description: I18n.get('O curso foi atualizado'),
                duration: 3,
            });

            this.setState({
                selectedItem: null
            })

            this.hideCourse();
        }
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><Link to="/home">{I18n.get("Admin")}</Link></li>
                        <li className="is-active"><Link to="/cadastro">{I18n.get("Cadastro")}</Link></li>
                    </ul>
                </nav>

                <div className="columns specialist-admin company-admin">

                    <div className="column is-3">
                        <Menu {...this.props} />
                    </div>

                    <div className="column is-9">

                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Cadastro - Especialidades")}</TitleBorder>
                            </Col>
                        </Row>
                        <Row gutter={1}>

                            <Col sm={24}>
                                <CardBorder>


                                    <FormSpecialisms
                                        partner={this.props.partner && this.props.partner}
                                        partnerId={this.props.partner && this.props.partner.partnerId}
                                        action={this.props.save}
                                        isNewCompany={this.props.new_company}
                                    />
                                </CardBorder>
                            </Col>

                        </Row>

                        <RowSpace>
                            <Col sm={24}></Col>
                        </RowSpace>


                        {this.props.partner.specialisms && this.props.partner.specialisms.indexOf("TRE_COACH") > -1 &&
                            <Fragment>
                                <Row gutter={1}>
                                    <Col sm={24}>
                                        <Row>
                                            <Col sm={24}>
                                                <TitleBorder>{I18n.get("Opções para cursos")}</TitleBorder>
                                            </Col>
                                        </Row>
                                        <CardBorder>

                                            <Row className="">
                                                <Col sm={24}>
                                                    <p className="BtnAction">
                                                        <Button type="primary" shape="round" onClick={() => this.showAddCourse()}>{I18n.get("+ Adicionar curso")}</Button>
                                                    </p>
                                                    <br />
                                                    <TableCourse
                                                        data={this.props.partner.specCoursesTraining && this.props.partner.specCoursesTraining}
                                                        sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                                        partnerId={this.props.partner && this.props.partner.partnerId}
                                                        delete={this.props.delete_course}
                                                        edit={this.showEditCourse}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBorder>
                                    </Col>
                                </Row>
                            </Fragment>

                        }


                        {/* <h1 className="title-bpool medium">
                            Especialidades & Segmentos
                        </h1>

                        <SpecialismForm {...this.props} isAdmin={true} /> */}
                    </div>

                </div>
                <Drawer
                    title={Object.keys(this.state.selectedItem).length > 0 ? I18n.get("Editar curso") : I18n.get("Adicionar curso")}
                    placement="right"
                    forceRender={true}
                    closable={true}
                    onClose={() => this.hideCourse()}
                    destroyOnClose={true}
                    visible={this.state.show}
                    width="50%"
                >
                    <FormCourse
                        partner={this.props.partner && this.props.partner}
                        partnerId={this.props.partner && this.props.partner.partnerId}
                        action={this.addCourse}
                        update={this.updateCourse}
                        cancel={() => this.hideCourse()}
                        item={this.state.selectedItem}
                        errors={this.props.partner && this.props.partner.errors}
                        forceRender={true}
                    />
                </Drawer>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialismAdmin);
