import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import Main from "../../../../components/UI/Main";
import { Unlogged } from "../../../../components/UI/Unlogged";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { parseQueryString } from "../../../Bpay/pages/Details/Filters/utils/resolvers";
import CheckIcon from "../../svgs/check-circle.svg";
import * as S from "./styles";

export const RegisterSupplierSuccess = () => {
  let location = useLocation();
  let history = useHistory();
  const [supplierCreatedLogin, setSupplierCreatedLogin] = useState(false);
  const [culture, setCulture] = useState("");

  React.useEffect(() => {
    const createdLogin = parseQueryString(location?.search);
    if (createdLogin?.login === "true") {
      setSupplierCreatedLogin(true);
    } else {
      setSupplierCreatedLogin(false);
    }

    if (createdLogin?.bPaymentId) {
      localStorage.setItem(
        "@BPOOL-supplier-bPaymentId",
        createdLogin?.bPaymentId
      );
    }
    if (createdLogin?.culture) {
      I18n.setLanguage(createdLogin?.culture);
      setCulture(createdLogin?.culture);
    }
  }, [location]);

  return (
    <>
      <Unlogged>
        <div className="container">
          <Main bgColor="#fff" padding="30px" style={{ marginBottom: 0 }}>
            <Row>
              <Col xs={24}>
                <S.Content>
                  <img src={CheckIcon} alt="Check" />
                  <h3>
                    {I18n.get(
                      supplierCreatedLogin
                        ? "Cadastro realizado com sucesso"
                        : "Faturamento enviado com sucesso"
                    )}
                  </h3>

                  {supplierCreatedLogin ? (
                    <>
                      <p>
                        {I18n.get(
                          "Seu cadastro foi realizado com sucesso, agora você pode se logar e cadastrar sua NF, além de consultar o status de seu faturamento."
                        )}
                      </p>
                      {/* <p>
                        {I18n.get(
                          "Seu faturamento foi enviado e em breve nossa equipe entrará em contato"
                        )}
                      </p> */}
                      <div style={{ marginTop: 40, width: "100%" }}>
                        <ButtonBpool
                          theme="primary"
                          full
                          text={I18n.get("Realizar login")}
                          onClick={() =>
                            history.push(
                              `/login${culture && `?locale=${culture}`}`
                            )
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <p>
                      {I18n.get(
                        "Em breve nosso time financeiro irá entrar em contato e você receberá um link para acompanhar o pagamento."
                      )}
                    </p>
                  )}
                </S.Content>
              </Col>
            </Row>
          </Main>
        </div>
      </Unlogged>
    </>
  );
};
