import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 60px;
`;

export const ListOfInvitations = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 30px 20px;
`;
