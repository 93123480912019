import styled from "styled-components";

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const SubTitle = styled.div`
  margin: 15px 0 30px 0;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  span {
    width: 70px;
    height: 1px;
    background-color: #000000;
    display: block;
  }
`;

export const Text = styled.div`
  font-weight: ${(props) => (props.strongText ? "700;" : "400;")};
  font-size: ${(props) => (props.strongText ? "16px;" : "14px;")};
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const TextSmall = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

export const HistoryBudgetsContent = styled.div`
  margin-left: 65%;
  width: 35%;
`;

export const Content = styled.div`
  padding: 30px 0 0 0;
`;

export const TogglesTaxes = styled.div`
  display: flex;
`;

export const ToggleContent = styled.div`
  display: flex;

  p {
    margin-left: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
  }
`;

export const ButtonExportExcel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const BudgetResume = styled.div`
  display: flex;
  align-items: center;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  span {
    margin-left: 5px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const BudgetResumeSubText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .resume-approved {
    color: #09c38a;
  }

  .resume-declined {
    color: #ff0000;
  }
`;

export const BudgetWaiting = styled.div``;

export const CommentsHistory = styled.div`
  div {
    line-height: 25px;
    font-size: 14px;
  }
`;

export const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
`;
