import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import { useBp } from "../../../../hooks/useBp/useBp.hook"
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";

import * as S from "./styles";

export const RefreshStatistic = () => {
  const { partnerStatusRefresh } = useBp();
  const { partnerId } = useParams();
  const [isSending, setIsSending] = useState(false);

  const handleSend = async () => {
    setIsSending(true);
    const response = await partnerStatusRefresh({ id: partnerId });

    if (response?.status === 200) {
      message.success(I18n.get("Updated successfully!"));
    } else {
      message.error(I18n.get("An error occurred while submitting."));
    }

    setIsSending(false);
  };

  return (
    <div className="card">
      <div className="card-content">
        <Row gutter={1}>
          <Col sm={24}>
            <h3 className="title is-4">{I18n.get("Refresh statistics")}</h3>

            <S.Text>
              <p>{I18n.get("To update statistics click the button below.")}</p>
            </S.Text>

            <ButtonBpool
              text={I18n.get('Update statistics')}
              theme="primary"
              full={false}
              loading={isSending}
              onClick={handleSend}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
