import React from "react";

import { Modal, Tooltip, Icon } from "antd";

import { I18n } from "aws-amplify";

import "./_billing-installments.css";

import InvoiceForm from "./_billing-invoice-form";

import FormatCurrency from "../../ui/format-currency";

import { ItemBillingInstallmentInvoiceAlert } from '../../../modules/Admin/components/itemBillingInstallment';

export default class BillingInstallments extends React.Component {
  constructor(props) {
    super();

    this.state = {
      installment: {},
    };
  }

  componentDidMount() {}

  onSelectInstallment(item, e) {
    this.setState({ installment: item });

    this.props.get_partner();
  }

  render() {
    const { project = {}, billing = {}, currency = {} } = this.props;

   

    return (
      <div style={{ marginTop: "1rem" }}>
        {/* <p className="is-pulled-right">
                        <strong>Saldo:</strong> {<FormatCurrency amount={billing.balance} />}
                    </p> */}

        <p className="title-bpool medium">{I18n.get("Parcelas")}</p>

        <div className="table-main">
          <table className="billing-installments">
            <thead>
              <tr>
                <th>{I18n.get("Parcela")}</th>
                <th>{I18n.get("Liberação")}</th>
                <th>{I18n.get("Valor")}</th>
                <th>{I18n.get("Em Aberto")}</th>
                <th style={{ width: "60%" }}>{I18n.get("NFSe & Pagamento")}</th>
              </tr>
            </thead>
            <tbody>
              {billing.installments &&
                billing.installments.map((item, index) => {
                  const selected = this.state.installment.id == item.id;

                  const trClassName = selected
                    ? "selected"
                    : this.state.installment.id
                    ? "blurred"
                    : "";

                  const tdClassName = selected
                    ? "selected"
                    : this.state.installment.id
                    ? "blurred"
                    : "";

                  return (
                    <tr key={item.id} className={trClassName}>
                      <td className={tdClassName}>
                        <span>#{index + 1}</span>
                      </td>
                      <td className={tdClassName}>
                        <span>{item.createdOn}</span>
                      </td>
                      <td
                        className={tdClassName + " has-text-right"}
                        style={{ paddingRight: "1rem", whiteSpace: "nowrap" }}
                      >
                        {
                          <FormatCurrency
                            amount={item.amount}
                            currency={currency}
                          />
                        }
                      </td>
                      <td
                        className={tdClassName + " has-text-right"}
                        style={{ paddingRight: "1rem", whiteSpace: "nowrap" }}
                      >
                        {
                          <FormatCurrency
                            amount={item.balance}
                            currency={currency}
                          />
                        }
                      </td>
                      <td className={tdClassName}>
                        {item.invoices &&
                          item.invoices.map((invoice, index) => {

                            return (
                              <div
                                key={invoice.id}
                                style={{
                                  marginBottom: "0.5rem",
                                  fontSize: "80%",
                                }}
                              >
                                <div className="columns">
                                  <div className="column is-1">
                                    <div
                                      title={invoice.file.name}
                                      style={{
                                        width: "5rem",
                                        display: "inline-block",
                                      }}
                                    >
                                      <a
                                        href={invoice.file.url}
                                        target="_blank"
                                        style={{ color: "#ccc" }}
                                      >
                                        <span
                                          className="icon is-large"
                                          style={{ marginTop: "0.2rem" }}
                                        >
                                          <i
                                            className="fas fa-file-invoice fa-3x"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </a>
                                    </div>
                                  </div>

                                  <div className="column is-4">
                                    <div style={{ lineHeight: "1.5rem" }}>
                                      <strong>{I18n.get("NF")}:</strong>{" "}
                                      {invoice.invoiceNumber} <br />
                                      <strong>{I18n.get("Valor:")}</strong>{" "}
                                      {
                                        <FormatCurrency
                                          amount={invoice.invoiceAmount}
                                          currency={currency}
                                        />
                                      }{" "}
                                      <br />
                                      <strong>
                                        {I18n.get("Retenção IR:")}
                                      </strong>{" "}
                                      {invoice.tax}% <br />
                                          <strong>{I18n.get("Vencimento")}:</strong> <Tooltip title={I18n.get("O pagamento é realizado pela BPool em até 3 dias do recebimento do cliente")}>
                                            {invoice.clientDueDate} <ItemBillingInstallmentInvoiceAlert invoice={invoice} />
                                          </Tooltip>  <br />
                                    </div>
                                  </div>

                                  <div className="column is-7">
                                    <div style={{ lineHeight: "1.5rem" }}>
                                      <strong>{I18n.get("Favorecido:")}</strong>{" "}
                                      {invoice.beneficiaryName} <br />
                                      <strong>{I18n.get("CNPJ:")}</strong>{" "}
                                      {invoice.beneficiaryId} <br />
                                      {invoice.isPaymentMethodTransfer && (
                                        <>
                                          <strong>{I18n.get("Banco:")}</strong>{" "}
                                          {invoice.bankId}{" "}
                                          <strong>
                                            {I18n.get("Agência:")}
                                          </strong>{" "}
                                          {invoice.bankBranch}{" "}
                                          <strong>{I18n.get("Conta:")}</strong>{" "}
                                          {invoice.bankAccount} <br />
                                        </>
                                      )}
                                      {invoice.isPaymentMethodPix && (
                                        <>
                                          <strong>
                                            {I18n.get("Chave Pix:")}
                                          </strong>{" "}
                                          {invoice.pix} <br />
                                        </>
                                      )}
                                      {invoice.isPaymentMethodSlip && (
                                        <>
                                          <strong>{I18n.get("Boleto:")}</strong>{" "}
                                          <a
                                            href={
                                              invoice.slip && invoice.slip.url
                                            }
                                            target="_blank"
                                          >
                                            {I18n.get("Download")}
                                          </a>
                                          {/* <div title={invoice.slip && invoice.slip.name} style={{ width: '5rem', display: 'inline-block' }}>

                                                                            <a href={invoice.slip && invoice.slip.url} target="_blank" style={{ color: '#ccc'  }}>
                                                                                <span className="icon is-large" style={{ marginTop: '0.2rem' }}>
                                                                                    <i className="fas fa-file-invoice fa-3x" aria-hidden="true"></i>
                                                                                </span>
                                                                            </a>
                                                                        </div> */}
                                        </>
                                      )}

                                    {invoice.isPaymentMethodTransferSWIFTorABA && (
                                        <>
                                          <br />

                                          <strong>{I18n.get("Intermediário")}</strong>
                                          <br />
                                          
                                          <strong>{I18n.get("Dirección de Intermediário")}: </strong> {invoice?.intermediateDirectionBeneficiary}<br />
                                          <strong>{I18n.get("Número de cuenta")}: </strong> {invoice?.intermediateAccountNumber}<br />
                                          <strong>{I18n.get("Clabe interbancaria")}: </strong> {invoice?.intermediateInterbankKey}<br />
                                          <strong>SWIFT / ABA / BIC / IBAN: </strong> {invoice?.intermediateSWIFTABABICIBAN}<br />
                                          <strong>{I18n.get("Banco")}: </strong> {invoice?.intermediateBank}<br />
                                          <strong>{I18n.get("Dirección del Banco")}: </strong> {invoice?.intermediateBankDirection}<br />

                                          <br />

                                          <strong>{I18n.get("Beneficiário")}</strong>
                                          <br />
                                          
                                          <strong>{I18n.get("Dirección de Intermediário")}: </strong> {invoice?.beneficiaryDirectionBeneficiary}<br />
                                          <strong>{I18n.get("Número de cuenta")}: </strong> {invoice?.beneficiaryAccountNumber}<br />
                                          <strong>{I18n.get("Clabe interbancaria")}: </strong> {invoice?.beneficiaryInterbankKey}<br />
                                          <strong>SWIFT / ABA / BIC / IBAN: </strong> {invoice?.beneficiarySWIFTABABICIBAN}<br />
                                          <strong>{I18n.get("Banco")}: </strong> {invoice?.beneficiaryBank}<br />
                                          <strong>{I18n.get("Dirección del Banco")}: </strong> {invoice?.beneficiaryBankDirection}<br />

                                         
                                        </>
                                      )}                                      
                                    </div>

                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {this.state.installment.id != item.id &&
                          item.balance > 0 && (
                            <a
                              className="button is-black is-rounded is-small"
                              onClick={this.onSelectInstallment.bind(
                                this,
                                item
                              )}
                            >
                              {I18n.get("Adicionar NF")}
                            </a>
                          )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {billing.installments && billing.installments.length == 0 && (
          <p>{I18n.get("Nenhuma parcela liberada.")}</p>
        )}

        <Modal
          visible={this.state.installment.id}
          width={900}
          footer={null}
          onCancel={this.onSelectInstallment.bind(this, {})}
        >
          <div style={{ padding: "1rem" }}>
            <InvoiceForm
              {...this.props}
              installment={this.state.installment}
              currency={currency}
              resetInstallment={this.onSelectInstallment.bind(this, {})}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
