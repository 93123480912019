import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { formatMoney2 } from '../../../../utils/helpers'
import {
    Form,
    Select,
    Button,
    Input,
    Row,
    Col,
    Icon,
    Alert,
    Modal
} from 'antd';

const { confirm } = Modal;

const RowEdit = styled(Row)`
    border-bottom: solid 1px #000;
    padding-bottom: 20px;
    margin-bottom: 30px;
    a {
        float: right;
        text-transform: uppercase;
        font-weight: bold;
        color: #000;

        i {
            font-size: 18px;
            background: #000;
            color: #fff;
            padding: 7px;
            border-radius: 20px;
            margin-left: 10px;
        }
    }
`
const PriceText = styled.p`
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: bold;
`;


const { TextArea } = Input;
const { Option } = Select;

class FormApproveAsset extends Component {
    constructor(props) {

        super();

        this.state = {
            disabled: true,
            reasonUpdate: "",
            reasonApprovedDeclined: "",
            formState: {},
            priceValue: formatMoney2(props.asset.price) || 'R$ 0',
            textDescription: props.asset.description.length,
            visibleModalUpdate: false,
            visibleModalApprove: false,
            visibleModalDisapprove: false
        };
    }

    onChange = (e) => {
        this.setState({ reasonUpdate: e.target.value })
    }

    onChangePrice = value => {
        setTimeout(this.setState({ priceValue: formatMoney2(value) }), 1);
    };

    onChangeTxt = value => {
        setTimeout(this.setState({ textDescription: value.length }), 1);
    };

    handleSubmit = () => {
        // e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                let data = {
                    ...this.props.asset,
                    ...values,
                    reasonUpdate: this.state.reasonUpdate
                }

                this.setState({
                    formState: values
                })

                this.props.update(data);
            }
        });
    };


    handleEdit = () => {
        this.setState({
            visibleModalUpdate: true
        })
    }

    handleApprove = () => {

        let data = {
            partnerAssetId: this.props.asset.partnerAssetId,
            approved: true,
            reasonApprovedDeclined: this.state.reasonApprovedDeclined
        }

        setTimeout(() => {
            this.props.action(data)
        }, 100);
    }

    handleDisapprove = () => {

        let data = {
            partnerAssetId: this.props.asset.partnerAssetId,
            approved: false,
            reasonApprovedDeclined: this.state.reasonApprovedDeclined
        }

        setTimeout(() => {
            this.props.action(data)
        }, 10);
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.formErrors != prevProps.formErrors) {
            Object.keys(this.props.formErrors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.formErrors[field].errorMessage)],
                    },
                });
            });

            this.setState({
                visibleModalUpdate: false,
                visibleModalApprove: false,
                visibleModalDisapprove: false,
            })
        };

        if (this.props.asset != prevProps.asset) {
            this.setState({
                disabled: true,
                visibleModalUpdate: false,
                visibleModalApprove: false,
                visibleModalDisapprove: false,
                reasonUpdate: ""
            })
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            asset,
            assetTypes,
            assetSpecialisms,
            assetComplexity
        } = this.props;


        return (
            <Fragment>
                <Modal
                    visible={this.state.visibleModalUpdate}
                    title={I18n.get("Deseja atualizar o asset? Justifique suas alterações:")}
                    onCancel={() => this.setState({ visibleModalUpdate: false })}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" onClick={() => this.setState({ visibleModalUpdate: false })}>{I18n.get("Cancelar")}</Button>,
                        <Button disabled={this.state.reasonUpdate.length <= 0} key="submit" type="primary" onClick={this.handleSubmit}>{I18n.get("Enviar")}</Button>,
                    ]}
                >
                    <TextArea
                        rows={4}
                        onChange={(e) => this.setState({ reasonUpdate: e.target.value })}
                    />
                </Modal>

                <Modal
                    visible={this.state.visibleModalDisapprove}
                    title={I18n.get("Deseja reporvar? Justifique:")}
                    onCancel={() => this.setState({ visibleModalDisapprove: false })}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" onClick={() => this.setState({ visibleModalDisapprove: false })}>{I18n.get("Cancelar")}</Button>,
                        <Button disabled={this.state.reasonApprovedDeclined.length <= 0} key="submit" type="primary" onClick={this.handleDisapprove}>{I18n.get("Enviar")}</Button>,
                    ]}
                >
                    <TextArea
                        rows={4}
                        onChange={(e) => this.setState({ reasonApprovedDeclined: e.target.value })}
                    />
                </Modal>

                <Modal
                    visible={this.state.visibleModalApprove}
                    title={I18n.get("Deseja aprovar? Justifique a aprovação:")}
                    onCancel={() => this.setState({ visibleModalApprove: false })}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" onClick={() => this.setState({ visibleModalApprove: false })}>{I18n.get("Cancelar")}</Button>,
                        <Button disabled={this.state.reasonApprovedDeclined.length <= 0} key="submit" type="primary" onClick={this.handleApprove}>{I18n.get("Enviar")}</Button>,
                    ]}
                >
                    <TextArea
                        rows={4}
                        onChange={(e) => this.setState({ reasonApprovedDeclined: e.target.value })}
                    />
                </Modal>



                <Form>
                    {
                        asset.status == 2 &&
                        <RowEdit>
                            <Col sm={24}>

                                <a onClick={() => this.setState({ disabled: !this.state.disabled })}>{I18n.get("Editar Assets")} {this.state.disabled &&
                                        <Icon type="lock" theme="filled" />
                                    }
                                    {!this.state.disabled &&
                                        <Icon type="unlock" theme="filled" />
                                    }
                                </a>
                            </Col>
                        </RowEdit>
                    }
                    {/* <Row>
                        <Col sm={12}>
                            <Alert message="Error Text" type="error" />
                        </Col>
                    </Row> */}
                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col sm={16}>
                                    <Form.Item label={I18n.get("Título")}>
                                        {getFieldDecorator('title', {
                                            rules: [{ required: false, message: 'ops!' }],
                                            initialValue: asset.title || null
                                        })(
                                            <Input
                                                disabled={this.state.disabled}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col sm={8}>
                                    <Form.Item label={I18n.get("Preço sugerido")}>
                                        {getFieldDecorator('price', {
                                            rules: [{ required: false, message: 'ops!' }],
                                            initialValue: asset.price || null
                                        })(
                                            <Input
                                                disabled={this.state.disabled}
                                                onChange={e => this.onChangePrice(e.target.value)}
                                            />
                                        )}
                                        <PriceText>{this.state.priceValue}</PriceText>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24}>
                                    <Form.Item label={I18n.get("Descrição Completa")}>
                                        {getFieldDecorator('description', {
                                            rules: [{ required: false, message: 'ops!' }],
                                            initialValue: asset.description || null
                                        })(
                                            <TextArea
                                                rows={4}
                                                disabled={this.state.disabled}
                                                onChange={e => this.onChangeTxt(e.target.value)}
                                            />
                                        )}
                                        <PriceText>{this.state.textDescription} {I18n.get("/ mínimo 250")}</PriceText>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24}>
                                    <Form.Item label={I18n.get("Complexidade do asset")}>
                                        {getFieldDecorator('complexity', {
                                            rules: [{ required: false, message: 'Campo obrigatório!' }],
                                            initialValue: asset.complexity || null
                                        })(
                                            <Select
                                                style={{ width: "100%" }}
                                                placeholder={I18n.get("Selecione a complexidade")}
                                                disabled={this.state.disabled}
                                            >
                                                {assetComplexity && assetComplexity.map((item, index) => <Option key={index} value={item.code}>{item.label}</Option>)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12}>
                            <Row>
                                <Col sm={24}>
                                    <Form.Item label={I18n.get("Especialidades")}>
                                        {getFieldDecorator('assetSpecialisms', {
                                            rules: [{ required: false, message: 'Campo obrigatório!' }],
                                            initialValue: asset.assetSpecialisms || null
                                        })(
                                            <Select
                                                style={{ width: "100%" }}
                                                mode="multiple"
                                                placeholder={I18n.get("Selecione as especialidades")}
                                                disabled={this.state.disabled}
                                            >
                                                {assetSpecialisms && assetSpecialisms.map((item, index) => <Option key={index} value={item.code}>{item.label}</Option>)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24}>
                                    <Form.Item label={I18n.get("Tipo")}>
                                        {getFieldDecorator('assetTypes', {
                                            rules: [{ required: false, message: 'Campo obrigatório!' }],
                                            initialValue: asset.assetTypes || null
                                        })(
                                            <Select
                                                style={{ width: "100%" }}
                                                placeholder={I18n.get("Selecione o tipo")}
                                                mode="multiple"
                                                disabled={this.state.disabled}
                                            >

                                                {assetTypes && assetTypes.map((item, index) => <Option key={index} value={item.code}>{item.label}</Option>)}

                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24}>
                                    <Form.Item label={I18n.get("Prazo (em dias)")}>
                                        {getFieldDecorator('durationDays', {
                                            rules: [{ required: false, message: 'ops!' }],
                                            initialValue: asset.durationDays || null
                                        })(
                                            <Input
                                                disabled={this.state.disabled}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            {this.state.disabled &&
                                <Row>
                                    <Col sm={24}>
                                        {
                                            asset.status == 2 && <div>
                                                <Button onClick={() => this.setState({ visibleModalApprove: true })} className="forceRight" type="primary" htmlType="submit" shape="round" size="large">{I18n.get("Aprovar")}</Button>
                                                <Button onClick={() => this.setState({ visibleModalDisapprove: true })} type="primary" shape="round" size="large">{I18n.get("Recusar")}</Button>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            }
                            {!this.state.disabled &&
                                <Row>
                                    <Col sm={24}>
                                        <Button type="primary" onClick={() => this.handleEdit()} shape="round" size="large">{I18n.get("Salvar")}</Button>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }
}

const ApproveAsset = Form.create({})(FormApproveAsset);

export default ApproveAsset;
