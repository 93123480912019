import React from 'react';

import { I18n } from 'aws-amplify';

import FormatCurrency from './ui/format-currency';

class ReviewStep2 extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {

        const currency = this.props.currency || {};

        return (
            <div>
                <br />
                <br />
                <h1 className="title-bpool small">
                  {I18n.get("Escopo do projeto")}</h1>

                <div className="card">
                    <div className="card-content break-word">

                        <div className="reviewed-media">
                            <p>
                                <strong>{I18n.get("Briefing:")}</strong> &nbsp;
                            {this.props.customEvent && this.props.customEvent.briefing}
                            </p>

                            <br />

                            <p>
                            <strong>{I18n.get("Budget:")}</strong> &nbsp;
                        
                            {this.props.customEvent && this.props.customEvent.budgetType == 'TOTAL' && <span><FormatCurrency amount={this.props.customEvent.budgetTotal} currency={currency} /></span>}
                            {this.props.customEvent && this.props.customEvent.budgetType == 'PER_PERSON' && <span><FormatCurrency amount={this.props.customEvent.budgetPerson} currency={currency} />{I18n.get("- POR PESSOA")}</span>}

                        </p>

                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default ReviewStep2;