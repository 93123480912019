import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import AppWrapperSimple from '../../components/AppWrapperSimple';

import PageSuccess from '../../components/PageSuccess';

class BrandeProfileSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <AppWrapperSimple>
                <PageSuccess>
                    <Fragment>
                        <h2>{I18n.get("Obrigado pelo seu tempo para avaliar este parceiro.")}</h2>
                        <p>{I18n.get("A sua colaboração é super importante para a nossa curadoria! =D")}</p>
                    </Fragment>
                </PageSuccess>
            </AppWrapperSimple>
        );
    }
}

export default BrandeProfileSuccess;
