import React from "react";
import { Icon } from "antd";
import { I18n } from "aws-amplify";
import { Modal, Button } from "antd";

import FormatCurrency from "../../../components/ui/format-currency";

export const ItemBillingInstallment = (props) => {
  const {
    installment,
    billingCurrency,
    index,
    handleSetItem,
    billingId,
    projectId,
  } = props;

  const rowStyle = {
    borderTop: index ? "solid 1px #e0e0e0" : "none",
  };

  return (
    <>
      <div style={{ display: "flex", padding: "0.25rem", ...rowStyle }}>
        <div style={{ flex: "1", textAlign: "right" }}>
          <FormatCurrency
            amount={installment.amount}
            currency={billingCurrency}
          />
        </div>

        <div>
          {installment.invoices &&
            installment.invoices.map((invoice) => {
              const data = {
                projectId: projectId,
                billingId: billingId,
                installmentId: installment?.id,
                invoiceId: invoice?.id,
              };
              return (
                <div>
                  <Icon
                    type="file"
                    theme="filled"
                    style={{ marginLeft: "0.3rem" }}
                    title={`NF: ${invoice.invoiceNumber}`}
                  />
                  &nbsp;{invoice.paymentDueDate} &nbsp;{" "}
                  <ItemBillingInstallmentInvoiceAlert invoice={invoice} />
                  {/* <a
                    className=""
                    onClick={() => handleSetItem(data)}
                    style={{ marginLeft: 15 }}
                  >
                    {I18n.get("Editar")}
                  </a> */}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export const ItemBillingInstallmentInvoiceAlert = ({ invoice }) => {
  let alertCmp = null;

  if (invoice.isDueDateApproaching) {
    alertCmp = <i className="bolStatus vencer"></i>;
  }

  if (invoice.isPastDueDate || invoice.isPaid) {
    alertCmp = <i className="bolStatus pago"></i>;
  }


  // if (invoice.isPastDueDate) {
  //   alertCmp = <i className="bolStatus vencido"></i>;
  // }

  // if (invoice.isPaid) {
  //   alertCmp = <i className="bolStatus pago"></i>;
  // }

  return alertCmp;
};
