import React, { useState } from "react";
import { Tooltip } from "antd";
import { I18n } from "aws-amplify";
import { Drawer } from "../../../../../../components/UI/Drawer";
import { FormatCurrency } from "../../../../../../components/UI/FormatCurrency";

import * as S from "./styles";
import { Invoices } from "./invoices";
import { DrawerInfos } from "./drawerInfos";

export const TableInstallments = ({
  data,
  currency,
  isLoading,
  handleReload,
}) => {
  const [drawer, setDrawer] = useState({
    open: false,
    invoiceId: "",
    createdOn: "",
    amount: "",
    balance: "",
  });
  const renderText = (text) => {
    if (text) {
      return text;
    } else if (text === 0) {
      return text;
    } else {
      return "---";
    }
  };

  React.useEffect(() => {
    const storageDataSubirNf = localStorage.getItem(
      "@BPOOL-accounts-dataSubirNf"
    );
    const dataSubirNf = JSON.parse(storageDataSubirNf);

    console.log("data 1", data);
    console.log("dataSubirNf", dataSubirNf);

    if (data?.length && dataSubirNf?.id) {
      const invoice = data?.find((item) => item?.id === dataSubirNf?.id);
      if (invoice) {
        setDrawer({
          open: true,
          invoiceId: invoice?.id,
          createdOn: invoice?.createdOn,
          amount: invoice?.amount,
          balance: invoice?.balance,
        });
        localStorage.removeItem("@BPOOL-accounts-dataSubirNf");
      }
    }
  }, [data]);

  const handleCloseDrawer = () => {
    setDrawer({
      open: false,
      invoiceId: "",
      createdOn: "",
      amount: "",
      balance: "",
    });
  };

  const columns = [
    {
      title: I18n.get("Parcela"),
      dataIndex: "parcela",
      width: 50,
      render: (type, record, index) => (
        <S.RowTab widthRow={50}>
          <S.RowElipsis>
            <span>#{index + 1}</span>
          </S.RowElipsis>
        </S.RowTab>
      ),
    },
    {
      title: I18n.get("Liberação"),
      dataIndex: "createdOn",
      width: 100,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.createdOn}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={100}>
            <S.RowElipsis>{renderText(record?.createdOn)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Valor"),
      dataIndex: "amount",
      width: 100,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.amount}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={100}>
            <S.RowElipsis>
              <FormatCurrency amount={record?.amount} currency={currency} />
            </S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Em Aberto"),
      dataIndex: "balance",
      width: 100,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.balance}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={100}>
            <S.RowElipsis>
              <FormatCurrency amount={record?.balance} currency={currency} />
            </S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: "",
      width: 50,
      render: (type, record) => (
        <S.ButtonAdd
          onClick={() =>
            setDrawer({
              open: true,
              invoiceId: record?.id,
              createdOn: record?.createdOn,
              amount: record?.amount,
              balance: record?.balance,
            })
          }
        >
          <span>{I18n.get("Adicionar NF")}</span>
        </S.ButtonAdd>
      ),
    },
  ];

  return (
    <>
      <S.Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        locale={{ emptyText: I18n.get("Nenhuma parcela liberada.") }}
        expandedRowRender={(record) =>
          record?.invoices?.length ? (
            <S.InfosNFSe>
              {record?.invoices?.map((item, index) => (
                <div key={index}>
                  <Invoices data={item} />
                </div>
              ))}
            </S.InfosNFSe>
          ) : (
            <S.InfosNFSe>
              <p>{I18n.get("Nenhuma NF cadastrada.")}</p>
            </S.InfosNFSe>
          )
        }
      />

      <Drawer
        open={drawer.open}
        handleClose={handleCloseDrawer}
        buttons={<></>}
      >
        {drawer.open ? (
          <DrawerInfos
            data={{ ...drawer, currency }}
            handleCloseDrawer={handleCloseDrawer}
            handleReload={handleReload}
          />
        ) : (
          <></>
        )}
      </Drawer>
    </>
  );
};
