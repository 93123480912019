import React from "react";
import { I18n } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { ClientProjectStatus } from "../../model";
import { Task } from "../../utils/task";
import { Link } from "react-router-dom";

import BriefingEditor from "./_briefing-editor";
import SubmitButtonField from "../form/submit-button-field";

import DownloadButton from "../form/download-button-field";

import EventDetails from "../_project-details-eventCustom";
import BPitchDetails from "../_project-details-bpitch";

import FileUploadList from "../../modules/components/FileUploadList";

import { Row, Col } from "antd";

class BriefingForm extends React.Component {
  constructor(props) {
    super();
    var briefings = {};

    props.briefings &&
      props.briefings.map((briefing) => {
        briefings[briefing.code] = briefing;
      });

    this.state = {
      projectId: props.match.params.projectId,
      fields: {
        briefingType: {
          error: props.errors?.briefingType,
          onChange: this.onChangeFormCheckboxMulti.bind(this, "briefingType"),
          values: [
            { code: "P", label: I18n.get("Briefing via plataforma.") },
            { code: "U", label: I18n.get("Via upload de arquivo") },
          ],
          isHorizontal: true,
        },
      },
      errors: {},
      task: {},
      submit_task: {},
      form: {
        briefingType: props.briefingType || ["P"],
        briefings: briefings,
        files: props.files || [],
        isSubmit: false,
      },
      customCount: 0,
      upload_task: {},
      sendForm: false,
    };

    this.upload = this.onUpload.bind(this);
  }

  componentDidMount() {
    var data = {
      clientId: this.props.client.clientId,
      projectId: this.props.match.params.projectId,
    };

    this.props.get_project(data);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (this.props != prevProps) {
      if (this.props.bp.clientProjectBriefingSections) {
        this.props.bp.clientProjectBriefingSections.map((item) => {
          if (item.mandatory) {
            this.setState((prevState) => {
              var nextState = Object.assign({}, prevState);

              if (item.code == "CUSTOM") {
                nextState.customCount = prevState.customCount + 1;

                item.code = "CUSTOM" + nextState.customCount;
              }

              nextState.form.briefings[item.code] = item;

              return nextState;
            });
          }
        });
      }
    }

    if (task != prev) {
      var nextState = Object.assign({}, prevState);

      nextState.task = task;

      if (task.result) {
        nextState.form.files = this.props.files || [];
        nextState.form.briefingType = this.props.briefingType || ["P"];

        this.props.briefings &&
          this.props.briefings.map((briefing) => {
            nextState.form.briefings[briefing.code] = briefing;
          });

        nextState.customCount = Object.keys(nextState.form.briefings).reduce(
          (acc, code) => {
            return code.substring(0, 6) == "CUSTOM" ? acc + 1 : acc;
          },
          0
        );
      }

      this.setState(nextState);
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const task = this.props.task || {};
  //   const prev = (prevProps && prevProps.task) || {};

  //   if (task != prev) {
  //     if (
  //       (this.props.bp.clientProjectBriefingSections &&
  //         !this.props.briefings) ||
  //       this.props.briefings.lenght == 0
  //     ) {
  //       this.props.bp.clientProjectBriefingSections.map((item) => {
  //         if (item.mandatory) {
  //           this.setState((prevState) => {
  //             var nextState = Object.assign({}, prevState);

  //             if (item.code == "CUSTOM") {
  //               nextState.customCount = prevState.customCount + 1;

  //               item.code = "CUSTOM" + nextState.customCount;
  //             }

  //             nextState.form.briefings[item.code] = item;

  //             return nextState;
  //           });
  //         }
  //       });
  //     }
  //   }

  //   if (task != prev) {
  //     var nextState = Object.assign({}, prevState);

  //     nextState.task = task;

  //     if (task.result) {
  //       nextState.form.files = this.props.files || [];
  //       nextState.form.briefingType = this.props.briefingType || ["P"];

  //       this.props.briefings &&
  //         this.props.briefings.map((briefing) => {
  //           nextState.form.briefings[briefing.code] = briefing;
  //         });

  //       nextState.customCount = Object.keys(nextState.form.briefings).reduce(
  //         (acc, code) => {
  //           return code.substring(0, 6) == "CUSTOM" ? acc + 1 : acc;
  //         },
  //         0
  //       );
  //     }

  //     this.setState(nextState);
  //   }
  // }

  onAddBriefing(item, e) {
    e.preventDefault();

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      if (item.code == "CUSTOM") {
        nextState.customCount = prevState.customCount + 1;

        item.code = "CUSTOM" + nextState.customCount;
      }

      nextState.form.briefings[item.code] = item;

      return nextState;
    });
  }

  onDelBriefing(item, e) {
    e.preventDefault();

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      delete nextState.form.briefings[item.code];

      if (item.code.substring(0, 6) == "CUSTOM") {
        nextState.customCount = Math.max(prevState.customCount - 1, 0);
      }

      return nextState;
    });
  }

  onChange(item, content, plaintext) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form.briefings[item.code].content = content;
      nextState.form.briefings[item.code].plaintext = plaintext;

      return nextState;
    });
  }

  onChangeLabel(item, label) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form.briefings[item.code].label = label;

      return nextState;
    });
  }

  onChangeBriefingFile(item, fileId) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form.briefings[item.code].fileId = fileId;

      return nextState;
    });
  }

  onUpload(file, e) {
    if (!file) return;

    if (e) e.preventDefault();

    var reader = new FileReader();

    reader.onload = (e) => {
      var upload_task = new Task();

      upload_task.start();

      this.setState({ upload_task: upload_task }, function () {
        const data = {
          projectId: this.state.projectId,
          name: file.name,
          base64: e.target.result,
        };

        this.props
          .upload_file(data)
          .then((response) => {
            this.setState((prevState) => {
              var nextState = Object.assign({}, prevState);

              nextState.form.files.push(response.data);
              nextState.upload_task = { result: true };

              return nextState;
            });
          })
          .catch((error) => {
            this.setState({ upload_task: { result: false } });
          });
      });
    };
    reader.readAsDataURL(file);
  }

  delFile(item) {
    var data = {
      projectId: this.props.match.params.projectId,
      id: item.id,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.delete_filev2(data);
    });
  }

  onDrop(files) {
    files = files.map((file) => {
      this.upload(file);
    });
  }

  getDataUploadMult = (files) => {
    this.setState({ sendForm: false });
    let file = files[0];

    delete file["uid"];

    const data = {
      ...this.props.project,
      filesSend: file,
    };

    this.props.upload_filev2(data);
  };

  getData() {
    const briefings =
      this.state.form.briefings &&
      Object.keys(this.state.form.briefings).map(
        (code, index) => this.state.form.briefings[code]
      );

    var data = {
      projectId: this.props.match.params.projectId,
      briefingType: this.state.form.briefingType,
      items: briefings,
      isSuggestion: true,
    };

    return data;
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      if (checked) {
        nextState.form[prop] = [...nextState.form[prop], value];
      } else {
        nextState.form[prop] = nextState.form[prop].filter((v) => v !== value);
      }

      nextState.errors[prop] = null;
      nextState.feedback = null;

      return nextState;
    });
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ sendForm: task.busy });

    this.setState({ task: task }, function () {
      this.props.update_briefing(this.getData());
    });
  }

  hasBriefing(item) {
    return this.state.form.briefings && this.state.form.briefings[item.code];
  }

  render() {
    const AddButton = (props) => {
      return (
        <a href="#" onClick={props.addBriefing}>
          <span className="icon" style={{ color: "#000000" }}>
            <i className="fas fa-plus-circle"></i>
          </span>
        </a>
      );
    };

    const canEdit =
      !(
        this.props.project &&
        this.props.project.status == ClientProjectStatus.DefinitionsSet
      ) && !this.props.project.partnerHasSubmittedDefinitions;

    var msgOk = "";

    if (this.props.auth.is_partner)
      msgOk = I18n.get(
        "O seu briefing foi salvo e enviado para o cliente. Enquanto ele revisa as informações inseridas você ja pode recomendar o cronograma de entrega."
      );
    else
      msgOk = I18n.get(
        "O seu briefing foi salvo e enviado para o parceiro. Agora ele vai revisar as infmormações fornecidas, sugerir um cronograma de entraga e validar o escopo"
      );

    let filesNormalize =
      this.props.files &&
      this.props.files.map((item) => {
        return {
          ...item,
          uid: item.id,
        };
      });

    return (
      <form onSubmit={this.submitForm.bind(this)}>
        <h1 className="title title-1">
          <span>1.</span>
          {I18n.get("Briefing")}
        </h1>

        {!canEdit && (
          <div className="alert-bpool">
            <p>
              <i className="fas fa-info-circle"></i>{" "}
              {I18n.get(
                "Escopo fechado ou em análise, briefing não pode ser alterado."
              )}
            </p>
          </div>
        )}

        {canEdit && (
          <div style={{ margin: "1rem 0 2rem" }}>
            <div className="card">
              <div className="card-content">
                <p
                  style={{
                    borderBottom: "solid 1px #000000",
                    paddingBottom: "0.3rem",
                    fontWeight: "bold",
                    fontSize: "80%",
                  }}
                >
                  {I18n.get("Faça o Upload dos arquivos")}{" "}
                  {this.props.isCreative && (
                    <span>
                      {I18n.get(
                        ", lembrando que os tópicos em asterisco precisam estar na plataforma ou arquivos:"
                      )}
                    </span>
                  )}
                </p>

                <div className="columns" style={{ marginTop: "2rem" }}>
                  <div className="column is-4">
                    <FileUploadList
                      getDataUpload={this.getDataUploadMult}
                      name="files"
                      uploadId={this.props.project.projectId}
                      get2={this.props.get2 || false}
                      fileListGet={filesNormalize || []}
                      limit={1}
                      disabledList={true}
                      direct={true}
                    />
                  </div>

                  <div className="column is-8">
                    <div style={{ marginTop: "13px" }}>
                      {this.props.files &&
                        this.props.files.map((item, index) => (
                          <div key={index} className="columns itemFile line">
                            <div className="column is-two-thirds is-clearfix upload-item">
                              <a href={item.url} target="_blank">
                                {item.name}
                              </a>
                            </div>
                            <div className="column ">
                              {item.uploadedDateFmt}
                              {!this.state.upload_task.busy &&
                                !this.state.task.busy && (
                                  <div className="is-pulled-right">
                                    {item.uploadedByUsername ==
                                      this.props.auth.username && (
                                      <a
                                        className="delete"
                                        onClick={this.delFile.bind(this, item)}
                                      ></a>
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                        ))}
                      {this.props.files && this.props.files.length == 0 && (
                        <p>{I18n.get("Nenhum arquivo.")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!canEdit && this.props.files.lenght > 0 && (
          <div style={{ marginTop: "5rem", borderLeft: "solid 9px #d4f49b" }}>
            <div style={{ paddingLeft: "2rem" }}>
              <p
                style={{
                  borderBottom: "solid 1px #000000",
                  paddingBottom: "0.3rem",
                  fontWeight: "bold",
                  fontSize: "80%",
                }}
              >
                {I18n.get("Arquivos")}
              </p>

              <div className="columns" style={{ marginTop: "2rem" }}>
                <div className="column is-12" style={{ paddingLeft: "1rem" }}>
                  {this.props.files &&
                    this.props.files.map((item, index) => (
                      <div
                        key={index}
                        className="columns itemFile grey2"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <div className="column upload-item">
                          <a href={item.url} target="_blank">
                            {item.name}
                          </a>
                        </div>
                        <div className="column ">{item.uploadedDateFmt}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {this.props.isCreative && (
          <>
            <div>
              <div>
                {canEdit && (
                  <p
                    style={{
                      borderBottom: "solid 1px #000000",
                      paddingBottom: "0.3rem",
                      fontWeight: "bold",
                      fontSize: "80%",
                    }}
                  >
                    {I18n.get(
                      "Preencha o detalhamento abaixo (campos obrigatórios marcados com asterísco) ou espeficique em que arquivo carregado a informação pode ser encontrada, através do drowpdown ao lado de cada tópico."
                    )}
                  </p>
                )}

                <div className="columns" style={{ marginTop: "2rem" }}>
                  <div className="column is-3">
                    <div className="card">
                      <div className="card-content">
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "110%",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {I18n.get("TÓPICOS")}
                        </p>

                        {this.props.bp.clientProjectBriefingSections &&
                          this.props.bp.clientProjectBriefingSections.map(
                            (item, index) => {
                              const hasBriefing = this.hasBriefing(item);

                              return (
                                <div
                                  key={index}
                                  className="is-clearfix briefing-item"
                                >
                                  <div className="is-pulled-right">
                                    {canEdit && !hasBriefing && (
                                      <AddButton
                                        key={index}
                                        addBriefing={this.onAddBriefing.bind(
                                          this,
                                          item
                                        )}
                                      />
                                    )}
                                  </div>
                                  {item.label}{" "}
                                  {item.mandatory &&
                                    item.mandatory === true && (
                                      <strong>*</strong>
                                    )}
                                </div>
                              );
                            }
                          )}

                        <div className="is-clearfix briefing-item">
                          <div className="is-pulled-right">
                            {canEdit && (
                              <AddButton
                                addBriefing={this.onAddBriefing.bind(this, {
                                  code: "CUSTOM",
                                  label: I18n.get("Tópico customizado"),
                                  enabled: true,
                                })}
                              />
                            )}
                          </div>
                          {I18n.get("Tópico customizado")}
                        </div>

                        <p
                          style={{
                            marginTop: "2rem",
                            fontStyle: "italic",
                            fontSize: "90%",
                            opacity: 0.7,
                          }}
                        >
                          {I18n.get("* Tópicos obrigatórios")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="column is-8 is-offset-1">
                    {Object.keys(this.props.errors).length > 0 && (
                      <ul className="ul-list-errors">
                        {Object.keys(this.props.errors).map((item) => {
                          return (
                            <li>
                              <span className="icon has-text-danger">
                                <i className="fas fa-exclamation"></i>
                              </span>
                              {this.props.errors[item].errorMessage}
                            </li>
                          );
                        })}
                      </ul>
                    )}

                    {this.state.sendForm && this.state.task.result && (
                      <p className="msgOk">{msgOk}</p>
                    )}

                    {this.state.form.briefings &&
                      Object.keys(this.state.form.briefings).map(
                        (code, index) => {
                          const item = this.state.form.briefings[code];

                          return (
                            <div key={index} className="itemBriefingEdit">
                              <BriefingEditor
                                {...item}
                                delBriefing={this.onDelBriefing.bind(
                                  this,
                                  item
                                )}
                                onChange={this.onChange.bind(this, item)}
                                onChangeLabel={this.onChangeLabel.bind(
                                  this,
                                  item
                                )}
                                onChangeFile={this.onChangeBriefingFile.bind(
                                  this,
                                  item
                                )}
                                canDelete={canEdit}
                                canEdit={canEdit}
                                files={this.state.form.files}
                              />
                            </div>
                          );
                        }
                      )}

                    <br />
                    <br />

                    {Object.keys(this.props.errors).length > 0 && (
                      <ul className="ul-list-errors">
                        {Object.keys(this.props.errors).map((item) => {
                          return (
                            <li>
                              <span className="icon has-text-danger">
                                <i className="fas fa-exclamation"></i>
                              </span>
                              {this.props.errors[item].errorMessage}
                            </li>
                          );
                        })}
                      </ul>
                    )}

                    {this.state.sendForm && this.state.task.result && (
                      <p className="msgOk">{msgOk}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {this.props.isEvent && (
          <div>
            <EventDetails {...this.props} />
          </div>
        )}

        {this.props.isBPitch && (
          <div>
            <BPitchDetails {...this.props} />
          </div>
        )}

        <Row>
          <Col sm={24} className="btns-inline">
            <div className="field is-narrow">
              <Link
                onClick={this.scrollToTop}
                to={`/parceiro/criar-projeto/${this.props.projectId}`}
                className="button bt-bpool black"
                style={{ marginRight: "100px" }}
              >
                {I18n.get("Voltar")}
              </Link>
            </div>

            {this.props.pdfBriefing && this.props.pdfBriefing.length > 0 && (
              <DownloadButton LinkToDownload={this.props.pdfBriefing} />
            )}

            {canEdit && (
              <SubmitButtonField
                label={I18n.get("Salvar Briefing")}
                className="button bt-bpool"
                onClick={this.submitForm.bind(this)}
                task={this.state.task}
                disabled={Object.keys(this.state.form.briefings).length < 5}
              />
            )}
            <div className="field is-narrow">
              <Link
                onClick={this.scrollToTop}
                to={`/parceiro/criar-projeto/milestones/${this.props.projectId}`}
                className="button bt-bpool"
              >
                {I18n.get("Próximo")}
              </Link>
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

export default withRouter(BriefingForm);
