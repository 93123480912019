import React from 'react';
import { I18n } from 'aws-amplify';
import { Tag } from 'antd';

export default class Card extends React.Component {

    constructor(props) {
        super();
    }

    getSector(sectorId) {

        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }

    getBilling(b){
        switch (b.code) {
            case 'FIXED_CONTRACT':
                return <Tag color="#ff0000">{I18n.get("Fixo")}</Tag>;
                break;
            case 'PER_PROJECT_LT_3':
                return <Tag color="#ff9800">{I18n.get("Menor 3meses")}</Tag>;
                break;
            case 'PER_PROJECT_GT_3':
                return <Tag color="#22e300">{I18n.get("Maior 3meses")}</Tag>;
                break;
        }
    }

    getNonCompete(b){

        if (b === true) {
            return <Tag color="#ff0000">{I18n.get("Sim")}</Tag>;
        } else {
            return <Tag color="#22e300">{I18n.get("Não")}</Tag>;
        }

    }

    render() {

        if (!this.props.bp) return null;
        if (!this.props.bp.partner) return null;

        const item = this.props.bp.partner;

      return (
          <div className="card">
              <div className="card-content">
                  <h3 className="title is-4">{I18n.get("Clientes")}</h3>

                  <div className="content">

                      <table className="table-bpool no-opacity">
                          <tbody>
                              <tr>
                                  <th>{I18n.get("Empresa")}</th>
                                  <th>{I18n.get("Marca")}</th>
                                  <th>{I18n.get("Produto")}</th>
                                  <th>{I18n.get("Categoria")}</th>
                                  <th>{I18n.get("NonCompete")}</th>
                                  <th>{I18n.get("Fat")}</th>
                              </tr>
                              {item.clients.map((item, index) =>
                                  (<tr key={index}>
                                      <td>
                                          {item.name}
                                      </td>
                                      <td>
                                          {item.brand}
                                      </td>
                                      <td>
                                          {item.product}
                                      </td>
                                      <td>
                                          {this.getSector(item.category).label}
                                      </td>
                                      <td>
                                          {this.getNonCompete(item.active)}
                                      </td>
                                      <td>
                                          {item.billing && this.getBilling(item.billing)}
                                      </td>
                                  </tr>)
                              )}
                          </tbody>
                      </table>

                  </div>
              </div>
          </div>
      );
    }
  }
