import React from "react";
import { I18n } from "aws-amplify";
import FillForm from "../page-templates/fillForm-logged";
import ProjectContainer from "../../containers/project-container";
import { Link } from "react-router-dom";

export default class ThankYou extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.scrollToTop();
  }

  scrollToTop() {
    const scrollDuration = 600;
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / (scrollDuration / 15);
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin;
    let scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        scrollCount = scrollCount + 1;
        scrollMargin =
          cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
        window.scrollTo(0, scrollHeight - scrollMargin);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  }

  render() {
    return (
      <FillForm noFixed={true} noFullHeight={true}>
        <div className="hero-body uplift new-project-thankyou-image">
          <div className="container">
            <div className="columns">
              <div className="column is-offset-3 is-6">
                <h1 className="subtitle is-4 is-marginless">
                  {I18n.get("Muito bom!")}
                </h1>
                <div className="colot-line"></div>
                <div>
                  <p>{I18n.get("O projeto do seu cliente foi criado!")}</p>
                  <br />

                  <p>
                    {I18n.get(
                      "O escopo final do seu projeto foi submetido. Se estiver tudo ok, o seu faturamento será liberado quando recebermos a aprovação do cliente."
                    )}
                  </p>
                  <br />
                  <br />
                  <p>
                    <Link to={`/parceiro/projetos`}>
                      {I18n.get("Ir para projetos")}
                    </Link>
                  </p>
                  <br />
                </div>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}
