export const urls = {
  0: '/registration-flow/application',
  1: '/registration-flow/about-the-company',
  2: '/registration-flow/diversity-and-inclusion',
  3: '/registration-flow/business-type',
  4: '/registration-flow/industry',
  5: '/registration-flow/speciality-proof-and-endersement',
  6: '/registration-flow/speciality-proof',
};

export const urlsIsStartup = {
  0: '/registration-flow/application',
  1: '/registration-flow/about-the-company',
  2: '/registration-flow/diversity-and-inclusion',
  3: '/registration-flow/business-type',
  4: '/registration-flow/speciality-proof-and-endersement',
  5: '/registration-flow/speciality-proof',
};

export const urlsFull = {
  7: '/registration-flow/full/company-profile',
  8: '/registration-flow/full/company-details',
  9: '/registration-flow/full/diversity-and-inclusion',
  10: '/registration-flow/full/business-type',
  11: '/registration-flow/full/industry',
  12: '/registration-flow/full/speciality-proof-and-endersement',
  13: '/registration-flow/full/awards',
  14: '/registration-flow/full/customers',
  15: '/registration-flow/full/team-registration',
  16: '/registration-flow/full/my-profile',
  17: '/registration-flow/full/resume',
};

export const urlsFullIsStartup = {
  7: '/registration-flow/full/company-profile',
  8: '/registration-flow/full/company-details',
  9: '/registration-flow/full/diversity-and-inclusion',
  10: '/registration-flow/full/business-type',
  11: '/registration-flow/full/speciality-proof-and-endersement',
  12: '/registration-flow/full/awards',
  13: '/registration-flow/full/customers',
  14: '/registration-flow/full/team-registration',
  15: '/registration-flow/full/my-profile',
  16: '/registration-flow/full/resume',
};

export const urlsLeader = {
  18: '/registration-flow/leader/about-you',
  19: '/registration-flow/leader/industry',
  20: '/registration-flow/leader/cases-and-awards',
  21: '/registration-flow/leader/resume',
};
