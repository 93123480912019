import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import {
    Table,
    Icon
} from 'antd';



const Btns = styled.p`
width: 100px;
display: block;
    a {
        margin-left: 10px;
    }
`

const FooterT = styled.div`
    font-weight: bold;
    text-align: right;
`

class tableMilistone extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getSector = (sectorId) => {
        const items = this.props.sectorsFlat && this.props.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }

    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return '';

        return this.props.bp.clientProjectStatusesMap[value] && this.props.bp.clientProjectStatusesMap[value].label;
    }


    render() {
        const columns = [
            {
                title: I18n.get("Projeto"),
                dataIndex: 'project',
                key: 'project'
            },
            {
                title: I18n.get("Milestone"),
                dataIndex: 'milestone',
                key: 'milestone'
            },
            {
                title: I18n.get("Data"),
                dataIndex: 'date',
                key: 'date'
            },
            {
                title: I18n.get("Entrega Parceiro"),
                dataIndex: 'deliveryPartner',
                key: 'deliveryPartner',
            },
            {
                title: I18n.get("Entrega Cliente"),
                dataIndex: 'deliveryClient',
                key: 'deliveryClient',
            }
        ]



        let dataTable = this.props.data  && this.props.data.map((item, index) => {
            return ({
                key: index,
                project: item.projectName,
                milestone: item.name,
                date: item.estimatedDate,
                deliveryPartner: item.partnerConfirmed ? <p className="checkMilestone"><Icon type="check-circle" theme="filled" /></p> : <p className="errorMilestone"><Icon type="close-circle" theme="filled" /></p>,
                deliveryClient: item.clientConfirmed ?  <p className="checkMilestone"><Icon type="check-circle" theme="filled" /></p> : <p className="errorMilestone"><Icon type="close-circle" theme="filled" /></p>,
            })
        });


        return (
            <Table
                pagination={this.props.pagination ? true : false}
                columns={columns}
                dataSource={dataTable}
                locale={{ emptyText: I18n.get('Sem informação') }}
                scroll={{ x: 1200 }}
                // expandedRowRender={record => <div><h2>Milestones</h2></div>}
            />
        );
    }
}

export default tableMilistone;
