import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Content from "../../../components/UI/Content";
import Main from "../../../components/UI/Main";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import { useMilestones } from "../../../hooks/useMilestones/useMilestones.hook";
import { useBriefings } from "../../../hooks/useBriefings/useBriefings.hook";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import Loading from "../../../components/UI/Loading";
import { H2Border } from "../../../components/UI/Titles";
import { Button } from "../../../components/UI/Button";
import Checkbox from "../../../components/UI/Checkbox";
import { TabsHorizontal } from "../../../components/UI/TabsHorizontal";
import { TableMacrosV2 } from "./components/TableMacrosV2";
import { ClientProjectStatus } from "../../../../model";

import { Row, Col, message } from "antd";

import TabsItemsClient from "../urls/urlsClient.json";
import TabsItemsPartner from "../urls/urlsPartner.json";
import * as S from "./styles";
import { FormMacro } from "./components/FormMacro";

const tableColumns = [
  "",
  I18n.get("Name"),
  I18n.get("Date"),
  I18n.get("Files"),
  I18n.get("Partner delivery"),
  I18n.get("Client approval"),
  ""
]


export const Macros = () => {
  let params = useParams();
  const auth = useSelector((state) => state.auth);
  const { getProjectById, mensalFee } = useProjects();
  const [projectId, setProjectId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [milestonesInfos, setMilestonesInfos] = useState([]);
  const [partnerHasSubmittedDefinitions, setPartnerHasSubmittedDefinitions] = useState(false);
  const [statusProject, setStatusProject] = useState(null);
  const [isFeeMensalCheck, setIsFeeMensalCheck] = useState(null);
  const [isSendingMensalFee, setIsSendingMensalFee] = useState(false);

  const { getMilestones } = useMilestones();
  const { getBriefing } = useBriefings();

  const loadMacros = async () => {
    if (params?.projectId) {
      setIsLoading(true);
      try {
        const response = await getMilestones({ id: params?.projectId });
        setMilestonesInfos(response);
        const responseBriefing = await getBriefing({ id: params?.projectId });
        setPartnerHasSubmittedDefinitions(responseBriefing?.data?.partnerHasSubmittedDefinitions);
        const responseProject = await getProjectById({ projectId: params?.projectId });
        setStatusProject(responseProject?.data?.status);
        setIsFeeMensalCheck(responseProject?.data?.isMensalFee);
      } catch (error) {
        return;
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadMacros();
  }, []);

  useEffect(() => {
    setProjectId(params.projectId);
  }, [params]);

  const onChangeCheckboxFee = async (check) => {
    try {
      setIsSendingMensalFee(true);
      setIsFeeMensalCheck(check);
      await mensalFee({ projectId: params.projectId, status: check });
      message.success(I18n.get("Fee updated successfully"));
      setIsSendingMensalFee(false);
    } catch (error) {
      message.error(I18n.get("Error updating fee"));
    }
  };

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Definições do Projeto")}</H2Border>
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <TabsHorizontal
                items={auth?.is_client_or_client_user ? TabsItemsClient : TabsItemsPartner}
                urlsPrefix={`/#/${auth?.is_client_or_client_user ? "cliente" : "parceiro"}/projetos/v2/${params.projectId}`}
                tabActive={2}
                keysDisableds={partnerHasSubmittedDefinitions ? [] : [4]}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={12} offset={6}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get('Milestones')}</S.H4>
                      </S.TitleWithIcon>
                    </S.RowCenter>
                  </Col>
                </Row>
                {isLoading ? (
                  <></>
                ) : partnerHasSubmittedDefinitions ? (
                  <Row>
                    <Col xs={24}>
                      <S.CloseScope>
                        <p>
                          {I18n.get(
                            'Escopo fechado ou em análise, briefing não pode ser alterado.'
                          )}
                        </p>
                      </S.CloseScope>
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col sm={24}>
                    <S.SubTitle>
                      <p>{I18n.get("Macro deliveries")}</p>
                      <span />
                    </S.SubTitle>
                  </Col>

                  <Row>
                    <Col xs={24}>
                      {isLoading ? (
                        <Loading
                          text={I18n.get("Loading...")}
                          sizeText={14}
                          sizeIcon={16}
                          color="#000000"
                          align="left"
                        />
                      ) : statusProject === 6 ? (
                        <S.CheckBoxContentNotAllowed>
                          <Checkbox
                            onChangeCb={(e, id) => true}
                            checked={isFeeMensalCheck}
                            checkboxSize={18}
                          />
                          <S.LabelCheckBox>{I18n.get("My project is of the MONTHLY FEE type, I don't need Macro Deliveries")}</S.LabelCheckBox>
                        </S.CheckBoxContentNotAllowed>
                      ) : (
                        isSendingMensalFee ? (
                          <Loading
                            text={I18n.get("Loading...")}
                            sizeText={14}
                            sizeIcon={16}
                            color="#000000"
                            align="left"
                          />
                        ) : (
                          <S.CheckBoxContent>
                            <Checkbox
                              onChangeCb={(e, id) => onChangeCheckboxFee(e.target.checked)}
                              checked={isFeeMensalCheck}
                              checkboxSize={18}
                            />
                            <S.LabelCheckBox>{I18n.get("My project is of the MONTHLY FEE type, I don't need Macro Deliveries")}</S.LabelCheckBox>
                          </S.CheckBoxContent>
                        )
                      )}
                    </Col>
                  </Row>

                  {!isFeeMensalCheck ? (
                    <>
                      <Col sm={24}>
                        {!auth?.is_client_or_client_user ? (
                          <FormMacro disableds={partnerHasSubmittedDefinitions} reload={loadMacros} />
                        ) : null}
                      </Col>

                      <Col sm={24}>
                        <TableMacrosV2
                          columns={tableColumns}
                          data={milestonesInfos}
                          loading={isLoading}
                          projectId={params.projectId}
                          reload={loadMacros}
                          disableds={partnerHasSubmittedDefinitions}
                          clientOrPartner={auth?.is_client_or_client_user ? "client" : "partner"}
                          statusProject={statusProject}
                        />
                      </Col>
                    </>
                  ) : null}
                </Row>

                <S.RowFooter>
                  <S.ButtonsFooter></S.ButtonsFooter>
                  <S.ButtonsFooter>
                    <Link
                      to={`/${auth?.is_partner ? "parceiro" : "cliente"
                        }/projetos/v2/${projectId}/briefing`}
                    >
                      <Button
                        text={I18n.get("Voltar")}
                        theme="black"
                        outline={true}
                        className="btnPrev"
                      />
                    </Link>
                    <Link
                      to={`/${auth?.is_partner ? "parceiro" : "cliente"
                        }/projetos/v2/${projectId}/review`}
                    >
                      <Button
                        text={I18n.get("Próximo: Review")}
                        theme="primary"
                      />
                    </Link>
                  </S.ButtonsFooter>
                </S.RowFooter>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
