import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useReview = () => {
  const getReviewDefinition = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${id}/review`,
      config
    );

    return data;
  };

  const getAssets = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    let queryString = Object.keys(infos)
      .map((key) => key + "=" + infos[key])
      .join("&");

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/Asset?${queryString}`,
      config
    );

    return data;
  };

  const getAssetsBundleFilters = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/FilterBundles`,
      config
    );

    return data;
  };

  const putNotes = async (dataNotes) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/notes`,
      dataNotes,
      config
    );

    return data;
  };

  const putAssets = async (dataAssets) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/AddAsset`,
      dataAssets,
      config
    );

    return data;
  };

  const sendToClient = async (dataSend) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/definition`,
      dataSend,
      config
    );

    return data;
  };

  const projectThreasholdAuthorization = async (dataSend) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/project-threashold-authorization`,
      dataSend,
      config
    );

    return data;
  };

  return {
    projectThreasholdAuthorization,
    sendToClient,
    getReviewDefinition,
    getAssets,
    getAssetsBundleFilters,
    putNotes,
    putAssets,
  };
};
