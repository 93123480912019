import React, { Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { 
    Row, 
    Col, 
    notification,
    Table, 
    Tag, 
    Modal,
    Drawer,
    Button 
} from 'antd';


import { Task } from '../../utils/task';



import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import styled from "styled-components";

import FormOriginSource from '../../modules/Admin/components/formOriginSource';

import { CardSimple } from '../../modules/components/Cards';
import TableAssets  from '../../modules/Admin/components/tableBundleClient';
import FormBundle  from '../../modules/Admin/components/formBundle';


// Jordi: Arquivo FormBundle nao subiu no git, comentando para o build passar

const { confirm } = Modal;

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_bundle_client_source: () => {
            dispatch(BP_API.get_bundle_client_source());
        },
        get_bundle_client: (data) => {
            dispatch(BP_API.get_bundle_client(data));
        },
        update_client_bundle: (data) => {
            dispatch(BP_API.update_client_bundle(data));
        },
        create_client_bundle: (data) => {
            dispatch(BP_API.create_client_bundle(data));
        },
        delete_client_bundle: (data) => {
            dispatch(BP_API.delete_client_bundle(data));
        },
        export_bundle: (data) => {
            dispatch(BP_API.export_bundle(data));
        },
        reset: () => {
            dispatch(BP_API.reset());
        }
        
    };
};





const ContainerTable = styled.div`
    background: #fff;
    padding: 50px;
    .RowBtn {
        margin-bottom: 80px;

        p {
            margin-bottom: 20px;
        }

        .tit {
            font-weight: bold;
        }
    }
`


class Assets extends React.Component {

    constructor(props) {

        super();

        this.state = {
            task: {},
            showForm: false,
            editItem: {},
            id: "",
            loadingPage: false,
            updateList: false
        };
    }

    componentWillMount = () => {
        this.props.reset();

    }

    componentDidMount = () => {
        this.props.get_bundle_client_source();
    }

    hadleSource = (data) => {
         var task = new Task();

        task.start();

        this.setState({ task: task, id: data.clientId, updateList: true}, function () {
            this.props.get_bundle_client(data);
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {
            this.setState({ task: task });
        }



        if (this.state.loadingPage && this.props.bp && this.props.bp.clientBundle && prevProps.bp.clientBundle && prevProps.bp.clientBundle.bundles && this.props.bp.clientBundle.bundles.length > prevProps.bp.clientBundle.bundles.length && !this.state.updateList) {

            notification.success({
                message: I18n.get('Adicionado com sucesso!'),
                description: 'O bundle foi adicionado',
            });

            this.closeDrawer()
        }


        if (this.state.loadingPage && this.props.bp.clientBundle && prevProps.bp.clientBundle && prevProps.bp.clientBundle.bundles && this.props.bp.clientBundle.bundles.length < prevProps.bp.clientBundle.bundles.length && !this.state.updateList) {

            notification.success({
                message: I18n.get('Deletado com sucesso!'),
                description: 'O bundle foi removido',
            });

            this.closeDrawer()
        }


         if (this.props.bp.clientBundle && prevProps.bp.clientBundle && prevProps.bp.clientBundle.bundles && this.props.bp.clientBundle.bundles != prevProps.bp.clientBundle.bundles && Object.keys(this.state.editItem).length > 0 && !this.state.updateList) {

            notification.success({
                message: I18n.get("Atualizado com sucesso!"),
                description: I18n.get('O bundle foi atualizado'),
                duration: 3,
            });

            this.setState({
                editItem: {}
            })

            this.closeDrawer();
        }




    }

     handleEdit = (item) => {

        this.setState({
            editItem: item
        });

        setTimeout(() => {
            this.showForm();
        }, 500);
    }

    showForm = () => {
        this.setState({
            showForm: true
        })
    }

    closeDrawer = () => {
        this.setState({
            showForm: false,
            editItem: {}
        })
    }

    handleExport = () => {
        const dataSend = {
            type:"B",
            clientId: this.state.id,
            email: this.props.auth.email
        } 

        this.props.export_bundle(dataSend)
    }

    handleSend = (data) => {
        const dataSend = {
            ...data,
            id: this.state.editItem && this.state.editItem.id,
            clientId: this.state.id
        }

        var task = new Task();
        task.start();


        if(Object.keys(this.state.editItem).length > 0){
            confirm({
                title: I18n.get('Deseja atualizar o bundle?'),
                // content: contentBox,
                cancelText: I18n.get("Não"),
                okText: I18n.get("Sim"),
                onOk:() => {
                    this.setState({ task: task, loadingPage: true, updateList: false }, function () {
                         this.props.update_client_bundle(dataSend);
                    });
                },
                onCancel:() => {
                    this.closeDrawer();
                },
            });
            
        } else {
            confirm({
                title: I18n.get('Deseja criar o bundle?'),
                // content: contentBox,
                cancelText: I18n.get("Não"),
                okText: I18n.get("Sim"),
                onOk:() => {
                    this.setState({ task: task, loadingPage: true, updateList: false }, function () {
                        this.props.create_client_bundle(dataSend);
                    });
                },
                onCancel:() => {
                    this.closeDrawer();
                },
            });
            
        }
    }

    handleEditActive = (data) => {

         const dataSend = {
            ...data,
            id: data.id,
            clientId: this.state.id
        }

        var task = new Task();
        task.start();

            confirm({
                title: I18n.get('Deseja atualizar o bundle?'),
                // content: contentBox,
                cancelText: I18n.get("Não"),
                okText: I18n.get("Sim"),
                onOk:() => {
                    this.setState({ task: task, updateList: false }, function () {
                         this.props.update_client_bundle(dataSend);
                    });
                },
                onCancel:() => {
                    this.closeDrawer();
                },
            });
    }

    handleAdd = () => {
        this.setState({
            editItem: {},
            showForm: true
        })
    }

    handleDelete = (id) => {
        const dataDelete = {
            clientId: this.state.id,
            bundleId: id
        }

         confirm({
            title: I18n.get('Deseja deletar o bundle?'),
            // content: contentBox,
            cancelText: I18n.get("Não"),
            okText: I18n.get("Sim"),
            onOk:() => {
                this.setState({ loadingPage: true, updateList: false }, function () {
                    this.props.delete_client_bundle(dataDelete)
                });                    
            },
            onCancel:() => {
                this.closeDrawer();
            },
        });

        
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/clientes-combo" className="is-black">{I18n.get("Combos")}</NavLink></li>
                    </ul>
                </nav>

                 <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            <FormOriginSource
                                filters={this.props.bp && this.props.bp.bundleClientSource}
                                action={this.hadleSource}
                                task={this.state.task}

                            />
                        </CardSimple>
                    </Col>
                </Row>
                <Row gutter={6}>
                    <Col sm={24}>
                        &nbsp;
                    </Col>
                </Row>


                <ContainerTable>
                    <Row gutter={6}>
                        <Col sm={24}>
                            <Button disabled={!this.state.id} onClick={() => this.handleAdd()} className="button bt-bpool black pull-right">+ {I18n.get("Adicionar Bundle")}</Button>
                            <Button disabled={!this.state.id} onClick={() => this.handleExport()} className="button bt-bpool pull-right" style={{marginRight: "10px"}}> {I18n.get("Exportar")}</Button>
                        </Col>
                    </Row>
                    <Row gutter={6}>
                        <Col sm={24}>
                            &nbsp;
                        </Col>
                    </Row>
                    <TableAssets    
                        edit={this.handleEdit}
                        delete={this.handleDelete}
                        editActive={this.handleEditActive}
                        task={this.state.task}
                        data={this.props.bp && this.props.bp.clientBundle && this.props.bp.clientBundle.bundles || []}
                        assetSpecialisms={this.props.bp && this.props.bp.clientBundle && this.props.bp.clientBundle.assetSpecialisms}
                        partnerSpecialismsArray={this.props.bp && this.props.bp.clientBundle && this.props.bp.clientBundle.partnerSpecialisms}
                        assetTypeArray={this.props.bp && this.props.bp.clientBundle && this.props.bp.clientBundle.assetTypes}
                    />
                </ContainerTable>


                <Drawer
                    title={this.state.editItem && Object.keys(this.state.editItem).length > 0 ? I18n.get("Atualizar") : I18n.get("Adicionar")}
                    placement="right"
                    closable={true}
                    onClose={() => this.closeDrawer()}
                    destroyOnClose={true}
                    visible={this.state.showForm}
                    width="60%"
                >
                    <FormBundle
                        action={this.handleSend}
                        onCancel={this.closeDrawer}
                        errors={this.props.bp.errors && this.props.bp.errors}
                        type={this.state.type}
                        item={this.state.editItem}
                        task={this.state.task}
                        assets={this.props.bp && this.props.bp.clientBundle && this.props.bp.clientBundle.assets}
                        assetTypes={this.props.bp && this.props.bp.clientBundle && this.props.bp.clientBundle.assetTypes}
                        assetSpecialisms={this.props.bp && this.props.bp.clientBundle && this.props.bp.clientBundle.assetSpecialisms}
                        partnerSpecialismsArray={this.props.bp && this.props.bp.clientBundle && this.props.bp.clientBundle.partnerSpecialisms}
                    />
                </Drawer>


            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
