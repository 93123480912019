import React from 'react';
import { I18n } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { Task } from '../../utils/task';

import { notification } from 'antd';

import { PROJECT_API } from '../../actions/project/actions';

import MilestoneList from '../shared/milestone-list';
import MilestoneForm from '../shared/milestone-form';

class ScheduleForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            projectId: props.match.params.projectId,
            form: {
                isSubmit: false,
            },
            slot: null,
            checkpoint: null,
            view: 'SCHEDULE',
            mode: '',
            task: {},
        };

        this.setMode = this.onSetMode.bind(this);
        this.setView = this.onSetView.bind(this);
    }

    onSetMode(mode, e) {

        e.preventDefault();

        this.setState({ mode: mode });

    }

    onSetView(view, e) {

        e.preventDefault();

        this.setState({ view: view });

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.project && this.props.project.task;

        if (!prevState) return;
        if (!task) return;

        var nextState = Object.assign({}, prevState);

        if (task.id != prevState.task.id) {

            nextState.task = task;

            this.setState(nextState);
        }
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
        };

        return data;
    }

    createMilestone(values) {

        const { projectId, extraId } = this.props.match.params;

        var data = {
            projectId,
            extraId,
            partnerId: this.props.partner.id,
            ...values,
        };

        this.props.create_event_quotation_milestone(data)
            .then(({ task, errors }) => {

                if (task.result) {

                    notification.success({
                        message: I18n.get(I18n.get('Adicionado com sucesso!')),
                        description:
                            I18n.get('Macro entrega foi adicionada'),
                    });
                }

            });
    }

    deleteMilestone(milestoneId) {

        const { projectId, extraId } = this.props.match.params;

        var data = {
            projectId,
            extraId,
            partnerId: this.props.partner.id,
            id: milestoneId,
        };

        this.props.delete_event_quotation_milestone(data)
            .then(({ task, errors }) => {

                if (task.result) {

                    notification.success({
                        message: I18n.get('Removido com sucesso!'),
                        description:
                            I18n.get('Macro entrega foi removida'),
                    });
                }

            });

    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    render() {

        const { quotation = {} } = this.props;

        const { milestones = [] } = quotation;

        const canEdit = this.props.auth.is_partner && quotation.isOpen;

        return (
            <div>

                <p className="title is-5">{I18n.get('Milestones')}</p>
                {canEdit && <>
                    <p class="subtitle is-6">{I18n.get('Detalhe abaixo datas macro datas de entrega para acompanhamento do projeto.')}</p>
                </>}

                <br /><br />

                <div style={{ backgroundColor: '#ffffff' }} className="container-calendar">

                    <div className="has-text-centered schedule-title">{I18n.get("Datas e Entregas")}<br />
                    </div>

                    <div style={{ backgroundColor: '#ffffff' }}>

                        <MilestoneList loading={{}} milestones={milestones} onDelete={this.deleteMilestone.bind(this)} canDelete={canEdit} />

                        {canEdit && <>

                            <MilestoneForm milestones={milestones} onCreate={this.createMilestone.bind(this)} disabled={!canEdit} />

                        </>}
                    </div>

                </div>

            </div>
        );
    }
}

export default withRouter(ScheduleForm);
