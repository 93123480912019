export const REGISTRATION_KEY_STORAGE = "@BPOOL-registration-flow";

const statusToName = (status) => {
  if (status === 1) {
    return "process";
  } else if (status === 2) {
    return "error";
  } else if (status === 3) {
    return "wait";
  } else if (status === 4) {
    return "finish";
  }
};

export const toOrganizeSteps = async ({
  arrSteps,
  contextStepBar,
  pageIndex,
  urls,
}) => {
  const stepsUpdateds = arrSteps.map((step) => ({
    ...step,
    status:
      contextStepBar === "pre" && step.step === 0
        ? "finish"
        : step.step === pageIndex
        ? "process"
        : statusToName(step.status),
    visited: false,
    isStartup: false,
    url: urls[step.step],
    description: step.step === 0 ? "Completed" : step.description,
  }));
  stepsUpdateds.sort((a, b) => (a.step > b.step) - (a.step < b.step));
  return stepsUpdateds;
};

export const toflowToStorage = async ({ key, stepbar }) => {
  const stringify = JSON.stringify(stepbar);

  localStorage.setItem(key, stringify);
};

export const toGetFlowStorage = async ({ key }) => {
  const flowStorage = localStorage.getItem(key);

  const flow = JSON.parse(flowStorage);

  return flow;
};

export const toRemoveFlowStorage = async ({ key }) => {
  localStorage.removeItem(key);
};

export const toUpdateFlowStorageSteps = async ({ screen }) => {
  const flow = await toGetFlowStorage({
    key: "@BPOOL-registration-flow-steps",
  });

  const actualStep = flow.steps.find((step) => step.step === screen);
  const indexActualStep = flow.steps.findIndex((item) => item.step === screen);

  const flowStepsTemp = [...flow.steps];

  flowStepsTemp[indexActualStep] = { ...actualStep, visited: true };

  const flowUpdated = {
    stepbarId: flow.stepbarId,
    steps: [...flowStepsTemp],
  };

  let flowStepsTempAwardUpdate = {};

  if (screen === 13) {
    flowStepsTempAwardUpdate = {
      ...flowUpdated,
      steps: flowUpdated.steps.map((item) =>
        item?.step === 13 ? { ...item, status: "finish" } : item
      ),
    };
  }

  let flowStepsTempTeamRegistrationUpdate = {};
  if (screen === 15) {
    flowStepsTempTeamRegistrationUpdate = {
      ...flowUpdated,
      steps: flowUpdated.steps.map((item) =>
        item?.step === 15
          ? { ...item, status: "finish", statusDescription: "finish" }
          : item
      ),
    };
  }

  let stepbarUpdated = {};

  if (screen === 13) {
    stepbarUpdated = flowStepsTempAwardUpdate;
  } else if (screen === 15) {
    stepbarUpdated = flowStepsTempTeamRegistrationUpdate;
  } else {
    stepbarUpdated = flowUpdated;
  }

  await toflowToStorage({
    key: "@BPOOL-registration-flow-steps",
    stepbar: stepbarUpdated,
  });
};

export const toUpdateStatusInStep = async ({ screen, status }) => {
  const flow = await toGetFlowStorage({
    key: "@BPOOL-registration-flow-steps",
  });

  const actualStep = flow.steps.find((step) => step.step === screen);
  const indexActualStep = flow.steps.findIndex((item) => item.step === screen);

  const flowStepsTemp = [...flow.steps];

  flowStepsTemp[indexActualStep] = { ...actualStep, visited: true, status };

  const flowUpdated = {
    stepbarId: flow.stepbarId,
    steps: [...flowStepsTemp],
  };

  await toflowToStorage({
    key: "@BPOOL-registration-flow-steps",
    stepbar: flowUpdated,
  });
};

export const flowToStorage = async (data) => {
  const stringify = JSON.stringify(data);

  localStorage.setItem(REGISTRATION_KEY_STORAGE, stringify);
};

export const getFlowStorage = async () => {
  const flowStorage = localStorage.getItem(REGISTRATION_KEY_STORAGE);

  const flow = JSON.parse(flowStorage);

  return flow;
};

export const updateFlowStorage = async ({ screen }) => {
  const flow = await toGetFlowStorage({
    key: "@BPOOL-registration-flow-steps",
  });

  const toStorage = flow.steps.map((item, index) => ({
    ...item,
    visited: screen === item.key ? true : item.visited,
  }));

  await toflowToStorage({
    key: "@BPOOL-registration-flow-steps",
    stepbar: toStorage,
  });
};

export const toRemoveAnStep = async ({ stepToRemove }) => {
  const flow = await toGetFlowStorage({
    key: "@BPOOL-registration-flow-steps",
  });

  const flowStepsTempWithoutIndustry = flow.steps.filter(
    (step) => step.id !== stepToRemove
  );

  const flowStepsTemp = flowStepsTempWithoutIndustry.map((step, index) => ({
    ...step,
    step: index,
  }));
  flowStepsTemp.sort((a, b) => (a.step > b.step) - (a.step < b.step));

  const flowUpdated = {
    stepbarId: flow.stepbarId,
    steps: [...flowStepsTemp],
  };

  await toflowToStorage({
    key: "@BPOOL-registration-flow-steps",
    stepbar: flowUpdated,
  });
};

export const toUpdateIsStartupInSteps = async ({ status }) => {
  const flow = await toGetFlowStorage({
    key: "@BPOOL-registration-flow-steps",
  });

  const flowStepsTemp = flow.steps.map((step) => ({
    ...step,
    isStartup: status,
  }));

  const flowUpdated = {
    stepbarId: flow.stepbarId,
    steps: [...flowStepsTemp],
  };

  await toflowToStorage({
    key: "@BPOOL-registration-flow-steps",
    stepbar: flowUpdated,
  });
};

export const removeFlowStorage = async () => {
  localStorage.removeItem(REGISTRATION_KEY_STORAGE);
};
