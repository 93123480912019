import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 16px;
  margin-top: 32px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props?.gridColumns};
  column-gap: 10px;
`;

export const Col = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => (props?.actions ? "flex-end;" : null)};

  h3 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    height: 40px;
    margin: 8px 0 8px 0;
  }
`;

export const ButtonAction = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  background: transparent;
  color: #1883ff;
  line-height: 22px;
  border: none;

  span {
    top: 2px;
    position: relative;
    margin-left: 8px;
    font-weight: 600;
    font-size: 14px;
    color: #1883ff;
  }
`;

export const Info = styled.div`
  width: 100%;

  label {
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 600;
  }
  p {
    height: 40px;
    line-height: 20px;
    margin: 8px 0 8px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    height: 40px;
  }
`;

export const CheckboxItem = styled.div`
  width: fit-content;
  .ant-checkbox {
    top: 2px;
  }
  label {
    cursor: pointer;
    margin: 0;
    display: flex;
  }
  p {
    cursor: pointer;
    margin: 0;
    font-size: 14px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }
  .ant-checkbox-inner {
    border-radius: 50%;
    border: 1px solid #e8e8e8;
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-infos {
    height: 20px;
    margin-bottom: 28px;

    li {
      height: 100%;
    }
  }
`;
