import React from 'react';

import { I18n } from 'aws-amplify';

import './quotation-item-form.css';

import { Timeline } from 'antd';

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';

import TextAreaField from '../../form/textarea-field';

import { getQuotationStatus } from '../../../utils/extras';


class QuotationItemForm extends React.Component {

    constructor(props) {

        super();
        this.state = {
            form: {
                partnerNotes: {
                    label: I18n.get("Comentários do Parceiro"),
                    value: props.quotation && props.quotation.partnerNotes,
                    onChange: this.onChangeForm.bind(this, 'partnerNotes'),
                    error: props.errors.partnerNotes,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'partnerNotes'),
                    visited: true,
                },
                clientNotes: {
                    label: I18n.get("Comentários do Cliente"),
                    value: props.quotation && props.quotation.clientNotes,
                    onChange: this.onChangeForm.bind(this, 'clientNotes'),
                    error: props.errors.clientNotes,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'clientNotes'),
                    visited: true,
                },
            },
            task: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        if (JSON.stringify(task) != JSON.stringify(prev)) {

            console.dir({ task: task, prev: prev });

            console.dir({ task: JSON.stringify(task), prev: JSON.stringify(prev) });

            var nextState = Object.assign({}, prevState, { task: task });

            const quotation = this.props.quotation || {};

            Object.keys(this.state.form).forEach((item) => {

                if (FormUtils.isFormField(nextState.form[item])) {

                    nextState.form[item].error = this.props.errors[item];

                    if ('checked' in nextState.form[item]) {
                        nextState.form[item].checked = quotation[item];
                    }
                    else {
                        nextState.form[item].value = quotation[item];
                    }
                }
                else {
                    nextState.form[item] = quotation[item];
                }
            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onSave(item) {

        if (!this.props.canEditPartner && !this.props.canEditClient) return;

        // zera o erro
        if (FormUtils.isFormField(this.state.form[item])) {
            this.state.form[item].error = null;
        }

        // Espera
        if (this.state.task.busy) return;

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {

            this.props.updateExtraQuotation(this.getData());
        });
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            quotationId: this.props.quotation.id,
        };

        Object.keys(this.state.form).forEach((item) => {

            if (FormUtils.isFormField(this.state.form[item])) {

                data[item] = this.state.form[item].value;
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    render() {

        const quotation = this.props.quotation || {};

        const history = this.props.quotationHistory && this.props.quotationHistory.filter((q) => q.id != quotation.id && !!q.submittedOn);

        return (
            <div>

                <h1 className="title">{I18n.get("Espaço para comentários")}</h1>

                <br />

                {history && history.length > 0 && <>

                    {I18n.get("Histórico")}

                    <div style={{ padding: '1rem', backgroundColor: '#fffff' }}>

                        <Timeline>

                            {history.map((q) => {
                                return (
                                    <Timeline.Item>
                                        <p>
                                            <span style={{ fontSize: '90%' }}>
                                                {I18n.get("Orçamento enviado em")} {q.submittedOn} &middot; {getQuotationStatus(this.props.auth, q)}
                                            </span>
                                            <ul style={{ fontSize: '80%' }}>
                                                <li>{I18n.get('Parceiro')}: {q.partnerNotes}</li>
                                                <li>{I18n.get('Cliente')}: &nbsp; {q.clientNotes}</li>
                                            </ul>
                                        </p>
                                    </Timeline.Item>
                                );
                            })}
                        </Timeline>

                    </div>

                </>}

                <TextAreaField {...this.state.form.partnerNotes} readOnly={!this.props.canEditPartner} />

                <TextAreaField {...this.state.form.clientNotes} readOnly={!this.props.canEditClient} />

                <br />


            </div>
        );
    }
}

export default QuotationItemForm;
