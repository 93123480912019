import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import CheckboxField from '../form/checkbox-field';


class NewCompanyForm extends React.Component {

    state = {
        form: {
            isNewCompany: {
                label: I18n.get("Minha empresa tem menos de 6 meses"),
                value: this.props.company && this.props.company.isNewCompany && this.props.company.isNewCompany,
                checked: this.props.company && this.props.company.isNewCompany && this.props.company.isNewCompany,
                successIcon: false,
            },
        },
        task: {},
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {


                if (FormUtils.isFormField(nextState.form[item])) {

                    nextState.form[item].error = this.props.errors[item];

                    if ('checked' in nextState.form[item]) {
                        nextState.form[item].checked = this.props.company && this.props.company[item] && this.props.company[item];
                    }
                    else {
                        nextState.form[item].value = this.props.company[item] && this.props.company[item];
                        nextState.form[item].checked = this.props.company[item] && this.props.company[item];
                    }
                }
                else {
                    nextState.form[item] = this.props.company[item];
                    nextState.form[item].checked = this.props.company[item] && this.props.company[item];

                }
            });

            this.setState(nextState);
        }


    }

    onChangeIsNew = (prop, e) => {
        const checked = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].checked = checked;
            nextState.form[prop].value = checked;

            return nextState;
        }, function () {
            var data = {
                partnerId: this.props.partnerId,
                isNewCompany: checked
            };

            this.props.new_company(data);
        });


    }



    render() {

        return (<section>
            <div className="columns spaceCheck">
                <div className="column is-12">
                    <CheckboxField
                        {...this.state.form.isNewCompany}
                        onChange={prop => this.onChangeIsNew('isNewCompany', prop)}
                    />
                </div>
            </div>

        </section>)
    }
}

export default NewCompanyForm;
