import React from 'react';

const FieldErrorMessage = ({ fields = [], errors = {} }) => {

    return (
        <p className="has-text-danger" style={{ fontSize: '80%'}}>
            <ul>
                {fields && fields.map((field)=> {
                    return (errors[field] && errors[field].errorMessage && <li>{errors[field].errorMessage}</li>);
                })}
            </ul>
        </p>
    );
};

export default FieldErrorMessage;