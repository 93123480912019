import styled from 'styled-components';
import { Button } from 'antd';

export const Wrapper = styled.div``;

const ButtonStyled = styled.div`
  padding: 0;
  border: none;
  background: transparent;

  img {
    width: 16px;
    height: 16px;
  }
`;

export { ButtonStyled as Button };
