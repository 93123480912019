import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Redirect, Link } from "react-router-dom";
import Loading from '../../../components/pages/loading';
import moment from 'moment';
import {
    Form,
    Select,
    Collapse,
    InputNumber,
    Switch,
    Radio,
    Slider,
    Button,
    Upload,
    Icon,
    Input,
    Table,
    Rate,
    TreeSelect,
    Checkbox,
    Row,
    Col,
    DatePicker,
    Alert,
    Avatar
} from 'antd';

import WhatifWrapper from '../components/WhatifWrapper';
import { IDEA_WHATIF_API } from '../../../actions/idea-whatif/actions';
import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';

import { IdeaWhatIfStatus } from '../../../model';


import t1 from '../../../image/thumb-1.jpg';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import { TitleBorder, TitleMiddle, TitleMiddleLine, TitleMedium } from '../components/Titles';
import { CardBorder } from '../components/Cards'
import MultUploadFile from '../components/MultUploadFile';
import ResumeTable from '../components/ResumeTable';

const { TextArea, Search } = Input;
const CheckboxGroup = Checkbox.Group;

const { MonthPicker, RangePicker } = DatePicker;


const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const FieldCustom = styled.div`

`
const Main = styled.div`
  max-width: 820px;
  margin: 0 auto 100px;
  padding-top: 80px;
  width: 100%;
  box-sizing: border-box;

  .BoxTerms {
      margin-top: 90px;
  }

  #offers {
      display:none;
  }
`;

const ThumbClient = styled.div`
    margin-top: 80px;
    .TitMarca {
        font-size: 23px;
        font-weight: 600;
        line-height: 37px;
        color: #353535;
        margin-left: 20px;
    }
`;

const RowType = styled(Row)`
  .ant-radio-group.ant-radio-group-outline {
      width: 100%;
  }

  .ant-form-item {
      margin-bottom: 0;
  }
`;

const RowPrices = styled(Row)`
  height: 0;
  overflow: hidden;
  transition: height .2s ease;

  .ant-table-wrapper {
    opacity: 0;
    transition: opacity .5s ease;
  }
  &.open {
      height: auto;

      .ant-table-wrapper {
        opacity: 1;
    }
  }
`;

const RowSubmit = styled(Row)`
  button {
      margin-right: 20px;
  }
`;

const BoxAddFild = styled(Search)`
    button {
        position:relative;

        i {
            display: none;
        }
    }
`;

const BoxTerm = styled.div`
  background: #fff;
  padding: 20px;
  height: 200px;
  overflow-x: hidden;

  p {
    color: #353535;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 15px;
  }
`;

const optionsSocial = [
    { label: I18n.get("Instagram"), value: 'Instagram' },
    { label: I18n.get("Insta Stories"), value: 'Insta Stories' },
    { label: I18n.get("Facebook"), value: 'Facebook' },
    { label: I18n.get("Twitter"), value: 'Twitter' },
];

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        create: data => {
            dispatch(IDEA_WHATIF_API.create(data));
        },
        update: data => {
            dispatch(IDEA_WHATIF_API.update_idea(data));
        },
        upload: data => {
            return IDEA_WHATIF_API.upload(data);
        },
        get_idea_detail: data => {
            dispatch(IDEA_WHATIF_API.get_idea_detail(data));
        },
        get_detail_products: id => {
            dispatch(PARTNER_WHATIF_API.get_detail_products(id))
        },
        delete_detail: () => {
            dispatch(IDEA_WHATIF_API.delete_detail())
        }
    };
};



class IdeaRegister extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            links: [],
            portfolios: [],
            referenceVideos: [],
            referenceImages: [],
            cases: [],
            jobOffer: "",
            jobValorIdea: 0,
            jobValorProduction: 0,
            statusBlur: false,
            fieldBlur: "",
            isSubmit: false,
            loadingImagens: false
        };
    }

    onChange = e => {

        this.setState({
            value: e.target.value,
        });
    };

    onChangeJob = e => {

        this.setState({
            jobOffer: e.target.value,
        });
    };

    onChangeIdeaValue = e => {

        this.setState({
            jobValorIdea: e.target.value
        });
    };

    onChangeProductionValue = e => {

        this.setState({
            jobValorProduction: e.target.value
        });
    };

    getDataUploadMult = (files) => {
        this.setState({
            referenceImages: files,
            statusBlur: true
        });


        setTimeout(() => {
            this.sendDataApi();
        }, 1);
    }

    onCreateIdea = () => {
        const {
            match: {
                params: {
                    whatIfId
                }
            }
        } = this.props;
        this.props.create(whatIfId);
    }

    onBlur = (e, field) => {
        if (this.props.ideaWhatIf && !this.props.ideaWhatIf.whatIfIdeaId && field == "name") {
            return null;
        } else {
            this.setState({
                statusBlur: true,
                fieldBlur: field,
                isSubmit: false
            });

            setTimeout(() => {
                this.sendDataApi();
            }, 1);
        }

    };

    sendDataApi = () => {
        const fieldsForm = this.props.form.getFieldsValue();

        const dataForm = {
            ...fieldsForm,
            partnerWhatIfId: this.props.partnerWhatIf.id,
            whatIfIdeaId: this.props.ideaWhatIf.ideaDetail.whatIfIdeaId,
            referenceImages: this.state.referenceImages,
            referenceVideos: this.state.referenceVideos,
            offers: [{
                jobOffer: fieldsForm.jobOffer,
                jobOfferPriceIdea: fieldsForm.jobOfferPriceIdea,
                jobOfferPriceProduction: fieldsForm.jobOfferPriceProduction,
                deadlineCommitment: fieldsForm.deadlineCommitment,
                deadlineDate: fieldsForm.deadlineDate,
                deadlineHour: fieldsForm.deadlineHour
            }],
            isSubmit: this.state.isSubmit
        }

        this.props.update(dataForm);
    }

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            statusBlur: false,
            isSubmit: true
        })

        this.props.form.validateFields((err, values) => {

            if (!err) {
                this.setState({
                    formState: values,
                    isSubmit: true
                })

                setTimeout(() => {
                    this.sendDataApi();
                }, 100);

            }
        });
    };

    getDataUpload = (field, data) => {
        this.setState({ [field]: data })
        return data;
    }

    componentDidMount() {

        this.props.delete_detail();

        const {
            match: {
                params: {
                    whatIfId,
                    whatIfIdeaId
                }
            }
        } = this.props;

        if (whatIfIdeaId) {
            const data = {
                id: this.props.match.params.whatIfIdeaId,
                prefix: "PartnerWhatIf"
            }

            this.props.get_idea_detail(data);
        }



        this.props.get_detail_products(whatIfId)
    }

    componentWillMount = () => {
        this.props.delete_detail();
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.ideaWhatIf.errors != prevProps.ideaWhatIf.errors && this.props.ideaWhatIf.errors && !this.state.statusBlur) {
            Object.keys(this.props.ideaWhatIf.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.ideaWhatIf.errors[field].errorMessage)],
                    },
                });
            });
        };

        if (this.props.ideaWhatIf.errors != prevProps.ideaWhatIf.errors && this.props.ideaWhatIf.errors[this.state.fieldBlur] && this.state.statusBlur) {

            this.props.form.setFields({
                [this.state.fieldBlur]: {
                    errors: [new Error(this.props.ideaWhatIf.errors[this.state.fieldBlur].errorMessage)],
                },
            });
        }

        if (this.props.ideaWhatIf.errors != prevProps.ideaWhatIf.errors && !this.props.ideaWhatIf.errors.offers) {
            this.props.form.setFields({
                offers: {
                    errors: null,
                },
            });
        }

        if (this.props.ideaWhatIf != prevProps.ideaWhatIf && this.props.ideaWhatIf.ideaDetail && this.props.match.params.whatIfIdeaId && !this.state.loadingImagens) {
            let listImg = this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.referenceImages && this.props.ideaWhatIf.ideaDetail.referenceImages.map((item, index) => {
                return {
                    uid: index,
                    name: 'image.png',
                    status: 'done',
                    url: item
                }
            });

            this.setState({
                referenceImages: this.props.ideaWhatIf.ideaDetail.referenceImages,
                referenceVideos: this.props.ideaWhatIf.ideaDetail.referenceVideos,
                listImg: listImg,
                loadingImagens: true
            });
        }

    }


    render() {
        if (!this.props.ideaWhatIf && this.props.match.params.whatIfIdeaId) {
            return (<Loading />);
        }
        const columnsIdea = [
            {
                title: I18n.get("Tipo"),
                dataIndex: 'tipo',
                key: 'tipo',
                render: (title, record) => (
                    <Radio onChange={this.onChangeIdeaValue} value={record.money}>{title}</Radio>
                )
            },
            {
                title: I18n.get("Valor"),
                dataIndex: 'valor',
                key: 'valor'
            },
            {
                title: I18n.get("Info"),
                dataIndex: 'info',
                key: 'info',
                render: (info, record) => (
                    <Fragment>
                        <a data-tip='' data-for={`info-${record.key}`} title="">
                            <Icon type="question-circle" />
                        </a>
                        <ReactTooltip id={`info-${record.key}`}>
                            <div className="tooltip-bp-content">

                                <p>{info}{record.key}</p>
                            </div>
                        </ReactTooltip>
                    </Fragment>
                )
            }
        ];

        const columnsProduction = [
            {
                title: I18n.get("Tipo"),
                dataIndex: 'tipo',
                key: 'tipo',
                render: (title, record) => (
                    <Radio onChange={this.onChangeProductionValue} value={record.money}>{title}</Radio>
                )
            },
            {
                title: I18n.get("Valor"),
                dataIndex: 'valor',
                key: 'valor'
            },
            {
                title: I18n.get("Info"),
                dataIndex: 'info',
                key: 'info',
                render: (info, record) => (
                    <Fragment>
                        <a data-tip='' data-for={`info-${record.key}`} title="">
                            <Icon type="question-circle" />
                        </a>
                        <ReactTooltip id={`info-${record.key}`}>
                            <div className="tooltip-bp-content">

                                <p>{info}{record.key}</p>
                            </div>
                        </ReactTooltip>
                    </Fragment>
                )
            }
        ];

        const dataValuesTypes = [
            {
                key: '1',
                tipo: 'Campanha',
                valor: 'R$ 50.000,00',
                info: "teste",
                money: 50000.00
            },
            {
                key: '2',
                tipo: 'Experiência',
                valor: 'R$ 20.000,00',
                info: "teste",
                money: 20000.00
            },
            {
                key: '3',
                tipo: 'Post',
                valor: 'R$ 5.000,00',
                info: "teste",
                money: 5000.00
            }
        ];


        const dataProduct = [
            {
                key: '4',
                tipo: 'Platinum',
                valor: 'R$ 20.000,00',
                info: "teste",
                money: 20000.00
            },
            {
                key: '5',
                tipo: 'Gold',
                valor: 'R$ 15.000,00',
                info: "teste",
                money: 15000.00
            },
            {
                key: '6',
                tipo: 'Silver',
                valor: 'R$ 10.000,00',
                info: "teste",
                money: 10000.00
            },
            {
                key: '7',
                tipo: 'Bronze',
                valor: 'R$ 5.000,00',
                info: "teste",
                money: 5000.00
            }
        ];

        const { getFieldDecorator } = this.props.form;

        const {
            match: {
                params: {
                    whatIfId,
                    whatIfIdeaId
                }
            },
            partnerWhatIf: {
                products,
                projectDetail
            }

        } = this.props;



        if (this.props.ideaWhatIf.isSubmit && this.props.ideaWhatIf.status === IdeaWhatIfStatus.Created && Object.keys(this.props.ideaWhatIf.errors).length === 0) {
            return (<Redirect to={`/parceiro-ideias/cadastro/ideia/${whatIfId}/sucesso`} />);
        }


        return (
            <Fragment>
                <WhatifWrapper>
                    {/* <SubHeader /> */}
                    <ContainerPage>
                        <Main>
                            <CardBorder
                                Bcolor="#fff"
                            >
                                <TitleBorder>Proposta ou ideia</TitleBorder>

                                <ThumbClient>
                                    {projectDetail && projectDetail.imageLogo && projectDetail.imageLogo &&
                                        <Avatar src={projectDetail.imageLogo} size={80} />
                                    }
                                    <span className="TitMarca">
                                        {projectDetail && projectDetail.brandName && projectDetail.brandName}
                                    </span>
                                </ThumbClient>
                            </CardBorder>
                            <Form onSubmit={this.handleSubmit}>
                                <CardBorder
                                    Bcolor="#c7e5f2"
                                >
                                    <Row>
                                        <Col sm={24}>
                                            <Form.Item label="De uma nome para sua ideia ou contéudo">
                                                {getFieldDecorator('name', {
                                                    rules: [{ required: false, message: 'ops!' }],
                                                    initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.name ? this.props.ideaWhatIf.ideaDetail.name : null
                                                })(
                                                    <Input
                                                        maxLength={200}
                                                        onBlur={e => this.onBlur(e, "name")}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {(this.props.ideaWhatIf && !this.props.ideaWhatIf.whatIfIdeaId && !whatIfIdeaId) &&
                                            <Col sm={24}>
                                                <Button disabled={!this.props.form.getFieldValue("name")} onClick={() => this.onCreateIdea()} type="primary" shape="round" size="large">Continuar</Button>
                                            </Col>
                                        }
                                    </Row>
                                </CardBorder>


                                {(this.props.ideaWhatIf && this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.whatIfIdeaId) &&
                                    <Fragment>
                                        <CardBorder
                                            Bcolor="#bab1d1"
                                        >
                                            <Row>
                                                <Col sm={24}>
                                                    <Form.Item label="Breve Descritivo da Ideia">
                                                        {getFieldDecorator('description', {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                            initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.description ? this.props.ideaWhatIf.ideaDetail.description : null
                                                        })(
                                                            <TextArea
                                                                onBlur={e => this.onBlur(e, "description")}
                                                                rows={4}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </CardBorder>

                                        <CardBorder
                                            Bcolor="#9ed3be"
                                        >
                                            <Row>
                                                <Col sm={24}>
                                                    <TitleMiddle>Tipo de ideia e proposta</TitleMiddle>
                                                </Col>
                                            </Row>
                                            <RowType>
                                                <Form.Item>
                                                    {getFieldDecorator('ideaProposalType', {
                                                        rules: [{ required: false, message: 'ops!' }],
                                                        initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.ideaProposalType >= 0 ? this.props.ideaWhatIf.ideaDetail.ideaProposalType : null
                                                    })(
                                                        <Radio.Group onChange={this.onChange} onBlur={e => this.onBlur(e, "ideaProposalType")}>
                                                            <Row>
                                                                <Col sm={8}><Radio value={0}>Ideias de Social</Radio></Col>
                                                                <Col sm={8}><Radio value={1}>Outras ideias</Radio></Col>
                                                            </Row>
                                                        </Radio.Group>
                                                    )}
                                                </Form.Item>
                                            </RowType>
                                            <Row>
                                                <Col sm={8}>
                                                    <Form.Item>
                                                        {getFieldDecorator('ideaProposalSocial', {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                            initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.ideaProposalSocial ? this.props.ideaWhatIf.ideaDetail.ideaProposalSocial : null
                                                        })(
                                                            <CheckboxGroup
                                                                options={optionsSocial}
                                                                onChange={e => this.onBlur(e, "ideaProposalSocial")}
                                                            />
                                                        )}
                                                    </Form.Item>

                                                </Col>
                                                <Col sm={8}>
                                                    <Form.Item>
                                                        {getFieldDecorator('ideaProposalOthers', {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                            initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.ideaProposalOthers ? this.props.ideaWhatIf.ideaDetail.ideaProposalOthers : null
                                                        })(
                                                            <Radio.Group
                                                                onChange={e => this.onBlur(e, "ideaProposalOthers")}
                                                            >
                                                                <Radio value="Ideia de Campanha">Ideia de Campanha</Radio>
                                                                <Radio value="Ideia Experiencia">Ideia Experiencia</Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </CardBorder>

                                        <CardBorder
                                            Bcolor="#ffc5b9"
                                        >
                                            <Row>
                                                <Col sm={24}>
                                                    <TitleMiddle>O contéudo & idéia que você está enviando:</TitleMiddle>
                                                </Col>
                                                <Col sm={24}>
                                                    <Form.Item>
                                                        {getFieldDecorator('ideaUsedOn', {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                            initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.ideaUsedOn ? this.props.ideaWhatIf.ideaDetail.ideaUsedOn : null
                                                        })(
                                                            <Radio.Group
                                                                onChange={e => this.onBlur(e, "ideaUsedOn")}
                                                            >
                                                                <Radio value="anytime">Pode ser usado em qualquer momento</Radio>
                                                                <Radio value="seasonal">Foi pensado para uma data e/ou evento específico (ex: Carnaval, Dia dos Pais, etc…</Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={24}>
                                                    <Form.Item label="Qual data ou evento?">
                                                        {getFieldDecorator('dateOrEvent', {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                            initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.dateOrEvent ? this.props.ideaWhatIf.ideaDetail.dateOrEvent : null
                                                        })(
                                                            <Input
                                                                onBlur={e => this.onBlur(e, "dateOrEvent")}
                                                                placeholder="Ex.: Natal, Dia das mães..."
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </CardBorder>

                                        <CardBorder
                                            Bcolor="#f0e2a1"
                                        >
                                            <Row>
                                                <Col sm={24}>
                                                    <TitleMiddle>O Contéudo & idéia que você está enviando se encaixa em:</TitleMiddle>
                                                </Col>
                                                <Col sm={24}>
                                                    <Form.Item>
                                                        {getFieldDecorator('ideaType', {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                            initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.ideaType ? this.props.ideaWhatIf.ideaDetail.ideaType : null
                                                        })(
                                                            <Radio.Group
                                                                onChange={e => this.onBlur(e, "ideaType")}
                                                            >
                                                                <Radio value="draft">É draft ou referência</Radio><br />
                                                                <Radio value="done">Já entregue produzida, finalizada pronto para usar.</Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </CardBorder>

                                        <CardBorder
                                            Bcolor="#e3fbb8"
                                        >
                                            <Row>
                                                <Col sm={24}>
                                                    <TitleMiddle>Referências para ilustrar sua ideia:</TitleMiddle>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={24}>
                                                    <FieldCustom>
                                                        <label className="label-bp">Vídeos</label>
                                                        <BoxAddFild
                                                            className="InputPlus"
                                                            placeholder=""
                                                            onSearch={value => { this.setState({ referenceVideos: [...this.state.referenceVideos, value] }) }}
                                                            enterButton
                                                        />
                                                    </FieldCustom>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={24}>
                                                    <ul className="ListDinamic">
                                                        {this.state.referenceVideos &&
                                                            this.state.referenceVideos.map((item, index) => {
                                                                return <li key={index}><a href="#" target="_blank" title=""><Icon type="link" /> {item}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={24}>
                                                    <Form.Item label="Imagens">
                                                        {getFieldDecorator('imagensRef', {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                        })(
                                                            <MultUploadFile
                                                                actionUpload={this.props.upload}
                                                                getDataUpload={this.getDataUploadMult}
                                                                name="referenceImages"
                                                                uploadId={this.props.ideaWhatIf.whatIfId}
                                                                fileListGet={this.state.listImg}
                                                                isEdit

                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </CardBorder>

                                        <CardBorder
                                            Bcolor="#ffc980"
                                        >
                                            <Row>
                                                <Col sm={24}>
                                                    <TitleMiddle>Proposta de trabalho:</TitleMiddle>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={24}>
                                                    <Form.Item>
                                                        {getFieldDecorator('jobOffer', {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                            initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOffer ? this.props.ideaWhatIf.ideaDetail.offers[0].jobOffer : null
                                                        })(
                                                            <Radio.Group
                                                                onChange={e => this.onBlur(e, "offers")}
                                                            >
                                                                <div>
                                                                    <Radio value="justIdea" onChange={this.onChangeJob}>Remuneraçåo apenas pela ideia. ( Não tenho interesse em produzir e/ou as ferramentas e equipes necessária)</Radio>
                                                                    <RowPrices className={
                                                                        this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOffer === "justIdea" || this.state.jobOffer === "justIdea" ? "open" : ""
                                                                    } gutter={1}>
                                                                        <Col sm={12}>
                                                                            {getFieldDecorator('jobOfferPriceIdea', {
                                                                                rules: [{ required: false, message: 'ops!' }],
                                                                                initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceIdea ? this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceIdea : null
                                                                            })(
                                                                                <Radio.Group
                                                                                    onChange={e => this.onBlur(e, "offers")}
                                                                                >
                                                                                    <Table
                                                                                        pagination={false}
                                                                                        columns={columnsIdea}
                                                                                        dataSource={dataValuesTypes}
                                                                                        scroll={{ x: 640 }}
                                                                                    />
                                                                                </Radio.Group>
                                                                            )}
                                                                        </Col>
                                                                    </RowPrices>
                                                                </div>
                                                                <div>
                                                                    <Radio value="ideaProduced" onChange={this.onChangeJob}>Já entreguei produzida a idéia, valor proposto é de:</Radio>
                                                                    <RowPrices className={
                                                                        this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOffer === "ideaProduced" || this.state.jobOffer === "ideaProduced" ? "open" : ""
                                                                    } gutter={1}>
                                                                        <Col sm={12}>
                                                                            {getFieldDecorator('jobOfferPriceIdea', {
                                                                                rules: [{ required: false, message: 'ops!' }],
                                                                                initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceIdea ? this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceIdea : null
                                                                            })(
                                                                                <Radio.Group
                                                                                    onChange={e => this.onBlur(e, "offers")}
                                                                                >
                                                                                    <Table
                                                                                        pagination={false}
                                                                                        columns={columnsIdea}
                                                                                        dataSource={dataValuesTypes}
                                                                                        scroll={{ x: 640 }}
                                                                                    />
                                                                                </Radio.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col sm={12}>
                                                                            {getFieldDecorator('jobOfferPriceProduction', {
                                                                                rules: [{ required: false, message: 'ops!' }],
                                                                                initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceProduction ? this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceProduction : null
                                                                            })(
                                                                                <Radio.Group
                                                                                    onChange={e => this.onBlur(e, "offers")}
                                                                                >
                                                                                    <Table
                                                                                        pagination={false}
                                                                                        columns={columnsProduction}
                                                                                        dataSource={dataProduct}
                                                                                        scroll={{ x: 640 }}
                                                                                    />
                                                                                </Radio.Group>
                                                                            )}
                                                                        </Col>
                                                                    </RowPrices>
                                                                </div>
                                                                <div>
                                                                    <Radio value="interestInProducing" onChange={this.onChangeJob}>Tenho interesse em produzir, consigo encaixar no pacote de produção:</Radio>
                                                                    <RowPrices className={
                                                                        this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOffer === "interestInProducing" || this.state.jobOffer === "interestInProducing" ? "open" : ""
                                                                    } gutter={1}>
                                                                        <Col sm={12}>
                                                                            {getFieldDecorator('jobOfferPriceIdea', {
                                                                                rules: [{ required: false, message: 'ops!' }],
                                                                                initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceIdea ? this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceIdea : null
                                                                            })(
                                                                                <Radio.Group
                                                                                    onChange={e => this.onBlur(e, "offers")}
                                                                                >
                                                                                    <Table
                                                                                        pagination={false}
                                                                                        columns={columnsIdea}
                                                                                        dataSource={dataValuesTypes}
                                                                                        scroll={{ x: 640 }}
                                                                                    />
                                                                                </Radio.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col sm={12}>
                                                                            {getFieldDecorator('jobOfferPriceProduction', {
                                                                                rules: [{ required: false, message: 'ops!' }],
                                                                                initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceProduction ? this.props.ideaWhatIf.ideaDetail.offers[0].jobOfferPriceProduction : null
                                                                            })(
                                                                                <Radio.Group
                                                                                    onChange={e => this.onBlur(e, "offers")}
                                                                                >
                                                                                    <Table
                                                                                        pagination={false}
                                                                                        columns={columnsProduction}
                                                                                        dataSource={dataProduct}
                                                                                        scroll={{ x: 640 }}
                                                                                    />
                                                                                </Radio.Group>
                                                                            )}
                                                                        </Col>
                                                                    </RowPrices>
                                                                    <RowPrices className={
                                                                        this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].jobOffer === "interestInProducing" || this.state.jobOffer === "interestInProducing" ? "open" : ""
                                                                    }>
                                                                        <Row gutter={1}>
                                                                            <Col sm={24}>
                                                                                <TitleMiddle>Prazo</TitleMiddle>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row gutter={1}>
                                                                            <Col sm={24}>
                                                                                <Form.Item>
                                                                                    {getFieldDecorator('deadlineCommitment', {
                                                                                        rules: [{ required: false, message: 'ops!' }],
                                                                                        initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].deadlineCommitment ? this.props.ideaWhatIf.ideaDetail.offers[0].deadlineCommitment : null,
                                                                                        valuePropName: 'checked'
                                                                                    })(
                                                                                        <Checkbox
                                                                                            onChange={e => this.onBlur(e, "offers")}
                                                                                        >Consigo me comprometer com a entrega na data e horário abaixo e tenho ciência de que o pagamento está sujeito a avaliação de qualidade do cliente e cumprimento do prazo.</Checkbox>
                                                                                    )}
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row gutter={1}>
                                                                            <Col sm={8}>
                                                                                <Form.Item label="Até a Data:">
                                                                                    {getFieldDecorator('deadlineDate', {
                                                                                        rules: [{ required: false, message: 'ops!' }],
                                                                                        // initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].deadlineDate ? this.props.ideaWhatIf.ideaDetail.offers[0].deadlineDate : null
                                                                                    })(
                                                                                        <DatePicker format={dateFormatList} onBlur={e => this.onBlur(e, "offers")} />
                                                                                    )}
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col sm={4}>
                                                                                <Form.Item label="Até o horário:">
                                                                                    {getFieldDecorator('deadlineHour', {
                                                                                        rules: [{ required: false, message: 'ops!' }],
                                                                                        initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.offers[0] && this.props.ideaWhatIf.ideaDetail.offers[0].deadlineHour ? this.props.ideaWhatIf.ideaDetail.offers[0].deadlineHour : null
                                                                                    })(
                                                                                        <Input placeholder="Ex.: 00:00" onBlur={e => this.onBlur(e, "offers")} />
                                                                                    )}
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </RowPrices>
                                                                </div>
                                                            </Radio.Group>
                                                        )}
                                                    </Form.Item>

                                                    <Form.Item>
                                                        {getFieldDecorator('offers', {
                                                            rules: [{ required: false, message: 'ops!' }],

                                                        })(
                                                            <Input />
                                                        )}
                                                    </Form.Item>

                                                </Col>
                                            </Row>
                                        </CardBorder>

                                        <CardBorder
                                            Bcolor="#cbede0"
                                        >
                                            <Row>
                                                <Col sm={24}>
                                                    <TitleMedium>Resumo da Proposta</TitleMedium>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={24}>
                                                    <ResumeTable
                                                        valorIdeia={this.state.jobValorIdea}
                                                        valorProducao={this.state.jobValorProduction}
                                                    />
                                                </Col>
                                            </Row>



                                            <Row className="BoxTerms">
                                                <Col sm={24}>
                                                    <TitleMedium>Termos e Condições</TitleMedium>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={24}>
                                                    <BoxTerm>
                                                        <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                                        <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                                        <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                                        <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                                    </BoxTerm>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={24}>
                                                    <Form.Item>
                                                        {getFieldDecorator('termsAccepted', {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                            initialValue: this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail.termsAccepted ? this.props.ideaWhatIf.ideaDetail.termsAccepted : null,
                                                            valuePropName: 'checked'
                                                        })(
                                                            <Checkbox
                                                                onChange={e => this.onBlur(e, "termsAccepted")}
                                                            >Li e aceito os termos de uso</Checkbox>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </CardBorder>


                                        <CardBorder>
                                            <RowSubmit>
                                                <Col sm={24}>
                                                    <Link to={`/parceiro-ideias/cadastro/ideia/${whatIfId}/preview/${this.props.ideaWhatIf.ideaDetail.whatIfIdeaId}`}>
                                                        <Button type="primary" shape="round" size="large">Visualizar</Button>
                                                    </Link>
                                                    <Button type="primary" htmlType="submit" shape="round" size="large">Salvar</Button>
                                                </Col>
                                            </RowSubmit>
                                        </CardBorder>
                                    </Fragment>
                                }

                            </Form>
                        </Main>
                    </ContainerPage>

                </WhatifWrapper>
            </Fragment>
        );
    }
}

const IdeaRegisterForm = Form.create({})(IdeaRegister);

export default connect(mapStateToProps, mapDispatchToProps)(IdeaRegisterForm);
