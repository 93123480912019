import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useDebounce } from "use-debounce";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Radio } from "antd";
import { Input } from "../../../../components/UI/Input";
import { createQueryString, parseQueryString } from "../../utils/resolvers";

import * as S from "./styles";

export const Filters = () => {
  let history = useHistory();
  let location = useLocation();
  const auth = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({});

  const [searchValueDebounced] = useDebounce(searchTerm, 1000);

  const handleChangeProjects = (e) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (e.target.value === 0) {
      delete objQueries.filtertype;
      queryString = createQueryString({ ...objQueries });
    } else {
      delete objQueries.page;
      queryString = createQueryString({
        ...objQueries,
        filtertype: e.target.value,
      });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleSearch = (projectName) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!projectName) {
      delete objQueries.projectName;
      queryString = createQueryString({ ...objQueries });
    } else {
      delete objQueries.page;
      queryString = createQueryString({ ...objQueries, projectName });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchValueDebounced]);

  useEffect(() => {
    const objQueries = parseQueryString(location?.search);

    if (objQueries?.projectName) {
      setSearchTerm(objQueries?.projectName);
    }

    setFilters(objQueries);
  }, [location]);

  return (
    <S.Wrapper>
      <div>
        <Input
          placeholder={I18n.get("Pesquisar")}
          label
          value={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />
      </div>
      {auth?.is_client_or_client_user ? (
        <S.RadiosProjects>
          <strong>{I18n.get("Projeto")}</strong>
          <Radio.Group
            onChange={handleChangeProjects}
            value={filters?.filtertype ? Number(filters?.filtertype) : 0}
          >
            <Radio value={0}>{I18n.get("Todos")}</Radio>
            <Radio value={1}>{I18n.get("Meus Projetos")}</Radio>
            <Radio value={2}>{I18n.get("Outros Projetos")}</Radio>
          </Radio.Group>
        </S.RadiosProjects>
      ) : null}
    </S.Wrapper>
  );
};
