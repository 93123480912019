import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { TitleMiddleLine } from '../../components/Titles';
import constants from "../../../constants";
import styled from 'styled-components';

import {
    Row,
    Col,
    Icon,
    Button,
    Checkbox,
    Input,
    Select,
    Form
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;


const RowSubmitModal = styled(Row)`
    .checkItem {
        .ant-form-item-children {
            float: left;
        }
    }
`;

class ModalTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }


    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {

                let data = {
                    ...values,
                    isSubmit: true,
                    partnerId: this.props.partnerId,
                }

                this.setState({
                    loading: true
                });

                this.props.action(data);

                setTimeout(() => {
                    this.setState({
                        loading: false
                    })

                    this.props.close();
                }, 1000);
            }
        });

    };


    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const { partner, locale } = this.props;

        let termsUrl = partner.partnerTerm;

        return (
            <Form onSubmit={this.handleSubmit}>
                <RowSubmitModal>
                    <Col sm={8}>
                        <Form.Item className="checkItem">
                            {getFieldDecorator('terms', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                valuePropName: 'checked'
                            })(
                                <Checkbox>{I18n.get("Li e estou de acordo")}</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={16}>
                        <Button type="primary" shape="round">
                            <a href={termsUrl} target="_blank"><Icon type="download" /> {I18n.get("Download dos termos")} </a>
                        </Button>

                        <Button disabled={!getFieldValue('terms')} type="primary" shape="round" htmlType="submit" loading={this.state.loading}>{I18n.get("Finalizar Cadastro")}</Button>


                    </Col>
                </RowSubmitModal>
            </Form>
        );
    }
}

const ModalTermsForm = Form.create({})(ModalTerms);

export default ModalTermsForm;
