import React, { Component } from "react";
import { I18n } from 'aws-amplify';
import Slider from "react-slick";
import styled from 'styled-components';

import icoNext from '../../image/ico-next.png';
import icoPrev from '../../image/ico-prev.png';

const ContainerSlider = styled.div`
    padding: 0 70px;

        .slick-prev, .slick-next {
            background: no-repeat center center;
            border: solid 1px;
            width: 38px;
            height: 66px;
        }

        .slick-prev {
            background-image: url("${icoPrev}");
            left: -50px;
        }

        .slick-next {
            background-image: url("${icoNext}");
            right: -50px;
        }

    .thumbWork {
        position: relative;
        cursor: pointer;

        * {
            cursor: pointer;
        }

        &:hover {
            .mask {
                opacity: .9;
            }
        }
    }

    .mask {
        position: absolute;
        top: 0;
        height: 0;
        background: #000;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .5 ease;
        cursor: pointer;
        z-index: 90;


        p {
            line-height: 125px;
            color: #fff;
            font-weight: bold;
        }
    }
  @media screen and (min-width: 1472px) {

  }
`;

class Carousel extends Component {
    render() {
        const { children } = this.props;

        return (
            <ContainerSlider>
                <Slider
                    {...this.props}
                >
                    {children}
                </Slider>
            </ContainerSlider>
        );
    }
}

export default Carousel;
