import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { EXTRAS_UPDATE } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const upload_po = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        // [HttpGet("/api/client-project/{projectId}/milestones")]

        //  'http://localhost:52159/api/client-project/projects/6123f3d9db29044367e764ca/extras/billings/updateCode' \

        return axios
          .put(
            `${Config.API_BASE_URL}/client-project/projects/${data.projectId}/extras/billings/updateCode`,
            data,
            config
          )
          .then((response) => {
            const payload = {
              // ...response.data,
              task: {
                result: true,
              },
            };

            dispatch({
              type: EXTRAS_UPDATE,
              payload: payload,
            });

            return true;
          })
          .catch((error) => {
            // Nao faz o dispatch no erro, tratamento direto na tela
            const payload = {
              errors: error.response.data,
              task: {
                result: false,
              },
            };

            return false;
          });
      })
      .catch((error) => {
        dispatch({ type: EXPIRED });
      });
  };
};

export default upload_po;
