import React, { Component, Fragment } from 'react';

import { I18n } from 'aws-amplify';

import styled from 'styled-components';

import WhatifWrapper from '../components/WhatifWrapper';
import ContainerPage from '../components/ContainerPage';

import { connect } from 'react-redux';

import { Link, Redirect } from 'react-router-dom';

import { CLIENT_WHATIF_API } from '../../../actions/client-whatif/actions';

import { TitleBorder } from '../components/Titles';

import SubHeader from '../components/SubHeader';

import { MENU_CLIENT_WHATIF } from '../constants';

import {
    Button,
    Row,
    Col,
    Modal,
    Table, Divider, Tag,
    Badge,
    Switch,
    Icon
} from 'antd';

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;
  color: #353535;

  .table-border {
    margin-top: 50px;
   }

  .btn-edit {
      margin-left: 15px;
  }

  .statusBad {
      .ant-badge-status-dot {
          width: 15px;
          height: 15px;
          margin: 0 auto;
      }
  }
`

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_products: () => {
            dispatch(CLIENT_WHATIF_API.get_products());
        },
        enable_product: (data) => {
            dispatch(CLIENT_WHATIF_API.enable_product(data));
        }
    };
};




class WhatIfHome extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    onChange(checked, record) {
        const data = {
            enableDisable: checked,
            productId: record.productId
        }
        this.props.enable_product(data);
    }


    componentDidMount() {
        this.props.get_products();
    }


    render() {
        const columns = [
            {
                title: I18n.get("Produto"),
                dataIndex: 'produto',
                key: 'produto',
                // render: text => <a>{text}</a>,
            },
            {
                title: I18n.get("Status"),
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => <Badge color={record.isCompleted ? "green" : "red"} className="statusBad" />,
            },

            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <Fragment>
                        <Switch defaultChecked={record.status} onChange={checked => this.onChange(checked, record)} />
                        {record.status &&
                            <Link to={`/cliente/ideias/brand-profile/${record.whatIfId}`} className="btn-edit">
                                <Icon type="form" />
                            </Link>
                        }
                    </Fragment>
                ),
            },
        ];

        const data = this.props.clientWhatIf.products && this.props.clientWhatIf.products.map((item, index) => {
 
            return ({
                key: index,
                produto: item.brand + " / " + item.product,
                status: item.enabled,
                productId: item.productId,
                brandId: item.brandId,
                whatIfId: item.whatIfId,
                isCompleted: item.isCompleted
            })
        })


 

        return (
            <WhatifWrapper>
                <SubHeader itensMenu={MENU_CLIENT_WHATIF} activeItem={1} />
                <ContainerPage>
                    <Main>
                        <Row>
                            <Col md={24}>
                                <TitleBorder>What IF</TitleBorder>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={24}>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan, blandit sed diam.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Table
                                    className="table-border"
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                />
                            </Col>
                        </Row>
                    </Main>
                </ContainerPage>
            </WhatifWrapper>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatIfHome);
