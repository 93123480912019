import styled from "styled-components";

export const Wrapper = styled.div``;

export const Button = styled.button`
  cursor: pointer;
  background: none;
  border: none;
`;

export const Icon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

export const WrapperInput = styled.div`
  width: 200px;
  height: 50px;
  background: #fff;
  display: flex;
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  right: 90px;

  input {
    width: 100%;
    border-radius: 15px;
    border: 1px solid #000;
    font-size: 12px;
    padding: 0 10px;
    color: #000;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: #000;
    }
    :-ms-input-placeholder {
      color: #000;
    }
  }
`;
