import React, { useState } from "react";
import { Icon } from "antd";
import IcoAttachment from "./svgs/attachment.svg";
import IcoEye from "./svgs/eye.svg";
import IcoDelete from "./svgs/delete.svg";
import { isImage } from "./utils/isImage";
import * as S from "./styles";

export const FileList = ({
  files,
  isLoading,
  handleDelete,
  noDelete,
  hasPropsItemListCanBeDeleted,
}) => {
  const [openModalFile, setOpenModalFile] = useState({
    visible: false,
    url: "",
    type: "",
    file: {},
  });

  const handleOpenOrDownloadFile = (file) => {
    if (file?.url) {
      if (isImage(file?.type) === "image" && file?.url) {
        setOpenModalFile({
          visible: true,
          url: file?.url,
          type: file?.type,
          file: {},
        });
      } else {
        const link = document.createElement("a");
        link.href = file?.url;
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      if (isImage(file?.type) === "image") {
        setOpenModalFile({
          visible: true,
          url: file?.url,
          type: "internal",
          file,
        });
      } else {
        const urlDownload = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = urlDownload;
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div />
      ) : (
        <S.FileList>
          {files?.map((file, index) => (
            <li key={index}>
              <S.NameFile>
                <img src={IcoAttachment} alt="attachment icon" />
                <span>{file?.name || file}</span>
              </S.NameFile>
              <S.ListActions>
                <button
                  onClick={() => handleOpenOrDownloadFile(file)}
                  disabled={isLoading}
                >
                  {isImage(file?.type) === "image" ? (
                    <img src={IcoEye} alt="eye icon" />
                  ) : (
                    <Icon type="download" style={{ fontSize: 18 }} />
                  )}
                </button>
                {hasPropsItemListCanBeDeleted ? (
                  file?.canBeDeleted ? (
                    <button
                      onClick={() => handleDelete(file)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Icon type="loading" />
                      ) : (
                        <img src={IcoDelete} alt="delete icon" />
                      )}
                    </button>
                  ) : null
                ) : noDelete ? null : (
                  <button
                    onClick={() => handleDelete(file)}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Icon type="loading" />
                    ) : (
                      <img src={IcoDelete} alt="delete icon" />
                    )}
                  </button>
                )}
              </S.ListActions>
            </li>
          ))}
        </S.FileList>
      )}

      <S.ModalViewImage
        visible={openModalFile.visible}
        title="Title"
        centered
        onOk={() =>
          setOpenModalFile({ visible: false, url: "", type: "", file: {} })
        }
        onCancel={() =>
          setOpenModalFile({ visible: false, url: "", type: "", file: {} })
        }
        footer={[]}
      >
        {openModalFile?.type === "internal" ? (
          <img
            src={URL.createObjectURL(openModalFile?.file)}
            alt="Preview file"
          />
        ) : (
          <img src={openModalFile.url} alt="Preview file" />
        )}
      </S.ModalViewImage>
    </>
  );
};
