export const ensureAbsoluteUrl = (url) => {
  // Verifica se a URL é nula ou indefinida e retorna como está
  if (!url) return url;

  // Verifica se a URL já contém um esquema válido
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url; // A URL já é absoluta, retorna como está
  }

  // Se a URL não começar com http:// ou https://, assume que precisa de https://
  return `https://${url}`;
};
