import React from "react";
import * as S from "./styles";
import Close from "./svgs/close.svg";
import Clip from "./svgs/clip.svg";

const ListFiles = ({ files, deleteFile, disabled }) => {
  return (
    <S.List>
      <S.Icon>
        <img src={Clip} alt="" />
      </S.Icon>
      {files &&
        files.map((item) => {
          return (
            <S.FileItem key={item?.id}>
              <S.FileName>
                <a href={item?.url}>{item?.name}</a>
              </S.FileName>
              <S.Close onClick={disabled ? () => true : () => deleteFile(item?.id)}>
                <img src={Close} alt="" />
              </S.Close>
            </S.FileItem>
          );
        })}
    </S.List>
  );
};

export default ListFiles;
