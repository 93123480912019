import { GET_STATUS_BAR } from '../../actions';

const initialState = {
  statusbar: {
    steps: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS_BAR:
      const newStateStatusBar = {
        ...state,
        steps: [...action.payload],
      };
      return newStateStatusBar;
    default:
      return state;
  }
};

export default reducer;
