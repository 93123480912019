import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import styled from 'styled-components';

import AppWrapperSimple from '../../components/AppWrapperRegister';

import ContainerPage from '../../components/ContainerPage';
import HeaderThumbCover from '../../components/HeaderThumbCover';
import PartnerInfos from '../../components/PartnerInfos';
import Loading from '../../../components/pages/loading';


const Main = styled.div`
  padding-bottom: 100px;
  max-width: 1000px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  color: #353535;
`;

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {

    };
};



class PartnerProfilePreview extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {

        if (!this.props.partner && !this.props.partner.company) {
            return (<Loading />);
        }


        return (
            <AppWrapperSimple
                grey
            >
                {/* <SubHeader /> */}
                <HeaderThumbCover
                    thumb={this.props.partner.company && this.props.partner.company.logoImage}
                    bgHeader={this.props.partner.company && this.props.partner.company.headerImage}
                    title={I18n.get("Preview Completo")}
                    link={`/parceiro/cadastro/preview-card/${this.props.partner && this.props.partner.partnerId}`}
                    linkText={I18n.get("Visualizar Resumido")}
                />
                <ContainerPage>
                    <Main>
                        <PartnerInfos
                            infos={this.props.partner && this.props.partner}
                            specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                            isPresentation={true}
                            diversity={this.props.bp.diversity && this.props.bp.diversity}
                        />
                    </Main>
                </ContainerPage>
            </AppWrapperSimple>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PartnerProfilePreview);

