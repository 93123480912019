import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import LandingPage from '../page-templates/landing';

export default class LoadingPage extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (
            <LandingPage>

                <div className="hero-body has-text-centered">
                    <div className="container">

                        <div className="pageloader has-background-black is-active">
                            <span className="title">{I18n.get("BPool")}</span>
                        </div>


                    </div>
                  </div>

         </LandingPage>
        );
    }
}

