import React from 'react';

import { I18n } from 'aws-amplify';

export default class ClientList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            partnerId: props.partnerId,
            leaderId: props.leaderId,
            type: props.type
        };

        this.delAward = this.onDelAward.bind(this);
    }

    onDelAward(item, e) {
        e.preventDefault();

        const data = Object.assign({}, this.state, { id: item.id });

        this.props.delAward(data);
    }

    getAwards() {
        if (!this.props.awards) return [];

        return this.props.awards.filter((item) => item.type == this.props.type);
    }

    getSector(sectorId) {

        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }

    render() {

        const awards = this.getAwards();

        return (
            <div>
            {awards && awards.length > 0
                && <table className="table is-bordered is-fullwidth">
                <thead>
                    <tr>
                        <th>{I18n.get("Nome")}</th>
                        <th className="is-not-mobile">{I18n.get("Marca")}</th>
                        <th className="is-not-mobile">{I18n.get("Categoria")}</th>
                        <th className="is-not-mobile">{I18n.get("Produto")}</th>
                        <th className="is-not-mobile">{I18n.get("Ano")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {awards && awards.map((item, index) =>
                        <tr key={index}>
                            <td>
                                {item.name}
                            </td>
                            <td className="is-not-mobile">
                                {item.brand}
                            </td>
                            <td className="is-not-mobile">
                                    {/*item.category*/}
                                    {this.getSector(item.category).label}
                            </td>
                            <td className="is-not-mobile">
                                {item.product}
                            </td>
                            <td className="is-not-mobile">
                                {item.year}
                            </td>
                            <td>
                                <a onClick={(e) => this.delAward(item, e)}>
                                    <i className="fa fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>}
            </div>
        );
    }
}