import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useQuery } from "react-query";
import { Row, Col, Icon } from "antd";
import { useSelector } from "react-redux";
import { useCertificates } from "../../../../../hooks/useCertificates/useCertificates.hook";
import { useUpload } from "../../../../../hooks/useUpload/useUpload.hook";
import { Input } from "../../../../../components/UI/Input";
import { InputMask } from "../../../../../components/UI/InputMask";
import { FileUpload } from "../../../../../components/UI/FileUpload";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { SelectOneWithImage } from "../../../../../components/UI/SelectOneWithImage";
import {
  hasError,
  clearErrorInput,
} from "../../../../RegistrationFlow/utils/errorsInput";
import { ListCertificates } from "./listCertificates";

import GOOGLE from "./svgs/logo_google.svg";
import META from "./svgs/logo_meta.svg";
import PINTEREST from "./svgs/logo_pinterest.svg";
import TIKTOK from "./svgs/logo_tiktok.svg";
import XTWITTER from "./svgs/logo_x.svg";

import * as S from "./styles";

export const CertificatesForm = () => {
  const auth = useSelector((state) => state.auth);
  const {
    getCertificatesProviders,
    getCertificatesPartner,
    sendCertificatesPartner,
    updateCertificatesPartner,
    deleteCertificatesPartner,
  } = useCertificates();
  const { uploadFile: uploadFileS3 } = useUpload();
  const [optionsCertificates, setOptionsCertificates] = useState([]);
  const [inputsForm, setInputsForm] = useState({});
  const [otherCertificate, setOtherCertificate] = useState(false);
  const [fileCertificate, setFileCertificate] = useState({});
  const [initialFile, setInitialFile] = useState({});
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [errorsInputs, setErrorsInputs] = useState([]);
  const [onEditing, setOnEditing] = useState(false);
  const [clearing, setClearing] = useState(false);

  const handleChangeCertificate = (value) => {
    if (value === "outro") {
      setInputsForm({
        ...inputsForm,
        isPrincipalCertificatorProvider: false,
        certificationProvider: value,
      });
      return setOtherCertificate(true);
    } else {
      setInputsForm({
        ...inputsForm,
        isPrincipalCertificatorProvider: true,
        certificationProvider: value,
      });
      return setOtherCertificate(false);
    }
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleFile = (file) => {
    setFileCertificate(file);
  };

  const deleteFile = async (fileOrId) => {
    if (fileOrId?.name) {
      setFileCertificate({});
      setInitialFile({});
    }
  };

  const renderLogoToOption = (key) => {
    if (key === "META") {
      return <img src={META} alt="Meta" style={{ maxHeight: 20 }} />;
    } else if (key === "GOOGLE") {
      return <img src={GOOGLE} alt="Google" style={{ maxHeight: 20 }} />;
    } else if (key === "PINTEREST") {
      return <img src={PINTEREST} alt="Google" style={{ maxHeight: 20 }} />;
    } else if (key === "TIKTOK") {
      return <img src={TIKTOK} alt="Google" style={{ maxHeight: 20 }} />;
    } else if (key === "XTWITTER") {
      return <img src={XTWITTER} alt="Google" style={{ maxHeight: 20 }} />;
    } else {
      return "";
    }
  };

  const transformObjectDataCertificates = (data) => {
    return Object.entries(data).map(([key, label]) => ({
      code: key,
      label: label,
      image: renderLogoToOption(key),
    }));
  };

  const loadInitSetup = async () => {
    try {
      setIsLoadingOptions(true);
      const response = await getCertificatesProviders();
      const data = transformObjectDataCertificates(response?.data);
      setOptionsCertificates([
        ...data,
        {
          code: "outro",
          label: I18n.get("Outro certificado"),
          image: <Icon type="plus" />,
        },
      ]);
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Houve um erro, tente novamente"),
        duration: 3,
      });
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const { data, isLoading, error, refetch } = useQuery(
    "certificatesPartner",
    () => getCertificatesPartner(),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data) => {
        console.log("onSuccess, data", data);
      },
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os certificados do parceiro"),
          duration: 3,
        });
      },
    }
  );

  const clearInputs = async () => {
    setInputsForm({});
    await deleteFile({ name: "delete" });
    setOtherCertificate(false);
  };

  const handleCancelEdit = async () => {
    setClearing(true);
    await clearInputs();
    setClearing(false);
    setOnEditing(false);
  };

  const handleSendCertificate = async () => {
    try {
      setIsSending(true);
      let objSend = {};
      if (inputsForm?.certificationProvider === "outro") {
        objSend = {
          ...inputsForm,
          certificationProvider: "",
          file: fileCertificate,
        };
      } else {
        objSend = { ...inputsForm, file: fileCertificate };
      }

      if (fileCertificate?.name && !fileCertificate?.url) {
        const result = await uploadFileS3({
          file: fileCertificate,
          idUser: `${auth?.clientId || auth?.partnerId}`,
          fieldId: `${auth?.clientId || auth?.partnerId}`,
        });

        objSend = {
          ...objSend,
          file: {
            name: result?.name,
            url: result?.url,
            type: result?.type,
            size: fileCertificate?.size,
            uploadedDate: result?.uploadedDate,
          },
        };
      }
      const response = inputsForm?.id
        ? await updateCertificatesPartner(objSend)
        : await sendCertificatesPartner(objSend);
      setClearing(true);
      await clearInputs();
      setClearing(false);
      setOnEditing(false);
      refetch();
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get(
          inputsForm?.id
            ? "Sucesso ao editar o certificado"
            : "Sucesso ao adicionar o certificado"
        ),
        duration: 3,
      });
    } catch (error) {
      setErrorsInputs(error?.response?.data?.errors);
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Houve um erro ao enviar, tente novamente"),
        duration: 3,
      });
    } finally {
      setIsSending(false);
    }
  };

  const editCertificate = (certificate) => {
    setOnEditing(true);

    if (certificate?.certificationProviderOther) {
      setOtherCertificate(true);
    }

    setInputsForm({
      id: certificate?.id,
      certificationProvider: certificate?.certificationProviderOther
        ? "outro"
        : certificate?.certificationProvider,
      certificationProviderOther: certificate?.certificationProviderOther,
      isPrincipalCertificatorProvider:
        certificate?.isPrincipalCertificatorProvider,
      title: certificate?.title,
      date: certificate?.date,
      url: certificate?.url,
    });
    if (certificate?.file?.url) {
      setInitialFile(certificate?.file);
      setFileCertificate(certificate?.file);
    }
  };

  useEffect(() => {
    loadInitSetup();
  }, []);

  return (
    <S.Wrapper>
      <h3>{I18n.get("Certificados Técnicos")}</h3>
      <S.Form>
        <Row>
          <Col xs={12} style={{ paddingLeft: 0 }}>
            <Input
              placeholder={I18n.get("Título do certificado")}
              required
              label
              value={inputsForm?.title}
              onChange={(value) => onChangeInputForm("title", value)}
              error={hasError({ arr: errorsInputs, field: "title" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "title",
                  arr: errorsInputs,
                });
                setErrorsInputs(errorsArr);
              }}
            />
          </Col>
          <Col xs={12} style={{ paddingRight: 0 }}>
            <SelectOneWithImage
              options={optionsCertificates}
              value={inputsForm?.certificationProvider}
              onChange={(value) => handleChangeCertificate(value)}
              placeholder={I18n.get("Digite o nome do orgão/empresa")}
              lastItemBordered
              loading={isLoadingOptions}
              error={hasError({
                arr: errorsInputs,
                field: "certificationProvider",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "certificationProvider",
                  arr: errorsInputs,
                });
                setErrorsInputs(errorsArr);
              }}
            />
          </Col>
        </Row>
        {otherCertificate ? (
          <Row>
            <Col xs={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Input
                placeholder={I18n.get("Nome do certificado")}
                required
                label
                value={inputsForm?.certificationProviderOther}
                onChange={(value) =>
                  onChangeInputForm("certificationProviderOther", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "certificationProviderOther",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "certificationProviderOther",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col xs={12} style={{ paddingLeft: 0 }}>
            <InputMask
              id="date"
              label={I18n.get("Período")}
              defaultValue={inputsForm?.date}
              onChange={(value) =>
                onChangeInputForm("date", value?.formattedValue)
              }
              mask="##/##/####"
              required
              loading={clearing}
              error={hasError({ arr: errorsInputs, field: "date" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "date",
                  arr: errorsInputs,
                });
                setErrorsInputs(errorsArr);
              }}
            />
          </Col>
          <Col xs={12} style={{ paddingRight: 0 }}>
            <Input
              placeholder={I18n.get("Url do certificado")}
              required
              label
              value={inputsForm?.url}
              onChange={(value) => onChangeInputForm("url", value)}
              error={hasError({ arr: errorsInputs, field: "url" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "url",
                  arr: errorsInputs,
                });
                setErrorsInputs(errorsArr);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
            {clearing ? (
              <div />
            ) : (
              <FileUpload
                cbFiles={(file) => handleFile(file)}
                multiple={false}
                isLoading={false}
                initialFiles={initialFile?.url ? [initialFile] : []}
                cbDeleteFile={(fileOrId) => deleteFile(fileOrId)}
                acceptedTypes={[]}
              />
            )}
          </Col>
        </Row>
        <Row style={{ marginBottom: 32 }}>
          <Col xs={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <S.Actions>
              {onEditing ? (
                <S.CancelEdit>
                  <button onClick={handleCancelEdit}>
                    {I18n.get("Cancelar")}
                  </button>
                </S.CancelEdit>
              ) : null}
              <ButtonBpool
                text={I18n.get(
                  inputsForm?.id
                    ? "Salvar certificado"
                    : "Adicionar certificado"
                )}
                onClick={handleSendCertificate}
                theme="tertiary"
                loading={isSending}
              />
            </S.Actions>
          </Col>
        </Row>
      </S.Form>

      <ListCertificates
        data={data?.data}
        isLoading={isLoading}
        refetch={refetch}
        editCertificate={editCertificate}
      />
    </S.Wrapper>
  );
};
