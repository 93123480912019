import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { SPEC_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';




const api_get_spec_dash = (data) => {


    let dataFrom = data.fromDate;
    let dataTo = data.toDate;

    delete data['date'];
    delete data['fromDate'];
    delete data['toDate'];

    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');

    return (dispatch, getState) => {
        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            // /client/detailspecialism/{from}/{to}/{spec}

            return axios.get(`${Config.API_BASE_URL}/AssetSaving/client/detailspecialism/${dataFrom}/${dataTo}/${data.code}`, config)
                .then(response => {

 

                    dispatch({
                        type: SPEC_GET,

                        payload: {
                            itemSaving: response.data,
                            errors: {},
                        }
                    })

                    return true;
                })
                .catch(error => {
                    dispatch({
                        type: SPEC_GET,
                        payload: {
                            errors: {},
                            task: {
                                result: false
                            }
                        }
                    })

                    return false;
                });
        }).catch(error => {
            // dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_spec_dash;
