import React from "react";

import { I18n } from "aws-amplify";

import FormatCurrency from "../../ui/format-currency";
import { BoldOutlined } from "@ant-design/icons";

require("./quotation-grandtotal.css");


class QuotationGrandTotal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { quotation = {}, currency = {} } = this.props;

    
    const VatCmp = ({ vat, amount, currency, otherTax, amountOtherTax }) => {
      return (
        <div
          style={{ marginTop: "0.5rem", fontWeight: "normal", fontSize: "75%" }}
        >
          {otherTax == 0 ? null : 
            <>
              +{I18n.get("Other Tax")} {otherTax}% <br />
              <FormatCurrency
                amount={amountOtherTax}
                currency={currency}
              />{" "}
            </>
          }
          <br />
          {vat == 0 ? null : (
            <>
              +{I18n.get("IVA")} {vat}%{" "}
              <FormatCurrency amount={amount} currency={currency} />
            </>
          )}
        </div>
      );
    };

    return (
      <>
        <div className="quotation-grandtotal">
          <p className="title is-5">{I18n.get("Resumo da Proposta")}</p>

          <br />

          <div className="columns head">
            <div className="column is-6">{I18n.get("Resumo Proposta")}</div>

            <div className="column is-3">%</div>

            <div className="column is-3">{I18n.get("Valor")}</div>
          </div>

          <div className="columns amount">
            <div className="column is-6 tit">
              {I18n.get("Proposta Parceiro")} 
            </div>

            <div className="column is-3"></div>

            <div className="column is-3 has-text-right">
              <FormatCurrency
                amount={quotation.totalExcludingBPCommission}
                currency={currency}
              />{" "}
              {/* <VatCmp
                vat={this.props?.assetTax}
                otherTax={this.props.otherTax}
              /> */}
              {/* <VatCmp
                vat={this.props?.assetTax}
                amount={this.props?.extraPricePartnerTax}
                currency={currency}
                otherTax={this.props.otherTax}
                amountOtherTax={this.props?.extraPricePartnerOtherTax}
              /> */}
            </div>
          </div>

          <div className="columns bpcommission">
            <div className="column is-6 tit">
              {I18n.get("Retenção Plataforma")}
            </div>

            <div className="column is-3 has-text-right">
              {quotation.bpCommission &&
                quotation.bpCommission.percentage > 0 &&
                quotation.bpCommission.percentageFmt}
            </div>

            <div className="column is-3 has-text-right">
              <FormatCurrency
                amount={quotation.bpCommission && quotation.bpCommission.amount}
                currency={currency}
              />{" "}
            
            </div>
          </div>

          <div className="columns total">
            <div className="column is-6 tit">{I18n.get("Total Projeto")}</div>

            <div className="column is-3"></div>

            <div className="column is-3 has-text-right">
             

              {(this.props.auth && this.props.auth.is_client_or_client_user) && 
                  <div className="displayValoresWhite">
                    {this.props.otherTax > 0 && (
                      <p style={{ fontWeight: "normal" }}>
                        <span>{I18n.get("Parcial:")} </span>
                        <br />
                        <span>
                          {this.props?.extraPriceClientWithDiscountFmt}
                        </span>
                      </p>
                    )}
                    <p>
                      <span style={{ fontWeight: "normal" }}>
                        {I18n.get("Total")}: <br />
                      </span>
                      <b style={{ fontSize: 20 }}>
                        
                        <FormatCurrency
                          amount={
                            this.props
                              ?.extraConvertedPriceClientWithDiscountWithOtherTax
                          }
                          currency={currency}
                        />
                      </b>{" "}
                      <br />
                      <span
                        style={{
                          fontSize: 11,
                          marginTop: "-10px",
                          fontWeight: "normal",
                        }}
                      >
                        {this.props.otherTax > 0
                          ? `(${I18n.get("incluyendo gross up")} ${
                              this.props.otherTax
                            }%)`
                          : ""}
                      </span>
                    </p>
                    {this.props?.assetTax > 0 && (
                      <p style={{ fontWeight: "normal" }}>
                        <span>{I18n.get("Impostos")} </span> <br />
                        (IVA {this.props?.assetTax}%):{" "}
                        <span>
                          {this.props?.extraPriceClientWithDiscountTaxFmt}
                        </span>
                      </p>
                    )}
                  </div>
                }
              {!this.props?.auth?.is_client_or_client_user && (
                  <>
                    <p style={{fontWeight: "bold", color: "#fff", fontSize: 20}}>{this.props.extraPriceClientWithDiscountWithOtherTaxFmt}</p>

                    <VatCmp
                      vat={this.props?.assetTax}
                      amount={this.props?.extraPriceClientWithDiscountTax}
                      currency={currency}
                      otherTax={this.props.otherTax}
                      amountOtherTax={
                        this.props?.extraPriceClientWithDiscountOtherTax
                      }
                    />
                  </>
                )}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "2rem" }}>
          <div className="field">
            <label className="label is-small">
              <div style={{ opacity: "0.5" }}>
                *
                {I18n.get(
                  "Itens de asset list já contemplam a retenção da plataforma e, portanto, valores de retenção não são calculados sobre tais itens."
                )}
              </div>
            </label>
          </div>
        </div>
      </>
    );
  }
}

export default QuotationGrandTotal;
