import { message, notification } from 'antd';

export const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}


export const openNotificationWithIcon = (error) => {

    let message = "";
    let description = "";

    switch (error) {
        case 500:
            message = "Ops algo deu errado!"
            description = "Tente mais tarde. Caso o erro persista, entre em contato."
            break;

        default:
            break;
    }


    notification["error"]({
        message: message,
        description: description
    });
};


export const handleIdeaUsedOn = (value) => {
    switch (value) {
        case "anytime":
            return "Pode ser usado em qualquer momento";
            break;

        case "seasonal":
            return "Foi pensado para uma data e/ou evento específico";
            break;

        default:
            break;
    }
};

export const handleJobOffer = (value) => {
    switch (value) {
        case "justIdea":
            return "Remuneraçåo apenas pela ideia. ( Não tenho interesse em produzir e/ou as ferramentas e equipes necessária)";
            break;

        case "ideaProduced":
            return "Já entreguei produzida a idéia, valor proposto é de:";
            break;
        case "interestInProducing":
            return "Tenho interesse em produzir, consigo encaixar no pacote de produção:";
            break;
        default:
            break;
    }
};

export const handleStatusIdea = (value) => {
    switch (value) {
        case "justIdea":
            return "Remuneraçåo apenas pela ideia. ( Não tenho interesse em produzir e/ou as ferramentas e equipes necessária)";
            break;

        case "ideaProduced":
            return "Já entreguei produzida a idéia, valor proposto é de:";
            break;
        case "interestInProducing":
            return "Tenho interesse em produzir, consigo encaixar no pacote de produção:";
            break;
        default:
            break;
    }
};

