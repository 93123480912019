import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col, message } from "antd";
import { Loggedv2 } from "../../components/Loggedv2";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { ButtonExpandSelect } from "../../components/ButtonExpandSelect";
import { useRegistrationFlow } from "../../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import * as S from "./styles";
import { urlsFull as urls } from "../../utils/stepsUrls";
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
  toRemoveAnStep,
  toUpdateIsStartupInSteps,
} from "../../utils/flowToStorage";
import { statusIdToName } from "../../components/Loggedv2/statusIdToName";

const MESSAGE_ERROR_GET_PAGE = "There was an error, try again.";
const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const PAGE_INDEX = 10;

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { getBusinessType, updateBusinessType, getBusinessTypeCases } = useRegistrationFlow();
  const { getStatusBarFull } = useRegistrationFlowFull();
  let history = useHistory();

  const vip = useSelector((state) => state?.partner?.vip);

  const [isLoadPage, setIsLoadPage] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [selectsData, setSelectsData] = useState([]);
  const [selectsDataDefaults, setSelectsDataDefaults] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [errorsPage, setErrorsPage] = useState([]);
  const [partnerIdState, setPartnerIdState] = useState("");
  const [limitItens, setLimitItens] = useState([]);
  const [modalRemovedsConfirm, setModalRemovedsConfirm] = useState({ open: false, messages: [] });

  useEffect(() => {
    if (partnerId) {
      setPartnerIdState(partnerId);
    }
    if (state?.partner) {
      setPartnerIdState(state?.partner?.partnerId);
    }
  }, [state, partnerId]);

  const defaultsSelecteds = ({ arrData }) => {
    const defaults = [];

    const filterArray = arrData?.map((item) => {
      const filterArrayChildren = item?.disciplineChildren?.map((children) => {
        if (children?.selected) {
          defaults.push(children?.code);
        }
      });
    });

    setSelectsDataDefaults(defaults);
    return null;
  };

  const loadInit = async () => {
    setIsLoadPage(true);

    const response = await getBusinessType({
      admin: contextPage === "admin" ? true : false,
    });

    if (response.success) {
      const arrData = response.data.map((item) => ({
        ...item,
        selected: item.disciplineChildren.some(
          (item) => item.selected === true
        ),
      }));
      setSelectsData(arrData);
      defaultsSelecteds({ arrData })
    } else {
      message.error(I18n.get(MESSAGE_ERROR_GET_PAGE));
    }

    setIsLoadPage(false);
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarFull();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const caseOrCasesMsg = (quantity) => {
    return quantity > 1 ? "cases" : "case";
  };

  const businessTypeCases = async ({ removedItems }) => {
    try {
      const responseBusinessTypeCases = await getBusinessTypeCases({ partnerId: partnerId || partnerIdState });
      
      if (responseBusinessTypeCases.success) {
        const cases = responseBusinessTypeCases?.data;

        const messageRemoveds = [];

        removedItems.forEach(removedItem => {
          const caseItem = cases.find(item => item.code === removedItem);
        
          if (caseItem) {
            messageRemoveds.push(`Você vai perder ${caseItem.quantity} ${caseOrCasesMsg(caseItem.quantity)} de ${caseItem.label}`)
          }
        });

        return messageRemoveds;

      } else {
        console.log("error business type cases get")
      }
    } catch (error) {
      console.log("error business type cases get", error)
      
    }
  };

  const checkIfWasChange = async ({ defaultValues, currentValues }) => {
    const addedItems = currentValues.filter(item => !defaultValues.includes(item));
    const removedItems = defaultValues.filter(item => !currentValues.includes(item));

    let wasChange = {
      hasRemoveds: false,
      messages: []
    }

    if (addedItems.length > 0 || removedItems.length > 0) {
      if (removedItems.length > 0) {
        const messages = await businessTypeCases({ removedItems });
        wasChange = {
          hasRemoveds: messages?.length ? true : false,
          messages
        }
        return wasChange;
      } else {
        wasChange = {
          hasRemoveds: false,
          messages: []
        }
        return wasChange;
      }
    } else {
      wasChange = {
        hasRemoveds: false,
        messages: []
      }
      return wasChange;
    }
  };

  const send = async () => {
    setIsSending(true);

    const arrSelecteds = [];
    selectsData.map((item) =>
      item.disciplineChildren.map((sub) =>
        sub.selected === true ? arrSelecteds.push(sub.code) : null
      )
    );

    const selecteds = selectsData.filter((item) => item.selected === true);
    const isStartup = selecteds.find(
      (select) => select.code === "MARTECADTECH"
    );

    const dataSendAdmin = {
      adminAction: true,
      codes: arrSelecteds,
    };

    const dataSend = {
      adminAction: false,
      codes: arrSelecteds,
    };

    const response = await updateBusinessType(contextPage === "admin" 
      ? { admin: true, partnerId, dataSend: dataSendAdmin }
      : { admin: false, partnerId: null, dataSend: dataSend }
    );

    setModalRemovedsConfirm({ open: false, messages: [] });

    if (contextPage === "full") {
      await newGetStepbar();
    }

    if (response?.errors?.length) {
      if (contextPage === "full") {
        setOpenModalConfirm(true);
      }
      setErrorsPage(response?.errors);
    } else {
      if (contextPage === "full") {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        const indexUrlIsStartup = PAGE_INDEX + 2;
        history.push({
          pathname: urls[isStartup ? indexUrlIsStartup : PAGE_INDEX + 1],
        });
      }
      if (contextPage === "edit" || contextPage === "admin") {
        sendOk(true);
      }
    }

    setIsSending(false);
  };

  const checkSend = async () => {
    setIsSending(true);
    const arrSelecteds = [];
    selectsData.map((item) =>
      item.disciplineChildren.map((sub) =>
        sub.selected === true ? arrSelecteds.push(sub.code) : null
      )
    );

    const wasChanged = await checkIfWasChange({ defaultValues: selectsDataDefaults, currentValues: arrSelecteds });
    if (wasChanged.hasRemoveds) {
      setModalRemovedsConfirm({ open: true, messages: wasChanged.messages });
    } else {
      await send();
    }
    setIsSending(false);
  };

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const handleChangeSelects = (arrChanged) => {
    const dataTemp = [...selectsData];
    const indexObj = dataTemp.findIndex(
      (item) => item.code === arrChanged.code
    );

    if (arrChanged.code === "MARTECADTECH") {
      dataTemp[indexObj].disciplineChildren = arrChanged.arr;

      const test = dataTemp.map((item) => {
        if (item.code === "MARTECADTECH") {
          return {
            ...item,
            selected: item.disciplineChildren.some(
              (item) => item.selected === true
            ),
          };
        } else {
          return {
            ...item,
            disciplineChildren: item.disciplineChildren.map((subItem) => ({
              ...subItem,
              selected: false,
            })),
            selected: false,
          };
        }
      });

      setSelectsData(test);
    } else {
      dataTemp[indexObj].disciplineChildren = arrChanged.arr;

      const test = dataTemp.map((item) => {
        if (item.code === "MARTECADTECH") {
          return {
            ...item,
            disciplineChildren: item.disciplineChildren.map((subItem) => ({
              ...subItem,
              selected: false,
            })),
            selected: false,
          };
        } else {
          return {
            ...item,
            selected: item.disciplineChildren.some(
              (item) => item.selected === true
            ),
          };
        }
      });

      setSelectsData(test);
    }
  };

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const filterItens = () => {
    const limitItens = [];

    const filterArray = selectsData.map((item) => {
      const filterArrayChildren = item?.disciplineChildren.map((children) => {
        if (children.selected) {
          limitItens.push(children);
        }
      });
    });

    setLimitItens(limitItens);
    return null;
  };

  useEffect(() => {
    filterItens();
  }, [selectsData]);

  const renderSubBodyTextModal = (data) => {
    if (data?.length > 1) {
      return (
        `
        <ul>
          ${
            data.map((message) => {
                return `<li>${message}</li>`
              }).join('')
            }
          </ul>
        `
      );
    } else {
      return `<div>${data}</div>`;
    }
  };

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("BusinessType")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get("Tell us about your speciatly services.")}
                <br />
                <strong>
                  {I18n.get(
                    "You may select up to 4 specialities in each category or across Agency and Studio. But once you select Martech & Adtech you will not be able to select either of the previous categories."
                  )}
                </strong>
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>
        <Row>
          {isLoadPage ? (
            <Col xs={24} style={{ marginTop: 40 }}>
              <div>
                <Loading
                  sizeIcon={18}
                  text={I18n.get("Loading informations")}
                  sizeText={14}
                  align="center"
                />
              </div>
            </Col>
          ) : (
            selectsData.map((item) => (
              <Col key={item.id} xs={24} style={{ marginTop: 40 }}>
                <ButtonExpandSelect
                  data={item}
                  expanded={item.expanded}
                  onChange={(changed) => handleChangeSelects(changed)}
                  error={Boolean(errorsPage.length)}
                  full={!vip}
                  isLimit={limitItens.length > 3}
                />
              </Col>
            ))
          )}
        </Row>

        <Row style={{ marginTop: 30 }}>
          {contextPage === "full" ? (
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get("Back")}
                theme="secondary"
                full
                onClick={handleBackPage}
              />
            </Col>
          ) : null}
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={
              contextPage === "edit" || contextPage === "admin"
                ? { span: 12, offset: 12 }
                : { span: 12, offset: 0 }
            }
          >
            <ButtonBpool
              text={
                contextPage === "edit" || contextPage === "admin"
                  ? I18n.get("Save")
                  : I18n.get("Save and Next")
              }
              theme="primary"
              full
              loading={isSending}
              onClick={checkSend}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={modalRemovedsConfirm.open || openModalConfirm}
        bodyText={modalRemovedsConfirm.open ? I18n.get("Essa ação é irreversível") : I18n.get("Missing required information")}
        subBodyText={modalRemovedsConfirm.open ? renderSubBodyTextModal(modalRemovedsConfirm.messages) : I18n.get(
          "Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?"
        )}
        okText={modalRemovedsConfirm.open ? I18n.get("Continue") : I18n.get("Yes")}
        handleClickOk={modalRemovedsConfirm.open ? send : handleClickOkModalConfirm}
        onCancel={modalRemovedsConfirm.open ? () => setModalRemovedsConfirm({ ... modalRemovedsConfirm, open: false }) : () => setOpenModalConfirm(false)}
        cancelText={modalRemovedsConfirm.open ? I18n.get("Cancel") : I18n.get("Close")}
        typeModal="isConfirm"
        isDanger={modalRemovedsConfirm.open ? true : false}
        isAlert={modalRemovedsConfirm.open ? true : false}
        isLoading={isSending}
      />
    </>
  );
};
