import React from 'react';

import { I18n } from 'aws-amplify';

import constants from '../../constants';

import CheckboxField from '../form/checkbox-field';

export default class AwardsList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            partnerId: props.partnerId,
            leaderId: props.leaderId,
            type: props.type,
            companyAwards: props.companyAwards
        };

        this.delAward = this.onDelAward.bind(this);
    }

    onDelAward(item, e) {
        e.preventDefault();

        const data = Object.assign({}, this.state, { id: item.id });

        this.props.delAward(data);
    }

    getAwards() {
        return this.props.companyAwards || [];
    }

    onChangeTeamAward(item, e) {

        const value = e.target.checked;

        const data = Object.assign({}, item, { leaderId: this.props.leaderId });

        if (value) {
            this.props.addAward(data);
        }
        else {
            this.props.delAward(data);
        }
    }

    isChecked(caseId) {

        if (!this.props.awards) return false;

        const items = this.props.awards.filter((item) => item.type == 1 && item.id == caseId);

        return items && items.length;
    }

    getSector(sectorId) {

        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }

    render() {

        const awards = this.getAwards();

        return (
            <table className="table is-bordered is-fullwidth">
                    <thead>
                        <tr>
                            <th>{I18n.get("Nome")}</th>
                            <th className="is-not-mobile">{I18n.get("Marca")}</th>
                            <th className="is-not-mobile">{I18n.get("Categoria")}</th>
                            <th className="is-not-mobile">{I18n.get("Produto")}</th>
                            <th className="is-not-mobile">{I18n.get("Ano")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {awards && awards.map((item, index) =>
                            <tr key={index}>
                                <td>
                                    {item.name}
                                </td>
                                <td className="is-not-mobile">
                                    {item.brand}
                                </td>
                                <td className="is-not-mobile">
                                    {/*item.category*/}
                                    {this.getSector(item.category).label}
                                </td>
                                <td className="is-not-mobile">
                                    {item.product}
                                </td>
                                <td className="is-not-mobile">
                                    {item.year}
                            </td>
                            <td>
                                <div className="checkmark-grey">
                                    <CheckboxField
                                        label={I18n.get("Sim")}
                                        value={true}
                                        checked={this.isChecked(item.id)}
                                        onChange={this.onChangeTeamAward.bind(this, item)}
                                        />
                                </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
        );
    }
}