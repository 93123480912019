import styled from "styled-components";

import { Button } from "antd";

const handleColorType = (type) => {
  switch (type) {
    case "primary":
      return `
        color: #fff; 
        background: #1883FF;
        border-color: #1883FF;
        
        span {
          color: #fff; 
        }
        
        &.ant-btn:not([disabled]):active,
        &:hover,&:focus,&:active {
          color:#fff;
          border-color: #000;
          background: #000;
        }
        `;
    case "black":
      return `
        color: #fff; 
        background: #000;
        border-color: #000;
        &:hover {
          color:#fff;
          border-color: #979797;
          background: #979797;
        }
      `;
    case "grey":
      return `
        color: #fff; 
        background: #979797;
        border-color: #979797;

        
        &.ant-btn:not([disabled]):active,
        &:hover,&:focus,&:active {
          color: #fff;
          background: #1883FF;
          border-color: #1883FF;
        }
      `;
    case "danger":
      return `
          color: #fff; 
          background: #FF0000;
          border-color: #FF0000;
  
          
          &.ant-btn:not([disabled]):active,
          &:hover,&:focus,&:active {
            color: #fff;
            background: #8c0000;
            border-color: #8c0000;
          }
        `;
    default:
      return `
        color: #fff; 
        background: #1883FF;
        border-color: #1883FF;
        span {
          color: #fff; 
        }
        
        &.ant-btn:not([disabled]):active,
        &:hover,&:focus,&:active {
          color:#fff;
        }
      `;
  }
};

const handleShape = (shape) => {
  switch (shape) {
    case "square":
      return `
        border-radius: 5px;
      `;
      break;

    default:
      return `
        border-radius: 28px;
      `;
      break;
  }
};

const handleOutiline = (outline, type) => {
  if (outline) {
    switch (type) {
      case "black":
        return `
        color: #000; 
        background: transparent;
        border-color: #000;

        
        
       
        &:hover,&:focus,&:active {
          background: transparent;
          color:#979797;
          border-color: #979797;
        }
      `;
      case "grey":
        return `
        color: #979797; 
        background: transparent;
        border-color: #979797;

        
        &.ant-btn:not([disabled]):active,
        &:hover,&:focus,&:active {
          color: #1883FF;
          background: #1883FF;
          border-color: #1883FF;
        }
      `;
      default:
        return `
        color: #1883FF; 
        background: transparent;
        border-color: #1883FF;
        span {
          color: #1883FF; 
        }
        
        &.ant-btn:not([disabled]):active,
        &:hover,&:focus,&:active {
          background: transparent;
          color:#000;
          border-color: #000;

          span {
            color: #000; 
          }
        }
      `;
    }
  }
};

const handleDisabled = (disabled) => {
  if (disabled) {
    return `
    span {
      color: #d9d9d9;
    }
    `;
  }
};

const handleFull = (full) => {
  if (full) {
    return `
    width: 100%;
    `;
  }
};

export const StyledButton = styled(Button)`
  &.ant-btn {
    font-size: 16px;
    padding: ${(props) => (props.padding ? props.padding : "0px 40px")};
    text-decoration: none;
    z-index: 7;
    border: 1px solid;
    text-align: center;
    ${({ theme }) => handleColorType(theme)};
    ${({ shape }) => handleShape(shape)};
    ${({ outline, theme }) => handleOutiline(outline, theme)};
    ${({ disabled }) => handleDisabled(disabled)};

    ${({ full }) => handleFull(full)};

    height: ${(props) => (props.size == "small" ? "42px" : "54px")};
    line-height: ${(props) => (props.size == "small" ? "42px" : "54px")};

    @media (max-width: 768px) {
      padding: 12px 35px;
    }

    .anticon {
      vertical-align: -5px;
      margin: 0 8px 0 5px;
    }
    img {
      width: 30px;
      margin-top: -3px;
      padding-right: 10px;
      filter: ${(props) => props.iconBrightness};
    }
  }
`;
