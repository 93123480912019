import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PARTNER_TAGS_CREATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_meeting_data = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.post(`${Config.API_BASE_URL}/partner/meeting-schedule/comment`, data, config)
        }).catch(error => {
            // dispatch({ type: EXPIRED });


            return {
                error: true
            };
            // console.log("SEND ERROR ", error);
        });
    };
}

export default api_meeting_data;
