import React from "react";

import { I18n } from "aws-amplify";

import Modal from "../../ui/modal";
import { Link } from "react-router-dom";
import LeaderCard from "./_leader-card";
import CaseItem from "./_case-item";
import CaseFull from "./_case-full";

import AwardItem from "./_award-item";
import AwardFull from "./_award-full";

import { Button } from "antd";

import LogoButton from "../../form/logo-button";

import ReactPlayer from "react-player";
import { max } from "moment";

import Carousel from "../../ui/carousel";

const Logo = React.memo((props) => {
  if (props.company && props.company.logoImage) {
    return (
      <figure className="media-left">
        <p className="image is-64x64">
          <img src={props.company.logoImage} />
        </p>
      </figure>
    );
  }

  return <div></div>;
});

const Banner = React.memo((props) => {
  if (props.company && props.company.introVideo) {
    return (
      <div>
        <ReactPlayer
          url={props.company.introVideo}
          playing={true}
          loop={true}
          controls={false}
          muted={true}
          pip={false}
          width="640px"
          height="320px"
          style={{ margin: "0 auto" }}
        />
      </div>
    );
  } else if (props.company && props.company.introImage) {
    return (
      <p className={`image ${props.isMiniThumb == true ? "imageFix" : ""}`}>
        <img src={props.company.introImage} />
      </p>
    );
  }

  return <div></div>;
});

class PartnerLargeCard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selected: null,
      currentLeader: 0,
      currentCase: 0,
      currentAward: 0,
    };

    this.select = this.onSelect.bind(this);
    this.closeModal = this.onCloseModal.bind(this);
  }

  onSelect(item, e) {
    this.setState({ selected: item });
  }

  onCloseModal(e) {
    e.preventDefault();
    this.setState({ selected: null });
  }

  ToTitleCase(str) {
    str = str.toLowerCase().split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  }

  getClients() {
    var clients =
      this.props.clients &&
      this.props.clients.map(
        (item) => `${item.name} (${this.ToTitleCase(item.product)})`
      );

    return clients && clients.join(", ");
  }

  render() {
    const isPreview = this.props.isPreview || false;

    //adiciona o responsavel na frente
    const leaders = this.props.leaders && [
      ...this.props.leaders,
      {
        name: this.props.name,
        expertise: { code: "RESP", label: I18n.get("Responsável") },
      },
    ];

    //Cases
    //Pegar da raiz tipo 1 = empresa
    //Pegar da raiz tipo 2 = case pessoal do responsavel
    //Pegar de cada lider tipo 2 = pessoal
    //Marcar os pessoais com o nome deles
    //Concatenar todos em um array novo

    var casesCompany =
      (this.props.cases && this.props.cases.filter((x) => x.type === 1)) || [];

    var casesResponsable = [];
    var casesLeaders = [];

    this.props.cases &&
      this.props.cases.forEach((x) => {
        if (x.type === 2) {
          var owner = this.props.name;
          casesResponsable.push({ ...x, owner });
        }
      });

    this.props.leaders &&
      this.props.leaders.forEach((x) => {
        var owner = x.name;

        if (x.cases && x.cases.length > 0) {
          x.cases.forEach((y) => {
            if (y.type === 2) {
              casesLeaders.push({ ...y, owner });
            }
          });
        }
      });

    let cases = [...casesCompany, ...casesResponsable, ...casesLeaders].sort(
      (a, b) => (a.year < b.year ? 1 : b.year < a.year ? -1 : 0)
    );

    let aw = [];

    this.props.leaders &&
      this.props.leaders.forEach((x) => {
        if (x.awards && x.awards.length > 0) {
          x.awards.forEach((y) => {
            if (y.type === 2) aw.push(y);
          });
        }
      });

    //const leaders_cases = this.props.leaders && this.props.leaders.map((leader) => leader.cases);
    //const cases = this.props.cases && this.props.cases.concat(casesLeaders).sort((a, b) => Number(b.year) - Number(a.year));
    //const leaders_awards = this.props.leaders && this.props.leaders.map((leader) => leader.awards);

    const awards =
      this.props.awards &&
      this.props.awards
        .concat(aw)
        .sort((a, b) => Number(b.year) - Number(a.year));

    return (
      <div>
        {" "}
        <div className="card">
          <div className="card-content">
            {/* <article className="media">
                        <div className="media-content">
                            <div className="content">
                                <p>
                                    <strong>{this.props.name}</strong> <br />
                                    <small>{this.props.company && this.props.company.city} - {this.props.company && this.props.company.state}</small> <br />
                                </p>
                            </div>
                        </div>
                        <div className="media-right">
                            {/* <span className="icon"><i className="fas fa-video"></i></span> &nbsp;&nbsp;
                            <span className="icon"><i className="fas fa-phone"></i></span>
                        </div>
                    </article> */}

            <article className="media">
              <Logo {...this.props} />

              <div className="media-content">
                <div className="content">
                  <p className="media-card-content-top">
                    <strong>
                      {this.props.company && this.props.company.name}
                    </strong>{" "}
                    <br />
                    <small>
                      {this.props.company && this.props.company.city} -{" "}
                      {this.props.company && this.props.company.state}
                    </small>{" "}
                    <br />
                    {/* <small>{this.props.company && this.getTeamSize(this.props.company.teamSizeRange)}</small> */}
                  </p>

                  {this.props.isAdminView && (
                    <p className="PbuttonView">
                      <Link
                        to={`/bpool/parceiros/${this.props.partnerId}/preview`}
                        target="_blank"
                      >
                        <Button className="button bt-bpool black pull-right">
                          {I18n.get("Ver perfil completo")}
                        </Button>
                      </Link>
                    </p>
                  )}
                </div>
              </div>
            </article>

            {<Banner {...this.props} />}

            <br />
            <br />

            <h1 className="title-bpool medium">
              <b>{I18n.get("Sobre")}</b>
            </h1>

            <p>{this.props.company && this.props.company.description}</p>

            <br />
            <br />

            {!this.props.isAdminView && (
              <h1 className="title-bpool medium">
                <b>{I18n.get("Clientes")}</b>
              </h1>
            )}

            <p>{this.getClients()}</p>

            <br />

            <div className="preview-hr">&nbsp;</div>

            {leaders && leaders.length > 0 && (
              <div className="preview-cards">
                <h1 className="title-bpool medium">
                  <b>{I18n.get("Líderes")}</b>
                </h1>

                <Carousel
                  items={leaders.map((item, index) => (
                    <div key={index} className="carousel-cards-item">
                      <LeaderCard {...item} />
                    </div>
                  ))}
                  size={3}
                />

                <div
                  className={
                    (cases && cases.length > 0) || (awards && awards.length > 0)
                      ? "preview-hr"
                      : "preview-hr-hidden"
                  }
                >
                  &nbsp;
                </div>
              </div>
            )}

            {cases && cases.length > 0 && (
              <div className="preview-cards preview-cards-not-media preview-cards-cases">
                <h1 className="title-bpool medium">
                  <b>{I18n.get("Cases")}</b>
                </h1>

                <Carousel
                  items={cases.map((item, index) => (
                    <div key={index} onClick={this.select.bind(this, item)}>
                      <CaseItem {...item} />
                    </div>
                  ))}
                  size={2}
                />

                <div
                  className={
                    awards && awards.length > 0
                      ? "preview-hr"
                      : "preview-hr-hidden"
                  }
                >
                  &nbsp;
                </div>
              </div>
            )}

            {awards && awards.length > 0 && (
              <div className="preview-cards preview-cards-not-media preview-cards-awards">
                <h1 className="title-bpool medium">
                  <b>{I18n.get("Prêmios")}</b>
                </h1>

                <Carousel
                  items={awards.map((item, index) => (
                    <AwardItem key={index} {...item} />
                  ))}
                  size={3}
                />
              </div>
            )}
          </div>
          <footer className="card-footer">
            {this.props.selectPartner && (
              <LogoButton
                label={I18n.get("Quero este parceiro")}
                onClick={this.props.selectPartner}
                task={this.props.task}
              />
            )}
          </footer>
        </div>
        <Modal isOpen={!!this.state.selected} closeModal={this.closeModal}>
          <CaseFull {...this.state.selected} partner={this.props} />

          <br />

          {this.props.selectPartner && (
            <LogoButton
              label={I18n.get("Quero este parceiro")}
              onClick={this.props.selectPartner}
              task={this.props.task}
            />
          )}
        </Modal>
      </div>
    );
  }
}

export default PartnerLargeCard;
