import React from 'react';

import { I18n } from 'aws-amplify';

import TooltipField from './tooltip-field';

import MarkAsRequiredOrOptional from './mark-as-required-or-optional';

export default class TextField extends React.Component {

    constructor(props) {
        super();

        this.state = {
            focused: false,
            visited: props.visited
        };
    }

    static defaultProps = {
        successIcon: true
    }

    onBlur() {
        this.setState({ focused: false });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onFocus() {
        this.setState({ focused: true, visited: true });
    }

    render() {

        const className = this.props.className || 'field';
        const inputClassName = this.props.inputClassName || 'input';
        const controlClassName = this.props.successIcon ? 'control has-icons-right' : 'control';

        const isError = !this.state.focused && this.state.visited && this.props.error;

        const isValid = !!this.props.value && !this.props.error;

        return (<div className={className} style={this.props.style} >
            {!!this.props.label &&<label className="label is-small">
                {this.props.label}  <MarkAsRequiredOrOptional {...this.props} /> <TooltipField {...this.props} />
            </label>}
            <div className={controlClassName}>
                <input
                    {...this.props}
                    onChange={this.props.onChange}
                    onBlur={() => this.onBlur()}
                    onFocus={() => this.onFocus()}
                    value={this.props.value || ''}
                    className={isError ? inputClassName + " is-danger" : inputClassName}
                    type="text"
                    placeholder={this.props.placeholder || ''}
                    readOnly={this.props.readOnly || false}
                    disabled={this.props.disabled || false}
                    maxLength={this.props.maxLength}
                />
                {this.props.successIcon && isValid && <span className="icon is-small is-right form-field-valid">
                    <i className="fa fa-check-circle"></i>
                </span>}
            </div>
            {isError && <p className="help is-danger">
                {this.props.error.errorMessage}
            </p>}
        </div>)
    }
}

