import React from 'react';

import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import { Tabs, Row, Col, Select, Button, Table, Tag, Modal, Empty, Drawer } from 'antd';

import FormSections from '../../modules/Admin/components/formSections';

import TableSections from '../../modules/Admin/components/tableSections';

import { Task } from '../../utils/task';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_knowledge: () => {
            return dispatch(BP_API.get_knowledge());
        },
        create_section: data => {
            return dispatch(BP_API.create_section(data));
        },
        update_section: data => {
            return dispatch(BP_API.update_section(data));
        },
        delete_section: data => {
            return dispatch(BP_API.delete_section(data));
        }
    };
};


class KnowledgeAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
            task: {},
            visible: false,
            item: {}
        };
    }

    componentDidMount() {
         this.props.get_knowledge();
    }

    componentDidUpdate = (prevProps) => {
        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        if (task != prev) {
            this.setState({ task: task });
        }

        if(this.props.bp && this.props.bp.knowledgeBase != prevProps.bp.knowledgeBase) {
            this.setState({
                task: {}
            })
        }
    }

    handleDelete = (data) => {
        this.props.delete_section(data).then((result) => {
            if(result) {
                this.props.get_knowledge();
                this.close();
            }
        });
    }

    handleAction = (data) => {
        var task = new Task();
        
        task.start();

        let dataSend = {
            ...data,
        }

        this.setState({ task: task, id: data.clientId, updateList: true}, function () {

            if(Object.keys(this.state.item).length > 0) {
                dataSend.scopeId = this.state.item.scopeId;
                dataSend.sectionId = this.state.item.sectionId;
                this.props.update_section(dataSend).then((result) => {
                    if(result) {
                        this.props.get_knowledge();
                        this.close();
                    }
                });
            } else {
                this.props.create_section(dataSend).then((result) => {
                    if(result) {
                        this.props.get_knowledge();
                        this.close();
                    }
                });
            }
            
        });
    }

    close = () => {
        this.setState({
            visible: false,
            item: {}
        })
    }

    setItem = (item) => {
        this.setState({
            visible: true,
            item: item
        })
    }

    render() {
        const {bp: {knowledgeBase = []}} = this.props;

        let dataSections = [];
        let dataScopes = [];

        knowledgeBase.map((scope) => {  
            dataScopes.push(
                {
                    ...scope
                }
            )

            if(scope.sections && scope.sections.length > 0){
                scope.sections.map(section => {

                    dataSections.push(
                        {
                            ...section,
                            scope: scope.name,
                            scopeId: scope.scopeId,
                            language: scope.language,
                            target: scope.target
                        }
                    )
                });
            }
        });


        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/wikis" className="is-black">{I18n.get("Wikis")}</NavLink></li>
                    </ul>
                </nav>

                <div className="card">
                    <div className="card-container card-wikis">
                            <p className="btpull">
                                <Button onClick={() => this.setState({visible: true, item: {}})} className="button bt-bpool black pull-right">{I18n.get("Adiconar Section")}</Button>
                            </p>

                            <TableSections 
                                data={dataSections || []}
                                edit={this.setItem}
                                delete={this.handleDelete}
                            />
                    </div>
                </div>

                <Drawer
                    title={Object.keys(this.state.item).length > 0 ? I18n.get("Editar") : I18n.get("Adicionar")}
                    placement="right"
                    closable={true}
                    onClose={() => this.close()}
                    destroyOnClose={true}
                    visible={this.state.visible}
                    width="50%"
                >
                    <FormSections
                        scopes={dataScopes || []}
                        action={this.handleAction}
                        task={this.state.task}
                        errors={this.props?.bp?.errors}
                        item={this.state.item}
                    />
                </Drawer>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeAdmin);
