export const hasDataBilling = (data) => {
  if (data?.bankBranch) {
    const obj = {
      formOfPayment: 1,
      formOfPaymentName: "Transferência",
      urlNF: data?.file?.url,
      data: {
        bankId: data?.bankId,
        bankBranch: data?.bankBranch,
        bankAccount: data?.bankAccount,
        invoiceNumber: data?.invoiceNumber,
        totalFmt: data?.totalFmt,
      },
    };
    return obj;
  } else if (data?.pix) {
    const obj = {
      formOfPayment: 2,
      formOfPaymentName: "Pix",
      urlNF: data?.file?.url,
      data: {
        pix: data?.pix,
        invoiceNumber: data?.invoiceNumber,
        totalFmt: data?.totalFmt,
      },
    };
    return obj;
  } else if (data?.bankSwift) {
    const obj = {
      formOfPayment: 3,
      formOfPaymentName: "SWIFT",
      urlNF: data?.file?.url,
      data: {
        bankName: data?.bankName,
        bankAddress: data?.bankAddress,
        bankCity: data?.bankCity,
        bankCountry: data?.bankCountry,
        bankSwift: data?.bankSwift,
        bankABA: data?.bankABA,
        bankAccount: data?.bankAccount,
        invoiceNumber: data?.invoiceNumber,
        totalFmt: data?.totalFmt,
      },
    };
    return obj;
  } else {
    const obj = {
      formOfPayment: 4,
      formOfPaymentName: "Nenhum dado bancário",
      urlNF: null,
      data: null,
    };
    return obj;
  }
};
