import React from 'react';

import { I18n } from 'aws-amplify';

import AwardsForm from '../awards-form';
import AwardsList from '../awards-list';
import AwardsCheck from './awards-check';

export default class AwardsCmp extends React.Component {

    constructor(props) {
        super();

        this.state = {
            editing: false
        }
    }

    onEditingChange(value, e) {
        this.setState({
            editing: value
        });
    }

    render() {

        return (
            <section>
                <h1 className="title-bpool medium">
                    {this.props.title}
                </h1>

                {!this.state.editing && this.props.type == 1 && <AwardsCheck {...this.props} type={this.props.type} />}

                {!this.state.editing && this.props.type == 2 && <AwardsList {...this.props} type={this.props.type} />}

                {!this.state.editing && this.props.type == 2 && <div className="field has-text-right">
                    <button
                        type="button"
                        style={{ marginTop: 15, fontSize: 12, textTransform: "uppercase" }}
                        onClick={this.onEditingChange.bind(this, true)}
                        className="button is-leaked">
                        <i style={{ display: "inline-block", marginRight: 15 }} className="fa fa-plus"></i>{I18n.get("Adicionar prêmio")}</button>
                </div>
                }

                {!!this.state.editing && <AwardsForm {...this.props} type={this.props.type} onEditingChange={this.onEditingChange.bind(this)} />}

            </section>
        );
    }
}