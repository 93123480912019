import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import styled from 'styled-components';
import {
    Form,
    Select,
    Button,
    Input,
    Row,
    Col,
} from 'antd';

import WhatifWrapper from '../components/WhatifWrapper';

import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import { TitleBorder, TitleMiddleLine } from '../components/Titles';
import { CardBorder } from '../components/Cards'

import { MENU_PARTNER_REGISTER, STATE } from '../constants';
import { PartnerWhatIfStatus } from '../../../model';

const { Option } = Select;



const Main = styled.div`
  max-width: 820px;
  margin: 0 auto 100px;
  padding-top: 80px;
  width: 100%;
  box-sizing: border-box;
`;


const RowSubmit = styled(Row)`
  button {
      margin-right: 20px;
  }
`;


const estados = [
    {
        title: I18n.get("Norte"),
        value: 'Norte',
        key: 'norte',
        children: [
            {
                title: I18n.get("Manaus"),
                value: 'Manaus',
                key: 'manaus',
            },
            {
                title: I18n.get("Belem"),
                value: 'Belem',
                key: 'belem',
            },
        ],
    },
    {
        title: I18n.get("Nordeste"),
        value: 'Nordeste',
        key: 'nordeste',
        children: [
            {
                title: I18n.get("Recife"),
                value: 'Recife',
                key: 'recife',
            },
            {
                title: I18n.get("Fortaleza"),
                value: 'fortaleza',
                key: 'fortaleza',
            },
            {
                title: I18n.get("Maceio"),
                value: 'Maceio',
                key: 'maceio',
            },
        ],
    },
];


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        update_step1: data => {
            dispatch(PARTNER_WHATIF_API.update_step1(data));
        }
    };
};

class GeneralInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: false,
            formState: {},
            fieldsForm: {},
            statusBlur: false,
            fieldBlur: "",
            isSubmit: false
        };
    }

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            statusBlur: false
        })

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const dataForm = {
                    partnerWhatIfId: this.props.partnerWhatIf.partnerWhatIfId,
                    pageStep: "step1",
                    isSubmit: true,
                    ...values,
                }

                this.setState({
                    formState: values
                })

                this.props.update_step1(dataForm);
            }
        });
    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partnerWhatIf.errors != prevProps.partnerWhatIf.errors && !this.state.statusBlur) {
            Object.keys(this.props.partnerWhatIf.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.partnerWhatIf.errors[field].errorMessage)],
                    },
                });
            });
        };

        if (this.props.partnerWhatIf.errors != prevProps.partnerWhatIf.errors && this.props.partnerWhatIf.errors[this.state.fieldBlur] && this.state.statusBlur) {

            this.props.form.setFields({
                [this.state.fieldBlur]: {
                    errors: [new Error(this.props.partnerWhatIf.errors[this.state.fieldBlur].errorMessage)],
                },
            });
        }
    }

    onBlur = (e, field) => {
        const fieldsForm = this.props.form.getFieldsValue();

        const dataForm = {
            partnerWhatIfId: this.props.partnerWhatIf.partnerWhatIfId,
            pageStep: "step1",
            ...fieldsForm
        }

        this.setState({
            statusBlur: true,
            fieldBlur: field,
            isSubmit: false,
        });

        setTimeout(() => {
            this.props.update_step1(dataForm);
        }, 10);

    };



    render() {
        const { getFieldDecorator } = this.props.form;



        if (this.props.partnerWhatIf.status === PartnerWhatIfStatus.CompletedStep1) {
            return (<Redirect to={"/parceiro-ideias/informacoes-gerais/sucesso"} />);
        }

        return (
            <WhatifWrapper isRegister>
                <SubHeader itensMenu={MENU_PARTNER_REGISTER} activeItem={1} />
                <ContainerPage>
                    <Main>
                        <CardBorder
                            Bcolor="#fff"
                        >
                            <TitleBorder>Informações Gerais</TitleBorder>

                            <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu phare tra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                            <p><a href="#" title="Ajuda para obter um CNPJ>Preciso de um cnpj"></a></p>
                        </CardBorder>
                        <Form onSubmit={this.handleSubmit}>
                            <CardBorder
                                Bcolor="#bab1d1"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <TitleMiddleLine>Informações Gerais</TitleMiddleLine>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <Form.Item label="Nome Completo">
                                            {getFieldDecorator('name', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.name ? this.props.partnerWhatIf.name : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "name")}
                                                />
                                            )}
                                        </Form.Item>

                                    </Col>

                                    <Col sm={12}>
                                        <Form.Item label="Gosto de ser chamado de">
                                            {getFieldDecorator('nameAlias', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.nameAlias ? this.props.partnerWhatIf.nameAlias : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "nameAlias")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>


                                    <Col sm={12}>
                                        <Form.Item label="E-mail">
                                            {getFieldDecorator('email', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.email ? this.props.partnerWhatIf.email : null
                                            })(
                                                <Input
                                                    disabled
                                                />
                                            )}
                                        </Form.Item>

                                    </Col>

                                    <Col sm={12}>
                                        <Form.Item label="Endereço Completo">
                                            {getFieldDecorator('address', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.address ? this.props.partnerWhatIf.address : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "address")}
                                                />
                                            )}
                                        </Form.Item>

                                    </Col>
                                    <Col sm={12}>
                                        <Form.Item label="Cidade">
                                            {getFieldDecorator('city', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.city ? this.props.partnerWhatIf.city : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "city")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Item label="UF">
                                            {getFieldDecorator('state', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.state ? this.props.partnerWhatIf.state : null
                                            })(
                                                <Select
                                                    style={{ width: "100%" }}
                                                    onBlur={e => this.onBlur(e, "state")}
                                                >
                                                    <Option value="sp">SP</Option>
                                                    <Option value="rj">RJ</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col sm={8}>
                                        <Form.Item label="CEP">
                                            {getFieldDecorator('zipCode', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.zipCode ? this.props.partnerWhatIf.zipCode : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "zipCode")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col sm={12}>
                                        <Form.Item label="Telefone Fixo(DDD)">
                                            {getFieldDecorator('phoneNumber', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.phoneNumber ? this.props.partnerWhatIf.phoneNumber : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "phoneNumber")}
                                                />
                                            )}
                                        </Form.Item>

                                    </Col>
                                    <Col sm={12}>
                                        <Form.Item label="Celular (DDD)">
                                            {getFieldDecorator('phoneNumberCel', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.phoneNumberCel ? this.props.partnerWhatIf.phoneNumberCel : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "phoneNumberCel")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col sm={12}>
                                        <Form.Item label="Gênero">
                                            {getFieldDecorator('genre', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.genre ? this.props.partnerWhatIf.genre : null
                                            })(
                                                <Select
                                                    style={{ width: "100%" }}
                                                    onBlur={e => this.onBlur(e, "genre")}
                                                >
                                                    <Option value="homem">Homem</Option>
                                                    <Option value="mulher">Mulher</Option>
                                                </Select>
                                            )}
                                        </Form.Item>

                                    </Col>
                                    <Col sm={12}>
                                        <Form.Item label="CNPJ">
                                            {getFieldDecorator('companyRegistrationId', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.companyRegistrationId ? this.props.partnerWhatIf.companyRegistrationId : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "companyRegistrationId")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Item label="Linkedin">
                                            {getFieldDecorator('companyLinkedin', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.companyLinkedin ? this.props.partnerWhatIf.companyLinkedin : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "companyLinkedin")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Item label="Site">
                                            {getFieldDecorator('companyWebsite', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.partnerWhatIf.companyWebsite ? this.props.partnerWhatIf.companyWebsite : null
                                            })(
                                                <Input
                                                    onBlur={e => this.onBlur(e, "companyWebsite")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </CardBorder>


                            <CardBorder>
                                <RowSubmit>
                                    <Col sm={24}>
                                        <Button type="primary" shape="round" size="large">Cancelar</Button>
                                        <Button type="primary" htmlType="submit" shape="round" size="large">Enviar</Button>
                                    </Col>
                                </RowSubmit>
                            </CardBorder>

                        </Form>
                    </Main>
                </ContainerPage>
            </WhatifWrapper >
        );
    }
}

const GeneralInformationForm = Form.create({})(GeneralInformation);

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInformationForm);
