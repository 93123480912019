import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';

import { PartnerWhatIfStatus } from '../../../model';

import styled from 'styled-components';
import {
    Form,
    Select,
    Collapse,
    Button,
    Icon,
    Input,
    Checkbox,
    Row,
    Col,
    Alert
} from 'antd';

import WhatifWrapper from '../components/WhatifWrapper';
import Carousel from '../components/Carousel'

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import { TitleBorder, TitleMiddle, TitleMiddleLine } from '../components/Titles';
import { CardBorder } from '../components/Cards';
import ModalEditCase from '../components/ModalEditCase';
import UploadFile from '../components/UploadFile';

import { MENU_PARTNER_REGISTER } from '../constants';

const { TextArea, Search } = Input;
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const { Panel } = Collapse;

const FieldCustom = styled.div`

`

const BoxAddFild = styled(Search)`
    button {
        position:relative;

        i {
            display: none;
        }
    }
`;


const Main = styled.div`
  max-width: 820px;
  margin: 0 auto 100px;
  padding-top: 80px;
  width: 100%;
  box-sizing: border-box;

  .icon-actions {
      i {
          margin-left: 10px;
      }
  }
`;

const BoxTerm = styled.div`
  background: #fff;
  padding: 20px;
  height: 200px;
  overflow-x: hidden;

  p {
    color: #353535;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 15px;
  }
`;

const RowSubmit = styled(Row)`
  button {
      margin-right: 20px;
  }
`;
const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
      margin-right: 30px;
  }
`;
const RowCase = styled(Row)`
    margin-bottom: 30px;
    .imgItem {
      padding: 0 10px;
      height: 200px;
      text-align: center;


      img {
          max-width: 100%;
          max-height: 100%;
          display: inline-block!important;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
      }
  }
`

const skillsOptions = ['Social media', 'Redação', 'Artes Plásticas', 'test1'];

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        update_step1: data => {
            dispatch(PARTNER_WHATIF_API.update_step1(data));
        },
        remove_case: data => {
            dispatch(PARTNER_WHATIF_API.remove_case(data));
        },
        upload: dataUpload => {
            return PARTNER_WHATIF_API.upload(dataUpload);
        }
    };
};



class PartnerProfile extends Component {

    state = {
        loading: false,
        links: [],
        portfolioURLs: this.props.partnerWhatIf.portfolioURLs ? this.props.partnerWhatIf.portfolioURLs : [],
        videos: [],
        imageLogo: this.props.partnerWhatIf.imageLogo ? this.props.partnerWhatIf.imageLogo : "",
        imageIntro: this.props.partnerWhatIf.imageIntro ? this.props.partnerWhatIf.imageIntro : "",
        imagesRef: [],
        cases: [],
        loadingCase: false,
        showAddCase: false,
        showEditCase: false,
        socialURLs: this.props.partnerWhatIf.socialURLs ? this.props.partnerWhatIf.socialURLs : [],
        statusBlur: false,
        fieldBlur: "",
        editItem: {},
        typeModal: "",
        resetModal: false,
        isSubmit: false,
        redirectSuccess: false

    };


    handleCancel = () => {
        this.setState({ showAddCase: false, editItem: {}, typeModal: "", resetModal: false });
    };

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            statusBlur: false
        })

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    formState: values,
                    isSubmit: true,
                    redirectSuccess: true
                })

                setTimeout(() => {
                    this.sendDataApi();
                }, 10);


            }
        });
    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partnerWhatIf.errors != prevProps.partnerWhatIf.errors && !this.state.statusBlur) {
            Object.keys(this.props.partnerWhatIf.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.partnerWhatIf.errors[field].errorMessage)],
                    },
                });
            });
        };

        if (this.props.partnerWhatIf.errors != prevProps.partnerWhatIf.errors && this.props.partnerWhatIf.errors[this.state.fieldBlur] && this.state.statusBlur) {

            this.props.form.setFields({
                [this.state.fieldBlur]: {
                    errors: [new Error(this.props.partnerWhatIf.errors[this.state.fieldBlur].errorMessage)],
                },
            });
        }

        if (this.props.partnerWhatIf != prevProps.partnerWhatIf) {
            this.setState({
                portfolioURLs: this.props.partnerWhatIf.portfolioURLs,
                imageLogo: this.props.partnerWhatIf.imageLogo,
                imageIntro: this.props.partnerWhatIf.imageIntro,
                socialURLs: this.props.partnerWhatIf.socialURLs
            });
        };

    }

    sendDataApi = () => {
        const fieldsForm = this.props.form.getFieldsValue();

        const dataForm = {
            ...fieldsForm,
            partnerWhatIfId: this.props.partnerWhatIf.partnerWhatIfId,
            pageStep: "step2",
            socialURLs: this.state.socialURLs,
            portfolioURLs: this.state.portfolioURLs,
            imageLogo: this.state.imageLogo,
            imageIntro: this.state.imageIntro,
            isSubmit: this.state.isSubmit
        }

        this.props.update_step1(dataForm);
    }

    onBlur = (e, field) => {

        this.setState({
            statusBlur: true,
            fieldBlur: field,
            isSubmit: false,
            redirectSuccess: false
        });

        setTimeout(() => {
            this.sendDataApi();
        }, 1);
    };

    addUrlPortfolio = (value) => {
        this.setState({
            statusBlur: true,
            portfolioURLs: [...this.state.portfolioURLs, value]
        })

        setTimeout(() => {
            this.sendDataApi();
        }, 20);
    }

    addUrlSocial = (value) => {
        this.setState({
            statusBlur: true,
            socialURLs: [...this.state.socialURLs, value]
        })

        setTimeout(() => {
            this.sendDataApi();
        }, 20);
    }

    getDataUpload = (dataForm) => {
        const { field, url } = dataForm;


        switch (field) {
            case "imageLogo":
                this.setState({
                    imageLogo: url
                });
                break;

            case "imageIntro":
                this.setState({
                    imageIntro: url
                });
                break;

            default:
                break;
        }

        const fieldsForm = this.props.form.getFieldsValue();

        const data = {
            ...fieldsForm,
            partnerWhatIfId: this.props.partnerWhatIf.partnerWhatIfId,
            imageLogo: this.state.imageLogo,
            imageIntro: this.state.imageIntro,
            socialURLs: this.state.socialURLs,
            pageStep: "step2"

        }

        this.setState({
            statusBlur: true
        });


        this.props.update_step1(data);
    };

    genExtra = (item) => (
        <span className="icon-actions">
            <Icon
                type="edit"
                theme="filled"
                onClick={() => this.editItemCase(item)}
            />
            <Icon
                type="delete"
                theme="filled"
                onClick={() => this.removeItemCase(item)}
            />
        </span>
    );

    editItemCase = (item) => {
        this.setState({
            typeModal: "edit",
            editItem: item,
            showAddCase: true

        });
    }

    removeItemCase = (item) => {
        const dataForm = {
            partnerWhatIfId: this.props.partnerWhatIf.partnerWhatIfId,
            caseId: item.caseId
        }

        this.props.remove_case(dataForm);
    }

    renderCaseItem = (cases) => {

        return cases.map(item => {
            return (
                <Panel header={`${item.name}`} key={item.caseId} extra={this.genExtra(item)}>
                    <RowCase>
                        <Col sm={24}>
                            <TitleMiddleLine>Descrição</TitleMiddleLine>
                            <p>{item.description}</p>
                        </Col>
                    </RowCase>

                    <RowCase>
                        <Col sm={24}>
                            <TitleMiddleLine>Ano</TitleMiddleLine>

                            <p>
                                {item.year &&
                                    <span>{item.year}</span>
                                }
                                {!item.year &&
                                    <span>--</span>
                                }
                            </p>
                        </Col>
                    </RowCase>
                    <RowCase>
                        <Col sm={24}>
                            <TitleMiddleLine>Imagens</TitleMiddleLine>

                            {item.images.length <= 0 &&
                                <p>Nenhuma imagem cadastrada</p>
                            }

                            {item.images.length > 0 &&
                                <Carousel
                                    dots={false}
                                    infinite={true}
                                    slidesToShow={item.images.length == 1 ? 1 : 2}
                                    slidesToScroll={1}
                                    variableWidth={false}
                                >
                                    {item.images.map(item => {
                                        return (
                                            <div key={item} className="imgItem">
                                                <img src={item} />
                                            </div>

                                        )
                                    })}
                                </Carousel>
                            }
                        </Col>
                    </RowCase>
                    <RowCase>
                        <Col sm={24}>
                            <TitleMiddleLine>Vídeos</TitleMiddleLine>

                            {item.videos.length <= 0 &&
                                <p>Nenhum vídeo cadastrado</p>
                            }

                            {item.videos &&
                                <ul className="ListDinamic">
                                    {item.videos.map(item => {
                                        return <li key={item}><a href="#" target="_blank"><Icon type="video-camera" /> {item}</a></li>
                                    })
                                    }
                                </ul>
                            }
                        </Col>
                    </RowCase>
                </Panel>
            );
        });


    }


    render() {
        const { getFieldDecorator } = this.props.form;

        if (this.props.partnerWhatIf.status && this.props.partnerWhatIf.status !== PartnerWhatIfStatus.CompletedStep1 && this.props.partnerWhatIf.status !== PartnerWhatIfStatus.Approved) {
            return (<Redirect to={"/parceiro-ideias/informacoes-gerais"} />);
        }

        if (this.props.partnerWhatIf.status && this.props.partnerWhatIf.status == PartnerWhatIfStatus.Approved && this.props.partnerWhatIf.isSubmit == true && this.state.redirectSuccess) {
            return (<Redirect to={"/parceiro-ideias/profile/sucesso"} />);
        }




        return (
            <Fragment>
                <WhatifWrapper isRegister>
                    <SubHeader itensMenu={MENU_PARTNER_REGISTER} activeItem={2} />
                    <ContainerPage>
                        <Main>
                            <CardBorder
                                Bcolor="#fff"
                            >
                                <TitleBorder>Profile</TitleBorder>

                                <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                            </CardBorder>
                            <Form onSubmit={this.handleSubmit}>
                                <CardBorder
                                    Bcolor="#bab1d1"
                                >
                                    <Row>
                                        <Col sm={24}>
                                            <TitleMiddleLine>Informações gerais</TitleMiddleLine>

                                            <Form.Item label="Logotipo">
                                                {getFieldDecorator('imageLogo', {
                                                    valuePropName: 'imageLogo'
                                                })(
                                                    <Fragment>
                                                        <UploadFile
                                                            name="imageLogo"
                                                            actionUpload={this.props.upload}
                                                            getDataUpload={this.getDataUpload}
                                                            id={this.props.partnerWhatIf.partnerWhatIfId}
                                                            imgUrl={this.props.partnerWhatIf.imageLogo}
                                                        />
                                                    </Fragment>
                                                )}
                                            </Form.Item>


                                            <Form.Item label="Imagem Destaque">
                                                {getFieldDecorator('imageIntro', {
                                                    valuePropName: 'imageIntro',
                                                })(
                                                    <Fragment>
                                                        <UploadFile
                                                            name="imageIntro"
                                                            cover
                                                            actionUpload={this.props.upload}
                                                            getDataUpload={this.getDataUpload}
                                                            id={this.props.partnerWhatIf.partnerWhatIfId}
                                                            imgUrl={this.props.partnerWhatIf.imageIntro}
                                                        />
                                                    </Fragment>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </CardBorder>


                                <CardBorder
                                    Bcolor="#c7e5f2"
                                >
                                    <Row>
                                        <Col sm={24}>
                                            <TitleMiddleLine>Informações Profissionais</TitleMiddleLine>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Item label="Profissão ( o que te define )">
                                                {getFieldDecorator('occupation', {
                                                    rules: [{ required: false, message: 'ops!' }],
                                                    initialValue: this.props.partnerWhatIf.occupation ? this.props.partnerWhatIf.occupation : null
                                                })(
                                                    <Input
                                                        onBlur={e => this.onBlur(e, "occupation")}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>
                                            <Form.Item label="Idiomas que falo - nível">
                                                {getFieldDecorator('languages', {
                                                    rules: [{ required: false, message: 'ops!' }],
                                                    initialValue: this.props.partnerWhatIf.languages ? this.props.partnerWhatIf.languages : null
                                                })(


                                                    <Select
                                                        mode="tags"
                                                        style={{ width: '100%' }}
                                                        placeholder="Multiplos"
                                                        onChange={e => this.onBlur(e, "languages")}
                                                    >
                                                        <Option value="Inglês básico">Inglês básico</Option>
                                                        <Option value="Inglês intermediário">Inglês intermediário</Option>
                                                        <Option value="Inglês avançado">Inglês avançado</Option>
                                                        <Option value="Espanhol básico">Espanhol básico</Option>
                                                        <Option value="Espanhol intermediário">Espanhol intermediário</Option>
                                                        <Option value="Espanhol avançado">Espanhol avançado</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>
                                            <FieldCustom>
                                                <label className="label-bp">Redes Sociais (Link)</label>
                                                <BoxAddFild
                                                    className="InputPlus"
                                                    placeholder=""
                                                    onSearch={value => { this.addUrlSocial(value) }}
                                                    enterButton
                                                />
                                            </FieldCustom>
                                        </Col>
                                    </Row>
                                    {this.state.socialURLs &&
                                        <Row>
                                            <Col sm={24}>
                                                <ul className="ListDinamic">
                                                    {this.props.partnerWhatIf.socialURLs &&
                                                        this.props.partnerWhatIf.socialURLs.map(item => {
                                                            return <li key={item}><a href="#" target="_blank"><Icon type="link" /> {item}</a></li>
                                                        })
                                                    }
                                                </ul>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col sm={24}>
                                            <Form.Item label="Assinale suas habilidades">
                                                {getFieldDecorator('skills', {
                                                    rules: [{ required: false, message: 'ops!' }],
                                                    initialValue: this.props.partnerWhatIf.skills ? this.props.partnerWhatIf.skills : null
                                                })(
                                                    <CheckboxGroup
                                                        options={skillsOptions}
                                                        onBlur={e => this.onBlur(e, "skills")}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <Form.Item label="Em que nível se encontra em sua profissão">
                                                {getFieldDecorator('occupationLevel', {
                                                    rules: [{ required: false, message: 'ops!' }],
                                                    initialValue: this.props.partnerWhatIf.occupationLevel ? this.props.partnerWhatIf.occupationLevel : null
                                                })(
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        onChange={e => this.onBlur(e, "occupationLevel")}
                                                    >
                                                        <Option value="avancado">Avançado - Mais de 10 anos de experiência</Option>
                                                        <Option value="medio">Médio - de 5 a 10 anos de experiência</Option>
                                                        <Option value="baixo">Baixo - Menos de 5 anos de experiência</Option>
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item label="Digite palavras-chaves que definem seu perfil">
                                                {getFieldDecorator('profileTags', {
                                                    rules: [{ required: false, message: 'ops!' }],
                                                    initialValue: this.props.partnerWhatIf.profileTags ? this.props.partnerWhatIf.profileTags : null
                                                })(
                                                    <Select
                                                        mode="tags"
                                                        style={{ width: '100%' }}
                                                        onChange={e => this.onBlur(e, "profileTags")}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <Form.Item label="Adicione as marcas que você ja trabalhou">
                                                {getFieldDecorator('brandsAlreadyWorked', {
                                                    rules: [{ required: false, message: 'ops!' }],
                                                    initialValue: this.props.partnerWhatIf.brandsAlreadyWorked ? this.props.partnerWhatIf.brandsAlreadyWorked : null
                                                })(
                                                    <Select
                                                        mode="tags"
                                                        style={{ width: '100%' }}
                                                        onChange={e => this.onBlur(e, "brandsAlreadyWorked")}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item label="Quais Espertises / Mercados já trabalhou:">
                                                {getFieldDecorator('marketsAlreadyWorked', {
                                                    rules: [{ required: false, message: 'ops!' }],
                                                    initialValue: this.props.partnerWhatIf.marketsAlreadyWorked ? this.props.partnerWhatIf.marketsAlreadyWorked : null
                                                })(
                                                    <Select
                                                        mode="tags"
                                                        style={{ width: '100%' }}
                                                        onChange={e => this.onBlur(e, "marketsAlreadyWorked")}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </CardBorder>
                                <CardBorder
                                    Bcolor="#ffc5b9"
                                >
                                    <Row>
                                        <Col sm={24}>
                                            <TitleMiddleLine>Portfólio</TitleMiddleLine>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>
                                            <FieldCustom>
                                                <label className="label-bp">Links</label>
                                                <BoxAddFild
                                                    className="InputPlus"
                                                    placeholder=""
                                                    onSearch={value => { this.addUrlPortfolio(value) }}
                                                    enterButton
                                                />
                                            </FieldCustom>
                                        </Col>
                                    </Row>
                                    {this.props.partnerWhatIf.portfolioURLs &&
                                        <Row>
                                            <Col sm={24}>
                                                <ul className="ListDinamic">
                                                    {this.props.partnerWhatIf.portfolioURLs &&
                                                        this.props.partnerWhatIf.portfolioURLs.map(item => {
                                                            return <li key={item}><a href="#" target="_blank"><Icon type="link" /> {item}</a></li>
                                                        })
                                                    }
                                                </ul>
                                            </Col>
                                        </Row>
                                    }
                                </CardBorder>
                                <CardBorder
                                    Bcolor="#cbede0"
                                >
                                    <Row>
                                        <Col sm={18}>
                                            <TitleMiddleLine>Cases completos</TitleMiddleLine>
                                        </Col>
                                        <Col sm={6}>
                                            <Button onClick={() => { this.setState({ resetModal: true, editItem: {}, typeModal: "add", showAddCase: true }) }} type="primary" shape="round" size="large">Adicionar Case</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>

                                            {this.props.partnerWhatIf.cases && this.props.partnerWhatIf.cases.length > 0 &&
                                                <Collapse>
                                                    {this.renderCaseItem(this.props.partnerWhatIf.cases)}
                                                </Collapse>
                                            }

                                            {this.props.partnerWhatIf.cases && this.props.partnerWhatIf.cases.length <= 0 &&
                                                <Alert message="Nenhum case adicionado" type="warning" />
                                            }

                                        </Col>
                                    </Row>
                                </CardBorder>
                                <CardBorder
                                    Bcolor="#000"
                                >
                                    <Row>
                                        <Col sm={24}>
                                            <TitleMiddle>Termos e Condições</TitleMiddle>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>
                                            <BoxTerm>
                                                <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                                <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                                <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                                <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                            </BoxTerm>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>
                                            <Form.Item>
                                                {getFieldDecorator('termsAccepted', {
                                                    rules: [{ required: false, message: 'ops!' }],
                                                    initialValue: this.props.partnerWhatIf.termsAccepted,
                                                    valuePropName: 'checked'
                                                })(
                                                    <Checkbox
                                                        onChange={e => this.onBlur(e, "termsAccepted")}
                                                    >Li e aceito os termos de uso</Checkbox>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </CardBorder>
                                <CardBorder>
                                    <RowSubmit>
                                        <Col sm={24}>
                                            <Link to="/parceiro-ideias/profile/preview">
                                                <Button type="primary" shape="round" size="large">Visualizar</Button>
                                            </Link>
                                            <Button type="primary" htmlType="submit" shape="round" size="large">Salvar</Button>
                                        </Col>
                                    </RowSubmit>
                                </CardBorder>
                            </Form>
                        </Main>
                    </ContainerPage>

                </WhatifWrapper>


                <ModalEditCase
                    typeModal={this.state.typeModal}
                    item={this.state.editItem}
                    show={this.state.showAddCase}
                    onCancel={this.handleCancel}
                    partnerWhatIfId={this.props.partnerWhatIf.partnerWhatIfId}
                    reset={this.state.resetModal}
                />
            </Fragment>
        );
    }
}

const PartnerProfileForm = Form.create({})(PartnerProfile);

export default connect(mapStateToProps, mapDispatchToProps)(PartnerProfileForm);
