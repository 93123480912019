import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink } from 'react-router-dom';

import { Task } from '../../../utils/task';

import TextField from '../../form/text-field';
import MoneyField from '../../form/money-field';
import TextAreaField from '../../form/textarea-field';
import MaskedField from '../../form/masked-field';
import PercentageField from '../../form/percentage-field';
import SelectField from '../../form/select-field';
import SubmitButtonField from '../../form/submit-button-field';

import FileUploadList from '../../../modules/components/FileUploadList';

import Dropzone from 'react-dropzone';
import iconUpload from '../../../svg/icon-upload.svg';

import { ProjectExtraType, ProjectExtraTypeNames } from '../../../model';

import FormatCurrency from '../../ui/format-currency';

class CreateForm extends React.Component {
    constructor(props) {

        super(props);

        const { extraName, extraType, budget, dueDate, description, comments, files = [], workingBudget, bpCommission } = props;

        this.state = {
            fields: {
                extraType: {
                    label: 'Tipo do Extra',
                    name: 'extraType',
                    onChange: this.onChangeForm.bind(this, 'extraType'),
                    onBlur: this.onSave.bind(this),
                    visited: true
                },
                extraName: {
                    label: I18n.get("Nome do Extra"),
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'extraName'),
                    onBlur: this.onSave.bind(this),
                    visited: true
                },
                budget: {
                    label: I18n.get("Budget"),
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'budget'),
                    onBlur: this.onSave.bind(this),
                    visited: true
                },
                bpCommission: {
                    label: I18n.get("Platform Fee"),
                    successIcon: false,
                    onChange: () => { },
                    visited: true,
                    readOnly: true
                },
                workingBudget: {
                    label: I18n.get("Budget Disponivel"),
                    successIcon: false,
                    onChange: () => { },
                    visited: true,
                    readOnly: true
                },
                dueDate: {
                    label: I18n.get("Data da Entrega"),
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'dueDate'),
                    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
                    placeholder: I18n.get("DD/MM/AAAA"),
                    onBlur: this.onSave.bind(this),
                    visited: true
                },
                description: {
                    label: I18n.get("Descrição"),
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'description'),
                    onBlur: this.onSave.bind(this),
                    visited: true
                },
                comments: {
                    label: I18n.get("Observações"),
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'comments'),
                    onBlur: this.onSave.bind(this),
                    visited: true
                },


            },
            form: {
                extraName,
                extraType,
                budget,
                dueDate,
                description,
                comments,
                files,
            },
            errors: {},
            task: {},
            upload_task: {},
            workingBudget: workingBudget,
            bpCommission: bpCommission && bpCommission.perc,
        }
    }

    onChangeType(value, e) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['extraype'] = value;

            return nextState;
        }, () => this.onSave()
        );
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.files != prevProps.files) {
            this.setState({
                form: {
                    ...this.state.form,
                    files: this.props.files
                }

            });
        }
    }
    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onUpload(file, e) {

        const { projectId, extraId } = this.props.match.params;

        if (!file) return;

        if (e) e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            var upload_task = new Task();

            upload_task.start();

            this.setState({ upload_task: upload_task }, function () {

                const data = {
                    projectId: projectId,
                    extraId: extraId,
                    name: file.name,
                    base64: e.target.result
                };

                this.props.upload_extra_file(data)
                    .then(response => {

                        this.setState((prevState) => {

                            var nextState = Object.assign({}, prevState);

                            nextState.form.files = response.data;
                            nextState.upload_task = { result: true };

                            return nextState;
                        });

                    })
                    .catch(error => {

                        this.setState({ upload_task: { result: false } });

                    });
            });
        };
        reader.readAsDataURL(file);
    }

    delFile(item) {

        const { projectId, extraId } = this.props.match.params;

        var data = {
            projectId: projectId,
            extraId: extraId,
            ...item
        };

        const files = this.state.form.files.filter((f) => f.id != item.id);

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.files = files;

            return nextState;
        });

        setTimeout(() => {
            this.onSave();
        }, 50);

    }

    onDrop(files) {

        files = files.map(file => {

            this.onUpload(file);
        });
    }

    onSave() {

        const { projectId, extraId } = this.props.match.params;

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        var data = {
            projectId: projectId,
            extraId: extraId,
            ...this.state.form
        };

        this.props.update_extra(data)
            .then(({ task, errors, workingBudget, bpCommission }) => {

                if (task.result) {

                    this.setState({ workingBudget: workingBudget, bpCommission: bpCommission && bpCommission.perc });
                }

            });

    }

    onSubmitForm(e) {

        const { projectId, extraId } = this.props.match.params;

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ task: submit_task });

        var data = {
            projectId: projectId,
            extraId: extraId,
            ...this.state.form
        };

        this.props.update_extra(data)
            .then(({ extraId, task, errors }) => {

                this.setState({ task: task, errors: errors });

                if (task.result) {
                    this.setState({ form: {} });

                    this.props.history.push(`/projetos/${projectId}/extras/${extraId}/detalhes`);
                }

            });
    }

    getDataUploadMult = (files) => {

        const { projectId, extraId } = this.props.match.params;

        let file = files[0];

        delete file['uid'];

        // const data = {

        //     name: file.name,
        //     base64: e.target.result
        // };


        const data = {
            // ...this.props.project,
            projectId: projectId,
            extraId: extraId,
            id: file.id,
            name: file.name,
            size: file.size,
            type: file.type,
            uploadedByName: file.uploadedByName,
            uploadedByUsername: file.uploadedByUsername,
            uploadedDate: file.uploadedDate,
            url: file.url
        }

        // this.props.upload_filev2(data);

        this.props.upload_extra_file(data)
            .then(response => {

                this.setState((prevState) => {

                    var nextState = Object.assign({}, prevState);

                    nextState.form.files = response.data;
                    nextState.upload_task = { result: true };

                    return nextState;
                });

            })
            .catch(error => {

                this.setState({ upload_task: { result: false } });

            });
    }


    render() {

        const { projectId, extraId } = this.props.match.params;

        const currency = this.props.currency || {};

        const types = [ProjectExtraType.Production, ProjectExtraType.InternalCosts, ProjectExtraType.ExternalCosts, ProjectExtraType.Media, ProjectExtraType.Tools, ProjectExtraType.Finance];

        const projectExtraTypeValues = types.map((item) => ({ code: `${item}`, label: I18n.get(ProjectExtraTypeNames[item]) }))

        let filesNormalize = this.props.files && this.props.files.map((item) => {
            return {
                ...item,
                uid: item.id,
            }
        })

        return (<div>

            <NavLink to={`/projetos/${projectId}/extras/${extraId}/detalhes`} className="is-pulled-right">
                <span className="icon">
                    <i className="fas fa-times" aria-hidden="true"></i>
                </span>
            </NavLink>

            <h5 className="title-bpool medium">{I18n.get("Detalhes")}</h5>

            <form>

                <TextField {...this.state.fields.extraName} value={this.state.form.extraName} error={this.state.errors.extraName} />

                <SelectField {...this.state.fields.extraType} value={this.state.form.extraType} error={this.state.errors.extraType} values={projectExtraTypeValues} />

                <div className="columns">

                    <div className="column is-6">

                        <MoneyField
                            {...this.state.fields.budget}
                            currency={currency}
                            value={this.state.form.budget}
                            error={this.state.errors.budget}
                        />

                    </div>

                    <div className="column is-6">

                        <PercentageField {...this.state.fields.bpCommission} value={this.state.bpCommission} />

                    </div>

                </div>

                <div className="columns">

                    <div className="column is-6">

                        <MoneyField
                             {...this.state.fields.workingBudget} 
                             currency={currency}
                             value={this.state.workingBudget} />

                    </div>

                </div>

                <MaskedField {...this.state.fields.dueDate} value={this.state.form.dueDate} error={this.state.errors.dueDate} />

                <TextAreaField {...this.state.fields.description} value={this.state.form.description} error={this.state.errors.description} />

                <TextAreaField {...this.state.fields.comments} value={this.state.form.comments} error={this.state.errors.comments} />

                <div className="field">
                    <label className="label is-small">{I18n.get("Deseja anexar algum arquivo?")}</label>
                </div>
                {/* {this.state.form.files && this.state.form.files.map((item, index) => {
                    return (<div key={index} title={item.name} className="big-idea-box" style={{ width: '5rem', display: 'inline-block' }}>

                        <a href={item.url} target="_blank" style={{ color: '#ccc' }}>
                            <span className="icon is-large" style={{ marginTop: '1rem' }}>
                                <i className="fas fa-file fa-3x" aria-hidden="true"></i>
                            </span>
                        </a>

                        <a className="delete" onClick={this.delFile.bind(this, item)} ></a>

                        <div style={{ width: '5rem', padding: '0.5rem', fontSize: '80%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</div>
                    </div>
                    );
                })} */}

                <div style={{ fontSize: '80%', marginTop: '50px' }}>

                    {this.state.form.files && this.state.form.files.map((item, index) =>
                    (<div key={index} class="columns" style={{ borderBottom: "1px solid black" }}>
                        <div class="column is-two-thirds is-clearfix upload-item" >
                            <a href={item.url} target="_blank">{item.name}</a>
                        </div>
                        <div class="column ">
                            {item.uploadedDateFmt}
                            {!this.state.upload_task.busy
                                && !this.state.task.busy && <div className="is-pulled-right">

                                    {item.uploadedByUsername == this.props.auth.username
                                        && <a className="delete" onClick={this.delFile.bind(this, item)} ></a>}
                                </div>}
                        </div>
                    </div>)
                    )}

                </div>
                <br />
                <br />
                <FileUploadList
                    getDataUpload={this.getDataUploadMult}
                    name="files"
                    uploadId={this.props.project.projectId}
                    get2={this.props.get2 || false}
                    fileListGet={filesNormalize || []}
                    disabledList={true}
                    limit={1}
                    output="object"
                />

                {/* <Dropzone
                    multiple={false}
                    className="dropzone"
                    onDrop={this.onDrop.bind(this)}
                    activeClassName="has-background-light" >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <button className="button bt-bpool full upload-button-style" type="button" disabled={this.state.upload_task.busy}>
                                {this.state.upload_task.busy && <a className="button is-loading is-black" style={{ background: 'transparent' }}></a>}
                                {!this.state.upload_task.busy && <><span>{I18n.get("UPLOAD DE ARQUIVOS")}</span>
                                    <div className="upload-button-icon"><img src={iconUpload} alt="Upload" /></div></>}
                            </button>
                        </div>
                    )}

                </Dropzone> */}


                <br />
                <br />
                <br />

                <SubmitButtonField
                    label={I18n.get("Salvar")}
                    onClick={this.onSubmitForm.bind(this)}
                    className="button bt-bpool black is-pulled-right"
                    task={this.state.task} />

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

            </form>
        </div>
        );
    }
}

export default CreateForm;
