import React from "react";
import { NavLink } from "react-router-dom";

import { I18n } from "aws-amplify";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";

import BriefingForm from "./briefing-form";

import ReviewForm from "../shared/review-form";

import StepsCreative from "./steps-creative";
import StepsBidding from "./steps-bidding";

import { scrollToTop } from "../../utils/ui";

class ProjectDefintionStep1 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      task: {},
    };
  }

  submitForm(e) {}

  componentDidMount() {
    scrollToTop();
  }

  render() {

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container">
            <div className="columns is-mobile">
              <div
                className="column is-6"
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="title">
                  {I18n.get("Definições do Projeto")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6">
                {this.props.isCreative && (
                  <>
                    <StepsCreative
                      active={1}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                    />
                  </>
                )}
                {this.props.isPartnerSelectionBidding && (
                  <>
                    <StepsBidding
                      active={1}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="client-step client-step-1 project-definition">
          <div className="container client-container-1">
            {this.props.isCreative && (
              <>
                <BriefingForm {...this.props} />
              </>
            )}

            {this.props.isPartnerSelectionBidding && (
              <>
                <ReviewForm ref="form" {...this.props} />

                <br />
                <br />

                {this.props.auth.is_partner && (
                  <>
                    <p style={{ fontWeight: "bold" }}>
                      {I18n.get("Sua proposta inicial foi aprovada!")}
                    </p>
                    <p style={{ marginTop: "0.50rem", fontSize: "90%" }}>
                      {I18n.get(
                        "Agora é hora de detalhar os custos. Vamos lá?"
                      )}
                    </p>
                  </>
                )}

                <br />

                <div style={{ padding: "1rem" }}>
                  <NavLink
                    to={`/projetos/${this.props.match.params.projectId}/detalhamento-passo-2`}
                    className="button bt-bpool"
                  >
                    {I18n.get("Ir para Proposta")}
                  </NavLink>
                </div>
              </>
            )}

            <br />
            <br />
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(ProjectDefintionStep1);
