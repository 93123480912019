import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";

import { Task } from "../utils/task";
import { scrollToTop } from "../utils/ui";

import { PROJECT_API } from "../actions/project/actions";
import { PARTNER_API } from "../actions/partner/actions";

export default function ProjectCart(Component) {
  const mapStateToProps = (state) => {
    return Object.assign(
      state,
      { clientId: state.client.clientId },
      state.project
    );
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      save_temp_cart: (data) => {
        dispatch(PARTNER_API.save_temp_cart(data));
      },
      checkout2: (data) => {
        return dispatch(PROJECT_API.checkout2(data));
      },
      add_asset_itens: (data) => {
        return dispatch(PROJECT_API.add_asset_itens(data));
      },
    };
  };

  class ProjectCartContext extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        cartId: "",
        cartAssets: [],
        checkout_task: {},
        loadingAssets: false,
      };
    }

    componentDidMount() {
      scrollToTop();

      this.setState({
        loadingAssets: true,
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (!prevProps) return;
      if (!prevState) return;

      if (this.props.assets != prevProps.assets) {
        if (
          this.props.bundle &&
          this.props.bundle.variations &&
          this.props.bundle.variations.length > 0
        ) {
          let assetsBundle = this.props.bundle.variations[0].assets.map(
            (item) => {
              let newItem = this.props.assets.find((i) => i.code == item.asset);

              return {
                ...newItem,
              };
            }
          );

          this.setState({
            cartAssets: assetsBundle,
          });
        }
      }

      if (
        this.props.assets &&
        this.props.bundle &&
        this.state.loadingAssets != prevState.loadingAssets
      ) {
        let assetsBundle = this.props.bundle.variations[0].assets.map(
          (item, index) => {
            let newItem = this.props.assets.find((i) => i.code == item.asset);
            return {
              ...newItem,
            };
          }
        );

        this.setState({
          cartAssets: assetsBundle,
        });
      }

      if (
        this.props.assets &&
        this.props.bundle &&
        this.props.bundle != prevProps.bundle
      ) {
        let assetsBundle = this.props.bundle.variations[0].assets.map(
          (item, index) => {
            let newItem = this.props.assets.find((i) => i.code == item.asset);
            return {
              ...newItem,
            };
          }
        );

        this.setState({
          cartAssets: assetsBundle,
        });
      }

      // if (!this.props.bundle) {
      //   this.setState({
      //     cartAssets: [],
      //   });
      // }

      if (this.state.checkout_task.result) {
        this.clearCart();
      }
    }
    componentWillMount() {
      this.setState({
        loadingAssets: false,
      });
    }

    // componentWillUnmount() {
    //     this.setState({
    //         loadingAssets: false
    //     })
    // }

    getVariationAssets(specialism = []) {
      const variationAssets =
        this.props.assets &&
        this.props.assets
          .filter((asset) => {
            var isIncluded = false;

            asset.specialism &&
              asset.specialism.forEach((element) => {
                isIncluded = specialism && specialism.indexOf(element) > -1;
              });

            return isIncluded;
          })
          .map((asset) => {
            // Permite duplicados conforme PPT QA Plataforma v1 de 24/07 # Jordi
            // asset.isDisabled = this.state.cartAssets && this.state.cartAssets.map((item) => item.code).indexOf(asset.code) > -1;

            asset.currency = this.props.project.currency;

            return { code: asset.code, label: asset.label, data: asset };
          });

      return variationAssets;
    }

    getGroupVariationAssets(specialism = []) {
      const variationAssets =
        this.props.assets &&
        this.props.assets
          .filter((asset) => {
            var isIncluded = false;

            let group_specialisms = [];

            asset.specialism &&
              asset.specialism.map((code) => {
                const group =
                  this.props.assetSpecialismsGroup &&
                  this.props.assetSpecialismsGroup.find(
                    (item) => item.code == code
                  );

                if (group) {
                  group_specialisms = [
                    ...group_specialisms,
                    ...group.codesGroup,
                  ];
                } else {
                  group_specialisms = [...group_specialisms, code];
                }
              });

            asset.specialism &&
              asset.specialism.forEach((element) => {
                isIncluded = group_specialisms.indexOf(element) > -1;
              });

            return isIncluded;
          })
          .map((asset) => {
            // Permite duplicados conforme PPT QA Plataforma v1 de 24/07 # Jordi
            // asset.isDisabled = this.state.cartAssets && this.state.cartAssets.map((item) => item.code).indexOf(asset.code) > -1;
            let assetData = {
              ...asset,
              currency: this.props.project.currency,
            };

            return { code: asset.code, label: asset.label, data: assetData };
          });

      return variationAssets;
    }

    getData() {
      var data = {
        clientId: this.props.clientId,
        projectId: this.props.match.params.projectId,
        assets: this.state.cartAssets,
      };

      return data;
    }

    getDataPartner() {
      var data = {
        ...this.props.partner.projectStep1,
        assetsCanBeExcluded: [],
        assets: this.state.cartAssets,
        partnerId: this.props.partner.partnerId,
      };

      return data;
    }

    addItem = (item) => {
      const copy = Object.assign({}, item);

      this.setState((prevState) => {
        return { cartAssets: [...prevState.cartAssets, copy] };
      });

      setTimeout(() => {
        if (
          this.props.auth.is_partner &&
          this.props.project &&
          this.props.project.status == 1
        ) {
          this.props.save_temp_cart(this.state.cartAssets);
        } else if (
          this.props.auth.is_partner &&
          this.props.project &&
          this.props.project.status == 5
        ) {
          var data = {};
          data = {
            clientId: this.props.clientId,
            projectId: this.props.projectId,
            assets: this.state.cartAssets,
          };

          this.props.checkout(data);
        } else {
          this.props.checkout(this.getData());
        }
      }, 10);
    };

    delItem2 = (item) => {
      // this.setState((prevState) => {
      //   const items = prevState.cartAssets.filter((a) => a.code != item.code);

      //   return { cartAssets: items };
      // });

      this.setState((prevState) => {
        const items = prevState.cartAssets;

        const pos = items.map((a) => a.code).indexOf(item.code);

        items.splice(pos, 1);

        return { cartAssets: items };
      });

      setTimeout(() => {
        if (
          this.props.auth.is_partner &&
          this.props.project &&
          !this.props.project.status
        ) {
          this.props.save_temp_cart(this.state.cartAssets);
        } else if (
          this.props.auth.is_partner &&
          this.props.project &&
          this.props.project.status == 5
        ) {
          var data = {};
          data = {
            clientId: this.props.clientId,
            projectId: this.props.projectId,
            assets: this.state.cartAssets,
          };

          this.props.checkout(data);
        } else {
          this.props.checkout(this.getData());
        }
      }, 10);
    };

    delItem = (item, e) => {
      e.preventDefault();

      this.setState((prevState) => {
        const items = prevState.cartAssets.filter(
          (a) => a.code != item.asset.code
        );

        return { cartAssets: items };
      });

      setTimeout(() => {
        if (
          this.props.auth.is_partner &&
          this.props.project &&
          !this.props.project.status
        ) {
          this.props.save_temp_cart(this.state.cartAssets);
        } else if (
          this.props.auth.is_partner &&
          this.props.project &&
          this.props.project.status == 5
        ) {
          var data = {};
          data = {
            clientId: this.props.clientId,
            projectId: this.props.projectId,
            assets: this.state.cartAssets,
          };

          this.props.checkout(data);
        } else {
          this.props.checkout(this.getData());
        }
      }, 10);
    };

    createCart = (cartId, assets) => {
      const assetsCopy = assets.map((asset) => Object.assign({}, asset));

      this.setState({ cartId: cartId, cartAssets: assetsCopy });
    };

    clearCart = () => {
      this.setState({ cartId: "", cartAssets: [], checkout_task: {} });
    };

    checkoutVariation() {
      var data = {};

      if (this.props.auth.is_partner) {
        data = {
          ...this.props.partner.projectStep1,
          assetsCanBeExcluded: [],
          assets: this.props.cartAssets,
          partnerId: this.props.partner.partnerId,
        };
      } else {
        data = {
          clientId: this.props.clientId,
          projectId: this.props.projectId,
          assets: this.state.cartAssets,
        };
      }

      var task = new Task();

      task.start();

      this.setState({ checkout_task: task }, function () {
        if (this.props.auth.is_partner) {
          this.props.checkout(data);
        } else {
          this.props.checkout(data);
        }
      });
    }

    render() {
      let group = "";
      let cartSpecialism = "";
      let cartSpecialismGroup = "";

      if (!this.props.asset) {
        group =
          this.props?.bp &&
          this.props?.bp?.assetSpecialismsGroup &&
          this.props?.bp?.assetSpecialismsGroup.find(
            (item) => item.code == cartSpecialism
          );

        cartSpecialismGroup = group ? group.code : "";

        cartSpecialism =
          this.state.cartAssets &&
          this.state.cartAssets.length > 0 &&
          this.state.cartAssets[0].specialism
            ? this.state.cartAssets[0].specialism[0]
            : "";
      }

      // Adiciona o indice para identificar e remover assets adicionais multiplas vezes
      this.state.cartAssets &&
        this.state.cartAssets.map((item, index) => (item.idx = index));

      return (
        <Component
          {...this.props}
          cartId={this.state.cartId}
          cartAssets={this.state.cartAssets && this.state.cartAssets}
          addItem={this.addItem.bind(this)}
          delItem={this.delItem.bind(this)}
          delItem2={this.delItem2}
          createCart={this.createCart.bind(this)}
          clearCart={this.clearCart.bind(this)}
          checkoutVariation={this.checkoutVariation.bind(this)}
          getVariationAssets={this.getVariationAssets.bind(this)}
          getGroupVariationAssets={this.getGroupVariationAssets.bind(this)}
          cartSpecialism={cartSpecialism}
          cartSpecialismGroup={cartSpecialismGroup}
        />
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ProjectCartContext);
}
