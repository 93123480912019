import styled from "styled-components";

export const TitleBorder = styled.h2`
  color: #000;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 30px;
  text-align: left;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 75px;
    height: 4px;
    background: #000000;
  }
`;

export const TitleH3Border = styled.h3`
  font-style: normal;
  text-align: left;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #000000;

  position: relative;
  padding-bottom: 10px;
  margin-bottom: 30px;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 75px;
    height: 6px;
    background: #000000;
  }
`;

export const TitleH5Border = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: left;
  color: #2f2f2f;

  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 75px;
    height: 1px;
    background: #000000;
  }
`;
