import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";
import { I18n } from "aws-amplify";

import { notification } from "antd";

import Config from "../../config";

import { BP_PROJECT_UPDATE } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const api_generate_billing_nfs = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .put(
            `${Config.API_BASE_URL}/bp/projects/supplier-billings/export`,
            data,
            config
          )
          .then((response) => {
            notification.success({
              message: I18n.get("Sucesso!"),
              description: I18n.get(
                "Solicitação enviada com sucesso. Você irá receber a planilha em seu email"
              ),
              duration: 3,
            });

            // return payload;
          })
          .catch((error) => {
            notification.success({
              message: I18n.get("Algo deu errado"),
              description: I18n.get("favor tentar novamente"),
              duration: 3,
            });

            // return payload;
          });
      })
      .catch((error) => {
        dispatch({ type: EXPIRED });
      });
  };
};

export default api_generate_billing_nfs;
