import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { TitleMiddleLine } from '../../components/Titles';
import constants from "../../../constants";
import styled from 'styled-components';

import {
    Row,
    Col,
    Button,
    Checkbox,
    Input,
    Select,
    Form
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;


const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
      margin-right: 20px;
  }
`;

class FormExpertise extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formState: []
        }
    }

    sendDataApi = () => {
        const fieldsForm = this.props.form.getFieldsValue();
        const dataForm = {
            ...fieldsForm,
            partnerId: this.props.partnerId,
        }

        return dataForm;
    }


    handleSubmit = () => {
        this.props.action(this.sendDataApi());
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.setState({
                    formState: values,
                })

                let data = {
                    ...values,
                    type: this.props.type
                }

                if (this.props.isLeader) {
                    data.leaderId = this.props.partnerId;
                } else {
                    data.partnerId = this.props.partnerId;
                }


                if (this.props.item) {
                    data.id = this.props.item.id;
                    this.props.update(data);

                } else {
                    this.props.action(data);
                }
            }
        });

    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const { partner } = this.props;


        const ListCategorie = this.props.sectorsFlat && this.props.sectorsFlat.sort((a, b) => (a.label > b.label) - (a.label < b.label));

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row className="RowSection">
                    <Col sm={24}>
                        <Form.Item label={I18n.get("Categoria")}>
                            {getFieldDecorator('sectorId', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.sectorId ? this.props.item.sectorId : null
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {ListCategorie && ListCategorie.map((item) => {
                                        return <Option value={item.code} key={item.code}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="RowSection">
                    <Col sm={24}>
                        <Form.Item label={I18n.get("Marcas")}>
                            {getFieldDecorator('brands', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.brands ? this.props.item.brands : null
                            })(
                                <Input

                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="RowSection">
                    <Col sm={12}>
                        <Form.Item label={I18n.get("Anos")}>
                            {getFieldDecorator('years', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.years ? this.props.item.years : null
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                >
                                    {constants.YEARS && constants.YEARS.map((item) => {
                                        return <Option value={item.label} key={item.id}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={12}>
                        <Form.Item label={I18n.get("Meses")}>
                            {getFieldDecorator('months', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.months ? this.props.item.months : null
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                >
                                    {constants.MONTHS && constants.MONTHS.map((item) => {
                                        return <Option value={item.label} key={item.id}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>


                </Row>
                <RowSubmitModal>
                    <Col sm={24}>

                        <Button shape="round" onClick={() => this.props.cancel()}>{I18n.get("Cancelar")}</Button>

                        <Button type="primary" shape="round" htmlType="submit">
                            {this.props.item ? I18n.get("Atualizar") : I18n.get("Adicionar")}
                        </Button>
                    </Col>
                </RowSubmitModal>
            </Form>
        );
    }
}

const FormExpertiseForm = Form.create({})(FormExpertise);

export default FormExpertiseForm;
