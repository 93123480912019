import styled from "styled-components";

export const WrapperMain = styled.div`
  .logged-page {
    background: #fff !important;
  }
`;

export const DescriptionInfo = styled.div`
  margin: 50px 0 100px;
  h2,
  h3 {
    font-weight: bold;
  }

  h2 {
    margin-bottom: 20px;
  }

  section {
    border: solid 1px #e7e7e7;
    border-radius: 20px;
    overflow: hidden;

    div {
      padding: 30px;

      &.payment-box {
        background: #e7e7e7;
      }
    }

    .invoiceResume,
    .resume {
      padding: 0;
    }
  }
`;

export const WrapperForm = styled.div`
  display: flex;
  background: #f4f4f4;
  padding: 30px 30px 10px;
  border-radius: 15px;
  margin: 40px 0;

  .field {
    width: 250px;
    margin-right: 20px;
  }
`;
export const WrapperPage = styled.div`
  padding-top: 8px;
  min-height: 100%;
`;

export const SubHeader = styled.div`
  border-bottom: solid 1px #e7e7e7;
  padding: 7px 10px 10px;
  margin-bottom: 50px;
`;

export const WrapperTable = styled.div`
  border: solid 1px #e7e7e7;

  p {
    margin: 0 !important;
  }

  p:nth-child(odd) {
    background: #e7e7e7;
  }
`;

export const ItemList = styled.p`
  display: block;
  padding: 7px 10px 5px;
  margin: 0;

  .invoiceResume {
    padding: 20px;
    background: #fff;

    h3 {
      font-size: 16px;
    }

    .topResume {
      margin-bottom: 20px;
    }
    p {
      background: #fff;
    }
  }
`;
