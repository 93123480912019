import styled from "styled-components";

export const Wrapper = styled.div`
  height: 80px;
  label {
    font-size: 12px;
    font-weight: 700;
    color: #000000;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-top: 8px;
  }
`;
