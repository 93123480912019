import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import ErrorPane from '../../error-pane';

import { Task } from '../../../utils/task';

import { BP_API } from '../../../actions/bp/actions';

import SelectField from '../../form/select-field';
import SubmitButtonField from '../../form/submit-button-field';

const mapStateToProps = state => {
    return state.bp;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(BP_API.update_client(data));
        }
    };
};

class ClientEditForm extends React.Component {
    constructor(props) {

        const item = props.bp && props.bp.partner || {};
        const errors = item.errors || {};

        super();

        this.state = {
            form: {
                clientId: props.match.params.clientId,
                status: {
                    label: I18n.get("Status"),
                    value: item.status,
                    values: props.bp.clientStatuses,
                    onChange: this.onChangeForm.bind(this, 'status'),
                    successIcon: false,
                    className: 'field is-narrow'
                },
                global: {
                    error: errors.global
                }
            },
            task: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        if (task != prev) {
            this.setState({ task: task });
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData() {

        var data = {};

        Object.keys(this.state.form).forEach((item) => {

            if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
                data[item] = this.state.form[item].value
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function() {
            this.props.save(this.getData());
        });
    }

    render() {

        return (
            <div className="card">
                <div className="card-content">

                    <h3 className="title is-6">{I18n.get("Editar cliente")}</h3>

                    <form onSubmit={this.submitForm.bind(this)}>

                        <SelectField {...this.state.form.status} />

                        {this.state.task.errors && Object.keys(this.state.task.errors).length > 0 && <article className="message is-danger">
                            <div className="message-body">
                                <ul className="has-text-danger">
                                    {Object.keys(this.state.task.errors).map((item) =>
                                        <li>{this.state.task.errors[item].errorMessage} </li>
                                    )}
                                </ul>
                            </div>
                        </article>}

                        <div className="control">
                            <SubmitButtonField
                                label={I18n.get("Salvar")}
                                onClick={this.submitForm.bind(this)}
                                task={this.state.task}
                            />
                        </div>

                    </form>

                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientEditForm);
