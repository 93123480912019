import React from 'react';

import { I18n } from 'aws-amplify';

const Status = ({ item }) => {

    return (
        <div>

            {item.accepted === true && (
                <div className="">
                    <i className="fas fa-check"></i>{" "}{I18n.get("Você aceitou esse convite.")}

                    <br /><br />

                    <div>

                        <p style={{ fontWeight: 'bold' }}>
                            {I18n.get('Agora que você aceitou o convite, já pode começar a trabalhar na proposta!')}
                        </p>
                        <p style={{ marginTop: '0.50rem', fontSize: '90%' }}>
                            {I18n.get('Clique abaixo se quiser enviar perguntas ao cliente ou começe a estruturar suas proposta agora mesmo.')}
                        </p>

                    </div>

                </div>
            )}

            {item.accepted === false && (
                <div className="msg-accepted no-accepted">
                    <i className="fas fa-ban"></i>{" "}{I18n.get("Você recusou esse convite.")}</div>
            )}

            {item.expired === true && <span className="tag">{I18n.get("CONVITE EXPIRADO")}</span>}

        </div>
    );
}

export default Status;
