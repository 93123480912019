import React from 'react';

import { I18n } from 'aws-amplify';

class ReviewStep5 extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {

        return (
            <div>

                <div className="card">
                    <div className="card-content break-word">
                        {this.props.partnerName && <>
                            <p>
                                <strong>{I18n.get("Parceiro:")}</strong> {this.props.partnerName}
                            </p>
                            <p>
                                <strong>{I18n.get("Nome do Líder:")}</strong> {this.props.partnerResponsibleName}
                            </p>
                            <p>
                                <strong>{I18n.get("Contato:")}</strong> {this.props.partnerResponsiblePhoneNumber}
                            </p>
                            <p>
                                <strong>{I18n.get("E-mail:")}</strong> {this.props.partnerResponsibleEmail}
                            </p>
                        </>}
                    </div>
                </div>

            </div>
        );
    }
}

export default ReviewStep5;
