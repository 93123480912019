import React from "react";

import { I18n } from "aws-amplify";

import NumberFormat from "react-number-format";

import { Icon } from "antd";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import QuotationBudget from "./quotation-budget";
import QuotationSummaryForm from "./quotation-summary-form";
import QuotationGrandTotal from "./quotation-grandtotal";
import QuotationSchedule from "./quotation-schedule-form";
import QuotationUpload from "./quotation-upload";

import TextAreaField from "../form/textarea-field";
import SubmitButtonField from "../form/submit-button-field";

class QuotationForm extends React.Component {
  constructor(props) {
    super(props);

    const { quotation = {} } = props;

    this.state = {
      fields: {
        partnerNotes: {
          label: I18n.get("Comentários do Parceiro"),
          onChange: this.onChangeForm.bind(this, "partnerNotes"),
          successIcon: false,
          // onBlur: this.onSave.bind(this, 'partnerNotes'),
          visited: true,
        },
        clientNotes: {
          label: I18n.get("Comentários do Cliente"),
          onChange: this.onChangeForm.bind(this, "clientNotes"),
          successIcon: false,
          // onBlur: this.onSave.bind(this, 'clientNotes'),
          visited: true,
        },
      },
      form: {
        partnerNotes: quotation.partnerNotes,
        clientNotes: quotation.clientNotes,
        files: quotation.files,
      },
      task: {},
      errors: {},
      approve_task: {},
      submit_task: {},
      save_task: {},
    };

    this.summaryForm = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    // console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const quotation = this.props.quotation || {};

      Object.keys(this.state.fields).forEach((item) => {
        nextState.form[item] = quotation[item];
      });

      // atualiza os arquivos
      if (task.result) {
        nextState.form.files = this.props.files || [];
      }

      this.setState(nextState);
    }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      partnerId: this.props.quotation.id,
      ...this.state.form,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (FormUtils.isFormField(this.state.form[item])) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  onSave(item) {
    var save_task = new Task();

    save_task.start();

    this.setState({ save_task: save_task }, function () {
      this.props.updateEventQuotation(this.getData());
    });
  }

  submitForm(isSubmit, e) {
    e.preventDefault();

    const summaryData = this.summaryForm.current.getData();

    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task }, function () {
      const data = Object.assign({}, this.getData(), summaryData, {
        isSubmit: isSubmit,
      });

      this.props.updateEventQuotation(data).then(({ task, errors }) => {
        this.setState({ submit_task: task, errors: errors });
      });
    });
  }

  onApproval(isApproved, e) {
    e.preventDefault();

    var approve_task = new Task();

    approve_task.start();

    this.setState({ approve_task: approve_task });

    const data = Object.assign({}, this.getData(), { isApproved: isApproved });

    this.props.updateEventQuotationStatus(data).then(({ task, errors }) => {
      this.setState({ approve_task: task, errors: errors });
    });
  }

  render() {
    const quotation = this.props.quotation || {};

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    const canApprove =
      this.props.auth.is_client_or_client_user && quotation.requestApproval;

    return (
      <div>
        {this.props.auth.is_client_or_client_user &&
          quotation.isOpen == true &&
          quotation.revising && (
            <>
              <div
                style={{
                  paddingBottom: "3rem",
                  color: "#1883ff",
                  opacity: "0.7",
                }}
              >
                <Icon type="info-circle" />{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {I18n.get("Orçamento enviado para revisão.")}
                </span>{" "}
                <span>
                  {I18n.get(
                    "O parceiro foi notificado e poderá alterar o orçamento."
                  )}
                </span>
              </div>
            </>
          )}

        {this.props.auth.is_partner &&
          quotation.isOpen == true &&
          quotation.revising && (
            <>
              <div
                style={{
                  paddingBottom: "3rem",
                  color: "#1883ff",
                  opacity: "0.7",
                }}
              >
                <Icon type="info-circle" />{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {I18n.get("O Cliente solictou uma revisão do orçamento.")}
                </span>{" "}
                <span>
                  {I18n.get(
                    "Veja no campo de comentários as observações do cliente."
                  )}
                </span>
              </div>
            </>
          )}

        <div className="columns">
          <div className="column is-12">
            <QuotationBudget {...this.props} quotation={quotation} />
          </div>
        </div>

        <br />
        <br />

        <div className="columns">
          <div className="column is-7">
            <QuotationUpload {...this.props} quotation={quotation} />
          </div>
        </div>

        <br />
        <br />

        <div className="columns">
          <div className="column is-12">
            <QuotationSummaryForm
              ref={this.summaryForm}
              {...this.props}
              quotation={quotation}
              errors={this.state.errors}
            />
          </div>
        </div>

        <br />
        <br />

        <div className="columns">
          <div className="column is-12">
            <QuotationGrandTotal {...this.props} quotation={quotation} />
          </div>
        </div>

        <br />
        <br />

        <div className="columns">
          <div className="column is-7">
            {!this.props.suppressSchedule && (
              <QuotationSchedule {...this.props} quotation={quotation} />
            )}
          </div>
        </div>

        <br />
        <br />

        <div className="columns">
          <div className="column is-7">
            <p className="title is-5">{I18n.get("Espaço para Comentários")}</p>

            <br />

            <TextAreaField
              {...this.state.fields.partnerNotes}
              value={this.state.form.partnerNotes}
              error={this.state.errors.partnerNotes}
              readOnly={!canEdit}
            />

            <TextAreaField
              {...this.state.fields.clientNotes}
              value={this.state.form.clientNotes}
              error={this.state.errors.clientNotes}
              readOnly={!canApprove}
            />

            <p className="has-text-danger" style={{ fontSize: "90%" }}>
              <ul>
                {Object.keys(this.state.errors).map((item) => {
                  if (!this.state.errors[item]) return;

                  return <li>{this.state.errors[item].errorMessage}</li>;
                })}
              </ul>
            </p>
          </div>
        </div>

        {canEdit && (
          <>
            <div className="columns">
              <div className="column is-8">
                <div style={{ display: "inline-block" }}>
                  <SubmitButtonField
                    label={I18n.get("Salvar e editar depois")}
                    className="button bt-bpool black"
                    task={this.state.submit_task}
                    onClick={this.submitForm.bind(this, false)}
                  />
                </div>

                <div style={{ display: "inline-block", marginLeft: "2rem" }}>
                  <SubmitButtonField
                    label={I18n.get("Enviar para cliente")}
                    className="button bt-bpool "
                    task={this.state.submit_task}
                    onClick={this.submitForm.bind(this, true)}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <br />

        {this.props.auth.is_partner && quotation.submittedOn && (
          <>
            <p>
              <strong>
                {I18n.get("Obrigado! Proposta enviada em")}{" "}
                {quotation.submittedOn}
              </strong>
              .
              {quotation.isApproved == null && (
                <span>
                  {" "}
                  {I18n.get(
                    "O cliente foi notificado e poderá aprovar ou declinar o orçamento."
                  )}
                </span>
              )}
            </p>
          </>
        )}

        {this.props.auth.is_client_or_client_user &&
          quotation.isOpen == true &&
          quotation.revising && (
            <>
              <div
                style={{
                  paddingBottom: "3rem",
                  color: "#1883ff",
                  opacity: "0.7",
                }}
              >
                <Icon type="info-circle" />{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {I18n.get("Orçamento enviado para revisão.")}
                </span>{" "}
                <span>
                  {I18n.get(
                    "O parceiro foi notificado e poderá alterar o orçamento."
                  )}
                </span>
              </div>
            </>
          )}

        {canApprove && (
          <>
            <div>
              {this.props.selectPartnerButton && (
                <>
                  <div style={{ display: "inline-block", marginRight: "2rem" }}>
                    {this.props.selectPartnerButton}
                  </div>
                </>
              )}

              <div style={{ display: "inline-block" }}>
                <SubmitButtonField
                  label={I18n.get("Solicitar Revisão")}
                  className="button bt-bpool black"
                  task={this.state.approve_task}
                  onClick={this.onApproval.bind(this, false)}
                />
              </div>
            </div>
          </>
        )}

        <br />
        <br />
      </div>
    );
  }
}

export default QuotationForm;
