import React from 'react';
import { I18n } from 'aws-amplify';

import { CustomEventBriefingType } from '../../model';

export const evtMenu = [
    'Briefing',
    'Sobre o evento',
    'Data e horário',
    'Público',
    'Localização',
    'Estrutura',
    'A & B',
    'Logística',
    'Equipe',
    'Atrações',
    'Comunicação / Brindes',
    'Budget',
    'Resultados',
    'Prazo para proposta',
    'Upload de arquivos'
];

export const mktMenu = [
    'Briefing',
    'Sobre o projeto',
    'Data e horário',
    'Público',
    'Localização',
    'Produto',
    'A & B',
    'Logística',
    'Equipe',
    'Atrações',
    'Comunicação / Brindes',
    'Budget',
    'Resultados',
    'Prazo para proposta',
    'Upload de arquivos'
];

export const dgtMenu = [
    'Briefing',
    'Sobre o evento',
    'Data e horário',
    'Público',
    'Plataforma',
    'Estrutura',
    'Atrações',
    'Comunicação / Brindes',
    'Budget',
    'Resultados',
    'Prazo para proposta',
    'Upload de arquivos'
];

export const comMenu = [
    'Briefing',
    'Budget',
    'Resultados',
    'Prazo para proposta',
    'Upload de arquivos'
];

export const promoMenu = [
    'Briefing',
    'Budget',
    'Prazo para proposta',
    'Upload de arquivos'
];


export const eventMenuMap = {
    [CustomEventBriefingType.Event]: evtMenu,
    [CustomEventBriefingType.Shopper]: mktMenu,
    [CustomEventBriefingType.Incentive]: mktMenu,
    [CustomEventBriefingType.Digital]: dgtMenu,
    [CustomEventBriefingType.Communication]: comMenu,
    [CustomEventBriefingType.Promotional]: promoMenu,
};