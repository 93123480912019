import styled, { css } from "styled-components";

const NotificationColorModifier = {
  success: () => css`
    border-color: #15b467;
    background-color: #efffe5;
  `,
  warning: () => css`
    border-color: #e8b51f;
    background-color: #fff9e5;
  `,
  error: () => css`
    border-color: #9f0f0f;
    background-color: #ffe5e5;
  `,
};

export const Notification = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  border: 1px solid;

  p {
    position: relative;
    top: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    line-height: 20px;
    margin-left: 16px;
    display: flex;
    align-items: center;
  }

  ${(props) =>
    props.type
      ? NotificationColorModifier[props.type]
      : NotificationColorModifier["success"]}
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;

  width: 24px;
  height: 24px;

  img {
    width: 24px;
    height: 24px;
  }
`;
