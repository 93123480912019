import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';
import { notification } from 'antd';
import { I18n } from 'aws-amplify';


import Config from '../../config';

import { BP_ADMIN_STATUS_PROJECT } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_refresh_stats = (id) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/bp/admin/partner-stats-refresh/${id}`, config)
                .then(response => {
                    dispatch({
                        type: BP_ADMIN_STATUS_PROJECT,
                        payload: {
                            // clientsAdd: response.data,
                            task: {
                                result: true
                            }
                        }
                    });

                    notification.success({
                        message: I18n.get('Atualizado com sucesso!'),
                        description: ""
                      });

                    return response;
                })
                .catch(error => {

                    notification.error({
                        message: I18n.get('Ops, algo deu errado!'),
                        description: I18n.get("Tente novamente mais tarde")
                      });

                      
                      return error;
                      throw (error);
                });

        }).catch(error => {

            // dispatch({ type: EXPIRED });
        });
    };
}

export default api_refresh_stats;
