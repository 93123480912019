import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { getPartnersById as getPartnerByIdState } from "../../../../../store/actions/partners";
import { usePartners } from "../../../../../hooks/usePartners/usePartners.hook";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import { useRegistrationFlow } from "../../../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";
import { useCertificates } from "../../../../../hooks/useCertificates/useCertificates.hook";
import { Row, Col, Icon, Tooltip } from "antd";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import Content from "../../../../../components/UI/Content";
import { H2Border } from "../../../../../components/UI/Titles";
import Main from "../../components/Main";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import Loading from "../../../../../components/UI/Loading";
import { Avatar } from "../components/Avatar";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import ReactPlayer from "react-player";
import starIcon from "../imgs/star.png";
import arrowDown from "../imgs/arrow-down.svg";
import tooltipIcon from "./tooltip-diversity.svg";

import { ListCertificates } from "../../../../RegistrationFlow/Full/Resume/listCertificates";

import { ItemWithTitle } from "./itemWithTitle";
import { ItemCarousel } from "./itemCarousel";
import { SosRS } from "./SosRS";

import * as S from "./styles";

import bannerPartner from "../mock/imgs/banner-partner.png";
import avatar from "../mock/imgs/avatar.png";

export const KnowMorePartner = () => {
  let params = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  const { getPartnerById } = usePartners();
  const { getProjectById } = useProjects();
  const { getDiversityAndInclusionSetup } = useRegistrationFlow();
  const { getCertificatesPartnerViewClient } = useCertificates();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [visibleAllClients, setVisibleAllClients] = useState(false);
  const [visibleAllLeaders, setVisibleAllLeaders] = useState(false);
  const [project, setProject] = useState("");
  const [dataCases, setDataCases] = useState({});
  const [dataAwards, setDataAwards] = useState({});
  const [dataPage, setDataPage] = useState({});
  const [dataDiversity, setDataDiversity] = useState([]);
  const [isLoadingCertificates, setIsLoadingCertificates] = useState(false);
  const [dataCertificates, setDataCertificates] = useState([]);

  const { partnerById } = useSelector((state) => state.partners);

  const { height, width } = useWindowDimensions();

  const itemsToShow = () => {
    if (width <= 992) {
      return 1;
    } else if (width <= 1200) {
      return 2;
    } else {
      return 2;
    }
  };

  const outerSpacing = () => {
    if (width <= 425) {
      return 10;
    } else if (width <= 550) {
      return width / 8;
    } else if (width <= 767) {
      return width / 6;
    } else if (width <= 992) {
      return width / 4;
    } else {
      return 100;
    }
  };

  const organizeArr = (arr) => {
    const arrMap = arr?.map((item, index) => {
      if (item?.links?.length > 0) {
        return {
          type: "link",
          url: item?.links[0],
        };
      }

      if (item?.videos?.length > 0) {
        return {
          type: "video",
          url: item?.videos[0],
        };
      }

      if (item?.images?.length > 0) {
        return {
          type: "image",
          url: item?.images[0],
        };
      }

      if (item?.photos?.length > 0) {
        return {
          type: "image",
          url: item?.photos[0],
        };
      }
    });

    const items = arrMap?.filter((item) => item);

    return items;
  };

  const itemsShowToWidth = {
    2: "50%",
    3: "33.333333%",
  };

  const countItemsShowWIdth = (item) => {
    const width = itemsShowToWidth[item];
    return width;
  };

  const prepareDescriptions = (description) => {
    const desc = description.split("LED:");
    const descOwned = desc[0].split("OWNED:");

    return { descriptionOwned: descOwned[1], descriptionLed: desc[1] };
  };

  const renderItemDiversity = (itemDiversity) => {
    const arrString = [];
    if (itemDiversity?.selectedLeadership) {
      if (itemDiversity?.childrenLeadership?.length) {
        const arrStringChildren = itemDiversity.childrenLeadership
          .filter((item) => item?.selected)
          .map((item) => item.label);
        if (arrStringChildren.length > 0) {
          arrString.push(`Liderado - ${arrStringChildren.join(", ")}`);
        } else {
          arrString.push("Liderado");
        }
      } else {
        arrString.push("Liderado");
      }
    }
    if (itemDiversity?.selectedOwned) {
      if (itemDiversity?.childrenOwned?.length) {
        const arrStringChildren = itemDiversity.childrenOwned
          .filter((item) => item?.selected)
          .map((item) => item.label);
        if (arrStringChildren.length > 0) {
          arrString.push(`Controlado - ${arrStringChildren.join(", ")}`);
        } else {
          arrString.push("Controlado");
        }
      } else {
        arrString.push("Controlado");
      }
    }
    return arrString.length > 0 ? arrString.join(", ") : "";
  };

  const loadPartnerByIdAndProject = async () => {
    try {
      setIsLoading(true);
      const response = await getPartnerById({
        projectId: params?.projectId,
        partnerId: params?.partnerId,
      });

      if (response.success) {
        setDataPage(response.data);
        const cases = [];
        response.data.specialityProofsEndorsement.map(
          (item) =>
            item?.cases?.length &&
            item.cases.map((itemCase) => cases.push(itemCase))
        );
        setDataCases(cases);
        const awards = [];
        response.data.awards.map((itemAward) => awards.push(itemAward));
        setDataAwards(awards);

        const responseSetup = await getDiversityAndInclusionSetup({
          admin: false,
        });
        const childrensArr = [];

        if (responseSetup.success) {
          const dataFormatted = responseSetup.data.map((item) => ({
            code: item.code,
            label: item.label,
            ...prepareDescriptions(item.description),
            selectedLeadership:
              response?.data?.company?.diversityLeadership?.find(
                (itemDiversity) => itemDiversity === item.code
              )
                ? true
                : false,
            selectedOwned: response?.data?.company?.diversityOwned?.find(
              (itemDiversity) => itemDiversity === item.code
            )
              ? true
              : false,
            childrenLeadership: item.children
              ? item.children.map((childItem) => ({
                  label: childItem.label,
                  code: childItem.code,
                  selected: response?.data?.company?.diversityLeadership?.find(
                    (item) => item === childItem.code
                  )
                    ? true
                    : false,
                }))
              : null,
            childrenOwned: item.children
              ? item.children.map((childItem) => ({
                  label: childItem.label,
                  code: childItem.code,
                  selected: response?.data?.company?.diversityOwned?.find(
                    (item) => item === childItem.code
                  )
                    ? true
                    : false,
                }))
              : null,
          }));
          setDataDiversity(dataFormatted);

          responseSetup.data.map((item) => ({
            children: item.children
              ? item.children.map((childItem) =>
                  childrensArr.push({
                    label: childItem.label,
                    code: childItem.code,
                  })
                )
              : null,
          }));
        }
      }

      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });

      setProject(responseProject?.data);

      if (response?.success && responseProject?.success) {
        const awards = organizeArr(response?.data?.awards);

        const partnerAndProject = {
          ...response?.data,
          awards,
          project: responseProject?.data,
        };
        dispatch(getPartnerByIdState(partnerAndProject));
      } else {
        dispatch(getPartnerByIdState({}));
        setError("Error.");
      }
      setIsLoading(false);
    } catch (error) {
      dispatch(getPartnerByIdState({}));
      setError("Error.");
    }
  };

  const verifiyIfDiversityVisible = (arr) => {
    return arr?.some((item) => item?.selectedLeadership || item?.selectedOwned);
  };

  const loadCertificates = async (partnerId) => {
    try {
      setIsLoadingCertificates(true);
      const response = await getCertificatesPartnerViewClient(partnerId);
      setDataCertificates(response?.data);
    } catch (error) {
      console.log("error");
    } finally {
      setIsLoadingCertificates(false);
    }
  };

  const partnerWinner = async () => {
    const { projectId, partnerId } = params;

    history.push(
      `/cliente/projetos/v2/selecionar-parceiro/${projectId}/passo-2/${partnerId}`
    );
  };

  const checkVideoService = (url) => {
    // Expressão regular para verificar se a URL é do YouTube
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)/;

    // Expressão regular para verificar se a URL é do Vimeo
    const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)/;

    if (url) {
      if (url.match(youtubeRegex)) {
        return "video";
      } else if (url.match(vimeoRegex)) {
        return "video";
      } else {
        return "no-video";
      }
    } else {
      return "no-video";
    }
  };

  const checkImage = (url) => {
    if (
      url.toLowerCase().indexOf(".jpeg") !== -1 ||
      url.toLowerCase().indexOf(".jpg") !== -1 ||
      url.toLowerCase().indexOf(".png") !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const imageBox = {
    objectFit: "cover",
    width: "300px",
    height: "156px",
  };

  const renderCase = (item) => {
    const checkLink = checkVideoService(item?.link);
    const checkImageUrl = item?.link && checkImage(item?.link);
    const linkImage = item?.linkImage;

    return (
      <div>
        {checkLink === "video" ? (
          <ReactPlayer
            // className="responsive-video"
            url={item.link}
            playing={true}
            loop={true}
            controls={false}
            muted={true}
            width="auto"
            height="auto"
            style={{ margin: "0 auto" }}
          />
        ) : (
          <>
            {linkImage ? (
              <a href={item.link} target="_blank">
                <img src={item.linkImage} alt="" style={imageBox} />
              </a>
            ) : checkImageUrl ? (
              <a href={item.link} target="_blank">
                <img src={item.link} alt="" style={imageBox} />
              </a>
            ) : (
              <a href={item.link} target="_blank">
                <S.ImageEmpty>
                  <Icon type="link" style={{ color: "#000" }} />
                </S.ImageEmpty>
              </a>
            )}
          </>
        )}
        <S.InfosItemCase>
          <div>
            {I18n.get("Name")}: {item?.name}
          </div>
          <div>
            {I18n.get("Date")}: {item?.year}
          </div>
          <div>
            {I18n.get("Brand")}: {item?.brand}
          </div>
        </S.InfosItemCase>
      </div>
    );
  };

  const renderAward = (item) => {
    const checkLink = checkVideoService(item?.link);
    const checkImageUrl = item?.link && checkImage(item?.link);

    return (
      <div>
        {checkLink === "video" ? (
          <ReactPlayer
            // className="responsive-video"
            url={item.link}
            playing={true}
            loop={true}
            controls={false}
            muted={true}
            width="auto"
            height="auto"
            style={{ margin: "0 auto" }}
          />
        ) : (
          <>
            {checkImageUrl ? (
              <a href={item.link} target="_blank">
                <img src={item.link} alt="" />
              </a>
            ) : (
              <a href={item.link} target="_blank">
                <S.ImageEmpty>
                  <Icon type="link" style={{ color: "#000" }} />
                </S.ImageEmpty>
              </a>
            )}
          </>
        )}
        <S.InfosItemCase>
          <div>
            {I18n.get("Name")}: {item?.name}
          </div>
          <div>
            {I18n.get("Year")}: {item?.year}
          </div>
          <div>
            {I18n.get("Participants")}: {item?.participantsUsersEmail?.length}
          </div>
        </S.InfosItemCase>
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    loadPartnerByIdAndProject();
    if (partnerById?.id !== params?.partnerId) {
      loadPartnerByIdAndProject();
      // loadCertificates(params?.partnerId);
    }
  }, []);

  const visibleGlobal = project?.productRequiredGlobalAuthorization;
  const globalAuthorization = project?.globalAuthorization;

  const disabledSelected = visibleGlobal && !globalAuthorization;

  return (
    <>
      <LoggedPage>
        <Content>
          <div className="container">
            <Row>
              <Col sm={24}>
                <H2Border>{I18n.get("Profile View")}</H2Border>
              </Col>
            </Row>
            {isLoading ? (
              <Row>
                <Col sm={24}>
                  <Main bgColor="#fff" padding="30px">
                    <Row>
                      <Col sm={24}>
                        <Loading
                          text={I18n.get("Loading...")}
                          sizeText={14}
                          sizeIcon={16}
                          align="start"
                        />
                      </Col>
                    </Row>
                  </Main>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col sm={24}>
                  <Main bgColor="#fff" padding="30px">
                    <Row>
                      <Col sm={24}>
                        <S.BannerPartner>
                          <img alt="banner partner" src={bannerPartner} />

                          <S.LogoWrapper>
                            <S.LogoPartner>
                              <img
                                alt="banner partner"
                                src={partnerById?.company?.logoImage}
                              />
                            </S.LogoPartner>
                          </S.LogoWrapper>
                        </S.BannerPartner>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24}>
                        <H2Border>{partnerById?.company?.name}</H2Border>
                      </Col>

                      <Col xs={24}>
                        <SosRS
                          visible={
                            dataPage?.company?.state === "RS" ||
                            dataPage?.company?.state === "commercialState"
                              ? true
                              : false
                          }
                        />
                      </Col>

                      <Col xs={24}>
                        <S.Subtitle>{I18n.get("Sobre")}</S.Subtitle>
                        <S.P>{partnerById?.company?.description}</S.P>
                      </Col>
                      <Col xs={24} style={{ marginTop: 30 }}>
                        <ItemWithTitle title={I18n.get("Specialities")}>
                          {dataPage?.businessTypesDescription?.length ? (
                            <S.Specialities>
                              {dataPage?.businessTypesDescription?.map(
                                (type, index) => (
                                  <li key={`${index}-${type}`}>
                                    <img src={starIcon} />
                                    <span>{type}</span>
                                  </li>
                                )
                              )}
                            </S.Specialities>
                          ) : (
                            <S.EmptyItem>
                              {I18n.get("No informations.")}
                            </S.EmptyItem>
                          )}
                        </ItemWithTitle>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} style={{ marginTop: 60 }}>
                        <S.SubtitleWithButton>
                          <S.Subtitle style={{ marginBottom: 10 }}>
                            {I18n.get("Clientes")}
                          </S.Subtitle>
                          <button
                            onClick={() =>
                              setVisibleAllClients(
                                visibleAllClients ? false : true
                              )
                            }
                          >
                            <img
                              alt="arrow down icon"
                              src={arrowDown}
                              style={{
                                transform: visibleAllClients
                                  ? "rotate(181deg)"
                                  : "",
                              }}
                            />
                          </button>
                        </S.SubtitleWithButton>
                        <S.Tags
                          style={{ height: visibleAllClients ? "auto" : 120 }}
                        >
                          {partnerById?.clients?.map((item, index) => (
                            <li key={index}>{item?.name}</li>
                          ))}
                        </S.Tags>
                      </Col>
                      {verifiyIfDiversityVisible(dataDiversity) ? (
                        <Col xs={24} style={{ marginTop: 60 }}>
                          <ItemWithTitle
                            title={I18n.get("Diversidade & Inclusão")}
                          >
                            <p>
                              {I18n.get(
                                "Essa empresa trabalha ativamente para a igualdade de gênero no local de trabalho."
                              )}
                            </p>
                            <p>
                              {I18n.get(
                                "Essa é a composição da sociedade e liderança da empresa."
                              )}
                            </p>
                            <div>
                              {dataDiversity?.length
                                ? dataDiversity?.map((item) =>
                                    item?.selectedLeadership ||
                                    item?.selectedOwned ? (
                                      <S.ItemDiversityView key={item?.code}>
                                        <strong>{item?.label}:</strong>
                                        <span style={{ marginLeft: 8 }}>
                                          {renderItemDiversity(item)}
                                        </span>
                                        <Tooltip
                                          placement="top"
                                          overlayClassName="tooltip-diversity"
                                          title={
                                            <div>
                                              {item?.selectedLeadership ? (
                                                <>
                                                  <p
                                                    style={{ marginBottom: 8 }}
                                                  >
                                                    <strong>
                                                      {I18n.get("Liderado")}
                                                    </strong>
                                                  </p>
                                                  <p>{item?.descriptionLed}</p>
                                                </>
                                              ) : null}
                                              {item?.selectedOwned ? (
                                                <>
                                                  <p
                                                    style={{
                                                      marginTop:
                                                        item?.selectedLeadership &&
                                                        item?.selectedOwned
                                                          ? 32
                                                          : 0,
                                                      marginBottom: 8,
                                                    }}
                                                  >
                                                    <strong>
                                                      {I18n.get("Controlado")}
                                                    </strong>
                                                  </p>
                                                  <p>
                                                    {item?.descriptionOwned}
                                                  </p>
                                                </>
                                              ) : null}
                                            </div>
                                          }
                                        >
                                          <img src={tooltipIcon} />
                                        </Tooltip>
                                      </S.ItemDiversityView>
                                    ) : null
                                  )
                                : null}
                            </div>
                          </ItemWithTitle>
                        </Col>
                      ) : null}
                      {dataCertificates?.length ? (
                        <Col xs={24} style={{ marginTop: 60 }}>
                          <ItemWithTitle
                            title={I18n.get("Certificados Técnicos")}
                          >
                            <div>
                              <ListCertificates
                                data={dataCertificates}
                                isLoading={isLoadingCertificates}
                              />
                            </div>
                          </ItemWithTitle>
                        </Col>
                      ) : null}
                      <Col xs={24} style={{ marginTop: 60 }}>
                        <ItemWithTitle
                          title={I18n.get("Meet the leaders")}
                          titleExpand
                        >
                          {dataPage?.users?.length ? (
                            <ItemCarousel id="leaders">
                              {dataPage?.users?.map((item, index) => (
                                <S.LeaderItem
                                  key={index}
                                  maxItemsShow={countItemsShowWIdth(2)}
                                  id={`slide-${index + 1}`}
                                >
                                  <S.LeaderProfilePicture>
                                    {item?.profilePicture ? (
                                      <img
                                        src={item?.profilePicture}
                                        alt={`${item.name} photo`}
                                      />
                                    ) : (
                                      <img
                                        src="/img/user-img-empty.jpg"
                                        alt={`${item.name} no photo`}
                                      />
                                    )}
                                  </S.LeaderProfilePicture>
                                  <S.LeaderItemInfos>
                                    <strong>{item.name}</strong>
                                    <p>{item.userProfileDescription}</p>
                                    <p className="leader-mail">{item.email}</p>
                                  </S.LeaderItemInfos>
                                </S.LeaderItem>
                              ))}
                            </ItemCarousel>
                          ) : (
                            <S.EmptyItem>
                              {I18n.get("No informations.")}
                            </S.EmptyItem>
                          )}
                        </ItemWithTitle>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} style={{ marginTop: 30 }}>
                        <ItemWithTitle title={I18n.get("Cases")}>
                          {dataCases?.length ? (
                            <ItemCarousel id="cases">
                              {dataCases?.map((item, index) => (
                                <S.CaseItem key={`${index}-${item.brand}`}>
                                  {renderCase(item)}
                                </S.CaseItem>
                              ))}
                            </ItemCarousel>
                          ) : (
                            <S.EmptyItem>
                              {I18n.get("No informations.")}
                            </S.EmptyItem>
                          )}
                        </ItemWithTitle>
                      </Col>

                      <Col xs={24} style={{ marginTop: 30 }}>
                        <ItemWithTitle title={I18n.get("Awards")}>
                          {dataAwards?.length ? (
                            <ItemCarousel id="awards">
                              {dataAwards?.map((item, index) => (
                                <S.CaseItem key={`${index}-${item.brand}`}>
                                  {renderAward(item)}
                                </S.CaseItem>
                              ))}
                            </ItemCarousel>
                          ) : (
                            <S.EmptyItem>
                              {I18n.get("No informations.")}
                            </S.EmptyItem>
                          )}
                        </ItemWithTitle>
                      </Col>
                    </Row>

                    <Row>
                      <div style={{ margin: "50px 0" }} />
                    </Row>

                    <Row>
                      <Col lg={24}>
                        <S.ActionButton>
                          <ButtonBpool
                            text={I18n.get("Quero este parceiro")}
                            theme="primary"
                            onClick={partnerWinner}
                          />
                        </S.ActionButton>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={24}>
                        <S.FooterButtons className="buttonsContainer">
                          <Link
                            to={`/cliente/projetos/v2/selecionar-parceiro/${params?.projectId}/passo-1`}
                          >
                            <ButtonBpool
                              text={I18n.get("Voltar")}
                              theme="secondary"
                              onClick={() =>
                                history.push(
                                  `/cliente/projetos/v2/selecionar-parceiro/${params?.projectId}/passo-1`
                                )
                              }
                            />
                          </Link>
                        </S.FooterButtons>
                      </Col>
                    </Row>
                  </Main>
                </Col>
              </Row>
            )}
          </div>
        </Content>
      </LoggedPage>
    </>
  );
};
