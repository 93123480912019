import React, { PureComponent, Fragment } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";

import NumberFormat from "react-number-format";

import {
  formatMoney2,
  formatMoneyInput,
  formatMoneyGraph,
} from "../../../utils/helpers";
import { Table, Popconfirm, Tooltip, Icon } from "antd";

const Btns = styled.p`
  width: 50px;
  displa: block;
  a {
    margin-left: 10px;
  }
`;

const FooterT = styled.div`
  font-weight: bold;
  text-align: right;
`;

class TableSupplier extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSector = (sectorId) => {
    const items =
      this.props.sectorsFlat &&
      this.props.sectorsFlat.filter((item) => item.code == sectorId);

    if (items && items.length) {
      return items[0].label;
    } else {
      return {};
    }
  };

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.partnerStatusesMap) return "";

    return this.props.bp.partnerStatusesMap[value].label;
  }

  getRegion(region) {
    if (region === "en") return "USA";
    else if (region === "es") return "LATAM";
    else return "BRASIL";
  }

  render() {
    const columns = [
      {
        title: I18n.get("Região"),
        dataIndex: "region",
        width: 110,
        key: "region",
      },
     
      {
        title: I18n.get("Nome"),
        dataIndex: "name",
        width: 400,
        key: "name",
        render: (name, record) => {
          return (
            <Link>
              {record.item.name} 
             
            </Link>
          );
        },
      },
      {
        title: I18n.get("E-mail"),
        dataIndex: "emailPrimary",
        width: 280,
        key: "emailPrimary",
        render: (name, record) => {
          return (
            <span>
               {record.item.emailPrimary || "---"}
             
            </span>
          );
        },
      },
      // {
      //   title: I18n.get("Cadastro"),
      //   dataIndex: "date",
      //   width: 120,
      //   key: "date",
      // },
      {
        title: I18n.get("Status"),
        dataIndex: "status",
        width: 220,
        key: "status",
      },
      {
        title: I18n.get("Link"),
        dataIndex: "link",
        key: "link",
        render: (link, record) => {
          return (
            <>
              {record.item.status == 2 ? record.item.statusName : link}
            </> 
          );
        },
      },
     
    ];

    let dataTable =
      this.props.data &&
      this.props.data.map((item, index) => {
        let specs = item.specialisms || [];
        let hasLiveMkt = specs.includes("LIVE_MKT");

        // console.log(item.signupLocale)

        return {
          key: index,
          region: this.getRegion(item.signupLocale),
          country:
            item.company &&
            item.company.country &&
            item.company.country.isO2 &&
            item.company.country.isO2.toLowerCase(),
          date: item.createdOnFmt,
          tMarket: item.company?.tMarket,
          responsible: item.name,
          state: item.company && item.company.state,
          registrationName: item.company && item.company.registrationName,
          city: item.company && item.company.city,
          name: item.company && item.company.name,
          status: item.statusName,
          link: item.link,
          item: item,
        };
      });

    return (
      <Table
        pagination={this.props.pagination ? true : false}
        columns={columns}
        dataSource={dataTable}
        locale={{ emptyText: I18n.get("Sem informação") }}
        scroll={{ x: 1200 }}
      />
    );
  }
}

export default TableSupplier;
