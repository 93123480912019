import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 15px 0 60px 0;

  .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff;

    ::after {
      background-color: #1890ff;
    }
  }
`;
