import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import constants from '../../../constants';
import { Link, Redirect } from 'react-router-dom';
import AppWrapperSimple from '../../components/AppWrapperRegister';
import ContainerPage from '../../components/ContainerPage';
import SubHeader from '../../components/SubHeader';

import FormCourse from '../components/formCourse';

import { PARTNER_API } from '../../../actions/partner/actions';

import FormSpecialisms from '../components/formSpecialisms';

import { TitleBorder, TitleMiddleLine } from '../../components/Titles';

import TableCourse from '../components/tableCourse';

import styled from 'styled-components';

import FormSkeleton from "../../components/FormSkeleton";

import { PartnerStatus } from '../../../model';


import {
    Row,
    Col,
    Input,
    Select,
    Drawer,
    notification,
    Button
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
    padding-top: 50px;
    padding-bottom: 90px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    .RowSection {
        margin-bottom: 30px;
    }
`
const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(PARTNER_API.update_specialism(data));
        },
        new_company: data => {
            dispatch(PARTNER_API.new_company(data));
        },
        create_course: data => {
            dispatch(PARTNER_API.create_course(data));
        },
        update_course: data => {
            dispatch(PARTNER_API.update_course(data));
        },
        delete_course: data => {
            dispatch(PARTNER_API.delete_course(data));
        },
    };
};

class FullSpecialisms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: {},
            edit: false,
            show: false
        }
    }

    hideCourse = () => {
        this.setState({
            selectedItem: {},
            edit: false,
            show: false
        });
    }

    showAddCourse = () => {
        this.setState({
            selectedItem: {},
            edit: false,
            show: true
        });
    }

    showEditCourse = (item) => {
        this.setState({
            selectedItem: item,
            edit: true,
            show: true
        });
    }

    updateCourse = (data) => {
        this.props.update_course(data);
    }

    addCourse = (data) => {
        this.props.create_course(data);
    }

    handleEditCourse = (item) => {
        setTimeout(() => {
            this.showEditCourse(item);
        }, 500);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partner.specCoursesTraining && prevProps.partner.specCoursesTraining && this.props.partner.specCoursesTraining.length > prevProps.partner.specCoursesTraining.length) {

            notification.success({
                message: I18n.get("Adicionado com sucesso!"),
                description: I18n.get('O curso foi adicionado ao seu cadastro'),
                duration: 3,
            });

            this.hideCourse();
        }

        if (this.props.partner.specCoursesTraining && prevProps.partner.specCoursesTraining && this.props.partner.specCoursesTraining.length < prevProps.partner.specCoursesTraining.length) {

            notification.success({
                message: I18n.get("Removido com sucesso!"),
                description: I18n.get('O curso foi removido do seu cadastro'),
                duration: 3,
            });
        }

        if (this.props.partner.specCoursesTraining && prevProps.partner.specCoursesTraining && this.props.partner.specCoursesTraining != prevProps.partner.specCoursesTraining && Object.keys(this.state.selectedItem).length > 0) {

            notification.success({
                message: I18n.get("Atualizado com sucesso!"),
                description: I18n.get('O curso foi atualizado'),
                duration: 3,
            });

            this.setState({
                selectedItem: null
            })

            this.hideCourse();
        }
    }


    render() {

        if (this.props.partner && this.props.partner.status == PartnerStatus.Approved) {
            return (<Redirect to="/parceiro/cadastro/sucesso" />);
        }

        //TODO: Find better way... GAMBI!
        let menuRegister = [...constants.MENU_REGISTER];
        for (let key in menuRegister) {
            menuRegister[key].label = I18n.get(menuRegister[key].label);
        }

        return (
            <AppWrapperSimple>
                <SubHeader
                    titlePage=""
                    itensMenu={menuRegister}
                    urlId={this.props.match.params.partnerId}
                    activeItem={3}
                />
                <ContainerPage>
                    {!this.props.partner &&
                        <FormSkeleton />
                    }
                    {this.props.partner &&
                        <Main>
                            <Row>
                                <Col sm={24}>
                                    <TitleBorder>{I18n.get("Cadastro - Especialidades")}</TitleBorder>
                                    <p className="descriptionPage">
                                        {I18n.get("Se a sua empresa é especialista em algum segmento ou tipo de trabalho, por favor conte pra gente. Além de fazer curadoria de talentos, nosso papel é trazer os projetos certos para os times certos.")}
                                    </p>
                                </Col>
                            </Row>

                            <FormSpecialisms
                                partner={this.props.partner && this.props.partner}
                                partnerId={this.props.match.params.partnerId}
                                action={this.props.save}
                                isNewCompany={this.props.new_company}
                            />

                            <Row className="RowSection">
                                <Col sm={8}>
                                </Col>
                            </Row>


                            {this.props.partner.specialisms && this.props.partner.specialisms.indexOf("TRE_COACH") > -1 &&

                                <Row className="">
                                    <Col sm={24}>
                                        <TitleMiddleLine>{I18n.get("Opções para cursos")}</TitleMiddleLine>

                                        <p>{I18n.get("Você selecionou Treinamento & Coaching como uma das tuas especialidades. Conte então um pouco dos teus cursos e metodologias.")}</p>
                                    </Col>

                                    <Col sm={24}>
                                        <p className="BtnAction">
                                            <Button type="primary" shape="round" onClick={() => this.showAddCourse()}>{I18n.get("+ Adicionar curso")}</Button>
                                        </p>
                                        <TableCourse
                                            data={this.props.partner.specCoursesTraining && this.props.partner.specCoursesTraining}
                                            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                            partnerId={this.props.partner && this.props.partner.partnerId}
                                            delete={this.props.delete_course}
                                            edit={this.showEditCourse}
                                        />
                                    </Col>
                                </Row>

                            }

                            <Row className="RowSection">
                                <Col sm={8}>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={3}>
                                    <Link to={`/parceiro/cadastro/empresa/${this.props.match.params.partnerId}`}><Button shape="round">{I18n.get("Voltar")}</Button></Link>
                                </Col>
                                <Col sm={18}>
                                </Col>
                                <Col sm={3}>
                                    <Link to={`/parceiro/cadastro/expertises/${this.props.match.params.partnerId}`}><Button type="primary" shape="round">{I18n.get("Avançar")}</Button></Link>
                                </Col>
                            </Row>

                        </Main>
                    }
                </ContainerPage>


                <Drawer
                    title={Object.keys(this.state.selectedItem).length > 0 ? I18n.get("Editar curso") : I18n.get("Adicionar curso")}
                    placement="right"
                    forceRender={true}
                    closable={true}
                    onClose={() => this.hideCourse()}
                    destroyOnClose={true}
                    visible={this.state.show}
                    width="50%"
                >
                    <FormCourse
                        partner={this.props.partner && this.props.partner}
                        partnerId={this.props.match.params.partnerId}
                        action={this.addCourse}
                        update={this.updateCourse}
                        cancel={() => this.hideCourse()}
                        item={this.state.selectedItem}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        forceRender={true}
                    />
                </Drawer>
            </AppWrapperSimple>
        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(FullSpecialisms);
