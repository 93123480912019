import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
    Icon,
    Row,
    Col
} from 'antd';

import WhatifWrapper from '../components/WhatifWrapper';

import { CLIENT_WHATIF_API } from '../../../actions/client-whatif/actions';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import HeaderThumbCover from '../components/HeaderThumbCover'
import Carousel from '../components/Carousel'
import { CardSimple } from '../components/Cards';

import icoTarget from '../../../image/ico-target.png';
import icoLuz from '../../../image/ico-luz.png';
import icoPlataformas from '../../../image/ico-plataforma.png';
import icoFoco from '../../../image/ico-foco.png';
import icoData from '../../../image/ico-datas.png';
import icoDonts from '../../../image/ico-donts.png';

import { MENU_CLIENT_WHATIF } from '../constants';

import checkOrange from '../../../image/ico-check-orange.png';

const Main = styled.div`
  padding-top: 150px;
  padding-bottom: 100px;

  .ItemImg {
    margin: 0 1px;
    overflow: hidden;

    img {
        max-width: 200px;
    }
    }

  .BoxGrey {
      margin-bottom: 30px;
  }

  .JobDescription {
      color: #3d3d3d;
      margin-bottom: 60px;

      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 21px;
        margin-bottom: 20px;
      }

      p {
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 15px;
      }
  }


  .TargetIdeias,.OtherInformation {
      margin-bottom: 100px;
      color: #3d3d3d;
      h3 {
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 10px;

          figure {
              margin-right: 30px;
          }
          span {
              line-height: 30px;
          }
          figure, span {
              display: inline-block;
          }
      }

      p, li {
          margin-bottom: 10px;
          font-size: 13px;
          font-weight: 600;
      }

      ul {
          margin-top: 20px;
      }

      li {
          margin-bottom: 15px;

          span {
              margin-right: 5px;
          }
      }

      .ReceiveIdeas {
          margin-bottom: 0;
          div {
              height: 100%;

              @media (max-width: 576px) {
                height: auto;
                margin-bottom: 30px;
              }
          }
      }
  }

  .OtherInformation {
      margin-bottom: 0px;
      .ant-col {
          margin-bottom: 60px;
      }
      h3 {
          padding: 0 20px;
          span {
              line-height: 17px;
              top: 10px;
              position: relative;
          }

          &.oneLine {
            span {
                top: 5px;
                margin-bottom: 36px;
            }
          }
      }
  }

  .ReferencesFiles {
      div {
          margin: 0 1px;
      }

      h3 {
          color: #3d3d3d;
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          margin:0 0 30px 70px;
      }

      @media (max-width: 768px) {
            h3 {
                margin: 0 0 30px 0px;
            }
        }
  }

  .LinksAndVideos {
      padding: 50px 70px;

      @media (max-width: 768px) {
           padding: 50px 0px;
            .BoxVideos {
                margin-top: 60px;
            }

            h3 {
                padding: 0;
            }
        }

      h3 {
          color: #3d3d3d;
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          margin:0 0 10px;

        }
        a {
            color: #3d3d3d;
            font-size: 13px;
            font-weight: 600;
            line-height: 21px;
            transition: all 0.5s ease;

            &:hover,&:focus {
                color: #939393;
            }
        }
  }
`;


const TagSpan = styled.span`
  display: inline-block;
  padding: 4px 9px;
  background: #ccc;
  margin: 0 3px 10px;

  &.bluetag {
      background: #c7e5f2;
  }

  &.blue2tag {
      background: #cbede0;
  }

  &.pinktag {
      background: #ffc5b9;
  }

  &.yellowtag {
      background: #fff291;
  }

  &.greentag {
      background: #e3fbb8;
  }

`

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_project: data => {
            dispatch(CLIENT_WHATIF_API.get_project(data));
        }
    };
};


class BrandProfilePreview extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const data = {
            clientId: this.props.client.id,
            whatIfId: this.props.match.params.whatIfId
        }

        this.props.get_project(data);
    }

    render() {
        const {
            match: {
                params: {
                    whatIfId
                }
            },
            clientWhatIf: {
                imageLogo,
                imageHeader,
                brandName,
                brandPurpose,
                description,
                productName,
                keywords,
                genre,
                ageRange,
                wantReceiveSocial,
                wantReceiveOthers,
                actuationPlatforms,
                regionFocusArea,
                specialDates,
                donts,
                referenceImages,
                referenceLinks,
                referenceVideos
            }
        } = this.props;

        return (
            <WhatifWrapper
                grey
            >
                <SubHeader itensMenu={MENU_CLIENT_WHATIF} activeItem={0} />
                <HeaderThumbCover
                    link={`/cliente/ideias/brand-profile/${whatIfId}`}
                    thumb={imageLogo}
                    bgHeader={imageHeader}
                />
                <ContainerPage>
                    <Main>
                        <section className="JobDescription">
                            <Row>
                                <Col sm={18}>
                                    <h2>{brandName} / {productName}</h2>
                                    {/* item.brand + " / " + item.product */}
                                </Col>
                            </Row>
                        </section>

                        <section className="TargetIdeias">
                            <Row type="flex" justify="start">
                                <Col lg={15} md={24} className="BoxGrey">
                                    <CardSimple>
                                        <h3>
                                            <figure>
                                                <img src={icoTarget} />
                                            </figure>
                                            <span>Brand Purpose</span>
                                        </h3>
                                        <p>{brandPurpose}</p>

                                        <p>
                                            <strong>Descrição do Target:</strong><br />
                                            {description}
                                        </p>
                                        <p>
                                            <strong>Palavras-chaves:</strong><br />
                                            {keywords && keywords.map((item, index) => {
                                                return <TagSpan key={index} className="bluetag">{item}</TagSpan>
                                            })}


                                        </p>
                                        <p>
                                            <strong>Gênero:</strong> <br />
                                            {genre && genre.map((item, index) => {
                                                return <TagSpan key={index} className="blue2tag">{item}</TagSpan>
                                            })}
                                        </p>
                                        <p>
                                            <strong>Faixa etária:</strong> <br />
                                            {ageRange && ageRange.map((item, index) => {
                                                return <TagSpan key={index} className="yellowtag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                                <Col lg={9} md={24} sm={24} xs={24} className="BoxGrey ReceiveIdeas">
                                    <CardSimple >
                                        <h3>
                                            <figure>
                                                <img src={icoLuz} />
                                            </figure>
                                            <span>Quero receber ideias</span>
                                        </h3>

                                        <Row>
                                            <Col sm={12}>
                                                <p><strong>Ideias de Social</strong></p>

                                                <ul>
                                                    {wantReceiveSocial && wantReceiveSocial.map((item, index) => {
                                                        return <li key={index}><span><img src={checkOrange} /></span> {item}</li>
                                                    })}
                                                </ul>
                                            </Col>
                                            <Col sm={12}>
                                                <p><strong>Outras ideias</strong></p>

                                                <ul>
                                                    {wantReceiveOthers && wantReceiveOthers.map((item, index) => {
                                                        return <li key={index}><span><img src={checkOrange} /></span> {item}</li>
                                                    })}
                                                </ul>
                                            </Col>
                                        </Row>

                                    </CardSimple>
                                </Col>
                            </Row>
                        </section>

                        <section className="OtherInformation">
                            <Row type="flex" justify="start">
                                <Col md={12} sm={12} lg={6} xs={24}>
                                    <div className="topBox">
                                        <h3>
                                            <figure>
                                                <img src={icoPlataformas} />
                                            </figure>
                                            <span>Plataformas<br />de Atuação</span>
                                        </h3>
                                    </div>
                                    <CardSimple>
                                        <p>
                                            {actuationPlatforms && actuationPlatforms.map((item, index) => {
                                                return <TagSpan key={index} className="pinktag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                                <Col md={12} sm={12} lg={6} xs={24}>
                                    <div className="topBox">
                                        <h3>
                                            <figure>
                                                <img src={icoFoco} />
                                            </figure>
                                            <span>Área Foco para Ideias<br />& Conteudo</span>
                                        </h3>
                                    </div>
                                    <CardSimple>
                                        <p>
                                            {regionFocusArea && regionFocusArea.map((item, index) => {
                                                return <TagSpan key={index} className="blue2tag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                                <Col md={12} sm={12} lg={6} xs={24}>
                                    <div className="topBox">
                                        <h3 className="oneLine">
                                            <figure>
                                                <img src={icoData} />
                                            </figure>
                                            <span>Datas Especiais</span>
                                        </h3>
                                    </div>
                                    <CardSimple>
                                        <p>
                                            {specialDates && specialDates.map((item, index) => {
                                                return <TagSpan key={index} className="yellowtag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                                <Col md={12} sm={12} lg={6} xs={24}>
                                    <div className="topBox">
                                        <h3 className="oneLine">
                                            <figure>
                                                <img src={icoDonts} />
                                            </figure>
                                            <span>Dont’s</span>
                                        </h3>
                                    </div>
                                    <CardSimple>
                                        <p>
                                            {donts && donts.map((item, index) => {
                                                return <TagSpan key={index} className="pinktag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                            </Row>
                        </section>

                        <section className="ReferencesFiles">
                            <Row>
                                <Col sm={24}>
                                    <h3>Referências</h3>
                                </Col>

                                <Col sm={24}>
                                    <Carousel
                                        dots={false}
                                        infinite={true}
                                        speed={300}
                                        slidesToShow={referenceImages && referenceImages.length < 5 ? referenceImages.length : 5}
                                        centerMode={true}
                                        variableWidth={true}
                                    >
                                        {referenceImages && referenceImages.map((item, index) => {
                                            return (
                                                <div key={index} className="ItemImg">
                                                    <img src={item} />
                                                </div>
                                            )
                                        })}

                                    </Carousel>
                                </Col>

                            </Row>

                        </section>
                        <section className="LinksAndVideos">
                            <Row>
                                <Col sm={24} md={8}>
                                    <h3>Links</h3>

                                    <ul>
                                        {referenceLinks && referenceLinks.map((link, index) => {
                                            return (
                                                <li>
                                                    <a href={link} target="_blank"><Icon type="link" /> {link}</a>
                                                </li>

                                            )
                                        })}
                                    </ul>
                                </Col>
                                <Col sm={24} md={8} className="BoxVideos">
                                    <h3>Vídeos</h3>

                                    <ul>
                                        {referenceVideos && referenceVideos.map((video, index) => {
                                            return (
                                                <li>
                                                    <a href={video} target="_blank"><Icon type="play-circle" /> {video}</a>
                                                </li>

                                            )
                                        })}
                                    </ul>
                                </Col>
                            </Row>
                        </section>
                    </Main>
                </ContainerPage>
            </WhatifWrapper>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandProfilePreview);
