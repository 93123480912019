import React from "react";

import { I18n } from "aws-amplify";

import { Modal } from "antd";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import QuotationItemForm from "./quotation-item-form";
import QuotationSectionEditForm from "./quotation-section-edit-form";

import CommentForm from "./quotation-item-comment-form";
import StatusForm from "./quotation-item-status-form";

import EventQuotationItemStatusIcon from "../shared/event-quotation-item-status";

import Dropdown from "../ui/dropdown";

import FormatCurrency from "../ui/format-currency";

const ptBR = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
});

class QuotationItemSummary extends React.Component {
  constructor(props) {
    super();

    this.state = {
      errors: {},
      task: {},
      itemComment: null,
      itemStatus: null,
    };

    this.delItem = this.onDelItem.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState) return;
    if (!this.props.task) return;

    if (this.props.task.id == prevState.task.id) return;

    // atualiza a task
    var nextState = Object.assign({}, prevState, { task: this.props.task });

    // atualiza os erros do form
    FormUtils.updateErrors(nextState, this.props.errors);

    if (this.props.task.result) {
      // se task for sucesso, limpa os campos do form
      FormUtils.resetForm(nextState);

      nextState.file = {};
    }

    this.setState(nextState);
  }

  onDelItem(item, e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      quotationId: this.props.quotation.id,
      sectionId: this.props.section.id,
      ...item,
    };

    this.props.delete_universal_quotation_item(data);
  }

  getItemType(value) {
    return (
      (this.props.bp.eventQuotationItemType &&
        this.props.bp.eventQuotationItemType.find(
          (item) => item.code == value
        )) ||
      {}
    );
  }

  getItemCategory(value) {
    return (
      (this.props.bp.eventSegments &&
        this.props.bp.eventSegments.find((item) => item.code == value)) ||
      {}
    );
  }

  onOpenStatus(item, e) {
    if (e) e.preventDefault();

    this.setState({ itemStatus: item });
  }

  onCloseStatus(e) {
    if (e) e.preventDefault();

    this.setState({ itemStatus: null });
  }

  onOpenComment(item, e) {
    if (e) e.preventDefault();

    this.setState({ itemComment: item });
  }

  onCloseComment(e) {
    if (e) e.preventDefault();

    this.setState({ itemComment: null });
  }

  render() {
    const project = this.props.project || {};

    const settings = project.settings || {};

    const quotation = this.props.quotation || {};
    const currency = this.props.currency || {};

    const section = this.props.section || {};

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    const canEditSytle = canEdit ? {} : { display: "none" };

    const reviewEnabled =
      settings.enabledBPoolQuotationItemReview &&
      quotation.isOpen == false &&
      (this.props.auth.is_client_or_client_user ||
        this.props.auth.is_master_or_bpool);

    const reviewStyle = reviewEnabled ? {} : { display: "none" };

    return (
      <>
        <section className="quotation-item-summary">
          <div style={{ display: "flex" }}>
            <div style={{ flex: "3", alignItems: "center" }}>
              <p className="title is-5">
                {section.sectionName}{" "}
                <span style={{ fontWeight: "normal", opacity: "0.6" }}>
                  {" "}
                  &middot; {I18n.get("Itens de Terceiros")}{" "}
                </span>
                {canEdit && (
                  <>
                    <a
                      onClick={(e) => this.setState({ showEditSection: true })}
                      style={{
                        marginLeft: "1rem",
                        fontWeight: "normal",
                        fontSize: "0.9rem",
                      }}
                    >
                      {I18n.get("Editar")}
                    </a>
                  </>
                )}
              </p>
            </div>

            <div
              style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}
            >
              {canEdit && (
                <>
                  <a
                    className="button bt-bpool black"
                    onClick={(e) => this.setState({ showAddItem: true })}
                  >
                    {I18n.get("Adicionar Item de Terceiro")}
                  </a>
                </>
              )}
            </div>
          </div>

          <br />
          <br />

          <div className="columns head">
            <div className="column is-5">{I18n.get("Item Terceiro")}</div>

            <div className="column is-1">{I18n.get("Qtd.")}</div>

            <div className="column is-1">{I18n.get("Período")}</div>

            <div className="column is-2">{I18n.get("Valor unit.")}</div>

            <div
              className={`column ${canEdit || reviewEnabled ? "is-2" : "is-3"}`}
            >
              {I18n.get("Total")}
            </div>

            <div className="column is-1" style={canEditSytle}></div>

            <div className="column is-1" style={reviewStyle}>
              {I18n.get("Revisão BPool")}
            </div>
          </div>

          {section.items &&
            section.items.map((item, index) => {
              return (
                <div className="columns">
                  <div className="column is-5">
                    <p style={{ fontSize: "110%" }}>
                      {item.description}
                      {item.file && item.file.url && (
                        <a
                          href={item.file.url}
                          target="_blank"
                          title={I18n.get("Baixar Orçamento")}
                          className="is-pulled-right"
                        >
                          <i className="fa fa-download"></i>
                        </a>
                      )}
                    </p>
                    <p style={{ fontSize: "80%" }}>
                      {this.getItemType(item.itemType).label} &middot;{" "}
                      {this.getItemCategory(item.category).label}
                    </p>
                  </div>

                  <div className="column is-1 has-text-right">
                    <p>{item.quantity}</p>
                  </div>

                  <div className="column is-1 has-text-right">
                    <p>{item.recurrence}</p>
                  </div>

                  <div className="column is-2 has-text-right">
                    <p style={{ fontSize: "110%" }}>
                      {/* {ptBR.format(item.price)} */}
                      <FormatCurrency amount={item.price} currency={currency} />
                    </p>
                    <p style={{ fontSize: "80%" }}>
                      {item.doubleTaxation ? (
                        <span>{I18n.get("Item bi-tributado")}</span>
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </p>
                  </div>

                  <div
                    className={`column ${
                      canEdit || reviewEnabled ? "is-2" : "is-3"
                    } has-text-right`}
                  >
                    <p style={{ fontSize: "110%" }}>
                      {/* {ptBR.format(item.subtotal)} */}
                      <FormatCurrency
                        amount={item.subtotal}
                        currency={currency}
                      />
                    </p>
                    <p style={{ fontSize: "80%" }}>&nbsp;</p>
                  </div>

                  <div
                    className="column is-1 has-text-centered"
                    style={canEditSytle}
                  >
                    <br />
                    <a
                      onClick={(e) => this.delItem(item, e)}
                      style={{ color: "#434343" }}
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>

                  <div
                    className="column is-1 has-text-centered"
                    style={reviewStyle}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: "1" }}>
                        <Dropdown
                          isOpen={this.state.itemComment == item}
                          style={{ marginTop: "0.5rem" }}
                          trigger={
                            <a
                              href="#"
                              onClick={this.onOpenComment.bind(this, item)}
                            >
                              <span
                                style={{
                                  marginTop: "0.5rem",
                                  backgroundColor: "#000000",
                                  borderRadius: "1rem",
                                  padding: "0.5rem 0.6rem",
                                }}
                              >
                                <i
                                  style={{ color: "#ffffff" }}
                                  className="fa fa-comment-alt"
                                ></i>
                              </span>
                            </a>
                          }
                        >
                          <CommentForm
                            {...this.props}
                            quotation={quotation}
                            section={section}
                            item={item}
                            onClose={this.onCloseComment.bind(this)}
                          />
                        </Dropdown>
                      </div>
                      <div style={{ flex: "1", paddingLeft: "0.5rem" }}>
                        <Dropdown
                          isOpen={this.state.itemStatus == item}
                          trigger={
                            <a
                              href="#"
                              onClick={this.onOpenStatus.bind(this, item)}
                            >
                              <EventQuotationItemStatusIcon
                                status={item.status}
                              />
                            </a>
                          }
                        >
                          <StatusForm
                            {...this.props}
                            quotation={quotation}
                            section={section}
                            item={item}
                            onClose={this.onCloseStatus.bind(this)}
                          />
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {section.items && section.items.length == 0 && (
            <>
              <div className="columns">
                <div className="column is-12" style={{ fontSize: "85%" }}>
                  {I18n.get("Nenhum item adicionado.")}
                </div>
              </div>
            </>
          )}

          <div className="columns total">
            {" "}
            <div
              className={`column ${
                canEdit ? "is-2" : "is-3"
              } is-offset-9 has-text-right`}
            >
              {/* {ptBR.format(section.subtotal)} */}
              <FormatCurrency amount={section.subtotal} currency={currency} />
            </div>
            <div className="column is-1" style={canEditSytle}></div>
          </div>
        </section>

        <Modal
          visible={this.state.showAddItem}
          width={900}
          footer={null}
          onCancel={(e) => this.setState({ showAddItem: false })}
        >
          <QuotationItemForm
            {...this.props}
            quotation={quotation}
            currency={currency}
            section={section}
            closeModal={(e) => this.setState({ showAddItem: false })}
          />
        </Modal>

        <Modal
          visible={this.state.showEditSection}
          width={900}
          footer={null}
          onCancel={(e) => this.setState({ showEditSection: false })}
        >
          {this.state.showEditSection && (
            <>
              <QuotationSectionEditForm
                {...this.props}
                quotation={quotation}
                currency={currency}
                section={section}
                closeModal={(e) => this.setState({ showEditSection: false })}
              />
            </>
          )}
        </Modal>
      </>
    );
  }
}

export default QuotationItemSummary;
