import styled from 'styled-components';
import { TabList, Tab, TabPanel } from 'react-tabs';

const StyledTabList = styled(TabList)`
  display: flex;
  padding: 15px 0;
  ${(props) => (props.centered ? 'justify-content: center;' : null)};
`;

const StyledTab = styled(Tab)`
  cursor: pointer;
  border-bottom: 1px solid;
  border-color: #979797;
  padding: 0 30px;
  font-size: 16px;
  line-height: 33px;
  color: #979797;

  /* &:first-child {
    padding-left: 0;
  }
  
  &:last-child {
    padding-right: 0;
  } */

  &[aria-selected='true'] {
    background: transparent;
    border-bottom: 2px solid;
    color: #1883ff;
    border-color: #1883ff;
  }
`;

const StyledTabPanel = styled(TabPanel)``;

export const TooltipText = styled.div`
  width: auto;
  text-align: center;
  line-height: 44px;
  border-radius: 3px;
  cursor: pointer;

  h3 {
    color: #979797;
  }
`;
export const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + -120px);
  left: 0;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 490px;
  padding: 5px 5px;
  border-radius: 29px;

  transition: all 0.1s ease-in-out;
  z-index: 99;

  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 40px;
    bottom: -10px;
    position: absolute;

    border: 10px solid transparent;
    transform: rotate(315deg);
    transition: all 0.1s ease-in-out;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
`;

export const TooltipContentInfos = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    line-height: 23px;
  }

  strong {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    line-height: 23px;
  }
`;

export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 490px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`;

export const MissingInfo = styled.div`
  .anticon {
    padding-left: 10px;
    color: #ff0000;
  }
`;

export {
  StyledTabList as TabList,
  StyledTab as Tab,
  StyledTabPanel as TabPanel,
};
