import React from "react";

//import Jitsi from 'react-jitsi';
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return state.client.clientId
    ? Object.assign({}, state.client, state)
    : state.partner.partnerId && Object.assign({}, state.partner, state);
};

const mapDispatchToProps = (dispatch) => {};

class Meeting extends React.Component {
  constructor(props) {
    super();
  }

  getUserName() {
    if (this.props.client) {
      const userFullName = this.props.client
        ? this.props.client.name
        : this.props.partner.name;

      return userFullName;
    }

    return "Undefined";
  }
  render() {
    const { id } = this.props.match.params;
    const config = {
      defaultLanguage: "pt",
      prejoinPageEnabled: false,
    };

    return (
      <>
        {/* {this.props.client ? (
                    // <Jitsi
                    //     config={config}
                    //     roomName={id}
                    //     containerStyle={{height: window.innerHeight, width:'screen.width'}}
                    //     displayName={this.props.client.name ?? this.props.partner.name}
                    // />
                ) : (
                    <></>
                )} */}
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Meeting);
