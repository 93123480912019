import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { PartnerStatus, PartnerWhatIfStatus } from '../../model';
import { ClientStatus } from '../../model';
import LoggedPage from '../page-templates/logged';
import Loading from './loading';
import WallPage from '../pages/wall';
import Menu from './_menu';
import Config from '../../config';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class HomePage extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {
        if (this.props.auth && this.props.auth.logged_user) {

            if (this.props.auth.is_partner) {

                if (this.props.partner && this.props.partner.status === PartnerStatus.Incomplete && Config.moduloNewRegister) {
                    //Pre cadastro incompleto - Status 1
                    return (<Redirect to={`/parceiro/pre-cadastro/${this.props.partner.partnerId}`} />);
                }

                if (this.props.partner && this.props.partner.status === PartnerStatus.Incomplete) {
                    //Pre cadastro incompleto - Status 1
                    return (<Redirect to={"/parceiro/pre-cadastro"} />);
                }


                if (this.props.partner && this.props.partner.status === PartnerStatus.Created) {
                    //Pre cadastro finalizado - faltando referencias - Status 2
                    return (<Redirect to={`/parceiro/obrigado-pre-cadastro/${this.props.partner.partnerId}`} />);
                }

                if (this.props.partner && this.props.partner.status === PartnerStatus.Referenced) {
                    //Pre cadastro - 3 avaliaram - Status 3
                    return (<Redirect to={`/parceiro/obrigado-pre-cadastro/${this.props.partner.partnerId}`} />);
                }

                // Status 4 = antigo BackgroundChecked

                if (this.props.partner && this.props.partner.status === PartnerStatus.PreApproved && Config.moduloNewRegister) {
                    //Pré selecionado com cadastro full incompleto - Status 5
                    return (<Redirect to={`/parceiro/cadastro/usuarios/${this.props.partner.partnerId}`} />);
                }

                if (this.props.partner && this.props.partner.status === PartnerStatus.PreApproved) {
                    //Pré selecionado com cadastro full incompleto - Status 5
                    return (<Redirect to={"/parceiro/passo-1"} />);
                }

                // Status 6 = antigo Evaluation desativado

                if (this.props.partner && this.props.partner.status === PartnerStatus.Approved) {

                    return (<Redirect to="/parceiro/inicio" />);

                    // if (this.props.auth.is_partner || this.props.auth.is_partner_operation) {
                    //     return (<Redirect to="/parceiro/projetos" />);
                    // } else if (this.props.auth.is_partner_financial) {
                    //     return (<Redirect to="/parceiro/faturamento" />);
                    // }

                }

            }

            if (this.props.auth.is_client || this.props.auth.is_client_user) {

                if (this.props.client && this.props.client.status < ClientStatus.Approved && Config.moduloNewRegisterClient) {
                    //Pré selecionado com cadastro full incompleto - Status 5
                    return (<Redirect to={`/cliente/cadastre-se/responsavel/${this.props.client.clientId}`} />);
                }

                if (this.props.client && this.props.client.status < ClientStatus.Approved) {
                    return (<Redirect to={"/cliente/passo-" + this.props.client.step} />);
                }

                if (this.props.client && this.props.client.status === ClientStatus.Approved) {
                    return (<Redirect to="/cliente/inicio" />);
                }

            }

            if (this.props.auth.is_master || this.props.auth.is_bpool) {
                return (<Redirect to="/bpool/dashboard" />);
            }

            if (this.props.partnerWhatIf && this.props.partnerWhatIf.status == PartnerWhatIfStatus.Incomplete) {
                return (<Redirect to="/parceiro-ideias/informacoes-gerais" />);
            }

            if (this.props.partnerWhatIf && this.props.partnerWhatIf.status == PartnerWhatIfStatus.CompletedStep1) {
                return (<Redirect to="/parceiro-ideias/profile" />);
            }

            if (this.props.partnerWhatIf && this.props.partnerWhatIf.status == PartnerWhatIfStatus.Approved) {
                return (<Redirect to="/parceiro-ideias/marcas" />);
            }

        }

        return (<LoggedPage {...this.props} >
            <Loading />
        </LoggedPage>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
