import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { TitleMiddleLine } from "../../components/Titles";
import constants from "../../../constants";
import styled from "styled-components";

import {
  Row,
  Col,
  Button,
  Checkbox,
  notification,
  Input,
  AutoComplete,
  Select,
  Form,
} from "antd";

const { TextArea } = Input;
const { Option } = Select;

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
    margin-right: 20px;
  }
`;

class FormAddAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: [],
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formState: values,
        });

        let data = {
          ...values,
          clientId: this.props.clientId,
        };

        this.props.add(data);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.users &&
      prevProps.users &&
      this.props.users.length > prevProps.users.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("Usuário adicionado."),
        duration: 3,
      });

      this.props.form.resetFields();
    }

    if (
      this.props.users &&
      prevProps.users &&
      this.props.users.length < prevProps.users.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("Usuário removido."),
        duration: 3,
      });

      this.props.form.resetFields();
    }

    if (this.props.errors != prevProps.errors && this.props.errors) {
      Object.keys(this.props.errors).map((field) => {
        this.props.form.setFields({
          [field]: {
            value: this.state.formState[field],
            errors: [new Error(this.props.errors[field].errorMessage)],
          },
        });
      });
    }
  }

  getCategoryCountValues = () => {
    var values = [],
      i = 0,
      len = 10;
    while (++i <= len) values.push({ code: i, label: i });

    return values;
  };

  handleRole = (value) => {
    if (value == "Client") {
      this.props.form.resetFields("productId");
    }
  };

  handleName = (e) => {
    let result = this.props.users.filter((item) => item.name == e);

    if (result.length > 0) {
      this.props.form.setFieldsValue({
        email: result[0].email,
      });
    }
  };

  handleEmail = (e) => {
    let result = this.props.users.filter((item) => item.email == e);

    if (result.length > 0) {
      this.props.form.setFieldsValue({
        name: result[0].name,
      });
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    let products = [];

    this.props.brands &&
      this.props.brands.map((item) => {
        item.products &&
          item.products.forEach((element) => {
            products.push({
              productName: element.name,
              key: element.id,
              brandName: item.name,
              brandId: item.id,
              productId: element.id,
            });
          });
      });

    console.log("SORT a", products);

    products.sort(
      (a, b) =>
        (a.productName.toLowerCase() > b.productName.toLowerCase()) -
        (a.productName.toLowerCase() < b.productName.toLowerCase())
    );

    console.log("SORT", products);

    var name_dict = {};
    var email_dict = {};

    this.props.users &&
      this.props.users.map((item) => (name_dict[item.name] = item.name));
    this.props.users &&
      this.props.users.map((item) => (email_dict[item.email] = item.email));

    var names = Object.keys(name_dict).map((k) => {
      if (name_dict[k] !== null) {
        return name_dict[k];
      }
    });
    var emails = Object.keys(email_dict).map((k) => email_dict[k]);

    var nameFiltered = names.filter(function (el) {
      return el != null;
    });

    return (
      <div className="form-mobile">
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={8} className="">
            <Col sm={8}>
              <Form.Item label={I18n.get("Nome")}>
                {getFieldDecorator("name", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                })(
                  // <Input
                  //     disabled={this.props?.brands && this.props.brands.length == 0}
                  //     />

                  <AutoComplete
                    disabled={
                      this.props?.brands && this.props.brands.length == 0
                    }
                    dataSource={nameFiltered}
                    style={{ width: 200 }}
                    onBlur={(e) => this.handleName(e)}
                    onSelect={(e) => this.handleName(e)}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label={I18n.get("E-mail")}>
                {getFieldDecorator("email", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                })(
                  <AutoComplete
                    disabled={
                      this.props?.brands && this.props.brands.length == 0
                    }
                    dataSource={emails}
                    style={{ width: 200 }}
                    onBlur={(e) => this.handleEmail(e)}
                    onSelect={(e) => this.handleEmail(e)}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item label={I18n.get("Permissão")}>
                {getFieldDecorator("role", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                })(
                  <Select
                    onSelect={(value) => this.handleRole(value)}
                    disabled={
                      this.props?.brands && this.props.brands.length == 0
                    }
                  >
                    {constants.PERMISSION_CLIENT.map((item) => {
                      if (this.props.isMaster) {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      } else {
                        if (item.code != "Client") {
                          return (
                            <Option value={item.code} key={item.code}>
                              {item.label}
                            </Option>
                          );
                        }
                      }
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={16}>
              <Form.Item label={I18n.get("Marca e Produto")}>
                {getFieldDecorator("productId", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                })(
                  <Select
                    filterOption={(input, option) =>
                      option.props.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    searchBy="label"
                    disabled={
                      getFieldValue("role") == "Client" ||
                      getFieldValue("role") == "Master" ||
                      (this.props?.brands && this.props.brands.length == 0)
                    }
                  >
                    {products.map((item) => {
                      return (
                        <Option
                          value={item.productId}
                          key={item.productId}
                          label={`${item.brandName} - ${item.productName}`}
                        >
                          {item.brandName} - {item.productName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={2}>
              <Button
                style={{ position: "relative", top: "35px" }}
                type="primary"
                shape="round"
                htmlType="submit"
              >
                +
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const FormAddAccessForm = Form.create({})(FormAddAccess);

export default FormAddAccessForm;
