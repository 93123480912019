import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useFileRepository } from "../../../../hooks/useFileRepository/useFileRepository.hook";
import { useInProgress } from "../../../../hooks/useInProgress/useInProgress.hook";
import { notification, Icon, Tooltip, Pagination } from "antd";
import { Drawer } from "./drawer";
import { Dialog } from "../../../../components/UI/Dialog";
import {
  FormatItemsTableFiles,
  createQueryString,
  ensureAbsoluteUrl,
  isImage,
  parseQueryString,
  verifyResultIsContainData,
} from "../../utils";
import Folder from "../../svgs/Folder.svg";
import FolderOpen from "../../svgs/FolderOpen.svg";
import ArrowTop from "../../svgs/ArrowTop.svg";
import ArrowDown from "../../svgs/ArrowDown.svg";

import * as S from "./styles";

const renderTp = ({ type, txt }) => {
  if (type === "link") {
    return I18n.get("Abrir link");
  } else if (type === "file-pdf") {
    return I18n.get(`${txt} PDF`);
  } else {
    return I18n.get(`${txt} Imagem`);
  }
};

export const ListAccordionsProjects = () => {
  const auth = useSelector((state) => state.auth);
  let history = useHistory();
  let location = useLocation();
  const { getProjects } = useFileRepository();
  const { deleteFile, deleteUrl } = useInProgress();
  const [openedsPanels, setOpenedsPanels] = useState([]);
  const [openDrawer, setOpenDrawer] = useState({
    open: false,
    projectId: "",
    projectName: "",
    filesCompletionProject: [],
    projectCompletionUrls: [],
  });
  const [openDialogDelete, setOpenDialogDelete] = useState({
    visible: false,
    text: "",
    infos: {},
  });
  const [isDeletingFileOrLink, setIsDeletingFileOrLink] = useState(false);
  const [openModalFile, setOpenModalFile] = useState({
    visible: false,
    url: "",
    type: "",
  });

  const { data, isLoading, error, refetch } = useQuery(
    ["fileRepository", createQueryString(parseQueryString(location?.search))],
    () => getProjects(createQueryString(parseQueryString(location?.search))),

    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        notification.error({
          message: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar a listagem."),
          duration: 3,
        });
      },
    }
  );

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete({
      visible: false,
      text: "",
      infos: {},
    });
  };

  const deleteItem = async ({ hook, id, projectId, type }) => {
    setIsDeletingFileOrLink(id);
    try {
      const objSend = {
        projectId,
        id,
      };
      await hook(objSend);
      notification.success({
        message: I18n.get("Sucesso!"),
        description: I18n.get(
          type === "link" ? "Link deletado." : "Imagem deletada."
        ),
        duration: 3,
      });
      refetch();
    } catch (error) {
      notification.error({
        message: I18n.get("Erro"),
        description: I18n.get(
          type === "link"
            ? "Erro ao deletar o link."
            : "Erro ao deletar a imagem."
        ),
        duration: 3,
      });
    } finally {
      setIsDeletingFileOrLink("");
      handleCloseDialogDelete();
    }
  };

  const handleDeleteFileOrLink = async ({ id, type, projectId }) => {
    if (type === "link") {
      await deleteItem({ hook: deleteUrl, id, projectId, type });
    } else {
      await deleteItem({ hook: deleteFile, id, projectId, type });
    }
  };

  const handleOpenDialogDelete = ({
    id,
    type,
    projectId,
    projectName,
    name,
  }) => {
    setOpenDialogDelete({
      visible: true,
      text:
        type === "link"
          ? `${I18n.get("Deseja excluir o link")} ${name} ${I18n.get(
              "da pasta"
            )} ${projectName}?`
          : `${I18n.get("Deseja excluir o arquivo")} ${name} ${I18n.get(
              "da pasta"
            )} ${projectName}?`,
      infos: {
        id,
        projectId,
        type,
      },
    });
  };

  const handleOpenOrDownloadFile = ({ url, type, download }) => {
    if (download) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      if (isImage(type) === "image" && url) {
        setOpenModalFile({ visible: true, url, type });
      } else {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const columns = [
    {
      title: I18n.get("Data do Upload"),
      dataIndex: "uploadedDate",
      key: "uploadedDate",
      width: 240,
      render: (text) => <span>{text}</span>,
    },
    {
      title: I18n.get("Arquivo/Link"),
      dataIndex: "name",
      key: "name",
      width: 600,
      render: (text, record) =>
        record?.icon === "link" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon type={record?.icon} style={{ fontSize: 24 }} />
            <S.ViewText style={{ marginLeft: 10 }}>{text}</S.ViewText>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon type={record?.icon} style={{ fontSize: 24 }} />
            <S.ViewText style={{ marginLeft: 10 }}>{text}</S.ViewText>
          </div>
        ),
    },
    {
      title: I18n.get("Ações"),
      dataIndex: "actions",
      key: "actions",
      width: 200,
      render: (text, record) => (
        <S.TableActions>
          <Tooltip
            placement="bottom"
            title={renderTp({ txt: "Ver", type: record?.icon })}
            overlayClassName="tooltip-repo-files"
          >
            {record?.icon === "link" ? (
              <a href={ensureAbsoluteUrl(record?.url)} target="_blank">
                <Icon type="eye" style={{ fontSize: 24 }} />
              </a>
            ) : isImage(record?.type) === "image" ? (
              <button
                onClick={() =>
                  handleOpenOrDownloadFile({
                    url: record?.url,
                    type: record?.type,
                    download: false,
                  })
                }
              >
                <Icon type="eye" style={{ fontSize: 24 }} />
              </button>
            ) : null}
          </Tooltip>
          {record?.icon !== "link" ? (
            <Tooltip
              placement="bottom"
              title={renderTp({ txt: "Download", type: record?.icon })}
              overlayClassName="tooltip-repo-files"
            >
              <button
                onClick={() =>
                  handleOpenOrDownloadFile({
                    url: record?.url,
                    type: record?.type,
                    download: true,
                  })
                }
                style={{
                  marginLeft: isImage(record?.type) === "image" ? 15 : 0,
                }}
              >
                <Icon type="download" style={{ fontSize: 24 }} />
              </button>
            </Tooltip>
          ) : null}
          {auth?.is_client_or_client_user ? null : record?.canBeDeleted ? (
            <Tooltip
              placement="bottom"
              title={I18n.get(
                record?.icon === "link" ? "Deletar link" : "Deletar arquivo"
              )}
              overlayClassName="tooltip-repo-files"
            >
              <button
                style={{ marginLeft: 15 }}
                onClick={() =>
                  handleOpenDialogDelete({
                    id: record?.id,
                    type: record?.icon,
                    projectId: record?.projectId,
                    projectName: record?.projectName,
                    name: record?.icon === "link" ? record?.url : record?.name,
                  })
                }
              >
                {isDeletingFileOrLink === record?.id ? (
                  <Icon type="loading" style={{ fontSize: 24 }} />
                ) : (
                  <Icon type="delete" style={{ fontSize: 24 }} />
                )}
              </button>
            </Tooltip>
          ) : null}
        </S.TableActions>
      ),
    },
  ];

  const callback = (key) => {
    setOpenedsPanels(key);
  };

  const handlePagination = (page) => {
    setOpenedsPanels([]);
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!page || page === 1) {
      delete objQueries.page;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, page });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleCloseDrawer = ({ reload }) => {
    setOpenDrawer({
      open: false,
      projectId: "",
      projectName: "",
      filesCompletionProject: [],
      projectCompletionUrls: [],
    });
    if (reload) {
      refetch();
    }
  };

  return (
    <>
      <S.Skeleton
        loading={isLoading}
        active
        title={false}
        paragraph={{
          rows: 5,
          className: "skeleton-accordions-list",
          width: "100%",
        }}
      >
        <S.Wrapper>
          {data &&
          data?.data?.result?.length &&
          auth?.is_client_or_client_user ? (
            verifyResultIsContainData(data?.data?.result) ? (
              <S.Collapse defaultActiveKey={[]} onChange={callback}>
                {data &&
                  data?.data?.result?.map((item, index) =>
                    item?.projectCompletionUrls?.length > 0 ||
                    item?.files?.length > 0 ? (
                      <S.Panel
                        key={`${index + 1}`}
                        header={
                          <div className="header-title">
                            {openedsPanels?.find(
                              (item) => item === `${index + 1}`
                            ) ? (
                              <img src={FolderOpen} alt="FolderOpen icon" />
                            ) : (
                              <img src={Folder} alt="Folder icon" />
                            )}

                            <p>{item?.projectName}</p>
                          </div>
                        }
                        extra={
                          <S.ExtraAccordion>
                            {auth?.is_client_or_client_user ? null : (
                              <S.ButtonUploadPartner>
                                <button
                                  onClick={() =>
                                    setOpenDrawer({
                                      open: true,
                                      projectId: item?.id,
                                      projectName: item?.projectName,
                                      filesCompletionProject: item?.files,
                                      projectCompletionUrls:
                                        item?.projectCompletionUrls,
                                    })
                                  }
                                >
                                  upload
                                </button>
                              </S.ButtonUploadPartner>
                            )}
                            {openedsPanels?.find(
                              (item) => item === `${index + 1}`
                            ) ? (
                              <img src={ArrowTop} alt="ArrowTop icon" />
                            ) : (
                              <img src={ArrowDown} alt="ArrowDown icon" />
                            )}
                          </S.ExtraAccordion>
                        }
                        showArrow={false}
                      >
                        <S.Table
                          columns={columns}
                          dataSource={FormatItemsTableFiles({
                            dataFiles: item?.files,
                            dataLinks: item?.projectCompletionUrls,
                          })}
                          pagination={false}
                          locale={{
                            emptyText: I18n.get(
                              "Nenhum arquivo ou link adicionado."
                            ),
                          }}
                        />
                      </S.Panel>
                    ) : null
                  )}
              </S.Collapse>
            ) : (
              <S.EmptyProject>
                {I18n.get("Nenhum projeto para exibir.")}
              </S.EmptyProject>
            )
          ) : data?.data?.result?.length > 0 ? (
            <S.Collapse defaultActiveKey={[]} onChange={callback}>
              {data &&
                data?.data?.result?.map((item, index) => (
                  <S.Panel
                    key={`${index + 1}`}
                    header={
                      <div className="header-title">
                        {openedsPanels?.find(
                          (item) => item === `${index + 1}`
                        ) ? (
                          <img src={FolderOpen} alt="FolderOpen icon" />
                        ) : (
                          <img src={Folder} alt="Folder icon" />
                        )}

                        <p>{item?.projectName}</p>
                      </div>
                    }
                    extra={
                      <S.ExtraAccordion>
                        {auth?.is_client_or_client_user ? null : (
                          <S.ButtonUploadPartner>
                            <button
                              onClick={() =>
                                setOpenDrawer({
                                  open: true,
                                  projectId: item?.id,
                                  projectName: item?.projectName,
                                  filesCompletionProject: item?.files,
                                  projectCompletionUrls:
                                    item?.projectCompletionUrls,
                                })
                              }
                            >
                              upload
                            </button>
                          </S.ButtonUploadPartner>
                        )}
                        {openedsPanels?.find(
                          (item) => item === `${index + 1}`
                        ) ? (
                          <img src={ArrowTop} alt="ArrowTop icon" />
                        ) : (
                          <img src={ArrowDown} alt="ArrowDown icon" />
                        )}
                      </S.ExtraAccordion>
                    }
                    showArrow={false}
                  >
                    <S.Table
                      columns={columns}
                      dataSource={FormatItemsTableFiles({
                        dataFiles: item?.files,
                        dataLinks: item?.projectCompletionUrls,
                      })}
                      pagination={false}
                      locale={{
                        emptyText: I18n.get(
                          "Nenhum arquivo ou link adicionado."
                        ),
                      }}
                    />
                  </S.Panel>
                ))}
            </S.Collapse>
          ) : (
            <S.EmptyProject>
              {I18n.get("Nenhum projeto para exibir.")}
            </S.EmptyProject>
          )}
        </S.Wrapper>
      </S.Skeleton>

      {data &&
      data?.data?.result?.length &&
      verifyResultIsContainData(data?.data?.result) ? (
        <Pagination
          defaultCurrent={
            parseQueryString(location?.search)?.page
              ? Number(parseQueryString(location?.search)?.page)
              : 1
          }
          total={data?.data?.pagination?.totalItems}
          pageSize={10}
          onChange={(page) => handlePagination(page)}
        />
      ) : null}

      <Drawer
        open={openDrawer.open}
        handleCloseDrawer={handleCloseDrawer}
        projectData={openDrawer}
        reload={() => refetch()}
      />

      <Dialog
        visible={openDialogDelete.visible}
        text={openDialogDelete.text}
        loading={isDeletingFileOrLink}
        buttons={{
          ok: {
            text: I18n.get("Confirmar"),
            func: () =>
              handleDeleteFileOrLink({
                id: openDialogDelete.infos.id,
                projectId: openDialogDelete.infos.projectId,
                type: openDialogDelete.infos.type,
              }),
          },
          cancel: {
            text: I18n.get("Fechar"),
            func: () => handleCloseDialogDelete(),
          },
        }}
      />

      <S.ModalViewImage
        visible={openModalFile.visible}
        title="Title"
        centered
        onOk={() => setOpenModalFile({ visible: false, url: "", type: "" })}
        onCancel={() => setOpenModalFile({ visible: false, url: "", type: "" })}
        footer={[]}
      >
        <img src={openModalFile.url} alt="Preview file" />
      </S.ModalViewImage>
    </>
  );
};
