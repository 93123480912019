const getValue = (value) => {
  if (value === null || value === undefined) {
    return "";
  } else {
    return value;
  }
};

export const OrganizeState = ({ data }) => {
  return {
    projectName: getValue(data?.projectName),
    brandId: getValue(data?.brandId),
    productId: getValue(data?.productId),
    clientResponsableUsername: getValue(data?.createdByUsername),
    estimatedStartDate: getValue(data?.estimatedStartDate),
    estimatedDurationInMonths: getValue(data?.estimatedDurationInMonths),
    estimatedDurationInWeeks: getValue(data?.estimatedDurationInWeeks),
    nonCompeteInDays: getValue(data?.nonCompeteInDays),
  };
};
