import styled from "styled-components";

export const Wrapper = styled.div`
  h3 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props?.isFullRegister ? "flex-start;" : "flex-end;"};
  margin-top: ${(props) => (props?.isFullRegister ? "8px;" : "32px;")};
`;

export const TitleContent = styled.div`
  padding: 0 15px;
  margin: 0 0 30px 0;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const Form = styled.div`
  padding-bottom: 90px;
`;

export const Empty = styled.div`
  margin-top: 32px;

  p {
  }
`;

export const CheckboxItem = styled.div`
  width: fit-content;
  height: 50px;
  display: flex;
  align-items: center;

  .ant-checkbox {
    top: 2px;
  }
  label {
    cursor: pointer;
    margin: 0;
    display: flex;
  }
  p {
    cursor: pointer;
    margin: 0;
    font-size: 14px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }
  .ant-checkbox-inner {
    border-radius: 50%;
    border: 1px solid #e8e8e8;
  }
`;
