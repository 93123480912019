import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import AppWrapperSimple from '../../components/AppWrapperSimple';
import { Link } from 'react-router-dom'

import PageSuccess from '../../components/PageSuccess';

class LeaderSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <AppWrapperSimple>
                <PageSuccess>
                    <Fragment>
                        <h2>{I18n.get("Obrigado pelo seu tempo!")}</h2>
                        <p>{I18n.get("Perfil de líder atualizado com sucesso")}</p>
                    </Fragment>
                </PageSuccess>
            </AppWrapperSimple>
        );
    }
}

export default LeaderSuccess;
