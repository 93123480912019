import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PARTNER_UPDATE_CASE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_edit_case = (data) => {

    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };


            // [HttpPut("/api/[controller]/{partnerWhatIfId}/cases/{caseId}")]
            return axios.put(`${Config.API_BASE_URL}/PartnerWhatIf/${data.partnerWhatIfId}/cases/${data.caseId}`, data, config)
                .then(response => {

                    let data = response.data;
                    delete data['errors'];

                    dispatch({
                        type: PARTNER_UPDATE_CASE,
                        payload: {
                            ...data,
                            errosCase: response.data.errors
                        }
                    })
                })
                .catch(error => {
                    dispatch({
                        type: PARTNER_UPDATE_CASE,
                        payload: {
                            errosCase: error.response.data
                        }
                    })

                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_edit_case;
