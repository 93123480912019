import styled from "styled-components";

export const Btns = styled.div`
  button {
    margin-left: 20px;
  }
`
export const Expand = styled.div`
  background: #fafafa;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.101557);
  border-radius: 10px;
  padding: 30px 30px 30px 10px;

  .contentInfo {
    margin: 30px 0 60px;
  }

  .blueText {
    color: #1883ff;
  }

  .buttonsContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    a {
      margin-left: 20px;
    }
  }
`;

export const MyProjectsCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const MyProjectsCheckBoxLabel = styled.div`
  margin-right: 8px;

  span {
    font-size: 14px;
  }
`;
