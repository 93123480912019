import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { PARTNER_API } from "../../actions/partner/actions";

import { Task } from "../../utils/task";

import NakedModal from "../ui/naked-modal";

import CheckboxField from "../form/checkbox-field";

import DownloadButton from "../form/download-button-field";

import Terms from "./_terms";

import SubmitButtonField from "../form/submit-button-field";

import { PartnerStatus } from "../../model";

const mapStateToProps = (state) => {
  return Object.assign(state, state.partner);
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (data) => {
      dispatch(PARTNER_API.update(data));
    },
  };
};

class TermsUpdateForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      form: {
        terms: {
          label: I18n.get("Li e estou de acordo"),
          value: props.terms,
          checked: props.terms,
          onChange: this.onChangeFormCheckbox.bind(this, "terms"),
          error: props.errors.terms && props.errors.terms,
          successIcon: false,
        },
      },
      task: {},
    };

    this.closeModal = this.onCloseModal.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.partner && this.props.partner.task;

    if (!prevState) return;
    if (!task) return;

    var nextState = Object.assign({}, prevState);

    if (task.id != prevState.task.id) {
      nextState.task = task;

      this.setState(nextState);
    }
  }

  onCloseModal(e) {
    if (e) e.preventDefault();

    this.setState({ termsIsOpen: false });
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].checked = checked;
      nextState.form[prop].value = checked;

      return nextState;
    });
  }

  getData() {
    const data = {
      partnerId: this.props.partnerId,
      terms: this.state.form.terms.value,
    };

    return data;
  }

  onSubmitForm(e) {
    e.preventDefault();

    this.closeModal();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  render() {
    if (!this.props.auth.is_partner) return null;

    if (
      this.props.auth.is_partner &&
      this.props.partner.status == PartnerStatus.Approved &&
      this.props.partner.terms
    )
      return null;

    return (
      <NakedModal wide={true} isOpen={true}>
        <div className="card" style={{ margin: "0.5rem" }}>
          <header className="card-header">
            <p className="card-header-title" style={{ fontSize: "130%" }}>
              {I18n.get("Termos Contratuais BPool")}
            </p>
          </header>
          <div className="card-content" style={{ maxWidth: "960px" }}>
            <div className="content">
              <form>
                {this.props?.partnerTerm && (
                  <iframe
                    src={`${this.props?.partnerTerm}`}
                    height="300px"
                    width="100%"
                  ></iframe>
                )}

                {/* <Terms /> */}
                <br />
                <br />
                <br />

                <DownloadButton
                  LinkToDownload={this.props?.partnerTerm}
                  text="Download"
                />

                <br />
                <br />

                <CheckboxField {...this.state.form.terms} />

                <div className="columns">
                  <div className="column is-half"></div>
                  <div className="column is-half">
                    <SubmitButtonField
                      className="button is-black is-fullwidth next"
                      label={I18n.get("CONTINUAR")}
                      onClick={this.onSubmitForm.bind(this)}
                      task={this.state.task}
                      suppressLabel={true}
                      disabled={!this.state.form.terms.value}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </NakedModal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsUpdateForm);
