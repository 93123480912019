import React, { useState, useEffect } from "react";
import { Icon, notification } from "antd";
import { useParams } from "react-router-dom";
import { useInProgress } from "../../../hooks/useInProgress/useInProgress.hook";
import { Input } from "../Input";
import Loading from "../Loading";
import { I18n } from "aws-amplify";
import iconDelete from "./svgs/icon-delete.svg";
import iconLink from "./svgs/icon-link.svg";

import * as S from "./styles";

export const LinksFiles = ({
  cbLinks,
  cbLinksOnClick,
  title = true,
  subTitle = true,
  isLoading,
  urlsDefault,
  noAdd,
  noDelete,
  projectId,
  notSendInternal,
  reload,
  hasPropsItemListCanBeDeleted,
}) => {
  const { addUrl, deleteUrl } = useInProgress();
  let params = useParams();
  const [links, setLinks] = useState([]);
  const [inputLink, setInputLink] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isDeleting, setIsDeleting] = useState("");

  React.useEffect(() => {
    if (urlsDefault?.length) {
      const linksDef = urlsDefault?.map((item) => ({
        id: item?.id,
        url: item?.url,
      }));
      setLinks(linksDef);
    }
  }, [urlsDefault]);

  const handleAddLink = async () => {
    try {
      setIsSending(true);
      const objSend = {
        projectId: projectId ? projectId : params?.projectId,
        url: inputLink,
      };
      const response = await addUrl(objSend);
      if (response?.success) {
        setLinks([
          ...links,
          { id: response?.data?.id, url: response?.data?.url },
        ]);
        setInputLink("");
        notification.success({
          message: I18n.get("Sucesso!"),
          description: I18n.get("Link adicionado."),
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: I18n.get("Erro!"),
        description: I18n.get("Erro ao adicionar link."),
        duration: 3,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleAddLinkInternal = () => {
    setLinks([...links, { id: null, url: inputLink }]);
    setInputLink("");

    if (cbLinksOnClick) {
      cbLinksOnClick({ id: null, url: inputLink });
    }
  };

  const handleDeleteLink = async (link) => {
    if (link?.id) {
      try {
        setIsDeleting(link?.id);
        const objSend = {
          projectId: projectId ? projectId : params?.projectId,
          id: link?.id,
        };
        const response = await deleteUrl(objSend);
        if (response?.success) {
          const linksFiltereds = links?.filter((item) => item?.id !== link?.id);
          setLinks(linksFiltereds);
          setInputLink("");
          notification.success({
            message: I18n.get("Sucesso!"),
            description: I18n.get("Link deletado."),
            duration: 3,
          });
        }
        await reload();
      } catch (error) {
        notification.error({
          message: I18n.get("Erro"),
          description: I18n.get("Erro ao deletar link."),
          duration: 3,
        });
      } finally {
        setIsDeleting("");
      }
    } else {
      const linksFiltereds = links?.filter((item) => item?.url !== link?.url);
      setLinks(linksFiltereds);
    }
  };

  function validarURL(url) {
    // const regex =
    //   /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+)(\?[\w=&-]*)?$/;

    // return regex.test(url);
    return url;
  }

  const handleKeyDown = (event) => {
    if (validarURL(inputLink)) {
      if (event.key === "Enter") {
        if (notSendInternal) {
          handleAddLinkInternal();
        } else {
          handleAddLink();
        }
      }
    } else return;
  };

  const ensureAbsoluteUrl = (url) => {
    // Verifica se a URL é nula ou indefinida e retorna como está
    if (!url) return url;

    // Verifica se a URL já contém um esquema válido
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url; // A URL já é absoluta, retorna como está
    }

    // Se a URL não começar com http:// ou https://, assume que precisa de https://
    return `https://${url}`;
  };

  useEffect(() => {
    if (cbLinks) {
      cbLinks(links);
    }
  }, [links]);

  return (
    <S.Wrapper>
      {title ? (
        <S.Title>
          <span>{I18n.get("Link")}</span>
        </S.Title>
      ) : null}
      {subTitle ? (
        <p className="text">
          {I18n.get(
            noAdd
              ? "Abaixo você pode visualizar links de vídeos ou arquivos caso desejar"
              : "Abaixo você pode inserir links de vídeos ou arquivos caso desejar"
          )}
        </p>
      ) : null}

      {noAdd ? null : (
        <S.InputAndButton>
          <Input
            placeholder={I18n.get("Link")}
            label
            value={inputLink}
            onChange={(value) => setInputLink(value)}
            onKeyDown={handleKeyDown}
            error=""
          />
          {isSending ? (
            <button disabled={true} onClick={() => true}>
              <Icon type="loading" />
            </button>
          ) : (
            <button
              disabled={validarURL(inputLink) ? false : true}
              onClick={
                notSendInternal
                  ? () => handleAddLinkInternal()
                  : () => handleAddLink()
              }
            >
              <Icon type="plus" />
            </button>
          )}
        </S.InputAndButton>
      )}

      {isLoading ? (
        <div>
          <Loading
            sizeIcon={18}
            text={I18n.get("Loading...")}
            sizeText={14}
            align="flex-start"
          />
        </div>
      ) : links?.length ? (
        <S.ListLinks>
          <ul>
            {links?.map((link, index) => (
              <li key={index}>
                <S.LinkAdded>{link?.url}</S.LinkAdded>
                <div style={{ display: "flex", padding: "0 0 0 8px" }}>
                  <a
                    href={ensureAbsoluteUrl(link?.url)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={iconLink} alt="icon link" />
                  </a>
                  {isDeleting === link?.id ? (
                    <button
                      disabled
                      onClick={() => true}
                      style={{ marginLeft: 5 }}
                    >
                      <Icon type="loading" />
                    </button>
                  ) : hasPropsItemListCanBeDeleted ? (
                    link?.canBeDeleted ? (
                      <button
                        onClick={() => handleDeleteLink(link)}
                        style={{ marginLeft: 5 }}
                      >
                        <img src={iconDelete} alt="icon delete" />
                      </button>
                    ) : null
                  ) : noDelete ? null : (
                    <button
                      onClick={() => handleDeleteLink(link)}
                      style={{ marginLeft: 5 }}
                    >
                      <img src={iconDelete} alt="icon delete" />
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </S.ListLinks>
      ) : null}
    </S.Wrapper>
  );
};
