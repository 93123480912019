import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import constants from '../../../constants';
import { Link, Redirect, withRouter } from 'react-router-dom';
import AppWrapperSimple from '../../components/AppWrapperRegister';
import ContainerPage from '../../components/ContainerPage';
import SubHeader from '../../components/SubHeader';
import { ClientStatus } from '../../../model';
import { CLIENT_API } from '../../../actions/client/actions';
import FormAddAccess from '../components/formAddAccess';
import FormTermsClient from '../components/formTermsClient';
import { TitleBorder } from '../../components/Titles';
import { Task } from '../../../utils/task';
import DownloadButton from '../../../components/form/download-button-field';
import styled from 'styled-components';

import TermsClientPT from '../components/_termsClientPT';
import TermsClientES from '../components/_termsClientES';
import TermsClientEN from '../components/_termsClientEN';


import {
    Row,
    Col,
    Input,
    notification,
    Select,
    Modal,
    Tag,
    Icon,
    Button,
    Drawer,
    Table,
    Tooltip,
    Form
} from 'antd';

import TableMaster from '../components/tableMaster';


const { TextArea } = Input;
const { Option } = Select;

const Btns = styled.p`
    a {
        margin-left: 10px;
    }
`


const Main = styled.div`
    padding-top: 50px;
    padding-bottom: 90px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    .RowSection {
        margin-bottom: 60px;
    }
`
const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        update_terms: data => {
            return dispatch(CLIENT_API.update_terms(data));
        },
        save: data => {
            dispatch(CLIENT_API.update_company(data));
        },
        update: data => {
            dispatch(CLIENT_API.update(data));
        },
        delUser: data => {
            dispatch(CLIENT_API.delete_user(data));
        },
        get: data => {
            dispatch(CLIENT_API.get(data));
        },
        addUser: data => {
            dispatch(CLIENT_API.create_user(data));
        },
    };
};

class FullAccessClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleModal: false,
            sendRegister: false,
            viewErros: false,
            task: {}
        }
    }


    componentDidMount = () => {
        this.props.get(this.props.match.params.clientId);
    }


    getUsers() {
        return this.props.client && this.props.client.users && this.props.client.users.filter((item) => item.role == "Client" && !item.productId);
    }

    handleSave = () => {
        const data = {
            clientId: this.props.match.params.clientId,
            sendRegister: true,
            isSubmit: true,
            viewErros: true
        }

        this.props.update(data);
    }

    fishOrTerms = () => {
        const data = {
            clientId: this.props.match.params.clientId,
            sendRegister: true,
            isSubmit: true,
        }

        var task = new Task();

        task.start();

        this.setState({
            task: task,
            viewErros: true
        }, function () {
            this.props.update(data);
        });
    }

    closeTerms = () => {
        this.setState({
            visibleModal: false
        });
        const data = {
            clientId: this.props.match.params.clientId,
            sendRegister: true,
            isSubmit: true,
        }


        setTimeout(() => {
            this.props.update(data);
        }, 20);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.auth.logged_user && this.props.client != prevProps.client && Object.keys(this.props.client.errors).length == 1 && this.state.viewErros) {
            this.setState({
                visibleModal: true
            });
        }

        if (this.props.client != prevProps.client) {
            this.setState({
                task: {},
            })
        }
    }

    render() {
        const isInit = (!this.props.auth || this.props.auth.init);

        if (this.props.client && this.props.client.status > ClientStatus.Incomplete) {
            return (<Redirect to="/cliente/cadastre-se/sucesso" />);
        }

        const isNotLogged = (this.props.auth && !this.props.auth.logged_user);
        const IsLogout = (this.props.auth && this.props.auth.is_logout);

        if (isNotLogged) {

            const login = {
                pathname: '/login',
                state: {
                    returnUrl: (IsLogout ? '' : this.props.location.pathname),
                }
            }

            return (<Redirect to={login}></Redirect>);
        }   



        let dataTable = this.getUsers();


        let errorMap = {
        };


        Object.keys(this.props.client && this.props.client.errors && this.props.client.errors).map((item) => {

            const error = this.props.client.errors[item];
            const key = error.step || 'global';


            if (error.step < 5) {
                if (!errorMap[key]) {
                    errorMap[key] = []
                }
                errorMap[key].push(error.errorMessage)
            }

        });

        let userClient = this.props.client && this.props.client.users && this.props.client.users.filter((i) => i.role == "Client");

        //TODO: Find better way... GAMBI!
        let menu = [...constants.MENU_REGISTER_CLIENT];
        for (let key in menu) {
            menu[key].label = I18n.get(menu[key].label);
        }

        return (
            <AppWrapperSimple>
                <SubHeader
                    titlePage=""
                    itensMenu={menu}
                    urlId={this.props.match.params.clientId}
                    activeItem={4}
                />
                <ContainerPage>
                    <Main>
                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Acesso Master")}</TitleBorder>
                                <p className="descriptionPage">
                                    {I18n.get("Se você quiser, indique abaixo outros usuários master que terão acesso irrestrito a todos os níveis da plataforma. Serão responsáveis por inclusão ou exclusão de cadastros bem como acessar todo painel de controle, avaliações, gastos por marcas, etc.")} <b>{I18n.get("Como responsável pelo cadastro, você já é usuário Master.")}</b>
                                </p>
                            </Col>
                        </Row>

                        <FormAddAccess
                            clientId={this.props.match.params.clientId}
                            add={this.props.addUser}
                            errors={this.props.client.errors && this.props.client.errors}
                            userClient={userClient}
                        />


                        <Row className="RowSection">
                            <Col sm={8}>
                            </Col>
                        </Row>

                        <Row className="RowSection">
                            <Col sm={24}>
                                <TableMaster
                                    data={dataTable || []}
                                    delete={this.props.delUser}
                                    clientId={this.props.match.params.clientId}
                                />
                            </Col>
                        </Row>

                        {Object.keys(errorMap).length > 0 && this.state.viewErros &&
                            <Fragment>
                                <Row className="">
                                    <Col sm={24}>
                                        <div className="ulErrors">

                                            {Object.keys(errorMap).map((item, index) => {

                                                let itemNav = constants.MENU_REGISTER_CLIENT.find((i) => i.activeMenu == item);


                                                return (
                                                    <div className="sectionError" key={index}>

                                                        <h3><Link to={`${itemNav.link && itemNav.link}${this.props.match.params.clientId}`}><Icon type="link" /> {itemNav.label}</Link></h3>

                                                        <ul>
                                                            {errorMap[item].map((errorMessage) => (<li><Link to={`${itemNav.link && itemNav.link}${this.props.match.params.clientId}`}><Icon type="exclamation-circle" />  {errorMessage}</Link></li>))}
                                                        </ul>
                                                    </div>
                                                );

                                            })}
                                        </div>

                                    </Col>
                                </Row>
                                <Row className="RowSection">
                                    <Col sm={24}>
                                    </Col>
                                </Row>
                            </Fragment>
                        }

                        <Row>
                            <Col sm={3}>
                                <Link to={`/cliente/cadastre-se/empresa/${this.props.match.params.clientId}`}><Button type="primary" shape="round">{I18n.get("Voltar")}</Button></Link>
                            </Col>
                            <Col sm={18}>
                            </Col>
                            <Col sm={3}>
                                <Button loading={this.state.task.busy} type="primary" onClick={() => this.fishOrTerms()} shape="round">{I18n.get("Finalizar")}</Button>
                            </Col>
                        </Row>

                    </Main>

                    <Modal
                        title={I18n.get("Termos Contratuais BPool")}
                        visible={this.state.visibleModal && Object.keys(errorMap).length == 0}
                        footer={false}
                        onCancel={() => this.setState({
                            visibleModal: false
                        })}
                        width="55%"
                    >

                        <div style={{ height: "420px" }}>
                            <iframe src={`${this.props.client.clientTerm}`} height="420px" width="100%"></iframe>
                        </div>

                        <p className="pright">
                            <DownloadButton LinkToDownload={this.props.client.clientTerm} text="Download" />
                        </p>

                        <br /><br />

                        <FormTermsClient
                            clientId={this.props.match.params.clientId}
                            update={this.props.update_terms}
                            close={this.closeTerms}
                        />
                    </Modal>

                </ContainerPage>
            </AppWrapperSimple>
        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FullAccessClient));
