import React, { Fragment } from "react";
import { I18n } from "aws-amplify";
import moment from "moment";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  Icon,
  Table,
  Tag,
  DatePicker,
  Drawer,
  Skeleton,
} from "antd";
import { Redirect } from "react-router-dom";

import DataSet from "@antv/data-set";
import { Chart, Geom, Axis, Tooltip, Coord, Guide } from "bizcharts";

import { formatCurrency, formatMoneyGraph } from "../../../utils/helpers";
import { WarningType } from "../../../model";
import { REPORT_API } from "../../../actions/report/actions";

import styled from "styled-components";

const { RangePicker } = DatePicker;

const TableCard = styled(Table)`
  margin-top: 14px;

  table {
    border: solid 1px #ccc !important;

    tr {
      cursor: pointer;
    }

    tr:nth-child(odd) td {
      background: #f2f2f2;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 6px 16px;
  }
`;

const DrawerBp = styled(Drawer)`
  top: 60px;

  h2 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;
    position: relative;

    &::after {
      width: 60px;
      content: "";
      border-bottom: solid 5px #000;
      display: block;
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }

  .description {
    font-size: 13px;
    line-height: 20px;
  }

  .total {
    margin: 30px 0;
    small {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .boxtotal {
      font-size: 22px;
      color: #fff;
      background: #000;
      padding: 5px 10px;
      strong {
        color: #fff;
      }
    }
  }

  .TableBox {
    margin-top: 50px;
  }
`;

const CardDisplay = styled(Card)`
  margin-bottom: 20px;

  .DescriptionPage {
    font-size: 14px;
  }

  .LabelPeriodo {
    font-size: 12px;
    font-weight: bold;
  }

  &.TableBox {
    min-height: 335px;
  }

  .GraphCard {
    opacity: 1;
    transition: opacity 2s ease;
  }

  .hidden {
    opacity: 0;
  }

  .show {
    opacity: 1;
  }

  .LabelCard {
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: -10px;
  }

  h2 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-left: 15px;
    border-left: solid 5px #000;
    span {
      display: block;
      color: #999;
      font-size: 11px;
    }

    &.title-span {
      line-height: 18px;
    }
  }

  .NumberCard {
    display: block;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
  }

  .DescriptionCard {
    span {
      font-weight: bold;
    }
    border-top: solid 1px #b5b5b5;
    padding-top: 10px;
    margin-top: 15px;
    font-size: 18px;
  }

  .BtnCard {
    border-top: solid 1px #ccc;
    padding-top: 15px;
    margin-top: 10px;
    display: none;
  }

  .LoadingCard {
    text-align: center;
    margin: 36px 0 33px;

    .noDate {
      display: block;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }

    i {
      font-size: 50px;
      opacity: 0.4;
    }

    &.LabelG {
      position: absolute;
      top: 38%;
      left: 0;
      width: 100%;

      &.noDateView {
        top: 32%;
      }
    }
  }
`;

const Miolo = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: calc(100% - 48px);
  font-size: 20px;

  h3 {
    font-size: 18px;
  }
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    api_get_warning_dash: (data) => {
      dispatch(REPORT_API.get_warning(data));
    },

    delete_itens_dash: (data) => {
      dispatch(REPORT_API.delete_itens());
    },
    reset_itens_dash: (data) => {
      dispatch(REPORT_API.reset_itens());
    },
  };
};

const columns = [
  {
    title: I18n.get("Mensagem"),
    dataIndex: "msg",
    key: "msg",
  },
  {
    title: I18n.get("Status"),
    key: "status",
    dataIndex: "status",
    render: (status) => (
      <Tag color={status && status === true ? "green" : "red"}>
        {status === true ? I18n.get("Resolvido") : I18n.get("Em andamento")}
      </Tag>
    ),
  },
  {
    title: I18n.get("Data"),
    dataIndex: "data",
    key: "data",
  },
];

class PedingContainerClient extends React.Component {
  constructor(props) {
    super(props);

    this.partner_chart = React.createRef();

    this.state = {
      visible: false,
      typeDrawer: "",
      redirectProject: false,
      redirectId: "",
      url: "",
    };
  }

  onClose = () => {
    this.setState({
      visible: false,
    });

    this.props.delete_itens_dash();
  };

  getApi(data) {
    this.props.api_get_warning_dash(data);
  }

  componentDidMount() {
    let data = {
      prefix: this.props.prefix,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      idGet: this.props.idGet,
    };

    this.getApi(data);
  }

  componentWillUnmount() {
    this.props.reset_itens_dash();
  }

  renderCallBack(arr) {
    if (arr == undefined) {
      return (
        <p className="LoadingCard LabelG">
          <Icon type="loading" />
        </p>
      );
    } else if (arr && arr.length == 0) {
      return (
        <p className="LoadingCard LabelG noDateView">
          <Icon type="inbox" />
          <span className="noDate">{I18n.get("Sem informações")}</span>
        </p>
      );
    }
  }

  render() {
    const { warning } = this.props.report;
    const { redirectProject, redirectId, url } = this.state;

    const dataWarning =
      warning &&
      warning.map((i) => {
        return {
          key: i.warningId,
          msg: i.message,
          data: i.createdOn,
          status: i.solved,
          type: i.type,
          url: i.url,
        };
      });

    if (redirectProject) {
      // Em alguns momentos, é necessário remover o prefixo "cliente/" para não ficar duplicado.
      if (url) {
        let newUrl = "";

        if (url.startsWith("cliente/")) {
          newUrl = url.replace("cliente/", "");
        } else {
          newUrl = url;
        }

        return <Redirect to={newUrl} />;
      }

      return <Redirect to={`/projetos/${redirectId}/resumo`} />;
    }

    return (
      <Fragment>
        <Row>
          <Col md={24}>
            <CardDisplay className="TableBox">
              <h2>{I18n.get("Central de pendências")}</h2>
              <TableCard
                showHeader={false}
                columns={columns}
                dataSource={dataWarning}
                pagination={{ defaultPageSize: 12 }}
                className={`GraphCard ${
                  dataWarning && dataWarning.length > 0 ? "show" : "hidden"
                }`}
                scroll={{ x: 640 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      this.setState({
                        redirectProject: true,
                        redirectId: record.key,
                        url: record.url,
                      });
                    },
                  };
                }}
              />
              {this.renderCallBack(dataWarning)}
            </CardDisplay>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PedingContainerClient);
