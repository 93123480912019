import { I18n } from "aws-amplify";

export const monthsPT = [
  {
    code: 0,
    label: I18n.get("0 mês"),
  },
  { code: 1, label: I18n.get("1 mês") },
  { code: 2, label: I18n.get("2 meses") },
  { code: 3, label: I18n.get("3 meses") },
  { code: 4, label: I18n.get("4 meses") },
  { code: 5, label: I18n.get("5 meses") },
  { code: 6, label: I18n.get("6 meses") },
  { code: 7, label: I18n.get("7 meses") },
  { code: 8, label: I18n.get("8 meses") },
  { code: 9, label: I18n.get("9 meses") },
  { code: 10, label: I18n.get("10 meses") },
  { code: 11, label: I18n.get("11 meses") },
  { code: 12, label: I18n.get("12 meses") },
];

export const monthsEN = [
  {
    code: 0,
    label: I18n.get("0 month"),
  },
  { code: 1, label: I18n.get("1 month") },
  { code: 2, label: I18n.get("2 months") },
  { code: 3, label: I18n.get("3 months") },
  { code: 4, label: I18n.get("4 months") },
  { code: 5, label: I18n.get("5 months") },
  { code: 6, label: I18n.get("6 months") },
  { code: 7, label: I18n.get("7 months") },
  { code: 8, label: I18n.get("8 months") },
  { code: 9, label: I18n.get("9 months") },
  { code: 10, label: I18n.get("10 months") },
  { code: 11, label: I18n.get("11 months") },
  { code: 12, label: I18n.get("12 months") },
];
