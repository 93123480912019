import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink, Redirect } from "react-router-dom";

import constants from '../../constants';

import { PartnerStatus } from '../../model';

import { QueryString } from '../../utils/querystring';

import SubmitButtonField from '../form/submit-button-field';

export default class PartnerList extends React.Component {

    constructor(props) {
        super();

        var queryString = QueryString.extract(props.location.search);

        this.state = {
            q: '',
            status: queryString.status || '',
            address_state: '',
        };

        this.changeFilter = this.onChangeFilter.bind(this);
        this.selectPartner = this.onSelectPartner.bind(this);

        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        //this.props.get_partners(this.state);
    }

    onChangeFilter(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            return Object.assign({}, prevState, {
                ...prevState,
                [prop]: value
            });
        });

        // , function () {
        //     this.props.get_partners(this.state)
        // });
    }

    onSelectPartner(item, e) {
        this.setState({ selected: item.id });
    }

    submitForm(e) {
        e.preventDefault();
        this.props.get_partners(this.state);
    }

    getStatus(value) {
        if (!this.props.bp || !this.props.bp.partnerStatusesMap) return '';

        return this.props.bp.partnerStatusesMap[value].label;
    }

    render() {

        if (this.state.selected) {
            return (<Redirect to={'/bpool/parceiros/' + this.state.selected} />);
        }



        return (
            <div>

                <div className="field is-grouped table-mob">

                    <div className="control campo-table-mob">
                        <label className="label is-small">{I18n.get("Buscar")}</label>
                        <input onChange={this.changeFilter.bind(this, 'q')} className="input" type="text" placeholder={I18n.get("Nome, Razão Social, Responsável, Email")} />
                    </div>

                    <div className="control campo-table-mob">
                        <label className="label is-small">{I18n.get("Status")}</label>
                        <div className="select">
                            <select value={this.state.status} onChange={this.changeFilter.bind(this, 'status')}>
                                <option value="">{I18n.get("Todos status")}</option>
                                {this.props.bp.partnerStatuses && this.props.bp.partnerStatuses.map((item) => (<option key={item.code} value={item.code}>{item.label}</option>))}
                            </select>
                        </div>
                    </div>

                    <div className="control campo-table-mob">
                        <label className="label is-small">{I18n.get("Estado")}</label>
                        <div className="select">
                            <select value={this.state.address_state} onChange={this.changeFilter.bind(this, 'address_state')}>
                                <option value="">{I18n.get("Todos estados")}</option>
                                {constants.STATES.map((item) => (<option key={item.code} value={item.code}>{item.label}</option>))}
                            </select>
                        </div>
                    </div>

                        <SubmitButtonField
                            label={I18n.get("Buscar")}
                            className="button is-dark is-outlined is-fullwidth"
                            onClick={this.submitForm.bind(this)}
                        />





                </div>

                <div className="">
                    <div className="table-main">

                    {this.props.bp.partners &&
                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth hasTableCounter">
                            <thead>
                                <tr>
                                    <th>{I18n.get("Responsável")}</th>
                                    <th>{I18n.get("Email")}</th>
                                    <th>{I18n.get("Empresa")}</th>
                                    <th>{I18n.get("Estado")}</th>
                                    <th>{I18n.get("Cadastro")}</th>
                                    <th>{I18n.get("Status")}</th>

                                    <th>{I18n.get("CV. Total")}</th>
                                    <th>{I18n.get("CV. Aguard.")}</th>
                                    <th>{I18n.get("CV. Aceito")}</th>
                                    <th>{I18n.get("CV. Negado")}</th>
                                    <th>{I18n.get("CV. Exp.")}</th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.props.bp.partners.map((item, index) => {

                                    let specs = item.specialisms || [];

                                    let hasLiveMkt = specs.includes("LIVE_MKT");


                                    return (
                                        <tr key={index}>
                                            <td className="has-text-left">
                                                <NavLink to={`/bpool/parceiros/${item.id}`} className="is-black">{item.name} {hasLiveMkt && <span class="tag is-info is-rounded tagLive">{I18n.get("Live Mkt")}</span>}</NavLink>
                                            </td>
                                            <td>
                                                {item.email && item.email}
                                            </td>
                                            <td>
                                                {item.company && item.company.name}
                                            </td>
                                            <td onClick={this.selectPartner.bind(this, item)}>
                                                {item.company && item.company.state}
                                            </td>
                                            <td onClick={this.selectPartner.bind(this, item)}>
                                                {item.createdOnFmt}
                                            </td>
                                            <td onClick={this.selectPartner.bind(this, item)}>
                                                {this.getStatus(item.status)}
                                            </td>

                                            <td>
                                                {item.statistics && item.statistics.projectInvite && item.statistics.projectInvite.total}
                                            </td>
                                            <td>
                                                {item.statistics && item.statistics.projectInvite && item.statistics.projectInvite.waiting}
                                            </td>
                                            <td>
                                                {item.statistics && item.statistics.projectInvite && item.statistics.projectInvite.accepted}
                                            </td>
                                            <td>
                                                {item.statistics && item.statistics.projectInvite && item.statistics.projectInvite.declined}
                                            </td>
                                            <td>
                                                {item.statistics && item.statistics.projectInvite && item.statistics.projectInvite.expired}
                                            </td>
                                            <td>
                                                <NavLink to={`/bpool/parceiros/${item.partnerId}/preview`}>{I18n.get("Preview")}</NavLink>
                                            </td>
                                        </tr>
                                    );
                                }

                                )}
                            </tbody>
                        </table>}

                    </div>
                </div>
            </div>
        );
    }
}
