import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  background-color: #f2f2f2;
`;


export const ParcialText = styled.p`
  border-bottom: 1px solid #cecece;
  padding: 10px 15px;
  text-align: right;
`;


export const TotalText = styled.p`
padding: 10px 15px;
text-align: right;



  strong {
    display block;
    font-size: 20px;
    span {
      color:#1883ff;
    }
  }
`;

export const IvaText = styled.p`
border-bottom: 1px solid #cecece;
padding: 10px 15px;
  text-align: right;
`;
