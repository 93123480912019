import React from 'react';

import { I18n } from 'aws-amplify';

const Steps = (props) => {

    if (!props.bp || !props.bp.partner) return null;

    const item = props.bp.partner;

    function getClassName(step) {



        if (item.status === step) {
            return 'is-active';
        }
        else if (item.status >= step) {
            return 'is-completed bp-is-completed';
        }
        else {
            return 'bp-is-pending';
        }
    }

    const items = [
        {
            index: 1,
            title: I18n.get("Incompleto"),
            desc: 'Iniciou pré-cadastro'
        },
        {
            index: 2,
            title: I18n.get("Pré-cadastro"),
            desc: 'Aguardando referencias'
        },
        {
            index: 3,
            title: I18n.get("Referenciado"),
            desc: 'Recebeu 3 avaliações'
        },
        // {
        //     index: 4,
        //     title: "Verificado",
        //     desc: 'Aguardando pré-seleção.'
        // },
        {
            index: 5,
            title: I18n.get("Pré-selecionado"),
            desc: 'Aguardando término cadastro'
        },
        // {
        //     index: 6,
        //     title: "Em avaliação",
        //     desc: 'Aguardando avaliação da BPool.'
        // },
        {
            index: 7,
            title: I18n.get("Aprovado"),
            desc: ''
        }
    ];


    return (
        <div className="steps">
            {items.map((step, index) =>
                (<div key={index} className={'step-item ' + getClassName(step.index)}>
                    <div className="step-marker"></div>
                    <div className="step-details">
                        <p className="step-title">{step.title}</p>
                        <p>{step.desc}</p>
                    </div>
                </div>)
            )}
        </div>
    )
};

export default Steps;
