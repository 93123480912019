import styled from "styled-components";

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;
  margin-bottom: 16px;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const Forgot = styled.div`
  text-align: left;

  a {
    font-size: 14px;
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Form = styled.div``;

export const ButtonLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    line-height: 54px;
    background-color: #000;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 0px 40px;
    border-radius: 10px;
    transition: all 0.2s;

    :hover,
    :focus,
    :active {
      background: #494949;
      color: #fff;
    }
  }
`;
