import axios from "axios";

import Config from "../../config";

import { AUTH_API } from "../auth/actions";
import { BP_API } from "../bp/actions";

import { USER_UPDATE } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const api_confirm_userpartner = (data, locale) => {
  return (dispatch) => {
    return axios
      .post(`${Config.API_BASE_URL}/PartnerUser/confirm`, data)
      .then((response) => {
        const email = response.data.email;
        const password = response.data.password;

        delete response.data["password"];

        dispatch({
          type: USER_UPDATE,
          payload: response.data,
        });

        dispatch(BP_API.get_domain(locale));
      })
      .catch((error) => {
        dispatch({
          type: USER_UPDATE,
          payload: {
            errors: error.response.data,
          },
        });
      });
  };
};
export default api_confirm_userpartner;
