import React from "react";
import * as S from "./styles";

const Loading = ({ text, sizeText, align, sizeIcon, color, style }) => {
  return (
    <S.StyledDiv align={align} style={style}>
      <S.StyledIcon sizeIcon={sizeIcon} color={color} type="loading" />
      {text ? (
        <S.Text sizeText={sizeText} color={color}>
          {text}
        </S.Text>
      ) : null}
    </S.StyledDiv>
  );
};

export default Loading;
