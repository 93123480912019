import React, { Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { NavLink, Redirect } from "react-router-dom";

import { Button, Icon, Modal } from 'antd';

import constants from '../../../constants';

import { ClientProjectStatus } from '../../../model';

import { QueryString } from '../../../utils/querystring';

import FormatCurrency from '../../ui/format-currency';

const { confirm } = Modal;


export default class ClientProjectList extends React.Component {

    constructor(props) {
        super();

        var queryString = QueryString.extract(props.location.search);

        this.state = {
            q: '',
            status: queryString.status || '',
        };

        this.changeFilter = this.onChangeFilter.bind(this);
        this.select = this.onSelect.bind(this);
    }

    componentDidMount() {

        this.props.get_projects(this.state);
    }

    onChangeFilter(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            return Object.assign({}, prevState, {
                ...prevState,
                [prop]: value
            });
        }, function () {
            this.props.get_projects(this.state)
        });
    }

    onSelect(item, e) {
        this.setState({ selected: item.id });
    }

    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return '';

        return this.props.bp.clientProjectStatusesMap[value] && this.props.bp.clientProjectStatusesMap[value].label;
    }

    showConfirm(item) {
        this.setState({
            deleteId: item.projectId,
            disableBtn: true
        });

        confirm({
            title: `${I18n.get("Deseja realmente excluir o projeto")} ${item.projectName} ?`,
            content: I18n.get("Essa ação é irreversível"),
            okText: "Excluir",
            cancelText: "CANCELAR",

            onOk: () => {
                this.props.delete_project(item.projectId);
            },
            onCancel() {
                this.setState({
                    disableBtn: false
                });
            },
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.bp.projects != prevProps.bp.projects) {
            this.setState({
                disableBtn: false
            });
        }
    }

    render() {

        const currency = this.props.currency || {};

        if (this.state.selected) {
            return (<Redirect to={'/bpool/projetos/' + this.state.selected} />);
        }


        return (
            <div>

                <div className="field is-grouped table-mob">

                    <div className="control campo-table-mob">
                        <label className="label is-small">{I18n.get("Buscar")}</label>
                        <input onChange={this.changeFilter.bind(this, 'q')} className="input" type="text" placeholder={I18n.get("Nome")} />
                    </div>

                    <div className="control campo-table-mob">
                        <label className="label is-small">{I18n.get("Status")}</label>
                        <div className="select">
                            <select value={this.state.status} onChange={this.changeFilter.bind(this, 'status')}>
                                <option value="">{I18n.get("Todos status")}</option>
                                {this.props.bp.clientProjectStatuses && this.props.bp.clientProjectStatuses.map((item) => (<option key={item.code} value={item.code}>{item.label}</option>))}
                            </select>
                        </div>
                    </div>

                </div>

                <div className="">
                    <div className="table-main">

                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth hasTableCounter">
                            <thead>
                                <tr>
                                    <th>{I18n.get("Tipo")}</th>
                                    <th>{I18n.get("Nome")}</th>
                                    <th>{I18n.get("Cliente")}</th>
                                    <th>{I18n.get("Data")}</th>
                                    <th>{I18n.get("Status")}</th>
                                    <th>{I18n.get("Valor")}</th>
                                    <th>{I18n.get("Parceiro")}</th>
                                    <th>{I18n.get("BPool")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.bp.projects && this.props.bp.projects.map((item, index) =>
                                    (<tr key={index}>
                                        <td onClick={this.select.bind(this, item)}>
                                            {item.projectType == 0 ? "C" : "E"}
                                        </td>
                                        <td className="has-text-left">
                                            <NavLink to={`/bpool/projetos/${item.id}`} className="is-black">{item.projectName}</NavLink>
                                        </td>
                                        <td>
                                            {item.clientName}
                                        </td>
                                        <td onClick={this.select.bind(this, item)}>
                                            {item.createdOnFmt}
                                        </td>
                                        <td onClick={this.select.bind(this, item)}>
                                            {this.getStatus(item.status)}
                                        </td>

                                        <td onClick={this.select.bind(this, item)}>
                                            <FormatCurrency amount={item.priceClientWithDiscount} currency={currency} />
                                        </td>

                                        <td onClick={this.select.bind(this, item)}>
                                            <FormatCurrency amount={item.pricePartner} currency={currency} />
                                        </td>

                                        <td onClick={this.select.bind(this, item)}>
                                            <FormatCurrency amount={item.priceBPoolComission} currency={currency} />
                                        </td>

                                        <td>

                                            {item.status == ClientProjectStatus.Draft &&
                                                <Fragment>
                                                    {this.state.disableBtn &&
                                                        <Button type="primary" className="btnDangerList">
                                                            <Icon type="loading" />
                                                        </Button>
                                                    }
                                                    {!this.state.disableBtn &&
                                                        <Button onClick={() => this.showConfirm(item)} type="primary" className="btnDangerList">
                                                            <Icon type="delete" theme="filled" />
                                                        </Button>
                                                    }

                                                </Fragment>
                                            }
                                        </td>
                                    </tr>)
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        );
    }
}
