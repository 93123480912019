import React from 'react';

import { I18n } from 'aws-amplify';

import ReactPlayer from 'react-player';

const Poster = React.memo(({ photos, videos }) => {

    if (videos && videos.length) {

        return (<div className="responsive-video-container"><ReactPlayer className="responsive-video" url={videos[0]} 
                                playing={true}
                                loop={true}
                                controls={false}
                                muted={true}
                                style={{ margin: '0 auto' }} /></div>);
    }
 
    if (photos && photos.length) {
        return (<figure className="media-left">
            <p className="image is-4by3">
                <img src={photos[0]} />
            </p>
        </figure>);
    }

    return (<figure className="media-left">
        <p className="image is-4by3">
            <img src='https://bpool.co/images/noBanner.png' />
        </p>
    </figure>);
});

class CaseItem extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    getShortName(name, size) {

        if (name && name.length > size) {
            name = name.substring(0, size - 3) + "...";
        }

        return name;
    }

    render() {

        return (<div>

            <Poster photos={this.props.photos} videos={this.props.videos} />

            <p>
                <small>{this.getShortName(this.props.product, 60)}</small> <br />
                <strong>{this.getShortName(this.props.brand, 60)}</strong> <br />
                {this.props.year}<br />
                {this.props.owner && 
                    <span class="tag is-warning">{this.props.owner}</span>}
            </p>

        </div>)
    }
}

export default CaseItem;