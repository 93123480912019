import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import TextField from '../form/text-field';
import CheckboxField from '../form/checkbox-field';
import SelectField from '../form/select-field';
import AutoSuggestField from '../form/auto-suggest-field';

const compareIgnoreCase = (a, b) => (a && a.toLowerCase()) === (b && b.toLowerCase());

class ClientUserForm extends React.Component {

    constructor(props) {

        super();

        this.state = {
            form: {
                addSelf: {
                    label: I18n.get("Quero fazer parte deste perfil"),
                    value: false,
                    checked: false,
                    error: props.errors.addSelf,
                    successIcon: false,
                },
                name: {
                    label: I18n.get("Nome"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'name'),
                    onSelect: this.onSelect.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'name'),
                },
                email: {
                    label: I18n.get("E-mail"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'email'),
                    onSelect: this.onSelect.bind(this, 'email'),
                    error: props.errors.email,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'email'),
                },
                jobPosition: {
                    label: I18n.get("Área"),
                    value: '',
                    onChange: this.onChangeJobPosition.bind(this),
                    error: props.errors.jobPosition,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'jobPosition'),
                },
            },
            task: {},
        };

        this.addUser = this.onAddUser.bind(this);
        this.delUser = this.onDelUser.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;

        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);
        }

        this.setState(nextState);
    }

    onSelect(prop, value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (prop == 'name') {
                var emails = this.props.users.filter((item) => item.name == value);

                if (emails && emails.length) {
                    nextState.form['email'].value = emails[0].email;
                }
            }
            if (prop == 'email') {
                var names = this.props.users.filter((item) => item.email == value);

                if (names && names.length) {
                    nextState.form['name'].value = names[0].name;
                }
            }

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onChangeJobPosition(e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['jobPosition'].value = value;

            return nextState;
        });
    }

    onAddSelf(self, e) {

        var task = new Task();

        task.start();

        var data = {
            clientId: this.props.clientId,
            role: this.props.role,
            productId: this.props.productId || '',
            name: this.props.name,
            email: this.props.email,
            jobPosition: 'OUT',
            id: self && self.id
        };

        this.setState({ task: task }, function () {

            if (!self) {
                this.props.addUser(data);
            }
            else {
                this.props.delUser(data);
            }
        });
    }

    onBlur(item) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    getData() {

        var form = FormUtils.getData(this.state);

        var data = {
            clientId: this.props.clientId,
            role: this.props.role,
            productId: this.props.productId || '',
            ...form
        };

        return data;
    }

    onAddUser(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.addUser(this.getData());
        });
    }

    onDelUser(item, e) {
        e.preventDefault();

        const data = Object.assign({}, this.getData(), { id: item.id });

        this.props.delUser(data);
    }

    getUsers() {
        if (!this.props.users) return [];

        if (this.props.role == 'Client') {
            return this.props.users.filter((item) => item.role == this.props.role && !item.productId);
        }
        else {
            return this.props.users.filter((item) => item.role == this.props.role && item.productId == this.props.productId);
        }

    }

    getJobPositions() {
        return this.props.bp.clientJobPositions && this.props.bp.clientJobPositions.map((item) => ({ code: item.code, label: item.label }));
    }

    getJobPosition(value) {

        return (this.props.bp.clientJobPositionsMap && this.props.bp.clientJobPositionsMap[value]) || {};
    }

    render() {

        const users = this.getUsers();
        var name_dict = {};
        var email_dict = {};

        this.props.users && this.props.users.map((item) => name_dict[item.name] = { code: item.name, label: item.name });
        this.props.users && this.props.users.map((item) => email_dict[item.email] = { code: item.email, label: item.email });

        var names = Object.keys(name_dict).map((k) => name_dict[k])
        var emails = Object.keys(email_dict).map((k) => email_dict[k])

        var showAddSelf = false;
        var self = users && users.find((item) => compareIgnoreCase(item.email, this.props.email) && item.role == this.props.role);

        return (
            <section>

                {showAddSelf && <CheckboxField {...this.state.form.addSelf} checked={!!self} value={!!self} onChange={this.onAddSelf.bind(this, self)} />}

                <div className="field is-horizontal">
                    <div className="field-body">

                        <AutoSuggestField {...this.state.form.name} values={names} />

                        <AutoSuggestField {...this.state.form.email} values={emails} />

                        {this.props.enableJobRole && <SelectField {...this.state.form.jobPosition} values={this.getJobPositions()} />}

                        <div className="field is-narrow">
                            <label className="label is-small">
                                &nbsp;
                            </label>
                            <p className="control">


                                <button
                                    type="button"
                                    onClick={this.addUser}
                                    className="button is-leaked">{I18n.get("CONFIRMAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                                {/* <a onClick={this.addUser}
                                        className="button is-rounded is-black">
                                    INCLUIR
                                </a> */}


                            </p>
                        </div>

                    </div>
                </div>

                <br />

                {users && users.length > 0 &&

                    <div className="table-main">

                        <table className="table is-bordered is-fullwidth" style={{ border: 'none' }}>
                            <thead class="table-blue-head">
                                <tr>
                                    <th>{I18n.get("Nome")}</th>
                                    <th>{I18n.get("E-mail")}</th>
                                    {this.props.enableJobRole && <th>{I18n.get("Área")}</th>}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((item, index) => {

                                    return (

                                        <tr key={index}>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>
                                                {item.email}
                                            </td>
                                            {this.props.enableJobRole && <td>
                                                {this.getJobPosition(item.jobPosition).label}
                                            </td>}
                                            <td>
                                                <a onClick={(e) => this.delUser(item, e)}>
                                                    <i className="fa fa-trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </section>
        );
    }
}

export default ClientUserForm;
