import React from "react";

import * as S from "./styles";

export const Unlogged = ({ children }) => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderContent>
          <span>
            <img
              src="/static/media/logoWOtext.eb8a797b.svg"
              alt="BPool"
              class="logo"
            />
          </span>
        </S.HeaderContent>
      </S.Header>
      <S.Content>{children}</S.Content>
      <S.Footer>
        <S.ContentFooter>
          <div className="container">
            <span>2024 © BPool</span>
          </div>
        </S.ContentFooter>
      </S.Footer>
    </S.Wrapper>
  );
};
