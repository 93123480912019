import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { Task } from '../../utils/task';

import { CLIENT_API } from '../../actions/client/actions';

import TextField from '../form/text-field';
//import PasswordField from '../form/password-field';
import MaskedField from '../form/masked-field';
import SubmitButtonField from '../form/submit-button-field';

//import TooltipField from '../form/tooltip-field';


//import Dropzone from 'react-dropzone'

const mapStateToProps = state => {
    return state.client;
};

const mapDispatchToProps = dispatch => {
    return {
        get_client: id => {
            dispatch(CLIENT_API.get(id));
        },
        save: data => {
            dispatch(CLIENT_API.update_person(data));
        }
    };
};

class PersonForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            form: {
                clientId: props.clientId,
                social_link: '',
                socialURLs: props.socialURLs,
                name: {
                    label: I18n.get("Nome Completo"),
                    value: props.name,
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'name'),
                },
                email: {
                    label: I18n.get("E-mail"),
                    value: props.email,
                    onChange: this.onChangeForm.bind(this, 'email'),
                    error: props.errors.email,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'email'),
                    readOnly: true
                },
                phoneNumber: {
                    label: I18n.get("Celular (DDD)"),
                    value: props.phoneNumber,
                    onChange: this.onChangeForm.bind(this, 'phoneNumber'),
                    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                    error: props.errors.phoneNumber,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'phoneNumber'),
                },
                nameAlias: {
                    label: I18n.get("Como você quer ser chamado?"),
                    tooltip: I18n.get("Algum apelido? Como você é conhecido no mercado? Queremos saber para facilitar sua identificação."),
                    value: props.nameAlias,
                    onChange: this.onChangeForm.bind(this, 'nameAlias'),
                    error: props.errors.nameAlias,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'nameAlias'),
                },
                linkedin: {
                    label: I18n.get("Linkedin Pessoal"),
                    value: props.linkedin,
                    onChange: this.onChangeForm.bind(this, 'linkedin'),
                    error: props.errors.linkedin,
                    onBlur: this.onSave.bind(this, 'linkedin'),
                },
                isSubmit: false,
            },
            task: {},
        };

        //this.onDrop = this.onDrop.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                if (typeof nextState.form[item] === "object") {
                    nextState.form[item].error = this.props.errors[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeSocialLink(e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.social_link = value;

            return nextState;

        });
    }

    onAddSocialLinkClick(e) {

        e.preventDefault();

        if (!this.state.form.social_link) return;

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.socialURLs = [...prevState.form.socialURLs, this.state.form.social_link];
            nextState.form.social_link = '';

            return nextState;

        }, this.onSave());
    }

    onDelSocialLinkClick(index, e) {

        e.preventDefault();

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.socialURLs = prevState.form.socialURLs.filter((item, i) => i != index);

            return nextState;

        }, this.onSave());
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData() {

        var data = {};

        Object.keys(this.state.form).forEach((item) => {

            if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
                data[item] = this.state.form[item].value
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    onSave(item) {



        if (this.props.isAdmin) return;

        // zera o erro
        if (item && typeof this.state.form[item] === "object") {
            this.state.form[item].error = null;
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }


    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    //onDrop(files) {
    //    const dropzone = document.querySelector(".dropzone")
    //    dropzone.style.backgroundImage = `url('${URL.createObjectURL(files[0])}')`
    //    dropzone.innerHTML = ""
    //}

    render() {

        //const dropzoneRef = React.createRef();

        //<div className="field">
        //    <label className="label is-small">
        //        Uma foto sua
        //        </label>
        //    <div style={{
        //        display: "flex",
        //        alignItems: "center"
        //    }}>
        //        <Dropzone className="dropzone" multiple={false} ref={dropzoneRef} onDrop={this.onDrop}>
        //            <span className="icon is-small">
        //                <i className="fa fa-camera"></i>
        //            </span>
        //        </Dropzone>
        //        <button type="button" className="button is-leaked" onClick={() => { dropzoneRef.current.open() }}>
        //            UPLOAD
        //            </button>
        //    </div>
        //</div>

        //<h1 className="title-bpool medium">
        //    LinkedIn
        //    </h1>

        //    <div className="field is-narrow">
        //        <p className="control">
        //            <a className="button has-icon is-rounded is-black">
        //                <span className="icon is-small">
        //                    <i className="fa fa-linkedin"></i>
        //                </span>
        //            </a>
        //            <button type="button" className="button is-leaked">
        //                INTEGRAR COM O LINKEDIN
        //            </button>
        //        </p>
        //    </div>

        return (
            <form onSubmit={this.submitForm.bind(this)} className="form-mobile">

                <TextField {...this.state.form.name} />
                <TextField {...this.state.form.nameAlias} />
                <TextField {...this.state.form.email} />
                <MaskedField {...this.state.form.phoneNumber} />

                <TextField {...this.state.form.linkedin} />


                <h1 className="title-bpool medium">{I18n.get("Redes Sociais")}</h1>

                {this.state.form.socialURLs && this.state.form.socialURLs.map((item, index) =>

                    (<div key={item} className="field is-horizontal">
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <a href={item} target="_blank">{item}</a>
                                </p>
                            </div>
                            <div className="field is-narrow">
                                <p className="control">
                                    <a onClick={this.onDelSocialLinkClick.bind(this, index)} className="delete is-black">
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>)

                )}

                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <p className="control">
                                <input
                                    onChange={this.onChangeSocialLink.bind(this)}
                                    value={this.state.form.social_link}
                                    className={(this.state.form.social_link && this.props.errors.socialURLs) ? "input is-danger" : "input"}
                                    type="text"
                                    placeholder={I18n.get("Cole o link aqui")}
                                />
                            </p>
                            {(this.state.link && this.props.errors.socialURLs) && <p className="help is-danger">
                                {this.props.errors.socialURLs.errorMessage}
                            </p>}
                        </div>
                        <div className="field is-narrow">
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={this.onAddSocialLinkClick.bind(this)}
                                    className="button is-leaked">{I18n.get("CONFIRMAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                                {/* <a onClick={this.onAddSocialLinkClick.bind(this)} className="button has-icon is-black">
                                    <span className="icon is-small">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                </a> */}

                            </p>
                        </div>
                    </div>
                </div>

                {this.props.isAdmin && <div className="control">
                    <SubmitButtonField
                        label={I18n.get("Salvar")}
                        onClick={this.submitForm.bind(this)}
                        task={this.state.task}
                    />
                </div>}

            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonForm);
