import React from "react";
import { I18n } from "aws-amplify";
import { NavLink } from "react-router-dom";

import ExtrasContainer from "../../containers/extras-container";

import FillForm from "../page-templates/fillForm-logged";

import QuotationForm from "./extras/quotation-form";

import Steps from "./steps";

import SelectField from "../form/select-field";

import { getQuotationStatus } from "../../utils/extras";

import StepsCreative from "../../components/client-project-definition/steps-creative";

import {NewExtraBudget} from "../../v2/pages/Proposal/Extras/pages/NewExtraBudget";

class ExtraQuotationPage extends React.Component {
  constructor(props) {
    const { quotation = {} } = props;

    super(props);
    this.state = {
      fields: {
        quotationId: {
          label: I18n.get("Histórico de cotações"),
          onChange: this.onChangeQuotation.bind(this),
          successIcon: false,
        },
      },
      form: {
        quotationId: quotation && quotation.id,
      },
      errors: {},
      task: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { quotation = {} } = this.props;

    if (quotation && quotation.id && !this.state.form.quotationId) {
      this.setState((prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form["quotationId"] = quotation.id;

        return nextState;
      });
    }
  }

  onChangeQuotation(e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["quotationId"] = value;

      return nextState;
    });
  }

  submitForm(e) {}

  getQuotations() {
    return (
      this.props.quotationHistory &&
      this.props.quotationHistory.map((item) => ({
        code: item.id,
        label: `${getQuotationStatus(this.props.auth, item)} - ${
          item.updatedOn
        }`,
      }))
    );
  }

  render() {
    const { auth = {} } = this.props;

    const displayPO = auth.is_master || auth.is_client_or_client_user;

    const quotation =
      this.props.quotationHistory &&
      this.props.quotationHistory.find(
        (q) => q.id == this.state.form.quotationId
      );

    const endExtraEnabled =
      auth.is_client_or_client_user &&
      this.props.quotation &&
      this.props.quotation.isApproved != null;

    return (
      <FillForm>
        <div className="hero-body has-background-white">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-4">
                <p className="title">
                  {this.props?.project && this.props?.project?.status != 6
                    ? I18n.get("Projeto em Andamento")
                    : I18n.get("Definições do Projeto")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-8">
                {this.props?.project && this.props?.project?.status == 6 && (
                  <>
                    <StepsCreative
                      active={5}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                    />
                  </>
                )}

                {this.props?.project && this.props?.project?.status != 6 && (
                  <Steps
                    active={6}
                    project={this.props.project}
                    hidden={this.props.auth.is_partner ? [5] : []}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="client-step client-step-1">
          <div
            className="container client-container-1"
            style={{ paddingTop: "0px" }}
          >
            <div className="columns" style={{ margin: 0 }}>
            

              <div className="column is-12 ">
                  <NewExtraBudget />

                {/* <QuotationForm {...this.props} quotation={quotation} /> */}

              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ExtrasContainer(ExtraQuotationPage);
