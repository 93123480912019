import { I18n } from "aws-amplify";

export const menuItems = [
  // {
  //   key: 1,
  //   label: I18n.get("Endereço"),
  //   url: "/fornecedor/perfil/endereco",
  // },
  {
    key: 1,
    label: I18n.get("Dados Bancários"),
    url: "/fornecedor/perfil/dados-bancarios",
  },
];
