import axios from 'axios';
import { Auth } from 'aws-amplify';
import Config from '../../config';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_upload_w = (data) => {

    return Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        delete data.token;

        return axios.post(`${Config.API_BASE_URL}/WhatIfIdea/${data.id}/upload`, data, config)


    });

}

export default api_upload_w;
