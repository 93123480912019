import get_bpool from "./get";
import get_domain from "./get_domain";
import get_bundles from "./get_bundles";
import get_assets from "./get_assets";
import get_users from "./get_users";
import get_dashboard from "./get_dashboard";
import get_partners from "./get_partners";
import api_get_suppliers from "./get_suppliers";
import get_partner from "./get_partner";
import update_evaluation from "./update_evaluation";
import create_user from "./create_user";
import update_match from "./update_match";

import get_assets_partners from "./get_assets_partners";
import update_asset from "./update-asset";
import approve_or_refuse from "./approve-or-refuse";

import update_partner from "./update_partner";
import update_partner_preselection from "./update_partner_preselection";
import update_partner_final_approval from "./update_partner_final_approval";

import update_partner_prov from "./update_partner_provisorio";

import get_industries from "./get_industries";

import update_market from "./update_partner_market";

import get_invoicetypes from "./get_invoicetypes";

import get_clients from "./get_clients";
import get_client from "./get_client";
import update_client from "./update_client";
import client_upload from "./client_upload";

import get_project from "./get_project";
import get_project_audio from "./get_project_audio";
import get_projects from "./get_projects";
import get_projects_full from "./get_projects_full";
import get_projects_kanban from "./get_projects_kanban";
import get_candidates from "./get_candidates";

import get_possible_matches from "./get_possible_matches";

import get_clients_logins from "./get_clients_logins";
import get_clients_users from "./get_clients_users";
import clone_user_client from "./clone_user_client";
import reset_user_client from "./reset_user_client";
import delete_user_client from "./delete_user_client";

import delete_project from "./delete-project";
import cancel_project from "./cancel-project";

import delete_extra from "./delete-extra";
import cancel_extra from "./cancel-extra";

import get_partners_logins from "./get_partners_logins";
import get_partners_users from "./get_partners_users";
import clone_user_partner from "./clone_user_partner";
import reset_user_partner from "./reset_user_partner";
import delete_user_partner from "./delete_user_partner";

import get_suppliers_logins from "./get_suppliers_logins";
import get_suppliers_users from "./get_suppliers_users";
import clone_user_supplier from "./clone_user_supplier";


import delete_itens from "./delete_itens";

import create_billing_installment from "./create-billing-installment";
import create_production_billing_installment from "./create-production-billing-installment";
import create_extra_billing_installment from "./create-extra-billing-installment";

import update_billing_installment_invoice_due_date from "./update-billing-installment-invoice-due-date";
import update_billing_installment_invoice_payment_date from "./update-billing-installment-invoice-payment-date";

import create_event_quotation_item_comment from "./create-event-quotation-item-comment";
import delete_event_quotation_item_comment from "./delete-event-quotation-item-comment";
import send_event_quotation_item_notification from "./send-event-quotation-item-notification";
import update_event_quotation_item_status from "./update-event-quotation-item-status";
import export_event_quotation from "./export-event-quotation";

import get_filters_projects from "./get_filters_projects";
import get_filters_audios from "./get_filters_audio_projects";
import get_projects_list from "./get_projects_list";
import get_audios_list from "./get_audio_list";

import get_filters_clients from "./get_filters_clients";
import get_clients_list from "./get_clients_list";

import get_filters_partners from "./get_filters_partners";
import get_partners_list from "./get_partners_list";

import get_partners_vips from "./get_partners_vips";
import create_partners_vips from "./create_partners_vips";
import send_email_vip from "./send_email_vip";

import get_filters_extras from "./get_filters_extras";
import get_extras_list from "./get_extras_list";

import get_filters_transfer from "./get_filters_transfer";
import get_invoices_list from "./get_invoices_list";

import get_filters_milestones from "./get_filters_milestones";
import get_milestone_list from "./get_milestones_list";

import reports from "./report";

import get_projects_and_extras_list from "./get_projects_and_extras_list";
import create_client_settings_project_rule from "./create-client-settings-project-rule";
import delete_client_settings_project_rule from "./delete-client-settings-project-rule";
import create_client_settings_extra_rule from "./create-client-settings-extra-rule";
import delete_client_settings_extra_rule from "./delete-client-settings-extra-rule";



import create_client_settings_bpay_rule from "./create-client-settings-bpay-rule";
import delete_client_settings_bpay_rule from "./delete-client-settings-bpay-rule";

import update_settings from "./update-settings";

import meeting_invites from "./meetings";

import get_assets_client_source from "./get_assets_client_source";
import get_assets_client from "./get_assets_load";
import update_client_asset from "./update_client_asset";
import create_client_asset from "./create_client_asset";
import delete_client_asset from "./delete_client_asset";

import get_bundle_client_source from "./get_bundle_client_source";
import get_bundle_client from "./get_bundle_load";
import delete_client_bundle from "./delete_client_bundle";
import update_client_bundle from "./update_client_bundle";
import create_client_bundle from "./create_client_bundle";

import reset from "./reset";

import generate_financial from "./generate-financial";
import generate_financial_nfs from "./generate-financial-nfs";
import generate_invoices from "./generate-invoices";
import export_bundle from "./export-bundle";

// Dash2
import get_countrys from "./get_country";
import status_summary from "./status-summary";
import feedback_summary from "./feedback-summary";

import financial from "./financial";
import financial_historic from "./financial-historic";
import pivot from "./pivot";
import pivot_table from "./pivot_table";

import get_status_admin from "./get_status_admin";
import update_status_project from "./update_status_project";
import update_commission from "./update_commission";
import update_bpcommission from "./update_bpcommission";

import get_responsable_client from "./get_project_responsable_client";
import update_responsable_client from "./update_responsable_client";

import get_responsable_partner from "./get_project_responsable_partner";
import update_responsable_partner from "./update_responsable_partner";

import update_cancel_invite from "./update_cancel_invite";
import refresh_project from "./get_refresh_project";

import get_clients_add from "./get_clients_add";
import update_client_add from "./update_add_client";

import update_stats_partner from "./get_refresh_stats";
import get_partners_exception from "./get_partners_exception";
import add_partners_exception from "./add_client_exception_partner";
import add_produtcs_exception from "./add_client_exception_product";
import remove_partners_exception from "./delete_client_exception_partner";
import remove_product_exception from "./delete_client_exception_product";

import delete_po from "./delete_po";
import delete_installment from "./delete_installment";
import delete_invoice from "./delete_invoice";

import delete_extra_v2 from "./delete_extra";

import get_knowledge from "./get_knowledge";
import create_scope from "./create_scope";
import update_scope from "./update_scope";
import delete_scope from "./delete_scope";
import create_section from "./create_section";
import update_section from "./update_section";
import delete_section from "./delete_section";
import create_categories from "./create_categories";
import update_categories from "./update_categories";
import delete_category from "./delete_category";
import create_content from "./create_content";
import update_content from "./update_content";
import delete_content from "./delete_content";

import update_types from "./update_types";

import update_proposal from "./update_proposal";

import api_update_nfs from "./update-billing-bpnfs";
import api_update_nfs_extra from "./update-billing-bpnfs-extra";

import api_update_nfs_ex from "./update-billing-bpnfs-extraordinario";

import get_supplier_billing_filters from "./get_supplier_billing_filters";
import get_supplier_billing_list from "./get_supplier_billing_list";
import create_supplier_billing from "./create-supplier-billing";
import supplier_billing_notify from "./supplier-billing-notify";
import delete_supplier_billing from "./delete-supplier-billing";

import reset_user from "./reset_user";

import update_master from "./update_usermaster";
import update_rules_proposal from "./update_rules_proposal";
import update_rules_bpay from "./update_rules_bpay";

import fire_pre_match from "./fire_pre_match";

import get_brands from "./get_brands";

import api_update_trained from "./update_client_trained";
import api_update_user_trained from "./update_user_trained";

export const BP_USER_GET = "bp_user_get";
export const BP_DOMAIN_GET = "bp_domain_get";
export const BP_USERS_GET = "bp_users_get";
export const BP_BUNDLES_GET = "bp_bundles_get";
export const BP_ASSETS_GET = "bp_assets_get";

export const BP_ASSETS_PARTNERS_GET = "bp_assets_partners_get";

export const BP_ASSETS_CLIENTS_GET = "bp_assets_clients_get";

export const BP_PARTNERS_GET = "bp_partners_get";
export const BP_PARTNER_GET = "bp_partner_get";
export const BP_PARTNER_UPDATE = "bp_partner_update";

export const BP_DASHBOARD_GET = "bp_dashboard_get";
export const BP_UPDATE_EVAL = "bp_update_eval";
export const BP_USER_CREATE = "bp_user_create";

export const BP_CLIENT_GET = "bp_client_get";
export const BP_CLIENT_LIST_GET = "bp_client_list_get";
export const BP_CLIENT_UPDATE = "bp_client_update";
export const BP_IND_GET = "bp_ind_get";

export const BP_PROJECT_GET = "bp_project_get";
export const BP_PROJECTS_GET = "bp_projects_get";
export const BP_PROJECT_UPDATE = "bp_project_update";

export const BP_PROJECTS_KANBAN_GET = "bp_projects_kanban_get";

export const BP_LOGIN_CLIENTS_LOGINS_GET = "bp_clients_login_get";
export const BP_LOGIN_CLIENTS_USERS_GET = "bp_clients_users_get";

export const BP_LOGIN_DELETE_ITENS = "bp_clients_delete_itens";

export const BP_PARTNERS_LOGINS_GET = "bp_partners_login_get";
export const BP_PARTNERS_USERS_GET = "bp_partners_login_get";

export const BP_UPDATE = "bp_update";
export const BP_MEETINGS = "bp_meetings";

export const BP_ADMIN_COUNTRYS_GET = "bp_countrys";
export const BP_ADMIN_STATUS_SUMMARY = "bp_status_summary";

export const BP_ADMIN_STATUS_PROJECT = "bp_admin_status_project";

export const BP_API = {
  get: get_bpool,
  create_user: create_user,
  get_domain: get_domain,
  get_users: get_users,
  get_dashboard: get_dashboard,
  get_partners: get_partners,
  get_partner: get_partner,
  get_bundles: get_bundles,
  get_assets: get_assets,
  update_evaluation: update_evaluation,
  update_partner: update_partner,
  update_partner_preselection: update_partner_preselection,
  update_partner_final_approval: update_partner_final_approval,
  get_clients: get_clients,
  get_client: get_client,
  update_client: update_client,
  client_upload: client_upload,
  get_project: get_project,
  get_projects: get_projects,
  get_projects_full: get_projects_full,
  get_projects_kanban: get_projects_kanban,
  update_match: update_match,
  get_candidates: get_candidates,
  get_clients_logins: get_clients_logins,
  get_partners_logins: get_partners_logins,
  get_clients_users: get_clients_users,
  get_partners_users: get_partners_users,
  clone_user_client: clone_user_client,
  clone_user_partner: clone_user_partner,
  clone_user_partner: clone_user_partner,
  reset_user_client: reset_user_client,
  reset_user_partner: reset_user_partner,
  delete_user_client: delete_user_client,
  delete_user_partner: delete_user_partner,
  delete_itens: delete_itens,
  create_billing_installment: create_billing_installment,
  create_production_billing_installment: create_production_billing_installment,
  create_extra_billing_installment: create_extra_billing_installment,
  update_billing_installment_invoice_due_date:
    update_billing_installment_invoice_due_date,
  update_billing_installment_invoice_payment_date:
    update_billing_installment_invoice_payment_date,
  delete_project: delete_project,
  cancel_project: cancel_project,
  get_assets_partners: get_assets_partners,
  update_asset: update_asset,
  approve_or_refuse: approve_or_refuse,
  update_settings: update_settings,
  // Quotacao de evento
  create_event_quotation_item_comment: create_event_quotation_item_comment,
  delete_event_quotation_item_comment: delete_event_quotation_item_comment,
  send_event_quotation_item_notification:
    send_event_quotation_item_notification,
  update_event_quotation_item_status: update_event_quotation_item_status,
  export_event_quotation: export_event_quotation,
  // Filters
  get_filters_projects: get_filters_projects,
  get_projects_list: get_projects_list,
  // Clients
  get_filters_clients: get_filters_clients,
  get_clients_list: get_clients_list,
  // Partners
  get_filters_partners: get_filters_partners,
  get_partners_list: get_partners_list,
  get_projects_and_extras_list: get_projects_and_extras_list,
  // Extras
  get_filters_extras: get_filters_extras,
  get_extras_list: get_extras_list,
  delete_extra: delete_extra,
  cancel_extra: cancel_extra,
  // Client Settings
  create_client_settings_project_rule: create_client_settings_project_rule,
  delete_client_settings_project_rule: delete_client_settings_project_rule,
  create_client_settings_extra_rule: create_client_settings_extra_rule,
  delete_client_settings_extra_rule: delete_client_settings_extra_rule,
  // Reports
  reports: reports,
  // Meetings
  meeting_invites: meeting_invites,
  // DASH2
  get_countrys: get_countrys,
  status_summary: status_summary,
  feedback_summary: feedback_summary,
  financial: financial,
  financial_historic: financial_historic,
  pivot: pivot,
  pivot_table: pivot_table,
  get_partners_vips: get_partners_vips,
  create_partners_vips: create_partners_vips,
  get_assets_client_source: get_assets_client_source,
  get_assets_client: get_assets_client,
  update_client_asset: update_client_asset,
  create_client_asset: create_client_asset,
  delete_client_asset: delete_client_asset,
  get_bundle_client_source: get_bundle_client_source,
  get_bundle_client: get_bundle_client,
  delete_client_bundle: delete_client_bundle,
  create_client_bundle: create_client_bundle,
  update_client_bundle: update_client_bundle,
  reset: reset,
  generate_financial: generate_financial,
  generate_financial_nfs: generate_financial_nfs,
  get_filters_transfer: get_filters_transfer,
  get_invoices_list: get_invoices_list,
  generate_invoices: generate_invoices,
  export_bundle: export_bundle,
  get_filters_milestones: get_filters_milestones,
  get_milestone_list: get_milestone_list,
  update_market: update_market,

  // Combos ADMIN
  get_status_admin: get_status_admin,
  update_status_project: update_status_project,
  update_commission: update_commission,
  get_responsable_client: get_responsable_client,
  update_responsable_client: update_responsable_client,
  get_responsable_partner: get_responsable_partner,
  update_responsable_partner: update_responsable_partner,
  update_cancel_invite: update_cancel_invite,
  refresh_project: refresh_project,
  get_clients_add: get_clients_add,
  update_client_add: update_client_add,
  update_stats_partner: update_stats_partner,
  get_partners_exception: get_partners_exception,
  add_partners_exception: add_partners_exception,
  remove_partners_exception: remove_partners_exception,
  add_produtcs_exception: add_produtcs_exception,
  remove_product_exception: remove_product_exception,
  delete_po: delete_po,
  delete_installment: delete_installment,
  delete_invoice: delete_invoice,
  delete_extra: delete_extra_v2,
  get_knowledge: get_knowledge,
  create_scope: create_scope,
  update_scope: update_scope,
  create_section: create_section,
  update_section: update_section,
  create_categories: create_categories,
  update_categories: update_categories,
  update_content: update_content,
  create_content: create_content,
  delete_scope: delete_scope,
  delete_section: delete_section,
  delete_category: delete_category,
  delete_content: delete_content,
  update_types: update_types,
  get_filters_audios: get_filters_audios,
  get_audios_list: get_audios_list,
  get_project_audio: get_project_audio,
  update_proposal: update_proposal,
  get_possible_matches: get_possible_matches,
  update_bpnfs: api_update_nfs,
  update_bpnfs_extra: api_update_nfs_extra,
  update_bpnfs_ex: api_update_nfs_extra,
  update_nfs_ex: api_update_nfs_ex,

  // Fat Extraordinario
  get_supplier_billing_filters: get_supplier_billing_filters,
  get_supplier_billing_list: get_supplier_billing_list,
  create_supplier_billing: create_supplier_billing,
  supplier_billing_notify: supplier_billing_notify,
  delete_supplier_billing: delete_supplier_billing,

  update_master: update_master,
  fire_pre_match: fire_pre_match,
  reset_user: reset_user,
  update_partner_prov: update_partner_prov,
  get_industries: get_industries,
  send_email_vip: send_email_vip,
  get_invoicetypes: get_invoicetypes,
  get_brands: get_brands,
  update_bpcommission: update_bpcommission,
  update_rules_proposal: update_rules_proposal,
  update_rules_bpay: update_rules_bpay,
  api_update_trained: api_update_trained,
  api_update_user_trained: api_update_user_trained,
  get_suppliers: api_get_suppliers,
  get_suppliers_logins: get_suppliers_logins,
  get_suppliers_users: get_suppliers_users,
  clone_user_supplier: clone_user_supplier,
  create_client_settings_bpay_rule: create_client_settings_bpay_rule,
  delete_client_settings_bpay_rule: delete_client_settings_bpay_rule
};
