import React from 'react';

import { I18n } from 'aws-amplify';

import Dropzone from 'react-dropzone';

import './photos-form.css';

export default class PhotosForm extends React.Component {

    constructor(props) {
        super();

        this.state = {};
    }



    onDrop(files, rejectedFiles) {

        //Acertar - verificar se o problema é o tipo ou o tamanho
        //travar em todos os lugares pra subir de um e um pra facilitar a validação
        // if (rejectedFiles && rejectedFiles.length > 0)
        //     alert("Arquivo não suportado. Somente arquivos do tipo imagens são aceitos (jpg ou png).");

        files = files.map(file => {

            const item = Object.assign(file, { preview: URL.createObjectURL(file) });

            this.props.addUpload(item);
        });
    }

    onCancel() {

    }

    render() {

        const limitSize = 100000000; //100mb

        //defaults
        let limitTo = 0;
        let allowMultiple = true;
        let accept = "*";

        let {limitToUpload,allowMultipleSelect, allowOnlyImages} = this.props;

        if(limitToUpload && limitToUpload !== undefined)
            limitTo = limitToUpload;

        if(allowMultipleSelect && allowMultipleSelect !== undefined)
            allowMultiple = allowMultipleSelect;

        if(allowOnlyImages && allowOnlyImages !== undefined)
            if (allowOnlyImages === true)
                accept = "image/*";


        return (
            <div>

                <div>

                    {limitTo == 0 //pode-se subir quantos arquivos quiser no total - Dropzone sempre ativo
                            && <Dropzone className="dropzone"
                            multiple={false}
                            accept={accept}
                            onDrop={this.onDrop.bind(this)}
                            onFileDialogCancel={this.onCancel.bind(this)}
                            activeClassName="has-background-light" >

                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <p className="has-text-centered">
                                    {!this.props.uploading && <span>{I18n.get("Clique ou arraste a imagem aqui.")}</span>}
                                    {this.props.uploading && <a className="button is-loading is-black" style={{ background: 'transparent' }}></a>}
                                </p>

                                </div>
                            )}

                </Dropzone>}

                    {limitTo > 0 //somente um arquivo no total é permitido - Dropzone some depois do limite colocado
                            && this.props.photos
                            && this.props.photos.length < limitTo
                            && <Dropzone className="dropzone"
                                        multiple={false}
                                        accept={accept}
                                        onDrop={this.onDrop.bind(this)}
                                        onFileDialogCancel={this.onCancel.bind(this)}
                                        activeClassName="has-background-light" >

                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps()}>
                                            <input {...getInputProps()} />

                                            <p className="has-text-centered">
                                                {!this.props.uploading && <span>{I18n.get("Clique ou arraste a imagem aqui.")}</span>}
                                                {this.props.uploading && <a className="button is-loading is-black" style={{ background: 'transparent' }}></a>}
                                            </p>

                                            </div>
                                        )}
                            </Dropzone>

                }



                </div>

                {this.props.photos && this.props.photos.map((item, index) =>
                    <figure style={{ textAlign: "right" }} key={index} className="image is-64x64">
                        <a onClick={(e) => this.props.del(index, e)}>
                            <i style={{ color: "#666", marginBottom: 7 }} className="fa fa-times"></i>
                        </a>
                        <img src={item} />
                    </figure>
                )}

                {this.props.errors.photos && <p className="help is-danger">
                    {this.props.errors.photos.errorMessage}
                </p>}

            </div>
        );
    }
}
