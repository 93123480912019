import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { Row, Col } from 'antd';
import Main from '../../../../components/UI/Main';
import { Input } from '../../../../components/UI/Input';
import { ButtonBpool } from '../../../../components/UI/ButtonBpool';
import PasswordChecklist from 'react-password-checklist';
import { useRegistrationFlowLeader } from '../../../../hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook';

import * as S from "./styles";

export const ContentPage = ({ contextPage, onChangeInfo, sendOk }) => {
  const { changePasswordLeader } = useRegistrationFlowLeader();
  const [inputsPasswords, setInputsPasswords] = useState({passWord: "", confirmPassWord: ""});
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [isDisabledButtonSend, setIsDisabledButtonSend] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (passwordIsValid) {
      setIsDisabledButtonSend(false);
    } else {
      setIsDisabledButtonSend(true);
    }
  }, [passwordIsValid]);

  const onChangePasswordInputForm = (id, value) => {
    setInputsPasswords({
      ...inputsPasswords,
      [id]: value ? value : '',
    });
  };

  const send = async () => {
    setIsSending(true)
    const response = await changePasswordLeader(inputsPasswords);
    if (response.success) {
      sendOk(true);
      setInputsPasswords({passWord: "", confirmPassWord: ""})
    } else {
      sendOk(false);
    }
    setIsSending(false)
  };

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get('Change password')}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  'Change password of login.'
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xs={24} sm={12}>
            <Input
              password
              placeholder={I18n.get('Password')}
              required
              value={inputsPasswords.passWord}
              onChange={(value) =>
                onChangePasswordInputForm('passWord', value)
              }
            />
            <div />
            <PasswordChecklist
              rules={[
                'minLength',
                'specialChar',
                'number',
                'capital',
                'match',
              ]}
              minLength={8}
              value={inputsPasswords.passWord}
              valueAgain={inputsPasswords.confirmPassWord}
              messages={{
                minLength: I18n.get('Password has at least 8 characters.'),
                specialChar: I18n.get('Password has special characters.'),
                number: I18n.get('Password has a number.'),
                capital: I18n.get('Password has a capital letter.'),
                match: I18n.get('Passwords match.'),
              }}
              onChange={(isValid) => setPasswordIsValid(isValid)}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Input
              password
              placeholder={I18n.get('Confirm password')}
              required
              value={inputsPasswords.confirmPassWord}
              onChange={(value) =>
                onChangePasswordInputForm('confirmPassWord', value)
              }
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={24} sm={24}>
            <ButtonBpool
              text={I18n.get('Save')}
              theme="primary"
              full
              loading={isSending}
              onClick={send}
              disabled={isDisabledButtonSend}
            />
          </Col>
        </Row>
      </Main>
    </>
  );
};
