import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperRegister";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";
import TableCase from "../components/tableCases";
import TablePortfolio from "../components/tablePortfolio";

import { PARTNER_API } from "../../../actions/partner/actions";

import FormAddCase from "../components/formAddCase";
import FormEditCase from "../components/formEditCase";
import FormPortfolio from "../components/formPortfolio";

import { TitleBorder, TitleMiddleLine } from "../../components/Titles";

import styled from "styled-components";

import { PartnerStatus } from "../../../model";

import {
  Row,
  Col,
  Input,
  Select,
  Tag,
  Icon,
  notification,
  Button,
  Drawer,
  Form,
} from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: 60px;
  }
`;

const ContentAba = styled.div`
  .BtnAction {
    margin-bottom: 20px;
    text-align: right;
  }
`;

const BoxAba = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 30px 20px;
  background: #ececec;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s ease;
  border: solid 4px #ececec;
  position: relative;
  .ant-tag {
    position: absolute;
    right: -15px;
    top: -10px;
    font-weight: bold;
  }

  p {
    cursor: pointer;
  }

  &:hover,
  &.active {
    background: #dcdcdc;
    border-color: #777;
  }

  .icone {
    margin-bottom: 10px;
    font-size: 40px;
  }
`;

const RowAba = styled(Row)`
  margin: 50px 0;
`;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClient: (data) => {
      return dispatch(PARTNER_API.create_client(data));
    },
    delClient: (data) => {
      return dispatch(PARTNER_API.delete_client(data));
    },
    addCase: (data) => {
      return dispatch(PARTNER_API.create_case(data));
    },
    updateCase: (data) => {
      return dispatch(PARTNER_API.update_case(data));
    },
    delCase: (data) => {
      return dispatch(PARTNER_API.delete_case(data));
    },
    updateOrderCase: (data) => {
      return dispatch(PARTNER_API.update_order_case(data));
    },
    upload: (data) => {
      return PARTNER_API.upload(data);
    },
    save: (data) => {
      dispatch(PARTNER_API.update_client(data));
    },
    addPortfolio: (data) => {
      dispatch(PARTNER_API.create_portfolio(data));
    },
    deletePortfolio: (data) => {
      dispatch(PARTNER_API.delete_portfolio(data));
    },
    updatePortfolio: (data) => {
      dispatch(PARTNER_API.update_portfolio(data));
    },
  };
};

class Works extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      showAddCase: false,
      showEditCase: false,
      showAddPortfolio: false,
      editCaseItem: {},
      editPortfolioItem: {},
      listImg: [],
      scape: "",
      editSelected: false,
    };
  }

  closeDrawer = () => {
    this.setState({
      showAddCase: false,
      showEditCase: false,
      showAwards: false,
      showAddCause: false,
      showAddPortfolio: false,
      editAwardItem: {},
      editCauseItem: {},
      editPortfolioItem: {},
      editCaseItem: {},
    });
  };

  handleEditPortfolio = (item) => {
    this.setState({
      editPortfolioItem: item,
      type: item.type,
    });

    setTimeout(() => {
      this.showAddPortfolio();
    }, 200);
  };

  showAddCase = () => {
    this.setState({
      showAddCase: true,
    });
  };

  hideAddCase = () => {
    this.setState({
      showAddCase: false,
    });
  };

  showAddPortfolio = (type) => {
    this.setState({
      showAddPortfolio: true,
    });
  };

  showEditCase = () => {
    this.setState({
      showEditCase: true,
    });
  };

  hideEditCase = () => {
    this.setState({
      showEditCase: false,
    });

    setTimeout(() => {
      this.setState({
        listImg: [],
        scape: "",
      });
    }, 400);
  };

  handleEditCase = (item) => {
    this.setState({
      editCaseItem: {
        ...item,
        scape: Math.random().toString(36).substring(7),
      },
      editSelected: true,
    });

    setTimeout(() => {
      this.showEditCase();
    }, 200);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.partner.cases &&
      prevProps.partner.cases &&
      this.props.partner.cases.length > prevProps.partner.cases.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("Case foi adicionado ao seu cadastro"),
        duration: 3,
      });

      this.hideAddCase();
    }

    if (
      this.props.partner.cases &&
      prevProps.partner.cases &&
      this.props.partner.cases.length < prevProps.partner.cases.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("Case foi removido do seu cadastro"),
        duration: 3,
      });
    }

    if (
      this.props.partner.cases &&
      prevProps.partner.cases &&
      this.props.partner.cases != prevProps.partner.cases &&
      this.state.editSelected
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("Case foi atualizado"),
        duration: 3,
      });

      this.setState({
        editSelected: false,
      });

      this.hideEditCase();
    }

    // PORTFOLIO

    if (
      this.props.partner.portfolios &&
      prevProps.partner.portfolios &&
      this.props.partner.portfolios.length > prevProps.partner.portfolios.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("Portfolio foi adicionado ao seu cadastro"),
        duration: 3,
      });

      this.setState({
        editPortfolioItem: {},
      });

      this.closeDrawer();
    }

    if (
      this.props.partner.portfolios &&
      prevProps.partner.portfolios &&
      this.props.partner.portfolios.length < prevProps.partner.portfolios.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("Portfolio foi removido do seu cadastro"),
        duration: 3,
      });
    }

    if (
      this.props.partner.portfolios &&
      prevProps.partner.portfolios &&
      this.props.partner.portfolios != prevProps.partner.portfolios &&
      Object.keys(this.state.editPortfolioItem).length > 0
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("Portfolio foi atualizado"),
        duration: 3,
      });

      this.setState({
        editPortfolioItem: {},
      });

      this.closeDrawer();
    }
  }

  render() {
    const { partner } = this.props;

    if (
      this.props.partner &&
      this.props.partner.status > PartnerStatus.Incomplete
    ) {
      return (
        <Redirect
          to={`/parceiro/obrigado-pre-cadastro/${this.props.partner.partnerId}`}
        />
      );
    }

    //TODO: Find better way... GAMBI!
    let menu = [...constants.MENU_PRE_SIGNUP];
    for (let key in menu) {
      menu[key].label = I18n.get(menu[key].label);
    }

    return (
      <AppWrapperSimple>
        <SubHeader
          titlePage={I18n.get("Pré-cadastro")}
          itensMenu={menu}
          urlId={this.props.match.params.partnerId}
          activeItem={4}
        />
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("4 de 5")}</TitleBorder>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <TitleMiddleLine>
                  {I18n.get("Trabalhos realizados. Insira pelo menos um")}
                </TitleMiddleLine>
              </Col>
              <Col sm={24}>
                <p>
                  {I18n.get(
                    "Selecione nos botões abaixo, os campos que você gostaria de abrir para inserir mais detalhes do trabalho da sua empresa. Quanto mais, melhor."
                  )}
                </p>
              </Col>
            </Row>
            <RowAba>
              <Col sm={6}>
                <BoxAba
                  onClick={() => this.setState({ activeTab: 1 })}
                  className={this.state.activeTab == 1 ? "active" : ""}
                >
                  <Tag color="#000">
                    {partner.cases && partner.cases.length}
                  </Tag>
                  <p className="icone">
                    <Icon type="star" theme="filled" />
                  </p>
                  <p>{I18n.get("Cases")}</p>
                </BoxAba>
              </Col>
              <Col sm={6}>
                <BoxAba
                  onClick={() => this.setState({ activeTab: 2 })}
                  className={this.state.activeTab == 2 ? "active" : ""}
                >
                  <Tag color="#000">
                    {partner.portfolios &&
                      this.props.partner.portfolios.filter(
                        (item) => item.type == 1
                      ).length}
                  </Tag>
                  <p className="icone">
                    <Icon type="bulb" />
                  </p>
                  <p>{I18n.get("PORTFOLIO")}</p>
                </BoxAba>
              </Col>
              {/*<Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTab: 3 })} className={this.state.activeTab == 3 ? "active" : ""}>
                                    <Tag color="#000">0</Tag>
                                    <p className="icone">
                                        <Icon type="trophy" theme="filled" />
                                    </p>
                                    <p>
                                        PRÊMIOS
                                    </p>
                                </BoxAba>
                            </Col>
                            <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTab: 4 })} className={this.state.activeTab == 4 ? "active" : ""}>
                                    <Tag color="#000">0</Tag>
                                    <p className="icone">
                                        <Icon type="flag" theme="filled" />
                                    </p>
                                    <p>
                                        CAUSAS
                                    </p>
                                </BoxAba>
                            </Col>*/}
            </RowAba>

            {this.state.activeTab == 1 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <p className="BtnAction">
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => this.showAddCase()}
                      >
                        {I18n.get("+ Adicionar case")}
                      </Button>
                    </p>
                    <TableCase
                      data={partner.cases && partner.cases}
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                      partnerId={
                        this.props.partner && this.props.partner.partnerId
                      }
                      order={this.props.updateOrderCase}
                      delete={this.props.delCase}
                      edit={this.handleEditCase}
                      specialismsBP={
                        this.props.bp.specialisms && this.props.bp.specialisms
                      }
                      specialisms={partner.specialisms && partner.specialisms}
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            {this.state.activeTab == 2 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <p className="BtnAction">
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => this.showAddPortfolio(1)}
                      >
                        {I18n.get("+ Adicionar portfolio")}
                      </Button>
                    </p>
                    <TablePortfolio
                      data={
                        partner.portfolios &&
                        partner.portfolios.filter((item) => item.type == 1)
                      }
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                      partnerId={
                        this.props.partner && this.props.partner.partnerId
                      }
                      delete={this.props.deletePortfolio}
                      edit={this.handleEditPortfolio}
                      specialismsBP={
                        this.props.bp.specialisms && this.props.bp.specialisms
                      }
                      specialisms={partner.specialisms && partner.specialisms}
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            {this.state.activeTab == 3 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <p className="BtnAction">
                      <Button type="primary" shape="round">
                        {I18n.get("+ Adicionar prêmio")}
                      </Button>
                    </p>
                    <TableCase
                      order={this.props.updateOrderCase}
                      data={[]}
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            {this.state.activeTab == 4 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <p className="BtnAction">
                      <Button type="primary" shape="round">
                        {I18n.get("+ Adicionar causa")}
                      </Button>
                    </p>
                    <TableCase
                      order={this.props.updateOrderCase}
                      data={[]}
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            <Row className="RowSection">
              <Col sm={8}></Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Link
                  to={`/parceiro/pre-cadastro/expertises/${this.props.match.params.partnerId}`}
                >
                  <Button shape="round">{I18n.get("Voltar")}</Button>
                </Link>
              </Col>
              <Col sm={18}></Col>
              <Col sm={3}>
                <Link
                  to={`/parceiro/pre-cadastro/clientes/${this.props.match.params.partnerId}`}
                >
                  <Button type="primary" shape="round">
                    {I18n.get("Avançar")}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Main>
        </ContainerPage>

        <Drawer
          title={I18n.get("Adicionar Case")}
          placement="right"
          closable={true}
          onClose={() => this.hideAddCase()}
          destroyOnClose={true}
          visible={this.state.showAddCase}
          width="60%"
        >
          <FormAddCase
            specialisms={partner.specialisms && partner.specialisms}
            specialismsBP={
              this.props.bp.specialisms && this.props.bp.specialisms
            }
            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
            addCase={this.props.addCase}
            onCancel={this.hideAddCase}
            cancel={this.hideAddCase}
            upload={this.props.upload}
            id={this.props.partner.partnerId}
            errors={this.props.partner.errors && this.props.partner.errors}
            type={1}
          />
        </Drawer>

        <Drawer
          title={I18n.get("Editar Case")}
          placement="right"
          forceRender={true}
          closable={true}
          onClose={() => this.hideEditCase()}
          destroyOnClose={true}
          visible={this.state.showEditCase}
          width="60%"
        >
          <FormEditCase
            specialisms={partner.specialisms && partner.specialisms}
            specialismsBP={
              this.props.bp.specialisms && this.props.bp.specialisms
            }
            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
            updateCase={this.props.updateCase}
            onCancel={this.hideEditCase}
            upload={this.props.upload}
            id={this.props.partner.partnerId}
            errors={this.props.partner.errors && this.props.partner.errors}
            type={1}
            item={this.state.editCaseItem}
            listImg={this.state.listImg}
          />
        </Drawer>

        <Drawer
          title={
            Object.keys(this.state.editPortfolioItem).length > 0
              ? I18n.get("Editar portfolio")
              : I18n.get("Adicionar portfolio")
          }
          placement="right"
          closable={true}
          onClose={() => this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.showAddPortfolio}
          width="50%"
        >
          <FormPortfolio
            specialisms={partner.specialisms && partner.specialisms}
            specialismsBP={
              this.props.bp.specialisms && this.props.bp.specialisms
            }
            action={this.props.addPortfolio}
            onCancel={this.closeDrawer}
            cancel={this.closeDrawer}
            update={this.props.updatePortfolio}
            partnerId={this.props.partner.partnerId}
            item={this.state.editPortfolioItem}
            errors={this.props.partner.errors && this.props.partner.errors}
            type={1}
            forceRender={true}
            locale={this.props.auth.locale}
          />
        </Drawer>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Works);
