import React from "react";

import TableBillingItem from "../../../v2/shared/componets/TableBillingItem";

import Installments from "./_billing-installments";

export default class BillingDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      installment: {},
      task: {},
    };
  }

  onSelectInstallment(item, e) {
    this.setState({ installment: item });
  }

  render() {
    const { project = {}, billing = {} } = this.props;

    const category =
      this.props?.bp &&
      this.props?.bp.sectorsFlat &&
      this.props?.bp?.sectorsFlat.filter(
        (item) => item.code == project.categoryId
      )[0];

    const { billingId, projectId } = this.props.match.params;

    return (
      <div className="content">
        {/* <BillingItem billing={billing} currency={project.billingCurrency} /> */}

        {/* <p style={{ textAlign: "right", marginBottom: "20px" }}>
          <Link
            to={`/parceiro/faturamento/informacoes/${projectId}/po/${billingId}`}
            className="button bt-bpool"
          >
            {I18n.get("Mais informações")}
          </Link>
        </p> */}

        <TableBillingItem
          billing={billing}
          currency={project.billingCurrency}
          paymentTermDays={project?.paymentTermDays}
          category={category && category?.label}
          bPoolOffices={this.props?.bp?.bPoolOffices || []}
          project={project}
        />

        <br />
        <br />

        <Installments
          {...this.props}
          billing={billing}
          currency={project.billingCurrency}
        />
      </div>
    );
  }
}
