import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_CLIENT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_user_trained = (data) => {

    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.put(`${Config.API_BASE_URL}/bp/${data.type}/logins/set-trained`, data, config)
                .then(response => {



                    return response.data;
                })
                .catch(error => {



                    return error.response.data;
                });

        }).catch(error => {
            // dispatch({ type: EXPIRED });
        });
    };
}


export default api_update_user_trained;
