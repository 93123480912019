import styled from "styled-components";
import { Table as TableAntd, Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div``;

export const Table = styled(TableAntd)`
  .ant-table-thead > tr > th {
    background: #000000;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th {
    vertical-align: middle;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }

  .ant-table-header-column {
    span {
      text-transform: uppercase;
    }
  }

  .ant-table-tbody > tr.supplier-duplicated {
    background-color: #fff1f0;
    font-weight: 600;

    :hover {
      font-weight: 600;
      background-color: #fff1f0;
    }
  }

  .ant-table-footer {
    border: 1px solid;
    border-color: #ccc;
    position: relative;
    top: -1px;
    padding-right: 105px;
    @media (min-width: 1442px) {
      padding-right: 110px;
    }
    @media (min-width: 1471px) {
      padding-right: 130px;
    }
  }
`;

export const Totals = styled.div`
  margin-top: 15px;
  width: 100%;
  height: 65px;
  background-color: #f5f5f5;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
`;

export const TotalInfo = styled.div`
  ${(props) => (props.isLoading ? null : "display: flex;")};
  ${(props) => (props.isLoading ? null : "flex-direction: column;")};
  ${(props) => (props.isLoading ? null : "justify-content: center;")};

  .text-total {
    font-size: 12px;
    line-height: 20px;
  }
  .value-total {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-totals {
    height: 20px;
    margin-top: 10px !important;

    li {
      width: 120px !important;
      height: 100%;
      margin-top: 5px !important;

      :first-child {
        width: 75px !important;
      }
    }
  }

  .skeleton-totals.right {
    margin-left: 100px;
  }
`;

export const ButtonDelete = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background: red;
  color: #fff;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FooterTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    span {
    }
    strong {
      margin-left: 24px;
    }
  }
`;
