import React from 'react';

import { I18n } from 'aws-amplify';

import FormatCurrency from '../ui/format-currency';

class Heading extends React.Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    render() {

        const { bPitch = {}, biddingPhaseQuotationDeadline, currency = {} } = this.props;

        return (
            <div className="heading-mobile">

                <p className="textInvite">
                    {I18n.get("Você foi convidado para a fase de proposta de um processo de BPitch. Verifique o histórico de briefing, escopo e KPIs. A data atualizada de apresentação da proposta é")}
                    &nbsp; {biddingPhaseQuotationDeadline}
                </p>

                <br /><br />

                <h1 className="title">{I18n.get("Briefing")}</h1>

                <br /><br />

                <p>
                    <strong>{I18n.get("Nome do Projeto:")}</strong> &nbsp; {this.props.projectName}
                </p>
                <p>
                    <strong>{I18n.get("Marca / Categoria:")}</strong> &nbsp; {this.props.brandName} - {this.props.categoryName}
                </p>
                <p>
                    <strong>{I18n.get("Budget:")}</strong> &nbsp;
                    {bPitch && <FormatCurrency amount={bPitch.budget} currency={currency} />}
                </p>
                <p>
                    <strong>{I18n.get("Prazo pagamento:")}</strong> {this.props.paymentTermDays} {I18n.get("dias")}</p>

                <br /><br />
            </div>
        );
    }
}

export default Heading;
