import React from 'react';

import { I18n } from 'aws-amplify';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import Steps from './steps';


class ProjectOnGoingEnded extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount() {
    }

    submitForm(e) {

    }

    render() {


        return (
            <FillForm>
                <div className="hero-body has-background-white project-ongoing-header">
                    <div className="container">
                        <div className="container-nav-progress">
                            <div className="col">
                                <p className="title">{I18n.get("Projeto em Andamento")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="col">
                                <Steps active={1} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []}  />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-ongoing">

                    <div className="container client-container-1">

                        <br />
                        <br />

                        <div className="columns">

                            <div className="column is-offset-1 is-6">

                                <h1 className="subtitle is-4 is-marginless">{I18n.get("Que Pena!")}</h1>
                                <br />
                                <p>{I18n.get("Recebemos o seu cancelamento.")}</p>

                                <p style={{ width: '80%' }}>{I18n.get("Já avisamos o seu parceiro.")}</p>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />


                            </div>

                        </div>

                    </div>

                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(ProjectOnGoingEnded);
