import styled from "styled-components";
import { Modal } from "antd";

export const Wrapper = styled.div``;
export const WrapperModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 40px;
  }
`;
export const TermsWrapper = styled.div`
  height: 500px;
  overflow-x: hidden;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const InputLabel = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 8px;
  display: flex;

  span {
    color: #ff0000;
    font-size: 22px;
  }
`;

export const TitleSectionForm = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
  width: max-content;
`;

export const RequirementsPassword = styled.ul`
  margin-top: 10px;
  li {
    color: #515151;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    list-style: initial;
    margin-left: 15px;

    :first-child {
      list-style: none;
      margin-left: 0;
    }
  }
`;

export const ConfirmPassError = styled.div`
  margin-top: 10px;
  color: #ff0000;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
`;

export const WrapperAgreeTerms = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;

  .ant-checkbox-wrapper {
    margin-top: 0;

    .ant-checkbox-inner {
      border-radius: 6px;
    }
  }
`;

export const LabelAgreeTerms = styled.div`
  span {
    font-size: 14px;
    color: #1883ff;
    font-weight: 700;
    margin-left: 8px;
  }
  button {
    background: transparent;
    font-size: 14px;
    color: #333333;
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
    border: none;
  }
`;
