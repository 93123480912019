import axios from 'axios';
import { Auth } from 'aws-amplify';
import Config from '../../../../config';

axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';

export const usePartners= () => {

  const deleteVip = async ({ partnerId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const dataSend = {
      partnerId: partnerId,
    };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/Admin/Partner`,
      {
        data: dataSend,
        ...config,
      }
    );

    return data;
  };



  

  return {
    deleteVip,
  };

};
