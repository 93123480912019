import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useMilestones = () => {
  const getMilestones = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/client-project/${id}/milestones`,
      config
    );

    return data;
  };

  const addMilestones = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project/partner/milestones`,
      infos,
      config
    );

    return data;
  };

  const deleteMilestones = async (info) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const dataSend = {
      id: info.milestoneId,
      projectId: info.projectId,
    };

    const data = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project/partner/milestones`,
      {
        data: dataSend,
        ...config,
      }
    );

    return data;
  };

  const updateDelivery = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/milestones/delivery`,
      infos,
      config
    );

    return data;
  };

  const updateIsMensal = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/client-project/partner/milestones/IsMonthFee`,
      infos,
      config
    );

    return data;
  };

  const uploadFile = async (file) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/client-project/partner/milestones/file`,
      file,
      config
    );

    return data;
  };

  const deleteFileMilestones = async (info) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    // const dataSend = {
    //   id: info.id,
    //   projectId: info.projectId,
    //   fileId: info.fileId,
    // };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/client-project/partner/milestones/file`,
      {
        data: info,
        ...config,
      }
    );

    return data;
  };

  const uploadFileMilestonesV2 = async (file) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/partner/milestones/file`,
      file,
      config
    );

    return data;
  };

  const deleteFileMilestonesV2 = async (info) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project/partner/milestones/file`,
      {
        data: info,
        ...config,
      }
    );

    return data;
  };

  return {
    getMilestones,
    addMilestones,
    deleteMilestones,
    updateDelivery,
    updateIsMensal,
    uploadFile,
    deleteFileMilestones,
    uploadFileMilestonesV2,
    deleteFileMilestonesV2,
  };
};
