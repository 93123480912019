import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid;
  border-color: #e9e9e9;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabledTab ? "not-allowed;" : "pointer;")};

  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: ${(props) => (props.disabledTab ? "#e7e7e7" : "#979797;")};
    padding: 10px 30px;

    ${(props) => (props.disabledTab ? "pointer-events: none;" : null)};
    ${(props) => (props.disabledTab ? "cursor: not-allowed;" : null)};
  }

  a.active {
    color: #1883ff;
  }
`;
