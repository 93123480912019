import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink } from 'react-router-dom';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import CheckboxMultiField from '../form/checkbox-multi-field';
import TextField from '../form/text-field';
import SubmitButtonField from '../form/submit-button-field';

import constants from '../../constants';

class ProjectEndForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            projectId: props.match.params.projectId,
            mode: props.mode,
            form: {
                cancelReasons: {
                    label: '',
                    value: props.cancelReasons || [],
                    error: props.errors.cancelReasons,
                    onChange: this.onChangeFormCheckbox.bind(this, 'cancelReasons'),
                },
                cancelOther: {
                    label: '',
                    value: '',
                    error: props.errors.cancelOther,
                    onChange: this.onChangeForm.bind(this, 'cancelOther'),
                    successIcon: false
                },
            },
            task: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {



                if (FormUtils.isFormField(nextState.form[item])) {
                    nextState.form[item].error = this.props.errors[item];
                    nextState.form[item].value = this.props[item] || '';
                }
                else if (typeof nextState.form !== 'undefined') {
                    nextState.form[item] = this.props[item];
                }
            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onChangeFormCheckbox(prop, e) {

        const value = e.target.value;
        const checked = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (checked) {
                nextState.form[prop].value = [...nextState.form[prop].value, value];
            }
            else {
                nextState.form[prop].value = nextState.form[prop].value.filter((v) => v !== value);
            }

            return nextState;
        });
    }

    getData() {

        var data = {
            projectId: this.state.projectId,
            ratings: this.state.ratings
        };


        Object.keys(this.state.form).forEach((item) => {

            if (FormUtils.isFormField(this.state.form[item])) {

                data[item] = this.state.form[item].value;
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.cancel(this.getData());
        });
    }

    render() {

        const cancelOtherIsEnabled = Array.isArray(this.state.form.cancelReasons.value) && this.state.form.cancelReasons.value.indexOf('OTHER') > -1;

        const cancelReasons = reasons.map((item) => {

            if (item.code == 'OTHER'){
                item.field = (<TextField {...this.state.form.cancelOther} disabled={!cancelOtherIsEnabled} />) ;
            }

            return item;
        });



        var errorMap = {
        };

        Object.keys(this.props.project.errors).map((item) => {

            const error = this.props.project.errors[item];

            const key = error.step || 'global';

            if (!errorMap[key]) {
                errorMap[key] = []
            }

            errorMap[key].push(error.errorMessage)
        });

        //TODO: Find better way... GAMBI!
        let reasons = [...constants.PROJECT_CANCEL_REASONS];
        for (let key in reasons) {
            reasons[key].label = I18n.get(reasons[key].label);
        }

        return (
            <div>

                <div className="columns">

                    <div className="column is-10 is-offset-1">


                        <h1 className="title">{I18n.get("Cancelamento do Projeto")}</h1>

                        <br /><br />

                        <p>{I18n.get("Lamentamos que você deseja cancelar o projeto. Podemos saber o motivo?")}</p>

                        <br /><br />

                        <form onSubmit={this.submitForm.bind(this)} style={{ textAlign: 'left' }}>

                            <CheckboxMultiField {...this.state.form.cancelReasons} values={cancelReasons} />

                            <br style={{ clear: 'both' }} /><br />

                            <br /><br />

                            {Object.keys(errorMap).length > 0 && <div className="content is-small">

                                <br /><br />

                                {Object.keys(errorMap).map((item) => {

                                    return (<p>
                                        <span class="icon has-text-danger">
                                            <i class="fas fa-exclamation"></i>
                                        </span>
                                        <ul>
                                            {errorMap[item].map((errorMessage) => (<li>{errorMessage}</li>))}
                                        </ul>
                                    </p>);
                                })}

                                <br />

                            </div>}

                            <div className="columns">

                                <div className="column is-2 is-offset-4">

                                    <NavLink to={`/projetos/${this.props.match.params.projectId}/resumo`} className="" >{I18n.get("< VOLTAR")}</NavLink>

                                </div>

                                <div className="column is-2">
                                    <SubmitButtonField
                                        label={I18n.get("CONFIRMAR CANCELAMENTO")}
                                        onClick={this.submitForm.bind(this)}
                                        task={this.state.task}
                                        suppressLabel={true}
                                    />
                                </div>
                            </div>

                        </form>

                    </div>

                </div>

            </div>
        );
    }
}

export default ProjectEndForm;
