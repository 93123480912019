export const inputsState = {
  website: '',
  instagram: '',
  portfolioURL: '',
  description: '',
  headerImage: '',
  introImage: '',
  introVideo: '',
  linkedin: '',
  logoImage: '',
};
