import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_ASSETS_CLIENTS_GET } from './actions';

import { 
    notification,
} from 'antd';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_delete_asset = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.delete(`${Config.API_BASE_URL}/bp/client/asset-delete/${data.clientId}/${data.assetId}`, config)
                .then(response => {

                    const payload = {
                        clientAssets: response.data,
                        errors: {},
                        task: {
                            result: true
                        }
                    };

                    dispatch({
                        type: BP_ASSETS_CLIENTS_GET,
                        payload: payload
                    })

                    return payload;
                })
                .catch(error => {

                    // Nao faz o dispatch no erro, tratamento direto na tela
                    notification.error({
                        message: 'Algo deu errado!',
                        description: 'Tente novamente mais tarde',
                    });

                    const payload = {
                        errors: error.response.data,
                        task: {
                            result: false
                        }
                    };

                    return payload;

                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_delete_asset;