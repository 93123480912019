import thunk from "redux-thunk";
import {
  applyMiddleware,
  compose,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";

import authReducer from "./reducers/auth-reducer";
import partnerReducer from "./reducers/partner-reducer";
import partnerWhatIfReducer from "./reducers/partner-whatif-reducer";
import clientWhatIfReducer from "./reducers/client-whatif-reducer";
import ideaWhatIfReducer from "./reducers/idea-whatif-reducer";
import clientReducer from "./reducers/client-reducer";
import projectReducer from "./reducers/project-reducer";
import productionReducer from "./reducers/production-reducer";
import clientUserReducer from "./reducers/client-user-reducer";
import leaderReducer from "./reducers/leader-reducer";
import userPartnerReducer from "./reducers/user-partner-reducer";
import referenceReducer from "./reducers/reference-reducer";
import bpReducer from "./reducers/bp-reducer";
import chatReducer from "./reducers/chat-reducer";
import reportReducer from "./reducers/report-reducer";
import extrasReducer from "./reducers/extras-reducer";
import wikisReducer from "./reducers/wikis-reducer";
import supplierReducer from "./reducers/supplier-reducer";

import counterReducer from "./v2/store/reducers/counter";
import projectsReducer from "./v2/store/reducers/projects";
import chatModalReducer from "./v2/store/reducers/chatModal";
import quickMeetingReducer from "./v2/store/reducers/quickMeeting";
import chatNotificationsReducer from "./v2/store/reducers/chatNotifications";
import partnersReducer from "./v2/store/reducers/partners";
import statusBarRegistrationFlowReducer from "./v2/store/reducers/statusBarRegistrationFlow";

const rootReducer = combineReducers({
  auth: authReducer,
  partner: partnerReducer,
  partnerWhatIf: partnerWhatIfReducer,
  clientWhatIf: clientWhatIfReducer,
  ideaWhatIf: ideaWhatIfReducer,
  client: clientReducer,
  client_user: clientUserReducer,
  project: projectReducer,
  production: productionReducer,
  leader: leaderReducer,
  partnerUser: userPartnerReducer,
  reference: referenceReducer,
  bp: bpReducer,
  chat: chatReducer,
  report: reportReducer,
  extras: extrasReducer,
  wikis: wikisReducer,
  counter: counterReducer,
  projectsv2: projectsReducer,
  chatNotifications: chatNotificationsReducer,
  chatModal: chatModalReducer,
  quickMeeting: quickMeetingReducer,
  partners: partnersReducer,
  statusbar: statusBarRegistrationFlowReducer,
  supplier: supplierReducer,
});

const middlewareEnhancer = applyMiddleware(thunk);

const composeWithDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancers = composeWithDevTools(middlewareEnhancer);

export const store = createStore(rootReducer, composedEnhancers);

// export const store = createStore(
//   rootReducer,
//   {
//     auth: {
//       init: true,
//     },
//     partner: {
//       errors: {},
//       task: {},
//     },
//     partnerWhatIf: {
//       errors: {},
//       task: {},
//     },
//     clientWhatIf: {
//       errors: {},
//       task: {},
//     },
//     ideaWhatIf: {
//       errors: {},
//       task: {},
//     },
//     client: {
//       socialURLs: [],
//       errors: {},
//       task: {},
//     },
//     client_user: {
//       errors: {},
//       task: {},
//     },
//     leader: {
//       portfolioURLs: [],
//       errors: {},
//       task: {},
//     },
//     partnerUser: {
//       errors: {},
//       task: {},
//     },
//     reference: {
//       errors: {},
//       task: {},
//     },
//     bp: {
//       errors: {},
//       task: {},
//     },
//     project: {
//       errors: {},
//       task: {},
//     },
//     production: {
//       errors: {},
//       task: {},
//     },
//     report: {
//       errors: {},
//       task: {},
//     },
//     chat: {
//       notifications: [],
//       messages: [],
//       activeChannel: '',
//       chatTopics: [],
//       chatActiveMessages: [],
//     },
//     extras: {
//       errors: {},
//       task: {},
//     },
//     wikis: {
//       errors: {},
//       task: {},
//     },
//     counter: {
//       number: 0,
//     },
//     projectsv2: {
//       errors: {},
//       task: {},
//     },
//     chatModal: {
//       open: false,
//       topic: '',
//     },
//     quickMeeting: {
//       open: false,
//       projectId: null,
//       partnerId: null,
//     },
//     chatNotifications: {
//       notifications: [],
//     },
//     partners: {
//       data: [],
//       partnerById: {},
//       stepProject: {
//         step: 1,
//       },
//     },
//     statusbar: {
//       steps: [],
//     },
//   },
//   allStoreEnhancers
// );
