import React, { Fragment } from 'react';

import { I18n } from 'aws-amplify';
import {
    Icon,
    Button,
    Row,
    Col
} from 'antd';

const Card = (props) => {

    if (!props.bp) return null;
    if (!props.bp.client) return null;
    if (!props.bp.client.company) return null;

    const item = props.bp.client.company;

    return (
        <div className="card">
            <div className="card-content">
                {/* <h3 className="title is-4">{I18n.get("Endereço")}</h3> */}


                <Row gutter={1}>
                    <Col sm={12}>
                        <h2 className="title-bpool regular">{I18n.get("Endereço")} {I18n.get("CNPJ")}</h2>

                        <p>
                            <b>{I18n.get("Logradouro")}: </b> {item.addressLine1} {item.addressLine2}
                        </p>
                        <p>
                            {item.city}
                            {item.state &&
                                <Fragment> / {item.state}</Fragment>
                            }
                        </p>
                        <p>
                            <b>{I18n.get("CEP")}: </b> {item.postcode}
                        </p>
                    </Col>


                    <Col sm={12}>
                        <h2 className="title-bpool regular">{I18n.get("Endereço")} {I18n.get("Comercial")}</h2>

                        <p>
                            <b>{I18n.get("Logradouro")}: </b> {item.commercialAddressLine1} {item.commercialAddressLine2}
                        </p>
                        <p>
                            {item.commercialCity}
                            {item.commercialState &&
                                <Fragment> / {item.commercialState}</Fragment>
                            }
                        </p>
                        <p>
                            <b>{I18n.get("CEP")}: </b> {item.commercialPostcode}
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
        // <div className="card">
        //     <div className="card-content">
        //         <h3 className="title is-4">{I18n.get("Endereço")}</h3>

        //         <div className="content">

        //             <table>
        //                 <tbody>
        //                     <tr>
        //                         <th>{I18n.get("Tipo")}</th>
        //                         <th>{I18n.get("Logradouro")}</th>
        //                         <th>{I18n.get("Complemento")}</th>
        //                         <th>{I18n.get("CEP")}</th>
        //                         <th>{I18n.get("Cidade")}</th>
        //                         <th>{I18n.get("Estado")}</th>
        //                     </tr>
        //                     <tr>
        //                         <td>{I18n.get("CNPJ")}</td>
        //                         <td>
        //                             {item.addressLine1}
        //                         </td>
        //                         <td>
        //                             {item.addressLine2}
        //                         </td>
        //                         <td>
        //                             {item.postcode}
        //                         </td>
        //                         <td>
        //                             {item.city}
        //                         </td>
        //                         <td>
        //                             {item.state}
        //                         </td>
        //                     </tr>
        //                     <tr>
        //                         <td>{I18n.get("Comercial")}</td>
        //                         <td>
        //                             {item.commercialAddressLine1}
        //                         </td>
        //                         <td>
        //                             {item.commercialAddressLine2}
        //                         </td>
        //                         <td>
        //                             {item.commercialPostcode}
        //                         </td>
        //                         <td>
        //                             {item.commercialCity}
        //                         </td>
        //                         <td>
        //                             {item.commercialState}
        //                         </td>
        //                     </tr>

        //                 </tbody>
        //             </table>

        //         </div>
        //     </div>
        // </div>
    );
};

export default Card;