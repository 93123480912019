import styled, { keyframes } from "styled-components";

export const Table = styled.table`
  width: 100%;
  border: none !important;
`;

export const TableHeader = styled.th`
  height: 50px;
  background-color: #f0f0f0;
  padding-top: 10px;
  font-size: 14px;
`;

export const TableData = styled.td`
  font-size: 14px;
  padding: 10px 0;
`;

export const TableRow = styled.tr`
  line-height: 50px;
  border-bottom: 1px solid;
  border-color: #f0f0f0;

  th:first-of-type,
  td:first-of-type {
    padding-left: 15px;
  }

  background-color: ${(props) => (props?.lastRow ? "#F0F0F0" : "#fff")};
`;

export const TableAccordion = styled.div`
  /* Estilos do accordion */
  display: block;
  overflow: hidden;
  max-height: ${(props) =>
    props.isOpen ? `${props?.itemsRows * 40 + 30}px` : "0"};
  animation: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  background: #fafafa;
`;

export const TableAccordionContent = styled.div`
  padding: 15px;
`;

export const ButtonFileUpload = styled.button`
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
  margin-top: 10px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: ${(props) => (props?.disabled ? "not-allowed" : "pointer")};
`;

export const RowFile = styled.div`
  cursor: default;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;

  :last-child {
    border: none;
  }
`;

export const IcoOpenClose = styled.div`
  width: 20px;
  height: 20px;
  margin-top: 15px;
  border-radius: 4px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentDel = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
`;

export const DelExtra = styled.button`
  cursor: pointer;
  background-color: #ff0000;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  svg {
    color: #fff;
    cursor: pointer;
  }
`;

export const FilesInModal = styled.div``;

export const ButtonClientApproval = styled.button`
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
  margin-top: 10px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
`;

export const ButtonOpenDescription = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0 0 0 15px;
  color: #1984ff;
  font-size: 16px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
`;

export const ContentDescription = styled.div`
  margin-top: 30px;

  strong {
    font-weight: 600;
    font-size: 13px;
  }

  p {
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
  }

  a {
    font-size: 12px;
    color: #333333;

    i {
      color: #1883ff;
    }
  }
`;

export const DoubleTaxationContent = styled.div`
  margin-bottom: 30px;
`;

export const DoubleTaxationText = styled.div`
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
    margin-right: 10px;
  }
`;

export const ButtonDeleteItem = styled.div`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;
