import React from 'react';

const TermsClientEN = () => {

    return (
        <div class="termsContainer">
            <div class="termsContent">

                <p><strong>BOUTIQUE</strong><strong> POOL</strong></p>
                <p>TERMS OF USE</p>
                <p><strong>CLIENT</strong></p>
                <p>This Term of Use (&ldquo;Term&rdquo;) governs the use of the online platform (&ldquo;Platform&rdquo;) created and developed by BOUTIQUE POOL INTERMEDIA&Ccedil;&Atilde;O E SERVI&Ccedil;OS DE MARKETING LTDA., Headquartered at Av. Nove de Julho 5229, 1st floor in the city and state of S&atilde;o Paulo, CEP 01.406-200, registered with CNPJ/MF under number 31.391.925/0001-34 (&ldquo;Boutique Pool&rdquo;), by companies that wish to hire creative third-party marketing services through the Platform (&ldquo;Clients&rdquo; ).</p>
                <p>AGREEMENT TO THIS TERM IS ABSOLUTELY INDISPENSABLE FOR THE USE OF THE BOUTIQUE POOL PLATFORM AND ITS SERVICES.</p>
                <p>Client must read, make sure it understood and agree with all the conditions established in this Term, before contracting projects on the platform.</p>
                <ol>
                    <li>Definitions For all purposes and effects of this Term, the following terms and expressions beginning in capital letters, in the singular or in the plural, will have the meanings indicated below, with due regard to other terms defined under this instrument:</li>
                </ol>
                <p>&ldquo;Partner&rdquo; or &ldquo;Creative Partner&rdquo; means both marketing agencies and companies and studios for creative, planning, content production, design and/or technologies in various mediums, small and medium, that provide the marketing services available for contracting by Clients through the Platform;</p>
                <p>&ldquo;Matchmaking&rdquo; means the steps for selecting Creative Partners promoted by the Platform, based on a proprietary algorithm developed by the platform and specific criteria and determined according to the Project requested by Client. At the end of the Matchmaking stage, the Platform will indicate three ideal Creative Partners to the demand requested by Client;</p>
                <p>&ldquo;Curatorship&rdquo; means the application of methodologies of analysis and selection of Platform participants on a recurring basis, using human intervention from Boutique Pool, curators and evaluation algorithms for the approval of Partners who are within the standards required by Boutique Pool, to then be indicated and connected with Clients, respecting the particularities and expectations required for the good development of business between both parties;</p>
                <p>"Asset" creative product priced and with its detailed scope, such as land page, website, concept board, creative concept, packaging design, point of sale material design, among others</p>
                <p>"Asset Market" list of creative products in a market place format categorized by type and specialty of communication and marketing services presented to Client, duly priced with full scope details. Client can select projects in the Asset Market (asset packages) or set up to set up its own project by setting up its individual asset.</p>
                <p>&ldquo;Service&rdquo; or &ldquo;Project&rdquo; means the marketing services hired by Client from the selected Creative Partners or projects developed for the Clients by the Partners, in a specific way, through the use of Platform tools. Sum of grouped Assets. The Services may include communication services in general, events, training and/or research, as well as the creation of printed, electronic, audiovisual or radio material, subject to the legal protections inherent to Copyright defined throughout this Term.</p>
                <p>&ldquo;Competition&rdquo; means stages of a specific selection, different from Matchmaking, through which Creative Partners may be invited by Clients to present commercial and creative proposals for a given Project. In such cases, Creative Partner and Client must adhere to a specific term, which will contain the rules of the respective Competition.</p>
                <ol start="2">
                    <li>Registration. The Platform is only available to legal entities or individuals who have are legally allowed to enter into agreements under Brazilian law. Persons without such permission, minors, or Clients whose registration is suspended or canceled may not use the Platform.</li>
                </ol>
                <p>2.1. To be a Client registered on the Platform, the registration form available on the Platform must be completed in all its fields with valid information, in an exact, precise and true manner ("Registration"). Client must provide documents and information that prove the identity and ability to contract on behalf of this legal entity, as requested to perform the Registration. Other documents and additional information may be requested by Boutique Pool. Client is committed to keeping the information included in the Registration up to date.</p>
                <p>2.2. The documentation provided by Client in the Registration must be current, legal, and effective. Boutique Pool does not assume any responsibility for active inspection of Clients, so that losses arising from false or inaccurate information will be the sole responsibility of Clients.</p>
                <p>2.3. Upon registration, Client must provide an email and a temporary password will be provided by Boutique Pool to access the Platform. Boutique Pool will not ask for passwords by any other means, or for any other reason, other than to access the Platform.</p>
                <p>2.4. Client will be exclusively responsible for all the operations carried out in its Registration, and must keep the confidentiality of its password. Boutique Pool is not responsible for operations carried out in its Registration. If the unauthorized use or access of its registration is detected, Client must immediately inform Boutique Pool for the purpose of suspending registration.</p>
                <p>2.5. Client may request the cancellation of its registration on the Platform at any time, without prejudice to Service requests already accepted by Partners at the time of the cancellation request, which must be completed normally, as if the registration for such purposes remained active, in the terms of the Agreement, defined below.</p>
                <ol start="3">
                    <li>Use of the Platform Upon completion of its Registration, approval of the prices of the standard table of projects and Assets or of its specific prices and Assets, as long as they are within the standards deemed as appropriate by Boutique Pool and, finally, adherence to this Term, Client may use the Platform to contract Projects and/or Services with the selected Creative Partners, using the tools available on the Platform, which include (i) access to the Asset Market to select Assets or Projects according to Asset and/or Approved projects; (ii) matchmaking of possible Partners to carry out Projects through matching algorithms, specific market experience and professional qualification; (iii) an environment for defining briefing, schedule and final scope; (iv) a digital signature of Project Specific Term defined below; (v) a mutual rating system for partial and final evaluations of each Project; (vi) payment centralization of multiple partners contracted through the Platform; (vii) the provision of an informational dashboard with investments made through the Platform in each Project and the average rating of each contracted partner.</li>
                </ol>
                <p>3.1. When using the Platform, Clients will receive the right non-exclusive use and for consideration of the software that makes up the Platform, to use it as limited by this Term.</p>
                <p>3.2. Boutique Pool, as a business platform, will use its best efforts to curate the best possible Partners with ethics and creative quality for the Services required by Client and thus promote the environment so that the Service is provided in the best way possible, being responsible for auditing the relations established between Client and Partner, through the Platform, exchanging and excluding Creative Partners who do not provide the Services in accordance with the rules of the Platform and/or when requested by Client.</p>
                <p>3.3. The application of Curatorship, Matchmaking and/or Competitions, and contracting must be carried out in an ethical and responsible manner by the parties involved, with the definition of contractual provisions before contracting the Services. Partners must respect Boutique Pool and Clients' Ethics and Anti-Corruption Codes.</p>
                <ol start="4">
                    <li>Platform flow. Boutique Pool will provide Client and Creative Partners with the use of the Platform according to the following flow:</li>
                </ol>
                <p>IMAGEM FLOW</p>
                <p>4.1. To use the Platform, Client (with its login and password) will select an Assets package with a pre-defined value (approved by its procurement area in the Platform's registration) and the matching algorithm, as defined below, will indicate three Creative Partners who will inform whether or not they can meet the requirement according to the conditions defined by Client. Client will then choose which Partner it wishes to work with based on its portfolio, expertise and empathy.</p>
                <ol start="5">
                    <li>Competitions. In some cases, it will be up to Boutique Pool, through the Platform's intelligence, to organize Competitions between Creative Partners, which will be carried out through the adhesion by the parties of terms specific to each Competition. Competitions will be held in the Platform environment and/or in person. Boutique Pool will use its best efforts to carry out the Competitions fairly, and it is up to the Clients and Creative Partners to adapt to the rules determined on the Platform.</li>
                </ol>
                <ol start="6">
                    <li>Selection for Service or Project Provision. Once selected to develop a Project or provide a Service, according to the Curatorship and Matchmaking procedures carried out, the Creative Partner will receive an email inviting it to participate in such Project with all the details of the Service and/or Project, including delivery time and values, among other information (&ldquo;Invitation&rdquo;).</li>
                </ol>
                <p>6.1. Partner shall indicate whether or not to it will take a given Project within 1 (one) business day (at the same time as the next business day) counted from the receipt of Boutique Pool referral message, carried out in the Platform environment. A Partner's silence will be construed by Boutique Pool as refusal to accept a Project. If the term established in this item falls on a weekend, the first subsequent business day will be considered.</p>
                <p>6.2. The three selected Partners who accepted the Invitation will be presented to Client and the Platform will assist Client in the selection by showing the portfolio, history and credentials of such Partners, as well as encouraging face-to-face conversations or making calls so that Client can make the choice at its exclusive criterion.&nbsp;</p>
                <p>6.3. Client will have to decide which is the most suitable for the Service or Project among these Partners within the term it has selected on the Platform. If Client does not select Partner within the respective period, the Creative Partner is authorized to withdraw from that Matchmaking and/or specific Competition.</p>
                <ol start="7">
                    <li>Project Specification Term (&ldquo;TEP&rdquo;). Once the Matchmaking is carried out and Partner is selected by Client, the final scope and the respective macro-stages of the Project are adjusted on the platform, and upon final acceptance, the specific TEP of that agreement is generated, which must be digitally signed between Client and Partner, with the intervention of Boutique Pool, establishing the specific rights and obligations of Client and Partner with respect to the selected Services and/or Project, detailing the Project to be developed or Service to be provided to Client, including deadlines and payment method of the Partner's compensation and regulating the relationship between these parties.</li>
                </ol>
                <p>7.1. Any Creative Partner selected by Client to provide Services or execute Projects under the terms of this Term will be bound to the TEP. Client's obligations with the Creative Partner are directly payable by the Creative Partner to Client.</p>
                <ol start="8">
                    <li>Evaluation. The Platform will contain tools to evaluate the Service or Project carried out by Partner according to each of the steps defined at the beginning of each Project/Service contracted between Clients and Partners. In the event of assessments inconsistent with a Client's expectations and briefing, a particular Partner must adapt the submitted materials, redoing them according to the instructions indicated in writing by the respective Client. While such adjustment is being made, specific payments to this Partner may be suspended, safeguarding the amounts related to the completed services. Once the conclusion of any adjustments or a certain stage of contracting occurs and upon the satisfactory evaluation of Client who contracted the Services, specific payment to this Partner will be made.</li>
                </ol>
                <p>8.1. The Platform will limit the number of times that a given Project can be redone or adapted, to avoid overly burdening Partners. In cases where a given Client exceeds the amounts of adjustments allowed or changes the briefing and scope of Services in a Project too many times, it will be at the discretion of each Partner to negotiate with the client a new contract on the Platform, or by a functionality called &ldquo;Project extension&rdquo; available on the Platform.</p>
                <p>8.2. The evaluations carried out on the Platform may contain public information, provided that they have been previously approved by Partners and Clients, when they will then be available to all those enrolled in the Platform. Assessments should be carried out in good faith and in an objective manner, indicating feedback to Partners in a concise manner. The Platform will not make partial evaluations of Creative Partners publicly available while certain Projects are being carried out.</p>
                <p>8.3. If a Partner disagrees with an evaluation, it may request its review by Boutique Pool, as well as clarification of any questions.</p>
                <p>8.4. Boutique Pool may exclude from the Platform, Partners who receive 3 or more bad evaluations, in specific contracts, over a period of up to 6 months. In these cases, Boutique Pool will inform the excluded Partner, in writing, of the reasons for its exclusion. Boutique Pool will consider an evaluation as bad when the overall score given at the end of a Project is less than 3, regular between 3.0 and 3.99, good between 4.0 and 4.74, and excellent between 4.75 and 5.</p>
                <ol start="9">
                    <li>Platform Development and Maintenance. Boutique Pool is responsible for developing and keeping the Platform up-to-date so that it organizes the relationships between Clients and Partners, in a safe way and respecting the provisions established for each Project. Boutique Pool may interrupt the availability of the Platform at any time at scheduled stops, without the need for prior notice to Clients and Partners, making an effort, however, so that such stops are made outside business hours. Boutique Pool is not required to keep the Platform available indefinitely, and there is no liability whatsoever to Clients and Partners in the event of a possible unscheduled outage.</li>
                </ol>
                <p>9.1. Boutique Pool will not be responsible for viruses that may infect the Client's equipment as a result of accessing, using, or checking the Platform or any transfer of data, files, images, text, or audio contained therein. Client may not held Boutique Pool accountable, nor demand payment for loss of profit, due to losses resulting from failure to comply with the provisions contained herein, resulting from technical difficulties or systems or Internet failures. Boutique Pool does not guarantee continued or uninterrupted access and use of the Platform. The system may not be available due to technical difficulties or Internet failures, or for any other circumstance beyond Boutique Pool's control and in such cases Boutique Pool will seek to re-establish accessibility to the Platform as soon as possible, without assuming any kind of liability.</p>
                <p>9.2. Clients are absolutely prohibited from any action or use of devices, software, or other means with the purpose of interfering in the activities and operations of the Platform. Any interference, activity, or attempted violation or that is contrary to the laws, including, without limitation, those dealing with intellectual property rights, and/or the prohibitions established in this Term will be subject to the relevant legal actions.</p>
                <ol start="10">
                    <li>Non-Competition. When requested by a Client, the Platform will not optionally list Partners who are working with other clients in the same category as Client. Therefore, when registering on the Platform, each Creative Partner must indicate the brands of products and/or services with which they may already work (requiring the submission of an Invoice from the last 3 months) so non-competition practices are established and thus, a Creative Partner does not develop any type of work for competing products and/or services.</li>
                </ol>
                <p>10.1. For the purpose of establishing non-competition practices, only the brands of products and/or services within the given category will be considered, with which a Creative Partner works, not the companies that own and market the brands of such products and/or services. In this way, Creative Partners will not be able to act only for brands of competing products and/or services directly in the same category, not for companies that are their manufacturers.</p>
                <p>10.2. If a Creative Partner already has non-competition agreements with one of its customers when registering and using the Platform, it must provide this information when starting its relationship with Boutique Pool, during registration, indicating the option of &ldquo;active non- compet.''</p>
                <p>10.3. Creative Partners must keep an updated list of companies, products, and services with which they maintain or have maintained a commercial relationship in the last 3 (three) months, so that there are no conflicts between these and any Clients that may be referred to Creative Partners, in the terms of this item.</p>
                <p>10.4. If a Creative Partner's non-compete period is required for it to develop services for a particular Client, it will be deemed that such Creative Partner is aware and agreed to such a limitation by accepting to begin work with such Client, and there is no liability whatsoever to Boutique Pool in the event that there are demands regarding the non-competition period already accepted in a given Project.</p>
                <ol start="11">
                    <li>Compensation and Payment Method. Client shall compensate Creative Partners under the terms defined for each Service or Project contracted, in accordance with each Invitation and TEP entered into between Client and Partners, including when there are staggered payments (&ldquo;Compensation&rdquo;). Boutique Pool will manage the payment flow of the contracted Projects, releasing the payments according to the conclusion of pre-defined stages between the Parties, retaining only the amounts that it may fit through the intermediation of the contracted Services.</li>
                </ol>
                <p>11.1. In case of attempts by Client to change the scope after the approval of a Project, it will be up to the Creative Partners to inform Boutique Pool about such changes in order to avoid possible losses. Extension of standard Platform scopes may also be contracted through the &ldquo;Extension of Non-Standardized Projects and Items&rdquo; functionality on the platform, through the &ldquo;Production&rdquo; Menu within each Project.</p>
                <p>11.2. Payments made by Client will be addressed to Boutique Pool, which will manage all these payments, passing them on to each Creative Partner within the conditions of each Project. In certain Projects, Boutique Pool will be able to manage the payment in stages to a Creative Partner, according to the completion of each of these stages. Payments to Creative Partners must, in any case, respect the payment terms of the Clients.</p>
                <p>11.3. The Parties agree that the payment of the Compensation by Client will occur according to the flow described below.</p>
                <p>(i) Client must enter the order number or purchase order (&ldquo;Order&rdquo;) when applicable or, authorize Invoicing on the platform;</p>
                <p>(ii) After placing the Order on the Platform or invoicing authorization, the Creative Partner will be notified by email with instructions and shall: (1) issue an invoice against Client in the amount of Compensation provided for Partner, according to the final approved scope and/or TEP under Boutique Pool, following invoicing instructions in the notification and specifying in the body of the said invoice that the amounts will be on behalf of Boutique Pool services mediator, against the mediation note; (2) upload the referred invoice issued on the Platform, and (3) Immediately communicate to Boutique Pool through the email suporte.parceiro@bpool.co in case of any technical problem that may harm or make the issuance of the Invoice unfeasible;</p>
                <p>(iii) Boutique Pool, in turn, will issue an invoice adding the Services provided by the Creative Partner in the field of &ldquo;Service Intermediation&rdquo; and the Transactional Fee, which will be described and transparently in the Invitation and TEP (&ldquo;Transactional Fee&rdquo;), and will send to the client according to instructions inserted in the platform (email, system upload, and/or physical receipt);</p>
                <p>(iv) Client will fully pay the invoice referred to in item (iii) to Boutique Pool's checking account and the latter will transfer the amount paid for the services to the partner, according to the final scope and/or TEP.</p>
                <p>Sole Paragraph: The Parties agree that, especially in relation to the obligations contained in subparagraph (ii) above, the Creative Partner will be required to issue the respective Invoice and upload it at the link provided by Boutique Pool within 48 (forty-eight) hours from receipt of the e-mail containing instructions in this regard. Subject to the provision in item (3) of subparagraph (ii), it is hereby agreed that the Creative Partner's failure to meet the aforementioned period will give rise to the application of a penalty of 2% (two percent) on the price of the services, percentage to the which will be increased by 0.5% (zero point five percent) per day of delay, limited to 5% (five percent).</p>
                <p>11.4. In case of delay in the payment of the Compensation through invoices sent, received, and not challenged by Client, the latter will be subject to a fine of 2% (two percent), in addition to default interest of 1% (one percent) per month, pro rata die. The fine and default interest referred to in this item will be due to the Creative Partner and Boutique Pool, in proportion to their respective compensations, according to the contracting terms of each Project or Service.</p>
                <p>11.5. On the date of adhesion to this Term, Client will be exempt from paying Boutique Pool the monthly fee for using the Platform (&ldquo;Monthly Fee&rdquo;). However, Client is aware and hereby agrees that Boutique Pool may charge Client the Monthly Fee at any time after entering into this Term, upon prior notice to Client.</p>
                <p>11.6. Depending on the number of creative items to be inserted into the system, as well as if adapting the Curatorship and contract criteria is necessary, according to the specific requirements of Client, Client is aware and declares to agree that a set-up fee can be charged by Boutique Pool, upon prior notification to Client.</p>
                <ol start="12">
                    <li>Copyright. For the purposes of this Term, &ldquo;Copyright&rdquo; means the moral and property rights on creations created during the rendering of marketing services in each Project, provided that they contain original creations capable of highlighting an idea or concept for a product or service. In this sense, Copyright can include creations manifested in printed, electronic, audiovisual, or radio material. Creative Partners must retain ownership of the Copyright to the materials they may submit for use on the Platform.</li>
                </ol>
                <p>12.1. Partners must create original concepts that will be offered for contracting by Clients. If non-original materials are used, the Partners may be excluded from the Platform, subject to the right of damage that a particular Client who may be adversely affected may come to seek against Partner. Boutique Pool is not liable in any way for the violation of third-party copyrights during the Services, and the adversely affected party must seek its rights from the Partner who violated such rights.</p>
                <p>12.2. All brands, trade names, domain names and other distinctive signs of Boutique Pool on the Platform, as well as the programs, databases, networks, and files, which allow Partner to access and use the Platform, are the property of Boutique Pool and are protected by international copyright laws and treaties, trademarks, patents, models, and industrial designs. Misuse and total or partial reproduction of the referred contents are prohibited, except with the express authorization of Boutique Pool.</p>
                <p>12.3. Any interference, attempt, or activity that violates or contravenes the laws of intellectual property rights and/or the prohibitions stipulated in this Term, will make the person liable to the relevant legal actions, as well as the sanctions provided for herein, being also responsible for damage for possible sanctions.</p>
                <ol start="13">
                    <li>Copyright Assignment. Materials subject to Copyright will be assigned to Clients when a particular Partner is selected in a specific Curatorship, Matchmaking, or Competition and the Service provided. Such assignment is for valuable consideration under the terms of each agreement, specific for use in each Project contracted, and limited to the uses provided for in each Project. In the absence of a specific definition, the assignment will be considered for valuable consideration and unlimited, definitive, and will cover all materials produced by the Creative Partner that are subject to Copyright.</li>
                </ol>
                <p>13.1. Copyright Assignment must be made by Partner with a guarantee that it obtained the rights from the authors of the materials contained in the Services, as individuals, who made these materials. In case of absence in obtaining the assignment to individuals, it will be up to each Client to decide whether to obtain this Copyright assignment in another way or to cancel the contracting of a certain Partner. Boutique Pool shall arrange the exclusion of this Partner from the Platform, at the request of Client, if Partner does not provide Client with documents that prove that the Copyright has been assigned to it correctly. Moral rights on marketing materials are not assigned, pursuant to Article 24 of the Copyright Law (Law 9810/98), and it is the responsibility of Clients to name their creators in the exhibition of the marketing materials that have been created.</p>
                <ol start="14">
                    <li>Boutique Pool Performance. Boutique Pool acts in the connection between Client and Partner and its responsibility is limited to the services provided by it through the Platform, as described in this Term.</li>
                </ol>
                <p>14.1. Client is aware and declares to agree that Boutique Pool will employ the best team and technology to perform Curatorship, Matchmaking, and Competition services, always making its best efforts to indicate the best Partners to provide Services to Clients, but cannot be held directly responsible for any damages caused to Clients by Partners in the provision of Services.</p>
                <p>14.2. If the Service is rendered unsatisfactory to Client, Boutique Pool will take the necessary measures to resolve inconsistencies in the Services, as provided for in item 8 above, arranging the replacement of Partner, if applicable, as well as retaining and/or returning any amounts paid by Client, according to the Platform rules applicable to the case at the time of the incident.</p>
                <p>14.3. In the event of a conflict between Client and Partner, Boutique Pool will use its best efforts to resolve such conflict in accordance with the pre-defined rules of the Platform and TEP definitions, but cannot, however, be held responsible for the payment of damages, including, but not limited to, property damage, pain and suffering, and loss of profits.</p>
                <p>14.4. Client may take the necessary legal measures directly before Partner, if it wants to obtain compensation for any property damage and pain and suffering caused to itself or to third parties.</p>
                <p>14.5. Notwithstanding the provisions above, in any event, Boutique Pool's liability under this instrument is limited to the Compensation amount for each Service/Project contracted on the Platform.</p>
                <ol start="15">
                    <li>Exclusion from the Platform. Boutique Pool may exclude Clients and Partners from the Platform if do not act in accordance with these Terms, ethics, or Brazilian law. In the event that Boutique Pool becomes aware of a negotiation or attempt to negotiate between Clients and Partners outside the Platform environment, after its initiation in the Platform environment, the exclusion will be immediately carried out.</li>
                </ol>
                <p>15.1. In the event of a exclusion during a Curatorship, Matchmaking, or Competition procedure, Boutique Pool may suspend these procedures without effectively hiring of a Partner (in the event of exclusion of a Client), or suspend the participation of a Partner in a specific Curatorship, Matchmaking, or Competition procedure, without returning any amounts incurred by the Party that may be excluded from the Platform.</p>
                <p>15.2. For the purposes of this clause, in the cases where Boutique Pool has not been compensated, it shall also be entitled to a referral fee provided for each Project, which shall be paid by Client, if the Project has not yet been paid, or by Partner, if it has already received the amounts of the Project, without prejudice to other losses and damages that may be required.</p>
                <p>15.3. The only exception to the above rule will be in the event that a given Creative Partner receives a Project offer by Boutique Pool and it is found that it has already maintained a commercial relationship with the Client selected by Boutique Pool in the previous 12 months. In this case, Boutique Pool must be informed immediately so that it can change the conditions of the direct relationship between Client and the Creative Partner, noting that in this case the compensation of the Platform will only be considered for Projects that started in the Platform. If there is a provision by Client that all Projects with this Creative Partner are concentrated on the Platform, from then on, Boutique Pool must establish specific prices that take such scenario into consideration.</p>
                <ol start="16">
                    <li>Event of default. Without prejudice to other measures, Boutique Pool may warn, suspend or cancel, temporarily or permanently, a Client's account on the Platform, at any time, and initiate the appropriate legal actions: a) if Client (i) fails to comply with any provision of this Term, (ii) fails to fulfill its Client duties with Boutique Pool and Partner, or (iii) performs fraudulent or intentional harmful acts; b) if any information provided by Client is incorrect; or c) if Boutique Pool understands that any action or omission by Client has caused damage to third parties or Boutique Pool or has the potential to do so.</li>
                </ol>
                <p>16.1. Client shall indemnify Boutique Pool, its subsidiaries, controlled, or controlling companies, directors, administrators, employees, representatives, and employees for any demand promoted by other Clients, Partners, or third parties, resulting from their activities on the Platform or for non-compliance with this Term, for violation of any law or third party rights, notably those related to the confidentiality of information received from Client, the privacy of data accessed under this instrument, as well as the regulation of advertising activities.</p>
                <ol start="17">
                    <li>Conflict Resolution. Boutique Pool may mediate any conflicts between Clients and Partners so that they are resolved in accordance with the Terms set forth herein and the applicable Brazilian law, adopting methods that are transparent to Clients and Partners and may retain the amounts involved until the conflict between Clients and Partners is solved.</li>
                </ol>
                <p>17.1. In all mediations, all parties involved will endeavor for the procedures to be carried out and completed within a maximum of 30 days.</p>
                <p>17.2. If any conflicts are not resolved by the mediation provided for in this item within a maximum period of 60 days, such conflicts must be resolved by arbitration, under the terms of the ARBITRANET Regulation, by an arbitral tribunal composed of 3 (three) arbitrators, who will be appointed in accordance with the provisions of said regulation. The arbitration will be conducted in Portuguese and should take place in the city of S&atilde;o Paulo, State of S&atilde;o Paulo, Brazil.</p>
                <p>17.3. Without prejudice to the provisions of Clause 18.2. above, the Parties recognize that any of them may appeal to the Judiciary Power exclusively for the following measures, and such measures should not be interpreted as a waiver by the Parties of the arbitration procedure: (i) to obtain preliminary and precautionary measures prior to confirmation of the arbitral tribunal; (ii) to execute any decision of the arbitral tribunal, including the final judgement; (iii) for the specific execution of this instrument; and (iv) for other procedures expressly permitted by Law No. 9,307/96, as amended. For the purposes of this item, the jurisdiction of the capital of S&atilde;o Paulo is hereby elected.</p>
                <ol start="18">
                    <li>Labor Relationship. Under no circumstances, the existence of any employment relationship will be assumed nor labor and social security obligations will be established between the Parties, by themselves and/with the representatives or employees of the other Party, or any of the Parties will guarantee the labor and social obligations and charges of the other Party which herein assumes full responsibility for such obligations, including civil and criminal. The Parties will be fully responsible for third party services that may be used to fulfill obligations undertaken on the Platform. In this sense, they will be responsible for the obligations enforced labor and social security legislation, being responsible for the payment of all tax, labor and social security charges related to their employees allocated in the performance of its activities.</li>
                </ol>
                <ol start="19">
                    <li>Taxes. Taxes of any nature that are due as a direct result of Projects provided through contracting through the Platform are the sole responsibility of the taxpayer, as defined in the tax rule.</li>
                </ol>
                <ol start="20">
                    <li>Agreement. This Term constitutes the entire agreement between the Parties on the use of the Platform and prevails over any previous agreements.</li>
                </ol>
                <ol start="21">
                    <li>Changes. This Term will be periodically reviewed by Boutique Pool, which may change it, excluding, modifying or inserting clauses or conditions, at its sole discretion. However, Client will be notified whenever there is a change in the provisions of this Term so that it can be aware of the new conditions. If it does not agree with the changes, it must cancel its account and stop using the Platform.</li>
                </ol>
                <ol start="22">
                    <li>Anti-corruption. The Parties will comply with and ensure that all their employees, subcontractors, consultants, agents, or representatives comply with Law 12.846/13, as well as the Clients' code of ethics, if any. Neither Party shall pay, offer, promise, or give, directly or indirectly, any amount or valuable thing, including any amounts paid as a result of the contracted services, to any employee or official of a government, company, or corporation controlled or owned by the government, a political party, a candidate for political office, or any other person while being aware of or believing that such amount or valuable item will be given to someone to influence any action or decision by such person or by any government agency with the purpose of obtaining, retaining, or conducting business for any of the Parties.</li>
                </ol>
                <ol start="23">
                    <li>Client hereby represents and warrants to Boutique Pool that it has read, understood, and agrees with all the provisions of these Terms.</li>
                </ol>
                <ol start="24">
                    <li>Client, as well as its respective legal representatives, declare that they are duly authorized to accept this Term, in the form of their respective corporate instruments.</li>
                </ol>


            </div>
        </div>
    );
}

export default TermsClientEN;
