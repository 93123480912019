import styled from "styled-components";

export const DivNewsInternal = styled.div`
    font-family: 'HelveticaNeue', sans-serif;
    background-color: #F3F3F3;
    padding: 125px 0 90px;
`;

export const DivNewsContainer = styled.div`
    display: flex;

`;


export const LinkAllNews = styled.a`
    padding-top: 40px;    
    color: #1883FF;
    font-size: 16px;
    text-decoration: underline;
    font-weight: 600;
    display: flex;

    &:visited, :hover {
        color: #1883FF;
        text-decoration: underline;
    }
`;