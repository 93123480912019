import React, { PureComponent, Fragment } from "react";
import { I18n } from 'aws-amplify';
import { Row, Col, Tag } from 'antd';

import { CardBorder } from "../../components/Cards";
import Carousel from "../../components/Carousel";

import CarouselThumbs from "../../components/CarouselThumbs";
import ListLinks from "../../components/ListLinks";
import noPhoto from '../../../image/no-photo2.png';
import ReactPlayer from 'react-player';


import NotRegistered from "../../components/NotRegistered";

import styled from "styled-components";


const Box = styled.div`

.ant-tag {
    margin-bottom: 10px;
}

.noMargin {
    div {
        margin-bottom: 0 !important;
    }
}

.TitleView {
    margin-bottom: 40px;
    h3 {
        margin-bottom: 5px;
    }
}

.colM {
    margin-bottom: 20px;
}

.ant-row-flex {
    margin-bottom: 20px;
}
p,li {
    font-size: 13px;
    line-height: 16px;
}

.bxDescription {
    padding-right: 20px;
    height: 60px;
    overflow: auto;

    p {
        font-size: 12px;
        line-height: 16px;
    }
}
.content-asset {
    p {
        margin-bottom: 5px;
    }

    ul {
        padding-left: 20px;
        li {
            list-style: disc;
            margin-bottom: 5px;
        }
    }
}

h4 {
    font-size: 14px;
    font-weight: bold;
    border-bottom: solid 1px #ccc;
    margin-bottom: 15px;
}

h3 {
    margin: 10px 0 20px;
    font-weight: bold;
}

.Sizing {
    width: 100%;
    max-width: 800px;
    margin: 50px auto;
}
    .ItemImg {
        margin: 0 2px;
        overflow: hidden;
        display: block;
        padding: 2px;
        max-width: 122px;

        img {
            width: calc(100% - 4px);
            display: block;
        }
    }
`

class ItemAsset extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            play: false
        };
    }
    render() {
        const {
           title,
           assetType,
           specialism,
           partnerSpecialism,
           variations
        } = this.props.item;



        const specialismValues = specialism && specialism.map((item) => {
            const specialismItem = this.props.assetSpecialisms && this.props.assetSpecialisms.find((s) =>  s.code == item) || {};
            return specialismItem;
        });

        const specialismPartnerValues = partnerSpecialism && partnerSpecialism.map((item) => {
            const specialism = this.props.partnerSpecialismsArray && this.props.partnerSpecialismsArray.find((s) => s.code == item) || {};
            return specialism;
        });


        const typesValues = assetType && assetType.map((item) => {
            const type = this.props.assetTypeArray && this.props.assetTypeArray.find((s) => s.code == item) || {};
            return type;
        });


        const variationSort = variations.sort((a, b) => b.label - a.label);



        return (
            <Box>
                <Row type="flex">
                    <Col className="colM" sm={24}>
                        <h3>{title}</h3>
                    </Col>
                </Row>

                <Row type="flex">
                    <Col className="colM" sm={8}>
                        <CardBorder
                            Bcolor="#f2f2f2"
                        >
                            <h4>{I18n.get("Tipo")}</h4>
                            {typesValues && typesValues.map((i, index) => {
                                return <Tag key={index} color="#000">{i.label}</Tag>
                            })}
                        </CardBorder>
                    </Col>

                    <Col className="colM" sm={8}>
                        <CardBorder
                            Bcolor="#f2f2f2"
                        >
                            <h4>{I18n.get("Especialidades")}</h4>
                            {specialismValues && specialismValues.map((i, index) => {
                                return <Tag key={index} color="#000">{i.label}</Tag>
                            })}
                        </CardBorder>
                    </Col>

                    <Col className="colM" sm={8}>
                        <CardBorder
                            Bcolor="#f2f2f2"
                        >
                            <h4>{I18n.get("Especialidades do parceiro")}</h4>
                            {specialismPartnerValues && specialismPartnerValues.map((i, index) => {
                                return <Tag key={index} color="#000">{i.label}</Tag>
                            })}
                        </CardBorder>
                    </Col>
                </Row>
                <Row type="flex">
                    {variationSort && variationSort.map((item,index) => {
                        return (
                            <Col className="colM" sm={6} key={index}>
                                <CardBorder
                                    Bcolor="#f2f2f2"
                                >
                                    <div className="content-asset">
                                        <h4>{item.label}</h4>
                                        <p><b>{I18n.get("Código")}:</b> {item.code}</p>
                                        <p><b>{I18n.get("Ativo")}:</b> {item.enabled ? I18n.get("SIM") : I18n.get("NÃO")}</p>
                                        <p><b>{I18n.get("Preço")}:</b> {item.priceWithDiscount.toLocaleString('pt-br', {minimumFractionDigits: 2})}</p>
                                        <p><b>{I18n.get("Tipo")}:</b> {item.variationType}</p>

                                        <p><b>{I18n.get("Descrição")}:</b> </p>
                                        <div className="bxDescription">
                                            <p>{item.description}</p>
                                        </div>
                                        <br />
                                        <p><b>{I18n.get("Assets")}:</b></p>
                                        <ul>
                                            {item.assets.map((asset,i) => {
                                                return <li key={i}>{asset.label} {" "}  <b>{asset.price.toLocaleString('pt-br', {minimumFractionDigits: 2})}</b></li>
                                            })} 
                                        </ul>

                                        <br />
                                        

                                    </div>
                                    
                                </CardBorder>
                            </Col>
                        )
                    })}
                    
                </Row>
            </Box>
        );
    }
}

export default ItemAsset;
