import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import MoneyField from '../../form/money-field';

import SubmitButtonField from '../../form/submit-button-field';

import NumberFormat from 'react-number-format';

const BRL = ({ amount }) => <NumberFormat value={amount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} fixedDecimalScale={true} />;

export default class BillingForm extends React.Component {

    constructor(props) {
        super();

        this.state = {
            fields: {
                amount: {
                    label: I18n.get("Valor a ser liberado"),
                    onChange: this.onChangeForm.bind(this, 'amount'),
                    successIcon: false,
                    visited: true,
                }
            },
            errors: {},
            form: {},
            task: {},
            submit_task: {}
        };
    }

    componentDidMount() {
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors = {}; // Como so tem um campo, limpa todos os erros

            return nextState;
        });
    }

    getData() {

        const { projectId, productionId, extraId } = this.props.match.params;

        const { billing } = this.props;

        var data = {
            projectId: projectId,
            productionId: productionId,
            extraId: extraId,
            billingId: billing.id,
            ...this.state.form
        };

        return data;
    }

    submitForm(e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.create_billing_installment(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }


    render() {

        const billing = this.props.billing || {};
        const billingCurrency = this.props.billingCurrency || {};

        const shouldDisable = !billing?.balance || billing?.balance <= 0 ;

        return (
            <form>

                <div className="columns">

                    <div className="column is-3">


                        <MoneyField {...this.state.fields.amount} 
                                value={this.state.form.amount} 
                                currency={billingCurrency} 
                                disabled={shouldDisable}
                        />

                        {this.state.errors && Object.keys(this.state.errors).map((item) => (<p className="help is-danger">{this.state.errors[item].errorMessage}</p>))}

                    </div>

                    <div className="column is-3">

                        <SubmitButtonField
                            label={I18n.get("LIBERAR VALOR")}
                            className="button is-black"
                            onClick={this.submitForm.bind(this)}
                            task={this.state.submit_task}
                            disabled={shouldDisable}
                        />

                    </div>

                    <div className="column is-6 has-text-right">
    {/* 
                        <p><strong>Saldo:</strong> {<BRL amount={billing.balance} />}</p> */}

                    </div>

                </div>


            </form>
        );
    }
}
