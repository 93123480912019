import styled from "styled-components";

export const StyledError = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ff0000;
  margin-top: 15px;
`;

export const Icon = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
`;
export const Label = styled.span`
  display: inline-block;
  vertical-align: middle;
`;
