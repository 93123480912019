import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useBp = () => {
  const getClientAddPartnerWorkedWith = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/bp/admin/client-add-partner-worked-with/${id}`,
      config
    );

    return data;
  };

  const putClientAddPartnerWorkedWith = async ({ form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/bp/admin/client-add-partner-worked-with`,
      form,
      config
    );

    return data;
  };

  const partnerStatusRefresh = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.get(
      `${Config.API_BASE_URL}/bp/admin/partner-stats-refresh/${id}`,
      config
    );

    return data;
  };

  const changeUserMasterPartner = async ({ form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/bp/admin/replace-partner-responsible`,
      form,
      config
    );

    return data;
  };

  // TODO: Refactorar para deixar genérico para demais funções, em caso de exceções no envio de notificação.
  const sendNotificationEmailBankAccountWrongAsync = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

   

    const config = { headers: { Authorization: "Bearer " + token } };

    let response = null;

    try {
      response = await axios.put(
        `${Config.API_BASE_URL}/bp/admin/notify-bank-account-wrong`,
        form,
        config
      );
    } catch (errors) {
      console.error(`Erro ocorreu no envio da notificação: ${errors}`);
    }

    return response;
  };

  const deleteInvoiceSupplier = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/bp/supplier-billings/${form.id}/invoice`,
      form,
      config
    );

    return data;
  };


  const updateSpecificInformation = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

   

    const config = { headers: { Authorization: "Bearer " + token } };

    let response = null;

    try {
      response = await axios.put(
        `${Config.API_BASE_URL}/bp/admin/update-specific-informations-to-invoice`,
        form,
        config
      );
    } catch (errors) {
      console.error(`Erro ocorreu no envio da notificação: ${errors}`);
    }

    return response;
  };

  return {
    getClientAddPartnerWorkedWith,
    putClientAddPartnerWorkedWith,
    partnerStatusRefresh,
    changeUserMasterPartner,
    sendNotificationEmailBankAccountWrongAsync,
    deleteInvoiceSupplier,
    updateSpecificInformation
  };
};
