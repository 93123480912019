import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import CheckboxMultiField from "../form/checkbox-multi-field";
import TextAreaField from "../form/textarea-field";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

import { Textarea } from "../../v2/components/UI/Textarea";

class ProductForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        productAdvantage: {
          label: I18n.get("Quais os diferencias do seu produto?"),
          onChange: this.onChangeForm.bind(this, "productAdvantage"),
          // onBlur: this.onSave.bind(this),
        },
        productBenefits: {
          label: I18n.get("Quais os benefícios do seu produto?"),
          onChange: this.onChangeForm.bind(this, "productBenefits"),
          // onBlur: this.onSave.bind(this),
        },
        productConcept: {
          label: I18n.get("Qual o conceito e posicionamento do seu produto?"),
          onChange: this.onChangeForm.bind(this, "productConcept"),
          // onBlur: this.onSave.bind(this),
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Produto")}</h1>

        <div className="bp-event-section">
          {/* <TextAreaField
            {...this.state.fields.productAdvantage}
            value={this.state.form.productAdvantage}
            error={this.state.errors.productAdvantage}
          /> */}

          <Textarea
            label={this.state.fields.productAdvantage.label}
            defaultValue={this.state.form.productAdvantage}
            value={this.state.form.productAdvantage}
            onChange={(value) =>
              this.state?.fields?.productAdvantage.onChange(value)
            }
            error={this.state?.errors?.productAdvantage?.errorMessage}
          />

          <br />
          {/* 
          <TextAreaField
            {...this.state.fields.productBenefits}
            value={this.state.form.productBenefits}
            error={this.state.errors.productBenefits}
          /> */}

          <Textarea
            label={this.state.fields.productBenefits.label}
            defaultValue={this.state.form.productBenefits}
            value={this.state.form.productBenefits}
            onChange={(value) =>
              this.state?.fields?.productBenefits.onChange(value)
            }
            error={this.state?.errors?.productBenefits?.errorMessage}
          />

          <br />

          {/* <TextAreaField
            {...this.state.fields.productConcept}
            value={this.state.form.productConcept}
            error={this.state.errors.productConcept}
          /> */}

          <Textarea
            label={this.state.fields.productConcept.label}
            defaultValue={this.state.form.productConcept}
            value={this.state.form.productConcept}
            onChange={(value) =>
              this.state?.fields?.productConcept.onChange(value)
            }
            error={this.state?.errors?.productConcept?.errorMessage}
          />

          <br />
        </div>
      </div>
    );
  }
}

export default ProductForm;
