import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, message } from "antd";
import { useDebounce } from "use-debounce";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import Content from "../../../components/UI/Content";
import { Input } from "../../../components/UI/Input";
import Main from "../../../components/UI/Main";
import { Button } from "../../../components/UI/Button";
import { Unlogged } from "../../../components/UI/Unlogged";
import { useRegistrationFlow } from "../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";
import { handleClearLocalStorage } from '../../../utils/clearStorage'

import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";

import * as S from "./styles";

export const ApplicationReceivedLogin = () => {
  const { getStatusBarPreApplication, verifyCnpj, registry } =
    useRegistrationFlow();
  const state = useSelector((state) => state);
  const { resendMail } = useRegistrationFlow();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [localeUser, setLocaleUser] = useState("pt");
  const [checkOk, setCheckOk] = useState(false);
  const [inputsErrorsForm, setInputsErrorsForm] = useState(null);
  const [errorInSend, setErrorInSend] = useState("");

  let location = useLocation();
  useEffect(() => {
    const language = getParamsLocale();
    setLocaleUser(language);
  }, [location]);

  let history = useHistory();

  const getParamsLocale = () => {
    let language = "";
    if (location.search) {
      const locationSplit = location.search.split("=");
      language = locationSplit[1];
    } else {
      language = "pt";
    }
    return language;
  };

  const hasError = (fieldInput) => {
    if (inputsErrorsForm.length) {
      const err = inputsErrorsForm.find(
        (item) => item.field.toLowerCase() === fieldInput.toLowerCase()
      );

      if (err) {
        return err.errorMessage;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const handleResendMail = async () => {
    setIsLoading(true);

    try {
      const response = await resendMail({ registrationId: null, email });
      if (response?.success) {
        setVisible(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setInputsErrorsForm(response?.errors);
      }
    } catch (error) {
      setErrorInSend("Oops! Something went wrong. Try again later, or contact us.");
      message.error(I18n.get("Oops! Something went wrong. Try again later, or contact us."));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const redirectPage = () => {
    history.push("/registration-flow/login");
  };

  useEffect(() => {
    if (!email) {
      setEmail(state?.partner?.email || "");
    } else {
      handleClearLocalStorage();
    }
  }, [state]);

  return (
    <Unlogged>
      <Content>
        <div className="container">
          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={12} offset={6}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Confirm your registration")}</S.H4>
                      </S.TitleWithIcon>
                      <S.Paragraph style={{ fontWeight: 700, marginTop: 40 }}>
                        {I18n.get(
                          "You need to confirm your register, to resend confirm the registration E-mail."
                        )}
                      </S.Paragraph>
                    </S.RowCenter>
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>
                  <Col xs={24} sm={12} offset={6}>
                    <Input
                      placeholder={I18n.get("E-mail")}
                      required
                      error={inputsErrorsForm?.Partner?.errorMessage}
                      onChange={(value) => setEmail(value)}
                      value={email}
                      disabled={state?.partner?.email ? true : false}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 40 }}>
                  <Col
                    xs={24}
                    sm={7}
                    offset={9}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      full
                      text={I18n.get("Resend email")}
                      className=""
                      theme="primary"
                      // outline
                      loading={isLoading}
                      onClick={handleResendMail}
                      isDisabled={!email}
                    />
                  </Col>
                </Row>
                {errorInSend ? (
                  <Row style={{ marginTop: 40 }}>
                    <Col xs={24}>
                      <S.ErrorInSend>
                        <p>{I18n.get(errorInSend)}</p>
                      </S.ErrorInSend>
                    </Col>
                  </Row>
                ) : null}
              </Main>
            </Col>
          </Row>
        </div>
      </Content>

      <ModalBp
        visible={visible}
        // bodyText={inputsForm.masterUserEmail}
        subBodyText={
          <>
            <p>
              {I18n.get("We send the confirmation to the registered email.")}
            </p>
            <p>
              {I18n.get("Access your email and confirm your registration.")}
            </p>
          </>
        }
        okText={I18n.get("Ok")}
        // cancelText={I18n.get("No, I need to adjust.")}
        handleClickOk={() => redirectPage()}
        onCancel={() => setVisible(false)}
        // typeModal="isConfirm"
      />
    </Unlogged>
  );
};
