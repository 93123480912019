import axios from "axios";

import Config from "../../config";

import { REFERENCE_GET } from "./actions";
import { BP_API } from "../bp/actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const api_get_reference = (data) => {
  return (dispatch) => {
    let endpoint = `${Config.API_BASE_URL}/reference`;

    dispatch(BP_API.get_domain(data.locale));

    return axios
      .post(endpoint, data)
      .then((response) => {
        dispatch({
          type: REFERENCE_GET,
          payload: response.data,
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export default api_get_reference;
