import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { WIKIS_API } from '../../actions/wikis/actions';

import PartnerTopMenu from '../partner/_top-menu';
import PartnerUserTopMenu from '../partner/_top-menu_user';
// import PartnerWhatIfTopMenu from '../../modules/whatif/components/_top-menu-partner';
// import LeaderTopMenu from '../leader/_top-menu';
import ClientTopMenu from '../client/_top-menu';
import ClientUserTopMenu from '../client-user/_top-menu';
import BPoolTopMenu from '../bpool/_top-menu';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_knowledge: (data) => {
            dispatch(WIKIS_API.get_knowledge(data));
        }
    };
};

class TopMenu extends React.Component {

    constructor(props) {
        super();
    }

    render() {

        // if (this.props.auth.is_partner_user) {
        //     return (<PartnerUserTopMenu {...this.props} />);
        // }

        if (this.props.auth.is_partner) {
            return (<PartnerTopMenu {...this.props} />);
        }

        if (this.props.auth.is_client) {
            return (<ClientTopMenu {...this.props} />);
        }

        if (this.props.auth.is_client_user) {
            return (<ClientUserTopMenu {...this.props} />);
        }

        if (this.props.auth.is_bpool || this.props.auth.is_master) {
            return (<BPoolTopMenu {...this.props} />);
        }

        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
