import styled from "styled-components";

export const TitleCentral = styled.div`
  display: flex;
  justify-content: center;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    color: #000000;
  }
`;

export const WrapperSelect = styled.div`
  margin-bottom: 30px;

  .ant-select-disabled .ant-select-selection {
    border: 1px solid;
  }
`;

export const Explanation = styled.div`
  text-align: center;
  margin: 10px 0 30px 0;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
  color: #000000;
`;

export const Box = styled.div`
  padding: 15px;
  background: #ffffff;
  border-radius: 10px;
`;

export const SelectClickButton = styled.button`
  border-radius: 20px;
  border: 0.5px solid;
  border-color: ${(props) => (props.active === true ? "#1883FF;" : "#000000;")};
  background: ${(props) =>
    props.active === true ? "#1883FF;" : "transparent;"};
  color: ${(props) => (props.active === true ? "#FFFFFF;" : "#000000;")};
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 10px 20px 0;
`;

export const ContentDatePicker = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .ant-calendar-picker {
    width: 100%;
  }
`;

export const ContentSelectHours = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ButtonSave = styled.button`
  width: 100%;
  font-size: 16px;
  padding: 16px 40px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  text-align: center;
  border: 1px solid;
  border-color: #1883ff;
  color: #fff;
  background: #1883ff;
  border-color: #1883ff;
  border-radius: 28px;

  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};

  span {
    margin-top: 3px;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 12px 35px;
  }
`;

export const BoxTimeZone = styled.div`
  width: max-content;
  text-align: center;
  border: 1px solid #979797;
  padding: 5px 20px;
  margin-bottom: 20px;
`;

export const ErrorSend = styled.div`
  color: #ff0000;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 15px;
`;
