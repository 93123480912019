import React, { useState } from 'react';
import { DatePicker as DatePickerAntd } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

import * as S from './styles';

export const DatePicker = ({
  defaultValue,
  format,
  onChange,
  disabledDate,
  disabledInput,
  placeholder,
  label,
  required,
  error,
  clearError,
  resetInput
}) => {
  const [dateInternal, setDateInternal] = useState(null);
  const handleChange = (date, dateString) => {
    onChange(dateString);
    if (dateString) {
      setDateInternal(dateString);
      if (dateString !== defaultValue) {
        if (clearError) {
          clearError();
        }
      }
    } else {
      setDateInternal(null);
    }
  };
  return (
    disabledInput || resetInput ? (
      <S.WrapperDisabled>
        <label className="label">
          {required ? (
            <span>*</span>
          ) : null}
          {label}
        </label>
        <S.InputFakeDisabled>
          <p>{placeholder}</p>
          <CalendarOutlined />
        </S.InputFakeDisabled>
      </S.WrapperDisabled>
    ) : (
      <S.Wrapper>
        <label className="label">
          {required ? (
            <span>*</span>
          ) : null}
          {label}
        </label>
        <DatePickerAntd
          defaultValue={defaultValue}
          format={format}
          onChange={handleChange}
          disabledDate={disabledDate}
          placeholder={placeholder}
          className={error ? "datepicker-error" : ""}
        />
        {error ? <S.InfoError>{error}</S.InfoError> : null}
      </S.Wrapper>
    )
  );
};
