import React from 'react';


import { I18n } from 'aws-amplify';


export default class ClientDetailReceita extends React.Component {

    constructor(props) {
        super();
    }

    componentDidMount() {
    }

    render() {

        //const item = this.props.item;



        const item = this.props.item && this.props.item.receitaFederal || {};

        const item2 = this.props.item && this.props.item.protestos || {};

        if (!item) return null;

        return (
            <section>

                <h3 className="title is-4">{I18n.get("Dados da Receita Federal")}</h3>

                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Empresa")}</h3>

                        <div className="content">

                            <table className="table is-narrow">
                                <tbody>

                                    <tr>
                                        <td className="card-label">{I18n.get("Nome")}</td>
                                        <td>{item.nome}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Cnpj")}</td>
                                        <td>{item.cnpj}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Tipo")}</td>
                                        <td>{item.tipo}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Capital Social")}</td>
                                        <td>{item.capital_social}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Status")}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Abertura")}</td>
                                        <td>{item.abertura}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Natureza")}</td>
                                        <td>{item.natureza_juridica}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Situação")}</td>
                                        <td>{item.situacao}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Email")}</td>
                                        <td>{item.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Telefone")}</td>
                                        <td>{item.telefone}</td>
                                    </tr>
                                    <tr>
                                        <td className="card-label">{I18n.get("Endereço")}</td>
                                        <td>
                                            {item.logradouro} {item.numero} {item.complemento} <br />
                                            {item.bairro} {item.cep} {item.municipio} {item.uf}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <br />

                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Atividade Principal")}</h3>

                        <div className="content">

                            <table className="table is-narrow">
                                <tbody>
                                    {item.atividade_principal && item.atividade_principal.map((item, index) =>
                                        (<tr key={index}>
                                            <td>
                                                {item.code} - {item.text}
                                            </td>
                                        </tr>)
                                    )}

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <br />

                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Atividade Secundária")}</h3>

                        <div className="content">

                            <table className="table is-narrow">
                                <tbody>
                                    {item.atividades_secundarias && item.atividades_secundarias.map((item, index) =>
                                        (<tr key={index}>
                                            <td>
                                                {item.code} - {item.text}
                                            </td>
                                        </tr>)
                                    )}

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <br />

                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Sócios")}</h3>

                        <div className="content">

                            <table className="table is-narrow">
                                <tbody>
                                    {item.qsa && item.qsa.map((item, index) =>
                                        (<tr key={index}>
                                            <td>
                                                {item.nome} - {item.qual}
                                            </td>
                                        </tr>)
                                    )}

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <br />

                <h3 className="title is-4">{I18n.get("Consulta de protestos")}</h3>

                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Protestos")}</h3>

                        <div className="content">

                            <table className="table is-narrow">
                                <tbody>

                                    <tr>
                                        <td className="card-label">{I18n.get("Quantidade")}</td>
                                        <td>{item2.qtd}</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </section>
        );
    }
}
