import React from "react";

import * as S from "./styles";

export const RenderInfoLabel = ({ label, info }) => {
  const renderText = (text) => {
    if (text === 0 || text === "0") {
      return text;
    } else if (text) {
      return text;
    } else {
      return "---";
    }
  };

  return (
    <S.Wrapper>
      <label>{renderText(label)}</label>
      <p>{renderText(info)}</p>
    </S.Wrapper>
  );
};
