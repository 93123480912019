import React from 'react';

import { I18n } from 'aws-amplify';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Config from '../../config';

import { CLIENT_API } from '../../actions/client/actions';

import LoadingPage from '../pages/loading';

const mapStateToProps = state => {

    return { client: state.client, auth: state.auth };
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: data => {
            dispatch(CLIENT_API.confirm(data));
        }
    };
};

class VerifyEmail extends React.Component {

    constructor(props) {

        super();

        this.state = {
            verificationToken: props.match.params.verificationToken
        };
    }

    componentWillMount() {
        this.props.onSubmit(this.state);
    }

    render() {
        if (!this.props.client || !this.props.client.id)
            return (<LoadingPage></LoadingPage>)

        if (this.props.client && this.props.client.completedOn) {
            return (<Redirect to="/home" />);
        }

        if (Config.moduloNewRegisterClient) {
            return (<Redirect to={`/cliente/cadastre-se/responsavel/${this.props.client.id}`} />);
        }

        if (this.props.client && this.props.client.step) {
            return (<Redirect to={'/cliente/passo-' + this.props.client.step} />);
        }

        return (<LoadingPage>
        </LoadingPage>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
