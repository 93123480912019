import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import styled from 'styled-components';
import constants from '../../../constants';

import {
    Row,
    Col,
} from 'antd';


import Loading from '../../../components/pages/loading';
import { PARTNER_API } from '../../../actions/partner/actions';
import AppWrapper from '../../components/AppWrapper';
import ContainerPage from '../../components/ContainerPage';
import SubHeader from '../../components/SubHeader';
import { SimpleHeader } from '../../components/HeadersTop';
import { TitleBorder } from '../../components/Titles';

import CreateAsset from './components/FormCreateAsset';
import CardAsset from './components/CardAsset';
import { Constants } from '@aws-amplify/core';

const RowContainer = styled(Row)`
  margin-bottom: 80px;

  .WrapperForm {
      padding: 0px 40px 0 0;
  }
`

const mapStateToProps = state => {
    return Object.assign({}, state.partner, state);
};

const mapDispatchToProps = (dispatch) => {
    return {
        create_asset: data => {
            dispatch(PARTNER_API.create_asset(data));
        },

        get_assets: data => {
            dispatch(PARTNER_API.get_assets(data));
        },
    }
};


class PartnerCreateAssets extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partnerId != prevProps.partnerId) {
            this.props.get_assets(this.props.partnerId);
        }
    }

    componentDidMount() {
        this.props.get_assets(this.props.partnerId);
    }

    getStatus = (value) => {

        //TODO: Find better way... GAMBI!
        let status = [...constants.ASSET_STATUS];
        for (let key in status) {
            status[key].label = I18n.get(status[key].label);
        }

        const item = status.find(el => el.code == value);

        return item.label;
    }

    render() {

        if (!this.props.partnerId && this.props.auth.logged_user) return null;

        const {
            partnerAssets,
            errors
        } = this.props;

        const {
            assetTypes,
            assetSpecialisms,
            assetComplexity,

        } = this.props.bp;

        return (
            <AppWrapper>
                <SubHeader
                    titlePage="Central de assets"
                />
                <ContainerPage>
                    <SimpleHeader
                        title=""
                        description="Cadastre um Novo Asset. Procure detalhar o serviço na DESCRIÇÃO, deixando claro quais são os entregáveis, o processo para  a execução e numero de refações ou ajustes inclusos. Quanto mais detalhado o escopo, melhor."
                    />

                    <RowContainer>
                        <Col sm={12}>
                            <TitleBorder>{I18n.get("Monte seu asset")}</TitleBorder>
                            <div className="WrapperForm">
                                <CreateAsset
                                    assetTypes={assetTypes}
                                    assetSpecialisms={assetSpecialisms}
                                    assetComplexity={assetComplexity}
                                    create={this.props.create_asset}
                                    partnerId={this.props.partnerId}
                                    formErrors={errors}
                                    partnerAssets={partnerAssets && partnerAssets}
                                />
                            </div>
                        </Col>
                        <Col sm={2}></Col>
                        <Col sm={10} className="lista-assets">
                            <TitleBorder>{I18n.get("Assets criados")}</TitleBorder>

                            {
                                partnerAssets && partnerAssets.map((item, index) => {

                                    let colorTag = "";

                                    switch (item.status) {
                                        case 2:
                                            colorTag = ""
                                            break;
                                        case 4:
                                            colorTag = "#175907"
                                            break;
                                        case 6:
                                            colorTag = "#990000"
                                            break;
                                        default:
                                            break;
                                    }

                                    return (
                                        <CardAsset
                                            statusColor={colorTag}
                                            status={this.getStatus(item.status)}
                                            asset={item}
                                            assetTypes={assetTypes}
                                            assetSpecialisms={assetSpecialisms}
                                            assetComplexity={assetComplexity}
                                            key={index}
                                        />
                                    )
                                })
                            }
                        </Col>
                    </RowContainer>

                </ContainerPage>
            </AppWrapper>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerCreateAssets);
