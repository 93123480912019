import { I18n } from "aws-amplify";
import constants from "../../../../../../../../constants";

const type = ({ item }) => {
  switch (item?.paymentMethod) {
    case "PIX":
      return {
        label: I18n.get("Pix"),
        info: `${item?.pix}`,
      };
      break;
    case "TRANSFER":
      const bank = constants?.BANK_IDS?.find(
        (bank) => bank?.code === item?.bankId
      );
      return {
        label: I18n.get("Transferência"),
        info: `AG: ${item?.bankBranch} C/C ${item?.bankAccount} - ${bank?.label}`,
      };
      break;
    case "SWIFT":
      return {
        label: I18n.get("Swift"),
        info: "swift...",
      };
      break;
    default:
      return "";
      break;
  }
};

export const formatPaymentBeneficiaries = (array) => {
  if (array?.length) {
    const arrayFormatted = array?.map((item) => ({
      code: item?.id,
      label: `${type({ item })?.label} - ${type({ item })?.info}`,
    }));
    return arrayFormatted;
  } else {
    return [];
  }
};
