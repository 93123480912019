import React from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';

class ReviewStep2 extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    getProductName() {

        var name = '';

        if (!this.props.client.brands) return name;

        this.props.client.brands.forEach((brand) => {

            brand.products.forEach((product) => {

                if (product.id == this.props.productId) {
                    name = `${brand.name} - ${product.name}`;
                }
            });

        });

        return name;
    }

    getBundle() {

        return this.props.bundle || {};
    }

    getVariation() {

        const bundle = this.getBundle();

        const item = bundle.variations && bundle.variations.filter((item) => item.id == this.props.variationId)[0];

        return item || {};

    }

    getSpecialim(code) {

        if (!code) return {};
        if (!this.props.bp.assetSpecialisms) return {};

        const item = this.props.bp.assetSpecialisms.filter((s) => s.code == code);

        if (item && item.length) {
            return item[0];
        }
        else {
            return {};
        }
    }

    getAssetType(code) {

        if (!code) return {};
        if (!this.props.bp.assetTypes) return {};

        const item = this.props.bp.assetTypes.filter((t) => t.code == code);

        if (item && item.length) {
            return item[0];
        }
        else {
            return {};
        }
    }

    render() {

        return (
            <div>

                <h1 className="title-bpool medium">
                    <span>2.</span>{I18n.get("Escopo Criativo")}</h1>

                <div className="card reviewed-card">
                    <div className="card-content">

                        <h1 className="title-bpool medium">{I18n.get("Evento customizado")}</h1>

                        <br />

                        <p>
                            <strong>{I18n.get("Briefing:")}</strong> &nbsp;
                            {this.props.customEvent && this.props.customEvent.briefing}
                        </p>

                        <br /><br />
                    </div>
                </div>

                {this.props.enableLinkButton && <>

                    <Link to={`/cliente/projetos/${this.props.match.params.projectId}/passo-2`} className="button is-link-to-return">{I18n.get("Redefinir")}</Link>

                </>}

            </div>
        );
    }
}

export default ReviewStep2;