import styled from "styled-components";
import * as MediaQuery from "../../../utils/mediaQuery";

export const Home = styled.div`
    padding: 120px 0 90px;
    font-family: 'HelveticaNeue', sans-serif;
    background-color: #F3F3F3;

    h1, p{
        font-family: 'HelveticaNeue', sans-serif;
    }
`;
   
export const BoxesHome = styled.div`
        > div {
            margin-top: 70px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @media (max-width: 1024px) {
                gap: 30px;
            }
        }

        > div:first-child {

            > div:first-child, > div:nth-child(2) {
                flex: 0 1 35%;

                @media (max-width: 1024px) {
                    flex: 0 1 48%;
                }
                @media (max-width: 768px) {
                    flex: 0 1 100%;
                }
            }

            > div:nth-child(3) {
                flex: 0 1 25%;

                @media (max-width: 1024px) {
                    flex: 0 1 48%;
                }
                @media (max-width: 768px) {
                    flex: 0 1 100%;
                }
            }

           
        }

        > div:nth-child(2) {

            > div:nth-child(1) {
                flex: 0 1 72.5%;
                @media (max-width: 768px) {
                    flex: 0 1 100%;
                }
            }

            > div:nth-child(2) {
                flex: 0 1 25%;
                @media (max-width: 768px) {
                    flex: 0 1 100%;
                }
            }
        }
`;