import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Info } from "./Info";
import * as S from "./styles";
import { useBpayAdmin } from "../../../../../../../../hooks/useBpay/useBpayAdmin.hook";
import { ButtonBpool } from "../../../../../../../../components/UI/ButtonBpool";
import { Textarea } from "../../../../../../../../components/UI/Textarea";
import { NotificationBPool } from "../../../../../../../../components/UI/NotificationBPool";
import { NotificationOnScreen } from "../../../../../../../../components/UI/NotificationOnScreen";
import { InfoBilling } from "../../../../../components/InfoBilling";
import { InfosBillingAccount } from "../../../../../components/TableBPayments/Drawers/DrawerBillingInvoice/InfosDrawer/infosBillingAccount";
import { DownloadNF } from "../../../../DownloadNF";

export const InfosDrawer = ({ data, loading, handleCloseDrawer, refetch }) => {
  const { putTextInfosSpecifics } = useBpayAdmin();
  const [isSendingInfosSpecifics, setIsSendingInfosSpecifics] = useState(false);
  const [infosSpecifics, setInfosSpecifics] = useState("");

  useEffect(() => {
    if (data?.specificInformations) {
      setInfosSpecifics(data?.specificInformations);
    }
  }, [data]);

  const sendInfosSpecifics = async () => {
    try {
      setIsSendingInfosSpecifics(true);
      const objSend = {
        bPayId: data?.bPayId,
        bPaymentId: data?.bPaymentId,
        specificInformations: infosSpecifics,
      };
      await putTextInfosSpecifics(objSend);
      handleCloseDrawer();
      refetch();
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("Informações específicas salvas"),
        duration: 3,
      });
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao salvar informações específicas"),
        duration: 3,
      });
    } finally {
      setIsSendingInfosSpecifics(false);
    }
  };

  return (
    <S.Wrapper>
      <h3>{I18n.get("Dados do BPayment")}</h3>
      <S.Infos>
        <Info label={I18n.get("SKU")} info={data?.sku} loading={loading} />
        <Info
          label={I18n.get("Fornecedor")}
          info={data?.emailPrimary}
          loading={loading}
        />
        <Info
          label={I18n.get("Valor Parceiro")}
          info={data?.totalFmt}
          loading={loading}
        />
        <Info
          label={I18n.get("Valor Fee")}
          info={data?.totalFeeFmt}
          loading={loading}
        />
      </S.Infos>

      {data?.billing?.clientCompany?.registrationName ? (
        <>
          <div
            style={{
              padding: "16px 0",
              borderTop: "1px solid #e8e8e8",
              marginTop: 32,
            }}
          >
            <S.Label>{I18n.get("Faturamento")}</S.Label>
          </div>
          <S.Infos>
            <Info
              label={I18n.get("Razão Social")}
              info={data?.billing?.clientCompany?.registrationName}
              loading={loading}
            />
            <Info
              label={I18n.get("CNPJ")}
              info={data?.billing?.clientCompany?.registrationId}
              loading={loading}
            />
          </S.Infos>
          <Info
            label={I18n.get("Endereço")}
            info={`${data?.billing?.clientCompany?.addressLine1}${
              data?.billing?.clientCompany?.addressLine2
                ? `, ${data?.billing?.clientCompany?.addressLine2}`
                : ""
            }`}
            loading={loading}
          />
          <S.Infos>
            <Info
              label={I18n.get("CEP")}
              info={data?.billing?.clientCompany?.zipCode}
              loading={loading}
            />
            <Info
              label={I18n.get("Cidade/Estado")}
              info={`${data?.billing?.clientCompany?.city} - ${data?.billing?.clientCompany?.state}`}
              loading={loading}
            />
            <Info
              label={I18n.get("País")}
              info={data?.billing?.clientCompany?.country?.name}
              loading={loading}
            />
          </S.Infos>
        </>
      ) : null}

      {data?.registrationName ? (
        <>
          <div
            style={{
              padding: "16px 0",
              borderTop: "1px solid #e8e8e8",
              marginTop: 32,
            }}
          >
            <S.Label>{I18n.get("Pagamento")}</S.Label>
          </div>
          <S.Infos>
            <Info
              label={I18n.get("Razão Social")}
              info={data?.registrationName}
              loading={loading}
            />
            <Info
              label={I18n.get("CNPJ")}
              info={data?.registrationId}
              loading={loading}
            />
            <Info
              label={I18n.get("CEP")}
              info={data?.zipCode}
              loading={loading}
            />
          </S.Infos>
          <Info
            label={I18n.get("Endereço")}
            info={`${data?.addressLine1}${
              data?.addressLine2 ? `, ${data?.addressLine2}` : ""
            }`}
            loading={loading}
          />
          <S.Infos>
            <Info
              label={I18n.get("Cidade/Estado")}
              info={`${data?.city} - ${data?.state}`}
              loading={loading}
            />
            <Info
              label={I18n.get("País")}
              info={data?.country?.name}
              loading={loading}
            />
          </S.Infos>
          <div style={{ marginBottom: 32 }}>
            <InfoBilling data={data?.dataBilling} isLoading={loading} />
          </div>
        </>
      ) : null}

      <DownloadNF file={data?.file} title={I18n.get("Baixar NF")} />

      <InfosBillingAccount
        data={data?.paymentBeneficiaryResponse}
        loading={loading}
      />

      <div>
        <S.Label>{I18n.get("Informações específicas")}</S.Label>
        <Textarea
          id="infosSpecifics"
          label={I18n.get("Escreva aqui")}
          rows={2}
          defaultValue={infosSpecifics}
          onChange={(value) => setInfosSpecifics(value)}
          error=""
          loading={loading}
        />
        <ButtonBpool
          full
          theme="tertiary"
          onClick={sendInfosSpecifics}
          text={I18n.get("Atualizar")}
          disabled={infosSpecifics ? false : true}
          loading={isSendingInfosSpecifics}
        />
      </div>
    </S.Wrapper>
  );
};
