import React from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
    return {
    };
};

class Menu extends React.Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <aside className="menu">
              <p className="menu-label">{I18n.get("Líder")}</p>
              <ul className="menu-list">
                    <li className="is-disable">
                        <a>{I18n.get("Dashboard")}</a>
                    </li>
              </ul>
              <p className="menu-label">{I18n.get("Cadastro")}</p>
              <ul className="menu-list">
                <li>
                    <Link to="/lider/responsavel" className="is-black">{I18n.get("Responsável")}</Link>
                </li>
                <li>
                    <Link to="/lider/mercados-expertise" className="is-black">{I18n.get("Mercado e Expertise")}</Link>
                </li>
                <li>
                    <Link to="/lider/cases-premios" className="is-black">{I18n.get("Cases e Prêmios")}</Link>
                </li>
              </ul>
            </aside>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
