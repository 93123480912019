import React from "react";
import { connect } from "react-redux";

import { PROJECT_API } from "../actions/project/actions";
import { CLIENT_API } from "../actions/client/actions";
import { EXTRAS_API } from "../actions/extras/actions";
import { scrollToTop } from "../utils/ui";

export default function ExtrasContainer(Component) {
  const mapStateToProps = (state) => {
    return Object.assign({}, state.project, state.extras, state);
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      load_client: () => {
        return dispatch(CLIENT_API.get());
      },
      get_assets: (data) => {
        dispatch(PROJECT_API.get_assets(data));
      },
      get_project: (id) => {
        return dispatch(PROJECT_API.get(id));
      },
      get_extra: (id) => {
        return dispatch(EXTRAS_API.get(id));
      },
      create_extra: (data) => {
        return dispatch(EXTRAS_API.create(data));
      },
      update_extra: (data) => {
        return dispatch(EXTRAS_API.update_extra(data));
      },
      update_status: (data) => {
        return dispatch(EXTRAS_API.update_status(data));
      },
      upload_extra_file: (data) => {
        return EXTRAS_API.upload_file(data);
      },
      delete_extra_file: (data) => {
        dispatch(EXTRAS_API.delete_file(data));
      },
      add_quotation_item: (data) => {
        return dispatch(EXTRAS_API.create_quotation_item(data));
      },
      add_quotation_item_comment: (data) => {
        return dispatch(EXTRAS_API.create_quotation_item_comment(data));
      },
      del_quotation_item_comment: (data) => {
        return dispatch(EXTRAS_API.delete_quotation_item_comment(data));
      },
      update_quotation_item_status: (data) => {
        return dispatch(EXTRAS_API.update_quotation_item_status(data));
      },
      del_quotation_item: (data) => {
        return dispatch(EXTRAS_API.delete_quotation_item(data));
      },
      update_quotation_tax: (data) => {
        return dispatch(EXTRAS_API.update_quotation_tax(data));
      },
      create_quotation_milestone: (data) => {
        return dispatch(EXTRAS_API.create_quotation_milestone(data));
      },
      delete_quotation_milestone: (data) => {
        return dispatch(EXTRAS_API.delete_quotation_milestone(data));
      },
      upload_quotation_file: (data) => {
        return EXTRAS_API.upload_quotation_file(data);
      },
      delete_quotation_file: (data) => {
        return dispatch(EXTRAS_API.delete_quotation_file(data));
      },
      updateExtraQuotation: (data) => {
        return dispatch(EXTRAS_API.update_quotation(data));
      },
      updateExtraQuotationStatus: (data) => {
        return dispatch(EXTRAS_API.update_quotation_status(data));
      },
      exportExtraQuotation: (data) => {
        return dispatch(EXTRAS_API.export_quotation(data));
      },
      create_billing: (data) => {
        return dispatch(EXTRAS_API.create_billing(data));
      },
      update_billing: (data) => {
        return dispatch(EXTRAS_API.update_billing(data));
      },
      delete_billing: (data) => {
        return dispatch(EXTRAS_API.delete_billing(data));
      },
      upload_billing_file: (data) => {
        return EXTRAS_API.upload_billing_file(data);
      },
      delete_billing_file: (data) => {
        return dispatch(EXTRAS_API.delete_billing_file(data));
      },
      upload_milestone_extra: (data) => {
        return dispatch(EXTRAS_API.upload_milestone_extra(data));
      },
      delete_file_milestone_extra: (data) => {
        return dispatch(EXTRAS_API.delete_file_milestone_extra(data));
      },
      confirm_milestone_extra: (data) => {
        return dispatch(EXTRAS_API.confirm_milestone_extra(data));
      },
      update_po: (data) => {
        return dispatch(EXTRAS_API.update_po(data));
      },
    };
  };

  class ExtrasContext extends React.Component {
    constructor(props) {
      super(props);
    }


    componentDidMount() {
      scrollToTop();

      if (
        this.props.match.params.projectId &&
        this.props.project.projectId != this.props.match.params.projectId
      ) {
        var data = {
          clientId: this.props.client.clientId,
          projectId: this.props.match.params.projectId,
        };

        this.props.get_project(data);
        this.props.get_assets(data);
      }

      if (!this.props.bp.assets || !this.props.bp.assets.length) {
        var data = {};

        if (this.props.match.params.projectId) {
          data = {
            projectId: this.props.match.params.projectId,
          };
        }

        this.props.get_assets(data);
      }

      if (this.props.match.params.extraId) {
        var data = {
          projectId: this.props.match.params.projectId,
          extraId: this.props.match.params.extraId,
        };

        this.props.get_extra(data);
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    componentWillUnmount() {}

    render() {
      return <Component {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ExtrasContext);
}
