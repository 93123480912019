import React from 'react';
import { I18n } from 'aws-amplify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import { Button, notification, Icon } from 'antd';

import Config from '../../config';

import { BP_PROJECTS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_client_project_delete = (projectId) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            // /api/client/delete-project/{projectId}

            return axios.delete(`${Config.API_BASE_URL}/bp/delete-project/${projectId}`, config)
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_client_project_delete;
