import React from "react";
import { I18n } from "aws-amplify";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import { PARTNER_API } from "../../actions/partner/actions";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";

import Steps from "./steps";

import PartnerInvitationState from "../shared/parner-invitation-status";

import Heading from "./_heading";

import EventDetails from "../_project-details-eventCustom";
import BPitchDetails from "../_project-details-bpitch";

import BPitchNda from "./_bpitch-nda";

import CheckboxField from "../form/checkbox-field";
import TextAreaField from "../form/textarea-field";
import SubmitButtonField from "../form/submit-button-field";

import { CardBorder } from "../../modules/components/Cards";

import NakedModal from "../ui/naked-modal";

import { Row, Col, Input, Select, Button, Form } from "antd";

const { Option } = Select;

const mapStateToProps = (state) => {
  return Object.assign({}, state, state.partner);
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_partner: (data) => {
      return dispatch(PARTNER_API.get(data));
    },
    update_invitation: (data) => {
      return dispatch(PARTNER_API.update_invitation(data));
    },
    update_match_nda: (data) => {
      return dispatch(PARTNER_API.update_match_nda(data));
    },
    update_match_nda_upload: (data) => {
      return dispatch(PARTNER_API.update_match_nda_upload(data));
    },
    get_userop: (data) => {
      dispatch(PARTNER_API.get_userop(data));
    },
  };
};

// Atualizado

class ProjectDefintionStep2 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      fields: {
        isNonCompeteAware: {
          label: "",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "isNonCompeteAware"),
          successIcon: false,
          visited: true,
        },
        reason: {
          label: "",
          onChange: this.onChangeForm.bind(this, "reason"),
          successIcon: false,
          visited: true,
        },
        isQuotationDeadlineAware: {
          label: "",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(
            this,
            "isQuotationDeadlineAware"
          ),
          successIcon: false,
          visited: true,
        },
      },
      userOp: null,
      form: {},
      errors: {},
      task: {},
      declineIsOpen: false,
    };
  }

  componentDidMount() {
    this.props.get_partner();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value = this.props[item] || "";
        } else if (
          typeof nextState.form !== "undefined" &&
          typeof this.props[item] !== "undefined"
        ) {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = checked;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onSubmitForm(accepted, e) {
    e.preventDefault();

    let operador = "";
    let userOpactive =
      this.props.usersOperationSelect &&
      this.props.usersOperationSelect.find(
        (s) => s.username == this.props.username
      );

    if (this.state.userOp) {
      operador = this.state.userOp;
    } else if (userOpactive) {
      operador = userOpactive.username;
    } else {
      operador = this.props.usersOperationSelect[0].username;
    }

    var data = {
      projectId: this.props.match.params.projectId,
      ...this.state.form,
      partnerUsername: operador,
      accepted: accepted,
    };

    var submit_task = new Task();

    submit_task.start();

    this.setState({ task: submit_task });

    this.props.update_invitation(data).then(({ task, errors }) => {
      if (accepted === true) {
        this.setState({ task: task, errors: errors });
      } else {
        this.setState({
          task: task,
          errors: errors,
          declineIsOpen: !task.result,
        });
      }
    });
  }

  getNonCompete(days) {
    var txt = I18n.get("Nenhum");

    if (days == 0) txt = I18n.get("DURANTE O PROJETO");

    if (days > 0)
      txt = I18n.get("DURANTE O PROJETO @@days@@ dias").replace(
        "@@days@@",
        days
      );

    return txt;
  }

  handleSelectUser = (value) => {
    this.setState({
      userOp: value,
    });
  };

  render() {
    const partner = this.props.partner || {};
    const project = this.props.project || {}; 

    
   
    if (partner.partnerId && !this.props.usersOperationSelect) {
      this.props.get_userop(this.props.partner.partnerId);
    }

    const isSelectedPartner =
      project.selectPartner && project.selectPartnerId == partner.partnerId;

    let userOpactive =
      this.props.usersOperationSelect &&
      this.props.usersOperationSelect.find(
        (s) => s.username == this.props.username
      );

      const invitation =
      (partner.projectInvitations &&
        partner.projectInvitations
          .filter((item) => item.projectId === this.props.match.params.projectId)
          .reverse()
          .find(() => true)) ||
      {};

    const projectMatch =
      (partner.projectMatches &&
        partner.projectMatches.find(
          (item) => item.projectId == this.props.match.params.projectId
        )) ||
      {};

      
      const ndaActionRequired =
      project.isPartnerSelectionBidding && projectMatch.ndaAccepted != true;

    const disableQuotation =
    invitation.expired === true ||
    invitation.accepted === false ||
    isSelectedPartner;

    const disableMessaging =
    this.props.settings && !this.props.settings.enableMessaging;

    const nonCompeteMessage = I18n.get(
      "Ao aceitar esse projeto, você automaticamente se comprometerá com um NON-COMPETE @@nonCompete@@ na categoria @@cat@@."
    )
    .replace(
      "@@nonCompete@@",
        this.getNonCompete(this.props.nonCompeteInDays)
      )
      .replace("@@cat@@", this.props.categoryName);

      
      return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-">
                <p className="title">
                  {I18n.get("Parceiro Convidado")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6">
                <Steps
                  active={1}
                  projectId={this.props.match.params.projectId}
                  disabled={disableQuotation ? [3] : []}
                  hidden={disableMessaging ? [5] : []}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="client-step client-step-1 passo2 project-definition"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <br className="brm" />
          <br className="brm" />
          <br className="brm" />
          <br className="brm" />

          <div
            className="container client-container-1"
            style={{ paddingTop: "100px" }}
          >
            <CardBorder>
              <Heading {...this.props} />
            </CardBorder>

            <div className="columns">
              <div className="column is-12">
                <BPitchNda {...this.props} />

                <br />
                <br />

                {project && project.isCustomEvent && (
                  <>
                    {projectMatch.ndaAccepted === true && (
                      <>
                        <EventDetails {...this.props} />
                      </>
                    )}
                  </>
                )}

                {project && project.isBPitch && (
                  <>
                    {projectMatch.ndaAccepted === true && (
                      <>
                        <BPitchDetails {...this.props} />
                      </>
                    )}
                  </>
                )}

                <br />

                {ndaActionRequired === false &&
                  invitation.actionRequired === true && (
                    <>
                      {invitation.requiresNonCompete && (
                        <>
                          <CheckboxField
                            {...this.state.fields.isNonCompeteAware}
                            label={nonCompeteMessage}
                            checked={this.state.form.isNonCompeteAware}
                            error={this.state.errors.isNonCompeteAware}
                          />
                        </>
                      )}

                      <CheckboxField
                        {...this.state.fields.isQuotationDeadlineAware}
                        label={`${I18n.get(
                          "Me comprometo com a entrega da proposta na data atualizada de"
                        )} ${project.biddingPhaseQuotationDeadline} `}
                        checked={this.state.form.isQuotationDeadlineAware}
                        error={this.state.errors.isQuotationDeadlineAware}
                      />
                    </>
                  )}
              </div>
            </div>

            {ndaActionRequired === false &&
              invitation.actionRequired === true && (
                <>
                  {this.props.usersOperationSelect && (
                    <>
                      <div className="columns">
                        <div className="column is-half">
                          <p>
                            <strong>{I18n.get("Usuário Responsável:")}</strong>
                          </p>

                          <Select
                            defaultValue={
                              userOpactive
                                ? userOpactive.username
                                : this.props.usersOperationSelect[0].username
                            }
                            onChange={(e) => this.handleSelectUser(e)}
                          >
                            {this.props.usersOperationSelect &&
                              this.props.usersOperationSelect.map(
                                (item, index) => {
                                  return (
                                    <Option
                                      key={item.username}
                                      value={item.username}
                                    >
                                      {item.name}
                                    </Option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    <div style={{ display: "inline-block" }}>
                      <SubmitButtonField
                        label={I18n.get("Montar proposta")}
                        className="button bt-bpool"
                        onClick={this.onSubmitForm.bind(this, true)}
                        task={this.state.task}
                      />
                    </div>

                    <div
                      style={{ display: "inline-block", marginLeft: "2rem" }}
                    >
                      <SubmitButtonField
                        label={I18n.get("Mudei de ideia. Não vou participar")}
                        className="button bt-bpool grey"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ declineIsOpen: true });
                        }}
                        task={this.state.task}
                      />
                    </div>
                  </div>
                </>
              )}

            <br />

            {!invitation.actionRequired && (
              <PartnerInvitationState item={invitation} />
            )}

            <br />
            <br />

            <div style={{ display: "flex", flexDirection: "row" }}>
              {this.props.projectId && !isSelectedPartner && (
                <>
                  {invitation.accepted && (
                    <div>
                      <NavLink
                        to={`/parceiro/eventos/${this.props.projectId}/convite-passo-2`}
                        className="button bt-bpool"
                        style={{ minWidth: "300px" }}
                      >
                        {I18n.get("Perguntas & Respostas")}
                      </NavLink>
                    </div>
                  )}

                  {invitation.accepted && (
                    <div style={{ marginLeft: "1rem" }}>
                      <NavLink
                        to={`/parceiro/eventos/${this.props.projectId}/convite-passo-3`}
                        className="button bt-bpool black"
                        style={{ minWidth: "300px" }}
                      >
                        {I18n.get("Proposta")}
                      </NavLink>
                    </div>
                  )}
                </>
              )}

              {this.props.projectId && isSelectedPartner && (
                <>
                  {invitation.accepted && (
                    <div style={{ marginLeft: "1rem" }}>
                      <NavLink
                        to={`/projetos/${this.props.projectId}/detalhamento-passo-2`}
                        className="button bt-bpool black"
                        style={{ minWidth: "300px" }}
                      >
                        {I18n.get("Proposta Comercial")}
                      </NavLink>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />
        </div>

        <NakedModal isOpen={this.state.declineIsOpen}>
          <div className="card" style={{ margin: "0.5rem" }}>
            <header className="card-header">
              <p className="card-header-title">{I18n.get("Convite")}</p>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ declineIsOpen: false });
                }}
                className="card-header-icon"
                aria-label="more options"
              >
                <span className="icon">
                  <i className="fas fa-times" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div className="card-content" style={{ maxWidth: "960px" }}>
              <div className="content has-text-left">
                <form>
                  <p>
                    {I18n.get(
                      "Por favor, comente o motivo de não ter aceito participar."
                    )}
                  </p>

                  <br />

                  <TextAreaField
                    {...this.state.fields.reason}
                    value={this.state.form.reason}
                    error={this.state.errors.reason}
                  />

                  <br />
                  <br />

                  <div className="columns">
                    <div className="column is-half"></div>
                    <div className="column is-half">
                      <SubmitButtonField
                        className="button is-black is-fullwidth next"
                        label={I18n.get("DECLINAR")}
                        onClick={this.onSubmitForm.bind(this, false)}
                        task={this.state.task}
                        suppressLabel={true}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </NakedModal>
      </FillForm>
    );
  }
}

export default ProjectContainer(
  connect(mapStateToProps, mapDispatchToProps)(ProjectDefintionStep2)
);
