import React from "react";
import { I18n } from "aws-amplify";

import { Row, Col, Form, Input, Button } from "antd";

import { CardBorder } from "../../modules/components/Cards";
import FieldDataPicker from "../../components/ui/field-datepicker";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

class MilestoneForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
      }

      this.setState({
        formState: values,
      });

      this.props.onCreate(values);

      setTimeout(() => {
        this.props.form.resetFields();
      }, 2000);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (this.props.errors != prevProps.errors && this.props.errors) {
      Object.keys(this.props.errors).map((field) => {
        if (field == "name" || field == "estimatedDate") {
          this.props.form.setFields({
            [field]: {
              value: this.state.formState[field],
              errors: [new Error(this.props.errors[field].errorMessage)],
            },
          });
        }
      });
    }
  }

  render() {
    const { milestones = [], disabled = false } = this.props;

    const { getFieldDecorator } = this.props.form;

    let limitMacro = milestones && milestones.length >= 5 ? true : false;

    return (
      <CardBorder>
        <div className="form">
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col sm={8}>
                <Form.Item label={I18n.get("Nome Macro Entrega")}>
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: I18n.get("Nome deve ser informado."),
                      },
                    ],
                  })(
                    <Input
                      placeholder={I18n.get("Nome Macro Entrega")}
                      disabled={disabled || limitMacro}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col sm={8}>
                <Form.Item label={I18n.get("Data Estimada")}>
                  {getFieldDecorator("estimatedDate", {
                    rules: [
                      {
                        required: true,
                        message: I18n.get("Data Estimada deve ser informada."),
                      },
                    ],
                  })(
                    <FieldDataPicker
                      disabled={disabled || limitMacro}
                      placeholder={I18n.get("Selecione")}
                      format={dateFormatList}
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col sm={4}>
                <Form.Item className="labelClean" label="&nbsp;">
                  <Button
                    disabled={disabled || limitMacro}
                    type="primary"
                    htmlType="submit"
                  >
                    {I18n.get("Adicionar")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </CardBorder>
    );
  }
}

export default Form.create({})(MilestoneForm);
