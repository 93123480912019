import React from 'react';

const TermsClientPT = () => {

    return (
        <div class="termsContainer">
            <div class="termsContent">




                <p><strong>BOUTIQUE</strong><strong> POOL</strong></p>
                <p>TERMO DE USO</p>
                <p><strong>CLIENTE</strong></p>
                <p>Este Termo de Uso (&ldquo;Termo&rdquo;) rege o uso da plataforma online (&ldquo;Plataforma&rdquo;) criada e desenvolvida pela BOUTIQUE POOL INTERMEDIA&Ccedil;&Atilde;O E SERVI&Ccedil;OS DE MARKETING LTDA., com sede na Av. Nove de Julho 5229, 1&ordm; andar na cidade e Estado de S&atilde;o Paulo, CEP 01.406-200, inscrita no CNPJ/MF sob o n&ordm; 31.391.925/0001-34 (&ldquo;Boutique Pool&rdquo;), pelas empresas que desejam contratar servi&ccedil;os criativos de marketing de terceiros por meio da Plataforma (&ldquo;Clientes&rdquo;).</p>
                <p>A CONCORD&Acirc;NCIA AO PRESENTE TERMO &Eacute; ABSOLUTAMENTE INDISPENS&Aacute;VEL &Agrave; UTILIZA&Ccedil;&Atilde;O DA PLATAFORMA BOUTIQUE POOL E SEUS SERVI&Ccedil;OS.</p>
                <p>O Cliente dever&aacute; ler, certificar-se de haver entendido e concordar com todas as condi&ccedil;&otilde;es estabelecidas neste Termo, antes da contrata&ccedil;&atilde;o de projetos na plataforma.</p>
                <ol>
                    <li> Defini&ccedil;&otilde;es Para todos os fins e efeitos deste Termo, os seguintes termos e express&otilde;es iniciados em letra mai&uacute;scula, no singular ou no plural, ter&atilde;o os significados indicados a seguir, com a devida considera&ccedil;&atilde;o a outros termos definidos ao logo deste instrumento:</li>
                </ol>
                <p>&ldquo;Parceiro&rdquo; ou &ldquo;Parceiro Criativo&rdquo; significam tanto ag&ecirc;ncias de marketing quanto empresas e est&uacute;dios de cria&ccedil;&atilde;o, planejamento, produ&ccedil;&atilde;o de conte&uacute;do, design e/ou tecnologias em m&iacute;dias diversas, de pequeno e m&eacute;dio porte, que prestem os servi&ccedil;os de marketing dispon&iacute;veis &agrave; contrata&ccedil;&atilde;o pelos Clientes por meio da Plataforma;</p>
                <p>&ldquo;Matchmaking&rdquo; significam as etapas de sele&ccedil;&atilde;o de Parceiros Criativos promovidas pela Plataforma, com base em algoritmo propriet&aacute;rio desenvolvido pela plataforma e crit&eacute;rios espec&iacute;ficos e determinados de acordo com o Projeto solicitado pelo Cliente. Ao final da etapa de Matchmaking, a Plataforma indicar&aacute; tr&ecirc;s Parceiros Criativos ideais &agrave; demanda solicitada pelo Cliente;</p>
                <p>&ldquo;Curadoria" significa a aplica&ccedil;&atilde;o de metodologias de an&aacute;lise e sele&ccedil;&atilde;o de participantes da Plataforma de forma recorrente, utilizando interven&ccedil;&atilde;o humana da Boutique Pool, curadores e algoritmos de avalia&ccedil;&atilde;o para a homologa&ccedil;&atilde;o de Parceiros que estejam dentro dos padr&otilde;es exigidos pela Boutique Pool, para ent&atilde;o serem indicados e conectados com os Clientes , respeitando-se as particularidades e expectativas necess&aacute;rias para o bom desenvolvimento de neg&oacute;cios entre ambas as partes;</p>
                <p>&ldquo;Asset&rdquo; produto criativo precificado e com seu escopo detalhado, como por exemplo, land page, site, prancha conceito, conceito criativo, design de embalagem, design de material de ponto de venda, entre outros</p>
                <p>&ldquo;Asset Market&rdquo; lista de produtos criativos em um formato de market place categorizado por tipo e especialidade de servi&ccedil;os de comunica&ccedil;&atilde;o e marketing apresentado ao Cliente, devidamente precificada com detalhamento completo de escopo. O Cliente pode selecionar projetos no Asset Market (pacotes de assets) ou pode monte montar seu pr&oacute;prio projeto montando seu asset individual.</p>
                <p>&ldquo;Servi&ccedil;o&rdquo; ou &ldquo;Projeto&rdquo; significam os servi&ccedil;os de marketing contratados pelo Cliente dos Parceiros Criativos selecionados ou projetos desenvolvidos para os Clientes pelos Parceiros, de forma espec&iacute;fica, mediante o emprego de ferramentas da Plataforma. Soma de Assets agrupados. Os Servi&ccedil;os podem englobar servi&ccedil;os de comunica&ccedil;&atilde;o em geral, eventos, treinamento e/ou pesquisa, bem como a cria&ccedil;&atilde;o de materiais impressos, eletr&ocirc;nicos, audiovisuais ou radiof&ocirc;nicos, sujeitos &agrave;s prote&ccedil;&otilde;es legais inerentes aos Direitos Autorais definidos ao longo deste Termo.</p>
                <p>&ldquo;Concorr&ecirc;ncia&rdquo; significam etapas de uma sele&ccedil;&atilde;o espec&iacute;fica, diferente do Matchmaking, por meio da qual Parceiros Criativos poder&atilde;o ser convidados por Clientes a apresentarem propostas comerciais e criativas para um determinado Projeto. Nesses casos, Parceiro Criativo e Cliente dever&atilde;o aderir a um termo espec&iacute;fico, o qual conter&aacute; as regras da respectiva Concorr&ecirc;ncia.</p>
                <ol start="2">
                    <li>Cadastro. A Plataforma somente est&aacute; dispon&iacute;vel para pessoas jur&iacute;dicas ou f&iacute;sicas que tenham capacidade legal para contratar nos termos da Lei brasileira. N&atilde;o poder&atilde;o utilizar a Plataforma as pessoas que n&atilde;o tenham esta capacidade, os menores de idade ou Clientes cujo cadastro esteja suspenso ou cancelado.</li>
                </ol>
                <p> 2.1. Para ser um Cliente cadastrado na Plataforma, &eacute; necess&aacute;rio que se complete o formul&aacute;rio de cadastro dispon&iacute;vel na Plataforma em todos os seus campos com dados v&aacute;lidos, de maneira exata, precisa e verdadeira (&ldquo;Cadastro&rdquo;). O Cliente dever&aacute; fornecer documentos e informa&ccedil;&otilde;es que comprovem a identidade e capacidade de contratar em nome desta pessoa jur&iacute;dica, conforme lhe for solicitado para a realiza&ccedil;&atilde;o do Cadastro. Outros documentos e informa&ccedil;&otilde;es complementares poder&atilde;o ser solicitados pela Boutique Pool. O Cliente assume o compromisso de manter as informa&ccedil;&otilde;es inclu&iacute;das no Cadastro atualizadas.</p>
                <p> 2.2. A documenta&ccedil;&atilde;o fornecida pelo Cliente no Cadastro dever&aacute; ser, atual, legal e vigente. A Boutique Pool n&atilde;o assume qualquer responsabilidade de fiscaliza&ccedil;&atilde;o ativa dos Clientes, de forma que preju&iacute;zos decorrentes de informa&ccedil;&otilde;es falsas ou imprecisas ser&atilde;o de exclusiva responsabilidade dos Clientes.</p>
                <p> 2.3. No ato do Cadastro, o Cliente dever&aacute; informar um e-mail e uma senha provis&oacute;ria ser&aacute; fornecida pela Boutique Pool para acesso &agrave; Plataforma. A Boutique Pool n&atilde;o solicitar&aacute; sua senha por quaisquer outros meios, ou para quaisquer outros motivos, que n&atilde;o seja para o acesso &agrave; Plataforma.</p>
                <p> 2.4. O Cliente ser&aacute; exclusivamente respons&aacute;vel por todas as opera&ccedil;&otilde;es efetuadas no seu Cadastro, devendo guardar o sigilo de sua senha. A Boutique Pool n&atilde;o se responsabiliza por opera&ccedil;&otilde;es efetuadas em seu Cadastro. Caso perceba o uso ou o acesso n&atilde;o autorizado do seu cadastro, o Cliente dever&aacute; comunicar tal fato &agrave; Boutique Pool imediatamente para fins de suspens&atilde;o do cadastro.</p>
                <p> 2.5. O Cliente poder&aacute; solicitar o cancelamento do seu cadastro na Plataforma a qualquer momento, sem preju&iacute;zo das solicita&ccedil;&otilde;es de Servi&ccedil;o j&aacute; aceitas por Parceiros no momento do pedido de cancelamento, as quais dever&atilde;o ser conclu&iacute;das normalmente, como se permanecesse ativo o cadastro para tais fins, nos termos do Contrato, abaixo definido.</p>
                <ol start="3">
                    <li>Uso da Plataforma Mediante a conclus&atilde;o de seu Cadastro, homologa&ccedil;&atilde;o dos valores da tabela padr&atilde;o de projetos e Assets ou de seus valores espec&iacute;ficos e Assets, desde que estejam dentro dos padr&otilde;es entendidos pela Boutique Pool como adequados e, por fim, ades&atilde;o a este Termo, o Cliente poder&aacute; usar a Plataforma para contrata&ccedil;&atilde;o de Projetos e/ou Servi&ccedil;os junto aos Parceiros Criativos selecionados, utilizando-se das ferramentas dispon&iacute;veis na Plataforma, as quais incluem (i) acesso ao Asset Market para sele&ccedil;&atilde;o de Assets ou Projetos conforme Asset e/ou Projetos homologados; (ii) matchmaking de poss&iacute;veis Parceiros para a realiza&ccedil;&atilde;o de Projetos por meio de algoritmo de matching, experi&ecirc;ncia espec&iacute;fica de mercado e qualifica&ccedil;&atilde;o profissional; (iii) ambiente de defini&ccedil;&atilde;o de briefing, cronograma e escopo final; (iv) assinatura digital de Termo Espec&iacute;fico de Projeto abaixo definido; (v) sistema de rating m&uacute;tuo para avalia&ccedil;&otilde;es parciais e finais de cada Projeto; (vi) centraliza&ccedil;&atilde;o de pagamento de m&uacute;ltiplos parceiros contratados atrav&eacute;s da Plataforma; (vii) disponibiliza&ccedil;&atilde;o de dashboard informativo com os investimentos realizados atrav&eacute;s da Plataforma em cada Projeto e m&eacute;dia de avalia&ccedil;&atilde;o de cada parceiro contratado.</li>
                </ol>
                <p>3.1. Ao utilizar a Plataforma, os Clientes receber&atilde;o o direito de uso oneroso e n&atilde;o exclusivo do software que comp&otilde;e a Plataforma, para utiliza&ccedil;&atilde;o limitada a este Termo.</p>
                <p>3.2. A Boutique Pool, na qualidade de plataforma de neg&oacute;cios, envidar&aacute; seus melhores esfor&ccedil;os para fazer a curadoria dos melhores Parceiros poss&iacute;veis com &eacute;tica e qualidade criativa para os Servi&ccedil;os demandados pelo Cliente e assim promover o ambiente para que seja prestado o Servi&ccedil;o da melhor maneira poss&iacute;vel, sendo respons&aacute;vel pela auditoria das rela&ccedil;&otilde;es estabelecidas entre Cliente e Parceiro, por meio da Plataforma, trocando e excluindo Parceiros Criativos que n&atilde;o prestem os Servi&ccedil;os de acordo com as regras da Plataforma e/ou quando solicitado pelo Cliente.</p>
                <p>3.3. A aplica&ccedil;&atilde;o da Curadoria, do Matchmaking e/ou das Concorr&ecirc;ncias, e contrata&ccedil;&otilde;es devem ser realizadas de maneira &eacute;tica e respons&aacute;vel pelas partes envolvidas, com a defini&ccedil;&atilde;o das premissas de contrata&ccedil;&atilde;o antecipadamente &agrave; contrata&ccedil;&atilde;o dos Servi&ccedil;os. Os Parceiros dever&atilde;o respeitar os C&oacute;digos de &Eacute;tica e Anticorrup&ccedil;&atilde;o da Boutique Pool e dos Clientes.</p>
                <ol start="4">
                    <li>4. Fluxo da Plataforma. A Boutique Pool disponibilizar&aacute; ao Cliente e aos Parceiros Criativos, o uso da Plataforma de acordo com o seguinte fluxo:</li>
                </ol>
                <p>4.1. Para usar a Plataforma, o Cliente (com seu login e senha) selecionar&aacute; um pacote de Assets com valor pr&eacute;-definido (homologado pela sua &aacute;rea de compras no cadastro da Plataforma) e o algoritmo de matching, conforme abaixo melhor definido, indicar&aacute; tr&ecirc;s Parceiros Criativos que informar&atilde;o se conseguem ou n&atilde;o atender &agrave; demanda solicitada de acordo com as condi&ccedil;&otilde;es definidas pelo Cliente. O Cliente ent&atilde;o escolher&aacute; com qual Parceiro deseja trabalhar com base em seu portf&oacute;lio, expertise e empatia.</p>
                <ol start="5">
                    <li>5. Concorr&ecirc;ncias. Em alguns casos caber&aacute; &agrave; Boutique Pool, atrav&eacute;s da intelig&ecirc;ncia da Plataforma, organizar as Concorr&ecirc;ncias entre Parceiros Criativos, as quais ser&atilde;o realizadas mediante a ades&atilde;o pelos envolvidos de termos espec&iacute;ficos a cada Concorr&ecirc;ncia. As Concorr&ecirc;ncias ser&atilde;o realizadas no ambiente da Plataforma e/ou presencialmente. A Boutique Pool envidar&aacute; seus melhores esfor&ccedil;os para realizar as Concorr&ecirc;ncias de forma justa, cabendo aos Clientes e Parceiros Criativos se adequarem &agrave;s regras determinadas na Plataforma.</li>
                </ol>
                <ol start="6">
                    <li>6. Sele&ccedil;&atilde;o para a Presta&ccedil;&atilde;o do Servi&ccedil;o ou Projeto. Uma vez selecionado para desenvolver determinado Projeto ou prestar determinado Servi&ccedil;o, conforme os procedimentos de Curadoria e de Matchmaking realizados, o Parceiro Criativo receber&aacute; um e-mail convidando-o para participar de tal Projeto com todos os detalhes do Servi&ccedil;o e/ou Projeto, incluindo prazo de entrega e valores, dentre outras informa&ccedil;&otilde;es (&ldquo;Convite&rdquo;).</li>
                </ol>
                <p>6.1. O Parceiro ter&aacute; a obriga&ccedil;&atilde;o de indicar se deseja atender ou n&atilde;o um determinado Projeto em at&eacute; 1 (um) dia &uacute;til (entende-se at&eacute; o mesmo hor&aacute;rio do pr&oacute;ximo dia &uacute;til) contado a partir do recebimento da mensagem de indica&ccedil;&atilde;o da Boutique Pool, realizada no ambiente da Plataforma. O sil&ecirc;ncio de um Parceiro ser&aacute; interpretado pela Boutique Pool como recusa para aceite de um Projeto. Caso o prazo estabelecido neste item caia em um final de semana, ser&aacute; considerado o primeiro dia &uacute;til subsequente.</p>
                <p>6.2. Os tr&ecirc;s Parceiros selecionados que aceitaram o Convite ser&atilde;o apresentados ao Cliente e a Plataforma auxiliar&aacute; a escolha do Cliente exibindo o portf&oacute;lio, hist&oacute;rico e credenciais de tais Parceiros, assim como estimular&aacute; conversas presenciais ou realiza&ccedil;&atilde;o de calls para que o Cliente possa fazer a escolha a seu exclusivo crit&eacute;rio. </p>
                <p>6.3. O Cliente ter&aacute; que decidir dentre estes Parceiros qual &eacute; o mais adequado ao Servi&ccedil;o ou Projeto dentro do prazo que selecionou na Plataforma. Caso o Cliente n&atilde;o selecione o Parceiro dentro do respectivo prazo, o Parceiro Criativo fica autorizado a retirar-se daquele Matchmaking e/ou Concorr&ecirc;ncia espec&iacute;fica.</p>
                <ol start="7">
                    <li>7. Termo de Especifica&ccedil;&atilde;o de Projeto (&ldquo;TEP&rdquo;). Uma vez realizado o Matchmaking e selecionado o Parceiro pelo Cliente, ajusta-se na plataforma o escopo final e as respectivas macro-etapas do Projeto, e mediante o aceite final &eacute; gerado o TEP especifico daquela contrata&ccedil;&atilde;o, o qual dever&aacute; ser assinado de forma digital entre Cliente e Parceiro, com a interveni&ecirc;ncia da Boutique Pool, estabelecendo os direitos e obriga&ccedil;&otilde;es espec&iacute;ficas do Cliente e do Parceiro com rela&ccedil;&atilde;o aos Servi&ccedil;os e/ou Projeto selecionado, detalhando o Projeto a ser desenvolvido ou Servi&ccedil;o a ser prestado ao Cliente, incluindo, os prazos e forma de pagamento da remunera&ccedil;&atilde;o do Parceiro e regulamentando a rela&ccedil;&atilde;o entre estas partes.</li>
                </ol>
                <p>7.1. Qualquer Parceiro Criativo selecionado pelo Cliente para a presta&ccedil;&atilde;o de Servi&ccedil;os ou a execu&ccedil;&atilde;o de Projetos nos termos deste Termo estar&aacute; vinculado ao TEP. As obriga&ccedil;&otilde;es do Cliente assumidas junto ao Parceiro Criativo s&atilde;o exig&iacute;veis diretamente pelo Parceiro Criativo frente ao Cliente.</p>
                <ol start="8">
                    <li>8. Avalia&ccedil;&atilde;o. A Plataforma conter&aacute; ferramentas de avalia&ccedil;&atilde;o do Servi&ccedil;o ou Projeto executado pelo Parceiro de acordo com cada uma das etapas definidas no in&iacute;cio de cada Projeto/Servi&ccedil;o contratado entre os Clientes e os Parceiros. Em caso de avalia&ccedil;&otilde;es inconsistentes com as expectativas e briefing de um Cliente, determinado Parceiro dever&aacute; adequar os materiais submetidos, refazendo-os de acordo com as instru&ccedil;&otilde;es indicadas por escrito pelo respectivo Cliente. Enquanto tal adequa&ccedil;&atilde;o estiver sendo realizada, os pagamentos espec&iacute;ficos a este Parceiro poder&atilde;o ser suspensos, resguardados os valores relativos aos servi&ccedil;os conclu&iacute;dos. Uma vez que ocorra a conclus&atilde;o de eventuais ajustes ou de uma determinada etapa de contrata&ccedil;&atilde;o e mediante a avalia&ccedil;&atilde;o satisfat&oacute;ria do Cliente que contratou os Servi&ccedil;os, o pagamento espec&iacute;fico a este Parceiro ser&aacute; efetuado.</li>
                </ol>
                <p>8.1. A Plataforma limitar&aacute; a quantidade de vezes que determinado Projeto possa ser refeito ou adequado, evitando onerar demasiadamente os Parceiros. Nos casos em que determinado Cliente ultrapasse as quantidades de adequa&ccedil;&otilde;es permitidas ou altere muitas vezes o briefing e escopo dos Servi&ccedil;os em um Projeto, ficar&aacute; a crit&eacute;rio de cada Parceiro a negocia&ccedil;&atilde;o com o cliente de uma nova contrata&ccedil;&atilde;o na Plataforma, ou pela funcionalidade denominada &ldquo;extens&atilde;o de Projetos&rdquo; dispon&iacute;vel na Plataforma.</p>
                <p>8.2. As avalia&ccedil;&otilde;es realizadas na Plataforma poder&atilde;o conter informa&ccedil;&otilde;es p&uacute;blicas, desde que aprovadas previamente pelos Parceiros e Clientes, quando ent&atilde;o estar&atilde;o dispon&iacute;veis a todos os inscritos na Plataforma. As avalia&ccedil;&otilde;es dever&atilde;o ser realizadas de boa-f&eacute; e de maneira objetiva, indicando feedback para os Parceiros de maneira concisa. A Plataforma n&atilde;o disponibilizar&aacute; publicamente avalia&ccedil;&otilde;es parciais de Parceiros Criativos enquanto determinados Projetos estiverem sendo realizados.</p>
                <p>8.3. Caso determinado Parceiro discorde de uma avalia&ccedil;&atilde;o realizada, poder&aacute; solicitar sua revis&atilde;o, pela Boutique Pool, bem como o esclarecimento de eventuais d&uacute;vidas.</p>
                <p>8.4. A Boutique Pool poder&aacute; excluir da Plataforma, os Parceiros que recebam 3 ou mais avalia&ccedil;&otilde;es ruins, em contrata&ccedil;&otilde;es espec&iacute;ficas, ao longo de um per&iacute;odo de at&eacute; 6 meses. Nestes casos, a Boutique Pool informar&aacute; ao Parceiro exclu&iacute;do, por escrito, os motivos de sua exclus&atilde;o. A Boutique Pool considerar&aacute; avalia&ccedil;&otilde;es ruins quando a nota geral conferida ao t&eacute;rmino do Projeto fica abaixo de 3, regular entre 3.0 e 3.99, boa entre 4.0 e 4.74 e excelente de 4.75 a 5.</p>
                <ol start="9">
                    <li>9. Desenvolvimento e Manuten&ccedil;&atilde;o da Plataforma. Cabe &agrave; Boutique Pool desenvolver e manter atualizada a Plataforma para que esta organize as rela&ccedil;&otilde;es entre Clientes e Parceiros, de forma segura e respeitando as premissas definidas em cada Projeto. A Boutique Pool poder&aacute; interromper a disponibilidade da Plataforma a qualquer tempo em paradas programadas, sem a necessidade de aviso pr&eacute;vio aos Clientes e Parceiros, esfor&ccedil;ando-se, contudo, para que tais paradas sejam realizadas fora do hor&aacute;rio comercial. A Boutique Pool n&atilde;o &eacute; obrigada a manter a Plataforma dispon&iacute;vel indefinidamente, n&atilde;o havendo qualquer tipo de responsabilidade perante os Clientes e Parceiros em caso de uma eventual indisponibilidade n&atilde;o programada.</li>
                </ol>
                <p>9.1. A Boutique Pool n&atilde;o ser&aacute; respons&aacute;vel por qualquer v&iacute;rus que possa infectar o equipamento do Cliente como consequ&ecirc;ncia do acesso, uso ou verifica&ccedil;&atilde;o da Plataforma ou de qualquer transfer&ecirc;ncia de dados, arquivos, imagens, textos, ou &aacute;udio contidos no mesmo. O Cliente n&atilde;o poder&aacute; imputar responsabilidade alguma para a Boutique Pool, nem exigir pagamento por lucro cessante, em virtude de preju&iacute;zos resultantes da inobserv&acirc;ncia &agrave;s disposi&ccedil;&otilde;es aqui contidas, decorrentes de dificuldades t&eacute;cnicas ou falhas nos sistemas ou na Internet. A Boutique Pool n&atilde;o garante o acesso e uso continuado ou ininterrupto da Plataforma. O sistema pode eventualmente n&atilde;o estar dispon&iacute;vel devido a dificuldades t&eacute;cnicas ou falhas de Internet, ou por qualquer outra circunst&acirc;ncia alheia &agrave; vontade da Boutique Pool e em tais casos a Boutique Pool procurar&aacute; restabelecer a acessibilidade &agrave; Plataforma com a maior brevidade poss&iacute;vel, sem que isso possa imputar algum tipo de responsabilidade.</p>
                <p>9.2. &Eacute; absolutamente proibido aos Clientes qualquer a&ccedil;&atilde;o ou uso de dispositivo, software, ou outro meio com o prop&oacute;sito de interferir nas atividades e opera&ccedil;&otilde;es de Plataforma. Qualquer intromiss&atilde;o, atividade ou tentativa de viola&ccedil;&atilde;o ou que seja contr&aacute;ria &agrave;s leis, incluindo, sem limita&ccedil;&atilde;o, as que versem sobre direito de propriedade intelectual, e/ou as proibi&ccedil;&otilde;es estipuladas neste Termo ser&atilde;o pass&iacute;veis das a&ccedil;&otilde;es legais pertinentes.</p>
                <ol start="10">
                    <li>10. N&atilde;o Competi&ccedil;&atilde;o. Quando houver solicita&ccedil;&atilde;o de um Cliente, a Plataforma n&atilde;o listar&aacute; como op&ccedil;&atilde;o, os Parceiros que estiverem trabalhando com outros clientes de uma mesma categoria do Cliente. Para tanto, quando do seu cadastramento na Plataforma, cada um dos Parceiros Criativos dever&aacute; indicar as marcas de produtos e/ou servi&ccedil;os com as quais eventualmente j&aacute; trabalhe (sendo o crit&eacute;rio para tal, o faturamento de uma Nota Fiscal nos &uacute;ltimos 3 meses) para que haja o estabelecimento de pr&aacute;ticas de n&atilde;o competi&ccedil;&atilde;o e assim, um Parceiro Criativo n&atilde;o desenvolva qualquer tipo de trabalho para produtos e/ou servi&ccedil;os concorrentes.</li>
                </ol>
                <p>10.1. Para fins do estabelecimento das pr&aacute;ticas de n&atilde;o concorr&ecirc;ncia, ser&atilde;o consideradas apenas as marcas dos produtos e/ou servi&ccedil;os dentro da determinada categoria, com que determinado Parceiro Criativo trabalhe, n&atilde;o as empresas que detenham e comercializem as marcas de tais produtos e/ou servi&ccedil;os. Deste modo, os Parceiros Criativos n&atilde;o poder&atilde;o atuar apenas para marcas de produtos e/ou servi&ccedil;os concorrentes diretamente da mesma categoria, n&atilde;o para as empresas que sejam as fabricantes destes.</p>
                <p>10.2. Se um Parceiro Criativo j&aacute; possuir acordos de n&atilde;o competi&ccedil;&atilde;o com um de seus clientes quando de seu cadastramento e utiliza&ccedil;&atilde;o da Plataforma, dever&aacute; prestar esta informa&ccedil;&atilde;o quando do in&iacute;cio de seu relacionamento com a Boutique Pool, durante seu cadastramento, assinalando a op&ccedil;&atilde;o de &ldquo;non-compete ativo&rsquo;&rsquo;.</p>
                <p>10.3. Os Parceiros Criativos devem manter atualizada a listagem de empresas, produtos e servi&ccedil;os com que mantenham ou tenham mantido um relacionamento comercial nos &uacute;ltimos 3 (tr&ecirc;s) meses, para que n&atilde;o haja conflitos entre estas e eventuais Clientes que possam ser indicados aos Parceiros Criativos, nos termos deste item.</p>
                <p>10.4. Caso seja exigido um per&iacute;odo de n&atilde;o competi&ccedil;&atilde;o de um Parceiro Criativo para que este desenvolva servi&ccedil;os para um determinado Cliente, ser&aacute; considerado que tal Parceiro Criativo verificou e concordou com tal limita&ccedil;&atilde;o ao aceitar iniciar os trabalhos com tal Cliente, n&atilde;o havendo qualquer tipo de responsabilidade da Boutique Pool na hip&oacute;tese de haver demandas a respeito do per&iacute;odo de n&atilde;o competi&ccedil;&atilde;o j&aacute; aceito em determinado Projeto.</p>
                <ol start="11">
                    <li>11. Remunera&ccedil;&atilde;o e Forma de Pagamento. O Cliente dever&aacute; remunerar os Parceiros Criativos nos termos definidos para cada Servi&ccedil;o ou Projeto contratado, de acordo com cada Convite e TEP celebrado entre Cliente e Parceiros, inclusive quando houver pagamentos escalonados (&ldquo;Remunera&ccedil;&atilde;o&rdquo;). A Boutique Pool far&aacute; a gest&atilde;o do fluxo de pagamentos dos Projetos contratados, liberando os pagamentos de acordo com a conclus&atilde;o de etapas pr&eacute;-definidas entre as Partes, retendo apenas os valores que lhe couberem pela intermedia&ccedil;&atilde;o dos Servi&ccedil;os</li>
                </ol>
                <p> 11.1. Em caso de tentativas de altera&ccedil;&atilde;o de escopo pelo Cliente ap&oacute;s a aprova&ccedil;&atilde;o de um Projeto, caber&aacute; aos Parceiros Criativos informar &agrave; Boutique Pool sobre tais mudan&ccedil;as para que se evite eventuais preju&iacute;zos. Extens&atilde;o de escopos padr&atilde;o da Plataforma tamb&eacute;m poder&atilde;o ser contratados atrav&eacute;s da funcionalidade &ldquo;Extens&atilde;o de Projetos e Itens N&atilde;o Padronizados&rdquo; na plataforma, atrav&eacute;s do Menu &ldquo;Produ&ccedil;&atilde;o&rdquo; dentro de cada Projeto.</p>
                <p> 11.2. Os pagamentos realizados pelo Cliente ser&atilde;o endere&ccedil;ados &agrave; Boutique Pool, que far&aacute; toda a gest&atilde;o destes pagamentos, os repassando a cada Parceiro Criativo dentro das condi&ccedil;&otilde;es de cada Projeto. Em determinados Projetos, a Boutique Pool poder&aacute; gerir o pagamento em etapas &agrave; um Parceiro Criativo, conforme houver a conclus&atilde;o de cada uma destas etapas. Os pagamentos aos Parceiros Criativos dever&atilde;o observar, de todo modo, os prazos de pagamento dos Clientes.</p>
                <p> 11.3. As Partes acordam que o pagamento da Remunera&ccedil;&atilde;o pela Cliente ocorrer&aacute; conforme o fluxo descrito abaixo.</p>
                <p>(i) O Cliente dever&aacute; inserir o n&uacute;mero de pedido ou ordem de compras (&ldquo;Pedido&rdquo;) quando aplic&aacute;vel ou, autorizar o Faturamento na plataforma;</p>
                <p>(ii) Ap&oacute;s a inser&ccedil;&atilde;o do Pedido na Plataforma ou autoriza&ccedil;&atilde;o do faturamento, o Parceiro Criativo ser&aacute; notificado por e-mail com instru&ccedil;&otilde;es e dever&aacute;: (1) emitir uma nota fiscal contra o Cliente no valor de Remunera&ccedil;&atilde;o previsto para o Parceiro, conforme escopo final aprovado e/ou TEP aos cuidados da Boutique Pool, seguindo instru&ccedil;&otilde;es de faturamento da notifica&ccedil;&atilde;o e especificando no corpo da referida nota fiscal que os valores ser&atilde;o em nome da intermediadora de servi&ccedil;os Boutique Pool, contra nota de intermedia&ccedil;&atilde;o; (2) fazer upload da referida nota fiscal emitida na Plataforma, e (3) Comunicar imediatamente a Boutique Pool atrav&eacute;s do e-mail <a href="mailto:suporte.parceiro@bpool.co">suporte.parceiro@bpool.co</a> em caso de qual(is)quer intercorr&ecirc;ncia t&eacute;cnica que que prejudique ou inviabilize a emiss&atilde;o da Nota Fiscal;;</p>
                <p>(iii) A Boutique Pool, por sua vez emitir&aacute; uma nota fiscal somando os Servi&ccedil;os prestados pelo Parceiro Criativo no campo de &ldquo;Intermedia&ccedil;&atilde;o de Servi&ccedil;os&rdquo; e o Fee Transacional, o qual estar&aacute; descrito e forma transparente no Convite e no TEP (&ldquo;Fee Transacional&rdquo;), e enviar&aacute; para o cliente conforme instru&ccedil;&otilde;es inseridas na plataforma (e-mail, upload em sistema e/ou recebimento f&iacute;sico);</p>
                <p>(iv) O Cliente efetuar&aacute; o pagamento integral da nota referida no item (iii) na conta corrente da Boutique Pool e esta repassar&aacute; ao parceiro o valor de seus servi&ccedil;os, conforme escopo final e/ou TEP.</p>
                <p>Par&aacute;grafo &Uacute;nico: As Partes convencionam que, notadamente em rela&ccedil;&atilde;o &agrave;s obriga&ccedil;&otilde;es contidas na al&iacute;nea (ii) acima, cumprir&aacute; ao Parceiro Criativo emitir a respectiva Nota Fiscal e realizar seu upload no link disponibilizado pela Boutique Pool em at&eacute; 48 (quarenta e oito) horas contadas do recebimento do e-mail contendo as instru&ccedil;&otilde;es neste sentido. Resguardado o disposto no item (3) da al&iacute;nea (ii), fica desde j&aacute; convencionado que o n&atilde;o atendimento ao referido prazo pelo Parceiro Criativo dar&aacute; ensejo &agrave; aplica&ccedil;&atilde;o de penalidade de 2% (dois por cento) sobre o valor dos servi&ccedil;os, percentual ao qual ser&aacute; acrescido 0,5% (zero v&iacute;rgula cinco por cento) por dia de atraso, a t&iacute;tulo de mora, limitado a 5% (cinco por cento).</p>
                <p>11.4. Em caso de atraso no pagamento da Remunera&ccedil;&atilde;o por meio das notas fiscais enviadas, recebidas e n&atilde;o contestadas pelo Cliente, este estar&aacute; sujeito &agrave; cobran&ccedil;a de multa de 2% (dois por cento), al&eacute;m de juros morat&oacute;rios de 1% (um por cento) ao m&ecirc;s, calculadas pro rata die. A multa e os juros morat&oacute;rios referidos neste item ser&atilde;o devidos ao Parceiro Criativo e &agrave; Boutique Pool, proporcionalmente &agrave;s suas respectivas remunera&ccedil;&otilde;es, de acordo com os termos de contrata&ccedil;&atilde;o de cada Projeto ou Servi&ccedil;o.</p>
                <p> 11.5. Na data de ades&atilde;o a este Termo, o Cliente estar&aacute; isento do pagamento para a Boutique Pool do fee mensal pelo uso da Plataforma (&ldquo;Fee Mensal&rdquo;). Entretanto, o Cliente est&aacute; ciente e declara concordar que a Boutique Pool poder&aacute; vir a cobrar do Cliente o Fee Mensal a qualquer momento ap&oacute;s a ades&atilde;o a este Termo, mediante comunica&ccedil;&atilde;o pr&eacute;via ao Cliente.</p>
                <p> 11.6. Dependendo da quantidade de itens de cria&ccedil;&atilde;o a serem inseridos no sistema, bem como se houver necessidade de adequa&ccedil;&atilde;o dos crit&eacute;rios de Curadoria e de contratos, de acordo com exig&ecirc;ncias especificas do Cliente, o Cliente est&aacute; ciente e declara concordar que um set-up fee pode vir a ser cobrado pela Boutique Pool, mediante comunica&ccedil;&atilde;o pr&eacute;via ao Cliente.</p>
                <ol start="12">
                    <li>12. Direitos Autorais. Para fins deste Termo, &ldquo;Direitos Autorais&rdquo; significam os direitos morais e patrimoniais das cria&ccedil;&otilde;es elaboradas durante a presta&ccedil;&atilde;o de servi&ccedil;os de marketing em cada Projeto, desde que estas contenham cria&ccedil;&otilde;es originais e de esp&iacute;rito capazes de destacar uma ideia ou conceito para um produto ou servi&ccedil;o. Neste sentido, os Direitos Autorais podem englobar cria&ccedil;&otilde;es manifestadas em materiais impressos, eletr&ocirc;nicos, audiovisuais ou radiof&ocirc;nicos. Os Parceiros Criativos dever&atilde;o deter a posse dos Direitos Autorais dos materiais a que venham a submeter para utiliza&ccedil;&atilde;o na Plataforma.</li>
                </ol>
                <p>12.1. Os Parceiros dever&atilde;o criar conceitos originais que ser&atilde;o ofertados para a contrata&ccedil;&atilde;o pelos Clientes. Em caso de verifica&ccedil;&atilde;o do uso de materiais n&atilde;o originais, os Parceiros poder&atilde;o ser exclu&iacute;dos da Plataforma, ressalvado o direito de indeniza&ccedil;&atilde;o que determinado Cliente que porventura seja prejudicado possa vir buscar contra o Parceiro. A Boutique Pool n&atilde;o possui qualquer responsabilidade sobre a viola&ccedil;&atilde;o de Direitos Autorais de terceiros na presta&ccedil;&atilde;o dos Servi&ccedil;os, devendo a parte prejudicada buscar seus direitos junto ao Parceiro que violou tais direitos.</p>
                <p>12.2. Todas as marcas, nomes comerciais, nomes de dom&iacute;nio e demais sinais distintivos da Boutique Pool na Plataforma, assim como os programas, banco de dados, redes e arquivos, que permitem que o Parceiro acesse e use a Plataforma, s&atilde;o propriedade da Boutique Pool e est&atilde;o protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodu&ccedil;&atilde;o total ou parcial dos referidos conte&uacute;dos s&atilde;o proibidos, salvo a autoriza&ccedil;&atilde;o expressa da Boutique Pool.</p>
                <p>12.3. Qualquer intromiss&atilde;o, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibi&ccedil;&otilde;es estipuladas neste Termo, tornar&atilde;o o respons&aacute;vel pass&iacute;vel das a&ccedil;&otilde;es legais pertinentes, bem como das san&ccedil;&otilde;es aqui previstas, sendo ainda respons&aacute;vel pelas indeniza&ccedil;&otilde;es por eventuais san&ccedil;&otilde;es.</p>
                <ol start="13">
                    <li>13. Cess&atilde;o de Direitos Autorais. Os materiais sujeitos a Direitos Autorais ser&atilde;o cedidos aos Clientes quando determinado Parceiro for selecionado em uma Curadoria, Matchmaking ou Concorr&ecirc;ncia espec&iacute;fica e o Servi&ccedil;o prestado. Tal cess&atilde;o &eacute; onerosa nos termos de cada contrata&ccedil;&atilde;o, espec&iacute;fica para a utiliza&ccedil;&atilde;o em cada Projeto contratado e limitada aos usos previstos em cada Projeto. Em caso de aus&ecirc;ncia de defini&ccedil;&atilde;o espec&iacute;fica, a cess&atilde;o ser&aacute; considerada onerosa e ilimitada, definitiva e abranger&aacute; todos os materiais produzidos pelo Parceiro Criativo que sejam sujeitos a Direitos Autorais.</li>
                </ol>
                <p>13.1. A cess&atilde;o dos Direitos Autorais dever&aacute; ser realizada pelo Parceiro com a garantia de que este obteve os direitos dos autores dos materiais contidos nos Servi&ccedil;os, pessoas f&iacute;sicas, que elaboraram estes materiais. Em caso de aus&ecirc;ncia na obten&ccedil;&atilde;o da cess&atilde;o das pessoas f&iacute;sicas, caber&aacute; a cada Cliente definir entre obter a cess&atilde;o destes Direitos Autorais de forma adicional ou cancelar a contrata&ccedil;&atilde;o de determinado Parceiro. &Agrave; Boutique Pool caber&aacute; a exclus&atilde;o deste Parceiro da Plataforma, mediante solicita&ccedil;&atilde;o do Cliente, caso o Parceiro n&atilde;o apresente ao Cliente documentos que demonstrem que os Direitos Autorais lhe foram cedidos de maneira correta. Os direitos morais sobre os materiais de marketing n&atilde;o s&atilde;o cedidos, na forma do artigo 24 da Lei de Direitos Autorais (Lei 9810/98), sendo de responsabilidade dos Clientes nomear seus criadores na exibi&ccedil;&atilde;o dos materiais de marketing que foram criados.</p>
                <ol start="14">
                    <li>14. Atua&ccedil;&atilde;o Boutique Pool. A Boutique Pool atua na conex&atilde;o entre o Cliente e o Parceiro e sua responsabilidade &eacute; limitada aos servi&ccedil;os por ela prestados por meio da Plataforma, conforme descrito neste Termo.</li>
                </ol>
                <p>14.1. O Cliente est&aacute; ciente e declara concordar que a Boutique Pool colocar&aacute; o melhor time e tecnologia na realiza&ccedil;&atilde;o da Curadoria, Matchmaking e Concorr&ecirc;ncia, envidando sempre seus melhores esfor&ccedil;os para indicar os melhores Parceiros para a presta&ccedil;&atilde;o dos Servi&ccedil;os aos Clientes, mas n&atilde;o poder&aacute; ser responsabilizada diretamente pelos danos eventualmente causados aos Clientes pelos Parceiros na presta&ccedil;&atilde;o dos Servi&ccedil;os.</p>
                <p>14.2. Em qualquer hip&oacute;tese de Servi&ccedil;o prestado de forma insatisfat&oacute;ria ao Cliente, a Boutique Pool tomar&aacute; as medidas necess&aacute;rias para solucionar qualquer inconsist&ecirc;ncia na presta&ccedil;&atilde;o dos Servi&ccedil;os, na forma prevista no item 8 acima, promovendo a troca do Parceiro se for o caso, bem como a reten&ccedil;&atilde;o e/ou devolu&ccedil;&atilde;o dos valores pagos pelo Cliente, de acordo com as regras da Plataforma aplic&aacute;veis ao caso vigentes &agrave; &eacute;poca do ocorrido.</p>
                <p>14.3. Em caso de conflito entre Cliente e Parceiro, a Boutique Pool envidar&aacute; seus melhores esfor&ccedil;os para resolver tal conflito de acordo com as regras pr&eacute;-definidas da Plataforma e defini&ccedil;&otilde;es do TEP, n&atilde;o podendo, entretanto, ser responsabilizada pelo pagamento de indeniza&ccedil;&otilde;es por perdas e danos, incluindo, mas n&atilde;o se limitando a, danos materiais e morais e lucros cessantes.</p>
                <p>14.4. O Cliente poder&aacute; adotar as medidas legais necess&aacute;rias diretamente perante o Parceiro, caso queira obter indeniza&ccedil;&atilde;o por eventuais danos materiais e morais, causados, a si ou terceiros.</p>
                <p>14.5. N&atilde;o obstante as disposi&ccedil;&otilde;es acima, em qualquer hip&oacute;tese, a responsabilidade da Boutique Pool nos termos deste instrumento &eacute; limitada ao valor da Remunera&ccedil;&atilde;o de cada Servi&ccedil;o/Projeto contratado na Plataforma.</p>
                <ol start="15">
                    <li>15. Exclus&atilde;o da Plataforma. A Boutique Pool poder&aacute; excluir da Plataforma os Clientes e Parceiros que n&atilde;o atuem em conson&acirc;ncia com estes Termos, a &eacute;tica ou a lei brasileira. Nas hip&oacute;teses em que a Boutique Pool tomar conhecimento de negocia&ccedil;&atilde;o ou tentativa de negocia&ccedil;&atilde;o entre Clientes e Parceiros fora do ambiente da Plataforma, ap&oacute;s seu in&iacute;cio ter ocorrido no ambiente da mesma, a exclus&atilde;o ser&aacute; sumariamente realizada.</li>
                </ol>
                <p>15.1. Em caso de exclus&atilde;o durante um procedimento de Curadoria, Matchmaking ou Concorr&ecirc;ncia, a Boutique Pool poder&aacute; suspender estes procedimentos sem que haja a efetiva contrata&ccedil;&atilde;o de um Parceiro (em caso de exclus&atilde;o de um Cliente), ou suspender a participa&ccedil;&atilde;o de um Parceiro em determinado procedimento de Curadoria, Matchmaking ou Concorr&ecirc;ncia, sem que haja a devolu&ccedil;&atilde;o de quaisquer valores incorridos pela Parte que vier a ser exclu&iacute;da da Plataforma.</p>
                <p>15.2. Para fins desta cl&aacute;usula, nas hip&oacute;teses em que a Boutique Pool n&atilde;o tiver sido remunerada, ser&aacute; ainda devido ao seu comissionamento previsto para cada Projeto, pelo Cliente no caso do Projeto ainda n&atilde;o ter sido pago ou pelo Parceiro, caso este j&aacute; tenha recebido os valores deste Projeto, sem preju&iacute;zo das demais perdas e danos que se fizerem exigidos.</p>
                <p>15.3. A &uacute;nica exce&ccedil;&atilde;o &agrave; regra acima ser&aacute; nas hip&oacute;teses em que determinado Parceiro Criativo receba a oferta de um Projeto pela Boutique Pool e se descubra que este j&aacute; manteve um relacionamento comercial com o Cliente selecionado pela Boutique Pool nos &uacute;ltimos 12 meses anteriores. Nesta hip&oacute;tese, a Boutique Pool dever&aacute; ser informada de imediato para que possa alterar as condi&ccedil;&otilde;es do relacionamento direto entre o Cliente e o Parceiro Criativo, observando-se que nesta hip&oacute;tese a remunera&ccedil;&atilde;o da Plataforma somente ser&aacute; considerada para os Projetos que forem iniciados a partir da utiliza&ccedil;&atilde;o da Plataforma. Caso haja a defini&ccedil;&atilde;o do Cliente para que todos os Projetos com este Parceiro Criativo sejam concentrados na Plataforma, a partir de ent&atilde;o, a Boutique Pool dever&aacute; estabelecer valores espec&iacute;ficos que contemplem tal cen&aacute;rio.</p>
                <ol start="16">
                    <li>16. Descumprimento contratual. Sem preju&iacute;zo de outras medidas, a Boutique Pool poder&aacute; advertir, suspender ou cancelar, tempor&aacute;ria ou definitivamente, a conta de um Cliente na Plataforma, a qualquer tempo, e iniciar as a&ccedil;&otilde;es legais cab&iacute;veis: a) se o Cliente (i) n&atilde;o cumprir qualquer dispositivo deste Termo, (ii) descumprir com seus deveres de Cliente junto &agrave; Boutique Pool e ao Parceiro, ou (iii) praticar atos fraudulentos ou dolosos; b) se qualquer informa&ccedil;&atilde;o fornecida pelo Cliente esteja incorreta; ou c) se a Boutique Pool entender que qualquer atitude do Cliente tenha causado algum dano a terceiros ou &agrave; Boutique Pool ou tenham a potencialidade de assim o fazer.</li>
                </ol>
                <p>16.1. O Cliente indenizar&aacute; a Boutique Pool, suas filiais, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros Clientes, Parceiros ou terceiros, decorrentes de suas atividades na Plataforma ou por seu descumprimento deste Termo, pela viola&ccedil;&atilde;o de qualquer lei ou direitos de terceiro, notadamente aqueles vinculados &agrave; confidencialidade das informa&ccedil;&otilde;es recebidas do Cliente, &agrave; privacidade dos dados acessados em decorr&ecirc;ncia do presente instrumento, bem como &agrave; regula&ccedil;&atilde;o das atividades de publicidade.</p>
                <ol start="17">
                    <li>17. Resolu&ccedil;&atilde;o de Conflitos. A Boutique Pool poder&aacute; mediar eventuais conflitos entre Clientes e Parceiros para que sejam solucionados de acordo os Termos aqui dispostos e a lei brasileira aplic&aacute;vel, adotando m&eacute;todos que sejam transparentes para Clientes e Parceiros e poder&aacute; reter os valores envolvidos at&eacute; que o conflito seja entre Clientes e Parceiros.</li>
                </ol>
                <p>17.1. Em todas as media&ccedil;&otilde;es, todos os envolvidos se esfor&ccedil;ar&atilde;o para que os procedimentos sejam realizados e conclu&iacute;dos em no m&aacute;ximo 30 dias a contar de seu in&iacute;cio.</p>
                <p>17.2. Caso quaisquer conflitos n&atilde;o sejam resolvidos pela media&ccedil;&atilde;o prevista neste item no prazo m&aacute;ximo de 60 dias contados de seu in&iacute;cio, tal(is) conflito(s) dever&aacute;(&atilde;) ser resolvido(s) por meio de arbitragem, nos termos do Regulamento da ARBITRANET, por um tribunal arbitral composto por 3 (tr&ecirc;s) &aacute;rbitros, os quais ser&atilde;o nomeados de acordo com o disposto no referido regulamento. A arbitragem ser&aacute; conduzida em l&iacute;ngua portuguesa e dever&aacute; ser realizada na cidade de S&atilde;o Paulo, Estado de S&atilde;o Paulo, Brasil.</p>
                <p>17.3. Sem preju&iacute;zo do disposto na Cl&aacute;usula 18.2. acima, as Partes reconhecem que qualquer uma delas poder&aacute; recorrer ao Poder Judici&aacute;rio exclusivamente para as seguintes medidas, sendo que tais medidas n&atilde;o devem ser interpretadas como ren&uacute;ncia pelas Partes do procedimento de arbitragem: (i) para obter medidas liminares e cautelares previamente &agrave; confirma&ccedil;&atilde;o do tribunal arbitral; (ii) para execu&ccedil;&atilde;o de qualquer decis&atilde;o do tribunal arbitral, incluindo a senten&ccedil;a final; (iii) para a execu&ccedil;&atilde;o espec&iacute;fica deste instrumento; e (iv) para outros procedimentos expressamente admitidos pela Lei n&ordm; 9.307/96, conforme alterada. Para fins do disposto neste item, fica eleito o foro da capital de S&atilde;o Paulo.</p>
                <ol start="18">
                    <li>18. Rela&ccedil;&otilde;es Trabalhistas. Sob nenhuma hip&oacute;tese ou em qualquer situa&ccedil;&atilde;o, se presumir&aacute; a eventual exist&ecirc;ncia, ou se estabelecer&aacute; a presun&ccedil;&atilde;o de qualquer v&iacute;nculo empregat&iacute;cio, ou obriga&ccedil;&otilde;es de car&aacute;ter trabalhista e previdenci&aacute;rio entre as Partes, por si e/com os prepostos ou empregados da outra Parte, nem qualquer das Partes ser&aacute; fiadora das obriga&ccedil;&otilde;es e encargos trabalhistas e sociais da outra Parte a qual, assume, neste ato, integral responsabilidade por tais obriga&ccedil;&otilde;es, inclusive civil e penal. As Partes ser&atilde;o integralmente respons&aacute;veis por servi&ccedil;os de terceiros que eventualmente sejam utilizados no cumprimento das obriga&ccedil;&otilde;es assumidas na Plataforma. Neste sentido, responder&atilde;o pelas obriga&ccedil;&otilde;es impostas pela legisla&ccedil;&atilde;o trabalhista e previdenci&aacute;ria, responsabilizando-se pelo recolhimento de todos os encargos fiscais, trabalhistas, sociais e previdenci&aacute;rios de seus empregados alocadas na execu&ccedil;&atilde;o de suas atividades.</li>
                </ol>
                <ol start="19">
                    <li>19. Os tributos de qualquer natureza que sejam devidos em decorr&ecirc;ncia direta dos Projetos prestados a partir de contrata&ccedil;&otilde;es atrav&eacute;s da Plataforma s&atilde;o de exclusiva responsabilidade do contribuinte do respectivo tributo, conforme definido na norma tribut&aacute;ria.</li>
                </ol>
                <ol start="20">
                    <li>20. Este Termo constitui o acordo integral entre as Partes, relativamente ao uso da Plataforma e prevalece sobre quaisquer acordos anteriores.</li>
                </ol>
                <ol start="21">
                    <li>21. Altera&ccedil;&otilde;es. Este Termo ser&aacute; revisto periodicamente pela Boutique Pool, que poder&aacute; alter&aacute;-lo, excluindo, modificando ou inserindo cl&aacute;usulas ou condi&ccedil;&otilde;es, a seu exclusivo crit&eacute;rio. N&atilde;o obstante, o Cliente receber&aacute; uma notifica&ccedil;&atilde;o sempre que houver uma altera&ccedil;&atilde;o nas disposi&ccedil;&otilde;es deste Termo para que possa ter ci&ecirc;ncia das novas condi&ccedil;&otilde;es. Caso n&atilde;o concorde com as altera&ccedil;&otilde;es, dever&aacute; cancelar sua conta e cessar toda e qualquer utiliza&ccedil;&atilde;o da Plataforma.</li>
                </ol>
                <ol start="22">
                    <li>22. Anticorrup&ccedil;&atilde;o. As Partes cumprir&atilde;o e far&atilde;o com que todos os seus empregados, subcontratados, consultores, agentes ou representantes cumpram a Lei 12.846/13, bem como os c&oacute;digo de &eacute;tica dos Clientes, quando houver. Nenhuma das Partes pagar&aacute;, oferecer&aacute;, prometer&aacute; ou dar&aacute;, direta ou indiretamente qualquer valor ou coisa de valor, incluindo quaisquer valores a ela pagos em decorr&ecirc;ncia dos servi&ccedil;os contratados, a qualquer funcion&aacute;rio ou oficial de um governo, empresa ou sociedade controlada pelo governo ou de propriedade do mesmo, partido pol&iacute;tico, candidato para cargo pol&iacute;tico, ou a qualquer outra pessoa estando ciente de ou acreditando que tal valor ou item de valor ser&aacute; transmitido a algu&eacute;m, para influenciar qualquer a&ccedil;&atilde;o ou decis&atilde;o por tal pessoa ou por qualquer &oacute;rg&atilde;o governamental com a finalidade de obter, reter ou conduzir neg&oacute;cios para quaisquer das Partes.</li>
                </ol>
                <ol start="23">
                    <li>23. O Cliente, neste ato, declara e garante &agrave; Boutique Pool que leu, compreendeu e concorda com todos as disposi&ccedil;&otilde;es deste Termos.</li>
                </ol>
                <ol start="24">
                    <li>24. O Cliente, bem como os seus respectivos representantes legais, declaram que est&atilde;o devidamente autorizados a aceitarem este Termo, na forma de seus respectivos instrumentos sociais.</li>
                </ol>



            </div>
        </div>
    );
}

export default TermsClientPT;
