const whatIcon = (itemType) => {
  if (itemType === "application/pdf") {
    return "file-pdf";
  } else {
    return "file-image";
  }
};

export const FormatItemsTableFiles = ({ dataFiles, dataLinks }) => {
  let filesAndLinks = [];

  if (dataFiles) {
    dataFiles.forEach((file) => {
      filesAndLinks.push({
        id: file.id,
        name: file.name,
        type: file.type,
        icon: whatIcon(file.type),
        uploadedDate: file.uploadedDate,
        url: file.url,
        projectId: file.projectId,
        projectName: file.projectName,
        canBeDeleted: file.canBeDeleted,
      });
    });
  }

  if (dataLinks) {
    dataLinks.forEach((link) => {
      filesAndLinks.push({
        id: link.id,
        name: link.url,
        type: "link/url",
        icon: "link",
        uploadedDate: link.uploadedDate,
        url: link.url,
        projectId: link.projectId,
        projectName: link.projectName,
        canBeDeleted: link.canBeDeleted,
      });
    });
  }

  if (filesAndLinks.length) {
    filesAndLinks.sort((a, b) => {
      const dataA = new Date(a.uploadedDate.split("/").reverse().join("-"));
      const dataB = new Date(b.uploadedDate.split("/").reverse().join("-"));

      return dataA - dataB;
    });
  }

  return filesAndLinks;
};
