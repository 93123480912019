import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Text = styled.div`
  font-size: ${(props) => (props.size === 'small' ? '12px;' : '16px;')};
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
