import React from "react";
import { I18n } from "aws-amplify";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import { Info } from "./Info";
import DownloadIcon from "../../svgs/fi-rr-download.svg";
import constants from "../../../../../../constants";
import * as S from "./styles";

// formOfPayment: 1 => "Transferência"
// formOfPayment: 2 => "Pix"
// formOfPayment: 3 => "SWIFT"
// formOfPayment: 4 => "Nenhum dado bancário"

export const InfoBilling = ({ data, isLoading }) => {
  const handleDownloadNF = ({ pdfUrl }) => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao baixar a NF"),
        duration: 3,
      });
    }
  };

  const whatBank = (bankId) => {
    const bank = constants?.BANK_IDS?.find((item) => item?.code === bankId);

    if (bank) {
      return bank?.label;
    } else {
      return bankId;
    }
  };

  if (data?.formOfPayment === 1) {
    return (
      <S.Wrapper>
        <S.Content>
          <Info
            label={I18n.get("Número da nota")}
            info={data?.data?.invoiceNumber}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Valor da nota")}
            info={data?.data?.totalFmt}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Forma de pagamento")}
            info={I18n.get(data?.formOfPaymentName)}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Banco")}
            info={whatBank(data?.data?.bankId)}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Agência")}
            info={data?.data?.bankBranch}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Conta")}
            info={data?.data?.bankAccount}
            loading={isLoading}
          />
        </S.Content>
        <S.Icon>
          <button onClick={() => handleDownloadNF({ pdfUrl: data?.urlNF })}>
            <img src={DownloadIcon} alt="Download" />
          </button>
        </S.Icon>
      </S.Wrapper>
    );
  } else if (data?.formOfPayment === 2) {
    return (
      <S.Wrapper>
        <S.Content>
          <Info
            label={I18n.get("Número da nota")}
            info={data?.data?.invoiceNumber}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Valor da nota")}
            info={data?.data?.totalFmt}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Forma de pagamento")}
            info={I18n.get(data?.formOfPaymentName)}
            loading={isLoading}
          />
        </S.Content>
        <S.Icon>
          <button onClick={() => handleDownloadNF({ pdfUrl: data?.urlNF })}>
            <img src={DownloadIcon} alt="Download" />
          </button>
        </S.Icon>
        <S.ContentChavePix>
          <Info
            label={I18n.get("Chave PIX")}
            info={data?.data?.pix}
            loading={isLoading}
          />
        </S.ContentChavePix>
      </S.Wrapper>
    );
  } else if (data?.formOfPayment === 3) {
    return (
      <S.Wrapper>
        <S.Content>
          <Info
            label={I18n.get("Número da nota")}
            info={data?.data?.invoiceNumber}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Valor da nota")}
            info={data?.data?.totalFmt}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Forma de pagamento")}
            info={I18n.get(data?.formOfPaymentName)}
            loading={isLoading}
          />
          <Info
            label={I18n.get("SWIFT")}
            info={data?.data?.bankSwift}
            loading={isLoading}
          />
          <Info
            label={I18n.get("ABA/ACH")}
            info={data?.data?.bankABA}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Conta")}
            info={data?.data?.bankAccount}
            loading={isLoading}
          />
        </S.Content>
        <S.Icon>
          <button onClick={() => handleDownloadNF({ pdfUrl: data?.urlNF })}>
            <img src={DownloadIcon} alt="Download" />
          </button>
        </S.Icon>
      </S.Wrapper>
    );
  } else {
    return <div />;
  }
};
