import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import Dashboard from './_dashboard';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_dashboard: data => {
            dispatch(BP_API.get_dashboard());
        },
    };
};

class DashboardAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/dashboard" className="is-black">{I18n.get("Dashboard")}</NavLink></li>
                    </ul>
                </nav>

                <div className="columns">

                    <div className="column is-12">

                        <Dashboard {...this.props} />

                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdmin);
