import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { Row, Col, Select, Radio } from 'antd';
import { Input } from '../../../../../components/UI/Input';
import { ButtonBpool } from '../../../../../components/UI/ButtonBpool';
import { SelectOne } from '../../../../../components/UI/SelectOne';

import * as S from './styles';
import COUNTRY_CODES from './countryCodes.json';

export const CaseForm = ({
  onSave,
  onEditing,
  onUpdate,
  onCancelEdit,
  industries,
  range,
  partnerSpecialityProofs,
  isMartecAdtech,
  requiredSpecialityProofs,
  handleType,
}) => {
  const [inputsForm, setInputsForm] = useState({
    name: null,
    companyName: null,
    link: null,
    year: null,
    industry: '',
    countryCode: 'BRA',
    brand: null,
    id: null,
  });
  const [selectCountry, setSelectCountry] = useState({
    countryLabel: 'Brazil',
    phoneMask: '(00)00000-0000',
    countryCode: 'BRA',
  });
  const listCountries = useSelector((state) => state.bp.countryCodes);
  const [type, setType] = useState(1);
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [loadingEditing, setLoadingEditing] = useState(false);
  const [isValidLink, setIsValidLink] = useState(false);

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const onChangeSelectCountry = (value) => {
    const country = COUNTRY_CODES.find((item) => item.name === value);
    setInputsForm({
      ...inputsForm,
      countryCode: country.code,
      phoneNumber: '',
    });
    setSelectCountry({
      countryLabel: value,
      phoneMask: country.mask,
      countryCode: country.code,
    });
  };

  const onChangeSelectIndustry = (value) => {
    onChangeInputForm('industry', value);
  };

  const onChangeSelectType = (e) => {
    setType(e.target.value);
    handleType(e.target.value);
  };

  useEffect(() => {
    if (
      inputsForm.name &&
      inputsForm.companyName &&
      inputsForm.brand &&
      inputsForm.countryCode &&
      inputsForm.year &&
      inputsForm.industry
    ) {
      if (!isMartecAdtech) {
        if (range.length) {
          setAddButtonDisabled(false);
        } else {
          setAddButtonDisabled(true);
        }
      }
      if (requiredSpecialityProofs) {
        if (partnerSpecialityProofs.length) {
          setAddButtonDisabled(false);
        } else {
          setAddButtonDisabled(true);
        }
      }
      // setAddButtonDisabled(false);
    }
  }, [inputsForm, range, requiredSpecialityProofs, partnerSpecialityProofs]);

  const clearInputs = async () => {
    setInputsForm({
      name: null,
      companyName: null,
      link: null,
      countryCode: 'BRA',
      brand: null,
      id: null,
      year: null,
    });
    setSelectCountry({
      countryLabel: 'Brazil',
      phoneMask: '(00)00000-0000',
      countryCode: 'BRA',
    });
    setType(1);
    setAddButtonDisabled(true);
  };

  const handleSave = async (form) => {
    onSave(form);
  };

  const handleUpdate = async (form) => {
    onUpdate(form);
  };

  const saveItem = async () => {
    const id = uuid();
    const form = { ...inputsForm, type, id };

    await handleSave(form);
    setLoadingEditing(true);
    await clearInputs();
    setLoadingEditing(false);
  };

  const updateItem = async () => {
    const form = { ...inputsForm, type };

    await handleUpdate(form);
    setLoadingEditing(true);
    await clearInputs();
    setLoadingEditing(false);
  };

  const cancelEdit = async () => {
    setLoadingEditing(true);
    await onCancelEdit();
    await clearInputs();
    setLoadingEditing(false);
  };

  const checkValidLink = (link) => {
    const regex = /^((https?:\/\/)?(www\.)?)[\w-]+(\.[\w-]+)+([/?#]\S*)?$/;
    return regex.test(link);
  };

  const onEditInfos = async (formEditing) => {
    setInputsForm({
      name: formEditing?.name,
      companyName: formEditing?.companyName,
      link: formEditing?.link,
      countryCode: formEditing?.countryCode,
      brand: formEditing?.brand,
      year: formEditing?.year,
      industry: formEditing?.industry,
      id: formEditing?.id,
    });
    setType(formEditing?.type);
  };

  const handleEdit = async (formEditing) => {
    setLoadingEditing(true);
    await onEditInfos(formEditing);
    setLoadingEditing(false);
  };

  const createDateArray = () => {
    var currentDate = new Date();
    var startDate = new Date(1900, 0, 1);
    var yearArray = [];

    while (startDate <= currentDate) {
      yearArray.push({
        code: startDate.getFullYear(),
        label: startDate.getFullYear().toString(),
      });
      startDate.setFullYear(startDate.getFullYear() + 1);
    }

    const sortedArray = yearArray.sort((a, b) => {
      if (b.code > a.code) {
        return 1;
      } else if (b.code < a.code) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedArray;
  };

  useEffect(() => {
    if (onEditing !== null) {
      handleEdit(onEditing);
    }
  }, [onEditing]);

  return (
    <S.WrapperCaseForm>
      <S.Title>
        <span>{I18n.get('Case')}</span>
      </S.Title>
      <S.ContentForm>
        <Row>
          <Col xs={24} sm={12}>
            <Radio.Group onChange={onChangeSelectType} value={type}>
              <Radio value={1}>Company</Radio>
              <Radio value={2}>Leadership</Radio>
            </Radio.Group>
          </Col>

          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get('Name')}
              label
              value={inputsForm.name}
              onChange={(value) => onChangeInputForm('name', value)}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get('Company name')}
              label
              value={inputsForm.companyName}
              onChange={(value) => onChangeInputForm('companyName', value)}
              required
            />
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get('Link')}
              label
              value={inputsForm.link}
              onChange={(value) => {
                onChangeInputForm('link', value);
                setIsValidLink(null);
              }}
              onBlur={(value) =>
                checkValidLink(value)
                  ? setIsValidLink(null)
                  : setIsValidLink(I18n.get('Invalid link'))
              }
              error={isValidLink}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <SelectOne
              options={listCountries || []}
              value={inputsForm?.countryCode}
              defaultValue={inputsForm?.countryCode}
              isCountry
              onChange={(value) => onChangeInputForm('countryCode', value)}
              required
              placeholder={I18n.get('Country')}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get('Brand')}
              label
              value={inputsForm.brand}
              onChange={(value) => onChangeInputForm('brand', value)}
              required
            />
          </Col>
          {!isMartecAdtech && (
            <Col xs={24} sm={12} style={{ marginBottom: 30 }}>
              {loadingEditing ? (
                <div>{I18n.get('Loading...')}</div>
              ) : (
                <SelectOne
                  options={industries}
                  value={inputsForm.industry}
                  defaultValue={onEditing ? inputsForm.industry : ''}
                  onChange={(value) => onChangeSelectIndustry(value)}
                  required
                  placeholder={I18n.get('Category')}
                  // error={errrorsInternalForm?.industry}
                />
              )}
            </Col>
          )}
          <Col xs={24} sm={12}>
            {loadingEditing ? (
              <div>{I18n.get('Loading...')}</div>
            ) : (
              <SelectOne
                options={createDateArray()}
                value={inputsForm.year}
                defaultValue={onEditing ? inputsForm.year : ''}
                onChange={(value) => onChangeInputForm('year', value)}
                required
                placeholder={I18n.get('Select year')}
                // error={errrorsInternalForm?.industry}
              />
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={24} sm={12}></Col>
          {onEditing ? (
            <Col xs={5}>
              <S.CancelEditCase>
                <button onClick={() => cancelEdit()}>
                  {I18n.get('Cancel')}
                </button>
              </S.CancelEditCase>
            </Col>
          ) : null}
          <Col xs={{ span: 7, offset: onEditing ? 0 : 5 }}>
            <ButtonBpool
              text={onEditing ? I18n.get('Save case') : I18n.get('Add')}
              theme="primary"
              // disabled={addButtonDisabled || isValidLink}
              disabled={addButtonDisabled}
              full
              loading={false}
              onClick={onEditing ? () => updateItem() : () => saveItem()}
            />
          </Col>
        </Row>
      </S.ContentForm>
    </S.WrapperCaseForm>
  );
};
