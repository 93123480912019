import React, {Component} from 'react';
import { Row, Col, Input } from 'antd';
import { I18n } from 'aws-amplify';
import TabsMenu from "./TabsMenu";



const { Search } = Input;

class HeaderPageTabs extends Component {
    state = { 
        link: null,
        term: null,
    }

    handleSelect = (value) => {
        if(value) {
            this.props.action(value);
        }
    }
    render() { 
        return (
            <div className="bp-headerpage">
                <div className="container">
                    <Row gutter={1}>
                        <Col sm={12}>
                            <TabsMenu
                                activeTab={this.props.activeTab}
                                isClient={this.props.isClient}
                                tabs={this.props.scope}
                            />
                        </Col>
                        <Col sm={12}>
                            <Search
                                placeholder={I18n.get("Buscar")}
                                onSearch={value => this.handleSelect(value)}
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
 
export default HeaderPageTabs;