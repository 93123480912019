import React from "react";

import { I18n } from "aws-amplify";

import { Avatar, Row, Col, Button } from "antd";

import Dphoto from "../../image/dphoto.png";

const Card = (props) => {
  if (!props.bp || !props.bp.partner) return null;

  const item = props.bp.partner;

  return (
    <div className="card">
      <div className="card-content">
        <Row gutter={1}>
          <Col sm={24}>
            <h3 className="title is-4">{I18n.get("Responsável")}</h3>
          </Col>
        </Row>
        <br />
        <Row gutter={1}>
          <Col sm={2}>
            <img width="100%" src={item.profilePicture || Dphoto} />
          </Col>
          <Col sm={22}>
            <div className="admin-content-right">
              <Row gutter={16}>
                <Col sm={12}>
                  <h3 className="title-bpool regular">{item.name}</h3>
                  <p>
                    <b>{I18n.get("E-mail")}:</b> {item.email}
                  </p>
                  <p>
                    <b>{I18n.get("Celular")}:</b> {item.phoneNumber}
                  </p>
                  <p>
                    <b>{I18n.get("Data cadastro")}:</b> {item.createdOnFmt}
                  </p>
                </Col>
                <Col sm={12}>
                  {item.website && (
                    <p>
                      <a
                        target="_blank"
                        href={item.website}
                        className="button bt-bpool pull-right"
                      >
                        {I18n.get("Website & Portfolio")}
                      </a>
                    </p>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Card;
