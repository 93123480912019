import { ADD_CHAT_NOTIFICATION, UPDATE_CHAT_NOTIFICATION } from '../../actions';

const initialState = {
  chatNotifications: {
    notifications: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CHAT_NOTIFICATION:
      const newStateAddNotification = {
        ...state,
        notifications: [...state.notifications, ...action.payload],
      };
      return newStateAddNotification;
    case UPDATE_CHAT_NOTIFICATION:
      const filteredItems = state.notifications.filter(
        (item) => item.id !== action.payload
      );
      console.log('filteredItems action.payload', action.payload);
      console.log('filteredItems', filteredItems);

      const newStateUpdateNotification = {
        ...state,
        notifications: [...filteredItems],
      };
      return newStateUpdateNotification;
    default:
      return state;
  }
};

export default reducer;
