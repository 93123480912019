import React from 'react';
import { I18n } from 'aws-amplify';
import axios from 'axios';

import Config from '../config';

import { Task } from '../utils/task';
import { FormUtils } from '../utils/form';

import TextField from './form/text-field';
import TextAreaField from './form/textarea-field';

import MaskedField from './form/masked-field';
import SelectSearchField from './form/select-search-field';
import CheckboxMultiField from './form/checkbox-multi-field';

import VideosForm from './videos-form';
import PhotosForm from './photos-form';

import TooltipField from './form/tooltip-field';

class CasesForm extends React.Component {

    constructor(props) {

        const item = props.item || {};

        super();
        this.state = {
            fields: {
                title: {
                    label: I18n.get("Título do Case"),
                    onChange: this.onChangeForm.bind(this, 'title'),
                    error: props.errors.title,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'title'),
                },
                specialism: {
                    label: I18n.get("Especialidade"),
                    error: props.errors.specialism,
                    onChange: this.onChangeFormCheckboxMulti.bind(this, 'specialism'),
                    isHorizontal: true
                },
                brand: {
                    label: I18n.get("Marca"),
                    onChange: this.onChangeForm.bind(this, 'brand'),
                    error: props.errors.brand,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'brand'),
                },
                challenge: {
                    label: I18n.get("Desafio"),
                    onChange: this.onChangeForm.bind(this, 'challenge'),
                    error: props.errors.challenge,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'challenge'),
                    minLength: 100,
                    maxLength: 250
                },
                product: {
                    label: I18n.get("Produto"),
                    onChange: this.onChangeForm.bind(this, 'product'),
                    error: props.errors.product,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'product'),
                },
                solution: {
                    label: I18n.get("Solução"),
                    onChange: this.onChangeForm.bind(this, 'solution'),
                    error: props.errors.solution,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'solution'),
                    minLength: 100,
                    maxLength: 250
                },
                //category: {
                //    label: 'Categoria',
                //    value: item.category || "",
                //    onChange: this.onChangeForm.bind(this, 'category'),
                //    error: props.errors.category,
                //    successIcon: false,
                //    visited: true,
                //    onBlur: this.onBlur.bind(this, 'category'),
                //},
                category: {
                    label: I18n.get("Categoria"),
                    onChange: this.onChangeSectorId.bind(this),
                    error: props.errors.category,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'category'),
                },
                year: {
                    label: I18n.get("Ano"),
                    onChange: this.onChangeForm.bind(this, 'year'),
                    error: props.errors.year,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'year'),
                    mask: [/\d/, /\d/, /\d/, /\d/]
                },
                result: {
                    label: I18n.get("Resultado"),
                    onChange: this.onChangeForm.bind(this, 'result'),
                    error: props.errors.result,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'result'),
                    minLength: 100,
                    maxLength: 250
                },
            },
            form: {
                type: props.type,
                caseId: item.id,
                title: item.title || "",
                specialism: item.specialism || [],
                brand: item.brand || "",
                challenge: item.challenge || "",
                product: item.product || "",
                solution: item.solution || "",
                category: item.category || "",
                year: item.year || "",
                result: item.result || "",
                videos: item.videos || [],
                photos: item.photos || [],
            },
            errors: {},
            task: {},
            submit_task: {},
            uploading: false,
        };

        this.addVideoLinkClick = this.onAddVideoLinkClick.bind(this);
        this.delVideoLinkClick = this.onDelVideoLinkClick.bind(this);

        this.delPhotoLinkClick = this.onDelPhotoLinkClick.bind(this);
        this.addPhotoUploadClick = this.onAddPhotoUploadClick.bind(this);

        this.addCase = this.onAddCase.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            this.props.onEditingChange(false);

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);
        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormCheckboxMulti(prop, e) {

        const value = e.target.value;
        const checked = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (checked) {
                nextState.form[prop] = [...nextState.form[prop], value];
            }
            else {
                nextState.form[prop] = nextState.form[prop].filter((v) => v !== value);
            }

            nextState.errors[prop] = null;
            nextState.feedback = null;

            return nextState;
        });
    }


    onChangeSectorId(value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['category'] = value;
            nextState.errors['category'] = null;

            return nextState;
        });
    }

    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    getData() {

        var data = {
            partnerId: this.props.partnerId,
            leaderId: this.props.leaderId
        };

        Object.keys(this.state.form).forEach((item) => {

            if (FormUtils.isFormField(this.state.form[item])) {

                data[item] = this.state.form[item].value;
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    onAddVideoLinkClick(video_link, e) {

        e.preventDefault();

        if (!video_link) return;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.videos = [...this.state.form.videos, video_link];

            return nextState;
        });
    }

    onDelVideoLinkClick(index, e) {

        e.preventDefault();

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.videos = this.state.form.videos.filter((item, i) => i != index);

            return nextState;
        });
    }

    onAddPhotoUploadClick(file, e) {

        if (!file) return;

        if (e) e.preventDefault();

        var image = new Image();
        var reader = new FileReader();

        reader.onload = (e) => {

            this.setState({ uploading: true });

            const item = { partnerId: this.props.partnerId, leaderId: this.props.leaderId, token: this.props.auth.jwtToken, img_base64: e.target.result };

            this.props.upload(item)
                .then(response => {

                    this.setState((prevState) => {

                        var nextState = Object.assign({}, prevState);

                        nextState.form.photos = [...this.state.form.photos, response.data.url];
                        nextState.uploading = false;

                        return nextState;
                    });

                })
                .catch(error => {



                    this.setState({ uploading: false });

                });

        };
        reader.readAsDataURL(file);
    }


    onDelPhotoLinkClick(index, e) {

        if (e) e.preventDefault();

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.photos = this.state.form.photos.filter((item, i) => i != index);

            return nextState;
        });
    }

    onAddCase(e) {
        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ task: submit_task });

        if (typeof (this.props.item) === "object") {
            this.props.updateCase(this.getData()).then(({ task, errors }) => {

                this.setState({ task: task, errors: errors });
            });
        }
        else {
            this.props.addCase(this.getData()).then(({ task, errors }) => {

                this.setState({ task: task, errors: errors });
            });
        }
    }

    render() {


        const enableCaseSpecialism = ['STU_PHOTO', 'EDIT.CONT_VID', 'TRE_COACH'].reduce((acc, item) => {
            return acc && this.props.specialisms.indexOf(item) == -1;
        }, true);

        const specialismValues = this.props.specialisms && this.props.specialisms.map((item) => {

            const specialism = this.props.bp.specialisms && this.props.bp.specialisms.find((s) => s.code == item) || {};

            return specialism;

        });

        return (
            <section>

                <div className="columns columns-no-pad">
                    <div className="column is-8">
                        <TextField {...this.state.fields.title} value={this.state.form.title} error={this.state.errors.title} />
                    </div>
                    <div className="column is-4">
                    </div>
                </div>

                {enableCaseSpecialism && <>

                    <CheckboxMultiField {...this.state.fields.specialism} value={this.state.form.specialism} error={this.state.errors.specialism} values={specialismValues} />

                    <br />

                </>}

                <div className="columns columns-no-pad">
                    <div className="column is-4">
                        <TextField {...this.state.fields.brand} value={this.state.form.brand} error={this.state.errors.brand} />
                    </div>
                    <div className="column is-4">
                        <SelectSearchField {...this.state.fields.category} value={this.state.form.category} error={this.state.errors.category} values={this.props.bp.sectorsFlat} />
                    </div>
                    <div className="column is-4" style={{ display: 'flex' }}>

                        <div style={{ flexGrow: 1 }}>
                            <TextField {...this.state.fields.product} value={this.state.form.product} error={this.state.errors.product} />

                        </div>
                        <div style={{ flexBasis: '120px', marginLeft: '1rem' }}>

                            <MaskedField  {...this.state.fields.year} value={this.state.form.year} error={this.state.errors.year} />

                        </div>

                    </div>
                </div>

                <div className="columns">
                    <div className="column is-4">
                        <TextAreaField {...this.state.fields.challenge}
                            value={this.state.form.challenge}
                            error={this.state.errors.challenge}
                            placeholder={enableCaseSpecialism ? 'obrigatório' : 'opcional'}
                        />
                    </div>
                    <div className="column is-4">
                        <TextAreaField {...this.state.fields.solution}
                            value={this.state.form.solution}
                            error={this.state.errors.solution}
                            placeholder={enableCaseSpecialism ? 'obrigatório' : 'opcional'}
                        />
                    </div>
                    <div className="column is-4">
                        <TextAreaField {...this.state.fields.result}
                            value={this.state.form.result}
                            error={this.state.errors.result}
                            placeholder={enableCaseSpecialism ? 'obrigatório' : 'opcional'}
                        />
                    </div>
                </div>

                <div className="columns media">
                    <div className="column is-6">
                        <h1 className="title-bpool medium">{I18n.get("Videos")} <TooltipField tooltip={I18n.get("Informe aqui a URL do seu vídeo. Pode ser YouTube, Vimeo ou qualquer outra plataforma")} /> </h1>
                        <VideosForm {...this.props} videos={this.state.form.videos} add={this.addVideoLinkClick} del={this.delVideoLinkClick} />
                    </div>
                    <div className="column is-6">
                        <h1 className="title-bpool medium">{I18n.get("Fotos")} <TooltipField tooltip={I18n.get("Arraste sua imagem aqui ou faça upload.")} /> </h1>
                        <PhotosForm {...this.props}
                            photos={this.state.form.photos}
                            uploading={this.state.uploading}
                            addUpload={this.addPhotoUploadClick}
                            del={this.delPhotoLinkClick}
                            delUpload={this.delPhotoUploadClick}
                            allowMultipleSelect={true}
                            allowOnlyImages={true}
                        />
                    </div>
                </div>
                {this.state.errors && Object.keys(this.state.errors).length > 0 && <div>
                    <p className="has-text-danger" style={{ marginTop: '1rem' }}>
                        <li>{I18n.get("Ocorreram erros de validação, por favor verifique o formulário.")}</li>
                    </p>
                </div>}
                <div style={{ margin: "60px 0", display: "flex", alignItems: "flex-end", flexDirection: "column" }} className="field">
                    <button
                        type="button"
                        style={{ margin: 0, fontSize: 12, textTransform: "uppercase" }}
                        onClick={this.onAddCase.bind(this)}
                        className="button is-leaked">{I18n.get("Salvar Case")}</button>
                </div>

            </section>
        );
    }
}

export default CasesForm;
