import { I18n } from "aws-amplify";

export const ratings = [
  {
    code: 1,
    label: I18n.get("Péssimo"),
    icon: "pessimo",
  },
  {
    code: 2,
    label: I18n.get("Ruim"),
    icon: "ruim",
  },
  {
    code: 3,
    label: I18n.get("Regular"),
    icon: "regular",
  },
  {
    code: 4,
    label: I18n.get("Bom"),
    icon: "bom",
  },
  {
    code: 5,
    label: I18n.get("Excelente"),
    icon: "excelente",
  },
];
