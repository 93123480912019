import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import {
  getPartnersById as getPartnerByIdState,
  updateProjectStep,
} from "../../../../../store/actions/partners";
import { usePartners } from "../../../../../hooks/usePartners/usePartners.hook";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import { useSessionStorage } from "../../../../../hooks/useSessionStorage/useSessionStorage.hook";
import { Row, Col, Tooltip } from "antd";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import Content from "../../../../../components/UI/Content";
import { H2Border } from "../../../../../components/UI/Titles";
import Loading from "../../../../../components/UI/Loading";
import { ModalConfirm } from "../../../../../components/UI/ModalConfirm/ModalConfirm";
import Main from "../../components/Main";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import IcoPlus from "../../../../../../svg/plus.svg";
import { StepsSelectPartner } from "../components/StepsSelectPartner";
import { Card } from "../components/Card";

import { KEY_PARTNER_WINNER } from "../constants/keys.storage";

import * as S from "./styles";

export const SelectPartnerStep2 = () => {
  let params = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  const { partnerById } = useSelector((state) => state.partners);

  const { getPartnerById } = usePartners();
  const { getProjectById } = useProjects();
  const { set } = useSessionStorage();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const loadPartnerByIdAndProject = async () => {
    try {
      setIsLoading(true);
      const response = await getPartnerById({
        projectId: params?.projectId,
        partnerId: params?.partnerId,
      });
      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });

      if (response?.success && responseProject?.success) {
        const partnerAndProject = {
          ...response?.data,
          project: responseProject?.data,
        };
        dispatch(getPartnerByIdState(partnerAndProject));
      } else {
        dispatch(getPartnerByIdState({}));
        setError("Error.");
      }
      setIsLoading(false);
    } catch (error) {
      dispatch(getPartnerByIdState({}));
      setError("Error.");
    }
  };

  const confirmPartner = async (partnerId) => {
    await set({
      key: KEY_PARTNER_WINNER,
      data: { projectId: params?.projectId, partnerId },
    });
    dispatch(updateProjectStep(2));
    history.push(
      `/cliente/projetos/v2/selecionar-parceiro/${params?.projectId}/passo-3`
    );
  };

  const handleCloseModalConfirm = () => {
    history.push(
      `/cliente/projetos/v2/selecionar-parceiro/${params?.projectId}/passo-3`
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (partnerById?.id !== params?.partnerId) {
      loadPartnerByIdAndProject();
    }
  }, []);

  return (
    <>
      <LoggedPage>
        <Content>
          <div className="container">
            <Row>
              <Col sm={12}>
                <H2Border>{I18n.get("Projetos")}</H2Border>
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <S.WrapperSteps>
                  <StepsSelectPartner active={2} projectId={params.projectId} partnerId={params?.partnerId} disabledTabs={[1, 3]} />
                </S.WrapperSteps>
              </Col>
            </Row>

            {isLoading ? (
              <Row>
                <Col sm={24}>
                  <Main bgColor="#fff" padding="30px">
                    <Row>
                      <Col sm={24}>
                        <div style={{ width: "100%" }}>
                          <Loading
                            text={I18n.get('Carregando...')}
                            sizeText={14}
                            sizeIcon={16}
                            align="center"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Main>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col sm={24}>
                  <Main bgColor="#fff" padding="30px">
                    <Row>
                      <Col xs={24} sm={12} offset={6}>
                        <S.RowCenter>
                          <S.H4>{I18n.get("Confirmação")}</S.H4>
                          <p>
                            {I18n.get("Agora é só confirmar o parceiro para encaminhar o projeto.")}
                          </p>
                        </S.RowCenter>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24}>
                        <S.WrapperBoxInfos>
                          <S.Box>
                            <S.BoxTitleInfos>
                              {I18n.get("Informações")}
                            </S.BoxTitleInfos>
                            <S.BoxInfos>
                              <ul>
                                <li>
                                  <strong>
                                    {I18n.get("Nome do Projeto:")}
                                  </strong>
                                  <span>
                                    {partnerById?.project?.projectName}
                                  </span>
                                </li>
                                <li>
                                  <strong>
                                    {I18n.get("Número do Projeto:")}
                                  </strong>
                                  <span>
                                    {partnerById?.project?.projectSKU}
                                  </span>
                                </li>
                                <li>
                                  <strong>
                                    {I18n.get("Marca / Produto:")}
                                  </strong>
                                  <span>{`${partnerById?.project?.brandName} - ${partnerById?.project?.productName}`}</span>
                                </li>
                              </ul>
                            </S.BoxInfos>
                          </S.Box>
                        </S.WrapperBoxInfos>
                      </Col>
                    </Row>
                    <Row>
                      <S.WrapperCard>
                        <Card
                          step={2}
                          dataCard={partnerById}
                          projectId={params?.projectId}
                          cbConfirmPartner={confirmPartner}
                        />
                      </S.WrapperCard>
                    </Row>

                    <Row>
                      <Col lg={24}>
                        <S.FooterButtons className="buttonsContainer">
                          <Link
                            to={`/cliente/projetos/v2/selecionar-parceiro/${params?.projectId}/passo-1`}
                          >
                            <ButtonBpool
                              text={I18n.get("Voltar")}
                              theme="secondary"
                              onClick={() =>
                                history.push(
                                  `/cliente/projetos/v2/selecionar-parceiro/${params?.projectId}/passo-1`
                                )
                              }
                            />
                          </Link>
                        </S.FooterButtons>
                      </Col>
                    </Row>
                  </Main>
                </Col>
              </Row>
            )}
          </div>
        </Content>
      </LoggedPage>

      <ModalConfirm
        visible={openModalConfirm}
        bodyText={I18n.get("Parabéns pela escolha!")}
        subBodyText={I18n.get(
          "Enquanto encaminhamos o projeto para o parceiro selecionado, você pode ajudar avaliando os parceiros indicados para o projeto!"
        )}
        okText="OK!"
        handleClickOk={handleCloseModalConfirm}
      />
    </>
  );
};
