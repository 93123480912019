import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useHistory, useParams } from "react-router-dom";
import { message, Icon } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Loading from "../../../../../components/UI/Loading";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { ModalBp } from "../../../../../components/UI/ModalBp/ModalBp";
import { useExtras } from "../../../../../hooks/useExtras/useExtras.hook";

import * as S from "./styles";

export const TableExtras = ({
  isLoading,
  columns,
  data,
  isClient,
  loadInit,
  isInProgress,
}) => {
  const auth = useSelector((state) => state.auth);
  let history = useHistory();
  let params = useParams();
  const { deleteExtra: removeExtra } = useExtras();
  const [modalDelete, setModalDelete] = useState({ open: false, id: null });
  const [isDeleting, setIsDeleting] = useState(false);

  const renderText = (text) => {
    if (text === 0) {
      return text;
    } else if (text) {
      return text;
    } else {
      return "---";
    }
  };

  const handleDetails = (id) => {
    const urlPartner = `/parceiro/projetos/v2/${params?.projectId}/in-progress/extras/${id}/details`;
    const urlClient = isInProgress
      ? `/cliente/projetos/v2/${params?.projectId}/in-progress/extras/${id}/details`
      : `/cliente/projetos/v2/${params?.projectId}/extras/${id}/details`;

    history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
  };

  const deleteExtra = async (id) => {
    setIsDeleting(true);

    try {
      const response = await removeExtra({
        projectId: params?.projectId,
        extraId: id,
      });

      if (response?.status === 204) {
        message.success(I18n.get("Extra deleted successfully"));
      }

      loadInit();
    } catch (error) {
      message.error(I18n.get("Error deleting extra"));
    }

    setIsDeleting(false);
    setModalDelete({ open: false, id: null });
  };

  const handleDelete = (id) => {
    setModalDelete({ open: true, id });
  };

  return (
    <>
      <S.Table>
        <thead>
          <S.TableRow>
            {columns?.map((column, index) => (
              <S.TableHeader key={index}>{I18n.get(column)}</S.TableHeader>
            ))}
          </S.TableRow>
        </thead>
        <tbody>
          {isLoading ? (
            <S.TableEmpty>
              <Loading
                text={I18n.get("Loading...")}
                sizeText={14}
                sizeIcon={16}
                color="#000000"
                align="left"
              />
            </S.TableEmpty>
          ) : data?.length ? (
            data?.map((item, index) => (
              <React.Fragment key={index}>
                <S.TableRow>
                  <S.TableData>{renderText(item?.extraName)}</S.TableData>
                  <S.TableData>{renderText(item?.extraTypeFmt)}</S.TableData>
                  <S.TableData>{renderText(item?.budgetFmt)}</S.TableData>
                  <S.TableData>{renderText(item?.extraPO)}</S.TableData>
                  <S.TableData>{renderText(item?.statusFmt)}</S.TableData>
                  <S.TableData>{renderText(item?.createdOn)}</S.TableData>
                  <S.TableData>
                    {item?.quotationPdf ? (
                      <a href={item?.quotationPdf} target="_blank" rel="noopener noreferrer">
                        <Icon type="file-pdf" />
                      </a>
                    ) : (
                      renderText("")
                    )}
                  </S.TableData>
                  <S.TableData>
                    <ButtonBpool
                      text={I18n.get("Details")}
                      theme="secondary"
                      onClick={() => handleDetails(item?.id)}
                    />
                  </S.TableData>
                  <S.TableData>
                    <S.ContentDel>
                      <S.DelExtra
                        disabled={
                          item?.status == 0 ||
                            (item?.status == 1 && item?.quotation.isOpen)
                            ? false
                            : true
                        }
                        onClick={
                          item?.status == 0 ||
                            (item?.status == 1 && item?.quotation.isOpen)
                            ? () => handleDelete(item?.id)
                            : () => true
                        }
                      >
                        <DeleteOutlined size={14} />
                      </S.DelExtra>
                    </S.ContentDel>
                  </S.TableData>
                </S.TableRow>
              </React.Fragment>
            ))
          ) : (
            <S.TableEmpty>
              <p>{I18n.get("No extras to display")}</p>
            </S.TableEmpty>
          )}
        </tbody>
      </S.Table>

      <ModalBp
        visible={modalDelete.open}
        bodyText={I18n.get("Do you want to delete?")}
        subBodyText={I18n.get("This action is irreversible.")}
        okText={I18n.get("Delete")}
        handleClickOk={() => deleteExtra(modalDelete.id)}
        isDanger={true}
        typeModal="isConfirm"
        cancelText={I18n.get("Cancel")}
        onCancel={() => setModalDelete({ open: false, id: null })}
        isLoading={isDeleting}
      />
    </>
  );
};
