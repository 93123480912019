import styled from "styled-components";

export const Wrapper = styled.div``;

export const Title = styled.div`
  margin-bottom: 24px;

  h3 {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
  }
`;

export const NfseWrapper = styled.div`
  padding-bottom: 2px;
  margin-bottom: 2px;
  width: max-content;
`;

export const NfseContent = styled.div`
  width: max-content;
  display: grid;
  grid-template-columns: 280px auto;
  column-gap: 16px;
`;
