import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import FillForm from '../page-templates/fillForm-logged';

import Steps from './steps';

const mapStateToProps = state => {
    return Object.assign({}, state.project, state);
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class ProjectDefintionsThankYou extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount() {

    }


    submitForm(e) {

    }

    render() {

        return (
            <FillForm>
                <div className="hero-body has-background-white project-definition-header">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-8">
                                <p className="title">{I18n.get("Definições do Projeto")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-4">
                                <Steps active={3} projectId={this.props.match.params.projectId} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-definition">

                    <div className="container client-container-1">

                    <div>
                        <h1 className="subtitle is-4 is-marginless">{I18n.get("Parabéns!")}</h1>
                        <br />
                        <p>{I18n.get("O ESCOPO FINAL DO SEU PROJETO FOI SUBMETIDO. SE ESTIVER TUDO OK, O SEU FATURAMENTO SERÁ LIBERADO QUANDO RECEBERMOS A APROVAÇÃO DO CLIENTE.")}</p>
                        <p style={{ fontSize: '80%' }}>

                        </p>

                        <div className="colot-line"></div>
                    </div>

                        <br />
                        <br />

                    </div>

                </div>

            </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDefintionsThankYou);
