import React from "react";

import * as S from "./styles";

export const TitlePage = ({ title, subtitle }) => {
  return (
    <S.Wrapper>
      <h3>{title}</h3>
      {subtitle ? <p>{subtitle}</p> : null}
    </S.Wrapper>
  );
};
