import styled from "styled-components";

export const WrapperInput = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.error ? "0px;" : "0px;")};
  height: 80px;

  .ant-input-group-addon {
    background: #e7e7e7;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .select-before {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    min-width: 100px;
    max-width: 130px;

    .prefix {
      margin-left: 3px;
      font-size: 11px;
    }

    .ant-select-selection {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-select-arrow {
        height: 50px;
        display: flex;
        align-items: center;
        right: 0;
        justify-content: flex-end;

        i {
          top: 0;
        }
      }

      .ant-select-selection__rendered {
        width: 100%;
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  input {
    height: 50px;
    background-color: #ffffff !important;
    border-radius: 10px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: ${(props) => (props.align ? props.align : "left")};
    color: #515152;
    box-sizing: border-box;
    padding: 0 15px;
    border: solid 1px ${(props) => (props.error ? "#ff0000" : "#999999")};
    width: 100%;
    outline: 0px;

    :hover,
    :focus {
      border-color: ${(props) =>
        props.error ? "#ff0000 !important" : "#1883ff !important"};
    }
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    top: -5px;
    left: 5px;
    font-size: 12px;
    font-weight: 700;

    span {
      color: #ff0000;
      font-size: 12px;
      padding-right: 2px;
    }
  }

  input:not(:placeholder-shown) ~ label {
    color: #515152;
  }

  input:focus ~ label {
    color: #1883ff;
  }

  label {
    top: 15px;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    left: 15px;
    margin: 0;
    color: #a4a4a4;
    padding: 0 5px;
    background-color: #ffffff;
    pointer-events: none;
    transition: top 0.2s ease-in-out, left 0.2s ease-in-out;

    span {
      color: #ff0000;
      font-size: 16px;
      padding-right: 2px;
    }
  }

  label.label.label-addbefore {
    top: -10px;
    left: ${(props) => (props.widthLabel ? props.widthLabel : "5px")};
    font-size: 12px;
    font-weight: 700;
    color: #515152;
    z-index: 2;
  }

  input:read-only {
    height: 50px;
    background-color: #ffffff !important;
    border: solid 1px ${(props) => (props.error ? "#ff0000" : "#e7e7e7")} !important;
  }
`;

export const Label = styled.label``;

export const InfoError = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;
