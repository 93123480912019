import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import BPoolSubMenu from '../bpool/_sub-menu';
import ClientUserSubMenu from '../client-user/_sub-menu';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class SubMenu extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        if (this.props.auth.is_bpool) return (<BPoolSubMenu {...this.props} />);

        if (this.props.auth.is_master) return (<BPoolSubMenu {...this.props} />);

        if (this.props.auth.is_client_user) return (<ClientUserSubMenu {...this.props} />);

        return null;
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);
