import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 346px;
  min-height: 548px;

  display: flex;
  flex-direction: column;
  margin-left: 70px;
  background: #ffffff;
  box-shadow: 0px 10px 7px rgba(0, 0, 0, 0.101557);
  border-radius: 10px;
  border: 1px solid #b4b4b4;

  :hover {
    border: 1px solid #1883ff;
  }
`;

export const Icons = styled.div`
  display: flex;
  height: 35px;
  padding: 0 15px;
  span {
    margin-right: 10px;
  }
`

export const IsWinner = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  margin-top: -25px;

  p {
    width: max-content;
    background: #19c31b;
    padding: 0 5px;
    font-size: 10px;
    font-weight: 700;
    color: #ffffff;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`;

export const Head = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;
`;

export const ContentInfos = styled.div`
  display: flex;
`;

export const AvatarAndName = styled.div`
  display: flex;

  width: 45px;
  height: 45px;

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
`;

export const ChatIcon = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
  }

  strong {
    height: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2f2f2f;
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
`;

export const NameAndLocal = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;

  strong {
  }
  p {
  }
`;

export const Content = styled.div`
  margin-top: 10px;
`;

export const Actions = styled.div`
  padding: 30px 15px;
  position: relative;
  height: 100%;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      :first-of-type {
        margin-bottom: 20px;
      }
    }
  }
`;

export const ActionsContact = styled.div`
  margin-bottom: 30px;
`;

export const PhoneAndMailContact = styled.div`
  align-items: unset !important;
  justify-content: unset !important;
  overflow-wrap: break-word;

  p {
    span {
      font-weight: 700;
    }
  }
`;

export const ButtonKnowMore = styled.button`
  margin-top: 30px;
  width: 100%;
  background: transparent;
  border: 1px solid;
  border-color: #1883ff;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  div {
    font-weight: 700;
    font-size: 14px;
    color: #1883ff;
    width: max-content;
    margin-right: 10px;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Rating = styled.div``;
