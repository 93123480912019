import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'


import AppWrapperSimple from '../../../modules/components/AppWrapper';

import PartnerCard from '../shared/_partner-card';

import PartnerInfos from '../../../modules/components/PartnerInfos';
import HeaderThumbCover from '../../../modules/components/HeaderThumbCover';
import ContainerPage from '../../../modules/components/ContainerPage';

import styled from 'styled-components';

import {
    Button
} from 'antd';

const Main = styled.div`
  padding-bottom: 100px;
  max-width: 1000px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  color: #353535;
`;

const mapStateToProps = state => {
    return Object.assign({}, state.partner, state);
};

const mapDispatchToProps = (dispatch) => {

    return {
    };
};

class PreviewPageAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {
        this.scrollToTop();
    }

    showDetails(item, e) {

        this.setState({ detailsView: item });
    }

    hideDetails(e) {

        e.preventDefault();

        this.setState({ detailsView: null });
    }

    scrollToTop() {
        const scrollDuration = 600;
        const scrollHeight = window.scrollY;
        const scrollStep = Math.PI / (scrollDuration / 15);
        const cosParameter = scrollHeight / 2;
        let scrollCount = 0;
        let scrollMargin;
        let scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                scrollCount = scrollCount + 1;
                scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                window.scrollTo(0, (scrollHeight - scrollMargin));
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }

    closePartner = () => {
        this.setState({
            detailsView: null
        });
    }

    render() {



        return (
            <AppWrapperSimple
                grey
            >

                {!this.state.detailsView &&
                    <div style={{ marginTop: "0px" }}>
                        <HeaderThumbCover
                            thumb={this.props.partner && this.props.partner.company && this.props.partner.company.logoImage}
                            bgHeader={this.props.partner && this.props.partner.company && this.props.partner.company.headerImage}
                            title={I18n.get("Preview Resumido")}
                            // link="/parceiro/cadastro"
                            linkText={I18n.get("Voltar")}
                            isCover={false}
                        />
                        <div style={{ marginTop: "90px" }}>
                            <PartnerCard {...this.props} isSelected={false} showDetails={this.showDetails.bind(this, this.props)} isPreview={true} />
                        </div>

                        <p className="btn-voltar-preview">
                        <Link to="/parceiro/cadastro">
                                <Button className="button bt-bpool black">
                                    {I18n.get("Ir para o cadastro")}
                                </Button>
                            </Link>
                        </p>
                    </div>
                }
                {this.state.detailsView &&
                    <div style={{ marginTop: "0px" }}>
                        <HeaderThumbCover
                            thumb={this.props.partner.company && this.props.partner.company.logoImage}
                            bgHeader={this.props.partner.company && this.props.partner.company.headerImage}
                            title={I18n.get("Preview Completo")}
                            linkText={I18n.get("Voltar")}
                            action={this.closePartner}
                        />

                        <ContainerPage>
                            <Main>
                                <PartnerInfos
                                    infos={this.props.partner && this.props.partner}
                                    specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                                    sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                    isPresentation={true}
                                    diversity={this.props.bp.diversity && this.props.bp.diversity}
                                    link="/parceiro/preview"
                                />
                            </Main>
                        </ContainerPage>

                        <p className="btn-voltar-preview">
                            <Button className="button bt-bpool black" onClick={() => this.setState({ detailsView: null })}>
                                {I18n.get("Voltar")}
                            </Button>
                        </p>
                    </div>
                }

            </AppWrapperSimple>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPageAdmin);
