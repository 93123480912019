import React from "react";
import * as S from "./styles";
import successIcon from "./svgs/sucess.svg";
import warningIcon from "./svgs/warning.svg";
import errorIcon from "./svgs/error.svg";

// types: "success" | "warning" | "error"
export const NotificationOnScreen = ({ visible, msg, type, style }) => {
  const renderIcon = (type) => {
    if (type === "warning") {
      return <img src={warningIcon} alt="icon warning" />;
    } else if (type === "error") {
      return <img src={errorIcon} alt="icon error" />;
    } else {
      return <img src={successIcon} alt="icon success" />;
    }
  };
  if (visible) {
    return (
      <S.Notification type={type} style={style ? style : {}}>
        <S.Icon>{renderIcon(type)}</S.Icon>
        <p>{msg}</p>
      </S.Notification>
    );
  } else {
    return <></>;
  }
};
