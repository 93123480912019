import React from "react";

import { I18n } from "aws-amplify";

import FormatCurrency from "../ui/format-currency";

require("./quotation-grandtotal.css");

const VatCmp = ({ vat, amount, currency, otherTax, amountOtherTax }) => {
  return (
    <div style={{ marginTop: "0.5rem", fontWeight: "normal", fontSize: "75%" }}>
      {otherTax > 0 ? (
        <>
          +{I18n.get("Other Tax")} {otherTax}%{" "}
          <FormatCurrency amount={amountOtherTax} currency={currency} />{" "}
        </>
      ) : null}
      <br />
      {vat > 0 ? (
        <>
          +{I18n.get("IVA")} {vat}%{" "}
          <FormatCurrency amount={amount} currency={currency} />
        </>
      ) : null}
    </div>
  );
};

class QuotationGrandTotal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { quotation = {}, currency = {} } = this.props;

    return (
      <>
        <div className="quotation-grandtotal">
          <p className="title is-5">{I18n.get("Resumo da Proposta")}</p>

          <br />

          <div className="columns head">
            <div className="column is-6">{I18n.get("Resumo Proposta")}</div>

            <div className="column is-3">%</div>

            <div className="column is-3">{I18n.get("Valor")}</div>
          </div>

          <div className="columns bpcommission">
            <div className="column is-6 tit">
              {I18n.get("Retenção Plataforma")}
            </div>

            <div className="column is-3 has-text-right">
              {quotation.bpCommission &&
                quotation.bpCommission.percentage &&
                quotation.bpCommission.percentageFmt}
            </div>

            <div className="column is-3 has-text-right">
              <FormatCurrency
                amount={quotation.bpCommission && quotation.bpCommission.amount}
                currency={currency}
              />
              <br />

              {(this.props.bpCommission &&
                this.props.bpCommission?.percFee > 0 && (
                  <span
                    style={{
                      fontSize: 11,
                      marginTop: "-10px",
                      fontWeight: "normal",
                    }}
                  >
                    + {I18n.get("Outras Taxas")}{" "}
                    {quotation.bpCommission.percentageFee}% (
                    <FormatCurrency
                      amount={quotation.bpCommission?.amountFee}
                      currency={currency}
                    />
                    )
                  </span>
                )) ||
                ""}
            </div>
          </div>

          <div className="columns amount">
            <div className="column is-6 tit">
              {I18n.get("Proposta Parceiro")}
            </div>

            <div className="column is-3"></div>

            <div className="column is-3 has-text-right">
              <FormatCurrency
                amount={quotation.totalPartner}
                currency={currency}
              />
            </div>
          </div>

          <div className="columns total">
            <div className="column is-6 tit">{I18n.get("Total Projeto")}</div>
            <div className="column is-3"></div>
            <div className="column is-3 has-text-right">
              <div className="displayValoresWhite">
                <p>
                  <span style={{ fontWeight: "normal" }}>
                    {I18n.get("Total")}: <br />
                  </span>
                  {/* <FormatCurrency
                        amount={quotation.total + quotation.totalOtherTax}
                        currency={currency}
                      /> */}

                  <FormatCurrency
                    amount={
                      quotation.total +
                      quotation.totalOtherTax +
                      quotation.bpCommission?.amountFee
                    }
                    currency={currency}
                  />

                  <br />
                  <span
                    style={{
                      fontSize: 11,
                      marginTop: "-10px",
                      fontWeight: "normal",
                    }}
                  >
                    {quotation?.otherTax > 0
                      ? `(${I18n.get("incluyendo gross up")} ${
                          quotation?.otherTax
                        }%)`
                      : ""}
                  </span>
                </p>

                {quotation.vat > 0 && (
                  <p style={{ fontWeight: "normal", border: 0 }}>
                    <span>{I18n.get("Impostos")}</span> <br />
                    (IVA {quotation.vat}%):{" "}
                    <span>
                      <FormatCurrency
                        amount={
                          quotation.estimateTotalVat || quotation.totalVat
                        }
                        currency={currency}
                      />
                    </span>
                  </p>
                )}
              </div>
              {/* {!this.props.auth.is_client_or_client_user && (
                <>
                  <FormatCurrency
                    amount={quotation.total}
                    currency={currency}
                  />
                  <VatCmp
                    vat={quotation.vat}
                    amount={quotation.estimateTotalVat || quotation.totalVat}
                    currency={currency}
                    otherTax={quotation?.otherTax}
                    amountOtherTax={
                      quotation?.estimateTotalOtherTax ||
                      quotation?.totalOtherTax
                    }
                  />
                </>
              )} */}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "2rem" }}>
          <div className="field">
            <label className="label is-small">
              <div style={{ opacity: "0.5" }}>
                *
                {I18n.get(
                  "Itens de asset list já contemplam retenção da plataforma e, portanto, valor da retenção não é somado a eles, e sim subtraído. Para qualquer outro item do orçamento, a retenção da plataforma é somada com cálculo de gross-up."
                )}
              </div>
            </label>
          </div>
        </div>
      </>
    );
  }
}

export default QuotationGrandTotal;
