import React, { Fragment } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Tabs,
  Row,
  Col,
  Select,
  Button,
  Table,
  Tag,
  Modal,
  Checkbox,
  notification,
} from "antd";
import { BP_API } from "../../actions/bp/actions";
import LoggedPage from "../page-templates/loggedAdmin";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import supplierAdmin from "./supplier-admin";

const { TabPane } = Tabs;
const { Option } = Select;
const { confirm } = Modal;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_clients_logins: () => {
      dispatch(BP_API.get_clients_logins());
    },
    get_partners_logins: () => {
      dispatch(BP_API.get_partners_logins());
    },
    get_suppliers_logins: () => {
      dispatch(BP_API.get_suppliers_logins());
    },
    get_clients_users: (id) => {
      dispatch(BP_API.get_clients_users(id));
    },
    get_partners_users: (id) => {
      dispatch(BP_API.get_partners_users(id));
    },
    get_suppliers_users: (id) => {
      dispatch(BP_API.get_suppliers_users(id));
    },
    reset_user_client: (id, username) => {
      dispatch(BP_API.reset_user_client(id, username));
    },
    reset_user: (data) => {
      dispatch(BP_API.reset_user(data));
    },
    clone_user_client: (data) => {
      return dispatch(BP_API.clone_user_client(data));
    },
    clone_user_partner: (id) => {
      return dispatch(BP_API.clone_user_partner(id));
    },
    clone_user_supplier: (id) => {
      return dispatch(BP_API.clone_user_supplier(id));
    },

    delete_user_client: (data) => {
      dispatch(BP_API.delete_user_client(data));
    },
    delete_user_partner: (id) => {
      dispatch(BP_API.delete_user_partner(id));
    },
    delete_itens: () => {
      dispatch(BP_API.delete_itens());
    },
    api_update_user_trained: (data) => {
      return dispatch(BP_API.api_update_user_trained(data));
    },
  };
};

const ContainerTabs = styled.div`
  .flagTag {
    margin-left: 5px;
  }
  .card-container {
    margin-top: 20px;

    .ant-table-small {
      border: 0;
      table {
        border: solid #ccc 1px !important;
      }
    }
  }
  .card-container > .ant-tabs-card > .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
  }

  .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 30px;
    border: 0;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar {
    border: 0;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
    color: #000;
  }

  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #f0f;
    background: #000;
    color: #fff;
  }

  .RowTable {
    margin-top: 30px;
  }

  .ActionsBtns {
    button {
      margin: 0 2px;
      line-height: 27px;
    }
  }
`;

class LoginsListAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      clientId: "",
      partnerId: "",
      user: [],
      loading: false,
      history: {},
    };
  }

  componentWillMount() {
    this.props.delete_itens();
  }

  componentDidMount() {
    this.props.get_clients_logins();
    this.props.get_partners_logins();
    this.props.get_suppliers_logins();
  }

  setTrained(data) {
    this.props.api_update_user_trained(data).then((response) => {
      if (response) {
        notification.success({
          message: I18n.get("Atualizado com sucesso!"),
        });
        this.getReload();
      } else {
        notification.error({
          message: I18n.get("Algo deu errado!"),
          description: I18n.get("Tente novamente mais tarde"),
        });
      }
    });
  }

  handleChange = (type, value) => {
    this.props.delete_itens();

    if (type == "client") {
      this.setState({
        clientId: value,
      });

      const h = {
        id: value,
        type: "client",
      };

      this.props.get_clients_users(value);
      this.setState({ history: h });
    } else if (type == "partner") {
      this.setState({
        partnerId: value,
      });

      const h = {
        id: value,
        type: "client",
      };

      this.props.get_partners_users(value);
      this.setState({ history: h });
    } else {
      this.setState({
        supplierId: value,
      });

      const h = {
        id: value,
        type: "supplier",
      };

      this.props.get_suppliers_users(value);
      this.setState({ history: h });
    }
  };

  getReload = () => {
    this.handleChange(this.state.history.type, this.state.history.id);
  };

  handleClone = (typeAction, username) => {
    this.setState({ loading: true });
    if (typeAction == "P") {
      let data = {
        partnerId: this.state.partnerId,
        username: username,
      };

      this.props.clone_user_partner(data).then((response) => {
        if (response) {
          this.setState({ loading: false });
        }
      });
    } else if (typeAction == "C") {
      let data = {
        clientId: this.state.clientId,
        username: username,
      };

      this.props.clone_user_client(data).then((response) => {
        if (response) {
          this.setState({ loading: false });
        }
      });
    } else {
      let data = {
        supplierId: this.state.supplierId,
        username: username,
      };

      this.props.clone_user_supplier(data).then((response) => {
        if (response) {
          this.setState({ loading: false });
        }
      });
    }
  };

  handleDeleteClone = (typeAction, username) => {
    if (typeAction == "P") {
      let data = {
        partnerId: this.state.partnerId,
        username: username,
      };

      this.props.delete_user_partner(data);
    } else {
      let data = {
        clientId: this.state.clientId,
        username: username,
      };

      this.props.delete_user_client(data);
    }
  };

  showConfirmReset(typeAction, id, username) {
    confirm({
      title: I18n.get("Deseja resetar a senha desse user?"),
      content: "Senha padrão: Aaaa1234!",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => this.handleReset(typeAction, id, username),
      onCancel: () => console.log("Não resetou"),
    });
  }

  handleReset = (typeAction, id, username) => {
    const data = {
      username: username,
    };

    this.props.reset_user(data);
  };
  onChangeTab = (activeKey) => {
    this.props.delete_itens();
  };

  render() {
    const {
      clientsLogins,
      loginClientsUsers,
      partnersLogins,
      loginPartnersUsers,
      suppliersLogins,
      loginSuppliersUsers,
    } = this.props.bp;

    const columns = [
      {
        title: I18n.get("Nome"),
        dataIndex: "nome",
        key: "nome",
        render: (text, record) => {
          const { isCloned, isResponsable, typeAction, roles } = record;

          const renderProfiles = roles?.map((el, i) => {
            let item;
            if (typeAction == "C") {
              item = el == "Client" ? "Master" : el.replace("ClientUser", "");
            } else {
              item = el == "Partner" ? "Master" : el.replace("Partner", "");
            }

            return (
              <Tag
                color={item == "Master" ? "red" : "blue"}
                className="flagTag"
              >
                {item}
              </Tag>
            );
          });

          return (
            <>
              <span>{text}</span>
              {isResponsable && (
                <Tag color="red" className="flagTag">
                  {I18n.get("responsável")}
                </Tag>
              )}
              {!isResponsable && renderProfiles}
            </>
          );
        },
      },
      {
        title: I18n.get("E-mail"),
        dataIndex: "email",
        key: "email",
      },
      {
        title: I18n.get("Clone"),
        dataIndex: "emailCloned",
        key: "emailCloned",
      },
      {
        title: "",
        key: "action",
        render: (text, record) => {
          const {
            canClone,
            canDelete,
            canResetPassword,
            username,
            usernameCloned,
            typeAction,
            key,
            isInviteOpen,
            token,
          } = record;

          if (typeAction === "S") {
            return (
              <>
                <Button
                  // disabled={username == null || usernameCloned != null}
                  onClick={() => this.handleClone(typeAction, username)}
                  type="primary"
                  icon="copy"
                  size="small"
                  loading={this.state.loading}
                />
                {/* {canDelete && (
                      <Button
                        disabled={!usernameCloned}
                        onClick={() =>
                          this.handleDeleteClone(typeAction, usernameCloned)
                        }
                        type="danger"
                        icon="delete"
                        size="small"
                      />
                    )} */}
                <Button
                  onClick={() =>
                    this.showConfirmReset(typeAction, key, username)
                  }
                  type="dashed"
                  icon="reload"
                  size="small"
                />
              </>
            );
          } else {
            let linkSignup;

            if (typeAction === "P") {
              linkSignup = `https://app.bpool.co/#/parceiro/usuario/convite/${token}`;
            } else {
              linkSignup = `https://app.bpool.co/#/cliente/usuario/verifica-email/${token}`;
            }

            return (
              <span className="ActionsBtns">
                {isInviteOpen && (
                  <>
                    <CopyToClipboard text={linkSignup}>
                      <button>Copy Link</button>
                    </CopyToClipboard>
                  </>
                )}
                {/*  */}

                {!isInviteOpen && (
                  <>
                    {canClone && (
                      <Button
                        disabled={username == null || usernameCloned != null}
                        onClick={() => this.handleClone(typeAction, username)}
                        type="primary"
                        icon="copy"
                        size="small"
                        loading={this.state.loading}
                      />
                    )}
                    {canDelete && (
                      <Button
                        disabled={!usernameCloned}
                        onClick={() =>
                          this.handleDeleteClone(typeAction, usernameCloned)
                        }
                        type="danger"
                        icon="delete"
                        size="small"
                      />
                    )}
                  </>
                )}
                <Button
                  onClick={() =>
                    this.showConfirmReset(typeAction, key, username)
                  }
                  type="dashed"
                  icon="reload"
                  size="small"
                />
              </span>
            );
          }
        },
      },
      {
        title: I18n.get("Treinamento"),
        dataIndex: "trained",
        key: "trained",
        render: (trained, record) => {
          let toUpdate = {};

          if (record.type == "client") {
            toUpdate = {
              id: record?.userId,
              type: record.type,
              clientId: record.id,
              trained: !trained,
            };
          } else {
            toUpdate = {
              id: record.userId,
              type: record.type,
              partnerId: record.id,
              trained: !trained,
            };
          }

          if (record.type == "client") {
            return (
              <>
                <Checkbox
                  checked={trained}
                  onChange={() => this.setTrained(toUpdate)}
                />
                {<span>{record.trainedOnUtcFmt}</span>}
              </>
            );
          } else {
            return null;
          }
        },
      },
    ];

    let dataLoginClient =
      loginClientsUsers &&
      loginClientsUsers.map((item) => {
        return {
          key: item.id,
          nome: item.name,
          email: item.email,
          emailCloned: item.emailCloned,
          roles: item.roles,
          id: item.clientId,
          type: "client",
          canClone: item.canClone,
          canDelete: item.canDelete,
          canResetPassword: item.canResetPassword,
          isCloned: item.isCloned,
          userId: item.userId,
          trained: item.trainedOnUtcFmt?.length > 0 ?? false,
          trainedOnUtcFmt: item.trainedOnUtcFmt,
          username: item.username,
          usernameCloned: item.usernameCloned,
          isResponsable: item.isResponsable,
          typeAction: "C",
          isInviteOpen: item.isInviteOpen,
          token: item.token,
        };
      });

    let dataLoginPartner =
      loginPartnersUsers &&
      loginPartnersUsers.map((item) => {
        return {
          key: item.id,
          nome: item.name,
          email: item.email,
          emailCloned: item.emailCloned,
          roles: item.roles,
          id: item.partnerId,
          type: "partner",
          canClone: item.canClone,
          canDelete: item.canDelete,
          canResetPassword: item.canResetPassword,
          isCloned: item.isCloned,
          username: item.username,
          trained: item.trainedOnUtcFmt?.length > 0 ?? false,
          trainedOnUtcFmt: item.trainedOnUtcFmt,
          userId: item.userId,
          usernameCloned: item.usernameCloned,
          isResponsable: item.isResponsable,
          typeAction: "P",
          isInviteOpen: item.isInviteOpen,
          token: item.token,
        };
      });

    let dataLoginSupplier =
      loginSuppliersUsers &&
      loginSuppliersUsers.map((item) => {
        return {
          key: item?.supplierId,
          nome: item.name,
          email: item.email,
          emailCloned: item.emailCloned,
          roles: item.roles,
          id: item.supplierId,
          type: "supplier",
          canClone: item?.canClone,
          canDelete: item?.canDelete,
          canResetPassword: item.canResetPassword,
          isCloned: item?.isCloned,
          username: item.userName,
          userId: item.userId,
          usernameCloned: item.usernameCloned,
          typeAction: "S",
          token: item.token,
        };
      });

    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li className="is-active">
              <NavLink to="/bpool/usuarios" className="is-black">
                {I18n.get("Logins")}
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="columns">
          <div className="column is-12">
            <ContainerTabs>
              <div className="card-container">
                <Tabs type="card" onChange={this.onChangeTab}>
                  <TabPane tab="Cliente" key="1">
                    <div>
                      <Row>
                        <Col md={24}>
                          <p>
                            <b>{I18n.get("Cliente:")}</b>
                          </p>
                          <Select
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            searchBy="label"
                            style={{ width: 600 }}
                            onChange={(e) => {
                              this.handleChange("client", e);
                            }}
                          >
                            {clientsLogins &&
                              clientsLogins.map((item) => {
                                return (
                                  <Option
                                    key={item.code}
                                    value={item.code}
                                    label={item.label}
                                  >
                                    {item.label}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Col>
                      </Row>

                      <Row className="RowTable">
                        <Col md={24}>
                          <div className="">
                            <Table
                              scroll={{ x: 640 }}
                              columns={columns}
                              dataSource={dataLoginClient}
                              locale={{ emptyText: "Sem Informação" }}
                              pagination={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tab="Parceiro" key="2">
                    <div>
                      <Row>
                        <Col md={24}>
                          <p>
                            <b>{I18n.get("Parceiro:")}</b>
                          </p>
                          <Select
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            searchBy="label"
                            style={{ width: 600 }}
                            onChange={(e) => {
                              this.handleChange("partner", e);
                            }}
                          >
                            {partnersLogins &&
                              partnersLogins.map((item) => {
                                return (
                                  <Option
                                    key={item.code}
                                    value={item.code}
                                    label={item.label}
                                  >
                                    {item.label}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Col>
                      </Row>

                      <Row className="RowTable">
                        <Col md={24}>
                          <div className="">
                            <Table
                              scroll={{ x: 640 }}
                              columns={columns}
                              dataSource={dataLoginPartner}
                              locale={{ emptyText: "Sem Informação" }}
                              pagination={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tab="Fornecedor" key="3">
                    <div>
                      <Row>
                        <Col md={24}>
                          <p>
                            <b>{I18n.get("Fornecedor:")}</b>
                          </p>
                          <Select
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            searchBy="label"
                            style={{ width: 600 }}
                            onChange={(e) => {
                              this.handleChange("supplier", e);
                            }}
                          >
                            {suppliersLogins &&
                              suppliersLogins.map((item) => {
                                return (
                                  <Option
                                    key={item.code}
                                    value={item.code}
                                    label={item.label}
                                  >
                                    {item.label}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Col>
                      </Row>

                      <Row className="RowTable">
                        <Col md={24}>
                          <div className="">
                            <Table
                              scroll={{ x: 640 }}
                              columns={columns}
                              dataSource={dataLoginSupplier}
                              locale={{ emptyText: "Sem Informação" }}
                              pagination={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </ContainerTabs>
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginsListAdmin);
