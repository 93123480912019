import React from "react";
import { I18n } from "aws-amplify";
import { Input, Button, Icon, Select } from "antd";
import Highlighter from "react-highlight-words";

const { Option } = Select;

const handleSearch = (
  selectedKeys,
  confirm,
  dataIndex,
  editFilter,
  options,
  reference
) => {
  editFilter(reference, selectedKeys[0]);
  confirm();
};

const handleReset = (clearFilters, editFilter, reference) => {
  editFilter(reference, "");

  clearFilters();
};

const selectColumnSearchProps = (dataIndex, options, editFilter, reference) => {
  let searchText = "";
  let searchedColumn = "";

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} data-label={`Search-${dataIndex}`}>
        <Select
          onChange={(e) => {
            setSelectedKeys(e ? [e] : []);
          }}
          onSelect={(e) => {
            setSelectedKeys(e ? [e] : []);
          }}
          onSearch={(e) => {
            setSelectedKeys(e ? [e] : []);
          }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
        >
          {options &&
            options.map((item, index) => {
              return <Option value={item.code}>{item.label}</Option>;
            })}
        </Select>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(
              selectedKeys,
              confirm,
              dataIndex,
              editFilter,
              options,
              reference
            )
          }
          // icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {I18n.get("Buscar")}
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, editFilter, reference)}
          size="small"
          style={{ width: 90 }}
        >
          {I18n.get("Cancelar")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  };
};

export default selectColumnSearchProps;
