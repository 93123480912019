import styled from "styled-components";

export const ContentBpay = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const TitleContent = styled.div`
  padding: 0;
  margin: 8px 0 32px 0;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const SearchWrapper = styled.div`
  width: 50%;
`;
