import styled, { keyframes } from "styled-components";

export const Table = styled.table`
  width: 100%;
  border: none !important;
`;

export const TableHeader = styled.th`
  height: 50px;
  background-color: #f0f0f0;
  padding-top: 10px;
  font-size: 14px;
`;

export const TableData = styled.td`
  font-size: 14px;
`;

export const TableRow = styled.tr`
  line-height: 50px;
  cursor: pointer;
  border-bottom: 1px solid;
  border-color: #f0f0f0;

  td:first-of-type {
    width: 40px;
  }
`;

export const TableAccordion = styled.div`
  /* Estilos do accordion */
  display: block;
  overflow: hidden;
  max-height: ${(props) =>
    props.isOpen ? `${props?.itemsRows * 40 + 30}px` : "0"};
  animation: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  background: #fafafa;
`;

export const TableAccordionContent = styled.div`
  padding: 15px;
`;

export const ButtonFileUpload = styled.button`
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
  margin-top: 10px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: ${(props) => (props?.disabled ? "not-allowed" : "pointer")};
`;

export const RowFile = styled.div`
  cursor: default;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;

  :last-child {
    border: none;
  }
`;

export const ContentDeleteMacro = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`;

export const IcoOpenClose = styled.div`
  width: 20px;
  height: 20px;
  margin-top: 15px;
  border-radius: 4px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DelFile = styled.button`
  cursor: ${(props) => (props?.disabledButton ? "not-allowed" : "pointer")};
  background-color: ${(props) =>
    props?.disabledButton ? "#e7e7e7" : "#ff0000"};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  svg {
    color: ${(props) => (props?.disabledButton ? "#696969" : "#fff")};
    cursor: ${(props) => (props?.disabledButton ? "not-allowed" : "pointer")};
  }
`;

export const FilesInModal = styled.div``;

export const ButtonClientApproval = styled.button`
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
  margin-top: 10px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
`;
