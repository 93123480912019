import styled from "styled-components";
import { I18n } from "aws-amplify";

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const H5 = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 15px;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const RowMain = styled.div`
  margin-top: 60px;
`;

export const CloseScope = styled.div`
  background: #2f2f2f;
  padding: 20px 30px;
  border-radius: 10px;
  margin: 0 0 60px;
  p {
    color: #fff;
    text-align: center;
  }
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const WrapperCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;

  div {
    :first-of-type {
      margin-left: 0;
    }
  }
`;

export const BoxRequired = styled.div`
  border-bottom: solid 1px #000;
  padding-bottom: 20px;
  margin-top: 40px;
  p {
    margin-bottom: 15px;
  }
`;

export const BoxNotRequired = styled.div`
  border-bottom: solid 1px #000;
  padding-bottom: 20px;
  margin-top: 40px;
  p {
    margin-bottom: 15px;
  }

  button {
    &.ant-btn {
      padding-left: 8px;
      padding-right: 8px;
      display: inline-block;
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 10px;

      img {
        margin-left: 5px;
        width: 22px;
      }
    }
  }
`;

export const ListBriefings = styled.div`
  margin-top: 20px;
  height: 830px;
  overflow: auto;
  padding-right: 10px;
`;

export const RowFooter = styled.div`
  padding-top: 50px;
  margin-top: 100px;
  border-top: solid 1px #000;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .btnPrev {
    margin-right: 20px;
  }
`;

export const BtnDownload = styled.a`
  margin-left: 40px;
  font-style: normal;

  &.disabled {
    color: #000;
    opacity: 0.4;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .labelText {
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    text-decoration-line: underline;
    margin-left: 15px;
    color: #1883ff;
    position: relative;
    top: 2px;
  }
`;

export const Item = styled.div`
  margin-bottom: 10px;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 150px;

  a,
  button {
    margin-left: 10px;
  }
`;

export const DisplayErrors = styled.div`
  h2 {
    font-weigth: bold;
  }

  div {
    margin-top: 30px;
  }
`;

export const TextEdit = styled.div``;

export const TitleDates = styled.div`
  padding: 15px 0 35px 0;

  p {
    font-size: 18px;
    line-height: 25px;
  }
`;

export const TitleStartDate = styled.div`
  margin-bottom: 25px;

  p {
    span {
      font-weight: 600;
      margin-left: 5px;
    }
  }
`;

export const ContentAttachments = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 50px 0 0 0;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
    margin: 50px 15px 0 15px;
  }
`;

export const SelectAttachment = styled.button`
  margin: 0 10px;
  border: none;
  border-radius: 10px;
  border-color: #434343;
  padding: 0;
  display: flex;
  width: 150px;
  height: 150px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  opacity: ${(props) => (props.disabledButton ? "0.4" : "1")};

  @keyframes color {
    0% {
      background-color: #efefef;
    }
    50% {
      background-color: #000;
    }
    100% {
      background-color: #000;
    }
  }

  &.recording {
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    img {
      width: 50px;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
    height: 100px;
  }
`;

export const BoxSelectAttachment = styled.div`
  @media (max-width: 768px) {
    display: flex;
    border: solid 2px;
    width: calc(100% - 20px);
    padding: 0 5px;
  }

  button {
    background-color: ${(props) =>
      props.disabledButton ? "#f0f0f0" : "#e7e7e7"};
    cursor: ${(props) => (props.disabledButton ? "not-allowed" : "pointer")};
    width: 210px;
    height: 120px;
  }

  button:after {
    content: "${I18n.get("Upload")}";
    font-size: 18px;
    font-weight: 700;
    margin-left: 25px;
    color: ${(props) => (props.disabledButton ? "#979797" : "#000000")};
  }
`;

export const AttachmentList = styled.div`
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
`;

export const Loading = styled.div`
  text-align: center;
  padding: 10px;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  @keyframes color {
    0% {
      background-color: #fff;
    }
    50% {
      background-color: #fafafa;
    }
    100% {
      background-color: #fafafa;
    }
  }
`;

export const AttachmentSelected = styled.div`
  color: #1883ff;
  font-size: 16px;
  line-height: 24px;
  margin: 5px 0 0 10px;
  width: 100%;
  border-bottom: solid 1px #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon-delete {
    color: ${(props) => (props.disabledButton ? "#e7e7e7" : "#1883ff")};
    cursor: ${(props) => (props.disabledButton ? "not-allowed" : "pointer")};
  }

  .icon-delete:hover {
    color: ${(props) => (props.disabledButton ? "#e7e7e7" : "#2883ed")};
  }

  a {
    font-size: 14px;
    color: #1883ff;
    padding: 5px 0;

    &:hover {
      color: #2883ed;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
