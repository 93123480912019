import React, { PureComponent } from "react";
import { I18n } from 'aws-amplify';
import { Icon, Tooltip } from 'antd';

import styled from "styled-components";

const List = styled.ul`
    margin-top: 20px;
    li {
        overflow: hidden;
        display: block;

    }
    a {
        color: #000;
    }
    i {
        display: inline-block;
        width: 30px;
        font-size: 20px;
        float: left;
        margin-right: 10px;
    }
    .MainLink {
        display: block;
        width: 80%;
        overflow: hidden;
        position: relative;
        height: 30px;

        span {
            max-width: 250px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden; /* "overflow" value must be different from "visible" */
            text-overflow:    ellipsis;
            position: relative;
            top: 2px;
        }
    }
`


class ListLinks extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (

            <List>
                {this.props.data &&
                    this.props.data.map(item => {
                        return (
                            <li>
                                <a href={item} target="_blank">
                                    <Tooltip title={item}>
                                        {this.props.icon &&
                                            <Icon type={this.props.icon} />
                                        }
                                        <span className="MainLink">
                                            <span className="Mask">{item}</span>
                                        </span>
                                    </Tooltip>
                                </a>
                            </li>
                        )
                    })
                }
            </List>
        );
    }
}

export default ListLinks;
