import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { I18n } from "aws-amplify";
import * as S from "./styles";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";

import { useProjects } from "../../../../hooks/useProjects/useProjects.hook";
import { useReview } from "../../../../hooks/useReview/useReview.hook";

import Content from "../../../../components/UI/Content";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { H2Border } from "../../../../components/UI/Titles";

import StepsCreateProject from "../components/StepsCreateProject";

import Main from "../components/Main";
import TopInformation from "../components/TopInformation";
import GlobalFileBox from "../../../Client/CreateProject/components/GlobalFileBox";
import { Row, Col } from "antd";

import IcoForecast from "../svgs/ico-step5-forecast.svg";
import IcoCheck from "../svgs/ico-step5-check.svg";
import IcoPayment from "../svgs/ico-step5-payment.svg";
import IcoTime from "../svgs/ico-step5-time.svg";

import { AssetBundle } from "../../../../../v2/components/UI/AssetBundle";
import { ConvertValuesCreativeScope } from "../../../../../v2/pages/ProjectsList/Invitations/ProjectDetails/utils/convertValuesCreativeScope";

const STEP = "step6";

export const CreateProjectStep6 = () => {
  const { putProjectByStep, putGlobal, removeGlobal } = useProjects();
  let params = useParams();
  const [projectDetails, setProjectDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [projectErrors, setProjectErrors] = useState({});
  const state = useSelector((state) => state);
  let history = useHistory();
  const [listOfAssets, setListOfAssets] = useState([]);
  const [dataValuesCreativeScope, setDataValuesCreativeScope] = useState({});
  const [currencyProject, setCurrencyProject] = useState({});

  const { getReviewDefinition } = useReview();

  const getStep = async () => {
    async function getProject() {
      const data = {
        projectId: params.projectId,
      };

      if (data.projectId) {
        const response = await getReviewDefinition({ id: data.projectId });
        setProjectDetails(response?.data);
        setListOfAssets(response?.data?.bundle?.variations[0]?.assets);

        const objDataValuesCreativeScope = ConvertValuesCreativeScope({
          data: response?.data,
          isInvite: false,
        });

        setDataValuesCreativeScope(objDataValuesCreativeScope);
        setCurrencyProject(response?.data?.currency);
      }
    }
    getProject();
  };

  function getCategories() {
    if (!state?.bp?.sectorsFlat) return [];
    if (state?.sameCategoryOnly) return [];
    if (!state.categoryIds) return [];

    return (
      projectDetails?.categoryIds &&
      projectDetails?.categoryIds.map((categoryId) => {
        const category = state?.bp?.sectorsFlat.filter(
          (item) => item.code == categoryId
        )[0];

        return category.label;
      })
    );
  }

  const sendToServer = async () => {
    try {
      setLoader(true);

      const id = params.projectId;

      const infosToServer = {
        id: id,
      };

      const response = await putProjectByStep(infosToServer, STEP);
      if (response.success) {
        history.push(`/cliente/projetos/v2/sucesso/${id}`);
      } else {
        setProjectErrors(response.errors);
        setLoader(false);
      }
    } catch (error) {
      setProjectErrors(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getStep();
    // calcTotal();
  }, []);

  const stepItens = [
    {
      key: 1,
      label: I18n.get("Informações"),
      url: `/cliente/projetos/v2/criar-projeto/${params.projectId}`,
    },
    {
      key: 2,
      label: I18n.get("Tipo"),
      url: `/cliente/projetos/v2/selecionar-assets/${params.projectId}`,
    },
    {
      key: 3,
      label: I18n.get("Demandas"),
      url: `/cliente/projetos/v2/demandas/${params.projectId}`,
    },
    {
      key: 4,
      label: I18n.get("Parceiros"),
      url: `/cliente/projetos/v2/parceiros/${params.projectId}`,
    },
    {
      key: 5,
      label: I18n.get("Checkout"),
      url: `/cliente/projetos/v2/checkout/${params.projectId}`,
    },
    {
      key: 6,
      label: I18n.get("Revisão"),
      url: `/cliente/projetos/v2/review/${params.projectId}`,
    },
  ];

  const getStepError = (step) => {
    let stepError = {};
    stepError = stepItens.find((i) => i.key == step);

    return stepError;
  };

  const { demandFiles = [] } = projectDetails;

  const handleConvertedValuesCreativeScope = async ({ values, currency }) => {
    const data = {
      ...values,
      currency,
    };
    const objDataValuesCreativeScope = ConvertValuesCreativeScope({
      data,
      isInvite: false,
    });
    setDataValuesCreativeScope(objDataValuesCreativeScope);

    const listAssetsUpdated = listOfAssets.map((item1) => {
      const matchingItem = values?.bundle?.variationResponse?.assets?.find(
        (item2) => item2.code === item1.code
      );

      if (matchingItem) {
        return {
          ...item1,
          priceFmt: matchingItem.priceFmt,
          priceTotalFmt: matchingItem.priceTotalFmt,
        };
      }

      return item1;
    });
    setListOfAssets(listAssetsUpdated);
  };

  const saveGlobal = async (data) => {
    const dataSend = {
      file: { ...data },
      projectId: projectDetails?.id,
    };

    if (data?.id) {
      const response = await putGlobal(dataSend);
      if (response?.success) {
        getStep();
      }
    } else {
      const response = await removeGlobal(dataSend);
      if (response?.success) {
        getStep();
      }
    }
  };

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Novo Projeto")}</H2Border>
            </Col>
            <Col sm={12} justify="end"></Col>
          </Row>
          <Row>
            <Col sm={24}>
              <S.StyleMain>
                <StepsCreateProject
                  active={6}
                  projectId={params.projectId || ""}
                />
              </S.StyleMain>

              <Main bgColor="#fff" padding="30px">
                <TopInformation>
                  <>
                    <h3>{I18n.get("Resumo do projeto")}</h3>

                    <p>
                      {I18n.get(
                        "Veja abaixo as informações mais relevantes do seu projeto"
                      )}
                    </p>
                  </>
                </TopInformation>

                <S.ContentReview>
                  <Row>
                    <Col xs={12} sm={12}>
                      <S.WrapperReview>
                        <S.TitleReview>
                          {I18n.get("Informações")}
                        </S.TitleReview>
                        <S.BoxReview>
                          <ul>
                            <li>
                              <strong>{I18n.get("Nome do Projeto:")}</strong>
                              <span>{projectDetails?.projectName}</span>
                            </li>
                            <li>
                              <strong>{I18n.get("Número do Projeto:")}</strong>
                              <span>{projectDetails?.projectSKU}</span>
                            </li>
                            <li>
                              <strong>{I18n.get("Produto:")}</strong>
                              <span>
                                {projectDetails?.brandName} -{" "}
                                {projectDetails?.productName}
                              </span>
                            </li>
                          </ul>
                        </S.BoxReview>
                      </S.WrapperReview>

                      <S.WrapperReview>
                        <S.TitleReview>
                          {I18n.get("Escopo Criativo")}
                        </S.TitleReview>
                        <S.BoxReview>
                          <AssetBundle
                            isInvite={false}
                            isClient={true}
                            listOfAssets={
                              listOfAssets?.length ? listOfAssets : []
                            }
                            dataValues={dataValuesCreativeScope}
                            convertedValues={handleConvertedValuesCreativeScope}
                            reload={getStep}
                            isModalConfirm={true}
                            descriptiveHourMan={
                              projectDetails?.descriptiveHourMan
                            }
                            isCart
                            currencyProject={currencyProject}
                          />
                        </S.BoxReview>
                      </S.WrapperReview>

                      <div style={{ marginTop: 30 }}>
                        <a href={projectDetails?.pdfProject} target="_blank">
                          <ButtonBpool
                            text={I18n.get("Baixar PDF")}
                            className=""
                            theme="primary"
                            disabled={projectDetails?.pdfProject ? false : true}
                          />
                        </a>
                      </div>
                    </Col>

                    <Col xs={12} sm={12}>
                      <S.WrapperReview>
                        <S.TitleReview>
                          {I18n.get("Demanda geral")}
                        </S.TitleReview>
                        <S.BoxReview>
                          {projectDetails?.demand ? (
                            <p style={{ marginBottom: 20 }}>
                              {projectDetails?.demand}
                            </p>
                          ) : null}

                          <p>
                            <b>{I18n.get("Arquivos")}</b>
                          </p>

                          {demandFiles.map((file) => {
                            if (file.type != "audio/mp3") {
                              return (
                                <S.AttachmentSelected key={file?.id}>
                                  <a href={file?.url}>{file?.name}</a>
                                </S.AttachmentSelected>
                              );
                            }
                          })}
                        </S.BoxReview>
                      </S.WrapperReview>

                      <S.WrapperReview>
                        <S.TitleReview>
                          {I18n.get("Sobre o Parceiro")}
                        </S.TitleReview>
                        <S.BoxReview>
                          <ul>
                            <li>
                              <strong>{I18n.get("Parceiro")}:</strong>

                              <span>
                                {" "}
                                {projectDetails?.selectPartnerName ||
                                  projectDetails?.partnerName ||
                                  I18n.get("A ser definido")}
                                <br />
                                {projectDetails?.selectPartnerName && (
                                  <em>
                                    {I18n.get(
                                      "Você selecionou um parceiro no qual já trabalhou."
                                    )}
                                  </em>
                                )}
                              </span>
                            </li>
                            {!projectDetails?.selectPartnerName && (
                              <li>
                                <strong>{I18n.get("Perfil")}:</strong>
                                <p>
                                  {projectDetails?.sameCategoryOnly && (
                                    <span>
                                      {I18n.get(
                                        "Procuro apenas parceiros criativos com experiência na categoria da minha marca."
                                      )}
                                    </span>
                                  )}
                                  {!projectDetails?.sameCategoryOnly && (
                                    <span>
                                      {I18n.get(
                                        "Procuro parceiros criativos com experiência nas seguintes categorias:"
                                      )}{" "}
                                      {getCategories().join(", ")}{" "}
                                    </span>
                                  )}
                                </p>
                              </li>
                            )}
                            {projectDetails?.partnerResponsibleName &&
                              projectDetails?.partnerResponsibleEmail && (
                                <>
                                  <li>
                                    <strong>{I18n.get("Nome")}:</strong>
                                    <span>
                                      {projectDetails?.partnerResponsibleName ||
                                        I18n.get("A ser definido")}
                                    </span>
                                  </li>
                                  <li>
                                    <strong>{I18n.get("Email")}:</strong>
                                    <span>
                                      {projectDetails?.partnerResponsibleEmail ||
                                        I18n.get("A ser definido")}
                                    </span>
                                  </li>
                                </>
                              )}
                          </ul>
                        </S.BoxReview>
                      </S.WrapperReview>

                      <S.WrapperReview>
                        <S.TitleReview>{I18n.get("Detalhes")}</S.TitleReview>
                        <S.BoxReviewDetails spacing="extra-large">
                          <ul>
                            <li>
                              <div>
                                <img src={IcoForecast} alt="Ico Forecast" />
                              </div>
                              <div style={{ marginLeft: 25 }}>
                                <strong>{I18n.get("Previsão do Projeto")}:</strong>
                                <br />

                                <p>
                                  <b>{I18n.get("Início")}:</b>{" "}
                                  {projectDetails?.estimatedStartDate}
                                </p>
                                <p>
                                  <b>{I18n.get("Término")}:</b>{" "}
                                  {projectDetails?.estimatedDurationInMonths
                                    ? projectDetails?.estimatedDurationInMonths
                                    : "0"}{" "}
                                  {I18n.get("mes(es) e")}{" "}
                                  {projectDetails?.estimatedDurationInWeeks
                                    ? projectDetails?.estimatedDurationInWeeks
                                    : "0"}{" "}
                                  {I18n.get("semana(s)")}
                                </p>
                              </div>
                            </li>
                            <li>
                              <div>
                                <img src={IcoCheck} alt="Ico Check" />
                              </div>
                              <div style={{ marginLeft: 25 }}>
                                <strong>
                                  {I18n.get("Prazo de seleção do parceiro")}:
                                </strong>
                                <span>
                                  {projectDetails?.estimatedDecisionInWeeks}{" "}
                                  {I18n.get("semana(s)")}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div>
                                <img src={IcoPayment} alt="Ico Payment" />
                              </div>
                              <div style={{ marginLeft: 25 }}>
                                <strong>
                                  {I18n.get("Prazo pagamento do cliente")}:
                                </strong>
                                <span>
                                  {I18n.get(
                                    "90 dias corridos a partir da data de faturamento."
                                  )}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div>
                                <img
                                  src={IcoTime}
                                  alt="Ico Time"
                                  style={{ width: "26px" }}
                                />
                              </div>
                              <div style={{ marginLeft: 25 }}>
                                <strong>{I18n.get("Termo de Exclusividade")}:</strong>
                                <span>
                                  {projectDetails?.nonCompeteInDaysFmt}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </S.BoxReviewDetails>
                      </S.WrapperReview>
                    </Col>
                  </Row>
                </S.ContentReview>
                <br />

                {projectDetails?.productRequiredGlobalAuthorization ? (
                  <Row>
                    <Col xs={24} sm={24}>
                      <S.WrapperReview>
                        <S.BoxReview style={{ padding: 0 }}>
                          <GlobalFileBox
                            title={I18n.get("Atenção!")}
                            description={I18n.get(
                              "Esta é uma marca Global. Anexe aqui a aprovação de global para este projeto."
                            )}
                            username={state?.auth?.username}
                            action={(data) => saveGlobal(data)}
                            file={projectDetails?.globalAuthorization}
                          />
                        </S.BoxReview>
                      </S.WrapperReview>
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col lg={24}>
                    {Object.keys(projectErrors).map((item, index) => {
                      let stepErrorItem = getStepError(
                        projectErrors[item]?.step
                      );

                      return (
                        <p>
                          <Link to={stepErrorItem?.url}>
                            {" "}
                            <span class="icon has-text-danger">
                              <i class="fas fa-exclamation"></i>
                            </span>
                            {projectErrors[item].errorMessage} [
                            {I18n.get("Editar")}]
                          </Link>
                        </p>
                      );
                    })}
                  </Col>
                </Row>

                <Row>
                  <Col lg={24}>
                    <S.FooterButtons className="buttonsContainer">
                      <ButtonBpool
                        text={I18n.get("Voltar")}
                        theme="secondary"
                        onClick={() =>
                          history.push(
                            `/cliente/projetos/v2/checkout/${params.projectId}`
                          )
                        }
                      />
                      <ButtonBpool
                        text={I18n.get("Criar Projeto")}
                        className=""
                        theme="primary"
                        loading={loader}
                        onClick={() => sendToServer()}
                      />
                    </S.FooterButtons>
                  </Col>
                </Row>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
