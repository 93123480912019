import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import styled from 'styled-components';
import { CardBorder } from './Cards';
import ItemMacro from './ItemFileMacros';
import FormUpload from './formUploadMacro';
import IcoTempo from '../../image/ico-tempo.png';

import locale from 'antd/es/date-picker/locale/pt_BR';

import {
    Row,
    Col,
    Checkbox,
    Form,
    Table,
    Icon,
    notification,
    Tag,
    Input,
    DatePicker,
    Drawer,
    Popconfirm,
    Button
} from 'antd';


const MainTable = styled(Table)`

    .statusMacro {
        text-align: center;
    }

    .btupload {
        position: relative;
        color: #6d6d6d;

        span {
            float: left;
        }

        i {
            font-style: normal;
            margin-right: 5px;
            position: relative;
            top: 5px;
        }

        &:hover {
            color: #1883ff;
        }
    }


    .ant-table-expanded-row td {
        background: #f7f7f7;
        padding: 0px;

        .tableMilestoneFiles {
            padding: 10px 20px 10px 10px;

            td {
                padding: 7px;
            }
        }
    }
    
`

const Main = styled.div`
    max-width: 1024px;
    margin: 50px auto 0px;
    box-sizing: border-box;
    padding: 20px 40px;
    border-radius: 20px;

    &.review {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .miniTit {
        margin-top: 20px;
    }

    .form {
        margin-top: 30px;
    }

    @media screen and (max-width: 768px) {
         padding: 20px 0px;
    }
`

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];



class ViewMacro extends Component {

    constructor(props) {
        super();
        this.state = {
            visible: false,
            activeKey: "",
            milestoneId: "", 
            loading: false
        };
    }


    DeleteFile = (data) => {
        const dataDelete = {
            projectId: this.props.project.projectId,
            ...data
        }

        this.props.deleteFile(dataDelete);
    }

    closeModal = () => {
        this.setState({
            visible: false
        })
    }

    openModal = (id) => {
        this.setState({
            milestoneId: id,
            visible: true
        })
    }

    onChange = e => {
        let data = {
            flag: e.target.checked,
            projectId: this.props.project.projectId
        }

        this.props.update(data);
    }
    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            loading: true
        });

        this.props.form.validateFields((err, values) => {
            if (!err) {

            }

            this.setState({
                formState: values,
            })

            let data = {
                ...values,
                projectId: this.props.project.projectId
            }

            this.props.create(data);

            setTimeout(() => {
                this.setState({
                    loading: false
                });
            }, 2000);
        });
    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {

                if (field == "name" || field == "estimatedDate") {
                    this.props.form.setFields({
                        [field]: {
                            value: this.state.formState[field],
                            errors: [new Error(this.props.errors[field].errorMessage)],
                        },
                    });
                }
            });
        }

        if (this.props.project.milestones && prevProps.project.milestones && this.props.project.milestones.length > prevProps.project.milestones.length) {

            notification.success({
                message: I18n.get(I18n.get('Adicionado com sucesso!')),
                description: I18n.get('Macro entrega foi adicionada'),
                duration: 3
            });

            this.props.form.resetFields();

            let after = this.props.project.milestones && this.props.project.milestones.length - 1;
            this.setState({
                activeKey: after.toString()
            })

        }

        if (this.props.project.milestones && prevProps.project.milestones && this.props.project.milestones.length < prevProps.project.milestones.length) {

            notification.success({
                message: I18n.get('Removido com sucesso!'),
                description: I18n.get('Macro entrega foi removida'),
                duration: 3
            });
        }

        if (this.props.project.milestones && prevProps.project.milestones && this.props.project.milestones != prevProps.project.milestones) {
            this.setState({
                visible: false
            });
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        let dataSource = this.props.project && this.props.project.milestones && this.props.project.milestones.map((item, index) => {

            return ({
                key: index,
                name: item.name,
                date: item.estimatedDate,
                item: item,
            })
        });

        const columns = [
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name',
            },
           
            {
                title: I18n.get("Data"),
                dataIndex: 'date',
                key: 'date',
            }
        ];

        let limitMacro = this.props.project.milestones && this.props.project.milestones.length >= 5 ? true : false

        return (
            <Main className={this.props.isReview ? "review" : ""}>
                {this.props.isPartner && !this.props.isReview &&
                    <div>
                        <CardBorder
                        >
                            <Row>
                                <Col sm={24}>
                                    <Checkbox disabled={!this.props.disabled} checked={this.props.project.isMensalFee} onChange={(e) => this.onChange(e)}>{I18n.get("Meu projeto é do tipo FEE MENSAL, não necessito de Macro Entregas")}</Checkbox>
                                </Col>
                            </Row>
                        </CardBorder>

                        {!this.props.project.isMensalFee &&
                            <CardBorder
                            >
                                <Row>
                                    <Col sm={24}>
                                        <p className="miniTit">
                                            <b>{I18n.get("Por favor, defina de 2 a 5 macro entregas que serão necessárias neste projeto com uma estimativa de data para cada um desses momentos. Isto servirá de suporte para as avaliações que serão feitas durante o projeto")}</b>
                                        </p>
                                    </Col>
                                </Row>
                                <div className="form">
                                    <Form onSubmit={this.handleSubmit}>
                                        <Row>
                                            <Col sm={8}>
                                                <Form.Item label={I18n.get("Nome Macro Entrega")}>
                                                    {getFieldDecorator('name', {
                                                        rules: [{ required: false, message: 'Please input your username!' }],
                                                    })(
                                                        <Input
                                                            placeholder={I18n.get("Nome Macro Entrega")}
                                                            disabled={!this.props.disabled || limitMacro}
                                                        />,
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Item label={I18n.get("Data Estimada")}>
                                                    {getFieldDecorator('estimatedDate', {
                                                        rules: [{ required: false, message: 'Please input your Password!' }],
                                                    })(
                                                        <DatePicker
                                                            disabled={!this.props.disabled || limitMacro} placeholder={I18n.get("Selecione")} locale={locale} format={dateFormatList} style={{ width: "100%" }} />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Item className="labelClean" label="&nbsp;">
                                                    <Button disabled={!this.props.disabled || limitMacro} type="primary" htmlType="submit">{I18n.get("Adicionar")}</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </CardBorder>
                        }

                    </div>
                }
                {
                    !this.props.project.isMensalFee &&
                    <CardBorder
                    >
                        <br />
                        <div className="table">
                            <Row>
                                <Col sm={24}>

                                    {this.state.loading &&
                                        <p className="iconeLoading">
                                            <Icon type="loading" />
                                        </p>
                                    }
                                    {!this.state.loading && dataSource && 
                                    
                                        <MainTable 
                                            locale={{ emptyText: I18n.get('Sem informação') }} 
                                            dataSource={!this.state.loading && dataSource} 
                                            columns={columns} 
                                            expandIcon={false}
                                            defaultExpandAllRows={true}
                                            expandedRowRender={record => {
                                                if(record.item.files.length > 0){
                                                    return(
                                                        <ItemMacro 
                                                            className={record.item.files.length > 0 ? "":"itemNone"}
                                                            item={record.item} 
                                                            username={this.props.username}
                                                            deleteFile={this.DeleteFile} 
                                                            disabled={!record.item.partnerConfirmDelivered || !record.item.clientConfirmDelivered}
                                                        />
                                                    )
                                                } else {
                                                    return false;
                                                }
                                            }
                                        }
                                        
                                            pagination={false}
                                            updateMilestoneId={this.updateMilestoneId}
                                            username={this.props.username}
                                        />
                                    }

                                </Col>
                            </Row>
                        </div>
                    </CardBorder>
                }

                {
                    this.props.project.isMensalFee && !this.props.isPartner &&
                    <CardBorder
                    >
                        <p>{I18n.get("Projeto do tipo mensal, não tem macros definidas.")}</p>
                    </CardBorder>
                }


                <Drawer
                    title={I18n.get("Upload")}
                    placement="right"
                    closable={true}
                    onClose={() => this.closeModal()}
                    destroyOnClose={true}
                    visible={this.state.visible}
                    width="30%"
                >
                    <FormUpload 
                        action={this.props.uploadMilestone}
                        projectId={this.props.project.projectId}
                        milestoneId={this.state.milestoneId}
                    />   
                </Drawer>

            </Main>
        );
    }
}


const ViewMacroForm = Form.create({})(ViewMacro);

export default ViewMacroForm;
