import React from 'react';

import { I18n } from 'aws-amplify';

export default class PasswordField extends React.Component {

    constructor(props) {
        super();

        this.state = {
            focused: false,
            visited: props.visited
        };
    }

    static defaultProps = {
        successIcon: true
    }

    onBlur() {
        this.setState({ focused: false });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onFocus() {
        this.setState({ focused: true, visited: true });
    }

    render() {

        const inputClassName = this.props.inputClassName || 'input';

        const isError = !this.state.focused && this.state.visited && this.props.error;
        const isValid = !!this.props.value && !this.props.error;

        return (<div className="field">
            <label className="label is-small">
                {this.props.label}
            </label>
            <div className="control has-icons-right">
                <input
                    onChange={this.props.onChange}
                    onBlur={() => this.onBlur()}
                    onFocus={() => this.onFocus()}
                    value={this.props.value || ''}
                    className={isError ? inputClassName + " is-danger" : inputClassName}
                    type="password"
                    placeholder="" />
                {this.props.successIcon && isValid && <span className="icon is-small is-right form-field-valid">
                    <i className="fa fa-check-circle"></i>
                </span>}
            </div>
            {isError && <p className="help is-danger">
                {this.props.error.errorMessage}
            </p>}
        </div>)
    }
}
