import React from "react";
import * as S from "./styles";

const Main = ({ bgColor, isSubmenu, children, padding }) => {
  return (
    <S.TopInformation bgColor={bgColor} isSubmenu={isSubmenu} padding={padding}>
      {children}
    </S.TopInformation>
  );
};

export default Main;
