import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Task } from "../../utils/task";

import { PROJECT_API } from "../../actions/project/actions";
import { BP_API } from "../../actions/bp/actions";

import LoggedPage from "../page-templates/loggedAdmin";

import { CardSimple } from "../../modules/components/Cards";
import FormFilterProject from "../../modules/Admin/components/filterBillingsAdmin";
import TableBillings from "../../modules/Admin/components/tableBillingsAdmin";

import styled from "styled-components";

import {
  Row,
  Col,
  Button,
  notification,
  Icon,
  Modal,
  DatePicker,
  Input,
} from "antd";

const NoData = styled.div`
  padding: 50px;
  text-align: center;

  h3 {
    font-weight: 700;
    margin-bottom: 30px;
  }

  i {
    font-size: 40px;
  }

  .txt {
    position: relative;
    left: 30px;
    top: -15px;
  }
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_projects_and_extras_list: (data) => {
      dispatch(BP_API.get_projects_and_extras_list(data));
    },
    get_filters_projects: () => {
      dispatch(BP_API.get_filters_projects());
    },
    reset: () => {
      dispatch(PROJECT_API.reset());
    },
    generate_financial: (data) => {
      dispatch(BP_API.generate_financial(data));
    },
    delete_po: (data) => {
      return dispatch(BP_API.delete_po(data));
    },
  };
};

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

class ClientProjectListAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      historySearch: {},
      task: {},
      listPo: [],
      itemDate: null,
    };
  }

  handleFilters = (data) => {
    var task = new Task();

    task.start();

    this.setState({ task: task, historySearch: data }, function () {
      this.props.get_projects_and_extras_list(data);
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.bp.task || {};
    const prev = (prevProps && prevProps.bp.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      this.setState({ task: task });
    }
  }

  componentDidMount() {
    this.props.reset();
    this.props.get_filters_projects();
  }

  // getPos = (id) => {

  //     let newList = this.state.listPo;

  //     if(newList.indexOf(id) == -1) {
  //         newList.push(id);
  //         this.setState({
  //             listPo:newList
  //         })
  //     } else if(newList.indexOf(id) == 1) {
  //         newList.splice(newList.indexOf(id), 1);
  //         this.setState({
  //             listPo:newList
  //         })
  //     }
  // }

  getPos = (item, id) => {
    let newList = this.state.listPo;

    const itemObj = {
      projectId: item.projectId,
      billingId: id,
      extraId: item.extraId,
    };

    if (newList.findIndex((x) => x.billingId === id) == -1) {
      newList.push(itemObj);
      this.setState({
        listPo: newList,
      });
    } else {
      newList.splice(
        newList.findIndex((x) => x.billingId === id),
        1
      );

      this.setState({
        listPo: newList,
      });
    }
  };

  handlegenerate = () => {
    const data = {
      email: this.props.auth.email,
      pos: this.state.listPo,
    };

    this.props.generate_financial(data);
  };

  handleDeletePo = (data) => {
    this.props
      .delete_po(data)
      .then((response) => {
        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
          this.props.get_projects_and_extras_list(this.state.historySearch);
        });
      })
      .catch((error) => {
        if (Object.keys(error?.response?.data).length > 0) {
          Object.keys(error?.response?.data).map((item) => {
            notification.error({
              message: I18n.get("Ops! Algo deu errado."),
              description: error?.response?.data[item].errorMessage,
            });
          });
        }
      });
  };

  handleSetItem = (item) => {
    this.setState({ itemDate: item });
  };
  render() {
    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li className="is-active">
              <NavLink to="/bpool/faturamento" className="is-black">
                {I18n.get("Faturamento")}
              </NavLink>
            </li>
          </ul>
        </nav>

        <Row gutter={6}>
          <Col sm={24}>
            <CardSimple>
              <FormFilterProject
                filters={this.props.bp && this.props.bp.adminProjectFilters}
                action={this.handleFilters}
                task={this.state.task}
              />
            </CardSimple>
          </Col>
        </Row>
        <br />

        <Row gutter={6}>
          <Col sm={24}>
            <Button
              disabled={this.state.listPo.length == 0}
              className="button bt-bpool black pull-right"
              onClick={() => this.handlegenerate()}
            >
              {I18n.get("Gerar Planilha")}
            </Button>
          </Col>
          <Col sm={24}>&nbsp;</Col>
          <Col sm={24}>
            <CardSimple>
              {!this.props.bp.adminProjectList && (
                <NoData>
                  <h3 className="title-bpool medium">
                    {I18n.get("Escolha as informações para a busca!")}
                  </h3>

                  {!this.state.task.busy && (
                    <p className="icone">
                      <Icon type="search" />
                    </p>
                  )}

                  {this.state.task.busy && (
                    <p className="icone">
                      <Icon type="loading" />
                    </p>
                  )}
                </NoData>
              )}

              {this.props.bp.adminProjectList && (
                <div>
                  {this.state.task.busy && (
                    <NoData>
                      <p className="icone">
                        <Icon type="loading" />{" "}
                        <span className="txt">Atualizando</span>
                      </p>
                    </NoData>
                  )}
                  <TableBillings
                    data={this.props.bp.adminProjectList.projectsAndExtras}
                    totals={{}}
                    getPos={this.getPos}
                    deletePO={this.handleDeletePo}
                    handleSetItem={this.handleSetItem}
                    {...this.props}
                  />
                </div>
              )}
            </CardSimple>
          </Col>
        </Row>

        <Modal
          title={I18n.get("Alterar a data")}
          visible={this.state.itemDate}
          footer={null}
          onCancel={() => this.setState({ itemDate: null })}
        >
          <Row>
            <Col>
              <DatePicker
                format={dateFormatList}
                // disabled={this.state.disabledFields}
                style={{ width: "100%" }}
              />
            </Col>
            <Col></Col>
          </Row>
        </Modal>
      </LoggedPage>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientProjectListAdmin);
