import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import { PROJECT_API } from '../../actions/project/actions';

import ViewMacros from '../../modules/components/ViewMacros';

import TimelineChart from '../shared/timeline-chart';

import moment from 'moment';


const mapStateToProps = state => {
    return Object.assign({}, state, { ...state.project });
};

const mapDispatchToProps = dispatch => {
    return {
        get_project: data => {
            dispatch(PROJECT_API.get(data));
        },
        create_checkpoint: data => {
            dispatch(PROJECT_API.create_checkpoint(data));
        },
        delete_checkpoint: data => {
            dispatch(PROJECT_API.delete_checkpoint(data));
        },
        update_checkpoint: data => {
            dispatch(PROJECT_API.update_checkpoint(data));
        },
        update_checkpoint_status: data => {
            return dispatch(PROJECT_API.update_checkpoint_status(data));
        },
        update_checkpoint_change: data => {
            dispatch(PROJECT_API.update_checkpoint_change(data));
        },
        create_milistones: data => {
            dispatch(PROJECT_API.create_milistones(data));
        },
        delete_milistones: data => {
            dispatch(PROJECT_API.delete_milistones(data));
        },
        get_milistones: projectId => {
            dispatch(PROJECT_API.get_milistones(projectId));
        },
        update_milestones_mensal: data => {
            dispatch(PROJECT_API.update_milestones_mensal(data));
        },
        upload_milestone: data => {
            dispatch(PROJECT_API.upload_milestone(data));
        },
        delete_file_milestone: data => {
            dispatch(PROJECT_API.delete_file_milestone(data));
        },
        confirm_milestone: data => {
            dispatch(PROJECT_API.confirm_milestone(data));
        }
    };
};

class ScheduleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentDidMount() {
        this.props.get_milistones(this.props.match.params.projectId);
    }

    render() {

        const { project = {} } = this.props;

        const timelineData = project && project.milestones && project.milestones.map((item, index) => {

            const estimatedDate = moment(item.estimatedDate, "DD/MM/YYYY");

            const record = [
                item.name,
                estimatedDate.toDate(),
                estimatedDate.add('days', 1).toDate()
            ];

            return record;

        });

        return (
            <div>
                <h1 className="title title-3">{I18n.get("Macro entregas")}</h1>

                {project && !project.isBPitch && <>

                    <ViewMacros
                        project={this.props.project}
                        create={this.props.create_milistones}
                        delete={this.props.delete_milistones}
                        update={this.props.update_milestones_mensal}
                        isPartner={this.props.auth.is_partner}
                        errors={this.props.errors && this.props.errors}
                        username={this.props.auth.username}
                        deleteFile={this.props.delete_file_milestone}
                        confirmMilestone={this.props.confirm_milestone}
                        uploadMilestone={this.props.upload_milestone}
                    />

                </>}

                {project && project.isBPitch && <>

                    <br /><br />

                    <TimelineChart {...this.props} items={timelineData} />

                </>}

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScheduleForm));
