import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { Collapse, Row, Col } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useCertificates } from "../../../../../hooks/useCertificates/useCertificates.hook";
import Loading from "../../../../../components/UI/Loading";
import { Dialog } from "../../../../../components/UI/Dialog";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";

import DefaultImg from "./svgs/default-img.svg";
import GOOGLE from "./svgs/logo_google.svg";
import META from "./svgs/logo_meta.svg";
import PINTEREST from "./svgs/logo_pinterest.svg";
import TIKTOK from "./svgs/logo_tiktok.svg";
import XTWITTER from "./svgs/logo_x.svg";
import * as S from "./styles";

const { Panel } = Collapse;

export const ListCertificates = ({ data, isLoading }) => {
  const onChange = (key) => {
    return;
  };

  const renderInfo = ({ label, value }) => {
    return (
      <S.ItemCase>
        <S.ItemCaseLabel>{label}</S.ItemCaseLabel>
        <S.ItemCaseValue>{value ? value : "---"}</S.ItemCaseValue>
      </S.ItemCase>
    );
  };

  const renderOrgaoEmissor = ({
    label,
    certificationProvider,
    certificationProviderOther,
  }) => {
    if (certificationProviderOther) {
      return renderInfo({
        label,
        value: certificationProviderOther,
      });
    } else {
      if (certificationProvider === "GOOGLE") {
        return renderInfo({
          label,
          value: "Google",
        });
      } else if (certificationProvider === "META") {
        return renderInfo({
          label,
          value: "Meta",
        });
      } else if (certificationProvider === "PINTEREST") {
        return renderInfo({
          label,
          value: "Pinterest",
        });
      } else if (certificationProvider === "TIKTOK") {
        return renderInfo({
          label,
          value: "TikTok",
        });
      } else if (certificationProvider === "XTWITTER") {
        return renderInfo({
          label,
          value: "X (Twitter)",
        });
      }
    }
  };

  const renderLogoOrgaoEmissor = ({
    certificationProvider,
    certificationProviderOther,
  }) => {
    if (certificationProviderOther) {
      return null;
    } else {
      if (certificationProvider === "GOOGLE") {
        return <img alt="GOOGLE" src={GOOGLE} />;
      } else if (certificationProvider === "META") {
        return <img alt="META" src={META} />;
      } else if (certificationProvider === "PINTEREST") {
        return <img alt="PINTEREST" src={PINTEREST} />;
      } else if (certificationProvider === "TIKTOK") {
        return <img alt="TIKTOK" src={TIKTOK} />;
      } else if (certificationProvider === "XTWITTER") {
        return <img alt="XTWITTER" src={XTWITTER} />;
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Row>
          <Col xs={24}>
            <S.ContentIsLoading>
              <Loading
                text={I18n.get("Loading informations...")}
                sizeText={14}
                sizeIcon={16}
              />
            </S.ContentIsLoading>
          </Col>
        </Row>
      ) : (
        <>
          <S.Collapse onChange={onChange}>
            {data?.map((item, index) => (
              <Panel
                key={item?.id}
                header={
                  <S.HeaderAccordion>
                    <div>{`${I18n.get("Certificado")} ${index + 1} - ${
                      item?.title
                    }`}</div>
                    <div style={{ marginLeft: 8 }}>
                      {renderLogoOrgaoEmissor({
                        certificationProvider: item?.certificationProvider,
                        certificationProviderOther:
                          item?.certificationProviderOther,
                      })}
                    </div>
                  </S.HeaderAccordion>
                }
              >
                <S.WrapperItem hasImage={Boolean(item?.file?.url)}>
                  {item?.file?.url ? (
                    <S.ImgItem>
                      <img
                        alt={item?.file?.name}
                        src={
                          item?.file?.type === "application/pdf"
                            ? DefaultImg
                            : item?.file?.url
                        }
                      />
                    </S.ImgItem>
                  ) : (
                    <div />
                  )}
                  <S.ContentItem>
                    {renderInfo({
                      label: I18n.get("Título do certificado"),
                      value: item?.title,
                    })}
                    {renderOrgaoEmissor({
                      label: I18n.get("Órgão emissor"),
                      certificationProvider: item?.certificationProvider,
                      certificationProviderOther:
                        item?.certificationProviderOther,
                    })}
                    {renderInfo({
                      label: I18n.get("Data de emissão"),
                      value: item?.date,
                    })}
                    {renderInfo({
                      label: I18n.get("URL"),
                      value: item?.url,
                    })}
                  </S.ContentItem>
                </S.WrapperItem>
              </Panel>
            ))}
          </S.Collapse>
        </>
      )}
    </>
  );
};
