import axios from "axios";
import { Auth } from "aws-amplify";

import Config from "../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const api_update_nfs = (data) => {
  return Auth.currentSession().then((session) => {
    const token = session && session.idToken.jwtToken;

    var config = { headers: { Authorization: "Bearer " + token } };

    // api/BP/projects/billings/bpoolnfe
    return axios.put(
      `${Config.API_BASE_URL}/BP/projects/billings/bpoolnfe`,
      data,
      config
    );
  });
};

export default api_update_nfs;
