import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Switch } from "react-router-dom";
import { FileRepositoryList } from "../../pages/FileRepository/pages/List";

const queryClient = new QueryClient();

function FileRepository() {
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route
          exact
          path="/cliente/repositorio-de-arquivos"
          component={FileRepositoryList}
        />
        <Route
          exact
          path="/parceiro/repositorio-de-arquivos"
          component={FileRepositoryList}
        />
      </Switch>
    </QueryClientProvider>
  );
}

export default FileRepository;
