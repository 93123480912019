import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Menu, Icon } from "antd";
import { useLocation } from "react-router-dom";
import * as S from "./styles";

const { SubMenu } = Menu;

const menu = [
  {
    label: "Dashboard",
    link: "/bpool/dashboard",
    children: null,
  },
  {
    label: "Parceiros",
    link: null,
    children: [
      {
        label: "Lista",
        link: "/bpool/parceiros",
      },
      {
        label: "VIP",
        link: "/bpool/parceiros-vip",
      },
      {
        label: "Pontuação",
        link: "/bpool/partner-score",
      },
      {
        label: "Fornecedores",
        link: "/bpool/fornecedores",
      },
    ],
  },
  {
    label: "Clientes",
    link: "/bpool/clientes",
    children: null,
  },
  {
    label: "Projetos",
    link: null,
    children: [
      {
        label: "Geral",
        link: "/bpool/projetos",
      },
      {
        label: "Extras",
        link: "/bpool/extras",
      },
      {
        label: "Áudios",
        link: "/bpool/audio",
      },
      {
        label: "Milistones",
        link: "/bpool/milistone",
      },
    ],
  },
  {
    label: "Financeiro",
    link: null,
    children: [
      {
        label: "Faturamento",
        link: "/bpool/faturamento",
      },
      {
        label: "Repasse",
        link: "/bpool/repasse",
      },
      {
        label: "BPay",
        link: "/bpool/bpay",
      },
      {
        label: "BPayments",
        link: "/bpool/bpay/bpayments",
      },
    ],
  },
  {
    label: "Logins",
    link: "/bpool/logins",
    children: null,
  },
  {
    label: "Assets",
    link: null,
    children: [
      {
        label: "Parceiros",
        link: "/bpool/central-de-assets",
      },
      {
        label: "Clientes",
        link: null,
        children: [
          {
            label: "Assets",
            link: "/bpool/clientes-assets",
          },
          {
            label: "Combo",
            link: "/bpool/clientes-combo",
          },
        ],
      },
    ],
  },
  {
    label: "Relatórios",
    link: "/bpool/relatorios",
    children: null,
  },
  {
    label: "Meetings",
    link: "/bpool/meetings",
    children: null,
  },
  {
    label: "Wiki",
    link: null,
    children: [
      {
        label: "Abas",
        link: "/bpool/wikis/abas",
      },
      {
        label: "Seções",
        link: "/bpool/wikis/secoes",
      },
      {
        label: "Categorias",
        link: "/bpool/wikis/categorias",
      },
      {
        label: "Conteúdo",
        link: "/bpool/wikis/conteudo",
      },
    ],
  },
  {
    label: "Notificações",
    link: "/bpool/notifications",
    children: null,
  },
];

export const MenuAdmin = () => {
  let location = useLocation();

  const childrenMenu = (items) => {
    return (
      <Menu>
        {items?.map((item, index) =>
          item?.children ? (
            <SubMenu
              key={`${index}-${item?.label}`}
              title={I18n.get(item?.label)}
            >
              {item?.children?.map((itemChildren, index) => (
                <Menu.Item key={`${index}-${itemChildren?.label}`}>
                  <Link to={itemChildren?.link}>
                    {I18n.get(itemChildren?.label)}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={`${index}-${item?.label}`}>
              <Link to={item?.link}>{I18n.get(item?.label)}</Link>
            </Menu.Item>
          )
        )}
      </Menu>
    );
  };

  const hasActive = ({ link, childrenLinks }) => {
    if (!childrenLinks?.length) {
      return link === location?.pathname ? "active" : "";
    } else {
      return childrenLinks.some((child) =>
        hasActive({ link: child.link, childrenLinks: child.children })
      )
        ? "active"
        : "";
    }
  };

  return (
    <S.Wrapper>
      <div className="container">
        <ul>
          {menu?.map((item, index) => (
            <li
              key={`${index}-${item?.label}`}
              className={hasActive({
                link: item?.link,
                childrenLinks: item?.children,
              })}
            >
              {item?.children ? (
                <S.Dropdown overlay={() => childrenMenu(item?.children)}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span>{I18n.get(item?.label)}</span>
                    <Icon type="down" />
                  </a>
                </S.Dropdown>
              ) : (
                <Link to={item?.link}>{I18n.get(item?.label)}</Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </S.Wrapper>
  );
};
