import confirm_user from './confirm';
import update_user from './update';

export const USER_UPDATE = 'userpartner__update';

export const USERPARTNER_API = {
    confirm: confirm_user,
    update: update_user
}


