import { I18n } from "aws-amplify";

export const mockDates = [
  {
    code: 0,
    label: I18n.get("0 semanas"),
  },
  {
    code: 1,
    label: I18n.get("1 semana"),
  },
  {
    code: 2,
    label: I18n.get("2 semanas"),
  },
  {
    code: 3,
    label: I18n.get("3 semanas"),
  },
  {
    code: 4,
    label: I18n.get("4 semanas"),
  },
  {
    code: 5,
    label: I18n.get("5 semanas"),
  },
  {
    code: 6,
    label: I18n.get("6 semanas"),
  },
];

export const mockDatesEN = [
  {
    code: 0,
    label: I18n.get("0 weeks"),
  },
  {
    code: 1,
    label: I18n.get("1 week"),
  },
  {
    code: 2,
    label: I18n.get("2 weeks"),
  },
  {
    code: 3,
    label: I18n.get("3 weeks"),
  },
  {
    code: 4,
    label: I18n.get("4 weeks"),
  },
  {
    code: 5,
    label: I18n.get("5 weeks"),
  },
  {
    code: 6,
    label: I18n.get("6 weeks"),
  },
];

export const monthsDates = [
  {
    code: 0,
    label: I18n.get("0 mês"),
  },
  { code: 1, label: I18n.get("1 mês") },
  { code: 2, label: I18n.get("2 meses") },
  { code: 3, label: I18n.get("3 meses") },
  { code: 4, label: I18n.get("4 meses") },
  { code: 5, label: I18n.get("5 meses") },
  { code: 6, label: I18n.get("6 meses") },
  { code: 7, label: I18n.get("7 meses") },
  { code: 8, label: I18n.get("8 meses") },
  { code: 9, label: I18n.get("9 meses") },
  { code: 10, label: I18n.get("10 meses") },
  { code: 11, label: I18n.get("11 meses") },
  { code: 12, label: I18n.get("12 meses") },
];

export const monthsDatesEN = [
  {
    code: 0,
    label: I18n.get("0 month"),
  },
  { code: 1, label: I18n.get("1 month") },
  { code: 2, label: I18n.get("2 months") },
  { code: 3, label: I18n.get("3 months") },
  { code: 4, label: I18n.get("4 months") },
  { code: 5, label: I18n.get("5 months") },
  { code: 6, label: I18n.get("6 months") },
  { code: 7, label: I18n.get("7 months") },
  { code: 8, label: I18n.get("8 months") },
  { code: 9, label: I18n.get("9 months") },
  { code: 10, label: I18n.get("10 months") },
  { code: 11, label: I18n.get("11 months") },
  { code: 12, label: I18n.get("12 months") },
];

export const weeksDatesEN = [
  { code: 1, label: I18n.get("Up to 1 week") },
  { code: 2, label: I18n.get("2 weeks") },
  { code: 3, label: I18n.get("3 weeks") },
  { code: 4, label: I18n.get("4 weeks") },
  { code: 5, label: I18n.get("5 weeks") },
  { code: 6, label: I18n.get("6 weeks") },
];

export const weeksDatesPT = [
  { code: 1, label: I18n.get("Até 1 semana") },
  { code: 2, label: I18n.get("2 semanas") },
  { code: 3, label: I18n.get("3 semanas") },
  { code: 4, label: I18n.get("4 semanas") },
  { code: 5, label: I18n.get("5 semanas") },
  { code: 6, label: I18n.get("6 semanas") },
];

export const mockNonCompete = [
  {
    code: -1,
    label: I18n.get("Nenhum"),
  },
  {
    code: 0,
    label: I18n.get("Durante o projeto"),
  },
  {
    code: 30,
    label: I18n.get("Durante o projeto + 30 dias"),
  },
  {
    code: 60,
    label: I18n.get("Durante o projeto + 60 dias"),
  },
  {
    code: 90,
    label: I18n.get("Durante o projeto + 90 dias"),
  },
];

export const mockNonCompeteEN = [
  {
    code: -1,
    label: I18n.get("None"),
  },
  {
    code: 0,
    label: I18n.get("During the project"),
  },
  {
    code: 30,
    label: I18n.get("During the project + 30 days"),
  },
  {
    code: 60,
    label: I18n.get("During the project + 60 days"),
  },
  {
    code: 90,
    label: I18n.get("During the project + 90 days"),
  },
];
