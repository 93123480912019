import { api_get_projects2 } from "./get";
import { api_get_project } from "./get_project";
import { api_get_project_step2 } from "./get_project_step2";
import { api_get_projects_filters } from "./get_projects_filters";
import { api_get_project_step3 } from "./get_project_step3";
import { api_get_project_step5 } from "./get_project_step5";
import { api_get_project_step6 } from "./get_project_step6";
import { get_types } from "./get_types";
import { get_tmarket } from "./get_tmarket";
import { get_assets_types } from "./get_assets_types";
import { get_assets } from "./get_assets";
import { get_asset_bundle } from "./get_assets_bundle";
import { get_assets_combo } from "./get_assets_combo";
import { get_assets_custom } from "./get_assets_custom";
import { api_create_project } from "./create_project";
import { api_update_project } from "./update_project";
import { api_add_asset } from "./add_asset";
import { api_add_project_bundle } from "./add_project_bundle";
import { api_update_demand_file } from "./update_demand_file";
import { api_update_demand } from "./update_demand";
import { api_delete_demand_file } from "./delete_demand_file";
import { api_update_project_step5 } from "./update_project_step5";
// import { api_update_project_step6 } from "./update_project_step6";

export const PROJECTS_API = {
  get_projects: api_get_projects2,
  get_project: api_get_project,
  get_filters: api_get_projects_filters,
  get_types: get_types,
  create_project: api_create_project,
  update_project: api_update_project,
  get_project_step2: api_get_project_step2,
  get_project_step3: api_get_project_step3,
  get_project_step5: api_get_project_step5,
  get_project_step6: api_get_project_step6,
  get_assets_types: get_assets_types,
  add_bundle: api_add_project_bundle,
  add_asset: api_add_asset,
  get_assets_combo: get_assets_combo,
  get_assets: get_assets,
  get_assets_custom: get_assets_custom,
  demand_file: api_update_demand_file,
  delete_demand_file: api_delete_demand_file,
  update_demand: api_update_demand,
  update_step5: api_update_project_step5,
  get_tmarket: get_tmarket,
  get_asset_bundle: get_asset_bundle,
  // update_step6: api_update_project_step6,
};
