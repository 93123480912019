import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import { PROJECT_API } from "../../actions/project/actions";

import { ClientProjectStatus } from "../../model";

import ReviewStep1 from "../_project-review-1";
import AssetBundle from "../_project-asset-bundle";
import ReviewStep4 from "../_project-review-4";
import ReviewStep5 from "../_project-review-5";
import ReviewStep5Client from "../_project-review-5-client";

import Billing from "./_billing";

import Timeline from "./_timeline";

import DownloadButton from "../form/download-button-field";

const mapStateToProps = (state) => {
  return Object.assign({}, state, { ...state.project });
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_project: (data) => {
      dispatch(PROJECT_API.get(data));
    },
    save: (data) => {
      dispatch(PROJECT_API.update_definitions(data));
    },
  };
};

class ProjectDetailsSimple extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {},
      task: {},
    };
  }

  componentDidMount() {
    if (this.props.match.params.projectId) {
      var data = {
        projectId: this.props.match.params.projectId,
      };

      var task = new Task();

      task.start();

      this.setState({ task: task }, function () {
        this.props.get_project(data);
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value = this.props[item] || "";
        } else if (typeof nextState.form !== "undefined") {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].value = value;

      return nextState;
    });
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (
        typeof this.state.form[item] === "object" &&
        "value" in this.state.form[item]
      ) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  getSelected() {
    return {
      bundleId: this.props.bundleId,
      variationId: this.props.variationId,
    };
  }

  render() {
    return (
      <div className="project-ongoing-review container-content">
        {/* {this.props.project.status == ClientProjectStatus.Started && <div className="is-pulled-right">
                        <NavLink to={`/projetos/${this.props.match.params.projectId}/finalizar`} className="button is-black is-outlined" >Finalizar Projeto</NavLink>
                    </div>} */}

        {/* {this.props.project.status == ClientProjectStatus.Finished && <div className="is-pulled-right">
                        <NavLink to={`/projetos/${this.props.match.params.projectId}/avaliacao`} className="button is-success is-outlined" >Projeto Finalizado</NavLink>
                    </div>} */}

        {this.props.project.status == ClientProjectStatus.Canceled && (
          <div className="is-pulled-right">
            <span class="tag is-warning is-medium">
              {I18n.get("Projeto Cancelado")}
            </span>
          </div>
        )}

        {this.props.isTitle && (
          <h1 className="title title-1">{I18n.get("Resumo")}</h1>
        )}

        {this.props.project.status == ClientProjectStatus.Started &&
          this.props.auth.is_client_or_client_user && (
            <div className="is-pulled-right btn-zindex btn-full-w">
              {/* <NavLink to={`/projetos/${this.props.match.params.projectId}/finalizar`} className="button is-black is-outlined">Finalizar Projeto</NavLink> */}

              {/* <NavLink to={`/projetos/${this.props.match.params.projectId}/cancelar`} className="button is-danger is-outlined" style={{ marginLeft: '2rem' }}>Cancelar Projeto</NavLink> */}
            </div>
          )}

        <br />
        <br />

        <div className="cleaboth"></div>

        <p>{I18n.get("Confira abaixo os detalhes do escopo do projeto.")}</p>
        <br />
        <br />

        <h1 className="title-bpool medium">{I18n.get("Informações")}</h1>

        <ReviewStep1 {...this.props} />

        <br />
        <br />

        <h1 className="title-bpool medium">
          <span>2.</span>
          {I18n.get("Escopo Criativo")}
        </h1>

        <div className="listBundle">
          <div className="reviewed-media uplift-review AssetBundle">
            <AssetBundle
              {...this.props.bundle}
              {...this.props}
              bp={this.props.bp}
              selected={this.getSelected()}
              isExpanded={true}
              IsOnGoing={true}
              IsClient={this.props.auth.is_client_or_client_user}
              IsPartner={this.props.auth.is_partner}
              PricePartner={this.props.pricePartnerTax}
              PriceClientWithDiscount={this.props.priceClientWithDiscountTax}
              PriceBPool={this.props.priceBPoolTax}
              currency={this.props.currency}
              assetTax={this.props.assetTax || 0}
              otherTax={this.props.otherTax || 0}
              isFull={true}
              projectStatus={this.props.status}
              projectAssets={this.props.project?.bundle?.variations}
            />
          </div>
        </div>

        <br />
        <br />

        <h1 className="title-bpool medium">
          <span>3.</span>
          {I18n.get("Detalhes")}
        </h1>

        <div className="uplift">
          <ReviewStep4 {...this.props} />
        </div>

        <br />
        <br />

        {/* {this.props.auth.is_client_or_client_user && <>
                    <h1 className="title-bpool medium">
                        <span>4.</span> Sobre o Parceiro
                    </h1>

                    <ReviewStep5 {...this.props} />
                </>}

                {!this.props.auth.is_client_or_client_user && <>
                    <h1 className="title-bpool medium">
                        <span>4.</span> Sobre o Cliente
                    </h1>

                    <ReviewStep5Client {...this.props} />
                </>}

                <br />
                <br />

                <h1 className="title-bpool medium">
                    <span>5.</span> Cronograma
                </h1>

                <div className="card project-ongoing-review-card">
                    <div className="card-content">
                        <div className="content">

                            <Timeline checkpoints={this.props.checkpoints} />
                        </div>
                    </div>
                </div>

                <br />
                <br />

                <h1 className="title-bpool medium">
                    <span>6.</span> Observações importantes do Parceiro Criativo.
                </h1>

                <div className={`card ${this.props.partnerNotes ? '' : 'no-content'}`}>
                    <div className="card-content">
                        <div className="content">
                            <pre style={{ backgroundColor: '#ffffff', padding: '0px' }}>{this.props.partnerNotes}</pre>
                        </div>
                    </div>
                </div>


                <br />
                <br />

                <h1 className="title-bpool medium">
                    <span>7.</span> Observações importantes do Cliente.
                </h1>

                <div className={`card ${this.props.clientNotes ? '' : 'no-content'}`}>
                    <div className="card-content">
                        <div className="content">
                            <pre style={{ backgroundColor: '#ffffff', padding: '0px' }}>{this.props.clientNotes}</pre>
                        </div>
                    </div>
                </div>

                <br />
                <br />

                <h1 className="title-bpool medium">
                    <span>8.</span> PO
                </h1>

                <Billing {...this.props} /> */}

        {/* <div className="card">
                    <div className="card-content">
                        <div className="content">

                            <strong>Número PO:</strong> &nbsp; {this.props.billing && this.props.billing.projectPO}
                    </div>
                </div>
                </div> */}

        <br />
        <br />

        {this.props.pdfProject && this.props.pdfProject.length > 0 && (
          <DownloadButton LinkToDownload={this.props.pdfProject} />
        )}

        <br />
        <br />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailsSimple);
