import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Task } from '../../utils/task';

import { PROJECT_API } from '../../actions/project/actions';
import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import { CardSimple } from '../../modules/components/Cards';
import FormFilterExtra from '../../modules/Admin/components/filterExtraAdmin';
import TableExtras from '../../modules/Admin/components/tableExtrasAdmin';

import styled from 'styled-components';

import ClientProjectList from './client-project/_list';

import {
    Row,
    Col,
    notification,
    Icon
} from 'antd';

const NoData = styled.div`
    padding: 50px;
    text-align: center;

    h3 {
        font-weight: 700;
        margin-bottom: 30px;
    }

    i {
        font-size: 40px;
    }

    .txt {
        position: relative;
        left: 30px;
        top: -15px;
    }
`;


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_projects: data => {
            dispatch(BP_API.get_projects(data));
        },
        get_extras_list: data => {
            dispatch(BP_API.get_extras_list(data));
        },
        get_filters_extras: () => {
            dispatch(BP_API.get_filters_extras());
        },
        delete_extra: data => {
            return dispatch(BP_API.delete_extra(data));
        },
        cancel_extra: data => {
            return dispatch(BP_API.cancel_extra(data));
        },
        reset: () => {
            dispatch(PROJECT_API.reset());
        },
    };
};

class ClientExtraListAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
            historySearch: {},
            task: {}
        };
    }

    handleFilters = (data) => {
        var task = new Task();

        task.start();

        this.setState({ task: task, historySearch: data }, function () {
            this.props.get_extras_list(data);
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {
            this.setState({ task: task });
        }

    }

    cancel = (data) => {
        this.props.cancel_extra(data).then(response => {
            var task = new Task();

            task.start();

            notification.open({
                message: I18n.get("Extra cancelado com sucesso!"),
                description: '',
                icon: <Icon type="check" style={{ color: '#000' }} />,
            });


            this.setState({ task: task }, function () {
                this.props.get_extras_list(this.state.historySearch);
            });

        })
            .catch(error => {
                notification.open({
                    message: I18n.get("Ops! Algo deu errado."),
                    description: I18n.get("Tente novamente mais tarde."),
                    icon: <Icon type="check" style={{ color: '#000' }} />,
                });
            });
    }

    delete = (data) => {
        this.props.delete_extra(data).then(response => {
            var task = new Task();

            task.start();

            this.setState({ task: task }, function () {
                this.props.get_extras_list(this.state.historySearch);
            });

        });
    }

    componentDidMount() {
        // this.props.reset();
        this.props.get_filters_extras();
    }

    render() {
        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/extras" className="is-black">{I18n.get("Extras")}</NavLink></li>
                    </ul>
                </nav>

                <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            <FormFilterExtra
                                filters={this.props.bp && this.props.bp.adminExtraFilters}
                                action={this.handleFilters}
                                task={this.state.task}
                            />
                        </CardSimple>
                    </Col>
                </Row>
                <br />

                <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            {!this.props.bp.adminExtraList &&
                                <NoData>
                                    <h3 className="title-bpool medium">{I18n.get("Escolha as informações para a busca!")}</h3>

                                    {!this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="search" />
                                        </p>
                                    }

                                    {this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="loading" />
                                        </p>
                                    }
                                </NoData>

                            }

                            {this.props.bp.adminExtraList &&
                                <div>
                                    {this.state.task.busy &&
                                        <NoData>
                                            <p className="icone">
                                                <Icon type="loading" /> <span className="txt">Atualizando</span>
                                            </p>
                                        </NoData>
                                    }
                                    <TableExtras
                                        data={this.props.bp.adminExtraList}
                                        delete={this.delete}
                                        cancel={this.cancel}
                                        {...this.props}
                                    />
                                </div>
                            }
                        </CardSimple>
                    </Col>
                </Row>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientExtraListAdmin);
