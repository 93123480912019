import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { PARTNER_API } from '../../actions/partner/actions';

import FillForm from '../page-templates/fillForm';
import WallPage from '../pages/wall';

import VideoForm from './video-form';

import Steps from './steps';

import { PartnerStatus } from '../../model';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_by_token: id => {
            dispatch(PARTNER_API.get_by_token(id));
        },
    };
};

class PartnerSignupVideo extends React.Component {
    constructor(props) {

        super();
        this.state = {
            verificationToken: props.match.params.verificationToken
        };
    }

    componentWillMount() {
        this.props.get_by_token(this.state.verificationToken);
    }

    onSubmitForm(e) {

        e.preventDefault();

        const form = this.refs.form;

        form.submitForm(e);
    }

    render() {



        if (this.props.partner && this.props.partner.status != PartnerStatus.PreApproved) {
            return <WallPage title={I18n.get("Obrigado.")} />;
        }

        return (
            <FillForm>
                <div className="partner-step partner-step-6 step-video">

                    <div className="container video-container">

                        <div className="column is-8 is-offset-2">

                            <h1 className="title">{I18n.get("Video")}</h1>

                            <p>{I18n.get("Olá,")}<span>{this.props.partner.name}</span>!</p>

                            <p>{I18n.get("Prezado candidato, você foi selecionado para a fase final de avaliação. Nosso processo de curadoria visa selecionar somente os mais capacitados para fazer parte do mais novo hub criativo.")}</p>
                            <p>{I18n.get("Para esta última fase, pedimos que você grave um vídeo (pode ser do seu celular, mesmo em baixa qualidade) de aproximadamente 10 min. Esse vídeo é de uso interno e será visto apenas pelo nosso comitê de avaliação.")}</p>
                            <p>{I18n.get("Nele pedimos que fale um pouco sobre você, sua agência e sócios, e que também nos conte como contornaria as situações a seguir:")}</p>
                            <ol>
                                <li>{I18n.get("1. Como você resolveria tal e tal problema?")}</li>
                                <li>{I18n.get("2. Como você resolveria tal e tal problema?")}</li>
                                <li>{I18n.get("3. Como você resolveria tal e tal problema?")}</li>
                                <li>{I18n.get("4. Como você resolveria tal e tal problema?")}</li>
                                <li>{I18n.get("5. Como você resolveria tal e tal problema?")}</li>
                            </ol>
                            <p>{I18n.get("Depois de ter feito o vídeo, pedimos que faça o upload dele para algum serviço de armazenamento na nuvem como Dropbox, Google Drive e OneDrive, ou uma plataforma de vídeos como YouTube ou Vimeo. Independente do serviço utilizado, é importante que você proteja o mesmo com senha ou publique de forma privada. Nos campos a seguir, informe o link do vídeo e nas observações, deixe informações sobre devemos acessar esse conteúdo (como usuário e senha, se for o caso).")}</p>

                        </div>

                        <div className="column is-8 is-offset-2">

                            <h2 className="title">{I18n.get("Infos do Vídeo")}</h2>

                            <VideoForm ref="form" {...this.props} />

                            {Object.keys(this.props.partner.errors).length > 0 &&
                                <p>
                                    <ul>
                                    {Object.keys(this.props.partner.errors).map((item) =>
                                        <li>
                                            <span class="icon has-text-danger">
                                                <i class="fas fa-exclamation"></i>
                                            </span>
                                            {this.props.partner.errors[item].errorMessage}
                                        </li>
                                    )}
                                    </ul>
                                </p>
                            }

                            <div className="field is-grouped is-grouped-centered">
                                <p className="control is-expanded">
                                    <button type="submit" onClick={this.onSubmitForm.bind(this)} className="button is-black">{I18n.get("ENVIAR")}</button>
                                </p>
                            </div>

                        </div>
                    </div>
            </div>


         </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSignupVideo);
