import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { PARTNER_API } from '../../../actions/partner/actions';
import { PROJECT_API } from '../../../actions/project/actions';
import styled from 'styled-components';
import LoggedPage from '../../page-templates/fillForm-logged';
import { Table, Icon } from 'antd';
import { formatMoney2 } from '../../../utils/helpers';
import NumberFormat from 'react-number-format';

import { scrollToTop } from '../../../utils/ui';

import FormatCurrency from '../../ui/format-currency';

const Bts = styled.p`
    width: 100px;
    a {
        margin-right: 15px;
        margin-bottom:10px;
    }
`
const Main = styled.div`
    background: #fff;
    padding-top: 130px;
    height: 100vh;
`
const mapStateToProps = state => {
    return Object.assign({}, state.partner, state);
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_agreements: () => {
            dispatch(PARTNER_API.get_agreements());
        }
    };
};


class TepProjectListAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {
        this.props.get_agreements();
        //this.props.reset();
        scrollToTop();
    }

    //Client Project List
    render() {

        const dataTermos = [
            {
                name: I18n.get("Termos de uso & Política de ética e práticas anticorrupção"),
                pdf: this.props.partner && this.props.partner.partnerTerm
            }
        ]

        const columnsTermos = [

            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: I18n.get("PDF"),
                dataIndex: 'pdf',
                key: 'pdf',
                render: pdf => {

                    return (
                        <Bts>
                            <a href={pdf} target="_blank"><Icon type="file-pdf" /> {I18n.get("Baixar")}</a>
                        </Bts>
                    );
                }
            },
        ]


        const data = this.props.teps && this.props.teps.map((item) => {
            return {
                type: item.projectTypeName,
                brand: item.brandName,
                product: item.productName,
                name: item.projectName,
                money: item.pricePartner,
                currency: item.projectCurrency,
                files: item
            }
        });

        const columns = [
            {
                title: I18n.get("Tipo"),
                dataIndex: 'type',
                key: 'type',
                render: text => {
                    switch (text) {
                        case "Creative":
                            return I18n.get("Criativo")
                            break;
                        case "Event":
                            return I18n.get("Evento")
                            break;

                        default:
                            break;
                    }
                },
            },
            {
                title: I18n.get("Marca"),
                dataIndex: 'brand',
                key: 'brand',
            },
            {
                title: I18n.get("Produto"),
                dataIndex: 'product',
                key: 'product',
            },
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: I18n.get("Valor"),
                key: 'action',
                render: (text, record) => (
                    <FormatCurrency amount={record.money} currency={record.currency} />
                )
            },
            {
                title: I18n.get("PDF"),
                dataIndex: 'files',
                key: 'files',
                render: (record) => {

                    return (
                        <Bts>
                            {record.pdfProject &&
                                <a href={record.pdfProject} target="_blank"><Icon type="file-pdf" /> {I18n.get("Projeto")}</a>
                            }
                            {record.pdfTep &&
                                <a href={record.pdfTep} target="_blank"><Icon type="file-pdf" /> {I18n.get("Tep")}</a>
                            }
                            {record.pdfAgreement &&
                                <a href={record.pdfAgreement} target="_blank"><Icon type="file-pdf" /> {I18n.get("Aceite")}</a>
                            }                            
                        </Bts>
                    );
                }
            },

        ];

        return (
            <LoggedPage>
                <Main className="uplift">

                    <div className="container">

                        <br />
                        <h1 className="title-bpool medium"><span>{I18n.get("Termos")}</span></h1>
                        <Table columns={columnsTermos} dataSource={dataTermos} pagination={false} />

                        <br />
                        <br />
                        <h1 className="title-bpool medium">{I18n.get("Projetos")}</h1>

                        <Table scroll={{ x: 640 }} columns={columns} dataSource={data} />

                    </div>

                </Main>
            </LoggedPage>
        );


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TepProjectListAdmin);
