import styled from 'styled-components';

export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const InputLabel = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 8px;
  display: flex;

  span {
    color: #ff0000;
    font-size: 22px;
  }
`;

export const TitleSectionForm = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
  width: max-content;
`;

export const LabelItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  width: 100%;

  span {
    font-size: 16px;
    font-weight: 700;
    margin-right: 20px;
  }
`;

export const LabelItemSelf = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  justify-content: center;

  height: 60px;
  width: 100%;

  span {
    font-size: 12px;
    font-weight: 700;
  }
`;

export const Checkboxs = styled.div`
  display: flex;

  span .ant-checkbox-inner {
    border-radius: 4px;
  }
`;

export const CheckboxItem = styled.div`
  width: 50%;
  text-align: center;
`;
