import React from 'react';

import { I18n } from 'aws-amplify';

import moment from 'moment';

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';

import RadioMultiField from '../../form/radio-multi-field';
import MoneyField from '../../form/money-field';
import SubmitButtonField from '../../form/submit-button-field';
import CheckboxField from '../../form/checkbox-field';

class QuotationItemStatusForm extends React.Component {
    constructor(props) {

        super(props);

        const item = props.item || {};



        this.state = {
            fields: {
                status: {
                    onChange: this.onChangeForm.bind(this, 'status'),
                    successIcon: false,
                    visited: true,
                    values: [{ code: 'true', label: I18n.get("Item em conformidade") }, { code: 'false', label: I18n.get("Item fora de conformidade") }],
                },
                suggestedPrice: {
                    label: I18n.get("Preço Unitário Sugerido"),
                    onChange: this.onChangeForm.bind(this, 'suggestedPrice'),
                    error: props.errors.price,
                    successIcon: false,
                    visited: true,
                }
            },
            form: {
                status: item.status,
                suggestedPrice: item.suggestedPrice
            },
            errors: {},
            submit_task: {},
        };
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            quotationId: this.props.quotation.id,
            ...this.props.item,
            ...this.state.form
        };

        return data;
    }

    submitForm(e) {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_quotation_item_status(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

                if (task.result) {

                    this.setState(prevState => ({
                        form: { ...prevState.form, new_comment: '' },
                    }));

                }
            });
    }

    render() {


        return (
            <div>

                <a onClick={this.props.onClose.bind(this)} className="is-pulled-right">
                    <span className="icon"><i className="fas fa-times" aria-hidden="true"></i></span>
                </a>

                <p className="has-text-weight-bold">{I18n.get("Revisão BPool")}</p>

                <br />

                <form onSubmit={this.submitForm.bind(this)}>

                    <RadioMultiField
                        {...this.state.fields.status}
                        value={this.state.form.status}
                        error={this.state.errors.status}
                        disabled={!this.props.auth.is_master_or_bpool}
                    />

                    <br />

                    <MoneyField
                        {...this.state.fields.suggestedPrice}
                        value={this.state.form.suggestedPrice}
                        error={this.state.errors.suggestedPrice}
                        disabled={!this.props.auth.is_master_or_bpool}
                        />

                    {this.props.auth.is_master_or_bpool && <SubmitButtonField
                        label={I18n.get("Salvar")}
                        className="button is-black is-rounded is-pulled-right"
                        onClick={this.submitForm.bind(this)}
                        task={this.state.submit_task}
                    />}

                    <br /><br />

                </form>

            </div>
        );
    }
}

export default QuotationItemStatusForm;
