import React from 'react';

import { I18n } from 'aws-amplify';

import constants from '../../constants';

import Responsbible from './_leader-details-responsible';
import MarketExpertises from './_common-marketExpertises';
import Cases from './_leader-cases';
import Awards from './_common-awards';


export default class LeaderDetailsView extends React.Component {

    constructor(props) {
        super();
    }

    componentDidMount() {
    }

    render() {
        
        const item = this.props.item;
        const sf = this.props.sf;

        if (!item) return null;

        return (
            <section>

                <h3 className="title is-3">{I18n.get("Detalhes do líder")}</h3>

                <div className="columns">

                    <div className="column is-12">

                        <Responsbible item={item} />

                    </div>

                </div>

                <div className="columns">

                    <div className="column is-12">

                        <MarketExpertises items={item.MarketExpertises} />

                    </div>

                </div>

                <div className="columns">

                    <div className="column is-12">

                        <Cases items={item.cases} sf={sf}/>

                    </div>

                </div>

                <div className="columns">

                    <div className="column is-12">

                        <Awards items={item.awards} />

                    </div>

                </div>

            </section>
        );
    }
}