import React from 'react';

import { I18n } from 'aws-amplify';

import CasesForm from '../cases-form';
import CasesList from '../cases-list';
import CasesCheck from './cases-check';

export default class CasesCmp extends React.Component {

    constructor(props) {
        super();

        this.state = {
            editing: false
        }
    }

    onEditingChange(value, e) {
        this.setState({
            editing: value
        });
    }

    render() {

        return (
            <section>
                <h1 className="title-bpool medium">
                    {this.props.title}
                </h1>

                {!this.state.editing && this.props.type == 1 && <CasesCheck {...this.props} type={this.props.type} onEditingChange={this.onEditingChange.bind(this)} />}

                {!this.state.editing && this.props.type == 2 && <CasesList {...this.props} type={this.props.type} onEditingChange={this.onEditingChange.bind(this)} />}

                {!this.state.editing && this.props.type == 2 && <div className="field has-text-right">
                    <button
                        type="button"
                        style={{ marginTop: 15, fontSize: 12, textTransform: "uppercase" }}
                        onClick={this.onEditingChange.bind(this, true)}
                        className="button is-leaked">
                        <i style={{ display: "inline-block", marginRight: 15 }} className="fa fa-plus"></i>{I18n.get("Adicionar case")}</button>
                </div>
                }
                {!!this.state.editing && <CasesForm {...this.props} type={this.props.type} onEditingChange={this.onEditingChange.bind(this)} item={this.state.editing} />}

            </section>
        );
    }
}
