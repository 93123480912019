import styled from "styled-components";
import { Link } from "react-router-dom";

import { Steps } from "antd";

const { Step } = Steps;

const COLOR_ACTIVE = "#1883FF";
const COLOR_INACTIVE = "#cccccc";
const COLOR_BLACK = "#000000";
const COLOR_WHITE = "#FFFFFF";

const handleDisabled = (disabled) => {
  if (disabled) {
    return `
    background: ${COLOR_INACTIVE};
    `;
  }
};

export const Wrapper = styled.div`
  max-width: 630px;
  margin: 50px auto 0;
`;

export const StyleSteps = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  &::before {
    width: 90%;
    height: 2px;
    background: ${COLOR_BLACK};
    position: absolute;
    top: 9px;
    content: "";
    display: block;
    left: 50%;
    margin-left: -45%;
  }
`;

export const StyleStep = styled(Link)`
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &[disabled] {
    pointer-events: painted;
    cursor: not-allowed;
  }
`;

export const StyleNumber = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.active ? "18px" : "12px")};
  line-height: 16px;
  display: block;
  margin: 0 auto;
  color: ${COLOR_WHITE};
  width: ${(props) => (props.active ? "40px" : "19px")};
  height: ${(props) => (props.active ? "40px" : "19px")};
  border-radius: 50%;
  background: ${(props) => (props.active ? COLOR_ACTIVE : COLOR_BLACK)};
  ${({ disabled }) => handleDisabled(disabled)};
  text-align: center;
  box-sizing: border-box;
  padding-top: ${(props) => (props.active ? "14px" : "2px")};
  position: relative;
  top: ${(props) => (props.active ? "-10px" : "0")};
`;

export const StyleLabel = styled.span`
  font-style: normal;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  color: ${COLOR_BLACK};
`;

export const ButtonFinishProject = styled.div`
  background: transparent;
  max-width: fit-content;
  position: absolute;
  top: 60px;
  right: 0;
  margin-right: 20px;
`;
