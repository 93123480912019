import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import {
    Table,
    Checkbox,
    Icon
} from 'antd';

import ItemAward from './ItemAward';

const Btns = styled.p`
width: 50px;
display: block;

&.checkItem {
    width: 100px;
}
    a {
        margin-left: 10px;
    }
`


class tableAwards extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    isChecked(awardId) {

        if (!this.props.dataLeader) return false;

        const items = this.props.dataLeader.filter((item) => item.type == 1 && item.id == awardId);

        return items && items.length;
    }

    getSector = (sectorId) => {
        const items = this.props.sectorsFlat && this.props.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }

    render() {
        const columns = [
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: I18n.get("Marca"),
                dataIndex: 'marca',
                key: 'marca'
            },
            {
                title: I18n.get("Produto"),
                dataIndex: 'produto',
                key: 'produto',
            },
            {
                title: I18n.get("Categoria"),
                dataIndex: 'categoria',
                key: 'categoria',
                render: (categoria) => this.getSector(categoria),
            },
            {
                title: I18n.get("Ano"),
                dataIndex: 'ano',
                key: 'ano',
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'item',
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                    }


                    if (this.props.isLeaderAction) {
                        data.leaderId = this.props.partnerId;
                    } else {
                        data.partnerId = this.props.partnerId;
                    }


                    return (
                        <div>
                            {!this.props.isLeader &&
                                <Btns>
                                    <a onClick={() => this.props.edit(record.item)}>
                                        <Icon type="edit" theme="filled" />
                                    </a>
                                    <a onClick={() => this.props.delete(data)}>
                                        <Icon type="delete" theme="filled" />
                                    </a>
                                </Btns>
                            }
                            {this.props.isLeader &&
                                <Btns className="checkItem">
                                    <Checkbox checked={this.isChecked(record.item.id)} onChange={(e) => this.props.clone(e, record.item, this.props.partnerId)}>{I18n.get("SIM")}</Checkbox>
                                </Btns>
                            }
                        </div>
                    );
                }
                // render: (record) => this.props.delMarketExpertise(record.item.id),
            },
        ]

        let dataCases = this.props.data && this.props.data.map((item, index) => {
            return {
                key: index,
                name: item.name,
                marca: item.brand,
                produto: item.product,
                categoria: item.category,
                ano: item.year,
                item: item
            }
        });

        return (
            <Table
                columns={columns}
                dataSource={dataCases}
                scroll={{ x: 640 }}
                locale={{ emptyText: I18n.get('Sem informação') }}
                expandedRowRender={(record) => <ItemAward item={record.item} category={this.getSector(record.item.category)} />}
            />
        );
    }
}

export default tableAwards;
