export const parseQueryString = (queryString) => {
  const params = new URLSearchParams(queryString);
  const obj = {};

  for (const [key, value] of params.entries()) {
    // Aqui estamos removendo as aspas simples, se houver, e decodificando os valores URI.
    // Se você não estiver usando aspas em torno dos valores, pode remover a lógica de replace.
    obj[key] = decodeURIComponent(value.replace(/'/g, ""));
  }

  return obj;
};

export const createQueryString = (params) => {
  const queryParts = Object.entries(params).map(([key, value]) => {
    // Aqui estou assumindo que você quer aspas simples em torno dos valores string.
    // Remova as aspas se não for necessário.
    // const formattedValue = typeof value === "string" ? `'${value}'` : value;
    const formattedValue = value;
    return `${encodeURIComponent(key)}=${encodeURIComponent(formattedValue)}`;
  });

  return `?${queryParts.join("&")}`;
};
