import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useSupplier } from "../../../../../../../hooks/useSupplier/useSupplier.hook";
import { Drawer } from "../../../../../../../components/UI/Drawer";
import { NotificationBPool } from "../../../../../../../components/UI/NotificationBPool";
import { InfosDrawer } from "./InfosDrawer";
import * as S from "../styles";

export const DrawerStatusSteps = ({ drawer, handleCloseDrawer, isPartner }) => {
  const { getInfosStatusSteps } = useSupplier();
  const [isLoading, setIsLoading] = useState(false);
  const [statusInvoice, setStatusInvoice] = useState({});

  const handleOpenDrawer = async () => {
    try {
      setIsLoading(true);
      const response = await getInfosStatusSteps(
        {
          bPaymentId: drawer?.data?.id,
          bPayId: drawer?.data?.invoice?.bPayId,
        },
        isPartner
      );
      setStatusInvoice(response?.data);
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao buscar dados"),
        duration: 3,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (drawer?.visible === true) {
      handleOpenDrawer();
    }
  }, [drawer?.visible]);

  return (
    <>
      <Drawer
        open={drawer.visible}
        handleClose={handleCloseDrawer}
        width={525}
        buttons={<></>}
      >
        <InfosDrawer
          data={{ ...drawer?.data, status: statusInvoice }}
          isLoading={isLoading}
        />
      </Drawer>
    </>
  );
};
