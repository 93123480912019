import React, { PureComponent } from "react";
import { I18n } from "aws-amplify";

import { Table, Button, Modal, notification } from "antd";

import { usePartners } from '../../../v2/hooks/Admin/usePartners/usePartners.hook';

const { confirm } = Modal;


class TableVips extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingDelete: false,
    };
  }

  deleteVip = async (id) => {  
    this.setState({ loadingDelete: true }); 
    const { deleteVip } = usePartners();
    const data = {
      partnerId: id,
    };

    const resp = await deleteVip(data);

    if(resp.success){
      this.props.get(); 
      this.setState({ loadingDelete: false }); 
      notification["success"]({
        message: I18n.get("Convite deletado com sucesso!"),
        description: "",
      });
    } else {
      this.setState({ loadingDelete: false }); 
      notification["error"]({
        message:I18n.get("Ops! Algo deu errado."),
        description: "",
      });
    }


  };

  getSector = (sectorId) => {
    const items =
      this.props.sectorsFlat &&
      this.props.sectorsFlat.filter((item) => item.code == sectorId);

    if (items && items.length) {
      return items[0].label;
    } else {
      return {};
    }
  };

  showConfirmReset(username) {
    confirm({
      title: I18n.get("Deseja resetar a senha desse user?"),
      content: "Senha padrão: Aaaa1234!",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => this.handleResetPassWord(username),
      onCancel: () => console.log("Não resetou"),
    });
  }

  handleResetPassWord = (username) => {
    const data = {
      username: username,
    };

    this.props.reset_user(data);
  };

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.partnerStatusesMap) return "";

    return this.props.bp.partnerStatusesMap[value].label;
  }

  render() {
    

    const columns = [
      {
        title: I18n.get("Idioma"),
        dataIndex: "signupLocale",
        key: "signupLocale",
      },
      {
        title: I18n.get("Empresa"),
        dataIndex: "companyName",
        key: "companyName",
      },
      {
        title: I18n.get("Responsável"),
        dataIndex: "responsableName",
        key: "responsableName",
      },
      {
        title: I18n.get("E-mail"),
        dataIndex: "responsableEmail",
        key: "responsableName",
      },
      {
        title: "",
        dataIndex: "inviteUrl",
        width: 270,
        key: "inviteUrl",
        render: (inviteUrl, record) => {
          const data = {
            username: record.username,
          };
          return (
            <>
              <Button
                className="button bt-bpool black"
                onClick={() => this.props.resend(data)}
                loading={this.props.loadingBtn}
              >
                {I18n.get("Reenviar email")}
              </Button>
              {"  "}

              <Button
                onClick={() => this.deleteVip(record?.id)}
                type="dashed"
                icon="delete"
                size="small"
                loading={this.state.loadingDelete}
              />
              {"  "}

              <Button
                onClick={() => this.showConfirmReset(record?.username)}
                type="dashed"
                icon="reload"
                size="small"
              />
            </>
          );
        },
      },
    ];

    let dataTable =
      this.props.data &&
      this.props.data.map((item, index) => {
        return {
          key: index,
          signupLocale: item.signupLocale,
          companyName: item.companyName,
          responsableName: item.responsableName,
          responsableEmail: item.responsableEmail,
          inviteUrl: item.inviteUrl,
          username: item?.userName,
          id: item?.id,
        };
      });

    return (
      <Table
        pagination={this.props.pagination ? true : false}
        columns={columns}
        dataSource={dataTable}
        locale={{ emptyText: I18n.get("Sem informação") }}
        scroll={{ x: 1200 }}
      />
    );
  }
}

export default TableVips;
