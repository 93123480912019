import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { CHAT_API } from '../../actions/chat/actions';
import { NOTIFICATIONS } from '../../actions/notifications/actions';

import LoggedPage from '../page-templates/fillForm-logged';
import NotificationList from './_notification-list';

const mapStateToProps = (state) => {
  return {
    chat: state.chat,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receive_message: (msg) => {
      dispatch(CHAT_API.receive_message(msg));
    },
    receive_notification: (msg) => {
      dispatch(CHAT_API.receive_notification(msg));
    },
    reset_notification: () => {
      dispatch(CHAT_API.reset_notification());
    },
    mark_notification: (channel, data) => {
      dispatch(CHAT_API.mark_notification(channel, data));
    },
    list_messages: (data) => {
      dispatch(CHAT_API.list_messages(data));
    },
    notification_mark_all_seen: () => {
      dispatch(NOTIFICATIONS.notification_mark_all_seen());
    },
    notification_all: () => {
      dispatch(NOTIFICATIONS.notification_all());
    },
  };
};

class NotificationListPage extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  componentDidMount() {
    this.scrollToTop();
  }

  scrollToTop() {
    const scrollDuration = 600;
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / (scrollDuration / 15);
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin;
    let scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        scrollCount = scrollCount + 1;
        scrollMargin =
          cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
        window.scrollTo(0, scrollHeight - scrollMargin);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  }

  render() {
    return (
      <LoggedPage>
        <div className="hero-body has-background-white project-definition-header">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-8">
                <p className="title">
                  {I18n.get('Notificações')}
                  <br />
                </p>
              </div>
              <div className="column is-4"></div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="container uplift-notification-list-container">
            <NotificationList {...this.props} />

            <br />
            <br />
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationListPage);
