import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { useProjects } from "../../../../hooks/useProjects/useProjects.hook";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import Content from "../../../../components/UI/Content";
import { H2Border } from "../../../../components/UI/Titles";
import Loading from "../../../../components/UI/Loading";
import Main from "../components/Main";

import * as S from "./styles";

export const StepSuccess = () => {
  let params = useParams();
  const { getProjectByIdOld } = useProjects();
  const [projectData, setProjectData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const loadInit = async () => {
    setIsLoading(true);

    try {
      const project = await getProjectByIdOld({ projectId: params.projectId });
      setProjectData(project);
    } catch (error) {
      console.log("error", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadInit();
  }, []);

  const firstText = `
    ${I18n.get(
      "Seu projeto está criado. Agora, nossa curadoria e algoritmo irão buscar 3 parceiros com o perfil, experiência e expertise necessários para a execução do projeto."
    )}
  `;

  const firstTextSelectPartner = `
    ${I18n.get(
      "Seu projeto está criado e o parceiro foi notificado do seu convite."
    )}
  `;

  const textClickHere = `
    <div>
      <span>
        ${I18n.get("Quer acompanhar o andamento deste e de outros projetos?")}
      </span>
      <a href="/#/cliente/projetos/v2/">${I18n.get("Clique aqui")}</a>
      <span>
        ${I18n.get("para acessar a área de projetos.")}
      </span>
    </div>
  `;

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Muito bom!")}</H2Border>
            </Col>
            <Col sm={12} justify="end"></Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={12} offset={6}>
                    {isLoading ? (
                      <S.ContentInfos>
                        <S.P>
                          <Loading
                            text={I18n.get("Carregando...")}
                            sizeText={14}
                            sizeIcon={16}
                            align="left"
                          />
                        </S.P>
                      </S.ContentInfos>
                    ) : (
                      <S.ContentInfos>
                        <S.P
                          dangerouslySetInnerHTML={{
                            __html: projectData?.selectPartner
                              ? firstTextSelectPartner
                              : firstText,
                          }}
                        />
                        {!projectData?.selectPartner && (
                          <S.P>
                            {I18n.get(
                              "Aguarde. Em até dois dias úteis, você receberá uma notificação sobre os parceiros ideais que selecionamos para este projeto."
                            )}
                          </S.P>
                        )}
                        <S.P
                          dangerouslySetInnerHTML={{ __html: textClickHere }}
                        />
                      </S.ContentInfos>
                    )}
                  </Col>
                </Row>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
