import styled from 'styled-components';

export const NavbarChatNotification = styled.div`
  position: relative;
`;

export const NavbarChatIconNotification = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 10px;

  img {
    width: 17px;
    height: 17px;
  }
`;

export const CounterNotification = styled.div`
  position: absolute;
  top: 0;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #e30404;
  color: #fff;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -20px;
  margin-top: 2px;
`;

export const DropDown = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 30px;
  background: #ffffff;
  width: 320px;
  height: 240px;
  border: 1px solid;
  border-color: #d1d1d1;
  overflow-y: auto;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
`;

export const ListDropDown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  li {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    cursor: pointer;
  }
`;

export const ButtonMessage = styled.button`
  width: 100%;
  margin: 0;
  padding: 15px;
  background: transparent;
  cursor: pointer;
  border: none;
`;

export const MessageList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    white-space: normal;
  }

  strong {
    margin-top: 5px;
    cursor: default;
    font-size: 10px;
    opacity: 0.5;
  }

  small {
    margin-top: 5px;
    cursor: default;
    font-size: 10px;
    opacity: 0.5;
  }
`;
