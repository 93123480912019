import styled from "styled-components";

const fullView = (full) => {
  if (full) {
    return `
      width: 100%;
    `;
  }
};
export const Card = styled.div`
  width: ${(props) => (props.windowWidth <= 425 ? "100%;" : "318px;")};
  background: #ffffff;
  border-radius: 10px;
  padding: 25px;
  margin-right: ${(props) => (props.windowWidth <= 1024 ? "10px;" : "25px;")};
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: #000000;
  box-sizing: border-box;
  ${({ full }) => fullView(full)};
`;

export const HeaderCard = styled.div`
  padding: 0;
  border-bottom: 1px solid;
  border-color: #fff;
  display: block;
  width: 100%;
  box-sizing: border-box;
  min-height: 85px;
  margin-bottom: 5px;
`;

export const TitleHeaderCard = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
  font-size: ${(props) => (props.windowWidth <= 1024 ? "14px;" : "20px;")};
  line-height: ${(props) => (props.windowWidth <= 1024 ? "18px;" : "26px;")};
  max-height: ${(props) => (props.windowWidth <= 1024 ? "72px;" : "104px;")};
  text-align: left;
  font-weight: bold;
  color: #000000;
`;

export const ContentCard = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-top: 25px;
`;

export const Item = styled.div`
  display: flex;
  color: #000000;
  font-size: ${(props) => (props.windowWidth <= 1024 ? "12px;" : "16px;")};

  strong {
    font-size: ${(props) => (props.windowWidth <= 1024 ? "12px;" : "16px;")};
    color: #000000;
    padding-right: 5px;
  }
`;

export const WrapperModels = styled.div`
  margin-top: 15px;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-top: 6.5px !important;
      display: inline-block;
      margin-right: 5px;

      :first-child {
        margin-left: 0;
      }

      :last-child {
        margin-right: 0;
      }
    }
  }
`;

export const ButtonCard = styled.button`
  padding: 5px 23px !important;
  border: 1px solid !important;
  border-color: #e7e7e7 !important;
  border-radius: 16px !important;
  font-size: ${(props) =>
    props.windowWidth <= 1024 ? "12px !important;" : "16px !important;"};
  line-height: 20px !important;
  width: auto !important;
  height: auto !important;
  background-color: ${(props) =>
    props.selected ? "#E7E7E7 !important;" : "#FFFFFF !important;"};
  box-shadow: none !important;
  margin: 0 !important;
  cursor: pointer !important;

  :hover,
  :focus,
  :active {
    box-shadow: none !important;
  }
`;

export const WrapperInfo = styled.div`
  margin-top: 15px;
  height: 120px;

  p {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
`;

export const ActionCard = styled.div`
  display: flex;
  margin-top: 15px;

  .duble {
    margin-right: 15px;
  }
`;

export const DisabledLink = styled.div`
  width: 100%;
  height: 47px;
  border: none;
  background: #979797;
  border-radius: 26px;
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
`;

export const Ico = styled.div`
  margin-right: 15px;
`;
