export function strapiUrlsToDictionary(data) {
  let result = {};
  result.fallback = data?.url;
  result.small = data?.formats.small
    ? data?.formats.small.url
    : result.fallback;
  result.medium = data?.formats.medium
    ? data?.formats.medium.url
    : result.fallback;
  result.large = data?.formats.large
    ? data?.formats.large.url
    : result.fallback;
  return result;
}