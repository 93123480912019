import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../../components/pages/loading';
import { Redirect, withRouter } from "react-router-dom";

import { Task } from '../../../utils/task';

import {
    Button,
    Row,
    Col,
    Modal,
    Icon
} from 'antd';

import WhatifWrapper from '../components/WhatifWrapper';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import HeaderThumbCover from '../components/HeaderThumbCover';
import ProposalModalClient from '../components/ProposalModalClient';

import { IdeaWhatIfStatus } from '../../../model';

import PartnerInfos from '../components/PartnerInfos';

import { CardBorder } from '../components/Cards';

import IdeaInfos from '../components/IdeaInfos';

import { IDEA_WHATIF_API } from '../../../actions/idea-whatif/actions';

import { MENU_CLIENT_WHATIF } from '../constants';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_idea_detail: data => {
            dispatch(IDEA_WHATIF_API.get_idea_detail(data));
        },
        decline_offer: data => {
            dispatch(IDEA_WHATIF_API.decline_offer(data));
        },
        approve_idea: whatIfIdeaId => {
            dispatch(IDEA_WHATIF_API.approve_idea(whatIfIdeaId));
        },
        decline_idea: whatIfIdeaId => {
            dispatch(IDEA_WHATIF_API.decline_idea(whatIfIdeaId));
        },
        favorite_idea: data => {
            dispatch(IDEA_WHATIF_API.favorite_idea(data));
        }
    };
};


const Main = styled.div`
  padding-top: 0px;
  padding-bottom: 100px;
  color: #353535;

  .ButtonTop {
      button {
          float: right;
          position: relative;
          top: 15px;
          z-index: 10;

          @media (max-width: 768px) {
              span {
                  display: none;
              }
          }
      }
  }

  .RowBtns {
    .BtnAprovar {
        margin-right: 20px;
    }

    .BtnFavoritos {
        float: right;
    }
  }
`;

const ContainerModal = styled.div`
  padding: 0 40px;
`

class IdeaPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            visibleProposal: false,
            display: "",
            task: {}
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    showModalProposal = (info) => {

        this.setState({
            display: info,
            visibleProposal: true,
        });
    };

    handleCancelProposal = () => {
        this.setState({
            visibleProposal: false,
            dataModal: false,
            ideiaModal: false,
            producaoModal: false
        });
    };

    componentDidMount = () => {
        const data = {
            id: this.props.match.params.whatIfIdeaId,
            prefix: "ClientWhatIf"
        }
        this.props.get_idea_detail(data);

    }

    handleApproveIdea = (whatIfIdeaId) => {
        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.approve_idea(whatIfIdeaId);
        });

    }

    handleDeclineIdea = () => {
        const data = {
            whatIfIdeaId: this.props.match.params.whatIfIdeaId,
            prefix: "ClientWhatIf"
        }

        setTimeout(() => {
            this.props.decline_idea(data);
        }, 10);
    }


    handleFavoriteIdea = () => {
        const data = {
            whatIfIdeaId: this.props.match.params.whatIfIdeaId,
            trueORfalse: !this.props.ideaWhatIf.ideaDetail.isFavorite
        }
        this.props.favorite_idea(data);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.state.visibleProposal && this.props.ideaWhatIf && this.props.ideaWhatIf.ideaDetail.offers && this.props.ideaWhatIf.ideaDetail.offers != prevProps.ideaWhatIf.ideaDetail.offers) {
            this.handleCancelProposal()
        }
    }



    render() {
        const isInit = (!this.props.auth || this.props.auth.init);

        if (isInit) {
            return (<Loading />);
        }

        const isNotLogged = (this.props.auth && !this.props.auth.logged_user);
        const IsLogout = (this.props.auth && this.props.auth.is_logout);

        if (isNotLogged) {

            const login = {
                pathname: '/login',
                state: {
                    returnUrl: (IsLogout ? '' : this.props.location.pathname),
                }
            }

            return (<Redirect to={login}></Redirect>);
        }   


        if (!this.props.ideaWhatIf.ideaDetail) {
            return (<Loading />);
        }

        const { display } = this.state;

        const {
            ideaWhatIf: {
                ideaDetail,
                ideaDetail: {
                    whatIfIdeaId,
                    partner,
                    partner: {
                        imageLogo,
                        imageIntro
                    },
                    status,
                    offers
                }
            }
        } = this.props;

        const activeOffer = offers.filter(offer => offer.activeOffer == true);

        const declineOfferGet = offers.filter(offer => offer.activeOffer == false && offer.declinedOn);
        const declineOffer = declineOfferGet.length > 0 ? declineOfferGet[0] : null;

        return (
            <Fragment>
                <WhatifWrapper>
                    <SubHeader itensMenu={MENU_CLIENT_WHATIF} activeItem={0} />
                    <HeaderThumbCover
                        thumb={imageLogo ? imageLogo : ""}
                        bgHeader={imageIntro ? imageIntro : ""}
                        isCreative
                    />
                    <ContainerPage>
                        <Main>
                            <Row>
                                <Col sm={24} className="ButtonTop">
                                    <Button className="BtnFavoritos" type="primary" shape="round" onClick={() => this.handleFavoriteIdea()}>
                                        {this.props.ideaWhatIf.ideaDetail.isFavorite &&
                                            <Icon type="heart" theme="twoTone" twoToneColor="#fff" />
                                        }
                                        {!this.props.ideaWhatIf.ideaDetail.isFavorite &&
                                            <Icon type="heart" />
                                        } Favoritos</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={24}>
                                    <IdeaInfos
                                        isClient
                                        showModal={this.showModal}
                                        showModalProposal={this.showModalProposal}
                                        idea={ideaDetail}
                                    />
                                </Col>
                            </Row>
                            <CardBorder>
                                <Row className="RowBtns">
                                    <Col sm={24}>
                                        {(status != IdeaWhatIfStatus.Approved && status != IdeaWhatIfStatus.ApprovedWithPO) &&
                                            <Fragment>
                                                <Button className="BtnAprovar" type="primary" shape="round" onClick={() => this.handleApproveIdea(whatIfIdeaId)}>APROVAR</Button>

                                                <Button className="BtnAprovar" type="primary" shape="round" onClick={() => this.handleDeclineIdea()}>Reprovar</Button>

                                                {activeOffer[0].activeOfferFrom != "C" && !declineOffer &&
                                                    <Button type="primary" shape="round" onClick={() => this.showModalProposal(offers[0].jobOffer)}>Fazer contra proposta</Button>

                                                }
                                            </Fragment>

                                        }


                                        <Button className="BtnFavoritos" type="primary" shape="round" onClick={() => this.handleFavoriteIdea()}>
                                            {this.props.ideaWhatIf.ideaDetail.isFavorite &&
                                                <Icon type="heart" theme="twoTone" twoToneColor="#fff" />
                                            }
                                            {!this.props.ideaWhatIf.ideaDetail.isFavorite &&
                                                <Icon type="heart" />
                                            } Favoritos</Button>
                                    </Col>
                                </Row>
                            </CardBorder>
                        </Main>
                    </ContainerPage>
                </WhatifWrapper>
                <Modal
                    title=""
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={false}
                    width={800}
                >
                    <ContainerModal>
                        <PartnerInfos
                            isClient
                            infos={partner}
                        />
                    </ContainerModal>
                </Modal>

                <Modal
                    title="Contra proposta"
                    visible={this.state.visibleProposal}
                    onCancel={this.handleCancelProposal}
                    footer={false}
                    width={1000}
                >
                    <ContainerModal>
                        <ProposalModalClient
                            display={display}
                            declineOffer={this.props.decline_offer}
                            jobOffert={offers[0].jobOffer}
                            originalOffer={offers[0]}
                            whatIfIdeaOfferId={activeOffer[0].whatIfIdeaOfferId}
                            prefix="ClientWhatIf"
                            whatIfIdeaId={whatIfIdeaId}
                            errorsOffers={this.props.ideaWhatIf.errorsOffers}

                        />
                    </ContainerModal>
                </Modal>
            </Fragment >
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IdeaPreview));
