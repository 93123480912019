import get_partner from "./get";
import get_by_token from "./get_by_token";

import create_partner from "./create";
import update_partner from "./update";

import confirm_partner from "./confirm";

import create_client from "./create-client";
import delete_client from "./delete-client";
import update_client2 from "./update-client-register";

import create_expertise from "./create-expertise";
import update_expertise from "./update-expertise";
import delete_expertise from "./delete-expertise";

import create_case from "./create-case";
import delete_case from "./delete-case";
import update_case from "./update-case";

import update_order_case from "./update-order-case";

import create_asset from "./create-asset";

import create_award from "./create-award";
import delete_award from "./delete-award";
import update_award from "./update-award";

import create_tag from "./create-tags";
import update_tag from "./update-tags";

import create_leader from "./create-leader";
import delete_leader from "./delete-leader";
import update_leader from "./update-leader";

import update_order_leader from "./update-order-leader";

import create_reference from "./create-reference";
import update_reference from "./update-reference";
import delete_reference from "./delete-reference";

import update_client from "./update-client";
import update_person from "./update-person";
import update_company from "./update-company";
import update_specialism from "./update-specialism";
import update_video from "./update-video";

import get_agreements from "./get_agreements";

import upload from "./upload";

import get_projects from "./get_projects";
import get_projects2 from "./get_projects2";
import get_project from "./get_project";

import update_invitation from "./update-invitation";
import update_invitation_withdraw from "./update-invitation-withdraw";
import update_match_opt_out from "./update-match-opt-out";
import update_match_nda from "./update-match-nda";
import update_match_nda_upload from "./update-match-nda-upload";

import get_dashboard from "./get_dashboard";

import get_invitations from "./get_invitations";

import update_partner_pre_signup from "./update-pre-signup";

import create_billing_invoice from "./create-billing-invoice";
import create_production_billing_invoice from "./create-production-billing-invoice";
import create_extra_billing_invoice from "./create-extra-billing-invoice";
import upload_billing_invoice from "./upload-billing-invoice";

import get_clients from "./get_clients";
import get_brands_client from "./get_brands_client";
import save_projetc_step1 from "./salve_project";
import temp_cart from "./temp-cart";

import api_set_new_company from "./set-new-company";

import get_assets from "./get_assets";

import create_course from "./create-course";
import delete_course from "./delete-course";
import update_course from "./update-course";

import create_cause from "./create-cause";
import delete_cause from "./delete-cause";
import update_cause from "./update-cause";

import create_portfolio from "./create-portfolio";
import delete_portfolio from "./delete-portfolio";
import update_portfolio from "./update-portfolio";

import create_user from "./create-user";
import delete_user from "./delete-user";
import update_user from "./update-user";

import get_userop from "./get_usersoperation";

import send_reference from "./send-reference";

import get_indemnity from "./get_indemnity";

import create_workabroad from "./create-work-abroad";
import delete_workabroad from "./delete-work-abroad";

import create_companyabroad from "./create-company-abroad";
import delete_companyabroad from "./delete-company-abroad";

import accept_meeting_invite from "./accept_meeting_invite";
import delete_meeting_invite from "./delete_meeting_invite";
import get_meeting_invites from "./get_meeting_invites";

import option_data_meeting from "./meeting-data";

import get_extras from "./get_extras";

import create_project_proposal from "./create-project-proposal";

// SUGESTÃO DE PROJETO

import get_clients_v2 from "./get_clients-v2";
import create_suggestion from "./create-suggestion-project";
import update_suggestion from "./update-suggestion-project";

export const PARTNER_GET = "partner_get";
export const PARTNER_CREATE = "partner_create";
export const PARTNER_UPDATE = "partner_update";

export const PARTNER_EXPERTISE_CREATE = "partner_expertise_create";
export const PARTNER_EXPERTISE_UPDATE = "partner_expertise_update";
export const PARTNER_EXPERTISE_DELETE = "partner_expertise_delete";

export const PARTNER_CLIENT_CREATE = "partner_client_create";
export const PARTNER_CLIENT_DELETE = "partner_client_delete";
export const PARTNER_CLIENT_UPDATE = "partner_client_update";

export const PARTNER_CASE_CREATE = "partner_case_create";
export const PARTNER_CASE_DELETE = "partner_case_delete";

export const PARTNER_AWARD_CREATE = "partner_award_create";
export const PARTNER_AWARD_UPDATE = "partner_award_update";
export const PARTNER_AWARD_DELETE = "partner_award_delete";

export const PARTNER_TAGS_CREATE = "partner_award_create";
export const PARTNER_TAGS_UPDATE = "partner_award_update";

export const PARTNER_LEADER_CREATE = "partner_leader_create";
export const PARTNER_LEADER_DELETE = "partner_leader_delete";
export const PARTNER_LEADER_UPDATE = "partner_leader_update";

export const PARTNER_REFERENCE_CREATE = "partner_reference_create";
export const PARTNER_REFERENCE_UPDATE = "partner_reference_update";
export const PARTNER_REFERENCE_DELETE = "partner_reference_delete";

export const PARTNER_PROJECTS_GET = "partner_projects_get";
export const PARTNER_PROJECTS_GET2 = "partner_projects_get2";
export const PARTNER_PROJECT_GET = "partner_project_get";
export const PARTNER_PROJECTS_UPDATE = "partner_projects_update";

export const PARTNER_CLIENTS_GET = "partner_clients_get";
export const PARTNER_BRANDS_GET = "partner_brands_get";
export const PARTNER_SAVE_PROJECT = "partner_save_projetc";
export const PARTNER_TEMPCART_PROJECT = "partner_tempcart_projetc";

export const PARTNER_NEW_COMPANY = "partner_new_company";

export const PARTNER_ASSETS_GET = "partner_assets_get";

export const PARTNER_AGREEMENTS = "partner_agreements";

export const PARTNER_COURSE_CREATE = "partner_course_create";
export const PARTNER_COURSE_DELETE = "partner_course_delete";
export const PARTNER_COURSE_UPDATE = "partner_course_update";

export const PARTNER_CAUSE_CREATE = "partner_cause_create";
export const PARTNER_CAUSE_DELETE = "partner_cause_delete";
export const PARTNER_CAUSE_UPDATE = "partner_cause_update";

export const PARTNER_PORTFOLIO_CREATE = "partner_portfolio_create";
export const PARTNER_PORTFOLIO_DELETE = "partner_portfolio_delete";
export const PARTNER_PORTFOLIO_UPDATE = "partner_portfolio_update";

export const PARTNER_USER_CREATE = "partner_user_create";
export const PARTNER_USER_UPDATE = "partner_user_update";
export const PARTNER_USER_DELETE = "partner_user_delete";

export const PARTNER_USEROP_GET = "partner_userop_get";

export const PARTNER_REFERENCE_SEND = "partner_reference_send";

export const PARTNER_CREATE_ABROAD = "partner_create_abroad";

export const PARTNER_EXTRAS_GET = "partner_extras_get";

export const PARTNER_PROJECT_UPDATE = "partner_project_update";

export const PARTNER_API = {
  get: get_partner,
  get_by_token: get_by_token,
  create: create_partner,
  update: update_partner,
  confirm: confirm_partner,
  create_expertise: create_expertise,
  delete_expertise: delete_expertise,
  update_expertise: update_expertise,
  create_client: create_client,
  update_client2: update_client2,
  delete_client: delete_client,
  create_case: create_case,
  delete_case: delete_case,
  update_case: update_case,
  create_award: create_award,
  delete_award: delete_award,
  update_award: update_award,
  create_leader: create_leader,
  delete_leader: delete_leader,
  update_leader: update_leader,
  create_reference: create_reference,
  update_reference: update_reference,
  delete_reference: delete_reference,
  update_client: update_client,
  update_person: update_person,
  update_company: update_company,
  update_specialism: update_specialism,
  update_video: update_video,
  upload: upload,
  get_projects: get_projects,
  get_invitations: get_invitations,
  get_project: get_project,
  update_invitation: update_invitation,
  update_invitation_withdraw: update_invitation_withdraw,
  update_match_opt_out: update_match_opt_out,
  update_match_nda: update_match_nda,
  update_match_nda_upload: update_match_nda_upload,
  get_dashboard: get_dashboard,
  update_pre_signup: update_partner_pre_signup,
  create_billing_invoice: create_billing_invoice,
  create_production_billing_invoice: create_production_billing_invoice,
  create_extra_billing_invoice: create_extra_billing_invoice,
  upload_billing_invoice: upload_billing_invoice,
  get_clients: get_clients,
  get_clients_v2: get_clients_v2,
  get_brands_client: get_brands_client,
  save_projetc_step1: save_projetc_step1,
  save_temp_cart: temp_cart,
  new_company: api_set_new_company,
  create_asset: create_asset,
  get_assets: get_assets,
  get_agreements: get_agreements,
  create_tags: create_tag,
  update_tags: update_tag,
  create_course: create_course,
  delete_course: delete_course,
  update_course: update_course,
  create_cause: create_cause,
  delete_cause: delete_cause,
  update_cause: update_cause,
  create_portfolio: create_portfolio,
  delete_portfolio: delete_portfolio,
  update_portfolio: update_portfolio,
  create_user: create_user,
  delete_user: delete_user,
  update_user: update_user,
  get_userop: get_userop,
  send_reference: send_reference,
  get_projects2: get_projects2,
  get_indemnity: get_indemnity,
  get_meeting_invites: get_meeting_invites,
  accept_meeting_invite: accept_meeting_invite,
  delete_meeting_invite: delete_meeting_invite,
  update_order_case: update_order_case,
  update_order_leader: update_order_leader,
  create_workabroad: create_workabroad,
  delete_workabroad: delete_workabroad,
  option_data_meeting: option_data_meeting,
  create_companyabroad: create_companyabroad,
  delete_companyabroad: delete_companyabroad,
  get_extras: get_extras,
  create_project_proposal: create_project_proposal,
  create_suggestion: create_suggestion,
  update_suggestion: update_suggestion,
};
