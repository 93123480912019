import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import * as S from './styles';

export const ItemCarousel = ({ children, id }) => {
  function goPrev() {
    document.getElementById(`carousel-resume-${id}`).scrollBy({
      left: -100,
      behavior: 'smooth',
    });
  }

  function goNext() {
    document.getElementById(`carousel-resume-${id}`).scrollBy({
      left: 100,
      behavior: 'smooth',
    });
  }

  return (
    <S.WrapperItemCarousel>
      <S.BtArrowCarousel className="prev" onClick={goPrev}>
        <LeftOutlined />
      </S.BtArrowCarousel>
      <S.ContainerItemCarousel>
        <S.ContentItemCarousel id={`carousel-resume-${id}`}>
          {children}
        </S.ContentItemCarousel>
      </S.ContainerItemCarousel>
      <S.BtArrowCarousel className="next" onClick={goNext}>
        <RightOutlined />
      </S.BtArrowCarousel>
    </S.WrapperItemCarousel>
  );
};
