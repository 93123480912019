import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 10px;
  background-color: #fafafa;
  border: 1px solid;
  border-color: #f0f0f0;
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => (props.columns ? props.columns : 4)}, 1fr);
  gap: 0px;
`;

export const HeaderCell = styled.div`
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  height: 50px;
  display: flex;
  align-items: center;
`;

export const Cell = styled.div`
  padding: 5px;
  background-color: #ffffff;
  border-bottom: 1px solid;
  border-color: #f0f0f0;
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  height: 50px;

  :last-of-type {
    border: none;
  }
`;

export const InfoMessage = styled.div`
  padding: 15px;
  background-color: #ffffff;

  p {
    font-size: 13px;
    color: #515151;
    line-height: 17px;
  }
`;

export const ValueTotal = styled.div`
  padding: 25px 50px 25px 10px;

  .grossUp {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
  }
  
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #515151;
  }
  .value {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #4281cb;
    margin-left: 30px;
  }
`;

export const ImportantMessage = styled.div`
  margin-top: 25px;

  p {
    font-size: 13px;
    color: #515151;
    line-height: 17px;

    span {
      font-weight: 600;
      padding-right: 5px;
    }
  }
`;
