export const inputsState = {
  name: "",
  registrationName: "",
  email: "",
  teamSizeRange: "",
  commercialAddressLine1: "",
  commercialAddressLine2: "",
  commercialCity: "",
  commercialCountry: {
    bpRegion: "",
    code: "BRA",
    isO2: "",
    name: "",
    capital: "",
    prefix: "",
    currencyCode: "",
    culture: "",
  },
  commercialPhoneNumber: "",
  commercialPostcode: "",
  commercialState: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  postcode: "",
  country: {
    bpRegion: "",
    code: "",
    isO2: "",
    name: "",
    capital: "",
    prefix: "",
    currencyCode: "",
    culture: "",
  },
  state: "",
  phoneNumber: "",
  docAccountStatement: null,
  docCnpjCard: null,
  docNegativeCertificate: null,
  docSocialContract: null,
  copyAddressToCommercialAddress: false,
};
