import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { CLIENT_API } from '../../../actions/client/actions';
import { PROJECT_API } from '../../../actions/project/actions';
import styled from 'styled-components';
import LoggedPage from '../../page-templates/fillForm-logged';
import { Table, Icon } from 'antd';
import { formatMoney2 } from '../../../utils/helpers';

import NdaList from './_nda-list';

import { ProjectTypeNames } from '../../../model';

import { scrollToTop } from '../../../utils/ui';

import FormatCurrency from '../../ui/format-currency';

const Bts = styled.p`
    a {
        margin-right: 15px;
        margin-bottom: 5px;
    }
`
const Main = styled.div`
    background: #fff;
    padding-top: 130px;
    padding-bottom: 130px;

    h1 {
        font-size: 25px;
        position: relative;
        width: auto;
        font-weight: bold;
        margin: 0 0 48px;
        font-family: "Helvetica Neue LT Std";

        span{
            font-family: "Helvetica Neue LT Std";
            position: relative;
            font-weight: bold;
            &::after {
            background-color: #000;
            position: absolute;
            display: block;
            content: '';
            height: 8px;
            margin-top: 12px;
            top: 100%;
            width: 80%;
        }
    }
    }
`
const mapStateToProps = state => {
    return Object.assign({}, state.client, state);
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_agreements: () => {
            dispatch(CLIENT_API.get_agreements());
        },
        get_ndas: () => {
            dispatch(CLIENT_API.get_ndas());
        },
        delete_project: (id) => {
            dispatch(CLIENT_API.delete_project(id));
        },
        reset: () => {
            dispatch(PROJECT_API.reset());
        },
    };
};


class TepProjectListAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {
        this.props.get_agreements();
        this.props.get_ndas();
        scrollToTop();
    }


    //Client Project List
    render() {


        const data = this.props.agreements && this.props.agreements.map((item) => {
            return {
                type: item.projectType,
                brand: item.brandName,
                product: item.productName,
                name: item.projectName,
                money: item.priceClientWithDiscount,
                currency: item.projectCurrency,
                files: item
            }
        });

        const dataTermos = [
            {
                name: I18n.get("Termos de uso & Política de ética e práticas anticorrupção"), 
                pdf: this.props.client && this.props.client.clientTerm
            }
        ]

        const columnsTermos = [

            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: I18n.get("PDF"),
                dataIndex: 'pdf',
                key: 'pdf',
                render: pdf => {

                    return (
                        <Bts>
                            <a href={pdf} target="_blank"><Icon type="file-pdf" /> {I18n.get("Baixar")}</a>
                        </Bts>
                    );
                }
            },
        ]

        const columns = [
            {
                title: I18n.get("Tipo"),
                dataIndex: 'type',
                key: 'type',
                render: t => ProjectTypeNames[t]
            },
            {
                title: I18n.get("Marca"),
                dataIndex: 'brand',
                key: 'brand',
            },
            {
                title: I18n.get("Produto"),
                dataIndex: 'product',
                key: 'product',
            },
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: I18n.get("Valor"),
                key: 'action',
                render: (text, record) => (
                    <FormatCurrency amount={record.money} currency={record.currency}  />
                )
            },
            {
                title: I18n.get("PDF"),
                dataIndex: 'files',
                key: 'files',
                render: (record) => {

                    return (
                        <Bts>
                            {record.pdfProject &&
                                <a href={record.pdfProject} target="_blank"><Icon type="file-pdf" /> {I18n.get("Projeto")}</a>
                            }
                            {record.pdfTep &&
                                <a href={record.pdfTep} target="_blank"><Icon type="file-pdf" /> {I18n.get("Tep")}</a>
                            }
                            {record.pdfAgreement &&
                                <a href={record.pdfAgreement} target="_blank"><Icon type="file-pdf" /> {I18n.get("Aceite")}</a>
                            }
                        </Bts>
                    );
                }
            },

        ];

        return (
            <LoggedPage>
                <Main className="uplift">

                    <div className="container">

                        <br />
                        <h1><span>{I18n.get("Termos")}</span></h1>
                        <Table columns={columnsTermos} dataSource={dataTermos} pagination={false} />

                        <br />
                        <br />
                        <h2 className="title-bpool medium">{I18n.get("Projetos")}</h2>

                        <div className="table-main">
                            <Table scroll={{ x: 640 }} columns={columns} dataSource={data} />
                        </div>

                        <br /><br />

                        <NdaList {...this.props} />

                    </div>

                </Main>
            </LoggedPage>
        );


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TepProjectListAdmin);
