import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";
import { I18n } from 'aws-amplify';

import Config from "../../config";

import { 
  notification,
} from 'antd';

import { BP_ASSETS_CLIENTS_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const api_update_client_asset = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = {
          headers: {
            Authorization: "Bearer " + token,
          },
          validateStatus: () => true, // Avoid exceptions propagation.
        };

        return axios
          .put(
            `${Config.API_BASE_URL}/bp/client/asset-update/${data.clientId}`,
            data,
            config
          )
          .then((response) => {

            if(response.data?.assets) {
              dispatch({
                type: BP_ASSETS_CLIENTS_GET,
                payload: {
                  clientAssets: response.data,
                  // errors: error?.response?.data,
                  task: { result: true },
                },
              });
            } else {
              dispatch({
                type: BP_ASSETS_CLIENTS_GET,
                
                payload: {
                  errors: response.data,
                   task: { result: false }
              }
              });


              if(response.data.isManHour) {

                notification.error({
                  message: I18n.get('Algo deu errado!'),
                  description: response.data.isManHour.errorMessage,
               });

              }
              else {
                  
                  notification.error({
                    message: I18n.get('Algo deu errado!'),
                    description: I18n.get('Tente novamente mais tarde'),
                });
              }
            }



            
          })
          .catch((error) => {
            console.log("error", error);

            dispatch({
              type: BP_ASSETS_CLIENTS_GET,
              
              payload: {
                errors: error.response.data,
                 task: { result: false }
            }
            });
          });
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        //  dispatch({ type: EXPIRED });
      });
  };
};

export default api_update_client_asset;
