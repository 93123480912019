import React from "react";

import ProjectContainer from "../../containers/project-partner-container";
import ProjectCart from "../../containers/project-cart-container";

import styled from "styled-components";
import { I18n } from "aws-amplify";

import FillForm from "../page-templates/fillForm-logged";

import { Button } from "antd";

import { Redirect, Link } from "react-router-dom";

import AssetCustomizeForm from "./asset-customize-form";
import AssetResumeForm from "./asset-resume-form";

import Loading from "../../components/pages/loading";

const MainForm = styled.div`
  background: #fff;
  margin: 0 auto 15px;
  box-sizing: border-box;
  padding: 20px 40px;
  overflow: hidden;

  .btnnext {
    float: right;
    margin-right: 15px;
  }
`;

class NewProjectStep2 extends React.Component {
  constructor(props) {
    super();

    const disableNewProjectStep2BundleView =
      props.client.customization &&
      props.client.customization.disableNewProjectStep2BundleView;

    const initialView = disableNewProjectStep2BundleView ? "CART" : "BUNDLE";

    this.state = {
      view: "CART",
      bundles: [],
      loadingAssets: false,
    };
  }

  setView = (viewName) => {
    this.setState({
      view: viewName,
    });
  };

  handleResumeBtnClick = (cartAssets) => {
    this.setState({
      view: "CHECKOUT",
      cartAssets: cartAssets,
    });
  };

  componentDidMount = () => {
    const data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_project(data);

    setTimeout(() => {
      let data = {
        clientId: this.props.project && this.props.project.clientId,
        isPartner: true,
      };

      this.props.get_assets(data);
    }, 1500);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.project.assets != prevProps.project.assets &&
      !this.state.loadingAssets
    ) {
      this.setState({
        loadingAssets: true,
      });
    }
  }

  render() {
    let applyDiscount =
      this.props.client &&
      this.props.client.customization &&
      this.props.client.customization.applyDiscount;

    if (!this.props.bp?.assetSpecialismsGroup) return <Loading />;
    if (!this.props.assets) return <Loading />;

    return (
      <FillForm>
        <div className="client-step client-step-1 criar-asset">
          <div className="container client-container-1 client-container-build-project uplift-step-2">
            <br />
            <br />
            <br />
            <div className="column is-12">
              {/* Carrinho */}
              {this.state.view == "CART" && (
                <AssetCustomizeForm
                  {...this.props}
                  onBundleBtnClick={this.setView.bind(this, "BUNDLE")}
                  onResumeBtnClick={this.setView.bind(this, "CHECKOUT")}
                  bundles={this.state.bundles}
                  cartAssets={this.props.cartAssets}
                  addItem={this.props.addItem}
                  applyDiscount={applyDiscount}
                  loadingAssets={this.state.loadingAssets}
                  clientId={this.props.project && this.props.project.clientId}
                  projectId={this.props.match.params.projectId}
                />
              )}

              {/* Resumo */}
              {this.state.view == "CHECKOUT" && (
                <div className="build-closure">
                  <AssetResumeForm
                    {...this.props}
                    onCustomBtnClick={this.setView.bind(this, "CART")}
                    cartAssets={this.props.cartAssets}
                    delItem={this.props.delItem}
                    applyDiscount={applyDiscount}
                  />
                </div>
              )}
            </div>
            {this.state.view == "CART" && (
              <div className="column is-12">
                <MainForm>
                  <Link
                    to={`/parceiro/criar-projeto/milestones/${this.props.match.params.projectId}`}
                  >
                    <Button
                      className="button bt-bpool pull-right black"
                      type="primary"
                    >
                      {I18n.get("Voltar")}
                    </Button>
                  </Link>
                </MainForm>
              </div>
            )}
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(ProjectCart(NewProjectStep2));
