import React from 'react';

import { I18n } from 'aws-amplify';

import Steps from './_audio-steps';

import NumberFormat from 'react-number-format';

import TableProposal from '../../../modules/Admin/components/tableProposal';
import TableProposalAsset from '../../../modules/Admin/components/tableProposalAsset';
import FormProposal from '../../../modules/Admin/components/formProposal';

import ReactAudioPlayer from 'react-audio-player';

import { Task } from '../../../utils/task';

import { Drawer, Rate, Collapse, Icon, Modal, Row, Col, Select, Button, Popconfirm,Tooltip} from 'antd';

const BRL = ({ amount }) => <NumberFormat value={amount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} fixedDecimalScale={true} />;





const { Option } = Select;

const { Panel } = Collapse;

export default class ProjectDetails extends React.Component {

    constructor(props) {
        super();

        this.state = {
            detailsView: null,
            task: {},
            visible: false,
            viewDrawer: false,
            loading: false,
            newStatus: "",
            newPerc: "",
            newRespClient: "",
            newRespPartner: "",
            loadingProject: false,
            assets: [],
            assetsGroup: []
        };
    }

    componentDidMount() {
        this.setState({ task: {}, loadingProject: true });

        let dataAsset = {
            clientId: this.props.project.project.clientId
        }


        this.props.get_assets(dataAsset);
    }

    componentWillMount(){
        this.setState({ loadingProject: false });
    }
    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            viewDrawer: false,
            assets: []
        });
    };

    sendProposal = (total) => {
        const data = {
            total: total,
            assets: this.state.assets,
            projectId: this.props.match.params.audioId
        }
        this.props.update_proposal(data).then(response => {
            if(response){
                var data = {
                    projectId: this.props.match.params.audioId
                };
        
               
                this.props.get_project(data);

                this.onClose();
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.project.task || {};
        const prev = prevProps && prevProps.project.task || {};



        if (task != prev) {
            this.setState({ task: task });
        }

    }


    addAsset = (data) => {
        
        let getAsset = this.props.bp?.assets.find(asset => asset.code == data.asset);

        let newAsset = this.state.assets;

        let n = 0;

        while (n < data.qnt) {                                                                                                                                                                                                                                                        
            newAsset = newAsset.concat(getAsset);
            n++
        }

        this.setState({ assets: newAsset })
    }  


    render() {
        const {
            project: {
                project,
            },
        } = this.props;

        var totalPrice = 0;
        var totalItem = 0;
        let _cart = this.state.assets;
        var cart = [];

        _cart.forEach((item) => {
            var index = cart.findIndex((_item) => {
                return _item.asset.id == item.id;
            });

            if (index > -1) {
                cart[index].total++;
            } else {
                cart.push({
                    asset: item,
                    total: 1
                });
            }
            totalPrice += item.price;
            totalItem++;
        });

        return (
            <div className="project-ongoing-review container-content">

                <Steps {...this.props}></Steps>

                <br />
                <br />

          

                <Row type="flex" className="rowFlexBp">
                    <Col sm={12}>
                        <h1 className="title-bpool medium">{I18n.get("Informações")}</h1>

                        {project && project.review && project.status == 20 &&
                            <p className="pbtn-avaliacao"><a onClick={this.showDrawer} className="button is-dark btn-open-avaliacao">{I18n.get("Ver avaliações")}</a></p>
                        }

                        <div className="card uplift">
                            <div className="card-content break-word">
                                <p>
                                    <strong>{I18n.get("Nome do Projeto:")}</strong> {this.props.projectName}
                                </p>
                                <p>
                                    <strong>{I18n.get("Produto:")}</strong> {this.props.brandName} - {this.props.productName}
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <h1 className="title-bpool medium">{I18n.get("Cliente")}</h1>
                        <div className="card">
                            <div className="card-content break-word">
                                {this.props.clientName && <>
                                    <p>
                                        <strong>{I18n.get("Cliente:")}</strong> {this.props.clientName}
                                    </p>
                                    <p>
                                        <strong>{I18n.get("Responsável:")}</strong> {this.props.createdByName}
                                    </p>

                                    <p>
                                        <strong>{I18n.get("Contato:")}</strong> {this.props.createdByPhone}
                                    </p>
                                    <p>
                                        <strong>{I18n.get("E-mail:")}</strong> {this.props.createdByEmail}
                                    </p>
                                </>}
                            </div>
                        </div>
                    </Col>
                </Row>


                <Row type="flex" className="rowFlexBp">
                    <Col sm={12}>
                        <div className="project-definition">
                            <h1 className="title-bpool medium">{I18n.get("Audios")}</h1>
                        </div>

                        <div className="card uplift">
                            <div className="card-content break-word">
                               {project?.audioFiles.length > 0 && project?.audioFiles.map((item,index) => {
                                   return(
                                       <div className="boxAudio">
                                           <div className="titleAudio"> 
                                                <span>0{index + 1}</span>
                                                <ReactAudioPlayer
                                                    src={item.url}
                                                    // autoPlay
                                                    controls
                                                />
                                            </div>

                                            <p className="descricao">
                                                {item.description || I18n.get("Aguardando a descrição")}
                                            </p>
                                        </div>
                                   )
                               })}
                            </div>
                        </div>

                    </Col>
                    <Col sm={12}>
                        <div className="project-definition">
                            <h1 className="title-bpool medium">{I18n.get("Extras")}</h1>
                        </div>
                        <div className="card uplift">
                            <div className="card-content break-word">
                               <p>{project?.demand}</p>

                               <div className="listFilesAudio">


                                    {project?.demandFiles.length > 0 && project?.demandFiles.map((item,index) => {
                                    return(
                                        <Tooltip key={index} title={item.name}>
                                            <a href={item.url} target="_blank">
                                                <Icon type="file" theme="filled" />
                                            </a>
                                        </Tooltip>
                                    )
                                    })}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <br />
                <br />
                
                <Row type="flex" className="rowFlexBp">
                    <Col sm={24}>
                        <div className="project-definition">
                            <h1 className="title-bpool medium">{I18n.get("Propostas")}</h1>
                        </div>

                        <div className="card uplift">
                            <p style={{padding: "30px"}}>
                                <Button onClick={() => this.setState({viewDrawer: true})} disabled={project.status == 5000 || project.status == 2000 ? false : true} className="button bt-bpool black pull-right">{I18n.get("Nova Proposta")}</Button>
                            </p>
                            <div className="card-content break-word">
                                <TableProposal
                                    data={project?.proposals || []}
                                    edit={this.setItem}
                                    delete={this.handleDelete}
                                />
                            </div>
                        </div>

                    </Col>
                </Row>

                <Drawer
                    title={I18n.get("Nova proposta")}
                    placement="right"
                    closable={false}
                    destroyOnClose={true}
                    onClose={this.onClose}
                    visible={this.state.viewDrawer}
                    width="60%"
                >

                        <FormProposal 
                            assets={this.props.bp?.assets}
                            action={this.addAsset}
                        />
                        <br />
                        <br />
                        <br />
                        <TableProposalAsset
                            data={cart || []}
                            totalPrice={totalPrice}
                        />

                        <Button onClick={() => this.sendProposal(totalItem)} disabled={this.state.assets.length == 0} className="button bt-bpool black pull-right">{I18n.get("Enviar Proposta")}</Button>
                </Drawer>
            </div>
        );
    }
}
