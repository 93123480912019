import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { I18n } from 'aws-amplify';

import NumberFormat from 'react-number-format';

import {
    Table,
    Icon
} from 'antd';

const BRL = ({ amount }) => <NumberFormat value={amount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} fixedDecimalScale={true} />;



const Btns = styled.p`
width: 50px;
displa: block;
    a {
        margin-left: 10px;
    }
`

class tableScope extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }


    getAssetType(code) {

        if (!code) return {};
        if (!this.props.bp.assetTypes) return {};

        const item = this.props.bp.assetTypes.filter((t) => t.code == code);
        if (item && item.length) {
            return item[0];
        }
        else {
            return {};
        }
    }


    render() {
        const columns = [
            {
                title: I18n.get("Projeto"),
                dataIndex: 'project',
                key: 'project'
            },
            {
                title: I18n.get("Asset"),
                dataIndex: 'asset',
                key: 'asset',
                // render: (asset) => {
                //     return this.getAssetType(asset);
                //  }
            },
            {
                title: I18n.get("R$ Ref"),
                dataIndex: 'ref',
                key: 'ref',
                render: (text) => {
                    return <BRL amount={text} />;
                 }
            },
            {
                title: I18n.get("R$ Bpool"),
                dataIndex: 'bpool',
                key: 'bpool',
                render: (text) => {
                    return <BRL amount={text} />;
                 }
            },
            {
                title: I18n.get("Qtd"),
                dataIndex: 'qtd',
                key: 'qtd'
            },
            // {
            //     title: I18n.get("R$ Total"),
            //     dataIndex: 'total',
            //     key: 'total',
            // },
            {
                title: I18n.get("R$ Saving"),
                dataIndex: 'saving',
                key: 'saving',
                render: (text) => {
                    return <BRL amount={text} />;
                 }
            }
            // {
            //     title: '',
            //     dataIndex: 'actions',
            //     key: 'item',
            //     width: '100px',
            //     render: (text, record) => {
            //         let data = {
            //             scopeId: record.scopeId,
            //         }

            //         return (
            //             <div>
            //                 {!this.props.isLeader &&
            //                     <Btns>
            //                         <a onClick={() => this.props.edit(record)}>
            //                             <Icon type="edit" theme="filled" />
            //                         </a>
            //                         <a onClick={() => this.props.delete(data)}>
            //                             <Icon type="delete" theme="filled" />
            //                         </a>
            //                     </Btns>
            //                 }
            //             </div>
            //         );
                    
            //     }
            // },
        ]

        let dataTable = this.props.data && this.props.data.map((item, index) => {
            return {
                key: index,
                project: item.projectName,
                asset: item.assetLabel,
                spec: item.specialismLabel,
                ref: item.totalRefRate,
                bpool: item.totalBPoolPaidRate,
                qtd: item.quantity,
                total: item.totalSaving * item.quantity,
                saving: item.totalSaving,
            }
        });

        return (
            <Table
                columns={columns}
                dataSource={dataTable}
                scroll={{ x: 640 }}
                locale={{ emptyText: I18n.get('Sem informação') }}
                footer={() => {
                    let total = dataTable.reduce((acumulador, item, indice, original) => {
                        return acumulador + item.total;
                    }, 0)

                    return <p style={{textAlign: "right", padding: "0 40px"}}><strong>{I18n.get('Total')}:</strong>  <BRL amount={total} /></p>
                }}
            />
        );
    }
}

export default tableScope;
