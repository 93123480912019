import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { I18n } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { PROJECTS_API } from "../../../../store/actions/projects";
import * as S from "./styles";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import { useForm, Controller } from "react-hook-form";
import {
  hasError,
  clearErrorInput,
} from "../../../RegistrationFlow/utils/errorsInput";
import Content from "../../../../components/UI/Content";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { H2Border } from "../../../../components/UI/Titles";
import { Input } from "../../../../components/UI/Input";
import { SelectOne } from "../../../../components/UI/SelectOne";
import Loading from "../../../../components/UI/Loading";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { getProducts } from "../../../../helpers/utils";

import StepsCreateProject from "../components/StepsCreateProject";
import SelectTypeProject from "./components/SelectTypeProject";
import SelectTmarket from "../components/SelectTmarket";
import Main from "../components/Main";
import TopInformation from "../components/TopInformation";

import { Row, Col } from "antd";

function CreateProjectSetp1() {
  const { handleSubmit, control, reset } = useForm();

  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [project, setProject] = useState({});
  const [errorsProject, setErrorsProject] = useState({});
  const [loading, setLoading] = useState(false);
  const [tMarket, setTMarket] = useState(1);
  const [isGlobal, setIsGlobal] = useState(null);
  const [openTmarket, setOpenTmarket] = useState(false);
  const [brandSelected, setBrandSelected] = useState("");
  const [isLoadingSelectBrand, setIsLoadingSelectBrand] = useState(false);
  const [modalErrorCreate, setModalErrorCreate] = useState({
    open: false,
    message: "",
  });

  const state = useSelector((state) => state);

  const dispatch = useDispatch();

  const brands = getProducts(state?.client?.brands || []);

  let history = useHistory();
  let params = useParams();

  const onChangeProductId = async (value) => {
    setIsLoadingSelectBrand(true);
    setBrandSelected(value);
    /* CORRIGIR LOGICA, nao precisa ir no back */

    const selected = brands.find((x) => x.code == value);

    setIsGlobal(selected.global);

    let ids = value.split("-");

    let data = {
      productId: ids[0],
      brandId: ids[1],
    };

    await PROJECTS_API.get_types(data).then((response) => {
      setTypes(response);
    });

    getTmarket(ids[0], ids[1]);
    setIsLoadingSelectBrand(false);
  };

  const onProjectTypeClick = (value) => {
    setSelectedType(value.toString());
  };

  const sendApi = (d) => {
    let id = (brandSelected && brandSelected.split("-")[0]) || null;

    setLoading(true);

    const data = {
      projectName: d.projectName || "",
      productId: id || null,
      projectType: selectedType || null,
      tMarket: tMarket,
    };

    const dataUpdate = {
      projectName: d.projectName || "",
      projectId: params.projectId,
    };

    if (params.projectId) {
      PROJECTS_API.update_project(dataUpdate).then((response) => {
        if (response.projectStep1.success) {
          if (response.projectStep1.data == 2) {
            history.push(`/cliente/projetos/${params.projectId}/passo-2`);
          } else {
            history.push(
              `/cliente/projetos/v2/selecionar-assets/${params.projectId}`
            );
          }

          // setLoading(false);
        } else {
          // setErrorsProject(response.projectStep1.errors);
          setLoading(false);

          const errors = [];
          Object.keys(response.projectStep1.errors).forEach(function (
            key,
            index
          ) {
            errors.push(response.projectStep1.errors[key]);
          });
          setErrorsProject(errors);
        }
      });
    } else {
      PROJECTS_API.create_project(data).then((response) => {
        console.log(">>>", response);

        if (response.projectStep1.success) {
          if (response.projectStep1?.data?.projectType == 2) {
            history.push(
              `/cliente/projetos/${response.projectStep1.data.id}/passo-2`
            );
          } else {
            history.push(
              `/cliente/projetos/v2/selecionar-assets/${response.projectStep1.data.id}`
            );
          }

          // setLoading(false);
        } else {
          // setErrorsProject(response.projectStep1.errors);
          const errors = [];
          Object.keys(response.projectStep1.errors).forEach(function (
            key,
            index
          ) {
            errors.push(response.projectStep1.errors[key]);
          });
          setErrorsProject(errors);
          setLoading(false);
          if (response?.projectStep1?.errors?.Project) {
            setModalErrorCreate({
              open: true,
              message: response?.projectStep1?.errors?.Project?.errorMessage,
            });
          }
        }
      });
    }
  };

  const onSelectMarket = (t) => {
    setTMarket(t);
  };

  const getTmarket = (productId, brandId) => {
    const client = state.client;

    if (!productId || !client || client.brands?.length == 0) return;

    const prod = client.brands
      .map((xx) => xx.products)
      .flat()
      .map((x) => ({
        brandId: x.brandId,
        productId: x.id,
        allowedProjectsTypes: x.allowedProjectsTypes,
        tMarket: x.tMarket,
      }))
      .find((xx) => xx.productId == productId);

    if (!prod) return;

    if (prod.tMarket.includes(1) && prod.tMarket.includes(2)) {
      setOpenTmarket(true);
    } else {
      setTMarket(prod.tMarket[0] || 1);
    }
  };

  useEffect(() => {
    const data = {
      projectId: params.projectId,
    };

    if (params.projectId) {
      PROJECTS_API.get_project(data).then((response) => {
        setSelectedType(
          response?.payload?.project?.data?.projectType.toString()
        );

        onChangeProductId(
          `${response?.payload?.project?.data?.productId}-${response?.payload?.project?.data?.brandId}`
        );

        setProject(response?.payload?.project?.data);
      });
    }
  }, []);

  useEffect(() => {
    reset({
      projectName: project?.projectName,
      productId: project?.productId
        ? `${project?.productId}-${project?.brandId}`
        : "",
    });

    if (project?.tMarket) {
      setOpenTmarket(true);
      setTMarket(project?.tMarket);
    }
  }, [project]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("errorsProject", errorsProject);
  return (
    <>
      <LoggedPage>
        <Content>
          <div className="container">
            <Row>
              <Col sm={12}>
                <H2Border>{I18n.get("Novo Projeto")}</H2Border>
              </Col>
              <Col sm={12} justify="end"></Col>
            </Row>
            <Row>
              <Col sm={24}>
                <S.StyleMain>
                  <StepsCreateProject
                    active={1}
                    disabledTabs={project?.productId ? [] : [2, 3, 4, 5, 6]}
                    projectId={params.projectId || ""}
                  />
                </S.StyleMain>

                <Main bgColor="#fff" padding="30px">
                  <TopInformation>
                    <>
                      <h3>{I18n.get("Informações")}</h3>

                      <p>
                        {I18n.get(
                          "Ótimo! Agora dê um nome ao seu projeto e selecione para qual marca e produto este projeto será desenvolvido."
                        )}
                      </p>
                    </>
                  </TopInformation>

                  <S.StyleForm>
                    <Row>
                      <Col sm={12}>
                        <Controller
                          control={control}
                          name="projectName"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Input
                              required
                              placeholder={I18n.get("Nome")}
                              value={value}
                              onChange={(e) => onChange(e)}
                              error={hasError({
                                field: "projectName",
                                arr: errorsProject,
                              })}
                              clearError={() => {
                                const errorsArr = clearErrorInput({
                                  field: "projectName",
                                  arr: errorsProject,
                                });
                                setErrorsProject(errorsArr);
                              }}
                              onBlur={onBlur}
                            />
                          )}
                        />
                      </Col>
                      <Col sm={12}>
                        <Controller
                          control={control}
                          name="productId"
                          render={({ field }) => (
                            <SelectOne
                              {...field}
                              options={brands}
                              value={brandSelected}
                              required={true}
                              onChange={(value) => onChangeProductId(value)}
                              placeholder={I18n.get("Selecione marca/produto")}
                              error={hasError({
                                field: "productId",
                                arr: errorsProject,
                              })}
                              clearError={() => {
                                const errorsArr = clearErrorInput({
                                  field: "productId",
                                  arr: errorsProject,
                                });
                                setErrorsProject(errorsArr);
                              }}
                              disabled={params.projectId}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </S.StyleForm>

                  {isLoadingSelectBrand ? (
                    <Row>
                      <Col lg={24}>
                        <div>
                          <Loading
                            text={I18n.get("Carregando...")}
                            sizeText={14}
                            sizeIcon={16}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col lg={24}>
                        <SelectTypeProject
                          disabled={params.projectId}
                          projectType={parseInt(selectedType)}
                          types={types || []}
                          onSelectType={onProjectTypeClick}
                          isLoadingSelectBrand={isLoadingSelectBrand}
                        />
                      </Col>
                    </Row>
                  )}

                  {/* {this.state.form.projectType === 0 &&
          this.props.client?.customization?.allowMultipleMarket &&
          this.state.openTmarket && (
          )} */}

                  {selectedType == 0 &&
                    state?.client?.customization?.allowMultipleMarket && (
                      <Row>
                        <Col lg={24}>
                          <SelectTmarket
                            active={project?.tMarket || tMarket}
                            onSelectMarket={onSelectMarket}
                            disabled={params.projectId}
                          />
                        </Col>
                      </Row>
                    )}

                  {isGlobal && (
                    <Row>
                      <Col lg={24}>
                        <Row>
                          <Col lg={24}>
                            <div className="msgGlobal">
                              <h2>{I18n.get("Atenção")}</h2>

                              {state?.client?.customization
                                ?.produtoGlobalMessage && (
                                <p>
                                  {" "}
                                  {
                                    state?.client?.customization
                                      ?.produtoGlobalMessage
                                  }
                                </p>
                              )}

                              {!state?.client?.customization
                                ?.produtoGlobalMessage && (
                                <>
                                  <p>
                                    {I18n.get(
                                      "Esta é uma marca Global. Lembre-se de solicitar a aprovação global para avançar com a agência escolhida"
                                    )}
                                  </p>
                                  <p>
                                    {I18n.get(
                                      "Você deverá anexar a aprovação no momento da escolha do parceiro."
                                    )}
                                  </p>
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col lg={24}>
                      {Object.keys(errorsProject).map((item, index) => {
                        return (
                          <p>
                            <Link>
                              {" "}
                              <span class="icon has-text-danger">
                                <i class="fas fa-exclamation"></i>
                              </span>
                              {errorsProject[item].errorMessage}
                              {/* {I18n.get("Editar")}] */}
                            </Link>
                          </p>
                        );
                      })}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={24}>
                      <S.FooterButtons className="buttonsContainer">
                        <Link to={`/cliente/projetos/v2`}>
                          <ButtonBpool
                            text={I18n.get("Voltar")}
                            theme="secondary"
                            loading={loading}
                            onClick={() => history.push(`/cliente/projetos/v2`)}
                          />
                        </Link>
                        <ButtonBpool
                          text={I18n.get("Próximo")}
                          className=""
                          theme="primary"
                          disabled={!selectedType}
                          onClick={handleSubmit((d) => sendApi(d))}
                          loading={loading}
                        />
                      </S.FooterButtons>
                    </Col>
                  </Row>
                </Main>
              </Col>
            </Row>
          </div>
        </Content>
      </LoggedPage>

      <ModalBp
        visible={modalErrorCreate?.open}
        bodyText={I18n.get("Erro")}
        subBodyText={I18n.get(modalErrorCreate?.message)}
        okText={I18n.get("Fechar")}
        handleClickOk={() => setModalErrorCreate({ open: false, message: "" })}
        onCancel={() => setModalErrorCreate({ open: false, message: "" })}
        isAlert
        isDanger
      />
    </>
  );
}

export default CreateProjectSetp1;
