import React from 'react';
import { I18n } from 'aws-amplify';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
    return {
    };
};

class Menu extends React.Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    componentWillUnmount() {
    }

    render() {

        const className = (this.props.partner && this.props.partner.status == 0) ? 'is-disabled' : '';

        return (
            <div>
                <br />
                <br />
                <aside className="menu">
                    <p className="menu-label">{I18n.get("MENU")}</p>
                    <ul className="menu-list">
                        <li className={className}>
                            <Link to="/parceiro/responsavel" className="is-black">{I18n.get("Usuários")}</Link>
                        </li>
                        <li className={className}>
                            <Link to="/parceiro/empresa" className="is-black">{I18n.get("Empresa")}</Link>
                        </li>
                        <li className={className}>
                            <Link to="/parceiro/especialidade" className="is-black">{I18n.get("Especialidades & Segmentos")}</Link>
                        </li>
                        <li className={className}>
                            <Link to="/parceiro/expertises" className="is-black">{I18n.get("Mercado Expertises")}</Link>
                        </li>
                        <li className={className}>
                            <Link to="/parceiro/trabalhos" className="is-black">{I18n.get("Trabalho & Realizações")}</Link>
                        </li>
                        <li className={className}>
                            <Link to="/parceiro/paises-atendidos" className="is-black">{I18n.get("Clientes fora do país")}</Link>
                        </li>
                        <li className={className}>
                            <Link to="/parceiro/lideranca" className="is-black">{I18n.get("Liderança")}</Link>
                        </li>
                        <li className={className}>
                            <Link to="/parceiro/clientes-referencias" className="is-black">{I18n.get("Clientes & Referências")}</Link>
                        </li>
                        <li className={className}>
                            <Link to="/parceiro/idioma" className="is-black">{I18n.get("Idioma")}</Link>
                        </li>
                    </ul>
                </aside></div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
