import React, { useState, useEffect } from "react";
import * as S from "./styles";
import MainTitle from "../MainTitle/index";
import Button from "../Button";
import FAQIcon from "../../../svg/faq-icon.svg";
import tutorialIcon from "../../../svg/tutorials-icon.svg";
import fluxogramasIcon from "../../../svg/fluxogramas-icon.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import thumbImg from "../../../image/video_thumb.png";
import { I18n } from "aws-amplify";
import downloadIcon from "../../../svg/download-icon.svg";
import xlsIcon from "../../../svg/xls-icon.svg";
import xlsxIcon from "../../../svg/xlsx-icon.svg";
import pdfIcon from "../../../svg/pdf-icon.svg";
import genericIcon from "../../../svg/generic-icon.svg";
import jpgIcon from "../../../svg/jpg-icon.svg";
import pptIcon from "../../../svg/ppt-icon.svg";
import psdIcon from "../../../svg/psd-icon.svg";
import txtIcon from "../../../svg/txt-icon.svg";
import zipIcon from "../../../svg/zip-icon.svg";
import epsIcon from "../../../svg/eps-icon.svg";
import docIcon from "../../../svg/doc-icon.svg";
import docxIcon from "../../../svg/docx-icon.svg";

import { Link } from "react-router-dom";

const TutorialsAndFAQ = ({ auth, tutorials, faqs, fluxogramas }) => {
  const [onView, setOnView] = useState("faqs");
  const half = Math.ceil(fluxogramas.length / 2);
  const [fluxogramas1, setFluxogramas1] = useState([]);
  const [fluxogramas2, setFluxogramas2] = useState([]);

  const getIconExtension = (url) => {
    const index = url.lastIndexOf(".");
    const extension = url.slice(index + 1);

    switch (extension) {
      case "pdf":
        return pdfIcon;
      case "xls":
        return xlsIcon;
      case "xlsx":
        return xlsxIcon;
      case "jpg":
        return jpgIcon;
      case "ppt":
        return pptIcon;
      case "psd":
        return psdIcon;
      case "txt":
        return txtIcon;
      case "zip":
        return zipIcon;
      case "eps":
        return epsIcon;
      case "doc":
        return docIcon;
      case "docx":
        return docxIcon;
      default:
        return genericIcon;
    }
  };

  useEffect(() => {
    if (fluxogramas.length > 12) {
      setFluxogramas1(fluxogramas.slice(0, 6));
      setFluxogramas2(fluxogramas.slice(6, 12));
    } else {
      setFluxogramas1(fluxogramas.slice(0, half));
      setFluxogramas2(fluxogramas.slice(half, fluxogramas.length));
    }
  }, [fluxogramas]);

  return (
    <div>
      <MainTitle text={I18n.get("Processos")} />
      <S.FaqBoard>
        <div>
          <div onClick={() => setOnView("faqs")}>
            <Button
              text={I18n.get("FAQs")}
              icon={FAQIcon}
              isDisabled={onView === "faqs" ? false : true}
              padding="12px 35px"
            />
          </div>
          <div onClick={() => setOnView("tutoriais")}>
            <Button
              text={I18n.get("Tutoriais")}
              icon={tutorialIcon}
              isDisabled={onView === "tutoriais" ? false : true}
              padding="12px 35px"
            />
          </div>
          <div onClick={() => setOnView("fluxogramas")}>
            <Button
              text={I18n.get("Fluxogramas")}
              icon={fluxogramasIcon}
              isDisabled={onView === "fluxogramas" ? false : true}
              padding="12px 35px"
            />
          </div>
        </div>
        {onView === "tutoriais" && (
          <>
            <div className="tutorials">
              {tutorials &&
                tutorials.map((res, index) => (
                  <Link
                    to={
                      auth.is_client
                        ? `/cliente/wikis/${I18n.get("Tutoriais")}`
                        : `/parceiro/wikis/${I18n.get("Tutoriais")}`
                    }
                    key={index}
                  >
                    <img src={thumbImg} />
                    <div>
                      <span>Vídeo</span>
                      <p>{res.title}</p>
                    </div>
                  </Link>
                ))}
              {tutorials.length < 1 && (
                <div>{I18n.get("Nenhum dado a ser exibido")}</div>
              )}
            </div>
            <Button
              text={I18n.get("Ver Mais")}
              href={
                auth.is_client
                  ? `/#/cliente/wikis/${I18n.get("Tutoriais")}`
                  : `/#/parceiro/wikis/${I18n.get("Tutoriais")}`
              }
            />
          </>
        )}
        {onView === "faqs" && (
          <>
            <div className="faq">
              {faqs &&
                faqs.map((res, index) => (
                  <div key={index}>
                    <h2>{res.title}</h2>
                    <p>{res.content}</p>
                  </div>
                ))}
            </div>
            {faqs.length < 1 && (
              <div>{I18n.get("Nenhum dado a ser exibido")}</div>
            )}
            <Button
              text={I18n.get("Ver Mais")}
              href={
                auth.is_client
                  ? `/#/cliente/wikis/${I18n.get("FAQs")}`
                  : `/#/parceiro/wikis/${I18n.get("FAQs")}`
              }
            />
          </>
        )}
        {onView === "fluxogramas" && (
          <>
            <div className="fluxogramas">
              <div>
                {fluxogramas1 &&
                  fluxogramas1.map((res, index) => (
                    <div key={index}>
                      <img
                        className="extension-image"
                        src={getIconExtension(res.content)}
                      />
                      <p>{res.title}</p>
                      <p>{res.date}</p>
                      <a href={res.content} className="download-icon" download>
                        <img src={downloadIcon} />
                      </a>
                    </div>
                  ))}
              </div>
              <div>
                {fluxogramas2 &&
                  fluxogramas2.map((res, index) => (
                    <div key={index}>
                      <img
                        className="extension-image"
                        src={getIconExtension(res.content)}
                      />
                      <p>{res.title}</p>
                      <p>{res.date}</p>
                      <a href={res.content} className="download-icon" download>
                        <img src={downloadIcon} />
                      </a>
                    </div>
                  ))}
              </div>
            </div>
            {fluxogramas.length < 1 && (
              <div>{I18n.get("Nenhum dado a ser exibido")}</div>
            )}
            <Button
              text={I18n.get("Ver Mais")}
              href={
                auth.is_client
                  ? `/#/cliente/wikis/${I18n.get("Fluxogramas")}`
                  : `/#/parceiro/wikis/${I18n.get("Fluxogramas")}`
              }
            />
          </>
        )}
      </S.FaqBoard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(TutorialsAndFAQ));
