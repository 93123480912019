export function localize(error) {

    const i18n = Object.assign({}, error);

    switch (error.code) {

        case "UserNotFoundException":
            i18n.message = 'Login não encontrado.';
            break;

        default:
            break;
    }

    return i18n;
}
