import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { BP_API } from "../../../../../../actions/bp/actions";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import { Menu } from "../../../components/Menu";
import { MenuAdmin } from "../../../../../components/UI/MenuAdmin";
import { verifyStatusPartner } from "../../../../../../utils/verifyStatusPartner";
import { useBp } from "../../../../../hooks/useBp/useBp.hook";
import { PartnerStatus } from "../../../components/PartnerStatus";
import { ChangeMaster } from "../../../components/ChangeMaster";
import { AddListToClient } from "../../../components/AddListToClient";
import { RefreshStatistic } from "../../../components/RefreshStatistic";
import { TableClients } from "../../../components/TableClients";
import { TableReferences } from "../../../components/TableReferences";

import * as S from "./styles";

export const AdministrativeTools = () => {
  const { getClientAddPartnerWorkedWith } = useBp();
  let params = useParams();
  const state = useSelector((state) => state);
  const bp = useSelector((state) => state?.bp);
  const dispatch = useDispatch();
  const [partnerId, setPartnerId] = useState("");
  const [partnerStatus, setPartnerStatus] = useState(null);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [list, setList] = useState([]);

  const statusPartner = async (partnerState) => {
    const status = await verifyStatusPartner(partnerState);
    setPartnerStatus(status);
  };

  const loadList = async () => {
    setIsLoadingList(true);

    const response = await getClientAddPartnerWorkedWith({ id: partnerId });
    if (response?.length > 0) {
      setList(response);
    } else {
      setList([]);
    }

    setIsLoadingList(false);
  };

  const reloadPage = () => {
    loadList();
  };

  useEffect(() => {
    setPartnerId(params.partnerId);
  }, [params]);

  useEffect(() => {
    if (state?.bp?.partner) {
      statusPartner(state?.bp?.partner);
      if (list.length === 0) {
        loadList();
      }
    } else {
      dispatch(BP_API.get_partner(params.partnerId));
    }
  }, [state]);

  const listWorked = list.filter(
    (item) => item.enabled === true && item.code !== ""
  );

  return (
    <LoggedPage>
      <S.SubMenuWrapper>
        <MenuAdmin />
      </S.SubMenuWrapper>
      <div className="hero-body">
        <div className="container">
          <Row style={{ marginTop: 25 }}>
            <Col xs={6}>
              <Menu partnerId={partnerId} />
            </Col>
            <Col xs={18}>
              <S.Wrapper>
                <section className="section">
                  <div className="columns">
                    <div className="column is-12">
                      {partnerStatus ? (
                        <PartnerStatus
                          status={partnerStatus}
                          changePartnerStatus
                          title={I18n.get("Change partner status")}
                        />
                      ) : null}
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <ChangeMaster />
                    </div>
                  </div>

                  {state?.bp.partner?.status === 7 ? (
                    <div className="columns">
                      <div className="column is-12">
                        <AddListToClient
                          list={list}
                          isLoadingList={isLoadingList}
                          reloadPage={reloadPage}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="columns">
                    <div className="column is-12">
                      <TableClients
                        data={listWorked}
                        sectorsFlat={state?.bp?.sectorsFlat || []}
                      />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <RefreshStatistic />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <TableReferences
                        data={state?.bp?.partner?.references || []}
                      />
                    </div>
                  </div>
                </section>
              </S.Wrapper>
            </Col>
          </Row>
        </div>
      </div>
    </LoggedPage>
  );
};
