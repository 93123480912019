import { Auth } from "aws-amplify";

import { AUTH_API } from "./actions";
import { CLIENT_API } from "../client/actions";
import { PARTNER_API } from "../partner/actions";

const api_login = ({ email, password, task }) => {
  return (dispatch) => {
    // if (email && email.length > 0)
    //     email = email.trim().toLowerCase();

    // if (password && password.length > 0)
    //     password = password.trim();

    Auth.signIn(email, password)
      .then((logged_user) => {

        const new_password_required =
          logged_user.challengeName &&
          logged_user.challengeName === "NEW_PASSWORD_REQUIRED";

        console.log("new_password_required", new_password_required);

        if (new_password_required) {
          var userAttributes = logged_user.challengeParam.userAttributes;

          // Precisa deletar o atributo para nao tentar setar novamente
          delete userAttributes["email_verified"];

          logged_user.completeNewPasswordChallenge(password, userAttributes, {
            onSuccess: function (res) {
              dispatch(AUTH_API.login_success(logged_user));
            },
            onFailure: function (err) {
              dispatch(AUTH_API.login_failure(err));
            },
          });
        } else {
          dispatch(AUTH_API.login_success(logged_user));
        }
      })
      .catch((err) => {
        dispatch(AUTH_API.login_failure(err));
      });
  };
};

export default api_login;
