import styled from "styled-components";
import { Modal as ModalAntd, Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div``;

export const TitleContent = styled.div`
  padding: 0 15px;
  margin: 0 0 30px 0;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const Form = styled.div`
  padding-bottom: 90px;
`;

export const BillingData = styled.div``;

export const SubBillingDataTitle = styled.div`
  display: flex;
  align-items: center;

  h4 {
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 8px;
    margin-left: 4px;

    i,
    svg {
      font-size: 22px;
    }
  }
`;

export const BillingDataCheckboxes = styled.div`
  .ant-radio-group {
    label {
      span {
        .ant-radio-inner::after {
          top: 4px;
          left: 4px;
        }
      }
    }
  }
`;

export const SelectCnpj = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
`;

export const CnpjSelectedContent = styled.div`
  margin-top: 32px;

  strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #979797;
  }
`;

export const Modal = styled(ModalAntd)`
  .ant-modal-header {
    border: none;
    padding: 40px;
    .ant-modal-title {
      font-size: 24px;
    }
  }
  .ant-modal-body {
    padding: 0 24px 24px 24px;
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: none;
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-input {
    height: 50px;
    margin-bottom: 30px;

    li {
      height: 100%;
    }
  }
`;
