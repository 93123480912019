import React from 'react';

import { I18n } from 'aws-amplify';


import { Task } from '../../utils/task';

import RadioMultiField from '../form/radio-multi-field';
import TextField from '../form/text-field';
import SubmitButtonField from '../form/submit-button-field';

import TooltipField from '../form/tooltip-field';

import { ClientProjectUniversalQuotationSectionType, ClientProjectUniversalQuotationSectionTypeStrings } from '../../model';

import { Input } from "../../v2/components/UI/Input";
import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";

class QuotationSectionCreateForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            fields: {
                sectionType: {
                    onChange: this.onChangeForm.bind(this, 'sectionType'),
                    successIcon: false,
                    visited: true,
                    isHorizontal: false
                },
                sectionName: {
                    onChange: this.onChangeForm.bind(this, 'sectionName'),
                    successIcon: false,
                    visited: true,
                },
            },
            form: {
            },
            errors: {},
            submit_task: {},
        };

        const project = props.project || {};
        const customEvent = project.customEvent || {};

        const isPromotional = project.isCustomEvent && customEvent && customEvent.isPromotional;

        if (isPromotional){
            this.state.form.sectionType = ClientProjectUniversalQuotationSectionType.GenericItems;
        }
    }

    onChangeForm(prop, e) {

        const value = e.target ? e.target.value : e;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    getData() {

        const quotation = this.props.quotation || {};

        var data = {
            projectId: this.props.match.params.projectId,
            quotationId: quotation.id,
            ...this.state.form
        };

        return data;
    }

    submitForm(e) {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.create_universal_quotation_section(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

                if (task.result) {

                    this.setState({ form: {} });

                    this.props.closeModal();


                }
            });
    }

    render() {

        const project = this.props.project || {};
        const customEvent = project.customEvent || {};

        const isPromotional = project.isCustomEvent && customEvent && customEvent.isPromotional;

        const settings = project.settings || {};

        const section = this.props.section || {};

        let sectionTypes = [
            {
                code: ClientProjectUniversalQuotationSectionType.Assets, label: I18n.get("Asset List do Cliente"), tooltip: I18n.get("Use esta seção para selecionar itens do asset list do cliente")
            },
            {
                code: ClientProjectUniversalQuotationSectionType.Items, label: I18n.get("Itens Externos"), tooltip: I18n.get("Use esta seção para inserir itens de fornecedores")
            },
            {
                code: ClientProjectUniversalQuotationSectionType.GenericItems, label: I18n.get("Itens Livres")
            },
            {
                code: ClientProjectUniversalQuotationSectionType.PercItems, label: I18n.get("Itens Percentuais"), tooltip: I18n.get("Use esta seção para adicionar comissões ou itens %")
            },
            {
                code: ClientProjectUniversalQuotationSectionType.HourItems, label: I18n.get("Itens por Hora"), tooltip: I18n.get("Use esta seção para inserir horas de equipe")
            }
        ];


        if (settings) {

            sectionTypes = sectionTypes.filter((item) => {

                if (item.tooltip) {
                    item.field = (<TooltipField tooltip={item.tooltip} />);
                }

                const typeName = ClientProjectUniversalQuotationSectionTypeStrings[item.code];

                return settings.enabledUniversalQuotationSectionTypes[typeName];
            });
        }

        if (isPromotional){

            sectionTypes = sectionTypes.filter((item) => (item.code == ClientProjectUniversalQuotationSectionType.GenericItems));
        }

        return (
            <div style={{ padding: '1rem' }}>

                <p className="title is-5">{I18n.get("Adicionar Seção")}</p>

                <br />

                <form onSubmit={this.submitForm.bind(this)}>

                    <RadioMultiField
                        {...this.state.fields.sectionType}
                        label={I18n.get('Tipo da Seção')}
                        value={this.state.form.sectionType}
                        error={this.state.errors.sectionType}
                        values={sectionTypes}
                        readOnly={section.id}
                    />

                    <br /><br />

                    {/* <TextField
                        {...this.state.fields.sectionName}
                        label={I18n.get('Nome da Seção')}
                        value={this.state.form.sectionName}
                        error={this.state.errors.sectionName}
                    /> */}

                        <Input
                      placeholder={I18n.get("Nome da Seção")}
                      value={this.state.form.sectionName}
                      onChange={(e) => this.onChangeForm("sectionName", e)}
                      error={this.state.errors.sectionName?.errorMessage}
                    />

                    <br /><br />

                    <SubmitButtonField
                        label={I18n.get("Salvar")}
                        className="button is-black is-rounded is-pulled-right"
                        onClick={this.submitForm.bind(this)}
                        task={this.state.submit_task}
                    />

                    <br /><br />

                </form>

            </div>
        );
    }
}

export default QuotationSectionCreateForm;
