import React, { PureComponent, Fragment } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Tag } from "antd";

import { CardBorder } from "../../components/Cards";
import Carousel from "../../components/Carousel";

import CarouselThumbs from "../../components/CarouselThumbs";
import ListLinks from "../../components/ListLinks";
import noPhoto from "../../../image/no-photo2.png";
import ReactPlayer from "react-player";

import NotRegistered from "../../components/NotRegistered";

import styled from "styled-components";

const Box = styled.div`
  .ant-tag {
    margin-bottom: 10px;
  }

  .noMargin {
    div {
      margin-bottom: 0 !important;
    }
  }

  .TitleView {
    margin-bottom: 40px;
    h3 {
      margin-bottom: 5px;
    }
  }

  .colM {
    margin-bottom: 20px;
  }

  .ant-row-flex {
    margin-bottom: 20px;
  }
  p {
    font-size: 13px;
    line-height: 16px;
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
    border-bottom: solid 1px #ccc;
    margin-bottom: 15px;
  }

  h3 {
    margin: 10px 0 20px;
    font-weight: bold;
  }

  .Sizing {
    width: 100%;
    max-width: 800px;
    margin: 50px auto;
  }
  .ItemImg {
    margin: 0 2px;
    overflow: hidden;
    display: block;
    padding: 2px;
    max-width: 122px;

    img {
      width: calc(100% - 4px);
      display: block;
    }
  }
`;

class ItemAsset extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
    };
  }
  render() {
    const {
      code,
      codeClient,
      enabled,
      label,
      description,
      price,
      price2,
      priceRef,
      unity,
      type,
      specialism,
      partnerSpecialism,
      complexityId,
      complexity,
      complexDesc,
      subDesc,
    } = this.props.item;

    const specialismValues =
      specialism &&
      specialism.map((item) => {
        const specialism =
          (this.props.assetSpecialisms &&
            this.props.assetSpecialisms.find((s) => s.code == item)) ||
          {};
        return specialism;
      });

    const specialismPartnerValues =
      partnerSpecialism &&
      partnerSpecialism.map((item) => {
        const specialism =
          (this.props.partnerSpecialismsArray &&
            this.props.partnerSpecialismsArray.find((s) => s.code == item)) ||
          {};
        return specialism;
      });

    const typesValues =
      type &&
      type.map((item) => {
        const typeItem =
          (this.props.assetTypeArray &&
            this.props.assetTypeArray.find((s) => s.code == item)) ||
          {};
        return typeItem;
      });

    return (
      <Box>
        <Row type="flex">
          <Col className="colM" sm={24}>
            <h3>{label}</h3>
          </Col>
        </Row>

        <Row type="flex">
          <Col className="colM" sm={6}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Cód. Cliente")}</h4>
              <p>{codeClient}</p>
            </CardBorder>
          </Col>

          <Col className="colM" sm={18}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Tipo")}</h4>
              {typesValues &&
                typesValues.map((i, index) => {
                  return (
                    <Tag key={index} color="#000">
                      {i.label}
                    </Tag>
                  );
                })}
            </CardBorder>
          </Col>
        </Row>

        <Row type="flex">
          <Col className="colM" sm={6}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Unidade")}</h4>
              <p>{unity}</p>
            </CardBorder>
          </Col>

          <Col className="colM" sm={6}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Valor")}</h4>
              <p>
                {price.toLocaleString("pt-br", { minimumFractionDigits: 2 }) ||
                  "--"}
              </p>
            </CardBorder>
          </Col>

          <Col className="colM" sm={6}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Valor TM2")}</h4>
              <p>
                {price2.toLocaleString("pt-br", { minimumFractionDigits: 2 }) ||
                  "--"}
              </p>
            </CardBorder>
          </Col>

          <Col className="colM" sm={6}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Valor Baseline")}</h4>
              <p>
                {(priceRef &&
                  priceRef?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })) ||
                  "--"}
              </p>
            </CardBorder>
          </Col>
        </Row>

        <Row type="flex">
          <Col className="colM" sm={24}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Descrição")}</h4>

              <div
                style={{
                  height: "100px",
                  overflow: "auto",
                  paddingRight: "30px",
                }}
              >
                <p>{description}</p>
              </div>
            </CardBorder>
          </Col>
        </Row>
        <Row type="flex">
          <Col className="colM" sm={6}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Subdescrição")}</h4>
              <p>{subDesc}</p>
            </CardBorder>
          </Col>
          <Col className="colM" sm={6}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Complexidade ID")}</h4>
              <p>{complexityId}</p>
            </CardBorder>
          </Col>
          <Col className="colM" sm={6}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Complexidade")}</h4>
              <p>{complexity}</p>
            </CardBorder>
          </Col>
          <Col className="colM" sm={6}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Complexidade descrição")}</h4>
              <p>{complexDesc}</p>
            </CardBorder>
          </Col>
        </Row>
        <Row type="flex">
          <Col className="colM" sm={12}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Especialidades")}</h4>
              {specialismValues &&
                specialismValues.map((i, index) => {
                  return (
                    <Tag key={index} color="#000">
                      {i.label}
                    </Tag>
                  );
                })}
            </CardBorder>
          </Col>

          <Col className="colM" sm={12}>
            <CardBorder Bcolor="#f2f2f2">
              <h4>{I18n.get("Especialidades do parceiro")}</h4>
              {specialismPartnerValues &&
                specialismPartnerValues.map((i, index) => {
                  return (
                    <Tag key={index} color="#000">
                      {i.label}
                    </Tag>
                  );
                })}
            </CardBorder>
          </Col>
        </Row>
      </Box>
    );
  }
}

export default ItemAsset;
