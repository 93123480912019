import React from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import Loading from "../../../../../../components/UI/Loading";
import { FormatCurrency } from "../../../../../../components/UI/FormatCurrency";

import * as S from "./styles";

export const TableBudget = ({ loading, columns, data }) => {
  const auth = useSelector((state) => state.auth);

  const renderText = (text) => {
    if (text === 0) {
      return String(text)
    } else if (text) {
      return String(text)
    } else {
      return "---"
    }
  };

  return (
    <>
      <S.Table>
        <thead>
          <S.TableRow>
            {columns?.map((column, index) => (
              <S.TableHeader key={index}>{column}</S.TableHeader>
            ))}
          </S.TableRow>
        </thead>
        <tbody>
          {loading ? (
            <div style={{ lineHeight: "50px" }}>
              <Loading
                text={I18n.get("Loading...")}
                sizeText={14}
                sizeIcon={16}
                color="#000000"
                align="left"
              />
            </div>
          ) : data?.itemsTable?.length ? data?.itemsTable?.map((item, index) => (
            <React.Fragment key={index}>
              <S.TableRow>
                <S.TableData>{renderText(item?.description)}</S.TableData>
                <S.TableData>{renderText(item?.fee)}</S.TableData>
                <S.TableData>{FormatCurrency({ amount: item?.value, currency: data?.currency })}</S.TableData>
              </S.TableRow>
            </React.Fragment>
          )) : (<div>{I18n.get("No record to display")}</div>)}
          <S.TableRow lastRow>
            <S.TableData>
              <strong>
                {renderText("Saldo")}
              </strong>
            </S.TableData>
            <S.TableData>
              <strong />
            </S.TableData>
            <S.TableData>
              <strong>
                {FormatCurrency({ amount: data?.balance, currency: data?.currency })}
              </strong>
            </S.TableData>
          </S.TableRow>
        </tbody>
      </S.Table>
    </>
  );
};
