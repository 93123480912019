import styled from "styled-components";
import { Table as TableAntd } from "antd";

export const Table = styled(TableAntd)`
  margin-top: 32px;

  .ant-table-thead > tr > th {
    background: #000000;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    vertical-align: middle;
  }

  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters {
    :hover {
      background: #222;
    }
  }
`;

export const RowTab = styled.div`
  ${(props) => (props?.widthRow ? `width: ${props?.widthRow}px;` : null)};
  display: flex;
  align-items: center;
  color: #000000;
`;

export const RowElipsis = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonAdd = styled.button`
  position: relative;
  z-index: 99;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #1883ff;
  border-radius: 8px;
  padding: 8px;
  span {
    font-size: 12px;
    color: #1883ff;
    font-weight: 600;
  }
  :hover {
    background-color: #1883ff;
    border-color: #1883ff;
    span {
      color: #ffffff;
    }
  }
`;

export const InfosNFSe = styled.div``;

export const InvoicesList = styled.div`
  max-width: 70%;
  display: grid;
  grid-template-columns: 40px 1fr 1fr;
  column-gap: 16px;

  ul {
    margin-bottom: 16px;
    li {
      display: flex;
      align-items: center;

      label {
        font-weight: 600;
      }
      p {
        margin-left: 5px;
      }
    }
  }
`;

export const IconNF = styled.div``;

export const DrawerInfos = styled.div`
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const DrawerTitleContent = styled.div`
  padding: 0 15px;
  margin: 0 0 30px 0;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const ErrorMsg = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;

export const LinkNewAcc = styled.div`
  margin-bottom: 32px;

  button {
    cursor: pointer;
    border: none;
    padding: 0;
    background: transparent;
    color: #1883ff;
    text-decoration: underline;
    font-size: 14px;
  }
`;

export const RadioAccountOrBoleto = styled.div`
  padding: 0 15px;
  margin: 0 0 15px 0;

  .ant-radio-inner::after {
    top: 4px;
    left: 4px;
  }
`;
