import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '../../image/logoWOtext.svg';

import '../../App.css';
import '../../Site.css';


import { AUTH_API } from '../../actions/auth/actions';

import Footer from './footer';

const mapStateToProps = state => {
    return {
        user: state.user,
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(AUTH_API.logout());
        }
    };
};

class LandingPageClean extends Component {

    constructor() {
        super();
        this.state = {};
    }

    onLogoutClick(e) {
        e.preventDefault();

        this.props.logout();
    }

    openNav() {
        document.getElementById("myNav").style.height = "100%";
    }

    closeNav() {
        document.getElementById("myNav").style.height = "0%";
    }

    render() {


        return (
            <div>
                <section className={`hero logged-page ${this.props.noFullHeight ? '' : 'is-fullheight'}`}>
                    <div className={`hero-head ${this.props.noFixed ? '' : 'is-fixed'}`}>

              
                        <nav className="navbar is-main navbar-main">
                            <div className="container">
                            
                                <div id="navbarMenu" className="navbar-menu navbar-menu-new is-active">
                                    <div className="navbar-start">
                                        {/* <TopMenu {...this.props} />  */}
                                    </div>
                                    <div className="navbar-logo">
                                        <div className="navbar-item">
                                            <a href="/"><img src={logo} alt="BPool" className="logo"/></a>
                                        </div>
                                    </div>

                                    <div className="navbar-end" style={{ zIndex: 999 }}>
                                        {/* <TopMenuSignup {...this.props} /> */}
                                    </div>
                                </div>
                            </div>
                        </nav>

                        {/* <nav className="navbar is-main navbar-main">
                            <div className="container">
                                <div className="navbar-brand is-expanded">
                                    <a className="navbar-item" href="/">
                                        <img src={logo} alt="BPool" />
                                    </a>
                                    <span className="navbar-burger burger" data-target="navbarMenu">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                </div>
                                <div id="navbarMenu" className="navbar-menu is-active">
                                    <div className="navbar-start">
                                        <div className="navbar-item">
                                        </div>
                                    </div>

                                    <div className="navbar-end" style={{ zIndex: 999 }}>
                                        <TopMenuSignup {...this.props} />
                                    </div>
                                </div>
                            </div>
                        </nav> */}
                        {/* <SubMenu {...this.props} /> */}
                    </div>
                    <div className="" style={{padding:"70px 0 0 0"}}>
                        {this.props.children}
                    </div>

                </section>

                <Footer {...this.props} />

            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingPageClean);
