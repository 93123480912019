import styled from 'styled-components';
export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const TitleSection = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #010202;
  margin-bottom: 20px;
`;

export const DescriptionForm = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #010202;
  margin-bottom: 20px;
`;

export const WrapperItem = styled.div`
  margin: 30px 0 0 0;

  .ant-collapse-extra {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const ItemFormEmpty = styled.div`
  font-size: 14px;
`;

export const ItemLabel = styled.div`
  font-weight: bold;
`;

export const ItemValue = styled.div``;

export const ContentItem = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const ItemInfo = styled.div``;

export const ErroSendPage = styled.div`
  color: #fff;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  background: #ff0000;
  border-radius: 4px;

  strong {
    color: #fff;
  }
`;

export const StatusDiv = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const CirclePending = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #ff0000;
`;

export const CircleConcluded = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #4fbf89;
`;

export const HandleResend = styled.div`
  margin-left: 6px;
`;

export const StatusText = styled.div`
  margin-left: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgb(0 0 0 / 65%);
  top: 1.5px;
  position: relative;
`;
