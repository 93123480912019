import React from 'react';

import * as S from './styles';

const Checkbox = ({
  checkboxSize,
  onChangeCb,
  id,
  labelCheckbox,
  fontSize,
  checkboxMarginTop,
  checked,
}) => {
  return (
    <S.Checkbox
      onChange={(e) => onChangeCb(e, id)}
      checkboxSize={checkboxSize}
      fontSize={fontSize}
      checkboxMarginTop={checkboxMarginTop}
      checked={checked}
    >
      {labelCheckbox}
    </S.Checkbox>
  );
};

export default Checkbox;
