import { I18n } from "aws-amplify";

export const nonCompetePT = [
  {
    code: -1,
    label: I18n.get("Nenhum"),
  },
  {
    code: 0,
    label: I18n.get("Durante o projeto"),
  },
  {
    code: 30,
    label: I18n.get("Durante o projeto + 30 dias"),
  },
  {
    code: 60,
    label: I18n.get("Durante o projeto + 60 dias"),
  },
  {
    code: 90,
    label: I18n.get("Durante o projeto + 90 dias"),
  },
];

export const nonCompeteEN = [
  {
    code: -1,
    label: I18n.get("None"),
  },
  {
    code: 0,
    label: I18n.get("During the project"),
  },
  {
    code: 30,
    label: I18n.get("During the project + 30 days"),
  },
  {
    code: 60,
    label: I18n.get("During the project + 60 days"),
  },
  {
    code: 90,
    label: I18n.get("During the project + 90 days"),
  },
];
