import React from "react";
import { NavLink } from "react-router-dom";
import * as S from "./styles";

export const BoxContent = ({ children, menuTitle, menu = [] }) => {
  return (
    <S.Wrapper hasMenu={menu?.length ? true : false}>
      {menu?.length ? (
        <S.MenuWrapper>
          <h3>{menuTitle}</h3>
          <ul>
            {menu?.map((item, index) => (
              <li key={index}>
                <NavLink to={item?.url}>
                  <span>{`${item?.key}. ${item?.label}`}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </S.MenuWrapper>
      ) : null}
      <S.Content hasMenu={menu?.length ? true : false}>{children}</S.Content>
    </S.Wrapper>
  );
};
