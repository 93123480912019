import styled from "styled-components";
import dateIcon from '../../../svg/calendar-icon-gray.svg';
import clockIcon from '../../../svg/clock-icon-gray.svg';
import profileIcon from '../../../svg/profile-icon-gray.svg';

export const DivInfos = styled.div`
   display: flex;
   flex-direction: row;
   gap: 10px;
   font-size: 14px;
   line-height: 24px;
   color: #979797;
`;

export const DivContentDate = styled.p`
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      background-image: url(${dateIcon});
      display: inline-flex;
      margin-right: 4px;
      bottom: -3px;
      position: relative;
    }
    &:after {
      content: '';
      width: 1px;
      height: 18px;
      display: inline-flex;
      background-color: #979797;
      margin-left: 15px;
      bottom: -2px;
      position: relative;
    }
`;

export const DivContentTime = styled.p`
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      background-image: url(${clockIcon});
      display: inline-flex;
      margin-right: 4px;
      bottom: -3px;
      position: relative;
    }
    &:after {
      content: '';
      width: 1px;
      height: 18px;
      display: inline-flex;
      background-color: #979797;
      margin-left: 15px;
      bottom: -2px;
      position: relative;
    }
`;

export const DivContentAuthor = styled.p`
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      background-image: url(${profileIcon});
      display: inline-flex;
      margin-right: 4px;
      bottom: -3px;
      position: relative;
    }
`;