import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { PARTNER_API } from '../../actions/partner/actions';

import { ClientProjectStatus } from '../../model';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import QuotationForm from './quotation-form';

import ProjectMessaging from '../shared/_project-messaging';

import Steps from './steps';

import { scrollToTop } from '../../utils/ui';

const mapStateToProps = state => {
    return Object.assign({}, state, state.partner);
};

const mapDispatchToProps = dispatch => {
    return {
        get_partner: data => {
            return dispatch(PARTNER_API.get(data));
        },
    }
}


class ProjectDefintionStep5 extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount() {

        var data = {
            projectId: this.props.match.params.projectId
        };

        this.props.get_project(data);

        scrollToTop();
    }

    submitForm(e) {

    }

    render() {

        const { partner = {}, project = {}, auth = {} } = this.props;

        const isSelectedPartner = project.selectPartner && project.selectPartnerId == partner.partnerId;

        const invitation = (partner.projectInvitations && partner.projectInvitations.find((item) => item.projectId == this.props.match.params.projectId)) || {};

        const disableQuotation = invitation.expired === true || invitation.accepted === false || isSelectedPartner;

        const quotation = this.props.eventQuotations && this.props.eventQuotations.find((item) => item.id == this.props.partner.id);

        const userMessages = partner.userMessages && partner.userMessages.filter((item) => item.projectId == this.props.match.params.projectId);

        if (project && project.status == ClientProjectStatus.DefinitionsSet) {
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/detalhamento-obrigado`} />);
        }

        if (auth.is_partner && project && project.partnerHasSubmittedDefinitions) {
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/detalhamento-obrigado`} />);
        }

        return (
            <FillForm>
                <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-6">
                                <p className="title">{I18n.get("Parceiro Convidado")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-6">
                                <Steps active={4} projectId={this.props.match.params.projectId} disabled={disableQuotation ? [3] : []} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1" style={{ backgroundColor: '#F7F7F7' }}>

                    <div className="container client-container-1">

                        <br />
                        <br />

                        <div className="columns">

                            <div className="column is-10">

                                <p className="title">{I18n.get("Mensagens")}</p>

                                <ProjectMessaging {...this.props} userMessages={userMessages} recipientId={this.props.project && this.props.project.clientId} />

                            </div>
                        </div>

                    </div>

                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(connect(mapStateToProps, mapDispatchToProps)(ProjectDefintionStep5));
