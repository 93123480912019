import list_messages from './list_messages';
import receive_message from './receive_message';
import receive_notification from './receive_notification';
import send_message from './send_message';
import reset_notification from './reset_notification';
import mark_notification from './mark_notification';
import send_ping from './send_ping';
import send_chat_message from './send_chat_message';
import update_status from './update_status';
import chat_topics from './chat_topics';
import receive_chat_topics from './receive_chat_topics';
import chat_topic_all from './chat_topic_all';
import receive_chat_messages from './receive_chat_messages';
import receive_new_chat_message from './receive_new_chat_message';

export const CHAT_NOTIFICATION = 'chat_notification';
export const CHAT_NOTIFICATION_RESET = 'chat_notification_reset';
export const CHAT_NOTIFICATION_MARK = 'chat_notification_mark';
export const CHAT_MESSAGE = 'chat_message';
export const CHAT_UPDATE = 'chat_update';
export const CHAT_PING = 'chat_update';
export const CHAT_LIST = 'chat_list';
export const CHAT_UPDATE_STATUS = 'chat_update_status';
export const CHAT_TOPICS = 'CHAT_TOPICS';
export const CHAT_TOPIC_MESSAGES = 'CHAT_TOPIC_MESSAGES';
export const CHAT_TOPIC_NEW_MESSAGE = 'CHAT_TOPIC_NEW_MESSAGE';
export const RESET_STATE = 'RESET_STATE';

export const CHAT_API = {
  list_messages: list_messages,
  receive_message: receive_message,
  receive_notification: receive_notification,
  send_message: send_message,
  reset_notification: reset_notification,
  mark_notification: mark_notification,
  send_ping: send_ping,
  send_chat_message: send_chat_message,
  update_status: update_status,
  chat_topics: chat_topics,
  receive_chat_topics: receive_chat_topics,
  chat_topic_all: chat_topic_all,
  receive_chat_messages: receive_chat_messages,
  receive_new_chat_message: receive_new_chat_message,
};
