import { OPEN_QUICK_MEETING, CLOSE_QUICK_MEETING } from '../../actions';

const initialState = {
  quickMeeting: {
    open: false,
    projectId: null,
    partnerId: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_QUICK_MEETING:
      const newStateOpenQuickMeeting = {
        ...state,
        open: true,
        projectId: action.payload.projectId,
        partnerId: action.payload.partnerId,
      };
      return newStateOpenQuickMeeting;
    case CLOSE_QUICK_MEETING:
      const newStateCloseQuickMeeting = {
        ...state,
        open: false,
        projectId: null,
        partnerId: null,
      };
      return newStateCloseQuickMeeting;
    default:
      return state;
  }
};

export default reducer;
