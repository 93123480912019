import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PROJECT_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_types = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            // /Client/GetProjectTypeAllowed?brandId=5f5ed05c5666750002599999&productId=5f5ed062566675000259999a'

            var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');

            return axios.get(`${Config.API_BASE_URL}/Client/GetProjectTypeAllowed?${queryString}`, config)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return false;
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_types;