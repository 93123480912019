import styled from 'styled-components';
import { Checkbox } from 'antd';

const CheckboxStyled = styled(Checkbox)`
  margin-top: ${(props) =>
    props.checkboxMarginTop ? `${props.checkboxMarginTop}px;` : '20px;'};

  span {
    font-size: ${(props) =>
      props.fontSize ? `${props.fontSize}px;` : '16px;'};
    color: #000;
    opacity: 1;
  }

  span.ant-checkbox-checked {
    span {
      background-color: #1890ff;
      border-color: #1890ff;
    }
  }

  span {
    .ant-checkbox-inner {
      border-radius: 50%;
      width: ${(props) =>
        props.checkboxSize ? `${props.checkboxSize}px;` : '24px;'};
      height: ${(props) =>
        props.checkboxSize ? `${props.checkboxSize}px;` : '24px;'};
      display: flex;
      align-items: center;
      justify-content: center;

      ::after {
        width: ${(props) =>
          props.checkboxSize
            ? `${props.checkboxSize / 2.78}px;`
            : `${24 / 2.78}px;`};
        height: ${(props) =>
          props.checkboxSize
            ? `${props.checkboxSize / 1.75}px;`
            : `${24 / 1.75}px;`};

        left: auto;
        margin-left: ${(props) =>
          props.checkboxSize
            ? `-${props.checkboxSize / 10}px;`
            : `-${24 / 10}px;`};
        margin-top: ${(props) =>
          props.checkboxSize
            ? `-${props.checkboxSize / 20}px;`
            : `-${24 / 20}px;`};
      }
    }
  }
`;

export { CheckboxStyled as Checkbox };
