import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div``;

export const TitleDrawer = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  padding: 32px 0;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 32px;

  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ErrorMsg = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;

export const RadioGroups = styled.div`
  margin: 16px 0;

  .ant-radio-inner::after {
    top: 4px;
    left: 4px;
  }
`;

export const Info = styled.div`
  label {
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 600;
  }
  p {
    line-height: 20px;
    margin-bottom: 8px;
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-infos {
    height: 20px;
    margin-bottom: 8px;

    li {
      height: 100%;
    }
  }
`;
