import React from "react";
import { I18n } from "aws-amplify";
import { withRouter } from "react-router-dom";

import { Task } from "../../utils/task";

import PartnerCard from "../partner/shared/_partner-card";
import PartnerFullCard from "../partner/shared/_partner-full-card";

import { Skeleton } from "antd";

import VideoCallIcon from "../../svg/icon-videocall.svg";
import ChatIcon from "../../svg/icon-chat.svg";

import Chat from "../client/chat-modal-client";
import NakedModal from "../ui/naked-modal";
import SimpleModal from "../ui/simple-modal";
import PartnerMessaging from "../shared/_partner-messaging";
import PartnerScheduling from "../shared/partner-scheduling";

import SubmitButtonField from "../form/submit-button-field";

import { scrollToTop } from "../../utils/ui";

import GlobalFileBox from "../../v2/pages/Client/CreateProject/components/GlobalFileBox";

import Config from "../../config";

class PartnersForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {
        isSubmit: false,
        partnerId: "",
      },
      messaging: false,
      scheduling: false,
      confirmation: false,
      deleteConfirmation: false,
      success: false,
      chatIsOpen: false,
      error: false,
      task: {},
      meetings: [],
      partnersSelected: [],
      activeProject: false,
      activePartner: false,
      loadingCards: false,
      loadingButtonSend: false,
    };
  }

  componentDidMount() {
    if (this.props.location.search == "?m=1") {
      // alert("m1");
      setTimeout(() => {
        this.setState({ scheduling: true });
      }, 1000);
    }
    if (this.props.location.search == "?c=1") {
      // alert("c1");
      setTimeout(() => {
        this.setState({ chatIsOpen: true });
      }, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.project && this.props.project.task;

    if (!prevState) return;
    if (!task) return;

    var nextState = Object.assign({}, prevState);

    if (task.id != prevState.task.id) {
      nextState.task = task;

      this.setState(nextState);

      if (task.result) {
        this.props.history.push(
          `/cliente/projetos/${this.props.projectId}/parceiro-passo-2`
        );
      }
    }
  }

  componentWillUnmount() {}

  showDetails(item, e) {
    this.setState({ detailsView: item });
    scrollToTop();
  }

  hideDetails(e) {
    e.preventDefault();

    this.setState({ detailsView: null });
  }

  showScheduling(e) {
    if (e) e.preventDefault();

    this.setState({ scheduling: true });
  }

  showMessaging(e) {
    if (e) e.preventDefault();

    this.setState({ messaging: true });
  }

  showSuccess() {
    this.hideAllModal();
    this.setState({ success: true });
  }

  showConfirmation() {
    this.hideAllModal();
    this.setState({ confirmation: true });
  }

  showChat(partner) {
    this.setState({
      activePartner: {
        partnerId: partner.partnerId,
        partnerName: partner.name,
        logo: "",
      },
      activeProject: {
        projectId: this.props.projectId,
        projectName: this.props.project.name,
      },
      chatIsOpen: true,
    });
  }

  handleInvite() {
    this.setState({ loadingButtonSend: true });

    const data = {
      invites: this.state.partnersSelected,
      projectId: this.props.projectId,
    };
    this.props
      .createMeetingInvite(data)
      .then((response) => {
        if (response.errors && Object.keys(response.errors).length !== 0) {
          this.setState({
            confirmation: false,
            error: true,
            loadingButtonSend: false,
          });
        } else {
          this.setState({
            confirmation: false,
            success: true,
            loadingButtonSend: false,
          });
        }
      })
      .catch(({ error }) => {
        this.setState({ error: true, loadingButtonSend: false });
      });
  }

  hideMessaging(e) {
    if (e) e.preventDefault();

    this.setState({ messaging: null });
  }

  hideChat(e) {
    if (e) e.preventDefault();

    this.setState({ chatIsOpen: null });
  }

  hideConfirmation() {
    this.setState({ confirmation: false });
  }

  hideScheduling(e) {
    if (e) e.preventDefault();

    this.setState({ scheduling: null });
  }

  hideDeleteConfirmation() {
    this.setState({ deleteConfirmation: false });
  }

  hideSuccess() {
    this.setState({ success: false });
  }
  hideError() {
    this.setState({ error: false });
  }
  hideAllModal() {
    this.hideScheduling();
    this.hideConfirmation();
    this.hideDeleteConfirmation();
    this.hideSuccess();
    this.hideError();
  }

  handleDelete() {
    this.setState({ loadingButtonSend: true });

    this.state.meetings.forEach((meeting) => {
      const data = {
        projectId: this.props.projectId,
        meetingId: meeting.id,
      };
      this.props.delete_meeting(data).then((response) => {
        if (response.errors && Object.keys(response.errors).length !== 0) {
          this.setState({
            deleteConfirmation: false,
            error: true,
            loadingButtonSend: false,
          });
        } else {
          this.setState({
            deleteConfirmation: false,
            scheduling: true,
            loadingButtonSend: false,
          });
        }
      });
    });
  }

  selectPartner(item) {
    this.setState({ partnerId: item.partnerId }, function () {
      var task = new Task();

      task.start();

      this.setState({ task: task }, function () {
        this.props.save(this.getData());
      });
    });
  }

  getData() {
    var data = {
      clientId: this.props.clientId,
      ...this.props.project,
    };

    if (this.state.partnerId) {
      data["partnerId"] = this.state.partnerId;
    }

    return data;
  }

  submitForm(e) {}

  saveGlobal = async (data) => {
    const dataSend = {
      ...data,
      projectId: this.props.match.params.projectId,
    }
    const response = await this.props.update_global(dataSend);



    var data = {
      clientId: this.props.client.clientId,
      projectId: this.props.match.params.projectId,
    };

    await this.props.get_project(data);
  }

  render() {
    let msgs = Config.showChat || Config.showMeet;

    
    const brandSelected = this.props.client?.brands?.find(
      (b) => b.id === this.props?.brandId
      );
      
      const productSelected = brandSelected?.products?.find(
        (p) => p.id === this.props.productId
        );

        


    return (
      <form className="partnersApproval">
        {!this.state.detailsView && (
          <div>
            <h1 className="title title-1">
              {I18n.get(
                "Olha que legal. Nosso algoritmo fez o matching e encontrou os parceiros abaixo."
              )}
            </h1>

            {msgs && (
              <>
                <p style={{ fontWeight: "bold" }}>
                  {I18n.get("Passo 1: Chemistry Meetings")}
                </p>
                <p style={{ display: "flex", alignItems: "center" }}>
                  {I18n.get(
                    "Navegue pelo perfil dos parceiros e agende videocalls de chemistry meetings através do botões de agendamento"
                  )}{" "}
                  &nbsp;{" "}
                  <img
                    src={VideoCallIcon}
                    className=""
                    style={{ height: "25px" }}
                  />{" "}
                  &nbsp; {I18n.get("ou_o_or")} {I18n.get("chat")} &nbsp;{" "}
                  <img src={ChatIcon} style={{ height: "25px" }} />
                </p>

                <br />
                <p style={{ fontWeight: "bold" }}>
                  {I18n.get("Passo 2: Selecione seu parceiro")}
                </p>
                <p>
                  {I18n.get(
                    "Quando terminar, selecione seu parceiro e faça uma breve avaliação do perfil dos parceiros exibidos no matching."
                  )}
                </p>
              </>
            )}

            {msgs && (
              <div>
                <br />
                <div className="alert-bpool">
                  <p>
                    <strong>{I18n.get("Chemistry meetings?")}</strong>{" "}
                    {I18n.get(
                      "Uma oportunidade de conversa como os líderes por trás de cada parceiro sobre seu desafio, categoria e marca. Tão importante quanto a criatividade é encontrar o parceiro certo para o seu projeto em termos de perfil, portfólio, tamanho, equipe, afinidade e interesse em sua marca."
                    )}
                  </p>
                </div>
              </div>
            )}



          
          </div>
        )}

        <br />
{/* AQUI */}

        {this.props.projectType === 0 && productSelected && productSelected?.global && 
          <GlobalFileBox 
          title={I18n.get("Atenção!")}
          description={I18n.get("Esta é uma marca Global. Anexe aqui a aprovação de global para este projeto.")}
          username={this.props.auth.username}
          action={this.saveGlobal}
          file={this.props.globalAuthorization}
          /> 
        }

        {!this.props.partners2 && (
          <div className="columns is-multiline">
            <div className="column is-one-third">
              <div>
                <Skeleton active paragraph={{ rows: 7 }} />
              </div>
            </div>

            <div className="column is-one-third">
              <Skeleton active paragraph={{ rows: 7 }} />
            </div>

            <div className="column is-one-third">
              <Skeleton active paragraph={{ rows: 7 }} />
            </div>
          </div>
        )}

        {!this.state.detailsView && (
          <>
            <div className="columns is-multiline">
              {this.props.partners2 &&
                this.props.partners2.map((item) => {
                  return (
                    <div key={item.id} className="column is-one-third">
                      <PartnerCard
                        {...item}
                        isSelected={item.partnerId == this.props.partnerId}
                        showDetails={() => this.props.getPartner(item)}
                        selectPartner={this.selectPartner.bind(this, item)}
                        task={this.state.task}
                        bp={this.props.bp}
                        isSelectedPartner={true}
                        isMiniThumb={true}
                        showScheduling={this.showScheduling.bind(this)}
                        showChat={() => this.showChat(item)}
                        scheduling={this.state.scheduling}
                        isChat={true}
                        noSite={true}
                        isListPartner={true}
                        isDisabled={this.props.projectType === 0 && productSelected?.global && !this.props.globalAuthorization}
                      />
                    </div>
                  );
                })}
            </div>

            {/* <div style={{ marginTop: '5rem', display: 'flex', justifyContent: 'center' }}>

                        <button className="button is-black" onClick={this.showMessaging.bind(this)}>{I18n.get("ENVIAR MENSAGEM")}</button>

                </div> */}
          </>
        )}

        {this.state.detailsView && (
          <div>
            <div
              style={{
                marginBottom: "1rem",
                position: "fixed",
                top: "150px",
                right: "20px",
              }}
            >
              <a
                onClick={this.hideDetails.bind(this)}
                class="delete is-large"
              ></a>
            </div>

            <PartnerFullCard
              isMiniThumb={false}
              {...this.state.detailsView}
              selectPartner={this.selectPartner.bind(
                this,
                this.state.detailsView
              )}
              task={this.state.task}
            />

            <div style={{ marginTop: "1rem" }}>
              <a href="" onClick={this.hideDetails.bind(this)}>
                {I18n.get("< voltar")}
              </a>
            </div>
          </div>
        )}

        <NakedModal
          isOpen={this.state.messaging}
          onCloseModal={this.hideMessaging.bind(this)}
        >
          <a
            onClick={() => this.setState({ messaging: false })}
            className="button is-black"
            style={{ float: "right" }}
          >
            X
          </a>

          <PartnerMessaging
            {...this.props}
            onClose={this.hideMessaging.bind(this)}
          />
        </NakedModal>

        <Chat
          isOpen={this.state.chatIsOpen}
          onCloseModal={this.hideChat.bind(this)}
          activeProject={this.state.activeProject}
          activePartner={this.state.activePartner}
          clearActiveChat={() =>
            this.setState({ activeProject: false, activePartner: false })
          }
        ></Chat>

        <SimpleModal
          className="is-round overflow"
          isOpen={this.state.scheduling}
          onCloseModal={this.hideScheduling.bind(this)}
          size="large"
        >
          <PartnerScheduling
            partners={this.props.partners2 && this.props.partners2}
            onClose={this.hideScheduling.bind(this)}
            onSuccess={this.showSuccess.bind(this)}
            onConfirmation={this.showConfirmation.bind(this)}
            onDeleteConfirmation={() =>
              this.setState({ scheduling: false, deleteConfirmation: true })
            }
            setMeetings={(meetings) => {
              if (meetings) this.setState({ meetings });
            }}
            setPartnersSelected={(partners) => {
              if (partners) this.setState({ partnersSelected: partners });
            }}
            partnerIdsOptedInProp={
              this.props?.project?.partnerIdsOptedIn &&
              this.props?.project?.partnerIdsOptedIn.length > 0
                ? this.props?.project?.partnerIdsOptedIn
                : this.props?.project?.acceptedPartnerIds
            }
          />
        </SimpleModal>

        <NakedModal
          className="is-round overflow"
          isOpen={this.state.success}
          size="large"
        >
          <a
            onClick={() => this.setState({ success: false })}
            className="button outside-modal-naked"
          >
            {I18n.get("Fechar X")}
          </a>
          <div
            style={{ padding: "0", borderRadius: "10px", overflow: "hidden" }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                padding: "2% 10%",
                height: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>
                {I18n.get("Convite enviado com sucesso.")}
                <br />
                {I18n.get("Por favor aguarde confirmação dos parceiros.")}
                <br />
              </h1>
            </div>
          </div>
        </NakedModal>

        <NakedModal
          className="is-round overflow"
          isOpen={this.state.error}
          size="large"
        >
          <a
            onClick={() => this.setState({ error: false })}
            className="button outside-modal-naked"
          >
            {I18n.get("Fechar X")}
          </a>
          <div
            style={{ padding: "0", borderRadius: "10px", overflow: "hidden" }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                padding: "2% 10%",
                height: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1 style={{ color: "red" }}>
                {I18n.get(
                  "Algo deu errado no processamento da sua requisição, por favor, tente novamente."
                )}
                <br />
                {I18n.get("Caso o erro persista, contate o suporte.")}
              </h1>
            </div>
          </div>
        </NakedModal>

        <NakedModal
          className="is-round overflow"
          isOpen={this.state.confirmation}
          size="large"
        >
          <a
            onClick={() => this.setState({ confirmation: false })}
            className="button outside-modal-naked"
          >
            {I18n.get("Fechar X")}
          </a>
          <div
            style={{ padding: "0", borderRadius: "10px", overflow: "hidden" }}
          >
            <div className="contentModalCall">
              <h1>
                {I18n.get(
                  "Enviar os seguintes horários de videocall para os parceiros:"
                )}{" "}
                {this.state.partnersSelected &&
                  this.state.partnersSelected
                    .map((partner) => partner.company.name)
                    .join(", ")}
                ?
              </h1>
              <ul>
                {this.state.meetings &&
                  this.state.meetings.map((meeting) => {
                    if (meeting.meetingStatus != 1) {
                      return (
                        <li style={{ listStyle: "none" }} key={meeting.id}>
                          {meeting.meetingDate} {meeting.startTime} -{" "}
                          {meeting.endTime}{" "}
                        </li>
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </ul>
              <SubmitButtonField
                label={I18n.get("Confirmar")}
                onClick={() => this.handleInvite()}
                className="button blue no-webkit"
                task={{ busy: this.state.loadingButtonSend }}
              />
            </div>
          </div>
        </NakedModal>

        <NakedModal
          className="is-round overflow"
          isOpen={this.state.deleteConfirmation}
          size="large"
        >
          <a
            onClick={() =>
              this.setState({ deleteConfirmation: false, scheduling: true })
            }
            className="button outside-modal-naked"
          >
            {I18n.get("Fechar X")}
          </a>
          <div
            style={{ padding: "0", borderRadius: "10px", overflow: "hidden" }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                padding: "2% 10%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1>{I18n.get("Deseja realmente deletar esta meeting?")}</h1>
              <ul>
                {this.state.meetings &&
                  this.state.meetings.map((meeting) => {
                    return (
                      <li style={{ listStyle: "none" }} key={meeting.id}>
                        {meeting.meetingDate} {meeting.startTime} -{" "}
                        {meeting.endTime}{" "}
                      </li>
                    );
                  })}
              </ul>
              <SubmitButtonField
                label={I18n.get("Confirmar")}
                onClick={() => this.handleDelete()}
                className="button blue no-webkit"
                task={{ busy: this.state.loadingButtonSend }}
              />
            </div>
          </div>
        </NakedModal>
      </form>
    );
  }
}

export default withRouter(PartnersForm);
