import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Radio } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRegistrationFlowFull } from "../../../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { usePartners } from "../../../../../../hooks/usePartners/usePartners.hook";
import { useUpload } from "../../../../../../hooks/useUpload/useUpload.hook";
import { Input } from "../../../../../../components/UI/Input";
import { FileUpload } from "../../../../../../components/UI/FileUpload";
import { SelectOne } from "../../../../../../components/UI/SelectOne";
import { ButtonBpool } from "../../../../../../components/UI/ButtonBpool";
import { FormatCurrency } from "../../../../../../components/UI/FormatCurrency";
import { CurrencyInput } from "../../../../../../components/UI/CurrencyInput";
import { InputMask } from "../../../../../../components/UI/InputMask";
import { NotificationBPool } from "../../../../../../components/UI/NotificationBPool";
import {
  hasError,
  clearErrorInput,
} from "../../../../../RegistrationFlow/utils/errorsInput";
import { formatPaymentBeneficiaries } from "./formatPaymentBeneficiaries";
import * as S from "./styles";

const optionsMedia = [
  { label: I18n.get("Sim"), code: true },
  { label: I18n.get("Não"), code: false },
];

export const DrawerInfos = ({ data, handleCloseDrawer, handleReload }) => {
  let params = useParams();
  let history = useHistory();
  const state = useSelector((state) => state);
  const { getBillingData } = useRegistrationFlowFull();
  const { sendInvoicePartnerProject } = usePartners();
  const { uploadFile: uploadFileS3 } = useUpload();
  const [errorsInputs, setErrorsInputs] = useState([]);
  const [inputsForm, setInputsForm] = useState({});
  const [fileNF, setFileNF] = useState({});
  const [slipFile, setSlipFile] = useState({});
  const [paymentBeneficiaries, setPaymentBeneficiaries] = useState([]);
  const [typeBilling, setTypeBilling] = useState(1);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    sending: false,
  });
  const [countryClientOrPartner, setCountryClientOrPartner] = useState("BRA");

  useEffect(() => {
    console.log("state", state);
    if (state?.auth?.is_client_or_client_user) {
      setCountryClientOrPartner(state?.client?.country?.code);
    } else {
      setCountryClientOrPartner(state?.partner?.company?.country?.code);
    }
  }, [state]);

  const handleSetErrorsInputs = (errors) => {
    setErrorsInputs(errors);
  };

  const handleUploadNF = (file) => {
    setFileNF(file);
  };

  const handleUploadSlip = (file) => {
    setSlipFile(file);
  };

  const handleDeleteUploadNF = async (fileOrId) => {
    if (fileOrId?.name) {
      setFileNF({});
    }
  };

  const handleDeleteUploadSlip = async (fileOrId) => {
    if (fileOrId?.name) {
      setSlipFile({});
    }
  };

  const handleSelectedNF = (file) => {
    const errorsArr = clearErrorInput({
      field: "file.Url",
      arr: errorsInputs,
    });
    handleSetErrorsInputs(errorsArr);
    handleUploadNF(file);
  };

  const handleSelectedSlip = (file) => {
    const errorsArr = clearErrorInput({
      field: "slip",
      arr: errorsInputs,
    });
    handleSetErrorsInputs(errorsArr);
    handleUploadSlip(file);
  };

  const handleChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]:
        value !== null || value !== "" || value !== undefined ? value : null,
    });
  };

  const loadBeneficiaries = async () => {
    try {
      setIsLoading({ loading: true, sending: false });
      const response = await getBillingData({ admin: false });
      const arrayPaymentBeneficiaries = formatPaymentBeneficiaries(
        response?.data?.data
      );
      setPaymentBeneficiaries(arrayPaymentBeneficiaries);
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Houve algum erro, tente novamente"),
        duration: 3,
      });
    } finally {
      setIsLoading({ loading: false, sending: false });
    }
  };

  const onCloseDrawer = () => {
    setInputsForm({});
    setFileNF({});
    setSlipFile({});
    handleSetErrorsInputs([]);
    handleCloseDrawer();
  };

  const sendNF = async () => {
    try {
      setIsLoading({ loading: false, sending: true });
      let objSend = {};

      if (typeBilling === 1) {
        objSend = {
          ...inputsForm,
          file: fileNF,
          projectId: params?.projectId,
          billingId: params?.billingId,
          installmentId: data?.invoiceId,
        };
      } else if (typeBilling === 2) {
        objSend = {
          ...inputsForm,
          file: fileNF,
          projectId: params?.projectId,
          billingId: params?.billingId,
          installmentId: data?.invoiceId,
          isSlip: true,
          slip: slipFile,
        };
      }

      if (fileNF?.name) {
        const result = await uploadFileS3({
          file: fileNF,
          idUser: `${state?.auth?.partnerId}`,
          fieldId: `billing-${params?.billingId}-project-${params?.projectId}-files`,
        });

        objSend = {
          ...objSend,
          file: {
            name: result?.name,
            url: result?.url,
            type: result?.type,
            size: fileNF?.size,
            uploadedDate: result?.uploadedDate,
          },
        };
      }
      if (slipFile?.name) {
        const result = await uploadFileS3({
          file: slipFile,
          idUser: `${state?.auth?.partnerId}`,
          fieldId: `billing-${params?.billingId}-project-${params?.projectId}-boleto`,
        });

        objSend = {
          ...objSend,
          slip: {
            name: result?.name,
            url: result?.url,
            type: result?.type,
            size: slipFile?.size,
            uploadedDate: result?.uploadedDate,
          },
        };
      }
      console.log("objSend", objSend);
      const response = await sendInvoicePartnerProject(objSend);
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("NF cadastrada com sucesso."),
        duration: 3,
      });
      onCloseDrawer();
      handleReload();
    } catch (error) {
      setErrorsInputs(error?.response?.data?.errors);
    } finally {
      setIsLoading({ loading: false, sending: false });
    }
  };

  useEffect(() => {
    if (data && !paymentBeneficiaries?.length) {
      loadBeneficiaries();
    }
    console.log("data", data);
  }, [data]);

  const handleGoToAddNewAcc = () => {
    const dataSubirNf = {
      id: data?.invoiceId,
      urlBack: `/parceiro/faturamento/${params?.projectId}/po/${params?.billingId}`,
    };
    localStorage.setItem(
      "@BPOOL-accounts-dataSubirNf",
      JSON.stringify(dataSubirNf)
    );
    history.push(`/partner/edit-profile/billing-data`);
  };

  const handleChangeCheckbox = (e) => {
    setTypeBilling(e.target.value);
  };

  return (
    <S.DrawerInfos>
      <S.DrawerTitleContent style={{ marginTop: 32 }}>
        <h3>{I18n.get("Parcela")}</h3>
      </S.DrawerTitleContent>
      <Row>
        <Col xs={8}>
          <p>
            <strong>{`${I18n.get("Liberação")}: `}</strong>
          </p>
          <div>
            <span>{data?.createdOn}</span>
          </div>
        </Col>
        <Col xs={8}>
          <p>
            <strong>{`${I18n.get("Valor")}: `}</strong>
          </p>
          <FormatCurrency amount={data?.amount} currency={data?.currency} />
        </Col>
        <Col xs={8}>
          <p>
            <strong>{`${I18n.get("Em aberto")}: `}</strong>
          </p>
          <FormatCurrency amount={data?.balance} currency={data?.currency} />
        </Col>
      </Row>
      <S.DrawerTitleContent style={{ marginTop: 32 }}>
        <h3>{I18n.get("Nota Fiscal")}</h3>
      </S.DrawerTitleContent>
      <Row>
        <Col xs={12}>
          <Input
            placeholder={I18n.get("Nº Nota")}
            label
            required
            value={inputsForm?.invoiceNumber}
            onChange={(value) => handleChangeInputForm("invoiceNumber", value)}
            error={hasError({
              arr: errorsInputs,
              field: "invoiceNumber",
            })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "invoiceNumber",
                arr: errorsInputs,
              });
              handleSetErrorsInputs(errorsArr);
            }}
          />
        </Col>
        <Col xs={12}>
          <CurrencyInput
            value={inputsForm?.invoiceAmount}
            onChange={(value) => handleChangeInputForm("invoiceAmount", value)}
            currency={data?.currency}
            placeholder={I18n.get("Valor")}
            error={hasError({
              arr: errorsInputs,
              field: "invoiceAmount",
            })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "invoiceAmount",
                arr: errorsInputs,
              });
              handleSetErrorsInputs(errorsArr);
            }}
          />
        </Col>
        <Col xs={12}>
          <SelectOne
            placeholder={I18n.get("Nota ref. Mídia")}
            options={optionsMedia}
            required
            value={inputsForm?.isMidia}
            onChange={(value) => handleChangeInputForm("isMidia", value)}
            error={hasError({ field: "isMidia", arr: errorsInputs })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "isMidia",
                arr: errorsInputs,
              });
              handleSetErrorsInputs(errorsArr);
            }}
          />
        </Col>
        <Col xs={12}>
          <Input
            placeholder={I18n.get("Retenção IR")}
            addonBefore={<div style={{ color: "#000" }}>%</div>}
            widthLabel="40px"
            typeInput="number"
            label
            required
            value={inputsForm?.tax}
            onChange={(value) => handleChangeInputForm("tax", value)}
            error={hasError({
              arr: errorsInputs,
              field: "tax",
            })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "tax",
                arr: errorsInputs,
              });
              handleSetErrorsInputs(errorsArr);
            }}
          />
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          <h3>{I18n.get("Upload de NFSe")}</h3>
          <FileUpload
            cbFiles={(file) => handleSelectedNF(file)}
            multiple={false}
            initialFiles={[]}
            acceptedTypes={[
              {
                label: "PDF",
                type: "application/pdf",
              },
            ]}
            cbDeleteFile={(fileOrId) => handleDeleteUploadNF(fileOrId)}
          />
          {hasError({
            arr: errorsInputs,
            field: "file.Url",
          }) ? (
            <S.ErrorMsg>
              {hasError({
                arr: errorsInputs,
                field: "file.Url",
              })}
            </S.ErrorMsg>
          ) : null}
        </Col>
      </Row>
      <S.DrawerTitleContent style={{ marginTop: 32 }}>
        <h3>{I18n.get("Beneficiário")}</h3>
      </S.DrawerTitleContent>

      <S.RadioAccountOrBoleto>
        <Radio.Group onChange={handleChangeCheckbox} value={typeBilling}>
          <Radio value={1}>{I18n.get("Conta")}</Radio>
          <Radio value={2}>{I18n.get("Boleto")}</Radio>
        </Radio.Group>
      </S.RadioAccountOrBoleto>

      {typeBilling === 1 ? (
        <>
          <Row>
            <Col xs={24}>
              {paymentBeneficiaries?.length ? (
                <>
                  <p>{I18n.get("Selecione a conta que deseja receber")}</p>
                  <div style={{ height: 80, marginTop: 8 }}>
                    <SelectOne
                      options={paymentBeneficiaries}
                      value={inputsForm?.paymentBeneficiaryId}
                      defaultValue={inputsForm?.paymentBeneficiaryId}
                      loading={isLoading?.loading}
                      onChange={(value) =>
                        handleChangeInputForm("paymentBeneficiaryId", value)
                      }
                      required
                      placeholder={I18n.get("Forma de recebimento")}
                      error={hasError({
                        arr: errorsInputs,
                        field: "paymentBeneficiaryId",
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "paymentBeneficiaryId",
                          arr: errorsInputs,
                        });
                        handleSetErrorsInputs(errorsArr);
                      }}
                    />
                  </div>
                </>
              ) : (
                <p>
                  {I18n.get(
                    "Atenção: A conta deve ser jurídica e ser da mesma titularidade da razão social"
                  )}
                </p>
              )}
              <S.LinkNewAcc>
                <button onClick={handleGoToAddNewAcc}>
                  {I18n.get(
                    paymentBeneficiaries?.length
                      ? "Adicionar nova forma de recebimento"
                      : "Adicionar forma de recebimento"
                  )}
                </button>
              </S.LinkNewAcc>
            </Col>
          </Row>
          {hasError({
            arr: errorsInputs,
            field: "paymentBeneficiaryId",
          }) ? (
            <S.ErrorMsg
              style={{ margin: 0, position: "relative", top: "-20px" }}
            >
              {hasError({
                arr: errorsInputs,
                field: "paymentBeneficiaryId",
              })}
            </S.ErrorMsg>
          ) : null}
        </>
      ) : (
        <Row>
          <Col xs={12}>
            {countryClientOrPartner === "BRA" ? (
              <InputMask
                id="paymentBeneficiaryId"
                label={I18n.get("Documento do Beneficiário")}
                defaultValue={inputsForm?.paymentBeneficiaryId}
                onChange={(value) =>
                  handleChangeInputForm("paymentBeneficiaryId", value?.value)
                }
                mask="##.###.###/####-##"
                required
                error={hasError({
                  arr: errorsInputs,
                  field: "paymentBeneficiaryId",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "paymentBeneficiaryId",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            ) : (
              <Input
                placeholder={I18n.get("Registration ID")}
                required
                label
                value={inputsForm?.paymentBeneficiaryId}
                onChange={(value) =>
                  handleChangeInputForm("paymentBeneficiaryId", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "paymentBeneficiaryId",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "paymentBeneficiaryId",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            )}
          </Col>
          <Col xs={12}>
            <Input
              placeholder={I18n.get("Nome do Beneficiário")}
              required
              label
              value={inputsForm?.paymentBeneficiaryName}
              onChange={(value) =>
                handleChangeInputForm("paymentBeneficiaryName", value)
              }
              error={hasError({
                arr: errorsInputs,
                field: "paymentBeneficiaryName",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "paymentBeneficiaryName",
                  arr: errorsInputs,
                });
                setErrorsInputs(errorsArr);
              }}
            />
          </Col>
          <Col xs={24}>
            <h3>{I18n.get("Upload do Boleto")}</h3>
            <FileUpload
              cbFiles={(file) => handleSelectedSlip(file)}
              multiple={false}
              initialFiles={[]}
              acceptedTypes={[
                {
                  label: "PDF",
                  type: "application/pdf",
                },
              ]}
              cbDeleteFile={(fileOrId) => handleDeleteUploadSlip(fileOrId)}
            />
            {hasError({
              arr: errorsInputs,
              field: "slip",
            }) ? (
              <S.ErrorMsg>
                {hasError({
                  arr: errorsInputs,
                  field: "slip",
                })}
              </S.ErrorMsg>
            ) : null}
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={24} style={{ marginTop: 32 }}>
          <ButtonBpool
            text={I18n.get("Adicionar NF")}
            theme="primary"
            full={false}
            loading={isLoading?.sending}
            onClick={sendNF}
          />
        </Col>
      </Row>
    </S.DrawerInfos>
  );
};
