import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import moment from 'moment';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import Content from "../../../../../components/UI/Content";
import Main from "../../components/Main";
import { H2Border } from "../../../../../components/UI/Titles";
import { StepsCreateProject } from "../../components/StepsCreateProject";
import { Input } from "../../../../../components/UI/Input";
import { SelectOne } from "../../../../../components/UI/SelectOne";
import { DatePicker } from "../../../../../components/UI/DatePicker";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import Loading from "../../../../../components/UI/Loading";
import { hasError, clearErrorInput, errorObjToArr } from "../../../../../utils/errorsInput";

import { monthsPT, monthsEN } from '../../constants/months';
import { weeksPT, weeksEN } from '../../constants/weeks';
import { nonCompetePT, nonCompeteEN } from '../../constants/nonCompete';

import * as S from "./styles";
import { OrganizeState } from "./organizeState";

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

export const CreateProjectPartnerStep1 = () => {
  let params = useParams();
  let history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { createProjectPartner, updateProjectPartner, clientsWorkedWith, clientBrands, getProjectByIdOld } = useProjects();
  const [project, setProject] = useState(null);
  const [inputsForm, setInputsForm] = useState({
    projectName: null,
    brandId: null,
    productId: null,
    clientResponsableUsername: null,
    estimatedStartDate: null,
    estimatedDurationInMonths: null,
    estimatedDurationInWeeks: null,
    nonCompeteInDays: null,
  });
  const [clientToProject, setClientToProject] = useState(null);
  const [dateProject, setDateProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSelects, setIsLoadingSelects] = useState(false);
  const [listOfClients, setListOfClients] = useState([]);
  const [listOfBrands, setListOfBrands] = useState([]);
  const [listOfProduct, setListOfProduct] = useState([]);
  const [listOfResponsible, setListOfResponsible] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [isLoadingDraft, setIsLoadingDraft] = useState(true);

  const loadInit = async () => {
    setIsLoading(true);

    const response = await clientsWorkedWith();
    if (response?.data?.success) {
      const listClients = response?.data?.data?.map((item) => ({
        code: item?.clientId,
        label: item?.clientCompanyName,
      }));
      setListOfClients(listClients);
    }

    setIsLoading(false);
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value !== null || value !== "" || value !== undefined ? value : null,
    });
  };

  const onChangeDate = (date, dateString) => {
    setDateProject(date);
  };

  const loadInfosToBrand = async (clientToProject) => {
    setIsLoadingSelects(true);
    const response = await clientBrands({ clientId: clientToProject });
    if (response?.status === 200) {
      const listBrands = response?.data;
      setListOfBrands(listBrands);
    }
    setIsLoadingSelects(false);
  };

  const loadInfosToProduct = async ({ brandId }) => {
    const brand = listOfBrands?.find((item) => item?.brandId === brandId);
    const listProducts = brand?.products;
    setListOfProduct(listProducts);
  };

  const loadInfosResponsible = async ({ productId }) => {
    const product = listOfProduct?.find((item) => item?.productId === productId);
    const listResponsibles = product?.responsables?.map(item => ({
      code: item?.username,
      label: item?.name,
    }));
    setListOfResponsible(listResponsibles);
  };

  const handleClient = (value) => {
    setClientToProject(value);
    setInputsForm({
      ...inputsForm,
      brandId: null,
      productId: null,
      clientResponsableUsername: null,
    })
    setListOfBrands([]);
    setListOfProduct([]);
    setListOfResponsible([]);
    if (value) {
      loadInfosToBrand(value)
    }
  };

  const loadInitDraft = async () => {
    setIsLoadingDraft(true);
    const responseProject = await getProjectByIdOld({ projectId: params.projectId });
    setProject({ productId: responseProject?.productId });
    const stateById = OrganizeState({ data: responseProject });
    setClientToProject(responseProject?.clientId);
    var dateGet = moment(responseProject?.estimatedStartDate, "DD/MM/YYYY");
    let customDate = dateGet.format("YYYY-MM-DD");
    const dateFormatted = moment(customDate, "YYYY-MM-DD");
    setDateProject(responseProject?.estimatedStartDate ? dateFormatted : null)
    setInputsForm({ ...stateById });
    await loadInfosToBrand(responseProject?.clientId)
    setIsLoadingDraft(false);
  }

  const send = async () => {
    const objSend = {
      ...inputsForm,
      clientId: clientToProject,
      estimatedStartDate: dateProject,
    }

    setIsSending(true);
    const response = params?.projectId
      ? await updateProjectPartner({ ...objSend, projectId: params?.projectId })
      : await createProjectPartner(objSend);

    if (response?.data?.success) {
      history.push(`/parceiro/projetos/v2/criar/briefing/${response?.data?.data}`)

    } else {
      const errors = response?.data?.errors;
      const errs = await errorObjToArr(errors);
      setInputsErrorsForm(errs);
    }

    setIsSending(false);
  };

  useEffect(() => {
    if (inputsForm?.brandId) {
      loadInfosToProduct({ brandId: inputsForm?.brandId });
    }
    if (inputsForm?.productId) {
      loadInfosResponsible({ productId: inputsForm?.productId });
    }
  }, [inputsForm, isLoadingDraft, listOfProduct, listOfBrands]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
    if (params?.projectId) {
      loadInitDraft();
    } else {
      setIsLoadingDraft(false);
    }
  }, []);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Novo Projeto")}</H2Border>
            </Col>
            <Col sm={12} justify="end"></Col>
          </Row>

          <Row>
            <Col sm={24}>
              <S.StyleMain>
                <StepsCreateProject
                  active={1}
                  disabledTabs={project?.productId ? [] : [2, 3, 4]}
                  projectId={params.projectId || ""}
                />
              </S.StyleMain>

              {isLoadingDraft ? (
                <Main bgColor="#fff" padding="30px">
                  <Loading
                    sizeIcon={18}
                    text={I18n.get('Carregando...')}
                    sizeText={14}
                    align="left"
                  />
                </Main>
              ) : (
                <Main bgColor="#fff" padding="30px">
                  <Row>
                    <Col sm={16}>
                      <Input
                        placeholder={I18n.get("Nome do Projeto")}
                        required
                        label
                        full
                        value={inputsForm?.projectName}
                        onChange={(value) => onChangeInputForm("projectName", value)}
                        error={hasError({ field: "projectName", arr: inputsErrorsForm })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "projectName",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                      />
                    </Col>
                    <Col sm={8}>
                      {isLoading ? (
                        <Loading
                          sizeIcon={18}
                          text={I18n.get('Carregando...')}
                          sizeText={14}
                          align="left"
                        />
                      ) : (
                        <SelectOne
                          placeholder={I18n.get('Cliente')}
                          options={listOfClients}
                          value={clientToProject}
                          required
                          onChange={(value) => handleClient(value)}
                          disabled={listOfClients?.length ? false : true}
                          error={hasError({ field: "clientId", arr: inputsErrorsForm })}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "clientId",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}>
                      {isLoadingSelects ? (
                        <Loading
                          sizeIcon={18}
                          text={I18n.get('Carregando...')}
                          sizeText={14}
                          align="left"
                        />
                      ) : (
                        <SelectOne
                          placeholder={I18n.get('Marca')}
                          options={listOfBrands?.map((item) => ({
                            code: item?.brandId,
                            label: item?.name,
                          }))}
                          required
                          value={inputsForm?.brandId}
                          onChange={(value) => onChangeInputForm("brandId", value)}
                          disabled={listOfBrands?.length ? false : true}
                          error={hasError({ field: "brandId", arr: inputsErrorsForm })}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "brandId",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      )}
                    </Col>
                    <Col sm={8}>
                      {isLoadingSelects ? (
                        <Loading
                          sizeIcon={18}
                          text={I18n.get('Carregando...')}
                          sizeText={14}
                          align="left"
                        />
                      ) : (
                        <SelectOne
                          placeholder={I18n.get('Produto')}
                          options={listOfProduct?.length ? listOfProduct?.map((item) => ({
                            code: item?.productId,
                            label: item?.name,
                          })) : []}
                          required
                          value={inputsForm?.productId}
                          onChange={(value) => onChangeInputForm("productId", value)}
                          disabled={listOfProduct?.length ? false : true}
                          error={hasError({ field: "productId", arr: inputsErrorsForm })}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "productId",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      )}
                    </Col>
                    <Col sm={8}>
                      {isLoadingSelects ? (
                        <Loading
                          sizeIcon={18}
                          text={I18n.get('Carregando...')}
                          sizeText={14}
                          align="left"
                        />
                      ) : (
                        <SelectOne
                          placeholder={I18n.get('Responsável')}
                          options={listOfResponsible?.length ? listOfResponsible : []}
                          required
                          value={inputsForm?.clientResponsableUsername}
                          onChange={(value) => onChangeInputForm("clientResponsableUsername", value)}
                          disabled={listOfResponsible?.length ? false : true}
                          error={hasError({ field: "clientResponsableUsername", arr: inputsErrorsForm })}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "clientResponsableUsername",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24}>
                      <S.Hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <DatePicker
                        label={I18n.get("Início do Projeto")}
                        placeholder="__/__/____"
                        required
                        defaultValue={dateProject}
                        format={dateFormatList}
                        onChange={onChangeDate}
                        value={dateProject ? moment(dateProject, 'DD-MM-YYYY') : null}
                        disabledDate={(current) => {
                          let customDate = moment().format('YYYY-MM-DD');
                          return current && current < moment(customDate, 'YYYY-MM-DD');
                        }}
                        error={hasError({ field: "estimatedStartDate", arr: inputsErrorsForm })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "estimatedStartDate",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                      />
                    </Col>
                    <Col sm={6}>
                      <SelectOne
                        placeholder={I18n.get('Mes(es)')}
                        options={auth?.locale === "en" ? monthsEN : monthsPT}
                        required
                        value={inputsForm?.estimatedDurationInMonths}
                        onChange={(value) => onChangeInputForm("estimatedDurationInMonths", value)}
                        error={hasError({ field: "estimatedDurationInMonths", arr: inputsErrorsForm })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "estimatedDurationInMonths",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                      />
                    </Col>
                    <Col sm={6}>
                      <SelectOne
                        placeholder={I18n.get('Semana(s)')}
                        options={auth?.locale === "en" ? weeksEN : weeksPT}
                        required
                        value={inputsForm?.estimatedDurationInWeeks}
                        onChange={(value) => onChangeInputForm("estimatedDurationInWeeks", value)}
                        error={hasError({ field: "estimatedDurationInWeeks", arr: inputsErrorsForm })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "estimatedDurationInWeeks",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                      />
                    </Col>
                    <Col sm={6}>
                      <SelectOne
                        placeholder={I18n.get('NonCompete')}
                        options={auth?.locale === "en" ? nonCompeteEN : nonCompetePT}
                        required
                        value={inputsForm?.nonCompeteInDays}
                        onChange={(value) => onChangeInputForm("nonCompeteInDays", value)}
                        error={hasError({ field: "nonCompeteInDays", arr: inputsErrorsForm })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "nonCompeteInDays",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24}>
                      <S.FooterButtons>
                        <ButtonBpool
                          text={I18n.get("Voltar")}
                          theme="secondary"
                          onClick={() => history.push("/parceiro/projetos/v2")}
                        />
                        <ButtonBpool
                          text={I18n.get("Próximo")}
                          theme="primary"
                          onClick={() => send()}
                          loading={isSending}
                        />
                      </S.FooterButtons>
                    </Col>
                  </Row>
                </Main>
              )}
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
