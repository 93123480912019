import { CHAT_NOTIFICATION_MARK } from './actions';
 
const api_call = (channel, ids) => {

    return (dispatch, getState) => {

        const payload = {
            "action":"markAsRead",
            "channelID": channel,
            "messageIDs":ids
        };

        window.sendWsMessage(JSON.stringify(payload));
    };

}
  
export default api_call;