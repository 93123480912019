import styled from "styled-components";

export const StyleForm = styled.form``;

export const StyleMain = styled.div`
  max-width: 630px;
  margin: 50px auto 0;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  button {
    margin-left: 10px;
  }
`;

export const ContainerSelects = styled.div`
  padding: 0;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

export const SelectsTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 25px;
`;

export const SelectsSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 25px;
`;

export const ContentSelect = styled.div`
  margin-top: 50px;
`;

export const ContainerDuble = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemDuble = styled.div`
  width: 47%;
`;

export const DisplayErrors = styled.div`
  padding: 0 30px;
  h2 {
    font-weight: bold;
  }

  div {
    margin-top: 30px;
  }
`;
