import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import WhatifWrapper from '../components/WhatifWrapper';

import SubHeader from '../components/SubHeader';

import PageSuccess from '../components/PageSuccess';

class RegisterPoSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <WhatifWrapper>
                <SubHeader />
                <PageSuccess>
                    <Fragment>
                        <h2>P.O cadastrada com sucesso!</h2>
                        <p>texto para essa página</p>
                    </Fragment>
                </PageSuccess>
            </WhatifWrapper>
        );
    }
}

export default RegisterPoSuccess;
