import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import { CLIENT_API } from '../../actions/client/actions';

import TextField from '../form/text-field';
import CheckboxField from '../form/checkbox-field';
import SelectField from '../form/select-field';
import TooltipField from '../form/tooltip-field';

import NumberFormat from 'react-number-format';

const BRL = ({ amount }) => <NumberFormat value={amount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} fixedDecimalScale={true} />;

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp }, state.client);
};

const mapDispatchToProps = dispatch => {
    return {
        addBrand: data => {
            dispatch(CLIENT_API.create_brand(data));
        },
        delBrand: data => {
            dispatch(CLIENT_API.delete_brand(data));
        },
    };
};


class PlanForm extends React.Component {

    constructor(props) {

        super();
        this.state = {
            form: {
                clientId: props.clientId,
                name: {
                    label: I18n.get("Marca"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'name'),
                    tooltip: I18n.get("Indique aqui quantas marcas do portfólio da sua empresa vão fazer parte da BPool.")
                },
                categoryCount: {
                    label: I18n.get("Número de categorias"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'categoryCount'),
                    error: props.errors.categoryCount,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'categoryCount'),
                    tooltip: I18n.get("Informe as categorias em que sua marca atua. Uma mesma marca pode atuar na categoria de shampoos e de hidradantes")
                },
            },
            task: {}
        };

        this.addBrand = this.onAddBrand.bind(this);
        this.delBrand = this.onDelBrand.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;

        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);
        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    getData() {

        var data = FormUtils.getData(this.state);

        return data;
    }

    onAddBrand(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.addBrand(this.getData());
        });
    }

    onDelBrand(item, e) {
        e.preventDefault();

        const data = Object.assign({}, this.getData(), { id: item.id });
        // this.props.delBrand(data);
    }

    getCategoryCountValues() {

        var values = [], i = 0, len = 10;
        while (++i <= len) values.push({ code: i, label: i });

        return values;
    }

    getPlanSubtotal(count) {
        if (!this.props.bp.brandPriceBRL) return 0;

        if (!count) return 0;

        return this.props.bp.brandPriceBRL * count;
    }

    getGrandTotal() {
        return this.props.brands && this.props.brands.reduce((acc, item) => acc + this.getPlanSubtotal(item.categoryCount), 0);
    }

    render() {

        return (
            <section>

                <div className="field is-horizontal">
                    <div className="field-body">

                        <TextField {...this.state.form.name} />

                        <SelectField {...this.state.form.categoryCount} values={this.getCategoryCountValues()} />

                        {/* <div className="field is-narrow">
                            <label className="label is-small">
                                 Mensalidade  <TooltipField tooltip='O valor da mensalidade será a soma das marcas registradas na BPool. Clientes beta estão isentos desta cobrança por 12 meses.' />}
                            </label>
                            <p className="control has-text-centered">
                                <BRL amount={this.getPlanSubtotal(this.state.form.categoryCount.value)} />
                            </p>
                        </div> */}

                        <div className="field is-narrow">
                            <label className="label">
                                &nbsp;
                            </label>
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={this.addBrand} disabled={this.state.singleUser}
                                    className="button is-leaked">{I18n.get("CONFIRMAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                                {/* <a onClick={this.addBrand} disabled={this.state.singleUser}
                                    className="button is-rounded is-black">
                                    INCLUIR
                                </a> */}


                            </p>
                        </div>

                    </div>
                </div>

                <br />

                {this.props.brands && this.props.brands.length > 0 && <table className="table is-bordered is-fullwidth" style={{ border: 'none' }}>
                    <thead>
                        <tr>
                            <th className="bp-has-background-pink">{I18n.get("Marca")}</th>
                            <th className="bp-has-background-pink">{I18n.get("Número de categorias")}</th>
                            {/* <th className="bp-has-background-pink">Mensalidade</th> */}
                            <th className="bp-has-background-pink"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.brands.map((item, index) =>
                            <tr key={index}>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.categoryCount}
                                </td>
                                {/* <td>
                                    <BRL amount={this.getPlanSubtotal(item.categoryCount)} />
                                </td> */}
                                <td>
                                    <a onClick={(e) => this.delBrand(item, e)}>
                                        <i className="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    {/* <tfoot>
                        <tr>
                            <td className="is-invisible"></td>
                            <td className="is-invisible"></td>
                            <td className="has-text-weight-bold has-text-white has-background-grey-light">
                                <BRL amount={this.getGrandTotal()} />
                            </td>
                            <td className="has-text-weight-bold has-text-white has-background-grey-light">
                                &nbsp;
                            </td>
                        </tr>
                    </tfoot> */}
                </table>}
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanForm);
