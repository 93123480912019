import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { Task } from '../../utils/task';

import { REFERENCE_API } from '../../actions/reference/actions';

import TextField from '../form/text-field';
import MaskedField from '../form/masked-field';
import SubmitButtonField from '../form/submit-button-field';

const mapStateToProps = state => {
    return state.reference;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(REFERENCE_API.update_person(data));
        }
    };
};

class PersonForm extends React.Component {
    constructor(props) {

        super();




        this.state = {
            form: {
                token: props.verificationToken,
                name: {
                    label: I18n.get("Nome Completo"),
                    value: props.name,
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'name'),
                },
                email: {
                    label: I18n.get("E-mail"),
                    value: props.email,
                    onChange: this.onChangeForm.bind(this, 'email'),
                    error: props.errors.email,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'email'),
                    readOnly: true
                },
                phoneNumber: {
                    label: I18n.get("Celular (DDD)"),
                    value: props.phoneNumber,
                    onChange: this.onChangeForm.bind(this, 'phoneNumber'),
                    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                    error: props.errors.phoneNumber,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'phoneNumber'),
                },
                isSubmit: false,
            },
            task: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                if (typeof nextState.form[item] === "object") {
                    nextState.form[item].error = this.props.errors[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData() {

        var data = {};

        Object.keys(this.state.form).forEach((item) => {

            if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
                data[item] = this.state.form[item].value
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    onSave(item) {



        if (this.props.isAdmin) return;

        // zera o erro
        if (item && typeof this.state.form[item] === "object") {
            this.state.form[item].error = null;
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }


    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    render() {

        return (<form onSubmit={this.submitForm.bind(this)}>

            <TextField {...this.state.form.name} />
            <TextField {...this.state.form.email} />
            <MaskedField {...this.state.form.phoneNumber} />

        </form>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonForm);
