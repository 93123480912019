import styled from 'styled-components';
import { Row } from 'antd';

export const Wrapper = styled.div`
  .RowGroup {
    margib-bottom: 60px;
  }
  .select-industries-dropdown {
    margin-bottom: 30px;

    .ant-select-selection {
      background: #fff;
      border: 1px solid #999999;
      height: 50px;
      border-radius: 10px !important;

      .ant-select-selection__rendered {
        height: 100%;
        display: flex;
        align-items: center;
      }

      span {
        i {
          top: 16px;
        }
      }
    }
  }
`;

export const RowDocs = styled(Row)`
  margin-bottom: 100px;
`;

export const BoxCheck = styled.div`
  margin-bottom: 30px;

  .ant-checkbox-inner {
    border: solid 1px #d9d9d9;
  }
`;

export const LabelForm = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 20px;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const InputLabel = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 8px;
  display: flex;

  span {
    color: #ff0000;
    font-size: 22px;
  }
`;

export const TitleSectionForm = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-bottom: solid 1px #000;
  margin-bottom: 40px;
  width: max-content;
`;

export const SeeExample = styled.div`
  button {
    padding: 0;
    margin: 0 0 0 5px;
    border: none;
    background: none;
    color: #1883ff;
    cursor: pointer;
  }
`;

export const LogoAndHeader = styled.div`
  margin-top: 30px;
  display: flex;
`;

export const UploadLogo = styled.div``;

export const UploadHeader = styled.div`
  width: 100%;
  margin-left: 15px;
`;

export const UploadPresentationImg = styled.div`
  width: 100%;
`;

export const Selects = styled.div`
  margin: 10px 0 20px 0;

  .text-span {
    font-size: 12px;
    color: #b9b9b9;
  }

  .ant-radio-inner {
    border-color: #b9b9b9;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff;
  }

  .ant-radio-inner::after {
    background-color: #1883ff;
  }
`;

export const ContentImagesPreview = styled.div``;

export const ChoiceUserImageOrVideo = styled.div`
  margin-top: 30px;
`;

export const HeaderImagePreview = styled.div`
  min-height: 195px;

  .header-label {
    margin: 0 0 17px 10px;
    display: flex;
    align-items: center;
    height: 25px;

    p {
      margin-left: 5px;
    }
  }

  .logo-label {
    width: 100%;
    position: relative;
    top: 17px;

    div {
      position: relative;
      left: calc(50% + 57px);
      width: calc(50% - 57px);
    }
  }
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #999999;

  strong {
    padding-right: 3px;
    font-weight: 600;
    color: #000000;
    ${(props) => (props.statusUp === 'error' ? 'color: #ff0000;' : null)};
  }

  ${(props) => (props.statusUp === 'error' ? 'color: #ff0000;' : null)};
`;

export const ErrorText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #ff0000;
`;

export const ImgHeader = styled.div`
  border-radius: 10px;

  img {
    border-radius: 10px;
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 65px;
  width: auto;
  left: calc(50% - 47px);
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const LogoImage = styled.div`
  width: 97px;
  height: 97px;
  border-radius: 50%;
  /* border: 5px solid #ffffff;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 25%); */

  img {
    border: 1px solid #000000;
    border-radius: 50%;
  }
`;

export const TitleSection = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  position: relative;
  height: 60px;
  margin-bottom: 15px;

  :after {
    content: '';
    width: 63px;
    height: 6px;
    background: #000000;
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

export const BgImagePreview = styled.div`
  .label-introimage {
    width: 100%;
    text-align: center;
    min-height: 42px;
  }
`;

export const ImgPresentation = styled.div`
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

export const WrapperVideo = styled.div`
  width: 100%;
  position: relative;
`;

export const VideoContent = styled.div`
  width: 640px;
  margin: 0 auto;
`;

export const EmptyVideo = styled.div`
  width: 640px;
  height: 360px;
  margin: 0 auto;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
