import React from 'react';

import { I18n } from 'aws-amplify';

// import Modal from '../../ui/modal';
import FormException from '../../../modules/Admin/components/formExceptions';
import FormExceptionsProduct from '../../../modules/Admin/components/formExceptionsProduct';

import {
    Table,
    Icon,
    Popconfirm,
    Modal,
    Select,
    Input,
    Form,
    notification,
    Tooltip,
    Tag
} from 'antd';


class Cmp extends React.Component {

    constructor(props) {

        super();

        this.state = {
            open: false
        };
    }

    removePartner = (data) => {
        this.props.remove_partners_exception(data).then(() => {
            this.props.get_exceptions(this.props.match.params.clientId);
        })
    }

    removeProduct = (data) => {
        this.props.remove_product_exception(data).then(() => {
            this.props.get_exceptions(this.props.match.params.clientId);
        })
    }

    handleSend = (data) => {
        const dataSend = {
            clientId: this.props.match.params.clientId,
            partnerId: data.partnerId,
            defaultCommission: data.defaultCommission
        }

        this.props.add_partners_exception(dataSend);
    }

    handleSendProduct = (data) => {
        const dataSend = {
            clientId: this.props.match.params.clientId,
            partnerId: data.partnerId,
            productId: data.productId,
            commission: data.commission
        }

        this.props.add_product_exception(dataSend).then((response) => {

            if(response?.errorsAddProduct && Object.keys(response?.errorsAddProduct).length > 0) {

            } else {
                this.setState({
                    open: false
                })
    
                setTimeout(() => {
                    this.props.get_exceptions(this.props.match.params.clientId);
                }, 200);

            }

        });
    }

    closeModal = () => {
        this.setState({open: false});
    }



    render() {

        if (!this.props.bp) return null;
        if (!this.props.bp.client) return null;

        const item = this.props.bp.client;
        const exceptions = this.props?.bp?.exceptions || [];

        return (
            <section>
                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Fee Exceptions")}</h3>

                        <FormException 
                            partners={this.props?.bp.exceptionsPartners || []}
                            action={this.handleSend}
                            errors={this.props.bp.errors}
                            exceptions={exceptions}
                        />

                        <div className="content">

                            <table className="table-bpool no-opacity">
                                <tbody>
                                    <tr>
                                        <th>{I18n.get("Parceiro")}</th>
                                        <th>{I18n.get("% Padrão")}</th>
                                        <th>{I18n.get("% Produto")}  <a onClick={() => this.setState({open: true})}><Icon type="plus-circle" /></a></th>
                                    </tr>

                                    {exceptions.map((item,index) => {

                                        const data = {
                                            partnerId: item.partnerId,
                                            clientId: this.props.match.params.clientId
                                        }

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {item.partnerName} {" "}
                                                    <a>
                                                        <Popconfirm
                                                            title={I18n.get("Deseja realmente excluir a exceção deste parceiro?")}
                                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                            onConfirm={() => this.removePartner(data)}
                                                            okText="Sim"
                                                            cancelText="Não"
                                                        >
                                                                <Icon type="delete" theme="filled" />
                                                        </Popconfirm>
                                                    </a>
                                                </td>
                                                <td>{item.bpComissionDefaultPerc}%</td>
                                                <td>
                                                    {item?.products.map((prod,index) => {

                                                        data.productId = prod.productId;
                                                        
                                                        return (
                                                            <p className="listBrandTable" key={index}>
                                                                {prod.brandName} - {prod.productName} {prod.bpComissionPerc}%

                                                                <a>
                                                                    <Popconfirm
                                                                        title={I18n.get("Deseja realmente excluir a exceção deste produto?")}
                                                                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                                        onConfirm={() => this.removeProduct(data)}
                                                                        okText={I18n.get("Sim")}
                                                                        cancelText={I18n.get("Não")}
                                                                    >
                                                                            <Icon type="delete" theme="filled" />
                                                                    </Popconfirm>
                                                                </a>
                                                            </p>
                                                        )
                                                    })}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr></tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>


                

                <Modal 
                     title={I18n.get("Produto %")}
                     visible={this.state.open}
                     width={800}
                    //  onOk={this.handleOk}
                     onCancel={() => this.setState({open: false})}
                     footer={null}
                     destroyOnClose={true}
                >

                    <section>
                        <div className="card">
                            <div className="card-content">

                                <div className="content">
                                    <FormExceptionsProduct
                                        partners={this.props?.bp.exceptionsPartners || []}
                                        products={this.props?.bp?.exceptionsProducts || []}
                                        action={this.handleSendProduct}
                                        errors={this.props.bp.errorsAddProduct}
                                    />
                                </div>
                            </div>

                        </div>

                    </section>

                </Modal>
            </section>
        );
    }
}

export default Cmp;