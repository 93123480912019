import React from "react";
import { I18n } from "aws-amplify";
import { scrollToTop } from "../../utils/ui";
import { Tooltip, Icon } from "antd";

import FormatCurrency from "../ui/format-currency";

class AssetCustomize extends React.Component {
  constructor(props) {
    super();
    this.state = {
      opened: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  handleAddItemBtnClick(item) {
    this.props.onAddItemBtnClick(item);
  }

  onClickCartIcon() {
    scrollToTop();
  }

  toggle(e) {
    this.setState({ opened: !this.state.opened });
  }

  render() {
    const asset = this.props.asset;

    let currency = this.props.currency;

    const complexityId = asset.complexityId;
    const complexity = asset.complexity;
    const complexityDescription = asset.complexDesc;

    const assetTax = this.props.assetTax;

    let assetPriceWithOutTax =
      Math.round((asset.price / ((100 + assetTax) / 100)) * 100) / 100;

    return (
      <article
        className="media media-template-card full"
        style={{ backgroundColor: "#ffffff" }}
        data-code={asset.code}
        data-label={asset.label}
        data-price={asset.price}
        data-complexity={complexity}
        data-complexity-id={complexityId}
        //data-price-with-tax={asset.price}
        //data-tax-perc={assetTax}
      >
        <div className="content">
          <div className="customize-content">
            <div className="columns">
              <div className="column is-8">
                <h2>
                  {asset.isManHour && (
                    <>
                      <Tooltip
                        title={I18n.get(
                          "Esse ativo é do tipo Hora/Homem; O Parceiro deverá incluir uma descrição para justificar o uso desse asset."
                        )}
                      >
                        <Icon type="clock-circle" theme="filled" />
                      </Tooltip>{" "}
                    </>
                  )}
                  {asset.label}{" "}
                  {complexityId && (
                    <>
                      <spam className="complexBadge">{complexityId}</spam> -{" "}
                      <spam className="complexBadgeTxt">{complexity}</spam>
                    </>
                  )}
                </h2>
              </div>
              <div className="column is-4 price priceText">
                {/* <BRL amount={asset.price} symbol={currency.symbolOnly} tSeparator={currency.thousandSeparator} dSeparator={currency.decimalSeparator} /> */}
                {/* <BRL amount={assetPriceWithOutTax} symbol={currency.symbolOnly} tSeparator={currency.thousandSeparator} dSeparator={currency.decimalSeparator} /> */}
                <FormatCurrency amount={asset.price} currency={currency} />
              </div>
            </div>

            {this.state.opened && (
              <>
                <p>
                  <strong>{I18n.get("Descrição")}</strong>
                </p>
                <p>{asset.description}</p>
                <br />

                {asset.complexity && (
                  <>
                    <p>
                      <strong>{I18n.get("Complexidade")}</strong>
                    </p>
                    <p>
                      <strong>
                        {asset.complexityId} {asset.complexity}
                      </strong>
                      : {asset.complexDesc}
                    </p>
                  </>
                )}
              </>
            )}

            <div className="columns">
              <div className="column is-8" onClick={this.toggle}>
                <span
                  className={`toggleTrigger button bt-bpool ${
                    !this.state.opened && "is-closed"
                  }`}
                >
                  {!this.state.opened
                    ? I18n.get("Saiba mais")
                    : I18n.get("Ver menos")}
                </span>
              </div>
              <div className="column is-4 customize-content-bottom">
                <button
                  type="button"
                  className="button bt-bpool black"
                  data-select-asset={asset.label}
                  onClick={this.handleAddItemBtnClick.bind(this, asset)}
                >
                  {I18n.get("Adicionar")}
                </button>
              </div>
            </div>
          </div>
          <div className="customize-baseline"></div>
        </div>
      </article>
    );
  }
}

export default AssetCustomize;
