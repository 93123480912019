import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import { PARTNER_API } from '../../actions/partner/actions';

import TextField from '../form/text-field';
import MaskedField from '../form/masked-field';
import SubmitButtonField from '../form/submit-button-field';

import TooltipField from '../form/tooltip-field';


const mapStateToProps = state => {
    return state.partner;
};

const mapDispatchToProps = dispatch => {
    return {
        get_partner: id => {
            dispatch(PARTNER_API.get(id));
        },
        save: data => {
            dispatch(PARTNER_API.update_person(data));
        }
    };
};

class PersonForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            form: {
                partnerId: props.partnerId,
                link: '',
                social_link: '',
                portfolioURLs: props.portfolioURLs,
                socialURLs: props.socialURLs,
                name: {
                    label: I18n.get("Nome do líder"),
                    tooltip: I18n.get("Pedimos o líder responsável aqui, nos passos a frente você poderá cadastrar todos os outros líderes"),
                    value: props.name,
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'name'),
                },
                email: {
                    label: I18n.get("E-mail"),
                    value: props.email,
                    onChange: this.onChangeForm.bind(this, 'email'),
                    error: props.errors.email,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'email'),
                    readOnly: true
                },
                linkedin: {
                    label: I18n.get("Linkedin Pessoal"),
                    placeholder: I18n.get("https://"),
                    value: props.linkedin,
                    onChange: this.onChangeForm.bind(this, 'linkedin'),
                    error: props.errors.linkedin,
                    onBlur: this.onSave.bind(this, 'linkedin')
                },
                phoneNumber: {
                    label: I18n.get("Celular (DDD)"),
                    value: props.phoneNumber,
                    onChange: this.onChangeForm.bind(this, 'phoneNumber'),
                    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                    error: props.errors.phoneNumber,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'phoneNumber'),
                },
                nameAlias: {
                    label: I18n.get("Como você quer ser chamado?"),
                    value: props.nameAlias,
                    onChange: this.onChangeForm.bind(this, 'nameAlias'),
                    error: props.errors.nameAlias,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'nameAlias'),
                },
                isSubmit: false,
            },
            task: {},
        };

        //this.onDrop = this.onDrop.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                if (FormUtils.isFormField(nextState.form[item])) {

                    nextState.form[item].error = this.props.errors[item];

                    if ('checked' in nextState.form[item]) {
                        nextState.form[item].checked = this.props[item];
                    }
                    else if (typeof (nextState.form[item].value) == 'undefined') {
                        nextState.form[item].value = this.props[item];
                    }
                }
                else if (typeof nextState.form[item] == 'undefined') {
                    nextState.form[item] = this.props[item];
                }
            });

            this.setState(nextState);
        }
    }

    onChangeLink(e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.link = value;

            return nextState;

        });
    }

    onAddLinkClick(e) {



        e.preventDefault();

        if (!this.state.form.link) return;

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.portfolioURLs = [...prevState.form.portfolioURLs, this.state.form.link];
            nextState.form.link = '';

            return nextState;

        }, this.onSave());
    }

    onDelLinkClick(index, e) {

        e.preventDefault();

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.portfolioURLs = prevState.form.portfolioURLs.filter((item, i) => i != index);

            return nextState;

        }, this.onSave());
    }

    onChangeSocialLink(e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.social_link = value;

            return nextState;

        });
    }

    onAddSocialLinkClick(e) {

        e.preventDefault();

        if (!this.state.form.social_link) return;

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.socialURLs = [...prevState.form.socialURLs, this.state.form.social_link];
            nextState.form.social_link = '';

            return nextState;

        }, this.onSave());
    }

    onDelSocialLinkClick(index, e) {

        e.preventDefault();

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.socialURLs = prevState.form.socialURLs.filter((item, i) => i != index);

            return nextState;

        }, this.onSave());
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData() {

        var data = {};

        Object.keys(this.state.form).forEach((item) => {

            if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
                data[item] = this.state.form[item].value
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    onSave(item) {



        if (this.props.isAdmin) return;

        // zera o erro
        if (item && typeof this.state.form[item] === "object") {
            this.state.form[item].error = null;
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }


    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }



    render() {



        return (
            <form onSubmit={this.submitForm.bind(this)} className="form-mobile">

                <TextField {...this.state.form.name} />
                <TextField {...this.state.form.nameAlias} />
                <TextField {...this.state.form.email} />
                <TextField {...this.state.form.linkedin} />
                <MaskedField {...this.state.form.phoneNumber} />



                {/* <h1 className="title-bpool medium">
                    Website &amp; Portfólio pessoal
                </h1>

                {this.state.form.portfolioURLs && this.state.form.portfolioURLs.map((item, index) =>

                    (<div key={item} className="field is-horizontal">
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <a className="break-word" href={item} target="_blank">{item}</a>
                                </p>
                            </div>
                            <div className="field is-narrow">
                                <p className="control">
                                    <a className="break-word" onClick={this.onDelLinkClick.bind(this, index)} className="delete is-black">
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>)

                )}

                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <p className="control">
                                <input
                                    onChange={this.onChangeLink.bind(this)}
                                    value={this.state.form.link}
                                    className={(this.state.form.link && this.props.errors.portfolioURLs) ? "input is-danger" : "input"}
                                    type="text"
                                    placeholder="Cole o link aqui"
                                />
                            </p>
                            {(this.state.link && this.props.errors.portfolioURLs) && <p className="help is-danger">
                                {this.props.errors.portfolioURLs.errorMessage}
                            </p>}
                        </div>
                        <div className="field is-narrow">
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={this.onAddLinkClick.bind(this)}
                                    className="button is-leaked">
                                    SALVAR <i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                            </p>
                        </div>
                    </div>
                </div> */}

                <h1 className="title-bpool medium">{I18n.get("Outras redes sociais pessoais")} <TooltipField tooltip={I18n.get("Aqui você pode colocar outras redes sociais que apresentam seu trabalho")} />
                </h1>

                {this.state.form.socialURLs && this.state.form.socialURLs.map((item, index) =>

                    (<div key={item} className="field is-horizontal">
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <a className="break-word" href={item} target="_blank">{item}</a>
                                </p>
                            </div>
                            <div className="field is-narrow">
                                <p className="control">
                                    <a className="break-word" onClick={this.onDelSocialLinkClick.bind(this, index)} className="delete is-black">
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>)

                )}

                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <p className="control">
                                <input
                                    onChange={this.onChangeSocialLink.bind(this)}
                                    value={this.state.form.social_link}
                                    className={(this.state.form.social_link && this.props.errors.socialURLs) ? "input is-danger" : "input"}
                                    type="text"
                                    placeholder={I18n.get("Cole o link aqui")}
                                />
                            </p>
                            {(this.state.social_link && this.props.errors.socialURLs) && <p className="help is-danger">
                                {this.props.errors.socialURLs.errorMessage}
                            </p>}
                        </div>
                        <div className="field is-narrow">
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={this.onAddSocialLinkClick.bind(this)}
                                    className="button is-leaked">{I18n.get("SALVAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                            </p>
                        </div>
                    </div>
                </div>

                {this.props.isAdmin && <div className="control">
                    <SubmitButtonField
                        label={I18n.get("Salvar")}
                        onClick={this.submitForm.bind(this)}
                        task={this.state.task}
                    />
                </div>}

            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonForm);
