import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { LEADER_API } from '../../actions/leader/actions';

import TextField from '../form/text-field';
import PasswordField from '../form/password-field';
import MaskedField from '../form/masked-field';

import { Task } from '../../utils/task';

const mapStateToProps = state => {
    return state.leader;
};

const mapDispatchToProps = dispatch => {
    return {
        get_leader: id => {
            dispatch(LEADER_API.get(id));
        },
        save: data => {
            dispatch(LEADER_API.update_person(data));
        }
    };
};

class PersonForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            form: {
                leaderId: props.leaderId,
                link: '',
                social_link: '',
                portfolioURLs: props.portfolioURLs,
                socialURLs: props.socialURLs,
                name: {
                    label: I18n.get("Nome completo"),
                    value: props.name,
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'name'),
                },
                email: {
                    label: I18n.get("E-mail"),
                    value: props.email,
                    onChange: this.onChangeForm.bind(this, 'email'),
                    error: props.errors.email,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'email'),
                    readOnly: true
                },
                linkedin: {
                    label: I18n.get("Linkedin Pessoal"),
                    placeholder: I18n.get("https://"),
                    value: props.linkedin,
                    onChange: this.onChangeForm.bind(this, 'linkedin'),
                    error: props.errors.linkedin,
                    onBlur: this.onSave.bind(this, 'linkedin')
                },
                phoneNumber: {
                    label: I18n.get("Celular (DDD)"),
                    value: props.phoneNumber,
                    onChange: this.onChangeForm.bind(this, 'phoneNumber'),
                    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                    error: props.errors.phoneNumber,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'phoneNumber'),
                },
                password: {
                    label: I18n.get("Senha"),
                    value: props.password,
                    onChange: this.onChangeForm.bind(this, 'password'),
                    error: props.errors.password,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'password'),
                },
                password2: {
                    label: I18n.get("Confirmação senha"),
                    value: props.password2,
                    onChange: this.onChangeForm.bind(this, 'password2'),
                    error: props.errors.password2,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'password2'),
                },
                nameAlias: {
                    label: I18n.get("Como você quer ser chamado?"),
                    value: props.nameAlias,
                    onChange: this.onChangeForm.bind(this, 'nameAlias'),
                    error: props.errors.nameAlias,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'nameAlias'),
                },
                isSubmit: false,
            },
            task: {},
        };
    }

    onChangeLink(e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.link = value;

            return nextState;

        });
    }

    onAddLinkClick(e) {

        e.preventDefault();

        if (!this.state.form.link) return;

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.portfolioURLs = [...prevState.form.portfolioURLs, this.state.form.link];
            nextState.form.link = '';

            return nextState;

        }, () => this.props.save(this.getData()));
    }

    onDelLinkClick(index, e) {

        e.preventDefault();

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.portfolioURLs = prevState.form.portfolioURLs.filter((item, i) => i != index);

            return nextState;

        }, () => this.props.save(this.getData()));
    }

    onChangeSocialLink(e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.social_link = value;

            return nextState;

        });
    }

    onAddSocialLinkClick(e) {

        e.preventDefault();

        if (!this.state.form.social_link) return;

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.socialURLs = [...prevState.form.socialURLs, this.state.form.social_link];
            nextState.form.social_link = '';

            return nextState;

        }, () => this.props.save(this.getData()));
    }

    onDelSocialLinkClick(index, e) {

        e.preventDefault();

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.socialURLs = prevState.form.socialURLs.filter((item, i) => i != index);

            return nextState;

        }, () => this.props.save(this.getData()));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                if (typeof nextState.form[item] === "object") {
                    nextState.form[item].error = this.props.errors[item];
                }

            });

            this.setState(nextState);
        }

    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData() {

        var data = {};

        Object.keys(this.state.form).forEach((item) => {

            if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
                data[item] = this.state.form[item].value
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    onSave(item) {
        // e.preventDefault();

        // this.props.save(this.getData());



        if (this.props.isAdmin) return;

        // zera o erro
        if (item && typeof this.state.form[item] === "object") {
            this.state.form[item].error = null;
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });

    }

    submitForm(e) {

        e.preventDefault();

        this.setState({ task: { is_processing: true } }, function () {
            this.props.save(this.getData());
        });
    }

    render() {

        return (
            <form onSubmit={this.submitForm.bind(this)}>

                <TextField {...this.state.form.name} />

                <TextField {...this.state.form.nameAlias} />

                <TextField {...this.state.form.email} />

                <TextField {...this.state.form.linkedin} />

                <MaskedField {...this.state.form.phoneNumber} />

                <div className="field is-horizontal">
                    <div className="field-body">

                        <PasswordField {...this.state.form.password} />

                        <PasswordField {...this.state.form.password2} />

                    </div>
                </div>




                    <br />

                <h1 className="title-bpool medium">{I18n.get("Website & Portfólio pessoal")}</h1>

                {this.state.form.portfolioURLs && this.state.form.portfolioURLs.map((item, index) =>

                    (<div key={item} className="field is-horizontal">
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <a href={item} target="_blank">{item}</a>
                                </p>
                            </div>
                            <div className="field is-narrow">
                                <p className="control">
                                    <a onClick={this.onDelLinkClick.bind(this, index)} className="delete is-black">
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>)

                )}

                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <p className="control">
                                <input
                                    onChange={this.onChangeLink.bind(this)}
                                    value={this.state.form.link}
                                    className={(this.state.form.link && this.props.errors.portfolioURLs) ? "input is-danger" : "input"}
                                    type="text"
                                    placeholder={I18n.get("Cole o link aqui")}
                                />
                            </p>
                            {(this.state.link && this.props.errors.portfolioURLs) && <p className="help is-danger">
                                {this.props.errors.portfolioURLs.errorMessage}
                            </p>}
                        </div>
                        <div className="field is-narrow">
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={this.onAddLinkClick.bind(this)}
                                    className="button is-leaked">{I18n.get("SALVAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                            </p>
                        </div>
                    </div>
                </div>

                <h1 className="title-bpool medium">{I18n.get("Outras redes sociais pessoais")}</h1>

                {this.state.form.socialURLs && this.state.form.socialURLs.map((item, index) =>

                    (<div key={item} className="field is-horizontal">
                        <div className="field-body">
                            <div className="field">
                                <p className="control">
                                    <a href={item} target="_blank">{item}</a>
                                </p>
                            </div>
                            <div className="field is-narrow">
                                <p className="control">
                                    <a onClick={this.onDelSocialLinkClick.bind(this, index)} className="delete is-black">
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>)

                )}

                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <p className="control">
                                <input
                                    onChange={this.onChangeSocialLink.bind(this)}
                                    value={this.state.form.social_link}
                                    className={(this.state.form.social_link && this.props.errors.socialURLs) ? "input is-danger" : "input"}
                                    type="text"
                                    placeholder={I18n.get("Cole o link aqui")}
                                />
                            </p>
                            {(this.state.link && this.props.errors.socialURLs) && <p className="help is-danger">
                                {this.props.errors.socialURLs.errorMessage}
                            </p>}
                        </div>
                        <div className="field is-narrow">
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={this.onAddSocialLinkClick.bind(this)}
                                    className="button is-leaked">{I18n.get("SALVAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                            </p>
                        </div>
                    </div>
                </div>



            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonForm);
