import React from "react";

import { I18n } from "aws-amplify";

import "./auto-suggest-field.css";

import Autosuggest from "react-autosuggest";

import TooltipField from "./tooltip-field";

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export default class AutoSuggestField extends React.Component {
  constructor(props) {
    super();

    this.state = {
      focused: false,
      visited: props.visited,
      value: props.value,
      suggestions: [],
    };
  }

  static defaultProps = {
    successIcon: true,
  };

  onBlur() {
    this.setState({ focused: false });

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  onFocus() {
    this.setState({ focused: true, visited: true });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value != prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    const escapedValue = escapeRegexCharacters(value && value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp(escapedValue, "i");

    const suggestions = this.props.values.filter((item) =>
      regex.test(item.label)
    );

    this.setState({
      suggestions: suggestions,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onChange = (event, { newValue, method }) => {
    this.setState(
      {
        value: newValue,
      },
      this.props.onSelect(newValue)
    );
  };

  onBlur() {
    this.setState({ focused: false });

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  getSuggestionValue = (suggestion) => {
    return suggestion.label;
  };

  renderSuggestion = (suggestion) => {
    return <span>{suggestion.label}</span>;
  };

  render() {
    const className = this.props.className || "field";
    const inputClassName = this.props.inputClassName || "input";
    const placeholder = this.props.placeholder || "";

    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: placeholder,
      value: value,
      onChange: this.onChange,
      className: inputClassName,
      onBlur: () => this.onBlur(),
    };

    const isError =
      !this.state.focused && this.state.visited && this.props.error;

    const isValid = !!this.props.value && !this.props.error;

    return (
      <div className={className}>
        <label className="label is-small">
          {this.props.label} <TooltipField {...this.props} />
        </label>
        <div className="control has-icons-right">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
          />
          {this.props.successIcon && isValid && (
            <span className="icon is-small is-right form-field-valid">
              <i className="fa fa-check-circle"></i>
            </span>
          )}
        </div>
        {isError && (
          <p className="help is-danger">{this.props.error.errorMessage}</p>
        )}
      </div>
    );
  }
}
