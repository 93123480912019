import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { Collapse, Icon } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";

import * as S from "./styles";

const { Panel } = Collapse;

export const ItemCustomers = ({
  textEmpty,
  data,
  categories,
  onDelete,
  onEdit,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    id: null,
  });

  const handleDeleteModal = (id) => {
    if (openDeleteModal.open) {
      setOpenDeleteModal({ open: false, id: null });
    } else {
      setOpenDeleteModal({ open: true, id });
    }
  };

  const modalConfirmDelete = () => {
    onDelete(openDeleteModal.id);
    setOpenDeleteModal({ open: false, id: null });
  };

  const onChange = (key) => {
    return;
  };

  const handleEdit = (id) => {
    onEdit(id);
  };

  const genExtra = (item) => (
    <>
      {!item.active && !item?.workedTimeMonth && !item?.workedTimeYear && (
        <span style={{ marginRight: 15 }}>
          {" "}
          <Icon
            style={{ color: "#f6812a" }}
            type="warning"
            theme="filled"
          />{" "}
          {I18n.get("Required action")}
        </span>
      )}
      <EditOutlined
        onClick={(event) => {
          event.stopPropagation();
          handleEdit(item?.id);
        }}
      />
      <DeleteOutlined
        style={{ marginLeft: 15 }}
        onClick={(event) => {
          event.stopPropagation();
          handleDeleteModal(item?.id);
        }}
      />
    </>
  );

  const renderInfo = ({ label, value }) => {
    return (
      <S.ItemCustomer>
        <S.ItemCustomerLabel>{label}</S.ItemCustomerLabel>
        <S.ItemCustomerValue>{value}</S.ItemCustomerValue>
      </S.ItemCustomer>
    );
  };

  const renderHeader = ({ active, index, name }) => {
    if (active) {
      return `${I18n.get("Client active")} ${index} - ${name}`;
    } else if (!active) {
      return `${I18n.get("Ex client")} ${index} - ${name}`;
    } else {
      return "";
    }
  };

  const renderYearsWorkTime = (years) => {
    if (years && years !== 0) {
      if (years > 1) {
        return `${years} ${I18n.get("years")}`;
      } else {
        return `${years} ${I18n.get("year")}`;
      }
    } else {
      return "";
    }
  };

  const renderMonthsWorkTime = (months) => {
    if (months && months !== 0) {
      if (months > 1) {
        return `${months} ${I18n.get("months")}`;
      } else {
        return `${months} ${I18n.get("month")}`;
      }
    } else {
      return "";
    }
  };

  const renderWorkTime = ({ years, months }) => {
    const yearsTemp = renderYearsWorkTime(years);
    const monthsTemp = renderMonthsWorkTime(months);

    if (yearsTemp && monthsTemp) {
      return `${yearsTemp} ${I18n.get("and")} ${monthsTemp}`;
    }
    if (yearsTemp && !monthsTemp) {
      return `${yearsTemp}`;
    }
    if (!yearsTemp && monthsTemp) {
      return `${monthsTemp}`;
    }
  };

  const renderCategoryName = (category) => {
    const categoryObj = categories?.find((cat) => cat.code === category);
    return categoryObj
      ? categoryObj?.label
      : I18n.get("Error in get category name.");
  };

  return (
    <>
      <S.WrapperItemCustomer>
        {data.length ? (
          <Collapse onChange={onChange}>
            {data.map((item, index) => (
              <Panel
                key={item?.id}
                header={renderHeader({
                  active: item.active,
                  index: index + 1,
                  name: item.name,
                })}
                extra={genExtra(item)}
              >
                <>
                  {!item.active &&
                    !item?.workedTimeMonth &&
                    !item?.workedTimeYear && (
                      <p className="textAlert">
                        {I18n.get(
                          "How long have you worked with this client? Click on the edit icon."
                        )}
                      </p>
                    )}
                  <S.ContentItemCustomer>
                    {renderInfo({
                      label: I18n.get("Name"),
                      value: item?.name,
                    })}
                    {renderInfo({
                      label: I18n.get("Brand"),
                      value: item?.brand,
                    })}
                    {renderInfo({
                      label: I18n.get("Category"),
                      value: renderCategoryName(item?.category),
                    })}
                    {item.active
                      ? renderInfo({
                          label: I18n.get("Exclusivity"),
                          value: item?.nonCompete ? "Yes" : "No",
                        })
                      : null}
                    {item.active && item?.nonCompete
                      ? renderInfo({
                          label: I18n.get("Exclusivity expire in"),
                          value: item?.nonCompeteExpiresOn,
                        })
                      : null}
                    {item?.workedTimeMonth || item?.workedTimeYear
                      ? renderInfo({
                          label: I18n.get("Worked time"),
                          value: renderWorkTime({
                            years: item?.workedTimeYear,
                            months: item?.workedTimeMonth,
                          }),
                        })
                      : null}
                  </S.ContentItemCustomer>
                </>
              </Panel>
            ))}
          </Collapse>
        ) : (
          <S.ItemCustomerFormEmpty>{textEmpty}</S.ItemCustomerFormEmpty>
        )}
      </S.WrapperItemCustomer>

      <ModalBp
        visible={openDeleteModal.open}
        bodyText={I18n.get("Do you want to delete?")}
        subBodyText={I18n.get("You will lose this item.")}
        okText={I18n.get("Delete")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={modalConfirmDelete}
        onCancel={handleDeleteModal}
        typeModal="isConfirm"
        isDanger
      />
    </>
  );
};
