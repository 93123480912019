import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Radio } from "antd";
import { useHistory, Link } from "react-router-dom";
import constants from "../../../../../../../constants";
import { Input } from "../../../../../../components/UI/Input";
import { InputMask } from "../../../../../../components/UI/InputMask";
import { SelectOne } from "../../../../../../components/UI/SelectOne";
import { FileUpload } from "../../../../../../components/UI/FileUpload";
import { ButtonBpool } from "../../../../../../components/UI/ButtonBpool";
import { hasError, clearErrorInput } from "../../../../utils/errorsInput";

import * as S from "../../styles";

export const FormFormDeRecebimento = ({
  inputsForm,
  handleChangeInputForm,
  handleChangeInputs,
  errorsInputs,
  handleSetErrorsInputs,
  isLoadingGet,
  isDrawer,
  paymentBeneficiaries,
  bPaymentId,
  handleDeleteUploadSlip,
  handleUploadSlip,
}) => {
  let history = useHistory();
  const [billingValue, setBillingValue] = useState(0);
  const [typeBilling, setTypeBilling] = useState(1);

  useEffect(() => {
    if (inputsForm?.country?.code !== "BRA") {
      setBillingValue(3);
    }
  }, [inputsForm]);

  useEffect(() => {
    if (
      inputsForm?.country?.code !== "BRA" &&
      billingValue === 3 &&
      !inputsForm?.paymentMethod
    ) {
      handleChangeInputForm("paymentMethod", "SWIFT");
    }
  }, [billingValue, inputsForm]);

  const handleGoToAddNewAcc = () => {
    console.log("bPaymentId", bPaymentId);
    const dataSubirNf = {
      id: bPaymentId,
      urlBack: `/fornecedor/inicio`,
    };
    localStorage.setItem(
      "@BPOOL-accounts-dataSubirNf",
      JSON.stringify(dataSubirNf)
    );
    history.push(`/fornecedor/perfil/dados-bancarios`);
  };

  const handleChangeCheckbox = (e) => {
    if (e.target.value) {
      handleChangeInputForm("isSlip", true);
    } else {
      handleChangeInputForm("isSlip", false);
    }
    setTypeBilling(e.target.value);
  };

  const handleSelectedSlip = (file) => {
    const errorsArr = clearErrorInput({
      field: "slip",
      arr: errorsInputs,
    });
    handleSetErrorsInputs(errorsArr);
    handleUploadSlip(file);
  };

  return (
    <>
      <h3 style={{ marginTop: 2 }}>{I18n.get("Forma de recebimento")}</h3>

      <S.RadioAccountOrBoleto>
        <Radio.Group onChange={handleChangeCheckbox} value={typeBilling}>
          <Radio value={1}>{I18n.get("Conta")}</Radio>
          <Radio value={2}>{I18n.get("Boleto")}</Radio>
        </Radio.Group>
      </S.RadioAccountOrBoleto>

      {typeBilling === 1 ? (
        <>
          <Row>
            <Col xs={24} style={{ padding: "0 0" }}>
              <>
                {paymentBeneficiaries?.length ? (
                  <>
                    <p>{I18n.get("Selecione a conta que deseja receber")}</p>
                    <div style={{ height: 80, marginTop: 8 }}>
                      <SelectOne
                        options={paymentBeneficiaries}
                        value={inputsForm?.paymentBeneficiaryId}
                        defaultValue={inputsForm?.paymentBeneficiaryId}
                        loading={isLoadingGet}
                        onChange={(value) =>
                          handleChangeInputForm("paymentBeneficiaryId", value)
                        }
                        required
                        placeholder={I18n.get("Forma de recebimento")}
                        error={hasError({
                          arr: errorsInputs,
                          field: "paymentBeneficiaryId",
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "paymentBeneficiaryId",
                            arr: errorsInputs,
                          });
                          handleSetErrorsInputs(errorsArr);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <p>
                    {I18n.get(
                      "Atenção: A conta deve ser jurídica e ser da mesma titularidade da razão social"
                    )}
                  </p>
                )}
                <S.LinkNewAcc>
                  <button onClick={handleGoToAddNewAcc}>
                    {I18n.get(
                      paymentBeneficiaries?.length
                        ? "Adicionar nova forma de recebimento"
                        : "Adicionar forma de recebimento"
                    )}
                  </button>
                </S.LinkNewAcc>
              </>
            </Col>
          </Row>
          {hasError({
            arr: errorsInputs,
            field: "paymentBeneficiaryId",
          }) ? (
            <S.ErrorMsg
              style={{ margin: 0, position: "relative", top: "-20px" }}
            >
              {hasError({
                arr: errorsInputs,
                field: "paymentBeneficiaryId",
              })}
            </S.ErrorMsg>
          ) : null}
        </>
      ) : (
        <Row>
          <Col xs={12} style={{ paddingLeft: 0 }}>
            {inputsForm?.country?.code === "BRA" ? (
              <InputMask
                id="paymentBeneficiaryId"
                label={I18n.get("Documento do Beneficiário")}
                defaultValue={inputsForm?.paymentBeneficiaryId}
                onChange={(value) =>
                  handleChangeInputForm("paymentBeneficiaryId", value?.value)
                }
                mask="##.###.###/####-##"
                required
                error={hasError({
                  arr: errorsInputs,
                  field: "paymentBeneficiaryId",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "paymentBeneficiaryId",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
            ) : (
              <Input
                placeholder={I18n.get("Registration ID")}
                required
                label
                value={inputsForm?.paymentBeneficiaryId}
                onChange={(value) =>
                  handleChangeInputForm("paymentBeneficiaryId", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "paymentBeneficiaryId",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "paymentBeneficiaryId",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
            )}
          </Col>
          <Col xs={12} style={{ paddingRight: 0 }}>
            <Input
              placeholder={I18n.get("Nome do Beneficiário")}
              required
              label
              value={inputsForm?.paymentBeneficiaryName}
              onChange={(value) =>
                handleChangeInputForm("paymentBeneficiaryName", value)
              }
              error={hasError({
                arr: errorsInputs,
                field: "paymentBeneficiaryName",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "paymentBeneficiaryName",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} style={{ padding: "0 0", marginBottom: 24 }}>
            <h3>{I18n.get("Upload do Boleto")}</h3>
            <FileUpload
              cbFiles={(file) => handleSelectedSlip(file)}
              multiple={false}
              initialFiles={[]}
              acceptedTypes={[
                {
                  label: "PDF",
                  type: "application/pdf",
                },
              ]}
              cbDeleteFile={(fileOrId) => handleDeleteUploadSlip(fileOrId)}
            />
            {hasError({
              arr: errorsInputs,
              field: "slip",
            }) ? (
              <S.ErrorMsg>
                {hasError({
                  arr: errorsInputs,
                  field: "slip",
                })}
              </S.ErrorMsg>
            ) : null}
          </Col>
        </Row>
      )}
    </>
  );
};
