import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_call = (data) => {
    return (dispatch, getState) => {

        const { token } = data;

        var config = { headers: { Authorization: token } };

        return axios.put(`${Config.API_BASE_URL}/nf-extraordinario/${data.publicKey}/invoice`, data, config)
            .then(response => {

                const payload = {
                    ...response.data,
                    errors: {},
                    task: {
                        result: true
                    }
                };

                return payload;
            })
            .catch(error => {

                const payload = {
                    errors: error.response.data,
                    task: {
                        result: false
                    }
                };

                return payload;

            });
    };
}

export default api_call;