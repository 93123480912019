import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import NumericField from '../../form/numeric-field';
import RadioMultiField from '../../form/radio-multi-field';
import CheckboxMultiField from '../../form/checkbox-multi-field';
import CheckboxSwitchField from '../../form/checkbox-switch-field';

import SubmitButtonField from '../../form/submit-button-field';

import { ClientProjectUniversalQuotationSectionType, ClientProjectUniversalQuotationSectionTypeStrings } from '../../../model';

class SettingsForm extends React.Component {
    constructor(props) {

        super();

        const { settings } = props;

        this.state = {
            fields: {
                biddingPhaseMaxInvites: {
                    onChange: this.onChangeForm.bind(this, 'biddingPhaseMaxInvites'),
                    successIcon: false,
                },
                enabledBPoolQuotationItemReview: {
                    onChange: this.onChangeForm.bind(this, 'enabledBPoolQuotationItemReview'),
                    successIcon: false,
                },
                enabledUniversalQuotationSectionTypes: {
                    onChange: this.onChangeFormCheckboxMultiDictonary.bind(this, 'enabledUniversalQuotationSectionTypes'),
                    successIcon: false,
                    checkmarkStyle: {
                        backgroundColor: '#ffffff'
                    }
                },

            },
            errors: {},
            form: { ...settings },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
    }


    componentWillUnmount() {
    }


    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormCheckboxMultiDictonary(prop, e) {

        const value = e.target.value;
        const checked = e.target.checked;



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop][value] = checked;

            nextState.errors[prop] = null;

            return nextState;
        });
    }

     onSubmitForm(e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ task: submit_task });

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.state.form
        };

        this.props.update_settings(data)
            .then(({ task, errors }) => {

                this.setState({ task: task, errors: errors });

                if (task.result) {
                    this.props.onClose();
                }

            });
    }

    render() {

        let sectionTypes = [
            { code: "Assets", label: I18n.get("Itens Internos") },
            { code: "Items", label: I18n.get("Itens Externos") },
            { code: "GenericItems", label: I18n.get("Itens Livres") },
            { code: "PercItems", label: I18n.get("Itens Percentuais") },
            { code: "HourItems", label: I18n.get("Itens por Hora") }
        ];

        // Converte de dicionary para array
        const enabledUniversalQuotationSectionTypesValue = sectionTypes.filter((item) => {

            return this.state.form.enabledUniversalQuotationSectionTypes && this.state.form.enabledUniversalQuotationSectionTypes[item.code];
             
        }).map((item) => item.code);

        return (
            <div>

                <br />

                <table className="table" style={{ border: 'none', width: '90%' }}>
                    <tbody>
                        <tr>
                            <th>
                                {I18n.get('Fase')}
                            </th>
                            <th>
                                {I18n.get('Parâmetro')}
                            </th>
                            <th>
                                {I18n.get('Valor')}
                            </th>
                        </tr>
                        <tr>
                            <td>
                                {I18n.get('Seleção de Parceiro')}
                            </td>
                            <td>
                                {I18n.get("Número máximo de convites")}
                            </td>
                            <td>
                                <NumericField
                                    {...this.state.fields.biddingPhaseMaxInvites}
                                    value={this.state.form.biddingPhaseMaxInvites}
                                    error={this.state.errors.biddingPhaseMaxInvites}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {I18n.get('Definições de Projeto')}
                            </td>
                            <td>
                                {I18n.get("Revisão BPool Item a Item na Proposta")}
                            </td>
                            <td>
                                <RadioMultiField
                                    {...this.state.fields.enabledBPoolQuotationItemReview}
                                    value={this.state.form.enabledBPoolQuotationItemReview}
                                    error={this.state.errors.enabledBPoolQuotationItemReview}
                                    values={[{ code: 'false', label: I18n.get('Desabilitado') }, { code: 'true', label: I18n.get('Habilitado') }]}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {I18n.get('Definições de Projeto')}
                            </td>
                            <td>
                                {I18n.get("Tipo de Seções Permitidas na Proposta")}
                            </td>
                            <td>

                                <CheckboxMultiField
                                    {...this.state.fields.enabledUniversalQuotationSectionTypes}
                                    value={enabledUniversalQuotationSectionTypesValue}
                                    error={this.state.errors.enabledUniversalQuotationSectionTypes}
                                    values={sectionTypes} />

                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />

                <SubmitButtonField
                    label={I18n.get("Salvar")}
                    onClick={this.onSubmitForm.bind(this)}
                    className="button is-black"
                    task={this.state.task} />

            </div>
        );
    }
}

export default SettingsForm;
