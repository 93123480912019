import React from 'react';

import { I18n } from 'aws-amplify';

import BriefingItem from './_project-briefing-item';

import { Collapse, Icon } from 'antd';


import WYSIWYGEditor from './form/wysiwyg-editor';
const { Panel } = Collapse;

class ReviewStep5 extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {

        return (
            <div className="card">
          
                <div className="card-content">

                    <Collapse accordion>

                        {
                            this.props.briefings && Object.keys(this.props.briefings).map((code, index) => {

                                const item = this.props.briefings[code];

                                const file = this.props.files && this.props.files.find((f) => item.fileId == f.id);

                                return (
                                    <Panel header={item.label} key={index}>
                                        {file &&
                                            <p className="fileBriefing">
                                                <strong>{I18n.get("Arquivo:")}</strong> <br /><a href={file.url}  download><Icon type="file-text" theme="filled" /> {file.name}</a>
                                            </p>
                                        }
                                        <br />
                                        <WYSIWYGEditor content={item.content} readOnly={true} />
                                    </Panel>
                                );
                            }
                            )
                        }
                    </Collapse>
                </div>
            </div>
        );
    }
}

export default ReviewStep5;
