import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Modal } from "antd";
import { Row, Col, Select } from "antd";
import "react-day-picker/lib/style.css";
import "../../../pages/ProjectsList/Meetings/css-day-picker.css";
import { closeQuickMeeting } from "../../../store/actions/quickMeeting";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import { useRegistrationFlowLeader } from "../../../hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook";
import { SelectOne } from "../SelectOne";

import "./styles.css";
import * as S from "./stylesJS";

import { RadioSelectMeeting } from "./components/RadioSelectMeeting";
import { RenderScreen } from "./RenderScreen";

const QuickMeeting = ({ visible, projectId, partnerId }) => {
  const dispatch = useDispatch();
  let params = useParams();
  const auth = useSelector((state) => state.auth);
  const { getProjectsToCombo } = useProjects();
  const { getTimezonesId } = useRegistrationFlowLeader();
  const [valueSelect, setValueSelect] = useState(null);
  const [timezoneUser, setTimezoneUser] = useState("");
  const [optionsProjects, setOptionsProjects] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [typeLabelMeetingSelect, setTypeLabelMeetingSelect] = useState("");

  const NOT_SELECTED_PROJECT = "Você precisa selecionar um projeto para seguir";

  const renderTitle = (value) => {
    const SelectsTitle = {
      4: I18n.get(
        "Use os campos abaixo para escolher as melhores datas para sua reunião"
      ),
      5: I18n.get(
        "Na fase de Briefing, nosso algoritmo fez o matching e encontrou alguns parceiros que podem ajudar com seu projeto."
      ),
      6: I18n.get(
        "Presentation é o passo onde nosso algoritmo fez o matching e encontrou alguns parceiros que podem ajudar com seu projeto."
      ),
      default: "",
    };

    return SelectsTitle[value] || SelectsTitle.default;
  };

  const { Option } = Select;

  const handleCloseQuickMeetings = () => {
    setSelectedProject(params?.projectId || "");
    setValueSelect(null);
    dispatch(closeQuickMeeting());
  };

  const loadOptionsProjects = async (statusProject) => {
    setIsLoadingProjects(true);
    setSelectedProject(params?.projectId || "");

    const response = await getProjectsToCombo({ statusProject });

    if (response.success) {
      const opts = response?.data?.map((item) => ({
        code: item?.id,
        label: item?.projectName,
        status: item?.status,
        projectSKU: item?.projectSKU,
      }));
      setOptionsProjects(response?.data);
    }

    setIsLoadingProjects(false);
  };

  const handleGettimezone = async () => {
    const response = await getTimezonesId(
      auth?.logged_user?.attributes["custom:timezone"]
    );
    if (response?.value) {
      setTimezoneUser(response?.text);
    }
  };

  useEffect(() => {
    if (selectedProject && optionsProjects.length) {
      const finded = optionsProjects.find(
        (project) => project.id === selectedProject
      );

      setValueSelect(finded?.status);
    }
  }, [optionsProjects, selectedProject]);

  // useEffect(() => {
  //   if (projectId && optionsProjects.length) {
  //     setSelectedProject(projectId);
  //   }
  // }, [projectId, optionsProjects]);

  useEffect(() => {
    loadOptionsProjects();
    handleGettimezone();
  }, [visible]);

  useEffect(() => {
    if (!timezoneUser) {
      handleGettimezone();
    }
  }, [auth]);

  return (
    <Modal
      title={I18n.get("Agenda")}
      centered
      visible={visible}
      onCancel={() => handleCloseQuickMeetings()}
      footer={[]}
      wrapClassName="quickmeeting-modal"
      destroyOnClose
    >
      {setTimezoneUser ? (
        <Row>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <S.BoxTimeZone>
              {I18n.get(
                "Atenção: Todos os horários abaixo já estão no seu fuso horário"
              )}{" "}
              <b>{timezoneUser || "--"}</b>
            </S.BoxTimeZone>
          </Col>
        </Row>
      ) : null}

      <Row>
        {!selectedProject ? (
          <Col span={24}>
            <S.TitleCentral>
              <p>{I18n.get(NOT_SELECTED_PROJECT)}</p>
            </S.TitleCentral>
          </Col>
        ) : null}

        <Col span={8} offset={8}>
          <S.WrapperSelect style={{ marginTop: !selectedProject ? 20 : null }}>
            {isLoadingProjects ? (
              <div>{I18n.get("Carregando itens...")}</div>
            ) : (
              <Select
                style={{ width: "100%" }}
                placeholder={I18n.get("Selecionar projeto")}
                onChange={(e) => {
                  setSelectedProject(e ? e : "");
                }}
                onSelect={(e) => {
                  setSelectedProject(e ? e : "");
                }}
                onSearch={(e) => {
                  setSelectedProject(e ? e : "");
                }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                defaultValue={selectedProject ? selectedProject : ""}
                // disabled={projectId ? true : false}
              >
                {optionsProjects?.length &&
                  optionsProjects?.map((item, index) => {
                    return (
                      <Option value={item?.id}>{item?.projectName}</Option>
                    );
                  })}
              </Select>
            )}
          </S.WrapperSelect>
        </Col>

        <Col span={24}>
          <RadioSelectMeeting
            valueSelect={valueSelect}
            setValueSelect={setValueSelect}
            selectedProject={selectedProject}
            typeLabelMeetingSelect={(label) => setTypeLabelMeetingSelect(label)}
          />

          <Row>
            <Col offset={6} span={12}>
              <S.Explanation>
                <p>{renderTitle(valueSelect)}</p>
              </S.Explanation>
            </Col>
          </Row>

          <RenderScreen
            projectId={projectId}
            selected={valueSelect}
            isLoadingProjects={isLoadingProjects}
            selectedProject={selectedProject}
            meetingType={valueSelect}
            typeLabelMeetingSelect={typeLabelMeetingSelect}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default QuickMeeting;
