import React, { Fragment } from "react";

import { I18n } from "aws-amplify";

import "./_project-details-eventCustom.css";

import constants from "../constants";

import {
  CustomEventBriefingType,
  CustomEventBriefingTypeNames,
} from "../model";

import { Collapse } from "antd";

import { CardBorder } from "../modules/components/Cards";

import FormatCurrency from "./ui/format-currency";

const { Panel } = Collapse;

class EventCustomDetails extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    const customEvent = this.props.customEvent || {};
    const currency = this.props.currency || {};
    const partner = this.props.partner || {};
    const project = this.props.project || {};



    const quotationPrev = this.props.eventQuotations[0] || {};

    const quotation =
      (partner.projectMatches &&
        partner.projectMatches.find(
          (item) => item.projectId == this.props.match.params.projectId
        )) || quotationPrev
      {};

    const eventTypes = [
      CustomEventBriefingType.Event,
      CustomEventBriefingType.Shopper,
      CustomEventBriefingType.Incentive,
    ];

    const requiresInvitation =
      this.props.bp.event_INVITATIONS &&
      this.props.bp.event_INVITATIONS.find(
        (a) =>
          new String(a.code).valueOf() ==
          new String(customEvent.requiresInvitation).valueOf()
      );

    const venueType =
      this.props.bp.event_VENUE_TYPES &&
      this.props.bp.event_VENUE_TYPES.find(
        (a) => a.code == customEvent.venueType
      );

    const venueRegion =
      this.props.bp.event_VENUE_REGIONS &&
      this.props.bp.event_VENUE_REGIONS.find(
        (a) => a.code == customEvent.venueRegion
      );

    const digitalPlatformType =
      this.props.bp.event_DIGITAL_PLATFORM_TYPES &&
      this.props.bp.event_DIGITAL_PLATFORM_TYPES.find(
        (a) => a.code == customEvent.digitalPlatformType
      );

    const digitalPlatformIntegrationType =
      this.props.bp.event_DIGITAL_PLATFORM_INTEGRATIONS &&
      this.props.bp.event_DIGITAL_PLATFORM_INTEGRATIONS.find(
        (a) => a.code == customEvent.digitalPlatformIntegrationType
      );

    const digitalRestrictionType =
      this.props.bp.event_DIGITAL_INTERNET_RESTRICTIONS &&
      this.props.bp.event_DIGITAL_INTERNET_RESTRICTIONS.find(
        (a) => a.code == customEvent.digitalRestrictionType
      );

    const accommodationType =
      this.props.bp.event_ACCOMMODATION_TYPES &&
      this.props.bp.event_ACCOMMODATION_TYPES.find(
        (a) => a.code == customEvent.accommodationType
      );

    const logisticsAgency =
      this.props.bp.event_LOGISTICS_AGENCY &&
      this.props.bp.event_LOGISTICS_AGENCY.find(
        (a) => a.code == customEvent.logisticsAgency
      );

    const logisticsAgencyInitial =
      this.props.bp.event_LOGISTICS_AGENCY_INTIAL &&
      this.props.bp.event_LOGISTICS_AGENCY_INTIAL &&
      this.props.bp.event_LOGISTICS_AGENCY_INTIAL.find(
        (a) => a.code == customEvent.logisticsAgencyInitial
      );

    const setDesignType =
      this.props.bp.event_SETDESIGN_TYPES &&
      this.props.bp.event_SETDESIGN_TYPES.find(
        (a) => a.code == customEvent.setDesignType
      );

    const furnitureType =
      this.props.bp.event_FURNITURE_TYPES &&
      this.props.bp.event_FURNITURE_TYPES.find(
        (a) => a.code == customEvent.furnitureType
      );

    const avEquipamentType =
      this.props.bp.event_AVEQUIPAMENT_TYPES &&
      this.props.bp.event_AVEQUIPAMENT_TYPES.find(
        (a) => a.code == customEvent.avEquipamentType
      );

    const souvenirType =
      this.props.bp.event_SOUVENIR_TYPES &&
      this.props.bp.event_SOUVENIR_TYPES.find(
        (a) => a.code == customEvent.souvenirType
      );

    const promoMaterialType =
      this.props.bp.event_MATERIAL_TYPES &&
      this.props.bp.event_MATERIAL_TYPES.find(
        (a) => a.code == customEvent.promoMaterialType
      );

    const digitalFoodType =
      this.props.bp.event_DIGITAL_FOOD_TYPES &&
      this.props.bp.event_DIGITAL_FOOD_TYPES.find(
        (a) => a.code == customEvent.digitalFoodType
      );

   

    return (
      <div>
        <CardBorder>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header={I18n.get("Briefing")} key="1">
              <p className="prop">
                <span className="name">{I18n.get("Tipo:")}</span>
                <span className="value">
                  {I18n.get(
                    CustomEventBriefingTypeNames[customEvent.briefingType]
                  )}
                </span>
              </p>
              <p className="prop">
                <span className="name">{I18n.get("Briefing:")}</span>
                <span className="value">{customEvent.briefing}</span>
              </p>
            </Panel>
            {customEvent.isCommunication === false &&
              customEvent.isPromotional === false && (
                <Panel header={I18n.get("Sobre o evento")} key="2">
                  <p className="prop">
                    {customEvent.isEventOrDigital && (
                      <span className="name">
                        {I18n.get("Qual é o objetivo do evento?")}
                      </span>
                    )}
                    {(customEvent.isShopper || customEvent.isIncentive) && (
                      <span className="name">
                        {I18n.get("Qual é o objetivo do projeto?")}
                      </span>
                    )}
                    <span className="value">{customEvent.message}</span>
                  </p>

                  <p className="prop">
                    {customEvent.isEvent && (
                      <span className="name">
                        {I18n.get("Qual o tipo de evento?")}
                      </span>
                    )}
                    {(customEvent.isShopper || customEvent.isIncentive) && (
                      <span className="name">
                        {I18n.get("Qual o tipo de projeto?")}
                      </span>
                    )}
                    <span className="name"></span>
                    <span className="value">
                      {customEvent.isEvent &&
                        customEvent.eventType &&
                        customEvent.eventType.map((v, index) => {
                          const item =
                            this.props.bp.eventCats &&
                            this.props.bp.eventCats.find((a) => a.code == v);
                          return (
                            item && (
                              <span class="multi">
                                {item && item.label}
                                {item.code == "OUTR" &&
                                  customEvent.eventTypeDesc}
                              </span>
                            )
                          );
                        })}

                      {customEvent.isShopper &&
                        customEvent.eventType &&
                        customEvent.eventType.map((v, index) => {
                          const item =
                            this.props.bp.shopperTypes &&
                            this.props.bp.shopperTypes.find((a) => a.code == v);
                          return (
                            item && (
                              <span class="multi">
                                {item && item.label}{" "}
                                {item.code == "OUTR" &&
                                  customEvent.eventTypeDesc}{" "}
                              </span>
                            )
                          );
                        })}

                      {customEvent.isIncentive &&
                        customEvent.eventType &&
                        customEvent.eventType.map((v, index) => {
                          const item =
                            this.props.bp.incentiveTypes &&
                            this.props.bp.incentiveTypes.find(
                              (a) => a.code == v
                            );
                          return (
                            item && (
                              <span class="multi">
                                {item && item.label}{" "}
                                {item.code == "OUTR" &&
                                  customEvent.eventTypeDesc}{" "}
                              </span>
                            )
                          );
                        })}
                    </span>
                  </p>

                  <p className="prop">
                    {customEvent.isEventOrDigital && (
                      <span className="name">
                        {I18n.get(
                          "Qual é a mensagem que os participantes devem levar do evento?"
                        )}
                      </span>
                    )}
                    {(customEvent.isShopper || customEvent.isIncentive) && (
                      <span className="name">
                        {I18n.get(
                          "Qual é a mensagem que os participantes devem levar do projeto?"
                        )}
                      </span>
                    )}
                    <span className="value">{customEvent.message}</span>
                  </p>
                </Panel>
              )}

            {customEvent.isCommunication === false &&
              customEvent.isPromotional === false && (
                <Panel header={I18n.get("Data e horário")} key="3">
                  {this.props.scheduleType == "DATE" && (
                    <p className="prop">
                      <span className="name">{I18n.get("Data evento:")}</span>
                      <span className="value">{this.props.scheduleDate}</span>
                    </p>
                  )}

                  {this.props.scheduleType == "PERIOD" && (
                    <p className="prop">
                      <span className="name">{I18n.get("Data evento:")}</span>
                      <span className="value">
                        {this.props.scheduleStartDate} -{" "}
                        {this.props.scheduleEndDate}
                      </span>
                    </p>
                  )}

                  <p className="prop">
                    <span className="name">{I18n.get("Período:")}</span>
                    <span className="value">
                      {this.props.timePeriod &&
                        this.props.timePeriod.map((v, index) => {
                          const item = constants.EVENT_TIME_PERIODS.find(
                            (a) => a.code == v
                          );
                          return (
                            <span class="multi"> {item && item.label}</span>
                          );
                        })}
                    </span>
                  </p>

                  <p className="prop">
                    <span className="name">
                      {I18n.get("O evento se repete por mais dias?")}
                    </span>
                    <span className="value">
                      {customEvent.isRecurring === true ? "Sim" : "Não"}
                    </span>
                  </p>

                  {customEvent.recurringDesc && (
                    <p className="prop">
                      <span className="name">{I18n.get("Frequência:")}</span>
                      <span className="value">{customEvent.recurringDesc}</span>
                    </p>
                  )}
                </Panel>
              )}

            {customEvent.isCommunication === false &&
              customEvent.isPromotional === false && (
                <Panel header={I18n.get("Público")} key="4">
                  <p className="prop">
                    <span className="name">
                      {I18n.get("Qual é o público?")}
                    </span>
                    <span className="value">{customEvent.audience}</span>
                  </p>

                  <p className="prop">
                    {customEvent.isEventOrDigital && (
                      <span className="name">
                        {I18n.get("Para quantas pessoas será o evento?")}
                      </span>
                    )}
                    {(customEvent.isShopper || customEvent.isIncentive) && (
                      <span className="name">
                        {I18n.get(
                          "Quantas pessoas pretendem que sejam impactadas?"
                        )}
                      </span>
                    )}
                    <span className="value">{customEvent.eventSize}</span>
                  </p>

                  <p className="prop">
                    {customEvent.isEventOrDigital && (
                      <span className="name">
                        {I18n.get("Como os participantes serão convidados?")}
                      </span>
                    )}
                    {(customEvent.isShopper || customEvent.isIncentive) && (
                      <span className="name">
                        {I18n.get(
                          "Pensaram em algum tipo de convite ou chamada?"
                        )}
                      </span>
                    )}
                    <span className="value">
                      {requiresInvitation && requiresInvitation.label}
                    </span>
                  </p>

                  {(customEvent.isShopper || customEvent.isIncentive) &&
                    customEvent.invitationChannel && (
                      <p className="prop">
                        <span className="name">
                          {I18n.get("Quais os canais a serem trabalhados?")}
                        </span>
                        <span className="value">
                          {customEvent.invitationChannel}
                        </span>
                      </p>
                    )}
                </Panel>
              )}

            {customEvent.isDigital === false &&
              customEvent.isCommunication === false &&
              customEvent.isPromotional === false && (
                <Panel header={I18n.get("Localização")} key="5">
                  <p className="prop">
                    <span className="name">
                      {I18n.get("Onde vai acontecer o evento?")}
                    </span>
                    <span className="value">
                      {venueType && venueType.label} &nbsp;
                      {venueType && venueType.code == "VENUE" && (
                        <span className="desc">{customEvent.venueName}</span>
                      )}
                      {venueType && venueType.code == "SIMILAR_TO" && (
                        <span className="desc">{customEvent.venueDesc}</span>
                      )}
                    </span>
                  </p>

                  <p className="prop">
                    <span className="name">
                      {I18n.get("Tem região definida? Alguma de preferência?")}
                    </span>
                    <span className="value">
                      {venueRegion && venueRegion.label} &nbsp;
                      {venueRegion && venueRegion.code == "YES" && (
                        <span className="desc">
                          {customEvent.venueRegionName}
                        </span>
                      )}
                    </span>
                  </p>

                  {customEvent.venueComments && (
                    <p className="prop">
                      <span className="name">{I18n.get("Observações")}</span>
                      <span className="value">{customEvent.venueComments}</span>
                    </p>
                  )}
                </Panel>
              )}

            {customEvent.isDigital === true && (
              <Panel header={I18n.get("Plataforma")} key="6">
                <p className="prop">
                  <span className="name">
                    {I18n.get("Tem preferencia de alguma plataforma")}
                  </span>
                  <span className="value">
                    {digitalPlatformType && digitalPlatformType.label} &nbsp;
                    {digitalPlatformType &&
                      digitalPlatformType.code == "YES" && (
                        <span className="desc">
                          {customEvent.digitalPlatformName}
                        </span>
                      )}
                    {digitalPlatformType &&
                      digitalPlatformType.code == "SIMILAR_TO" && (
                        <span className="desc">
                          {customEvent.digitalPlatformExamples}
                        </span>
                      )}
                  </span>
                </p>

                <p className="prop">
                  <span className="name">
                    {I18n.get(
                      "Precisamos integrar a plataforma do evento com alguma interna"
                    )}
                  </span>
                  <span className="value">
                    {digitalPlatformIntegrationType &&
                      digitalPlatformIntegrationType.label}{" "}
                    &nbsp;
                    {digitalPlatformIntegrationType &&
                      digitalPlatformIntegrationType.code == "YES" && (
                        <span className="desc">
                          {customEvent.digitalPlatformIntegrationDesc}
                        </span>
                      )}
                  </span>
                </p>

                <p className="prop">
                  <span className="name">
                    {I18n.get(
                      "Existe alguma restrição de internet em relação aos convidados que precisamos pensar"
                    )}
                  </span>
                  <span className="value">
                    {digitalRestrictionType && digitalRestrictionType.label}
                  </span>
                </p>
              </Panel>
            )}

            {customEvent.isEventOrDigital && (
              <Panel header={I18n.get("Estrutura")} key="7">
                <p className="prop">
                  <span className="name">
                    {I18n.get(
                      "Será necessário cenografia ou alguma estrutura específica?"
                    )}
                  </span>
                  <span className="value">
                    {setDesignType && setDesignType.label} &nbsp;
                    {setDesignType && setDesignType.code == "YES" && (
                      <span className="desc">{customEvent.setDesignDesc}</span>
                    )}
                  </span>
                </p>

                {customEvent.isDigital && (
                  <Fragment>
                    <p className="prop">
                      <span className="name">
                        {I18n.get(
                          "Será necessário pensarmos em algo de alimentos e bebida (Delivery)"
                        )}
                      </span>
                      <span className="value">
                        {digitalFoodType && digitalFoodType.label} &nbsp;
                        {digitalFoodType && digitalFoodType.code == "YES" && (
                          <span className="desc">
                            {customEvent.digitalFoodDesc}
                          </span>
                        )}
                      </span>
                    </p>
                  </Fragment>
                )}

                {customEvent.isEvent && (
                  <Fragment>
                    <p className="prop">
                      <span className="name">
                        {I18n.get("Mobiliário e paisagismo.")}
                      </span>
                      <span className="value">
                        {furnitureType && furnitureType.label} &nbsp;
                        {furnitureType && furnitureType.code == "YES" && (
                          <span className="desc">
                            {customEvent.furnitureDesc}
                          </span>
                        )}
                      </span>
                    </p>

                    <p className="prop">
                      <span className="name">
                        {I18n.get("Equipamento áudio & vídeo.")}
                      </span>
                      <span className="value">
                        {avEquipamentType && avEquipamentType.label} &nbsp;
                        {avEquipamentType && avEquipamentType.code == "YES" && (
                          <span className="desc">
                            {customEvent.avEquipamentDesc}
                          </span>
                        )}
                      </span>
                    </p>
                  </Fragment>
                )}
              </Panel>
            )}

            {customEvent.isShopper && (
              <Panel header={I18n.get("Produto")} key="8">
                <p className="prop">
                  <span className="name">
                    {I18n.get("Quais os diferencias do seu produto?")}
                  </span>
                  <span className="value">{customEvent.productAdvantage}</span>
                </p>

                <p className="prop">
                  <span className="name">
                    {I18n.get("Quais os benefícios do seu produto?")}
                  </span>
                  <span className="value">{customEvent.productBenefits}</span>
                </p>

                <p className="prop">
                  <span className="name">
                    {I18n.get(
                      "Qual o conceito e posicionamento do seu produto?"
                    )}
                  </span>
                  <span className="value">{customEvent.productConcept}</span>
                </p>
              </Panel>
            )}

            {customEvent.isIncentive && (
              <Panel header={I18n.get("Produto")} key="8">
                <p className="prop">
                  <span className="name">
                    {I18n.get("Quais os principais concorrentes?")}
                  </span>
                  <span className="value">
                    {customEvent.productCompetitors}
                  </span>
                </p>

                <p className="prop">
                  <span className="name">
                    {I18n.get(
                      "Quais são os pontos favoráveis e desfavoráveis em relação à concorrência?"
                    )}
                  </span>
                  <span className="value">
                    {customEvent.productProsAndCons}
                  </span>
                </p>

                <p className="prop">
                  <span className="name">
                    {I18n.get(
                      "Qual o conceito e posicionamento do seu produto?"
                    )}
                  </span>
                  <span className="value">{customEvent.productConcept}</span>
                </p>
              </Panel>
            )}

            {customEvent.isDigital === false &&
              customEvent.isCommunication === false &&
              customEvent.isPromotional === false && (
                <Panel header={I18n.get("A & B")} key="9">
                  <p className="prop">
                    <span className="name">
                      {I18n.get(
                        "Vai precisar de algum desses serviços de Catering?"
                      )}
                    </span>
                    <span className="value">
                      {customEvent.cateringType &&
                        customEvent.cateringType.length == 0 && (
                          <>{I18n.get("Nenhum")}</>
                        )}

                      {customEvent.cateringType &&
                        customEvent.cateringType.map((v, index) => {
                          const item =
                            this.props.bp.event_CATERING_TYPES &&
                            this.props.bp.event_CATERING_TYPES.find(
                              (a) => a.code == v
                            );
                          return (
                            <span class="multi"> {item && item.label}</span>
                          );
                        })}
                    </span>
                  </p>

                  <p className="prop">
                    <span className="name">
                      {I18n.get("Qual o tipo de alimentação?")}
                    </span>
                    <span className="value">
                      {customEvent.foodType &&
                        customEvent.foodType.length == 0 && (
                          <>{I18n.get("Nenhum")}</>
                        )}

                      {customEvent.foodType &&
                        customEvent.foodType.map((v, index) => {
                          const item =
                            this.props.bp.event_FOOD_TYPES &&
                            this.props.bp.event_FOOD_TYPES.find(
                              (a) => a.code == v
                            );
                          return (
                            <span class="multi"> {item && item.label}</span>
                          );
                        })}
                    </span>
                  </p>

                  <p className="prop">
                    <span className="name">
                      {I18n.get("Tem alguma restriçao alimentar?")}
                    </span>
                    <span className="value">
                      {new String(customEvent.foodRestriction) == "true"
                        ? "Sim"
                        : "Não"}
                    </span>
                  </p>

                  {new String(customEvent.foodRestriction) == "true" && (
                    <p className="prop">
                      <span className="name">
                        {I18n.get("Restriçao alimentar:")}
                      </span>
                      <span className="value">
                        {customEvent.foodRestrictionDesc}
                      </span>
                    </p>
                  )}

                  <p className="prop">
                    <span className="name">
                      {I18n.get("Tem alguma restriçao de bebidas?")}
                    </span>
                    <span className="value">
                      {new String(customEvent.drinkRestriction) == "true"
                        ? "Sim"
                        : "Não"}
                    </span>
                  </p>

                  {new String(customEvent.drinkRestriction) == "true" && (
                    <p className="prop">
                      <span className="name">
                        {I18n.get("Restriçao de bebidas:")}
                      </span>
                      <span className="value">
                        {customEvent.drinkRestrictionDesc}
                      </span>
                    </p>
                  )}
                </Panel>
              )}
            {customEvent.isEvent && (
              <Panel header={I18n.get("Logística")} key="10">
                <p className="prop">
                  <span className="name">
                    {I18n.get("Pretende oferecer estrutura de transporte?")}
                  </span>
                  <span className="value">
                    {new String(customEvent.transport) == "true"
                      ? "Sim"
                      : "Não"}
                  </span>
                </p>

                {new String(customEvent.transport) == "true" && (
                  <>
                    <p className="prop">
                      <span className="name">
                        {I18n.get("Tipo de transporte:")}
                      </span>
                      <span className="value">
                        {customEvent.transportType &&
                          customEvent.transportType.map((v, index) => {
                            const item =
                              this.props.bp.event_TRANSPORT_TYPES &&
                              this.props.bp.event_TRANSPORT_TYPES.find(
                                (a) => a.code == v
                              );
                            return (
                              <span class="multi"> {item && item.label}</span>
                            );
                          })}
                      </span>
                    </p>

                    <p className="prop">
                      <span className="name">
                        {I18n.get("Origem/percentual do transporte Aéreo:")}
                      </span>
                      <span className="value">
                        <span className="desc">
                          {I18n.get("Norte:")}{" "}
                          {customEvent.transportAirRegion.n || 0}%
                        </span>
                        ,
                        <span className="desc">
                          {I18n.get("Nordeste:")}{" "}
                          {customEvent.transportAirRegion.ne || 0}%
                        </span>
                        ,
                        <span className="desc">
                          {I18n.get("Centro-Oeste:")}{" "}
                          {customEvent.transportAirRegion.co || 0}%
                        </span>
                        ,
                        <span className="desc">
                          {I18n.get("Sudeste:")}{" "}
                          {customEvent.transportAirRegion.se || 0}%
                        </span>
                        ,
                        <span className="desc">
                          {I18n.get("Sul:")}{" "}
                          {customEvent.transportAirRegion.s || 0}%
                        </span>
                      </span>
                    </p>

                    <p className="prop">
                      <span className="name">
                        {I18n.get("Tipo de transporte Terrestre:")}
                      </span>
                      <span className="value">
                        {customEvent.transportLandType &&
                          customEvent.transportLandType.map((v, index) => {
                            const item =
                              this.props.bp.event_TRANSPORT_LAND_TYPES &&
                              this.props.bp.event_TRANSPORT_LAND_TYPES.find(
                                (a) => a.code == v
                              );
                            return (
                              <span class="multi"> {item && item.label}</span>
                            );
                          })}
                      </span>
                    </p>

                    <p className="prop">
                      <span className="name">{I18n.get("Hospedagem?")}</span>
                      <span className="value">
                        {accommodationType && accommodationType.label} &nbsp;
                        {accommodationType &&
                          accommodationType.code == "YES" && (
                            <span className="desc">
                              {customEvent.accommodationDesc}
                            </span>
                          )}
                      </span>
                    </p>
                  </>
                )}
              </Panel>
            )}
            {(customEvent.isShopper || customEvent.isIncentive) && (
              <Panel header={I18n.get("Logística")} key="11">
                <p className="prop">
                  <span className="name">
                    {I18n.get("Logística será da agência?")}
                  </span>
                  <span className="value">
                    {logisticsAgency && logisticsAgency.label} &nbsp;
                  </span>
                </p>

                <p className="prop">
                  <span className="name">
                    {I18n.get("Temos a grade inicial?")}
                  </span>
                  <span className="value">
                    {logisticsAgencyInitial && logisticsAgencyInitial.label}{" "}
                    &nbsp;
                    <span class="desc">
                      {customEvent.logisticsAgencyQuantity}
                    </span>
                  </span>
                </p>

                {logisticsAgencyInitial &&
                  logisticsAgencyInitial.code == "YES" && (
                    <p className="prop">
                      <span className="name">
                        {I18n.get("Origem e percentual:")}
                      </span>
                      <span className="value">
                        <span className="desc">
                          {I18n.get("Norte:")}{" "}
                          {customEvent.logisticsAgencyRegion.n || 0}%
                        </span>
                        ,
                        <span className="desc">
                          {I18n.get("Nordeste:")}{" "}
                          {customEvent.logisticsAgencyRegion.ne || 0}%
                        </span>
                        ,
                        <span className="desc">
                          {I18n.get("Centro-Oeste:")}{" "}
                          {customEvent.logisticsAgencyRegion.co || 0}%
                        </span>
                        ,
                        <span className="desc">
                          {I18n.get("Sudeste:")}{" "}
                          {customEvent.logisticsAgencyRegion.se || 0}%
                        </span>
                        ,
                        <span className="desc">
                          {I18n.get("Sul:")}{" "}
                          {customEvent.logisticsAgencyRegion.s || 0}%
                        </span>
                      </span>
                    </p>
                  )}
              </Panel>
            )}
            {customEvent.isDigital === false &&
              customEvent.isCommunication === false &&
              customEvent.isPromotional === false && (
                <Panel header={I18n.get("Equipe")} key="12">
                  <p className="prop">
                    <span className="name">
                      {I18n.get("Você vai precisar de um time de apoio?")}
                    </span>
                    <span className="value">
                      {customEvent.staffType &&
                        customEvent.staffType.length == 0 && (
                          <>{I18n.get("Nenhum")}</>
                        )}

                      {customEvent.isEvent &&
                        customEvent.staffType &&
                        customEvent.staffType.map((v, index) => {
                          let parent = null;

                          let item =
                            this.props.bp.event_STAFF_TYPES &&
                            this.props.bp.event_STAFF_TYPES.find(
                              (a) => a.code == v
                            );

                          if (item == null) {
                            const len =
                              this.props.bp.event_STAFF_TYPES &&
                              this.props.bp.event_STAFF_TYPES.length;

                            for (let i = 0; i < len; i++) {
                              const sub = this.props.bp.event_STAFF_TYPES[i];

                              if (sub.children == null) continue;

                              item = sub.children.find((a) => a.code == v);

                              if (item != null) {
                                parent = sub;
                                break;
                              }
                            }
                          } else if (item.children) {
                            // Skip do pai com filhos
                            return null;
                          }

                          return (
                            <span class="multi">
                              {parent && parent.label} {item && item.label}
                            </span>
                          );
                        })}

                      {customEvent.isShopper &&
                        customEvent.staffType &&
                        customEvent.staffType.map((v, index) => {
                          let parent = null;

                          let item =
                            this.props.bp.event_STAFF_SHOPPER_TYPES &&
                            this.props.bp.event_STAFF_SHOPPER_TYPES.find(
                              (a) => a.code == v
                            );

                          if (item == null) {
                            const len =
                              this.props.bp.event_STAFF_SHOPPER_TYPES &&
                              this.props.bp.event_STAFF_SHOPPER_TYPES.length;

                            for (let i = 0; i < len; i++) {
                              const sub =
                                this.props.bp.event_STAFF_SHOPPER_TYPES[i];

                              if (sub.children == null) continue;

                              item = sub.children.find((a) => a.code == v);

                              if (item != null) {
                                parent = sub;
                                break;
                              }
                            }
                          } else if (item.children) {
                            // Skip do pai com filhos
                            return null;
                          }

                          return (
                            <span class="multi">
                              {" "}
                              {parent && parent.label} {item && item.label}
                            </span>
                          );
                        })}

                      {customEvent.isIncentive &&
                        customEvent.staffType &&
                        customEvent.staffType.map((v, index) => {
                          let parent = null;

                          let item =
                            this.props.bp.event_STAFF_INCENTIVE_TYPES &&
                            this.props.bp.event_STAFF_INCENTIVE_TYPES.find(
                              (a) => a.code == v
                            );

                          if (item == null) {
                            const len =
                              this.props.bp.event_STAFF_INCENTIVE_TYPES &&
                              this.props.bp.event_STAFF_INCENTIVE_TYPES.length;

                            for (let i = 0; i < len; i++) {
                              const sub =
                                this.props.bp.event_STAFF_INCENTIVE_TYPES[i];

                              if (sub.children == null) continue;

                              item = sub.children.find((a) => a.code == v);

                              if (item != null) {
                                parent = sub;
                                break;
                              }
                            }
                          } else if (item.children) {
                            // Skip do pai com filhos
                            return null;
                          }

                          return (
                            <span class="multi">
                              {" "}
                              {parent && parent.label} {item && item.label}
                            </span>
                          );
                        })}
                    </span>
                  </p>
                </Panel>
              )}
            {customEvent.isCommunication === false &&
              customEvent.isPromotional === false && (
                <Panel header={I18n.get("Atrações")} key="13">
                  <p className="prop">
                    <span className="name">{I18n.get("Atrações:")}</span>
                    <span className="value">
                      {customEvent.attractionType &&
                        customEvent.attractionType.length == 0 && (
                          <>{I18n.get("Nenhuma")}</>
                        )}

                      {customEvent.attractionType &&
                        customEvent.attractionType.map((v, index) => {
                          const item =
                            this.props.bp.event_ATTRACTION_TYPES &&
                            this.props.bp.event_ATTRACTION_TYPES.find(
                              (a) => a.code == v
                            );
                          return (
                            <span class="multi">
                              {item && item.label}
                              {item && item.code == "CELEBRITY" && (
                                <span className="desc">
                                  {customEvent.attractionCelebrity}
                                </span>
                              )}
                              {item && item.code == "INFLUENCER" && (
                                <span className="desc">
                                  {customEvent.attractionInfluencer}
                                </span>
                              )}
                              {item && item.code == "OTHER" && (
                                <span className="desc">
                                  {customEvent.attractionDesc}
                                </span>
                              )}
                            </span>
                          );
                        })}
                    </span>
                  </p>
                </Panel>
              )}
            {customEvent.isCommunication === false &&
              customEvent.isPromotional === false && (
                <Panel header={I18n.get("Comunicação / Brindes")} key="14">
                  <p className="prop">
                    <span className="name">
                      {I18n.get(
                        "Pretende oferecer algum brinde ou lembrança do evento?"
                      )}
                    </span>
                    <span className="value">
                      {souvenirType && souvenirType.label} &nbsp;
                      {souvenirType && souvenirType.code == "YES" && (
                        <span className="desc">{customEvent.souvenirDesc}</span>
                      )}
                    </span>
                  </p>

                  {customEvent.souvenirBudget && (
                    <p className="prop">
                      <span className="name">
                        {I18n.get("Pensou num Budget por brinde/pessoa?")}
                      </span>
                      <span className="value">
                        {customEvent.souvenirBudget && (
                          <FormatCurrency amount={customEvent.souvenirBudget} />
                        )}
                      </span>
                    </p>
                  )}

                  <p className="prop">
                    <span className="name">
                      {I18n.get("Vai precisar de material gráfico?")}
                    </span>
                    <span className="value">
                      {promoMaterialType && promoMaterialType.label} &nbsp;
                      {promoMaterialType && promoMaterialType.code == "YES" && (
                        <span className="desc">
                          {customEvent.promoMaterialDesc}
                        </span>
                      )}
                    </span>
                  </p>
                </Panel>
              )}
            <Panel header={I18n.get("Budget")} key="15">
              <p className="prop">
                <span className="name">
                  {I18n.get("Budget máximo autorizado pelo cliente")}
                </span>
                <span className="value">
                  {customEvent.budgetType == "TOTAL" && (
                    <>
                      <span>
                        <FormatCurrency amount={customEvent.budgetTotal} currency={currency} />
                        &nbsp;&nbsp;
                        {I18n.get("(TOTAL)")}
                      </span>
                      <br />

                      {Object.keys(quotation).length > 0 && (
                        <>
                          <span>
                            <strong>
                              {I18n.get("Retenção máxima BPool")}:
                            </strong>{" "}
                            <FormatCurrency
                              amount={
                                quotation?.bpCommission &&
                                quotation?.bpCommission?.maxAmount
                              }
                              currency={currency}
                            />
                          </span>
                          <br />

                          <span>
                            <strong>
                              {" "}
                              {I18n.get(
                                "Budget máximo disponível para orçamento"
                              )}
                              :
                            </strong>{" "}
                            <FormatCurrency
                              amount={quotation?.workingBudgetMax}
                              currency={currency}
                            />
                          </span>
                        </>
                      )}
                    </>
                  )}
                  {customEvent.budgetType == "PER_PERSON" && (
                    <span>
                      <FormatCurrency amount={customEvent.budgetPerson} />
                      &nbsp;&nbsp;
                      {I18n.get("(POR PESSOA) Número de Participantes:")}{" "}
                      {customEvent.participants}{" "}
                    </span>
                  )}
                  {customEvent.budgetType == "NOTSET" && (
                    <span>{I18n.get("Não Informado")}</span>
                  )}
                  {customEvent.budgetType == "TOTAL" &&
                    customEvent.isPromotional &&
                    customEvent.budgetPerItem && (
                      <span>
                        &nbsp;&nbsp;
                        <FormatCurrency amount={customEvent.budgetPerItem} />
                        &nbsp;
                        {I18n.get("(POR ITEM)")}
                      </span>
                    )}
                </span>
              </p>

              {customEvent.budgetExclusions && (
                <>
                  <p className="prop">
                    <span className="name">
                      {customEvent.isCommunication === false &&
                        customEvent.isPromotional === false && (
                          <>
                            {I18n.get(
                              "Itens que serão negociados diretamente pela sua empresa e que não entradrão na verba alocada acima (exemplo: hospedagem, passagens áreas, etc)"
                            )}
                          </>
                        )}
                      {customEvent.isCommunication === true && (
                        <>
                          {I18n.get(
                            "Itens que serão negociados diretamente pela sua empresa e que não entradrão na verba alocada acima (exemplo: itens de produção, veiculação de mídia e etc)"
                          )}
                        </>
                      )}
                      {customEvent.isPromotional === true && (
                        <>
                          {I18n.get(
                            "Itens que serão negociados diretamente pela sua empresa e que não entradrão na verba alocada acima"
                          )}
                        </>
                      )}
                    </span>
                    <span className="value">
                      {customEvent.budgetExclusions}
                    </span>
                  </p>
                </>
              )}
            </Panel>
            {customEvent.isPromotional === false && (
              <Panel
                header={I18n.get("Mensuração de Resultado (KPIs)")}
                key="16"
              >
                <p className="prop">
                  <span className="name">
                    {I18n.get("Como você vai medir o sucesso do evento?")}
                  </span>
                  <span className="value">{customEvent.resultsDesc}</span>
                </p>
              </Panel>
            )}
            <Panel header={I18n.get("Prazo para a proposta")} key="17">
              <p className="prop">
                <span className="name">{I18n.get("Data limite:")}</span>
                <span className="value">{customEvent.quotationDate}</span>
              </p>
            </Panel>
            {customEvent.isPromotional === true && (
              <Panel
                header={I18n.get("Prazo para entrega do material")}
                key="17"
              >
                <p className="prop">
                  <span className="name">{I18n.get("Data limite:")}</span>
                  <span className="value">{customEvent.promotionalDate}</span>
                </p>
              </Panel>
            )}
            <Panel header={I18n.get("Arquivos")} key="18">
              {this.props.files &&
                this.props.files.map((item, index) => (
                  <div
                    key={index}
                    className="is-clearfix upload-item"
                    style={{ padding: "1rem" }}
                  >
                    <a href={item.url} target="_blank">
                      {item.name}
                    </a>
                  </div>
                ))}
              {this.props.files && this.props.files.length == 0 && (
                <p>
                  <span className="has-text-grey-light">
                    {I18n.get("Nenhum.")}
                  </span>
                </p>
              )}
            </Panel>
          </Collapse>
        </CardBorder>
      </div>
    );
  }
}

export default EventCustomDetails;
