import React from "react";

import { I18n } from "aws-amplify";

import { Avatar, Row, Col, Button } from "antd";

import Dphoto from "../../../image/dphoto.png";

const Card = (props) => {
  if (!props.bp || !props.bp.client) return null;

  const item = props.bp.client;

  return (
    <div className="card">
      <div className="card-content">
        <Row gutter={1}>
          <Col sm={24}>
            <h3 className="title is-4">{I18n.get("Responsável")}</h3>
          </Col>
        </Row>
        <br />
        <Row gutter={1}>
          <Col sm={2}>
            <img width="100%" src={item.profilePicture || Dphoto} />
          </Col>
          <Col sm={22}>
            <div className="admin-content-right">
              <Row gutter={16}>
                <Col sm={12}>
                  <h3 className="title-bpool regular">{item.name}</h3>
                  <p>
                    <b>{I18n.get("E-mail")}:</b> {item.email}{" "}
                  </p>
                  <p>
                    <b>{I18n.get("Celular")}:</b> {item.phoneNumber}
                  </p>
                  <p>
                    <b>{I18n.get("Data cadastro")}:</b> {item.createdOnFmt}
                  </p>
                  <br />
                  <p>
                    <Button
                      className="button bt-bpool black"
                      onClick={() => props.onVisible()}
                    >
                      {I18n.get("Trocar usuário master")}
                    </Button>
                  </p>
                </Col>
                <Col sm={12}>
                  {item.socialURLs && (
                    <p>
                      {item.socialURLs.map((item, index) => (
                        <a
                          key={index}
                          className="button bt-bpool pull-right"
                          href={item}
                          target="_blank"
                        >
                          {item}
                        </a>
                      ))}
                    </p>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Card;
