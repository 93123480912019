import get from './get';
import confirm_client from './confirm';
import update from './update';
import updateLogado from './updateLoggaded';

export const CLIENT_USER_GET = 'client-user_get';
export const CLIENT_USER_UPDATE = 'client-user_update';

export const CLIENT_USER_API = {
    get: get,
    confirm: confirm_client,
    update: update,
    updateLogado: updateLogado
}

