import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useBpayAdmin } from "../../../../../../../hooks/useBpay/useBpayAdmin.hook";
import { Drawer } from "../../../../../../../components/UI/Drawer";
import { ButtonBpool } from "../../../../../../../components/UI/ButtonBpool";
import { Dialog } from "../../../../../../../components/UI/Dialog";
import { NotificationBPool } from "../../../../../../../components/UI/NotificationBPool";
import * as S from "../styles";

export const DrawerStatus = ({ drawer, handleCloseDrawer }) => {
  const { getBpayDataInternalToDrawer, sendWarningUploadInvoiceInternal } =
    useBpayAdmin();
  const [isSendingMails, setIsSendingMails] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({});

  const handleOpenDrawer = async ({ bpayId, bPaymentId }) => {
    // try {
    //   setIsLoadingDrawer(true);
    //   const response = await getBpayDataInternalToDrawer({
    //     bpayId,
    //     bPaymentId,
    //   });
    //   setDataDrawer(response?.data);
    // } catch (error) {
    //   NotificationBPool.open({
    //     type: "error",
    //     title: I18n.get("Erro!"),
    //     description: I18n.get("Erro ao carregar as informações"),
    //     duration: 3,
    // });
    // } finally {
    //   setIsLoadingDrawer(false);
    // }
  };

  useEffect(() => {
    if (drawer?.open === true) {
      handleOpenDrawer({
        bpayId: drawer?.bpayId,
        bPaymentId: drawer?.bPaymentId,
      });
    }
  }, [drawer?.open]);

  return (
    <>
      <Drawer
        open={drawer.open}
        handleClose={handleCloseDrawer}
        buttons={<></>}
      >
        <div>Status, em construção.</div>
      </Drawer>
    </>
  );
};
