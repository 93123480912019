import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { BP_PROJECT_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const api_get_possible_matches = (data) => {
  return (dispatch, getState) => {
    Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .get(
            `${Config.API_BASE_URL}/bp/projects/${data.projectId}/PossibleMatches`,
            config
          )
          .then((response) => {
            dispatch({
              type: BP_PROJECT_GET,
              payload: {
                possibleMatches: response.data,
              },
            });
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default api_get_possible_matches;
