import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { I18n } from 'aws-amplify';

import {
    Table,
    Icon
} from 'antd';

const Btns = styled.p`
width: 50px;
displa: block;
    a {
        margin-left: 10px;
    }
`

class tableCategories extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderType = (type) => {
        switch (type) {
            case 1:
                return "Text";
                break;
            case 2:
                return "Vídeo";
                break;
            case 3:
                return "File";
                break;
        
            default:
                break;
        }
    }

    render() {
        const columns = [
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: I18n.get("Escopo/Section"),
                dataIndex: 'scope',
                key: 'scope'
            },
            {
                title: I18n.get("Target"),
                dataIndex: 'target',
                key: 'target',
                render: (text) => {
                    return text == "C" ? "Client":"Partner";
                }
            },
            {
                title: I18n.get("Ordem"),
                dataIndex: 'order',
                key: 'order'
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'item',
                width: '100px',
                render: (text, record) => {
                    let data = {
                        scopeId: record.scopeId,
                        sectionId: record.sectionId,
                        categoryId: record.categoryId

                    }

                    return (
                        <div>
                            {!this.props.isLeader &&
                                <Btns>
                                    <a onClick={() => this.props.edit(record)}>
                                        <Icon type="edit" theme="filled" />
                                    </a>
                                    <a onClick={() => this.props.delete(data)}>
                                        <Icon type="delete" theme="filled" />
                                    </a>
                                </Btns>
                            }
                        </div>
                    );
                    
                }
            },
        ]

        let dataTable = this.props.data && this.props.data.map((item, index) => {
            return {
                key: index,
                name: item.name,
                categoryId: item.categoryId,
                scope: `${item.scope} - ${item.section} - ${item.language}`,
                scopeId: item.scopeId,
                sectionId: item.sectionId,
                // type: item.type,
                language: item.language,
                order: item.order,
                target: item.target
            }
        });

        return (
            <Table
                columns={columns}
                dataSource={dataTable}
                scroll={{ x: 640 }}
                locale={{ emptyText: I18n.get('Sem informação') }}
            />
        );
    }
}

export default tableCategories;
