import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { I18n } from "aws-amplify";
import ReactPlayer from "react-player";
import { openChatModal } from "../../../../../../store/actions/chatModal";
import { openQuickMeeting } from "../../../../../../store/actions/quickMeeting";
import { ButtonBpool } from "../../../../../../components/UI/ButtonBpool";
import { RateBox } from "../RateBox";

import * as S from "./styles";

import arrowRight from "../../imgs/arrow-right-bt.svg";
import chatIcon from "../../imgs/chat-icon.png";
import iconDiversidade from "../../Step1/svgs/ico-diversity.svg";
import iconCertificado from "../../Step1/svgs/ico-certificates.svg";

export const Card = ({
  step,
  dataCard,
  projectId,
  reasons,
  cbConfirmPartner,
  cbFinalize,
  disabledSelected,
}) => {
  const [reasonsPartner, setReasonsPartner] = useState({});

  const dispatch = useDispatch();

  const handleOpenChat = () => {
    dispatch(openChatModal());
  };

  const handleOpenQuickMeeting = () => {
    dispatch(openQuickMeeting({ projectId, partnerId: null }));
  };

  const reasonsByPartner = () => {
    const partnerReasons = reasons?.find(
      (reason) => reason.partnerId === dataCard?.id
    );
    setReasonsPartner(partnerReasons);
  };

  const knowMore = () => {
    const partnerId = dataCard?.id;

    history.push(
      `/cliente/projetos/v2/selecionar-parceiro/${projectId}/saiba-mais/${partnerId}`
    );
  };

  const selectPartner = () => {
    const partnerId = dataCard?.id;

    history.push(
      `/cliente/projetos/v2/selecionar-parceiro/${projectId}/passo-2/${partnerId}`
    );
  };

  useEffect(() => {
    if (reasons?.length && dataCard?.id) {
      reasonsByPartner();
    }
  }, [dataCard, reasons]);

  let history = useHistory();

  const renderActions = () => {
    if (step === 1) {
      return (
        <S.Actions>
          <S.ButtonKnowMore onClick={knowMore} disabled={disabledSelected}>
            <div>{I18n.get("Saiba mais")}</div>
            <img src={arrowRight} alt="ico Know More" />
          </S.ButtonKnowMore>
          <div style={{ marginTop: 20 }}>
            <ButtonBpool
              text={I18n.get("Chemistry Meeting")}
              theme="primary"
              full
              onClick={handleOpenQuickMeeting}
              disabled={disabledSelected}
            />
          </div>
          <div>
            <ButtonBpool
              text={I18n.get("Selecionar parceiro")}
              theme="primary"
              full
              onClick={selectPartner}
              disabled={disabledSelected}
            />
          </div>
        </S.Actions>
      );
    }

    if (step === 2) {
      return (
        <S.Actions>
          <S.ActionsContact>
            <S.PhoneAndMailContact>
              <p>
                <span>
                  {I18n.get("Email")}
                  {`: `}
                </span>
                {dataCard?.email}
              </p>
              <p>
                <span>
                  {I18n.get("Phone")}
                  {`: `}
                </span>
                {dataCard?.company?.phoneNumber}
              </p>
            </S.PhoneAndMailContact>
          </S.ActionsContact>
          <ButtonBpool
            text={I18n.get("Confirmar Parceiro")}
            theme="primary"
            full
            onClick={() => cbConfirmPartner(dataCard?.id)}
          />
        </S.Actions>
      );
    }

    if (step === 3) {
      return (
        <RateBox
          reasonsPartner={reasonsPartner}
          cbFinalize={cbFinalize}
          partnerId={dataCard?.id}
        />
      );
    }
  };

  return (
    <S.Wrapper
      style={{
        marginLeft: step === 2 ? 0 : "",
        minHeight: step === 3 ? 420 : "",
        border: reasonsPartner.isWinner ? "2px solid" : "",
        borderColor: reasonsPartner.isWinner ? "#19c31b" : "",
      }}
      data-label={dataCard?.company?.name}
    >
      {reasonsPartner.isWinner ? (
        <S.IsWinner>
          <p>{I18n.get("Selecionado")}</p>
        </S.IsWinner>
      ) : null}
      <S.Head>
        <S.ContentInfos>
          <S.AvatarAndName>
            <img src={dataCard?.company?.logoImage} />
          </S.AvatarAndName>
          <S.NameAndLocal>
            <strong>{dataCard?.company?.name}</strong>
            <p>{dataCard?.company?.city}</p>
          </S.NameAndLocal>
        </S.ContentInfos>
        <S.ChatIcon onClick={handleOpenChat}>
          <img alt="chat icon" src={chatIcon} />
          <strong>Chat</strong>
        </S.ChatIcon>
      </S.Head>
      <S.Icons>
        {dataCard?.hasDiversity && (
          <span>
            <img alt="Diversidade" src={iconDiversidade} />
          </span>
        )}
        {dataCard?.hasCertificate && (
          <span>
            <img alt="Certificado" src={iconCertificado} />
          </span>
        )}
      </S.Icons>
      <S.Content>
        <ReactPlayer
          url={dataCard?.company?.introVideo}
          playing={true}
          loop={true}
          controls={false}
          muted={true}
          pip={false}
          width="100%"
          style={{
            backgroundColor: "#000",
            width: "100%",
            maxHeight: "180px",
            margin: "0 auto",
          }}
        />
      </S.Content>
      {renderActions()}
    </S.Wrapper>
  );
};
