import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Tooltip, Icon } from "antd";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import {
  hasError,
  clearErrorInput,
} from "../../../../RegistrationFlow/utils/errorsInput";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { CurrencyInput } from "../../../../../components/UI/CurrencyInput";
import { Input } from "../../../../../components/UI/Input";
import { Dialog } from "../../../../../components/UI/Dialog";
import { SelectOne } from "../../../../../components/UI/SelectOne";
import { Textarea } from "../../../../../components/UI/Textarea";
import { Alert } from "../../../../../components/UI/Alert";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import { FormatCurrency } from "../../../../../components/UI/FormatCurrency";
import { useBpay } from "../../../../../hooks/useBpay/useBpay.hook";
import { NewBPay } from "../../../templates/NewBPay";
import BpayContext from "../../../context/BpayContext";

import * as S from "./styles";

const billToOptions = [
  {
    code: 1,
    label: "CLIENT",
  },
  {
    code: 2,
    label: "BPool",
  },
];

export const NewBpayData = () => {
  let history = useHistory();
  let params = useParams();
  const state = useSelector((state) => state);
  const { calculateTotalValue, step1BpayDraft, step1BpayGenerateBudget } =
    useBpay();
  const { sharedSetupBpay, sharedStateBpay, fetchDataBpay } =
    useContext(BpayContext);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [inputsForm, setInputsForm] = useState({});
  const [currencySelected, setCurrencySelected] = useState(null);
  const [openModal, setOpenModal] = useState({
    open: false,
    id: null,
    pdf: null,
  });
  const [bpayId, setBpayId] = useState(undefined);
  const [errorsInputs, setErrorsInputs] = useState([]);
  const [isDisabledGenerateBudget, setIsDisabledGenerateBudget] =
    useState(true);
  const [valueDebounced] = useDebounce(inputsForm?.budget, 1000);
  const [taxes, setTaxes] = useState({});

  const onChangeInputForm = (id, value) => {
    if (id === "billingCurrencyCode") {
      const currencyInfos = sharedSetupBpay?.setup?.listCurrenciesDefault?.find(
        (item) => item?.culture === value
      );
      setCurrencySelected(currencyInfos);
    }

    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleSend = async ({ id, draft }) => {
    let objSend = {
      name: inputsForm?.name,
      description: inputsForm?.description,
      brandId: inputsForm?.brandId,
      budget: inputsForm?.budget,
      culture: inputsForm?.culture,
      billingCurrencyCode: inputsForm?.billingCurrencyCode,
    };

    try {
      setIsSending(true);

      if (id) {
        objSend.id = id;
      }

      if (draft) {
        const response = await step1BpayDraft(objSend);
        NotificationBPool.open({
          type: "success",
          title: I18n.get("Sucesso!"),
          description: I18n.get(
            id
              ? "BPay foi atualizado no rascunho."
              : "BPay foi salvo no rascunho."
          ),
          duration: 3,
        });
        history.push(`/cliente/bpay/new/suppliers/${response?.id || id}`);
      } else {
        const response = await step1BpayGenerateBudget(objSend);
        setOpenModal({ open: true, id: response?.id, pdf: response?.urlPdf });
      }
    } catch (error) {
      setErrorsInputs(error?.response?.data?.errors);
      setOpenModal({ open: false, id: null, pdf: null });
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get(
          draft ? "Erro ao salvar no rascunho." : "Erro ao gerar orçamento"
        ),
        duration: 3,
      });
    } finally {
      setIsSending(false);
    }
  };

  const calculeValue = async (value) => {
    let objSend = {
      value,
      billingCurrencyCode: currencySelected?.code
        ? currencySelected?.code
        : sharedSetupBpay?.setup?.listCurrenciesDefault?.find(
            (item) =>
              item?.culture === sharedStateBpay?.data?.billingCurrencyCode
          )?.code,
    };
    if (params?.bpayId) {
      objSend = {
        ...objSend,
        id: params?.bpayId,
      };
    }
    try {
      setIsCalculating(true);
      const response = await calculateTotalValue(objSend);

      setInputsForm({
        ...inputsForm,
        totalFee: response?.totalFee,
        total: response?.total,
      });
      setTaxes({
        assetTax: response?.assetTax,
        assetTaxPercFmt: response?.assetTaxPercFmt,
        otherTax: response?.otherTax,
        otherTaxPercFmt: response?.otherTaxPercFmt,
      });
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao carregar os dados"),
        duration: 3,
      });
    } finally {
      setIsCalculating(false);
    }
  };

  useEffect(() => {
    if (valueDebounced) {
      calculeValue(valueDebounced);
    }
  }, [valueDebounced]);

  const handleClickOkModal = () => {
    const pdfUrl = openModal?.pdf;
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao baixar o PDF"),
        duration: 3,
      });
    }
    history.push(`/cliente/bpay/new/suppliers/${openModal?.id}`);
  };

  const handleBackOrCancel = () => {
    return history.push("/cliente/bpay");
  };

  useEffect(() => {
    let objData = {};
    if (
      sharedStateBpay?.data?.id &&
      sharedStateBpay?.data?.id === params?.bpayId
    ) {
      objData = {
        id: sharedStateBpay?.data?.id,
        budget: sharedStateBpay?.data?.budget,
        name: sharedStateBpay?.data?.name,
        brandId: sharedStateBpay?.data?.brandId,
        description: sharedStateBpay?.data?.description,
        culture: sharedStateBpay?.data?.culture,
        billingCurrencyCode: sharedStateBpay?.data?.billingCurrencyCode,
        paymentDefaultTermDays: sharedStateBpay?.data?.paymentDefaultTermDays,
      };
    } else {
      objData = {
        culture: sharedStateBpay?.data?.culture,
        billingCurrencyCode: sharedStateBpay?.data?.billingCurrencyCode,
        paymentDefaultTermDays: sharedStateBpay?.data?.paymentDefaultTermDays,
      };
    }
    setInputsForm(objData);
  }, [sharedStateBpay]);

  useEffect(() => {
    if (bpayId || bpayId === null) {
      fetchDataBpay({
        id: params?.bpayId,
        newBpay: params?.bpayId ? false : true,
      });
    }
  }, [bpayId]);

  useEffect(() => {
    setBpayId(params?.bpayId ? params?.bpayId : null);
  }, [params?.bpayId]);

  useEffect(() => {
    if (
      inputsForm?.name &&
      inputsForm?.brandId &&
      inputsForm?.culture &&
      inputsForm?.billingCurrencyCode &&
      inputsForm.budget
    ) {
      setIsDisabledGenerateBudget(false);
    } else {
      setIsDisabledGenerateBudget(true);
    }
  }, [inputsForm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <NewBPay
      buttons={
        sharedSetupBpay?.isLoading || sharedStateBpay?.isLoading ? (
          <></>
        ) : (
          <>
            <ButtonBpool
              text={I18n.get(bpayId ? "Voltar" : "Cancelar")}
              theme="secondary"
              onClick={handleBackOrCancel}
            />
            {sharedSetupBpay?.isLoading ||
            sharedStateBpay?.isLoading ? null : sharedStateBpay?.data
                ?.status === null || sharedStateBpay?.data?.status === 1 ? (
              <ButtonBpool
                text={I18n.get(
                  bpayId ? "Atualizar e avançar" : "Salvar e avançar"
                )}
                theme="tertiary"
                onClick={() => handleSend({ id: bpayId, draft: true })}
                loading={isSending}
              />
            ) : (
              <ButtonBpool
                text={I18n.get("Avançar")}
                theme="tertiary"
                onClick={() =>
                  history.push(`/cliente/bpay/new/suppliers/${params?.bpayId}`)
                }
                loading={isSending}
              />
            )}
            <ButtonBpool
              text={I18n.get(
                bpayId ? "Gerar orçamento atualizado" : "Gerar orçamento"
              )}
              theme="primary"
              onClick={() => handleSend({ id: bpayId, draft: false })}
              disabled={isDisabledGenerateBudget}
              loading={isSending}
            />
          </>
        )
      }
    >
      <S.TitleContent>
        <h3>{I18n.get(bpayId ? "Editar Dados do BPay" : "Dados do BPay")}</h3>
      </S.TitleContent>
      {sharedStateBpay?.data?.status && sharedStateBpay?.data?.status === 1 ? (
        <S.AlertPage>
          <Alert
            message={I18n.get("BPay está em rascunho.")}
            type="warning"
            notClosable
          />
        </S.AlertPage>
      ) : null}
      <S.Form>
        <Row>
          <Col md={12}>
            <S.Skeleton
              loading={sharedSetupBpay?.isLoading || sharedStateBpay?.isLoading}
              active
              title={false}
              paragraph={{
                rows: 1,
                className: "skeleton-input",
                width: "100%",
              }}
            >
              <Input
                placeholder={I18n.get("Nome do BPay")}
                label
                required
                value={inputsForm.name}
                onChange={(value) => onChangeInputForm("name", value)}
                error={hasError({ arr: errorsInputs, field: "name" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "name",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </S.Skeleton>
          </Col>
          <Col md={12}>
            <S.Skeleton
              loading={sharedSetupBpay?.isLoading || sharedStateBpay?.isLoading}
              active
              title={false}
              paragraph={{
                rows: 1,
                className: "skeleton-input",
                width: "100%",
              }}
            >
              <SelectOne
                options={sharedSetupBpay?.setup?.brandsProducts}
                value={inputsForm?.brandId}
                defaultValue={inputsForm?.brandId}
                onChange={(value) => onChangeInputForm("brandId", value)}
                required
                placeholder={I18n.get("Marca/Produto")}
                error={hasError({ arr: errorsInputs, field: "brandId" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "brandId",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </S.Skeleton>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <S.Skeleton
              loading={sharedSetupBpay?.isLoading || sharedStateBpay?.isLoading}
              active
              title={false}
              paragraph={{
                rows: 1,
                className: "skeleton-input",
                width: "100%",
              }}
            >
              <SelectOne
                options={sharedSetupBpay?.setup?.listLanguages}
                value={inputsForm?.culture}
                defaultValue={inputsForm?.culture}
                // isCountry
                onChange={(value) => onChangeInputForm("culture", value)}
                required
                placeholder={I18n.get("Idioma")}
                error={hasError({ arr: errorsInputs, field: "culture" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "culture",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </S.Skeleton>
          </Col>
          <Col md={8}>
            <S.Skeleton
              loading={sharedSetupBpay?.isLoading || sharedStateBpay?.isLoading}
              active
              title={false}
              paragraph={{
                rows: 1,
                className: "skeleton-input",
                width: "100%",
              }}
            >
              <SelectOne
                options={sharedSetupBpay?.setup?.listCurrencies}
                value={inputsForm?.billingCurrencyCode}
                defaultValue={inputsForm?.billingCurrencyCode}
                onChange={(value) =>
                  onChangeInputForm("billingCurrencyCode", value)
                }
                required
                placeholder={I18n.get("Moeda")}
                error={hasError({
                  arr: errorsInputs,
                  field: "billingCurrencyCode",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "billingCurrencyCode",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </S.Skeleton>
          </Col>
          <Col md={8}>
            <S.Skeleton
              loading={sharedSetupBpay?.isLoading || sharedStateBpay?.isLoading}
              active
              title={false}
              paragraph={{
                rows: 1,
                className: "skeleton-input",
                width: "100%",
              }}
            >
              <CurrencyInput
                placeholder={I18n.get("Valor fornecedores")}
                required
                value={inputsForm.budget}
                onChange={(value) => onChangeInputForm("budget", value)}
                currency={
                  currencySelected ||
                  sharedSetupBpay?.setup?.billingPaymentCurrency
                }
                error={hasError({ arr: errorsInputs, field: "budget" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "budget",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </S.Skeleton>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <S.Skeleton
              loading={
                sharedSetupBpay?.isLoading ||
                sharedStateBpay?.isLoading ||
                isCalculating
              }
              active
              title={false}
              paragraph={{
                rows: 1,
                className: "skeleton-input",
                width: "100%",
              }}
            >
              <CurrencyInput
                placeholder={I18n.get("Retenção BPool")}
                required
                value={inputsForm.totalFee}
                onChange={(value) => onChangeInputForm("totalFee", value)}
                currency={
                  currencySelected ||
                  sharedSetupBpay?.setup?.billingPaymentCurrency
                }
                disabled={true}
                error={hasError({ arr: errorsInputs, field: "totalFee" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "totalFee",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </S.Skeleton>
          </Col>
          <Col md={8}>
            <S.Skeleton
              loading={
                sharedSetupBpay?.isLoading ||
                sharedStateBpay?.isLoading ||
                isCalculating
              }
              active
              title={false}
              paragraph={{
                rows: 1,
                className: "skeleton-input",
                width: "100%",
              }}
            >
              <CurrencyInput
                placeholder={I18n.get("Valor Total")}
                required
                value={inputsForm.total}
                onChange={(value) => onChangeInputForm("total", value)}
                currency={
                  currencySelected ||
                  sharedSetupBpay?.setup?.billingPaymentCurrency
                }
                disabled={true}
                error={hasError({ arr: errorsInputs, field: "total" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "total",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </S.Skeleton>
          </Col>
          <Col md={8}>
            <S.Skeleton
              loading={sharedSetupBpay?.isLoading || sharedStateBpay?.isLoading}
              active
              title={false}
              paragraph={{
                rows: 1,
                className: "skeleton-input",
                width: "100%",
              }}
            >
              <Input
                placeholder={I18n.get("Prazo de pagamento padrão")}
                label
                required
                value={`${inputsForm.paymentDefaultTermDays} ${I18n.get(
                  "dias"
                )}`}
                onChange={(value) =>
                  onChangeInputForm("paymentDefaultTermDays", value)
                }
                disabled
                error={hasError({
                  arr: errorsInputs,
                  field: "paymentDefaultTermDays",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "paymentDefaultTermDays",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </S.Skeleton>
          </Col>
        </Row>
        <Row>
          {state?.client?.country?.code !== "BRA" &&
          taxes?.otherTax &&
          taxes.otherTax !== 0 ? (
            <Col md={4}>
              <S.Skeleton
                loading={
                  sharedSetupBpay?.isLoading ||
                  sharedStateBpay?.isLoading ||
                  isCalculating
                }
                active
                title={false}
                paragraph={{
                  rows: 1,
                  className: "skeleton-input",
                  width: "100%",
                }}
              >
                <S.ViewTaxes>
                  <S.TitleTaxes>
                    <h3>{I18n.get("Other Taxes")}</h3>
                    <Tooltip
                      placement="topRight"
                      title={taxes?.otherTaxPercFmt}
                      overlayClassName="tooltip-status-bpay"
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </S.TitleTaxes>
                  <p>
                    {FormatCurrency({
                      amount: taxes?.otherTax,
                      currency:
                        currencySelected ||
                        sharedSetupBpay?.setup?.billingPaymentCurrency,
                    })}
                  </p>
                </S.ViewTaxes>
              </S.Skeleton>
            </Col>
          ) : null}
          {state?.client?.country?.code !== "BRA" &&
          taxes?.assetTax &&
          taxes.assetTax !== 0 ? (
            <Col md={4}>
              <S.Skeleton
                loading={
                  sharedSetupBpay?.isLoading ||
                  sharedStateBpay?.isLoading ||
                  isCalculating
                }
                active
                title={false}
                paragraph={{
                  rows: 1,
                  className: "skeleton-input",
                  width: "100%",
                }}
              >
                <S.ViewTaxes>
                  <S.TitleTaxes>
                    <h3>{I18n.get("IVA")}</h3>
                    <Tooltip
                      placement="topRight"
                      title={taxes?.assetTaxPercFmt}
                      overlayClassName="tooltip-status-bpay"
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </S.TitleTaxes>
                  <p>
                    {FormatCurrency({
                      amount: taxes?.assetTax,
                      currency:
                        currencySelected ||
                        sharedSetupBpay?.setup?.billingPaymentCurrency,
                    })}
                  </p>
                </S.ViewTaxes>
              </S.Skeleton>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col md={24}>
            <S.Skeleton
              loading={sharedSetupBpay?.isLoading || sharedStateBpay?.isLoading}
              active
              title={false}
              paragraph={{
                rows: 2,
                className: "skeleton-input",
                width: "100%",
              }}
            >
              <Textarea
                label={I18n.get("Descritivo resumido")}
                rows={4}
                defaultValue={inputsForm?.description}
                onChange={(value) => onChangeInputForm("description", value)}
              />
            </S.Skeleton>
          </Col>
        </Row>
      </S.Form>

      <Dialog
        visible={openModal?.open}
        text={I18n.get("Orçamento gerado com sucesso!")}
        subText={I18n.get(
          "Seu orçamento foi gerado, você pode baixa-lo agora ou prosseguir e baixar posteriormente."
        )}
        buttons={{
          ok: {
            text: I18n.get("Baixar PDF e avançar"),
            func: () => handleClickOkModal(),
          },
          cancel: {
            text: I18n.get("Avançar"),
            func: () =>
              history.push(`/cliente/bpay/new/suppliers/${openModal?.id}`),
          },
        }}
      />
    </NewBPay>
  );
};
