import styled from "styled-components";

export const StyledItem = styled.div`
  padding: 30px;
  background: #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.101557);
  border-radius: 10px;
  width: 100%;
  max-width: 950px;
  min-width: 950px;
  position: relative;
  text-align: left;
  p {
    text-align: left;
  }
`;

export const StyledTitle = styled.h3`
  color: #000;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 30px;
  text-align: left;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 75px;
    height: 7px;
    background: #000000;
  }
`;

export const StyledName = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: left;

  color: #2f2f2f;
`;

export const StyledBadge = styled.span`
  display: inline-block;
  color: #fff;
  background: #000;
  font-size: 12px;
  width: 23px;
  text-align: center;
  border-radius: 20px;
  line-height: 24px;
  height: 22px;
`;

export const StyledAction = styled.div`
  display: block;
  width: 100%;
`;

export const StyledDescription = styled.div`
  height: 200px;
  padding-right: 20px;
  overflow-x: auto;
  margin: 30px 0 70px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const StyledBadgeText = styled.span``;
export const Close = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 90;
`;

export const StyledInfos = styled.div`
  margin: 30px 0;
  p {
    font-size: 16px;
    line-height: 28px;

    strong,
    b {
      font-weight: bold;
    }

    b {
      color: #1883ff;
    }
  }
`;
