
export class QueryString {

    static extract(search) {

        var queryString = {};

        if (search) {

            search.replace(
                new RegExp("([^?=&]+)(=([^&]*))?", "g"),
                function ($0, $1, $2, $3) { queryString[$1] = $3; return $0; }
            );
        }

		return queryString;
	}
}
