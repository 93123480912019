import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Task } from '../../utils/task';

import { PROJECT_API } from '../../actions/project/actions';
import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import { CardSimple } from '../../modules/components/Cards';
import FormFilterMilistone from '../../modules/Admin/components/filterMilistoneAdmin';
import TableMilestone from '../../modules/Admin/components/tableMilistoneAdmin';

import styled from 'styled-components';

import ClientMilistoneList from './client-project/_list';

import {
    Row,
    Col,
    notification,
    Icon
} from 'antd';

const NoData = styled.div`
    padding: 50px;
    text-align: center;

    h3 {
        font-weight: 700;
        margin-bottom: 30px;
    }

    i {
        font-size: 40px;
    }

    .txt {
        position: relative;
        left: 30px;
        top: -15px;
    }
`;


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_projects: data => {
            dispatch(BP_API.get_projects(data));
        },
        get_milestone_list: data => {
            dispatch(BP_API.get_milestone_list(data));
        },
        get_filters_milestones: () => {
            dispatch(BP_API.get_filters_milestones());
        },
        delete_project: id => {
            return dispatch(BP_API.delete_project(id));
        },
        cancel_project: id => {
            return dispatch(BP_API.cancel_project(id));
        },
        reset: () => {
            dispatch(PROJECT_API.reset());
        },
    };
};

class ClientMilistoneListAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
            historySearch: {},
            task: {}
        };
    }

    handleFilters = (data) => {
        var task = new Task();

        task.start();

        this.setState({ task: task, historySearch: data }, function () {
            this.props.get_milestone_list(data);
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {
            this.setState({ task: task });
        }

    }



   
    componentDidMount() {
        this.props.reset();
        this.props.get_filters_milestones();
    }

    render() {
        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/projetos" className="is-black">{I18n.get("Projetos")}</NavLink></li>
                    </ul>
                </nav>

                <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            <FormFilterMilistone
                                filters={this.props.bp && this.props.bp.adminMilestonesFilters}
                                action={this.handleFilters}
                                task={this.state.task}
                            />
                        </CardSimple>
                    </Col>
                </Row>
                <br />

                <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            {!this.props.bp.adminMilestoneList &&
                                <NoData>
                                    <h3 className="title-bpool medium">{I18n.get("Escolha as informações para a busca!")}</h3>

                                    {!this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="search" />
                                        </p>
                                    }

                                    {this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="loading" />
                                        </p>
                                    }
                                </NoData>

                            }

                            {this.props.bp.adminMilestoneList &&
                                <div>
                                    {this.state.task.busy &&
                                        <NoData>
                                            <p className="icone">
                                                <Icon type="loading" /> <span className="txt">Atualizando</span>
                                            </p>
                                        </NoData>
                                    }
                                    <TableMilestone
                                        data={this.props.bp.adminMilestoneList}
                                        // totals={this.props.bp.adminProjectList.totals}
                                        // delete={this.delete}
                                        cancel={this.cancel}
                                        {...this.props}
                                    />
                                </div>
                             } 
                        </CardSimple>
                    </Col>
                </Row>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientMilistoneListAdmin);
