import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { connect } from 'react-redux';

import WhatifWrapper from '../components/WhatifWrapper';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import HeaderThumbCover from '../components/HeaderThumbCover';
import Loading from '../../../components/pages/loading';

import IdeaInfos from '../components/IdeaInfos';

import { IDEA_WHATIF_API } from '../../../actions/idea-whatif/actions';

import { IdeaWhatIfStatus } from '../../../model';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_idea_detail: data => {
            dispatch(IDEA_WHATIF_API.get_idea_detail(data));
        },
        decline_offer: data => {
            dispatch(IDEA_WHATIF_API.decline_offer(data));
        },
        decline: data => {
            dispatch(IDEA_WHATIF_API.decline(data));
        },
        accept: data => {
            dispatch(IDEA_WHATIF_API.accept(data));
        },
        delete_detail: () => {
            dispatch(IDEA_WHATIF_API.delete_detail())
        }
    };
};


const Main = styled.div`
  padding-top: 0px;
  padding-bottom: 100px;
  color: #353535;
`;

class IdeaView extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
        const data = {
            id: this.props.match.params.whatIfIdeaId,
            prefix: "PartnerWhatIf"
        }
        this.props.get_idea_detail(data);

    }

    componentWillMount = () => {
        this.props.delete_detail()
    }

    acceptOffer = () => {
        const data = {
            prefix: "PartnerWhatIf",
            whatIfIdeaId: this.props.ideaWhatIf.ideaDetail.whatIfIdeaId
        }
        this.props.accept(data)

    }

    declineOffer = () => {
        const data = {
            prefix: "PartnerWhatIf",
            whatIfIdeaId: this.props.ideaWhatIf.ideaDetail.whatIfIdeaId
        }
        this.props.decline(data)
    }


    render() {
        if (!this.props.ideaWhatIf.ideaDetail) {
            return (<Loading />);
        }
        const {
            ideaWhatIf: {
                ideaDetail,
                ideaDetail: {
                    partner: {
                        imageLogo,
                        imageIntro
                    },
                    offers
                }
            }
        } = this.props;

 

        return (
            <WhatifWrapper>
                {/* <SubHeader /> */}
                <HeaderThumbCover
                    thumb={imageLogo ? imageLogo : ""}
                    bgHeader={imageIntro ? imageIntro : ""}
                    // isCreative
                    link={ideaDetail.status == IdeaWhatIfStatus.Draft ? `/parceiro-ideias/cadastro/ideia/${this.props.ideaWhatIf.ideaDetail.whatIfId}/editar/${this.props.ideaWhatIf.ideaDetail.whatIfIdeaId}` : false}
                />
                <ContainerPage>
                    <Main>
                        <IdeaInfos
                            counterProposal={true}
                            idea={ideaDetail}
                            isView
                            isPartner
                            accept={this.acceptOffer}
                            decline={this.declineOffer}
                        />
                    </Main>
                </ContainerPage>
            </WhatifWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdeaView);
