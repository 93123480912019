import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { useSupplier } from "../../../../../../../hooks/useSupplier/useSupplier.hook";
import { useUpload } from "../../../../../../../hooks/useUpload/useUpload.hook";
import { Drawer } from "../../../../../../../components/UI/Drawer";
import { ButtonBpool } from "../../../../../../../components/UI/ButtonBpool";
import { NotificationBPool } from "../../../../../../../components/UI/NotificationBPool";
import { FormNF } from "../../../../../components/FormNF";
import { formatPaymentBeneficiaries } from "./formatPaymentBeneficiaries";
import * as S from "../styles";

export const DrawerStatus1 = ({
  drawer,
  handleCloseDrawer,
  refetch,
  isPartner,
}) => {
  const { getPaymentInformations, putBillingInvoice } = useSupplier();
  const auth = useSelector((state) => state.auth);
  const { uploadFile: uploadFileS3 } = useUpload();
  const [isLoading, setIsloading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [formData, setFormData] = useState({});
  const [paymentInformations, setDataPaymentInformations] = useState({});
  const [paymentBeneficiaries, setPaymentBeneficiaries] = useState([]);
  const [errorsInputs, setErrorsInputs] = useState([]);

  const handleOpenDrawer = async () => {
    console.log("open 1");
    try {
      setIsloading(true);
      const response = await getPaymentInformations(isPartner);
      setDataPaymentInformations({
        ...response?.data,
        registrationId: response?.data?.beneficiaryId,
        registrationName: response?.data?.beneficiaryName,
      });
      const arrayPaymentBeneficiaries = formatPaymentBeneficiaries(
        response?.data?.paymentBeneficiaries
      );
      setPaymentBeneficiaries(arrayPaymentBeneficiaries);
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Houve algum erro, tente novamente"),
        duration: 3,
      });
    } finally {
      setIsloading(false);
      localStorage.removeItem("@BPOOL-supplier-bPaymentId");
      localStorage.removeItem("@BPOOL-accounts-dataSubirNf");
    }
  };

  const handleChangeForm = (dataForm) => {
    setFormData(dataForm);
  };

  const handleSend = async () => {
    try {
      setIsSending(true);
      let objSendNewNF = {
        supplierId: auth?.supplierId,
        bPaymentId: drawer?.bPaymentId,
        invoiceNumber: formData?.invoiceNumber,
        userTermsAccepted: true,
        file: {},
        beneficiaryName: formData?.beneficiaryName,
        beneficiaryId: formData?.beneficiaryId,
        paymentBeneficiaryId: formData?.paymentBeneficiaryId,
        paymentBeneficiaryName: formData?.beneficiaryName,
      };

      if (formData?.file?.name) {
        const result = await uploadFileS3({
          file: formData?.file,
          idUser: `${auth?.supplierId}`,
          fieldId: `bpay-invoice-${auth?.supplierId}-files`,
        });

        objSendNewNF = {
          ...objSendNewNF,
          file: {
            name: result?.name,
            url: result?.url,
            type: result?.type,
            size: formData?.file?.size,
            uploadedDate: result?.uploadedDate,
          },
        };
      }
      if (formData?.slip?.name) {
        const result = await uploadFileS3({
          file: formData?.slip,
          idUser: `${auth?.supplierId}`,
          fieldId: `bpay-invoice-${auth?.supplierId}-file-slip`,
        });

        objSendNewNF = {
          ...objSendNewNF,
          isSlip: true,
          slip: {
            name: result?.name,
            url: result?.url,
            type: result?.type,
            size: formData?.slip?.size,
            uploadedDate: result?.uploadedDate,
          },
        };
      }
      await putBillingInvoice(objSendNewNF, isPartner);
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("NF Enviada"),
        duration: 3,
      });
      handleCloseDrawer();
      refetch();
    } catch (error) {
      setErrorsInputs(error?.response?.data?.errors);
      // NotificationBPool.open({
      //   type: "error",
      //   title: I18n.get("Erro!"),
      //   description: I18n.get("Erro ao enviar a NF"),
      //   duration: 3,
      // });
    } finally {
      setIsSending(false);
    }
  };

  const handleChangeErrors = (errors) => {
    setErrorsInputs(errors);
  };

  useEffect(() => {
    if (drawer?.visible === true) {
      handleOpenDrawer();
    }
  }, [drawer?.visible]);

  return (
    <>
      <Drawer
        open={drawer.visible}
        handleClose={handleCloseDrawer}
        width={600}
        buttons={
          <>
            <ButtonBpool
              text={I18n.get("Enviar")}
              onClick={handleSend}
              theme="primary"
              loading={isSending}
            />
          </>
        }
      >
        <S.TitleDrawer style={{ marginBottom: 16 }}>
          {I18n.get("Subir nova nota")}
        </S.TitleDrawer>

        <FormNF
          modules={[
            "DadosDaEmpresa",
            "Endereco",
            "DadosDaNota",
            "FormaDeRecebimento",
            "CarregarNF",
          ]}
          modulesDisableds={["DadosDaEmpresa", "Endereco"]}
          onChangeForm={handleChangeForm}
          formError={errorsInputs}
          onChangeErrors={handleChangeErrors}
          isLoadingGet={isLoading}
          nfValue={drawer?.nfValue}
          bPaymentId={drawer?.bPaymentId}
          defaultValues={paymentInformations}
          isDrawer={true}
          paymentBeneficiaries={paymentBeneficiaries}
        />
      </Drawer>
    </>
  );
};
