import React, { Fragment } from "react";

import { I18n } from "aws-amplify";

import NumberFormat from "react-number-format";

import SelectSearchField from "./form/select-search-field";

import TooltipField from "./form/tooltip-field";

import { TextareaHourDescription } from "../v2/components/Shared/TextareaHourDescription";

import BackgroundA from "./../image/bundle_bg_A.png";
import BackgroundB from "./../image/bundle_bg_B.png";
import BackgroundC from "./../image/bundle_bg_C.png";
import IcoCar from "./../image/ico-car.png";
import Modal from "./ui/modal";

import ReactTooltip from "react-tooltip";

import Config from "../config";

import { Select } from "antd";

const { Option } = Select;

const BRL = ({ amount, symbol, tSeparator, dSeparator }) => (
  <NumberFormat
    value={amount ?? 0}
    displayType={"text"}
    thousandSeparator={tSeparator ?? "."}
    decimalSeparator={dSeparator ?? ","}
    prefix={`${symbol ?? "R$"} `}
    decimalScale={2}
    fixedDecimalScale={true}
  />
);

const CustomOption = ({ innerProps, isDisabled, data }) => {
  const asset = data.data;
  const currency = data.data.currency;

  return !asset.isDisabled ? (
    <div {...innerProps}>
      <div
        className="asset-label"
        data-tip={asset.description}
        data-for={data.value}
      >
        {data.label} &nbsp;{" "}
        <span className="tag">
          <BRL
            amount={asset.price}
            symbol={currency.symbolOnly}
            tSeparator={currency.thousandSeparator}
            dSeparator={currency.decimalSeparator}
          />
        </span>
      </div>
      <ReactTooltip id={data.value} place="right" type="dark" effect="solid" />
    </div>
  ) : (
    <div className="asset-label is-disable">{data.label}</div>
  );
};

class AssetBundle extends React.Component {
  constructor(props) {
    super();

    var variation =
      (props.projectAssets && props.projectAssets[0]) ||
      (props.variations && props.variations[0]);

    if (props.selected.variationId && !props.isInvite) {
      variation =
        props.variations &&
        props.variations.filter(
          (item) => item.id == props.selected.variationId
        )[0];
    }

    let curDefault = {
      code: "BRL",
      name: "Brazilian Real",
      symbolOnly: "R$",
      symbol: "Brazilian Real R$",
      thousandSeparator: ".",
      decimalSeparator: ",",
    };

    this.state = {
      currencyType: props.currency && props.currency.code,
      rates: [],
      currencies: [],
      currency: props.currency || curDefault,
      variation: variation,
      isMobile: null,
      selected: null,
      form: {
        assetId: {
          label: "",
          value: "",
          onChange: this.onChangeAssetId.bind(this),
          error: props.errors && props.errors.assetId,
          successIcon: false,
          visited: true,
          components: { Option: CustomOption },
        },
      },
      isExpanded: true,
    };
    this.select = this.onSelect.bind(this);
    this.closeModal = this.onCloseModal.bind(this);
  }

  convertToCurrency(amount, symbol) {
    //se for Taxa fixa, pegar do projeto
    //Taxas fixas sempre sao MOEDA LOCAL -> DOLLAR
    //Nesse caso como o valor fixo foi informado de Dollar p/ local, a operação tem de dividir.
    //Nos {I18n.get("Outros")} casos onde é Local p/ Dollar, tem de multiplicar.
    if (this.props.isFixedRate == true && symbol == "USD") {
      let fixedRate = parseFloat(this.props.exchangeRate?.rate ?? 1);

      return Number.parseFloat(amount / fixedRate).toFixed(2);
    }

    let originalRates = this.props.bp?.exchangeRates?.filter(
      (xx) => xx.from === this.props.currency?.code ?? "BRL"
    );

    let rateData =
      this.state.rates?.length > 0 ? this.state.rates : originalRates;

    let rate = rateData.find(
      (xx) => xx.from === this.state.currency.code && xx.to === symbol
    )?.rate;

    return Number.parseFloat(amount * rate).toFixed(2);
  }

  componentDidMount() {
    this.setState({
      isMobile: window.innerWidth < 768,
      currency: this.props.currency ?? "BRL",
      currencyType: this.props.currency?.code ?? "BRL",
      rates: this.props.bp?.exchangeRates?.filter(
        (xx) => xx.from === this.props.currency?.code ?? "BRL"
      ),
    });
  }

  handleUpdate = () => {
    const dataGet = {
      clientId: this.props.clientId,
      projectId: this.props.projectId,
    };

    this.props.get_project(dataGet);
  };

  onSelect(item, e) {
    this.setState({ selected: item });
  }

  onCloseModal(e) {
    e.preventDefault();

    this.setState({ selected: null });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps) return;

    if (!this.props.isBundlesCart) {
      if (this.props != prevProps) {
        var variation = null;

        if (
          this.props.selected &&
          this.props?.selected?.variationId &&
          !this.props.isInvite &&
          !this.props.IsAdmin
        ) {
          variation =
            (this.props?.variations &&
              this.props?.variations.filter(
                (item) => item?.id == this.props?.selected?.variationId
              )[0]) ||
            this.props.projectAssets[0];
        } else {
          variation =
            (this.props?.projectAssets && this.props?.projectAssets[0]) ||
            (this.props?.variations && this.props?.variations[0]);
        }

        this.setState({ variation: variation });
      }
    }

    if (this.props.currency != prevProps.currency) {
      this.setState({
        currency: this.props.currency ?? "BRL",
        currencyType: this.props.currency?.code ?? "BRL",
        rates: this.props.bp?.exchangeRates?.filter(
          (xx) => xx.from === this.props.currency?.code ?? "BRL"
        ),
      });
    }
  }

  selectVariation(variation, e) {
    this.setState({ variation: variation });
  }

  onChangeAssetId(value) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["assetId"].value = value;

      return nextState;
    });
  }

  onEditVariationClick(e) {
    e.preventDefault();

    var assets = [];

    this.state.variation.assets.forEach((sub) => {
      const asset = this.getAsset(sub.asset);

      if (asset) {
        asset.canBeExcluded =
          sub.canBeExcluded ||
          (this.state.variation.assetsCanBeExcluded &&
            this.state.variation.assetsCanBeExcluded.indexOf(sub.asset) > -1);

        assets.push(asset);
      }
    });

    this.props.createCart(this.state.variation.id, assets);
  }

  onCancelEditVariationClick(e) {
    e.preventDefault();

    this.setState({
      currencyType: this.props.currency.code,
    });

    this.props.clearCart();
  }

  onAddAsset(e) {
    e.preventDefault();

    const value = this.state.form.assetId.value;

    if (!value) return;

    var asset = this.getAsset(value);

    asset.canBeExcluded = true;

    this.props.addItem(asset);

    this.setState({
      currencyType: this.props.currency.code,
    });

    // reset do combo
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["assetId"].value = "";

      return nextState;
    });
  }

  onDeleteAsset(asset, e) {
    this.props.delItem2(asset);
  }

  getColor(variationType) {
    switch (variationType) {
      case "B":
        return "#BAB1D0";
      case "C":
        return "#CBEDDF";
      case "D":
        return "#FFC5B9";
      default:
        return "#C8E5F3";
    }
  }

  getBackgorund() {
    if (!this.state.variation || this.state.variation.variationType == "A") {
      return <img src={BackgroundA} alt="" />;
    }

    if (this.state.variation.variationType == "B") {
      return <img src={BackgroundB} alt="" />;
    }

    if (this.state.variation.variationType == "C") {
      return <img src={BackgroundC} alt="" />;
    }
  }

  getAsset(code) {
    if (!code) return {};
    if (!this.props.assets) return;

    let item = this.props.assets.filter((asset) => asset.code == code);

    if (item && item.length) {
      return item[0];
    } else {
      return {};
    }
  }

  getSpecialim(code) {
    if (!code) return {};
    if (!this.props.assetSpecialisms) return;

    const item = this.props.assetSpecialisms.filter((s) => s.code == code);

    if (item && item.length) {
      return item[0];
    } else {
      return {};
    }
  }

  getAssetType(code) {
    if (!code) return {};
    if (!this.props.assetTypes) return;

    const item = this.props.assetTypes.filter((t) => t.code == code);

    if (item && item.length) {
      return item[0];
    } else {
      return {};
    }
  }

  getDescription() {
    return this.state.isExpanded
      ? this.state.variation.description
      : this.state.variation.summary;
  }

  getComplexity() {
    return this.state.variation.complexity;
  }

  getComplexityId() {
    return this.state.variation.complexityId;
  }

  getComplexityDescription() {
    return this.state.variation.complexDescription;
  }

  isSelected() {
    return this.props.selected.bundleId === this.props.bundleId;
  }

  expand(e) {
    this.setState({ isExpanded: true });
  }

  handleCurrency = (value) => {
    this.setState({ currencyType: value });
  };

  printMoney = (value) => {
    let currency = this.state.currency;
    let currencyState = this.state.currencyType;

    if (currencyState == this.props.currency?.code) {
      return (
        <BRL
          amount={value}
          symbol={currency.symbolOnly}
          tSeparator={currency.thousandSeparator}
          dSeparator={currency.decimalSeparator}
        />
      );
    }

    if (currencyState != this.props.currency?.code && currencyState == "USD") {
      return (
        <BRL
          amount={this.convertToCurrency(value, "USD")}
          symbol={"USD"}
          tSeparator={","}
          dSeparator={"."}
        />
      );
    }

    if (currencyState != this.props.currency?.code && currencyState == "EUR") {
      return (
        <BRL
          amount={this.convertToCurrency(value, "EUR")}
          symbol={"€"}
          tSeparator={","}
          dSeparator={"."}
        />
      );
    }

    if (currencyState != this.props.currency?.code && currencyState == "ARS") {
      return (
        <BRL
          amount={this.convertToCurrency(value, "ARS")}
          symbol={"ARS"}
          tSeparator={","}
          dSeparator={"."}
        />
      );
    }

    if (currencyState != this.props.currency?.code && currencyState == "COP") {
      return (
        <BRL
          amount={this.convertToCurrency(value, "COP")}
          symbol={"COP"}
          tSeparator={","}
          dSeparator={"."}
        />
      );
    }
  };

  render() {
    if (!this.state.variation) return null;

    let currency = this.state.currency;

    let assetTax = this.props.assetTax || 0;
    let otherTax = this.props.otherTax || 0;

    const { applyClientDiscount } = Config;

    const price = this.state.variation.price;

    const priceDiscount = this.state.variation.priceWithDiscount;

    const hasDiscount = applyClientDiscount && priceDiscount < price;

    const totalDiscount = ((1 - priceDiscount / price) * 100).toFixed(0);

    const isEditing =
      this.props.cartId == this.state.variation.id && !this.props.isInvite;

    const isInvite = this.props.isInvite || false;

    const isPartner = this.props.IsPartner || false;

    // const teste = this.props.projectAssets || this.props.variations;

    const variations = this.isSelected()
      ? this.props.variations.filter(
          (item) => item.id == this.props.selected.variationId
        )
      : this.props.variations.sort(
          (a, b) => (a.label > b.label) - (a.label < b.label)
        );

    var totalPrice = 0;
    var discountPrice = 0;
    var finalPrice = 0;
    var totalItem = 0;

    var complexityId = this.state.variation.complexityId || "";
    var complexity = this.state.variation.complexity || "";

    var cart = [];
    const _cart = this.props.cartAssets || [];

    _cart.forEach((item) => {
      var index = cart.findIndex((_item) => {
        return _item.asset.id == item.id;
      });

      if (index > -1) {
        cart[index].total++;
      } else {
        cart.push({
          asset: item,
          total: 1,
        });
      }
      totalPrice += item.price;
      totalItem++;
    });

    finalPrice = totalPrice;

    let priceClient = price; //priceDiscount;
    let finalPriceBPoolFee = this.props.priceBPoolComission; // || priceClient / 10; //TODO: diff % for clients

    let finalPricePartner = this.props.pricePartner;

    //let isPriceForced = false;

    //Se ta vindo da base, nao calcular pois tem projetos com acertos manuais
    //if (this.props.PriceForced && this.props.PriceForced == true) {

    //priceClient = this.props.PriceClientWithDiscount;
    //finalPriceBPoolFee = this.props.PriceBPool;
    //finalPricePartner = this.props.PricePartner;
    //isPriceForced = true;
    //}

    // const priceClientTax = this.props.priceClientWithDiscountTax;
    const priceClientTax = this.props.priceClientWithDiscountTax;
    const priceClientOtherTax = this.props.priceClientWithDiscountOtherTax;
    const finalPricePartnerTax = this.props.pricePartnerTax;
    const finalPricePartnerOtherTax = this.props.pricePartnerOtherTax;
    const finalPriceBPoolFeeTax = this.props.priceBPoolComissionTax;
    const finalPriceBPoolFeeOtherTax = this.props.priceBPoolComissionOtherTax;

    let specialismValues =
      this.props.bp &&
      this.props.assetSpecialisms &&
      this.props.specialism &&
      this.props.specialism.map((item) => {
        const specialism =
          this.props.assetSpecialisms.find((s) => s.code == item) || {};

        return specialism;
      });

    let listSpecialism =
      specialismValues && specialismValues.map((item) => item.label);

    var totalPrice2 = 0;
    var discountPrice2 = 0;
    var finalPrice2 = 0;
    var totalItem2 = 0;

    var complexityId = this.state.variation.complexityId || "";
    var complexity = this.state.variation.complexity || "";

    var cart2 = [];
    const _cart2 = this.state.variation.assets || [];

    _cart2.forEach((item) => {
      var index = cart2.findIndex((_item) => {
        return _item.asset.asset == item.asset;
      });

      if (index > -1) {
        cart2[index].total++;
      } else {
        cart2.push({
          asset: item,
          total: 1,
        });
      }
      totalPrice2 += item.price;
      totalItem2++;
    });

    finalPrice2 = totalPrice2;

    let hasIsManHour = false;

    for (let i = 0; i < cart2.length; i++) {
      if (cart2[i].asset.isManHour === true) {
        hasIsManHour = true;
        break;
      }
    }

    const priceClientFormat =
      this.props?.project?.priceClientWithDiscountWithOtherTax ||
      this.props?.priceClientWithDiscountWithOtherTax;

    return (
      <article
        className={`media media-template-card break-word AssetBundle ${
          this.props.isFull ? "full" : ""
        }`}
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="media-content">
          <div className="content">
            {this.props.currency && this.props.currency.code != "BRL" && (
              <div className="SelectMoeda">
                {!isEditing && !this.props.showConvert && (
                  <Select
                    defaultValue={this.state.currencyType}
                    value={this.state.currencyType}
                    style={{ width: 120 }}
                    onChange={this.handleCurrency}
                  >
                    {this.props.currency && this.props.currency.code && (
                      <Option value={this.props.currency.code}>
                        {this.props.currency.code}
                      </Option>
                    )}
                    <Option value="ARS">ARS</Option>
                    <Option value="COP">COP</Option>
                    <Option value="USD">USD</Option>
                    <Option value="EUR">EUR</Option>
                  </Select>
                )}
              </div>
            )}

            <div className="topAssets">
              <p className="subtitle title-new">{this.props.title}</p>
              <p>
                {I18n.get("Especialidades")}:{" "}
                {listSpecialism && listSpecialism.join(", ")}
              </p>
            </div>

            {this.props.paymentCurrency &&
              !this.props.showConvert &&
              this.props.IsPartner && (
                <p className="BoxPaymentCurrency">
                  <b>{I18n.get("Atenção")}: </b>{" "}
                  {I18n.get("Este cliente solicita o faturamento em")} {""}{" "}
                  <b>{this.props.paymentCurrency.code}</b>
                  {"."}{" "}
                  {I18n.get(
                    "Use o botão acima para alterar a moeda e ver o preço."
                  )}
                </p>
              )}

            <p className="priceText">
              {this.props.IsPartner && (
                <strong>{I18n.get("Valor Parceiro")}</strong>
              )}
              {!this.props.IsPartner && <strong>{I18n.get("Valor")}</strong>}

              {!isInvite && (
                <Fragment>
                  {(this.props.IsClient === false || isPartner == true) &&
                    !this.props.IsAdmin && (
                      <span
                        className="bigValue"
                        data-project-total={this.printMoney(finalPricePartner)}
                      >
                        {/* {this.printMoney(finalPricePartner)} */}
                        {this.printMoney(finalPricePartner)}
                      </span>
                    )}

                  {(this.props.IsShoppingCart === true ||
                    this.props.IsClient === true ||
                    this.props.IsAdmin === true) && (
                    <Fragment>
                      <span
                        className="bigValue"
                        data-project-total={
                          this.props?.project
                            ?.priceClientWithDiscountWithOtherTaxFmt ||
                          this.props?.project?.project
                            ?.priceClientWithDiscountWithOtherTaxFmt ||
                          this.state.variation.priceWithDiscount
                        }
                      >
                        {this.props?.project
                          ?.priceClientWithDiscountWithOtherTaxFmt ||
                        this.props?.priceClientWithDiscountWithOtherTax
                          ? this.printMoney(priceClientFormat)
                          : this.printMoney(
                              this.state.variation.priceWithDiscount
                            )}
                      </span>

                      {assetTax > 0 && (
                        <div className="discount has-text-grey-dark">
                          <div className="countBx">
                            <span
                              style={{
                                color: "#aaa",
                                margin: "0 0 -10px",
                                display: "block",
                              }}
                            >
                              {otherTax > 0
                                ? `(${I18n.get(
                                    "incluyendo gross up"
                                  )} ${otherTax}%)`
                                : ""}
                            </span>

                            {assetTax > 0 && (
                              <>
                                +{" "}
                                <span
                                  className="spanVat"
                                  data-project-tax-perc={assetTax}
                                  data-project-tax-total={this.printMoney(
                                    priceClientTax
                                  )}
                                >
                                  IVA {assetTax}
                                </span>
                                % {this.printMoney(priceClientTax)}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}

              {isInvite && (
                <>
                  <span
                    className="bigValue"
                    data-project-total={this.props.PricePartner}
                  >
                    {this.printMoney(finalPricePartner)}
                  </span>

                  {(assetTax > 0 || otherTax > 0) && (
                    <div className="discount has-text-grey-dark">
                      <div className="countBx">
                        {otherTax > 0 && (
                          <>
                            {" "}
                            +{" "}
                            <span
                              className="spanVat"
                              data-partner-tax-perc={otherTax}
                              data-partner-tax-total={this.printMoney(
                                this.props.pricePartnerOtherTax
                              )}
                            >
                              {I18n.get("Outros")} {otherTax}
                            </span>
                            % {this.printMoney(this.props.pricePartnerOtherTax)}
                          </>
                        )}

                        {assetTax > 0 && (
                          <>
                            {" "}
                            +{" "}
                            <span
                              className="spanVat"
                              data-partner-tax-perc={assetTax}
                              data-partner-tax-total={this.printMoney(
                                this.props.pricePartnerTax
                              )}
                            >
                              IVA {assetTax}
                            </span>
                            % {this.printMoney(this.props.pricePartnerTax)}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </p>

            <nav className="someBox">
              <div className="">
                <div className="level-item">
                  <h5 className="title" style={{ width: "100%" }}>
                    <div className="discount has-text-grey-dark">
                      {!this.props.IsOnGoing && (
                        <>
                          {(assetTax > 0 || otherTax > 0) &&
                            !isInvite &&
                            this.props.IsPartner && (
                              <div className="countBx">
                                {otherTax > 0 && (
                                  <>
                                    {" "}
                                    +{" "}
                                    <span
                                      className="spanVat"
                                      data-partner-tax-perc={otherTax}
                                      data-partner-tax-total={
                                        finalPricePartnerOtherTax
                                      }
                                    >
                                      {I18n.get("Outros")} {otherTax}
                                    </span>
                                    %{" "}
                                    {this.printMoney(finalPricePartnerOtherTax)}
                                  </>
                                )}

                                {assetTax > 0 && (
                                  <>
                                    {" "}
                                    +{" "}
                                    <span
                                      className="spanVat"
                                      data-partner-tax-perc={assetTax}
                                      data-partner-tax-total={
                                        finalPricePartnerTax
                                      }
                                    >
                                      IVA {assetTax}
                                    </span>
                                    % {this.printMoney(finalPricePartnerTax)}
                                  </>
                                )}
                              </div>
                            )}

                          {!this.props.IsAdmin && isInvite && (
                            <div
                              style={{
                                fontWeight: "lighter",
                                paddingTop: "5px",
                              }}
                              className="descMini"
                            >
                              <p
                                data-client-total={this.printMoney(
                                  this.props.priceClientWithDiscount
                                )}
                              >
                                {I18n.get("- Valor Cliente")}{" "}
                                {this.printMoney(
                                  this.props.priceClientWithDiscount
                                )}{" "}
                                {(assetTax > 0 || otherTax > 0) && (
                                  <Fragment>
                                    [
                                    {otherTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {this.printMoney(priceClientOtherTax)}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-client-tax-perc={otherTax}
                                          data-client-tax-total={this.printMoney(
                                            priceClientOtherTax
                                          )}
                                        >
                                          {I18n.get("Outros")} {otherTax}%
                                        </span>
                                      </>
                                    )}
                                    {assetTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {this.printMoney(priceClientTax)}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-client-tax-perc={assetTax}
                                          data-client-tax-total={this.printMoney(
                                            priceClientTax
                                          )}
                                        >
                                          IVA {assetTax}%
                                        </span>
                                      </>
                                    )}
                                    ]
                                  </Fragment>
                                )}
                              </p>
                              <p
                                data-partner-total={this.printMoney(
                                  finalPricePartner
                                )}
                              >
                                {I18n.get("- Valor Parceiro")}{" "}
                                <span>
                                  {this.printMoney(finalPricePartner)}
                                </span>{" "}
                                {(assetTax > 0 || otherTax > 0) && (
                                  <Fragment>
                                    [
                                    {otherTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        {this.printMoney(
                                          this.props.pricePartnerOtherTax
                                        )}{" "}
                                        <span
                                          className="spanVat"
                                          data-partner-tax-perc={otherTax}
                                          data-partner-tax-total={
                                            this.props.pricePartnerOtherTax
                                          }
                                        >
                                          {I18n.get("Outros")} {otherTax}%
                                        </span>
                                      </>
                                    )}
                                    {assetTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        {this.printMoney(
                                          this.props.pricePartnerTax
                                        )}{" "}
                                        <span
                                          className="spanVat"
                                          data-partner-tax-perc={assetTax}
                                          data-partner-tax-total={
                                            this.props.pricePartnerTax
                                          }
                                        >
                                          IVA {assetTax}%
                                        </span>
                                      </>
                                    )}
                                    ]
                                  </Fragment>
                                )}
                              </p>
                              <p
                                data-bpool-total={
                                  this.props.priceBPoolComission
                                }
                              >
                                {I18n.get("- Fee BPool")}{" "}
                                {this.printMoney(
                                  this.props.priceBPoolComission
                                )}{" "}
                                {(assetTax > 0 || otherTax > 0) && (
                                  <Fragment>
                                    [
                                    {otherTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {this.printMoney(
                                            finalPriceBPoolFeeOtherTax
                                          )}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-bpool-tax-perc={otherTax}
                                          data-bpool-tax-total={
                                            finalPriceBPoolFeeOtherTax
                                          }
                                        >
                                          {I18n.get("Outros")} {otherTax}%
                                        </span>
                                      </>
                                    )}
                                    {assetTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {this.printMoney(
                                            finalPriceBPoolFeeTax
                                          )}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-bpool-tax-perc={assetTax}
                                          data-bpool-tax-total={
                                            finalPriceBPoolFeeTax
                                          }
                                        >
                                          IVA {assetTax}%
                                        </span>
                                      </>
                                    )}
                                    ]
                                  </Fragment>
                                )}
                              </p>
                            </div>
                          )}

                          {!this.props.IsAdmin &&
                            !this.props.IsShoppingCart &&
                            !isInvite &&
                            !isEditing &&
                            isPartner == false && (
                              <>
                                <div
                                  style={{
                                    fontWeight: "lighter",
                                    paddingTop: "5px",
                                  }}
                                  className="descMini"
                                >
                                  <p
                                    data-partner-total={this.printMoney(
                                      finalPricePartner
                                    )}
                                  >
                                    {I18n.get("- Valor PO")}{" "}
                                    <span>
                                      <b>
                                        {
                                          this.props
                                            .priceClientWithDiscountWithOtherTaxFmt
                                        }
                                      </b>
                                    </span>{" "}
                                  </p>
                                  <p
                                    data-partner-total={this.printMoney(
                                      finalPricePartner
                                    )}
                                  >
                                    {I18n.get("- Valor Parceiro")}{" "}
                                    <span>
                                      {this.printMoney(finalPricePartner)}
                                    </span>{" "}
                                    {(assetTax > 0 || otherTax > 0) && (
                                      <Fragment>
                                        [
                                        {otherTax > 0 && (
                                          <>
                                            {" "}
                                            +{" "}
                                            {this.printMoney(
                                              this.props.pricePartnerOtherTax
                                            )}{" "}
                                            <span
                                              className="spanVat"
                                              data-partner-tax-perc={otherTax}
                                              data-partner-tax-total={this.printMoney(
                                                this.props.pricePartnerOtherTax
                                              )}
                                            >
                                              {I18n.get("Outros")} {otherTax}%
                                            </span>
                                          </>
                                        )}
                                        {assetTax > 0 && (
                                          <>
                                            {" "}
                                            +{" "}
                                            {this.printMoney(
                                              this.props.pricePartnerTax
                                            )}{" "}
                                            <span
                                              className="spanVat"
                                              data-partner-tax-perc={assetTax}
                                              data-partner-tax-total={this.printMoney(
                                                this.props.pricePartnerTax
                                              )}
                                            >
                                              IVA {assetTax}%
                                            </span>
                                          </>
                                        )}
                                        ]
                                      </Fragment>
                                    )}
                                  </p>
                                  <p
                                    data-bpool-total={this.printMoney(
                                      finalPriceBPoolFee
                                    )}
                                  >
                                    {I18n.get("- Fee BPool")}{" "}
                                    <span>
                                      {this.printMoney(finalPriceBPoolFee)}
                                    </span>{" "}
                                    {(assetTax > 0 || otherTax > 0) && (
                                      <Fragment>
                                        [
                                        {otherTax > 0 && (
                                          <>
                                            {" "}
                                            +{" "}
                                            <span>
                                              {this.printMoney(
                                                finalPriceBPoolFeeOtherTax
                                              )}
                                            </span>{" "}
                                            <span
                                              className="spanVat"
                                              data-bpool-tax-perc={otherTax}
                                              data-bpool-tax-total={
                                                finalPriceBPoolFeeOtherTax
                                              }
                                            >
                                              {I18n.get("Outros")} {otherTax}%
                                            </span>
                                          </>
                                        )}
                                        {assetTax > 0 && (
                                          <>
                                            {" "}
                                            +{" "}
                                            <span>
                                              {this.printMoney(
                                                finalPriceBPoolFeeTax
                                              )}
                                            </span>{" "}
                                            <span
                                              className="spanVat"
                                              data-bpool-tax-perc={assetTax}
                                              data-bpool-tax-total={
                                                finalPriceBPoolFeeTax
                                              }
                                            >
                                              IVA {assetTax}%
                                            </span>
                                          </>
                                        )}
                                        ]
                                      </Fragment>
                                    )}
                                  </p>
                                </div>
                              </>
                            )}

                          {!this.props.IsAdmin &&
                            !this.props.IsShoppingCart &&
                            !isInvite &&
                            !isEditing &&
                            isPartner == true && (
                              <div
                                style={{
                                  fontWeight: "lighter",
                                  paddingTop: "5px",
                                }}
                                className="descMini"
                              >
                                <p
                                  data-client-total={
                                    this.props.priceClientWithDiscount
                                  }
                                >
                                  {I18n.get("- Valor Cliente")}{" "}
                                  {this.printMoney(
                                    this.props.priceClientWithDiscount
                                  )}{" "}
                                  {(assetTax > 0 || otherTax > 0) && (
                                    <Fragment>
                                      [
                                      {otherTax > 0 && (
                                        <>
                                          {" "}
                                          +{" "}
                                          <span>
                                            {this.printMoney(
                                              priceClientOtherTax
                                            )}
                                          </span>{" "}
                                          <span
                                            className="spanVat"
                                            data-client-tax-perc={otherTax}
                                            data-client-tax-total={this.printMoney(
                                              priceClientOtherTax
                                            )}
                                          >
                                            {I18n.get("Outros")} {otherTax}%
                                          </span>
                                        </>
                                      )}
                                      {assetTax > 0 && (
                                        <>
                                          {" "}
                                          +{" "}
                                          <span>
                                            {this.printMoney(priceClientTax)}
                                          </span>{" "}
                                          <span
                                            className="spanVat"
                                            data-client-tax-perc={assetTax}
                                            data-client-tax-total={this.printMoney(
                                              priceClientTax
                                            )}
                                          >
                                            IVA {assetTax}%
                                          </span>
                                        </>
                                      )}
                                      ]
                                    </Fragment>
                                  )}
                                </p>
                                <p
                                  data-bpool-total={this.printMoney(
                                    finalPriceBPoolFee
                                  )}
                                >
                                  {I18n.get("- Fee BPool")}{" "}
                                  <span>
                                    {this.printMoney(finalPriceBPoolFee)}
                                  </span>{" "}
                                  {(assetTax > 0 || otherTax > 0) && (
                                    <Fragment>
                                      [
                                      {otherTax > 0 && (
                                        <>
                                          {" "}
                                          +{" "}
                                          <span>
                                            {this.printMoney(
                                              finalPriceBPoolFeeOtherTax
                                            )}
                                          </span>{" "}
                                          <span
                                            className="spanVat"
                                            data-bpool-tax-perc={otherTax}
                                            data-bpool-tax-total={
                                              finalPriceBPoolFeeOtherTax
                                            }
                                          >
                                            {I18n.get("Outros")} {otherTax}%
                                          </span>
                                        </>
                                      )}
                                      {assetTax > 0 && (
                                        <>
                                          {" "}
                                          +{" "}
                                          <span>
                                            {this.printMoney(
                                              finalPriceBPoolFeeTax
                                            )}
                                          </span>{" "}
                                          <span
                                            className="spanVat"
                                            data-bpool-tax-perc={assetTax}
                                            data-bpool-tax-total={
                                              finalPriceBPoolFeeTax
                                            }
                                          >
                                            IVA {assetTax}%
                                          </span>
                                        </>
                                      )}
                                      ]
                                    </Fragment>
                                  )}
                                </p>
                              </div>
                            )}

                          {this.props.IsAdmin && (
                            <div
                              style={{
                                fontWeight: "lighter",
                                paddingTop: "5px",
                              }}
                              className="descMini"
                            >
                              <p data-client-total={priceClient}>
                                {I18n.get("- Valor Cliente")}{" "}
                                {this.printMoney(
                                  this.props.priceClientWithDiscount
                                )}
                                {/* {this.props.priceClientWithDiscountWithOtherTaxFmt} */}{" "}
                                {(assetTax > 0 || otherTax > 0) && (
                                  <Fragment>
                                    [
                                    {otherTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {this.printMoney(priceClientOtherTax)}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-client-tax-perc={otherTax}
                                          data-client-tax-total={this.printMoney(
                                            priceClientOtherTax
                                          )}
                                        >
                                          {I18n.get("Outros")} {otherTax}%
                                        </span>
                                      </>
                                    )}
                                    {assetTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {this.printMoney(priceClientTax)}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-client-tax-perc={assetTax}
                                          data-client-tax-total={this.printMoney(
                                            priceClientTax
                                          )}
                                        >
                                          IVA {assetTax}%
                                        </span>
                                      </>
                                    )}
                                    ]
                                  </Fragment>
                                )}
                              </p>
                              <p
                                data-partner-total={this.printMoney(
                                  finalPricePartner
                                )}
                              >
                                {I18n.get("- Valor Parceiro")}{" "}
                                <span>
                                  {this.printMoney(finalPricePartner)}
                                </span>{" "}
                                {(assetTax > 0 || otherTax > 0) && (
                                  <Fragment>
                                    [
                                    {otherTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {this.printMoney(
                                            this.props.pricePartnerOtherTax
                                          )}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-partner-tax-perc={otherTax}
                                          data-partner-tax-total={
                                            this.props.pricePartnerOtherTax
                                          }
                                        >
                                          {I18n.get("Outros")} {otherTax}%
                                        </span>
                                      </>
                                    )}
                                    {assetTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {this.printMoney(
                                            this.props.pricePartnerTax
                                          )}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-partner-tax-perc={assetTax}
                                          data-partner-tax-total={
                                            this.props.pricePartnerTax
                                          }
                                        >
                                          IVA {assetTax}%
                                        </span>
                                      </>
                                    )}
                                    ]
                                  </Fragment>
                                )}
                              </p>
                              <p
                                data-bpool-total={this.printMoney(
                                  finalPriceBPoolFee
                                )}
                              >
                                {I18n.get("- Fee BPool")}{" "}
                                <span>
                                  {this.printMoney(finalPriceBPoolFee)}
                                </span>{" "}
                                {(assetTax > 0 || otherTax > 0) && (
                                  <Fragment>
                                    [
                                    {otherTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {" "}
                                          {this.printMoney(
                                            finalPriceBPoolFeeOtherTax
                                          )}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-bpool-tax-perc={otherTax}
                                          data-bpool-tax-total={
                                            finalPriceBPoolFeeOtherTax
                                          }
                                        >
                                          {I18n.get("Outros")} {otherTax}%
                                        </span>
                                      </>
                                    )}
                                    {assetTax > 0 && (
                                      <>
                                        {" "}
                                        +{" "}
                                        <span>
                                          {" "}
                                          {this.printMoney(
                                            finalPriceBPoolFeeTax
                                          )}
                                        </span>{" "}
                                        <span
                                          className="spanVat"
                                          data-bpool-tax-perc={assetTax}
                                          data-bpool-tax-total={
                                            finalPriceBPoolFeeTax
                                          }
                                        >
                                          IVA {assetTax}%
                                        </span>
                                      </>
                                    )}
                                    ]
                                  </Fragment>
                                )}
                              </p>
                            </div>
                          )}
                        </>
                      )}

                      {this.props.IsOnGoing && (
                        <>
                          {this.props.IsClient && (
                            <div className="descMini">
                              <div>
                                <p
                                  data-client-total={
                                    this.props
                                      .priceClientWithDiscountWithOtherTaxFmt
                                  }
                                >
                                  {I18n.get("- Valor PO")}{" "}
                                  <span>
                                    {
                                      this.props
                                        .priceClientWithDiscountWithOtherTaxFmt
                                    }
                                  </span>{" "}
                                </p>
                              </div>

                              <div>
                                <p
                                  data-partner-total={this.printMoney(
                                    finalPricePartner
                                  )}
                                >
                                  {I18n.get("- Valor Parceiro")}{" "}
                                  <span>
                                    {this.printMoney(finalPricePartner)}
                                  </span>{" "}
                                  {(assetTax > 0 || otherTax > 0) && (
                                    <Fragment>
                                      [
                                      {otherTax > 0 && (
                                        <>
                                          {" "}
                                          +{" "}
                                          <span>
                                            {this.printMoney(
                                              this.props.pricePartnerOtherTax
                                            )}
                                          </span>{" "}
                                          <span
                                            className="spanVat"
                                            data-partner-tax-perc={otherTax}
                                            data-partner-tax-total={
                                              this.props.pricePartnerOtherTax
                                            }
                                          >
                                            {I18n.get("Outros")} {otherTax}%
                                          </span>
                                        </>
                                      )}
                                      {assetTax > 0 && (
                                        <>
                                          {" "}
                                          +{" "}
                                          <span>
                                            {this.printMoney(
                                              this.props.pricePartnerTax
                                            )}
                                          </span>{" "}
                                          <span
                                            className="spanVat"
                                            data-partner-tax-perc={assetTax}
                                            data-partner-tax-total={
                                              this.props.pricePartnerTax
                                            }
                                          >
                                            IVA {assetTax}%
                                          </span>
                                        </>
                                      )}
                                      ]
                                    </Fragment>
                                  )}
                                </p>
                                <p
                                  data-bpool-total={this.printMoney(
                                    finalPriceBPoolFee
                                  )}
                                >
                                  {I18n.get("- Fee BPool")}{" "}
                                  <span>
                                    {this.printMoney(finalPriceBPoolFee)}
                                  </span>{" "}
                                  {(assetTax > 0 || otherTax > 0) && (
                                    <Fragment>
                                      [
                                      {otherTax > 0 && (
                                        <>
                                          {" "}
                                          +{" "}
                                          <span>
                                            {this.printMoney(
                                              finalPriceBPoolFeeOtherTax
                                            )}
                                          </span>{" "}
                                          <span
                                            className="spanVat"
                                            data-bpool-tax-perc={otherTax}
                                            data-bpool-tax-total={
                                              finalPriceBPoolFeeOtherTax
                                            }
                                          >
                                            {I18n.get("Outros")} {otherTax}%
                                          </span>
                                        </>
                                      )}
                                      {assetTax > 0 && (
                                        <>
                                          {" "}
                                          +{" "}
                                          <span>
                                            {this.printMoney(
                                              finalPriceBPoolFeeTax
                                            )}
                                          </span>{" "}
                                          <span
                                            className="spanVat"
                                            data-bpool-tax-perc={assetTax}
                                            data-bpool-tax-total={
                                              finalPriceBPoolFeeTax
                                            }
                                          >
                                            IVA {assetTax}%
                                          </span>
                                        </>
                                      )}
                                      ]
                                    </Fragment>
                                  )}
                                </p>
                              </div>
                            </div>
                          )}

                          {!this.props.IsClient && (
                            <div className="descMini">
                              {/* Aqui */}
                              <div className="countBx">
                                {assetTax > 0 && (
                                  <div>
                                    +{" "}
                                    <span
                                      className="spanVat"
                                      data-partner-tax-perc={assetTax}
                                      data-partner-tax-total={this.printMoney(
                                        finalPricePartner
                                      )}
                                    >
                                      IVA {assetTax}
                                    </span>
                                    % {this.printMoney(finalPricePartner)}
                                  </div>
                                )}
                              </div>

                              <div
                                style={{
                                  fontWeight: "lighter",
                                  paddingTop: "5px",
                                }}
                              >
                                <br />
                                <p data-client-total={priceClient}>
                                  {I18n.get("- Valor Cliente")}{" "}
                                  {this.printMoney(priceClient)}{" "}
                                  {assetTax > 0 && (
                                    <Fragment>
                                      [ + {this.printMoney(priceClientTax)}{" "}
                                      <span
                                        className="spanVat"
                                        data-client-tax-perc={assetTax}
                                        data-client-tax-total={this.printMoney(
                                          priceClientTax
                                        )}
                                      >
                                        IVA {assetTax}%
                                      </span>
                                      ]
                                    </Fragment>
                                  )}
                                </p>
                                <p
                                  data-partner-total={this.printMoney(
                                    finalPricePartner
                                  )}
                                >
                                  {I18n.get("- Valor Parceiro")}{" "}
                                  <span>
                                    {this.printMoney(finalPricePartner)}
                                  </span>{" "}
                                  {assetTax > 0 && (
                                    <Fragment>
                                      [ +{" "}
                                      <span>
                                        {this.printMoney(
                                          this.props.pricePartnerTax
                                        )}
                                      </span>{" "}
                                      <span
                                        className="spanVat"
                                        data-partner-tax-perc={assetTax}
                                        data-partner-tax-total={
                                          this.props.pricePartnerTax
                                        }
                                      >
                                        IVA {assetTax}%
                                      </span>
                                      ]
                                    </Fragment>
                                  )}
                                </p>
                                <p
                                  data-bpool-total={this.printMoney(
                                    finalPriceBPoolFee
                                  )}
                                >
                                  {I18n.get("- Fee BPool")}{" "}
                                  <span>
                                    {this.printMoney(finalPriceBPoolFee)}
                                  </span>{" "}
                                  {assetTax > 0 && (
                                    <Fragment>
                                      [ +{" "}
                                      <span>
                                        {this.printMoney(finalPriceBPoolFeeTax)}
                                      </span>{" "}
                                      <span
                                        className="spanVat"
                                        data-bpool-tax-perc={assetTax}
                                        data-bpool-tax-total={
                                          finalPriceBPoolFeeTax
                                        }
                                      >
                                        IVA {assetTax}%
                                      </span>
                                      ]
                                    </Fragment>
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {(this.state.currencyType == "USD" ||
                        this.state.currencyType == "EUR") && (
                        <p className="miniInfo">
                          {I18n.get(
                            "USD/EUR apenas para referência, a fatura é emitida em moeda local e o câmbio é o oficial do cliente na data do pagamento. Os valores em dólares podem variar entre o faturamento e o pagamento."
                          )}
                        </p>
                      )}
                      <br />
                    </div>
                  </h5>
                </div>
              </div>
            </nav>

            <div
              className="tabs is-toggle"
              style={{
                borderColor: this.getColor(this.state.variation.variationType),
              }}
            >
              <ul>
                {variations &&
                  variations.map((item, index) => {
                    if (!this.state.variation) return null;

                    const btnClass =
                      "tagBtn " +
                      (item.id == this.state.variation.id ? "is-active" : "");

                    return (
                      <li
                        key={index}
                        className={`${btnClass} ${
                          item.id == this.state.variation.id ? "active" : ""
                        }`}
                        onClick={this.selectVariation.bind(this, item)}
                      >
                        <a>
                          <span>
                            {I18n.get("Modelo")} {item.variationType}
                          </span>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="media-template-card-content">
              <p className="regular-control-text">{this.getDescription()}</p>

              {complexity && complexity.length > 0 && (
                <>
                  <p>
                    <span
                      style={{
                        padding: "0.3rem",
                        fontWeight: "500",
                        backgroundColor: this.getColor(
                          this.state.variation.variationType
                        ),
                      }}
                    >
                      {I18n.get("Complexidade")}
                    </span>
                    {this.getComplexityId() && (
                      <span className="projAssetBundleBullet">
                        {this.getComplexityId()}
                      </span>
                    )}
                    <span
                      className="regular-control-text"
                      style={{ fontWeight: "600" }}
                    >
                      {this.getComplexity()}
                    </span>
                  </p>

                  {this.getComplexityDescription() && (
                    <p className="regular-control-text">
                      {this.getComplexityDescription()}
                    </p>
                  )}
                </>
              )}
              <div className="boxItens">
                <h2>{I18n.get("Itens inclusos")}</h2>

                {this.props.projectStatus <= 5 && this.props.createCart && (
                  <div>
                    {!isEditing && (
                      <a
                        href=""
                        className="edit-right editCall"
                        onClick={this.onEditVariationClick.bind(this)}
                      >
                        <i className="far fa-edit"></i>
                        {I18n.get("Editar itens")}
                      </a>
                    )}

                    {isEditing && (
                      <a
                        href=""
                        className="edit-right editCall"
                        onClick={this.onCancelEditVariationClick.bind(this)}
                      >
                        <i className="far fa-edit"></i>
                        {I18n.get("Restaurar Pacote Original")}
                      </a>
                    )}
                  </div>
                )}

                <br />

                {!isEditing && (
                  <ul>
                    {cart2?.map((itemAsset, index) => {
                      // const asset = this.getAsset(sub.asset) || {};

                      var assetDescription = "";

                      if (itemAsset) {
                        assetDescription = itemAsset?.asset?.description || "";
                      }

                      if (itemAsset?.asset?.complexity) {
                        assetDescription =
                          itemAsset?.asset?.complexity +
                          " - " +
                          itemAsset?.asset?.complexDesc +
                          " - \r\n" +
                          assetDescription;
                      }

                      return (
                        <div>
                          <li key={index} className="regular-control-text">
                            <span className="labelSpan">
                              <strong>{itemAsset?.total} x </strong>{" "}
                              {itemAsset?.asset?.label}
                              {itemAsset?.asset?.complexityId ||
                                (itemAsset?.asset?.complexId && (
                                  <>
                                    <span className="complexBadge2">
                                      {itemAsset?.asset?.complexityId ||
                                        itemAsset?.asset?.complexId}
                                    </span>{" "}
                                    {itemAsset?.asset?.complexity ||
                                      itemAsset?.asset?.complex}
                                  </>
                                ))}
                              {this.state.isMobile ? (
                                <i
                                  onClick={() => this.onSelect(true)}
                                  className="fas fa-question-circle reset-after"
                                ></i>
                              ) : (
                                <TooltipField
                                  tooltip={assetDescription}
                                  orientation={"right"}
                                />
                              )}
                            </span>

                            <strong className="priceStrong">
                              {this.printMoney(
                                itemAsset.total * itemAsset?.asset?.price
                              )}
                            </strong>
                          </li>
                          <Modal
                            isOpen={this.state.selected}
                            closeModal={this.closeModal}
                          >
                            {itemAsset?.asset?.description}
                          </Modal>
                        </div>
                      );
                    })}
                  </ul>
                )}
                {isEditing && (
                  <div>
                    {" "}
                    <ul>
                      {this.props.cartAssets &&
                        this.props.cartAssets.map((asset, index) => {
                          return this.props.canExcludeAllAssets ||
                            asset.canBeExcluded ? (
                            <li key={index} className="regular-control-text">
                              {asset.label}{" "}
                              <span className="tag">
                                <BRL
                                  amount={asset.price}
                                  symbol={currency.symbolOnly}
                                  tSeparator={currency.thousandSeparator}
                                  dSeparator={currency.decimalSeparator}
                                />
                              </span>
                              <a onClick={() => this.onDeleteAsset(asset)}>
                                <i
                                  className="fas fa-times-circle"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                </i>
                              </a>
                            </li>
                          ) : (
                            <li key={index} className="regular-control-text">
                              {asset.label}{" "}
                              <span className="tag">
                                <BRL
                                  amount={asset.price}
                                  symbol={currency.symbolOnly}
                                  tSeparator={currency.thousandSeparator}
                                  dSeparator={currency.decimalSeparator}
                                />
                              </span>
                              <TooltipField
                                tooltip={"Item não excluível"}
                                canClose={true}
                                classfigure="fas fa-times-circle"
                                classfigurecolor="gray"
                                withoutquestionmark="false"
                                isfixed="false"
                              />
                            </li>
                          );
                        })}
                    </ul>
                    <div className="columns">
                      <div className="column is-10">
                        {I18n.get("Adicionar Itens:")}
                        <br />
                        <SelectSearchField
                          {...this.state.form.assetId}
                          values={this.props.variationAssets}
                        />
                      </div>

                      <div className="column is-2">
                        <br />
                        <div className="field is-narrow">
                          <label className="label is-small"></label>
                          <div className="control">
                            <button
                              onClick={this.onAddAsset.bind(this)}
                              className="button is-black is-rounded"
                            >
                              <span className="icon">
                                <i className="fas fa-plus fa-lg"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <br />

              {/* TODO */}

              {hasIsManHour && this.props.projectStatus != 7 && (
                <TextareaHourDescription
                  defaultValue={this.props?.descriptiveHourMan || ""}
                  getProject={this.handleUpdate}
                  isClient={this.props.IsClient}
                />
              )}

              {this.state.isExpanded && (
                <p>
                  <strong>{I18n.get("Importante:")}</strong>{" "}
                  {I18n.get(
                    "os prazos estabelecidos nos descritivos são máximos, visando melhor planejamento de recursos e manutenção de preço justo. Não existe um prazo mínimo para conclusão de um projeto, o cronograma é acordado entre as partes."
                  )}
                </p>
              )}

              <nav className="level level-number paddingButtonsCheckout">
                <div className="level-left">
                  <div className="level-item"></div>
                </div>

                <div className="level-right">
                  <div className="level-item">
                    {!this.state.isExpanded && (
                      <button
                        onClick={(e) => this.expand(e)}
                        type="button"
                        className="button bt-bpool black is-outlined"
                      >
                        {I18n.get("Detalhes")}
                      </button>
                    )}

                    {this.props.resetBundle && this.isSelected() && (
                      <button
                        onClick={(e) => this.props.resetBundle()}
                        type="button"
                        className="button bt-bpool grey"
                      >
                        {I18n.get("Remover")}
                      </button>
                    )}
                  </div>

                  <div className="level-item">
                    {/* Selecionado e não está editando */}
                    {this.props.selectBundle &&
                      this.isSelected() &&
                      !isEditing && (
                        <button
                          onClick={(e) =>
                            this.props.selectBundle(
                              this.state.variation.id,
                              this.props.bundleId
                            )
                          }
                          type="button"
                          className="button bt-bpool black"
                        >
                          {I18n.get("Selecionado")}
                        </button>
                      )}

                    {/* Selecionado e está editando */}
                    {this.props.checkoutVariation &&
                      this.isSelected() &&
                      isEditing && (
                        <button
                          onClick={this.props.checkoutVariation}
                          type="button"
                          className="button bt-bpool black"
                        >
                          {I18n.get("Salvar")}
                        </button>
                      )}

                    {/* Não Selecionado e não está editando */}
                    {this.props.selectBundle &&
                      !isEditing &&
                      this.state.isExpanded &&
                      !this.isSelected() && (
                        <button
                          onClick={(e) =>
                            this.props.selectBundle(
                              this.state.variation.id,
                              this.props.bundleId
                            )
                          }
                          type="button"
                          className="button bt-bpool black bt-ico"
                        >
                          {" "}
                          <img src={IcoCar} /> {I18n.get("Adicionar")}
                        </button>
                      )}

                    {/* Não Selecionado e está editando */}
                    {this.props.checkoutVariation &&
                      isEditing &&
                      this.state.isExpanded &&
                      !this.isSelected() && (
                        <button
                          onClick={this.props.checkoutVariation}
                          type="button"
                          className="button bt-bpool black bt-ico"
                        >
                          <img src={IcoCar} /> {I18n.get("Adicionar")}
                        </button>
                      )}
                  </div>
                </div>
              </nav>
            </div>

            <br />
          </div>
        </div>
        <div className="media-right" style={{ padding: "1rem" }}></div>
      </article>
    );
  }
}

export default AssetBundle;
