import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Modal, Checkbox, message } from "antd";
import { usePo } from "../../../../../hooks/usePo/usePo.hook";
import { useExtras } from "../../../../../hooks/useExtras/useExtras.hook";
import { useUpload } from "../../../../../hooks/useUpload/useUpload.hook";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import Content from "../../../../../components/UI/Content";
import Main from "../../../../../components/UI/Main";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import Loading from "../../../../../components/UI/Loading";
import { H2Border } from "../../../../../components/UI/Titles";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { TabsHorizontal } from "../../../../../components/UI/TabsHorizontal";
import { TabsProject } from "../../../../../components/UI/TabsProject";
import { Input } from "../../../../../components/UI/Input";
import { SelectOne } from "../../../../../components/UI/SelectOne";
import { InputMask } from "../../../../../components/UI/InputMask";
import { UploadInternal } from "../../../../../components/UI/UploadInternal";
import { PoBoxItem } from "./components/PoBoxItem";
import { Tabs } from "../../components/Tabs";
import { CurrencyInput } from "../../../../../components/UI/CurrencyInput";
import constants from "../../../../../../constants";
import { hasError, clearErrorInput } from '../../../../RegistrationFlow/utils/errorsInput';

import TabsItemsClient from "../../../urls/urlsClient.json";
import TabsItemsClientDefinition from "../../../urls/urlsClientDefinition.json";
import TabsItemsPartner from "../../../urls/urlsPartner.json";
import * as S from "./styles";

export const NewExtraPo = () => {
  let params = useParams();
  const state = useSelector((state) => state);
  const auth = useSelector((state) => state?.auth);
  const { getProjectById } = useProjects();
  const { getPo } = usePo();
  const { postPoExtra, getExtraById, getBudgetExtraById } = useExtras();
  const { uploadFile } = useUpload();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBillings, setIsLoadingBillings] = useState(false);
  const [listOfBillings, setListOfBillings] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [checkboxCompany, setCheckboxCompany] = useState(false);
  const [inputsForm, setInputsForm] = useState({});
  const [filePo, setFilePo] = useState({});
  const [isLoadingInput, setIsLoadingInput] = useState(false);
  const [addIsDisabled, setAddIsDisabled] = useState(true);
  const [infosPo, setInfosPo] = useState({});
  const [billingCurrency, setBillingCurrency] = useState({});
  const [loadSend, setLoadSend] = useState(false);
  const [project, setProject] = useState(null);
  const [paymentTermDays, setPaymentTermDays] = useState("");
  const [countryClient, setCountryClient] = useState("");
  const [errorsInputsForm, setErrorsInputsForm] = useState([]);

  const loadPos = async () => {
    // const response = await getPo({ id: params?.projectId });
    const response = await getBudgetExtraById({
      projectId: params?.projectId,
      extraId: params?.extraId,
      isClient: auth?.is_client_or_client_user ? true : false,
    });

    if (response?.statusCode === 200) {
      setInfosPo({
        billingTotalFmt: response?.data?.billingTotalFmt,
        billingPOBalance: response?.data?.billingPOBalance,
        billingPOBalanceFmt: response?.data?.billingPOBalanceFmt,
      });
      setListOfBillings(response?.data?.billings);

      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });
      if (responseProject?.success) {
        setProject(responseProject?.data);
        setPaymentTermDays(responseProject?.data?.paymentTermDays);
      }
    } else {
      message.error(I18n.get("Ops algo deu errado!"));
    }
    const responseDetails = await getExtraById({
      projectId: params?.projectId,
      extraId: params?.extraId,
    });
    if (responseDetails?.statusCode === 200) {
      setBillingCurrency(responseDetails?.data?.billingCurrency);
    } else {
      message.error(I18n.get("Ops algo deu errado!"));
    }
  };

  const loadInit = async () => {
    setIsLoadingBillings(true);
    await loadPos();
    setIsLoadingBillings(false);
  };

  const populateInfosClientToForm = () => {
    const clientInfos = state?.client?.company;
    setInputsForm({
      registrationName: clientInfos?.registrationName || "",
      registrationId: clientInfos?.registrationId || "",
      postcode: clientInfos?.postcode || "",
      addressLine1: clientInfos?.addressLine1 || "",
      addressLine2: clientInfos?.addressLine2 || "",
      city: clientInfos?.city || "",
      state: clientInfos?.state || "",
      total: "",
      code: "",
      projectPO: "",
      billingDate: null,
    });
    setFilePo({});
  };

  const handleAddPo = () => {
    if (modalAddVisible) {
      setModalAddVisible(false);
      populateInfosClientToForm()
      setIsLoadingInput(true);
    } else {
      setModalAddVisible(true);
      setIsLoadingInput(false);
    }
  };

  const handleCloseAddPo = () => {
    setModalAddVisible(false);
    populateInfosClientToForm();
    setFilePo({});
  };

  const sendApi = async () => {
    setIsSending(true);

    try {
      let fileUploaded = null;

      if (filePo?.name) {
        fileUploaded = await uploadFile({
          file: filePo,
          idUser: "usertestingnow",
          fieldId: `${auth?.is_client_or_client_user ? "cliente" : "parceiro"}-${params?.projectId
            }-po`,
        });
      }

      const data = {
        ...inputsForm,
        authorize: checkboxCompany,
        projectId: params?.projectId,
        extraId: params?.extraId,
        files: fileUploaded ? [fileUploaded] : null
      };

      await postPoExtra(data);
      handleAddPo();
      setFilePo({});
      message.success(I18n.get("PO successfully added!"));
      await loadPos();
    } catch (error) {
      const errors = [];
      Object.keys(error?.response?.data?.errors).forEach(function (key, index) {
        errors.push(error?.response?.data?.errors[key]);
      });
      setErrorsInputsForm(errors);
    }

    setIsSending(false);
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleClickSub = async (check) => {
    setCheckboxCompany(check);

    setInputsForm({ ...inputsForm, billingDate: null, orderNumber: "" });
  };

  useEffect(() => {
    loadInit();
  }, []);

  useEffect(() => {
    if (checkboxCompany) {
      if (
        inputsForm?.billingDate &&
        inputsForm?.total &&
        inputsForm?.code &&
        inputsForm?.registrationName &&
        inputsForm?.registrationId &&
        inputsForm?.postcode &&
        inputsForm?.addressLine1 &&
        inputsForm?.addressLine2 &&
        inputsForm?.city &&
        inputsForm?.state
      ) {
        setAddIsDisabled(false);
      } else {
        setAddIsDisabled(true);
      }
    } else {
      if (
        inputsForm?.projectPO &&
        inputsForm?.total &&
        inputsForm?.code &&
        inputsForm?.registrationName &&
        inputsForm?.registrationId &&
        inputsForm?.postcode &&
        inputsForm?.addressLine1 &&
        inputsForm?.addressLine2 &&
        inputsForm?.city &&
        inputsForm?.state
      ) {
        setAddIsDisabled(false);
      } else {
        setAddIsDisabled(true);
      }
    }
  }, [inputsForm, checkboxCompany]);

  let tabs =
    project?.status === 7 ? TabsItemsClient : TabsItemsClientDefinition;

  let tabActive = project?.status === 7 ? 5 : 4;

  let disableTabs = [];

  if (!project?.partnerHasSubmittedDefinitions) {
    disableTabs = [3, 4];
  } else if (project?.partnerHasSubmittedDefinitions && project?.status < 6) {
    disableTabs = [3];
  } else {
    disableTabs = [];
  }

  useEffect(() => {
    if (state?.auth?.is_client_or_client_user && !inputsForm?.registrationName) {
      populateInfosClientToForm();
      setCountryClient(state?.client?.country?.code);
    }
  }, [state]);

  return (
    <>
      <LoggedPage>
        <Content>
          <div className="container">
            <Row>
              <Col sm={12}>
                <H2Border>
                  {project?.status === 7
                    ? I18n.get("Projeto em andamento")
                    : I18n.get("Definições do Projeto")}
                </H2Border>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <TabsProject
                  context={project?.status === 6 ? "definitions" : project?.status === 7 ? "inProgress" : "finalized"}
                  page="extras"
                  externalLoading={isLoading}
                  projectStatus={project?.status}
                  partnerHasSubmittedDefinitions={project?.partnerHasSubmittedDefinitions}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <Main bgColor="#fff" padding="30px">
                  <Row>
                    <Col xs={24} sm={24}>
                      <S.RowCenter>
                        <S.TitleWithIcon>
                          <S.H4>{I18n.get("Extras")}</S.H4>
                        </S.TitleWithIcon>
                      </S.RowCenter>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24}>
                      <Tabs extraId={params?.extraId} />
                    </Col>
                  </Row>

                  {isLoading ? (
                    <Loading
                      text={I18n.get("Loading...")}
                      sizeText={14}
                      sizeIcon={16}
                      color="#000000"
                      align="left"
                    />
                  ) : (
                    <>
                      <Row>
                        <Col xs={16}>
                          <S.Content>
                            <S.TotalPo>
                              <p>{`${I18n.get("Total")}: ${infosPo?.billingTotalFmt}`}</p>
                              <p>{`${I18n.get("Balance")}: ${infosPo?.billingPOBalanceFmt}`}</p>
                            </S.TotalPo>
                            <S.Text>
                              <p>
                                {I18n.get(
                                  "Below are the #PO ​​registered for the project."
                                )}
                              </p>
                            </S.Text>
                          </S.Content>
                        </Col>
                        {auth?.is_client_or_client_user && infosPo?.billingPOBalance > 0 ? (
                          <Col xs={8}>
                            <S.Content>
                              <S.ButtonAddPo>
                                <ButtonBpool
                                  text={I18n.get("Add #PO")}
                                  theme="primary"
                                  onClick={handleAddPo}
                                />
                              </S.ButtonAddPo>
                            </S.Content>
                          </Col>
                        ) : null}
                      </Row>

                      <Row>
                        {isLoadingBillings ? (
                          <Loading
                            text={I18n.get("Loading...")}
                            sizeText={14}
                            sizeIcon={16}
                            color="#000000"
                            align="left"
                            style={{ marginTop: 30 }}
                          />
                        ) : (
                          <>
                            {listOfBillings?.length === 0 ? (
                              <Col xs={24}>
                                <S.BillingsEmpty>
                                  <p>{I18n.get("No #PO ​​registered yet.")}</p>
                                </S.BillingsEmpty>
                              </Col>
                            ) : (
                              listOfBillings?.map((item, index) => (
                                <Col xs={24} key={index}>
                                  <PoBoxItem
                                    data={item}
                                    currency={billingCurrency}
                                    refreshPos={loadInit}
                                  />
                                </Col>
                              ))
                            )}
                          </>
                        )}
                      </Row>
                    </>
                  )}
                </Main>
              </Col>
            </Row>
          </div>
        </Content>
      </LoggedPage>

      <Modal
        title={I18n.get("Add #PO")}
        centered
        visible={modalAddVisible}
        onOk={handleAddPo}
        onCancel={handleAddPo}
        width={1200}
        footer={[
          <S.ButtonsModalFooter>
            <ButtonBpool
              text={I18n.get("Add")}
              theme="primary"
              onClick={sendApi}
              loading={isSending}
              isDisabled={addIsDisabled}
            />
          </S.ButtonsModalFooter>
        ]}
      >
        <Row>
          <Col sm={24}>
            <S.SubTitle>
              <p>{I18n.get("Billing Data")}</p>
              <span />
            </S.SubTitle>
          </Col>
          <Col sm={24}>
            <S.ContentCheckboxCompany>
              <Checkbox
                checked={checkboxCompany}
                onChange={(e) => handleClickSub(e.target.checked)}
              />
              <span className="text">
                {I18n.get(
                  "My company does not require PO, I authorize billing for payment on the date and amount below."
                )}
              </span>
            </S.ContentCheckboxCompany>
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            {!checkboxCompany ? (
              <Input
                placeholder={I18n.get("Order number or purchase order")}
                required
                label
                value={inputsForm?.projectPO}
                onChange={(value) => onChangeInputForm("projectPO", value)}
                error={hasError({ arr: errorsInputsForm, field: "projectPO" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "projectPO",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />
            ) : (
              <InputMask
                id="billingDate"
                label={I18n.get("Data Pagamento")}
                defaultValue={inputsForm?.billingDate}
                onChange={(value) =>
                  onChangeInputForm("billingDate", value?.formattedValue)
                }
                mask="##/##/####"
                required
                error={hasError({ arr: errorsInputsForm, field: "billingDate" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "billingDate",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />
            )}
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <CurrencyInput
              placeholder={I18n.get("Value")}
              required
              value={inputsForm?.total}
              onChange={(value) => onChangeInputForm("total", value)}
              currency={billingCurrency}
              error={hasError({ arr: errorsInputsForm, field: "total" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "total",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Code")}
              label
              value={inputsForm?.code}
              onChange={(value) => onChangeInputForm("code", value)}
              error={hasError({ arr: errorsInputsForm, field: "code" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "code",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={24}>
            <UploadInternal
              multiple={false}
              cbFiles={(item) => setFilePo(item)}
              filesDefault={filePo}
            />
          </Col>
          <Col sm={24}>
            <S.PaymentosInstructions>
              <strong>{I18n.get("Condição Pagamento:")}</strong>{" "}
              {paymentTermDays + " "}
              {paymentTermDays ? I18n.get("dias corridos") : ""}
            </S.PaymentosInstructions>
          </Col>
          <Col sm={24}>
            <S.SubTitle>
              <p>{I18n.get("Issue Data")}</p>
              <span />
            </S.SubTitle>
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Razão social")}
              required
              label
              value={inputsForm?.registrationName}
              onChange={(value) => onChangeInputForm("registrationName", value)}
              error={hasError({ arr: errorsInputsForm, field: "registrationName" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "registrationName",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            {isLoadingInput ? (
              <div />
            ) : (
              <>
                {countryClient === "BRA" ? (
                  <InputMask
                    id="registrationId"
                    label={I18n.get("CNPJ")}
                    defaultValue={inputsForm?.registrationId}
                    onChange={(value) =>
                      onChangeInputForm("registrationId", value?.value)
                    }
                    mask="##.###.###/####-##"
                    required
                    error={hasError({ arr: errorsInputsForm, field: "registrationId" })}
                    clearError={() => {
                      const errorsArr = clearErrorInput({
                        field: "registrationId",
                        arr: errorsInputsForm,
                      });
                      setErrorsInputsForm(errorsArr);
                    }}
                  />
                ) : (
                  <Input
                    placeholder={I18n.get("Registration ID")}
                    required
                    label
                    value={inputsForm?.registrationId}
                    onChange={(value) => onChangeInputForm('registrationId', value)}
                    error={hasError({ arr: errorsInputsForm, field: "registrationId" })}
                    clearError={() => {
                      const errorsArr = clearErrorInput({
                        field: "registrationId",
                        arr: errorsInputsForm,
                      });
                      setErrorsInputsForm(errorsArr);
                    }}
                  />
                )}
              </>


            )}
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            {isLoadingInput ? (
              <div />
            ) : (
              <InputMask
                id="postcode"
                label={I18n.get("Codigo postal")}
                defaultValue={inputsForm?.postcode}
                onChange={(value) =>
                  onChangeInputForm("postcode", value?.value)
                }
                mask="##.###-###"
                required
                error={hasError({ arr: errorsInputsForm, field: "postcode" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "postcode",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />
            )}
          </Col>
          <Col sm={16} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Address")}
              required
              label
              value={inputsForm?.addressLine1}
              onChange={(value) => onChangeInputForm("addressLine1", value)}
              error={hasError({ arr: errorsInputsForm, field: "addressLine1" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "addressLine1",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Complement")}
              required
              label
              value={inputsForm?.addressLine2}
              onChange={(value) => onChangeInputForm("addressLine2", value)}
              error={hasError({ arr: errorsInputsForm, field: "addressLine2" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "addressLine2",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("City")}
              required
              label
              value={inputsForm?.city}
              onChange={(value) => onChangeInputForm("city", value)}
              error={hasError({ arr: errorsInputsForm, field: "city" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "city",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            {countryClient === "BRA" ? (
              <SelectOne
                options={constants.STATES}
                value={inputsForm?.state}
                defaultValue={inputsForm?.state}
                onChange={(value) => onChangeInputForm("state", value)}
                required
                placeholder={I18n.get("State")}
                error={hasError({ arr: errorsInputsForm, field: "state" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "state",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />
            ) : (
              <Input
                placeholder={I18n.get("State")}
                required
                label
                value={inputsForm?.state}
                onChange={(value) => onChangeInputForm("state", value)}
                error={hasError({ arr: errorsInputsForm, field: "state" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "state",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />)}
          </Col>
          <Col sm={8} style={{ height: "50px" }}>
            <S.Balance>{`Saldo total: ${infosPo?.billingPOBalanceFmt}`}</S.Balance>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
