import React from 'react';

import { I18n } from 'aws-amplify';

import SubmitButtonField from '../form/submit-button-field';


const QuestionItem = ({ question, partner, onDeleteQuestion, onOpenAnswer }) => {

    return (
        <div className="notification" style={{ backgroundColor: '#ffffff', fontSize: '90%', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)' }}>

            {onDeleteQuestion && <button className="delete" onClick={onDeleteQuestion} ></button>}

            <span>
                — {question.body}
            </span>

            {question.answer && <>

                <div style={{ padding: '1.15rem', opacity: '0.75' }}>
                    {question.answer}
                </div>

            </>}

            {!question.answer && <>

                <p style={{ marginTop: '1rem', fontSize: '75%', opacity: '0.5' }}>
                    {I18n.get("Enviado em")} {question.createdOn}
                    {partner && <span> {I18n.get("por")} {partner.name} </span>}
                </p>

            </>}


            {onOpenAnswer && <>

                <div style={{ marginTop: '1rem' }}>

                    <SubmitButtonField
                        label={I18n.get("Responder")}
                        className="button bt-bpool black"
                        onClick={onOpenAnswer}
                    />

                </div>



            </>}

        </div>

    )
}

export default QuestionItem;