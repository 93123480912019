import React from "react";
import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";

import DemandForm from "./demand-form";

import ProjectReview from "../shared/_project-review";

import SubHeader from "./__sub-header";

import { scrollToTop } from "../../utils/ui";

class NewProjectStep3 extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const logo =
      this.props.client &&
      this.props.client.company &&
      this.props.client.company.logo;

    const {
      isCreative,
      isEvent,
      isBPitch,
      customEvent = {},
      bPitch = {},
    } = this.props;

    const subHeaderProps = {
      isCreative,
      isEvent,
      isBPitch,
      customEvent,
      bPitch,
    };

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-3">
          <SubHeader
            projectId={this.props.match.params.projectId}
            active={3}
            logo={logo}
            {...subHeaderProps}
          />
        </div>
        <div className="client-step client-step-1 review-bg uplift">
          <div className="container client-container-1 uplift-step-3">
            <div className="columns">
              <div className="column is-4">
                <DemandForm {...this.props} />
                <br />
                <br />
                <div className="field">
                  <p
                    className="control is-expanded"
                    onClick={(e) => scrollToTop}
                  >
                    <Link
                      to={`/cliente/projetos/${this.props.match.params.projectId}/passo-2`}
                      className="button bt-bpool grey mright"
                    >
                      {I18n.get("Voltar")}
                    </Link>
                    <Link
                      to={`/cliente/projetos/${this.props.match.params.projectId}/passo-4`}
                      className="button bt-bpool pull-right"
                    >
                      {I18n.get("Próximo")}
                    </Link>
                  </p>
                </div>
                <br />
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 is-offset-1">
                <ProjectReview
                  {...this.props}
                  {...this.props.project}
                  step={3}
                />
              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(NewProjectStep3);
