import {
  COUNTER_INCREMENT,
  COUNTER_DECREMENT,
  COUNTER_RESET,
} from '../../actions';

const initialState = {
  counter: {
    number: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTER_INCREMENT:
      const newStateIncrement = { ...state, number: action.payload };
      return newStateIncrement;
    case COUNTER_DECREMENT:
      const newStateDecrement = { ...state, number: action.payload };
      return newStateDecrement;
    case COUNTER_RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
