import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import styled from 'styled-components';
import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';
import {
    Form,
    Button,
    Input,
    Row,
    Col
} from 'antd';

import ContainerPage from '../components/ContainerPage';
import { TitleBorder, TitleMiddleLine } from '../components/Titles';
import { CardBorder } from '../components/Cards'

import LandingPage from '../../../components/page-templates/landingForLogin';



const Main = styled.div`
  max-width: 820px;
  margin: 0 auto 100px;
  padding-top: 80px;
  width: 100%;
  box-sizing: border-box;
`;


const RowSubmit = styled(Row)`
  button {
      margin-right: 20px;
  }
`;

const mapStateToProps = state => {
    return state.partnerWhatIf;
};

const mapDispatchToProps = dispatch => {
    return {
        create: data => {
            dispatch(PARTNER_WHATIF_API.create(data));
        }
    };
};


class PartnerRegister extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            formState: {},
            task: {},
            sucesso: false
        };
    }


    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('As senhas não conferem!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['password2'], { force: true });
        }
        callback();
    };

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {


                const dataForm = {
                    ...values,
                }

                this.setState({
                    formState: values
                })
                this.props.create(dataForm);
            }
        });
    };

    componentDidUpdate(prevProps) {

        if (!prevProps) return;
        if (this.props.errors != prevProps.errors) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;



        if (this.props.task.result == true) {
            return (<Redirect to="/cadastro/parceiro-ideias/sucesso" />);
        }


        return (
            <LandingPage>
                <ContainerPage>
                    <Main>
                        <CardBorder
                            Bcolor="#fff"
                        >
                            <TitleBorder>Cadastre-se</TitleBorder>

                            <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu phare tra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                            <p><a href="#" title="Ajuda para obter um CNPJ>Preciso de um cnpj"></a></p>
                        </CardBorder>
                        <Form onSubmit={this.handleSubmit}>
                            <CardBorder
                                Bcolor="#bab1d1"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <TitleMiddleLine>Informações Gerais</TitleMiddleLine>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <Form.Item
                                            label="Nome Completo"
                                        >
                                            {getFieldDecorator('name', {
                                                rules: [
                                                    { required: false, message: 'O Nome é obrigatório' }
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>

                                    </Col>

                                    <Col sm={12}>
                                        <Form.Item label="E-mail">
                                            {getFieldDecorator('email', {
                                                rules: [{ required: false, message: 'O e-mail é obrigatório' }],
                                            })(
                                                <Input
                                                    placeholder="Ex.: nome@suaempresa.com.br"
                                                />
                                            )}
                                        </Form.Item>

                                    </Col>
                                    <Col sm={12}>
                                        <Form.Item label="Senha hasFeedback">
                                            {getFieldDecorator('password', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'A senha é obrigatória',
                                                    },
                                                    {
                                                        min: 6, message: 'Sua senha precisa ter no mínimo 6 caracteres'
                                                    },
                                                    {
                                                        validator: this.validateToNextPassword,
                                                    },
                                                ],
                                            })(<Input.Password />)}
                                        </Form.Item>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Item label="Confirmar senha hasFeedback">
                                            {getFieldDecorator('password2', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Confirmar a senha é obrigatório',
                                                    },
                                                    {
                                                        validator: this.compareToFirstPassword,
                                                    },
                                                ],
                                            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CardBorder>


                            <CardBorder>
                                <RowSubmit>
                                    <Col sm={24}>
                                        <Button type="primary" htmlType="submit" shape="round" size="large">Enviar</Button>
                                    </Col>
                                </RowSubmit>
                            </CardBorder>

                        </Form>
                    </Main>
                </ContainerPage>
            </LandingPage>
        );
    }
}

const PartnerRegisterForm = Form.create({})(PartnerRegister);

export default connect(mapStateToProps, mapDispatchToProps)(PartnerRegisterForm);
