import styled from "styled-components";

export const CreatProject = styled.div`
  background: #fff;
  height: 400px;
  border-radius: 10px;
  box-size: border-box;
  padding: 40px;
  width: 350px;
  position: relative;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: left;
`;

export const Close = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.4;
  }
`;

export const Buttons = styled.div`
  margin-top: 30px;
  a,
  button {
    width: 100%;
    margin-bottom: 15px;
  }
`;
