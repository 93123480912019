import { OPEN_CHAT_MODAL, CLOSE_CHAT_MODAL } from '../../actions';

const initialState = {
  chatModal: {
    open: false,
    topic: '',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CHAT_MODAL:
      const newStateOpenChatModal = {
        ...state,
        open: true,
        topic: action.payload,
      };
      return newStateOpenChatModal;
    case CLOSE_CHAT_MODAL:
      const newStateCloseChatModal = {
        ...state,
        open: false,
        topic: '',
      };
      return newStateCloseChatModal;
    default:
      return state;
  }
};

export default reducer;
