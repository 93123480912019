import React from 'react';

import { I18n } from 'aws-amplify';

export default class I18nExample extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    onChangeLocale(e){

        const value = e.target.value;

        I18n.setLanguage(value);

        this.setState({ locale: value }); // garante o updade da pagina para pegar o novo idioma
    }

    render(){

        return (
            <div style={{ padding: '5rem' }}>

                <form>

                <select onChange={this.onChangeLocale.bind(this)}>
                    <option>en</option>
                    <option>pt</option>
                    <option>es</option>
                </select>


                <span style={{ marginLeft: '1rem' }}>{I18n.get('Name')}</span>

                </form>

            </div>
        );
    }

}