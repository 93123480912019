import React from 'react';

import { I18n } from 'aws-amplify';

import MarkAsRequiredOrOptional from './mark-as-required-or-optional';

export default class TextAreaField extends React.Component {

    constructor(props) {
        super();

        this.state = {
            focused: false,
            visited: props.visited, //visited: false 
            len: (props.value && props.value.length) || 0
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevProps) return;

        if (this.props.value != prevProps.value) {
            this.setState({ len: this.props.value && this.props.value.length });
        }
    }

    onBlur() {
        this.setState({ focused: false });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onFocus() {
        this.setState({ focused: true, visited: true });
    }

    getMinLengthMessage() {

        if (!this.props.minLength) return '';

        if (this.props.maxLength && this.props.maxLength > 0) {
            return `${this.state.len}/${this.props.maxLength}`;
        } else {
            return `${this.state.len}`;
        }

        //if (this.state.len > this.props.minLength) {
        //    return this.state.len;
        //}
        //else {
        //    return `${this.state.len}/${this.props.minLength}`;
        //}

    }

    render() {

        const isError = !this.state.focused && this.state.visited && this.props.error;

        return (
            <div className="field">
                <label className="label is-small">
                    {this.props.label} <MarkAsRequiredOrOptional {...this.props} />
                    {this.props.labels && this.props.labels[this.props.labelKey]}
                </label>
                <div className="control">
                    <textarea
                        onChange={this.props.onChange}
                        onBlur={() => this.onBlur()}
                        onFocus={() => this.onFocus()}
                        value={this.props.value || ''}
                        className={isError ? "textarea is-danger" : "textarea"}
                        placeholder={this.props.placeholder || ''}
                        readOnly={this.props.readOnly || false}
                        disabled={this.props.disabled || false}
                        maxLength={this.props.maxlength}
                    />
                    {this.props.minLength && <div>
                        <span className="has-text-dark-grey is-pulled-left" style={{ fontSize: '80%' }}>{I18n.get("mínimo")} {this.props.minLength}
                        </span>

                        <span className="has-text-dark-grey is-pulled-right" style={{ fontSize: '80%' }}>
                            {this.getMinLengthMessage()}
                        </span>
                    </div>
                    }
                </div>
                {isError && <p className="help is-danger">
                    &nbsp; {this.props.error.errorMessage}
                </p>}
            </div>
        );
    }
}
