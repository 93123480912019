import React, { useState } from "react";
// import moment from "moment";

import { I18n } from "aws-amplify";
import Pix from "../../../image/pix.png";

import { Task } from "../../../utils/task";

import FormatCurrency from "../../ui/format-currency";

import { Popconfirm, notification, Icon } from "antd";

import { ItemBillingInstallmentInvoiceAlert } from "../../../modules/Admin/components/itemBillingInstallment";

import { ClientDueDateForm } from "./_invoice_clientduedate_form";
import { PaymentDateForm } from "./_invoice_paymentdate_form";

export default class BillingInstallments extends React.Component {
  constructor(props) {
    super();

    this.state = {
      clientDueDateEditing: 0,
      paymentDateEditing: 0,
    };

    this.select = this.onSelect.bind(this);
  }

  componentDidMount() {}

  onSelect(item, e) {
    this.setState({ selected: item.id });
  }

  deleteInstallment = (data) => {
    var task = new Task();

    task.start();

    this.props
      .delete_installment(data)
      .then((response) => {
        notification.success({
          message: I18n.get("Atualizado com sucesso!"),
        });
      })
      .catch((error) => {
        if (Object.keys(error?.response?.data).length > 0) {
          Object.keys(error?.response?.data).map((item) => {
            notification.error({
              message: I18n.get("Ops! Algo deu errado."),
              description: error?.response?.data[item].errorMessage,
            });
          });
        }
      });
  };

  deleteInvoice = (data) => {
    var task = new Task();

    task.start();

    this.props
      .delete_invoice(data)
      .then((response) => {
        notification.success({
          message: I18n.get("Atualizado com sucesso!"),
        });
      })
      .catch((error) => {
        if (Object.keys(error?.response?.data).length > 0) {
          Object.keys(error?.response?.data).map((item) => {
            notification.error({
              message: I18n.get("Ops! Algo deu errado."),
              description: error?.response?.data[item].errorMessage,
            });
          });
        }
      });
  };

  render() {
    const { projectId, extraId, billingId } = this.props.match.params;
    const { billing = {}, currency = {} } = this.props;

    return (
      <div>
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth hasTableCounter">
          <thead>
            <tr>
              <th>{I18n.get("Parcela")}</th>
              <th>{I18n.get("Data Liberação")}</th>
              <th>%</th>
              <th>{I18n.get("Valor")}</th>
              <th>{I18n.get("Nota Fiscal e Pagamento")}</th>
            </tr>
          </thead>
          <tbody>
            {billing.installments &&
              billing.installments.map((item, index) => {
                let data = {
                  projectId: projectId,
                  extraId: extraId,
                  billingId: billingId,
                  installmentId: item.id,
                };

                return (
                  <tr key={index}>
                    <td onClick={this.select.bind(this, item)}>
                      <Popconfirm
                        title={I18n.get(
                          "Deseja realmente excluir esse REPASSE bem como todas as notas debaixo dele?"
                        )}
                        onConfirm={() => this.deleteInstallment(data)}
                        okText={I18n.get("SIM")}
                        cancelText={I18n.get("NÃO")}
                        placement="right"
                      >
                        <a className="info-invite-icon">
                          <Icon type="delete" theme="filled" />
                        </a>
                      </Popconfirm>
                    </td>
                    <td onClick={this.select.bind(this, item)}>
                      {item.createdOn}
                    </td>
                    <td
                      onClick={this.select.bind(this, item)}
                      className="has-text-right"
                    >
                      {item.amountPercFmt}
                    </td>
                    <td
                      onClick={this.select.bind(this, item)}
                      className="has-text-right"
                    >
                      <FormatCurrency
                        amount={item.amount}
                        currency={currency}
                      />
                    </td>
                    <td
                      onClick={this.select.bind(this, item)}
                      className="has-text-left"
                    >
                      {item.invoices &&
                        item.invoices.map((invoice) => {
                          let dataInvoice = {
                            projectId: projectId,
                            extraId: extraId,
                            billingId: billingId,
                            installmentId: item.id,
                            invoiceId: invoice.id,
                          };

                          return (
                            <div
                              style={{
                                marginBottom: "0.5rem",
                                fontSize: "90%",
                              }}
                            >
                              <div className="columns">
                                <div className="column is-1">
                                  <div
                                    title={invoice.file.name}
                                    style={{
                                      width: "5rem",
                                      display: "inline-block",
                                    }}
                                  >
                                    <a
                                      href={invoice.file.url}
                                      target="_blank"
                                      style={{ color: "#ccc" }}
                                    >
                                      <span
                                        className="icon is-large"
                                        style={{ marginTop: "0.2rem" }}
                                      >
                                        <i
                                          className="fas fa-file-invoice fa-3x"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </a>
                                  </div>
                                </div>

                                <div className="column is-4">
                                  <div style={{ lineHeight: "1.5rem" }}>
                                    <strong>{I18n.get("NF:")}</strong>{" "}
                                    {invoice.invoiceNumber} <br />
                                    <strong>{I18n.get("Valor:")}</strong>{" "}
                                    <FormatCurrency
                                      amount={invoice.invoiceAmount}
                                      currency={currency}
                                    />{" "}
                                    <br />
                                    <strong>
                                      {I18n.get("Retenção IR:")}
                                    </strong>{" "}
                                    {invoice.tax}% <br />
                                    <div style={{ martinTop: "0.5rem" }}>
                                      <strong>{I18n.get("Vencimento")}:</strong>

                                      {this.state.clientDueDateEditing !=
                                        invoice.id && (
                                        <>
                                          &nbsp;
                                          <span>
                                            {invoice.clientDueDate}{" "}
                                          </span>{" "}
                                          <a
                                            onClick={() =>
                                              this.setState({
                                                clientDueDateEditing:
                                                  invoice.id,
                                              })
                                            }
                                          >
                                            <Icon type="edit" />
                                          </a>
                                        </>
                                      )}
                                      {this.state.clientDueDateEditing ==
                                        invoice.id && (
                                        <>
                                          <ClientDueDateForm
                                            {...this.props}
                                            invoice={{ ...data, ...invoice }}
                                            onDismiss={(e) =>
                                              this.setState({
                                                clientDueDateEditing: 0,
                                              })
                                            }
                                          />
                                        </>
                                      )}

                                      <br />

                                      <strong>{I18n.get("Pagamento")}:</strong>

                                      {this.state.paymentDateEditing !=
                                        invoice.id && (
                                        <>
                                          &nbsp;
                                          <span>
                                            {invoice.paymentDueDate}{" "}
                                            <ItemBillingInstallmentInvoiceAlert
                                              invoice={invoice}
                                            />
                                          </span>{" "}
                                          <a
                                            onClick={() =>
                                              this.setState({
                                                paymentDateEditing: invoice.id,
                                              })
                                            }
                                          >
                                            <Icon type="edit" />
                                          </a>
                                        </>
                                      )}
                                      {this.state.paymentDateEditing ==
                                        invoice.id && (
                                        <>
                                          <PaymentDateForm
                                            {...this.props}
                                            invoice={{ ...data, ...invoice }}
                                            onDismiss={(e) =>
                                              this.setState({
                                                paymentDateEditing: 0,
                                              })
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  <div className="modDoc">
                                    {invoice?.paymentBeneficiary?.slip &&
                                      invoice?.paymentBeneficiary?.slip
                                        ?.url && (
                                        <p>
                                          <a
                                            href={
                                              invoice?.paymentBeneficiary?.slip
                                                ?.url
                                            }
                                            download
                                          >
                                            <i
                                              className="fas fa-file-invoice"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            Boleto
                                          </a>
                                        </p>
                                      )}

                                    {invoice?.paymentBeneficiary?.pix && (
                                      <p>
                                        <img src={Pix} width="30px" />{" "}
                                        {invoice?.paymentBeneficiary?.pix}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="column is-7">
                                  <Popconfirm
                                    title={I18n.get(
                                      "Deseja realmente excluir esse Invoice?"
                                    )}
                                    onConfirm={() =>
                                      this.deleteInvoice(dataInvoice)
                                    }
                                    okText={I18n.get("SIM")}
                                    cancelText={I18n.get("NÃO")}
                                    placement="right"
                                  >
                                    <a className="info-invite-icon pull-right">
                                      <Icon type="delete" theme="filled" />
                                    </a>
                                  </Popconfirm>

                                  <div style={{ lineHeight: "1.5rem" }}>
                                    {invoice?.paymentBeneficiary
                                      ?.isPaymentMethodTransferSWIFTorABA && (
                                      <>
                                        <br />
                                        <strong>
                                          {I18n.get("Intermediário")}
                                        </strong>
                                        <br />
                                        <strong>
                                          {I18n.get(
                                            "Dirección de Intermediário"
                                          )}
                                          :{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.intermediateDirectionBeneficiary
                                        }
                                        <br />
                                        <strong>
                                          {I18n.get("Número de cuenta")}:{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.intermediateAccountNumber
                                        }
                                        <br />
                                        <strong>
                                          {I18n.get("Clabe interbancaria")}:{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.intermediateInterbankKey
                                        }
                                        <br />
                                        <strong>
                                          SWIFT / ABA / BIC / IBAN :{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.intermediateSWIFTABABICIBAN
                                        }
                                        <br />
                                        <strong>
                                          {I18n.get("Banco")}:{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.intermediateBank
                                        }
                                        <br />
                                        <strong>
                                          {I18n.get("Dirección del Banco")}:{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.intermediateBankDirection
                                        }
                                        <br />
                                        <br />
                                        <strong>
                                          {I18n.get("Beneficiário")}
                                        </strong>
                                        <br />
                                        <strong>
                                          {I18n.get(
                                            "Dirección de Intermediário"
                                          )}
                                          :{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.beneficiaryDirectionBeneficiary
                                        }
                                        <br />
                                        <strong>
                                          {I18n.get("Número de cuenta")}:{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.beneficiaryAccountNumber
                                        }
                                        <br />
                                        <strong>
                                          {I18n.get("Clabe interbancaria")}:{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.beneficiaryInterbankKey
                                        }
                                        <br />
                                        <strong>
                                          SWIFT / ABA / BIC / IBAN :{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.beneficiarySWIFTABABICIBAN
                                        }
                                        <br />
                                        <strong>
                                          {I18n.get("Banco")}:{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.beneficiaryBank
                                        }
                                        <br />
                                        <strong>
                                          {I18n.get("Dirección del Banco")}:{" "}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.beneficiaryBankDirection
                                        }
                                        <br />
                                      </>
                                    )}
                                    {!invoice.isPaymentMethodTransferSWIFTorABA && (
                                      <>
                                        <strong>
                                          {I18n.get("Favorecido:")}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.beneficiaryName
                                        }{" "}
                                        <br />
                                        <strong>
                                          {I18n.get("CNPJ:")}
                                        </strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.beneficiaryId
                                        }{" "}
                                        <br />
                                        <strong>
                                          {I18n.get("Banco:")}
                                        </strong>{" "}
                                        {invoice?.paymentBeneficiary?.bankId}{" "}
                                        <strong>{I18n.get("Agência:")}</strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.bankBranch
                                        }{" "}
                                        <strong>{I18n.get("Conta:")}</strong>{" "}
                                        {
                                          invoice?.paymentBeneficiary
                                            ?.bankAccount
                                        }{" "}
                                        <br />
                                      </>
                                    )}
                                  </div>

                                  {this.props.isEvent && (
                                    <div
                                      style={{
                                        lineHeight: "1.5rem",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <strong>{I18n.get("Descrição:")}</strong>{" "}
                                      {invoice.description} <br />
                                      <strong>
                                        {I18n.get("Segmento:")}
                                      </strong>{" "}
                                      {invoice.segment} <br />
                                      <strong>
                                        {I18n.get("Categoria:")}
                                      </strong>{" "}
                                      {invoice.category} <br />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {billing.installments && billing.installments.length == 0 && (
          <p>{I18n.get("Nenhuma parcela liberada.")}</p>
        )}
      </div>
    );
  }
}
