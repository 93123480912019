import styled from "styled-components";
import { Icon } from "antd";

export const Wrapper = styled.div``;

export const Title = styled.div`
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
`;

export const BoxInfo = styled.div`
  padding-bottom: ${(props) => (props.noBorder ? null : "10px;")};
  border: none;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 30px;
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    color: #000000;
  }

  button {
    border: none;
    background: transparent;
    color: #1883ff;
    cursor: pointer;
  }
`;

export const Info = styled.div`
  strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #000000;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #000000;
    padding-left: 5px;
  }
`;

export const StyledIcon = styled(Icon)`
  font-size: ${(props) => (props.sizeIcon ? `${props.sizeIcon}px` : `40px`)};
  color: ${(props) => (props.color ? props.color : "#000000")};
`;
