import React from 'react';

import { I18n } from 'aws-amplify';

const Card = (props) => {

    if (!props.item) return null;

    const item = props.item;

    return (
        <div className="card">
            <div className="card-content">
                <h3 className="title is-4">{I18n.get("Responsável")}</h3>

                <div className="content">

                    <table className="table is-narrow">
                        <tbody>
                            <tr>
                                <th>{I18n.get("Nome")}</th>
                                <th>{I18n.get("E-mail")}</th>
                                <th>{I18n.get("Celular")}</th>
                                <th>{I18n.get("Data cadastro")}</th>
                                <th>{I18n.get("Website & Portfolio")}</th>
                            </tr>
                                <tr>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                        {item.phoneNumber}
                                    </td>
                                    <td>
                                        {item.createdOnFmt}
                                    </td>
                                    <td>
                                    {item.portfolioURLs && item.portfolioURLs.map((item, index) =>
                                        (<a key={index} href={item} target="_blank">{item}</a>)
                                    )}
                                    </td>
                                </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
};

export default Card;