import React from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';

import NumberFormat from 'react-number-format';

import constants from '../../constants';


class ReviewStep2 extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    getProductName() {

        var name = '';

        if (!this.props.client.brands) return name;

        this.props.client.brands.forEach((brand) => {

            brand.products.forEach((product) => {

                if (product.id == this.props.productId) {
                    name = `${brand.name} - ${product.name}`;
                }
            });

        });

        return name;
    }

    getBundle() {

        return this.props.bundle || {};
    }

    getVariation() {

        const bundle = this.getBundle();

        const item = bundle.variations && bundle.variations.filter((item) => item.id == this.props.variationId)[0];

        return item || {};

    }

    getSpecialim(code) {

        if (!code) return {};
        if (!this.props.bp.assetSpecialisms) return {};

        const item = this.props.bp.assetSpecialisms.filter((s) => s.code == code);

        if (item && item.length) {
            return item[0];
        }
        else {
            return {};
        }
    }

    getAssetType(code) {

        if (!code) return {};
        if (!this.props.bp.assetTypes) return {};

        const item = this.props.bp.assetTypes.filter((t) => t.code == code);

        if (item && item.length) {
            return item[0];
        }
        else {
            return {};
        }
    }

    render() {

        const bundle = this.getBundle();
        const variation = this.getVariation();

        const assetSpecialisms = bundle.specialism && bundle.specialism.map((item) => this.getSpecialim(item).label);
        const assetTypes = bundle.assetType && bundle.assetType.map((item) => this.getAssetType(item).label);

        return (
            <div>

                <h1 className="title-bpool medium">
                    <span>2.</span>{I18n.get("Escopo Criativo")}</h1>

                <div className="card reviewed-card">
                    <div className="card-content">

                        <h1 className="title-bpool medium">
                            {bundle.title}
                        </h1>
                        <span style={{ backgroundColor: '#F0E2A1' }} className="card-model">{I18n.get("Modelo")} {variation.variationType}</span>

                        <p>
                            <strong>{I18n.get("Especialidade:")}</strong> {assetSpecialisms && assetSpecialisms.join(', ')}
                        </p>
                        <p>
                            <strong>{I18n.get("Tipo:")}</strong> {assetTypes && assetTypes.join(', ')}
                        </p>

                        <br /><br />

                        <h5 className="title-bpool medium" style={{ color: '#2F7709' }}>
                            <NumberFormat value={variation.priceWithDiscount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} />
                        </h5>
                    </div>
                </div>

                {this.props.enableLinkButton && <>

                    <Link to={`/cliente/projetos/${this.props.match.params.projectId}/passo-2`} className="button is-link-to-return">{I18n.get("Redefinir")}</Link>

                </>}

            </div>
        );
    }
}

export default ReviewStep2;