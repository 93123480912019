export const sendErrors = async ({ errors }) => {
  let errorMessage = "";

  if (Object.values(errors)?.length > 1) {
    const errorMessages = [];
    for (const key in errors) {
      const errorMessage = errors[key].errorMessage;
      errorMessages.push(errorMessage);
    }
    const errorMessageString = errorMessages.map((message, index) => {
      const errorNumber = index + 1;
      return `Erro ${errorNumber}: ${message}`;
    }).join(", ");

    errorMessage = errorMessageString
  } else {
    errorMessage = Object.values(errors)[0].errorMessage;
  }

  let errorsToForm = {name: "", email: "", userProfiles: ""};
  Object.keys(errors).find((key) => {
    if (key.includes("Email")) {
      errorsToForm = {...errorsToForm, email: errors[key].errorMessage}
    }
    if (key.includes("Name")) {
      errorsToForm = {...errorsToForm, name: errors[key].errorMessage}
    }
  });

  return { errorMessage, errorsToForm }
};