import React from 'react';

import { I18n } from 'aws-amplify';

import Modal from '../ui/modal';

import Details from './_common-cases-details';

class Cases extends React.Component {

    constructor(props) {

        super();

        this.state = {
            selected: null,
        };

        this.select = this.onSelect.bind(this);
        this.closeModal = this.onCloseModal.bind(this);
    }

    onSelect(item, e) {
        this.setState({ selected: item });
    }

    onCloseModal(e) {
        e.preventDefault();

        this.setState({ selected: null });
    }

    getType(value) {
        if (!this.props.bp || !this.props.bp.caseTypesMap) return '';

        return this.props.bp.caseTypesMap[value].label;
    }

    getSector(sectorId) {

        const items = this.props.sf.filter((item) => item.code == sectorId);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }

    render() {

        const items = this.props.items;
        const sf = this.props.sf;

        if (!items) return null;

        return (
            <section>

                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Cases")}</h3>

                        <div className="content">

                            <table className="table is-hoverable is-fullwidth is-narrow">
                                <tbody>
                                    <tr>
                                        <th>{I18n.get("Tipo")}</th>
                                        <th>{I18n.get("Marca")}</th>
                                        <th>{I18n.get("Produto")}</th>
                                        <th>{I18n.get("Categoria")}</th>
                                        <th>{I18n.get("Ano")}</th>
                                        <th></th>
                                    </tr>
                                    {items && items.map((item, index) =>
                                        (<tr key={index} onClick={this.select.bind(this, item)} className={this.state.selected && this.state.selected.id == item.id ? 'bp-is-selected' : ''}>
                                            <td>
                                                {item.typeName}
                                            </td>
                                            <td>
                                                {item.brand}
                                            </td>
                                            <td>
                                                {item.product}
                                            </td>
                                            <td>
                                                {this.getSector(item.category).label}
                                            </td>
                                            <td>
                                                {item.year}
                                            </td>
                                            <td className="has-text-centered">
                                                <i className="fas fa-eye"></i>
                                            </td>
                                        </tr>)
                                    )}
                                </tbody>
                            </table>

                            {this.state.selected && <div>

                                <button onClick={this.closeModal} className="delete is-pulled-right" aria-label="close"></button>

                                <h3 className="title is-6">{I18n.get("Detalhes do case")}</h3>

                                <Details item={this.state.selected} sf={sf} />
                            </div>}

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cases;