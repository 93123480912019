import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";

import LoggedPage from "../../components/page-templates/fillForm-logged";
import { Link } from "react-router-dom";

import ContainerPartner from "./components/container-client";

import constants from "../../constants";
import SubMenuHeader from "../components/SubHeaderMenu";

import { PARTNER_API } from "../../actions/partner/actions";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_partner: (id) => {
      dispatch(PARTNER_API.get());
    },
  };
};

class PartnerReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.props.get_partner();
  }

  render() {
    // if (!this.props.partner.partnerId && this.props.auth.logged_user) {
    //   return null;
    // }

    //TODO: Find better way... GAMBI!
    let menu = [...constants.MENU_REPORT_PARTNER];
    for (let key in menu) {
      menu[key].label = I18n.get(menu[key].label);
    }

    return (
      <LoggedPage isReport>
        <div
          className="headerX has-background-white project-definition-header"
          style={{ padding: "0 !important" }}
        >
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-6">
                <p className="title">
                  <Link style={{ color: "black" }} to="/cliente/dashboard">
                    {I18n.get("Dashboard")}
                  </Link>
                  <br />
                </p>
              </div>
              <div className="column is-6">
                <SubMenuHeader itens={menu} activeItem={2} />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard2">
          <div
            className="container client-list-container"
            style={{ paddingTop: "160px" }}
          >
            <ContainerPartner
              {...this.props}
              prefix="partner"
              idGet={this.props.partner.partnerId}
            />
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerReport);
