import React, { useState, useEffect } from "react";
import { Icon } from "antd";
import { I18n } from "aws-amplify";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useExtras } from "../../../../../../hooks/useExtras/useExtras.hook";
import Loading from "../../../../../../components/UI/Loading";
import { FormatCurrency } from "../../../../../../components/UI/FormatCurrency";
import { ToggleCheckbox } from "../../../../../../components/UI/ToggleCheckbox";

import * as S from "./styles";

export const TableProposalGeneralCosts = ({ loading, reload, data, currency }) => {
  const auth = useSelector((state) => state.auth);
  const { putPercentsGeneralCosts } = useExtras()
  let params = useParams();
  const [inputs, setInputs] = useState({ fee: null, taxes: null });
  const [isSending, setIsSending] = useState(false);
  const [togglesTaxes, setTogglesTaxes] = useState({
    includeTaxOnIntensFree: false,
    includeTaxOnFee: false,
  });

  useEffect(() => {
    setInputs({
      fee: data?.fee?.percentage,
      taxes: data?.tax?.percentage,
    });

    setTogglesTaxes({
      includeTaxOnIntensFree: data?.includeTaxOnIntensFree === null ? false : data?.includeTaxOnIntensFree,
      includeTaxOnFee: data?.includeTaxOnFee === null ? false : data?.includeTaxOnFee,
    });
  }, [data]);

  const handleChangeInput = ({ id, value }) => {
    setInputs({
      ...inputs,
      [id]: value ? value : null,
    });
  };

  const send = async ({ includeTaxOnFeeInternal, includeTaxOnItensFreeInternal }) => {
    setIsSending(true);
    try {
      const obj = {
        projectId: params?.projectId,
        extraId: params?.extraId,
        feePercentage: inputs?.fee ? Number(inputs?.fee) : 0,
        taxPercentage: inputs?.taxes ? Number(inputs?.taxes) : 0,
        includeTaxOnFee: includeTaxOnFeeInternal !== undefined ? includeTaxOnFeeInternal : togglesTaxes?.includeTaxOnFee,
        includeTaxOnItensFree: includeTaxOnItensFreeInternal !== undefined ? includeTaxOnItensFreeInternal : togglesTaxes?.includeTaxOnIntensFree,
      };
      await putPercentsGeneralCosts({ dataForm: obj });
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsSending(false);
      await reload();
    }

  };

  const renderTotal = () => {
    return data?.totalExcludingBPCommissionFmt;
    // if (isFee && isTaxes) {
    //   return <FormatCurrency currency={currency} amount={data?.total} />;
    // }
    // if (!isFee && isTaxes) {
    //   return <FormatCurrency currency={currency} amount={data?.totalExcludingBPCommission} />;
    // }
    // if (isFee && !isTaxes) {
    //   return <FormatCurrency currency={currency} amount={data?.itemsTotal + data?.fee?.amount} />;
    // }
    // if (!isFee && !isTaxes) {
    //   return <FormatCurrency currency={currency} amount={data?.itemsTotal} />;
    // }
  };

  const onChangeTogglesForms = async ({ id, value }) => {
    setTogglesTaxes({
      ...togglesTaxes,
      [id]: value,
    });

    await send({
      includeTaxOnFeeInternal: id === "includeTaxOnFee" ? value : undefined,
      includeTaxOnItensFreeInternal: id === "includeTaxOnIntensFree" ? value : undefined,
    })
  };

  return (
    <>
      <S.TableContainer>
        <S.TableHeader>
          <S.TableHeadItem style={{ paddingLeft: 5 }}>{I18n.get("General Costs")}</S.TableHeadItem>
          <S.TableHeadItem>%</S.TableHeadItem>
          <S.TableHeadItem>{I18n.get("Valor")}</S.TableHeadItem>
        </S.TableHeader>
        <S.TableContent>
          <S.TableRow>
            <S.TableItem>{I18n.get("Internal Costs")}</S.TableItem>
            <S.TableItem>{data?.bpCommission?.percentageFmt}</S.TableItem>
            <S.TableItem>
              <FormatCurrency currency={currency} amount={data?.internalItemsSubTotal} />
            </S.TableItem>
          </S.TableRow>
          <S.TableRow>
            <S.TableItem>{I18n.get("Third Costs")}</S.TableItem>
            <S.TableItem>{data?.bpCommission?.percentageFmt}</S.TableItem>
            <S.TableItem />
          </S.TableRow>
          <S.TableRow>
            <S.TableItem subItem>
              <S.CircleIco />
              <span>{I18n.get("Items with double taxation")}</span>
            </S.TableItem>
            <S.TableItem />
            <S.TableItem>
              <FormatCurrency currency={currency} amount={data?.externalItemsWithDoubleTaxTotal} />
            </S.TableItem>
          </S.TableRow>
          <S.TableRow>
            <S.TableItem subItem>
              <S.CircleIco />
              <span>{I18n.get("Items without double taxation")}</span>
            </S.TableItem>
            <S.TableItem />
            <S.TableItem>
              <FormatCurrency currency={currency} amount={data?.externalItemsWithoutDoubleTaxSubTotal} />
            </S.TableItem>
          </S.TableRow>
        </S.TableContent>
        <S.TableFooter>
          {auth?.is_client_or_client_user ? null : (
            <S.TableRow>
              <S.TableItem>
                <strong>{I18n.get("Fee Comission")}</strong>
              </S.TableItem>
              <S.TableItem>
                <S.InputPercent>
                  <input type="number" value={inputs.fee} onChange={(e) => handleChangeInput({ id: "fee", value: e?.target?.value })} />
                  <S.PercentAddon>%</S.PercentAddon>
                </S.InputPercent>
                <button onClick={send} disabled={isSending}>
                  {isSending ? (
                    <Icon type="loading" />
                  ) : (
                    <Icon type="reload" />
                  )}
                </button>
              </S.TableItem>
              <S.TableItem>
                <strong>
                  <FormatCurrency currency={currency} amount={data?.fee?.amount} />
                </strong>
              </S.TableItem>
            </S.TableRow>
          )}
          {auth?.is_client_or_client_user ? null : (
            <S.TableRow>
              <S.TableItem style={{ height: "auto", flexDirection: "column", alignItems: "flex-start" }}>
                <strong>{I18n.get("Taxes")}</strong>
                <S.TextSmall style={{ margin: "10px 0 0 0" }}>
                  {I18n.get("Consult your contract and verify that the agreed prices for interior items are gross or net.")}
                </S.TextSmall>
              </S.TableItem>
              <S.TableItem>
                <S.InputPercent>
                  <input type="number" value={inputs.taxes} onChange={(e) => handleChangeInput({ id: "taxes", value: e?.target?.value })} />
                  <S.PercentAddon>%</S.PercentAddon>
                </S.InputPercent>
              </S.TableItem>
              <S.TableItem>
                <strong>
                  <FormatCurrency currency={currency} amount={data?.tax?.amount} />
                </strong>
              </S.TableItem>
              <S.TogglesTaxes>
                <S.ToggleContent>
                  <S.ToggleCheckboxDiv>
                    <ToggleCheckbox
                      onChangeCb={(e, id) => onChangeTogglesForms({ id, value: e.target.checked })}
                      id="includeTaxOnIntensFree"
                      checked={togglesTaxes.includeTaxOnIntensFree}
                    />
                  </S.ToggleCheckboxDiv>
                  <p>{I18n.get("Internal Items")}</p>
                </S.ToggleContent>
                <S.ToggleContent>
                  <S.ToggleCheckboxDiv>
                    <ToggleCheckbox
                      onChangeCb={(e, id) => onChangeTogglesForms({ id, value: e.target.checked })}
                      id="includeTaxOnFee"
                      checked={togglesTaxes.includeTaxOnFee}
                    />
                  </S.ToggleCheckboxDiv>
                  <p>{I18n.get("Comission Fee")}</p>
                </S.ToggleContent>
              </S.TogglesTaxes>
            </S.TableRow>
          )}
        </S.TableFooter>
        <S.TableTotal>
          <S.TableRow>
            <S.TableItem />
            <S.TableItem total>{I18n.get("Total")}</S.TableItem>
            <S.TableItem>
              <strong>{renderTotal()}</strong>
            </S.TableItem>
          </S.TableRow>
        </S.TableTotal>
      </S.TableContainer>

      {/* {auth?.is_client_or_client_user ? null : (
        <div style={{ marginTop: 30 }}>
          <S.Text strongText style={{ marginBottom: 10 }}>
            {I18n.get("Include taxes *")}
          </S.Text>
          <S.TextSmall style={{ marginBottom: 25 }}>
            {I18n.get("Consult your contract and verify that the agreed prices for interior items are gross or net.")}
          </S.TextSmall>
          <S.TogglesTaxes>
            <S.ToggleContent>
              <ToggleCheckbox
                onChangeCb={(e, id) => onChangeTogglesForms({ id, value: e.target.checked })}
                id="includeTaxOnIntensFree"
                checked={togglesTaxes.includeTaxOnIntensFree}
              />
              <p>{I18n.get("Internal Items")}</p>
            </S.ToggleContent>
            <S.ToggleContent style={{ marginLeft: "25%" }}>
              <ToggleCheckbox
                onChangeCb={(e, id) => onChangeTogglesForms({ id, value: e.target.checked })}
                id="includeTaxOnFee"
                checked={togglesTaxes.includeTaxOnFee}
              />
              <p>{I18n.get("Comission Fee")}</p>
            </S.ToggleContent>
          </S.TogglesTaxes>
        </div>
      )} */}
    </>
  );
};
