import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { I18n } from 'aws-amplify';

import TableProposalAsset from './tableProposalAsset';

import NumberFormat from 'react-number-format';

import {
    Table,
    Icon
} from 'antd';

const Btns = styled.p`
width: 50px;
displa: block;
    a {
        margin-left: 10px;
    }
`
const BRL = ({ amount }) => <NumberFormat value={amount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} fixedDecimalScale={true} />;


class tableProposal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const columns = [
            {
                title: I18n.get("Criado em"),
                dataIndex: 'date',
                key: 'date'
            },
            {
                title: I18n.get("Total Assets"),
                dataIndex: 'assets',
                key: 'assets'
            },
            {
                title: I18n.get("Total"),
                dataIndex: 'total',
                key: 'total',
                render: (total, record) => {
                    return <p><BRL amount={total} /></p>
                }
            },
            {
                title: I18n.get("Status"),
                dataIndex: 'status',
                key: 'status',
                render: (total, record) => {
                    if(record.item.approved == false && record.item.notApproved == false){
                        return I18n.get("Aguardando aprovação");
                    }
                    if(record.item.approved == true && !record.item.notApproved){
                        return I18n.get("Aprovado");
                    }
                    if(!record.item.approved && record.item.notApproved == false){
                        return I18n.get("Reprovado");
                    }
                   
                }
            }
        ]

        let dataTable = this.props.data && this.props.data.map((item, index) => {
            return {
                key: index,
                assets: item?.assets.length,
                total: item?.total,
                date: item?.sentToClientToApproveOn,
                status: item?.status,
                item: item
            }
        });


        return (
            <Table
                columns={columns}
                dataSource={dataTable || []}
                scroll={{ x: 640 }}
                locale={{ emptyText: I18n.get('Sem informação') }}
                expandedRowRender={record => {

                    var totalPrice = 0;
                    var totalItem = 0;
                    let _cart = record.item.assets;
                    var cart = [];
                    
                    _cart.forEach((item) => {
                        var index = cart.findIndex((_item) => {
                            return _item.asset.code == item.code;
                        });
                        
                        if (index > -1) {
                            cart[index].total++;
                        } else {
                            cart.push({
                                asset: item,
                                total: 1
                            });
                        }
                        totalPrice += item.price;
                        totalItem++;
                    });
                    
                    return (
                        <div className="expTable">
                            <TableProposalAsset 
                                data={cart || []}
                                totalPrice={totalPrice}
                            />
                        </div>
                    )
                }}
            />
        );
    }
}

export default tableProposal;
