import { I18n } from "aws-amplify";

export const statusFmtBpay = (status) => {
  let statusFmt;

  switch (status) {
    case 1:
      statusFmt = I18n.get("Rascunho");
      break;
    case 2:
      statusFmt = I18n.get("Cadastro de fornecedor");
      break;
    case 3:
      statusFmt = I18n.get("Aguardando PO");
      break;
    case 4:
      statusFmt = I18n.get("Em andamento");
      break;
    case 5:
      statusFmt = I18n.get("Finalizado");
      break;
    default:
      statusFmt = "Status ...";
  }
  return statusFmt;
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
