import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useCertificates = () => {
  const getCertificatesProviders = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/certificate/providers`,
      config
    );

    return data;
  };

  const getCertificatesPartner = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/certificate/partner`,
      config
    );

    return data;
  };

  const sendCertificatesPartner = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/partner/certificate`,
      dataForm,
      config
    );

    return data;
  };

  const updateCertificatesPartner = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/partner/certificate`,
      dataForm,
      config
    );

    return data;
  };

  const deleteCertificatesPartner = async (id) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/partner/certificate`,
      {
        data: { id },
        ...config,
      },
      config
    );

    return data;
  };

  const getCertificatesPartnerViewClient = async (partnerId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/certificate/client/${partnerId}`,
      config
    );

    return data;
  };

  return {
    getCertificatesProviders,
    getCertificatesPartner,
    sendCertificatesPartner,
    updateCertificatesPartner,
    deleteCertificatesPartner,
    getCertificatesPartnerViewClient,
  };
};
