import React from "react";

import { Descriptions } from "antd";

import { I18n } from "aws-amplify";

import NumberFormat from "react-number-format";

import { Task } from "../../../utils/task";
import { FormUtils } from "../../../utils/form";

import TextField from "../../form/text-field";
import MaskedField from "../../form/masked-field";
import MoneyField from "../../form/money-field";
import PercentageField from "../../form/percentage-field";
import RadioMultiField from "../../form/radio-multi-field";
import SelectSearchField from "../../form/select-search-field";
import SubmitButtonField from "../../form/submit-button-field";
import SelectField from "../../form/select-field";

import FileUploadList from "../../../modules/components/FileUploadList";

import constants from "../../../constants";

import FormatCurrency from "../../ui/format-currency";

import "./_billing-invoice-form.css";

class BillingInvoiceForm extends React.Component {
  constructor(props) {
    super();

    const partner = props.partner || {};

    const paymentBeneficiaries = partner.paymentBeneficiaries || [];

    const beneficiaryType = paymentBeneficiaries.length == 0 ? "NEW" : "";

    const company = props.company || {};

    

    this.state = {
      fields: {
        paymentMethod: {
          onChange: this.onChangeForm.bind(this, "paymentMethod"),
          successIcon: false,
          visited: true,
          isHorizontal: true,
        },
        bankId: {
          label: I18n.get("Banco"),
          onChange: this.onChangeFormValue.bind(this, "bankId"),
          successIcon: false,
          visited: true,
        },
        bankBranch: {
          label: I18n.get("Agencia"),
          onChange: this.onChangeForm.bind(this, "bankBranch"),
          successIcon: false,
          visited: true,
        },
        bankAccount: {
          label: I18n.get("Conta"),
          onChange: this.onChangeForm.bind(this, "bankAccount"),
          successIcon: false,
          visited: true,
        },
        paymentBeneficiaryId: {
          onChange: this.onChangeForm.bind(this, "paymentBeneficiaryId"),
          successIcon: false,
          visited: true,
        },
        beneficiaryName: {
          label: I18n.get("Favorecido"),
          onChange: this.onChangeForm.bind(this, "beneficiaryName"),
          successIcon: false,
          visited: true,
        },
        beneficiaryId: {
          label: I18n.get("CNPJ"),
          onChange: this.onChangeForm.bind(this, "beneficiaryId"),
          mask: [
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
          ],
          successIcon: false,
          visited: true,
        },
        invoiceNumber: {
          label: I18n.get("Nº Nota"),
          onChange: this.onChangeForm.bind(this, "invoiceNumber"),
          successIcon: false,
          visited: true,
        },
        invoiceAmount: {
          label: I18n.get("Valor"),
          onChange: this.onChangeForm.bind(this, "invoiceAmount"),
          successIcon: false,
          visited: true,
        },
        tax: {
          label: I18n.get("Retenção IR"),
          onChange: this.onChangeForm.bind(this, "tax"),
          successIcon: false,
          visited: true,
        },
        isMidia: {
          label: I18n.get("Nota ref. Mídia"),
          onChange: this.onChangeForm.bind(this, "isMidia"),
          successIcon: false,
          visited: true,
        },
        pix: {
          label: I18n.get("Chave Pix"),
          onChange: this.onChangeForm.bind(this, "pix"),
          successIcon: false,
          visited: true,
        },
        bankName: {
          label: I18n.get("Banco"),
          onChange: this.onChangeForm.bind(this, "bankName"),
          successIcon: false,
          visited: true,
        },
        bankCountryId: {
          label: I18n.get("País"),
          onChange: this.onChangeFormValue.bind(this, "bankCountryId"),
          successIcon: false,
          visited: true,
        },
        bankCity: {
          label: I18n.get("Cidade"),
          onChange: this.onChangeForm.bind(this, "bankCity"),
          successIcon: false,
          visited: true,
        },
        bankAddress: {
          label: I18n.get("Endereço do beneficiário"),
          onChange: this.onChangeForm.bind(this, "bankAddress"),
          successIcon: false,
          visited: true,
        },
        bankSwift: {
          label: "Código SWIFT",
          onChange: this.onChangeForm.bind(this, "bankSwift"),
          successIcon: false,
          visited: true,
        },
        bankABA: {
          label: "Código ABA/ACH",
          onChange: this.onChangeForm.bind(this, "bankABA"),
          successIcon: false,
          visited: true,
        },
        // INTERMEDIARIO
        intermediateDirectionBeneficiary: {
          label: I18n.get("Dirección de Intermediário"),
          onChange: this.onChangeForm.bind(
            this,
            "intermediateDirectionBeneficiary"
          ),
          successIcon: false,
          visited: true,
        },
        intermediateAccountNumber: {
          label: I18n.get("Número de cuenta"),
          onChange: this.onChangeForm.bind(this, "intermediateAccountNumber"),
          successIcon: false,
          visited: true,
        },
        intermediateInterbankKey: {
          label: I18n.get("Clabe interbancaria"),
          onChange: this.onChangeForm.bind(this, "intermediateInterbankKey"),
          successIcon: false,
          visited: true,
        },
        intermediateSWIFTABABICIBAN: {
          label: I18n.get("SWIFT / ABA / BIC / IBAN"),
          onChange: this.onChangeForm.bind(this, "intermediateSWIFTABABICIBAN"),
          successIcon: false,
          visited: true,
        },
        intermediateBank: {
          label: I18n.get("Banco"),
          onChange: this.onChangeForm.bind(this, "intermediateBank"),
          successIcon: false,
          visited: true,
        },
        intermediateBankDirection: {
          label: I18n.get("Dirección del Banco"),
          onChange: this.onChangeForm.bind(this, "intermediateBankDirection"),
          successIcon: false,
          visited: true,
        },

        // Beneficiário
        beneficiaryDirectionBeneficiary: {
          label: I18n.get("Dirección de Intermediário"),
          onChange: this.onChangeForm.bind(
            this,
            "beneficiaryDirectionBeneficiary"
          ),
          successIcon: false,
          visited: true,
        },
        beneficiaryAccountNumber: {
          label: I18n.get("Número de cuenta"),
          onChange: this.onChangeForm.bind(this, "beneficiaryAccountNumber"),
          successIcon: false,
          visited: true,
        },
        beneficiaryInterbankKey: {
          label: I18n.get("Clabe interbancaria"),
          onChange: this.onChangeForm.bind(this, "beneficiaryInterbankKey"),
          successIcon: false,
          visited: true,
        },
        beneficiarySWIFTABABICIBAN: {
          label: I18n.get("SWIFT / ABA / BIC / IBAN"),
          onChange: this.onChangeForm.bind(this, "beneficiarySWIFTABABICIBAN"),
          successIcon: false,
          visited: true,
        },
        beneficiaryBank: {
          label: I18n.get("Banco"),
          onChange: this.onChangeForm.bind(this, "beneficiaryBank"),
          successIcon: false,
          visited: true,
        },
        beneficiaryBankDirection: {
          label: I18n.get("Dirección del Banco"),
          onChange: this.onChangeForm.bind(this, "beneficiaryBankDirection"),
          successIcon: false,
          visited: true,
        },
      },
      form: {
        invoiceAmount: 0,
        beneficiaryType: beneficiaryType,
        beneficiaryId: company.registrationId,
        beneficiaryName: company.registrationName,
        paymentMethod: "",
        isMidia: null,
      },
      errors: {},
      file: {},
      filePreview: [],
      slip: {},
      slipPreview: [],
      task: {},
      upload_task: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState) return;
    if (!this.props.task) return;

    if (this.props.task.id == prevState.task.id) return;

    // atualiza a task
    var nextState = Object.assign({}, prevState, { task: this.props.task });

    // atualiza os erros do form
    FormUtils.updateErrors(nextState, this.props.errors);

    if (this.props.task.result) {
      // se task for sucesso, limpa os campos do form
      FormUtils.resetForm(nextState);

      nextState.file = {};
    }

    this.setState(nextState);
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeFormValue(prop, value) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  isMidiaV = (valor) => {
    switch (valor) {
      case "true":
        return true;
        break;
      case "false":
        return false;
        break;

      default:
        return null;
        break;
    }
  };

  getData() {
    const { projectId, extraId } = this.props.match.params;

    const { billing } = this.props;

    var data = {
      projectId: projectId,
      extraId: extraId,
      billingId: billing.id,
      installmentId: this.props.installment.id,
      ...this.state.form,
      isMidia: this.isMidiaV(this.state.form.isMidia),
      file: this.state.file,
      slip: this.state.slip,
    };

    return data;
  }

  submitForm(e) {
    e.preventDefault();

    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props
      .create_billing_invoice(this.getData())
      .then(({ task, errors }) => {
        if (task.result) {
          this.setState({
            submit_task: task,
            errors: errors,
            form: {},
            file: {},
            slip: {},
          });

          this.props.resetInstallment();
        } else {
          this.setState({ submit_task: task, errors: errors });
        }
      });
  }

  getDataUploadMult = (files) => {
    if (files.length > 0) {
      this.setState({
        file: {
          ...files[0],
        },
        filePreview: files,
      });
    } else {
      this.setState({
        filePreview: [],
        file: {},
      });
    }
  };

  getDataUploadSlip = (files) => {
    if (files.length > 0) {
      this.setState({
        slip: {
          ...files[0],
        },
        slipPreview: files.map((file) => ({ ...file, uid: file.id })),
      });
    } else {
      this.setState({
        slipPreview: [],
        slip: {},
      });
    }
  };

  render() {

    const { partner = {}, bp = {} } = this.props;

    const currency = this.props.currency || {};

    const { paymentBeneficiaries = [], country = {} } = partner;

    const paymentBeneficiary =
      paymentBeneficiaries &&
      paymentBeneficiaries.find(
        (item) => item.id == this.state.form.paymentBeneficiaryId
      );

    const isPaymentBeneficiarySlip =
      paymentBeneficiary && paymentBeneficiary.paymentMethod == "SLIP";

    const paymentBeneficiaryValues =
      paymentBeneficiaries &&
      paymentBeneficiaries
        .sort(
          (a, b) =>
            (a.beneficiaryName > b.beneficiaryName) -
            (a.beneficiaryName < b.beneficiaryName)
        )
        .map((item) => {
          let paymentMethod = "";

          if (item.paymentMethod == "TRANSFER") {
            paymentMethod = `${I18n.get("Banco")}: ${item.bankId} ${I18n.get(
              "Agência"
            )}: ${item.bankBranch} ${I18n.get("Conta")}: ${item.bankAccount}`;
          } else if (item.paymentMethod == "PIX") {
            paymentMethod = `${I18n.get("Chave Pix")}: ${item.pix}`;
          } else if (item.paymentMethod == "SWIFT_ABA") {
            

            paymentMethod = `${I18n.get("Banco")}: ${item.bankName} - ${
              item.bankCity
            }, ${item.bankCountryId} SWIFT: ${
              item.bankSwift ?? "-"
            } ABA/ACH: ${item.bankAba ?? "-"} ${I18n.get(
              "Conta"
            )}: ${item.bankAccount}`;
          } else {
            paymentMethod = I18n.get(item.paymentMethod);
          }

          return {
            code: item.id,
            label: (
              <div className="payment-beneficiary-item">
                {item.beneficiaryName} - {item.beneficiaryId} <br />{" "}
                <span style={{ fontSize: "90%", color: "#696969" }}>
                  {paymentMethod}
                </span>{" "}
              </div>
            ),
          };
        });

    paymentBeneficiaryValues.push({
      code: "NEW",
      label: (
        <div className="payment-beneficiary-new">
          {I18n.get("NOVO BENEFICIÁRIO")}
        </div>
      ),
    });

    let bpoolOffice = this.props.project.bPoolOffice;

    let filterMethod = [];
    let brasilMethods = ["PIX", "SLIP", "TRANSFER"];
    let latamMethods = ["SWIFT_ABA"];
    let usaMethods = ["SWIFT_ABA"];

    if (bpoolOffice.bpRegion == "BRASIL") {
      filterMethod = brasilMethods;
    }

    if (bpoolOffice.bpRegion == "LATAM") {
      filterMethod = latamMethods;
    }

    if (bpoolOffice.bpRegion == "USA") {
      filterMethod = usaMethods;
    }


    let billingInvoicePaymentMethods =
      bp?.billingInvoicePaymentMethods?.filter(payMethod => filterMethod.includes(payMethod.code));

    let filesNormalize =
      this.state.filePreview &&
      this.state.filePreview.map((item) => {
        return {
          ...item,
          uid: item.id,
        };
      });

    const { installment = {} } = this.props;

    const isMidiaValues = [
      {
        code: true,
        label: I18n.get("Sim"),
      },
      {
        code: false,
        label: I18n.get("Não"),
      },
    ];

    const isMask = this.props?.project && this.props?.project?.bPoolOffice && this.props?.project?.bPoolOffice?.bpRegion == "BRASIL";

    return (
      <section className="billing-invoice-form">
        <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
          {I18n.get("Parcela")}
        </p>

        <br />

        <Descriptions>
          <Descriptions.Item label={I18n.get("Liberação")}>
            {installment.createdOn}
          </Descriptions.Item>
          <Descriptions.Item label={I18n.get("Valor")}>
            {<FormatCurrency amount={installment.amount} currency={currency} />}
          </Descriptions.Item>
          <Descriptions.Item label={I18n.get("Em Aberto")}>
            {
              <FormatCurrency
                amount={installment.balance}
                currency={currency}
              />
            }
          </Descriptions.Item>
        </Descriptions>

        <br />

        <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
          {I18n.get("Nota Fiscal")}
        </p>

        <br />

        <div className="columns">
          <div className="column is-4">
            <TextField
              {...this.state.fields.invoiceNumber}
              value={this.state.form.invoiceNumber}
              error={this.state.errors.invoiceNumber}
            />
          </div>

          <div className="column is-4">
            <MoneyField
              {...this.state.fields.invoiceAmount}
              currency={currency}
              value={this.state.form.invoiceAmount}
              error={this.state.errors.invoiceAmount}
            />
          </div>

          <div className="column is-4">
            <SelectField
              {...this.state.fields.isMidia}
              value={this.state.form.isMidia}
              error={this.state.errors.isMidia}
              values={isMidiaValues}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-4">
            <PercentageField
              {...this.state.fields.tax}
              value={this.state.form.tax}
              error={this.state.errors.tax}
              inputClassName="input has-text-right"
            />
          </div>
        </div>

        <div>
          <label className="label is-small">
            {I18n.get("Upload de NFSe")}
            <div></div>
          </label>
          <FileUploadList
            getDataUpload={this.getDataUploadMult}
            name="files"
            uploadId={this.props.project.projectId}
            get2={this.props.get2 || false}
            fileListGet={filesNormalize}
            limit={1}
            output="object"
          />
          {this.state.filePreview.length == 0 &&
            this.state.errors.file &&
            this.state.errors.file && (
              <p className="help is-danger">
                {this.state.errors.file.errorMessage}
              </p>
            )}
          {this.state.filePreview.length == 0 &&
            this.state.errors["file.id"] && (
              <p className="help is-danger">
                {this.state.errors["file.id"].errorMessage}
              </p>
            )}
        </div>

        <br />

        <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
          {I18n.get("Beneficiário")}
        </p>

        <div className="boxBeneficiario">
          <RadioMultiField
            {...this.state.fields.paymentBeneficiaryId}
            value={this.state.form.paymentBeneficiaryId}
            error={this.state.errors.paymentBeneficiaryId}
            values={paymentBeneficiaryValues}
          />
        </div>

        {this.state.form.paymentBeneficiaryId == "NEW" && (
          <>
            <div className="columns">
              <div className="column is-6">
                <TextField
                  {...this.state.fields.beneficiaryName}
                  value={this.state.form.beneficiaryName}
                  error={this.state.errors.beneficiaryName}
                />
              </div>

              <div className="column is-6">
                {isMask && 
                <MaskedField
                {...this.state.fields.beneficiaryId}
                value={this.state.form.beneficiaryId}
                error={this.state.errors.beneficiaryId}
                />
              }
              {!isMask && 
             
                <TextField
                  {...this.state.fields.beneficiaryId}
                  value={this.state.form.beneficiaryId}
                  error={this.state.errors.beneficiaryId}
                />
              }
              </div>
            </div>

            <br />
          </>
        )}

        {this.state.form.paymentBeneficiaryId == "NEW" && (
          <>
            <RadioMultiField
              {...this.state.fields.paymentMethod}
              label={I18n.get("Forma de Pagamento")}
              value={this.state.form.paymentMethod}
              error={this.state.errors.paymentMethod}
              values={billingInvoicePaymentMethods}
            />
          </>
        )}

        {this.state.form.paymentBeneficiaryId == "NEW" &&
          this.state.form.paymentMethod == "TRANSFER" && (
            <>
              <div className="columns">
                <div className="column is-6">
                  <SelectSearchField
                    {...this.state.fields.bankId}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.bankId
                        : this.state.form.bankId
                    }
                    error={this.state.errors.bankId}
                    values={constants.BANK_IDS}
                    isSearchable={!paymentBeneficiary}
                  />
                </div>
                <div className="column is-3">
                  <TextField
                    {...this.state.fields.bankBranch}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.bankBranch
                        : this.state.form.bankBranch
                    }
                    error={this.state.errors.bankBranch}
                    readOnly={paymentBeneficiary}
                  />
                </div>
                <div className="column is-3">
                  <TextField
                    {...this.state.fields.bankAccount}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.bankAccount
                        : this.state.form.bankAccount
                    }
                    error={this.state.errors.bankAccount}
                    readOnly={paymentBeneficiary}
                  />
                </div>
              </div>
            </>
          )}

        {((this.state.form.paymentBeneficiaryId == "NEW" &&
          this.state.form.paymentMethod == "SLIP") ||
          isPaymentBeneficiarySlip) && (
          <>
            <div className="columns">
              <div className="column is-12">
                <label className="label is-small">
                  {I18n.get("Upload de Boleto")}
                  <div></div>
                </label>
                <FileUploadList
                  getDataUpload={this.getDataUploadSlip}
                  name="slip"
                  uploadId={this.props.project.projectId}
                  get2={this.props.get2 || false}
                  fileListGet={this.state.slipPreview}
                  limit={1}
                  output="object"
                />
                {this.state.slipPreview.length == 0 &&
                  this.state.errors.slip &&
                  this.state.errors.slip && (
                    <p className="help is-danger">
                      {this.state.errors.slip.errorMessage}
                    </p>
                  )}
                {this.state.slipPreview.length == 0 &&
                  this.state.errors["slip.id"] && (
                    <p className="help is-danger">
                      {this.state.errors["slip.id"].errorMessage}
                    </p>
                  )}
              </div>
            </div>
          </>
        )}

        {this.state.form.paymentBeneficiaryId == "NEW" &&
          this.state.form.paymentMethod == "PIX" && (
            <>
              <div className="columns">
                <div className="column is-12">
                  <TextField
                    {...this.state.fields.pix}
                    value={this.state.form.pix}
                    error={this.state.errors.pix}
                  />
                </div>
              </div>
            </>
          )}

        {this.state.form.paymentBeneficiaryId == "NEW" &&
          this.state.form.paymentMethod == "SWIFT_ABA" && (
            <>
              <div className="columns">
                <div className="column is-12">
                  <h2>{I18n.get("Intermediário")}</h2>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.intermediateDirectionBeneficiary}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.intermediateDirectionBeneficiary
                        : this.state.form.intermediateDirectionBeneficiary
                    }
                    error={this.state.errors.intermediateDirectionBeneficiary}
                  />
                </div>
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.intermediateAccountNumber}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.intermediateAccountNumber
                        : this.state.form.intermediateAccountNumber
                    }
                    error={this.state.errors.intermediateAccountNumber}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.intermediateInterbankKey}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.intermediateInterbankKey
                        : this.state.form.intermediateInterbankKey
                    }
                    error={this.state.errors.intermediateInterbankKey}
                  />
                </div>
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.intermediateSWIFTABABICIBAN}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.intermediateSWIFTABABICIBAN
                        : this.state.form.intermediateSWIFTABABICIBAN
                    }
                    error={this.state.errors.intermediateSWIFTABABICIBAN}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.intermediateBank}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.intermediateBank
                        : this.state.form.intermediateBank
                    }
                    error={this.state.errors.intermediateBank}
                  />
                </div>
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.intermediateBankDirection}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.intermediateBankDirection
                        : this.state.form.intermediateBankDirection
                    }
                    error={this.state.errors.intermediateBankDirection}
                  />
                </div>
              </div>

              <br />
              <br />
              <br />

              <div className="columns">
                <div className="column is-12">
                  <h2>{I18n.get("Beneficiário")}</h2>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.beneficiaryDirectionBeneficiary}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.beneficiaryDirectionBeneficiary
                        : this.state.form.beneficiaryDirectionBeneficiary
                    }
                    error={this.state.errors.beneficiaryDirectionBeneficiary}
                  />
                </div>
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.beneficiaryAccountNumber}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.beneficiaryAccountNumber
                        : this.state.form.beneficiaryAccountNumber
                    }
                    error={this.state.errors.beneficiaryAccountNumber}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.beneficiaryInterbankKey}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.beneficiaryInterbankKey
                        : this.state.form.beneficiaryInterbankKey
                    }
                    error={this.state.errors.beneficiaryInterbankKey}
                  />
                </div>
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.beneficiarySWIFTABABICIBAN}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.beneficiarySWIFTABABICIBAN
                        : this.state.form.beneficiarySWIFTABABICIBAN
                    }
                    error={this.state.errors.beneficiarySWIFTABABICIBAN}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.beneficiaryBank}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.beneficiaryBank
                        : this.state.form.beneficiaryBank
                    }
                    error={this.state.errors.beneficiaryBank}
                  />
                </div>
                <div className="column is-6">
                  <TextField
                    {...this.state.fields.beneficiaryBankDirection}
                    value={
                      paymentBeneficiary
                        ? paymentBeneficiary.beneficiaryBankDirection
                        : this.state.form.beneficiaryBankDirection
                    }
                    error={this.state.errors.beneficiaryBankDirection}
                  />
                </div>
              </div>
            </>
          )}

        <br />

        <SubmitButtonField
          label={I18n.get("ADICIONAR NF")}
          className="button is-black"
          onClick={this.submitForm.bind(this)}
          task={this.state.submit_task}
          disabled={this.state.upload_task.busy}
        ></SubmitButtonField>

        {/* {project.isCustomEvent && <>

                        <p style={{ fontSize: '1.2rem' }}>{I18n.get("Detalhes")}</p>

                        <div className="columns">

                            <div className="column is-4">
                                <TextField {...this.state.fields.description} value={this.state.form.description} error={this.state.errors.description} />
                            </div>
                            <div className="column is-2">
                                <TextField {...this.state.fields.segment} value={this.state.form.segment} error={this.state.errors.segment} />
                            </div>
                            <div className="column is-2">
                                <TextField {...this.state.fields.category} value={this.state.form.category} error={this.state.errors.category} />
                            </div>

                            <div className="column is-2 has-text-right">

                            </div>

                        </div>

                    </>} */}
      </section>
    );
  }
}

export default BillingInvoiceForm;
