import styled from "styled-components";
import { Collapse as CollapseAntd } from "antd";

export const Collapse = styled(CollapseAntd)`
  .ant-collapse-header {
    display: flex;
    align-items: center;
  }
  .ant-collapse-extra {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const WrapperItem = styled.div`
  display: grid;
  gap: ${(props) => (props?.hasImage ? "32px" : "0px")};
  grid-template-columns: ${(props) =>
    props?.hasImage ? "130px 1fr" : "0px 1fr"};
`;

export const ImgItem = styled.div`
  width: 130px;

  img {
    width: 100%;
  }
`;

export const ItemCaseFormEmpty = styled.div`
  font-size: 14px;
`;

export const ItemCaseLabel = styled.div`
  font-weight: bold;
`;

export const ItemCaseValue = styled.div``;

export const ContentItem = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const HeaderAccordion = styled.div`
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    max-height: 20px;
  }
`;

export const ItemCase = styled.div``;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;
