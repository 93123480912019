import React from "react";

import { I18n } from "aws-amplify";

import * as S from "./styles";

import { Button } from "../../../../../../components/UI/Button";
import IconClose from "../../../../../../../svg/close-black.svg";

import { Row, Col, Tooltip, Icon } from "antd";

const AssetDetails = ({ asset, addAsset, loading, hideButton, close }) => {
  return (
    <S.StyledItem
      data-code={asset?.code}
      data-label={asset?.label}
      data-price={asset?.price}
      data-complexity={asset?.complexity}
      data-complexity-id={asset?.complexityId}
    >
      <S.Close onClick={() => close()}>
        <img src={IconClose} alt="" />
      </S.Close>
      <Row>
        <Col lg={24}>
          <S.StyledTitle>{I18n.get("Detalhes")}</S.StyledTitle>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <p>
            <S.StyledName>
              {asset?.label}
              {asset?.complexityId && (
                <>
                  {" - "}
                  <S.StyledBadge>{asset?.complexityId}</S.StyledBadge>{" "}
                  <S.StyledBadgeText>{asset?.complexity}</S.StyledBadgeText>
                </>
              )}
            </S.StyledName>
          </p>

          <S.StyledInfos>
            <p>
              <strong>{I18n.get("Valor")}:</strong> <b>{asset?.priceFmt}</b>
            </p>
            {/* <p><strong>{I18n.get("Prazo")}:</strong>  {asset?.priceFmt}</p> */}
          </S.StyledInfos>
        </Col>
        <Col lg={12}>
          <S.StyledName>{I18n.get("Informações do escopo")}</S.StyledName>
          <S.StyledDescription>
            <div>{asset?.description}</div>
          </S.StyledDescription>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <S.StyledAction>
            {!hideButton && (
              <Button
                text={I18n.get("Selecionar")}
                className="pull-right"
                theme="primary"
                target="_blank"
                size="small"
                loading={loading}
                onClick={() => addAsset(asset?.id)}
              />
            )}
          </S.StyledAction>
        </Col>
      </Row>
    </S.StyledItem>
  );
};

export default AssetDetails;
