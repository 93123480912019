import React, { Component } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";

import {
  Row,
  Col,
  Button,
  AutoComplete,
  Input,
  Select,
  Form,
  Checkbox,
} from "antd";

const { Option } = Select;

const { TextArea } = Input;

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
    margin-right: 20px;
  }
`;

class FormAddMsgGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: [],
      viewErros: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formState: values,
          viewErros: true,
        });

        let data = {
          ...values
        };

        this.props.add(data);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;
  }

  getCategoryCountValues = () => {
    var values = [],
      i = 0,
      len = 10;
    while (++i <= len) values.push({ code: i, label: i });

    return values;
  };

  render() {
    const { getFieldDecorator } = this.props.form;

  

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row className="" gutter={12}>
          <Col sm={24}>
            <Form.Item label={I18n.get("Mensagem")}>
              {getFieldDecorator("produtoGlobalMessage", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: this.props?.msg || "",
              })(<TextArea height="200px"  />)}
            </Form.Item>
          </Col>
       
          <Col sm={24}>
            <Button
            
              type="primary"
              shape="round"
              htmlType="submit"
            >
              {I18n.get("Salvar")}
            </Button>
          </Col>
        </Row>       
      </Form>
    );
  }
}

const FormAddMsgGlobalForm = Form.create({})(FormAddMsgGlobal);

export default FormAddMsgGlobalForm;
