import React from "react";
import { notification, Icon } from "antd";

const COLOR_FONTS = "#FFFFFF";
const COLORS_SUCCESS = { primary: "#15b467", secondary: "" };
const COLORS_ERROR = { primary: "#9F0F0F", secondary: "" };
const COLORS_WARNING = { primary: "#C29800", secondary: "" };
const COLORS_DEFAULT = { primary: "#1883ff", secondary: "" };

const renderByType = (type) => {
  if (type === "success") {
    return {
      color: COLORS_SUCCESS.primary,
      icon: <Icon type="check-circle" style={{ color: COLOR_FONTS }} />,
    };
  } else if (type === "error") {
    return {
      color: COLORS_ERROR.primary,
      icon: <Icon type="close-circle" style={{ color: COLOR_FONTS }} />,
    };
  } else if (type === "warning") {
    return {
      color: COLORS_WARNING.primary,
      icon: <Icon type="exclamation-circle" style={{ color: COLOR_FONTS }} />,
    };
  } else {
    return {
      color: COLORS_DEFAULT.primary,
      icon: <Icon type="check-circle" style={{ color: COLOR_FONTS }} />,
    };
  }
};

export const NotificationBPool = {
  open: ({ duration = 0, type, title = "", description = "" }) => {
    notification.open({
      duration,
      className: "notification-bpool",
      message: title ? (
        <h3
          style={{
            color: COLOR_FONTS,
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "24px",
          }}
        >
          {title}
        </h3>
      ) : (
        ""
      ),
      description: description ? (
        <p
          style={{
            color: COLOR_FONTS,
            fontWeight: 600,
            fontSize: 14,
            lineHeight: "18px",
          }}
        >
          {description}
        </p>
      ) : (
        ""
      ),
      icon: renderByType(type)?.icon,
      closeIcon: <Icon type="close" style={{ color: COLOR_FONTS }} />,
      style: {
        backgroundColor: renderByType(type)?.color,
        color: COLOR_FONTS,
      },
    });
  },
};
