import React, { Component, Fragment } from "react";

import LayoutWikis from "./components/LayoutWikis";
import ContainerKnowledge from "./components/ContainerKnowledge";
import { I18n } from "aws-amplify";

import { Col, Row, Button, Icon } from "antd";

import TextItem from "./components/FaqItem";
import VideoItem from "./components/PreviewVideo";
import FileItem from "./components/FileItem";

class ViewItem extends Component {
  state = {
    collapsed: false,
    menuActive: null,
    termActive: "",
  };

  renderTypeItem = (type, content) => {
    let target = this.props.auth.is_client ? "cliente" : "parceiro";

    if (type == 2) {
      return (
        <Col sm={6}>
          <VideoItem {...this.props} target={target} item={content} />
        </Col>
      );
    }

    if (type == 1) {
      return (
        <Col sm={24}>
          <TextItem {...this.props} target={target} item={content} />
        </Col>
      );
    }

    if (type == 3) {
      return (
        <Col sm={6}>
          <FileItem {...this.props} target={target} item={content} />
        </Col>
      );
    }
  };

  componentDidMount = () => {
    this.props.get_knowledge(this.props.target);
  };

  render() {
    let scopeSections =
      this.props?.wikis?.knowledgeBase &&
      this.props?.wikis?.knowledgeBase.find(
        (sec) => sec.name == this.props.match.params.scope
      );

    let categorias = [];

    let contents = [];

    scopeSections &&
      scopeSections.sections.map((item) => {
        item.categories.map((cat) => {
          cat.contents.map((content) => {
            contents.push({
              ...content,
              type: scopeSections.type,
            });
          });
        });
      });

    let selected = contents.find(
      (item) => item.contentId == this.props.match.params.contentId
    );
    let videoOrigin = selected?.content || "";
    let convert =
      videoOrigin && videoOrigin.split("https://www.loom.com/share/")[1];

    console.log("scopeSections?.type", scopeSections?.type);

    return (
      <LayoutWikis
        {...this.props}
        scope={this.props?.wikis?.knowledgeBase || []}
      >
        <Row type="flex" gutter={36}>
          {scopeSections?.type == 1 && (
            <Col sm={24}>
              <div className="mainVideo">
                <div className="loomDiv">
                  <iframe
                    src={`https://www.loom.com/embed/${convert}`}
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                  />
                </div>
              </div>
            </Col>
          )}

          {scopeSections?.type == 3 && (
            <Fragment>
              <Col sm={20}>
                <h2>{selected.title}</h2>
              </Col>
              <Col sm={4}>
                {/* <a href={selected.content} className="button bt-bpool pull-right" download>{I18n.get("Download")}</a> */}
              </Col>
              <Col sm={24}>
                <iframe
                  src={selected.content}
                  height="900px"
                  width="100%"
                ></iframe>
              </Col>
            </Fragment>
          )}
          {scopeSections?.type == 2 && (
            <div className={`bp-video-faq ${this.state.open ? "open" : ""}`}>
              <h2 onClick={() => this.setState({ open: !this.state.open })}>
                {selected.title}
              </h2>

              <div className={`faq-description`}>
                <p>{selected.content}</p>
              </div>
            </div>
          )}
        </Row>
      </LayoutWikis>
    );
  }
}

export default ContainerKnowledge(ViewItem);
