import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { TitleMiddleLine } from "../../components/Titles";
import constants from "../../../constants";

import styled from "styled-components";

import {
  Row,
  Col,
  Button,
  Checkbox,
  Tooltip,
  Icon,
  Input,
  Switch,
  Select,
  Form,
} from "antd";

const { TextArea } = Input;
const { Option } = Select;

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
    margin-right: 20px;
  }
`;

class FormAddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: [],
    };
  }

  sendDataApi = () => {
    const fieldsForm = this.props.form.getFieldsValue();
    const dataForm = {
      ...fieldsForm,
      partnerId: this.props.partnerId,
    };

    return dataForm;
  };

  onChangeIsNew = (e) => {
    let checked = e.target.checked;

    let data = {
      partnerId: this.props.partnerId,
      isNewCompany: checked,
    };

    setTimeout(() => {
      this.props.isNewCompany(data);
    }, 50);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (this.props.errors != prevProps.errors && this.props.errors) {
      Object.keys(this.props.errors).map((field) => {
        this.props.form.setFields({
          [field]: {
            value: this.state.formState[field],
            errors: [new Error(this.props.errors[field].errorMessage)],
          },
        });
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formState: values,
        });

        let billing = values.billing ? JSON.parse(values.billing) : null;

        let data = {
          ...values,
          billing: billing,
          partnerId: this.props.partnerId,
        };

        if (this.props.item) {
          data.id = this.props.item.id;
          this.props.update(data);
        } else {
          this.props.action(data);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    if (!this.props.partner) return "";

    const { partner } = this.props;

    const ListCategorie =
      this.props.sectorsFlat &&
      this.props.sectorsFlat.sort(
        (a, b) => (a.label > b.label) - (a.label < b.label)
      );

    return (
      <Form onSubmit={this.handleSubmit}>


        <Row>


              <Col sm={12}>
                <Form.Item label={I18n.get("Empresa")}>
                  {getFieldDecorator("name", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.item && this.props.item.name
                        ? this.props.item.name
                        : null,
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item 
                            label={
                                    <span>
                                      {I18n.get("Marca")}{" "}

                                          {this.props.partner.signupLocale == 'en' && <Tooltip
                                            title={I18n.get("If it was a masterbrand project, insert N/A")}
                                          >
                                            <Icon
                                              className="iconLabel"
                                              type="question-circle"
                                              theme="filled"
                                            />
                                          </Tooltip>}

                                    </span>
                            }>

                  {getFieldDecorator("brand", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.item && this.props.item.brand
                        ? this.props.item.brand
                        : null,
                  })(<Input />)}
                </Form.Item>
              </Col>

          </Row>

          <Row>

              <Col sm={12}>
                <Form.Item label={
                                  <span>
                                      {I18n.get("Produto")}{" "}

                                          {this.props.partner.signupLocale == 'en' && <Tooltip
                                            title={I18n.get("If it was a masterbrand project, insert N/A")}
                                          >
                                            <Icon
                                              className="iconLabel"
                                              type="question-circle"
                                              theme="filled"
                                            />
                                          </Tooltip>}

                                    </span>
                                }>

                  {getFieldDecorator("product", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.item && this.props.item.product
                        ? this.props.item.product
                        : null,
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col sm={12}>
                <Form.Item label={
                                    <span>
                                      {I18n.get("Categoria")}
                                    </span>
                                  }>
                  {getFieldDecorator("category", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.item && this.props.item.category
                        ? this.props.item.category
                        : null,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {ListCategorie &&
                        ListCategorie.map((item) => {
                          return (
                            <Option value={item.code} key={item.code}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

        </Row>

        <Row>

          <Col span={24}>
            <Form.Item label={
                                <span>
                                {I18n.get("Faturamento_addclient")}
                                </span>
                              }>
              {getFieldDecorator("billing", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.billing
                    ? JSON.stringify(this.props.item.billing)
                    : null,
              })(
                <Select style={{ width: "100%" }} showSearch={true}>
                  {this.props.partnerClientBilling &&
                    this.props.partnerClientBilling.map((item) => {
                      return (
                        <Option value={JSON.stringify(item)} key={item.id}>
                          {item.label}
                        </Option>
                      );
                      // value = { JSON.stringify(item) }
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

        </Row>

        <Row>

          <Col span={24}>
            <Form.Item
              label={
                <span>
                  {I18n.get("Non-compete Ativo")}
                  <Tooltip
                    title={I18n.get(
                      "Por favor, marque a opção 'Non-compete' se a sua empresa assinou qualquer cláusula de 'non-compete' dentro da categoria e ainda esteja válida. Se você fez algum trabalho há 3 meses, mas assinou um 'non-compete' de 6 meses, lembre-se de desligar a chave 'Non-Compete Ativo' quando esses 6 meses expirarem"
                    )}
                  >
                    <Icon
                      className="iconLabel"
                      type="question-circle"
                      theme="filled"
                    />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("active", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.active ? true : false,
                valuePropName: "checked",
              })(<Switch />)}
            </Form.Item>
          </Col>


        </Row>

        <RowSubmitModal>
          <Col sm={24}>
            <Button shape="round" onClick={this.props.onCancel}>
              {I18n.get("Cancelar")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              onClick={this.handleSubmit}
            >
              {this.props.item ? I18n.get("Atualizar") : I18n.get("Adicionar")}
            </Button>
          </Col>
        </RowSubmitModal>
      </Form>
    );
  }
}

const MainFormAddClient = Form.create({})(FormAddClient);

export default MainFormAddClient;
