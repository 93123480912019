import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import constants from '../../../constants';
import { Link, Redirect } from 'react-router-dom';
import AppWrapperSimple from '../../components/AppWrapperRegister';
import ContainerPage from '../../components/ContainerPage';
import SubHeader from '../../components/SubHeader';

import { CLIENT_API } from '../../../actions/client/actions';

import FormResponsibleClient from '../components/formResponsibleClient';
import FormUser from '../components/formUser';

import { TitleBorder, TitleMiddleLine } from '../../components/Titles';

import styled from 'styled-components';

import FormSkeleton from "../../components/FormSkeleton";


import {
    Row,
    Col,
    Input,
    notification,
    Select,
    Tag,
    Icon,
    Button,
    Drawer,
    Table,
    Tooltip,
    Form
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;

const Btns = styled.p`
    a {
        margin-left: 10px;
    }
`


const Main = styled.div`
    padding-top: 50px;
    padding-bottom: 90px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    .RowSection {
        margin-bottom: 60px;
    }
`
const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(CLIENT_API.update_person(data));
        },
        get: data => {
            dispatch(CLIENT_API.get(data));
        },
    };
};

class FullResponsibleClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            show: false,
            userFlag: false
        }
    }


    showEditUser = (item) => {
        this.setState({
            selectedItem: item,
            edit: true,
            show: true,
            type: item.type,
            userFlag: true
        });
    }

    componentDidMount = () => {
        this.props.get(this.props.match.params.clientId);
    }



    render() {

        const { partner } = this.props;

        // if (this.props.partner && this.props.partner.status == 2) {
        //     return (<Redirect to={`/parceiro/obrigado-pre-cadastro`}></Redirect>);
        // }

        //TODO: Find better way... GAMBI!
        let menu = [...constants.MENU_REGISTER_CLIENT];
        for (let key in menu) {
            menu[key].label = I18n.get(menu[key].label);
        }

        return (
            <AppWrapperSimple>
                <SubHeader
                    titlePage=""
                    itensMenu={ menu }
                    urlId={this.props.match.params.clientId}
                    activeItem={1}
                />
                <ContainerPage>
                    <Main>
                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Cadastro - Responsável")}</TitleBorder>
                                <p className="descriptionPage">
                                    {I18n.get("Ao preencher este cadastro, você será o responsável principal da sua empresa em nossa plataforma. Nos próximos passos, poderá adicionar as pessoas da empresa que fazem parte do processo de contratação de parceiros criativos e desenvolvimento de projetos.")}
                                </p>
                            </Col>
                        </Row>

                        <FormResponsibleClient
                            client={this.props.client && this.props.client}
                            clientId={this.props.match.params.clientId}
                            action={this.props.save}
                            errors={this.props.client.errors && this.props.client.errors}
                            countryCodes={this.props.bp && this.props.bp.countryCodes}
                        />


                        <Row className="RowSection">
                            <Col sm={8}>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={3}>

                            </Col>
                            <Col sm={18}>
                            </Col>
                            <Col sm={3}>
                                <Link to={`/cliente/cadastre-se/empresa/${this.props.match.params.clientId}`}><Button type="primary" shape="round">{I18n.get("Avançar")}</Button></Link>
                            </Col>
                        </Row>

                    </Main>

                </ContainerPage>
            </AppWrapperSimple>
        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(FullResponsibleClient);
