import React, { useEffect, useState } from 'react';
import * as S from './styles';
import BannerSlider from '../../boxes-home/BannerSlider';
import ProjectStatus from '../../boxes-home/ProjectStatus';
import DashAndPendencies from './../../boxes-home/DashAndPendencies';
import TutorialsAndFAQ from '../../boxes-home/TutorialsAndFAQ';
import VideocallsAgenda from './../../boxes-home/VideocallsAgenda/index';
import Profile from '../../boxes-home/Profile';
import LoggedPage from '../../page-templates/fillForm-logged';
import { Auth } from 'aws-amplify';
import Config from '../../../config';
import axios from 'axios';
import { useMediaQuery } from './../../../utils/mediaQuery';

const InitialPage = () => { 
    const [projects, setProjects] = useState([]);
    const [dashboard, setDashboard] = useState([]);
    const [pendencies, setPendencies] = useState([]);
    const [agenda, setAgenda] = useState([]);
    const [profile, setProfile] = useState([]);
    const [staff, setStaff] = useState([]);
    const [tutorials, setTutorials] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [fluxogramas, setFluxogramas] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useMediaQuery();

    useEffect(() => {
        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/LandingPage`, config)
                .then(response => {
                    setDashboard(response.data.dashboard);
                    setFaqs(response.data.faqs);
                    setAgenda(response.data.meetings);
                    setProfile(response.data.profile);
                    setProjects(response.data.projects);
                    setStaff(response.data.supportStaff);
                    setTutorials(response.data.tutoriais);
                    setPendencies(response.data.warnings);
                    setFluxogramas(response.data.processos);
                })
                .catch(error => {
                    throw (error);
                });
        }).catch(error => {
            throw (error);
        });
        setLoading(false);
    }, [])

    return (
        <LoggedPage>
            {!loading && (
                <S.Home>
                    <div className="container">
                        <BannerSlider />
                        <S.BoxesHome>
                            <div>
                                <ProjectStatus projects={projects}/>
                                <DashAndPendencies dashboard={dashboard} pendencies={pendencies}/>
                                <VideocallsAgenda agenda={agenda}/>
                            </div>
                            <div>
                                <TutorialsAndFAQ tutorials={tutorials} faqs={faqs} fluxogramas={fluxogramas}/>
                                <Profile profile={profile} staff={staff}/>
                            </div>
                        </S.BoxesHome>
                    </div>
                </S.Home>
            )}
        </LoggedPage>
    )
}

export default InitialPage;