import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { Task } from '../../../utils/task';

import {
    Row,
    Col,
    Button,
    Checkbox,
    Input,
    Select,
    Form
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;


const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
`;

const RowStrong = styled(Row)`
    margin: 30px 0;
`

class FormTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formState: [],
            terms_task: {},
            errors: []
        }
    }

    sendDataApi = () => {
        const fieldsForm = this.props.form.getFieldsValue();
        const dataForm = {
            ...fieldsForm,
        }

        return dataForm;
    }

    handleSendDelegate = () => {

        var task = new Task();

        task.start();

        this.setState({ terms_task: task });

        const data = {
            clientId: this.props.clientId,
            delegateTerms: this.props.form.getFieldValue("delegateTerms")
        }

        this.props.update(data)
            .then(({ task, errors }) => {
                this.setState({ terms_task: task, errors: errors });
            });
    }

    handleTerms = () => {

        var task = new Task();

        task.start();

        this.setState({ terms_task: task });

        const data = {
            clientId: this.props.clientId,
            terms: this.props.form.getFieldValue("terms")
        }

        this.props.update(data)
            .then(({ task, errors }) => {
                this.setState({ terms_task: task, errors: errors });
            });

    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.state.errors != prevState.errors && this.state.errors) {
            Object.keys(this.state.errors).map(field => {
                this.props.form.setFields({
                    delegateTerms: {
                        value: this.props.form.getFieldValue("delegateTerms"),
                        errors: [new Error(this.state.errors["delegateTerms"] && this.state.errors["delegateTerms"].errorMessage)],
                    },
                });
            });
        }

        if (this.state.terms_task != prevState.terms_task && this.state.terms_task.result) {
            this.props.close();
        }
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <Form>
                <RowStrong>
                    <Col sm={24}>
                        <p><strong> {I18n.get("*Você pode incluir seu próprio email como responsável caso queira analisar posteriormente o Termo de Uso")}</strong></p>
                    </Col>
                </RowStrong>
                <Row className="RowSection">
                    <Col sm={12}>
                        <Form.Item>
                            {getFieldDecorator('terms', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                            })(
                                <Checkbox>{I18n.get("Li e estou de acordo")}</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={12}>
                        <Form.Item>
                            {getFieldDecorator('delegateTerms', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                            })(
                                <Input
                                    placeholder={I18n.get("E-mail Responsável")}
                                />
                            )}
                        </Form.Item>
                    </Col>




                </Row>
                <RowSubmitModal>

                    <Col sm={12}>
                        <Button loading={this.state.terms_task.busy} onClick={() => this.handleTerms()} disabled={!getFieldValue("terms")} type="primary" shape="round" style={{ float: "left" }}>{I18n.get("Aceitar")}</Button>
                    </Col>
                    <Col sm={12}>
                        <Button loading={this.state.terms_task.busy} onClick={() => this.handleSendDelegate()} disabled={!getFieldValue("delegateTerms")} type="primary" shape="round" style={{ float: "right" }} htmlType="submit">{I18n.get("ENVIAR PARA RESPONSÁVEL")}</Button>
                    </Col>
                </RowSubmitModal>
            </Form>
        );
    }
}

const FormTermsForm = Form.create({})(FormTerms);

export default FormTermsForm;
