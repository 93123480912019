import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import { Auth } from "aws-amplify";
import { I18n } from "aws-amplify";

import { AUTH_API } from "../../actions/auth/actions";

import { Task } from "../../utils/task";

import LandingPage from "../page-templates/landingForLogin";

import SubmitButtonField from "../form/submit-button-field";

import ErrorPane from "../error-pane";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgot_password: (data) => {
      dispatch(AUTH_API.forgot_password(data));
    },
  };
};

class Form extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      has_sent: false,
      task: {},
    };

    this.changeEmail = this.onChangeEmail.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState) return;
    if (!this.props.auth.task) return;

    if (this.props.auth.task.id != prevState.task.id) {
      this.setState({ task: this.props.auth.task });
    }
  }

  componentDidMount() {
    let lang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    lang =
      lang ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage;

    let language = lang.split("-")[0];

    if (language == "pt" || language == "es" || language == "en") {
      I18n.setLanguage(language);
    } else {
      I18n.setLanguage("pt");
    }
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value?.trim() });
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.forgot_password(this.state);
    });
  }

  render() {
    if (this.props.auth.password_reset_required) {
      return <Redirect to="/redefinir-senha" />;
    }

    return (
      <LandingPage>
        <div className="middle">
          <section className="login-section">
            <div className="container">
              <div className="columns  has-text-centered">
                <div className="column is-4 is-offset-4 form-recovery">
                  <div class="boxRedefinir">
                    <h3 className="title-bpool medium">
                      {I18n.get("Esqueci minha senha")}
                    </h3>
                    <p class="subtitle has-text-grey">
                      {I18n.get(
                        "Por favor digite seu e-mail cadastrado na plataforma."
                      )}
                    </p>
                    <br />
                  </div>
                  <form onSubmit={this.submitForm}>
                    <div className="form-logoin has-text-left">
                      <div className="filds">
                        <label>{I18n.get("E-mail")}</label>
                        <input
                          onChange={this.changeEmail}
                          className="form-input"
                          type="email"
                          placeholder=""
                          autoFocus=""
                        />
                      </div>

                      <ErrorPane error={this.state.task.error}></ErrorPane>

                      <SubmitButtonField
                        label={I18n.get("Enviar código")}
                        className="button bt-bpool is-fullwidth"
                        onClick={this.submitForm.bind(this)}
                        task={this.state.task}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </section>
        </div>
      </LandingPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
