import get_project from "./get";
import get_types from "./get_types";
import get_tmarket from "./get_tmarket";

import get_project_production from "./get_project_production";

import create_project from "./create-project";
import delete_project from "./delete-project";
import update_project from "./update-project";
import update_project_status from "./update-project-status";

import reset from "./reset";

import update_briefing from "./update-briefing";
import add_asset_itens from "./add-asset-itens";

import upload_file from "./upload-file";
import upload_filev2 from "./upload-file-v2";
import delete_file from "./delete-file";
import delete_filev2 from "./delete-file-v2";

import create_checkpoint from "./create-checkpoint";
import delete_checkpoint from "./delete-checkpoint";
import update_checkpoint from "./update-checkpoint";
import update_checkpoint_status from "./update-checkpoint-status";

import update_checkpoint_change from "./update-checkpoint-change";
import update_checkpoint_change_status from "./update-checkpoint-change-status";

import update_definitions from "./update-definitions";
import update_nfse from "./update-nfse";

import end from "./end";
import checkout from "./checkout";
import checkout2 from "./checkout-partner";
import checkoutv2 from "./checkout-partner-v2";
import cancel from "./cancel";

import update_custom_event from "./update-custom-event";

import update_bpitch from "./update-bpitch";
import upload_bpitch_file from "./upload-bpitch-file";
import delete_bpitch_file from "./delete-bpitch-file";
import update_bpitch_partner from "./update-bpitch-partner";

import update_meeting from "./update-meeting";
import create_meeting from "./create-meeting";
import create_meeting_invite from "./create-meeting-invite";
import delete_meeting from "./delete-meeting";

import get_partners from "./get_partners";

import create_event_quotation_asset from "./create-event-quotation-asset";
import delete_event_quotation_asset from "./delete-event-quotation-asset";
import update_event_quotation_asset from "./update-event-quotation-asset";
import create_event_quotation_item from "./create-event-quotation-item";
import delete_event_quotation_item from "./delete-event-quotation-item";

import create_event_quotation_item_comment from "./create-event-quotation-item-comment";
import delete_event_quotation_item_comment from "./delete-event-quotation-item-comment";
import update_event_quotation_item_status from "./update-event-quotation-item-status";
import send_event_quotation_item_notification from "./send-event-quotation-item-notification";

import upload_event_quotation_file from "./upload-event-quotation-file";
import upload_event_quotation_file2 from "./upload-event-quotation-file2";
import delete_event_quotation_file from "./delete-event-quotation-file";
import update_event_quotation_tax from "./update-event-quotation-tax";
import create_event_quotation_milestone from "./create-event-quotation-milestone";
import delete_event_quotation_milestone from "./delete-event-quotation-milestone";

import upload_milestone from "./upload_file_milestone";
import delete_file_milestone from "./delete_file_milestone";
import confirm_milestone from "./confirmMilestone";

import update_event_quotation from "./update-event-quotation";
import update_event_quotation_status from "./update-event-quotation-status";

import update_event_partners from "./update-event-partners";
import export_event_quotation from "./export-event-quotation";

import create_project_billing from "./create-project-billing";
import update_project_billing from "./update-project-billing";
import delete_project_billing from "./delete-project-billing";
import upload_billing_file from "./upload-billing-file";
import delete_billing_file from "./delete-billing-file";

import update_terms from "./update-terms";
import confirm_terms from "./confirm-terms";
import update_responsible_terms from "./update-responsible-terms";

import get_preferred_partners from "./get_preferred_partners";

import send_partner_message from "./send-partner-message";
import send_user_message from "./send-user-message";

import create_question from "./create-question";
import delete_question from "./delete-question";
import update_question from "./update-question";

import get_partner_responsible from "./get_partner_responsible";
import update_partner_responsible from "./update_partner_responsible";

import create_milistones from "./create_milestones";
import get_milistones from "./get_milestones";
import delete_milistones from "./delete_milestones";
import update_milestones_mensal from "./update_milestones_mensal";

import send_feedback from "./feedback";

import delivery from "./delivery_milestone";

import delete_extra from "./delete_extra";

import create_partner_contact from "./create-partner-contact";
import delete_partner_contact from "./delete-partner-contact";

import update_commission from "./update-commission";

import create_universal_quotation_section from "./create-universal-quotation-section";
import update_universal_quotation_section from "./update-universal-quotation-section";
import delete_universal_quotation_section from "./delete-universal-quotation-section";
import create_universal_quotation_asset from "./create-universal-quotation-asset";
import update_universal_quotation_asset from "./update-universal-quotation-asset";
import delete_universal_quotation_asset from "./delete-universal-quotation-asset";
import create_universal_quotation_item from "./create-universal-quotation-item";
import delete_universal_quotation_item from "./delete-universal-quotation-item";
import create_universal_quotation_generic_item from "./create-universal-quotation-generic-item";
import delete_universal_quotation_generic_item from "./delete-universal-quotation-generic-item";
import create_universal_quotation_perc_item from "./create-universal-quotation-perc-item";
import delete_universal_quotation_perc_item from "./delete-universal-quotation-perc-item";
import create_universal_quotation_hour_item from "./create-universal-quotation-hour-item";
import delete_universal_quotation_hour_item from "./delete-universal-quotation-hour-item";
import update_universal_quotation from "./update-universal-quotation";
import update_universal_quotation_tax from "./update-universal-quotation-tax";
import update_universal_quotation_status from "./update-universal-quotation-status";
import create_universal_quotation_milestone from "./create-universal-quotation-milestone";
import delete_universal_quotation_milestone from "./delete-universal-quotation-milestone";
import upload_universal_quotation_file from "./upload-universal-quotation-file";
import delete_universal_quotation_file from "./delete-universal-quotation-file";
import export_universal_quotation from "./export-universal-quotation";
import create_universal_quotation_item_comment from "./create-universal-quotation-item-comment";
import delete_universal_quotation_item_comment from "./delete-universal-quotation-item-comment";
import update_universal_quotation_item_status from "./update-universal-quotation-item-status";
import send_universal_quotation_item_notification from "./send-universal-quotation-item-notification";
import update_po from "./update_po";
import projectThreasholdAuthorization from "./update_threasholdAuthorization";
import update_global from "./update_global";
import api_get_diversity from "./get_diversity_v2";
import api_update_project_currency from "./update_project_currency";

import get_bundles from "./get_bundles";
import get_assets from "./get_assets";
import get_assets_combo from "./get_assets_combo";

export const PROJECT_RESPONSIBLE_GET = "project_responsible_get";
export const PROJECT_RESPONSIBLE_UPDATE = "project_responsible_update";

export const PROJECT_GET = "project_get";
export const PROJECT_CREATE = "project_create";
export const PROJECT_DELETE = "project_delete";
export const PROJECT_UPDATE = "project_update";
export const PROJECT_RESET = "project_reset";

export const PROJECT_UPDATE_NFSE = "project_update_nfse";
export const PROJECT_GET_PRODUCTION = "get_project_production";

export const PROJECT_FEEDBACK = "project_feedback ";

export const PROJECT_INDEMNITY = "project_indemnity ";
export const PROJECT_ASSETS_GET = "project_assets";
export const PROJECT_CURRENCY_UPDATE = "update_project_currency";


export const PROJECT_API = {
  get: get_project,
  create: create_project,
  update: update_project,
  delete: delete_project,
  update_project_status: update_project_status,
  reset: reset,
  get_project_production: get_project_production,
  update_briefing: update_briefing,
  upload_file: upload_file,
  upload_filev2: upload_filev2,
  delete_file: delete_file,
  delete_filev2: delete_filev2,
  create_checkpoint: create_checkpoint,
  delete_checkpoint: delete_checkpoint,
  update_checkpoint: update_checkpoint,
  update_checkpoint_status: update_checkpoint_status,
  update_definitions: update_definitions,
  update_checkpoint_change: update_checkpoint_change,
  update_checkpoint_change_status: update_checkpoint_change_status,
  checkout: checkout,
  checkout2: checkout2,
  checkoutv2: checkoutv2,
  end: end,
  update_nfse: update_nfse,
  get_partners: get_partners,
  update_custom_event: update_custom_event,
  create_event_quotation_item: create_event_quotation_item,
  delete_event_quotation_item: delete_event_quotation_item,
  create_event_quotation_item_comment: create_event_quotation_item_comment,
  delete_event_quotation_item_comment: delete_event_quotation_item_comment,
  update_event_quotation_item_status: update_event_quotation_item_status,
  send_event_quotation_item_notification:
    send_event_quotation_item_notification,
  upload_event_quotation_file: upload_event_quotation_file,
  upload_event_quotation_file2: upload_event_quotation_file2,
  delete_event_quotation_file: delete_event_quotation_file,
  update_event_quotation_tax: update_event_quotation_tax,
  create_event_quotation_asset: create_event_quotation_asset,
  delete_event_quotation_asset: delete_event_quotation_asset,
  update_event_quotation_asset: update_event_quotation_asset,
  create_event_quotation_milestone: create_event_quotation_milestone,
  delete_event_quotation_milestone: delete_event_quotation_milestone,
  update_event_quotation: update_event_quotation,
  update_event_partners: update_event_partners,
  update_event_quotation_status: update_event_quotation_status,
  export_event_quotation: export_event_quotation,
  create_project_billing: create_project_billing,
  update_project_billing: update_project_billing,
  delete_project_billing: delete_project_billing,
  upload_billing_file: upload_billing_file,
  delete_billing_file: delete_billing_file,
  cancel: cancel,
  update_terms: update_terms,
  confirm_terms: confirm_terms,
  update_responsible_terms: update_responsible_terms,
  get_preferred_partners: get_preferred_partners,
  send_partner_message: send_partner_message,
  send_user_message: send_user_message,
  create_question: create_question,
  delete_question: delete_question,
  update_question: update_question,
  get_partner_responsible: get_partner_responsible,
  update_partner_responsible: update_partner_responsible,
  create_milistones: create_milistones,
  delete_milistones: delete_milistones,
  get_milistones: get_milistones,
  update_milestones_mensal: update_milestones_mensal,
  send_feedback: send_feedback,
  delivery: delivery,
  update_bpitch: update_bpitch,
  upload_bpitch_file: upload_bpitch_file,
  delete_bpitch_file: delete_bpitch_file,
  update_bpitch_partner: update_bpitch_partner,
  delete_extra: delete_extra,
  create_partner_contact: create_partner_contact,
  delete_partner_contact: delete_partner_contact,
  update_commission: update_commission,
  create_meeting: create_meeting,
  update_meeting: update_meeting,
  delete_meeting: delete_meeting,
  create_meeting_invite: create_meeting_invite,
  create_universal_quotation_section: create_universal_quotation_section,
  update_universal_quotation_section: update_universal_quotation_section,
  delete_universal_quotation_section: delete_universal_quotation_section,
  create_universal_quotation_asset: create_universal_quotation_asset,
  update_universal_quotation_asset: update_universal_quotation_asset,
  delete_universal_quotation_asset: delete_universal_quotation_asset,
  create_universal_quotation_item: create_universal_quotation_item,
  delete_universal_quotation_item: delete_universal_quotation_item,
  create_universal_quotation_generic_item:
    create_universal_quotation_generic_item,
  delete_universal_quotation_generic_item:
    delete_universal_quotation_generic_item,
  create_universal_quotation_perc_item: create_universal_quotation_perc_item,
  delete_universal_quotation_perc_item: delete_universal_quotation_perc_item,
  create_universal_quotation_hour_item: create_universal_quotation_hour_item,
  delete_universal_quotation_hour_item: delete_universal_quotation_hour_item,
  update_universal_quotation: update_universal_quotation,
  update_universal_quotation_tax: update_universal_quotation_tax,
  update_universal_quotation_status: update_universal_quotation_status,
  create_universal_quotation_milestone: create_universal_quotation_milestone,
  delete_universal_quotation_milestone: delete_universal_quotation_milestone,
  upload_universal_quotation_file: upload_universal_quotation_file,
  delete_universal_quotation_file: delete_universal_quotation_file,
  export_universal_quotation: export_universal_quotation,
  create_universal_quotation_item_comment:
    create_universal_quotation_item_comment,
  delete_universal_quotation_item_comment:
    delete_universal_quotation_item_comment,
  update_universal_quotation_item_status:
    update_universal_quotation_item_status,
  send_universal_quotation_item_notification:
    send_universal_quotation_item_notification,
  upload_milestone: upload_milestone,
  delete_file_milestone: delete_file_milestone,
  confirm_milestone: confirm_milestone,
  get_bundles: get_bundles,
  get_assets: get_assets,
  get_types: get_types,
  get_tmarket: get_tmarket,
  update_po: update_po,
  add_asset_itens: add_asset_itens,
  update_global: update_global,
  get_diversity: api_get_diversity,  
  projectThreasholdAuthorization: projectThreasholdAuthorization,
  refreshProject: api_update_project_currency,
  get_assets_combo: get_assets_combo

};
