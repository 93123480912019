import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { TitleMiddleLine } from '../../components/Titles';
import constants from "../../../constants";
import styled from 'styled-components';

import {
    Row,
    Col,
    Button,
    InputNumber,
    Checkbox,
    Input,
    Select,
    Form
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
      margin-right: 20px;
  }
`;


class FormAddReference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formState: [],
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            this.setState({
                formState: values,
            })

            if (!err) {

                let relacao = values.relation ? JSON.parse(values.relation) : null;

                let data = {
                    ...values,
                    relation: relacao,
                    partnerId: this.props.partnerId,
                }

                if (this.props.item) {
                    data.id = this.props.item.id;
                    this.props.update(data);

                } else {
                    this.props.action(data);
                }

                // this.props.action(data);
            }
        });

    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;

        const { partner } = this.props;


        const ListCategorie = this.props.sectorsFlat && this.props.sectorsFlat.sort((a, b) => (a.label > b.label) - (a.label < b.label));

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={8}>
                        <Form.Item label={I18n.get("Nome")}>
                            {getFieldDecorator('name', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.name ? this.props.item.name : null
                            })(
                                <Input

                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={8}>
                        <Form.Item label={I18n.get("E-mail")}>
                            {getFieldDecorator('email', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.email ? this.props.item.email : null
                            })(
                                <Input

                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={8}>
                        <Form.Item label={I18n.get("LinkedIn")}>
                            {getFieldDecorator('linkedInUrl', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.linkedInUrl ? this.props.item.linkedInUrl : null
                            })(
                                <Input

                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <Form.Item label={I18n.get("Ano")}>
                            {getFieldDecorator('year', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.year ? this.props.item.year : null
                            })(
                                <InputNumber />
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={8}>
                        <Form.Item label={I18n.get("Relação prof")}>
                            {getFieldDecorator('relation', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.relation ? JSON.stringify(this.props.item.relation) : null
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                >
                                    {this.props.partnerReferenceRelation && this.props.partnerReferenceRelation.map((item) => {
                                        return <Option key={item.code} value={JSON.stringify(item)}>{item.label}</Option>
                                    })}

                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <RowSubmitModal>
                    <Col sm={24}>
                        <Button shape="round" onClick={this.props.onCancel}>{I18n.get("Cancelar")}</Button>
                        <Button type="primary" htmlType="submit" shape="round" onClick={this.handleSubmit}>
                            {this.props.item ? I18n.get("Atualizar") : I18n.get("Adicionar")}
                        </Button>
                    </Col>
                </RowSubmitModal>
            </Form>
        );
    }
}

const MainFormAddReference = Form.create({})(FormAddReference);

export default MainFormAddReference;
