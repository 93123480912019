import React, { Fragment } from "react";
import { I18n } from "aws-amplify";
import moment from "moment";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  Icon,
  Table,
  Tag,
  DatePicker,
  Drawer,
  Skeleton,
  Button,
  Checkbox,
} from "antd";
import { Redirect } from "react-router-dom";

import FilterSaving from "./filterSaving";

import DataSet from "@antv/data-set";
import { Chart, Geom, Axis, Tooltip, Coord, Label, Guide } from "bizcharts";

import { Task } from "../../../utils/task";

import { formatCurrency, formatMoneyGraph } from "../../../utils/helpers";

import { REPORT_API } from "../../../actions/report/actions";

import styled from "styled-components";

import TableSaving from "./tableSaving";
import TableSavingAsset from "./tableSavingAsset";
import TableSavingSpec from "./tableSavingSpec";

import { TableSavingProduct } from "./TableSavingProduct";

const { RangePicker } = DatePicker;

const TableCard = styled(Table)`
  margin-top: 14px;

  table {
    border: solid 1px #ccc !important;

    tr {
      cursor: pointer;
    }

    tr:nth-child(odd) td {
      background: #f2f2f2;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 6px 16px;
  }
`;

const DrawerBp = styled(Drawer)`
  top: 60px;

  h2 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;
    position: relative;

    &::after {
      width: 60px;
      content: "";
      border-bottom: solid 5px #000;
      display: block;
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }

  .description {
    font-size: 13px;
    line-height: 20px;
  }

  .total {
    margin: 30px 0;
    small {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .boxtotal {
      font-size: 22px;
      color: #fff;
      background: #000;
      padding: 5px 10px;
      strong {
        color: #fff;
      }
    }
  }

  .TableBox {
    margin-top: 50px;
  }
`;

const CardDisplay = styled(Card)`
  margin-bottom: 20px;
  height: 100%;

  .DescriptionPage {
    font-size: 14px;
  }

  .LabelPeriodo {
    font-size: 12px;
    font-weight: bold;
  }

  &.TableBox {
    min-height: 335px;
  }

  .GraphCard {
    opacity: 1;
    transition: opacity 2s ease;
  }

  .hidden {
    opacity: 0;
  }

  .show {
    opacity: 1;
  }

  .LabelCard {
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: -10px;
  }

  h2 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-left: 15px;
    border-left: solid 5px #000;
    span {
      display: block;
      color: #999;
      font-size: 11px;
    }

    &.title-span {
      line-height: 18px;
    }
  }

  .NumberCard {
    display: block;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
  }

  .DescriptionCard {
    span {
      font-weight: bold;
    }
    border-top: solid 1px #b5b5b5;
    padding-top: 10px;
    margin-top: 15px;
    font-size: 12px;
  }

  .BtnCard {
    border-top: solid 1px #ccc;
    padding-top: 15px;
    margin-top: 10px;
    display: none;
  }

  .LoadingCard {
    text-align: center;
    margin: 36px 0 33px;

    .noDate {
      display: block;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }

    i {
      font-size: 50px;
      opacity: 0.4;
    }

    &.LabelG {
      position: absolute;
      top: 38%;
      left: 0;
      width: 100%;

      &.noDateView {
        top: 32%;
      }
    }
  }
`;

const Miolo = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: calc(100% - 48px);
  font-size: 20px;

  h3 {
    font-size: 18px;
  }
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_data_export: (data) => {
      return dispatch(REPORT_API.get_data_export(data));
    },
    get_data_saving: (data) => {
      dispatch(REPORT_API.get_data_saving(data));
    },
    get_filter_saving: () => {
      dispatch(REPORT_API.get_filter_saving());
    },
    reset_itens_dash: (data) => {
      dispatch(REPORT_API.reset_itens());
    },
    get_item_saving: (data) => {
      return dispatch(REPORT_API.get_item_saving(data));
    },
    get_item_asset: (data) => {
      return dispatch(REPORT_API.get_item_asset(data));
    },
    get_item_spec: (data) => {
      return dispatch(REPORT_API.get_item_spec(data));
    },
  };
};

const visitData = [];
const beginDay = new Date().getTime();
for (let i = 0; i < 20; i += 1) {
  visitData.push({
    x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    y: Math.floor(Math.random() * 100) + 10,
  });
}

const today = new Date();
const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
const firstDayLastMonth = new Date(
  lastDayLastMonth.getFullYear(),
  lastDayLastMonth.getMonth(),
  1
);

const currentDate = moment(lastDayLastMonth).format("YYYY-MM-DD");
const dayDate = moment(firstDayLastMonth).format("YYYY-MM-DD");

class ContainerSaving extends React.Component {
  constructor(props) {
    super(props);

    this.partner_chart = React.createRef();

    this.state = {
      dataForm: {
        fromDate: dayDate,
        toDate: currentDate,
        status: "-1",
        brand: "-1",
      },
      dataView: null,
      typeView: null,
      titleView: "",
      visible: false,
    };
  }

  onClose = () => {
    this.setState({
      visible: false,
      dataView: null,
      titleView: "",
    });

    // this.props.delete_itens_dash();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState) return;
    if (!prevProps) return;

    if (this.props.report && this.props.report.task != prevState.task) {
      this.setState({ task: this.props.report.task });
    }
  }

  componentDidMount() {
    const data = {
      dataFrom: dayDate,
      dataTo: currentDate,
    };
    this.props.get_filter_saving();
    this.props.get_data_saving(data);
  }

  componentWillUnmount() {
    this.props.reset_itens_dash();
  }

  renderDataTable(obj) {
    const newObj = obj.projects.map((i) => {
      return {
        key: i.projectId,
        marca: i.brand,
        nome: i.name,
        po: i.po,
        preco: i.price,
        inicio: i.start,
        fim: i.end ? i.end : "--",
        status: i.statusId,
      };
    });

    return newObj;
  }

  renderCallBack(arr) {
    if (arr == undefined) {
      return (
        <p className="LoadingCard LabelG">
          <Icon type="loading" />
        </p>
      );
    } else if (arr && arr.length == 0) {
      return (
        <p className="LoadingCard LabelG noDateView">
          <Icon type="inbox" />
          <span className="noDate">{I18n.get("Sem informações")}</span>
        </p>
      );
    }
  }

  getData = (d) => {
    let data = {
      ...d,
      fromDate: d.dataFrom || dayDate,
      toDate: d.dataTo || currentDate,
    };

    this.setState({
      dataForm: data,
    });

    // this.props.get_filter_saving();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.get_data_saving(data);
    });
  };

  getDataToExport = (d) => {
    let data = {
      ...d,
      fromDate: d.dataFrom || dayDate,
      toDate: d.dataTo || currentDate,
    };

    this.setState({
      dataForm: data,
    });

    // this.props.get_filter_saving();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.get_data_export(data).then(({ task, errors, exportUrl }) => {
        if (task.result) {
          window.location = exportUrl;

          this.setState({ task: task });
        }
      });
    });
  };

  showDrawer = (infos, type) => {
    let data = {};

    this.setState({
      visible: true,
    });

    switch (type) {
      case "time":
        data = {
          ...this.state.dataForm,
          month: infos._origin.month,
          year: infos._origin.year,
          status: this.state.checkedValues || this.state.dataForm.status,
        };

        this.props.get_item_saving(data).then((response) => {
          if (response) {
            this.setState({
              dataView: this.props.report.itemSaving,
              typeView: "time",
              titleView: `${I18n.get("Savings referente a")} ${
                infos._origin.name
              } de ${infos._origin.year}`,
            });
          }
        });

        break;

      case "asset":
        data = {
          ...this.state.dataForm,
          status: this.state.checkedValues || this.state.dataForm.status,
          assetCode: infos._origin.assetCode,
        };

        this.props.get_item_asset(data).then((response) => {
          if (response) {
            this.setState({
              dataView: this.props.report.itemSaving,
              typeView: "asset",
              titleView: `${I18n.get("Spending referente a")} ${
                infos._origin.name
              }`,
            });
          }
        });

        break;
      case "spec":
        data = {
          ...this.state.dataForm,
          status: this.state.checkedValues || this.state.dataForm.status,
          code: infos._origin.code,
        };

        this.props.get_item_spec(data).then((response) => {
          if (response) {
            this.setState({
              dataView: this.props.report.itemSaving,
              typeView: "spec",
              titleView: `${I18n.get("Spending referente a")} ${
                infos._origin.name
              }`,
            });
          }
        });

        break;

      default:
        break;
    }
  };

  render() {
    const { filterSaving, dataSaving } = this.props.report;
    const { typeDrawer, redirectProject, redirectId, url } = this.state;

    const { DataView } = DataSet;

    let valor = 0;

    let dataTable = null;
    let DrawerInfos = null;

    const cols = {
      percent: {
        formatter: (val) => {
          val = val * 100 + "%";
          return val;
        },
      },
    };

    const dataSaveByOverTime = dataSaving?.saveByOverTime?.savingAsset.map(
      (i) => {
        return {
          name: i.monthText,
          total: i.totalSaving,
          month: i.month,
          year: i.year,
        };
      }
    );

    const dataSavingDescription =
      dataSaving?.savingDescription?.savingAsset.map((i) => {
        return {
          name: i.name,
          total: i.totalBPoolPaidRate,
          month: i.month,
          assetCode: i.assetCode,
        };
      });

    const dataSpecSaving = dataSaving?.specSaving?.map((i) => {
      return {
        name: i.name,
        total: i.totalSaving,
        month: i.month,
        code: i.code,
      };
    });

    if (redirectProject) {
      if (url) {
        return <Redirect to={url} />;
      }
      return <Redirect to={`/projetos/${redirectId}/resumo`} />;
    }

    return (
      <Fragment>
        <Row>
          <Col md={24}>
            <CardDisplay>
              <FilterSaving
                filters={filterSaving}
                action={this.getData}
                export={this.getDataToExport}
                loading={this.state?.task?.busy}
              />
            </CardDisplay>
          </Col>
        </Row>
        <Row>
          <Col md={24}>

            {dataSaveByOverTime?.length > 0 && 
                <CardDisplay>
                  <h2>{I18n.get("Spending over Time")}</h2>
                  <Chart
                    height={300}
                    data={dataSaveByOverTime}
                    scale={cols}
                    forceFit
                    className={`GraphCard ${
                      dataSaveByOverTime && dataSaveByOverTime.length > 0
                        ? "show"
                        : "hidden"
                    }`}
                    onPlotClick={(ev) => {
                      if (ev.data) {
                        const { data } = ev;

                        this.showDrawer(data, "time");
                      }
                    }}
                  >
                    <Axis name="name" />
                    <Axis
                      name="total"
                      label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                    />
                    <Tooltip
                      crosshairs={{
                        type: "y",
                      }}
                    />
                    <Geom
                      type="interval"
                      position="name*total"
                      color="#4f4f4f"
                      tooltip={[
                        "name*total",
                        (name, total) => {
                          return {
                            // Custom tooltip on the title display and so on.
                            name: "Total:",
                            title: name,
                            value: "R$ " + formatMoneyGraph(total),
                          };
                        },
                      ]}
                    >
                      <Label
                        content="total"
                        formatter={(total) => {
                          return formatMoneyGraph(total);
                        }}
                      />
                    </Geom>
                  </Chart>

              {this.renderCallBack(dataSaveByOverTime)}
            
            </CardDisplay>}


          </Col>

          <Col md={24}>


            {dataSaving?.brandSaving?.length > 0 &&
                <CardDisplay>
                  <h2>
                    {I18n.get("Spending")} / {I18n.get("Projetos")}{" "}
                    {this.props?.client?.customization?.billingCurrency?.symbolOnly && (
                      <>
                        {" "}
                        / {this.props?.client?.customization.billingCurrency.symbolOnly}
                      </>
                    )}
                  </h2>

                  {TableSavingProduct(dataSaving?.brandSaving)}

                </CardDisplay>}


          </Col>
        </Row>

        <Row>
          <Col md={24}>


            {dataSaving?.specSaving?.length > 0 &&
              <CardDisplay>
                <h2>{I18n.get("Spending by Asset")}</h2>
                <TableSaving {...this.props} data={dataSaving?.specSaving} />
              </CardDisplay>}


          </Col>
        </Row>

        <DrawerBp
          title={this.state.titleView}
          width="70%"
          onClose={this.onClose}
          visible={this.state.visible}
          destroyOnClose={true}
          className="DrawerBp"
        >
          {this.state.dataView && this.state.typeView == "time" && (
            <Fragment>
              <TableSaving {...this.props} data={this.state.dataView || []} />
            </Fragment>
          )}
          {this.state.dataView && this.state.typeView == "asset" && (
            <Fragment>
              <TableSavingAsset
                {...this.props}
                data={this.state.dataView || []}
              />
            </Fragment>
          )}
          {this.state.dataView && this.state.typeView == "spec" && (
            <Fragment>
              <TableSavingSpec
                {...this.props}
                data={this.state.dataView || []}
              />
            </Fragment>
          )}
          {!this.state.dataView && (
            <Fragment>
              <Skeleton active />
            </Fragment>
          )}
        </DrawerBp>
      </Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContainerSaving);
