import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { PARTNER_API } from '../../actions/partner/actions';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import TextField from '../form/text-field';
import TextAreaField from '../form/textarea-field';

const mapStateToProps = state => {
    return state.partner;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(PARTNER_API.update_video(data));
        }
    };
};

class VideoForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            form: {
                verificationToken: props.match.params.verificationToken,
                videoUrl: {
                    label: I18n.get("Link do vídeo"),
                    value: props.videoUrl,
                    onChange: this.onChangeForm.bind(this, 'videoUrl'),
                    error: props.errors.videoUrl,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'videoUrl'),
                },
                videoComment: {
                    label: I18n.get("Observações sobre como acessar"),
                    value: props.videoComment,
                    onChange: this.onChangeForm.bind(this, 'videoComment'),
                    error: props.errors.videoComment,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'videoComment'),
                },
            },
            task: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            this.props.onEditingChange(false);

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);
        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    getData() {

        var data = FormUtils.getData(this.state);

        return data;
    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function() {
            this.props.save(this.getData());
        });
    }
    render() {
        return (<form onSubmit={this.submitForm.bind(this)}>

            <TextField {...this.state.form.videoUrl} />
            <TextAreaField {...this.state.form.videoComment} />

        </form>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(VideoForm);
