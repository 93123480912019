import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { message, Icon, Tooltip } from "antd";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import IcoInfo from "../../svgs/info.svg";

import * as S from "./styles";

const FILE_UPLOAD_ERROR = "Insert allowed files (JPG, PNG or PDF)";

export const OutlineUpload = ({
  id,
  fileToUpload,
  fileDefault,
  removeFileDefault,
  label,
  helperText,
  file,
  hideIcon,
  error,
  loading,
}) => {
  const [fileUploadError, setFileUploadError] = useState("");

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleFileUpload = (file) => {
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf"
    ) {
      setFileUploadError("");
      fileToUpload(id, file);
    } else {
      setFileUploadError(FILE_UPLOAD_ERROR);
      fileToUpload(id, null);
      message.error(I18n.get(FILE_UPLOAD_ERROR));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const removeFile = () => {
    fileToUpload(id, null);
  };

  return (
    <>
      <S.WrapperUploadCertified>
        <S.ContainerUploadCertified
          className={fileUploadError ? "error" : ""}
          onDrop={handleFileDrop}
          onDragOver={handleDragOver}
        >
          <S.ContentUploadCertified>
            <S.IconAndTextUploadCertified>
              {loading && (
                <S.IconUploadCertified>
                  <LoadingOutlined className={fileUploadError ? "error" : ""} />
                </S.IconUploadCertified>
              )}
              {!hideIcon && !loading && (
                <S.IconUploadCertified>
                  <UploadOutlined className={fileUploadError ? "error" : ""} />
                </S.IconUploadCertified>
              )}
              <S.TextUploadCertified>
                {fileDefault && !fileDefault?.url && !fileUploadError ? (
                  <S.FileUploaded>
                    <span className="uploaded">{fileDefault?.name}</span>
                    <button onClick={removeFile}>
                      <Icon type="delete" />
                      {I18n.get("Delete")}
                    </button>
                  </S.FileUploaded>
                ) : fileDefault && fileDefault.url ? (
                  <S.FileUploaded>
                    <span className="uploaded">{fileDefault.name}</span>
                    <a href={fileDefault.url} target="_blank">
                      <button className="view">
                        <Icon type="eye" />
                        {I18n.get("Ver")}
                      </button>
                    </a>

                    <button onClick={removeFileDefault}>
                      <Icon type="delete" />
                      {I18n.get("Delete")}
                    </button>
                  </S.FileUploaded>
                ) : (
                  <>
                    <strong className={fileUploadError ? "error" : ""}>
                      {label || I18n.get("Select the file")}
                    </strong>
                    <span className={fileUploadError ? "error" : ""}>
                      {fileUploadError
                        ? I18n.get(fileUploadError)
                        : I18n.get("JPG, PNG or PDF")}
                    </span>
                  </>
                )}
              </S.TextUploadCertified>
            </S.IconAndTextUploadCertified>
            {helperText && (
              <S.IconInfo>
                <Tooltip
                  placement="top"
                  autoAdjustOverflow={true}
                  title={helperText}
                  overlayStyle={{
                    width: "400px",
                    maxWidth: "400px",
                  }}
                >
                  <img src={IcoInfo} alt="Info" />
                </Tooltip>
              </S.IconInfo>
            )}
            <S.ButtonUploadCertified>
              <label htmlFor={id}>{I18n.get("Select file")}</label>
              <input
                type="file"
                name={id}
                id={id}
                onChange={(e) => handleFileUpload(e.target.files[0])}
              />
            </S.ButtonUploadCertified>
          </S.ContentUploadCertified>
        </S.ContainerUploadCertified>
      </S.WrapperUploadCertified>
      {error ? <S.InfoError>{error}</S.InfoError> : null}
    </>
  );
};
