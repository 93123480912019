import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { BP_API } from "../../actions/bp/actions";

import LoggedPage from "../page-templates/loggedAdmin";
import Loading from "../pages/loading";

import PartnerDetailsView from "./_partner-details";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_partners: (data) => {
      dispatch(BP_API.get_partners(data));
    },
    get_partner: (id) => {
      return dispatch(BP_API.get_partner(id));
    },
    update_market: (data) => {
      dispatch(BP_API.update_market(data));
    },
    getClients: (id) => {
      return dispatch(BP_API.get_clients_add(id));
    },
    updateStats: (id) => {
      return dispatch(BP_API.update_stats_partner(id));
    },
    updateClients: (data) => {
      return dispatch(BP_API.update_client_add(data));
    },
    update_master: (data) => {
      return dispatch(BP_API.update_master(data));
    },
  };
};

class PartnerDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      partnerId: props.match.params.partnerId,
      loading: false,
    };
  }

  componentDidMount() {
    this.props.getClients(this.state.partnerId);
    this.props.get_partner(this.state.partnerId).then((response) => {
      if (response) {
        this.setState({ loading: true });
      }
    });
  }

  componentWillMount() {
    this.setState({ loading: false });
  }

  render() {
    if (!this.state.loading) return <Loading />;
    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/bpool/parceiros" className="is-black">
                {I18n.get("Parceiros")}
              </NavLink>
            </li>
            <li className="is-active">
              <a href="#">
                {this.props.bp.partner && this.props.bp.partner.name}
              </a>
            </li>
          </ul>
        </nav>

        <div className="columns">
          <div className="column is-12">
            <PartnerDetailsView {...this.props} />
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDetails);
