import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fafafa;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const Content = styled.div`
  padding: 15px 10px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }

  button {
    cursor: pointer;
    font-size: 12px;
    background: transparent;
    border: none;

    i {
      margin-right: 5px;
    }
  }
`;

export const ItemsList = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
`;

export const Count = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;

  span {
    font-weight: 700;
    font-size: 14px;
  }
`;

export const Description = styled.div`
  margin-left: 10px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
`;

export const ItemDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemName = styled.div`
  font-size: 14px;
  line-height: 28px;
`;

export const ItemDescription = styled.div`
  font-size: 13px;
  line-height: 28px;
  display: flex;

  p {
    display: -webkit-box;
    max-width: 100%;
    height: 2.8em;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.4;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tp {
    margin-left: 10px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d9d9d9;
    cursor: pointer;

    span {
      cursor: pointer;
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

export const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const DeleteIconItem = styled.button`
  cursor: pointer;
  margin-top: 3px;
  background-color: red;
  color: #fff;
  font-size: 12px;
  border: none;
  padding: 0px 2px;
  border-radius: 4px;
  height: 20px;
  margin-right: 10px;
`;

export const TitleModal = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const TitleTotal = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
  }
`;

export const ListAssets = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
`;

export const ItemListAsset = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 15px 0;
`;

export const LeftAsset = styled.div`
  width: calc(100% - 60px);
`;

export const LabelAndValue = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelAsset = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #1883ff;
`;

export const ValueAsset = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #1883ff;
`;

export const DescriptionAsset = styled.div`
  display: -webkit-box;
  max-width: 100%;
  height: 28px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PlusContent = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;

  button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #1883ff;
    color: #fff;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SeparatorVertical = styled.div`
  width: 1px;
  height: calc(500px + 90px);
  background-color: #e8e8e8;
`;

export const ListAssetsAdded = styled.div``;

export const SearchArea = styled.div`
  margin: 10px 0;
`;

export const ChangedItems = styled.div`
  margin-right: 10px;

  p {
    color: #ff0000;
    font-size: 14px;
  }
`;

export const LoadingRemove = styled.div`
  height: 28px;
  p {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const ButtonOtherPack = styled.button`
  font-weight: 600;
  color: #1883ff;
`;
