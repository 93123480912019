import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import StarReview from '../../form/star-review';

import SubmitButtonField from '../../form/submit-button-field';

import { ClientProjectExtraStatus } from '../../../model';


class POForm extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            fields: {
            },
            form: {
                ratings: []
            },
            errors: {},
            task: {},
            upload_task: {}
        }
    }


    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeReview(index, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            const nextState = Object.assign({}, prevState);

            nextState.form.ratings[index] = this.props.bp.clientProjectCheckpointRatings[index];
            nextState.form.ratings[index].value = value;

            return nextState;

        });
    }

    onSubmitForm(e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ task: submit_task });

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            ...this.state.form,
        };

        this.props.update_status(data)
            .then(({ task, errors }) => {

                this.setState({ task: task, errors: errors });

                if (task.result) {
                    this.props.history.push(`/projetos/${this.props.match.params.projectId}/extras`);
                }

            });
    }

    render() {
        return (
            <div className="card">

                <div className="card-content">
                    <form>

                        {this.props.bp.clientProjectCheckpointRatings && this.props.bp.clientProjectCheckpointRatings.map((item, index) =>

                            <StarReview
                                key={item.code}
                                id={index}
                                label={item.label}
                                onChange={this.onChangeReview.bind(this)}
                                selected={this.state.form.ratings[index] && this.state.form.ratings[index].value}
                            />

                        )}

                        <br /><br />

                        <br />
                        <br />

                        {(this.props.auth.is_client || this.props.auth.is_client_user) && <SubmitButtonField label={I18n.get("FINALIZAR")}
                            disabled={this.props.status == ClientProjectExtraStatus.Finished}
                            onClick={this.onSubmitForm.bind(this)}
                            task={this.state.task} />}



                    </form>
                </div>
            </div>
        );
    }
}

export default POForm;
