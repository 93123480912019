import React from 'react';

import { I18n } from 'aws-amplify';
import TableAwards from '../../modules/Admin/components/tableAward';

class Awards extends React.Component {

    constructor(props) {

        super();

    }



    render() {


        if (!this.props.bp) return null;
        if (!this.props.bp.partner) return null;

        const items = this.props.bp.partner.awards;

        return (
            <div className="card">
                <div className="card-content">
                    <h3 className="title is-4">{I18n.get("Prêmios")}</h3>

                    <div className="content">

                        <TableAwards
                            data={items}
                            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                            partnerId={this.props.partner && this.props.partner.partnerId}
                            delete={this.props.delAward}
                            edit={this.handleEditAward}
                            specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                            specialisms={this.props.partner.specialisms && this.props.partner.specialisms}
                            viewAdmin={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Awards;