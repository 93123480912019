import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, notification } from "antd";
import { useExtras } from "../../../../../hooks/useExtras/useExtras.hook";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import { getQuotationStatus } from "../../utils/extras";
import { getMessagesOnQuotationBudget } from "../../utils/extrasMessagesBudget";
import Content from "../../../../../components/UI/Content";
import Main from "../../../../../components/UI/Main";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import { H2Border } from "../../../../../components/UI/Titles";
import { TabsProject } from "../../../../../components/UI/TabsProject";
import { Textarea } from "../../../../../components/UI/Textarea";
import { SelectOne } from "../../../../../components/UI/SelectOne";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import Loading from "../../../../../components/UI/Loading";
import { Tabs } from "../../components/Tabs";
import { TableBudget } from "./TableBudget";
import { TableProposal } from "./TableProposal";
import { TableProposalGeneralCosts } from "./TableProposalGeneralCosts";
import { TableProposalResume } from "./TableProposalResume";
import TabsItemsClient from "../../../urls/urlsClient.json";
import TabsItemsClientDefinition from "../../../urls/urlsClientDefinition.json";
import TabsItemsPartner from "../../../urls/urlsPartner.json";

import * as S from "./styles";

const tableColumnsBudget = [
  I18n.get("Description"),
  I18n.get("%"),
  I18n.get("Value"),
];

const tableColumnsProposalClient = [
  I18n.get("Id"),
  I18n.get("Item"),
  I18n.get("Quantity"),
  I18n.get("Value Unit"),
  I18n.get("Value"),
];

const tableColumnsProposalPartner = [
  I18n.get("Id"),
  I18n.get("Item"),
  I18n.get("Quantity"),
  I18n.get("Value Unit"),
  I18n.get("Value"),
  "",
];

const tableColumnsProposalGeneralsCosts = [
  I18n.get("General Costs"),
  I18n.get("%"),
  I18n.get("Value"),
];

const tableColumnsProposalResume = [
  I18n.get("Resume proposal"),
  I18n.get("%"),
  I18n.get("Value"),
];

export const NewExtraBudget = () => {
  let params = useParams();
  let history = useHistory();
  const auth = useSelector((state) => state.auth);
  const {
    getBudgetExtraById,
    postExportToExcel,
    partnerSendToApproval,
    clientSendToApproval,
  } = useExtras();
  const { getProjectById } = useProjects();
  const [quoteHistory, setQuoteHistory] = useState(null);
  const [budgetData, setBudgetData] = useState({});
  const [commercialProposalData, setCommercialProposalData] = useState({});
  const [summaryProposalData, setSummaryProposalData] = useState({});
  const [inputsForm, setInputsForm] = useState({});
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [budgetDetails, setBudgetDetails] = useState({});
  const [quotation, setQuotation] = useState({});
  const [listHistoryBudgets, setListHistoryBudgets] = useState([]);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSendingSecondary, setIsSendingSecondary] = useState(false);
  const [project, setProject] = useState(null);
  const [quotationHistorySelect, setQuotationHistorySelect] = useState({});

  const formatItemsTable = ({ items, bundle }) => {
    if (bundle?.length && items?.length) {
      return [...items, ...bundle];
    } else if (bundle?.length && !items?.length) {
      return [...bundle];
    } else if (!bundle?.length && items?.length) {
      return [...items];
    } else {
      return [];
    }
  };

  const loadInit = async () => {
    setIsLoading(true);
    try {
      const response = await getBudgetExtraById({
        projectId: params?.projectId,
        extraId: params?.extraId,
        isClient: auth?.is_client_or_client_user ? true : false,
      });
      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });

     

      setProject(responseProject?.data);

      const quotationHistories = response?.data?.quotationHistory?.map(
        (item) => {
          const quotationHistoryStatus = getQuotationStatus({ auth, item });
          return {
            code: item?.quotationId,
            label: `${quotationHistoryStatus} ${item?.createdOnFmt}`,
            createdOnFmt: item?.createdOnFmt,
          };
        }
      );

      setListHistoryBudgets(quotationHistories);
      setQuoteHistory(
        quotationHistories?.length ? response?.data?.quotation?.id : null
      );
      setBudgetDetails(response?.data);
      setBudgetData({
        itemsTable: [
          {
            id: 1,
            description: "Budget Projeto",
            fee: "",
            value: response?.data?.quotation?.budget,
          },
          {
            id: 2,
            description: "Platform Fee",
            fee: response?.data?.quotation?.bpCommission?.percentageFmt,
            value: response?.data?.quotation?.bpCommission?.amountFmt,
          },
          {
            id: 3,
            description: "Budget Parceiro",
            fee: "",
            value: response?.data?.quotation?.workingBudgetFmt,
          },
          {
            id: 4,
            description: "Proposta Parceiro",
            fee: "",
            value: response?.data?.quotation?.totalExcludingBPCommissionFmt,
          },
        ],
        balance: response?.data?.quotation?.balance,
        currency: response?.data?.currency,
      });
      setSummaryProposalData({
        itemsTable: [
          {
            id: 1,
            resume: "Proposta Parceiro",
            fee: "",
            value: auth?.is_client_or_client_user
              ? response?.data?.quotation?.totalExcludingBPCommissionFmt
              : response?.data?.quotation?.totalPartnerFmt,
          },
          {
            id: 2,
            resume: "Retenção Plataforma",
            fee: response?.data?.quotation?.bpCommission?.percentageFmt,
            value: response?.data?.quotation?.bpCommission?.amountFmt,
          },
        ],
        balance: response?.data?.quotation?.totalFmt,
        currency: response?.data?.currency,
      });

      const itemsTableCommercialProposal = formatItemsTable({
        items: response?.data?.quotation?.items,
        bundle: response?.data?.quotation?.bundle?.variation?.assets,
      });
      setCommercialProposalData({
        itemsTable: itemsTableCommercialProposal,
        balance: response?.data?.quotation?.totalItensWithAssets,
        currency: response?.data?.currency,
        quotationId: response?.data?.quotation?.quotationId,
        extraType: response?.data?.extraType,
        quotationHistory: response?.data?.quotationHistory,
      });
      setQuotation(response?.data?.quotation);
    } catch (error) {
      console.log("error", error?.response);
    }
    setIsLoading(false);
  };

  const onChangeHistoryBudgets = (handleQuote) => {
    setQuoteHistory(handleQuote);
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const onChangeInputComments = (id, value) => {
    setComments({
      ...comments,
      [id]: value ? value : null,
    });
  };

  const handleBack = () => {
    const urlPartner = `/parceiro/projetos/v2/${params?.projectId}/in-progress/extras`;
    const urlClient = `/cliente/projetos/v2/${params?.projectId}/in-progress/extras`;

    history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
  };

  const handleExportToExcel = async () => {
    setIsLoadingExport(true);

    try {
      const dataSend = {
        extraId: params?.extraId,
        projectId: params?.projectId,
        quotationId: budgetDetails?.quotation?.quotationId,
      };
      const response = await postExportToExcel(dataSend);
      if (response?.data) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.target = "_blank";
        downloadLink.download = `resume-proposal-${budgetDetails?.quotation?.quotationId}.xlsx`;

        downloadLink.click();
        downloadLink.addEventListener("click", () => URL.revokeObjectURL(url));
      }
    } catch (error) {
      notification.error({
        message: I18n.get("Erro ao exportar para Excel"),
        description: I18n.get("Houve algum erro."),
        duration: 3,
      });
    }

    setIsLoadingExport(false);
  };

  const send = async ({ isDraft }) => {
    setIsSending(true);
    try {
      const dataSend = {
        projectId: params?.projectId,
        extraId: params?.extraId,
        quotationId: budgetDetails?.quotation?.quotationId,
        partnerNotes: comments?.commentsPartner,
        partnerScope: inputsForm.description,
        isDraft: isDraft ? true : false,
      };
      await partnerSendToApproval(dataSend);
      if (isDraft) {
        notification.success({
          message: I18n.get("Orçamento salvo com sucesso!"),
          description: I18n.get(
            "O orçamento foi salvo com sucesso e pode ser editado ou enviado para o cliente."
          ),
          duration: 3,
        });
        history.push(
          `/parceiro/projetos/v2/${params?.projectId}/in-progress/extras`
        );
      }
      await loadInit();
    } catch (error) {
      console.log("error", error?.response);
    }
    setIsSending(false);
  };

  const handleApproveOrRecuse = async (approveOrRecuse) => {
    if (!approveOrRecuse) {
      setIsSendingSecondary(true);
    } else {
      setIsSending(true);
    }
    try {
      const dataSend = {
        projectId: params?.projectId,
        extraId: params?.extraId,
        quotationId: budgetDetails?.quotation?.quotationId,
        isApproved: approveOrRecuse,
        clientNotes: comments?.commentsClient,
      };
      await clientSendToApproval(dataSend);
      await loadInit();
    } catch (error) {
      console.log("error", error?.response);
    }
    if (!approveOrRecuse) {
      setIsSendingSecondary(false);
    } else {
      setIsSending(false);
    }
  };

  let tabs =
    project?.status === 7 ? TabsItemsClient : TabsItemsClientDefinition;

  let tabActive = project?.status === 7 ? 5 : 4;

  let disableTabs = [];

  if (!project?.partnerHasSubmittedDefinitions) {
    disableTabs = [3, 4];
  } else if (project?.partnerHasSubmittedDefinitions && project?.status < 6) {
    disableTabs = [3];
  } else {
    disableTabs = [];
  }

  const whatClassBudgetStatusMessage = ({ isClient, quotationData }) => {
    if (isClient) {
      if (quotationData?.isApproved === false) {
        return "resume-declined";
      } else if (quotationData?.isApproved === true) {
        return "resume-approved";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    const quotationHistory = budgetDetails?.quotationHistory?.find(
      (item) => item?.quotationId === quoteHistory
    );
    setQuotationHistorySelect(quotationHistory || {});
  }, [budgetDetails, quoteHistory]);

  useEffect(() => {
    loadInit();
  }, []);


  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>
                {project?.status === 7
                  ? I18n.get("Projeto em andamento")
                  : I18n.get("Definições do Projeto")}
              </H2Border>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <TabsProject
                context={
                  project?.status === 6
                    ? "definitions"
                    : project?.status === 7
                    ? "inProgress"
                    : "finalized"
                }
                page="extras"
                externalLoading={isLoading}
                projectStatus={project?.status}
                partnerHasSubmittedDefinitions={
                  project?.partnerHasSubmittedDefinitions
                }
              />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Extras")}</S.H4>
                      </S.TitleWithIcon>
                    </S.RowCenter>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <Tabs
                      extraId={params?.extraId}
                      disableFinalize={
                        budgetDetails?.billings?.length > 0 ? false : true
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <S.Content>
                      <S.HistoryBudgetsContent>
                        <SelectOne
                          options={listHistoryBudgets}
                          value={quoteHistory}
                          onChange={(value) => onChangeHistoryBudgets(value)}
                          placeholder={I18n.get("Quote history")}
                          defaultActiveFirstOption={
                            listHistoryBudgets?.length ? true : false
                          }
                          error={""}
                        />
                      </S.HistoryBudgetsContent>

                      <S.SubTitle>
                        <p>{I18n.get("Budget")}</p>
                        <span />
                      </S.SubTitle>

                      {isLoading ? (
                        <S.ContentIsLoading>
                          <Loading
                            text={I18n.get("Loading informations...")}
                            sizeText={14}
                            sizeIcon={16}
                          />
                        </S.ContentIsLoading>
                      ) : auth?.is_client_or_client_user &&
                        budgetDetails?.status === 0 ? (
                        <S.BudgetWaiting>
                          <S.Text strongText style={{ marginBottom: 15 }}>
                            {I18n.get("Extra em rascunho")}
                          </S.Text>
                          <S.Text style={{ marginBottom: 15 }}>
                            {I18n.get(
                              "Termine de editar o extra e salva as informações para que o seu parceiro prepare o orçamento."
                            )}
                          </S.Text>
                        </S.BudgetWaiting>
                      ) : !budgetDetails?.quotation?.quotationId ? (
                        <S.BudgetWaiting>
                          <S.Text strongText style={{ marginBottom: 15 }}>
                            {I18n.get("The Partner is preparing the proposal.")}
                          </S.Text>
                          <S.Text style={{ marginBottom: 15 }}>
                            {I18n.get(
                              "You will be notified once the Partner has finalized it and you will be able to approve or decline the proposal."
                            )}
                          </S.Text>
                        </S.BudgetWaiting>
                      ) : (
                        <>
                          <TableBudget
                            loading={false}
                            data={budgetData}
                            columns={tableColumnsBudget}
                          />

                          {auth?.is_client_or_client_user ? null : quotationHistorySelect?.isOpen ? (
                            <div style={{ marginTop: 30 }}>
                              <S.Text strongText style={{ marginBottom: 15 }}>
                                {I18n.get(
                                  "Detail description of the extra to be sent to the customer"
                                )}
                              </S.Text>
                              <Textarea
                                id="description"
                                label={I18n.get(
                                  "Detail description of the extra to be sent to the customer"
                                )}
                                rows={4}
                                maxLength={500}
                                counterChar
                                defaultValue={inputsForm.description}
                                onChange={(value) =>
                                  onChangeInputForm("description", value)
                                }
                                error=""
                              />
                            </div>
                          ) : null}

                          <div style={{ marginTop: 60 }}>
                            <S.SubTitle>
                              <p>{I18n.get("Commercial Proposal")}</p>
                              <span />
                            </S.SubTitle>
                          </div>

                          <TableProposal
                            loading={false}
                            data={commercialProposalData}
                            columns={
                              auth?.is_client_or_client_user
                                ? tableColumnsProposalClient
                                : tableColumnsProposalPartner
                            }
                            isClient={
                              auth?.is_client_or_client_user ? true : false
                            }
                            reload={loadInit}
                            quoteHistory={quoteHistory}
                          />

                          <div style={{ marginTop: 60 }}>
                            <TableProposalGeneralCosts
                              loading={false}
                              data={quotation}
                              reload={loadInit}
                              currency={budgetDetails?.currency}
                            />
                          </div>

                          <div style={{ marginTop: 90 }}>
                            <TableProposalResume
                              loading={false}
                              data={summaryProposalData}
                              columns={tableColumnsProposalResume}
                            />
                            <S.Text style={{ margin: "15px 0" }}>
                              {I18n.get(
                                "*Asset list items already include platform retention and, therefore, retention amounts are not calculated on such items."
                              )}
                            </S.Text>
                            <S.ButtonExportExcel>
                              <ButtonBpool
                                theme="primary"
                                onClick={handleExportToExcel}
                                text={I18n.get("Exportar para Excel")}
                                loading={isLoadingExport}
                              />
                            </S.ButtonExportExcel>
                          </div>

                          <div style={{ marginTop: 90 }}>
                            <S.SubTitle>
                              <p>{I18n.get("Space for comments")}</p>
                              <span />
                            </S.SubTitle>

                            <div style={{ marginBottom: 30 }}>
                              <div>
                                <strong>{I18n.get("Histórico")}</strong>
                              </div>
                              {budgetDetails?.quotationHistory?.map((item) => (
                                <S.CommentsHistory key={item?.quotationId}>
                                  <div
                                    style={{ marginLeft: 15, marginTop: 10 }}
                                  >
                                    {I18n.get("Orçamento enviado em") +
                                      " " +
                                      `${item?.createdOn}`}{" "}
                                    -{" "}
                                    <strong>
                                      {getQuotationStatus({ auth, item })}
                                    </strong>
                                  </div>
                                  <div style={{ marginLeft: 30 }}>
                                    {I18n.get("Comentário Parceiro")}:{" "}
                                    <span>{item?.partnerNotes || "---"}</span>
                                  </div>
                                  <div style={{ marginLeft: 30 }}>
                                    {I18n.get("Comentário Cliente")}:{" "}
                                    <span>{item?.clientNotes || "---"}</span>
                                  </div>
                                </S.CommentsHistory>
                              ))}
                            </div>

                            <div>
                              {auth?.is_client_or_client_user ? (
                                quotationHistorySelect?.isApproved === null ? (
                                  <div>
                                    <S.Text
                                      strongText
                                      style={{ marginBottom: 15 }}
                                    >
                                      {I18n.get("Comments of the Client")}
                                    </S.Text>
                                    <Textarea
                                      id="commentsClient"
                                      label={I18n.get("Comments of the Client")}
                                      rows={4}
                                      maxLength={500}
                                      counterChar
                                      defaultValue={comments.commentsClient}
                                      onChange={(value) =>
                                        onChangeInputComments(
                                          "commentsClient",
                                          value
                                        )
                                      }
                                      error=""
                                    />
                                  </div>
                                ) : null
                              ) : !quotationHistorySelect?.isOpen ||
                                quotationHistorySelect?.isApproved ? null : (
                                <div>
                                  <S.Text
                                    strongText
                                    style={{ marginBottom: 15 }}
                                  >
                                    {I18n.get("Comments of the Partner")}
                                  </S.Text>
                                  <Textarea
                                    id="commentsPartner"
                                    label={I18n.get("Comments of the Partner")}
                                    rows={4}
                                    maxLength={500}
                                    counterChar
                                    defaultValue={comments.commentsPartner}
                                    onChange={(value) =>
                                      onChangeInputComments(
                                        "commentsPartner",
                                        value
                                      )
                                    }
                                    error=""
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          {!auth?.is_client_or_client_user &&
                          quotationHistorySelect?.isOpen ? null : (
                            <div style={{ marginTop: 60 }}>
                              <S.BudgetResume>
                                {!auth?.is_client_or_client_user &&
                                quotationHistorySelect?.submittedOn ? (
                                  <p>
                                    {I18n.get(
                                      "Orçamento enviado ao cliente em"
                                    )}
                                  </p>
                                ) : (
                                  <p>{I18n.get("Orçamento recebido em")}</p>
                                )}
                                {!auth?.is_client_or_client_user &&
                                quotationHistorySelect?.submittedOn ? (
                                  <span>
                                    {quotationHistorySelect?.submittedOn}
                                  </span>
                                ) : (
                                  <span>{budgetDetails?.createdOnFmt}</span>
                                )}
                              </S.BudgetResume>
                              <S.BudgetResumeSubText>
                                <p
                                  className={whatClassBudgetStatusMessage({
                                    isClient: auth?.is_client_or_client_user,
                                    quotationData: quotationHistorySelect,
                                  })}
                                >
                                  {I18n.get(
                                    getMessagesOnQuotationBudget({
                                      auth,
                                      item: quotationHistorySelect,
                                    })
                                  )}
                                  {quotationHistorySelect?.approvedOn ? (
                                    <span style={{ marginLeft: 5 }}>
                                      {quotationHistorySelect?.approvedOn}
                                    </span>
                                  ) : null}
                                </p>
                              </S.BudgetResumeSubText>
                            </div>
                          )}
                        </>
                      )}
                    </S.Content>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <S.ButtonsFooter>
                      {!auth?.is_client_or_client_user ? (
                        !quotationHistorySelect?.isOpen ||
                        quotationHistorySelect?.isApproved ? null : (
                          <>
                            <ButtonBpool
                              text={I18n.get("Save and edit later")}
                              theme="secondary"
                              onClick={() => send({ isDraft: true })}
                              loading={isSending}
                            />
                            <ButtonBpool
                              text={I18n.get("Send to client")}
                              theme="primary"
                              onClick={send}
                              loading={isSending}
                            />
                          </>
                        )
                      ) : quotationHistorySelect?.isApproved === null ? (
                        <>
                          <ButtonBpool
                            text={I18n.get("Recuse")}
                            theme="secondary"
                            onClick={() => handleApproveOrRecuse(false)}
                            loading={isSendingSecondary}
                          />
                          <ButtonBpool
                            text={I18n.get("Approve final escope")}
                            theme="primary"
                            onClick={() => handleApproveOrRecuse(true)}
                            loading={isSending}
                          />
                        </>
                      ) : null}
                    </S.ButtonsFooter>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <S.ButtonsFooter style={{ justifyContent: "flex-end" }}>
                      <ButtonBpool
                        text={I18n.get("Back to the list")}
                        theme="secondary"
                        onClick={handleBack}
                      />
                    </S.ButtonsFooter>
                  </Col>
                </Row>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
