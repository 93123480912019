import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const HeaderTabs = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: 1px solid #ededed;
`;

export const ButtonTab = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  margin-right: 15px;

  color: ${(props) => (props.active ? '#1883ff' : '#979797')};
  border-bottom: ${(props) =>
    props.active ? '2px solid #1883ff' : '2px solid transparent'};
`;

export const ContentTab = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px;
`;
