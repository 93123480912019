import { ITENS_DELETE } from './actions';

export const reset_itens_dash = () => {

    return (dispatch, getState) => {

        dispatch({
            type: ITENS_DELETE,
            payload: {
                spec: undefined,
                asset: undefined,
                brand: undefined,
                historicItem: undefined,
                partnerItem: undefined,
                status: undefined,
                warning: undefined
            }
        });
    }
}

export default reset_itens_dash;
