import React from "react";
import { I18n } from "aws-amplify";
import { Redirect } from "react-router-dom";

import { Task } from "../../utils/task";

import ProjectContainer from "../../containers/project-container";

import { ClientProjectStatus } from "../../model";

import FillForm from "../page-templates/fillForm-logged";

import ProjectPOForm from "../shared/project-po-form";

import BillingItem from "../shared/billing-item";

import StepsCreative from "./steps-creative";
import StepsBidding from "./steps-bidding";

import { scrollToTop } from "../../utils/ui";

import LogoButton from "../form/logo-button";

import { Row, Col, Button } from "antd";

class ProjectDefintionStep5 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      delete_task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    //this.props.get_assets({});
    scrollToTop();
  }

  onDelete(billingId, e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      billingId: billingId,
    };

    var delete_task = new Task();

    delete_task.start();

    this.setState({ delete_task: delete_task });

    this.props.delete_billing(data).then(({ task, errors }) => {
      this.setState({ delete_task: task, errors: errors });
    });
  }

  onSubmitForm(e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      status: "Started",
    };

    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_project_status(data).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    if (
      this.props.project &&
      this.props.project.status == ClientProjectStatus.Started
    ) {
      return (
        <Redirect
          to={`/projetos/${this.props.match.params.projectId}/detalhamento-po-obrigado`}
        />
      );
    }

    const { billings = [] } = this.props;

    const submitIsEnabled = this.state.show == false && billings.length > 0;

    // let symbol = this.props.currency?.symbolOnly ?? 'R$';

    const currency = this.props.currency || {};
    const billingCurrency = this.props.billingCurrency || {};

    console.log(" this.props", this.props);

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container">
            <div className="columns is-mobile">
              <div
                className="column is-6"
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="title">
                  {I18n.get("Definições do Projeto")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6">
                {this.props.isCreative && (
                  <>
                    <StepsCreative
                      active={4}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                    />
                  </>
                )}
                {this.props.isPartnerSelectionBidding && (
                  <>
                    <StepsBidding
                      active={3}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="client-step client-step-1 project-definition">
          <div className="container client-container-1">
            <h1 className="title title-4">
              {this.props.isCreative && (
                <>
                  <span>4.</span> {I18n.get("faturamentoPO")}
                </>
              )}

              {this.props.isPartnerSelectionBidding && (
                <>
                  <span>3.</span> {I18n.get("faturamentoPO")}
                </>
              )}
            </h1>

            <Row gutter={1}>
              <Col sm={24}>
                <div className="alert-bpool">
                  <p>
                    {I18n.get(
                      "Por favor preencha os campos abaixo e faça upload do documento de Ordem de Compra (PO ou similar, conforme padrão da sua empresa). Não se esqueça de verificar os dados que serão utilizados para faturamento da nota fiscal contra sua empresa e de clicar em ‘Salvar’. Uma vez adicionados os detalhes de faturamento, não se esqueça de clicar em ‘Iniciar Projeto‘."
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <br />
            <br />

            {billings.map((billing) => (
              <div style={{ marginTop: "2rem" }}>
                <div className="card">
                  <div className="card-content">
                    <BillingItem
                      billing={billing}
                      currency={billingCurrency}
                      onDelete={this.onDelete.bind(this, billing.id)}
                    />
                  </div>
                </div>
              </div>
            ))}

            <br />

            {(this.state.show == true ||
              (this.state.show == false &&
                billings &&
                billings.length == 0)) && (
              <div>
                {billings.length > 0 && (
                  <a
                    onClick={(e) => this.setState({ show: false })}
                    className="is-pulled-right"
                  >
                    <span className="icon">
                      <i className="fas fa-times" aria-hidden="true"></i>
                    </span>
                  </a>
                )}

                <ProjectPOForm
                  {...this.props}
                  onClose={(e) => this.setState({ show: false })}
                />
              </div>
            )}

            <br />

            <div className="columns">
              <div className="column is-6 is-offset-3">
                <div className="field is-grouped is-grouped-centered">
                  <p className="control is-expanded" onClick={this.scrollToTop}>
                    {this.state.show == false && billings.length > 0 && (
                      <button
                        onClick={(e) => this.setState({ show: true })}
                        className="button bt-bpool grey is-fullwidth  previous"
                      >
                        {I18n.get("Adicionar #PO")}
                      </button>
                    )}
                  </p>

                  <p className="control is-expanded" onClick={this.scrollToTop}>
                    {submitIsEnabled && (
                      <Button
                        onClick={this.onSubmitForm.bind(this)}
                        className="button bt-bpool black is-fullwidth  next"
                        loading={this.state.submit_task.busy}
                        disabled={!submitIsEnabled}
                      >
                        {I18n.get("Iniciar projeto")}
                      </Button>
                    )}
                  </p>
                </div>
              </div>
            </div>

            <Row gutter={1}>
              <Col sm={24}>
                {/* <LogoButton  label={I18n.get("Iniciar projeto")} disabled={!submitIsEnabled} className="button is-black is-pulled-right" task={this.state.submit_task} /> */}
              </Col>
            </Row>

            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(ProjectDefintionStep5);
