import React, { Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { Tag } from 'antd';

export default class Card extends React.Component {

    constructor(props) {
        super();
    }

    getSpecialismLiveMkt(partner) {

        let x = "Live Marketing";


    }


    getSpecialism(spec) {

        const items = this.props.bp
            && this.props.bp.specialisms && this.props.bp.specialisms.filter((item) => item.code == spec);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }

    //     const SIZE = [
    //     { "label": "Pequena < 100", "code": "SMALL" },
    //     { "label": "Médio >100 até 750", "code": "AVERAGE" },
    //     { "label": "Grande >750 até 1500", "code": "BIG" },
    //     { "label": "Macro >1500", "code": "MACRP" },
    // ]

    renderSize(text) {
        switch (text) {
            case 'SMALL':
                return <Tag color="#cecece">P</Tag>
                break;
            case 'AVERAGE':
                return <Tag color="#aaa">M</Tag>
                break;
            case 'BIG':
                return <Tag color="#7c7c7c">G</Tag>
                break;
            case 'MACRP':
                return <Tag color="#000">XG</Tag>
                break;
        }
    }

    render() {

        if (!this.props.bp) return null;
        if (!this.props.bp.partner) return null;
        if (!this.props.bp.partner.specialisms) return null;

        const partner = this.props.bp.partner;


        return (
            <div className="card">
                <div className="card-content">
                    <h3 className="title is-4">{I18n.get("Especialidades")}</h3>

                    <div className="content">

                        <table className="table-bpool no-opacity">
                            <tbody>
                                {partner.specialisms.map((item, index) => {
                                    let isLiveMkt = item == "LIVE_MKT";

                                    return (
                                        <Fragment>
                                            {this.props.bp.partner.eventLiveMarketing && this.props.bp.partner.eventLiveMarketing.indexOf("Eventos") > -1 && isLiveMkt &&
                                                <tr key={`${index}-eventos`}>
                                                    <td colspan={this.props.bp.partner.eventLiveMarketingEvents && this.props.bp.partner.eventLiveMarketingEvents.type.indexOf("CORPORATEB2B") ? 1 : 5}>
                                                        {this.getSpecialism(item).label}
                                                    </td>
                                                    <td colspan={2}>
                                                        <Tag color="#f50">{I18n.get("Eventos")}</Tag>
                                                        {this.props.bp.partner.eventLiveMarketingEvents && this.props.bp.partner.eventLiveMarketingEvents.type.map(itemType => {
                                                            return <Tag color="#000">{itemType}</Tag>
                                                        })}
                                                    </td>
                                                    {this.props.bp.partner.eventLiveMarketingEvents && this.props.bp.partner.eventLiveMarketingEvents.type.indexOf("CORPORATEB2B") &&
                                                        <td colspan={2}>
                                                            {this.props.bp.partner.eventLiveMarketingEvents && this.props.bp.partner.eventLiveMarketingEvents.corporate.map(itemCorp => {
                                                                return <Tag color="magenta">{itemCorp}</Tag>
                                                            })}
                                                        </td>
                                                    }
                                                    <td td colspan={1} style={{ textAlign: "right" }}>
                                                        {this.props.bp.partner.eventLiveMarketingEvents && this.props.bp.partner.eventLiveMarketingEvents.size && this.props.bp.partner.eventLiveMarketingEvents.size.map(item => {
                                                            return this.renderSize(item)
                                                        })}
                                                    </td>
                                                </tr>
                                            }

                                            {this.props.bp.partner.eventLiveMarketing && this.props.bp.partner.eventLiveMarketing.indexOf("Experiência de Marca") > -1 && isLiveMkt &&
                                                <tr key={`${index}-marca`}>
                                                    <td colspan={1}>
                                                        {this.getSpecialism(item).label}
                                                    </td>

                                                    <td colspan={4}>
                                                        <Tag color="#f50">{I18n.get("Experiência de Marca")}</Tag>
                                                    </td>

                                                    <td colspan={1} style={{ textAlign: "right" }}>
                                                        {this.props.bp.partner.eventLiveMarketingBrandExperience && this.props.bp.partner.eventLiveMarketingBrandExperience.size && this.props.bp.partner.eventLiveMarketingBrandExperience.size.map(item => {
                                                            return this.renderSize(item)
                                                        })}
                                                    </td>
                                                </tr>
                                            }

                                            {this.props.bp.partner.eventLiveMarketing && this.props.bp.partner.eventLiveMarketing.indexOf("Incentivo") > -1 && isLiveMkt &&
                                                <tr key={`${index}-incentivo`}>
                                                    <td colspan={1}>
                                                        {this.getSpecialism(item).label}
                                                    </td>

                                                    <td colspan={4}>
                                                        <Tag color="#f50">{I18n.get("Incentivo")}</Tag>
                                                    </td>

                                                    <td colspan={1} style={{ textAlign: "right" }}>

                                                        {this.props.bp.partner.eventLiveMarketingBrandIncentive && this.props.bp.partner.eventLiveMarketingBrandIncentive.size && this.props.bp.partner.eventLiveMarketingBrandIncentive.size.map(item => {
                                                            return this.renderSize(item)
                                                        })}
                                                    </td>
                                                </tr>
                                            }

                                            {this.props.bp.partner.eventLiveMarketing && this.props.bp.partner.eventLiveMarketing.indexOf("Mão de Obra") > -1 && isLiveMkt &&
                                                <tr key={`${index}-mao`}>
                                                    <td colspan={1}>
                                                        {this.getSpecialism(item).label}
                                                    </td>
                                                    <td colspan={2}>
                                                        <Tag color="#f50">{I18n.get("Mão de Obra")}</Tag>
                                                        {this.props.bp.partner.eventLiveMarketingLabor && this.props.bp.partner.eventLiveMarketingLabor.type.map(itemType => {
                                                            return <Tag color="#000">{itemType}</Tag>
                                                        })}
                                                    </td>

                                                    <td colspan={2}>
                                                        {this.props.bp.partner.eventLiveMarketingLabor && this.props.bp.partner.eventLiveMarketingLabor.contract.map(itemContract => {
                                                            return <Tag color="magenta">{itemContract}</Tag>
                                                        })}
                                                    </td>

                                                    <td td colspan={1} style={{ textAlign: "right" }}>
                                                        {this.props.bp.partner.eventLiveMarketingEvents && this.props.bp.partner.eventLiveMarketingEvents.size && this.props.bp.partner.eventLiveMarketingEvents.size.map(item => {
                                                            return this.renderSize(item)
                                                        })}
                                                    </td>
                                                </tr>
                                            }

                                            {this.props.bp.partner.eventLiveMarketing && this.props.bp.partner.eventLiveMarketing.indexOf("Shopper") > -1 && isLiveMkt &&
                                                <tr key={`${index}-shopper`}>
                                                    <td colspan={1}>
                                                        {this.getSpecialism(item).label}
                                                    </td>

                                                    <td colspan={4}>
                                                        <Tag color="#f50">{I18n.get("Shopper")}</Tag>
                                                        {this.props.bp.partner.eventLiveMarketingShopper && this.props.bp.partner.eventLiveMarketingShopper.type.map(itemType => {
                                                            return <Tag color="#000">{itemType}</Tag>
                                                        })}
                                                    </td>

                                                    <td colspan={1} style={{ textAlign: "right" }}>
                                                        {this.props.bp.partner.eventLiveMarketingShopper && this.props.bp.partner.eventLiveMarketingShopper.size && this.props.bp.partner.eventLiveMarketingShopper.size.map(item => {
                                                            return this.renderSize(item)
                                                        })}
                                                    </td>
                                                </tr>
                                            }

                                            {!isLiveMkt &&
                                                <tr key={index}>
                                                    <td colspan={6}>
                                                        {this.getSpecialism(item).label}
                                                    </td>
                                                </tr>
                                            }
                                        </Fragment>
                                    );
                                }
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        );

    }
}
