import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import constants from '../../../constants';
import { Link, Redirect } from 'react-router-dom';
import AppWrapperSimple from '../../components/AppWrapperRegister';
import ContainerPage from '../../components/ContainerPage';
import SubHeader from '../../components/SubHeader';
import TableCase from '../components/tableCases';
import TableAwards from '../components/tableAward';
import TableCause from '../components/tableCause';
import TablePortfolio from '../components/tablePortfolio';
import TableCountries from '../components/tableCountries';
import TableCountriesEx from '../components/tableCountriesEx';


import FormCountries from '../components/formCountries';

import { PARTNER_API } from '../../../actions/partner/actions';

import FormAddCase from '../components/formAddCase';
import FormEditCase from '../components/formEditCase';
import FormAwards from '../components/formAwards';
import FormCause from '../components/formCause';
import FormPortfolio from '../components/formPortfolio';

import { TitleBorder, TitleMiddleLine } from '../../components/Titles';

import styled from 'styled-components';

import { PartnerStatus } from '../../../model';


import {
    Row,
    Col,
    Input,
    Select,
    Tag,
    Icon,
    notification,
    Button,
    Tooltip,
    Drawer,
    Form
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
    padding-top: 50px;
    padding-bottom: 90px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    .RowSection {
        margin-bottom: 60px;
    }
`

const ContentAba = styled.div`
    .BtnAction {
        margin-bottom: 20px;
        text-align: right;
    }
`

const BoxAba = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 30px 20px;
    background: #ececec;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .4s ease;
    border: solid 4px #ececec;
    position: relative;
    .ant-tag {
        position: absolute;
        right: -15px;
        top: -10px;
        font-weight: bold;
    }

    p  {
        cursor: pointer;
    }

    &:hover, &.active {
        background: #dcdcdc;
        border-color: #777;
    }

    .icone {
        margin-bottom: 10px;
        font-size: 40px;
    }
`

const RowAba = styled(Row)`
    margin: 50px 0;
`
const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get: () => {
            return dispatch(PARTNER_API.get());
        },
        addClient: data => {
            return dispatch(PARTNER_API.create_client(data));
        },
        delClient: data => {
            return dispatch(PARTNER_API.delete_client(data));
        },
        addCase: data => {
            return dispatch(PARTNER_API.create_case(data));
        },
        updateCase: data => {
            return dispatch(PARTNER_API.update_case(data));
        },
        updateOrderCase: data => {
            return dispatch(PARTNER_API.update_order_case(data));
        },
        delCase: data => {
            return dispatch(PARTNER_API.delete_case(data));
        },
        upload: data => {
            return PARTNER_API.upload(data);
        },
        addAward: data => {
            dispatch(PARTNER_API.create_award(data));
        },
        delAward: data => {
            dispatch(PARTNER_API.delete_award(data));
        },
        updateAward: data => {
            dispatch(PARTNER_API.update_award(data));
        },
        addCause: data => {
            dispatch(PARTNER_API.create_cause(data));
        },
        deleteCause: data => {
            dispatch(PARTNER_API.delete_cause(data));
        },
        updateCause: data => {
            dispatch(PARTNER_API.update_cause(data));
        },
        addPortfolio: data => {
            dispatch(PARTNER_API.create_portfolio(data));
        },
        deletePortfolio: data => {
            dispatch(PARTNER_API.delete_portfolio(data));
        },
        updatePortfolio: data => {
            dispatch(PARTNER_API.update_portfolio(data));
        },
        addCountry: data => {
            return dispatch(PARTNER_API.create_workabroad(data));
        },
        deleteCountry: data => {
            return dispatch(PARTNER_API.delete_workabroad(data));
        },
        addCompanyCountry: data => {
            return dispatch(PARTNER_API.create_companyabroad(data));
        },
        deleteCompanyCountry: data => {
            return dispatch(PARTNER_API.delete_companyabroad(data));
        },
    };
};

class FullWorks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            activeTabResp: 1,
            showAddCase: false,
            showEditCase: false,
            showAddAwards: false,
            showAddCountry: false,
            showAddCause: false,
            showAddPortfolio: false,
            editCaseItem: {},
            editAwardItem: {},
            editCauseItem: {},
            editPortfolioItem: {},
            showAwards: false,
            listImg: [],
            scape: "",
            editSelected: false,
            exterior: false,
            type: 1,
        }
    }

    showAddCountry = () => {
        this.setState({
            showAddCountry: true
        });
    }


    showAddCase = (type) => {
        this.setState({
            showAddCase: true,
            type: type
        });
    }

    showAddAwards = (type) => {
        this.setState({
            showAwards: true,
            type: type
        });
    }

    showAddCause = (type) => {
        this.setState({
            showAddCause: true,
            type: type
        });
    }

    showAddPortfolio = (type) => {
        this.setState({
            showAddPortfolio: true,
            type: type
        });
    }

    closeDrawer = () => {
        this.setState({
            showAddCase: false,
            showEditCase: false,
            showAwards: false,
            showAddCountry: false,
            showAddCause: false,
            showAddPortfolio: false,
            editAwardItem: {},
            editCauseItem: {},
            editPortfolioItem: {},
            exterior: false,
            editCaseItem: {}
        });
    }

    showEditCase = () => {
        this.setState({
            showEditCase: true
        });
    }

    hideEditCase = () => {
        this.setState({
            showEditCase: false,
            editCaseItem: null
        });

        setTimeout(() => {
            this.setState({
                listImg: [],
                scape: ""
            })
        }, 400);
    }


    handleEditCase = (item) => {
        this.setState({
            editCaseItem: {
                ...item,
                scape: Math.random().toString(36).substring(7)
            },
            type: item.type,
            editSelected: true
        });

        setTimeout(() => {
            this.showEditCase();
        }, 200);


    }
    handleEditAward = (item) => {

        this.setState({
            editAwardItem: item,
            type: item.type,
        });

        setTimeout(() => {
            this.showAddAwards();
        }, 500);

    }

    handleEditPortfolio = (item) => {

        this.setState({
            editPortfolioItem: item,
            type: item.type,
        });

        setTimeout(() => {
            this.showAddPortfolio();
        }, 200);

    }

    handleEditCause = (item) => {

        this.setState({
            editCauseItem: item,
        });

        setTimeout(() => {
            this.showAddCause();
        }, 200);

    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partner.workAbroad && prevProps.partner.workAbroad && this.props.partner.workAbroad.length > prevProps.partner.workAbroad.length) {

            notification.success({
                message: I18n.get('Adicionado com sucesso!'),
                description: 'O país foi adicionado ao seu cadastro',
            });

            this.closeDrawer()
        }

        if (this.props.partner.workAbroad && prevProps.partner.workAbroad && this.props.partner.workAbroad.length < prevProps.partner.workAbroad.length) {

            notification.success({
                message: I18n.get('Deletado com sucesso!'),
                description: 'O país foi removido ao seu cadastro',
            });

            this.closeDrawer()
        }

        if (this.props.partner.cases && prevProps.partner.cases && this.props.partner.cases.length > prevProps.partner.cases.length) {

            notification.success({
                message: I18n.get("Adicionado com sucesso!"),
                description: I18n.get('Case foi adicionado ao seu cadastro'),
                duration: 3
            });

            this.closeDrawer();
        }

        if (this.props.partner.cases && prevProps.partner.cases && this.props.partner.cases.length < prevProps.partner.cases.length) {

            notification.success({
                message: I18n.get("Removido com sucesso!"),
                description: I18n.get('Case foi removido do seu cadastro'),
                duration: 3
            });
        }

        if (this.props.partner.cases && prevProps.partner.cases && this.props.partner.cases != prevProps.partner.cases && this.state.editSelected) {

            notification.success({
                message: I18n.get("Atualizado com sucesso!"),
                description: I18n.get('Case foi atualizado'),
                duration: 3
            });

            this.setState({
                editSelected: false
            })

            this.hideEditCase();
        }

        // AWARDS

        if (this.props.partner.awards && prevProps.partner.awards && this.props.partner.awards.length > prevProps.partner.awards.length) {

            notification.success({
                message: I18n.get("Adicionado com sucesso!"),
                description: I18n.get('O prêmio foi adicionado ao seu cadastro'),
                duration: 3,
            });

            this.closeDrawer();
        }

        if (this.props.partner.awards && prevProps.partner.awards && this.props.partner.awards.length < prevProps.partner.awards.length) {

            notification.success({
                message: I18n.get("Removido com sucesso!"),
                description: I18n.get('O prêmio foi removido do seu cadastro'),
                duration: 3,
            });
        }

        if (this.props.partner.awards && prevProps.partner.awards && this.props.partner.awards != prevProps.partner.awards && Object.keys(this.state.editAwardItem).length > 0) {

            notification.success({
                message: I18n.get("Atualizado com sucesso!"),
                description: I18n.get('O prêmio foi atualizado'),
                duration: 3,
            });

            this.setState({
                editAwardItem: {}
            })

            this.closeDrawer();
        }

        // CAUSAS

        if (this.props.partner.causes && prevProps.partner.causes && this.props.partner.causes.length > prevProps.partner.causes.length) {

            notification.success({
                message: I18n.get("Adicionado com sucesso!"),
                description: I18n.get('A causa foi adicionada ao seu cadastro'),
                duration: 3,
            });

            this.closeDrawer();
        }

        if (this.props.partner.causes && prevProps.partner.causes && this.props.partner.causes.length < prevProps.partner.causes.length) {

            notification.success({
                message: I18n.get("Removido com sucesso!"),
                description: I18n.get('A causa foi removida do seu cadastro'),
                duration: 3,
            });
        }

        if (this.props.partner.causes && prevProps.partner.causes && this.props.partner.causes != prevProps.partner.causes && Object.keys(this.state.editCauseItem).length > 0) {

            notification.success({
                message: I18n.get("Atualizado com sucesso!"),
                description: I18n.get('A causa foi atualizada'),
                duration: 3,
            });

            this.setState({
                selectedItem: null
            })

            this.closeDrawer();
        }


        // PORTFOLIO

        if (this.props.partner.portfolios && prevProps.partner.portfolios && this.props.partner.portfolios.length > prevProps.partner.portfolios.length) {

            notification.success({
                message: I18n.get("Adicionado com sucesso!"),
                description: I18n.get('Portfolio foi adicionado ao seu cadastro'),
                duration: 3,
            });

            this.setState({
                editPortfolioItem: {}
            })


            this.closeDrawer();
        }

        if (this.props.partner.portfolios && prevProps.partner.portfolios && this.props.partner.portfolios.length < prevProps.partner.portfolios.length) {

            notification.success({
                message: I18n.get("Removido com sucesso!"),
                description: I18n.get('Portfolio foi removido do seu cadastro'),
                duration: 3,
            });
        }

        if (this.props.partner.portfolios && prevProps.partner.portfolios && this.props.partner.portfolios != prevProps.partner.portfolios && Object.keys(this.state.editPortfolioItem).length > 0) {

            notification.success({
                message: I18n.get("Atualizado com sucesso!"),
                description: I18n.get('Portfolio foi atualizado'),
                duration: 3,
            });

            this.setState({
                editPortfolioItem: {}
            })

            this.closeDrawer();
        }
    }

    handleAction = (data) => {
        
        if(this.state.exterior){
            this.props.addCompanyCountry(data).then(response => {
                if(response) {
                    this.closeDrawer();
                    this.props.get();
                    notification.success({
                        message: I18n.get('Adicionado com sucesso!'),
                        description: 'O país foi adicionado ao seu cadastro',
                    });
                } else {
                    notification.error({
                        message: 'Ops! Algo deu errado',
                        description: 'Tente novamente mais tarde',
                    });
                }
            });
        } else {
            this.props.addCountry(data)
        }
    }

    handleDelete = (data, ex) => {
        
        if(ex){
            this.props.deleteCompanyCountry(data).then(response => {
                if(response) {
                    this.props.get();

                    notification.success({
                        message: I18n.get('Deletado com sucesso!'),
                        description: 'O país foi removido ao seu cadastro',
                    });
                } else {
                    notification.error({
                        message: 'Ops! Algo deu errado',
                        description: 'Tente novamente mais tarde',
                    });
                }
            });
        } else {
            this.props.deleteCountry(data)
        }
    }


    render() {
        if (this.props.partner && this.props.partner.status == PartnerStatus.Approved) {
            return (<Redirect to="/parceiro/cadastro/sucesso" />);
        }

        const { partner } = this.props;

        //TODO: Find better way... GAMBI!
        let menuRegister = [...constants.MENU_REGISTER];
        for (let key in menuRegister) {
            menuRegister[key].label = I18n.get(menuRegister[key].label);
        }

        return (
            <AppWrapperSimple>
                <SubHeader
                    titlePage=""
                    itensMenu={menuRegister}
                    urlId={this.props.match.params.partnerId}
                    activeItem={5}
                />
                <ContainerPage>
                    <Main>
                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Cadastro - Cases & Prêmios")}</TitleBorder>
                                <p className="descriptionPage">{I18n.get("Selecione nos botões abaixo os campos que você gostaria de abrir para inserir mais detalhes do trabalho da sua empresa. Quanto mais informações e detalhes, melhor nosso algoritmo irá ranquear sua empresa.")}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <TitleMiddleLine>{I18n.get("Trabalhos realizados. Insira pelo menos um")}</TitleMiddleLine>
                            </Col>
                            <Col sm={24}>
                                <p>{I18n.get("Selecione nos botões abaixo, os campos que você gostaria de abrir para inserir mais detalhes do trabalho da sua empresa. Quanto mais, melhor.")}</p>
                            </Col>
                        </Row>
                        <RowAba>
                            <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTab: 1 })} className={this.state.activeTab == 1 ? "active" : ""}>
                                    <Tag color="#000">
                                        {partner.cases && this.props.partner.cases.filter((item) => item.type == 1).length}
                                    </Tag>
                                    <p className="icone">
                                        <Icon type="star" theme="filled" />
                                    </p>
                                    <p>{I18n.get("Cases")}</p>
                                </BoxAba>
                            </Col>
                            <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTab: 2 })} className={this.state.activeTab == 2 ? "active" : ""}>
                                    <Tag color="#000">
                                        {partner.portfolios && this.props.partner.portfolios.filter((item) => item.type == 1).length}
                                    </Tag>
                                    <p className="icone">
                                        <Icon type="bulb" />
                                    </p>
                                    <p>{I18n.get("PORTFOLIO")}</p>
                                </BoxAba>
                            </Col>
                            <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTab: 3 })} className={this.state.activeTab == 3 ? "active" : ""}>
                                    <Tag color="#000">
                                        {partner.awards && this.props.partner.awards.filter((item) => item.type == 1).length}
                                    </Tag>
                                    <p className="icone">
                                        <Icon type="trophy" theme="filled" />
                                    </p>
                                    <p>{I18n.get("PRÊMIOS")}</p>
                                </BoxAba>
                            </Col>
                            <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTab: 4 })} className={this.state.activeTab == 4 ? "active" : ""}>
                                    <Tag color="#000">
                                        {partner.causes && this.props.partner.causes.length}
                                    </Tag>
                                    <p className="icone">
                                        <Icon type="flag" theme="filled" />
                                    </p>
                                    <p>{I18n.get("CAUSAS")}</p>
                                </BoxAba>
                            </Col>
                        </RowAba>

                        {this.state.activeTab == 1 &&
                            <Row>
                                <Col sm={24}>
                                    <ContentAba>
                                        <p className="BtnAction">
                                            <Button type="primary" shape="round" onClick={() => this.showAddCase(1)}>{I18n.get("+ Adicionar case")}</Button>
                                        </p>
                                        <TableCase
                                            data={partner.cases && partner.cases.filter((item) => item.type == 1)}
                                            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                            partnerId={this.props.partner && this.props.partner.partnerId}
                                            delete={this.props.delCase}
                                            order={this.props.updateOrderCase}
                                            edit={this.handleEditCase}
                                            specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                                            specialisms={partner.specialisms && partner.specialisms}
                                        />
                                    </ContentAba>
                                </Col>
                            </Row>
                        }

                        {this.state.activeTab == 2 &&
                            <Row>
                                <Col sm={24}>
                                    <ContentAba>
                                        <p className="BtnAction">
                                            <Button type="primary" shape="round" onClick={() => this.showAddPortfolio(1)}>{I18n.get("+ Adicionar portfolio")}</Button>
                                        </p>
                                        <TablePortfolio
                                            data={partner.portfolios && partner.portfolios.filter((item) => item.type == 1)}
                                            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                            partnerId={this.props.partner && this.props.partner.partnerId}
                                            delete={this.props.deletePortfolio}
                                            edit={this.handleEditPortfolio}
                                            specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                                            specialisms={partner.specialisms && partner.specialisms}
                                        />
                                    </ContentAba>
                                </Col>
                            </Row>
                        }

                        {this.state.activeTab == 3 &&
                            <Row>
                                <Col sm={24}>
                                    <ContentAba>
                                        <p className="BtnAction">
                                            <Button type="primary" onClick={() => this.showAddAwards(1)} shape="round">{I18n.get("+ Adicionar prêmio")}</Button>
                                        </p>
                                        <TableAwards
                                            data={partner.awards && partner.awards.filter((item) => item.type == 1)}
                                            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                            partnerId={this.props.partner && this.props.partner.partnerId}
                                            delete={this.props.delAward}
                                            edit={this.handleEditAward}
                                            specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                                            specialisms={partner.specialisms && partner.specialisms}
                                        />
                                    </ContentAba>
                                </Col>
                            </Row>
                        }

                        {this.state.activeTab == 4 &&
                            <Row>
                                <Col sm={24}>
                                    <ContentAba>
                                        <p className="BtnAction">
                                            <Button type="primary" onClick={() => this.showAddCause()} shape="round" >{I18n.get("+ Adicionar causa")}</Button>
                                        </p>
                                        <TableCause
                                            data={partner.causes && partner.causes}
                                            partnerId={this.props.partner && this.props.partner.partnerId}
                                            delete={this.props.deleteCause}
                                            edit={this.handleEditCause}

                                        />
                                    </ContentAba>
                                </Col>
                            </Row>
                        }


                        <Row className="RowSection">
                            <Col sm={8}>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Cases, Prêmios e Portifolio do responsável")}</TitleBorder>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <TitleMiddleLine>{I18n.get("Mostre um pouco dos trabalhos realizados pelo responsável.")}<Tooltip title={I18n.get("Adicione material pessoal do responsável. O seu histórico também conta para o nosso algoritmo.")}><Icon type="question-circle" theme="filled" /></Tooltip></TitleMiddleLine>
                            </Col>

                        </Row>
                        <RowAba>
                            <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTabResp: 1 })} className={this.state.activeTabResp == 1 ? "active" : ""}>
                                    <Tag color="#000">
                                        {partner.cases && this.props.partner.cases.filter((item) => item.type == 2).length}
                                    </Tag>
                                    <p className="icone">
                                        <Icon type="star" theme="filled" />
                                    </p>
                                    <p>{I18n.get("Cases")}</p>
                                </BoxAba>
                            </Col>
                            <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTabResp: 2 })} className={this.state.activeTabResp == 2 ? "active" : ""}>
                                    <Tag color="#000">
                                        {partner.portfolios && this.props.partner.portfolios.filter((item) => item.type == 2).length}
                                    </Tag>
                                    <p className="icone">
                                        <Icon type="bulb" />
                                    </p>
                                    <p>{I18n.get("PORTFOLIO")}</p>
                                </BoxAba>
                            </Col>
                            <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTabResp: 3 })} className={this.state.activeTabResp == 3 ? "active" : ""}>
                                    <Tag color="#000">
                                        {partner.awards && this.props.partner.awards.filter((item) => item.type == 2).length}
                                    </Tag>
                                    <p className="icone">
                                        <Icon type="trophy" theme="filled" />
                                    </p>
                                    <p>{I18n.get("PRÊMIOS")}</p>
                                </BoxAba>
                            </Col>
                            {/* <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTabResp: 4 })} className={this.state.activeTabResp == 4 ? "active" : ""}>
                                    <Tag color="#000">
                                        {partner.causes && this.props.partner.causes.filter((item) => item.type == 2).length}
                                    </Tag>
                                    <p className="icone">
                                        <Icon type="flag" theme="filled" />
                                    </p>
                                    <p>
                                        CAUSAS
                                    </p>
                                </BoxAba>
                            </Col> */}
                        </RowAba>

                        {this.state.activeTabResp == 1 &&
                            <Row>
                                <Col sm={24}>
                                    <ContentAba>
                                        <p className="BtnAction">
                                            <Button type="primary" shape="round" onClick={() => this.showAddCase(2)}>{I18n.get("+ Adicionar case")}</Button>
                                        </p>
                                        <TableCase
                                            data={partner.cases && partner.cases.filter((item) => item.type == 2)}
                                            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                            partnerId={this.props.partner && this.props.partner.partnerId}
                                            delete={this.props.delCase}
                                            order={this.props.updateOrderCase}
                                            edit={this.handleEditCase}
                                            specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                                            specialisms={partner.specialisms && partner.specialisms}
                                        />
                                    </ContentAba>
                                </Col>
                            </Row>
                        }

                        {this.state.activeTabResp == 2 &&
                            <Row>
                                <Col sm={24}>
                                    <ContentAba>
                                        <p className="BtnAction">
                                            <Button type="primary" shape="round" onClick={() => this.showAddPortfolio(2)}>{I18n.get("+ Adicionar portfolio")}</Button>
                                        </p>
                                        <TablePortfolio
                                            data={partner.portfolios && partner.portfolios.filter((item) => item.type == 2)}
                                            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                            partnerId={this.props.partner && this.props.partner.partnerId}
                                            delete={this.props.deletePortfolio}
                                            edit={this.handleEditPortfolio}
                                        />
                                    </ContentAba>
                                </Col>
                            </Row>
                        }

                        {this.state.activeTabResp == 3 &&
                            <Row>
                                <Col sm={24}>
                                    <ContentAba>
                                        <p className="BtnAction">
                                            <Button type="primary" onClick={() => this.showAddAwards(2)} shape="round">{I18n.get("+ Adicionar prêmio")}</Button>
                                        </p>
                                        <TableAwards
                                            data={partner.awards && partner.awards.filter((item) => item.type == 2)}
                                            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                            partnerId={this.props.partner && this.props.partner.partnerId}
                                            delete={this.props.delAward}
                                            edit={this.handleEditAward}
                                            specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                                            specialisms={partner.specialisms && partner.specialisms}
                                        />
                                    </ContentAba>
                                </Col>
                            </Row>
                        }

                        {this.state.activeTabResp == 4 &&
                            <Row>
                                <Col sm={24}>
                                    <ContentAba>
                                        <p className="BtnAction">
                                            <Button type="primary" onClick={() => this.showAddCause()} shape="round" >{I18n.get("+ Adicionar causa")}</Button>
                                        </p>
                                        <TableCause
                                            data={partner.causes && partner.causes.filter((item) => item.type == 2)}
                                            partnerId={this.props.partner && this.props.partner.partnerId}
                                            delete={this.props.deleteCause}
                                            edit={this.handleEditCause}

                                        />
                                    </ContentAba>
                                </Col>
                            </Row>
                        }


                        <Row className="RowSection">
                            <Col sm={8}>
                            </Col>
                        </Row>


                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Atendimento de clientes fora do seu país")}</TitleBorder>

                                <p>
                                    {I18n.get("Esta área é destinada pra quem já tem experiência em atender clientes fora de seu país")}
                                </p>
                            </Col>
                        </Row>




                        <Row>
                            <Col sm={24}>
                                <p className="BtnAction">
                                    <Button type="primary" shape="round" onClick={() => this.setState({ showAddCountry: true })}>+ {I18n.get("Adicionar país")}</Button>
                                </p>
                            </Col>
                            <Col sm={24}>
                                <br />
                                <TableCountries
                                    data={this.props.partner.workAbroad && this.props.partner.workAbroad}
                                    partnerId={this.props.partner && this.props.partner.partnerId}
                                    partnerWorkAbroad={this.props.bp.partnerWorkAbroad && this.props.bp.partnerWorkAbroad}
                                    delete={this.handleDelete}
                                />
                            </Col>
                        </Row>

                        <Row className="RowSection">
                            <Col sm={8}>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Possui empresa no exterior")}</TitleBorder>

                                <p>
                                    {I18n.get("Se possui empresas para faturar no exterior, favor informar os países onde estão.")}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <p className="BtnAction">
                                    <Button type="primary" shape="round" onClick={() => this.setState({ exterior: true, showAddCountry: true })}>+ {I18n.get("Adicionar país")}</Button>
                                </p>
                            </Col>
                            <Col sm={24}>
                                <br />
                                <TableCountriesEx
                                    data={this.props.partner.companyAbroad && this.props.partner.companyAbroad}
                                    partnerId={this.props.partner && this.props.partner.partnerId}
                                    delete={this.handleDelete}
                                />
                            </Col>
                        </Row>
                        

                        <Row className="RowSection">
                            <Col sm={8}>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={3}>
                                <Link to={`/parceiro/cadastro/expertises/${this.props.match.params.partnerId}`}><Button shape="round">{I18n.get("Voltar")}</Button></Link>
                            </Col>
                            <Col sm={18}>
                            </Col>
                            <Col sm={3}>
                                <Link to={`/parceiro/cadastro/lideranca/${this.props.match.params.partnerId}`}><Button type="primary" shape="round">{I18n.get("Avançar")}</Button></Link>
                            </Col>
                        </Row>



                    </Main>
                </ContainerPage>

                <Drawer
                    title={Object.keys(this.state.editAwardItem).length > 0 ? I18n.get("Editar prêmio") : I18n.get("Adicionar prêmio")}
                    placement="right"
                    closable={true}
                    onClose={() => this.closeDrawer()}
                    destroyOnClose={true}
                    visible={this.state.showAwards}
                    width="50%"
                >
                    <FormAwards
                        specialisms={partner.specialisms && partner.specialisms}
                        specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                        sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                        action={this.props.addAward}
                        cancel={this.closeDrawer}
                        update={this.props.updateAward}
                        partnerId={this.props.partner.partnerId}
                        item={this.state.editAwardItem}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        type={this.state.type}
                        forceRender={true}
                    />
                </Drawer>
                

                <Drawer
                    title={Object.keys(this.state.editPortfolioItem).length > 0 ? I18n.get("Editar portfolio") : I18n.get("Adicionar portfolio")}
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({showAddPortfolio: false, editPortfolioItem: {}})}
                    destroyOnClose={true}
                    visible={this.state.showAddPortfolio}
                    width="50%"
                >
                    <FormPortfolio
                        locale={this.props.auth.locale}
                        specialisms={partner.specialisms && partner.specialisms}
                        action={this.props.addPortfolio}
                        cancel={this.closeDrawer}
                        update={this.props.updatePortfolio}
                        partnerId={this.props.partner.partnerId}
                        item={this.state.editPortfolioItem}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        type={this.state.type}
                        forceRender={true}
                        specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                        isFull={true}
                    />
                </Drawer>

                <Drawer
                    title={I18n.get("Adicionar país")}
                    placement="right"
                    closable={true}
                    onClose={() => this.closeDrawer()}
                    destroyOnClose={true}
                    visible={this.state.showAddCountry}
                    width="40%"
                >
                    <FormCountries
                        countryCodes={this.props.bp.countryCodes && this.props.bp.countryCodes}
                        partnerWorkAbroad={this.props.bp.partnerWorkAbroad && this.props.bp.partnerWorkAbroad}
                        action={this.handleAction}
                        cancel={this.closeDrawer}
                        update={this.props.updateAward}
                        partnerId={this.props.partner.partnerId}
                        item={this.state.editAwardItem}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        type={this.state.type}
                        forceRender={true}
                        exterior={this.state.exterior}
                    />
                </Drawer>

                <Drawer
                    title={I18n.get("Adicionar Case")}
                    placement="right"
                    closable={true}
                    onClose={() => this.closeDrawer()}
                    destroyOnClose={true}
                    visible={this.state.showAddCase}
                    width="60%"
                >
                    <FormAddCase
                        specialisms={partner.specialisms && partner.specialisms}
                        specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                        sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                        addCase={this.props.addCase}
                        onCancel={this.closeDrawer}
                        upload={this.props.upload}
                        id={this.props.partner.partnerId}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        type={this.state.type}
                        isFull={true}
                    />
                </Drawer>

                <Drawer
                    title={I18n.get("Editar Case")}
                    placement="right"
                    forceRender={true}
                    closable={true}
                    onClose={() => this.hideEditCase()}
                    destroyOnClose={true}
                    visible={this.state.showEditCase}
                    width="60%"
                >
                    <FormEditCase
                        specialisms={partner.specialisms && partner.specialisms}
                        specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                        sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                        updateCase={this.props.updateCase}
                        onCancel={() => this.hideEditCase()}
                        upload={this.props.upload}
                        id={this.props.partner.partnerId}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        type={this.state.type}
                        item={this.state.editCaseItem}
                        listImg={this.state.listImg}
                        isFull={true}
                    />
                </Drawer>


                

                <Drawer
                    title={Object.keys(this.state.editCauseItem).length > 0 ? I18n.get("Editar causa") : I18n.get("Adicionar causa")}
                    placement="right"
                    closable={true}
                    onClose={() => this.closeDrawer()}
                    destroyOnClose={true}
                    visible={this.state.showAddCause}
                    width="50%"
                >
                    <FormCause
                        specialisms={partner.specialisms && partner.specialisms}
                        action={this.props.addCause}
                        cancel={this.closeDrawer}
                        update={this.props.updateCause}
                        partnerId={this.props.partner.partnerId}
                        item={this.state.editCauseItem}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        type={1}
                        forceRender={true}
                    />
                </Drawer>


               
            </AppWrapperSimple>
        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(FullWorks);
