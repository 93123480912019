import React, { useState } from "react";
import NumberFormat from "react-number-format";

import * as S from "./styles";

export const CurrencyInput = ({
  value,
  required,
  placeholder,
  widthLabel,
  currency = {},
  disabled,
  error,
  onChange,
  clearError,
}) => {
  const thousandSeparator = currency?.thousandSeparator || ".";
  const decimalSeparator = currency?.decimalSeparator || ",";
  const symbolOnly = currency?.symbolOnly || "R$";

  const [valueTemp, setValueTemp] = useState("");

  const labelStyleActive = {
    top: "-5px",
    left: widthLabel ? widthLabel : "5px",
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
  };

  const spanStyleActive = {
    color: "#ff0000",
    fontSize: "12px",
    paddingRight: "2px",
  };

  const changeInput = async (valueInternal) => {
    if (onChange) {
      onChange(valueInternal);
      setValueTemp(valueInternal);
      if (valueInternal !== value) {
        if (clearError) {
          clearError();
        }
      }
    }
  };

  return (
    <S.WrapperInput error={error} widthLabel={widthLabel}>
      <NumberFormat
        value={value}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        prefix={`${symbolOnly} `}
        decimalScale={2}
        fixedDecimalScale={true}
        disabled={disabled}
        onValueChange={(value) => {
          changeInput(value?.floatValue);
        }}
      />
      <label className="label" style={valueTemp ? labelStyleActive : {}}>
        {required ? (
          <span style={valueTemp ? spanStyleActive : {}}>*</span>
        ) : null}
        {placeholder}
      </label>
      {error ? <S.InfoError>{error}</S.InfoError> : null}
    </S.WrapperInput>
  );
};
