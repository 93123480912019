import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { Row, Col, message } from "antd";
import { Loggedv2 } from "../../components/Loggedv2";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";
import { Input } from "../../../../components/UI/Input";
import { BoxWithCheckboxes } from "../../../../components/UI/BoxWithCheckboxes";
import { SelectOne } from "../../../../components/UI/SelectOne";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { ItemTeam } from "./itemTeam";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { urlsFull as urls } from "../../utils/stepsUrls";
import { clearErrorInput } from "./utils/clearErrorInput";
import { sendErrors } from "./utils/sendErrors";
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from "../../utils/flowToStorage";
import { statusIdToName } from "../../components/Loggedv2/statusIdToName";

import * as S from "./styles";

const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const MESSAGE_ERROR = "There was an error, try again.";
const PAGE_INDEX = 15;

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  let history = useHistory();
  const state = useSelector((state) => state);
  const {
    getStatusBarFull,
    getTeamRegistration,
    getTeamRegistrationProfiles,
    postTeamRegistrationInfos,
    postTeamRegistrationUser,
    putTeamRegistrationUser,
    deleteTeamRegistrationUser,
  } = useRegistrationFlowFull();
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [inputsForm, setInputsForm] = useState({});
  const [changeInfo, setChangeInfo] = useState(false);
  const [checkboxIdTab, setCheckboxIdTab] = useState("id-0");
  const [profilesDrop, setProfilesDrop] = useState([]);
  const [leadershipActives, setLeadershipActives] = useState([]);
  const [userActives, setUserActives] = useState([]);
  const [leadershipSendTemp, setLeadershipSendTemp] = useState([]);
  const [usersSendTemp, setUsersSendTemp] = useState([]);
  const [onEditing, setOnEditing] = useState(false);
  const [errrorsInternalForm, setErrrorsInternalForm] = useState({});
  const [errors, setErrors] = useState([]);
  const [isLoadingItem, setIsLoadingItem] = useState(false);
  const [visibleModal, setVisibleModal] = useState({
    open: false,
    type: "success",
    message: "",
  });

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const loadInit = async () => {
    setIsLoading(true);

    const response = await getTeamRegistration({
      admin: contextPage === "admin" ? true : false,
    });
    if (response.success) {
      const leaders = response.data.filter((leader) =>
        leader.userProfiles.includes(15)
      );
      setLeadershipActives(leaders);
      const users = response.data.filter(
        (user) => !user.userProfiles.includes(15)
      );
      setUserActives(users);
    }

    const responseProfiles = await getTeamRegistrationProfiles();
    if (responseProfiles.success) {
      const profiles = responseProfiles.data.map((profile) => ({
        code: Number(profile.code),
        label: profile.label,
      }));
      setProfilesDrop(profiles);
    }

    setIsLoading(false);
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarFull();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);

    const objToSend = [
      ...leadershipActives,
      ...userActives,
      ...leadershipSendTemp,
      ...usersSendTemp,
    ];

    const arrToSend = objToSend?.map((item) => ({
      ...item,
    }));

    const formData = {
      id: state?.partner?.id || partnerId,
      adminAction:
        contextPage === "admin" || contextPage === "edit" ? true : false,
      users: arrToSend,
    };

    const response = await postTeamRegistrationInfos({
      admin: contextPage === "admin" || contextPage === "edit" ? true : false,
      form: formData,
    });

    if (contextPage === "full") {
      await newGetStepbar();
    }

    if (response.success) {
      if (contextPage === "full") {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: urls[PAGE_INDEX + 1],
        });
      }
      if (contextPage === "edit" || contextPage === "admin") {
        sendOk(true);
      }
    } else {
      message.error(I18n.get(MESSAGE_ERROR));
      const errors = [];
      Object.keys(response?.errors).forEach(function (key, index) {
        errors.push(response?.errors[key]);
      });
      setErrors(errors);
    }

    setIsSending(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleChangeProfile = (value) => {
    onChangeInputForm("userProfiles", value);
  };

  const formLeadership = () => {
    return (
      <>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <Col xs={24} sm={24}>
            <S.DescriptionForm>
              {I18n.get(
                "Enter below professionals who will fill in information about their careers to enrich the company's record."
              )}
            </S.DescriptionForm>
          </Col>
        </Row>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Name")}
              label
              required
              value={inputsForm.name}
              onChange={(value) => onChangeInputForm("name", value)}
              error={errrorsInternalForm?.name}
              clearError={() => {
                const errorsObj = clearErrorInput({
                  field: "name",
                  obj: errrorsInternalForm,
                });
                setErrrorsInternalForm(errorsObj);
              }}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Email")}
              label
              required
              value={inputsForm.email}
              onChange={(value) => onChangeInputForm("email", value)}
              error={errrorsInternalForm?.email}
              clearError={() => {
                const errorsObj = clearErrorInput({
                  field: "email",
                  obj: errrorsInternalForm,
                });
                setErrrorsInternalForm(errorsObj);
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const formUser = () => {
    return (
      <>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <Col xs={24} sm={24}>
            <S.DescriptionForm>
              {I18n.get(
                "Enter below professionals who should have access to the BPool platform for day-to-day use. Choose operations for those who manage projects and finance for those responsible for billing."
              )}
            </S.DescriptionForm>
          </Col>
        </Row>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Name")}
              label
              required
              value={inputsForm.name}
              onChange={(value) => onChangeInputForm("name", value)}
              error={errrorsInternalForm?.name}
              clearError={() => {
                const errorsObj = clearErrorInput({
                  field: "name",
                  obj: errrorsInternalForm,
                });
                setErrrorsInternalForm(errorsObj);
              }}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Email")}
              label
              required
              value={inputsForm.email}
              onChange={(value) => onChangeInputForm("email", value)}
              error={errrorsInternalForm?.email}
              clearError={() => {
                const errorsObj = clearErrorInput({
                  field: "email",
                  obj: errrorsInternalForm,
                });
                setErrrorsInternalForm(errorsObj);
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <Col xs={12} style={{ height: 80 }}>
            <SelectOne
              options={profilesDrop}
              value={inputsForm.userProfiles}
              defaultValue=""
              onChange={handleChangeProfile}
              required
              placeholder={I18n.get("Assignment")}
              error={errrorsInternalForm?.userProfiles}
              multiple
            />
          </Col>
        </Row>
      </>
    );
  };

  const onChangeCheck = ({ id }) => {
    setCheckboxIdTab(id);
    setInputsForm({});
    setOnEditing(false);
    setErrrorsInternalForm({});
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const duplicateEmail = (email, arrTemp, arrActives) => {
    const checkArrays = [...arrTemp, ...arrActives];

    const checkActives = checkArrays.filter((i) => i.email == email);

    const checked = checkActives.length == 0 ? true : false;

    return checked;
  };

  const postUserFull = async () => {
    const checkEmail = inputsForm.email && validateEmail(inputsForm.email);

    if (checkboxIdTab === "id-0") {
      if (inputsForm.name && checkEmail) {
        const id = uuid();
        const objAdd = {
          id,
          name: inputsForm.name,
          email: inputsForm.email,
          userProfiles: [15],
        };

        if (
          duplicateEmail(
            inputsForm.email,
            leadershipSendTemp,
            leadershipActives
          )
        ) {
          setLeadershipSendTemp((leaderSendTemp) => [
            ...leaderSendTemp,
            objAdd,
          ]);
          setInputsForm({});
          setErrrorsInternalForm({});
        } else {
          setErrrorsInternalForm({
            name: inputsForm.name ? "" : I18n.get("Fill in the name field"),
            email: I18n.get("E-mail already registered"),
          });
        }
      } else {
        setErrrorsInternalForm({
          name: inputsForm.name ? "" : I18n.get("Fill in the name field"),
          email: checkEmail ? "" : I18n.get("Fill in the email field"),
        });
      }
    }

    if (checkboxIdTab === "id-1") {
      if (inputsForm.name && checkEmail && inputsForm.userProfiles?.length) {
        const id = uuid();
        const objAdd = {
          id,
          name: inputsForm.name,
          email: inputsForm.email,
          userProfiles: inputsForm.userProfiles,
        };

        if (duplicateEmail(inputsForm.email, usersSendTemp, userActives)) {
          setUsersSendTemp((userSendTemp) => [...userSendTemp, objAdd]);
          setInputsForm({});
          setErrrorsInternalForm({});
        } else {
          setErrrorsInternalForm({
            name: inputsForm.name ? "" : I18n.get("Fill in the name field"),
            email: I18n.get("E-mail already registered"),
          });
        }
      } else {
        setErrrorsInternalForm({
          name: inputsForm.name ? "" : I18n.get("Fill in the name field"),
          email: checkEmail ? "" : I18n.get("Fill in the email field"),
          userProfiles: inputsForm.userProfiles
            ? ""
            : I18n.get("Fill in the assignment field"),
        });
      }
    }

    setChangeInfo(true);
    onChangeInfo(true);
  };

  const postUserAdminOrEdit = async () => {
    const dataForm = {
      partnerId: state?.partner?.id || partnerId,
      id: "",
      userName: null,
      email: inputsForm.email,
      name: inputsForm.name,
      userProfiles: checkboxIdTab === "id-0" ? [15] : inputsForm.userProfiles,
    };

    setIsLoadingItem(true);
    const response = await postTeamRegistrationUser({ form: dataForm });

    if (response.success) {
      setVisibleModal({
        open: true,
        type: "success",
        message: "Your information has been saved",
      });
      setInputsForm({});
      setErrrorsInternalForm({});
      await loadInit();
    } else {
      let errorMessage = "";
      if (response.errors) {
        const errors = await sendErrors({ errors: response.errors });
        errorMessage = errors?.errorMessage;

        setErrrorsInternalForm(errors?.errorsToForm);
      }
      setVisibleModal({
        open: true,
        type: "error",
        message: errorMessage || I18n.get("Your information has not saved"),
      });
    }
    setIsLoadingItem(false);
  };

  const addItemForm = async () => {
    if (contextPage === "full") {
      await postUserFull();
    } else {
      await postUserAdminOrEdit();
    }
  };

  const putUserFull = async () => {
    if (inputsForm.name && inputsForm.email && inputsForm.userProfiles) {
      const arr1 = leadershipActives.find(
        (customer) => customer.id === inputsForm.id
      );
      const arr2 = userActives.find(
        (customer) => customer.id === inputsForm.id
      );
      const arr3 = leadershipSendTemp.find(
        (customer) => customer.id === inputsForm.id
      );
      const arr4 = usersSendTemp.find(
        (customer) => customer.id === inputsForm.id
      );

      if (arr1) {
        const leadershipActivesTemp = [...leadershipActives];
        const indexLeadershipActives = leadershipActives.findIndex(
          (item) => item.id === inputsForm.id
        );
        leadershipActivesTemp[indexLeadershipActives] = inputsForm;
        setLeadershipActives(leadershipActivesTemp);
      }

      if (arr2) {
        const userActivesTemp = [...userActives];
        const indexuserActives = userActives.findIndex(
          (item) => item.id === inputsForm.id
        );
        userActivesTemp[indexuserActives] = inputsForm;
        setUserActives(userActivesTemp);
      }

      if (arr3) {
        const leadershipSendTempTemp = [...leadershipSendTemp];
        const indexleadershipSendTemp = leadershipSendTemp.findIndex(
          (item) => item.id === inputsForm.id
        );
        leadershipSendTempTemp[indexleadershipSendTemp] = inputsForm;
        setLeadershipSendTemp(leadershipSendTempTemp);
      }

      if (arr4) {
        const usersSendTempTemp = [...usersSendTemp];
        const indexusersSendTemp = usersSendTemp.findIndex(
          (item) => item.id === inputsForm.id
        );
        usersSendTempTemp[indexusersSendTemp] = inputsForm;
        setUsersSendTemp(usersSendTempTemp);
      }

      setInputsForm({});
      setOnEditing(false);
      setChangeInfo(true);
      onChangeInfo(true);
      setErrrorsInternalForm({});
    } else {
      setErrrorsInternalForm({
        name: inputsForm.name ? "" : I18n.get("Fill in the name field"),
        email: inputsForm.email ? "" : I18n.get("Fill in the email field"),
        userProfiles: inputsForm.userProfiles
          ? ""
          : I18n.get("Fill in the assignment field"),
      });
    }
  };

  const putUserAdminOrEdit = async () => {
    const dataForm = {
      ...inputsForm,
      partnerId: state?.partner?.id || partnerId,
    };

    setIsLoadingItem(true);
    const response = await putTeamRegistrationUser({ form: dataForm });

    if (response.success) {
      setVisibleModal({
        open: true,
        type: "success",
        message: "Your information has been saved",
      });
      setInputsForm({});
      setErrrorsInternalForm({});
      await loadInit();
    } else {
      let errorMessage = "";
      if (response.errors) {
        const errors = await sendErrors({ errors: response.errors });
        errorMessage = errors?.errorMessage;

        setErrrorsInternalForm(errors?.errorsToForm);
      }
      setVisibleModal({
        open: true,
        type: "error",
        message: errorMessage || I18n.get("Your information has not saved"),
      });
    }
    setIsLoadingItem(false);
  };

  const updateItemForm = async () => {
    if (contextPage === "full") {
      await putUserFull();
    } else {
      await putUserAdminOrEdit();
    }
  };

  const handleEditItem = ({ type, id }) => {
    setOnEditing(true);
    setCheckboxIdTab(type);
    setErrrorsInternalForm({});

    const arrOfUsers = [
      ...leadershipActives,
      ...userActives,
      ...leadershipSendTemp,
      ...usersSendTemp,
    ];

    const editUser = arrOfUsers.find((customer) => customer.id === id);
    const objToEdit = {
      ...editUser,
    };

    setInputsForm(objToEdit);
  };

  const deleteUserFull = (id) => {
    const arr1 = leadershipActives.find((leader) => leader.id === id);
    const arr2 = userActives.find((user) => user.id === id);
    const arr3 = leadershipSendTemp.find((leader) => leader.id === id);
    const arr4 = usersSendTemp.find((user) => user.id === id);

    if (arr1) {
      const arrUpdated1 = leadershipActives.filter((item) => item.id !== id);
      setLeadershipActives(arrUpdated1);
      setErrrorsInternalForm({});
    }

    if (arr2) {
      const arrUpdated2 = userActives.filter((item) => item.id !== id);
      setUserActives(arrUpdated2);
      setErrrorsInternalForm({});
    }

    if (arr3) {
      const arrUpdated3 = leadershipSendTemp.filter((item) => item.id !== id);
      setLeadershipSendTemp(arrUpdated3);
      setErrrorsInternalForm({});
    }

    if (arr4) {
      const arrUpdated4 = usersSendTemp.filter((item) => item.id !== id);
      setUsersSendTemp(arrUpdated4);
      setErrrorsInternalForm({});
    }
  };

  const deleteUserAdminOrEdit = async (id) => {
    try {
      const response = await deleteTeamRegistrationUser({
        id,
        partnerId: state?.partner?.id || partnerId,
      });

      if (response.success) {
        setVisibleModal({
          open: true,
          type: "success",
          message: "Your information has been saved",
        });
        setInputsForm({});
        setErrrorsInternalForm({});
        await loadInit();
      } else {
        setVisibleModal({
          open: true,
          type: "error",
          message: response?.errors?.PartnerUser?.errorMessage,
        });
      }
    } catch (error) {
      setVisibleModal({
        open: true,
        type: "error",
        message: I18n.get("Your information has not saved"),
      });
    }
  };

  const handleDeleteItem = async (id) => {
    if (contextPage === "full") {
      deleteUserFull(id);
    } else {
      await deleteUserAdminOrEdit(id);
    }
  };

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("Team Registration")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  "Who else from your company do you want to bring to BPool? Add in LEADERSHIP professionals who will fill in information about their careers to enrich the company's registration, and in USER those who should also have access to the platform for daily use."
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        {isLoading ? (
          <Row>
            <Col xs={24}>
              <S.ContentIsLoading>
                <Loading
                  text={I18n.get("Loading informations...")}
                  sizeText={14}
                  sizeIcon={16}
                />
              </S.ContentIsLoading>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col xs={24}>
                <S.TitleSection>{I18n.get("Team Registration")}</S.TitleSection>
              </Col>
              <Col xs={24}>
                <BoxWithCheckboxes
                  checkboxes={[I18n.get("Leadership"), I18n.get("Users")]}
                  checkboxIdTab={checkboxIdTab}
                  onChangeCheck={(id) => onChangeCheck({ id })}
                  components={[formLeadership(), formUser()]}
                  actions={[
                    <ButtonBpool
                      text={I18n.get(!onEditing ? "Add" : "Save")}
                      theme="primary"
                      loading={isLoadingItem}
                      onClick={
                        !onEditing
                          ? () => addItemForm()
                          : () => updateItemForm()
                      }
                    />,
                  ]}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col xs={24}>
                <ItemTeam
                  textEmpty=""
                  data={[...leadershipActives, ...leadershipSendTemp]}
                  profiles={profilesDrop}
                  onDelete={(id) => handleDeleteItem(id)}
                  onEdit={(id) => handleEditItem({ type: "id-0", id })}
                  isUser={false}
                  partnerId={state?.partner?.id || partnerId}
                  contextPage={contextPage}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 60 }}>
              <Col xs={24}>
                <ItemTeam
                  textEmpty=""
                  data={[...userActives, ...usersSendTemp]}
                  profiles={profilesDrop}
                  onDelete={(id) => handleDeleteItem(id)}
                  onEdit={(id) => handleEditItem({ type: "id-1", id })}
                  isUser
                  partnerId={state?.partner?.id || partnerId}
                  contextPage={contextPage}
                />
              </Col>
            </Row>
          </>
        )}
        {errors.length ? (
          <Row style={{ marginTop: 30 }}>
            <Col xs={24}>
              <S.ErroSendPage>
                <strong>{I18n.get("Error ao enviar: ")}</strong>
                {errors.map((err) => I18n.get(err.errorMessage))}
              </S.ErroSendPage>
            </Col>
          </Row>
        ) : null}
        <Row style={{ marginTop: 30 }}>
          {contextPage === "full" ? (
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get("Back")}
                theme="secondary"
                full
                onClick={handleBackPage}
                disabled={isLoading}
              />
            </Col>
          ) : null}
          {contextPage === "edit" || contextPage === "admin" ? null : (
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }}>
              <ButtonBpool
                text={
                  contextPage === "edit" || contextPage === "admin"
                    ? I18n.get("Save")
                    : I18n.get("Save and Next")
                }
                theme="primary"
                full
                loading={isSending}
                onClick={send}
                disabled={isLoading}
              />
            </Col>
          )}
        </Row>
      </Main>

      <ModalBp
        visible={visibleModal.open}
        bodyText={I18n.get(
          visibleModal.type === "error"
            ? I18n.get("Ops algo deu errado!")
            : I18n.get("Salvo com sucesso.")
        )}
        subBodyText={
          visibleModal.type === "error"
            ? visibleModal.message
            : I18n.get(visibleModal.message)
        }
        okText={I18n.get("OK")}
        handleClickOk={() =>
          setVisibleModal({
            open: false,
            type: visibleModal.type,
            message: visibleModal.message,
          })
        }
        onCancel={() =>
          setVisibleModal({
            open: false,
            type: visibleModal.type,
            message: visibleModal.message,
          })
        }
        isDanger={visibleModal.type === "error" ? true : false}
        isAlert={visibleModal.type === "error" ? true : false}
      />
    </>
  );
};
