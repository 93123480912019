import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import RadioMultiField from "../form/radio-multi-field";
import CheckboxMultiField from "../form/checkbox-multi-field";
import NumericField from "../form/numeric-field";
import PercentageField from "../form/percentage-field";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

import { Input } from "../../v2/components/UI/Input";

class ServicesForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        logisticsAgency: {
          label: I18n.get("Logística será da agência?"),
          onChange: this.onChangeForm.bind(this, "logisticsAgency"),
          // onBlur: this.onSave.bind(this),
        },
        logisticsAgencyInitial: {
          label: I18n.get("Temos a grade inicial?"),
          onChange: this.onChangeForm.bind(this, "logisticsAgencyInitial"),
          // onBlur: this.onSave.bind(this),
        },
        logisticsAgencyQuantity: {
          onChange: this.onChangeForm.bind(this, "logisticsAgencyQuantity"),
          // onBlur: this.onSave.bind(this),
        },
        logisticsAgencyOrigin: {
          label: I18n.get("Por favor informe a origem e percentual"),
          onChange: this.onChangeFormCheckboxMulti.bind(
            this,
            "logisticsAgencyOrigin"
          ),
          // onBlur: this.onSave.bind(this),
        },
        logisticsAgencyRegion: {
          // onBlur: this.onSave.bind(this),
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeLogisticsAgencyRegion(region, e) {
    const value = e.target ? e.target.value : e;
    let regiao = region.toLowerCase();

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form.logisticsAgencyRegion[regiao] = value;

        if (nextState.errors.logisticsAgencyRegion) {
          delete nextState.errors.logisticsAgencyRegion[regiao];
        }

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeTransportLandRegion(region, e) {
    const value = e.target.value;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form.transportLandRegion[region] = value;

        if (nextState.errors.transportLandRegion) {
          delete nextState.errors.transportLandRegion[region];
        }

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckbox(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop].value = [...nextState.form[prop].value, value];
        } else {
          nextState.form[prop].value = nextState.form[prop].value.filter(
            (v) => v !== value
          );
        }

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const logisticsAgencyValues = constants.EVENT_LOGISTICS_AGENCY.map(
      (item) => {
        return item;
      }
    );

    const isLogisticsAgencyQuantityEnabled =
      this.state.form.logisticsAgencyInitial == "YES";

    const logisticsAgencyInitialValues =
      constants.EVENT_LOGISTICS_AGENCY_INTIAL.map((item) => {
        item.label = I18n.get(item.label);

        if (item.code == "YES") {
          item.field = (
            // <NumericField
            //   {...this.state.fields.logisticsAgencyQuantity}
            //   value={this.state.form.logisticsAgencyQuantity}
            //   error={this.state.errors.logisticsAgencyQuantity}
            //   disabled={!isLogisticsAgencyQuantityEnabled}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.logisticsAgencyQuantity.onChange(value)
              }
              value={this.state.form.logisticsAgencyQuantity}
              disabled={!isLogisticsAgencyQuantityEnabled}
              error={this.state.errors.logisticsAgencyQuantity?.errorMessage}
            />
          );
        }

        return item;
      });

    const logisticsAgencyOriginValues = constants.EVENT_REGIONS.map(
      (region) => {
        const item = { ...region };

        const isSelected =
          this.state.form.logisticsAgencyOrigin &&
          this.state.form.logisticsAgencyOrigin.indexOf(item.code) > -1;

        const code = item.code.toLowerCase();

        item.field = (
          //   <PercentageField
          //     className="field"
          //     {...this.state.fields.logisticsAgencyRegion}
          //     onChange={this.onChangeLogisticsAgencyRegion.bind(this, code)}
          //     value={this.state.form.logisticsAgencyRegion[code]}
          //     error={
          //       isSelected &&
          //       this.state.errors.logisticsAgencyRegion &&
          //       this.state.errors.logisticsAgencyRegion[code]
          //     }
          //     disabled={!isSelected}
          //   />

          <Input
            onChange={(code) =>
              this.onChangeLogisticsAgencyRegion(region.code, code)
            }
            value={this.state.form.logisticsAgencyRegion[code.toLowerCase()]}
            disabled={!isSelected}
            error={
              isSelected &&
              this.state.errors.logisticsAgencyRegion &&
              this.state.errors.logisticsAgencyRegion[code] &&
              this.state.errors.logisticsAgencyRegion[code].errorMessage
            }
            suffix={"%"}
          />
        );

        return item;
      }
    );

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Logística")}</h1>

        <div className="bp-event-section bpForce mini">
          <RadioMultiField
            {...this.state.fields.logisticsAgency}
            value={this.state.form.logisticsAgency}
            error={this.state.errors.logisticsAgency}
            values={logisticsAgencyValues}
          />

          <br style={{ clear: "both" }} />

          <RadioMultiField
            {...this.state.fields.logisticsAgencyInitial}
            value={this.state.form.logisticsAgencyInitial}
            error={this.state.errors.logisticsAgencyInitial}
            values={logisticsAgencyInitialValues}
          />

          <br style={{ clear: "both" }} />

          {isLogisticsAgencyQuantityEnabled && (
            <>
              <br />

              <CheckboxMultiField
                {...this.state.fields.logisticsAgencyOrigin}
                value={this.state.form.logisticsAgencyOrigin}
                error={this.state.errors.logisticsAgencyOrigin}
                values={logisticsAgencyOriginValues}
              />

              <br style={{ clear: "both" }} />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ServicesForm;
