import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import FormTermsClient from "../components/formTermsClient";
import AppWrapperSimple from "../../components/AppWrapperRegister";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";
import DownloadButton from "../../../components/form/download-button-field";
import { Task } from "../../../utils/task";
import { ClientStatus } from "../../../model";

import { CLIENT_API } from "../../../actions/client/actions";

import FormAddbrand from "../components/formAddBrand";
import FormAddAccess from "../components/formAddAccess";
import FormAddMsgGlobal from "../components/formAddMsgGlobal";

import EditableFormTable from "../components/tableBrandProduct";

import TableMaster from "../components/tableMaster";

import { TitleBorder } from "../../components/Titles";

import styled from "styled-components";

import {
  Row,
  Col,
  Input,
  notification,
  Select,
  Icon,
  Button,
  Modal,
} from "antd";

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: 60px;
  }

  @media screen and (max-width: 576px) {
    form {
      margin-bottom: 30px;
      button {
        top: 0 !important;
      }
    }
  }
`;
const LineBox = styled.div`
  border: solid 1px #ccc;
  padding: 0 20px 20px;
  margin-top: 30px;

  legend {
    position: relative;
    top: -15px;
    span {
      padding: 7px;
      background: #fff;
      border: solid 1px #ccc;
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBrand: (data) => {
      dispatch(CLIENT_API.create_brand_v2(data));
    },
    delBrand: (data) => {
      dispatch(CLIENT_API.delete_brand_v2(data));
    },
    updateBrand: (data) => {
      return dispatch(CLIENT_API.update_brand_v2(data));
    },
    get: (data) => {
      dispatch(CLIENT_API.get(data));
    },
    delUser: (data) => {
      dispatch(CLIENT_API.delete_user_v2(data));
    },
    addUser: (data) => {
      dispatch(CLIENT_API.create_user_v2(data));
    },
    updateUser: (data) => {
      return dispatch(CLIENT_API.update_user_v2(data));
    },
    update_terms: (data) => {
      return dispatch(CLIENT_API.update_terms(data));
    },
    update: (data) => {
      dispatch(CLIENT_API.update(data));
    },
    reset_erros: () => {
      dispatch(CLIENT_API.reset_erros());
    },
    updateMsg: (data) => {
      return dispatch(CLIENT_API.update_msg(data));
    },
    load_client: () => {
      return dispatch(CLIENT_API.get());
    },
  };
};

class FullBrandsClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleModal: false,
      sendRegister: false,
      viewErros: false,
      errosFinish: false,
      task: {},
    };
  }

  componentDidMount = () => {
    this.props.get(this.props.match.params.clientId);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.auth.logged_user &&
      this.props.client != prevProps.client &&
      Object.keys(this.props.client.errors).length == 1 &&
      this.state.errosFinish
    ) {
      this.setState({
        visibleModal: true,
        errosFinish: false,
        viewErros: false,
      });
    }

    if (
      this.props.auth.logged_user &&
      this.props.client != prevProps.client &&
      Object.keys(this.props.client.errors).length == 1 &&
      !this.state.errosFinish
    ) {
      this.setState({
        visibleModal: false,
        viewErros: false,
      });
    }

    if (this.props.client != prevProps.client) {
      this.setState({
        task: {},
      });
    }

    if (
      this.props.client.products &&
      prevProps.client.products &&
      this.props.client.products.length > prevProps.client.products.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("Produto adicionado."),
        duration: 3,
      });

      this.setState({ viewErros: false });
    }

    if (
      this.props.client.products &&
      prevProps.client.products &&
      this.props.client.products.length < prevProps.client.products.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("Produto removido."),
        duration: 3,
      });

      this.setState({ viewErros: false });
    }
  }

  handleUpdateMsg = (data) => {
    console.log(data);
    this.props.updateMsg(data).then((res) => {
      if (res) {
        notification.success({
          message: I18n.get("Mensagem adicionada!"),
          description: I18n.get(
            "A mensagem para produto global foi adicionada com sucesso!"
          ),
          duration: 3,
        });
        this.props.load_client();
        // Atualizando arvore
      } else {
        notification.error({
          message: I18n.get("Ops, algo deu errado!"),
          description: I18n.get("Tente novamente mais tarde"),
          duration: 3,
        });
      }
    });
  };

  viewErrors = () => {
    this.setState({
      viewErros: true,
    });

    setTimeout(() => {
      this.props.reset_erros();
      this.setState({
        viewErros: false,
      });
    }, 7000);
  };

  getUsers() {
    if (this.props.auth.profile == "Client") {
      return this.props.client && this.props.client.users;
    } else {
      return (
        this.props.client &&
        this.props.client.users &&
        this.props.client.users.filter((item) => item.role != "Client")
      );
    }
  }

  handleSave = () => {
    const data = {
      clientId: this.props.match.params.clientId,
      sendRegister: true,
      isSubmit: true,
      viewErros: true,
    };

    this.props.update(data);
  };

  fishOrTerms = () => {
    const data = {
      clientId: this.props.match.params.clientId,
      sendRegister: true,
      isSubmit: true,
    };

    var task = new Task();

    task.start();

    this.setState(
      {
        task: task,
        viewErros: true,
        errosFinish: true,
      },
      function () {
        this.props.update(data);
      }
    );
  };

  closeTerms = () => {
    this.setState({
      visibleModal: false,
    });
    const data = {
      clientId: this.props.match.params.clientId,
      sendRegister: true,
      isSubmit: true,
    };

    var task = new Task();

    task.start();

    this.setState(
      {
        task: task,
        viewErros: true,
        errosFinish: true,
      },
      function () {
        setTimeout(() => {
          this.props.update(data);
        }, 20);
      }
    );
  };

  render() {
    // if (this.props.partner && this.props.partner.status == 2) {
    //     return (<Redirect to={`/parceiro/obrigado-pre-cadastro`}></Redirect>);
    // }

    //TODO: Find better way... GAMBI!

    if (
      this.props.client &&
      this.props.client.status > ClientStatus.Incomplete
    ) {
      return <Redirect to="/cliente/cadastre-se/sucesso" />;
    }

    let menu = [...constants.MENU_REGISTER_CLIENT];
    for (let key in menu) {
      menu[key].label = I18n.get(menu[key].label);
    }

    let userClient =
      this.props.client &&
      this.props.client.users &&
      this.props.client.users.filter((i) => i.role == "Client");

    let dataTable = this.getUsers();

    let errorMap = {};

    if (this.props.client && this.props.client.errors) {
      Object.keys(this.props.client.errors).map((item) => {
        const error = this.props.client.errors[item];
        const key = error.step || "global";

        if (error.step < 5) {
          if (!errorMap[key]) {
            errorMap[key] = [];
          }
          errorMap[key].push(error.errorMessage);
        }
      });
    }

    return (
      <AppWrapperSimple>
        <SubHeader
          titlePage=""
          itensMenu={menu}
          urlId={this.props.match.params.clientId}
          activeItem={3}
        />
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>
                  {I18n.get("Marcas e Produtos/Serviços")}
                </TitleBorder>
                <p className="descriptionPage">
                  {I18n.get(
                    "Você poderá cadastrar todas marcas que deseja usar na plataforma e, sempre que necessário, poderá acrescentar novas marcas através do seu login na BPool."
                  )}
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <LineBox>
                  <legend>
                    {" "}
                    <span>{I18n.get("Adicionar marca e produto")}</span>
                  </legend>
                  <FormAddbrand
                    clientId={this.props.client?.clientId}
                    add={this.props.addBrand}
                    errors={
                      this.props.client.errors && this.props.client.errors
                    }
                    brands={this.props.client && this.props.client.brands}
                    productsItens={
                      this.props.client && this.props.client.products
                    }
                    sectorsFlat={
                      this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                    }
                  />
                </LineBox>
              </Col>
            </Row>

            <Row>
              <Col sm={24} style={{marginBottom: 50}}>
                <LineBox>
                  <legend>
                    {" "}
                    <span>{I18n.get("Mensagem para projetos globais")}</span>
                  </legend>
                  <FormAddMsgGlobal
                    add={this.handleUpdateMsg}
                    msg={this.props.client?.customization?.produtoGlobalMessage}
                  />
                </LineBox>

              </Col>
            </Row>
            {this.state.viewErros &&
              this.props.client?.errorsDelete &&
              Object.keys(this.props.client?.errorsDelete).length > 0 && (
                <div>
                  <Row>
                    <Col sm={24}>
                      <div className="error-display">
                        <ul>
                          {Object.keys(this.props.client.errorsDelete).map(
                            (item, index) => {
                              return (
                                <li key={index}>
                                  <Icon type="close-circle" theme="filled" />{" "}
                                  {
                                    this.props.client.errorsDelete[item]
                                      .errorMessage
                                  }
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  <Row className="RowSection">
                    <Col sm={8}></Col>
                  </Row>
                </div>
              )}

            <Row>
              <Col sm={24}>
                <EditableFormTable
                  brands={this.props.client && this.props.client.brands}
                  productsItens={
                    this.props.client && this.props.client.products
                  }
                  sectorsFlat={
                    this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                  }
                  delBrand={this.props.delBrand}
                  updateBrand={this.props.updateBrand}
                  clientId={this.props.client?.clientId}
                  viewErros={this.viewErrors}
                />
              </Col>
            </Row>

            <Row className="RowSection">
              <Col sm={8}></Col>
            </Row>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Usuários")}</TitleBorder>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <FormAddAccess
                  clientId={this.props.match.params.clientId}
                  add={this.props.addUser}
                  errors={this.props.client.errors && this.props.client.errors}
                  userClient={userClient}
                  sectorsFlat={
                    this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                  }
                  brands={this.props.client?.brands}
                  users={this.props.client?.users}
                  isMaster={this.props.auth.profile == "Client"}
                />
              </Col>
            </Row>
            <Row className="RowSection">
              <Col sm={24}>
                <TableMaster
                  data={dataTable || []}
                  delete={this.props.delUser}
                  clientId={this.props.client.id}
                  brands={this.props.client?.brands}
                  products={this.props.client?.products}
                  updateUser={this.props.updateUser}
                  users={this.props.client?.users}
                  isMaster={this.props.auth.profile == "Client"}
                />
              </Col>
            </Row>
            <Row className="RowSection">
              <Col sm={8}></Col>
            </Row>

            {Object.keys(errorMap).length > 0 && this.state.viewErros && (
              <Fragment>
                <Row className="">
                  <Col sm={24}>
                    <div className="ulErrors">
                      {Object.keys(errorMap).map((item, index) => {
                        let itemNav = constants.MENU_REGISTER_CLIENT.find(
                          (i) => i.activeMenu == item
                        );

                        return (
                          <div className="sectionError" key={index}>
                            <h3>
                              <Link
                                to={`${itemNav?.link}${this.props.match.params.clientId}`}
                              >
                                <Icon type="link" /> {itemNav?.label}
                              </Link>
                            </h3>

                            <ul>
                              {errorMap[item].map((errorMessage) => (
                                <li>
                                  <Link
                                    to={`${itemNav?.link}${this.props.match.params.clientId}`}
                                  >
                                    <Icon type="exclamation-circle" />{" "}
                                    {errorMessage}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <Row className="RowSection">
                  <Col sm={24}></Col>
                </Row>
              </Fragment>
            )}

            <Row>
              <Col sm={3}>
                <Link
                  to={`/cliente/cadastre-se/empresa/${this.props.match.params.clientId}`}
                >
                  <Button type="primary" shape="round">
                    {I18n.get("Voltar")}
                  </Button>
                </Link>
              </Col>
              <Col sm={18}></Col>
              <Col sm={3}>
                <Button
                  loading={this.state.task.busy}
                  type="primary"
                  onClick={() => this.fishOrTerms()}
                  shape="round"
                >
                  {I18n.get("Finalizar")}
                </Button>
                {/* <Link to={`/cliente/cadastre-se/acesso-master/${this.props.match.params.clientId}`}><Button type="primary" shape="round">{I18n.get("Avançar")}</Button></Link> */}
              </Col>
            </Row>
          </Main>

          <Modal
            title={I18n.get("Termos Contratuais BPool")}
            visible={
              this.state.visibleModal && Object.keys(errorMap).length == 0
            }
            footer={false}
            onCancel={() =>
              this.setState({
                visibleModal: false,
              })
            }
            width="55%"
          >
            <div style={{ height: "420px" }}>
              <iframe
                src={`${this.props.client.clientTerm}`}
                height="420px"
                width="100%"
              ></iframe>
            </div>

            <p className="pright">
              <DownloadButton
                LinkToDownload={this.props.client.clientTerm}
                text="Download"
              />
            </p>

            <br />
            <br />

            <FormTermsClient
              clientId={this.props.match.params.clientId}
              update={this.props.update_terms}
              close={this.closeTerms}
            />
          </Modal>
        </ContainerPage>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FullBrandsClient);
