import styled from "styled-components";
import { Skeleton as SkeletonAntd, Modal as ModalAntd } from "antd";

export const TitleContent = styled.div`
  padding: 0 15px;
  margin: 0 0 30px 0;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const Form = styled.div`
  padding-bottom: 90px;
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-input {
    height: 50px;
    margin-bottom: 30px;

    li {
      height: 100%;
    }
  }
  .skeleton-upload {
    height: 206px;
    margin-top: 0 !important;

    li {
      width: 100% !important;
      height: 100%;
      margin-top: 0 !important;
    }
  }
`;

export const UploadPoFile = styled.div`
  margin-top: 15px;
`;
