import React from "react";
import { I18n } from "aws-amplify";
// import { useSupplier } from "../../../../hooks/useSupplier/useSupplier.hook";
import { Unlogged } from "../../../../components/UI/Unlogged";
import { BoxContent } from "../../components/BoxContent";
import { TitlePage } from "../../components/TitlePage";
import * as S from "./styles";

export const UnloggedList = () => {
  return (
    <Unlogged>
      <div className="container">
        <BoxContent>
          <TitlePage
            title={I18n.get("Acompanhar faturamento")}
            subTitle={I18n.get(
              "Acompanhe por aqui o histórico do seu faturamento."
            )}
          />
          <div>Tabela, em construção</div>
        </BoxContent>
      </div>
    </Unlogged>
  );
};
