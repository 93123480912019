import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Upload, Icon, message, notification, Form } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import FileIcon from '../../svg/icon-file.svg';
import IconButton from '../../components/form/icon-button';
import moment from 'moment';
import Config from '../../config';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Somente arquivos JPG e PNG são suportados.');
  }
  return isJpgOrPng;
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ChatFileUpload extends Component {
  constructor(props) {
    super();

    this.state = {
      imageUrl: '',
      imgPreview: '',
    };

    this.inputFileRef = null;

    this.setInputFileRef = (element) => {
      this.inputFileRef = element;
    };
  }

  handleInputChange(event) {
    this.props.startLoading();

    // notification.success({
    //     message: I18n.get('Arquivo selecionado.'),
    //     description:
    //         I18n.get('O upload está iniciando'),
    // });

    let file = event.target.files[0];
    const EndpointGetAuthToUpload = this.props.get2
      ? Config.UPLOAD_URL2
      : Config.UPLOAD_URL;

    let fileSplit = file.name.split('.');
    let fileSafe = file.name.replace(/[^a-z0-9]/gi, '-');
    let fileNew = `${fileSafe}_${uuidv4()}`;
    if (fileSplit.length > 1) {
      fileNew = fileNew + '.' + fileSplit[fileSplit.length - 1];
    }

    let id = this.props.idContext; //Dependenddo do contexto, será ClientId ou PartnerId ou ProjectId

    let key = `${id}/${fileNew}`; //Caminho final do arquivo - contexto/nome-unico-arquivo.txt

    //Access Token vindo do Cognito
    let authHeader = {
      headers: { Authorization: 'Bearer ' + this.props.auth.jwtToken },
    };

    //EndPoint para pegar autorização para subir o arquivo. passar a chave (key) na queryString
    let preSignUrl = `${EndpointGetAuthToUpload}?k=${key}${
      this.props.get2 ? '&t=pleader' : ''
    }`;

    axios
      .get(preSignUrl, authHeader)
      .then((resp) => {
        let { url, fields } = resp.data;

        let formData = new FormData();

        //Dados recebidos para autorizar o upload do arquivo
        Object.keys(fields).forEach((key) => {
          formData.append(key, fields[key]);
        });

        //arquivo

        formData.append('file', file);

        var config = {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        };

        //tendo sucesso, fazer POST do arquivo com os dados de autorização
        axios
          .post(url, formData, config)
          .then((response) => {
            this.setState({
              statusCode: response.status,
              imgPreview: resp.data.url + '/' + resp.data.fields.key,
            });

            let urlClean =
              'https://' + resp.data.url.split('s3.amazonaws.com/')[1] + '/';

            let dataForm = {
              field: 'chat_file',
              url: urlClean + resp.data.fields.key,
            };
            this.props.getDataUpload(dataForm);
          })
          .catch((error) => {

            notification.error({
              message: 'ops! Algo deu errado.',
              description: 'Tente novamente por favor.',
            });
            this.props.stopLoading();
          });
      })
      .catch((error) => {

        notification.error({
          message: 'ops! Algo deu errado.',
          description: 'Tente novamente por favor.',
        });
        this.props.stopLoading();
      });
  }

  handleClick() {
    if (this.inputFileRef) {
      this.inputFileRef.click();
    }
  }

  render() {
    const icon = this.props.icon;
    return (
      <>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => this.handleInputChange(e)}
          ref={this.setInputFileRef}
        />

        <span
          style={this.props.style}
          className="icofile"
          onClick={() => this.handleClick()}
        >
          {icon ? (
            <img src={this.props.icon} alt="Icon" />
          ) : (
            <Icon type="upload" />
          )}
        </span>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatFileUpload);
