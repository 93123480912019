import React from 'react';
import * as S from './styles';
import moment from 'moment';

const NewsInfo = ({date, time, author}) => {

    return (
        <S.DivInfos>
            <S.DivContentDate>{moment(date).format('DD/MM/YY')}</S.DivContentDate>
            <S.DivContentTime>{time} min</S.DivContentTime>
            <S.DivContentAuthor>{author}</S.DivContentAuthor>
        </S.DivInfos>
    )
}

export default NewsInfo;