import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { SelectOne } from "../../../../components/UI/SelectOne";

import * as S from "./styles";

export const ButtonExpandBoxSelect = ({
  label,
  sectionCode,
  sublabel,
  description,
  active,
  expanded,
  subs,
  handleSelectItem,
  full,
  error,
  setYear,
  setMonth,
}) => {
  const [expand, setExpand] = useState(active);
  const [activedSubs, setActivedSubs] = useState([]);

  const handleClick = () => {
    if (expand) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  };

  const arrayYear = [
    { code: 1, label: `1 ${I18n.get("year")}` },
    { code: 2, label: `2 ${I18n.get("years")}` },
    { code: 3, label: `3 ${I18n.get("years")}` },
    { code: 4, label: `4 ${I18n.get("years")}` },
    { code: 5, label: `5 ${I18n.get("years")}` },
    { code: 6, label: `6 ${I18n.get("years")}` },
    { code: 7, label: `7 ${I18n.get("years")}` },
    { code: 8, label: `8 ${I18n.get("years")}` },
    { code: 9, label: `9 ${I18n.get("years")}` },
    { code: 10, label: `10 ${I18n.get("years")}` },
    { code: 11, label: `11 ${I18n.get("years")}` },
    { code: 12, label: `12 ${I18n.get("years")}` },
    { code: 13, label: `13 ${I18n.get("years")}` },
    { code: 14, label: `14 ${I18n.get("years")}` },
    { code: 15, label: `15 ${I18n.get("years")}` },
    { code: 16, label: `16 ${I18n.get("years")}` },
    { code: 17, label: `17 ${I18n.get("years")}` },
    { code: 18, label: `18 ${I18n.get("years")}` },
    { code: 19, label: `19 ${I18n.get("years")}` },
    { code: 20, label: `20 ${I18n.get("years")}` },
  ];

  const arrayMonth = [
    { code: 0, label:  `0 ${I18n.get("mês")}` },
    { code: 1, label:  `1 ${I18n.get("mês")}` },
    { code: 2, label:  `2 ${I18n.get("meses")}` },
    { code: 3, label:  `3 ${I18n.get("meses")}` },
    { code: 4, label:  `4 ${I18n.get("meses")}` },
    { code: 5, label:  `5 ${I18n.get("meses")}` },
    { code: 6, label:  `6 ${I18n.get("meses")}` },
    { code: 7, label:  `7 ${I18n.get("meses")}` },
    { code: 8, label:  `8 ${I18n.get("meses")}` },
    { code: 9, label:  `9 ${I18n.get("meses")}` },
    { code: 10, label: `10 ${I18n.get("meses")}`  },
    { code: 11, label: `11 ${I18n.get("meses")}`  },
    { code: 12, label: `12 ${I18n.get("meses")}`  },
  ];

  const handleClickSub = async (code, type) => {
    handleSelectItem({
      item: {
        code: code,
        type: type,
      },
      sectionCode: sectionCode,
    });
  };

  const handleYear = async (code, type, year) => {
    setYear({
      item: {
        code: code,
        type: type,
      },
      sectionCode: sectionCode,
      years: parseInt(year),
    });
  };

  const handleMonth = async (code, type, month) => {
    setMonth({
      item: {
        code: code,
        type: type,
      },
      sectionCode: sectionCode,
      months: parseInt(month),
    });
  };

  useEffect(() => {
    if (expanded) {
      setExpand(expanded);
    }
  }, [expanded]);

  return (
    <S.Wrapper>
      <S.Button active={active} onClick={handleClick} error={error}>
        <div>{label}</div>
        {sublabel ? <S.SubLabel>{sublabel}</S.SubLabel> : null}
      </S.Button>

      {expand ? (
        <S.Expanded>
          <S.TextExpanded>{I18n.get(description)}</S.TextExpanded>
          <S.ExpandedContent>
            <S.WrapperUserMaster>
              <S.TitleSectioon>{I18n.get("Usuário master")}</S.TitleSectioon>

              <S.Subs>
                {subs?.map((sub) => {
                  if (sub.type == 2) {
                    return (
                      <li key={sub.code}>
                        <S.ButtonSub
                          active={sub.selected}

                          // disabled={full}
                        >
                          <span>{sub.label}</span>
                          <Checkbox
                            checked={sub.selected}
                            onChange={() => handleClickSub(sub.code, sub.type)}
                          />
                          {/* {sub.active ? <MinusOutlined /> : <PlusOutlined />} */}

                          {sub.selected && (
                            <div className="selectBox">
                              <div className="item">
                                <SelectOne
                                  options={arrayYear}
                                  value={sub.years}
                                  // defaultValue={onEditing ? inputsForm.year : ''}
                                  onChange={(value) =>
                                    handleYear(sub.code, sub.type, value)
                                  }
                                  required
                                  placeholder={I18n.get("Years")}
                                  // error={errrorsInternalForm?.industry}
                                />
                              </div>
                              <div className="item">
                                <SelectOne
                                  options={arrayMonth}
                                  value={parseInt(sub.months)}
                                  // defaultValue={onEditing ? inputsForm.year : ''}
                                  onChange={(value) =>
                                    handleMonth(sub.code, sub.type, value)
                                  }
                                  required
                                  placeholder={I18n.get("Months")}
                                  // error={errrorsInternalForm?.industry}
                                />
                              </div>
                            </div>
                          )}
                        </S.ButtonSub>
                      </li>
                    );
                  }
                })}
              </S.Subs>
            </S.WrapperUserMaster>
            <S.WrapperCompany>
              <S.TitleSectioon>{I18n.get("Company")}</S.TitleSectioon>
              <S.Subs>
                {subs?.map((sub) => {
                  if (sub.type == 1) {
                    return (
                      <li key={sub.code}>
                        <S.ButtonSub
                          active={sub.selected}
                          // disabled={full}
                        >
                          <span>{sub.label}</span>
                          <Checkbox
                            checked={sub.selected}
                            onChange={() => handleClickSub(sub.code, sub.type)}
                          />

                          {sub.selected && (
                            <div className="selectBox">
                              <div className="item">
                                <SelectOne
                                  options={arrayYear}
                                  value={sub.years}
                                  // defaultValue={onEditing ? inputsForm.year : ''}
                                  onChange={(value) =>
                                    handleYear(sub.code, sub.type, value)
                                  }
                                  required
                                  placeholder={I18n.get("Years")}
                                  // error={errrorsInternalForm?.industry}
                                />
                              </div>
                              <div className="item">
                                <SelectOne
                                  options={arrayMonth}
                                  value={parseInt(sub.months)}
                                  // defaultValue={onEditing ? inputsForm.year : ''}
                                  onChange={(value) =>
                                    handleMonth(sub.code, sub.type, value)
                                  }
                                  required
                                  placeholder={I18n.get("Months")}
                                  // error={errrorsInternalForm?.industry}
                                />
                              </div>
                            </div>
                          )}
                        </S.ButtonSub>
                      </li>
                    );
                  }
                })}
              </S.Subs>
            </S.WrapperCompany>
          </S.ExpandedContent>
        </S.Expanded>
      ) : null}
    </S.Wrapper>
  );
};
