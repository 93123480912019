import get_reference from './get';
import update_reference from './update';
import update_person from './update-person';

export const REFERENCE_GET = 'reference_get';
export const REFERENCE_UPDATE = 'reference_update';

export const REFERENCE_API = {
    get: get_reference,
    update: update_reference,
    update_person: update_person,
}
