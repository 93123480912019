import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import {
    Icon,
    Avatar
} from 'antd';

import Carousel from '../components/Carousel';
import { TitleMiddleLine } from '../components/Titles';

import IcoInfo from '../../../image/ico-infos.png';
import IcoHabilidades from '../../../image/ico-habilidades.png';
import IcoIdiomas from '../../../image/ico-idiomas.png';
import IcoMarcas from '../../../image/ico-marcas.png';
import IcoPortifolio from '../../../image/ico-portifolio.png';



const MainInfos = styled.div`
  .PartnerName {
      padding: 50px 0 0px;
      overflow: hidden;

      h2 {
          font-size: 20px;
          font-weight: 600;
      }

      h4 {
          font-size: 13px;
          font-weight: 600;
          line-height: 25px;
      }

      .ThumbAvatar {
          float: left;
          margin-right: 50px;
          position: relative;
          top: -40px;
      }
  }

  .boxPreview {
      margin-bottom: 60px;
      background: no-repeat left top;

      &.PartnerInfos {
          background-image: url(${IcoInfo});
      }

      &.PartnerHabilidades {
          background-image: url(${IcoHabilidades});
      }

      &.PartnerIdiomas {
          background-image: url(${IcoIdiomas});
      }

      &.PartnerMarcas {
          background-image: url(${IcoMarcas});
      }

       &.PartnerPortfolio {
          background-image: url(${IcoPortifolio});
          min-height: 100px;

          h3  {
              margin-bottom: 20px;
          }

          a {
              display: inline-block;
              padding: 2px 0;
              font-size: 13px;
              font-weight: 600;
              line-height: 20px;
              color: #353535;
              transition: all .4s ease;

              i {
                  color: #000;
                  margin-right: 5px;
                  font-size: 16px;
                  position: relative;
                  top: 1px;
                  transition: all .4s ease;
                  padding: 5px;
                  border-radius: 50%;
              }

              &:hover, &:focus {
                  color: #000;


                  i {
                      background: #fca99c;
                  }
              }
          }
      }
  }

  .boxCard {
      padding: 0 90px;
      margin-bottom: 30px;

      h3 {
          font-size: 15px;
          font-weight: 700;
          line-height: 15px;
      }

      p {
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
      }
  }

  .ItemCase {
      margin-bottom: 80px;
      h4 {
        font-size: 13px;
        font-weight: 700;
        line-height: 37px;
      }

      p {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
      }

      .ItemImg {
          margin: 0 1px;
      }

      .CarouselBox {
          margin-top: 50px;
      }
  }
`;


class PartnerInfos extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderItemCases = (item) => {

        const {
            caseId,
            name,
            images,
            videos,
            year = "--",
            description,

        } = item;

        return (
            <div className="ItemCase">
                <TitleMiddleLine>
                    {name}
                </TitleMiddleLine>

                <h4>Ano: {year}</h4>
                <p>{description}</p>

                {images &&

                    <div className="CarouselBox">
                        <Carousel
                            dots={false}
                            infinite={true}
                            slidesToShow={item.images.length == 1 ? 1 : 2}
                            slidesToScroll={1}
                            variableWidth={false}
                        >
                            {images.map((item, index) => {
                                return (
                                    <div key={index} className="ItemImg">
                                        <img src={item} />
                                    </div>
                                )
                            })}

                        </Carousel>
                    </div>
                }

                <h4>Vídeos</h4>
                {videos &&
                    <ul className="ListDinamic">
                        {videos.map(item => {
                            return <li key={item}><a href="#" target="_blank"><Icon type="video-camera" /> {item}</a></li>
                        })
                        }
                    </ul>
                }
            </div>
        );


    }

    renderOccupationLevel = (item) => {

        switch (item) {
            case "baixo":
                return "Baixo - Menos de 5 anos de experiência";
                break;

            case "medio":
                return "Médio - de 5 a 10 anos de experiência";
                break;

            case "avancado":
                return "Avançado - Mais de 10 anos de experiência";
                break;

            default:
                break;
        }

    }

    render() {
        const {
            isClient,
            infos: {
                name,
                occupation,
                genre,
                email,
                phoneNumber,
                phoneNumberCel,
                address,
                city,
                state,
                companyRegistrationId,
                skills,
                occupationLevel,
                profileTags,
                brandsAlreadyWorked,
                marketsAlreadyWorked,
                socialURLs,
                cases,
                imageLogo
            }

        } = this.props;

        return (
            <MainInfos>
                <div className="PartnerName boxPreview">
                    {isClient &&
                        <Avatar className="ThumbAvatar" size={205} icon="user" src={imageLogo ? imageLogo : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRjxdE_fZ5XjTyLxIQ9fDoHn4OysIuHiLCHOYDcozZWcgCu-SIX"} />
                    }
                    <div className="boxCard">
                        <h2>{name}</h2>
                        <h4>{occupation}</h4>
                        {/* <RatingPreview value={3.5} /> */}
                    </div>
                </div>
                <div className="PartnerInfos boxPreview">
                    <div className="boxCard">
                        <h3>Infos</h3>
                        <p>{name}</p>
                        <p>Gênero: {genre}</p>
                    </div>

                    <div className="boxCard">
                        <h3>Contato</h3>
                        <p>{email}</p>
                        <p>Tel.: {phoneNumber}</p>
                        <p>Cel.: {phoneNumberCel} </p>
                    </div>
                    <div className="boxCard">
                        <h3>Endereço</h3>
                        <p>{address} {city ? `- ${city} ` : ""} {state ? `/ ${state}` : ""}</p>
                    </div>
                    <div className="boxCard">
                        <h3>Empresa</h3>
                        <p>{companyRegistrationId}</p>
                    </div>
                </div>
                <div className="PartnerHabilidades boxPreview">
                    <div className="boxCard">
                        <h3>Habilidades</h3>
                        <p>
                            {skills && skills.map((item, index) => {
                                let n = skills.length - 1;

 

                                if (index < n) {
                                    return <span>{item}, </span>
                                } else {
                                    return <span>{item}</span>
                                }
                            })}
                        </p>
                    </div>
                    <div className="boxCard">
                        <h3>Experiência profissional</h3>
                        <p>{occupationLevel && this.renderOccupationLevel(occupationLevel)}</p>
                    </div>
                    <div className="boxCard">
                        <h3>O que me define:</h3>
                        <p>
                            {profileTags && profileTags.map((item, index) => {
                                let n = profileTags.length - 1;

                                if (index < n) {
                                    return <span key={index}>{item}, </span>
                                } else {
                                    return <span key={index}>{item}</span>
                                }
                            })}
                        </p>
                    </div>
                </div>
                <div className="PartnerIdiomas boxPreview">
                    <div className="boxCard">
                        <h3>Idiomas</h3>
                        <p>Português, Inglês Avançado e Espanhol Básico</p>
                    </div>
                </div>
                <div className="PartnerMarcas boxPreview">
                    <div className="boxCard">
                        <h3>Marcas que já trabalhei</h3>

                        <p>
                            {brandsAlreadyWorked && brandsAlreadyWorked.map((item, index) => {
                                let n = brandsAlreadyWorked.length - 1;

                                if (index < n) {
                                    return <span key={index}>{item}, </span>
                                } else {
                                    return <span key={index}>{item}</span>
                                }
                            })}
                        </p>
                    </div>

                    <div className="boxCard">
                        <h3>Quais mercados já trabalhei</h3>
                        <p>
                            {marketsAlreadyWorked && marketsAlreadyWorked.map((item, index) => {
                                let n = marketsAlreadyWorked.length - 1;

                                if (index < n) {
                                    return <span key={index}>{item}, </span>
                                } else {
                                    return <span key={index}>{item}</span>
                                }
                            })}
                        </p>
                    </div>
                </div>
                <div className="PartnerPortfolio boxPreview">
                    <div className="boxCard">
                        <h3>Portfolio e redes sociais</h3>

                        {socialURLs && socialURLs.map((item, index) => {
                            return <p key={index}><a href={item} target="_blank"><Icon type="link" /> {item}</a></p>
                        })}
                    </div>
                </div>

                <section className="CasesList">
                    {!cases &&
                        <p>Nenhum case cadastrado!</p>
                    }
                    {cases && cases.map((item, index) => {
                        return this.renderItemCases(item)
                    })}
                </section>
            </MainInfos>
        );
    }
}


export default PartnerInfos;
