import React from 'react';

import { I18n } from 'aws-amplify';

import { ProjectType } from '../../../../../../../model';
import iconCriacao from '../../../../../../../image/icot1-dark.svg';
import iconEvento from '../../../../../../../image/icot3-dark.svg';
import iconPitch from '../../../../../../../image/class.svg';

import * as S from './styles';

import { Row, Col, Tooltip, Icon } from 'antd';

const CreativeOrEvent = (props) => {
  let isCreative = props.projectType === ProjectType.Creative;
  let isEvent = props.projectType === ProjectType.EventCustom;
  let isBPitch = props.projectType === ProjectType.BPitch;
  // let isEvent = props.projectType === ProjectType.EventCustom || props.projectType === ProjectType.EventBundle;

  // let isSelected = isCreative || isEvent   TODO: Original eventos;
  let isSelected = isCreative;

  let creativeCss = isCreative ? 'is-selected' : '';
  let eventCss = isEvent ? 'is-selected' : '';
  let bpitchCss = isBPitch ? 'is-selected' : '';

  if (props.types && props.types.length == 0) return null;

  return (
    <div className="selectProject" style={{ paddingTop: '10px' }}>
      <Row>
        <Col sm={24}>
          <div className="field">
            <label className="title-bpool medium tmargin">
              {I18n.get('Tipo do Projeto')}
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        {props?.types.indexOf(0) != -1 && (
          <Col sm={8}>
            <S.ItemButton
              disabled={props.disabled}
              className={creativeCss + ' comunicacao'}
              onClick={() => props.onSelectType(0)}
            >
              <img src={iconCriacao} alt={I18n.get('BPool Marketplace')} />
              <span>{I18n.get('BPool Marketplace')}</span>

              <span className="Info">
                <Tooltip
                  title={I18n.get(
                    'Criação e produção de projetos de Marketing que não são relacionados a eventos, por exemplo websites, landing pages, CRM, campanhas digitais, etc'
                  )}
                >
                  <Icon type="question-circle" theme="filled" />
                </Tooltip>
              </span>
            </S.ItemButton>
          </Col>
        )}

        {props?.types.indexOf(2) != -1 && (
          <Col sm={8}>
            <S.ItemButton
              disabled={props.disabled}
              className={`live ${eventCss}`}
              onClick={() => props.onSelectType(2)}
            >
              <img
                src={iconEvento}
                alt={I18n.get('BPool Proposal')}
                style={{ left: '38%' }}
              />
              <span>{I18n.get('BPool Proposals')}</span>

              <span className="Info">
                <Tooltip
                  title={I18n.get(
                    'Propostas customizadas com cotação livre para o parceiro.'
                  )}
                >
                  <Icon type="question-circle" theme="filled" />
                </Tooltip>
              </span>
            </S.ItemButton>
          </Col>
        )}

        {props?.types.indexOf(4) != -1 && (
          <Col sm={8}>
            <S.ItemButton
              disabled={props.disabled}
              className={`pitch  ${bpitchCss}`}
              onClick={() => props.onSelectType(4)}
            >
              <img src={iconPitch} alt={I18n.get('BPitch')} />
              <span>{I18n.get('BPitch')}</span>
              <span className="Info">
                <Tooltip
                  title={I18n.get(
                    'Assessoria de clientes na definição de parceiros ideias e no processo de concorrência'
                  )}
                >
                  <Icon type="question-circle" theme="filled" />
                </Tooltip>
              </span>
            </S.ItemButton>
          </Col>
        )}
      </Row>

    </div>
  );
};

export default CreativeOrEvent;
