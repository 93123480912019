import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CLIENT_USER_API } from "../../../actions/client-user/actions";
import { PartnerStatus } from "../../../model";
import Loading from "../../../components/pages/loading";
import { QueryString } from "../../../utils/querystring";

const mapStateToProps = (state) => {
  return { client_user: state.client_user, auth: state.auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirm: (data, locale) => {
      dispatch(CLIENT_USER_API.confirm(data, locale));
    },
  };
};

class UserClientVerifyEmail extends React.Component {
  constructor(props) {
    super();

    this.state = {
      locale: "pt",
      verificationToken: props.match.params.verificationToken,
    };
  }

  componentWillMount() {
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    this.setState({
      locale: locale,
    });

    I18n.setLanguage(locale);

    let token = {
      verificationToken: this.props.match.params.verificationToken,
      locale: locale,
    };

    this.props.confirm(token, locale);
  }

  render() {
    if (!this.props.client_user || !this.props.client_user.id)
      return <Loading></Loading>;

    if (this.props.client_user && this.props.client_user.verificationToken) {
      if (!this.props.client_user.completedOn) {
        let redirect = `/cliente/usuario/cadastro/sobre-voce/${this.props.client_user.verificationToken}`;

        if (this.state.locale != "pt") {
          redirect = redirect + `?locale=${this.state.locale}`;
        }

        return <Redirect to={redirect} />;
      } else {
        return <Redirect to="/cliente/usuario/cadastro/sucesso" />;
      }
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserClientVerifyEmail);
