import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import {
    Table,
    Icon
} from 'antd';

import ItemPortfolio from './ItemPortfolio';

const Btns = styled.p`
width: 50px;
displa: block;
    a {
        margin-left: 10px;
    }
`


class tablePortfolio extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const columns = [
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'item',
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                    }

                    if (this.props.isLeader) {
                        data.leaderId = this.props.partnerId;
                    } else {
                        data.partnerId = this.props.partnerId;
                    }

                    return (
                        <Btns>
                            <a onClick={() => this.props.edit(record.item)}>
                                <Icon type="edit" theme="filled" />
                            </a>
                            <a onClick={() => this.props.delete(data)}>
                                <Icon type="delete" theme="filled" />
                            </a>
                        </Btns>
                    )
                }
                // render: (record) => this.props.delMarketExpertise(record.item.id),
            },
        ]

        let dataCases = this.props.data && this.props.data.map((item, index) => {
            return {
                key: index,
                name: item.name,
                item: item
            }
        });

        return (
            <Table
                columns={columns}
                dataSource={dataCases}
                scroll={{ x: 640 }}
                locale={{ emptyText: I18n.get('Sem informação') }}
                expandedRowRender={(record) => <ItemPortfolio item={record.item} specialismsBP={this.props.specialismsBP} specialisms={this.props.specialisms} />}
            />
        );
    }
}

export default tablePortfolio;
