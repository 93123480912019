import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';

import CheckboxField from '../form/checkbox-field';
import SubmitButtonField from '../form/submit-button-field';

import { scrollToTop } from '../../utils/ui';

class TermsForm extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            fields: {
                terms: {
                    value: true,
                    onChange: this.onChangeFormCheckbox.bind(this, 'terms'),
                    successIcon: false,
                    checkmarkStyle: {
                        backgroundColor: '#ffffff'
                    }
                },
            },
            errors: {},
            form: {},
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeFormCheckbox(prop, e) {

        const value = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }


    onSubmit(e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.onApprove()
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

            });
    }


    render() {

        const project = this.props.project || {};

        const { isPartnerSelectionBidding, isCreative, partnerName } = project;

        let terms = '';

        if (isPartnerSelectionBidding) {

            terms = `${I18n.get('DECLARO que aceito integralmente os termos do Projeto definido junto à empresa')} ${partnerName}${I18n.get(', cujos termos negociados encontram-se nesta aba de “Proposta”')}`;

        }
        else {
            terms = `${I18n.get('DECLARO que aceito integralmente os termos do Projeto definido junto à empresa')} ${partnerName}${I18n.get(', cujos termos negociados encontram-se nesta aba de “Review”')}`;
        }


        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Aceite")}</h1>

                <div style={{ marginTop: '3rem', maxWidth: '50rem' }}>

                    <CheckboxField
                        {...this.state.fields.terms}
                        label={terms}
                        checked={this.state.form.terms}
                        error={this.state.errors.terms} />



                    <p className="pullcenter">
                    <SubmitButtonField
                        label={I18n.get("Confirmar")}
                        className="button bt-bpool big"
                        task={this.state.submit_task}
                        onClick={this.onSubmit.bind(this)}
                        disabled={!this.state.form.terms}
                    />
                    </p>


                </div>
            </div>
        );
    }
}

export default TermsForm;
