import React from "react";
import { I18n } from "aws-amplify";
// import "./modalconfirm-styles.css";

import * as S from "./styles";

import Logo from "../../../media/img/logo.png";
import LogoBlack from "../../svgs/logo-bp-black.svg";
import IcoConfirm from "../../../media/svg/ico-confirm-modal.svg";
import IcoAlert from "../../../media/svg/ico-alert-modal.svg";

export const ModalBp = ({
  visible,
  bodyText,
  subBodyText,
  okText,
  handleClickOk,
  typeModal,
  cancelText,
  isDanger,
  isAlert,
  onCancel,
  actionBp,
  isLoading,
  children,
  maskClosable,
  buttonOkDisabled,
  whiteModal,
}) => {
  return (
    <S.MainModal
      isDanger={isDanger}
      centered
      visible={visible}
      onOk={handleClickOk}
      confirmLoading={isLoading}
      okText={okText}
      okButtonProps={{
        disabled: buttonOkDisabled !== undefined ? buttonOkDisabled : false,
      }}
      onCancel={onCancel || handleClickOk}
      cancelText={cancelText || I18n.get("Voltar")}
      wrapClassName={`modalbp ${actionBp}`}
      maskClosable={maskClosable || false}
      cancelButtonProps={{
        style: {
          display: typeModal != "isConfirm" ? "none" : "",
        },
      }}
      whiteModal={whiteModal}
    >
      <S.Logo>
        <img src={whiteModal ? LogoBlack : Logo} alt="Bpool" />
      </S.Logo>

      <S.Icone>
        {isAlert ? (
          <img src={IcoAlert} alt="Ico confirm modal" />
        ) : (
          <img src={IcoConfirm} alt="Ico confirm modal" />
        )}
      </S.Icone>
      <S.TextModal whiteModal={whiteModal}>{bodyText}</S.TextModal>
      {subBodyText ? (
        <S.SubText
          whiteModal={whiteModal}
          dangerouslySetInnerHTML={{ __html: subBodyText }}
        />
      ) : null}
      {children ? <S.Content>{children}</S.Content> : null}
    </S.MainModal>
  );
};
