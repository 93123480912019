import update_idea from './idea-update';
import create from './create';
import upload from './upload';
import api_get_ideas from './get-ideas';
import get_idea_detail from './get-detail-idea';
import decline_offer from './decline-offer';
import api_get_my_ideas from './get-my-ideas';
import decline from './decline';
import accept from './accept';
import approve_idea from './approve-idea';
import favorite_idea from './favorite-idea';
import api_search_ideas from './search-ideas';

import decline_idea from './decline-idea';
import add_po from './add-po';

import delete_detail from './delete_itens'

export const IDEA_UPDATE = 'idea_whatif_update';
export const IDEA_CREATE = 'idea_whatif_create';
export const IDEAS_GET = 'idea_whatif_get_ideas';
export const IDEA_GET_DETAIL = 'idea_whatif_get_detail';
export const IDEA_UPDATE_OFFER = 'idea_whatif_update_OFFER';
export const IDEAS_SEARCH = 'idea_whatif_search_ideas';
export const ITENS_DELETE = 'idea_whatif_delete_ideas';


export const IDEA_WHATIF_API = {
    create: create,
    update_idea: update_idea,
    upload: upload,
    api_get_ideas: api_get_ideas,
    get_idea_detail: get_idea_detail,
    decline_offer: decline_offer,
    api_get_my_ideas: api_get_my_ideas,
    accept: accept,
    decline: decline,
    approve_idea: approve_idea,
    decline_idea: decline_idea,
    favorite_idea: favorite_idea,
    add_po: add_po,
    search_ideas: api_search_ideas,
    delete_detail: delete_detail
}
