import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import { Rate } from 'antd';
import styled from 'styled-components';

const Rating = styled.span`
    .ant-rate-text {
        color: #535353;
        font-size: 11px;
        font-weight: 400;
        line-height: 20px;
    }

    .DigValue {
        color: #acacac;
        font-size: 16px;
        font-weight: 700;
        line-height: 37px;
        margin-right: 10px;
    }
`

class RatingPreview extends PureComponent {
    render() {

        const { value } = this.props;
        return (
            <Rating>
                <span className="DigValue">{value}</span>
                <Rate disabled allowHalf value={value} />
                {value == 0 ? <span className="ant-rate-text">Ainda não foi avaliada.</span> : ''}
            </Rating>
        );
    }
}

export default RatingPreview;
