import React from "react";
import { I18n } from "aws-amplify";

import Steps from "../../../../../components/UI/Steps";

export const StepsCreateProject = ({ active, disabledTabs = [], projectId }) => {
  const stepItens = [
    {
      key: 1,
      label: I18n.get("Informações"),
      url: `/parceiro/projetos/v2/criar/informacoes/${projectId}`,
    },
    {
      key: 2,
      label: I18n.get("Briefing"),
      url: `/parceiro/projetos/v2/criar/briefing/${projectId}`,
    },
    {
      key: 3,
      label: I18n.get("Tipo"),
      url: `/parceiro/projetos/v2/criar/tipo/${projectId}`,
    },
    {
      key: 4,
      label: I18n.get("Resumo"),
      url: `/parceiro/projetos/v2/criar/resumo/${projectId}`,
    },
  ];

  return (
    <Steps itens={stepItens} active={active} disabledTabs={disabledTabs} />
  );
};
