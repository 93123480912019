export class Task {
    start() {
        this.start_date = new Date();
        this.busy = true;
        this.id = new Date().getTime();

        return this;
    }

    complete_as_success() {
        this.busy = false;
        this.completed = true;
        this.result = true;
        this.end_date = new Date();
    }

    complete_as_failure(error) {
        this.busy = false;
        this.completed = true;
        this.result = false;
        this.end_date = new Date();
        this.error = error;
    }
}

