import React, { useState } from 'react';

import * as S from './styles';

export const TabsSelect = ({ tabs, tabDefault, onChange }) => {
  const [tabSelect, setTabSelect] = useState(tabDefault ? tabDefault : 0);

  const renderComponent = () => {
    const tabActive = tabs.find((tab) => tab.key === tabSelect);

    return tabActive.component;
  };

  const handleChangeTab = ({ key }) => {
    setTabSelect(key);
    onChange(key);
  };

  return (
    <S.Wrapper>
      <S.HeaderTabs>
        {tabs.map((tab) => (
          <S.ButtonTab
            key={tab.key}
            onClick={() => handleChangeTab({ key: tab.key })}
            active={tabSelect === tab.key ? true : false}
          >
            {tab.label}
          </S.ButtonTab>
        ))}
      </S.HeaderTabs>
      <S.ContentTab>{renderComponent()}</S.ContentTab>
    </S.Wrapper>
  );
};
