import React from "react";
import { I18n } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { Task } from "../../utils/task";

import * as JsSearch from "js-search";
import ItemAssetCart from "./item-asset-cart";
import AssetCustomize from "./_asset-customize";
import icoWhatsapp from "../../image/icow.png";
import {TextareaHourDescription} from "../../v2/components/Shared/TextareaHourDescription";

import { Row, Col, Form, Input, Select, Button } from "antd";

import FormatCurrency from "../ui/format-currency";

const { Option } = Select;

class AssetCustomizeForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isMobile: true,
      specialismMap: {},
      assetTypeMap: {},
      page: 1,
      task: {},
      assets: [],
      loadingCheck: false,
    };
  }

  renderBtn = () => {
    if (this.props.client.signupLocale === "pt") {
      return (
        <a
          className="button bt-bpool black"
          href="https://api.whatsapp.com/send?phone=+5511974721502"
          target="blank"
        >
          <img src={icoWhatsapp} /> <span> {I18n.get("Clique aqui")} </span>
        </a>
      );
    } else if (this.props.client.signupLocale === "en") {
      return (
        <a
          className="button bt-bpool black"
          href="https://api.whatsapp.com/send?phone=+16469189446"
          target="blank"
        >
          <img src={icoWhatsapp} /> <span> {I18n.get("Clique aqui")} </span>
        </a>
      );
    } else {
      return (
        <a
          className="button bt-bpool black"
          href="https://api.whatsapp.com/send?phone=+525539884303"
          target="blank"
        >
          <img src={icoWhatsapp} /> <span> {I18n.get("Clique aqui")} </span>
        </a>
      );
    }
  };

  handleUpdate = () => {
    const dataGet = {
      clientId: this.props.clientId,
      projectId: this.props.projectId,
    };

    this.props.get_project(dataGet);
  };

  componentDidMount() {
    this.setState({
      isMobile: window.innerWidth < 768,
      assets: this.props.project.assets && this.props.project.assets,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (this.props.project.assets != prevProps.project.assets) {
      this.setState({
        assets: this.props.project.assets,
      });
    }
  }

  onChangeNew = (e, field) => {
    let assetsFilter = this.props.project.assets;
    const fieldsForm = this.props.form.getFieldsValue();

    if (fieldsForm.assetSpecialism) {
      assetsFilter = assetsFilter.filter(
        (asset) =>
          asset.specialism &&
          asset.specialism.indexOf(fieldsForm.assetSpecialism) > -1
      );
    }

    if (fieldsForm.assetType) {
      assetsFilter = assetsFilter.filter(
        (asset) => asset.type && asset.type.indexOf(fieldsForm.assetType) > -1
      );
    }

    var search = new JsSearch.Search("code");
    search.addIndex("label");
    search.addDocuments(assetsFilter);

    if (fieldsForm.search && !e.target) {
      assetsFilter = search.search(fieldsForm.search);
    }

    if (field == "search" && e.target.value.length > 0) {
      assetsFilter = search.search(e.target.value);
    }

    setTimeout(() => {
      this.setState({
        assets: assetsFilter,
      });
    }, 300);
  };

  // getSelectedSpecilism() {
  //     return this.state.form.assetSpecialism.value || this.props.cartSpecialismGroup;
  // }

  setPage(page, e) {
    this.setState({ page: page });
  }

  getData() {
    var data = {
      clientId: this.props.clientId,
      ...this.props.project,
    };

    return data;
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  handleBundleBtnClick = () => {
    this.props.onBundleBtnClick();
  };

  handleResumeBtnClick = () => {
    this.setState({
      loadingCheck: true,
    });
    const cart = this.props.cartAssets;

    const data = {
      projectId: this.props.project.projectId,
      clientId: this.props.project.clientId,
      assets: this.props.cartAssets,
    };

    this.props.checkoutv2(data).then((response) => {
      this.props.onResumeBtnClick(cart);
      this.setState({
        loadingCheck: false,
      });
    });
  };

  renderBox = (disableBundle, disableCart) => {
    if (disableBundle === true && disableCart === false) {
      return (
        <div className="columns columns-for-np customized-projects-header">
          <div className="column left-column-for-np is-6">
            <div className="boxTitle left">
              <h1 className="title-bpool medium">
                {I18n.get("Monte Seu Projeto")}
              </h1>
              <p className="regular-control-text">
                {I18n.get(
                  "Para criar o seu projeto a partir de diferentes assets, é preciso definir uma especialidade. Como a plataforma trabalha com parceiros criativos especialistas, não é possível misturar assets de diferentes especialidades em um mesmo projeto. Por exemplo, um parceiro especialista em redes sociais não será um especialista em embalagens."
                )}
              </p>
            </div>
          </div>
          <div className="column left-column-for-np is-6">
            <div className="boxTitle right">
              <h1 className="title-bpool medium">
                {I18n.get("Pré-escopo por áudio")}
              </h1>
              <p className="regular-control-text">
                {I18n.get(
                  "Você também pode montar este pré-escopo por áudio. Envie uma mensagem de voz para nosso time explicando sua necessidade que faremos uma sugestão com as melhores alternativas na plataforma."
                )}
              </p>
              <p className="btnWhatsapp">{this.renderBtn()}</p>
            </div>
          </div>
        </div>
      );
    }
    // Mostra bloco com click pra lista de combos
    if (disableBundle === false && disableCart === false) {
      return (
        <div className="columns columns-for-np customized-projects-header">
          <div className="column left-column-for-np is-9 ">
            <div className="boxTitle left">
              <h1 className="title-bpool medium">
                {I18n.get("Monte Seu Projeto")}
              </h1>
              <p className="regular-control-text">
                {I18n.get(
                  "Para criar o seu projeto a partir de diferentes assets, é preciso definir uma especialidade. Como a plataforma trabalha com parceiros criativos especialistas, não é possível misturar assets de diferentes especialidades em um mesmo projeto. Por exemplo, um parceiro especialista em redes sociais não será um especialista em embalagens."
                )}
              </p>
            </div>
          </div>
          <div className="column left-column-for-np is-3 has-text-centered">
            <div className="boxTitle right">
              <h1 className="title-bpool medium">
                {I18n.get("Prefere projetos")}
                <br></br>
                {I18n.get("pré-definidos?")}
              </h1>
              <div className="field">
                <label className="label is-small">&nbsp;</label>
                <p className="control is-expanded centered-button">
                  <button
                    type="button"
                    className="button bt-bpool black"
                    onClick={this.handleBundleBtnClick}
                  >
                    {I18n.get("Clique")} <br className="mobile-only" />
                    {I18n.get("aqui")}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (disableBundle === false && disableCart === true) {
      return (
        <div className="columns columns-for-np customized-projects-header">
          <div className="column left-column-for-np is-12 green">
            <div className="boxTitle left">
              <h1 className="subtitle">{I18n.get("Monte Seu Projeto")}</h1>
              <p className="regular-control-text">
                {I18n.get(
                  "Para criar o seu projeto a partir de diferentes assets, é preciso definir uma especialidade. Como a plataforma trabalha com parceiros criativos especialistas, não é possível misturar assets de diferentes especialidades em um mesmo projeto. Por exemplo, um parceiro especialista em redes sociais não será um especialista em embalagens."
                )}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    let assetTax = this.props.assetTax ?? 0;

    let currency = this.props?.currency;

    const { getFieldDecorator, getFieldsValue } = this.props.form;

    if (!this.props.assetSpecialismsGroup) return null;

    const { assets } = this.state;

    const assetSpecialisms = [
      { code: "", label: I18n.get("Todos") },
      ...(this.props.assetSpecialismsGroup &&
        this.props.assetSpecialismsGroup.sort(
          (a, b) => (a.label > b.label) - (a.label < b.label)
        )),
    ];

    const assetTypeMap = {};

    assets &&
      assets.map((asset) =>
        asset.type.map(
          (item) => (assetTypeMap[item] = (assetTypeMap[item] || 0) + 1)
        )
      );

    const assetTypes = [
      { code: "", label: I18n.get("Todos") },
      ...(this.props?.assetTypes &&
        this.props?.assetTypes
          .filter((item) => !!assetTypeMap[item.code])
          .sort((a, b) => (a.label > b.label) - (a.label < b.label))),
    ];

    var specialismsBag = [];
    var assetTypesBag = [];

    this.props.project.bundles &&
      this.props.project.bundles.map((item) => {
        specialismsBag.push(...item.specialism);
        assetTypesBag.push(...item.assetType);
      });

    var totalPrice = 0;
    var totalItem = 0;
    const _cart = this.props.cartAssets;
    var cart = [];

    _cart.forEach((item) => {
      var index = cart.findIndex((_item) => {
        const idx = _item.asset.id || _item.asset.asset;
        return idx == item.id || idx == item.asset;
      });

      if (index > -1) {
        cart[index].total++;
      } else {
        cart.push({
          asset: item,
          total: 1,
        });
      }
      totalPrice += item.price;
      totalItem++;
    });

    const cartOrdem = cart.sort((a, b) =>
      a.asset.label.localeCompare(b.asset.label)
    );
   

    let hasIsManHour = false;

    for (let i = 0; i < cartOrdem.length; i++) {
      if (cartOrdem[i].asset.isManHour === true) {
        hasIsManHour = true;
        break;
      }
    }


    const echoResume = (classForResume) => {
      let totalTax = Math.round((totalPrice * assetTax) / 100);

      return (
        <div className={`column right-column-for-np is-5 ${classForResume}`}>
          <h6>
            <a href="javascript:;" className="customize-cart">
              <span className="customize-cart-items" data-items={totalItem}>
                {totalItem}
              </span>
              <i className="fas fa-shopping-cart"></i>
            </a>{" "}
            {I18n.get("Assets Selecionados")}
          </h6>
          <div className="cartResume">
            <span>
              <div>
                <div className="customize-cart-dropdown-updated">
                  <div>
                    {cartOrdem.map((item, index) => {
                      // let assetPriceWithOutTax = Math.round((item.asset.price / ((100 + assetTax) / 100)) * 100) / 100;

                      return (
                        <ItemAssetCart
                          key={index}
                          item={item}
                          currency={currency}
                          delItem={this.props.delItem}
                          addItem={this.props.add_asset_itens}
                          projectId={this.props.projectId}
                          cart={cart}
                          clientId={this.props.clientId}
                          getProject={this.handleUpdate}
                        />
                      );
                    })}
                  </div>
                  <div className="customize-cart-dropdown-item-sum">
                    <span className="cart-item-amount" data-total={totalPrice}>
                      <FormatCurrency amount={totalPrice} currency={currency} />
                    </span>
                  </div>


                  {hasIsManHour && 
                    <TextareaHourDescription 
                    defaultValue={this.props?.descriptiveHourMan || ""}
                    getProject={this.handleUpdate}
                    />
                  }

                  {assetTax > 0 && (
                    <>
                      <div
                        className="some-bpool"
                        data-has-tax={true}
                        data-tax-perc={assetTax}
                      >
                        <p>
                          <FormatCurrency
                            amount={totalPrice}
                            currency={currency}
                          />{" "}
                          +{" "}
                          <span style={{ opacity: "0.7", fontSize: "90%" }}>
                            {" "}
                            <span className="vat">
                              {I18n.get("IVA")} {assetTax}%
                            </span>{" "}
                            <FormatCurrency
                              amount={totalTax}
                              currency={currency}
                            />
                          </span>
                        </p>
                      </div>
                    </>
                  )}

                  <div className="customize-card-final">
                    <Button
                      data-button="finalizar"
                      onClick={this.handleResumeBtnClick}
                      disabled={totalItem == 0 || (hasIsManHour && !this.props.descriptiveHourMan)}
                      className="button bt-bpool black full"
                      loading={this.state.loadingCheck}
                      type="primary"
                    >
                      {I18n.get("Finalizar")}
                    </Button>
                    {/* <button
                      data-button="finalizar"
                      disabled={totalItem == 0}
                      type="button"
                      className="button bt-bpool black full"
                      onClick={this.handleResumeBtnClick}
                    >
                      {I18n.get("Finalizar")}
                    </button> */}
                  </div>
                </div>
              </div>
            </span>
          </div>
          {classForResume === "resume-for-mobile" && (
            <hr className="hr-style" />
          )}
        </div>
      );
    };

    let urlPhone = "";

    if (this.props.client.signupLocale === "pt") {
      urlPhone = "https://api.whatsapp.com/send?phone=+5511974721502";
    } else if (this.props.client.signupLocale === "en") {
      urlPhone = "https://api.whatsapp.com/send?phone=+16469189446";
    } else {
      urlPhone = "https://api.whatsapp.com/send?phone=+525539884303";
    }

    return (
      <form onSubmit={this.submitForm.bind(this)}>
        <div>
          {this.props.client.customization &&
            this.renderBox(
              this.props.client.customization.disableNewProjectStep2BundleView,
              this.props.client.customization.disableNewProjectStep2CartView
            )}
          <br />
          <br />
        </div>

        <Form>
          <Row>
            <Col sm={7}>
              <Form.Item label={I18n.get("Especialidade")}>
                {getFieldDecorator("assetSpecialism", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder={I18n.get("Selecione a Especialidade")}
                    onSelect={(e) => this.onChangeNew(e, "assetSpecialism")}
                    disabled={!!this.props.cartSpecialism}
                    // mode="multiple"
                  >
                    {assetSpecialisms &&
                      assetSpecialisms.map((item, index) => (
                        <Option key={index} value={item.code}>
                          {item.label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={7}>
              <Form.Item label={I18n.get("Disciplina")}>
                {getFieldDecorator("assetType", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder={I18n.get("Selecione o Tipo")}
                    onSelect={(e) => this.onChangeNew(e, "assetType")}
                    // mode="multiple"
                  >
                    {assetTypes &&
                      assetTypes.map((item, index) => (
                        <Option key={index} value={item.code}>
                          {item.label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={10}>
              <Form.Item label={I18n.get("Digite o que procura:")}>
                {getFieldDecorator("search", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                })(<Input onChange={(e) => this.onChangeNew(e, "search")} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <hr className="hr-style" />
        <div className="columns columns-for-np customized-projects-body">
          {this.state.isMobile && echoResume("resume-for-mobile")}

          <div className="column right-column-for-np is-7">
            {assets && assets.length > 0 && (
              <h6>
                {I18n.get("Assets encontrados:")} {assets.length}
              </h6>
            )}
            {!assets ||
              (!assets.length && (
                <h6>{I18n.get("Nenhum asset encontrado.")}</h6>
              ))}
            {assets &&
              assets.map((item, index) => {
                const cartItemCount = this.props.cartAssets.reduce(
                  (acc, asset) => {
                    if (item.code == asset.code) {
                      return acc + 1;
                    }

                    return acc;
                  },
                  0
                );

                return (
                  <AssetCustomize
                    key={index}
                    onAddItemBtnClick={this.props.addItem}
                    asset={item}
                    cartItemCount={cartItemCount}
                    currency={currency}
                    assetTax={assetTax}
                  />
                );
              })}
          </div>

          {!this.state.isMobile && echoResume("resume-not-mobile")}
        </div>
      </form>
    );
  }
}

const searchAsset = Form.create({})(AssetCustomizeForm);

export default withRouter(searchAsset);
