import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_LOGIN_CLIENTS_USERS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_clients_users = (id) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/bp/logins/getClientUsers/${id}`, config)
                .then(response => {
                    dispatch({
                        type: BP_LOGIN_CLIENTS_USERS_GET,
                        payload: {
                            loginClientsUsers: response.data
                        }
                    });
                })
                .catch(error => {
                    throw (error);
                });
            }).catch(error => { 
                dispatch({ type: EXPIRED }); 
            }); 
        };
}

export default api_get_clients_users;