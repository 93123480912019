import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import { CLIENT_API } from '../../actions/client/actions';

import TextField from '../form/text-field';
import CheckboxField from '../form/checkbox-field';
import SelectField from '../form/select-field';
import TooltipField from '../form/tooltip-field';
import SelectSearchField from '../form/select-search-field';
import SubmitButtonField from '../form/submit-button-field';

import NumberFormat from 'react-number-format';

import UserCmp from './_user-cmp';

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp }, state.client);
};

const mapDispatchToProps = dispatch => {
    return {
        addUser: data => {
            dispatch(CLIENT_API.create_user(data));
        },
        delUser: data => {
            dispatch(CLIENT_API.delete_user(data));
        },
        save: data => {
            dispatch(CLIENT_API.update_product(data));
        },
    };
};


class BrandsUsersForm extends React.Component {

    constructor(props) {

        super();
        this.state = {
            form: {
                clientId: props.clientId,
                brandId: '',
                index: '',
                productId: '',
                brand: {
                    value: '',
                    onChange: this.onChangeBrand.bind(this),
                    error: props.errors.brand,
                    successIcon: false,
                    visited: true,
                },
                name: {
                    label: I18n.get("Nome do Produto ou Serviço"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'name'),
                },
                categoryId: {
                    label: I18n.get("Categoria"),
                    value: '',
                    onChange: this.onChangeCategoryId.bind(this),
                    error: props.errors.categoryId,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'categoryId'),
                },
                categoryIsMissing: {
                    label: I18n.get("Não encontrei categoria"),
                    value: false,
                    checked: false,
                    onChange: this.onChangeCategoryNotFound.bind(this),
                    error: props.errors.categoryNotFound,
                    successIcon: false,
                    visited: true,
                    //onBlur: this.onBlur.bind(this, 'categoryId'),
                    onClick: this.onBlur.bind(this, 'categoryId'),
                },
                categoryName: {
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'categoryName'),
                    error: props.errors.categoryName,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'categoryName'),
                },
            },
            product: null,
            task: {}
        };

        this.editProduct = this.onEditProduct.bind(this);
        this.save = this.onSave.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;

        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            const [brandId, index, product] = this.getProduct(prevState.form.brand.value);

            nextState['product'] = product;
            nextState.form['brandId'] = brandId;
            nextState.form['index'] = index;

        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onChangeCategoryNotFound(e) {

        const checked = e.target.checked;



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['categoryIsMissing'].checked = checked;
            nextState.form['categoryIsMissing'].value = checked;

            nextState.form['categoryId'].disabled = checked;

            if (checked) {

                nextState.form['categoryId'].value = '';
            }

            return nextState;
        });
    }

    getProduct(selected) {



        var brandId = '', index = '';

        if (selected) {
            [brandId, index] = selected.split(':', 2);
        }
        else {
            return [brandId, index, null];
        }

        const brand = this.props.brands && this.props.brands.filter((item) => item.id == brandId);

        const product = brand[0] && brand[0].products.filter((item) => item.index == index);

        return [brandId, index, product[0]];
    }

    onChangeBrand(e) {

        const value = e.target.value;



        this.selectBrand(value);
    }

    selectBrand(value) {

        const [brandId, index, product] = this.getProduct(value);

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['brand'].value = value;
            nextState.form['name'].value = product && product.name;
            nextState.form['categoryId'].value = product && product.categoryId;
            nextState.form['categoryId'].disabled = product && product.categoryIsMissing;
            nextState.form['categoryName'].value = product && product.categoryName;
            nextState.form['categoryIsMissing'].value = product && product.categoryIsMissing;
            nextState.form['categoryIsMissing'].checked = product && product.categoryIsMissing;
            nextState.form['brandId'] = brandId;
            nextState.form['index'] = index;

            nextState['product'] = product;



            return nextState;
        });
    }

    onChangeCategoryId(value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['categoryId'].value = value;
            nextState.form['categoryName'].value = '';
            nextState.form['categoryIsMissing'].value = false;
            nextState.form['categoryIsMissing'].checked = false;

            return nextState;
        });
    }

    onEditProduct(e) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            const product = prevState.product || {};



            nextState.form['name'].value = product.name;
            nextState.form['categoryId'].value = product.categoryId;
            nextState.form['categoryName'].value = product.categoryName;
            nextState.form['categoryIsMissing'].value = product.categoryIsMissing;
            nextState.form['categoryIsMissing'].checked = product.categoryIsMissing;

            nextState['product'] = null;

            return nextState;
        });
    }

    getCategory(value) {

        if (value) {
            if (!this.props.bp.sectorsFlatMap)
                return {};

            return this.props.bp.sectorsFlatMap[value];
        }
        else {
            return { label: this.state.form.categoryName.value + I18n.get("(cat. não cadastrada)") };
        }
    }

    getBrandName(value) {

        const brand = this.props.brands && this.props.brands.filter((item) => item.id == value);

        return brand[0];
    }

    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    getData() {

        var data = FormUtils.getData(this.state);

        return data;
    }

    onSave(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    getCategoryValues() {
        //return this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => !item.isUnspecified);
        return this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.sort((a, b) => (a.label > b.label) - (a.label < b.label));
    }

    getBrandCategoryValues() {

        var values = [];

        this.props.brands && this.props.brands.map((brand) => {

            for (var i = 0; i < brand.categoryCount; i++) {

                const product = brand.products.filter((item) => item.index == i);

                var label = `${brand.name} - Categoria ${i + 1}`;

                if (product && product.length) {
                    label = `${brand.name} - ${product[0].name}`;
                }

                values.push({ code: `${brand.id}:${i}`, label: label });
            }

        });

        return values;
    }

    render() {

        const isAdmin = this.props.isAdmin || false;

        const categoryValues = this.getBrandCategoryValues();

        // auto-seleciona a primeira opcao do combo de marcas-categorias
        if (categoryValues && categoryValues[0] && !this.state.form['brand'].value) {

            this.selectBrand(categoryValues[0].code);
        }

        if (!categoryValues.length) {

            return (
                <p style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
                    <span class="icon has-text-danger">
                        <i class="fas fa-exclamation"></i>
                    </span>
                    <Link to={'/cliente/passo-3'}>{I18n.get("Passo 3")}</Link>
                    <ul>
                        <li style={{ fontSize: '90%' }}>{I18n.get("Por favor inclua pelo menos um marca para cadastrar os responsáveis.")}</li>
                    </ul>
                </p>
            );
        }

        const brand_category = this.state.product && (<b>{this.getBrandName(this.state.product.brandId).name.toUpperCase()} - {this.state.product.name.toUpperCase()}</b>);

        return (
            <section>

                <div className="columns fullsize-border">

                    <div className="column is-6">
                        <h1 className="subtitle subtitle-v2">{I18n.get("Informações da Marca")}</h1>
                    </div>

                    <div className="column is-5 is-offset-1 select-on-title">

                        <SelectField {...this.state.form.brand} values={categoryValues} />

                    </div>

                </div>

                {/* sem associação */}
                {!this.state.product && <div>

                    <p style={{ marginTop: '1rem', marginBottom: '2rem' }}>{I18n.get("Informe abaixo os produtos e categorias que vão usar a BPool. Se não encontrar a categoria, por favor informar ao lado. Não esqueça de repetir o processo para todas as marcas/categorias cadastradas (dropdown no topo da página).")}</p>

                    <div className="columns">

                        <div className="column is-6">

                            <TextField {...this.state.form.name} />

                        </div>
                    </div>
                    <div className="columns">

                        <div className="column is-6">

                            <SelectSearchField {...this.state.form.categoryId} values={this.getCategoryValues()} />

                            {this.state.form.categoryIsMissing.value && <TextField {...this.state.form.categoryName} />}

                        </div>
                        <div className="column is-6">

                            <div style={{ marginTop: '0.5rem' }}>&nbsp;</div>

                            <CheckboxField {...this.state.form.categoryIsMissing} />

                        </div>

                    </div>

                    <div className="columns">

                        <div className="column is-6">

                            <div className="field jc-end">

                                <p className="control">

                                    <button
                                        type="button"
                                        onClick={this.save.bind(this)} task={this.state.task}
                                        className="button is-leaked">{I18n.get("CONFIRMAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                    </button>

                                    <br /><br /><br /><br />

                                    {/* <SubmitButtonField
                                        className="button is-black is-rounded"
                                        label="SALVAR"
                                        onClick={this.save.bind(this)}
                                        task={this.state.task}
                                    /> */}


                                </p>
                            </div>

                        </div>

                    </div>

                </div>}

                {/* Produto associado */}
                {this.state.product && <div>

                    <p style={{ marginTop: '1rem', marginBottom: '2rem' }}>{I18n.get("No dropdown acima você vai encontrar as marcas e categorias cadastradas. Informe abaixo os responsáveis por cada uma delas.")}</p>

                    <table className="table is-bordered is-fullwidth" style={{ border: 'none' }}>
                        <thead>
                            <tr>
                                <th className="bp-has-background-purple">{I18n.get("Nome do Produto")}</th>
                                <th className="bp-has-background-purple">{I18n.get("Nome da Categoria")}</th>
                                <th className="bp-has-background-purple"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {this.state.product.name}
                                </td>
                                <td>
                                    {this.getCategory(this.state.product.categoryId).label}
                                </td>
                                <td>
                                    <a onClick={(e) => this.editProduct(e)}>
                                        <i className="fa fa-edit"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h1 className="title-bpool medium">{I18n.get("Usuários responsáveis -")} <b>{this.getBrandName(this.state.product.brandId).name.toUpperCase()} - {this.state.product.name.toUpperCase()}</b>
                    </h1>

                    <p style={{ marginTop: '1rem', marginBottom: '0rem' }}>{I18n.get("No item A abaixo, você adiciona os usuários que vão demandar serviços da plataforma para a marca/categoria selecionada acima. Já no item B, você adiciona os usuários que serão responsáveis por negociar qualquer escopo não padronizado BPool com os parceiros criativos.")}</p>


                    <div className="columns">

                        <div className="column is-11 is-offset-1">

                            <h1 className="title-bpool medium">{I18n.get("A. Usuários da plataforma")}</h1>
                            <p className="is-size-7" style={{ marginTop: '1rem' }}>{I18n.get("Adicione os usuários da marca/categoria")} {brand_category} {I18n.get("que vão demandar serviços na plataforma, responsáveis pelo gerenciamento de projetos e parceiros. (Alguns clientes cadastram não só o responsável pela marca/categoria mas também outros membros do time).")}</p>

                            <br />

                            <UserCmp {...this.props} productId={this.state.product && this.state.product.id} enableJobRole={true} enableAddSelf={false} role="ClientUserMarketing" />

                            <h1 className="title-bpool medium">{I18n.get("B. Responsável por aprovação de itens não padronizados no escopo da BPool")}</h1>
                            <p className="is-size-7" style={{ marginTop: '1rem' }}>{I18n.get("Adicione os usuários que serão responsáveis por negociar qualquer escopo não padronizado BPool com os parceiros criativos para a marca/categoria")} {brand_category} {I18n.get("(Alguns clientes concentram a negociação de ítens não padronizados da plataforma no responsável da área de Compras que atende a marca e/ou categoria).")}</p>

                            <br />

                            <UserCmp {...this.props} productId={this.state.product && this.state.product.id} enableJobRole={true} enableAddSelf={false} role="ClientUserPurchasing" />

                            <p className="is-size-6" style={{ marginTop: '2rem' }}>
                                <strong>{I18n.get("Se você cadastrou outras marcas/categorias")}</strong> {I18n.get(", não esqueça de repetir o processo acima para cada uma delas (dropdown no topo da página).")}</p>


                        </div>

                    </div>



                </div>}

            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsUsersForm);
