import React from "react";
import * as S from "./styles";
import MainTitle from "../MainTitle/index";
import Button from "../Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { I18n } from "aws-amplify";

const VideocallsAgenda = ({ auth, agenda }) => {
  return (
    <div>
      <MainTitle text={I18n.get("Agenda de Videocalls")} />
      <S.AgendaBoard>
        {agenda &&
          agenda.map((res, index) =>
            res.statusCode == 1 ? (
              <S.Call color="#1883FF" key={index}>
                <div>
                  <p className="date">
                    {res.date.substring(0, res.date.indexOf("-"))}
                  </p>
                  <p className="hour">
                    {res.date.substring(res.date.indexOf("-") + 1)}
                  </p>
                </div>
                <div>
                  <p className="name">{res.partnerName}</p>
                  <p className="client">{res.projectName}</p>
                </div>
                <div>
                  <p className="status">{I18n.get("Confirmada")}</p>
                </div>
                {res?.meetingId && (
                  <div>
                    <a
                      className="button bt-bpool bsmall"
                      target="_blank"
                      href={`https://meeting.bpool.co/${res?.meetingId}`}
                    >
                      {I18n.get("Participar agora")}
                    </a>
                  </div>
                )}
              </S.Call>
            ) : (
              <S.Call color="#FF0000" key={index}>
                <div>
                  <p className="date">
                    {res.date.substring(0, res.date.indexOf("-"))}
                  </p>
                  <p className="hour">
                    {res.date.substring(res.date.indexOf("-") + 1)}
                  </p>
                </div>
                <div>
                  <p className="name">{res.partnerName}</p>
                  <p className="client">{res.projectName}</p>
                </div>
                <div>
                  <p className="status">{I18n.get("Aguardando confirmação")}</p>
                </div>
              </S.Call>
            )
          )}
        {agenda.length < 1 && <p>{I18n.get("Nenhum dado a ser exibido")}</p>}

        {auth.is_client && (
          <Button
            text={I18n.get("Ir para Videocalls")}
            padding="14px 40px"
            href="/#/cliente/projetos?=3"
          />
        )}
        {auth.is_partner && (
          <Button
            text={I18n.get("Ir para Videocalls")}
            padding="14px 40px"
            href="/#/parceiro/agendamentos"
          />
        )}
      </S.AgendaBoard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(VideocallsAgenda));
