import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { IDEA_UPDATE_OFFER } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_add_po = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.put(`${Config.API_BASE_URL}/ClientWhatIf/idea/${data.whatIfIdeaId}/addPo`, data, config)
                .then(response => {
                    dispatch({
                        type: IDEA_UPDATE_OFFER,
                        payload: {
                            ideaDetail: {
                                ...response.data
                            },
                            task: {
                                result: true
                            }
                        }
                    });

                })
                .catch(error => {

                    dispatch({
                        type: IDEA_UPDATE_OFFER,
                        payload: {
                            errors: error.response.data,
                            task: {
                                result: false
                            }
                        }
                    });
                });
        }).catch(error => {
 
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_add_po;
