import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { HISTORIC_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';


const api_get_historic_dash = (data) => {
    return (dispatch, getState) => {
        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            let year = data.year ? data.year : "2019"


            return axios.get(`${Config.API_BASE_URL}/dashboard/${data.prefix}/${data.idGet}/historic/y/${data.fromDate}/${data.toDate}/${data.projectType}?status=${data.status}`, config)
                .then(response => {



                    dispatch({
                        type: HISTORIC_GET,

                        payload: {
                            historicYear: response.data,
                            errors: {},
                        }
                    })
                })
                .catch(error => {
                    dispatch({
                        type: HISTORIC_GET,
                        payload: {
                            errors: {},
                            task: {
                                result: false
                            }
                        }
                    })
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_historic_dash;
