import styled from "styled-components";

export const StyleMain = styled.div``;
export const Label = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;

  &::before {
    content: "";
    width: 70px;
    border-bottom: solid 1px #000;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
export const ButtonSubmit = styled.div`
  margin-top: 65px;
`;
