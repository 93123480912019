import styled from "styled-components";
import * as MediaQuery from "../../../utils/mediaQuery";

export const ProfileBoard = styled.div`
    background-color: #FFF;
    border-radius: 10px;
    padding: 40px 30px 50px;
    text-align: center;
    height: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    img {
        width: 45px;
        border-radius: 50px;
    }
    h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 23px;
        margin: 0 0 3px;
        text-align: left;
    }
    h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        margin: 0 0 3px;
        text-align: left;
    }
    p {
        margin: 0 0 3px;
        text-align: left;
    }

    > div {
        align-self: flex-start;
        display: flex;
        flex-direction: column;

       
        .name {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;

            
            p{
                font-size: 14px;
                line-height: 20px;
            }

        }

        .profile-pic {
            width: 45px;
            height: 45px;
            background-color: #090909;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                color: #FFF;
                font-size: 24px;
                font-weight: 600;
                margin: 0;
            }
        }

        .responsible {
            padding: 30px 0;

            > div:nth-child(2) {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;
                margin: 30px 0;

               

                
            }
            > div:nth-child(3) {
                p {
                    font-size: 14px;
                    margin: 0 0 10px;
                    max-width: 215px;

                    ${() =>
                        MediaQuery.IS_MOBILE() &&
                        `
                        max-width: unset;
                    `}
                }
            }
        }
    }

    .team {
        align-self: flex-start;
        margin: 0px 0 30px;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 15px;
        h2 {
            margin-bottom: 30px;
        }
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;
            gap: 15px;

        }
    }

    .link-email {
        cursor: pointer;
        overflow-wrap: break-word;
    }
`;