import styled from "styled-components";
import Carousel from "react-elastic-carousel";

export const ContainerSearchProjects = styled.div`
  margin: 30px 0 15px 0;
  display: flex;
  justify-content: space-between;
`;

export const ContainerSearchInput = styled.div`
  width: 345px;
`;

export const Wrapper = styled.div``;

export const Title = styled.div`
  span {
    font-weight: bold;
    font-size: ${(props) => (props.windowWidth <= 1024 ? "16px;" : "24px;")};
    line-height: ${(props) => (props.windowWidth <= 1024 ? "22px;" : "33px;")};
    color: #000000;
  }

  hr {
    margin: ${(props) =>
      props.windowWidth <= 1024 ? "5px 0 0 0;" : "15px 0 0 0;"};
    width: ${(props) => (props.windowWidth <= 1024 ? "50px;" : "75px;")};
    border-bottom: ${(props) =>
      props.windowWidth <= 1024 ? "3px solid;" : "6px solid;"};
    border-color: #000000;
  }
`;

export const Cards = styled.div`
  display: flex;
  margin-top: 50px;
`;

const StyledCarousel = styled(Carousel)`
  margin: 0;

  .rec-slider-container {
    margin: 0 -10px;
  }

  .rec-slider {
    margin: 0;
  }

  button {
    width: 22px;
    height: 22px;
    left: 0px;
    top: 0px;

    background: #c4c4c4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    :hover,
    :focus {
      box-shadow: 0 0 1px 3px #2f2f2f;
    }
  }

  .rec-dot_active {
    background: #2f2f2f;
  }

  .rec-pagination {
    margin-top: 30px;
  }
`;

export const Box = styled.div`
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #ccc;

  strong {
    font-size: 18px;
  }
`;

export const ContentDatePicker = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .ant-calendar-picker {
    width: 100%;
  }
`;

export const ContentSelectHours = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SelectClickButton = styled.button`
  border-radius: 20px;
  border: 0.5px solid;
  border-color: ${(props) => (props.active === true ? "#1883FF;" : "#000000;")};
  background: ${(props) =>
    props.active === true ? "#1883FF;" : "transparent;"};
  color: ${(props) => (props.active === true ? "#FFFFFF;" : "#000000;")};
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 10px 20px 0;
`;

export const ButtonSave = styled.button`
  width: 100%;
  font-size: 16px;
  padding: 16px 40px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  text-align: center;
  border: 1px solid;
  border-color: #1883ff;
  color: #fff;
  background: #1883ff;
  border-color: #1883ff;
  border-radius: 28px;

  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};

  span {
    margin-top: 3px;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 12px 35px;
  }
`;

export const ContentSelectProject = styled.div`
  width: 340px;
  margin: 10px 0;
`;

export const ContentSelectPartner = styled.div`
  width: 340px;
  margin: 10px 0 10px 10px;
`;

export const WrapperModal = styled.div`
  width: 500px;
  height: 170px;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyModal = styled.div`
  margin: 30px 0;

  span {
    font-size: 16px;
  }
`;

export const ActionsModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const ButtonYes = styled.div`
  width: 120px;
  border: none;
  border-radius: 10px;
  background: #32cd32;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
`;

export const ButtonNo = styled.div`
  width: 120px;
  border: none;
  border-radius: 10px;
  background: #ff0000;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
`;

export const ButtonCancel = styled.div`
  width: 120px;
  border: none;
  border-radius: 10px;
  background: #666666;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
`;

export const ButtonSaveEdit = styled.div`
  width: 120px;
  border: none;
  border-radius: 10px;
  background: #1883ff;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
`;

export const SpacingBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const WrapperRadioGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  .ant-radio-group .ant-radio-wrapper {
    margin-right: 20px;
  }

  .ant-radio-group .ant-radio-inner {
    :after {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      top: 4px;
      left: 4px;
    }
  }

  .ant-radio-group .ant-radio-checked .ant-radio-inner {
    border-color: #000000;

    :after {
      background-color: #1883ff;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      top: 4px;
      left: 4px;
    }
  }

  .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
    font-size: 24px;
    width: 24px;
    height: 24px;
    background: #f3f3f3;
  }

  .ant-radio-group .ant-radio-wrapper span {
    font-family: "Helvetica Neue LT Std";
    font-weight: 700;
    color: #000000;
    font-size: 24px;
  }

  .ant-radio-group .ant-radio-wrapper-checked span {
    color: #1883ff;
  }

  .ant-radio-group .ant-radio-wrapper-disabled span {
    color: #979797;
  }
`;

export const ContentCheckbox = styled.div``;

export { StyledCarousel as Carousel };
