import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import TextField from '../form/text-field';
import CheckboxField from '../form/checkbox-field';
import SelectField from '../form/select-field';

class LeaderForm extends React.Component {

    constructor(props) {

        super();
        this.state = {
            form: {
                name: {
                    label: I18n.get("Nome"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'name'),
                },
                email: {
                    label: I18n.get("E-mail"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'email'),
                    error: props.errors.email,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'email'),
                },
                expertise: {
                    label: I18n.get("Função"),
                    value: '',
                    onChange: this.onChangeLeaderExpertise.bind(this),
                    error: props.errors.expertise,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'expertise'),
                },
            },
            task: {}
        };

        this.addLeader = this.onAddLeader.bind(this);
        this.delLeader = this.onDelLeader.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);
        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    getData() {

        var data = {
            partnerId: this.props.partnerId,
        };

        Object.keys(this.state.form).forEach((item) => {

            if (FormUtils.isFormField(this.state.form[item])) {

                data[item] = this.state.form[item].value;
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    onChangeLeaderExpertise(e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['expertise'].value = this.props.bp.marketExpertisesMap[value];

            return nextState;
        });
    }

    onChangeSingleUser(e) {

        const value = e.target.checked;

        if (value) {
            this.setState({ singleUser: value, name: '', email: '', expertise: {} });
        }
        else {
            this.setState({ singleUser: value });
        }
    }


    onAddLeader(e) {

        e.preventDefault();

        if (this.state.singleUser) return;

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.addLeader(this.getData());
        });
    }

    onDelLeader(item, e) {
        e.preventDefault();

        const data = Object.assign({}, this.getData(), { id: item.id });

        this.props.delLeader(data);
    }

    getMarketExpertises() {
        return this.props.bp.marketExpertises && this.props.bp.marketExpertises.map((item) => ({ code: item.code, label: item.label }));
    }

    render() {

        return (
            <section>

                <div className="field is-horizontal">
                    <div className="field-body">

                        <TextField {...this.state.form.name} disabled={this.state.singleUser} />

                        <TextField {...this.state.form.email} disabled={this.state.singleUser} />

                        <SelectField {...this.state.form.expertise} value={this.state.form.expertise.value && this.state.form.expertise.value.code} values={this.getMarketExpertises()} disabled={this.state.singleUser} />

                        <div className="field is-narrow">
                            <label className="label">
                                &nbsp;
                        </label>
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={this.addLeader}
                                    disabled={this.state.singleUser}
                                    className="button is-leaked">{I18n.get("SALVAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                            </p>
                        </div>

                    </div>
                </div>

                {this.props.leaders && this.props.leaders.length === 0 && <CheckboxField
                    label={I18n.get("Sou a única pessoa no negócio.")}
                    value={true}
                    checked={this.state.singleUser}
                    onChange={this.onChangeSingleUser.bind(this)}
                />}

                {this.props.leaders && this.props.leaders.length > 0 &&

                    <div className="table-main">
                        <table className="table is-bordered is-fullwidth">
                            <thead>
                                <tr>
                                    <th>{I18n.get("Nome")}</th>
                                    <th>{I18n.get("E-mail")}</th>
                                    <th>{I18n.get("Função")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.leaders.map((item, index) =>
                                    <tr key={index}>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            {item.email}
                                        </td>
                                        <td>
                                            {item.expertise.label}
                                        </td>
                                        <td>
                                            <a onClick={(e) => this.delLeader(item, e)}>
                                                <i className="fa fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </section>
        );
    }
}

export default LeaderForm;
