import React from "react";

import { I18n } from "aws-amplify";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { PARTNER_API } from "../../actions/partner/actions";

import LoadingPage from "../pages/loading";
import { PartnerStatus } from "../../model";

import Config from "../../config";
//import LandingPage from '../page-templates/landing';

//import Steps from './steps';

const mapStateToProps = (state) => {
  return { partner: state.partner, auth: state.auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      dispatch(PARTNER_API.confirm(data));
    },
  };
};

class VerifyEmail extends React.Component {
  // constructor(props) {
  //   super();

  //   this.state = {
  //     verificationToken: props.match.params.verificationToken,
  //   };
  // }

  // componentDidMount() {
  //   this.props.onSubmit(this.state);
  // }

  render() {
    return <Redirect to={`/login`} />;

    // if (!this.props.partner || !this.props.partner.id)
    //   return <LoadingPage></LoadingPage>;

    // if (
    //   this.props.partner &&
    //   this.props.partner.status === PartnerStatus.Approved
    // ) {
    //   return <Redirect to="/home" />;
    // }

    // let isNotLogged =
    //   this.props.auth && !this.props.auth.init && !this.props.auth.logged_user;
    //   return <Redirect to={`/login`} />;

    // if (Config.moduloNewRegister) {
    // }

    // return <Redirect to={"/login"} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
