import React, { useEffect, useState, useContext } from "react";
import { I18n } from "aws-amplify";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import { useBpay } from "../../../../../hooks/useBpay/useBpay.hook";
import { useUpload } from "../../../../../hooks/useUpload/useUpload.hook";
import { NewBPay } from "../../../templates/NewBPay";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { Input } from "../../../../../components/UI/Input";
import { Dialog } from "../../../../../components/UI/Dialog";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import { FileUpload } from "../../../../../components/UI/FileUpload";
import { FormatCurrency } from "../../../../../components/UI/FormatCurrency";
import BpayContext from "../../../context/BpayContext";
import {
  hasError,
  clearErrorInput,
} from "../../../../RegistrationFlow/utils/errorsInput";
import { BillingsData } from "./components/BillingsData";
import { RenderInfoLabel } from "./components/RenderInfoLabel";
import * as S from "./styles";

export const NewBpayPo = () => {
  let params = useParams();
  let history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { sharedSetupBpay, fetchDataBpay } = useContext(BpayContext);
  const { getPoByBPayIdAndSetup, postPo } = useBpay();
  const { uploadFile: uploadFileS3 } = useUpload();
  const [inputsForm, setInputsForm] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [filePo, setFilePo] = useState({});
  const [bpayId, setBpayId] = useState(undefined);
  const [errorsInputs, setErrorsInputs] = useState([]);
  const [isLoadingUploadPo, setIsLoadingUploadPo] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [regitrationDiffRegister, setRegitrationDiffRegister] = useState(false);
  const [dialogPdf, setDialogPdf] = useState({ visible: false });

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleSend = async (id) => {
    try {
      setIsSending(true);
      let objSend = {
        bPayId: id,
        registrationName: inputsForm?.registrationName,
        registrationId: inputsForm?.registrationId,
        poNumber: inputsForm?.poNumber,
        fileAttached: {},
      };

      if (filePo?.name) {
        const result = await uploadFileS3({
          file: filePo,
          idUser: `${auth?.clientId}`,
          fieldId: `po-bpay-${params?.bpayId}-files`,
        });

        objSend = {
          ...objSend,
          fileAttached: {
            name: result?.name,
            url: result?.url,
            type: result?.type,
            size: filePo?.size,
            uploadedDate: result?.uploadedDate,
          },
        };
      }

      const response = await postPo(objSend);
      setDialogPdf({ visible: true });
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao salvar a PO"),
        duration: 3,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleBack = async (id) => {
    history.push(`/cliente/bpay/new/suppliers/${id}`);
  };

  const handleUploadFilePo = async (file) => {
    setFilePo(file);
  };

  const { data, isLoading, error, refetch } = useQuery(
    "POBpay",
    () => getPoByBPayIdAndSetup(params?.bpayId),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os dados de PO"),
          duration: 3,
        });
      },
    }
  );

  useEffect(() => {
    setInputsForm({
      registrationName:
        data?.data?.registrationName ||
        data?.billingData?.clientCompany?.registrationName,
      registrationId:
        data?.data?.registrationId ||
        data?.billingData?.clientCompany?.registrationId,
      valueBPay: data?.data?.valueBPay,
      valuePo: data?.data?.valuePo,
      poNumber: data?.data?.poNumber,
    });
  }, [data]);

  useEffect(() => {
    if (
      inputsForm?.registrationName &&
      inputsForm?.valueBPay &&
      inputsForm?.valuePo &&
      inputsForm?.poNumber &&
      filePo?.name
    ) {
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(true);
    }
  }, [inputsForm, filePo]);

  const onChangeBillingData = ({ value, registrationId, registrationName }) => {
    setRegitrationDiffRegister(value);
    setInputsForm({ ...inputsForm, registrationName, registrationId });
  };

  const deleteFiles = async (fileOrId) => {
    if (fileOrId?.name) {
      setFilePo({});
    }
  };

  useEffect(() => {
    if (bpayId || bpayId === null) {
      fetchDataBpay({
        id: params?.bpayId,
        newBpay: params?.bpayId ? false : true,
      });
    }
  }, [bpayId]);

  useEffect(() => {
    setBpayId(params?.bpayId ? params?.bpayId : null);
  }, [params?.bpayId]);

  const currencyBilling = (culture) => {
    const billing = sharedSetupBpay?.setup?.listCurrenciesDefault?.find(
      (item) => item?.culture === culture
    );
    if (billing) {
      return billing;
    } else {
      return "";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NewBPay
        buttons={
          <>
            <ButtonBpool
              text={I18n.get("Voltar")}
              theme="secondary"
              onClick={() => handleBack(bpayId)}
            />
            <ButtonBpool
              text={I18n.get("Liberar Faturamento")}
              theme="primary"
              loading={isSending}
              onClick={() => handleSend(bpayId)}
              disabled={isDisabledButton}
            />
          </>
        }
      >
        <S.TitleContent>
          <h3>{I18n.get("Inserir PO")}</h3>
        </S.TitleContent>
        <Row>
          <BillingsData
            billingData={data?.billingData}
            isLoading={isLoading}
            refetch={refetch}
            onChangeBillingData={onChangeBillingData}
          />
        </Row>
        <S.Form style={{ marginTop: 32 }}>
          {regitrationDiffRegister ? null : (
            <Row>
              <Col md={24}>
                <S.Skeleton
                  loading={isLoading}
                  active
                  title={false}
                  paragraph={{
                    rows: 1,
                    className: "skeleton-input",
                    width: "100%",
                  }}
                >
                  <RenderInfoLabel
                    label={I18n.get("Razão social do cliente")}
                    info={inputsForm.registrationName}
                  />
                </S.Skeleton>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={8}>
              <S.Skeleton
                loading={isLoading}
                active
                title={false}
                paragraph={{
                  rows: 1,
                  className: "skeleton-input",
                  width: "100%",
                }}
              >
                <Input
                  placeholder={I18n.get("Número da PO")}
                  label
                  required
                  value={inputsForm.poNumber}
                  onChange={(value) => onChangeInputForm("poNumber", value)}
                  error={hasError({ arr: errorsInputs, field: "poNumber" })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "poNumber",
                      arr: errorsInputs,
                    });
                    setErrorsInputs(errorsArr);
                  }}
                />
              </S.Skeleton>
            </Col>
            <Col md={8}>
              <S.Skeleton
                loading={isLoading}
                active
                title={false}
                paragraph={{
                  rows: 1,
                  className: "skeleton-input",
                  width: "100%",
                }}
              >
                <RenderInfoLabel
                  label={I18n.get("Valor da PO")}
                  info={FormatCurrency({
                    amount: inputsForm.valuePo,
                    currency: currencyBilling(
                      data?.billingData?.clientCompany?.country?.culture
                    ),
                  })}
                />
              </S.Skeleton>
            </Col>
            <Col md={8}>
              <S.Skeleton
                loading={isLoading}
                active
                title={false}
                paragraph={{
                  rows: 1,
                  className: "skeleton-input",
                  width: "100%",
                }}
              >
                <RenderInfoLabel
                  label={I18n.get("Valor BPay")}
                  info={FormatCurrency({
                    amount: inputsForm.valueBPay,
                    currency: currencyBilling(
                      data?.billingData?.clientCompany?.country?.culture
                    ),
                  })}
                />
              </S.Skeleton>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <S.UploadPoFile>
                <S.Skeleton
                  loading={false}
                  active
                  title={false}
                  paragraph={{
                    rows: 1,
                    className: "skeleton-upload",
                  }}
                >
                  <p style={{ margin: "16px 0" }}>
                    <span style={{ color: "#ff0000", marginRight: 3 }}>*</span>
                    {I18n.get("Insira um arquivo para anexar a PO.")}
                  </p>
                  <FileUpload
                    cbFiles={(file) => handleUploadFilePo(file)}
                    multiple={false}
                    isLoading={isLoadingUploadPo}
                    initialFiles={[]}
                    cbDeleteFile={(fileOrId) => deleteFiles(fileOrId)}
                    acceptedTypes={[
                      {
                        label: "PDF",
                        type: "application/pdf",
                      },
                    ]}
                  />
                </S.Skeleton>
              </S.UploadPoFile>
            </Col>
          </Row>
        </S.Form>
      </NewBPay>

      <Dialog
        visible={dialogPdf?.visible}
        text={I18n.get("BPay gerado com sucesso!")}
        subText=""
        buttons={{
          ok: {
            text: I18n.get("Fechar"),
            func: () => history.push(`/cliente/bpay`),
          },
          cancel: null,
        }}
      />
    </>
  );
};
