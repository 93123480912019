import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import PartnerEditForm from './_partner-edit-form';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_partners: data => {
            dispatch(BP_API.get_partners(data));
        },
        get_partner: id => {
            dispatch(BP_API.get_partner(id));
        },
    };
};

class PartnerEdit extends React.Component {

    constructor(props) {

        super();

        this.state = {
            partnerId: props.match.params.partnerId,
        };
    }

    componentDidMount() {

        this.props.get_partner(this.state.partnerId);
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li><NavLink to="/bpool/parceiros" className="is-black">{I18n.get("Parceiros")}</NavLink></li>
                        <li><NavLink to={`/bpool/parceiros/${this.props.match.params.partnerId}`} className="is-black">{this.props.bp.partner && this.props.bp.partner.name}</NavLink></li>
                        <li className="is-active"><a href="#">{I18n.get("Editar")}</a></li>
                    </ul>
                </nav>

                <div className="columns">

                    <div className="column is-6">

                        <PartnerEditForm {...this.props} />
                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerEdit);

