import get_leader from './get';
import get_company_info from './get-company-info';
import update_leader from './update';
import confirm_leader from './confirm';
import update_person from './update-person';

import create_expertise from './create-expertise';
import update_expertise from './update-expertise';
import delete_expertise from './delete-expertise';

import create_case from './create-case';
import delete_case from './delete-case';
import update_case from './update-case';

import create_award from './create-award';
import update_award from './update-award';
import delete_award from './delete-award';

import create_portfolio from './create-portfolio';
import delete_portfolio from './delete-portfolio';
import update_portfolio from './update-portfolio';

import upload from './upload';

export const LEADER_GET = 'leader_get';
export const LEADER_UPDATE = 'leader_update';

export const LEADER_EXPERTISE_CREATE = 'leader_expertise_create';
export const LEADER_EXPERTISE_UPDATE = 'leader_expertise_update';
export const LEADER_EXPERTISE_DELETE = 'leader_expertise_delete';

export const LEADER_CASE_CREATE = 'leader_case_create';
export const LEADER_CASE_DELETE = 'leader_case_delete';
export const LEADER_CASE_UPDATE = 'leader_case_update';

export const LEADER_AWARD_CREATE = 'leader_award_create';
export const LEADER_AWARD_UPDATE = 'leader_update_create';
export const LEADER_AWARD_DELETE = 'leader_award_delete';


export const LEADER_PORTFOLIO_CREATE = 'leader_portfolio_create';
export const LEADER_PORTFOLIO_UPDATE = 'leader_portifolio_update';
export const LEADER_PORTFOLIO_DELETE = 'leader_portfolio_delete';

export const LEADER_API = {
    get: get_leader,
    get_company_info: get_company_info,
    confirm: confirm_leader,
    update: update_leader,
    update_person: update_person,
    create_expertise: create_expertise,
    update_expertise: update_expertise,
    delete_expertise: delete_expertise,
    create_case: create_case,
    delete_case: delete_case,
    update_case: update_case,
    create_award: create_award,
    update_award: update_award,
    delete_award: delete_award,
    create_portfolio: create_portfolio,
    delete_portfolio: delete_portfolio,
    update_portfolio: update_portfolio,
    upload: upload
}


