import React from 'react';
import { Modal, Button } from 'antd';
import './modalcta-styles.css';

import Logo from '../../../media/img/logo.png';
import IcoAlert from '../../../media/svg/ico-alert-modal.svg';

// import * as S from "./styles";

export const ModalCta = ({
  visible,
  bodyText,
  cancelText,
  okText,
  handleClickOk,
  handleClickCancel,
  isLoading,
}) => {
  return (
    <Modal
      centered
      visible={visible}
      onOk={handleClickOk}
      okText={okText}
      onCancel={handleClickCancel}
      cancelText={cancelText}
      confirmLoading={isLoading}
      wrapClassName="modalcta"
    >
      <div className="logo-modal">
        <img src={Logo} alt="Bpool" />
      </div>
      <div className="ico-modal">
        <img src={IcoAlert} alt="Alert modal" />
      </div>
      <div className="text-modal">{bodyText}</div>
    </Modal>
  );
};
