import React from 'react';

import { I18n } from 'aws-amplify';

import numeral from 'numeral';

import './quotation-item-form.css';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import TextField from '../form/text-field';
import NumericField from '../form/numeric-field';
import MoneyField from '../form/money-field';
import SelectField from '../form/select-field';
import RadioMultiField from '../form/radio-multi-field';
import UploadField from '../form/upload-field';
import SelectSearchField from '../form/select-search-field';

import FormatCurrency from '../ui/format-currency';

const ptBR = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2
});

class QuotationItemForm extends React.Component {

    constructor(props) {

        super();
        this.state = {
            fields: {
                itemType: {
                    onChange: this.onChangeForm.bind(this, 'itemType'),
                    error: props.errors.itemType,
                    successIcon: false,
                    visited: true,
                },
                segment: {
                    onChange: this.onChangeFormValue.bind(this, 'segment'),
                    error: props.errors.segment,
                    successIcon: false,
                    visited: true,
                },
                category: {
                    onChange: this.onChangeFormValue.bind(this, 'category'),
                    error: props.errors.category,
                    successIcon: false,
                    visited: true,
                },
                description: {
                    onChange: this.onChangeForm.bind(this, 'description'),
                    error: props.errors.description,
                    successIcon: false,
                    visited: true,
                },
                quantity: {
                    onChange: this.onChangeForm.bind(this, 'quantity'),
                    error: props.errors.quantity,
                    successIcon: false,
                    visited: true,
                },
                recurrence: {
                    onChange: this.onChangeForm.bind(this, 'recurrence'),
                    error: props.errors.recurrence,
                    successIcon: false,
                    visited: true,
                },
                price: {
                    onChange: this.onChangeForm.bind(this, 'price'),
                    error: props.errors.price,
                    successIcon: false,
                    visited: true,
                },
                doubleTaxation: {
                    // tooltip: I18n.get("Selecione 'Não' se o fornecedor for faturar direto. Se sua empresa precisar adiantar o pagamento ao fornecedor, por exemplo, selecione 'Sim'. Isso irá acarretar em bitributação."),
                    value: true,
                    onChange: this.onChangeForm.bind(this, 'doubleTaxation'),
                    successIcon: false,
                    visited: true,
                    values: [{ code: true, label: I18n.get("Sim") }, { code: false, label: I18n.get("Não") }],
                    isHorizontal: true
                },
                file: {
                    value: 'Selecione...',
                    onChange: this.onUpload.bind(this),
                    error: props.errors.file,
                    visited: true,
                }
            },
            file: {},
            form: {
            },
            errors: {},
            task: {},
            upload_task: {},
            itemComment: null,
            itemStatus: null
        };

        this.addItem = this.onAddItem.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);

            nextState.file = {};
        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormValue(prop, value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            quotationId: this.props.quotation.id,
            sectionId: this.props.section.id,
            ...this.state.form
        };

        data.file = this.state.file;

        return data;
    }

    onAddItem(e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.create_universal_quotation_item(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

                if (task.result) {
                    this.props.closeModal();
                }

            });
    }


    onUpload(e) {

        var file = e.target.files[0];

        if (!file) return;


        if (e) e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            var upload_task = new Task();

            upload_task.start();

            this.setState({ upload_task: upload_task }, function () {

                const data = {
                    projectId: this.props.match.params.projectId,
                    partnerId: this.props.partner.id,
                    file_type: 'item',
                    name: file.name,
                    base64: e.target.result
                };

                this.props.uploadEventQuotationfile(data)
                    .then(response => {

                        this.setState((prevState) => {

                            var nextState = Object.assign({}, prevState);

                            nextState.file = response.data;
                            nextState.form.file = file.name;
                            nextState.upload_task = { result: true };

                            return nextState;
                        });

                    })
                    .catch(error => {

                        this.setState({ upload_task: { result: false } });

                    });
            });
        };
        reader.readAsDataURL(file);
    }

    delFile(item) {

        var data = {
            projectId: this.props.match.params.projectId,
            partnerId: this.props.partner.id,
            ...item
        };

        this.setState({ file: {} }, function () {
            this.props.delEventQuotationFile(data);
        });

    }

    onDrop(files) {

        files = files.map(file => {

            this.upload(file);
        });
    }

    onCancel() {

    }

    getItemType(value) {

        return (this.props.bp.eventQuotationItemType && this.props.bp.eventQuotationItemType.find((item) => item.code == value)) || {};
    }

    getItemCategory(value) {

        return (this.props.bp.eventSegments && this.props.bp.eventSegments.find((item) => item.code == value)) || {};
    }

    getTotal() {

        let { quantity, recurrence, price } = this.state.form;

        if (quantity && recurrence && price) {

            price = price.replace(/[^\d]/g, '');

            const total = numeral(quantity).value() * numeral(recurrence).value() * (numeral(price).value() / 100);

            return total;
        }
        else {
            return 0;
        }
    }

    render() {

        const quotation = this.props.quotation || {};
        const currency = this.props.currency || {};
        
        const canEdit = this.props.auth.is_partner && quotation.isOpen;

        const sectors = [];

        this.props.bp.eventSegments && this.props.bp.eventSegments.map((item) => {
            if (sectors.indexOf(item.sector) == -1) {
                sectors.push(item.sector);
            }
        });

        const segments = sectors.map((item) => ({ code: item, label: item }));

        const subs = [];

        this.props.bp.eventSegments && this.props.bp.eventSegments.map((item) => {

            if (item.sector === this.state.form.segment) {
                subs.push({
                    code: item.code, label: `${item.cat} / ${item.subCat}`
                })
            }
        });

        return (
            <section className="quotation-item-form" style={{ paddingBottom: '1rem' }}>

                <p className="title is-5">{I18n.get("Adicionar Item de Terceiro")}</p>

                <div style={{ backgroundColor: '#fff', padding: '1rem' }}>

                    <div className="columns">

                        <div className="column is-4">
                            <SelectField
                                {...this.state.fields.itemType}
                                label={I18n.get("Tipo")}
                                value={this.state.form.itemType}
                                error={this.state.errors.itemType}
                                values={this.props.bp.eventQuotationItemType} />
                        </div>
                        <div className="column is-4">
                            <SelectSearchField
                                {...this.state.fields.segment}
                                label={I18n.get("Segmento")}
                                values={segments}
                                value={this.state.form.segment}
                                error={this.state.errors.segment} />
                        </div>
                        <div className="column is-4">
                            <SelectSearchField
                                {...this.state.fields.category}
                                label={I18n.get("Categoria")}
                                values={subs}
                                value={this.state.form.category}
                                error={this.state.errors.category} />
                        </div>
                    </div>

                    <div className="columns">

                        <div className="column is-4">
                            <TextField
                                {...this.state.fields.description}
                                label={I18n.get("Descrição")}
                                value={this.state.form.description}
                                error={this.state.errors.description} />
                        </div>

                        <div className="column is-2">
                            <NumericField
                                {...this.state.fields.quantity}
                                label={I18n.get("Quantidade")}
                                value={this.state.form.quantity}
                                error={this.state.errors.quantity} />
                        </div>
                        <div className="column is-2">
                            <NumericField
                                {...this.state.fields.recurrence}
                                label={I18n.get("Período")}
                                value={this.state.form.recurrence}
                                error={this.state.errors.recurrence} />
                        </div>
                        <div className="column is-4">
                            <MoneyField
                                {...this.state.fields.price}
                                currency={currency}
                                label={I18n.get("Preço unitário")}
                                value={this.state.form.price}
                                error={this.state.errors.price} />
                        </div>

                    </div>

                    <div className="columns">

                        <div className="column is-4">

                            <MoneyField
                                label={I18n.get('Total')}
                                value={this.getTotal()}
                                readOnly={true}
                                currency={currency}
                            />

                        </div>

                        <div className="column is-4">
                            <RadioMultiField
                                {...this.state.fields.doubleTaxation}
                                label={I18n.get("Bitributação")}
                                value={this.state.form.doubleTaxation}
                                error={this.state.errors.doubleTaxation} />
                        </div>

                        <div className="column is-4">

                            <UploadField
                                {...this.state.fields.file}
                                label={I18n.get("Upload de orçamento")}
                                value={this.state.form.file}
                                error={this.state.errors.file}
                                uploading={this.state.upload_task.busy} />

                            {this.props.errors['file.id'] && <p className="help is-danger">
                                {this.props.errors['file.id'].errorMessage}
                            </p>}

                        </div>

                    </div>

                </div>

                <br />

                <button
                    type="button"
                    task={this.state.task}
                    onClick={this.addItem}
                    className="button bt-bpool is-pulled-right"
                    disabled={this.state.upload_task.busy}>{I18n.get("Adicionar")}</button>

                <br className="is-clearfix" />

                <br />

            </section>
        );
    }
}

export default QuotationItemForm;
