import styled from "styled-components";
import Carousel from "react-elastic-carousel";

export const Wrapper = styled.div``;

export const Title = styled.div`
  span {
    font-weight: bold;
    font-size: ${(props) => (props.windowWidth <= 1024 ? "16px;" : "24px;")};
    line-height: ${(props) => (props.windowWidth <= 1024 ? "22px;" : "33px;")};
    color: #000000;
  }

  hr {
    margin: ${(props) =>
      props.windowWidth <= 1024 ? "5px 0 0 0;" : "15px 0 0 0;"};
    width: ${(props) => (props.windowWidth <= 1024 ? "50px;" : "75px;")};
    border-bottom: ${(props) =>
      props.windowWidth <= 1024 ? "3px solid;" : "6px solid;"};
    border-color: #000000;
  }
`;

export const Cards = styled.div`
  display: flex;
  margin-top: 50px;
`;

const StyledCarousel = styled(Carousel)`
  margin: 0;

  .rec-item-wrapper {
    justify-content: flex-start;
    padding: 0 15px !important;
  }

  .rec-slider-container {
    margin: 0 -10px;
  }

  .rec-slider-container {
    margin: 0 -10px;
  }

  .rec-slider {
    margin: 0;
  }

  button {
    width: 22px;
    height: 22px;
    left: 0px;
    top: 0px;

    background: #c4c4c4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    :hover,
    :focus {
      box-shadow: 0 0 1px 3px #2f2f2f;
    }
  }

  .rec-dot_active {
    background: #2f2f2f;
  }

  .rec-pagination {
    margin-top: 30px;
  }
`;

export { StyledCarousel as Carousel };
