import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { TitleMiddleLine } from '../../components/Titles';
import constants from "../../../constants";
import styled from 'styled-components';
import MultUploadFile from '../../components/MultUploadFile';

import {
    Row,
    Col,
    Button,
    Checkbox,
    InputNumber,
    Input,
    Icon,
    Tooltip,
    Select,
    Form
} from 'antd';


const { TextArea, Search } = Input;
const { Option } = Select;


const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
      margin-right: 20px;
  }
`;

const BoxAddFild = styled(Search)`
    button {
        position:relative;

        i {
            display: none;
        }
    }
`;

class FormCountries extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }




    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {



                let codeCountry = this.props.countryCodes.filter(i => i.code == values.country)

                this.setState({
                    formState: values,
                })

                let data = {
                    country: codeCountry[0],
                    workType: values.workType,
                    partnerId: this.props.partnerId
                }


                this.props.action(data);
            }
        });

    };

    componentDidMount() {
        this.setState({
            loading: true
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const { partner } = this.props;

        const ListCategorie = this.props.sectorsFlat && this.props.sectorsFlat.sort((a, b) => (a.label > b.label) - (a.label < b.label));


        return (
            <Form onSubmit={this.handleSubmit}>
                <Row className="RowSection">
                    <Col sm={12}>
                        <Form.Item label={!this.props.exterior ? I18n.get("Qual país você gostaria de atender") : I18n.get("Qual país")}>
                            {getFieldDecorator('country', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.jobTitle ? this.props.item.jobTitle : null
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.countryCodes && this.props.countryCodes.map((item) => {
                                        return <Option value={item.code} key={item.code}>{item.name}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {!this.props.exterior &&
                
                <Row>
                    <Col sm={24}>
                        <Form.Item label={I18n.get("Justifique sua escolha")}>
                            {getFieldDecorator('workType', {
                                rules: [{ required: false, message: 'ops!' }],
                            })(
                                <Checkbox.Group>
                                    {this.props.partnerWorkAbroad && this.props.partnerWorkAbroad.map((i) => {
                                        return <Col sm={24}><Checkbox value={i.code}>{i.label}</Checkbox></Col>
                                    })}
                                </Checkbox.Group>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                }

                <RowSubmitModal>
                    <Col sm={24}>

                        <Button shape="round" onClick={() => this.props.cancel()}>{I18n.get("Cancelar")}</Button>

                        <Button type="primary" shape="round" htmlType="submit">
                            {I18n.get("Adicionar")}
                        </Button>
                    </Col>
                </RowSubmitModal>
            </Form>
        );
    }
}

const FormFormCountries = Form.create({})(FormCountries);

export default FormFormCountries;
