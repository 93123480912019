import React, { Component } from "react";
import { I18n } from 'aws-amplify';
import Slider from "react-slick";
import styled from 'styled-components';

import icoNext from '../../image/ico-next.png';
import icoPrev from '../../image/ico-prev.png';

const ContainerSlider = styled.div`
        padding: 0 70px;

        .slick-prev, .slick-next {
            background: no-repeat center center;
            border: solid 1px;
            width: 38px;
            height: 66px;

            &::before {
                display: none;
            }
        }

        .slick-list {
            padding-bottom: 100px;
        }

        .slick-dots.slick-thumb {
            bottom: 50px;
            
        }

        .slick-prev {
            background-image: url("${icoPrev}");
            left: -50px;
        }

        .slick-next {
            background-image: url("${icoNext}");
            right: -50px;
        }

        .ItemImgFull {
            background: #fff;
            height: 400px;
            text-align: center;

            img {
                height: 100%;
                width: auto;
                max-width: 100%;
                display: inline-block;
            }
        }
  
`;

class CarouselThumbs extends Component {
    render() {
        const { children } = this.props;

        return (
            <ContainerSlider>
                <Slider
                    {...this.props}
                >
                    {children}
                </Slider>
            </ContainerSlider>
        );
    }
}

export default CarouselThumbs;
