import get_knowledge from './get_knowledge';
import get_faqs from './get_faqs';
import get_videos from './get_tutoriais';
import get_maps from './get_maps';


// MACRO
export const WIKIS_KNOWLEDGE = 'wikis_knowledge_get';


// FAQS
export const WIKIS_FAQ_GET = 'wikis_faqs_get';

// MAPAS
export const WIKIS_MAPS_GET = 'wikis_maps_get';


// VIDEOS
export const WIKIS_TUTORIAIS_GET = 'wikis_tutoriais_get';

export const WIKIS_API = {
    get_faqs: get_faqs,
    get_videos: get_videos,
    get_maps: get_maps,
    get_knowledge: get_knowledge
}
