import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Input } from "../../../../../../components/UI/Input";
import { InputMask } from "../../../../../../components/UI/InputMask";
import { parseQueryString } from "../../../../pages/Initial/Filters/utils/resolvers";
import { hasError, clearErrorInput } from "../../../../utils/errorsInput";
import * as S from "../../styles";

export const FormDadosDaEmpresa = ({
  inputsForm,
  handleChangeInputForm,
  errorsInputs,
  handleSetErrorsInputs,
  isLoadingGet,
  inputsDisableds,
}) => {
  let location = useLocation();
  const state = useSelector((state) => state);
  const [culture, setCulture] = useState("br");
  useEffect(() => {
    const parameters = parseQueryString(location?.search);
    if (parameters?.culture) {
      setCulture(parameters?.culture);
    } else if (state?.auth?.locale) {
      setCulture(state?.auth?.locale);
    }
  }, [location, state]);

  return (
    <>
      <h3>{I18n.get("Dados da empresa")}</h3>
      <Row>
        <Col xs={24} md={12} style={{ paddingLeft: 0 }}>
          <Input
            placeholder={I18n.get("Razão social")}
            label
            required
            value={inputsForm?.registrationName}
            onChange={(value) =>
              handleChangeInputForm("registrationName", value)
            }
            loading={isLoadingGet}
            error={hasError({
              arr: errorsInputs,
              field: "registrationName",
            })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "registrationName",
                arr: errorsInputs,
              });
              handleSetErrorsInputs(errorsArr);
            }}
            disabled={inputsDisableds}
          />
        </Col>
        <Col xs={24} md={12} style={{ paddingRight: 0 }}>
          {culture === "pt" || culture === "br" || culture === "" ? (
            <InputMask
              id="registrationId"
              label={I18n.get("CNPJ")}
              defaultValue={inputsForm?.registrationId}
              loading={isLoadingGet}
              onChange={(value) =>
                handleChangeInputForm("registrationId", value?.formattedValue)
              }
              mask="##.###.###/####-##"
              required
              error={hasError({
                arr: errorsInputs,
                field: "registrationId",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "registrationId",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
              disabled={inputsDisableds}
            />
          ) : (
            <Input
              placeholder={I18n.get("CNPJ")}
              label
              required
              value={inputsForm?.registrationId}
              onChange={(value) =>
                handleChangeInputForm("registrationId", value)
              }
              loading={isLoadingGet}
              error={hasError({
                arr: errorsInputs,
                field: "registrationId",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "registrationId",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
              disabled={inputsDisableds}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
