import React, { Fragment } from 'react';

import { I18n } from 'aws-amplify';

// import Modal from '../ui/modal';
import Dphoto from '../../image/dphoto.png';
import ReactPlayer from 'react-player';

import PartnerDetailReceita from '../bpool/_partner-details-company-receita';

import {
    Icon,
    Button,
    Modal,
    Select,
    Row,
    Col
} from 'antd';

const { Option } = Select;

class Card extends React.Component {

    constructor(props) {

        super();

        this.state = {
            selected: null,
            visibleImg: false,
            visibleVideo: false,
            selectTier:"",
        };

        this.select = this.onSelect.bind(this);

        this.closeModal = this.onCloseModal.bind(this);

    }

    handleSelectTier = (value) => {
        this.setState({
            selectTier: value
        })
    }

    sendMarket = () => {
        const data = {
            partnerId: this.props.bp?.partner?.id,
            market: this.state.selectTier
        }


        this.props.update_market(data);
        
        setTimeout(() => {
            this.setState({selectTier: ""});
        }, 1000);
    }

    showModalImg = () => {
        this.setState({
            visibleImg: true,
        });
    };

    showModalVideo = () => {
        this.setState({
            visibleVideo: true,
        });
    };

    handleCancel = e => {
        this.setState({
            visibleImg: false,
            visibleVideo: false,
        });
    };

    onSelect(item, e) {
        this.setState({ selected: item });
    }

    onCloseModal(e) {
        e.preventDefault();
        this.setState({ selected: null });
    }


    render() {

        if (!this.props.bp) return null;
        if (!this.props.bp.partner) return null;
        if (!this.props.bp.partner.company) return null;

        const item = this.props.bp.partner.company;

        return (
            <div className="card">
                <div className="card-content">


                    <Row gutter={1}>
                        <Col sm={24}>
                            <h3 className="title is-4">{I18n.get("Empresa")}</h3>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={1}>
                        <Col sm={2}>
                            <img width="100%" src={item.logoImage || Dphoto} />
                        </Col>
                        <Col sm={22}>
                            <div className="admin-content-right">
                                <Row gutter={16}>
                                    <Col sm={9}>
                                        <h3 className="title-bpool regular">
                                            {item.name} (M {item.tMarket?.join('')})
                                        </h3>
                                        <div className="form-tier">
                                            <div className="selectTier">

                                                <Select
                                                    style={{ width: "100%" }}
                                                    showSearch={true}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    onChange={this.handleSelectTier}
                                                    value={this.state.selectTier}
                                                    
                                                    >
                                                    <Option value="" >{I18n.get("Selecione")}</Option>
                                                    <Option value="1" >1</Option>
                                                    <Option value="2" >2</Option>
                                                    <Option value="12" >1,2</Option>
                                                
                                                </Select>

                                            </div>
                                                <Button onClick={() => this.sendMarket()} className="button bt-bpool black" disabled={!this.state.selectTier}>Alterar</Button>
                                        </div>
                                        <p><b>{I18n.get("Razão social")}: </b>{item.registrationName}</p>
                                        <p><b>{I18n.get("CNPJ")}:  </b>{item.registrationId}</p>
                                    </Col>
                                    <Col sm={15}>
                                        <p><b>{I18n.get("Ano fundação")}: </b> {item.registrationDate}</p>
                                        <p><b>{I18n.get("Telefone")}: </b>{item.phoneNumber}</p>
                                        <br />
                                        <p className="listButtons">
                                            {item.website &&
                                                <Fragment>
                                                    <a target="_blank" href={item.website} className="button bt-bpool black"><Icon type="link" /> {I18n.get("Websites")}</a>
                                                    <Button onClick={() => this.setState({ visibleImg: true })} className="button bt-bpool black" disabled={!item.introImage}><Icon type="eye" theme="filled" /> {I18n.get("Imagem da introdução")}</Button>
                                                    <Button onClick={() => this.setState({ visibleVideo: true })} className="button bt-bpool black" disabled={!item.introVideo}><Icon type="eye" theme="filled" /> {I18n.get("Vídeo de introdução")}</Button>
                                                </Fragment>
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>



                </div>

                {/* <Modal isOpen={!!this.state.selected} closeModal={this.closeModal}>

                    <PartnerDetailReceita item={this.state.selected} />

                </Modal> */}


                <Modal
                    title={I18n.get("Imagem da introdução")}
                    visible={this.state.visibleImg}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    footer={null}
                >
                    <p>
                        <img src={item.introImage} />
                    </p>
                </Modal>

                <Modal
                    title={I18n.get("Vídeo de introdução")}
                    visible={this.state.visibleVideo}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    footer={null}
                >

                    <ReactPlayer url={item.introVideo}
                        playing={true}
                        loop={true}
                        controls={false}
                        muted={true}
                        pip={false}
                        width='100%'
                        style={{ backgroundColor: '#000', width: '100%', maxHeight: '300px', margin: '0 auto' }} />

                </Modal>

            </div>
        );
    }

}

export default Card;
