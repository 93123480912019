import styled from "styled-components";
import {
  Collapse as CollapseAntd,
  Table as TableAntd,
  Skeleton as SkeletonAntd,
  Modal as ModalAntd,
} from "antd";

const { Panel: PanelAntd } = CollapseAntd;

export const Wrapper = styled.div`
  margin: 30px 0 60px 0;
`;

export const Collapse = styled(CollapseAntd)`
  background: transparent;
  border: none;

  .ant-collapse-content-active {
    border: none !important;
  }
`;

export const Panel = styled(PanelAntd)`
  border: none !important;
  background: #fafafa;
  margin-bottom: 5px;

  .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        margin-right: 15px;
      }

      p {
        cursor: pointer;
        height: 100%;
        font-size: 14px;
        font-weight: 700;
        padding-top: 5px;
      }
    }
  }
`;

export const Table = styled(TableAntd)`
  thead tr th {
    text-transform: uppercase;
    background: #ffffff;

    span {
      color: #000000;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .ant-table-placeholder {
    border: solid 1px #ccc;
    border-top: none;
  }
`;

export const ExtraAccordion = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
`;

export const ButtonUploadPartner = styled.div`
  margin-right: 15px;

  button {
    z-index: 2;
    cursor: pointer;
    border: 1px solid;
    border-color: #1883ff;
    background: #ffffff;
    padding: 5px 15px;
    border-radius: 8px;
    color: #1883ff;
    font-size: 12px;
    text-transform: capitalize;

    :hover {
      background: #1883ff;
      color: #ffffff;
    }
  }
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;

  a,
  a:visited {
    color: inherit;
  }

  a:hover {
    color: #1883ff;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0px;

    :hover {
      i,
      svg {
        color: #1883ff;
      }
    }
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-accordions-list {
    margin: 30px 0 60px 0;
    height: 285px;

    li {
      height: 53px !important;
      margin-top: 5px !important;

      :first-child {
        margin-top: 0px !important;
      }
    }
  }
`;

export const EmptyProject = styled.div`
  font-size: 14px;
`;

export const ModalViewImage = styled(ModalAntd)`
  .ant-modal-header,
  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    padding: 50px 15px 15px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  @media (max-width: 1365px) {
    width: 80% !important;
  }

  @media (min-width: 1366px) {
    width: 1300px !important;
  }
`;

export const ViewText = styled.p`
  width: 100%;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
