import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Radio, Modal, Checkbox } from "antd";
import { I18n } from "aws-amplify";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBpay } from "../../../../../hooks/useBpay/useBpay.hook";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { Input } from "../../../../../components/UI/Input";
import { CurrencyInput } from "../../../../../components/UI/CurrencyInput";
import { InputMask } from "../../../../../components/UI/InputMask";
import { Alert } from "../../../../../components/UI/Alert";
import { Dialog } from "../../../../../components/UI/Dialog";
import { FileUpload } from "../../../../../components/UI/FileUpload";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import { NewBPay } from "../../../templates/NewBPay";
import BpayContext from "../../../context/BpayContext";
import { TableSuppliers } from "./TableSuppliers";
import { TableSuppliersPreview } from "./TableSuppliersPreview";
import {
  hasError,
  clearErrorInput,
} from "../../../../RegistrationFlow/utils/errorsInput";
import inputsFormInit from "./inputsFormInit.json";

import * as S from "./styles";

export const NewBpaySuppliers = () => {
  let params = useParams();
  let history = useHistory();
  let location = useLocation();
  const state = useSelector((state) => state);
  const {
    sharedSetupBpay,
    sharedStateBpay,
    fetchDataBpay,
    fetchDataBpayUpdate,
  } = useContext(BpayContext);

  const {
    sendSupplier,
    importSuppliers,
    bulkCreateSuppliers,
    step2BpayGenerateBudget,
  } = useBpay();
  const [inputsForm, setInputsForm] = useState(inputsFormInit);
  const [bpayId, setBpayId] = useState(undefined);
  const [typeAddSupplier, setTypeAddSupplier] = useState(1);
  const [alertInfo, setAlertInfo] = useState(null);
  const [isLoadingAddSupplier, setIsLoadingAddSupplier] = useState(false);
  const [fileSpreadsheet, setFileSpreadsheet] = useState({});
  const [sendingSpreadsheet, setSendingSpreadsheet] = useState(false);
  const [previewTableData, setPreviewTableData] = useState({
    listPreview: [],
    listDuplicateds: [],
    errors: [],
  });
  const [modalPreviewTable, setModalPreviewTable] = useState({ open: false });
  const [dialogPdf, setDialogPdf] = useState({ visible: false });
  const [saveDuplicateds, setSaveDuplicateds] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [errorsInputs, setErrorsInputs] = useState([]);

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleClickOkDialog = ({ pdfUrl, id }) => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao baixar o PDF"),
        duration: 3,
      });
    }
    history.push(`/cliente/bpay/new/po/${id}`);
  };

  const handleGenerateBudget = async ({ dialog }) => {
    try {
      setIsSending(true);
      const objSend = {
        bpayId: params?.bpayId,
      };
      const response = await step2BpayGenerateBudget(objSend);
      await fetchDataBpayUpdate({ id: params?.bpayId });
      if (dialog) {
        setDialogPdf({
          visible: true,
          id: response?.id,
          urlPdf: response?.urlPdf,
        });
      }
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao gerar o orçamento"),
        duration: 3,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleSend = async ({ id, refreshBudget }) => {
    if (refreshBudget) {
      await handleGenerateBudget({ dialog: false });
    }
    history.push(`/cliente/bpay/new/po/${id}`);
  };

  const handleBack = async (id) => {
    history.push(`/cliente/bpay/new/data/${id}`);
  };

  const handleChangeTypeAddSupplier = (e) => {
    setTypeAddSupplier(e?.target?.value);
  };

  const handleDownloadSpreadsheet = async () => {
    // colocar if de download por idioma
    const exampleSpreadsheet =
      "https://assets.bpool.co/bpay/MODELO_BPay_Upload_Fornecedores.xlsx";
    const link = document.createElement("a");
    link.href = exampleSpreadsheet;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAddNewSupplier = async () => {
    setIsLoadingAddSupplier(true);
    setAlertInfo(null);
    try {
      await sendSupplier({ bpayId, ...inputsForm });
      // setAlertInfo({
      //   type: "success",
      //   message: "Pagamento Fornecedor foi cadastrado com sucesso",
      // });
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get(
          "Pagamento Fornecedor foi cadastrado com sucesso"
        ),
        duration: 3,
      });
      await fetchDataBpayUpdate({ id: params?.bpayId });
      setInputsForm(inputsFormInit);
    } catch (error) {
      setErrorsInputs(error?.response?.data?.errors);
      setAlertInfo({
        type: "error",
        message: "Erro ao cadastrar fornecedor",
      });
    }
    setIsLoadingAddSupplier(false);
  };

  const addDuplicatedFlag = (list, listDuplicateds) => {
    return list.map((item) => ({
      ...item,
      duplicated: listDuplicateds.includes(item.emailPrimary),
    }));
  };

  const handleSpreadsheet = (file) => {
    setFileSpreadsheet(file);
  };

  const handleSpreadsheetSend = async (file) => {
    setAlertInfo(null);
    setSendingSpreadsheet(true);
    try {
      const response = await importSuppliers({
        spreadsheet: file,
        id: params?.bpayId,
        forceSave: false,
      });

      if (response?.bPaySuppliersValid?.length) {
        setModalPreviewTable({ open: true });
        setPreviewTableData({
          // listDuplicateds: response?.bPaySupplierDuplicatedEmailPrimary,
          listDuplicateds: [],
          listPreview: response?.bPaySuppliersValid,
          errors: [],
        });

        await fetchDataBpayUpdate({ id: params?.bpayId });
        // setAlertInfo({
        //   type: "success",
        //   message: "Sucesso ao importar a planilha",
        // });
        NotificationBPool.open({
          type: "success",
          title: I18n.get("Sucesso!"),
          description: I18n.get("Sucesso ao importar a planilha"),
          duration: 3,
        });
      } else {
        setAlertInfo({
          type: "error",
          message: I18n.get("Planilha importada está vazia."),
        });
      }
    } catch (error) {
      if (
        error?.response?.data?.errorMessage[0] ===
        "Fornecedor de BPay duplicado."
      ) {
        setModalPreviewTable({ open: true });

        const suppliersSaveds =
          sharedStateBpay?.data?.bPaySuppliers?.bPaySuppliers?.map((item) => ({
            ...item,
            bpayId: params?.bpayId,
          }));

        let updatedList = [];

        if (suppliersSaveds?.length) {
          updatedList = addDuplicatedFlag(
            [...suppliersSaveds, ...error?.response?.data?.bPaySuppliersValid],
            error?.response?.data?.bPaySupplierDuplicatedEmailPrimary
          );
        } else {
          updatedList = addDuplicatedFlag(
            [...error?.response?.data?.bPaySuppliersValid],
            error?.response?.data?.bPaySupplierDuplicatedEmailPrimary
          );
        }

        setPreviewTableData({
          listDuplicateds:
            error?.response?.data?.bPaySupplierDuplicatedEmailPrimary,
          listPreview: updatedList,
          errors: error?.response?.data?.errorMessage,
        });
      } else if (error?.response?.data?.errorMessage[0] === "Arquivo lido.") {
        setAlertInfo({
          type: "error",
          message: I18n.get(
            "O arquivo importado deve ser .xlsx (baixe o modelo acima)."
          ),
        });
      } else {
        function arrayParaListaUl(array) {
          let listaHtml = "<ul>";
          array.forEach((item) => {
            listaHtml += `<li>${item}</li>`;
          });
          listaHtml += "</ul>";

          return listaHtml;
        }
        setAlertInfo({
          type: "error",
          // message: error?.response?.data?.errorMessage[0],
          message:
            error?.response?.data?.errorMessage?.length > 1
              ? arrayParaListaUl(error?.response?.data?.errorMessage)
              : error?.response?.data?.errorMessage[0],
        });
      }
    }
    setSendingSpreadsheet(false);
  };

  useEffect(() => {
    if (fileSpreadsheet?.name) {
      handleSpreadsheetSend(fileSpreadsheet);
    }
  }, [fileSpreadsheet]);

  const deleteFiles = async (fileOrId) => {
    if (fileOrId?.name) {
      setFileSpreadsheet({});
    }
  };

  const previewDataCb = (data) => {
    const arrDuplicateds = [];
    data.map((item) => {
      if (item?.duplicated) {
        return arrDuplicateds.push(item?.emailPrimary);
      } else return;
    });

    setPreviewTableData({
      ...previewTableData,
      listPreview: data,
      listDuplicateds: arrDuplicateds,
    });
  };

  const clearModalPreview = async () => {
    setPreviewTableData({
      listPreview: [],
      listDuplicateds: [],
      errors: [],
    });
    setModalPreviewTable({ open: false });
    setSaveDuplicateds(false);
  };

  const handleCloseModalPreview = async () => {
    setIsLoadingAddSupplier(true);
    await clearModalPreview();
    setIsLoadingAddSupplier(false);
  };

  const saveDataPeview = async () => {
    try {
      setIsLoadingAddSupplier(true);
      const response = await bulkCreateSuppliers({
        infos: [
          ...previewTableData?.listPreview,
          ...previewTableData?.listDuplicateds,
        ],
        forceIgnoreDuplications: previewTableData?.listDuplicateds?.length
          ? saveDuplicateds
          : false,
      });
      handleCloseModalPreview();
      // setAlertInfo({
      //   type: "success",
      //   message: "Dados de fornecedor importados com sucesso",
      // });
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("Dados de fornecedor importados com sucesso"),
        duration: 3,
      });
      await fetchDataBpayUpdate({ id: params?.bpayId });
    } catch (error) {
      setAlertInfo({
        type: "error",
        message: "Erro ao importar fornecedores",
      });
    }
    setIsLoadingAddSupplier(false);
  };
  const handleChangeSaveDuplicateds = (e) => {
    setSaveDuplicateds(e.target.checked);
  };

  useEffect(() => {
    if (bpayId || bpayId === null) {
      fetchDataBpay({ id: params?.bpayId, query: location?.search });
    }
  }, [bpayId, location]);

  useEffect(() => {
    fetchDataBpayUpdate({ id: params?.bpayId, query: location?.search });
  }, [location]);

  useEffect(() => {
    setBpayId(params?.bpayId ? params?.bpayId : null);
  }, [params?.bpayId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const disabledAndTooltip = (dataBpay) => {
    if (dataBpay?.status === 1) {
      return {
        disabled: true,
        tooltip:
          "Para inserir a PO, deve Gerar um Orçamento atualizado primeiro.",
      };
    } else if (dataBpay?.status === 2) {
      if (dataBpay?.divergent?.isDivergent) {
        return {
          disabled: true,
          tooltip: "Para inserir a PO, insirir os BPayments corretamente.",
        };
      } else {
        return {
          disabled: false,
          tooltip: "",
        };
      }
    } else {
      return {
        disabled: false,
        tooltip: "",
      };
    }
  };

  const currencyBilling = (culture) => {
    const billing = sharedSetupBpay?.setup?.listCurrenciesDefault?.find(
      (item) => item?.culture === culture
    );
    if (billing) {
      return billing;
    } else {
      return "";
    }
  };

  return (
    <>
      <NewBPay
        buttons={
          sharedSetupBpay?.isLoading || sharedStateBpay?.isLoading ? (
            <></>
          ) : (
            <>
              <ButtonBpool
                text={I18n.get("Voltar")}
                theme="secondary"
                onClick={() => handleBack(bpayId)}
              />
              {sharedStateBpay?.data?.status === 1 ||
              sharedStateBpay?.data?.status === 2 ? (
                <ButtonBpool
                  text={I18n.get("Gerar orçamento atualizado")}
                  theme="tertiary"
                  onClick={() => handleGenerateBudget({ dialog: true })}
                  loading={isSending}
                  disabled={
                    sharedStateBpay?.data?.divergent?.isDivergent ? true : false
                  }
                  tooltip={
                    sharedStateBpay?.data?.divergent?.isDivergent
                      ? I18n.get(
                          "Para gerar orçamento atualizado, o valor Total BPayments deve ser igual ao valor do Total BPay."
                        )
                      : null
                  }
                />
              ) : null}
              <ButtonBpool
                text={I18n.get("Inserir PO")}
                theme="primary"
                loading={isSending}
                onClick={() => handleSend({ id: bpayId, refreshBudget: true })}
                disabled={disabledAndTooltip(sharedStateBpay?.data)?.disabled}
                tooltip={disabledAndTooltip(sharedStateBpay?.data)?.tooltip}
              />
            </>
          )
        }
      >
        <S.TitleContent>
          <h3>{I18n.get("Dados do(s) fornecedor(es)")}</h3>
        </S.TitleContent>
        {sharedSetupBpay?.isLoading ||
        sharedStateBpay?.isLoading ? null : sharedStateBpay?.data?.status &&
          sharedStateBpay?.data?.status === 1 ? (
          <S.AlertPage>
            <Alert
              message={I18n.get("BPay está em rascunho.")}
              type="warning"
              notClosable
            />
          </S.AlertPage>
        ) : null}
        <S.Content>
          <Row>
            <Col md={24}>
              <S.SelectTypeAddSupplier>
                <p>{I18n.get("Inserir")}</p>
                <Radio.Group
                  onChange={handleChangeTypeAddSupplier}
                  value={typeAddSupplier}
                >
                  <Radio value={1}>{I18n.get("Planilha")}</Radio>
                  <Radio value={2}>{I18n.get("Manualmente")}</Radio>
                </Radio.Group>
              </S.SelectTypeAddSupplier>
            </Col>
            <Col md={24}>
              {typeAddSupplier === 1 ? (
                <S.DownloadSpreadsheet>
                  <p>{I18n.get("Faça download da planilha modelo")}</p>
                  <S.DownloadSpreadsheetInfos>
                    <p>
                      {I18n.get(
                        "Para importar seus lançamentos a partir de uma planilha, ela precisa estar no formato correto da planilha modelo que disponibilizamos. "
                      )}
                    </p>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <ButtonBpool
                        text={I18n.get("Baixar modelo")}
                        theme="tertiary"
                        onClick={handleDownloadSpreadsheet}
                      />
                    </div>
                  </S.DownloadSpreadsheetInfos>
                  <S.DownloadSpreadsheetUpload>
                    <S.Skeleton
                      loading={isLoadingAddSupplier}
                      active
                      title={false}
                      paragraph={{
                        rows: 1,
                        className: "skeleton-upload",
                      }}
                    >
                      <FileUpload
                        cbFiles={(file) => handleSpreadsheet(file)}
                        multiple={false}
                        isLoading={sendingSpreadsheet}
                        initialFiles={[]}
                        cbDeleteFile={(fileOrId) => deleteFiles(fileOrId)}
                        acceptedTypes={[
                          {
                            label: "XLSX",
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          },
                        ]}
                      />
                    </S.Skeleton>
                  </S.DownloadSpreadsheetUpload>
                </S.DownloadSpreadsheet>
              ) : (
                <S.FormManual>
                  <S.FormManualRow columns={2}>
                    <S.FormManualCol>
                      <Input
                        placeholder={I18n.get("Nome fornecedor")}
                        label
                        required
                        value={inputsForm.name}
                        onChange={(value) => onChangeInputForm("name", value)}
                        error={hasError({
                          arr: errorsInputs,
                          field: "name",
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "name",
                            arr: errorsInputs,
                          });
                          setErrorsInputs(errorsArr);
                        }}
                      />
                    </S.FormManualCol>
                    <S.FormManualCol>
                      <CurrencyInput
                        placeholder={I18n.get("Valor")}
                        value={inputsForm.total}
                        onChange={(value) => onChangeInputForm("total", value)}
                        currency={currencyBilling(
                          sharedStateBpay?.data?.clientCountry?.culture
                        )}
                        required
                        error={hasError({
                          arr: errorsInputs,
                          field: "total",
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "total",
                            arr: errorsInputs,
                          });
                          setErrorsInputs(errorsArr);
                        }}
                      />
                    </S.FormManualCol>
                  </S.FormManualRow>
                  <S.FormManualRow columns={2}>
                    <S.FormManualCol>
                      <Input
                        placeholder={I18n.get("E-mail fornecedor - 1")}
                        label
                        required
                        value={inputsForm.emailPrimary}
                        onChange={(value) =>
                          onChangeInputForm("emailPrimary", value)
                        }
                        error={hasError({
                          arr: errorsInputs,
                          field: "emailPrimary",
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "emailPrimary",
                            arr: errorsInputs,
                          });
                          setErrorsInputs(errorsArr);
                        }}
                      />
                    </S.FormManualCol>
                    <S.FormManualCol>
                      <Input
                        placeholder={I18n.get("E-mail fornecedor - 2")}
                        label
                        value={inputsForm.emailSecondary}
                        onChange={(value) =>
                          onChangeInputForm("emailSecondary", value)
                        }
                        error={hasError({
                          arr: errorsInputs,
                          field: "emailSecondary",
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "emailSecondary",
                            arr: errorsInputs,
                          });
                          setErrorsInputs(errorsArr);
                        }}
                      />
                    </S.FormManualCol>
                    <S.FormManualCol>
                      {state?.client?.country?.code === "BRA" ? (
                        <InputMask
                          id="phoneNumber"
                          label={I18n.get("Número de telefone")}
                          defaultValue={inputsForm?.phoneNumber}
                          onChange={(value) =>
                            onChangeInputForm(
                              "phoneNumber",
                              value?.formattedValue
                            )
                          }
                          mask={"(##) #####-####"}
                          error={hasError({
                            arr: errorsInputs,
                            field: "phoneNumber",
                          })}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "phoneNumber",
                              arr: errorsInputs,
                            });
                            setErrorsInputs(errorsArr);
                          }}
                        />
                      ) : (
                        <Input
                          placeholder={I18n.get("Número de telefone")}
                          label
                          value={inputsForm.phoneNumber}
                          onChange={(value) =>
                            onChangeInputForm("phoneNumber", value)
                          }
                          error={hasError({
                            arr: errorsInputs,
                            field: "phoneNumber",
                          })}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "phoneNumber",
                              arr: errorsInputs,
                            });
                            setErrorsInputs(errorsArr);
                          }}
                        />
                      )}
                    </S.FormManualCol>
                  </S.FormManualRow>
                  <S.FormManualRow columns={1}>
                    <S.FormManualCol
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <ButtonBpool
                        text={I18n.get("Adicionar")}
                        theme="primary"
                        onClick={handleAddNewSupplier}
                        loading={isLoadingAddSupplier}
                      />
                    </S.FormManualCol>
                  </S.FormManualRow>
                </S.FormManual>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={24} style={{ marginTop: 32 }}>
              <TableSuppliers
                isLoading={sharedStateBpay?.isLoading}
                alertInfo={alertInfo}
                onCloseAlert={() => setAlertInfo(null)}
                data={{
                  list: sharedStateBpay?.data?.bPaySuppliers?.bPayments,
                  totalRecords:
                    sharedStateBpay?.data?.bPaySuppliers
                      ?.totalRecordsBPaySuppliers,
                  totalBpay: sharedStateBpay?.data?.budgetFmt,
                  totalBpayments: sharedStateBpay?.data?.totalBPaymentsFmt,
                }}
                valuesDivergets={sharedStateBpay?.data?.divergent}
              />
            </Col>
          </Row>
        </S.Content>
      </NewBPay>

      <Modal
        title={I18n.get("Preview de dados da planilha importada")}
        centered
        width={1200}
        visible={modalPreviewTable?.open}
        onOk={saveDataPeview}
        onCancel={handleCloseModalPreview}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: previewTableData?.listDuplicateds?.length
                ? "space-between"
                : "flex-end",
            }}
          >
            {previewTableData?.listDuplicateds?.length ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={saveDuplicateds}
                  onChange={handleChangeSaveDuplicateds}
                >
                  {I18n.get("Salvar com dados duplicados?")}
                </Checkbox>
              </div>
            ) : null}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ButtonBpool
                text={I18n.get("Cancelar")}
                theme="secondary"
                onClick={handleCloseModalPreview}
              />
              <div style={{ marginLeft: 10 }}>
                <ButtonBpool
                  text={I18n.get("Salvar dados")}
                  theme="primary"
                  onClick={saveDataPeview}
                  loading={isLoadingAddSupplier}
                  disabled={
                    previewTableData?.listDuplicateds?.length
                      ? saveDuplicateds
                        ? false
                        : true
                      : false
                  }
                />
              </div>
            </div>
          </div>,
        ]}
      >
        <div style={{ height: 650 }}>
          <TableSuppliersPreview
            isLoading={sendingSpreadsheet}
            data={{
              list:
                [
                  ...previewTableData?.listPreview,
                  ...previewTableData?.listDuplicateds,
                ] || [],
              listDuplicateds: previewTableData.listDuplicateds,
              errors: previewTableData.errors,
            }}
            dataCb={(data) => previewDataCb(data)}
          />
        </div>
      </Modal>

      <Dialog
        visible={dialogPdf?.visible}
        text={I18n.get("Orçamento gerado com sucesso!")}
        subText={I18n.get(
          "Seu orçamento foi gerado, você pode baixa-lo agora ou prosseguir e baixar posteriormente."
        )}
        buttons={{
          ok: {
            text: I18n.get("Baixar PDF e avançar"),
            func: () =>
              handleClickOkDialog({
                pdfUrl: dialogPdf?.urlPdf,
                id: dialogPdf?.id,
              }),
          },
          cancel: {
            text: I18n.get("Avançar"),
            func: () => history.push(`/cliente/bpay/new/po/${dialogPdf?.id}`),
          },
        }}
      />
    </>
  );
};
