import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';

import RadioMultiField from '../../form/radio-multi-field';
import CheckboxMultiField from '../../form/checkbox-multi-field';

import { scrollToTop } from '../../../utils/ui';

import { BPitchType, BPitchTypeNames, BPitchScopeType, BPitchScopeTypeNames } from '../../../model';

import FieldIsRequiredMark from '../../ui/field-is-required-mark';

class EventTypeForm extends React.Component {
    constructor(props) {

        super(props);

        const { bPitch = { scopeType: [] } } = props;

        this.state = {
            fields: {
                pitchScopeType: {
                    name: 'pitchScopeType',
                    onChangeRadio: this.onChangeFormRadio.bind(this, 'pitchScopeType'),
                    onChangeCheckbox: this.onChangeFormCheckboxMulti.bind(this, 'pitchScopeType'),
                    // onBlur: this.onSave.bind(this, 'pitchScopeType'),
                },
            },
            errors: {},
            form: { ...bPitch },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch && bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }
            });

            this.setState(nextState);
        }
    }

    onChangeType(value, e) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['pitchType'] = value;

            return nextState;

        }, () => this.onSaving());
    }

    onChangeFormRadio(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = [value];
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving());
    }

    onChangeFormCheckboxMulti(prop, e) {

        const value = e.target.value;
        const checked = e.target.checked;


        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (checked) {
                nextState.form[prop] = [...nextState.form[prop], value];
            }
            else {
                nextState.form[prop] = nextState.form[prop].filter((v) => v != value);
            }

            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving());
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.props.bPitch,
            ...this.state.form
        };

        return data;
    }

    onSaving() {

        clearTimeout(this.t);
        this.t = setTimeout(() => this.onSave(), 1000)
    }


    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_bpitch(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    render() {

        const bPitch = this.props.bPitch || { pitchType: BPitchType.PerProject };

        const types = [BPitchType.PerProject, BPitchType.PerYear];



        const communicationScopeTypes = [BPitchScopeType.CommunicationIntegrated, BPitchScopeType.CommunicationDigital];

        const communicationScopeTypeValues = communicationScopeTypes.map((item) => ({ code: item, label: I18n.get(BPitchScopeTypeNames[item]) }))

        const liveMarketingScopeTypes = [BPitchScopeType.LiveMarketingEvent, BPitchScopeType.LiveMarketingShopper, BPitchScopeType.LiveMarketingIncentive, BPitchScopeType.LiveMarketingActivation];

        const liveMarketingScopeTypeValues = liveMarketingScopeTypes.map((item) => ({ code: item, label: I18n.get(BPitchScopeTypeNames[item]) }))

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Escolha o Tipo de Escopo")}</h1>

                <div className="columns">

                    {types.map((item) => {

                        const className = (bPitch && item === bPitch.pitchType) ? 'is-selected' : 'is-outlined';

                        return (<div className="column is-5" style={{ marginTop: '2rem' }}>
                            <a onClick={this.onChangeType.bind(this, item)} className={'button full bt-bpool black ' + className} style={{ minWidth: '250px' }}>
                                {I18n.get(BPitchTypeNames[item])}
                            </a>
                        </div>);

                    })}

                </div>

                <br />

                <h1 style={{ fontSize: '90%' }}>{I18n.get("Escolha um Escopo Predominante")} <FieldIsRequiredMark /></h1>

                <br />

                <div className="columns">

                    <div className="column is-4">
                        <p style={{ fontWeight: 'bold' }}>{I18n.get("Comunicação")}</p>

                        {bPitch && bPitch.isPerProject && <>

                            <RadioMultiField
                                {...this.state.fields.pitchScopeType}
                                onChange={this.state.fields.pitchScopeType.onChangeRadio}
                                value={this.state.form.pitchScopeType && this.state.form.pitchScopeType[0]}
                                error={this.state.errors.pitchScopeType}
                                values={communicationScopeTypeValues}
                            />

                        </>}

                        {bPitch && bPitch.isPerYear && <>

                            <CheckboxMultiField
                                {...this.state.fields.pitchScopeType}
                                onChange={this.state.fields.pitchScopeType.onChangeCheckbox}
                                value={this.state.form.pitchScopeType}
                                error={this.state.errors.pitchScopeType}
                                values={communicationScopeTypeValues}
                            />

                        </>}
                    </div>

                    <div className="column is-4">

                        <p style={{ fontWeight: 'bold' }}>{I18n.get("BPool Proposal")}</p>

                        {bPitch && bPitch.isPerProject && <>

                            <RadioMultiField
                                {...this.state.fields.pitchScopeType}
                                onChange={this.state.fields.pitchScopeType.onChangeRadio}
                                value={this.state.form.pitchScopeType && this.state.form.pitchScopeType[0]}
                                error={this.state.errors.pitchScopeType}
                                values={liveMarketingScopeTypeValues}
                            />

                        </>}

                        {bPitch && bPitch.isPerYear && <>

                            <CheckboxMultiField
                                {...this.state.fields.pitchScopeType}
                                onChange={this.state.fields.pitchScopeType.onChangeCheckbox}
                                value={this.state.form.pitchScopeType}
                                error={this.state.errors.pitchScopeType}
                                values={liveMarketingScopeTypeValues}
                            />

                        </>}

                    </div>


                </div>

                <br />
                <br />

                {/* <a className="bp-event-anchor">

                    <button onClick={this.props.onTypeSelect} className="button is-dark">{I18n.get("PRÓXIMO")}</button>

                </a> */}

            </div>
        );
    }
}

export default EventTypeForm;
