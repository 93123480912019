import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperRegister";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";

import { PartnerStatus } from "../../../model";

import { PARTNER_API } from "../../../actions/partner/actions";

import FormCompany from "../components/formCompany";

import { TitleBorder, TitleMiddleLine } from "../../components/Titles";

import styled from "styled-components";

import { Row, Col, Input, Select, Button, Form } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
  padding-top: "50px";
  padding-bottom: "90px";
  width: "100%";
  max-width: "1024px";
  margin: "0 auto";

  .RowSection {
    margin-bottom: "60px";
  }
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (data) => {
      dispatch(PARTNER_API.update_company(data));
    },
    create_tag: (data) => {
      dispatch(PARTNER_API.create_tags(data));
    },
    new_company: (data) => {
      dispatch(PARTNER_API.new_company(data));
    },
  };
};

class FullCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { partner } = this.props;

    if (
      this.props.partner &&
      this.props.partner.status == PartnerStatus.Approved
    ) {
      return <Redirect to="/parceiro/cadastro/sucesso" />;
    }

    //TODO: Find better way... GAMBI!
    let menuRegister = [...constants.MENU_REGISTER];
    for (let key in menuRegister) {
      menuRegister[key].label = I18n.get(menuRegister[key].label);
    }

    let partnerManagerRanges = [];
    let partnerRevenueRanges = [];

    switch (this.props.partner.signupLocale) {
      case "es":
        partnerManagerRanges = this.props.bp.partnerManagerRanges_es;
        partnerRevenueRanges = this.props.bp.partnerRevenueRanges_es;
        break;

      case "en":
        partnerManagerRanges = this.props.bp.partnerManagerRanges_en;
        partnerRevenueRanges = this.props.bp.partnerRevenueRanges_en;
        break;

      default:
        partnerManagerRanges = this.props.bp.partnerManagerRanges;
        partnerRevenueRanges = this.props.bp.partnerRevenueRanges;
        break;
    }

    console.log("this.props.bp", this.props.bp);
    return (
      <AppWrapperSimple>
        <SubHeader
          titlePage=""
          itensMenu={menuRegister}
          urlId={this.props.match.params.partnerId}
          activeItem={2}
        />
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Cadastro - A Empresa")}</TitleBorder>
                <p className="descriptionPage">
                  {I18n.get(
                    "Todas as informações solicitadas sobre a sua empresa e o seu trabalho são essenciais para ajudar o nosso algoritmo encontrar jobs e clientes com o seu perfil. Quanto mais precisas elas forem, mais chances de dar match. Beleza?"
                  )}
                </p>
              </Col>
            </Row>

            <FormCompany
              partner={this.props.partner && this.props.partner}
              partnerId={this.props.match.params.partnerId}
              action={this.props.save}
              isNewCompany={this.props.new_company}
              partnerManagerRanges={this.props.bp && partnerManagerRanges}
              partnerRevenueRanges={this.props.bp && partnerRevenueRanges}
              partnerTeamSizeRanges={
                this.props.bp && this.props.bp.partnerTeamSizeRanges
              }
              createTag={this.props.create_tag}
              errors={this.props.partner.errors && this.props.partner.errors}
              diversity={this.props.bp.diversity && this.props.bp.diversity}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
              locale={this.props.partner.signupLocale}
            />

            <Row>
              <Col sm={3}>
                <Link
                  to={`/parceiro/cadastro/usuarios/${this.props.match.params.partnerId}`}
                >
                  <Button shape="round">{I18n.get("Voltar")}</Button>
                </Link>
              </Col>
              <Col sm={18}></Col>
              <Col sm={3}>
                <Link
                  to={`/parceiro/cadastro/especialidades/${this.props.match.params.partnerId}`}
                >
                  <Button type="primary" shape="round">
                    {I18n.get("Avançar")}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Main>
        </ContainerPage>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FullCompany);
