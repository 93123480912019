import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import MultUploadFile from '../../components/MultUploadFile';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TextCounter } from "./counterText";

import {
    Form,
    Button,
    Icon,
    Input,
    Checkbox,
    Row,
    Tooltip,
    InputNumber,
    Col,
    Select,
    Modal
} from 'antd';



const { TextArea, Search } = Input;
const { Option } = Select;

const FieldCustom = styled.div`

`

const BoxAddFild = styled(Search)`
    button {
        position:relative;

        i {
            display: none;
        }
    }
`;

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
      margin-right: 20px;
  }
`;

class FormEditCase extends Component {


    state = {
        videos: [],
        photos: [],
        formState: [],
        listImg: [],
        errosSubmit: false,
        loading: false,
        imgError: false
    }

    removeUrl = (url) => {
        const arr = this.state.videos.filter(e => e !== url);

        this.setState({
            videos: arr
        })
    }

    addUrl = () => {
        const valueInput = this.props.form.getFieldValue('urls');

        if (valueInput) {
            this.setState({
                videos: [
                    ...this.state.videos,
                    valueInput
                ]
            })
        }

        this.props.form.resetFields('urls');
    }

    getDataUploadMult = (files) => {
        this.setState({
            photos: files,
            imgError: false
        });


        setTimeout(() => {
            // this.sendDataApi();
        }, 1);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.setState({
                    formState: values,
                    errosSubmit: true
                })

                const data = {
                    ...values,
                    caseId: this.props.item.caseId,
                    photos: this.state.photos,
                    videos: this.state.videos,
                    type: this.props.type
                }

                if (this.props.isLeader) {
                    data.leaderId = this.props.id;

                    let x = {
                        Case: data,
                        Locale: this.props.locale
                    };

                    setTimeout(() => {
                        this.props.updateCase(x);
                    }, 100);

                } else {
                    data.partnerId = this.props.id;

                    setTimeout(() => {
                        this.props.updateCase(data);
                    }, 100);
                }



            }
        });
    };

    componentWillMount = () => {
        this.props.form.resetFields();

        this.setState({
            loadingImgs: false,
            loading: true
        });
    }

    componentDidMount = () => {
        this.setState({
            loadingImgs: true,
            loading: true
        });
    }

    componentWillMount = () => {
        this.setState({
            loadingImgs: true,
            errosSubmit: false
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors && this.state.errosSubmit) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        }

        if (this.state.photos != prevProps.photos && this.state.photos.length > 0 && !this.state.imgError) {
            this.props.form.setFields({
                photos: {
                    errors: "",
                },
            });

            this.setState({
                imgError: true
            });
        }

        if (this.props.item != prevProps.item && this.props.item) {

            let listImg = this.props.item && this.props.item.photos && this.props.item.photos.map((item, index) => {
                return {
                    uid: index + this.props.scape,
                    name: 'image.png',
                    status: 'done',
                    url: item
                }
            });

            this.setState({
                listImg: listImg,
                photos: this.props.item.photos || listImg,
                videos: this.props.item.videos
            });
        }

        if (this.state.loading != prevState.loading) {
            let listImg = this.props.item && this.props.item.photos && this.props.item.photos.map((item, index) => {
                return {
                    uid: index + this.props.scape,
                    name: 'image.png',
                    status: 'done',
                    url: item
                }
            });

            this.setState({
                listImg: listImg,
                photos: this.props.item.photos || listImg,
                videos: this.props.item.videos
            });
        }

    }

    

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const specialismValues = this.props.specialisms && this.props.specialisms.map((item) => {

            const specialism = this.props.specialismsBP && this.props.specialismsBP.find((s) => s.code == item) || {};

            return specialism;

        });

        const ListCategorie = this.props.sectorsFlat && this.props.sectorsFlat.sort((a, b) => (a.label > b.label) - (a.label < b.label));

        let specialismList = getFieldValue("specialism") || [];

        let specialismCheck = specialismList.indexOf("TRE_COACH" || "STU_PHOTO") == -1

        const linkEsp = this.props.isFull ? `/parceiro/cadastro/especialidades/${this.props.id}` : `/parceiro/pre-cadastro/especialidades/${this.props.id}`

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={24}>
                        <Form.Item
                            label={I18n.get("Título do Case")}>
                            {getFieldDecorator('title', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.title ? this.props.item.title : null
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                {(!this.props.isLeader && this.props.type != 2) &&
                    <Row>
                        <Col sm={24}>
                            <Form.Item label={I18n.get("Especialidade")}>
                                {getFieldDecorator('specialism', {
                                    rules: [{ required: false, message: 'ops!' }],
                                    initialValue: this.props.item && this.props.item.specialism ? this.props.item.specialism : null
                                })(
                                    <Checkbox.Group>
                                        {specialismValues && specialismValues.map((i) => {

                                            return <Col sm={8}><Checkbox value={i.code}>{i.label}</Checkbox></Col>
                                        })}
                                    </Checkbox.Group>
                                )}
                                {specialismValues && specialismValues.length == 0 &&
                                    <>
                                        <br/><p style={{ marginTop: "-40px", color: "#e20000" }}><strong style={{ color: "#e20000" }}>{I18n.get("Favor preencher as")} <Link style={{ color: "#e20000", textDecoration: "underline" }} to={linkEsp}>{I18n.get("especialidades")}</Link>{I18n.get("primeiro.")}</strong></p>
                                    </>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                }

                <Row>
                    <Col sm={10}>
                        <Form.Item label={I18n.get("Marca")}>
                            {getFieldDecorator('brand', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.brand ? this.props.item.brand : null
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={14}>
                        <Form.Item label={I18n.get("Categoria")}>
                            {getFieldDecorator('category', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.category ? this.props.item.category : null
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {ListCategorie && ListCategorie.map((item) => {
                                        return <Option value={item.code} key={item.code}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={10}>
                        <Form.Item label={I18n.get("Produto")}>
                            {getFieldDecorator('product', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.product ? this.props.item.product : null
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={6}>
                        <Form.Item label={I18n.get("Ano")}>
                            {getFieldDecorator('year', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.year ? this.props.item.year : null
                            })(
                                <InputNumber />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <Form.Item label={I18n.get("Desafio")} className="textAreaCounter">
                            {getFieldDecorator('challenge', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.challenge ? this.props.item.challenge : ""
                            })(
                                <TextArea rows={4} maxLength={specialismCheck ? 250 : 10000} />
                            )}
                        </Form.Item>

                        {specialismCheck &&
                            <TextCounter
                                counter={getFieldValue('challenge') && getFieldValue('challenge').length}
                                limit={250}
                                min={100}
                            />
                        }

                    </Col>
                    <Col sm={8}>
                        <Form.Item label={I18n.get("Solução")} className="textAreaCounter">
                            {getFieldDecorator('solution', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.solution ? this.props.item.solution : ""
                            })(
                                <TextArea rows={4} maxLength={specialismCheck ? 250 : 10000} />
                            )}
                        </Form.Item>
                        {specialismCheck &&
                            <TextCounter
                                counter={getFieldValue('solution') && getFieldValue('solution').length}
                                limit={250}
                                min={100}
                            />
                        }
                    </Col>
                    <Col sm={8}>
                        <Form.Item label={I18n.get("Resultado")} className="textAreaCounter">
                            {getFieldDecorator('result', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: this.props.item && this.props.item.result ? this.props.item.result : ""
                            })(
                                <TextArea rows={4} maxLength={specialismCheck ? 250 : 10000} />
                            )}
                        </Form.Item>
                        {specialismCheck &&
                            <TextCounter
                                counter={getFieldValue('result') && getFieldValue('result').length}
                                limit={250}
                                min={100}
                            />
                        }
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <Form.Item label={I18n.get("Imagens")}>
                            {getFieldDecorator('photos', {
                                rules: [{ required: false, message: 'ops!' }],
                                // initialValue: this.props.item && this.props.item.specialism ? this.props.item.specialism : null
                            })(
                                <MultUploadFile
                                    getDataUpload={this.getDataUploadMult}
                                    name="Imagens"
                                    uploadId={this.props.id}
                                    actionUpload={this.props.upload}
                                    // fileListGet={this.state.listImg}
                                    fileListGet={this.state.listImg || []}
                                    isEdit={true}
                                    get2={this.props.get2 || false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={12}>

                        <Form.Item label={I18n.get("Vídeos")}>
                            {getFieldDecorator('urls', {
                                rules: [{ required: false, message: 'ops!' }],
                            })(
                                <BoxAddFild
                                    className="InputPlus"
                                    placeholder=""
                                    onSearch={() => this.addUrl()}
                                    enterButton
                                />
                            )}
                            <p className="helptext">{I18n.get("(Youtube, Vimeo ou Dropbox)")}</p>
                        </Form.Item>

                        <ul className="ListDinamic">
                            {this.state.videos &&
                                this.state.videos.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={item} target="_blank">
                                                <Tooltip title={item}><Icon type="video-camera" /> <span className="txt">{item}</span></Tooltip>
                                            </a>
                                            <Icon type="delete" onClick={() => this.removeUrl(item)} theme="filled" className="iconList" />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>

                <RowSubmitModal>
                    <Col sm={24}>
                        <Button shape="round" onClick={this.props.onCancel}>{I18n.get("Cancelar")}</Button>
                        <Button type="primary" htmlType="submit" shape="round" onClick={this.handleSubmit}>{I18n.get("Atualizar")}</Button>
                    </Col>
                </RowSubmitModal>

            </Form>
        );
    }
}

const MainEditCase = Form.create({})(FormEditCase);

export default MainEditCase;
