import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ContentModalPreview = styled.div``;

export const HeaderModalPreview = styled.div``;

export const Label = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #ed0404;
`;

export const ImgHeader = styled.div`
  border-radius: 10px;

  img {
    border-radius: 10px;
  }
`;

export const Logo = styled.div`
  position: relative;
  top: -40px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
`;

export const LogoImage = styled.div`
  width: 97px;
  height: 97px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 25%);

  img {
    border: 1px solid #000000;
    border-radius: 50%;
  }
`;

export const TitleSection = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  position: relative;
  height: 60px;
  margin-bottom: 15px;

  :after {
    content: '';
    width: 63px;
    height: 6px;
    background: #000000;
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

export const BgModalPreview = styled.div``;

export const ImgPresentation = styled.div`
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

export const VideoContent = styled.div`
  width: 640px;
  margin: 0 auto;
`;
