import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const TitleContent = styled.div`
  padding: 0 15px;
  margin: 0 0 30px 0;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const AlertPage = styled.div`
  margin: 0 15px 30px 15px;
`;

export const Content = styled.div`
  padding-bottom: 90px;
`;

export const SelectTypeAddSupplier = styled.div`
  .ant-radio-inner::after {
    top: 4px;
    left: 4px;
  }

  .ant-radio-inner {
    border-color: #000;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
  }
`;

export const DownloadSpreadsheet = styled.div`
  margin-top: 30px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
  }
`;

export const DownloadSpreadsheetInfos = styled.div`
  border-top: 1px solid #b5b5b5;
  padding: 20px 0;
  display: grid;
  gap: 15px;
  grid-template-columns: calc(60% - 5px) calc(40% - 10px);

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
  }
`;

export const DownloadSpreadsheetUpload = styled.div`
  margin-top: 15px;
`;

export const FormManual = styled.div`
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  margin-top: 20px;
`;

export const FormManualRow = styled.div`
  display: grid;
  column-gap: 15px;
  /* row-gap: 15px; */
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
`;

export const FormManualCol = styled.div``;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-upload {
    height: 166px;
    margin-top: 0 !important;

    li {
      width: 100% !important;
      height: 100%;
      margin-top: 0 !important;
    }
  }
`;
