import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PROJECT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_delete_quotation_item = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.delete(`${Config.API_BASE_URL}/client-project/${data.projectId}/event-quotations/${data.partnerId}/items/${data.id}`, config)
                .then(response => {

                    dispatch({
                        type: PROJECT_UPDATE,
                        payload: {
                            ...response.data,
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    });

                })
                .catch(error => {

                    dispatch({
                        type: PROJECT_UPDATE,
                        payload: {
                            errors: error.response.data,
                            task: {
                                result: false
                            }
                        }
                    });
                });

    }).catch(error => { 
        dispatch({ type: EXPIRED }); 
        }); 
    };
}

export default api_delete_quotation_item;