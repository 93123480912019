import React from "react";
import { Icon } from "antd";
import { I18n } from "aws-amplify";
import * as S from "./styles";

export const DownloadNF = ({ file, title }) => {
  return (
    <>
      {file?.url ? (
        <S.Wrapper>
          <S.TitleDownloadNF>
            <strong>{`${title || I18n.get("Arquivo")}:`}</strong>
          </S.TitleDownloadNF>
          <S.File>
            <p>{file?.name}</p>
            <a href={file?.url}>
              <Icon type="download" />
            </a>
          </S.File>
        </S.Wrapper>
      ) : null}
    </>
  );
};
