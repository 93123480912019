import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 10px;
  border-radius: 10px;
  background: #fafafa;

  .demo-wrapper {
    .rdw-editor-toolbar {
      margin-bottom: 0px;
      border: none;
      padding: 15px 15px 0 15px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    .public-DraftEditor-content {
      background: #ffffff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-top: 5px;
    }
  }
`;

export const Title = styled.div`
  padding: 15px;

  p {
    font-size: 18px;
    line-height: 25px;
  }
`;
