import styled from "styled-components";

const COLOR_ACTIVE = "#1883FF";
const COLOR_INACTIVE = "#2f2f2f";

export const Wrapper = styled.div`
  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;

    li {
      width: max-content;
      margin: 0 30px;

      a {
        cursor: pointer;
        p {
          cursor: pointer;
          color: ${COLOR_INACTIVE};
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    li.active {
      a {
        pointer-events: none;
        cursor: default;
        p {
          cursor: pointer;
          font-weight: 700;
        }
      }
    }

    li.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
`;

export const LinkContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 10px 0 0 0;
  }
`;
