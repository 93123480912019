import React from 'react';

import { I18n } from 'aws-amplify';

class IconButton extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    static defaultProps = {
        className: 'card-svg-icon no-webkit',
        style: {}
    }

    render() {

        const style = {
            ...this.props.style
        };

        const is_busy = this.props.task && this.props.task.busy;

        const className = is_busy ? [this.props.className, "is-loading"].join(' ') : this.props.className;

        const buttonContent = this.props.svgIcon ?
            (<><img src={this.props.svgIcon} alt={this.props.alt} /></>) :
            (<><i className={this.props.icon} alt={this.props.alt} /></>)

        return (
            <button onClick={this.props.onClick} type={this.props.type ? this.props.type : 'button'} className={className} style={style}>
                {buttonContent}
            </button>
        );
    }
}

export default IconButton;