import { Link } from "react-router-dom";
import styled from "styled-components";

import { Steps } from "antd";

const { Step } = Steps;

const handleDisabled = (disabled) => {
  if (disabled) {
    return `
    background: #ccc;
    `;
  }
};

export const StyleSteps = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  &::before {
    width: 90%;
    height: 2px;
    background: #000;
    position: absolute;
    top: 9px;
    content: "";
    display: block;
    left: 50%;
    margin-left: -45%;
  }
`;

export const StyleStep = styled(Link)`
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &[disabled] {
    pointer-events: painted;
    cursor: not-allowed;
  }
`;

export const StyleNumber = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.active ? "18px" : "12px")};
  line-height: 16px;
  display: block;
  margin: 0 auto;
  color: #ffffff;
  width: ${(props) => (props.active ? "40px" : "19px")};
  height: ${(props) => (props.active ? "40px" : "19px")};
  border-radius: 50%;
  background: ${(props) => (props.active ? "#1883FF" : "#000")};
  ${({ disabled }) => handleDisabled(disabled)};
  text-align: center;
  box-sizing: border-box;
  padding-top: ${(props) => (props.active ? "14px" : "2px")};
  position: relative;
  top: ${(props) => (props.active ? "-10px" : "0")};
`;

export const StyleLabel = styled.span`
  font-style: normal;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
`;
