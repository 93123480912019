import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import Checkbox from '../../../../components/UI/Checkbox';

import * as S from './styles';

export const ItemCaseOrAwardsRowChecks = ({
  id,
  checkDefault,
  onChangeCheck,
}) => {
  const [checkbox, setCheckbox] = useState(checkDefault ? 'yes' : 'no');

  const onChangeCheckbox = (e, id) => {
    if (id === 'yes') {
      setCheckbox('yes');
    } else {
      setCheckbox('no');
    }
  };

  useEffect(() => {
    onChangeCheck({ id, checkbox });
  }, [checkbox]);

  return (
    <>
      <S.CheckboxItem>
        <Checkbox
          id="yes"
          onChangeCb={(e, id) => onChangeCheckbox(e, 'yes')}
          checked={checkbox === 'yes' ? true : false}
          checkboxSize={16}
        />
        <p>{I18n.get('Yes')}</p>
      </S.CheckboxItem>
      <S.CheckboxItem>
        <Checkbox
          id="no"
          onChangeCb={(e, id) => onChangeCheckbox(e, 'no')}
          checked={checkbox === 'no' ? true : false}
          checkboxSize={16}
        />
        <p>{I18n.get('No')}</p>
      </S.CheckboxItem>
    </>
  );
};
