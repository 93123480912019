import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";

import { PREVIEW_API } from "../../actions/preview/actions";

import HeaderThumbCover from "../../modules/components/HeaderThumbCover";
import ContainerPage from "../../modules/components/ContainerPage";
import PartnerInfos from '../../modules/components/PartnerInfos';
import Loading from '../pages/loading';

import LandingPage from '../page-templates/landingForLogin';

import PartnerCard from "../partner/shared/_partner-card";


import styled from "styled-components";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_project: (data) => {
      return dispatch(PREVIEW_API.get_project(data));
    },
  };
};

const Main = styled.div`
  padding-bottom: 100px;
  max-width: 1000px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  color: #353535;
`;
class ProjectPreviewPage extends React.Component {
  constructor(props) {
    super();

    this.state = {
      partner: null,
      task: {},
      error: null
    };
  }

  componentDidMount() {

    const data = {
      projectId: this.props.match.params.projectId
    };

    this.props.get_project(data).then(({ projectName, partners, diversity, sectorsFlat, specialisms, task, error }) => {

      this.setState({ projectName, partners, diversity, sectorsFlat, specialisms, task, error });
    });
  }

  showDetails(item, e) {

    this.setState({ selected: item });
  }

  hideDetails(e) {

    e.preventDefault();

    this.setState({ selected: null });
  }

  render() {

    const { projectName, selected } = this.state;
    const partners = this.state.partners || {};

    const diversity = this.state.diversity || [];
    const specialisms = this.state.specialisms || [];
    const sectorsFlat = this.state.sectorsFlat || [];

    if (!projectName) {
      return (<Loading />);
    }

    return (
      <LandingPage>

        <div>

          {!selected &&

            <ContainerPage>
              <Main style={{ padding: '0' }}>

                <h1>{projectName}</h1>

                <br />

                <div className="columns is-multiline">
                  {partners && partners.map((partner) => {

                    return (

                      <div key={partner.id} className="column is-one-third">
                        <PartnerCard
                          {...partner}
                          isPreview={true}
                          bp={this.props.bp}
                          isMiniThumb={true}
                          isChat={false}
                          noSite={true}
                          isListPartner={true}
                          showDetails={this.showDetails.bind(this, partner)}
                        />

                      </div>
                    );

                  })}
                </div>


              </Main>
            </ContainerPage>

          }

          {selected && <>


            <button onClick={this.hideDetails.bind(this)} className='button bt-bpool grey previous'>{I18n.get("< Voltar")}</button> &nbsp;&nbsp;

            <HeaderThumbCover
              thumb={selected.company && selected.company.logoImage}
              bgHeader={selected.company && selected.company.headerImage}
              title=""
              linkText={I18n.get("Voltar")}
              action={this.closePartner}
            />

            <ContainerPage>
              <Main>
                <PartnerInfos
                  infos={selected}
                  specialismsBP={specialisms}
                  sectorsFlat={sectorsFlat}
                  isPresentation={true}
                  diversity={diversity}
                  link="/parceiro/preview"
                />

              </Main>
            </ContainerPage>

          </>}

        </div>
      </LandingPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPreviewPage);
