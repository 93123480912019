import React from 'react';
import * as S from './styles';

const Button = ({text, href, icon, isDisabled, padding, target}) => { 
    return(
        <S.StyledButton 
            backgroundColor={isDisabled ? "transparent" : "#1883FF"} 
            href={href}
            iconBrightness={isDisabled ? "brightness(85%)" : "brightness(100%)" }
            color={isDisabled ? "#CCCCCC" : "#FFF"}
            borderColor={isDisabled ? "#CCCCCC" : "transparent"}
            padding={padding ? padding : "14px 65px"}
            target={target}
        > 
            {icon && <img src={icon} alt={`ícone de ${text}`}/>}
            <span>{text}</span>
        </S.StyledButton> 
    )
};

export default Button;