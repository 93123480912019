import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PROJECT_GET_PRODUCTION } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_project_production = (data) => {


    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };
        
        //api/client-project/{projectId}/production
        return axios.get(`${Config.API_BASE_URL}/client-project/${data.projectId}/production`, config)
            .then(response => {
                dispatch({
                    type: PROJECT_GET_PRODUCTION,
                    payload: {
                        ...response.data,
                        errors: {},
                        task: {
                            result: true
                        }
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: PROJECT_GET_PRODUCTION,
                    payload: {
                        errors: {},
                        task: {
                            result: false
                        }
                    }
                })
            });
}).catch(error => { 
 dispatch({ type: EXPIRED }); 
 }); 
 };
}

export default api_get_project_production;