import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import FillForm from '../page-templates/fillForm';

import ResponsibleForm from './responsible-form';

import Steps from './steps';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class LeaderSignupStep1 extends React.Component {
    constructor(props) {

        super();
        this.state = {
        };
    }

    scrollToTop() {
        const scrollDuration = 600;
        const scrollHeight = window.scrollY;
        const scrollStep = Math.PI / (scrollDuration / 15);
        const cosParameter = scrollHeight / 2;
        let scrollCount = 0;
        let scrollMargin;
        let scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                scrollCount = scrollCount + 1;  
                scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                window.scrollTo(0, (scrollHeight - scrollMargin));
            } else {
                clearInterval(scrollInterval); 
            }
        }, 15);
    }
    
    render() {

        return (
            <FillForm>
                <div className="hero-body has-background-white">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-4">
                                <span className="title-box">{I18n.get("Líderes")}</span>
                            </div>
                            <div className="column is-8">
                                <Steps active={1}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="leader-step leader-step-1">

                    <div className="container">

                        <div className="column is-4 is-offset-4">

                            <h1 className="title">
                                <span>1.</span>{I18n.get("Sobre Você")}</h1>

                            <ResponsibleForm {...this.props} />
                                     
                            <br />

                            <div className="field is-grouped is-grouped-centered">
                                <p className="control is-expanded"  onClick={this.scrollToTop} >
                                    <Link to="/lider/passo-2" className="button next is-black is-fullwidth">{I18n.get("PRÓXIMO [ 2 de 3 ]")}</Link>
                                </p>
                            </div>
                        </div>
                    </div>
            </div>


         </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderSignupStep1);
