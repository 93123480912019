import axios from 'axios';
import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { Task } from '../../utils/task';

import constants from '../../constants';

import { CLIENT_API } from '../../actions/client/actions';

import TextField from '../form/text-field';
import TextAreaField from '../form/textarea-field';
import SelectField from '../form/select-field';
import MaskedField from '../form/masked-field';
import RadioField from '../form/radio-field';
import CheckboxField from '../form/checkbox-field';
import CheckboxSwitchField from '../form/checkbox-switch-field';
import SubmitButtonField from '../form/submit-button-field';

//import TooltipField from '../form/tooltip-field';
//import bulmaCalendar from 'bulma-calendar';

import TooltipField from '../form/tooltip-field';
import PhotosForm from '../photos-form';

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp, company: {} }, state.client);
};

const mapDispatchToProps = dispatch => {
    return {
        upload: data => {
            return CLIENT_API.upload(data);
        },
        save: (data) => {
            dispatch(CLIENT_API.update_company(data));
        },
    };
};

class CompanyForm extends React.Component {
    constructor(props) {

        super();

        const company = props.company || {};

        this.state = {
            form: {
                clientId: props.clientId,
                photos: company.photos || [],
                posters: company.posters || [],
                name: {
                    label: I18n.get("Nome fantasia"),
                    value: company.name,
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'name'),
                },
                registrationName: {
                    label: I18n.get("Razão social"),
                    value: company.registrationName,
                    onChange: this.onChangeForm.bind(this, 'registrationName'),
                    error: props.errors.registrationName,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'registrationName'),
                },
                registrationId: {
                    label: I18n.get("CNPJ"),
                    value: company.registrationId,
                    mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
                    onChange: this.onChangeForm.bind(this, 'registrationId'),
                    error: props.errors.registrationId,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'registrationId'),
                    readOnly: props.isAdmin,
                    size: 50,
                },
                postcode: {
                    label: I18n.get("CEP"),
                    value: company.postcode,
                    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/,],
                    onChange: this.onChangeForm.bind(this, 'postcode'),
                    error: props.errors.postcode,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'postcode'),
                },
                addressLine1: {
                    label: I18n.get("Endereço do CNPJ"),
                    value: company.addressLine1,
                    onChange: this.onChangeForm.bind(this, 'addressLine1'),
                    error: props.errors.addressLine1,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'addressLine1'),
                },
                addressLine2: {
                    label: I18n.get("Complemento"),
                    value: company.addressLine2,
                    onChange: this.onChangeForm.bind(this, 'addressLine2'),
                    error: props.errors.addressLine2,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'addressLine2'),
                },
                city: {
                    label: I18n.get("Cidade"),
                    value: company.city,
                    onChange: this.onChangeForm.bind(this, 'city'),
                    error: props.errors.city,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'city'),
                },
                state: {
                    label: I18n.get("Estado"),
                    value: company.state,
                    values: constants.STATES,
                    onChange: this.onChangeForm.bind(this, 'state'),
                    error: props.errors.state,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'state'),
                },
                phoneNumber: {
                    label: I18n.get("Telefone"),
                    value: company.phoneNumber,
                    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                    onChange: this.onChangeForm.bind(this, 'phoneNumber'),
                    error: props.errors.phoneNumber,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'phoneNumber'),
                },
                website: {
                    label: I18n.get("Website"),
                    value: company.website,
                    onChange: this.onChangeForm.bind(this, 'website'),
                    error: props.errors.website,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'website'),
                },
                linkedin: {
                    label: I18n.get("Linkedin Corporativo"),
                    placeholder: I18n.get("https://"),
                    value: props.linkedin,
                    onChange: this.onChangeForm.bind(this, 'linkedin'),
                    error: props.errors.linkedin,
                    visited: true
                },
                copyAddressToCommercialAddress: {
                    label: I18n.get("O mesmo da empresa"),
                    value: false,
                    checked: company.copyAddressToCommercialAddress,
                    onChange: this.onChangeFormCheckbox.bind(this, 'copyAddressToCommercialAddress'),
                    error: props.errors.copyAddressToCommercialAddress,
                    successIcon: false,
                    //onBlur: this.onSave.bind(this, 'copyAddressToCommercialAddress'),
                    onClick: this.onSave.bind(this, 'copyAddressToCommercialAddress'),
                },
                commercialPostcode: {
                    label: I18n.get("CEP"),
                    value: company.commercialPostcode,
                    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/,],
                    onChange: this.onChangeForm.bind(this, 'commercialPostcode'),
                    error: props.errors.commercialPostcode,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'commercialPostcode'),
                },
                commercialAddressLine1: {
                    label: I18n.get("Endereço"),
                    value: company.commercialAddressLine1,
                    onChange: this.onChangeForm.bind(this, 'commercialAddressLine1'),
                    error: props.errors.commercialAddressLine1,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'commercialAddressLine1'),
                },
                commercialAddressLine2: {
                    label: I18n.get("Complemento"),
                    value: company.commercialAddressLine2,
                    onChange: this.onChangeForm.bind(this, 'commercialAddressLine2'),
                    error: props.errors.commercialAddressLine2,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'commercialAddressLine2'),
                },
                commercialCity: {
                    label: I18n.get("Cidade"),
                    value: company.commercialCity,
                    onChange: this.onChangeForm.bind(this, 'commercialCity'),
                    error: props.errors.commercialCity,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'commercialCity'),
                },
                commercialState: {
                    label: I18n.get("Estado"),
                    value: company.commercialState,
                    values: constants.STATES,
                    onChange: this.onChangeForm.bind(this, 'commercialState'),
                    error: props.errors.commercialState,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'commercialState'),
                },
                commercialPhoneNumber: {
                    label: I18n.get("Telefone"),
                    value: company.commercialPhoneNumber,
                    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                    onChange: this.onChangeForm.bind(this, 'commercialPhoneNumber'),
                    error: props.errors.commercialPhoneNumber,
                    successIcon: true,
                    onBlur: this.onSave.bind(this, 'commercialPhoneNumber'),
                },
                revenueRange: {
                    label: I18n.get("Faturamento anual"),
                    value: company.revenueRange,
                    onChange: this.onChangeForm.bind(this, 'revenueRange'),
                    error: props.errors.revenueRange,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'revenueRange'),
                },
                employeeRange: {
                    label: I18n.get("Funcionários"),
                    value: company.employeeRange,
                    onChange: this.onChangeForm.bind(this, 'employeeRange'),
                    error: props.errors.employeeRange,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'employeeRange'),
                },
                paymentDefaultTermDays: {
                    label: I18n.get("Prazo Padrão de Pagamento"),
                    value: company.paymentDefaultTermDays,
                    onChange: this.onChangeForm.bind(this, 'paymentDefaultTermDays'),
                    error: props.errors.paymentDefaultTermDays,
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'paymentDefaultTermDays'),
                },
                isNational: {
                    label: I18n.get("Nacional"),
                    value: company.isNational,
                    checked: company.isNational,
                    onChange: this.onChangeFormRadio.bind(this, 'isNational'),
                    error: props.errors.isNational,
                    successIcon: false,
                    //onBlur: this.onSave.bind(this, 'isNational'),
                    onClick: this.onSave.bind(this, 'isNational'),
                },
                isMultinational: {
                    label: I18n.get("Multinacional"),
                    value: company.isMultinational,
                    checked: company.isMultinational,
                    onChange: this.onChangeFormRadio.bind(this, 'isMultinational'),
                    error: props.errors.isMultinational,
                    successIcon: false,
                    //onBlur: this.onSave.bind(this, 'isMultinational'),
                    onClick: this.onSave.bind(this, 'isMultinational'),
                },
                isPublic: {
                    label: I18n.get("Capital Aberto"),
                    value: company.isPublic,
                    checked: company.isPublic,
                    onChange: this.onChangeFormRadio.bind(this, 'isPublic'),
                    error: props.errors.isPublic,
                    successIcon: false,
                    //onBlur: this.onSave.bind(this, 'isPublic'),
                    onClick: this.onSave.bind(this, 'isPublic'),
                },
                isPrivate: {
                    label: I18n.get("Capital Fechado"),
                    value: company.isPrivate,
                    checked: company.isPrivate,
                    onChange: this.onChangeFormRadio.bind(this, 'isPrivate'),
                    error: props.errors.isPrivate,
                    successIcon: false,
                    //onBlur: this.onSave.bind(this, 'isPrivate'),
                    onClick: this.onSave.bind(this, 'isPrivate'),
                },
                evaluatePartner: {
                    id: 'evaluatePartner',
                    label: '',
                    value: company.evaluatePartner,
                    checked: company.evaluatePartner,
                    onChange: this.onChangeFormCheckbox.bind(this, 'evaluatePartner'),
                    error: props.errors.evaluatePartner,
                    successIcon: false,
                    //onBlur: this.onSave.bind(this, 'evaluatePartner'),
                    onClick: this.onSave.bind(this, 'evaluatePartner'),
                },
                uploading: false,
                isSubmit: false,
            },
            task: {},
        }

        this.submitForm = this.submitForm.bind(this);

        this.delPhotoLinkClick = this.onDelPhotoLinkClick.bind(this, 'photos');
        this.addPhotoUploadClick = this.onAddPhotoUploadClick.bind(this, 'photos');

        this.delPosterImageClick = this.onDelPhotoLinkClick.bind(this, 'posters');
        this.addPosterImageClick = this.onAddPhotoUploadClick.bind(this, 'posters');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                if (typeof nextState.form[item] === "object") {
                    nextState.form[item].error = this.props.errors[item];
                }

            });

            this.setState(nextState);
        }
    }

    onPostcodeSearchClick(e) {

        const value = this.state.form.postcode.value;

        if (value && value.length > 0) {
            const pattern = /^[0-9]{5}-[0-9]{3}$/;

            const code = value.replace("-", "");

            return axios.get(`https://viacep.com.br/ws/${code}/json/`)
                .then(response => {

                    this.setState((prevState) => {

                        var nextState = Object.assign({}, prevState);

                        if (response.data.logradouro) {
                            nextState.form.addressLine1.value = response.data.logradouro;
                        }

                        if (response.data.localidade) {
                            nextState.form.city.value = response.data.localidade;
                        }

                        if (response.data.uf) {
                            nextState.form.state.value = response.data.uf;
                        }

                        return nextState;
                    });
                })
                .catch(error => {
                    throw (error);
                });

        }
    }

    onCommercialPostcodeSearchClick(e) {

        const value = this.state.form.commercialPostcode.value;

        if (value && value.length > 0) {
            const pattern = /^[0-9]{5}-[0-9]{3}$/;

            const code = value.replace("-", "");

            return axios.get(`https://viacep.com.br/ws/${code}/json/`)
                .then(response => {

                    this.setState((prevState) => {

                        var nextState = Object.assign({}, prevState);

                        if (response.data.logradouro) {
                            nextState.form.commercialAddressLine1.value = response.data.logradouro;
                        }

                        if (response.data.localidade) {
                            nextState.form.commercialCity.value = response.data.localidade;
                        }

                        if (response.data.uf) {
                            nextState.form.commercialState.value = response.data.uf;
                        }

                        return nextState;
                    });
                })
                .catch(error => {
                    throw (error);
                });
        }
    }



    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onChangeFormCheckbox(prop, e) {

        const checked = e.target.checked;



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].checked = checked;
            nextState.form[prop].value = checked;

            return nextState;
        });
    }

    // simula o compartamento do radio
    onChangeFormRadio(prop, e) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].checked = true;
            nextState.form[prop].value = true;

            if (prop == 'isNational') {

                nextState.form['isMultinational'].checked = false;
                nextState.form['isMultinational'].value = false;
            }

            if (prop == 'isMultinational') {

                nextState.form['isNational'].checked = false;
                nextState.form['isNational'].value = false;
            }

            if (prop == 'isPublic') {

                nextState.form['isPrivate'].checked = false;
                nextState.form['isPrivate'].value = false;
            }

            if (prop == 'isPrivate') {

                nextState.form['isPublic'].checked = false;
                nextState.form['isPublic'].value = false;
            }

            return nextState;
        });
    }

    getData() {

        var data = {};

        Object.keys(this.state.form).forEach((item) => {

            if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
                data[item] = this.state.form[item].value;
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    onSave(item) {



        if (this.props.isAdmin) return;

        // zera o erro
        if (item && typeof this.state.form[item] === "object") {
            this.state.form[item].error = null;
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    onAddPhotoUploadClick(img_type, file, e) {

        if (!file)
            return;

        if (e)
            e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            this.setState({ uploading: true });

            const item = {
                clientId: this.props.clientId,
                token: this.props.auth.jwtToken,
                img_type: img_type,
                img_base64: e.target.result
            };

            this.props.upload(item)
                .then(response => {

                    this.setState((prevState) => {

                        var nextState = Object.assign({}, prevState);

                        nextState.form[img_type] = [...this.state.form[img_type], response.data.url];
                        nextState.uploading = false;

                        return nextState;
                    });

                })
                .catch(error => {

                    this.setState({ uploading: false });

                });

        };
        reader.readAsDataURL(file);
    }


    onDelPhotoLinkClick(img_type, index, e) {

        if (e)
            e.preventDefault();

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[img_type] = this.state.form[img_type].filter((item, i) => i != index);

            return nextState;
        });

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });

    }

    render() {

        return (
            <form onSubmit={this.submitForm} className="form-mobile">

                <TextField {...this.state.form.name} />

                <TextField {...this.state.form.registrationName} />

                <MaskedField {...this.state.form.registrationId} />

                {/* <MaskedField {...this.state.form.registrationDate} /> */}

                <div className="field is-horizontal">
                    <div className="field-body">

                        <div className="inputsize-zipcode">
                            <MaskedField {...this.state.form.postcode} />
                        </div>

                        <div className="field">
                            <label className="label is-small">
                                &nbsp;
                            </label>
                            <p className="control is-expanded">

                                <button
                                    type="button"
                                    onClick={this.onPostcodeSearchClick.bind(this)}
                                    className="button is-leaked">{I18n.get("BUSCAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                                {/* <button onClick={this.onPostcodeSearchClick.bind(this)}
                                        type="button" className="button is-black is-rounded">
                                    BUSCAR
                                </button> */}

                            </p>

                        </div>

                    </div>
                </div>

                <TextField {...this.state.form.addressLine1} />

                <TextField {...this.state.form.addressLine2} />

                <div className="field is-horizontal">
                    <div className="field-body">

                        <TextField {...this.state.form.city} />

                        <SelectField {...this.state.form.state} />

                    </div>
                </div>

                <MaskedField {...this.state.form.phoneNumber} />

                <TextField {...this.state.form.website} />

                <TextField {...this.state.form.linkedin} />

                <h1 className="title-bpool medium">{I18n.get("Endereço comercial")}</h1>
                <p>{I18n.get("Indicar endereço de onde estará o seu time, ou a maior parte do time")}</p>
                <br />
                <CheckboxField {...this.state.form.copyAddressToCommercialAddress} />

                {!this.state.form.copyAddressToCommercialAddress.checked && <div className="field is-horizontal">
                    <div className="field-body">

                        <div className="inputsize-zipcode">
                            <MaskedField {...this.state.form.commercialPostcode} />
                        </div>

                        <div className="field">
                            <label className="label is-small">
                                &nbsp;
                            </label>
                            <p className="control is-expanded">

                                <button
                                    type="button"
                                    onClick={this.onCommercialPostcodeSearchClick.bind(this)}
                                    className="button is-leaked">{I18n.get("BUSCAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                                {/* <button onClick={this.onCommercialPostcodeSearchClick.bind(this)}
                                    type="button" className="button is-black is-rounded">
                                    BUSCAR
                                </button> */}
                            </p>
                        </div>

                    </div>
                </div>}

                {!this.state.form.copyAddressToCommercialAddress.checked && <TextField {...this.state.form.commercialAddressLine1} />}

                {!this.state.form.copyAddressToCommercialAddress.checked && <TextField {...this.state.form.commercialAddressLine2} />}

                {!this.state.form.copyAddressToCommercialAddress.checked && <div className="field is-horizontal">
                    <div className="field-body">


                        <TextField {...this.state.form.commercialCity} />

                        <SelectField {...this.state.form.commercialState} />

                    </div>
                </div>}

                {!this.state.form.copyAddressToCommercialAddress.checked && <MaskedField {...this.state.form.commercialPhoneNumber} />}

                <h1 className="title-bpool medium">{I18n.get("Alguns Números")}</h1>

                <SelectField {...this.state.form.revenueRange} values={this.props.bp && this.props.client.signupLocale === "es" ? this.props.bp.clientRevenueRanges_es : this.props.bp.clientRevenueRanges} />

                <SelectField {...this.state.form.employeeRange} values={this.props.bp.clientEmployeesRanges} />

                <SelectField {...this.state.form.paymentDefaultTermDays} values={this.props.bp.clientPaymentDefaultTermDays} />




                <h1 className="title-bpool medium">{I18n.get("Sua empresa é:")}</h1>

                <div className="columns">

                    <div className="column is-5">
                        <CheckboxField {...this.state.form.isMultinational} />
                    </div>

                    <div className="column is-1 orSpace">
                        ou
                    </div>

                    <div className="column is-6">
                        <CheckboxField {...this.state.form.isNational} />
                    </div>

                </div>

                <h1 className="title-bpool medium">{I18n.get("Sua empresa é:")}</h1>

                <div className="columns">

                    <div className="column is-5">
                        <CheckboxField {...this.state.form.isPublic} />
                    </div>

                    <div className="column is-1 orSpace">
                        ou
                    </div>

                    <div className="column is-6">
                        <CheckboxField {...this.state.form.isPrivate} />
                    </div>

                </div>

                <h1 className="title-bpool medium">{I18n.get("Logo")}</h1>

                <p style={{ marginTop: '1rem', marginBottom: '2rem' }}>{I18n.get("Enviar somente uma imagem do tipo JPG ou PNG.")}<br />
                </p>

                <PhotosForm {...this.props}
                    allowMultipleSelect={false}
                    allowOnlyImages={true}
                    limitToUpload={1}
                    photos={this.state.form.photos}
                    uploading={this.state.uploading}
                    addUpload={this.addPhotoUploadClick}
                    del={this.delPhotoLinkClick} />

                <h1 className="title-bpool medium">{I18n.get("Imagem de Capa")}</h1>

                <p style={{ marginTop: '1rem', marginBottom: '2rem' }}>{I18n.get("Enviar somente uma imagem do tipo JPG ou PNG.")}<br />
                </p>

                <PhotosForm {...this.props}
                    allowMultipleSelect={false}
                    allowOnlyImages={true}
                    limitToUpload={1}
                    photos={this.state.form.posters}
                    uploading={this.state.uploading}
                    addUpload={this.addPosterImageClick}
                    del={this.delPosterImageClick} />

                <h1 className="title-bpool medium">{I18n.get("Avaliação do Parceiro e da Marca")}</h1>

                <div className="field is-horizontal">
                    <div className="field-body">

                        <div className="field">
                            <div className="control" style={{ textAlign: 'justify' }}>{I18n.get("A cada checkpoint do projeto, os usuários de marketing na plataforma vão avaliar o parceiro (qualidade de entrega, cumprimento de cronograma, etc.). Você gostaria que o time envolvido no projeto fosse avaliado pelo parceiro (qualidade de briefing e dos feedbacks, entre outros aspectos)?")}</div>
                        </div>

                        <div className="field">
                            <label className="label is-small">
                            </label>
                            <div className="control">

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <CheckboxSwitchField {...this.state.form.evaluatePartner} />
                            </div>
                        </div>

                    </div>
                </div>

                {this.props.isAdmin && <div className="control">
                    <SubmitButtonField
                        label={I18n.get("Salvar")}
                        onClick={this.submitForm.bind(this)}
                        task={this.state.task}
                    />
                </div>}
            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CompanyForm);
