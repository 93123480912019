import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { CLIENT_API } from '../../actions/client/actions';

import UserCmp from './_user-cmp';


const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp }, state.client);
};

const mapDispatchToProps = dispatch => {
    return {
        addUser: data => {
            dispatch(CLIENT_API.create_user(data));
        },
        delUser: data => {
            dispatch(CLIENT_API.delete_user(data));
        },
        update_payment: data => {
            dispatch(CLIENT_API.update_payment(data));
        },
    };
};

class ContractPaymentForm extends React.Component {
    constructor(props) {
        super();

        this.state = {
            clientId: props.clientId//,
        };

        this.submitForm = this.onSubmitForm.bind(this);
    }



    onSubmitForm(e) {
        e.preventDefault();
        this.props.update_payment(this.state);
    }

    render() {

        const isAdmin = this.props.isAdmin || false;

        return (
            <form onBlur={this.submitForm} className="form-mobile">

                <h1 className="subtitle subtitle-v2">{I18n.get("Contrato Digital Master BPool")}</h1>

                <p style={{ marginTop: '1rem', marginBottom: '2rem' }}>{I18n.get(
                    "Indique abaixo os responsáveis pela assinatura do contrato digital. Ao concluir esse cadastro, todos vão receber o contrato de adesão a BPool e os contratos de projetos para assinar eletronicamente."
                )}</p>

                <UserCmp {...this.props} role="ClientUserGlobalContract" enableJobRole={true} enableAddSelf={!isAdmin} />


            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractPaymentForm);
