import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';

import TextField from '../../form/text-field';
import PercentageField from '../../form/percentage-field';
import RadioMultiField from '../../form/radio-multi-field';


import constants from '../../../constants';

import { scrollToTop } from '../../../utils/ui';

class ScorecardForm extends React.Component {
    constructor(props) {

        super(props);

        const { bPitch = {} } = props;

        let { scorecard = [] } = bPitch;

        if (!bPitch.scorecardIsCustom && scorecard.length == 0) {
            scorecard = constants.BPITCH_SCORECARD;
        }

        this.state = {
            fields: {
                topicName: {
                    label: I18n.get("Nome"),
                    placeholder: I18n.get("Adicionar Critério"),
                    onChange: this.onChangeForm.bind(this, 'topicName'),
                    maxLength: 100,
                    visited: true,
                    successIcon: false
                },
                itemName: {
                    label: I18n.get("Nome"),
                    placeholder: I18n.get("Adicionar Item"),
                    onChange: this.onChangeForm.bind(this, 'itemName'),
                    maxLength: 100,
                    visited: true,
                    successIcon: false
                },
                itemPerc: {
                    label: I18n.get("Peso"),
                    onChange: this.onChangeForm.bind(this, 'itemPerc'),
                    visited: true,
                    successIcon: false
                },
            },
            itemTopicIndex: null,
            errors: {},
            form: { ...bPitch, scorecard },
            task: {},
            submit_task: {},
            scorecardBkp: []
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }

            });

            this.setState(nextState);
        }
    }

    componentWillUnmount(){
        this.props.update_bpitch(this.getData())
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving());
    }

    onAddItemChangeForm(topicIndex, prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            const key = `${prop}_${topicIndex}`;

            nextState.form[key] = value;
            nextState.errors[key] = null;

            return nextState;

        }, () => this.onSaving());
    }

    onTopicChangeForm(topicIndex, prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.scorecard[topicIndex][prop] = value;
            nextState.errors.scorecard = null;

            return nextState;

        }, () => this.onSaving());
    }

    onItemChangeForm(topicIndex, itemIndex, prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.scorecard[topicIndex].children[itemIndex][prop] = value;
            nextState.errors.scorecard = null;

            return nextState;

        }, () => this.onSaving());
    }

    onAddTopic(e) {

        e.preventDefault();

        const validation = this.state.form.topicName && this.state.form.topicName.length;

        const errors = {
            topicName: validation ? null : { errorMessage: I18n.get('Por favor inforne o Nome') },
        };

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (validation) {

                const customCount = prevState.form.scorecard.reduce((acc, item) => {
                    return acc + (item.code.indexOf('CUSTOM') > -1 ? 1 : 0);
                }, 0);

                const topic = {
                    code: 'CUSTOM' + (customCount + 1),
                    label: this.state.form.topicName,
                    children: []
                }

                nextState.form.scorecard = [...prevState.form.scorecard, topic];
                nextState.form.topicName = null;
                nextState.errors.topicName = null;
            }
            else {

                nextState.errors = { ...prevState.errors, ...errors };
            }

            return nextState;

        }, () => this.onSaving());

    }

    onDelTopic(topicIndex, e) {

        e.preventDefault();

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.scorecard = prevState.form.scorecard.filter((v, index) => index != topicIndex);

            return nextState;
       
        }, () => this.onSaving());
    }

    onAddItem(topicIndex, e) {

        e.preventDefault();

        const itemNameKey = `itemName_${topicIndex}`;
        const itemPercKey = `itemPerc_${topicIndex}`;

        const validation1 = this.state.form[itemNameKey] && this.state.form[itemNameKey].length;
        const validation2 = this.state.form[itemPercKey] && this.state.form[itemPercKey].length;

        const validation = validation1 && validation2;

        const errors = {
            [itemNameKey]: validation1 ? null : { errorMessage: I18n.get('Por favor inforne o Nome') },
            [itemPercKey]: validation2 ? null : { errorMessage: I18n.get('Por favor inforne o Peso') },
        };

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (validation) {

                const topic = prevState.form.scorecard[topicIndex];

                const customCount = topic.children.reduce((acc, item) => {
                    return acc + (item.code && item.code.indexOf('CUSTOM') > -1 ? 1 : 0);
                }, 0);

                const item = {
                    code: 'CUSTOM' + (customCount + 1),
                    label: this.state.form[itemNameKey],
                    perc: this.state.form[itemPercKey]
                }

                nextState.form.scorecard[topicIndex].children = [...topic.children, item];
                nextState.form[itemNameKey] = null;
                nextState.errors[itemNameKey] = null;
                nextState.form[itemPercKey] = null;
                nextState.errors[itemPercKey] = null;
            }
            else {

                nextState.errors = { ...prevState.errors, ...errors };
            }

            return nextState;
                    
        }, () => this.onSaving());
    }

    onDelItem(topicIndex, itemIndex, e) {

        e.preventDefault();

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.scorecard[topicIndex].children = prevState.form.scorecard[topicIndex].children.filter((v, index) => index != itemIndex);

            return nextState;
        
        }, () => this.onSaving());
    }

    onChangeScorecardCustom(value) {

        
        this.setState(prevState => {

            var nextState = Object.assign({}, prevState);

            if (value === true){

                const prev =  [...prevState.form.scorecard];
                
                nextState.form.scorecard = nextState.scorecardBkp;
                nextState.scorecardBkp = prev;
            }
            else {

                const prev =  [...prevState.form.scorecard];
               
                nextState.form.scorecard = nextState.scorecardBkp;
                nextState.scorecardBkp = prev;
            }

            nextState.form.scorecardIsCustom = value;

            return nextState;
        
        }, () => this.onSaving());
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.props.bPitch,
            ...this.state.form
        };

        return data;
    }

    onSaving() {

        clearTimeout(this.t);
        this.t = setTimeout(() => this.onSave(), 1000)
    }

    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_bpitch(this.getData())
            .then(({ task = {}, errors = {} }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    render() {

        const columnStyle = {
            padding: '0.30rem'
        };

        const total = this.state.form.scorecard.reduce((acc, topic) => {

            const subtotal = topic && topic.children && topic.children.reduce((acc, item) => acc + (item && item.perc ? parseFloat(item.perc) : 0), 0);

            return acc + (subtotal ?? 0);

        }, 0);

        const scorecardTotalError = this.state.errors['scorecardTotal'];

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Scorecard")}</h1>

                <p>
                    {I18n.get("Por favor preencha os critérios e pesos que utilizará na avaliação dos participantes do seu pitch.")} {I18n.get("Criamos um modelo BPool como base que você pode utilizar ou não.")} {I18n.get("Você deve preencher pelo menos um critério para passar para próxima etapa.")}
                </p>

                <div className="bp-event-section" style={{ marginTop: '2rem', maxWidth: '50rem' }}>
                    <div className="tabs is-centered is-medium" >

                        <ul style={{ borderBottom: 'solid 1px #dbdbdb' }}>
                            <li className={this.state.form.scorecardIsCustom === false ? 'is-active' : ''}>
                                <a onClick={this.onChangeScorecardCustom.bind(this, false)} style={{ fontWeight: 'normal' }}>
                                    {I18n.get("BPool")}
                                </a>
                            </li>
                            <li className={this.state.form.scorecardIsCustom === true ? 'is-active' : ''}>
                                <a onClick={this.onChangeScorecardCustom.bind(this, true)} style={{ fontWeight: 'normal' }}>
                                    {I18n.get("Novo")}
                                </a>
                            </li>

                        </ul>

                    </div>


                    <div className="columns">

                        <div className="column is-9">
                            <p style={{ fontSize: '85%' }}>{I18n.get("A soma dos pesos deve ser igual a 100%.")}</p>
                        </div>

                        <div className="column is-3">
                            <p style={{ fontWeight: 'bold' }}>{I18n.get("Total")}  {total}%</p>

                            {scorecardTotalError && scorecardTotalError.errorMessage && <>
                                <p className="help is-danger">
                                    {scorecardTotalError.errorMessage}
                                </p>
                            </>}

                        </div>
                    </div>

                    {this.state.form.scorecard.map((topic, topicIndex) => {

                        const topicLabelError = this.state.errors[`scorecard[${topicIndex}].label`];
                        const topicCountError = this.state.errors[`scorecard[${topicIndex}].children`];

                        const total = topic && topic.children && topic.children.reduce((acc, item) => acc + (item && item.perc ? parseFloat(item.perc) : 0), 0);

                        return (
                            <div style={{ marginTop: '1rem', padding: '3rem', borderRadius: '0.25rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
                                <div className="columns">

                                    <div className="column is-9" style={columnStyle}>

                                        <TextField
                                            label={I18n.get("Critério")}
                                            onChange={this.onTopicChangeForm.bind(this, topicIndex, 'label')}
                                            // onBlur={this.onSave.bind(this)}
                                            value={topic && topic.label}
                                            error={topicLabelError ?? topicCountError}
                                            visited={true}
                                            successIcon={false}
                                        />

                                    </div>

                                    <div className="column is-2" style={columnStyle}>

                                        <div style={{ marginTop: '1rem', padding: '0.75rem', fontWeight: 'bold' }}>
                                            {/* {I18n.get("Peso")}:  */}
                                            {total}%
                                        </div>

                                        {/* <PercentageField label={I18n.get("Peso")} readOnly={true} value={total} /> */}

                                    </div>

                                    <div className="column is-1" style={columnStyle}>

                                        <div className="field is-narrow">
                                            <label className="label is-small">
                                                &nbsp;
                                            </label>
                                            <div className="control">
                                                <a onClick={this.onDelTopic.bind(this, topicIndex)} className='button is-rounded is-black is-outlined'>
                                                    <span className="icon">
                                                        <i className="fa fa-times"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                {topic && topic.children && topic.children.map((item, itemIndex) => {

                                    const itemLabelError = this.state.errors[`scorecard[${topicIndex}].children[${itemIndex}].label`];
                                    const itemPercError = this.state.errors[`scorecard[${topicIndex}].children[${itemIndex}].perc`];

                                    return (
                                        <div className="columns">

                                            <div className="column is-6 is-offset-3" style={columnStyle}>

                                                <TextField
                                                    // label={`Item ${itemIndex + 1}`}
                                                    placeholder={I18n.get("Preencha o texto do item")}
                                                    onChange={this.onItemChangeForm.bind(this, topicIndex, itemIndex, 'label')}
                                                    // onBlur={this.onSave.bind(this)}
                                                    value={item && item.label}
                                                    error={itemLabelError}
                                                    visited={true}
                                                    successIcon={false}
                                                />

                                            </div>


                                            <div className="column is-2" style={columnStyle}>

                                                <PercentageField
                                                    // label={I18n.get("Peso")}
                                                    onChange={this.onItemChangeForm.bind(this, topicIndex, itemIndex, 'perc')}
                                                    // onBlur={this.onSave.bind(this)}
                                                    value={item && item.perc}
                                                    error={itemPercError}
                                                    visited={true}
                                                />

                                            </div>

                                            <div className="column is-1" style={columnStyle}>

                                                <div className="field is-narrow">
                                                    <div className="control">
                                                        <a onClick={this.onDelItem.bind(this, topicIndex, itemIndex)} className='button is-rounded is-black is-outlined'>
                                                            <span className="icon is-small">
                                                                <i className="fa fa-times"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    );

                                })}

                                {topic && topic.children && topic.children.length < 5 && <>

                                    <br /><br />

                                    <div className="columns">

                                        <div className="column is-6 is-offset-3" style={columnStyle}>

                                            <TextField
                                                {...this.state.fields.itemName}
                                                onChange={this.onAddItemChangeForm.bind(this, topicIndex, 'itemName')}
                                                value={this.state.form[`itemName_${topicIndex}`]}
                                                error={this.state.errors[`itemName_${topicIndex}`]} />

                                        </div>

                                        <div className="column is-2" style={columnStyle}>

                                            <PercentageField
                                                {...this.state.fields.itemPerc}
                                                onChange={this.onAddItemChangeForm.bind(this, topicIndex, 'itemPerc')}
                                                value={this.state.form[`itemPerc_${topicIndex}`]}
                                                error={this.state.errors[`itemPerc_${topicIndex}`]} />
                                        </div>

                                        <div className="column is-1" style={columnStyle}>
                                            <div className="field is-narrow">
                                                <label className="label is-small">
                                                    &nbsp;
                                            </label>
                                                <div className="control">
                                                    <a onClick={this.onAddItem.bind(this, topicIndex)} className='button is-rounded is-black'>
                                                        <span className="icon is-small">
                                                            <i className="fa fa-plus"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>}

                            </div>
                        );
                    })}

                    <br /><br />

                    {this.state.form.scorecard && this.state.form.scorecard.length < 5 && <>

                        <div style={{ padding: '3rem' }}>

                            <p>{I18n.get("ADICIONAR CRITÉRIO")}</p>
                            <span style={{ fontSize: '80%', color: '#909090' }}>{I18n.get("Adicione um critério customizado.")}</span>

                            <br /><br /><br />

                            <div className="columns">

                                <div className="column is-11">

                                    <TextField {...this.state.fields.topicName} value={this.state.form.topicName} error={this.state.errors.topicName} />

                                </div>

                                <div className="column is-1">
                                    <div className="field is-narrow">
                                        <label className="label is-small">
                                            &nbsp;
                                </label>
                                        <div className="control">
                                            <a onClick={this.onAddTopic.bind(this)} className='button is-rounded is-black'>
                                                <span className="icon is-small">
                                                    <i className="fa fa-plus"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </>}

                </div>
            </div>
        );
    }
}

export default ScorecardForm;
