import styled from "styled-components";

export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const WrapperTypesCustomer = styled.div`
  display: flex;
  margin: 30px 0 60px 0;

  .active {
    background: #1883ff;
    border-color: #1883ff;
    color: #ffffff;
  }
`;

export const ButtonType = styled.button`
  width: 170px;
  height: 65px;
  border-radius: 15px;
  margin-right: 15px;
  background: #ffffff;
  border: 1px solid;
  border-color: #333;
  color: #979797;
  font-size: 18px;
  line-height: 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

export const TitleSection = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #010202;
  margin-bottom: 20px;
`;

export const ContentCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleCheckboxes = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const CheckboxesAndLabels = styled.div`
  display: flex;
`;

export const Checks = styled.div`
  width: 50%;
  display: flex;
  align-items: center;

  label {
    margin: 0;

    span {
      .ant-checkbox-inner {
        border-radius: 4px;
      }
    }
  }
`;

export const LabelCheck = styled.div`
  margin-left: 5px;
`;

export const ContentDatePicker = styled.div`
  width: 50%;
  padding-right: 15px;

  span {
    width: 100%;

    .ant-calendar-picker-input {
      height: 50px;
      border-radius: 10px !important;
      background: #fff !important;
      border: solid 1px !important;
      border-color: ${(props) =>
        props.errorDate ? "#ff0000 !important" : "#999999 !important"};
    }
  }
`;

export const SelectCategory = styled.div`
  .ant-select {
    .ant-select-selection {
      background: #ffffff;
      color: #515152;
      border: solid 1px;
      border-color: ${(props) => (props.errorSelect ? "#ff0000" : "#999999")};
      border-radius: 10px;
      height: 50px;
      padding: 0 15px;
      display: flex;
      align-items: center;

      .ant-select-selection__rendered {
        margin: 0;
      }

      .ant-select-arrow {
        top: 8px;
        color: #999999;
      }
    }
  }
`;

export const FieldError = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;

export const ContentSelectYearsAndMonths = styled.div`
  display: flex;
`;

export const SelectYearAndMonth = styled.div`
  .ant-select {
    .ant-select-selection {
      background: #ffffff;
      color: #515152;
      border: solid 1px #999999;
      border-radius: 10px;
      height: 50px;
      padding: 0 15px;
      display: flex;
      align-items: center;

      .ant-select-selection__rendered {
        margin: 0;
      }

      .ant-select-arrow {
        top: 8px;
        color: #999999;
      }
    }
  }
`;

export const WrapperItemCustomer = styled.div`
  margin: 30px 0 0 0;
  .textAlert {
    padding: 15px 0;
    color: #f00;
  }
`;

export const ItemCustomerFormEmpty = styled.div`
  font-size: 14px;
`;

export const ItemCustomerLabel = styled.div`
  font-weight: bold;
`;

export const ItemCustomerValue = styled.div``;

export const ContentItemCustomer = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const ItemCustomer = styled.div``;

export const ErroSendPage = styled.div`
  color: #fff;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  background: #ff0000;
  border-radius: 4px;

  strong {
    color: #fff;
  }
`;

export const ErrorDateCustomer = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;
