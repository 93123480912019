import styled from "styled-components";

export const DescriptionSelfDeclaration = styled.div``;

export const WrapperUploadCertified = styled.div`
  margin-top: 0px;

  .error {
    border-color: #ff0000;
  }
`;

export const LabelUploadCertified = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #2f2f2f;
`;

export const ContainerUploadCertified = styled.div`
  border-radius: 15px;
  border: 2px dashed;
  border-color: #ccc;
  margin-top: 15px;
`;

export const InfoError = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;

export const IconAndTextUploadCertified = styled.div`
  display: flex;
  align-items: center;
  max-width: 190px;
`;

export const ContentUploadCertified = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconUploadCertified = styled.div`
  span {
    font-size: 30px;
    color: #1883ff;
  }

  .error {
    color: #ff0000;
  }
`;

export const TextUploadCertified = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  height: 100%;
  justify-content: center;
  width: 100%;

  strong {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: #121212;
  }

  span {
    font-weight: 400;
    font-size: 9.14851px;
    line-height: 12px;
    color: #868686;
  }

  .error {
    color: #ff0000;
  }
`;

export const FileUploaded = styled.div`
  width: 100%;
  .uploaded {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #121212;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #ff0000;
    margin-left: 10px;

    &.view {
      color: #000;
    }

    i {
      padding-right: 3px;
    }
  }
`;

export const IconInfo = styled.div``;

export const ButtonUploadCertified = styled.div`
  input {
    display: none;
  }

  label {
    cursor: pointer;
    background-color: #1883ff;
    border-color: #1883ff;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    padding: 10px 15px;
  }
`;

export const RowLoading = styled.div`
  margin-top: 14px;
`;
