import React from 'react';

import { I18n } from 'aws-amplify';

import HeadingEvent from './_heading-event';
import HeadingBPitch from './_heading-bpitch';

class Heading extends React.Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    render() {

        const { project = {} } = this.props;

        if (project && project.isCustomEvent){

            return (<HeadingEvent {...this.props} />);
        }

        if (project && project.isBPitch){
            return ( <HeadingBPitch {...this.props} />);
        }

        return null;
    }
}

export default Heading;
