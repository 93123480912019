import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import ContainerPage from './ContainerPage';
import SubHeaderMenu from './SubHeaderMenu';

const Nav = styled.nav`
  background: #fff;
  border-bottom: solid #dbdbdb 1px;
  height: 65px;

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
  }
`;

const Title = styled.h3`
  line-height: 65px;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  padding-left: 15px;
`;

class SubHeader extends Component {
    state = {}
    render() {
        const { titlePage = "", itensMenu, activeItem } = this.props;

        return (
            <Nav>
                <ContainerPage>
                    <Row gutter={1}>
                        <Col md={12} sm={24}>
                            <Title>{titlePage}</Title>
                        </Col>
                        <Col md={12} sm={24}>
                            <SubHeaderMenu
                                itens={itensMenu}
                                activeItem={activeItem}
                            />
                        </Col>
                    </Row>
                </ContainerPage>
            </Nav>
        )
    }
}

export default SubHeader;
