import React from 'react';

import { I18n } from 'aws-amplify';

export default class CheckboxSwitchField extends React.Component {
    constructor(props) {
        super();
        this.state = {
        }
    }

    onClick() {
        this.setState({ focused: false });

        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {

        const isError = !!this.props.value && this.props.error;

        return (<div className="field">
            
            <input
                id={this.props.id}
                type="checkbox"
                value={this.props.value || ""}
                checked={this.props.checked}
                onChange={this.props.onChange}
                className={isError ? "switch is-rounded is-danger" : "switch is-rounded is-success"}
                //onBlur={this.props.onBlur}
                onClick={() => this.onClick()}
                disabled={this.props.disabled}
            />
            {isError && <p className="help is-danger">
                {this.props.error.errorMessage}
            </p>}
            <label htmlFor={this.props.id}>&nbsp; {this.props.label} </label>

        </div>);
    }
}
