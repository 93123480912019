import React from "react";
import { I18n } from "aws-amplify";

import * as S from "./styles";
import { Select } from "antd";
const { Option } = Select;

const ComboAssets = (props) => {
  return (
    <S.Wrapper>
      <Select
        placeholder={I18n.get("Adicionar Itens")}
        onSelect={(e) => props.getCode(e)}
      >
        {props?.assets.map((item) => {
          return (
            <Option value={item?.code}>
              {item?.label} - <b>{item?.priceFmt}</b>
            </Option>
          );
        })}
      </Select>
    </S.Wrapper>
  );
};

export default ComboAssets;
