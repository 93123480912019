import React from "react";
import Button from "../Button";
import MainTitle from "../MainTitle";
import * as S from "./styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { I18n } from "aws-amplify";

const ProjectStatus = ({ auth, projects }) => {
  return (
    <div>
      <MainTitle text={I18n.get("Últimos Status de Projetos")} />
      <S.ProjectBoard>
        <div>
          <div className="row">
            <h2>{I18n.get("Nome")}</h2>
            <h2>{I18n.get("Status")}</h2>
          </div>
          {projects &&
            projects.map((res, index) => (
              <div className="row" key={index}>
                <p>{res.name}</p>
                <p>{res.statusDescription}</p>
              </div>
            ))}
        </div>
        {projects.length < 1 && (
          <div className="row">
            <p>{I18n.get("Nenhum dado a ser exibido")}</p>
          </div>
        )}
        {auth.is_client_or_client_user && (
          <Button
            text={I18n.get("Ir para Projetos")}
            href="/#/cliente/projetos/v2"
          />
        )}
        {auth.is_partner && (
          <Button
            text={I18n.get("Ir para Projetos")}
            href="/#/parceiro/projetos/v2"
          />
        )}
      </S.ProjectBoard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(ProjectStatus));
