import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import MultUploadFile from '../../components/MultUploadFile';
import { Link } from 'react-router-dom';

import {
    Row,
    Col,
    Button,
    Checkbox,
    InputNumber,
    Input,
    Icon,
    Tooltip,
    Select,
    Form
} from 'antd';


const { TextArea, Search } = Input;
const { Option } = Select;


const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
      margin-right: 20px;
  }
`;

const BoxAddFild = styled(Search)`
    button {
        position:relative;

        i {
            display: none;
        }
    }
`;

class FormPortfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            videos: [],
            links: [],
            images: [],
            formState: [],
            listImg: []
        }
    }

    removeUrl = (url) => {
        const arr = this.state.videos.filter(e => e !== url);

        this.setState({
            videos: arr
        })
    }

    removeLink = (url) => {
        const arr = this.state.links.filter(e => e !== url);

        this.setState({
            links: arr
        })
    }

    addUrl = () => {
        const valueInput = this.props.form.getFieldValue('videos');

        if (valueInput) {
            this.setState({
                videos: [
                    ...this.state.videos,
                    valueInput
                ]
            })
        }

        this.props.form.resetFields('videos');
    }

    addLink = () => {
        const valueInput = this.props.form.getFieldValue('links');

        if (valueInput) {
            this.setState({
                links: [
                    ...this.state.links,
                    valueInput
                ]
            })
        }

        this.props.form.resetFields('links');
    }

    getDataUploadMult = (files) => {
        this.setState({
            images: files
        });


        setTimeout(() => {
            // this.sendDataApi();
        }, 1);
    }

    sendDataApi = () => {
        const fieldsForm = this.props.form.getFieldsValue();
        const dataForm = {
            ...fieldsForm,
            partnerId: this.props.partnerId,
        }

        return dataForm;
    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        I18n.setLanguage(this.props.locale);
    }


    handleSubmit = () => {

        let x = {
            Portifolio: this.sendDataApi(),
            Locale: this.props.locale
        };



        this.props.action(x);
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.setState({
                    formState: values,
                })

                let data = {
                    ...values,
                    images: this.state.images,
                    videos: this.state.videos,
                    links: this.state.links,
                    type: this.props.item.type || this.props.type
                }

                if (this.props.isLeader) {
                    data.leaderId = this.props.partnerId;
                } else {
                    data.partnerId = this.props.partnerId;
                }


                if (Object.keys(this.props.item).length > 0) {
                    data.id = this.props.item.id;

                    let x = {
                        Portifolio: data,
                        Locale: this.props.locale
                    };

                    this.props.update(x);

                } else {

                    let x = {
                        Portifolio: data,
                        Locale: this.props.locale
                    };

                    this.props.action(x);
                }
            }
        });

    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        }

        if (this.state.loading != prevState.loading) {
            let listImg = this.props.item && this.props.item.images && this.props.item.images.map((item, index) => {
                return {
                    uid: index,
                    name: 'image.png',
                    status: 'done',
                    url: item
                }
            });

            this.setState({
                listImg: listImg,
                images: this.props.item.images || [],
                videos: this.props.item.videos || [],
                links: this.props.item.links || []
            });

            if (!this.props.item.images) {
                this.setState({
                    listImg: []
                });
            }
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const { partner } = this.props;


        const ListCategorie = this.props.sectorsFlat && this.props.sectorsFlat.sort((a, b) => (a.label > b.label) - (a.label < b.label));

        const specialismValues = this.props.specialisms && this.props.specialisms.map((item) => {

            const specialism = this.props.specialismsBP && this.props.specialismsBP.find((s) => s.code == item) || {};

            return specialism;

        });

        const linkEsp = this.props.isFull ? `/parceiro/cadastro/especialidades/${this.props.id}` : `/parceiro/pre-cadastro/especialidades/${this.props.id}`

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row className="RowSection">
                    <Col sm={12}>
                        <Form.Item label={I18n.get("Nome")}>
                            {getFieldDecorator('name', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.name ? this.props.item.name : null
                            })(
                                <Input

                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                {(!this.props.isLeader && this.props.type != 2) &&
                    <Row>
                        <Col sm={24}>
                            <Form.Item label={I18n.get("Especialidade")}>
                                {getFieldDecorator('specialism', {
                                    rules: [{ required: false, message: 'ops!' }],
                                    initialValue: this.props.item && this.props.item.specialism ? this.props.item.specialism : null
                                })(
                                    <Checkbox.Group>
                                        {specialismValues && specialismValues.map((i) => {
                                            return <Col sm={12}><Checkbox value={i.code}>{i.label}</Checkbox></Col>
                                        })}
                                    </Checkbox.Group>
                                )}
                                {specialismValues && specialismValues.length == 0 &&
                                    <>
                                        <br/><p style={{ marginTop: "-40px", color: "#e20000" }}><strong style={{ color: "#e20000" }}>{I18n.get("Favor preencher as")} <Link style={{ color: "#e20000", textDecoration: "underline" }} to={linkEsp}>{I18n.get("especialidades")}</Link>{I18n.get("primeiro.")}</strong></p>
                                    </>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                }


                <Row>
                    <Col sm={24}>
                        <Form.Item label={I18n.get("Imagens")}>
                            {getFieldDecorator('images', {
                                rules: [{ required: false, message: 'ops!' }],
                            })(
                                <MultUploadFile
                                    getDataUpload={this.getDataUploadMult}
                                    name="images"
                                    uploadId={this.props.partnerId}
                                    actionUpload={this.props.upload}
                                    fileListGet={this.state.listImg || []}
                                    isEdit={true}
                                    get2={this.props.get2 || false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={12}>

                        <Form.Item label={I18n.get("Vídeos")}>
                            {getFieldDecorator('videos', {
                                rules: [{ required: false, message: 'ops!' }],
                            })(
                                <BoxAddFild
                                    className="InputPlus"
                                    placeholder=""
                                    onSearch={() => this.addUrl()}
                                    enterButton
                                />
                            )}
                            <p className="helptext">{I18n.get("(Youtube, Vimeo ou Dropbox)")}</p>
                        </Form.Item>

                        <ul className="ListDinamic">
                            {this.state.videos &&
                                this.state.videos.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={item} target="_blank">
                                                <Tooltip title={item}><Icon type="video-camera" /> <span className="txt">{item}</span></Tooltip>
                                            </a>
                                            <Icon type="delete" onClick={() => this.removeUrl(item)} theme="filled" className="iconList" />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                    <Col sm={12}>

                        <Form.Item label={I18n.get("Links")}>
                            {getFieldDecorator('links', {
                                rules: [{ required: false, message: 'ops!' }],
                            })(
                                <BoxAddFild
                                    className="InputPlus"
                                    placeholder=""
                                    onSearch={() => this.addLink()}
                                    enterButton
                                />
                            )}
                        </Form.Item>

                        <ul className="ListDinamic">
                            {this.state.links &&
                                this.state.links.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={item} target="_blank">
                                                <Tooltip title={item}><Icon type="link" /> <span className="txt">{item}</span></Tooltip>
                                            </a>
                                            <Icon type="delete" onClick={() => this.removeLink(item)} theme="filled" className="iconList" />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>
                <RowSubmitModal>
                    <Col sm={24}>

                        <Button shape="round" onClick={() => this.props.cancel()}>{I18n.get("Cancelar")}</Button>

                        <Button type="primary" shape="round" htmlType="submit">
                            {Object.keys(this.props.item).length > 0 ? I18n.get("Atualizar") : I18n.get("Adicionar")}
                        </Button>
                    </Col>
                </RowSubmitModal>
            </Form>
        );
    }
}

const FormPortfolioForm = Form.create({})(FormPortfolio);

export default FormPortfolioForm;
