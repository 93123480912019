import styled, { css } from "styled-components";
import { Button } from "antd";

const ButtonColorModifier = {
  primary: () => css`
    background-color: #1883ff !important;
    border-color: #1883ff !important;
    color: #ffffff !important;
    :hover {
      background-color: #2883ed !important;
      border-color: #2883ed !important;
    }
    :active,
    :focus {
      background-color: #2883ed !important;
      border-color: #2883ed !important;
    }
    :visited {
      background-color: #2883ed !important;
      border-color: #2883ed !important;
    }
  `,
  secondary: () => css`
    background-color: #ffffff !important;
    border-color: #000000 !important;
    color: #000000 !important;
    :hover {
      background-color: #000000 !important;
      border-color: #000000 !important;
      color: #ffffff !important;
    }
    :active,
    :focus {
      background-color: #000000 !important;
      border-color: #000000 !important;
      color: #ffffff !important;
    }
    :visited {
      background-color: #000000 !important;
      border-color: #000000 !important;
      color: #ffffff !important;
    }
  `,
  tertiary: () => css`
    background-color: #ffffff !important;
    border-color: #1883ff !important;
    color: #1883ff !important;
    :hover {
      background-color: #2883ed !important;
      border-color: #2883ed !important;
      color: #ffffff !important;
    }
    :active,
    :focus {
      background-color: #ffffff !important;
      border-color: #2883ed !important;
      color: #2883ed !important;
    }
    :visited {
      background-color: #ffffff !important;
      border-color: #2883ed !important;
      color: #2883ed !important;
    }
  `,
  danger: () => css`
    background-color: #ff0000 !important;
    border-color: #ff0000 !important;
    color: #ffffff !important;
    :hover {
      background-color: #dc0505 !important;
      border-color: #dc0505 !important;
    }
    :active,
    :focus {
      background-color: #dc0505 !important;
      border-color: #dc0505 !important;
    }
    :visited {
      background-color: #dc0505 !important;
      border-color: #dc0505 !important;
    }
  `,
  disabled: () => css`
    background-color: #1883ff !important;
    border-color: #1883ff !important;
    color: #ffffff !important;
    opacity: 0.5 !important;
    :hover {
      background-color: #2883ed !important;
      border-color: #2883ed !important;
    }
    :active,
    :focus {
      background-color: #2883ed !important;
      border-color: #2883ed !important;
    }
    :visited {
      background-color: #2883ed !important;
      border-color: #2883ed !important;
    }
  `,
  disabledSecondary: () => css`
    background-color: #ffffff !important;
    border-color: #000000 !important;
    color: #000000 !important;
    opacity: 0.5 !important;
    :hover {
      background-color: #ffffff !important;
    }
    :active,
    :focus {
      background-color: #ffffff !important;
    }
    :visited {
      background-color: #ffffff !important;
    }
  `,
  disabledTertiary: () => css`
    background-color: #ffffff !important;
    border-color: #1883ff !important;
    color: #1883ff !important;
    opacity: 0.5 !important;
    :hover {
      background-color: #ffffff !important;
    }
    :active,
    :focus {
      background-color: #ffffff !important;
    }
    :visited {
      background-color: #ffffff !important;
    }
  `,
};

const ButtonStyled = styled(Button)`
  ${(props) =>
    props.theme
      ? ButtonColorModifier[
          props.disabled
            ? props.theme === "tertiary"
              ? "disabledTertiary"
              : props.theme === "secondary"
              ? "disabledSecondary"
              : "disabled"
            : props.theme
        ]
      : ButtonColorModifier["primary"]}
  width: ${(props) => (props.full ? "100%;" : "max-content;")};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  ${(props) => (props?.size === "large" ? "height: 62px;" : "height: 50px;")}
  ${(props) =>
    props?.size === "large" ? "font-size: 16px;" : "font-size: 14px;"}
  ${(props) => (props?.size === "large" ? "padding: 0 30px;" : "")}
  
  img {
    margin-right: 10px;
    height: 15px;
  }

  span {
    line-height: 14px;
    margin-top: 3.5px;
  }
`;

export { ButtonStyled as Button };
