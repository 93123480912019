import React from "react";

import { I18n } from "aws-amplify";

import Steps from "./_steps";

import ReviewStep1 from "../../_project-review-1";
// import AssetBundle from "../../_project-asset-bundle";
import { AssetBundle } from "../../../v2/components/UI/AssetBundle";
import { ConvertValuesCreativeScope } from "../../../v2/pages/ProjectsList/Invitations/ProjectDetails/utils/convertValuesCreativeScope";
// import { AssetBundle } from "../../../../../v2/components/UI/AssetBundle";
import ReviewStep4 from "../../_project-review-4";

import NakedModal from "../../ui/naked-modal";

import PartnerCard from "../../partner/shared/_partner-card";
import PartnerFullCard from "../../partner/shared/_partner-full-card";

import SubmitButtonField from "../../form/submit-button-field";

import WYSIWYGEditor from "../../form/wysiwyg-editor";

import QuotationForm from "../../client-project-definition/quotation-form";

import ReactTooltip from "react-tooltip";

import ReviewStep5 from "../../_project-review-5";
import ReviewStepFiles from "../../_project-review-files";
import ReviewStep5Client from "../../_project-review-5-client";

import EventDetails from "../../_project-details-eventCustom";
import BPitchDetails from "../../_project-details-bpitch";

import MilestoneList from "../../shared/milestone-list";

import ExtraList from "./_extra-list";

import SettingsForm from "./_settings-form";

import { Task } from "../../../utils/task";

import {
  Drawer,
  Rate,
  Collapse,
  Icon,
  Modal,
  Row,
  Col,
  Select,
  Button,
  InputNumber,
  Popconfirm,
  Tooltip,
  notification,
} from "antd";

import { ClientProjectUniversalQuotationSectionTypeNames } from "../../../model";
import { SosRSAdmin } from "./SosRSAdmin";

const { Option } = Select;

const { Panel } = Collapse;

export default class ProjectDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      detailsView: null,
      task: {},
      visible: false,
      loading: false,
      newStatus: "",
      newPerc: "",
      priceBPoolComission: "",
      newRespClient: "",
      newRespPartner: "",
      loadingProject: false,
    };
  }

  componentDidMount() {
    this.setState({ task: {}, loadingProject: true });

    if (this.props.project?.project?.clientId) {
      this.props.getClientResponsables(this.props.project?.project?.clientId);
    }

    if (this.props.project?.project?.partnerId) {
      this.props.getPartnerResponsables(this.props.project?.project?.partnerId);
    }
  }

  componentWillMount() {
    this.setState({ loadingProject: false });
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  showCandidates = () => {
    if (!this.props.bp.candidates) {
      this.props.loadCandidates();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.project.task || {};
    const prev = (prevProps && prevProps.project.task) || {};

    if (
      this.props.partnerId != prevProps.partnerId &&
      this.props.partnerId &&
      !this.state.loading
    ) {
      let data = {
        partnerId: this.props.partnerId,
        projectId: this.props.projectId,
      };
      this.props.getPartnerResponsible(data);

      if (this.props.project?.project?.clientId) {
        this.props.getClientResponsables(this.props.project?.project?.clientId);
      }

      if (this.props.project?.project?.partnerId) {
        this.props.getPartnerResponsables(
          this.props.project?.project?.partnerId
        );
      }

      this.setState({
        loading: true,
        newPerc: this.props.project?.project?.bpCommission?.perc,
        priceBPoolComission: this.props.project?.project?.priceBPoolComission,
      });
    }

    if (
      this.state.loadingProject != prevState.loadingProject &&
      this.props.project?.project
    ) {
      this.setState({
        newPerc: this.props.project?.project?.bpCommission?.perc,
        priceBPoolComission: this.props.project?.project?.priceBPoolComission,
      });

      if (this.props.project?.project?.clientId) {
        this.props.getClientResponsables(this.props.project?.project?.clientId);
      }

      if (this.props.project?.project?.partnerId) {
        this.props.getPartnerResponsables(
          this.props.project?.project?.partnerId
        );
      }
    }

    if (task != prev) {
      this.setState({ task: task });
    }
  }

  showDetails(item, e) {
    this.setState({ detailsView: item });
  }

  hideDetails(e) {
    if (e) e.preventDefault();

    this.setState({ detailsView: null });
  }

  addMatch(partnerId) {
    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.update_match({
        projectId: this.props.projectId,
        partnerId: partnerId,
      });

      setTimeout(() => {
        this.setState({ task: {} });
      }, 2000);
    });
  }

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return "";

    return this.props.bp.clientProjectStatusesMap[value].label;
  }

  getSelected() {
    return {
      bundleId: this.props.bundleId,
      variationId: this.props.variationId,
    };
  }

  handleStatus = (value) => {
    this.setState({
      newStatus: value,
    });
  };

  sendStatus = () => {
    const data = {
      projectId: this.props.project.project.id,
      status: this.state.newStatus,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.updateStatusProject(data);
    });
  };

  handleCommission = (value) => {
    this.setState({
      newPerc: value,
    });
  };

  handleBpCommission = (value) => {
    this.setState({
      priceBPoolComission: value,
    });
  };

  sendCommission = () => {
    const data = {
      projectId: this.props.project.project.id,
      commissionPerc: this.state.newPerc,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.updateCommission(data);
    });
  };

  sendBpCommission = () => {
    const data = {
      projectId: this.props.project.project.id,
      priceBPoolComission: Number(this.state.priceBPoolComission),
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.updateBpCommission(data).then((resp) => {
        if (resp.success) {
          this.setState({ task: {} });
        } else {
          this.setState({ task: {} });
        }
      });
    });
  };

  handleClient = (value) => {
    this.setState({
      newRespClient: value,
    });
  };

  sendClient = () => {
    const data = {
      projectId: this.props.project.project.id,
      username: this.state.newRespClient,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.updateResponsableClient(data);
    });
  };

  handlePartner = (value) => {
    this.setState({
      newRespPartner: value,
    });
  };

  sendPartner = () => {
    const data = {
      projectId: this.props.project.project.id,
      username: this.state.newRespPartner,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.updateResponsablePartner(data);
    });
  };

  cancelInvite = (id) => {
    const data = {
      projectId: this.props.project.project.id,
      partnerId: id,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.updateCancelInvite(data);
    });
  };

  refreshProject = (id) => {
    var task = new Task();

    task.start();

    this.props.refreshProject(id).then((response) => {
      if (response) {
        this.props.get_domain();
        this.setState({ task: {} });
      } else {
        this.setState({ task: {} });
      }
    });

    this.setState({ task: task }, function () {});
  };

  refreshProjectCurrency = () => {
    const data = {
      projectId: this.props.project.project.id,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.refreshProjectCurrency(data);
    });
  };

  firePreMatch = (id) => {
    var preMatch = new Task();

    preMatch.start();

    const data = {
      projectId: id,
    };

    this.setState({ preMatch: preMatch }, function () {
      this.props.fire_pre_match(data).then(({ task, errors }) => {
        this.setState({ preMatch: task });

        if (task.result) {
          notification.success({
            message: I18n.get("Convites enviados com sucesso."),
            description: "",
          });
        } else {
          notification.error({
            message: I18n.get("Ops, algo deu errado!"),
            description: "",
          });
        }
      });
    });
  };

  render() {
    const {
      briefings = [],
      project: { project },
      briefing,
      bp: {
        clientProjectPartnerSelectedReasons,
        clientProjectPartnerNotSelectedReasons,
        clientProjectPartnerSelectedReasonsEvents,
      },
    } = this.props;

    // console.log("this.props", this.props);
    const selectedReasons =
      project && project.isPartnerSelectionBidding
        ? clientProjectPartnerSelectedReasonsEvents
        : clientProjectPartnerSelectedReasons;

    const x = this.props.partnerId;
    const reasonsok =
      this.props.reasons &&
      this.props.reasons.filter((reason) => reason.partnerId == x);
    const reasonsnot =
      this.props.reasons &&
      this.props.reasons.filter((reason) => reason.partnerId != x);

    // let ListConfim = this.props.bp.possibleMatches && this.props.bp.possibleMatches.possiblePartners.find(item => this.props.partnerIds.indexOf(item.partnerId) > -1);

    let ListConfim = [];
    let ListNotConfim = [];

    this.props.bp.possibleMatches &&
      this.props.bp.possibleMatches.possiblePartners.map((item) => {
        const isConfirmed = this.props.partnerIds.indexOf(item.partnerId) > -1;

        if (isConfirmed) {
          ListConfim.push(item);
        }
      });

    this.props.bp.possibleMatches &&
      this.props.bp.possibleMatches.possiblePartners.map((item) => {
        const isConfirmed = this.props.partnerIds.indexOf(item.partnerId) > -1;

        if (!isConfirmed) {
          ListNotConfim.push(item);
        }
      });

    const ListOrder = [...ListConfim, ...ListNotConfim];

    const quotation = this.props.eventQuotation || {};

    let milestones = [];

    if (project && project.isCreative) {
      milestones = project.milestones;
    } else {
      milestones = quotation.milestones;
    }

    let objDataValuesCreativeScope = ConvertValuesCreativeScope({
      data: project,
      isInvite: false,
    });

    return (
      <div className="project-ongoing-review container-content">
        <Steps {...this.props}></Steps>

        <br />
        <br />

        {this.props.auth.is_bpool &&
          (project?.projectType == 0 || project?.projectType == 2) && (
            <div>
              <Row>
                <Col sm={6}>
                  <div className="card uplift ">
                    <div className="card-content break-word">
                      <h3>{I18n.get("Status")}</h3>

                      <Select
                        onSelect={(value) => this.handleStatus(value)}
                        defaultValue={project?.status}
                        style={{ width: 120 }}
                      >
                        {this.props?.bp?.statusProjectAdmin?.map(
                          (item, index) => {
                            return (
                              <Option key={index} value={item.code}>
                                {item.label}
                              </Option>
                            );
                          }
                        )}
                      </Select>

                      <p className="btn-submit" style={{ marginTop: 10 }}>
                        <Button
                          loading={this.state?.task?.busy}
                          onClick={() => this.sendStatus()}
                          className="button bt-bpool black pull-right"
                          disabled={
                            !this.state.newStatus ||
                            project?.status == this.state.newStatus
                          }
                        >
                          {I18n.get("Alterar")}
                        </Button>
                      </p>
                    </div>
                  </div>
                </Col>

                {project?.projectType == 0 && ( //Alterar comissao somente criativos (proposal/bpitch tem outro flow)
                  <Col sm={6}>
                    <div className="card uplift ">
                      <div className="card-content break-word">
                        <h3 style={{ fontSize: 12 }}>{I18n.get("Comissão")}</h3>
                        <InputNumber
                          disabled={project?.projectType == 2}
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace("%", "")}
                          min={0}
                          max={100}
                          onChange={(e) => this.handleCommission(e)}
                          value={
                            (this.state?.newPerc &&
                              this.state?.newPerc.toString()) ||
                            0
                          }
                          addonAfter="%"
                        />
                        <br />
                        <p className="btn-submit" style={{ marginTop: "7px" }}>
                          <Button
                            loading={this.state?.task?.busy}
                            onClick={() => this.sendCommission()}
                            disabled={
                              this.state.newPerc ==
                                project?.bpCommission?.perc ||
                              project?.projectType == 2
                            }
                            className="button bt-bpool black pull-right"
                          >
                            {I18n.get("Alterar")}
                          </Button>
                        </p>
                      </div>
                    </div>
                  </Col>
                )}

                <Col sm={6}>
                  <div className="card uplift ">
                    <div className="card-content break-word">
                      <h3 style={{ fontSize: 12 }}>
                        {I18n.get("Responsável Cliente")}
                      </h3>

                      <Select
                        onSelect={(value) => this.handleClient(value)}
                        defaultValue={project?.createdByUsername}
                        style={{ width: "100%" }}
                      >
                        {this.props?.bp?.responsablesClient?.map(
                          (item, index) => {
                            return (
                              <Option
                                disabled={!item.enabled}
                                key={index}
                                value={item.code}
                              >
                                {item.label}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                      <p className="btn-submit" style={{ marginTop: 10 }}>
                        <Button
                          loading={this.state?.task?.busy}
                          onClick={() => this.sendClient()}
                          className="button bt-bpool black pull-right"
                          disabled={
                            !this.state.newRespClient ||
                            this.state.newRespClient ==
                              project?.createdByUsername
                          }
                        >
                          {I18n.get("Alterar")}
                        </Button>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="card uplift ">
                    <div className="card-content break-word">
                      <h3 style={{ fontSize: 12 }}>
                        {I18n.get("Responsável Parceiro")}
                      </h3>

                      <Select
                        disabled={!project.partnerId || project.status < 5}
                        onSelect={(value) => this.handlePartner(value)}
                        defaultValue={
                          project?.partnerResponsibleUsername ||
                          this.props.partnerResponsibleUsername
                        }
                        style={{ width: "100%" }}
                      >
                        {this.props?.bp?.responsablesPartner?.map(
                          (item, index) => {
                            return (
                              <Option
                                disabled={!item.enabled}
                                key={index}
                                value={item.code}
                              >
                                {item.label}
                              </Option>
                            );
                          }
                        )}
                      </Select>

                      <p className="btn-submit" style={{ marginTop: 10 }}>
                        <Button
                          loading={this.state?.task?.busy}
                          onClick={() => this.sendPartner()}
                          className="button bt-bpool black pull-right"
                          disabled={
                            !this.state.newRespPartner ||
                            this.state.newRespPartner ==
                              project?.partnerResponsibleUsername
                          }
                        >
                          {I18n.get("Alterar")}
                        </Button>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <br />
            </div>
          )}

        <Row>
          <Col sm={24}>
            <h1 className="title-bpool medium">{I18n.get("Informações")}</h1>
            <div>
              <Col sm={6}>
                {project && (
                  <p className="pbtn-avaliacao">
                    <Button
                      loading={this.state?.task?.busy}
                      onClick={() => this.refreshProjectCurrency(project.id)}
                      className="button bt-bpool black"
                    >
                      Refresh Projeto
                    </Button>
                  </p>
                )}
              </Col>
              <Col>
                {project && project.review && project.status == 20 && (
                  <p className="pbtn-avaliacao">
                    <a
                      onClick={this.showDrawer}
                      className="button is-dark btn-open-avaliacao"
                    >
                      {I18n.get("Ver avaliações")}
                    </a>
                  </p>
                )}
              </Col>
            </div>

            <ReviewStep1 {...this.props} />

            <br />
            <br />

            {this.props.projectType === 0 && (
              <>
                <h1 className="title-bpool medium">
                  {I18n.get("Escopo Criativo")}
                </h1>

                <div className="reviewed-media uplift-review">
                  <div className="card-box" style={{ width: "50%" }}>
                    <div className="card-row">
                      <div className="card">
                        <div className="card-content" style={{ width: "100%" }}>
                          <AssetBundle
                            disabledEdit={true}
                            isClient={true}
                            listOfAssets={
                              this.props.bundle &&
                              this.props.bundle.variations &&
                              this.props.bundle.variations[0].assets &&
                              this.props.bundle.variations[0].assets?.length
                                ? this.props.bundle.variations[0].assets
                                : []
                            }
                            dataValues={objDataValuesCreativeScope}
                            isModalConfirm={true}
                            descriptiveHourMan={this.props?.descriptiveHourMan}
                            isCart
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <AssetBundle
                    {...this.props.bundle}
                    {...this.props}
                    bp={this.props.bp}
                    selected={this.getSelected()}
                    isExpanded={true}
                    IsAdmin={true}
                    IsClient={this.props.auth.is_client_or_client_user}
                    IsPartner={this.props.auth.is_partner}
                    PricePartner={this.props.pricePartner}
                    PricePartnerTax={this.props.pricePartnerTax}
                    PricePartnerOtherTax={this.props.pricePartnerOtherTax}
                    PriceClientWithDiscount={this.props.priceClientWithDiscount}
                    PriceClientWithDiscountTax={
                      this.props.priceClientWithDiscountTax
                    }
                    PriceClientWithDiscountOtherTax={
                      this.props.priceClientWithDiscountOtherTax
                    }
                    PriceBPool={this.props.priceBPoolComission}
                    PriceBPoolTax={this.props.priceBPoolComissionTax}
                    PriceBPoolOtherTax={this.props.priceBPoolComissionOtherTax}
                    currency={this.props.currency}
                    assetTax={this.props.assetTax || 0}
                    projectStatus={this.props.status}
                    projectAssets={project?.bundle?.variations}
                  /> */}
                </div>

                <br />
                <br />
              </>
            )}

            {(this.props.demand ||
              (this.props.demandFiles &&
                this.props.demandFiles.length > 0)) && (
              <div>
                <br />
                <br />
                <h1 className="title-bpool medium">
                  {I18n.get("Demanda geral")}
                </h1>

                <div className="card-box">
                  <div className="card-row">
                    <div className="card">
                      <div className="card-content" style={{ width: "100%" }}>
                        {this.props.demand && <p>{this.props.demand}</p>}

                        {this.props.demandFiles &&
                          this.props.demandFiles.length > 0 && (
                            <div style={{ marginTop: "20px" }}>
                              <p>
                                <strong>{I18n.get("Arquivos:")}</strong>
                              </p>

                              <div style={{ padding: "20px 15px" }}>
                                {this.props.demandFiles &&
                                  this.props.demandFiles.map((item, index) => (
                                    <div
                                      key={index}
                                      class="columns columns itemFile line"
                                    >
                                      <div class="column is-clearfix upload-item">
                                        <a href={item.url} target="_blank">
                                          <Icon
                                            type="file-text"
                                            theme="filled"
                                          />{" "}
                                          &nbsp; {item.name}
                                        </a>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.props.projectType === 0 && (
              <>
                <br />
                <br />
                <h1 className="title-bpool medium">{I18n.get("Arquivos")}</h1>

                <ReviewStepFiles {...this.props} />

                <br />
                <br />
              </>
            )}

            <h1 className="title-bpool medium">{I18n.get("Detalhes")}</h1>

            <div className="uplift">
              <ReviewStep4 {...this.props} />
            </div>

            <br />
            <br />

            <h1 className="title-bpool medium">{I18n.get("Cliente")}</h1>

            <ReviewStep5Client {...this.props} />

            <br />
            <br />

            <h1 className="title-bpool medium">{I18n.get("Parceiro")}</h1>

            <ReviewStep5 {...this.props} />

            <br />
            <br />

            <h1 className="title-bpool medium">{I18n.get("Briefing")}</h1>

            <div className="client-step client-step-1 project-ongoing">
              {project && project.isCustomEvent && (
                <>
                  <EventDetails {...this.props} />
                </>
              )}

              {project && project.isBPitch && (
                <>
                  <BPitchDetails {...this.props} />
                </>
              )}

              {project && project.isCreative && (
                <>
                  <div>
                    {!briefing && (
                      <div className="card project-ongoing-review-card">
                        <div className="card-content">
                          <div className="content">
                            <p>{I18n.get("Aguardando briefing...")}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {briefing && (
                      <Collapse
                        onChange={(key) =>
                          this.setState({ briefingPanel: key })
                        }
                      >
                        <Panel
                          header={
                            this.state.briefingPanel == "0"
                              ? I18n.get("Retrair")
                              : I18n.get("Expandir")
                          }
                        >
                          {/* {file &&
                                  <p className="fileBriefing">
                                      <strong>{I18n.get("Arquivo:")}</strong> <br /><a href={file.url}  download><Icon type="file-text" theme="filled" /> {file.name}</a>
                                  </p>
                              } */}
                          <br />
                          <WYSIWYGEditor
                            content={briefing.plaintext}
                            readOnly={true}
                          />
                        </Panel>
                      </Collapse>
                    )}
                  </div>
                </>
              )}
            </div>

            <br />
            <br />

            <div className="project-definition">
              <h1 className="title-bpool medium">{I18n.get("Cronograma")}</h1>

              <Collapse
                onChange={(key) => this.setState({ schedulePanel: key })}
              >
                <Panel
                  header={
                    this.state.schedulePanel == "0"
                      ? I18n.get("Retrair")
                      : I18n.get("Expandir")
                  }
                >
                  <MilestoneList
                    loading={{}}
                    isAdmin={true}
                    milestones={milestones}
                    canDelete={false}
                  />
                </Panel>
              </Collapse>
            </div>

            <br />
            <br />

            {this.props.isPartnerSelectionBidding && (
              <>
                <div className="project-definition">
                  <h1 className="title-bpool medium">{I18n.get("Proposta")}</h1>

                  <Collapse
                    onChange={(key) => this.setState({ quotationPanel: key })}
                  >
                    <Panel
                      header={
                        this.state.quotationPanel == "0"
                          ? I18n.get("Retrair")
                          : I18n.get("Expandir")
                      }
                    >
                      <div style={{ padding: "1rem" }}>
                        {quotation && quotation.id && (
                          <QuotationForm
                            {...this.props}
                            quotation={quotation}
                            task={{}}
                            suppressSchedule={true}
                          />
                        )}
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </>
            )}

            <br />
            <br />

            <div className="project-definition">
              <h1 className="title-bpool medium">{I18n.get("Extras")}</h1>

              <Collapse onChange={(key) => this.setState({ extrasPanel: key })}>
                <Panel
                  header={
                    this.state.extrasPanel == "0"
                      ? I18n.get("Retrair")
                      : I18n.get("Expandir")
                  }
                >
                  <ExtraList
                    {...this.props}
                    isAdmin={true}
                    extras={project && project.extras}
                  />
                </Panel>
              </Collapse>
            </div>

            <br />
            <br />

            <div className="project-definition">
              <h1 className="title-bpool medium">
                {I18n.get("Matching")}
                <Button
                  loading={this.state?.task?.busy}
                  onClick={() => this.refreshProject(project.id)}
                  className="button bt-bpool black"
                >
                  Refresh
                </Button>
              </h1>

              <Collapse
                onChange={(key) => {
                  this.setState({ matchingPanel: key });
                  this.showCandidates();
                }}
              >
                <Panel
                  header={
                    this.state.matchingPanel == "0"
                      ? I18n.get("Retrair")
                      : I18n.get("Expandir")
                  }
                >
                  <div className="tile is-ancestor has-text-centered">
                    <div className="tile is-parent">
                      <article className="tile is-child box">
                        <p className="title">
                          {this.props.bp.possibleMatches &&
                            this.props.bp.possibleMatches.totalMatched}
                        </p>
                        <p className="title-bpool medium">
                          {I18n.get("Algoritmo")}
                        </p>
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child box">
                        <p className="title">
                          {this.props.bp.possibleMatches &&
                            this.props.bp.possibleMatches.totalGlobal}
                        </p>
                        <p className="title-bpool medium">
                          {I18n.get("Total")}
                        </p>
                      </article>
                    </div>
                  </div>

                  {/* SOS RS  */}
                  <SosRSAdmin visible={this.props?.sosrs} />

                  <div className="box">
                    <pre>
                      {this.props.bp?.possibleMatches?.analyser &&
                        this.props.bp?.possibleMatches?.analyser}
                    </pre>
                  </div>

                  <br />
                  <br />

                  {!this.props.bp.candidates && (
                    <p className="animate-loading">
                      <Icon type="loading" />
                    </p>
                  )}
                  {this.props.bp.candidates && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <div>
                          {I18n.get("Parceiros selecionados")}:{" "}
                          {ListConfim && ListConfim.length}
                        </div>

                        <div style={{ marginLeft: "1rem" }}>
                          <Button
                            loading={this.state?.preMatch?.busy}
                            onClick={() => this.firePreMatch(project.id)}
                            className="button bt-bpool black"
                            disabled={!(ListConfim && ListConfim.length > 0)}
                          >
                            Disparar Convites
                          </Button>
                        </div>
                      </div>

                      <div className="columns is-multiline">
                        {ListOrder &&
                          ListOrder.map((item, index) => {
                            const clean2 = item.reason.replace(
                              /(?:\r\n|\r|\n)/g,
                              ""
                            );

                            let clean3 = clean2.split(",");

                            const reasonItens = clean3;

                            const selecionada =
                              project && project.partnerId
                                ? project.partnerId
                                : null;

                            const isConfirmed =
                              this.props.partnerIds.indexOf(item.partnerId) >
                              -1;
                            const partner =
                              this.props.bp.candidates &&
                              this.props.bp.candidates.find(
                                (c) => c.id == item.partnerId
                              );

                            const inviteInfos =
                              partner &&
                              partner?.projectInvitations &&
                              partner?.projectInvitations.filter(
                                (partnerIn) =>
                                  partnerIn.projectId == this.props.projectId
                              )[0];

                            const i = this.props.partnerIds.indexOf(
                              item.partnerId
                            );

                            let y = [];
                            let w = [];

                            if (reasonsok && reasonsok.length > 0) {
                              if (item.partnerId == reasonsok[0].partnerId) {
                                if (
                                  reasonsok[0].noReason &&
                                  reasonsok[0].noReason == true
                                ) {
                                  y.push("Cliente não justificou");
                                } else {
                                  if (
                                    reasonsok[0].reason1 &&
                                    reasonsok[0].reason1.length
                                  ) {
                                    const reason1 =
                                      selectedReasons &&
                                      selectedReasons.find(
                                        (xx) => xx.code == reasonsok[0].reason1
                                      );

                                    y.push(reason1 && reason1.label);
                                  }
                                  if (
                                    reasonsok[0].reason2 &&
                                    reasonsok[0].reason2.length
                                  ) {
                                    const reason2 =
                                      selectedReasons &&
                                      selectedReasons.find(
                                        (xx) => xx.code == reasonsok[0].reason2
                                      );

                                    y.push(reason2 && reason2.label);
                                  }
                                  if (
                                    reasonsok[0].reason3 &&
                                    reasonsok[0].reason3.length
                                  ) {
                                    const reason3 =
                                      selectedReasons &&
                                      selectedReasons.find(
                                        (xx) => xx.code == reasonsok[0].reason3
                                      );

                                    y.push(reason3 && reason3.label);
                                  }
                                  if (
                                    reasonsok[0].reason4 &&
                                    reasonsok[0].reason4.length
                                  ) {
                                    const reason4 =
                                      selectedReasons &&
                                      selectedReasons.find(
                                        (xx) => xx.code == reasonsok[0].reason4
                                      );

                                    y.push(reason4 && reason4.label);
                                  }
                                  if (
                                    reasonsok[0].otherReason &&
                                    reasonsok[0].otherReason
                                  ) {
                                    y.push(reasonsok[0].otherReason);
                                  }
                                }
                              }
                            }

                            if (reasonsnot && reasonsnot.length > 0) {
                              if (reasonsnot && reasonsnot.length) {
                                for (
                                  var rnot = 0;
                                  rnot < reasonsnot.length;
                                  rnot++
                                ) {
                                  if (
                                    reasonsnot[rnot] &&
                                    reasonsnot[rnot].partnerId == item.partnerId
                                  ) {
                                    if (
                                      reasonsnot[rnot].noReason &&
                                      reasonsnot[rnot].noReason == true
                                    ) {
                                      w.push("Cliente não justificou");
                                    } else {
                                      if (
                                        reasonsnot[rnot].reason1 &&
                                        reasonsnot[rnot].reason1.length
                                      ) {
                                        const reason1 =
                                          clientProjectPartnerNotSelectedReasons &&
                                          clientProjectPartnerNotSelectedReasons.find(
                                            (xx) =>
                                              xx.code ==
                                              reasonsnot[rnot].reason1
                                          );

                                        w.push(reason1 && reason1.label);
                                      }
                                      if (
                                        reasonsnot[rnot].reason2 &&
                                        reasonsnot[rnot].reason2.length
                                      ) {
                                        const reason2 =
                                          clientProjectPartnerNotSelectedReasons &&
                                          clientProjectPartnerNotSelectedReasons.find(
                                            (xx) =>
                                              xx.code ==
                                              reasonsnot[rnot].reason2
                                          );

                                        w.push(reason2 && reason2.label);
                                      }
                                      if (
                                        reasonsnot[rnot].reason3 &&
                                        reasonsnot[rnot].reason3.length
                                      ) {
                                        const reason3 =
                                          clientProjectPartnerNotSelectedReasons &&
                                          clientProjectPartnerNotSelectedReasons.find(
                                            (xx) =>
                                              xx.code ==
                                              reasonsnot[rnot].reason3
                                          );

                                        w.push(reason3 && reason3.label);
                                      }
                                      if (
                                        reasonsnot[rnot].reason4 &&
                                        reasonsnot[rnot].reason4.length
                                      ) {
                                        const reason4 =
                                          clientProjectPartnerNotSelectedReasons &&
                                          clientProjectPartnerNotSelectedReasons.find(
                                            (xx) =>
                                              xx.code ==
                                              reasonsnot[rnot].reason4
                                          );

                                        w.push(reason4 && reason4.label);
                                      }
                                      if (
                                        reasonsnot[rnot].otherReason &&
                                        reasonsnot[rnot].otherReason
                                      ) {
                                        w.push(reasonsnot[rnot].otherReason);
                                      }
                                    }
                                  }
                                }
                              }
                            }

                            return (
                              <div
                                data-partner-email={partner?.email}
                                key={index}
                                className="column is-4 is-multiple"
                              >
                                <div
                                  className="has-text-centered"
                                  style={{ padding: "0.5rem" }}
                                >
                                  {this.props.partnerIds.indexOf(
                                    item.partnerId
                                  ) != -1 && (
                                    <Popconfirm
                                      title={I18n.get(
                                        "Gostaria de excluir o convite desse parceiro"
                                      )}
                                      onConfirm={() =>
                                        this.cancelInvite(item.partnerId)
                                      }
                                      okText={I18n.get("SIM")}
                                      cancelText={I18n.get("NÃO")}
                                    >
                                      <Tooltip
                                        title={I18n.get(
                                          "Excluir convite do parceiro"
                                        )}
                                      >
                                        <a
                                          disabled={project.status > 5}
                                          className="info-invite-icon"
                                        >
                                          <Icon type="delete" theme="filled" />
                                        </a>
                                      </Tooltip>
                                    </Popconfirm>
                                  )}
                                  {this.props.partnerIds.indexOf(
                                    item.partnerId
                                  ) != -1 && (
                                    <span
                                      data-tip=""
                                      data-for={`invite-parceiro-${index}`}
                                      className="info-invite-icon"
                                    >
                                      <i className="fas fas fa-envelope"></i>

                                      <ReactTooltip
                                        id={`invite-parceiro-${index}`}
                                      >
                                        <div className="content-tip-reason content-parceiro">
                                          <h4>{I18n.get("Convite")}</h4>

                                          <p>
                                            <strong>
                                              {I18n.get("Enviado:")}
                                            </strong>{" "}
                                            {inviteInfos &&
                                              inviteInfos.createdOnFmt}
                                          </p>

                                          {inviteInfos &&
                                            inviteInfos.accepted != null && (
                                              <div>
                                                <p>
                                                  <strong>
                                                    {I18n.get("Respondido:")}
                                                  </strong>{" "}
                                                  {inviteInfos.updatedOnFmt}
                                                </p>
                                                <p>
                                                  <strong>
                                                    {I18n.get("Aceito?:")}
                                                  </strong>{" "}
                                                  {inviteInfos.accepted
                                                    ? "SIM"
                                                    : "Não"}
                                                </p>
                                                {!inviteInfos.accepted && (
                                                  <p>
                                                    <strong>
                                                      {I18n.get("Motivo:")}
                                                    </strong>{" "}
                                                    {inviteInfos.reason}
                                                  </p>
                                                )}
                                              </div>
                                            )}
                                        </div>
                                      </ReactTooltip>
                                    </span>
                                  )}
                                  {item.isMatch && (
                                    <span class="tag is-success">
                                      {I18n.get("MATCH")}
                                    </span>
                                  )}
                                  {!item.isMatch && (
                                    <span class="tag is-danger">
                                      {I18n.get("NOT MATCH")}
                                    </span>
                                  )}
                                  &nbsp;{" "}
                                  <span className="is-size-5">
                                    #{item.rank}
                                  </span>
                                  <span
                                    data-tip=""
                                    data-for={`reason-parceiro-${index}`}
                                    className="info-admin-icon"
                                  >
                                    <i className="fas fa-question-circle"></i>

                                    <ReactTooltip
                                      id={`reason-parceiro-${index}`}
                                    >
                                      <div className="content-tip-reason content-parceiro">
                                        <h4>{I18n.get("Justificativa 2")}</h4>

                                        {reasonItens &&
                                          reasonItens.map((itemR) => {
                                            return <p>{itemR}</p>;
                                          })}
                                      </div>
                                    </ReactTooltip>
                                  </span>
                                  &nbsp;{" "}
                                  <span className="is-size-5">
                                    #{item.score}
                                  </span>
                                  <span
                                    data-tip=""
                                    data-for={`reason-parceiro-${index}-2`}
                                    className="info-admin-icon"
                                  >
                                    <i className="fas fa-plus-circle"></i>

                                    <ReactTooltip
                                      id={`reason-parceiro-${index}-2`}
                                    >
                                      <div className="content-tip-reason content-parceiro">
                                        <h4>{I18n.get("Score Analyser")}</h4>

                                        {item.scoreAnalyser
                                          ?.split("\n")
                                          .map((itemR) => {
                                            return <p>{itemR}</p>;
                                          })}
                                      </div>
                                    </ReactTooltip>
                                  </span>
                                  {selecionada &&
                                    selecionada == item?.partnerId && (
                                      <span
                                        className="icon-reasonso ok"
                                        data-tip=""
                                        data-for="reason-success"
                                      >
                                        <i className="fas fa-thumbs-up"></i>
                                        <ReactTooltip id="reason-success">
                                          <div className="content-tip-reason">
                                            <h4>
                                              {I18n.get("Motivos da escolha")}
                                            </h4>
                                            <ul>
                                              {y.map((item) => {
                                                return <li>{item}</li>;
                                              })}
                                            </ul>
                                          </div>
                                        </ReactTooltip>
                                      </span>
                                    )}
                                  {reasonsnot &&
                                    reasonsnot.length > 0 &&
                                    isConfirmed &&
                                    item.partnerId != selecionada &&
                                    inviteInfos &&
                                    inviteInfos.accepted && (
                                      <span
                                        className="icon-reasonso not"
                                        data-tip=""
                                        data-for={`reason-error-${index}`}
                                      >
                                        <i className="fas fa-thumbs-up"></i>
                                        <ReactTooltip
                                          id={`reason-error-${index}`}
                                        >
                                          <div className="content-tip-reason">
                                            <h4>
                                              {I18n.get("Motivos da recusa")}
                                            </h4>
                                            <ul>
                                              {w.map((item) => {
                                                return <li>{item}</li>;
                                              })}
                                            </ul>
                                          </div>
                                        </ReactTooltip>
                                      </span>
                                    )}
                                </div>

                                <PartnerCard
                                  naoSelecionado={
                                    reasonsnot &&
                                    reasonsnot.length > 0 &&
                                    isConfirmed &&
                                    item.partnerId != selecionada &&
                                    inviteInfos
                                  }
                                  naoAceitou={
                                    reasonsnot &&
                                    reasonsnot.length > 0 &&
                                    isConfirmed &&
                                    item.partnerId != selecionada &&
                                    inviteInfos &&
                                    !inviteInfos.accepted
                                  }
                                  selecionada={selecionada}
                                  key={index}
                                  {...partner}
                                  isPreview={true}
                                  showDetails={this.showDetails.bind(
                                    this,
                                    partner
                                  )}
                                  isAdmin={true}
                                  reasonsnot={w}
                                  reasonsnok={y}
                                  // projectInvite={partner?.statistics?.projectInvite}
                                  // projectStatus={partner?.statistics?.projectStatus}
                                />

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {!isConfirmed && (
                                    <SubmitButtonField
                                      label={I18n.get("Selecionar Parceiro")}
                                      className="button is-dark is-fullwidth"
                                      onClick={this.addMatch.bind(
                                        this,
                                        item.partnerId
                                      )}
                                      task={this.state.task}
                                    />
                                  )}

                                  {isConfirmed && (
                                    <div style={{ padding: "1rem" }}>
                                      <span className="tag is-medium">
                                        {I18n.get("SELECIONADO")}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}
                </Panel>
              </Collapse>
            </div>

            <br />
            <br />

            <div className="project-definition">
              <h1 className="title-bpool medium">{I18n.get("Configuração")}</h1>

              <Collapse onChange={(key) => this.setState({ extrasPanel: key })}>
                <Panel
                  header={
                    this.state.extrasPanel == "0"
                      ? I18n.get("Retrair")
                      : I18n.get("Expandir")
                  }
                >
                  <div style={{ padding: "1rem" }}>
                    <table
                      className="table"
                      style={{ border: "none", width: "700px" }}
                    >
                      <tbody>
                        <tr>
                          <th>{I18n.get("Fase")}</th>
                          <th>{I18n.get("Parâmetro")}</th>
                          <th>{I18n.get("Valor")}</th>
                        </tr>
                        <tr>
                          <td>{I18n.get("Seleção de Parceiro")}</td>
                          <td>{I18n.get("Número máximo de convites")}</td>
                          <td>
                            {project &&
                              project.settings &&
                              project.settings.biddingPhaseMaxInvites}
                          </td>
                        </tr>
                        <tr>
                          <td>{I18n.get("Definições de Projeto")}</td>
                          <td>
                            {I18n.get("Revisão BPool Item a Item na Proposta")}
                          </td>
                          <td>
                            {project &&
                              project.settings &&
                              project.settings
                                .enabledBPoolQuotationItemReview === true && (
                                <span>{I18n.get("Habilitado")}</span>
                              )}
                            {project &&
                              project.settings &&
                              project.settings
                                .enabledBPoolQuotationItemReview === false && (
                                <span>{I18n.get("Desabilitado")}</span>
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td>{I18n.get("Definições de Projeto")}</td>
                          <td>
                            {I18n.get("Tipo de Seções Permitidas na Proposta")}
                          </td>
                          <td>
                            {project &&
                              project.settings &&
                              project.settings
                                .enabledUniversalQuotationSectionTypes &&
                              Object.keys(
                                project.settings
                                  .enabledUniversalQuotationSectionTypes
                              ).map((item) => {
                                return (
                                  <div>
                                    {" "}
                                    <u>
                                      {
                                        ClientProjectUniversalQuotationSectionTypeNames[
                                          item
                                        ]
                                      }
                                    </u>
                                    :{" "}
                                    {project.settings
                                      .enabledUniversalQuotationSectionTypes[
                                      item
                                    ] ? (
                                      <span>{I18n.get("Habilitado")}</span>
                                    ) : (
                                      <span>{I18n.get("Desabilitado")}</span>
                                    )}
                                  </div>
                                );
                              })}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p style={{ padding: "1rem" }}>
                    <a
                      onClick={(e) => this.setState({ editSettings: true })}
                      className="button bt-bpool black"
                    >
                      {I18n.get("Editar Configuração")}
                    </a>
                  </p>
                </Panel>
              </Collapse>
            </div>
          </Col>
        </Row>

        <br />
        <br />

        <NakedModal
          isOpen={!!this.state.detailsView}
          wide={true}
          onCloseModal={this.hideDetails.bind(this)}
        >
          <div className="has-background-white">
            {this.state.detailsView && (
              <PartnerFullCard {...this.state.detailsView} isAdminView={true} />
            )}
          </div>
        </NakedModal>

        <Drawer
          title={I18n.get("Avaliações")}
          placement="right"
          closable={true}
          onClose={this.onClose}
          destroyOnClose={true}
          visible={this.state.visible}
          width="40%"
        >
          {project && project.review && (
            <div className="item-rating">
              <p className="title-item">
                {I18n.get("Comentário sobre o parceiro:")}
              </p>
              <p>{project.review.partnerReview}</p>
            </div>
          )}

          {project &&
            project.review &&
            project.review.ratings &&
            project.review.ratings.map((item, index) => {
              return (
                <div className="item-rating" key={index}>
                  <p className="title-item">{item.label}</p>
                  <p>
                    <Rate disabled defaultValue={item.value} />
                  </p>
                </div>
              );
            })}

          <div className="item-rating">
            <p className="title-item">{I18n.get("Indicação da BPool")}</p>
            <p>
              <Rate
                disabled
                defaultValue={
                  project && project.review && project.review.recommendBP
                }
              />
            </p>
          </div>

          <div className="item-rating">
            <p className="title-item">{I18n.get("Recomenda o parceiro?")}</p>
            <p>
              <Rate
                disabled
                defaultValue={
                  project && project.review && project.review.recommendPartner2
                }
              />
            </p>
          </div>
        </Drawer>

        <Modal
          visible={this.state.editSettings}
          footer={null}
          onCancel={(e) => this.setState({ editSettings: null })}
          width={900}
          title={I18n.get("Editar Configuração")}
        >
          <SettingsForm
            {...this.props}
            settings={project && project.settings}
            onClose={(e) => this.setState({ editSettings: null })}
          />
        </Modal>
      </div>
    );
  }
}
