import React from 'react';

import { I18n } from 'aws-amplify';

import moment from 'moment';

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';

import TextField from '../../form/text-field';
import TextAreaField from '../../form/textarea-field';
import SubmitButtonField from '../../form/submit-button-field';

class QuotationItemCommentForm extends React.Component {
    constructor(props) {

        super(props);



        this.state = {
            fields: {
                new_comment: {
                    label: I18n.get("Nova mensagem"),
                    onChange: this.onChangeForm.bind(this, 'new_comment'),
                    successIcon: false,
                    visited: true
                },
            },
            form: {},
            errors: {},
            submit_task: {},
        };
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            quotationId: this.props.quotation.id,
            ...this.props.item,
            body: this.state.form.new_comment
        };

        return data;
    }

    submitForm(e) {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.add_quotation_item_comment(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

                if (task.result) {

                    this.setState(prevState => ({
                        form: { ...prevState.form, new_comment: '' },
                    }));

                }
            });
    }

    onDeleteComment(commentId, e) {



        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            quotationId: this.props.quotation.id,
            id: this.props.item.id,
            commentId: commentId,
        };

        this.props.del_quotation_item_comment(data)
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

                if (task.result) {

                    this.setState(prevState => ({
                        form: { ...prevState.form, new_comment: '' },
                    }));

                }
            });
    }

    render() {


        return (
            <div>

                <a onClick={this.props.onClose.bind(this)} className="is-pulled-right">
                    <span className="icon"><i className="fas fa-times" aria-hidden="true"></i></span>
                </a>

                <p className="has-text-weight-bold">{I18n.get("Comentários")}</p>

                <br />

                {this.props.item.comments && this.props.item.comments.map((item) => {

                    const isAuthor = this.props.auth.username == item.createdByUsername;

                    return <>
                        <div className="dropdown-item">
                            <p className="has-text-left" style={{ flex: '1 1 0%', padding: '0.6rem', backgroundColor: '#f5f5f5', borderRadius: '0.25rem', fontSize: '90%' }}>

                                {isAuthor && <a onClick={this.onDeleteComment.bind(this, item.id)} class="delete is-small is-pulled-right" title={I18n.get("Excluir comentário")}></a>}

                                {item.body}

                            </p>

                            <p className="has-text-right" style={{ fontSize: '75%' }}>
                                {isAuthor && <span>{this.props.auth.name} &middot;</span>}  {item.createdOn}
                            </p>
                        </div>
                        {/* <hr className="dropdown-divider" /> */}
                    </>;
                })}

                <br />

                <form onSubmit={this.submitForm.bind(this)}>

                    <TextAreaField {...this.state.fields.new_comment} value={this.state.form.new_comment} error={this.state.errors.new_comment} />

                    <SubmitButtonField
                        label={I18n.get("Comentar")}
                        className="button is-black is-rounded is-pulled-right"
                        onClick={this.submitForm.bind(this)}
                        task={this.state.submit_task}
                    />

                    <br /><br />

                </form>

            </div>
        );
    }
}

export default QuotationItemCommentForm;
