import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { I18n } from "aws-amplify";
import * as S from "./styles";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import { useProjects } from "../../../../hooks/useProjects/useProjects.hook";
import { useReview } from "../../../../hooks/useReview/useReview.hook";
import { SelectOne } from "../../../../components/UI/SelectOne";
import Content from "../../../../components/UI/Content";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { AssetsCart } from "../../../../components/UI/AssetBundle/components/AssetsCart";
import { H2Border, H3Border } from "../../../../components/UI/Titles";
import Loading from "../../../../components/UI/Loading";
import StepsCreateProject from "../components/StepsCreateProject";
import Main from "../components/Main";
import TopInformation from "../components/TopInformation";
import { PROJECTS_API } from "../../../../store/actions/projects";
import { Row, Col } from "antd";

export const CreateProjectStep2 = ({ custom }) => {
  const state = useSelector((state) => state);
  const { getAssetsStep2, addAssetToProject } = useProjects();
  const { getAssetsBundleFilters } = useReview();
  const [projectDetails, setProjectDetails] = useState({});
  const [assetsStep, setAssetsStep] = useState({});
  const [assets, setAssets] = useState({});
  const [filters, setFilters] = useState({
    spec: "",
    type: "",
  });
  const [loadingAssetsList, setLoadingAssetsList] = useState(false);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [assetsCart, setAssetsCart] = useState([]);
  const [cartTotals, setCartTotals] = useState({
    priceClientWithDiscountTotalFmt: "",
    priceClientWithDiscountFmt: "",
    priceBPoolComissionFmt: "",
  });
  const [listOfAssets, setListOfAssets] = useState([]);
  const [listDataIncludedItems, setListDataIncludedItems] = useState([]);
  const [listFilterSelect, setListFilterSelect] = useState([]);
  const [listFilterSelectTypes, setListFilterSelectTypes] = useState([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState([]);
  const [filterSelectValue, setFilterSelectValue] = useState("");
  const [filterSelectTypeValue, setFilterSelectTypeValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  let history = useHistory();

  let params = useParams();

  const assetTypeMap = {};

  const [isSending, setIsSending] = useState(false);
  const [isDisabledSave, setIsDisabledSave] = useState(true);

  const loadListFiltersSelect = async () => {
    setIsLoadingFilters(true);
    const response = await getAssetsBundleFilters();

    if (response?.success) {
      if (!listFilterSelect?.length) {
        setListFilterSelect([
          { code: "all", label: I18n.get("Todos") },
          ...response?.data?.specialisms.sort(
            (a, b) => (a.label > b.label) - (a.label < b.label)
          ),
        ]);
      }
      if (!listFilterSelectTypes?.length) {
        setListFilterSelectTypes([
          { code: "all", label: I18n.get("Todos") },
          ...response?.data?.assetsTypes.sort(
            (a, b) => (a.label > b.label) - (a.label < b.label)
          ),
        ]);
      }
    }

    setIsLoadingFilters(false);
  };

  async function getAssets(data) {
    setLoadingAssetsList(true);
    await PROJECTS_API.get_assets(data).then((response) => {
      setAssets(response.data);
    });
    setLoadingAssetsList(false);
  }

  const getStep = () => {
    function getProject() {
      const data = {
        projectId: params.projectId,
      };
      PROJECTS_API.get_project_step2(data).then((response) => {
        setProjectDetails(response?.payload?.project?.data);

        setLoadingAssets(false);
      });
    }
    getProject();
  };

  const getStepInit = async () => {
    const { data } = await getAssetsStep2(params.projectId);

    const assets = data?.bundle?.variations[0]?.assets || [];
    setListOfAssets(data?.bundle ? data?.bundle?.variations[0]?.assets : []);
    const assetsWithId = assets.map((itemAsset, index) => ({
      ...itemAsset,
      idInternal: `${index}-${itemAsset.asset}`,
    }));

    setCartTotals({
      priceClientWithDiscountTotalFmt: data.priceClientWithDiscountTotalFmt,
      priceClientWithDiscountFmt: data.priceClientWithDiscountFmt,
      priceBPoolComissionFmt: data.priceBPoolComissionFmt,
      priceClientWithDiscountTaxFmt: data?.priceClientWithDiscountTaxFmt,
      priceClientWithDiscountWithOtherTaxFmt:
        data?.priceClientWithDiscountWithOtherTaxFmt,
    });
    setAssetsCart(assetsWithId);
  };

  const onChangeNew = (e, field) => {
    setFilters({
      ...filters,
      [field]: e,
    });
  };

  // assets new

  const handleSave = async () => {
    setIsSending(true);
    setIsDisabledSave(true);
    const dataSend = listDataIncludedItems?.map((item) => ({
      code: item?.asset ? item?.asset : item?.code,
      quantity: item?.quantity ? Number(item?.quantity) : 1,
    }));

    let data = {
      projectId: params.projectId,
      assets: dataSend,
    };

    const response = await addAssetToProject({ assets: data });
    if (response?.success) {
      history.push(`/cliente/projetos/v2/demandas/${params.projectId}`);
    }
    setIsSending(false);
  };

  const handleListDataIncludedItems = (list) => {
    setListDataIncludedItems(list);
  };

  const handleChanged = (hasChanged) => {
    setIsDisabledSave(!hasChanged);
  };

  useEffect(() => {
    setListDataIncludedItems(listOfAssets);
  }, [listOfAssets]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getStep();
    getStepInit();
    loadListFiltersSelect();
  }, []);

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 9999,
      projectId: params.projectId,
      spec: filterSelectValue === "all" ? "" : filterSelectValue,
      type: filterSelectTypeValue === "all" ? "" : filterSelectTypeValue,
      search: searchTerm,
    };

    setFilters({
      spec: filterSelectValue === "all" ? "" : filterSelectValue,
      type: filterSelectTypeValue === "all" ? "" : filterSelectTypeValue,
    });

    getAssets(data);
    getStep();
  }, [filterSelectValue, filterSelectTypeValue]);

  useEffect(() => {
    function getAssetsStep() {
      const data = {
        projectId: params.projectId,
      };
      PROJECTS_API.get_assets_types(data).then((response) => {
        setAssetsStep(response);
      });
    }

    getAssetsStep();
  }, []);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Novo Projeto")}</H2Border>
            </Col>
            <Col sm={12} justify="end"></Col>
          </Row>
          <Row>
            <Col sm={24}>
              <S.StyleMain>
                <StepsCreateProject
                  active={2}
                  projectId={params.projectId || ""}
                />
              </S.StyleMain>

              <Main bgColor="#fff" padding="30px">
                <TopInformation>
                  <>
                    <h3>{I18n.get("Personalizado")}</h3>

                    <p>
                      {I18n.get(
                        "Defina a Especialidade, a Disciplina e selecione os Ativos que você precisa para montar o projeto de acordo com sua necessidade"
                      )}
                    </p>
                  </>
                </TopInformation>
                <S.TopFilters>
                  <S.BoxSelects>
                    {isLoadingFilters ? (
                      <Row>
                        <Col md={12}>
                          <S.ContentIsLoading style={{ height: 80 }}>
                            <Loading
                              text={I18n.get("Carregando...")}
                              sizeText={14}
                              sizeIcon={16}
                              align="left"
                            />
                          </S.ContentIsLoading>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col md={6} data-label="assetSpecialism">
                          <SelectOne
                            options={listFilterSelect}
                            value={filterSelectValue || "all"}
                            onChange={(e) => setFilterSelectValue(e)}
                            placeholder={I18n.get("Especialidade")}
                          />
                        </Col>
                        <Col md={6} data-label="assetType">
                          <SelectOne
                            options={listFilterSelectTypes}
                            value={filterSelectTypeValue || "all"}
                            onChange={(e) => setFilterSelectTypeValue(e)}
                            placeholder={I18n.get("Tipo")}
                          />
                        </Col>
                      </Row>
                    )}
                  </S.BoxSelects>
                </S.TopFilters>

                <Row>
                  <Col lg={24}>
                    <H3Border>{I18n.get("Monte seu projeto")}</H3Border>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}></Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <AssetsCart
                      isCart
                      listDataIncludedItems={listDataIncludedItems}
                      handleListDataIncludedItems={handleListDataIncludedItems}
                      handleChanged={handleChanged}
                      cartTotals={cartTotals}
                      filters={filters}
                      reload={getStepInit}
                      assetTax={projectDetails?.assetTax}
                      otherTax={projectDetails?.otherTax}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={24}>
                    <S.FooterButtons className="buttonsContainer">
                      <ButtonBpool
                        text={I18n.get("Voltar")}
                        theme="secondary"
                        onClick={() =>
                          history.push(
                            `/cliente/projetos/v2/criar-projeto/${params.projectId}`
                          )
                        }
                      />
                      <div style={{ marginLeft: 15 }}>
                        <ButtonBpool
                          text={I18n.get("Próximo")}
                          onClick={handleSave}
                          className=""
                          theme="primary"
                          loading={isSending}
                        />
                      </div>
                    </S.FooterButtons>
                  </Col>
                </Row>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
