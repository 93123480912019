import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
// import BpayProvider from "../../pages/Bpay/context/BpayProvider";
import { Route, Switch } from "react-router-dom";
import { Initial } from "../../pages/Supplier/pages/Initial";
import { Profile } from "../../pages/Supplier/pages/Profile";
import { ProfileAddress } from "../../pages/Supplier/pages/Profile/pages/ProfileAddress";
import { ProfileDataBank } from "../../pages/Supplier/pages/Profile/pages/ProfileDataBank";
import { PartnerInitial } from "../../pages/Supplier/pages/Initial/partner";
import { UnloggedList } from "../../pages/Supplier/pages/UnloggedList";
import { BpayRegisterSupplier } from "../../pages/Supplier/pages/RegisterSupplier";
import { RegisterSupplierSuccess } from "../../pages/Supplier/pages/RegisterSupplierSuccess";

const queryClient = new QueryClient();

function SupplierRoutes() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <BpayProvider> */}
      <Switch>
        <Route
          path="/fornecedor/bpay/:bpayId/bpayment/:bpaymentId/invoices"
          exact
          component={BpayRegisterSupplier}
        />
        <Route
          path="/fornecedor/bpay/:bpayId/bpayment/:bpaymentId"
          exact
          component={BpayRegisterSupplier}
        />
        <Route // POC: Parceiro
          path="/partners/bpay/:bpayId/bpayment/:bpaymentId"
          exact
          component={BpayRegisterSupplier}
        />
        <Route path="/partners/inicio" exact component={PartnerInitial} />
        <Route
          path="/fornecedor/:supplierId/cadastro"
          exact
          component={BpayRegisterSupplier}
        />
        <Route
          path="/fornecedor/bpay/invoices/sucesso"
          exact
          component={RegisterSupplierSuccess}
        />
        <Route path="/fornecedor/inicio" exact component={Initial} />
        <Route
          path="/fornecedor/:supplierId/lista"
          exact
          component={UnloggedList}
        />
        <Route path="/fornecedor/perfil" exact component={Profile} />
        <Route
          path="/fornecedor/perfil/endereco"
          exact
          component={ProfileAddress}
        />
        <Route
          path="/fornecedor/perfil/dados-bancarios"
          exact
          component={ProfileDataBank}
        />
      </Switch>
      {/* </BpayProvider> */}
    </QueryClientProvider>
  );
}

export default SupplierRoutes;
