import React from 'react';

import { I18n } from 'aws-amplify';

class LeaderCard extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    details(e) {

    }

    getShortName(name, size) {

        if (name && name.length > size )
        {
            name = name.substring(0, size-3) + "...";
        }

        return name;
    }

    render() {

        return (<div className="card">
            <div className="card-content">

                <article className="media">
                    <figure className="media-left">
                        <p className="image is-64x64">
                            <p data-letters={this.props.name.charAt(0).toUpperCase()}></p>
                        </p>
                    </figure>
                    <div className="media-content">
                        <div className="content">
                            <p>
                                <strong>{this.getShortName(this.props.name, 20)}</strong> <br />
                                <small>{this.props.expertise && this.props.expertise.label}</small> <br />
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>)
    }
}

export default LeaderCard;