import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col } from "antd";
import { I18n } from "aws-amplify";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import { Menu } from "../../../components/Menu";
import { MenuAdmin } from "../../../../../components/UI/MenuAdmin";
import { BP_API } from "../../../../../../actions/bp/actions";
import { verifyStatusPartner } from "../../../../../../utils/verifyStatusPartner";

import { PartnerStatus } from "../../../components/PartnerStatus";
import Responsbible from "../../../../../../components/bpool/_partner-details-responsible";
import Company from "../../../../../../components/bpool/_partner-details-company";
import Address from "../../../../../../components/bpool/_partner-details-address";

import { getNameOfUrlLocation } from "../../../utils/getNameOfUrlLocation";

import * as S from "./styles";

export const PartnerDetails = () => {
  let params = useParams();
  let location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [partnerId, setPartnerId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [partnerStatus, setPartnerStatus] = useState(null);

  const statusPartner = async (partnerState) => {
    const status = await verifyStatusPartner(partnerState);
    setPartnerStatus(status);
  };

  useEffect(() => {
    if (!state?.bp?.partner?.status) {
      dispatch(BP_API.get_partner(params.partnerId));
    }

    if (state?.bp?.partner) {
      if (state?.bp?.partner?.partnerId !== params.partnerId) {
        dispatch(BP_API.get_partner(params.partnerId));
      }
      statusPartner(state?.bp?.partner);
    }
  }, [state]);

  useEffect(() => {
    setPartnerId(params.partnerId);
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLocationName(getNameOfUrlLocation(location.pathname) || "");
  }, [location]);

  return (
    <LoggedPage>
      <S.SubMenuWrapper>
        <MenuAdmin />
      </S.SubMenuWrapper>
      <div className="hero-body">
        <div className="container">
          <Row style={{ marginTop: 25 }}>
            <Col xs={6}>
              <Menu partnerId={partnerId} locationName={locationName} />
            </Col>
            <Col xs={18}>
              <S.Wrapper>
                <section className="section">
                  <div className="columns">
                    <div className="column is-12">
                      {partnerStatus ? (
                        <PartnerStatus
                          status={partnerStatus}
                          changePartnerStatus={false}
                          title={I18n.get("Partner Status")}
                        />
                      ) : null}
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <Responsbible {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <Company {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <Address {...state} />
                    </div>
                  </div>

                  {/* <div className="columns">
                    <div className="column is-12">
                      <EssentialDocuments {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <Specialisms {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <Diversity {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <Clients {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <Cases {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <Awards {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <Leaders {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <References {...state} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <ServiceOutCountry {...state} />
                    </div>
                  </div> */}
                </section>
              </S.Wrapper>
            </Col>
          </Row>
        </div>
      </div>
    </LoggedPage>
  );
};
