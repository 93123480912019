import { CHAT_UPDATE_STATUS } from './actions';

export const api_call = ({status}) => {

    return (dispatch, getState) => {

        dispatch({
            type: CHAT_UPDATE_STATUS,
            payload: {
                status: status
            }
        })        

    }
}

export default api_call;