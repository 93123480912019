import React from 'react';

import { I18n } from 'aws-amplify';

export default class ErrorPane extends React.Component {
    render() {

        if (!this.props.error) return null;

        const message = this.props.error.errorMessage || this.props.error.message;

        if (!message) return null;

        return (<article className="message is-danger">
            <div className="message-body">
                {I18n.get(message)}
            </div>
        </article>)
    }
}
