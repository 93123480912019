import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Tooltip } from "antd";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useProjects } from "../../../../../../hooks/useProjects/useProjects.hook";
import { Input } from "../../../../Input";
import Loading from "../../../../Loading";

import * as S from "./styles";
import IcoDel from "./svgs/ico-del.svg";

export const ItemAsset = ({
  item,
  allItems,
  hasEdit,
  handleEditQuantity,
  onClickDeleteItem,
  isCart,
  reload,
}) => {
  let params = useParams();
  const { addAssetToProject } = useProjects();
  const [itemQty, setItemQty] = useState(0);
  const [itemCode, setItemCode] = useState(0);
  const [firstInit, setFirstInit] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const [itemQtyDebounced] = useDebounce(itemQty, 1000);

  const onChangeQty = ({ code, quantity }) => {
    if (quantity > 0) {
      handleEditQuantity({ code, quantity });
      setItemQty(quantity);
      setItemCode(code);
    }
  };

  const handleDeleteItem = async (asset) => {
    setIsSending(true)
    await onClickDeleteItem(asset);
    setIsSending(false)
  };

  const renderComplexity = ({ complex, complexity }) => {
    if (complex) {
      return complex;
    } else if (complexity) {
      return complexity;
    } else {
      return "---";
    }
  };

  const saveItems = async () => {
    setIsSending(true);

    const dataOrganize = allItems?.map((item) => ({
      code: item?.asset ? item?.asset : item?.code,
      quantity: item?.quantity ? Number(item?.quantity) : 1,
    }));

    const dataSend = dataOrganize.map((item) => {
      if (item.code === itemCode) {
        return { ...item, quantity: Number(itemQty) };
      }
      return item;
    });

    let data = {
      projectId: params.projectId,
      assets: dataSend,
    };

    const response = await addAssetToProject({ assets: data });
    if (response?.success) {
      reload();
      setFirstInit(true);
    }

    setIsSending(false);
  };

  useEffect(() => {
    setItemQty(item?.quantity);
  }, [item]);

  useEffect(() => {
    if (isCart && itemQtyDebounced !== 0 && !firstInit) {
      saveItems();
    } else if (itemQtyDebounced !== 0) {
      setFirstInit(false);
    }
  }, [itemQtyDebounced]);

  return (
    <S.Wrapper>
      {isSending ? (
        <div style={{ marginLeft: 10 }}>
          <Loading
            text={I18n.get("Carregando...")}
            sizeText={14}
            sizeIcon={16}
            color="#000000"
            align="left"
          />
        </div>
      ) : (
        <>
          <S.IcoTooltip>
            <Tooltip className="tp" placement="right" title={item?.description}>
              <span style={{ fontWeight: 600, fontSize: 12 }}>?</span>
            </Tooltip>
          </S.IcoTooltip>
          <S.LabelAndComplexity hasEdit={hasEdit}>
            <S.Label>{item?.label}</S.Label>
            <S.Description>
              {renderComplexity({
                complex: item?.complex,
                complexity: item?.complexity,
              })}
            </S.Description>
          </S.LabelAndComplexity>
          {hasEdit ? (
            <S.Qty>
              <Input
                placeholder={I18n.get("Qtd")}
                label
                value={itemQty}
                onChange={(value) =>
                  onChangeQty({ code: item?.code, quantity: value })
                }
                error=""
                typeInput="number"
                noShowError
                sizeInput="small"
              />
            </S.Qty>
          ) : (
            <S.QtyNoEdit style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ textAlign: 'center', margin: 0 }} className="label">
                {I18n.get("Qtd")}
              </div>

              <S.IcoTooltip>
                <Tooltip className="tp" placement="center" title={null}>
                  <div style={{ textAlign: 'center' }}>
                    {item?.quantity}
                  </div>
                </Tooltip>
              </S.IcoTooltip>
            </S.QtyNoEdit>
          )}
          <S.Value>
            <span>{item?.priceFmt}</span>
          </S.Value>
          {hasEdit ? (
            <S.IcoDel onClick={() => handleDeleteItem(item)}>
              <img alt="icon del" src={IcoDel} />
            </S.IcoDel>
          ) : null}
        </>
      )}
    </S.Wrapper>
  );
};
