import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Loading from "../Loading";

import { tabsDefinitions } from "./tabs/definitions";
import { tabsInProgress } from "./tabs/inProgress";
import { tabsFinalizeds } from "./tabs/finalizeds";

import { ClientProjectStatus } from "../../../../model";

import * as S from "./styles";

export const TabsProject = ({
  context,
  page,
  externalLoading,
  projectStatus,
  partnerHasSubmittedDefinitions,
}) => {
  const auth = useSelector((state) => state.auth);
  let params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);

  const loadInit = async () => {
    setIsLoading(
      externalLoading !== undefined
        ? externalLoading
        : auth?.email
        ? false
        : true
    );

    if (context === "definitions") {
      const tabs = await tabsDefinitions({
        isClient: auth?.is_client_or_client_user,
        projectId: params?.projectId,
      });
      setTabs(tabs);
    } else if (context === "inProgress") {
      const tabs = await tabsInProgress({
        isClient: auth?.is_client_or_client_user,
        projectId: params?.projectId,
      });
      setTabs(tabs);
    } else if (context === "finalized") {
      const tabs = await tabsFinalizeds({
        isClient: auth?.is_client_or_client_user,
        projectId: params?.projectId,
      });
      setTabs(tabs);
    } else {
      setTabs([]);
    }

    setIsLoading(
      externalLoading !== undefined
        ? externalLoading
        : auth?.email
        ? false
        : true
    );
  };

  const disableTabs = () => {
    if (!partnerHasSubmittedDefinitions) {
      return ["po", "extras"];
    } else if (partnerHasSubmittedDefinitions && projectStatus < ClientProjectStatus.DefinitionsSet) {
      return ["po", "extras"];

    } else { // Por solicitação, se estiver com o Escopo Aprovado, não deve desabilitar o acesso da aba de Extras.
      return [];
    }
  };

  useEffect(() => {
    loadInit();
  }, [context, page, auth, externalLoading]);

  return (
    <>
      <S.Wrapper>
        {isLoading ? (
          <Loading
            text={I18n.get("Loading...")}
            sizeIcon={18}
            sizeText={14}
            align="center"
          />
        ) : (
          <>
            <S.StyleSteps>
              {tabs?.map((item, index) => {
                return (
                  <S.StyleStep
                    to={
                      context === "definitions"
                        ? disableTabs().find(
                            (itemDisabled) => itemDisabled === item?.key
                          )
                          ? "#"
                          : item.url
                        : item.url
                    }
                    disabled={
                      context === "definitions"
                        ? disableTabs().find(
                            (itemDisabled) => itemDisabled === item?.key
                          )
                          ? true
                          : false
                        : false
                    }
                  >
                    <S.StyleNumber
                      active={page === item?.key ? true : false}
                      disabled={
                        context === "definitions"
                          ? disableTabs().find(
                              (itemDisabled) => itemDisabled === item?.key
                            )
                            ? true
                            : false
                          : false
                      }
                    >
                      {index + 1}
                    </S.StyleNumber>
                    <S.StyleLabel active={page === item?.key ? true : false}>
                      {item.label}
                    </S.StyleLabel>
                  </S.StyleStep>
                );
              })}
            </S.StyleSteps>
          </>
        )}
      </S.Wrapper>
    </>
  );
};
