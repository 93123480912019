import React from 'react';

import { I18n } from 'aws-amplify';

import ReviewStep2Creative from './_project-review-2-creative';
import ReviewStep2EventBundle from './_project-review-2-eventBundle';
import ReviewStep2EventCustom from './_project-review-2-eventCustom';
import ReviewStep2BPitch from './_project-review-2-bpitch';

import { ProjectType } from '../../model';

class ReviewStep2 extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {
    	
        if (this.props.projectType === ProjectType.Creative){
            return (<ReviewStep2Creative {...this.props} />);
        }
        if (this.props.projectType === ProjectType.EventBundle){
            return (<ReviewStep2EventBundle {...this.props} />);
        }
        if (this.props.projectType === ProjectType.EventCustom){
            return (<ReviewStep2EventCustom {...this.props} />);
        }
        if (this.props.isBPitch){
            return (<ReviewStep2BPitch {...this.props} />);
        }        
    	
        return null;
    }
}

export default ReviewStep2;