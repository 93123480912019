import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { TitleMiddleLine } from "../../components/Titles";
import constants from "../../../constants";
import styled from "styled-components";
import MultUploadFile from "../../components/MultUploadFile";

import {
  Row,
  Col,
  Button,
  Checkbox,
  InputNumber,
  Input,
  Icon,
  Switch,
  Tooltip,
  Select,
  Form,
} from "antd";

const { TextArea, Search } = Input;
const { Option } = Select;

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
    margin-right: 20px;
  }
`;

const BoxAddFild = styled(Search)`
  button {
    position: relative;

    i {
      display: none;
    }
  }
`;

class FormAssets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      videos: [],
      links: [],
      images: [],
      formState: [],
      listImg: [],
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formState: values,
        });

        let data = {
          ...values,
        };

        this.props.action(data);
      }
    });
  };

  componentDidMount() {
    this.setState({
      loading: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (this.props.errors != prevProps.errors && this.props.errors) {

      console.log("FORA",this.props.errors );
      Object.keys(this.props.errors).map((field) => {
        console.log("DENTRO",field );
        this.props.form.setFields({
          [field]: {
            value: this.state.formState[field],
            errors: [new Error(this.props.errors[field].errorMessage)],
          },
        });
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row className="RowSection">
          {this.props.item && Object.keys(this.props.item).length > 0 && (
            <Col sm={5}>
              <Form.Item label={I18n.get("Código")}>
                {getFieldDecorator("code", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue: this.props.item?.code || "",
                })(<Input disabled={true} />)}
              </Form.Item>
            </Col>
          )}

          <Col sm={5}>
            <Form.Item label={I18n.get("Cód. Cliente")}>
              {getFieldDecorator("codeClient", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: this.props.item?.codeClient || "",
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={6}>
            <Form.Item label={I18n.get("Label")}>
              {getFieldDecorator("label", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.label
                    ? this.props.item.label
                    : "",
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={4}>
            <Form.Item label={I18n.get("Ativo")}>
              {getFieldDecorator("enabled", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  (this.props.item && this.props.item.enabled) || true,
              })(
                <Select>
                  <Option value={true}>{I18n.get("SIM")}</Option>
                  <Option value={false}>{I18n.get("NÃO")}</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={4}>
            <Form.Item label={I18n.get("Hora/Homem")}>
              {getFieldDecorator("isManHour", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: this.props.item && this.props.item.isManHour,
              })(
                <Select>
                  <Option value={true}>{I18n.get("SIM")}</Option>
                  <Option value={false}>{I18n.get("NÃO")}</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={4}>
            <Form.Item label={I18n.get("Itens Live Marketing")}>
              {getFieldDecorator("isRateCard", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: this.props.item && this.props.item.isRateCard,
              })(
                <Select>
                  <Option value={true}>{I18n.get("SIM")}</Option>
                  <Option value={false}>{I18n.get("NÃO")}</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={5}>
            <Form.Item label={I18n.get("Unidade")}>
              {getFieldDecorator("unity", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.unity
                    ? this.props.item.unity
                    : "",
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex">
          <Col sm={6}>
            <Form.Item label={I18n.get("Tipo")}>
              {getFieldDecorator("type", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.type
                    ? this.props.item.type
                    : [],
              })(
                <Select
                  showSearch={true}
                  mode="tags"
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.assetTypes &&
                    this.props.assetTypes.map((item, index) => {
                      return (
                        <Option value={item.code} key={item.code}>
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={6}>
            <Form.Item label={I18n.get("Valor")}>
              {getFieldDecorator("price", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: this.props.item?.price || 0,
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={6}>
            <Form.Item label={I18n.get("Valor TM2")}>
              {getFieldDecorator("price2", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: this.props.item?.price2 || 0,
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={6}>
            <Form.Item label={I18n.get("Valor Baseline")}>
              {getFieldDecorator("priceRef", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: this.props.item?.priceRef || 0,
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={24}>
            <Form.Item label={I18n.get("Descrição")}>
              {getFieldDecorator("description", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.description
                    ? this.props.item.description
                    : "",
              })(<Input.TextArea rows={4} />)}
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item label={I18n.get("Subdescrição")}>
              {getFieldDecorator("subDesc", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.subDesc
                    ? this.props.item.subDesc
                    : "",
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item label={I18n.get("Especialidades")}>
              {getFieldDecorator("specialism", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.specialism
                    ? this.props.item.specialism
                    : [],
              })(
                <Select
                  showSearch={true}
                  mode="tags"
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.assetSpecialisms &&
                    this.props.assetSpecialisms.map((item, index) => {
                      return (
                        <Option value={item.code} key={item.code}>
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item label={I18n.get("Especialidades do parceiro")}>
              {getFieldDecorator("partnerSpecialism", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.partnerSpecialism
                    ? this.props.item.partnerSpecialism
                    : [],
              })(
                <Select
                  showSearch={true}
                  mode="tags"
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.partnerSpecialismsArray &&
                    this.props.partnerSpecialismsArray.map((item, index) => {
                      return (
                        <Option value={item.code} key={item.code}>
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item label={I18n.get("Complexidade ID")}>
              {getFieldDecorator("complexityId", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.complexityId
                    ? this.props.item.complexityId
                    : "",
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item label={I18n.get("Complexidade")}>
              {getFieldDecorator("complexity", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.complexity
                    ? this.props.item.complexity
                    : "",
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item label={I18n.get("Complexidade descrição")}>
              {getFieldDecorator("complexDesc", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.complexDesc
                    ? this.props.item.complexDesc
                    : "",
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <RowSubmitModal>
          <Col sm={24}>
            <Button shape="round" onClick={() => this.props.onCancel()}>
              {I18n.get("Cancelar")}
            </Button>

            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              loading={this.props.task.busy}
            >
              {this.props.item && Object.keys(this.props.item).length > 0
                ? I18n.get("Atualizar")
                : I18n.get("Adicionar")}
            </Button>
          </Col>
        </RowSubmitModal>
      </Form>
    );
  }
}

const FormAssetsForm = Form.create({})(FormAssets);

export default FormAssetsForm;
