import styled from 'styled-components';
import { Progress } from 'antd';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

export const Container = styled.div`
  width: 100%;
`;

const ProgressStyled = styled(Progress)`
  .ant-progress-outer {
    .ant-progress-inner {
      background: #c4c4c4;
      height: 20px;

      .ant-progress-bg {
        background: #1883ff;
        height: 20px !important;
      }
    }
  }
`;

export const ProgressAndStep = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Steps = styled.div`
  display: flex;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 33.333333%; */
  width: 50%;
  margin-top: 10px;

  button {
    background: none;
    border: none;
    cursor: pointer;

    p {
      cursor: pointer;
      margin-top: 10px;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #2f2f2f;
    }

    :disabled {
      cursor: initial;

      p {
        cursor: initial;
      }
    }
  }
`;

export { ProgressStyled as Progress };
