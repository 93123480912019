import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

import { Task } from '../../utils/task';

import { REFERENCE_API } from '../../actions/reference/actions';

import FillForm from '../page-templates/fillForm';

import WallPage from './wall';

import ResponsibleForm from './responsible-form';

import SubmitButtonField from '../form/submit-button-field';

import Steps from './steps';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class PartnerSignupStep1 extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.partner && this.props.partner.task;

        if (!prevState) return;
        if (!task) return;

        var nextState = Object.assign({}, prevState);

        if (task.id != prevState.task.id) {

            nextState.task = task;

            this.setState(nextState);
        }
    }

    onSubmitForm(e) {

        e.preventDefault();

        const form = this.refs.form;

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            form.submitForm(e);
        });
    }

    render() {

        if (this.props.client_user && this.props.client_user.completedOn) {
            return (<Redirect to="/cliente/usuario/obrigado" />);
        }

        var errorMap = {
        };

        Object.keys(this.props.client_user.errors).map((item) => {

            const error = this.props.client_user.errors[item];

            const key = error.step || 'global';

            if (!errorMap[key]) {
                errorMap[key] = []
            }

            errorMap[key].push(error.errorMessage)
        });

        return (
            <FillForm>
                <div className="hero-body has-background-white">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-7">
                                <span className="title-box">{I18n.get("Novo usuário")}</span>
                            </div>
                            <div className="column is-5">
                                <Steps active={1}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="partner-step partner-step-1 reference-step-1">

                    <div className="container reference-container">

                        <div className="column is-4 is-offset-4">

                            <h1 className="title">
                                <span>1.</span>{I18n.get("Sobre você")}</h1>

                            <ResponsibleForm ref="form" {...this.props} />
                                     
                            <br />

                            {Object.keys(errorMap).length > 0 && <div className="content is-small">

                                <br /><br />

                                {Object.keys(errorMap).map((item) => {

                                    return (
                                        <p>
                                            <span class="icon has-text-danger">
                                                <i class="fas fa-exclamation"></i>
                                            </span>
                                            <Link to='/cliente/usuario/passo-1'>{I18n.get("Passo 1")}</Link>
                                            <ul>
                                                {errorMap[item].map((errorMessage) => (<li>{errorMessage}</li>))}
                                            </ul>
                                        </p>
                                    );
                                })}

                            </div>}

                            <div className="field is-grouped is-grouped-centered">
                                <p className="control is-expanded">
                                </p>
                                <p className="control is-expanded">
                                    <SubmitButtonField
                                        className="button is-black is-fullwidth next"
                                        label={I18n.get("FINALIZAR")}
                                        onClick={this.onSubmitForm.bind(this)}
                                        task={this.state.task}
                                        suppressLabel={true}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
            </div>


         </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSignupStep1);
