
import React from 'react';

import StepsCreative from './steps-creative';
import StepsBidding from './steps-bidding';

const Steps = (props) => {

    const { project = {} } = props;

    console.log("project", project);

    if (project.isCreative) {

        return <StepsCreative {...props} />
    }
    else {
        return <StepsBidding {...props} />
    }

}

export default Steps;
