import React from 'react';

import { I18n } from 'aws-amplify';

const Card = (props) => {

    if (!props.bp || !props.bp.client) return null;

    const item = props.bp.client;

    var linkSignup = "https://app.bpool.co/#/cliente/verifica-email/" + item.verificationToken;

    return (
        <div className="card">
            <div className="card-content">
                <h3 className="title is-4">{I18n.get("Link cadastro")}</h3>

                <div className="content">
                    <a target="_blank" href={ linkSignup }>{ linkSignup }</a>
                </div>
            </div>
        </div>
    );
};

export default Card;
