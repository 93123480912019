import axios from 'axios';

import Config from '../../config';

import { AUTH_API } from '../auth/actions';

import { CLIENT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_confirm_client = (data) => {
    return (dispatch) => {

        return axios.post(`${Config.API_BASE_URL}/client/confirm-terms`, data)
            .then(response => {

                dispatch({
                    type: CLIENT_UPDATE,
                    payload: response.data
                });
            })
            .catch(error => {

                dispatch({
                    type: CLIENT_UPDATE,
                    payload: {
                        errors: error.response.data
                    }
                });
            });
    };
}
export default api_confirm_client;