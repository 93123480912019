import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Config from '../../config';
import { Upload, Icon, Modal, Button, message, notification } from 'antd';
import styled from 'styled-components';


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function getBase64Get(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {

        notification.error({
            message: I18n.get('Ops, algo deu errado!'),
            description: I18n.get('Somente arquivos JPG e PNG são suportados.'),
            duration: 3,
        });
    }

    return isJpgOrPng;
}

function statusProgress(st) {
    this.setState({
        progress: st
    })
}

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {};
}



const SpanLoading = styled.span`
    position: absolute;
    display: block;
    background: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #fff;
    font-size: 64px;
    opacity: 0;
    transition: all .5s ease;
    &.loading {
        opacity: .5;
    }

    b {
        font-size: 14px;
        display: block;
        position: absolute;
        width: 100%;
        left:0;
        top: 40px;
    }

    .iconAnimate {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 100px;
            color: #fff;
        }
    }
`

class MultUploadFile extends Component {
    state = {
        previewVisible: false,
        previewImage: '',
        fileList: [],
        fileSend: [],
        loading: false,
        progress: "0%",
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = (e) => {
        // this.setState({ fileList });

    };




    onDrop = ({ onSuccess, onError, file }) => {
        //EndPoint para pegar os dados de autorização
        const EndpointGetAuthToUpload = this.props.get2 ? Config.UPLOAD_URL2 : Config.UPLOAD_URL;

        this.setState({
            loading: true
        });
        let fileSplit = file.name.split('.');
        let fileSafe = file.name.replace(/[^a-z0-9]/gi, '-');
        let fileNew = `${fileSafe}_${uuidv4()}`;
        if (fileSplit.length > 1) {
            fileNew = fileNew + '.' + fileSplit[fileSplit.length - 1];
        }

        let id = this.props.uploadId; //Dependenddo do contexto, será ClientId ou PartnerId ou ProjectId

        let key = `${id}/${fileNew}`; //Caminho final do arquivo - contexto/nome-unico-arquivo.txt

        //Access Token vindo do Cognito
        let authHeader = { headers: { Authorization: 'Bearer ' + this.props.auth.jwtToken } };

        //EndPoint para pegar autorização para subir o arquivo. passar a chave (key) na queryString
        let preSignUrl = `${EndpointGetAuthToUpload}?k=${key}${this.props.get2 ? "&t=pleader" : ""}`;

        //Pega autorização para upload
        axios.get(preSignUrl, authHeader).then((resp) => {

            let { url, fields } = resp.data;

            let formData = new FormData();

            //Dados recebidos para autorizar o upload do arquivo
            Object.keys(fields).forEach(key => {
                formData.append(key, fields[key])
            });

            //arquivo

            formData.append('file', file);

            let percentCompleted = "";

            var config = {
                headers: { 'content-type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                    this.setState({
                        progress: percentCompleted + "%"
                    })

                    return percentCompleted
                }
            };

            //tendo sucesso, fazer POST do arquivo com os dados de autorização
            axios.post(url, formData, config).then((response) => {

                this.setState({
                    statusCode: response.status,
                    imgPreview: resp.data.url + "/" + resp.data.fields.key
                });

                // const dataForm = {
                //     field: this.props.name,
                //     url: response.data.url + "/" + response.data.fields
                // }

                // this.props.getDataUpload(dataForm);

                //Atualiza o item com o arquivo
                // let urlUpdate = '';
                // axios.put(urlUpdate, config).then((response) => {

                //     //Mensagem de OK

                // }).catch(error => { // Erro Atualização

                //     console.error("Erro Atualziar Objeto. Erro: " + error.response.status +'-'+ error.response.data.message)

                // });

                setTimeout(() => {
                    this.setState({
                        loading: false,
                        progress: "0%"
                    })
                }, 2000);

                let urlClean = "https://" + resp.data.url.split("s3.amazonaws.com/")[1] + "/"

                this.setState({
                    fileList: [
                        ...this.state.fileList,
                        {
                            uid: url,
                            name: 'image.png',
                            status: 'done',
                            url: urlClean + resp.data.fields.key,
                        }
                    ]
                })

                setTimeout(() => {
                    this.sendImages();
                }, 1);

                onSuccess(null, file);


            }).catch(error => {


            });

        }).catch(error => {


        });


    };

    // customRequest = ({ file, onError, onSuccess }) => {
    //     let img64 = "";

    //     getBase64Get(file, imageUrl => {
    //         img64 = imageUrl;

    //         const dataUpload = {
    //             id: this.props.uploadId,
    //             img_type: file.type,
    //             img_base64: img64
    //         }

    //         this.props.actionUpload(dataUpload).then((response) => {
    //             const url = response.data.url;

    //             this.setState({
    //                 fileList: [
    //                     ...this.state.fileList,
    //                     {
    //                         uid: url,
    //                         name: 'image.png',
    //                         status: 'done',
    //                         url: url,
    //                     }
    //                 ]
    //             })

    //             setTimeout(() => {
    //                 this.sendImages();
    //             }, 1);

    //             onSuccess(null, file);
    //         }).catch((error) => {
    //             onError(error)
    //         });
    //     });
    // }

    sendImages = () => {
        const imagens = this.state.fileList.map(item => {
            return item.url;
        });

        setTimeout(() => {
            this.props.getDataUpload(imagens)
        }, 10);
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.fileListGet != prevProps.fileListGet && this.props.isEdit) {
            this.setState({
                fileList: this.props.fileListGet
            });
        }
    }


    render() {
        const { previewVisible, previewImage, fileList } = this.state;


        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{I18n.get("Upload")}</div>
            </div>
        );
        return (
            <div className="clearfix">
                <Upload
                    listType="picture-card"
                    onPreview={this.handlePreview}
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    customRequest={(e) => this.onDrop(e)}
                    onChange={(e) => this.handleChange(e)}
                    onRemove={(file) => {
                        this.setState(state => {
                            const index = state.fileList.indexOf(file);
                            const newFileList = state.fileList.slice();
                            newFileList.splice(index, 1);
                            return {
                                fileList: newFileList,
                            };
                        });

                        setTimeout(() => {
                            this.sendImages()
                        }, 1);
                    }}
                >

                    <SpanLoading className={this.state.loading ? "loading" : ""}>
                        <b>{this.state.progress}</b>
                        <Icon type="loading" />
                    </SpanLoading>

                    {fileList && fileList.length >= 6 ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultUploadFile);
// export default MultUploadFile;
