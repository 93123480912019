import { CHAT_MESSAGE } from './actions';

export const api_call = (data) => {

    return (dispatch, getState) => {

        dispatch({
            type: CHAT_MESSAGE,
            payload: data
        })        

    }
}

export default api_call;