import axios from 'axios';

import Config from '../../config';

import { AUTH_API } from '../auth/actions';

import { LEADER_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_confirm_leader = (data) => {
    return (dispatch) => {

        let endpoint = `${Config.API_BASE_URL}/leader/confirm`;

        return axios.post(endpoint, data)
            .then(response => {

                const email = response.data.email;
                const password = response.data.password;

                delete response.data['password'];

                dispatch({
                    type: LEADER_UPDATE,
                    payload: response.data
                });

            })
            .catch(error => {

                dispatch({
                    type: LEADER_UPDATE,
                    payload: {
                        errors: error.response.data
                    }
                });
            });
    };
}
export default api_confirm_leader;
