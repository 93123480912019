import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import LoggedPage from '../page-templates/logged';

import Menu from './_menu';

import CompanyForm from './company-form';

import FormCompanyClient from '../../modules/Register/components/formCompanyClient';

import SubmitButtonField from '../form/submit-button-field';

import { CLIENT_API } from '../../actions/client/actions';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(CLIENT_API.update_company(data));
        },
    };
};

class CompanyAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><Link to="/home">{I18n.get("Admin")}</Link></li>
                        <li className="is-active"><Link to="/cadastro">{I18n.get("Cadastro")}</Link></li>
                    </ul>
                </nav>

                <div className="columns company-admin">

                    <div className="column is-3">
                        <Menu {...this.props} active={2} />
                    </div>

                    <div className="column is-8">

                        <h1 className="title">{I18n.get("Dados da empresa")}</h1>

                        {/* <CompanyForm {...this.props} isAdmin={true} /> */}

                        <FormCompanyClient
                            client={this.props.client && this.props.client}
                            clientId={this.props.client.clientId}
                            action={this.props.save}
                            errors={this.props.client.errors && this.props.client.errors}
                            revenueRanges={this.props.bp && this.props.client.signupLocale === "es" ? this.props.bp.clientRevenueRanges_es : this.props.bp.clientRevenueRanges}
                            employeesRanges={this.props.bp.clientEmployeesRanges && this.props.bp.clientEmployeesRanges}
                            paymentDefaultTermDays={this.props.bp.clientPaymentDefaultTermDays && this.props.bp.clientPaymentDefaultTermDays}
                            countryCodes={this.props.bp && this.props.bp.countryCodes}
                        />

                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdmin);
