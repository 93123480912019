export const isImage = (type) => {
  if (type === "image/jpeg") {
    return "image";
  } else if (type === "image/jpg") {
    return "image";
  } else if (type === "image/png") {
    return "image";
  } else if (type === "image/gif") {
    return "image";
  } else if (type === "image/webp") {
    return "image";
  } else if (type === "image/svg+xml") {
    return "image";
  } else if (type === "image/bmp") {
    return "image";
  } else if (type === "image/tiff") {
    return "image";
  } else if (type === "image/avif") {
    return "image";
  } else {
    return "other-file";
  }
};
