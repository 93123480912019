import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
  column-gap: 16px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  padding: 16px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 33.333333% 33.333333% 33.333333%;
  column-gap: 16px;
`;

export const ContentChavePix = styled.div`
  width: 100%;
`;

export const Icon = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 16px 16px 0 0;

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: none;
  }
`;

export const Info = styled.div`
  label {
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 600;
  }
  p {
    line-height: 20px;
    margin-bottom: 8px;
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-infos {
    height: 20px;
    margin-bottom: 28px;

    li {
      height: 100%;
    }
  }
`;
