import React from "react";

import { I18n } from "aws-amplify";

// import Modal from '../../ui/modal';
import { Icon, Modal, notification } from "antd";

import FormTypes from "../../../modules/Admin/components/formProductTypes";
import FormTMarket from "../../../modules/Admin/components/formProductTmarket";

class Cmp extends React.Component {
  constructor(props) {
    super();

    this.state = {
      selected: null,
      selectedProduct: null,
      selectedBrand: null,
      modalTypes: false,
      modalTmarket: false,
      users: [],
    };

    this.select = this.onSelect.bind(this);
    this.closeModal = this.onCloseModal.bind(this);
  }

  onSelect(item, e) {
    const users =
      this.props.bp.client &&
      this.props.bp.client.users.filter((user) => user.productId == item.id);

    this.setState({ selected: item, users: users });
  }

  onCloseModal(e) {
    e.preventDefault();

    this.setState({ selected: null, users: [] });
  }

  getCategory(value) {
    if (!value) return {};
    if (!this.props.bp.sectorsFlatMap) return {};

    return this.props.bp.sectorsFlatMap[value];
  }

  getData = (data) => {
    const dataSend = {
      allowedProjectsTypes:
        data.allowedProjectsTypes ||
        this.state.selectedProduct.allowedProjectsTypes,
      brandId: this.state.selectedBrand.id,
      productId: this.state.selectedProduct.id,
      clientId: this.props.bp.client.id,
      tMarket: data.tMarket || this.state.selectedProduct.tMarket,
    };

    this.props.update_types(dataSend).then((response) => {
      if (response) {
        this.closeModalTypes();
        this.props.get_client(this.props.bp.client.id);

        notification["success"]({
          message: I18n.get("Atualizado com sucesso!"),
          description: "",
        });
      } else {
        notification["error"]({
          message: I18n.get("Ops! Algo deu errado."),
          description: "",
        });
        this.closeModalTypes();
      }
    });
  };

  closeModalTypes = () => {
    this.setState({
      modalTypes: false,
      modalTmarket: false,
      selectedProduct: null,
      selectedBrand: null,
    });
  };

  render() {
    if (!this.props.bp) return null;
    if (!this.props.bp.client) return null;

    const item = this.props.bp.client;

    return (
      <section>
        <div className="card">
          <div className="card-content">
            <h3 className="title is-4">{I18n.get("Marcas")}</h3>

            <div className="content">
              <table className="table-bpool no-opacity">
                <tbody>
                  <tr>
                    <th>{I18n.get("Marca")}</th>
                    <th>{I18n.get("Produto")}</th>
                    <th>{I18n.get("Categoria")}</th>
                    <th>{I18n.get("Mercados")}</th>
                    <th className="has-text-centered">
                      {I18n.get("Responsáveis")}
                    </th>
                    <th className="has-text-centered">
                      {I18n.get("Tipo Projetos")}
                    </th>
                  </tr>
                  {item.brands.map((brand, index) =>
                    brand.products.map((product) => {
                      return (
                        <tr key={product.id}>
                          <td>{brand.name}</td>
                          <td>{product.name}</td>
                          <td>{this.getCategory(product.categoryId).label}</td>

                          <td>
                            {item?.customization?.allowMultipleMarket && (
                              <span>
                                <a
                                  onClick={() =>
                                    this.setState({
                                      selectedProduct: product,
                                      selectedBrand: brand,
                                      modalTmarket: true,
                                    })
                                  }
                                >
                                  {product?.tMarket.sort().join()}
                                </a>
                              </span>
                            )}

                            {!item?.customization?.allowMultipleMarket && (
                              <span>{product?.tMarket.sort().join()}</span>
                            )}
                          </td>

                          <td
                            className="has-text-centered"
                            onClick={this.select.bind(this, product)}
                          >
                            <i className="fas fa-eye"></i>
                          </td>
                          <td className="has-text-centered">
                            <Icon
                              onClick={() =>
                                this.setState({
                                  selectedProduct: product,
                                  selectedBrand: brand,
                                  modalTypes: true,
                                })
                              }
                              type="unlock"
                              theme="filled"
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal
          title={I18n.get("Responsáveis")}
          visible={!!this.state.selected}
          onCancel={this.closeModal}
          width="50%"
          footer={null}
        >
          <section>
            <div className="card">
              <div className="card-content">
                <div className="content">
                  <table className="table-bpool no-opacity">
                    <tbody>
                      <tr>
                        <th>{I18n.get("Nome")}</th>
                        <th>{I18n.get("Email")}</th>
                        <th>{I18n.get("Função")}</th>
                      </tr>
                      {this.state.users.map((user, index) => (
                        <tr key={index}>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </Modal>

        <Modal
          title={I18n.get("Módulos")}
          visible={this.state.modalTypes}
          onCancel={this.closeModalTypes}
          width="50%"
          footer={null}
          destroyOnClose={true}
        >
          <section>
            <div className="card">
              <div className="card-content">
                <div className="content modaltypes">
                  <h2>
                    {this.state?.selectedBrand?.name} {" / "}{" "}
                    {this.state?.selectedProduct?.name}
                  </h2>

                  <FormTypes
                    action={this.getData}
                    allowedProjectsTypes={
                      this.state?.selectedProduct?.allowedProjectsTypes || []
                    }
                  />
                </div>
              </div>
            </div>
          </section>
        </Modal>

        <Modal
          title={I18n.get("Mercados")}
          visible={this.state.modalTmarket}
          onCancel={this.closeModalTypes}
          width="50%"
          footer={null}
          destroyOnClose={true}
        >
          <section>
            <div className="card">
              <div className="card-content">
                <div className="content modaltypes">
                  <h2>
                    {this.state?.selectedBrand?.name} {" / "}{" "}
                    {this.state?.selectedProduct?.name}
                  </h2>

                  <FormTMarket
                    action={this.getData}
                    tMarket={this.state?.selectedProduct?.tMarket || []}
                  />
                </div>
              </div>
            </div>
          </section>
        </Modal>
      </section>
    );
  }
}

export default Cmp;
