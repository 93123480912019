import React from 'react';

import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import { Tabs, Row, Col, Select, Button, Table, Tag, Modal, Empty, Drawer } from 'antd';

import FormCategories from '../../modules/Admin/components/formCategories';

import TableCategories from '../../modules/Admin/components/tableCategories';

import { Task } from '../../utils/task';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_knowledge: () => {
            return dispatch(BP_API.get_knowledge());
        },
        create_categorie: data => {
            return dispatch(BP_API.create_categories(data));
        },
        update_categorie: data => {
            return dispatch(BP_API.update_categories(data));
        },
        delete_category: data => {
            return dispatch(BP_API.delete_category(data));
        }
    };
};


class KnowledgeCategoryAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
            task: {},
            visible: false,
            item: {}
        };
    }

    componentDidMount() {
         this.props.get_knowledge();
    }

    componentDidUpdate = (prevProps) => {
        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        if (task != prev) {
            this.setState({ task: task });
        }

        if(this.props.bp && this.props.bp.knowledgeBase != prevProps.bp.knowledgeBase) {
            this.setState({
                task: {}
            })
        }
    }

    handleDelete = (data) => {
        this.props.delete_category(data).then((result) => {
            if(result) {
                this.props.get_knowledge();
                this.close();
            }
        });
    }

    handleAction = (data) => {
        var task = new Task();
        
        task.start();

        let dataSend = {
            ...data,
        }

        this.setState({ task: task, id: data.clientId, updateList: true}, function () {

            if(Object.keys(this.state.item).length > 0) {
                dataSend.categoryId = this.state.item.categoryId;
                // dataSend.scopeId = this.state.item.scopeId;

                this.props.update_categorie(dataSend).then((result) => {
                    if(result) {
                        this.props.get_knowledge();
                        this.close();
                    }
                });
            } else {
                this.props.create_categorie(dataSend).then((result) => {
                    if(result) {
                        this.props.get_knowledge();
                        this.close();
                    }
                });
            }
            
        });
    }

    close = () => {
        this.setState({
            visible: false,
            item: {}
        })
    }

    setItem = (item) => {
        this.setState({
            visible: true,
            item: item
        })
    }

    render() {
        const {bp: {knowledgeBase = []}} = this.props;

        let dataScopes = [];
        let dataCategories = [];
        let dataSections = [];

        knowledgeBase.map((scope) => {  ;
            if(scope.sections && scope.sections.length > 0){
                scope.sections.map(section => {
                    dataSections.push(
                        {
                            scope: scope.name,
                            scopeId: scope.scopeId,
                            section: section.name,
                            target: scope.target,
                            sectionId: section.sectionId,
                            language: scope.language,
                        }
                    )
                    if(section?.categories.length > 0) {

                        section.categories.map((categorie) => {
                            dataCategories.push(
                                {
                                    ...categorie,
                                    scope: scope.name,
                                    scopeId: scope.scopeId,
                                    categoryId: categorie.categoryId,
                                    section: section.name,
                                    target: scope.target,
                                    sectionId: section.sectionId,
                                    language: scope.language,
                                }
                            )
                        });
                    }

                });
            }
        });

        console.log("this.state.item", this.state.item);

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/wikis" className="is-black">{I18n.get("Wikis")}</NavLink></li>
                    </ul>
                </nav>

                <div className="card">
                    <div className="card-container card-wikis">
                            <p className="btpull">
                                <Button onClick={() => this.setState({visible: true, item: {}})} className="button bt-bpool black pull-right">{I18n.get("Adiconar categoria")}</Button>
                            </p>

                            <TableCategories 
                                data={dataCategories || []}
                                edit={this.setItem}
                                delete={this.handleDelete}
                            />
                    </div>
                </div>

                <Drawer
                    title={Object.keys(this.state.item).length > 0 ? I18n.get("Editar") : I18n.get("Adicionar")}
                    placement="right"
                    closable={true}
                    onClose={() => this.close()}
                    destroyOnClose={true}
                    visible={this.state.visible}
                    width="50%"
                >
                    <FormCategories
                        sections={dataSections || []}
                        action={this.handleAction}
                        task={this.state.task}
                        errors={this.props?.bp?.errors}
                        item={this.state.item}
                    />
                </Drawer>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeCategoryAdmin);
