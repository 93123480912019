import React from "react";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";

import BgImg from "./imgs/bg-client.png";
import LogoEmpty from "./imgs/logo-empty.jpg";
import IcoNew from "./imgs/ico-new.svg";
import IcoAccepted from "./imgs/ico-accepted.svg";
import IcoDeclined from "./imgs/ico-declined.svg";
import IcoNotChosen from "./imgs/ico-not-chosen.svg";

import * as S from "./styles";

export const ItemInvitation = ({ data }) => {
  let history = useHistory();

  const renderInfo = ({ label, text }) => {
    return (
      <S.Info>
        <S.LabelInfo>{`${label}:`}</S.LabelInfo>
        <S.TextInfo>{text || "---"}</S.TextInfo>
      </S.Info>
    );
  };

  const renderIconStatus = (status) => {
    // None (Nenhum) = 0
    // New (Novo) = 1
    // Accepted (Aceito) = 2
    // Declined (Declinado) = 3
    // Withdraw (Deletado) = 4
    // Expired (Expirado) = 9
    // NotChosen = 10
    // Chosen = 11

    const statusIconMap = {
      0: (
        <>
          <span>{I18n.get("None")}</span>
        </>
      ),
      1: (
        <>
          <img src={IcoNew} alt="Ico New" />
          <span>{I18n.get("New")}</span>
        </>
      ),
      2: (
        <>
          <img src={IcoAccepted} alt="Ico Accepted" />
          <span>{I18n.get("Aceito")}</span>
        </>
      ),
      3: (
        <>
          <img src={IcoDeclined} alt="Ico Declined" />
          <span>{I18n.get("Declinado")}</span>
        </>
      ),
      4: (
        <>
          <span>{I18n.get("Project deleted")}</span>
        </>
      ),
      9: (
        <>
          <span>{I18n.get("Expired")}</span>
        </>
      ),
      10: (
        <>
          <img src={IcoNotChosen} alt="Ico NotChosen" />
          <span>{I18n.get("Not chosen")}</span>
        </>
      ),
      11: (
        <>
          <span>{I18n.get("Chosen")}</span>
        </>
      ),
    };

    const statusIcon = statusIconMap[status] || (
      <>
        <span>{I18n.get("Erro in get status")}</span>
      </>
    );

    return statusIcon;
  };

  const goToProject = () => {
    if (data.projectType == 2) {
      if (data.isPreInvite) {
        history.push(`/parceiro/eventos/${data?.projectId}/detalhes`);
      } else {
        history.push(`/parceiro/eventos/${data?.projectId}/convite-passo-1`);
      }
    } else {
      history.push(
        `/parceiro/projetos/v2/${data?.projectId}/invitation-details`
      );
    }
  };

  return (
    <S.Wrapper>
      <S.Imgs>
        <S.ImgBg>
          <img src={BgImg} alt={`bg-img`} />
        </S.ImgBg>
        <S.ImgLogo>
          <img
            src={data?.clientLogo || LogoEmpty}
            alt={`logo-${data?.clientName}`}
          />
        </S.ImgLogo>
      </S.Imgs>
      <S.Infos>
        <S.CardTitle>{data?.projectName}</S.CardTitle>

        <S.ContentInfos>
          {renderInfo({
            label: I18n.get("Brand"),
            text: data?.projectBrandName,
          })}
          {renderInfo({ label: I18n.get("Date"), text: data?.createdOn })}
          {renderInfo({
            label: I18n.get("Expires in"),
            text: data?.willExpireOn,
          })}
          {renderInfo({
            label: I18n.get("Category"),
            text: data?.categoryName,
          })}
        </S.ContentInfos>

        <S.Status>
          <span className="titleLabel">Status:</span>
          {renderIconStatus(data?.statusInvite)}
        </S.Status>
      </S.Infos>
      <S.ViewProject>
        <ButtonBpool
          text={I18n.get("View project")}
          theme="primary"
          onClick={() => goToProject()}
        />
      </S.ViewProject>
    </S.Wrapper>
  );
};
