import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';
import { PartnerWhatIfStatus } from '../../../model';
import LoadingPage from '../../../components/pages/loading';
//import LandingPage from '../page-templates/landing';

//import Steps from './steps';

const mapStateToProps = state => {

    return { partnerWhatIf: state.partnerWhatIf, auth: state.auth };
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: data => {
            dispatch(PARTNER_WHATIF_API.confirm(data));
        }
    };
};

class VerifyEmailPre extends React.Component {

    constructor(props) {

        super();

        this.state = {
            verificationToken: props.match.params.verificationToken
        };
    }

    componentWillMount() {
        this.props.onSubmit(this.state);
    }

    render() {

 

        if (!this.props.partnerWhatIf || !this.props.partnerWhatIf.id)
            return (<LoadingPage></LoadingPage>)

        if (this.props.partnerWhatif && this.props.partnerWhatIf.status == PartnerWhatIfStatus.Approved) {
            return (<Redirect to="/parceiro-ideias/marcas" />);
        }

        return (<Redirect to="/parceiro-ideias/informacoes-gerais" />);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPre);
