import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import * as S from "./styles";

export const Card = ({ cardData, windowWidth, addbundle, loading, full }) => {
  const [indexSelected, setIndexSelected] = useState(0);
  const renderButtons = (status, item) => {
    if (status === 0) {
      const data = {
        bundleId: item.bundleId,
        variationId: item.modelo[indexSelected].id,
      };

      return (
        <>
          <ButtonBpool
            onClick={() => addbundle(data)}
            loading={loading}
            theme="primary"
            full={true}
            text={I18n.get("Selecionar")}
          />
        </>
      );
    }

    if (status === 1) {
      const data = {
        bundleId: "",
        variationId: "",
      };
      return (
        <>
          <ButtonBpool
            onClick={() => addbundle(data)}
            loading={loading}
            text={I18n.get("Remover")}
            size="small"
            className="duble"
            theme="secondary"
          />
          <ButtonBpool
            theme="primary"
            onClick={() => addbundle(data)}
            loading={loading}
            text={I18n.get("Selecionado")}
            size="small"
            className="duble"
          />
        </>
      );
    }
  };

  return (
    <S.Card windowWidth={windowWidth} full={full} data-label={cardData?.titulo}>
      <S.HeaderCard>
        <S.TitleHeaderCard windowWidth={windowWidth}>
          {cardData?.titulo}
        </S.TitleHeaderCard>
      </S.HeaderCard>
      <S.ContentCard>
        <S.Item windowWidth={windowWidth}>
          <strong>{I18n.get("Valor")}:</strong>
          {cardData?.modelo[indexSelected]?.valor}
        </S.Item>
        {/* <S.Item windowWidth={windowWidth}>
          <strong>Prazo:</strong>
          {cardData?.prazo}
        </S.Item>
        */}
        <S.WrapperModels>
          <p>
            <strong>{I18n.get("Modelos")}:</strong>
          </p>
          <ul>
            {cardData?.modelo.map((item, index) => (
              <li key={index}>
                <S.ButtonCard
                  onClick={() => setIndexSelected(index)}
                  selected={index === indexSelected ? true : false}
                  windowWidth={windowWidth}
                >
                  {item?.variationType}
                </S.ButtonCard>
              </li>
            ))}
          </ul>
        </S.WrapperModels>

        <S.WrapperInfo>
          {full && <p>{cardData?.modelo[indexSelected]?.descricao}</p>}
          {!full && (
            <p>
              {cardData?.modelo[indexSelected]?.descricao &&
                cardData?.modelo[indexSelected]?.descricao.substring(0, 80) +
                "..."}
            </p>
          )}
        </S.WrapperInfo>
        <S.ActionCard status={cardData?.status}>
          {renderButtons(cardData?.status, cardData)}
        </S.ActionCard>
      </S.ContentCard>
    </S.Card>
  );
};
