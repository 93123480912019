import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import Content from "../../../components/UI/Content";
import Main from "../../../components/UI/Main";
import { Button } from "../../../components/UI/Button";
import { Unlogged } from "../../../components/UI/Unlogged";
import { useRegistrationFlow } from "../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";

import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";

import * as S from "./styles";

export const ApplicationReceived = () => {
  const { resendMail } = useRegistrationFlow();
  const [userInfos, setUserInfos] = useState({ name: "", mail: "", cnpj: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleResendMail = async () => {
    setIsLoading(true);
    await resendMail({ registrationId: userInfos.cnpj, email: null });
    setVisible(true);
    setIsLoading(false);
  };

  useEffect(() => {
    const user = localStorage.getItem("@BPOOL-registration-flow-user");
    const userParsed = JSON.parse(user);

    if (user) {
      setUserInfos({
        name: userParsed.name,
        mail: userParsed.mail,
        cnpj: userParsed.cnpj,
      });
    } else {
      setUserInfos({
        name: "",
        mail: "",
        cnpj: "",
      });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Unlogged>
      <Content>
        <div className="container">
          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={12} offset={6}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Application Received")}</S.H4>
                      </S.TitleWithIcon>
                      <S.Paragraph style={{ fontWeight: 700, marginTop: 40 }}>
                        {I18n.get("Hi")}
                        <span>{`${userInfos.name},`}</span>
                        {I18n.get(
                          "Thanks. Your initial application was submitted!"
                        )}
                      </S.Paragraph>
                      <S.Paragraph style={{ marginTop: 60 }}>
                        {I18n.get("You will soon receive an email in")}
                        <span>{userInfos.mail}</span>
                        {I18n.get("from BPool confirming your registration.")}
                      </S.Paragraph>
                    </S.RowCenter>
                  </Col>
                </Row>
                <Row style={{ marginTop: 40 }}>
                  <Col
                    xs={24}
                    sm={7}
                    offset={9}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      full
                      text={I18n.get("Resend email")}
                      className=""
                      theme="primary"
                      // outline
                      loading={isLoading}
                      onClick={handleResendMail}
                      isDisabled={!Boolean(userInfos.cnpj)}
                    />
                  </Col>
                </Row>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>

      <ModalBp
        visible={visible}
        // bodyText={inputsForm.masterUserEmail}
        subBodyText={
          <>
            <p>
              {I18n.get(
                "We resend the confirmation email. If you have not received it and your e-mail is 'email@informando.com', please contact support.partner@bpool.co"
              )}
            </p>
            <p>
              {I18n.get(
                "If it is not your email, please, submit a new application again."
              )}
            </p>
          </>
        }
        okText={I18n.get("Ok")}
        // cancelText={I18n.get("No, I need to adjust.")}
        handleClickOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        // typeModal="isConfirm"
      />
    </Unlogged>
  );
};
