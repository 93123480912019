import axios from 'axios';
import { Auth } from 'aws-amplify';
import Config from '../../../config';

axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';

export const useRegistrationFlowLeader = () => {
  const getStepBarLeader = async (token) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/leader/${token}/status-bar`
    );
    return data;
  };

  const getAboutYou = async (token) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/leader/verification-token/${token}`
    );
    return data;
  };

  const getAboutYouLogged = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    
    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/partner/leader/about`,
      config
    );
    return data;
  };

  const getIndustry = async (form) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/${form.partnerId}/leader/${form.leaderId}/industries`
    );
    return data;
  };

  const getIndustryLogged = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/partner/leader/industries`,
      config
    );
    return data;
  };

  const getIndustriesSelectedLogged = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/partner/leader/industry-selected`,
      config
    );
    return data;
  };

  const putAboutYou = async (form) => {
    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/partner/leader/about`,
      form
    );

    return data;
  };

  

  const getCasesAndAwards = async (token) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/leader/${token}/cases-awards`
    );
    return data;
  };

  const getCasesAndAwardsLogged = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/partner/leader/cases-awards`,
      config
    );
    return data;
  };


  

  const getIndustriesDropDownLogged = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/partner/leader/industry-select`,
      config
    );
    return data;
  };

  const getIndustries = async (token) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/leader/${token}/business-type`
    );
    return data;
  };

  const getIndustriesToDropdownCases = async (token) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/leader/${token}/industry-select`
    );
    return data;
  };

  const putCasesAndAwards = async (form) => {
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/partner/leader/cases-awards`,
      form
    );

    return data;
  };

  const putCasesAndAwardsLogged = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/admin/partner/leader/cases-awards`,
      form,
      config
    );
    return data;
  };

  const putAboutYouLogged = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/admin/partner/leader/about`,
      form,
      config
    );
    return data;
  };



  const updateIndustry = async (form) => {
    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/partner/leader/industries`,
      form
    );

    return data;
  };

  const updateIndustryLogged = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/admin/partner/leader/industries`,
      form,
      config
    );

    return data;
  };

  const getResume = async (token) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/leader/${token}/resume`
    );
    return data;
  };

  const finishLeader = async (form) => {
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/partner/leader/finish`,
      form
    );
    return data;
  };

  const changePasswordLeader = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/admin/partner/leader/password-update`,
      form,
      config
    );
    return data;
  };

  const getTimezones = async (token) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/timezones`
    );
    return data;
  };

  const getTimezonesId = async (id) => {

    const encodeId = encodeURIComponent(id);
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/timezones/${encodeId}`
    );
    return data.data;
  };

  return {
    getStepBarLeader,
    getAboutYou,
    getAboutYouLogged,
    putAboutYou,
    getCasesAndAwards,
    getCasesAndAwardsLogged,
    getIndustriesDropDownLogged,
    getIndustries,
    getIndustriesToDropdownCases,
    putCasesAndAwards,
    putCasesAndAwardsLogged,
    updateIndustry,
    updateIndustryLogged,
    getIndustry,
    getIndustryLogged,
    getIndustriesSelectedLogged,
    getResume,
    finishLeader,
    changePasswordLeader,
    putAboutYouLogged,
    getTimezones,
    getTimezonesId
  };
};
