import React from "react";
import { I18n } from "aws-amplify";

import * as S from "./styles";

export const Drawer = ({
  open,
  handleClose,
  children,
  buttons = null,
  width = 720,
}) => {
  return (
    <S.Drawer
      width={width || 720}
      onClose={handleClose}
      visible={open}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <div className="body-drawer">{children}</div>

      {buttons ? <S.ButtonsContent>{buttons}</S.ButtonsContent> : null}
    </S.Drawer>
  );
};
