import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { Link, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PROJECTS_API } from "../../../../../store/actions/projects";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import Content from "../../../../../components/UI/Content";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { ModalBp } from "../../../../../components/UI/ModalBp/ModalBp";
import Main from "../../components/Main";
import { H2Border } from "../../../../../components/UI/Titles";
import { AssetBundle } from "../../../../../components/UI/AssetBundle";
import { StepsCreateProject } from "../../components/StepsCreateProject";
import TopInformation from "../../components/TopInformation";
import { ConvertValuesCreativeScope } from "../../../../../../v2/pages/ProjectsList/Invitations/ProjectDetails/utils/convertValuesCreativeScope";
import GlobalFileBox from "../../../../Client/CreateProject/components/GlobalFileBox";

import * as S from "./styles";

import IcoForecast from "./svgs/ico-step5-forecast.svg";
import IcoCheck from "./svgs/ico-step5-check.svg";
import IcoPayment from "./svgs/ico-step5-payment.svg";

export const CreateProjectPartnerStep4 = () => {
  const { sendSuggestion, putGlobal, removeGlobal } = useProjects();
  let params = useParams();
  const [projectDetails, setProjectDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [projectErrors, setProjectErrors] = useState({});
  const state = useSelector((state) => state);
  let history = useHistory();
  const [listOfAssets, setListOfAssets] = useState([]);
  const [dataValuesCreativeScope, setDataValuesCreativeScope] = useState({});
  const [currencyProject, setCurrencyProject] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const getStep = () => {
    function getProject() {
      const data = {
        projectId: params.projectId,
      };

      PROJECTS_API.get_project_step6(data).then((response) => {
        setProjectDetails(response?.payload?.project?.data);
        setListOfAssets(
          response?.payload?.project?.data?.bundle?.variations[0]?.assets
        );
        const objDataValuesCreativeScope = ConvertValuesCreativeScope({
          data: response?.payload?.project?.data,
          isInvite: false,
        });
        setDataValuesCreativeScope(objDataValuesCreativeScope);
        setCurrencyProject(response?.payload?.project?.data?.currency);
      });
    }
    getProject();
  };

  function getCategories() {
    if (!state?.bp?.sectorsFlat) return [];
    if (state?.sameCategoryOnly) return [];
    if (!state.categoryIds) return [];

    return (
      projectDetails?.categoryIds &&
      projectDetails?.categoryIds.map((categoryId) => {
        const category = state?.bp?.sectorsFlat.filter(
          (item) => item.code == categoryId
        )[0];

        return category.label;
      })
    );
  }

  const sendToServer = async () => {
    try {
      setLoader(true);

      const id = params.projectId;

      const infosToServer = {
        projectId: id,
      };

      const response = await sendSuggestion(infosToServer);
      if (response.success) {
        setOpenModal(true)
      } else {
        setProjectErrors(response.errors);
        setLoader(false);
      }
    } catch (error) {
      setProjectErrors(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getStep();
  }, []);

  const { demandFiles = [] } = projectDetails;

  const stepItens = [
    {
      key: 1,
      label: I18n.get("Informações"),
      url: `/parceiro/projetos/v2/criar/informacoes/${params.projectId}`,

    },
    {
      key: 2,
      label: I18n.get("Briefing"),
      url: `/parceiro/projetos/v2/criar/briefing/${params.projectId}`,
    },
    {
      key: 3,
      label: I18n.get("Tipo"),
      url: `/parceiro/projetos/v2/criar/tipo/${params.projectId}`,
    },
    {
      key: 4,
      label: I18n.get("Resumo"),
      url: `/parceiro/projetos/v2/criar/resumo/${params.projectId}`,
    },
  ];

  const getStepError = (step) => {
    let stepError = {};
    stepError = stepItens.find((i) => i.key == step);

    return stepError;
  };

  const handleConvertedValuesCreativeScope = async ({ values, currency }) => {
    const data = {
      ...values,
      currency,
    };
    const objDataValuesCreativeScope = ConvertValuesCreativeScope({
      data,
      isInvite: false,
    });
    setDataValuesCreativeScope(objDataValuesCreativeScope);

    const listAssetsUpdated = listOfAssets.map((item1) => {
      const matchingItem = values?.bundle?.variationResponse?.assets?.find(
        (item2) => item2.code === item1.code
      );

      if (matchingItem) {
        return {
          ...item1,
          priceFmt: matchingItem.priceFmt,
          priceTotalFmt: matchingItem.priceTotalFmt,
        };
      }

      return item1;
    });
    setListOfAssets(listAssetsUpdated);
  };

  const saveGlobal = async (data) => {
    const dataSend = {
      file: { ...data },
      projectId: projectDetails?.id,
    };

    if (data?.id) {
      const response = await putGlobal(dataSend);
      if (response?.success) {
        getStep();
      }
    } else {
      const response = await removeGlobal(dataSend);
      if (response?.success) {
        getStep();
      }
    }
  };

  return (
    <>
      <LoggedPage>
        <Content>
          <div className="container">
            <Row>
              <Col sm={12}>
                <H2Border>{I18n.get("Novo Projeto")}</H2Border>
              </Col>
              <Col sm={12} justify="end"></Col>
            </Row>

            <Row>
              <Col sm={24}>
                <S.StyleMain>
                  <StepsCreateProject
                    active={4}
                    projectId={params.projectId || ""}
                  />
                </S.StyleMain>

                <Main bgColor="#fff" padding="30px">
                  <TopInformation>
                    <>
                      <h3>{I18n.get("Resumo do projeto")}</h3>

                      <p>{I18n.get("Veja abaixo as informações mais relevantes do seu projeto")}</p>
                    </>
                  </TopInformation>

                  <S.ContentReview>
                    <Row>
                      <Col xs={12} sm={12}>
                        <S.WrapperReview>
                          <S.TitleReview>
                            {I18n.get("Informações")}
                          </S.TitleReview>
                          <S.BoxReview>
                            <ul>
                              <li>
                                <strong>{I18n.get("Tipo")}:</strong>
                                <span>{projectDetails?.projectTypeDescription}</span>
                              </li>
                              <li>
                                <strong>{I18n.get("Nome do Projeto")}:</strong>
                                <span>{projectDetails?.projectName}</span>
                              </li>
                              <li>
                                <strong>{I18n.get("Número do Projeto")}:</strong>
                                <span>{projectDetails?.projectSKU}</span>
                              </li>
                              <li>
                                <strong>{I18n.get("Produto")}:</strong>
                                <span>
                                  {projectDetails?.brandName} -{" "}
                                  {projectDetails?.productName}
                                </span>
                              </li>
                            </ul>
                          </S.BoxReview>
                        </S.WrapperReview>
                      </Col>

                      <Col xs={12} sm={12}>
                        <S.WrapperReview>
                          <S.TitleReview>{I18n.get("Detalhes")}</S.TitleReview>
                          <S.BoxReviewDetails spacing="extra-large">
                            <ul>
                              <li>
                                <div>
                                  <img src={IcoForecast} alt="Ico Forecast" />
                                </div>
                                <div style={{ marginLeft: 25 }}>
                                  <strong>{I18n.get("Previsão do projeto")}:</strong>
                                  <br />
                                  <p>
                                    <b>{I18n.get("Início")}:</b>{" "}
                                    {projectDetails?.estimatedStartDate}
                                  </p>
                                  <p>
                                    <b>{I18n.get("Término")}:</b>{" "}
                                    {projectDetails?.partnerDeliveryEstimated}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <img src={IcoCheck} alt="Ico Check" />
                                </div>
                                <div style={{ marginLeft: 25 }}>
                                  <strong>
                                    {I18n.get("Previsão do projeto Término")}:
                                  </strong>
                                  <span>
                                    {projectDetails?.estimatedDurationInMonths
                                      ? projectDetails?.estimatedDurationInMonths
                                      : "0"}{" "}
                                    {I18n.get("mes(es) e")}{" "}
                                    {projectDetails?.estimatedDurationInWeeks
                                      ? projectDetails?.estimatedDurationInWeeks
                                      : "0"}{" "}
                                    {I18n.get("semana(s)")}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <img src={IcoPayment} alt="Ico Payment" />
                                </div>
                                <div style={{ marginLeft: 25 }}>
                                  <strong>
                                    {I18n.get("Previsão pagamento com cliente")}:
                                  </strong>
                                  <span>
                                    {projectDetails?.paymentTermDays}{" "}
                                    {I18n.get("dias corridos")}
                                  </span>
                                </div>
                              </li>
                              {/* <li>
                              <div>
                                <img
                                  src={IcoTime}
                                  alt="Ico Time"
                                  style={{ width: "26px" }}
                                />
                              </div>
                              <div style={{ marginLeft: 25 }}>
                                <strong>{I18n.get("Exclusivity Term")}:</strong>
                                <span>
                                  {projectDetails?.nonCompeteInDaysFmt}
                                </span>
                              </div>
                            </li> */}
                            </ul>
                          </S.BoxReviewDetails>
                        </S.WrapperReview>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24}>
                        <S.WrapperReview>
                          <S.TitleReview>
                            {I18n.get("Escopo Criativo")}
                          </S.TitleReview>
                          <S.BoxReview>
                            <AssetBundle
                              isInvite={false}
                              isClient={false}
                              listOfAssets={listOfAssets?.length ? listOfAssets : []}
                              dataValues={dataValuesCreativeScope}
                              convertedValues={handleConvertedValuesCreativeScope}
                              reload={getStep}
                              isModalConfirm={true}
                              descriptiveHourMan={
                                projectDetails?.descriptiveHourMan
                              }
                              isCart
                              currencyProject={currencyProject}
                            />
                          </S.BoxReview>
                        </S.WrapperReview>

                        <div style={{ marginTop: 30 }}>
                          <a href={projectDetails?.pdfProject} target="_blank">
                            <ButtonBpool
                              text={I18n.get("Baixar PDF")}
                              className=""
                              theme="primary"
                              disabled={projectDetails?.pdfProject ? false : true}
                            />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </S.ContentReview>

                  {projectDetails?.globalAuthorization ? (
                    <Row>
                      <Col xs={24} sm={24}>
                        <S.WrapperReview>
                          <S.BoxReview style={{ padding: 0 }}>
                            <GlobalFileBox
                              title={I18n.get("Atenção!")}
                              description={I18n.get(
                                "Esta é uma marca Global. Anexe aqui a aprovação de global para este projeto."
                              )}
                              username={state?.auth?.username}
                              action={(data) => saveGlobal(data)}
                              file={projectDetails?.globalAuthorization}
                            />
                          </S.BoxReview>
                        </S.WrapperReview>
                      </Col>
                    </Row>
                  ) : null}

                  <Row>
                    <Col lg={24}>
                      {Object.keys(projectErrors).map((item, index) => {
                        let stepErrorItem = getStepError(
                          projectErrors[item]?.step
                        );

                        return (
                          <p>
                            <Link to={stepErrorItem?.url}>
                              {" "}
                              <span class="icon has-text-danger">
                                <i class="fas fa-exclamation"></i>
                              </span>
                              {projectErrors[item].errorMessage} [
                              {I18n.get("Editar")}]
                            </Link>
                          </p>
                        );
                      })}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={24}>
                      <S.FooterButtons className="buttonsContainer">
                        <ButtonBpool
                          text={I18n.get("Voltar")}
                          theme="secondary"
                          onClick={() =>
                            history.push(`/parceiro/projetos/v2/criar/tipo/${params.projectId}`)}
                        />
                        <ButtonBpool
                          text={I18n.get("Enviar sugestão")}
                          className=""
                          theme="primary"
                          loading={loader}
                          onClick={() => sendToServer()}
                        />
                      </S.FooterButtons>
                    </Col>
                  </Row>
                </Main>
              </Col>
            </Row>
          </div>
        </Content>
      </LoggedPage>

      <ModalBp
        visible={openModal}
        bodyText={I18n.get('Projeto criado com sucesso')}
        // subBodyText={I18n.get('Your information has been successfully saved.')}
        okText={I18n.get('Voltar para projetos')}
        // cancelText={I18n.get("Cancelar")}
        handleClickOk={() => history.push(`/parceiro/projetos/v2`)}
        onCancel={() => setOpenModal(false)}
      // typeModal="isConfirm"
      // isDanger={true}
      />
    </>
  );
};
