import React from "react";

import { I18n } from "aws-amplify";

import { Modal } from "antd";

import { FormUtils } from "../../utils/form";

import NumericField from "../form/numeric-field-type-number";

import QuotationAssetForm from "./quotation-asset-form";
import QuotationSectionEditForm from "./quotation-section-edit-form";

import FormatCurrency from "../ui/format-currency";

import iconRateCart from "../../image/ratecart.png";
import iconAssets from "../../image/ico-assets.png";
import iconHoraHomem from "../../image/ico-hourman.png";
import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";

const ptBR = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
});

class QuotationAssetSummary extends React.Component {
  constructor(props) {
    super();

    let assetsClean = props?.assetsCombo.filter(
      (item) => item.isRateCard == false && item.isManHour == false
    );
    let assetsRateCart = props?.assetsCombo.filter(
      (item) => item.isRateCard === true
    );
    let assetsHourMan = props?.assetsCombo.filter(
      (item) => item.isManHour === true
    );

    let assets = props?.assetsCombo;

    this.state = {
      errors: {},
      task: {},
      assets: assets,
      assetsHourMan: assetsHourMan,
      assetsRateCart: assetsRateCart,
      assetsClean: assetsClean,
      selectedAsset: {},
      viewDescription: false,
    };

    this.delItem = this.onDelItem.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState) return;
    if (!this.props.task) return;

    if (this.props.task.id == prevState.task.id) return;

    // atualiza a task
    var nextState = Object.assign({}, prevState, { task: this.props.task });

    // atualiza os erros do form
    FormUtils.updateErrors(nextState, this.props.errors);

    if (this.props.task.result) {
      // se task for sucesso, limpa os campos do form
      FormUtils.resetForm(nextState);

      nextState.file = {};
    }

    this.setState(nextState);
  }

  changeQuantity = (item, e) => {
    const value = e.target.value;

    const data = {
      projectId: this.props.match.params.projectId,
      quotationId: this.props.quotation.id,
      sectionId: this.props.section.id,
      ...item,
      quantity: value,
    };

    this.props.update_universal_quotation_asset(data);
  };

  onDelItem(item, e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      quotationId: this.props.quotation.id,
      sectionId: this.props.section.id,
      ...item,
    };

    this.props.delete_universal_quotation_asset(data);
  }

  getItemType(value) {
    return (
      (this.props.bp.eventQuotationItemType &&
        this.props.bp.eventQuotationItemType.find(
          (item) => item.code == value
        )) ||
      {}
    );
  }

  getItemCategory(value) {
    return (
      (this.props.bp.eventSegments &&
        this.props.bp.eventSegments.find((item) => item.code == value)) ||
      {}
    );
  }

  handleInfo = (item) => {
    Modal.info({
      title: item?.label,
      width: 800,
      content: (
        <div>
          <p>{item?.description}</p>
        </div>
      ),
      onOk() {},
    });
  };

  render() {
    const { project = {} } = this.props;

    const quotation = this.props.quotation || {};
    const currency = this.props.currency || {};

    const section = this.props.section || {};

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    const canEditSytle = canEdit ? {} : { display: "none" };

    const assetTypes =
      this.props.project.assetTypes &&
      this.props.project.assetTypes
        .filter(
          (item) => item.code == "PL" || item.code == "GE" || item.code == "CR"
        )
        .sort((a, b) => (a.label > b.label) - (a.label < b.label));

    var assets = this.props.project.assets;

    return (
      <>
        <section className="quotation-asset-summary">
          <div style={{ display: "flex" }}>
            <div style={{ flex: "1", alignItems: "center" }}>
              <p className="title is-5">
                {section.sectionName}{" "}
                <span style={{ fontWeight: "normal", opacity: "0.6" }}>
                  {" "}
                  &middot; {I18n.get("Itens Internos")}{" "}
                </span>
                {canEdit && (
                  <>
                    <a
                      onClick={(e) => this.setState({ showEditSection: true })}
                      style={{
                        marginLeft: "1rem",
                        fontWeight: "normal",
                        fontSize: "0.9rem",
                      }}
                    >
                      {I18n.get("Editar")}
                    </a>
                  </>
                )}
              </p>
            </div>

            <div
              style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}
            >
              {canEdit && (
                <>
                  <ButtonBpool
                    text={I18n.get("Adicionar Entregável")}
                    theme="secundary"
                    onClick={(e) => this.setState({ showAddAsset: true })}
                  />
                </>
              )}
            </div>
          </div>

          <br />
          <br />

          <div className="columns head">
            <div className="column is-5">{I18n.get("Item Interno")}</div>

            <div className="column is-1">{I18n.get("Qtd.")}</div>

            <div className="column is-3">{I18n.get("Valor")}</div>

            <div className={`column ${canEdit ? "is-2" : "is-3"}`}>
              {I18n.get("Total")}
            </div>

            <div className="column is-1" style={canEditSytle}></div>
          </div>

          {section.items &&
            section.items.map((item, index) => {
              const asset =
                this.props.project.assetsCombo &&
                this.props.project.assetsCombo.find((a) => a.code == item.code);

              asset &&
                (asset.typeLabel =
                  asset.type &&
                  asset.type.map((code) => {
                    const assetType =
                      (assetTypes &&
                        assetTypes.find((item) => item.code == code)) ||
                      {};

                    return assetType.label;
                  }));

              return (
                <div className="columns">
                  <div className="column is-5">
                    <p style={{ fontSize: "110%" }}>
                      {asset && asset.label}{" "}
                      <span
                        style={{
                          margin: "-3px 0 0 10px",
                          position: "relative",
                          top: "-3px",
                        }}
                      >
                        {asset.isRateCard && (
                          <img src={iconRateCart} style={{ width: "20px" }} />
                        )}
                        {asset.isManHour && (
                          <img src={iconHoraHomem} style={{ width: "20px" }} />
                        )}

                        {!asset.isManHour && !asset.isRateCard && (
                          <img src={iconAssets} style={{ width: "20px" }} />
                        )}
                      </span>
                    </p>
                    <p style={{ fontSize: "80%" }}>
                      <a
                        className="openDescriptionAsset"
                        onClick={() => this.handleInfo(asset)}
                      >
                        {I18n.get("Saiba mais")}{" "}
                      </a>
                      {/* {asset && asset.typeLabel} */}
                    </p>
                  </div>

                  <div className="column is-1 has-text-right">
                    <p>
                      {canEdit && (
                        <>
                          <NumericField
                            value={item.quantity}
                            visited={true}
                            successIcon={false}
                            onChange={this.changeQuantity.bind(this, item)}
                          />
                        </>
                      )}
                      {!canEdit && <>{item.quantity}</>}
                    </p>
                  </div>

                  <div className="column is-3 has-text-right">
                    <p style={{ fontSize: "110%" }}>
                      {/* {ptBR.format(item.amount)} */}
                      <FormatCurrency
                        amount={item.amount}
                        currency={currency}
                      />
                    </p>
                    <p style={{ fontSize: "80%" }}>
                      {I18n.get("Preço unitário")} &nbsp;
                      {/* {ptBR.format(item.price)} */}
                      <FormatCurrency amount={item.price} currency={currency} />
                    </p>
                  </div>

                  <div
                    className={`column ${
                      canEdit ? "is-2" : "is-3"
                    } has-text-right`}
                  >
                    <p style={{ fontSize: "110%" }}>
                      {/* {ptBR.format(item.subtotal)} */}
                      <FormatCurrency
                        amount={item.subtotal}
                        currency={currency}
                      />
                    </p>
                    <p style={{ fontSize: "80%" }}>
                      {/* {I18n.get("Incluso")} {item.tax}{I18n.get("% impostos")} */}
                    </p>
                  </div>

                  <div
                    className="column is-1 has-text-centered"
                    style={canEditSytle}
                  >
                    <br />
                    <a
                      onClick={(e) => this.delItem(item, e)}
                      style={{ color: "#434343" }}
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                </div>
              );
            })}

          {section.items && section.items.length == 0 && (
            <>
              <div className="columns">
                <div className="column is-12" style={{ fontSize: "85%" }}>
                  {I18n.get("Nenhum item adicionado.")}
                </div>
              </div>
            </>
          )}

          <div className="columns total">
            <div
              className={`column ${
                canEdit ? "is-2" : "is-3"
              } is-offset-9 has-text-right`}
            >
              {/* {ptBR.format(section.subtotal)} */}
              <FormatCurrency amount={section.subtotal} currency={currency} />
            </div>
            <div className="column is-1" style={canEditSytle}></div>
          </div>

          {(this.state.assetsRateCart.length > 0 ||
            this.state.assetsHourMan.length > 0) && (
            <>
              <div className="legendAssets borderTop">
                {this.state.assetsRateCart.length > 0 && (
                  <p>
                    <span className="icone">
                      <img src={iconRateCart} style={{ width: "20px" }} />
                    </span>
                    <span className="text">
                      <b>{I18n.get("Itens Live Marketing")}:</b>{" "}
                      {I18n.get(
                        "Itens pré-precificados para serviços de live marketing, como eventos, ativações, promoções e outros."
                      )}
                    </span>
                  </p>
                )}
                {this.state.assetsHourMan.length > 0 && (
                  <p>
                    <span className="icone">
                      <img src={iconHoraHomem} style={{ width: "20px" }} />
                    </span>
                    <span className="text">
                      <b>{I18n.get("Hora Homem")}:</b>{" "}
                      {I18n.get(
                        "Tabela referencial de valor da hora de profissionais da agência pré-estabelecido pelo cliente."
                      )}
                    </span>
                  </p>
                )}

                <p>
                  <span className="icone">
                    <img src={iconAssets} style={{ width: "20px" }} />
                  </span>
                  <span className="text">
                    <b>{I18n.get("Assets")}:</b>{" "}
                    {I18n.get(
                      "Itens pré-precificados para serviços criativos ou de capital intelectual como um todo."
                    )}
                  </span>
                </p>
              </div>
            </>
          )}
        </section>

        <Modal
          visible={this.state.showAddAsset}
          width={900}
          footer={null}
          onCancel={(e) => this.setState({ showAddAsset: false })}
        >
          <QuotationAssetForm
            {...this.props}
            quotation={quotation}
            currency={currency}
            section={section}
            closeModal={(e) => this.setState({ showAddAsset: false })}
          />
        </Modal>

        <Modal
          visible={this.state.showEditSection}
          width={900}
          footer={null}
          onCancel={(e) => this.setState({ showEditSection: false })}
        >
          {this.state.showEditSection && (
            <>
              <QuotationSectionEditForm
                {...this.props}
                quotation={quotation}
                currency={currency}
                section={section}
                closeModal={(e) => this.setState({ showEditSection: false })}
              />
            </>
          )}
        </Modal>
      </>
    );
  }
}

export default QuotationAssetSummary;
