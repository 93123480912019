import React, { useState, useEffect } from 'react';
import * as S from './styles';
import readingTime from 'reading-time';
import NewsInfo from '../NewsInfo';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'aws-amplify';
import LoadingPage from '../../pages/loading';

const NewsContainer = ({auth, news}) => {
    const [loading, setLoading] = useState(true);

    useEffect (() => {
        setLoading(false);
    }, [news])

    return(
        <>
        <S.ContainerNews>
            {!loading && news && 
                news.map((res, index) => (
                        <S.DivNews key={index} href={auth.is_client ? `/#/cliente/news/${res.slug}` : `/#/parceiro/news/${res.slug}`}>
                            <S.DivImageNews>
                                <picture>
                                    <img src={res.image && res.image.url} />
                                </picture>
                            </S.DivImageNews>
                            <S.DivContent>
                                <S.ContentCategory>{res.category && res.category.name}</S.ContentCategory>
                                <S.ContentTitle>{res.title}</S.ContentTitle>
                                <NewsInfo date={res.published_at} time={Math.ceil(readingTime(res.content).minutes)} author={res.author}/>
                                <S.ContentText>
                                    {res.description}
                                    {auth.is_client &&(<Link to={`/cliente/news/${res.slug}`}>{I18n.get("Ler Mais")} </Link> )}
                                    {auth.is_partner &&(<Link to={`/parceiro/news/${res.slug}`}>{I18n.get("Ler Mais")} </Link> )}
                                </S.ContentText>
                            </S.DivContent>
                        </S.DivNews>
                ))
            }{loading &&
                <S.LoaderDiv>
                    <LoadingPage/>
                </S.LoaderDiv>
            }
        </S.ContainerNews>
        </>
    )
}

const mapStateToProps = state => {
    return state;
};

export default connect(
    mapStateToProps,
)(withRouter(NewsContainer));