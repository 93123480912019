import React from "react";
import * as S from "./styles";

export const H2Border = ({ children }) => {
  return <S.TitleBorder>{children}</S.TitleBorder>;
};

export const H3Border = ({ children }) => {
  return <S.TitleH3Border>{children}</S.TitleH3Border>;
};

export const H5Border = ({ children }) => {
  return <S.TitleH5Border>{children}</S.TitleH5Border>;
};
