import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { CLIENT_USER_CREATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_client_create_user = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.put(`${Config.API_BASE_URL}/client/${data.clientId}/user2/edit`, data, config)
            .then(response => {

                const payload = {
                    ...response.data,
                    errorsUpdate: {},
                    task: {
                        result: true
                    }
                }

                dispatch({
                    type: CLIENT_USER_CREATE,
                    payload: {...payload}
                });

                return payload;

            })
            .catch(error => {

                const payload = {
                    errorsUpdate: error.response.data,
                    task: {
                        result: false
                    }
                }

                dispatch({
                    type: CLIENT_USER_CREATE,
                    payload: {...payload}
                });

                return payload;
            });
        }).catch(error => { 
            //  dispatch({ type: EXPIRED }); 
            return error;
 }); 
 };
}

export default api_client_create_user;