import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import LandingPage from '../page-templates/fillForm';

import bannerImage3 from '../../image/banner-image3.png';

export default class WallPage extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (
            <LandingPage>
                    <div className="hero-body">
                        <div className="container">

                            <div className="columns">

                                <div className="column is-4">

                                    <figure className="image">
                                        <img src={bannerImage3} alt="Banner" />
                                    </figure>

                                </div>

                            </div>

                            <div className="columns">

                                <div className="column is-offset-3 is-6">

                                    <h1 className="subtitle is-4 is-marginless">{I18n.get("Cadastro concluído")}</h1>
                                    <div className="colot-line"></div>


                                    <div>

                                        <br />
                                            <p>{I18n.get("Obrigado por todas informações.")}</p>
                                        <br />
                                            <p>{I18n.get("Você concluiu uma fase importante para fazer parte da BPool.")}</p>
                                        <br />
                                            <p>{I18n.get("Agora é só começar!")} <Link to="/login">{I18n.get("Clique aqui")}</Link>.
                                            </p>

                                    </div>

                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />


                                </div>

                            </div>

                        </div>
                    </div>

            </LandingPage>
        );
    }
}

