import styled from "styled-components";
import { Row, Input, Tooltip } from "antd";

const { TextArea } = Input;

export const TopHeader = styled.div`
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 50px 120px;
  margin-top: 40px;
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;
`;


export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  overflow: hidden;
`;

export const Box = styled.div`
  border-radius: 10px;
  background: #ffffff;
  padding: 50px 30px 30px;
  height: calc(100% - 50px);
  box-sizing: border-box;
`;

export const CloseScope = styled.div`
  background: #2f2f2f;
  padding: 20px 30px;
  border-radius: 10px;
  margin: 0 0 60px;
  h3 {
    color: #fff;
    margin-bottom: 20px;
  }
  p {
    color: #fff;
  }
`;

export const RowMarge = styled(Row)`
  margin-top: 50px;
  margin-bottom: 130px;
`;

export const Edit = styled.a`
  margin-left: 20px;
`;

export const TextAreaEdit = styled(TextArea)`
  height: 200px !important;
  max-height: auto !important;
  border-radius: 10px !important;
`;

export const DisplayInformation = styled.div``;

export const DateInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 14px;
`;

export const ItemFile = styled.a`
  display: block;
  padding: 10px;
  color: #0d141e;
`;
export const IconFile = styled.span`
  margin-right: 10px;
`;

export const LabelFile = styled.span``;

export const ContentInfo = styled.p``;

export const RowFooter = styled.div`
  padding-top: 50px;
  /* margin-bottom: 100px; */
  border-top: solid 1px #000;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsFooter = styled.div`
  display: flex;
  .btnPrev {
    margin-right: 20px;
  }
`;

export const AttachmentSelected = styled.div`
  color: #1883ff;
  font-size: 16px;
  line-height: 24px;
  margin: 5px 0 0 10px;
  width: 100%;
  border-bottom: solid 1px #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 14px;
    color: #1883ff;
    padding: 5px 0;

    &:hover {
      color: #000;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const ContentReview = styled.div`
  margin-top: -10px;
  background-color: #fff;
  padding-bottom: 30px;
`;

export const WrapperReview = styled.div`
  margin-top: 30px;
`;

export const TitleReview = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
`;

export const BoxReview = styled.div`
  border-radius: 10px;
  margin-top: 15px;
  padding: 25px 15px;
  border: 1px solid;
  border-color: #e7e7e7;

  ul {
    li {
      display: flex;
      align-items: baseline;
      margin: ${(props) =>
        props.spacing === "extra-large" ? "50px 0;" : "10px 0;"};
      line-height: 25px;

      :first-child {
        margin-top: 0;
      }

      :last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding-left: 5px;
      }
    }
  }
`;

export const BoxReviewDetails = styled.div`
  border-radius: 10px;
  margin-top: 15px;
  padding: 25px 15px;
  border: 1px solid;
  border-color: #e7e7e7;

  ul {
    li {
      display: grid;
      grid-template-columns: 30px calc(100% - 30px);
      margin-bottom: 10px;
      line-height: 30px;

      :last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding-left: 5px;
      }
    }
  }
`;

export const RowValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Value = styled.div`
  color: #1883ff;
  font-weight: 600;
  font-size: 16px;
`;

export const H4 = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
`;

export const ModelsBoxes = styled.div`
  margin: 30px 0;

  ul {
    display: flex;

    li {
      padding: 10px 15px;
      border-radius: 5px;
      background-color: #1883ff;
      color: #ffffff;
      font-size: 14px;
      margin-right: 15px;

      :last-child {
        margin-right: 0;
      }
    }
  }
`;

export const ContentTooltip = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

export const StyleTooltip = styled(Tooltip)``;

export const Assets = styled.div`
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #e7e7e7;
  padding: 15px;
  background-color: #f2f2f2;

  ul {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 14px;
      margin: 0 0 0 0;

      p {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }

      strong {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
      }

      :first-child {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
`;

export const Important = styled.div`
  margin-top: 30px;

  span {
    font-weight: 600;
    padding-right: 5px;
  }
`;

export const NotesPartnerOrClient = styled.div`
  p {
    font-weight: 400;
    padding-right: 14px;
    margin-bottom: 15px;
  }
`;

export const NotesView = styled.div`
  min-height: 150px;

  p {
    font-weight: 400;
    padding-right: 14px;
  }
`;

export const ErrorInGetPage = styled.div`
  margin-top: 30px;

  p {
    font-weight: 400;
    padding-right: 14px;
    color: red;
  }

  strong {
    color: red;
  }
`;

export const FooterMain = styled.div`
  background-color: #fff;
  padding: 100px 30px 50px 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const ContentLoadingSending = styled.div`
  p {
    margin: 0;
  }
`;
