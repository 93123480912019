import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin: 15px 0;
`;

export const IcoTooltip = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .tp {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #b5becb;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LabelAndComplexity = styled.div`
  width: ${(props) =>
    props?.hasEdit ? "calc(100% - 310px)" : "calc(100% - 270px)"};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;

export const Description = styled.div`
  font-size: 12px;
`;

export const Qty = styled.div`
  width: 80px;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

export const Value = styled.div`
  width: 150px;
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
`;

export const QtyNoEdit = styled.div`
  width: 80px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 22.5px;
  }
  .label {
    color: #515152;
    font-size: 10px;
    font-weight: 600;
    margin: 0;
    line-height: 10px;
  }
`;

export const IcoDel = styled.button`
  width: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
`;
