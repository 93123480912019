import React from 'react';

import { I18n } from 'aws-amplify';

export default class CasesList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            partnerId: props.partnerId,
            leaderId: props.leaderId
        };

        this.delCase = this.onDelCase.bind(this);
    }

    onDelCase(item, e) {
        e.preventDefault();

        const data = Object.assign({}, this.state, { id: item.id });

        this.props.delCase(data);
    }

    getCases() {
        if (!this.props.cases) return [];

        return this.props.cases.filter((item) => item.type == this.props.type);
    }

    getSector(sectorId) {

        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }

    render() {

        const cases = this.getCases();

        return (
            <div>
            {cases && cases.length > 0
                && <table className="table is-bordered is-fullwidth">
                <tbody>
                    <tr>
                        <th>{I18n.get("Marca")}</th>
                        <th className="is-not-mobile">{I18n.get("Produto")}</th>
                        <th className="is-not-mobile">{I18n.get("Categoria")}</th>
                        <th className="is-not-mobile">{I18n.get("Ano")}</th>
                        <th>
                            &nbsp;
                        </th>
                        <th>
                            &nbsp;
                        </th>
                    </tr>
                    {cases.map((item, index) => 
                        <tr key={index}>
                            <td>
                                {item.brand}
                            </td>
                            <td className="is-not-mobile">
                                {item.product}
                            </td>
                            <td className="is-not-mobile">
                                    {/*item.category*/}
                                    {this.getSector(item.category).label}
                            </td>
                            <td className="is-not-mobile">
                                {item.year}
                            </td>
                            <td className="has-text-centered">  
                                <a onClick={(e) => this.props.onEditingChange(item) }>
                                    <i className="fa fa-edit"></i>
                                </a>
                            </td>
                            <td className="has-text-centered">  
                                <a onClick={(e) => this.delCase(item, e)}>
                                    <i className="fa fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                    )
                    }
                </tbody></table>
            }
                </div>
        );
    }
}