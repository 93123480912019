import React from 'react';
import { I18n } from 'aws-amplify';

const Terms = (props) => {

   return (
      <div className="termsContainer" style={{ height: '50vh' }}>
         <div className="termsContent" style={{ height: '45vh' }}>
            <p>
               <strong>TERMO DE BOAS PRÁTICAS</strong>
            </p>
            <p>
               &nbsp;
            </p>
            <p>
               Como líder do pitch <b>{props.projectName}</b>, concordo em colocar meus melhores esforços para:
            </p>
            <ul>
               <li style={{ marginTop: '1.5rem' }}>
                     - Seguir o processo e funil de concorrência BPitch, respeitando as datas propostas no cronograma elaborado;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                     - Comunicar possíveis mudanças de cronograma ao meu B.Manager BPool, com o máximo de antecedência possível;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                     - Responder a possíveis dúvidas dos participantes do processo de pitch de forma ágil e ética, dando sempre condições iguais a todos;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                     - Respeitar os critérios e pesos que defini na plataforma como forma de determinar vencedor, segundo e terceiro colocados;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                     - Ao final do processo, dar feedback de forma construtiva através de scorecards e comentários abertos aos não vencedores do pitch.
               </li>
            </ul>
         </div>
      </div>
   );
}

export default Terms;