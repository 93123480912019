import React, { useEffect, useState } from 'react';
import { I18n } from "aws-amplify";
import Card from '../Card';
import Loading from '../../../../../components/UI/Loading'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { useMeetings } from '../../../../../hooks/useMeetings/useMeetings.hook';

import * as S from './styles';

export const CarouselCards = ({ type, search }) => {
  const { height, width } = useWindowDimensions();
  const { getMeetings, getMeetingsAll } = useMeetings();
  const [isLoading, setIsLoading] = useState(false);
  const [meetingsConfirmeds, setMeetingsConfirmeds] = useState([]);
  const [meetingsConfirmedsBackup, setMeetingsConfirmedsBackup] = useState([]);
  const [meetingsWaitings, setMeetingsWaitings] = useState([]);
  const [meetingsWaitingsBackup, setMeetingsWaitingsBackup] = useState([]);

  const itemsToShow = () => {
    if (width <= 992) {
      return 1;
    } else if (width <= 1200) {
      return 2;
    } else {
      return 3;
    }
  };

  const outerSpacing = () => {
    if (width <= 425) {
      return 10;
    } else if (width <= 550) {
      return width / 7;
    } else if (width <= 767) {
      return width / 5;
    } else if (width <= 992) {
      return width / 3;
    } else {
      return 100;
    }
  };

  const loadMeetings = async () => {
    setIsLoading(true);
    const meetingType = type;

    // const confirmeds = await getMeetings({
    //   meetingStatus: 0,
    //   inviteStatus: 0,
    //   meetingType,
    // });
    // const waitings = await getMeetings({
    //   meetingStatus: 1,
    //   inviteStatus: 1,
    //   meetingType,
    // });
    const meetings = await getMeetingsAll({ meetingType });

    const waitings = meetings?.filter(item => item?.invites[0]?.inviteStatus === 0);
    const confirmeds = meetings?.filter(item => item?.invites[0]?.inviteStatus === 1);

    setMeetingsWaitings(waitings?.length ? waitings : []);
    setMeetingsConfirmeds(confirmeds?.length ? confirmeds : []);

    setMeetingsWaitingsBackup(waitings?.length ? waitings : []);
    setMeetingsConfirmedsBackup(confirmeds?.length ? confirmeds : []);

    setIsLoading(false);
  };

  useEffect(() => {
    loadMeetings();
  }, [type]);

  const filterBySearch = async (search) => {
    if (search) {
      const filteredData = meetingsConfirmedsBackup.filter((item) => {
        return Object.values(item?.projectName)
          .join('')
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setMeetingsConfirmeds(filteredData);
      const filteredDataWaitings = meetingsWaitingsBackup.filter((item) => {
        return Object.values(item?.projectName)
          .join('')
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setMeetingsWaitings(filteredDataWaitings);
    } else {
      setMeetingsConfirmeds(meetingsConfirmedsBackup);
      setMeetingsWaitings(meetingsWaitingsBackup);
    }
  };

  useEffect(() => {
    filterBySearch(search);
  }, [search]);

  return (
    <>
      <S.Wrapper style={{ marginTop: 50 }}>
        <S.Title windowWidth={width}>
          <span>{I18n.get("Confirmadas")}</span>
          <hr />

          <S.Cards>
            {isLoading ? (
              <Loading
                text={I18n.get("Carregando...")}
                sizeText={14}
                sizeIcon={16}
                align="start"
              />
            ) : (
              <S.Carousel
                showArrows={false}
                itemPadding={width <= 1024 ? [] : [0, 20]}
                itemsToShow={itemsToShow()}
                outerSpacing={outerSpacing()}
              >
                {meetingsConfirmeds.length > 0 ? (
                  meetingsConfirmeds.map((card, index) => (
                    <Card key={index} cardData={card} windowWidth={width} />
                  ))
                ) : (
                  <div>{I18n.get("Nenhuma meeting confirmada.")}</div>
                )}
              </S.Carousel>
            )}
          </S.Cards>
        </S.Title>
      </S.Wrapper>

      <S.Wrapper style={{ marginTop: 30 }}>
        <S.Title windowWidth={width}>
          <span>{I18n.get("Aguardando")}</span>
          <hr />

          <S.Cards>
            {isLoading ? (
              <Loading
                text={I18n.get("Carregando...")}
                sizeText={14}
                sizeIcon={16}
                align="start"
              />
            ) : (
              <S.Carousel
                showArrows={false}
                itemPadding={width <= 1024 ? [0, 15] : [0, 10]}
                itemsToShow={itemsToShow()}
                outerSpacing={outerSpacing()}
              >
                {meetingsWaitings.length > 0 ? (
                  meetingsWaitings.map((card, index) => (
                    <Card key={index} cardData={card} windowWidth={width} reload={loadMeetings} />
                  ))
                ) : (
                  <div>{I18n.get("Nenhuma meeting aguardando.")}</div>
                )}
              </S.Carousel>
            )}
          </S.Cards>
        </S.Title>
      </S.Wrapper>

      {/* <S.Wrapper style={{ marginTop: 30 }}>
        <S.Title windowWidth={width}>
          <span>Realizadas</span>
          <hr />

          <S.Cards>
            <S.Carousel
              showArrows={false}
              itemPadding={width <= 1024 ? [] : [0, 20]}
              itemsToShow={itemsToShow()}
              outerSpacing={outerSpacing()}
            >
              {realizadas.map((card, index) => (
                <Card key={index} cardData={card} windowWidth={width} />
              ))}
            </S.Carousel>
          </S.Cards>
        </S.Title>
      </S.Wrapper> */}
    </>
  );
};
