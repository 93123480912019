import { PARTNER_TEMPCART_PROJECT } from './actions';

export const temp_cart = (data) => {

    return (dispatch, getState) => {
        dispatch({
            type: PARTNER_TEMPCART_PROJECT,
            payload: {
                tempCart: data
            }
        });
    }
}

export default temp_cart;
