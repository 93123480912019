import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { Input } from 'antd';
import iconCheck from '../../../../image/check.svg';
export const StyledInputMask = styled(InputMask)`
  height: 82px;
  background-color: ${(props) => (props.bg ? props.bg : '#e7e7e7')} !important;
  background-image: url(${(props) =>
    props.isCorrect ? iconCheck : ''}) !important;
  background-repeat: no-repeat !important;

  background-position-x: 97% !important;
  background-position-y: center !important;

  border-radius: 56px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  color: #979797;
  box-sizing: border-box;
  padding: 0 20px;
  border: solid 1px ${(props) => (props.error ? '#f00' : '#e7e7e7')};
  width: 100%;
`;

// TODO: remover os !importants depois da refaturação concluida
export const StyledInput = styled(Input)`
  height: 82px;
  background-color: ${(props) => (props.bg ? props.bg : '#e7e7e7')} !important;
  background-image: url(${(props) =>
    props.isCorrect ? iconCheck : ''}) !important;
  background-repeat: no-repeat !important;

  background-position-x: 97% !important;
  background-position-y: center !important;

  border-radius: 56px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  color: #979797;
  box-sizing: border-box;
  padding: 0 20px;
  border: solid 1px ${(props) => (props.error ? '#f00' : '#e7e7e7')};

  &.ant-input:read-only {
    height: 82px;
    opacity: 0.5;
  }
`;

export const StyleField = styled.div`
  margin-bottom: 40px;
`;
