import React from 'react';
import { I18n } from 'aws-amplify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import { Button, notification, Icon } from 'antd';

import Config from '../../config';

import { CLIENT_PROJECTS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_client_project_delete = (projectId) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            // /api/client/delete-project/{projectId}

            return axios.delete(`${Config.API_BASE_URL}/client/delete-project/${projectId}`, config)
                .then(response => {
                    dispatch({
                        type: CLIENT_PROJECTS_GET,
                        payload: {
                            projects: response.data,
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    });

                    notification.open({
                        message: I18n.get("Projeto excluido com sucesso!"),
                        description: '',
                        icon: <Icon type="check" style={{ color: '#000' }} />,
                    });

                })
                .catch(error => {
                    notification.open({
                        message: I18n.get("Ops! Algo deu errado"),
                        description: I18n.get("Tente novamente mais tarde"),
                        icon: <Icon type="close" style={{ color: '#000' }} />,
                    });
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_client_project_delete;
