import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_call = (data) => {

    return (dispatch, getState) => {

        var config = {};

        return axios.get(`${Config.API_BASE_URL}/preview/project/${data.projectId}`, config)
        .then(response => {

            const payload = {
                ...response.data,
                task: {
                    result: true
                }
            };

            return payload;
        })
        .catch(error => {

            const payload = {
                error: error.response.data,
                task: {
                    result: false
                }
            };

            return payload;
        });
    };
}

export default api_call;
