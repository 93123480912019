import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { CLIENT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_client = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.put(`${Config.API_BASE_URL}/client/${data.clientId}`, data, config)
                .then(response => {
                    dispatch({
                        type: CLIENT_UPDATE,
                        payload: response.data
                    })
                })
                .catch(error => {

                    dispatch({
                        type: CLIENT_UPDATE,
                        payload: {
                            errors: error.response.data
                        }
                    })
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}


export default api_update_client;