import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import LoggedPage from '../page-templates/logged';

import Menu from './_menu';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class ProfilePage extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        if (this.props.auth.is_master || this.props.auth.is_bpool){
            return (<Redirect to="/bpool/dashboard" />)
        }           

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><Link to="/home">{I18n.get("Admin")}</Link></li>
                        <li className="is-active"><Link to="/cadastro">{I18n.get("Cadastro")}</Link></li>
                    </ul>
                </nav>        

                <Menu {...this.props} />

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
