import React from "react";
import { I18n } from "aws-amplify";
import Loading from "../../../../../../components/UI/Loading";

import * as S from "../../styles";

export const GeneralDemand = ({ isLoading, data }) => {
  return (
    <S.BoxItem>
      <S.BoxItemTitle>
        <p>{I18n.get("Demanda geral")}</p>
        <span />
      </S.BoxItemTitle>
      {isLoading ? (
        <Loading
          text={I18n.get("Carregando...")}
          sizeText={14}
          sizeIcon={16}
          color="#000000"
          align="left"
        />
      ) : (
        <>
          <S.Text>{data?.demand}</S.Text>

          <div>
            <p style={{ marginTop: 15 }}>
              <b>{`${I18n.get("Arquivos")}:`}</b>
            </p>

            {data &&
              data?.demandFiles?.length ?
              data?.demandFiles.map((file) => {
                if (file.type != "audio/mp3") {
                  return (
                    <S.AttachmentSelected key={file?.id}>
                      <a href={file?.url}>{file?.name}</a>
                    </S.AttachmentSelected>
                  );
                }
              }) : (<S.Text style={{ marginTop: 5 }}>{I18n.get("Nenhum arquivo.")}</S.Text>)}
          </div>
        </>
      )}


    </S.BoxItem>
  );
};
