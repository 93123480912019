import React, { Component } from "react";
import { I18n } from 'aws-amplify';
import {
    Row,
    Col,
    Form,
    Icon,
    Spin,
    DatePicker,
    Input,
    Select,
    Button
} from 'antd';

import styled from 'styled-components';

import locale from 'antd/es/date-picker/locale/pt_BR';

import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px 0;
`

const FormBox = styled(Form)`

`




class FilterDashAdminB extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                let dataFrom = moment(values.range[0]).format('YYYY-MM-DD');
                let dataTo = moment(values.range[1]).format('YYYY-MM-DD');

                const data = {
                    from: dataFrom,
                    to: dataTo,
                    countryCode: values.countryCode
                }

                this.props.action(data);
            }
        });
    };
    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const { filters } = this.props

        const DataRanger = new Date;
        const currentDate = moment(DataRanger).endOf('month').format("YYYY-MM-DD");
        const dayDate = moment(DataRanger).startOf('month').add(-1, 'M').format("YYYY-MM-DD");

        if (!filters) {
            return (
                <BoxSpin>
                    <Spin />
                </BoxSpin>
            )
        }


        return (
            <FormBox onSubmit={this.handleSubmit}>
                <Row gutter={6}>
                    <Col sm={6}>
                        <Form.Item
                            label={I18n.get("Pais")}>
                            {getFieldDecorator('countryCode', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: "BRA"
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {filters && filters.country.map((item, index) => {
                                        return <Option className="" disabled={!item.enabled} value={item.code} key={index}>{item.label}</Option>
                                    })}

                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={5}>
                        <Form.Item label={I18n.get("De")}>
                            {getFieldDecorator('range', {
                                rules: [{ required: false, message: 'Please input your Password!' }],
                                initialValue:[moment(dayDate), moment(currentDate)]
                            })(
                                <RangePicker
                                        ranges={{
                                            Today: [moment(), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        // onChange={this.onChangeDatas.bind(this)}
                                        format="DD/MM/YYYY"
                                    />
                            )}
                        </Form.Item>
                    </Col>
                   

                    
                    <Col sm={2}>
                        <Form.Item className="labelClean" label="&nbsp;">
                            <Button
                                className="button bt-bpool black"
                                shape="round"
                                htmlType="submit"
                                disabled={!filters}
                                loading={this.props.task && this.props.task.busy}
                            >
                                OK
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </FormBox>
        )
    }
}



const FormFilterDashAdminB = Form.create({})(FilterDashAdminB);

export default FormFilterDashAdminB;

