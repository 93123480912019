import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import LoggedPage from "../page-templates/logged";

import styled from "styled-components";

import Menu from "./_menu";

import ResponsibleForm from "./responsible-form";

import { PARTNER_API } from "../../actions/partner/actions";

import FormUser from "../../modules/Register/components/formUser";
import FormResponsible from "../../modules/Register/components/formResponsible";
import { TitleBorder, TitleMiddleLine } from "../../modules/components/Titles";
import { CardBorder } from "../../modules/components/Cards";

import {
  Row,
  Col,
  Input,
  notification,
  Select,
  Tag,
  Icon,
  Button,
  Drawer,
  Table,
  Tooltip,
  Form,
} from "antd";

const Btns = styled.p`
  a {
    margin-left: 10px;
  }
`;

const RowSpace = styled(Row)`
  margin: 20px 0;
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (data) => {
      dispatch(PARTNER_API.update_person(data));
    },
    addUser: (data) => {
      dispatch(PARTNER_API.create_user(data));
    },
    updateUser: (data) => {
      dispatch(PARTNER_API.update_user(data));
    },
    deleteUser: (data) => {
      dispatch(PARTNER_API.delete_user(data));
    },
  };
};

class ResponsibleAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      selectedItem: null,
      edit: false,
      show: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.partner.users &&
      prevProps.partner.users &&
      this.props.partner.users.length > prevProps.partner.users.length
    ) {
      notification.success({
        message: I18n.get(I18n.get("Adicionado com sucesso!")),
        description: I18n.get("O usuário foi adicionado ao seu cadastro"),
        duration: 3,
      });

      this.hideUser();
    }

    if (
      this.props.partner.users &&
      prevProps.partner.users &&
      this.props.partner.users.length < prevProps.partner.users.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("O usuário foi removido do seu cadastro"),
        duration: 3,
      });
    }

    if (
      this.props.partner.users &&
      prevProps.partner.users &&
      this.props.partner.users != prevProps.partner.users &&
      this.state.selectedItem
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("O usuário foi atualizado"),
        duration: 3,
      });

      this.setState({
        selectedItem: null,
      });

      this.hideUser();
    }
  }

  showEditUser = (item) => {
    this.setState({
      selectedItem: item,
      edit: true,
      show: true,
      type: item.type,
    });
  };

  showAddUser = () => {
    this.setState({
      show: true,
    });
  };

  hideUser = () => {
    this.setState({
      selectedItem: null,
      edit: false,
      show: false,
    });
  };

  render() {
    const columns = [
      {
        title: I18n.get("Nome"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: I18n.get("E-mail"),
        dataIndex: "email",
        key: "email",
      },
      {
        title: I18n.get("Função"),
        dataIndex: "roles",
        key: "roles",
        render: (roles) =>
          roles.map((item, index) => {
            return (
              <Tag color="#000" key={index}>
                {item == "PartnerOperation" ? "Operação" : "Financeiro"}
              </Tag>
            );
          }),
      },
      {
        title: "",
        dataIndex: "action",
        key: "item",
        render: (text, record) => {
          let data = {
            id: record.item.id,
            partnerId: this.props.partner.partnerId,
          };

          return (
            <Btns>
              <a onClick={() => this.props.deleteUser(data)}>
                <Icon type="delete" theme="filled" />
              </a>
              <a onClick={() => this.showEditUser(record.item)}>
                <Icon type="edit" theme="filled" />
              </a>
            </Btns>
          );
        },
      },
    ];

    let dataUser =
      this.props.partner.users &&
      this.props.partner.users.map((item, index) => {
        return {
          key: index,
          name: item.name,
          email: item.email,
          roles: item.roles,
          item: item,
        };
      });

    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/home">{I18n.get("Admin")}</Link>
            </li>
            <li className="is-active">
              <Link to="/cadastro">{I18n.get("Cadastro")}</Link>
            </li>
          </ul>
        </nav>

        <div className="columns partner-responsible company-admin">
          <div className="column is-3">
            <Menu {...this.props} />
          </div>

          <div className="column is-9">
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Cadastro - Responsável")}</TitleBorder>
              </Col>
            </Row>

            <FormResponsible
              partner={this.props.partner && this.props.partner}
              partnerId={this.props.partner && this.props.partner.partnerId}
              action={this.props.save}
              errors={this.props.partner.errors && this.props.partner.errors}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
            />

            <RowSpace>
              <Col sm={24}></Col>
            </RowSpace>

            <Row gutter={1}>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Cadastro - Usuário")}</TitleBorder>
                <TitleMiddleLine></TitleMiddleLine>
              </Col>
            </Row>
            <Row gutter={1}>
              <Col sm={24}>
                <CardBorder style={{ height: "auto" }}>
                  <Row gutter={1}>
                    <Col sm={24}>
                      <p className="BtnAction">
                        <Button
                          type="primary"
                          shape="round"
                          onClick={() => this.showAddUser()}
                        >
                          {I18n.get("+ Adicionar usuário")}
                        </Button>
                      </p>
                      <br />
                    </Col>
                  </Row>
                  <Row gutter={1}>
                    <Col sm={24}>
                      <Table
                        scroll={{ x: 640 }}
                        locale={{ emptyText: I18n.get("Sem informação") }}
                        columns={columns}
                        dataSource={dataUser}
                      />
                    </Col>
                  </Row>
                </CardBorder>
              </Col>
            </Row>

            {/* <div className="columns">
                            <div className="column is-6">
                                <h1 className="title-bpool medium">
                                    Dados do responsável
                                 </h1>

                                <ResponsibleForm {...this.props} isAdmin={true} />
                            </div>
                        </div> */}
          </div>
        </div>

        <Drawer
          title={
            this.state.selectedItem
              ? I18n.get("Editar usuário")
              : I18n.get("Adicionar usuário")
          }
          placement="right"
          forceRender={true}
          closable={true}
          onClose={() => this.hideUser()}
          destroyOnClose={true}
          visible={this.state.show}
          width="40%"
        >
          <FormUser
            partner={this.props.partner && this.props.partner}
            idContext={this.props.partner && this.props.partner.partnerId}
            action={this.props.addUser}
            update={this.props.updateUser}
            cancel={() => this.hideUser()}
            item={this.state.selectedItem}
            errors={this.props.partner.errors && this.props.partner.errors}
          />
        </Drawer>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleAdmin);
