import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import FileUploadList from "../../modules/components/FileUploadList";

import DownloadButton from "../form/download-button-field";
import SubmitButtonField from "../form/submit-button-field";

class BPitchNda extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  onNdaAccept(e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      ndaAccepted: true,
    };

    var submit_task = new Task();

    submit_task.start();

    this.setState({ task: submit_task });

    this.props.update_match_nda(data).then(({ task, errors }) => {
      this.setState({ task: task, errors: errors });
    });
  }

  getDataUploadMult = (files) => {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    var data = {
      ...this.props.project,
      ndaUploaded: files[0],
    };

    this.props.update_match_nda_upload(data).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  };

  onDelFile(item, e) {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    const data = {
      ...this.props.project,
      ndaUploaded: null,
    };

    this.props.update_match_nda_upload(data).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const partner = this.props.partner || {};
    const project = this.props.project || {};

    const { ndaFile } = project;

    const projectMatch =
      (partner.projectMatches &&
        partner.projectMatches.find(
          (item) => item.projectId == this.props.match.params.projectId
        )) ||
      {};

    const { ndaUploaded } = projectMatch;

    return (
      <div className="card">
        <div className="card-content">
          <div className="content">
            <h5 className="title">{I18n.get("Documentos obrigatórios")}</h5>

            {projectMatch.ndaAccepted !== true && (
              <>
                {ndaFile && (
                  <>
                    <p>
                      {I18n.get(
                        "Clique no botão a seguir para fazer o download dos documentos."
                      )}
                    </p>

                    <DownloadButton
                      LinkToDownload={ndaFile.url}
                      text={I18n.get("Baixar PDF")}
                      btnClassName="button bt-bpool black"
                    />

                    <br />
                    <br />

                    <h6 className="title">{I18n.get("Upload")}</h6>

                    <p>{I18n.get("Faça o upload do NDA assinado.")}</p>

                    <div style={{ maxWidth: "40rem" }}>
                      {!ndaUploaded && (
                        <>
                          <FileUploadList
                            getDataUpload={this.getDataUploadMult}
                            name="files"
                            uploadId={this.props.project.projectId}
                            get2={this.props.get2 || false}
                            fileListGet={[]}
                            direct={true}
                          />
                        </>
                      )}

                      {ndaUploaded && (
                        <>
                          <div
                            className="columns"
                            style={{
                              marginTop: "1rem",
                              borderBottom: "solid 1px #eeeeee",
                            }}
                          >
                            <div className="column is-10">
                              <div className="field">
                                <label className="label is-small">
                                  {I18n.get("Arquivo")}
                                </label>
                                <div className="control">
                                  <a href={ndaUploaded.url} target="_blank">
                                    {ndaUploaded.name}
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="column is-2 has-text-right">
                              <div className="field is-narrow">
                                <div className="control">
                                  <a
                                    onClick={this.onDelFile.bind(
                                      this,
                                      ndaUploaded
                                    )}
                                    className="button is-rounded is-black is-outlined"
                                  >
                                    <span className="icon is-small">
                                      <i className="fa fa-times"></i>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}

                {!ndaFile && (
                  <>
                    <div className="content-terms">
                      {projectMatch.ndaUrl && (
                        <div style={{ height: "520px" }}>
                          <iframe
                            src={`${projectMatch.ndaUrl}`}
                            height="500px"
                            width="100%"
                          ></iframe>
                        </div>
                      )}
                    </div>
                  </>
                )}

                <br />
                <br />
                <br />

                <div className="columns">
                  <div className="column is-6">
                    <SubmitButtonField
                      label={I18n.get("Estou de acordo com os termos")}
                      className="button bt-bpool"
                      onClick={this.onNdaAccept.bind(this)}
                      task={this.state.task}
                    />
                  </div>

                  <div className="column is-6 has-text-right">
                    {projectMatch.ndaUrl && !ndaFile && (
                      <DownloadButton
                        LinkToDownload={projectMatch.ndaUrl}
                        text={I18n.get("Baixar PDF")}
                        btnClassName="button bt-bpool black"
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {projectMatch.ndaAccepted === true && (
              <>
                <div className="columns">
                  <div className="column is-6">
                    <i className="fas fa-check"></i>{" "}
                    {I18n.get("Você concordou com os termos")}
                  </div>

                  <div className="column is-6 has-text-right">
                    {!ndaFile && projectMatch.ndaUrl && (
                      <DownloadButton
                        LinkToDownload={projectMatch.ndaUrl}
                        text={I18n.get("Baixar PDF")}
                        btnClassName="button bt-bpool black"
                      />
                    )}

                    {ndaFile && (
                      <DownloadButton
                        LinkToDownload={ndaFile.url}
                        text={I18n.get("Baixar PDF")}
                        btnClassName="button bt-bpool black"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BPitchNda;
