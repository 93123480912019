import { GET_PARTNERS, GET_PARTNER_BY_ID, UPDATE_PROJECT_STEP } from '..';

export const getPartners = (payload) => ({
  type: GET_PARTNERS,
  payload,
});

export const getPartnersById = (payload) => ({
  type: GET_PARTNER_BY_ID,
  payload,
});

export const updateProjectStep = (payload) => ({
  type: UPDATE_PROJECT_STEP,
  payload,
});
