import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';
import styled from 'styled-components';

import WhatifWrapper from '../components/WhatifWrapper';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import HeaderThumbCover from '../components/HeaderThumbCover';
import PartnerInfos from '../components/PartnerInfos';


const Main = styled.div`
  padding-bottom: 100px;
  max-width: 1000px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 160px;
  padding-right: 160px;
  color: #353535;
`;

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        update_step1: data => {
            dispatch(PARTNER_WHATIF_API.update_step1(data));
        },
        remove_case: data => {
            dispatch(PARTNER_WHATIF_API.remove_case(data));
        },
        upload: data => {
            dispatch(PARTNER_WHATIF_API.upload(data));
        }
    };
};



class PartnerProfilePreview extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {

        const {
            partnerWhatIf,
            partnerWhatIf: {
                imageLogo,
                imageIntro
            }

        } = this.props;

        return (
            <WhatifWrapper
                grey
            >
                <SubHeader />
                <HeaderThumbCover
                    thumb={imageLogo}
                    bgHeader={imageIntro}
                    title="Preview Profile"
                    link="/parceiro-ideias/profile"
                />
                <ContainerPage>
                    <Main>
                        <PartnerInfos
                            infos={partnerWhatIf}
                        />
                    </Main>
                </ContainerPage>
            </WhatifWrapper>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PartnerProfilePreview);

