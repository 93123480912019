import LogoParceiro from '../../components/Card/parceiro-logo.jpg';

export const aguardandos = [
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Aguardando',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Aguardando',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Aguardando',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Aguardando',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Aguardando',
    logo: LogoParceiro,
  },
  {
    parceiro: 'Agência One',
    data: new Date(),
    nome: 'Fernanda Araújo',
    empresa: 'Heineken',
    status: 'Aguardando',
    logo: LogoParceiro,
  },
];
