import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { message, Icon } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import * as S from "./styles";

const FILE_UPLOAD_ERROR = "Insert allowed files (JPG, PNG or PDF)";

export const UploadCertified = ({
  fileToUpload,
  fileDefault,
  removeFileDefault,
}) => {
  const [file, setFile] = useState(null);
  const [fileUploadError, setFileUploadError] = useState("");

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf"
    ) {
      setFileUploadError("");
      setFile(file);
      fileToUpload(file);
    } else {
      setFileUploadError(FILE_UPLOAD_ERROR);
      setFile(null);
      message.error(I18n.get(FILE_UPLOAD_ERROR));
    }
  };

  const removeFile = () => {
    setFile(null);
  };

  return (
    <S.WrapperUploadCertified>
      <S.LabelUploadCertified>
        {I18n.get(
          "If you have the diversity and inclusion certificate, please attach the one below:"
        )}
      </S.LabelUploadCertified>
      <S.ContainerUploadCertified className={fileUploadError ? "error" : ""}>
        <S.ContentUploadCertified>
          <S.IconAndTextUploadCertified>
            <S.IconUploadCertified>
              <UploadOutlined className={fileUploadError ? "error" : ""} />
            </S.IconUploadCertified>
            <S.TextUploadCertified>
              {file && !fileUploadError ? (
                <S.FileUploaded>
                  <span className="uploaded">{file.name}</span>
                  <button onClick={removeFile}>
                    <Icon type="delete" />
                    {I18n.get("Delete")}
                  </button>
                </S.FileUploaded>
              ) : fileDefault ? (
                <S.FileUploaded>
                  <span className="uploaded">{fileDefault.name}</span>
                  <a href={fileDefault.url} target="_blank">
                    <button className="view">
                      <Icon type="eye" />
                      {I18n.get("Ver")}
                    </button>
                  </a>
                  <button onClick={removeFileDefault}>
                    <Icon type="delete" />
                    {I18n.get("Delete")}
                  </button>
                </S.FileUploaded>
              ) : (
                <>
                  <strong className={fileUploadError ? "error" : ""}>
                    {I18n.get("Select the certified")}
                  </strong>
                  <span className={fileUploadError ? "error" : ""}>
                    {fileUploadError
                      ? I18n.get(fileUploadError)
                      : I18n.get("JPG, PNG or PDF")}
                  </span>
                </>
              )}
            </S.TextUploadCertified>
          </S.IconAndTextUploadCertified>
          <S.ButtonUploadCertified>
            <label for="arquivo">{I18n.get("Select file")}</label>
            <input
              type="file"
              name="arquivo"
              id="arquivo"
              onChange={(e) => handleChangeFile(e)}
            />
          </S.ButtonUploadCertified>
        </S.ContentUploadCertified>
      </S.ContainerUploadCertified>
    </S.WrapperUploadCertified>
  );
};
