import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col, Select, Rate, message } from "antd";
import { useReferences } from "../../../hooks/useReferences/useReferences.hook";
import { Unlogged } from "../../../components/UI/Unlogged";
import Main from "../../../components/UI/Main";
import { Input } from "../../../components/UI/Input";
import { SelectOne } from "../../../components/UI/SelectOne";
import { InputMask } from "../../../components/UI/InputMask";
import { Textarea } from "../../../components/UI/Textarea";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import {
  hasError,
  clearErrorInput,
} from "../../RegistrationFlow/utils/errorsInput";
import Loading from "../../../components/UI/Loading";

import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";

import COUNTRY_CODES from "./countryCodes.json";
import * as S from "./styles";

const { Option } = Select;

export const ReferenceForm = () => {
  const { getConfirmReference, postReferenceByReference } = useReferences();
  const { tokenId } = useParams();
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [inputsForm, setInputsForm] = useState({
    name: "",
    lastName: "",
    mail: "",
    linkedin: "",
    phoneNumber: "",
    country: "BRA",
    countryCodeTel: "BRA",
    ratingsComment: "",
  });
  const [selectCountry, setSelectCountry] = useState({
    countryLabel: "Brazil",
    phoneMask: "(00)00000-0000",
    countryCodeTel: "BRA",
  });
  const [ratings, setRatings] = useState([]);
  const [partnerId, setPartnerId] = useState("");
  const [id, setId] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(null);
  const [name, setName] = useState();

  const handleRates = (rate, value) => {
    let ratingsTemp = [...ratings];
    const objIndex = ratingsTemp.findIndex((item) => item.code === rate.code);
    ratingsTemp[objIndex] = { ...rate, value };
    setRatings(ratingsTemp);
  };

  const onChangeSelectCountry = (value) => {
    const country = COUNTRY_CODES.find((item) => item.name === value);
    setInputsForm({
      ...inputsForm,
      countryCodeTel: country.code,
      phoneNumber: "",
    });
    setSelectCountry({
      countryLabel: value,
      phoneMask: country.mask,
      countryCodeTel: country.code,
    });
  };

  const selectBefore = (
    <Select
      defaultValue=""
      className="select-before"
      onSelect={(value) => onChangeSelectCountry(value)}
    >
      {COUNTRY_CODES.map((item) => {
        let codeString = item.isO2.toLowerCase();
        return (
          <Option key={item.code} value={item.name} prefix={item.prefix}>
            <img
              src={`https://assets.bpool.co/country-flags/png24px/${codeString}.png`}
            />
            <span className="prefix">{item.prefix}</span>
          </Option>
        );
      })}
    </Select>
  );

  const send = async () => {
    setIsSending(true);
    const ratingsSend = ratings.map((item) => ({
      code: item.code,
      value: item.value,
    }));

    const country = COUNTRY_CODES.find(
      (item) => item.code === inputsForm.country
    );

    const formToSend = {
      partnerId,
      id,
      linkedin: inputsForm.linkedin,
      phoneNumber: inputsForm.phoneNumber,
      country: country,
      ratingsComment: inputsForm.ratingsComment,
      ratings: ratingsSend,
    };

    const response = await postReferenceByReference(formToSend);

    if (response.success) {
      setVisible(true);
    } else {
      setErrors(response?.errors);
    }

    setIsSending(false);
  };

  const loadInit = async ({ tokenId }) => {
    setIsLoadingPage(true);
    const response = await getConfirmReference(tokenId);

    console.log('response', response);

    if (response.success) {
      setRatings(response.data.ratings);
      setPartnerId(response.data.partnerId);
      setId(response.data.id);
      setStatus(response.data.status);
      I18n.setLanguage(response.data.culture);
      setName(response.data.partnerName);

      const inpts = formatInputs(response.data);
      setInputsForm(inpts);
    }
    setIsLoadingPage(false);
  };

  const formatInputs = (dataInputs) => {
    let startCulture = "";

    if (dataInputs.culture == "en") {
      startCulture = "USA";
    } else if (dataInputs.culture == "pt") {
      startCulture = "BRA";
    } else {
      startCulture = "ARG";
    }
    const inputs = {
      name: dataInputs.name,
      lastName: "",
      mail: dataInputs.email,
      linkedin: dataInputs.linkedin,
      phoneNumber: dataInputs.phoneNumber,
      country: startCulture,
      countryCodeTel: startCulture,
      ratingsComment: "",
    };

    return inputs;
  };

  const redirectPage = () => {
    window.location.href = "https://www.bpool.co/";
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value,
    });
    setErrors({});
  };

  useEffect(() => {
    loadInit({ tokenId });
  }, []);

  return (
    <Unlogged>
      <div className="container">
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
            {status === 3 && (
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Thank you for your reference.")}</S.H4>
                      </S.TitleWithIcon>
                      <S.Paragraph>
                        {I18n.get("You have already sent us your reference.")}
                      </S.Paragraph>
                    </S.RowCenter>
                  </Col>
                </Row>
              </Main>
            )}
            {status != 3 && (
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("References about you")}</S.H4>
                      </S.TitleWithIcon>
                      <S.Paragraph>
                        <strong>{name}</strong>{" "}
                        {I18n.get(
                          "has indicated you as someone who can assess your profile in our pool. The information is confidential, will not be shared with the evaluated professional and will only serve for our algorithm to search for projects with this profile."
                        )}
                      </S.Paragraph>
                    </S.RowCenter>
                  </Col>
                </Row>
                {isLoadingPage ? (
                  <S.LoadingPage>
                    <Row>
                      <Col xs={24}>
                        <S.ContentLoader>
                          <Loading sizeIcon={30} align="center" />
                        </S.ContentLoader>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <S.Text>
                          {I18n.get(
                            "Wait a moment we are confirming the reference about you."
                          )}
                        </S.Text>
                      </Col>
                    </Row>
                  </S.LoadingPage>
                ) : (
                  <>
                    <Row style={{ marginTop: 40 }}>
                      <Col xs={24} style={{ marginTop: 30 }}>
                        <S.TitleSectionForm>
                          {I18n.get("About you")}
                        </S.TitleSectionForm>
                      </Col>
                      <Col xs={24} sm={8}>
                        <S.Label>{I18n.get("Name")}</S.Label>
                        <S.Value>{inputsForm.name || "---"}</S.Value>
                      </Col>
                      <Col xs={24} sm={8}>
                        {inputsForm.countryCodeTel != "BRA" && (
                          <Input
                            placeholder={I18n.get("Phone number")}
                            required
                            value={inputsForm.phoneNumber}
                            onChange={(value) =>
                              onChangeInputForm("phoneNumber", value)
                            }
                            addonBefore={selectBefore}
                            error={errors && errors?.PhoneNumber?.errorMessage}
                            widthLabel="130px"
                          />
                        )}
                        {inputsForm.countryCodeTel == "BRA" && (
                          <Input
                            placeholder={I18n.get("Phone number")}
                            label
                            required
                            value={inputsForm.phoneNumber}
                            onChange={(value) =>
                              onChangeInputForm("phoneNumber", value)
                            }
                            mask="(00) 00000-0000"
                            addonBefore={selectBefore}
                            error={errors && errors?.PhoneNumber?.errorMessage}
                            widthLabel="130px"
                          />
                        )}
                      </Col>
                      <Col xs={24} sm={8}>
                        <SelectOne
                          options={COUNTRY_CODES || []}
                          value={inputsForm?.country}
                          defaultValue={inputsForm?.country}
                          isCountry
                          onChange={(value) =>
                            onChangeInputForm("country", value, true)
                          }
                          required
                          placeholder={I18n.get("Country")}
                          error={errors && errors?.country?.errorMessage}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col xs={24} sm={8}>
                        <S.Label>{I18n.get("Email")}</S.Label>
                        <S.Value>{inputsForm.mail || "---"}</S.Value>
                      </Col>

                      <Col xs={24} sm={8}>
                        <Input
                          placeholder={I18n.get("LinkedIn")}
                          value={inputsForm.linkedin}
                          onChange={(value) =>
                            onChangeInputForm("linkedin", value)
                          }
                          error={errors && errors?.Linkedin?.errorMessage}
                          widthLabel="110px"
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                      <Col xs={24} style={{ marginTop: 30 }}>
                        <S.TitleSectionForm>
                          {I18n.get("Rating")}
                        </S.TitleSectionForm>
                      </Col>
                      {ratings.map((rating, index) => (
                        <Col key={`${index}-${rating.code}`} xs={24} sm={8}>
                          <S.TitleRate>
                            {I18n.get(rating.label) || "---"}
                          </S.TitleRate>
                          <S.WrapperRate>
                            <Rate
                              value={rating.value}
                              defaultValue={rating.value}
                              onChange={(value) => handleRates(rating, value)}
                            />
                          </S.WrapperRate>
                          {errors && errors[`Rating_${rating.code}`] && (
                            <S.InfoError className="errorRating">
                              {errors[`Rating_${rating.code}`].errorMessage}
                            </S.InfoError>
                          )}
                        </Col>
                      ))}
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col xs={24}>
                        <Textarea
                          id="leave-a-comment"
                          label={I18n.get("Want to leave a comment")}
                          rows={4}
                          maxLength={500}
                          counterChar
                          defaultValue={inputsForm.ratingsComment}
                          onChange={(value) =>
                            onChangeInputForm("ratingsComment", value)
                          }
                          error=""
                        />
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      <Col xs={24} sm={12}>
                        {/* <ButtonBpool
                        text={I18n.get('Back')}
                        theme="secondary"
                        full
                        disabled
                        onClick={() => true}
                      /> */}
                      </Col>
                      <Col xs={24} sm={12}>
                        <ButtonBpool
                          text={I18n.get("Send")}
                          theme="primary"
                          full
                          loading={isSending}
                          onClick={send}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Main>
            )}
          </Col>
        </Row>
      </div>

      <ModalBp
        visible={visible}
        bodyText={I18n.get("Thank you for your reference")}
        subBodyText={I18n.get("Your reference has been sent successfully.")}
        okText={I18n.get("BPool website")}
        // cancelText={I18n.get("Cancelar")}
        handleClickOk={() => redirectPage()}
        onCancel={() => {
          console.log();
        }}
        // typeModal="isConfirm"
        // isDanger={true}
      />
    </Unlogged>
  );
};
