import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: max-content;
  margin-right: 10px;
`;

export const Img = styled.div`
  display: flex;
  margin-right: 15px;

  img {
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #2f2f2f;
`;

export const Info = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

export const Mail = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;
