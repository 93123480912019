import styled from 'styled-components';

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const TitleSectionForm = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
  width: max-content;
`;

export const TitleRate = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-bottom: 10px;
  width: 100%;
`;

export const WrapperTable = styled.div`
  margin-top: 30px;

  .ant-table table {
    border: none !important;
  }

  .ant-table-thead > tr > th {
    background-color: #f8f8f8;
  }

  .ant-table-thead > tr > th * {
    color: #222222;
  }
`;

export const ButtonIcoStatus = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const CirclePending = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #ff0000;
`;

export const CircleConcluded = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #4fbf89;
`;

export const CircleCreated = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #ffff00;
`;

export const StatusDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusText = styled.div`
  margin-left: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgb(0 0 0 / 65%);
  top: 1.5px;
  position: relative;
`;

export const WrapperFooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;

export const SelectBox = styled.div`
  .select-references-culture-dropdown {
    margin-bottom: 5px;

    .ant-select-selection {
      background: #fff;
      border: 1px solid #999999;
      height: 50px;
      border-radius: 10px !important;

      .ant-select-selection__rendered {
        height: 100%;
        display: flex;
        align-items: center;
      }

      span {
        i {
          top: 16px;
        }
      }
    }
  }

  .select-references-culture-dropdown.error {
    .ant-select-selection {
      border: 1px solid #ff0000;
    }
  }
`;

export const InfoError = styled.div`
  color: #ff0000;
  margin: 0 0 0 5px;
  font-size: 12px;
`;
