import axios from 'axios';

import Config from '../../config';

import { REFERENCE_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_reference = (data) => {
    return (dispatch) => {

        return axios.put(`${Config.API_BASE_URL}/reference/${data.Reference.token}`, data)
            .then(response => {
                dispatch({
                    type: REFERENCE_UPDATE,
                    payload: response.data
                })
            })
            .catch(error => {

                dispatch({
                    type: REFERENCE_UPDATE,
                    payload: {
                        errors: error.response.data
                    }
                })
            });
    };
}


export default api_update_reference;
