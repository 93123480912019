import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { LEADER_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_leader_person = (data) => {

    return (dispatch, getState) => {



        return axios.put(`${Config.API_BASE_URL}/leader/${data.PartnerLeader.leaderId}/person`, data)
            .then(response => {

                dispatch({
                    type: LEADER_UPDATE,
                    payload: {
                        ...response.data,
                        task: { result: true }
                    }
                })
            })
            .catch(error => {

                dispatch({
                    type: LEADER_UPDATE,
                    payload: {
                        errors: error.response.data,
                        task: { result: false }
                    }
                })

            });
    };
}


export default api_update_leader_person;
