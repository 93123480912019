import React from 'react';

import { I18n } from 'aws-amplify';

class ReviewStep5 extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {
        return (
            <div>

                <div className="card">
                    <div className="card-content break-word">
                        {this.props.clientName && <>
                            <p>
                                <strong>{I18n.get("Cliente:")}</strong> {this.props.clientName}
                            </p>
                            <p>
                                <strong>{I18n.get("Responsável:")}</strong> {this.props.createdByName}
                            </p>

                            <p>
                                <strong>{I18n.get("Contato:")}</strong> {this.props.createdByPhone}
                            </p>
                            <p>
                                <strong>{I18n.get("E-mail:")}</strong> {this.props.createdByEmail}
                            </p>
                        </>}
                    </div>
                </div>

                {/* <div className="card">
                    <div className="card-content break-word">
                        {this.props.clientDelegateTermsEmail &&
                            <div className="boxDelegate">
                                <h3>Responsável Termo:</h3>
                                <p>
                                    <strong>Nome:</strong> {this.props.clientDelegateTermsName}
                                </p>
                                <p>
                                    <strong>E-mail:</strong> {this.props.clientDelegateTermsEmail}
                                </p>
                            </div>
                        }

                    </div>
                </div> */}

            </div>
        );
    }
}

export default ReviewStep5;
