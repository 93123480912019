import styled from "styled-components";
import { Tooltip } from "antd";

export const StyleForm = styled.form`
  width: 500px;
  margin: 60px auto;
`;

export const StyleMain = styled.div`
  max-width: 630px;
  margin: 50px auto 0;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  a,
  button {
    margin-left: 10px;
  }
`;

export const AttachmentSelected = styled.div`
  color: #1883ff;
  font-size: 16px;
  line-height: 24px;
  margin: 5px 0 0 10px;
  width: 100%;
  border-bottom: solid 1px #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 14px;
    color: #1883ff;
    padding: 5px 0;

    &:hover {
      color: #000;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const ContentReview = styled.div`
  margin-top: 20px;
`;

export const WrapperReview = styled.div`
  margin-top: 30px;
`;

export const TitleReview = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
`;

export const BoxReview = styled.div`
  border-radius: 10px;
  margin-top: 15px;
  padding: 25px 15px;
  border: 1px solid;
  border-color: #e7e7e7;

  ul {
    li {
      display: flex;
      align-items: baseline;
      margin: ${(props) =>
        props.spacing === "extra-large" ? "50px 0;" : "10px 0;"};
      line-height: 25px;

      :first-child {
        margin-top: 0;
      }

      :last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding-left: 5px;
      }
    }
  }
`;

export const BoxReviewDetails = styled.div`
  border-radius: 10px;
  margin-top: 15px;
  padding: 25px 15px;
  border: 1px solid;
  border-color: #e7e7e7;

  ul {
    li {
      display: grid;
      grid-template-columns: 30px calc(100% - 30px);
      margin-bottom: 10px;
      line-height: 30px;

      :last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding-left: 5px;
      }
    }
  }
`;

export const RowValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Value = styled.div`
  color: #1883ff;
  font-weight: 600;
  font-size: 16px;
`;

export const H4 = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
`;

export const ModelsBoxes = styled.div`
  margin: 30px 0;

  ul {
    display: flex;

    li {
      padding: 10px 15px;
      border-radius: 5px;
      background-color: #1883ff;
      color: #ffffff;
      font-size: 14px;
      margin-right: 15px;

      :last-child {
        margin-right: 0;
      }
    }
  }
`;

export const ContentTooltip = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

export const StyleTooltip = styled(Tooltip)``;

export const Assets = styled.div`
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #e7e7e7;
  padding: 15px;
  background-color: #f2f2f2;

  ul {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 14px;
      margin: 0 0 0 0;

      p {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }

      strong {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
      }

      :first-child {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
`;

export const Important = styled.div`
  margin-top: 30px;

  span {
    font-weight: 600;
    padding-right: 5px;
  }
`;
