import React, { Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import FillForm from '../page-templates/fillForm-logged';

import bannerImage3 from '../../image/banner-image3.png';

import PageSuccess from '../../modules/components/PageSuccess';

import { scrollToTop } from '../../utils/ui';

export default class WallPage extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {

        scrollToTop();
    }

    render() {

        return (
            <FillForm>
                <PageSuccess>
                    <Fragment>
                        <h2>{I18n.get("Obrigado!")}</h2>

                        <p>{I18n.get("Agora a BPool e o parceiro irão iniciar o processo de faturamento.")}</p>
                        <br />
                        <p>{I18n.get("Qualquer dúvida, entre em contato com a gente pelo e-mail")} <a href="mailto:suporte.cliente@bpool.co">suporte.cliente@bpool.co</a> {I18n.get("e teremos prazer em te ajudar.")}</p>
                        <br />
                        <p>{I18n.get("Para acompanhar tudo sobre este e outros projetos,")} <br /><Link to="/cliente/projetos">{I18n.get("clique aqui")}</Link>.
                                </p>
                    </Fragment>
                </PageSuccess>

            </FillForm>
        );
    }
}

