import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Unlogged } from "../../../../components/UI/Unlogged";
import { ContentPage } from "./content-page";

export const AboutYou = () => {
  return (
    <Unlogged>
      <ContentPage />
    </Unlogged>
  );
};
