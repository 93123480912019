import React, { Component } from "react";
import { 
    Row, 
    Col, 
    Form, 
    Button,
    Checkbox,
} from 'antd';

import styled from 'styled-components';

import { I18n } from 'aws-amplify';


const Terms = styled(Form)`
    .ant-row {
        margin-bottom: 0px;
    }

    .ant-checkbox-wrapper {
        line-height: 20px;
    }

    .Send {
        margin-top: 20px;
        padding-top: 20px;
        border-top: solid #ccc 1px;
    }
`;


class FormTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <Terms 
            // onSubmit={this.handleSubmitSave}
           >
                <Row>
                    <Col sm={24}>
                        <Form.Item>
                            {getFieldDecorator('check1', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                valuePropName: 'checked'

                            })(
                                <Checkbox>
                                    {I18n.get("Declaro expressamente aceitar as condições neste ‘Convite de Projeto’.")}
                                </Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                <Col sm={24}>
                        <Form.Item>
                            {getFieldDecorator('check2', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                valuePropName: 'checked'

                            })(
                                <Checkbox>
                                    {I18n.get("Declaro que em decorrência da natureza dos serviços que serão prestados por mim, li e estou de acordo com os termos da Indemnity Letter formalizada em decorrência desta negociação.")}
                                </Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="Send">
                    <Col sm={24}>
                        <Button 
                            type="primary" 
                            htmlType="submit" 
                            shape="round"
                            onClick={() => this.props.confirm()}
                            disabled={!getFieldValue("check1") || !getFieldValue("check2")}
                        >
                            {I18n.get("Enviar")}
                        </Button>
                    </Col>
                </Row>
            </Terms>
        );
    }
}


const ContainerFormTerms = Form.create({})(FormTerms);

export default ContainerFormTerms;
