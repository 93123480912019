import React from 'react';

import { I18n } from 'aws-amplify';

import CreativeSteps from './steps-creative';
import EventSteps from './steps-event';
import DigitalSteps from './steps-digital';
import BPitchSteps from './steps-bpitch';

const SubHeader = ({ projectId, active, logo, isCreative, isEvent, isBPitch, customEvent = {}, bPitch = {}, isCustomEvent }) => {



    return (
        <div className="container">
            <div className="columns is-mobile">
                <div className="column is-4" style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
                    {logo && <img src={logo} style={{ height: '65%', marginRight: '2rem', maxWidth: '110px' }} ></img>}
                    <p className="title">{I18n.get("Novo Projeto")}</p>
                </div>
                <div className="column is-8">
                    
                    {isCreative && <>
                        <CreativeSteps projectId={projectId} active={active} />
                    </>}
                    {isCustomEvent && customEvent && customEvent.isDigital === false && <>
                        <EventSteps projectId={projectId} active={active} />
                    </>}
                    {isCustomEvent && customEvent && customEvent.isDigital === true && <>
                        <DigitalSteps projectId={projectId} active={active} />
                    </>}
                    {isBPitch && bPitch && <>
                        <BPitchSteps projectId={projectId} active={active} />
                    </>}                    
                </div>
            </div>
        </div>
    );
}

export default SubHeader;
