import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
//import { Link, Redirect } from 'react-router-dom'

import { PARTNER_API } from '../../actions/partner/actions';

import ReferenceCmp from './_reference-cmp';

import TooltipField from '../form/tooltip-field';

const mapStateToProps = state => {
    return Object.assign({},
        {
            bp: state.bp, reference: { errors: {} }
        },
        state.partner);
};

const mapDispatchToProps = dispatch => {
    return {
        addReference: data => {
            dispatch(PARTNER_API.create_reference(data));
        },
        delReference: data => {
            dispatch(PARTNER_API.delete_reference(data));
        },
        submit: data => {
            dispatch(PARTNER_API.update(data));
        },
    };
};

class ReferenceForm extends React.Component {
    constructor(props) {
        super();
        this.state = {
        };

        this.submitForm = this.submitForm.bind(this);
    }

    getData() {

        var data = {
            partnerId: this.props.partnerId,
            terms: true,
            isSubmit: true
        };

        return data;
    }

    submitForm(e) {
        e.preventDefault();
        const data = this.getData();
        this.props.submit(data);
    }

    render() {

        return (
            <form onSubmit={this.submitForm}>

                <h1 className="title-bpool medium">{I18n.get("Quem fala bem da sua empresa · Insira pelo menos três referências")} <TooltipField tooltip={I18n.get(
                    "Vamos enviar um e-mail para essas pessoas com algumas perguntas simples sobre você, considerando capacidades técnicas e relação interpessoal. Fica tranquilo que serão só 5 minutos porque não queremos incomodar ninguém =)"
                )} />
                </h1>

                <p>{I18n.get(
                    "DICA: você precisará de ao menos 3 avaliações pra passar pra próxima fase do cadastro, então insira quantas pessoas quiser. Assim, se alguém não responder a pesquisa, você não fica na mão."
                )}</p>

                <ReferenceCmp {...this.props} />

            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReferenceForm);
