import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import LoggedPage from '../page-templates/logged';

import Menu from './_menu';

import PlanForm from './plan-form';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class PlanAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><Link to="/home">{I18n.get("Admin")}</Link></li>
                        <li className="is-active"><Link to="/cadastro">{I18n.get("Cadastro")}</Link></li>
                    </ul>
                </nav>          

                <div className="columns plan-admin">

                    <div className="column is-3">
                        <Menu {...this.props} />
                    </div>

                    <div className="column is-8">

                        <h1 className="title">{I18n.get("Plano")}</h1>

                        <PlanForm {...this.props} isAdmin={true} />

                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanAdmin);
