import React from "react";
import { Route, Switch } from "react-router-dom";

import ProjectsList from "../../pages/ProjectsList";
import CreateProjectStep1 from "../../pages/Client/CreateProject/Step1";
import { CreateProjectStep2 } from "../../pages/Client/CreateProject/Step2";
import { Matching } from "../../pages/ClientProjects/Matching";
import { SelectProject } from "../../pages/Client/CreateProject/SelectStep2";

import { CreateProjectStep3 } from "../../pages/Client/CreateProject/Step3";
import { CreateProjectStep4 } from "../../pages/Client/CreateProject/Step4";
import { CreateProjectStep5 } from "../../pages/Client/CreateProject/Step5";
import { CreateProjectStep6 } from "../../pages/Client/CreateProject/Step6";
import { StepSuccess } from "../../pages/Client/CreateProject/StepSuccess";
import { Step2Combos } from "../../pages/Client/CreateProject/Step2Combos";

import { SelectPartnerStep1 } from "../../pages/Client/CreateProject/SelectPartner/Step1";
import { SelectPartnerStep2 } from "../../pages/Client/CreateProject/SelectPartner/Step2";
import { SelectPartnerStep3 } from "../../pages/Client/CreateProject/SelectPartner/Step3";
import { KnowMorePartner } from "../../pages/Client/CreateProject/SelectPartner/KnowMorePartner";

import { Briefing } from "../../pages/Definitions/Briefing";
import { Macros } from "../../pages/Definitions/Macros";
import { Review } from "../../pages/Definitions/Review";
import { PO } from "../../pages/Definitions/Po";

import { ReviewInProgress } from "../../pages/InProgress/Review";
import { BriefingInProgress } from "../../pages/InProgress/Briefing";
import { FilesInProgress } from "../../pages/InProgress/Files";
import { MilestonesInProgress } from "../../pages/InProgress/Milestones";
import { POInProgress } from "../../pages/InProgress/Po";
import { ExtrasInProgress } from "../../pages/InProgress/Extras";
import { NewExtra } from "../../pages/InProgress/Extras/pages/NewExtra";
import { NewExtraDetails } from "../../pages/InProgress/Extras/pages/NewExtraDetails";
import { NewExtraBudget } from "../../pages/InProgress/Extras/pages/NewExtraBudget";
import { NewExtraPo } from "../../pages/InProgress/Extras/pages/NewExtraPo";
import { NewExtraFinalize } from "../../pages/InProgress/Extras/pages/NewExtraFinalize";
import { Finalize as FinalizeProject } from "../../pages/InProgress/Finalize";

function ProjectsListRoutes() {
  return (
    <>
      <Switch>
        {/* PARTNER USER */}
        <Route exact path="/cliente/projetos/v2" component={ProjectsList} />
        <Route
          exact
          path="/cliente/projetos/v2/criar-projeto"
          component={CreateProjectStep1}
        />
        <Route
          exact
          path="/cliente/projetos/v2/criar-projeto/:projectId"
          component={CreateProjectStep1}
        />
        <Route
          exact
          path="/cliente/projetos/v2/selecionar-assets/:projectId"
          component={SelectProject}
        />
        <Route
          exact
          path="/cliente/projetos/v2/matching/:projectId"
          component={Matching}
        />
        <Route
          exact
          path="/cliente/projetos/v2/step2-combos/:projectId"
          component={Step2Combos}
        />
        <Route
          exact
          path="/cliente/projetos/v2/demandas/:projectId"
          component={CreateProjectStep3}
        />
        <Route
          exact
          path="/cliente/projetos/v2/parceiros/:projectId"
          component={CreateProjectStep4}
        />
        <Route
          exact
          path="/cliente/projetos/v2/checkout/:projectId"
          component={CreateProjectStep5}
        />
        <Route
          exact
          path="/cliente/projetos/v2/review/:projectId"
          component={CreateProjectStep6}
        />
        <Route
          exact
          path="/cliente/projetos/v2/sucesso/:projectId"
          component={StepSuccess}
        />
        <Route
          exact
          path="/cliente/projetos/v2/selecionar-parceiro/:projectId/passo-1"
          component={SelectPartnerStep1}
        />
        <Route
          exact
          path="/cliente/projetos/v2/selecionar-parceiro/:projectId/passo-2/:partnerId"
          component={SelectPartnerStep2}
        />
        <Route
          exact
          path="/cliente/projetos/v2/selecionar-parceiro/:projectId/passo-3"
          component={SelectPartnerStep3}
        />
        <Route
          exact
          path="/cliente/projetos/v2/selecionar-parceiro/:projectId/saiba-mais/:partnerId"
          component={KnowMorePartner}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/briefing"
          component={Briefing}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/macros"
          component={Macros}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/review"
          component={Review}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/faturamento"
          component={PO}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/review"
          component={ReviewInProgress}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/briefing"
          component={BriefingInProgress}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/files"
          component={FilesInProgress}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/milestones"
          component={MilestonesInProgress}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/po"
          component={POInProgress}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/extras"
          component={ExtrasInProgress}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/extras/new"
          component={NewExtra}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/extras/:extraId/details"
          component={NewExtraDetails}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/extras/:extraId/budget"
          component={NewExtraBudget}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/extras/:extraId/po"
          component={NewExtraPo}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/extras/:extraId/finalize"
          component={NewExtraFinalize}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/extras"
          component={ExtrasInProgress}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/extras/new"
          component={NewExtra}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/extras/:extraId/details"
          component={NewExtraDetails}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/extras/:extraId/budget"
          component={NewExtraBudget}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/extras/:extraId/po"
          component={NewExtraPo}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/extras/:extraId/finalize"
          component={NewExtraFinalize}
        />

        <Route
          exact
          path="/cliente/projetos/v2/:projectId/in-progress/finalize"
          component={FinalizeProject}
        />
      </Switch>
    </>
  );
}

export default ProjectsListRoutes;
