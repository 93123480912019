import axios from 'axios';

import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { IDEAS_SEARCH } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_search_ideas = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

 

            return axios.post(`${Config.API_BASE_URL}/ClientWhatIf/${data.clientId}/search`, data, config)
                .then(response => {
                    dispatch({
                        type: IDEAS_SEARCH,
                        payload: {
                            ideas: [
                                ...response.data,
                            ],
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    });
                })
                .catch(error => {
                    throw (error);
                });
        }).catch(error => {
            // dispatch({ type: EXPIRED });
        });
    };
}

export default api_search_ideas;
