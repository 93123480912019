import React from 'react';
import { I18n } from 'aws-amplify';
import { Table, Tooltip, Icon } from 'antd';
import * as S from './styles';


export const TablePartners = ({
  loaderPartners,
  partnersData,
}) => {



  const columns = [
    {
      title: I18n.get('Region'),
      dataIndex: 'bpRegion',
      filters: [
        {
          text: 'BRASIL',
          value: 'BRASIL',
        },
        {
          text: 'LATAM',
          value: 'LATAM',
        },
        {
          text: 'USA',
          value: 'USA',
        },
      ],
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.bpRegion?.toLowerCase().localeCompare(b.bpRegion?.toLowerCase()),
      onFilter: (value, record) => record.bpRegion.indexOf(value) === 0,
    },
    {
      title: I18n.get('Name'),
      dataIndex: 'partnerName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.partnerName?.toLowerCase().localeCompare(b.partnerName?.toLowerCase()),
    },
    {
      title: I18n.get('Score'),
      key: 'scoreTotal',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.score.total - b.score.total,
      render: (record) => (
        <>
          <span>{record.score.total}</span>
          {' '}
          <span className="Info">
                  <Tooltip  
                    placement="right" 
                    overlayStyle={{ whiteSpace: 'pre-line', width: '700px' }}
                    title={record.score.analyser}
                  >
                    <Icon type="question-circle" theme="filled" />
                  </Tooltip>
          </span>
        </>
      ),
    },
    {
      title: I18n.get('Billed'),
      dataIndex: 'totalBilledFmt',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.totalBilled - b.totalBilled,
    },
    {
      title: I18n.get('Accepted'),
      dataIndex: 'projectInvite.accepted',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.projectInvite.accepted - b.projectInvite.accepted,
    },
    {
      title: I18n.get('Declined'),
      dataIndex: 'projectInvite.declined',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.projectInvite.declined - b.projectInvite.declined,
    },
    {
      title: I18n.get('Expired'),
      dataIndex: 'projectInvite.expired',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.projectInvite.expired - b.projectInvite.expired,
    },
    {
      title: I18n.get('Withdrawned'),
      dataIndex: 'projectInvite.withdrawned',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.projectInvite.withdrawned - b.projectInvite.withdrawned,
    },

  ];


  return (
    <>
      <S.Title>
        <p>{I18n.get('Partners Score')}</p>
      </S.Title>

      <S.TableWrapper>
        <Table
          loading={loaderPartners}
          dataSource={partnersData}
          columns={columns}
          pagination={false}
        />
      </S.TableWrapper>
    </>
  );
};
