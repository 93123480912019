import React from "react";

import { I18n } from "aws-amplify";

import NumberFormat from "react-number-format";

import numeral from "numeral";

import MoneyField from "../../form/money-field";
import PercentageField from "../../form/percentage-field";
import TooltipField from "../../form/tooltip-field";

import { ProjectExtraTypeNames } from "../../../model";

require("./quotation-summary.css");

const ptBR = new Intl.NumberFormat("pt", {
  minimumFractionDigits: 2,
});

const BRL = ({ amount }) => (
  <NumberFormat
    value={amount}
    displayType={"text"}
    thousandSeparator="."
    decimalSeparator=","
    prefix={"R$ "}
    decimalScale={2}
    fixedDecimalScale={true}
  />
);

export default class QuotationSummary extends React.Component {
  constructor(props) {
    super();

    const { quotation = {} } = props;

    const { fee = {}, tax = {} } = quotation;

    this.state = {
      fields: {
        fee: {
          onChange: this.onChangeForm.bind(this, "fee"),
          onBlur: this.onSave.bind(this),
          successIcon: false,
          visited: true,
        },
        feeAmount: {
          onChange: this.onChangeForm.bind(this, "feeAmount"),
          onBlur: this.onSave.bind(this),
          successIcon: false,
          visited: true,
        },
        tax: {
          onChange: this.onChangeForm.bind(this, "tax"),
          onBlur: this.onSave.bind(this),
          successIcon: false,
          visited: true,
        },
        taxAmount: {
          onChange: this.onChangeForm.bind(this, "taxAmount"),
          onBlur: this.onSave.bind(this),
          successIcon: false,
          visited: true,
        },
      },
      form: {
        fee: fee.percentage,
        feeAmount: fee.amount,
        tax: tax.percentage,
        taxAmount: tax.amount,
      },
      errors: {},
      task: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      //console.dir({ task: task, prev: prev });

      var nextState = Object.assign({}, prevState, { task: task });

      const { quotation = {} } = this.props;

      const { fee = {}, tax = {} } = quotation;

      nextState.form.fee = fee.percentage;
      nextState.form.feeAmount = fee.amount;
      nextState.form.tax = tax.percentage;
      nextState.form.taxAmount = tax.amount;
      nextState.errors = this.props.errors;
    }

    // if (JSON.stringify(this.props.errors) != JSON.stringify(this.state.errors)){
    //     this.setState({ errors: this.props.errors });
    // }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    const { quotation = {} } = this.props;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      if (prop == "fee") {
        const feePerc = numeral(value).value() / 100;
        const feeAmount = quotation.itemsSubTotal * (feePerc / 100);
        const feeAmountFmt = ptBR.format(feeAmount);

        nextState.form.feeAmount = feeAmountFmt;
      }

      // if (prop == 'tax'){

      //     const taxPerc = numeral(value).value() / 100;
      //     const taxAmount = quotation.itemsSubTotal * (taxPerc / 100);
      //     const taxAmountFmt = ptBR.format(taxAmount);

      //     nextState.form.taxAmount = taxAmountFmt;
      // }

      return nextState;
    });
  }

  getData() {
    const quotation = this.props.quotation || {};

    var data = {
      projectId: this.props.match.params.projectId,
      extraId: this.props.match.params.extraId,
      quotationId: this.props.quotation.id,
      fee: {
        percentage: this.state.form.fee,
        amount: this.state.form.feeAmount,
      },
      tax: {
        percentage: this.state.form.tax,
        amount: this.state.form.taxAmount,
      },
    };

    return data;
  }

  onSave() {
    const data = this.getData();

    this.props.update_quotation(data).then(({ task, errors }) => {
      this.setState({ task: task, errors: errors });
    });
  }

  render() {
    const quotation = this.props.quotation || {};

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    const canEditSytle = canEdit ? {} : { display: "none" };

    return (
      <div className="quotation-summary">
        <div className="columns">
          <div className="column is-6 is-offset-6 tit">
            <p className="title-bpool medium">
              {I18n.get("Resumo da Proposta")}
            </p>
          </div>
        </div>

        <div className="columns">
          <div className="column is-9 is-offset-3 tit">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "15rem" }}>
                    {I18n.get("Custos Gerais")}
                  </th>
                  <th>%</th>
                  <th>{I18n.get("Valores")}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="item-line">
                  <td>
                    {/* {ProjectExtraTypeNames[this.props.extraType]} */}
                    {I18n.get("Total de Itens")}
                  </td>
                  <td></td>
                  <td className="has-text-right">
                    <BRL amount={quotation.itemsSubTotal} />
                  </td>
                </tr>
                {this.props.isFeeEnabled && (
                  <>
                    <tr className="fee-line">
                      <td>
                        {this.props.auth.is_client_or_client_user && (
                          <>{I18n.get("Honorário parceiro")}</>
                        )}

                        {this.props.auth.is_partner && (
                          <>
                            {I18n.get("Seus Honorários/Comissão")}
                            <TooltipField
                              tooltip={I18n.get(
                                "Insira o % de honorários sobre itens de terceiros ou edite em R$ o valor no campo ao lado"
                              )}
                            />
                          </>
                        )}
                      </td>
                      <td>
                        {canEdit && (
                          <PercentageField
                            {...this.state.fields.fee}
                            value={this.state.form.fee}
                            error={this.state.errors["fee.percentage"]}
                          />
                        )}
                        {!canEdit && (
                          <p>{quotation.fee && quotation.fee.percentageFmt}</p>
                        )}
                      </td>
                      <td>
                        {canEdit && (
                          <MoneyField
                            {...this.state.fields.feeAmount}
                            value={this.state.form.feeAmount}
                            error={this.state.errors["fee.amount"]}
                          />
                        )}
                        {!canEdit && (
                          <BRL amount={quotation.fee && quotation.fee.amount} />
                        )}
                      </td>
                    </tr>
                  </>
                )}
                {/* <tr className="tax-line">
                                    <td>{I18n.get("Impostos")}</td>
                                    <td>
                                        {canEdit && <PercentageField {...this.state.fields.tax} value={this.state.form.tax} error={this.state.errors['tax.percentage']} />}
                                        {!canEdit && <p>{quotation.tax && quotation.tax.percentageFmt}</p>}
                                    </td>
                                    <td>
                                        {canEdit && <MoneyField {...this.state.fields.taxAmount} value={this.state.form.taxAmount} error={this.state.errors['tax.amount']} />}
                                        {!canEdit && <BRL amount={quotation.tax && quotation.tax.amount} />}
                                    </td>
                                </tr> */}
                {/* <tr className="commission-line">
                                    <td>
                                        {quotation.bpCommission && quotation.bpCommission.description}
                                    </td>
                                    <td>
                                        {quotation.bpCommission && quotation.bpCommission.percentageFmt}
                                    </td>
                                    <td>
                                        <BRL amount={quotation.bpCommission && quotation.bpCommission.amount} />
                                    </td>
                                </tr> */}
                <tr className="invoice-line">
                  <td></td>
                  <td>
                    {I18n.get("TOTAL NF")}

                    {this.props.client &&
                      this.props.client.signupLocale === "pt" && (
                        <div style={{ marginTop: "1rem", fontSize: "80%" }}>
                          {I18n.get("emitida contra o cliente A/C BPool")}
                        </div>
                      )}
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    <BRL amount={quotation.total} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
