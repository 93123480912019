import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';

import BgSuccess from '../../image/bg-success.jpg';

// background: #fff url(${ BgSuccess }) no - repeat;

const MainSuccess = styled.div`
    background: #fff;
    position: absolute;
    height: 100%;
    width: 100%;

    .InfoSuccess {
        width: 370px;
        margin: 260px auto 0;
        position: relative;
        left: 60px;

        a {
            text-decoration: undeline!important;
            color: #000;
            font-weight: bold;
        }

        h2 {
            font-size: 25px;
            font-weight: 600;
            position: relative;
            margin-bottom: 50px;

            &::after {
                position: absolute;
                width: 80px;
                height: 8px;
                background-color: #ccc;
                bottom: -25px;
                content: "";
                display: block;
            }
        }

        @media (max-width: 768px) {
            max-width: 370px;
            width: 100%;
            margin: 280px auto 0;
            position: relative;
            left: 0px;
            box-sizing: border-box;
            padding: 0 20px;

        }
    }
`;

class PageSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <MainSuccess>
                {/* {this.props.children} */}
                <div className="InfoSuccess">
                    {this.props.children}
                </div>
            </MainSuccess>
        );
    }
}

export default PageSuccess;
