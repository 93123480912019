import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { scrollToTop } from '../../utils/ui';

import '../../App.css';

import logo from '../../image/logoWOtext.svg';
import Footer from '../../components/page-templates/footer';

import TopMenuSignup from '../../components/page-templates/_top-menu-signup';


import Loading from '../../components/pages/loading';



const ContainerPage = styled.div`
    .logged-page {
        background: #fff;

        &.page-grey {
            background: #f7f7f7;
            height: 100%;
            position: relative;
            width: 100%;
        }
    }

    .page-whatif {
        height: 100%;
    }
`

class AppWrapperSimple extends Component {

    constructor(props) {
        super();
        this.state = {
        };
    }

    componentDidMount() {
        scrollToTop();
    }

    render() {

        return (
            <ContainerPage>
                <section className={`hero logged-page ${this.props.noFullHeight ? '' : 'is-fullheight'}`}>
                    <div className={`hero-head ${this.props.noFixed ? '' : 'is-fixed'}`}>

              
                        <nav className="navbar is-main navbar-main">
                            <div className="container">
                            
                                <div id="navbarMenu" className="navbar-menu navbar-menu-new is-active">
                                    <div className="navbar-start">
                                        {/* <TopMenu {...this.props} />  */}
                                    </div>
                                    <div className="navbar-logo">
                                        <div className="navbar-item">
                                            <a href="/"><img src={logo} alt="BPool" className="logo"/></a>
                                        </div>
                                    </div>

                                    <div className="navbar-end" style={{ zIndex: 999 }}>

                                        {/* <LanguageNavBarItem {...this.props} /> */}
                                        <div className="navbar-name">
                                            {/* <span className="navbar-link-name-not-mobile">{firstName}</span> */}
                                        </div>
                                        
                                        {/* <ChatNavBarItem {...this.props} /> */}

                                    </div>
                                </div>
                            </div>
                        </nav>


                    </div>
                    <div className="" style={{padding:"55px 0 0 0"}}>
                        {this.props.children}
                    </div>

                </section>

                <Footer {...this.props} />

            </ContainerPage>

        );
    }
}

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppWrapperSimple);
