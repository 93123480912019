import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';


const MainCounter = styled.div`
    span { font-size: 12px; }
   .counter {
       float: right;
   }

   .sume {
       font-weight: bold;
   }
`;

export class TextCounter extends PureComponent {
    render() {
        return (
            <MainCounter>
                <p>
                    <span className="min">{I18n.get("mínimo")} {this.props.min}
                    </span>

                    <span className="counter">
                        <span className="sume">{this.props.counter}</span>/{this.props.limit}
                    </span>
                </p>
            </MainCounter>
        );
    }
}



