import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
//import { Link, Redirect } from 'react-router-dom'

import LandingPage from '../page-templates/landing';
import WallPage from '../pages/wall';
import WallOkClient from '../pages/wallOkClient';


import Steps from './steps';
//import WallOkPartner from '../pages/wallOkPartner';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
    return {
    };
};

class EmailSent extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <WallOkClient />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSent);
