import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { HISTORIC_MONTH_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const api_get_extras_end_project = (data) => {
  return (dispatch, getState) => {
    Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        let year = data.year ? data.year : new Date().getFullYear();

        return axios
          .get(
            `${Config.API_BASE_URL}/Dashboard/${data.prefix}/general/${data.fromDate}/${data.toDate}`,
            config
          )
          .then((response) => {
            dispatch({
              type: HISTORIC_MONTH_GET,

              payload: {
                newDash: response?.data?.data,
                errors: {},
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: HISTORIC_MONTH_GET,
              payload: {
                errors: {},
                task: {
                  result: false,
                },
              },
            });
          });
      })
      .catch((error) => {
        dispatch({ type: EXPIRED });
      });
  };
};

export default api_get_extras_end_project;
