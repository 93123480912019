import { Table } from "antd";
import styled from "styled-components";

export const TableStyle = styled(Table)`
  .ant-table table {
    border: 0 !important;
  }
  .ant-table-tbody {
    .ant-table-expanded-row {
      background: #fafafa;
      padding: 0;
      margin: 0;

      td {
        padding: 0;
        margin: 0;
      }
    }
  }

  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters
    .anticon-filter.ant-table-filter-open,
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters
    .ant-table-filter-icon.ant-table-filter-open,
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
    .anticon-filter:hover,
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
    .ant-table-filter-icon:hover {
    background: #000;
  }

  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
  .ant-table-thead > tr > th.ant-table-column-sort {
    background: #000;

    .ant-table-filter-icon {
      background: #000;
    }
  }

  .ant-table-tbody {
    td {
      padding: ${(props) => (props.isBig ? "30px 16px" : "16px 16px")};
      vertical-align: middle;
    }
  }

  .ant-table-thead
    > tr
    > th
    .ant-table-column-sorter
    .ant-table-column-sorter-inner-full {
    margin-top: -8px;
  }

  .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: #fafafa;
  }
`;
