const renderValue = (value) => {
  if (value === 0 || value === "0") {
    return value;
  } else if (value) {
    return value;
  } else {
    return "---";
  }
};

export const converData = (data) => {
  return {
    name: renderValue(data?.name),
    bPaymentsAmount: renderValue(data?.bPaymentsAmount),
    createdOnFmt: renderValue(data?.createdOnFmt),
    totalFmt: renderValue(data?.totalFmt),
    bPoolNFSe: renderValue(data?.billing?.bPoolNFSe),
    description: renderValue(data?.description),
    billTo: renderValue(data?.billTo),
    sku: renderValue(data?.sku),
  };
};
