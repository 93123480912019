import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Icon } from "antd";
import { useExtras } from "../../../../../hooks/useExtras/useExtras.hook";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import Content from "../../../../../components/UI/Content";
import Main from "../../../../../components/UI/Main";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import { H2Border } from "../../../../../components/UI/Titles";
import { TabsProject } from "../../../../../components/UI/TabsProject";
import { Input } from "../../../../../components/UI/Input";
import { CurrencyInput } from "../../../../../components/UI/CurrencyInput";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import Loading from "../../../../../components/UI/Loading";
import { ClientProjectStatus } from "../../../../../../model";

import TabsItemsClient from "../../../urls/urlsClient.json";
import TabsItemsClientDefinition from "../../../urls/urlsClientDefinition.json";
import TabsItemsPartner from "../../../urls/urlsPartner.json";

import * as S from "./styles";

export const NewExtra = () => {
  let params = useParams();
  let history = useHistory();
  let location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { postExtra, getExtraTypes } = useExtras();
  const { getProjectById } = useProjects();
  const [inputsForm, setInputsForm] = useState({});
  const [activeKey, setActiveKey] = useState(null);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [isDisabledNext, setIsDisabledNext] = useState(true);
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);
  const [errorTypeRequred, setErrorTypeRequred] = useState("");
  const [errorTypeBudget, setErrorTypeBudget] = useState("");
  const [dataTypes, setDataTypes] = useState([]);
  const [projectCurrency, setProjectCurrency] = useState({});
  const [project, setProject] = useState(null);

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const clickNewType = (key) => {
    if (activeKey === key) {
      setActiveKey(null);
    } else {
      setActiveKey(key);
    }
  };

  const handleBack = () => {
    const urlPartner = `/parceiro/projetos/v2/${params?.projectId}/in-progress/extras`;
    const urlClient = `/cliente/projetos/v2/${params?.projectId}/in-progress/extras`;

    history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
  };

  const handleNext = async () => {
    setIsLoadingNext(true);

    let budgetInternal = inputsForm?.budget;
    if (auth?.is_client_or_client_user) {
      budgetInternal = inputsForm?.budget;
    } else {
      budgetInternal = 1;
    }

    if (activeKey) {
      const dataFormClient = {
        projectId: params?.projectId,
        extraName: inputsForm?.name,
        budget: Number(inputsForm?.budget),
        extraType: activeKey,
      };

      const dataFormPartner = {
        projectId: params?.projectId,
        extraName: inputsForm?.name,
        extraType: activeKey,
      };

      try {
        const response = await postExtra({
          isClient: auth?.is_client_or_client_user ? true : false,
          dataForm: auth?.is_client_or_client_user ? dataFormClient : dataFormPartner,
        });

        const pathname = location?.pathname.replace("/new", "");

        const urlPartner = `${pathname}/${response?.data}/budget`;
        const urlClient = `${pathname}/${response?.data}/details`;

        history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
      } catch (error) {
        console.log("error?.response", error?.response);
      }
    } else if (!activeKey && budgetInternal) {
      setErrorTypeRequred(I18n.get("Type must be informed."));
    } else if (!budgetInternal && activeKey) {
      setErrorTypeRequred("");
    } else if (!budgetInternal && !activeKey) {
      setErrorTypeRequred(I18n.get("Type must be informed."));
    }
    setIsLoadingNext(false);
  };

  useEffect(() => {
    if (inputsForm?.name) {
      setIsDisabledNext(false);
    } else {
      setIsDisabledNext(true);
    }
  }, [inputsForm]);

  useEffect(() => {
    if (activeKey) {
      setErrorTypeRequred("");
    }
  }, [activeKey]);

  const loadInit = async () => {
    setIsLoadingTypes(true);
    try {
      const response = await getExtraTypes();
      const types = response?.data?.map((item) => ({
        code: Number(item?.code),
        label: item?.label,
        description: item?.description,
      }));
      setDataTypes(types);

      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });
      if (responseProject?.success) {
        setProjectCurrency(responseProject?.data?.currency);
        setProject(responseProject?.data);
      }
    } catch (error) {
      console.log("error in get extra types", error?.response);
    }
    setIsLoadingTypes(false);
  };

  useEffect(() => {
    loadInit();
  }, []);

  let tabs =
    project?.status === ClientProjectStatus.Started ? TabsItemsClient : TabsItemsClientDefinition;

  let tabActive = project?.status === ClientProjectStatus.Started ? 5 : 4;

  let disableTabs = [];

  if (!project?.partnerHasSubmittedDefinitions) {
    disableTabs = [3, 4];
  } else if (project?.partnerHasSubmittedDefinitions && project?.status < ClientProjectStatus.DefinitionsSet) {
    disableTabs = [3];
  } else {
    disableTabs = [];
  }

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>
                {project?.status === ClientProjectStatus.Started
                  ? I18n.get("Projeto em andamento")
                  : I18n.get("Definições do Projeto")}
              </H2Border>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <TabsProject
                context={project?.status === ClientProjectStatus.DefinitionsSet ? "definitions"
                        : project?.status === ClientProjectStatus.Started ? "inProgress" : "finalized"}
                page="extras"
                externalLoading={isLoadingTypes}
                projectStatus={project?.status}
                partnerHasSubmittedDefinitions={project?.partnerHasSubmittedDefinitions}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Extras")}</S.H4>
                      </S.TitleWithIcon>
                    </S.RowCenter>
                  </Col>
                </Row>
                <Row>
                  <Col sm={24}>
                    <S.SubTitle>
                      <p>{I18n.get("New Extra")}</p>
                      <span />
                    </S.SubTitle>
                  </Col>
                  <Col xs={24} sm={24}>
                    <S.Text>
                      {I18n.get(
                        "To start the quotation, we need some information. They will be sent to the Partner, who will then formalize a proposal and send it directly to you via our platform."
                      )}
                    </S.Text>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <S.ContentForm>
                      <S.InputsForm>
                        <S.RowInput>
                          <Input
                            placeholder={I18n.get("Name")}
                            label
                            required
                            value={inputsForm.name}
                            onChange={(value) =>
                              onChangeInputForm("name", value)
                            }
                            error=""
                          />
                        </S.RowInput>
                        <S.RowInput
                          style={{ marginLeft: auth?.is_client_or_client_user ? 30 : 0 }}
                        >
                          {auth?.is_client_or_client_user ? (
                            <CurrencyInput
                              value={inputsForm.budget}
                              onChange={(value) =>
                                onChangeInputForm("budget", value)
                              }
                              currency={projectCurrency}
                              placeholder={I18n.get("Budget")}
                              error={errorTypeBudget}
                              clearError={() => {
                                setErrorTypeBudget("");
                              }}
                            />
                          ) : null}
                        </S.RowInput>
                      </S.InputsForm>
                    </S.ContentForm>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <S.ContentButtons>
                      <S.Text strongText>
                        {I18n.get("Choose the type of extra")}
                      </S.Text>
                      <S.ContentTypesOfExtras>
                        {isLoadingTypes ? (
                          <S.ContentLoading>
                            <Loading
                              text={I18n.get("Loading...")}
                              sizeText={14}
                              sizeIcon={16}
                              color="#000000"
                              align="left"
                            />
                          </S.ContentLoading>
                        ) : (
                          dataTypes?.map((item) => (
                            <button
                              key={item?.code}
                              onClick={() => clickNewType(item?.code)}
                              className={
                                activeKey === item.code ? "active" : ""
                              }
                            >
                              <span>{I18n.get(item?.label)}</span>
                              <Icon type="plus" />
                            </button>
                          ))
                        )}
                      </S.ContentTypesOfExtras>

                      {errorTypeRequred && !activeKey ? (
                        <S.ErrorTypeRequredDiv>
                          <p>{errorTypeRequred}</p>
                        </S.ErrorTypeRequredDiv>
                      ) : (
                        <S.ErrorTypeRequredDiv />
                      )}
                    </S.ContentButtons>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <S.ButtonsFooter>
                      <ButtonBpool
                        text={I18n.get("Back")}
                        theme="secondary"
                        onClick={handleBack}
                      />
                      <ButtonBpool
                        text={I18n.get("Next")}
                        theme="primary"
                        onClick={handleNext}
                        loading={isLoadingNext}
                        disabled={isDisabledNext}
                      />
                    </S.ButtonsFooter>
                  </Col>
                </Row>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
