import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Task } from '../../utils/task';
import { PROJECT_API } from '../../actions/project/actions';
import iconUpload from '../../svg/icon-upload.svg';
import Dropzone from 'react-dropzone';
import FileUploadList from '../../modules/components/FileUploadList';


const mapStateToProps = state => {
    return Object.assign({}, state, { ...state.project });
};

const mapDispatchToProps = dispatch => {
    return {
        get_project: data => {
            dispatch(PROJECT_API.get(data));
        },
        upload_file: data => {
            dispatch(PROJECT_API.upload_filev2(data));
        },
        delete_file: data => {
            dispatch(PROJECT_API.delete_filev2(data));
        },
        save: data => {
            dispatch(PROJECT_API.update_briefing(data));
        },
        saveProject: data => {
            return dispatch(PROJECT_API.update(data));
        },
    };
};


class BriefingForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            projectId: props.match.params.projectId,
            form: {
                files: props.files || [],
                isSubmit: false,
            },
            task: {},
            upload_task: {}
        };

        this.upload = this.onUpload.bind(this);
    }

    scrollToTop() {
        const scrollDuration = 600;
        const scrollHeight = window.scrollY;
        const scrollStep = Math.PI / (scrollDuration / 15);
        const cosParameter = scrollHeight / 2;
        let scrollCount = 0;
        let scrollMargin;
        let scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                scrollCount = scrollCount + 1;
                scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                window.scrollTo(0, (scrollHeight - scrollMargin));
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }

    componentDidMount() {
        //this.props.get_assets({});
        this.scrollToTop();

        if (this.props.match.params.projectId) {

            var data = {
                projectId: this.props.match.params.projectId
            };

            var task = new Task();

            task.start();

            this.setState({ task: task }, function () {
                this.props.get_project(data);
            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.project && this.props.project.task;

        if (!prevState) return;
        if (!task) return;

        var nextState = Object.assign({}, prevState);

        if (task.id != prevState.task.id) {

            nextState.task = task;

            if (task.result) {

                nextState.form.files = this.props.files || [];

            }

            this.setState(nextState);
        }
    }

    onUpload(file, e) {

        if (!file) return;

        if (e) e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            var upload_task = new Task();

            upload_task.start();

            this.setState({ upload_task: upload_task }, function () {

                const data = { projectId: this.state.projectId, name: file.name, base64: e.target.result };

                this.props.upload_file(data)
                    .then(response => {

                        this.setState((prevState) => {

                            var nextState = Object.assign({}, prevState);

                            nextState.form.files.push(response.data);
                            nextState.upload_task = { result: true };

                            return nextState;
                        });

                    })
                    .catch(error => {

                        this.setState({ upload_task: { result: false } });

                    });
            });
        };
        reader.readAsDataURL(file);
    }

    delFile(item) {

        var data = {
            projectId: this.props.match.params.projectId,
            ...item
        };

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.delete_file(data);
        });


    }

    onDrop(files) {

        files = files.map(file => {

            this.upload(file);
        });
    }

    onCancel() {

    }

    getDataUploadMult = (files) => {

        let file = files[0];

        delete file['uid'];

        const data = {
            ...this.props.project,
            filesSend: file
        }

        this.props.upload_file(data);
    }


    render() {
        let filesNormalize = this.props.files && this.props.files.map((item) => {
            return {
                ...item,
                uid: item.id,
            }
        })


        return (
            <div>

                <h1 className="title title-3">{I18n.get("Arquivos")}</h1>
                <br />
                <br />

                <div className="card">
          
                <div className="card-content" style={{paddingTop: "30px", paddingBottom: "30px"}}>

                <div className="columns">
                    <div className="column is-one-quarter">
                    {I18n.get("1. Este ambiente é coloborativo, onde Cliente e Parceiro podem trocar arquivos relacionados ao projeto.")}
                    <br /><br /><br /><br /><br />
                    {I18n.get("2. Somente a pessoa que fez o upload do arquivo que pode excluir o mesmo.")}<br /><br /><br />

                        <FileUploadList
                            getDataUpload={this.getDataUploadMult}
                            name="files"
                            uploadId={this.props.project.projectId}
                            get2={this.props.get2 || false}
                            fileListGet={filesNormalize || []}
                            disabledList={true}
                            limit={1}
                            output="object"
                        />




                    </div>


                    <div className="column fileList ">



                        {this.props.files && this.props.files.map((item, index) =>
                            (<div key={index} className="columns itemFile" style={{ borderBottom: "1px solid black" }}>
                                <div className="column is-two-thirds is-clearfix upload-item" >
                                    <a href={item.url} target="_blank">{item.name}</a>
                                </div>
                                <div className="column ">
                                    {item.uploadedDateFmt}
                                    {!this.state.upload_task.busy
                                        && !this.state.task.busy && <div className="is-pulled-right">

                                            {item.uploadedByUsername == this.props.auth.username
                                                && <a className="delete" onClick={this.delFile.bind(this, item)} ></a>}
                                        </div>}
                                </div>
                            </div>)
                        )}

                        {this.props.files && this.props.files.length == 0 && <p>{I18n.get("Nenhum arquivo.")}</p>}

                    </div>




                </div>

            </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BriefingForm));


//export default BriefingForm;
