import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Content = styled.div`
  display: flex;
`;

export const ButtonChangeStatus = styled.button`
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  margin-left: 15px;
  color: #000;
  cursor: pointer;
`;

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  background: #ebebeb;
`;

export const Infos = styled.div`
  padding: 0 0 30px 0;

  strong {
    margin-right: 3.5px;
  }
`;

export const Errors = styled.div`
  h4 {
    margin: 10px 0 0 0;
    font-size: 14px;
    font-weight: 600;
    color: red;
  }

  ul {
    padding-left: 10px;

    li {
      color: red;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  margin: 5px 0 15px 0;

  p {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Subtitle = styled.div`
  margin: 15px 0 5px 0;

  p {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const ContentRadios = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const ActionsButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;
`;

export const ContentLoading = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
`;

export const NotUsersList = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  border-color: #e7e7e7;
  border-radius: 10px;
  padding: 0 15px;
  cursor: not-allowed;

  p {
    font-size: 14px;
    color: #979797;
    cursor: not-allowed;
  }
  
  span {
    color: #979797;
    cursor: not-allowed;
  }
`;