import axios from "axios";
import { Auth } from "aws-amplify";

import Config from "../../config";


axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const upload_global = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        // [HttpGet("/api/client-project/{projectId}/milestones")]

        const dataSend = {
          ...data,
        };

        delete dataSend.projectId;

        
        const checkObj = Object.keys(dataSend).length > 0;
        
        let url = "";
        

        const send = checkObj ? dataSend : null;


        if(checkObj) {
          url = `${Config.API_BASE_URL}/client-project/${data.projectId}/global-Authorization-upload`;
        } else {
          url = `${Config.API_BASE_URL}/client-project/${data.projectId}/global-Authorization-upload-remove`;
        }

       

        return axios
          .put(
            `${url}`,
            send,
            config
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error;
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default upload_global;
