import styled from "styled-components";
import MaskedInput from "antd-mask-input";
import { DatePicker } from "antd";

export const StyledInput = styled(DatePicker)`
  width: 100%;
  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    right: 30px;
    font-size: 20px;
    top: 46%;
    color: #1883ff;
  }
  input.ant-input:read-only {
    height: 82px;
    background: #e7e7e7 !important;
    border-radius: 56px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #979797;
    box-sizing: border-box;
    border: solid 1px ${(props) => (props.error ? "#f00" : "e7e7e7")};
    width: 100%;
  }

  input.ant-input[disabled] {
    opacity: 0.4;
  }
`;

export const StyleField = styled.div`
  margin-bottom: 40px;
`;
