import React from 'react';
import ReactDOM from 'react-dom';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import { CardSimple } from '../../modules/components/Cards';
import { TitleBorder } from '../../modules/components/Titles';

import FormFilterDash from '../../modules/Admin/components/filterDashAdminC';

import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
// import TableRenderers from 'react-pivottable/TableRenderers';
// import Plot from 'react-plotly.js';
// import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';


import { Task } from '../../utils/task';

import {
    Row,
    Col,
} from 'antd';

// create Plotly renderers via dependency injection
// const PlotlyRenderers = createPlotlyRenderers(Plot);
const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        pivot: (data) => {
            dispatch(BP_API.pivot());
        },  
        pivot_table: (data) => {
            dispatch(BP_API.pivot_table(data));
        }, 
    };
};

class DashboardAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            task: {},
            reload: 1
        };
    }

     componentDidMount = () => {

        const DataRanger = new Date;
        const toDate = moment(DataRanger).endOf('month').format("YYYY-MM-DD");
        const fromDate = moment(DataRanger).startOf('month').add(-1, 'M').format("YYYY-MM-DD");

     
        const dataInit = {
            from: fromDate, 
            to: toDate
        }

        this.props.pivot()
        


    }

    handleFilters = (data) =>{
        var task = new Task();

        task.start();

        this.setState({ task: task, reload: 2 }, function () {
           this.props.pivot_table(data)
        });
        
    }

     componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.bp.task) return;



        if(this.props.bp.pivotTable != prevProps.bp.pivotTable){
            this.setState({
                task: this.props.bp.task,
                reload: 1,
            });
        }
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/dashboard" className="is-black">{I18n.get("Dashboard")}</NavLink></li>
                    </ul>
                </nav>

                <Row>
                    <Col sm={24}>
                        <CardSimple>
                            <FormFilterDash
                                filters={this.props.bp && this.props.bp.pivot || []}
                                action={this.handleFilters}
                                task={this.state.task}
                            />
                        </CardSimple>
                    </Col>
                </Row>

                <Row>
                    <Col sm={24}>
                        <CardSimple>
                            <div className="pivotTableBp">
                                {this.state.reload != 2 && 
                                    <PivotTableUI
                                        data={this.state.reload != 2 ?  (this.props.bp && this.props.bp.pivotTable || []) : []}
                                        onChange={s => this.setState(s)}
                                        // renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                                        {...this.state}           
                                    />
                                }
                            </div>
                        </CardSimple>
                    </Col>
                </Row>
            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdmin);
