import styled from 'styled-components';

export const WrapperModal = styled.div`
  width: 700px;
  height: 590px;
  background: #000000;
  border-radius: 10px;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 110px;
`;

export const HeadModal = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

export const MessageModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
  }

  p {
    color: #ffffff;
    margin: 50px 0 0 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
  }
`;

export const CtaModal = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`;

export const ButtonBack = styled.button`
  border: 1px solid;
  border-color: #ffffff;
  background: transparent;
  border-radius: 26px;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  cursor: pointer;
  padding: 12.5px 60px;
  margin-right: 15px;
`;

export const ButtonConfirm = styled.button`
  background-color: ${(props) =>
    props.bgColor ? `${props.bgColor};` : '#FF0000;'};
  border: none;
  border-radius: 26px;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  cursor: pointer;
  padding: 12.5px 50px;
  margin-left: 15px;
  width: 100%;
`;

export const ButtonConfirmSuccess = styled.button`
  border: 1px solid;
  border-color: #1883ff;
  background: #1883ff;
  border-radius: 26px;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  cursor: pointer;
  padding: 12.5px 60px;
  margin-right: 15px;
`;
