import React, { useState, useEffect } from "react";
import { NotificationBPool } from "../../../../../../components/UI/NotificationBPool";
import { I18n } from "aws-amplify";
import { Icon } from "antd";

import * as S from "./styles";

export const TableSuppliersPreview = ({ data, isLoading, dataCb }) => {
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);

  const handleDeleteSupplier = async ({ sku }) => {
    setIsLoadingInternal(true);
    try {
      const updated = data?.list.filter((item) => item.sku !== sku);
      dataCb(updated);
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao deletar"),
        duration: 3,
      });
    }
    setIsLoadingInternal(false);
  };

  const columns = [
    {
      title: I18n.get("Fornecedor"),
      dataIndex: "name",
    },
    {
      title: I18n.get("Email 1"),
      dataIndex: "emailPrimary",
    },
    {
      title: I18n.get("Email 2"),
      dataIndex: "emailSecondary",
    },
    {
      title: I18n.get("Telefone"),
      dataIndex: "phoneNumber",
    },
    {
      title: I18n.get("Valor"),
      dataIndex: "totalFmt",
    },
    {
      title: "",
      dataIndex: "id",
      render: (type, record) => (
        <S.ButtonDelete
          onClick={() => handleDeleteSupplier({ sku: record.sku })}
        >
          <Icon type="delete" />
        </S.ButtonDelete>
      ),
    },
  ];

  return (
    <S.Wrapper>
      <S.HeadPreview
        style={{
          backgroundColor: !data?.listDuplicateds?.length
            ? "#1984ff"
            : "#f65c54",
        }}
      >
        {!data?.listDuplicateds?.length
          ? I18n.get("Preview de dados de fornecedor (dados não salvos)")
          : data?.listDuplicateds?.length > 1
          ? `${data?.listDuplicateds?.length} ${I18n.get(
              "fornecedores estão duplicados (dados não salvos)"
            )}`
          : I18n.get("1 fornecedor está duplicado (dados não salvos)")}
      </S.HeadPreview>
      {data && data?.list?.length ? (
        <S.Table
          columns={columns}
          dataSource={data?.list}
          pagination={data?.list?.length > 8 ? true : false}
          loading={isLoading || isLoadingInternal}
          scroll={data?.list?.length > 8 ? { y: 450 } : {}}
          onRow={(record, rowIndex) => {
            if (record?.duplicated || record?.isDuplicated) {
              return { className: "supplier-duplicated" };
            }
          }}
        />
      ) : (
        <div />
      )}
    </S.Wrapper>
  );
};
