import React from "react";

import { I18n } from "aws-amplify";

import { ProjectType } from "../../model";
import iconCriacao from "../../image/icot1-dark.svg";
import iconEvento from "../../image/icot3-dark.svg";
import iconPitch from "../../image/class.svg";

import Config from "../../config";

import styled from "styled-components";

import { Row, Col, Tooltip, Icon } from "antd";

const ItemButton = styled.a`
  width: 100%;
  display: block;
  border: solid 4px #fff;
  border-radius: 15px;
  background: #eeeeee;
  text-align: center;
  padding: 30px 10px;
  min-height: 157px;
  color: #434343;
  font-size: 20px;
  font-weight: bold;
  font-family: "Helvetica Neue LT Std";
  transition: all 0.5s ease;
  margin-bottom: 20px;
  position: relative;

  .Info {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &.pitch {
    img {
      width: 87px;
    }
  }

  &.live {
    img {
      width: 80px;
    }
  }

  img {
    margin-right: 20px;
    width: 60px;
  }

  &:hover {
    color: #434343;
    border: solid 4px #eee;
  }

  &.is-selected {
    border: solid 4px #1883ff;
  }

  @media screen and (max-width: 940px) {
    min-height: 80px;
    img {
      width: 35px;
    }

    &.pitch {
      img {
        width: 40px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    img {
      margin: 0 0 10px;
    }

    span {
      display: block;
    }
  }
`;

const CreativeOrEvent = (props) => {
  let isCreative = props.projectType === ProjectType.Creative;
  let isEvent = props.projectType === ProjectType.EventCustom;
  let isBPitch = props.projectType === ProjectType.BPitch;
  // let isEvent = props.projectType === ProjectType.EventCustom || props.projectType === ProjectType.EventBundle;

  // let isSelected = isCreative || isEvent   TODO: Original eventos;
  let isSelected = isCreative;

  let creativeCss = isCreative ? "is-selected" : "";
  let eventCss = isEvent ? "is-selected" : "";
  let bpitchCss = isBPitch ? "is-selected" : "";

  if (props.types && props.types.length == 0) return null;

  return (
    <div className="selectProject" style={{ paddingTop: "90px" }}>
      <Row>
        <Col sm={24}>
          <div className="field">
            <label className="title-bpool big tmargin">
              {I18n.get("Tipo do Projeto")}
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        {props?.types.indexOf(0) != -1 && (
          <Col sm={8}>
            <ItemButton
              className={creativeCss + " comunicacao"}
              disabled={props.disabled}
              onClick={props.onCreativeClick}
            >
              <img src={iconCriacao} alt={I18n.get("BPool Marketplace")} />
              <span>{I18n.get("BPool Marketplace")}</span>

              <span className="Info">
                <Tooltip
                  title={I18n.get(
                    "Criação e produção de projetos de Marketing que não são relacionados a eventos, por exemplo websites, landing pages, CRM, campanhas digitais, etc"
                  )}
                >
                  <Icon type="question-circle" theme="filled" />
                </Tooltip>
              </span>
            </ItemButton>
          </Col>
        )}

        {props?.types.indexOf(2) != -1 && (
          <Col sm={8}>
            <ItemButton
              className={`live ${eventCss}`}
              disabled={props.disabled}
              onClick={props.onCustomClick}
            >
              <img
                src={iconEvento}
                alt={I18n.get("BPool Proposal")}
                style={{ left: "38%" }}
              />
              <span>{I18n.get("BPool Proposals")}</span>

              <span className="Info">
                <Tooltip
                  title={I18n.get(
                    "Propostas customizadas com cotação livre para os parceiros. Aplica-se para projetos maiores e não padronizados, live marketing e produção de vídeos / fotos."
                  )}
                >
                  <Icon type="question-circle" theme="filled" />
                </Tooltip>
              </span>
            </ItemButton>
          </Col>
        )}

        {props?.types.indexOf(4) != -1 && (
          <Col sm={8}>
            <ItemButton
              className={`pitch  ${bpitchCss}`}
              disabled={props.disabled}
              onClick={props.onBPitchClick}
            >
              <img src={iconPitch} alt={I18n.get("BPitch")} />
              <span>{I18n.get("BPitch")}</span>
              <span className="Info">
                <Tooltip
                  title={I18n.get(
                    "Assessoria de clientes na definição de parceiros ideias e no processo de concorrência"
                  )}
                >
                  <Icon type="question-circle" theme="filled" />
                </Tooltip>
              </span>
            </ItemButton>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CreativeOrEvent;
