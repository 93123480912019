import React, { useState, useEffect } from "react";

import { PROJECTS_API } from "../../../../store/actions/projects";
import { Link, useHistory, useParams } from "react-router-dom";

import Loading from "../../../../../components/pages/loading";

import { CreateProjectStep2 } from "../Step2";
import { Step2Combos } from "../Step2Combos";

import { useSelector } from "react-redux";

export const SelectProject = () => {
  const client = useSelector((state) => state.client);
  const [projectDetails, setProjectDetails] = useState({});
  const [view, setView] = useState(false);
  const [custom, setCustom] = useState(false);

  let params = useParams();

  const getStep = () => {
    function getProject() {
      const data = {
        projectId: params.projectId,
      };
      PROJECTS_API.get_project_step2(data).then((response) => {
        setProjectDetails(response?.payload?.project?.data);

        // setLoadingAssets(false);
      });
    }
    getProject();
  };

  function selectView(view) {
    setView(view);
    setCustom(true);
  }

  useEffect(() => {
    getStep();
  }, []);

  useEffect(() => {
    const disableNewProjectStep2BundleView =
      client?.customization &&
      client?.customization?.disableNewProjectStep2BundleView;

    if (disableNewProjectStep2BundleView !== undefined) {
      const initialView = disableNewProjectStep2BundleView ? "CART" : "BUNDLE";
      setView(initialView);
    }
  }, [client]);

  if (client?.customization?.disableNewProjectStep2BundleView == undefined) {
    return <Loading />;
  }

  return (
    <>
      {view == "CART" && (
        <CreateProjectStep2 selectView={selectView} custom={custom} />
      )}

      {view == "BUNDLE" && <Step2Combos selectView={selectView} />}
    </>
  );
};
