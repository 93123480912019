const renderValue = (value) => {
  if (value === 0 || value === "0") {
    return value;
  } else if (value) {
    return value;
  } else {
    return "---";
  }
};

export const converData = (data) => {
  return {
    totalBPaymentsFinishedFmt: renderValue(data?.totalBPaymentsFinishedFmt),
    totalBPaymentsInProgressFmt: renderValue(data?.totalBPaymentsInProgressFmt),
    totalBPaymentsFmt: renderValue(data?.totalBPaymentsFmt),
  };
};
