import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useBriefings = () => {
  const getBriefing = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${id}/briefing`,
      config
    );

    return data;
  };

  const updateBriefing = async (info) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/briefing`,
      info,
      config
    );

    return data;
  };

  const uploadFile = async (file) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/file`,
      file,
      config
    );

    return data;
  };

  const deleteFile = async (info) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const dataSend = {
      id: info.id,
      projectId: info.projectId,
    };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project/file`,
      {
        data: dataSend,
        ...config,
      }
    );

    return data;
  };

  return {
    getBriefing,
    uploadFile,
    updateBriefing,
    deleteFile,
  };
};
