import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import { ContentPage } from "../../../../RegistrationFlow/Full/TeamRegistration/content-page";
import { Menu } from "../../../components/Menu";
import { MenuAdmin } from "../../../../../components/UI/MenuAdmin";
import { ModalBp } from "../../../../../components/UI/ModalBp/ModalBp";

import { getNameOfUrlLocation } from "../../../utils/getNameOfUrlLocation";

import * as S from "./styles";

export const EditTeamRegistration = () => {
  let params = useParams();
  let location = useLocation();
  const [partnerId, setPartnerId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [visible, setVisible] = useState(false);

  const handleOkInfos = (status) => {
    if (status) {
      setVisible(true);
    }
  };

  useEffect(() => {
    setPartnerId(params.partnerId);
  }, [params]);

  useEffect(() => {
    setLocationName(getNameOfUrlLocation(location.pathname) || "");
  }, [location]);

  return (
    <>
      <LoggedPage>
        <S.SubMenuWrapper>
          <MenuAdmin />
        </S.SubMenuWrapper>
        <div className="hero-body">
          <div className="container">
            <Row style={{ marginTop: 25 }}>
              <Col xs={6}>
                <Menu partnerId={partnerId} locationName={locationName} />
              </Col>
              <Col xs={18}>
                <ContentPage
                  contextPage="admin"
                  partnerId={partnerId}
                  onChangeInfo={(info) => console.log("change", info)}
                  sendOk={(status) => handleOkInfos(status)}
                />
              </Col>
            </Row>
          </div>
        </div>
      </LoggedPage>

      <ModalBp
        visible={visible}
        bodyText={I18n.get("Saved information.")}
        subBodyText={I18n.get("Your information has been successfully saved.")}
        okText={I18n.get("OK")}
        // cancelText={I18n.get("Cancelar")}
        handleClickOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        // typeModal="isConfirm"
        // isDanger={true}
      />
    </>
  );
};
