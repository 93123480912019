import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink, withRouter } from 'react-router-dom'

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';
import { QueryString } from '../../utils/querystring';

import BPitchTypeForm from './bpitch/_bpitch-type';
import BPitchDetails from './bpitch/_bpitch-details';
import BPitchPartner from './bpitch/_bpitch-partner';
import BPitchBudget from './bpitch/_bpitch-budget';
import BPitchCost from './bpitch/_bpitch-cost';

import BPitchProduct from './bpitch/_bpitch-product';
import BPitchBusiness from './bpitch/_bpitch-business';
import BPitchReason from './bpitch/_bpitch-reason';
import BPitchCompetition from './bpitch/_bpitch-competition';
import BPitchAdditional from './bpitch/_bpitch-additional';

import BPitchBriefing from './bpitch/_bpitch-briefing';
import BPitchAudience from './bpitch/_bpitch-audience';
import BPitchKPI from './bpitch/_bpitch-kpi';

import BPitchUpload from './bpitch/_bpitch-upload';
import BPitchSchedule from './bpitch/_bpitch-schedule';
import BPitchScorecard from './bpitch/_bpitch-scorecard';
import BPitchTerms from './bpitch/_bpitch-terms';
import BPitchCustomNDA from './bpitch/_bpitch-custom-nda';

import { bPitchMenu } from './bpitch/_menu';

import './event-customize-form.css';

import { BPitchType } from '../../model';


class EventCustomizeForm extends React.Component {
    constructor(props) {
        super();

        const queryString = QueryString.extract(props.location.search);

        const { s = 0 } = queryString;

        this.state = {
            form: {
                isSubmit: false,
            },
            step: s && parseInt(s),
            task: {},
        };
    }

    componentDidMount() {
        var data = {
            clientId: this.props.client.clientId,
            projectId: this.props.match.params.projectId
        };

        this.props.getPreferredPartners(data);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                if (FormUtils.isFormField(nextState.form[item])) {
                    nextState.form[item].error = this.props.errors[item];
                    nextState.form[item].value = this.props[item] || nextState.form[item].defaultValue;
                }
                else if (typeof nextState.form !== 'undefined') {
                    nextState.form[item] = this.props[item];
                }
            });

            this.setState(nextState);
        }
    }

    show(index, e) {
        e.preventDefault();

        this.setState({ step: index });
    }


    getData() {

        var data = {
            clientId: this.props.clientId,
            ...this.props.project
        };

        Object.keys(this.state.form).forEach((item) => {

            if (FormUtils.isFormField(this.state.form[item])) {

                data[item] = this.state.form[item].value
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    handleBundleBtnClick = () => {
        this.props.onBundleBtnClick();
    }

    handleResumeBtnClick = () => {
        const cart = this.props.cartAssets;
        this.props.onResumeBtnClick(cart);
    }

    onTypeSelect() {

        this.setState({ step: 0 });
    }

    getNextStep(){

        const bPitch = this.props.bPitch || {};

        const { isProductInfoViaUpload, isAudienceInfoViaUpload, isBusinessObjectiveInfoViaUpload, isReasonToBelieveInfoViaUpload, isCompetitionInfoViaUpload, isBriefingInfoViaUpload, isKpiInfoViaUpload } = bPitch;

        if (this.state.step == 4){

            if (!isProductInfoViaUpload) return 5;
            if (!isAudienceInfoViaUpload) return 6;
            if (!isBusinessObjectiveInfoViaUpload) return 7;
            if (!isReasonToBelieveInfoViaUpload) return 8;
            if (!isCompetitionInfoViaUpload) return 9;
            if (!isBriefingInfoViaUpload) return 10;
            if (!isKpiInfoViaUpload) return 12;
            
            return 13;
        }
        if (this.state.step == 5){

            if (!isAudienceInfoViaUpload) return 6;
            if (!isBusinessObjectiveInfoViaUpload) return 7;
            if (!isReasonToBelieveInfoViaUpload) return 8;
            if (!isCompetitionInfoViaUpload) return 9;
            if (!isBriefingInfoViaUpload) return 10;
            if (!isKpiInfoViaUpload) return 12;

            return 13;
        }
        if (this.state.step == 6){

            if (!isBusinessObjectiveInfoViaUpload) return 7;
            if (!isReasonToBelieveInfoViaUpload) return 8;
            if (!isCompetitionInfoViaUpload) return 9;
            if (!isBriefingInfoViaUpload) return 10;
            if (!isKpiInfoViaUpload) return 12;

            return 13;
        }
        if (this.state.step == 7){

            if (!isReasonToBelieveInfoViaUpload) return 8;
            if (!isCompetitionInfoViaUpload) return 9;
            if (!isBriefingInfoViaUpload) return 10;
            if (!isKpiInfoViaUpload) return 12;
            
            return 13;
        }
        if (this.state.step == 8){

            if (!isCompetitionInfoViaUpload) return 9;
            if (!isKpiInfoViaUpload) return 12;
            
            return 13;
        }

        if (this.state.step == 9){

            if (!isBriefingInfoViaUpload) return 10;
            if (!isKpiInfoViaUpload) return 12;

            return 13;
        }

        if (this.state.step == 11){

            if (!isKpiInfoViaUpload) return 12;

            return 13;
        }
            
        return this.state.step + 1;
    }

    isOptional(menuIndex){

        const bPitch = this.props.bPitch || {};

        const { isProductInfoViaUpload, isAudienceInfoViaUpload, isBusinessObjectiveInfoViaUpload, isReasonToBelieveInfoViaUpload, isCompetitionInfoViaUpload, isBriefingInfoViaUpload, isKpiInfoViaUpload } = bPitch;

        if (menuIndex == 5 && isProductInfoViaUpload) return true;
        if (menuIndex == 6 && isAudienceInfoViaUpload) return true;
        if (menuIndex == 7 && isBusinessObjectiveInfoViaUpload) return true;
        if (menuIndex == 8 && isReasonToBelieveInfoViaUpload) return true;
        if (menuIndex == 9 && isCompetitionInfoViaUpload) return true;
        if (menuIndex == 10 && isBriefingInfoViaUpload) return true;
        if (menuIndex == 11 && isBriefingInfoViaUpload) return true;
        if (menuIndex == 12 && isKpiInfoViaUpload) return true;

        return false;
    }

    nextStep(){

        const next = this.getNextStep();

        this.setState({ step: next });
    }

    render() {

        const bPitch = this.props.bPitch || { pitchType: BPitchType.PerProject };

        const steps = bPitchMenu.map((item) => item.children).reduce((acc, curr) => acc.concat(curr));

        let index = -1;

        return (
            <form onSubmit={this.submitForm.bind(this)}>
                <div className="columns columns-for-np">
                    <div className="column left-column-for-np is-3 is-fullheight" style={{ paddingLeft: '1rem', backgroundColor: '#EEEEEE' }}>

                        <aside className="menu">
                            {bPitchMenu && bPitchMenu.map((group) => {

                                return (<>

                                    <p className="menu-label" style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
                                        {group.label}
                                    </p>
                                    <ul className="bp-menu-list" style={{ paddingTop: '0px' }}>

                                        {group && group.children.map((item, sub) => {

                                            index++;

                                            const className = this.state.step == index ? 'bp-is-active' : 'bp-is-inactive';

                                            let disabledStep = "";

                                            if (this.state.step == null) {
                                                disabledStep = "disabledLink"
                                            }

                                            let optionalStep = "";

                                            if (this.isOptional(index)){
                                                optionalStep = "bpitch-optionalStep";
                                            }

                                            return (<li><a onClick={this.show.bind(this, index)} className={`${className} ${disabledStep} ${optionalStep}`}>{item}</a></li>);
                                        })}
                                    </ul>

                                </>);
                            })}
                        </aside>
                    </div>

                    <div className="column right-column-for-np is-9" style={{ backgroundColor: '#ffffff', padding: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                        {this.state.step == 0 && <BPitchTypeForm {...this.props} />}

                        {this.state.step == 1 && <BPitchDetails {...this.props} />}

                        {this.state.step == 2 && <BPitchBudget {...this.props} />}

                        {this.state.step == 3 && <BPitchCost {...this.props} />}

                        {this.state.step == 4 && <BPitchUpload {...this.props} />}

                        {this.state.step == 5 && <BPitchProduct {...this.props} />}

                        {this.state.step == 6 && <BPitchAudience {...this.props} />}

                        {this.state.step == 7 && <BPitchBusiness {...this.props} />}

                        {this.state.step == 8 && <BPitchReason {...this.props} />}

                        {this.state.step == 9 && <BPitchCompetition {...this.props} />}

                        {this.state.step == 10 && <BPitchAdditional {...this.props} />}

                        {this.state.step == 11 && <BPitchBriefing {...this.props} />}

                        {this.state.step == 12 && <BPitchKPI {...this.props} />}

                        {this.state.step == 13 && <BPitchPartner {...this.props} />}

                        {this.state.step == 14 && <BPitchSchedule {...this.props} />}

                        {this.state.step == 15 && <BPitchScorecard {...this.props} />}

                        {this.state.step == 16 && <BPitchTerms {...this.props} />}

                        {this.state.step == 17 && <BPitchCustomNDA {...this.props} />}

                        <div style={{ marginTop: '2rem' }}>

                            <div style={{ display: 'flex' }}>

                                <div style={{ flex: '1', textAlign: 'right', paddingRight: '1rem' }}>

                                    {this.state.step != null && this.state.step == 0 && <>

                                        <NavLink to={`/cliente/projetos/${this.props.match.params.projectId}/passo-1`}>
                                            <button className="button bt-bpool grey">{I18n.get("Voltar")}</button>
                                        </NavLink>
                                    </>}

                                    {this.state.step != null && this.state.step != 0 && <>

                                        <a onClick={this.show.bind(this, this.state.step - 1)} className="button bt-bpool grey">{I18n.get("Voltar")}</a>

                                    </>}
                                </div>

                                <div style={{ flex: '1', textAlign: 'left', paddingLeft: '1rem' }}>

                                    {this.state.step != null && this.state.step < (steps && steps.length - 1) && <>

                                        <a onClick={this.nextStep.bind(this)}>
                                            <button className="button bt-bpool">{I18n.get("Próximo")}</button>
                                        </a>

                                    </>}

                                    {this.state.step == (steps && steps.length - 1) && <>

                                        <NavLink to={`/cliente/projetos/${this.props.match.params.projectId}/passo-3`}>
                                            <button className="button bt-bpool">{I18n.get("Próximo")}</button>
                                        </NavLink>

                                    </>}

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </form>
        );
    }
}

export default withRouter(EventCustomizeForm);
