import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useDebounce } from "use-debounce";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { H2Border } from "../../../components/UI/Titles";
import { useParams, Link, useHistory } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import Loading from "../../../components/UI/Loading";
import Content from "../../../components/UI/Content";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import { Textarea } from "../../../components/UI/Textarea";
import IcoForecast from "../svgs/ico-step5-forecast.svg";
import IcoCheck from "../svgs/ico-step5-check.svg";
import IcoPayment from "../svgs/ico-step5-payment.svg";
import IcoTime from "../svgs/ico-step5-time.svg";
import GlobalFileBox from "../../Client/CreateProject/components/GlobalFileBox";

import { useReview } from "../../../hooks/useReview/useReview.hook";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";

import { AssetBundle } from "../../../components/UI/AssetBundle";
import { ConvertValuesCreativeScope } from "../../../../v2/pages/ProjectsList/Invitations/ProjectDetails/utils/convertValuesCreativeScope";

import { TabsProject } from "../../../components/UI/TabsProject";
import {
  Informations,
  GeneralDemand,
  CreativeScope,
  Details,
  AboutPartner,
} from "./modules";

import * as S from "./styles";

export const Review = () => {
  let params = useParams();
  const auth = useSelector((state) => state.auth);

  const state = useSelector((state) => state);
  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState(null);
  const {
    getReviewDefinition,
    putNotes,
    putAssets,
    sendToClient,
    projectThreasholdAuthorization,
  } = useReview();
  const { getProjectById, putGlobal, removeGlobal } = useProjects();
  const [notes, setNotes] = useState(null);
  const [loadSend, setLoadSend] = useState(false);
  const [visibleInclude, setVisibleInclude] = useState(false);
  const [visibleExclude, setVisibleExclude] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleSend, setVisibleSend] = useState(null);
  const [codeDelete, setCodeDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [statusProject, setStatusProject] = useState({});
  const [visibleDecline, setVisibleDecline] = useState(false);
  const [visibleApprove, setVisibleApprove] = useState(false);

  const [successDecline, setSuccessDecline] = useState(false);
  const [successApprove, setSuccessApprove] = useState(false);

  const [projectErrors, setProjectErrors] = useState({});
  const [projectProcurement, setProjectProcurement] = useState(null);

  const [searchValueDebounced] = useDebounce(notes, 1000);

  const [listOfAssets, setListOfAssets] = useState([]);
  const [dataValuesCreativeScope, setDataValuesCreativeScope] = useState({});
  const [errorInGet, setErrorInGet] = useState(false);
  const [sendingNotes, setSendingNotes] = useState(false);
  const [init, setInit] = useState(false);
  const [currencyProject, setCurrencyProject] = useState({});

  const [contactInformation, setContactInformation] = useState({});
  const [generalDemand, setGeneralDemand] = useState(null);
  const [creativeScope, setCreativeScope] = useState({});
  const [details, setDetails] = useState({});
  const [aboutPartner, setAboutPartner] = useState({});

  const history = useHistory();

  const getEstimatedEndDate = (data) => {
    let months = "";
    let weeks = "";

    if (data?.estimatedDurationInMonths) {
      months = `${data?.estimatedDurationInMonths} ${I18n.get(
        "mes(es) e"
      )}${" "}`;
    } else {
      months = `0 ${I18n.get("mes(es) e")}${" "}`;
    }

    if (data?.estimatedDurationInWeeks) {
      weeks = `${data?.estimatedDurationInWeeks} ${I18n.get("semana(s)")}`;
    } else {
      weeks = `0 ${I18n.get("semana(s)")}`;
    }

    return `${months} ${weeks}`;
  };

  const loadReview = async () => {
    setIsLoading(true);
    try {
      if (projectId) {
        const response = await getReviewDefinition({ id: projectId });
        if (response?.success) {
          setProject(response?.data);
          setListOfAssets(response?.data?.bundle?.variations[0]?.assets);
          const objDataValuesCreativeScope = ConvertValuesCreativeScope({
            data: response?.data,
            isInvite: false,
          });
          setDataValuesCreativeScope(objDataValuesCreativeScope);

          setContactInformation({
            projectName: response?.data?.projectName,
            projectSKU: response?.data?.projectSKU,
            projectType: response?.data?.projectType,
            product: `${response?.data?.brandName} - ${response?.data?.productName}`,
          });
          setGeneralDemand({
            demand: response?.data?.demand,
            demandFiles: response?.data?.demandFiles,
          });
          setCreativeScope(response?.data?.bundle);
          setDetails({
            estimatedEndDate: getEstimatedEndDate(response?.data),
            estimatedStartDate: response?.data?.estimatedStartDate,
            estimatedDecisionInWeeks: response?.data?.estimatedDecisionInWeeks
              ? `${response?.data?.estimatedDecisionInWeeks} ${I18n.get(
                  "semana(s)"
                )}`
              : "---",
            paymentTermDays: `${response?.data?.paymentTermDays} ${I18n.get(
              "dias corridos"
            )}`,
            nonCompeteInDays: response?.data?.nonCompeteInDays,
            additionalRule: response?.data?.additionalRule,
            globalAuthorization: response?.data?.globalAuthorization,
            descriptiveHourMan: response?.data?.descriptiveHourMan,
          });
          setAboutPartner({
            partnerName: response?.data?.partnerName,
            partnerResponsibleEmail: response?.data?.partnerResponsibleEmail,
            partnerResponsibleName: response?.data?.partnerResponsibleName,
            partnerResponsiblePhoneNumber:
              response?.data?.partnerResponsiblePhoneNumber,
          });

          setCodeDelete(null);
          setSuccessDecline(false);
          setCurrencyProject(response?.data?.currency);
          if (auth?.is_client_or_client_user) {
            setNotes(response?.data?.clientNotes);
          } else {
            setNotes(response?.data?.partnerNotes);
          }
          setInit(true);
        }
        const responseProject = await getProjectById({
          projectId: params?.projectId,
        });
        setStatusProject({
          status: responseProject?.data?.status,
          partnerHasSubmittedDefinitions:
            responseProject?.data?.partnerHasSubmittedDefinitions,
        });
        setProjectProcurement({
          isRequiredAprovation: responseProject?.data.isRequiredAprovation,
          isReEvaluate: responseProject?.data.isReEvaluate,
          isApproved: responseProject?.data.isApproved,
        });
      }
    } catch (error) {
      setErrorInGet(true);
    }
    setIsLoading(false);
  };

  const handleAproveOrDecline = async (approved) => {
    setLoadSend(true);

    const data = {
      projectId: params.projectId,
      clientNotes: notes ? notes : project?.clientNotes || "",
      definitionApproved: approved,
    };

    const response = await sendToClient(data);

    if (response?.success) {
      if (approved) {
        setVisibleApprove(false);
        setSuccessApprove(true);
      } else {
        setSuccessDecline(true);
        setVisibleDecline(false);
      }
    } else {
      setProjectErrors(response.errors);
      if (approved) {
        setVisibleApprove(false);
      } else {
        setVisibleDecline(false);
      }
    }
    setLoadSend(false);
    window.scrollTo(0, 0);
  };

  const partnerSend = async () => {
    setLoadSend(true);
    const data = {
      projectId: params.projectId,
      partnerNotes: notes ? notes : project?.partnerNotes || "",
    };

    const response = await sendToClient(data);

    if (response?.success) {
      setVisibleSend(true);
    } else {
      setLoadSend(false);
      setProjectErrors(response.errors);
    }
  };

  const cartArray =
    project?.bundle?.variations &&
    project?.bundle?.variations[0]?.assets &&
    project?.bundle?.variations[0]?.assets.map((item) => item.asset);

  const handleDeleteItem = async (code) => {
    let indices = [];

    let idx = cartArray.indexOf(code);

    while (idx != -1) {
      indices.push(idx);
      idx = cartArray.indexOf(code, idx + 1);
    }

    let removeItem = indices[0];

    let cartFull = cartArray;

    cartFull.splice(removeItem, 1);

    let cart = cartFull;

    let data = {
      projectId: params.projectId,
      assetsCode: cart,
    };

    putAssets(data).then((response) => {
      if (response?.success) {
        loadReview();
        setVisibleExclude(true);
      }
    });
  };

  const handleThreasholdAuthorization = async (authorization) => {
    let data = {
      projectId: params.projectId,
      authorized: authorization,
    };

    const response = await projectThreasholdAuthorization(data);

    await loadReview();

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth?.username) {
      loadReview();
    }
  }, [auth, projectId]);

  useEffect(() => {
    setProjectId(params.projectId);
  }, [params]);

  useEffect(() => {
    if (project?.partnerNotes && auth?.is_partner) {
      setNotes(project?.partnerNotes);
    } else {
      setNotes(project?.clientNotes);
    }
  }, [project]);

  const saveGlobal = async (data) => {
    const dataSend = {
      file: { ...data },
      projectId: project?.id,
    };

    if (data?.id) {
      const response = await putGlobal(dataSend);
      loadReview();
    } else {
      const response = await removeGlobal(dataSend);
      loadReview();
    }
  };

  const putNotesSave = async () => {
    const data = {
      notes: notes,
      projectId: params.projectId,
    };

    if (auth?.is_partner) {
      if (
        notes !== null &&
        notes?.toLowerCase() !== project?.partnerNotes?.toLowerCase()
      ) {
        setSendingNotes(true);
        await putNotes(data);
        setSendingNotes(false);
      }
    } else {
      if (
        notes !== null &&
        notes?.toLowerCase() !== project?.clientNotes?.toLowerCase()
      ) {
        setSendingNotes(true);
        await putNotes(data);
        setSendingNotes(false);
      }
    }
  };

  useEffect(() => {
    if (init) {
      putNotesSave();
    }
  }, [searchValueDebounced, init]);

  const stepItens = [
    {
      key: 1,
      label: I18n.get("Briefing"),
      url: `/${
        auth?.is_client_or_client_user ? "cliente" : "parceiro"
      }/projetos/v2/${params.projectId}/briefing`,
    },
    {
      key: 2,
      label: I18n.get("Milestones"),
      url: `/${
        auth?.is_client_or_client_user ? "cliente" : "parceiro"
      }/projetos/v2/${params.projectId}/macros`,
    },
    {
      key: 3,
      label: I18n.get("Review"),
      url: `/${
        auth?.is_client_or_client_user ? "cliente" : "parceiro"
      }/projetos/v2/${params.projectId}/review`,
    },
  ];

  const getStepError = (step) => {
    let stepError = {};
    stepError = stepItens.find((i) => i.key == step);

    return stepError;
  };

  function getCategories() {
    if (!state?.bp?.sectorsFlat) return [];
    if (state?.sameCategoryOnly) return [];
    if (!state.categoryIds) return [];

    return (
      project?.categoryIds &&
      project?.categoryIds.map((categoryId) => {
        const category = state?.bp?.sectorsFlat.filter(
          (item) => item.code == categoryId
        )[0];

        return category.label;
      })
    );
  }

  const handleConvertedValuesCreativeScope = async ({ values, currency }) => {
    const data = {
      ...values,
      currency,
    };
    const objDataValuesCreativeScope = ConvertValuesCreativeScope({
      data,
      isInvite: false,
    });
    setDataValuesCreativeScope(objDataValuesCreativeScope);

    const listAssetsUpdated = listOfAssets.map((item1) => {
      const matchingItem = values?.bundle?.variationResponse?.assets?.find(
        (item2) => item2.code === item1.code
      );

      if (matchingItem) {
        return {
          ...item1,
          priceFmt: matchingItem.priceFmt,
          priceTotalFmt: matchingItem.priceTotalFmt,
        };
      }

      return item1;
    });
    setListOfAssets(listAssetsUpdated);
  };

  const hasIsManHour =
    listOfAssets &&
    listOfAssets.length > 0 &&
    listOfAssets.some((asset) => asset?.isManHour === true);

  const checkdDescriptiveHourMan = hasIsManHour && !project?.descriptiveHourMan;

  const disabledSubmit =
    project?.partnerHasSubmittedDefinitions || checkdDescriptiveHourMan;

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Definições do Projeto")}</H2Border>
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <TabsProject
                context="definitions"
                page="review"
                externalLoading={isLoading}
                projectStatus={statusProject?.status}
                partnerHasSubmittedDefinitions={
                  statusProject?.partnerHasSubmittedDefinitions
                }
              />
            </Col>
          </Row>

          {errorInGet ? (
            <Row>
              <Col sm={24}>
                <S.TopHeader>
                  <S.Title>{I18n.get("Resumo do projeto")}</S.Title>
                  <S.Paragraph>
                    {I18n.get(
                      "Veja abaixo as informações mais relevantes do seu projeto"
                    )}
                  </S.Paragraph>
                  <S.ErrorInGetPage>
                    <strong>{I18n.get("Erro.")}</strong>
                    <p>
                      {I18n.get(
                        "Houve um erro ao trazer as informações desta página, tente novamente ou entre em contato conosco."
                      )}
                    </p>
                  </S.ErrorInGetPage>
                </S.TopHeader>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col sm={24}>
                  <S.TopHeader>
                    <S.Title>{I18n.get("Resumo do projeto")}</S.Title>
                    <S.Paragraph>
                      {I18n.get(
                        "Veja abaixo as informações mais relevantes do seu projeto"
                      )}
                    </S.Paragraph>
                    <br />
                    <S.Paragraph>
                      {project?.pdfBundle && project?.status >= 6 && (
                        <a
                          href={project?.pdfBundle}
                          target="_blank"
                          style={{ textAlign: "right" }}
                        >
                          <ButtonBpool
                            text={I18n.get("Download PDF")}
                            className="pull-right"
                            theme="primary"
                          />
                        </a>
                      )}

                      {project?.pdfProject && project?.status < 6 && (
                        <a href={project?.pdfProject} target="_blank">
                          <ButtonBpool
                            text={I18n.get("Download PDF")}
                            className="pull-right"
                            theme="primary"
                          />
                        </a>
                      )}
                    </S.Paragraph>
                    <br />

                    {!isLoading ? (
                      <>
                        {auth?.is_client_or_client_user &&
                          project?.partnerHasSubmittedDefinitions &&
                          project?.status != 6 &&
                          !projectProcurement?.isRequiredAprovation && (
                            <S.CloseScope>
                              <p>
                                {I18n.get(
                                  "Escopo fechado ou em análise, os assets não podem ser alterado."
                                )}
                              </p>
                            </S.CloseScope>
                          )}

                        {auth?.is_client_or_client_user &&
                          project?.status == 6 && (
                            <S.CloseScope>
                              <h3>
                                {I18n.get(
                                  "O ESCOPO FINAL DO SEU PROJETO ESTÁ APROVADO."
                                )}
                              </h3>
                              <p>
                                {I18n.get(
                                  "Veja abaixo os detalhes do projeto aprovado por"
                                )}{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {project?.clientTermsUpdatedByEmail}
                                </span>{" "}
                                {I18n.get("em")}{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {project?.clientTermsUpdatedOn}
                                </span>{" "}
                                {I18n.get("com o parceiro")}{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {project?.partnerName}
                                </span>
                                .{" "}
                                {I18n.get(
                                  "De agora em diante, qualquer adição de escopo será através dos “Extras” ou “Novo Projeto”."
                                )}
                              </p>
                            </S.CloseScope>
                          )}

                        {auth?.is_partner && (
                          <>
                            {project?.partnerHasSubmittedDefinitions && (
                              <>
                                <S.Paragraph>
                                  {I18n.get(
                                    "Parabéns! O escopo final do seu projeto foi submetido."
                                  )}
                                </S.Paragraph>
                                <S.Paragraph>
                                  {I18n.get(
                                    "Se estiver tudo OK, o seu faturamento será liberado quando recebermos a aprovação do cliente."
                                  )}
                                </S.Paragraph>
                                <br />
                              </>
                            )}
                            <S.Paragraph>
                              {I18n.get(
                                "Nessa etapa a revisão detalhada do briefing do cliente, esclarecimentos e ajustes deverão ter sido feitos, bem como a proposta de milestones do projeto. Você deve agora revisar o projeto e submeter o escopo final para aprovação do cliente. Caso precise editar o pacote de assets baseado no entendimento mais profundo do briefing e conversas com o cliente, isso pode ser feito abaixo."
                              )}
                            </S.Paragraph>
                          </>
                        )}

                        {/* MENSAGENS PROCUREMENT */}

                        {projectProcurement &&
                          projectProcurement?.isRequiredAprovation &&
                          !projectProcurement.isApproved &&
                          auth.is_client_user && (
                            <Row>
                              <Col sm={24}>
                                <p className="alertIsRequiredAprovation">
                                  {I18n.get(
                                    "O escopo está sendo ajustado pelo parceiro e ainda precisará ser aprovado pelo time de procurement. Assim que aprovado você será notificado."
                                  )}
                                </p>
                              </Col>
                            </Row>
                          )}

                        {projectProcurement?.isRequiredAprovation &&
                          projectProcurement?.isApproved &&
                          auth.is_client_user &&
                          !projectProcurement?.clientTermsUpdatedOn &&
                          project?.partnerHasSubmittedDefinitions &&
                          project?.status < 6 && (
                            <Row>
                              <Col sm={24}>
                                <p className="alertIsRequiredAprovation">
                                  {I18n.get(
                                    "O escopo foi revisado e aprovado pelo time de procurement. Por favor, revise as informaçoes de briefing e milestones para aprovar e começar o projeto."
                                  )}
                                </p>
                              </Col>
                            </Row>
                          )}

                        {projectProcurement?.isRequiredAprovation &&
                          projectProcurement?.isApproved &&
                          auth?.is_client && (
                            <Row>
                              <Col sm={24}>
                                <p className="alertIsRequiredAprovation">
                                  {I18n.get(
                                    "Você aprovou este escopo! Agora o responsável pelo projeto irá avaliar o briefing para aprovar o escopo final."
                                  )}
                                </p>
                              </Col>
                            </Row>
                          )}

                        {/* isReEvaluate */}
                        {projectProcurement &&
                          projectProcurement.isRequiredAprovation &&
                          projectProcurement.isApproved &&
                          !projectProcurement.isReEvaluate &&
                          !project?.partnerHasSubmittedDefinitions &&
                          auth?.is_client_user && (
                            <Row>
                              <Col sm={24}>
                                <p className="alertIsRequiredAprovation">
                                  {I18n.get(
                                    "Você reprovou este escopo. O parceiro irá revisar de acordo com os seus comentários e enviar novamente para sua aprovação."
                                  )}
                                </p>
                              </Col>
                            </Row>
                          )}
                      </>
                    ) : (
                      <div />
                    )}
                  </S.TopHeader>
                </Col>
              </Row>

              <Row>
                <Col sm={24}>
                  <S.ContentReview>
                    <Row>
                      <Col sm={12} xs={12}>
                        <Informations
                          isLoading={isLoading}
                          data={contactInformation}
                        />
                      </Col>
                      <Col sm={12} xs={12}>
                        <GeneralDemand
                          isLoading={isLoading}
                          data={generalDemand}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} xs={12} style={{ marginTop: 30 }}>
                        <CreativeScope
                          isLoading={false}
                          listOfAssets={listOfAssets}
                          dataValues={dataValuesCreativeScope}
                          isInvite={false}
                          convertedValues={handleConvertedValuesCreativeScope}
                          reload={loadReview}
                          data={creativeScope}
                          descriptiveHourMan={project?.descriptiveHourMan}
                          disabledEdit={
                            project?.partnerHasSubmittedDefinitions &&
                            project?.status != 6 &&
                            !projectProcurement?.isRequiredAprovation
                              ? true
                              : false
                          }
                          currencyProject={currencyProject}
                          isCart
                          isModalConfirm={true}
                          isClient={auth?.is_client_or_client_user}
                          showTooltipOtherTax
                        />
                        {project?.pdfProject && project?.status >= 6 && (
                          <div style={{ marginTop: 30 }}>
                            <a href={project?.pdfProject} target="_blank">
                              <ButtonBpool
                                text={I18n.get("Download PDF")}
                                className=""
                                theme="primary"
                              />
                            </a>
                          </div>
                        )}
                      </Col>
                      <Col sm={12} xs={12} style={{ marginTop: 30 }}>
                        <AboutPartner
                          isLoading={isLoading}
                          data={aboutPartner}
                        />

                        <Details
                          isLoading={isLoading}
                          data={details}
                          style={{ marginTop: 30 }}
                        />
                      </Col>
                    </Row>
                    {!isLoading ? (
                      <Row>
                        <Col xs={12} sm={12}>
                          <S.WrapperReview>
                            <S.TitleReview>
                              {I18n.get("Observações importantes do Parceiro")}
                            </S.TitleReview>
                            <S.BoxReview>
                              <S.NotesPartnerOrClient>
                                <p>
                                  {I18n.get(
                                    auth?.is_partner
                                      ? "Aqui você pode adicionar observações importantes para o cliente."
                                      : "Aqui você pode visualizar as observações importantes para o cliente."
                                  )}
                                </p>
                                {auth?.is_partner ? (
                                  <>
                                    {sendingNotes ? (
                                      <S.ContentLoadingSending>
                                        <Loading
                                          text={I18n.get(
                                            "Enviando comentário..."
                                          )}
                                          sizeText={14}
                                          sizeIcon={16}
                                          align="start"
                                        />
                                      </S.ContentLoadingSending>
                                    ) : (
                                      <Textarea
                                        label={I18n.get(
                                          "Observações importantes do Parceiro"
                                        )}
                                        rows={4}
                                        defaultValue={notes}
                                        onChange={(value) => setNotes(value)}
                                        // required
                                        // disabled={isClient}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <S.NotesView>
                                    <p>{project?.partnerNotes}</p>
                                  </S.NotesView>
                                )}
                              </S.NotesPartnerOrClient>
                            </S.BoxReview>
                          </S.WrapperReview>
                        </Col>
                        <Col xs={12} sm={12}>
                          <S.WrapperReview>
                            <S.TitleReview>
                              {I18n.get("Observações importantes do Cliente")}
                            </S.TitleReview>
                            <S.BoxReview>
                              <S.NotesPartnerOrClient>
                                <p>
                                  {I18n.get(
                                    !auth?.is_partner
                                      ? "Aqui você pode adicionar observações importantes para o parceiro."
                                      : "Aqui você pode visualizar as observações importantes para o parceiro."
                                  )}
                                </p>
                                {!auth?.is_partner ? (
                                  <>
                                    {sendingNotes ? (
                                      <S.ContentLoadingSending>
                                        <Loading
                                          text={I18n.get(
                                            "Enviando comentário..."
                                          )}
                                          sizeText={14}
                                          sizeIcon={16}
                                          align="start"
                                        />
                                      </S.ContentLoadingSending>
                                    ) : (
                                      <Textarea
                                        label={I18n.get(
                                          "Observações importantes do Cliente"
                                        )}
                                        rows={4}
                                        defaultValue={notes}
                                        onChange={(value) => setNotes(value)}
                                        // required
                                        // disabled={isClient}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <S.NotesView>
                                    <p>{project?.clientNotes}</p>
                                  </S.NotesView>
                                )}
                              </S.NotesPartnerOrClient>
                            </S.BoxReview>
                          </S.WrapperReview>
                        </Col>
                      </Row>
                    ) : (
                      <div />
                    )}
                  </S.ContentReview>
                </Col>
              </Row>

              {auth?.is_client_or_client_user &&
              project?.productRequiredGlobalAuthorization ? (
                <Row>
                  <Col xs={24} sm={24}>
                    <S.WrapperReview>
                      <S.BoxReview style={{ padding: 0 }}>
                        <GlobalFileBox
                          title={I18n.get("Atenção!")}
                          description={I18n.get(
                            "Esta é uma marca Global. Anexe aqui a aprovação de global para este projeto."
                          )}
                          username={state?.auth?.username}
                          action={(data) => saveGlobal(data)}
                          file={project?.globalAuthorization}
                        />
                      </S.BoxReview>
                    </S.WrapperReview>
                  </Col>
                </Row>
              ) : null}

              <Row style={{ marginBottom: 100 }}>
                <Col sm={24}>
                  <S.FooterMain>
                    {Object.keys(projectErrors).length > 0 && (
                      <Row>
                        <Col lg={24}>
                          {Object.keys(projectErrors).map((item, index) => {
                            let stepErrorItem = getStepError(
                              projectErrors[item]?.step
                            );

                            return (
                              <p>
                                <Link to={stepErrorItem?.url}>
                                  {" "}
                                  <span class="icon has-text-danger">
                                    <i class="fas fa-exclamation"></i>
                                  </span>
                                  {projectErrors[item].errorMessage} [
                                  {I18n.get("Editar")}]
                                </Link>
                              </p>
                            );
                          })}
                        </Col>
                      </Row>
                    )}

                    {auth?.is_partner && (
                      <S.RowFooter>
                        <S.ButtonsFooter></S.ButtonsFooter>
                        <S.ButtonsFooter>
                          <Link
                            to={`/${
                              auth?.is_partner ? "parceiro" : "cliente"
                            }/projetos/v2/${projectId}/briefing`}
                          >
                            <ButtonBpool
                              text={I18n.get("Back")}
                              theme="secondary"
                              className="btnPrev"
                              onClick={() => true}
                            />
                          </Link>

                          <ButtonBpool
                            text={I18n.get(
                              "Submeter o escopo final do projeto"
                            )}
                            theme="primary"
                            onClick={() => partnerSend()}
                            loading={loadSend}
                            disabled={disabledSubmit}
                          />
                        </S.ButtonsFooter>
                      </S.RowFooter>
                    )}

                    {auth?.is_client_or_client_user && (
                      <S.RowFooter>
                        <S.ButtonsFooter>
                          <Link
                            to={`/${
                              auth?.is_partner ? "parceiro" : "cliente"
                            }/projetos/v2/${projectId}/briefing`}
                          >
                            <ButtonBpool
                              text={I18n.get("Back")}
                              theme="secondary"
                              className="btnPrev"
                              onClick={() => true}
                            />
                          </Link>
                        </S.ButtonsFooter>

                        {(projectProcurement?.isRequiredAprovation &&
                          projectProcurement?.isApproved) ||
                        !projectProcurement?.isRequiredAprovation ? (
                          <>
                            {project?.status != 6 && (
                              <S.ButtonsFooter>
                                <ButtonBpool
                                  text={I18n.get("Declinar")}
                                  theme="danger"
                                  className="btnPrev"
                                  onClick={() => setVisibleDecline(true)}
                                  disabled={
                                    !project?.partnerHasSubmittedDefinitions
                                  }
                                />
                                <ButtonBpool
                                  text={I18n.get("Aprovar escopo")}
                                  theme="primary"
                                  className="btnPrev"
                                  onClick={() => setVisibleApprove(true)}
                                  disabled={
                                    !project?.partnerHasSubmittedDefinitions
                                  }
                                />
                              </S.ButtonsFooter>
                            )}
                          </>
                        ) : null}

                        {auth?.is_client &&
                          project?.partnerHasSubmittedDefinitions &&
                          projectProcurement?.isRequiredAprovation &&
                          !projectProcurement?.isApproved && (
                            <S.ButtonsFooter>
                              <ButtonBpool
                                text={I18n.get("Declinar procurement")}
                                theme="danger"
                                className="btnPrev"
                                // loading={isSending}
                                onClick={() =>
                                  handleThreasholdAuthorization(false)
                                }
                              />

                              <ButtonBpool
                                text={I18n.get("Aprovar procurement")}
                                theme="primary"
                                // loading={isSending}
                                onClick={() =>
                                  handleThreasholdAuthorization(true)
                                }
                              />
                            </S.ButtonsFooter>
                          )}

                        {project?.status == 6 && (
                          <S.ButtonsFooter>
                            <Link
                              to={`/${
                                auth?.is_partner ? "parceiro" : "cliente"
                              }/projetos/v2/${projectId}/faturamento`}
                            >
                              <ButtonBpool
                                text={I18n.get("Faturamento")}
                                theme="primary"
                                onClick={() => true}
                              />
                            </Link>
                          </S.ButtonsFooter>
                        )}
                      </S.RowFooter>
                    )}
                  </S.FooterMain>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Content>

      <ModalBp
        visible={successApprove}
        bodyText={I18n.get("Escopo aprovado com sucesso!")}
        okText={I18n.get("Ir para faturamento")}
        handleClickOk={() =>
          history.push(`/cliente/projetos/v2/${params.projectId}/faturamento`)
        }
        // isDanger={true}
      />

      <ModalBp
        visible={successDecline}
        bodyText={I18n.get("Escopo reprovado com sucesso!")}
        subBodyText={I18n.get("Aguarde as alterações do parceiro.")}
        okText={I18n.get("ok")}
        handleClickOk={() => loadReview()}
        // isDanger={true}
      />

      <ModalBp
        visible={visibleInclude}
        bodyText={I18n.get("Item Incluído")}
        okText={I18n.get("Confirmar")}
        handleClickOk={() => setVisibleInclude(false)}
        // isDanger={true}
      />

      <ModalBp
        visible={visibleExclude}
        bodyText={I18n.get("Item Apagado")}
        okText={I18n.get("Confirmar")}
        handleClickOk={() => setVisibleExclude(false)}
        // isDanger={true}
      />

      <ModalBp
        visible={visibleSend}
        bodyText={I18n.get("Submetido com sucesso!")}
        subBodyText={I18n.get(
          "O Cliente vai avaliar as informações, aprovar ou reprovar o escopo!"
        )}
        okText={I18n.get("OK!")}
        handleClickOk={() => history.push("/parceiro/projetos/v2")}
        // isDanger={true}
      />

      <ModalBp
        visible={visibleApprove}
        bodyText={I18n.get("Deseja aprovar o escopo do projeto?")}
        okText={I18n.get("Aprovar")}
        cancelText={I18n.get("Cancelar")}
        onCancel={() => {
          setVisibleApprove(false);
        }}
        handleClickOk={() => {
          handleAproveOrDecline(true);
        }}
        typeModal="isConfirm"
        isLoading={loadSend}
      >
        <p>
          {I18n.get(
            "DECLARO que aceito integralmente os termos do Projeto definido junto à empresa"
          )}{" "}
          {state.client?.company?.registrationName}{" "}
          {I18n.get(
            ", cujos termos negociados encontram-se nesta aba de “Review”"
          )}
          `;
        </p>
        <p>
          {I18n.get(
            "Ao confirmar o projeto passará para o status EM ANDAMENTO, e o parceiro dará início ao projeto. Deseja continuar?"
          )}
        </p>
      </ModalBp>

      <ModalBp
        visible={visibleDecline}
        bodyText={I18n.get("Deseja reprovar o escopo do projeto?")}
        subBodyText={I18n.get(
          "Ao confirmar o parceiro será notificado para fazer os ajustes necessarios, deseja continuar?"
        )}
        okText={I18n.get("Reprovar")}
        cancelText={I18n.get("Cancelar")}
        onCancel={() => {
          setVisibleDecline(false);
        }}
        handleClickOk={() => {
          handleAproveOrDecline(false);
        }}
        typeModal="isConfirm"
        isDanger={true}
        isLoading={loadSend}
      />

      <ModalBp
        visible={visibleDelete}
        bodyText={I18n.get("Apagar item selecionado?")}
        okText={I18n.get("Apagar")}
        cancelText={I18n.get("Cancelar")}
        onCancel={() => {
          setVisibleDelete(false);
        }}
        handleClickOk={() => {
          handleDeleteItem(codeDelete);
          setVisibleDelete(false);
        }}
        typeModal="isConfirm"
        isDanger={true}
      />
    </LoggedPage>
  );
};
