import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { formatMoney2 } from '../../../../utils/helpers'
import {
    Form,
    Select,
    Button,
    Input,
    InputNumber,
    Row,
    Tooltip,
    Col,
    Icon
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;


const PriceText = styled.p`
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: bold;
`;

const ResponseBox = styled.div`
   h3 {
       margin-bottom: 20px;
   }

   p {
       margin-bottom: 20px;

       a {
           text-decoration: underline;
       }
   }
`;

const RowItem = styled(Form.Item)`
    position: relative;
    top: -8px;
`;

class FormCreateAsset extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formState: {},
            priceValue: 'R$ 0',
            textDescription: 0,
            viewSuccess: false,
            sendAsset: false
        };
    }

    onChange = value => {
        setTimeout(this.setState({ priceValue: formatMoney2(value) }), 1);
    };

    onChangeTxt = value => {
        setTimeout(this.setState({ textDescription: value.length }), 1);
    };


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                const data = {
                    partnerId: this.props.partnerId,
                    ...values
                }

                this.setState({
                    formState: values,
                    sendAsset: true
                })

                this.props.create(data);
            }
        });
    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.formErrors != prevProps.formErrors) {
            Object.keys(this.props.formErrors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.formErrors[field].errorMessage)],
                    },
                });
            });
        };

        if (this.props.partnerAssets != prevProps.partnerAssets && this.state.sendAsset) {
            this.props.form.resetFields();
            this.setState({
                viewSuccess: true
            });
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            assetTypes,
            assetSpecialisms,
            assetComplexity
        } = this.props;

        return (
            <Fragment>
                {this.state.viewSuccess &&
                    <ResponseBox>
                        <h3>{I18n.get("Recebemos a sua sugestão de asset com sucesso!")}</h3>
                        <p>{I18n.get("Nossa equipe irá analizar e entrará em contato em até 24hs. Se você quiser criar um novo projeto usando itens já existentes no asset list da BPool,")} <Link to="/parceiro/criar-projeto"> {I18n.get("clique aqui")} </Link>. {I18n.get("Caso queira usar o asset que acabou de criar, por favor, aguarde a aprovação do item.")}</p>
                        <p><Button type="primary" onClick={() => this.setState({ viewSuccess: false, sendAsset: false })}>{I18n.get("Sugerir novo asset")}</Button></p>
                    </ResponseBox>
                }
                {!this.state.viewSuccess &&
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col sm={16}>
                                <Form.Item label={I18n.get("Título")}>
                                    <div></div>
                                    {getFieldDecorator('title', {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col sm={8}>
                                <RowItem>

                                    <div className="ant-col ant-form-item-label">
                                        <label className="">{I18n.get("Preço sugerido")}</label>
                                        <Tooltip title={I18n.get("O preço sugerido refere-se ao desembolso do cliente. Lembre-se de contemplar o fee da plataforma.")}>
                                            <Icon type="question-circle" theme="filled" />
                                        </Tooltip>
                                    </div>
                                    {getFieldDecorator('price', {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    })(
                                        <InputNumber
                                            decimalSeparator=","
                                        />
                                    )}

                                </RowItem>

                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <Form.Item label={I18n.get("Descrição Completa")}>
                                    {getFieldDecorator('description', {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    })(
                                        <TextArea
                                            rows={4}
                                            onChange={e => this.onChangeTxt(e.target.value)}
                                        />
                                    )}
                                    <PriceText>{this.state.textDescription} {I18n.get("/ mínimo 250")}</PriceText>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <Form.Item label={I18n.get("Complexidade do asset")}>
                                    {getFieldDecorator('complexity', {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    })(
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder={I18n.get("Selecione a complexidade")}
                                        >
                                            {assetComplexity && assetComplexity.map((item, index) => <Option key={index} value={item.code}>{item.label}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <Form.Item label={I18n.get("Especialidades")}>
                                    {getFieldDecorator('assetSpecialisms', {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    })(
                                        <Select
                                            style={{ width: "100%" }}
                                            mode="multiple"
                                            placeholder={I18n.get("Selecione as especialidades")}
                                        >
                                            {assetSpecialisms && assetSpecialisms.map((item, index) => <Option key={index} value={item.code}>{item.label}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <Form.Item label={I18n.get("Tipo")}>
                                    {getFieldDecorator('assetTypes', {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    })(
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder={I18n.get("Selecione o tipo")}
                                            mode="multiple"
                                        >

                                            {assetTypes && assetTypes.map((item, index) => <Option key={index} value={item.code}>{item.label}</Option>)}

                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <Form.Item label={I18n.get("Prazo (em dias)")}>
                                    {getFieldDecorator('durationDays', {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    })(
                                        <InputNumber min={1} suffix="DIAS" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <Button className="button bt-bpool black" type="primary" htmlType="submit" shape="round" size="large">{I18n.get("Enviar")}</Button>
                            </Col>
                        </Row>
                    </Form>
                }
            </Fragment>
        );
    }
}

const CreateAsset = Form.create({})(FormCreateAsset);

export default CreateAsset;
