import React, { Fragment } from "react";
import { I18n } from "aws-amplify";
import moment from "moment";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { Excel } from "antd-table-saveas-excel";
import {
  Row,
  Col,
  Card,
  Icon,
  Table,
  DatePicker,
  Drawer,
  Skeleton,
  Button,
} from "antd";
import { Redirect } from "react-router-dom";

import DataSet from "@antv/data-set";
import { Chart, Geom, Axis, Tooltip, Legend, Label } from "bizcharts";

import { Task } from "../../../utils/task";
import logo from "../../../image/logoWOtextb.svg";

import {
  formatCurrency,
  formatMoney,
  formatMoneyGraph,
} from "../../../utils/helpers";

import { REPORT_API } from "../../../actions/report/actions";

import { TableProject } from "./TableProjects";

import styled from "styled-components";

const { RangePicker } = DatePicker;

const TableCard = styled(Table)`
  margin-top: 14px;

  table {
    border: solid 1px #ccc !important;

    tr {
      cursor: pointer;
    }

    tr:nth-child(odd) td {
      background: #f2f2f2;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 6px 16px;
  }
`;

const DrawerBp = styled(Drawer)`
  top: 60px;

  h2 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;
    position: relative;

    &::after {
      width: 60px;
      content: "";
      border-bottom: solid 5px #000;
      display: block;
      position: absolute;
      bottom: -12px;
      left: 0;
    }
  }

  .description {
    font-size: 13px;
    line-height: 20px;
  }

  .total {
    margin: 30px 0;
    small {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .boxtotal {
      font-size: 22px;
      color: #fff;
      background: #000;
      padding: 5px 10px;
      strong {
        color: #fff;
      }
    }
  }

  .TableBox {
    margin-top: 50px;
  }
`;

const CardDisplay = styled(Card)`
  margin-bottom: 20px;
  height: 100%;

  .DescriptionPage {
    font-size: 14px;
  }

  .LabelPeriodo {
    font-size: 12px;
    font-weight: bold;
  }

  &.TableBox {
    min-height: 335px;
  }

  .GraphCard {
    opacity: 1;
    transition: opacity 2s ease;
  }

  .hidden {
    opacity: 0;
  }

  .show {
    opacity: 1;
  }

  .LabelCard {
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: -10px;
  }

  h2 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-left: 15px;
    border-left: solid 5px #000;
    span {
      display: block;
      color: #999;
      font-size: 11px;
    }

    &.title-span {
      line-height: 18px;
    }
  }

  .NumberCard {
    display: block;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
  }

  .DescriptionCard {
    span {
      font-weight: bold;
    }
    border-top: solid 1px #b5b5b5;
    padding-top: 10px;
    margin-top: 15px;
    font-size: 12px;
  }

  .BtnCard {
    border-top: solid 1px #ccc;
    padding-top: 15px;
    margin-top: 10px;
    display: none;
  }

  .LoadingCard {
    text-align: center;
    margin: 36px 0 33px;

    .noDate {
      display: block;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }

    i {
      font-size: 50px;
      opacity: 0.4;
    }

    &.LabelG {
      position: absolute;
      top: 38%;
      left: 0;
      width: 100%;

      &.noDateView {
        top: 32%;
      }
    }
  }
`;

const Miolo = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: calc(100% - 48px);
  font-size: 20px;

  h3 {
    font-size: 18px;
  }
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    api_get_historic_item_dash: (data) => {
      dispatch(REPORT_API.get_historic_item(data));
    },

    delete_itens_dash: (data) => {
      dispatch(REPORT_API.delete_itens());
    },

    reset_itens_dash: (data) => {
      dispatch(REPORT_API.reset_itens());
    },
    get_extras_and_project: (data) => {
      dispatch(REPORT_API.get_extras_and_project(data));
    },
    get_projectextras_item: (data) => {
      dispatch(REPORT_API.get_projectextras_item(data));
    },
    get_dashboard_infos: (data) => {
      return dispatch(REPORT_API.get_dashboard_infos(data));
    },
  };
};

const BRL = ({ amount }) => (
  <NumberFormat
    value={amount}
    displayType={"text"}
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale={true}
  />
);

const visitData = [];
const beginDay = new Date().getTime();
for (let i = 0; i < 20; i += 1) {
  visitData.push({
    x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    y: Math.floor(Math.random() * 100) + 10,
  });
}

function compareByAlph(a, b) {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
}

const DataRanger = new Date();
const currentDate = moment(DataRanger).format("YYYY-MM-DD");
const dayDate = moment(DataRanger).add(-2, "M").format("YYYY-MM-DD");

class ContainerClient extends React.Component {
  constructor(props) {
    super(props);

    this.partner_chart = React.createRef();

    this.state = {
      fromDate: dayDate,
      toDate: currentDate,
      visible: false,
      redirectProject: false,
      redirectId: "",
      url: "",
      checkedValues: "7",
      drawerInfos: null,
      dataTable: null,
    };
  }
  renderDataTable(obj) {
    const newObj = obj.map((item) => {
      return {
        ...item,
        extra: "atualizado",
        totalSum: item.billingAmountClient,
        billingAmount: this.props.auth.is_client
          ? item.billingAmountClientFmt
          : item?.billingAmountPartnerFmt,
        clientOrPartner: this.props.auth.is_client
          ? item.partnerName
          : item.projectClientName,
      };
    });

    return newObj;
  }

  showDrawer = async (infos, billingtype, projectType, isMonth, nameFile) => {
    const {
      _origin: { code, year, period, month, brandId, partnerId, clientId },
    } = infos;

    this.setState({
      visible: true,
    });

    const dataDash = {
      isMonth: isMonth,
      billingtype: billingtype,
      projectType: projectType,
      year,
      month,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      prefix: this.props.auth.is_client ? "client" : "partner",
    };

    const resp = await this.props.get_dashboard_infos(dataDash);
    const objMap = await this.renderDataTable(resp.data?.installments || []);

    const infosDrawer = {
      name: nameFile,
      description: "",
      total: this.props.auth.is_client
        ? resp.data.totalBillingAmountClient
        : resp.data.totalBillingAmountPartner,
    };

    this.setState({
      drawerInfos: infosDrawer,
      dataTable: objMap,
    });
  };

  onClose = () => {
    this.setState({
      drawerInfos: null,
      dataTable: null,
      visible: false,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState) return;
    if (!prevProps) return;

    if (this.props.report && this.props.report.task != prevState.task) {
      this.setState({ task: this.props.report.task });
    }
  }

  getApi(data) {
    this.props.get_extras_and_project(data);

    var task = new Task();
  }

  getApiFilter(data) {
    this.props.get_extras_and_project(data);
  }

  componentDidMount() {
    let data = {
      prefix: this.props.prefix,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      idGet: this.props.idGet,
      projectType: "0",
      status: "7",
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.getApi(data);
    });
  }

  componentWillUnmount() {
    this.props.reset_itens_dash();
  }

  onChangeDatas(dateStrings) {
    let dataFrom = moment(dateStrings[0]).format("YYYY-MM-DD");
    let dataTo = moment(dateStrings[1]).format("YYYY-MM-DD");

    this.setState({
      fromDate: dataFrom,
      toDate: dataTo,
    });
  }

  onChangeStatus(checkedValues) {
    this.setState({
      checkedValues: checkedValues,
    });
  }

  onFilter() {
    let dataChange = {
      prefix: this.props.prefix,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      idGet: this.props.idGet,
      projectType: "0",
      status: this.state.checkedValues,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.getApiFilter(dataChange);
    });
  }

  renderCallBack(arr) {
    if (arr == undefined) {
      return (
        <p className="LoadingCard LabelG">
          <Icon type="loading" />
        </p>
      );
    } else if (arr && arr.length == 0) {
      return (
        <p className="LoadingCard LabelG noDateView">
          <Icon type="inbox" />
          <span className="noDate">{I18n.get("Sem informações")}</span>
        </p>
      );
    }
  }

  render() {
    const {
      projects = [],
      specs = [],
      newDash,
    } = this.props.report;
    const { redirectProject, redirectId, url } = this.state;

    const { DataView } = DataSet;

    let valor = 0;

    const columnsModal = [
      {
        title: I18n.get("Projeto"),
        dataIndex: "projectName",
        sorter: (a, b) => compareByAlph(a.projectName, b.projectName),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: I18n.get("Data Faturamento"),
        dataIndex: "installmentCreatedOn",
      },
      {
        title: I18n.get("Tipo"),
        dataIndex: "billingType",
      },
      {
        title: I18n.get("Tipo Projeto"),
        dataIndex: "projectTypeName",
      },
      {
        title: I18n.get("Subtipo"),
        dataIndex: "projectProposalTypeName",
      },
      {
        title: I18n.get("Tipo Extra"),
        dataIndex: "extraTypeName",
      },

      {
        title: I18n.get("Marca"),
        dataIndex: "projectBrandName",
        sorter: (a, b) => compareByAlph(a.projectBrandName, b.projectBrandName),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: I18n.get("Marca / Produto"),
        dataIndex: "projectProductName",
        sorter: (a, b) =>
          compareByAlph(a.projectProductName, b.projectProductName),
        sortDirections: ["descend", "ascend"],
      },
      {
        title:
          this.props?.auth && this.props.auth.is_client
            ? I18n.get("Parceiro")
            : I18n.get("Cliente"),
        dataIndex: "clientOrPartner",
        sorter: (a, b) => compareByAlph(a.clientOrPartner, b.clientOrPartner),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: I18n.get("Valor"),
        dataIndex: "billingAmount",
        sorter: (a, b) => compareByAlph(a.billingAmount, b.billingAmount),
        sortDirections: ["descend", "ascend"],
        with: 300,
      },
    ];

    const data =
      projects &&
      projects.map((i) => {
        valor = valor + i.price;

        return {
          name: i.name,
          total: i.price,
          projectId: i.projectId,
        };
      });

    let dataEspecs =
      specs &&
      specs.map((i) => {
        return {
          name: i.name,
          total: i.total,
          code: i.code,
        };
      });

    const handleClick = () => {
      const excel = new Excel();
      excel
        .addSheet("test")
        .addColumns(columnsModal)
        .addDataSource(this.state.dataTable, {
          str2Percent: true,
        })
        .saveAs(`${this.state.drawerInfos?.name}.xlsx`);
    };

    const dataGeneralMonth =
      newDash &&
      newDash?.totalGeneralByMonth &&
      newDash?.totalGeneralByMonth.map((i) => {
        return {
          name: i.monthText.slice(0, 3) + " / " + i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataGeneralYear =
      newDash &&
      newDash?.totalGeneralByYear &&
      newDash?.totalGeneralByYear.map((i) => {
        return {
          name: i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataMktGeralMonth =
      newDash &&
      newDash?.totalMainProjectAndExtrasMktByMonth &&
      newDash?.totalMainProjectAndExtrasMktByMonth.map((i) => {
        return {
          name: i.monthText.slice(0, 3) + " / " + i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataMktGeralYear =
      newDash &&
      newDash?.totalMainProjectAndExtrasMktByYear &&
      newDash?.totalMainProjectAndExtrasMktByYear.map((i) => {
        return {
          name: i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataMktProjectsMonth =
      newDash &&
      newDash?.totalMainProjectMktByMonth &&
      newDash?.totalMainProjectMktByMonth.map((i) => {
        return {
          name: i.monthText.slice(0, 3) + " / " + i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataMktProjectsYear =
      newDash &&
      newDash?.totalMainProjectMktByYear &&
      newDash?.totalMainProjectMktByYear.map((i) => {
        return {
          name: i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataMktExtrasMonth =
      newDash &&
      newDash?.totalExtrasMktByMonth &&
      newDash?.totalExtrasMktByMonth.map((i) => {
        return {
          name: i.monthText.slice(0, 3) + " / " + i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataMktExtrasYear =
      newDash &&
      newDash?.totalExtraMktByYear &&
      newDash?.totalExtraMktByYear.map((i) => {
        return {
          name: i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataProposalGeralMonth =
      newDash &&
      newDash?.totalMainProjectAndExtrasProposalByMonth &&
      newDash?.totalMainProjectAndExtrasProposalByMonth.map((i) => {
        return {
          name: i.monthText.slice(0, 3) + " / " + i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataProposalGeralYear =
      newDash &&
      newDash?.totalMainProjectAndExtrasProposalByYear &&
      newDash?.totalMainProjectAndExtrasProposalByYear.map((i) => {
        return {
          name: i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataProposalProjectsMonth =
      newDash &&
      newDash?.totalMainProjectProposalByMonth &&
      newDash?.totalMainProjectProposalByMonth.map((i) => {
        return {
          name: i.monthText.slice(0, 3) + " / " + i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataProposalProjectsYear =
      newDash &&
      newDash?.totalMainProjectProposalByYear &&
      newDash?.totalMainProjectProposalByYear.map((i) => {
        return {
          name: i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataProposalExtrasMonth =
      newDash &&
      newDash?.totalExtrasProposalByMonth &&
      newDash?.totalExtrasProposalByMonth.map((i) => {
        return {
          name: i.monthText.slice(0, 3) + " / " + i.year.toString(),
          total: i.total,
          year: i.year.toString(),
          month: i.month,
        };
      });

    const dataProposalExtrasYear =
      (newDash &&
        newDash?.totalExtraProposalByYear &&
        newDash?.totalExtraProposalByYear.map((i) => {
          return {
            name: i.year.toString(),
            total: i.total,
            year: i.year.toString(),
            month: i.month,
          };
        })) ||
      [];

    const dataBpayMonth =
      (newDash &&
        newDash?.totalBPayByMonth &&
        newDash?.totalBPayByMonth.map((i) => {
          return {
            name: i.monthText.slice(0, 3) + " / " + i.year.toString(),
            total: i.total,
            year: i.year.toString(),
            month: i.month,
          };
        })) ||
      [];

    const dataBpayYear =
      (newDash &&
        newDash?.totalBPayByYear &&
        newDash?.totalBPayByYear.map((i) => {
          return {
            name: i.year.toString(),
            total: i.total,
            year: i.year.toString(),
            month: i.month,
          };
        })) ||
      [];


    const dv = new DataView();

    if (data) {
      dv.source(data).transform({
        type: "percent",
        field: "count",
        dimension: "item",
        as: "percent",
      });
    }

    const cols = {
      percent: {
        formatter: (val) => {
          val = val * 100 + "%";
          return val;
        },
      },
    };

    if (redirectProject) {
      if (url) {
        return <Redirect to={url} />;
      }
      return <Redirect to={`/projetos/${redirectId}/resumo`} />;
    }

    return (
      <Fragment>
        <Row>
          <Col md={24}>
            <CardDisplay className="noPrint">
              <Row>
                <Col md={3}>
                  <p className="LabelPeriodo">&nbsp;</p>
                  <h1>{I18n.get("Filtrar")}</h1>
                  {/* <p className="DescriptionPage">{I18n.get("Informações dos seus projetos e marcas")}</p> */}
                </Col>

                <Col md={6}>
                  <p className="LabelPeriodo">
                    {I18n.get("Selecione o período")}
                  </p>
                  <RangePicker
                    defaultValue={[moment(dayDate), moment(currentDate)]}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    onChange={this.onChangeDatas.bind(this)}
                    format="DD/MM/YYYY"
                  />
                </Col>
                <Col md={3}>
                  <p className="LabelPeriodo">&nbsp;</p>
                  <Button
                    block
                    disabled={
                      !this.state.checkedValues ||
                      this.state.checkedValues.length == 0
                    }
                    onClick={() => this.onFilter()}
                    type="primary"
                    loading={this.state.task && this.state.task.busy}
                  >
                    {this.state.task &&
                      !this.state.task.busy &&
                      I18n.get("Filtrar")}
                  </Button>
                </Col>
                <Col md={12}>
                  <div className="pull-right" style={{ paddingTop: "20px" }}>
                    <a
                      onClick={() => window.print()}
                      className="button bt-bpool black"
                    >
                      {I18n.get("Baixar PDF")}
                    </a>
                  </div>
                </Col>
              </Row>
            </CardDisplay>
          </Col>
        </Row>

        <Row className="PrintLogoName">
          <Col md={12}>
            <img src={logo} width={120} />
          </Col>
          <Col md={12}>
            <h2>
              {this.props.auth.is_client
                ? this.props?.client?.company?.name
                : this.props?.partner?.company?.name}
            </h2>
          </Col>
        </Row>

        <Row className="PrintLinha1">
          <Col md={14}>
            <CardDisplay>
              <h2>
                {I18n.get("Geral")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataGeneralMonth}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataGeneralMonth && dataGeneralMonth.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(data, 1, null, true, I18n.get("Geral"));
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataGeneralMonth)}
            </CardDisplay>
          </Col>
          <Col md={10}>
            <CardDisplay>
              <h2>
                {I18n.get("Geral")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataGeneralYear}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataGeneralYear && dataGeneralYear.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(data, 1, null, false, I18n.get("Geral"));
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataGeneralYear)}
            </CardDisplay>
          </Col>
        </Row>

        <Row className="PrintLinha2">
          <Col md={14}>
            <CardDisplay>
              <h2>
                {I18n.get("Marketplace")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataMktGeralMonth}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataMktGeralMonth && dataMktGeralMonth.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(data, 1, 0, true, I18n.get("Marketplace"));
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataMktGeralMonth)}
            </CardDisplay>
          </Col>
          <Col md={10}>
            <CardDisplay>
              <h2>
                {I18n.get("Marketplace")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataMktGeralYear}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataMktGeralYear && dataMktGeralYear.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(data, 1, 0, false, I18n.get("Marketplace"));
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataMktGeralYear)}
            </CardDisplay>
          </Col>
        </Row>

        <Row className="PrintLinha3">
          <Col md={14}>
            <CardDisplay>
              <h2>
                {I18n.get("Marketplace | Projetos")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataMktProjectsMonth}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataMktProjectsMonth && dataMktProjectsMonth.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(
                      data,
                      2,
                      0,
                      true,
                      I18n.get("Marketplace | Projetos")
                    );
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataMktProjectsMonth)}
            </CardDisplay>
          </Col>
          <Col md={10}>
            <CardDisplay>
              <h2>
                {I18n.get("Marketplace | Projetos")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataMktProjectsYear}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataMktProjectsYear && dataMktProjectsYear.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(
                      data,
                      2,
                      0,
                      false,
                      I18n.get("Marketplace | Projetos")
                    );
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataMktProjectsYear)}
            </CardDisplay>
          </Col>
        </Row>

        <Row>
          <Col md={14}>
            <CardDisplay>
              <h2>
                {I18n.get("Marketplace | Extras")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataMktExtrasMonth}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataMktExtrasMonth && dataMktExtrasMonth.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(
                      data,
                      3,
                      0,
                      true,
                      I18n.get("Marketplace | Extras")
                    );
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataMktExtrasMonth)}
            </CardDisplay>
          </Col>
          <Col md={10}>
            <CardDisplay>
              <h2>
                {I18n.get("Marketplace | Extras")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataMktExtrasYear}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataMktExtrasYear && dataMktExtrasYear.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(
                      data,
                      3,
                      0,
                      false,
                      I18n.get("Marketplace | Extras")
                    );
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataMktExtrasYear)}
            </CardDisplay>
          </Col>
        </Row>

        <Row className="PrintLinha4">
          <Col md={14}>
            <CardDisplay>
              <h2>
                {I18n.get("Proposals")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataProposalGeralMonth}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataProposalGeralMonth && dataProposalGeralMonth.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(data, 1, 2, true, I18n.get("Proposals"));
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataProposalGeralMonth)}
            </CardDisplay>
          </Col>
          <Col md={10}>
            <CardDisplay>
              <h2>
                {I18n.get("Proposals")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataProposalGeralYear}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataProposalGeralYear && dataProposalGeralYear.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(data, 1, 2, false, I18n.get("Proposals"));
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataProposalGeralYear)}
            </CardDisplay>
          </Col>
        </Row>

        <Row>
          <Col md={14}>
            <CardDisplay>
              <h2>
                {I18n.get("Proposals | Projetos")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataProposalProjectsMonth}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataProposalProjectsMonth &&
                  dataProposalProjectsMonth.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(
                      data,
                      2,
                      2,
                      true,
                      I18n.get("Proposals | Projetos")
                    );
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataProposalProjectsMonth)}
            </CardDisplay>
          </Col>
          <Col md={10}>
            <CardDisplay>
              <h2>
                {I18n.get("Proposals | Projetos")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataProposalProjectsYear}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataProposalProjectsYear &&
                  dataProposalProjectsYear.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(
                      data,
                      2,
                      2,
                      false,
                      I18n.get("Proposals | Projetos")
                    );
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataProposalProjectsYear)}
            </CardDisplay>
          </Col>
        </Row>

        <Row className="PrintLinha4">
          <Col md={14}>
            <CardDisplay>
              <h2>
                {I18n.get("Proposals | Extras")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataProposalExtrasMonth}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataProposalExtrasMonth && dataProposalExtrasMonth.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(
                      data,
                      3,
                      2,
                      true,
                      I18n.get("Proposals | Extras")
                    );
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataProposalExtrasMonth)}
            </CardDisplay>
          </Col>
          <Col md={10}>
            <CardDisplay>
              <h2>
                {I18n.get("Proposals | Extras")}{" "}
                {this.props?.client?.customization?.currency?.symbolOnly && (
                  <>
                    {" "}
                    | {
                      this.props?.client?.customization.currency.symbolOnly
                    }{" "}
                  </>
                )}
                | x 1.000
              </h2>
              <Chart
                height={300}
                data={dataProposalExtrasYear}
                scale={cols}
                forceFit
                className={`GraphCard ${
                  dataProposalExtrasYear && dataProposalExtrasYear.length > 0
                    ? "show"
                    : "hidden"
                }`}
                onPlotClick={(ev) => {
                  if (ev.data) {
                    const { data } = ev;

                    this.showDrawer(
                      data,
                      3,
                      2,
                      false,
                      I18n.get("Proposals | Extras")
                    );
                  }
                }}
              >
                <Axis name="name" />
                <Axis
                  name="total"
                  label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                />
                <Geom
                  type="interval"
                  position="name*total"
                  color="#000"
                  tooltip={[
                    "name*total",
                    (name, total) => {
                      return {
                        // Custom tooltip on the title display and so on.
                        name: "Total:",
                        title: name,
                        value: "R$ " + formatMoneyGraph(total),
                      };
                    },
                  ]}
                >
                  <Label
                    offset={7}
                    content="total"
                    formatter={(total) => {
                      return formatMoneyGraph(total);
                    }}
                  />
                </Geom>
              </Chart>

              {this.renderCallBack(dataProposalExtrasYear)}
            </CardDisplay>
          </Col>
        </Row>
        {this.props.auth.is_client && (
          <Row>
            <Col md={14}>
              <CardDisplay>
                <h2>
                  {I18n.get("BPay")}{" "}
                  {this.props?.client?.customization?.currency?.symbolOnly && (
                    <>
                      {" "}
                      | {
                        this.props?.client?.customization.currency.symbolOnly
                      }{" "}
                    </>
                  )}{" "}
                  | x 1.000
                </h2>
                <Chart
                  height={300}
                  data={dataBpayMonth}
                  scale={cols}
                  forceFit
                  className={`GraphCard ${
                    dataBpayMonth && dataBpayMonth.length > 0
                      ? "show"
                      : "hidden"
                  }`}
                  onPlotClick={(ev) => {
                    if (ev.data) {
                      const { data } = ev;

                      this.showDrawer(data, 1, 999, true);
                    }
                  }}
                >
                  <Axis name="name" />
                  <Axis
                    name="total"
                    label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                  />
                  <Tooltip
                    crosshairs={{
                      type: "y",
                    }}
                  />
                  <Geom
                    type="interval"
                    position="name*total"
                    color="#000"
                    tooltip={[
                      "name*total",
                      (name, total) => {
                        return {
                          // Custom tooltip on the title display and so on.
                          name: "Total:",
                          title: name,
                          value: "R$ " + formatMoneyGraph(total),
                        };
                      },
                    ]}
                  >
                    <Label
                      content="total"
                      formatter={(total) => {
                        return formatMoneyGraph(total);
                      }}
                    />
                  </Geom>
                </Chart>

                {this.renderCallBack(dataBpayMonth)}
              </CardDisplay>
            </Col>
            <Col md={10}>
              <CardDisplay>
                <h2>
                  {I18n.get("BPay")}{" "}
                  {this.props?.client?.customization?.currency?.symbolOnly && (
                    <>
                      {" "}
                      | {this.props?.client?.customization.currency.symbolOnly}
                    </>
                  )}{" "}
                  | x 1.000
                </h2>
                <Chart
                  height={300}
                  data={dataBpayYear}
                  scale={cols}
                  forceFit
                  className={`GraphCard ${
                    dataBpayYear && dataBpayYear.length > 0 ? "show" : "hidden"
                  }`}
                  onPlotClick={(ev) => {
                    if (ev.data) {
                      const { data } = ev;

                      this.showDrawer(data, 1, 999, false);
                    }
                  }}
                >
                  <Axis name="name" />
                  <Axis
                    name="total"
                    label={{ formatter: (val) => `${formatMoneyGraph(val)}` }}
                  />
                  <Tooltip
                    crosshairs={{
                      type: "y",
                    }}
                  />
                  <Geom
                    type="interval"
                    position="name*total"
                    color="#000"
                    tooltip={[
                      "name*total",
                      (name, total) => {
                        return {
                          // Custom tooltip on the title display and so on.
                          name: "Total:",
                          title: name,
                          value: "R$ " + formatMoneyGraph(total),
                        };
                      },
                    ]}
                  >
                    <Label
                      content="total"
                      formatter={(total) => {
                        return formatMoneyGraph(total);
                      }}
                    />
                  </Geom>
                </Chart>

                {this.renderCallBack(dataBpayYear)}
              </CardDisplay>
            </Col>
          </Row>
        )}
        <Row className="noPrint">
          <Col md={224}>
            <CardDisplay>
              <h2>
                {I18n.get("Projetos")} | {I18n.get("ACC. ANO")}{" "}
              </h2>

              {newDash &&
                newDash?.generalTable &&
                TableProject(newDash?.generalTable, this.props.auth.is_client)}

              {this.renderCallBack(newDash?.generalTable)}
            </CardDisplay>
          </Col>
        </Row>

        <DrawerBp
          title=""
          width="75%"
          onClose={this.onClose}
          visible={this.state.visible}
          destroyOnClose={true}
          className="DrawerBp"
        >
          {this.state.drawerInfos && this.state.drawerInfos && (
            <Fragment>
              <h2>{this.state.drawerInfos.name}</h2>
              <p className="description">
                {this.state.drawerInfos.description}
              </p>
              <p className="total">
                <small>{I18n.get("Total:")}</small>
                <span className="boxtotal">
                  <strong>R$</strong>{" "}
                  {formatCurrency(this.state.drawerInfos.total)}
                </span>
              </p>
              <p style={{ marginBottom: "20px", overflow: "hidden" }}>
                <button
                  className="button bt-bpool pull-right black"
                  onClick={handleClick}
                >
                  {I18n.get("Baixar")}
                </button>
              </p>

              <TableCard
                columns={columnsModal}
                dataSource={this.state.dataTable}
                pagination={{ defaultPageSize: 10 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      this.setState({
                        redirectProject: true,
                        redirectId: record.projectId,
                      });
                    },
                  };
                }}
              />
            </Fragment>
          )}
          {!this.state.drawerInfos && (
            <Fragment>
              <Skeleton active />
            </Fragment>
          )}
        </DrawerBp>
      </Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContainerClient);
