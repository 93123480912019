import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import CheckboxField from "../form/checkbox-field";
import TextAreaField from "../form/textarea-field";

import EventCheckoutForm from "./event-checkout-form";

import { scrollToTop } from "../../utils/ui";

import { Textarea } from "../../v2/components/UI/Textarea";

class ScheduleForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        isRecurring: {
          label: I18n.get("O evento se repete por mais dias?"),
          onChange: this.onChangeFormCheckbox.bind(this, "isRecurring"),
          // onBlur: this.onSave.bind(this),
        },
        recurringDesc: {
          label: I18n.get("Frequência"),
          onChange: this.onChangeForm.bind(this, "recurringDesc"),
          // onBlur: this.onSave.bind(this),
          placeholder: I18n.get(
            "Descreva a frequencia do seu evento. Exemplo: 2 dias ou toda quinta durante o mês de Março, todo final de semana de Abril"
          ),
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = checked;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Data e horário")}</h1>

        <div className="bp-event-section">
          <div className="field">
            <label className="label is-small">
              {I18n.get(
                "Você já sabe quando vai ser? Pode colocar uma data definitiva ou uma janela:"
              )}
            </label>
          </div>

          <br />

          <EventCheckoutForm {...this.props} />

          <br />
          <br />

          <CheckboxField
            {...this.state.fields.isRecurring}
            value={this.state.form.isRecurring}
            checked={this.state.form.isRecurring}
            error={this.state.errors.isRecurring}
          />

          {this.state.form.isRecurring === true && (
            // <TextAreaField {...this.state.fields.recurringDesc} value={this.state.form.recurringDesc} error={this.state.errors.recurringDesc} />

            <Textarea
              label={this.state.fields.recurringDesc.label}
              defaultValue={this.state.form.recurringDesc}
              value={this.state.form.recurringDesc}
              onChange={(value) =>
                this.state?.fields?.recurringDesc.onChange(value)
              }
              error={this.state?.errors?.recurringDesc?.errorMessage}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ScheduleForm;
