import React from 'react';

import { I18n } from 'aws-amplify';

import TooltipField from './tooltip-field';

import MarkAsRequiredOrOptional from './mark-as-required-or-optional';

export default class RadioField extends React.Component {
    constructor(props) {
        super();

        this.state = {
            focused: false,
            visited: props.visited
        };
    }

    static defaultProps = {
        successIcon: true
    }

    onFocus() {
        this.setState({ focused: true, visited: true });
    }

    onBlur() {
        this.setState({ focused: false });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onChange(e) {

        this.props.onChange(e);

        // Workaround para o blur
        if (this.props.onBlur) {
            setTimeout(() => {
                this.onBlur();
            }, 200);
        }
    }

    render() {

        const isError = !this.state.focused && this.state.visited && this.props.error;

        return (<div className="field">
            <label className="label is-small">
                {this.props.label} <MarkAsRequiredOrOptional {...this.props} />
                {this.props.labels && this.props.labels[this.props.labelKey]}
                <TooltipField tooltip={this.props.tooltip} />
            </label>
            <div className="control" style={{ paddingTop: '0.5rem' }}>

                {this.props.values && this.props.values.map((item, index) => {

                    const checked = new String(item.code).valueOf() == new String(this.props.value).valueOf();



                    const style = {
                        minHeight: '20px',
                        // marginLeft: index > 0 ? '0.5em' : ''
                    }

                    const Radio = (<label className="radio" style={style}>
                        <input type="radio" name={this.props.name} onChange={this.onChange.bind(this)} value={item.code} checked={checked} className={isError ? "is-danger" : ""} disabled={this.props.disabled || false} />
                        <span className="radiomark"></span>
                        <p>&nbsp;{item.label}</p>
                    </label>)


                    if (!item.field) {

                        const hStyle = {
                            clear: 'both',
                            display: this.props.isHorizontal ? 'inline-block' : 'block',
                            marginRight: '0.5rem'
                        }


                        // isHorizontal so funciona sem o campo adicional de texto
                        return (<div key={index} style={hStyle}>{Radio}</div>);
                    }
                    else {
                        return (
                            <div key={index} style={{ clear: 'both' }} >
                                <div style={{ float: 'left', clear: 'none', paddingTop: '0.25rem', minWidth: '50px' }}>
                                    {Radio}
                                </div>

                                <div style={{ float: 'left', clear: 'none', paddingLeft: '1rem' }}>
                                    {item.field}
                                </div>
                            </div>);
                    }

                })}

            </div>
            {isError && <p className="help is-danger">
                {this.props.error.errorMessage}
            </p>}
        </div>)
    }
}
