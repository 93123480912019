import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import NumberFormat from "react-number-format";
import { Select } from "antd";
import Loading from "../../UI/Loading";

import * as S from "./styles";
import COUNTRY_CODES from "./countryCodes.json";
const { Option } = Select;

const labelHtml = ({ req, label }) => {
  if (req) {
    return (
      <>
        <span className="required-input">*</span>
        {label}
      </>
    );
  } else {
    return label;
  }
};

const InputMaskCustom = (props) => {
  return (
    <S.InputMaskCustom addonBefore={props?.addonBefore}>
      <input {...props} className={props.error ? "input-error" : ""} />
      <label htmlFor={props.id}>
        {labelHtml({ req: props.required, label: props.label })}
      </label>
      {props.error ? <p className="error-input">{props.error}</p> : null}
    </S.InputMaskCustom>
  );
};

export const InputFlag = ({
  id,
  label,
  defaultValue,
  disabled,
  required,
  flagCodeDefault,
  flagDisabled,
  onChange,
  error,
  clearError,
  loading,
}) => {
  const [inputCountry, setInputCountry] = useState({
    countryLabel: "Brazil",
    phoneMask: "(00)00000-0000",
    countryCode: "BRA",
  });
  const [inputValue, setInputValue] = useState("");
  const [inputIsLoading, setInputIsLoading] = useState(false);

  const changeInput = async ({ value, country }) => {
    setInputValue(value);

    if (onChange) {
      onChange({ value: value, country: country || inputCountry.countryCode });
      if (inputValue !== defaultValue) {
        if (clearError) {
          clearError();
        }
      }
    }
  };

  const onChangeSelectCountry = async (value) => {
    const country = COUNTRY_CODES.find((item) => item.name === value);

    setInputIsLoading(true);
    await changeInput({ value: "", country: country.code });
    setInputIsLoading(false);

    setInputCountry({
      countryLabel: value,
      phoneMask: country.mask,
      countryCode: country.code,
    });
  };

  useEffect(() => {
    if (loading) {
      setInputIsLoading(true);
    }
  }, [loading]);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (flagCodeDefault) {
      const country = COUNTRY_CODES.find(
        (item) => item.code === flagCodeDefault
      );

      setInputCountry({
        countryLabel: country?.name,
        phoneMask: country?.mask,
        countryCode: country?.code,
      });
    }
  }, [flagCodeDefault]);

  return (
    <S.InputGroup data-testid={`input-group-${id}`}>
      {inputIsLoading ? (
        <S.ContentIsLoading>
          <Loading
            text={I18n.get("Loading informations...")}
            sizeText={14}
            sizeIcon={16}
          />
        </S.ContentIsLoading>
      ) : (
        <>
          <Select
            defaultValue={inputCountry.countryLabel}
            className="select-before"
            onSelect={(value) => onChangeSelectCountry(value)}
            value={inputCountry.countryLabel}
            disabled={flagDisabled}
            data-testid={`input-select-${id}`}
          >
            {(COUNTRY_CODES &&
              COUNTRY_CODES.map((item) => {
                let codeString = item.isO2.toLowerCase();
                return (
                  <Option
                    key={item.code}
                    value={item.name}
                    prefix={item.prefix}
                  >
                    <img
                      src={`https://assets.bpool.co/country-flags/png24px/${codeString}.png`}
                    />
                    <span className="prefix">{item.prefix}</span>
                  </Option>
                );
              })) ||
              []}
          </Select>
          <NumberFormat
            data-testid={`input-${id}`}
            id={id}
            customInput={InputMaskCustom}
            placeholder={label}
            label={label}
            defaultValue={inputValue}
            value={inputValue}
            onValueChange={(valueInternal) =>
              changeInput({
                value: valueInternal.value,
                country: inputCountry.countryCode,
              })
            }
            format={
              inputCountry.countryCode === "BRA" ? "(##) #####-####" : null
            }
            mask="_"
            required={required}
            error={error}
            disabled={disabled}
          />
        </>
      )}
    </S.InputGroup>
  );
};
