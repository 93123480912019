import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperSimple";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";

import { USERPARTNER_API } from "../../../actions/partner-user/actions";

import FormUser from "../components/formUser";

import { TitleBorder, TitleMiddleLine } from "../../components/Titles";

import styled from "styled-components";

import FormSkeleton from "../../components/FormSkeleton";

import { QueryString } from "../../../utils/querystring";

import { Row, Col, Input, Select, Button, Form } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: 60px;
  }
`;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirm: (data, locale) => {
      dispatch(USERPARTNER_API.confirm(data, locale));
    },
    update: (data) => {
      dispatch(USERPARTNER_API.update(data));
    },
  };
};

class UserAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    this.setState({
      locale: locale,
    });

    I18n.setLanguage(locale);

    let data = {
      verificationToken: this.props.match.params.token,
      locale: locale,
    };

    this.props.confirm(data, locale);
  };

  render() {
    if (this.props.partnerUser && this.props.partnerUser.completedOn) {
      return <Redirect to="/parceiro/usuario/cadastro/sucesso" />;
    }

    return (
      <AppWrapperSimple>
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>
                  {I18n.get("Cadastro de usuário - Sobre você")}
                </TitleBorder>
                <p className="descriptionPage">
                  {I18n.get(
                    "Você foi cadastrado(a) como um(a) usuário(o) da plataforma BPool e terá acessos e mensagens de acordo com a atribuição selecionada abaixo no campo FUNÇÃO. Complete as informações e comece a usar a plataforma agora mesmo!"
                  )}
                </p>
              </Col>
            </Row>

            <FormUser
              idContext={this.props.partnerUser && this.props.partnerUser.id}
              update={this.props.update}
              item={this.props.partnerUser && this.props.partnerUser}
              errors={this.props.partnerUser && this.props.partnerUser.errors}
              isUser={true}
              locale={this.state.locale}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
            />
          </Main>
        </ContainerPage>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAbout);
