import React, { useState } from "react";
import { I18n } from "aws-amplify";

import IconTotal0 from "../../../svgs/icon-total-0.svg";
import IconTotal1 from "../../../svgs/icon-total-1.svg";
import IconTotal2 from "../../../svgs/icon-total-2.svg";
import * as S from "./styles";

export const BPaymentsData = ({ data, isLoading }) => {
  const renderValue = (value) => {
    if (value || value === 0 || value === "0") {
      return value;
    } else {
      return "---";
    }
  };

  return (
    <>
      <S.ListOfTotals>
        <S.Skeleton
          loading={isLoading}
          active
          title={false}
          paragraph={{
            rows: 1,
            className: "skeleton-total",
            width: "100%",
          }}
        >
          <S.Total>
            <S.TotalIcon>
              <img alt="icon total 0" src={IconTotal0} />
            </S.TotalIcon>
            <S.TotalInfos>
              <h4>{I18n.get("Valor total de BPayments")}</h4>
              <p>{renderValue(data?.totalBPayments)}</p>
            </S.TotalInfos>
          </S.Total>
        </S.Skeleton>
        <S.Skeleton
          loading={isLoading}
          active
          title={false}
          paragraph={{
            rows: 1,
            className: "skeleton-total",
            width: "100%",
          }}
        >
          <S.Total>
            <S.TotalIcon>
              <img alt="icon total 2" src={IconTotal2} />
            </S.TotalIcon>
            <S.TotalInfos>
              <h4>{I18n.get("Valor BPayments em andamento")}</h4>
              <p>{renderValue(data?.totalBPaymentsInProgressFmt)}</p>
            </S.TotalInfos>
          </S.Total>
        </S.Skeleton>
        <S.Skeleton
          loading={isLoading}
          active
          title={false}
          paragraph={{
            rows: 1,
            className: "skeleton-total",
            width: "100%",
          }}
        >
          <S.Total>
            <S.TotalIcon>
              <img alt="icon total 3" src={IconTotal1} />
            </S.TotalIcon>
            <S.TotalInfos>
              <h4>{I18n.get("Valor Total BPayments finalizados")}</h4>
              <p>{renderValue(data?.totalBPaymentsFinishedFmt)}</p>
            </S.TotalInfos>
          </S.Total>
        </S.Skeleton>
      </S.ListOfTotals>
    </>
  );
};
