import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import Content from "../../../components/UI/Content";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import { H2Border } from "../../../components/UI/Titles";
import { TabsProject } from "../../../components/UI/TabsProject";

import * as S from "./styles";

import { Briefing as BriefingShared } from "../../Shared/Project/Briefing";

export const Briefing = () => {
  let params = useParams();
  const { getProjectById } = useProjects();
  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);;
  const [statusProject, setStatusProject] = useState({});
  const [urlNextStep, setUrlNextStep] = useState("");

  const loadInit = async () => {
    setIsLoading(true);
    try {
      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });

      setStatusProject({
        status: responseProject?.data?.status,
        partnerHasSubmittedDefinitions:
          responseProject?.data?.partnerHasSubmittedDefinitions
      });
    } catch (error) {

    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
    const urlNext = `/${auth?.is_partner ? "parceiro" : "cliente"}/projetos/v2/${params?.projectId
      }/review`;
    setUrlNextStep(urlNext);
  }, []);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Definições do Projeto")}</H2Border>
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <TabsProject
                context="definitions"
                page="briefing"
                externalLoading={isLoading}
                projectStatus={statusProject?.status}
                partnerHasSubmittedDefinitions={statusProject?.partnerHasSubmittedDefinitions}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <BriefingShared urlNextStep={urlNextStep} />
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
