import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { useUpload } from "../../../../../hooks/useUpload/useUpload.hook";
import { useInProgress } from "../../../../../hooks/useInProgress/useInProgress.hook";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { Drawer as DrawerComp } from "../../../../../components/UI/Drawer";
import { FileUpload } from "../../../../../components/UI/FileUpload";
import { LinksFiles } from "../../../../../components/UI/LinksFiles";

export const Drawer = ({ open, handleCloseDrawer, projectData, reload }) => {
  const auth = useSelector((state) => state.auth);
  const { uploadFile: uploadFileS3 } = useUpload();
  const { uploadFile, deleteFile, addUrls } = useInProgress();
  const [filesInternal, setFilesInternal] = useState([]);
  const [linksInternal, setLinksInternal] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);

  const closeDrawer = ({ reload }) => {
    setFilesInternal([]);
    setLinksInternal([]);
    handleCloseDrawer({ reload });
  };

  const uploadFiles = async (files) => {
    setLoadingFiles(true);

    try {
      let filesToUpload = [];
      if (files?.length > 0) {
        await Promise.all(
          files.map(async (file) => {
            const result = await uploadFileS3({
              file: file,
              idUser: auth?.is_client_or_client_user
                ? `${auth?.clientId}`
                : `${auth?.partnerId}`,
              fieldId: `${
                auth?.is_client_or_client_user ? "cliente" : "parceiro"
              }-${projectData?.projectId}-files`,
            });
            filesToUpload.push(result);
          })
        );
      }

      if (filesToUpload?.length > 0) {
        await Promise.all(
          filesToUpload.map(async (file) => {
            await uploadFile({
              projectId: projectData?.projectId,
              name: file?.name,
              url: file?.url,
              type: file?.type,
              size: file?.size,
              uploadedDate: file?.uploadedDate,
              projectCompletionFile: true,
            });
          })
        );
        notification.success({
          message: I18n.get("Sucesso!"),
          description: I18n.get("Os arquivos foram salvos."),
          duration: 3,
        });
      }

      if (linksInternal?.length) {
        const objSend = {
          projectId: projectData?.projectId,
          urls: linksInternal?.map((url) => url.url),
        };
        const response = await addUrls(objSend);
        if (response?.success) {
          notification.success({
            message: I18n.get("Sucesso!"),
            description: I18n.get(
              linksInternal?.length > 1
                ? "Links adicionados."
                : "Link adicionado."
            ),
            duration: 3,
          });
        }
      }
    } catch (error) {
      notification.success({
        message: I18n.get("Erro"),
        description: I18n.get("Erro ao salvar os arquivos."),
        duration: 3,
      });
    } finally {
      setLoadingFiles(false);
    }
    closeDrawer({ reload: true });
  };

  const deleteFiles = async (fileOrId) => {
    if (fileOrId?.name) {
      const updatedFiles = filesInternal.filter((file) => file !== fileOrId);
      setFilesInternal(updatedFiles);
    } else {
      setLoadingFiles(true);
      const info = {
        projectId: projectData?.projectId,
        id: fileOrId,
      };

      const response = await deleteFile(info);

      if (response?.success) {
        notification.success({
          message: I18n.get("Sucesso!"),
          description: I18n.get("O arquivo foi deletado."),
          duration: 3,
        });
      } else {
        notification.error({
          message: I18n.get("Erro"),
          description: I18n.get("Erro ao deletar o arquivo."),
          duration: 3,
        });
      }
      setLoadingFiles(false);
      reload();
    }
  };

  const cbLinks = (link) => {
    setLinksInternal([...linksInternal, link]);
  };

  const hasDisabledSave = () => {
    if (filesInternal?.length) {
      return false;
    } else if (linksInternal?.length) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <DrawerComp
      open={open}
      handleClose={() => closeDrawer({ reload: false })}
      buttons={
        <>
          <ButtonBpool
            text={I18n.get("Cancelar")}
            className=""
            theme="tertiary"
            onClick={() => closeDrawer({ reload: false })}
          />
          <div style={{ marginLeft: 10 }}>
            <ButtonBpool
              text={I18n.get("Salvar")}
              className=""
              theme="primary"
              loading={loadingFiles}
              disabled={hasDisabledSave()}
              onClick={() => uploadFiles(filesInternal)}
            />
          </div>
        </>
      }
    >
      <div>
        {projectData?.projectName ? (
          <div style={{ marginBottom: 15 }}>
            <strong style={{ fontSize: 22 }}>
              {I18n.get(
                `Upload de arquivos para o projeto ${projectData?.projectName}`
              )}
            </strong>
          </div>
        ) : null}
        <strong style={{ fontSize: 17 }}>
          {I18n.get(
            "Para solicitar a conclusão do projeto, você precisará fornecer prova entrega do escopo acordado, envie abaixo os documentos."
          )}
        </strong>

        <p style={{ fontSize: 14, marginTop: 30 }}>
          {I18n.get(
            "Envie os arquivos para a pasta do projeto A. Pode ser em PDF, JPG, PNG WORD, PPT ou link"
          )}
        </p>

        {!open ? (
          <div />
        ) : (
          <div style={{ marginTop: 15 }}>
            <FileUpload
              isLoading={loadingFiles}
              cbFiles={(files) => setFilesInternal(files)}
              initialFiles={
                projectData?.filesCompletionProject?.length
                  ? projectData?.filesCompletionProject
                  : []
              }
              cbDeleteFile={(fileOrId) => deleteFiles(fileOrId)}
              hasPropsItemListCanBeDeleted={true}
            />
          </div>
        )}
        {!open ? (
          <div />
        ) : (
          <div style={{ marginTop: 30 }}>
            <LinksFiles
              isLoading={loadingFiles}
              projectId={projectData?.projectId}
              // cbLinks={cbLinks}
              cbLinksOnClick={cbLinks}
              notSendInternal
              urlsDefault={
                projectData?.projectCompletionUrls?.length
                  ? projectData?.projectCompletionUrls
                  : []
              }
              hasPropsItemListCanBeDeleted
            />
          </div>
        )}
      </div>
    </DrawerComp>
  );
};
