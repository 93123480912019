import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperSimple";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";
import { CLIENT_USER_API } from "../../../actions/client-user/actions";
import FormUser from "../components/formUserClient";
import { TitleBorder, TitleMiddleLine } from "../../components/Titles";
import styled from "styled-components";
import FormSkeleton from "../../components/FormSkeleton";
import { QueryString } from "../../../utils/querystring";

import { Row, Col, Input, Select, Button, Form } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: 60px;
  }
`;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirm: (data) => {
      dispatch(CLIENT_USER_API.confirm(data));
    },
    update: (data) => {
      dispatch(CLIENT_USER_API.update(data));
    },
  };
};

class UserAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: "pt",
      verificationToken: props.match.params.verificationToken,
    };
  }

  componentDidMount = () => {
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    this.setState({
      locale: locale,
    });

    I18n.setLanguage(locale);

    let token = {
      verificationToken: this.props.match.params.verificationToken,
      locale: locale,
    };

    this.props.confirm(token);
  };

  render() {
    if (this.props.client_user && this.props.client_user.completedOn) {
      return <Redirect to="/cliente/usuario/cadastro/sucesso" />;
    }

    return (
      <AppWrapperSimple>
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>
                  {I18n.get("Cadastro de usuário - Sobre você")}
                </TitleBorder>
              </Col>
            </Row>

            <FormUser
              idContext={this.props.clientUser && this.props.clientUser.id}
              update={this.props.update}
              item={this.props.client_user && this.props.client_user}
              errors={this.props.client_user && this.props.client_user.errors}
              isUser={true}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
              isUserClient={true}
              token={this.props.match.params.token}
              locale={this.state.locale}
            />
          </Main>
        </ContainerPage>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAbout);
