import get_client from './get';

import create_client from './create';
import update_client from './update';
import confirm_client from './confirm';
import confirm_terms from './confirm-terms';

import update_person from './update-person';
import update_company from './update-company';

import create_user from './create-user';
import delete_user from './delete-user';

import create_brand from './create-brand';
import delete_brand from './delete-brand';
import update_product from './update-product';
import delete_product from './delete-product';

// v2

import create_brand_v2 from './create-brand-v2';
import delete_brand_v2 from './delete-brand-v2';
import update_brand_v2 from './update-brand-v2';
import reset_erros from './reset-erros';

import update_payment from './update-payment';
import update_msg from './update-msg-global';

import create_project from './create-project';
import delete_project from './delete-project';
import update_project from './update-project';

import get_projects from './get_projects';
import get_projects2 from './get_projects2';
import get_agreements from './get_agreements';
import get_dashboard from './get_dashboard';
import upload from './upload';
import update_terms from './update-terms';
import update_responsible_terms from './update-responsible-terms';
import get_project_invitations from './get_project_invitations';
import get_ndas from './get_ndas';


import send_feedback from './feedback';


import create_user_v2 from './create-user-v2';
import delete_user_v2 from './delete-user-v2';
import update_user_v2 from './update-user-v2';


import get_extras from './get_extras';

export const CLIENT_GET = 'client_get';
export const CLIENT_CREATE = 'client_create';
export const CLIENT_UPDATE = 'client_update';

export const CLIENT_USER_CREATE = 'client_user_create';
export const CLIENT_USER_DELETE = 'client_user_delete';

export const CLIENT_BRAND_CREATE = 'client_brand_create';
export const CLIENT_BRAND_DELETE = 'client_brand_delete';
export const CLIENT_BRAND_UPDATE = 'client_brand_update';

export const CLIENT_CONTACT_CREATE = 'client_contact_create';
export const CLIENT_CONTACT_DELETE = 'client_contact_delete';

export const CLIENT_PROJECTS_GET = 'client_project_update';
export const CLIENT_PROJECTS_GET2 = 'client_project_get2';
export const CLIENT_PROJECTS_AGREEMENTS = 'client_agreements';

export const CLIENT_PROJECTS_INVITATIONS = 'client_project_get_invitations';

export const CLIENT_PROJECT_CREATE = 'client_project_create';
export const CLIENT_PROJECT_DELETE = 'client_project_delete';
export const CLIENT_PROJECT_UPDATE = 'client_project_update';

export const CLIENT_FEEDBACK = 'client_feedback ';

export const CLIENT_UPLOAD = 'client_upload';

export const CLIENT_EXTRAS_GET = 'client_project_update';


export const CLIENT_API = {
    get: get_client,
    create: create_client,
    update: update_client,
    confirm: confirm_client,
    update_person: update_person,
    update_company: update_company,
    create_user: create_user,
    delete_user: delete_user,
    create_brand: create_brand,
    delete_brand: delete_brand,
    update_product: update_product,
    delete_product: delete_product,
    update_payment: update_payment,
    get_projects: get_projects,
    get_agreements: get_agreements,
    create_project: create_project,
    delete_project: delete_project,
    update_project: update_project,
    get_dashboard: get_dashboard,
    upload: upload,
    update_terms: update_terms,
    confirm_terms: confirm_terms,
    update_responsible_terms: update_responsible_terms,
    send_feedback: send_feedback,
    get_projects2: get_projects2,
    get_project_invitations: get_project_invitations,
    get_ndas: get_ndas,
    create_brand_v2: create_brand_v2,
    delete_brand_v2: delete_brand_v2,
    update_brand_v2: update_brand_v2,
    create_user_v2: create_user_v2,
    delete_user_v2: delete_user_v2,
    update_user_v2: update_user_v2,
    reset_erros: reset_erros,
    get_extras: get_extras,
    update_msg: update_msg
}

