import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import icoAgenda from "../../image/ico-agenda.svg";
import icoContract from "../../image/ico-contract.svg";
import icoDash from "../../image/ico-dash.svg";
import icoExit from "../../image/ico-exit.svg";
import icoFaq from "../../image/ico-faq.svg";
import icoHome from "../../image/ico-home.svg";
import icoNews from "../../image/ico-news.svg";
import icoProfile from "../../image/ico-profile-menu.svg";
import icoProjects from "../../image/ico-projects.svg";
import icoBpay from "../../image/ico-bpay.svg";
import icoRepoFiles from "../../image/ico-repo-files.svg";
import icoClose from "../../image/ico-close.svg";
import icoMenu from "../../image/ico-menu-white.svg";
import bLogo from "../../image/b-logo.svg";

import { AUTH_API } from "../../actions/auth/actions";

import Config from "../../config";

import { Tag } from "antd";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(AUTH_API.logout());
    },
  };
};

class TopMenu extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isActive: null,
    };

    this.activeMenu = this.activeMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    this.props.get_knowledge("Client");
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isActive: false,
      });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logout();
  }

  activeMenu(event) {
    event.stopPropagation();
    this.setState({
      isActive: true,
    });
  }

  deactivateMenu(event) {
    event.stopPropagation();
    this.setState({
      isActive: false,
    });
  }

  render() {
    const name =
      (this.props.auth && this.props.auth.name) || this.props.auth.email;

    var firstName = "";

    const urlDash = "/cliente/dashboard";

    //Inicialmente somente pra Parceiros no Brasil
    // let locale = this.props.auth.locale;
    // let isPartner = this.props.auth.is_partner;
    // let shouldShowFaqs = locale === "pt" && isPartner === true;

    let urlWiki =
      this.props?.wikis?.knowledgeBase &&
      this.props?.wikis?.knowledgeBase.length > 0
        ? this.props?.wikis?.knowledgeBase[0]?.name
        : "all";

    // const name = (this.props.auth
    //     && this.props.auth.name) || this.props.auth.email;
    // var firstName = "";
    // const urlDash = "/cliente/dashboard";
    // if (name.indexOf("@") > -1)
    //     firstName = name.split('@')[0].toUpperCase();
    // else
    //     firstName = name.split(' ')[0].toUpperCase();

    return (
      <div
        ref={this.setWrapperRef}
        onClick={(event) => this.activeMenu(event)}
        className={`navbar-item has-dropdown ${
          this.state.isActive ? "is-active" : ""
        }`}
      >
        <a className="navbar-link">
          <img src={icoMenu} alt="icone menu" />
        </a>

        {/* <div className="navbar-dropdown">
                    <div className="columns">
                        <div className="column is-6 is-mobile navbar-dropdown-left">{I18n.get("Olá,")} {firstName}!
                        </div>
                        <div className="column is-6 is-mobile navbar-dropdown-right">

                            <NavLink to={urlDash} className="navbar-item" activeClassName="is-active">{I18n.get("Dashboard")}</NavLink>
                            <NavLink to="/cliente/central-de-pendencias" className="navbar-item" activeClassName="is-active">{I18n.get("Pendências")}</NavLink>

                            <NavLink to="/cliente/contratos" className="navbar-item" activeClassName="is-active">{I18n.get("Contratos")}</NavLink>
                            {/* {Config.moduloWhatif &&
                                <NavLink to="/cliente/ideias/home" className="navbar-item menu-space" activeClassName="is-active">What IF <Tag color="#e50d0d">New</Tag></NavLink>
                            } 
                            <NavLink to="/cliente/projetos" className="navbar-item" activeClassName="is-active">{I18n.get("Projetos")}</NavLink>
                            <NavLink to="/cliente/extras" className="navbar-item" activeClassName="is-active">{I18n.get("Extras")}</NavLink>
                            <NavLink to="/cliente/contratos" className="navbar-item" activeClassName="is-active">{I18n.get("Contratos")}</NavLink> 
                            {/* {Config.moduloWhatif &&
                                <NavLink to="/cliente/ideias/home" className="navbar-item menu-space" activeClassName="is-active">What IF <Tag color="#e50d0d">New</Tag></NavLink>
                            } */}
        {/* <NavLink to="/cliente/projetos" className="navbar-item" activeClassName="is-active">{I18n.get("Projetos")}</NavLink>

                            <NavLink to="/cliente/responsavel" className="navbar-item" activeClassName="is-active">{I18n.get("Cadastro")}</NavLink>
                            {/* {shouldShowFaqs &&
                                <NavLink disabled={(this.props?.wikis?.knowledgeBase && this.props?.wikis?.knowledgeBase.length == 0)} to={`/cliente/wikis/${urlWiki}`} className="navbar-item" activeClassName="is-active">{I18n.get("Processos")}</NavLink>
                            } */}

        {/* <a onClick={this.onLogoutClick.bind(this)} className="navbar-item navbar-last-item">{I18n.get("Sair")}</a> 

                        </div>
                    </div>
                </div> */}

        <div className="navbar-menu-left">
          <div className="menu-left-header">
            <a href="/">
              <img src={bLogo} />
            </a>
            <img
              src={icoClose}
              onClick={(event) => this.deactivateMenu(event)}
            />
          </div>
          <div>
            <NavLink to="/" className="navbar-item" activeClassName="is-active">
              <img src={icoHome} />
              {I18n.get("Home")}
            </NavLink>
            {/* <NavLink to="/cliente/news" className="navbar-item" activeClassName="is-active"><img src={icoNews}/>{I18n.get("News")}</NavLink>  */}
            <NavLink
              to={`/parceiro/wikis/${urlWiki}`}
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoFaq} />
              {I18n.get("Processos")}
            </NavLink>

            <NavLink
              to="/cliente/projetos/v2"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoProjects} />
              {I18n.get("Projetos")}
            </NavLink>
            {Config.moduloNewBpay && (
              <NavLink
                to="/cliente/bpay"
                className="navbar-item"
                activeClassName="is-active"
              >
                <img src={icoBpay} />
                {I18n.get("BPay")}
              </NavLink>
            )}
            <NavLink
              to="/cliente/repositorio-de-arquivos"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoRepoFiles} />
              {I18n.get("Repositório de Arquivos")}
            </NavLink>
            <NavLink
              to="/cliente/projetos/v2?meetings"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoAgenda} />
              {I18n.get("Agenda de Videocalls")}
            </NavLink>
            <NavLink
              to="/cliente/dashboard"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoDash} />
              {I18n.get("Central de Pendências e Dashboard")}
            </NavLink>
            <NavLink
              to="/cliente/contratos"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoContract} />
              {I18n.get("Contratos")}
            </NavLink>
            <NavLink
              to="/cliente/responsavel"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoProfile} />
              {I18n.get("Perfil")}
            </NavLink>
            {Config.moduloFaqs && (
              <NavLink
                to="/cliente/wikis/tutoriais"
                className="navbar-item"
                activeClassName="is-active"
              >
                <img src={icoFaq} />
                {I18n.get("Tutoriais e FAQs")}
              </NavLink>
            )}

            <a
              onClick={this.onLogoutClick.bind(this)}
              className="navbar-item navbar-last-item"
            >
              <img src={icoExit} />
              {I18n.get("Sair")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
