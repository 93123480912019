import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message, Modal, Icon, Tag } from 'antd';
import { UploadOutlined, MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { I18n } from "aws-amplify";
import { useMilestones } from "../../../../../hooks/useMilestones/useMilestones.hook";
import Loading from "../../../../../components/UI/Loading";
import { ModalBp } from "../../../../../components/UI/ModalBp/ModalBp";
import { BoxUpload } from '../../../../../components/UI/BoxUpload';
import { Button } from '../../../../../components/UI/Button';
import { ClientProjectStatus } from "../../../../../../model"

import * as S from "./styles";

export const TableMacrosV2 = ({
  columns,
  data,
  loading,
  projectId,
  reload,
  disableds,
  clientOrPartner,
  statusProject
}) => {
  const {
    uploadFileMilestonesV2,
    deleteFileMilestonesV2,
    deleteMilestones,
    updateDelivery
  } = useMilestones();
  const [openAccordion, setOpenAccordion] = useState(null);
  const [isLoadingFile, setIsLoadingFile] = useState(null);
  const [openModal, setOpenModal] = useState({
    open: false,
    bodyText: "",
    subBodyText: "",
    typeModal: "",
    infosFunction: { funtionType: "" },
  });
  const [modalUpload, setModalUpload] = useState({ open: false, milestoneId: "", files: [], title: "" });
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState("");
  const [isClientOrPartnerApprovalLoading, setIsClientOrPartnerApprovalLoading] = useState(null);
  const [isDeletingMacro, setIsDeletingMacro] = useState("");

  const handleAccordionClick = (index) => {
    setOpenAccordion(index === openAccordion ? null : index);
  };

  const renderText = (text) => {
    return text || "---";
  };

  const renderIcoOpen = ({ status, index }) => {
    return status === "open" ?
      (
        <S.IcoOpenClose onClick={() => handleAccordionClick(index)}>
          <MinusOutlined size={14} />
        </S.IcoOpenClose>
      ) :
      (
        <S.IcoOpenClose onClick={() => handleAccordionClick(index)}>
          <PlusOutlined size={14} />
        </S.IcoOpenClose>
      )
  };

  const handleUploadFile = async ({ file, milestoneId }) => {
    const objUpload = {
      milestoneId,
      projectId,
      name: file?.name,
      url: file?.url,
      type: file?.type,
      size: file?.size,
      uploadedDate: file?.uploadedDate,
      description: "",
      fileCategory: "",
    }

    const response = await uploadFileMilestonesV2(objUpload);
    if (response?.success) {
      message.success(I18n.get("File uploaded successfully"));
      await reload();
    } else {
      message.error(I18n.get("Error uploading file"));
    }
  };

  const handleModalCancel = () => {
    setOpenModal({
      open: false,
      bodyText: "",
      subBodyText: "",
      typeModal: "",
      infosFunction: { funtionType: "" },
    })
  };

  const handleDeleteFile = async ({ fileId, milestoneId }) => {
    const itemDelete = {
      projectId,
      milestoneId,
      fileId
    };
    setIsLoadingFile(true);

    try {
      const response = await deleteFileMilestonesV2(itemDelete);
      if (response?.success) {
        handleModalCancel();
        message.success(I18n.get("File deleted successfully"));
        await reload();
      }
    } catch (error) {
      handleModalCancel();
      message.error(I18n.get("Error deleting file"));
    }

    setIsLoadingFile(false);
  };

  const handleDeleteMacro = async ({ milestoneId }) => {
    const itemDelete = {
      projectId,
      milestoneId
    };
    console.log('handleDeleteMacro itemDelete', itemDelete);
    setIsDeletingMacro(milestoneId);

    try {
      const response = await deleteMilestones(itemDelete);
      handleModalCancel();
      message.success(I18n.get("Macro deleted successfully"));
      await reload();
    } catch (error) {
      handleModalCancel();
      message.error(I18n.get("Error deleting macro"));
    }

    setIsDeletingMacro("");
  };

  const handleDeleteFileOnBoxUpload = async ({ fileId, milestoneId, files }) => {
    setIsLoadingDeleteFile(fileId);
    try {
      await handleDeleteFile({ fileId, milestoneId });
      await reload();

      const filesUpdate = files.filter(file => file.id !== fileId);

      setModalUpload({ ...modalUpload, files: filesUpdate });

    } catch (error) {
      return;
    }
    setIsLoadingDeleteFile("");
  };

  const handleOpenModalDelete = ({ fileId, milestoneId, file }) => {
    if (file) {
      setOpenModal({
        open: true,
        bodyText: "Do you really want to delete the file?",
        subBodyText: "This action is irreversible.",
        typeModal: "isConfirm",
        infosFunction: { fileId, milestoneId, funtionType: "deleteFile" },
      });
    } else {
      setOpenModal({
        open: true,
        bodyText: "Do you really want to delete the macro?",
        subBodyText: "This action is irreversible.",
        typeModal: "isConfirm",
        infosFunction: { milestoneId, funtionType: "deleteMacro" },
      });
    }
  };

  const handleModalConfirm = () => {
    if (openModal?.infosFunction?.funtionType === "deleteFile") {
      handleDeleteFile({ fileId: openModal?.infosFunction?.fileId, milestoneId: openModal?.infosFunction?.milestoneId });
    }
    if (openModal?.infosFunction?.funtionType === "deleteMacro") {
      handleDeleteMacro({ milestoneId: openModal?.infosFunction?.milestoneId });
    }
  };

  const closeModalUpload = () => {
    setModalUpload({ open: false, milestoneId: "", files: [], title: "" });
  };

  useEffect(() => {
    if (modalUpload?.open) {
      setModalUpload({ ...modalUpload, files: data?.find(item => item?.milestoneId === modalUpload?.milestoneId)?.files })
    }
  }, [data]);

  const clientOrPartnerApproval = async (milestonesId) => {
    setIsClientOrPartnerApprovalLoading(milestonesId);
    const dataClient = {
      projectId,
      milestoneId: milestonesId,
      approved: true
    }
    const dataPartner = {
      projectId,
      milestoneId: milestonesId,
    }

    const response = await updateDelivery(clientOrPartner === "client" ? dataClient : dataPartner);
    if (response?.success) {
      message.success(I18n.get("Delivery approved successfully"));
      await reload();
    } else {
      message.error(I18n.get("Error approving delivery"));
    }

    setIsClientOrPartnerApprovalLoading(null);
  };

  const renderButtonClientApproval = ({ milestoneId, clientConfirmDelivered }) => {
    return (
      isClientOrPartnerApprovalLoading === milestoneId ? (
        <S.ButtonClientApproval disabled onClick={() => true}>
          <Loading
            text={I18n.get("Loading")}
            sizeText={14}
            sizeIcon={14}
            color="#000000"
            align="left"
          />
        </S.ButtonClientApproval>
      ) : (
        clientConfirmDelivered ? (
          <Icon type="check-circle" />
        ) : (
          clientOrPartner === "client" ? (
            <S.ButtonClientApproval onClick={() => clientOrPartnerApproval(milestoneId)}>
              {I18n.get("Approve")}
            </S.ButtonClientApproval>
          ) : (
            <Tag>{I18n.get("Waiting")}</Tag>
          )
        )
      )
    )
  };

  const renderButtonPartnerApproval = ({ milestoneId, partnerConfirmDelivered }) => {
    return (
      isClientOrPartnerApprovalLoading === milestoneId ? (
        <S.ButtonClientApproval disabled onClick={() => true}>
          <Loading
            text={I18n.get("Loading")}
            sizeText={14}
            sizeIcon={14}
            color="#000000"
            align="left"
          />
        </S.ButtonClientApproval>
      ) : (
        partnerConfirmDelivered ? (
          <Icon type="check-circle" />
        ) : (
          clientOrPartner === "partner" ? (
            <S.ButtonClientApproval onClick={() => clientOrPartnerApproval(milestoneId)}>
              {I18n.get("Approve")}
            </S.ButtonClientApproval>
          ) : (
            <Tag>{I18n.get("Waiting")}</Tag>
          )
        )
      )
    )
  };

  return (
    <>
      <S.Table>
        <thead>
          <S.TableRow>
            {columns?.map((column, index) => (
              <S.TableHeader key={index}>{column}</S.TableHeader>
            ))}
          </S.TableRow>
        </thead>
        <tbody>
          {loading ? (
            <div style={{ lineHeight: "50px" }}>
              <Loading
                text={I18n.get("Loading...")}
                sizeText={14}
                sizeIcon={16}
                color="#000000"
                align="left"
              />
            </div>
          ) : data?.length ? data?.map((item, index) => (
            <React.Fragment key={item?.milestoneId}>
              <S.TableRow>
                <S.TableData>
                  {renderIcoOpen({ status: openAccordion === index ? "open" : "closed", index })}
                </S.TableData>
                <S.TableData>{renderText(item?.name)}</S.TableData>
                <S.TableData>{renderText(item?.createdOn)}</S.TableData>
                <S.TableData>
                  <S.ButtonFileUpload disabled={disableds} onClick={disableds ? () => true : () => setModalUpload({ open: true, milestoneId: item?.milestoneId, files: item?.files, title: `${item?.name} ${I18n.get("files")}` })}>
                    <UploadOutlined />
                    <span style={{ marginLeft: 5, lineHeight: "30px" }}>{I18n.get("Upload")}</span>
                  </S.ButtonFileUpload>
                </S.TableData>
                <S.TableData>{renderButtonPartnerApproval({ milestoneId: item?.milestoneId, partnerConfirmDelivered: item?.partnerConfirmDelivered })}</S.TableData>
                <S.TableData>{renderButtonClientApproval({ milestoneId: item?.milestoneId, clientConfirmDelivered: item?.clientConfirmDelivered })}</S.TableData>
                <S.TableData>
                  {statusProject === 6 ? (
                    <S.ContentDeleteMacro>
                      <S.DelFile onClick={() => true} disabledButton={true} disabled={true}>
                        <DeleteOutlined size={14} />
                      </S.DelFile>
                    </S.ContentDeleteMacro>
                  ) : (
                    isDeletingMacro === item?.milestoneId ? (
                      <S.ContentDeleteMacro>
                        <Loading
                          text=""
                          sizeText={14}
                          sizeIcon={16}
                          color="#000000"
                          align="left"
                        />
                      </S.ContentDeleteMacro>
                    ) : (
                      <S.ContentDeleteMacro>
                        <S.DelFile onClick={() => handleOpenModalDelete({ milestoneId: item?.milestoneId, file: false })}>
                          <DeleteOutlined size={14} />
                        </S.DelFile>
                      </S.ContentDeleteMacro>
                    )
                  )}
                </S.TableData>
              </S.TableRow>
              <S.TableRow style={{ height: 0, border: "none" }}>
                <S.TableData colSpan={columns?.length}>
                  <S.TableAccordion isOpen={openAccordion === index} itemsRows={item?.files?.length === 0 ? 1 : item?.files?.length}>
                    <S.TableAccordionContent>
                      {item?.files?.length ? item?.files?.map((file) => (
                        <S.RowFile key={file?.id}>
                          {disableds ? (
                            <S.DelFile onClick={() => true} disabledButton={disableds} disabled={disableds}>
                              <DeleteOutlined size={14} />
                            </S.DelFile>
                          ) : (
                            <S.DelFile onClick={() => handleOpenModalDelete({ fileId: file?.id, milestoneId: item?.milestoneId, file: true })}>
                              <DeleteOutlined size={14} />
                            </S.DelFile>
                          )}
                          <div style={{ marginLeft: 15 }}>{file?.name}</div>
                        </S.RowFile>
                      )) : (
                        <S.RowFile>
                          {I18n.get("No files to display")}
                        </S.RowFile>
                      )}
                    </S.TableAccordionContent>
                  </S.TableAccordion>
                </S.TableData>
              </S.TableRow>
            </React.Fragment>
          )) : (<div>{I18n.get("No record to display")}</div>)}
        </tbody>
      </S.Table>

      <ModalBp
        visible={openModal.open}
        bodyText={I18n.get(openModal.bodyText)}
        subBodyText={I18n.get(openModal.subBodyText)}
        okText={I18n.get('Yes')}
        handleClickOk={handleModalConfirm}
        cancelText={I18n.get('Cancel')}
        onCancel={handleModalCancel}
        typeModal={openModal.typeModal}
        isLoading={isLoadingFile || isDeletingMacro}
        isAlert
      />

      <Modal
        title={modalUpload.title}
        centered
        visible={modalUpload.open}
        onOk={closeModalUpload}
        onCancel={closeModalUpload}
        footer={[
          <Button
            text={I18n.get("Close")}
            theme="secondary"
            full
            onClick={closeModalUpload}
          />
        ]}
      >
        <BoxUpload
          direct={true}
          disabledList={false}
          name="files"
          sendUpload={(file) => handleUploadFile({ file, milestoneId: modalUpload.milestoneId })}
          uploadId={modalUpload.milestoneId}
          loadingFiles={loadingFiles}
        />

        <S.FilesInModal>
          {modalUpload?.files?.length ? modalUpload?.files?.map((file) => (
            <S.RowFile key={file?.id}>
              {isLoadingDeleteFile === file?.id ? (
                <Loading
                  text={I18n.get("Deleting...")}
                  sizeText={14}
                  sizeIcon={16}
                  color="#000000"
                  align="left"
                />
              ) : (
                <>
                  <S.DelFile onClick={() => handleDeleteFileOnBoxUpload({ fileId: file?.id, milestoneId: modalUpload?.milestoneId, files: modalUpload?.files })}>
                    <DeleteOutlined size={14} />
                  </S.DelFile>
                  <div style={{ marginLeft: 15 }}>{file?.name}</div>
                </>
              )}
            </S.RowFile>
          )) : (
            <div>
              {I18n.get("No files to display")}
            </div>
          )}
        </S.FilesInModal>
      </Modal>
    </>
  );
};
