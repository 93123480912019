import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Upload, Icon, Modal, Button, message } from 'antd';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function getBase64Get(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('Somente arquivos JPG e PNG são suportados.');
    }
    // const isLt2M = file.size / 1024 / 1024 < 4;
    // if (!isLt2M) {
    //     message.error('Tamanho máximo suportado é de 4MB');
    // }
    // return isJpgOrPng && isLt2M;
    return isJpgOrPng;
}


class MultUploadFile extends Component {
    state = {
        previewVisible: false,
        previewImage: '',
        fileList: [],
        fileSend: [],
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = (e) => {
        // this.setState({ fileList });

    };

    customRequest = ({ file, onError, onSuccess }) => {
        let img64 = "";

        getBase64Get(file, imageUrl => {
            img64 = imageUrl;

            const dataUpload = {
                id: this.props.uploadId,
                img_type: file.type,
                img_base64: img64
            }

            this.props.actionUpload(dataUpload).then((response) => {
                const url = response.data.url;

                this.setState({
                    fileList: [
                        ...this.state.fileList,
                        {
                            uid: url,
                            name: 'image.png',
                            status: 'done',
                            url: url,
                        }
                    ]
                })

                setTimeout(() => {
                    this.sendImages();
                }, 1);

                onSuccess(null, file);
            }).catch((error) => {
                onError(error)
            });
        });
    }

    sendImages = () => {
        const imagens = this.state.fileList.map(item => {
            return item.url;
        });

        setTimeout(() => {
            this.props.getDataUpload(imagens)
        }, 10);
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.fileListGet != prevProps.fileListGet && this.props.isEdit) {
            this.setState({
                fileList: this.props.fileListGet
            });
        }
    }


    render() {
        const { previewVisible, previewImage, fileList } = this.state;

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div className="clearfix">
                <Upload
                    listType="picture-card"
                    onPreview={this.handlePreview}
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    customRequest={(e) => this.customRequest(e)}
                    onChange={(e) => this.handleChange(e)}
                    onRemove={(file) => {
                        this.setState(state => {
                            const index = state.fileList.indexOf(file);
                            const newFileList = state.fileList.slice();
                            newFileList.splice(index, 1);
                            return {
                                fileList: newFileList,
                            };
                        });

                        setTimeout(() => {
                            this.sendImages()
                        }, 1);
                    }}
                >
                    {fileList.length >= 6 ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(MultUploadFile);
export default MultUploadFile;
