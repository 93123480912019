import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { BP_API } from "../../actions/bp/actions";
import { PROJECT_API } from "../../actions/project/actions";

import LoggedPage from "../page-templates/loggedAdmin";

import BillingDetailsView from "./billing/_details";

import { Button, notification } from "antd";

const mapStateToProps = (state) => {
  return Object.assign({}, state.bp && state.bp.project, state);
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_project: (data) => {
      dispatch(BP_API.get_project(data));
    },
    updateCommission: (data) => {
      return dispatch(BP_API.update_commission(data));
    }, 
    create_billing_installment: (data) => {
      return dispatch(BP_API.create_billing_installment(data));
    },
    delete_installment: (data) => {
      return dispatch(BP_API.delete_installment(data));
    },
    delete_invoice: (data) => {
      return dispatch(BP_API.delete_invoice(data));
    },
    update_bpfns: (data) => {
      return BP_API.update_bpnfs(data);
    },
    update_billing_installment_invoice_due_date: (data) => {
      return dispatch(BP_API.update_billing_installment_invoice_due_date(data));
    },
    update_billing_installment_invoice_payment_date: (data) => {
      return dispatch(BP_API.update_billing_installment_invoice_payment_date(data));
    }
  };
};

class BillingDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      projectId: props.match.params.projectId,
    };
  }

  componentDidMount() {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_project(data);
  }

  handleNF = async (data) => {
    const dataSend = {
      projectId: this.props.match.params.projectId,
      ...data,
    };

    await this.props.update_bpfns(dataSend).then((response) => {
      if (response?.status == 200) {
        let data2 = {
          projectId: this.props.match.params.projectId,
        };

        notification.success({
          message: I18n.get("Atualizado com sucesso"),
        });

        this.props.get_project(data2);
      }
    });

    return true;
  };

  sendCommission = async (value) => {
    const { billingId, projectId} = this.props.match.params;
    const dataC = {
      bpCommissionAmount: value,
      billingId: billingId,
      projectId: projectId
    };


   const response = await this.props.updateCommission(dataC);

   var data = {
    projectId: this.props.match.params.projectId,
  };

  this.props.get_project(data);

   return response.data;
   
  };

  

  render() {
    const { billingId } = this.props.match.params;

    const { billings = [] } = this.props;

    const billing = billings.find((item) => item.id == billingId);

    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/bpool/faturamento" className="is-black">
                {I18n.get("Faturamento")}
              </NavLink>
            </li>
            <li className="is-active">
              <a href="#">{this.props.projectName}</a>
            </li>
          </ul>
        </nav>

        <br />
        <br />

        <div className="columns">
          <div className="column is-12">
            {billing?.id && (
              <BillingDetailsView
                {...this.props}
                billing={billing}
                handleNF={this.handleNF}
                sendCommission={this.sendCommission}
              />
            )}
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetails);
