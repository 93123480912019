import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { BP_API } from "../../actions/bp/actions";

import LoggedPage from "../page-templates/loggedAdmin";

import { Task } from "../../utils/task";

import styled from "styled-components";

import { CardSimple } from "../../modules/components/Cards";

import FormVipPartner from "../../modules/Admin/components/formPartnersVipsAdmin";
import TablePartner from "../../modules/Admin/components/tablePartnersVipsAdmin";

import { Row, Col, Button, notification, Drawer, Modal, Icon } from "antd";

const { confirm } = Modal;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_partners_vips: (data) => {
      dispatch(BP_API.get_partners_vips());
    },
    create_partners_vips: (data) => {
      return dispatch(BP_API.create_partners_vips(data));
    },
    resend_email_vip: (data) => {
      return dispatch(BP_API.send_email_vip(data));
    },
    reset_user: (data) => {
      dispatch(BP_API.reset_user(data));
    },
  };
};

const NoData = styled.div`
  padding: 50px;
  text-align: center;

  h3 {
    font-weight: 700;
    margin-bottom: 30px;
  }

  i {
    font-size: 40px;
  }

  .txt {
    position: relative;
    left: 30px;
    top: -15px;
  }
`;

class PartnerAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      task: {},
      viewVip: false,
      sendRegister: true,
      errors: {},
      loadingBtn: false,
    };
  }

  handleFilters = (data) => {
    var task = new Task();

    task.start();

    const contentBox = (
      <div>
        <p>
          <b>{I18n.get("Idioma")}:</b> {data.signupLocale}
        </p>
        <p>
          <b>{I18n.get("CNPJ")}:</b> {data.registrationId}
        </p>
        <p>
          <b>{I18n.get("Empresa")}:</b> {data.companyName}
        </p>
        <p>
          <b>{I18n.get("Responsável")}:</b> {data.responsableName}
        </p>
        <p>
          <b>{I18n.get("E-mail")}:</b> {data.responsableEmail}
        </p>
      </div>
    );

    this.setState({ task: task }, function () {
      confirm({
        title: I18n.get("Confirma cadastrar com estes dados:"),
        content: contentBox,
        cancelText: I18n.get("Cancelar"),
        onOk: () => {
          this.props.create_partners_vips(data).then((response) => {
            if (response.success) {
              this.props.get_partners_vips();
              this.setState({
                viewVip: false,
              });
              notification.success({
                message: I18n.get("Adicionado com sucesso!"),
                description: I18n.get("O parceiro foi adicionado com sucesso"),
              });
            } else {
              this.setState({
                task: {},
                errors: response.errors,
              });
            }
          });
        },
        onCancel: () => {
          this.setState({
            task: {},
          });
        },
      });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.bp.task || {};
    const prev = (prevProps && prevProps.bp.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      this.setState({ task: task });
    }

    if (
      this.props.bp.vipList &&
      prevProps.bp.vipList &&
      this.props.bp.vipList.length > prevProps.bp.vipList.length &&
      !this.state.sendRegister
    ) {
      this.setState({ errors: {} });
      this.handleClose();
    }
  }

  componentDidMount() {
    // this.props.reset();
    this.props.get_partners_vips();
  }

  handleClose = () => {
    this.setState({ viewVip: false, sendRegister: true });
    this.setState({ errors: {} });
  };

  handleResend = (data) => {
    this.setState({ loadingBtn: true });
    this.props.resend_email_vip(data).then((response) => {
      if (response.success) {
        notification.success({
          message: I18n.get("E-mail enviado com sucesso!"),
        });
        this.setState({ loadingBtn: false });
      } else {
        notification.error({
          message: I18n.get("Erro ao enviar e-mail!"),
        });
        this.setState({ loadingBtn: false });
      }
    });
  };

  render() {
    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li className="is-active">
              <NavLink to="/bpool/parceiros" className="is-black">
                {I18n.get("Parceiros VIP")}
              </NavLink>
            </li>
          </ul>
        </nav>

        <Row gutter={6}>
          <Col sm={24}>
            <Button
              onClick={() =>
                this.setState({ viewVip: true, sendRegister: false })
              }
              className="button bt-bpool black pull-right"
            >
              {I18n.get("Adicionar parceiro vip")}
            </Button>
          </Col>
        </Row>
        <br />

        <Row gutter={6}>
          <Col sm={24}>
            <CardSimple>
              <TablePartner
                data={this.props.bp.vipList && this.props.bp.vipList}
                {...this.props}
                resend={this.handleResend}
                loadingBtn={this.state.loadingBtn}
                get={this.props.get_partners_vips}
              />
            </CardSimple>
          </Col>
        </Row>

        <Drawer
          title={I18n.get("Adicionar parceiro vip")}
          placement="right"
          closable={true}
          onClose={() => this.handleClose()}
          destroyOnClose={true}
          visible={this.state.viewVip}
          width="50%"
        >
          <FormVipPartner
            action={this.handleFilters}
            task={this.state.task}
            errors={this.state.errors}
          />
        </Drawer>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerAdmin);
