import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1fr;
  column-gap: 16px;
`;

export const WrapperRangePicker = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .ant-calendar-picker-input.ant-input {
    border-radius: 5px !important;
    height: 50px;
    background-color: #fff !important;
    border: 1px solid #999;

    input {
      font-size: 14px;
      color: #515152;

      ::placeholder {
        font-size: 14px;
        color: #515152;
        opacity: 1; /* Firefox */
      }
    }
  }
  .ant-calendar-range-picker-separator {
    vertical-align: middle;
    position: relative;
    top: 14px;
    font-weight: 600;
    font-size: 14px;
    color: #515152;
  }
`;

export const CheckboxProjects = styled.div`
  display: flex;
  flex-direction: column;

  .ant-radio-group {
    display: flex;
    flex-direction: row;

    label {
      margin: 0px 8px 0px 0px;

      .ant-checkbox-inner {
        border-radius: 4px;
      }
    }
  }

  strong {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
  }

  .ant-radio-wrapper-checked {
    .ant-radio-checked {
      .ant-radio-inner {
        :after {
          top: 4px;
          left: 4px;
        }
      }
    }
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-input {
    margin-top: 0;
    height: 50px;
    margin-bottom: 30px;

    li {
      height: 100%;
    }
  }
`;
