import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import Chart from "react-google-charts";

import { scrollToTop } from '../../../utils/ui';

import moment from 'moment';
import { resolveOnChange } from 'antd/lib/input/Input';

class ScheduleChart extends React.Component {
    constructor(props) {

        super(props);

        const { bPitch = {} } = props;

        this.state = {
            fields: {},
            errors: {},
            form: { ...bPitch },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }

            });

            this.setState(nextState);
        }
    }

    render() {

        const { auth = {}, bPitch = {} } = this.props;

        const { locale = 'pt' } = auth;

        const { schedule = [] } = bPitch;

        const items = [];

        schedule.map((item) => {

            if (!item.code) return;

            if (item.scheduleType == 'PERIOD') {

                const startDate = moment(item.startDate, "DD/MM/YYYY");
                const endDate = moment(item.endDate, "DD/MM/YYYY");

                const record = [
                    // item.code,
                    item.label,
                    startDate.toDate(),
                    endDate.toDate(),
                    // null,
                    // 0,
                    // null
                ];

                items.push(record);
            }
            else {

                const scheduleDate = moment(item.scheduleDate, "DD/MM/YYYY");

                const record = [
                    // item.code,
                    item.label,
                    scheduleDate.toDate(),
                    scheduleDate.add('days', 1).toDate(),
                    // null,
                    // 0,
                    // null
                ];

                items.push(record);
            }

        });

        const sorted = items.sort((a, b) => (a[1] > b[1]) - (a[1] < b[1]));

        return (
            <div>


                {/* <p>{I18n.get("TIMELINE")}</p>

                <br /> */}

                {items && items.length == 0 && <>

                    <p style={{ fontSize: '80%', color: '#696969' }}>{I18n.get("Nenhuma informação para exibir.")}</p>

                </>}

                {items && items.length > 0 && <>

                    <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="Timeline"
                        loader={<div>{I18n.get("Loading Chart")}</div>}
                        chartLanguage={locale}
                        data={[
                            [
                                // { type: 'string', label: 'Task ID' },
                                { type: 'string', label: I18n.get("Task Name") },
                                { type: 'date', label: I18n.get("Start Date") },
                                { type: 'date', label: I18n.get("End Date") },
                                // { type: 'number', label: 'Duration' },
                                // { type: 'number', label: 'Percent Complete' },
                                // { type: 'string', label: 'Dependencies' }
                            ],
                            ...sorted
                        ]}
                        options={{
                            //height: items.length * 60,
                            gantt: {
                                //trackHeight: 30,
                            },
                            colors: [
                                '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980',
                                '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980', '#ffc980'
                            ],
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    />

                </>}

            </div>
        );
    }
}

export default ScheduleChart;
