import React from 'react';

import { I18n } from 'aws-amplify';

import numeral from 'numeral';


import './quotation-item-form.css';

import FormatCurrency from '../../ui/format-currency';

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';
import FileUploadList from '../../../modules/components/FileUploadButton';


import CommentForm from './quotation-item-comment-form';
import StatusForm from './quotation-item-status-form';

import EventQuotationItemStatusIcon from '../../shared/event-quotation-item-status';

import TextField from '../../form/text-field';
import NumericField from '../../form/numeric-field';
import MoneyField from '../../form/money-field';
import PercentageField from '../../form/percentage-field';
import SelectField from '../../form/select-field';
import RadioMultiField from '../../form/radio-multi-field';
import UploadField from '../../form/upload-field';
import SelectSearchField from '../../form/select-search-field';

import { ProjectExtraType } from '../../../model';


class QuotationItemForm extends React.Component {

    constructor(props) {

        super();

        let itemType = '';
        let itemTypeTooltip = null;

        if (props.isExtraInternalCosts) {

            itemType = 'INT';
            itemTypeTooltip = I18n.get("Item INTERNO selecionado, caso seja um serviço externo da sua empresa, inicie o o processo abrindo o box 'Custos Externos'.");
        }
        if (props.isExtraExternalCosts) {

            itemType = 'EXT';
            itemTypeTooltip = I18n.get("Item EXTERNO selecionado, caso seja um serviço interno da sua empresa, inicie o o processo abrindo o box 'Custos Internos'.");
        }

        this.state = {
            fields: {
                itemType: {
                    label: I18n.get("Tipo"),
                    tooltip: itemTypeTooltip,
                    onChange: this.onChangeForm.bind(this, 'itemType'),
                    successIcon: false,
                    visited: true,
                },
                // segment: {
                //     label: I18n.get("Segmento"),
                //     value: '',
                //     onChange: this.onChangeFormValue.bind(this, 'segment'),
                //     successIcon: false,
                //     visited: true,
                // },
                // category: {
                //     label: I18n.get("Categoria"),
                //     value: '',
                //     onChange: this.onChangeFormValue.bind(this, 'category'),
                //     successIcon: false,
                //     visited: true,
                // },
                description: {
                    label: I18n.get("Descrição"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'description'),
                    successIcon: false,
                    visited: true,
                },
                quantity: {
                    label: I18n.get("Quantidade"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'quantity'),
                    successIcon: false,
                    visited: true,
                },
                // recurrence: {
                //     label: I18n.get("Período"),
                //     value: '',
                //     onChange: this.onChangeForm.bind(this, 'recurrence'),
                //     successIcon: false,
                //     visited: true,
                // },
                price: {
                    label: I18n.get("Valor unitário"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'price'),
                    successIcon: false,
                    visited: true,
                },
                doubleTaxation: {
                    label: I18n.get("Bitributação"),
                    tooltip: I18n.get("Selecione 'Não' se o fornecedor for faturar direto. Se sua empresa precisar adiantar o pagamento ao fornecedor, por exemplo, selecione 'Sim'. Isso irá acarretar em bitributação."),
                    value: true,
                    onChange: this.onChangeForm.bind(this, 'doubleTaxation'),
                    successIcon: false,
                    visited: true,
                    values: [{ code: true, label: I18n.get("Sim") }, { code: false, label: I18n.get("Não") }],
                    isHorizontal: true
                },
                tax: {
                    label: I18n.get("Impostos"),
                    tooltip: I18n.get("Insira aqui o % de impostos que sua empresa pagará ao bitributar este item. Se tiver dúvida sobre isso, consulte seu Financeiro."),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'tax'),
                    successIcon: false,
                    visited: true,
                },
                file: {
                    label: I18n.get("Upload de orçamento"),
                    value: 'Selecione...',
                    onChange: this.onUpload.bind(this),
                    visited: true,
                }
            },
            file: {},
            form: {
                itemType: itemType
            },
            errors: {},
            task: {},
            upload_task: {},
            itemComment: null,
            itemStatus: null
        };

        this.addItem = this.onAddItem.bind(this);
        this.delItem = this.onDelItem.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);

            nextState.file = {};
        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormValue(prop, value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            quotationId: this.props.quotation.id,
            ...this.state.form
        };

        data.file = this.state.file;

        return data;
    }

    onAddItem(e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        const data = this.getData();

        this.props.add_quotation_item(data)
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

                if (task.result) {

                    const { itemType } = data;

                    this.setState({ form: { itemType: itemType } });

                    this.props.closeModal();
                }

            });
    }

    onDelItem(item, e) {

        e.preventDefault();

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            quotationId: this.props.quotation.id,
            ...item
        };

        this.props.del_quotation_item(data);
    }

    onUpload(e) {

        var file = e.target.files[0];

        if (!file) return;


        if (e) e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            var upload_task = new Task();

            upload_task.start();

            this.setState({ upload_task: upload_task }, function () {

                const data = {
                    projectId: this.props.match.params.projectId,
                    extraId: this.props.match.params.extraId,
                    quotationId: this.props.quotation.id,
                    file_type: 'item',
                    name: file.name,
                    base64: e.target.result
                };

                this.props.upload_quotation_file(data)
                    .then(response => {

                        this.setState((prevState) => {

                            var nextState = Object.assign({}, prevState);

                            nextState.file = response.data;
                            nextState.form.file = file.name;
                            nextState.upload_task = { result: true };

                            return nextState;
                        });

                    })
                    .catch(error => {

                        this.setState({ upload_task: { result: false } });

                    });
            });
        };
        reader.readAsDataURL(file);
    }

    delFile(item) {

        var data = {
            projectId: this.props.match.params.projectId,
            extraId: this.props.match.params.extraId,
            quotationId: this.props.quotation.id,
            ...item
        };

        this.setState({ file: {} }, function () {
            this.props.delete_quotation_file(data);
        });

    }

    onDrop(files) {

        files = files.map(file => {

            this.upload(file);
        });
    }

    onCancel() {

    }

    getItemType(value) {

        return (this.props.bp.eventQuotationItemType && this.props.bp.eventQuotationItemType.find((item) => item.code == value)) || {};
    }

    getItemCategory(value) {

        return (this.props.bp.eventSegments && this.props.bp.eventSegments.find((item) => item.code == value)) || {};
    }

    getDataUploadMult = (files) => {

        const { projectId, extraId } = this.props.match.params;

        let file = files[0];

        delete file['uid'];

        // const data = {

        //     name: file.name,
        //     base64: e.target.result
        // };


        const data = {
            // ...this.props.project,
            id: file.id,
            name: file.name,
            size: file.size,
            type: file.type,
            uploadedByName: file.uploadedByName,
            uploadedByUsername: file.uploadedByUsername,
            uploadedDate: file.uploadedDate,
            url: file.url
        }

        this.setState({
            file: data
        })
    }

    getTotal() {

        let { quantity, price } = this.state.form;

        if (quantity && price) {

            price = price.replace(/[^\d]/g, '');

            const total = numeral(quantity).value() * (numeral(price).value() / 100);

            return total;
        }
        else {
            return 0;
        }
    }


    render() {

        const { project = {} } = this.props;

        const quotation = this.props.quotation || {};
        const currency = this.props.currency || {};

        let { eventQuotationItemType = [] } = this.props.bp;

        if (this.props.isExtraInternalCosts) {

            eventQuotationItemType = eventQuotationItemType.filter((item) => item.code == 'INT');
        }
        if (this.props.isExtraExternalCosts) {

            eventQuotationItemType = eventQuotationItemType.filter((item) => item.code == 'EXT');
        }

        let filesNormalize = this.props.files && this.props.files.map((item) => {
            return {
                ...item,
                uid: item.id,
            }
        })

        return (

            <section className="quotation-item-form">

                <div>

                    <p className="title-bpool medium">{I18n.get("Adicionar Item")}</p>

                    <div style={{ spadding: '1rem' }}>

                        <div className="columns">

                            <div className="column is-4">
                                <SelectField {...this.state.fields.itemType} value={this.state.form.itemType} error={this.state.errors.itemType} values={eventQuotationItemType} />
                            </div>

                        </div>

                        <div className="columns">

                            <div className="column is-4">
                                <TextField {...this.state.fields.description} value={this.state.form.description} error={this.state.errors.description} />
                            </div>

                            <div className="column is-4">
                                <NumericField {...this.state.fields.quantity} value={this.state.form.quantity} error={this.state.errors.quantity} />
                            </div>
                            <div className="column is-3">
                                <MoneyField 
                                    {...this.state.fields.price}
                                    currency={currency}
                                    value={this.state.form.price}
                                    error={this.state.errors.price} 
                                    />
                            </div>

                        </div>

                        <div className="columns">


                            <div className="column is-4">

                                <MoneyField
                                    label={I18n.get('Total')}
                                    value={this.getTotal()}
                                    readOnly={true}
                                    currency={currency}
                                />

                            </div>

                            <div className="column is-4">

                                <br />

                                <FileUploadList
                                    getDataUpload={this.getDataUploadMult}
                                    name="files"
                                    uploadId={this.props.project.projectId}
                                    get2={this.props.get2 || false}
                                    fileListGet={filesNormalize || []}
                                    disabledList={true}
                                    limit={1}
                                    output="object"
                                />

                                {this.props.errors['file.id'] && <p className="help is-danger">
                                    {this.props.errors['file.id'].errorMessage}
                                </p>}

                            </div>

                            {!this.props.isExtraInternalCosts && <>

                                <div className="column is-4">

                                    <RadioMultiField {...this.state.fields.doubleTaxation} value={this.state.form.doubleTaxation} error={this.state.errors.doubleTaxation} />
                                </div>

                            </>}

                        </div>

                    </div>

                    <br />

                    <button
                        type="button"
                        task={this.state.task}
                        onClick={this.addItem}
                        className="button bt-bpool pull-right"
                        disabled={this.state.upload_task.busy}>{I18n.get("Adicionar")}</button>

                    <br className="is-clearfix" />

                    <br /><br />

                </div>

            </section>
        );
    }
}

export default QuotationItemForm;
