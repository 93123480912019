import styled from 'styled-components';

export const Wrapper = styled.button`
  cursor: pointer;
  visibility: visible;
  z-index: 99;
  border: none;
  position: fixed;
  right: 245px;
  top: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #1883ff;
  color: #ffffff;
`;
