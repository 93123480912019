import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import { PARTNER_API } from '../../actions/partner/actions';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import Steps from './steps';

import TextAreaField from '../form/textarea-field';
import SubmitButtonField from '../form/submit-button-field';

import NakedModal from '../ui/naked-modal';
import Highlight from '../ui/highlight';

import QuestionItem from '../shared/question-item';

const mapStateToProps = state => {
    return Object.assign({}, state, state.partner);
};

const mapDispatchToProps = dispatch => {
    return {
        get_partner: data => {
            return dispatch(PARTNER_API.get(data));
        },
        update_invitation: data => {
            return dispatch(PARTNER_API.update_invitation(data));
        },
    }
}

class ProjectDefintionStep3 extends React.Component {
    constructor(props) {
        super();
        this.state = {
            fields: {
                body: {
                    label: I18n.get("Digite a pergunta"),
                    onChange: this.onChangeForm.bind(this, 'body'),
                    successIcon: false,
                    visited: true,
                },
                answer: {
                    label: I18n.get("Digite a resposta"),
                    onChange: this.onChangeForm.bind(this, 'answer'),
                    successIcon: false,
                    visited: true
                }
            },
            form: {},
            errors: {},
            task: {},
            declineIsOpen: false
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ this: this.props, prev: prevProps });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                if (FormUtils.isFormField(nextState.form[item])) {
                    nextState.form[item].error = this.props.errors[item];
                    nextState.form[item].value = this.props[item] || '';
                }
                else if (typeof nextState.form !== 'undefined' && typeof this.props[item] !== 'undefined') {
                    nextState.form[item] = this.props[item];
                }
            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormCheckbox(prop, e) {

        const checked = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = checked;
            nextState.errors[prop] = null;

            return nextState;
        });
    }


    onCreateQuestion(e) {

        e.preventDefault();

        var data = {
            projectId: this.props.match.params.projectId,
            body: this.state.form.body,
        };

        var submit_task = new Task();

        submit_task.start();

        this.setState({ task: submit_task });

        this.props.createQuestion(data)
            .then(({ task, errors }) => {

                this.props.get_partner();

                if (task.result) {

                    this.setState({ task: task, errors: errors, questionIsOpen: false });
                }
                else {
                    this.setState({ task: task, errors: errors });
                }

            });

    }


    onDeleteQuestion(id, e) {

        e.preventDefault();

        var data = {
            projectId: this.props.match.params.projectId,
            id: id
        };

        var submit_task = new Task();

        submit_task.start();

        this.setState({ task: submit_task });

        this.props.deleteQuestion(data)
            .then(({ task, errors }) => {

                this.props.get_partner();

                if (task.result) {

                    this.setState({ task: task, errors: errors, questionIsOpen: false });
                }
                else {
                    this.setState({ task: task, errors: errors });
                }

            });

    }

    render() {

        const { partner = {}, project = {}, settings = {} } = this.props;

        const invitation = (partner.projectInvitations && partner.projectInvitations.find((item) => item.projectId == this.props.match.params.projectId)) || {};

        const isSelectedPartner = project.selectPartner && project.selectPartnerId == partner.partnerId;

        const disableQuotation = invitation.expired === true || invitation.accepted === false || isSelectedPartner;

        const disableMessaging = settings && !settings.enableMessaging;

        const pendingQuestions = partner.pendingQuestions && partner.pendingQuestions.filter((item) => item.projectId == this.props.match.params.projectId);

        return (
            <FillForm>
                <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-6">
                                <p className="title">{I18n.get("Parceiro Convidado")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-6">
                                <Steps active={2} projectId={this.props.match.params.projectId} disabled={disableQuotation ? [3] : []} hidden={disableMessaging ? [5] : []} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1" style={{ backgroundColor: '#F7F7F7' }}>

                    <div className="container client-container-1">

                        <br />
                        <br />

                        <div className="columns">

                            <div className="column is-12">

                                <p className="title">{I18n.get("Perguntas & Respostas")}</p>
                                <Highlight
                                    title={I18n.get('Dica')}
                                    description={I18n.get('Durante o período de proposta você poderá tirar dúvidas com o cliente e/ou BPool utilizando essa área. As perguntas serão respondidas para todos os participantes.')}
                                />

                                <br /><br /><br />


                                {this.props.questions && this.props.questions.map((question, index) => {
                                    return (<QuestionItem question={question} />);
                                })}


                                <br />

                                <h1>{I18n.get("Minhas Perguntas")}</h1>

                                <br />

                                {pendingQuestions && pendingQuestions.map((question, index) => {

                                    return (<QuestionItem question={question} onDeleteQuestion={this.onDeleteQuestion.bind(this, question.id)} />);

                                })}

                                <br />

                                <SubmitButtonField
                                    label={I18n.get("Fazer uma pergunta")}
                                    className="button bt-bpool black"
                                    onClick={(e) => this.setState({ questionIsOpen: true })}
                                    task={this.state.task}
                                />

                            </div>
                        </div>

                        <br />

                    </div>

                    <br /><br /><br /><br />

                </div>

                <NakedModal isOpen={this.state.questionIsOpen}>

                    <div className="card" style={{ margin: '0.5rem' }}>
                        <header className="card-header">
                            <p className="card-header-title">{I18n.get("Pergunta")}</p>
                            <a href="#" onClick={(e) => { e.preventDefault(); this.setState({ questionIsOpen: false }) }} className="card-header-icon" aria-label="more options">
                                <span className="icon">
                                    <i className="fas fa-times" aria-hidden="true"></i>
                                </span>
                            </a>
                        </header>
                        <div className="card-content" style={{ maxWidth: '960px' }}>
                            <div className="content has-text-left">

                                <form>

                                    <br />

                                    <TextAreaField {...this.state.fields.body} value={this.state.form.body} error={this.state.errors.body} />

                                    <br />
                                    <br />

                                    <div style={{ fontSize: '80%', fontWeight: 'bold' }}>{I18n.get("Você será notificado quando o Cliente responder à sua pergunta e então ela entrará na lista pública de perguntas e respostas.")}</div>

                                    <br />
                                    <br />

                                    <div className="columns">
                                        <div className="column is-half">
                                        </div>
                                        <div className="column is-half">
                                            <SubmitButtonField
                                                className="button is-black is-fullwidth next"
                                                label={I18n.get("Enviar")}
                                                onClick={this.onCreateQuestion.bind(this)}
                                                task={this.state.task}
                                                suppressLabel={true}
                                            />
                                        </div>
                                    </div>

                                </form>


                            </div>
                        </div>
                    </div>

                </NakedModal>

            </FillForm>
        );
    }
}

export default ProjectContainer(connect(mapStateToProps, mapDispatchToProps)(ProjectDefintionStep3));
