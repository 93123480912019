import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TooltipField from '../form/tooltip-field';


const mapStateToProps = state => {
    return state;
};
const mapDispatchToProps = dispatch => {
    return {}
};

class Steps extends React.Component {
    constructor(props) {
        super();
        this.state = {
            items: [
                {
                    index: 1,
                    title: I18n.get("Sobre o Responsável"),
                    color: "#F0E2A1",
                    link: "/cliente/passo-1"
                },
                {
                    index: 2,
                    title: I18n.get("Sobre a Empresa"),
                    color: "#CBEDDF",
                    link: "/cliente/passo-2"
                },
                {
                    index: 3,
                    title: I18n.get("Marcas e Produtos/Serviços"),
                    color: "#FFC5B9",
                    link: "/cliente/passo-3"
                },
                {
                    index: 4,
                    title: I18n.get("Marcas & Usuários"),
                    color: "#A8D7EB",
                    link: "/cliente/passo-4"
                },
                {
                    index: 5,
                    title: I18n.get("Acesso Master"),
                    color: "#BAB1D0",
                    link: "/cliente/passo-5"
                },
            ]
        };
    }

    render() {
        const { items } = this.state
        const { active } = this.props



        let name = '';

        if (this.props.client && this.props.client.nameAlias) {

            name = this.props.client.nameAlias;
        }
        else if (this.props.client && this.props.client.name) {

            name = this.props.client && this.props.client.name;

            let parts = name.split(' ');

            if (parts.length > 0) {
                name = parts[0];
            }
        }

        let txt = '';

        return (
            <div>
            <ul id="partner-signup-steps">
                {items.map(function(item) {

            switch(active) {
                case 1:
                    txt = I18n.get("Ao preencher este cadastro, você será o responsável principal da sua empresa em nossa plataforma. Nos próximos passos, poderá adicionar as pessoas da empresa que fazem parte do processo de contratação de parceiros criativos e desenvolvimento de projetos.");
                    break;
                case 2:
                    txt = I18n.get('Queremos saber mais sobre sua empresa para nosso algoritimo buscar o melhor parceiro. Não se preocupe. Essas informações ficam só aqui, são totalmente confidenciais.');
                    break;
                case 3:
                    txt = I18n.get('Você poderá cadastrar todas marcas que deseja usar na plataforma e, sempre que necessário, poderá acrescentar novas marcas através do seu login na BPool.');
                    break;
                case 4:
                    txt = I18n.get('Chegou a hora de cadastrar as marcas e os usuários responsáveis que vão fazer parte da nossa plataforma. =]');
                    break;
                case 5:
                    txt = '';
                    break;
                default:
                    txt = '';
            }

                    const lineStyle = {
                        background: item.color,
                    };
                    return (<li key={item.index} className={(active && item.index === active && "progress-bar--item is-active") || "progress-bar--item"}>
                        <h3>{item.index}
                        {active && item.index === active && <TooltipField tooltip={txt} orientation="bottom" canClose={true} withoutquestionmark="true" isfixed="true" />}
                        </h3>
                        <Link to={item.link} className="progress-bar--menu-item">
                            <span style={lineStyle}></span>
                            <strong className="is-not-mobile">{item.title}</strong>
                        </Link>
                    </li>)
                })}
            </ul>
        </div>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
