import React, { useEffect, useState } from "react";
import { Row, Col, message, Modal } from "antd";
import { I18n } from "aws-amplify";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useProjects } from "../../../../hooks/useProjects/useProjects.hook";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import Main from "../../../../components/UI/Main";
import Content from "../../../../components/UI/Content";
import { H2Border } from "../../../../components/UI/Titles";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { Textarea } from "../../../../components/UI/Textarea";
import Checkbox from "../../../../components/UI/Checkbox";
import { SelectOne } from "../../../../components/UI/SelectOne";

import * as S from "./styles";
import { CreativeScope } from "./modules/CreativeScope";
import { GeneralDemand } from "./modules/GeneralDemand";
import { Informations } from "./modules/Informations";
import { Details } from "./modules/Details";
import IcoAccepted from "../components/ItemInvitation/imgs/ico-accepted.svg";
import IcoDeclined from "../components/ItemInvitation/imgs/ico-declined.svg";
import IcoNotChosen from "../components/ItemInvitation/imgs/ico-not-chosen.svg";
import FormTerms from "./_form-terms";

import { ConvertValuesCreativeScope } from "./utils/convertValuesCreativeScope";

import ModalPdf from "../../../../../modules/components/ModalPdf";

import { PARTNER_API } from "../../../../../actions/partner/actions";

export const ProjectInvitationDetails = () => {
  let params = useParams();
  const auth = useSelector((state) => state.auth);
  const state = useSelector((state) => state);
  const partner = useSelector((state) => state.partner);
  const dispatch = useDispatch();

  const {
    getPartnerProjectById,
    getPartnerInvites,
    getUsersPartnerInvites,
    updateInviteAcceptOrNot,
  } = useProjects();

  const [isLoading, setIsLoading] = useState(false);
  const [contactInformation, setContactInformation] = useState({});
  const [details, setDetails] = useState({});
  const [latamMsg, setLatamMsg] = useState({});
  const [generalDemand, setGeneralDemand] = useState(null);
  const [creativeScope, setCreativeScope] = useState({});
  const [listOfAssets, setListOfAssets] = useState([]);
  const [invitationData, setInvitationData] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [userSelect, setUserSelect] = useState(null);
  const [acceptCheckbox, setAcceptCheckbox] = useState(false);
  const [dataValuesCreativeScope, setDataValuesCreativeScope] = useState({});
  const [reason, setReason] = useState("");
  const [openModalReason, setOpenModalReason] = useState(false);
  const [isLoadingSaving, setIsLoadingSaving] = useState(false);
  const [errorUserSelect, setErrorUserSelect] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [disabledButtonSend, setDisabledButtonSend] = useState(true);
  const [hasConfirmedAlready, setHasConfirmedAlready] = useState(null);
  const [currencyProject, setCurrencyProject] = useState({});

  const disableButtonModal = reason?.length <= 0;

  const loadUsers = async () => {
    const responseListUsers = await getUsersPartnerInvites(auth?.partnerId);

    if (responseListUsers?.success) {
      const list = responseListUsers?.data?.map((item) => ({
        code: item?.username,
        label: item?.name,
      }));
      setUsersList(list);
    }
  };

  const loadInit = async () => {
    setIsLoading(true);
    const response = await getPartnerProjectById({
      projectId: params.projectId,
    });
    const responseInvitations = await getPartnerInvites("");

    const invitation =
      (responseInvitations?.success &&
        responseInvitations?.data?.filter(
          (item) => item.projectId === params.projectId
        )[0]) ||
      {};

    setInvitationData(invitation);

    if (response?.status === 200) {
      setContactInformation({
        projectName: response?.data?.projectName,
        projectSKU: response?.data?.projectSKU,
        projectType: response?.data?.projectType,
        product: `${response?.data?.brandName} - ${response?.data?.productName}`,
        indemnityInvite: response?.data?.indemnityInvite,
        indemnityAgreements: response?.data?.indemnityAgreements,
      });

      setGeneralDemand({
        demand: response?.data?.demand,
        demandFiles: response?.data?.demandFiles,
      });
      setCreativeScope(response?.data?.bundle);
      setListOfAssets(response?.data?.bundle?.variations[0]?.assets);
      setDetails({
        estimatedEndDate: response?.data?.estimatedEndDate,
        estimatedStartDate: response?.data?.estimatedStartDate,
        estimatedDecisionInWeeks: `${
          response?.data?.estimatedDecisionInWeeks
        } ${I18n.get("semana(s)")}`,
        paymentTermDays: `${response?.data?.paymentTermDays} ${I18n.get(
          "dias corridos"
        )}`,
        nonCompeteInDays: response?.data?.nonCompeteInDays,
        isInvite: response?.data?.isInvite,
        statusInvite: responseInvitations?.data?.find(
          (item) => item?.projectId === params.projectId
        )?.statusInvite,
        additionalRule: response?.data?.additionalRule,
        globalAuthorization: response?.data?.globalAuthorization,
        descriptiveHourMan: response?.data?.descriptiveHourMan,
      });

      setLatamMsg({
        clientBillingCountry: response?.data?.clientBillingCountry, // TODO: remove default value
      });

      const objDataValuesCreativeScope = ConvertValuesCreativeScope({
        data: response?.data,
      });

      setDataValuesCreativeScope(objDataValuesCreativeScope);
      setCurrencyProject(response?.data?.currency);
    } else {
      message.error(I18n.get("Error loading project data"));
    }
    setIsLoading(false);
  };

  const onChangeUserSelect = (user) => {
    if (user) {
      setErrorUserSelect("");
    }
    setUserSelect(user);
  };

  const handleChangeCheckbox = (e) => {
    setAcceptCheckbox(e.target.checked);
  };

  const participateOrNot = async (participate) => {
    if (!participate) {
      setOpenModalReason(true);
    } else {
      if (!userSelect) {
        setErrorUserSelect(I18n.get("Please select a Responsible user"));
      } else {
        setErrorUserSelect("");
        setIsSending(true);
        const objSend = {
          partnerId: auth?.partnerId,
          projectId: params?.projectId,
          isNonCompeteAware: acceptCheckbox,
          accepted: participate,
          reason: "",
          partnerUsername: userSelect,
          isQuotationDeadlineAware: true,
        };
        const response = await updateInviteAcceptOrNot(objSend);
        if (response?.success) {
          setUserSelect(null);
          setReason("");
          loadInit();
        } else {
          message.error(I18n.get("Error saving data"));
        }

        setIsSending(false);
      }
    }
  };

  const getMessage = (country) => {
    var txt = I18n.get("");

    switch (country) {
      case "ARG":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura electrónica de Monotributista por monto neto o de Responsable inscripto, por monto neto del proyecto más iva."
        );
        break;
      case "CHL":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura de exportación por monto neto del proyecto SIN IVA."
        );
        break;
      case "COL":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura electrónica de empresas solo COLOMBIANAS."
        );
        break;
      case "CRI":
        txt = I18n.get("Tipo de factura solicitada: Factura SIN IVA.");
        break;
      case "ECU":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura electrónica de empresas solo ECUATORIANAS."
        );
        break;
      case "MEX":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura electrónicas de sociedades mexicanas. Agencias fuera de México deben emitir factura de exportación sin IVA."
        );
        break;
      case "PAN":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura de exportación por monto neto del proyecto SIN IVA."
        );
        break;
      case "URY":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura  electrónica de sociedades uruguayas."
        );
        break;
      case "PRT":
        txt = I18n.get(
          "Tipo de factura solicitada: Factura de exportación por monto neto del proyecto SIN IVA."
        );
        break;
    }

    return txt;
  };

  const handleOkModalReason = async () => {
    setIsLoadingSaving(true);
    const objSend = {
      partnerId: auth?.partnerId,
      projectId: params?.projectId,
      isNonCompeteAware: acceptCheckbox,
      accepted: false,
      reason,
      partnerUsername: userSelect,
      isQuotationDeadlineAware: true,
    };
    const response = await updateInviteAcceptOrNot(objSend);

    if (response?.success) {
      setUserSelect(null);
      setReason("");
      setOpenModalReason(false);
      loadInit();
      // window.location.reload();
    } else {
      message.error(I18n.get("Error saving data"));
    }

    setOpenModalReason(false);
    setIsLoadingSaving(false);
  };

  const onChangeReason = (value) => {
    setReason(value);
  };

  const handleConvertedValuesCreativeScope = async ({ values, currency }) => {
    const data = {
      ...values,
      currency,
    };
    const objDataValuesCreativeScope = ConvertValuesCreativeScope({
      data,
      isInvite: true,
    });
    setDataValuesCreativeScope(objDataValuesCreativeScope);

    const listAssetsUpdated = listOfAssets.map((item1) => {
      const matchingItem = values?.bundle?.variationResponse?.assets?.find(
        (item2) => item2.code === item1.code
      );

      if (matchingItem) {
        return {
          ...item1,
          priceFmt: matchingItem.priceFmt,
          priceTotalFmt: matchingItem.priceTotalFmt,
        };
      }

      return item1;
    });
    setListOfAssets(listAssetsUpdated);
  };

  const handleConfirm = () => {
    var data = {
      projectId: params.projectId,
      partnerId: auth.partnerId,
    };

    dispatch(PARTNER_API.get_indemnity(data));

    loadInit();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  useEffect(() => {
    if (auth?.partnerId) {
      loadUsers();
    }
  }, [auth]);

  useEffect(() => {
    if (userSelect && acceptCheckbox) {
      setDisabledButtonSend(false);
    } else {
      setDisabledButtonSend(true);
    }
  }, [userSelect, acceptCheckbox]);

  useEffect(() => {
    if (
      contactInformation &&
      Array.isArray(contactInformation.indemnityAgreements)
    ) {
      let h =
        contactInformation.indemnityAgreements.some(
          (p) => p.partnerId === auth?.partnerId
        ) || false;

      setHasConfirmedAlready(h);
    }
  }, [contactInformation, auth?.partnerId]);

  return (
    <>
      <LoggedPage>
        <Content>
          <div className="container">
            <Row>
              <Col sm={24} xs={24}>
                <H2Border>{I18n.get("Invitation details")}</H2Border>
              </Col>
            </Row>
            <Main bgColor="#fff" padding="30px">
              <Row>
                <Col sm={12} xs={12}>
                  <Informations
                    isLoading={isLoading}
                    data={contactInformation}
                  />
                </Col>
                <Col sm={12} xs={12}>
                  <GeneralDemand isLoading={isLoading} data={generalDemand} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={12} style={{ marginTop: 30 }}>
                  <CreativeScope
                    isLoading={isLoading}
                    listOfAssets={listOfAssets}
                    dataValues={dataValuesCreativeScope}
                    isInvite={true}
                    convertedValues={handleConvertedValuesCreativeScope}
                    reload={loadInit}
                    data={creativeScope}
                    descriptiveHourMan={details?.descriptiveHourMan}
                    disabledEdit={true}
                    currencyProject={currencyProject}
                    showTooltipOtherTax
                  />
                </Col>
                <Col sm={12} xs={12} style={{ marginTop: 30 }}>
                  <Details isLoading={isLoading} data={details} />

                  {partner?.country?.bpRegion === "LATAM" &&
                    latamMsg?.clientBillingCountry && (
                      <S.MsgLatam>
                        <p>{getMessage(latamMsg?.clientBillingCountry)}</p>
                      </S.MsgLatam>
                    )}
                </Col>
              </Row>

              <Row>
                <Col sm={12} xs={12} style={{ marginTop: 30 }}>
                  <S.StatusOrSendStatus>
                    {invitationData?.accepted === true ? (
                      <S.MessageStatus>
                        <img src={IcoAccepted} alt="Ico Accepted" />
                        <span>{I18n.get("You accepted this invitation.")}</span>
                      </S.MessageStatus>
                    ) : null}
                    {invitationData?.accepted === false ? (
                      <S.MessageStatus>
                        <img src={IcoDeclined} alt="Ico Declined" />
                        <span>{I18n.get("You declined this invitation.")}</span>
                      </S.MessageStatus>
                    ) : null}
                    {invitationData?.canWithdraw &&
                      invitationData?.status != 9 &&
                      invitationData?.status != 4 && (
                        <S.StatusInvite>
                          <S.MessageStatus>
                            <img src={IcoNotChosen} alt="Ico Not Chosen" />
                            <span>
                              {I18n.get(
                                "Deadline for choosing a Partner has expired"
                              )}
                            </span>
                          </S.MessageStatus>
                          <S.SubMessageStatus>
                            {I18n.get(
                              "From now on, you have the option to withdraw from the process if you wish."
                            )}
                          </S.SubMessageStatus>
                        </S.StatusInvite>
                      )}
                    {invitationData?.withdrawn === true ? (
                      <S.MessageStatus>
                        <img src={IcoDeclined} alt="Ico Declined" />
                        <span>
                          {I18n.get("You withdrew from this selection in")}{" "}
                          {invitationData?.withdrawnOn}
                        </span>
                      </S.MessageStatus>
                    ) : null}

                    {invitationData?.actionRequired === true ? (
                      <div>
                        <S.ContentCheckbox>
                          <Checkbox
                            onChangeCb={(e) => handleChangeCheckbox(e)}
                            checked={acceptCheckbox}
                            checkboxSize={18}
                          />
                          <S.LabelCheckbox>
                            {I18n.get(
                              "By accepting this project, you will automatically commit to a NON-COMPETE DURING THE PROJECT in the"
                            )}{" "}
                            {invitationData?.categoryName}
                          </S.LabelCheckbox>
                        </S.ContentCheckbox>
                        <div style={{ marginTop: 25 }}>
                          <SelectOne
                            options={usersList}
                            value={userSelect}
                            onChange={(value) => onChangeUserSelect(value)}
                            placeholder={I18n.get("Responsible User")}
                            disabled={usersList?.length === 0}
                            error={errorUserSelect}
                          />
                        </div>
                      </div>
                    ) : null}
                  </S.StatusOrSendStatus>

                  {isLoading ? null : invitationData?.accepted ===
                    true ? null : (
                    <S.ButtonsContainer>
                      <ButtonBpool
                        text={I18n.get("I do not accept")}
                        theme="secondary"
                        onClick={() => participateOrNot(false)}
                        loading={isSending}
                      />
                      <ButtonBpool
                        text={I18n.get(
                          "I agree to participate in the selection"
                        )}
                        theme="primary"
                        onClick={() => participateOrNot(true)}
                        loading={isSending}
                        disabled={disabledButtonSend}
                      />
                    </S.ButtonsContainer>
                  )}
                </Col>
              </Row>
              <S.RowFooter>
                <S.ButtonsFooter>
                  <Link to={`/parceiro/projetos/v2?invitations`}>
                    <ButtonBpool
                      text={I18n.get("Back to invitation list")}
                      theme="secondary"
                      className="btnPrev"
                      onClick={() => true}
                    />
                  </Link>
                </S.ButtonsFooter>
              </S.RowFooter>
            </Main>
          </div>
        </Content>
      </LoggedPage>

      <Modal
        title={I18n.get("Decline invitation")}
        visible={openModalReason}
        onOk={handleOkModalReason}
        onCancel={() => setOpenModalReason(false)}
        centered
        width={800}
        footer={[
          <S.ModalButtons>
            <ButtonBpool
              text={I18n.get("Cancel")}
              theme="secondary"
              full={false}
              onClick={() => setOpenModalReason(false)}
            />
            <ButtonBpool
              text={I18n.get("Save")}
              theme="primary"
              full={false}
              loading={isLoadingSaving}
              onClick={handleOkModalReason}
              disabled={disableButtonModal}
            />
          </S.ModalButtons>,
        ]}
      >
        <div>
          {I18n.get("We understand that you declined your participation.")}
          <S.ReasonTitle>
            {I18n.get(
              "Please comment on the reason for not accepting to participate."
            )}
          </S.ReasonTitle>
          <div>
            <Textarea
              id="reason"
              label={I18n.get("Reason")}
              rows={2}
              maxLength={250}
              counterChar
              defaultValue={reason}
              onChange={(value) => onChangeReason(value)}
              error=""
            />
          </div>
        </div>
      </Modal>

      {contactInformation?.indemnityInvite && !hasConfirmedAlready && (
        <ModalPdf
          pdf={contactInformation?.indemnityInvite}
          visible={!hasConfirmedAlready}
        >
          <FormTerms confirm={handleConfirm} />
        </ModalPdf>
      )}
    </>
  );
};
