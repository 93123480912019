import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { PARTNER_SAVE_PROJECT } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const update_suggestion_project = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .put(`${Config.API_BASE_URL}/v2/client-project/partner`, data, config)
          .then((response) => {
            dispatch({
              type: PARTNER_SAVE_PROJECT,
              payload: {
                project: response.data.data,
                task: { result: true },
              },
            });

            return response.data.data;
          })
          .catch((error) => {
            dispatch({
              type: PARTNER_SAVE_PROJECT,
              payload: {
                errors: error.response.data,
                task: { result: false },
              },
            });
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default update_suggestion_project;
