import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    Button,
    Icon,
    Row,
    Modal,
    Alert,
    Col
} from 'antd';

import RatingPreview from '../components/RatingPreview'

import { TitleMiddle, TitleMedium } from '../components/Titles';
import { CardBorder } from '../components/Cards';
import ResumeTable from '../components/ResumeTable';

import Carousel from '../components/Carousel'
import icoLuz from '../../../image/ico-luz-2.png';
import icoRelogio from '../../../image/ico-relogio.png';
import icoCalc from '../../../image/ico-calculadora.png';

import { formatMoney } from '../../../utils/helpers';

import { IdeaWhatIfStatus } from '../../../model';

import { handleIdeaUsedOn, handleJobOffer, handleStatusIdea } from '../helpers/utils';


const MainInfos = styled.div`
  color: #353535;

  .AlertPo {
      background: #F5FCED;
      padding: 15px 10px;
      &.topo {
          margin-bottom: -50px;
          margin-top: 80px;
      }

      &.bottom {
          margin-bottom: 40px;
      }

      button {
          float: right;
          margin-top: 10px;
      }
  }

  .BtnModal {
      margin-top: 20px;
  }

  .TextContra {
      margin-top: 20px;

      .tagYellow {
          background: #FFC5B9;
      }
  }

  .RowSuccess {
    margin-bottom: 30px;
  }

   .AlertMsg {

       padding: 40px 40px 20px;

       .titAlert {
           border-bottom: solid 1px #ccc;
           padding-bottom: 20px;
           margin-bottom: 30px;
       }

       &.Received {
        background: #fff4f4;
        border: dotted 2px #FFC5B9;
       }

       &.Accept {
           border: dotted 1px #88d8ba;
           background: #CBEDE0;
       }

       &.Reject {
           border: dotted 1px #ed8787;
           background: #ffe6e0;
       }

       h3 {
           font-weight: 700;
           margin-bottom: 0px;
       }
   }

    .BtnView {
        margin-top: 10px;
    }

    .TitleP {
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        margin-bottom: 5px;
    }

    .tagYellow {
        background: #fff291;
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        padding: 2px 7px;
    }

    .tagPink {
        background: #ffe5e5;
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 7px;
    }

    .RowTitle {
        margin-bottom: 30px;
    }

    .BoxIcon {
        padding-left: 70px;
        background: no-repeat top left;
        min-height: 100px;

        &.Status {
            background-image:url(${icoLuz});
        }

        &.Relogio {
            background-image:url(${icoRelogio});
        }

        &.Proposta {
            background-image:url(${icoCalc});
            padding-left: 90px;
        }

        &.Vazio {
            padding-left: 90px;
        }
    }


  .PartnerName {
      padding: 10px 0 0 300px;

      h2 {
          font-size: 20px;
          font-weight: 600;
      }

      h4 {
          font-size: 13px;
          font-weight: 600;
          line-height: 25px;
      }

      @media (max-width: 768px) {
            padding: 120px 0 0 0;
            width: 300px;
            margin: 0 auto;
            text-align: center;
      }
  }

  .ResumeIdea {
      background: #ececec;
      padding: 40px 20px;
      margin-top: 60px;
      color: #353535;
      margin-bottom: 50px;

      .TitleBorder {
          color: #3d3d3d;
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          border-left: 8px #bab1d1 solid;
          padding-left: 30px;
          margin-bottom: 30px;
      }



      .ListLinks {
          li {
              margin: 0 0 5px;

              a {
                  color: #3d3d3d;
                  font-size: 15px;
                  i {
                      margin-right: 5px;
                  }

                  &:hover, &:focus {
                      color: #bab1d1;
                  }
              }
          }
      }

      .RowSlide {
          margin-bottom: 30px;

      }

      .ItemImg {
          margin: 0 1px;
          overflow: hidden;

          img {
              max-width: 200px;
          }
      }

      h2 {
          font-size: 25px;
          font-weight: 700;
      }

      p {
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 20px;
      }
      .Redes {
          margin-bottom: 20px;
          li {
              display: inline-block;
              font-size: 13px;
              font-weight: 700;
              line-height: 37px;
              padding-right: 30px;
              position: relative;

              &:last-child  {
                  &::after {
                      display: none;
                  }
              }

              &::after {
                  content: "/";
                  position: absolute;
                  right: 12px;
                  top: 0;
              }
          }
      }
  }

  @media (max-width: 768px) {
      .TitSpace {
          margin-top: 50px;
      }
      .BoxIcon {
        padding-left: 90px;


        &.Vazio {
            padding-left: 90px;
            margin: 0 0 50px;
            overflow: hidden;
        }
    }
  }
`;



class IdeaInfos extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const {
            isClient = false,
            showModal,
            showModalProposal,
            idea,
            idea: {
                ideaProposalSocial,
                ideaProposalOthers,
                ideaType,
                description,
                status,
                ideaUsedOn,
                referenceImages,
                referenceVideos,
                offers,
                partner: {
                    name,
                    occupation
                }
            },
            isView,
            isPartner
        } = this.props;

        const activeOffer = offers.filter(offer => offer.activeOffer == true);
        const approvedOfferGet = offers.filter(offer => offer.activeOffer == true && offer.approvedOn);
        const declineOfferGet = offers.filter(offer => offer.activeOffer == false && offer.declinedOn && offer.activeOfferFrom != "P");

        const approvedOffer = approvedOfferGet.length > 0 ? approvedOfferGet[0] : null;
        const declineOffer = declineOfferGet.length > 0 ? declineOfferGet[0] : null;

 

        return (
            <MainInfos>
                <div className="PartnerName boxPreview">
                    <div className="boxCard">
                        <h2>{name}</h2>
                        <h4>{occupation}</h4>
                        {/* <RatingPreview value={3.5} /> */}
                        {isClient &&
                            <Row gutter={1}>
                                <Col sm={24}>
                                    <Button className="BtnView" type="primary" shape="round" onClick={showModal}>Ver Perfil</Button>
                                </Col>
                            </Row>
                        }
                    </div>
                </div>
                {isClient && status == IdeaWhatIfStatus.Approved &&
                    <section className="AlertPo topo">
                        <Row>
                            <Col sm={18}>
                                <h2>Essa ideia já foi aprovada!</h2>
                                <p>É necessário cadastrar a P.O</p>
                            </Col>
                            <Col sm={6}>
                                <p>
                                    <Link to={`/cliente/ideias/recebida/cadastrar-po/${idea.whatIfIdeaId}`}>
                                        <Button type="primary" shape="round" onClick={this.props.decline}>Cadastrar P.O</Button>
                                    </Link>
                                </p>
                            </Col>
                        </Row>
                    </section>
                }

                {isPartner && status == IdeaWhatIfStatus.ApprovedWithPO &&
                    <section className="AlertPo topo">
                        <Row>
                            <Col sm={18}>
                                <h2>Essa ideia já foi aprovada!</h2>
                                <p>Aguarde os próximos passos.</p>
                            </Col>
                        </Row>
                    </section>
                }


                <section className="ResumeIdea">
                    <Row>
                        <Col sm={24}>
                            <ul className="Redes">
                                {ideaProposalSocial && ideaProposalSocial.map((item, index) => {
                                    return <li key={index}>{item}</li>
                                })}
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24}>
                            <h2>{idea.name}</h2>
                            <p className="tagPink">IDEIA: {handleIdeaUsedOn(ideaUsedOn && ideaUsedOn)}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24}>
                            <p>
                                {description}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={24}>
                            <h3 className="TitleBorder">Conteúdo ou Referência</h3>
                        </Col>
                    </Row>
                    <Row className="RowSlide">
                        <Col sm={24}>
                            <Carousel
                                dots={false}
                                infinite={true}
                                speed={300}
                                slidesToShow={referenceImages.length < 5 ? referenceImages.length : 5}
                                centerMode={false}
                                variableWidth={true}
                            >

                                {referenceImages && referenceImages.map((image, index) => {
                                    return (
                                        <div className="ItemImg">
                                            <img src={image} />
                                        </div>
                                    )

                                })}


                            </Carousel>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24}>
                            <h3 className="TitleBorder">Vídeos</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24}>

                            <ul className="ListLinks">
                                {referenceVideos && referenceVideos.map((video, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={video} title=""><Icon type="play-circle" /> {video}</a>
                                        </li>
                                    )


                                })}
                            </ul>
                        </Col>
                    </Row>
                </section>

                <CardBorder
                    Bcolor="#fff291"
                >
                    <Row className="RowTitle">
                        <Col sm={24}>
                            <TitleMiddle>Informações Gerais:</TitleMiddle>
                        </Col>
                    </Row>

                    {(status == IdeaWhatIfStatus.Approved && IdeaWhatIfStatus.ApprovedWithPO) &&
                        <Row className="RowSuccess">
                            <Col sm={24}>
                                <Alert
                                    message="Essa ídeia já foi aprovada!"
                                    description=""
                                    type="success"
                                />
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col md={16} sm={24} >

                            <Row gutter={1} className="TitSpace">

                                <Col md={24}>
                                    <div className="BoxIcon Proposta">
                                        <p className="TitleP">Proposta de Trabalho</p>
                                        <p>{handleJobOffer(offers[0] && offers[0].jobOffer)}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={1}>
                                <div className="BoxIcon Vazio">

                                    {offers[0].jobOfferPriceIdea &&
                                        <Col sm={24} md={12}>
                                            <p className="TitleP">Ideia:</p>
                                            <p>
                                                <span className="tagYellow">R$ {formatMoney(offers[0].jobOfferPriceIdea)}</span>
                                            </p>
                                            {isClient && activeOffer.length == 1 && !approvedOffer &&
                                                <div className="TextContra">
                                                    {offers.length == 2 && activeOffer[0].whatIfIdeaOfferId != offers[0].whatIfIdeaOfferId &&
                                                        <div className="ItemContra">
                                                            <p className="TitleP">Valor da ideia(Contraproposta):</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(activeOffer[0].jobOfferPriceIdea)}</span>
                                                            </p>
                                                            <p><small><strong>Justificativa:</strong> {activeOffer[0].comment}</small></p>
                                                        </div>

                                                    }

                                                    {activeOffer[0].activeOfferFrom != "C" && !declineOffer &&
                                                        <p className="BtnModal">
                                                            <Button type="primary" shape="round" onClick={() => showModalProposal(offers[0].jobOffer)}>Fazer contra proposta</Button>
                                                        </p>

                                                    }
                                                </div>
                                            }
                                        </Col>
                                    }
                                    {offers[0].jobOfferPriceProduction &&
                                        <Col sm={24} md={12} className="TitSpace">
                                            <p className="TitleP">Produção:</p>
                                            <p>
                                                <span className="tagYellow">R$ {formatMoney(offers[0].jobOfferPriceProduction)}</span>
                                            </p>
                                            {isClient && activeOffer && !approvedOffer &&
                                                <div className="TextContra">
                                                    {offers.length == 2 && activeOffer[0].whatIfIdeaOfferId != offers[0].whatIfIdeaOfferId &&
                                                        <div className="ItemContra">
                                                            <p className="TitleP">Valor da Produção(Contraproposta):</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(activeOffer[0].jobOfferPriceProduction)}</span>
                                                            </p>
                                                        </div>

                                                    }
                                                    {activeOffer[0] && activeOffer[0].activeOfferFrom != "C" && !declineOffer &&
                                                        <p className="BtnModal">
                                                            <Button type="primary" shape="round" onClick={() => showModalProposal(offers[0].jobOffer)}>Fazer contra proposta</Button>
                                                        </p>
                                                    }
                                                </div>
                                            }
                                        </Col>
                                    }
                                </div>
                            </Row>
                        </Col>

                        <Col md={8} sm={24}>
                            <Row gutter={1}>
                                <Col md={24}>
                                    {/* <div className="BoxIcon Status">
                                        <p className="TitleP">Estatus da Ideia:</p>
                                        <p>
                                            <span className="tagYellow">
                                                {handleStatusIdea(status)}
                                            </span>
                                        </p>

                                    </div> */}
                                </Col>
                            </Row>
                            {offers[0].deadlineDate &&
                                <Row gutter={1}>
                                    <Col md={24}>
                                        <div className="BoxIcon Relogio">
                                            <p className="TitleP">Data da entrega:</p>
                                            <p>
                                                <span className="tagYellow">{offers[0].deadlineDate}</span>
                                                <span className="tagYellow">{offers[0].deadlineHour}h</span>
                                            </p>
                                            {isClient && activeOffer && !approvedOffer &&
                                                <div className="TextContra">

                                                    {offers.length == 2 && activeOffer[0].whatIfIdeaOfferId != offers[0].whatIfIdeaOfferId &&
                                                        <div className="TextContra">
                                                            <p className="TitleP">Data da entrega (Contraproposta):</p>
                                                            <p>
                                                                <span className="tagYellow">{activeOffer[0].deadlineDate}</span>
                                                                <span className="tagYellow">{activeOffer[0].deadlineHour}h</span>
                                                            </p>

                                                        </div>
                                                    }


                                                    {activeOffer[0] && activeOffer[0].activeOfferFrom != "C" && !declineOffer &&
                                                        <p className="BtnModal">
                                                            <Button type="primary" shape="round" onClick={() => showModalProposal(offers[0].jobOffer)}>Fazer contra proposta</Button>
                                                        </p>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Col>

                    </Row>

                    {isClient && activeOffer.length == 1 && declineOffer &&
                        <Row>
                            <Col sm={24}>
                                <div className="AlertMsg Reject">
                                    <Row className="titAlert">
                                        <Col md={24}>
                                            <h3>O Parceiro rejeitou a proposta!</h3>
                                            <p>Veja as condições rejeitadas:</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={16} sm={24}>

                                            <Row gutter={1} className="TitSpace">

                                                <Col md={24}>
                                                    <div className="BoxIcon Proposta">
                                                        <p className="TitleP">Justificativa da contra-proposta:</p>
                                                        <p>{declineOffer.comment}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={1}>
                                                <div className="BoxIcon Vazio">

                                                    {declineOffer.jobOfferPriceIdea &&
                                                        <Col sm={24} md={12}>
                                                            <p className="TitleP">Ideia:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(declineOffer.jobOfferPriceIdea)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                    {declineOffer.jobOfferPriceProduction &&
                                                        <Col sm={24} md={12} className="TitSpace">
                                                            <p className="TitleP">Produção:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(declineOffer.jobOfferPriceProduction)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                </div>
                                            </Row>
                                        </Col>

                                        <Col md={8} sm={24}>
                                            <Row gutter={1}>
                                                <Col md={24}>
                                                    {/* <div className="BoxIcon Status">
                                                        <p className="TitleP">Estatus da Ideia:</p>
                                                        <p><span className="tagYellow">Draft</span></p>

                                                    </div> */}
                                                </Col>
                                            </Row>
                                            {declineOffer.deadlineDate &&
                                                <Row gutter={1}>
                                                    <Col md={24}>
                                                        <div className="BoxIcon Relogio">
                                                            <p className="TitleP">Data da entrega:</p>
                                                            <p>
                                                                <span className="tagYellow">{declineOffer.deadlineDate}</span>
                                                                <span className="tagYellow">{declineOffer.deadlineHour}h</span>
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    }

                    {isClient && offers.length > 1 && approvedOffer &&
                        <Row>
                            <Col sm={24}>
                                <div className="AlertMsg Accept">
                                    <Row className="titAlert">
                                        <Col md={24}>
                                            <h3>O Parceiro aceitou a proposta!</h3>
                                            <p>Veja as condições aceitas:</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={16} sm={24}>

                                            <Row gutter={1} className="TitSpace">

                                                <Col md={24}>
                                                    <div className="BoxIcon Proposta">
                                                        <p className="TitleP">Justificativa da contra-proposta:</p>
                                                        <p>{approvedOffer.comment}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={1}>
                                                <div className="BoxIcon Vazio">

                                                    {approvedOffer.jobOfferPriceIdea &&
                                                        <Col sm={24} md={12}>
                                                            <p className="TitleP">Ideia:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(approvedOffer.jobOfferPriceIdea)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                    {approvedOffer.jobOfferPriceProduction &&
                                                        <Col sm={24} md={12} className="TitSpace">
                                                            <p className="TitleP">Produção:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(approvedOffer.jobOfferPriceProduction)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                </div>
                                            </Row>
                                        </Col>

                                        <Col md={8} sm={24}>
                                            <Row gutter={1}>
                                                <Col md={24}>
                                                    {/* <div className="BoxIcon Status">
                                                        <p className="TitleP">Estatus da Ideia:</p>
                                                        <p><span className="tagYellow">Draft</span></p>

                                                    </div> */}
                                                </Col>
                                            </Row>
                                            {approvedOffer.deadlineDate &&
                                                <Row gutter={1}>
                                                    <Col md={24}>
                                                        <div className="BoxIcon Relogio">
                                                            <p className="TitleP">Data da entrega:</p>
                                                            <p>
                                                                <span className="tagYellow">{approvedOffer.deadlineDate}</span>
                                                                <span className="tagYellow">{approvedOffer.deadlineHour}h</span>
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    }

                    {isPartner && isView && offers.length > 1 && approvedOffer &&
                        <Row>
                            <Col sm={24}>
                                <div className="AlertMsg Accept">
                                    <Row className="titAlert">
                                        <Col md={24}>
                                            <h3>Você aceitou a contra-proposta!</h3>
                                            <p>Veja as condições aceitas:</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={16} sm={24}>

                                            <Row gutter={1} className="TitSpace">

                                                <Col md={24}>
                                                    <div className="BoxIcon Proposta">
                                                        <p className="TitleP">Justificativa da contra-proposta:</p>
                                                        <p>{approvedOffer.comment}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={1}>
                                                <div className="BoxIcon Vazio">

                                                    {approvedOffer.jobOfferPriceIdea &&
                                                        <Col sm={24} md={12}>
                                                            <p className="TitleP">Ideia:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(approvedOffer.jobOfferPriceIdea)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                    {approvedOffer.jobOfferPriceProduction &&
                                                        <Col sm={24} md={12} className="TitSpace">
                                                            <p className="TitleP">Produção:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(approvedOffer.jobOfferPriceProduction)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                </div>
                                            </Row>
                                        </Col>

                                        <Col md={8} sm={24}>
                                            <Row gutter={1}>
                                                <Col md={24}>
                                                    {/* <div className="BoxIcon Status">
                                                        <p className="TitleP">Estatus da Ideia:</p>
                                                        <p><span className="tagYellow">Draft</span></p>

                                                    </div> */}
                                                </Col>
                                            </Row>
                                            {approvedOffer.deadlineDate &&
                                                <Row gutter={1}>
                                                    <Col md={24}>
                                                        <div className="BoxIcon Relogio">
                                                            <p className="TitleP">Data da entrega:</p>
                                                            <p>
                                                                <span className="tagYellow">{approvedOffer.deadlineDate}</span>
                                                                <span className="tagYellow">{approvedOffer.deadlineHour}h</span>
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    }

                    {isPartner && activeOffer.length > 0 && declineOffer &&
                        <Row>
                            <Col sm={24}>
                                <div className="AlertMsg Reject">
                                    <Row className="titAlert">
                                        <Col md={24}>
                                            <h3>Você rejeitou a contra-proposta!</h3>
                                            <p>Veja as condições rejeitadas:</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={16} sm={24}>

                                            <Row gutter={1} className="TitSpace">

                                                <Col md={24}>
                                                    <div className="BoxIcon Proposta">
                                                        <p className="TitleP">Justificativa da contra-proposta:</p>
                                                        <p>{declineOffer.comment}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={1}>
                                                <div className="BoxIcon Vazio">

                                                    {declineOffer.jobOfferPriceIdea &&
                                                        <Col sm={24} md={12}>
                                                            <p className="TitleP">Ideia:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(declineOffer.jobOfferPriceIdea)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                    {declineOffer.jobOfferPriceProduction &&
                                                        <Col sm={24} md={12} className="TitSpace">
                                                            <p className="TitleP">Produção:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(declineOffer.jobOfferPriceProduction)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                </div>
                                            </Row>
                                        </Col>

                                        <Col md={8} sm={24}>
                                            <Row gutter={1}>
                                                <Col md={24}>
                                                    {/* <div className="BoxIcon Status">
                                                        <p className="TitleP">Estatus da Ideia:</p>
                                                        <p><span className="tagYellow">Draft</span></p>

                                                    </div> */}
                                                </Col>
                                            </Row>
                                            {declineOffer.deadlineDate &&
                                                <Row gutter={1}>
                                                    <Col md={24}>
                                                        <div className="BoxIcon Relogio">
                                                            <p className="TitleP">Data da entrega:</p>
                                                            <p>
                                                                <span className="tagYellow">{declineOffer.deadlineDate}</span>
                                                                <span className="tagYellow">{declineOffer.deadlineHour}h</span>
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    }

                    {isPartner && isView && activeOffer.length > 0 && !approvedOffer && activeOffer[0].whatIfIdeaOfferId != offers[0].whatIfIdeaOfferId &&
                        <Row>
                            <Col sm={24}>
                                <div className="AlertMsg Received">
                                    <Row className="titAlert">
                                        <Col md={24}>
                                            <h3>Atenção! Você recebeu um contra-proposta!</h3>
                                            <p>Analise a proposta, depois responda com o aceitar ou recusar.</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={16} sm={24}>

                                            <Row gutter={1} className="TitSpace">

                                                <Col md={24}>
                                                    <div className="BoxIcon Proposta">
                                                        <p className="TitleP">Justificativa da contra-proposta:</p>
                                                        <p>{activeOffer[0].comment}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={1}>
                                                <div className="BoxIcon Vazio">

                                                    {activeOffer[0].jobOfferPriceIdea &&
                                                        <Col sm={24} md={12}>
                                                            <p className="TitleP">Ideia:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(activeOffer[0].jobOfferPriceIdea)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                    {activeOffer[0].jobOfferPriceProduction &&
                                                        <Col sm={24} md={12} className="TitSpace">
                                                            <p className="TitleP">Produção:</p>
                                                            <p>
                                                                <span className="tagYellow">R$ {formatMoney(activeOffer[0].jobOfferPriceProduction)}</span>
                                                            </p>
                                                        </Col>
                                                    }
                                                </div>
                                            </Row>
                                        </Col>

                                        <Col md={8} sm={24}>
                                            <Row gutter={1}>
                                                <Col md={24}>
                                                    {/* <div className="BoxIcon Status">
                                                        <p className="TitleP">Estatus da Ideia:</p>
                                                        <p><span className="tagYellow">Draft</span></p>

                                                    </div> */}
                                                </Col>
                                            </Row>
                                            {activeOffer[0].deadlineDate &&
                                                <Row gutter={1}>
                                                    <Col md={24}>
                                                        <div className="BoxIcon Relogio">
                                                            <p className="TitleP">Data da entrega:</p>
                                                            <p>
                                                                <span className="tagYellow">{activeOffer[0].deadlineDate}</span>
                                                                <span className="tagYellow">{activeOffer[0].deadlineHour}h</span>
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <Button type="primary" shape="round" onClick={this.props.decline}>Recusar</Button>
                                            <Button type="primary" shape="round" onClick={this.props.accept}>Aceitar</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    }
                </CardBorder>


                {isClient && idea.po && 
                    <CardBorder
                        Bcolor="#5bff92"
                    >
                        <Row>
                            <Col sm={24}>
                                <TitleMedium>PO</TitleMedium>
                            </Col>
                            <Row>
                                <Col sm={24}>
                                    <p>{idea.po}</p>
                                </Col>
                            </Row>
                        </Row>
                    </CardBorder>                
                }

                <CardBorder
                    Bcolor="#cbede0"
                >
                    <Row>
                        <Col sm={24}>
                            <TitleMedium>Resumo da Proposta</TitleMedium>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={18}>
                            <ResumeTable
                                valorIdeia={approvedOffer ? approvedOffer.jobOfferPriceIdea : offers[0].jobOfferPriceIdea}
                                valorProducao={approvedOffer ? approvedOffer.jobOfferPriceProduction : offers[0].jobOfferPriceProduction}
                                scroll={{ y: 595 }}
                            />
                        </Col>
                    </Row>
                </CardBorder>

                {status == IdeaWhatIfStatus.Approved &&
                    <section className="AlertPo bottom">
                        <Row>
                            <Col sm={18}>
                                <h2>Essa ideia já foi aprovada!</h2>
                                <p>É necessário cadastrar a P.O</p>
                            </Col>
                            <Col sm={6}>
                                <p>
                                    <Link to={`/cliente/ideias/recebida/cadastrar-po/${idea.whatIfIdeaId}`}>
                                        <Button type="primary" shape="round" onClick={this.props.decline}>Cadastrar P.O</Button>
                                    </Link>
                                </p>
                            </Col>
                        </Row>
                    </section>
                }

            </MainInfos>
        );
    }
}


export default IdeaInfos;
