import styled from 'styled-components';

export const Title = styled.div`
  margin-top: 60px;
  position: relative;

  p {
    font-weight: 600;
    color: #000000;
    font-size: 30px;
    margin: 15px 0 45px 0;

    :after {
      position: absolute;
      content: '';
      width: 40px;
      height: 4px;
      background: #000000;
      bottom: -10px;
      left: 0;
    }
  }
`;

export const Wrapper = styled.div`
  .ant-select-selection {
    background: #f2f2f2;
    border-radius: 4px;
    line-height: 40px;
    height: 40px;
  }

  .ant-select-selection__rendered {
    line-height: 40px;
  }

  .ant-select-arrow {
    top: 5px;
  }

  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin: 20px 0 10px 0;
  color: #000000;
`;

export const WrapperTable = styled.div`
  .ant-table-thead {
    tr {
      th {
        width: 17%;

        :first-child {
          width: 66%;
        }
      }
    }
  }
`;

export const ContentSelectsAll = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 0;
  margin-top: 15px;

  div {
    width: 17%;

    :first-child {
      width: 66%;
    }

    button {
      font-size: 12px;
      border: 0;
      background: #434343;
      color: #fff;
      cursor: pointer;
      font-weight: 600;
      border-radius: 4px;
      padding: 7px 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const WrapperButtonSave = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 30px 0 30px 0;
`;

export const WrapperNameAndTooltip = styled.div`
  max-width: max-content;
  display: flex;
  align-items: flex-start;
`;

export const TooltipDescription = styled.div`
  margin-left: 10px;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }
`;

export const TheName = styled.div`
  line-height: 22px;
`;

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;

  form {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
  }
`;

export const Form = styled.form``;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
`;

export const InputGroup = styled.div`
  width: 100%;
  margin: 0 15px;

  input,
  button {
    width: 100%;
    height: 40px;
  }

  input {
    border: 1px solid;
    border-radius: 5px;
    padding: 10px 15px;
  }

  button {
    cursor: pointer;
  }
`;

export const ListErrors = styled.ul`
  li {
    height: 30px;
    display: flex;
    line-height: 30px;
    align-items: center;

    span {
      padding-top: 5px;
    }

    img {
      margin-right: 15px;
      height: 24px;
    }
  }
`;
