import React, { Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Table, Tag } from 'antd';

import Loading from '../../components/pages/loading';

import { formatCurrency } from '../../utils/helpers'


import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import FormApproveAsset from '../../modules/RepositoryAssets/admin/components/FormApproveAsset';

import styled from "styled-components";

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_assets_partners: () => {
            dispatch(BP_API.get_assets_partners());
        },
        approve_or_refuse: data => {
            dispatch(BP_API.approve_or_refuse(data));
        },
        update_asset: data => {
            dispatch(BP_API.update_asset(data));
        },
    };
};


const columns = [
    { title: I18n.get("Parceiro"), dataIndex: 'partner', key: 'partner' },
    { title: I18n.get("Título"), dataIndex: 'title', key: 'title' },
    {
        title: I18n.get("Status"),
        dataIndex: 'status',
        key: 'status',
        render: price => {
            switch (price) {
                case 2:
                    return <Tag color="#2db7f5">{I18n.get("CRIADO")}</Tag>;
                    break;
                case 4:
                    return <Tag color="#87d068">{I18n.get("APROVADO")}</Tag>;
                    break;
                case 6:
                    return <Tag color="#f50">{I18n.get("REPROVADO")}</Tag>;
                    break;

                default:
                    break;
            }
        }
    },
    {
        title: I18n.get("Preço"),
        dataIndex: 'price',
        key: 'price',
        render: price => { return formatCurrency(price) }
    },
];


const ContainerTable = styled.div`
    background: #fff;
    padding: 50px;
    .RowBtn {
        margin-bottom: 80px;

        p {
            margin-bottom: 20px;
        }

        .tit {
            font-weight: bold;
        }
    }
`


class Assets extends React.Component {

    constructor(props) {

        super();

        this.state = {};
    }


    componentDidMount = () => {
        this.props.get_assets_partners();
    }

    render() {
        if (!this.props.bp.assetsPartners && this.props.auth.logged_user) return <Loading />;

        const {
            bp: {
                assetsPartners,
                assetTypes,
                assetSpecialisms,
                assetComplexity,
                errors
            }
        } = this.props;


        let data = assetsPartners && assetsPartners.map((item, index) => {
            return {
                key: index,
                title: item.title,
                price: item.price,
                status: item.status,
                partner: item.partnerCompanyName,
                asset: item
            }
        });


        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/usuarios" className="is-black">{I18n.get("Assets")}</NavLink></li>
                    </ul>
                </nav>

                <ContainerTable>
                    <Table
                        scroll={{ x: 640 }}
                        columns={columns}
                        expandedRowRender={record => (
                            <div>
                                <Row>
                                    <Col sm={24}>
                                        <FormApproveAsset
                                            asset={record.asset}
                                            action={this.props.approve_or_refuse}
                                            update={this.props.update_asset}
                                            assetTypes={assetTypes}
                                            assetSpecialisms={assetSpecialisms}
                                            assetComplexity={assetComplexity}
                                            formErrors={errors}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )}
                        dataSource={data}
                    />
                </ContainerTable>


            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
