import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_PROJECTS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_projects = (data) => {

    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');

    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/bp/admin/project-audio?${queryString}`, config)
                .then(response => {
                    dispatch({
                        type: BP_PROJECTS_GET,
                        payload: {
                            adminProjectList: response.data,
                            task: {
                                result: true
                            }
                        }
                    });
                })
                .catch(error => {

                    throw (error);
                });

        }).catch(error => {

            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_projects;
