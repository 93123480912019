import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import RadioMultiField from "../form/radio-multi-field";
import TextAreaField from "../form/textarea-field";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

import { CustomEventBriefingType } from "../../model";

import { Input } from "../../v2/components/UI/Input";
import { Textarea } from "../../v2/components/UI/Textarea";

const evt = CustomEventBriefingType.Event;
const shp = CustomEventBriefingType.Shopper;
const ict = CustomEventBriefingType.Incentive;
const dgt = CustomEventBriefingType.Digital;
const com = CustomEventBriefingType.Communication;

class LocationForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        venueType: {
          labels: {
            [evt]: I18n.get("Onde vai acontecer o evento?"),
            [shp]: I18n.get("Onde vai acontecer o projeto?"),
            [ict]: I18n.get("Onde vai acontecer o projeto?"),
          },
          name: "venueType",
          onChange: this.onChangeForm.bind(this, "venueType"),
          // onBlur: this.onSave.bind(this),
          values: [],
        },
        venueName: {
          label: "",
          onChange: this.onChangeForm.bind(this, "venueName"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
        venueDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "venueDesc"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
        venueRegion: {
          label: I18n.get("Tem região definida? Alguma de preferência?"),
          name: "venueRegion",
          onChange: this.onChangeForm.bind(this, "venueRegion"),
          // onBlur: this.onSave.bind(this),
          values: [],
        },
        venueComments: {
          label: I18n.get("Observações"),
          name: "venueComments",
          onChange: this.onChangeForm.bind(this, "venueComments"),
          // onBlur: this.onSave.bind(this),
          values: [],
        },
        venueRegionName: {
          label: "",
          onChange: this.onChangeForm.bind(this, "venueRegionName"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};

    const venueTypeValues = constants.EVENT_VENUE_TYPES.map((item) => {
      if (item.code == "VENUE") {
        item.field = (
          //   <TextField
          //     className="field-cond"
          //     {...this.state.fields.venueName}
          //     value={this.state.form.venueName}
          //     error={this.state.errors.venueName}
          //     disabled={this.state.form.venueType != "VENUE"}
          //   />

          <Input
            onChange={(value) => this.state?.fields?.venueName.onChange(value)}
            value={this.state.form.venueName}
            disabled={this.state.form.venueType != "VENUE"}
            error={this.state.errors.venueName?.errorMessage}
          />
        );
      }

      if (item.code == "SIMILAR_TO") {
        item.field = (
          //   <TextField
          //     className="field-cond"
          //     {...this.state.fields.venueDesc}
          //     value={this.state.form.venueDesc}
          //     error={this.state.errors.venueDesc}
          //     disabled={this.state.form.venueType != "SIMILAR_TO"}
          //   />

          <Input
            onChange={(value) => this.state?.fields?.venueDesc.onChange(value)}
            value={this.state.form.venueDesc}
            disabled={this.state.form.venueType != "SIMILAR_TO"}
            error={this.state.errors.venueDesc?.errorMessage}
          />
        );
      }

      return item;
    });

    const venueRegionValues = constants.EVENT_VENUE_REGIONS.map((item) => {
      //Gambi pra pegar tradução
      item.label = I18n.get(item.label);

      if (item.code == "YES") {
        item.field = (
          //   <TextField
          //     className="field-cond"
          //     {...this.state.fields.venueRegionName}
          //     value={this.state.form.venueRegionName}
          //     error={this.state.errors.venueRegionName}
          //     disabled={this.state.form.venueRegion != "YES"}
          //   />

          <Input
            onChange={(value) =>
              this.state?.fields?.venueRegionName.onChange(value)
            }
            value={this.state.form.venueRegionName}
            disabled={this.state.form.venueRegion != "YES"}
            error={this.state.errors.venueRegionName?.errorMessage}
          />
        );
      }

      return item;
    });

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Localização")}</h1>

        <div className="bpForce mini">
          <RadioMultiField
            {...this.state.fields.venueType}
            labelKey={customEvent.briefingType}
            value={this.state.form.venueType}
            error={this.state.errors.venueType}
            values={venueTypeValues}
          />

          <br />

          <RadioMultiField
            {...this.state.fields.venueRegion}
            value={this.state.form.venueRegion}
            error={this.state.errors.venueRegion}
            values={venueRegionValues}
          />

          <br />

          {/* <TextAreaField
            {...this.state.fields.venueComments}
            value={this.state.form.venueComments}
            error={this.state.errors.venueComments}
          /> */}

          <Textarea
            label={this.state.fields.venueComments.label}
            defaultValue={this.state.form.venueComments}
            value={this.state.form.venueComments}
            onChange={(value) =>
              this.state?.fields?.venueComments.onChange(value)
            }
            error={this.state?.errors?.venueComments?.errorMessage}
          />
        </div>
      </div>
    );
  }
}

export default LocationForm;
