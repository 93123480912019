import React from "react";
import * as S from "../../styles";

export const Info = ({ label, info, loading }) => {
  const renderValue = (value) => {
    if (value === 0 || value === "0" || value) {
      return value;
    } else {
      return "---";
    }
  };

  return (
    <S.Info>
      <label>{label}:</label>
      <S.Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-infos",
          width: "100%",
        }}
      >
        <p>{renderValue(info)}</p>
      </S.Skeleton>
    </S.Info>
  );
};
