import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useParams } from "react-router-dom";
import { useProjects } from "../../../../../../../../hooks/useProjects/useProjects.hook";
import { AssetsCart } from "../../../../../../../../components/UI/AssetBundle/components/AssetsCart";

import * as S from "./styles";

export const ModalAssets = ({ handleChangeIncludedItems }) => {
  let params = useParams();
  const { getAssetsStep2 } = useProjects();
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [listDataIncludedItems, setListDataIncludedItems] = useState([]);
  const [listOfAssets, setListOfAssets] = useState([]);
  const [assetsCart, setAssetsCart] = useState([]);
  const [cartTotals, setCartTotals] = useState({
    priceClientWithDiscountTotalFmt: "",
    priceClientWithDiscountFmt: "",
    priceBPoolComissionFmt: "",
  });
  const [filters, setFilters] = useState({
    spec: "",
    type: "",
  });
  const [projectDetails, setProjectDetails] = useState({});

  const handleListDataIncludedItems = (list) => {
    setListDataIncludedItems(list);
  };

  const handleChanged = (hasChanged) => {
    setIsDisabledSave(!hasChanged);
  };

  const getStepInit = async () => {
    const { data } = await getAssetsStep2(params.projectId);

    const assets = data?.bundle?.variations[0]?.assets || [];
    setListOfAssets(data?.bundle ? data?.bundle?.variations[0]?.assets : []);
    const assetsWithId = assets.map((itemAsset, index) => ({
      ...itemAsset,
      idInternal: `${index}-${itemAsset.asset}`,
    }));

    setCartTotals({
      priceClientWithDiscountTotalFmt: data.priceClientWithDiscountTotalFmt,
      priceClientWithDiscountFmt: data.priceClientWithDiscountFmt,
      priceBPoolComissionFmt: data.priceBPoolComissionFmt,
    });
    setAssetsCart(assetsWithId);
  };

  useEffect(() => {
    handleChangeIncludedItems(listDataIncludedItems);
  }, [listDataIncludedItems]);

  return (
    <S.Wrapper>
      <AssetsCart
        isCart={false}
        removeTotals
        listDataIncludedItems={listDataIncludedItems}
        handleListDataIncludedItems={handleListDataIncludedItems}
        handleChanged={handleChanged}
        cartTotals={cartTotals}
        filters={filters}
        reload={getStepInit}
        assetTax={projectDetails?.assetTax}
        otherTax={projectDetails?.otherTax}
      />
    </S.Wrapper>
  );
};
