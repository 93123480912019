import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';
import WhatifWrapper from '../components/WhatifWrapper';

import SubHeader from '../components/SubHeader';

import PageSuccess from '../components/PageSuccess';

class GeneralInformationSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <WhatifWrapper>
                <SubHeader />
                <PageSuccess>
                    <Fragment>
                        <h2>Parabéns</h2>
                        <p>Suas informações foram cadastradas com sucesso! Agora você precisa nos contar um pouco sobre seu trabalho.</p>

                        <p>
                            <Link to={'/parceiro-ideias/profile'}>Próximo</Link>
                        </p>
                    </Fragment>
                </PageSuccess>
            </WhatifWrapper>
        );
    }
}

export default GeneralInformationSuccess;
