import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import SelectSearchField from "../form/select-search-field";
import SelectField from "../form/select-field";

import GlobalFileBox from "../../v2/pages/Client/CreateProject/components/GlobalFileBox";
import { SelectOne } from "../../v2/components/UI/SelectOne";
import constants from "../../constants";

import { Radio } from "antd";
import FlagRS from "../../image/flag-rs.jpg";

const CustomOption = ({ innerProps, data }) => {
  const item = data.data;

  return item.enabled ? (
    <div {...innerProps}>
      <div className="asset-label">{data.label}</div>
    </div>
  ) : (
    <div className="asset-label is-disable">{data.label}</div>
  );
};

class PartnerInfoForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      diversity: [],
      diversityOwned: [],
      loadIndustries: false,
      fields: {
        selectPartner: {},
        selectPartnerId: {
          label: "",
          components: { Option: CustomOption },
          onChange: this.onChangeFormValue.bind(this, "selectPartnerId"),
          visited: true,
          successIcon: false,
        },
        alreadyWorkedPartnerId: {
          label: "",
          components: { Option: CustomOption },
          onChange: this.onChangeWorkedPartnerId.bind(this),
          visited: true,
          successIcon: false,
        },
        sameCategoryOnly: {
          onChange: this.onChangeForm.bind(this, "sameCategoryOnly"),
        },
        specificRegionOnly: {
          onChange: this.onChangeForm.bind(this, "specificRegionOnly"),
        },
        sosrs: {
          onChange: this.onChangeForm.bind(this, "sosrs"),
        },
        specificRegion: {
          onChange: this.onChangeForm.bind(this, "specificRegion"),
        },
        hasAdditionalCategories: {
          onChange: this.onChangeForm.bind(this, "hasAdditionalCategories"),
        },
        categoryIds: {
          label: I18n.get("Categoria"),
          onChange: this.onChangeFormValue.bind(this, "categoryIds"),
          visited: true,
          successIcon: false,
        },
        diversitySpecific: {
          label: I18n.get("Representantes de Owner ou Led"),
          onChange: this.onChangeFormValue.bind(this, "diversitySpecific"),
          visited: true,
          successIcon: false,
        },
        diversitySpecificOwner: {
          label: I18n.get("Representantes de Owner"),
          onChange: this.onChangeFormValue.bind(this, "diversitySpecificOwner"),
          visited: true,
          successIcon: false,
        },
      },
      form: {
        selectPartner: null,
        alreadyWorkedPartnerId: null,
        selectPartnerRadio: "F",
        sameCategoryOnly: props.sameCategoryOnly,
        specificRegionOnly: false,
        sosrs: false,
        specificRegion: props.specificRegion,
        hasAdditionalCategories: props.hasAdditionalCategories,
        categoryIds: props.categoryIds,
        diversity: props.diversity?.toString() || "0",
        diversitySpecific: props.diversitySpecific,
        diversitySpecificOwner: props.diversitySpecificOwner ?? [],
        isSubmit: false,
      },
      errors: {},
      task: {},
    };
  }

  componentDidMount() {
    var data = {
      clientId: this.props.client.clientId,
      projectId: this.props.match.params.projectId,
    };

    this.props.get_industries();

    this.props.getPreferredPartners(data);

    this.props.get_diversity().then((response) => {
      // Primeiro map: todos os itens das duas listas
      const diversity = this.flattenData(response.data.data);

      // Segundo map: filtrar itens cuja descrição contenha a palavra 'OWNED'
      const diversityOwned = diversity.filter((item) =>
        item.description?.includes("OWNED")
      );
      this.setState({
        diversity,
        diversityOwned,
      });
    });
  }

  flattenData(data) {
    const result = [];
    for (const item of data) {
      result.push(item);
      if (item.children && item.children.length > 0) {
        const children = this.flattenData(item.children);
        result.push(...children);
      }
    }
    return result;
  }
  componentWillUnmount() {
    //this.props.save(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);
        nextState.form[prop] = value;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormValue(prop, value) {
    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);
        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeWorkedPartnerId = (value) => {
    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form["alreadyWorkedPartnerId"] = value;
        nextState.form["suggestAlreadyWorkedPartner"] = true;

        return nextState;
      },
      () => this.onSaving()
    );
  };

  getData() {
    var data = {
      clientId: this.props.clientId,
      ...this.props.project,
      ...this.state.form,
    };
    return data;
  }

  onSaving() {
    this.props.handleDisabledButton(true);
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 20);
  }

  onSave() {
    if (this.props.isAdmin) return;

    var task = new Task();
    task.start();

    const data = this.getData();
    this.setState({ task: task }, function () {
      this.props.save(data).then((resp) => {
        if (resp?.id) {
          this.props.handleDisabledButton(false);
        }
      });
    });
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  getCategoryValues() {
    return (
      this.props.bp.industries &&
      this.props.bp.industries
        .filter((item) => !item.isUnspecified)
        .map((item) => ({
          code: item.id,
          label: item.description,
          selected: item.selected || false,
        }))
    );
  }

  getDiversityValues() {
    return this.state.diversity;
  }

  getDiversityOwnerValues() {
    return this.state.diversity;
  }

  onChangeSelectPartner = (e) => {
    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        const value = e.target.value;

        nextState.form["selectPartnerRadio"] = value;

        if (value == "F") {
          nextState.form["selectPartner"] = null;
          nextState.form["selectPartnerId"] = null;
          nextState.form["suggestAlreadyWorkedPartner"] = null;
          nextState.form["alreadyWorkedPartnerId"] = null;
        }

        if (value == "T") {
          nextState.form["selectPartner"] = true;
          nextState.form["selectPartnerId"] = null;
          nextState.form["suggestAlreadyWorkedPartner"] = null;
          nextState.form["alreadyWorkedPartnerId"] = null;
        }

        if (value == "One") {
          nextState.form["selectPartner"] = null;
          nextState.form["selectPartnerId"] = null;
          nextState.form["suggestAlreadyWorkedPartner"] = true;
        }

        return nextState;
      },
      () => this.onSaving()
    );
  };

  handleSetDiversity = (e) => {
    // this.setState();
    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form["diversity"] = e.target.value;

        if (e.target.value != 2) {
          nextState.form["diversitySpecificOwner"] = [];
          nextState.form["diversitySpecific"] = [];
        }

        return nextState;
      },
      () => this.onSaving()
    );
  };

  saveGlobal = async (data) => {
    const dataSend = {
      ...data,
      projectId: this.props.match.params.projectId,
    };
    const response = await this.props.update_global(dataSend);

    var data = {
      clientId: this.props.client.clientId,
      projectId: this.props.match.params.projectId,
    };

    await this.props.get_project(data);
  };

  render() {
    const diversidade = [
      { label: I18n.get("Sim, faço questão"), value: "2" },
      { label: I18n.get("Sim, mas não obrigatório"), value: "1" },
      { label: I18n.get("Não"), value: "0" },
    ];

    const brandSelected = this.props.client?.brands?.find(
      (b) => b.id === this.props?.brandId
    );

    const productSelected = brandSelected?.products?.find(
      (p) => p.id === this.props.productId
    );

    const getSpecificRegionValues = () => {
      switch (this.props.client?.country?.code) {
        case "BRA":
          return constants.BRA_REGIONS;
        case "USA":
          return constants.USA_REGIONS;
        // Latam
        case "MEX":
          return constants.LATAM_REGIONS;
        case "CRI":
          return constants.LATAM_REGIONS;
        case "SLV":
          return constants.LATAM_REGIONS;
        case "GTM":
          return constants.LATAM_REGIONS;
        case "URY":
          return constants.LATAM_REGIONS;
        case "PAN":
          return constants.LATAM_REGIONS;
        case "CHL":
          return constants.LATAM_REGIONS;
        case "ECU":
          return constants.LATAM_REGIONS;
        case "ARG":
          return constants.LATAM_REGIONS;
        case "COL":
          return constants.LATAM_REGIONS;

        default:
          return [];
      }
    };

    return (
      <>
        <form onSubmit={this.submitForm.bind(this)}>
          {this.props.hasPreferredPartners && (
            <div className="form-group">
              <h1 className="title-bpool medium">
                {I18n.get("Escolha o Parceiro")}
              </h1>

              <br />

              <div className="field">
                <div className="bp-group-radio" data-ref="Diversidade">
                  <Radio.Group
                    onChange={(e) => this.onChangeSelectPartner(e)}
                    value={this.state.form.selectPartnerRadio}
                  >
                    <Radio
                      className="vertical"
                      value="F"
                      disabled={!this.props.hasPreferredPartners}
                    >
                      {I18n.get("Quero conhecer novos Parceiros Criativos.")}
                    </Radio>

                    <Radio
                      className="vertical"
                      value="T"
                      disabled={!this.props.hasPreferredPartners}
                    >
                      {I18n.get(
                        "Quero trabalhar com parceiro criativo que já tenho definido."
                      )}
                    </Radio>

                    <Radio
                      className="vertical"
                      value="One"
                      disabled={!this.props.hasPreferredPartners}
                    >
                      {I18n.get(
                        "Quero que uma das opções seja um parceiro que trabalhei."
                      )}
                    </Radio>
                  </Radio.Group>
                </div>
              </div>

              {this.state.form.selectPartnerRadio == "T" && (
                <>
                  <div style={{ marginBottom: 40 }}>
                    <SelectOne
                      options={this.props?.preferredPartners}
                      value={this.state.form.selectPartnerId}
                      onChange={(value) =>
                        this.onChangeFormValue("selectPartnerId", value)
                      }
                      placeholder={I18n.get("Selecione um parceiro abaixo")}
                      error=""
                    />

                    {/* <SelectSearchField
                      {...this.state.fields.selectPartnerId}
                      value={this.state.form.selectPartnerId}
                      error={this.state.errors.selectPartnerId}
                      values={this.props.preferredPartners.map((item) => ({
                        ...item,
                        data: item,
                      }))}
                    /> */}
                  </div>
                </>
              )}

              {this.state.form.selectPartnerRadio == "One" && (
                <div>
                  <SelectOne
                    options={this.props?.preferredPartners}
                    value={this.state.form.alreadyWorkedPartnerId}
                    onChange={(value) => this.onChangeWorkedPartnerId(value)}
                    placeholder={I18n.get("Selecione um parceiro abaixo")}
                    error=""
                  />

                  {/* <SelectSearchField
                    {...this.state.fields.alreadyWorkedPartnerId}
                    value={this.state.form.alreadyWorkedPartnerId}
                    error={this.state.errors.alreadyWorkedPartnerId}
                    values={this.props.preferredPartners.map((item) => ({
                      ...item,
                      data: item,
                    }))}
                  /> */}
                </div>
              )}

              <br />
            </div>
          )}

          {(!this.props.hasPreferredPartners ||
            this.state.form.selectPartnerRadio == "F" ||
            this.state.form.selectPartnerRadio == "One") && (
            <div className="form-group" style={{ marginTop: "20px" }}>
              <br />
              <br />
              <h1 className="title-bpool medium">
                {I18n.get("Novos Parceiros")}
              </h1>

              {this.props?.client?.country?.code === "BRA" ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 16,
                    }}
                  >
                    <h2
                      className="title-bpool small"
                      style={{ margin: 0, position: "relative", top: "3px" }}
                    >
                      {I18n.get("SOS Rio Grande do Sul")}
                    </h2>
                    <img
                      src={FlagRS}
                      alt="Bandeira do Rio Grande do Sul"
                      style={{ marginLeft: 16 }}
                    />
                  </div>

                  <p className="regular-control-text">
                    {I18n.get(
                      "Neste momento delicado que vive o RS, quer dar preferência por parceiros daquele estado para apoiar a economia local?"
                    )}
                  </p>
                  <p
                    className="regular-control-text"
                    style={{ margin: "16px 0 10px 0", fontWeight: 600 }}
                  >
                    {I18n.get(
                      "Se sim, vamos procurar inserir ao menos um parceiro do RS na sua seleção"
                    )}
                  </p>

                  <div
                    className="field"
                    data-ref="Se sim, vamos procurar inserir ao menos um parceiro do RS na sua seleção"
                  >
                    <div className="bp-group-radio">
                      <Radio.Group
                        {...this.state.fields.sosrs}
                        value={this.state.form.sosrs}
                      >
                        <Radio value={true}>{I18n.get("Sim")}</Radio>

                        <Radio value={false}>{I18n.get("Não")}</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </>
              ) : null}

              <p className="regular-control-text" style={{ marginTop: 32 }}>
                {I18n.get(
                  "Gostaria somente de parceiros de alguma região específica?"
                )}
              </p>

              <br />

              <div
                className="field"
                data-ref="Gostaria somente de parceiros de alguma região específica?"
              >
                <div className="bp-group-radio">
                  <Radio.Group
                    {...this.state.fields.specificRegionOnly}
                    value={this.state.form.specificRegionOnly}
                  >
                    <Radio value={true}>{I18n.get("Sim")}</Radio>

                    <Radio value={false}>{I18n.get("Não")}</Radio>
                  </Radio.Group>
                </div>
              </div>

              {this.state.form.specificRegionOnly === true && (
                <>
                  <br />
                  {/* <SelectField
                    {...this.state.fields.specificRegion}
                    label={I18n.get("Região Específica")}
                    value={this.state.form.specificRegion}
                    error={this.state.errors.specificRegion}
                    values={getSpecificRegionValues()}
                  /> */}

                  <SelectOne
                    options={getSpecificRegionValues()}
                    value={this.state.form.specificRegion}
                    onChange={(value) =>
                      this.onChangeForm("specificRegion", value)
                    }
                    placeholder={I18n.get("Região Específica")}
                    error=""
                  />

                  <br />
                </>
              )}

              <br />
              <br />
              <h1 className="title-bpool medium">{I18n.get("Expertise")}</h1>
              <p className="regular-control-text">
                {I18n.get(
                  "Busco somente parceiros com expertise na categoria da minha marca:"
                )}
              </p>

              <br />

              <div
                className="field"
                data-ref="Busco somente parceiros com expertise na categoria da minha marca:"
              >
                <div className="bp-group-radio">
                  <Radio.Group
                    {...this.state.fields.sameCategoryOnly}
                    value={this.state.form.sameCategoryOnly}
                  >
                    <Radio value={true}>{I18n.get("Sim")}</Radio>

                    <Radio value={false}>{I18n.get("Não")}</Radio>
                  </Radio.Group>
                </div>
              </div>

              <p className="regular-control-text">
                {I18n.get("Busco parceiros com outros expertises?")}
              </p>

              <br />

              <div
                className="field"
                data-ref="Busco parceiros com outros expertises?"
              >
                <div className="bp-group-radio">
                  <Radio.Group
                    {...this.state.fields.hasAdditionalCategories}
                    value={this.state.form.hasAdditionalCategories}
                  >
                    <Radio value={true}>{I18n.get("Sim")}</Radio>

                    <Radio value={false}>{I18n.get("Não")}</Radio>
                  </Radio.Group>
                </div>
              </div>

              <br />

              {/* <SelectSearchField
                {...this.state.fields.categoryIds}
                label={I18n.get("Categoria")}
                value={this.state.form.categoryIds}
                error={this.state.errors.categoryIds}
                isMulti
                values={this.getCategoryValues()}
                disabled={!this.state.form.hasAdditionalCategories}
              /> */}

              <SelectOne
                options={
                  this.props.bp.industries ? this.getCategoryValues() : []
                }
                value={this.state.form?.categoryIds || []}
                defaultValue=""
                onChange={(value) =>
                  this.onChangeFormValue("categoryIds", value)
                }
                required
                placeholder={I18n.get("Categoria")}
                error={this.state.errors.categoryIds}
                multiple
                disabled={!this.state.form.hasAdditionalCategories}
              />

              <br />
              <br />
              <h1 className="title-bpool medium">{I18n.get("Diversidade")}</h1>
              <p className="regular-control-text">
                {I18n.get(
                  "Quero conhecer ao menos um parceiro que tenha pluralidade no time de liderança ou quadro societário."
                )}
              </p>

              <br />

              <div className="field is-horizontal">
                <div
                  className="field-body bp-group-radio"
                  data-ref="Diversidade"
                >
                  <Radio.Group
                    options={diversidade}
                    onChange={(e) => this.handleSetDiversity(e)}
                    value={this.state.form.diversity}
                  />
                </div>
              </div>

              <br />
              {/* <SelectSearchField
                {...this.state.fields.diversitySpecificOwner}
                label={I18n.get("Representantes de Owner")}
                isMulti
                values={this.getDiversityOwnerValues()}
                value={this.state.form.diversitySpecificOwner}
                error={this.state.errors.diversitySpecificOwner}
                disabled={this.state.form.diversity == 0}
              /> */}

              <SelectOne
                options={this.getDiversityOwnerValues()}
                value={this.state.form?.diversitySpecificOwner}
                defaultValue=""
                onChange={(value) =>
                  this.onChangeFormValue("diversitySpecificOwner", value)
                }
                placeholder={I18n.get("Representantes de Owner")}
                error={this.state.errors.diversitySpecificOwner}
                multiple
                disabled={this.state.form.diversity == 0}
              />

              <br />
              {/* <SelectSearchField
                {...this.state.fields.diversitySpecific}
                label={I18n.get("Representantes de Owner ou Led")}
                isMulti
                values={this.getDiversityValues()}
                value={this.state.form.diversitySpecific}
                error={this.state.errors.diversitySpecific}
                disabled={this.state.form.diversity == 0}
              /> */}

              <SelectOne
                options={this.getDiversityOwnerValues()}
                value={this.state.form?.diversitySpecific}
                defaultValue=""
                onChange={(value) =>
                  this.onChangeFormValue("diversitySpecific", value)
                }
                placeholder={I18n.get("Representantes de Owner ou Led")}
                error={this.state.errors.diversitySpecific}
                multiple
                disabled={this.state.form.diversity == 0}
              />
            </div>
          )}

          <br />
        </form>
        {/*  */}
        {this.props.projectType === 0 &&
          productSelected &&
          productSelected?.global &&
          this.state.form.selectPartnerRadio == "T" && (
            <GlobalFileBox
              title={I18n.get("Atenção!")}
              description={I18n.get(
                "Esta é uma marca Global. Anexe aqui a aprovação de global para este projeto."
              )}
              username={this.props.auth.username}
              action={this.saveGlobal}
              file={this.props.globalAuthorization}
            />
          )}
      </>
    );
  }
}

export default PartnerInfoForm;
