import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Drawer as DrawerAntd } from "antd";
import { useBpayAdmin } from "../../../../../../../hooks/useBpay/useBpayAdmin.hook";
import { Drawer } from "../../../../../../../components/UI/Drawer";
import { ButtonBpool } from "../../../../../../../components/UI/ButtonBpool";
import { Textarea } from "../../../../../../../components/UI/Textarea";
import { NotificationBPool } from "../../../../../../../components/UI/NotificationBPool";
import { InfosDrawer } from "./InfosDrawer";
import { hasDataBilling } from "../../utils/hasDataBilling";
import * as S from "../styles";

export const DrawerBillingInvoice = ({
  drawer,
  handleCloseDrawer,
  refetch,
}) => {
  const {
    getBillingDataInternalToDrawer,
    putTextBankAccountWrong,
    deleteNFWithDescription,
  } = useBpayAdmin();
  const [childrenDrawer, setChildrenDrawer] = useState({ open: false });
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({});
  const [isSendingWrongReason, setIsSendingWrongReason] = useState(false);
  const [bankAccountWrongReason, setBankAccountWrongReason] = useState("");

  const handleOpenDrawer = async ({ bpayId, bPaymentId }) => {
    try {
      setIsLoadingDrawer(true);
      const response = await getBillingDataInternalToDrawer({
        bpayId,
        bPaymentId,
      });
      const dataBilling = hasDataBilling(response?.data);
      setDataDrawer({ ...response?.data, dataBilling: dataBilling });
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao carregar as informações"),
        duration: 3,
      });
    } finally {
      setIsLoadingDrawer(false);
    }
  };

  const sendWrongReason = async () => {
    try {
      setIsSendingWrongReason(true);
      const objSend = {
        bPaymentId: dataDrawer?.bPaymentId,
        bPayId: dataDrawer?.bPayId,
        bankAccountWrongReason,
      };
      await putTextBankAccountWrong(objSend);
      refetch();
      handleOpenDrawer({
        bpayId: dataDrawer?.bPayId,
        bPaymentId: dataDrawer?.bPaymentId,
      });
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("Informações atualizadas"),
        duration: 3,
      });
      setChildrenDrawer({ open: false });
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao enviar informações"),
        duration: 3,
      });
    } finally {
      setIsSendingWrongReason(false);
    }
  };

  const sendDeleteNF = async () => {
    try {
      setIsSendingWrongReason(true);
      const objSend = {
        bPaymentId: dataDrawer?.bPaymentId,
        bPayId: dataDrawer?.bPayId,
        description: bankAccountWrongReason,
      };
      await deleteNFWithDescription(objSend);
      refetch();
      handleOpenDrawer({
        bpayId: dataDrawer?.bPayId,
        bPaymentId: dataDrawer?.bPaymentId,
      });
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("NF excluída com sucesso"),
        duration: 3,
      });
      setChildrenDrawer({ open: false });
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao excluir a NF"),
        duration: 3,
      });
    } finally {
      setIsSendingWrongReason(false);
    }
  };

  useEffect(() => {
    if (drawer?.open === true) {
      handleOpenDrawer({
        bpayId: drawer?.bpayId,
        bPaymentId: drawer?.bPaymentId,
      });
    }
  }, [drawer?.open]);

  return (
    <>
      <Drawer
        open={drawer.open}
        handleClose={handleCloseDrawer}
        width={525}
        buttons={
          <>
            <ButtonBpool
              text={I18n.get("Notificar conta errada")}
              onClick={() => setChildrenDrawer({ open: true, type: 1 })}
              theme="tertiary"
              loading={false}
              disabled={dataDrawer?.status === 2 ? false : true}
            />
            <div style={{ marginLeft: 16 }}>
              <ButtonBpool
                text={I18n.get("Excluir nota")}
                onClick={() => setChildrenDrawer({ open: true, type: 2 })}
                theme="secondary"
                loading={false}
                disabled={
                  dataDrawer?.status === 2 || dataDrawer?.status === 4
                    ? false
                    : true
                }
              />
            </div>
          </>
        }
      >
        {drawer.open ? (
          <InfosDrawer
            data={{
              ...dataDrawer,
              specificInformations: drawer?.specificInformations,
            }}
            loading={isLoadingDrawer}
            handleCloseDrawer={handleCloseDrawer}
            refetch={refetch}
          />
        ) : null}

        <Drawer
          open={childrenDrawer.open}
          handleClose={() => setChildrenDrawer({ open: false })}
          width={480}
          buttons={<></>}
        >
          <div>
            <S.H3>
              {I18n.get(
                childrenDrawer.type === 1
                  ? "Para prosseguir, você deve notificar o parceiro sobre a conta errada"
                  : "Para prosseguir, você deve notificar o parceiro sobre a exclusão e o motivo"
              )}
            </S.H3>
            <S.Label>
              {I18n.get(
                childrenDrawer.type === 1 ? "Notificação" : "Motivo da exclusão"
              )}
            </S.Label>
            <Textarea
              id="bankAccountWrongReason"
              label={I18n.get("Escreva aqui")}
              rows={2}
              defaultValue={bankAccountWrongReason}
              onChange={(value) => setBankAccountWrongReason(value)}
              error=""
            />
            <div style={{ display: "flex" }}>
              <ButtonBpool
                full
                theme="tertiary"
                onClick={() => setChildrenDrawer({ open: false })}
                text={I18n.get("Cancelar")}
                loading={false}
              />
              <div style={{ marginLeft: 16 }}>
                <ButtonBpool
                  full
                  theme="primary"
                  onClick={
                    childrenDrawer.type === 1
                      ? () => sendWrongReason()
                      : () => sendDeleteNF()
                  }
                  text={I18n.get(
                    childrenDrawer.type === 1
                      ? "Enviar notificação"
                      : "Confirmar exclusão"
                  )}
                  disabled={bankAccountWrongReason ? false : true}
                  loading={isSendingWrongReason}
                />
              </div>
            </div>
          </div>
        </Drawer>
      </Drawer>
    </>
  );
};
