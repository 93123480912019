import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid;
  border-radius: 10px;
  width: calc(100% - 300px);
  padding: 25px 0;
  font-weight: 700;
  font-size: 20px;

  background-color: ${(props) =>
    props.active ? "#1883ff" : props.error ? "#ffffff" : "#ffffff"};
  border-color: ${(props) =>
    props.active ? "#ffffff" : props.error ? "#ff0000" : "#1883ff"};
  color: ${(props) =>
    props.active ? "#ffffff" : props.error ? "#ff0000" : "#1883ff"};

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const Expanded = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ExpandedContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextExpanded = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 35px 0 40px 0;
`;

export const WrapperUserMaster = styled.div`
  width: 100%;
  height: 100%;
  max-height: 300px;
  padding: 15px;
  overflow-y: auto;
  background: #ffffff;
  border: 0.5px solid #dadada;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.101557);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const WrapperCompany = styled.div`
  width: 100%;
  height: 100%;
  max-height: 300px;
  padding: 15px;
  margin-top: 30px;
  overflow-y: auto;
  background: #ffffff;
  border: 0.5px solid #dadada;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.101557);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleSectioon = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #2f2f2f;
  margin-top: 10px;
  text-align: center;
`;

export const Subs = styled.ul`
  /* height: 330px; */
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  li {
    display: inline-block;
    margin: 6px 4px;
    width: calc(100% / 2 - 8px);
  }
`;

export const ButtonSub = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid;
  border-color: #2f2f2f;
  border-radius: 17.5px;
  padding: 8px 20px;
  flex-wrap: wrap;

  background-color: ${(props) => (props.active ? "#ffffff" : "#ffffff")};
  border-color: ${(props) => (props.active ? "#1883ff" : "#ccc")};

  div.selectBox {
    display: flex;
    width: 100%;
    margin-top: 20px;

    .item {
      width: 130px;
      margin-right: 10px;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const SubLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
`;
