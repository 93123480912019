
const status = {

    ProjectStatus: { 0: "Nenhum",
                     1: "Rascunho",
                     2: "Criado",
                     3: "Pré-Selecionados",
                     4: "Selecionados",
                     5: "Parceiro Definido",
                     6: "Definições",
                     7: "Iniciado",
                     20: "Finalizado",
                     90: "Cancelado" },
    
    ClientStatus: { 0: "Nenhum",
                     1: "Incompleto",
                     2: "Criado",
                     4: "Background-Check",
                     7: "Aprovado",
                     90: "Rejeitado",
                     91: "Inativo" },                  

    PartnerStatus: { 0: "Nenhum",
                     1: "Incompleto",
                     2: "Criado",
                     3: "Referenciado",
                     4: "Background-Check",
                     5: "Pré-aprovado",
                     6: "Avaliação",
                     7: "Aprovado",
                     8: "Video-Enviado",
                     90: "Rejeitado",
                     91: "Inativo" },
}

export default status