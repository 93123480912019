import api_get_status_dash from "./get";
import api_get_projects_dash from "./get_projects";
import api_get_specs_dash from "./get_specs";
import api_get_assets_dash from "./get_assets";
import api_get_warning_dash from "./get_warning";

import api_get_brands_dash from "./get_brands";
import api_get_brand_dash from "./get_brand";

import api_get_historic_dash from "./get_historic";
import api_get_historic_item_dash from "./get_historic_item";
import api_get_historic_month_dash from "./get_historic_month";

import api_get_partners_dash from "./get_partners";
import api_get_partners_dash2 from "./get_partners2";
import api_get_partner_dash from "./get_partner";

import api_get_clients_dash from "./get_clients";
import api_get_client_dash from "./get_client";

// GET - abrindo item unico
import api_get_project_dash from "./get_project";
import api_get_spec_dash from "./get_spec";
import api_get_asset_dash from "./get_asset";

import api_get_briefings_dash from "./get_briefings";
import api_get_briefing_dash from "./get_briefing";

import api_get_savings_dash from "./get_savings";
import api_get_saving_dash from "./get_saving";

import get_filter_saving from "./get_filters_saving";
import get_data_saving from "./get_data_saving";
import get_data_export from "./get_data_export";
import get_item_saving from "./get_saving_item";
import get_item_asset from "./get_saving_asset";
import get_item_spec from "./get_saving_spec";

import api_get_extras_and_project from "./get_extras_end_project";
import api_get_projectextras_item from "./get_projectextras_item";

// DELETE ITEM
import delete_itens_dash from "./delete_itens";
import reset_itens_dash from "./reset_itens";

// DASHBOARD INFOS V2 -
import api_get_dashboard_infos from "./get_dashboard_infos";
export const DASHBOARD_INFOS = "dashboard_infos";

export const REPORT_GET = "status_report_get";
export const PROJECTS_GET = "projects_report_get";

export const SPECS_GET = "specs_report_get";
export const SPEC_GET = "spec_report_get";

export const ASSETS_GET = "assets_report_get";
export const ASSET_GET = "asset_report_get";

export const BRIEFINGS_GET = "briefings_report_get";
export const BRIEFING_GET = "briefing_report_get";

export const SAVINGS_GET = "savings_report_get";
export const SAVING_GET = "saving_report_get";
export const SAVING_EXPORT_GET = "saving_export_get";

export const WARNING_GET = "warning_report_get";
export const BRANDS_GET = "brands_report_get";

export const HISTORIC_GET = "historic_report_get";
export const HISTORIC_ITEM_GET = "historic_item_report_get";
export const HISTORIC_MONTH_GET = "historic_month_report_get";

export const ITENS_DELETE = "itens_report_delete";

export const PARTNERS_GET = "partners_report_get";
export const PARTNER_GET = "partner_report_get";

export const CLIENTS_GET = "clients_report_get";
export const CLIENT_GET = "client_report_get";

// OPEN  REPORT
export const PROJECT_GET = "project_report_get";

export const REPORT_API = {
  get: api_get_status_dash,
  get_projects: api_get_projects_dash,
  get_specs: api_get_specs_dash,
  get_spec: api_get_spec_dash,
  get_assets: api_get_assets_dash,
  get_asset: api_get_asset_dash,
  get_briefings: api_get_briefings_dash,
  get_briefing: api_get_briefing_dash,
  get_warning: api_get_warning_dash,
  get_partners: api_get_partners_dash,
  get_partners2: api_get_partners_dash2,
  get_partner: api_get_partner_dash,
  get_clients: api_get_clients_dash,
  get_client: api_get_client_dash,
  get_brands: api_get_brands_dash,
  get_brand: api_get_brand_dash,
  get_historic: api_get_historic_dash,
  get_historic_item: api_get_historic_item_dash,
  get_historic_month: api_get_historic_month_dash,
  get_project: api_get_project_dash,
  delete_itens: delete_itens_dash,
  reset_itens: reset_itens_dash,
  get_savings: api_get_savings_dash,
  get_saving: api_get_saving_dash,
  get_filter_saving: get_filter_saving,
  get_data_saving: get_data_saving,
  get_data_export: get_data_export,
  get_item_saving: get_item_saving,
  get_item_asset: get_item_asset,
  get_item_spec: get_item_spec,
  get_extras_and_project: api_get_extras_and_project,
  get_projectextras_item: api_get_projectextras_item,
  get_dashboard_infos: api_get_dashboard_infos,
};
