import React, { Component } from "react";
import { connect } from "react-redux";
//
import moment from "moment";

import Config from "./config";

import { HashRouter as Router, Route } from "react-router-dom";

import * as createHistory from "history";

import { Auth, I18n, Hub } from "aws-amplify";

//CSS FRAMEWORK ANT.DESIGN
import "antd/dist/antd.css";

//SLICK CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Ant-theme.css";

import "./Responsive.css";
import "./bpool-theme.css";

import { BP_API } from "./actions/bp/actions";
import { AUTH_API } from "./actions/auth/actions";
import { PARTNER_API } from "./actions/partner/actions";
import { PARTNER_WHATIF_API } from "./actions/partner-whatif/actions";
import { CLIENT_API } from "./actions/client/actions";
import { LEADER_API } from "./actions/leader/actions";
import { CLIENT_USER_API } from "./actions/client-user/actions";
import { CHAT_API } from "./actions/chat/actions";
import { WIKIS_API } from "./actions/wikis/actions";
import { NOTIFICATIONS } from "./actions/notifications/actions";

import { addChatNotification } from "./v2/store/actions/chatNotifications";

import Home from "./components/pages/home";
import Profile from "./components/pages/profile";
import InitialPage from "./components/pages/initial/index";
import NewsPage from "./components/pages/news/index";
import NewsInternal from "./components/pages/news/news-internal/index";

// import LoginForm from './components/auth/login-form';
import { Login } from "./v2/pages/RegistrationFlow/Login";
import FirstAccessForm from "./components/auth/first-access-form";
import ForgotPasswordForm from "./components/auth/forgot-password-form";
import ResetPasswordForm from "./components/auth/reset-password-form";

import NotificationListPage from "./components/notification/notification-list-page";

//PARTNER
import PartnerEmailSent from "./components/partner/email-sent";
import PartnerSignupVideo from "./components/partner/signup-video";
import PartnerVerifyEmail from "./components/partner/verify-email";
import PartnerSignupThankYou from "./components/partner/signup-thankyou";

//PARTNER-PRE-SIGNUP
import PartnerSignupThankYouPreSignup from "./components/partner/signup-thankyou-pre-signup";

//PARTNER-ADMIN
import PartnerProjectListAdmin from "./components/partner/admin/project-list-page";
import PartnerProjectInviteAdmin from "./components/partner/admin/project-invite-page";
import PartnerProjectInviteDeclineAdmin from "./components/partner/admin/project-invite-decline-page";
import PartnerResponsibleAdmin from "./components/partner/responsible-admin";
import PartnerIdiomaAdmin from "./components/partner/idioma-admin";
import PartnerCompanyAdmin from "./components/partner/company-admin";
import PartnerSpecialismAdmin from "./components/partner/specialism-admin";
import PartnerExpertiseAdmin from "./components/partner/expertise-admin";
import PartnerClientsCasesAwardsAdmin from "./components/partner/clients-cases-awards-admin";
import PartnerCountriesServedAdmin from "./components/partner/countries-served-admin";

import PartnerLeadersAdmin from "./components/partner/leaders-admin";
import PartnerReferencesAdmin from "./components/partner/references-admin";
import PartnerPreview from "./components/partner/admin/preview-page";
import PartnerBillingListAdmin from "./components/partner/admin/billing-list-page";
import InvoiceInstructions from "./v2/pages/Partner/Billings/InvoiceInstructions";
import PartnerBillingDetailsAdmin from "./components/partner/admin/billing-details";
import PartnerBillingDetailsAdminExtra from "./components/partner/admin/billing-details-extra";
// v2 faturamento
import { PoBillingPartner } from "./v2/pages/Partner/Billings/Po";
import { ExtrasBillingPartner } from "./v2/pages/Partner/Billings/Extras";

// PARTNER EVENT INVITATION
import PartnerProjectInvitationStep1 from "./components/partner-project-invitation/project-event-step1";
import PartnerProjectInvitationStep2 from "./components/partner-project-invitation/project-event-step2";
import PartnerProjectInvitationStep3 from "./components/partner-project-invitation/project-event-step3";
import PartnerProjectInvitationStep4 from "./components/partner-project-invitation/project-event-step4";
import PartnerProjectInvitationThankYou from "./components/partner-project-invitation/project-event-thankyou";

// PARTNER BPITCH/PROPOSAL NDA e OPT-OUT

import PartnerProjectBPitchMatch from "./components/partner-project-invitation/project-bpitch-match";
import PartnerProjectProposalMatch from "./components/partner-project-invitation/project-proposal-match";

//CLIENT
import ClientSignUp from "./components/client/signup";
import ClientEmailSent from "./components/client/email-sent";
import ClientVerifyEmail from "./components/client/verify-email";
import ClientSignupStep1 from "./components/client/signup-step1";
import ClientSignupStep2 from "./components/client/signup-step2";
import ClientSignupStep3 from "./components/client/signup-step3";
import ClientSignupStep4 from "./components/client/signup-step4";
import ClientSignupStep5 from "./components/client/signup-step5";
import ClientSignupThankYou from "./components/client/signup-thankyou";
import ClientSignupTerms from "./components/client/signup-terms";

//CLIENT-ADMIN
import ClientReports from "./modules/Reports/ClientReports";
import ClientReportsEvents from "./modules/Reports/ClientReportsEvents";
import ClientPendingCentral from "./modules/Reports/ClientPendingCentral";
import ClientSavings from "./modules/Reports/ClientSavings";
import PartnerSavings from "./modules/Reports/PartnerSavings";

//PARTNER-ADMIN
import PartnerReports from "./modules/Reports/PartnerReports";
import PartnerReportsEvents from "./modules/Reports/PartnerReportsEvents";
import PartnerPendingCentral from "./modules/Reports/PartnerPendingCentral";

import ClientResponsibleAdmin from "./components/client/responsible-admin";
import ClientUserAdmin from "./components/client/user-client-admin";
import ClientCompanyAdmin from "./components/client/company-admin";
import ClientPlanAdmin from "./components/client/plan-admin";
import ClientContractPaymentAdmin from "./components/client/contract-payment-admin";
import ClientBrandsUsersAdmin from "./components/client/brands-users-admin";
import ClientMasterUsersAdmin from "./components/client/master-users-admin";
import ClientIdiomaAdmin from "./components/client/idioma-admin";

import ClientProjectListAdmin from "./components/client/admin/project-list-page";
import ClientProjectListAdminNew from "./v2/routes/client";
import PartnerProjectListAdminNew from "./v2/routes/partner";

import ClientTepProjectListAdmin from "./components/client/admin/tep-project-list-page";
import ClientExtraListAdmin from "./components/client/admin/extra-list-page";
import PartnerTepProjectListAdmin from "./components/partner/admin/tep-project-list-page";
import PartnerExtraListAdmin from "./components/partner/admin/extra-list-page";

//CLIENT PROJECT PRODUCTION
// import ProjectProductionClientHome from './components/production-client/production-client-home';
// import ProjectProductionClientNew from './components/production-client/production-client-new';
// import ProjectProductionClientNewThankyou from './components/production-client/production-client-new-thankyou';
// import ProjectProductionClientOngoing from './components/production-client/production-client-ongoing';

//PARTNER PROJECT PRODUCTION
// import ProjectProductionPartnerHome from './components/production-partner/production-partner-home';
// import ProjectProductionPartnerOngoing from './components/production-partner/production-partner-ongoing';

import PartnerNewProjectStep1Briefing from "./components/partner-new-project/new-project-step1-briefing";
import PartnerNewProjectStep1Milestones from "./components/partner-new-project/new-project-step1-milestones";

import PartnerNewProjectStep1 from "./components/partner-new-project/new-project-step1";
import PartnerNewProjectStep2 from "./components/partner-new-project/new-project-step2";
import PartnerNewProjectSuccess from "./components/partner-new-project/new-project-thankyou";

import PartnerNewProposalStep1 from "./components/partner-new-proposal/partner-new-proposal-step1";

//CLIENT-NEW-PROJECT
import ClientNewProjectBasicInfo from "./components/client-new-project/new-project-basic-info";
import ClientNewProjectSelect from "./components/client-new-project/new-project-select";
import ClientNewProjectDemand from "./components/client-new-project/new-project-demand";
import ClientNewProjectPartner from "./components/client-new-project/new-project-partner";
import ClientNewProjectCheckout from "./components/client-new-project/new-project-checkout";
import ClientNewProjectReview from "./components/client-new-project/new-project-review";
import ClientNewProjectThankYou from "./components/client-new-project/new-project-thankyou";

//CLIENT-SELECT-PROJECT /
import ClientSelectPartnerStep1 from "./components/client-select-partner/select-partner-step1";
import ClientSelectPartnerStep2 from "./components/client-select-partner/select-partner-step2";
import ClientSelectPartnerThankYou from "./components/client-select-partner/select-partner-thankyou";
import ClientSelectPartnerResume from "./components/client-select-partner/select-partner-resume";

//CLIENT-SELECT-PARTNER EVENT
import ClientSelectPartnerEventStep1 from "./components/client-select-partner-event/select-partner-step1";
import ClientSelectPartnerEventStep2 from "./components/client-select-partner-event/select-partner-step2";
import ClientSelectPartnerEventStep3 from "./components/client-select-partner-event/select-partner-step3";
import ClientSelectPartnerEventThankYou from "./components/client-select-partner-event/select-partner-thankyou";

// PROJECT
import ProjectDefintionsStep1 from "./components/client-project-definition/project-definition-step1";
import ProjectDefintionsCreativeDetails from "./components/client-project-definition/project-definition-creative-details";
import ProjectDefintionsBiddingQuotation from "./components/client-project-definition/project-definition-bidding-quotation";
import ProjectDefintionsMilestones from "./components/client-project-definition/project-definition-milestones";
import ProjectDefintionsPO from "./components/client-project-definition/project-definition-po";
import ProjectDefintionsThankYou from "./components/client-project-definition/project-definition-thankyou";
import ProjectPOThankYou from "./components/client-project-definition/project-po-thankyou";
import ProjectDefintionsPartnerSubmitted from "./components/client-project-definition/project-definition-partner-submitted";
import ProjectDefintionsClientApproved from "./components/client-project-definition/project-definition-client-approved";
import ProjectDefintionsClientDeclined from "./components/client-project-definition/project-definition-client-declined";
import ProjectDefintionsTEPTerms from "./components/client-project-definition/project-definition-tep-terms";

// PROJECT-ONGOING
import ProjectOnGoingNfse from "./components/client-project-ongoing/project-ongoing-nfse";
import ProjectOnGoingSummary from "./components/client-project-ongoing/project-ongoing-summary";
import ProjectOnGoingBriefing from "./components/client-project-ongoing/project-ongoing-briefing";
import ProjectOnGoingFileManager from "./components/client-project-ongoing/project-ongoing-file-manager";
import ProjectOnGoingSchedule from "./components/client-project-ongoing/project-ongoing-schedule";
import ProjectOnGoingQuotation from "./components/client-project-ongoing/project-ongoing-quotation";
import ProjectOnGoingPO from "./components/client-project-ongoing/project-ongoing-po";
import ProjectOnGoingEnd from "./components/client-project-ongoing/project-ongoing-end";
import ProjectOnGoingEnded from "./components/client-project-ongoing/project-ongoing-ended";
import ProjectOnGoingCancel from "./components/client-project-ongoing/project-ongoing-cancel";
import ProjectOnGoingCancelled from "./components/client-project-ongoing/project-ongoing-cancelled";
import ProjectOnGoingExtras from "./components/client-project-ongoing/project-ongoing-extras";
import ProjectOnGoingExtrasNew from "./components/client-project-ongoing/project-ongoing-extras-new";
import ProjectOnGoingExtrasEdit from "./components/client-project-ongoing/project-ongoing-extras-edit";
import ProjectOnGoingExtrasDetails from "./components/client-project-ongoing/project-ongoing-extras-details";
import ProjectOnGoingExtrasQuotation from "./components/client-project-ongoing/project-ongoing-extras-quotation";
import ProjectOnGoingExtrasPO from "./components/client-project-ongoing/project-ongoing-extras-po";
import ProjectOnGoingExtrasEnd from "./components/client-project-ongoing/project-ongoing-extras-end";

import ProjectResumeSimple from "./components/client-project-ongoing/project-resume-simple";

// CLIENT-USER
import ClientUserSignupStep1 from "./components/client-user/signup-step1";
import ClientUserSignupThankYou from "./components/client-user/signup-thankyou";

// CLIENT-USER ADMIN
import ClientUserResponsibleAdmin from "./components/client-user/responsible-admin";

//LEADER
import LeaderSignupStep1 from "./components/leader/signup-step1";
import LeaderSignupStep2 from "./components/leader/signup-step2";
import LeaderSignupStep3 from "./components/leader/signup-step3";
import LeaderSignupThankYou from "./components/leader/signup-thankyou";

//LEADER-ADMIN
import LeaderResponsibleAdmin from "./components/leader/responsible-admin";
import LeaderExpertiseAdmin from "./components/leader/expertise-admin";
import LeaderCasesAwardsAdmin from "./components/leader/cases-awards-admin";

// REFERENCE
import ReferenceReviewStep1 from "./components/reference/review-step1";
import ReferenceReviewStep2 from "./components/reference/review-step2";
import ReferenceReviewThankYou from "./components/reference/review-thankyou";

// BPOOL-ADMIN
import BillingListAdmin from "./components/bpool/billing-list-admin";
import BillingDetails from "./components/bpool/billing-details";
import BillingDetailsExtra from "./components/bpool/billing-details-extra";

// Repasse
import BillingTransferListAdmin from "./components/bpool/billing-transfer-list-admin";

// Extraordinario
import SupplierBillingListAdmin from "./components/bpool/supplier-billing/supplier-billing-list-admin";

import DashboardAdmin from "./components/bpool/dashboard-admin";
import DashboardAdminA from "./components/bpool/dashboard-admin-a";
import DashboardAdminB from "./components/bpool/dashboard-admin-b";
import DashboardAdminC from "./components/bpool/dashboard-admin-c";
import DashboardAdminD from "./components/bpool/dashboard-admin-d";
import ReportsAdmin from "./components/bpool/reports-admin";
import MeetingsAdmin from "./components/bpool/meetings-admin";
import WikisAdmin from "./components/bpool/knowledge-admin";
import { NotificationsAdmin } from "./v2/pages/Admin/Notifications";
import WikisAdminSections from "./components/bpool/knowledge-sections-admin";
import WikisAdminCategories from "./components/bpool/knowledge-category-admin";
import WikisAdminContents from "./components/bpool/knowledge-contents-admin";
import UserListAdmin from "./components/bpool/user-list-admin";
import LoginsListAdmin from "./components/bpool/logins-list-admin";
import UserNewAdmin from "./components/bpool/user-new-admin";
import PartnerAdmin from "./components/bpool/partner-admin";
import SupplierAdmin from "./components/bpool/supplier-admin";
import PartnerVipAdmin from "./components/bpool/partner-vip-admin";
import PartnerDetails from "./components/bpool/partner-details";
import PartnerEvaluation from "./components/bpool/partner-evaluation";
import PartnerEdit from "./components/bpool/partner-edit";
import PartnerPreSelection from "./components/bpool/partner-preselection";
import PartnerFinalApproval from "./components/bpool/partner-final-approval";
import { PartnerScoreAdmin } from "./v2/pages/Admin/PartnerScore";

import ClientListAdmin from "./components/bpool/client-list-admin";
import ClientDetails from "./components/bpool/client-details";
import ClientEdit from "./components/bpool/client-edit";
import ClientCustomize from "./components/bpool/client-customize";
import BPoolClientProjectListAdmin from "./components/bpool/client-project-list-admin";
import BPoolClientProjectAudioListAdmin from "./components/bpool/client-project-audio-list-admin";
import BPoolClientMilestoneListAdmin from "./components/bpool/client-milestones-list-admin";
import BPoolClientExtraListAdmin from "./components/bpool/client-extra-list-admin";
import BPoolClientProjectListFullAdmin from "./components/bpool/client-project-list-full-admin";
import BPoolClientProjectKanbanAdmin from "./components/bpool/client-project-kanban-admin";
import BPoolClientProjectDetails from "./components/bpool/client-project-details";
import BPoolClientProjectAudioDetails from "./components/bpool/client-audio-details";
import projectOngoingMessages from "./components/client-project-ongoing/project-ongoing-messages";
import BPoolPartnerPreview from "./components/bpool/partner/preview-page";
import Assets from "./components/bpool/assets-admin";
import ClientAssets from "./components/bpool/clients-assets-admin";
import ClientCombos from "./components/bpool/clients-combo-admin";

//WhatIf - Client
import BrandProfile from "./modules/whatif/client/brand-profile";
import RegisterPo from "./modules/whatif/client/register-po";
import RegisterPoSuccess from "./modules/whatif/client/register-po-success";
import BrandProfilePreview from "./modules/whatif/client/brand-profile-preview";
import BrandProfileSuccess from "./modules/whatif/client/brand-profile-success";
import CounterProposalSuccess from "./modules/whatif/client/counter-proposal-success";
import IdeaPreview from "./modules/whatif/client/idea-preview";
import SearchIdea from "./modules/whatif/client/search-idea";
import WhatIfHome from "./modules/whatif/client/whatif-home";

//WhatIf - Partner
import CreativeBrands from "./modules/whatif/partner/creative-brands";
import GeneralInformationForm from "./modules/whatif/partner/general-information";

import PartnerRegister from "./modules/whatif/partner/partner-register";
import PartnerRegisterSuccess from "./modules/whatif/partner/partner-register-success";

import GeneralInformationSuccess from "./modules/whatif/partner/general-information-success";

import PartnerProfile from "./modules/whatif/partner/partner-profile";
import PartnerProfileSuccess from "./modules/whatif/partner/partner-profile-success";
import PartnerProfilePreview from "./modules/whatif/partner/partner-profile-preview";

import IdeaRegister from "./modules/whatif/partner/idea-register";
import IdeaRegisterPreview from "./modules/whatif/partner/idea-register-preview";
import IdeaRegisterSuccess from "./modules/whatif/partner/idea-register-success";
import MyIdeas from "./modules/whatif/partner/my-ideas";

import IdeaView from "./modules/whatif/partner/idea-view";

import PartnerVerifyEmailWhatIf from "./modules/whatif/partner/verify-email-pre";

//Assets - Partner
import PartnerCreateAssets from "./modules/RepositoryAssets/partner/create-assets";

import Votes from "./modules/EmailVotes/ViewVotes";
import VotesPartner from "./modules/EmailVotes/ViewVotesPartner";

// NOVO REGISTRO

import Register from "./modules/Register/Register";

import I18nExample from "./components/i18n-page";

import Meeting from "./components/meeting";

// Vídeos e Faqs

import Wikis from "./modules/Wikis/Wikis";

// BPay

import FormSupplierBilling from "./components/supplier/supplier-billing-invoice-page";

//Registration Flow
import { Application } from "./v2/pages/RegistrationFlow/Application";
import { ReferenceForm } from "./v2/pages/ReferencesPartner/ReferenceForm";
import { AboutYou as LeaderAboutYou } from "./v2/pages/RegistrationFlow/Leader/AboutYou";

import RegistrationFlow from "./v2/routes/registrationFlow";

//References Partner
import ReferencesPartner from "./v2/routes/referencesPartner";

//Partner Edit Profile
import PartnerEditProfile from "./v2/routes/partnerEditProfile";

//Leader Edit Profile
import LeaderEditProfile from "./v2/routes/leaderEditProfile";

//Bpool Partner Admim V2
import BpoolPartnerAdmin from "./v2/routes/BpoolPartnerAdmin";

import ProjectPreviewPage from "./components/preview/project-page";

// BPAY 2.0
import Bpay from "./v2/routes/Bpay";
import BpayAdmin from "./v2/routes/BpayAdmin";

// Supplier routes
import SupplierRoutes from "./v2/routes/Supplier";

// Playground Components
import { PlaygroundComponents } from "./v2/pages/PlaygroundComponents";

import FileRepository from "./v2/routes/FileRepository";

import ChatwootWidget from "chatwoot";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_partner: (id) => {
      dispatch(PARTNER_API.get(id));
    },
    get_partner_whatid: (id) => {
      dispatch(PARTNER_WHATIF_API.get(id));
    },
    login_success: (user) => {
      dispatch(AUTH_API.login_success(user));
    },
    login_failure: (error) => {
      dispatch(AUTH_API.login_failure(error));
    },
    get_domain: () => {
      dispatch(BP_API.get_domain());
    },
    load_partner: () => {
      return dispatch(PARTNER_API.get());
    },
    load_partner_whatif: () => {
      dispatch(PARTNER_WHATIF_API.get());
    },
    load_client: () => {
      return dispatch(CLIENT_API.get());
    },
    load_client_user: () => {
      dispatch(CLIENT_USER_API.get());
    },
    load_leader: (email) => {
      dispatch(LEADER_API.get());
    },
    load_bpool: () => {
      dispatch(BP_API.get());
    },
    // load_master: () => {
    //   dispatch(BP_API.get());
    // },
    receive_message: (msg) => {
      dispatch(CHAT_API.receive_message(msg));
    },
    receive_notification: (msg) => {
      dispatch(CHAT_API.receive_notification(msg));
    },
    reset_notification: () => {
      dispatch(CHAT_API.reset_notification());
    },
    mark_notification: (channel, data) => {
      dispatch(CHAT_API.mark_notification(channel, data));
    },
    list_notification: (data) => {
      dispatch(CHAT_API.list_notification(data));
    },
    ping_notification: () => {
      dispatch(CHAT_API.send_ping());
    },
    chat_update_status: (data) => {
      dispatch(CHAT_API.update_status(data));
    },
    get_knowledge: (data) => {
      dispatch(WIKIS_API.get_knowledge(data));
    },
    notification_all: () => {
      dispatch(NOTIFICATIONS.notification_all());
    },
    notification_all_not_seen: () => {
      dispatch(NOTIFICATIONS.notification_all_not_seen());
    },
    notification_mark_all_seen: () => {
      dispatch(NOTIFICATIONS.notification_mark_all_seen());
    },
    chat_topics: () => {
      dispatch(CHAT_API.chat_topics());
    },
    receive_chat_topics: (topics) => {
      dispatch(CHAT_API.receive_chat_topics(topics));
    },
    chat_topic_all: (topic) => {
      dispatch(CHAT_API.chat_topic_all(topic));
    },
    receive_chat_messages: (data) => {
      dispatch(CHAT_API.receive_chat_messages(data));
    },
    receive_new_chat_message: (data) => {
      dispatch(CHAT_API.receive_new_chat_message(data));
    },
    addChatNotification: (data) => {
      dispatch(addChatNotification(data));
    },
  };
};

const history = createHistory.createBrowserHistory();

class App extends Component {
  constructor(props) {
    super();

    this.state = {
      userLogged: null,
    };

    var chatWs = undefined;

    const sendWsMessage = (msg) => {
      if (chatWs && chatWs.readyState == WebSocket.OPEN) {
        chatWs.send(msg);
      } else {
        console.warn(
          `Unable to send message '${msg}'. Ws connection is not open.`
        );
      }
    };

    const closeWs = async () => {
      if (chatWs && chatWs.readyState == WebSocket.OPEN) {
        chatWs.close();
        window.location.reload();
      }
    };

    const pingWs = () => {
      if (chatWs && chatWs.readyState == WebSocket.OPEN) {
        props.ping_notification();

        //console.log("props", props);
      }
    };

    let timeout = 10000;
    let timer = null;

    const reconnectWs = (username, token, is_client) => {
      timer = setTimeout(() => {
        props.chat_update_status({ status: I18n.get("Reconectando...") });

        openWs(username, token, is_client);
      }, timeout);
    };

    const openWs = (username, token, is_client) => {
      const url = Config.WEBSOCKET + "?token=" + token;

      let initialization = true;

      chatWs = new WebSocket(url);

      chatWs.onopen = (e) => {
        props.chat_update_status({ status: I18n.get("Conectado.") });

        props.reset_notification();

        props.notification_all_not_seen();

        props.notification_all();

        props.chat_topics();
      };

      chatWs.onmessage = (e) => {
        if (e.data == "pong") {
          return;
        }

        const dataParsed = JSON.parse(e.data);

        if (dataParsed.messageType === "notification_all") {
          var msg = e && e.data ? JSON.parse(e.data) : [];

          props.receive_notification(msg?.data);
        }

        if (dataParsed.messageType === "notification_all_not_seen") {
          var msg = e && e.data ? JSON.parse(e.data) : [];

          props.receive_notification(msg?.data);
        }

        if (dataParsed.messageType === "chat_topics") {
          var chatTopics = e && e.data ? JSON.parse(e.data) : [];

          props.receive_chat_topics(chatTopics?.data);
        }

        if (dataParsed.messageType === "chat_message_all") {
          var chatMessages = e && e.data ? JSON.parse(e.data) : [];

          props.receive_chat_messages(chatMessages?.data);
        }

        if (dataParsed.messageType === "chat_message") {
          var chatMessage = e && e.data ? JSON.parse(e.data) : [];

          if (chatMessage?.data?.length) {
            const username = this.props.auth.username;

            var notificationsChat = [];

            chatMessage.data.map((item) => {
              if (username !== item.from) {
                notificationsChat.push({
                  from: item.from,
                  message: "Você recebeu uma nova mensagem no chat.",
                  topic: chatMessage?.topic,
                  createdAt: item.createdAt,
                  id: moment(item.createdAt).valueOf(),
                });
              }
            });

            props.addChatNotification(notificationsChat);

            props.receive_new_chat_message(chatMessage?.data);
          }
        }
      };

      chatWs.onclose = (e) => {
        props.chat_update_status({ status: I18n.get("Conexão Terminada.") });

        if (!e.wasClean) {
          reconnectWs(username, token);
        }
      };

      chatWs.onerror = (err) => {
        if (err && err.code === "ECONNREFUSED") {
          reconnectWs(username, token);
        }

        props.chat_update_status({ status: I18n.get("Conexão com Erro.") });
      };

      // Faz o ping para manter a conexao ativa
      setInterval(() => {
        if (chatWs && chatWs.readyState == WebSocket.OPEN) {
          props.ping_notification();
        }
      }, 60000);

      setTimeout(() => {
        initialization = false;
      }, 60000);
    };

    window.openWs = openWs;
    window.closeWs = closeWs;
    window.sendWsMessage = sendWsMessage;
    window.ping = pingWs;

    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user) => props.login_success(user))
      .catch((err) => props.login_failure(err));

    Hub.listen("bp", (data) => {
      const { payload } = data;

      const { event, data: eventData } = payload;

      if (event == "localeChange") {
        I18n.setLanguage(eventData);

        this.forceUpdate();
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.auth == prevProps.auth &&
      this.props.auth.clientId == prevProps.auth.clientId &&
      this.props.auth.partnerId == prevProps.auth.partnerId &&
      this.props.auth.bpId == prevProps.auth.bpId &&
      this.props.auth.supplierId == prevProps.auth.supplierId &&
      this.props.auth.jwtToken == prevProps.auth.jwtToken
    ) {
      return;
    }

    if (
      !this.props.auth ||
      !this.props.auth.logged_user ||
      !this.props.auth.jwtToken
    )
      return;

    if (
      this.props.client.id?.length > 0 ||
      this.props.partner.id?.length > 0 ||
      this.props.bp.id?.length > 0
    )
      return;

    //console.log("AUTH: ", this.props.auth);

    let clientProfiles = [
      "Client",
      "ClientUserMarketing",
      "ClientUserPurchasing",
    ];

    let partnerProfiles = [
      "Partner",
      "PartnerOperation",
      "PartnerFinancial",
      "PartnerAndSupplier",
      "PartnerLeader",
    ];

    let bpoolProfiles = ["BPool", "Master"];
    let supplierProfiles = ["Supplier"];

    let profileFull = this.props.auth.profile;
    let username = this.props.auth.username;
    let name = this.props.auth.name;
    let email = this.props.auth.email;
    let companyName;
    let region;
    let profile;

    const loadPromises = [];

    if (bpoolProfiles.includes(profileFull)) {
      companyName = "BPool";
      region = "BRASIL";
      profile = "BPool";

      // Nao ta funcionando a promise
      // loadPromises.push(
      //     this.props.load_bpool().then((response) => {
      //       companyName = "BPool";
      //       region = "BRASIL";
      //       profile = "BPool";
      //     })
      // );
    }

    if (supplierProfiles.includes(profileFull)) {
      region = "BRASIL";
      profile = "Supplier";
      //companyName = "";
    }

    if (clientProfiles.includes(profileFull)) {
      loadPromises.push(
        this.props.load_client().then((response) => {
          companyName = response?.company?.name;
          region = response?.company?.country?.bpRegion;
          profile = "Client";
        })
      );
    }

    if (partnerProfiles.includes(profileFull)) {
      loadPromises.push(
        this.props.load_partner().then((response) => {
          companyName = response?.company?.name;
          region = response?.company?.country?.bpRegion;
          profile = "Partner";
        })
      );
    }

    let locale = (
      this.props.auth.locale ||
      navigator.language ||
      "pt"
    ).substring(0, 2);

    let localeChatwoot;

    switch (locale) {
      case "en":
        localeChatwoot = "en_US";
        break;
      case "es":
        localeChatwoot = "es_ES";
        break;
      default:
        localeChatwoot = "pt_BR";
        break;
    }

    Promise.all(loadPromises).then(() => {
      const userLogged = {
        username: username,
        email: email,
        name: name,
        companyName: companyName,
        region: region,
        locale: localeChatwoot,
        profile: profile,
      };

      //console.log("USER LOGGED FIRST: ", userLogged);

      this.setState({ userLogged: userLogged });
    });
  }

  render() {
    let userLogged = this.state.userLogged;

    let hostsToEnableChatwoot = ["app.bpool.co"];

    let thisHost = window.location?.hostname?.toLocaleLowerCase();

    let shouldShowOnThisEnvironment = hostsToEnableChatwoot.includes(thisHost);

    let isAdminUser = userLogged?.profile == "BPool";

    // Davi pediu pra remover nas ferias dele - 12/7/24
    // let shouldShowChatwoot =
    //   userLogged != null &&
    //   shouldShowOnThisEnvironment == true &&
    //   isAdminUser == false;

      let shouldShowChatwoot = false;

    return (
      <>
        {shouldShowChatwoot && <ChatwootWidget user={userLogged} />}

        <Router history={history}>
          <Route path="/" exact component={Home} />

          <Route path="/cliente/inicio" exact component={InitialPage} />
          <Route path="/parceiro/inicio" exact component={InitialPage} />

          <Route path="/cliente/news" exact component={NewsPage} />
          <Route path="/parceiro/news" exact component={NewsPage} />

          <Route path="/cliente/news/:slug" exact component={NewsInternal} />
          <Route path="/parceiro/news/:slug" exact component={NewsInternal} />

          <Route path="/parceiro" exact component={Application} />
          <Route path="/cliente" exact component={ClientSignUp} />

          <Route path="/primeiro-acesso" component={FirstAccessForm} />
          <Route path="/esqueci-minha-senha" component={ForgotPasswordForm} />
          <Route path="/redefinir-senha" component={ResetPasswordForm} />

          <Route path="/login" component={Login} />

          <Route path="/notificacoes" exact component={NotificationListPage} />
          <Route path="/notificacao" exact component={NotificationListPage} />

          <Route path="/cliente/email-enviado" component={ClientEmailSent} />
          <Route
            path="/cliente/verifica-email/:verificationToken"
            component={ClientVerifyEmail}
          />
          <Route path="/cliente/passo-1" component={ClientSignupStep1} />
          <Route path="/cliente/passo-2" component={ClientSignupStep2} />
          <Route path="/cliente/passo-3" component={ClientSignupStep3} />
          <Route path="/cliente/passo-4" component={ClientSignupStep4} />
          <Route path="/cliente/passo-5" component={ClientSignupStep5} />
          <Route path="/cliente/obrigado" component={ClientSignupThankYou} />
          <Route
            path="/cliente/termos/:verificationToken"
            component={ClientSignupTerms}
          />

          {/* <Route path="/cliente/dashboard-old" component={ClientDashboardAdmin} /> */}
          <Route
            exact
            path="/cliente/central-de-pendencias"
            component={ClientPendingCentral}
          />
          <Route
            exact
            path="/cliente/dashboard/eventos"
            component={ClientReportsEvents}
          />
          <Route exact path="/cliente/dashboard" component={ClientReports} />
          <Route
            exact
            path="/cliente/dashboard/savings"
            component={ClientSavings}
          />

          <Route exact path="/parceiro/dashboard" component={PartnerReports} />
          <Route
            exact
            path="/parceiro/central-de-pendencias"
            component={PartnerPendingCentral}
          />
          <Route
            exact
            path="/parceiro/dashboard/eventos"
            component={PartnerReportsEvents}
          />
          {/* <Route exact path="/parceiro/dashboard/savings" component={PartnerSavings} /> */}

          <Route
            // exact
            path="/parceiro/projetos/v2"
            component={PartnerProjectListAdminNew}
          />

          <Route
            path="/cliente/responsavel"
            component={ClientResponsibleAdmin}
          />

          <Route path="/cliente/user" component={ClientUserAdmin} />

          <Route path="/cliente/empresa" component={ClientCompanyAdmin} />
          <Route path="/cliente/plano" component={ClientPlanAdmin} />
          <Route
            path="/cliente/contrato-pagamento"
            component={ClientContractPaymentAdmin}
          />
          <Route path="/cliente/marcas" component={ClientBrandsUsersAdmin} />
          <Route path="/cliente/master" component={ClientMasterUsersAdmin} />
          <Route path="/cliente/idioma" component={ClientIdiomaAdmin} />

          <Route
            exact
            path="/cliente/projetos/"
            component={ClientProjectListAdmin}
          />

          <Route
            // exact
            path="/cliente/projetos/v2"
            component={ClientProjectListAdminNew}
          />
          <Route
            path="/cliente/contratos"
            exact
            component={ClientTepProjectListAdmin}
          />
          <Route
            path="/cliente/extras"
            exact
            component={ClientExtraListAdmin}
          />

          <Route
            path="/cliente/projetos/passo-1"
            exact
            component={ClientNewProjectBasicInfo}
          />

          <Route
            path="/cliente/projetos/:projectId/passo-1"
            component={ClientNewProjectBasicInfo}
          />
          <Route
            path="/cliente/projetos/:projectId/passo-2"
            component={ClientNewProjectSelect}
          />

          <Route
            path="/cliente/projetos/:projectId/passo-3"
            render={(routerProps) => {
              const { project = {} } = this.props;
              const { customEvent = {} } = project;

              if (project?.projectType == 0) {
                return (
                  <ClientNewProjectDemand {...this.props} {...routerProps} />
                );
              } else if (project?.projectType == 4) {
                return (
                  <ClientNewProjectCheckout {...this.props} {...routerProps} />
                );
              } else if (customEvent && customEvent.isDigital) {
                return (
                  <ClientNewProjectCheckout {...this.props} {...routerProps} />
                );
              } else {
                return (
                  <ClientNewProjectPartner {...this.props} {...routerProps} />
                );
              }
            }}
          />

          <Route
            path="/cliente/projetos/:projectId/passo-4"
            render={(routerProps) => {
              const { project = {} } = this.props;
              const { customEvent = {} } = project;

              if (project.projectType == 0) {
                return (
                  <ClientNewProjectPartner {...this.props} {...routerProps} />
                );
              } else if (project?.projectType == 4) {
                return (
                  <ClientNewProjectReview {...this.props} {...routerProps} />
                );
              } else if (customEvent && customEvent.isDigital) {
                return (
                  <ClientNewProjectReview {...this.props} {...routerProps} />
                );
              } else {
                return (
                  <ClientNewProjectCheckout {...this.props} {...routerProps} />
                );
              }
            }}
          />

          <Route
            path="/cliente/projetos/:projectId/passo-5"
            render={(routerProps) => {
              if (this.props.project && this.props.project.projectType == 0) {
                return (
                  <ClientNewProjectCheckout {...this.props} {...routerProps} />
                );
              } else {
                return (
                  <ClientNewProjectReview {...this.props} {...routerProps} />
                );
              }
            }}
          />

          <Route
            path="/cliente/projetos/:projectId/passo-6"
            component={ClientNewProjectReview}
          />
          <Route
            path="/cliente/projetos/:projectId/obrigado"
            component={ClientNewProjectThankYou}
          />

          <Route
            path="/cliente/projetos/:projectId/selecionar-resumo"
            component={ClientSelectPartnerResume}
          />

          <Route
            path="/cliente/projetos/:projectId/parceiro-passo-1"
            component={ClientSelectPartnerStep1}
          />
          <Route
            path="/cliente/projetos/:projectId/parceiro-passo-2"
            component={ClientSelectPartnerStep2}
          />
          <Route
            path="/cliente/projetos/:projectId/parceiro-selecionado"
            component={ClientSelectPartnerThankYou}
          />

          <Route
            path="/cliente/eventos/:projectId/parceiro-passo-1"
            component={ClientSelectPartnerEventStep1}
          />

          <Route
            path="/cliente/eventos/:projectId/perguntas-e-respostas"
            component={ClientSelectPartnerEventStep3}
          />
          <Route
            path="/cliente/eventos/:projectId/parceiro-passo-2"
            component={ClientSelectPartnerEventStep2}
          />
          <Route
            path="/cliente/eventos/:projectId/parceiro-selecionado"
            component={ClientSelectPartnerEventThankYou}
          />

          <Route
            path="/projetos/:projectId/detalhamento-passo-1"
            component={ProjectDefintionsStep1}
          />

          <Route
            path="/projetos/:projectId/detalhamento-passo-2"
            render={(routerProps) => {
              const { project = {} } = this.props;

              if (project.isCreative) {
                return (
                  <ProjectDefintionsMilestones
                    {...this.props}
                    {...routerProps}
                  />
                );
              } else {
                return (
                  <ProjectDefintionsBiddingQuotation
                    {...this.props}
                    {...routerProps}
                  />
                );
              }
            }}
          />

          <Route
            path="/projetos/:projectId/detalhamento-passo-3"
            render={(routerProps) => {
              const { project = {} } = this.props;

              if (project.isCreative) {
                return (
                  <ProjectDefintionsCreativeDetails
                    {...this.props}
                    {...routerProps}
                  />
                );
              } else {
                return <ProjectDefintionsPO {...this.props} {...routerProps} />;
              }
            }}
          />

          <Route
            path="/projetos/:projectId/detalhamento-passo-4"
            component={ProjectDefintionsPO}
          />
          <Route
            path="/projetos/:projectId/detalhamento-obrigado"
            component={ProjectDefintionsThankYou}
          />
          <Route
            path="/projetos/:projectId/detalhamento-po-obrigado"
            component={ProjectPOThankYou}
          />
          <Route
            path="/projetos/:projectId/detalhamento-enviado"
            component={ProjectDefintionsPartnerSubmitted}
          />
          <Route
            path="/projetos/:projectId/detalhamento-aprovado"
            component={ProjectDefintionsClientApproved}
          />
          <Route
            path="/projetos/:projectId/detalhamento-declinado"
            component={ProjectDefintionsClientDeclined}
          />

          {/* <Route path="/projetos/:projectId/detalhamento-tep/:verificationToken" component={ProjectDefintionsTEPTerms} /> */}

          <Route
            path="/projetos/:projectId/nfse"
            exact
            component={ProjectOnGoingNfse}
          />

          <Route
            path="/projetos/:projectId/resumo"
            component={ProjectOnGoingSummary}
          />

          <Route
            path="/projetos/:projectId/resumo-simples"
            component={ProjectResumeSimple}
          />

          <Route
            path="/projetos/:projectId/briefing"
            component={ProjectOnGoingBriefing}
          />
          <Route
            path="/projetos/:projectId/arquivos"
            component={ProjectOnGoingFileManager}
          />
          <Route
            path="/projetos/:projectId/mensagens"
            component={projectOngoingMessages}
          />
          <Route
            path="/projetos/:projectId/cronograma"
            component={ProjectOnGoingSchedule}
          />
          <Route
            path="/projetos/:projectId/proposta"
            component={ProjectOnGoingQuotation}
          />
          <Route
            path="/projetos/:projectId/faturamento"
            component={ProjectOnGoingPO}
          />
          <Route
            path="/projetos/:projectId/avaliacao"
            component={ProjectOnGoingEnd}
          />
          <Route
            path="/projetos/:projectId/finalizar"
            component={ProjectOnGoingEnd}
          />
          <Route
            path="/projetos/:projectId/finalizado"
            component={ProjectOnGoingEnded}
          />
          <Route
            path="/projetos/:projectId/cancelar"
            component={ProjectOnGoingCancel}
          />
          <Route
            path="/projetos/:projectId/cancelado"
            component={ProjectOnGoingCancelled}
          />

          <Route
            path="/projetos/:projectId/producao"
            exact
            component={ProjectOnGoingExtras}
          />
          <Route
            path="/projetos/:projectId/extras"
            exact
            component={ProjectOnGoingExtras}
          />
          <Route
            path="/projetos/:projectId/extras/new"
            exact
            component={ProjectOnGoingExtrasNew}
          />
          <Route
            path="/projetos/:projectId/extras/:extraId/editar"
            component={ProjectOnGoingExtrasEdit}
          />
          <Route
            path="/projetos/:projectId/extras/:extraId/details"
            component={ProjectOnGoingExtrasDetails}
          />
          <Route
            path="/projetos/:projectId/extras/:extraId/budget"
            component={ProjectOnGoingExtrasQuotation}
          />
          <Route
            path="/projetos/:projectId/extras/:extraId/po"
            component={ProjectOnGoingExtrasPO}
          />
          <Route
            path="/projetos/:projectId/extras/:extraId/finalize"
            component={ProjectOnGoingExtrasEnd}
          />

          <Route
            path="/cliente/usuario/passo-1"
            component={ClientUserSignupStep1}
          />
          <Route
            path="/cliente/usuario/obrigado"
            component={ClientUserSignupThankYou}
          />

          <Route
            path="/cliente/usuario"
            exact
            component={ClientUserResponsibleAdmin}
          />

          <Route
            path="/parceiro/verifica-email/:verificationToken"
            component={PartnerVerifyEmail}
          />
          <Route path="/parceiro/email-enviado" component={PartnerEmailSent} />
          {/* <Route exact path="/parceiro/verifica-pre-cadastro/:verificationToken" component={PartnerVerifyEmailPre} />
                <Route path="/parceiro/passo-1" component={PartnerSignupStep1} />
                <Route path="/parceiro/passo-2" component={PartnerSignupStep2} />
                <Route path="/parceiro/passo-3" component={PartnerSignupStep3} />
                <Route path="/parceiro/passo-4" component={PartnerSignupStep4} />
                <Route path="/parceiro/passo-5" component={PartnerSignupStep5} />
                <Route path="/parceiro/passo-6" exact component={PartnerSignupStep6} />
                <Route path="/parceiro/passo-6/preview" exact component={PartnerSignupStep6Preview} /> */}
          <Route path="/parceiro/obrigado" component={PartnerSignupThankYou} />
          <Route
            path="/parceiro/obrigado-pre-cadastro/:partnerId"
            component={PartnerSignupThankYouPreSignup}
          />
          <Route
            path="/parceiro/video/:verificationToken"
            component={PartnerSignupVideo}
          />

          {/* <Route path="/parceiro/pre-cadastro" component={PartnerPreSignup} /> */}
          {/* <Route path="/parceiro/dashboard" component={PartnerDashboardAdmin} /> */}

          <Route
            path="/parceiro/projetos"
            exact
            component={PartnerProjectListAdmin}
          />
          <Route
            path="/parceiro/agendamentos"
            component={PartnerProjectListAdmin}
          />
          <Route
            path="/parceiro/projetos/:projectId/convite"
            component={PartnerProjectInviteAdmin}
          />
          <Route
            path="/parceiro/projetos/:projectId/declinar-convite"
            component={PartnerProjectInviteDeclineAdmin}
          />

          <Route
            path="/parceiro/eventos/:projectId/convite-passo-1"
            component={PartnerProjectInvitationStep1}
          />
          <Route
            path="/parceiro/eventos/:projectId/convite-passo-2"
            component={PartnerProjectInvitationStep2}
          />
          <Route
            path="/parceiro/eventos/:projectId/convite-passo-3"
            component={PartnerProjectInvitationStep3}
          />
          <Route
            path="/parceiro/eventos/:projectId/convite-passo-4"
            component={PartnerProjectInvitationStep4}
          />
          <Route
            path="/parceiro/eventos/:projectId/obrigado"
            component={PartnerProjectInvitationThankYou}
          />

          <Route
            path="/parceiro/bpitch/:projectId/detalhes"
            component={PartnerProjectBPitchMatch}
          />

          <Route
            path="/parceiro/eventos/:projectId/detalhes"
            component={PartnerProjectProposalMatch}
          />

          <Route
            path="/parceiro/responsavel"
            component={PartnerResponsibleAdmin}
          />
          <Route path="/parceiro/idioma" component={PartnerIdiomaAdmin} />
          <Route path="/parceiro/empresa" component={PartnerCompanyAdmin} />
          <Route
            path="/parceiro/especialidade"
            component={PartnerSpecialismAdmin}
          />
          <Route
            path="/parceiro/expertises"
            component={PartnerExpertiseAdmin}
          />
          <Route
            path="/parceiro/trabalhos"
            component={PartnerClientsCasesAwardsAdmin}
          />
          <Route
            path="/parceiro/paises-atendidos"
            component={PartnerCountriesServedAdmin}
          />
          <Route path="/parceiro/lideranca" component={PartnerLeadersAdmin} />
          <Route
            path="/parceiro/clientes-referencias"
            component={PartnerReferencesAdmin}
          />

          <Route
            path="/parceiro/contratos"
            exact
            component={PartnerTepProjectListAdmin}
          />
          <Route
            path="/parceiro/extras"
            exact
            component={PartnerExtraListAdmin}
          />

          <Route
            exact
            path="/parceiro/criar-projeto"
            component={PartnerNewProjectStep1}
          />
          <Route
            exact
            path="/parceiro/criar-projeto/:projectId"
            component={PartnerNewProjectStep1}
          />
          <Route
            exact
            path="/parceiro/criar-projeto/briefing/:projectId"
            component={PartnerNewProjectStep1Briefing}
          />

          <Route
            exact
            path="/parceiro/criar-projeto/milestones/:projectId"
            component={PartnerNewProjectStep1Milestones}
          />

          <Route
            exact
            path="/parceiro/criar-projeto/assets/:projectId"
            component={PartnerNewProjectStep2}
          />
          <Route
            exact
            path="/parceiro/criar-projeto/sucesso/:projectId"
            component={PartnerNewProjectSuccess}
          />

          <Route
            exact
            path="/parceiro/criar-proposal"
            component={PartnerNewProposalStep1}
          />

          <Route path="/parceiro/preview" component={PartnerPreview} />

          <Route
            path="/parceiro/faturamento"
            exact
            component={PartnerBillingListAdmin}
          />
          <Route
            path="/parceiro/faturamento/:projectId/po/:billingId"
            exact
            component={PoBillingPartner}
          />
          <Route
            path="/parceiro/faturamento/v1/:projectId/po/:billingId"
            exact
            component={PartnerBillingDetailsAdmin}
          />
          <Route
            path="/parceiro/faturamento/informacoes/:projectId/po/:billingId"
            exact
            component={InvoiceInstructions}
          />
          <Route
            path="/parceiro/faturamento/v1/:projectId/extra/:extraId/po/:billingId"
            exact
            component={PartnerBillingDetailsAdminExtra}
          />
          <Route
            path="/parceiro/faturamento/:projectId/extra/:extraId/po/:billingId"
            exact
            component={ExtrasBillingPartner}
          />
          <Route
            path="/lider/convite/:verificationToken"
            component={LeaderAboutYou}
          />
          <Route path="/lider/passo-1" component={LeaderSignupStep1} />
          <Route path="/lider/passo-2" component={LeaderSignupStep2} />
          <Route path="/lider/passo-3" component={LeaderSignupStep3} />
          <Route path="/lider/obrigado" component={LeaderSignupThankYou} />

          <Route path="/lider/responsavel" component={LeaderResponsibleAdmin} />
          <Route
            path="/lider/mercados-expertise"
            component={LeaderExpertiseAdmin}
          />
          <Route
            path="/lider/cases-premios"
            component={LeaderCasesAwardsAdmin}
          />

          <Route
            path="/referencia/convite/:verificationToken"
            component={ReferenceForm}
          />
          <Route path="/referencia/passo-1" component={ReferenceReviewStep1} />
          <Route path="/referencia/passo-2" component={ReferenceReviewStep2} />
          <Route
            path="/referencia/obrigado"
            component={ReferenceReviewThankYou}
          />

          <Route path="/home" exact component={Home} />
          <Route path="/admin" exact component={Home} />

          <Route path="/cadastro" exact component={Profile} />
          <Route path="/cliente/cadastro" exact component={Profile} />
          <Route path="/parceiro/cadastro" exact component={Profile} />

          <Route path="/bpool/dashboard" component={DashboardAdmin} />
          <Route path="/bpool/dashboard-a" component={DashboardAdminA} />
          <Route path="/bpool/dashboard-b" component={DashboardAdminB} />
          <Route path="/bpool/dashboard-c" component={DashboardAdminC} />
          <Route path="/bpool/dashboard-d" component={DashboardAdminD} />
          <Route path="/bpool/relatorios" component={ReportsAdmin} />
          <Route path="/bpool/meetings" component={MeetingsAdmin} />

          {/* WIKIS */}
          <Route path="/bpool/notifications" component={NotificationsAdmin} />
          <Route path="/bpool/wikis/abas" component={WikisAdmin} />
          <Route path="/bpool/wikis/secoes" component={WikisAdminSections} />
          <Route
            path="/bpool/wikis/categorias"
            component={WikisAdminCategories}
          />

          <Route path="/bpool/wikis/conteudo" component={WikisAdminContents} />

          <Route path="/bpool/usuarios" exact component={UserListAdmin} />
          <Route path="/bpool/usuarios/novo" exact component={UserNewAdmin} />

          <Route path="/bpool/partner-score" component={PartnerScoreAdmin} />

          <Route path="/bpool/parceiros" exact component={PartnerAdmin} />
          <Route path="/bpool/fornecedores" exact component={SupplierAdmin} />
          <Route
            path="/bpool/parceiros-vip"
            exact
            component={PartnerVipAdmin}
          />
          <Route path="/bpool/faturamento" exact component={BillingListAdmin} />
          <Route
            path="/bpool/faturamento/:projectId/po/:billingId"
            exact
            component={BillingDetails}
          />
          <Route
            path="/bpool/faturamento/:projectId/extra/:extraId/po/:billingId"
            exact
            component={BillingDetailsExtra}
          />

          <Route
            path="/bpool/repasse"
            exact
            component={BillingTransferListAdmin}
          />

          <Route
            path="/bpool/extraordinario"
            exact
            component={SupplierBillingListAdmin}
          />

          <Route
            path="/bpool/parceiros/:partnerId"
            exact
            component={PartnerDetails}
          />
          <Route
            path="/bpool/parceiros/:partnerId/avaliar"
            exact
            component={PartnerEvaluation}
          />
          <Route
            path="/bpool/parceiros/:partnerId/editar"
            exact
            component={PartnerEdit}
          />
          <Route
            path="/bpool/parceiros/:partnerId/pre-selecao"
            exact
            component={PartnerPreSelection}
          />
          <Route
            path="/bpool/parceiros/:partnerId/aprovacao-final"
            exact
            component={PartnerFinalApproval}
          />
          <Route
            path="/bpool/parceiros/:partnerId/preview"
            exact
            component={BPoolPartnerPreview}
          />
          <Route path="/bpool/clientes" exact component={ClientListAdmin} />
          <Route
            path="/bpool/clientes/:clientId"
            exact
            component={ClientDetails}
          />
          <Route
            path="/bpool/clientes/:clientId/editar"
            exact
            component={ClientEdit}
          />
          <Route
            path="/bpool/clientes/:clientId/customizar"
            exact
            component={ClientCustomize}
          />
          <Route
            path="/bpool/projetos"
            exact
            component={BPoolClientProjectListAdmin}
          />
          <Route
            path="/bpool/audio"
            exact
            component={BPoolClientProjectAudioListAdmin}
          />
          <Route
            path="/bpool/milistone"
            exact
            component={BPoolClientMilestoneListAdmin}
          />
          <Route
            path="/bpool/extras"
            exact
            component={BPoolClientExtraListAdmin}
          />
          <Route
            path="/bpool/projetos-detalhados"
            exact
            component={BPoolClientProjectListFullAdmin}
          />
          <Route
            path="/bpool/projetos-kanban"
            exact
            component={BPoolClientProjectKanbanAdmin}
          />
          <Route
            path="/bpool/projetos/:projectId"
            exact
            component={BPoolClientProjectDetails}
          />
          <Route
            path="/bpool/projetos/audio/:audioId"
            exact
            component={BPoolClientProjectAudioDetails}
          />

          <Route path="/bpool/logins" exact component={LoginsListAdmin} />
          <Route path="/bpool/central-de-assets" exact component={Assets} />
          <Route path="/bpool/clientes-assets" exact component={ClientAssets} />
          <Route path="/bpool/clientes-combo" exact component={ClientCombos} />

          <Route exact path="/cliente/ideias/home" component={WhatIfHome} />
          <Route
            exact
            path="/cliente/ideias/brand-profile/:whatIfId"
            component={BrandProfile}
          />
          <Route
            exact
            path="/cliente/ideias/brand-profile/:whatIfId/preview/"
            component={BrandProfilePreview}
          />
          <Route
            exact
            path="/cliente/ideias/brand-profile/:whatIfId/sucesso"
            component={BrandProfileSuccess}
          />

          <Route
            exact
            path="/cliente/ideias/contra-proposta/sucesso"
            component={CounterProposalSuccess}
          />
          <Route exact path="/cliente/ideias/buscar" component={SearchIdea} />
          <Route
            exact
            path="/cliente/ideias/recebida/:whatIfIdeaId"
            component={IdeaPreview}
          />
          <Route
            exact
            path="/cliente/ideias/recebida/cadastrar-po/:whatIfIdeaId"
            component={RegisterPo}
          />
          <Route
            exact
            path="/cliente/ideias/recebida/cadastrar-po/:whatIfIdeaId/sucesso"
            component={RegisterPoSuccess}
          />

          <Route
            exact
            path="/cadastro/parceiro-ideias"
            component={PartnerRegister}
          />
          <Route
            exact
            path="/cadastro/parceiro-ideias/sucesso"
            component={PartnerRegisterSuccess}
          />

          <Route
            path="/parceiro-ideias/verifica-cadastro/:verificationToken"
            exact
            component={PartnerVerifyEmailWhatIf}
          />

          <Route
            exact
            path="/parceiro-ideias/informacoes-gerais"
            component={GeneralInformationForm}
          />
          <Route
            exact
            path="/parceiro-ideias/informacoes-gerais/sucesso"
            component={GeneralInformationSuccess}
          />

          <Route
            exact
            path="/parceiro-ideias/profile"
            component={PartnerProfile}
          />
          <Route
            exact
            path="/parceiro-ideias/profile/preview"
            component={PartnerProfilePreview}
          />
          <Route
            exact
            path="/parceiro-ideias/profile/sucesso"
            component={PartnerProfileSuccess}
          />

          <Route
            exact
            path="/parceiro-ideias/marcas"
            component={CreativeBrands}
          />

          <Route
            exact
            path="/parceiro-ideias/cadastro/ideia/:whatIfId"
            component={IdeaRegister}
          />
          <Route
            exact
            path="/parceiro-ideias/cadastro/ideia/:whatIfId/editar/:whatIfIdeaId"
            component={IdeaRegister}
          />
          <Route
            exact
            path="/parceiro-ideias/cadastro/ideia/:whatIfId/preview/:whatIfIdeaId"
            component={IdeaRegisterPreview}
          />
          <Route
            exact
            path="/parceiro-ideias/cadastro/ideia/:whatIfIdeaId/view"
            component={IdeaView}
          />
          <Route
            exact
            path="/parceiro-ideias/cadastro/ideia/:whatIfIdeaId/sucesso"
            component={IdeaRegisterSuccess}
          />
          <Route
            exact
            path="/parceiro-ideias/minhas-ideias"
            component={MyIdeas}
          />

          {/* MODULO ASSETS */}

          <Route
            exact
            path="/parceiro/assets/criar"
            component={PartnerCreateAssets}
          />
          <Route
            exact
            path="/cliente/acompanhamento/:projectId/:feedbackId/:grade"
            component={Votes}
          />
          <Route
            exact
            path="/parceiro/acompanhamento/:projectId/:feedbackId/:grade"
            component={VotesPartner}
          />

          <Route
            exact
            path="/preview/projeto/:projectId"
            component={ProjectPreviewPage}
          />

          <Route path="/parceiro/" component={Register} />
          <Route path="/cliente/" component={Register} />
          {/* <Route path="/parceiro/usuario/" component={Register} /> */}
          <Route path="/lider/cadastro/" component={Register} />
          <Route path="/referencia/cadastro/" component={Register} />

          <Route path="/cliente/wikis/" component={Wikis} />
          <Route path="/parceiro/wikis/" component={Wikis} />

          {/* <Route exact path="/cliente/cadastre-se" component={Register} /> */}
          {/* <Route path="/cliente/usuario/verifica-email/:verificationToken" component={ClientUserVerifyEmail} /> */}

          <Route path="/i18n" component={I18nExample} />

          <Route path="/meeting/:id" component={Meeting} />

          <Route path="/registration-flow" component={RegistrationFlow} />

          <Route path="/references-partner" component={ReferencesPartner} />

          <Route path="/partner/edit-profile" component={PartnerEditProfile} />

          <Route path="/leader/edit-profile" component={LeaderEditProfile} />

          <Route
            path="/bpool/partner/v2/:partnerId"
            component={BpoolPartnerAdmin}
          />

          <Route path="/nf-extra/:id" component={FormSupplierBilling} />

          {Config.moduloNewBpay && ( // TODO: FLAGFEATURE BPAY
            <Route path="/cliente/bpay" component={Bpay} />
          )}
          <Route
            path="/cliente/repositorio-de-arquivos"
            component={FileRepository}
          />
          <Route
            path="/parceiro/repositorio-de-arquivos"
            component={FileRepository}
          />
          <Route path="/bpool/bpay" component={BpayAdmin} />

          <Route path="/fornecedor" component={SupplierRoutes} />
          <Route path="/partners" component={SupplierRoutes} />

          <Route
            path="/playground-components"
            exact
            component={PlaygroundComponents}
          />
        </Router>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
