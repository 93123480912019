import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, message, Select } from "antd";
import { useUpload } from "../../../../hooks/useUpload/useUpload.hook";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";
import { Input } from "../../../../components/UI/Input";
import { Textarea } from "../../../../components/UI/Textarea";
import { UploadLogo } from "../../Full/components/UploadLogo";
import { useRegistrationFlowLeader } from "../../../../hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook";
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from "../../utils/flowToStorage";
import { SelectOne } from "../../../../components/UI/SelectOne";
import { InputFlag } from "../../../../components/UI/InputFlag";
import { hasError, clearErrorInput } from "../../utils/errorsInput";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { statusIdToName } from "../../components/Loggedv2/statusIdToName";

import * as S from "./styles";
import { inputsState } from "./inputsState";
import COUNTRY_CODES from "./countryCodes.json";
import { urlsLeader as urls } from "../../utils/stepsUrls";

const { Option } = Select;
const MESSAGE_ERROR_TOKEN = "Error in get token.";
const ERROR_IMAGE_UP = I18n.get("Maximum limit 5mb");
const ERROR_IMAGE_NOT_UP = "Logo image is required with sizes (117 x 117px)";

const PAGE_INDEX = 18;

export const ContentPage = ({
  onChangeInfo,
  contextPage,
  leaderId,
  sendOk,
}) => {
  const listCountries = useSelector((state) => state.bp.countryCodes);
  const state = useSelector((state) => state);
  const location = useLocation();
  let history = useHistory();
  const {
    getAboutYou,
    getAboutYouLogged,
    putAboutYou,
    getStepBarLeader,
    putAboutYouLogged,
    getTimezones,
  } = useRegistrationFlowLeader();
  const { uploadFile } = useUpload();

  const [token, setToken] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [inputsForm, setInputsForm] = useState(inputsState);
  const [inputsFormInitials, setInputsFormInitials] = useState(inputsState);
  const [statusLogoUpload, setStatusLogoUpload] = useState("");
  const [timezones, setTimezones] = useState([]);
  const [originalTimezones, setOriginalTimezones] = useState([]);
  const [timezoneSelected, setTimezoneSelect] = useState("");
  const [fileLogoToUpload, setFileLogoToUpload] = useState(null);
  const [selectCountry, setSelectCountry] = useState({
    countryLabel: "Brazil",
    phoneMask: "(00)00000-0000",
    countryCode: "BRA",
  });
  const [selectCountryPhone, setSelectCountryPhone] = useState({
    countryLabel: "Brazil",
    phoneMask: "(00)00000-0000",
    countryCode: "BRA",
  });
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [completed, setCompleted] = useState(null);

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: `${urls[PAGE_INDEX + 1]}/${token}`,
    });
  };

  const onChangeInputForm = (id, value, isCountry) => {
    if (isCountry) {
      const obj = COUNTRY_CODES.find((i) => i.code == value);
      setInputsForm({
        ...inputsForm,
        [id]: value ? obj : null,
      });
    } else {
      setInputsForm({
        ...inputsForm,
        [id]: value ? value : null,
      });
    }
  };

  const onChangeInputPhone = ({ valuePhone, valuePhoneCountry }) => {
    setInputsForm({
      ...inputsForm,
      phoneNumber: valuePhone ? valuePhone : null,
      countryCodePhoneNumber: valuePhoneCountry ? valuePhoneCountry : null,
    });
  };

  const onChangeSelectCountry = (value) => {
    const country = COUNTRY_CODES.find((item) => item?.name === value);
    setInputsForm({
      ...inputsForm,
      country: country,
      phoneNumber: "",
    });
    setSelectCountry({
      countryLabel: value,
      phoneMask: country.mask,
      countryCode: country.code,
    });
  };

  const fileToUpload = ({ file }) => {
    setInputsForm({
      ...inputsForm,
      profilePicture: file ? file : null,
    });

    const errorsArr = clearErrorInput({
      field: "profilePicture",
      arr: inputsErrorsForm,
    });
    setInputsErrorsForm(errorsArr);
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStepBarLeader(token);
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);
    let responseProfilePicture = null;

    if (inputsForm?.profilePicture?.uid) {
      responseProfilePicture = await uploadFile({
        file: inputsForm?.profilePicture,
        idUser: "usertestingnow",
        fieldId: `logoImage`,
      });
    }

    const objToSend = {
      ...inputsForm,
      profilePicture: responseProfilePicture
        ? responseProfilePicture?.url
        : inputsForm?.profilePicture,
      countryCodePhoneNumber: inputsForm.countryCodePhoneNumber,
    };

    setInputsForm({
      ...inputsForm,
      profilePicture: objToSend?.profilePicture,
    });

    if (contextPage === "edit") {
      const responseLogged = await putAboutYouLogged(objToSend);

      if (responseLogged.success) {
        sendOk(true);
      } else {
        sendOk(false);
      }
      setIsSending(false);
    } else {
      const response = await putAboutYou(objToSend);
      await newGetStepbar();
      if (response.success) {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: `${urls[PAGE_INDEX + 1]}/${token}`,
        });
      } else {
        setOpenModalConfirm(true);
        const errors = [];
        Object.keys(response?.errors).forEach(function (key, index) {
          errors.push(response?.errors[key]);
        });
        setInputsErrorsForm(errors);
      }
    }

    setIsSending(false);
  };

  const prepareToken = async (url) => {
    const arrayOfStrings = url.split("/");
    const tkn = arrayOfStrings[arrayOfStrings.length - 1];
    return tkn;
  };

  const trateString = (value) => {
    if (value) {
      return value;
    } else {
      return null;
    }
  };

  const prepareResponse = async ({ response, tokenUrl }) => {
    const dataForm = {
      id: trateString(response?.data?.users?.id),
      partnerId: trateString(response?.data?.id),
      verificationToken: tokenUrl ? tokenUrl : "",
      name: trateString(response?.data?.users?.name),
      nameAlias: trateString(response?.data?.users?.nameAlias),
      email: trateString(response?.data?.users?.email),
      timeZone: trateString(response?.data?.users?.timeZone),
      linkedin: trateString(response?.data?.users?.linkedin),
      country: response?.data?.users?.country,
      phoneNumber: trateString(response?.data?.users?.phoneNumber),
      countryCodePhoneNumber:
        response?.data?.users?.countryCodePhoneNumber || "BRA",
      profilePicture: trateString(response?.data?.users?.profilePicture),
      resume: trateString(response?.data?.users?.resume),
    };

    return dataForm;
  };

  const loadInit = async () => {
    setIsLoadingPage(true);
    onChangeInfo(false);

    const tokenUrl = await prepareToken(location?.pathname);
    if (tokenUrl) {
      setToken(tokenUrl);

      if (contextPage === "edit") {
        const responseLogged = await getAboutYouLogged();
        const dataForm = await prepareResponse({
          response: responseLogged,
          tokenUrl: null,
        });
        setInputsForm(dataForm);
        setInputsFormInitials(dataForm);
        setStatusLogoUpload(
          responseLogged?.data?.users?.profilePicture
            ? trateString(responseLogged?.data?.users?.profilePicture)
            : ""
        );

        setIsLoadingPage(false);
      } else {
        const response = await getAboutYou(tokenUrl);

        if (response.success) {
          if (response?.data?.users.completedOn) {
            history.push({
              pathname: "/registration-flow/leader/completed",
            });
          } else {
            const ll = response?.data?.users?.country?.bpRegion;

            switch (ll) {
              case "USA":
                I18n.setLanguage("en");
                break;
              case "LATAM":
                I18n.setLanguage("es");
                break;
              case "BRASIL":
                I18n.setLanguage("pt");
                break;

              default:
                break;
            }

            const dataForm = await prepareResponse({
              response: response,
              tokenUrl: tokenUrl,
            });
            setInputsForm(dataForm);
            setInputsFormInitials(dataForm);
            setStatusLogoUpload(
              response?.data?.users?.profilePicture
                ? trateString(response?.data?.users?.profilePicture)
                : ""
            );

            setIsLoadingPage(false);
          }
        }
      }
    } else {
      message.error(I18n.get(MESSAGE_ERROR_TOKEN));
    }
    // setIsLoadingPage(false);
  };

  const renderHasError = (status) => {
    if (status === "error") {
      return <S.ErrorText>{ERROR_IMAGE_UP}</S.ErrorText>;
    } else if (status === "errorNotUpload") {
      return <S.ErrorText>{ERROR_IMAGE_NOT_UP}</S.ErrorText>;
    } else {
      return null;
    }
  };

  // const setLocale = () => {
  //   const locationSearch = location?.search;
  //   const locale = locationSearch?.split("=")[1];
  //   I18n.setLanguage(locationSearch ? locale : "pt");
  // };

  const loadTimezone = async () => {
    const response = await getTimezones();

    const timeFormat = response.data.map((item) => {
      return {
        code: item?.value,
        label: `${item?.text} - ${item?.value}`,
        selected: item.selected,
      };
    });

    setTimezones(timeFormat);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, [location]);

  useEffect(() => {
    loadTimezone();
  }, []);

  //validation
  useEffect(() => {
    if (
      inputsForm?.name !== inputsFormInitials?.name ||
      inputsForm?.nameAlias !== inputsFormInitials?.nameAlias ||
      inputsForm.email !== inputsFormInitials.email ||
      inputsForm.linkedin !== inputsFormInitials.linkedin ||
      inputsForm.phoneNumber !== inputsFormInitials.phoneNumber ||
      inputsForm.resume !== inputsFormInitials.resume ||
      inputsForm.timeZone !== inputsFormInitials.timeZone
    ) {
      onChangeInfo(true);
    } else {
      onChangeInfo(false);
    }
  }, [inputsForm, inputsFormInitials]);

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("About you")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  "Our algorithm wants to know more about you, who are in the lead. Remember that each information is valuable for him to find projects that have to do with your company."
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        {isLoadingPage ? (
          <Row>
            <Col xs={24}>
              <S.ContentIsLoading>
                <Loading
                  text={I18n.get("Loading informations...")}
                  sizeText={14}
                  sizeIcon={16}
                />
              </S.ContentIsLoading>
            </Col>
          </Row>
        ) : (
          <>
            <Row style={{ marginTop: 30 }}>
              <Col xs={24} sm={6}>
                <S.Logo>
                  <S.LogoImage>
                    <UploadLogo
                      defaultImg={inputsFormInitials.profilePicture}
                      imgUploadStatus={(status) => setStatusLogoUpload(status)}
                      fileToUpload={(file) => fileToUpload({ file })}
                      styleButton={{
                        width: "113px",
                        height: "113px",
                      }}
                    />
                  </S.LogoImage>
                  {statusLogoUpload === "" || statusLogoUpload === "removed" ? (
                    <S.SizeLogo>
                      117 x 117px
                      <br />
                      <strong>JPG or PNG</strong>
                    </S.SizeLogo>
                  ) : null}
                  <div style={{ marginTop: "20px" }}>
                    <S.ErrorText>
                      {hasError({
                        field: "profilePicture",
                        arr: inputsErrorsForm,
                      })}
                    </S.ErrorText>
                  </div>
                </S.Logo>
                {renderHasError(statusLogoUpload)}
              </Col>
              <Col xs={24} sm={18}>
                <S.InputsContent>
                  <S.RowInput>
                    <Input
                      placeholder={I18n.get("Name")}
                      label
                      required
                      value={inputsForm?.name}
                      onChange={(value) => onChangeInputForm("name", value)}
                      error={hasError({
                        field: "Name",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "Name",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput style={{ marginLeft: 15 }}>
                    <Input
                      placeholder={I18n.get("Name alias")}
                      label
                      required
                      value={inputsForm?.nameAlias}
                      onChange={(value) =>
                        onChangeInputForm("nameAlias", value)
                      }
                      error={hasError({
                        field: "NameAlias",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "NameAlias",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput>
                    <SelectOne
                      options={COUNTRY_CODES}
                      value={inputsForm?.country?.code}
                      defaultValue={inputsForm?.country?.code}
                      isCountry
                      onChange={(value) =>
                        onChangeInputForm("country", value, true)
                      }
                      required
                      placeholder={I18n.get("Country")}
                      error={hasError({
                        field: "country",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "country",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput style={{ marginLeft: 15 }}>
                    <Input
                      placeholder={I18n.get("Email")}
                      label
                      required
                      value={inputsForm.email}
                      onChange={(value) => onChangeInputForm("email", value)}
                      error={hasError("email")}
                      disabled
                    />
                  </S.RowInput>
                  <S.RowInput>
                    <Input
                      placeholder={I18n.get("Linkedin")}
                      label
                      value={inputsForm.linkedin}
                      onChange={(value) => onChangeInputForm("linkedin", value)}
                      error={hasError({
                        field: "Linkedin",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "Linkedin",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput style={{ marginLeft: 15 }}>
                    <InputFlag
                      id="phoneNumber"
                      label={I18n.get("Phone number")}
                      defaultValue={inputsForm?.phoneNumber}
                      flagCodeDefault={
                        inputsForm?.countryCodePhoneNumber || "BRA"
                      }
                      onChange={(value) =>
                        onChangeInputPhone({
                          valuePhone: value?.value,
                          valuePhoneCountry: value?.country,
                        })
                      }
                      required
                      error={hasError({
                        field: "phoneNumber",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "phoneNumber",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>

                  <S.RowInput style={{ width: "calc(100% - 15px)" }}>
                    <SelectOne
                      options={timezones}
                      value={inputsForm.timeZone}
                      placeholder={I18n.get("Fuso horário")}
                      onChange={(value) => onChangeInputForm("timeZone", value)}
                      required
                      error={hasError({
                        field: "timeZone",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "timeZone",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                      // error=""
                    />

                    {contextPage === "edit" && (
                      <p
                        style={{ fontSize: 12, color: "#7c7c7c", marginTop: 5 }}
                      >
                        {I18n.get(
                          "Ao alterar o fuso horário, será necessário refazer o login"
                        )}
                      </p>
                    )}
                  </S.RowInput>
                </S.InputsContent>
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col xs={24}>
                <Textarea
                  id="resume"
                  label={I18n.get("Resume")}
                  rows={4}
                  maxLength={500}
                  counterChar
                  defaultValue={inputsForm.resume}
                  onChange={(value) => onChangeInputForm("resume", value)}
                  error={hasError({
                    field: "resume",
                    arr: inputsErrorsForm,
                  })}
                />
              </Col>
            </Row>
          </>
        )}

        <Row style={{ marginTop: 30 }}>
          <Col xs={24} sm={12}></Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={
              contextPage === "edit"
                ? { span: 24, offset: 0 }
                : { span: 12, offset: 0 }
            }
          >
            <ButtonBpool
              text={
                contextPage === "edit"
                  ? I18n.get("Save")
                  : I18n.get("Save and Next")
              }
              theme="primary"
              full
              loading={isSending}
              onClick={send}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get("Missing required information")}
        subBodyText={I18n.get(
          "Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?"
        )}
        okText={I18n.get("Yes")}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get("Close")}
        typeModal="isConfirm"
        // isDanger
      />
    </>
  );
};
