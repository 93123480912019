import styled from "styled-components";

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const SubTitle = styled.div`
  margin: 15px 0 30px 0;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  span {
    width: 70px;
    height: 1px;
    background-color: #000000;
    display: block;
  }
`;

export const Text = styled.div`
  font-weight: ${(props) => (props.strongText ? "700;" : "400;")};
  font-size: ${(props) => (props.strongText ? "16px;" : "14px;")};
`;

export const FilesList = styled.div``;

export const RowFile = styled.div`
  cursor: default;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;

  :last-child {
    border: none;
  }
`;

export const DelFile = styled.button`
  cursor: ${(props) => (props?.disabledButton ? "not-allowed" : "pointer")};
  background-color: ${(props) =>
    props?.disabledButton ? "#e7e7e7" : "#ff0000"};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  svg {
    color: ${(props) => (props?.disabledButton ? "#696969" : "#fff")};
    cursor: ${(props) => (props?.disabledButton ? "not-allowed" : "pointer")};
  }
`;

export const TextInfo = styled.div`
  margin: 15px 0;

  strong {
    font-weight: 700;
    font-size: 14px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const LastUpdate = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 30px;

  strong {
    font-weight: 700;
    font-size: 12px;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    margin-left: 5px;
  }
`;

export const Content = styled.div`
  padding: 30px 0 0 0;
`;

export const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionWrapper = styled.div`
  margin: 15px 0;
`;

export const AccordionTitle = styled.h3`
  display: flex;
  align-items: center;
  cursor: pointer;

  .title {
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    margin: 0;

    strong {
      font-weight: 700;
    }
  }

  .title-arrow {
    margin-left: 15px;

    i {
      font-size: 14px;
    }
  }
`;

export const AccordionContent = styled.div`
  padding: 10px 0;
  border-top: 1px solid #e8e8e8;
`;
