import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Radio } from 'antd';

import * as S from "./styles";

const filters = [
  {
    key: 999,
    label: I18n.get("All"),
  },
  {
    key: 1,
    label: I18n.get("New"),
  },
  {
    key: 2,
    label: I18n.get("Aceitos"),
  },
  {
    key: 3,
    label: I18n.get("Declinados"),
  },
  {
    key: 9,
    label: I18n.get("Expired"),
  },
];

export const Filters = ({ filterList, initFilterStatus }) => {
  const [isSelected, setIsSelected] = useState(initFilterStatus);

  const onChangeCheckboxes = (e) => {
    setIsSelected(e.target.value);
  };

  useEffect(() => {
    filterList(isSelected);
  }, [isSelected]);

  return (
    <S.Wrapper>
      <Radio.Group onChange={onChangeCheckboxes} value={isSelected}>
        {filters.map((item) => (
          <Radio key={item?.key} value={item?.key}>{item?.label}</Radio>
        ))}
      </Radio.Group>
    </S.Wrapper>
  );
};
