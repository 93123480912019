import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { InfoCircleOutlined } from '@ant-design/icons';
import IcoInfo from '../../../media/svg/ico-info.svg';
import * as S from './styles';

import { Tabs } from 'react-tabs';

const TabsInternal = ({ tabItens, centered, tabActual }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    tabActual(tabIndex);
  }, [tabIndex]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
      <S.TabList centered={centered}>
        {tabItens &&
          tabItens.map((item) => {
            return (
              <S.Tab disabled={item.disabled}>
                {item.disabled ? (
                  <S.TooltipCard>
                    <S.TooltipText>
                      <h3>{item.label}</h3>
                    </S.TooltipText>
                    <S.TooltipBox>
                      <S.TooltipContent>
                        <div>
                          <img src={IcoInfo} alt="Ico confirm tooltip" />
                        </div>
                        <S.TooltipContentInfos>
                          <strong>{I18n.get(item?.infos?.title)}</strong>
                          <p>{I18n.get(item?.infos?.info)}</p>
                        </S.TooltipContentInfos>
                      </S.TooltipContent>
                    </S.TooltipBox>
                  </S.TooltipCard>
                ) : (
                  <div>
                    {item.missingInfo ? (
                      <S.MissingInfo>
                        <span>{item.label}</span>
                        <InfoCircleOutlined />
                      </S.MissingInfo>
                    ) : (
                      item.label
                    )}
                  </div>
                )}
              </S.Tab>
            );
          })}
      </S.TabList>

      {tabItens &&
        tabItens.map((item) => {
          return <S.TabPanel>{item.component}</S.TabPanel>;
        })}
    </Tabs>
  );
};

export default TabsInternal;
