import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Checkbox } from "antd";
import Content from "../../../components/UI/Content";
import Main from "../../../components/UI/Main";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import { useMilestones } from "../../../hooks/useMilestones/useMilestones.hook";
import { useBriefings } from "../../../hooks/useBriefings/useBriefings.hook";
import { TabsHorizontal } from "../../../components/UI/TabsHorizontal";
import { H2Border } from "../../../components/UI/Titles";
import { Button } from "../../../components/UI/Button";
import Loading from "../../../components/UI/Loading";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";

import FormMilestones from "./components/FormMilestones";

import Download from "../svgs/download.svg";
import TabsItemsClient from "../urls/urlsClient.json";
import TabsItemsPartner from "../urls/urlsPartner.json";
import { TableMacros } from "./components/TableMacros";
import { TableMacrosV2 } from "../../Definitions/Macros/components/TableMacrosV2"

import * as S from "./styles";

const tableColumns = [
  "",
  I18n.get("Name"),
  I18n.get("Date"),
  I18n.get("Files"),
  I18n.get("Partner delivery"),
  I18n.get("Client approval"),
]

export const MilestonesInProgress = () => {
  let params = useParams();
  const auth = useSelector((state) => state.auth);
  const { getProjectById } = useProjects();
  const { getMilestones } = useMilestones();
  const { getBriefing } = useBriefings();
  const [projectId, setProjectId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [milestonesInfos, setMilestonesInfos] = useState([]);
  const [partnerHasSubmittedDefinitions, setPartnerHasSubmittedDefinitions] = useState(false);
  const [statusProject, setStatusProject] = useState(null);

  const loadMacros = async () => {
    if (params?.projectId) {
      setIsLoading(true);
      try {
        const response = await getMilestones({ id: params?.projectId });
        setMilestonesInfos(response);
        const responseBriefing = await getBriefing({ id: params?.projectId });
        setPartnerHasSubmittedDefinitions(responseBriefing?.data?.partnerHasSubmittedDefinitions);
        const responseProject = await getProjectById({ projectId: params?.projectId });
        setStatusProject(responseProject?.data?.status)
      } catch (error) {
        return;
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadMacros();
  }, []);

  useEffect(() => {
    setProjectId(params.projectId);
  }, [params]);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get('Projeto em andamento')}</H2Border>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <TabsHorizontal
                items={auth?.is_client_or_client_user ? TabsItemsClient : TabsItemsPartner}
                urlsPrefix={`/#/${auth?.is_client_or_client_user ? "cliente" : "parceiro"}/projetos/v2/${params.projectId}`}
                tabActive={4}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Milestones")}</S.H4>
                      </S.TitleWithIcon>
                    </S.RowCenter>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24}>
                    <S.SubTitle>
                      <p>{I18n.get("Macro deliveries")}</p>
                      <span />
                    </S.SubTitle>
                  </Col>
                  <Col sm={24}>
                    <TableMacrosV2
                      columns={tableColumns}
                      data={milestonesInfos}
                      loading={isLoading}
                      projectId={params.projectId}
                      reload={loadMacros}
                      disableds={partnerHasSubmittedDefinitions}
                      clientOrPartner={auth?.is_client_or_client_user ? "client" : "partner"}
                      statusProject={statusProject}
                    />
                  </Col>
                </Row>

                <S.RowFooter>
                  <S.ButtonsFooter></S.ButtonsFooter>
                  <S.ButtonsFooter>
                    <Link
                      to={`/${auth?.is_partner ? "parceiro" : "cliente"
                        }/projetos/v2/${projectId}/in-progress/briefing`}
                    >
                      <Button
                        text={I18n.get("Voltar")}
                        theme="black"
                        outline={true}
                        className="btnPrev"
                      />
                    </Link>
                    <Link
                      to={`/${auth?.is_partner ? "parceiro" : "cliente"
                        }/projetos/v2/${projectId}/in-progress/po`}
                    >
                      <Button
                        text={I18n.get("Próximo")}
                        theme="primary"
                      />
                    </Link>
                  </S.ButtonsFooter>
                </S.RowFooter>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
