import { Auth } from "aws-amplify";

import { LOGOUT } from "./actions";
import { RESET_STATE } from "../chat/actions";

export const api_logout = () => {
  return (dispatch, getState) => {
    Auth.signOut();

    window.closeWs();

    dispatch({
      type: LOGOUT,
      payload: {
        logged_user: null,
        email: null,
        is_logout: true,
      },
    });
    dispatch({
      type: RESET_STATE,
    });

    window.location.replace("");

    if (window.$chatwoot) {
      window.$chatwoot.reset()
    }

    localStorage.setItem("@BPOOL-redirect-url-after-login", "");
    localStorage.clear();
  };
};

export default api_logout;
