import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { Link, Redirect } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    Avatar
} from 'antd';
import ContainerPage from './ContainerPage';
import styled from 'styled-components';
import Dthumb from '../../image/dthumb.png';
import Dcapa from '../../image/dcapa.png';

class HeaderThumbCover extends Component {
    state = {}
    render() {
        const { thumb, bgHeader, isCreative = false, title = "", isCover = true, link, linkText = "Editar" } = this.props;

        const capa = bgHeader ? bgHeader : Dcapa;

        const TitleButton = styled.header`
            background: #fff;
            height: 90px;


            h3 {
                line-height: 90px;
                font-weight: 600;
            }

            .btEditar {
                float: right;
                margin-top: 30px;
            }
            `;

        const BannerCover = styled.div`
            background: #000 url(${capa});
            background-size: cover;
            height: 220px;

            .LogoAvatar {
                background: #fff;
                position: relative;
                top: 140px;
                left: 230px;

                @media (max-width: 768px) {
                    left: 0px;
                    display: block;
                    margin: 0 auto;
                }
            }
        `;

        return (
            <Fragment>
                <header>
                    {!this.props.disabledSubheader &&
                        <TitleButton>
                            <ContainerPage>
                                <Row gutter={1}>
                                    <Col sm={22}>
                                        <h3>{title}</h3>
                                    </Col>

                                    {link &&
                                        <Col sm={2}>
                                            <Link
                                                to={link}
                                                onClick={this.props.action && this.props.action}
                                            >
                                                <Button type="primary" shape="round" size="large" className="btEditar">
                                                    {linkText}
                                                </Button>
                                            </Link>
                                        </Col>
                                    }
                                </Row>
                            </ContainerPage>
                        </TitleButton>
                    }
                    {isCover &&
                        <BannerCover>
                            <ContainerPage>
                                {!this.props.disabledThumb && <Avatar className="LogoAvatar" size={205} icon="user" src={thumb ? thumb : Dthumb} />}
                            </ContainerPage>
                        </BannerCover>
                    }
                </header>
            </Fragment>
        )
    }
}


export default HeaderThumbCover;
