import React from 'react';

import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';

import { Task } from '../../utils/task';

import PartnerCard from '../partner/shared/_partner-card';

import styled from 'styled-components';

import SubmitButtonField from '../form/submit-button-field';
import {
    Row,
    Col,
    Form,
    Rate,
    Input
} from 'antd';

const { TextArea } = Input;


const RowItem = styled(Row)`
    margin-bottom: 30px;

    .BoxCard {
        position: relative;

        .card {
            height: 100%;
        }
    }
`

const ItemVote = styled.div`
    background: #fff;
    padding: 24px 30px 7px;
    height: 100%;
    .anticon-star {
        font-size: 40px;
    }

    .ant-form-item {
        border-bottom: solid 1px #eee;
        padding-bottom: 15px;
        margin-bottom: 12px;

        &:last-child {
            border: 0;
        }
    }
`


class ReasonsForm extends React.Component {
    constructor(props) {

        let partnersFeed = [];

        const partnerIdStorage = localStorage.getItem("selectedPartnerId");

        props.partners2 && props.partners2.map((item, index) => {


            if (item.partnerId == partnerIdStorage) {

                partnersFeed.push(
                    {
                        partner: item,
                        position: index,
                        partnerId: item.partnerId,
                        isWinner: true,
                        grades: props.bp.clientProjectPartnerSelectedReasons2Events && props.bp.clientProjectPartnerSelectedReasons2Events.map((item) => {
                            return (
                                {
                                    reason: item,
                                    grade: null
                                }
                            );
                        })
                    }
                );
            } else {
                partnersFeed.push(
                    {
                        partner: item,
                        position: index,
                        partnerId: item.partnerId,
                        isWinner: false,
                        grades: props.bp.clientProjectPartnerNotSelectedReasons2Events && props.bp.clientProjectPartnerNotSelectedReasons2Events.map((item) => {
                            return (
                                {
                                    reason: item,
                                    grade: null
                                }
                            );
                        })
                    }
                );
            }
        });


        super();

        this.state = {
            partnersFeed: partnersFeed,
            task: {},
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.auth.task) return;

        if (this.props.auth.task.id != prevState.task.id) {
            this.setState({ task: this.props.auth.task });
        }
    }


    submitForm(e) {

        // e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task });


        this.props.form.validateFields((err, values) => {
            if (!err) {

                let reasons = [];

                this.state.partnersFeed.map((item) => {

                    const invitation = item.partner.projectInvitations.find((a) => a.projectId == this.props.projectId);

                    const accepted = (invitation && invitation.accepted === true);

                    if (accepted) {

                        reasons.push({
                            partnerId: item.partnerId,
                            isWinner: item.isWinner,
                            grades: item.grades.map((g) => {
                                return {
                                    ...g,
                                    grade: values[`${item.position}-${g.reason.code}`]
                                }
                            }),
                            comment: values[`${item.position}-comment`]
                        });
                    }

                });


                let data = {
                    ...this.props.project,
                    partnerId: localStorage.getItem("selectedPartnerId"),
                    reasons2: reasons,
                    isSelectSubmit: true,
                    isPartnerSelectionBidding: true
                }

                this.setState({ task: task }, function () {
                    // this.props.onSubmit(this.state);

                    this.props.save(data);

                });
            }
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        var errorMap = {
        };

        Object.keys(this.props.project.errors).map((item) => {

            const error = this.props.project.errors[item];

            const key = error.step || 'global';

            if (!errorMap[key]) {
                errorMap[key] = []
            }

            errorMap[key].push(error.errorMessage)
        });

        const partnerIdSelected = localStorage.getItem("selectedPartnerId");


        return (
            <Form>
                <div>
                    <h1 className="title title-2">
                        <span>2.</span>{I18n.get("Justificativa da escolha")}</h1>

                    <br />
                    <br />

                </div>

                {this.state.partnersFeed && this.state.partnersFeed.map((item, index) => {


                    if (item.partnerId != partnerIdSelected) return null;

                    let position = item.position;

                    return (
                        <RowItem key={index} gutter={1} type="flex">
                            <Col sm={8} className="BoxCard">
                                <PartnerCard key={item.partnerId} {...item.partner} isSelected={true} isMini={true} />
                            </Col>

                            <Col sm={16}>
                                <ItemVote>
                                    <Row>
                                        {this.props.bp.clientProjectPartnerSelectedReasons2Events && this.props.bp.clientProjectPartnerSelectedReasons2Events.map((item, index) => {
                                            return (
                                                <Col sm={12}>


                                                    <Form.Item label={item.label} key={index}>
                                                        {getFieldDecorator(`${position}-${item.code}`, {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                        })(
                                                            <Rate />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                    <Form.Item label={I18n.get("Gostaria de deixar um feedback construtivo a este parceiro?")}>
                                        {getFieldDecorator(`${position}-comment`, {
                                            rules: [{ required: false, message: 'ops!' }],
                                        })(
                                            <TextArea
                                                rows={5}
                                            />
                                        )}
                                    </Form.Item>
                                </ItemVote>
                            </Col>
                        </RowItem>
                    );
                })}

                {this.state.partnersFeed && this.state.partnersFeed.map((item, index) => {


                    if (item.partnerId == partnerIdSelected) return null;

                    const invitation = item.partner.projectInvitations.find((a) => a.projectId == this.props.projectId);

                    const accepted = (invitation && invitation.accepted === true);

                    if (!accepted) return null;

                    let position = item.position;

                    return (
                        <RowItem key={index} gutter={1} type="flex">
                            <Col sm={8} className="BoxCard">
                                <PartnerCard key={item.partnerId} {...item.partner} isNotSelected={true} isMini={true} />
                            </Col>

                            <Col sm={16}>
                                <ItemVote>
                                    <Row>
                                        {this.props.bp.clientProjectPartnerNotSelectedReasons2Events && this.props.bp.clientProjectPartnerNotSelectedReasons2Events.map((item) => {
                                            return (
                                                <Col sm={12}>
                                                    <Form.Item label={item.label} key={item.code}>
                                                        {getFieldDecorator(`${position}-${item.code}`, {
                                                            rules: [{ required: false, message: 'ops!' }],
                                                        })(
                                                            <Rate />
                                                        )}
                                                    </Form.Item>

                                                </Col>
                                            );
                                        })}
                                    </Row>
                                    <Form.Item label={I18n.get("Gostaria de deixar um feedback construtivo a este parceiro?")}>
                                        {getFieldDecorator(`${position}-comment`, {
                                            rules: [{ required: false, message: 'ops!' }],
                                        })(
                                            <TextArea
                                                rows={3}
                                            />
                                        )}
                                    </Form.Item>
                                </ItemVote>
                            </Col>
                        </RowItem>
                    );
                })}

                {Object.keys(errorMap).length > 0 && <div className="content is-small">

                    <br /><br />

                    {Object.keys(errorMap).map((item) => {
                        if (item == 2) {
                            return (
                                <p key={item}>
                                    <span className="icon has-text-danger">
                                        <i className="fas fa-exclamation"></i>
                                    </span>
                                    <Link to={`/cliente/projetos/${this.props.match.params.projectId}/parceiro-passo-${item}`}>{I18n.get("Passo")} {item}</Link>
                                    <ul>
                                        <li>{errorMap[item][0]}</li>
                                    </ul>
                                </p>
                            );
                        } else {

                            return (
                                <p key={item}>
                                    <span className="icon has-text-danger">
                                        <i className="fas fa-exclamation"></i>
                                    </span>
                                    <Link to={`/cliente/projetos/${this.props.match.params.projectId}/parceiro-passo-${item}`}>{I18n.get("Passo")} {item}</Link>
                                    <ul>
                                        {errorMap[item].map((errorMessage) => (<li>{errorMessage}</li>))}
                                    </ul>
                                </p>
                            );
                        }
                    })}

                </div>}

                <div className="field is-grouped partnersApprovalReasons" style={{ justifyContent: 'space-between' }}>
                    <p className="control">
                        <Link to={`/cliente/eventos/${this.props.match.params.projectId}/parceiro-passo-1`} className="button bt-bpool grey">{I18n.get("Voltar")}</Link>
                    </p>
                    <p className="control">
                        <SubmitButtonField
                            className="button bt-bpool pull-right black"
                            label={I18n.get("FINALIZAR")}
                            onClick={this.submitForm.bind(this)}
                            task={this.state.task}
                            suppressLabel={true}
                        />
                    </p>
                </div>
            </Form>
        );
    }
}

const MainReasonsForm = Form.create({})(ReasonsForm);

export default (MainReasonsForm);
