import { ITENS_DELETE } from './actions';

export const delete_itens_dash = () => {

    return (dispatch, getState) => {

        dispatch({
            type: ITENS_DELETE,
            payload: {
                spec: undefined,
                asset: undefined,
                brand: undefined,
                historicItem: undefined,
                partnerItem: undefined,
            }
        });
    }
}

export default delete_itens_dash;
