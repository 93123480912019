import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Form, Icon, Spin, Input, Select, Button } from "antd";

import styled from "styled-components";

const { Option } = Select;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`;

const FormBox = styled(Form)``;

class FormScope extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formState: values,
        });

        this.props.action(values);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (this.props.errors != prevProps.errors && this.props.errors) {
      Object.keys(this.props.errors).map((field) => {
        this.props.form.setFields({
          [field]: {
            value: this.state.formState[field],
            errors: [new Error(this.props.errors[field].errorMessage)],
          },
        });
      });
    }
  }

  renderAction = (item) => {
    if (Object.keys(item).length > 0) {
      return I18n.get("Editar");
    } else {
      return I18n.get("Adcionar");
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { filters } = this.props;

    const { item = {} } = this.props;

    // if (!filters) {
    //     return (
    //         <BoxSpin>
    //             <Spin />
    //         </BoxSpin>
    //     )
    // }

    return (
      <FormBox onSubmit={this.handleSubmit}>
        <Row>
          <Col sm={12}>
            <Form.Item label={I18n.get("Nome")}>
              {getFieldDecorator("name", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: item.name || "",
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item label={I18n.get("Idioma")}>
              {getFieldDecorator("language", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: item.language || null,
              })(
                <Select
                  className=""
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option className="" value={null}>
                    {I18n.get("Selecione")}
                  </Option>
                  <Option className="" value="pt">
                    PT
                  </Option>
                  <Option className="" value="es">
                    ES
                  </Option>
                  <Option className="" value="en">
                    EN
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item label={I18n.get("Tipo")}>
              {getFieldDecorator("type", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: item.type || 0,
              })(
                <Select
                  className=""
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option className="" value={0}>
                    {I18n.get("Selecione")}
                  </Option>
                  <Option className="" value={1}>
                    {I18n.get("Vídeo")}
                  </Option>
                  <Option className="" value={2}>
                    {I18n.get("Texto")}
                  </Option>
                  <Option className="" value={3}>
                    {I18n.get("Arquivo")}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item label={I18n.get("Target")}>
              {getFieldDecorator("target", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: item.target || null,
              })(
                <Select
                  className=""
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option className="" value={null}>
                    {I18n.get("Selecione")}
                  </Option>
                  <Option className="" value="C">
                    {I18n.get("Cliente")}
                  </Option>
                  <Option className="" value="P">
                    {I18n.get("Parceiro")}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item label={I18n.get("Ordem")}>
              {getFieldDecorator("order", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: item.order || null,
              })(
                <Select
                  className=""
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option className="" value={null}>
                    {I18n.get("Selecione")}
                  </Option>
                  <Option className="" value={1}>
                    1
                  </Option>
                  <Option className="" value={2}>
                    2
                  </Option>
                  <Option className="" value={3}>
                    3
                  </Option>
                  <Option className="" value={4}>
                    4
                  </Option>
                  <Option className="" value={5}>
                    5
                  </Option>
                  <Option className="" value={6}>
                    6
                  </Option>
                  <Option className="" value={7}>
                    7
                  </Option>
                  <Option className="" value={8}>
                    8
                  </Option>
                  <Option className="" value={9}>
                    9
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item className="labelClean" label="&nbsp;">
              <Button
                className="button bt-bpool black"
                shape="round"
                htmlType="submit"
                // disabled={!filters || getFieldValue("clientId") == ""}
                loading={this.props?.task?.busy}
              >
                {this.renderAction(item)}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </FormBox>
    );
  }
}

const FormFormScope = Form.create({})(FormScope);

export default FormFormScope;
