import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import RadioMultiField from "../form/radio-multi-field";
import MoneyField from "../form/money-field";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

import { CustomEventBriefingType } from "../../model";

import { Input } from "../../v2/components/UI/Input";
import { CurrencyInput } from "../../v2/components/UI/CurrencyInput";

const evt = CustomEventBriefingType.Event;
const shp = CustomEventBriefingType.Shopper;
const ict = CustomEventBriefingType.Incentive;

class PromoForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        souvenirType: {
          labels: {
            [evt]: I18n.get(
              "Pretende oferecer algum brinde ou lembrança do evento?"
            ),
            [shp]: I18n.get("Pretende oferecer algum brinde?"),
            [ict]: I18n.get("Pretende oferecer algum brinde?"),
          },
          name: "souvenirType",
          onChange: this.onChangeForm.bind(this, "souvenirType"),
          // onBlur: this.onSave.bind(this),
          values: [],
        },
        souvenirDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "souvenirDesc"),
          // onBlur: this.onSave.bind(this),
          visited: true,
          successIcon: false,
        },
        souvenirBudget: {
          label: I18n.get("Pensou num Budget por brinde/pessoa?"),
          onChange: this.onChangeForm.bind(this, "souvenirBudget"),
          // onBlur: this.onSave.bind(this, 'souvenirBudget'),
        },
        promoMaterialType: {
          label: I18n.get("Vai precisar de material gráfico?"),
          name: "promoMaterialType",
          onChange: this.onChangeForm.bind(this, "promoMaterialType"),
          // onBlur: this.onSave.bind(this),
          values: [],
        },
        promoMaterialDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "promoMaterialDesc"),
          // onBlur: this.onSave.bind(this),
          visited: true,
          successIcon: false,
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};
    const currency = this.props.currency || {};

    const souvenirTypeValues = constants.EVENT_SOUVENIR_TYPES.map((item) => {
      item.label = I18n.get(item.label);

      if (item.code == "YES_PERSONALIZED") {
        const isSelected = this.state.form.souvenirType == "YES_PERSONALIZED";

        item.field = (
          //   <TextField
          //     className="field field-cond"
          //     {...this.state.fields.souvenirDesc}
          //     value={isSelected && this.state.form.souvenirDesc}
          //     error={isSelected && this.state.errors.souvenirDesc}
          //     disabled={!isSelected}
          //   />

          <>
            {isSelected && (
              <Input
                onChange={(value) =>
                  this.state?.fields?.souvenirDesc.onChange(value)
                }
                value={this.state.form.souvenirDesc}
                disabled={!isSelected}
                error={
                  isSelected && this.state.errors?.souvenirDesc?.errorMessage
                }
              />
            )}
          </>
        );
      }

      if (item.code == "YES_IMMEDIATE") {
        const isSelected = this.state.form.souvenirType == "YES_IMMEDIATE";

        item.field = (
          //   <TextField
          //     className="field field-cond"
          //     {...this.state.fields.souvenirDesc}
          //     value={isSelected && this.state.form.souvenirDesc}
          //     error={isSelected && this.state.errors.souvenirDesc}
          //     disabled={!isSelected}
          //   />

          <>
            {isSelected && (
              <Input
                onChange={(value) =>
                  this.state?.fields?.souvenirDesc.onChange(value)
                }
                value={this.state.form.souvenirDesc}
                disabled={!isSelected}
                error={
                  isSelected && this.state.errors?.souvenirDesc?.errorMessage
                }
              />
            )}
          </>
        );
      }

      if (item.code == "YES_TECH") {
        const isSelected = this.state.form.souvenirType == "YES_TECH";

        item.field = (
          //   <TextField
          //     className="field field-cond"
          //     {...this.state.fields.souvenirDesc}
          //     value={isSelected && this.state.form.souvenirDesc}
          //     error={isSelected && this.state.errors.souvenirDesc}
          //     disabled={!isSelected}
          //   />

          <>
            {isSelected && (
              <Input
                onChange={(value) =>
                  this.state?.fields?.souvenirDesc.onChange(value)
                }
                value={this.state.form.souvenirDesc}
                disabled={!isSelected}
                error={
                  isSelected && this.state.errors?.souvenirDesc?.errorMessage
                }
              />
            )}
          </>
        );
      }

      return item;
    });

    const promoMaterialTypeValues = constants.EVENT_MATERIAL_TYPES.map(
      (item) => {
        item.label = I18n.get(item.label);

        if (item.code == "YES") {
          item.field = (
            // <TextField
            //   className="field field-cond"
            //   {...this.state.fields.promoMaterialDesc}
            //   value={this.state.form.promoMaterialDesc}
            //   error={this.state.errors.promoMaterialDesc}
            //   disabled={this.state.form.promoMaterialType != "YES"}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.promoMaterialDesc.onChange(value)
              }
              value={this.state.form.promoMaterialDesc}
              disabled={this.state.form.promoMaterialType != "YES"}
              error={this.state.errors?.promoMaterialDesc?.errorMessage}
            />
          );
        }

        return item;
      }
    );

    return (
      <div>
        <h1 className="title-bpool medium">
          {I18n.get("Comunicação / Brindes")}
        </h1>

        <div className="bp-event-section bpForce mini">
          <RadioMultiField
            {...this.state.fields.souvenirType}
            labelKey={customEvent.briefingType}
            value={this.state.form.souvenirType}
            error={this.state.errors.souvenirType}
            values={souvenirTypeValues}
          />

          <br style={{ clear: "both" }} />
          <br />

          {/* <MoneyField
            {...this.state.fields.souvenirBudget}
            value={this.state.form.souvenirBudget}
            error={this.state.errors.souvenirBudget}
            style={{ maxWidth: "300px" }}
          /> */}

          <div style={{ width: 400 }}>
            <CurrencyInput
              placeholder={this.state.fields.souvenirBudget.label}
              required
              value={this.state.form.souvenirBudget}
              //   onChange={(value) => onChangeInputForm("total", value)}
              onChange={(value) =>
                this.state.fields.souvenirBudget.onChange(value)
              }
              currency={currency}
              error={this.state.errors?.souvenirBudget?.errorMessage}
            />
          </div>

          <br />
          <br />

          <RadioMultiField
            {...this.state.fields.promoMaterialType}
            value={this.state.form.promoMaterialType}
            error={this.state.errors.promoMaterialType}
            values={promoMaterialTypeValues}
          />
        </div>
      </div>
    );
  }
}

export default PromoForm;
