import React from "react";
import constants from "../../../../../../../constants";
import { I18n } from "aws-amplify";
import { Tooltip, Icon } from "antd";
import * as S from "./styles";

export const Invoices = ({ data }) => {
  const circleStatus = ({ isDueDateApproaching, isPastDueDate, isPaid }) => {
    let alertCmp = null;
    if (isDueDateApproaching) {
      alertCmp = <i style={{ marginLeft: 5 }} className="bolStatus vencer"></i>;
    }
    if (isPastDueDate || isPaid) {
      alertCmp = <i style={{ marginLeft: 5 }} className="bolStatus pago"></i>;
    }
    return alertCmp;
  };

  const renderPaymentBeneficiary = (paymentBeneficiary) => {
    if (paymentBeneficiary?.paymentMethod === "TRANSFER") {
      const bankName = constants?.BANK_IDS?.find(
        (item) => item?.code === paymentBeneficiary?.bankId
      );
      return (
        <>
          <li>
            <label>{I18n.get("Transferência")}</label>
          </li>
          <li>
            <label>{`${I18n.get("Banco")}: `}</label>
            <p>{bankName?.label}</p>
          </li>
          <li>
            <label>{`${I18n.get("Agência")}: `}</label>
            <p>{paymentBeneficiary?.bankBranch}</p>
          </li>
          <li>
            <label>{`${I18n.get("Conta")}: `}</label>
            <p>{paymentBeneficiary?.bankAccount}</p>
          </li>
        </>
      );
    } else if (paymentBeneficiary?.paymentMethod === "PIX") {
      return (
        <>
          <li>
            <label>{I18n.get("Pix")}</label>
          </li>
          <li>
            <label>{`${I18n.get("Chave pix")}: `}</label>
            <p>{paymentBeneficiary?.pix}</p>
          </li>
        </>
      );
    } else if (paymentBeneficiary?.paymentMethod === "SWIFT") {
      return (
        <>
          <li>
            <label>{I18n.get("Swift")}</label>
          </li>
        </>
      );
    } else if (paymentBeneficiary?.paymentMethod === "SLIP") {
      return (
        <>
          <li>
            <label>{`${I18n.get("Boleto")}: `}</label>
            <p>
              <a
                href={paymentBeneficiary?.slip?.url}
                download="download"
                style={{ color: "#000", fontSize: 14 }}
              >
                <Icon type="file-pdf" /> &nbsp;
                {paymentBeneficiary?.slip?.name}
              </a>
            </p>
          </li>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <S.InvoicesList>
      <S.IconNF>
        <a href={data?.file?.url} target="_blank" style={{ color: "#ccc" }}>
          <i className="fas fa-file-invoice fa-3x" aria-hidden="true"></i>
        </a>
      </S.IconNF>
      <ul>
        <li>
          <label>{`${I18n.get("NF")}: `}</label>
          <p>{data?.invoiceNumber}</p>
        </li>
        <li>
          <label>{`${I18n.get("Valor")}: `}</label>
          <p>{data?.totalFmt}</p>
        </li>
        <li>
          <label>{`${I18n.get("Retenção IR")}: `}</label>
          <p>{`${data?.tax}%`}</p>
        </li>
        <li>
          <label>{`${I18n.get("Vencimento")}: `}</label>
          <p>
            <Tooltip
              title={I18n.get(
                "O pagamento é realizado pela BPool em até 3 dias do recebimento do cliente"
              )}
              placement="topRight"
              overlayClassName="tooltip-status-bpay"
            >
              {data.clientDueDate}
              {circleStatus({
                isDueDateApproaching: data?.isDueDateApproaching,
                isPastDueDate: data?.isPastDueDate,
                isPaid: data?.isPaid,
              })}
            </Tooltip>
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <label>{`${I18n.get("Favorecido")}: `}</label>
          <p>{data?.paymentBeneficiary?.beneficiaryName}</p>
        </li>
        <li>
          <label>{`${I18n.get("CNPJ")}: `}</label>
          <p>{data?.paymentBeneficiary?.beneficiaryId}</p>
        </li>
        {renderPaymentBeneficiary(data?.paymentBeneficiary)}
      </ul>
    </S.InvoicesList>
  );
};
