import React from 'react';

import { I18n } from 'aws-amplify';

import constants from '../../constants';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import TextField from '../form/text-field';
import MaskedField from '../form/masked-field';
import SelectField from '../form/select-field';

class ReferenceForm extends React.Component {

    constructor(props) {

        super();
        this.state = {
            form: {
                name: {
                    label: I18n.get("Nome"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'name'),
                },
                email: {
                    label: I18n.get("E-mail"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'email'),
                    error: props.errors.email,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'email'),
                },
                linkedInUrl: {
                    label: I18n.get("LinkedIn"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'linkedInUrl'),
                    error: props.errors.linkedInUrl,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'linkedInUrl'),
                },
                year: {
                    label: I18n.get("Ano"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'year'),
                    error: props.errors.year,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'year'),
                    mask: [/\d/, /\d/, /\d/, /\d/]
                },
                relation: {
                    label: I18n.get("Relação prof."),
                    value: props.relation,
                    onChange: this.onChangeForm.bind(this, 'relation'),
                    error: props.errors.relation,
                    successIcon: false,
                    onBlur: this.onBlur.bind(this, 'relation'),
                    visited: true
                },
            },
            task: {}
        };

        this.addReference = this.onAddReference.bind(this);
        this.delReference = this.onDelReference.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);
        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    getData() {

        var data = {
            partnerId: this.props.partnerId,
        };

        Object.keys(this.state.form).forEach((item) => {

            if (FormUtils.isFormField(this.state.form[item])) {

                data[item] = this.state.form[item].value;
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        //salva uma copia do objeto
        data['relation'] = this.props.bp.partnerReferenceRelation.find((p) => p.code == this.state.form.relation.value);;

        return data;
    }

    onAddReference(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.addReference(this.getData());
        });
    }

    onDelReference(item, e) {
        e.preventDefault();

        const data = Object.assign({}, this.getData(), { id: item.id });

        this.props.delReference(data);
    }

    render() {

        return (
            <div>

                <div className="field is-horizontal">
                    <div className="field-body">

                        <TextField {...this.state.form.name} />

                        <TextField {...this.state.form.email} />

                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-body">
                        <TextField {...this.state.form.linkedInUrl} />

                        <MaskedField {...this.state.form.year} />

                        <SelectField {...this.state.form.relation} values={this.props.bp.partnerReferenceRelation} />

                        <div className="field is-narrow">
                            <label className="label">
                                &nbsp;
                            </label>
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={this.addReference}
                                    className="button is-leaked">{I18n.get("SALVAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                            </p>
                        </div>

                    </div>
                </div>

                {this.props.references && this.props.references.length > 0
                    && <table className="table is-bordered is-fullwidth">
                        <thead>
                            <tr>
                                <th>{I18n.get("Nome")}</th>
                                <th className="is-not-mobile">{I18n.get("E-mail")}</th>
                                <th className="is-not-mobile">{I18n.get("LinkedIn")}</th>
                                <th className="is-not-mobile">{I18n.get("Ano")}</th>
                                <th className="is-not-mobile">{I18n.get("Relação profissional")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.references.map((item, index) =>
                                <tr key={index}>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td className="is-not-mobile">
                                        {item.email}
                                    </td>
                                    <td className="is-not-mobile">
                                        {item.linkedInUrl}
                                    </td>
                                    <td className="is-not-mobile">
                                        {item.year}
                                    </td>
                                    <td className="is-not-mobile">
                                        {item.relation && item.relation.label}
                                    </td>
                                    <td>
                                        <a onClick={(e) => this.delReference(item, e)}>
                                            <i className="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>}
            </div>
        );
    }
}

export default ReferenceForm;
