import React from "react";
import { I18n } from "aws-amplify";
import { withRouter } from "react-router-dom";

import ExtrasContainer from "../../containers/extras-container";

import FillForm from "../page-templates/fillForm-logged";

import {NewExtra} from "../../v2/pages/Proposal/Extras/pages/NewExtra";

import Steps from "./steps";

import NewForm from "./extras/_new-form";

import StepsCreative from "../../components/client-project-definition/steps-creative";

class ExtrasNewForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    console.log({ ExtrasNewForm: this.props });

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container">
            <div className="columns is-mobile">
              <div
                className="column is-6"
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="title">
                  {this.props?.project && this.props?.project?.status != 6
                    ? I18n.get("Projeto em Andamento")
                    : I18n.get("Definições do Projeto")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6">
                {this.props?.project && this.props?.project?.status == 6 && (
                  <>
                    <StepsCreative
                      active={5}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                    />
                  </>
                )}

                {this.props?.project && this.props?.project?.status != 6 && (
                  <Steps
                    active={6}
                    project={this.props.project}
                    hidden={this.props.auth.is_partner ? [5] : []}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="client-step client-step-1 project-ongoing">
          <div className="container client-container-1 container-content" style={{padding: 0}}>         

            <div>
              <div className="columns">
                <div className="column is-4">
                  {/* <NewForm {...this.props} /> */}
                  <NewExtra />
                </div>

                <div className="column is-6 is-offset-1"></div>
              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ExtrasContainer(withRouter(ExtrasNewForm));
