import React from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';

class Review extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    getProductName() {

        var name = '';

        if (!this.props.client.brands) return name;

        this.props.client.brands.forEach((brand) => {

            brand.products.forEach((product) => {

                if (product.id == this.props.productId) {
                    name = `${brand.name} - ${product.name}`;
                }
            });

        });

        return name;
    }
    
    render() {

        return (
            <div>

                <h1 className="title-bpool small">{I18n.get("Informações")}</h1>

                <div className="card">
                    <div className="card-content">
                        <p>
                            <strong>{I18n.get("Nome do Projeto:")}</strong> {this.props.projectName}
                        </p>
                        <p>
                            <strong>{I18n.get("Número do Projeto:")}</strong> {this.props.projectSKU}
                        </p>
                        <p>
                            <strong>{I18n.get("Produto:")}</strong> {this.getProductName()}
                        </p>
                    </div>
                </div>

                {this.props.enableLinkButton && <>

                    <Link to={`/cliente/projetos/${this.props.match.params.projectId}/passo-1`} className="button is-link-to-return">{I18n.get("Redefinir")}</Link>

                </>}

            </div>
        );
    }
}

export default Review;
