import React from 'react';
import { I18n } from 'aws-amplify';
// import { ShakeRotate } from 'reshake'
import { NavLink, Redirect } from 'react-router-dom';
import moment from 'moment';

const ChatNavBarItem = (props) => {

    // const YesMessage = () => (
    //     <ShakeRotate active={true} h={0} v={2} r={25} dur={330}
    //         int={50} max={100} fixed={true} fixedStop={false} freez={false} >
    //         <i className="has-text-warning bellNotification fa fa-bell"></i>
    //     </ShakeRotate>
    //   );

    const YesMessage = () => (
        <i className="bellNotificationYes far fa-bell"></i>
    );

    const NoMessage = () => (
        <i className="bellNotificationNo far fa-bell"></i>
    );

    const msgs = props.chat && props.chat.notifications
        && props.chat.notifications.length > 0
        && props.chat.notifications || [];

    const notSeen = msgs.filter(x => x.seen === 0);

    var bell;

    if (notSeen.length > 0) {
        bell = <YesMessage />;
    } else {
        bell = <NoMessage />;
    }

    const statusStyle = {
        backgroundColor: '#ffffff'
    };

    // se nao tiverem notificacoes alinha no final
    if (notSeen.length == 0) {
        statusStyle['position'] = 'absolute';
        statusStyle['bottom'] = '0';
    }

    return (
        <div className="navbar-item has-dropdown is-hoverable" style={{ height: "auto" }}>

            <a className="navbar-link is-arrowless" style={{ color: '#ffffff' }}>
                {bell}
            </a>

            <div className="navbar-dropdown navbar-notification-window is-right">

                <div className="navbar-item level-right notificationViewAll">
                    <p style={{ padding: '0.5rem' }}>
                        <NavLink to="/notificacoes">{I18n.get("Ver todas")}</NavLink>
                    </p>
                </div>


                {notSeen.length > 0
                    && notSeen.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, index) => {

                        return (<div key={index} className="navbar-item">
                            <div style={{ padding: '0.5rem' }}>

                                {item.url && <NavLink to={item.url} >
                                    <p className="iconNotificationWithLink">{item.message}</p>
                                    <small>{moment(item.createdAt).fromNow()}</small>
                                </NavLink>}

                                {!item.url && <>
                                    <p className="iconNotificationWithOutLink">{item.message}</p>
                                    <small>{moment(item.createdAt).fromNow()}</small>
                                </>}

                            </div>
                        </div>)
                    })}

                {notSeen.length == 0 && <>
                    <div className="navbar-item">
                        <p style={{ padding: '0.5rem' }}>{I18n.get("Sem mensagens novas...")}</p>
                    </div>
                </>}

                <div className="navbar-item" style={statusStyle}>
                    <p style={{ textAlign: 'center', fontSize: '8pt', color: '#909090' }} title={I18n.get('Status da conexão com o servidor')}>
                        {props.chat.status}
                    </p>
                </div>

            </div>



        </div>
    );
}


export default ChatNavBarItem;
