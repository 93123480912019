import axios from "axios";
import { Auth } from "aws-amplify";
import { notification } from "antd";
import { I18n } from "aws-amplify";

import Config from "../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const update_commission = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };
        // 

        return axios
          .put(
            `${Config.API_BASE_URL}/bp/admin/project-change-comission-no-calculate`,
            data,
            config
          )
          .then((response) => {
            notification.success({
              message: I18n.get("Atualizado com sucesso!"),
              description: "",
            });

            return response;
          })
          .catch((error) => {
            notification.error({
              message: I18n.get("Ops, algo deu errado!"),
              description: I18n.get("Tente novamente mais tarde"),
            });
            return error;
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default update_commission;
