import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import TextField from '../form/text-field';
import SelectField from '../form/select-field';
import CheckboxField from '../form/checkbox-field';

import SelectSearchField from '../form/select-search-field';

class MarketExpertiseForm extends React.Component {

    constructor(props) {
        super();
        this.state = {
            form: {
                type: props.type,
                sectorId: {
                    label: I18n.get("Categoria"),
                    value: '',
                    onChange: this.onChangeMarketExpertiseSectorId.bind(this),
                    error: props.errors.sectorId,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'sectorId'),
                },
                years: {
                    label: I18n.get("Anos"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'years'),
                    error: props.errors.years,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'years'),
                },
                months: {
                    label: I18n.get("Meses"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'months'),
                    error: props.errors.months,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'months'),
                },
                brands: {
                    label: I18n.get("Marcas"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'brands'),
                    error: props.errors.brands,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'brands'),
                },
            },
            task: {},
        };

        this.addMarketExpertise = this.onAddMarketExpertise.bind(this);
        this.delMarketExpertise = this.onDelMarketExpertise.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);
        }

        this.setState(nextState);
    }

    onChangeMarketExpertiseSectorId(value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['sectorId'].value = value;

            return nextState;
        });
    }

    onAddMarketExpertise(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.addMarketExpertise(this.getData());
        });
    }

    onDelMarketExpertise(item, e) {


        e.preventDefault();
        const data = Object.assign({}, this.getData(), { id: item.id });
        this.props.delMarketExpertise(data);
    }

    getMarketExpertises() {

        if (!this.props.marketExpertises) return [];

        return this.props.marketExpertises.filter((item) => item.type == this.props.type);
    }

    getSector(sectorId) {

        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }



    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    getData() {

        var data = {
            partnerId: this.props.partnerId,
        };

        Object.keys(this.state.form).forEach((item) => {

            if (FormUtils.isFormField(this.state.form[item])) {

                data[item] = this.state.form[item].value;
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    getYears() {

        const years = [
            { label: "00", id: 0 },
            { label: "01", id: 1 },
            { label: "02", id: 2 },
            { label: "03", id: 3 },
            { label: "04", id: 4 },
            { label: "05", id: 5 },
            { label: "06", id: 6 },
            { label: "07", id: 7 },
            { label: "08", id: 8 },
            { label: "09", id: 9 },
            { label: "10", id: 10 },
            { label: "11", id: 11 },
            { label: "12", id: 12 },
            { label: "13", id: 13 },
            { label: "14", id: 14 },
            { label: "15", id: 15 },
            { label: "16", id: 16 },
            { label: "17", id: 17 },
            { label: "18", id: 18 },
            { label: "19", id: 19 },
            { label: "20", id: 20 },
            { label: "21", id: 21 },
            { label: "22", id: 22 },
            { label: "23", id: 23 },
            { label: "24", id: 24 },
            { label: "25", id: 25 },
            { label: "26", id: 26 },
            { label: "27", id: 27 },
            { label: "28", id: 28 },
            { label: "29", id: 29 },
            { label: "30", id: 30 }
        ];

        return years;
    }

    getMonths() {

        const months = [
            { label: "00", id: 0 },
            { label: "01", id: 1 },
            { label: "02", id: 2 },
            { label: "03", id: 3 },
            { label: "04", id: 4 },
            { label: "05", id: 5 },
            { label: "06", id: 6 },
            { label: "07", id: 7 },
            { label: "08", id: 8 },
            { label: "09", id: 9 },
            { label: "10", id: 10 },
            { label: "11", id: 11 },
            { label: "12", id: 12 }
        ];

        return months;

    }

    getCategoryValues() {
        return this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.sort((a, b) => (a.label > b.label) - (a.label < b.label));
    }

    render() {

        const marketExpertises = this.getMarketExpertises();


        return (
            <section>
                <div className="columns">

                    <div className="column is-4">

                        <SelectSearchField {...this.state.form.sectorId} values={this.getCategoryValues()} />

                    </div>

                    <div className="column is-2">

                        <SelectField {...this.state.form.years} small="{true}" values={this.getYears()} className="years-select-expertise" />

                    </div>

                    <div className="column is-2">

                        <SelectField {...this.state.form.months} small="{true}" values={this.getMonths()} className="years-select-expertise" />

                    </div>

                    <div className="column is-3">

                        <TextField {...this.state.form.brands} />

                    </div>

                    <div className="column is-1">

                        <div className="field is-narrow">
                            <label className="label">
                                &nbsp;
                            </label>
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={this.addMarketExpertise}
                                    className="button is-leaked">{I18n.get("SALVAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                            </p>
                        </div>

                    </div>

                </div>
                <div className="handler-table">
                    <div className="container-table">
                        {marketExpertises && marketExpertises.length > 0
                            && <table className="table is-bordered is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>{I18n.get("Expertise")}</th>
                                        <th>{I18n.get("Anos")}</th>
                                        <th>{I18n.get("Meses")}</th>
                                        <th>{I18n.get("Marcas")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marketExpertises.map((item, index) =>
                                        <tr key={index}>
                                            <td>{this.getSector(item.sectorId).label}</td>
                                            <td>{item.years}</td>
                                            <td>{item.months}</td>
                                            <td>{item.brands}</td>
                                            <td className="has-text-centered">
                                                <a onClick={(e) => this.delMarketExpertise(item, e)}>
                                                    <i className="fa fa-trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>}
                    </div>
                </div>

            </section>
        );
    }
}

export default MarketExpertiseForm;
