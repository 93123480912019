import { PartnerStatus } from '../model';

export const verifyStatusPartner = async (partnerState) => {
  if (partnerState && partnerState?.status === PartnerStatus.Incomplete) {
    //Pre cadastro incompleto - Status 1
    return 1;
  } else if (
    partnerState &&
    partnerState?.status === PartnerStatus.PreApproved
  ) {
    //Pré selecionado com cadastro full incompleto - Status 5
    return 5;
  } else if (partnerState && partnerState.status === PartnerStatus.Referenced) {
    //Pre cadastro - 3 avaliaram - Status 3
    return 3;
  } else if (partnerState && partnerState.status === PartnerStatus.Created) {
    //Pre cadastro finalizado - faltando referencias - Status 2
    return 2;
  } else if (partnerState && partnerState.status === PartnerStatus.Approved) {
    //Aprovado - Status 7
    return 7;
  } else {
    return 99;
  }
};
