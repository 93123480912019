import styled from "styled-components";

export const StyleForm = styled.form`
  width: 80%;
  margin: 60px auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyleMain = styled.div`
  max-width: 630px;
  margin: 50px auto 0;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  a,
  button {
    margin-left: 10px;
  }
`;
