import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Collapse } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";

import * as S from "./styles";

import COUNTRY_CODES from "./countryCodes.json";

const { Panel } = Collapse;

export const ItemCases = ({ data, onDelete, onEdit }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    id: null,
  });

  const handleDeleteModal = (id) => {
    if (openDeleteModal.open) {
      setOpenDeleteModal({ open: false, id: null });
    } else {
      setOpenDeleteModal({ open: true, id });
    }
  };

  const modalConfirmDelete = () => {
    onDelete(openDeleteModal.id);
    setOpenDeleteModal({ open: false, id: null });
  };

  const onChange = (key) => {
    return;
  };

  const handleEdit = (id) => {
    onEdit(id);
  };

  const genExtra = (id) => (
    <>
      <EditOutlined
        onClick={(event) => {
          event.stopPropagation();
          handleEdit(id);
        }}
      />
      <DeleteOutlined
        style={{ marginLeft: 15 }}
        onClick={(event) => {
          event.stopPropagation();
          handleDeleteModal(id);
        }}
      />
    </>
  );

  const renderInfo = ({ label, value }) => {
    return (
      <S.ItemCase>
        <S.ItemCaseLabel>{label}</S.ItemCaseLabel>
        <S.ItemCaseValue>{value ? value : "---"}</S.ItemCaseValue>
      </S.ItemCase>
    );
  };

  const renderTypeName = (type) => {
    const types = {
      1: "Company",
      2: "Leadership",
    };

    return types[type];
  };

  const countryView = (code) => {
    const countryItem = COUNTRY_CODES.filter((item) => item.code === code);

    return countryItem[0]?.name;
  };

  return (
    <>
      <S.WrapperItemCaseForm>
        {data.length ? (
          <Collapse onChange={onChange}>
            {data.map((item, index) => (
              <Panel
                key={item?.id}
                header={`Case ${index + 1} - ${item?.companyName}`}
                extra={genExtra(item?.id)}
              >
                <S.ContentItemCase>
                  {renderInfo({
                    label: I18n.get("Name"),
                    value: item?.name,
                  })}
                  {renderInfo({
                    label: I18n.get("Company name"),
                    value: item?.companyName,
                  })}
                  {renderInfo({ label: I18n.get("Link"), value: item?.link })}
                  {renderInfo({
                    label: I18n.get("Country"),
                    value: countryView(item?.countryCode),
                  })}
                  {renderInfo({ label: I18n.get("Brand"), value: item?.brand })}
                  {renderInfo({
                    label: I18n.get("Category"),
                    value: item?.industryDescription,
                  })}
                  {renderInfo({ label: I18n.get("Year"), value: item?.year })}
                  {renderInfo({
                    label: I18n.get("Case of"),
                    value: renderTypeName(item?.type),
                  })}
                </S.ContentItemCase>
              </Panel>
            ))}
          </Collapse>
        ) : (
          <S.ItemCaseFormEmpty>
            {/* {I18n.get('No cases added so far.')} */}
          </S.ItemCaseFormEmpty>
        )}
      </S.WrapperItemCaseForm>

      <ModalBp
        visible={openDeleteModal.open}
        bodyText={I18n.get("Do you want to delete?")}
        subBodyText={I18n.get("You will lose this item.")}
        okText={I18n.get("Delete")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={modalConfirmDelete}
        onCancel={handleDeleteModal}
        typeModal="isConfirm"
        isDanger
      />
    </>
  );
};
