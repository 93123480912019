import { PROJECTS_GET, PROJECT_GET, FILTERS_GET } from "../../actions";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECTS_GET: //all projects
      const newState = Object.assign({}, state, action.payload);
      return newState;
    case PROJECT_GET: // single project
      const singleProject = Object.assign({}, state, action.payload);
      return singleProject;
    case FILTERS_GET: // single project
      const filtersProjects = Object.assign({}, state, action.payload);
      return filtersProjects;
    default:
      return state;
  }
};

export default reducer;
