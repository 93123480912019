import React from "react";

import NumberFormat from "react-number-format";

export const FormatCurrency = ({ amount, currency = {} }) => {
  // {"code":"USD","name":"US Dollar","symbolOnly":"Us$","symbol":"US Dollar Us$","thousandSeparator":",","decimalSeparator":".","enabled":true}

  const thousandSeparator = currency?.thousandSeparator || ".";
  const decimalSeparator = currency?.decimalSeparator || ",";
  const symbolOnly = currency?.symbolOnly || "R$";

  return (
    <NumberFormat
      value={amount}
      displayType={"text"}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      prefix={`${symbolOnly} `}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

