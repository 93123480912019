export const ConvertBpCurrenciesInList = (currencies) => {
  if (currencies?.length) {
    const currenciesFormatted = currencies.map((currency) => {
      return {
        code: currency.code,
        label: currency.code,
      };
    });
    return currenciesFormatted;
  } else {
    return [];
  }
};
