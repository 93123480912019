
import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TooltipField from '../form/tooltip-field';


const mapStateToProps = state => {
    return state;
};
const mapDispatchToProps = dispatch => {
    return {}
};

class Steps extends React.Component {
    constructor(props) {
        super();
        this.state = {
            items: [
                {
                    index: 1,
                    title: I18n.get("Briefing"),
                    color: "#FFC5B9",
                    link: `/projetos/${props.projectId}/detalhamento-passo-1`
                },
                {
                    index: 2,
                    title: I18n.get("Milestones"), //MILESTONES
                    color: "#C7E5F2",
                    link: `/projetos/${props.projectId}/detalhamento-passo-2`
                },
                {
                    index: 3,
                    title: I18n.get("Review"),
                    color: "#E3FBB8",
                    link: `/projetos/${props.projectId}/detalhamento-passo-3`
                },
                {
                    index: 4,
                    title: I18n.get("faturamentoPO"),
                    color: "#BAB1D1",
                    link: `/projetos/${props.projectId}/detalhamento-passo-4`
                },
                {
                    index: 5,
                    title: I18n.get("Extras"),
                    color: "#BAB1D1",
                    link: `/projetos/${props.projectId}/extras`
                }

            ]
        };
    }

    render() {

        let { items } = this.state
        const { active, project: { status } } = this.props

        let partnerName = '';

        if (this.props.reference && this.props.reference.partnerName) {

            partnerName = this.props.reference.partnerName;
        }

        let txt = '';

        let txtPo = '';

        if (this.props.auth.is_client || this.props.auth.is_client_user)
            txtPo = '';
        else
            txtPo = I18n.get('Quando diponível, o número de PO irá aparecer para você nesta área abaixo. O projeto não deverá começar antes que o cliente informe tal número.');

        const isPartner = this.props.auth.is_partner;



        const {terms = null} = this.props;

        let useFlow = this.props.client && this.props.client.customization && this.props.client.customization.useSignFlow;

        if (this.props.auth.is_partner === true) {
            items = items.filter(ff=> ff.index !== 4);
        }

        let txtProp = '';
        if (this.props.auth.is_client || this.props.auth.is_client_user)
            txtProp = I18n.get('O parceiro irá recomendar uma estimativa com macro entregas de acordo com as necessidades do briefing.');


        return (
            <div>
                <ul id="partner-signup-steps" className="reference-signup-steps">
                    {items.map(function (item) {

                        switch (active) {
                            // case 1:
                            //     txt = 'Esta é uma área comum e colaborativa para preenchimento de informações relevantes para a execução do projeto. Ao menos cinco (5) dos campos abaixo devem ser preenchidos (sinalizados com *) pelo cliente e validados pelo parceiro criativo. Arquivos de apoio poderão ser adicionados.';
                            //     break;
                            case 2:
                                txt = txtProp;
                                break;
                            case 4:
                                txt = txtPo;
                                break;
                            default:
                                txt = '';
                        }

                        const hideStep = (item.index >= 4 && status < 6) || (item.index == 4 && isPartner === true) ;

                        const lineStyle = {
                            background: item.color,
                        };

                        return (
                            <li key={item.index} className={((active && item.index === active) && "progress-bar--item is-active") || `progress-bar--item ${status < 6 && item.index == 5 ? "disabled":""}`}>
                                <h3>
                                    {(active && item.index === active && txt) && <TooltipField tooltip={txt} orientation="bottom" canClose={true} withoutquestionmark="true" isfixed="true" />}
                                </h3>
                                <Link 
                                    to={item.link} 
                                    className={`progress-bar--menu-item ${(status < 6 && item.title == I18n.get("faturamentoPO")) || (item.title == I18n.get("faturamentoPO") && !terms) || hideStep  ? 'link-disable' : ''}`}
                                    disabled={status < 6 && item.index == 5}
                                >
                                    <span style={lineStyle}></span>
                                    <strong className="is-not-mobile">{item.title}</strong>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
