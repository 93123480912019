import React, { useState, useEffect, useContext } from "react";
import { I18n } from "aws-amplify";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Steps } from "antd";
import Loading from "../../../../components/UI/Loading";
import BpayContext from "../../context/BpayContext";

import * as S from "./styles";

const { Step } = Steps;

const stepsInit = [
  {
    title: "Dados do BPay",
    description: "Aguardando",
    url: "/cliente/bpay/new/data",
    key: "data",
    status: "wait",
    blocked: false,
  },
  {
    title: "Cadastro de fornecedor",
    description: "Aguardando",
    url: "/cliente/bpay/new/suppliers",
    key: "suppliers",
    status: "wait",
    blocked: true,
  },
  {
    title: "Inserir PO",
    description: "Aguardando",
    url: "/cliente/bpay/new/po",
    key: "po",
    status: "wait",
    blocked: true,
  },
];

export const StepsVertical = () => {
  let history = useHistory();
  let location = useLocation();
  let params = useParams();
  const { sharedStateBpay } = useContext(BpayContext);
  const [steps, setSteps] = useState(stepsInit);
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [blockSteps, setBlockSteps] = useState(true);

  const whatStepClick = (value) => {
    const step = steps[value];
    return step;
  };

  const handleOnChange = (current) => {
    setCurrent(current);
    const step = whatStepClick(current);
    history.push(step?.url);
  };

  const stepSupplierInfos = (statusProject) => {
    if (statusProject === 1) {
      return {
        blocked: false,
        step: { status: "wait", description: "Aguardando" },
      };
    } else if (statusProject === 2) {
      return {
        blocked: false,
        step: { status: "wait", description: "Aguardando" },
      };
    } else if (statusProject === null) {
      return {
        blocked: true,
        step: { status: "wait", description: "Aguardando" },
      };
    } else {
      return {
        blocked: false,
        step: { status: "finish", description: "Concluído" },
      };
    }
  };

  const stepPoIsBlocked = (statusProject) => {
    if (statusProject === 1) {
      return true;
    } else if (statusProject === 2) {
      return true;
    } else if (statusProject === null) {
      return true;
    } else {
      return false;
    }
  };

  const organizeSteps = async ({ urlActual, bpayId }) => {
    const indexCurrent = stepsInit.findIndex((item) => item.url === urlActual);
    setCurrent(indexCurrent);

    const stepsUpdateds = stepsInit?.map((item) => {
      if (item?.key === "data") {
        if (sharedStateBpay?.data?.id) {
          return {
            ...item,
            status: "finish",
            description: "Concluído",
            url: bpayId ? `${item?.url}/${bpayId}` : item?.url,
          };
        } else {
          return {
            ...item,
            status: "wait",
            description: "Aguardando",
            url: bpayId ? `${item?.url}/${bpayId}` : item?.url,
          };
        }
      }
      if (item?.key === "suppliers") {
        return {
          ...item,
          status: stepSupplierInfos(sharedStateBpay?.data?.status || null).step
            .status,
          description: stepSupplierInfos(sharedStateBpay?.data?.status || null)
            .step.description,
          url: bpayId ? `${item?.url}/${bpayId}` : item?.url,
          blocked: stepSupplierInfos(sharedStateBpay?.data?.status || null)
            .blocked,
        };
      }
      if (item?.key === "po") {
        return {
          ...item,
          status: "wait",
          description: "Aguardando",
          url: bpayId ? `${item?.url}/${bpayId}` : item?.url,
          blocked: stepPoIsBlocked(sharedStateBpay?.data?.status || null),
        };
      }
    });

    if (stepsUpdateds[indexCurrent]) {
      stepsUpdateds[indexCurrent].status = "process";
      stepsUpdateds[indexCurrent].description = "Em andamento";
    }
    setSteps(stepsUpdateds);
  };

  const loadInit = async ({ pathname, bpayId }) => {
    const parts = pathname.split("/");
    const urlActual = "/" + parts.slice(1, 5).join("/");

    if (bpayId) {
      setBlockSteps(false);
    } else {
      setBlockSteps(true);
    }

    setIsLoading(true);
    await organizeSteps({ urlActual, bpayId });

    if (bpayId && !sharedStateBpay?.data?.id) {
      setIsLoading(true);
    } else if (sharedStateBpay?.data?.id !== bpayId) {
      setIsLoading(true);
    } else if (bpayId && sharedStateBpay?.data?.id) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInit({ pathname: location?.pathname, bpayId: params?.bpayId });
  }, [location, params, sharedStateBpay]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Skeleton
          loading={isLoading}
          active
          title={false}
          paragraph={{
            rows: 6,
            className: "skeleton-step",
          }}
        >
          <Steps
            current={current}
            onChange={handleOnChange}
            direction="vertical"
          >
            {steps?.map((step, index) => (
              <Step
                key={index}
                title={I18n.get(step?.title)}
                description={I18n.get(step?.description)}
                status={step?.status}
                className={step?.blocked ? "disabled" : "enabled"}
              />
            ))}
          </Steps>
        </S.Skeleton>
      </S.Content>
    </S.Wrapper>
  );
};
