import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
    return {
    };
};

class Menu extends React.Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <nav className="navbar is-white is-submenu">
                <div className="container">
                    <div className="navbar-menu">
                        <div className="navbar-start is-white">
                            <NavLink to="/cliente/usuario" className="navbar-item" activeClassName="is-active">{I18n.get("Cadastro")}</NavLink>
                        </div>
                        <div className="navbar-end">
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
