import React from "react";
import NumberFormat from "react-number-format";
import { ClientProjectStatus } from "../../model";
import { I18n } from "aws-amplify";

// FORMATAÇÃO DE VALORES $$
export const FormatCurrency = ({ amount, currency = {} }) => {
  const thousandSeparator = currency?.thousandSeparator || ".";
  const decimalSeparator = currency?.decimalSeparator || ",";
  const symbolOnly = currency?.symbolOnly || "R$";

  return (
    <NumberFormat
      value={amount}
      displayType={"text"}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      prefix={`${symbolOnly} `}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

export const compareByAlph = (a, b) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

export const getProducts = (brands) => {
  var items = [];

  brands &&
    brands.map((brand) =>
      brand?.products.map((product) => {
        if (product.categoryId) {
          items.push({
            code: `${product.id}-${brand.id}`,
            label: `${brand.name} - ${product.name}`,
            global: product.global,
          });
        }
      })
    );

  return items;
};
export const formatarNumero = (string) => {
  string = string.replace(/[.,]/g, "");

  const tamanho = string.length;
  if (tamanho <= 2) {
    string = "0," + string.padStart(2, "0");
  } else {
    const parteInteira = string.slice(0, tamanho - 2);
    const parteDecimal = string.slice(tamanho - 2);
    string = parteInteira + "," + parteDecimal;
  }

  return string;
};

export const getActionClient = (item) => {
  if (item.status == ClientProjectStatus.Draft) {
    if (item.projectType == 0) {
      return {
        label: I18n.get("Editar"),
        redirectUrl: `/cliente/projetos/v2/criar-projeto/${item.id}`,
      };
    } else {
      return {
        label: I18n.get("Editar"),
        redirectUrl: `/cliente/projetos/${item.id}/passo-1`,
      };
    }
  }

  if (
    item.status == ClientProjectStatus.Created ||
    item.status == ClientProjectStatus.PreMatched
  ) {
    if (item.projectType == 0) {
      return {
        label: I18n.get("Aguardando Matching"),
        redirectUrl: `/cliente/projetos/v2/matching/${item.id}`,
      };
    } else {
      return { label: I18n.get("Matching..."), matching: true };
    }
  }

  if (item.status == ClientProjectStatus.Matched) {
    if (item.projectType === 0) {
      return {
        label: I18n.get("Selecionar Parceiro"),
        redirectUrl: `/cliente/projetos/v2/selecionar-parceiro/${item.id}/passo-1`,
      };
    } else {
      return {
        label: I18n.get("Selecionar Parceiro"),
        redirectUrl: `/cliente/eventos/${item.id}/parceiro-passo-1`,
        // redirectUrl: `/cliente/projetos/v2/selecionar-parceiro/${item.id}/passo-1`,
      };
    }
  }
  if (item.status == ClientProjectStatus.PartnerSelected) {
    if (item.createdByPartner == true) {
      return {
        label: I18n.get("Proposta Parceiro"),
        redirectUrl: `cliente/projetos/v2/${item.id}/briefing`,
      };
    } else {
      if (item.projectType == 2) {
        return {
          label: I18n.get("Briefing & Cronograma"),
          redirectUrl: `/projetos/${item.id}/detalhamento-passo-2`,
        };
      } else {
        return {
          label: I18n.get("Briefing & Cronograma"),
          redirectUrl: `/cliente/projetos/v2/${item.id}/briefing`,
        };
      }
    }
  }

  if (item.status == ClientProjectStatus.DefinitionsSet) {
    if (item.projectType === 0) {
      return {
        label: I18n.get("Informar PO"),
        redirectUrl: `/cliente/projetos/v2/${item.id}/faturamento`,
      };
    } else {
      return {
        label: I18n.get("Informar PO"),
        redirectUrl: `/projetos/${item.id}/detalhamento-passo-4`,
      };
    }
  }

  if (item.status == ClientProjectStatus.Started) {
    if (item.projectType == 2) {
      return {
        label: I18n.get("Andamento"),
        redirectUrl: `/projetos/${item.id}/resumo`,
      };
    } else {
      return {
        label: I18n.get("Andamento"),
        redirectUrl: `/cliente/projetos/v2/${item.id}/in-progress/review`,
      };
    }
  }

  if (item.status == ClientProjectStatus.RequestComplete) {
    if (item.projectType == 2) {
      return {
        label: I18n.get("Andamento"),
        redirectUrl: `/projetos/${item.id}/resumo`,
      };
    } else {
      return {
        label: I18n.get("Andamento"),
        redirectUrl: `/cliente/projetos/v2/${item.id}/in-progress/review`,
      };
    }
  }

  if (item.status == ClientProjectStatus.Finished) {
    if (item.projectType == 2) {
      return {
        label: I18n.get("Finalizado"),
        redirectUrl: `/projetos/${item.id}/finalizado`,
      };
    } else {
      return {
        label: I18n.get("Finalizado"),
        redirectUrl: `/cliente/projetos/v2/${item.id}/in-progress/finalize`,
      };
    }
  }

  if (item.status == ClientProjectStatus.Canceled) {
    return {
      label: I18n.get("Cancelado"),
      redirectUrl: `/projetos/${item.id}/resumo`,
    };
  }

  return {};
};

export const getActionPartner = (item) => {
  var action = {
    url: "",
    label: "",
  };

  if (item.status == 8) {
    return {
      label: I18n.get("Draft"),
      redirectUrl: `/parceiro/projetos/v2/criar/informacoes/${item.id}`,
    };
  }

  if (item.projectType === 0 && item.status == ClientProjectStatus.PreMatched) {
    return {
      label: I18n.get("Convite Projeto"),
      redirectUrl: `/parceiro/projetos/${item.projectId}/convite`,
    };
  }

  if (
    item.isPartnerSelectionBidding &&
    (item.status == ClientProjectStatus.PreMatched ||
      item.status == ClientProjectStatus.Matched)
  ) {
    return {
      label: I18n.get("Convite Projeto"),
      redirectUrl: `/parceiro/eventos/${item.projectId}/convite-passo-1`,
    };
  }

  if (
    item.status == ClientProjectStatus.PartnerSelected ||
    item.status == ClientProjectStatus.DefinitionsSet
  ) {
    if (item.projectType == 2) {
      return {
        label: I18n.get("Briefing & Cronograma"),
        redirectUrl: `/projetos/${item.id}/detalhamento-passo-2`,
      };
    } else {
      return {
        label: I18n.get("Briefing & Cronograma"),
        redirectUrl: `/parceiro/projetos/v2/${item.id}/briefing`,
      };
    }
  }

  if (item.status == ClientProjectStatus.Started) {
    if (item.projectType == 2) {
      return {
        label: I18n.get("Andamento"),
        redirectUrl: `/projetos/${item.id}/resumo`,
      };
    } else {
      return {
        label: I18n.get("Andamento"),
        redirectUrl: `/parceiro/projetos/v2/${item.id}/in-progress/review`,
      };
    }
  }

  if (item.status == ClientProjectStatus.RequestComplete) {
    if (item.projectType == 2) {
      return {
        label: I18n.get("Andamento"),
        redirectUrl: `/projetos/${item.id}/resumo`,
      };
    } else {
      return {
        label: I18n.get("Andamento"),
        redirectUrl: `/parceiro/projetos/v2/${item.id}/in-progress/review`,
      };
    }
  }

  // if (item.status == ClientProjectStatus.DefinitionsSet) {
  //     return { label: 'Aguardando #PO', po: true };
  // }

  if (item.status == ClientProjectStatus.Canceled) {
    return {
      label: I18n.get("Cancelado"),
      redirectUrl: `/projetos/${item.projectId}/resumo`,
    };
  }

  if (item.status == ClientProjectStatus.Finished) {
    return {
      label: I18n.get("Finalizado"),
      // redirectUrl: `/projetos/${item.id}/resumo`,
      redirectUrl: `/parceiro/projetos/v2/${item.id}/in-progress/finalize`,
    };
  }

  return {};
};
