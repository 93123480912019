import React from "react";
import { I18n } from "aws-amplify";
import { Icon, Empty } from "antd";
import * as S from "./styles";

const TableBP = (props) => {
  return (
    <S.TableStyle
      {...props}
      dataSource={props?.data}
      locale={{
        emptyText: (
          <div style={{ padding: "50px 0" }}>
            <p>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={I18n.get("Sem informação")}
              />
            </p>
          </div>
        ),
      }}
    />
  );
};

export default TableBP;
