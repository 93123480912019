import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';

import MoneyField from '../../form/money-field';
import PercentageField from '../../form/percentage-field';

import { BPCommissionType, BPCommissionTypeNames, ProjectType, ProjectTypeNames, BPCompensationType, BPCompensationTypeNames } from '../../../model';

import { scrollToTop } from '../../../utils/ui';

import { CurrencySymbols } from '../../../model';

class CostForm extends React.Component {
    constructor(props) {

        super(props);

        const { bPitch = {}, bpCommission = {} } = props;

        const { commissionType, perc, flat, compensationType, compensation2ndPerc, compensation2ndFlat, compensation3rdPerc, compensation3rdFlat } = bpCommission;


        this.state = {
            fields: {
                perc: {
                    onChange: this.onChangeForm.bind(this, 'perc'),
                    // onBlur: this.onSave.bind(this),
                    // readOnly: true,
                    successIcon: false,
                    markAsRequired: true
                },
                flat: {
                    onChange: this.onChangeForm.bind(this, 'flat'),
                    // onBlur: this.onSave.bind(this),
                    // readOnly: true,
                    successIcon: false,
                    markAsRequired: true
                },
                compensation2ndPerc: {
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'compensation2ndPerc'),
                    // onBlur: this.onSave.bind(this),
                    visited: true,
                    markAsRequired: true
                },
                compensation2ndFlat: {
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'compensation2ndFlat'),
                    // onBlur: this.onSave.bind(this),
                    visited: true,
                    markAsRequired: true
                },
                compensation3rdPerc: {
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'compensation3rdPerc'),
                    // onBlur: this.onSave.bind(this),
                    visited: true,
                    markAsRequired: true
                },
                compensation3rdFlat: {
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'compensation3rdFlat'),
                    // onBlur: this.onSave.bind(this),
                    visited: true,
                    markAsRequired: true
                },
            },
            errors: {},
            form: { ...bpCommission },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving() );
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.state.form
        };

        return data;
    }

    onSaving(){

        clearTimeout(this.t);
        this.t = setTimeout(() => this.onSave(), 1000)
    }

    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_commission(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    render() {

        const { bPitch = {}, bpCommission = {}, currency = {} } = this.props;

        const { commissionType, compensationType } = bpCommission;

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Pitch Cost")}</h1>

                <p>
                    {I18n.get("A BPool acredita em concorrências mais justas, eficazes, e, parte da sua missão é encontrar o balanço correto entre as necessidades de seus clientes e parceiros.")}
                </p>

                <br/>

                <p>
                    {I18n.get("Além de um funil de concorrência que prevê um peso alto para avaliação de histórico, portfolio, time e perfil, através de Chemistry Meetings, sugerimos abaixo uma remuneração para cobrir uma parte mínima dos custos envolvidos de quem passa para a última fase do processo (Proposta Criativa/Comerical), que serão pagas ao segundo e terceiro lugar.")}
                </p>

                <br/>
                
                <p>
                    {I18n.get("Os valores refletem a recomendação BPool mas podem ser alterados.")}
                </p>


                <div className="bp-event-section" style={{ marginTop: '3rem', maxWidth: '20rem' }}>

                    {commissionType == BPCommissionType.Perc && <>

                        <PercentageField
                            {...this.state.fields.perc}
                            label={I18n.get("BPool Platform Fee")}
                            value={this.state.form.perc}
                            error={this.state.errors.perc} />

                    </>}

                    {commissionType == BPCommissionType.Flat && <>

                        <MoneyField
                            {...this.state.fields.flat}
                            label={I18n.get("BPool Platform Fee")}
                            currency={currency}
                            value={this.state.form.flat}
                            error={this.state.errors.flat} />

                    </>}

                    {compensationType == BPCompensationType.Perc && <>

                        <PercentageField
                            {...this.state.fields.compensation2ndPerc}
                            label={I18n.get("Remuneração Custo 2º Lugar")}
                            value={this.state.form.compensation2ndPerc}
                            error={this.state.errors.compensation2ndPerc} />


                        <PercentageField
                            {...this.state.fields.compensation3rdPerc}
                            label={I18n.get("Remuneração Custo 2º Lugar")}
                            value={this.state.form.compensation3rdPerc}
                            error={this.state.errors.compensation3rdPerc} />

                    </>}

                    {compensationType == BPCompensationType.Flat && <>

                        <MoneyField
                            {...this.state.fields.compensation2ndFlat}
                            label={I18n.get("Remuneração Custo 3º Lugar")}
                            currency={currency}
                            value={this.state.form.compensation2ndFlat}
                            error={this.state.errors.compensation2ndFlat} />

                        <MoneyField
                            {...this.state.fields.compensation3rdFlat}
                            label={I18n.get("Remuneração Custo 3º Lugar")}
                            currency={currency}
                            value={this.state.form.compensation3rdFlat}
                            error={this.state.errors.compensation3rdFlat} />

                    </>}

                </div>
            </div>
        );
    }
}

export default CostForm;
