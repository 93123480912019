import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import LoggedPage from "../page-templates/logged";

import styled from "styled-components";

import LanguageNavBarItem from "../shared/language-navbar-item";

import Menu from "./_menu";

import { TitleBorder } from "../../modules/components/Titles";

import { Row, Col } from "antd";

const RowSpace = styled(Row)`
  margin: 20px 0;
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class IdiomaAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      selectedItem: null,
      edit: false,
      show: false,
    };
  }

  render() {
    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/home">{I18n.get("Admin")}</Link>
            </li>
            <li className="is-active">
              <Link to="/cadastro">{I18n.get("Cadastro")}</Link>
            </li>
          </ul>
        </nav>

        <div className="columns partner-responsible company-admin">
          <div className="column is-3">
            <Menu {...this.props} />
          </div>

          <div className="column is-9">
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Idioma")}</TitleBorder>
              </Col>
            </Row>

            <RowSpace>
              <Col sm={24} className="selectIdioma">
                <LanguageNavBarItem {...this.props} isSelect={true} />
              </Col>
            </RowSpace>
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdiomaAdmin);
