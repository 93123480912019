import { I18n } from "aws-amplify";

export function getMessagesOnQuotationBudget({ auth, item = {} }) {
  const reviseStr = item.revise === true ? ` (${I18n.get("Revisar")})` : "";

  if (auth?.is_client_or_client_user || auth.is_client_user) {
    if (item.isApproved === true)
      return I18n.get("Você aprovou esse orçamento em");

    if (item.isApproved === false)
      return I18n.get("Você declinou esse orçamento");
  } else {
    if (item.isApproved === true)
      return I18n.get("O cliente aprovou esse orçamento em");

    if (item.isApproved === false)
      return I18n.get("O cliente declinou esse orçamento");
  }

  if (auth?.is_client_or_client_user || auth.is_client_user) {
    if (item.isOpen) {
      return `${I18n.get("Em Aberto")} ${reviseStr}`;
    } else {
      return I18n.get("Você pode aprovar ou declinar o orçamento");
    }
  } else {
    if (item.isOpen) {
      return I18n.get("Em Aberto");
    } else {
      return I18n.get(
        "O cliente foi notificado e poderá aprovar ou declinar o orçamento"
      );
    }
  }

  return "--";
}
