import styled from "styled-components";
import { Modal } from "antd";

export const Teste = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px 0;
`;

export const Icone = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px 0;
`;

export const Content = styled.div`
  text-align: center;
  margin-top: 30px;
  p {
    color: #ffffff;
    margin-bottom: 20px;
  }
`;

export const MainModal = styled(Modal)`
  &.ant-modal {
    width: 740px !important;

    .ant-modal-content {
      background: ${(props) => (props.whiteModal ? "#ffffff" : "#000000")};
      color: ${(props) => (props.whiteModal ? "#000000" : "#ffffff")};
    }

    .ant-modal-footer {
      padding: 60px 30px;
      border: none;
    }

    .ant-modal-footer > div {
      display: flex;
      justify-content: center;
    }

    .ant-modal-footer > div > button {
      border-radius: 10px;
      padding: 0 50px;
      height: 50px;
      font-weight: 700;
      font-size: 14px;
      margin: 0 10px;
    }

    .ant-modal-footer > div > button:last-of-type {
      background-color: ${(props) => (props.isDanger ? "#FF0000" : "#1883ff")};
      border-color: ${(props) => (props.isDanger ? "#FF0000" : "#1883ff")};
      width: 300px;
      color: #fff;
      :disabled {
        opacity: 0.3;
      }
    }

    .ant-modal-footer > div > button:first-of-type {
      border: ${(props) =>
        props.whiteModal ? "1px solid #1883ff" : "1px solid #fff"};
      background: ${(props) => (props.whiteModal ? "#ffffff" : "transparent")};
      color: ${(props) => (props.whiteModal ? "#1883ff" : "#fff")};
    }

    .ant-modal-footer > div > button {
      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextModal = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: ${(props) => (props.whiteModal ? "#000000" : "#ffffff")};
`;

export const SubText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => (props.whiteModal ? "#000000" : "#ffffff")};
  margin-top: 20px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }
`;

export const DarkBg = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 999998;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
`;
