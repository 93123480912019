import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { useDebounce } from "use-debounce";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCep } from "../../../../../../hooks/useCep/useCep.hook";
import { Input } from "../../../../../../components/UI/Input";
import { InputMask } from "../../../../../../components/UI/InputMask";
import { SelectOne } from "../../../../../../components/UI/SelectOne";
import { parseQueryString } from "../../../../pages/Initial/Filters/utils/resolvers";
import constants from "../../../../../../../constants";
import COUNTRY_CODES from "../../countryCodes.json";
import { hasError, clearErrorInput } from "../../../../utils/errorsInput";
import * as S from "../../styles";

export const FormEndereco = ({
  inputsForm,
  handleChangeInputForm,
  handleChangeInputsAddressByCep,
  errorsInputs,
  handleSetErrorsInputs,
  isLoadingGet,
  defaultValues,
  inputsDisableds,
  listTimezone,
}) => {
  let location = useLocation();
  const state = useSelector((state) => state);
  const [culture, setCulture] = useState("br");
  useEffect(() => {
    const parameters = parseQueryString(location?.search);
    if (parameters?.culture) {
      setCulture(parameters?.culture);
    } else if (state?.auth?.locale) {
      setCulture(state?.auth?.locale);
    }
  }, [location, state]);
  const { getDataFromCep } = useCep();
  const [cepData, setCepData] = useState({ cep: { zipCode: "", cep: null } });
  const [cepValueDebounced] = useDebounce(cepData?.cep?.cep, 1000);

  const getCepData = async (cep) => {
    try {
      const response = await getDataFromCep(cep.cep);
      if (defaultValues?.zipCode) {
        handleChangeInputsAddressByCep({
          ...inputsForm,
          zipCode: defaultValues?.zipCode,
          city: defaultValues?.city,
          addressLine1: defaultValues?.addressLine1,
          state: defaultValues?.state,
          country: defaultValues?.country,
        });
      } else {
        handleChangeInputsAddressByCep({
          ...inputsForm,
          zipCode: cepData.cep.zipCode,
          city: response?.cepInfos?.city,
          addressLine1: response?.cepInfos?.addressLine1,
          state: response?.cepInfos?.state,
          country: response?.cepInfos?.country,
        });
      }
    } catch (error) {
      handleChangeInputsAddressByCep({
        ...inputsForm,
        zipCode: "",
        city: "",
        addressLine1: "",
        state: "",
        country: "",
      });
    }
  };

  const handleChangeCep = (value) => {
    setCepData({
      ...cepData,
      cep: { zipCode: value?.value, cep: value?.value },
    });
  };

  useEffect(() => {
    if (cepValueDebounced) {
      if (culture === "pt" || culture === "br" || culture === "") {
        getCepData(cepData.cep);
      }
    }
  }, [cepValueDebounced]);

  useEffect(() => {
    if (defaultValues) {
      setCepData({
        ...cepData,
        cep: { zipCode: defaultValues?.zipCode, cep: defaultValues?.zipCode },
      });
    }
  }, [defaultValues]);

  return (
    <>
      <h3 style={{ marginTop: 2 }}>{I18n.get("Endereço")}</h3>
      <Row>
        <Col xs={24} md={6} style={{ paddingLeft: 0 }}>
          {culture === "pt" || culture === "br" || culture === "" ? (
            <InputMask
              id="zipCode"
              label={I18n.get("CEP")}
              defaultValue={cepData?.cep?.zipCode}
              loading={isLoadingGet}
              onChange={(value) => handleChangeCep(value)}
              mask="#####-###"
              required
              error={hasError({
                arr: errorsInputs,
                field: "zipCode",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "zipCode",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
              disabled={inputsDisableds}
            />
          ) : (
            <Input
              placeholder={I18n.get("CEP")}
              label
              required
              value={inputsForm.zipCode}
              loading={isLoadingGet}
              onChange={(value) => handleChangeInputForm("zipCode", value)}
              error={hasError({
                arr: errorsInputs,
                field: "zipCode",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "zipCode",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
              disabled={inputsDisableds}
            />
          )}
        </Col>
        <Col xs={24} md={18} style={{ paddingRight: 0 }}>
          <Input
            placeholder={I18n.get("Endereço (Rua, número e bairro)")}
            label
            required
            value={inputsForm.addressLine1}
            loading={isLoadingGet}
            onChange={(value) => handleChangeInputForm("addressLine1", value)}
            error={hasError({
              arr: errorsInputs,
              field: "addressLine1",
            })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "addressLine1",
                arr: errorsInputs,
              });
              handleSetErrorsInputs(errorsArr);
            }}
            disabled={inputsDisableds}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12} style={{ paddingLeft: 0 }}>
          <Input
            placeholder={I18n.get("Complemento")}
            label
            value={inputsForm.addressLine2}
            loading={isLoadingGet}
            onChange={(value) => handleChangeInputForm("addressLine2", value)}
            disabled={inputsDisableds}
          />
        </Col>
        <Col xs={24} md={12} style={{ paddingRight: 0 }}>
          <Input
            placeholder={I18n.get("Cidade")}
            label
            required
            value={inputsForm.city}
            loading={isLoadingGet}
            onChange={(value) => handleChangeInputForm("city", value)}
            error={hasError({
              arr: errorsInputs,
              field: "city",
            })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "city",
                arr: errorsInputs,
              });
              handleSetErrorsInputs(errorsArr);
            }}
            disabled={inputsDisableds}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12} style={{ paddingLeft: 0 }}>
          <div style={{ height: 80 }}>
            <SelectOne
              options={COUNTRY_CODES}
              value={inputsForm?.country?.code}
              defaultValue={inputsForm?.country?.code}
              isCountry
              loading={isLoadingGet}
              onChange={(value) =>
                handleChangeInputForm("country", value, true)
              }
              required
              placeholder={I18n.get("País")}
              error={hasError({
                arr: errorsInputs,
                field: "countryCode",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "countryCode",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
              disabled={inputsDisableds}
            />
          </div>
        </Col>
        <Col xs={24} md={12} style={{ paddingRight: 0 }}>
          <div style={{ height: 80 }}>
            {culture === "pt" || culture === "br" || culture === "" ? (
              <SelectOne
                options={constants.STATES}
                value={inputsForm?.state}
                defaultValue={inputsForm?.state}
                loading={isLoadingGet}
                onChange={(value) => handleChangeInputForm("state", value)}
                required
                placeholder={I18n.get("Estado")}
                error={hasError({
                  arr: errorsInputs,
                  field: "state",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "state",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
                disabled={inputsDisableds}
              />
            ) : (
              <Input
                placeholder={I18n.get("Estado")}
                label
                required
                value={inputsForm.state}
                loading={isLoadingGet}
                onChange={(value) => handleChangeInputForm("state", value)}
                error={hasError({
                  arr: errorsInputs,
                  field: "state",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "state",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
                disabled={inputsDisableds}
              />
            )}
          </div>
        </Col>
        {listTimezone?.length ? (
          <Col xs={24} md={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div style={{ height: 80 }}>
              <SelectOne
                options={listTimezone}
                value={inputsForm?.timeZone || "America/Sao_Paulo"}
                defaultValue={inputsForm?.timeZone || "America/Sao_Paulo"}
                loading={isLoadingGet}
                onChange={(value) => handleChangeInputForm("timeZone", value)}
                required
                placeholder={I18n.get("Fuso horário")}
                error={hasError({
                  arr: errorsInputs,
                  field: "timeZone",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "timeZone",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
                disabled={inputsDisableds}
              />
            </div>
          </Col>
        ) : null}
      </Row>
    </>
  );
};
