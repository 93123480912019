import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
`;

export const RadiosProjects = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  .ant-radio-wrapper-checked {
    .ant-radio-checked {
      .ant-radio-inner {
        :after {
          top: 4px;
          left: 4px;
        }
      }
    }
  }
`;
