import React from "react";

import moment from "moment";

import styled from "styled-components";
import ProjectContainer from "../../containers/project-partner-container";

import { v4 as uuidv4 } from "uuid";

import { I18n } from "aws-amplify";

import { Redirect } from "react-router-dom";

import FillForm from "../page-templates/fillForm-logged";

import {
  Form,
  Select,
  Input,
  Button,
  Icon,
  notification,
  DatePicker,
  Row,
  Col,
} from "antd";

const { Option } = Select;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const MainForm = styled.div`
  background: #fff;
  margin: 0 auto 15px;
  box-sizing: border-box;
  padding: 20px 40px;
  overflow: hidden;

  .btnnext {
    float: right;
    margin-right: 15px;
  }
`;

const Container = styled.div`
  margin-top: 120px;
  margin-bottom: 50px;
`;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class NewProjectStep1 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      disabledClient: true,
      disabledBrand: true,
      disabledProject: true,
      disabledResponsible: true,
      disabledFields: true,
      products: [],
      responsables: [],
      saved: false,
      getClients: false,
      isMensalFee: false,
      milestones: [],
      projectId: null,
      loading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.project != prevProps.project &&
      this.props.match.params.projectId &&
      this.props.project?.projectId
    ) {
      this.onChangeClient(this.props.project?.clientId);
    }

    if (
      this.props.partner &&
      this.props.partner?.clientBrands != prevProps.partner?.clientBrands &&
      this.props.match.params.projectId
    ) {
      this.onChangeBrand(this.props.project?.brandId);
      setTimeout(() => {
        this.onChangeProduct(this.props.project?.productId);
        this.onChangeResponsable(this.props.project?.createdByUsername);
      }, 1000);
    }

    if (
      this.state.milestones != prevState.milestones &&
      this.state.milestones.length > prevState.milestones.length
    ) {
      notification.success({
        message: "Adicionado com sucesso!",
        description: "Macro entrega foi adicionada",
      });

      this.props.form.resetFields("estimatedDate");
      this.props.form.resetFields("name");
    }

    if (
      this.state.milestones != prevState.milestones &&
      this.state.milestones.length < prevState.milestones.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("Macro entrega foi removida"),
        duration: 3,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (this.props.match.params.projectId) {
        if (!err) {
          this.setState({
            loading: true,
          });
          const data = {
            ...values,
            projectId: this.props.match.params.projectId,
          };

          this.props.update_suggestion(data).then((response) => {
            this.setState({ projectId: response });

            setTimeout(() => {
              this.setState({
                saved: true,
                loading: false,
              });
            }, 300);
          });
        }
      } else {
        if (!err) {
          this.setState({
            loading: true,
          });
          const data = {
            ...values,
          };

          this.props.create_suggestion(data).then((response) => {
            this.setState({ projectId: response });

            setTimeout(() => {
              this.setState({
                saved: true,
                loading: false,
              });
            }, 300);
          });
        }
      }
    });
  };

  onChangeName = (e) => {
    if (e.target.value) {
      this.setState({
        disabledClient: false,
      });
    } else {
      this.setState({
        disabledClient: true,
      });
    }
  };

  onBlurName = () => {
    this.props.get_clients_v2();
    // this.props.get_clients(this.props.partner.partnerId);
  };

  onChangeClient = (value) => {
    this.props.get_brands_client(value);

    if (value) {
      this.setState({
        disabledBrand: false,
      });
    } else {
      this.setState({
        disabledBrand: true,
      });
    }

    this.props.form.resetFields(
      "brandId",
      "productId",
      "clientResponsableUsername",
      "estimatedStartDate",
      "estimatedDurationInMonths",
      "estimatedDurationInWeeks",
      "NonCompete"
    );
  };

  onChangeBrand = (value) => {
    const brandSelect =
      this.props.partner &&
      this.props.partner?.clientBrands &&
      this.props.partner?.clientBrands.filter(
        (brand) => brand.brandId == value
      );

    if (value) {
      this.setState({
        disabledProject: false,
        products: brandSelect && brandSelect[0]?.products,
      });
    } else {
      this.setState({
        disabledProject: true,
        products: [],
      });
    }

    this.props.form.resetFields(
      "productId",
      "clientResponsableUsername",
      "estimatedStartDate",
      "estimatedDurationInMonths",
      "estimatedDurationInWeeks",
      "NonCompete"
    );
  };

  onChangeProduct = (value) => {
    const productsSelect =
      this.state.products &&
      this.state.products.filter((product) => product.productId == value);

    if (value) {
      this.setState({
        disabledResponsible: false,
        responsables: productsSelect && productsSelect[0]?.responsables,
      });
    } else {
      this.setState({
        disabledResponsible: true,
        responsables: [],
      });
    }

    this.props.form.resetFields(
      "clientResponsableUsername",
      "estimatedStartDate",
      "estimatedDurationInMonths",
      "estimatedDurationInWeeks",
      "NonCompete"
    );
  };

  onChangeResponsable = (value) => {
    const productsSelect =
      this.state.products &&
      this.state.products.filter((product) => product.productId == value);

    if (value) {
      this.setState({
        disabledFields: false,
      });
    } else {
      this.setState({
        disabledFields: true,
      });
    }

    this.props.form.resetFields(
      "estimatedStartDate",
      "estimatedDurationInMonths",
      "estimatedDurationInWeeks",
      "NonCompete"
    );
  };

  componentWillMount = () => {
    this.setState({
      saved: false,
      getClients: true,
    });
  };

  componentDidMount = () => {
    if (this.props.match.params.projectId) {
      var data = {
        clientId: this.props.client.clientId,
        projectId: this.props.match.params.projectId,
      };

      this.props.get_project(data);
      this.props.get_clients_v2();
    }
  };

  getWeekDurationValues() {
    var values = [];

    values.push({ code: 0, label: I18n.get("0 semana") });
    values.push({ code: 1, label: I18n.get("1 semana") });
    values.push({ code: 2, label: I18n.get("2 semanas") });
    values.push({ code: 3, label: I18n.get("3 semanas") });
    values.push({ code: 4, label: I18n.get("4 semanas") });
    values.push({ code: 5, label: I18n.get("5 semanas") });
    values.push({ code: 6, label: I18n.get("6 semanas") });
    values.push({ code: 7, label: I18n.get("7 semanas") });
    values.push({ code: 8, label: I18n.get("8 semanas") });

    return values;
  }

  onChangeFee = (e) => {
    this.setState({
      isMensalFee: e.target.checked,
    });
  };

  addMacro = () => {
    let macro = {
      id: uuidv4(),
      name: this.props.form.getFieldValue("name"),
      estimatedDate: moment(
        this.props.form.getFieldValue("estimatedDate")
      ).format("DD/MM/YYYY"),
    };

    this.setState({
      milestones: [...this.state.milestones, macro],
    });
  };

  deleteMacro = (item) => {
    this.setState((state) => {
      const index = state.milestones.indexOf(item);
      const newMilestones = state.milestones.slice();
      newMilestones.splice(index, 1);
      return {
        milestones: newMilestones,
      };
    });
  };

  render() {
    // if (!this.props.partner.partnerId) return null;

    const { getFieldDecorator, setFieldValue, getFieldsError } =
      this.props.form;

    const logo =
      this.props.client &&
      this.props.client.company &&
      this.props.client.company.logo;

    let applyDiscount =
      this.props.client &&
      this.props.client.customization &&
      this.props.client.customization.applyDiscount;

    const MonthDurationValues = [
      { code: 0, label: I18n.get("0 mês") },
      { code: 1, label: I18n.get("1 mês") },
      { code: 2, label: I18n.get("2 meses") },
      { code: 3, label: I18n.get("3 meses") },
      { code: 4, label: I18n.get("4meses") },
      { code: 5, label: I18n.get("5meses") },
      { code: 6, label: I18n.get("6 meses") },
      { code: 7, label: I18n.get("7 meses") },
      { code: 8, label: I18n.get("8 meses") },
      { code: 9, label: I18n.get("9 meses") },
      { code: 10, label: I18n.get("10 meses") },
      { code: 11, label: I18n.get("11 meses") },
      { code: 12, label: I18n.get("12 meses") },
    ];

    const WeekDurationValues = [
      //{ code: 0, label: I18n.get("0 semana") },
      { code: 1, label: I18n.get("1 semana") },
      { code: 2, label: I18n.get("2 semanas") },
      { code: 3, label: I18n.get("3 semanas") },
      { code: 4, label: I18n.get("4 semanas") },
      { code: 5, label: I18n.get("5 semanas") },
      { code: 6, label: I18n.get("6 semanas") },
      { code: 7, label: I18n.get("7 semanas") },
      { code: 8, label: I18n.get("8 semanas") },
    ];

    const nonCompeteValues = [
      { code: -1, label: I18n.get("Nenhum") },
      { code: 0, label: I18n.get("Durante o projeto") },
      { code: 30, label: I18n.get("Durante o projeto + 30 dias") },
      { code: 60, label: I18n.get("Durante o projeto + 60 dias") },
      { code: 90, label: I18n.get("Durante o projeto + 90 dias") },
    ];

    let dataSource = this.state.milestones.map((item, index) => {
      return {
        key: index,
        name: item.name,
        date: item.estimatedDate,
        item: item,
      };
    });

    const columns = [
      {
        title: I18n.get("Nome"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: I18n.get("Data"),
        dataIndex: "date",
        key: "date",
      },
      {
        title: I18n.get("Arquivos"),
        dataIndex: "files",
        key: "files",
      },
      {
        title: I18n.get("Entrega do parceiro"),
        dataIndex: "deliveryPartner",
        key: "deliveryPartner",
      },
      {
        title: I18n.get("Aprovação do cliente"),
        dataIndex: "approval",
        key: "approval",
      },
      {
        title: "",
        dataIndex: "item",
        key: "item",
        render: (text, record) => {
          return (
            <p>
              <a onClick={() => this.deleteMacro(record.item)}>
                <Icon type="delete" theme="filled" />
              </a>
            </p>
          );
        },
      },
    ];

    let limitMacro =
      this.state.milestones && this.state.milestones.length >= 5 ? true : false;
    let disabledBtn =
      !this.state.isMensalFee &&
      this.state.milestones &&
      this.state.milestones.length < 2
        ? true
        : false;

    if (this.state.saved && this.state.projectId) {
      // return <Redirect to="/parceiro/criar-projeto/assets" />;
      return (
        <Redirect
          to={`/parceiro/criar-projeto/briefing/${this.state.projectId}`}
        />
      );
    }

    if (
      this.props.match.params.projectId &&
      this.props.project &&
      this.props.project.projectId
    ) {
      if (this.props.partner && !this.props.partner.partnerId) return null;
    }

    let dataStart = this.props.project?.estimatedStartDate;

    let dateMomentObject = moment(dataStart, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    let dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object

    return (
      <FillForm>
        <div className="client-step client-step-1 mob-container">
          <div className="container client-container-1 client-container-build-project uplift-step-2">
            <Container>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <MainForm>
                  <Row>
                    <Col sm={16} data-label="name">
                      <Form.Item label={I18n.get("Nome do projeto")}>
                        {getFieldDecorator("projectName", {
                          rules: [
                            {
                              required: true,
                              message: I18n.get("Campo obrigatório"),
                            },
                          ],
                          initialValue: this.props.project?.projectName || "",
                        })(
                          <Input
                            placeholder={I18n.get("Nome")}
                            onChange={this.onChangeName}
                            onBlur={this.onBlurName}
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col sm={8} data-label="client">
                      <Form.Item label={I18n.get("Cliente")}>
                        {getFieldDecorator("clientId", {
                          rules: [
                            {
                              required: true,
                              message: I18n.get("Campo obrigatório"),
                            },
                          ],
                          initialValue: this.props.project?.clientId || "",
                        })(
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder={I18n.get("Selecione o cliente")}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.onChangeClient(e)}
                            disabled={this.state.disabledClient}
                          >
                            {this.props?.partner?.projectClients &&
                              this.props.partner.projectClients.length > 0 &&
                              this.props.partner.projectClients.map(
                                (item, index) => {
                                  return (
                                    <Option value={item.clientId}>
                                      {item.clientCompanyName}
                                    </Option>
                                  );
                                }
                              )}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>

                    <Col sm={8} data-label="brand">
                      <Form.Item label={I18n.get("Marca")}>
                        {getFieldDecorator("brandId", {
                          rules: [
                            {
                              required: true,
                              message: I18n.get("Campo obrigatório"),
                            },
                          ],
                          initialValue: this.props.project?.brandId || "",
                        })(
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder={I18n.get("Selecione o produto")}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.onChangeBrand(e)}
                            disabled={this.state.disabledBrand}
                          >
                            {this.props.partner.clientBrands &&
                              this.props.partner.clientBrands.map(
                                (item, index) => {
                                  return (
                                    <Option value={item.brandId}>
                                      {item.name}
                                    </Option>
                                  );
                                }
                              )}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>

                    <Col sm={8} data-label="product">
                      <Form.Item label={I18n.get("Produto")}>
                        {getFieldDecorator("productId", {
                          rules: [
                            {
                              required: true,
                              message: I18n.get("Campo obrigatório"),
                            },
                          ],
                          initialValue: this.props.project?.productId || "",
                        })(
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder={I18n.get("Selecione o produto")}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.onChangeProduct(e)}
                            disabled={this.state.disabledProject}
                          >
                            {this.state.products &&
                              this.state.products.map((item, index) => {
                                return (
                                  <Option value={item.productId}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>

                    <Col sm={8} data-label="responsableUsername">
                      <Form.Item label={I18n.get("Responsável")}>
                        {getFieldDecorator("clientResponsableUsername", {
                          rules: [
                            {
                              required: true,
                              message: I18n.get("Campo obrigatório"),
                            },
                          ],
                          initialValue:
                            (this.props.project &&
                              this.props.project?.createdByUsername) ||
                            "",
                        })(
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder={I18n.get("Selecione o responsável m")}
                            optionFilterProp="children"
                            onChange={(e) => this.onChangeResponsable(e)}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.disabledResponsible}
                          >
                            {this.state.responsables &&
                              this.state.responsables.map((item, index) => {
                                return (
                                  <Option value={item.username}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </MainForm>
                <MainForm>
                  {/* <Row>
                                    <Col sm={24}>
                                        <label><b>{I18n.get("Duração:")}</b></label>
                                    </Col>
                                </Row> */}
                  <Row>
                    <Col sm={6} data-label="estimatedStartDate">
                      <Form.Item label={I18n.get("Ínicio")}>
                        {getFieldDecorator("estimatedStartDate", {
                          rules: [
                            {
                              required: true,
                              message: I18n.get("Campo obrigatório"),
                            },
                          ],
                          initialValue:
                            this.props.project?.estimatedStartDate &&
                            moment(dateObject, dateFormatList),
                        })(
                          <DatePicker
                            format={dateFormatList}
                            disabled={this.state.disabledFields}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col sm={6} data-label="estimatedDurationInMonths">
                      <Form.Item label={I18n.get("Meses")}>
                        {getFieldDecorator("estimatedDurationInMonths", {
                          rules: [
                            {
                              required: true,
                              message: I18n.get("Campo obrigatório"),
                            },
                          ],
                          initialValue:
                            this.props.project?.estimatedDurationInMonths || "",
                        })(
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder={I18n.get("Meses")}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.disabledFields}
                          >
                            {MonthDurationValues &&
                              MonthDurationValues.map((item, index) => {
                                return (
                                  <Option value={item.code}>
                                    {item.label}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col sm={6} data-label="estimatedDurationInWeeks">
                      <Form.Item label={I18n.get("Semanas")}>
                        {getFieldDecorator("estimatedDurationInWeeks", {
                          rules: [
                            {
                              required: true,
                              message: I18n.get("Campo obrigatório"),
                            },
                          ],
                          initialValue:
                            this.props.project?.estimatedDurationInWeeks || "",
                        })(
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder={I18n.get("Semanas")}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.disabledFields}
                          >
                            {WeekDurationValues &&
                              WeekDurationValues.map((item, index) => {
                                return (
                                  <Option value={item.code}>
                                    {item.label}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>

                    <Col sm={6} data-label="NonCompete">
                      <Form.Item label={I18n.get("NonCompete")}>
                        {getFieldDecorator("nonCompeteInDays", {
                          rules: [
                            {
                              required: true,
                              message: I18n.get("Campo obrigatório"),
                            },
                          ],
                          initialValue:
                            this.props.project?.nonCompeteInDays || "",
                        })(
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder={I18n.get("Selecione o responsável")}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.disabledFields}
                          >
                            {nonCompeteValues &&
                              nonCompeteValues.map((item, index) => {
                                return (
                                  <Option key={index} value={item.code}>
                                    {item.label}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </MainForm>

                <MainForm>
                  <Button
                    className="button bt-bpool pull-right black"
                    loading={this.state.loading}
                    type="primary"
                    htmlType="submit"
                  >
                    {I18n.get("Avançar")}
                  </Button>
                </MainForm>
              </Form>
            </Container>
          </div>
        </div>
      </FillForm>
    );
  }
}

const NewProjectStep1Form = Form.create({})(NewProjectStep1);

export default ProjectContainer(NewProjectStep1Form);
