import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";
import { statusFmtBpay, fileToBase64 } from "./utils";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useBpay = () => {
  const getBpays = async (query) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/client/bpay${query}`
        : `${Config.API_BASE_URL}/v2/client/bpay`,
      config
    );

    const dataFormatted = data?.data?.bPays?.map((item) => ({
      ...item,
      statusFmt: statusFmtBpay(item?.status),
    }));

    return {
      list: dataFormatted,
      total: data?.data?.totalRecords,
    };
  };

  const getListBpays = async (query) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/client/bpay${query}`
        : `${Config.API_BASE_URL}/v2/client/bpay`,
      config
    );

    const dataFormatted = data?.data?.bPays?.map((item) => ({
      ...item,
      statusFmt: statusFmtBpay(item?.status),
    }));

    return {
      ...data,
      data: dataFormatted,
      pagination: { total: data?.data?.totalRecords },
    };
  };

  const exportBPays = async ({ query }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/client/bpay/export-bpays-with-suppliers${query}`
        : `${Config.API_BASE_URL}/v2/client/bpay/export-bpays-with-suppliers`,
      config
    );

    return data;
  };

  const getBpayById = async ({ id, query }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/client/bpay/${id}${query}`
        : `${Config.API_BASE_URL}/v2/client/bpay/${id}`,
      config
    );

    const dataFormatted = {
      ...data?.data,
      statusFmt: statusFmtBpay(data?.data?.status),
    };

    return { ...data, data: dataFormatted };
  };

  const getTotals = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client/bpay/get-totals`,
      config
    );

    return { ...data.data };
  };

  const getBpaymentSetupInProgress = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client/bpay/bpayment/setup`,
      config
    );

    return data;
  };

  const getSetupBpay = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client/bpay/setup`,
      config
    );

    return data;
  };

  const calculateTotalValue = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/client/bpay/calculate`,
      infos,
      config
    );

    return data;
  };

  const step1BpayDraft = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    if (infos?.id) {
      const { data } = await axios.put(
        `${Config.API_BASE_URL}/v2/client/bpay/step1-bpay-draft`,
        infos,
        config
      );

      return data;
    } else {
      const { data } = await axios.post(
        `${Config.API_BASE_URL}/v2/client/bpay/step1-bpay-draft`,
        infos,
        config
      );

      return data;
    }
  };

  const step1BpayGenerateBudget = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    if (infos?.id) {
      const { data } = await axios.put(
        `${Config.API_BASE_URL}/v2/client/bpay/step1-bpay-generate-budget`,
        infos,
        config
      );

      return data;
    } else {
      const { data } = await axios.post(
        `${Config.API_BASE_URL}/v2/client/bpay/step1-bpay-generate-budget`,
        infos,
        config
      );

      return data;
    }
  };

  const step2BpayGenerateBudget = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client/bpay/step2-bpay-generate-budget`,
      infos,
      config
    );

    return data;
  };

  const deleteBpay = async (id) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/client/bpay`,
      {
        data: { id },
        ...config,
      }
    );

    return data;
  };

  const sendSupplier = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    if (infos?.id) {
      const { data } = await axios.put(
        `${Config.API_BASE_URL}/v2/client/bpay/bpayment`,
        infos,
        config
      );

      return data;
    } else {
      const { data } = await axios.post(
        `${Config.API_BASE_URL}/v2/client/bpay/bpayment`,
        infos,
        config
      );

      return data;
    }
  };

  const deleteSuppliers = async ({ bpayId, id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/client/bpay/${bpayId}/bpayment/${id}`,
      config
    );

    return data;
  };

  const importSuppliers = async ({ spreadsheet, id, forceSave }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const base64File = await fileToBase64(spreadsheet);

    const body = {
      base64: base64File,
    };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client/bpay/bpayment/import-preview-bpayments-xlsx/${id}/force-ignore-duplications/${forceSave}`,
      body,
      config
    );

    return data;
  };

  const bulkCreateSuppliers = async ({ infos, forceIgnoreDuplications }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/client/bpay/bpayment/bulk/force-ignore-duplications/${forceIgnoreDuplications}`,
      infos,
      config
    );

    return data;
  };

  const downloadXlsxSample = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client/bpay/supplier/download-xlsx-sample-to-import-bpay-suppliers`,
      config
    );

    return data;
  };

  const getSetupPoBpay = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client/billing-company/setup`,
      config
    );

    return data;
  };

  const getPoByBPayId = async (bpayId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client/bpay/po/${bpayId}`,
      config
    );

    return data;
  };

  const getPoByBPayIdAndSetup = async (bpayId) => {
    try {
      const responsePoById = await getPoByBPayId(bpayId);
      const responsePoSetup = await getSetupPoBpay();
      const data = {
        data: responsePoById?.data,
        billingData: responsePoSetup?.data,
        errors: null,
      };

      return data;
    } catch (error) {
      const data = {
        data: null,
        errors: "Erro.",
      };

      return data;
    }
  };

  const postNewRegistrationId = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/client/billing-company`,
      infos,
      config
    );

    return data;
  };

  const postPo = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/client/bpay/po`,
      infos,
      config
    );

    return data;
  };

  const bpayDetailsInProgress = async (bpayId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client/bpay/${bpayId}/in-progress-details`,
      config
    );

    return data;
  };

  const bpayDetailsInProgressList = async ({ bpayId, query }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/client/bpay/${bpayId}/bpayments${query}`
        : `${Config.API_BASE_URL}/v2/client/bpay/${bpayId}/bpayments`,
      config
    );

    return data;
  };

  const getBPaymentDataStatus = async ({ bPayId, bPaymentId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client/bpay/get-financial-status/${bPayId}/bpayment/${bPaymentId}`,
      config
    );

    return data;
  };

  return {
    getBpays,
    getListBpays,
    exportBPays,
    getBpayById,
    getTotals,
    getSetupBpay,
    getBpaymentSetupInProgress,
    getSetupPoBpay,
    calculateTotalValue,
    step1BpayDraft,
    step1BpayGenerateBudget,
    step2BpayGenerateBudget,
    deleteBpay,
    sendSupplier,
    deleteSuppliers,
    importSuppliers,
    bulkCreateSuppliers,
    downloadXlsxSample,
    getPoByBPayId,
    getPoByBPayIdAndSetup,
    postNewRegistrationId,
    postPo,
    bpayDetailsInProgress,
    getBPaymentDataStatus,
    bpayDetailsInProgressList,
  };
};
