import axios from 'axios';

import Config from '../../config';

import { PARTNER_CREATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_create_partner = (data) => {
    return (dispatch) => {

        return axios.post(`${Config.API_BASE_URL}/partner`, data)
            .then(response => {

                dispatch({
                    type: PARTNER_CREATE,
                    payload: {
                        email_sent: true,
                        errors: {},
                        task: { result: true }
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: PARTNER_CREATE,
                    payload: {
                        errors: error.response.data,
                        task: { result: false }
                    }
                })
            });
    };
}

export default api_create_partner;