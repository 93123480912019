import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import moment from "moment";
import { Row, Col } from "antd";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useMeetings } from "../../../hooks/useMeetings/useMeetings.hook";
import "../../../pages/ProjectsList/Meetings/css-day-picker.css";
import { ModalCta } from "../ModalCta/ModalCta";
import { ModalConfirm } from "../ModalConfirm/ModalConfirm";
import { ButtonBpool } from "../ButtonBpool";
import { ModalBp } from "../ModalBp/ModalBp";
import Loading from "../Loading";
import { Button } from "../Button";

import * as S from "./stylesJS";
import icoRemove from "../../../pages/ProjectsList/Meetings/svg/icon-remove.png";
import constants from "../../../../constants";
import { hoursMock } from "../../../pages/ProjectsList/Meetings/screens/mocks/hoursMock";

export const RenderScreen = ({
  projectId,
  selected,
  isLoadingProjects,
  selectedProject,
  meetingType,
  typeLabelMeetingSelect,
}) => {
  const {
    getMeetingsByProjectId,
    postMeeting,
    deleteMeeting,
    getAvailablePartners,
    toInviteMeeting,
  } = useMeetings();

  const [selectedDay, setSelectedDay] = useState(null);
  const [hours, setHours] = useState(hoursMock);
  const [dateAndHourSelected, setDateAndHourSelected] = useState({
    projectId: selectedProject,
    startTime: null,
    endTime: null,
    meetingDate: null,
    meetingType: null,
  });
  const [availablePartners, setAvailablePartners] = useState([]);
  const [loaderAvailablePartners, setLoaderAvailablePartners] = useState(false);
  const [datesMeeting, setDatesMeeting] = useState([]);
  const [loaderDates, setLoaderDates] = useState(false);
  const [modalSelectedMeeting, setModalSelectedMeeting] = useState("");
  const [openModalCta, setOpenModalCta] = useState(false);
  const [isLoadingDeleteMeetingDate, setIsLoadingDeleteMeetingDate] =
    useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [loaderSaveMeetingDate, setLoaderSaveMeetingDate] = useState(false);
  const [loaderToInvite, setLoaderToInvite] = useState(false);
  const [inviteSended, setInviteSended] = useState(false);
  const [errorInHour, setErrorInHour] = useState("");
  const [errorSend, setErrorSend] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const handleModalOpenModalCta = (meetingId) => {
    if (openModalCta) {
      setOpenModalCta(false);
      setModalSelectedMeeting("");
    } else {
      setOpenModalCta(true);
      setModalSelectedMeeting(meetingId);
    }
  };

  const renderDate = (dateRender, index) => {
    return (
      <div
        key={index}
        style={{
          marginTop: 15,
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div>
            <span style={{ paddingRight: 10, fontWeight: 600 }}>
              {I18n.get("Data")}:
            </span>
            {dateRender.date}
          </div>
          <div>
            <span style={{ paddingRight: 10, fontWeight: 600 }}>
              {I18n.get("Horário")}:
            </span>
            {dateRender.hour}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            display: "flex",
            justifyContent: "flex-end",
            width: 50,
          }}
        >
          <button
            type="button"
            style={{
              padding: 0,
              margin: 0,
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => handleModalOpenModalCta(dateRender.meetingId)}
          >
            <img src={icoRemove} alt="Remove" />
          </button>
        </div>
      </div>
    );
  };

  const getMeetingsDates = async () => {
    try {
      setLoaderDates(true);
      const response = await getMeetingsByProjectId(selectedProject);

      const dates = [];
      response.data.map((item) =>
        dates.push({
          meetingId: item.meetingId,
          date: item.meetingDate,
          hour: `${item.startTime} - ${item.endTime}`,
        })
      );

      setDatesMeeting(dates);
    } catch (error) {
      console.log("getMeetingsDates error", error);
    } finally {
      setLoaderDates(false);
    }
  };

  const saveMeetingDate = async () => {
    if (!dateAndHourSelected.startTime) {
      setErrorInHour(I18n.get("Select a time to save the date."));
    } else {
      try {
        setLoaderSaveMeetingDate(true);
        await postMeeting(dateAndHourSelected);

        setSelectedDay(null);
        setDateAndHourSelected({
          projectId: selectedProject,
          startTime: null,
          endTime: null,
          meetingDate: new Date(),
          meetingType: null,
        });
        setHours(hoursMock);
        await getMeetingsDates();
      } catch (error) {
        console.log("saveMeetingDate error", error);
      } finally {
        setLoaderSaveMeetingDate(false);
      }
    }
  };

  const daleteMeetingDate = async () => {
    try {
      setIsLoadingDeleteMeetingDate(true);
      await deleteMeeting(modalSelectedMeeting);

      await getMeetingsDates();
      setOpenModalCta(false);
      setInviteSended(false);
      setOpenModalConfirm(true);
    } catch (error) {
      console.log("saveMeetingDate error", error);
    } finally {
      setIsLoadingDeleteMeetingDate(false);
    }
  };

  const handleClickOk = async () => {
    const getPartnersToSend = availablePartners.map((partner) => {
      if (partner.active === true) {
        return {
          partnerId: partner.id,
          partnerName: partner.name,
          partnerEmail: partner.email,
          partnerCompany: partner.companyName,
        };
      }
    });

    const availablePartnersSelecteds = getPartnersToSend.filter((e) => e);

    try {
      setLoaderToInvite(true);

      await toInviteMeeting(selectedProject, availablePartnersSelecteds);
      const arrInitial = [];
      availablePartners.map((partner) =>
        arrInitial.push({ ...partner, active: false })
      );
      setAvailablePartners(arrInitial);
      setInviteSended(true);
      setOpenModalConfirm(true);
    } catch (error) {
      setErrorSend(true);
    } finally {
      setLoaderToInvite(false);
    }
  };

  const handleDayClick = (day, { selected }) => {
    setSelectedDay(selected ? undefined : day);

    setDateAndHourSelected({
      ...dateAndHourSelected,
      meetingDate: selected ? undefined : day,
      projectId: selectedProject,
    });
  };

  const meetingTypeValue = (type) => {
    if (type === 4) {
      return 1;
    }
    if (type === 5) {
      return 2;
    }
    if (type === 6) {
      return 3;
    }
  };

  const handleHourChange = (hour) => {
    setErrorInHour("");
    const event = new Date("August 19, 1975 00:00:00");
    const hourSplitted = hour.display.split("h");
    event.setHours(hourSplitted[0], hourSplitted[1], 59);

    const formatDateMoment = moment(event);
    const justHour = formatDateMoment.format("HH:mm");
    const hourFormatSplit = justHour.split(":");
    const endHour = Number(hourFormatSplit[0]) + 1;
    const endHourFormatted = `${endHour}:00`;

    setDateAndHourSelected({
      ...dateAndHourSelected,
      startTime: justHour,
      endTime: endHourFormatted,
      projectId: selectedProject,
      meetingType: meetingTypeValue(meetingType),
    });

    const updateHour = {
      display: hour.display,
      active: true,
      blocked: true,
    };

    const hoursChangeds = hours.map((h) =>
      h.display !== updateHour.display
        ? { display: h.display, active: false, blocked: false }
        : updateHour
    );

    setHours(hoursChangeds);
  };

  const renderHours = () => {
    return hours.map((item, index) => (
      <S.SelectClickButton
        key={index}
        active={item.active}
        onClick={() => handleHourChange(item)}
      >
        {item.display}
      </S.SelectClickButton>
    ));
  };

  const verifyIfPartnerSelected = (array) => {
    return array.some((obj) => obj.active === true);
  };

  const loadAvailablePartners = async () => {
    try {
      setLoaderAvailablePartners(true);
      const response = await getAvailablePartners(selectedProject);

      if (response.success) {
        const partners = [];
        response.data.map((item, index) =>
          partners.push({ ...item, active: false, sequence: index + 1 })
        );
        partners.sort((a, b) => a.sequence - b.sequence);
        setAvailablePartners(partners);
      }
    } catch (error) {
      setAvailablePartners([]);
      console.log("loadAvailablePartners error", error);
    } finally {
      setLoaderAvailablePartners(false);
    }
  };

  const handleSelectPartner = async (partner) => {
    const finded = availablePartners.find((item) => item.id === partner.id);

    const updatePartner = {
      ...partner,
      active: !finded.active,
    };

    const filteredItems = availablePartners.filter(
      (item) => item.id !== partner.id
    );
    const arrItems = [...filteredItems, updatePartner];
    arrItems.sort((a, b) => a.sequence - b.sequence);

    setAvailablePartners(arrItems);
  };

  useEffect(() => {
    if (availablePartners?.length) {
      const hasSelected = verifyIfPartnerSelected(availablePartners);
      setDisabledSubmit(!hasSelected ? true : false);
    }
  }, [availablePartners]);

  const clickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    setTimeout(() => {
      setInviteSended(false);
    }, 2000);
  };

  useEffect(() => {
    if (selectedProject) {
      getMeetingsDates();
      loadAvailablePartners();
    }
  }, [selected, selectedProject]);

  useEffect(() => {
    const dateNow = new Date();
    handleDayClick(dateNow, {});
  }, []);

  return (
    <>
      <Row>
        <Col span={8}>
          <S.Box>
            {selectedProject ? (
              <>
                <S.ContentDatePicker>
                  <DayPicker
                    selectedDays={selectedDay}
                    onDayClick={handleDayClick}
                    months={constants.DAYPICKER_MONTHS}
                    weekdaysLong={constants.WEEKDAYS_mONLONG}
                    weekdaysShort={constants.WEEKDAYS_SHORT}
                    disabledDays={{
                      before: new Date(),
                    }}
                  />
                </S.ContentDatePicker>

                <S.ContentSelectHours>{renderHours()}</S.ContentSelectHours>

                <div style={{ marginTop: 20 }}>
                  <ButtonBpool
                    text={I18n.get("Salvar Data")}
                    theme="primary"
                    loading={loaderSaveMeetingDate}
                    onClick={() => saveMeetingDate()}
                    full
                  />
                </div>

                {errorInHour ? <S.ErrorSend>{errorInHour}</S.ErrorSend> : null}
              </>
            ) : (
              <div>
                <S.Title>{I18n.get("Data e hora")}</S.Title>
                <div>{I18n.get("Selecione um projeto.")}</div>
              </div>
            )}
          </S.Box>
        </Col>

        <Col span={8}>
          <S.Box data-label="quickmeeting-dates-available">
            <S.Title>{I18n.get("Datas Disponibilizadas")}</S.Title>
            {loaderDates ? (
              <Loading
                text={I18n.get("Carregando datas..")}
                sizeText={14}
                sizeIcon={16}
                align="start"
              />
            ) : (
              <div
                style={{
                  marginTop: 15,
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                {datesMeeting.length ? (
                  datesMeeting.map((item, index) => renderDate(item, index))
                ) : selectedProject ? (
                  <div>{I18n.get("Sem datas disponíveis")}</div>
                ) : (
                  <div>{I18n.get("Selecione um projeto.")}</div>
                )}
              </div>
            )}
          </S.Box>
        </Col>

        <Col span={8}>
          <S.Box data-label="quickmeeting-time-available-for">
            <S.Title>{I18n.get("Disponibilizar horários para:")}</S.Title>
            <div style={{ marginTop: 15 }}>
              {loaderAvailablePartners ? (
                <Loading
                  text={I18n.get("Carregando parceiros...")}
                  sizeText={14}
                  sizeIcon={16}
                  align="start"
                />
              ) : availablePartners.length ? (
                availablePartners.map((item) => (
                  <S.SelectClickButton
                    key={item.id}
                    active={item.active}
                    onClick={() => handleSelectPartner(item)}
                  >
                    {item.companyName}
                  </S.SelectClickButton>
                ))
              ) : selectedProject ? (
                <div>{I18n.get("Não tem parceiros nesse projeto")}</div>
              ) : (
                <div>{I18n.get("Selecione um projeto.")}</div>
              )}
            </div>
          </S.Box>
        </Col>
      </Row>

      {selectedProject ? (
        !isLoadingProjects ? (
          <Row>
            <Col offset={16} span={8}>
              <div style={{ marginTop: "-55px" }}>
                <ButtonBpool
                  text={I18n.get("Convidar")}
                  theme="primary"
                  loading={loaderToInvite}
                  onClick={() => handleClickOk()}
                  full
                  disabled={disabledSubmit}
                />
              </div>
            </Col>
          </Row>
        ) : null
      ) : null}

      <ModalCta
        visible={openModalCta}
        bodyText={I18n.get("Apagar agendamento selecionado?")}
        cancelText={I18n.get("Voltar")}
        okText={I18n.get("Apagar")}
        handleClickOk={daleteMeetingDate}
        handleClickCancel={handleModalOpenModalCta}
        isLoading={isLoadingDeleteMeetingDate}
      />

      <ModalConfirm
        visible={openModalConfirm}
        bodyText={
          inviteSended
            ? I18n.get(
                `Convite para ${typeLabelMeetingSelect} Meeting enviado!`
              )
            : I18n.get("Agendamento apagado!")
        }
        subBodyText={
          inviteSended
            ? I18n.get(
                "Enquanto encaminhamos o convite para o projeto você pode ajudar avaliando os parceiros indicados!"
              )
            : null
        }
        okText={I18n.get("OK!")}
        handleClickOk={() => clickOkModalConfirm()}
      />

      <ModalBp
        visible={errorSend}
        bodyText={I18n.get("Erro ao enviar convite!")}
        subBodyText={I18n.get(
          "Houve um erro ao enviar o convite, favor verificar data e parceiro."
        )}
        okText={I18n.get("Fechar")}
        handleClickOk={() => setErrorSend(false)}
        onCancel={() => setErrorSend(false)}
        isAlert={true}
        isDanger={true}
      />
    </>
  );
};
