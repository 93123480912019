import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import CheckboxMultiField from "../form/checkbox-multi-field";
import RadioMultiField from "../form/radio-multi-field";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

import { CustomEventBriefingType } from "../../model";
import { Input } from "../../v2/components/UI/Input";

class StaffForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        staffType: {
          label: I18n.get("Você vai precisar de um time de apoio? Quais:"),
          value: [],
          onChange: this.onChangeFormCheckboxMulti.bind(this, "staffType"),
          onBlur: this.onSave.bind(this),
        },
        staffDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "staffDesc"),
          onBlur: this.onSave.bind(this),
          successIcon: false,
        },
        costumeDesign: {
          label: I18n.get("Voce vai precisar de figurino?"),
          onChange: this.onChangeForm.bind(this, "costumeDesign"),
          onBlur: this.onSave.bind(this),
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};

    const keys = {
      [CustomEventBriefingType.Event]: "EVENT_STAFF_TYPES",
      [CustomEventBriefingType.Shopper]: "EVENT_STAFF_SHOPPER_TYPES",
      [CustomEventBriefingType.Incentive]: "EVENT_STAFF_INCENTIVE_TYPES",
    };

    const key = keys[customEvent.briefingType];

    const staffDescIsEnabled =
      Array.isArray(this.state.form.staffType) &&
      this.state.form.staffType.indexOf("OTHER") > -1;

    const staffTypeValues =
      constants[key] &&
      constants[key].map((item) => {
        if (item.code == "OTHER") {
          item.field = (
            // <TextField
            //   {...this.state.fields.staffDesc}
            //   value={this.state.form.staffDesc}
            //   error={this.state.errors.staffDesc}
            //   disabled={!staffDescIsEnabled}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.staffDesc.onChange(value)
              }
              placeholder={this.state.fields.staffDesc.label}
              value={this.state.form.staffDesc}
              disabled={!staffDescIsEnabled}
              error={this.state.errors.staffDesc?.errorMessage}
            />
          );
        }

        return item;
      });

    const costumeDesignValues = constants.EVENT_COSTUME_DESIGN_TYPES.map(
      (item) => {
        item.label = I18n.get(item.label);

        return item;
      }
    );

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Equipe")}</h1>

        <div className="bp-event-section bpForce mini">
          <CheckboxMultiField
            {...this.state.fields.staffType}
            value={this.state.form.staffType}
            error={this.state.errors.staffType}
            values={staffTypeValues}
          />

          <br style={{ clear: "both" }} />
          <br />

          {customEvent.isEvent && (
            <p style={{ fontSize: "80%" }}>
              {I18n.get(
                "* De acordo com a legislação de São Paulo. Verifique regulamentação da sua região."
              )}
            </p>
          )}

          {customEvent.isShopper && (
            <>
              <RadioMultiField
                {...this.state.fields.costumeDesign}
                value={this.state.form.costumeDesign}
                error={this.state.errors.costumeDesign}
                values={costumeDesignValues}
              />

              <br style={{ clear: "both" }} />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default StaffForm;
