import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Icon } from 'antd';

import { PARTNER_API } from '../../../actions/partner/actions';

import { Task } from '../../../utils/task';

import LoggedPage from '../../page-templates/fillForm-logged';

import BillingTable from './_billing-table';

import { scrollToTop } from '../../../utils/ui';

import NoData from '../../ui/no-data';

import { ProjectTypeNames, ProjectExtraTypeNames, ClientProjectStatusNames } from '../../../model';

import { getQuotationStatus } from '../../../utils/extras';


const mapStateToProps = state => {
  return Object.assign({}, state.partner, state);
};

const mapDispatchToProps = (dispatch) => {

  return {
    get_projects: () => {
      return dispatch(PARTNER_API.get_projects2({ fat: true })); 
    },
  };
};


class BillingListAdmin extends React.Component {

  constructor(props) {

    super();

    this.state = {
      task: {},
      errors: {}
    };
  }


  componentDidMount() {

    var load_task = new Task();

    load_task.start();

    this.setState({ task: load_task });

    this.props.get_projects()
      .then(({ task, errors }) => {

        this.setState({ task: task, errors: errors });

      });

    scrollToTop();

  }

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return '';

    return ProjectTypeNames[value].label;
  }

  render() {

    const data = [];

    this.props.projects && this.props.projects.map((item, index) => {

      const projectStatus = ClientProjectStatusNames[item.status];

      const projectRow = {
        key: item.projectId,
        projectId: item.projectId,
        type: I18n.get('Projeto'),
        sku: item.projectSKU,
        name: item.projectName,
        email: item.email,
        money: item.currency.name,
        clientName: item.clientName,
        status: projectStatus,
        price: item.priceClientWithDiscount,
        date: item.createdOnFmt,
        billings: item.billings,
        currency: item.currency,
        billingTotal: item.billingTotal,
        itemDate: item.createdOn,
        billingCurrency: item.billingCurrency
      }

      const children = [];

      item.extras && item.extras.map((extra) => {

        const extraStatus = getQuotationStatus(this.props.auth, extra.quotation);

        const extraRow = {
          key: extra.extraId,
          projectId: item.projectId,
          extraId: extra.extraId,
          type: 'Extra',
          sku: item.projectSKU,
          name: extra.extraName,
          email: item.email,
          money: item.currency.name,
          clientName: item.clientName,
          status: extraStatus,
          date: extra.createdOnFmt,
          billings: extra.billings,
          currency: item.currency,
          billingTotal: extra.billingTotal,
          itemDate: extra.createdOn,
          billingCurrency: extra.billingCurrency
        }

        children.push(extraRow);

      });

      if (children.length) {
        projectRow.children = children;
      }

      data.push(projectRow);

    });

    return (
      <LoggedPage {...this.props}>

        <div className="uplift partner-projects client-project-list">
          <div className="container">
            <br />
            <h1><span>{I18n.get("Faturamento")}</span></h1>

            <br />

            <div>
              {this.state.task.busy &&
                <NoData>
                  <p className="icone">
                    <Icon type="loading" /> <span className="txt">{I18n.get('Atualizando')}</span>
                  </p>
                </NoData>
              }

              <div className='alertFaturamento'>
                <p>
                  {I18n.get("Por favor, considere pagamento da BPool em até 3 dias úteis após o pagamento do cliente. Além disso, a BPool paga às terças e sextas-feiras.")}
                </p>

                <p className='legendStatus'>
                  <strong>{I18n.get("Status do vencimento")}:</strong> <span className='listStatus'>
                    <span className="item">
                      <i className="bolStatus vencer"></i> {I18n.get("Data de pagamento se aproximando")}
                    </span>

                    <span className="item">
                      <i className="bolStatus vencido"></i> {I18n.get("Pagamento atrasado")}
                    </span>

                    <span className="item">
                      <i className="bolStatus pago"></i> {I18n.get("Pagamento realizado")}
                    </span>
                  </span>
                </p>
              </div>
              <BillingTable
                data={data}
                totals={{}}
                {...this.props}
              />
            </div>


          </div>

        </div>

      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingListAdmin);
