import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { AUTH_API } from "../../actions/auth/actions";

import { Task } from "../../utils/task";

import LandingPage from "../page-templates/landingForLogin";

import ErrorPane from "../error-pane";

import { Row, Col, Tooltip, Button, Icon, Input, Form } from "antd";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      dispatch(AUTH_API.reset_password(data));
    },
  };
};

class FormReset extends React.Component {
  constructor(props) {
    super();

    this.state = {
      email: props.auth.email || "",
      code: "",
      password: "",
      password2: "",
      task: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState) return;

    if (this.props.auth.email != prevProps.auth.email) {
      this.setState({ email: this.props.auth.email });
    }

    if (!this.props.auth.task) return;

    if (this.props.auth.task.id != prevState.task.id) {
      this.setState({ task: this.props.auth.task });
    }
  }

  componentDidMount() {
    let lang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    lang =
      lang ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage;

    let language = lang.split("-")[0];

    if (language == "pt" || language == "es" || language == "en") {
      I18n.setLanguage(language);
    } else {
      I18n.setLanguage("pt");
    }
  }

  submitForm = () => {
    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.onSubmit(this.state);
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var task = new Task();

        task.start();

        const data = {
          ...values,
          email: values.email.trim(),
          code: values.code.trim(),
        };

        this.setState({ task: task }, function () {
          this.props.onSubmit(data);
        });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback(I18n.get("As senhas não conferem!"));
    } else {
      callback();
    }
  };

  render() {
    const { search } = this.props.location;

    const code = search ? search.split("c=")[1] : "";

    const { getFieldDecorator, getFieldValue } = this.props.form;

    return (
      <LandingPage>
        <div className="middle">
          <section className="login-section">
            <div className="container">
              <div className="columns  has-text-centered">
                <div className="column is-4 is-offset-4 form-recovery">
                  <div class="boxRedefinir">
                    <h3 className="title has-text-grey">
                      {I18n.get("Redefinir senha")}
                    </h3>
                    {!this.state.task.result && (
                      <p className="subtitle has-text-grey">
                        {I18n.get("Por favor escolha uma nova senha.")}
                      </p>
                    )}
                    <br />
                  </div>
                  {!this.state.task.result && (
                    <>
                      <Form
                        onSubmit={this.handleSubmit}
                        className="form-logoin has-text-left"
                      >
                        <Row>
                          <Col sm={24}>
                            <Form.Item label={I18n.get("E-mail")}>
                              {getFieldDecorator("email", {
                                rules: [
                                  {
                                    required: true,
                                    message: I18n.get("Campo obrigatório!"),
                                  },
                                ],
                                initialValue: this.state.email,
                              })(<Input />)}
                            </Form.Item>
                          </Col>
                          <Col sm={24}>
                            <Form.Item
                              label={I18n.get("Código enviado para o e-mail")}
                            >
                              {getFieldDecorator("code", {
                                rules: [
                                  {
                                    required: true,
                                    message: I18n.get("Campo obrigatório!"),
                                  },
                                ],
                                initialValue: code,
                              })(<Input />)}
                            </Form.Item>
                          </Col>

                          <Col sm={24}>
                            <Form.Item
                              label={
                                <span>
                                  {I18n.get("Senha")}
                                  <Tooltip
                                    title={I18n.get(
                                      "Use oito ou mais caracteres com uma combinação de letras (maiúsculas e minúsculas), números e símbolos (!@#$%&=?*.:,<>-_+)"
                                    )}
                                  >
                                    <Icon
                                      className="iconLabel"
                                      type="question-circle"
                                      theme="filled"
                                    />
                                  </Tooltip>
                                </span>
                              }
                            >
                              {getFieldDecorator("password", {
                                rules: [
                                  {
                                    required: true,
                                    message: I18n.get("Campo obrigatório!"),
                                  },
                                  ,
                                  {
                                    pattern:
                                      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{}[\];:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                                    message: I18n.get(
                                      "Senha Inválida, caracteres especiais permitidos (!@#$%^&*()-_=+{}[];:,<.>)"
                                    ),
                                  },
                                ],
                              })(<Input.Password />)}
                            </Form.Item>
                          </Col>
                          <Col sm={24}>
                            <Form.Item label={I18n.get("Confirme a senha")}>
                              {getFieldDecorator("password2", {
                                rules: [
                                  {
                                    required: true,
                                    message: I18n.get("Campo obrigatório!"),
                                  },
                                  {
                                    validator: this.compareToFirstPassword,
                                  },
                                ],
                              })(<Input.Password />)}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={24}>
                            <br />
                          </Col>
                        </Row>

                        {this.state.task.error && (
                          <Row>
                            <Col sm={24}>
                              <ErrorPane
                                error={this.state.task.error}
                              ></ErrorPane>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col sm={24}>
                            <Button
                              className="button bt-bpool full"
                              type="primary"
                              shape="round"
                              htmlType="submit"
                              loading={this.state.task.busy}
                            >
                              {I18n.get("Redefinir senha")}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                  {this.state.task.result && (
                    <>
                      <br />
                      <br />
                      <div className="form-logoin has-text-left">
                        <div>
                          <h3>{I18n.get("Senha alterada com sucesso!")}</h3>
                          <br />
                          <p className="subtitle has-text-grey">
                            {I18n.get("Para se logar,")}{" "}
                            <NavLink to="/login">
                              {I18n.get("clique aqui.")}
                            </NavLink>
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </section>
        </div>
        <br />
        <br />
      </LandingPage>
    );
  }
}

const resetForm = Form.create({})(FormReset);

export default connect(mapStateToProps, mapDispatchToProps)(resetForm);
