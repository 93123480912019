import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook"
import Loading from "../../../components/UI/Loading";

import { Filters } from "./components/Filters"
import { ItemInvitation } from "./components/ItemInvitation";

import * as S from "./styles";

const INIT_FILTER_STATUS = 1; //New

export const Invitations = () => {
  const { getPartnerInvites } = useProjects();
  const [filters, setFilters] = useState("");
  const [listInvitations, setListInvitations] = useState([]);
  const [listInvitationsBackup, setListInvitationsBackup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const filterListInitial = async ({ status, arr }) => {
    const listUpdated = arr.filter((item) => (
      item?.statusInvite === status
    ));
    setListInvitations(listUpdated);
  };

  const loadInit = async () => {
    setIsLoading(true);

    const response = await getPartnerInvites(filters);
    if (response?.success) {
      if (response?.data?.length) {
        await filterListInitial({ status: INIT_FILTER_STATUS, arr: response?.data });
        setListInvitationsBackup(response?.data);
      } else {
        setListInvitations([]);
        setListInvitationsBackup([]);
      }
    }

    setIsLoading(false);
  };

  const filterList = async (status) => {
    const listUpdated = listInvitationsBackup.filter((item) => (
      item?.statusInvite === status
    ));
    setListInvitations(listUpdated);
  };

  const handleFilterList = async (status) => {
    if (status === 999) {
      setListInvitations(listInvitationsBackup);
    } else {
      filterList(status);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  return (
    <S.Wrapper>
      <Filters filterList={handleFilterList} initFilterStatus={INIT_FILTER_STATUS} />
      <div>
        {isLoading ? (
          <div>
            <Loading
              sizeIcon={18}
              text={I18n.get("Loading...")}
              sizeText={14}
              align="left"
            />
          </div>
        ) : listInvitations?.length > 0 ? (
          <S.ListOfInvitations>
            {listInvitations.map((invite, index) => (
              <ItemInvitation key={index} data={invite} />
            ))}
          </S.ListOfInvitations>
        ) : (
          <div>{I18n.get("There are no invitations.")}</div>
        )}
      </div>
    </S.Wrapper>
  );
};
