import React, { PureComponent } from "react";
import { I18n } from 'aws-amplify';
import { Icon } from 'antd';
import styled from "styled-components";

const Box = styled.div`
    background: #F0F2F5;
    text-align: center;
    padding: 30px 20px;
    margin-top: 20px;

    i {
        font-size: 20px;
    }
`
class NotRegistered extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Box>
                <p><Icon type="exclamation-circle" /></p>
                <p>{this.props.text}</p>
            </Box>
        );
    }
}

export default NotRegistered;
