import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { openChatModal } from '../../../store/actions/chatModal';
import { updateChatNotification } from '../../../store/actions/chatNotifications';

import ChatIconNotification from '../../../../svg/chat-icon-notification.svg';

import * as S from './styles';

const chatNotificationsLocal = {
  notifications: [
    {
      createdAt: '2022-01-24T13:05:15.492Z',
      from: '803b4a38-762b-4e25-8c77-2e7bbace95f3',
      id: 1643029515492,
      message: 'Você recebeu uma nova mensagem no chat.',
      seen: true,
      topic:
        '1T1/803b4a38-762b-4e25-8c77-2e7bbace95f3/9c80132d-222d-401a-b834-4081cd7af089',
    },
    {
      createdAt: '2022-01-24T13:05:26.592Z',
      from: '803b4a38-762b-4e25-8c77-2e7bbace95f3',
      id: 1643029526592,
      message: 'Você recebeu uma nova mensagem no chat.',
      seen: false,
      topic:
        '1T1/803b4a38-762b-4e25-8c77-2e7bbace95f3/9c80132d-222d-401a-b834-4081cd7af089',
    },
  ],
};

const ChatNotification = () => {
  const chatNotifications = useSelector((state) => state.chatNotifications);
  const [openDropDown, setOpenDropDown] = useState(false);

  const dispatch = useDispatch();

  const handleOpenDropDown = () => {
    if (openDropDown) {
      setOpenDropDown(false);
    } else {
      setOpenDropDown(true);
    }
  };

  const handleClickMessage = (topic, id) => {
    setOpenDropDown(false);
    dispatch(openChatModal(topic));
    dispatch(updateChatNotification(id));
  };

  const handleOpenChat = () => {
    dispatch(openChatModal());
  };

  return (
    <S.NavbarChatNotification data-label="button-open-chat-navbar">
      <S.NavbarChatIconNotification
        onMouseEnter={
          chatNotifications?.notifications?.length
            ? () => setOpenDropDown(true)
            : () => true
        }
        onClick={
          chatNotifications?.notifications?.length
            ? () => handleOpenDropDown()
            : () => handleOpenChat()
        }
      >
        {chatNotifications?.notifications?.length ? (
          <S.CounterNotification>
            <div style={{ lineHeight: 7 }}>
              {chatNotifications?.notifications?.length}
            </div>
          </S.CounterNotification>
        ) : null}
        <img src={ChatIconNotification} />
      </S.NavbarChatIconNotification>

      {openDropDown ? (
        <S.DropDown onMouseLeave={() => setOpenDropDown(false)}>
          <S.ListDropDown>
            {chatNotifications?.notifications?.map((item, index) => {
              return (
                <li
                  style={{
                    background: index % 2 === 0 ? '#f9f9f9' : '#f1f1f1',
                  }}
                  key={`${item.from}-${index}`}
                >
                  <S.ButtonMessage
                    onClick={() => handleClickMessage(item.topic, item.id)}
                  >
                    <S.MessageList>
                      <p>{item.message}</p>
                      {/* <strong>De: Paulo Souza</strong> */}
                      <small>{moment(item.createdAt).fromNow()}</small>
                    </S.MessageList>
                  </S.ButtonMessage>
                </li>
              );
            })}
          </S.ListDropDown>
        </S.DropDown>
      ) : null}
    </S.NavbarChatNotification>
  );
};

export default ChatNotification;
