import styled from "styled-components";

export const H3 = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
  margin: 32px 0;
`;

export const Label = styled.div`
  line-height: 20px;
  margin-bottom: 4px;
  font-weight: 600;
`;
