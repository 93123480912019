import { CHAT_LIST } from './actions';

const api_call = (data) => {

    return (dispatch, getState) => {
        dispatch({
            type: CHAT_LIST,
            payload: data
        })

        if(data){
            const x = JSON.stringify({"action":"listMessages","channelID":data});
            window.sendWsMessage(x);
        }
    };

}

export default api_call;
