import React, { Fragment } from 'react';

import { I18n } from 'aws-amplify';

//  import Modal from '../../ui/modal';

import Dphoto from '../../../image/dphoto.png';
import ReactPlayer from 'react-player';

import {
    Icon,
    Button,
    Modal,
    Row,
    Col
} from 'antd';

import ClientDetailReceita from './_details-company-receita';

class Card extends React.Component {

    constructor(props) {

        super();

        this.state = {
            selected: null,
            visibleHeader: false,
            visiblePoster: false
        };

        this.select = this.onSelect.bind(this);

        this.closeModal = this.onCloseModal.bind(this);

    }

    onSelect(item, e) {
        this.setState({ selected: item });
    }

    onCloseModal(e) {
        e.preventDefault();
        this.setState({ selected: null });
    }

    handleCancel = e => {
        this.setState({
            visibleHeader: false,
            visiblePoster: false
        });
    };


    render() {

        if (!this.props.bp) return null;
        if (!this.props.bp.client) return null;
        if (!this.props.bp.client.company) return null;

        const item = this.props.bp.client.company;



        return (
            <Fragment>
                <div className="card">
                    <div className="card-content">


                        <Row gutter={1}>
                            <Col sm={24}>
                                <h3 className="title is-4">{I18n.get("Empresa")}</h3>
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={1}>
                            <Col sm={2}>
                                <img width="100%" src={item.logoImage || Dphoto} />
                            </Col>
                            <Col sm={22}>
                                <div className="admin-content-right">
                                    <Row gutter={16}>
                                        <Col sm={12}>
                                            <h3 className="title-bpool regular">{item.name} (T{item.tc})</h3>
                                            <p><b>{I18n.get("Razão social")}: </b>{item.registrationName}</p>
                                            <p><b>{I18n.get("CNPJ")}:  </b>{item.registrationId}</p>
                                        </Col>
                                        <Col sm={12}>
                                            <p><b>{I18n.get("Ano fundação")}: </b> {item.registrationDate}</p>
                                            <p><b>{I18n.get("Telefone")}: </b>{item.phoneNumber}</p>
                                            <br />
                                            <p className="listButtons">
                                                {item.website &&
                                                    <Fragment>
                                                        <a target="_blank" href={item.website} className="button bt-bpool black"><Icon type="link" /> {I18n.get("Websites")}</a>
                                                        <Button onClick={() => this.setState({ visibleHeader: true })} className="button bt-bpool black" disabled={!item.header}><Icon type="eye" theme="filled" /> {I18n.get("Imagem header")}</Button>
                                                        {/* <Button onClick={() => this.setState({ visiblePoster: true })} className="button bt-bpool black" disabled={item.posters?.length === 0 ?? true}><Icon type="eye" theme="filled" /> {I18n.get("Imagem poster")}</Button> */}
                                                    </Fragment>
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>



                    </div>

                    {/* <Modal isOpen={!!this.state.selected} closeModal={this.closeModal}>

                    <PartnerDetailReceita item={this.state.selected} />

                </Modal> */}


                    <Modal
                        title={I18n.get("Imagem header")}
                        visible={this.state.visibleHeader}
                        onCancel={this.handleCancel}
                        destroyOnClose={true}
                        footer={null}
                    >
                        <p>
                            <img src={item.header} />
                        </p>
                    </Modal>

                    <Modal
                        title={I18n.get("Imagem poster")}
                        visible={this.state.visiblePoster}
                        onCancel={this.handleCancel}
                        destroyOnClose={true}
                        footer={null}
                    >

                        {(item.posters) && <p>
                            <img src={item.posters[0]} />
                        </p>}

                    </Modal>

                </div>
            </Fragment>
        );
    }

}

export default Card;
