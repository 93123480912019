import React from 'react';

import { I18n } from 'aws-amplify';

const SimpleModal = (props) => {

    const modalClassName = 'modal simple-modal ' + props.className + (props.isOpen ? ' is-active ' : '');

    var contentStyle = {}
    switch(props.size) {
        case 'large':
            contentStyle = { width: '811px', maxWidth: '811px' }
            break;
        case 'fullscreen':
            contentStyle = { width: '90%', maxWidth: '1200px'}
            break;
        default:
            contentStyle = {}
    }
    
    return (
        <div className={modalClassName}>
            {props.onCloseModal &&
                <a onClick={() => props.onCloseModal()} className="button outside-modal-content ">{I18n.get("Fechar X")}</a>
            }
            <div className="modal-background"></div>
            <div className="modal-content" style={contentStyle}>
                {props.onCloseModal &&
                    <a onClick={() => props.onCloseModal()} className="button inside-modal-content ">{I18n.get("Fechar X")}</a>
                }
                {props.children}
            </div>
        </div>
    )
};

export default SimpleModal;