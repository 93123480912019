import React, { useState } from "react";
import { Select } from "antd";

import * as S from "./styles";
const { Option } = Select;

export const SelectOneWithImage = ({
  options,
  value,
  defaultValue,
  onChange,
  required,
  placeholder,
  error,
  clearError,
  disabled,
  defaultActiveFirstOption,
  loading,
  lastItemBordered = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const changeValue = (valueInternal) => {
    if (onChange) {
      onChange(valueInternal);
      if (valueInternal !== value) {
        if (clearError) {
          clearError();
        }
      }
    }
  };

  const checkValue = value != "" || value != undefined || value != null;

  const toggleFocus = () => {
    setIsFocused((prevIsFocused) => !prevIsFocused);
  };

  return (
    <>
      <S.Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-input",
          width: "100%",
        }}
      >
        <S.Wrapper errorSelect={error} isFocused={isFocused}>
          {placeholder ? (
            <S.PlaceHolder valueSelected={checkValue} isFocused={isFocused}>
              {required ? <span>*</span> : null}
              {placeholder}
            </S.PlaceHolder>
          ) : null}
          <Select
            defaultValue={defaultValue}
            style={{ width: "100%" }}
            onChange={(val) => changeValue(val)}
            value={value}
            disabled={disabled}
            defaultActiveFirstOption={defaultActiveFirstOption}
            data-test={`select-${placeholder
              ?.replace(/\s+/g, "-")
              ?.toLowerCase()}`}
            showSearch={true}
            filterOption={(input, option) =>
              option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onFocus={toggleFocus}
            onBlur={toggleFocus}
          >
            {options?.map((option, index) => (
              <Option
                key={option.code}
                value={option?.code}
                label={option?.label}
                style={
                  lastItemBordered && index === options?.length - 1
                    ? { borderTop: "1px solid #ccc", marginTop: 16 }
                    : {}
                }
              >
                {option?.image ? (
                  <span
                    role="img"
                    aria-label={option?.label}
                    style={{ marginRight: 8 }}
                  >
                    {option?.image}
                  </span>
                ) : null}
                {option?.label}
              </Option>
            ))}
          </Select>
          {error ? <S.FieldError>{error}</S.FieldError> : null}
        </S.Wrapper>
      </S.Skeleton>
    </>
  );
};
