import styled from "styled-components";

export const Wrapper = styled.div``;

export const WrapperTypes = styled.div`
  display: flex;
  justify-content: center;
  div {
    padding: 0 10px;
  }
`;

export const MiniBorder = styled.div`
  width: 80%;
  margin: 60px auto 0;
  border-top: solid 1px #000;
`;

export const Btns = styled.div`
  display: flex;
  justify-content: right;

  button {
    min-width: 100px;
    margin-left: 15px;
  }
`;

export const ItemReferrer = styled.div`
  margin-bottom: 20px;
  .titleReference {
    font-weight: bold;
    color: #2883ed;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .brand {
    margin-bottom: 5px;
    font-weight: bold;
    color: #000;
    font-size: 18px;
  }

  .infos {
    display: flex;
  }

  .name,
  .email {
    width: 30%;
  }

  .btns {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    button {
      margin-left: 15px;
      min-width: 80px;
    }
  }
`;

export const InfoError = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;

export const SelectBox = styled.div`
  .select-industries-dropdown {
    margin-bottom: 5px;

    .ant-select-selection {
      background: #fff;
      border: 1px solid #999999;
      height: 50px;
      border-radius: 10px !important;

      .ant-select-selection__rendered {
        height: 100%;
        display: flex;
        align-items: center;
      }

      span {
        i {
          top: 16px;
        }
      }
    }
  }
`;

export const ButtonType = styled.button`
  display: block;
  cursor: pointer;
  border: 1px solid;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  font-weight: 500;
  font-size: 16px;
  height: 60px;

  background-color: ${(props) => (props.active ? "#1883ff" : "#ffffff")};
  border-color: ${(props) => (props.active ? "#ffffff" : "#1883ff")};
  color: ${(props) => (props.active ? "#ffffff" : "#1883ff")};
`;

export const LabelButton = styled.div`
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: ${(props) => (props.active ? "#ffffff" : "#1883ff")};
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }

  &.box-client {
    margin: 80px 0 40px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const H5 = styled.h5`
  font-weight: 700;
  font-size: 24px;
  color: #000000;
`;
export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const InputLabel = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 8px;
  display: flex;

  span {
    color: #ff0000;
    font-size: 22px;
  }
`;

export const TitleSectionForm = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 20px;
  display: flex;
  border-bottom: 1px solid;
  border-color: #000000;
  width: max-content;
  span {
    color: #f00;
  }
`;

export const WrapperItemCaseForm = styled.div`
  margin: 30px 0 30px 0;
`;

export const ItemCaseFormEmpty = styled.div`
  font-size: 14px;
`;

export const ItemCaseLabel = styled.div`
  font-weight: bold;
`;

export const ItemCaseValue = styled.div``;

export const ContentItemCase = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const ItemCase = styled.div``;
