import React from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { Table, Tag } from 'antd';

import NumberFormat from 'react-number-format';


const BRL = ({ amount }) => <NumberFormat value={amount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} fixedDecimalScale={true} />;


const TableCpm = styled(Table)`
    border: solid 1px #ccc;

    .ant-table table {
        border: 0!important;
    }
    .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover, .ant-table-thead > tr > th.ant-table-column-sort {
        background: #000;
    }
`



export function TableSavingProduct(data) {
    function compareByAlph(a, b)  { if (a > b) { return -1; } if (a < b) { return 1; } return 0; }

    const columns = [
        {
            title: I18n.get("Projeto"),
            dataIndex: 'projectName',
            sorter: (a, b) => compareByAlph(a.projectName, b.projectName),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: I18n.get("Marca"),
            dataIndex: 'brand',
            sorter: (a, b) => compareByAlph(a.brand, b.brand),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: I18n.get("Produto"),
            dataIndex: 'brandProduct',
            sorter: (a, b) => compareByAlph(a.brandProduct, b.brandProduct),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: I18n.get("TT Assets Preço Referência"),
            dataIndex: 'totalRef',
            sorter: (a, b) => compareByAlph(a.totalRefSort, b.totalRefSort),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: I18n.get("TT Assets Spent"),
            dataIndex: 'totalSpent',
            sorter: (a, b) => compareByAlph(a.totalSpentSort, b.totalSpentSort),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: I18n.get("Savings"),
            dataIndex: 'totalSaving',
            sorter: (a, b) => compareByAlph(a.totalSavingSort, b.totalSavingSort),
            sortDirections: ['descend', 'ascend'],
            with: 200
        },
     
    ];

    let dataTable = data && data.map((item, index) => {
        return {
            projectName: item.projectName,
            brand: item.brand,
            brandProduct: item.brand,
            totalRef: <BRL amount={item.totalRef} />,
            totalSaving:  <BRL amount={item.totalSaving} />,
            totalSpent: <BRL amount={item.totalSpent} />,
            totalRefSort: item.totalRef,
            totalSavingSort: item.totalSaving,
            totalSpentSort: item.totalSpent,
        }
    }) || [];
    
    
      
  return <TableCpm 
        columns={columns} 
        dataSource={dataTable} 
        scroll={{ y: 300 }} 
        pagination={false}
        footer={() => {
            let total = dataTable.length > 0 && dataTable.reduce((acumulador, item, indice, original) => {
                return acumulador + item.totalSavingSort;
            }, 0)

            return <p style={{textAlign: "right", padding: "0 40px"}}><strong>{I18n.get('Total')}:</strong>  <BRL amount={total} /></p>
        }}
    />  
}

