import styled from "styled-components";

export const List = styled.div`
  border-bottom: solid 1px #000;
  padding-bottom: 15px;
  margin-bottom: 70px;
`;

export const Icon = styled.span`
  margin-right: 15px;
`;
export const FileItem = styled.div`
  margin-right: 40px;
  margin-bottom: 10px;
  display: inline-block;
`;
export const FileName = styled.span`
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #000000;

    &:hover,
    &:focus {
      color: #1883ff;
    }
  }
`;
export const Close = styled.a`
  position: relative;
  top: -5px;
  left: 5px;
  img {
    width: 15px;
    height: 15px;
  }

  &:hover,
  &:focus {
    opacity: 0.5;
  }
`;
