import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { ClientProjectStatus } from '../../model';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import ProjectCancelForm from './project-cancel-form';

import Steps from './steps';

class ProjectOnGoingEnd extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount(){
    }

    submitForm(e) {

    }

    render() {

        if (this.props.project.status == ClientProjectStatus.Canceled){
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/cancelado`} />)
        }

        return (
            <FillForm>
                <div className="hero-body has-background-white project-ongoing-header">
                    <div className="container">
                        <div className="container-nav-progress">
                            <div className="col">
                                <p className="title">{I18n.get("Projeto em Andamento")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="col">
                                <Steps active={1} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []}  />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-ongoing">

                    <div className="container client-container-1">

                    <ProjectCancelForm {...this.props} />

                        <br />
                        <br />


                    </div>

                </div>

         </FillForm>
        );
    }
}

export default ProjectContainer(ProjectOnGoingEnd);
