import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 32px 0;
  border-bottom: 1px solid;
  border-color: #e8e8e8;
  margin-bottom: 32px;

  h3 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 32px;
  }
`;

export const Divider = styled.hr`
  margin: 32px 0;
  height: 1px;
  background: #e8e8e8;
`;

export const Info = styled.div`
  label {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
  p {
    color: ${(props) => (props?.colorBlack ? "#000000" : "#979797")};
    font-size: 16px;
    line-height: 22px;
    margin-top: 4px;
  }

  a.description {
    color: #000;
    span {
      margin-right: 5px;
    }

    &:hover {
      color: #1883ff;
      text-decoration: underline;
    }
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-infos {
    margin-top: 4px;
    height: 22px;

    li {
      height: 100%;
    }
  }
`;
