import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { Collapse } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ModalBp } from '../../../../components/UI/ModalBp/ModalBp';
import { ItemCaseOrAwardsRowChecks } from './itemCaseOrAwardsRowChecks';

import * as S from './styles';

const { Panel } = Collapse;

export const ItemAward = ({
  textEmpty,
  data,
  profiles,
  onDelete,
  onEdit,
  typeOfTab,
  addOrRemoveParticipedAwardsIds,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    id: null,
  });
  const [participedAwardsIds, setParticipedAwardsIds] = useState([]);

  useEffect(() => {
    addOrRemoveParticipedAwardsIds(participedAwardsIds);
  }, [participedAwardsIds]);

  const handleDeleteModal = (id) => {
    if (openDeleteModal.open) {
      setOpenDeleteModal({ open: false, id: null });
    } else {
      setOpenDeleteModal({ open: true, id });
    }
  };

  const modalConfirmDelete = () => {
    onDelete(openDeleteModal.id);
    setOpenDeleteModal({ open: false, id: null });
  };

  const onChange = (key) => {
    return;
  };

  const handleEdit = (id) => {
    onEdit(id);
  };

  const genExtra = (id) => (
    <>
      <EditOutlined
        onClick={(event) => {
          event.stopPropagation();
          handleEdit(id);
        }}
      />
      <DeleteOutlined
        style={{ marginLeft: 15 }}
        onClick={(event) => {
          event.stopPropagation();
          handleDeleteModal(id);
        }}
      />
    </>
  );

  const renderInfo = ({ label, value }) => {
    return (
      <S.ItemInfo>
        <S.ItemLabel>{label}</S.ItemLabel>
        <S.ItemValue>{value}</S.ItemValue>
      </S.ItemInfo>
    );
  };

  const renderHeader = ({ type, index, name }) => {
    if (type === 1) {
      return `${I18n.get('Your company award')} ${index} - ${name}`;
    } else {
      return `${I18n.get('Your career award')} ${index} - ${name}`;
    }
  };

  const renderProfilesName = (roles) => {
    if (roles.length) {
      return roles.map((role) => role);
    } else {
      return I18n.get('Not selected');
    }
  };

  const onChangeParticipedAwardsIds = (checkParticiped) => {
    if (checkParticiped.checkbox === 'yes') {
      setParticipedAwardsIds([...participedAwardsIds, checkParticiped.id]);
    } else {
      const filteredItems = participedAwardsIds.filter(
        (id) => id !== checkParticiped.id
      );
      setParticipedAwardsIds(filteredItems);
    }
  };

  return (
    <>
      <S.WrapperItem>
        {data.length ? (
          <Collapse onChange={onChange}>
            {data.map((item, index) => (
              <Panel
                key={item?.id}
                header={renderHeader({
                  type: item?.type,
                  index: index + 1,
                  name: item?.name,
                })}
                extra={typeOfTab === 0 ? null : genExtra(item?.id)}
              >
                <S.ContentItem>
                  {renderInfo({
                    label: I18n.get('Award name'),
                    value: item?.name,
                  })}
                  {renderInfo({
                    label: I18n.get('Brand'),
                    value: item?.brand,
                  })}
                  {renderInfo({
                    label: I18n.get('Ano'),
                    value: item?.year,
                  })}
                  {renderInfo({
                    label: I18n.get('Category'),
                    value: item?.industry,
                  })}

                  {typeOfTab === 0 ? (
                    <>
                      <S.ItemInfo>
                        <S.ItemLabel>
                          {I18n.get('Did you participate in this case?')}
                        </S.ItemLabel>
                        <S.Checkboxes>
                          <ItemCaseOrAwardsRowChecks
                            id={item?.id}
                            checkDefault={item?.participated}
                            onChangeCheck={(check) =>
                              onChangeParticipedAwardsIds(check)
                            }
                          />
                        </S.Checkboxes>
                      </S.ItemInfo>
                    </>
                  ) : null}
                </S.ContentItem>
              </Panel>
            ))}
          </Collapse>
        ) : (
          <S.ItemFormEmpty>{textEmpty}</S.ItemFormEmpty>
        )}
      </S.WrapperItem>

      <ModalBp
        visible={openDeleteModal.open}
        bodyText={I18n.get('Do you want to delete?')}
        subBodyText={I18n.get('You will lose this item?.')}
        okText={I18n.get('Delete')}
        cancelText={I18n.get('Cancel')}
        handleClickOk={modalConfirmDelete}
        onCancel={handleDeleteModal}
        typeModal="isConfirm"
        isDanger
      />
    </>
  );
};
