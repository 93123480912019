import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { I18n } from "aws-amplify";
import { saveAs } from "file-saver";
import { PDFReader } from "reactjs-pdf-reader";
import { ButtonBpool } from "../../../../../../components/UI/ButtonBpool";

import * as S from "../../styles";

export const ModalTerms = ({ openTerms, handleClickCancel }) => {
  let location = useLocation();
  const [localeUser, setLocaleUser] = useState("pt");

  const parseQueryString = (queryString) => {
    const params = new URLSearchParams(queryString);
    const obj = {};
    for (const [key, value] of params.entries()) {
      obj[key] = decodeURIComponent(value.replace(/'/g, ""));
    }
    return obj;
  };

  const getParamsLocale = () => {
    let languageInternal = "";
    const params = parseQueryString(location.search);
    if (params?.culture) {
      languageInternal = params?.culture;
    } else {
      languageInternal = "pt";
    }
    return languageInternal;
  };

  useEffect(() => {
    const language = getParamsLocale();
    setLocaleUser(language);
  }, [location]);

  const renderPdfByLocation = (language) => {
    if (language === "pt" || language === "br") {
      return "https://assets.bpool.co/terms/partner-bpay-br.pdf";
    } else if (language === "en") {
      return "https://assets.bpool.co/terms/partner-bpay-en.pdf";
    } else if (language === "es") {
      return "https://assets.bpool.co/terms/partner-bpay-es.pdf";
    }
  };

  const downloadTerms = () => {
    saveAs(renderPdfByLocation(localeUser), "BPool-terms.pdf");
  };

  return (
    <S.WrapperModal
      visible={openTerms}
      onOk={downloadTerms}
      okText={I18n.get("Download")}
      onCancel={handleClickCancel}
      cancelText={I18n.get("Fechar")}
      width={1000}
      centered
      footer={[
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonBpool
            onClick={handleClickCancel}
            text={I18n.get("Fechar")}
            theme="tertiary"
          />
          <div style={{ marginLeft: 16 }}>
            <ButtonBpool
              onClick={downloadTerms}
              text={I18n.get("Download")}
              theme="primary"
            />
          </div>
        </div>,
      ]}
    >
      <S.TermsWrapper>
        <PDFReader
          url={renderPdfByLocation(localeUser)}
          showAllPage
          width={800}
        />
      </S.TermsWrapper>
    </S.WrapperModal>
  );
};
