import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import * as S from "./styles";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const RichEditor = ({ title, defaultValue, disabled, onChange }) => {

  useEffect(() => {
    if (defaultValue) {
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue))))
    } else {
      setEditorState(EditorState.createEmpty())
    }
  }, [defaultValue]);

  const [editorState, setEditorState] = useState("");

  const onEditorStateChange = (editorState) => {
    return setEditorState(editorState);
  };

  const handleContent = () => {
    const contentState = editorState.getCurrentContent();

    const content = JSON.stringify(convertToRaw(contentState));

    const text = contentState.getPlainText();

    onChange({ content, text });
  };

  return (
    <S.Wrapper>
      {title ? (
        <S.Title>
          <p>{title}</p>
        </S.Title>
      ) : null}
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        onChange={handleContent}
        readOnly={disabled}
        toolbar={{
          options: ["inline"],
          inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"],
          },
        }}
      />
    </S.Wrapper>
  );
};
