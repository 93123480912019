import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_MEETINGS } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_meetings = (data) => {

    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.post(`${Config.API_BASE_URL}/bp/meeting-invites`, data, config)
                .then(response => {

                    dispatch({
                        type: BP_MEETINGS,
                        payload: {
                            meetingsInvites: response.data,
                            task: { result: true }
                        }
                    })
                })
                .catch(error => {

                    dispatch({
                        type: BP_MEETINGS,
                        payload: {
                            task: { errors: error.response.data }
                        }
                    })

                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}


export default api_meetings;