import styled from "styled-components";

export const Wrapper = styled.div``;

export const Table = styled.table`
  border: none !important;
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  tr {
    background-color: #ffffff;
    line-height: 55px;
  }

  tr:first-of-type {
    background-color: #FAFAFA;
;
  }
`;

export const LoadingContent = styled.div`
  line-height: 55px;
  display: flex;
  align-items: center;
`;