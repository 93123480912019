import styled from "styled-components";

export const TopInformation = styled.div`
  padding: 0 15px;
  margin: 0 auto;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    /* identical to box height */
    margin-bottom: 15px;
    text-align: center;

    color: #000000;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: left;

    color: #000000;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;
