import styled, { css } from "styled-components";

export const TitleModal = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const TitleTotal = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
  }
`;

export const SearchArea = styled.div`
  margin: 10px 0;
`;

export const ListAssets = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
`;

export const ItemListAsset = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 15px 0;
`;

export const LeftAsset = styled.div`
  width: calc(100% - 60px);
`;

export const LabelAndValue = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelAsset = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #1883ff;
`;

export const ValueAsset = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #1883ff;
`;

export const ExpandButton = styled.span`
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  color: #999999;
  text-decoration: underline;
`;

export const DescriptionAssetTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.2;
  /* margin-top: 16px; */
`;

export const DescriptionAsset = styled.div`
  display: -webkit-box;
  max-width: 100%;
  /* margin-top: 10px; */
  font-size: 12px;
  ${(props) =>
    !props.expanded &&
    `
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      height: 28px;
      line-height: 1.2;
  `}
`;

export const PlusContent = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;

  button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #1883ff;
    color: #fff;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SeparatorVertical = styled.div`
  width: 1px;
  height: calc(500px + 90px);
  background-color: #e8e8e8;
`;

export const ListAndTotals = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: ${(props) => (props?.cartTotals ? "570px" : "670px")};
`;

export const ListAssetsAdded = styled.div`
  max-height: 650px;
  overflow-y: scroll;
`;

export const CartTotals = styled.div``;

export const ChangedItems = styled.div`
  margin-right: 10px;

  p {
    color: #ff0000;
    font-size: 14px;
  }
`;

const getGridTemplateColumns = (total) => {
  switch (total) {
    case 1:
      return "1fr";
    case 2:
      return "1fr 1fr";
    case 3:
      return "1fr 1fr 1fr";
    default:
      return "1fr"; // Default case
  }
};

export const ComplexityAsset = styled.div`
  font-size: 12px;
  margin-top: 12px;
  display: grid;
  align-items: center;
  grid-row-gap: 6px;
  grid-template-areas:
    "item1 item2"
    "item3 item3";
  /* ${({ totalComplexities }) => css`
    grid-template-columns: ${getGridTemplateColumns(totalComplexities)};
  `} */
  span {
    font-weight: 600;
  }
`;
