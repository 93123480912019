export const validateYouTubeUrl = (urlToParse) => {
  if (urlToParse) {
    var regExp =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (urlToParse.match(regExp)) {
      return true;
    }
  }
  return false;
};

export const validateVimeoUrl = (urlToParse) => {
  if (urlToParse) {
    var regExp = /^(?:https?\:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)?$/;
    if (urlToParse.match(regExp)) {
      return true;
    }
  }
  return false;
};

export const validateDropboxStringUrl = (urlToParse) => {
  if (urlToParse) {
    var regExp = /^(?:https?\:\/\/)?(?:www\.)?(?:dropbox\.com\/)?$/;
    if (urlToParse.match(regExp)) {
      return true;
    }
  }
  return false;
};

export const youtubeAndVimeoPass = (urlToParse) => {
  const youtubePass = validateYouTubeUrl(urlToParse);
  const vimeoPass = validateVimeoUrl(urlToParse);

  const pass = [youtubePass, vimeoPass].includes(true);
  return pass;
};
