import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { CLIENT_BRAND_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_client_update_global = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.put(`${Config.API_BASE_URL}/client/Customization-product-global-message`, data, config)
            .then(response => {

               return response;
               
            })
            .catch(error => {
                return error;

                

            });
}).catch(error => { 
    return error;
//  dispatch({ type: EXPIRED }); 
 }); 
 };
}

export default api_client_update_global;