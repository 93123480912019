import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import FillForm from '../page-templates/fillForm';

import bannerImage3 from '../../image/banner-image3.png';

export default class WallPage extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (<FillForm>

            <div className="hero-body">
                <div className="container">

                    <div className="columns">

                        <div className="column is-4">

                            <figure className="image">
                                <img src={bannerImage3} alt="Banner" />
                            </figure>

                        </div>

                    </div>

                    <div className="columns">

                        <div className="column is-offset-3 is-6">

                            <h1 className="subtitle is-4 is-marginless">
                                {this.props.title}
                            </h1>
                            <div className="colot-line"></div>

                            <br /><br />
                            
                            <h1 className="subtitle is-6">
                                {this.props.subtitle}
                            </h1>

                        </div>

                    </div>

                </div>
              </div>

     </FillForm>)
    }
}

