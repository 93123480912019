import styled from "styled-components";

export const Wrapper = styled.div`
  .text {
    font-size: 14px;
    margin-top: 15px;
  }
`;

export const Title = styled.div`
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
  max-width: fit-content;

  span {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }
`;

export const InputAndButton = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 65px) 65px;
  margin-top: 15px;

  button {
    margin-left: 15px;
    cursor: pointer;
    background-color: #1984ff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: none;

    i {
      color: #fff;
      font-size: 35px;
    }

    :disabled {
      cursor: not-allowed;
      background-color: #e7e7e7;
    }
  }
`;

export const ListLinks = styled.div`
  ul {
    li {
      height: 50px;
      border: 1px solid #999;
      color: #515152;
      border-radius: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 2px 3px 2px rgb(0 0 0 / 8%);
      margin-top: 15px;

      :first-child {
        margin-top: 0;
      }

      button {
        cursor: pointer;
        background: transparent;
        border: none;
        padding: 5px;
        display: flex;
        align-items: center;
      }
      a {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const LinkAdded = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
