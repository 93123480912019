import React from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { Input } from "../../../../../../components/UI/Input";
import { hasError, clearErrorInput } from "../../../../utils/errorsInput";
import { Info } from "./Info";

import * as S from "../../styles";

export const FormDadosDaNota = ({
  inputsForm,
  handleChangeInputForm,
  errorsInputs,
  handleSetErrorsInputs,
  isLoadingGet,
  nfValue,
  inputsDisableds,
}) => {
  return (
    <>
      <h3 style={{ marginTop: 2 }}>{I18n.get("Dados da nota")}</h3>
      <Row>
        <Col xs={24} md={12} style={{ paddingLeft: 0 }}>
          <Input
            id="invoiceNumber"
            placeholder={I18n.get("Número da nota")}
            label
            required
            typeInput="number"
            value={inputsForm.invoiceNumber}
            onChange={(value) => handleChangeInputForm("invoiceNumber", value)}
            error={hasError({
              arr: errorsInputs,
              field: "invoiceNumber",
            })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "invoiceNumber",
                arr: errorsInputs,
              });
              handleSetErrorsInputs(errorsArr);
            }}
            disabled={inputsDisableds}
          />
        </Col>
        <Col xs={24} md={12} style={{ paddingRight: 0 }}>
          <Info
            label={I18n.get("Valor da nota")}
            info={nfValue}
            loading={isLoadingGet}
          />
        </Col>
      </Row>
    </>
  );
};
