import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { BP_API } from "../../actions/bp/actions";

import LoggedPage from "../page-templates/loggedAdmin";

import ClientCustomizeForm from "./client/_customize-form";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_clients: (data) => {
      dispatch(BP_API.get_clients(data));
    },
    get_invoicetypes: () => {
      dispatch(BP_API.get_invoicetypes());
    },
    get_client: (id) => {
      dispatch(BP_API.get_client(id));
    },

    client_upload: (data) => {
      return BP_API.client_upload(data);
    },
  };
};

class ClientCustomize extends React.Component {
  constructor(props) {
    super();

    this.state = {
      clientId: props.match.params.clientId,
    };
  }

  componentDidMount() {
    this.props.get_client(this.state.clientId);
    this.props.get_invoicetypes();
  }

  render() {
    return (
      <LoggedPage {...this.props}>
        <nav
          className="breadcrumb is-medium"
          style={{ marginTop: "60px", marginBottom: "60px" }}
          aria-label="breadcrumbs"
        >
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/bpool/clientes" className="is-black">
                {I18n.get("Clientes")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/bpool/clientes/${this.props.match.params.clientId}`}
                className="is-black"
              >
                {this.props.bp.client && this.props.bp.client.name}
              </NavLink>
            </li>
            <li className="is-active">
              <a href="#">{I18n.get("Customizar")}</a>
            </li>
          </ul>
        </nav>

        <div className="columns">
          <div className="column is-12">
            <ClientCustomizeForm {...this.props} />
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientCustomize);
