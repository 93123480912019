import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import { Unlogged } from "../../../../components/UI/Unlogged";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";

import * as S from "./styles";

const HELLO = I18n.get("Hi");
const THANKS = I18n.get("thanks for your registration.");

export const Completed = () => {
  const location = useLocation();
  let history = useHistory();

  const [name, setName] = useState("People");

  const goToRegistration = () => {
    history.push({
      pathname: "/registration-flow/login",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (location?.state?.name) {
      setName(location?.state?.name);
    }
  }, [location]);

  return (
    <Unlogged>
      <div className="container">
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
            <Main bgColor="#fff" padding="30px">
              <Row>
                <Col xs={24}>
                  <S.RowCenter>
                    <S.TitleWithIcon>
                      <S.H4>
                        {I18n.get("Registration completed successfully!")}
                      </S.H4>
                    </S.TitleWithIcon>
                  </S.RowCenter>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <Col xs={24} sm={24}>
                  <S.Thanks>{I18n.get(`${HELLO} ${name}, ${THANKS}`)}</S.Thanks>

                  <S.Action>
                    <ButtonBpool
                      text={I18n.get("Access registration")}
                      theme="primary"
                      full={false}
                      loading={false}
                      onClick={goToRegistration}
                    />
                  </S.Action>
                </Col>
              </Row>
            </Main>
          </Col>
        </Row>
      </div>
    </Unlogged>
  );
};
