import React from "react";
import { I18n } from "aws-amplify";
import Loading from "../../../../../../components/UI/Loading";
import { ProjectTypeNames } from "../../../../../../../model";

import * as S from "../../styles";

export const Informations = ({ isLoading, data }) => {
  const renderItem = ({ label, item }) => {
    return (
      <S.ItemText>
        <p>{`${label}: `}</p>
        <span>{item || "---"}</span>
      </S.ItemText>
    );
  };

  return (
    <S.BoxItem>
      <S.BoxItemTitle>
        <p>{I18n.get("Informações")}</p>
        <span />
      </S.BoxItemTitle>
      {isLoading ? (
        <Loading
          text={I18n.get("Carregando...")}
          sizeText={14}
          sizeIcon={16}
          color="#000000"
          align="left"
        />
      ) : (
        <S.ItemsText>
          {renderItem({ label: I18n.get("Tipo"), item: I18n.get(ProjectTypeNames[data.projectType]) })}
          {renderItem({ label: I18n.get("Nome do Projeto"), item: data?.projectName })}
          {renderItem({ label: I18n.get("Número do Projeto"), item: data?.projectSKU })}
          {renderItem({ label: I18n.get("Produto"), item: data?.product })}
        </S.ItemsText>
      )}
    </S.BoxItem>
  );
};
