import React from 'react';

import { I18n } from 'aws-amplify';

import './star-review.css';

class StarReview extends React.Component {
    constructor(props) {

        super();
    }

    render() {

        return (
            <div className="field star-field is-clearfix">
                <label className="label">
                    {this.props.label}
                </label>
                <div className="control">
                    <fieldset className="rating is-pulled-left">
                        <input onChange={(e) => this.props.onChange(this.props.id, e)} checked={this.props.selected == '5'} type="radio" id={this.props.id + 'star5'} name={'rating_' + this.props.id} value="5" /><label htmlFor={this.props.id + 'star5'} title={I18n.get("Rocks!")}>{I18n.get("5 stars")}</label>
                        <input onChange={(e) => this.props.onChange(this.props.id, e)} checked={this.props.selected == '4'} type="radio" id={this.props.id + 'star4'} name={'rating_' + this.props.id} value="4" /><label htmlFor={this.props.id + 'star4'} title={I18n.get("Pretty good")}>{I18n.get("4 stars")}</label>
                        <input onChange={(e) => this.props.onChange(this.props.id, e)} checked={this.props.selected == '3'} type="radio" id={this.props.id + 'star3'} name={'rating_' + this.props.id} value="3" /><label htmlFor={this.props.id + 'star3'} title={I18n.get("Meh")}>{I18n.get("3 stars")}</label>
                        <input onChange={(e) => this.props.onChange(this.props.id, e)} checked={this.props.selected == '2'} type="radio" id={this.props.id + 'star2'} name={'rating_' + this.props.id} value="2" /><label htmlFor={this.props.id + 'star2'} title={I18n.get("Kinda bad")}>{I18n.get("2 stars")}</label>
                        <input onChange={(e) => this.props.onChange(this.props.id, e)} checked={this.props.selected == '1'} type="radio" id={this.props.id + 'star1'} name={'rating_' + this.props.id} value="1" /><label htmlFor={this.props.id + 'star1'} title={I18n.get("Sucks big time")}>{I18n.get("1 star")}</label>
                    </fieldset>
                </div>
                {this.props.error && <p className="help is-danger">
                    {this.props.error.errorMessage}
                </p>}
            </div>
        );
    }
}

export default StarReview;