import React, { PureComponent } from "react";
import { I18n } from 'aws-amplify';
import { Row, Col, Tag } from 'antd';

import { CardBorder } from "../../components/Cards";
import Carousel from "../../components/Carousel";
import ListLinks from "../../components/ListLinks";

import noPhoto from '../../../image/no-photo2.png';
import CarouselThumbs from "../../components/CarouselThumbs";
import ReactPlayer from 'react-player';

import NotRegistered from "../../components/NotRegistered";

import styled from "styled-components";

const Box = styled.div`

.noMargin {
    div {
        margin-bottom: 0 !important;
    }
}

.TitleView {
    margin-bottom: 40px;
    h3 {
        margin-bottom: 5px;
    }
}

.ant-row-flex {
    margin-bottom: 20px;
}
p {
    font-size: 13px;
    line-height: 16px;
}

h4 {
    font-size: 14px;
    font-weight: bold;
    border-bottom: solid 1px #ccc;
    margin-bottom: 15px;
}

h3 {
    margin: 10px 0 20px;
}

.Sizing {
    width: 100%;
    max-width: 800px;
    margin: 50px auto;
}
    .ItemImg {
        margin: 0 2px;
        overflow: hidden;
        display: block;
        padding: 2px;
        max-width: 122px;

        img {
            width: calc(100% - 4px);
            display: block;
        }
    }
`

class ItemCourse extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const {
            name,
            description,
            videos,
            links,
            images,
        } = this.props.item;

        if (this.props.isPresentation) {
            return (
                <Box>
                    <Row className="TitleView">
                        <Col className="" sm={24}>
                            <h3>{name}</h3>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="" sm={24}>

                            <CarouselThumbs
                                dots={true}
                                dotsClass="slick-dots slick-thumb"
                                infinite={true}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                afterChange={() => {
                                    this.setState({
                                        play: false
                                    });
                                }}
                            >
                                {videos.length <= 0 && images.length <= 0 &&
                                    <div className="ItemImgFull">
                                        <img src={noPhoto} />
                                    </div>
                                }

                                {
                                    videos && videos.length > 0 && videos.map((item, index) => {
                                        return (
                                            <div key={index} className="ItemImgFull">
                                                <ReactPlayer
                                                    playing={this.state.play}
                                                    url={item}
                                                    style={{ margin: '0 auto' }}
                                                    onPause={() => this.setState({ play: false })}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    images.map((item, index) => {
                                        return (
                                            <div key={index} className="ItemImgFull">
                                                <img src={item} />
                                            </div>
                                        )
                                    })
                                }

                            </CarouselThumbs>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="noMargin" sm={24}>
                            <CardBorder

                            >
                                <h4>{I18n.get("Descrição")}</h4>
                                <p>{description}</p>
                            </CardBorder>
                        </Col>
                        {links.length > 0 &&
                            <Col className="noMargin" sm={24}>
                                <CardBorder>
                                    <h4>{I18n.get("Links")}</h4>
                                    <ListLinks data={links} icon="link" />
                                </CardBorder>
                            </Col>
                        }

                    </Row>
                </Box>
            );
        } else {

            return (
                <Box>
                    <Row className="" type="flex">
                        <Col sm={24}>
                            <h3>{name}</h3>
                        </Col>
                    </Row>
                    <Row className="" type="flex">
                        <Col sm={16}>
                            <CardBorder
                                Bcolor="#f2f2f2"
                            >
                                <h4>{I18n.get("Descrição")}</h4>
                                <p>{description}</p>
                            </CardBorder>
                        </Col>
                        <Col sm={8}>
                            <CardBorder
                                Bcolor="#f2f2f2"
                            >
                                <h4>{I18n.get("Links")}</h4>

                                {links.length == 0 &&
                                    <NotRegistered
                                        text="Nenhum link foi cadastrado!"
                                    />
                                }
                                {links.length > 0 &&
                                    <ListLinks data={links} icon="link" />
                                }
                            </CardBorder>
                        </Col>
                    </Row>

                    <Row className="" type="flex">
                        <Col sm={16}>
                            <CardBorder
                                Bcolor="#f2f2f2"
                            >
                                <h4>{I18n.get("Fotos")}</h4>
                                {images.length == 0 &&
                                    <NotRegistered
                                        text="Nenhuma foto foi cadastrada!"
                                    />
                                }
                                {images.length > 0 &&
                                    <div className="Sizing">
                                        <Carousel
                                            dots={false}
                                            infinite={false}
                                            slidesToShow={images.length == 1 ? 1 : 3}
                                            slidesToScroll={1}
                                            variableWidth={false}
                                        >
                                            {images.map((item, index) => {
                                                return (
                                                    <div key={index} className="ItemImg">
                                                        <img src={item} width={images.length == 1 ? "122px" : "90%"} />
                                                    </div>
                                                )
                                            })}

                                        </Carousel>
                                    </div>
                                }
                            </CardBorder>
                        </Col>
                        <Col sm={8}>
                            <CardBorder
                                Bcolor="#f2f2f2"
                            >
                                <h4>{I18n.get("Vídeos")}</h4>

                                {videos.length == 0 &&
                                    <NotRegistered
                                        text="Nenhum vídeo foi cadastrado!"
                                    />
                                }
                                {videos.length > 0 &&
                                    <ListLinks data={videos} icon="youtube" />
                                }
                            </CardBorder>
                        </Col>
                    </Row>


                </Box>
            );
        }
    }
}

export default ItemCourse;
