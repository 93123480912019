import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';
import { I18n } from 'aws-amplify';
import { notification } from 'antd';

import Config from '../../config';

import { BP_ASSETS_PARTNERS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_asset = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };
            return axios.put(`${Config.API_BASE_URL}/bp/partner-asset/update`, data, config)
                .then(response => {
                    const payload = {
                        assetsPartners: response.data,
                        errors: {},
                        task: {
                            result: true
                        }
                    };

                    dispatch({
                        type: BP_ASSETS_PARTNERS_GET,
                        payload: payload
                    });

                    notification.success({
                        message: I18n.get(`Asset atualizado com sucesso!`),
                        description: I18n.get('Você atualizou o asset, não esqueça de aprova-lo.'),
                        duration: 3
                    });

                    return payload;

                })
                .catch(error => {
                    const payload = {
                        errors: error.response.data,
                        task: {
                            result: false
                        }
                    };

                    dispatch({
                        type: BP_ASSETS_PARTNERS_GET,
                        payload: payload
                    });

                    return payload;
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_update_asset;
