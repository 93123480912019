import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { PROJECT_API } from '../../actions/project/actions';
import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedFull';

import styled from "styled-components";


import { Card, Tag } from "antd";

import Board from "react-trello";

const { Meta } = Card;

const MyCard = styled(Card)`
    width: 350px;
    margin-top: 10px;
    text-align: left;

    .ant-tag {
        font-size: 10px;
    }

    .ant-card-body {
        position: relative;

    }

    .date {
        position: absolute;
        right: 10px;
        top: 25px;
         color: #4d4d4d;
         font-size: 10px;
         font-weight: bold;
         opacity: .6;
    }
    .info {
        font-size: 11px;
        color: #4d4d4d;
        white-space: pre-wrap;
        line-height: 12px;
        border-top: solid 1px #eaeaea;
        padding-top: 10px;
        margin-top: 10px;
    }

    .titleCard {
        font-size: 14px;
        color: #4d4d4d;
        white-space: pre-wrap;
        line-height: 16px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .price {
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 5px;
    }
`

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_projects_kanban: () => {
            dispatch(BP_API.get_projects_kanban());
        },
        reset: () => {
            dispatch(PROJECT_API.reset());
        },
    };
};


const CustomCard = props => {
    return (
        <MyCard>
            <div className="titleCard">{props.title}</div>
            <p>
                <Tag color="#000">{props.clientName}</Tag>
                {props.partnerName && <Tag color="#666">{props.partnerName}</Tag>}
            </p>

            {props.price && <p className="price">{props.price}</p>}

            <span className="date">
                {props.label}
            </span>


            <div className="info">
                {props.description}
            </div>
        </MyCard>
    );
};

// /bpool/projetos-kanban

class ClientProjectKanbanAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {
        // this.props.reset();
        this.props.get_projects_kanban();
    }

    render() {
        if (!this.props.bp && !this.props.bp.kanban && !this.props.bp.kanban.lanes.length > 0) return null;

        const data = {
            ...this.props.bp.kanban
        }

        return (
            <LoggedPage {...this.props}>

                <div className="columns">

                    <div className="column is-12">
                        {this.props.bp.kanban &&

                            <Board
                                data={data}
                                style={{ backgroundColor: '#606060' }}
                                components={{ Card: CustomCard }}
                                laneDraggable={false}
                                draggable={false}
                                cardDraggable={false}
                                customCardLayout
                            />
                        }
                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProjectKanbanAdmin);
