import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useSupplier = () => {
  const getSupplier = async (query, isPartner) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    const baseApi = !isPartner ? `supplier` : `partners/bpay`;

    const config = { headers: { Authorization: "Bearer " + token } };

    const dataSetup = await axios.get(
      `${Config.API_BASE_URL}/v2/${baseApi}/setup`,
      config
    );

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/${baseApi}${
            isPartner ? "/bpayments" : "/bpayments"
          }${query}`
        : `${Config.API_BASE_URL}/v2/${baseApi}`,
      config
    );

    return {
      setup: dataSetup?.data?.data,
      data: data?.data,
    };
  };

  const getInfosFormSupplier = async (obj) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/bpay/${obj?.bpayId}/bpayments/${obj?.bpaymentId}/invoice`
    );

    return data;
  };

  const putInvoice = async (infos) => {
    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/bpay/billing/invoice`,
      infos
    );

    return data;
  };

  const putBillingInvoice = async (infos, isPartner) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    const baseApi = !isPartner ? `supplier` : `partners/bpay`;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/${baseApi}/billing/invoice`,
      infos,
      config
    );

    return data;
  };

  const putBanckaccount = async (infos, isPartner) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    const baseApi = !isPartner ? `supplier` : `partners/bpay`;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/${baseApi}/billing/invoice/banckaccount`,
      infos,
      config
    );

    return data;
  };

  const createUserSupplier = async (infos) => {
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/supplier`,
      infos
    );

    return data;
  };

  const createUserSupplierRegister = async (infos) => {
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/supplier`,
      infos
    );

    return data;
  };

  const getTimezones = async () => {
    const { data } = await axios.get(`${Config.API_BASE_URL}/v2/timezones`);
    return data;
  };

  const getPaymentInformations = async (isPartner) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    const baseApi = !isPartner ? `supplier` : `partners/bpay`;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/${baseApi}/payment-informations`,
      config
    );

    return data;
  };

  const getInfosInvoiceSupplier = async (bPaymentId, isPartner) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    const baseApi = !isPartner ? `supplier` : `partners/bpay`;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/${baseApi}/bpayments/${bPaymentId}/invoice`,
      config
    );

    return data;
  };

  const getInfosStatusSteps = async ({ bPayId, bPaymentId }, isPartner) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    const baseApi = !isPartner ? `supplier` : `partners/bpay`;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/${baseApi}/bpayments/${bPaymentId}/invoice-status`,
      config
    );

    return data;
  };

  const getSupplierAlreadyRegister = async ({ bPaymentId }) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/supplier/info-by-bpaymentid/${bPaymentId}`
    );

    return data;
  };

  const getBillingDataSupplier = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/supplier/payment-beneficiary`,
      config
    );

    return data;
  };

  const addBillingDataSupplier = async ({ form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/supplier/payment-beneficiary`,
      form,
      config
    );

    return data;
  };

  const updateBillingDataSupplier = async ({ form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/supplier/payment-beneficiary`,
      form,
      config
    );

    return data;
  };
  const deleteBillingDataSupplier = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/supplier/payment-beneficiary`,
      {
        data: { id },
        ...config,
      }
    );

    return data;
  };

  return {
    getSupplier,
    getInfosFormSupplier,
    putInvoice,
    putBillingInvoice,
    getTimezones,
    createUserSupplier,
    createUserSupplierRegister,
    putBanckaccount,
    getPaymentInformations,
    getInfosInvoiceSupplier,
    getInfosStatusSteps,
    getSupplierAlreadyRegister,
    getBillingDataSupplier,
    addBillingDataSupplier,
    updateBillingDataSupplier,
    deleteBillingDataSupplier,
  };
};
