import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { CLIENT_API } from "../../actions/client/actions";
import { Task } from "../../utils/task";
import FillForm from "../page-templates/fillForm";
import DownloadButton from "../form/download-button-field";
import CheckboxField from "../form/checkbox-field";
import SubmitButtonField from "../form/submit-button-field";
import { scrollToTop } from "../../utils/ui";
import { QueryString } from "../../utils/querystring";

import TermsClientPT from "../../modules/Register/components/_termsClientPT";
import TermsClientES from "../../modules/Register/components/_termsClientES";
import TermsClientEN from "../../modules/Register/components/_termsClientEN";

const mapStateToProps = (state) => {
  return Object.assign(state, state.client);
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirm_terms: (data) => {
      dispatch(CLIENT_API.confirm_terms(data));
    },
    update_responsible_terms: (data) => {
      return dispatch(CLIENT_API.update_responsible_terms(data));
    },
  };
};

class ClientSignupTerms extends React.Component {
  constructor(props) {
    super();

    this.state = {
      locale: "pt",
      form: {
        terms: {
          label: I18n.get("Li e estou de acordo"),
          value: props.terms,
          checked: props.terms,
          onChange: this.onChangeFormCheckbox.bind(this, "terms"),
          error: props.errors.terms,
          successIcon: false,
        },
      },
      task: {},
      errors: {},
      terms_task: {},
    };
  }

  componentWillMount() {
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    this.setState({
      locale: locale,
    });

    I18n.setLanguage(locale);

    const data = {
      verificationToken: this.props.match.params.verificationToken,
    };

    this.props.confirm_terms(data);

    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.client && this.props.client.task;

    if (!prevState) return;
    if (!task) return;

    var nextState = Object.assign({}, prevState);

    if (task.id != prevState.task.id) {
      nextState.task = task;

      this.setState(nextState);
    }
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].checked = checked;
      nextState.form[prop].value = checked;

      return nextState;
    });
  }

  getData() {
    const data = {
      clientId: this.props.clientId,
    };

    return data;
  }

  onSubmitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ terms_task: task });

    const data = {
      verificationToken: this.props.match.params.verificationToken,
      terms: this.state.form.terms.value,
    };

    this.props.update_responsible_terms(data).then(({ task, errors }) => {
      this.setState({ terms_task: task, errors: errors });
    });
  }

  render() {
    let locale = this.state.locale || "pt";

    let clientTerms = "https://assets.bpool.co/termos/ClientTermPT.pdf";

    if (locale === "es") {
      clientTerms = "https://assets.bpool.co/termos/ClientTermES.pdf";
    }

    if (locale === "en") {
      clientTerms = "https://assets.bpool.co/termos/ClientTermEN.pdf";
    }

    return (
      <FillForm>
        <div className="hero-body has-background-white">
          <div className="container">
            <div className="column is-12">
              {this.props.company && this.props.company.name}
            </div>
          </div>
        </div>
        <div className="client-step client-step-6 content-client">
          <div className="container client-container-6">
            <div className="column is-10 is-offset-1">
              <div className="card" style={{ margin: "0.5rem" }}>
                <header className="card-header">
                  <p className="card-header-title" style={{ fontSize: "130%" }}>
                    {I18n.get("Termos Contratuais BPool")}
                  </p>
                </header>
                <div className="card-content">
                  <div className="content content-terms">
                    <form>
                      {locale == "en" && (
                        <>
                          <TermsClientEN />
                        </>
                      )}

                      {locale == "es" && (
                        <>
                          <TermsClientES />
                        </>
                      )}

                      {locale != "en" && locale != "es" && (
                        <>
                          <TermsClientPT />
                        </>
                      )}

                      <br />

                      <DownloadButton
                        LinkToDownload={clientTerms}
                        text="Download"
                      />

                      <br />
                      <br />

                      {!this.props.delegateTermsUpdatedOn && (
                        <div className="columns">
                          <div className="column is-6">
                            <div className="checkBorderLine">
                              <CheckboxField {...this.state.form.terms} />
                            </div>

                            <SubmitButtonField
                              className="button is-black is-fullwidth next"
                              label={I18n.get("ACEITAR")}
                              onClick={this.onSubmitForm.bind(this)}
                              task={this.state.terms_task}
                              suppressLabel={true}
                              disabled={!this.state.form.terms.value}
                            />
                          </div>
                        </div>
                      )}

                      {this.props.delegateTermsUpdatedOn && (
                        <p style={{ fontWeight: "semibold", fontSize: "80%" }}>
                          {I18n.get("Contrato aceito em")}{" "}
                          {this.props.delegateTermsUpdatedOn}.
                        </p>
                      )}

                      <p className="has-text-danger">
                        <ul>
                          {Object.keys(this.state.errors).map((item) => {
                            return (
                              <li>{this.state.errors[item].errorMessage}</li>
                            );
                          })}
                        </ul>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSignupTerms);
