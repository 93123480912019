import React from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Tag } from "antd";
import { compareByAlph } from "../../../../helpers/utils";
import TableBP from "../../../../components/UI/Table";
import Loading from "../../../../components/UI/Loading";

import * as S from "./styles";

export const TableClients = ({ data, sectorsFlat }) => {
  const renderText = (text) => {
    if (text) {
      return <S.Text>{text}</S.Text>;
    } else {
      return <S.Text>---</S.Text>;
    }
  };

  const getSector = (id) => {
    if (sectorsFlat?.length) {
      const sector = sectorsFlat.find((item) => item.code === id);
      return sector?.label;
    } else {
      return (
        <Loading
          text={I18n.get("Carregando...")}
          sizeIcon={12}
          sizeText={12}
          color="#4a4a4a"
        />
      );
    }
  };

  const getBilling = (billing) => {
    if (!billing) {
      return <S.Text>---</S.Text>;
    } else {
      switch (billing.code) {
        case "FIXED_CONTRACT":
          return <Tag color="red">{I18n.get("Fixo")}</Tag>;
          break;
        case "PER_PROJECT_LT_3":
          return <Tag color="gold">{I18n.get("Menor 3meses")}</Tag>;
          break;
        case "PER_PROJECT_GT_3":
          return <Tag color="green">{I18n.get("Maior 3meses")}</Tag>;
          break;
      }
    }
  };

  const columns = [
    {
      title: I18n.get("Company"),
      dataIndex: "label",
      key: "label",
      render: (label, record) => <S.Text>{renderText(label)}</S.Text>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => compareByAlph(a.label, b.label),
    },
  ];

  return (
    <>
      <div className="card">
        <div className="card-content">
          <Row gutter={1}>
            <Col sm={24}>
              <h3 className="title is-4">{I18n.get("Clients")}</h3>
              {data.length > 0 ? (
                <TableBP
                  columns={columns}
                  data={data}
                  isBig={false}
                  pagination={false}
                />
              ) : (
                <div>{I18n.get("Has no customers.")}</div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
