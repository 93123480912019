import React, { PureComponent } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import { formatCurrency } from "../../../utils/helpers";
import Highlighter from "react-highlight-words";
import { Table, Input, Tag, Switch, Button, Icon } from "antd";

import ItemAsset from "./ItemAsset";

const Btns = styled.p`
  width: 50px;
  display: block;

  &.checkItem {
    width: 100px;
  }
  a {
    margin-left: 10px;
  }
`;

class tableAssetsClient extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 300, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small">
          Cancelar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleActive = (e, item) => {
    const data = {
      ...item,
      enabled: e,
    };

    this.props.editActive(data);
  };

  handleIsManHour = (e, item) => {
    const data = {
      ...item,
      isManHour: e,
    };

    this.props.editActive(data);
  };

  handleIsRateCard = (e, item) => {
    const data = {
      ...item,
      isRateCard: e,
    };

    this.props.editActive(data);
  };

  render() {
    const columns = [
      {
        title: I18n.get("Código"),
        dataIndex: "code",
        key: "code",
        width: "150px",
        ...this.getColumnSearchProps("code"),
      },
      {
        title: I18n.get("Nome"),
        dataIndex: "label",
        key: "label",
        ...this.getColumnSearchProps("label"),
      },
      {
        title: I18n.get("Preço"),
        dataIndex: "price",
        key: "price",
        width: "150px",
        render: (price) => {
          return <p>{formatCurrency(price)}</p>;
        },
      },      
      {
        title: I18n.get("Itens Live Marketing"),
        dataIndex: "isRateCard",
        key: "isRateCard",
        width: "150px",
        render: (isRateCard, record) => {
          return (
            <Switch
              loading={this.props.task.busy}
              onChange={(e) => this.handleIsRateCard(e, record.item)}
              checkedChildren="SIM"
              unCheckedChildren="Não"
              checked={isRateCard}
            />
          );
        },
      },
      {
        title: I18n.get("Hora/Homem"),
        dataIndex: "isManHour",
        key: "isManHour",
        width: "150px",
        render: (isManHour, record) => {
          return (
            <Switch
              loading={this.props.task.busy}
              onChange={(e) => this.handleIsManHour(e, record.item)}
              checkedChildren="SIM"
              unCheckedChildren="Não"
              checked={isManHour}
            />
          );
        },
      },
      {
        title: I18n.get("Ativo"),
        dataIndex: "enabled",
        key: "enabled",
        width: "100px",
        render: (enable, record) => {
          return (
            <Switch
              loading={this.props.task.busy}
              onChange={(e) => this.handleActive(e, record.item)}
              checkedChildren="SIM"
              unCheckedChildren="Não"
              checked={enable}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "actions",
        key: "item",
        width: "80px",
        render: (text, record) => {
          return (
            <Btns>
              <a onClick={() => this.props.edit(record.item)}>
                <Icon type="edit" theme="filled" />
              </a>
              <a onClick={() => this.props.delete(record.item.id)}>
                <Icon type="delete" theme="filled" />
              </a>
            </Btns>
          );
        },
      },
    ];

    let data =
      this.props.data &&
      this.props.data.map((item, index) => {
        return {
          key: index,
          code: item.code,
          label: item.label,
          price: item.price,
          isManHour: item.isManHour,
          isRateCard: item.isRateCard,
          enabled: item.enabled,
          item: item,
        };
      });

    return (
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 640 }}
        pagination={false}
        locale={{ emptyText: I18n.get("Sem informação") }}
        expandedRowRender={(record) => (
          <ItemAsset
            item={record.item}
            assetSpecialisms={this.props.assetSpecialisms}
            partnerSpecialismsArray={this.props.partnerSpecialismsArray}
            assetTypeArray={this.props.assetTypeArray}
          />
        )}
      />
    );
  }
}

export default tableAssetsClient;
