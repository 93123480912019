import React, { useState } from 'react';
import { I18n } from 'aws-amplify';
import { Row, Col } from 'antd';
import LoggedPage from '../../../../../components/page-templates/fillForm-logged';
import { ContentPage } from '../../../RegistrationFlow/Full/CompanyDetails/content-page';
import { Menu } from '../../components/Menu';
import { ModalBp } from '../../../../components/UI/ModalBp/ModalBp';

//import * as S from './styles';

export const EditCompanyDetails = () => {
  const [visible, setVisible] = useState(false);

  const handleOkInfos = (status) => {
    if (status) {
      setVisible(true);
    }
  };

  return (
    <>
      <LoggedPage>
        <div className="hero-body">
          <div className="container">
            <Row style={{ marginTop: 58 }}>
              <Col xs={6}>
                <Menu />
              </Col>
              <Col xs={18}>
                <ContentPage
                  contextPage="edit"
                  onChangeInfo={(info) => console.log('change', info)}
                  sendOk={(status) => handleOkInfos(status)}
                />
              </Col>
            </Row>
          </div>
        </div>
      </LoggedPage>

      <ModalBp
        visible={visible}
        bodyText={I18n.get('Saved information.')}
        subBodyText={I18n.get('Your information has been successfully saved.')}
        okText={I18n.get('OK')}
        // cancelText={I18n.get("Cancelar")}
        handleClickOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        // typeModal="isConfirm"
        // isDanger={true}
      />
    </>
  );
};
