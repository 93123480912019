import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Icon, Tooltip, Popconfirm } from "antd";
import { useBpay } from "../../../../../hooks/useBpay/useBpay.hook";
import { Alert } from "../../../../../components/UI/Alert";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import {
  createQueryString,
  parseQueryString,
} from "../../../utils/paramsResolvers";
import { LinkPdf } from "../../../utils/linkPdf";

import * as S from "./styles";
import { colorStatus, colorBgStatus } from "../../../utils/colorsStatus";

export const TableBpays = () => {
  let history = useHistory();
  let location = useLocation();
  const { getBpays, deleteBpay } = useBpay();
  const [isLoadingInternal, setIsLoadingInternal] = useState("");
  const [alertInfoInternal, setAlertInfoInternal] = useState(null);

  const renderText = (text) => {
    if (text) {
      return text;
    } else if (text === 0) {
      return text;
    } else {
      return "---";
    }
  };

  const { data, isLoading, error, refetch } = useQuery(
    ["bpays", createQueryString(parseQueryString(location?.search))],
    () => getBpays(createQueryString(parseQueryString(location?.search))),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar a listagem"),
          duration: 3,
        });
      },
    }
  );

  const handleDeleteBPay = async (id) => {
    try {
      setIsLoadingInternal(id);
      await deleteBpay(id);
      setAlertInfoInternal({
        type: "success",
        message: "BPay cancelado com sucesso",
      });
      refetch();
    } catch (error) {
      setAlertInfoInternal({
        type: "error",
        message: "Erro ao cancelar o BPay",
      });
    } finally {
      setIsLoadingInternal(null);
    }
  };

  const linkTo = ({ status, bpayId }) => {
    if (status === 2) {
      return (
        <Link to={`/cliente/bpay/new/suppliers/${bpayId}`}>
          <S.ButtonIcon>
            <Icon type="edit" />
          </S.ButtonIcon>
        </Link>
      );
    } else if (status === 3) {
      return (
        <Link to={`/cliente/bpay/new/po/${bpayId}`}>
          <S.ButtonIcon>
            <Icon type="edit" />
          </S.ButtonIcon>
        </Link>
      );
    } else if (status === 4) {
      return (
        <Link to={`/cliente/bpay/details/${bpayId}`}>
          <S.ButtonIcon>
            <Icon type="eye" />
          </S.ButtonIcon>
        </Link>
      );
    } else if (status === 5) {
      return (
        <Link to={`/cliente/bpay/finalized/${bpayId}`}>
          <S.ButtonIcon>
            <Icon type="eye" />
          </S.ButtonIcon>
        </Link>
      );
    } else {
      return (
        <Link to={`/cliente/bpay/new/data/${bpayId}`}>
          <S.ButtonIcon>
            <Icon type="edit" />
          </S.ButtonIcon>
        </Link>
      );
    }
  };

  const columns = [
    {
      title: I18n.get("Data da Solicitação"),
      dataIndex: "createdOnFmt",
      width: 150,
      // sorter: true,
      render: (type, record) => (
        <S.RowTab>{renderText(record?.createdOnFmt)}</S.RowTab>
      ),
    },
    {
      title: I18n.get("SKU"),
      dataIndex: "sku",
      width: 150,
      // sorter: true,
      render: (type, record) => <S.RowTab>{renderText(record?.sku)}</S.RowTab>,
    },
    {
      title: I18n.get("Nome do BPay"),
      dataIndex: "name",
      width: 225,
      ellipsis: true,
      render: (type, record) => (
        <S.RowTab>
          <S.RowElipsis>{renderText(record?.name)}</S.RowElipsis>
        </S.RowTab>
      ),
    },
    {
      title: I18n.get("Valor"),
      dataIndex: "budgetFmt",
      width: 150,
      render: (type, record) => (
        <S.RowTab>{renderText(record?.budgetFmt)}</S.RowTab>
      ),
    },
    {
      title: I18n.get("Status"),
      dataIndex: "statusFmt",
      width: 200,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${I18n.get("Solicitação está em")} ${record?.statusFmt}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.Status style={{ ...colorBgStatus(record?.status) }}>
            <span style={{ ...colorStatus(record?.status) }}>
              {renderText(record?.statusFmt)}
            </span>
          </S.Status>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Orçamento"),
      align: "left",
      dataIndex: "id",
      width: 170,
      render: (type, record) => (
        <S.RowTab style={{ justifyContent: "center" }}>
          <S.ButtonIcon
            disabled={record?.pfdLink ? false : true}
            onClick={
              record?.pfdLink ? () => LinkPdf(record?.pfdLink) : () => true
            }
          >
            <Icon type="file-pdf" />
          </S.ButtonIcon>
        </S.RowTab>
      ),
    },
    {
      title: I18n.get("Ações"),
      dataIndex: "id",
      width: 150,
      render: (type, record) => (
        <S.RowTab>
          {linkTo({ status: record?.status, bpayId: record.id })}
          {record?.status === 1 ? (
            record?.isMyBPay ? (
              isLoadingInternal === record.id ? (
                <S.LoadingIcon>
                  <Icon type="loading" />
                </S.LoadingIcon>
              ) : (
                <Popconfirm
                  placement="top"
                  title={I18n.get("Deseja mesmo cancelar?")}
                  onConfirm={() => handleDeleteBPay(record.id)}
                  okText={I18n.get("Sim")}
                  cancelText={I18n.get("Não")}
                >
                  <S.ButtonIcon
                    style={{ marginLeft: 5 }}
                    // onClick={() => handleDeleteBPay(record.id)}
                  >
                    <Icon type="delete" />
                  </S.ButtonIcon>
                </Popconfirm>
              )
            ) : null
          ) : null}
        </S.RowTab>
      ),
    },
  ];

  const handlePagination = (pageNumber) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!pageNumber || pageNumber === 1) {
      delete objQueries.pageNumber;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, pageNumber });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  return (
    <>
      <div style={{ height: 49 }}>
        {alertInfoInternal && (
          <Alert
            message={alertInfoInternal?.message}
            type={alertInfoInternal?.type}
            autoClose
            onClose={() => setAlertInfoInternal(null)}
          />
        )}
      </div>
      <S.Table
        columns={columns}
        dataSource={data?.list}
        pagination={{
          pageSize: 10,
          total: data?.total,
          defaultCurrent: parseQueryString(location?.search)?.pageNumber
            ? Number(parseQueryString(location?.search)?.pageNumber)
            : 1,
          onChange: (page) => {
            handlePagination(page);
          },
        }}
        loading={isLoading}
        locale={{ emptyText: I18n.get("Nenhum BPay cadastrado até o momento") }}
      />
    </>
  );
};
