import React from "react";

import { I18n } from "aws-amplify";

import { ProjectType } from "../model";

import AssetBundle from "./_project-asset-bundle";

import ReviewStep2EventBundle from "./_project-review-2-eventBundle";
import ReviewStep2EventCustom from "./_project-review-2-eventCustom";
import ReviewStep2BPitch from "./_project-review-2-bpitch";

class ReviewStep2 extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  getBundle() {
    return this.props.bundle || {};
  }

  getSelected() {
    return {
      bundleId: this.props.bundleId,
      variationId: this.props.variationId,
    };
  }

  render() {
    const bundle = this.getBundle();

    if (this.props.projectType === ProjectType.Creative) {
      return (
        <>
          <br />
          <br />
          <h1 className="title-bpool small">{I18n.get("Escopo Criativo")}</h1>

          <div className="reviewed-media uplift-review  listBundle">
            <AssetBundle
              {...bundle}
              {...this.props}
              pdfBundle={this.props.pdfBundle || null}
              bp={this.props.bp}
              selected={this.getSelected()}
              isExpanded={true}
              IsClient={this.props.auth.is_client_or_client_user}
              IsPartner={this.props.auth.is_partner}
              PricePartner={this.props.pricePartner}
              PricePartnerTax={this.props.pricePartnerTax}
              PricePartnerOtherTax={this.props.pricePartnerOtherTax}
              PriceClientWithDiscount={this.props.priceClientWithDiscount}
              PriceClientWithDiscountTax={this.props.priceClientWithDiscountTax}
              PriceClientWithDiscountOtherTax={this.props.priceClientWithDiscountOtherTax}
              PriceBPool={this.props.priceBPoolComission}
              PriceBPoolTax={this.props.priceBPoolComissionTax}
              PriceBPoolOtherTax={this.props.priceBPoolComissionOtherTax}
              isFull={true}
              currency={this.props.currency}
              assetTax={this.props.assetTax || 0}
              projectStatus={this.props.status}
              projectAssets={
                this.props.project &&
                this.props.project.bundle &&
                this.props.project.bundle.variations &&
                this.props.project.bundle.variations
              }
            />
          </div>
        </>
      );
    }
    if (this.props.projectType === ProjectType.EventBundle) {
      return <ReviewStep2EventBundle {...this.props} />;
    }
    if (this.props.projectType === ProjectType.EventCustom) {
      return <ReviewStep2EventCustom {...this.props} />;
    }
    if (this.props.isBPitch) {
      return <ReviewStep2BPitch {...this.props} />;
    }

    return null;
  }
}

export default ReviewStep2;
