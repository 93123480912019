import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 15px;
  height: ${(props) => (props.isRating ? '1000px;' : '84px;')};

  touch-action: manipulation;
  position: relative;
  width: 100%;
  color: #000000d9;
  transition: all 0.5s, visibility 0s;
`;

export const ContentNoRating = styled.div`
  position: relative;
  padding-top: 30px;
  display: ${(props) => (props.isRating ? 'none;' : 'flex;')};
  flex-direction: column;
  flex-wrap: nowrap;
  transition: all 0.5s, visibility 0s;
`;

export const ContentIsRating = styled.div`
  position: relative;
  padding-top: 30px;
  display: ${(props) => (props.isRating ? 'flex;' : 'none;')};
  flex-direction: column;
  flex-wrap: nowrap;
  transition: all 0.5s, visibility 0s;
`;

export const Title = styled.div`
  font-family: 'Helvetica Neue LT Std';
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const ListRatings = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;

  li {
    width: 20%;
  }
`;

export const ButtonIcon = styled.button`
  position: relative;
  width: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon-rating-b {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${(props) => (props.active ? '#3283ff' : '#e9e9e9')};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }

  p {
    position: relative;
    cursor: pointer;
    opacity: ${(props) => (props.active ? '1' : '0.5')};
    font-size: 10px;
  }

  :hover {
    .icon-rating-b {
      background: #3283ff;
    }

    p {
      opacity: 1;
    }
  }
`;

export const Textarea = styled.textarea`
  border-radius: 10px;
  background: #e9e9e9;
  border: none;
`;
