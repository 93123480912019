import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff2cf;
  padding: 16px;
  margin-bottom: 40px;

  .sos-flag {
  }
  .sos-infos {
    margin-left: 16px;

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    p {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;
