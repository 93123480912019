import React, { useState } from 'react';
import { Radio, Row, Col } from 'antd';

const RadioGroup = ({
  columns,
  options,
  optionsNoLabel,
  optionDefault,
  onChange,
  style,
}) => {
  const [value, setValue] = useState(
    optionDefault !== null ? optionDefault : null
  );

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <>
      {columns ? (
        <Radio.Group
          onChange={handleChange}
          value={value}
          style={{ width: '100%', ...style }}
        >
          <Row>
            {options.length
              ? options.map((radio, index) => (
                  <Col key={index} xs={24 / columns}>
                    <Radio value={index}>
                      <span
                        style={{ display: optionsNoLabel ? 'none' : 'inherit' }}
                      >
                        {radio}
                      </span>
                    </Radio>
                  </Col>
                ))
              : null}
          </Row>
        </Radio.Group>
      ) : (
        <Radio.Group onChange={handleChange} value={value}>
          {options.length
            ? options.map((radio, index) => (
                <Radio key={index} value={index}>
                  <span
                    style={{ display: optionsNoLabel ? 'none' : 'inherit' }}
                  >
                    {radio}
                  </span>
                </Radio>
              ))
            : null}
        </Radio.Group>
      )}
    </>
  );
};

export default RadioGroup;
