import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../../config";

import { PROJECTS_GET } from "..";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const api_get_projects2 = (data) => {
  return Auth.currentSession()
    .then((session) => {
      const token = session && session.idToken.jwtToken;

      var config = { headers: { Authorization: "Bearer " + token } };

      var queryString = Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&");

      return axios
        .get(`${Config.API_BASE_URL}/v2/client-project?${queryString}`, config)
        .then((response) => {
          return {
            type: PROJECTS_GET,
            payload: {
              projects: response.data.data.result,
              pagination: response.data.data.pagination,
              errors: {},
              task: {
                result: true,
              },
            },
          };
        })
        .catch((error) => {
          return {
            type: PROJECTS_GET,
            payload: {
              errors: {},
              task: {
                result: false,
              },
            },
          };
        });
    })
    .catch((error) => {});
};
