import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useExtras = () => {
  const getExtras = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/extras`,
      config
    );

    return data;
  };

  const getExtraById = async ({ projectId, extraId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/extras/${extraId}`,
      config
    );

    return data;
  };

  const getExtraTypes = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/extras/types`,
      config
    );

    return data;
  };

  const postExtra = async ({ isClient, dataForm }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.post(
      isClient
        ? `${Config.API_BASE_URL}/v2/client-project/extras`
        : `${Config.API_BASE_URL}/v2/client-project/partner/extras`,
      dataForm,
      config
    );

    return data;
  };

  const deleteExtra = async (info) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const dataSend = {
      projectId: info.projectId,
      extraId: info.extraId,
    };

    const data = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project/extras`,
      {
        data: dataSend,
        ...config,
      }
    );

    return data;
  };

  const getBudgetExtraById = async ({ projectId, extraId, isClient }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      isClient
        ? `${Config.API_BASE_URL}/v2/client-project/${projectId}/extras/${extraId}/orcamento`
        : `${Config.API_BASE_URL}/v2/client-project/${projectId}/partner/extras/${extraId}/orcamento`,
      config
    );

    return data;
  };

  const postItemExtra = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project/extras/quotations/items`,
      dataForm,
      config
    );

    return data;
  };

  const deleteItemExtra = async (dataDelete) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project/extras/quotations/items`,
      {
        data: dataDelete,
        ...config,
      }
    );

    return data;
  };

  const deleteAssetTypeAssetExtra = async (dataDelete) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project/partner/extras/quotations/assets`,
      {
        data: dataDelete,
        ...config,
      }
    );

    return data;
  };

  const postExportToExcel = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project/extras/quotations/export`,
      dataForm,
      config
    );

    return data;
  };

  const putPercentsGeneralCosts = async ({ dataForm }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/extras/fees-and-taxes`,
      dataForm,
      config
    );

    return data;
  };

  const partnerSendToApproval = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/extras/quotations/partnersendtoapproval`,
      dataForm,
      config
    );

    return data;
  };

  const clientSendToApproval = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/extras/quotations/approve-refused`,
      dataForm,
      config
    );

    return data;
  };

  const putDetailsExtra = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/extras`,
      dataForm,
      config
    );

    return data;
  };

  const getRatingsExtra = async (projectId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/extras/get-ratings`,
      config
    );

    return data;
  };

  const postPoExtra = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project/extras/billings`,
      dataForm,
      config
    );

    return data;
  };

  const putAddAssetsInExtra = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/partner/extras/quotations/assets`,
      dataForm,
      config
    );

    return data;
  };

  const putFinalizeExtra = async (dataForm) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/extras/finalize`,
      dataForm,
      config
    );

    return data;
  };

  const getExtraDetails = async ({ projectId, extraId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/extras/details/${extraId}`,
      config
    );

    return data;
  };

  const updateCodePoExtra = async (dataPo) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/extras/billings/updatePOCode`,
      dataPo,
      config
    );

    return data;
  };

  return {
    getExtras,
    getExtraById,
    getExtraTypes,
    postExtra,
    deleteExtra,
    getBudgetExtraById,
    postItemExtra,
    deleteItemExtra,
    deleteAssetTypeAssetExtra,
    postExportToExcel,
    putPercentsGeneralCosts,
    partnerSendToApproval,
    clientSendToApproval,
    putDetailsExtra,
    getRatingsExtra,
    postPoExtra,
    putAddAssetsInExtra,
    putFinalizeExtra,
    getExtraDetails,
    updateCodePoExtra,
  };
};
