import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useInProgress = () => {
  const getFiles = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/file`,
      config
    );

    return data;
  };

  const uploadFile = async (file) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/file`,
      file,
      config
    );

    return data;
  };

  const addUrl = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/completionUrls`,
      infos,
      config
    );

    return data;
  };

  const addUrls = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/upload-list-completionUrls`,
      infos,
      config
    );

    return data;
  };

  const deleteUrl = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project/urls`,
      {
        data: infos,
        ...config,
      }
    );

    return data;
  };

  const deleteFile = async (info) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const dataSend = {
      id: info.id,
      projectId: info.projectId,
    };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project/file`,
      {
        data: dataSend,
        ...config,
      }
    );

    return data;
  };

  const completeProject = async (id) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project/partner/complete-project`,
      {
        id: id,
      },
      config
    );

    return data;
  };

  const getCompletion = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/completion-files`,
      config
    );

    return data;
  };

  return {
    getFiles,
    uploadFile,
    addUrl,
    addUrls,
    deleteUrl,
    deleteFile,
    completeProject,
    getCompletion,
  };
};
