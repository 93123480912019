import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';
import { notification } from 'antd';
import { I18n } from 'aws-amplify';


import Config from '../../config';

import { BP_PROJECT_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const update_status_project = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.put(`${Config.API_BASE_URL}/bp/admin/project-change-status`,data, config)
            .then(response => {
                dispatch({
                    type: BP_PROJECT_GET,
                    payload: {
                        project: response.data,
                        task: { result: true }
                    }
                });

                notification.success({
                    message: I18n.get('Atualizado com sucesso!'),
                    description: ""
                  });
            })
            .catch(error => {
                notification.error({
                    message: I18n.get('Ops, algo deu errado!'),
                    description: I18n.get("Tente novamente mais tarde")
                  });
                throw (error);
            });

        }).catch(error => { 
            // dispatch({ type: EXPIRED }); 
        }); 
    };
}

export default update_status_project;