import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

export default class Footer extends React.Component {

    constructor(props) {
        super();
        this.state = {};
    }

    render() {

        const ano = new Date().getFullYear();

        return (
            <footer className="footer">
                            <div className="container">
                                <div className="level">
                                    <p className="copy-text level-left">{ano} © BPool</p>
                                    <ul className="footer-menu level-right">

                                        {this.props.auth.is_client && this.props.client
                                            && <li><a target="_blank" href={this.props.client.clientTerm}>{I18n.get("Termos de uso & Política de ética e práticas anticorrupção")}</a></li>}

                                        {this.props.auth.is_partner && this.props.partner
                                            && <li><a target="_blank" href={this.props.partner.partnerTerm}>{I18n.get("Termos de uso & Política de ética e práticas anticorrupção")}</a></li>}


                                    </ul>
                                </div>
                            </div>
                    </footer>
        );

    }
}

