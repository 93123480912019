import styled from "styled-components";

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const WrapperCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;

  div {
    :first-of-type {
      margin-left: 0;
    }
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 150px;

  a,
  button {
    margin-left: 10px;
  }
`;

export const WrapperSteps = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  div {
    ::before {
      width: 85%;
      height: 2px;
      background: #000;
      position: absolute;
      top: 9px;
      content: "";
      display: block;
      left: 55%;
      margin-left: -45%;
    }
  }
`;
