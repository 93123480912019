import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { I18n } from 'aws-amplify';

import * as S from './styles';

export const ItemWithTitle = ({ children, title, titleExpand = false }) => {
  const [expand, setExpand] = useState(true);

  const handleExpand = () => {
    setExpand(expand ? false : true);
  };

  const renderTitle = () => {
    if (titleExpand) {
      return (
        <S.TitleButtonSectionResume onClick={handleExpand}>
          <span>{title}</span>
          <S.IconsItemWithTitle expand={expand}>
            <DownOutlined />
          </S.IconsItemWithTitle>
        </S.TitleButtonSectionResume>
      );
    } else {
      return (
        <S.TitleSectionResume>
          <span>{title}</span>
        </S.TitleSectionResume>
      );
    }
  };

  return (
    <S.WrapperItemWithTitle>
      {renderTitle()}
      <S.ContentItemWithTitle titleExpand={titleExpand} expanded={expand}>
        {children}
      </S.ContentItemWithTitle>
    </S.WrapperItemWithTitle>
  );
};
