import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import CheckboxMultiField from '../form/checkbox-multi-field';
import TextAreaField from '../form/textarea-field';
import SubmitButtonField from '../form/submit-button-field';

import { scrollToTop } from '../../utils/ui';

class ProjectMessagingForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            fields: {
                body: {
                    label: I18n.get("Digite uma mensagem"),
                    successIcon: true,
                    onChange: this.onChangeForm.bind(this, 'body'),
                    visited: true
                }
            },
            errors: {},
            form: {
            },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
    }


    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;
            nextState.feedback = null;

            return nextState;
        });
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            recipientId: this.props.recipientId,
            ...this.state.form
        };

        return data;
    }

    submitForm() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task, feedback: '' });

        this.props.sendUserMessage(this.getData())
            .then(({ task = {}, errors = {} }) => {

                this.setState((prevState) => {

                    var nextState = Object.assign({}, prevState);

                    nextState.submit_task = task;
                    nextState.errors = errors;

                    if (task.result) {
                        nextState.feedback = 'Mensagem enviada.';
                        nextState.form.body = '';
                    }

                    return nextState;
                });

                if (this.props.get_partner) {
                    this.props.get_partner();
                }

                if (this.props.get_partners) {

                    const data = {
                        projectId: this.props.match.params.projectId
                    };

                    this.props.get_partners(data);
                }

            });
    }

    render() {

        const { userMessages = [] } = this.props;

        return (
            <div style={{ marginTop: '1rem' }}>

                <div style={{ padding: '1rem', maxHeight: '50vh', overflowY: 'auto' }}>
                    {userMessages && userMessages.map((msg, index) => {

                        const senderIsClient = this.props.auth.is_client_or_client_user && msg.profile && msg.profile.startsWith('Client');
                        const senderIsPartner = this.props.auth.is_partner && msg.profile && msg.profile.startsWith('Partner');



                        const align = (senderIsClient || senderIsPartner) ? 'right' : 'left';
                        const color = (senderIsClient || senderIsPartner) ? '#bab1d1' : '#ffffff';

                        const msgContainerStyle = {
                            marginTop: '0.5rem',
                            padding: '1rem',
                            textAlign: align,

                        };

                        const msgBodyStyle = {
                            display: 'inline-block',
                            backgroundColor: color,
                            padding: '0.5rem',
                            borderRadius: '0.25rem',
                            fontSize: '9pt',
                            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                        };

                        const msgTimeStyle = {
                            marginTop: '0.5rem',
                            fontSize: '8pt',
                            color: '#cccccc',
                        }

                        return (<div style={{ marginTop: '0.5rem' }}>

                            <div style={msgContainerStyle}>
                                <div style={msgBodyStyle}>
                                    {msg.body}
                                </div>

                                <div style={msgTimeStyle}>
                                    {msg.createdOn} &middot; {msg.senderName}
                                </div>
                            </div>

                        </div>)
                    })}

                    {userMessages && userMessages.length == 0 && <>

                        <p style={{ fontSize: '9pt', color: '#cccccc' }}>{I18n.get("Nenhuma mensagem.")}</p>

                    </>}

                </div>

                <div style={{ marginTop: '2rem' }}>

                    <TextAreaField {...this.state.fields.body} value={this.state.form.body} error={this.state.errors.body} />

                    <p style={{ padding: '1rem', fontSize: '9pt' }}>{this.state.feedback}</p>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <SubmitButtonField
                            label={I18n.get("ENVIAR")}
                            onClick={this.submitForm.bind(this)}
                            task={this.state.submit_task}
                        />

                    </div>

                </div>

            </div>
        );
    }
}

export default ProjectMessagingForm;
