const renderValue = (value) => {
  if (value || value === 0 || value === "0") {
    return value;
  } else {
    return "---";
  }
};

export const converData = (data) => {
  return {
    totalBPayments: renderValue(data?.budgetFmt),
    totalBPaymentsFinishedFmt: renderValue(data?.totalBPaymentsFinishedFmt),
    totalBPaymentsInProgressFmt: renderValue(data?.totalBPaymentsInProgressFmt),
  };
};
