import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Task } from "../../utils/task";
import { ClientProjectStatus } from "../../model";

import { PROJECT_API } from "../../actions/project/actions";

import ViewMacros from "../../modules/components/ViewMacros";

import TimelineChart from "../shared/timeline-chart";

import moment from "moment";

const mapStateToProps = (state) => {
  return Object.assign({}, state, { ...state.project });
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_project: (data) => {
      dispatch(PROJECT_API.get(data));
    },
    create_checkpoint: (data) => {
      dispatch(PROJECT_API.create_checkpoint(data));
    },
    delete_checkpoint: (data) => {
      dispatch(PROJECT_API.delete_checkpoint(data));
    },
    update_checkpoint: (data) => {
      dispatch(PROJECT_API.update_checkpoint(data));
    },
    update_checkpoint_status: (data) => {
      return dispatch(PROJECT_API.update_checkpoint_status(data));
    },
    update_checkpoint_change: (data) => {
      dispatch(PROJECT_API.update_checkpoint_change(data));
    },
    create_milistones: (data) => {
      dispatch(PROJECT_API.create_milistones(data));
    },
    delete_milistones: (data) => {
      dispatch(PROJECT_API.delete_milistones(data));
    },
    get_milistones: (projectId) => {
      dispatch(PROJECT_API.get_milistones(projectId));
    },
    update_milestones_mensal: (data) => {
      dispatch(PROJECT_API.update_milestones_mensal(data));
    },
    upload_milestone: (data) => {
      dispatch(PROJECT_API.upload_milestone(data));
    },
    delete_file_milestone: (data) => {
      dispatch(PROJECT_API.delete_file_milestone(data));
    },
    confirm_milestone: (data) => {
      dispatch(PROJECT_API.confirm_milestone(data));
    },
  };
};

class ScheduleForm extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {
    this.props.get_milistones(this.props.match.params.projectId);
  }

  render() {
    const { project = {} } = this.props;

    const bPitch = project.bPitch || {};

    const canEdit =
      !(
        this.props.project &&
        this.props.project.status == ClientProjectStatus.DefinitionsSet
      ) && !this.props.project.partnerHasSubmittedDefinitions;

    const timelineData = [];

    bPitch.schedule &&
      bPitch.schedule.map((item) => {
        if (!item.code) return;

        if (item.scheduleType == "PERIOD") {
          const startDate = moment(item.startDate, "DD/MM/YYYY");
          const endDate = moment(item.endDate, "DD/MM/YYYY");

          const record = [
            // item.code,
            item.label,
            startDate.toDate(),
            endDate.toDate(),
            // null,
            // 0,
            // null
          ];

          timelineData.push(record);
        } else {
          const scheduleDate = moment(item.scheduleDate, "DD/MM/YYYY");

          const record = [
            // item.code,
            item.label,
            scheduleDate.toDate(),
            scheduleDate.add("days", 1).toDate(),
            // null,
            // 0,
            // null
          ];

          timelineData.push(record);
        }
      });

    return (
      <div>
        <h1 className="title title-2">
          <span>2.</span> {I18n.get("Macro Entregas")}
        </h1>

        <ViewMacros
          project={this.props.project}
          create={this.props.create_milistones}
          delete={this.props.delete_milistones}
          update={this.props.update_milestones_mensal}
          uploadMilestone={this.props.upload_milestone}
          isPartner={this.props.auth.is_partner}
          errors={this.props.errors && this.props.errors}
          disabled={canEdit}
          username={this.props.auth.username}
          deleteFile={this.props.delete_file_milestone}
          confirmMilestone={this.props.confirm_milestone}
          loading={{}}
        />

        {!this.props.isSuggestion && (
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="field is-grouped is-grouped-centered">
                <p className="control is-expanded" onClick={this.scrollToTop}>
                  <Link
                    to={`/projetos/${this.props.projectId}/detalhamento-passo-1`}
                    className="button bt-bpool grey is-fullwidth  previous"
                  >
                    {I18n.get("Voltar")}
                  </Link>
                </p>
                <p className="control is-expanded" onClick={this.scrollToTop}>
                  <Link
                    to={`/projetos/${this.props.projectId}/detalhamento-passo-3`}
                    className="button bt-bpool black is-fullwidth  next"
                  >
                    {I18n.get("Próximo")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}

        {this.props.isSuggestion && (
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="field is-grouped is-grouped-centered">
                <p className="control is-expanded" onClick={this.scrollToTop}>
                  <Link
                    to={`/parceiro/criar-projeto/briefing/${this.props.projectId}`}
                    className="button bt-bpool grey is-fullwidth  previous"
                  >
                    {I18n.get("Voltar")}
                  </Link>
                </p>
                <p className="control is-expanded" onClick={this.scrollToTop}>
                  <Link
                    to={`/parceiro/criar-projeto/assets/${this.props.projectId}`}
                    className="button bt-bpool black is-fullwidth  next"
                  >
                    {I18n.get("Próximo")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScheduleForm));
