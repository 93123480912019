export const localeValidation = (locale) => {
  const localeLowerCase = locale.toLowerCase();

  if (
    localeLowerCase === "pt-br" ||
    localeLowerCase === "br" ||
    localeLowerCase === "pt"
  ) {
    return "pt";
  } else if (localeLowerCase === "es") {
    return "es";
  } else if (localeLowerCase === "en") {
    return "en";
  } else {
    return "pt";
  }
};
