import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { CLIENT_PROJECTS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_projects = () => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.get(`${Config.API_BASE_URL}/client/projects`, config)
            .then(response => {
                dispatch({
                    type: CLIENT_PROJECTS_GET,
                    payload: {
                        projects: response.data,
                        errors: {},
                        task: {
                            result: true
                        }
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: CLIENT_PROJECTS_GET,
                    payload: {
                        errors: {},
                        task: {
                            result: false
                        }
                    }
                })
            });
}).catch(error => { 
 dispatch({ type: EXPIRED }); 
 }); 
 };
}

export default api_get_projects;