import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const TitleContent = styled.div`
  padding: 0 15px;
  margin: 0 0 30px 0;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const AlertPage = styled.div`
  margin: 0 15px 30px 15px;
`;

export const Form = styled.div`
  padding-bottom: 90px;
`;

export const ViewTaxes = styled.div`
  height: 70px;
  position: relative;
  top: -10px;
  left: 5px;
`;

export const TitleTaxes = styled.div`
  display: flex;
  align-items: center;
  h3 {
    font-size: 14px;
    font-weight: 600;
  }
  i {
    cursor: pointer;
    margin-left: 5px;
    position: relative;
    top: -3px;
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-input {
    height: 50px;
    margin-bottom: 30px;

    li {
      height: 100%;
    }
  }
`;
