import { useState, useLayoutEffect } from "react";

export class MediaQueryDimensions {
  constructor() {
    MediaQueryDimensions.w = 0;
    MediaQueryDimensions.h = 0;
  }
}

export const SIZE_MOBILE = 768;

export const IS_MOBILE = () =>{
  return MediaQueryDimensions.w<SIZE_MOBILE;
};

export const IS_DESKTOP = () =>{
  return MediaQueryDimensions.w>=SIZE_MOBILE;
};


export function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
      MediaQueryDimensions.w = window.innerWidth;
      MediaQueryDimensions.h = window.innerHeight;
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screenSize;
}
