import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';
import { I18n } from 'aws-amplify';

import {
    notification
} from 'antd';

import Config from '../../config';

import { PARTNER_REFERENCE_DELETE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_partner_send_reference = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/partner/${data.partnerId}/reference/${data.id}/send`, config)
                .then(response => {

                    dispatch({
                        type: PARTNER_REFERENCE_DELETE,
                        payload: response.data
                    });

                    notification.success({
                        message: I18n.get('Enviado com sucesso!'),
                        description: I18n.get('O aviso foi enviado com sucesso.'),
                        duration: 3
                    });

                })
                .catch(error => {

                    dispatch({
                        type: PARTNER_REFERENCE_DELETE,
                        payload: {
                            reference: {
                                errors: error.response.data
                            }
                        }
                    });
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_partner_send_reference;
