import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import ReactPlayer from "react-player";
import { Row, Col, message, Collapse, Alert, Icon, Tooltip } from "antd";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { useRegistrationFlow } from "../../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";
import { useCertificates } from "../../../../hooks/useCertificates/useCertificates.hook";
import { urlsFull as urls } from "../../utils/stepsUrls";
import { ItemWithTitle } from "./itemWithTitle";
import { ItemCarousel } from "./itemCarousel";
import { ListCertificates } from "./listCertificates";
import starIcon from "./star.svg";
import tooltipIcon from "./tooltip-diversity.svg";

import * as S from "./styles";
// import noProfilePicture from './user-img-empty.jpg';

const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const MESSAGE_ERROR = "There was an error, try again.";
const PAGE_INDEX = 17;

const { Panel } = Collapse;

export const ContentPage = ({ contextPage, onChangeInfo, sendOk }) => {
  let history = useHistory();
  const { getResume, finishFullRegister } = useRegistrationFlowFull();
  const { getDiversityAndInclusionSetup } = useRegistrationFlow();
  const { getCertificatesPartner } = useCertificates();
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [dataPage, setDataPage] = useState({});
  const [dataCases, setDataCases] = useState([]);
  const [dataAwards, setDataAwards] = useState([]);
  const [errorsFinish, setErrorsFinish] = useState([]);
  const [dataDiversity, setDataDiversity] = useState([]);
  const [isLoadingCertificates, setIsLoadingCertificates] = useState(false);
  const [dataCertificates, setDataCertificates] = useState([]);

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const renderItemDiversity = (itemDiversity) => {
    const arrString = [];
    if (itemDiversity?.selectedLeadership) {
      if (itemDiversity?.childrenLeadership?.length) {
        const arrStringChildren = itemDiversity.childrenLeadership
          .filter((item) => item?.selected)
          .map((item) => item.label);
        if (arrStringChildren.length > 0) {
          arrString.push(`Liderado - ${arrStringChildren.join(", ")}`);
        } else {
          arrString.push("Liderado");
        }
      } else {
        arrString.push("Liderado");
      }
    }
    if (itemDiversity?.selectedOwned) {
      if (itemDiversity?.childrenOwned?.length) {
        const arrStringChildren = itemDiversity.childrenOwned
          .filter((item) => item?.selected)
          .map((item) => item.label);
        if (arrStringChildren.length > 0) {
          arrString.push(`Controlado - ${arrStringChildren.join(", ")}`);
        } else {
          arrString.push("Controlado");
        }
      } else {
        arrString.push("Controlado");
      }
    }
    return arrString.length > 0 ? arrString.join(", ") : "";
  };

  const prepareDescriptions = (description) => {
    const desc = description.split("LED:");
    const descOwned = desc[0].split("OWNED:");

    return { descriptionOwned: descOwned[1], descriptionLed: desc[1] };
  };

  const getCertificates = async () => {
    try {
      setIsLoadingCertificates(true);
      const response = await getCertificatesPartner();
      setDataCertificates(response?.data);
    } catch (error) {
      console.log("error");
    } finally {
      setIsLoadingCertificates(false);
    }
  };

  const verifiyIfDiversityVisible = (arr) => {
    return arr?.some((item) => item?.selectedLeadership || item?.selectedOwned);
  };

  const loadInit = async () => {
    setIsLoading(true);

    const response = await getResume();
    if (response.success) {
      setDataPage(response.data);
      const cases = [];
      response.data.specialityProofsEndorsement.map(
        (item) =>
          item?.cases?.length &&
          item.cases.map((itemCase) => cases.push(itemCase))
      );
      setDataCases(cases);
      const awards = [];
      response.data.awards.map((itemAward) => awards.push(itemAward));
      setDataAwards(awards);

      const responseSetup = await getDiversityAndInclusionSetup({
        admin: false,
      });
      const childrensArr = [];

      if (responseSetup.success) {
        const dataFormatted = responseSetup.data.map((item) => ({
          code: item.code,
          label: item.label,
          ...prepareDescriptions(item.description),
          selectedLeadership:
            response?.data?.company?.diversityLeadership?.find(
              (itemDiversity) => itemDiversity === item.code
            )
              ? true
              : false,
          selectedOwned: response?.data?.company?.diversityOwned?.find(
            (itemDiversity) => itemDiversity === item.code
          )
            ? true
            : false,
          childrenLeadership: item.children
            ? item.children.map((childItem) => ({
                label: childItem.label,
                code: childItem.code,
                selected: response?.data?.company?.diversityLeadership?.find(
                  (item) => item === childItem.code
                )
                  ? true
                  : false,
              }))
            : null,
          childrenOwned: item.children
            ? item.children.map((childItem) => ({
                label: childItem.label,
                code: childItem.code,
                selected: response?.data?.company?.diversityOwned?.find(
                  (item) => item === childItem.code
                )
                  ? true
                  : false,
              }))
            : null,
        }));
        setDataDiversity(dataFormatted);

        responseSetup.data.map((item) => ({
          children: item.children
            ? item.children.map((childItem) =>
                childrensArr.push({
                  label: childItem.label,
                  code: childItem.code,
                })
              )
            : null,
        }));
      }
      await getCertificates();
    } else {
      message.error(I18n.get(MESSAGE_ERROR));
    }

    setIsLoading(false);
  };

  const send = async () => {
    setIsSending(true);

    const response = await finishFullRegister();

    if (response.success) {
      history.push({
        pathname: "/registration-flow/full/completed",
      });
    } else {
      const errors = [];
      Object.keys(response?.errors).forEach(function (key, index) {
        errors.push({
          ...response?.errors[key],
          field: response?.errors[key].field.toLowerCase(),
        });
      });
      setErrorsFinish(errors);
    }

    setIsSending(false);
  };

  const renderVideoOrImage = ({ video, image }) => {
    if (image) {
      return <img src={image} alt="Intro Image" />;
    } else if (!image && video) {
      return (
        <ReactPlayer
          // className="responsive-video"
          url={video}
          playing={true}
          loop={true}
          controls={false}
          muted={true}
          style={{ margin: "0 auto" }}
        />
      );
    } else if (!image && !video) {
      return <S.EmptyItem>{I18n.get("No informations.")}</S.EmptyItem>;
    }
  };

  const itemsShowToWidth = {
    2: "50%",
    3: "33.333333%",
  };

  const countItemsShowWIdth = (item) => {
    const width = itemsShowToWidth[item];
    return width;
  };

  const checkVideoService = (url) => {
    // Expressão regular para verificar se a URL é do YouTube
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)/;

    // Expressão regular para verificar se a URL é do Vimeo
    const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)/;

    if (url) {
      if (url.match(youtubeRegex)) {
        return "video";
      } else if (url.match(vimeoRegex)) {
        return "video";
      } else {
        return "no-video";
      }
    } else {
      return "no-video";
    }
  };

  const checkImage = (url) => {
    if (
      url.toLowerCase().indexOf(".jpeg") !== -1 ||
      url.toLowerCase().indexOf(".jpg") !== -1 ||
      url.toLowerCase().indexOf(".png") !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const imageBox = {
    objectFit: "cover",
    width: "300px",
    height: "156px",
  };

  const renderCase = (item) => {
    const checkLink = checkVideoService(item?.link);
    const checkImageUrl = item?.link && checkImage(item?.link);
    const linkImage = item?.linkImage;

    return (
      <div>
        {checkLink === "video" ? (
          <ReactPlayer
            // className="responsive-video"
            url={item.link}
            playing={true}
            loop={true}
            controls={false}
            muted={true}
            width="auto"
            height="auto"
            style={{ margin: "0 auto" }}
          />
        ) : (
          <>
            {linkImage ? (
              <a href={item.link} target="_blank">
                <img src={item.linkImage} alt="" style={imageBox} />
              </a>
            ) : checkImageUrl ? (
              <a href={item.link} target="_blank">
                <img src={item.link} alt="" style={imageBox} />
              </a>
            ) : (
              <a href={item.link} target="_blank">
                <S.ImageEmpty>
                  <Icon type="link" style={{ color: "#000" }} />
                </S.ImageEmpty>
              </a>
            )}
          </>
        )}
        <S.InfosItemCase>
          <div>
            {I18n.get("Name")}: {item?.name}
          </div>
          <div>
            {I18n.get("Date")}: {item?.year}
          </div>
          <div>
            {I18n.get("Brand")}: {item?.brand}
          </div>
        </S.InfosItemCase>
      </div>
    );
  };
  const renderAward = (item) => {
    const checkLink = checkVideoService(item?.link);
    const checkImageUrl = item?.link && checkImage(item?.link);

    return (
      <div>
        {checkLink === "video" ? (
          <ReactPlayer
            // className="responsive-video"
            url={item.link}
            playing={true}
            loop={true}
            controls={false}
            muted={true}
            width="auto"
            height="auto"
            style={{ margin: "0 auto" }}
          />
        ) : (
          <>
            {checkImageUrl ? (
              <a href={item.link} target="_blank">
                <img src={item.link} alt="" />
              </a>
            ) : (
              <a href={item.link} target="_blank">
                <S.ImageEmpty>
                  <Icon type="link" style={{ color: "#000" }} />
                </S.ImageEmpty>
              </a>
            )}
          </>
        )}
        <S.InfosItemCase>
          <div>
            {I18n.get("Name")}: {item?.name}
          </div>
          <div>
            {I18n.get("Year")}: {item?.year}
          </div>
          <div>
            {I18n.get("Participants")}: {item?.participantsUsersEmail?.length}
          </div>
        </S.InfosItemCase>
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("Profile View")}</S.H4>
              </S.TitleWithIcon>
              {/* <S.Paragraph>{I18n.get("XXX")}</S.Paragraph> */}
            </S.RowCenter>
          </Col>
        </Row>

        {isLoading ? (
          <Row>
            <Col xs={24}>
              <S.ContentIsLoading>
                <Loading
                  text={I18n.get("Loading informations...")}
                  sizeText={14}
                  sizeIcon={16}
                />
              </S.ContentIsLoading>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col xs={24}>
                <S.Header>
                  <S.HeaderImage>
                    {dataPage?.company?.headerImage ? (
                      <img
                        src={dataPage.company.headerImage}
                        alt="Header image"
                      />
                    ) : null}
                  </S.HeaderImage>
                  <S.LogoImage>
                    <S.BackLogo>
                      {dataPage?.company?.logoImage ? (
                        <img
                          src={dataPage.company.logoImage}
                          alt="Logo image"
                        />
                      ) : null}
                    </S.BackLogo>
                  </S.LogoImage>
                </S.Header>
              </Col>
              <Col xs={24}>
                <S.TitleSection>{dataPage?.company?.name}</S.TitleSection>
              </Col>
              <Col xs={24}>
                <S.IntroVideoOrImage>
                  {renderVideoOrImage({
                    video: dataPage?.company?.introVideo,
                    image: dataPage?.company?.introImage,
                  })}
                </S.IntroVideoOrImage>
              </Col>
              <Col xs={24}>
                <ItemWithTitle title={I18n.get("About")}>
                  {dataPage?.company?.description ? (
                    <S.AboutText>{dataPage?.company?.description}</S.AboutText>
                  ) : (
                    <S.EmptyItem>{I18n.get("No informations.")}</S.EmptyItem>
                  )}
                </ItemWithTitle>
              </Col>
              <Col xs={24}>
                <ItemWithTitle title={I18n.get("Problems that company solves")}>
                  {dataPage?.company?.description ? (
                    <S.AboutText>
                      {dataPage?.company?.problemSolves}
                    </S.AboutText>
                  ) : (
                    <S.EmptyItem>{I18n.get("No informations.")}</S.EmptyItem>
                  )}
                </ItemWithTitle>
              </Col>
              <Col xs={24}>
                <ItemWithTitle title={I18n.get("Competitive differentiators")}>
                  {dataPage?.company?.description ? (
                    <S.AboutText>
                      {dataPage?.company?.competitiveDifferentiators}
                    </S.AboutText>
                  ) : (
                    <S.EmptyItem>{I18n.get("No informations.")}</S.EmptyItem>
                  )}
                </ItemWithTitle>
              </Col>
              <Col xs={24}>
                <ItemWithTitle title={I18n.get("Specialities")}>
                  {dataPage?.businessTypesDescription?.length ? (
                    <S.Specialities>
                      {dataPage?.businessTypesDescription?.map(
                        (type, index) => (
                          <li key={`${index}-${type}`}>
                            <img src={starIcon} />
                            <span>{type}</span>
                          </li>
                        )
                      )}
                    </S.Specialities>
                  ) : (
                    <S.EmptyItem>{I18n.get("No informations.")}</S.EmptyItem>
                  )}
                </ItemWithTitle>
              </Col>
              <Col xs={24}>
                <ItemWithTitle title={I18n.get("Customers")} titleExpand>
                  {dataPage?.clients?.length ? (
                    <S.CustomersShow>
                      {dataPage?.clients?.map((client, index) => (
                        <li key={`${index}-${client.name}`}>
                          <span>{client.name}</span>
                        </li>
                      ))}
                    </S.CustomersShow>
                  ) : (
                    <S.EmptyItem>{I18n.get("No informations.")}</S.EmptyItem>
                  )}
                </ItemWithTitle>
              </Col>
              {verifiyIfDiversityVisible(dataDiversity) ? (
                <Col xs={24}>
                  <ItemWithTitle title={I18n.get("Diversidade & Inclusão")}>
                    <p>
                      {I18n.get(
                        "Essa empresa trabalha ativamente para a igualdade de gênero no local de trabalho."
                      )}
                    </p>
                    <p>
                      {I18n.get(
                        "Essa é a composição da sociedade e liderança da empresa."
                      )}
                    </p>
                    <div>
                      {dataDiversity?.length
                        ? dataDiversity?.map((item) =>
                            item?.selectedLeadership || item?.selectedOwned ? (
                              <S.ItemDiversityView key={item?.code}>
                                <strong>{item?.label}:</strong>
                                <span style={{ marginLeft: 8 }}>
                                  {renderItemDiversity(item)}
                                </span>
                                <Tooltip
                                  placement="top"
                                  overlayClassName="tooltip-diversity"
                                  title={
                                    <div>
                                      {item?.selectedLeadership ? (
                                        <>
                                          <p style={{ marginBottom: 8 }}>
                                            <strong>
                                              {I18n.get("Liderado")}
                                            </strong>
                                          </p>
                                          <p>{item?.descriptionLed}</p>
                                        </>
                                      ) : null}
                                      {item?.selectedOwned ? (
                                        <>
                                          <p
                                            style={{
                                              marginTop:
                                                item?.selectedLeadership &&
                                                item?.selectedOwned
                                                  ? 32
                                                  : 0,
                                              marginBottom: 8,
                                            }}
                                          >
                                            <strong>
                                              {I18n.get("Controlado")}
                                            </strong>
                                          </p>
                                          <p>{item?.descriptionOwned}</p>
                                        </>
                                      ) : null}
                                    </div>
                                  }
                                >
                                  <img src={tooltipIcon} />
                                </Tooltip>
                              </S.ItemDiversityView>
                            ) : null
                          )
                        : null}
                    </div>
                  </ItemWithTitle>
                </Col>
              ) : null}

              {dataCertificates?.length ? (
                <Col xs={24}>
                  <ItemWithTitle title={I18n.get("Certificados Técnicos")}>
                    <div>
                      <ListCertificates
                        data={dataCertificates}
                        isLoading={isLoadingCertificates}
                      />
                    </div>
                  </ItemWithTitle>
                </Col>
              ) : null}
              <Col xs={24}>
                <ItemWithTitle title={I18n.get("Meet the leaders")} titleExpand>
                  {dataPage?.users?.length ? (
                    <ItemCarousel id="leaders">
                      {dataPage?.users?.map((item, index) => (
                        <S.LeaderItem
                          key={index}
                          maxItemsShow={countItemsShowWIdth(2)}
                          id={`slide-${index + 1}`}
                        >
                          <S.LeaderProfilePicture>
                            {item?.profilePicture ? (
                              <img
                                src={item?.profilePicture}
                                alt={`${item.name} photo`}
                              />
                            ) : (
                              <img
                                src="/img/user-img-empty.jpg"
                                alt={`${item.name} no photo`}
                              />
                            )}
                          </S.LeaderProfilePicture>
                          <S.LeaderItemInfos>
                            <strong>{item.name}</strong>
                            <p>{item.userProfileDescription}</p>
                            <p className="leader-mail">{item.email}</p>
                          </S.LeaderItemInfos>
                        </S.LeaderItem>
                      ))}
                    </ItemCarousel>
                  ) : (
                    <S.EmptyItem>{I18n.get("No informations.")}</S.EmptyItem>
                  )}
                </ItemWithTitle>
              </Col>
              <Col xs={24}>
                <ItemWithTitle title={I18n.get("Cases")}>
                  {dataCases?.length ? (
                    <ItemCarousel id="cases">
                      {dataCases.map((item, index) => (
                        <S.CaseItem key={`${index}-${item.brand}`}>
                          {renderCase(item)}
                        </S.CaseItem>
                      ))}
                    </ItemCarousel>
                  ) : (
                    <S.EmptyItem>{I18n.get("No informations.")}</S.EmptyItem>
                  )}
                </ItemWithTitle>
              </Col>
              <Col xs={24}>
                <ItemWithTitle title={I18n.get("Awards")}>
                  {dataAwards?.length ? (
                    <ItemCarousel id="awards">
                      {dataAwards.map((item, index) => (
                        <S.CaseItem key={`${index}-${item.brand}`}>
                          {renderAward(item)}
                        </S.CaseItem>
                      ))}
                    </ItemCarousel>
                  ) : (
                    <S.EmptyItem>{I18n.get("No informations.")}</S.EmptyItem>
                  )}
                </ItemWithTitle>
              </Col>
            </Row>
          </>
        )}

        {errorsFinish?.length ? (
          <Row style={{ marginTop: 80 }}>
            <Col lg={24}>
              {errorsFinish.map((item, index) => {
                return (
                  <>
                    <a href={`/#${urls[item?.step]}`}>
                      <Alert
                        message={item.errorMessage}
                        type="error"
                        showIcon
                        style={{ marginBottom: 10 }}
                      />
                    </a>
                  </>
                );
              })}
            </Col>
          </Row>
        ) : null}

        {contextPage === "full" ? (
          <Row style={{ marginTop: 30 }}>
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get("Back")}
                theme="secondary"
                full
                onClick={handleBackPage}
                disabled={isLoading}
              />
            </Col>
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get("Finalize")}
                theme="primary"
                full
                loading={isSending}
                onClick={send}
                disabled={isLoading}
              />
            </Col>
          </Row>
        ) : null}
      </Main>
    </>
  );
};
