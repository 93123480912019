import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import LoggedPage from "../page-templates/logged";

import { CLIENT_API } from "../../actions/client/actions";

import Menu from "./_menu";

import FormResponsibleClient from "../../modules/Register/components/formResponsibleClient";

import ResponsibleForm from "./responsible-form";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (data) => {
      dispatch(CLIENT_API.update_person(data));
    },
  };
};

class ResponsibleAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  render() {
    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/home">{I18n.get("Admin")}</Link>
            </li>
            <li className="is-active">
              <Link to="/cadastro">{I18n.get("Cadastro")}</Link>
            </li>
          </ul>
        </nav>

        <div className="columns">
          <div className="column is-3">
            <Menu {...this.props} active={1} />
          </div>

          <div className="column is-8">
            <h1 className="title">{I18n.get("Dados do responsável")}</h1>

            {/* <ResponsibleForm {...this.props} isAdmin={true} /> */}

            <FormResponsibleClient
              client={this.props.client && this.props.client}
              clientId={this.props.client.clientId}
              action={this.props.save}
              errors={this.props.client.errors && this.props.client.errors}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
              edit={true}
            />
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleAdmin);
