import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Wrapper = styled.div`
  position: relative;

  .ant-select {
    .ant-select-selection {
      background: #ffffff;
      color: #515152;
      border: solid 1px;
      border-color: ${(props) => (props.errorSelect ? "#ff0000" : "#999999")};
      border-radius: 10px;
      height: 50px;
      padding: 0 15px;

      .ant-select-selection__rendered {
        margin: 7.5px 0 0 0;
      }

      .ant-select-arrow {
        top: 8px;
        color: #999999;
      }
    }
  }
`;

// isFocused={isFocused}
export const PlaceHolder = styled.div`
  position: absolute;
  z-index: 9;
  top: ${(props) => (props.valueSelected ? "-5px" : "18px")};
  left: ${(props) => (props.valueSelected ? "5px" : "15px")};
  font-weight: 400;
  font-size: ${(props) => (props.valueSelected ? "12px" : "14px")};
  font-weight: ${(props) => (props.valueSelected ? "700" : "400")};
  color: ${(props) => (props.isFocused ? "#1883ff" : "#515152")};
  background-color: #fff;
  padding: 0 5px;
  pointer-events: none;
  transition: all 280ms;

  span {
    color: #f00;
    font-size: ${(props) => (props.valueSelected ? "12px" : "16px")};
    padding-right: 2px;
  }
`;

export const FieldError = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-input {
    margin-top: 0;
    height: 50px;

    li {
      height: 100%;
    }
  }
`;
