import React from "react";
import { I18n } from "aws-amplify";
import { Redirect } from "react-router-dom";

import { Task } from "../../utils/task";
import { PROJECT_API } from "../../actions/project/actions";

import ProjectContainer from "../../containers/project-container";

import { ClientProjectStatus } from "../../model";

import FillForm from "../page-templates/fillForm-logged";

import ProjectPOForm from "../shared/project-po-form";

import BillingItem from "../shared/billing-item";

import Steps from "./steps";

import { scrollToTop } from "../../utils/ui";

import LogoButton from "../form/logo-button";

import FormatCurrency from "../ui/format-currency";

class ProjectDefintionStep5 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      delete_task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    //this.props.get_assets({});
    scrollToTop();
  }

  onDelete(billingId, e) {
    const { projectId } = this.props.match.params;

    e.preventDefault();

    var data = {
      projectId: projectId,
      billingId: billingId,
    };

    var delete_task = new Task();

    delete_task.start();

    this.setState({ delete_task: delete_task });

    this.props.delete_billing(data).then(({ task, errors }) => {
      this.setState({ delete_task: task, errors: errors });
    });
  }

  onSubmitForm(e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      status: "Started",
    };

    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_project_status(data).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  handleEditPo = (data) => {
    return this.props.update_po(data).then((response) => {
      if (response) {
        let data = {
          clientId: this.props.client.clientId,
          projectId: this.props.match.params.projectId,
        };
        this.props.get_project(data);

        return true;
      } else {
        return false;
      }
    });
  };

  render() {
    const { billings = [], currency = {}, billingCurrency = {} } = this.props;

    const canDelete =
      this.props.auth.is_master || this.props.auth.is_client_or_client_user;

      console.log("this.props.auth", this.props.auth);


    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container">
            <div className="columns is-mobile">
              <div
                className="column is-6"
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="title">
                  {I18n.get("Projeto em Andamento")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6">
              <Steps
                      active={5}
                      project={this.props.project}
                      terms={this.props.clientTermsUpdatedOn}
                    />
              </div>
            </div>
          </div>
        </div>

        <div className="client-step client-step-1 project-definition">
          <div className="container client-container-1">
            <h1 className="title title-4">{I18n.get("#PO")}</h1>

            <br />
            <br />

            <p>
              <strong>{I18n.get("Valores")}:</strong>{" "}
              {this.props.hasBillingCurrency && (
                <FormatCurrency
                  amount={this.props.convertedPriceClientWithDiscount}
                  currency={billingCurrency}
                />
              )}
              {!this.props.hasBillingCurrency && (
                <>{this.props.PriceClientWithDiscountFmt}</>
              )}{" "}
            </p>

            <div
              className="displayValores"
              style={{ background: "#fff", margin: "15px 0 40px" }}
            >
              <p style={{ fontWeight: "normal" }}>
                <span>{I18n.get("Parcial:")} </span>
                <br />
                <span>{this.props?.priceClientWithDiscountFmt}</span>
              </p>

              <p>
                <span style={{ fontWeight: "normal" }}>
                  {I18n.get("Total")}: <br />
                  <span
                    style={{
                      fontSize: 11,
                      marginTop: "-10px",
                      fontWeight: "normal",
                    }}
                  >
                    {this.props.otherTax > 0
                      ? `(${I18n.get("incluyendo gross up")} ${
                          this.props.otherTax
                        }%)`
                      : ""}
                  </span>
                </span>
                <b>{this.props?.priceClientWithDiscountWithOtherTaxFmt}</b>{" "}
              </p>

              {this.props?.assetTax > 0 && (
                <p style={{ fontWeight: "normal", border: 0 }}>
                  <span>{I18n.get("Impostos")} </span>
                  (IVA {this.props?.assetTax}%):{" "}
                  <span>{this.props?.priceClientWithDiscountTaxFmt}</span>
                </p>
              )}
            </div>

            <br />
            <br />

            <p>{I18n.get("Abaixo estão as #PO cadastradas para o projeto.")}</p>

            {billings.map((billing) => (
              <div style={{ marginTop: "2rem" }}>
                <div className="card">
                  <div
                    className="card-content"
                    style={{ paddingTop: "30px", paddingBottom: "30px" }}
                  >
                    <BillingItem
                      billing={billing}
                      currency={billingCurrency}
                      onDelete={
                        canDelete && this.onDelete.bind(this, billing.id)
                      }
                      projectId={this.props.match.params.projectId}
                      updatePo={this.handleEditPo}
                      isAdmin={this.props.auth.is_master_or_bpool} 
                    />
                  </div>
                </div>
              </div>
            ))}

            <br />

            {billings && billings.length == 0 && (
              <>
                <p style={{ fontSize: "80%", color: "#909090" }}>
                  {I18n.get("Nenhuma #PO.")}
                </p>
              </>
            )}

            <br />

            <p>
              <strong>{I18n.get("Saldo:")}</strong>{" "}
              <FormatCurrency
                amount={this.props.billingPOBalance}
                currency={billingCurrency}
              />
            </p>

            <br />

            {this.state.show == false && (
              <div style={{ marginTop: "1rem" }}>
                <button
                  onClick={(e) => this.setState({ show: true })}
                  className="button bt-bpool black is-outlined"
                >
                  {I18n.get("Adicionar #PO")}
                </button>
              </div>
            )}

            {this.state.show == true && (
              <div style={{ paddingTop: "2rem" }}>
                <a
                  onClick={(e) => this.setState({ show: false })}
                  className="is-pulled-right"
                >
                  <span className="icon">
                    <i className="fas fa-times" aria-hidden="true"></i>
                  </span>
                </a>

                <ProjectPOForm
                  {...this.props}
                  onClose={(e) => this.setState({ show: false })}
                />
              </div>
            )}

            <br />

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(ProjectDefintionStep5);
