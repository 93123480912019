import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const create_scope = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.post(`${Config.API_BASE_URL}/bp/admin/KnowledgeBaseSection`, data, config)
                .then(response => {
                    dispatch({
                        type: BP_UPDATE,
                        payload: {
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    })
                   return true
                })
                .catch(error => {
                    dispatch({
                        type: BP_UPDATE,
                        payload: {
                            errors: error.response.data.errors,
                            task: {
                                result: false
                            }
                        }
                    })

                    return false;
                });

        }).catch(error => {
            // dispatch({ type: EXPIRED });
        });
    };
}

export default create_scope;