import React from "react";
import { I18n } from "aws-amplify";

import Steps from "../../../../../components/UI/Steps";

const StepsCreateProject = ({ active, disabledTabs = [], projectId }) => {
  const stepItens = [
    {
      key: 1,
      label: I18n.get("Informações"),
      url: `/cliente/projetos/v2/criar-projeto/${projectId}`,
    },
    {
      key: 2,
      label: I18n.get("Tipo"),
      url: `/cliente/projetos/v2/selecionar-assets/${projectId}`,
    },
    {
      key: 3,
      label: I18n.get("Demandas Menu"),
      url: `/cliente/projetos/v2/demandas/${projectId}`,
    },
    {
      key: 4,
      label: I18n.get("Parceiros"),
      url: `/cliente/projetos/v2/parceiros/${projectId}`,
    },
    {
      key: 5,
      label: I18n.get("Checkout"),
      url: `/cliente/projetos/v2/checkout/${projectId}`,
    },
    {
      key: 6,
      label: I18n.get("Revisão"),
      url: `/cliente/projetos/v2/review/${projectId}`,
    },
  ];

  return (
    <Steps itens={stepItens} active={active} disabledTabs={disabledTabs} />
  );
};

export default StepsCreateProject;
