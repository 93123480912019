import React from "react";
import { connect } from "react-redux";

import { PROJECT_API } from "../actions/project/actions";
import { CLIENT_API } from "../actions/client/actions";
import { CLIENT_WHATIF_API } from "../actions/client-whatif/actions";
import { BP_API } from "../actions/bp/actions";

import { scrollToTop } from "../utils/ui";

export default function ProjectContainer(Component) {
  const mapStateToProps = (state) => {
    return Object.assign(
      state,
      { clientId: state.client.clientId },
      state.project
    );
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      load_client: () => {
        return dispatch(CLIENT_API.get());
      },
      get_industries: () => {
        return dispatch(BP_API.get_industries());
      },
      get_project: (id) => {
        return dispatch(PROJECT_API.get(id));
      },
      get_assets: (data) => {
        dispatch(PROJECT_API.get_assets(data));
      },
      get_assets_combo: (data) => {
        dispatch(PROJECT_API.get_assets_combo(data));
      },
      get_bundles: (data) => {
        dispatch(PROJECT_API.get_bundles(data));
      },
      get_partners: (data) => {
        dispatch(PROJECT_API.get_partners(data));
      },
      checkout: (data) => {
        dispatch(PROJECT_API.checkout(data));
      },
      addProject: (data) => {
        dispatch(PROJECT_API.create(data));
      },
      update_project_status: (data) => {
        return dispatch(PROJECT_API.update_project_status(data));
      },
      reset: () => {
        dispatch(PROJECT_API.reset());
      },
      update_definitions: (data) => {
        return dispatch(PROJECT_API.update_definitions(data));
      },
      create_checkpoint: (data) => {
        dispatch(PROJECT_API.create_checkpoint(data));
      },
      delete_checkpoint: (data) => {
        dispatch(PROJECT_API.delete_checkpoint(data));
      },
      update_checkpoint: (data) => {
        dispatch(PROJECT_API.update_checkpoint(data));
      },
      upload_file: (data) => {
        return PROJECT_API.upload_file(data);
      },
      delete_file: (data) => {
        dispatch(PROJECT_API.delete_file(data));
      },
      update_briefing: (data) => {
        dispatch(PROJECT_API.update_briefing(data));
      },
      end: (data) => {
        dispatch(PROJECT_API.end(data));
      },
      save: (data) => {
        return dispatch(PROJECT_API.update(data));
      },
      update_custom_event: (data) => {
        return dispatch(PROJECT_API.update_custom_event(data));
      },
      addEventQuotationAsset: (data) => {
        return dispatch(PROJECT_API.create_event_quotation_asset(data));
      },
      delEventQuotationAsset: (data) => {
        dispatch(PROJECT_API.delete_event_quotation_asset(data));
      },
      updateEventQuotationAsset: (data) => {
        dispatch(PROJECT_API.update_event_quotation_asset(data));
      },
      addEventQuotationItem: (data) => {
        return dispatch(PROJECT_API.create_event_quotation_item(data));
      },
      addEventQuotationItemComment: (data) => {
        return dispatch(PROJECT_API.create_event_quotation_item_comment(data));
      },
      delEventQuotationItemComment: (data) => {
        return dispatch(PROJECT_API.delete_event_quotation_item_comment(data));
      },
      sendEventQuotationItemNotification: (data) => {
        return dispatch(
          PROJECT_API.send_event_quotation_item_notification(data)
        );
      },
      updateEventQuotationItemStatus: (data) => {
        return dispatch(PROJECT_API.update_event_quotation_item_status(data));
      },
      delEventQuotationItem: (data) => {
        dispatch(PROJECT_API.delete_event_quotation_item(data));
      },
      updateEventQuotationTax: (data) => {
        dispatch(PROJECT_API.update_event_quotation_tax(data));
      },
      uploadEventQuotationfile: (data) => {
        return PROJECT_API.upload_event_quotation_file(data);
      },
      uploadEventQuotationfile2: (data) => {
        return PROJECT_API.upload_event_quotation_file2(data);
      },
      deleteEventQuotationFile: (data) => {
        dispatch(PROJECT_API.delete_event_quotation_file(data));
      },
      create_event_quotation_milestone: (data) => {
        return dispatch(PROJECT_API.create_event_quotation_milestone(data));
      },
      delete_event_quotation_milestone: (data) => {
        return dispatch(PROJECT_API.delete_event_quotation_milestone(data));
      },
      updateEventQuotation: (data) => {
        return dispatch(PROJECT_API.update_event_quotation(data));
      },
      updateEventPartners: (data) => {
        return dispatch(PROJECT_API.update_event_partners(data));
      },
      updateEventQuotationStatus: (data) => {
        return dispatch(PROJECT_API.update_event_quotation_status(data));
      },
      exportEventQuotation: (data) => {
        return dispatch(PROJECT_API.export_event_quotation(data));
      },
      cancel: (data) => {
        dispatch(PROJECT_API.cancel(data));
      },
      add_project_idea: (data) => {
        dispatch(CLIENT_WHATIF_API.add_project_idea(data));
      },
      updateTerms: (data) => {
        return dispatch(PROJECT_API.update_terms(data));
      },
      getPreferredPartners: (data) => {
        return dispatch(PROJECT_API.get_preferred_partners(data));
      },
      sendPartnerMessage: (data) => {
        return dispatch(PROJECT_API.send_partner_message(data));
      },
      sendUserMessage: (data) => {
        return dispatch(PROJECT_API.send_user_message(data));
      },
      createQuestion: (data) => {
        return dispatch(PROJECT_API.create_question(data));
      },
      updateQuestion: (data) => {
        return dispatch(PROJECT_API.update_question(data));
      },
      deleteQuestion: (data) => {
        return dispatch(PROJECT_API.delete_question(data));
      },
      create_billing: (data) => {
        return dispatch(PROJECT_API.create_project_billing(data));
      },
      update_billing: (data) => {
        return dispatch(PROJECT_API.update_project_billing(data));
      },
      delete_billing: (data) => {
        return dispatch(PROJECT_API.delete_project_billing(data));
      },
      upload_billing_file: (data) => {
        return PROJECT_API.upload_billing_file(data);
      },
      delete_billing_file: (data) => {
        return dispatch(PROJECT_API.delete_billing_file(data));
      },
      updatePartnerResponsible: (data) => {
        return dispatch(PROJECT_API.update_partner_responsible(data));
      },
      getPartnerResponsible: (data) => {
        return dispatch(PROJECT_API.get_partner_responsible(data));
      },
      create_milistones: (data) => {
        dispatch(PROJECT_API.create_milistones(data));
      },
      delete_milistones: (data) => {
        dispatch(PROJECT_API.delete_milistones(data));
      },
      get_milistones: (projectId) => {
        dispatch(PROJECT_API.get_milistones(projectId));
      },
      update_milestones_mensal: (data) => {
        dispatch(PROJECT_API.update_milestones_mensal(data));
      },
      upload_filev2: (data) => {
        dispatch(PROJECT_API.upload_filev2(data));
      },
      delete_filev2: (data) => {
        dispatch(PROJECT_API.delete_filev2(data));
      },
      update_bpitch: (data) => {
        return dispatch(PROJECT_API.update_bpitch(data));
      },
      upload_bpitch_file: (data) => {
        return dispatch(PROJECT_API.upload_bpitch_file(data));
      },
      delete_bpitch_file: (data) => {
        return dispatch(PROJECT_API.delete_bpitch_file(data));
      },
      update_bpitch_partner: (data) => {
        return dispatch(PROJECT_API.update_bpitch_partner(data));
      },
      delete_extra: (data) => {
        dispatch(PROJECT_API.delete_extra(data));
      },
      create_partner_contact: (data) => {
        return dispatch(PROJECT_API.create_partner_contact(data));
      },
      delete_partner_contact: (data) => {
        return dispatch(PROJECT_API.delete_partner_contact(data));
      },
      update_commission: (data) => {
        return dispatch(PROJECT_API.update_commission(data));
      },
      createMeetingInvite: (data) => {
        return dispatch(PROJECT_API.create_meeting_invite(data));
      },
      delete_meeting: (data) => {
        return dispatch(PROJECT_API.delete_meeting(data));
      },
      create_universal_quotation_section: (data) => {
        return dispatch(PROJECT_API.create_universal_quotation_section(data));
      },
      update_universal_quotation_section: (data) => {
        return dispatch(PROJECT_API.update_universal_quotation_section(data));
      },
      delete_universal_quotation_section: (data) => {
        return dispatch(PROJECT_API.delete_universal_quotation_section(data));
      },
      create_universal_quotation_asset: (data) => {
        return dispatch(PROJECT_API.create_universal_quotation_asset(data));
      },
      update_universal_quotation_asset: (data) => {
        return dispatch(PROJECT_API.update_universal_quotation_asset(data));
      },
      delete_universal_quotation_asset: (data) => {
        return dispatch(PROJECT_API.delete_universal_quotation_asset(data));
      },
      create_universal_quotation_section: (data) => {
        return dispatch(PROJECT_API.create_universal_quotation_section(data));
      },
      create_universal_quotation_item: (data) => {
        return dispatch(PROJECT_API.create_universal_quotation_item(data));
      },
      delete_universal_quotation_item: (data) => {
        return dispatch(PROJECT_API.delete_universal_quotation_item(data));
      },
      create_universal_quotation_generic_item: (data) => {
        return dispatch(
          PROJECT_API.create_universal_quotation_generic_item(data)
        );
      },
      delete_universal_quotation_generic_item: (data) => {
        return dispatch(
          PROJECT_API.delete_universal_quotation_generic_item(data)
        );
      },
      create_universal_quotation_perc_item: (data) => {
        return dispatch(PROJECT_API.create_universal_quotation_perc_item(data));
      },
      delete_universal_quotation_perc_item: (data) => {
        return dispatch(PROJECT_API.delete_universal_quotation_perc_item(data));
      },
      create_universal_quotation_hour_item: (data) => {
        return dispatch(PROJECT_API.create_universal_quotation_hour_item(data));
      },
      delete_universal_quotation_hour_item: (data) => {
        return dispatch(PROJECT_API.delete_universal_quotation_hour_item(data));
      },
      update_universal_quotation: (data) => {
        return dispatch(PROJECT_API.update_universal_quotation(data));
      },
      update_universal_quotation_tax: (data) => {
        return dispatch(PROJECT_API.update_universal_quotation_tax(data));
      },
      update_universal_quotation_status: (data) => {
        return dispatch(PROJECT_API.update_universal_quotation_status(data));
      },
      create_universal_quotation_milestone: (data) => {
        return dispatch(PROJECT_API.create_universal_quotation_milestone(data));
      },
      delete_universal_quotation_milestone: (data) => {
        return dispatch(PROJECT_API.delete_universal_quotation_milestone(data));
      },
      upload_universal_quotation_file: (data) => {
        return PROJECT_API.upload_universal_quotation_file(data);
      },
      delete_universal_quotation_file: (data) => {
        return dispatch(PROJECT_API.delete_universal_quotation_file(data));
      },
      export_universal_quotation: (data) => {
        return dispatch(PROJECT_API.export_universal_quotation(data));
      },
      create_universal_quotation_item_comment: (data) => {
        return dispatch(
          PROJECT_API.create_universal_quotation_item_comment(data)
        );
      },
      delete_universal_quotation_item_comment: (data) => {
        return dispatch(
          PROJECT_API.delete_universal_quotation_item_comment(data)
        );
      },
      update_universal_quotation_item_status: (data) => {
        return dispatch(
          PROJECT_API.update_universal_quotation_item_status(data)
        );
      },
      send_universal_quotation_item_notification: (data) => {
        return dispatch(
          PROJECT_API.send_universal_quotation_item_notification(data)
        );
      },
      get_types: (data) => {
        return dispatch(PROJECT_API.get_types(data));
      },
      get_tmarket: (data) => {
        return dispatch(PROJECT_API.get_tmarket(data));
      },
      update_po: (data) => {
        return dispatch(PROJECT_API.update_po(data));
      },
      upload_milestone: (data) => {
        dispatch(PROJECT_API.upload_milestone(data));
      },
      update_global: (data) => {
        return dispatch(PROJECT_API.update_global(data));
      },
      get_diversity: () => {
        return dispatch(PROJECT_API.get_diversity());
      },
      projectThreasholdAuthorization: (data) => {
        return dispatch(PROJECT_API.projectThreasholdAuthorization(data));
      },
    };
  };

  class ProjectContext extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      scrollToTop();

      if (!this.props.match.params.projectId) {
        this.props.reset();
      }

      if (
        this.props.match.params.projectId &&
        this.props.projectId != this.props.match.params.projectId
      ) {
        var data = {
          clientId: this.props.client.clientId,
          projectId: this.props.match.params.projectId,
        };

        var dataBundle = {
          projectId: this.props.match.params.projectId,
        };
        this.props.get_project(data);
        this.props.get_assets(dataBundle);
        this.props.get_bundles({});
        this.props.getPreferredPartners(data);
      }

      if (!this.props.project.assets || !this.props.project.assets.length) {
        var data = {};

        if (this.props.match.params.projectId) {
          data = {
            projectId: this.props.match.params.projectId,
          };
        }

        this.props.get_assets(data);
      }

      if (!this.props.project.bundles || !this.props.project.bundles.length) {
        this.props.get_bundles({});
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    componentWillUnmount() {}

    render() {
      return <Component {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ProjectContext);
}
