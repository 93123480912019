import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { connect } from 'react-redux';

import WhatifWrapper from '../components/WhatifWrapper';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import HeaderThumbCover from '../components/HeaderThumbCover';
import Loading from '../../../components/pages/loading';

import IdeaInfos from '../components/IdeaInfos';

import { IDEA_WHATIF_API } from '../../../actions/idea-whatif/actions';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_idea_detail: data => {
            dispatch(IDEA_WHATIF_API.get_idea_detail(data));
        }
    };
};


const Main = styled.div`
  padding-top: 0px;
  padding-bottom: 100px;
  color: #353535;
`;

class IdeaRegisterPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
 
        const data = {
            id: this.props.match.params.whatIfIdeaId,
            prefix: "PartnerWhatIf"
        }
        setTimeout(() => {
            this.props.get_idea_detail(data);
        }, 10);

    }



    render() {

        if (!this.props.ideaWhatIf.ideaDetail) {
            return (<Loading />);
        }

        if (!this.props.ideaWhatIf.ideaDetail.partner) {
            return (<Loading />);
        }
        const {
            match: {
                params: {
                    whatIfId,
                }
            }
        } = this.props;

        return (
            <WhatifWrapper>
                <HeaderThumbCover
                    thumb={this.props.ideaWhatIf.ideaDetail.partner.imageLogo ? this.props.ideaWhatIf.ideaDetail.partner.imageLogo : ""}
                    bgHeader={this.props.ideaWhatIf.ideaDetail.partner.imageIntro ? this.props.ideaWhatIf.ideaDetail.partner.imageIntro : ""}
                    link={`/parceiro-ideias/cadastro/ideia/${whatIfId}/editar/${this.props.ideaWhatIf.ideaDetail.whatIfIdeaId}`}
                />
                <ContainerPage>
                    <Main>
                        <IdeaInfos
                            counterProposal={true}
                            idea={this.props.ideaWhatIf.ideaDetail && this.props.ideaWhatIf.ideaDetail}
                            isPartner
                        />
                    </Main>
                </ContainerPage>
            </WhatifWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdeaRegisterPreview);
