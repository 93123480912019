import React from 'react';

import { I18n } from 'aws-amplify';

import moment from 'moment';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import RadioMultiField from '../form/radio-multi-field';
import TextField from '../form/text-field';
import SubmitButtonField from '../form/submit-button-field';

import { ClientProjectUniversalQuotationSectionType } from '../../model';

class QuotationSectionDeleteForm extends React.Component {
    constructor(props) {

        super(props);

        const section = props.section || {};

        const { sectionName, sectionType } = section;

        this.state = {
            fields: {
                sectionType: {
                    onChange: this.onChangeForm.bind(this, 'sectionType'),
                    successIcon: false,
                    visited: true,
                    isHorizontal: false
                },
                sectionName: {
                    onChange: this.onChangeForm.bind(this, 'sectionName'),
                    successIcon: false,
                    visited: true,
                },
            },
            form: {
                sectionName,
                sectionType
            },
            errors: {},
            submit_task: {},
        };
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    getData() {

        const quotation = this.props.quotation || {};
        const section = this.props.section || {};

        var data = {
            projectId: this.props.match.params.projectId,
            quotationId: quotation.id,
            sectionId: section.id,
            ...this.state.form
        };

        return data;
    }

    submitForm(e) {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.delete_universal_quotation_section(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

                if (task.result) {

                    this.setState({ form: {} });

                    this.props.closeModal();
                }
            });
    }

    render() {

        const section = this.props.section || {};

        let sectionTypes = [
            { code: ClientProjectUniversalQuotationSectionType.Assets, label: I18n.get("Itens Internos") },
            { code: ClientProjectUniversalQuotationSectionType.Items, label: I18n.get("Itens Externos") },
            { code: ClientProjectUniversalQuotationSectionType.GenericItems, label: I18n.get("Itens Livre") },
            { code: ClientProjectUniversalQuotationSectionType.PercItems, label: I18n.get("Itens Percentuais") },
            { code: ClientProjectUniversalQuotationSectionType.HourItems, label: I18n.get("Itens por Hora") }
        ];

        const sectionType = sectionTypes.find((item) => item.code == section.sectionType);

        return (
            <div style={{ padding: '1rem' }}>

                <p className="title is-5">{I18n.get("Excluir Seção")}</p>

                <br />



                <br />

                <form onSubmit={this.submitForm.bind(this)}>

                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: '10rem', padding: '0.75rem' }}>
                                    <strong>{I18n.get('Nome da Seção')}:</strong>
                                </td>
                                <td style={{ padding: '0.75rem' }}>
                                    {section.sectionName}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.75rem' }}>
                                    <strong>{I18n.get('Tipo da Seção')}:</strong>
                                </td>
                                <td style={{ padding: '0.75rem' }}>
                                    {sectionType.label}
                                </td>
                            </tr>

                            <tr>
                                <td style={{ width: '10rem', padding: '0.75rem' }}>
                                    <strong>{I18n.get('Itens')}:</strong>
                                </td>
                                <td style={{ padding: '0.75rem' }}>
                                    {section.items.length}
                                </td>
                            </tr>
                        </tbody>

                    </table>

                    <br />
                    <br />

                    <SubmitButtonField
                        label={I18n.get("Excluir")}
                        className="button is-black is-rounded is-pulled-right"
                        onClick={this.submitForm.bind(this)}
                        task={this.state.submit_task}
                    />

                    <br /><br />

                </form>

            </div>
        );
    }
}

export default QuotationSectionDeleteForm;
