import {
  GET_PARTNERS,
  GET_PARTNER_BY_ID,
  UPDATE_PROJECT_STEP,
} from '../../actions';

const initialState = {
  partners: {
    data: [],
    partnerById: {},
    stepProject: {
      step: 1,
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNERS:
      const newStateGetPartners = {
        ...state,
        data: [...action.payload],
      };
      return newStateGetPartners;
    case GET_PARTNER_BY_ID:
      const newStateGetPartnerIndividual = {
        ...state,
        partnerById: { ...action.payload },
      };
      return newStateGetPartnerIndividual;
    case UPDATE_PROJECT_STEP:
      const newStateUpdateStep = {
        ...state,
        stepProject: { step: action.payload },
      };
      return newStateUpdateStep;
    default:
      return state;
  }
};

export default reducer;
