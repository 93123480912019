import React, { useEffect, useRef, useState } from "react";
import { Icon } from "antd";

import * as S from "./styles";

export const Alert = ({
  message,
  description,
  type,
  notClosable,
  autoClose,
  onClose,
}) => {
  const [open, setOpen] = useState(false);

  const handleCloseAlert = () => {
    setOpen(false);
    onClose();
  };

  const renderIcon = () => {
    if (type === "success") {
      return <Icon type="check-circle" className="alert-icon" />;
    }
    if (type === "error") {
      return <Icon type="close-circle" className="alert-icon" />;
    }
    if (type === "warning") {
      return <Icon type="exclamation-circle" className="alert-icon" />;
    }
  };

  useEffect(() => {
    if (message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  useEffect(() => {
    let timer;

    if (autoClose && open) {
      timer = setTimeout(() => {
        setOpen(false);
        onClose();
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [autoClose, open]);

  if (open) {
    return (
      <S.Alert data-show="true">
        <S.Content
          className={`alert-${type} ${
            description ? "alert-with-description" : ""
          }`}
        >
          {renderIcon()}
          <span
            className={description ? "message-title" : ""}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          {description ? (
            <span className="description">{description}</span>
          ) : null}
          {notClosable ? null : (
            <button onClick={handleCloseAlert}>
              <Icon type="close" />
            </button>
          )}
        </S.Content>
      </S.Alert>
    );
    // <S.Alert
    //   message={message}
    //   type={type}
    //   showIcon
    //   closable
    //   onClose={onClose ? onClose : () => true}
    //   ref={alertRef}
    // />
  } else {
    return true;
  }
};
