import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import TextAreaField from "../form/textarea-field";

import FileUploadList from "../../modules/components/FileUploadList";

import { scrollToTop } from "../../utils/ui";

class DemandForm extends React.Component {
  constructor(props) {
    super(props);

    const { demand = "" } = props;

    this.state = {
      fields: {
        demand: {
          onChange: this.onChangeForm.bind(this, "demand"),
          onBlur: this.onSave.bind(this, "demand"),
          successIcon: false,
        },
      },
      errors: {},
      form: {
        demand,
      },
      demandFiles: [],
      task: {},
      submit_task: {},
      files: [],
      listImg: [],
      loadImgs: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.fields).forEach((item) => {
        nextState.form[item] = this.props[item];
      });

      this.setState(nextState);
    }

    if (this.props.project != prevProps.project) {
      let listImg =
        this.props.project &&
        this.props.project.demandFiles &&
        this.props.project.demandFiles.map((item, index) => {
          return {
            ...item,
            uid: item.id,
          };
        });

      this.setState({
        demandFiles: listImg,
      });
    }

    if (this.state.loadImgs != prevState.loadImgs) {
      let listImg =
        this.props.project &&
        this.props.project.demandFiles &&
        this.props.project.demandFiles.map((item, index) => {
          return {
            ...item,
            uid: item.id,
          };
        });

      this.setState({
        demandFiles: listImg,
      });
    }
  }

  componentDidMount() {
    scrollToTop();
    this.setState({
      loadImgs: true,
    });
  }

  componentWillUnmount() {
    this.props.save(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  getData() {
    var data = {
      ...this.props.project,
      ...this.state.form,
    };

    return data;
  }

  getDataUploadMult = (files) => {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    var data = {
      ...this.props.project,
      demandFiles: files,
    };

    this.props.save(data).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  };

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.save(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    return (
      <div>
        <br />
        <br />

        <div className="form-group">
          <p style={{ fontWeight: "bold" }}>{I18n.get("Detalhes")}</p>

          <p style={{ marginTop: "10px" }}>
            {I18n.get(
              "Agora que você selecionou o escopo desejado, conte um pouco mais do que você espera deste projeto. Não precisa abrir informações do briefing ainda, mas tente ajudar os parceiros criativos a entenderem melhor a sua demanda. Se preferir, você também poderá subir algum arquivo para dar mais subsídios para o parceiro entender o seu desafio."
            )}
          </p>
        </div>

        <br />
        <br />

        <TextAreaField
          {...this.state.fields.demand}
          value={this.state.form.demand}
          error={this.state.errors.demand}
        />

        <FileUploadList
          getDataUpload={this.getDataUploadMult}
          name="files"
          uploadId={this.props.project.projectId}
          get2={this.props.get2 || false}
          fileListGet={this.state.demandFiles || []}
          direct={true}
        />

        {/* <p style={{ fontStyle: 'italic', fontSize: '80%' }}>Max. 250 caracteres.</p> */}
      </div>
    );
  }
}

export default DemandForm;
