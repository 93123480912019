import styled from "styled-components";

export const Wrapper = styled.div``;

export const AssetBundleHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 26px;

  .blue {
    color: #1883ff;
  }

  .strong {
    font-weight: 700;
  }
`;

export const ContentSelect = styled.div`
  width: 100px;
`;
