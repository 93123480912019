import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import { BP_API } from "../../../actions/bp/actions";

import LoggedPage from "../../page-templates/loggedFull";

import PartnerCard from "../../partner/shared/_partner-card";
import PartnerFullCard from "../../partner/shared/_partner-full-card";

import PartnerInfos from "../../../modules/components/PartnerInfos";
import HeaderThumbCover from "../../../modules/components/HeaderThumbCover";
import ContainerPage from "../../../modules/components/ContainerPage";

import styled from "styled-components";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_partner: (id) => {
      dispatch(BP_API.get_partner(id));
    },
  };
};

const Main = styled.div`
  padding-bottom: 100px;
  max-width: 1000px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  color: #353535;
`;

class PreviewPageAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  componentDidMount() {
    if (
      !this.props.bp.partner ||
      this.props.bp.partner.id != this.props.match.params.partnerId
    ) {
      this.props.get_partner(this.props.match.params.partnerId);
    }
  }

  showDetails(item, e) {
    this.setState({ detailsView: item });
  }

  hideDetails(e) {
    e.preventDefault();

    this.setState({ detailsView: null });
  }

  render() {
    const partner = this.props.bp.partner || {};

    return (
      <LoggedPage {...this.props}>
        <div style={{ height: "60px" }}></div>
        <div style={{ marginTop: "50" }}>
          <HeaderThumbCover
            thumb={partner?.company && partner?.company.logoImage}
            bgHeader={partner?.company && partner?.company.headerImage}
            title=""
            // link={`/bpool/parceiros`}
            // linkText={I18n.get("Voltar")}
            action={this.closePartner}
          />

          <ContainerPage>
            <Main>
              <PartnerInfos
                infos={partner && partner}
                specialismsBP={
                  this.props.bp.specialisms && this.props.bp.specialisms
                }
                sectorsFlat={
                  this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                }
                isPresentation={true}
                diversity={this.props.bp.diversity && this.props.bp.diversity}
              />
            </Main>
          </ContainerPage>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPageAdmin);
