import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
    Form,
    Button,
    Icon,
    Input,
    Row,
    Col,
    Modal
} from 'antd';

import MultUploadFile from './MultUploadFile';

const { TextArea, Search } = Input;
const FieldCustom = styled.div`

`

const BoxAddFild = styled(Search)`
    button {
        position:relative;

        i {
            display: none;
        }
    }
`;

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
      margin-right: 20px;
  }
`;

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        add_case: data => {
            dispatch(PARTNER_WHATIF_API.add_case(data));
        },
        update_case: data => {
            dispatch(PARTNER_WHATIF_API.update_case(data));
        },
        upload: data => {
            return PARTNER_WHATIF_API.upload(data);
        }
    };
};

class ModalEditCase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videos: [],
            images: [],
            listImg: [],
            cleanModal: false

        }
    }


    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partnerWhatIf.cases != prevProps.partnerWhatIf.cases) {
            this.cancelModal();
        }

        if (this.props.partnerWhatIf.errosCase && this.props.partnerWhatIf.errosCase
            != prevProps.partnerWhatIf.errosCase
            && !this.state.statusBlur
        ) {
            Object.keys(this.props.partnerWhatIf.errosCase).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.partnerWhatIf.errosCase[field].errorMessage)],
                    },
                });
            });
        };

        if (this.props.item != prevProps.item && Object.keys(this.props.item).length != 0) {
            let listImg = this.props.typeModal == "edit" && this.props.item.images && this.props.item.images.map((item, index) => {
                return {
                    uid: index,
                    name: 'image.png',
                    status: 'done',
                    url: item
                }
            });
            this.setState({
                videos: this.props.item.videos ? this.props.item.videos : [],
                images: this.props.item.images ? this.props.item.images : [],
                listImg: this.props.typeModal == "edit" ? listImg : []
            });
        }

        if (this.props.reset != prevProps.reset && this.props.typeModal == "add") {
            this.setState({
                videos: [],
                images: [],
                listImg: []
            });
        }


    }


    getDataUpload = (files) => {
        this.setState({
            images: files
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.setState({
                    formState: values
                })

                const dataForm = {
                    ...values,
                    partnerWhatIfId: this.props.partnerWhatIfId,
                    caseId: this.props.item && this.props.item.caseId ? this.props.item.caseId : "",
                    videos: this.state.videos,
                    images: this.state.images,
                }

                if (this.props.typeModal == "edit") {
                    this.props.update_case(dataForm);
                } else {
                    this.props.add_case(dataForm);
                }

            }
        });


    };

    cancelModal = () => {
        this.props.form.resetFields()
        this.props.onCancel()
    }

    render() {
        const { getFieldDecorator } = this.props.form;



        return (
            <Modal
                visible={this.props.show}
                title={`${this.props.typeModal == "edit" ? "Editar" : "Adicionar"} case`}
                footer={false}
                onCancel={this.cancelModal}
            >
                <Form>
                    <Row>
                        <Col sm={24}>
                            <Form.Item
                                label="[Case] - Nome do Projeto">
                                {getFieldDecorator('name', {
                                    rules: [{ required: false, message: 'ops!' }],
                                    initialValue: this.props.item.name ? this.props.item.name : null
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24}>
                            <Form.Item label="Texto Explicativo">
                                {getFieldDecorator('description', {
                                    rules: [{ required: false, message: 'ops!' }],
                                    initialValue: this.props.item.description ? this.props.item.description : null
                                })(
                                    <TextArea rows={4} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24}>
                            <Form.Item label="Ano">
                                {getFieldDecorator('year', {
                                    rules: [{ required: false, message: 'ops!' }],
                                    initialValue: this.props.item.year ? this.props.item.year : null
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={24}>
                            <Form.Item label="Imagens">
                                {getFieldDecorator('images', {
                                    rules: [{ required: false, message: 'ops!' }],
                                })(
                                    <MultUploadFile
                                        actionUpload={this.props.upload}
                                        getDataUpload={this.getDataUpload}
                                        uploadId={this.props.partnerWhatIfId}
                                        fileListGet={this.state.listImg}
                                        isEdit

                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={24}>
                            <FieldCustom>
                                <label className="label-bp">Vídeos</label>
                                <BoxAddFild
                                    className="InputPlus"
                                    placeholder=""
                                    onSearch={value => { this.setState({ videos: [...this.state.videos, value] }) }}
                                    enterButton
                                />
                            </FieldCustom>
                        </Col>
                    </Row>



                    <Row>
                        <Col sm={24}>
                            <ul className="ListDinamic">
                                {this.state.videos &&
                                    this.state.videos.map(item => {
                                        return <li key={item}><a href="#" target="_blank"><Icon type="video-camera" /> {item}</a></li>
                                    })
                                }
                            </ul>
                        </Col>
                    </Row>

                    <RowSubmitModal>
                        <Col sm={24}>
                            <Button type="primary" shape="round" size="large" onClick={this.cancelModal}>Cancelar</Button>
                            <Button type="primary" htmlType="submit" shape="round" size="large" onClick={this.handleSubmit}>
                                {this.props.typeModal == "edit" ? "Salvar" : "Adicionar"}
                            </Button>
                        </Col>
                    </RowSubmitModal>

                </Form>
            </Modal>
        );
    }
}


const ModalEditCaseForm = Form.create({})(ModalEditCase);

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditCaseForm);
