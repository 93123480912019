import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { USERPARTNER_API } from "../../../actions/partner-user/actions";
import { PartnerStatus } from "../../../model";

import Loading from "../../../components/pages/loading";

import { QueryString } from "../../../utils/querystring";

const mapStateToProps = (state) => {
  return { partner_user: state.partnerUser, auth: state.auth };
  // return { partner: state.partner, auth: state.auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirm: (data) => {
      dispatch(USERPARTNER_API.confirm(data));
    },
  };
};

class UserPartnerVerifyEmail extends React.Component {
  constructor(props) {
    super();

    this.state = {
      locale: "pt",
      verificationToken: props.match.params.verificationToken,
    };
  }

  componentDidMount() {
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    this.setState({
      locale: locale,
    });

    I18n.setLanguage(locale);

    let data = {
      verificationToken: this.props.match.params.verificationToken,
      locale: locale,
    };

    this.props.confirm(data);
  }

  render() {
    if (!this.props.partner_user || !this.props.partner_user.id)
      return <Loading></Loading>;

    if (this.props.partner_user && this.props.partner_user.verificationToken) {
      if (!this.props.partner_user.completedOn) {
        return <Redirect to={`/login`} />;
      } else {
        return <Redirect to="/login" />;
      }
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPartnerVerifyEmail);
