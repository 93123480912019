import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { SPECS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';


const api_get_specs_dash = (data) => {
    return (dispatch, getState) => {
        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };


            return axios.get(`${Config.API_BASE_URL}/dashboard/${data.prefix}/${data.idGet}/spec/${data.fromDate}/${data.toDate}?status=${data.status}`, config)
                .then(response => {



                    dispatch({
                        type: SPECS_GET,

                        payload: {
                            specs: response.data,
                            errors: {},
                        }
                    })
                })
                .catch(error => {
                    dispatch({
                        type: SPECS_GET,
                        payload: {
                            errors: {},
                            task: {
                                result: false
                            }
                        }
                    })
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_specs_dash;
