import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TooltipField from '../form/tooltip-field';

// Não é referenciado mas preicsa do import
import bulmaSteps from '../../../node_modules/bulma-extensions/bulma-steps/dist/js/bulma-steps.min.js';

const mapStateToProps = state => {
    return state;
};
const mapDispatchToProps = dispatch => {
    return {}
};

class Steps extends React.Component {
    constructor(props) {
        super();
        this.state = {
            items: [
                {
                    index: 1,
                    title: I18n.get("Sobre o Responsável"),
                    color: "#BCE6F4",
                    link: "/parceiro/passo-1"
                },
                {
                    index: 2,
                    title: I18n.get("Sobre a Empresa"),
                    color: "#CBEDE0",
                    link: "/parceiro/passo-2"
                },
                {
                    index: 3,
                    title: I18n.get("Especialidades & Segmentos"),
                    color: "#FFC5B9",
                    link: "/parceiro/passo-3"
                },
                {
                    index: 4,
                    title: I18n.get("Clientes, Cases & Prêmios"),
                    color: "#F6E39F",
                    link: "/parceiro/passo-4"
                },
                {
                    index: 5,
                    title: I18n.get("Líderes da Empresa"),
                    color: "#BAB1D1",
                    link: "/parceiro/passo-5"
                },
                {
                    index: 6,
                    title: I18n.get("Referências"),
                    color: "#E3FBB8",
                    link: "/parceiro/passo-6"
                }
            ]
        };
    }

    render() {
        const { items } = this.state
        const { active } = this.props



        let name = '';

        if (this.props.partner && this.props.partner.nameAlias) {

            name = this.props.partner.nameAlias;
        }
        else if (this.props.partner && this.props.partner.name) {

            name = this.props.partner && this.props.partner.name;

            let parts = name.split(' ');

            if (parts.length > 0) {
                name = parts[0];
            }
        }

        let txt = '';

        return (
            <div>
            <ul id="partner-signup-steps">
                {items.map(function(item) {

            switch(active) {
                case 1:
                    txt = I18n.get("Para conhecer você melhor, precisamos de algumas informações. Reserve um tempinho para preencher todos os campos e incluir detalhes importantes. Isso vai ajudar nosso algoritmo a encontrar projetos bacanas com seu perfil. Capricha aí =)");
                    break;
                case 2:
                    txt = I18n.get('Todas as informações solicitadas sobre a sua empresa e o seu trabalho são essenciais para ajudar o nosso algoritmo encontrar jobs e clientes com o seu perfil. Quanto mais precisas elas forem, mais chances de dar match. Beleza?');
                    break;
                case 3:
                    txt = I18n.get("Se a sua empresa é especialista em algum segmento ou tipo de trabalho, por favor conte pra gente. Quando você entende bem de um setor ou mídia, tem uma compreensão mais rápida do briefing, o que é bom pra todos envolvidos. Além de fazer curadoria de talentos, nosso papel é trazer os projetos certos para os times certos.");
                    break;
                case 4:
                    txt = I18n.get('Clientes e cases representam reputação em nosso mercado. Tudo o que a sua agência (durante sua existência) e você (durante sua carreira) conquistaram e construíram é levado em conta pelo nosso algoritmo.');
                    break;
                case 5:
                    txt = I18n.get('Ao conhecer os líderes da sua empresa, vamos ter uma visão mais completa do seu negócio em termos de experiência, expertises e outras habilidades. Conhecer o time que lidera e faz acontecer importa muito para nós, para o algoritmo e para os clientes.');
                    break;
                default:
                    txt = I18n.get('Estamos quase no final do seu cadastro! Mais ainda falta algo importante: as suas referências profissionais. Pedimos que algum cliente, ex-cliente, parceiro profissional ou alguém com quem você trabalhou durante sua carreira avalie você. É rápido e vai ajudar nosso algoritmo a conhecer melhor seus pontos fortes =)');
            }

                    const lineStyle = {
                        background: item.color,
                    };
                    return (<li key={item.index} className={(active && item.index === active && "progress-bar--item is-active") || "progress-bar--item"}>
                        <h3>{item.index}
                        {active && item.index === active && <TooltipField tooltip={txt} orientation="bottom" canClose={true} withoutquestionmark="true" isfixed="true" />}
                        </h3>
                        <Link to={item.link} className="progress-bar--menu-item">
                            <span style={lineStyle}></span>
                            <strong className="is-not-mobile">{item.title}</strong>
                        </Link>
                    </li>)
                })}
            </ul>
        </div>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
