import React from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { FileUpload } from "../../../../../../components/UI/FileUpload";
import { hasError, clearErrorInput } from "../../../../utils/errorsInput";

import * as S from "../../styles";

export const FormCarregarNF = ({
  inputsForm,
  handleUploadNF,
  handleDeleteUploadNF,
  errorsInputs,
  handleSetErrorsInputs,
  isLoadingGet,
  inputsDisableds,
}) => {
  const handleSelectedNF = (file) => {
    const errorsArr = clearErrorInput({
      field: "file.Url",
      arr: errorsInputs,
    });
    handleSetErrorsInputs(errorsArr);
    handleUploadNF(file);
  };

  return (
    <>
      <h3 style={{ marginTop: 2 }}>{I18n.get("Carregar NF")}</h3>
      <Row>
        <Col xs={24} md={24} style={{ paddingRight: 0, paddingLeft: 0 }}>
          <FileUpload
            cbFiles={(file) => handleSelectedNF(file)}
            multiple={false}
            initialFiles={[]}
            acceptedTypes={[
              {
                label: "PDF",
                type: "application/pdf",
              },
            ]}
            cbDeleteFile={(fileOrId) => handleDeleteUploadNF(fileOrId)}
          />
          {hasError({
            arr: errorsInputs,
            field: "file.Url",
          }) ? (
            <S.ErrorMsg>
              {hasError({
                arr: errorsInputs,
                field: "file.Url",
              })}
            </S.ErrorMsg>
          ) : null}
        </Col>
      </Row>
    </>
  );
};
