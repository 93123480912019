import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { SPEC_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const api_get_spec_dash = (data) => {
  let dataFrom = data.fromDate;
  let dataTo = data.toDate;

  delete data["date"];
  delete data["fromDate"];
  delete data["toDate"];

  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");

  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        // http://localhost:52159/api/AssetSaving/client/detailassset/2021-05-08/2021-11-08/CP_IN_1080_A?brand=-1&status=-1&spec=-1&productId=-1

        return axios
          .get(
            `${Config.API_BASE_URL}/AssetSaving/client/detailassset/${dataFrom}/${dataTo}/${data.assetCode}?${queryString}`,
            config
          )
          .then((response) => {
            dispatch({
              type: SPEC_GET,

              payload: {
                itemSaving: response.data,
                errors: {},
              },
            });

            return true;
          })
          .catch((error) => {
            dispatch({
              type: SPEC_GET,
              payload: {
                errors: {},
                task: {
                  result: false,
                },
              },
            });

            return false;
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default api_get_spec_dash;
