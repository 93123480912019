import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import { PARTNER_API } from '../../actions/partner/actions';
import SelectSearchField from '../form/select-search-field';

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp, marketExpertise: { errors: {} }, }, state.partner);
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(PARTNER_API.update_specialism(data));
        }
    };
};

const SHOPPERTYPES = [
    { "label": "Modelos Eventos", "code": "EVENTOS" },
    { "label": "Artísticos", "code": "RECEPCIONISTAS" },
    { "label": "Promotora PDV", "code": "PDV" }
]

const EXPERTISE = [
    { "label": "Permanente", "code": "PERMANENTE" },
    { "label": "Temporario", "code": "TEMPORARIO" }
]

const SIZE = [
    { "label": "Pequena < 100", "code": "SMALL" },
    { "label": "Médio >100 até 750", "code": "AVERAGE" },
    { "label": "Grande >750 até 1500", "code": "BIG" },
    { "label": "Macro >1500", "code": "MACRP" },
]

class LiveMarketingShopper extends React.Component {
    constructor(props) {
        super();

        this.state = {
            form: {
                'eventLiveMarketingShopper.type': {
                    label: I18n.get("Expertise"),
                    value: [],
                    onChange: this.onChangeType.bind(this),
                    error: props.errors['eventLiveMarketingShopper.type'],
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'eventLiveMarketingShopper.type')
                },
                'eventLiveMarketingShopper.size': {
                    label: I18n.get("Porte"),
                    value: [],
                    onChange: this.onChangeSize.bind(this),
                    error: props.errors['eventLiveMarketingShopper.size'],
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'eventLiveMarketingShopper.size')
                },
                isSubmit: false,
            },
            task: {},
        };

        //this.onDrop = this.onDrop.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                const value = FormUtils.getNestedValue(this.props, item);



                if (FormUtils.isFormField(nextState.form[item])) {

                    nextState.form[item].error = this.props.errors[item];

                    if ('checked' in nextState.form[item]) {
                        nextState.form[item].checked = value;
                    }
                    else {
                        nextState.form[item].value = value;
                    }
                }
                else {
                    nextState.form[item] = value;
                }
            });

            this.setState(nextState);
        }
    }


    onChangeType(value) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['eventLiveMarketingShopper.type'].value = value;

            return nextState;
        }, function () {

            var data = this.getData();

            this.onSave(data);
        });
    }

    onChangeExpertise(value) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['eventLiveMarketingShopper.expertise'].value = value;

            return nextState;
        }, function () {

            var data = this.getData();

            this.onSave(data);
        });
    }

    onChangeSize(value) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['eventLiveMarketingShopper.size'].value = value;

            return nextState;
        }, function () {

            var data = this.getData();

            this.onSave(data);
        });
    }


    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    onChangeForm(prop, e) {



        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData(isSubmit = false) {

        var data = {
            partnerId: this.props.partnerId,
            specialisms: this.props.specialisms,
            keyServices: this.props.keyServices,
            marketExpertises: this.props.marketExpertises,
            eventLiveMarketing: this.props.eventLiveMarketing,
            eventLiveMarketingBrandExperience: this.props.eventLiveMarketingBrandExperience,
            eventLiveMarketingBrandIncentive: this.props.eventLiveMarketingBrandIncentive,
            eventLiveMarketingEvents: this.props.eventLiveMarketingEvents,
            eventLiveMarketingLabor: this.props.eventLiveMarketingLabor,
            eventLiveMarketingShopper: this.props.eventLiveMarketingShopper,
            eventCat: this.props.eventCat,
            eventSub: this.props.eventSub,
            eventLiveMarketingShopper: {
                type: this.state.form['eventLiveMarketingShopper.type'].value,
                size: this.state.form['eventLiveMarketingShopper.size'].value,
            },
            isSubmit: isSubmit
        };

        return data;
    }

    onSave(item) {



        if (this.props.isAdmin) return;

        // zera o erro
        if (item && typeof this.state.form[item] === "object") {
            this.state.form[item].error = null;
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }


    submitForm(e) {

        const isSubmit = true;

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData(isSubmit));
        });
    }

    render() {

        const company = this.props.company || {};

        return (
            <form onSubmit={this.submitForm.bind(this)}>

                <div className="columns">
                    <div className="column is-4">
                        <SelectSearchField {...this.state.form['eventLiveMarketingShopper.type']} values={SHOPPERTYPES} isMulti />
                    </div>
                    <div className="column is-4">
                        <SelectSearchField {...this.state.form['eventLiveMarketingShopper.size']} values={SIZE} isMulti />
                    </div>
                </div>
            </form >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LiveMarketingShopper);
