import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { ClientProjectStatus } from '../../model';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import ProjectEndForm from './project-end-form';

import Steps from './steps';

class ProjectOnGoingEnd extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount() {
    }

    submitForm(e) {

    }

    render() {
        const pageReview = this.props.match.path.split("/")[3];
        const isReview = pageReview && pageReview == 'avaliacao';




        if (this.props.project.status == ClientProjectStatus.Finished && isReview == false) {
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/finalizado`} />)
        }

        return (
            <FillForm>
                <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-6">
                                <p className="title">{I18n.get("Projeto em Andamento")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-6">
                                <Steps active={7} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="client-step client-step-1 project-ongoing">

                    <div className="container client-container-1">

                        <ProjectEndForm
                            {...this.props}
                            isReview={isReview}
                        />

                        <br />
                        <br />


                    </div>

                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(ProjectOnGoingEnd);
