import React from "react";
import { I18n } from "aws-amplify";
import { Info } from "./Info";
import * as S from "./styles";

export const InfosDrawer = ({ data, loading }) => {
  return (
    <S.Wrapper>
      <h3>
        {I18n.get(
          "Deseja enviar o e-mail de instruções de faturamento para o fornecedor abaixo?"
        )}
      </h3>
      <S.Infos>
        <Info
          label={I18n.get("Fornecedor")}
          info={data?.name}
          loading={loading}
        />
        <Info label={I18n.get("SKU")} info={data?.sku} loading={loading} />
        <Info
          label={I18n.get("Valor Total")}
          info={data?.totalFmt}
          loading={loading}
        />
        <Info
          label={I18n.get("Valor Fee")}
          info={data?.totalFeeFmt}
          loading={loading}
        />
        <Info
          label={I18n.get("Data da solicitação")}
          info={data?.createdOn}
          loading={loading}
        />
        <div />
        <Info
          label={I18n.get("Fornecedor email")}
          info={data?.emailPrimary}
          loading={loading}
        />
      </S.Infos>
    </S.Wrapper>
  );
};
