import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
// import { connect } from 'react-redux';
import { Upload, Icon, message } from 'antd';
import styled from 'styled-components';
// import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);


}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('Somente arquivos JPG e PNG são suportados.');
    }
    // const isLt2M = file.size / 1024 / 1024 < 4;
    // if (!isLt2M) {
    //     message.error('Tamanho máximo suportado é de 4MB');
    // }
    // return isJpgOrPng && isLt2M;
    return isJpgOrPng;
}

const UploadBtn = styled(Upload)`
    .ant-upload.ant-upload-select-picture-card {

        ${({ cover }) => cover && `
            max-width: 450px;
            width: 100%;
        `}
    }
`

const SpanLoading = styled.span`
    position: relative;
    display: block;

    .iconAnimate {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 30px;
            color: #fff;
        }
    }
`

class UploadFile extends Component {
    state = {
        loading: false,
        imageUrl: ""
    };

    handleChange = info => {


        if (info.file.status === 'uploading') {
            this.setState({ loading: true });

        }

    };

    customRequest = ({ onSuccess, onError, file }) => {


        let img64 = "";

        getBase64(file, imageUrl => {

            img64 = imageUrl;

            const dataUpload = {
                id: this.props.id,
                img_type: file.type,
                img_base64: img64
            }
            this.props.actionUpload(dataUpload).then((response) => {
                const dataForm = {
                    field: this.props.name,
                    url: response.data.url
                }
                this.setState({
                    file: file,
                    imageUrl
                })

                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 2000);

                this.props.getDataUpload(dataForm);

                onSuccess(null, file);
            }).catch((error) => {
                onError(error)
            });
        });
    }


    render() {
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
                {this.props.cover &&
                    <small>Formato ideal: 1280px X 220px</small>
                }
            </div>
        );
        const { imgUrl } = this.props;

        return (
            <UploadBtn
                {...this.props}
                className="avatar-uploader"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
                customRequest={this.customRequest}
            >
                {imgUrl ? <SpanLoading>{this.state.loading ? <span className="iconAnimate"><Icon type="loading" /></span> : ""} <img src={imgUrl} alt="avatar" style={{ width: '100%' }} /></SpanLoading> : uploadButton}
            </UploadBtn>
        );
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
export default UploadFile;
