import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { PROJECT_API } from '../../actions/project/actions';

import { Task } from '../../utils/task';

import FillForm from '../page-templates/fillForm';

import Terms from './_terms';

import CheckboxField from '../form/checkbox-field';
import SubmitButtonField from '../form/submit-button-field';

import { scrollToTop } from '../../utils/ui';

const mapStateToProps = state => {
    return Object.assign(state, state.project);
};

const mapDispatchToProps = dispatch => {
    return {
        confirm_terms: data => {
            dispatch(PROJECT_API.confirm_terms(data));
        },
        update_responsible_terms: data => {
            return dispatch(PROJECT_API.update_responsible_terms(data));
        },
    };
};

class ProjectDefintionTEP extends React.Component {

    constructor(props) {

        super();

        this.state = {
            form: {
                clientTerms: {
                    label: I18n.get("Li e estou de acordo"),
                    value: props.clientTerms,
                    checked: props.clientTerms,
                    onChange: this.onChangeFormCheckbox.bind(this, 'clientTerms'),
                    error: props.errors.clientTerms,
                    successIcon: false,
                },
            },
            task: {},
            errors: {},
            terms_task: {},
        };

    }

    componentDidMount() {

        const data = {
            verificationToken: this.props.match.params.verificationToken
        };

        this.props.confirm_terms(data);

        scrollToTop();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.project && this.props.project.task;

        if (!prevState) return;
        if (!task) return;

        var nextState = Object.assign({}, prevState);

        if (task.id != prevState.task.id) {

            nextState.task = task;

            this.setState(nextState);
        }
    }

    onChangeFormCheckbox(prop, e) {

        const checked = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].checked = checked;
            nextState.form[prop].value = checked;

            return nextState;
        });
    }

    getData() {

        const data = {
            clientId: this.props.clientId,
        };

        return data;
    }

    onSubmitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ terms_task: task });

        const data = {
            verificationToken: this.props.match.params.verificationToken,
            clientTerms: this.state.form.clientTerms.value
        }

        this.props.update_responsible_terms(data)
            .then(({ task, errors }) => {

                this.setState({ terms_task: task, errors: errors });

            });
    }

    render() {

        return (
            <FillForm>
                <div className="hero-body has-background-white project-definition-header">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-8">
                                <p className="title">{I18n.get("Definições do Projeto")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-4">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-definition">

                    <div className="container client-container-1">

                        <div className="column is-10 is-offset-1">

                            <div className="card" style={{ margin: '0.5rem' }}>
                                <header className="card-header">
                                    <p className="card-header-title" style={{ fontSize: '130%' }}>{I18n.get("Termo de Especificação de Projeto")}</p>
                                </header>
                                <div className="card-content" style={{ maxWidth: '960px' }}>
                                    <div className="content">

                                        <form>

                                            <Terms />

                                            <br /><br />


                                            {!this.props.clientTermsUpdatedOn &&  <div className="columns">
                                                <div className="column is-6">

                                                    <CheckboxField {...this.state.form.clientTerms} />

                                                    <SubmitButtonField
                                                        className="button is-black is-fullwidth next"
                                                        label={I18n.get("ACEITAR")}
                                                        onClick={this.onSubmitForm.bind(this)}
                                                        task={this.state.terms_task}
                                                        suppressLabel={true}
                                                        disabled={!this.state.form.clientTerms.value}
                                                    />


                                                </div>
                                            </div>}

                                            {this.props.clientTermsUpdatedOn && <p style={{ fontWeight: 'semibold', fontSize: '80%' }}>{I18n.get("Aceite da TEP em")} {this.props.clientTermsUpdatedOn}.
                                            </p>}

                                            <p className="has-text-danger">
                                                <ul>
                                                    {Object.keys(this.state.errors).map((item) => {

                                                        return (<li>{this.state.errors[item].errorMessage}</li>)
                                                    })}
                                                </ul>
                                            </p>


                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDefintionTEP);
