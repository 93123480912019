import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Task } from '../../utils/task';

import { PROJECT_API } from '../../actions/project/actions';
import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import { CardSimple } from '../../modules/components/Cards';
import FormFilterTransfer from '../../modules/Admin/components/filterTransferAdmin';
import TableBillings from '../../modules/Admin/components/tableTransferAdmin';

import styled from 'styled-components';

import {
    Row,
    Col,
    Button,
    notification,
    Icon
} from 'antd';

const NoData = styled.div`
    padding: 50px;
    text-align: center;

    h3 {
        font-weight: 700;
        margin-bottom: 30px;
    }

    i {
        font-size: 40px;
    }

    .txt {
        position: relative;
        left: 30px;
        top: -15px;
    }
`;


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_invoices_list: data => {
            dispatch(BP_API.get_invoices_list(data));
        },
        get_filters_transfer: () => {
            dispatch(BP_API.get_filters_transfer());
        },
        reset: () => {
            dispatch(PROJECT_API.reset());
        },
        generate_invoices: (data) => {
            dispatch(BP_API.generate_invoices(data));
        }
        
    };
};

class ClientProjectListAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
            historySearch: {},
            task: {},
            listPo:[]
        };
    }

    handleFilters = (data) => {
        var task = new Task();

        task.start();

        this.setState({ task: task, historySearch: data }, function () {
            this.props.get_invoices_list(data);
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {
            this.setState({ task: task });
        }

    }

    componentDidMount() {
        this.props.reset();
        this.props.get_filters_transfer();
    }

    getPos = (item) => {

        let newList = this.state.listPo;


        const itemObj = {
            projectId: item.projectId, 
            extraId: item.extraId, 
            installmentId: item.installmentId, 
            //invoiceId: item.invoiceId,
            billingId: item.billingId
        }
      

        if(newList.findIndex(x => x.installmentId === item.installmentId) == -1) {
            newList.push(itemObj);
            this.setState({
                listPo:newList
            })
        } else {
            newList.splice(newList.findIndex(x => x.installmentId === item.installmentId), 1);

            this.setState({
                listPo:newList
            })
        }
    }


    handlegenerate = () => {
        const data = {
            email: this.props.auth.email,
            invoices: this.state.listPo
        }
        this.props.generate_invoices(data)
    }
    render() {
        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/faturamento" className="is-black">{I18n.get("Faturamento")}</NavLink></li>
                    </ul>
                </nav>

                <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            <FormFilterTransfer
                                filters={this.props.bp && this.props.bp.adminTransferFilters}
                                action={this.handleFilters}
                                task={this.state.task}
                            />
                        </CardSimple>
                    </Col>
                </Row>
                <br />

                <Row gutter={6}>
                    <Col sm={24}>
                        <Button disabled={this.state.listPo.length == 0} className="button bt-bpool black pull-right" onClick={() => this.handlegenerate()}>
                            {I18n.get("Gerar Planilha")}
                        </Button>
                    </Col>
                    <Col sm={24}>
                        &nbsp;
                    </Col>
                    <Col sm={24}>
                        <CardSimple>
                            {!this.props.bp.adminInvoicesList &&
                                <NoData>
                                    <h3 className="title-bpool medium">{I18n.get("Escolha as informações para a busca!")}</h3>

                                    {!this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="search" />
                                        </p>
                                    }

                                    {this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="loading" /> 
                                        </p>
                                    }
                                </NoData>

                            }

                            {this.props.bp.adminInvoicesList &&
                                <div>
                                    {this.state.task.busy &&
                                        <NoData>
                                            <p className="icone">
                                        <Icon type="loading" /> <span className="txt">Atualizando</span>
                                            </p>
                                        </NoData>
                                    }
                                    <TableBillings
                                        data={this.props.bp.adminInvoicesList}
                                        totals={{}}
                                        getPos={this.getPos}
                                        {...this.props}
                                    />
                                </div>
                            }
                        </CardSimple>
                    </Col>
                </Row>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProjectListAdmin);
