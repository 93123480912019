import { Auth } from 'aws-amplify';

import { LOGIN_SUCCESS, LOGIN_FAILURE } from './actions';

import { CLIENT_API } from '../client/actions';
import { PARTNER_API } from '../partner/actions';

const api_login_failure = (error) => {

    return (dispatch) => {

        const password_reset_required = (error && error.code == "PasswordResetRequiredException");

        dispatch({
            type: LOGIN_FAILURE,
            payload: {
                init: false,
                logged_user: null,
                jwtToken: null,
                password_reset_required: password_reset_required,
                task: {
                    result: false,
                    error: error
                }
            }
        });
    }
}

export default api_login_failure;