import styled from "styled-components";

export const Wrapper = styled.div``;

export const HeaderTexts = styled.div`
  margin-bottom: 80px;
  strong {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 8px;
    position: relative;
    float: left;
    width: 100%;
  }
  p {
    font-size: 14px;
    line-height: 22px;
  }
`;
