import React, { Component } from "react";
import { I18n } from 'aws-amplify';
import {
    Row,
    Col,
    Form,
    Icon,
    Spin,
    Input,
    Select,
    Button
} from 'antd';

import styled from 'styled-components';

const { Option } = Select;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`

class FilterProjectAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.props.action(values);
            }
        });
    };
    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const { filters } = this.props

        if (!filters) {
            return (
                <BoxSpin>
                    <Spin />
                </BoxSpin>
            )
        }


        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={6}>
                        <Form.Item
                            label={I18n.get("Pais")}>
                            {getFieldDecorator('country', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: "-1"
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {filters && filters.countries.map((item, index) => {
                                        return <Option value={item.code} key={index}>{item.label}</Option>
                                    })}

                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={4}>
                        <Form.Item
                            label={I18n.get("Status")}>
                            {getFieldDecorator('status', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: -1
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {filters && filters.status.map((item, index) => {
                                        return <Option value={item.code} key={index}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={4}>
                        <Form.Item
                            label={I18n.get("Configuração")}>
                            {getFieldDecorator('settings', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: ''
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {filters && filters.settings.map((item, index) => {
                                        return <Option value={item.code} key={index}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={2}>
                        <Form.Item
                            label={I18n.get("Tier")}>
                            {getFieldDecorator('tier', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: '-1'
                            })(
                                <Select
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    <Option value="-1">Todos</Option>
                                   <Option value="1">1</Option>
                                   <Option value="2">2</Option>
                                   <Option value="3">3</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={4}>
                        <Form.Item
                            label={I18n.get("Nome")}>
                            {getFieldDecorator('name', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: ''
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={2}>
                        <Form.Item className="labelClean" label="&nbsp;">
                            <Button
                                type="primary"
                                shape="round"
                                htmlType="submit"
                                disabled={!filters}
                                loading={this.props.task.busy}
                            >
                                OK
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        )
    }
}



const FormFilterProjectAdmin = Form.create({})(FilterProjectAdmin);

export default FormFilterProjectAdmin;

