import React from 'react';
import { I18n } from 'aws-amplify';
import { Redirect } from 'react-router-dom';

import { ClientProjectStatus } from '../../model';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import ProjectDetailsForm from './project-details-form';
import QuotationForm from './quotation-form';

import StepsCreative from './steps-creative';
import StepsBidding from './steps-bidding';

import { scrollToTop } from '../../utils/ui';

class ProjectDefintionStep3 extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount() {
        const data = {
            projectId: this.props.match.params.projectId,
        }

        this.props.get_assets_combo(data);
        scrollToTop();
    }

    submitForm(e) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};


        if (this.props.partner != prevProps.partner && this.props.partner.partnerId && !this.state.loading) {

            let data = {
                partnerId: this.props.partner.partnerId,
                projectId: this.props.projectId
            }
            this.props.getPartnerResponsible(data);

            this.setState({
                loading: true
            })
        }
    }


    render() {

        if (this.props.project && this.props.project.status == ClientProjectStatus.DefinitionsSet) {
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/detalhamento-obrigado`} />);
        }

        if (this.props.auth.is_partner && this.props.project && this.props.project.partnerHasSubmittedDefinitions) {
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/detalhamento-obrigado`} />);
        }

        if (this.props.auth.is_partner && this.props.redirectToPartnerSubmitted) {
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/detalhamento-enviado`} />);
        }

        if (this.props.auth.is_client && this.props.redirectToClientApproved) {
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/detalhamento-aprovado`} />);
        }

        if (this.props.auth.is_client && this.props.redirectToClientDeclined) {
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/detalhamento-declinado`} />);
        }

        const quotation = this.props.universalQuotation || {};

        return (
            <FillForm>
                <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-6" style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
                                <p className="title">{I18n.get("Definições do Projeto")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-6">
                                <StepsBidding active={2} projectId={this.props.match.params.projectId} terms={this.props.clientTermsUpdatedOn} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-definition">

                    <div className="container client-container-1">

                        <p className="title">{I18n.get("Orçamento")}</p>

                        {this.props.auth.is_partner && <>

                            <div>

                                <p style={{ fontWeight: 'bold' }}>
                                    {I18n.get('Agora é a hora de detalhar sua proposta.')}
                                </p>
                                <p style={{ marginTop: '0.50rem', fontSize: '90%' }}>
                                    {I18n.get('Ao clicar em ADICIONAR SEÇÃO você poderá adicionar campos de custos internos, como hora homem entregáveis, ou externos, como custos de fornecedores.')} <br />
                                    {I18n.get('Atente-se ao valor no campo BUDGET DISPONÍVEL pois essa será a sua verba para trabalhar, ok?')}
                                </p>

                            </div>

                        </>}
                        
                        <QuotationForm {...this.props} quotation={quotation} />

                        <br />
                        <br />

                    </div>

                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(ProjectDefintionStep3);
