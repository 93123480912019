import React from "react";
import { I18n } from "aws-amplify";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import { PARTNER_API } from "../../actions/partner/actions";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";

import ProposalMatchHeading from "./_heading-proposal-match";

import EventDetails from "../_project-details-eventCustom";

import ProposalNda from "./_bpitch-nda";

import SubmitButtonField from "../form/submit-button-field";

import { CardBorder } from "../../modules/components/Cards";

import { Row, Col, Input, Select, Button, Form } from "antd";

const { Option } = Select;

const mapStateToProps = (state) => {
  return Object.assign({}, state, state.partner);
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_partner: (data) => {
      return dispatch(PARTNER_API.get(data));
    },
    update_match_opt_out: (data) => {
      return dispatch(PARTNER_API.update_match_opt_out(data));
    },
    update_match_nda: (data) => {
      return dispatch(PARTNER_API.update_match_nda(data));
    },
    update_match_nda_upload: (data) => {
      return dispatch(PARTNER_API.update_match_nda_upload(data));
    },
  };
};

class ProjectProposalMatch extends React.Component {
  constructor(props) {
    super();
    this.state = {
      fields: {},
      form: {},
      errors: {},
      task: {},
      declineIsOpen: false,
    };
  }

  componentDidMount() {
    this.props.get_partner();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value = this.props[item] || "";
        } else if (
          typeof nextState.form !== "undefined" &&
          typeof this.props[item] !== "undefined"
        ) {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = checked;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onSubmitForm(optOut, e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      optOut: optOut,
    };

    var submit_task = new Task();

    submit_task.start();

    this.setState({ task: submit_task });

    this.props.update_match_opt_out(data).then(({ task, errors }) => {
      this.setState({ task: task, errors: errors });
    });
  }

  render() {
    const partner = this.props.partner || {};
    const project = this.props.project || {};

    const projectMatch =
      (partner.projectMatches &&
        partner.projectMatches.find(
          (item) => item.projectId == this.props.match.params.projectId
        )) ||
      {};

    console.log("projectMatch", projectMatch);

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-">
                <p className="title">
                  {I18n.get("Indicação Projeto")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6"></div>
            </div>
          </div>
        </div>
        <div
          className="client-step client-step-1 passo2 project-definition"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <br className="brm" />
          <br className="brm" />
          <br className="brm" />
          <br className="brm" />

          <div
            className="container client-container-1"
            style={{ paddingTop: "100px" }}
          >
            <CardBorder>
              <ProposalMatchHeading {...this.props} quotation={projectMatch} />
            </CardBorder>

            <div className="columns">
              <div className="column is-12">
                {project && project.isCustomEvent && (
                  <>
                    <ProposalNda {...this.props} />

                    <br />
                    <br />

                    {projectMatch.ndaAccepted === true && (
                      <>
                        <EventDetails {...this.props} />

                        {projectMatch.optOut === null && (
                          <>
                            <div>
                              <div style={{ display: "inline-block" }}>
                                <SubmitButtonField
                                  label={I18n.get("Participar do Projeto")}
                                  className="button bt-bpool"
                                  onClick={this.onSubmitForm.bind(this, false)}
                                  task={this.state.task}
                                />
                              </div>

                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "2rem",
                                }}
                              >
                                <SubmitButtonField
                                  label={I18n.get("Não Posso Participar")}
                                  className="button bt-bpool grey"
                                  onClick={this.onSubmitForm.bind(this, true)}
                                  task={this.state.task}
                                  disabled={projectMatch.optOut}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <br />

            {projectMatch && projectMatch.optOut === true && (
              <>
                <div className="msg-accepted no-accepted">
                  <i className="fas fa-ban"></i>{" "}
                  {I18n.get("Você fez optou por não participar desse projeto.")}
                </div>
              </>
            )}

            {projectMatch && projectMatch.optOut === false && (
              <>
                <div className="msg-accepted yes-accepted">
                  <i className="fas fa-check"></i>{" "}
                  {I18n.get(
                    "Você optou por participar. Seu perfil será exibido no matching do projeto e sua empresa poderá ser convidada para uma Chemistry Meeting e/ou fase de proposta, conforme seleção do cliente."
                  )}{" "}
                  {I18n.get(
                    "Agora é aguardar e ficar atento(a) aos emails e notificações da plataforma!"
                  )}
                </div>
              </>
            )}

            <br />

            <NavLink to={`/`} className="button bt-bpool grey previous">
              {I18n.get("Voltar a Home")}
            </NavLink>

            <br />
            <br />
          </div>

          <br />
          <br />
          <br />
          <br />
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(
  connect(mapStateToProps, mapDispatchToProps)(ProjectProposalMatch)
);
