import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { v4 as uuid } from "uuid";
import { useUpload } from "../../../../../hooks/useUpload/useUpload.hook";
import { useInProgress } from "../../../../../hooks/useInProgress/useInProgress.hook";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { Drawer as DrawerComp } from "../../../../../components/UI/Drawer";
import { FileUpload } from "../../../../../components/UI/FileUpload";
import { LinksFiles } from "../../../../../components/UI/LinksFiles";

export const Drawer = ({
  open,
  handleCloseDrawer,
  urlsCompletion,
  filesCompletionProject,
  projectId,
  reload,
  soliciteConclusion,
}) => {
  const auth = useSelector((state) => state.auth);
  const { uploadFile: uploadFileS3 } = useUpload();
  const { uploadFile, deleteFile, addUrls } = useInProgress();
  const [filesInternal, setFilesInternal] = useState([]);
  const [linksInternal, setLinksInternal] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);

  const closeDrawer = ({ reload }) => {
    setFilesInternal([]);
    setLinksInternal([]);
    handleCloseDrawer({ reload });
  };

  const uploadFiles = async (files) => {
    setLoadingFiles(true);

    try {
      let filesToUpload = [];
      if (files?.length > 0) {
        await Promise.all(
          files.map(async (file) => {
            const result = await uploadFileS3({
              file: file,
              idUser: auth?.is_client_or_client_user
                ? `${auth?.clientId}`
                : `${auth?.partnerId}`,
              fieldId: `${
                auth?.is_client_or_client_user ? "cliente" : "parceiro"
              }-${projectId}-files`,
            });
            filesToUpload.push(result);
          })
        );
      }

      if (filesToUpload?.length > 0) {
        await Promise.all(
          filesToUpload.map(async (file) => {
            await uploadFile({
              projectId: projectId,
              name: file?.name,
              url: file?.url,
              type: file?.type,
              size: file?.size,
              uploadedDate: file?.uploadedDate,
              projectCompletionFile: true,
            });
          })
        );
      }
      notification.success({
        message: I18n.get("Sucesso!"),
        description: I18n.get("Os arquivos foram salvos."),
        duration: 3,
      });

      if (linksInternal?.length) {
        const objSend = {
          projectId: projectId,
          urls: linksInternal?.map((url) => url.url),
        };
        const response = await addUrls(objSend);
        if (response?.success) {
          notification.success({
            message: I18n.get("Sucesso!"),
            description: I18n.get(
              linksInternal?.length > 1
                ? "Links adicionados."
                : "Link adicionado."
            ),
            duration: 3,
          });
        }
      }
    } catch (error) {
      notification.success({
        message: I18n.get("Erro"),
        description: I18n.get("Erro ao salvar os arquivos."),
        duration: 3,
      });
    } finally {
      setLoadingFiles(false);
      closeDrawer({ reload: false });
      await soliciteConclusion();
    }
  };

  const deleteFiles = async (fileOrId) => {
    if (fileOrId?.name) {
      const updatedFiles = filesInternal.filter((file) => file !== fileOrId);
      setFilesInternal(updatedFiles);
    } else {
      setLoadingFiles(true);
      const info = {
        projectId: projectId,
        id: fileOrId,
      };

      const response = await deleteFile(info);

      if (response?.success) {
        notification.success({
          message: I18n.get("Sucesso!"),
          description: I18n.get("O arquivo foi deletado."),
          duration: 3,
        });
      } else {
        notification.success({
          message: I18n.get("Erro"),
          description: I18n.get("Erro ao deletar o arquivo."),
          duration: 3,
        });
      }
      setLoadingFiles(false);
      await reload();
    }
  };

  const handleAddFiles = (files) => {
    setFilesInternal(files);
  };

  const cbLinks = (links) => {
    setLinksInternal(links);
  };

  const hasDisabledSave = () => {
    if (filesInternal?.length) {
      return false;
    } else if (linksInternal?.length) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <DrawerComp
      open={open}
      handleClose={() => closeDrawer({ reload: false })}
      buttons={
        <>
          <ButtonBpool
            text={I18n.get("Cancelar")}
            className=""
            theme="tertiary"
            onClick={() => closeDrawer({ reload: false })}
          />
          <div style={{ marginLeft: 10 }}>
            <ButtonBpool
              text={I18n.get("Salvar")}
              className=""
              theme="primary"
              disabled={hasDisabledSave()}
              loading={loadingFiles}
              onClick={() => uploadFiles(filesInternal)}
            />
          </div>
        </>
      }
    >
      <div>
        <strong style={{ fontSize: 17 }}>
          {I18n.get(
            "Para solicitar a conclusão do projeto, você precisará fornecer prova entrega do escopo acordado, envie abaixo os documentos."
          )}
        </strong>

        <p style={{ fontSize: 14, marginTop: 30 }}>
          {I18n.get(
            "Envie os arquivos para a pasta do projeto A. Pode ser em PDF, JPG, PNG WORD, PPT ou link"
          )}
        </p>

        <div style={{ marginTop: 15 }}>
          <FileUpload
            isLoading={loadingFiles}
            cbFiles={(files) => handleAddFiles(files)}
            initialFiles={
              filesCompletionProject?.length ? filesCompletionProject : []
            }
            cbDeleteFile={(fileOrId) => deleteFiles(fileOrId)}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <LinksFiles
            isLoading={loadingFiles}
            projectId={projectId}
            cbLinks={cbLinks}
            notSendInternal
            urlsDefault={urlsCompletion?.length ? urlsCompletion : []}
          />
        </div>
      </div>
    </DrawerComp>
  );
};
