import React, { useEffect, useState, useRef } from "react";
import { I18n } from "aws-amplify";
import { notification } from "antd";
import { ButtonBpool } from "../ButtonBpool";
import IcoUpload from "./svgs/upload.svg";

import { FileList } from "./FileList";

import * as S from "./styles";

// JPG: type: "image/jpeg"
// PNG: type: "image/png"
// PDF: type: "application/pdf"
// XLSX: type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

export const FileUpload = ({
  multiple = true,
  initialFiles = [],
  isLoading,
  cbFiles,
  cbDeleteFile,
  acceptedTypes,
  hasPropsItemListCanBeDeleted,
}) => {
  const fileInputRef = useRef(null);
  const [disabledUpload, setDisabledUpload] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [key, setKey] = useState(0);
  const [acceptedTypesFiles, setAcceptedTypesFiles] = useState([
    {
      type: "image/jpeg",
      label: "JPG",
    },
    {
      type: "image/png",
      label: "PNG",
    },
    {
      type: "application/pdf",
      label: "PDF",
    },
  ]);

  useEffect(() => {
    if (acceptedTypes?.length) {
      setAcceptedTypesFiles(acceptedTypes);
    }
  }, [acceptedTypes]);

  function filterValidFiles(files) {
    const acceptedTypesOfFiles = acceptedTypesFiles.map(
      (acceptedFile) => acceptedFile.type
    );
    return files.filter((file) => acceptedTypesOfFiles.includes(file.type));
  }

  const handleFileChange = (event) => {
    const validFiles = filterValidFiles(Array.from(event.target.files));
    const numberOfFilesHandle = Array.from(event.target.files)?.length;
    const numberOfValidFiles = validFiles?.length;

    if (numberOfValidFiles > 0) {
      setFiles([...files, ...validFiles]);

      if (multiple) {
        cbFiles(validFiles);
      } else {
        cbFiles(validFiles[0]);
      }
    }
    const diffValidFilesAndUploadFiles =
      numberOfFilesHandle - numberOfValidFiles;

    if (diffValidFilesAndUploadFiles > 0) {
      const filesPermitExtensions = acceptedTypesFiles.map(
        (item) => item.label
      );
      let filesPermit = filesPermitExtensions.toString();

      const msgError =
        diffValidFilesAndUploadFiles > 1
          ? I18n.get(
              "Alguns arquivos não foram aceitos. Arquivos aceitos são:" +
                " " +
                filesPermit
            )
          : I18n.get(
              "1 arquivo não foi aceito. Arquivos aceitos são:" +
                " " +
                filesPermit
            );
      notification.error({
        message: I18n.get("Erro formato de arquivos"),
        description: msgError,
        duration: 3,
      });
    }
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    const validFiles = filterValidFiles(Array.from(event.dataTransfer.files));
    const numberOfFilesHandle = Array.from(event.dataTransfer.files)?.length;
    const numberOfValidFiles = validFiles?.length;

    if (numberOfValidFiles > 0) {
      setFiles([...files, ...validFiles]);

      if (multiple) {
        cbFiles(validFiles);
      } else {
        cbFiles(validFiles[0]);
      }
    }
    const diffValidFilesAndUploadFiles =
      numberOfFilesHandle - numberOfValidFiles;

    if (diffValidFilesAndUploadFiles > 0) {
      const filesPermitExtensions = acceptedTypesFiles.map(
        (item) => item.label
      );
      let filesPermit = filesPermitExtensions.toString();

      const msgError =
        diffValidFilesAndUploadFiles > 1
          ? I18n.get(
              "Alguns arquivos não foram aceitos. Arquivos aceitos são:" +
                " " +
                filesPermit
            )
          : I18n.get(
              "1 arquivo não foi aceito. Arquivos aceitos são:" +
                " " +
                filesPermit
            );
      notification.error({
        message: I18n.get("Erro formato de arquivos"),
        description: msgError,
        duration: 3,
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrag = (enter) => {
    setIsDragging(enter);
  };

  const handleClick = () => {
    if (fileInputRef?.current) {
      return fileInputRef?.current?.click();
    }
  };

  const handleDelete = async (fileToDelete) => {
    if (fileToDelete?.id) {
      await cbDeleteFile(fileToDelete?.id);
    } else {
      await cbDeleteFile(fileToDelete);
    }
    const updatedFiles = files.filter((file) => file !== fileToDelete);
    setFiles(updatedFiles);
    setKey((prevKey) => prevKey + 1);
  };

  function getAcceptedFileLabels(filesExtensions) {
    if (filesExtensions?.length) {
      const labels = filesExtensions?.map((file) => file.label);
      return labels.join(", ");
    }
  }

  useEffect(() => {
    if (initialFiles?.length) {
      setFiles(initialFiles);
    }
  }, [initialFiles]);

  useEffect(() => {
    if (!isLoading) {
      if (!multiple) {
        files?.length >= 1 ? setDisabledUpload(true) : setDisabledUpload(false);
      } else {
        setDisabledUpload(false);
      }
    }
  }, [files, multiple, isLoading]);

  return (
    <>
      <S.Wrapper>
        <label
          for="images"
          className={`drop-container ${isDragging ? "is-dragging" : ""} ${
            disabledUpload ? "disabled-input" : ""
          }`}
          id="dropcontainer"
          onDrop={disabledUpload ? () => true : (e) => handleDrop(e)}
          onDragOver={disabledUpload ? () => true : (e) => handleDragOver(e)}
          onDragEnter={disabledUpload ? () => true : () => handleDrag(true)}
          onDragLeave={disabledUpload ? () => true : () => handleDrag(false)}
          onClick={disabledUpload ? () => true : (e) => handleClick(e)}
          onChange={disabledUpload ? () => true : (e) => handleFileChange(e)}
          key={key}
        >
          <img src={IcoUpload} alt="upload icon" />
          <span className="drop-title">
            {I18n.get("Arraste e solte seu arquivo ou")}
          </span>
          <span className="drop-extensions">
            {getAcceptedFileLabels(acceptedTypesFiles)}
          </span>
          <ButtonBpool
            text={I18n.get("Selecione Arquivo")}
            theme="primary"
            loading={isLoading}
            onClick={() => true}
            disabled={disabledUpload}
          />
          <input
            type="file"
            multiple={multiple}
            required
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </label>
        <FileList
          files={files}
          isLoading={isLoading}
          handleDelete={handleDelete}
          hasPropsItemListCanBeDeleted={hasPropsItemListCanBeDeleted}
        />
      </S.Wrapper>
    </>
  );
};
