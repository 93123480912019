import React from "react";

import { I18n } from "aws-amplify";
import styled from "styled-components";

import { Row, Col, Tooltip, Icon } from "antd";

const ItemButton = styled.a`
  width: 100%;
  display: block;
  border: solid 4px #fff;
  border-radius: 15px;
  background: #eeeeee;
  text-align: center;
  padding: 60px 10px;
  min-height: 157px;
  color: #434343;
  font-size: 20px;
  font-weight: bold;
  font-family: "Helvetica Neue LT Std";
  transition: all 0.5s ease;
  margin-bottom: 20px;
  position: relative;

  .Info {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &.pitch {
    img {
      width: 87px;
    }
  }

  &.live {
    img {
      width: 80px;
    }
  }

  img {
    margin-right: 20px;
    width: 60px;
  }

  &:hover {
    color: #434343;
    border: solid 4px #eee;
  }

  &.is-selected {
    border: solid 4px #1883ff;
  }

  @media screen and (max-width: 940px) {
    min-height: 80px;
    img {
      width: 35px;
    }

    &.pitch {
      img {
        width: 40px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    img {
      margin: 0 0 10px;
    }

    span {
      display: block;
    }
  }
`;

const SelectTmarket = (props) => {
  return (
    <div className="selectProject" style={{ paddingTop: "90px" }}>
      <Row>
        <Col sm={6}>
          <ItemButton
            className={props.active == 1 ? "is-selected" : ""}
            disabled={props.disabled}
            onClick={() => props.onSelectMarket(1)}
          >
            <span>{I18n.get("Premium")}</span>

            <span className="Info">
              <Tooltip
                title={I18n.get(
                  "Trabalhe com os parceiros mais experientes e consagrados do nosso pool!"
                )}
              >
                <Icon type="question-circle" theme="filled" />
              </Tooltip>
            </span>
          </ItemButton>
        </Col>

        <Col sm={6}>
          <ItemButton
            className={props.active == 2 ? "is-selected" : ""}
            disabled={props.disabled}
            onClick={() => props.onSelectMarket(2)}
          >
            <span>{I18n.get("Master")}</span>

            <span className="Info">
              <Tooltip
                title={I18n.get(
                  "Trabalhe com opções econômicas para projetos mais táticos."
                )}
              >
                <Icon type="question-circle" theme="filled" />
              </Tooltip>
            </span>
          </ItemButton>
        </Col>
      </Row>
    </div>
  );
};

export default SelectTmarket;
