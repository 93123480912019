import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const InputMaskCustom = styled.div`
  position: relative;
  width: ${(props) => (props.addonBefore ? "calc(100% - 75px)" : "100%")};

  input {
    height: 50px;
    background-color: #ffffff;
    border-radius: ${(props) =>
      props.addonBefore ? "0px 10px 10px 0px" : "10px 10px 10px 10px"};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #515152;
    box-sizing: border-box;
    padding: 0 15px;
    border: solid 1px;
    border-color: #999999;
    width: 100%;
    transition: border-color 0.2s ease-in-out;

    :disabled,
    :read-only {
      border: solid 1px !important;
      border-color: #e7e7e7 !important;
      background-color: #ffffff !important;
      cursor: not-allowed !important;
    }

    ::-webkit-input-placeholder {
      color: transparent !important;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: transparent !important;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent !important;
    }
    :-ms-input-placeholder {
      color: transparent !important;
    }
  }

  .input-error {
    border-color: #ff0000;
  }

  label {
    background-color: #ffffff;
    color: #a4a4a4;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: -12px;
    left: 15px;
    padding: 0 5px;
    pointer-events: none;
    transform: translateY(30px);
    transition: top 0.2s ease-in-out, left 0.2s ease-in-out;

    span.required-input {
      color: #ff0000;
    }
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    top: -35px;
    left: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #515152;
  }

  p.error-input {
    color: #ff0000;
    margin: 5px 0 0 5px;
    font-size: 12px;
    line-height: 12px;
  }
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;

  .select-before {
    min-width: 95px;
    max-width: 160px;
    height: 50px;
    width: auto;

    .prefix {
      margin-left: 3px;
      font-size: 13px;
    }

    .ant-select-selection {
      width: 100%;
      height: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 0px;

      .ant-select-selection__rendered {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0 11px;
      }

      .ant-select-arrow {
        height: 50px;
        display: flex;
        align-items: center;
        right: 0;
        justify-content: flex-end;
        padding-right: 11px;

        i {
          top: 0;
        }
      }
    }
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-input {
    margin-top: 0;
    height: 50px;

    li {
      height: 100%;
    }
  }
`;
