import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import NumericField from '../form/numeric-field';
import TextAreaField from '../form/textarea-field';

import { scrollToTop } from '../../utils/ui';

class ScorecardForm extends React.Component {
    constructor(props) {

        super(props);

        const { partner = {} } = props;

        const bPitch = props.bPitch || {};

        const { id: partnerId = '' } = partner;

        const { scorecard = [], scorecardGrades = {}, scorecardNotes = {} } = bPitch;

        const partnerGrades = scorecardGrades[partnerId] || scorecard;

        const scorecardNotesValue = scorecardNotes && scorecardNotes[partnerId];

        this.state = {
            fields: {
                scorecardNotes: {
                    onChange: this.onChangeForm.bind(this, 'scorecardNotes'),
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'scorecardNotes'),
                    visited: true,
                },
            },
            errors: {},
            form: {
                partnerGrades,
                scorecardNotes: scorecardNotesValue
            },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {}, partner = {} } = this.props;

            const { id: partnerId = '' } = partner;

            const { scorecard = [], scorecardGrades = {} } = bPitch;

            nextState.form.partnerGrades = scorecardGrades[partnerId] || scorecard;

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }


    onGradeChangeForm(topicIndex, itemIndex, partnerId, e) {

        const value = e.target.value;

        if (!!value) {

            const grade = parseInt(value);

            if (grade < 1 || grade > 10) return;
        }

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            // nextState.form.scorecard[topicIndex].children[itemIndex][prop] = value;

            const { partnerGrades = [] } = prevState.form;

            partnerGrades[topicIndex].children[itemIndex].grade = value;

            nextState.form.partnerGrades = partnerGrades;
            nextState.errors.scorecardGrades = null;

            return nextState;
        });
    }


    getData() {

        const { bPitch = {}, partner = {} } = this.props;

        const { id: partnerId = '' } = partner;

        bPitch.scorecardGrades[partnerId] = this.state.form.partnerGrades;

        if (bPitch.scorecardNotes == null){
            bPitch.scorecardNotes = {};
        }

        bPitch.scorecardNotes[partnerId] = this.state.form.scorecardNotes;

        var data = {
            projectId: this.props.match.params.projectId,
            ...bPitch,
        };

        return data;
    }

    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_bpitch(this.getData())
            .then(({ task = {}, errors = {} }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    render() {

        const { bPitch = {}, partner = {} } = this.props;

        const { scorecard = [], scorecardNotes = {} } = bPitch;

        const { id: partnerId = '' } = partner;

        const finalGrade = this.state.form.partnerGrades && this.state.form.partnerGrades.reduce((acc, topic) => {

            const subtotal = (topic && topic.weighedGrade ? parseFloat(topic.weighedGrade) : 0);

            return acc + (subtotal ?? 0);

        }, 0);

        const scorecardTotalError = this.state.errors['scorecardTotal'];

        return (
            <div>

                {scorecardTotalError && scorecardTotalError.errorMessage && <>
                    <p className="help is-danger">
                        {scorecardTotalError.errorMessage}
                    </p>
                </>}

                <div className="columns is-centered">

                    <div className="column is-6" style={{ padding: '0rem', display: 'flex', alignItems: 'center' }}>

                        <span className="tag is-medium is-warning">{I18n.get('Nota Final')}: {finalGrade}</span>

                    </div>

                    <div className="column is-1" style={{ padding: '0rem', display: 'flex', alignItems: 'center' }}>

                        <p className="prop">
                            <span className="name">{I18n.get('Peso')} (%)</span>
                        </p>

                    </div>

                    <div className="column is-1" style={{ padding: '0rem', display: 'flex', alignItems: 'center' }}>

                        <p className="prop">
                            <span className="name">{I18n.get('Nota')} (1 - 10)</span>
                        </p>

                    </div>

                </div>

                {scorecard && scorecard.map((topic, topicIndex) => {

                    const topicLabelError = this.state.errors[`scorecard[${topicIndex}].label`];
                    const topicCountError = this.state.errors[`scorecard[${topicIndex}].children`];

                    const weighedGrade = this.state.form.partnerGrades[topicIndex].weighedGrade;

                    const total = topic && topic.children && topic.children.reduce((acc, item) => acc + (item && item.perc ? parseFloat(item.perc) : 0), 0);

                    return (
                        <div style={{ marginTop: '2rem' }}>
                            <div className="columns is-centered">

                                <div className="column is-6" style={{ padding: '0rem', display: 'flex', alignItems: 'center', borderBottom: 'solid 1px #909090' }}>

                                    <p className="prop">
                                        <span className="name">{topic.label}</span>
                                    </p>

                                </div>

                                <div className="column is-1" style={{ padding: '0rem', display: 'flex', alignItems: 'center', borderBottom: 'solid 1px #909090' }}>

                                    <p className="prop">
                                        <span className="name">{total}%</span>
                                    </p>

                                </div>


                                <div className="column is-1" style={{ padding: '0rem', display: 'flex', alignItems: 'center', borderBottom: 'solid 1px #909090' }}>

                                    <p style={{ fontSize: '80%', color: '#909090' }}>
                                        {I18n.get('Ponderada')}: {weighedGrade}
                                    </p>
                                </div>

                            </div>

                            {topic && topic.children && topic.children.map((item, itemIndex) => {

                                const itemPercError = this.state.errors[`scorecard[${topicIndex}].children[${itemIndex}].perc`];

                                const value = this.state.form.partnerGrades[topicIndex].children[itemIndex].grade;

                                return (
                                    <div className="columns is-centered" style={{ marginTop: '1rem' }}>

                                        <div className="column is-5 is-offset-1" style={{ padding: '0rem', display: 'flex', alignItems: 'center', fontSize: '90%' }}>

                                            {item.label}

                                        </div>


                                        <div className="column is-1" style={{ padding: '0rem', display: 'flex', alignItems: 'center' }}>

                                            {item.perc}%

                                        </div>

                                        <div className="column is-1" style={{ padding: '0rem', display: 'flex', alignItems: 'center' }}>

                                            <NumericField
                                                //placeholder={I18n.get("Nota")}
                                                onChange={this.onGradeChangeForm.bind(this, topicIndex, itemIndex, partnerId)}
                                                onBlur={this.onSave.bind(this)}
                                                value={value}
                                                error={itemPercError}
                                                visited={true}
                                            />
                                        </div>

                                    </div>
                                );

                            })}

                        </div>
                    );
                })}

                <br /><br /><br />

                <TextAreaField 
                    {...this.state.fields.scorecardNotes} 
                    label={I18n.get("Por favor faça sugestões/críticas construtivas para o parceiro.")}
                    value={this.state.form.scorecardNotes} 
                    error={this.state.errors.scorecardNotes}
                />

            </div>
        );
    }
}

export default ScorecardForm;
