import { CHAT_UPDATE } from './actions';
import Config from '../../config';

const api_call = ({ topic, message }) => {
  return (dispatch, getState) => {
    const payload = {
      action: 'chat',
      topic: topic,
      message: message,
      env: 'D',
    };
    const data = JSON.stringify(payload);

    window.sendWsMessage(data);
  };
};

export default api_call;
