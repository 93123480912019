import React from 'react';

const TermsClientES = () => {

    return (
        <div class="termsContainer">
            <div class="termsContent">


                <p><strong>BOUTIQUE</strong><strong> POOL</strong></p>
                <p>T&Eacute;RMINO DE USO</p>
                <p><strong>CLIENTE</strong></p>
                <p>Este T&eacute;rmino de Uso (&ldquo;T&eacute;rmino&rdquo;) rige el uso de la plataforma en l&iacute;nea (&ldquo;Plataforma&rdquo;) creada y desarrollada por BOUTIQUE POOL INTERMEDIA&Ccedil;&Atilde;O E SERVI&Ccedil;OS DE MARKETING LTDA., con sede en la Av. Nove de Julho 5229, 1&ordm; andar, en la ciudad y estado de Estado de S&atilde;o Paulo, CEP 01.406-200, inscrita en el CNPJ/MF bajo el n&ordm; 31.391.925/0001-34 (&ldquo;Boutique Pool&rdquo;), por las empresas que desean contratar servicios creativos de marketing de terceros por medio de la Plataforma (&ldquo;Clientes&rdquo;).</p>
                <p>LA CONCORDANCIA AL PRESENTE TERMINO ES ABSOLUTAMENTE INDISPENSABLE AL USO DE LA PLATAFORMA BOUTIQUE POOL Y SUS SERVICIOS.</p>
                <p>El Cliente debe leer, certificarse de haber entendido y concordar con todas las condiciones establecidas en este T&eacute;rmino, antes de la contrataci&oacute;n de proyectos en la plataforma.</p>
                <ol>
                    <li>Definiciones Para todos los fines y efectos de este T&eacute;rmino, los siguientes t&eacute;rminos y expresiones iniciados en letra may&uacute;scula, en singular o plural, tendr&aacute;n los significados indicados a continuaci&oacute;n, con la debida consideraci&oacute;n a otros t&eacute;rminos definidos a lo largo de este instrumento:</li>
                </ol>
                <p>&ldquo;Socio&rdquo; o &ldquo;Socio Creativo&rdquo; significan tanto agencias de marketing como empresas y estudios para la creaci&oacute;n, planificaci&oacute;n, producci&oacute;n de contenidos, dise&ntilde;o y/o tecnolog&iacute;as en diversos medios, de peque&ntilde;o y mediano porte, que brindan los servicios de marketing disponibles para la contrataci&oacute;n de los Clientes a trav&eacute;s de la Plataforma;</p>
                <p>&ldquo;Matchmaking&rdquo; significan las etapas de selecci&oacute;n de Socios Creativos promovidas por la Plataforma, basadas en el algoritmo propietario desarrollado por la plataforma y criterios espec&iacute;ficos y determinados de acuerdo con el Proyecto solicitado por el Cliente. Al final de la etapa de Matchmaking, la Plataforma indicar&aacute; tres Socios Creativos ideales para la demanda solicitada por el Cliente;</p>
                <p>&ldquo;Curadur&iacute;a" significa la aplicaci&oacute;n de metodolog&iacute;as de an&aacute;lisis y selecci&oacute;n de participantes de la Plataforma de manera recurrente, utilizando la intervenci&oacute;n humana de Boutique Pool, curadores y algoritmos de evaluaci&oacute;n para la aprobaci&oacute;n de los Socios que se encuentren dentro de los est&aacute;ndares requeridos por Boutique Pool, para luego ser indicados y conectados con los Clientes, respetando las particularidades y expectativas necesarias para el buen desarrollo de los negocios entre ambas partes;</p>
                <p>&ldquo;Asset&rdquo; producto creativo cotizado con su alcance detallado, como land page, sitio web, tablero conceptual, concepto creativo, dise&ntilde;o de empaque, dise&ntilde;o de material de punto de venta, entre otros.</p>
                <p>&ldquo;Asset Market&rdquo; lista de productos creativos en un formato de market place categorizados por tipo y especialidad de servicios de comunicaci&oacute;n y marketing presentados al Cliente, debidamente cotizados con detalles completos de alcance. El Cliente puede seleccionar proyectos en el Asset Market (paquetes de assets) o puede configurar su propio proyecto configurando su asset individual.</p>
                <p>&ldquo;Servicio&rdquo; o &ldquo;Proyecto&rdquo; significan los servicios de marketing contratados por el Cliente a los Socios Creativos seleccionados o proyectos desarrollados para los Clientes por los Socios, de manera espec&iacute;fica, mediante el uso de herramientas de la Plataforma. Suma de Assets agrupados. Los Servicios pueden incluir servicios de comunicaci&oacute;n en general, eventos, formaci&oacute;n y/o investigaci&oacute;n, as&iacute; como la creaci&oacute;n de material impreso, electr&oacute;nico, audiovisual o radiof&oacute;nico, sujeto a las protecciones legales inherentes a los Derechos de Autor definidos a lo largo de este T&eacute;rmino.</p>
                <p>&ldquo;Competencia&rdquo; significan etapas de una selecci&oacute;n espec&iacute;fica, diferente al Matchmaking, a trav&eacute;s de las cuales los Clientes pueden invitar a Socios Creativos para presentar propuestas comerciales y creativas para un Proyecto determinado. En tales casos, el Socio Creativo y el Cliente deben adherirse a un t&eacute;rmino espec&iacute;fico, que contendr&aacute; las reglas de la Competencia respectiva.</p>
                <ol start="2">
                    <li>Registro. La Plataforma solo est&aacute; disponible para personas jur&iacute;dicas o personas f&iacute;sicas que tengan capacidad legal para contratar seg&uacute;n la legislaci&oacute;n brasile&ntilde;a. Las personas que no tengan esta capacidad, menores de edad o Clientes cuyo registro est&eacute; suspendido o cancelado no podr&aacute;n utilizar la Plataforma.</li>
                </ol>
                <p>2.1. Para ser Cliente registrado en la Plataforma, es necesario completar el formulario de registro disponible en la Plataforma en todos sus campos con datos v&aacute;lidos, de manera exacta, precisa y veraz ("Registro"). El Cliente debe proporcionar documentos e informaci&oacute;n que demuestren la identidad y capacidad de contratar en nombre de esta persona jur&iacute;dica, seg&uacute;n se le solicite para realizar el Registro. Boutique Pool puede solicitar otros documentos e informaci&oacute;n adicional. El Cliente se compromete a mantener actualizada la informaci&oacute;n incluida en el Registro.</p>
                <p>2.2. La documentaci&oacute;n aportada por el Cliente en el Registro debe estar actualizada, legal y vigente. Boutique Pool no asume ninguna responsabilidad por la inspecci&oacute;n recurrente de los Clientes, por lo que las p&eacute;rdidas derivadas de informaci&oacute;n falsa o inexacta ser&aacute;n responsabilidad exclusiva de los Clientes.</p>
                <p>2.3. Al registrarse, el Cliente debe proporcionar un correo electr&oacute;nico y Boutique Pool proporcionar&aacute; una contrase&ntilde;a temporal para acceder a la Plataforma. Boutique Pool no le pedir&aacute; su contrase&ntilde;a por ning&uacute;n otro medio, ni por ning&uacute;n otro motivo, que no sea para acceder a la Plataforma.</p>
                <p>2.4. El Cliente ser&aacute; el &uacute;nico responsable de todas las operaciones que se realicen en su Registro, debiendo mantener la confidencialidad de su contrase&ntilde;a. Boutique Pool no se responsabiliza de las operaciones realizadas en su Registro. Si identifica el uso o acceso no autorizado de su registro, el Cliente debe informar inmediatamente a Boutique Pool con el prop&oacute;sito de suspender el registro.</p>
                <p>2.5. El Cliente podr&aacute; solicitar la cancelaci&oacute;n de su registro en la Plataforma en cualquier momento, sin perjuicio de las solicitudes de Servicio ya aceptadas por los Socios en el momento de la solicitud de cancelaci&oacute;n, la cual deber&aacute; ser completada con normalidad, como si el registro a tales efectos permaneciera activo, en los t&eacute;rminos del Acuerdo, definidos a continuaci&oacute;n.</p>
                <ol start="3">
                    <li>Uso de la Plataforma Al t&eacute;rmino de su Registro,y de la aprobaci&oacute;n de los valores de la tabla est&aacute;ndar de proyectos y Assets o de sus valores y Assets espec&iacute;ficos, siempre que se encuentren dentro de los est&aacute;ndares que Boutique Pool entienda como adecuados y, finalmente, el cumplimiento de este Plazo, el Cliente podr&aacute; utilizar la Plataforma para contratar Proyectos y/o Servicios de Socios Creativos seleccionados, utilizando las herramientas disponibles en la Plataforma, que incluyen (i) acceso al Asset Market para seleccionar Assets o Proyectos de acuerdo con los Assets y/o Proyectos aprobados; (ii) matchmaking de posibles Socios para la realizaci&oacute;n de Proyectos mediante algoritmos de matching, experiencia de mercado espec&iacute;fica y calificaci&oacute;n profesional; (iii) entorno para la definici&oacute;n de briefing, cronograma y alcance final; (iv) firma digital del T&eacute;rmino Espec&iacute;fico del Proyecto que se define a continuaci&oacute;n; (v) sistema de calificaci&oacute;n mutua para evaluaciones parciales y finales de cada Proyecto; (vi) centralizaci&oacute;n del pago de m&uacute;ltiples socios contratados a trav&eacute;s de la Plataforma; (vii) provisi&oacute;n de un dashboard informativo con las inversiones realizadas a trav&eacute;s de la Plataforma en cada Proyecto y la calificaci&oacute;n promedio de cada socio contratado.</li>
                </ol>
                <p>3.1. Al usar la Plataforma, los Clientes recibir&aacute;n el derecho a un uso oneroso y no exclusivo del software que conforma la Plataforma, para uso limitado a este T&eacute;rmino.</p>
                <p>3.2. Boutique Pool, como plataforma de negocios, har&aacute; sus mejores esfuerzos para curar los mejores esfuerzos posibles con &eacute;tica y calidad creativa para los Servicios demandados por el Cliente y as&iacute; promover el ambiente para que el Servicio se brinde de la mejor manera posible, siendo responsable de auditar las relaciones establecidas entre Cliente y Socio, a trav&eacute;s de la Plataforma, intercambiando y excluyendo Socios Creativos que no presten los Servicios de acuerdo con las reglas de la Plataforma y/o cuando as&iacute; lo solicite el Cliente.</p>
                <p>3.3. La aplicaci&oacute;n de Curadur&iacute;a, Matchmaking y/o Competencia, y la contrataci&oacute;n debe ser realizada de manera &eacute;tica y responsable por las partes involucradas, con la definici&oacute;n de las premisas de contrataci&oacute;n previa a la contrataci&oacute;n de los Servicios. Los socios deben respetar los C&oacute;digos de &Eacute;tica y Anticorrupci&oacute;n de Boutique Pool y de los Clientes.</p>
                <ol start="4">
                    <li>Flujo de la Plataforma. Boutique Pool pondr&aacute; a disposici&oacute;n del Cliente y Socios Creativos, el uso de la Plataforma seg&uacute;n el siguiente flujo:</li>
                </ol>
                <p>IMAGEM FLUXO</p>
                <p>4.1. Para utilizar la Plataforma, el Cliente (con su nombre de usuario y contrase&ntilde;a) seleccionar&aacute; un paquete de Assets con un valor predefinido (aprobado por su &aacute;rea de compras en el registro de la Plataforma) y el algoritmo de coincidencia, como se define a continuaci&oacute;n, indicar&aacute; tres Socios Creativos que informar&aacute;n si pueden o no atender la demanda solicitada seg&uacute;n las condiciones definidas por el Cliente. Luego, el cliente elegir&aacute; con qu&eacute; Socio desea trabajar en funci&oacute;n de su cartera, experiencia y empat&iacute;a.</p>
                <ol start="5">
                    <li>Competencias. En algunos casos, corresponder&aacute; al Boutique Pool, a trav&eacute;s de la inteligencia de la Plataforma, organizar Concursos entre los Socios Creativos, que se llevar&aacute;n a cabo mediante la adhesi&oacute;n de las partes de plazos espec&iacute;ficos a cada Competencia. Las Competencias se realizar&aacute;n en el entorno de la Plataforma y/o presencialmente. Boutique Pool har&aacute; todo lo posible para llevar a cabo las Competencias de forma justa, y corresponde a los Clientes y Socios Creativos adaptarse a las reglas determinadas en la Plataforma.</li>
                </ol>
                <ol start="6">
                    <li>Selecci&oacute;n para la Prestaci&oacute;n del Servicio o Proyecto. Una vez seleccionado para desarrollar un Proyecto o prestar un Servicio, de acuerdo con los procedimientos de la Curadur&iacute;a y Matchmaking realizados, el Socio Creativo recibir&aacute; un correo electr&oacute;nico invit&aacute;ndolo a participar en dicho Proyecto con todos los detalles del Servicio y/o Proyecto, incluyendo tiempo de entrega y valores, entre otras informaciones (&ldquo;Invitaci&oacute;n&rdquo;).</li>
                </ol>
                <p>6.1. El Socio tendr&aacute; la obligaci&oacute;n de indicar si asiste o no a un determinado Proyecto dentro de 1 (un) d&iacute;a h&aacute;bil (entendido hasta la misma hora que el siguiente d&iacute;a h&aacute;bil) contado a partir de la recepci&oacute;n del mensaje de referencia de Boutique Pool, realizado en el entorno de la Plataforma. El silencio de un Socio ser&aacute; interpretado por Boutique Pool como una negativa a aceptar un Proyecto. Si el plazo establecido en este &iacute;tem cae en fin de semana, se considerar&aacute; el primer d&iacute;a h&aacute;bil posterior.</p>
                <p>6.2. Los tres Socios seleccionados que aceptaron la Invitaci&oacute;n se presentar&aacute;n al Cliente y la Plataforma ayudar&aacute; al Cliente a elegir mostrando el portfaolio, el historial y las credenciales de dichos Socios, as&iacute; como estimulando conversaciones cara a cara o haciendo llamadas para que el Cliente pueda tomar la decisi&oacute;n por su cuenta. </p>
                <p>6.3. El Cliente deber&aacute; decidir entre estos Socios cu&aacute;l es el m&aacute;s adecuado para el Servicio o Proyecto dentro del plazo seleccionado en la Plataforma. Si el Cliente no selecciona al Socio dentro del plazo respectivo, el Socio Creativo est&aacute; autorizado a retirarse de ese Matchmaking y/o Competencia espec&iacute;ficos.</p>
                <ol start="7">
                    <li>T&eacute;rmino de Especificaci&oacute;n del Proyecto ("TEP"). Una vez realizado el Matchmaking y seleccionado el Socio por parte del Cliente, se ajusta en la plataforma el alcance final y las respectivas macro etapas del Proyecto, y al momento de la aceptaci&oacute;n final se genera el TEP espec&iacute;fico de dicho contrato, el cual debe ser firmado digitalmente entre Cliente y Socio, con la intervenci&oacute;n de Boutique Pool, estableciendo los derechos y obligaciones espec&iacute;ficos del Cliente y el Socio con respecto a los Servicios y/o Proyecto seleccionado, detallando el Proyecto a desarrollar o el Servicio a brindar al Cliente, incluyendo, plazos y forma de pago de la retribuci&oacute;n del Socio y regulando la relaci&oacute;n entre estas partes.</li>
                </ol>
                <p>7.1. Cualquier Socio Creativo seleccionado por el Cliente para la provisi&oacute;n de Servicios o la ejecuci&oacute;n de Proyectos bajo los t&eacute;rminos de este T&eacute;rmino estar&aacute; vinculado al TEP. Las obligaciones del Cliente asumidas con el Socio Creativo son directamente pagaderas por el Socio Creativo al Cliente.</p>
                <ol start="8">
                    <li>Evaluaci&oacute;n. La Plataforma contendr&aacute; herramientas para la evaluaci&oacute;n del Servicio o Proyecto realizado por el Socio seg&uacute;n cada uno de los pasos definidos al inicio de cada Proyecto/Servicio contratado entre Clientes y Socios. En el caso de evaluaciones inconsistentes con las expectativas y el briefing del Cliente, un Socio en particular debe adaptar los materiales presentados, rehaci&eacute;ndolos de acuerdo a las instrucciones indicadas por escrito por el Cliente respectivo. Mientras se realiza dicho ajuste, se pueden suspender pagos espec&iacute;ficos a este Socio, salvaguardando los montos relacionados con los servicios completados. Una vez que se produzca la conclusi&oacute;n de los ajustes o una determinada etapa de contrataci&oacute;n y tras la evaluaci&oacute;n satisfactoria del Cliente que contrat&oacute; los Servicios, se realizar&aacute; el pago espec&iacute;fico a este Socio.</li>
                </ol>
                <p>8.1. La Plataforma limitar&aacute; la cantidad de veces que un Proyecto se puede rehacer o adaptar, evitando sobrecargar demasiadamente a los Socios. En los casos en que un Cliente determinado exceda las cantidades de ajustes permitidos o, a menudo, cambie el resumen y el alcance de los Servicios en un Proyecto, ser&aacute; a discreci&oacute;n de cada Socio negociar con el cliente una nueva contrataci&oacute;n en la Plataforma, o por la funcionalidad llamada "extensi&oacute;n de Proyectos&rdquo; disponible en la Plataforma.</p>
                <p>8.2. Las evaluaciones realizadas en la Plataforma pueden contener informaci&oacute;n p&uacute;blica, siempre que hayan sido previamente aprobadas por Socios y Clientes, cuando luego estar&aacute;n disponibles para todos los inscritos en la Plataforma. Las evaluaciones deben llevarse a cabo de buena fe y de manera objetiva, indicando los comentarios a los socios de manera concisa. La Plataforma no pondr&aacute; a disposici&oacute;n del p&uacute;blico evaluaciones parciales de Socios Creativos mientras se est&eacute;n llevando a cabo ciertos Proyectos.</p>
                <p>8.3. Si un Socio no est&aacute; de acuerdo con una evaluaci&oacute;n realizada, podr&aacute; solicitar su revisi&oacute;n por parte de Boutique Pool, as&iacute; como la aclaraci&oacute;n de cualquier duda.</p>
                <p>8.4. Boutique Pool podr&aacute; excluir de la Plataforma a los Socios que reciban 3 o m&aacute;s evaluaciones negativas en contratos espec&iacute;ficos, durante un per&iacute;odo de hasta 6 meses. En estos casos, Boutique Pool informar&aacute; al Socio excluido, por escrito, de los motivos de su exclusi&oacute;n. Boutique Pool considerar&aacute; malas evaluaciones cuando el puntaje general otorgado al final del Proyecto sea inferior a 3, regular entre 3.0 y 3.99, bueno entre 4.0 y 4.74 y excelente de 4.75 a 5.</p>
                <ol start="9">
                    <li>Desarrollo y Mantenimiento de la Plataforma. Boutique Pool se encarga de desarrollar y mantener actualizada la Plataforma para que organice las relaciones entre Clientes y Socios, de manera segura y respetando las premisas definidas en cada Proyecto. Boutique Pool puede interrumpir la disponibilidad de la Plataforma en cualquier momento en los cortes programados, sin necesidad de previo aviso a los Clientes y Socios, esforz&aacute;ndose, sin embargo, para que dichos cortes se realicen fuera del horario comercial. Boutique Pool no est&aacute; obligada a mantener la Plataforma disponible indefinidamente, y no existe responsabilidad alguna ante los Clientes y Socios en caso de indisponibilidad no programada.</li>
                </ol>
                <p>9.1. Boutique Pool no ser&aacute; responsable de ning&uacute;n virus que pueda infectar el equipo del Cliente como resultado del acceso, uso o verificaci&oacute;n de la Plataforma o cualquier transferencia de datos, archivos, im&aacute;genes, texto o audio contenidos en ella. El Cliente no podr&aacute; ceder responsabilidad alguna a Boutique Pool, ni exigir el pago por lucro cesante, por p&eacute;rdidas derivadas del incumplimiento de las disposiciones aqu&iacute; contenidas, derivadas de dificultades t&eacute;cnicas o fallas en los sistemas o en Internet. Boutique Pool no garantiza el acceso y uso continuado o ininterrumpido de la Plataforma. Es posible que el sistema no est&eacute; disponible debido a dificultades t&eacute;cnicas o fallas de Internet, o por cualquier otra circunstancia fuera del control de Boutique Pool y en tales casos Boutique Pool buscar&aacute; restablecer la accesibilidad a la Plataforma lo antes posible, sin que esta pueda imputando alg&uacute;n tipo de responsabilidad.</p>
                <p>9.2. Los clientes tienen absolutamente prohibido cualquier acci&oacute;n o uso de un dispositivo, software u otros medios con el prop&oacute;sito de interferir en las actividades y operaciones de la Plataforma. Cualquier interferencia, actividad o intento de violaci&oacute;n o que sea contraria a las leyes, incluidas, entre otras, las que se ocupan de los derechos de propiedad intelectual y/o las prohibiciones estipuladas en este T&eacute;rmino, estar&aacute; sujeta a las acciones legales pertinentes.</p>
                <ol start="10">
                    <li>No Competencia. Cuando lo solicite un Cliente, la Plataforma no incluir&aacute; opcionalmente a Socios que est&eacute;n trabajando con otros clientes en la misma categor&iacute;a que el Cliente. Por tanto, al darse de alta en la Plataforma, cada uno de los Socios Creativos debe indicar las marcas de productos y/o servicios con los que ya puede trabajar (siendo el criterio para ello la facturaci&oacute;n de una Factura en los &uacute;ltimos 3 meses) por existe el establecimiento de pr&aacute;cticas de no competencia y, por lo tanto, un Socio Creativo no desarrolla ning&uacute;n tipo de trabajo para productos y/o servicios de la competencia.</li>
                </ol>
                <p>10.1. A los efectos de establecer pr&aacute;cticas de no competencia, solo se considerar&aacute;n las marcas de productos y/o servicios dentro de la categor&iacute;a dada, con las que trabaja un Socio Creativo, no las empresas que poseen y comercializan las marcas de dichos productos y/o servicios. De esta manera, los Socios Creativos no podr&aacute;n actuar solo para marcas de productos y/o servicios competidores directamente en la misma categor&iacute;a, ni para empresas que son sus fabricantes.</p>
                <p>10.2. Si un Socio Creativo ya tiene acuerdos de no competencia con uno de sus clientes al momento de registrarse y utilizar la Plataforma, deber&aacute; proporcionar esta informaci&oacute;n al iniciar su relaci&oacute;n con Boutique Pool, durante el registro, indicando la opci&oacute;n de &ldquo;no competencia activa".</p>
                <p>10.3. Los Socios Creativos deben mantener actualizado el listado de empresas, productos y servicios con los que mantienen o han mantenido una relaci&oacute;n comercial en los &uacute;ltimos 3 (tres) meses, para que no existan conflictos entre estos y cualesquiera Clientes que puedan ser referidos a los Socios Creativos, en t&eacute;rminos de este art&iacute;culo.</p>
                <p>10.4. Si se requiere un per&iacute;odo de no competencia de un Socio Creativo para que desarrolle servicios para un Cliente en particular, se considerar&aacute; que dicho Socio Creativo ha verificado y aceptado dicha limitaci&oacute;n al aceptar comenzar a trabajar con dicho Cliente, y no existe responsabilidad alguna de Boutique Pool en el caso de que existan demandas sobre el per&iacute;odo de no competencia ya aceptado en un determinado Proyecto.</p>
                <ol start="11">
                    <li>Remuneraci&oacute;n y Forma de Pago. El Cliente remunerar&aacute; a los Socios Creativos en los t&eacute;rminos definidos para cada Servicio o Proyecto contratado, de acuerdo con cada Invitaci&oacute;n y TEP celebrados entre Cliente y Socios, incluso cuando existan pagos escalonados (&ldquo;Remuneraci&oacute;n&rdquo;). Boutique Pool gestionar&aacute; el flujo de pago de los Proyectos contratados, liberando los pagos de acuerdo a la conclusi&oacute;n de etapas predefinidas entre las Partes, reteniendo &uacute;nicamente los montos que pueda caber a trav&eacute;s de la intermediaci&oacute;n de los Servicios contratados.</li>
                </ol>
                <p> 11.1. En caso de intentos de cambiar el alcance por parte del Cliente despu&eacute;s de la aprobaci&oacute;n de un Proyecto, corresponder&aacute; a los Socios Creativos informar a Boutique Pool sobre tales cambios para evitar posibles p&eacute;rdidas. La extensi&oacute;n de los alcances de la Plataforma est&aacute;ndar tambi&eacute;n se puede contratar a trav&eacute;s de la funcionalidad &ldquo;Extensi&oacute;n de Proyectos y Elementos No Estandarizados&rdquo; en la plataforma, a trav&eacute;s del Men&uacute; de &ldquo;Producci&oacute;n&rdquo; dentro de cada Proyecto.</p>
                <p> 11.2. Los pagos realizados por el Cliente ser&aacute;n dirigidos a Boutique Pool, que gestionar&aacute; todos estos pagos, pas&aacute;ndolos a cada Socio Creativo dentro de las condiciones de cada Proyecto. En determinados Proyectos, el Boutique Pool podr&aacute; gestionar el pago por etapas a un Socio Creativo, ya que cada una de estas etapas se ha completado. Los pagos a los Socios Creativos deben, en cualquier caso, respetar las condiciones de pago de los Clientes.</p>
                <p> 11.3. Las Partes acuerdan que el pago de la Retribuci&oacute;n por parte del Cliente se producir&aacute; seg&uacute;n el flujo que se describe a continuaci&oacute;n.</p>
                <p>(i) El Cliente debe ingresar el n&uacute;mero de pedido u orden de compra (&ldquo;Pedido&rdquo;) cuando corresponda o, autorizar la Facturaci&oacute;n en la plataforma;</p>
                <p>(ii) Despu&eacute;s de realizar el Pedido en la Plataforma o la autorizaci&oacute;n de facturaci&oacute;n, el Socio Creativo ser&aacute; notificado por correo electr&oacute;nico con instrucciones y deber&aacute;: (1) emitir factura contra el Cliente por el monto de Remuneraci&oacute;n otorgada al Socio, de acuerdo al alcance final aprobado y/o TEP direccionado a Boutique Pool, siguiendo las instrucciones de facturaci&oacute;n de la notificaci&oacute;n y especificando en el cuerpo de dicha factura que los montos estar&aacute; en nombre del intermediador de servicios de Boutique Pool, contra la nota de intermediaci&oacute;n; (2) cargue la factura emitida en la Plataforma y (3) comun&iacute;quese de inmediato con Boutique Pool por el correo electr&oacute;nico suporte.parceiro@bpool.co en caso de cualquier inconveniente t&eacute;cnico que perjudique o inviabilice la emisi&oacute;n de la Factura;</p>
                <p>(iii) Boutique Pool, a su vez, emitir&aacute; una factura agregando los Servicios prestados por el Socio Creativo en el campo de "Intermediaci&oacute;n del Servicio" y la Tarifa de Transacci&oacute;n, que se describir&aacute; y de forma transparente en la Invitaci&oacute;n y TEP ("Tarifa de Transacci&oacute;n"), y enviar&aacute; al cliente de acuerdo a las instrucciones insertadas en la plataforma (correo electr&oacute;nico, carga del sistema y/o recibo f&iacute;sico);</p>
                <p>(iv) El Cliente realizar&aacute; el pago &iacute;ntegro de la nota a que se refiere el &iacute;tem (iii) en la cuenta corriente de Boutique Pool y este &uacute;ltimo traspasar&aacute; el valor de sus servicios al socio, de acuerdo al alcance final y/o TEP.</p>
                <p>P&aacute;rrafo &Uacute;nico: Las Partes acuerdan que, especialmente en relaci&oacute;n con las obligaciones contenidas en el p&aacute;rrafo (ii) anterior, el Socio Creativo emitir&aacute; la Factura respectiva y la cargar&aacute; en el enlace provisto por Boutique Pool dentro de las 48 (cuarenta y ocho) horas siguientes a la recepci&oacute;n del correo electr&oacute;nico que contiene instrucciones al respecto. Sujeto a lo dispuesto en el &iacute;tem (3) del p&aacute;rrafo (ii), se acuerda que el incumplimiento por parte del Socio Creativo del per&iacute;odo antes mencionado dar&aacute; lugar a la aplicaci&oacute;n de una penalidad del 2% (dos por ciento) sobre el valor de los servicios, porcentaje al que se incrementar&aacute; en un 0,5% (cero coma cinco por ciento) por d&iacute;a de retraso, a modo de mora, limitado al 5% (cinco por ciento).</p>
                <p>11.4. En caso de retraso en el pago de la Retribuci&oacute;n a trav&eacute;s de las facturas enviadas, recibidas y no impugnadas por el Cliente, el Cliente estar&aacute; sujeto a una multa del 2% (dos por ciento), adem&aacute;s de un inter&eacute;s de demora del 1% (uno por ciento) por mes, se calcula pro rata die. La multa e inter&eacute;s moratorio a que se refiere este &iacute;tem se adeudar&aacute; al Socio Creativo y al Boutique Pool, en proporci&oacute;n a su respectiva remuneraci&oacute;n, de acuerdo con los t&eacute;rminos de contrataci&oacute;n de cada Proyecto o Servicio.</p>
                <p> 11.5. En la fecha de adhesi&oacute;n a este Plazo, el Cliente estar&aacute; exento del pago al Boutique Pool de la tarifa mensual por el uso de la Plataforma (&ldquo;Tarifa Mensual&rdquo;). Sin embargo, el Cliente tiene conocimiento y declara estar de acuerdo con que Boutique Pool puede cobrarle al Cliente la Tarifa Mensual en cualquier momento despu&eacute;s de unirse a este Plazo, previa notificaci&oacute;n al Cliente.</p>
                <p> 11.6. Dependiendo de la cantidad de elementos creativos que se inserten en el sistema, as&iacute; como si es necesario ajustar los criterios de Curadur&iacute;a y contrato, de acuerdo con los requisitos espec&iacute;ficos del Cliente, el Cliente es consciente y declara estar de acuerdo en que una tarifa de instalaci&oacute;n puede ser cobrado por Boutique Pool, previa notificaci&oacute;n al Cliente.</p>
                <ol start="12">
                    <li>Derechos de Autor. Para efectos de este T&eacute;rmino, &ldquo;Derechos de Autor&rdquo; significa los derechos morales y patrimoniales de las creaciones elaboradas durante la prestaci&oacute;n de servicios de marketing en cada Proyecto, siempre que contengan creaciones originales capaces de resaltar una idea o concepto de un producto o servicio. En este sentido, los Derechos de Autor pueden incluir creaciones manifestadas en material impreso, electr&oacute;nico, audiovisual o radiof&oacute;nico. Los Socios Creativos deben conservar la propiedad de los Derechos de Autor de los materiales que pueden enviar para su uso en la Plataforma.</li>
                </ol>
                <p>12.1. Los Socios deben crear conceptos originales que se ofrecer&aacute;n para la contrataci&oacute;n de los Clientes. En caso de verificaci&oacute;n del uso de materiales no originales, los Socios pueden ser excluidos de la Plataforma, sujeto al derecho a indemnizaci&oacute;n que un determinado Cliente que pueda resultar perjudicado pueda buscar contra el Socio. Boutique Pool no tiene responsabilidad por la violaci&oacute;n de los Derechos de Autor de terceros en la prestaci&oacute;n de los Servicios, y la parte lesionada debe reclamar sus derechos al Socio que viol&oacute; dichos derechos.</p>
                <p>12.2. Todas las marcas, nombres comerciales, nombres de dominio y dem&aacute;s signos distintivos de Boutique Pool en la Plataforma, as&iacute; como los programas, bases de datos, redes y archivos, que permiten al Socio acceder y utilizar la Plataforma, son propiedad de Boutique Pool y est&aacute;n protegidos por leyes y tratados internacionales de derechos de autor, marcas, patentes, modelos y dise&ntilde;os industriales. Queda prohibido el mal uso y reproducci&oacute;n total o parcial de dicho contenido, salvo autorizaci&oacute;n expresa de Boutique Pool.</p>
                <p>12.3. Cualquier injerencia, intento o actividad que viole o contravenga las leyes de derechos de propiedad intelectual y/o las prohibiciones estipuladas en este T&eacute;rmino, har&aacute; responsable a la persona de las acciones legales pertinentes, as&iacute; como de las sanciones aqu&iacute; previstas, siendo tambi&eacute;n responsable de las indemnizaciones por posibles sanciones.</p>
                <ol start="13">
                    <li>Cesi&oacute;n de Derechos de Autor. Los materiales sujetos a Derechos de Autor se asignar&aacute;n a los clientes cuando se seleccione un Socio en particular en una Curadur&iacute;a, Matchmaking o Competencia espec&iacute;ficos y el Servicio brindado. Tal cesi&oacute;n es costeada bajo los t&eacute;rminos de cada contrato, espec&iacute;fico para uso en cada Proyecto contratado y limitado a los usos previstos en cada Proyecto. En ausencia de una definici&oacute;n espec&iacute;fica, la cesi&oacute;n se considerar&aacute; onerosa e ilimitada, definitiva y cubrir&aacute; todos los materiales producidos por el Socio Creativo que est&eacute;n sujetos a Derechos de Autor.</li>
                </ol>
                <p>13.1. La cesi&oacute;n de los Derechos de Autor debe ser realizada por el Socio con la garant&iacute;a de que obtuvo los derechos de los autores de los materiales contenidos en los Servicios, individuos, que elaboraron estos materiales. En caso de ausencia en la obtenci&oacute;n de la cesi&oacute;n de particulares, corresponder&aacute; a cada Cliente decidir entre obtener la cesi&oacute;n de estos Derechos de Autor de forma adicional o cancelar la contrataci&oacute;n de un determinado Socio. Boutique Pool tendr&aacute; la exclusi&oacute;n de este Socio de la Plataforma, a solicitud del Cliente, si el Socio no proporciona al Cliente los documentos que demuestren que los Derechos de Autor le ha sido debidamente asignado. No se asignan derechos morales sobre los materiales de marketing, de conformidad con el art&iacute;culo 24 de la Ley de Derechos de Autor (Ley 9810/98), y es responsabilidad de los Clientes nombrar a sus creadores en la exhibici&oacute;n de los materiales de marketing que se han creado.</p>
                <ol start="14">
                    <li>Actuaci&oacute;n de Boutique Pool. Boutique Pool act&uacute;a en la conexi&oacute;n entre el Cliente y el Socio y su responsabilidad se limita a los servicios prestados por &eacute;l a trav&eacute;s de la Plataforma, como se describe en este T&eacute;rmino.</li>
                </ol>
                <p>14.1. El Cliente es consciente y declara estar de acuerdo que el Boutique Pool pondr&aacute; el mejor equipo y tecnolog&iacute;a en la realizaci&oacute;n de la Curadur&iacute;a, Matchmaking y Competencia, haciendo siempre sus mejores esfuerzos para indicar los mejores Socios para la prestaci&oacute;n de los Servicios a los Clientes, pero no puede responsabilizarse directamente por cualquier da&ntilde;o causado a los Clientes por los Socios en la prestaci&oacute;n de Servicios.</p>
                <p>14.2. En cualquier caso de Servicio prestado de manera insatisfactoria al Cliente, Boutique Pool tomar&aacute; las medidas necesarias para resolver cualquier inconsistencia en la prestaci&oacute;n de los Servicios, seg&uacute;n lo dispuesto en el punto 8 anterior, promoviendo el intercambio del Socio si corresponde, as&iacute; como la retenci&oacute;n y/o devoluci&oacute;n de las cantidades abonadas por el Cliente, de acuerdo con las reglas de la Plataforma aplicables al caso vigente en el momento del evento.</p>
                <p>14.3. En el caso de un conflicto entre el Cliente y el Socio, Boutique Pool har&aacute; todo lo posible para resolver dicho conflicto de acuerdo con las reglas predefinidas de la Plataforma y las definiciones de TEP, pero no puede, sin embargo, ser responsable del pago de da&ntilde;os, incluidos, entre otros, da&ntilde;os materiales y morales y lucros cesantes.</p>
                <p>14.4. El Cliente podr&aacute; tomar las medidas legales necesarias directamente ante el Socio, en caso de que desee obtener una indemnizaci&oacute;n por los da&ntilde;os materiales y morales, ocasionados, a s&iacute; mismo o a terceros.</p>
                <p>14.5. Sin perjuicio de las disposiciones anteriores, en cualquier caso, la responsabilidad de Boutique Pool bajo este instrumento se limita al monto de la Retribuci&oacute;n por cada Servicio/Proyecto contratado en la Plataforma.</p>
                <ol start="15">
                    <li>Exclusi&oacute;n de la Plataforma. Boutique Pool puede excluir de la Plataforma a Clientes y Socios que no act&uacute;en de acuerdo con estos T&eacute;rminos, la &eacute;tica o la ley del pa&iacute;s donde est&aacute; registrado fiscalmente el socio. En el caso de que el Boutique Pool tenga conocimiento de una negociaci&oacute;n o intento de negociaci&oacute;n entre Clientes y Socios fuera del entorno de la Plataforma, despu&eacute;s de que se haya producido su inicio en el entorno de la Plataforma, la exclusi&oacute;n se realizar&aacute; en forma inmediata.</li>
                </ol>
                <p>15.1. En caso de exclusi&oacute;n durante un procedimiento de Curadur&iacute;a, Matchmaking o Competencia, Boutique Pool puede suspender estos procedimientos sin la contrataci&oacute;n efectiva de un Socio (en caso de exclusi&oacute;n de un Cliente), o suspender la participaci&oacute;n de un Socio en un procedimiento en particular de Curadur&iacute;a, Matchmaking o Competencia, sin devoluci&oacute;n de cantidades incurridas por la Parte que puedan ser excluidas de la Plataforma.</p>
                <p>15.2. A los efectos de esta cl&aacute;usula, en los casos en los que el Boutique Pool no haya sido remunerado, tambi&eacute;n ser&aacute; debido a su puesta en servicio prevista para cada Proyecto, por parte del Cliente en caso de que el Proyecto a&uacute;n no haya sido pagado o por el Socio, si ya ha recibido las cantidades de este Proyecto, sin perjuicio de las dem&aacute;s p&eacute;rdidas y da&ntilde;os que pudieran requerirse.</p>
                <p>15.3. La &uacute;nica excepci&oacute;n a la regla anterior ser&aacute; en el caso de que un Socio Creativo determinado reciba la oferta de un Proyecto por parte de Boutique Pool y se encuentre que el Proyecto ya ha mantenido una relaci&oacute;n comercial con el Cliente seleccionado por Boutique Pool en los 12 meses anteriores. En este caso, el Boutique Pool debe ser informado de inmediato para que pueda cambiar las condiciones de la relaci&oacute;n directa entre el Cliente y el Socio Creativo, observando que en este caso la remuneraci&oacute;n de la Plataforma solo ser&aacute; considerada para Proyectos que se inicien desde el uso de la Plataforma. Si existe una definici&oacute;n del Cliente para que todos los Proyectos con este Socio Creativo se concentren en la Plataforma, a partir de entonces, el Pool Boutique deber&aacute; establecer valores espec&iacute;ficos que contemplen dicho escenario.</p>
                <ol start="16">
                    <li>Incumplimiento contractual. Sin perjuicio de otras medidas, Boutique Pool podr&aacute; advertir, suspender o cancelar, temporal o permanentemente, la cuenta de un Cliente en la Plataforma, en cualquier momento, e iniciar las acciones legales correspondientes: a) si el Cliente (i) no cumple con alguna disposici&oacute;n de este T&eacute;rmino, (ii) no cumplir con sus deberes de Cliente con el Boutique Pool y el Socio, o (iii) realizar actos fraudulentos o intencionales; b) si alguna informaci&oacute;n proporcionada por el Cliente es incorrecta; o c) si Boutique Pool entiende que cualquier actitud del Cliente ha causado da&ntilde;o a terceros o al Boutique Pool o tiene el potencial de hacerlo.</li>
                </ol>
                <p>16.1. El Cliente indemnizar&aacute; al Boutique Pool, sus subsidiarias, sociedades controladas o controladoras, directores, administradores, colaboradores, representantes y empleados por cualquier demanda promovida por otros Clientes, Socios o terceros, que surja de sus actividades en la Plataforma o por su incumplimiento de este Plazo, por violaci&oacute;n de cualquier ley o derechos de terceros, en particular los vinculados a la confidencialidad de la informaci&oacute;n recibida del Cliente, la privacidad de los datos a los que se accede como consecuencia de este instrumento, as&iacute; como la regulaci&oacute;n de las actividades publicitarias.</p>
                <ol start="17">
                    <li>Resoluci&oacute;n de Conflictos. Boutique Pool puede mediar cualquier conflicto entre Clientes y Socios para que se resuelvan de acuerdo con los T&eacute;rminos aqu&iacute; establecidos y aplicando la ley del pa&iacute;s donde se preste el servicio,adoptando m&eacute;todos que sean transparentes para Clientes y Socios y podr&aacute; retener los montos involucrados hasta que el conflicto sea entre Clientes y Socios.</li>
                </ol>
                <p>17.1. En todas las mediaciones, todos los implicados se esforzar&aacute;n por que los tr&aacute;mites se lleven a cabo y finalicen en un plazo m&aacute;ximo de 30 d&iacute;as desde el inicio.</p>
                <p>17.2. Si alg&uacute;n conflicto no se resuelve mediante la mediaci&oacute;n prevista en este &iacute;tem en un plazo m&aacute;ximo de 60 d&iacute;as desde el inicio, dicho conflicto(s) deber&aacute; resolverse mediante arbitraje, en los t&eacute;rminos del Reglamento de ARBITRANET, por un tribunal arbitral integrado por 3 (tres) &aacute;rbitros, quienes ser&aacute;n nombrados de conformidad con lo dispuesto en dicho reglamento. El arbitraje se llevar&aacute; a cabo en idioma portugu&eacute;s y deber&aacute; tener lugar en la ciudad de S&atilde;o Paulo, Estado de S&atilde;o Paulo, Brasil.</p>
                <p>17.3. Sin perjuicio de lo dispuesto en la Cl&aacute;usula 18.2. arriba, las Partes reconocen que cualquiera de ellas puede apelar al Poder Judicial exclusivamente por las siguientes medidas, y tales medidas no deben ser interpretadas como una renuncia por parte de las Partes al procedimiento de arbitraje: (i) para obtener medidas preliminares y de protecci&oacute;n antes de la confirmaci&oacute;n por el tribunal arbitral; (ii) para ejecutar cualquier decisi&oacute;n del tribunal arbitral, incluida la sentencia final; (iii) para la ejecuci&oacute;n espec&iacute;fica de este instrumento; y (iv) para otros procedimientos expresamente permitidos por la Ley N&deg; 9.307/96, seg&uacute;n enmendada. Para los efectos de este punto, se elige la jurisdicci&oacute;n de la capital de S&atilde;o Paulo.</p>
                <ol start="18">
                    <li>Relaciones Laborales. En ning&uacute;n caso ni bajo ning&uacute;n supuesto se asumir&aacute; la existencia de empleo, ni la presunci&oacute;n de relaci&oacute;n laboral, ni las obligaciones laborales y de seguridad social establecidas entre las Partes, por s&iacute; mismas y/con los representantes o empleados de la otra Parte, o cualquier de las Partes garantizar&aacute; las obligaciones y cargas laborales y sociales de la otra Parte que, en este acto, asume plena responsabilidad por tales obligaciones, incluidas las civiles y penales. Las Partes ser&aacute;n plenamente responsables de los servicios de terceros que eventualmente puedan ser utilizados para cumplir con las obligaciones asumidas en la Plataforma. En este sentido, ser&aacute;n responsables de las obligaciones que imponga la legislaci&oacute;n laboral y de seguridad social, siendo responsables del pago de todos los cargos tributarios, laborales, sociales y de seguridad social de sus empleados asignados en el desempe&ntilde;o de sus actividades.</li>
                </ol>
                <ol start="19">
                    <li>Impuestos. Los impuestos de cualquier naturaleza que se devengan como resultado directo de los Proyectos provistos mediante la contrataci&oacute;n a trav&eacute;s de la Plataforma son responsabilidad exclusiva del contribuyente, seg&uacute;n se define en la norma tributaria.</li>
                </ol>
                <ol start="20">
                    <li>Acuerdo. Este T&eacute;rmino constituye el acuerdo completo entre las Partes, con respecto al uso de la Plataforma y prevalece sobre cualquier acuerdo anterior.</li>
                </ol>
                <ol start="21">
                    <li>Cambios. Este T&eacute;rmino ser&aacute; revisado peri&oacute;dicamente por Boutique Pool, el cual podr&aacute; cambiarlo, excluyendo, modificando o insertando cl&aacute;usulas o condiciones, a su sola discreci&oacute;n. Sin embargo, el Cliente recibir&aacute; una notificaci&oacute;n siempre que se produzca un cambio en las disposiciones de este Plazo para que pueda estar al tanto de las nuevas condiciones. Si no est&aacute; de acuerdo con los cambios, debe cancelar su cuenta y dejar de usar de toda forma a la Plataforma.</li>
                </ol>
                <ol start="22">
                    <li>Anticorrupci&oacute;n. Las Partes cumplir&aacute;n y se asegurar&aacute;n de que todos sus empleados, subcontratistas, consultores, agentes o representantes cumplan con la Ley 12.846/13, as&iacute; como con el c&oacute;digo de &eacute;tica de los Clientes, si lo hubiera. Ninguna de las Partes pagar&aacute;, ofrecer&aacute;, prometer&aacute; o dar&aacute;, directa o indirectamente, ninguna cantidad o cosa de valor, incluidas las cantidades pagadas como resultado de los servicios contratados, a ning&uacute;n empleado o funcionario de un gobierno, empresa o sociedad controlada por el gobierno o propiedad, partido pol&iacute;tico, candidato a un cargo pol&iacute;tico o cualquier otra persona que tenga conocimiento o crea que dicho valor o art&iacute;culo de valor ser&aacute; transferido a alguien, para influir en cualquier acci&oacute;n o decisi&oacute;n de esa persona o de cualquier agencia gubernamental con el prop&oacute;sito de obtener, retener o realizar negocios para cualquiera de las Partes.</li>
                </ol>
                <ol start="23">
                    <li>El Cliente declara y garantiza a Boutique Pool que ha le&iacute;do, entendido y est&aacute; de acuerdo con todas las disposiciones de estos T&eacute;rminos.</li>
                </ol>
                <ol start="24">
                    <li>El Cliente, as&iacute; como sus respectivos representantes legales, declaran estar debidamente autorizados para aceptar este Plazo, en la forma de sus respectivos instrumentos societarios.</li>
                </ol>


            </div>
        </div>
    );
}

export default TermsClientES;
