import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PARTNER_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_partner_invitation = (data) => {

    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.put(`${Config.API_BASE_URL}/partner/projects/${data.projectId}/invitation`, data, config)
                .then(response => {

                    const payload = {
                        ...response.data,
                        task: {
                            result: true
                        }
                    };

                    dispatch({
                        type: PARTNER_UPDATE,
                        payload: payload
                    })

                    return payload;

                })
                .catch(error => {

                    const payload = {
                        errors: error.response.data,
                        task: {
                            result: false
                        }
                    };

                    dispatch({
                        type: PARTNER_UPDATE,
                        payload: {
                            errors: error.response.data,
                            task: { result: false }
                        }
                    })

                    return payload;
                });
        });
    };
}

export default api_update_partner_invitation;