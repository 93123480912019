import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import TabsClient from "./tabs-client.json";
import TabsPartner from "./tabs-partner.json";
import * as S from "./styles";

export const Tabs = ({ extraId, disableFinalize }) => {
  let params = useParams();
  let location = useLocation();
  const auth = useSelector((state) => state.auth);

  const getLastWordAfterLastSlash = (str) => {
    const parts = str?.split('/');
    return parts?.pop() || "";
  };

  const removeLastWordFromPath = (path) => {
    const lastSlashIndex = path.lastIndexOf('/');
    if (lastSlashIndex !== -1) {
      return path.slice(0, lastSlashIndex);
    }
    return path;
  }

  return (
    <S.Wrapper>
      {auth?.is_client_or_client_user ? TabsClient?.map((item, index) => (
        <S.Tab key={item?.id} disabledTab={item?.id === 4 ? disableFinalize : false}>
          <a
            href={`/#${removeLastWordFromPath(location?.pathname)}/${item?.url}`}
            className={getLastWordAfterLastSlash(location?.pathname) === item?.key ? 'active' : ''}
          >
            {item?.label}
          </a>
        </S.Tab>
      )) : TabsPartner?.map((item, index) => (
        <S.Tab key={item?.id}>
          <a
            href={`/#${removeLastWordFromPath(location?.pathname)}/${item?.url}`}
            className={getLastWordAfterLastSlash(location?.pathname) === item?.key ? 'active' : ''}
          >
            {item?.label}
          </a>
        </S.Tab>
      ))}
    </S.Wrapper>
  );
};
