import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { Icon } from 'antd';

import { AUTH_API } from '../../actions/auth/actions';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(AUTH_API.logout());
        }
    };
};

class TopMenuSignup extends React.Component {

    constructor(props) {
        super();

        this.state = {
            isActive: null,
        }

        this.activeMenu = this.activeMenu.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                isActive: false,
            });
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    onLogoutClick(e) {
        e.preventDefault();
        this.props.logout();
    }

    activeMenu(event) {
        event.stopPropagation();
        this.setState({
            isActive: true,
        });
    }

    render() {



        return (
            <div ref={this.setWrapperRef} 
                // onClick={(event) => this.activeMenu(event)} 
                className={`navbar-item  ${this.state.isActive ? 'is-active' : ''}`}
            >
                <a className="navbar-link-old has-text-white" onClick={this.onLogoutClick.bind(this)}>{I18n.get("Sair")} {" "}<Icon type="right-square" theme="filled" /></a>
            </div>
        );


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuSignup);
