import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import RadioMultiField from "../form/radio-multi-field";
import CheckboxMultiField from "../form/checkbox-multi-field";
import TextAreaField from "../form/textarea-field";
import PercentageField from "../form/percentage-field";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

import { Input } from "../../v2/components/UI/Input";
import { Textarea } from "../../v2/components/UI/Textarea";

class ServicesForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        transport: {
          label: I18n.get("Pretende oferecer estrutura de transporte?"),
          onChange: this.onChangeForm.bind(this, "transport"),
        },
        transportType: {
          label: I18n.get("Informe o tipo de transporte"),
          onChange: this.onChangeFormCheckboxMulti.bind(this, "transportType"),
          // onBlur: this.onSave.bind(this),
        },
        transportAirOrigin: {
          label: I18n.get(
            "Por favor informe a origem/percentual do transporte Aéreo"
          ),
          onChange: this.onChangeFormCheckboxMulti.bind(
            this,
            "transportAirOrigin"
          ),
          // onBlur: this.onSave.bind(this),
        },
        transportAirRegion: {
          // onBlur: this.onSave.bind(this),
        },
        transportLandType: {
          label: I18n.get("Por favor informe o tipo de transporte Terrestre"),
          onChange: this.onChangeFormCheckboxMulti.bind(
            this,
            "transportLandType"
          ),
          // onBlur: this.onSave.bind(this),
        },
        transportLandRegion: {
          // onBlur: this.onSave.bind(this),
        },
        accommodationType: {
          label: I18n.get("Hospedagem?"),
          onChange: this.onChangeForm.bind(this, "accommodationType"),
          // onBlur: this.onSave.bind(this),
        },
        accommodationDesc: {
          label: I18n.get("Por favor indicar o número de diárias e a região"),
          onChange: this.onChangeForm.bind(this, "accommodationDesc"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeTransportAirRegion(region, e) {
    const value = e.target ? e.target.value : e;
    let regiao = region.toLowerCase();

    console.log(">>>", regiao, e, value);

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form.transportAirRegion[regiao] = value;

        if (nextState.errors.transportAirRegion) {
          delete nextState.errors.transportAirRegion[regiao];
        }

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeTransportLandRegion(region, e) {
    const value = e.target.value;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form.transportLandRegion[region] = value;

        if (nextState.errors.transportLandRegion) {
          delete nextState.errors.transportLandRegion[region];
        }

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckbox(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop].value = [...nextState.form[prop].value, value];
        } else {
          nextState.form[prop].value = nextState.form[prop].value.filter(
            (v) => v !== value
          );
        }

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const transportValues =
      this.props.bp.event_TRANSPORT &&
      this.props.bp.event_TRANSPORT.map((item) => {
        return item;
      });

    const transportTypeValues =
      this.props.bp.event_TRANSPORT_TYPES &&
      this.props.bp.event_TRANSPORT_TYPES.map((item) => {
        return item;
      });

    const transportTypeIsAirEnabled =
      this.state.form.transportType.indexOf("AIR") > -1;
    const transportTypeIsLandEnabled =
      this.state.form.transportType.indexOf("LAND") > -1;

    const transportAirOriginValues = constants.EVENT_REGIONS.map((region) => {
      const item = { ...region };

      const isSelected =
        this.state.form.transportAirOrigin.indexOf(item.code) > -1;

      const code = item.code.toLowerCase();

      item.field = (
        // <PercentageField
        //   className="field"
        //   {...this.state.fields.transportAirRegion}
        //   onChange={this.onChangeTransportAirRegion.bind(this, code)}
        //   value={this.state.form.transportAirRegion[code]}
        //   error={
        //     isSelected &&
        //     this.state.errors.transportAirRegion &&
        //     this.state.errors.transportAirRegion[code]
        //   }
        //   disabled={!isSelected}
        // />
        <div>
          <span>
            <Input
              onChange={(code) =>
                this.onChangeTransportAirRegion(region.code, code)
              }
              value={this.state.form.transportAirRegion[code.toLowerCase()]}
              disabled={!isSelected}
              error={
                isSelected &&
                this.state.errors.transportAirRegion &&
                this.state.errors.transportAirRegion[code] &&
                this.state.errors.transportAirRegion[code].errorMessage
              }
              suffix={"%"}
            />
          </span>
        </div>
      );

      return item;
    });

    const transportLandTypeValues = constants.EVENT_TRANSPORT_LAND_TYPES.map(
      (item) => {
        //gambi trandução
        item.label = I18n.get(item.label);

        return item;
      }
    );

    const accommodationTypeValues = constants.EVENT_ACCOMMODATION_TYPES.map(
      (item) => {
        //gambi trandução
        item.label = I18n.get(item.label);

        return item;
      }
    );

    const accommodationDescEnabled = this.state.form.accommodationType == "YES";

    console.log(
      "this.state.form.transportAirRegion",
      this.state.form.transportAirRegion
    );

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Logística")}</h1>

        <div className="bp-event-section bpForce mini">
          <RadioMultiField
            {...this.state.fields.transport}
            value={this.state.form.transport}
            error={this.state.errors.transport}
            values={transportValues}
          />

          <br />

          {new String(this.state.form.transport) == "true" && (
            <>
              <CheckboxMultiField
                {...this.state.fields.transportType}
                value={this.state.form.transportType}
                error={this.state.errors.transportType}
                values={transportTypeValues}
              />

              <br style={{ clear: "both" }} />
            </>
          )}

          {transportTypeIsAirEnabled && (
            <>
              <br />

              <CheckboxMultiField
                {...this.state.fields.transportAirOrigin}
                value={this.state.form.transportAirOrigin}
                error={this.state.errors.transportAirOrigin}
                values={transportAirOriginValues}
              />

              <br style={{ clear: "both" }} />
            </>
          )}

          {transportTypeIsLandEnabled && (
            <>
              <br />

              <CheckboxMultiField
                {...this.state.fields.transportLandType}
                value={this.state.form.transportLandType}
                error={this.state.errors.transportLandType}
                values={transportLandTypeValues}
              />

              <br style={{ clear: "both" }} />
            </>
          )}

          <br />

          <RadioMultiField
            {...this.state.fields.accommodationType}
            value={this.state.form.accommodationType}
            error={this.state.errors.accommodationType}
            values={accommodationTypeValues}
          />

          <br style={{ clear: "both" }} />

          {/* <TextField
            className="field mobile-small"
            {...this.state.fields.accommodationDesc}
            value={this.state.form.accommodationDesc}
            error={this.state.errors.accommodationDesc}
            style={{ width: "50%" }}
          /> */}

          <div style={{ width: "50%" }}>
            <Input
              onChange={(value) =>
                this.state?.fields?.accommodationDesc.onChange(value)
              }
              placeholder={this.state.fields.accommodationDesc.label}
              value={this.state.form.accommodationDesc}
              disabled={!accommodationDescEnabled}
              error={this.state.errors.accommodationDesc?.errorMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesForm;
