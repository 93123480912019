import React from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';

import { BPitchTypeNames } from '../../model';

import IcoInicio from '../../image/icot6-dark.svg';
import IcoStatus from '../../image/icot8-dark.svg';
import IcoPrazo from '../../image/icot7-dark.svg';
import IcoTermino from '../../image/ico-new-termino.svg';

import { ViewPay } from '../../utils/helpers'


class ReviewStep4 extends React.Component {
    constructor(props) {

        super();
        this.state = {
            view: false
        }
    }

   
      

    getPaymentInDays() {

        return this.props.client && this.props.client.company && this.props.client.company.paymentDefaultTermDays;
    }

    getNonCompete(days) {

        var txt = I18n.get("Nenhum");

        if (days == 0)
            txt = I18n.get("Durante o projeto");

        if (days > 0)
            txt = I18n.get("Durante o projeto + @@days@@ dias").replace("@@days@@", days);

        return txt;
    }

    render() {

        const step = this.props.isCreative ? 5 : 4;

        const bPitch = this.props.bPitch || {};

        const { pitchType } = bPitch;


        return (
            <div>

                <h1 className="title-bpool small">{I18n.get("Detalhes")}</h1>

                <div className="card-boxes">
                    <div className="card-row">
                        <div className="card">
                            <div className="card-intro-icon not-for-mobile">
                                <img src={IcoInicio} width="56"  />
                            </div>
                            <div className="card-content">
                                <p>
                                    <strong>{I18n.get("Previsão do Projeto")}</strong>
                                </p>
                                {this.props.isCreative && <p>
                                    <strong>{I18n.get("Início:")}</strong> {this.props.estimatedStartDate}<br />
                                    <strong>{I18n.get("Término:")}</strong> {this.props.estimatedDurationInMonths ? this.props.estimatedDurationInMonths : "0"} {I18n.get("mês(es) e")} {this.props.estimatedDurationInWeeks ? this.props.estimatedDurationInWeeks : "0"} {I18n.get("semana(s)")}
                                </p>}
                                {this.props.isEvent && this.props.scheduleType == 'DATE' && <p>
                                    <strong>{I18n.get("Data evento:")}</strong> {this.props.scheduleDate}<br />
                                </p>}

                                {this.props.isEvent && this.props.scheduleType == 'PERIOD' && <p>
                                    <strong>{I18n.get("Data evento:")}</strong> {this.props.scheduleStartDate} - {this.props.scheduleEndDate}<br />
                                </p>}

                                {this.props.isBPitch && <p>
                                    <strong>{I18n.get("Tipo:")}</strong> <span className="flag-blue"> {I18n.get(BPitchTypeNames[pitchType])}</span><br />
                                </p>}

                            </div>
                        </div>
                        <div className="card">
                            <div className="card-intro-icon not-for-mobile">
                                <img src={IcoStatus} width="56" />
                            </div>
                            <div className="card-content">
                                <p>
                                    <strong>{I18n.get("Prazo de seleção do parceiro")}</strong>
                                </p>
                                <p>
                                    {this.props.estimatedDecisionInWeeks} {I18n.get("semana(s)")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="card-row">
                        <div className="card">
                            <div className="card-intro-icon not-for-mobile">
                                <img src={IcoPrazo} width="56" />
                            </div>
                            <div className="card-content">
                                <p>
                                    <strong>{I18n.get("Prazo pagamento do cliente")}</strong>
                                </p>
                                <p>
                                    {this.getPaymentInDays()} {I18n.get("dias corridos")}
                                </p>
                                <p>
                                    {I18n.get("A contar da data do faturamento.")}
                                </p>

                                {this.props?.client?.customization?.paymentOnlyAfterDelivery && this.props?.auth?.is_partner && this.props?.partner?.company?.country?.code == "BRA" &&
                                    <p className="alertPay">
                                        {I18n.get("IMPORTANTE: este cliente autoriza faturamento após a entrega do trabalho. Consulte as condições de receber o pagamento antecipado da BPool clicando")} {""} <a onClick={() => ViewPay()}>{I18n.get("aqui")}</a>
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-intro-icon not-for-mobile">
                                <img src={IcoTermino} width="56" />
                            </div>
                            <div className="card-content">
                                <p>
                                    <strong>{I18n.get("Termo de Exclusividade")}</strong>
                                </p>
                                <p>
                                    {this.getNonCompete(this.props.nonCompeteInDays)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.enableLinkButton && <>

                    <Link to={`/cliente/projetos/${this.props.match.params.projectId}/passo-${step}`} className="button is-link-to-return">{I18n.get("Redefinir")}</Link>

                </>}

            </div>
        );
    }
}

export default ReviewStep4;
