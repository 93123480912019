import React from "react";
import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";

import ProjectDetails from "./project-details";
import QuotationForm from "../client-project-definition/quotation-form";

import Steps from "./steps";

import { scrollToTop } from "../../utils/ui";

class ProjectOnGoingSummary extends React.Component {
  constructor(props) {
    super();
    this.state = {
      task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  submitForm(e) {}

  render() {
    const quotation = this.props.universalQuotation || {};

    const { project = {} } = this.props;

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container">
            <div className="columns is-mobile">
              <div
                className="column is-6"
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="title">
                  {I18n.get("Projeto em Andamento")}
                  <br />
                  <span className="tag is-warning">
                    {project && project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6">
              <Steps active={4} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []} />
              </div>
            </div>
          </div>
        </div>

        <div className="client-step client-step-1 project-ongoing">
          <div
            className="container client-container-1 project-ongoing-review"
            style={{ paddingBottom: "0" }}
          >
            {this.props.isCreative && (
              <>
                <ProjectDetails {...this.props} />
              </>
            )}

            {this.props.isPartnerSelectionBidding && (
              <>
                <QuotationForm {...this.props} quotation={quotation} />
              </>
            )}

            <br />
            <br />

            {this.props.pdfBundle && this.props.project?.status < 6 && (
              <a
                href={this.props.pdfBundle}
                target="_blank"
                className="button bt-bpool black"
                style={{ marginTop: "1rem" }}
              >
                {I18n.get("Download PDF")}
              </a>
            )}

            {this.props.pdfProject && this.props.project?.status >= 6 && (
              <a
                href={this.props.pdfProject}
                target="_blank"
                className="button bt-bpool black"
                style={{ marginTop: "1rem" }}
              >
                {I18n.get("Download PDF")}
              </a>
            )}
          </div>

          <div className="columns">
            <div className="column"></div>

            <div className="column">
              <div className="field is-grouped is-grouped-centered container-content p-btns">
                <p className="control is-expanded" onClick={this.scrollToTop}>
                  {this.props.auth.is_partner && (
                    <>
                      <Link
                        to={`/projetos/${this.props.match.params.projectId}/extras`}
                        className="button bt-bpool black is-fullwidth next"
                      >
                        {I18n.get("PRÓXIMO")}
                      </Link>
                    </>
                  )}

                  {!this.props.auth.is_partner && (
                    <>
                      <Link
                        to={`/projetos/${this.props.match.params.projectId}/faturamento`}
                        className="button bt-bpool black is-fullwidth"
                      >
                        {I18n.get("PRÓXIMO")}
                      </Link>
                    </>
                  )}
                </p>
              </div>
            </div>

            <div className="column"></div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(ProjectOnGoingSummary);
