import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { CLIENT_USER_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_client_user = (data) => {

    return (dispatch, getState) => {

        //Auth.currentSession().then((session) => {

        // const token = session && session.idToken.jwtToken;

        // var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.put(`${Config.API_BASE_URL}/client-user/${data.token}`, data)
                    .then(response => {

                        dispatch({
                            type: CLIENT_USER_UPDATE,
                            payload: {
                                ...response.data,
                                task: { result: true }
                            }
                        })
                    })
                    .catch(error => {

                        dispatch({
                            type: CLIENT_USER_UPDATE,
                            payload: {
                                errors: error.response.data,
                                task: { result: false }
                            }
                        })

                    });

        // }).catch(error => {
        //     dispatch({ type: EXPIRED });
        // });
        
    };
}
export default api_update_client_user;