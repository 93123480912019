import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
// import BpayProvider from "../../pages/Bpay/context/BpayProvider";
import { Route, Switch } from "react-router-dom";
import { AdminBpayList } from "../../pages/Admin/Bpay/pages/List";
import { AdminBpayDetails } from "../../pages/Admin/Bpay/pages/Details";
import { AdminBPayments } from "../../pages/Admin/Bpay/pages/BPayments";

const queryClient = new QueryClient();

function Bpay() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <BpayProvider> */}
      <Switch>
        <Route path="/bpool/bpay" exact component={AdminBpayList} />
        <Route
          path="/bpool/bpay/detalhes/:bpayId"
          exact
          component={AdminBpayDetails}
        />
        <Route path="/bpool/bpay/bpayments" exact component={AdminBPayments} />
      </Switch>
      {/* </BpayProvider> */}
    </QueryClientProvider>
  );
}

export default Bpay;
