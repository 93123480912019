import styled from "styled-components";

export const StyledTitle = styled.h1`
    color: #000000;
    font-size: ${props => props.size};
    font-weight: 700;
    margin-bottom: 40px;
    margin-left: ${props => props.marginLeft};
    
    &::after {
        content: '';
        width: 75px;
        height: 6px;
        display: flex;
        background-color: #000000;
        margin-top: 20px;
    }
`;