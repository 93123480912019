import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { I18n } from 'aws-amplify';
import { Row, Col, message, Skeleton, Steps } from 'antd';
import { AUTH_API } from '../../../../../actions/auth/actions';
import { useRegistrationFlow } from '../../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook';
import Loading from '../../../../../components/pages/loading';
import { StepsVertical } from '../../../../components/UI/StepsVertical';
import Main from '../../../../components/UI/Main';
import Content from '../../../../components/UI/Content';
import {
  urlsIsStartup,
  urlsFullIsStartup,
} from '../../../RegistrationFlow/utils/stepsUrls';
import { handleClearLocalStorage } from '../../../../utils/clearStorage';
import {
  toOrganizeSteps,
  toflowToStorage,
  toGetFlowStorage,
  toRemoveFlowStorage,
  toRemoveAnStep,
  toUpdateIsStartupInSteps,
} from '../../utils/flowToStorage';
import * as S from './styles';

const ERROR_GET_STEPS_STATUSBAR = 'An error has occurred in the status bar.';

const { Step } = Steps;

const statusToName = (status) => {
  if (status === 1) {
    return "process";
  } else if (status === 2) {
    return "error";
  } else if (status === 3) {
    return "wait";
  } else if (status === 4) {
    return "finish";
  }
};

export const Loggedv2 = ({
  children,
  pageIndex,
  statusBarStepsClickable,
  statusChangeInfo,
  stepbarId,
  hookGetItems,
  urls,
  contextStepBar,
}) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  let history = useHistory();
  const { getBusinessType } = useRegistrationFlow();
  const [statusBarSteps, setStatusBarSteps] = useState([]);
  const [isLoadingSteps, setIsLoadingSteps] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isStartupSteps, setIsStartupSteps] = useState(false);

  const whatIsStatus = ({ step }) => {
    if (step?.status) {
      if (step.status === 'error') {
        return 'error';
      } else if (step.status === 'finish') {
        return 'finish';
      } else {
        if (step.url === location.pathname) {
          return 'process';
        } else {
          return statusToName(step.status);
        }
      }
    } else {
      return step?.statusDescription
    }
  };

  const whatBusinessType = async ({ arr }) => {
    const separatedSelects = arr?.map((item) =>
      item?.disciplineChildren?.map((childItem) =>
        childItem?.selected
          ? { code: item?.code, selected: true }
          : { code: item?.code, selected: false }
      )
    );
    const flated = separatedSelects.flat();
    const whatBusinessTemp = flated?.map((item) =>
      item?.selected ? item?.code : null
    );
    const whatBusiness = whatBusinessTemp?.filter((item) => item);
    return whatBusiness[0];
  };

  const loadStepsStatusBar = async () => {
    setIsLoadingSteps(true);

    const response = await hookGetItems();
    const responseBusinessType = await getBusinessType({ admin: false });
    const whatBusiness = await whatBusinessType({
      arr: responseBusinessType?.data,
    });
    const isStartup = whatBusiness === 'MARTECADTECH' ? true : false;
    setIsStartupSteps(isStartup);

    if (response?.success) {
      const stepsOrganizeds = await toOrganizeSteps({
        arrSteps: response.data.statusBarRegistration,
        contextStepBar,
        pageIndex,
        urls,
      });

      let steps = [];

      if (isStartup) {
        const idStepIndustry = '63a4501fc33411fd205cae9b';
        const filtereds = stepsOrganizeds?.filter(
          (item) => item?.id !== idStepIndustry
        );
        const updatedsIsStartup = filtereds?.map((item, index) => ({
          ...item,
          step: index + 7,
          isStartup: true,
        }));
        steps = updatedsIsStartup;
      } else {
        steps = stepsOrganizeds;
      }

      setStatusBarSteps(steps);

      const toStorage = {
        stepbarId,
        steps: steps,
      };

      await toflowToStorage({
        key: '@BPOOL-registration-flow-steps',
        stepbar: toStorage,
      });
    } else {
      message.error(I18n.get(ERROR_GET_STEPS_STATUSBAR));
    }

    setIsLoadingSteps(false);
  };

  const hasStepsInApp = async ({ flow }) => {
    const stepsUpdateds = flow.steps.map((step) => ({
      ...step,
      status: whatIsStatus({ step })
    }));

    const isStartup = stepsUpdateds[3]?.isStartup;
    setIsStartupSteps(isStartup);

    const toStorage = {
      stepbarId,
      steps: stepsUpdateds,
    };

    await toflowToStorage({
      key: '@BPOOL-registration-flow-steps',
      stepbar: toStorage,
    });
    setStatusBarSteps(stepsUpdateds);
    setIsLoadingSteps(false);
  };

  const init = async () => {
    const flow = await toGetFlowStorage({
      key: '@BPOOL-registration-flow-steps',
    });

    if (flow && flow.stepbarId === stepbarId) {
      await hasStepsInApp({ flow });
    }

    if (flow && flow.stepbarId !== stepbarId) {
      await toRemoveFlowStorage({ key: '@BPOOL-registration-flow-steps' });
      await loadStepsStatusBar();
    }

    if (!flow) {
      await loadStepsStatusBar();
    }
  };

  useEffect(() => {
    if (stepbarId) {
      init();
    }
  }, [urls]);

  //AUTH
  const isNotLogged = auth?.init === false && !auth.logged_user;

  if (isNotLogged) {
    history.push({
      pathname: '/registration-flow/login',
    });
  }

  const handleLogout = async () => {
    dispatch(AUTH_API.logout());
    handleClearLocalStorage();
    history.push({
      pathname: '/registration-flow/login',
    });
  };

  const handleClickDropdown = () => {
    setDropdownOpen(dropdownOpen ? false : true);
  };

  if (auth?.init) {
    return <Loading />;
  }

  const onChange = (value) => {
    console.log('onChange', value);
  };

  const urlsArr = () => {
    if (contextStepBar === 'pre') {
      if (isStartupSteps) {
        return urlsIsStartup;
      } else {
        return urls;
      }
    } else if (contextStepBar === 'full') {
      if (isStartupSteps) {
        return urlsFullIsStartup;
      } else {
        return urls;
      }
    } else {
      return urls;
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderContent>
          <div className="container" style={{ display: 'flex' }}>
            <S.ContentUser />
            <S.ContentLogo>
              <span>
                <img
                  src="/static/media/logoWOtext.eb8a797b.svg"
                  alt="BPool"
                  class="logo"
                />
              </span>
            </S.ContentLogo>
            <S.ContentUser>
              {auth?.init ? (
                <strong>{I18n.get('Loading...')}</strong>
              ) : (
                <S.Dropdown onClick={handleClickDropdown}>
                  <strong>
                    <span>{auth?.name}</span>
                    {dropdownOpen ? <UpOutlined /> : <DownOutlined />}
                  </strong>
                  <S.DropdownOpened active={dropdownOpen}>
                    <li>
                      <button onClick={handleLogout}>
                        {I18n.get('Logout')}
                      </button>
                    </li>
                  </S.DropdownOpened>
                </S.Dropdown>
              )}
            </S.ContentUser>
          </div>
        </S.HeaderContent>
      </S.Header>

      <Content paddingTop={60}>
        <div className="container">
          <Row>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: stepbarId ? 16 : 24, offset: 0 }}
            >
              {isLoadingSteps && (
                <Main bgColor="#fff" padding="30px">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Main>
              )}
              {!isLoadingSteps && <>{children}</>}
            </Col>
            {stepbarId ? (
              <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }}>
                <Main bgColor="#fff" padding="30px">
                  {isLoadingSteps && (
                    <>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </>
                  )}

                  {!isLoadingSteps && (
                    <StepsVertical
                      isLoading={isLoadingSteps}
                      steps={statusBarSteps}
                      clickable={statusBarStepsClickable}
                      page={pageIndex}
                      urls={urlsArr()}
                      changedInfos={statusChangeInfo}
                      contextStepBar={contextStepBar}
                    />
                  )}
                </Main>
              </Col>
            ) : null}
          </Row>
        </div>
      </Content>

      <S.Footer>
        <S.ContentFooter>
          <div className="container">
            <span>{`${new Date().getFullYear()} © BPool`}</span>
          </div>
        </S.ContentFooter>
      </S.Footer>
    </S.Wrapper>
  );
};
