import React from 'react';


import FieldIsRequiredMark from '../ui/field-is-required-mark';
import FieldIsOptionalMark from '../ui/field-is-optional-mark';

const MarkAsRequiredOrOptional = ({markAsRequired, markAsOptional}) => {

    if (markAsRequired){
        return (<FieldIsRequiredMark />);
    }

    if (markAsOptional){
        return (<FieldIsOptionalMark />)
    }

    return null;

};

export default MarkAsRequiredOrOptional;