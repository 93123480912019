import axios from 'axios';

import Config from '../../config';

import { PROJECT_FEEDBACK } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_client_feedback = (data) => {
    return (dispatch) => {

        let msg = {
            comment: data.comment,
            commentBpool: data.commentBpool,
        };

        return axios.put(`${Config.API_BASE_URL}/feedback/${data.projectId}/${data.feedbackId}/${data.prefix}/${data.grade}`, msg).then(response => {

            const payload = {
                milestones: response.data,
                task: {
                    result: true
                }
            };

            dispatch({
                type: PROJECT_FEEDBACK,
                payload: payload
            })

            return payload;
        })
            .catch(error => {

                // Nao faz o dispatch no erro, tratamento direto na tela
                const payload = {
                    errors: error.response.data,
                    task: {
                        result: false
                    }
                };

                return payload;
            });
    };
}
export default api_client_feedback;
