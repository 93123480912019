import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import {
    Row,
    Col,
    Tooltip,
    Button,
    Icon,
    notification,
    Modal,
    Rate,
    Checkbox,
    Input,
    Form
} from 'antd';


const { confirm } = Modal;


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class FormReport extends React.Component {

    constructor(props) {

        super();
        this.state = {
        };
    }


    handleSubmit = (e) => {
        e.preventDefault();


        this.props.form.validateFields((err, values) => {

            let nums = []
            nums = nums.concat(values.partner, values.client, values.projects, values.billings, values.bpays);

            nums = nums.filter(function (el) {
                return el != null;
            });

            const data = {
                reports: nums,
                email: this.props.auth.email
            }

            this.props.reports(data)
                .then(response => {

                    if (response.data.ok === true) {
                        confirm({
                            title: I18n.get("Processando..."),
                            content: 'Os relatórios selecionados serão enviados para o seu email.',
                            okText: I18n.get("OK"),
                            onOk: () => {
                                this.props.form.resetFields('partner');
                                this.props.form.resetFields('client');
                                this.props.form.resetFields('projects');
                                this.props.form.resetFields('billings');
                                this.props.form.resetFields('bpays');
                            },
                            cancelButtonProps: {
                                style: { display: 'none' }
                            }
                        });
                    } else {
                        confirm({
                            title: I18n.get("ERRO Processamento"),
                            content: 'Erro ao tentar processar os relatórios.',
                            okText: I18n.get("OK"),
                            onOk: () => {
                                this.props.form.resetFields('partner');
                                this.props.form.resetFields('client');
                                this.props.form.resetFields('projects');
                                this.props.form.resetFields('billings');
                                this.props.form.resetFields('bpays');
                            },
                            cancelButtonProps: {
                                style: { display: 'none' }
                            }
                        });
                    }

                })
                .catch(error => {
                    notification.open({
                        message: I18n.get("Ops! Algo deu errado."),
                        description: I18n.get("Tente novamente mais tarde." + error),
                        icon: <Icon type="check" style={{ color: '#000' }} />,
                    });
                });
        });

    };

    render() {

        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <section className="info-tiles">
                <div className="tile is-ancestor has-text-centered">

                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            {/* <p className="title">{this.props.bp.dashboard.partnerCount}</p> */}
                            <p className="title-bpool medium">{I18n.get("Parceiros")}</p>
                        </article>
                    </div>

                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            {/* <p className="title">{this.props.bp.dashboard.clientCount}</p> */}
                            <p className="title-bpool medium">{I18n.get("Clientes")}</p>
                        </article>
                    </div>

                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            {/* <p className="title">{this.props.bp.dashboard.clientProjectCount}</p> */}
                            <p className="title-bpool medium">{I18n.get("Projetos")}</p>
                        </article>
                    </div>

                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            {/* <p className="title">{this.props.bp.dashboard.clientProjectCount}</p> */}
                            <p className="title-bpool medium">{I18n.get("Financeiro")}</p>
                        </article>
                    </div>

                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            {/* <p className="title">{this.props.bp.dashboard.clientProjectCount}</p> */}
                            <p className="title-bpool medium">{I18n.get("BPay")}</p>
                        </article>
                    </div>

                </div>
                <Form onSubmit={this.handleSubmit} className="admin-boxs">

                    <div className="tile is-ancestor">

                        <div className="tile is-parent">

                            <div className="tile is-child box">
                                <Form.Item>
                                    {getFieldDecorator('partner', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <Checkbox.Group>
                                            <p><Checkbox value={"100"}>{I18n.get("Geral")}</Checkbox></p>
                                            <p><Checkbox value={"103"}>{I18n.get("Usuários")}</Checkbox></p>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                            </div>

                        </div>

                        <div className="tile is-parent">

                            <div className="tile is-child box">
                                <Form.Item>
                                    {getFieldDecorator('client', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <Checkbox.Group>
                                            <p><Checkbox value={"1000"}>{I18n.get("Geral")}</Checkbox></p>
                                            <p><Checkbox value={"1001"}>{I18n.get("Produtos vs Parceiros")}</Checkbox></p>
                                            <p><Checkbox value={"1003"}>{I18n.get("Usuários")}</Checkbox></p>
                                            <p>&nbsp;</p>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                            </div>

                        </div>

                        <div className="tile is-parent">

                            <div className="tile is-child box">
                                <Form.Item>
                                    {getFieldDecorator('projects', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <Checkbox.Group>
                                            <p><Checkbox value={"10000"}>{I18n.get("Geral")}</Checkbox></p>
                                            <p><Checkbox value={"10001"}>{I18n.get("Extras")}</Checkbox></p>
                                            <p><Checkbox value={"10002"}>{I18n.get("Com Assets")}</Checkbox></p>
                                            <p>&nbsp;</p>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                            </div>

                        </div>

                        <div className="tile is-parent">

                            <div className="tile is-child box">
                                <Form.Item>
                                    {getFieldDecorator('billings', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <Checkbox.Group>
                                            <p><Checkbox value = {"20000"}>{I18n.get("POs")}</Checkbox></p>
                                            <p><Checkbox value = {"20002"}>{I18n.get("Lançamentos")}</Checkbox></p>
                                            <p><Checkbox value = {"20003"}>{I18n.get("Repasses")}</Checkbox></p>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                            </div>

                        </div>

                        <div className="tile is-parent">

                            <div className="tile is-child box">
                                <Form.Item>
                                    {getFieldDecorator('bpays', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <Checkbox.Group>
                                            <p><Checkbox value = {"30000"}>{I18n.get("BPays")}</Checkbox></p>
                                            <p><Checkbox value = {"30001"}>{I18n.get("BPayments")}</Checkbox></p>
                                            <p><Checkbox value = {"30002"}>{I18n.get("Fornecedores")}</Checkbox></p>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                            </div>

                        </div>

                    </div>


                    <div className="columns">

                        <div className="column is-12">
                            <Button className="pull-right" type="primary" htmlType="submit" shape="round">{I18n.get("Processar")}</Button>
                        </div>
                    </div>
                </Form>

            </section>
        );
    }
}


const MainFormReport = Form.create({})(FormReport);


export default connect(mapStateToProps, mapDispatchToProps)(MainFormReport);
