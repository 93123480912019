import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { SAVING_EXPORT_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";
axios.defaults.timeout = 500000;

const api_get_project_dash = (data) => {
  let dataFrom = data.dataFrom;
  let dataTo = data.dataTo;

  delete data["date"];
  delete data["dataFrom"];
  delete data["dataTo"];

  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");

  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .get(
            `${Config.API_BASE_URL}/AssetSaving/client/asset/export/${dataFrom}/${dataTo}?${queryString}`,
            config
          )
          .then((response) => {
            const payload = {
              ...response.data,
              task: {
                result: true,
              },
            };

            dispatch({
              type: SAVING_EXPORT_GET,
              payload: payload,
            });

            return payload;
          })
          .catch((error) => {
            // Nao faz o dispatch no erro, tratamento direto na tela
            const payload = {
              errors: error.response.data,
              task: {
                result: false,
              },
            };

            return payload;
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default api_get_project_dash;
