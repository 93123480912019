import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import styled from 'styled-components';

import LoggedPage from '../page-templates/logged';

import Menu from './_menu';

import LeadersForm from './leaders-form';

import { CardBorder } from '../../modules/components/Cards';

import TableLeaders from '../../modules/Register/components/tableLeaders';

import { PartnerStatus } from '../../model';


import { PARTNER_API } from '../../actions/partner/actions';

import FormLeaders from '../../modules/Register/components/formLeaders';

import { TitleBorder, TitleMiddleLine } from '../../modules/components/Titles';


import {
    Row,
    Col,
    Tooltip,
    Icon,
    notification,
    Button,
    Drawer
} from 'antd';

const Btns = styled.p`
    a {
        margin-left: 10px;
    }
`

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        create_leader: data => {
            dispatch(PARTNER_API.create_leader(data));
        },
        delete_leader: data => {
            dispatch(PARTNER_API.delete_leader(data));
        },
        edit_leader: data => {
            dispatch(PARTNER_API.update_leader(data));
        },
        updateOrderLeader: data => {
            dispatch(PARTNER_API.update_order_leader(data));
        },
        addCase: data => {
            return dispatch(PARTNER_API.create_case(data));
        },
        updateCase: data => {
            return dispatch(PARTNER_API.update_case(data));
        },
        delCase: data => {
            return dispatch(PARTNER_API.delete_case(data));
        },
        upload: data => {
            return PARTNER_API.upload(data);
        }
    };
};


class LeadersAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            edit: false,
            show: false,
            listImg: [],
            scape: "",
            editSelected: false
        }
    }

    hideLeader = () => {
        this.setState({
            selectedItem: null,
            edit: false,
            show: false
        });
    }

    showAddLeaders = () => {
        this.setState({
            selectedItem: null,
            edit: false,
            show: true
        });
    }

    showEditLeaders = (item) => {
        this.setState({
            selectedItem: item,
            edit: true,
            show: true
        });
    }


    getSector = (sectorId) => {
        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }

    updateLeader = (data) => {
        this.props.edit_leader(data);
    }

    addLeaders = (data) => {
        this.props.create_leader(data);
    }


    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partner.leaders && prevProps.partner.leaders && this.props.partner.leaders.length > prevProps.partner.leaders.length) {

            notification.success({
                message: I18n.get(I18n.get('Adicionado com sucesso!')),
                description: I18n.get('Líder foi adicionado ao seu cadastro'),
                duration: 3,
            });

            this.hideLeader();
        }

        if (this.props.partner.leaders && prevProps.partner.leaders && this.props.partner.leaders.length < prevProps.partner.leaders.length) {

            notification.success({
                message: I18n.get('Removido com sucesso!'),
                description: I18n.get('Líder foi removido do seu cadastro'),
                duration: 3,
            });
        }

        if (this.props.partner.leaders && prevProps.partner.leaders && this.props.partner.leaders != prevProps.partner.leaders && this.state.selectedItem) {

            notification.success({
                message: I18n.get('Atualizado com sucesso!'),
                description: I18n.get('Líder foi atualizado'),
                duration: 3,
            });

            this.setState({
                selectedItem: null
            })

            this.hideLeader();
        }

    }

    render() {
        let data = this.props.partner.leaders && this.props.partner.leaders.map((item, index) => {
            return {
                key: index,
                name: item.name,
                email: item.email,
                funcao: item.expertise.label,
                item: item
            }
        });

        const columns = [
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: I18n.get("E-mail"),
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: I18n.get("Função"),
                dataIndex: 'funcao',
                key: 'funcao',
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'item',
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                        partnerId: this.props.partner && this.props.partner.partnerId,
                    }

                    return (
                        <Btns>
                            <a onClick={() => this.props.delete_leader(data)}>
                                <Icon type="delete" theme="filled" />
                            </a>
                            <a onClick={() => this.showEditLeaders(record.item)}>
                                <Icon type="edit" theme="filled" />
                            </a>
                        </Btns>
                    )
                }
            },
        ]


        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><Link to="/home">{I18n.get("Admin")}</Link></li>
                        <li className="is-active"><Link to="/cadastro">{I18n.get("Cadastro")}</Link></li>
                    </ul>
                </nav>

                <div className="columns company-admin">

                    <div className="column is-3">
                        <Menu {...this.props} />
                    </div>

                    <div className="column is-9">

                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Cadastro - Líderes")}</TitleBorder>
                            </Col>
                        </Row>
                        <Row gutter={1}>
                            <Col sm={24}>
                                <CardBorder>
                                    <Row>
                                        <Col sm={24}>
                                            <TitleMiddleLine>{I18n.get("Além de você, quem são os líderes do seu negócio")} <Tooltip title={I18n.get("Outros(as) líderes poderão colaborar adicionando suas próprias informações no cadastro. Os(as) profissionais inseridos(as) aqui receberão um email com link assim que você finalizar o processo de cadastro.")}> <Icon type="question-circle" theme="filled" /></Tooltip></TitleMiddleLine>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={24}>
                                            <p className="BtnAction">
                                                <Button type="primary" shape="round" onClick={() => this.showAddLeaders()}>{I18n.get("+ Adicionar líder")}</Button>
                                            </p>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>
                                            <TableLeaders
                                                data={this.props.partner.leaders && this.props.partner.leaders}
                                                partnerId={this.props.partner && this.props.partner.partnerId}
                                                order={this.props.updateOrderLeader}
                                                edit={this.showEditLeaders}
                                                delete={this.props.delete_leader}
                                            />
                                        </Col>
                                    </Row>
                                </CardBorder>
                            </Col>
                        </Row>



                    </div>

                </div>

                <Drawer
                    title={this.state.selectedItem ? I18n.get("Editar líder") : I18n.get("Adicionar líder")}
                    placement="right"
                    forceRender={true}
                    closable={true}
                    onClose={() => this.hideLeader()}
                    destroyOnClose={true}
                    visible={this.state.show}
                    width="40%"
                >
                    <FormLeaders
                        partner={this.props.partner && this.props.partner}
                        partnerId={this.props.partner && this.props.partner.partnerId}
                        action={this.addLeaders}
                        update={this.updateLeader}
                        cancel={() => this.hideLeader()}
                        item={this.state.selectedItem}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        expertise={this.props.bp.marketExpertises && this.props.bp.marketExpertises}
                    />
                </Drawer>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadersAdmin);
