import React from "react";
import * as S from "./styles";

const Main = ({ bgColor, isSubmenu, children, padding, style }) => {
  return (
    <S.StyleMain backgroundColor={bgColor} isSubmenu={isSubmenu} padding={padding} style={style ? style : {}}>
      {children}
    </S.StyleMain>
  );
};

export default Main;
