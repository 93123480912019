import { OPEN_QUICK_MEETING, CLOSE_QUICK_MEETING } from '..';

export const openQuickMeeting = (payload) => ({
  type: OPEN_QUICK_MEETING,
  payload,
});

export const closeQuickMeeting = () => ({
  type: CLOSE_QUICK_MEETING,
});
