import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { Icon, Modal } from "antd";
import { useParams } from "react-router-dom";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import { ButtonBpool } from "../../../ButtonBpool";
import { ModalBp } from "../../../ModalBp/ModalBp";
import Loading from "../../../Loading";
import { ItemAsset } from "./ItemAsset/itemAsset";
import { AssetsCart } from "../AssetsCart";

import * as S from "./styles";

export const IncludedItems = ({
  isModalConfirm,
  data,
  reload,
  disabledEdit,
  removeAll,
  isCart,
  cartTotals,
  taxes
}) => {
  let params = useParams();
  const { addAssetToProject, addAssetBundleToProject } = useProjects();
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [listDataIncludedItems, setListDataIncludedItems] = useState([]);
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [loadingRemoveAll, setLoadingRemoveAll] = useState(false);

  const handleOpenModal = () => {
    setModalEditVisible(true);
  };

  const handleCloseModal = () => {
    if (isCart) {
      setModalEditVisible(false);
    } else {
      if (isModalConfirm) {
        if (!isDisabledSave) {
          setOpenModalConfirm(openModalConfirm ? false : true);
        } else {
          setListDataIncludedItems(data?.length ? data : []);
          setModalEditVisible(false);
        }
      }
    }
  };

  const handleSave = async () => {
    setIsSending(true);
    setIsDisabledSave(true);
    const dataSend = listDataIncludedItems?.map((item) => ({
      code: item?.asset ? item?.asset : item?.code,
      quantity: item?.quantity ? Number(item?.quantity) : 1,
    }));

    let data = {
      projectId: params.projectId,
      assets: dataSend,
    };

    const response = await addAssetToProject({ assets: data });
    if (response?.success) {
      await reload();
      setModalEditVisible(false);
    }
    setIsSending(false);
  };

  useEffect(() => {
    setListDataIncludedItems(data);
  }, [data]);

  const handleListDataIncludedItems = (list) => {
    setListDataIncludedItems(list);
  };

  const handleChanged = (hasChanged) => {
    setIsDisabledSave(!hasChanged);
  };

  const removeAllItems = async () => {
    setLoadingRemoveAll(true)
    const objSend = {
      projectId: params.projectId,
      bundleId: null,
      bundle: null
    };
    await addAssetBundleToProject(objSend);
    setLoadingRemoveAll(false)
    await reload();
  }

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Title>
            <p>{I18n.get("Itens inclusos")}</p>

            {disabledEdit ? null : (
              <button onClick={handleOpenModal}>
                <Icon type="edit" />
                {I18n.get("Editar itens")}
              </button>
            )}

            {loadingRemoveAll ? <S.LoadingRemove>
              <Loading
                text={I18n.get('Carregando...')}
                sizeText={12}
                sizeIcon={14}
                style={{ padding: 0 }}
              />
            </S.LoadingRemove> : !removeAll ? null : removeAll === "BUNDLE" ? (
              <S.ButtonOtherPack onClick={removeAllItems}>
                {I18n.get("Escolher outro pacote")}
              </S.ButtonOtherPack>
            ) : (
              <button onClick={removeAllItems}>
                <Icon type="edit" />
                {I18n.get("Remover todos itens")}
              </button>
            )}

          </S.Title>
          {data?.length ? (
            <S.ItemsList>
              {data?.map((item, index) => (
                <ItemAsset
                  key={index}
                  item={item}
                  hasEdit={false}
                  handleEditQuantity={() => true}
                  onClickDeleteItem={() => true}
                />
              ))}
            </S.ItemsList>
          ) : null}
        </S.Content>
      </S.Wrapper>

      <Modal
        title={I18n.get("Editar Escopo Criativo")}
        centered
        visible={modalEditVisible}
        onOk={handleSave}
        onCancel={() => handleCloseModal()}
        width={1200}
        footer={[
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ButtonBpool
              text={"Close"}
              theme="secondary"
              onClick={() => handleCloseModal()}
              loading={false}
            />
            {isCart ?
              <ButtonBpool
                text={"Concluir edição"}
                theme="primary"
                onClick={() => handleCloseModal()}
              /> : (
                <ButtonBpool
                  text={"Save"}
                  theme="primary"
                  onClick={handleSave}
                  loading={isSending}
                  disabled={isDisabledSave}
                />
              )}
          </div>,
        ]}
      >
        {/* <AssetsCart
          title={I18n.get("Add new item")}
          listDataIncludedItems={listDataIncludedItems}
          handleListDataIncludedItems={handleListDataIncludedItems}
          handleChanged={handleChanged}
        /> */}
        <AssetsCart
          title={I18n.get("Adicionar novo item")}
          listDataIncludedItems={listDataIncludedItems}
          handleListDataIncludedItems={handleListDataIncludedItems}
          handleChanged={handleChanged}
          isCart={isCart}
          cartTotals={cartTotals}
          reload={reload}
          assetTax={taxes?.assetTax}
          otherTax={taxes?.otherTax}
        />
      </Modal>
      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get("Você deseja mesmo fechar?")}
        subBodyText={I18n.get(
          "Você alterou as informações e não salvou. Se você sair, você perderá."
        )}
        okText={I18n.get("Salvar")}
        handleClickOk={handleSave}
        cancelText={I18n.get("Cancelar")}
        onCancel={() => handleCloseModal()}
        typeModal="isConfirm"
        isLoading={isSending}
        isAlert
      />
    </>
  );
};
