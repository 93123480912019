import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import Main from '../../../../components/UI/Main';
import { ButtonBpool } from '../../../../components/UI/ButtonBpool';
import Loading from '../../../../components/UI/Loading';
import { ModalBp } from '../../../../components/UI/ModalBp/ModalBp';
import { ButtonExpandBoxSelect } from '../../components/ButtonExpandBoxSelect/indexLeader';
import { useRegistrationFlowFull } from '../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook';
import { urlsLeader as urls } from '../../utils/stepsUrls';
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from '../../utils/flowToStorage';
import { statusIdToName } from '../../components/Loggedv2/statusIdToName';

import { useRegistrationFlowLeader } from '../../../../hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook';

import * as S from './styles';

const MESSAGE_ERROR_BACK_PAGE = 'Error in back page.';
const PAGE_INDEX = 19;

export const ContentPage = ({ contextPage, onChangeInfo, sendOk }) => {
  const location = useLocation();
  const { getAboutYou, getAboutYouLogged, updateIndustry, updateIndustryLogged, getStepBarLeader, getIndustry, getIndustryLogged } =
    useRegistrationFlowLeader();
  let history = useHistory();

  const [token, setToken] = useState('');
  const [options, setOptions] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [isLoadPage, setIsLoadPage] = useState(false);
  const [sendLoadingPage, setSendLoadingPage] = useState(false);
  const [partnerId, setPartnerId] = useState('');
  const [leaderId, setLeaderId] = useState('');
  const [errorsPage, setErrorsPage] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: `${urls[PAGE_INDEX + 1]}/${token}`,
    });
  };

  const prepareToken = async (url) => {
    const arrayOfStrings = url.split('/');
    const tkn = arrayOfStrings[arrayOfStrings.length - 1];
    return tkn;
  };

  const loadInit = async () => {
    setIsLoadPage(true);

    const tokenUrl = await prepareToken(location?.pathname);
    if (tokenUrl) {
      setToken(tokenUrl);

      if (contextPage === 'edit') {
        const responseAbout = await getAboutYouLogged();

        if (responseAbout.success) {
          setPartnerId(responseAbout?.data?.id);
          setLeaderId(responseAbout?.data?.users?.id);
        }

        const responseLogged = await getIndustryLogged();

        const list = responseLogged?.data?.industry.map((item) => item);

        setIsLoadPage(false);
        setOptions(list);
      } else {
        const response = await getAboutYou(tokenUrl);

        if (response.success) {
          const data = {
            partnerId: response?.data?.id,
            leaderId: response?.data?.users?.id,
          };
          const responseInit = await getIndustry(data);

          setPartnerId(response?.data?.id);
          setLeaderId(response?.data?.users?.id);

          if (responseInit.success) {
            // const responseStep = await getStepBarLeader();

            const list = responseInit?.data?.industry.map((item) => item);

            setIsLoadPage(false);

            setOptions(list);
          }
        }
      }

    }
  };

  const setYear = async (item) => {
    const editOptions = options;

    const indiceCategorie = editOptions.findIndex(
      (cat) => cat.code == item.sectionCode
    );

    const selectCategorie = editOptions[indiceCategorie];

    let typeUser = selectCategorie;

    const subSelected = typeUser.childrenLeader.find(
      (i) => i.code == item.item.code
    );

    subSelected.years = item.years;

    typeUser = subSelected;

    editOptions[indiceCategorie] = selectCategorie;

    setOptions([...editOptions]);
  };

  const setMonth = async (item) => {
    const editOptions = options;

    const indiceCategorie = editOptions.findIndex(
      (cat) => cat.code == item.sectionCode
    );

    const selectCategorie = editOptions[indiceCategorie];

    let typeUser = selectCategorie;

    const subSelected = typeUser.childrenLeader.find(
      (i) => i.code == item.item.code
    );

    subSelected.months = item.months;

    typeUser = subSelected;

    editOptions[indiceCategorie] = selectCategorie;

    setOptions([...editOptions]);
  };

  const handleSelectItem = async (item) => {
    const editOptions = options;

    const indiceCategorie = editOptions.findIndex(
      (cat) => cat.code == item.sectionCode
    );

    const selectCategorie = editOptions[indiceCategorie];

    let typeUser = selectCategorie;

    const subSelected = typeUser.childrenLeader.find(
      (i) => i.code == item.item.code
    );

    // const subSelectedIndex = typeUser.findIndex(
    //   (i) => i.code == item.item.code
    // );

    if (!subSelected.selected) {
      subSelected.selected = true;
    } else {
      subSelected.selected = false;
    }

    typeUser = subSelected;

    editOptions[indiceCategorie] = selectCategorie;

    setOptions([...editOptions]);
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStepBarLeader(token);
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const handleSendApi = async () => {
    setSendLoadingPage(true);

    const sendIndustries = {
      partnerId: partnerId,
      id: leaderId,
      industries: [...industries],
    };

    if (contextPage === 'edit') {
      const responseUpdateLogged = await updateIndustryLogged(sendIndustries);

      if (responseUpdateLogged.success) {
        sendOk(true);
      } else {
        sendOk(false);
      }

      setSendLoadingPage(false);
    } else {

      const response = await updateIndustry(sendIndustries);
      await newGetStepbar();

      if (response.success) {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: `${urls[PAGE_INDEX + 1]}/${token}`,
        });
      } else {
        setErrorsPage(response?.errors);
        setOpenModalConfirm(true);
      }
      setSendLoadingPage(false);
    }

  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  useEffect(() => {
    const sendApi = [];

    options.map((cat) => {
      const catItens = [...cat.childrenLeader];

      catItens.map((subEnabled) => {
        if (subEnabled.selected) {
          sendApi.push({
            code: subEnabled.code,
            type: subEnabled.type,
            years: subEnabled.years,
            months: subEnabled.months,
          });
        }
      });
    });

    setIndustries(sendApi);
  }, [options]);

  const handleBackPage = () => {
    const url = `${urls[PAGE_INDEX - 1]}/${token}`;

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        {/* <ScrollUpDown /> */}

        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get('Industry')}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  'Select the industries your company or agency leadership have expertise on'
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>
        <Row>
          {isLoadPage && (
            <Col xs={24} style={{ marginTop: 40 }}>
              <div>
                <Loading
                  sizeIcon={18}
                  text={I18n.get('Loading informations')}
                  sizeText={14}
                  align="center"
                />
              </div>
            </Col>
          )}

          {errorsPage && (
            <S.Error>{errorsPage?.Industry?.errorMessage}</S.Error>
          )}

          {options &&
            options.map((item) => {
              const subs = [...item?.childrenLeader];

              let isActive = subs.findIndex((i) => i.selected === true) != -1;

              return (
                <Col key={item.id} xs={24} style={{ marginTop: 40 }}>
                  <ButtonExpandBoxSelect
                    sectionCode={item?.code}
                    label={item?.label}
                    description={item?.description}
                    active={isActive}
                    subs={item.childrenLeader}
                    full
                    setYear={(e) => setYear(e)}
                    setMonth={(e) => setMonth(e)}
                    handleSelectItem={(e) => handleSelectItem(e)}
                    error={Object.keys(errorsPage).length > 0}
                  />
                </Col>
              );
            })}
        </Row>

        <Row style={{ marginTop: 30 }}>
          {contextPage === 'edit' ? null : (
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get('Back')}
                theme="secondary"
                full
                onClick={handleBackPage}
              />
            </Col>
          )}
          <Col 
            xs={{ span: 24, offset: 0 }}
            sm={
              contextPage === "edit"
                ? { span: 24, offset: 0 }
                : { span: 12, offset: 0 }
            }>
            <ButtonBpool
              text={contextPage === "edit" ? I18n.get('Save') : I18n.get('Save and Next')}
              theme="primary"
              full
              loading={sendLoadingPage}
              onClick={() => handleSendApi()}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get('Missing required information')}
        subBodyText={I18n.get(
          'Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?'
        )}
        okText={I18n.get('Yes')}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get('Close')}
        typeModal="isConfirm"
      // isDanger
      />
    </>
  );
};
