import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

//import LandingPage from '../page-templates/landing';
import FillForm from '../page-templates/fillForm';


import PlanForm from './plan-form';

import Steps from './steps';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class ClientSignupStep3 extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }

    scrollToTop() {
        const scrollDuration = 600;
        const scrollHeight = window.scrollY;
        const scrollStep = Math.PI / (scrollDuration / 15);
        const cosParameter = scrollHeight / 2;
        let scrollCount = 0;
        let scrollMargin;
        let scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                scrollCount = scrollCount + 1;
                scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                window.scrollTo(0, (scrollHeight - scrollMargin));
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }

    render() {

        return (
            <FillForm>
                <div className="hero-body has-background-white">
                    <div className="container">
                        <div className="column is-12">
                            <Steps active={3} />
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-3 content-client">

                    <div className="container client-container-3">

                        <div className="column is-8 is-offset-2">

                            <h1 className="title">
                                <span>3.</span>{I18n.get("Indique as marcas e número de categorias")}</h1>

                            <p>{I18n.get("Você poderá cadastrar todas marcas que deseja usar na plataforma e, sempre que necessário, poderá acrescentar novas marcas através do seu login na BPool.")} </p>

                            <br /><br />

                            <PlanForm {...this.props} />

                            <div className="field is-grouped is-grouped-centered">
                                <p className="control is-expanded" onClick={this.scrollToTop}>
                                    <Link to="/cliente/passo-2" className="button is-black is-outlined is-fullwidth previous">{I18n.get("Voltar")}</Link>
                                </p>
                                <p className="control is-expanded" onClick={this.scrollToTop}>
                                    <Link to="/cliente/passo-4" className="button is-black is-fullwidth next">{I18n.get("PRÓXIMO [ 4 de 5 ]")}</Link>
                                </p>
                            </div>
                        </div>
                    </div>
            </div>


         </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSignupStep3);
