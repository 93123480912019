import React from "react";
import { I18n } from "aws-amplify";
import { Logged } from "../../components/Logged";
import { BoxContent } from "../../components/BoxContent";
import { TitlePage } from "../../components/TitlePage";
import { menuItems } from "./pages/menu";
import * as S from "./styles";

export const Profile = () => {
  return (
    <Logged>
      <div className="container">
        <BoxContent menuTitle={I18n.get("SOBRE A EMPRESA")} menu={menuItems}>
          <TitlePage title={I18n.get("Perfil")} />
          <div>123</div>
        </BoxContent>
      </div>
    </Logged>
  );
};
