import React from 'react';
import { Hub, I18n } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";

import Config from '../../config';

import { AUTH_API } from '../../actions/auth/actions';

import { Modal, Button } from 'antd';

const { confirm } = Modal;

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        set_locale: (data) => {
            dispatch(AUTH_API.set_locale(data));
        },
        logout: () => {
            dispatch(AUTH_API.logout());
        }
    };
};

class Language extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isActive: null,
        }

        this.activeMenu = this.activeMenu.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                isActive: false,
            });
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    activeMenu(event) {
        event.stopPropagation();
        this.setState({
            isActive: true,
        });
    }

    setLanguage(locale, e) {

        if (e) e.preventDefault();



        this.props.set_locale({ locale: locale });

        this.setState({ isActive: null });

        if (this.props.isSelect) {
            confirm({
                title: I18n.get("Atenção"),
                content: I18n.get("Para uma melhor experiência de troca de idioma, você deverá se logar novamente"),
                cancelButtonProps: {
                    style: { display: 'none' }
                },
                onOk:() => {
                    this.props.logout();
                }
            })
        }


    }

    render() {

        const { locale } = this.props.auth;

        const languages = [
            { code: 'pt', name: I18n.get("Português") },
            { code: 'en', name: I18n.get("Inglês") },
            { code: 'es', name: I18n.get("Espanhol") },
        ];

        const className = 'navbar-item has-dropdown' + (this.state.isActive ? ' is-active' : '');

        return <>
            {Config.moduloChangeLanguage && <div ref={this.setWrapperRef} onClick={(event) => this.activeMenu(event)} className={className}>
                <a className="navbar-link has-text-white idioma-bpool">
                    {I18n.get("Idioma")}
                </a>
                <div className="navbar-dropdown">

                    {languages.map((lang) => {

                        const isSelected = (locale == lang.code);

                        const className = `navbar-item ${isSelected ? 'is-active' : ''}`;

                        return (
                            <a onClick={this.setLanguage.bind(this, lang.code)} className={className}>
                                {I18n.get(lang.name)} {this.state.isActive}
                                {locale == lang.code && <>
                                    <span style={{ marginLeft: '0.5rem' }}><i className="fas fa-check" /></span>
                                </>}
                            </a>
                        )
                    })}
                </div>
            </div>}

        </>;


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Language);
