import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperRegister";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";

import { PARTNER_API } from "../../../actions/partner/actions";
// import { BP_API } from '../../../actions/bp/actions';

import FormAbout from "../components/formAboutBoutique";

import { TitleBorder, TitleMiddleLine } from "../../components/Titles";

import { PartnerStatus } from "../../../model";

import styled from "styled-components";

import { Row, Col, Input, Select, Button, Form } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: "60px";
  }
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (data) => {
      dispatch(PARTNER_API.update_pre_signup(data));
    },
    new_company: (data) => {
      dispatch(PARTNER_API.new_company(data));
    },
    // get_partner: id => {
    //     dispatch(BP_API.get_partner(id));
    // },
  };
};

class AboutBoutique extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { partner } = this.props;

    if (
      this.props.partner &&
      this.props.partner.status > PartnerStatus.Incomplete
    ) {
      return (
        <Redirect
          to={`/parceiro/obrigado-pre-cadastro/${this.props.partner.partnerId}`}
        />
      );
    }

    //TODO: Find better way... GAMBI!
    let menu = [...constants.MENU_PRE_SIGNUP];
    for (let key in menu) {
      menu[key].label = I18n.get(menu[key].label);
    }

    return (
      <AppWrapperSimple>
        <SubHeader
          titlePage={I18n.get("Pré-cadastro")}
          itensMenu={menu}
          urlId={this.props.match.params.partnerId}
          activeItem={1}
        />
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("1 de 5")}</TitleBorder>
              </Col>
            </Row>

            <FormAbout
              partner={this.props.partner && this.props.partner}
              partnerId={this.props.match.params.partnerId}
              action={this.props.save}
              isNewCompany={this.props.new_company}
              errors={this.props.partner && this.props.partner.errors}
              diversity={this.props.bp && this.props.bp.diversity}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
            />

            <Row>
              <Col sm={3}></Col>
              <Col sm={18}></Col>
              <Col sm={3}>
                <Link
                  to={`/parceiro/pre-cadastro/especialidades/${this.props.match.params.partnerId}`}
                >
                  <Button type="primary" shape="round">
                    {I18n.get("Avançar")}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Main>
        </ContainerPage>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutBoutique);
