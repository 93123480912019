import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Steps } from "antd";
// import { Tag } from "../../../../components/UI/Tag";
// import { Input } from "../../../../components/UI/Input";
// import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import * as S from "./styles";

const { Step } = Steps;

export const StatusInvoice = ({ data, isLoading }) => {
  const whatStatus = (status) => {
    if (status === true) {
      return "finish";
    } else if (status === false) {
      return "process";
    } else {
      return "wait";
    }
  };

  const renderSteps = () => {
    return (
      <Steps direction="vertical">
        <Step
          title={I18n.get("NF Fornecedor")}
          description={data?.nfFornecedorMsg || I18n.get("Aguardando")}
          status={whatStatus(data?.isNfFornecedor)}
        />
        <Step
          title={I18n.get("Pagamento agendado")}
          description={data?.pgtAgendadoMsg || I18n.get("Aguardando")}
          status={whatStatus(data?.isPagamentoAgendado)}
        />
        <Step
          title={I18n.get("Pagamento realizado")}
          description={data?.pgtRealizadoMsg || I18n.get("Aguardando")}
          status={whatStatus(data?.isPagamentoRealizado)}
        />
      </Steps>
    );
  };

  return (
    <S.Wrapper>
      <S.Skeleton
        loading={isLoading}
        active
        title={false}
        paragraph={{
          rows: 6,
          className: "skeleton-step",
        }}
      >
        {renderSteps()}
      </S.Skeleton>
    </S.Wrapper>
  );
};
