import React from 'react';
import * as S from './styles';

const MainTitle = ({text, size, marginLeft}) => { 

    return (
       <S.StyledTitle size={size ? size : "20px"} marginLeft={marginLeft ? marginLeft : "30px"}>{text}</S.StyledTitle>
    );

}

export default MainTitle;