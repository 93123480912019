import React, { useState } from 'react';
import Checkbox from '../Checkbox';

import * as S from './styles';

export const BoxWithCheckboxes = ({
  checkboxes,
  checkboxIdTab,
  onChangeCheck,
  components,
  actions,
}) => {
  const handleChangeCheckbox = ({ id }) => {
    onChangeCheck(id);
  };

  return (
    <S.Wrapper>
      <S.Header>
        {checkboxes.map((checkbox, index) => (
          <S.ContentCheckbox
            key={`${index}-${checkbox}`}
            onClick={() => handleChangeCheckbox({ id: `id-${index}` })}
          >
            <Checkbox
              id={`id-${index}`}
              onChangeCb={(e, id) => handleChangeCheckbox({ id })}
              checked={checkboxIdTab === `id-${index}` ? true : false}
              checkboxSize={18}
            />
            <S.LabelCheckbox>{checkbox}</S.LabelCheckbox>
          </S.ContentCheckbox>
        ))}
      </S.Header>
      <S.Body>
        {components.map((component, index) =>
          checkboxIdTab === `id-${index}` ? (
            <div key={index}>{component}</div>
          ) : null
        )}
      </S.Body>
      {actions?.length ? (
        <S.Actions>
          {actions?.map((action, index) => (
            <div key={`action-${index}`}>{action}</div>
          ))}
        </S.Actions>
      ) : null}
    </S.Wrapper>
  );
};
