import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import { ModalCta } from "../../../../../components/UI/ModalCta";
import { Link } from "react-router-dom";

import IcoEdit from "../../svgs/ico-edit.svg";
import * as S from "./styles";

export const ReviewProject = ({ project }) => {
  let params = useParams();
  const history = useHistory();
  const { addAssetBundleToProject } = useProjects();
  const [openModal, setOpenModal] = useState(false);
  const [successEditOrDelete, setSuccessEditOrDelete] = useState({
    success: false,
    message: "",
  });
  const [loadingRemoveAll, setLoadingRemoveAll] = useState(false);

  const handleModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
      setSuccessEditOrDelete({ success: false, message: "" });
    }
  };

  const editReview = async () => {
    try {
      // integração...
      setSuccessEditOrDelete({
        success: true,
        message: "Editado ok!",
      });
    } catch (error) {
      console.log("saveMeetingDate error", error);
    }
  };

  const removeAllItems = async () => {
    setLoadingRemoveAll(true)
    const objSend = {
      projectId: params.projectId,
      bundleId: null,
      bundle: null
    };
    const response = await addAssetBundleToProject(objSend);

    if (response?.success) {
      history.push(`/cliente/projetos/v2/selecionar-assets/${params.projectId}`);
    }

    setLoadingRemoveAll(false);
  }

  return (
    <>
      <S.Wrapper data-label="boxRevisao">
        <S.BoxInfo style={{ marginTop: 0 }}>
          <S.TitleBox>
            <p>{I18n.get("Informações")}</p>

            <Link to={`/cliente/projetos/v2/criar-projeto/${project?.id}`}>
              <button type="button" data-label="Editar informações">
                <img src={IcoEdit} alt="" />
              </button>
            </Link>
          </S.TitleBox>
          <S.Info style={{ marginTop: 15 }}>
            <strong>{I18n.get("Nome do Projeto")}:</strong>
            <span>{project?.projectName}</span>
          </S.Info>
          <S.Info>
            <strong>{I18n.get("Número do Projeto")}:</strong>
            <span>{project?.projectSKU}</span>
          </S.Info>
          <S.Info>
            <strong> {I18n.get("Marca / Produto")}:</strong>
            <span>
              {project?.brandName} / {project?.productName}
            </span>
          </S.Info>
        </S.BoxInfo>

        <S.BoxInfo>
          <S.TitleBox>
            <p> {I18n.get("Escopo Criativo")}</p>
            <button type="button" onClick={removeAllItems} disabled={loadingRemoveAll} data-label="Editar Creative Scope">
              {loadingRemoveAll ? <S.StyledIcon type="loading" sizeIcon={16} color="#1883ff" /> : <img src={IcoEdit} alt="" />}
            </button>
          </S.TitleBox>
          <S.Info style={{ marginTop: 15 }}>
            <strong> {I18n.get("Tipo")}:</strong>
            <span>{project?.bundle?.title}</span>
          </S.Info>
          <S.Info>
            <strong>{I18n.get("Valor")}:</strong>
            <span>{project?.priceClientWithDiscountWithOtherTaxFmt}</span>
          </S.Info>
          <S.Info>
            <strong>{I18n.get("Modelo")}:</strong>
            <span>
              {project?.bundle?.variations &&
                project?.bundle?.variations[0]?.variationType}
            </span>
          </S.Info>
        </S.BoxInfo>
        {/*  */}

        {project?.partner && (
          <S.BoxInfo>
            <S.TitleBox>
              <p>{I18n.get("Parceiro")}</p>
              <button type="button" data-label="Editar Parceiro">
                <img src={IcoEdit} alt="Edit information" />
              </button>
            </S.TitleBox>
            <S.Info style={{ marginTop: 15 }}>
              <strong>{I18n.get("Nome")}:</strong>
              <span>{I18n.get("A ser definido")}:</span>
            </S.Info>
            <S.Info>
              <strong>{I18n.get("Perfil")}:</strong>
              <span>
                {I18n.get("Parceiro da Região 1 com expertise na categoria da minha marca.")}
              </span>
            </S.Info>
            <S.Info>
              <strong>{I18n.get("Prazo de seleção")}:</strong>
              <span>{I18n.get("2 semanas")}</span>
            </S.Info>
            <S.Info>
              <strong>{I18n.get("Modelo")}:</strong>
              <span>A</span>
            </S.Info>
          </S.BoxInfo>
        )}
        {project?.datas && (
          <S.BoxInfo noBorder>
            <S.TitleBox>
              <p>{I18n.get("Datas")}</p>
              <button type="button" data-label="Editar Datas">
                <img src={IcoEdit} alt="Edit information" />
              </button>
            </S.TitleBox>
            <S.Info style={{ marginTop: 15 }}>
              <strong>{I18n.get("Data estimada de início do projeto")}:</strong>
              <span>18/10/2021</span>
            </S.Info>
            <S.Info>
              <strong>{I18n.get("Data estimada de conclusão do projeto")}:</strong>
              <span>{I18n.get("2 semanas")}</span>
            </S.Info>
          </S.BoxInfo>
        )}
      </S.Wrapper>

      <ModalCta
        openModal={openModal}
        message={I18n.get("Edite item do projeto")}
        titleButtonBack={I18n.get("Voltar")}
        titleButtonConfirm={I18n.get("Salvar")}
        bgColorButtonConfirm="#1883ff"
        cbButtonConfirm={editReview}
        onCloseModal={handleModal}
        loading={false}
        success={successEditOrDelete}
      />
    </>
  );
};
