import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";

import * as S from "./styles";
import MicRecorder from "mic-recorder-to-mp3";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import ReactAudioPlayer from "react-audio-player";
import { useDebounce } from "use-debounce";

import { PROJECTS_API } from "../../../../store/actions/projects";

import axios from "axios";

import { Textarea } from "../../../../components/UI/Textarea";
import Content from "../../../../components/UI/Content";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { H2Border } from "../../../../components/UI/Titles";

import StepsCreateProject from "../components/StepsCreateProject";
import { ReviewProject } from "../components/ReviewProject";
import Main from "../components/Main";
import TopInformation from "../components/TopInformation";

import { Row, Col, notification, Icon, Popconfirm, Upload, Spin } from "antd";

import { v4 as uuidv4 } from "uuid";
import Config from "../../../../../config";

import IcoAttachment from "../svgs/ico-attachment.svg";
import MicrophoneIconStop from "../../../../../svg/chat-icon-stop-audio.svg";
import IcoAudio from "../svgs/ico-audio.svg";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export const CreateProjectStep3 = () => {
  let params = useParams();
  let history = useHistory();

  const get2 = useSelector((state) => state.get2);
  const auth = useSelector((state) => state.auth);

  const [projectDetails, setProjectDetails] = useState({});

  const [loadingFile, setLoadingFile] = useState(false);

  const [demandText, setDemandText] = useState("");

  const [mp3Audio, setMp3Audio] = useState({
    isRecording: false,
    blobURL: "",
    isBlocked: false,
    loading: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getStep();
    // calcTotal();
  }, []);

  const [getValueDemand] = useDebounce(demandText, 2000);

  useEffect(() => {
    const data = {
      projectId: params.projectId,
      demand: demandText,
    };
    if (Object.keys(projectDetails).length > 0) {
      PROJECTS_API.update_demand(data).then((response) => {
        getStep();
      });
    }
  }, [getValueDemand]);

  useEffect(() => {
    setDemandText(projectDetails.demand);
  }, [projectDetails]);

  const permissionMic = async () => {
    // Mic
    navigator.getUserMedia(
      { audio: true },
      () => {
        setMp3Audio({
          ...mp3Audio,
          isBlocked: false,
        });
      },
      () => {
        setMp3Audio({
          ...mp3Audio,
          isBlocked: true,
        });
      }
    );
  };

  const getDataUploadFile = async (dataForm) => {
    const data = {
      ...dataForm,
      projectId: params.projectId,
    };

    PROJECTS_API.demand_file(data).then((response) => {
      if (response?.data?.success) {
        getStep();
      }
      // setProjectDetails(response?.payload?.project);
    });
  };

  // const onClickSendAudio = async () => {
  //   await permissionMic();

  //   if (mp3Audio.isBlocked) {
  //     console.log("Permission Denied");
  //   } else {
  //     Mp3Recorder.start()
  //       .then(() => {
  //         setMp3Audio({
  //           ...mp3Audio,
  //           isRecording: true,
  //         });
  //       })
  //       .catch((e) => console.error(e));
  //   }
  // };

  const sendToServer = async (file) => {
    const EndpointGetAuthToUpload = get2
      ? Config.UPLOAD_URL2
      : Config.UPLOAD_URL;

    let fileSplit = file.name.split(".");
    let fileSafe = file.name.replace(/[^a-z0-9]/gi, "-");
    let fileNew = `${fileSafe}_${uuidv4()}`;
    if (fileSplit.length > 1) {
      fileNew = fileNew + "." + fileSplit[fileSplit.length - 1];
    }

    let id = auth.partnerId || auth.clientId; //Dependenddo do contexto, será ClientId ou PartnerId ou ProjectId

    let key = `${id}/${fileNew}`; //Caminho final do arquivo - contexto/nome-unico-arquivo.txt

    //Access Token vindo do Cognito
    let authHeader = {
      headers: { Authorization: "Bearer " + auth.jwtToken },
    };

    //EndPoint para pegar autorização para subir o arquivo. passar a chave (key) na queryString
    let preSignUrl = `${EndpointGetAuthToUpload}?k=${key}${
      get2 ? "&t=pleader" : ""
    }`;

    axios.get(preSignUrl, authHeader).then((resp) => {
      let { url, fields } = resp.data;

      let formData = new FormData();

      //Dados recebidos para autorizar o upload do arquivo
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });

      //arquivo

      formData.append("file", file);

      var config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      //tendo sucesso, fazer POST do arquivo com os dados de autorização

      axios
        .post(url, formData, config)
        .then(async (response) => {
          let urlClean =
            "https://" + resp.data.url.split("s3.amazonaws.com/")[1] + "/";

          let dataForm = {
            url: urlClean + resp.data.fields.key,
            size: file.size,
            type: file.type,
            name: file.name,
          };

          await getDataUploadFile(dataForm);

          setMp3Audio({
            ...mp3Audio,
            isRecording: false,
          });
        })
        .catch((error) => {
          notification.error({
            message: "ops! Algo deu errado.",
            description: "Tente novamente por favor.",
          });
        });
    });
  };

  // const onClickStopAudio = async () => {
  //   Mp3Recorder.stop()
  //     .getMp3()
  //     .then(([buffer, blob]) => {
  //       const blobURL = URL.createObjectURL(blob);

  //       setMp3Audio({
  //         ...mp3Audio,
  //         blobURL,
  //         isRecording: false,
  //         loading: true,
  //       });

  //       const file = new File(buffer, "audio.mp3", {
  //         type: blob.type,
  //         lastModified: Date.now(),
  //       });

  //       sendToServer(file);
  //     })
  //     .catch((e) => console.log(e));
  // };

  const onSendFile = async (file) => {
    setLoadingFile(true);
    sendToServer(file);
  };

  const onDeleteFile = async (id, type) => {
    const data = {
      id: id,
      projectId: params.projectId,
    };

    if (type == "audio") {
      setMp3Audio({
        ...mp3Audio,
        isRecording: false,
        loading: true,
      });
    } else {
      setLoadingFile(true);
    }

    PROJECTS_API.delete_demand_file(data).then((response) => {
      if (response?.data?.success) {
        getStep();
      }
    });
  };

  const getStep = () => {
    function getProject() {
      const data = {
        projectId: params.projectId,
      };
      PROJECTS_API.get_project_step3(data).then((response) => {
        setProjectDetails(response?.payload?.project);

        setLoadingFile(false);
        setMp3Audio({
          ...mp3Audio,
          isRecording: false,
          loading: false,
        });
      });
    }
    getProject();
  };

  const { demandFiles = [] } = projectDetails;

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Novo Projeto")}</H2Border>
            </Col>
            <Col sm={12} justify="end"></Col>
          </Row>
          <Row>
            <Col sm={24}>
              <S.StyleMain>
                <StepsCreateProject
                  active={3}
                  projectId={params.projectId || ""}
                />
              </S.StyleMain>

              <div>
                <Row>
                  <Col xs={24} sm={17}>
                    <Main padding="30px" style={{ background: "#fff" }}>
                      <Row>
                        <Col xs={24} sm={24}>
                          <TopInformation>
                            <>
                              <h3>{I18n.get("Demandas")}</h3>

                              <p>
                                {I18n.get(
                                  "Caso queira adicionar mais informações e anexar um arquivo para dar mais informações ao parceiro, envie-nos uma mensagem. Se estiver tudo bem, é só enviar!"
                                )}
                              </p>
                            </>
                          </TopInformation>

                          <S.ContentAttachments>
                            <S.BoxSelectAttachment>
                              <Upload
                                fileList={false}
                                onChange={(e) => onSendFile(e.file)}
                              >
                                <S.SelectAttachment>
                                  <img
                                    src={IcoAttachment}
                                    alt="Attachment File"
                                  />
                                </S.SelectAttachment>
                              </Upload>
                            </S.BoxSelectAttachment>
                            <S.AttachmentList>
                              {loadingFile && (
                                <S.Loading>
                                  <Spin indicator={antIcon} />
                                </S.Loading>
                              )}
                              {demandFiles.map((file) => {
                                if (file.type != "audio/mp3") {
                                  return (
                                    <S.AttachmentSelected key={file?.id}>
                                      <a href={file?.url}>{file?.name}</a>

                                      <Popconfirm
                                        title={I18n.get(
                                          "Você realmente deseja excluir este arquivo?"
                                        )}
                                        placement="left"
                                        icon={
                                          <Icon
                                            type="question-circle-o"
                                            style={{ color: "red" }}
                                          />
                                        }
                                        onConfirm={() =>
                                          onDeleteFile(file.id, "file")
                                        }
                                        okText={I18n.get("Sim")}
                                        cancelText={I18n.get("Não")}
                                      >
                                        <a>
                                          <Icon type="delete" theme="filled" />
                                        </a>
                                      </Popconfirm>
                                    </S.AttachmentSelected>
                                  );
                                }
                              })}
                            </S.AttachmentList>
                          </S.ContentAttachments>

                          <S.ContentAboutProject>
                            <Textarea
                              id="demandText"
                              label={I18n.get(
                                "Conte-nos mais sobre seu projeto (opcional)"
                              )}
                              rows={4}
                              defaultValue={projectDetails?.demand}
                              onChange={(value) => setDemandText(value)}
                              // error={hasError({
                              //   field: 'description',
                              //   arr: inputsErrorsForm,
                              // })}
                            />
                          </S.ContentAboutProject>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={24}>
                          <S.FooterButtons className="buttonsContainer">
                            <ButtonBpool
                              text={I18n.get("Voltar")}
                              theme="secondary"
                              onClick={() =>
                                history.push(
                                  `/cliente/projetos/v2/selecionar-assets/${params.projectId}`
                                )
                              }
                            />
                            <Link
                              to={`/cliente/projetos/v2/parceiros/${params.projectId}`}
                            >
                              <ButtonBpool
                                text={I18n.get("Próximo")}
                                className=""
                                theme="primary"
                              />
                            </Link>
                          </S.FooterButtons>
                        </Col>
                      </Row>
                    </Main>
                  </Col>
                  <Col xs={24} sm={7}>
                    <Main
                      padding="30px"
                      style={{ background: "#fafafa", padding: "0" }}
                    >
                      <ReviewProject project={projectDetails} />
                    </Main>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
