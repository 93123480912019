import axios from 'axios';
import { Auth } from 'aws-amplify';
import Config from '../../../../config';

axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';

export const useNotifications = () => {
  const getFilter = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/AdminNotification/filter`,
      config
    );

    return data;
  };

  const getFilterSaved = async ({ email, name }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const params = `email=${email}&name=${name}`;

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/AdminNotification?${params}`,
      config
    );

    return data;
  };

  const getById = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/AdminNotification/${id}`,
      config
    );

    return data;
  };

  const getUsers = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/AdminNotification`,
      config
    );

    return data;
  };

  const postUser = async (objToSend) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/AdminNotification`,
      objToSend,
      config
    );

    return data;
  };

  const putUser = async (objToSend) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/AdminNotification`,
      objToSend,
      config
    );

    return data;
  };

  const deleteUser = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/AdminNotification/${id}`,
      config
    );

    return data;
  };

  return {
    getFilter,
    getFilterSaved,
    getById,
    getUsers,
    postUser,
    putUser,
    deleteUser,
  };
};
