import {
  Avatar,
  ChatList,
  ChatListItem,
  Message,
  MessageGroup,
  MessageList,
  MessageMedia,
  MessageText,
  Row,
  SendButton,
  TextComposer,
  TextInput,
  ThemeProvider,
  Title,
  Subtitle,
} from '@livechat/ui-kit';
import { I18n } from 'aws-amplify';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { CHAT_API } from '../../actions/chat/actions';
import { CLIENT_API } from '../../actions/client/actions';
import constants from '../../constants';
import ChatLogoIcon from '../../svg/chat-logo.svg';
import MicrophoneIcon from '../../svg/icon-microphone.svg';
import MicrophoneIconStop from '../../svg/icon-microphone-stop.svg';
import FileIcon from '../../svg/icon-file.svg';
import VideoCallIcon from '../../svg/icon-videocall-2.svg';
import IconButton from '../form/icon-button';
import {
  ChatListContainer,
  ConversationContainer,
  FlexRowDiv,
  LoadingMessagesContainer,
  MessageListContainer,
  ModalBackground,
  TextComposerContainer,
  LoadingIconAudioContainer,
} from '../shared/chat-components';
import SimpleModal from '../ui/simple-modal';
import ChatFileUpload from '../../modules/components/ChatFileUpload';
import { getFileExtensionIcon } from '../shared/_utils';

import axios from 'axios';
import Config from '../../config';

import { notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import MicRecorder from 'mic-recorder-to-mp3';
import ReactAudioPlayer from 'react-audio-player';

const mapStateToProps = (state) => ({
  state,
  auth: state.auth,
  chat: state.chat,
  partner: state.partner,
  client: state.client,
  isOpen: state.isOpen,
});

const mapDispatchToProps = (dispatch) => {
  return {
    send_chat_message: (topic, message) => {
      return dispatch(CHAT_API.send_chat_message(topic, message));
    },
    list_messages: (data) => {
      return dispatch(CHAT_API.list_messages(data));
    },
    receive_message: (data) => {
      return dispatch(CHAT_API.receive_message(data));
    },
    get_project_invitations: (data) => {
      return dispatch(CLIENT_API.get_project_invitations(data));
    },
    chat_topic_all: (topic) => {
      return dispatch(CHAT_API.chat_topic_all(topic));
    },
    receive_chat_messages: (data) => {
      return dispatch(CHAT_API.receive_chat_messages(data));
    },
    receive_new_chat_message: (data) => {
      dispatch(CHAT_API.receive_new_chat_message(data));
    },
  };
};

function LoadingMessages(props) {
  return (
    <LoadingMessagesContainer className={props.active ? 'active' : ''}>
      <i className="fas fa-spinner fa-pulse"></i>
    </LoadingMessagesContainer>
  );
}

function CollapseButton(props) {
  var className =
    'collapse-chat-button no-webkit ' + (props.chatListOpen ? 'is-open' : '');

  return (
    <a className={className} onClick={() => props.onClick()}>
      {props.chatListOpen && props.chatListOpen ? (
        <i class="fas fa-angle-double-left"></i>
      ) : (
        <i class="fas fa-angle-double-right"></i>
      )}
    </a>
  );
}

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

let firstChatSelected = false;
let chatSelectedFromParam = false;

class Chat extends React.Component {
  constructor(props) {
    super();

    const initialState = {
      activePartner: {
        id: false,
        name: false,
        logo: '',
      },
      activeProject: {
        projectId: false,
        projectName: false,
      },
      loadingMessage: true,
      invitations: [],
      chatListOpen: true,

      activeChat: {
        topic: '',
        clientId: '',
        userOther: {
          userOtherId: '',
          userOtherName: '',
        },
      },
      mp3Audio: {
        isRecording: false,
        blobURL: '',
        isBlocked: false,
        loading: false,
      },
    };

    this.scrollToRef = null;
    this.scrollFromRef = null;

    this.setScrollFromRef = (element) => {
      this.scrollFromRef = element;
    };

    this.setScrollToRef = (element) => {
      this.scrollToRef = element;
    };

    this.setSCroll = () => {
      if (this.scrollFromRef && this.scrollToRef) {
        let offset = this.scrollToRef.parentNode.offsetTop - 200;
        this.scrollFromRef.scrollTop = offset > 0 ? offset : 0;
      }
    };
    this.state = initialState;
  }

  async chatTopicAll(topic) {
    this.props.chat_topic_all(topic);
  }

  async onActiveChatList(topic, name) {
    this.setState({ ...this.state, loadingMessage: true });
    const topicParts = topic.split('/');
    const clientId = this.props.auth.clientId;
    const userOtherId = topicParts[2];
    const userOtherName = name;

    this.setState({
      ...this.state,
      activeChat: {
        topic,
        clientId,
        userOther: { userOtherId, userOtherName },
      },
      loadingMessage: true,
    });

    await this.chatTopicAll(topic);
    this.setState({ ...this.state, loadingMessage: false });
  }

  componentDidMount(prevProps) {
    //
  }

  componentWillUnmount() {
    this.props.list_messages(null);
    firstChatSelected = false;
  }

  selectFirstChatTopic(topic, chatTopics) {
    const firstChat = chatTopics && chatTopics[0];

    if (!topic && !firstChatSelected && firstChat) {
      this.onActiveChatList(firstChat?.Topic, firstChat?.Name);

      firstChatSelected = true;
    }
  }

  setChatString(chatString, chatTopics) {
    if (!chatSelectedFromParam && chatTopics?.length) {
      const topicFinded = chatTopics.find((chat) => chat.Topic === chatString);

      if (topicFinded) {
        this.onActiveChatList(topicFinded?.Topic, topicFinded?.Name);
      }

      chatSelectedFromParam = true;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const chat = this.props.chat;
    const chatActiveMessages = this.props.chat?.chatActiveMessages;
    const chatTopics = this.props.chat?.chatTopics;

    if (this.props.chatTopicString) {
      this.setChatString(this.props.chatTopicString, chatTopics);
    } else {
      if (!firstChatSelected) {
        this.selectFirstChatTopic(prevState.activeChat.topic, chatTopics);
      }
    }

    if (this.state.loadingMessage) {
      if (chat && chatActiveMessages?.length > 0) {
        setTimeout(() => {
          this.setState({ ...this.state, loadingMessage: false });
        }, 500);
      }
    }

    setTimeout(() => {
      this.setSCroll();
    }, 0);
  }

  activeChatFromInvitations() {
    if (this.props.invitations && this.props.invitations[0]) {
      let invitation = this.props.invitations[0];
      if (invitation.partners && invitation.partners[0]) {
        let partner = invitation.partners[0];
        this.onActiveChatList(partner, invitation);
      }
    }
  }

  onCloseModal() {
    this.props.clearActiveChat();

    this.setState({
      activePartner: false,
      activeProject: false,
    });
    this.props.onCloseModal();
  }

  renderMessageGroup(messages, isOwn) {
    let textAlign = isOwn ? { textAlign: 'right' } : { textAlign: 'left' };
    let authorName = isOwn
      ? I18n.get('Eu')
      : this.state.activeChat.userOther.userOtherName;
    return (
      <MessageGroup className="message-group" isOwn={isOwn} onlyFirstWithMeta>
        {messages.map((message) => {
          let messageDate = moment(message.createdAt);
          let messageShowDate = messageDate.isBefore(new Date(), 'day')
            ? messageDate.format('DD/MM HH:mm')
            : messageDate.format('HH:mm');

          if (message.message.search(constants.CHAT_MESSAGE_TYPE_MEET) >= 0) {
            let cleanMessage = message.message.replace(
              constants.CHAT_MESSAGE_TYPE_MEET,
              ''
            );
            return (
              <Message
                key={`message_${message.messageId}`}
                className="message-info"
                authorName={authorName}
                date={messageShowDate}
                isOwn={isOwn}
              >
                <span>
                  {I18n.get(
                    'Por favor, acesse a videochamada através do link:'
                  )}{' '}
                  <a href={cleanMessage} target="_blank">
                    {I18n.get('VIDEOCHAMADA')}
                  </a>
                </span>
              </Message>
            );
          } else if (
            message.message.search(constants.CHAT_MESSAGE_TYPE_FILE) >= 0
          ) {
            let icon_type = getFileExtensionIcon(message.message);

            let cleanMessage = message.message.replace(
              constants.CHAT_MESSAGE_TYPE_FILE,
              ''
            );
            return (
              <Message
                key={`message_${message.messageId}`}
                className="message-info"
                authorName={authorName}
                date={messageShowDate}
                isOwn={isOwn}
              >
                <a
                  className="chat-file-link"
                  href={cleanMessage}
                  target="_blank"
                >
                  <span>{I18n.get('Baixe o arquivo clicando aqui:')}</span>{' '}
                  <i className={icon_type}></i>
                </a>
              </Message>
            );
          } else if (
            message.message.search(constants.CHAT_MESSAGE_TYPE_AUDIO) >= 0
          ) {
            let cleanMessage = message.message.replace(
              constants.CHAT_MESSAGE_TYPE_AUDIO,
              ''
            );
            return (
              <Message
                key={`message_${message.messageId}`}
                className="message-info"
                authorName={authorName}
                date={messageShowDate}
                isOwn={isOwn}
              >
                <div style={{ width: 300 }}>
                  <ReactAudioPlayer
                    src={cleanMessage}
                    controls
                    style={{ width: '100%' }}
                  />
                </div>
              </Message>
            );
          } else {
            return (
              <Message
                key={`message_${message.messageId}`}
                className="message-info"
                authorName={authorName}
                date={messageShowDate}
                isOwn={isOwn}
              >
                <MessageText className="message-text" style={textAlign}>
                  {message.message}
                </MessageText>
              </Message>
            );
          }
        })}
      </MessageGroup>
    );
  }

  async sendChatMessage(message) {
    if (!this.state.loadingMessage) {
      await this.setState({ ...this.state, loadingMessage: true });
    }

    const topic = this.state.activeChat.topic;

    await this.props.send_chat_message({ topic, message });

    if (this.state.loadingMessage) {
      setTimeout(() => {
        this.setState({ ...this.state, loadingMessage: false });
      }, 100);
    }
  }

  async permissionMic() {
    // Mic
    navigator.getUserMedia(
      { audio: true },
      () => {
        this.setState({
          ...this.state,
          mp3Audio: { ...this.state.mp3Audio, isBlocked: false },
        });
      },
      () => {
        this.setState({
          ...this.state,
          mp3Audio: { ...this.state.mp3Audio, isBlocked: true },
        });
      }
    );
  }

  async onClickSendAudio() {
    await this.permissionMic();

    if (this.state.mp3Audio.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({
            ...this.state,
            mp3Audio: { ...this.state.mp3Audio, isRecording: true },
          });
        })
        .catch((e) => console.error(e));
    }
  }

  async getDataUploadAudio(dataForm) {
    const { field, url } = dataForm;

    if (url) {
      this.sendChatMessage(`${constants.CHAT_MESSAGE_TYPE_AUDIO}${url}`);
    }
  }

  async sendAudioToServer(file) {
    const EndpointGetAuthToUpload = this.props.get2
      ? Config.UPLOAD_URL2
      : Config.UPLOAD_URL;

    let fileSplit = file.name.split('.');
    let fileSafe = file.name.replace(/[^a-z0-9]/gi, '-');
    let fileNew = `${fileSafe}_${uuidv4()}`;
    if (fileSplit.length > 1) {
      fileNew = fileNew + '.' + fileSplit[fileSplit.length - 1];
    }

    let id = this.props.auth.partnerId; //Dependenddo do contexto, será ClientId ou PartnerId ou ProjectId

    let key = `${id}/${fileNew}`; //Caminho final do arquivo - contexto/nome-unico-arquivo.txt

    //Access Token vindo do Cognito
    let authHeader = {
      headers: { Authorization: 'Bearer ' + this.props.auth.jwtToken },
    };

    //EndPoint para pegar autorização para subir o arquivo. passar a chave (key) na queryString
    let preSignUrl = `${EndpointGetAuthToUpload}?k=${key}${
      this.props.get2 ? '&t=pleader' : ''
    }`;

    axios.get(preSignUrl, authHeader).then((resp) => {
      let { url, fields } = resp.data;

      let formData = new FormData();

      //Dados recebidos para autorizar o upload do arquivo
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });

      //arquivo

      formData.append('file', file);

      var config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      //tendo sucesso, fazer POST do arquivo com os dados de autorização
      axios
        .post(url, formData, config)
        .then(async (response) => {
          let urlClean =
            'https://' + resp.data.url.split('s3.amazonaws.com/')[1] + '/';

          let dataForm = {
            field: 'audio_file',
            url: urlClean + resp.data.fields.key,
          };

          await this.getDataUploadAudio(dataForm);
          this.setState({
            ...this.state,
            loadingMessage: false,
            mp3Audio: { ...this.state.mp3Audio, loading: false },
          });
        })
        .catch((error) => {

          notification.error({
            message: 'ops! Algo deu errado.',
            description: 'Tente novamente por favor.',
          });
        });
    });
  }

  async onClickStopAudio() {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        this.setState({
          ...this.state,
          loadingMessage: true,
          mp3Audio: {
            ...this.state.mp3Audio,
            blobURL,
            isRecording: false,
            loading: true,
          },
        });

        const file = new File(buffer, 'audio.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });

        this.sendAudioToServer(file);
      })
      .catch((e) => console.log(e));
  }

  onClickVideoCall() {
    const clientId = this.props.client.clientId;
    const partnerId = this.state.activePartner && this.state.activePartner.id;
    const projectId =
      this.state.activeProject && this.state.activeProject.projectId;

    if (partnerId && clientId) {
      this.sendChatMessage(
        `${constants.CHAT_MESSAGE_TYPE_MEET}https://meeting.bpool.co/${projectId}${clientId}${partnerId}`
      );
    }
  }

  getDataUpload = (dataForm) => {
    const { field, url } = dataForm;
    this.setState({
      fileUrl: url,
    });

    const fileUrl = this.state.fileUrl;

    if (fileUrl) {
      this.sendChatMessage(`${constants.CHAT_MESSAGE_TYPE_FILE}${fileUrl}`);
    }
  };

  render() {
    let sameUser = false;
    let oldUser = false;
    let messagesGroups = [];
    let messagesList = [];

    let messages = this.props.chat && this.props.chat.chatActiveMessages;

    // console.log('messages', messages);

    if (messages && messages.length > 0) {
      messages.map((message, index) => {
        let isOwn = message.from === this.props.partner?.username;
        sameUser = message.from === oldUser;
        oldUser = message.from;

        messagesList.push(message);

        if (!sameUser) {
          messagesGroups.push(this.renderMessageGroup(messagesList, isOwn));
          messagesList = [];
          oldUser = false;
        }

        if (this.props.chat.chatActiveMessages.length === index + 1) {
          messagesGroups.push(this.renderMessageGroup(messagesList, isOwn));
          messagesList = [];
        }
      });
    }

    return (
      <SimpleModal
        className="is-round overflow chat"
        isOpen={this.props.isOpen}
        onCloseModal={() => this.onCloseModal()}
        size="fullscreen"
      >
        <ModalBackground>
          <ThemeProvider>
            <FlexRowDiv>
              <CollapseButton
                onClick={() =>
                  this.setState({ chatListOpen: !this.state.chatListOpen })
                }
                chatListOpen={this.state.chatListOpen}
              />
              <ChatListContainer
                className={this.state.chatListOpen ? 'is-open' : ''}
                ref={this.setScrollFromRef}
              >
                {this.props.chat.chatTopics && (
                  <>
                    <ChatList className="chat-list">
                      {this.props.chat.chatTopics.map((topic, index) => {
                        let listItemClassName = 'chat-list-item';

                        if (this.state.activeChat.topic === topic.Topic) {
                          listItemClassName = [
                            listItemClassName,
                            'active',
                          ].join(' ');
                        }

                        return (
                          <ChatListItem
                            key={`${topic.Topic}_${index}`}
                            className={listItemClassName}
                            onClick={() =>
                              this.onActiveChatList(topic.Topic, topic.Name)
                            }
                          >
                            <Avatar
                              key={`avatar_${topic.Topic}_${index}`}
                              className="avatar-big"
                              imgUrl={topic.CompanyLogo}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Title
                                key={`title_${topic.CompanyName}_${index}`}
                                ellipsis
                                className="chat-list-title"
                              >
                                {topic.CompanyName}
                              </Title>
                              <Subtitle
                                key={`subtitle${topic.Name}_${index}`}
                                ellipsis
                                className="chat-list-subtitle"
                                style={{ marginTop: 7, fontSize: 12 }}
                              >
                                {topic.Name}
                              </Subtitle>
                            </div>
                          </ChatListItem>
                        );
                      })}
                    </ChatList>
                  </>
                )}
              </ChatListContainer>
              <ConversationContainer
                className={this.state.chatListOpen ? 'is-open' : ''}
              >
                <MessageListContainer>
                  <MessageList className="message-list" active>
                    {messagesGroups}
                  </MessageList>
                  <LoadingMessages active={this.state.loadingMessage} />
                </MessageListContainer>
                <TextComposerContainer>
                  <TextComposer
                    className="text-composer"
                    onSend={(value) => this.sendChatMessage(value)}
                  >
                    <Row className="row-input" align="center">
                      {this.state.mp3Audio.loading ? (
                        <LoadingIconAudioContainer>
                          <i className="fas fa-spinner fa-pulse"></i>
                        </LoadingIconAudioContainer>
                      ) : (
                        <IconButton
                          style={{
                            marginRight: '5px',
                            width: '35px',
                            height: '35px',
                          }}
                          svgIcon={
                            this.state.mp3Audio.isRecording
                              ? MicrophoneIconStop
                              : MicrophoneIcon
                          }
                          alt="Audio"
                          onClick={
                            this.state.mp3Audio.isRecording
                              ? () => this.onClickStopAudio()
                              : () => this.onClickSendAudio()
                          }
                        />
                      )}
                      <IconButton
                        style={{
                          marginRight: '5px',
                          width: '35px',
                          height: '35px',
                          background: 'transparent',
                        }}
                        svgIcon={VideoCallIcon}
                        alt="Meet"
                        onClick={() => this.onClickVideoCall()}
                      />
                      <ChatFileUpload
                        style={{
                          marginRight: '5px',
                          width: '35px',
                          height: '35px',
                          background: 'transparent',
                        }}
                        startLoading={() => {
                          this.setState({
                            ...this.state,
                            loadingMessage: true,
                          });
                        }}
                        stopLoading={() => {
                          this.setState({
                            ...this.state,
                            loadingMessage: false,
                          });
                        }}
                        getDataUpload={this.getDataUpload}
                        idContext={`${this.state?.activeProject?.projectId}/${this.props?.client?.clientId}`}
                      />
                      <TextInput
                        className="text-input"
                        placeholder={I18n.get('Escreva uma mensagem...')}
                        maxRows={4}
                        minRows={1}
                      />
                      <SendButton
                        className="text-send-button"
                        fit
                        onClick={(e) => e.preventDefault()}
                      />
                    </Row>
                  </TextComposer>
                </TextComposerContainer>
              </ConversationContainer>
            </FlexRowDiv>
          </ThemeProvider>
        </ModalBackground>
      </SimpleModal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
