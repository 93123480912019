import React from 'react';
import { I18n } from 'aws-amplify';
import SubmitButtonField from '../form/submit-button-field';
import { NavLink, Redirect } from 'react-router-dom';
import moment from 'moment';

export default class NotificationList extends React.Component {
  constructor(props) {
    super();
    this.state = { step: 0, range: 10 }; // UPDATE RANGE TO CHANGE PAGE BEHAVIOR
    this.mark = this.onMark.bind(this);
  }

  onMark() {
    var notSeen = this.props.chat.notifications.filter((x) => x.seen === 0);
    var ids = [];
    for (var i = 0; i < notSeen.length; i++) {
      ids.push(notSeen[i].messageID);
    }
    this.props.reset_notification();
    this.props.notification_mark_all_seen();
  }

  loadMore() {
    this.setState((state) => ({ step: state.step + 1 }));
  }

  navigateToNotification(notification) {
    if (!notification.seen) {
      // this.props.reset_notification();
      // this.props.mark_notification(this.props.auth.username, [
      //   notification.messageID,
      // ]);
    }

    this.props.history.push(`${notification.url}`);
  }

  render() {
    const msgs =
      (this.props.chat &&
        this.props.chat.notifications &&
        this.props.chat.notifications.length > 0 &&
        this.props.chat.notifications) ||
      [];

    const msgsNotSeen = msgs.filter((x) => x.seen === 0);

    const hasMsgs = msgs && msgs.length > 0;

    const sortedMsgs =
      hasMsgs &&
      msgs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const rangeMin = 0; // this.state.range * this.state.step;
    const rangeMax = this.state.range * this.state.step + this.state.range;
    const splittedMsgs =
      hasMsgs &&
      sortedMsgs.filter((msg, index) => index >= rangeMin && index < rangeMax);
    const hasMsgsLeft = hasMsgs && msgs.length > rangeMax;

    return (
      <div>
        <SubmitButtonField
          label={I18n.get('Marcar todas como lida')}
          onClick={() => this.mark()}
          task={this.state.task}
          disabled={msgsNotSeen.length === 0}
        />
        <br />
        <div className="card">
          <div className="card-content">
            <table className="table-bpool">
              <tbody>
                {!hasMsgs && (
                  <>
                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        {I18n.get('Sem novas mensagens...')}
                      </td>
                    </tr>
                  </>
                )}
                {hasMsgs &&
                  splittedMsgs.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          className={item.seen === 0 ? 'is-new' : ''}
                          style={{ textAlign: 'left' }}
                        >
                          {item.url && (
                            <button
                              className="item-list-notification"
                              onClick={() => this.navigateToNotification(item)}
                            >
                              {item.seen === 0 && (
                                <>
                                  <span className="notificationItemTopicNotSeen">
                                    <span className="button bt-bpool black mini">
                                      {I18n.get('NOVO')}
                                    </span>
                                    {item.message}
                                  </span>
                                  <p className="notificationItemTopicDate">
                                    {moment(item.createdAt).fromNow()}
                                  </p>
                                </>
                              )}

                              {item.seen === 1 && (
                                <>
                                  <span className="notificationItemTopicSeen">
                                    {item.message}
                                  </span>
                                  <p className="notificationItemTopicDate">
                                    {moment(item.createdAt).fromNow()}
                                  </p>
                                </>
                              )}
                            </button>
                          )}

                          {!item.url && (
                            <>
                              {item.seen === 0 && (
                                <>
                                  <span className="notificationItemTopicNotSeen">
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    {item.message}
                                  </span>
                                  <p className="notificationItemTopicDate">
                                    {moment(item.createdAt).fromNow()}
                                  </p>
                                </>
                              )}

                              {item.seen === 1 && (
                                <>
                                  <span className="notificationItemTopicSeen">
                                    {item.message}
                                  </span>
                                  <p className="notificationItemTopicDate">
                                    {moment(item.createdAt).fromNow()}
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {hasMsgsLeft && (
              <div className="notificationPagination">
                <button
                  type="button"
                  className="button bt-bpool"
                  onClick={this.loadMore.bind(this)}
                >
                  {I18n.get('Carregar mais')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
