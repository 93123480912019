import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";
import { notification } from "antd";

import Config from "../../config";

import { BP_LOGIN_CLIENTS_USERS_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const api_clone_user_client = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .put(`${Config.API_BASE_URL}/bp/logins/cloneuserclient`, data, config)
          .then((response) => {
            dispatch({
              type: BP_LOGIN_CLIENTS_USERS_GET,
              payload: {
                loginClientsUsers: response.data,
              },
            });
          })
          .then(() => {
            notification["success"]({
              message: "Clonado com sucesso!",
              description: "",
            });

            return true;
          })
          .catch((error) => {
            notification["error"]({
              message: "Ops! Algo deu errado.",
              description: "",
            });

            return false;
          });
      })
      .catch((error) => {
        dispatch({ type: EXPIRED });
      });
  };
};

export default api_clone_user_client;
