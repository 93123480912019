import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperSimple";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";

import { REFERENCE_API } from "../../../actions/reference/actions";

import FormReference from "../components/formReference";

import { TitleBorder, TitleMiddleLine } from "../../components/Titles";

import styled from "styled-components";

import FormSkeleton from "../../components/FormSkeleton";

import { QueryString } from "../../../utils/querystring";

import { Row, Col, Input, Select, Button, Form } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: 60px;
  }
`;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (data) => {
      dispatch(REFERENCE_API.update_person(data));
    },
    update: (data) => {
      dispatch(REFERENCE_API.update(data));
    },
    get_reference: (token) => {
      dispatch(REFERENCE_API.get(token));
    },
  };
};

class ReferenceAbout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: null,
      verificationToken: props.match.params.verificationToken,
    };
  }

  componentWillMount = () => {
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    this.setState({
      locale: locale,
    });

    I18n.setLanguage(locale);

    let x = { locale: locale, verificationToken: this.state.verificationToken };

    this.props.get_reference(x);
  };

  render() {
    if (this.props.reference && this.props.reference.submittedOn) {
      return <Redirect to="/referencia/cadastro/sucesso" />;
    }

    return (
      <AppWrapperSimple>
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Referência - Sobre você")}</TitleBorder>
                <p className="descriptionPage">
                  O{" "}
                  {this.props.reference.partnerName &&
                    this.props.reference.partnerName}{" "}
                  {I18n.get(
                    "indicou você como alguém que pode avaliar o seu perfil em nosso pool. As informações são confidênciais, não serão compartilhadas com o profissional avaliado e servirão apenas para nosso algoritimo buscar projetos com esse perfil."
                  )}
                </p>
              </Col>
            </Row>

            <FormReference
              reference={this.props.reference && this.props.reference}
              action={this.props.update}
              errors={
                this.props.reference.errors && this.props.reference.errors
              }
              isReference={true}
              countryCodes={this.props.bp && this.props.bp.countryCodes}
              locale={this.state.locale}
            />
          </Main>
        </ContainerPage>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceAbout);
