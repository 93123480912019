import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBriefings } from "../../../../hooks/useBriefings/useBriefings.hook";
import { Row, Col, notification, Icon, Popconfirm, Upload, Spin } from "antd";
import Main from "../../../../components/UI/Main";
import { BoxUpload } from "../../../../components/UI/BoxUpload";
import ListFiles from "./components/ListFiles";
import { RichEditor } from "../../../../components/UI/RichEditor";
import { DatePicker } from "../../../../components/UI/DatePicker";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";

import Download from "./download.svg";
import IcoAttachment from "./ico-attachment.svg";
import * as S from "./styles";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export const Briefing = ({ urlNextStep }) => {
  let params = useParams();
  let history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { getBriefing, uploadFile, updateBriefing, deleteFile } =
    useBriefings();
  const [isLoading, setIsLoading] = useState(false);
  const [briefings, setBriefings] = useState({});
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [Error, setError] = useState(null);
  const [filesIdsToSend, setFilesIdsToSend] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [errorInDate, setErrorInDate] = useState("");
  const [topicsProject, setTopicsProject] = useState({});
  const [partnerDeliveryEstimated, setPartnerDeliveryEstimated] =
    useState(null);
  const [infosPageDefault, setInfosPageDefault] = useState({
    content: "",
    text: "",
    partnerDeliveryEstimated: "",
  });

  const loadBriefing = async () => {
    setIsLoading(true);
    try {
      const response = await getBriefing({ id: params?.projectId });
      if (response?.success) {
        setBriefings(response?.data);

        if (response?.data?.briefing) {
          setTopicsProject({
            text: response?.data?.briefing?.content,
            plaintext: response?.data?.briefing?.plaintext,
          });

          setInfosPageDefault({
            content: response?.data?.briefing?.content,
            text: response?.data?.briefing?.plaintext,
            partnerDeliveryEstimated: response?.data?.partnerDeliveryEstimated,
          });
        }

        var dateGet = moment(
          response?.data?.partnerDeliveryEstimated,
          "DD/MM/YYYY"
        );
        let customDate = dateGet.format("YYYY-MM-DD");
        const dateFormatted = moment(customDate, "YYYY-MM-DD");
        setPartnerDeliveryEstimated(
          response?.data?.partnerDeliveryEstimated ? dateFormatted : null
        );

        setLoadingFiles(false);
      } else {
        // setError("Error.");
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const relooadBriefingToImages = async () => {
    setLoadingFiles(true);
    const response = await getBriefing({ id: params?.projectId });
    if (response?.success) {
      setBriefings({ ...briefings, files: response?.data?.files });
    } else {
      // setError("Error.");
    }
    setLoadingFiles(false);
  };

  const uploadFileBriefing = async (file) => {
    setLoadingFiles(true);
    const data = {
      projectId: params?.projectId,
      name: file?.name,
      url: file?.url,
      type: file?.type,
      size: file?.size,
      uploadedDate: file?.uploadedDate,
    };
    try {
      const response = await uploadFile(data);
      await relooadBriefingToImages();
    } catch (error) {
      setLoadingFiles(false);
    }
  };

  const deleteFileBriefing = async (file) => {
    setLoadingFiles(true);
    const info = {
      projectId: params?.projectId,
      id: file,
    };

    try {
      const response = await deleteFile(info);
      await relooadBriefingToImages();
    } catch (error) {}
    setLoadingFiles(false);
  };

  const verifyIfChanged = () => {
    if (infosPageDefault?.text !== topicsProject?.plaintext) {
      return true;
    } else if (typeof partnerDeliveryEstimated === "object") {
      if (
        infosPageDefault?.partnerDeliveryEstimated !==
        moment(partnerDeliveryEstimated).format("DD/MM/YYYY")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      infosPageDefault?.partnerDeliveryEstimated !== partnerDeliveryEstimated
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSendApi = async () => {
    const modified = verifyIfChanged();

    if (!modified) {
      history.push(urlNextStep);
    } else if (!briefings?.partnerHasSubmittedDefinitions) {
      if (partnerDeliveryEstimated) {
        setIsSending(true);
        const data = {
          projectId: params?.projectId,
          partnerDeliveryEstimated: partnerDeliveryEstimated,
          briefing: {
            text: topicsProject?.text || "",
            plaintext: topicsProject?.plaintext || "",
            filesIds: filesIdsToSend,
          },
        };

        await updateBriefing(data).then((response) => {
          if (response.success) {
            history.push(urlNextStep);
          } else {
            setError(response.errors?.briefings?.errorMessage || null);

            if (response.errors?.EstimatedDate) {
              setErrorInDate(response.errors.EstimatedDate.errorMessage);
              setIsSending(false);
            }
          }
        });

        setIsSending(false);
      } else {
        setErrorInDate(I18n.get("Please, select the end of the project."));
      }
    } else {
      history.push(urlNextStep);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadBriefing();
  }, []);

  useEffect(() => {
    const filesIds = briefings?.files?.map((item) => item?.id);
    setFilesIdsToSend(filesIds);
  }, [briefings]);

  const handleChangeText = ({ content, text }) => {
    setTopicsProject({
      text: text,
      plaintext: content,
    });
  };

  const onChangeDate = (date, dateString) => {
    setPartnerDeliveryEstimated(date);
  };

  return (
    <Main bgColor="#fff" padding="30px">
      <Row>
        <Col xs={24} sm={12} offset={6}>
          <S.RowCenter>
            <S.TitleWithIcon>
              <S.H4>{I18n.get("Briefing")}</S.H4>
            </S.TitleWithIcon>
            <S.Paragraph>
              {!briefings?.partnerHasSubmittedDefinitions &&
                I18n.get("Upload de documentos")}
            </S.Paragraph>
          </S.RowCenter>
        </Col>
      </Row>

      {briefings?.partnerHasSubmittedDefinitions && (
        <Row>
          <Col xs={24}>
            <S.CloseScope>
              <p>
                {I18n.get(
                  "Escopo fechado ou em análise, briefing não pode ser alterado."
                )}
              </p>
            </S.CloseScope>
          </Col>
        </Row>
      )}

      <Row>
        <Col sm={24}>
          <S.ContentAttachments>
            <S.BoxSelectAttachment
              disabledButton={
                briefings?.partnerHasSubmittedDefinitions ? true : false
              }
            >
              <Upload
                fileList={false}
                onChange={(e) => uploadFileBriefing(e.file)}
                disabled={
                  briefings?.partnerHasSubmittedDefinitions ? true : false
                }
              >
                <S.SelectAttachment
                  disabledButton={
                    briefings?.partnerHasSubmittedDefinitions ? true : false
                  }
                >
                  <img src={IcoAttachment} alt="Attachment File" />
                </S.SelectAttachment>
              </Upload>
            </S.BoxSelectAttachment>
            <S.AttachmentList>
              {loadingFiles && (
                <S.Loading>
                  <Spin indicator={antIcon} />
                </S.Loading>
              )}
              {briefings?.files?.map((file) => {
                if (file.type != "audio/mp3") {
                  return (
                    <S.AttachmentSelected
                      key={file?.id}
                      disabledButton={
                        briefings?.partnerHasSubmittedDefinitions ? true : false
                      }
                    >
                      <a href={file?.url}>{file?.name}</a>

                      <Popconfirm
                        title={I18n.get(
                          "Você realmente deseja excluir este arquivo?"
                        )}
                        placement="left"
                        disabled={
                          briefings?.partnerHasSubmittedDefinitions
                            ? true
                            : false
                        }
                        icon={
                          <Icon
                            type="question-circle-o"
                            style={{ color: "red" }}
                          />
                        }
                        onConfirm={() => deleteFileBriefing(file.id)}
                        okText={I18n.get("Sim")}
                        cancelText={I18n.get("Não")}
                      >
                        <a className="icon-delete">
                          <Icon type="delete" theme="filled" />
                        </a>
                      </Popconfirm>
                    </S.AttachmentSelected>
                  );
                }
              })}
            </S.AttachmentList>
          </S.ContentAttachments>
        </Col>
      </Row>

      <S.RowMain>
        <Row>
          <Col sm={24}>
            {isLoading ? (
              <Loading
                text={I18n.get("Loading...")}
                sizeIcon={20}
                sizeText={14}
                align="left"
              />
            ) : (
              <S.TextEdit>
                <RichEditor
                  title={I18n.get(
                    "Describe below the topics you will deliver in the project"
                  )}
                  defaultValue={
                    briefings?.briefing?.content ||
                    briefings?.briefing?.plaintext ||
                    ""
                  }
                  disabled={
                    briefings?.partnerHasSubmittedDefinitions ? true : false
                  }
                  onChange={handleChangeText}
                />
              </S.TextEdit>
            )}
          </Col>
        </Row>
      </S.RowMain>

      <S.RowMain>
        <Row>
          <Col sm={24}>
            <S.TitleDates>
              <p>
                {I18n.get(
                  "Now the beginning of the project and the end/delivery of it."
                )}
              </p>
            </S.TitleDates>
          </Col>
        </Row>
        {isLoading ? (
          <Row>
            <Col sm={24}>
              <Loading
                text={I18n.get("Loading...")}
                sizeIcon={20}
                sizeText={14}
                align="left"
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={24}>
              <S.TitleStartDate>
                <p>
                  {I18n.get("The Project will begin on the date of:")}
                  <span>{briefings?.estimatedStartDate || "---"}</span>
                </p>
              </S.TitleStartDate>
            </Col>
            <Col sm={8}>
              {isLoading ? (
                <div>loading</div>
              ) : (
                <DatePicker
                  defaultValue={partnerDeliveryEstimated}
                  format={dateFormatList}
                  onChange={onChangeDate}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  placeholder={
                    briefings?.partnerHasSubmittedDefinitions
                      ? briefings?.partnerDeliveryEstimated
                      : "__/__/____"
                  }
                  label={I18n.get("Select the end of the project")}
                  required
                  error={errorInDate}
                  clearError={() => {
                    setErrorInDate("");
                  }}
                  disabledInput={
                    briefings?.partnerHasSubmittedDefinitions ? true : false
                  }
                />
              )}
            </Col>
          </Row>
        )}
      </S.RowMain>

      <S.DisplayErrors>
        <Row>
          <Col lg={24}>
            {Error && (
              <>
                <h3>
                  {I18n.get("Ops... Alguns campos precisam ser informados.")}
                </h3>
                <br />
                <p>
                  {" "}
                  <span class="icon has-text-danger">
                    <i class="fas fa-exclamation"></i>
                  </span>
                  {Error}
                </p>
              </>
            )}
          </Col>
        </Row>
      </S.DisplayErrors>

      <S.RowFooter>
        <S.ButtonsFooter>
          <a href={briefings?.pdfBriefing} target="_blank">
            <ButtonBpool
              text={I18n.get("Download")}
              className=""
              icon={Download}
              theme="secondary"
              disabled={briefings?.pdfBriefing ? false : true}
            />
          </a>
          <ButtonBpool
            text={
              !briefings?.partnerHasSubmittedDefinitions
                ? I18n.get("Save and Next")
                : I18n.get("Next")
            }
            theme="primary"
            onClick={handleSendApi}
            loading={isSending}
          />
        </S.ButtonsFooter>
      </S.RowFooter>
    </Main>
  );
};
