import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import FileUploadList from '../../../modules/components/FileUploadList';

import { scrollToTop } from '../../../utils/ui';

class CustomNDAForm extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            fields: {},
            errors: {},
            form: {},
            ndaFiles: [],
            task: {},
            files: [],
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    // getData() {

    //     var data = {
    //         projectId: this.props.match.params.projectId,
    //         ...this.props.bPitch,
    //         ...this.state.form
    //     };

    //     return data;
    // }

    // onSave() {

    //     var submit_task = new Task();

    //     submit_task.start();

    //     this.setState({ submit_task: submit_task });

    //     this.props.update_bpitch(this.getData())
    //         .then(({ task = {}, errors = {} }) => {

    //             this.setState({ submit_task: task, errors: errors });
    //         });
    // }

    getDataUploadMult = (files) => {
        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });


        var data = {
            ...this.props.project,
            ndaFile: files[0]
        };

        this.props.save(data)
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    onDelFile(item, e) {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        const data = {
            ...this.props.project,
            ndaFile: null
        };

        this.props.save(data)
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });

    }

    render() {

        const { ndaFile } = this.props;

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("NDA Próprio")}</h1>

                <p>
                    {I18n.get("O modulo BPitch já possui um NDA padrão de aceite obrigatório pelos participantes.")}
                </p>

                <br />

                <p>
                    {I18n.get("Utilize essa seção somente se a sua empresa requer que os participantes do BPitch assinem o modelo de NDA próprio. Somente nesse caso, faça upload do documento.")}
                </p>

                <div className="bp-event-section" style={{ marginTop: '3rem', maxWidth: '40rem' }}>

                    <br /><br />

                    {!ndaFile && <>

                        <FileUploadList
                            getDataUpload={this.getDataUploadMult}
                            name="files"
                            uploadId={this.props.project.projectId}
                            get2={this.props.get2 || false}
                            fileListGet={this.state.ndaFiles || []}
                            direct={true}
                        />

                    </>}

                    {ndaFile && <>

                        <div className="columns" style={{ marginTop: '1rem', borderBottom: 'solid 1px #eeeeee' }}>

                            <div className="column is-10">

                                <div className="field">
                                    <label className="label is-small">{I18n.get("Arquivo")}</label>
                                    <div className="control">
                                        <a href={ndaFile.url} target='_blank'>{ndaFile.name}</a>
                                    </div>
                                </div>

                            </div>

                            <div className="column is-2 has-text-right">
                                <div className="field is-narrow">
                                    <div className="control">
                                        <a onClick={this.onDelFile.bind(this, ndaFile)} className='button is-rounded is-black is-outlined'>
                                            <span className="icon is-small">
                                                <i className="fa fa-times"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </>}


                    <br /><br />

                </div>
            </div>
        );
    }
}

export default CustomNDAForm;
