import React from "react";
import { I18n } from "aws-amplify";
import { Redirect } from "react-router-dom";

import ProjectContainer from "../../containers/project-container";

import { ClientProjectStatus } from "../../model";

import FillForm from "../page-templates/fillForm-logged";

import PartnerInfos from "../../modules/components/PartnerInfos";
import HeaderThumbCover from "../../modules/components/HeaderThumbCover";
import ContainerPage from "../../modules/components/ContainerPage";
import { scrollToTop } from "../../utils/ui";

import PartnersForm from "./partners-form";

import styled from "styled-components";

import Steps from "./steps";

import { Button } from "antd";

const Main = styled.div`
  padding-bottom: 100px;
  max-width: 1000px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  color: #353535;
`;

class SelectPartnerStep1 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      task: {},
      partnerSelect: null,
    };
  }

  componentDidMount() {
    const data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_partners(data);
  }

  getPartnerSelect = (item) => {
    this.setState({
      partnerSelect: item,
    });

    scrollToTop();
  };

  closePartner = () => {
    this.setState({
      partnerSelect: null,
    });
  };

  submitForm(e) {}

  render() {
    if (
      this.props.project &&
      this.props.project.status >= ClientProjectStatus.PartnerSelected
    ) {
      return (
        <Redirect
          to={`/cliente/projetos/${this.props.match.params.projectId}/parceiro-selecionado`}
        />
      );
    }

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-7">
                <p className="title">
                  {I18n.get("Parceiro Aprovado")}
                  <br />
                  <span className="tag is-warning tag-project-name">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-5">
                <Steps
                  active={2}
                  projectId={this.props.match.params.projectId}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.partnerSelect && (
          <div style={{ marginTop: "53px" }}>
            <HeaderThumbCover
              thumb={
                this.state.partnerSelect.company &&
                this.state.partnerSelect.company.logoImage
              }
              bgHeader={
                this.state.partnerSelect.company &&
                this.state.partnerSelect.company.headerImage
              }
              title=""
              // link={`/cliente/projetos/${this.props.match.params.projectId}/parceiro-passo-1`}
              linkText={I18n.get("Voltar")}
              action={this.closePartner}
              disabledSubheader={true}
            />

            <ContainerPage>
              <Main>
                <PartnerInfos
                  infos={this.state.partnerSelect && this.state.partnerSelect}
                  specialismsBP={
                    this.props.bp.specialisms && this.props.bp.specialisms
                  }
                  sectorsFlat={
                    this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                  }
                  isPresentation={true}
                  diversity={this.props.bp.diversity && this.props.bp.diversity}
                />
              </Main>
            </ContainerPage>

            <p className="btn-voltar-preview">
              <Button
                className="button bt-bpool black"
                onClick={() => this.setState({ partnerSelect: null })}
              >
                {I18n.get("Voltar")}
              </Button>
            </p>
          </div>
        )}

        {!this.state.partnerSelect && (
          <div className="client-step client-step-1">
            <div className="container client-container-1">
              <PartnersForm
                {...this.props}
                getPartner={this.getPartnerSelect}
              />
              <br />
              <br />
            </div>
          </div>
        )}
      </FillForm>
    );
  }
}

export default ProjectContainer(SelectPartnerStep1);
