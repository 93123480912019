import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import { TitleMiddleLine } from "../../components/Titles";
import { Link } from "react-router-dom";
import UploadFile from "../../components/FileUpload";
import FileUploadList from "../../components/FileUploadButton";
import { CardBorder } from "../../components/Cards";

import constants from "../../../constants";
import MaskedInput from "antd-mask-input";

import { v4 as uuidv4 } from "uuid";
import FormSkeleton from "../../components/FormSkeleton";
import axios from "axios";

import { TextCounter } from "./counterText";

import {
  Row,
  Col,
  Button,
  Radio,
  Tooltip,
  Switch,
  Checkbox,
  notification,
  Icon,
  Input,
  Select,
  Form,
} from "antd";

const { TextArea, Search } = Input;
const { Option } = Select;

class FormCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posters: [],
      logo: "",
      copy: false,
      fieldBlur: "",
      loadingFiles: false,
      isMultinational: null,
      isNational: null,
      isPublic: null,
      isPrivate: null,
      countrySeleted: "",
      commercialCountrySeleted: "",
    };
  }

  sendDataApi = () => {
    const fieldsForm = this.props.form.getFieldsValue();
    const dataForm = {
      ...this.props.client.company,
      ...fieldsForm,
      clientId: this.props.clientId,

      copyAddressToCommercialAddress:
        fieldsForm.copyAddressToCommercialAddress ||
        this.props.client.copyAddressToCommercialAddress,
      employeeRange:
        fieldsForm.employeeRange || this.props.client.employeeRange,
      evaluatePartner:
        fieldsForm.evaluatePartner || this.props.client.evaluatePartner,
      linkedin: fieldsForm.linkedin || this.props.client.linkedin,
      name: fieldsForm.name || this.props.client.name,
      paymentDefaultTermDays:
        fieldsForm.paymentDefaultTermDays ||
        this.props.client.paymentDefaultTermDays,
      phoneNumber: fieldsForm.phoneNumber || this.props.client.phoneNumber,
      postcode: fieldsForm.postcode || this.props.client.postcode,
      registrationId:
        fieldsForm.registrationId || this.props.client.registrationId,
      registrationName:
        fieldsForm.registrationName || this.props.client.registrationName,
      revenueRange: fieldsForm.revenueRange || this.props.client.revenueRange,
      website: fieldsForm.website || this.props.client.website,

      photos: this.state.logo ? [this.state.logo] : null,
      posters: this.state.posters.length > 0 ? this.state.posters : null,
      isSubmit: false,

      addressLine1:
        this.state.addressLine1 ||
        fieldsForm.addressLine1 ||
        this.props.client.addressLine1,
      addressLine2:
        this.state.addressLine2 ||
        fieldsForm.addressLine2 ||
        this.props.client.addressLine2,
      country: this.state.countrySeleted || this.props.client.country,
      city: this.state.city || fieldsForm.city || this.props.client.city,
      state: this.state.state || fieldsForm.state || this.props.client.state,

      commercialAddressLine1: fieldsForm.copyAddressToCommercialAddress
        ? fieldsForm.addressLine1
        : this.state.commercialAddressLine1 ||
          fieldsForm.commercialAddressLine1,
      commercialAddressLine2: fieldsForm.copyAddressToCommercialAddress
        ? fieldsForm.addressLine2
        : this.state.commercialAddressLine2 ||
          fieldsForm.commercialAddressLine2,

      commercialCity: fieldsForm.copyAddressToCommercialAddress
        ? fieldsForm.city
        : this.state.commercialCity || fieldsForm.commercialCity,
      commercialState: fieldsForm.copyAddressToCommercialAddress
        ? fieldsForm.state
        : this.state.commercialState || fieldsForm.commercialState,

      commercialCountry: fieldsForm.copyAddressToCommercialAddress
        ? this.state.countrySeleted
        : this.state.commercialCountrySeleted,

      isMultinational: this.state.isMultinational,
      isNational: this.state.isNational,
      isPublic: this.state.isPublic,
      isPrivate: this.state.isPrivate,
    };

    return dataForm;
  };

  componentDidMount = () => {
    this.setState({
      loadingFiles: true,
    });
  };

  componentWillMount = () => {
    this.setState({
      loadingFiles: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.client &&
      this.props.client.company != prevProps.client.company
    ) {
      this.setState({
        logo: this.props.client.company.logo,
        posters: this.props.client.company.posters
          ? [this.props.client.company.posters[0]]
          : [],
        isMultinational: this.props.client.company.isMultinational,
        isNational: this.props.client.company.isNational,
        isPublic: this.props.client.company.isPublic,
        isPrivate: this.props.client.company.isPrivate,
        countrySeleted: this.props.client.company.country,
        commercialCountrySeleted: this.props.client.company.commercialCountry,
      });
    }

    if (
      this.props.client &&
      this.props.client.company &&
      this.state.loadingFiles != prevState.loadingFiles
    ) {
      this.setState({
        logo: this.props.client.company.logo,
        posters: this.props.client.company.posters
          ? [this.props.client.company.posters[0]]
          : [],
        isMultinational: this.props.client.company.isMultinational,
        isNational: this.props.client.company.isNational,
        isPublic: this.props.client.company.isPublic,
        isPrivate: this.props.client.company.isPrivate,
        countrySeleted: this.props.client.company.country,
        commercialCountrySeleted: this.props.client.company.commercialCountry,
      });
    }

    if (
      this.props.errors != prevProps.errors &&
      Object.keys(this.props.errors).length > 0 &&
      this.props.errors[this.state.fieldBlur]
    ) {
      this.props.form.setFields({
        [this.state.fieldBlur]: {
          errors: [
            new Error(this.props.errors[this.state.fieldBlur].errorMessage),
          ],
        },
      });
    }
  }

  onChangeAdress = (field) => {
    setTimeout(() => {
      this.handleSubmit(field);
    }, 10);
  };

  handleSubmit = (field) => {
    this.setState({
      fieldBlur: field,
    });

    setTimeout(() => {
      this.props.action(this.sendDataApi());
    }, 50);
  };

  handleSubmitSave = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.action(this.sendDataApi());
      }
    });
  };

  onPostcodeSearchClick = (e, field) => {
    let value = e.target.value;

    if (value && value.length > 0) {
      const pattern = /^[0-9]{5}-[0-9]{3}$/;

      const code = value.replace("-", "");

      return axios
        .get(`https://viacep.com.br/ws/${code}/json/`)
        .then((response) => {
          if (field == "postcode") {
            this.props.form.setFieldsValue({
              addressLine1: response.data.logradouro,
            });
            this.props.form.setFieldsValue({ city: response.data.localidade });
            this.props.form.setFieldsValue({ state: response.data.uf });

            this.setState({
              addressLine1: response.data.logradouro,
              city: response.data.localidade,
              state: response.data.uf,
            });

            setTimeout(() => {
              this.handleSubmit("postcode");
            }, 100);
          } else {
            this.props.form.setFieldsValue({
              commercialAddressLine1: response.data.logradouro,
            });
            this.props.form.setFieldsValue({
              commercialCity: response.data.localidade,
            });
            this.props.form.setFieldsValue({
              commercialState: response.data.uf,
            });

            this.setState({
              commercialAddressLine1: response.data.logradouro,
              commercialCity: response.data.localidade,
              commercialState: response.data.uf,
            });

            setTimeout(() => {
              this.handleSubmit("commercialPostcode");
            }, 100);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  getDataUpload = (dataForm) => {
    const { field, url } = dataForm;

    switch (field) {
      case "logo":
        this.setState({
          logo: url,
        });
        break;
      case "posters":
        this.setState({
          posters: [url],
        });
        break;

      default:
        break;
    }

    setTimeout(() => {
      this.handleSubmit();
    }, 100);
  };

  handleNacional = (e) => {
    if (e.target.value == "Nacional") {
      this.setState({
        isMultinational: false,
        isNational: true,
      });
    } else {
      this.setState({
        isMultinational: true,
        isNational: false,
      });
    }

    setTimeout(() => {
      this.handleSubmit();
    }, 100);
  };

  handlePublic = (e) => {
    if (e.target.value == "Public") {
      this.setState({
        isPublic: true,
        isPrivate: false,
      });
    } else {
      this.setState({
        isPublic: false,
        isPrivate: true,
      });
    }

    setTimeout(() => {
      this.handleSubmit();
    }, 100);
  };

  isPublicOrPrivate = (open, close) => {
    if (open === true) {
      return "Public";
    } else if (close === true) {
      return "Private";
    } else {
      return null;
    }
  };

  isNationalOrMult = (nacional, multinacional) => {
    if (multinacional === true) {
      return "Multinacional";
    } else if (nacional === true) {
      return "Nacional";
    } else {
      return null;
    }
  };

  handleCountry = (e) => {
    if (e) {
      let obj = this.props.countryCodes.find((i) => i.code == e);

      let country = {
        name: obj.name,
        code: obj.code,
        prefix: obj.prefix,
      };

      this.setState({
        prefix: obj.prefix,
        countrySeleted: country,
      });

      this.props.form.setFieldsValue({
        postcode: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        phoneNumber: "",
      });

      setTimeout(() => {
        this.handleSubmit("country");
      }, 100);
    }
  };

  handleCommercialCountry = (e) => {
    if (e) {
      let obj = this.props.countryCodes.find((i) => i.code == e);

      let country = {
        name: obj.name,
        code: obj.code,
        prefix: obj.prefix,
      };

      this.setState({
        prefix: obj.prefix,
        commercialCountrySeleted: country,
      });

      this.props.form.setFieldsValue({
        commercialPostcode: "",
        commercialAddressLine1: "",
        commercialAddressLine2: "",
        commercialCity: "",
        commercialState: "",
        commercialPhoneNumber: "",
      });

      setTimeout(() => {
        this.handleSubmit("commercialCountry");
      }, 100);
    }
  };

  getPrefix = (code) => {
    let prefixCode =
      this.props.countryCodes &&
      this.props.countryCodes.find((i) => i.code == code);

    if (prefixCode) {
      return prefixCode.prefix || "";
    } else {
      return "";
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    if (!this.props.client || !this.props.client) return <FormSkeleton />;

    const { client } = this.props;

    return (
      <Form onSubmit={this.handleSubmitSave}>
        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>{I18n.get("Dados da empresa")}</TitleMiddleLine>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Item label={I18n.get("Nome fantasia")}>
                {getFieldDecorator("name", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company && this.props.client.company.name
                      ? this.props.client.company.name
                      : null,
                })(<Input onBlur={() => this.handleSubmit("name")} />)}
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Razão Social")}>
                {getFieldDecorator("registrationName", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.registrationName
                      ? this.props.client.company.registrationName
                      : null,
                })(
                  <Input onBlur={() => this.handleSubmit("registrationName")} />
                )}
              </Form.Item>
            </Col>

            <Col sm={12}>
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("CNPJ")}>
                  {getFieldDecorator("registrationId", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.registrationId
                        ? this.props.client.company.registrationId
                        : null,
                  })(
                    <MaskedInput
                      mask="00.000.000/0000-00"
                      size="14"
                      onBlur={() => this.handleSubmit("registrationId")}
                    />
                  )}
                </Form.Item>
              )}
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("CNPJ")}>
                  {getFieldDecorator("registrationId", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.registrationId
                        ? this.props.client.company.registrationId
                        : null,
                  })(
                    <Input onBlur={() => this.handleSubmit("registrationId")} />
                  )}
                </Form.Item>
              )}
            </Col>
            <Col sm={12}>
              <Form.Item label={I18n.get("Site")}>
                {getFieldDecorator("website", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.website
                      ? this.props.client.company.website
                      : null,
                })(<Input onBlur={() => this.handleSubmit("website")} />)}
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Linkedin Corporativo")}>
                {getFieldDecorator("linkedin", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.linkedin
                      ? this.props.client.company.linkedin
                      : null,
                })(<Input onBlur={() => this.handleSubmit("linkedin")} />)}
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Sua empresa é")}>
                {getFieldDecorator("multinationalOrNacional", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.isNationalOrMult(
                      this.props.client.company.isNational,
                      this.props.client.company.isMultinational
                    ),
                })(
                  <Radio.Group onChange={(e) => this.handleNacional(e)}>
                    <Radio value="Multinacional">
                      {I18n.get("Multinacional")}
                    </Radio>
                    <Radio value="Nacional">{I18n.get("Nacional")}</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Sua empresa é")}>
                {getFieldDecorator("openOrClose", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.isPublicOrPrivate(
                      this.props.client.company.isPublic,
                      this.props.client.company.isPrivate
                    ),
                })(
                  <Radio.Group onChange={(e) => this.handlePublic(e)}>
                    <Radio value="Public">{I18n.get("Capital Aberto")}</Radio>
                    <Radio value="Private">{I18n.get("Capital Fechado")}</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
        </CardBorder>

        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>
                {I18n.get("Perfil da sua empresa")}
              </TitleMiddleLine>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Logo - 113 x 113 pixels")}>
                {getFieldDecorator("logo", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company && this.props.client.company.logo,
                })(
                  <UploadFile
                    name="logo"
                    nameField="logo"
                    getDataUpload={this.getDataUpload}
                    idContext={this.props.client.clientId}
                    imgUrl={
                      this.props.client &&
                      this.props.client.company &&
                      this.props.client.company.logo
                    }
                    get2={this.props.get2 ? true : false}
                  />
                )}

                <p className="helpText">
                  {I18n.get("Enviar somente uma imagem do tipo JPG ou PNG.")}
                </p>
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Imagem Capa - 1280 x 720 pixels")}>
                {getFieldDecorator("posters", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.posters,
                })(
                  <UploadFile
                    name="posters"
                    nameField="posters"
                    getDataUpload={this.getDataUpload}
                    idContext={this.props.client.clientId}
                    imgUrl={
                      this.props.client.company &&
                      this.props.client.company.posters
                        ? this.props.client.company.posters[0]
                        : null
                    }
                    cover={true}
                    coverDescription="Formato ideal: 1280px X 770px"
                    get2={this.props.get2 ? true : false}
                  />
                )}
                <p className="helpText">
                  {I18n.get("Enviar somente uma imagem do tipo JPG ou PNG.")}
                </p>
              </Form.Item>
            </Col>
          </Row>
        </CardBorder>

        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>{I18n.get("Endereço do CNPJ")}</TitleMiddleLine>
            </Col>
          </Row>
          <Row className="row-bpool">
            <Col sm={6}>
              <Form.Item label={I18n.get("País")}>
                {getFieldDecorator("country", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.country
                      ? this.props.client.company.country.code
                      : "",
                })(
                  <Select
                    style={{ width: "100%" }}
                    onBlur={(e) => this.handleCountry(e)}
                    showSearch={true}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {this.props.countryCodes &&
                      this.props.countryCodes.map((item) => {
                        let codeString = item.isO2.toLowerCase();
                        return (
                          <Option
                            value={item.code}
                            key={item.code}
                            prefix={item.prefix}
                          >
                            <img
                              src={`https://assets.bpool.co/country-flags/png24px/${codeString}.png`}
                            />{" "}
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={6}>
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("CEP")}>
                  {getFieldDecorator("postcode", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.postcode
                        ? this.props.client.company.postcode
                        : null,
                  })(<Input onBlur={() => this.handleSubmit("postcode")} />)}
                </Form.Item>
              )}
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("CEP")}>
                  {getFieldDecorator("postcode", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.postcode
                        ? this.props.client.company.postcode
                        : null,
                  })(
                    <MaskedInput
                      mask="00000-000"
                      onBlur={(e) => this.onPostcodeSearchClick(e, "postcode")}
                    />
                  )}
                </Form.Item>
              )}
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Endereço")}>
                {getFieldDecorator("addressLine1", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.addressLine1
                      ? this.props.client.company.addressLine1
                      : null,
                })(<Input onBlur={() => this.handleSubmit("addressLine1")} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row className="row-bpool">
            <Col sm={8}>
              <Form.Item label={I18n.get("Complemento")}>
                {getFieldDecorator("addressLine2", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.addressLine2
                      ? this.props.client.company.addressLine2
                      : null,
                })(<Input onBlur={() => this.handleSubmit("addressLine2")} />)}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label={I18n.get("Cidade")}>
                {getFieldDecorator("city", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company && this.props.client.company.city
                      ? this.props.client.company.city
                      : null,
                })(<Input onBlur={() => this.handleSubmit("city")} />)}
              </Form.Item>
            </Col>

            <Col sm={8}>
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("Estado")}>
                  {getFieldDecorator("state", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.state
                        ? this.props.client.company.state
                        : null,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      onChange={() => this.handleSubmit("state")}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {constants.STATES &&
                        constants.STATES.map((item) => {
                          return (
                            <Option value={item.code} key={item.code}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              )}
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("Estado")}>
                  {getFieldDecorator("state", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.state
                        ? this.props.client.company.state
                        : null,
                  })(<Input onBlur={() => this.handleSubmit("state")} />)}
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row className="row-bpool">
            <Col sm={8}>
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("Telefone")}>
                  {getFieldDecorator("phoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.phoneNumber
                        ? this.props.client.company.phoneNumber
                        : null,
                  })(
                    <Input
                      addonBefore={
                        this.props.client.company &&
                        this.props.client.company.country &&
                        this.props.client.company.country.prefix
                          ? this.props.client.company.country.prefix
                          : ""
                      }
                      onBlur={() => this.handleSubmit("phoneNumber")}
                    />
                  )}
                </Form.Item>
              )}
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("Telefone")}>
                  {getFieldDecorator("phoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.phoneNumber
                        ? this.props.client.company.phoneNumber
                        : null,
                  })(
                    <Input
                      addonBefore={
                        this.props.client.company &&
                        this.props.client.company.country &&
                        this.props.client.company.country.prefix
                          ? this.props.client.company.country.prefix
                          : ""
                      }
                      onBlur={() => this.handleSubmit("phoneNumber")}
                    />
                    // <MaskedInput  TODO: fazer mascara
                    //     addonBefore={this.props.client.company && this.props.client.company.country && this.props.client.company.country.prefix ? this.props.client.company.country.prefix : ""}
                    //     mask="(11) 1111-1111"
                    //     size="14"
                    //     onBlur={() => this.handleSubmit('phoneNumber')}
                    // />
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
        </CardBorder>

        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>
                {I18n.get("Endereço do comercial")}
              </TitleMiddleLine>
            </Col>
          </Row>

          <Row className="row-bpool">
            <Col sm={24}>
              <Form.Item label={I18n.get("O mesmo da empresa")}>
                {getFieldDecorator("copyAddressToCommercialAddress", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.copyAddressToCommercialAddress
                      ? true
                      : false,
                  valuePropName: "checked",
                })(
                  <Checkbox
                    onChange={(e) =>
                      this.onChangeAdress("copyAddressToCommercialAddress")
                    }
                  >
                    {I18n.get("Sim, é o mesmo endereço do CNPJ")}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="row-bpool">
            <Col sm={6}>
              <Form.Item label={I18n.get("País")}>
                {getFieldDecorator("commercialCountry", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.commercialCountry
                      ? this.props.client.company.commercialCountry.code
                      : "",
                })(
                  <Select
                    style={{ width: "100%" }}
                    onBlur={(e) => this.handleCommercialCountry(e)}
                    showSearch={true}
                    disabled={getFieldValue("copyAddressToCommercialAddress")}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {this.props.countryCodes &&
                      this.props.countryCodes.map((item) => {
                        let codeString = item.isO2.toLowerCase();
                        return (
                          <Option
                            value={item.code}
                            key={item.code}
                            prefix={item.prefix}
                          >
                            <img
                              src={`https://assets.bpool.co/country-flags/png24px/${codeString}.png`}
                            />{" "}
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={6}>
              {getFieldValue("commercialCountry") != "BRA" && (
                <Form.Item label={I18n.get("CEP")}>
                  {getFieldDecorator("commercialPostcode", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.commercialPostcode
                        ? this.props.client.company.commercialPostcode
                        : null,
                  })(
                    <Input
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                      onBlur={() => this.handleSubmit("commercialPostcode")}
                    />
                  )}
                </Form.Item>
              )}
              {getFieldValue("commercialCountry") == "BRA" && (
                <Form.Item label={I18n.get("CEP")}>
                  {getFieldDecorator("commercialPostcode", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.commercialPostcode
                        ? this.props.client.company.commercialPostcode
                        : null,
                  })(
                    <MaskedInput
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                      mask="00000-000"
                      size="8"
                      onBlur={(e) =>
                        this.onPostcodeSearchClick(e, "commercialPostcode")
                      }
                    />
                  )}
                </Form.Item>
              )}
            </Col>
            {/* <Col sm={4}>
                            <Form.Item className="labelClean" label="&nbsp;">
                                <Button
                                    type="primary"
                                    shape="round"
                                    disabled={getFieldValue('copyAddressToCommercialAddress')}
                                >Buscar</Button>
                            </Form.Item>
                        </Col> */}
            <Col sm={12}>
              <Form.Item label={I18n.get("Endereço")}>
                {getFieldDecorator("commercialAddressLine1", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.commercialAddressLine1
                      ? this.props.client.company.commercialAddressLine1
                      : null,
                })(
                  <Input
                    onBlur={() => this.handleSubmit("commercialAddressLine1")}
                    disabled={getFieldValue("copyAddressToCommercialAddress")}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="row-bpool">
            <Col sm={8}>
              <Form.Item label={I18n.get("Complemento")}>
                {getFieldDecorator("commercialAddressLine2", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.commercialAddressLine2
                      ? this.props.client.company.commercialAddressLine2
                      : null,
                })(
                  <Input
                    onBlur={() => this.handleSubmit("commercialAddressLine2")}
                    disabled={getFieldValue("copyAddressToCommercialAddress")}
                  />
                )}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label={I18n.get("Cidade")}>
                {getFieldDecorator("commercialCity", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.commercialCity
                      ? this.props.client.company.commercialCity
                      : null,
                })(
                  <Input
                    onBlur={() => this.handleSubmit("commercialCity")}
                    disabled={getFieldValue("copyAddressToCommercialAddress")}
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={8}>
              {getFieldValue("commercialCountry") == "BRA" && (
                <Form.Item label={I18n.get("Estado")}>
                  {getFieldDecorator("commercialState", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.commercialState
                        ? this.props.client.company.commercialState
                        : null,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      onChange={() => this.handleSubmit("commercialState")}
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {constants.STATES &&
                        constants.STATES.map((item) => {
                          return (
                            <Option value={item.code} key={item.code}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              )}

              {getFieldValue("commercialCountry") != "BRA" && (
                <Form.Item label={I18n.get("Estado")}>
                  {getFieldDecorator("commercialState", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.commercialState
                        ? this.props.client.company.commercialState
                        : null,
                  })(
                    <Input
                      onBlur={() => this.handleSubmit("commercialState")}
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                    />
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row className="row-bpool">
            <Col sm={8}>
              {getFieldValue("commercialCountry") != "BRA" && (
                <Form.Item label={I18n.get("Telefone")}>
                  {getFieldDecorator("commercialPhoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.commercialPhoneNumber
                        ? this.props.client.company.commercialPhoneNumber
                        : null,
                  })(
                    <Input
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                      addonBefore={
                        this.props.client.company &&
                        this.props.client.company.commercialCountry
                          ? this.props.client.company.commercialCountry.prefix
                          : null
                      }
                      onBlur={() => this.handleSubmit("commercialPhoneNumber")}
                    />
                  )}
                </Form.Item>
              )}
              {getFieldValue("commercialCountry") == "BRA" && (
                <Form.Item label={I18n.get("Telefone")}>
                  {getFieldDecorator("commercialPhoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.client.company &&
                      this.props.client.company.commercialPhoneNumber
                        ? this.props.client.company.commercialPhoneNumber
                        : null,
                  })(
                    <Input
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                      addonBefore={
                        this.props.client.company &&
                        this.props.client.company.commercialCountry
                          ? this.props.client.company.commercialCountry.prefix
                          : null
                      }
                      onBlur={() => this.handleSubmit("commercialPhoneNumber")}
                    />
                    // <MaskedInput TODO: fazer Mascara
                    //     disabled={getFieldValue('copyAddressToCommercialAddress')}
                    //     addonBefore={this.props.client.company && this.props.client.company.commercialCountry ? this.props.client.company.commercialCountry.prefix : null}
                    //     mask="(11) 1111-1111"
                    //     size="14"
                    //     onBlur={() => this.handleSubmit('commercialPhoneNumber')}
                    // />
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
        </CardBorder>

        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>{I18n.get("Alguns Números")}</TitleMiddleLine>
            </Col>
            <Col sm={8}>
              <Form.Item label={I18n.get("Faturamento anual")}>
                {getFieldDecorator("revenueRange", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.revenueRange
                      ? this.props.client.company.revenueRange
                      : null,
                })(
                  <Select
                    style={{ width: "100%" }}
                    onChange={() => this.handleSubmit("revenueRange")}
                    showSearch={true}
                  >
                    <Option value="" key=""></Option>
                    {this.props.revenueRanges &&
                      this.props.revenueRanges.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item label={I18n.get("Funcionários")}>
                {getFieldDecorator("employeeRange", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.employeeRange
                      ? this.props.client.company.employeeRange
                      : null,
                })(
                  <Select
                    style={{ width: "100%" }}
                    onChange={() => this.handleSubmit("employeeRange")}
                    showSearch={true}
                  >
                    <Option value="" key=""></Option>
                    {this.props.employeesRanges &&
                      this.props.employeesRanges.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item label={I18n.get("Prazo Padrão de Pagamento")}>
                {getFieldDecorator("paymentDefaultTermDays", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.client.company &&
                    this.props.client.company.paymentDefaultTermDays
                      ? this.props.client.company.paymentDefaultTermDays
                      : null,
                })(
                  <Select
                    style={{ width: "100%" }}
                    onChange={() => this.handleSubmit("paymentDefaultTermDays")}
                    showSearch={true}
                  >
                    <Option value="" key=""></Option>
                    {this.props.paymentDefaultTermDays &&
                      this.props.paymentDefaultTermDays.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </CardBorder>
        <CardBorder>
          <Row className="RowSection">
            <Col sm={24}>
              <TitleMiddleLine>
                {I18n.get("Avaliação do Parceiro e da Marca")}
              </TitleMiddleLine>
            </Col>
            <Col sm={24}>
              <p>
                {I18n.get(
                  "Os usuários de marketing na plataforma vão avaliar quinzenalmente o parceiro e a evolução do projeto (qualidade da entrega, cumprimento de cronograma, etc). Você gostaria que o time envolvido no projeto fosse avaliado pelo parceiro (qualidade de briefing e dos feedbacks, entre outros aspectos)?"
                )}
              </p>
              <br />
            </Col>
            <Col sm={24}>
              <Form.Item>
                {getFieldDecorator("evaluatePartner", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.isRegister ||
                    (this.props.client.company &&
                      this.props.client.company.evaluatePartner),
                  defaultChecked: true,
                  valuePropName: "checked",
                })(
                  <Switch
                    onChange={() => this.handleSubmit("evaluatePartner")}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </CardBorder>
      </Form>
    );
  }
}

const MainFormCompany = Form.create({})(FormCompany);

export default MainFormCompany;
