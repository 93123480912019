import React, { Component } from "react";
import { I18n } from 'aws-amplify';
import {
    Row,
    Col,
    Form,
    Icon,
    Spin,
    Input,
    Select,
    Button
} from 'antd';

import styled from 'styled-components';

const { Option } = Select;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`

class FilterProjectAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                this.props.action(values);
            }
        });
    };

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const { filters } = this.props

        // if (!filters) {
        //     return (
        //         <BoxSpin>
        //             <Spin />
        //         </BoxSpin>
        //     )
        // }


        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={3}>

                        <Form.Item
                            label={I18n.get("Status")}>
                            {getFieldDecorator('status', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: -1
                            })(
                                <Select
                                    className="SelectMin"
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {filters && filters.status.map((item, index) => {
                                        return <Option className="SelectMin" value={item.code} key={index}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={3}>

                        <Form.Item
                            label={I18n.get("Cliente")}>
                            {getFieldDecorator('clientId', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: "-1"
                            })(
                                <Select
                                    className="SelectMin"
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {filters && filters.clients.map((item, index) => {
                                        return <Option className="SelectMin" value={item.code} key={index}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>

                        </Col>

                        <Col sm={4}>

                        <Form.Item
                            label={I18n.get("Parceiro")}>
                            {getFieldDecorator('partnerId', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: "-1"
                            })(
                                <Select
                                    className="SelectMin"
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {filters && filters.partners.map((item, index) => {
                                        return <Option className="SelectMin" value={item.code} key={index}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>

                        </Col>

                        <Col sm={4}>

                        <Form.Item
                            label={I18n.get("Entregue pelo parceiro")}>
                            {getFieldDecorator('partnerConfirmed', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: null
                            })(
                                <Select
                                    className="SelectMin"
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    
                                    <Option className="SelectMin" value={null}>Selecione</Option>
                                    <Option className="SelectMin" value={false}>Não</Option>
                                    <Option className="SelectMin" value={true}>Sim</Option>
                                
                                </Select>
                            )}
                        </Form.Item>

                        </Col>
                        <Col sm={4}>

                        <Form.Item
                            label={I18n.get("Aprovado pelo cliente")}>
                            {getFieldDecorator('clientConfirmed', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: null
                            })(
                                <Select
                                    className="SelectMin"
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    <Option className="SelectMin" value={null}>Selecione</Option>
                                    <Option className="SelectMin" value={false}>Não</Option>
                                    <Option className="SelectMin" value={true}>Sim</Option>
                                
                                </Select>
                            )}
                        </Form.Item>

                        </Col>
                    <Col sm={4}>
                        <Form.Item
                            label={I18n.get("Projeto")}>
                            {getFieldDecorator('projectName', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: ""
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>

                    <Col sm={1}>
                        <Form.Item className="labelClean" label="&nbsp;">
                            <Button
                                type="primary"
                                shape="round"
                                htmlType="submit"
                                disabled={!filters}
                                loading={this.props.task.busy}
                            >
                                OK
                            </Button>
                        </Form.Item>
                    </Col>                    

                </Row>

                {/* <Row>

                    <Col sm={9}>

                    </Col>



                </Row> */}

            </Form>
        )
    }
}



const FormFilterProjectAdmin = Form.create({})(FilterProjectAdmin);

export default FormFilterProjectAdmin;

