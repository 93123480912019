import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col, message, Card, Collapse, Tag, Avatar } from "antd";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";
import { Input } from "../../../../components/UI/Input";
import { useRegistrationFlowLeader } from "../../../../hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook";
import PasswordChecklist from "react-password-checklist";

import { urlsLeader as urls } from "../../utils/stepsUrls";
import * as S from "./styles";

const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const PAGE_INDEX = 21;

const { Panel } = Collapse;

export const ContentPage = ({ onChangeInfo, contextPage, leaderId }) => {
  const { getAboutYou, getResume, finishLeader } = useRegistrationFlowLeader();
  const location = useLocation();
  let history = useHistory();
  const [token, setToken] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isLoadPage, setIsLoadPage] = useState(false);
  const [resume, setResume] = useState({});
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [Erros, setErros] = useState([]);
  const [inputsForm, setInputsForm] = useState({
    passWord: "",
    confirmPassWord: "",
  });
  const [controlPassword, setControlPassword] = useState({
    bothLowerUpper: false,
    numberAndSymbol: false,
    least8Char: false,
    complete: false,
    passwordsMatch: false,
  });
  const [isDisabledButtonSend, setIsDisabledButtonSend] = useState(true);

  const prepareToken = async (url) => {
    const arrayOfStrings = url.split("/");
    const tkn = arrayOfStrings[arrayOfStrings.length - 1];
    return tkn;
  };

  const send = async () => {
    setIsSending(true);

    const data = {
      verificationToken: token,
      passWord: inputsForm.passWord,
      confirmPassWord: inputsForm.confirmPassWord,
    };
    const response = await finishLeader(data);

    if (response.success) {
      history.push({
        pathname: "/registration-flow/leader/completed",
      });
    } else {
      setErros(response?.errors);
    }

    setIsSending(false);
  };

  const loadInit = async () => {
    setIsLoadPage(true);
    const tokenUrl = await prepareToken(location?.pathname);
    if (tokenUrl) {
      setToken(tokenUrl);

      const response = await getAboutYou(tokenUrl);

      if (response.success) {
        const responseInit = await getResume(tokenUrl);

        const data = {
          ...responseInit?.data,
        };

        setResume({ ...data });
      }
    }
    setIsLoadPage(false);
  };

  const renderInfo = ({ label, value }) => {
    return (
      <S.ItemInfo>
        <S.ItemLabel>{label}</S.ItemLabel>
        <S.ItemValue>{value}</S.ItemValue>
      </S.ItemInfo>
    );
  };

  const renderTypeName = (type) => {
    const types = {
      1: "Company",
      2: "Leadership",
    };

    return types[type];
  };

  const handleBackPage = () => {
    const url = `${urls[PAGE_INDEX - 1]}/${token}`;

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };
  const onChange = (key) => {
    return;
  };

  const onChangePasswordInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : "",
    });
  };

  const validPassword = () => {
    let min8chars = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    let min8charsBothLowerAndUpper =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    let min8charsAndNumberAndSymbol =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let complete =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (inputsForm.passWord.match(complete)) {
      setControlPassword({
        bothLowerUpper: true,
        numberAndSymbol: true,
        least8Char: true,
        complete: true,
        passwordsMatch: false,
      });
      return null;
    } else if (inputsForm.passWord.match(min8charsAndNumberAndSymbol)) {
      setControlPassword({
        bothLowerUpper: false,
        numberAndSymbol: true,
        least8Char: true,
        complete: false,
        passwordsMatch: false,
      });
      return null;
    } else if (inputsForm.passWord.match(min8charsBothLowerAndUpper)) {
      setControlPassword({
        bothLowerUpper: true,
        numberAndSymbol: false,
        least8Char: true,
        complete: false,
        passwordsMatch: false,
      });
      return null;
    } else if (inputsForm.passWord.match(min8chars)) {
      setControlPassword({
        bothLowerUpper: false,
        numberAndSymbol: false,
        least8Char: true,
        complete: false,
        passwordsMatch: false,
      });
      return null;
    } else {
      setControlPassword({
        bothLowerUpper: false,
        numberAndSymbol: false,
        least8Char: false,
        complete: false,
        passwordsMatch: false,
      });
      return null;
    }
  };

  useEffect(() => {
    validPassword();

    if (inputsForm.confirmPassWord) {
      if (inputsForm.passWord === inputsForm.confirmPassWord) {
        setControlPassword({ ...controlPassword, passwordsMatch: true });
      } else {
        setControlPassword({ ...controlPassword, passwordsMatch: false });
      }
    }
  }, [inputsForm.passWord, inputsForm.confirmPassWord]);

  useEffect(() => {
    if (passwordIsValid) {
      setIsDisabledButtonSend(false);
    } else {
      setIsDisabledButtonSend(true);
    }
  }, [passwordIsValid]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  const cases =
    (Object.keys(resume).length > 0 && [
      ...resume?.companyCases,
      ...resume?.leaderCases,
    ]) ||
    [];

  const awards =
    (Object.keys(resume).length > 0 && [
      ...resume?.companyAwards,
      ...resume?.leaderAwards,
    ]) ||
    [];

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("Resume")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  "Our algorithm wants to know more about you, who are in the lead. Remember that each information is valuable for him to find projects that have to do with your company."
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        <Row>
          <Col xs={24} style={{ marginTop: 30 }}>
            <S.TitleSectionForm>{I18n.get("Resume")}</S.TitleSectionForm>
          </Col>
        </Row>

        {isLoadPage ? (
          <Row>
            <Col xs={24}>
              <S.ContentIsLoading>
                <Loading
                  text={I18n.get("Loading informations...")}
                  sizeText={14}
                  sizeIcon={16}
                />
              </S.ContentIsLoading>
            </Col>
          </Row>
        ) : (
          <>
            <S.RowMain style={{ marginTop: 30 }}>
              <Col xs={24}>
                <Card title={I18n.get("Sobre você")}>
                  <Row gutter={6}>
                    <Col xs={6}>
                      {resume?.profilePicture && (
                        <Avatar size={124} src={resume.profilePicture} />
                      )}
                      {!resume?.profilePicture && (
                        <Avatar size={124} icon="user" />
                      )}
                    </Col>
                    <Col xs={9}>
                      {renderInfo({
                        label: I18n.get("Name"),
                        value: resume?.name,
                      })}
                    </Col>
                    <Col xs={9}>
                      {renderInfo({
                        label: I18n.get("How would you like to be called?"),
                        value: resume?.nameAlias,
                      })}
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={6}></Col>
                    <Col xs={9}>
                      {renderInfo({
                        label: I18n.get("Email"),
                        value: resume?.email,
                      })}
                    </Col>
                    <Col xs={9}>
                      {renderInfo({
                        label: I18n.get("linkedin"),
                        value: resume?.linkedin,
                      })}
                    </Col>
                  </Row>

                  <Row gutter={6}>
                    <Col xs={6}></Col>
                    <Col xs={9}>
                      {renderInfo({
                        label: I18n.get("Country"),
                        value: resume?.country?.name,
                      })}
                    </Col>
                    <Col xs={9}>
                      {renderInfo({
                        label: I18n.get("Phone number"),
                        value: resume?.phoneNumber,
                      })}
                    </Col>
                  </Row>

                  <Row gutter={6}>
                    <Col xs={24}>
                      {renderInfo({
                        label: I18n.get("Brief resume"),
                        value: resume?.resume,
                      })}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </S.RowMain>

            <S.RowMain style={{ marginTop: 30 }}>
              <Col xs={24}>
                <Card title={I18n.get("Mercado de Expertise")}>
                  <Row gutter={6}>
                    <Col xs={24}>
                      <S.List>
                        {resume?.industries &&
                          resume?.industries.map((item) => {
                            const childrens = item?.childrenLeader.map(
                              (i) => i.label
                            );
                            return (
                              <S.ListItem key={item?.code}>
                                <S.NameItem>{item?.label}</S.NameItem>
                                <S.ChildrensItem>
                                  {childrens.join("; ")}.
                                </S.ChildrensItem>
                              </S.ListItem>
                            );
                          })}
                      </S.List>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </S.RowMain>

            <S.RowMain style={{ marginTop: 30 }}>
              <Col xs={24}>
                <Card title={I18n.get("Cases  ")}>
                  <Row gutter={6}>
                    <Col xs={24}>
                      <Collapse onChange={onChange}>
                        {cases &&
                          cases.map((item, index) => {
                            return (
                              <Panel
                                key={item?.id}
                                header={
                                  <>
                                    {`Case ${index + 1} - ${item?.companyName}`}{" "}
                                    {item.participated && (
                                      <Tag style={{ marginLeft: "15px" }}>
                                        {I18n.get("Participated")}
                                      </Tag>
                                    )}
                                    {item.type == 2 ? (
                                      <Tag style={{ float: "right" }}>
                                        {I18n.get("My case")}
                                      </Tag>
                                    ) : (
                                      <Tag style={{ float: "right" }}>
                                        {I18n.get("Company case")}
                                      </Tag>
                                    )}
                                  </>
                                }
                              >
                                <S.ContentItemCase>
                                  {renderInfo({
                                    label: I18n.get("Company name"),
                                    value: item?.companyName,
                                  })}
                                  {renderInfo({
                                    label: I18n.get("Link"),
                                    value: item?.link,
                                  })}
                                  {renderInfo({
                                    label: I18n.get("Country"),
                                    value: item?.countryCode,
                                  })}
                                  {renderInfo({
                                    label: I18n.get("Brand"),
                                    value: item?.brand,
                                  })}
                                  {renderInfo({
                                    label: I18n.get("Date"),
                                    value: item?.year,
                                  })}
                                  {renderInfo({
                                    label: I18n.get("Case of"),
                                    value: renderTypeName(item?.type),
                                  })}
                                </S.ContentItemCase>
                              </Panel>
                            );
                          })}
                      </Collapse>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </S.RowMain>

            <S.RowMain style={{ marginTop: 30 }}>
              <Col xs={24}>
                <Card title={I18n.get("Prêmios  ")}>
                  <Row gutter={6}>
                    <Col xs={24}>
                      <Collapse onChange={onChange}>
                        {awards &&
                          awards.map((item, index) => {
                            return (
                              <Panel
                                key={item?.id}
                                header={
                                  <>
                                    {`Award ${index + 1} - ${item?.name}`}{" "}
                                    {item.participated && (
                                      <Tag style={{ marginLeft: "15px" }}>
                                        {I18n.get("Participated")}
                                      </Tag>
                                    )}
                                    {item.type == 2 ? (
                                      <Tag style={{ float: "right" }}>
                                        {I18n.get("My award")}
                                      </Tag>
                                    ) : (
                                      <Tag style={{ float: "right" }}>
                                        {I18n.get("Company award")}
                                      </Tag>
                                    )}
                                  </>
                                }
                              >
                                <S.ContentItemCase>
                                  {renderInfo({
                                    label: I18n.get("Name"),
                                    value: item?.name,
                                  })}
                                  {renderInfo({
                                    label: I18n.get("Year"),
                                    value: item?.year,
                                  })}
                                  {renderInfo({
                                    label: I18n.get("Brand"),
                                    value: item?.brand,
                                  })}
                                  {/* {renderInfo({
                                    label: I18n.get("Product"),
                                    value: item?.product,
                                  })} */}
                                  {/* {renderInfo({
                                    label: I18n.get("Link"),
                                    value: item?.link,
                                  })}
                                  {renderInfo({
                                    label: I18n.get("Vídeo"),
                                    value: item?.video,
                                  })} */}

                                  {renderInfo({
                                    label: I18n.get("Award of"),
                                    value: renderTypeName(item?.type),
                                  })}
                                </S.ContentItemCase>
                              </Panel>
                            );
                          })}
                      </Collapse>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </S.RowMain>

            <S.RowMain style={{ marginTop: 30 }}>
              <Col xs={24}>
                <S.TitleSectionForm>
                  {I18n.get("Password registration")}
                </S.TitleSectionForm>
              </Col>
              <Col xs={24} sm={12}>
                <Input
                  password
                  placeholder={I18n.get("Password")}
                  required
                  value={inputsForm.passWord}
                  onChange={(value) =>
                    onChangePasswordInputForm("passWord", value)
                  }
                />
                <div />
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={8}
                  value={inputsForm.passWord}
                  valueAgain={inputsForm.confirmPassWord}
                  messages={{
                    minLength: I18n.get("Password has at least 8 characters."),
                    specialChar: I18n.get("Password has special characters."),
                    number: I18n.get("Password has a number."),
                    capital: I18n.get("Password has a capital letter."),
                    match: I18n.get("Passwords match."),
                  }}
                  onChange={(isValid) => setPasswordIsValid(isValid)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Input
                  password
                  placeholder={I18n.get("Confirm password")}
                  required
                  value={inputsForm.confirmPassWord}
                  onChange={(value) =>
                    onChangePasswordInputForm("confirmPassWord", value)
                  }
                />
              </Col>
            </S.RowMain>
          </>
        )}

        {Erros && (
          <Row>
            <Col lg={24}>
              {Object.keys(Erros).map((item, index) => {
                return (
                  <p>
                    {" "}
                    <span class="icon has-text-danger">
                      <i class="fas fa-exclamation"></i>
                    </span>
                    {Erros[item].errorMessage}
                  </p>
                );
              })}
            </Col>
          </Row>
        )}

        <Row style={{ marginTop: 70 }}>
          <Col xs={24} sm={12}>
            <ButtonBpool
              text={I18n.get("Back")}
              theme="secondary"
              full
              loading={isSending}
              onClick={handleBackPage}
            />
          </Col>
          <Col xs={24} sm={12}>
            <ButtonBpool
              text={I18n.get("Finalize")}
              theme="primary"
              full
              loading={isSending}
              onClick={send}
              disabled={isDisabledButtonSend}
            />
          </Col>
        </Row>
      </Main>
    </>
  );
};
