import React from 'react';
import { I18n } from 'aws-amplify';

const Terms = (props) => {

   return (
      <div className="termsContainer" style={{ height: '50vh' }}>
         <div className="termsContent" style={{ height: '45vh' }}>
            <p>
               <strong>BEST PRACTICE TERM</strong>
            </p>
            <p>
               &nbsp;
            </p>
            <p>
               As the <b>{props.projectName}</b> pitch leader, I agree to put my best efforts to:
            </p>
            <ul>
               <li style={{ marginTop: '1.5rem' }}>
                  - Follow the BPitch competition process and funnel, respecting the dates proposed in the elaborated schedule;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                  - Communicate possible schedule changes to my B.Manager BPool, as soon as possible;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                  - Respect the criteria and weights that I defined on the platform as a way to determine the winner, second and third place;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                  - Respond to possible doubts of the participants of the pitch process in an agile and ethical manner, always giving equal conditions to all;
               </li>
               <li style={{ marginTop: '1.5rem' }}>
                  - At the end of the process, give feedback in a constructive way through scorecards and open comments to non-winners of the pitch.
               </li>
            </ul>
         </div>
      </div>
   );
}

export default Terms;