import styled, { keyframes } from "styled-components";

export const TableContainer = styled.div``;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  font-weight: bold;
  line-height: 50px;
  background: #f0f0f0;
`;

export const TableHeadItem = styled.div``;

export const TableContent = styled.div``;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
`;

export const TableItem = styled.div`
  height: 39px;
  display: flex;
  align-items: center;

  ${(props) => (props?.subItem ? "padding-left: 30px;" : null)};
  ${(props) => (props?.total ? "justify-content: flex-end;" : null)};
  ${(props) => (props?.total ? "padding: 0 15px;" : null)};
  ${(props) => (props?.total ? "font-weight: 600;" : null)};

  button {
    cursor: pointer;
    border: none;
    width: 30px;
    height: 30px;
    background: #1983ff;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
  }
`;

export const InputPercent = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  input {
    width: 60px;
    height: 30px;
    border-radius: 4px 0 0 4px;
    border: 1px solid #d8d8d8;
    padding: 5px;
    text-align: center;
  }
`;

export const PercentAddon = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid #d8d8d8;
  background: #d8d8d8;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableCell = styled.div`
  text-align: center;
`;

export const CircleIco = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 10px;
  background: #000;
`;

export const TableFooter = styled.div`
  background: #f0f0f0;
`;

export const TableTotal = styled.div`
  background: #d8d8d8;
`;

export const Text = styled.div`
  font-weight: ${(props) => (props.strongText ? "700;" : "400;")};
  font-size: ${(props) => (props.strongText ? "16px;" : "14px;")};
`;

export const TextSmall = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

export const TogglesTaxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
`;

export const ToggleContent = styled.div`
  display: flex;

  p {
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const ToggleCheckboxDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
