export const hoursMock = [
  {
    display: '09h00',
    active: false,
    blocked: false,
  },
  {
    display: '10h00',
    active: false,
    blocked: false,
  },
  {
    display: '11h00',
    active: false,
    blocked: false,
  },
  {
    display: '12h00',
    active: false,
    blocked: false,
  },
  {
    display: '13h00',
    active: false,
    blocked: false,
  },
  {
    display: '14h00',
    active: false,
    blocked: false,
  },
  {
    display: '15h00',
    active: false,
    blocked: false,
  },
  {
    display: '16h00',
    active: false,
    blocked: false,
  },
  {
    display: '17h00',
    active: false,
    blocked: false,
  },
  {
    display: '18h00',
    active: false,
    blocked: false,
  },
  {
    display: '19h00',
    active: false,
    blocked: false,
  },
  {
    display: '20h00',
    active: false,
    blocked: false,
  },
];
