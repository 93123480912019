import React from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';
import { I18n } from 'aws-amplify';

import Config from '../../config';


import {
    notification,
    Icon
} from 'antd';

import { PROJECT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_delete_extras = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };


            return axios.get(`${Config.API_BASE_URL}/client-project/${data.projectId}/extra/${data.extraId}/delete`, config)
                .then(response => {

                    const payload = {
                        ...response.data,
                        task: {
                            result: true
                        }
                    };

                    dispatch({
                        type: PROJECT_UPDATE,
                        payload: payload
                    })

                    notification.open({
                        message: I18n.get("Extra excluído com sucesso!"),
                        description: '',
                        icon: <Icon type="check" style={{ color: '#000' }} />,
                    });

                    return payload;
                })
                .catch(error => {

                    // Nao faz o dispatch no erro, tratamento direto na tela
                    notification.open({
                        message: I18n.get("Ops! Algo deu errado."),
                        description: I18n.get("Tente novamente mais tarde."),
                        icon: <Icon type="check" style={{ color: '#000' }} />,
                    });

                    const payload = {
                        errors: error.response.data,
                        task: {
                            result: false
                        }
                    };

                    return payload;
                });

        }).catch(error => {

            dispatch({ type: EXPIRED });
        });
    };
}

export default api_delete_extras;
