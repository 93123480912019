import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import TextField from '../../form/text-field';
import TooltipField from '../../form/tooltip-field';


import ScheduleItem from './_bpitch-schedule-item';
import ScheduleChart from './_bpitch-schedule-chart';

import { scrollToTop } from '../../../utils/ui';

import './_bpitch-schedule.css';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class ScheduleForm extends React.Component {
    constructor(props) {

        super(props);

        const { bPitch = {} } = props;

        let currentKeys = ['PORTFOLIO_EVALUATION', 'CHEMISTRY_MEETING', 'QUOTATION_INVITATION', 'QUOTATION_PRESENTATION', 'QUOTATION_WINNER', 'FEEDBACK'];

        let deletedKeys = ['QUOTATION_NEGOCIAION', 'QUOTATION_UPLOAD', 'QUOTATION_EVALUATION', 'KICKOFF'];

        var scheduleMap = {};

        bPitch.schedule && bPitch.schedule.map((item) => {

            scheduleMap[item.code] = item;

            if (currentKeys.indexOf(item.code) == -1) {
                currentKeys = [...currentKeys, item.code];
            }

            if (deletedKeys.indexOf(item.code) > -1) {
                deletedKeys = deletedKeys.filter((k) => k != item.code);
            }
        });

        // currentKeys.map((code) => {

        //     if (!scheduleMap[code]){
        //         scheduleMap[code] = { scheduleType: 'DATE' }
        //     }

        // });

        this.state = {
            fields: {
                custom: {
                    label: I18n.get("Nome"),
                    onChange: this.onChangeForm.bind(this, 'custom'),
                    maxLength: 30,
                    visited: true,
                    successIcon: false
                },
            },
            errors: {},
            form: { ...bPitch, tasks: {} },
            task: {},
            submit_task: {},
            scheduleMap: scheduleMap,
            customCount: 0,
            displayChart: false,
            currentKeys: currentKeys,
            deletedKeys: deletedKeys
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }

            });

            if (task.result) {

                bPitch.schedule && bPitch.schedule.map((item) => {

                    nextState.scheduleMap[item.code] = item;

                    if (prevState.currentKeys.indexOf(item.code) == -1) {
                        nextState.currentKeys = [...prevState.currentKeys, item.code];
                    }

                    if (prevState.deletedKeys.indexOf(item.code) > -1) {
                        nextState.deletedKeys = prevState.deletedKeys.filter((k) => k != item.code);
                    }
                });

                nextState.customCount = Object.keys(nextState.scheduleMap).reduce((acc, code) => {

                    return (code.substring(0, 6) == 'CUSTOM') ? acc + 1 : acc;

                }, 0);
            }

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    getData() {

        const schedule = [];

        this.state.currentKeys.map((k) => {

            const item = this.state.scheduleMap[k];

            const isDateValid = item && item.scheduleType == 'DATE' && item.scheduleDate;
            const isPeridValid = item && item.scheduleType == 'PERIOD' && item.startDate && item.endDate;
            const isDeleted = this.state.deletedKeys.indexOf(k) > -1;

            if (item && (isDateValid || isPeridValid) && !isDeleted) {
                schedule.push(item);
            }

        });

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.props.bPitch,
            schedule
        };

        return data;
    }

    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        const data = this.getData();

        this.props.update_bpitch(data)
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    // onAddTask(item, e) {

    //     e.preventDefault();

    //     this.setState((prevState) => {

    //         var nextState = Object.assign({}, prevState);

    //         nextState.scheduleMap[item.code] = item;

    //         return nextState;
    //     });
    // }

    // onDelTask(item, e) {

    //     e.preventDefault();

    //     this.setState((prevState) => {

    //         var nextState = Object.assign({}, prevState);

    //         delete nextState.scheduleMap[item.code];

    //         if (item.code && item.code.substring(0, 6) == 'CUSTOM') {
    //             nextState.customCount = Math.max(prevState.customCount - 1, 0);
    //         }

    //         return nextState;
    //     }, () => this.onSave());
    // }

    onDelTopic(item, e) {

        e.preventDefault();

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            delete nextState.scheduleMap[item.code];

            if (item.code && item.code.substring(0, 6) == 'CUSTOM') {
                nextState.customCount = Math.max(prevState.customCount - 1, 0);
            }

            nextState.currentKeys = prevState.currentKeys.filter((v) => v != item.code);
            nextState.deletedKeys = [...prevState.deletedKeys, item.code];

            return nextState;
        }, () => this.onSave());
    }

    onAddTopic(e) {

        e.preventDefault();

        const validation = this.state.form.custom && this.state.form.custom.length;

        const errors = {
            custom: validation ? null : { errorMessage: I18n.get('Por favor inforne o Nome') },
        };

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (validation) {

                nextState.customCount = prevState.customCount + 1;

                const item = {
                    code: 'CUSTOM' + nextState.customCount + 1,
                    label: this.state.form.custom
                }

                nextState.scheduleMap[item.code] = item;
                nextState.currentKeys = [...prevState.currentKeys, item.code];
                nextState.form.custom = null;
                nextState.errors.custom = null;
            }
            else {

                nextState.errors = { ...prevState.errors, ...errors };
            }

            return nextState;
        });
    }

    onChangeItem(item, e) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.scheduleMap[item.code] = item;

            return nextState;
        }, () => this.onSave());
    }

    displayChart(value) {
        this.setState({ displayChart: value });
    }

    getItems() {

        const { bp = {} } = this.props;

        const { clientProjectBPitchTasks = [] } = bp;

        const items = {};

        // Monta a lista baseado no Domain
        clientProjectBPitchTasks && clientProjectBPitchTasks.map((item) => {
            items[item.code] = item;
        });

        // Sobrescre os adicionados ao projeto
        this.state.scheduleMap && Object.keys(this.state.scheduleMap).map((k) => {
            const item = this.state.scheduleMap[k];

            const exising = items[item.code] || {};

            items[item.code] = { ...exising, ...item }; // Faz o merge para preservar a propriedade Description
        });

        return items;
    }

    onDragEnd(result) {

        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const getList = (id) => {

            if (id == 'current-list') {

                return this.state.currentKeys;
            }

            if (id == 'deleted-list') {

                return this.state.deletedKeys;
            }

            throw new Error(`Unknown list ${id}`)

        }

        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        const move = (source, destination, droppableSource, droppableDestination) => {
            const sourceClone = Array.from(source);
            const destClone = Array.from(destination);
            const [removed] = sourceClone.splice(droppableSource.index, 1);

            destClone.splice(droppableDestination.index, 0, removed);

            const result = {};
            result[droppableSource.droppableId] = sourceClone;
            result[droppableDestination.droppableId] = destClone;

            return result;
        };

        if (source.droppableId === destination.droppableId) {

            const keys = getList(source.droppableId);

            const orderedKeys = reorder(
                keys,
                result.source.index,
                result.destination.index
            );



            if (source.droppableId === 'current-list') {
                this.setState({
                    currentKeys: orderedKeys
                }, () => {
                    this.onSave();
                });
            }


            if (source.droppableId === 'deleted-list') {
                this.setState({
                    deletedKeys: orderedKeys
                });
            }



        }
        else {

            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );



            this.setState({
                currentKeys: result['current-list'],
                deletedKeys: result['deleted-list']
            }, () => {
                this.onSave();
            });

        }


    }

    render() {

        const { auth = {} } = this.props;

        const { locale } = auth;

        const items = this.getItems();

        const AddButton = ({ onClick, style = {} }) => {
            return (<a onClick={onClick} style={style}>
                <span className="icon" style={{ color: '#000000' }}>
                    <i className="fas fa-plus-circle"></i>
                </span>
            </a>);
        }

        const canEdit = true;

        const getListStyle = isDraggingOver => ({
            background: isDraggingOver ? '#fafafa' : 'transparent',
            border: isDraggingOver ? 'dashed 1px #cccccc' : 'none',
        });

        const getItemStyle = (isDragging, draggableStyle) => ({
            // some basic styles to make the items look a bit nicer
            userSelect: 'none',

            // change background colour if dragging
            background: isDragging ? 'lightgreen' : 'grey',

            // styles we need to apply on draggables
            ...draggableStyle
        });


        return (
            <div className="bpitch-schedule">

                <div className="tabs is-centered is-medium" >

                    <ul style={{ borderBottom: 'solid 1px #dbdbdb' }}>
                        <li className={this.state.displayChart === false ? 'is-active' : ''}>
                            <a onClick={this.displayChart.bind(this, false)} style={{ fontWeight: 'normal' }}>
                                {I18n.get("Cronograma")}
                            </a>
                        </li>
                        <li className={this.state.displayChart === true ? 'is-active' : ''}>
                            <a onClick={this.displayChart.bind(this, true)} style={{ fontWeight: 'normal' }}>
                                {I18n.get("Timeline")}
                            </a>
                        </li>

                    </ul>
                </div>

                <div className="bp-event-section" style={{ marginTop: '3rem', paddingRight: '2rem' }}>

                    {this.state.displayChart && <>

                        <h1 className="title-bpool medium">{I18n.get("Timeline")}</h1>

                        <ScheduleChart {...this.props} />

                    </>}

                    {!this.state.displayChart && <>

                        <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>

                            <h1 className="title-bpool medium">{I18n.get("Cronograma")}</h1>

                            <span style={{ fontSize: '80%', color: '#909090' }}>{I18n.get("Arraste tópicos da 'LISTA DE TÓPICOS' para o 'CRONOGRAMA'. Preencha datas fixas ou períodos. Utilize o botão excluir ou arraste tópicos que não for utilizar do 'CRONOGRAMA' para a 'LISTA DE TÓPICOS'.")}</span>

                            <div style={{ padding: '1rem' }}>

                                <Droppable droppableId="current-list">
                                    {provided => (

                                        <div ref={provided.innerRef} {...provided.droppableProps}>

                                            {this.state.currentKeys.map((code, index) => {

                                                const item = items[code];

                                                const scheduleItem = this.state.scheduleMap[code] || { ...item, scheduleType: 'DATE' };

                                                const scheduleItemIndex = Object.keys(this.state.scheduleMap).indexOf(code);

                                                return (
                                                    <Draggable key={code} draggableId={code} index={index}>
                                                        {provided => (<div ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="columns bpitch-schedule-topic">

                                                            <div className="column is-4" style={{ padding: '0.5rem', display: 'flex', alignItems: 'center' }}>

                                                                {item.label}

                                                                {item.description && <TooltipField tooltip={item.description} classfigurecolor="#cccccc" />}

                                                            </div>
                                                            <div className="column is-7" style={{ padding: '0.5rem' }}>

                                                                {/* {canEdit && !scheduleItem && <AddButton key={index} onClick={this.onAddTask.bind(this, item)} />} */}

                                                                <ScheduleItem {...scheduleItem} locale={locale} index={scheduleItemIndex} errors={this.state.errors} onChangeItem={this.onChangeItem.bind(this)} />

                                                            </div>
                                                            <div className="column is-1" style={{ padding: '1rem' }}>

                                                                {scheduleItem && <>

                                                                    <div className="field is-narrow">
                                                                        {/* <label className="label is-small">
                                                                            &nbsp;
                                                                            </label> */}
                                                                        <div className="control">
                                                                            <a onClick={this.onDelTopic.bind(this, scheduleItem)} style={{ color: '#000000', textDecoration: 'none' }}>
                                                                                <span className="icon">
                                                                                    <i className="fa fa-minus-circle"></i>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>

                                                                </>}

                                                            </div>

                                                        </div>)}
                                                    </Draggable>
                                                );
                                            })}

                                            {provided.placeholder}
                                        </div>
                                    )}

                                </Droppable>


                            </div>

                            <div style={{ marginTop: '2rem', padding: '1rem' }}>

                                <div className="columns">

                                    <div className="column is-4">

                                        <p>{I18n.get("ADICIONAR TÓPICO")}</p>
                                        <span style={{ fontSize: '80%', color: '#909090' }}>{I18n.get("Adicione um tópico customizado.")}</span>

                                        <br /><br />

                                        <div className="columns">

                                            <div className="column is-10">

                                                <TextField {...this.state.fields.custom} value={this.state.form.custom} error={this.state.errors.custom} />

                                            </div>

                                            <div className="column is-2">
                                                <div className="field is-narrow">
                                                    <label className="label is-small">
                                                        &nbsp;
                                        </label>
                                                    <div className="control">
                                                        <a onClick={this.onAddTopic.bind(this)} className='button is-rounded is-black is-outlined'>
                                                            <span className="icon is-small">
                                                                <i className="fa fa-plus"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-6 is-offset-2">

                                        <p>{I18n.get("LISTA DE TÓPICOS")}</p>
                                        <span style={{ fontSize: '80%', color: '#909090' }}>{I18n.get("Arraste um tópico da lista abaixo para a lista principal para adiciona-lo.")}</span>

                                        <br /><br />

                                        <Droppable droppableId="deleted-list">
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                    {this.state.deletedKeys.map((code, index) => {

                                                        const item = items[code];

                                                        return (<Draggable
                                                            key={item.code}
                                                            draggableId={item.code}
                                                            index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <a className="button is-small is-black is-rounded is-outlined" style={{ margin: '0.5rem' }}>
                                                                        {item.label}
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </Draggable>);

                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>


                                    </div>

                                </div>

                            </div>

                        </DragDropContext>

                    </>}

                </div>
            </div >
        );
    }
}

export default ScheduleForm;
