import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useProjects } from "../../../../hooks/useProjects/useProjects.hook";
import { NotificationBPool } from "../../../../components/UI/NotificationBPool";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import Content from "../../../../components/UI/Content";
import Main from "../../../../components/UI/Main";
import { H2Border } from "../../../../components/UI/Titles";
import TableBillingItem from "../../../../../v2/shared/componets/TableBillingItem";
import { Info } from "./components/Info";
import { TableInstallments } from "./components/TableInstallments";
import * as S from "./styles";

export const PoBillingPartner = () => {
  let params = useParams();
  const state = useSelector((state) => state);
  const { getPartnerProjectById } = useProjects();
  const [isLoading, setIsLoading] = useState(false);
  const [partnerProject, setPartnerProject] = useState({});
  const [partnerProjectBilling, setPartnerProjectBilling] = useState({});
  const [category, setCategory] = useState(null);

  const getPartnerProject = async () => {
    try {
      setIsLoading(true);
      const { data } = await getPartnerProjectById({
        projectId: params?.projectId,
      });
      setPartnerProject(data);

      const billing = data?.billings.find(
        (item) => item.id == params?.billingId
      );

      setPartnerProjectBilling(billing);
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao carregar o projeto."),
        duration: 3,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPartnerProject();
  }, []);

  useEffect(() => {
    console.log("partnerProject", partnerProject);
  }, [partnerProject]);

  useEffect(() => {
    if (state?.bp && partnerProject?.projectId) {
      const category =
        state?.bp &&
        state?.bp?.sectorsFlat &&
        state?.bp?.sectorsFlat?.filter(
          (item) => item?.code == partnerProject?.categoryId
        )[0];
      setCategory(category);
    }
  }, [state, partnerProject]);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Faturamento")}</H2Border>
            </Col>
            <Col sm={12} justify="end"></Col>
          </Row>

          <Main bgColor="#fff" padding="30px">
            <Row style={{ marginBottom: 32 }}>
              <Col sm={12}>
                <Info
                  info={partnerProject?.projectName}
                  loading={isLoading}
                  bigger
                />
                <Info info={partnerProject?.clientName} loading={isLoading} />
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <TableBillingItem
                  billing={partnerProjectBilling}
                  currency={partnerProject?.billingCurrency}
                  paymentTermDays={partnerProject?.paymentTermDays}
                  category={category && category?.label}
                  bPoolOffices={state?.bp?.bPoolOffices || []}
                  project={partnerProject}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <S.Title>
                  <h3>{I18n.get("Parcelas")}</h3>
                </S.Title>
                <TableInstallments
                  data={partnerProjectBilling?.installments || []}
                  currency={partnerProject?.currency}
                  isLoading={isLoading}
                  handleReload={getPartnerProject}
                />
              </Col>
            </Row>
          </Main>
        </div>
      </Content>
    </LoggedPage>
  );
};
