import { I18n } from "aws-amplify";

import Config from "./config";

import CountryIso from "./data/countries";

const evento = Config.moduloDashEvent
  ? {
      //TEMP OFF
      // label: I18n.get("Proposals"),
      // link: "/cliente/dashboard/eventos",
      // activeMenu: 3,
    }
  : {};
const eventop = Config.moduloDashEvent
  ? {
      // TEMP OFF
      // label: I18n.get("Proposals"),
      // link: "/parceiro/dashboard/eventos",
      // activeMenu: 3,
    }
  : {};

const constants = {
  PROJECT_TYPES: [
    { code: 0, label: "Marketplace" },
    { code: 2, label: "Proposal" },
    { code: 4, label: "BPitch" },
    { code: 5, label: "BPay" },
  ],
  PROJECT_PROPOSAL_TYPES: [
    { code: 0, label: "Event" },
    { code: 1, label: "Shopper" },
    { code: 2, label: "Incentive" },
    { code: 3, label: "Digital" },
    { code: 4, label: "Communication" },
    { code: 5, label: "Promotional" },
  ],
  PROJECT_EXTRA_TYPES: [
    { code: 0, label: "Production" },
    { code: 1, label: "Media" },
    { code: 2, label: "Tools" },
    { code: 3, label: "Finance" },
    { code: 4, label: "InternalCosts" },
    { code: 5, label: "ExternalCosts" },
  ],
  STATES: [
    { label: "Acre", code: "AC" },
    { label: "Alagoas", code: "AL" },
    { label: "Amapá", code: "AP" },
    { label: "Amazonas", code: "AM" },
    { label: "Bahia", code: "BA" },
    { label: "Ceará", code: "CE" },
    { label: "Distrito Federal", code: "DF" },
    { label: "Espírito Santo", code: "ES" },
    { label: "Goiás", code: "GO" },
    { label: "Maranhão", code: "MA" },
    { label: "Mato Grosso", code: "MT" },
    { label: "Mato Grosso do Sul", code: "MS" },
    { label: "Minas Gerais", code: "MG" },
    { label: "Pará", code: "PA" },
    { label: "Paraíba", code: "PB" },
    { label: "Paraná", code: "PR" },
    { label: "Pernambuco", code: "PE" },
    { label: "Piauí", code: "PI" },
    { label: "Rio de Janeiro", code: "RJ" },
    { label: "Rio Grande do Norte", code: "RN" },
    { label: "Rio Grande do Sul", code: "RS" },
    { label: "Rondônia", code: "RO" },
    { label: "Roraima", code: "RR" },
    { label: "Santa Catarina", code: "SC" },
    { label: "São Paulo", code: "SP" },
    { label: "Sergipe", code: "SE" },
    { label: "Tocantins", code: "TO" },
  ],

  STATES_AND_REGIONS: [
    { label: "Região Norte", code: "REG_NORTE" },
    { label: "Região Nordeste", code: "REG_NORDESTE" },
    { label: "Região Centro-Oeste", code: "REG_COESTE" },
    { label: "Região Sudeste", code: "REG_SUDESTE" },
    { label: "Região Sul", code: "REG_SUL" },
    { label: "Acre", code: "AC" },
    { label: "Alagoas", code: "AL" },
    { label: "Amapá", code: "AP" },
    { label: "Amazonas", code: "AM" },
    { label: "Bahia", code: "BA" },
    { label: "Ceará", code: "CE" },
    { label: "Distrito Federal", code: "DF" },
    { label: "Espírito Santo", code: "ES" },
    { label: "Goiás", code: "GO" },
    { label: "Maranhão", code: "MA" },
    { label: "Mato Grosso", code: "MT" },
    { label: "Mato Grosso do Sul", code: "MS" },
    { label: "Minas Gerais", code: "MG" },
    { label: "Pará", code: "PA" },
    { label: "Paraíba", code: "PB" },
    { label: "Paraná", code: "PR" },
    { label: "Pernambuco", code: "PE" },
    { label: "Piauí", code: "PI" },
    { label: "Rio de Janeiro", code: "RJ" },
    { label: "Rio Grande do Norte", code: "RN" },
    { label: "Rio Grande do Sul", code: "RS" },
    { label: "Rondônia", code: "RO" },
    { label: "Roraima", code: "RR" },
    { label: "Santa Catarina", code: "SC" },
    { label: "São Paulo", code: "SP" },
    { label: "Sergipe", code: "SE" },
    { label: "Tocantins", code: "TO" },
  ],

  STATES_AND_REGIONS_LATAM: [
    { code: "LATAM_ALL", label: "Todos" },
    { code: "LATAM_ARG", label: "Argentina" },
    { code: "LATAM_BLZ", label: "Belize" },
    { code: "LATAM_BOL", label: "Bolivia" },
    { code: "LATAM_BRA", label: "Brazil" },
    { code: "LATAM_CHL", label: "Chile" },
    { code: "LATAM_COL", label: "Colombia" },
    { code: "LATAM_CRI", label: "Costa Rica" },
    { code: "LATAM_ECU", label: "Ecuador" },
    { code: "LATAM_SLV", label: "El Salvador" },
    { code: "LATAM_USA", label: "Estados Unidos" },
    { code: "LATAM_GTM", label: "Guatemala" },
    { code: "LATAM_GUY", label: "Guyana" },
    { code: "LATAM_HND", label: "Honduras" },
    { code: "LATAM_MEX", label: "Mexico" },
    { code: "LATAM_NIC", label: "Nicaragua" },
    { code: "LATAM_PAN", label: "Panama" },
    { code: "LATAM_PER", label: "Peru" },
    { code: "LATAM_PRY", label: "Paraguay" },
    { code: "LATAM_SUR", label: "Suriname" },
    { code: "LATAM_URY", label: "Uruguay" },
    { code: "LATAM_VEN", label: "Venezuela" },
  ],

  BRA_REGIONS: [
    { code: "N", label: I18n.get("Norte") },
    { code: "NE", label: I18n.get("Nordeste") },
    { code: "CO", label: I18n.get("Centro-Oeste") },
    { code: "SE", label: I18n.get("Sudeste") },
    { code: "S", label: I18n.get("Sul") },
  ],

  USA_REGIONS: [
    { code: "USA", label: I18n.get("Estados Unidos") },
    { code: "AUS", label: I18n.get("Austrália") },
    { code: "GBR", label: I18n.get("Reino Unido") },
    { code: "LAT", label: I18n.get("América Latina") },
  ],

  LATAM_REGIONS: [
    { code: "ARG", label: I18n.get("Argentina") },
    { code: "BRA", label: I18n.get("Brasil") },
    { code: "CHL", label: I18n.get("Chile") },
    { code: "COL", label: I18n.get("Colombia") },
    { code: "CRI", label: I18n.get("Costa Rica") },
    { code: "SLV", label: I18n.get("El Salvador") },
    { code: "ECU", label: I18n.get("Equador") },
    { code: "GTM", label: I18n.get("Glatemala") },
    { code: "MEX", label: I18n.get("México") },
    { code: "PAN", label: I18n.get("Panamá") },
    { code: "URY", label: I18n.get("Uruguai") },
    { code: "BOL", label: I18n.get("Bolívia") },
    { code: "PER", label: I18n.get("Peru") },
  ],

  YESNO: [
    { label: I18n.get("Sim"), code: "true" },
    { label: I18n.get("Não"), code: "false" },
  ],

  BPOOL_PRESELECTION: [
    { label: I18n.get("Pré-aprovado"), code: "true" },
    { label: I18n.get("Rejeitado"), code: "false" },
  ],

  BPOOL_FINAL_APPROVAL: [
    { label: I18n.get("Aprovado"), code: "true" },
    { label: I18n.get("Rejeitado"), code: "false" },
  ],

  EVENT_TIME_PERIODS: [
    { code: "MORNING", label: I18n.get("Manhã") },
    { code: "EVENING", label: I18n.get("Tarde") },
    { code: "NIGHT", label: I18n.get("Noite") },
    { code: "ALLDAY", label: I18n.get("Dia inteiro") },
  ],

  EVENT_INVITATIONS: [
    { code: "true", label: I18n.get("Preciso que me ajudem a pensar nisso") },
    { code: "false", label: I18n.get("Eu vou cuidar dos convites") },
  ],

  EVENT_VENUE_TYPES: [
    { code: "VENUE", label: I18n.get("Já tenho local. Trata-se do(a)") },
    {
      code: "SIMILAR_TO",
      label: I18n.get(
        "Não tenho local definido, mas gostaria que fosse algo do tipo"
      ),
    },
    { code: "OPEN", label: I18n.get("Sem ideia, me ajudem a pensar!") },
  ],

  EVENT_DIGITAL_PLATFORM_TYPES: [
    { code: "YES", label: I18n.get("Sim. Plataforma") },
    {
      code: "SIMILAR_TO",
      label: I18n.get("Não tenho mas gostaria ver exemplos nas plataformas"),
    },
    { code: "OPEN", label: I18n.get("Sem ideia, me ajudem a pensar!") },
  ],

  EVENT_DIGITAL_PLATFORM_INTEGRATIONS: [
    { code: "YES", label: I18n.get("Sim. Plataforma") },
    {
      code: "NO",
      label: I18n.get(
        "Não, será tudo através da plataforma que vocês apresentarem"
      ),
    },
  ],

  EVENT_DIGITAL_INTERNET_RESTRICTIONS: [
    { code: "YES", label: I18n.get("Sim, texto aberto") },
    { code: "NO", label: I18n.get("Não, pode considerar internet básica") },
  ],

  EVENT_DIGITAL_FOOD_TYPES: [
    { code: "YES", label: I18n.get("Sim, minha sugestão é") },
    { code: "NO", label: I18n.get("Não") },
  ],

  EVENT_VENUE_REGIONS: [
    { code: "YES", label: I18n.get("Sim") },
    { code: "NO", label: I18n.get("Não. Estou aberto a sugestões.") },
  ],

  EVENT_TRANSPORT_TYPES: [
    { code: "AIR", label: I18n.get("Aéreo") },
    { code: "LAND", label: I18n.get("Terrestre") },
  ],

  EVENT_TRANSPORT_LAND_TYPES: [
    { code: "UBER_TAXI", label: I18n.get("Uber/Taxi") },
    { code: "SHUTTLE", label: I18n.get("Van") },
    { code: "PRIVATE", label: I18n.get("Motorista Privado") },
  ],

  EVENT_REGIONS: [
    { code: "N", label: I18n.get("Norte") },
    { code: "NE", label: I18n.get("Nordeste") },
    { code: "CO", label: I18n.get("Centro-Oeste") },
    { code: "SE", label: I18n.get("Sudeste") },
    { code: "S", label: I18n.get("Sul") },
  ],

  EVENT_ACCOMMODATION_TYPES: [
    { code: "YES", label: I18n.get("Sim") },
    { code: "NO", label: I18n.get("Não") },
  ],

  EVENT_LOGISTICS_AGENCY: [
    { code: "YES", label: I18n.get("Sim") },
    { code: "NO", label: I18n.get("Não. Estou aberto a sugestões.") },
  ],

  EVENT_LOGISTICS_AGENCY_INTIAL: [
    { code: "YES", label: I18n.get("Sim. Quantidade:") },
    { code: "NO", label: I18n.get("Não") },
  ],

  EVENT_CATERING_TYPES: [
    { code: "WELCOME_COFFEE", label: I18n.get("Welcome coffee") },
    { code: "WELCOME_DRINKS", label: I18n.get("Welcome drinks") },
    { code: "BRUNCH", label: I18n.get("Brunch") },
    { code: "BREAKFAST", label: I18n.get("Café da manhã") },
    { code: "LUNCH", label: I18n.get("Almoço") },
    { code: "COFFEE_BREAK", label: I18n.get("Coffee break") },
    { code: "DINER", label: I18n.get("Jantar") },
    { code: "THEMED_COUSINE", label: I18n.get("Culinária temática. Qual?") },
    { code: "ALCOHOLIC_BEVERAGE", label: I18n.get("Bebidas alcoólicas") },
  ],

  EVENT_FOOD_TYPES: [
    { code: "FINGER", label: I18n.get("Finger foods") },
    { code: "COCKTAIL", label: I18n.get("Coquetel") },
    { code: "BUFFET", label: I18n.get("Buffet") },
    { code: "PLATED", label: I18n.get("Empratado") },
    { code: "PARTY", label: I18n.get("Festa") },
  ],

  EVENT_SETDESIGN_TYPES: [
    { code: "YES", label: I18n.get("Sim, como:") },
    { code: "NO", label: I18n.get("Não") },
    { code: "SUGGEST", label: I18n.get("Aceito sugestões") },
  ],

  EVENT_FURNITURE_TYPES: [
    { code: "YES", label: I18n.get("Sim, como:") },
    { code: "NO", label: I18n.get("Não") },
    { code: "SUGGEST", label: I18n.get("Aceito sugestões") },
  ],

  EVENT_AVEQUIPAMENT_TYPES: [
    { code: "YES", label: I18n.get("Sim, como:") },
    { code: "NO", label: I18n.get("Não") },
    { code: "SUGGEST", label: I18n.get("Aceito sugestões") },
  ],

  EVENT_STAFF_TYPES: [
    {
      code: "RECEPTIONIST",
      label: I18n.get("Recepcionista(s)"),
      children: [
        { code: "RECEPTIONIST_A", label: I18n.get("Perfil A") },
        { code: "RECEPTIONIST_B", label: I18n.get("Perfil B") },
        { code: "RECEPTIONIST_C", label: I18n.get("Perfil C") },
        { code: "RECEPTIONIST_D", label: I18n.get("Perfil Diversidade") },
      ],
    },
    {
      code: "PROMOTER",
      label: I18n.get("Promotor(es)"),
      children: [
        { code: "PROMOTER_A", label: I18n.get("Perfil A") },
        { code: "PROMOTER_B", label: I18n.get("Perfil B") },
        { code: "PROMOTER_C", label: I18n.get("Perfil C") },
        { code: "PROMOTER_D", label: I18n.get("Perfil Diversidade") },
      ],
    },
    { code: "SECURITY", label: I18n.get("Segurança") },
    { code: "CLEANING", label: I18n.get("Limpeza") },
    {
      code: "PARAMEDIC",
      label: I18n.get(
        "Socorristas/ambulâncias (obrigatório para eventos acima de 1.500 pessoas*)"
      ),
    },
    { code: "OTHER", label: I18n.get("Outros") },
  ],

  EVENT_STAFF_SHOPPER_TYPES: [
    {
      code: "RECEPTIONIST",
      label: I18n.get("Recepcionista(s)"),
      children: [
        { code: "RECEPTIONIST_A", label: I18n.get("Perfil A") },
        { code: "RECEPTIONIST_B", label: I18n.get("Perfil B") },
        { code: "RECEPTIONIST_C", label: I18n.get("Perfil C") },
        { code: "RECEPTIONIST_D", label: I18n.get("Perfil Diversidade") },
      ],
    },
    {
      code: "PROMOTER",
      label: I18n.get("Promotor(es)"),
      children: [
        { code: "PROMOTER_A", label: I18n.get("Perfil A") },
        { code: "PROMOTER_B", label: I18n.get("Perfil B") },
        { code: "PROMOTER_C", label: I18n.get("Perfil C") },
        { code: "PROMOTER_D", label: I18n.get("Perfil Diversidade") },
      ],
    },
    { code: "SECURITY", label: I18n.get("Segurança") },
    { code: "CLEANING", label: I18n.get("Limpeza") },
    { code: "PRODUCER", label: I18n.get("Produtor") },
  ],

  EVENT_STAFF_INCENTIVE_TYPES: [
    {
      code: "RECEPTIONIST",
      label: I18n.get("Recepcionista(s)"),
      children: [
        { code: "RECEPTIONIST_A", label: I18n.get("Perfil A") },
        { code: "RECEPTIONIST_B", label: I18n.get("Perfil B") },
        { code: "RECEPTIONIST_C", label: I18n.get("Perfil C") },
        { code: "RECEPTIONIST_D", label: I18n.get("Perfil Diversidade") },
      ],
    },
    {
      code: "PROMOTER",
      label: I18n.get("Promotor(es)"),
      children: [
        { code: "PROMOTER_A", label: I18n.get("Perfil A") },
        { code: "PROMOTER_B", label: I18n.get("Perfil B") },
        { code: "PROMOTER_C", label: I18n.get("Perfil C") },
        { code: "PROMOTER_D", label: I18n.get("Perfil Diversidade") },
      ],
    },
    { code: "SECURITY", label: I18n.get("Segurança") },
    { code: "CLEANING", label: I18n.get("Limpeza") },
    { code: "PRODUCER", label: I18n.get("Produtor") },
  ],

  EVENT_COSTUME_DESIGN_TYPES: [
    { code: "YES", label: I18n.get("Sim, já tenho disponível.") },
    { code: "NO", label: I18n.get("Não. Preciso que me ajudem a pensar.") },
  ],

  EVENT_ATTRACTION_TYPES: [
    { code: "MUSIC_BAND", label: I18n.get("Banda") },
    { code: "DJ", label: "DJ" },
    { code: "MC", label: I18n.get("Mestre de cerimônias") },
    { code: "CELEBRITY", label: I18n.get("Celebridade. Quem?") },
    { code: "INFLUENCER", label: I18n.get("Influenciador") },
    { code: "SPEAKER", label: I18n.get("Palestrante") },
    { code: "OTHER", label: I18n.get("Outros") },
  ],

  EVENT_SOUVENIR_TYPES: [
    {
      code: "YES_PERSONALIZED",
      label: I18n.get("Sim, personalizado. Pensei em algo do tipo:"),
    },
    {
      code: "YES_IMMEDIATE",
      label: I18n.get("Sim, pronta entrega. Pensei em algo do tipo:"),
    },
    {
      code: "YES_TECH",
      label: I18n.get("Sim, técnologico. Pensei em algo do tipo:"),
    },
    { code: "SUGGEST", label: I18n.get("Sim, me ajudem a pensar!") },
    { code: "NO", label: I18n.get("Não.") },
  ],

  EVENT_MATERIAL_TYPES: [
    { code: "YES", label: I18n.get("Sim. Pensei em algo do tipo:") },
    { code: "SUGGEST", label: I18n.get("Sim, me ajudem a pensar!") },
    { code: "NO", label: I18n.get("Não.") },
  ],

  PROJECT_CANCEL_REASONS: [
    {
      code: "SERVICE",
      label: I18n.get("Não achei um pacote de serviços que me atenda."),
    },
    {
      code: "PARTNER",
      label: I18n.get("Os parceiros não estão dentro das minhas expectativas."),
    },
    {
      code: "QUOTATION",
      label: I18n.get(
        "Não consegui entrar em acordo com o parceiro a respeito do escopo final do projeto"
      ),
    },
    { code: "OTHER", label: I18n.get("Outro:") },
  ],

  SAZONAL: [
    { code: "CARNAVAL", label: I18n.get("Carnaval") },
    { code: "DIADASMAES", label: I18n.get("Dia das Mães") },
    { code: "DIADOSPAIS", label: I18n.get("Dia dos Pais") },
    { code: "DIADOSNAMORADOS", label: I18n.get("Dia dos namorados") },
    { code: "PASCOA", label: I18n.get("Páscoa") },
    { code: "INVERNO", label: I18n.get("Inverno") },
    { code: "VERAO", label: I18n.get("Verão") },
  ],

  BANK_IDS: [
    { code: "1", label: "1 - Banco do Brasil S.A." },
    { code: "70", label: "70 - Banco de Brasília S.A." },
    {
      code: "136",
      label:
        "136 - Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil",
    },
    { code: "104", label: "104 - Caixa Econômica Federal" },
    { code: "77", label: "77 - Banco Inter S.A." },
    { code: "741", label: "741 - Banco Ribeirão Preto S.A." },
    { code: "739", label: "739 - Banco Cetelem S.A." },
    { code: "743", label: "743 - Banco Semear S.A." },
    { code: "747", label: "747 - Banco Rabobank International Brasil S.A." },
    { code: "748", label: "748 - Banco Cooperativo Sicredi S. A." },
    { code: "752", label: "752 - Banco BNP Paribas Brasil S.A." },
    { code: "756", label: "756 - Banco Cooperativo do Brasil S/A - Bancoob" },
    { code: "757", label: "757 - Banco Keb Hana do Brasil S. A." },
    {
      code: "84",
      label: "84 - Uniprime Norte do Paraná - Cooperativa de Crédito Ltda.",
    },
    { code: "62", label: "62 - Hipercard Banco Múltiplo S.A." },
    { code: "74", label: "74 - Banco J. Safra S.A." },
    {
      code: "99",
      label:
        "99 - Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda.",
    },
    { code: "25", label: "25 - Banco Alfa S.A." },
    { code: "63", label: "63 - Banco Bradescard S.A." },
    {
      code: "97",
      label: "97 - Credisis - Central de Cooperativas de Crédito Ltda.",
    },
    { code: "3", label: "3 - Banco da Amazônia S.A." },
    { code: "37", label: "37 - Banco do Estado do Pará S.A." },
    { code: "85", label: "85 - Cooperativa Central de Crédito - Ailos" },
    {
      code: "114",
      label:
        "114 - Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP",
    },
    { code: "36", label: "36 - Banco Bradesco BBI S.A." },
    { code: "394", label: "394 - Banco Bradesco Financiamentos S.A." },
    { code: "4", label: "4 - Banco do Nordeste do Brasil S.A." },
    {
      code: "320",
      label: "320 - China Construction Bank (Brasil) Banco Múltiplo S/A",
    },
    {
      code: "133",
      label:
        "133 - Confederação Nacional das Cooperativas Centrais de Crédito e Economia Familiar",
    },
    { code: "121", label: "121 - Banco Agibank S.A." },
    { code: "83", label: "83 - Banco da China Brasil S.A." },
    { code: "94", label: "94 - Banco Finaxis S.A." },
    { code: "47", label: "47 - Banco do Estado de Sergipe S.A." },
    { code: "254", label: "254 - Parana Banco S. A." },
    { code: "107", label: "107 - Banco Bocom BBM S.A." },
    { code: "412", label: "412 - Banco Capital S. A." },
    { code: "124", label: "124 - Banco Woori Bank do Brasil S.A." },
    { code: "389", label: "389 - Banco Mercantil do Brasil S.A." },
    { code: "634", label: "634 - Banco Triângulo S.A." },
    {
      code: "279",
      label: "279 - Cooperativa de Crédito Rural de Primavera do Leste",
    },
    {
      code: "21",
      label: "21 - Banestes S.A. Banco do Estado do Espírito Santo",
    },
    { code: "246", label: "246 - Banco ABC Brasil S.A." },
    { code: "208", label: "208 - Banco BTG Pactual S.A." },
    { code: "746", label: "746 - Banco Modal S.A." },
    { code: "241", label: "241 - Banco Clássico S.A." },
    { code: "612", label: "612 - Banco Guanabara S.A." },
    { code: "604", label: "604 - Banco Industrial do Brasil S.A." },
    { code: "505", label: "505 - Banco Credit Suisse (Brasil) S.A." },
    { code: "300", label: "300 - Banco de la Nacion Argentina" },
    { code: "477", label: "477 - Citibank N.A." },
    { code: "266", label: "266 - Banco Cédula S.A." },
    { code: "122", label: "122 - Banco Bradesco BERJ S.A." },
    { code: "376", label: "376 - Banco J. P. Morgan S. A." },
    { code: "473", label: "473 - Banco Caixa Geral - Brasil S.A." },
    { code: "745", label: "745 - Banco Citibank S.A." },
    { code: "120", label: "120 - Banco Rodobens SA" },
    { code: "265", label: "265 - Banco Fator S.A." },
    { code: "243", label: "243 - Banco Máxima S.A." },
    { code: "17", label: "17 - BNY Mellon Banco S.A." },
    { code: "125", label: "125 - Plural S.A. Banco Múltiplo" },
    { code: "65", label: "65 - Banco AndBank (Brasil) S.A." },
    { code: "213", label: "213 - Banco Arbi S.A." },
    { code: "18", label: "18 - Banco Tricury S.A." },
    { code: "422", label: "422 - Banco Safra S.A." },
    { code: "224", label: "224 - Banco Fibra S.A." },
    { code: "600", label: "600 - Banco Luso Brasileiro S.A." },
    { code: "623", label: "623 - Banco Pan S.A." },
    { code: "204", label: "204 - Banco Bradesco Cartões S.A." },
    { code: "655", label: "655 - Banco Votorantim S.A." },
    { code: "456", label: "456 - Banco MUFG Brasil S.A." },
    { code: "464", label: "464 - Banco Sumitomo Mitsui Brasileiro S.A." },
    { code: "341", label: "341 - Itaú Unibanco S.A." },
    { code: "274", label: "274 - BMP SCMEPP Ltda" },
    { code: "237", label: "237 - Banco Bradesco S.A." },
    { code: "613", label: "613 - Omni Banco S.A." },
    { code: "652", label: "652 - Itaú Unibanco Holding S.A." },
    { code: "637", label: "637 - Banco Sofisa S. A." },
    { code: "653", label: "653 - Banco Indusval S. A." },
    { code: "69", label: "69 - Banco Crefisa S.A." },
    { code: "249", label: "249 - Banco Investcred Unibanco S.A." },
    { code: "318", label: "318 - Banco BMG S.A." },
    { code: "626", label: "626 - Banco Ficsa S. A." },
    { code: "366", label: "366 - Banco Société Générale Brasil S.A." },
    { code: "611", label: "611 - Banco Paulista S.A." },
    {
      code: "755",
      label: "755 - Bank of America Merrill Lynch Banco Múltiplo S.A.",
    },
    {
      code: "89",
      label: "89 - Cooperativa de Crédito Rural da Região da Mogiana",
    },
    { code: "643", label: "643 - Banco Pine S.A." },
    { code: "707", label: "707 - Banco Daycoval S.A." },
    { code: "487", label: "487 - Deutsche Bank S.A. - Banco Alemão" },
    { code: "633", label: "633 - Banco Rendimento S.A." },
    { code: "218", label: "218 - Banco BS2 S.A." },
    {
      code: "753",
      label: "753 - Novo Banco Continental S.A. - Banco Múltiplo",
    },
    { code: "222", label: "222 - Banco Credit Agrícole Brasil S.A." },
    { code: "281", label: "281 - Cooperativa de Crédito Rural Coopavel" },
    { code: "98", label: "98 - Credialiança Cooperativa de Crédito Rural" },
    { code: "610", label: "610 - Banco VR S.A." },
    { code: "10", label: "10 - Credicoamo Crédito Rural Cooperativa" },
    { code: "33", label: "33 - Banco Santander (Brasil) S. A." },
    { code: "217", label: "217 - Banco John Deere S.A." },
    { code: "41", label: "41 - Banco do Estado do Rio Grande do Sul S.A." },
    { code: "654", label: "654 - Banco A. J. Renner S.A." },
    { code: "212", label: "212 - Banco Original S.A." },
    { code: "260", label: "260 - Banco Nubank (NuConta)" },
    { code: "336", label: "336 - Banco C6" },
    { code: "403", label: "403 - Banco Cora SCD" },
    { code: "756", label: "756 - BANCO COOPERATIVO SICOOB S.A." },
    { code: "319", label: "319 - Banco Ouro Minas" },
    { code: "329", label: "329 - Banco QItech" },
  ],

  ASSET_STATUS: [
    { code: "2", label: I18n.get("Criado") },
    { code: "4", label: I18n.get("Aprovado") },
    { code: "6", label: I18n.get("Rejeitado") },
  ],

  PERMISSION_CLIENT: [
    { code: "Client", label: I18n.get("Master") },
    { code: "ClientUserMarketing", label: I18n.get("Marketing") },
    { code: "ClientUserPurchasing", label: I18n.get("Compras") },
  ],

  MENU_REPORT_CLIENT: [
    {
      label: I18n.get("Central de pendências"),
      link: "/cliente/central-de-pendencias",
      activeMenu: 1,
    },
    {
      label: I18n.get("Marketplace"),
      link: "/cliente/dashboard",
      activeMenu: 2,
    },
    {
      label: I18n.get("Spending"),
      link: "/cliente/dashboard/savings",
      activeMenu: 4,
    },
  ],

  MENU_REPORT_PARTNER: [
    {
      label: I18n.get("Central de pendências"),
      link: "/parceiro/central-de-pendencias",
      activeMenu: 1,
    },
    {
      label: I18n.get("Marketplace"),
      link: "/parceiro/dashboard",
      activeMenu: 2,
    },
    eventop,
    // { label: I18n.get("Spending"), link: "/parceiro/dashboard/savings", activeMenu: 4 },
  ],

  MENU_PRE_SIGNUP: [
    {
      label: I18n.get("Sobre sua boutique"),
      link: "/parceiro/pre-cadastro/",
      activeMenu: 1,
    },
    {
      label: I18n.get("Especialidades"),
      link: "/parceiro/pre-cadastro/especialidades/",
      activeMenu: 2,
    },
    {
      label: I18n.get("Mercados de expertise"),
      link: "/parceiro/pre-cadastro/expertises/",
      activeMenu: 3,
    },
    {
      label: I18n.get("Trabalhos"),
      link: "/parceiro/pre-cadastro/trabalhos/",
      activeMenu: 4,
    },
    {
      label: I18n.get("Clientes & Referências"),
      link: "/parceiro/pre-cadastro/clientes/",
      activeMenu: 5,
    },
  ],

  MENU_REGISTER: [
    {
      label: I18n.get("Usuários"),
      link: "/parceiro/cadastro/usuarios/",
      activeMenu: 1,
    },
    {
      label: I18n.get("A Empresa"),
      link: "/parceiro/cadastro/empresa/",
      activeMenu: 2,
    },
    {
      label: I18n.get("Especialidades"),
      link: "/parceiro/cadastro/especialidades/",
      activeMenu: 3,
    },
    {
      label: I18n.get("Mercados de expertise"),
      link: "/parceiro/cadastro/expertises/",
      activeMenu: 4,
    },
    {
      label: I18n.get("Trabalhos & Realizações"),
      link: "/parceiro/cadastro/trabalhos/",
      activeMenu: 5,
    },
    {
      label: I18n.get("Liderança"),
      link: "/parceiro/cadastro/lideranca/",
      activeMenu: 6,
    },
    {
      label: I18n.get("Clientes"),
      link: "/parceiro/cadastro/clientes-e-referencias/",
      activeMenu: 7,
    },
  ],

  MENU_REGISTER_CLIENT: [
    {
      label: I18n.get("Sobre o Responsável"),
      link: "/cliente/cadastre-se/responsavel/",
      activeMenu: 1,
    },
    {
      label: I18n.get("Sobre a Empresa"),
      link: "/cliente/cadastre-se/empresa/",
      activeMenu: 2,
    },
    {
      label: I18n.get("Marcas & Usuários"),
      link: "/cliente/cadastre-se/marcas-e-usuarios/",
      activeMenu: 3,
    },
    // { label: I18n.get("Acesso Master"), link: "/cliente/cadastre-se/acesso-master/", activeMenu: 4 }
  ],

  MENU_REGISTER_LEADERS: [
    {
      label: I18n.get("Sobre você"),
      link: "/lider/cadastro/sobre-voce/",
      activeMenu: 1,
    },
    {
      label: I18n.get("Segmentos & Expertises"),
      link: "/lider/cadastro/expertise/",
      activeMenu: 2,
    },
    {
      label: I18n.get("Cases & Prêmios"),
      link: "/lider/cadastro/trabalhos/",
      activeMenu: 3,
    },
  ],

  MENU_REGISTER_REF: [
    {
      label: I18n.get("Sobre você"),
      link: "/referencia/cadastro/sobre-voce",
      activeMenu: 1,
    },
    {
      label: I18n.get("Avaliação"),
      link: "/referencia/cadastro/avaliacao",
      activeMenu: 2,
    },
  ],

  MKT_SIZE: [
    { label: I18n.get("Pequena < 100"), code: "SMALL" },
    { label: I18n.get("Médio >100 até 750"), code: "AVERAGE" },
    { label: I18n.get("Grande >750 até 1500"), code: "BIG" },
    { label: I18n.get("Macro >1500"), code: "MACRP" },
  ],
  MKT_COMPLEXITY: [
    { label: I18n.get("Pequena"), code: "SMALL" },
    { label: I18n.get("Média"), code: "AVERAGE" },
    { label: I18n.get("Grande"), code: "BIG" },
  ],

  MKT_EVENTSTYPE: [
    { label: I18n.get("Corporativos (B2B)"), code: "CORPORATEB2B" },
    { label: I18n.get("Artísticos"), code: "ARTISTIC" },
    { label: I18n.get("Culturais"), code: "CULTURAL" },
    { label: I18n.get("Religiosos"), code: "RELIGIOUS" },
    { label: I18n.get("Esportivos"), code: "SPORTS" },
    { label: I18n.get("Acadêmicos"), code: "ACADEMICS" },
    { label: I18n.get("Turísticos"), code: "TOURISTIC" },
    { label: I18n.get("Marca (B2C)"), code: "BRANDB2C" },
  ],

  MKT_CORPORATETYPE: [
    { label: I18n.get("Workshops"), code: "Workshops" },
    { label: I18n.get("Convenções"), code: "Convencoes" },
    { label: I18n.get("Ferias"), code: "Feiras" },
    { label: I18n.get("Festa corporativa"), code: "Festa corporativa" },
    { label: I18n.get("Entrevistas coletiva"), code: "Entrevistas coletiva" },
    { label: I18n.get("Conferências"), code: "Conferencias" },
    { label: I18n.get("Forum"), code: "Forum" },
    { label: "RH", code: "RH" },
    { label: I18n.get("Evento PR"), code: "Evento PR" },
    { label: I18n.get("Roadshow"), code: "Roadshow" },
  ],

  LABORTYPES: [
    { label: I18n.get("Modelos Eventos"), code: "EVENTOS" },
    { label: I18n.get("Artísticos"), code: "RECEPCIONISTAS" },
    { label: I18n.get("Promotora PDV"), code: "PDV" },
  ],

  LABORSIZE: [
    { label: I18n.get("Pequena < 100"), code: "SMALL" },
    { label: I18n.get("Médio >100 até 750"), code: "AVERAGE" },
    { label: I18n.get("Grande >750 até 1500"), code: "BIG" },
    { label: I18n.get("Macro >1500"), code: "MACRP" },
  ],

  CONTRACTTYPES: [
    { label: I18n.get("Permanente"), code: "PERMANENTE" },
    { label: I18n.get("Temporario"), code: "TEMPORARIO" },
  ],

  MONTHS: [
    { label: "00", id: 0 },
    { label: "01", id: 1 },
    { label: "02", id: 2 },
    { label: "03", id: 3 },
    { label: "04", id: 4 },
    { label: "05", id: 5 },
    { label: "06", id: 6 },
    { label: "07", id: 7 },
    { label: "08", id: 8 },
    { label: "09", id: 9 },
    { label: "10", id: 10 },
    { label: "11", id: 11 },
    { label: "12", id: 12 },
  ],

  YEARS: [
    { label: "00", id: 0 },
    { label: "01", id: 1 },
    { label: "02", id: 2 },
    { label: "03", id: 3 },
    { label: "04", id: 4 },
    { label: "05", id: 5 },
    { label: "06", id: 6 },
    { label: "07", id: 7 },
    { label: "08", id: 8 },
    { label: "09", id: 9 },
    { label: "10", id: 10 },
    { label: "11", id: 11 },
    { label: "12", id: 12 },
    { label: "13", id: 13 },
    { label: "14", id: 14 },
    { label: "15", id: 15 },
    { label: "16", id: 16 },
    { label: "17", id: 17 },
    { label: "18", id: 18 },
    { label: "19", id: 19 },
    { label: "20", id: 20 },
    { label: "21", id: 21 },
    { label: "22", id: 22 },
    { label: "23", id: 23 },
    { label: "24", id: 24 },
    { label: "25", id: 25 },
    { label: "26", id: 26 },
    { label: "27", id: 27 },
    { label: "28", id: 28 },
    { label: "29", id: 29 },
    { label: "30", id: 30 },
  ],

  BPITCH_SCORECARD: [
    {
      code: "PARTNERFIT_CHEMISTRY",
      label: I18n.get("PARTNER FIT & CHEMISTRY"),
      children: [
        {
          code: "EXPERTISE",
          label: I18n.get(
            "Expertise and specialization alignment with my project"
          ),
          perc: 10,
        },
        {
          code: "AFFINITY",
          label: I18n.get("Partner affinity with my category and/or brand"),
          perc: 10,
        },
        {
          code: "RELEVANCE",
          label: I18n.get(
            "Relevance of partner portfolio and experience for my project"
          ),
          perc: 5,
        },
        {
          code: "PERCEPTION",
          label: I18n.get("General perception of partner portfolio"),
          perc: 5,
        },
      ],
    },
    {
      code: "STRATEGY_VISION",
      label: I18n.get("STRATEGY & VISION"),
      children: [
        {
          code: "BREAKTHROUGH",
          label: I18n.get(
            "Breakthrough power of the strategy presented probability of effectiveness of the strategy proposed"
          ),
          perc: 10,
        },
        {
          code: "EFFECTIVENESS",
          label: I18n.get(
            "Probability of effectiveness of the strategy proposed"
          ),
          perc: 10,
        },
      ],
    },
    {
      code: "CREATIVE_INNOVATION",
      label: I18n.get("CREATIVE & INNOVATION"),
      children: [
        {
          code: "POWER_BIG_IDEA",
          label: I18n.get(
            "Breakthrough power of the big idea/concept presented"
          ),
          perc: 10,
        },
        {
          code: "EFF_BIG_IDEA",
          label: I18n.get(
            "Probability of effectiveness of big idea and/or concept presented"
          ),
          perc: 10,
        },
        {
          code: "QUALITY",
          label: I18n.get("Quality of creative execution (creative assets)"),
          perc: 5,
        },
        {
          code: "CROSS_CHANNEL",
          label: I18n.get(
            "Level to which the big idea/concept works cross-channel"
          ),
          perc: 5,
        },
      ],
    },
    {
      code: "BUDGET",
      label: I18n.get("BUDGET"),
      children: [
        {
          code: "BUDGET",
          label: I18n.get("Strategic use of budget"),
          perc: 10,
        },
      ],
    },
    {
      code: "TEAM_STRUCTURE",
      label: I18n.get("TEAM & STRUCTURE PROPOSED FOR THE PROJECT"),
      children: [
        {
          code: "CONFIDENCE",
          label: I18n.get(
            "General level of confidence in the team proposed to execute the project"
          ),
          perc: 5,
        },
        { code: "OTHER", label: I18n.get("Outro:") },
      ],

      SAZONAL: [
        { code: "CARNAVAL", label: I18n.get("Carnaval") },
        { code: "DIADASMAES", label: I18n.get("Dia das Mães") },
        { code: "DIADOSPAIS", label: I18n.get("Dia dos Pais") },
        { code: "DIADOSNAMORADOS", label: I18n.get("Dia dos namorados") },
        { code: "PASCOA", label: I18n.get("Páscoa") },
        { code: "INVERNO", label: I18n.get("Inverno") },
        { code: "VERAO", label: I18n.get("Verão") },
      ],

      BANK_IDS: [
        { code: "1", label: "1 - Banco do Brasil S.A." },
        { code: "70", label: "70 - Banco de Brasília S.A." },
        {
          code: "136",
          label:
            "136 - Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil",
        },
        { code: "104", label: "104 - Caixa Econômica Federal" },
        { code: "77", label: "77 - Banco Inter S.A." },
        { code: "741", label: "741 - Banco Ribeirão Preto S.A." },
        { code: "739", label: "739 - Banco Cetelem S.A." },
        { code: "743", label: "743 - Banco Semear S.A." },
        {
          code: "747",
          label: "747 - Banco Rabobank International Brasil S.A.",
        },
        { code: "748", label: "748 - Banco Cooperativo Sicredi S. A." },
        { code: "752", label: "752 - Banco BNP Paribas Brasil S.A." },
        {
          code: "756",
          label: "756 - Banco Cooperativo do Brasil S/A - Bancoob",
        },
        { code: "757", label: "757 - Banco Keb Hana do Brasil S. A." },
        {
          code: "84",
          label: "84 - Uniprime Norte do Paraná - Cooperativa de Crédito Ltda.",
        },
        { code: "62", label: "62 - Hipercard Banco Múltiplo S.A." },
        { code: "74", label: "74 - Banco J. Safra S.A." },
        {
          code: "99",
          label:
            "99 - Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda.",
        },
        { code: "25", label: "25 - Banco Alfa S.A." },
        { code: "63", label: "63 - Banco Bradescard S.A." },
        {
          code: "97",
          label: "97 - Credisis - Central de Cooperativas de Crédito Ltda.",
        },
        { code: "3", label: "3 - Banco da Amazônia S.A." },
        { code: "37", label: "37 - Banco do Estado do Pará S.A." },
        { code: "85", label: "85 - Cooperativa Central de Crédito - Ailos" },
        {
          code: "114",
          label:
            "114 - Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP",
        },
        { code: "36", label: "36 - Banco Bradesco BBI S.A." },
        { code: "394", label: "394 - Banco Bradesco Financiamentos S.A." },
        { code: "4", label: "4 - Banco do Nordeste do Brasil S.A." },
        {
          code: "320",
          label: "320 - China Construction Bank (Brasil) Banco Múltiplo S/A",
        },
        {
          code: "133",
          label:
            "133 - Confederação Nacional das Cooperativas Centrais de Crédito e Economia Familiar",
        },

        { code: "121", label: "121 - Banco Agibank S.A." },
        { code: "274", label: "274 - BMP SCMEPP Ltda" },
        { code: "83", label: "83 - Banco da China Brasil S.A." },
        { code: "94", label: "94 - Banco Finaxis S.A." },
        { code: "47", label: "47 - Banco do Estado de Sergipe S.A." },
        { code: "254", label: "254 - Parana Banco S. A." },
        { code: "107", label: "107 - Banco Bocom BBM S.A." },
        { code: "412", label: "412 - Banco Capital S. A." },
        { code: "124", label: "124 - Banco Woori Bank do Brasil S.A." },
        { code: "389", label: "389 - Banco Mercantil do Brasil S.A." },
        { code: "634", label: "634 - Banco Triângulo S.A." },
        {
          code: "279",
          label: "279 - Cooperativa de Crédito Rural de Primavera do Leste",
        },
        {
          code: "21",
          label: "21 - Banestes S.A. Banco do Estado do Espírito Santo",
        },
        { code: "246", label: "246 - Banco ABC Brasil S.A." },
        { code: "208", label: "208 - Banco BTG Pactual S.A." },
        { code: "746", label: "746 - Banco Modal S.A." },
        { code: "241", label: "241 - Banco Clássico S.A." },
        { code: "612", label: "612 - Banco Guanabara S.A." },
        { code: "604", label: "604 - Banco Industrial do Brasil S.A." },
        { code: "505", label: "505 - Banco Credit Suisse (Brasil) S.A." },
        { code: "300", label: "300 - Banco de la Nacion Argentina" },
        { code: "477", label: "477 - Citibank N.A." },
        { code: "266", label: "266 - Banco Cédula S.A." },
        { code: "122", label: "122 - Banco Bradesco BERJ S.A." },
        { code: "376", label: "376 - Banco J. P. Morgan S. A." },
        { code: "473", label: "473 - Banco Caixa Geral - Brasil S.A." },
        { code: "745", label: "745 - Banco Citibank S.A." },
        { code: "120", label: "120 - Banco Rodobens SA" },
        { code: "265", label: "265 - Banco Fator S.A." },
        { code: "243", label: "243 - Banco Máxima S.A." },
        { code: "17", label: "17 - BNY Mellon Banco S.A." },
        { code: "125", label: "125 - Plural S.A. Banco Múltiplo" },
        { code: "65", label: "65 - Banco AndBank (Brasil) S.A." },
        { code: "213", label: "213 - Banco Arbi S.A." },
        { code: "18", label: "18 - Banco Tricury S.A." },
        { code: "422", label: "422 - Banco Safra S.A." },
        { code: "224", label: "224 - Banco Fibra S.A." },
        { code: "600", label: "600 - Banco Luso Brasileiro S.A." },
        { code: "623", label: "623 - Banco Pan S.A." },
        { code: "204", label: "204 - Banco Bradesco Cartões S.A." },
        { code: "655", label: "655 - Banco Votorantim S.A." },
        { code: "456", label: "456 - Banco MUFG Brasil S.A." },
        { code: "464", label: "464 - Banco Sumitomo Mitsui Brasileiro S.A." },
        { code: "341", label: "341 - Itaú Unibanco S.A." },
        { code: "237", label: "237 - Banco Bradesco S.A." },
        { code: "613", label: "613 - Omni Banco S.A." },
        { code: "652", label: "652 - Itaú Unibanco Holding S.A." },
        { code: "637", label: "637 - Banco Sofisa S. A." },
        { code: "653", label: "653 - Banco Indusval S. A." },
        { code: "69", label: "69 - Banco Crefisa S.A." },
        { code: "249", label: "249 - Banco Investcred Unibanco S.A." },
        { code: "318", label: "318 - Banco BMG S.A." },
        { code: "626", label: "626 - Banco Ficsa S. A." },
        { code: "366", label: "366 - Banco Société Générale Brasil S.A." },
        { code: "611", label: "611 - Banco Paulista S.A." },
        {
          code: "755",
          label: "755 - Bank of America Merrill Lynch Banco Múltiplo S.A.",
        },
        {
          code: "89",
          label: "89 - Cooperativa de Crédito Rural da Região da Mogiana",
        },
        { code: "643", label: "643 - Banco Pine S.A." },
        { code: "707", label: "707 - Banco Daycoval S.A." },
        { code: "487", label: "487 - Deutsche Bank S.A. - Banco Alemão" },
        { code: "633", label: "633 - Banco Rendimento S.A." },
        { code: "218", label: "218 - Banco BS2 S.A." },
        {
          code: "753",
          label: "753 - Novo Banco Continental S.A. - Banco Múltiplo",
        },
        { code: "222", label: "222 - Banco Credit Agrícole Brasil S.A." },
        { code: "281", label: "281 - Cooperativa de Crédito Rural Coopavel" },
        { code: "98", label: "98 - Credialiança Cooperativa de Crédito Rural" },
        { code: "610", label: "610 - Banco VR S.A." },
        { code: "10", label: "10 - Credicoamo Crédito Rural Cooperativa" },
        { code: "33", label: "33 - Banco Santander (Brasil) S. A." },
        { code: "217", label: "217 - Banco John Deere S.A." },
        { code: "41", label: "41 - Banco do Estado do Rio Grande do Sul S.A." },
        { code: "654", label: "654 - Banco A. J. Renner S.A." },
        { code: "212", label: "212 - Banco Original S.A." },
        { code: "260", label: "260 - Banco Nubank (NuConta)" },
        { code: "336", label: "336 - Banco C6" },
      ],

      ASSET_STATUS: [
        { code: "2", label: I18n.get("Criado") },
        { code: "4", label: I18n.get("Aprovado") },
        { code: "6", label: I18n.get("Rejeitado") },
      ],

      PERMISSION_CLIENT: [
        { code: "Client", label: I18n.get("Master") },
        { code: "ClientUserMarketing", label: I18n.get("Marketing") },
        { code: "ClientUserPurchasing", label: I18n.get("Compras") },
      ],

      MENU_REPORT_CLIENT: [
        {
          label: I18n.get("Central de pendências"),
          link: "/cliente/central-de-pendencias",
          activeMenu: 1,
        },
        {
          label: I18n.get("Mkt Place"),
          link: "/cliente/dashboard/detalhado",
          activeMenu: 2,
        },
        evento,
        {
          label: I18n.get("Spending"),
          link: "/cliente/dashboard/savings",
          activeMenu: 4,
        },
      ],

      MENU_REPORT_PARTNER: [
        {
          label: I18n.get("Central de pendências"),
          link: "/parceiro/central-de-pendencias",
          activeMenu: 1,
        },
        {
          label: I18n.get("Mkt Place"),
          link: "/parceiro/dashboard/detalhado",
          activeMenu: 2,
        },
        eventop,
        // { label: I18n.get("Spending"), link: "/parceiro/dashboard/savings", activeMenu: 4 },
      ],

      MENU_PRE_SIGNUP: [
        {
          label: I18n.get("Sobre sua boutique"),
          link: "/parceiro/pre-cadastro/",
          activeMenu: 1,
        },
        {
          label: I18n.get("Especialidades"),
          link: "/parceiro/pre-cadastro/especialidades/",
          activeMenu: 2,
        },
        {
          label: I18n.get("Mercados de expertise"),
          link: "/parceiro/pre-cadastro/expertises/",
          activeMenu: 3,
        },
        {
          label: I18n.get("Trabalhos"),
          link: "/parceiro/pre-cadastro/trabalhos/",
          activeMenu: 4,
        },
        {
          label: I18n.get("Clientes & Referências"),
          link: "/parceiro/pre-cadastro/clientes/",
          activeMenu: 5,
        },
      ],

      MENU_REGISTER: [
        {
          label: I18n.get("Usuários"),
          link: "/parceiro/cadastro/usuarios/",
          activeMenu: 1,
        },
        {
          label: I18n.get("A Empresa"),
          link: "/parceiro/cadastro/empresa/",
          activeMenu: 2,
        },
        {
          label: I18n.get("Especialidades"),
          link: "/parceiro/cadastro/especialidades/",
          activeMenu: 3,
        },
        {
          label: I18n.get("Mercados de expertise"),
          link: "/parceiro/cadastro/expertises/",
          activeMenu: 4,
        },
        {
          label: I18n.get("Trabalhos & Realizações"),
          link: "/parceiro/cadastro/trabalhos/",
          activeMenu: 5,
        },
        {
          label: I18n.get("Liderança"),
          link: "/parceiro/cadastro/lideranca/",
          activeMenu: 6,
        },
        {
          label: I18n.get("Clientes"),
          link: "/parceiro/cadastro/clientes-e-referencias/",
          activeMenu: 7,
        },
      ],

      MENU_REGISTER_CLIENT: [
        {
          label: I18n.get("Sobre o Responsável"),
          link: "/cliente/cadastre-se/responsavel/",
          activeMenu: 1,
        },
        {
          label: I18n.get("Sobre a Empresa"),
          link: "/cliente/cadastre-se/empresa/",
          activeMenu: 2,
        },
        {
          label: I18n.get("Marcas & Usuários"),
          link: "/cliente/cadastre-se/marcas-e-usuarios/",
          activeMenu: 3,
        },
        // { label: I18n.get("Acesso Master"), link: "/cliente/cadastre-se/acesso-master/", activeMenu: 4 }
      ],

      MENU_REGISTER_LEADERS: [
        {
          label: I18n.get("Sobre você"),
          link: "/lider/cadastro/sobre-voce/",
          activeMenu: 1,
        },
        {
          label: I18n.get("Segmentos & Expertises"),
          link: "/lider/cadastro/expertise/",
          activeMenu: 2,
        },
        {
          label: I18n.get("Cases & Prêmios"),
          link: "/lider/cadastro/trabalhos/",
          activeMenu: 3,
        },
      ],

      MENU_REGISTER_REF: [
        {
          label: I18n.get("Sobre você"),
          link: "/referencia/cadastro/sobre-voce",
          activeMenu: 1,
        },
        {
          label: I18n.get("Avaliação"),
          link: "/referencia/cadastro/avaliacao",
          activeMenu: 2,
        },
      ],

      MKT_SIZE: [
        { label: I18n.get("Pequena < 100"), code: "SMALL" },
        { label: I18n.get("Médio >100 até 750"), code: "AVERAGE" },
        { label: I18n.get("Grande >750 até 1500"), code: "BIG" },
        { label: I18n.get("Macro >1500"), code: "MACRP" },
      ],
      MKT_COMPLEXITY: [
        { label: I18n.get("Pequena"), code: "SMALL" },
        { label: I18n.get("Média"), code: "AVERAGE" },
        { label: I18n.get("Grande"), code: "BIG" },
      ],

      MKT_EVENTSTYPE: [
        { label: I18n.get("Corporativos (B2B)"), code: "CORPORATEB2B" },
        { label: I18n.get("Artísticos"), code: "ARTISTIC" },
        { label: I18n.get("Culturais"), code: "CULTURAL" },
        { label: I18n.get("Religiosos"), code: "RELIGIOUS" },
        { label: I18n.get("Esportivos"), code: "SPORTS" },
        { label: I18n.get("Acadêmicos"), code: "ACADEMICS" },
        { label: I18n.get("Turísticos"), code: "TOURISTIC" },
        { label: I18n.get("Marca (B2C)"), code: "BRANDB2C" },
      ],

      MKT_CORPORATETYPE: [
        { label: I18n.get("Workshops"), code: "Workshops" },
        { label: I18n.get("Convenções"), code: "Convencoes" },
        { label: I18n.get("Ferias"), code: "Feiras" },
        { label: I18n.get("Festa corporativa"), code: "Festa corporativa" },
        {
          label: I18n.get("Entrevistas coletiva"),
          code: "Entrevistas coletiva",
        },
        { label: I18n.get("Conferências"), code: "Conferencias" },
        { label: I18n.get("Forum"), code: "Forum" },
        { label: "RH", code: "RH" },
        { label: I18n.get("Evento PR"), code: "Evento PR" },
        { label: I18n.get("Roadshow"), code: "Roadshow" },
      ],

      LABORTYPES: [
        { label: I18n.get("Modelos Eventos"), code: "EVENTOS" },
        { label: I18n.get("Artísticos"), code: "RECEPCIONISTAS" },
        { label: I18n.get("Promotora PDV"), code: "PDV" },
      ],

      LABORSIZE: [
        { label: I18n.get("Pequena < 100"), code: "SMALL" },
        { label: I18n.get("Médio >100 até 750"), code: "AVERAGE" },
        { label: I18n.get("Grande >750 até 1500"), code: "BIG" },
        { label: I18n.get("Macro >1500"), code: "MACRP" },
      ],

      CONTRACTTYPES: [
        { label: I18n.get("Permanente"), code: "PERMANENTE" },
        { label: I18n.get("Temporario"), code: "TEMPORARIO" },
      ],

      MONTHS: [
        { label: "00", id: 0 },
        { label: "01", id: 1 },
        { label: "02", id: 2 },
        { label: "03", id: 3 },
        { label: "04", id: 4 },
        { label: "05", id: 5 },
        { label: "06", id: 6 },
        { label: "07", id: 7 },
        { label: "08", id: 8 },
        { label: "09", id: 9 },
        { label: "10", id: 10 },
        { label: "11", id: 11 },
        { label: "12", id: 12 },
      ],

      YEARS: [
        { label: "00", id: 0 },
        { label: "01", id: 1 },
        { label: "02", id: 2 },
        { label: "03", id: 3 },
        { label: "04", id: 4 },
        { label: "05", id: 5 },
        { label: "06", id: 6 },
        { label: "07", id: 7 },
        { label: "08", id: 8 },
        { label: "09", id: 9 },
        { label: "10", id: 10 },
        { label: "11", id: 11 },
        { label: "12", id: 12 },
        { label: "13", id: 13 },
        { label: "14", id: 14 },
        { label: "15", id: 15 },
        { label: "16", id: 16 },
        { label: "17", id: 17 },
        { label: "18", id: 18 },
        { label: "19", id: 19 },
        { label: "20", id: 20 },
        { label: "21", id: 21 },
        { label: "22", id: 22 },
        { label: "23", id: 23 },
        { label: "24", id: 24 },
        { label: "25", id: 25 },
        { label: "26", id: 26 },
        { label: "27", id: 27 },
        { label: "28", id: 28 },
        { label: "29", id: 29 },
        { label: "30", id: 30 },
      ],

      BPITCH_SCORECARD: [
        {
          code: "SKILLSET",
          label: I18n.get("Balance of team proposed (skill set & seniority)"),
          perc: 5,
        },
      ],
    },
    // {
    //     code: 'PORTFOLIO_PPROFILE', label: I18n.get("Portfólio & Perfil"),
    //     children: [
    //         { code: 'AFFINITY', label: I18n.get("Afinidade com sua marca e/ou categoria"), perc: 5 },
    //         { code: 'EXPERTISE', label: I18n.get("Adequação de expertise ao meu desafio"), perc: 10 },
    //         { code: 'RELEVANCE', label: I18n.get("Relevância dos cases para seu desafio"), perc: 5 },
    //         { code: 'QUALITY', label: I18n.get("Qualidade do perfil e portfólio"), perc: 5 },
    //     ]
    // },
    // {
    //     code: 'TECHNICAL_PROPOSAL', label: I18n.get("Proposta Técnica"),
    //     children: [
    //         { code: 'PLANNING', label: I18n.get("Planejamento estratégico"), perc: 5 },
    //         { code: 'INNOVATION', label: I18n.get("Nível de diferenciação da proposta (inovação & criatividade)"), perc: 10 },
    //         { code: 'IMPLEMENTATION', label: I18n.get("Segurança na implementação"), perc: 5 },
    //         { code: 'PROBABILITY', label: I18n.get("Probabilidade real de resolver o problema / desafio apresentado"), perc: 5 },
    //     ]
    // },
  ],
  CHAT_MESSAGE_TYPE_MEET: "%%MEET:",
  CHAT_MESSAGE_TYPE_FILE: "%%FILE:",
  CHAT_MESSAGE_TYPE_AUDIO: "%%AUDIO:",
  GENERIC_ITEM_TYPE: [
    { label: "Criação", code: "CREATIVE" },
    { label: "Planejamento", code: "PLANNING" },
    { label: "Gerenciamento", code: "MANAGING" },
    { label: "Outro", code: "OTHER" },
  ],
  // https://www.iso.org/obp/ui/#search
  COUNTRY_ISO: CountryIso,
};

export default constants;
