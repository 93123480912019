
import React from "react";
import { DatePicker, ConfigProvider } from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';


function FieldDatePicker(props) {
  return (
    <ConfigProvider direction="rtl" locale={locale}>
        <DatePicker 
            {...props}
        />
    </ConfigProvider>

  );
}

export default FieldDatePicker;