export const colorStatus = (status) => {
  switch (status) {
    case 1:
      // Aguardando NF
      return { color: "#776400" };
    case 2:
      // Aguardando pagamento
      return { color: "#290772" };
    case 3:
      // Faturado
      return { color: "#36694E" };
    case 4:
      // Conta errada
      return { color: "#64370C" };
    default:
      return { color: "#FFF" };
  }
};

export const colorBgStatus = (status) => {
  switch (status) {
    case 1:
      // Aguardando NF
      return { background: "#FFD467" };
    case 2:
      // Aguardando pagamento
      return { background: "#D7E4FF" };
    case 3:
      // Faturado
      return { background: "#C6F1DA" };
    case 4:
      // Conta errada
      return { background: "#FCDEC1" };
    default:
      return { background: "#1883FF" };
  }
};
