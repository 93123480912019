import styled from "styled-components";

export const StyledSpanStatus = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  background: #1883ff;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;

  :focus,
  :active,
  :visited {
    background: #1883ff;
    color: #fff;
  }
  :hover {
    background: #1274e5;
    color: #fff;
  }
`;

export const Text = styled.div``;

export const WrapperExpandDetails = styled.div`
  padding: 30px 0;
`;

export const ContentExpandDetails = styled.div``;

export const TitleExpandDetails = styled.div`
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
`;

export const ResponsibleExpandDetails = styled.div`
  padding: 30px 0 15px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #e7e7e7;

  h3 {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const RatedsExpandDetails = styled.div`
  h3 {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const ItemCol = styled.div`
  margin: 2.5px 0;

  span {
    margin-left: 3.5px;
  }
`;

export const RatingsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const ItemRating = styled.div`
  display: flex;
  align-items: center;
`;

export const TableRating = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    padding: 5px 0;
  }

  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
    height: 50px;
  }

  tr:nth-child(odd) {
    background-color: #f9f9f9;
    height: 50px;
  }
`;
