import React from 'react';

import { I18n } from 'aws-amplify';

const Status = ({ status }) => {

    return (
        <div>

            {status === true && (
                <img title={I18n.get("Item em conformidade")} style={{ width: '1.9rem', height: '1.9rem' }} src="/img/icon-success.svg"></img>
            )}

            {status === false && (
                <img title={I18n.get("Item fora de conformidade")} style={{ width: '1.9rem', height: '1.9rem' }} src="/img/icon-warning.svg"></img>
            )}

            {status === null && <div title={I18n.get("Item ainda não revisado")} style={{ width: '1.9rem', height: '1.9rem', border: 'solid 1px #9684C6', borderRadius: '1rem', padding: '0.8rem 0.8rem' }}> </div>}

        </div>
    );
}

export default Status;