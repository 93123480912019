import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../utils/task';
import { FormUtils } from '../utils/form';

import TextField from './form/text-field';
import CheckboxField from './form/checkbox-field';
import SelectField from './form/select-field';
import TextAreaField from './form/textarea-field';
import MaskedField from './form/masked-field';
import SelectSearchField from './form/select-search-field';

export default class AwardsForm extends React.Component {

    constructor(props) {

        super();

        this.state = {
            form: {
                type: props.type,
                name: {
                    label: I18n.get("Nome do prêmio"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'name'),
                },
                brand: {
                    label: I18n.get("Marca"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'brand'),
                    error: props.errors.brand,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'brand'),
                },
                //category: {
                //    label: 'Categoria',
                //    value: '',
                //    onChange: this.onChangeForm.bind(this, 'category'),
                //    error: props.errors.category,
                //    successIcon: false,
                //    visited: true,
                //    onBlur: this.onBlur.bind(this, 'category'),
                //},
                category: {
                    label: I18n.get("Categoria"),
                    value: '',
                    onChange: this.onChangeSectorId.bind(this),
                    error: props.errors.category,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'category'),
                },
                product: {
                    label: I18n.get("Produto"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'product'),
                    error: props.errors.product,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'product'),
                },
                year: {
                    label: I18n.get("Categoria"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'category'),
                    error: props.errors.category,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'category'),
                },
                year: {
                    label: I18n.get("Ano"),
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'year'),
                    error: props.errors.year,
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'year'),
                    mask: [/\d/, /\d/, /\d/, /\d/],
                    className: 'field is-narrow'
                },
            },
            task: {}
        }

        this.addClick = this.onAddClick.bind(this);
        this.delAward = this.onDelAward.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            this.props.onEditingChange(false);

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);
        }

        this.setState(nextState);
    }

    onAddClick(e) {

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.addAward(this.getData());
        });
    }

    onDelAward(item, e) {
        e.preventDefault();

        const data = Object.assign({}, this.getData(), { id: item.id });

        this.props.delAward(data);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    getData() {

        var data = {
            partnerId: this.props.partnerId,
            leaderId: this.props.leaderId
        };

        Object.keys(this.state.form).forEach((item) => {

            if (FormUtils.isFormField(this.state.form[item])) {

                data[item] = this.state.form[item].value;
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    getAwardCategories() {
        return this.props.bp.awardCategories && this.props.bp.awardCategories.map((item) => ({ code: item.code, label: item.label }));
    }

    onChangeSectorId(value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['category'].value = value;

            return nextState;
        });
    }

    render() {

        return (
            <div>

                <div className="columns columns-no-pad">
                    <div className="column is-5">
                        <TextField {...this.state.form.name} />
                    </div>
                    <div className="column is-2">
                        <MaskedField {...this.state.form.year} />
                    </div>
                    <div className="column is-5">
                        {/*<SelectField {...this.state.form.category} values={this.getAwardCategories()} />*/}
                        <SelectSearchField {...this.state.form.category} values={this.props.bp.sectorsFlat} />
                    </div>
                </div>
                <div className="columns columns-no-pad">
                    <div className="column is-6">
                        <TextField {...this.state.form.product} />
                    </div>
                    <div className="column is-6">
                        <TextField {...this.state.form.brand} />
                    </div>
                </div>

                <div style={{ margin: "60px 0", display: "flex", alignItems: "flex-end", flexDirection: "column"  }} className="field">
                    <button
                        type="button"
                        style={{ margin: 0, fontSize: 12, textTransform: "uppercase" }}
                        onClick={this.addClick}
                        className="button is-leaked">{I18n.get("Salvar Prêmio")}</button>
                </div>
               </div>
        );
    }
}
