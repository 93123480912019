import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { Link, useParams, useHistory } from "react-router-dom";
import Tooltip from "../../../../components/UI/Tooltip";

import { useSelector } from "react-redux";
import { Row, Col, Icon } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { Card } from "../components/Card";
import Filters from "./components/Filters";
import { PROJECTS_API } from "../../../../store/actions/projects";
import { useReview } from '../../../../hooks/useReview/useReview.hook'
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

import Modal from "../../../../components/UI/Modal";

import AssetDetails from "../Step2/components/AssetDetails";

import StepsCreateProject from "../components/StepsCreateProject";
import TopInformation from "../components/TopInformation";
import Main from "../components/Main";
import { Button } from "../../../../components/UI/Button";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import Content from "../../../../components/UI/Content";
import { H2Border } from "../../../../components/UI/Titles";
import { SelectOne } from "../../../../components/UI/SelectOne";
import { Input } from "../../../../components/UI/Input";
import Loading from "../../../../components/UI/Loading";
import ButtonIconLabel from './components/ButtonIconLabel'

import ComboAssets from "./components/ComboAssets";

import { AssetBundle } from "../../../../components/UI/AssetBundle";
import { ConvertValuesCreativeScope } from "../../../../../v2/pages/ProjectsList/Invitations/ProjectDetails/utils/convertValuesCreativeScope";

import personalized from "./components/Filters/svgs/personalized.svg";
import * as S from "./styles";

export const Step2Combos = ({ selectView }) => {
  let params = useParams();
  let history = useHistory();
  const { getAssetsBundleFilters } = useReview();
  const auth = useSelector((state) => state?.auth);
  const [projectDetails, setProjectDetails] = useState({});
  const [assets, setAssets] = useState([]);
  const [assetsCombo, setAssetsCombo] = useState([]);
  const { control } = useForm();
  const { width } = useWindowDimensions();
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [assetDetailsItem, setAssetDetails] = useState({});
  const [addAssetItem, setAddAssetItem] = useState(null);
  const [listOfAssets, setListOfAssets] = useState([]);
  const [dataValuesCreativeScope, setDataValuesCreativeScope] = useState({});
  const [bundleAdded, setBundleAdded] = useState(false);
  const [openModalNoBundle, setOpenModalNoBundle] = useState(false);
  const [listFilterSelect, setListFilterSelect] = useState([]);
  const [listFilterSelectTypes, setListFilterSelectTypes] = useState([]);
  const [filterSelectValue, setFilterSelectValue] = useState("");
  const [filterSelectTypeValue, setFilterSelectTypeValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const [searchValueDebounced] = useDebounce(searchTerm, 1000);

  // DG = "Digital".
  const specialismCodeDefault = "DG";

  let assetsBundle = projectDetails?.bundle?.variations[0]?.assets || [];

  let totalPrice = 0;
  let totalItem = 0;
  let cart = [];

  const calcTotal =
    assetsBundle &&
    assetsBundle.forEach((item) => {
      let index = cart.findIndex((_item) => {
        return _item.asset.id == item.id;
      });

      if (index > -1) {
        cart[index].total++;
      } else {
        cart.push({
          asset: item,
          total: 1,
        });
      }
      totalPrice += item.price;
      totalItem++;
    });

  const itemsToShow = () => {
    // return 3;
    if (width <= 992) {
      return 1;
    } else if (width <= 1200) {
      return 2;
    } else {
      return 3;
    }
  };

  const outerSpacing = () => {
    // return 100;

    if (width <= 425) {
      return 10;
    } else if (width <= 550) {
      return width / 8;
    } else if (width <= 767) {
      return width / 6;
    } else if (width <= 992) {
      return width / 3;
    } else {
      return 100;
    }
  };

  const handleOpenAsset = (asset) => {
    setAssetDetails(asset);
  };

  const addbundle = async (data) => {
    setLoadingAssets(true);
    setBundleAdded(true);

    const sendApi = {
      ...data,
      projectId: params.projectId,
    };

    await PROJECTS_API.add_bundle(sendApi).then((response) => {
      if (response.success) {
        getStep();
      }
    });
  };

  function removeBundle() {
    const data = {
      bundleId: "",
      variationId: "",
    };

    setBundleAdded(false)

    addbundle(data);
  }

  function getAssets(data) {
    setIsLoading(true);

    PROJECTS_API.get_asset_bundle(data).then((response) => {
      setAssets(response.data);
    }).then(() => {
      setIsLoading(false);
    })
  }

  const loadListFiltersSelect = async () => {
    setIsLoadingFilters(true);
    const response = await getAssetsBundleFilters();

    if (response?.success) {
      if (!listFilterSelect?.length) {
        setListFilterSelect([{ code: 'all', label: I18n.get("Todos") }, ...response?.data?.specialisms.sort((a, b) => (a.label > b.label) - (a.label < b.label))]);
      }
      if (!listFilterSelectTypes?.length) {
        setListFilterSelectTypes([{ code: 'all', label: I18n.get("Todos") }, ...response?.data?.assetsTypes.sort((a, b) => (a.label > b.label) - (a.label < b.label))]);
      }
    }

    // Code of filter inicialization.
    setFilterSelectValue(specialismCodeDefault);

    setIsLoadingFilters(false);
  };

  const getStep = () => {
    async function getProject() {
      const data = {
        projectId: params.projectId,
      };
      await PROJECTS_API.get_project_step2(data).then((response) => {
        setProjectDetails(response?.payload?.project?.data);
        if (response?.payload?.project?.data?.bundle?.title === "Pacote customizado") {
          selectView("CART")
        }
        setListOfAssets(response?.payload?.project?.data?.bundle?.variations[0]?.assets);
        const objDataValuesCreativeScope = ConvertValuesCreativeScope({ data: response?.payload?.project?.data, isInvite: false });
        setDataValuesCreativeScope(objDataValuesCreativeScope);

        setLoadingAssets(false);
      });
    }
    getProject();
  };

  let cartArray =
    assetsBundle.map((item) => {
      return item.asset;
    }) || [];

  const handleDeleteItem = async (code) => {
    let indices = [];

    let idx = cartArray.indexOf(code);

    while (idx != -1) {
      indices.push(idx);
      idx = cartArray.indexOf(code, idx + 1);
    }

    let removeItem = indices[0];

    let cartFull = cartArray;

    cartFull.splice(removeItem, 1);

    let cart = cartFull;

    let data = {
      projectId: params.projectId,
      assetsCode: cart,
    };

    setLoadingAssets(true);

    await PROJECTS_API.add_asset(data).then((response) => {
      getStep();
    });
  };

  const handleAddAsset = (code) => {
    let cart = { assetsCode: [...cartArray, code] };

    let data = {
      projectId: params.projectId,
      ...cart,
    };

    setLoadingAssets(true);

    PROJECTS_API.add_asset(data).then((response) => {
      getStep();
    });
  };

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 9999,
      projectId: params.projectId,
      spec: filterSelectValue === "all" ? "" : filterSelectValue,
      type: filterSelectTypeValue === "all" ? "" : filterSelectTypeValue,
      search: searchTerm,
    };

    getAssets(data);
    getStep();
  }, [filterSelectValue, filterSelectTypeValue, searchValueDebounced]);

  useEffect(() => {
    if (projectDetails?.bundleId) {
      getAssetsCombo();
    }
  }, [projectDetails]);

  useEffect(() => {
    loadListFiltersSelect()
  }, []);

  async function getAssetsCombo() {
    const data = {
      projectId: params.projectId,
    };

    await PROJECTS_API.get_assets_combo(data).then((response) => {
      setAssetsCombo(response?.data);
    });
  }

  function getCodeCombo(code) {
    setAddAssetItem(code);
  }

  function renderbundle() {
    return (
      <S.BoxReview>
        <S.RowCenter>
          <S.H4>{projectDetails?.bundle?.title}</S.H4>
          <p>
            {I18n.get("Especialidades")}:{" "}
            {/* {listSpecialism && listSpecialism.join(', ')} */}
          </p>
        </S.RowCenter>
        <S.RowValue>
          <S.H4>{I18n.get("Valor")}</S.H4>
          <S.Value>{projectDetails?.priceClientWithDiscountTotalFmt}</S.Value>
        </S.RowValue>
        <ul>
          <li>
            <strong style={{ fontSize: 12 }}>
              {I18n.get("- Valor Parceiro")}:
            </strong>
            <span style={{ fontSize: 12 }}>
              {projectDetails?.priceClientWithDiscountFmt}
            </span>
          </li>
          <li>
            <strong style={{ fontSize: 12 }}>{I18n.get("- Taxa BPool")}:</strong>
            <span style={{ fontSize: 12 }}>
              {projectDetails?.priceBPoolComissionFmt}
            </span>
          </li>
        </ul>

        <S.ModelsBoxes>
          <ul>
            <li>
              {I18n.get("Modelo")}{" "}
              {projectDetails?.bundle?.variations &&
                projectDetails?.bundle?.variations[0]?.variationType}
            </li>
          </ul>
        </S.ModelsBoxes>

        <S.AddAsset>
          <Row gutter={1}>
            <Col sm={16}>
              <ComboAssets assets={assetsCombo} getCode={getCodeCombo} />
            </Col>
            <Col sm={6}>
              <Button
                text={I18n.get("Adicionar")}
                className=""
                size="small"
                theme="primary"
                loading={loadingAssets}
                isDisabled={!addAssetItem}
                onClick={() => handleAddAsset(addAssetItem)}
              />
            </Col>
          </Row>
        </S.AddAsset>
        <p>{I18n.get("Pacote personalizado contendo 1 asset")}.</p>
        <S.Assets>
          <p>{I18n.get("Itens inclusos")}</p>
          <ul>
            {cart.map((item) => {
              return (
                <li>
                  <p>
                    {item?.asset?.label}
                    <S.ContentTooltip style={{ marginTop: "-10px" }}>
                      <Tooltip
                        textTooltip={item?.asset?.description}
                        placement="rightTop"
                      />
                    </S.ContentTooltip>
                  </p>
                  <strong>
                    <span className="soma">{item?.total} X</span>
                    {item?.asset?.priceTotalFmt}

                    <a onClick={() => handleDeleteItem(item?.asset?.asset)}>
                      <Icon type="delete" theme="filled" />
                    </a>
                  </strong>
                </li>
              );
            })}
          </ul>
        </S.Assets>
        <S.Important>
          <span>{I18n.get("Importante:")}</span>{" "}
          {I18n.get(
            "os prazos estabelecidos nos descritivos são máximos, visando melhor planejamento de recursos e manutenção de preço justo. Não existe um prazo mínimo para conclusão de um projeto, o cronograma é acordado entre as partes."
          )}
        </S.Important>
        <S.Buttons>
          <Button
            onClick={() => removeBundle()}
            loading={loadingAssets}
            text={I18n.get("Remover")}
            size="small"
            padding="0 20px"
            className="duble"
            theme="grey"
          />
        </S.Buttons>
      </S.BoxReview>
    );
  }

  const handleConvertedValuesCreativeScope = async ({ values, currency }) => {
    const data = {
      ...values,
      currency
    }
    const objDataValuesCreativeScope = ConvertValuesCreativeScope({ data, isInvite: false });
    setDataValuesCreativeScope(objDataValuesCreativeScope);

    const listAssetsUpdated = listOfAssets.map(item1 => {
      const matchingItem = values?.bundle?.variationResponse?.assets?.find(item2 => item2.code === item1.code);

      if (matchingItem) {
        return {
          ...item1,
          priceFmt: matchingItem.priceFmt,
          priceTotalFmt: matchingItem.priceTotalFmt
        };
      }

      return item1;
    });
    setListOfAssets(listAssetsUpdated);
  };

  return (
    <>
      <LoggedPage>
        <Content>
          <div className="container">
            <Row>
              <Col sm={12}>
                <H2Border>{I18n.get("Novo Projeto")}</H2Border>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <S.StyleMain>
                  <StepsCreateProject
                    active={2}
                    projectId={params.projectId || ""}
                  />
                </S.StyleMain>

                <Main bgColor="#fff" padding="30px">
                  <TopInformation>
                    <>
                      <h3>
                        {projectDetails?.bundleId && (
                          <>{I18n.get("Pacote selecionado!")}</>
                        )}
                        {!projectDetails?.bundleId && (
                          <>{I18n.get("Selecione o tipo de Projeto")}</>
                        )}
                      </h3>

                      <p>
                        {projectDetails?.bundleId && (
                          <>
                            {I18n.get(
                              "Veja abaixo a composição do pacote. Se preferir customizar os itens, volte e clique em Personalizar Meu Pacote na tela anterior."
                            )}
                          </>
                        )}
                        {!projectDetails?.bundleId && (
                          <>
                            <p>
                              {I18n.get(
                                "Pra facilitar a sua busca, aqui você encontra opções de projetos pré-formatados que podem ser filtrados por tipo e especialidade."
                              )}
                            </p>
                            <br/>

                            <p>
                                {I18n.get(
                                    "Mas você pode ainda clicar em "
                                )}
                                <strong>
                                    {I18n.get("Personalizar meu pacote")}
                                </strong>

                                {I18n.get(" e personalizar seus assets como desejar!")}
                            </p>
                          </>
                        )}
                      </p>
                    </>
                  </TopInformation>

                  {!projectDetails?.bundleId && (
                    <S.FiltersHead>
                      {isLoadingFilters ? (
                        <Row>
                          <Col md={12}>
                            <S.ContentIsLoading style={{ height: 80 }}>
                              <Loading
                                text={I18n.get("Carregando...")}
                                sizeText={14}
                                sizeIcon={16}
                                align="left"
                              />
                            </S.ContentIsLoading>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col md={6}>
                            <SelectOne
                              options={listFilterSelect}
                              value={filterSelectValue || specialismCodeDefault}
                              onChange={(e) => setFilterSelectValue(e)}
                              placeholder={I18n.get("Especialidade")}
                            />
                          </Col>
                          <Col md={6}>
                            <SelectOne
                              options={listFilterSelectTypes}
                              value={filterSelectTypeValue || "all"}
                              onChange={(e) => setFilterSelectTypeValue(e)}
                              placeholder={I18n.get("Tipo")}
                            />
                          </Col>
                          <Col md={6}>
                            <Input
                              placeholder={I18n.get('Buscar assets')}
                              label
                              value={searchTerm}
                              onChange={(value) => setSearchTerm(value)}
                            />
                          </Col>
                          <Col md={6}>
                            <ButtonBpool
                              text={I18n.get("Personalizar meu pacote")}
                              icon={personalized}
                              iconMustRenderInRightSide={true}
                              theme="primary"
                              onClick={() => selectView("CART")}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col lg={24}>
                          <S.Separator />
                        </Col>
                      </Row>
                    </S.FiltersHead>
                  )}

                  {projectDetails?.bundleId && projectDetails?.bundle?.title !== "Pacote customizado" ? (
                    <S.CartBox>
                      <Row>
                        <Col lg={24}>
                          <AssetBundle
                            isInvite={false}
                            disabledEdit={true}
                            removeAll="BUNDLE"
                            listOfAssets={listOfAssets?.length ? listOfAssets : []}
                            dataValues={dataValuesCreativeScope}
                            convertedValues={handleConvertedValuesCreativeScope}
                            reload={getStep}
                            isModalConfirm={true}
                            descriptiveHourMan={projectDetails?.descriptiveHourMan}
                            currencyProject={projectDetails?.currency}
                            isClient={auth?.is_client_or_client_user}
                          />
                        </Col>
                      </Row>
                    </S.CartBox>
                  ) : null}

                  {!projectDetails?.bundleId && (
                    <>
                      {isLoading ? (
                        <S.ContentIsLoading style={{ marginTop: 50 }}>
                          <Loading
                            text={I18n.get("Carregando...")}
                            sizeText={16}
                            sizeIcon={18}
                            align="left"
                          />
                        </S.ContentIsLoading>
                      ) : (
                        assets &&
                        assets.length > 0 &&
                        assets.map((spec, index) => {
                          if (
                            spec?.assetBundles &&
                            spec?.assetBundles.length > 0
                          ) {
                            return (
                              <Row key={index}>
                                <Col lg={24}>
                                  <S.Wrapper style={{ marginTop: 50 }}>
                                    <S.Title windowWidth={width}>
                                      <span>{spec?.label}</span>
                                    </S.Title>
                                    <hr />

                                    <S.Cards>
                                      <S.Carousel
                                        showArrows={false}
                                        itemPadding={[]}
                                        itemsToShow={itemsToShow()}
                                        outerSpacing={outerSpacing()}
                                        preventDefaultTouchmoveEvent
                                      >
                                        {spec?.assetBundles &&
                                          spec?.assetBundles.map(
                                            (card, index) => {
                                              const cardItem = {
                                                titulo: card?.title,
                                                bundleId: card?.bundleId,
                                                modelo: card?.variations.map(
                                                  (variation, index) => {
                                                    return {
                                                      valor:
                                                        variation?.priceFmt,
                                                      prazo: "--",
                                                      nome: variation?.label,
                                                      ativo: true,
                                                      id: variation?.id,
                                                      variationType:
                                                        variation?.variationType,
                                                      descricao:
                                                        variation?.description,
                                                    };
                                                  }
                                                ),
                                                status:
                                                  projectDetails?.bundleId ==
                                                    card?.bundleId
                                                    ? 1
                                                    : 0,
                                              };

                                              return (
                                                <Card
                                                  key={index}
                                                  cardData={cardItem}
                                                  windowWidth={width}
                                                  addbundle={addbundle}
                                                  loading={loadingAssets}
                                                />
                                              );
                                            }
                                          )}
                                      </S.Carousel>
                                    </S.Cards>
                                  </S.Wrapper>
                                </Col>
                              </Row>
                            );
                          }
                        })
                      )}
                    </>
                  )}

                  <Row>
                    <Col lg={24}>
                      <S.FooterButtons className="buttonsContainer">
                        <ButtonBpool
                          text={I18n.get("Voltar")}
                          theme="secondary"
                          onClick={() =>
                            history.push(
                              `/cliente/projetos/v2/criar-projeto/${params.projectId}`
                            )
                          }
                        />
                        {!bundleAdded && !projectDetails?.bundleId ? (
                          <ButtonBpool
                            text={I18n.get("Próximo")}
                            theme="primary"
                            className="next-bt"
                            onClick={() => setOpenModalNoBundle(true)}
                          />
                        ) : (
                          <Link
                            to={`/cliente/projetos/v2/demandas/${params.projectId}`}
                          >
                            <ButtonBpool
                              text={I18n.get("Próximo")}
                              theme="primary"
                              onClick={() => true}
                            />
                          </Link>
                        )}
                      </S.FooterButtons>
                    </Col>
                  </Row>
                </Main>
              </Col>
            </Row>
          </div>
        </Content>
        {assetDetailsItem && Object.keys(assetDetailsItem).length > 0 && (
          <Modal onClickCloseModal={() => setAssetDetails({})}>
            <AssetDetails
              asset={assetDetailsItem}
              addAsset={handleAddAsset}
              loading={loadingAssets}
              close={() => setAssetDetails({})}
            />
          </Modal>
        )}
      </LoggedPage>

      <ModalBp
        visible={openModalNoBundle}
        bodyText={I18n.get("Você realmente quer continuar?")}
        subBodyText={I18n.get(
          "Você não adicionou nenhum pacote. Quer mesmo continuar?"
        )}
        okText={I18n.get("Sim")}
        handleClickOk={() => history.push(
          `/cliente/projetos/v2/demandas/${params.projectId}`
        )}
        cancelText={I18n.get("Cancelar")}
        onCancel={() => setOpenModalNoBundle(false)}
        typeModal="isConfirm"
        isAlert
      />
    </>
  );
};
