import React, { Fragment } from "react";

import moment from "moment";
import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import MaskedField from "../form/masked-field";
import RadioField from "../form/radio-field";
import CheckboxMultiField from "../form/checkbox-multi-field";

import constants from "../../constants";

import { DatePicker } from "../../v2/components/UI/DatePicker";

class ScheduleForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      fields: {
        scheduleType: props.scheduleType,
        scheduleTypeD: {
          label: I18n.get("Data definitiva"),
          name: "scheduleType",
          value: "DATE",
          checked: false,
          onChange: this.onChangeFormRadio.bind(this, "scheduleTypeD"),
          successIcon: false,
        },
        scheduleTypeP: {
          label: I18n.get("Período"),
          name: "scheduleType",
          value: "PERIOD",
          checked: false,
          onChange: this.onChangeFormRadio.bind(this, "scheduleTypeP"),
          successIcon: false,
        },
        scheduleTypeS: {
          label: I18n.get("Sazonal"),
          name: "scheduleType",
          value: "SAZONAL",
          checked: false,
          onChange: this.onChangeFormRadio.bind(this, "scheduleTypeS"),
          successIcon: false,
        },
        scheduleDate: {
          label: I18n.get("Data entrega"),
          mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
          onChange: this.onChangeFormNew.bind(this, "scheduleDate"),
          // onBlur: this.onSave.bind(this, 'scheduleDate'),
          successIcon: false,
          placeholder: I18n.get("DD/MM/AAAA"),
        },
        scheduleStartDate: {
          label: "De",
          mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
          onChange: this.onChangeFormNew.bind(this, "scheduleStartDate"),
          successIcon: false,
          placeholder: I18n.get("DD/MM/AAAA"),
        },
        scheduleEndDate: {
          label: I18n.get("Até"),
          mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
          onChange: this.onChangeFormNew.bind(this, "scheduleEndDate"),
          successIcon: false,
          placeholder: I18n.get("DD/MM/AAAA"),
        },
        timePeriod: {
          label: I18n.get("Escolha o período"),
          value: [],
          onChange: this.onChangeFormCheckboxMulti.bind(this, "timePeriod"),
        },
        timeSeasonal: {
          label: I18n.get("Escolha a data especial"),
          value: [],
          onChange: this.onChangeFormCheckboxMulti.bind(this, "timeSeasonal"),
        },
      },
      errors: {},
      form: {},
      task: {},
      submit_task: {},
    };

    Object.keys(this.state.fields).forEach((item) => {
      this.state.form[item] = props[item];

      if (item == "scheduleType") {
        this.state.form["scheduleTypeD"] = props[item] == "DATE";
        this.state.form["scheduleTypeP"] = props[item] == "PERIOD";
        this.state.form["scheduleTypeS"] = props[item] == "SAZONAL";
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        console.log({ [item]: this.props[item], e: item in this.state.fields });

        if (item in this.state.fields) {
          return; // local state is king
        }

        const value = this.props[item];

        nextState.form[item] = value;
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.save(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormNew(prop, e) {
    const value = e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormRadio(prop, e) {
    const checked = e.target.checked;
    const value = e.target.value;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = checked;
        nextState.errors[prop] = null;

        if (prop == "scheduleTypeD" && checked) {
          nextState.form["scheduleType"] = value;
          nextState.form["scheduleTypeP"] = false;
          nextState.form["scheduleTypeS"] = false;
        }
        if (prop == "scheduleTypeP" && checked) {
          nextState.form["scheduleType"] = value;
          nextState.form["scheduleTypeD"] = false;
          nextState.form["scheduleTypeS"] = false;
        }
        if (prop == "scheduleTypeS" && checked) {
          nextState.form["scheduleType"] = value;
          nextState.form["scheduleTypeP"] = false;
          nextState.form["scheduleTypeD"] = false;
        }

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = checked;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.project,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    if (this.props.isAdmin) return;

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  render() {
    const isDate = this.state.form.scheduleType == "DATE";
    const isPeriod = this.state.form.scheduleType == "PERIOD";
    const isSeasonal = this.state.form.scheduleType == "SAZONAL";
    const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

    //TODO: Find better way... GAMBI!
    let sazonal = [...constants.SAZONAL];
    for (let key in sazonal) {
      sazonal[key].label = I18n.get(sazonal[key].label);
    }

    let eventPeriods = [...constants.EVENT_TIME_PERIODS];
    for (let key in eventPeriods) {
      eventPeriods[key].label = I18n.get(eventPeriods[key].label);
    }

    return (
      <div>
        <div className="radio-left">
          <div className="columns">
            <div className="column is-4">
              <RadioField
                {...this.state.fields.scheduleTypeD}
                checked={isDate}
                error={this.state.errors.scheduleTypeD}
              />
            </div>

            <div className="column is-4">
              <RadioField
                {...this.state.fields.scheduleTypeP}
                checked={isPeriod}
                error={this.state.errors.scheduleTypeP}
              />
            </div>

            <div className="column is-4">
              <RadioField
                {...this.state.fields.scheduleTypeS}
                checked={isSeasonal}
                error={this.state.errors.scheduleTypeS}
              />
            </div>
          </div>
        </div>

        {isDate && (
          //   <MaskedField
          //     {...this.state.fields.scheduleDate}
          //     value={this.state.form.scheduleDate}
          //     error={this.state.errors.scheduleDate}
          //     style={{ width: "50%" }}
          //   />
          <div style={{ width: 160 }}>
            <DatePicker
              value={moment(this.state.form.scheduleDate, "YYYY-MM-DD")}
              defaultValue={
                this.state.form?.scheduleDate
                  ? moment(this.state.form?.scheduleDate, "YYYY-MM-DD")
                  : null
              }
              format={dateFormatList}
              // onChange={onChangeDate}
              onChange={(value) =>
                this.state.fields.scheduleDate.onChange(value)
              }
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }}
              placeholder="__/__/____"
              label={this.state.fields.scheduleDate.label}
              required
            />
          </div>
        )}

        {isPeriod && (
          <div className="field is-horizontal">
            <div className="field-body">
              {/* <MaskedField {...this.state.fields.scheduleStartDate} value={this.state.form.scheduleStartDate} error={this.state.errors.scheduleStartDate} /> */}

              <div style={{ marginRight: 15 }}>
                <DatePicker
                  value={moment(
                    this.state.form.scheduleStartDate,
                    "YYYY-MM-DD"
                  )}
                  defaultValue={
                    this.state.form?.scheduleStartDate
                      ? moment(this.state.form?.scheduleStartDate, "YYYY-MM-DD")
                      : null
                  }
                  format={dateFormatList}
                  // onChange={onChangeDate}
                  onChange={(value) =>
                    this.state.fields.scheduleStartDate.onChange(value)
                  }
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  placeholder="__/__/____"
                  label={this.state.fields.scheduleStartDate.label}
                  required
                />
              </div>

              {/* <MaskedField {...this.state.fields.scheduleEndDate} value={this.state.form.scheduleEndDate} error={this.state.errors.scheduleEndDate} /> */}

              <DatePicker
                value={moment(this.state.form.scheduleEndDate, "YYYY-MM-DD")}
                defaultValue={
                  this.state.form?.scheduleEndDate
                    ? moment(this.state.form?.scheduleEndDate, "YYYY-MM-DD")
                    : null
                }
                format={dateFormatList}
                // onChange={onChangeDate}
                onChange={(value) =>
                  this.state.fields.scheduleEndDate.onChange(value)
                }
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
                placeholder="__/__/____"
                label={this.state.fields.scheduleEndDate.label}
                required
              />
            </div>
          </div>
        )}

        {isSeasonal && (
          <Fragment>
            <CheckboxMultiField
              {...this.state.fields.timeSeasonal}
              value={this.state.form.timeSeasonal}
              error={this.state.errors.timeSeasonal}
              values={sazonal}
            />
          </Fragment>
        )}

        <br />
        <br />

        {!this.props.isCheckout && (
          <CheckboxMultiField
            {...this.state.fields.timePeriod}
            value={this.state.form.timePeriod}
            error={this.state.errors.timePeriod}
            values={eventPeriods}
          />
        )}
      </div>
    );
  }
}

export default ScheduleForm;
