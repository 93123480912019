import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { WIKIS_MAPS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_tutoriais = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            let target = data ? "C" : "P";

            return axios.get(`${Config.API_BASE_URL}/KnowledgeBase?scope=Map`, config)
                .then(response => {

                    const maps = response.data.filter(i => i.scope == "Map" && i.target == target);

                    dispatch({
                        type: WIKIS_MAPS_GET,
                        payload: {
                            maps: maps,
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    });
                })
                .catch(error => {
                    dispatch({
                        type: WIKIS_MAPS_GET,
                        payload: {
                            errors: {},
                            task: {
                                result: false
                            }
                        }
                    })
                });

        }).catch(error => {
            // dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_tutoriais;