import React from "react";
import { I18n } from "aws-amplify";
import Loading from "../../../../../components/UI/Loading";
import { LinksFiles } from "../../../../../components/UI/LinksFiles";
import { FileList } from "../../../../../components/UI/FileUpload/FileList";

import * as S from "../styles";

export const CompletionFiles = ({ isLoading, data }) => {
  const filesCompletion = (files) => {
    return files?.filter((file) => file?.projectCompletionFile === true);
  };

  return (
    <S.BoxItem style={{ marginBottom: 30 }}>
      <S.BoxItemTitle>
        <p>{I18n.get("Arquivos de finalização")}</p>
        <span />
      </S.BoxItemTitle>
      {isLoading ? (
        <Loading
          text={I18n.get("Carregando...")}
          sizeText={14}
          sizeIcon={16}
          color="#000000"
          align="left"
        />
      ) : (
        <>
          <S.Text>
            {I18n.get(
              "No espaço abaixo você encontra todos os documentos enviados como prova para finalização do seu projeto."
            )}
          </S.Text>

          <div>
            <FileList
              noDelete
              files={filesCompletion(data?.files)}
              isLoading={isLoading}
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <LinksFiles
              title={false}
              subTitle={false}
              isLoading={isLoading}
              urlsDefault={data?.links}
              noAdd
              noDelete
            />
          </div>
        </>
      )}
    </S.BoxItem>
  );
};
