import React from "react";
import NumberFormat from "react-number-format";

import * as S from "./styles";

const labelHtml = ({ req, label }) => {
  if (req) {
    return (
      <>
        <span className="required-input">*</span>
        {label}
      </>
    );
  } else {
    return label;
  }
};

const InputMaskCustom = (props) => {
  return (
    <S.InputMaskCustom addonBefore={props?.addonBefore}>
      <input {...props} className={props.error ? "input-error" : ""} />
      <label htmlFor={props.id}>
        {labelHtml({ req: props.required, label: props.label })}
      </label>
      {props.error ? <p className="error-input">{props.error}</p> : null}
    </S.InputMaskCustom>
  );
};

export const InputMask = ({
  id,
  label,
  defaultValue,
  onChange,
  mask,
  required,
  error,
  clearError,
  disabled,
  addonBefore,
  loading,
}) => {
  const changeInput = async (valueInternal) => {
    if (onChange) {
      onChange(valueInternal);
      if (valueInternal?.value !== defaultValue) {
        if (clearError) {
          clearError();
        }
      }
    }
  };

  return (
    <>
      <S.Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-input",
          width: "100%",
        }}
      >
        <S.InputGroup>
          {addonBefore ? addonBefore : null}
          <NumberFormat
            id={id}
            customInput={InputMaskCustom}
            placeholder={label}
            label={label}
            defaultValue={defaultValue}
            value={defaultValue}
            onValueChange={(valueInternal) => changeInput(valueInternal)}
            format={mask}
            mask="_"
            required={required}
            error={error}
            disabled={disabled}
            addonBefore={Boolean(addonBefore)}
          />
        </S.InputGroup>
      </S.Skeleton>
    </>
  );
};
