import React, { Component } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";

import FileUpload from "./FileUploadList";
import { Row, Col, Button, Form } from "antd";

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
    margin-right: 20px;
  }
`;

class UploadFileMacro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: [],
      files: [],
    };
  }

  getDataUploadMult = (files) => {
    this.setState({
      files: files,
    });

    setTimeout(() => {
      // this.sendDataApi();
    }, 1);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          projectId: this.props.projectId,
          milestoneId: this.props.milestoneId,
          files: this.state.files,
        };

        this.props.action(data);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    let filesNormalize =
      this.state.files &&
      this.state.files.map((item) => {
        return {
          ...item,
          uid: item.id,
        };
      });

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row className="">
          <Col sm={24}>
            <Form.Item label={I18n.get("Arquivos")}>
              {getFieldDecorator("file", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
              })(
                <FileUpload
                  getDataUpload={this.getDataUploadMult}
                  name="files"
                  uploadId={this.props.projectId}
                  get2={this.props.get2 || false}
                  fileListGet={filesNormalize || []}
                  // limit={1}
                  output="object"
                />
              )}
            </Form.Item>
          </Col>
          <Col sm={24}>
            <br />
          </Col>

          <Col sm={24}>
            <Button
              style={{ position: "relative", top: "35px" }}
              type="primary"
              shape="round"
              htmlType="submit"
            >
              {I18n.get("Adicionar")}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const UploadFileMacroForm = Form.create({})(UploadFileMacro);

export default UploadFileMacroForm;
