import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { getNameOfUrlLocation } from "../../utils/getNameOfUrlLocation";

import * as S from "./styles";

export const Menu = ({ partnerId }) => {
  let location = useLocation();
  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    if (!locationName) {
      setLocationName(getNameOfUrlLocation(location.pathname));
    }
  }, [location]);

  return (
    <S.Aside className="menu">
      <p class="menu-label" style={{ fontSize: 15, color: "#333333" }}>
        MENU
      </p>
      <ul className="menu-list">
        <li>
          <a
            class={`is-black ${locationName === "partner" ? "active" : ""}`}
            href={`#/bpool/partner/v2/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>1.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Administrative Partner Admin")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${
              locationName === "administrative-tools" ? "active" : ""
            }`}
            href={`#/bpool/partner/v2/administrative-tools/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>2.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Administrative Tools")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${
              locationName === "company-profile" ? "active" : ""
            }`}
            href={`#/bpool/partner/v2/company-profile/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>3.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Company Profile")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${
              locationName === "company-details" ? "active" : ""
            }`}
            href={`#/bpool/partner/v2/company-details/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>4.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Company Details")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${
              locationName === "billing-data" ? "active" : ""
            }`}
            href={`#/bpool/partner/v2/billing-data/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>5.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Billing Data")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${
              locationName === "diversity-and-inclusion" ? "active" : ""
            }`}
            href={`#/bpool/partner/v2/diversity-and-inclusion/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>6.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("D&I Qualification")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${
              locationName === "business-type" ? "active" : ""
            }`}
            href={`#/bpool/partner/v2/business-type/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>7.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Business Type")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${locationName === "industry" ? "active" : ""}`}
            href={`#/bpool/partner/v2/industry/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>8.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Industry")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${
              locationName === "speciality-proof-and-endersement"
                ? "active"
                : ""
            }`}
            href={`#/bpool/partner/v2/speciality-proof-and-endersement/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>9.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Speciality proof & Endersement")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${locationName === "awards" ? "active" : ""}`}
            href={`#/bpool/partner/v2/awards/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>10.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Awards")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${locationName === "customers" ? "active" : ""}`}
            href={`#/bpool/partner/v2/customers/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>11.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Clients")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${
              locationName === "team-registration" ? "active" : ""
            }`}
            href={`#/bpool/partner/v2/team-registration/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>12.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Team Registration")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${locationName === "my-profile" ? "active" : ""}`}
            href={`#/bpool/partner/v2/my-profile/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>13.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("My Profile")}
            </span>
          </a>
        </li>
        <li>
          <a
            class={`is-black ${locationName === "language" ? "active" : ""}`}
            href={`#/bpool/partner/v2/language/${partnerId}`}
          >
            <span style={{ fontWeight: 400 }}>14.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Language")}
            </span>
          </a>
        </li>
      </ul>
    </S.Aside>
  );
};
