import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import PartnerMenu from '../partner/_menu';
import LeaderMenu from '../leader/_menu';
import ClientMenu from '../client/_menu';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class HomePage extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        if (this.props.auth.is_partner)
            return (<PartnerMenu {...this.props} />);

        if (this.props.auth.is_leader)
            return (<LeaderMenu {...this.props} />);

        if (this.props.auth.is_client)
            return (<ClientMenu {...this.props} />);

        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
