import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";
import { notification } from "antd";

import Config from "../../config";

import { BP_PARTNERS_LOGINS_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const reset_user = (data) => {
  return (dispatch, getState) => {
    Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .put(
            `${Config.API_BASE_URL}/bp/logins/reset-user-password`,
            data,
            config
          )

          .then((response) => {
            notification["success"]({
              message: "Senha resetada com sucesso!",
              description: "",
            });
          })
          .catch((error) => {
            notification["error"]({
              message: "Ops! Algo deu errado.",
              description: "",
            });

            throw error;
          });
      })
      .catch((error) => {
        dispatch({ type: EXPIRED });
      });
  };
};

export default reset_user;
