import React from 'react';

import { I18n } from 'aws-amplify';

const Card = (props) => {

    const item = props.item;

    if (!item) return null;

    return (
        <div className="card">
            <div className="card-content">
                <h3 className="title is-4">{I18n.get("Avaliação")}</h3>

                <div className="content">

                    <table className="table is-narrow">
                        <tbody>
                            <tr>
                                <th>{I18n.get("Nome")}</th>
                                <th>{I18n.get("Nota")}</th>
                            </tr>
                                {item.ratings && item.ratings.map((item, index) =>
                                (<tr key={index}>
                                    <td>
                                        {item.label}
                                    </td>
                                    <td>
                                        {item.value}
                                    </td>
                                </tr>)
                            )}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
};

export default Card;