import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import constants from '../../constants';

import { PARTNER_API } from '../../actions/partner/actions';

import SelectSearchField from '../form/select-search-field';

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp, marketExpertise: { errors: {} }, }, state.partner);
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(PARTNER_API.update_specialism(data));
        }
    };
};

const COMPLEXITY = [
    { "label": "Pequena", "code": "SMALL" },
    { "label": "Média", "code": "AVERAGE" },
    { "label": "Grande", "code": "BIG" },
]

class LiveMarketingIncentive extends React.Component {
    constructor(props) {
        super();

        this.state = {
            form: {
                'eventLiveMarketingBrandIncentive.size': {
                    label: I18n.get("Complexibilidade"),
                    value: [],
                    onChange: this.onChangeSize.bind(this),
                    error: props.errors['eventLiveMarketingBrandIncentive.size'],
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'eventLiveMarketingBrandIncentive.size')
                },

                isSubmit: false,
            },
            task: {},
        };

        //this.onDrop = this.onDrop.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                const value = FormUtils.getNestedValue(this.props, item);



                if (FormUtils.isFormField(nextState.form[item])) {

                    nextState.form[item].error = this.props.errors[item];

                    if ('checked' in nextState.form[item]) {
                        nextState.form[item].checked = value;
                    }
                    else {
                        nextState.form[item].value = value;
                    }
                }
                else {
                    nextState.form[item] = value;
                }
            });

            this.setState(nextState);
        }
    }

    onChangeSize(value) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['eventLiveMarketingBrandIncentive.size'].value = value;

            return nextState;
        }, function () {

            var data = this.getData();

            this.onSave(data);
        });
    }

    onBlur(item) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData(isSubmit = false) {

        var data = {
            partnerId: this.props.partnerId,
            specialisms: this.props.specialisms,
            keyServices: this.props.keyServices,
            marketExpertises: this.props.marketExpertises,
            eventLiveMarketing: this.props.eventLiveMarketing,
            eventLiveMarketingBrandExperience: this.props.eventLiveMarketingBrandExperience,
            eventLiveMarketingBrandIncentive: this.props.eventLiveMarketingBrandIncentive,
            eventLiveMarketingEvents: this.props.eventLiveMarketingEvents,
            eventLiveMarketingLabor: this.props.eventLiveMarketingLabor,
            eventLiveMarketingShopper: this.props.eventLiveMarketingShopper,
            eventCat: this.props.eventCat,
            eventSub: this.props.eventSub,
            eventLiveMarketingBrandIncentive: {
                size: this.state.form['eventLiveMarketingBrandIncentive.size'].value
            },
            isSubmit: isSubmit
        };

        return data;
    }

    onSave(item) {



        if (this.props.isAdmin) return;

        // zera o erro
        if (item && typeof this.state.form[item] === "object") {
            this.state.form[item].error = null;
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }


    submitForm(e) {

        const isSubmit = true;

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData(isSubmit));
        });
    }

    render() {

        return (
            <form onSubmit={this.submitForm.bind(this)}>

                <div className="columns">
                    <div className="column is-4">
                        <SelectSearchField {...this.state.form['eventLiveMarketingBrandIncentive.size']} values={COMPLEXITY} isMulti />
                    </div>
                </div>


            </form>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LiveMarketingIncentive);
