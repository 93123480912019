import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Collapse } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { ItemCaseOrAwardsRowChecks } from "./itemCaseOrAwardsRowChecks";

import * as S from "./styles";

const { Panel } = Collapse;

export const ItemCase = ({
  textEmpty,
  data,
  profiles,
  onDelete,
  onEdit,
  typeOfTab,
  addOrRemoveParticipedCasesIds,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    id: null,
  });
  const [participedCasesIds, setParticipedCasesIds] = useState([]);

  useEffect(() => {
    addOrRemoveParticipedCasesIds(participedCasesIds);
  }, [participedCasesIds]);

  const handleDeleteModal = (id) => {
    if (openDeleteModal.open) {
      setOpenDeleteModal({ open: false, id: null });
    } else {
      setOpenDeleteModal({ open: true, id });
    }
  };

  const modalConfirmDelete = () => {
    onDelete(openDeleteModal.id);
    setOpenDeleteModal({ open: false, id: null });
  };

  const onChange = (key) => {
    return;
  };

  const handleEdit = (id) => {
    onEdit(id);
  };

  const genExtra = (id) => (
    <>
      <EditOutlined
        onClick={(event) => {
          event.stopPropagation();
          handleEdit(id);
        }}
      />
      <DeleteOutlined
        style={{ marginLeft: 15 }}
        onClick={(event) => {
          event.stopPropagation();
          handleDeleteModal(id);
        }}
      />
    </>
  );

  const renderInfo = ({ label, value }) => {
    return (
      <S.ItemInfo>
        <S.ItemLabel>{label}</S.ItemLabel>
        <S.ItemValue>{value}</S.ItemValue>
      </S.ItemInfo>
    );
  };

  const renderHeader = ({ type, index, name }) => {
    if (type === 1) {
      return `${I18n.get("Your company case")} ${index} - ${name}`;
    } else {
      return `${I18n.get("Your career case")} ${index} - ${name}`;
    }
  };

  const renderProfilesName = (roles) => {
    if (roles.length) {
      return roles.map((role) => role);
    } else {
      return I18n.get("Not selected");
    }
  };

  const onChangeParticipedCasesIds = (checkParticiped) => {
    if (checkParticiped.checkbox === "yes") {
      setParticipedCasesIds([...participedCasesIds, checkParticiped.id]);
    } else {
      const filteredItems = participedCasesIds.filter(
        (id) => id !== checkParticiped.id
      );
      setParticipedCasesIds(filteredItems);
    }
  };

  const checkDefaults = ({ status, id }) => {
    if (participedCasesIds?.length) {
      const hasClick = participedCasesIds?.find((item) => item === id);
      return Boolean(hasClick);
    } else {
      return status;
    }
  };

  return (
    <>
      <S.WrapperItem>
        {data.length ? (
          <Collapse onChange={onChange}>
            {data.map((item, index) => {
              return (
                <Panel
                  key={item?.id}
                  header={renderHeader({
                    type: item.type,
                    index: index + 1,
                    name: item.companyName,
                  })}
                  extra={typeOfTab === 0 ? null : genExtra(item?.id)}
                >
                  <S.ContentItem>
                    {renderInfo({
                      label: I18n.get("Case name"),
                      value: item?.name ? item?.name : "---",
                    })}
                    {renderInfo({
                      label: I18n.get("Company name"),
                      value: item?.companyName ? item?.companyName : "---",
                    })}
                    {renderInfo({ label: I18n.get("Link"), value: item?.link })}
                    {renderInfo({
                      label: I18n.get("Segment"),
                      value: item?.industryDescription ? item?.industryDescription : "---",
                    })}
                    {renderInfo({
                      label: I18n.get("Brand"),
                      value: item?.brand ? item?.brand : "---",
                    })}
                    {renderInfo({
                      label: I18n.get("Country"),
                      value:
                        item?.countryCode || item?.country
                          ? item?.countryCode || item?.country
                          : "---",
                    })}
                    {renderInfo({
                      label: I18n.get("Year"),
                      value: item?.year ? item?.year : "---",
                    })}

                    {typeOfTab === 0 ? (
                      <>
                        <S.ItemInfo>
                          <S.ItemLabel>
                          {I18n.get('Did you participate in this case?')}
                          </S.ItemLabel>
                          <S.Checkboxes>
                            <ItemCaseOrAwardsRowChecks
                              id={item?.id}
                              checkDefault={checkDefaults({
                                status: item?.participated,
                                id: item?.id,
                              })}
                              onChangeCheck={(check) =>
                                onChangeParticipedCasesIds(check)
                              }
                            />
                          </S.Checkboxes>
                        </S.ItemInfo>
                      </>
                    ) : null}
                  </S.ContentItem>
                </Panel>
              );
            })}
          </Collapse>
        ) : (
          <S.ItemFormEmpty>{textEmpty}</S.ItemFormEmpty>
        )}
      </S.WrapperItem>

      <ModalBp
        visible={openDeleteModal.open}
        bodyText={I18n.get("Do you want to delete?")}
        subBodyText={I18n.get("You will lose this item.")}
        okText={I18n.get("Delete")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={modalConfirmDelete}
        onCancel={handleDeleteModal}
        typeModal="isConfirm"
        isDanger
      />
    </>
  );
};
