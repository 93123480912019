import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { HISTORIC_ITEM_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const api_get_projectextras_item = (data) => {
  return (dispatch, getState) => {
    Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        const month = data.month ? data.month : 1;
        const year = data.year ? data.year : new Date().getFullYear();

        // [HttpGet("/api/[controller]/client/{clientId}/historic/m/{from}/{to}/project/{projectType}")]

        return axios
          .get(
            `${Config.API_BASE_URL}/dashboard/${data.prefix}/totalinstallmentsbymonth/${data?.month}?status=${data.status}`,
            config
          )
          .then((response) => {
            dispatch({
              type: HISTORIC_ITEM_GET,

              payload: {
                historicItemProjectItem: response.data,
                errors: {},
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: HISTORIC_ITEM_GET,
              payload: {
                errors: {},
                task: {
                  result: false,
                },
              },
            });
          });
      })
      .catch((error) => {
        dispatch({ type: EXPIRED });
      });
  };
};

export default api_get_projectextras_item;
