import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import { CLIENT_API } from '../../actions/client/actions';

import TextField from '../form/text-field';
import CheckboxField from '../form/checkbox-field';
import SelectField from '../form/select-field';
import TooltipField from '../form/tooltip-field';
import SelectSearchField from '../form/select-search-field';
import SubmitButtonField from '../form/submit-button-field';

import NumberFormat from 'react-number-format';

import UserCmp from './_user-cmp';

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp }, state.client);
};

const mapDispatchToProps = dispatch => {
    return {
        addUser: data => {
            dispatch(CLIENT_API.create_user(data));
        },
        delUser: data => {
            dispatch(CLIENT_API.delete_user(data));
        },
        submit: data => {
            dispatch(CLIENT_API.update(data));
        },
    };
};


class MasterUsersForm extends React.Component {

    constructor(props) {

        super();
        this.state = {
            clientId: props.clientId
        };

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm(e) {
        e.preventDefault();
        const data = Object.assign({}, this.state, { isSubmit: true });
        this.props.submit(data);
    }

    render() {

        const isAdmin = this.props.isAdmin || false;


        return (<section>

            <UserCmp {...this.props} role="Client"  enableAddSelf={!isAdmin}  />

        </section>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MasterUsersForm);
