import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import constants from '../../../constants';
import { Link } from 'react-router-dom';
import AppWrapperSimple from '../../components/AppWrapperRegister';
import ContainerPage from '../../components/ContainerPage';
import SubHeader from '../../components/SubHeader';

import TableLeaders from '../components/tableLeaders';

import { PARTNER_API } from '../../../actions/partner/actions';

import FormLeaders from '../components/formLeaders';

import { TitleBorder, TitleMiddleLine } from '../../components/Titles';

import styled from 'styled-components';


import {
    Row,
    Col,
    Input,
    Icon,
    Select,
    Button,
    Tooltip,
    Drawer,
    notification
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;



const Main = styled.div`
    padding-top: 50px;
    padding-bottom: 90px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    .BtnAction {
        margin-bottom: 20px;
        text-align: right;
    }

    .RowSection {
        margin-bottom: 60px;
    }
`

const ContentAba = styled.div`
    .BtnAction {
        margin-bottom: 20px;
        text-align: right;
    }
`

const BoxAba = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 30px 20px;
    background: #ececec;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .4s ease;
    border: solid 4px #ececec;
    position: relative;
    .ant-tag {
        position: absolute;
        right: -15px;
        top: -10px;
        font-weight: bold;
    }

    p  {
        cursor: pointer;
    }

    &:hover, &.active {
        background: #dcdcdc;
        border-color: #777;
    }

    .icone {
        margin-bottom: 10px;
        font-size: 40px;
    }
`

const RowAba = styled(Row)`
    margin: 50px 0;
`

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        create_leader: data => {
            dispatch(PARTNER_API.create_leader(data));
        },
        delete_leader: data => {
            dispatch(PARTNER_API.delete_leader(data));
        },
        updateOrderLeader: data => {
            dispatch(PARTNER_API.update_order_leader(data));
        },
        edit_leader: data => {
            dispatch(PARTNER_API.update_leader(data));
        },
        addCase: data => {
            return dispatch(PARTNER_API.create_case(data));
        },
        updateCase: data => {
            return dispatch(PARTNER_API.update_case(data));
        },
        delCase: data => {
            return dispatch(PARTNER_API.delete_case(data));
        },
        upload: data => {
            return PARTNER_API.upload(data);
        }
    };
};





class FullLeaders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            edit: false,
            show: false,
            listImg: [],
            scape: "",
            editSelected: false
        }
    }

    hideLeader = () => {
        this.setState({
            selectedItem: null,
            edit: false,
            show: false
        });
    }

    showAddLeaders = () => {
        this.setState({
            selectedItem: null,
            edit: false,
            show: true
        });
    }

    showEditLeaders = (item) => {
        this.setState({
            selectedItem: item,
            edit: true,
            show: true
        });
    }


    getSector = (sectorId) => {
        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }

    updateLeader = (data) => {
        this.props.edit_leader(data);
    }

    addLeaders = (data) => {
        this.props.create_leader(data);
    }


    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partner.leaders && prevProps.partner.leaders && this.props.partner.leaders.length > prevProps.partner.leaders.length) {

            notification.success({
                message: I18n.get("Adicionado com sucesso!"),
                description: I18n.get('Líder foi adicionado ao seu cadastro'),
                duration: 3,
            });

            this.hideLeader();
        }

        if (this.props.partner.leaders && prevProps.partner.leaders && this.props.partner.leaders.length < prevProps.partner.leaders.length) {

            notification.success({
                message: I18n.get("Removido com sucesso!"),
                description: I18n.get('Líder foi removido do seu cadastro'),
                duration: 3,
            });
        }

        if (this.props.partner.leaders && prevProps.partner.leaders && this.props.partner.leaders != prevProps.partner.leaders && this.state.selectedItem) {

            notification.success({
                message: I18n.get("Atualizado com sucesso!"),
                description: I18n.get('Líder foi atualizado'),
                duration: 3,
            });

            this.setState({
                selectedItem: null
            })

            this.hideLeader();
        }

    }


    render() {
        const { partner } = this.props;

        //TODO: Find better way... GAMBI!
        let menuRegister = [...constants.MENU_REGISTER];
        for (let key in menuRegister) {
            menuRegister[key].label = I18n.get(menuRegister[key].label);
        }

        return (
            <AppWrapperSimple>
                <SubHeader
                    titlePage=""
                    itensMenu={menuRegister}
                    urlId={this.props.match.params.partnerId}
                    activeItem={6}
                />
                <ContainerPage>
                    <Main>
                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Cadastro - Líderes")}</TitleBorder>
                                <p className="descriptionPage">{I18n.get("Ao conhecer os líderes da sua empresa, vamos ter uma visão mais completa do seu negócio em termos de experiência, expertises e outras habilidades. Conhecer o time que lidera e faz acontecer importa muito para nós, para o algoritmo e para os clientes.")}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={24}>
                                <TitleMiddleLine>{I18n.get("Além de você, quem são os líderes do seu negócio")} <Tooltip title={I18n.get("Outros(as) líderes poderão colaborar adicionando suas próprias informações no cadastro. Os(as) profissionais inseridos(as) aqui receberão um email com link assim que você finalizar o processo de cadastro.")}> <Icon type="question-circle" theme="filled" /></Tooltip></TitleMiddleLine>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={24}>
                                <p className="BtnAction">
                                    <Button type="primary" shape="round" onClick={() => this.showAddLeaders()}>{I18n.get("+ Adicionar líder")}</Button>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <TableLeaders
                                    data={this.props.partner.leaders && this.props.partner.leaders}
                                    partnerId={this.props.partner && this.props.partner.partnerId}
                                    order={this.props.updateOrderLeader}
                                    edit={this.showEditLeaders}
                                    delete={this.props.delete_leader}
                                />
                            </Col>
                        </Row>





                        <Row className="RowSection">
                            <Col sm={8}>
                            </Col>
                        </Row>


                        <Row>
                            <Col sm={3}>
                                <Link to={`/parceiro/cadastro/trabalhos/${this.props.match.params.partnerId}`}><Button shape="round">{I18n.get("Voltar")}</Button></Link>
                            </Col>
                            <Col sm={18}>
                            </Col>
                            <Col sm={3}>
                                <Link to={`/parceiro/cadastro/clientes-e-referencias/${this.props.match.params.partnerId}`}><Button type="primary" shape="round">{I18n.get("Avançar")}</Button></Link>
                            </Col>
                        </Row>

                    </Main>
                </ContainerPage>

                <Drawer
                    title={this.state.selectedItem ? I18n.get("Editar líder") : I18n.get("Adicionar líder")}
                    placement="right"
                    forceRender={true}
                    closable={true}
                    onClose={() => this.hideLeader()}
                    destroyOnClose={true}
                    visible={this.state.show}
                    width="40%"
                >
                    <p className="descriptionPage">{I18n.get("Vamos enviar um e-mail para este(a) líder para que ele (a) preencha mais informações.")}</p>
                    <FormLeaders
                        partner={this.props.partner && this.props.partner}
                        partnerId={this.props.match.params.partnerId}
                        action={this.addLeaders}
                        update={this.updateLeader}
                        cancel={() => this.hideLeader()}
                        item={this.state.selectedItem}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        expertise={this.props.bp.marketExpertises && this.props.bp.marketExpertises}
                    />
                </Drawer>


            </AppWrapperSimple>
        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(FullLeaders);
