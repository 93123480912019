import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Rate, message, Icon } from "antd";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import Content from "../../../components/UI/Content";
import Main from "../../../components/UI/Main";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import { H2Border } from "../../../components/UI/Titles";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import { Textarea } from "../../../components/UI/Textarea";
import Loading from "../../../components/UI/Loading";
import { TabsProject } from "../../../components/UI/TabsProject";

import * as S from "./styles";

export const Finalize = () => {
  let params = useParams();
  let history = useHistory();
  const auth = useSelector((state) => state.auth);
  const bp = useSelector((state) => state.bp);
  const { getProjectByIdOld, finalizeProject } = useProjects();
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [ratings, setRatings] = useState([]);
  const [listOfRatings, setListOfRatings] = useState([]);
  const [project, setProject] = useState(null);
  const [inputsForm, setInputsForm] = useState({});
  const [recommendBP, setRecommendBP] = useState("");
  const [recommendPartner2, setRecommendPartner2] = useState("");
  const [finalized, setFinalized] = useState(false);
  const [isReview, setIsReview] = useState({ reviewed: false, ratings: {} });
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState({});

  const handleChangeRate = ({ ratingCode, ratingLabel, ratingValue }) => {
    if (ratingCode === "5") {
      setRecommendBP(String(ratingValue));
    } else if (ratingCode === "4") {
      setRecommendPartner2(String(ratingValue));
    } else {
      const existingRatingIndex = ratings.findIndex(
        (rating) => rating.code === ratingCode
      );

      if (existingRatingIndex === -1) {
        // Se o rating não existe no array, adiciona o novo objeto
        setRatings((prevRatings) => [
          ...prevRatings,
          {
            code: ratingCode,
            label: ratingLabel,
            value: String(ratingValue),
          },
        ]);
      } else {
        // Se o rating já existe no array, atualiza o valor do rating no objeto existente
        setRatings((prevRatings) =>
          prevRatings.map((rating, index) =>
            index === existingRatingIndex
              ? { ...rating, value: String(ratingValue) }
              : rating
          )
        );
      }
    }
  };

  const handleBack = () => {
    const urlPartner = `/parceiro/projetos/v2/${params?.projectId}/in-progress/${params?.extraId}/extras`;
    const urlClient = `/cliente/projetos/v2/${params?.projectId}/in-progress/extras`;

    history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
  };

  const loadInit = async () => {
    setIsLoading(true);
    try {
      const responseProject = await getProjectByIdOld({
        projectId: params?.projectId,
      });

      if (responseProject?.review) {
        setIsReview({ reviewed: true, ratings: responseProject?.review });
        setRatings(responseProject?.review?.ratings);
        setRecommendBP(String(responseProject?.review?.recommendBP));
        setRecommendPartner2(
          String(responseProject?.review?.recommendPartner2)
        );
      }
    } catch (error) {
      console.log("error", error?.response);
    }
    setIsLoading(false);
  };

  const whatValueRating = (code) => {
    const finded = ratings?.find((item) => item?.code === code);

    if (finded) {
      return finded?.value;
    } else {
      return null;
    }
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const send = async () => {
    setIsSending(true);

    try {
      const objSend = {
        projectId: params?.projectId,
        partnerReview: inputsForm?.reviewPartner || "",
        ratings,
        recommendBP,
        recommendPartner2,
      };
      const response = await finalizeProject(objSend);

      if (response?.status === 200) {
        setFinalized(true);
      } else {
        setFinalized(true);
      }
    } catch (error) {
      setError(error?.response?.data?.partnerReview);
    }

    setIsSending(false);
  };

  const backToProjects = () => {
    const urlPartner = `/parceiro/projetos/v2`;
    const urlClient = `/cliente/projetos/v2`;

    history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
  };

  useEffect(() => {
    loadInit();
  }, []);

  useEffect(() => {
    if (shouldDisableFinalize() && recommendPartner2 && recommendBP) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [ratings, recommendPartner2, recommendBP]);

  const shouldDisableFinalize = () => {
    let shouldBeDisabled = false;

    // Verifica se ratings existe e tem pelo menos o total de opções para avaliar o projeto.
    if (ratings?.length === bp?.clientProjectCheckpointRatings?.length) {
      shouldBeDisabled = true;
    } else {
      console.log(
        `Status Finalização: Ação de \"Finalizar\" bloqueado, pois é necessário preencher as avaliações.`
      );
    }

    return shouldBeDisabled;
  };

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Projeto em andamento")}</H2Border>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <TabsProject context="finalized" page="finalize" />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                {isLoading ? null : (
                  <Row>
                    <Col xs={24} sm={24}>
                      <S.TitleIcon>
                        <p>
                          <svg
                            width="80"
                            height="80"
                            viewBox="0 0 80 80"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="40" cy="40" r="40" fill="#1883FF" />
                            <circle cx="40" cy="40" r="40" stroke="white" />
                            <path
                              d="M22.1277 42.3086L33.1834 52.7947L57.3899 29.2483"
                              stroke="white"
                              stroke-width="5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </p>

                        <div>
                          <S.H4>
                            {isReview?.reviewed || finalized
                              ? I18n.get("Projeto finalizado")
                              : I18n.get("Finalizar projeto")}
                          </S.H4>

                          {isReview?.reviewed || finalized ? (
                            <p>
                              <strong>
                                {I18n.get(
                                  "Parabéns, seu projeto foi finalizado."
                                )}
                              </strong>
                            </p>
                          ) : (
                            <p>
                              {I18n.get(
                                "Por favor, avalie abaixo a sua experiência neste projeto."
                              )}
                            </p>
                          )}
                        </div>
                      </S.TitleIcon>
                    </Col>
                  </Row>
                )}
                {!finalized ? (
                  <Row>
                    {isLoading ? (
                      <Col xs={24} sm={24}>
                        <S.ContentIsLoading>
                          <Loading
                            text={I18n.get("Loading informations...")}
                            sizeText={14}
                            sizeIcon={16}
                          />
                        </S.ContentIsLoading>
                      </Col>
                    ) : (
                      <>
                        {bp?.clientProjectCheckpointRatings?.map(
                          (item, index) => (
                            <Col key={index} sm={24}>
                              <S.Content>
                                <S.ItemNumber>
                                  <S.Number>
                                    <span>{index + 1}</span>
                                  </S.Number>
                                  <S.Text>{item?.label}</S.Text>
                                </S.ItemNumber>
                                <S.ContentRate>
                                  <Rate
                                    value={whatValueRating(item?.code)}
                                    onChange={(value) =>
                                      handleChangeRate({
                                        ratingCode: item?.code,
                                        ratingLabel: item?.label,
                                        ratingValue: value,
                                      })
                                    }
                                    disabled={isReview?.reviewed || finalized}
                                  />{" "}
                                </S.ContentRate>
                              </S.Content>
                            </Col>
                          )
                        )}
                        <Col key={4} sm={24}>
                          <S.Content>
                            <S.ItemNumber>
                              <S.Number>
                                <span>
                                  {bp?.clientProjectCheckpointRatings?.length +
                                    1}
                                </span>
                              </S.Number>
                              <S.Text>
                                {I18n.get(
                                  "Em uma escala de 1 a 5, avalie a probabilidade de você recomendar a agência para outras marcas?"
                                )}
                              </S.Text>
                            </S.ItemNumber>
                            <S.Text></S.Text>
                            <S.ContentRate>
                              <Rate
                                value={recommendPartner2}
                                onChange={(value) =>
                                  handleChangeRate({
                                    ratingCode: "4",
                                    ratingLabel: I18n.get(
                                      "Em uma escala de 1 a 5, avalie a probabilidade de você recomendar a agência para outras marcas?"
                                    ),
                                    ratingValue: value,
                                  })
                                }
                                disabled={isReview?.reviewed || finalized}
                              />{" "}
                            </S.ContentRate>
                          </S.Content>
                        </Col>
                        <Col key={5} sm={24}>
                          <S.Content>
                            <S.ItemNumber>
                              <S.Number>
                                <span>
                                  {bp?.clientProjectCheckpointRatings?.length +
                                    2}
                                </span>
                              </S.Number>
                              <S.Text>
                                {" "}
                                {I18n.get(
                                  "Em uma escala de 1 a 5, avalie a probabilidade de você recomendar a BPool para outras marcas?"
                                )}
                              </S.Text>
                            </S.ItemNumber>

                            <S.ContentRate>
                              <Rate
                                value={recommendBP}
                                onChange={(value) =>
                                  handleChangeRate({
                                    ratingCode: "5",
                                    ratingLabel: I18n.get(
                                      "Em uma escala de 1 a 5, avalie a probabilidade de você recomendar a BPool para outras marcas?"
                                    ),
                                    ratingValue: value,
                                  })
                                }
                                disabled={isReview?.reviewed || finalized}
                              />{" "}
                            </S.ContentRate>
                          </S.Content>
                        </Col>
                        <Col xs={24} sm={24} style={{ marginTop: 50 }}>
                          {isReview?.reviewed ? (
                            <S.ReviewAboutPartner>
                              <p className="title">
                                {I18n.get("Review sobre o Parceiro")}
                              </p>
                              <p>{isReview?.ratings?.partnerReview}</p>
                            </S.ReviewAboutPartner>
                          ) : (
                            <Textarea
                              id="reviewPartner"
                              label={I18n.get(
                                "Faça um breve review do Parceiro"
                              )}
                              rows={4}
                              maxLength={500}
                              counterChar
                              defaultValue={inputsForm.reviewPartner}
                              onChange={(value) =>
                                onChangeInputForm("reviewPartner", value)
                              }
                              disabled={isReview?.reviewed || finalized}
                              error={error?.errorMessage}
                              clearError={() => setError({})}
                              required
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                ) : (
                  <Row>
                    <Col xs={24} sm={24}>
                      <S.FinalizedText>
                        <div style={{ marginTop: 30 }}>
                          <span>
                            {I18n.get(
                              "Obrigado por utilizar a plataforma BPool."
                            )}
                          </span>
                        </div>
                      </S.FinalizedText>
                    </Col>
                    <Col xs={24} sm={24} style={{ marginTop: 50 }}>
                      <ButtonBpool
                        text={I18n.get("Back to Projects")}
                        theme="primary"
                        onClick={backToProjects}
                      />
                    </Col>
                  </Row>
                )}

                {isLoading ? null : !finalized ? (
                  <Row>
                    <Col xs={24} sm={24}>
                      <S.ButtonsFooter>
                        <ButtonBpool
                          text={I18n.get("Back")}
                          theme="secondary"
                          onClick={handleBack}
                        />
                        {isReview?.reviewed ? null : (
                          <ButtonBpool
                            text={I18n.get("Finalize")}
                            theme="primary"
                            onClick={send}
                            disabled={isDisabled}
                            loading={isSending}
                          />
                        )}
                      </S.ButtonsFooter>
                    </Col>
                  </Row>
                ) : null}
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
