/// <reference path="../form/textarea-field.js" />
import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { REFERENCE_API } from '../../actions/reference/actions';

import LandingPage from '../page-templates/landing';

import LoadingPage from '../pages/loading';

import StarReview from '../form/star-review';
import TextAreaField from '../form/textarea-field';

const mapStateToProps = state => {
    return state.reference;
};

const mapDispatchToProps = dispatch => {
    return {
        get_reference: token => {
            dispatch(REFERENCE_API.get(token));
        },
        save: data => {
            dispatch(REFERENCE_API.update(data));
        }
    };
};

class ReviewForm extends React.Component {
    constructor(props) {

        super();
        this.state = {
            token: props.verificationToken,
            comment: '',
            ratings: props.ratings || []
        };

        this.changeReview = this.onChangeReview.bind(this);
    }

    componentDidMount() {
    }

    onChangeReview(index, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            const current = Object.assign({}, prevState);

            current.ratings[index] = this.props.ratings[index];
            current.ratings[index].value = value;

            return current;

        });

    }

    onChangeComment(e) {
        this.setState({ comment: e.target.value });
    }

    submitForm(e) {
        e.preventDefault();
        const data = Object.assign({}, this.state, { isSubmit: true });
        this.props.save(data);
    }

    render() {

        return (
            <form onSubmit={this.submitForm.bind(this)}>

                                {this.props.ratings && this.props.ratings.map((item, index) =>

                                    <StarReview
                                        key={item.code}
                                        id={index}
                                        label={item.label}
                                        onChange={this.changeReview}
                                        selected={this.state.ratings[index] && this.state.ratings[index].value}
                                    />

                                )}
                                <div 
                                    className="reference-textarea"
                                >
                                    <TextAreaField
                                        label={I18n.get("Quer deixar algum comentário?")}
                                        value={this.state.comment}
                                        onChange={this.onChangeComment.bind(this)}
                                    />
                                </div>

                        </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReviewForm);
