import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import {
    Table,
    Icon,
    Checkbox
} from 'antd';

import ItemCase from './ItemCase'

const Btns = styled.p`
width: 50px;
display: block;
&.checkItem {
    width: 100px;
}
    a {
        margin-left: 10px;
    }
`

const closest = function (el, selector, rootNode) {
    rootNode = rootNode || document.body;
    const matchesSelector =
        el.matches ||
        el.webkitMatchesSelector ||
        el.mozMatchesSelector ||
        el.msMatchesSelector;

    while (el) {
        const flagRoot = el === rootNode;

        if (flagRoot || matchesSelector.call(el, selector)) {
            if (flagRoot) {
                el = null;

            }

            break;
        }
        el = el.parentElement;

    }

    el.setAttribute('style', 'border: 50px solid red;');
    return el;
};

class tableLeader extends PureComponent {
    constructor(props) {
        super(props);


        this.onMouseDown = this.onMouseDown.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);


        this.state = {
            data: [],
            dragIndex: -1,
            draggedIndex: -1,
            loaddingCases: false
        };

        this.columns = [
            {
                title: "",
                key: 'operate',
                width: "50px",
                render: (text, record, index) =>
                    <span>
                        {(this.state.dragIndex >= 0 &&
                            this.state.dragIndex !== this.state.draggedIndex &&
                            index === this.state.draggedIndex &&
                            <span
                                className={`drag-target-line ${this.state.draggedIndex <
                                    this.state.dragIndex
                                    ? 'drag-target-top'
                                    : ''}`}
                            />) ||
                            ''}
                        <a
                            className="drag-handle"
                            draggable="false"
                            onMouseDown={this.onMouseDown}
                        >
                            <Icon type="menu" />
                        </a>
                    </span>,
            },
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: I18n.get("E-mail"),
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: I18n.get("Função"),
                dataIndex: 'funcao',
                key: 'funcao',
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'item',
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                        partnerId: this.props.partnerId,
                    }


                    return (
                        <Btns>
                            <a onClick={() => this.props.edit(record.item)}>
                                <Icon type="edit" theme="filled" />
                            </a>
                            <a onClick={() => this.props.delete(data)}>
                                <Icon type="delete" theme="filled" />
                            </a>
                        </Btns>
                    )
                }
            },
        ]
    }


    isChecked(caseId) {

        if (!this.props.dataLeader) return false;

        const items = this.props.dataLeader.filter((item) => item.type == 1 && item.id == caseId);

        return items && items.length;
    }

    onMouseDown(e) {
        const target = this.getTrNode(e.target);
        if (target) {
            target.setAttribute('draggable', true);
            target.ondragstart = this.onDragStart;
            target.ondragend = this.onDragEnd;
        }
    }

    onDragStart(e) {
        const target = this.getTrNode(e.target);
        if (target) {

            e.dataTransfer.effectAllowed = 'move';
            target.parentElement.ondragenter = this.onDragEnter;
            target.parentElement.ondragover = function (ev) {

                ev.preventDefault();
                return true;
            };
            const dragIndex = target.rowIndex - 1;
            this.setState({ dragIndex, draggedIndex: dragIndex });
        }
    }

    onDragEnter(e) {
        const target = this.getTrNode(e.target);
        this.setState({
            draggedIndex: target ? target.rowIndex - 1 : -1,
        });
    }

    onDragEnd(e) {
        const target = this.getTrNode(e.target);
        if (target) {
            target.setAttribute('draggable', false);
            target.ondragstart = null;
            target.ondragend = null;
            target.parentElement.ondragenter = null;
            target.parentElement.ondragover = null;
            this.changeRowIndex();
        }
    }

    getTrNode(target) {

        return closest(target, 'tr');
    }

    changeRowIndex = () => {
        const result = {};
        const currentState = this.state;
        result.dragIndex = result.draggedIndex = -1;
        if (
            currentState.dragIndex >= 0 &&
            currentState.dragIndex !== currentState.draggedIndex
        ) {
            const { dragIndex, draggedIndex, data: oldData } = currentState;
            const data = [...oldData];
            //       const data = oldData;
            const item = data.splice(dragIndex, 1)[0];
            data.splice(draggedIndex, 0, item);
            result.data = data;
            result.dragIndex = -1;
            result.draggedIndex = -1;
        }

        let list = result.data.map((item, index) => {
            return {
                order: index,
                leaderId: item.item.leaderId
            }
        });

        const dataApi = {
            partnerId: this.props.partnerId,
            orderLeader: list
        }

        this.setState(result);

        if (!this.props.isLeader) {
            this.props.order(dataApi);
        }


    }

    componentDidMount = () => {
        this.setState({
            loaddingCases: true
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.data != prevProps.data || this.state.loaddingCases != prevState.loaddingCases && this.props.data) {


            let dataLeaders = this.props.data && this.props.data.map((item, index) => {
                return {
                    key: index,
                    name: item.name,
                    email: item.email,
                    funcao: item.expertise.label,
                    order: item.order,
                    item: item
                }
            });

            this.setState({
                data: dataLeaders.sort(function (a, b) {
                    return a.order - b.order;
                })
            })
        }
    }

    render() {
        return (
            <Table
                className={(this.state.dragIndex >= 0 && 'dragging-container') || ''}
                columns={this.columns}
                dataSource={this.state.data}
                locale={{ emptyText: I18n.get('Sem informação') }}
                scroll={{ x: 640 }}
            />
        );
    }
}

export default tableLeader;
