import React from "react";
import { I18n } from "aws-amplify";
import { NavLink } from "react-router-dom";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";

import CheckoutForm from "./checkout-form";

import ProjectReview from "../shared/_project-review";

import SubHeader from "./__sub-header";

import { scrollToTop } from "../../utils/ui";

import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";

class NewProjectStep4 extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    const logo =
      this.props.client &&
      this.props.client.company &&
      this.props.client.company.logo;

    const {
      isCreative,
      isEvent,
      isBPitch,
      customEvent = {},
      bPitch = {},
      isCustomEvent,
    } = this.props;

    const subHeaderProps = {
      isCreative,
      isEvent,
      isBPitch,
      customEvent,
      bPitch,
      isCustomEvent,
    };

    let step = 0;

    if (this.props.isCustomEvent && customEvent.isDigital === true) {
      step = 3;
    } else if (this.props.isCustomEvent && customEvent.isDigital === false) {
      step = 4;
    } else {
      step = 5;
    }

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-4">
          <SubHeader
            projectId={this.props.match.params.projectId}
            active={step}
            logo={logo}
            {...{
              isCreative,
              isEvent,
              isBPitch,
              customEvent,
              bPitch,
              isCustomEvent,
            }}
          />
        </div>
        <div className="client-step client-step-1 review-bg uplift">
          <div className="container client-container-1 uplift-step-4">
            <div className="columns">
              <div className="column is-4">
                <CheckoutForm {...this.props} isCheckout />

                <br />
                <br />

                <div className="field">
                  <p
                    className="control is-expanded"
                    onClick={(e) => scrollToTop}
                  >
                    <NavLink
                      to={`/cliente/projetos/${
                        this.props.match.params.projectId
                      }/passo-${step - 1}`}
                      style={{ float: "left" }}
                    >
                      <ButtonBpool
                        text={I18n.get("Voltar")}
                        theme="secondary"
                      />
                    </NavLink>
                    <NavLink
                      to={`/cliente/projetos/${
                        this.props.match.params.projectId
                      }/passo-${step + 1}`}
                      className="pull-right"
                    >
                      <ButtonBpool
                        text={I18n.get("Próximo")}
                        className=""
                        theme="primary"
                        loading={this.state.disabledBtn}
                      />
                    </NavLink>
                  </p>
                </div>
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 is-offset-1">
                <ProjectReview
                  {...this.props}
                  {...this.props.project}
                  step={4}
                />
              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(NewProjectStep4);
