import React, { useState } from 'react';
import moment from 'moment';

import { I18n } from 'aws-amplify';

import {
    Table,
    Popconfirm,
    notification,
    DatePicker,
    Button,
    Checkbox,
    Tooltip,
    Icon,
    Tag
} from 'antd';

const dateFormat = 'DD/MM/YYYY';

export const PaymentDateForm = ({ invoice = {}, update_billing_installment_invoice_payment_date, onDismiss }) => {

    //const initialPaymentDate = invoice.paymentDate ?? moment().format(dateFormat);
    const initialPaymentDate = invoice.paymentDueDate ?? moment().format(dateFormat);


    const [paymentDate, setPaymentDate] = useState(initialPaymentDate);
    const [busy, setBusy] = useState(false);

    const onSave = (e, data) => {

        e.preventDefault();

        setBusy(true);

        update_billing_installment_invoice_payment_date(data).then((response) => {

            setBusy(false);
            
            notification.success({
                message: I18n.get("Atualizado com sucesso!"),
            });

            onDismiss();

        }).catch(error => {

            setBusy(false);
            
            if(Object.keys(error?.response?.data).length > 0){
                
                Object.keys(error?.response?.data).map(item => {
                    notification.error({
                        message: I18n.get("Ops! Algo deu errado."),
                        description: error?.response?.data[item].errorMessage,
                      });

                });
            }
        });

        return false;
    }

    return (
        <form style={{ display: 'inline-flex', padding: '0.25rem' }}>

            <DatePicker size='small' defaultValue={moment(paymentDate, dateFormat)} format={dateFormat} onChange={(date, dateString) => setPaymentDate(dateString)} />

            <Button onClick={(e) => onSave(e, { ...invoice, paymentDate })} loading={busy} className="button bt-bpool black" style={{ marginLeft: '0.25rem' }}>
                {I18n.get("Salvar")}
            </Button>

            <div style={{ padding: '0.5rem', fontSize: '90%' }}>

                <a onClick={e => onDismiss()}>{I18n.get('Cancelar')}</a>

            </div>
        
        </form>
    );
}
