import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { BP_PARTNERS_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const api_vips = () => {
  return (dispatch, getState) => {
    Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .get(`${Config.API_BASE_URL}/v2/Admin/Partner/vip`, config)
          .then((response) => {
            dispatch({
              type: BP_PARTNERS_GET,
              payload: {
                vipList: response.data,
              },
            });
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default api_vips;
