import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import CheckboxMultiField from '../form/checkbox-multi-field';
import TextAreaField from '../form/textarea-field';
import SubmitButtonField from '../form/submit-button-field';

import { scrollToTop } from '../../utils/ui';

class PartnerMessagingForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            fields: {
                recipient: {
                    label: I18n.get("Envie mensagem para os Parceiros Criativos"),
                    error: props.errors.recipient,
                    onChange: this.onChangeFormCheckboxMulti.bind(this, 'recipient'),
                },
                body: {
                    label: I18n.get("Mensagem"),
                    successIcon: true,
                    onChange: this.onChangeForm.bind(this, 'body'),
                    visited: true
                }
            },
            errors: {},
            form: {
                recipient: []
            },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }


    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;
            nextState.feedback = null;

            return nextState;
        });
    }

    onChangeFormCheckboxMulti(prop, e) {

        const value = e.target.value;
        const checked = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (checked) {
                nextState.form[prop] = [...nextState.form[prop], value];
            }
            else {
                nextState.form[prop] = nextState.form[prop].filter((v) => v !== value);
            }

            nextState.errors[prop] = null;
            nextState.feedback = null;

            return nextState;
        });
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.state.form
        };

        return data;
    }

    submitForm() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task, feedback: '' });

        this.props.sendPartnerMessage(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors, feedback: 'Mensagem enviada.' });

                setTimeout(() => {
                    this.setState({ feedback: null }, () => this.props.onClose());
                }, 2000);
            });
    }

    render() {

        if (this.state.feedback) {
            return (<div className="for-np has-text-left" style={{ backgroundColor: '#eeeeee', padding: '2rem', border: 'solid 1px #000000' }}>
                {this.state.feedback}
            </div>);
        }

        const recipientValues = this.props.partners?.length ? this.props.partners.map((partner) => ({ code: partner.id, label: partner.company.name })) : []

        return (
            <div className="for-np has-text-left" style={{ backgroundColor: '#eeeeee', padding: '2rem', border: 'solid 1px #000000' }}>

                <CheckboxMultiField {...this.state.fields.recipient} value={this.state.form.recipient} error={this.state.errors.recipient} values={recipientValues} />

                <TextAreaField {...this.state.fields.body} value={this.state.form.body} error={this.state.errors.body} />

                <p style={{ padding: '1rem' }}>{this.state.feedback}</p>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                    <SubmitButtonField
                        label={I18n.get("ENVIAR")}
                        onClick={this.submitForm.bind(this)}
                        task={this.state.submit_task}
                    />

                </div>
            </div>
        );
    }
}

export default PartnerMessagingForm;
