import React, { Fragment } from 'react';

import { I18n } from 'aws-amplify';

import './_project-details-eventCustom.css';

import constants from '../constants';

import { BPitchType, BPitchTypeNames, BPitchScopeType, BPitchScopeTypeNames, BPCommissionType, BPCompensationType } from '../model';

import NumberFormat from 'react-number-format';

import { Collapse } from 'antd';

import { CardBorder } from '../modules/components/Cards';

import FormatCurrency from './ui/format-currency';

const { Panel } = Collapse;

class BPitchDetails extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {

        const { bPitch = {}, bpCommission = {}, currency = {}, defaultActiveKey = [1] } = this.props;

        const { commissionType, perc, flat, compensationType, compensation2ndPerc, compensation2ndFlat, compensation3rdPerc, compensation3rdFlat } = bpCommission || {};

        return (
            <div>
                <CardBorder>
                    <Collapse defaultActiveKey={defaultActiveKey}>
                        <Panel header={I18n.get("Escopo do Pitch")} key="1">
                            <p className="prop">
                                <span className="name">{I18n.get("Formato do pitch:")}</span>
                                <span className="value">
                                    {I18n.get(BPitchTypeNames[bPitch.pitchType])}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Escopo Predominante:")}</span>
                                <div className="value">
                                    {bPitch.pitchScopeType && bPitch.pitchScopeType.map((item) => {
                                        return (<div> {I18n.get(BPitchScopeTypeNames[item])}</div>);
                                    })}
                                </div>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Nome do Pitch:")}</span>
                                <span className="value">
                                    {bPitch.pitchName}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Marca:")}</span>
                                <span className="value">
                                    {bPitch.pitchName}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Produto / Serviço:")}</span>
                                <span className="value">
                                    {bPitch.pitchName}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Descrição:")}</span>
                                <span className="value">
                                    {bPitch.description}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Segmento:")}</span>
                                <span className="value">
                                    {bPitch.pitchSegment}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Budget:")}</span>
                                <span className="value">
                                    {bPitch.budget && <FormatCurrency amount={bPitch.budget} currency={currency} />}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Budget Split:")}</span>
                                <div className="value">
                                    {bPitch.budgetItems && bPitch.budgetItems.map((item) => {
                                        return (<div> {item && item.name}: {item && item.perc}%</div>);
                                    })}
                                </div>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Platform Fee:")}</span>
                                <span className="value">
                                    {commissionType == BPCommissionType.Perc && <>
                                        {perc}%
                                    </>}
                                    {commissionType == BPCommissionType.Flat && <>
                                        <FormatCurrency amount={flat} currency={currency} />
                                    </>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Remuneração Custo 2ª Lugar:")}</span>
                                <span className="value">
                                    {compensationType == BPCompensationType.Perc && <>
                                        {compensation2ndPerc}%
                                    </>}
                                    {compensationType == BPCompensationType.Flat && <>
                                        <FormatCurrency amount={compensation2ndFlat} currency={currency} />
                                    </>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Remuneração Custo 3ª Lugar:")}</span>
                                <span className="value">
                                    {compensationType == BPCompensationType.Perc && <>
                                        {compensation3rdPerc}%
                                    </>}
                                    {compensationType == BPCompensationType.Flat && <>
                                        <FormatCurrency amount={compensation3rdFlat} currency={currency} />
                                    </>}
                                </span>
                            </p>
                        </Panel>
                        <Panel header={I18n.get("Sobre a Marca & Produto")} key="2">
                            <p className="prop">
                                <span className="name">{I18n.get("Produto e/ou serviço e marca e o mercado de atuação:")}</span>
                                <span className="value">
                                    {bPitch.productMarketBrand} {bPitch.isProductInfoViaUpload && <i>{I18n.get("Ver arquivo anexo.")}</i>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Perfil do usuário, consumidor e/ou cliente:")}</span>
                                <span className="value">
                                    {bPitch.customerProfile} {bPitch.isProductInfoViaUpload && <i>{I18n.get("Ver arquivo anexo.")}</i>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Público(s) alvo(s) do projeto, em ordem de prioridade:")}</span>
                                <span className="value">
                                    {bPitch.audience} {bPitch.isAudienceInfoViaUpload && <i>{I18n.get("Ver arquivo anexo.")}</i>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Praças de abrangência do projeto, em ordem de prioridade:")}</span>
                                <span className="value">
                                    {bPitch.placement} {bPitch.isAudienceInfoViaUpload && <i>{I18n.get("Ver arquivo anexo.")}</i>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Objetivos de negócio:")}</span>
                                <span className="value">
                                    {bPitch.businessObjective} {bPitch.isBusinessObjectiveInfoViaUpload && <i>{I18n.get("Ver arquivo anexo.")}</i>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Objetivos de comunicação:")}</span>
                                <span className="value">
                                    {bPitch.communicationObjective} {bPitch.isBusinessObjectiveInfoViaUpload && <i>{I18n.get("Ver arquivo anexo.")}</i>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Reason to believe:")}</span>
                                <span className="value">
                                    {bPitch.reasonToBelieve} {bPitch.isReasonToBelieveInfoViaUpload && <i>{I18n.get("Ver arquivo anexo.")}</i>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Benefícios e atributos:")}</span>
                                <span className="value">
                                    {bPitch.benefits} {bPitch.isReasonToBelieveInfoViaUpload && <i>{I18n.get("Ver arquivo anexo.")}</i>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Cenário competitivo:")}</span>
                                <span className="value">
                                    {bPitch.competition} {bPitch.isCompetitionInfoViaUpload && <i>{I18n.get("Ver arquivo anexo.")}</i>}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Informações adicionais da marca, produto ou serviço:")}</span>
                                <span className="value">
                                    {bPitch.additionalInfo}
                                </span>
                            </p>
                        </Panel>
                        <Panel header={I18n.get("Briefing e Entregáveis")} key="3">
                            <p className="prop">
                                <span className="name">{I18n.get("Escopo detalhado do projeto:")}</span>
                                <span className="value">
                                    {bPitch.briefing}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("KPIs de sucesso do projeto:")}</span>
                                <span className="value">
                                    {bPitch.kpi}
                                </span>
                            </p>

                            <p className="prop">
                                <span className="name">{I18n.get("Entregáveis dos parceiros participantes:")}</span>
                                <span className="value">
                                    {bPitch.deliverables}
                                </span>
                            </p>
                        </Panel>
                        <Panel header={I18n.get("Arquivos")} key="4">
                            {bPitch.files && bPitch.files.map((item) =>
                            (<p className="prop">
                                <span className="name">{item.description}:</span>
                                <span className="value">
                                    <a href={item.url} target="_blank">{item.name}</a>
                                </span>
                            </p>)
                            )}

                            {/* <ul style={{ marginLeft: '1rem' }}>
                    {bPitch.files && bPitch.files.map((item, index) =>
                        (<li style={{ marginTop: '0.5rem' }}>
                            <a href={item.url} target="_blank">{item.name}</a>
                        </li>)
                    )}
                </ul> */}

                            {bPitch.files && bPitch.files.length == 0 && <p>
                                <span className="has-text-grey-light">{I18n.get("Nenhum.")}</span>
                            </p>}
                        </Panel>
                        <Panel header={I18n.get("Cronograma")} key="5">
                            {bPitch.schedule && bPitch.schedule.map((item) => {

                                return (
                                    <p className="prop">
                                        <span className="name">{item.label}:</span>
                                        <span className="value">
                                            {item.scheduleType == 'PERIOD' && <>
                                                {item.startDate} - {item.endDate}
                                            </>}
                                            {item.scheduleType == 'DATE' && <>
                                                {item.scheduleDate}
                                            </>}
                                        </span>
                                    </p>
                                );

                            })}
                        </Panel>
                        <Panel header={I18n.get("Scorecard")} key="6">
                            {bPitch.scorecard && bPitch.scorecard.map((topic) => {

                                return (
                                    <p className="prop">
                                        <span className="name">{topic.label}: &nbsp; {topic.perc}% </span>
                                        <div className="value">
                                            {topic.children && topic.children.map((item) => {
                                                return (<div style={{ marginLeft: '1rem' }}> {item && item.label}: {item && item.perc}%</div>);
                                            })}
                                        </div>
                                    </p>
                                );

                            })}
                        </Panel>
                    </Collapse>
                </CardBorder>
            </div>
        );
    }
}

export default BPitchDetails;
