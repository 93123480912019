import React, { Component } from "react";
import { I18n } from "aws-amplify";
import moment from "moment";
import { Row, Col, Form, Select, DatePicker, Button } from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;

class FilterSaving extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let dataFrom = moment(values.date[0]).format("YYYY-MM-DD");
        let dataTo = moment(values.date[1]).format("YYYY-MM-DD");

        const data = {
          ...values,
          dataFrom: dataFrom,
          dataTo: dataTo,
        };

        setTimeout(() => {
          this.props.action(data);
        }, 100);
      }
    });
  };

  onExport = (type = 1) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let dataFrom = moment(values.date[0]).format("YYYY-MM-DD");
        let dataTo = moment(values.date[1]).format("YYYY-MM-DD");

        const data = {
          ...values,
          dataFrom: dataFrom,
          dataTo: dataTo,
          type: type
        };

        setTimeout(() => {
          this.props.export(data);

          //this.props.export(data);
        }, 100);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;
    const { filters } = this.props;

    const today = new Date();
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const firstDayLastMonth = new Date(
      lastDayLastMonth.getFullYear(),
      lastDayLastMonth.getMonth(),
      1
    );

    const currentDate = moment(lastDayLastMonth).format("YYYY-MM-DD");
    const dayDate = moment(firstDayLastMonth).format("YYYY-MM-DD");

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          {/* <Col sm={3}>
            <Form.Item label={I18n.get("Status")}>
              {getFieldDecorator("status", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: -1,
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filters &&
                    filters?.status.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col> */}
          <Col sm={6}>
            <Form.Item label={I18n.get("Data")}>
              {getFieldDecorator("date", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: [moment(dayDate), moment(currentDate)],
              })(
                <RangePicker
                  // defaultValue={[moment(dayDate), moment(currentDate)]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                  format="DD/MM/YYYY"
                />
              )}
            </Form.Item>
          </Col>

          <Col sm={5}>
            <Form.Item label={I18n.get("Marca")}>
              {getFieldDecorator("brand", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "-1",
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filters &&
                    filters?.brand.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={5}>
            <Form.Item label={I18n.get("Produto")}>
              {getFieldDecorator("productId", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "-1",
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filters &&
                    filters?.product.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={4}>
            <Form.Item label={I18n.get("Parceiro")}>
              {getFieldDecorator("partner", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "-1",
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filters &&
                    filters?.partner.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          {/* <Col sm={4}>
            <Form.Item label={I18n.get("Especialidades")}>
              {getFieldDecorator("spec", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "-1",
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option className="SelectMin" value="-1">
                    {I18n.get("All")}
                  </Option>
                  {filters &&
                    filters?.specs.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col> */}

          
        </Row>

        <Row>
        <Col sm={4}>
            <Form.Item className="labelClean" label="&nbsp;">
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={!filters}
                className="full button bt-bpool black"
                loading={this.props.loading}
              >
                {I18n.get("Pesquisar")}
              </Button>
            </Form.Item>
          </Col>

          <Col sm={4}>
            <Form.Item className="labelClean" label="&nbsp;">

            &nbsp;
              
              <Button
                className="button bt-bpool black pull-left"
                style={{ fontSize: "90%" }}
                onClick={() => this.onExport(1)}
                loading={this.props.loading}
              >
                {I18n.get("Exportar Spending")}
              </Button>


            </Form.Item>
          </Col>

          <Col sm={4}>
            <Form.Item className="labelClean" label="&nbsp;">

            &nbsp;
              

              <Button
                className="button bt-bpool black pull-left"
                style={{ fontSize: "90%" }}
                onClick={() => this.onExport(2)}
                loading={this.props.loading}
              >
                {I18n.get("Exportar Todos")}
              </Button>

            </Form.Item>
          </Col>


        </Row>


      </Form>
    );
  }
}

const FormFilterSaving = Form.create({})(FilterSaving);

export default FormFilterSaving;
