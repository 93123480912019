import styled from "styled-components";

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const BoxReview = styled.div`
  border-radius: 10px;
  margin-top: 15px;
  padding: 25px 15px;
  border: 1px solid;
  border-color: #e7e7e7;

  ul {
    li {
      display: flex;
      align-items: baseline;
      margin: ${(props) =>
        props.spacing === "extra-large" ? "50px 0;" : "10px 0;"};
      line-height: 25px;

      :first-child {
        margin-top: 0;
      }

      :last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding-left: 5px;
      }
    }
  }
`;

export const BoxReviewDetails = styled.div`
  border-radius: 10px;
  margin-top: 15px;
  padding: 25px 15px;
  border: 1px solid;
  border-color: #e7e7e7;

  ul {
    li {
      display: grid;
      grid-template-columns: 30px calc(100% - 30px);
      margin-bottom: 10px;
      line-height: 30px;

      :last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding-left: 5px;
      }
    }
  }
`;

export const ContentReview = styled.div`
  margin-top: -10px;
  background-color: #fff;
  padding-bottom: 30px;
`;

export const WrapperReview = styled.div`
  margin-top: 30px;
`;

export const TitleReview = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
`;

export const RowFooter = styled.div`
  margin-top: 100px;
  padding-top: 50px;
  /* margin-bottom: 100px; */
  border-top: solid 1px #000;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsFooter = styled.div`
  display: flex;
  .btnPrev {
    margin-right: 20px;
  }
`;
