import React from "react";
import * as S from "./styles";

export const Button = ({
  text,
  icon,
  isDisabled,
  theme, // primary || grey
  onClick,
  target,
  className,
  size,
  shape,
  outline,
  download,
  loading,
  padding,
  full,
  iconRight,
}) => {
  return (
    <S.StyledButton
      disabled={isDisabled}
      iconBrightness={isDisabled ? "brightness(85%)" : "brightness(100%)"}
      color={isDisabled ? "#CCCCCC" : "#FFF"}
      borderColor={isDisabled ? "#CCCCCC" : "transparent"}
      target={target}
      className={`${className}`}
      onClick={onClick}
      theme={theme}
      size={size ? size : "medium"}
      shape={shape}
      outline={outline}
      download={download}
      loading={loading}
      full={full}
      padding={padding}
      data-label={text}
    >
      {icon && <img src={icon} alt={`ícone de ${text}`} />}
      {text}
      {iconRight && <img src={iconRight} alt={`ícone de ${text}`} />}
    </S.StyledButton>
  );
};
