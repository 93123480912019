import React, { Fragment } from "react";
import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import StarReview from "../form/star-review";

import TextAreaField from "../form/textarea-field";
import SubmitButtonField from "../form/submit-button-field";

import { Row, Col } from "antd";

class ProjectEndForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      projectId: props.match.params.projectId,
      mode: props.mode,
      form: {
        recommendPartner: "",
        partnerReview: {
          label: "",
          value: props.partnerReview,
          onChange: this.onChangeForm.bind(this, "partnerReview"),
          error: props.errors.partnerReview,
          successIcon: false,
          maxLength: 500,
        },
        recommendBP: {
          value: "",
        },
        recommendPartner2: {
          value: "",
        },
      },
      ratings: [],
      task: {},
    };

    this.changeReview = this.onChangeReview.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value = this.props[item] || "";
        } else if (typeof nextState.form !== "undefined") {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].value = value;

      return nextState;
    });
  }

  onChangeReview(index, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      const current = Object.assign({}, prevState);

      current.ratings[index] =
        this.props.bp.clientProjectCheckpointRatings[index];
      current.ratings[index].value = value;

      return current;
    });
  }

  onRecommendPartnerClick(value, e) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["recommendPartner"] = value;

      return nextState;
    });
  }

  getData() {
    if (this.props.review) {
      var dataSend = {
        ...this.props.review,
        projectId: this.state.projectId,
      };
      return dataSend;
    } else {
      var data = {
        projectId: this.state.projectId,
        ratings: this.state.ratings,
      };

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(this.state.form[item])) {
          data[item] = this.state.form[item].value;
        } else {
          data[item] = this.state.form[item];
        }
      });
      return data;
    }
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.end(this.getData());
    });
  }

  getTitle(isReview) {
    if (isReview) {
      return <h1 className="title">{I18n.get("Avaliação do projeto")}</h1>;
    } else {
      return <h1 className="title">{I18n.get("Finalizar")}</h1>;
    }
  }

  getSubTitle(isReview) {
    if (isReview) {
      return <p className="title-bpool medium"> </p>;
    } else {
      return <p>{I18n.get("Faça um breve review do Parceiro.")}</p>;
    }
  }

  render() {
    var errorMap = {};

    Object.keys(this.props.project.errors).map((item) => {
      const error = this.props.project.errors[item];

      const key = error.step || "global";

      if (!errorMap[key]) {
        errorMap[key] = [];
      }

      errorMap[key].push(error.errorMessage);
    });

    const recommendPartnerTClassName =
      (this.props.review && this.props.review.recommendPartner) ||
      this.state.form.recommendPartner === true
        ? "is-black"
        : "is-black is-outlined";
    const recommendPartnerFClassName =
      (this.props.review && !this.props.review.recommendPartner) ||
      this.state.form.recommendPartner === false
        ? "is-black"
        : "is-black is-outlined";

    const { isReview } = this.props;

    const projectRatings =
      this.props.review && this.props.review.ratings
        ? this.props.review.ratings
        : this.state.ratings;
    const bpRatings =
      this.props.review && this.props.review.recommendBP
        ? this.props.review.recommendBP
        : this.state.form.recommendBP.value;
    const partnerRatings =
      this.props.review && this.props.review.recommendPartner2
        ? this.props.review.recommendPartner2
        : this.state.form.recommendPartner2.value;
    const partnerReviewText =
      this.props.review && this.props.review.partnerReview;

    return (
      <div>
        <div className="columns">
          <div className="column is-12">
            {this.getTitle(isReview)}
            <br />
            {this.getSubTitle(isReview)}

            <br />
            <br />

            <div className="card">
              <div
                className="card-content"
                style={{ paddingTop: "30px", paddingBottom: "30px" }}
              >
                <form
                  onSubmit={this.submitForm.bind(this)}
                  style={{ textAlign: "left" }}
                >
                  <Row>
                    {this.props.bp.clientProjectCheckpointRatings &&
                      this.props.bp.clientProjectCheckpointRatings.map(
                        (item, index) => (
                          <Col sm={12} key={index}>
                            <p style={{ paddingTop: "2rem" }}>
                              {" "}
                              <strong>{item.label}</strong>{" "}
                            </p>

                            <StarReview
                              key={item.code}
                              id={index}
                              label=""
                              onChange={this.changeReview}
                              selected={
                                projectRatings[index] &&
                                projectRatings[index].value
                              }
                            />
                          </Col>
                        )
                      )}

                    <Col sm={12}>
                      {isReview && (
                        <Fragment>
                          <div>
                            <p style={{ paddingTop: "2rem" }}>
                              <strong>
                                {I18n.get(
                                  "Em uma escala de 1 a 5, avalie a probabilidade de você recomendar a agência para outras marcas?"
                                )}
                              </strong>
                            </p>
                          </div>
                          <div>
                            <StarReview
                              id="recommendPartner2"
                              label=""
                              onChange={this.onChangeForm.bind(this)}
                              selected={partnerRatings}
                            />
                          </div>
                        </Fragment>
                      )}

                      {!isReview && (
                        <Fragment>
                          <div>
                            <p style={{ paddingTop: "2rem" }}>
                              <strong>
                                {I18n.get(
                                  "Em uma escala de 1 a 5, avalie a probabilidade de você recomendar a agência para outras marcas?"
                                )}
                              </strong>
                            </p>
                          </div>

                          <div>
                            <StarReview
                              id="recommendPartner2"
                              label=""
                              onChange={this.onChangeForm.bind(this)}
                              selected={partnerRatings}
                            />
                          </div>
                        </Fragment>
                      )}
                    </Col>

                    <Col sm={12}>
                      <div>
                        <p
                          style={{
                            paddingTop: partnerReviewText ? "0" : "3rem",
                          }}
                        >
                          {" "}
                          <strong>
                            {I18n.get("Faça um breve review do Parceiro")}
                          </strong>{" "}
                        </p>
                      </div>

                      <div>
                        {partnerReviewText && <p>{partnerReviewText}</p>}

                        {!partnerReviewText && (
                          <TextAreaField {...this.state.form.partnerReview} />
                        )}
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div>
                        <p style={{ paddingTop: "2rem" }}>
                          {" "}
                          <strong>
                            {I18n.get(
                              "Em uma escala de 1 a 5, avalie a probabilidade de você recomendar a BPool para outras marcas?"
                            )}
                          </strong>
                        </p>
                      </div>

                      <div>
                        <StarReview
                          id="recommendBP"
                          label=""
                          onChange={this.onChangeForm.bind(this)}
                          selected={bpRatings}
                        />
                      </div>
                    </Col>
                  </Row>

                  {Object.keys(errorMap).length > 0 && (
                    <div className="content is-small">
                      <br />
                      <br />

                      {Object.keys(errorMap).map((item) => {
                        return (
                          <p>
                            <span class="icon has-text-danger">
                              <i class="fas fa-exclamation"></i>
                            </span>
                            <ul>
                              {errorMap[item].map((errorMessage) => (
                                <li>{errorMessage}</li>
                              ))}
                            </ul>
                          </p>
                        );
                      })}

                      <br />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        {!isReview && (
          <div className="columns">
            <div className="column"></div>

            <div className="column">
              <div className="field is-grouped is-grouped-centered container-content p-btns">
                <p className="control is-expanded" onClick={this.scrollToTop}>
                  <Link
                    to={`/projetos/${this.props.match.params.projectId}/resumo`}
                    className="button bt-bpool grey is-fullwidth previous"
                  >
                    {I18n.get("Voltar")}
                  </Link>
                </p>
                <p className="control is-expanded" onClick={this.scrollToTop}>
                  <SubmitButtonField
                    label={I18n.get("FINALIZAR")}
                    onClick={this.submitForm.bind(this)}
                    task={this.state.task}
                    suppressLabel={true}
                    className="button bt-bpool black is-fullwidth previous"
                  />
                </p>
              </div>
            </div>

            <div className="column"></div>
          </div>
        )}
      </div>
    );
  }
}

export default ProjectEndForm;
