import React from "react";
import { connect } from "react-redux";

import { scrollToTop } from "../../../utils/ui";

import { WIKIS_API } from "../../../actions/wikis/actions";

export default function ProjectContainer(Component) {
  const mapStateToProps = (state) => {
    return state;
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      get_knowledge: (data) => {
        dispatch(WIKIS_API.get_knowledge(data));
      },
    };
  };

  class KnowledgeContext extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      scrollToTop();
      // this.props.get_knowledge()
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      if (
        this.props.auth.is_client != prevProps.auth.is_client ||
        this.props.auth.is_partner != prevProps.auth.is_partner
      ) {
        const data =
          this.props.auth.is_client || this.props.auth.is_client_user
            ? "Client"
            : "Partner";
        this.props.get_knowledge(data);
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          target={
            this.props.auth.is_client || this.props.auth.is_client_user
              ? "Client"
              : "Partner"
          }
        />
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(KnowledgeContext);
}
