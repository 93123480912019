import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import {
    Table,
    Input,
    Button,
    Switch,
    Tag,
    Icon
} from 'antd';

import ItemBundle from './ItemBundle';

const Btns = styled.p`
width: 50px;
display: block;

&.checkItem {
    width: 100px;
}
    a {
        margin-left: 10px;
    }
`


class tableAssetsClient extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleActive = (e, item) => {
        const data = {
            ...item,
            enabled: e
        }

        this.props.editActive(data)
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                ref={node => {
                    this.searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 300, marginBottom: 8, display: 'block' }}
                />
                <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon="search"
                size="small"
                style={{ marginRight: 8 }}
                >
                Buscar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small">
                Cancelar
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
        record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
            />
        ) : (
            text
        ),
    });

    render() {
        const columns = [
            {
                title: I18n.get("Título"),
                dataIndex: 'title',
                key: 'title',
                ...this.getColumnSearchProps('title'),
            },
            {
                title: I18n.get("Variações"),
                dataIndex: 'variations',
                key: 'variations',
                width: "120px"
            },
            {
                title: I18n.get("Ativo"),
                dataIndex: 'enabled',
                key: 'enabled',
                width: "120px",
                render: (enable, record) => {                    
                    return <Switch loading={this.props.task.busy} onChange={(e) => this.handleActive(e, record.item)} checkedChildren="SIM" unCheckedChildren="Não" checked={enable} />
                }
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'item',
                width: "80px",
                render: (text, record) => {
                    return (
                        <Btns>
                            <a onClick={() => this.props.edit(record.item)}>
                                <Icon type="edit" theme="filled" />
                            </a>
                            <a onClick={() => this.props.delete(record.item.id)}>
                                <Icon type="delete" theme="filled" />
                            </a>
                        </Btns>
                    );
                }
            }

        ]

        let data = this.props.data && this.props.data.map((item, index) => {
            return {
                key: index,
                title: item.title,
                enabled: item.enabled,
                variations: item.variations && item.variations.length,
                item: item
            }
        });

        return (
            <Table
                columns={columns}
                dataSource={data}
                scroll={{ x: 640 }}
                locale={{ emptyText: I18n.get('Sem informação') }}
                pagination={false}
                expandedRowRender={(record) => <ItemBundle 
                    item={record.item} 
                    assetSpecialisms={this.props.assetSpecialisms} 
                    partnerSpecialismsArray={this.props.partnerSpecialismsArray}
                    assetTypeArray={this.props.assetTypeArray}
                                                        
                />}
            />
        );
    }
}

export default tableAssetsClient;
