import React, { useEffect } from "react";
import { I18n } from "aws-amplify";
import Loading from "../../../../../components/UI/Loading";
import { AssetBundle } from "../../../../../components/UI/AssetBundle";
import * as S from "../styles";

export const CreativeScope = ({
  isLoading,
  listOfAssets,
  dataValues,
  isInvite,
  convertedValues,
  reload,
  descriptiveHourMan,
  disabledEdit,
  currencyProject,
  isCart,
  isModalConfirm,
  isClient,
  showTooltipOtherTax
}) => {
  return (
    <S.BoxItem>
      <S.BoxItemTitle>
        <p>{I18n.get("Escopo Criativo")}</p>
        <span />
      </S.BoxItemTitle>
      {isLoading ? (
        <Loading
          text={I18n.get("Carregando...")}
          sizeText={14}
          sizeIcon={16}
          color="#000000"
          align="left"
        />
      ) : (
        <>
          <AssetBundle
            isInvite={isInvite}
            listOfAssets={listOfAssets}
            dataValues={dataValues}
            convertedValues={convertedValues}
            reload={reload}
            isModalConfirm={isModalConfirm}
            descriptiveHourMan={descriptiveHourMan}
            disabledEdit={disabledEdit}
            currencyProject={currencyProject}
            isCart={isCart}
            isClient={isClient}
            showTooltipOtherTax={showTooltipOtherTax}
          />
        </>
      )}
    </S.BoxItem>
  );
};
