import React, { useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Rate } from "antd";

import * as S from "./styles";

export const ExpandDetails = ({ data, referenceRatings, close }) => {
  const itemCol = ({ title, value, style }) => {
    return <S.ItemCol style={style ? style : {}}>
      <strong>{title}:</strong><span>{value || "---"}</span>
    </S.ItemCol>
  };

  const itemValue = ({ value }) => {
    return value || "---";
  };

  const itemNameReference = ({ code }) => {
    const item = referenceRatings?.find(item => item?.code === code);

    return item ? item?.label : "---";
  };

  return (
    <S.WrapperExpandDetails>
      <S.ContentExpandDetails>
        <S.TitleExpandDetails>
          <h1>
            {I18n.get("Reference details")}
          </h1>
        </S.TitleExpandDetails>

        <S.ResponsibleExpandDetails>
          <h3>{I18n.get("Responsible")}</h3>
          <Row style={{ marginTop: 10 }}>
            <Col xs={24} sm={8} style={{ paddingLeft: 0 }}>
              {itemCol({ title: I18n.get("Name"), value: itemValue({ value: data?.name }) })}
            </Col>
            <Col xs={24} sm={16} style={{ paddingRight: 0 }}>
              {itemCol({ title: I18n.get("E-mail"), value: itemValue({ value: data?.email }) })}
            </Col>
            <Col xs={24} sm={8} style={{ paddingLeft: 0 }}>
              {itemCol({ title: I18n.get("Celular"), value: itemValue({ value: data?.phoneNumber }) })}
            </Col>
            <Col xs={24} sm={16} style={{ paddingRight: 0 }}>
              {itemCol({ title: I18n.get("Registration date"), value: itemValue({ value: data?.createdOn }) })}
            </Col>
          </Row>
        </S.ResponsibleExpandDetails>

        <S.RatedsExpandDetails>
          <h3>{I18n.get("Rating")}</h3>
          <S.RatingsContent>
            <S.TableRating>
            <tr style={{ height: 30 }}>
              <th>{I18n.get("Name")}</th>
              <th>{I18n.get("Rating")}</th>
            </tr>
              {data?.ratings?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div>{itemNameReference({ code: item?.code })}</div>
                  </td>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {`(${item?.value})`}
                      <div style={{ marginLeft: 5 }}>
                        <Rate disabled allowHalf value={item?.value} style={{ marginLeft: 5 }} />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </S.TableRating>
          </S.RatingsContent>
        </S.RatedsExpandDetails>
      </S.ContentExpandDetails>
    </S.WrapperExpandDetails>
  );
};
