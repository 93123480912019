import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { I18n } from "aws-amplify";
import { Row, Col, Select, Icon } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useRegistrationFlow } from "../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";
import Main from "../../../components/UI/Main";
import Checkbox from "../../../components/UI/Checkbox";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import { Input } from "../../../components/UI/Input";
import { InputFlag } from "../../../components/UI/InputFlag";
import { SelectOne } from "../../../components/UI/SelectOne";
import { InputMask } from "../../../components/UI/InputMask";
import { ModalConfirm } from "../../../components/UI/ModalConfirm/ModalConfirm";
import { Unlogged } from "../../../components/UI/Unlogged";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";
import { ModalTerms } from "./modalTerms";
import PasswordChecklist from "react-password-checklist";
import { hasError, clearErrorInput } from "../utils/errorsInput";
import { localeValidation } from "../utils/localeValidation";

import * as S from "./styles";

import COUNTRY_CODES from "./countryCodes.json";
const MESSAGE_ERROR_GET_PAGE = "There was an error, try again.";

const { Option } = Select;

export const Application = () => {
  let history = useHistory();
  let location = useLocation();
  const listCountries = useSelector((state) => state.bp.countryCodes);
  const { getStatusBarPreApplication, verifyCnpj, registry } =
    useRegistrationFlow();

  const [controlCnpjInput, setControlCnpjInput] = useState({
    error: false,
    valid: false,
    message: "",
  });
  const [cnpjValue, setCnpjValue] = useState("");
  const [viewBoxAddCnpj, setViewBoxAddCnpj] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [inputsForm, setInputsForm] = useState({
    firstName: "",
    lastName: "",
    masterUserEmail: "",
    confirmMasterUserEmail: "",
    linkedin: "",
    companyName: "",
    tradeName: "",
    countryCode: "BRA",
    phoneNumber: "",
    passWord: "",
    confirmPassWord: "",
  });
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [selectCountry, setSelectCountry] = useState({
    countryLabel: "Brazil",
    phoneMask: "(00)00000-0000",
    countryCode: "BRA",
  });
  const [isDisabledButtonSend, setIsDisabledButtonSend] = useState(true);
  const [openModalMailConfirmation, setOpenModalMailConfirmation] =
    useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSubmitCnpj, setIsSubmitCnpj] = useState(false);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [errorEmail, setErrorEmail] = useState(null);
  const [localeUser, setLocaleUser] = useState("pt");
  const [checkCnpjDebounced] = useDebounce(cnpjValue, 1000);
  const [loadingCnpj, setLoadingCnpj] = useState(false);

  useEffect(() => {
    const language = getParamsLocale();
    const locale = localeValidation(language);
    setLocaleUser(locale);
    I18n.setLanguage(locale);
  }, [location]);

  useEffect(async () => {
    const data = {
      number: cnpjValue,
      locale: localeUser,
    };

    if (cnpjValue) {
      setLoadingCnpj(true);
      const response = await verifyCnpj(data);

      if (response.data && response.success && response.isValid) {
        setOpenModalConfirm(true);
        setInputsErrorsForm([]);
        setLoadingCnpj(false);
      } else if (!response.data && !response.success && response.isValid) {
        setViewBoxAddCnpj(true);
        setInputsErrorsForm([]);
        setLoadingCnpj(false);
      } else {
        const errors = [];
        Object.keys(response?.errors).forEach(function (key, index) {
          errors.push(response?.errors[key]);
        });
        setInputsErrorsForm(errors);
        setLoadingCnpj(false);
        setViewBoxAddCnpj(false);
      }
      setIsSubmitCnpj(false);
    }
  }, [checkCnpjDebounced]);

  function validCnpj(cnpj) {
    let regex =
      /^([0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9])+$/;

    if (cnpj.match(regex)) {
      setControlCnpjInput({ error: false, valid: true, message: "" });

      return true;
    } else {
      setControlCnpjInput({
        error: true,
        valid: false,
        message: "Please enter our CNPJ in the format: XX.XXX.XXX/XXX1-XX.",
      });
      setCnpjValue("");
      return false;
    }
  }

  const getParamsLocale = () => {
    let language = "";
    if (location.search) {
      const locationSplit = location.search.split("=");
      language = locationSplit[1];
    } else {
      language = "pt";
    }
    return language;
  };

  const renderIcoInput = (condition) => {
    if (condition) {
      return <CheckOutlined style={{ color: "#3dc38a", fontSize: 20 }} />;
    } else {
      return;
    }
  };

  const handleSubmit = async () => {
    // setIsSubmitCnpj(true);
    // const response = await verifyCnpj(cnpjValue);
  };

  const handleModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push("/registration-flow/login");
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value,
    });
  };

  const onChangeInputPhone = ({ valuePhone, valuePhoneCountry }) => {
    setInputsForm({
      ...inputsForm,
      phoneNumber: valuePhone ? valuePhone : null,
      countryCodePhoneNumber: valuePhoneCountry ? valuePhoneCountry : null,
    });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onBlurInputForm = (id, value) => {
    if (validateEmail(value)) {
      const error = {
        ...errorEmail,
        [id]: {
          errorMessage: null,
        },
      };

      setErrorEmail(error);
    } else {
      const error = {
        ...errorEmail,
        [id]: {
          errorMessage: I18n.get("E-mail inválido"),
        },
      };
      setErrorEmail(error);
    }
  };

  const hasError = (fieldInput) => {
    if (inputsErrorsForm.length) {
      const err = inputsErrorsForm.find(
        (item) => item.field.toLowerCase() === fieldInput.toLowerCase()
      );

      if (err) {
        return err.errorMessage;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const signUp = async () => {
    setIsSending(true);
    const formData = {
      ...inputsForm,
      registrationId: cnpjValue,
      terms: agreeTerms,
      locale: localeUser,
    };
    // delete formData.confirmMasterUserEmail;

    const response = await registry(formData);

    if (response.success) {
      const stringfy = JSON.stringify({
        name: inputsForm.firstName,
        mail: inputsForm.masterUserEmail,
        cnpj: cnpjValue,
      });
      localStorage.setItem("@BPOOL-registration-flow-user", stringfy);

      history.push({
        pathname: "/registration-flow/application/received",
        state: { name: inputsForm.firstName, mail: inputsForm.masterUserEmail },
      });
    } else {
      const errors = [];
      Object.keys(response?.errors).forEach(function (key, index) {
        errors.push(response?.errors[key]);
      });
      setInputsErrorsForm(errors);
      // message.error(I18n.get(MESSAGE_ERROR_GET_PAGE));
    }

    setIsSending(false);
  };
  
  const handleModalMailConfirmation = () => {
    setOpenModalMailConfirmation(false);
    signUp();
  };

  useEffect(() => {
    if (
      inputsForm.firstName &&
      inputsForm.lastName &&
      inputsForm.masterUserEmail &&
      inputsForm.masterUserEmail === inputsForm.confirmMasterUserEmail &&
      inputsForm.linkedin &&
      inputsForm.companyName &&
      inputsForm.tradeName &&
      inputsForm.phoneNumber &&
      passwordIsValid &&
      agreeTerms
    ) {
      setIsDisabledButtonSend(false);
    } else {
      setIsDisabledButtonSend(true);
    }
  }, [inputsForm, cnpjValue, passwordIsValid, agreeTerms]);

  const [isLoadingSteps, setIsLoadingSteps] = useState(false);
  const [stepsStatusBar, setStepsStatusBar] = useState([]);
  const statusbar = useSelector((state) => state.statusbar);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Unlogged>
        <div className="container">
          <Row style={{ marginTop: 100 }}>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 4 }}>
              <Main bgColor="#fff" padding="30px">
                {/* {viewBoxAddCnpj ? <ScrollUpDown /> : null} */}
                <Row>
                  <Col xs={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Join BPool as")}</S.H4>
                      </S.TitleWithIcon>
                      <S.Paragraph>
                        {I18n.get(
                          "Select how you want to work your projects. As a contracting company or partner in agencies."
                        )}
                      </S.Paragraph>
                    </S.RowCenter>
                  </Col>
                </Row>
                {/* <Row style={{ marginTop: 30 }}>
                  <Col
                    xs={24}
                    sm={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <ButtonSelect
                      title={I18n.get("Company")}
                      subtitle={I18n.get(
                        "Your a an brand looking to hire agencies"
                      )}
                      disabled={true}
                      onClick={() => console.log("button Company")}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <ButtonSelect
                      title={I18n.get("Partner")}
                      subtitle={I18n.get(
                        "Your a an brand waiting to work with great brands"
                      )}
                      disabled={false}
                      onClick={() => console.log("button Partner")}
                    />
                  </Col>
                </Row> */}

                <Row style={{ marginTop: 30 }}>
                  <Col xs={24}>
                    {localeUser == "pt" && (
                      <Input
                        mask={"00.000.000/0000-00"}
                        placeholder={I18n.get("CNPJ")}
                        required
                        error={
                          controlCnpjInput.message || hasError("RegistrationId")
                        }
                        suffix={renderIcoInput(controlCnpjInput.valid)}
                        onChange={(value) => setCnpjValue(value)}
                      />
                    )}

                    {localeUser != "pt" && (
                      <Input
                        placeholder={I18n.get("CNPJ")}
                        required
                        error={
                          controlCnpjInput.message || hasError("RegistrationId")
                        }
                        suffix={renderIcoInput(controlCnpjInput.valid)}
                        // debounceTime={500}
                        onChange={(value) =>
                          setCnpjValue(value.replace(/[\/.]/g, ""))
                        }
                        value={cnpjValue}
                      />
                    )}
                  </Col>
                </Row>
                {/* {!viewBoxAddCnpj ? (
                  <>
                    <Row style={{ marginTop: 30 }}>
                      <Col xs={24}>
                        <ButtonBpool
                          text={I18n.get("Submit")}
                          theme="primary"
                          full
                          disabled={!controlCnpjInput.valid}
                          onClick={handleSubmit}
                          loading={isSubmitCnpj}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null} */}

                {loadingCnpj && (
                  <Row>
                    <Col xs={24}>
                      <p style={{ textAlign: "center", fontSize: "40px" }}>
                        <Icon type="loading" />
                      </p>
                    </Col>
                  </Row>
                )}

                {viewBoxAddCnpj ? (
                  <>
                    <Row>
                      <Col xs={24}>
                        <S.TitleSectionForm>
                          {I18n.get("Master details")}
                        </S.TitleSectionForm>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Input
                          placeholder={I18n.get("First name")}
                          required
                          value={inputsForm.firstName}
                          onChange={(value) =>
                            onChangeInputForm("firstName", value)
                          }
                          error={hasError("firstName")}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "firstName",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Input
                          placeholder={I18n.get("Last name")}
                          required
                          value={inputsForm.lastName}
                          onChange={(value) =>
                            onChangeInputForm("lastName", value)
                          }
                          error={hasError("lastName")}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "lastName",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Input
                          placeholder={I18n.get("Master user email")}
                          required
                          value={inputsForm.masterUserEmail}
                          onChange={(value) =>
                            onChangeInputForm("masterUserEmail", value)
                          }
                          onBlur={(value) =>
                            onBlurInputForm("masterUserEmail", value)
                          }
                          error={
                            hasError("masterUserEmail") ||
                            errorEmail?.MasterUserEmail?.errorMessage
                          }
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "MasterUserEmail",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Input
                          placeholder={I18n.get("Confirm master user email")}
                          required
                          value={inputsForm.confirmMasterUserEmail}
                          onChange={(value) =>
                            onChangeInputForm("confirmMasterUserEmail", value)
                          }
                          onBlur={(value) =>
                            onBlurInputForm("confirmMasterUserEmail", value)
                          }
                          error={
                            hasError("confirmMasterUserEmail") ||
                            errorEmail?.confirmMasterUserEmail?.errorMessage
                          }
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "confirmMasterUserEmail",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <Input
                          placeholder={I18n.get("LinkedIn")}
                          value={inputsForm.linkedin}
                          onChange={(value) =>
                            onChangeInputForm("linkedin", value)
                          }
                          error={hasError("linkedin")}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "linkedin",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </Col>
                    </Row>
                    {/*  */}
                    <Row>
                      <Col xs={24}>
                        <S.TitleSectionForm>
                          {I18n.get("Company information")}
                        </S.TitleSectionForm>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Input
                          placeholder={I18n.get("Company name")}
                          required
                          value={inputsForm.companyName}
                          onChange={(value) =>
                            onChangeInputForm("companyName", value)
                          }
                          error={hasError("companyName")}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "companyName",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Input
                          placeholder={I18n.get("Trade name")}
                          required
                          value={inputsForm.tradeName}
                          onChange={(value) =>
                            onChangeInputForm("tradeName", value)
                          }
                          error={hasError("tradeName")}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "tradeName",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </Col>

                      <Col xs={24} sm={12}>
                        {/* <Input
                          placeholder={I18n.get('Country')}
                          required
                          value={selectCountry.countryLabel}
                          disabled
                          addonBefore={selectBefore}
                        /> */}

                        <SelectOne
                          options={COUNTRY_CODES || []}
                          value={inputsForm?.countryCode}
                          defaultValue={inputsForm?.countryCode}
                          isCountry
                          onChange={(value) =>
                            onChangeInputForm("countryCode", value, true)
                          }
                          required
                          placeholder={I18n.get("Country")}
                          error={hasError("countryCode")}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "countryCode",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={12} style={{ height: 80 }}>
                        <InputFlag
                          id="phoneNumber"
                          label={I18n.get("Phone number")}
                          defaultValue={inputsForm?.phoneNumber}
                          onChange={(value) => onChangeInputPhone({ valuePhone: value?.value, valuePhoneCountry: value?.country })}
                          required
                          error={hasError("phoneNumber")}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "phoneNumber",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                          />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={12}>
                        <Input
                          password
                          placeholder={I18n.get("Password")}
                          required
                          value={inputsForm.passWord}
                          onChange={(value) =>
                            onChangeInputForm("passWord", value)
                          }
                          error={hasError("passWord")}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "passWord",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                            "match",
                          ]}
                          minLength={8}
                          value={inputsForm.passWord}
                          valueAgain={inputsForm.confirmPassWord}
                          messages={{
                            minLength: I18n.get(
                              "Password has at least 8 characters."
                            ),
                            specialChar: I18n.get(
                              "Password has special characters."
                            ),
                            number: I18n.get("Password has a number."),
                            capital: I18n.get("Password has a capital letter."),
                            match: I18n.get("Passwords match."),
                          }}
                          onChange={(isValid) => setPasswordIsValid(isValid)}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Input
                          password
                          placeholder={I18n.get("Confirm password")}
                          required
                          value={inputsForm.confirmPassWord}
                          onChange={(value) =>
                            onChangeInputForm("confirmPassWord", value)
                          }
                          error={hasError("confirmPassWord")}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "confirmPassWord",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <S.WrapperAgreeTerms>
                          <Checkbox
                            id="none"
                            onChangeCb={(e, id) =>
                              setAgreeTerms(e.target.checked)
                            }
                            checked={agreeTerms}
                          />

                          <S.LabelAgreeTerms>
                            <span>{I18n.get("I Agree to")}</span>
                            <button onClick={() => setOpenTerms(true)}>
                              {I18n.get("Terms of Use and Privacy Police.")}
                            </button>
                          </S.LabelAgreeTerms>
                        </S.WrapperAgreeTerms>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      <Col xs={24} sm={12}></Col>
                      <Col xs={24} sm={12}>
                        <ButtonBpool
                          text={I18n.get("Submit")}
                          theme="primary"
                          full
                          loading={isSending}
                          //disabled={isDisabledButtonSend}
                          onClick={() => setOpenModalMailConfirmation(true)}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Main>
            </Col>
            {/* <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }}>
              <Main bgColor="#fff" padding="30px">
                <StepsVertical
                  isLoading={isLoadingSteps}
                  steps={stepsStatusBar}
                  clickable={false}
                  page={0}
                />
              </Main>
            </Col> */}
          </Row>
        </div>
      </Unlogged>

      <ModalConfirm
        visible={openModalConfirm}
        bodyText={I18n.get("CNPJ already has a registration")}
        subBodyText={I18n.get("Please login to continue")}
        okText="Login"
        handleClickOk={handleModalConfirm}
      />

      <ModalBp
        visible={openModalMailConfirmation}
        bodyText={inputsForm.masterUserEmail}
        subBodyText={I18n.get(
          "Is this email correct? If not, please go back and adjust."
        )}
        okText={I18n.get("Yes, it's correct.")}
        cancelText={I18n.get("No, I need to adjust.")}
        handleClickOk={handleModalMailConfirmation}
        onCancel={() => setOpenModalMailConfirmation(false)}
        typeModal="isConfirm"
      />

      <ModalTerms
        openTerms={openTerms}
        handleClickCancel={() => setOpenTerms(false)}
      />
    </>
  );
};
