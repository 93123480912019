import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import moment from 'moment';
import { useParams } from "react-router-dom";
import { message } from "antd";
import { useMilestones } from "../../../../../hooks/useMilestones/useMilestones.hook";
import { Input } from "../../../../../components/UI/Input";
import { Button } from "../../../../../components/UI/Button";
import { DatePicker } from "../../../../../components/UI/DatePicker";
import { clearErrorInput, hasError } from "../../../../RegistrationFlow/utils/errorsInput";

import * as S from "./styles";

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

export const FormMacro = ({ disableds, reload }) => {
  let params = useParams();
  const { addMilestones } = useMilestones();
  const [inputsForm, setInputsForm] = useState({});
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [dateCustomer, setDateCustomer] = useState(null);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [disabledAdd, setDisabledAdd] = useState(false);
  const [isResetDatePicker, setIsResetDatePicker] = useState(false);

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const onChangeDate = (date, dateString) => {
    setDateCustomer(date);
  };

  const clearInputs = async () => {
    setInputsForm({});
    setDateCustomer(null);
  };

  const handleAddNewMacro = async () => {
    setIsLoadingSend(true);

    const data = {
      estimatedDate: dateCustomer,
      name: inputsForm.name,
      projectId: params?.projectId,
    };

    try {
      await addMilestones(data);
      message.success(I18n.get("Macro added successfully"));
      setIsResetDatePicker(true);
      await clearInputs();
      setIsResetDatePicker(false);
      reload();
    } catch (error) {
      message.error(I18n.get("Error adding macro"));
      setInputsErrorsForm(error?.response?.data?.errors);
    }

    setIsLoadingSend(false);
  };

  useEffect(() => {
    if (inputsForm.name && dateCustomer) {
      setDisabledAdd(false);
    } else {
      setDisabledAdd(true);
    }
  }, [inputsForm, dateCustomer]);

  return (
    <S.Wrapper>
      <S.SubTitle disabled={disableds}>
        <p>{I18n.get("Add a new delivery macro")}</p>
        <span />
      </S.SubTitle>

      <S.Inputs>
        <S.ColumnInput style={{ flex: 2 }}>
          <Input
              placeholder={I18n.get("Name macro")}
              label
              required
              disabled={disableds}
              value={inputsForm.name}
              onChange={(value) => onChangeInputForm("name", value)}
              error={hasError({
                field: "name",
                arr: inputsErrorsForm,
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "name",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
        </S.ColumnInput>
        <S.ColumnInput style={{ flex: 2, marginLeft: 30 }}>
          <DatePicker
            defaultValue={dateCustomer}
            format={dateFormatList}
            onChange={onChangeDate}
            disabledInput={disableds}
            disabledDate={(current) => {
              let customDate = moment().format('YYYY-MM-DD');
              return current && current < moment(customDate, 'YYYY-MM-DD');
            }}
            placeholder="__/__/____"
            label={I18n.get("Estimated Date")}
            required
            error={hasError({
              field: "estimatedDate",
              arr: inputsErrorsForm,
            })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "estimatedDate",
                arr: inputsErrorsForm,
              });
              setInputsErrorsForm(errorsArr);
            }}
            resetInput={isResetDatePicker}
          />
        </S.ColumnInput>
        <S.ColumnInput style={{ flex: 1, marginLeft: 30 }}>
          <Button
            text={I18n.get("Add")}
            theme="primary"
            full
            isDisabled={disableds || disabledAdd}
            onClick={handleAddNewMacro}
            loading={isLoadingSend}
          />
        </S.ColumnInput>
      </S.Inputs>
    </S.Wrapper>
  );
};
