import styled from "styled-components";

export const WrapperTable = styled.div`
  border: solid 1px #e7e7e7;

  p {
    margin: 0 !important;
  }

  p:nth-child(odd) {
    background: #e7e7e7;
  }
`;


export const DescriptionInfo = styled.div`
  margin: 50px 0 100px;
  h2,
  h3 {
    font-weight: bold;
  }

  h2 {
    margin-bottom: 20px;
  }

  section {
    border: solid 1px #e7e7e7;
    border-radius: 20px;
    overflow: hidden;

    div {
      padding: 30px;

      &.payment-box {
        background: #e7e7e7;
      }
    }

    .invoiceResume,
    .resume {
      padding: 0;
    }
  }
`;

export const ItemList = styled.p`
  display: block;
  padding: 7px 10px 5px;
  margin: 0;

  .invoiceResume {
    padding: 20px;
    background: #fff;

    h3 {
      font-size: 16px;
    }

    .topResume {
      margin-bottom: 20px;
    }
    p {
      background: #fff;
    }
  }
`;
