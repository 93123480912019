import React from "react";
import { I18n } from "aws-amplify";
import Loading from "../../../../../components/UI/Loading";

import * as S from "../styles";

export const AboutPartner = ({ isLoading, data }) => {
  const renderItem = ({ label, item }) => {
    return (
      <S.ItemText>
        <p>{`${label}: `}</p>
        <span>{item || "---"}</span>
      </S.ItemText>
    );
  };

  return (
    <S.BoxItem>
      <S.BoxItemTitle>
        <p>{I18n.get("Sobre o Parceiro")}</p>
        <span />
      </S.BoxItemTitle>
      {isLoading ? (
        <Loading
          text={I18n.get("Carregando...")}
          sizeText={14}
          sizeIcon={16}
          color="#000000"
          align="left"
        />
      ) : (
        <S.ItemsText>
          {renderItem({ label: I18n.get("Parceiro"), item: data?.partnerName })}
          {renderItem({ label: I18n.get("Nome do Líder"), item: data?.partnerResponsibleName })}
          {renderItem({ label: I18n.get("Contato"), item: data?.partnerResponsiblePhoneNumber })}
          {renderItem({ label: I18n.get("Email"), item: data?.partnerResponsibleEmail })}
        </S.ItemsText>
      )}
    </S.BoxItem>
  );
};
