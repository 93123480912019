import React, {PureComponent} from 'react';
import { I18n } from 'aws-amplify';
import { Row, Col, Button, Icon } from 'antd';
import { Link } from 'react-router-dom'
import thumbFake from '../../../image/thumb-fake.png';

class FileItem extends PureComponent {
    state = {
        open: false
    }
    render() { 
        const {item, target} = this.props;
        return (
            <div className="bp-file-preview">
                <a href={item.content} target="_blank">
                <Icon type="file" theme="filled" />{" "}{item.title}
                </a>                
            </div>
        );
    }
}
 
export default FileItem;