import React, { Component } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import ItemMacro from "../../modules/components/ItemFileMacros";
import FormUpload from "../../modules/components/formUploadMacro";


import {
  Row,
  Col,
  Table,
  Icon,
  notification,
  Tag,
  Drawer,
  Popconfirm,
  Button,
} from "antd";

const MainTable = styled(Table)`
  .statusMacro {
    text-align: center;
  }

  .btupload {
    position: relative;
    color: #6d6d6d;

    span {
      float: left;
    }

    i {
      font-style: normal;
      margin-right: 5px;
      position: relative;
      top: 5px;
    }

    &:hover {
      color: #1883ff;
    }
  }

  .ant-table-expanded-row td {
    background: #f7f7f7;
    padding: 0px;

    .tableMilestoneFiles {
      padding: 10px 20px 10px 10px;

      td {
        padding: 7px;
      }
    }
  }
`;

const Main = styled.div`
  max-width: 1024px;
  margin: 0px auto 0px;
  box-sizing: border-box;
  padding: 0;
  border-radius: 20px;

  &.review {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  .miniTit {
    margin-top: 20px;
  }

  .form {
    margin-top: 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 0px;
  }
`;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

class ViewMacro extends Component {
  constructor(props) {
    super();
    this.state = {
      visible: false,
      activeKey: "",
      milestoneId: "",
      loading: false,
    };
  }

  DeleteFile = (data) => {
    const dataDelete = {
      ...data,
    };

    this.props.deleteFileMilestone(dataDelete);
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  openModal = (id) => {
    this.setState({
      milestoneId: id,
      visible: true,
    });
  };

  onChange = (e) => {
    let data = {
      flag: e.target.checked,
      projectId: this.props.projectId,
    };

    this.props.update(data);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (this.props.errors != prevProps.errors && this.props.errors) {
      Object.keys(this.props.errors).map((field) => {
        if (field == "name" || field == "estimatedDate") {
          this.props.form.setFields({
            [field]: {
              value: this.state.formState[field],
              errors: [new Error(this.props.errors[field].errorMessage)],
            },
          });
        }
      });
    }

    if (
      this.props.milestones &&
      prevProps.milestones &&
      this.props.milestones.length > prevProps.milestones.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("Macro entrega foi adicionada"),
        duration: 3,
      });

      let after = this.props.milestones && this.props.milestones.length - 1;
      this.setState({
        activeKey: after.toString(),
      });
    }

    // if (this.props.milestones && prevProps.milestones && this.props.milestones.length < prevProps.milestones.length) {

    //     notification.success({
    //         message: I18n.get('Removido com sucesso!'),
    //         description: I18n.get('Macro entrega foi removida'),
    //         duration: 3
    //     });
    // }

    if (
      this.props.milestones &&
      prevProps.milestones &&
      this.props.milestones != prevProps.milestones
    ) {
      this.setState({
        visible: false,
      });
    }
  }

  render() {
    const loading = this.props.loading || {};

    let dataSource =
      this.props.milestones &&
      this.props.milestones.map((item, index) => {
        return {
          key: index,
          name: item.name,
          date: item.estimatedDate,
          item: item,
        };
      });

    const columns = [
      {
        title: I18n.get("Nome"),
        dataIndex: "name",
        key: "name",
      },

      {
        title: I18n.get("Data"),
        dataIndex: "date",
        key: "date",
      },
      {
        title: I18n.get("Arquivos"),
        dataIndex: "files",
        key: "files",
        width: 140,
        render: (files, record) => {
          if (!this.props.isAdmin) {
            return (
              <a
                className="btupload"
                onClick={() => this.openModal(record.item.milestoneId)}
              >
                <span class="material-icons md-48">file_upload</span>{" "}
                <i>{I18n.get("Upload")}</i>
              </a>
            );
          } else {
            return <p>{record.item.files.length}</p>;
          }
        },
      },
      {
        title: I18n.get("Entrega do parceiro"),
        dataIndex: "deliveryPartner",
        key: "deliveryPartner",
        render: (files, record) => {
          const data = {
            milestoneId: record.item.milestoneId,
            projectId: this.props.projectId,
            prefix: "P",
            confirm: false,
          };

          const partnerDate = new Date(
            record.item.partnerConfirmDeliveredOnFmt
          );
          const clientDate = new Date(record.item.clientConfirmDeliveredOnFmt);

          if (this.props.isPartner) {
            if (
              (record.item.partnerConfirmDelivered &&
                record.item.clientConfirmDelivered === false &&
                clientDate.getTime() > partnerDate.getTime()) ||
              !record.item.partnerConfirmDelivered
            ) {
              return (
                <p className="">
                  <Popconfirm
                    title={I18n.get("Confirma a entrega dessa macro?")}
                    onConfirm={() => {
                      data.confirm = true;

                      this.props.confirmMilestone(data);
                    }}
                    onCancel={() => console.log("NAO")}
                    okText={I18n.get("Sim")}
                    cancelText={I18n.get("Não")}
                  >
                    <Button className="button bt-bpool bsmall black">
                      {I18n.get("Confirmar")}
                    </Button>
                  </Popconfirm>
                </p>
              );
            } else {
              return (
                <p className="checkMilestone">
                  <Icon type="check-circle" theme="filled" />
                </p>
              );
            }
          } else {
            if (
              (record.item.partnerConfirmDelivered &&
                record.item.clientConfirmDelivered === false &&
                clientDate.getTime() > partnerDate.getTime()) ||
              !record.item.partnerConfirmDelivered
            ) {
              return <Tag>{I18n.get("Aguardando")}</Tag>;
            } else {
              return (
                <p className="checkMilestone">
                  <Icon type="check-circle" theme="filled" />
                </p>
              );
            }
          }
        },
      },
      {
        title: I18n.get("Aprovação do cliente"),
        dataIndex: "approval",
        key: "approval",
        render: (files, record) => {
          let data = {
            milestoneId: record.item.milestoneId,
            projectId: this.props.projectId,
            prefix: "C",
            confirm: false,
          };

          const partnerDate = new Date(
            record.item.partnerConfirmDeliveredOnFmt
          );
          const clientDate = new Date(record.item.clientConfirmDeliveredOnFmt);

          if (!this.props.isPartner) {
            if (!record.item.partnerConfirmDelivered) {
              return "";
            } else if (
              record.item.partnerConfirmDelivered &&
              record.item.clientConfirmDelivered
            ) {
              return (
                <p className="checkMilestone">
                  <Icon type="check-circle" theme="filled" />
                </p>
              );
            } else if (
              record.item.partnerConfirmDelivered &&
              record.item.clientConfirmDelivered === false &&
              clientDate.getTime() > partnerDate.getTime()
            ) {
              return (
                <p className="errorMilestone">
                  <Icon type="close-circle" theme="filled" />
                </p>
              );
            } else if (
              (record.item.partnerConfirmDelivered &&
                record.item.clientConfirmDelivered === false &&
                clientDate.getTime() < partnerDate.getTime()) ||
              record.item.partnerConfirmDelivered
            ) {
              return (
                <p className="">
                  <Popconfirm
                    title={I18n.get("Deseja aprovar essa entrega?")}
                    onConfirm={() => {
                      data.confirm = true;

                      this.props.confirmMilestone(data);
                    }}
                    onCancel={() => this.props.confirmMilestone(data)}
                    okText={I18n.get("Aprovar")}
                    cancelText={I18n.get("Reprovar")}
                  >
                    <Button className="button bt-bpool bsmall black">
                      {I18n.get("Aprovar")}
                    </Button>
                  </Popconfirm>
                </p>
              );
            }
          } else {
            if (
              record.item.partnerConfirmDelivered &&
              record.item.clientConfirmDelivered === false &&
              record.item.partnerConfirmDelivered &&
              record.item.clientConfirmDelivered === false &&
              clientDate.getTime() > partnerDate.getTime()
            ) {
              return (
                <p className="errorMilestone">
                  <Icon type="close-circle" theme="filled" />
                </p>
              );
            } else if (
              (record.item.partnerConfirmDelivered &&
                record.item.clientConfirmDelivered === false &&
                clientDate.getTime() < partnerDate.getTime()) ||
              (record.item.partnerConfirmDelivered &&
                !record.item.clientConfirmDelivered)
            ) {
              return <Tag>{I18n.get("Em analise")}</Tag>;
            } else if (
              !record.item.partnerConfirmDelivered &&
              !record.item.clientConfirmDelivered
            ) {
              return "";
            } else {
              return (
                <p className="checkMilestone">
                  <Icon type="check-circle" theme="filled" />
                </p>
              );
            }
          }
        },
      },
      {
        title: "",
        dataIndex: "item",
        key: "item",
        render: (text, record) => {
          let data = {
            projectId: this.props.projectId,
            milestoneId: record.item.milestoneId,
          };

          if (!this.props.isReview && this.props.isPartner) {
            // if(this.props.disabled){

            return (
              <p>
                <Popconfirm
                  title={I18n.get("Deseja excluir essa macro?")}
                  onConfirm={() => this.props.onDelete(data)}
                  onCancel={() => console.log("NAO")}
                  okText={I18n.get("Sim")}
                  cancelText={I18n.get("Não")}
                >
                  <a>
                    <Icon type="delete" theme="filled" />
                  </a>
                </Popconfirm>
              </p>
            );
            // }
          }
        },
      },
    ];

    let limitMacro =
      this.props.milestones && this.props.milestones.length >= 5 ? true : false;

    return (
      <Main className={this.props.isReview ? "review" : ""}>
        <br />
        <div className="table">
          <Row>
            <Col sm={24}>
              {!this.props.isAdmin && (
                <div>
                  {loading && loading.busy && loading.busy && (
                    <p className="iconeLoading">
                      <Icon type="loading" />
                    </p>
                  )}
                </div>
              )}
              {!loading.busy && dataSource && (
                <MainTable
                  locale={{ emptyText: I18n.get("Sem informação") }}
                  dataSource={!loading.busy && dataSource}
                  columns={columns}
                  expandIcon={false}
                  defaultExpandAllRows={true}
                  expandedRowRender={(record) => {
                    if (record.item.files.length > 0) {
                      return (
                        <ItemMacro
                          className={
                            record.item.files.length > 0 ? "" : "itemNone"
                          }
                          item={record.item}
                          username={this.props.username}
                          deleteFile={this.DeleteFile}
                          disabled={
                            !record.item.partnerConfirmDelivered ||
                            !record.item.clientConfirmDelivered
                          }
                        />
                      );
                    } else {
                      return false;
                    }
                  }}
                  pagination={false}
                  updateMilestoneId={this.updateMilestoneId}
                  username={this.props.username}
                />
              )}
            </Col>
          </Row>
        </div>

        <Drawer
          title={I18n.get("Upload")}
          placement="right"
          closable={true}
          onClose={() => this.closeModal()}
          destroyOnClose={true}
          visible={this.state.visible}
          width="30%"
        >
          <FormUpload
            action={this.props.upload_milestone}
            projectId={this.props.projectId}
            milestoneId={this.state.milestoneId}
          />
        </Drawer>
      </Main>
    );
  }
}

export default ViewMacro;
