import React from "react";
import { I18n } from "aws-amplify";
import { StatusBPayment } from "../../../components/StatusBPayment";
import * as S from "./styles";

export const InfosDrawer = ({ data, isLoading }) => {
  return (
    <div>
      <S.TitleDrawer>
        {I18n.get("Acompanhe por aqui todos os passos do seu pagamento")}
      </S.TitleDrawer>
      <S.Infos>
        <div>
          <label>{I18n.get("Fornecedor")}</label>
          <p>{data?.name}</p>
        </div>
        <div>
          <label>{I18n.get("Email")}</label>
          <p>{data?.emailPrimary}</p>
        </div>
        <div>
          <label>{I18n.get("Valor")}</label>
          <p>{data?.totalFmt}</p>
        </div>
      </S.Infos>
      <StatusBPayment
        data={data?.status}
        emailPrimary={data?.emailPrimary}
        isLoading={isLoading}
      />
    </div>
  );
};
