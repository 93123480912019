import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';
import WhatifWrapper from '../components/WhatifWrapper';

import SubHeader from '../components/SubHeader';

import PageSuccess from '../components/PageSuccess';

class IdeaRegisterSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <WhatifWrapper>
                <SubHeader />
                <PageSuccess>
                    <Fragment>
                        <h2>Tudo certo!</h2>
                        <p>Sua ideia foi enviada com sucesso, você será contatado se for selecionada pelo cliente!</p>
                    </Fragment>
                </PageSuccess>
            </WhatifWrapper>
        );
    }
}

export default IdeaRegisterSuccess;
