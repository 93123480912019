import React from "react";
import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";

import PartnerInfoForm from "./partner-info-form";

import ProjectReview from "../shared/_project-review";

import SubHeader from "./__sub-header";

import { scrollToTop } from "../../utils/ui";

import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";

import { Icon } from 'antd';

class NewProjectStep3 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      disabledBtn: false,
    };
  }

  handleDisabledButton = (disabled) => {
    this.setState({ disabledBtn: disabled });
  };

  componentDidMount() {}

  render() {
    const logo =
      this.props.client &&
      this.props.client.company &&
      this.props.client.company.logo;

    const {
      isCreative,
      isEvent,
      isBPitch,
      customEvent = {},
      bPitch = {},
      isCustomEvent
    } = this.props;

    const subHeaderProps = {
      isCreative,
      isEvent,
      isBPitch,
      customEvent,
      bPitch,
      isCustomEvent
    };

    const step = this.props.isCreative ? 4 : 3;

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-3">
          <SubHeader
            projectId={this.props.match.params.projectId}
            active={step}
            logo={logo}
            {...subHeaderProps}
          />
        </div>
        <div className="client-step client-step-1 review-bg uplift">
          <div className="container client-container-1 uplift-step-3">
            <div className="columns">
              <div className="column is-5">
                <PartnerInfoForm {...this.props} handleDisabledButton={this.handleDisabledButton}/>

                <br />
                <br />

                <div className="field">
                  <p
                    className="control is-expanded"
                    onClick={(e) => scrollToTop}
                  >
                    <Link
                      to={`/cliente/projetos/${
                        this.props.match.params.projectId
                      }/passo-${step - 1}`}
                      disabled={this.state.disabledBtn}
                      style={{float:'left'}}
                    >
                   

                      <ButtonBpool
                              text={I18n.get("Voltar")}
                              theme="secondary"
                              disabled={this.state.disabledBtn}
                              
                            />
                    </Link>
                    <Link
                      to={`/cliente/projetos/${
                        this.props.match.params.projectId
                      }/passo-${step + 1}`}
                      className="pull-right"
                      disabled={this.state.disabledBtn}
                    >
                     {/* {this.state.disabledBtn && <Icon type="loading" />} {" "} {I18n.get("Próximo")}  */}
                     <ButtonBpool
                              text={I18n.get("Próximo")}
                              className=""
                              theme="primary"
                              loading={this.state.disabledBtn}
                            />
                    </Link>
                  </p>
                </div>

                <br />
              </div>

              <div className="column is-2"></div>

              <div className="column is-3 is-offset-1">
                <ProjectReview
                  {...this.props}
                  {...this.props.project}
                  step={3}
                />
              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(NewProjectStep3);
