import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { I18n } from "aws-amplify";
import { useSessionStorage } from "../../../../../../hooks/useSessionStorage/useSessionStorage.hook";
import { ButtonBpool } from "../../../../../../components/UI/ButtonBpool";

import { ratings } from "./ratings";
import { KEY_PARTNERS_RATES } from "../../constants/keys.storage";

import * as S from "./styles";

import { Icon1, Icon2, Icon3, Icon4, Icon5 } from "./svgs/icons";

export const RateBox = ({ reasonsPartner, cbFinalize, partnerId }) => {
  let params = useParams();
  const { set, get } = useSessionStorage();

  const [partnerRated, setPartnerRated] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [ratingPartner, setRatingPartner] = useState({});
  const [isDisabledRateButton, setIsDisabledRateButton] = useState(true);

  const renderIcon = (icon, active, label) => {
    if (icon === "pessimo") {
      return (
        <>
          <Icon1 active={active} />
          <p style={{ opacity: active ? 1 : "" }}>{label}</p>
        </>
      );
    }
    if (icon === "ruim") {
      return (
        <>
          <Icon2 active={active} />
          <p style={{ opacity: active ? 1 : "" }}>{label}</p>
        </>
      );
    }
    if (icon === "regular") {
      return (
        <>
          <Icon3 active={active} />
          <p style={{ opacity: active ? 1 : "" }}>{label}</p>
        </>
      );
    }
    if (icon === "bom") {
      return (
        <>
          <Icon4 active={active} />
          <p style={{ opacity: active ? 1 : "" }}>{label}</p>
        </>
      );
    }
    if (icon === "excelente") {
      return (
        <>
          <Icon5 active={active} />
          <p style={{ opacity: active ? 1 : "" }}>{label}</p>
        </>
      );
    }
  };

  const enableFinalize = async () => {
    const itemsRateds = await get({ key: KEY_PARTNERS_RATES });

    if (itemsRateds && itemsRateds?.reasons2?.length >= 3) {
      cbFinalize();
    }
  };

  const rate = async () => {
    const send = {
      projectId: params.projectId,
      reasons2: [ratingPartner],
    };

    const itemsStoraged = await get({ key: KEY_PARTNERS_RATES });

    if (itemsStoraged) {
      const arrUpdated = {
        projectId: itemsStoraged.projectId,
        reasons2: [...itemsStoraged.reasons2, ratingPartner],
      };
      await set({ key: KEY_PARTNERS_RATES, data: arrUpdated });
      await enableFinalize();
    } else {
      await set({ key: KEY_PARTNERS_RATES, data: send });
    }

    setPartnerRated(true);
  };

  function checkGrades(array) {
    return array?.every(
      (item) =>
        item?.hasOwnProperty("grade") && item?.grade >= 1 && item?.grade <= 5
    );
  }

  const ratePartner = async () => {
    const checkGradesResult = checkGrades(ratingPartner?.grades);

    if (checkGradesResult) {
      await rate();
      setIsRating(isRating ? false : true);
    }
  };

  const handleChangeRate = (code, value) => {
    const gradesModify = ratingPartner?.grades?.map((item) => {
      if (item?.reason?.code === code) {
        return { ...item, grade: value };
      } else {
        return item;
      }
    });

    setRatingPartner({ ...ratingPartner, grades: gradesModify });
  };

  const onChangeTextComment = (text) => {
    setRatingPartner({ ...ratingPartner, comment: text });
  };

  const mapRates = async () => {
    const rates = await get({ key: KEY_PARTNERS_RATES });

    if (rates && rates?.reasons2?.length) {
      const finded = rates?.reasons2?.find(
        (rate) => rate?.partnerId === partnerId
      );

      if (finded) {
        setPartnerRated(true);
      }
    }
  };

  useEffect(() => {
    if (reasonsPartner?.partnerId) {
      setRatingPartner(reasonsPartner);
      mapRates();
    }
  }, [reasonsPartner]);

  useEffect(() => {
    const checkGradesResult = checkGrades(ratingPartner?.grades);

    if (checkGradesResult) {
      setIsDisabledRateButton(false);
    } else {
      setIsDisabledRateButton(true);
    }
  }, [ratingPartner]);

  return (
    <S.Wrapper isRating={isRating}>
      <S.ContentNoRating isRating={isRating}>
        <ButtonBpool
          text={I18n.get(
            partnerRated ? I18n.get("Avaliado!") : I18n.get("Avaliar!")
          )}
          theme="primary"
          full
          onClick={
            partnerRated
              ? () => true
              : () => setIsRating(isRating ? false : true)
          }
          disabled={partnerRated ? true : false}
        />
      </S.ContentNoRating>
      <S.ContentIsRating isRating={isRating}>
        {ratingPartner?.grades?.map((rating, index) => (
          <div key={index}>
            <S.Title>{I18n.get(rating?.reason?.label)}</S.Title>
            <S.ListRatings>
              {ratings?.map((item) => (
                <li key={item.code}>
                  <S.ButtonIcon
                    onClick={() =>
                      handleChangeRate(rating?.reason?.code, item.code)
                    }
                  >
                    {renderIcon(
                      item.icon,
                      item.code === rating?.grade ? true : false,
                      item.label
                    )}
                  </S.ButtonIcon>
                </li>
              ))}
            </S.ListRatings>
          </div>
        ))}
        <div>
          <S.Title>
            {I18n.get(
              "Gostaria de deixar um feedback construtivo a este parceiro? (Opcional)"
            )}
          </S.Title>
          <S.Textarea
            value={ratingPartner?.comment ? ratingPartner?.comment : ""}
            onChange={(e) => onChangeTextComment(e.target.value)}
            placeholder={I18n.get("Mensagem")}
          ></S.Textarea>
        </div>
        <ButtonBpool
          text={I18n.get("Avaliar parceiro")}
          theme="primary"
          full
          onClick={() => ratePartner()}
          disabled={isDisabledRateButton}
        />
      </S.ContentIsRating>
    </S.Wrapper>
  );
};
