import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PARTNER_LEADER_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_partner_update_leader = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.put(`${Config.API_BASE_URL}/partner/${data.partnerId}/leader`, data, config)
            .then(response => {

                dispatch({
                    type: PARTNER_LEADER_UPDATE,
                    payload: {
                        ...response.data,
                        errors: {},
                        task: {
                            result: true
                        }
                    }
                });

            })
            .catch(error => {

                dispatch({
                    type: PARTNER_LEADER_UPDATE,
                    payload: {
                        errors: error.response.data,
                        task: {
                            result: false
                        }
                    }
                });
            });
}).catch(error => { 
 dispatch({ type: EXPIRED }); 
 }); 
 };
}

export default api_partner_update_leader;