import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Tag, Modal, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { SelectOne } from '../../../../components/UI/SelectOne';
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { BP_API } from "../../../../../actions/bp/actions";

import * as S from './styles';

const objStatus = {
  1: {
    color: 'blue',
    text: 'Pré register',
  },
  5: {
    color: 'cyan',
    text: 'Full register',
  },
  3: {
    color: 'gold',
    text: 'Pré register - 3 References',
  },
  2: {
    color: 'orange',
    text: 'Pré register - Missing references',
  },
  7: {
    color: 'green',
    text: 'Approved',
  },
  99: {
    color: 'red',
    text: 'Other',
  },
};

const listStatus = [
  {
    label: 'Alterar para o FULL',
    code: 1,
  },
];

export const PartnerStatus = ({ title, status, changePartnerStatus }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const bp = useSelector((state) => state?.bp);
  const [visibleModal, setVisibleModal] = useState(false);
  const [changeStatus, setChangeStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const whatStatus = ({ status }) => {
    return (
      <Tag color={objStatus[status]?.color}>
        <CheckCircleOutlined />
        {I18n.get(objStatus[status]?.text)}
      </Tag>
    );
  };

  const showModal = () => {
    setVisibleModal(visibleModal ? false : true);
  };

  const handleCancel = () => {
    setVisibleModal(false);
  };

  const handleSend = async () => {
    if (changeStatus === 1) {
      const data = {
        partnerId: bp?.partner?.partnerId,
        status: 5,
      };
      dispatch(BP_API.update_partner(data));
    } else {
      console.log('send else');
    }
  };

  const handleOk = async () => {
    setLoading(true);
    await handleSend();
    setLoading(false);
    setVisibleModal(false);
  };

  const onChangeStatus = (handleStatus) => {
    setChangeStatus(handleStatus);
  };

  return (
    <>
      <div className="card">
        <div className="card-content">
          <Row gutter={1}>
            <Col sm={24}>
              <h3 className="title is-4">{I18n.get(title)}</h3>
              <S.ContentPartnerStatus>
                {whatStatus({ status })}
                {changePartnerStatus ? (
                  <S.ButtonChangeStatus onClick={showModal}>
                    {I18n.get('Change status')}
                  </S.ButtonChangeStatus>
                ) : (
                  null
                )}
              </S.ContentPartnerStatus>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        centered
        width={700}
        title={I18n.get('Change Status Partner')}
        visible={visibleModal}
        onCancel={handleCancel}
        footer={[
          <S.ActionsButtons>
            <ButtonBpool
              text={I18n.get('Cancel')}
              theme="secondary"
              full={false}
              onClick={handleCancel}
            />
            <ButtonBpool
              text={I18n.get('Save status')}
              theme="primary"
              full={false}
              onClick={handleOk}
              loading={loading}
              disabled={changeStatus === null ? true : false}
            />
          </S.ActionsButtons>
        ]}
      >
        <S.ContentSelect>
          <SelectOne
            options={listStatus}
            value={changeStatus}
            onChange={(value) => onChangeStatus(value)}
            placeholder={I18n.get('Status')}
            defaultActiveFirstOption={true}
            // error=""
          />
          {errors?.length ? (
            <S.Errors>
              <h4>Houve um ou mais erros:</h4>
              <ul>
                <li>Erro na troca de status 1</li>
                <li>Erro na troca de status 2</li>
                <li>Erro na troca de status 3</li>
                <li>Erro na troca de status 4</li>
              </ul>
            </S.Errors>
          ) : null}
        </S.ContentSelect>
      </Modal>
    </>
  );
};
