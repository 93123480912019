import React, { Fragment } from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../utils/task';

import PartnerCard from '../partner/shared/_partner-card';
import PartnerFullCard from '../partner/shared/_partner-full-card';

import CheckboxField from '../form/checkbox-field';
import LogoButton from '../form/logo-button';

import { scrollToTop } from '../../utils/ui';

import QuotationForm from '../partner-project-invitation/quotation-form';

import ProjectMessaging from '../shared/_project-messaging';

import NakedModal from '../ui/naked-modal';

import Terms from './_terms';

import SubmitButtonField from '../form/submit-button-field';

import PartnerInfos from '../../modules/components/PartnerInfos';
import HeaderThumbCover from '../../modules/components/HeaderThumbCover';
import ContainerPage from '../../modules/components/ContainerPage';

import ScorecardForm from './_bpitch-scorecard';

import PartnerInviteForm from './_partner-invite-form';

import { getQuotationStatus } from '../../utils/extras';

import SelectField from '../form/select-field';

import {
    Icon,
    Button,
    Modal
} from 'antd';

import styled from 'styled-components';

import Chat from '../client/chat-modal-client';
import SimpleModal from '../ui/simple-modal';
import PartnerScheduling from '../shared/partner-scheduling';
import Config from '../../config';


const Main = styled.div`
  padding-bottom: 100px;
  max-width: 1000px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  color: #353535;
`;


class PartnerDetailsView extends React.Component {
    constructor(props) {

        super();

        console.log({ tab: props.tab })

        this.state = {
            fields: {
                quotationIdx: {
                    onChange: this.onChangeQuotation.bind(this),
                    successIcon: false,
                },
            },
            form: {
            },
            errors: {},
            tab: props.tab || 'P',
            submit_task: {},
            partnerSelect: {},
            messaging: false,
            error: false,
            task: {},

        };

        this.closeModal = this.onCloseModal.bind(this);
    }

    onChangeQuotation(e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['quotationIdx'] = value;

            return nextState;
        });
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });


            Object.keys(this.state.fields).forEach((item) => {

                const value = this.props[item];

                nextState.form[item] = value;


            });

            this.setState(nextState);
        }
    }


    componentWillUnmount() {
    }

    selectTab(tab, e) {

        e.preventDefault();



        this.setState({ tab: tab });
    }


    getData() {

        var data = {
            ...this.props.project
        };

        if (this.state.partnerId) {
            data['partnerId'] = this.state.partnerId;
        }

        return data;
    }

    selectPartner(item) {

        this.setState({ partnerId: item.partnerId }, function () {

            var task = new Task();

            task.start();

            this.setState({ task: task }, function () {

                // this.props.save(this.getData());
                localStorage.setItem("selectedPartnerId", item.partnerId);

                this.props.history.push(`/cliente/eventos/${this.props.projectId}/parceiro-passo-2`);
            });
        });
    }

    onInvitePartnerForm(e) {

        e.preventDefault();

        this.setState({ invitePartner: true });
    }

    onInvitePartner({ partnerId, quotationDeadline }) {

        // As ultimas implementacoes na tela quebraram a logica para Live Marketing, desabilitando os termos por ora para a tela voltar a funcionar

        // if (this.props.isEvent && !this.props.eventTerms && !this.state.form.eventTerms) {

        //     this.setState((prevState) => {

        //         var nextState = Object.assign({}, prevState);

        //         nextState.termsIsOpen = true;

        //         return nextState;
        //     });
        // }

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        const data = {
            projectId: this.props.match.params.projectId,
            eventTerms: true,
            quotationDeadline: quotationDeadline,
            partners: [partnerId]
        };

        this.props.updateEventPartners(data)
            .then(({ task, errors }) => {

                this.setState((prevState) => {

                    var nextState = Object.assign({}, prevState);

                    nextState.submit_task = task;
                    nextState.errors = errors;
                    nextState.termsIsOpen = false;
                    nextState.form.partners = [];

                    if (task.result) {
                        nextState.invitePartner = null;
                    }

                    return nextState;
                });

                // refresh dos parceiros

                const data = {
                    projectId: this.props.match.params.projectId
                };

                this.props.get_partners(data);
            });

    }

    onCloseModal(e) {

        if (e) e.preventDefault();

        this.setState({ termsIsOpen: false });
    }

    render() {

        const { isBPitch, bPitch = {}, partners = [], partners2 = [], isBiddingPhasePartnerInvite, isBiddingPhaseQuotation } = this.props;

        const enableMessaging = this.props.settings && this.props.settings.enableMessaging;

        const selected = this.props.partner && this.props.partners2.find((p) => p.id == this.props.partner.id);

        const userMessages = selected && selected.userMessages && selected.userMessages.filter((item) => item.projectId == this.props.match.params.projectId);

        const { eventQuotationHistory = [] } = this.props;

        const quotations = eventQuotationHistory && selected && eventQuotationHistory.filter((item) => item.id == selected.id).map((item, index) => ({ code: index, label: `${getQuotationStatus(this.props.auth, item)} - ${item.updatedOn}` }));

        let quotation = {};

        if (this.state.form.quotationIdx != '' && this.state.form.quotationIdx >= 0) {

            quotation = eventQuotationHistory[this.state.form.quotationIdx];
        }
        else {

            quotation = selected && this.props.eventQuotations && this.props.eventQuotations.find((item) => item.id == selected.id);
        }

        console.log({ partner: this.props.partner, selected: selected });

        return (
            <div style={{ marginTop: "170px" }}>

                {/* <div style={{ marginBottom: '1rem', position: 'fixed', top: '170px', right: '20px', zIndex: 999 }}>

                        <a onClick={this.hideDetails.bind(this)} class="delete is-large"></a>

                    </div> */}

                <div className="container client-container-1">

                    <div className="tabsPartnerInfo">


                        <button onClick={this.props.hideDetails} className='button bt-bpool grey previous'>{I18n.get("< Voltar")}</button> &nbsp;&nbsp;


                        <button onClick={this.selectTab.bind(this, 'Q')} className={['button bt-bpool quad', this.state.tab !== 'Q' ? 'grey' : ''].join(' ')}>{I18n.get("Proposta")}</button>

                        {isBPitch && <>

                            <button onClick={this.selectTab.bind(this, 'C')} className={['button bt-bpool quad', this.state.tab !== 'C' ? 'grey' : ''].join(' ')}>{I18n.get("Scorecard")}</button>

                        </>}

                        <button onClick={this.selectTab.bind(this, 'P')} className={['button bt-bpool quad', this.state.tab !== 'P' ? 'grey' : ''].join(' ')}>{I18n.get("Portfolio")}</button>


                        {enableMessaging && <>

                            <button onClick={this.selectTab.bind(this, 'M')} className={['button bt-bpool quad', this.state.tab !== 'M' ? 'grey' : ''].join(' ')}>{I18n.get("MENSAGENS")}</button>

                        </>}

                    </div>

                </div>

                {this.state.tab === 'P' &&

                    <div style={{ marginTop: "20px" }}>
                        <HeaderThumbCover
                            thumb={selected.company && selected.company.logoImage}
                            bgHeader={selected.company && selected.company.headerImage}
                            title=""
                        />

                        <ContainerPage>
                            <Main>
                                <PartnerInfos
                                    infos={selected && selected}
                                    specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                                    sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                    isPresentation={true}
                                    diversity={this.props.bp.diversity && this.props.bp.diversity}
                                />
                            </Main>
                        </ContainerPage>
                    </div>

                    // <PartnerFullCard {...selected} />

                }

                {this.state.tab === 'C' && <div style={{ backgroundColor: '#fff', padding: '1rem' }}>
                    <div className="container client-container-1">

                        <article className="media">

                            {selected.company && selected.company.logoImage && <figure className="media-left">
                                <p className="image is-64x64">
                                    <img src={selected.company.logoImage} />
                                </p>
                            </figure>}

                            <div className="media-content">
                                <div className="content">
                                    <p className="media-card-content-top">
                                        <strong>{selected.company && selected.company.name}</strong> <br />
                                        <small>{selected.company && selected.company.city} - {selected.company && selected.company.state}</small> <br />
                                    </p>
                                </div>
                            </div>
                        </article>

                        <br />

                        <p className="title is-5">{I18n.get('Scorecard')}</p>
                        <p class="subtitle is-6">{I18n.get('Por favor preencha o scorecard abaixo dando notas aos critérios estabelecidos no início do processo. Ele servirá não só para determinar o vencedor da fase de Proposta mas também para que os parceiros, independente de êxito ou não, possam implementar melhorias para os próximos processos que participarem.')}</p>

                        <div style={{ marginTop: '3rem', padding: '1rem' }}>

                            <ScorecardForm {...this.props} partner={selected} />

                        </div>

                        <br /><br />

                    </div>

                </div>}

                {this.state.tab === 'Q' && <div style={{ backgroundColor: '#fff', padding: '1rem' }}>
                    <div className="container client-container-1">

                        {this.props.auth.is_client_or_client_user && <>

                            <div>

                                <p style={{ fontWeight: 'bold' }}>
                                    {I18n.get('Veja abaixo a proposta simplificada enviada pelo parceiro.')}
                                </p>
                                <p style={{ marginTop: '0.50rem', fontSize: '90%' }}>
                                    {I18n.get('Ao definir com quem quer seguir, você vai poder pedir um detalhamento da proposta.')}
                                </p>

                            </div>

                        </>}

                        <br /><br />


                        <div className="columns">
                            <div className="column is-5">

                                <article className="media">

                                    {selected.company && selected.company.logoImage && <figure className="media-left">
                                        <p className="image is-64x64">
                                            <img src={selected.company.logoImage} />
                                        </p>
                                    </figure>}

                                    <div className="media-content">
                                        <div className="content">
                                            <p className="media-card-content-top">
                                                <strong>{selected.company && selected.company.name}</strong> <br />
                                                <small>{selected.company && selected.company.city} - {selected.company && selected.company.state}</small> <br />
                                            </p>
                                        </div>
                                    </div>
                                </article>


                            </div>

                            <div className="column is-2">

                                <SelectField
                                    {...this.state.fields.quotationIdx}
                                    label={I18n.get("Histórico de Orçamentos")}
                                    value={this.state.form.quotationIdx}
                                    error={this.state.errors.quotationIdx}
                                    values={quotations} />

                            </div>
                        </div>

                        <br /><br />

                        <p className="title">
                            {I18n.get("Orçamento")}

                            {this.state.form.quotationIdx != '' && this.state.form.quotationIdx >= 0 && <>
                                &nbsp;-&nbsp;{I18n.get("Histórico")}
                            </>}

                        </p>

                        <QuotationForm {...this.props} quotation={quotation} selectPartnerButton={
                            <SubmitButtonField
                                label={I18n.get("Quero este parceiro")}
                                className="button bt-bpool"
                                task={this.state.task}
                                disabled={!quotation}
                                onClick={this.selectPartner.bind(this, selected)}
                            />
                        } />
                    </div>

                </div>}

                {this.state.tab === 'M' && <div className="container client-container-1">

                    <div style={{ backgroundColor: '#fff', padding: '1rem' }}>

                        <article className="media">

                            {selected.company && selected.company.logoImage && <figure className="media-left">
                                <p className="image is-64x64">
                                    <img src={selected.company.logoImage} />
                                </p>
                            </figure>}

                            <div className="media-content">
                                <div className="content">
                                    <p className="media-card-content-top">
                                        <strong>{selected.company && selected.company.name}</strong> <br />
                                        <small>{selected.company && selected.company.city} - {selected.company && selected.company.state}</small> <br />
                                    </p>
                                </div>
                            </div>
                        </article>



                        <br />

                        <p className="title">{I18n.get("Mensagens")}</p>

                        <ProjectMessaging {...this.props} userMessages={userMessages} recipientId={selected.id} />

                    </div>

                </div>}


                <div className="has-text-centered" style={{ padding: '1.5rem' }}>


                </div>


                {/* <div style={{ marginTop: '1rem' }}>

                        <a href="" onClick={this.hideDetails.bind(this)}>&lt; voltar</a>

                    </div> */}

            </div>

        );
    }
}

export default PartnerDetailsView;
