import { I18n } from 'aws-amplify';

const STATES = [
    { "label": "Acre", "code": "AC" },
    { "label": "Alagoas", "code": "AL" },
    { "label": "Amapá", "code": "AP" },
    { "label": "Amazonas", "code": "AM" },
    { "label": "Bahia", "code": "BA" },
    { "label": "Ceará", "code": "CE" },
    { "label": "Distrito Federal", "code": "DF" },
    { "label": "Espírito Santo", "code": "ES" },
    { "label": "Goiás", "code": "GO" },
    { "label": "Maranhão", "code": "MA" },
    { "label": "Mato Grosso", "code": "MT" },
    { "label": "Mato Grosso do Sul", "code": "MS" },
    { "label": "Minas Gerais", "code": "MG" },
    { "label": "Pará", "code": "PA" },
    { "label": "Paraíba", "code": "PB" },
    { "label": "Paraná", "code": "PR" },
    { "label": "Pernambuco", "code": "PE" },
    { "label": "Piauí", "code": "PI" },
    { "label": "Rio de Janeiro", "code": "RJ" },
    { "label": "Rio Grande do Norte", "code": "RN" },
    { "label": "Rio Grande do Sul", "code": "RS" },
    { "label": "Rondônia", "code": "RO" },
    { "label": "Roraima", "code": "RR" },
    { "label": "Santa Catarina", "code": "SC" },
    { "label": "São Paulo", "code": "SP" },
    { "label": "Sergipe", "code": "SE" },
    { "label": "Tocantins", "code": "TO" }
];

const MENU_PARTNER_REGISTER = [
    { label: I18n.get("Info"), link: "/parceiro-ideias/informacoes-gerais", activeMenu: 1 },
    { label: I18n.get("Profile"), link: "/parceiro-ideias/profile", activeMenu: 2 }
];

const MENU_CLIENT_WHATIF = [
    { label: I18n.get("Produtos"), link: "/cliente/ideias/home", activeMenu: 1 },
    { label: I18n.get("Buscar ideias"), link: "/cliente/ideias/buscar", activeMenu: 2 },
];


export {
    STATES,
    MENU_PARTNER_REGISTER,
    MENU_CLIENT_WHATIF
};
