import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { Row, Col, message, DatePicker, Select } from 'antd';
import moment from 'moment';
import { Loggedv2 } from '../../components/Loggedv2';
import Main from '../../../../components/UI/Main';
import { ButtonBpool } from '../../../../components/UI/ButtonBpool';
import { Input } from '../../../../components/UI/Input';
import Loading from '../../../../components/UI/Loading';
import Checkbox from '../../../../components/UI/Checkbox';
import { BoxWithCheckboxes } from '../../../../components/UI/BoxWithCheckboxes';
import { SelectOne } from '../../../../components/UI/SelectOne';
import { ItemCustomers } from './itemCustomers';
import { useRegistrationFlowFull } from '../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook';
import { urlsFull as urls } from '../../utils/stepsUrls';
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from '../../utils/flowToStorage';
import { statusIdToName } from '../../components/Loggedv2/statusIdToName';
import { ModalBp } from '../../../../components/UI/ModalBp/ModalBp';

import * as S from './styles';
import mockYears from './mockYears.json';
import mockMonths from './mockMonths.json';

const MESSAGE_ERROR_BACK_PAGE = 'Error in back page.';
const MESSAGE_ERROR = 'There was an error, try again.';
const PAGE_INDEX = 14;

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  let history = useHistory();
  const {
    getStatusBarFull,
    getCustomersInfos,
    getCustomersTypeInfos,
    postCustomersInfos,
    getIndustriesSelect,
  } = useRegistrationFlowFull();
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [inputsForm, setInputsForm] = useState({ nonCompete: 'yes' });
  const [changeInfo, setChangeInfo] = useState(false);
  const [dateCustomer, setDateCustomer] = useState(null);
  const [errorDateCustomer, setErrorDateCustomer] = useState('');
  const [checkboxIdTab, setCheckboxIdTab] = useState('id-0');
  const [typesCustomer, setTypesCustomer] = useState([]);
  const [typeCustomerActive, setTypeCustomerActive] = useState('');
  const [categoriesDrop, setCategoriesDrop] = useState([]);
  const [customersActives, setCustomersActives] = useState([]);
  const [customersNoActives, setCustomersNoActives] = useState([]);
  const [customersSendTemp, setCustomersSendTemp] = useState([]);
  const [customersExSendTemp, setCustomersExSendTemp] = useState([]);
  const [onEditing, setOnEditing] = useState(false);
  const [errrorsInternalForm, setErrrorsInternalForm] = useState({});
  const [sendErrorMin3Customers, setSendErrorMin3Customers] = useState('');
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const loadInit = async () => {
    setIsLoading(true);

    const responseTypes = await getCustomersTypeInfos();
    if (responseTypes.status === 200) {
      const types = responseTypes.data.map((type) => ({
        code: type.code,
        label: type.label,
      }));
      setTypesCustomer(types);
    }

    const response = await getCustomersInfos({
      admin: contextPage === 'admin' ? true : false,
    });
    if (response.success) {
      if (response.data.clientProfileType) {
        setTypeCustomerActive(String(response.data.clientProfileType) || '1');
      } else {
        setTypeCustomerActive('1');
      }
      const clients = response.data.clients.filter(
        (client) => client.active === true
      );
      setCustomersActives(clients);
      const exClients = response.data.clients.filter(
        (client) => client.active === false
      );
      setCustomersNoActives(exClients);
    }

    const responseCategories = await getIndustriesSelect({
      admin: contextPage === 'admin' ? true : false,
      type: 1,
    });
    if (responseCategories.success) {
      const categories = responseCategories.data.map((category) => ({
        code: category.id,
        label: category.description,
      }));
      setCategoriesDrop(categories);
    }

    setIsLoading(false);
  };

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarFull();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);

    const objToSend = {
      adminAction: contextPage === 'admin' ? true : false,
      id: contextPage === 'admin' ? partnerId : null,
      clientProfileType: Number(typeCustomerActive),
      clients: [
        ...customersActives,
        ...customersNoActives,
        ...customersSendTemp,
        ...customersExSendTemp,
      ],
    };

    const response = await postCustomersInfos({
      admin: contextPage === 'admin' ? true : false,
      form: objToSend,
    });
    if (contextPage === 'full') {
      await newGetStepbar();
    }

    if (response.success) {
      if (contextPage === 'full') {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: urls[PAGE_INDEX + 1],
        });
      }
      if (contextPage === 'edit' || contextPage === 'admin') {
        sendOk(true);
      }
      setSendErrorMin3Customers('');
    } else {
      if (contextPage === 'full') {
        setOpenModalConfirm(true);
      }
      const errorMin3Customers = response?.errors?.Clients?.errorMessage;

      if (errorMin3Customers) {
        setSendErrorMin3Customers(errorMin3Customers);
      }
    }

    setIsSending(false);
  };

  const validDateNonCompete = async () => {
    if (inputsForm.nonCompete === 'yes' && !dateCustomer) {
      setErrorDateCustomer(I18n.get('Select one date'));
      return 'error';
    } else if (inputsForm.nonCompete === 'yes' && dateCustomer) {
      setErrorDateCustomer('');
      return 'ok';
    } else if (inputsForm.nonCompete === 'no' && !dateCustomer) {
      setDateCustomer(null);
      setErrorDateCustomer('');
      return 'ok';
    } else if (inputsForm.nonCompete === 'no' && dateCustomer) {
      setDateCustomer(null);
      setErrorDateCustomer('');
      return 'ok';
    } else {
      setErrorDateCustomer('');
      return 'ok';
    }
  };

  const addCustomerPosValidationDate = async (errorDate) => {
    if (
      inputsForm.name &&
      inputsForm.brand &&
      inputsForm.category &&
      errorDate === 'ok'
    ) {
      const id = uuid();
      const objAdd = {
        id,
        active: true,
        name: inputsForm.name,
        brand: inputsForm.brand,
        category: inputsForm.category,
        nonCompete: inputsForm.nonCompete === 'yes' ? true : false,
        nonCompeteExpiresOn: dateCustomer,
      };

      setCustomersSendTemp((customersSendTemp) => [
        ...customersSendTemp,
        objAdd,
      ]);
      setDateCustomer(null);
      setInputsForm({ nonCompete: 'yes' });
      setChangeInfo(true);
      onChangeInfo(true);
      setErrrorsInternalForm({});
      setSendErrorMin3Customers('');
    } else {
      setErrrorsInternalForm({
        name: inputsForm.name ? '' : I18n.get('Fill in the name field'),
        brand: inputsForm.brand ? '' : I18n.get('Fill in the brand field'),
        category: inputsForm.category ? '' : I18n.get('Select one category'),
      });
    }
  };

  const addCustomer = async () => {
    if (checkboxIdTab === 'id-0') {
      const hasErrorDate = await validDateNonCompete();
      await addCustomerPosValidationDate(hasErrorDate);
    }

    if (checkboxIdTab === 'id-1') {
      if (inputsForm.name && inputsForm.brand && inputsForm.category) {
        const id = uuid();
        const objAdd = {
          id,
          active: false,
          name: inputsForm.name,
          brand: inputsForm.brand,
          category: inputsForm.category,
          workedTimeYear: inputsForm.workedTimeYear,
          workedTimeMonth: inputsForm.workedTimeMonth,
          nonCompete: false,
        };

        setCustomersExSendTemp((customersExSendTemp) => [
          ...customersExSendTemp,
          objAdd,
        ]);

        setInputsForm({ nonCompete: 'yes' });
        setChangeInfo(true);
        onChangeInfo(true);
        setErrrorsInternalForm({});
        setSendErrorMin3Customers('');
      } else {
        setErrrorsInternalForm({
          name: inputsForm.name ? '' : I18n.get('Fill in the name field'),
          brand: inputsForm.brand ? '' : I18n.get('Fill in the brand field'),
          category: inputsForm.category ? '' : I18n.get('Select one category'),
          workedTimeYear: inputsForm.workedTimeYear
            ? ''
            : I18n.get('Select one year'),
          workedTimeMonth: inputsForm.workedTimeMonth
            ? ''
            : I18n.get('Select one month'),
        });
      }
    }
  };

  const updateCustomer = () => {
    if (inputsForm.name && inputsForm.brand && inputsForm.category) {
      const arr1 = customersActives.find(
        (customer) => customer.id === inputsForm.id
      );
      const arr2 = customersNoActives.find(
        (customer) => customer.id === inputsForm.id
      );
      const arr3 = customersSendTemp.find(
        (customer) => customer.id === inputsForm.id
      );
      const arr4 = customersExSendTemp.find(
        (customer) => customer.id === inputsForm.id
      );

      if (arr1) {
        const customersActivesTemp = [...customersActives];
        const indexCustomersActives = customersActives.findIndex(
          (item) => item.id === inputsForm.id
        );
        customersActivesTemp[indexCustomersActives] = inputsForm;
        setCustomersActives(customersActivesTemp);
        setDateCustomer(null);
      }

      if (arr2) {
        const customersNoActivesTemp = [...customersNoActives];
        const indexCustomersNoActives = customersNoActives.findIndex(
          (item) => item.id === inputsForm.id
        );
        customersNoActivesTemp[indexCustomersNoActives] = inputsForm;
        setCustomersNoActives(customersNoActivesTemp);
      }

      if (arr3) {
        const customersSendTempTemp = [...customersSendTemp];
        const indexCustomersSendTemp = customersSendTemp.findIndex(
          (item) => item.id === inputsForm.id
        );
        customersSendTempTemp[indexCustomersSendTemp] = inputsForm;
        setCustomersSendTemp(customersSendTempTemp);
        setDateCustomer(null);
      }

      if (arr4) {
        const customersExSendTempTemp = [...customersExSendTemp];
        const indexCustomersExSendTemp = customersExSendTemp.findIndex(
          (item) => item.id === inputsForm.id
        );
        customersExSendTempTemp[indexCustomersExSendTemp] = inputsForm;
        setCustomersExSendTemp(customersExSendTempTemp);
      }

      setInputsForm({});
      setOnEditing(false);
      setChangeInfo(true);
      onChangeInfo(true);
      setErrrorsInternalForm({});
    } else {
      setErrrorsInternalForm({
        name: inputsForm.name ? '' : I18n.get('Fill in the name field'),
        brand: inputsForm.brand ? '' : I18n.get('Fill in the brand field'),
        category: inputsForm.category ? '' : I18n.get('Select one category'),
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  const handleChangeTypeCustomer = (type) => {
    setTypeCustomerActive(type);
    setChangeInfo(true);
    onChangeInfo(true);
  };

  const onChangeDate = (date, dateString) => {
    setDateCustomer(dateString);
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleChangeCategory = (value) => {
    onChangeInputForm('category', value);
  };

  const formCustomer = () => {
    return (
      <Row style={{ marginLeft: '-15px', marginRight: '-15px' }}>
        <Col xs={24} sm={12} style={{ height: 80 }}>
          <Input
            placeholder={I18n.get('Company Name')}
            label
            required
            value={inputsForm.name}
            onChange={(value) => onChangeInputForm('name', value)}
            error={errrorsInternalForm?.name}
          />
        </Col>
        <Col xs={24} sm={12} style={{ height: 80 }}>
          <Input
            placeholder={I18n.get('Brand')}
            label
            required
            value={inputsForm.brand}
            onChange={(value) => onChangeInputForm('brand', value)}
            error={errrorsInternalForm?.brand}
          />
        </Col>
        <Col xs={24} sm={12} style={{ height: 80 }}>
          <SelectOne
            options={categoriesDrop}
            value={inputsForm.category}
            defaultValue=""
            onChange={handleChangeCategory}
            required
            placeholder={I18n.get('Category')}
            error={errrorsInternalForm?.category}
          />
        </Col>
        <Col xs={24} sm={12} style={{ top: '-10px' }}>
          <S.ContentCheckboxes>
            <S.TitleCheckboxes>
              {I18n.get('Do you have a non-compete contract with this client?')}
            </S.TitleCheckboxes>
            <S.CheckboxesAndLabels>
              <S.Checks>
                <Checkbox
                  id="yes"
                  onChangeCb={(e, id) => onChangeInputForm('nonCompete', id)}
                  checked={inputsForm.nonCompete === 'yes' ? true : false}
                  checkboxSize={16}
                />
                <S.LabelCheck>{I18n.get('Yes')}</S.LabelCheck>
              </S.Checks>
              <S.Checks>
                <Checkbox
                  id="no"
                  onChangeCb={(e, id) => onChangeInputForm('nonCompete', id)}
                  checked={inputsForm.nonCompete === 'no' ? true : false}
                  checkboxSize={16}
                />
                <S.LabelCheck>{I18n.get('No')}</S.LabelCheck>
              </S.Checks>
            </S.CheckboxesAndLabels>
          </S.ContentCheckboxes>
        </Col>
        {inputsForm.nonCompete === 'yes' ? (
          <Col xs={24} style={{ top: '-10px' }}>
            <S.TitleCheckboxes>
              {I18n.get('When does this exclusivity expire?')}
            </S.TitleCheckboxes>
            <S.ContentDatePicker errorDate={Boolean(errorDateCustomer)}>
              <DatePicker
                defaultValue={dateCustomer}
                format={dateFormatList}
                onChange={onChangeDate}
                placeholder="__/__/____"
                value={dateCustomer ? moment(dateCustomer, 'DD-MM-YYYY') : null}
                disabledDate={(current) => {
                  let customDate = moment().format('YYYY-MM-DD');
                  return current && current < moment(customDate, 'YYYY-MM-DD');
                }}
              />
              {errorDateCustomer ? (
                <S.ErrorDateCustomer>
                  {I18n.get(errorDateCustomer)}
                </S.ErrorDateCustomer>
              ) : null}
            </S.ContentDatePicker>
          </Col>
        ) : null}
      </Row>
    );
  };

  const formExCustomer = () => {
    return (
      <>
        <Row style={{ marginLeft: '-15px', marginRight: '-15px' }}>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get('Company Name')}
              label
              required
              value={inputsForm.name}
              onChange={(value) => onChangeInputForm('name', value)}
              error={errrorsInternalForm?.name}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get('Brand')}
              label
              required
              value={inputsForm.brand}
              onChange={(value) => onChangeInputForm('brand', value)}
              error={errrorsInternalForm?.brand}
            />
          </Col>
        </Row>
        <Row style={{ marginLeft: '-15px', marginRight: '-15px' }}>
          <Col xs={12} style={{ height: 80 }}>
            <SelectOne
              options={categoriesDrop}
              value={inputsForm.category}
              defaultValue=""
              onChange={handleChangeCategory}
              required
              placeholder={I18n.get('Category')}
              error={errrorsInternalForm?.category}
            />
          </Col>
          <Col xs={12} style={{ top: '-15px' }}>
            <S.TitleCheckboxes style={{ top: '-2px', position: 'relative' }}>
              {I18n.get('How long did you work?')}
            </S.TitleCheckboxes>
            <S.ContentSelectYearsAndMonths>
              <S.SelectYearAndMonth style={{ width: '55%' }}>
                <SelectOne
                  options={mockYears}
                  value={inputsForm.workedTimeYear}
                  defaultValue=""
                  onChange={(value) =>
                    onChangeInputForm('workedTimeYear', Number(value))
                  }
                  required
                  placeholder={I18n.get('Years')}
                  error={errrorsInternalForm?.workedTimeYear}
                />
              </S.SelectYearAndMonth>
              <S.SelectYearAndMonth
                style={{ width: 'calc(45% - 10px)', marginLeft: 10 }}
              >
                <SelectOne
                  options={mockMonths}
                  value={inputsForm.workedTimeMonth}
                  defaultValue=""
                  onChange={(value) =>
                    onChangeInputForm('workedTimeMonth', Number(value))
                  }
                  required
                  placeholder={I18n.get('Months')}
                  error={errrorsInternalForm?.workedTimeMonth}
                />
              </S.SelectYearAndMonth>
            </S.ContentSelectYearsAndMonths>
          </Col>
        </Row>
      </>
    );
  };

  const onChangeCheck = ({ id }) => {
    setCheckboxIdTab(id);
    setInputsForm({});
    setDateCustomer(null);
    setOnEditing(false);
    setErrrorsInternalForm({});
  };

  const handleEditCustomer = ({ type, id }) => {
    setOnEditing(true);
    setCheckboxIdTab(type);
    setErrrorsInternalForm({});

    const arrOfCustomers = [
      ...customersActives,
      ...customersNoActives,
      ...customersSendTemp,
      ...customersExSendTemp,
    ];

    const editCustomer = arrOfCustomers.find((customer) => customer.id === id);
    if (editCustomer.active) {
      const objToEdit = {
        id: editCustomer.id,
        active: editCustomer.active,
        name: editCustomer.name,
        brand: editCustomer.brand,
        category: editCustomer.category,
        nonCompete: editCustomer.nonCompete ? 'yes' : 'no',
        nonCompeteExpiresOn: editCustomer.nonCompeteExpiresOn,
      };

      setInputsForm(objToEdit);
      setDateCustomer(editCustomer.nonCompeteExpiresOn);
    } else {
      const objToEdit = {
        id: editCustomer.id,
        active: editCustomer.active,
        name: editCustomer.name,
        brand: editCustomer.brand,
        category: editCustomer.category,
        workedTimeYear:
          editCustomer.workedTimeYear && editCustomer.workedTimeYear !== 0
            ? String(editCustomer.workedTimeYear)
            : '',
        workedTimeMonth:
          editCustomer.workedTimeMonth && editCustomer.workedTimeMonth !== 0
            ? editCustomer.workedTimeMonth
            : '',
      };

      setInputsForm(objToEdit);
    }
  };

  const handleDeleteCustomer = (id) => {
    const arr1 = customersActives.find((customer) => customer.id === id);
    const arr2 = customersNoActives.find((customer) => customer.id === id);
    const arr3 = customersSendTemp.find((customer) => customer.id === id);
    const arr4 = customersExSendTemp.find((customer) => customer.id === id);

    if (arr1) {
      const arrUpdated1 = customersActives.filter((item) => item.id !== id);
      setCustomersActives(arrUpdated1);
      setErrrorsInternalForm({});
    }

    if (arr2) {
      const arrUpdated2 = customersNoActives.filter((item) => item.id !== id);
      setCustomersNoActives(arrUpdated2);
      setErrrorsInternalForm({});
    }

    if (arr3) {
      const arrUpdated3 = customersSendTemp.filter((item) => item.id !== id);
      setCustomersSendTemp(arrUpdated3);
      setErrrorsInternalForm({});
    }

    if (arr4) {
      const arrUpdated4 = customersExSendTemp.filter((item) => item.id !== id);
      setCustomersExSendTemp(arrUpdated4);
      setErrrorsInternalForm({});
    }
  };

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get('Clients')}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  'Tell us about your typical client profile and give us some reference contacts.'
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        {isLoading ? (
          <Row>
            <Col xs={24}>
              <S.ContentIsLoading>
                <Loading
                  text={I18n.get('Loading informations...')}
                  sizeText={14}
                  sizeIcon={16}
                />
              </S.ContentIsLoading>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col xs={24}>
                <S.WrapperTypesCustomer>
                  {typesCustomer.map((type) => (
                    <S.ButtonType
                      key={type.code}
                      onClick={() => handleChangeTypeCustomer(type.code)}
                      className={
                        type.code === typeCustomerActive ? 'active' : ''
                      }
                    >
                      {type.label}
                    </S.ButtonType>
                  ))}
                </S.WrapperTypesCustomer>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <S.TitleSection>
                  {I18n.get('Client informations')}
                </S.TitleSection>
              </Col>
              <Col xs={24}>
                <BoxWithCheckboxes
                  checkboxes={[
                    I18n.get('Active Client'),
                    I18n.get('Ex Client'),
                  ]}
                  checkboxIdTab={checkboxIdTab}
                  onChangeCheck={(id) => onChangeCheck({ id })}
                  components={[formCustomer(), formExCustomer()]}
                  actions={[
                    <ButtonBpool
                      text={I18n.get(!onEditing ? 'Add' : 'Save')}
                      theme="primary"
                      onClick={
                        !onEditing
                          ? () => addCustomer()
                          : () => updateCustomer()
                      }
                    />,
                  ]}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 30 }}>
              <Col xs={24}>
                <ItemCustomers
                  textEmpty=""
                  data={[...customersActives, ...customersSendTemp]}
                  categories={categoriesDrop}
                  onDelete={(id) => handleDeleteCustomer(id)}
                  onEdit={(id) => handleEditCustomer({ type: 'id-0', id })}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 60 }}>
              <Col xs={24}>
                <ItemCustomers
                  textEmpty=""
                  data={[...customersNoActives, ...customersExSendTemp]}
                  categories={categoriesDrop}
                  onDelete={(id) => handleDeleteCustomer(id)}
                  onEdit={(id) => handleEditCustomer({ type: 'id-1', id })}
                />
              </Col>
            </Row>
          </>
        )}
        {sendErrorMin3Customers ? (
          <Row style={{ marginTop: 30 }}>
            <Col xs={24}>
              <S.ErroSendPage>
                <strong>{I18n.get('Error ao enviar: ')}</strong>
                {I18n.get(sendErrorMin3Customers)}
              </S.ErroSendPage>
            </Col>
          </Row>
        ) : null}
        <Row style={{ marginTop: 30 }}>
          {contextPage === 'full' ? (
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get('Back')}
                theme="secondary"
                full
                onClick={handleBackPage}
                disabled={isLoading}
              />
            </Col>
          ) : null}
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={
              contextPage === 'edit' || contextPage === 'admin'
                ? { span: 12, offset: 12 }
                : { span: 12, offset: 0 }
            }
          >
            <ButtonBpool
              text={
                contextPage === 'edit' || contextPage === 'admin'
                  ? I18n.get('Save')
                  : I18n.get('Save and Next')
              }
              theme="primary"
              full
              loading={isSending}
              onClick={send}
              disabled={isLoading}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get('Missing required information')}
        subBodyText={I18n.get(
          'Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?'
        )}
        okText={I18n.get('Yes')}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get('Close')}
        typeModal="isConfirm"
        // isDanger
      />
    </>
  );
};
