import React from "react";
import { I18n } from "aws-amplify";
import numeral from "numeral";
import NumberFormat from "react-number-format";
import { Modal, Button } from "antd";

import "numeral/locales";

export function ViewPay() {
  Modal.info({
    title: I18n.get("Antecipação de pagamento BPool"),
    width: "60%",
    content: (
      <div style={{ paddingTop: "30px" }}>
        <p>
          {I18n.get(
            "Você sabia que pode adiantar o seu pagamento por uma taxa de apenas 0,75% ao mês?"
          )}
        </p>
        <br />
        <p>
          {I18n.get(
            "Sim, quando ao menos 50% do trabalho estiver entregue, você pode acionar a BPool."
          )}
        </p>
        <br />
        <p>
          {I18n.get(
            "Para isso basta enviar a NF e o nome do projeto na plataforma para o e-mail"
          )}{" "}
          <b>nuno@bpool.co</b>.
        </p>
        <br />
        <p>
          {I18n.get(
            "A BPool irá enviar um documento para assinatura do desconto de valores e em até 5 dias uteis o pagamento é realizado."
          )}
        </p>
        <br />
        <p>
          {I18n.get(
            "O % de juros sempre será descontado do valor da NF enviada."
          )}
        </p>
      </div>
    ),
    onOk: () => {},
  });
}

export function formatCurrency(v) {
  // return <NumberFormat value={v} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} />;
  return (
    <NumberFormat
      className="FormatLabel"
      value={v}
      displayType={"text"}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
    />
  );
}

export function formatMoney(currency) {
  return numeral(currency.toString().replace(",", ".")).format("0.0,00");
}

export function formatMoney2(currency) {
  let formatter = new Intl.NumberFormat([], {
    style: "currency",
    currency: "BRL",
  });

  return formatter.format(currency);
}

export function getSector(sectorId, sectorsFlat) {
  const items = sectorsFlat.filter((item) => item.code == sectorId);

  if (items && items.length) {
    return items[0].label;
  } else {
    return {};
  }
}

export function codeToLabel(code, arr) {
  const items = arr.filter((item) => item.code == code);

  if (items && items.length) {
    return items[0].label;
  } else {
    return {};
  }
}

export function formatMoneyInput(currency) {
  let formatter = new Intl.NumberFormat([], {
    currency: "BRL",
  });

  return formatter.format(currency);
}

export function formatMoneyGraph(currency) {
  // return numeral(currency.toString().replace(",", ".")).format('0a')

  let valor = currency / 1000;

  return numeral(valor.toString().replace(",", ".")).format("0");
  // return valor;
}

// 113131
