import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink } from 'react-router-dom';

import { ProjectExtraType, ProjectExtraTypeNames } from '../../../model';

import { getQuotationStatus } from '../../../utils/extras';

import FormatCurrency from '../../ui/format-currency';

class ProductionDetails extends React.Component {
    constructor(props) {

        super();
        this.state = {
            task: {}
        }
    }

    componentDidMount() {
    }

    render() {

        const { projectId, extraId } = this.props.match.params;

        const { auth = {} } = this.props;

        const { extraName, extraType, budget, dueDate, description, comments, files = [], bpCommission = {}, workingBudget } = this.props;

        const quotation = this.props.quotation || {};

        const currency = this.props.currency || {};

        return (
            <div>



                <div className="columns">
                    <div className="column is-12">
                        <h5 className="title-bpool medium">{I18n.get("Detalhes")}</h5>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-5">
                        <p>
                            <strong>{I18n.get("Tipo")}:</strong> {I18n.get(ProjectExtraTypeNames[extraType])}
                        </p>
                        <p>
                            <strong>{I18n.get("Nome")}:</strong> {extraName}
                        </p>
                        <br />
                        <p>
                            <strong>{I18n.get("Budget")}:</strong> <FormatCurrency amount={budget} currency={currency} />
                        </p>
                        <p>
                            <strong>{I18n.get("Platform Fee")}:</strong> {bpCommission && bpCommission.perc}%
                        </p>
                        <p>
                            <strong>{I18n.get("Budget Disponível")}:</strong> <FormatCurrency amount={workingBudget} currency={currency} />
                        </p>
                        {quotation && quotation.submittedOn && <>
                            <br />
                            <p>
                                <strong>{I18n.get("Valor Orçamento")}:</strong> <FormatCurrency amount={quotation.total} currency={currency} />
                            </p>
                            <p>
                                <strong>{I18n.get("Status Orçamento")}:</strong> {getQuotationStatus(auth, quotation)}
                            </p>
                        </>}
                        <br />
                        <p>
                            <strong>{I18n.get("Data de Entrega")}:</strong> {dueDate}
                        </p>
                    </div>
                    <div className="column is-2"></div>
                    <div className="column is-5">
                        <div className="field">
                            <label className="label">{I18n.get("Descrição")}</label>
                            <div className="control">
                                {description}
                            </div>
                        </div>

                        <br />

                        <div className="field">
                            <label className="label">{I18n.get("Observações")}</label>
                            <div className="control">
                                {comments}
                            </div>
                        </div>

                        <br />
                        <br />

                        <div className="field">
                            <label className="label">{I18n.get("Anexos")}</label>
                        </div>

                        {files && files.map((item, index) => {
                            return (<div key={index} title={item.name} className="big-idea-box" style={{ width: '5rem', display: 'inline-block' }}>

                                <a href={item.url} target="_blank" style={{ color: '#ccc' }}>
                                    <span className="icon is-large" style={{ marginTop: '1rem' }}>
                                        <i className="fas fa-file fa-3x" aria-hidden="true"></i>
                                    </span>
                                </a>

                                <div style={{ width: '5rem', padding: '0.5rem', fontSize: '80%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</div>
                            </div>
                            );
                        })}

                        {files && files.length == 0 && <p className="has-text-grey-light" style={{ fontSize: '80%' }}>{I18n.get("Nenhum anexo.")}</p>}

                        <br />
                        <br />
                        <p style={{ fontSize: '80%' }}>{I18n.get("Última atualização:")} {this.props.updatedOn}
                        </p>
                    </div>
                </div>
                <br />
                <br />
                <p>
                    {auth.is_client_or_client_user && <>
                        <NavLink to={`/projetos/${projectId}/extras/${extraId}/editar`} className="button bt-bpool is-outlined">{I18n.get("Editar")}</NavLink>
                    </>}
                    <NavLink to={`/projetos/${projectId}/extras/${extraId}/orcamento`} className={`button bt-bpool black is-black ${auth.is_client_or_client_user ? "is-pulled-right" : ""}`}>{I18n.get("Orçamento")}</NavLink>
                </p>
            </div>
        );
    }
}

export default (ProductionDetails);
