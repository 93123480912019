import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const usePartners = () => {
  const getPartners = async ({ id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${id}/client/partners`,
      config
    );

    return data;
  };

  const getPartnerById = async ({ projectId, partnerId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/client/partner/${partnerId}`,
      config
    );

    return data;
  };

  const updatePartner = async (rating) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project/client/selectpartner`,
      rating,
      config
    );

    return data;
  };

  const getPartnersToComboMeeting = async ({ projectId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project-meeting/client/project/${projectId}/partners`,
      config
    );

    return data;
  };

  const sendInvoicePartnerProject = async (dataInvoice) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/partner/client-project/billing/installments/invoices`,
      dataInvoice,
      config
    );

    return data;
  };

  const sendInvoicePartnerExtraProject = async (dataInvoice) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/partner/client-project/extras/billing/installments/invoices`,
      dataInvoice,
      config
    );

    return data;
  };

  return {
    getPartners,
    getPartnerById,
    updatePartner,
    getPartnersToComboMeeting,
    sendInvoicePartnerProject,
    sendInvoicePartnerExtraProject,
  };
};
