import React from 'react';

import { I18n } from 'aws-amplify';

import NumberFormat from 'react-number-format';

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';

import QuotationBudget from './quotation-budget';
import QuotationItemForm from './quotation-item-form';
import QuotationSummary from './quotation-summary';
import QuotationSummaryForm from './quotation-summary-form';
import QuotationGrandTotal from './quotation-grandtotal';
import QuotationSchedule from './quotation-schedule-form';
import QuotationNotes from './quotation-notes';

import TextAreaField from '../../form/textarea-field';
import SubmitButtonField from '../../form/submit-button-field';
import FieldErrorMessage from '../../form/field-error-message';

const BRL = ({ amount }) => <NumberFormat value={amount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} />;

class QuotationForm extends React.Component {
    constructor(props) {

        super(props);

        const { quotation = {} } = props;

        const { partnerScope = '' } = quotation;

        this.state = {
            fields: {
                partnerScope: {
                    label: I18n.get("Detalhar descritivo do extra a ser enviado ao cliente"),
                    onChange: this.onChangeForm.bind(this, 'partnerScope'),
                    successIcon: false,
                    onBlur: this.onSave.bind(this, 'partnerScope'),
                    visited: true,
                },
            },
            form: {
                partnerScope: partnerScope
            },
            errors: {},
            task: {},
            approve_task: {},
            submit_task: {},
            errors: {}
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        if (JSON.stringify(task) != JSON.stringify(prev)) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { quotation = {} } = this.props;

            nextState.form.partnerScope = quotation.partnerScope;

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onSave(item) {

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {

            this.props.updateExtraQuotation(this.getData());
        });
    }

    getData() {

        const { projectId, extraId } = this.props.match.params;

        const quotation = this.props.quotation || {};

        var data = {
            projectId: projectId,
            extraId: extraId,
            quotationId: quotation.id,
            tax: quotation.tax,
            partnerScope: this.state.form.partnerScope,
            partnerNotes: quotation.partnerNotes,
            clientNotes: quotation.clientNotes
        };

        return data;
    }

    submitForm(isSubmit, e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task }, function () {

            const data = Object.assign({}, this.getData(), { isSubmit: isSubmit });

            this.props.updateExtraQuotation(data)
                .then(({ task, errors }) => {

                    this.setState({ submit_task: task, errors: errors });

                });
        });
    }

    onApproval(isApproved, e) {

        e.preventDefault();

        var approve_task = new Task();

        approve_task.start();

        this.setState({ approve_task: approve_task });

        const data = Object.assign({}, this.getData(), { isApproved: isApproved });

        this.props.updateExtraQuotationStatus(data)
            .then(({ task, errors }) => {

                this.setState({ approve_task: task, errors: errors });

            });
    }

    onExport(e) {

        const { projectId, extraId } = this.props.match.params;

        if (e) e.preventDefault();

        var data = {
            projectId: projectId,
            extraId: extraId,
            quotationId: this.props.quotation.id,
        };

        this.props.exportExtraQuotation(data)
            .then(({ task, errors, exportUrl }) => {

                if (task.result) {
                    window.location = exportUrl;
                }

            });

    }

    render() {

        const quotation = this.props.quotation || {};
        const currency = this.props.currency || {};

        const canEdit = this.props.auth.is_partner && quotation.isOpen;

        const canApprove = this.props.auth.is_client_or_client_user && quotation.requestApproval;

        const style = {
            opacity: quotation.submittedOn ? '0.75' : '1'
        };

        if (this.props.auth.is_client_or_client_user && (!quotation.id || quotation.isOpen)) {

            return (<div style={{ height: '40vh' }}>

                <div className="msg-info">
                    <h4>
                        {I18n.get("O Parceiro está elaborando a proposta.")}
                    </h4>
                    <span> {I18n.get("Você será notificado assim que o Parceiro finaliza-la e você poderá aprovar ou declinar a proposta.")}</span>
                </div>

            </div>);
        }

        return (
            <div >

                <br /><br />

                <QuotationBudget {...this.props} quotation={quotation} currency={currency} />

                <br /><br /><br />

                <div style={style}>

                    <TextAreaField {...this.state.fields.partnerScope} value={this.state.form.partnerScope} error={this.state.errors.partnerScope} readOnly={!canEdit} />

                    <br />

                    <FieldErrorMessage fields={['itemsSubTotal']} errors={this.state.errors} />

                    <br />
                    <br />
                    <br />
                    <br />
                    {quotation && <>

                        <QuotationSummaryForm ref={this.summaryForm} {...this.props} quotation={quotation} currency={currency} errors={this.state.errors} />

                        <br /><br />

                        <QuotationGrandTotal {...this.props} quotation={quotation} currency={currency} />

                        {this.props.exportExtraQuotation && <>

                            <br />
                            <br />
                            <a className="button bt-bpool black pull-right" style={{ fontSize: '90%' }} onClick={this.onExport.bind(this)}>{I18n.get('Exportar para Excel')}</a>

                        </>}
                        <br />
                        <br />

                    </>}

                    {!this.props.suppressSchedule && <>

                        <QuotationSchedule {...this.props} quotation={quotation} />

                        <FieldErrorMessage fields={['milestones']} errors={this.state.errors} />

                    </>}

                    <br />
                    <br />

                    <QuotationNotes {...this.props} quotation={quotation} canEditPartner={canEdit} canEditClient={canApprove} />

                </div>

                <p className="has-text-danger">
                    <ul>
                        {Object.keys(this.state.errors).map((item) => {

                            if (!this.state.errors[item]) return null;

                            return (<li>{this.state.errors[item].errorMessage}</li>)
                        })}
                    </ul>
                </p>

                {canEdit && <div className="columns">

                    <div className="column is-3" style={{ padding: '1rem' }}>

                        <SubmitButtonField
                            label={I18n.get("Enviar para cliente")}
                            className="button bt-bpool"
                            task={this.state.submit_task}
                            onClick={this.submitForm.bind(this, true)}
                        />

                    </div>

                    <div className="column is-3" style={{ padding: '1rem' }}>

                        <SubmitButtonField
                            label={I18n.get("Salvar e editar depois")}
                            className="button bt-bpool black"
                            task={this.state.submit_task}
                            onClick={this.submitForm.bind(this, false)}
                        />

                    </div>

                </div>}

                <br />

                {this.props.auth.is_partner && quotation.submittedOn && <>

                    <div className="msg-info">
                        <h4>
                            <i className="fas fa-check"></i> {I18n.get("Orçamento enviado ao cliente em")} {quotation.submittedOn}.
                        </h4>
                        {quotation.isApproved === null && <span> {I18n.get("O cliente foi notificado e poderá aprovar ou declinar o orçamento.")}</span>}
                    </div>

                    {quotation.isApproved === true && (
                        <div className="msg-accepted yes-accepted">
                            <i className="fas fa-check"></i> {I18n.get("O cliente aprovou esse orçamento.")}</div>
                    )}


                    {quotation.isApproved === false && (
                        <div className="msg-accepted no-accepted">
                            <i className="fas fa-ban"></i> {I18n.get("O cliente declinou esse orçamento.")}</div>
                    )}

                </>}

                {this.props.auth.is_client_or_client_user && quotation.submittedOn && <>

                    <p>
                        <strong>{I18n.get("Orçamento recebido em")} {quotation.submittedOn}</strong>.
                        {quotation.isApproved === null && <span> {I18n.get("Você pode aprovar ou declinar o orçamento.")}</span>}
                    </p>


                    {quotation.isApproved === true && (
                        <div className="msg-accepted yes-accepted">
                            <i className="fas fa-check"></i> {I18n.get("Você aprovou esse orçamento.")}</div>
                    )}


                    {quotation.isApproved === false && (
                        <div className="msg-accepted no-accepted">
                            <i className="fas fa-ban"></i> {I18n.get("Você declinou esse orçamento.")}</div>
                    )}

                    <br /><br />

                </>}


                {canApprove && <>

                    <div className="columns">

                        <div className="column is-9" style={{ padding: '1rem' }}>

                            <SubmitButtonField
                                label={I18n.get("Aprovar escopo final")}
                                className="button bt-bpool"
                                task={this.state.approve_task}
                                onClick={this.onApproval.bind(this, true)}
                            />

                        </div>

                        <div className="column is-3" style={{ padding: '1rem' }}>

                            <SubmitButtonField
                                label={I18n.get("Recusar")}
                                className="button bt-bpool black pull-right"
                                task={this.state.approve_task}
                                onClick={this.onApproval.bind(this, false)}
                            />

                        </div>


                    </div>

                    <br /><br />

                </>}


            </div>
        );

    }
}

export default QuotationForm;
