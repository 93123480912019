import styled, { css } from "styled-components";
import BIcon from "../../../svg/b-icon.svg";
import CheckIcon from "../../../svg/check-icon.svg";
import { MediaQueryDimensions } from "../../../utils/mediaQuery";
import * as MediaQuery from "../../../utils/mediaQuery";


export const ProjectBoard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 30px 50px;
  text-align: center;
  height: calc(100% - 96px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  div {
    text-align: left;
    width: 100%;

    .row {
      display: flex;
      flex-direction: row;
      gap: 40px;
      margin-bottom: 25px;

      p,
      h2 {
        width: 50%;
      }

      h2 {
        font-size: 18px;
        padding-bottom: 30px;
        width: 50%;
        margin: 0;

        &:first-child::before {
          content: "";
          display: inline-flex;
          width: 24px;
          height: 24px;
          background: url(${BIcon}) no-repeat center;
          bottom: -5px;
          position: relative;
          margin-right: 10px;
        }

        &:nth-child(2)::before {
          content: "";
          display: inline-flex;
          width: 24px;
          height: 24px;
          background: url(${CheckIcon}) no-repeat center;
          bottom: -5px;
          position: relative;
          margin-right: 10px;
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;

        &:nth-child(2) {
          color: #1883ff;
          font-weight: 600;
        }
      }

      &:last-child {
        padding-bottom: 40px;
      }
    }
  }

  ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    
    width: 95%;
        padding: 30px 10px 50px;
    `}

  
`;
