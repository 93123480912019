import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { PROJECTS_API } from "../../store/actions/projects";
import { Row, Col, Switch } from "antd";

import { ButtonBpool } from "../../components/UI/ButtonBpool";
import Modal from "../../components/UI/Modal";
import Content from "../../components/UI/Content";
import { H2Border } from "../../components/UI/Titles";
import TabsBP from "../../components/UI/Tabs";
import Loading from "../../components/UI/Loading";
import Checkbox from "../../components/UI/Checkbox";
import { ModalBp } from "../../components/UI/ModalBp/ModalBp";

import IcoPlus from "../../../svg/plus.svg";

import LoggedPage from "../../../components/page-templates/fillForm-logged";
import Meetings from "./Meetings";
import { Invitations } from "./Invitations";
import Table from "./Projects/components/ProjectTable";
import Newproject from "./Projects/components/NewProjectBox";

import IconProjects from "../../../svg/ico-projetos.svg";
import IconMeetings from "../../../svg/ico-meetings.svg";
import IconInvitations from "../../../svg/ico-invitations.svg";

import * as S from "./styles";

function ListProjectClient() {
  const state = useSelector((state) => state);
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalDeployVisible, setModalDeployVisible] = useState(false);

  let history = useHistory();

  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 20,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      ...filters,
    };

    PROJECTS_API.get_projects(data).then((response) => {
      dispatch(response);
    });

    PROJECTS_API.get_filters(data).then((response) => {
      dispatch(response);
    });
  }, []);

  const reloadTable = async () => {
    const data = {
      ...filters,
    };

    setLoading(true);

    PROJECTS_API.get_projects(data).then((response) => {
      dispatch(response);

      if (response) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    reloadTable();
  }, [filters]);

  const editFilter = (prop, value) => {
    setFilters((prevState) => {
      return { ...prevState, [prop]: value };
    });
  };

  const onChangeCheckbox = (checked) => {
    editFilter("onlyMyProjects", checked);
  };

  const renderFilterMyProjects = () => {
    return (
      <Row>
        <Col
          xs={{ span: 12, offset: 12 }}
          sm={{ span: 6, offset: 18 }}
          justify="end"
        >
          <S.MyProjectsCheckBox>
            <S.MyProjectsCheckBoxLabel>
              <span>{I18n.get("Meus projetos")}</span>
            </S.MyProjectsCheckBoxLabel>
            <Switch onChange={(checked) => onChangeCheckbox(checked)} />
          </S.MyProjectsCheckBox>
        </Col>
      </Row>
    );
  };

  const tabsClient = [
    {
      label: I18n.get("Meus projetos"),
      key: 0,
      icon: IconProjects,
      component: (
        <>
          {renderFilterMyProjects()}
          {loading && <Loading style={{ marginBottom: "60px" }} />}
          <Table
            data={state?.projectsv2?.projects}
            isBig={true}
            editFilter={editFilter}
            filters={state?.projectsv2?.filters}
            pagination={{
              pageSize: filters.pageSize,
              total: state?.projectsv2?.pagination?.totalItems,
              onChange: (page) => {
                setFilters((prevState) => {
                  return { ...prevState, page: page };
                });
              },
            }}
            reload={reloadTable}
          />
        </>
      ),
    },
    {
      label: I18n.get("Meetings"),
      icon: IconMeetings,
      key: 1,
      component: <Meetings />,
    },
  ];

  const tabsPartner = [
    {
      label: I18n.get("Meus projetos"),
      key: 0,
      icon: IconProjects,
      component: (
        <>
          {renderFilterMyProjects()}
          {loading && <Loading style={{ marginBottom: "60px" }} />}
          <Table
            data={state?.projectsv2?.projects}
            isBig={true}
            editFilter={editFilter}
            filters={state?.projectsv2?.filters}
            pagination={{
              pageSize: filters.pageSize,
              total: state?.projectsv2?.pagination?.totalItems,
              onChange: (page) => {
                setFilters((prevState) => {
                  return { ...prevState, page: page };
                });
              },
            }}
            reload={reloadTable}
          />
        </>
      ),
    },
    {
      label: I18n.get("Invitations"),
      icon: IconInvitations,
      key: 1,
      component: <Invitations />,
    },
    {
      label: I18n.get("Meetings"),
      icon: IconMeetings,
      key: 2,
      component: <Meetings />,
    },
  ];

  const modalDeploy = async () => {
    const modalDeployOpen = localStorage.getItem("@BPOOL-modal-deploy");

    if (!modalDeployOpen) {
      localStorage.setItem("@BPOOL-modal-deploy", "3");
    } else {
      const modalDeployOpenNumber = parseInt(modalDeployOpen);

      if (modalDeployOpenNumber > 0) {
        setModalDeployVisible(true);
        localStorage.setItem(
          "@BPOOL-modal-deploy",
          (modalDeployOpenNumber - 1).toString()
        );
      }
    }
  };

  useEffect(() => {
    modalDeploy();
  }, []);

  return (
    <LoggedPage>
      <Content>
        <div className="container" style={{ marginBottom: 30 }}>
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Projetos")}</H2Border>
            </Col>
            <Col sm={12} justify="end">
              {state.auth?.is_partner ? (
                <S.Btns>
                  <ButtonBpool
                    icon={IcoPlus}
                    text={I18n.get("Submeter novo Projeto")}
                    theme="primary"
                    className="pull-right"
                    onClick={() =>
                      history.push(`/parceiro/projetos/v2/criar/informacoes`)
                    }
                  />

                  <ButtonBpool
                    icon={IcoPlus}
                    text={I18n.get("Submeter Novo Proposal")}
                    theme="primary"
                    className="pull-right"
                    onClick={() => history.push(`/parceiro/criar-proposal`)}
                  />
                </S.Btns>
              ) : null}
              {state.auth.is_client_or_client_user ? (
                <ButtonBpool
                  icon={IcoPlus}
                  text={I18n.get("Novo Projeto")}
                  theme="primary"
                  className="pull-right"
                  onClick={() =>
                    history.push(`/cliente/projetos/v2/criar-projeto`)
                  }
                />
              ) : null}
            </Col>
          </Row>

          <Row style={{ marginTop: "30px" }}>
            <Col sm={24}>
              <TabsBP
                tabItens={
                  state?.auth?.is_client_or_client_user
                    ? tabsClient
                    : tabsPartner
                }
                isClient={state?.auth?.is_client_or_client_user ? true : false}
              ></TabsBP>
            </Col>
          </Row>
        </div>

        {visibleModal && (
          <Modal closeInBg onClickCloseModal={() => setVisibleModal(false)}>
            <Newproject onClickCloseModal={() => setVisibleModal(false)} />
          </Modal>
        )}
      </Content>
      {/* <ModalBp  DEIXAR COMENTADO PARA O USO FUTURO DE OUTROS ALERTAS
        visible={modalDeployVisible}
        subBodyText={`${I18n.get(
          `Olá. Nossa plataforma está passando por melhorias de fluxos e atualizações visuais. Portanto, você vai experienciar algumas mudanças na usabilidade, sobretudo na parte de projetos. A ideia é deixar tudo mais simples para vocês, mas contamos com sua paciência e colaboração caso alguma mudança gerar um desconforto inicial. Se precisa de algum suporte favor contactar`
        )} ${
          state.auth.is_client_or_client_user
            ? '<a style="color:#fff;text-decoration: underline;" href="mailto:suporte.cliente@bpool.co">suporte.cliente@bpool.co</a>'
            : '<a style="color:#fff;text-decoration: underline;" href="mailto:suporte.parceiro@bpool.co">suporte.parceiro@bpool.co</a>'
        }<br />${I18n.get("Obrigado!")}`}
        okText={I18n.get("Ok")}
        handleClickOk={() => setModalDeployVisible(false)}
        onCancel={() => setModalDeployVisible(false)}
        isAlert={true} */}
      />
    </LoggedPage>
  );
}

export default ListProjectClient;
