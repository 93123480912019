import styled from "styled-components";
import { Tag } from "antd";

export const StyledDivStatus = styled.div`
  ${(props) => (props.draftCanDelete ? "display: grid;" : null)};
  ${(props) =>
    props.draftCanDelete
      ? "grid-template-columns: calc(100% - 41px) 41px;"
      : null};
`;

export const StyledSpanStatus = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  background: #1883ff;
  color: #fff;
  padding: 10px 6.5px;
  border-radius: 4px;
  width: 100%;
  display: block;

  :focus,
  :active,
  :visited {
    background: #1883ff;
    color: #fff;
  }
  :hover {
    background: #1274e5;
    color: #fff;
  }
`;

export const StyledSpanTag = styled(Tag)`
  border-color: #1883ff;
  color: #1883ff;
`;

export const DeleteProject = styled.button`
  color: #fff;
  background: #000;
  border: none;
  border-radius: 4px;
  padding: 7px 8.5px;
  margin-left: 10px;
  cursor: pointer;
`;
