import React from "react";
import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import TextField from "../form/text-field";
import TextAreaField from "../form/textarea-field";
import MoneyField from "../form/money-field";
import SelectField from "../form/select-field";
import SelectSearchField from "../form/select-search-field";
import SubmitButtonField from "../form/submit-button-field";

import ProjectPartnerContainer from "../../containers/project-partner-container";

import { Button } from "antd";

import FillForm from "../page-templates/fillForm-logged";

import {
  ProjectType,
  CustomEventBriefingType,
  CustomEventBriefingTypeNames,
} from "../../model";

import { SelectOne } from "../../v2/components/UI/SelectOne";
import { Input } from "../../v2/components/UI/Input";
import { Textarea } from "../../v2/components/UI/Textarea";
import { CurrencyInput } from "../../v2/components/UI/CurrencyInput";
import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";

class NewProposalStep1 extends React.Component {
  constructor(props) {
    super();

    this.state = {
      clientCurrency: null,
      fields: {
        briefingType: {
          onChange: this.onChangeFormValue.bind(this, "briefingType"),
          successIcon: false,
          visited: true,
        },
        clientId: {
          onChange: this.onChangeFormValue.bind(this, "clientId"),
          successIcon: false,
          visited: true,
        },
        productId: {
          onChange: this.onChangeProductId.bind(this),
          successIcon: false,
          visited: true,
        },
        projectName: {
          onChange: this.onChangeForm.bind(this, "projectName"),
          successIcon: false,
          visited: true,
        },
        clientResponsableUsername: {
          onChange: this.onChangeFormValue.bind(
            this,
            "clientResponsableUsername"
          ),
          successIcon: false,
          visited: true,
        },
        briefing: {
          successIcon: true,
          visited: true,
          onChange: this.onChangeForm.bind(this, "briefing"),
        },
        budgetTotal: {
          visited: true,
          onChange: this.onChangeForm.bind(this, "budgetTotal"),
        },
        resultsDesc: {
          visited: true,
          onChange: this.onChangeForm.bind(this, "resultsDesc"),
        },
        nonCompeteInDays: {
          label: "",
          onChange: this.onChangeForm.bind(this, "nonCompeteInDays"),
          successIcon: false,
          visited: true,
          // onBlur: this.onSave.bind(this, 'nonCompeteInDays')
        },
      },
      form: {},
      errors: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    this.props.get_clients();
  }

  getClients() {
    return (
      this.props.partner.projectClients &&
      this.props.partner.projectClients.map((item) => {
        return { code: item.clientId, label: item.clientCompanyName };
      })
    ) || [];
  }

  getProducts() {
    return (
      this.props.partner.clientBrands &&
      this.props.partner.clientBrands
        .map((brand) => {
          return brand.products.map((product) => {
            return {
              code: `${product.productId}-${brand.brandId}`,
              label: `${brand.name} - ${product.name}`,
            };
          });
        })
        .flat()
    ) || [];
  }

  getResponsibles() {
    let responsables = [];

    this.props.partner.clientBrands &&
      this.props.partner.clientBrands.map((brand) => {
        brand.products.map((product) => {
          if (product.productId == this.state.form.productId) {
            responsables = product.responsables;
          }
        });
      });

    return responsables.map((item) => ({
      code: item.username,
      label: item.name,
    }) || []);
  }

  getNonCompeteValues() {
    var values = [];

    values.push({ code: -1, label: I18n.get("Nenhum") });
    values.push({ code: 0, label: I18n.get("Durante o projeto") });
    values.push({ code: 30, label: I18n.get("Durante o projeto + 30 dias") });
    values.push({ code: 60, label: I18n.get("Durante o projeto + 60 dias") });
    values.push({ code: 90, label: I18n.get("Durante o projeto + 90 dias") });

    return values;
  }

  onChangeFormValue(prop, value) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });

    if (prop == "clientId") {
      this.props.get_brands_client(value);

      const clientActive = this.props.partner.projectClients.filter(
        (i) => i.clientId == value
      );

      this.setState({
        clientCurrency: clientActive[0].clientCurrency,
      });
    }
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeProductId(value, e) {
    let ids = value.split("-");

    const [productId, brandId] = ids;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["productId"] = productId;
      nextState.form["brandId"] = brandId;
      nextState.errors["productId"] = null;
      nextState.errors["brandId"] = null;

      return nextState;
    });
  }

  getData() {
    var data = {
      partnerId: this.props.partner.partnerId,
      ...this.state.form,
      projectType: ProjectType.EventCustom,
    };

    return data;
  }

  onSubmit(e) {
    e.preventDefault();

    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props
      .create_project_proposal(this.getData())
      .then(({ task = {}, errors = {}, project = {} }) => {

        if (task.result) {
          this.props.history.push(
            `/projetos/${project.projectId}/detalhamento-passo-2`
          );
        }

        this.setState({ submit_task: task, errors: errors });
      });
  }

  render() {
    // const isValid = (form) => {
    //     return form.clientId && form.productId && form.brandId && form.projectName;
    // }

    const eventTypes = [
      CustomEventBriefingType.Communication,
      CustomEventBriefingType.Event,
      CustomEventBriefingType.Shopper,
      CustomEventBriefingType.Incentive,
      CustomEventBriefingType.Digital,
      CustomEventBriefingType.Promotional,
    ];

    const datatypes = eventTypes && eventTypes.map((item) => ({
      code: item.toString(),
      label: CustomEventBriefingTypeNames[item],
    }));


    return (
      <FillForm>
        <div className="client-step client-step-1 mob-container">
          <div className="container client-container-1 client-container-build-project uplift-step-2">
            <div style={{ marginTop: "120px", maxWidth: "42rem" }}>
              <h1 className="title title-2">
                {I18n.get("Novo Proposal")}{" "}
                <span style={{ fontSize: "80%", opacity: "0.5" }}>
                  {" "}
                  &middot; {I18n.get("Comunicação/Digital")}
                </span>
              </h1>

              <form
                onSubmit={(e) => this.onSubmit(e)}
                style={{ marginBottom: 50 }}
              >
                {/* <SelectSearchField
                  data-select="selectClient"
                  {...this.state.fields.briefingType}
                  label={I18n.get("Escolha o tipo de projeto")}
                  value={this.state.form.briefingType}
                  error={this.state.errors.briefingType}
                  values={datatypes}
                /> */}

                <SelectOne
                  options={datatypes || []}
                  value={this.state.form?.briefingType || null} 
                  onChange={(value) =>
                    this.onChangeFormValue("briefingType", value)
                  }
                  placeholder={I18n.get("Escolha o tipo de projeto")}
                  error={this.state.errors?.briefingType?.errorMessage}
                />

                <br />

                {/* <SelectSearchField
                  data-select="selectClient"
                  {...this.state.fields.clientId}
                  label={I18n.get("Cliente")}
                  value={this.state.form.clientId}
                  error={this.state.errors.clientId}
                  values={this.getClients()}
                /> */}

                <SelectOne
                  options={this.getClients()}
                  value={this.state.form?.clientId || null}
                  onChange={(value) =>
                    this.onChangeFormValue("clientId", value)
                  }
                  placeholder={I18n.get("Cliente")}
                  error={this.state.errors?.clientId?.errorMessage}
                />

                <br />
                {/*
                <SelectSearchField
                  data-select="selectProduct"
                  {...this.state.fields.productId}
                  label={I18n.get("Marca / Produto")}
                  value={`${this.state.form.productId}-${this.state.form.brandId}`}
                  error={this.state.errors.productId}
                  values={this.getProducts()}
                />
*/}
                <SelectOne
                  options={
                    this.props.partner.clientBrands ? this.getProducts() : []
                  }
                  value={
                    this.state.form.productId
                      ? `${this.state.form.productId}-${this.state.form.brandId}`
                      : null
                  }
                  onChange={(value) => this.onChangeProductId(value)}
                  placeholder={I18n.get("Marca / Produto")}
                  error={this.state.errors?.productId?.errorMessage}
                />

                <br />

                {/* <SelectSearchField
                  data-select="selectResponsable"
                  {...this.state.fields.clientResponsableUsername}
                  label={I18n.get("Responsável")}
                  value={this.state.form.clientResponsableUsername}
                  error={this.state.errors.clientResponsableUsername}
                  values={this.getResponsibles()}
                /> */}

                <SelectOne
                  options={
                    this.props.partner.clientBrands
                      ? this.getResponsibles()
                      : []
                  }
                  value={this.state.form?.clientResponsableUsername}
                  onChange={(value) =>
                    this.onChangeFormValue("clientResponsableUsername", value)
                  }
                  placeholder={I18n.get("Responsável")}
                  error={this.state.errors?.clientResponsableUsername?.errorMessage}
                />

                <br />

                {/* <TextField
                  data-input="projectName"
                  {...this.state.fields.projectName}
                  label={I18n.get("Nome do projeto")}
                  value={this.state.form.projectName}
                  error={this.state.errors.projectName}
                /> */}

                <Input
                  label={I18n.get("Nome do projeto")}
                  value={this.state.form.projectName}
                  onChange={(value) =>
                    this.onChangeFormValue("projectName", value)
                  }
                  placeholder={I18n.get("Nome do projeto")}
                  error={this.state.errors?.projectName?.errorMessage}
                />

                {/* <TextAreaField
                  {...this.state.fields.briefing}
                  label={I18n.get("Briefing")}
                  value={this.state.form.briefing}
                  error={this.state.errors.briefing}
                /> */}

                <Textarea
                  label={I18n.get("Briefing")}
                  value={this.state.form.briefing}
                  onChange={(value) =>
                    this.onChangeFormValue("briefing", value)
                  }
                  error={this.state.errors.briefing?.errorMessage}
                />
                <br />

                {/* <MoneyField
                  {...this.state.fields.budgetTotal}
                  label={I18n.get("Budget Total")}
                  value={this.state.form.budgetTotal}
                  error={this.state.errors.budgetTotal}
                  style={{ maxWidth: "250px" }}
                  currency={
                    this.state.clientCurrency && this.state.clientCurrency
                  }
                /> */}

                <CurrencyInput
                  placeholder={I18n.get("Budget Total")}
                  value={this.state.form.budgetTotal}
                  onChange={(value) =>
                    this.onChangeFormValue("budgetTotal", value)
                  }
                  currency={
                    this.state.clientCurrency && this.state.clientCurrency
                  }
                  error={this.state.errors?.budgetTotal?.errorMessage}
                />

                {/* 
                <TextAreaField
                  {...this.state.fields.resultsDesc}
                  label={I18n.get("Como você vai medir o sucesso do projeto?")}
                  value={this.state.form.resultsDesc}
                  error={this.state.errors.resultsDesc}
                /> */}

                <Textarea
                  label={I18n.get("Como você vai medir o sucesso do projeto?")}
                  value={this.state.form.resultsDesc}
                  onChange={(value) =>
                    this.onChangeFormValue("resultsDesc", value)
                  }
                  error={this.state.errors.resultsDesc?.errorMessage}
                />

                <br />
                {/* 
                <SelectField
                  {...this.state.fields.nonCompeteInDays}
                  label={I18n.get(
                    "Precisa incluir cláusula de non-compete para este projeto? Por qual periodo (a partir do início do projeto)?"
                  )}
                  value={this.state.form.nonCompeteInDays}
                  error={this.state.errors.nonCompeteInDays}
                  values={this.getNonCompeteValues()}
                /> */}

                <SelectOne
                  options={this.getNonCompeteValues()}
                  value={this.state.form?.nonCompeteInDays}
                  onChange={(value) =>
                    this.onChangeFormValue("nonCompeteInDays", value)
                  }
                  placeholder={I18n.get(
                    "Precisa incluir cláusula de non-compete para este projeto? Por qual periodo (a partir do início do projeto)?"
                  )}
                  error={this.state.errors?.nonCompeteInDays?.errorMessage}
                />

                <br />
                <br />

                {/* <Button
                  className="btnnext"
                  type="primary"
                  htmlType="submit"
                  loading={this.state.submit_task.busy}
                >
                  {I18n.get("Avançar")}
                </Button> */}



                <ButtonBpool
                        text={I18n.get("Avançar")}
                        className=""
                        theme="primary"
                        loading={this.state.task?.busy}
                        htmlType="submit"
                       
                      />
              </form>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectPartnerContainer(NewProposalStep1);
