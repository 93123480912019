import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 15px;

  h2 {
    font-size: 18px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }
`;
