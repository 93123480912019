import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { SelectOne } from "../../../../components/UI/SelectOne";

import * as S from "./styles";

export const ButtonExpandBoxSelect = ({
  label,
  sectionCode,
  sublabel,
  description,
  active,
  expanded,
  subs,
  handleSelectItem,
  full,
  setYear,
  setMonth,
}) => {
  const [expand, setExpand] = useState(false);
  const [activedSubs, setActivedSubs] = useState([]);

  const handleClick = () => {
    if (expand) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  };

  const arrayYear = [
    { code: 1, label: I18n.get("1 year") },
    { code: 2, label: I18n.get("2 years") },
    { code: 3, label: I18n.get("3 years") },
    { code: 4, label: I18n.get("4 years") },
    { code: 5, label: I18n.get("5 years") },
    { code: 6, label: I18n.get("6 years") },
    { code: 7, label: I18n.get("7 years") },
    { code: 8, label: I18n.get("8 years") },
    { code: 9, label: I18n.get("9 years") },
    { code: 10, label: I18n.get("10 years") },
    { code: 11, label: I18n.get("11 years") },
    { code: 12, label: I18n.get("12 years") },
    { code: 13, label: I18n.get("13 years") },
    { code: 14, label: I18n.get("14 years") },
    { code: 15, label: I18n.get("15 years") },
    { code: 16, label: I18n.get("16 years") },
    { code: 17, label: I18n.get("17 years") },
    { code: 18, label: I18n.get("18 years") },
    { code: 19, label: I18n.get("19 years") },
    { code: 20, label: I18n.get("20 years") },
  ];

  const arrayMonth = [
    { code: 0, label: I18n.get("0 mês") },
    { code: 1, label: I18n.get("1 mês") },
    { code: 2, label: I18n.get("2 meses") },
    { code: 3, label: I18n.get("3 meses") },
    { code: 4, label: I18n.get("4 meses") },
    { code: 5, label: I18n.get("5 meses") },
    { code: 6, label: I18n.get("6 meses") },
    { code: 7, label: I18n.get("7 meses") },
    { code: 8, label: I18n.get("8 meses") },
    { code: 9, label: I18n.get("9 meses") },
    { code: 10, label: I18n.get("10 meses") },
    { code: 11, label: I18n.get("11 meses") },
    { code: 12, label: I18n.get("12 meses") },
  ];

  const handleClickSub = async (code, type) => {
    handleSelectItem({
      item: {
        code: code,
        type: type,
      },
      sectionCode: sectionCode,
    });
  };

  const handleYear = async (code, type, year) => {
    setYear({
      item: {
        code: code,
        type: type,
      },
      sectionCode: sectionCode,
      years: parseInt(year),
    });
  };

  const handleMonth = async (code, type, month) => {
    setMonth({
      item: {
        code: code,
        type: type,
      },
      sectionCode: sectionCode,
      months: parseInt(month),
    });
  };

  useEffect(() => {
    if (expanded) {
      setExpand(expanded);
    }
  }, [expanded]);

  return (
    <S.Wrapper>
      <S.Button active={active} onClick={handleClick}>
        <div>{label}</div>
        {sublabel ? <S.SubLabel>{sublabel}</S.SubLabel> : null}
      </S.Button>

      {expand ? (
        <S.Expanded>
          {/* <S.TextExpanded>{I18n.get(description)}</S.TextExpanded> */}
          <S.ExpandedContent>
            <S.WrapperUserMaster>
              <S.Subs>
                {subs?.map((sub) => {
                  return (
                    <li key={sub.code}>
                      <S.ButtonSub
                        active={sub.selected}
                        // disabled={full}
                      >
                        <span>{sub.label}</span>
                        <Checkbox
                          checked={sub.selected}
                          onChange={() => handleClickSub(sub.code, sub.type)}
                        />
                        {sub.selected && (
                          <div className="selectBox">
                            <div className="item">
                              <SelectOne
                                options={arrayYear}
                                value={sub.years}
                                // defaultValue={onEditing ? inputsForm.year : ''}
                                onChange={(value) =>
                                  handleYear(sub.code, sub.type, value)
                                }
                                required
                                placeholder={I18n.get("Years")}
                                // error={errrorsInternalForm?.industry}
                              />
                            </div>
                            <div className="item">
                              <SelectOne
                                options={arrayMonth}
                                value={parseInt(sub.months)}
                                // defaultValue={onEditing ? inputsForm.year : ''}
                                onChange={(value) =>
                                  handleMonth(sub.code, sub.type, value)
                                }
                                required
                                placeholder={I18n.get("Months")}
                                // error={errrorsInternalForm?.industry}
                              />
                            </div>
                          </div>
                        )}
                      </S.ButtonSub>
                    </li>
                  );
                })}
              </S.Subs>
            </S.WrapperUserMaster>
          </S.ExpandedContent>
        </S.Expanded>
      ) : null}
    </S.Wrapper>
  );
};
