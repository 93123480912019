import styled from "styled-components";

export const StyledButton = styled.a`
  cursor: pointer;
  border-radius: 28px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  font-size: 16px;
  padding: ${(props) => props.padding};
  text-decoration: none;
  display: inline-flex;
  border: 1px solid;
  border-color: ${(props) => props.borderColor};
  align-items: center;

  @media (max-width: 768px) {
    padding: 12px 35px;
  }

  img {
    width: 30px;
    margin-top: -3px;
    padding-right: 10px;
    filter: ${(props) => props.iconBrightness};
  }

  &:hover,
  &:visited {
    color: ${(props) => props.color};
  }
`;
