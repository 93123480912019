import React from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';

const Container = styled.div`
  @media screen and (min-width: 1472px) {
        max-width: 1344px;
        width: 100%;
        margin: 0 auto;
  }

  @media screen and (max-width: 1280px) {
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
  }


`;

const ContainerPage = ({ children }) => {

    return (
        <Container>
            {children}
        </Container>
    )
}

export default ContainerPage;
