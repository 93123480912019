import {
    LOGIN,
    LOGOUT
} from '../actions/auth/actions';

import { PROJECT_RESET } from '../actions/project/actions';

const initialState = {
    errors: {},
    task: {}
};

export default function projectReducer(state = initialState, action) {



    if (action.type == LOGIN || action.type == LOGOUT) return initialState;

    if (action.type == PROJECT_RESET) return initialState;

    if (action.type.indexOf('project_') == -1) return state;

    return Object.assign({}, state, action.payload);
}
