import React from 'react';
import * as S from './styles';

const Main = ({ bgColor, isSubmenu, children, padding, paddingTop, style }) => {
  return (
    <S.StyleContent
      bgColor={bgColor}
      isSubmenu={isSubmenu}
      padding={padding}
      paddingTop={paddingTop}
      style={style ? style : {}}
    >
      {children}
    </S.StyleContent>
  );
};

export default Main;
