import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import {
    Table,
    Icon
} from 'antd';

import ItemCause from './ItemCause'

const Btns = styled.p`
width: 50px;
displa: block;
    a {
        margin-left: 10px;
    }
`


class tableCause extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getSector = (sectorId) => {
        const items = this.props.sectorsFlat && this.props.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }

    render() {
        const columns = [
            {
                title: I18n.get("País"),
                dataIndex: 'name',
                key: 'name',
                // render: (expertise) => this.getSector(expertise),
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'item',
                width: "100px",
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                        partnerId: this.props.partnerId,
                    }

                    return (
                        <Btns>
                            <a onClick={() => this.props.delete(data, true)}>
                                <Icon type="delete" theme="filled" />
                            </a>
                        </Btns>
                    )
                }
                // render: (record) => this.props.delMarketExpertise(record.item.id),
            },
        ]

        let dataTable = this.props.data && this.props.data.map((item, index) => {
            return {
                key: index,
                name: item.country.name,
                item: item
            }
        });

        return (
            <Table
                columns={columns}
                dataSource={dataTable}
                locale={{ emptyText: I18n.get('Sem informação') }}
                scroll={{ x: 640 }}
            />
        );
    }
}

export default tableCause;
