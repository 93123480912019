import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import TextField from '../../form/text-field';
import CheckboxField from '../../form/checkbox-field';
import UploadItemForm from './_bpitch-upload-item';

import { scrollToTop } from '../../../utils/ui';

class BudgetForm extends React.Component {
    constructor(props) {

        super(props);

        const bPitch = props.bPitch || {};

        const { isProductInfoViaUpload, isAudienceInfoViaUpload, isBusinessObjectiveInfoViaUpload, isReasonToBelieveInfoViaUpload, isCompetitionInfoViaUpload, isBriefingInfoViaUpload, isKpiInfoViaUpload } = bPitch;

        this.state = {
            fields: {
                isProductInfoViaUpload: {
                    value: true,
                    onChange: this.onChangeFormCheckbox.bind(this, 'isProductInfoViaUpload'),
                    successIcon: false,
                },
                isAudienceInfoViaUpload: {
                    value: true,
                    onChange: this.onChangeFormCheckbox.bind(this, 'isAudienceInfoViaUpload'),
                    successIcon: false,
                },
                isBusinessObjectiveInfoViaUpload: {
                    value: true,
                    onChange: this.onChangeFormCheckbox.bind(this, 'isBusinessObjectiveInfoViaUpload'),
                    successIcon: false,
                },
                isReasonToBelieveInfoViaUpload: {
                    value: true,
                    onChange: this.onChangeFormCheckbox.bind(this, 'isReasonToBelieveInfoViaUpload'),
                    successIcon: false,
                },
                isCompetitionInfoViaUpload: {
                    value: true,
                    onChange: this.onChangeFormCheckbox.bind(this, 'isCompetitionInfoViaUpload'),
                    successIcon: false,
                },
                isBriefingInfoViaUpload: {
                    value: true,
                    onChange: this.onChangeFormCheckbox.bind(this, 'isBriefingInfoViaUpload'),
                    successIcon: false,
                },
                isKpiInfoViaUpload: {
                    value: true,
                    onChange: this.onChangeFormCheckbox.bind(this, 'isKpiInfoViaUpload'),
                    successIcon: false,
                },
            },
            errors: {},
            form: {
                isProductInfoViaUpload,
                isAudienceInfoViaUpload,
                isBusinessObjectiveInfoViaUpload,
                isReasonToBelieveInfoViaUpload,
                isCompetitionInfoViaUpload,
                isBriefingInfoViaUpload,
                isKpiInfoViaUpload
            },
            task: {},
            submit_task: {}
        }
    }

    componentDidMount() {
        scrollToTop();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        console.dir({ aatask: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            const { bPitch = {} } = this.props;

            Object.keys(this.state.fields).forEach((item) => {

                if (bPitch[item]) {
                    nextState.form[item] = bPitch[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormCheckbox(prop, e) {

        const value = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;

        }, () => this.onSaving());
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.props.bPitch,
            ...this.state.form
        };

        return data;
    }

    onSaving() {

        clearTimeout(this.t);
        this.t = setTimeout(() => this.onSave(), 1000)
    }

    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.update_bpitch(this.getData())
            .then(({ task = {}, errors = {} }) => {

                this.setState({ submit_task: task, errors: errors });
            });
    }

    onDelFile(item, e) {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        const data = {
            projectId: this.props.match.params.projectId,
            id: item.id
        };

        this.props.delete_bpitch_file(data).then(() => {

            this.setState((prevState) => {

                var nextState = Object.assign({}, prevState);
    
                nextState.form.isProductInfoViaUpload = false;
                nextState.form.isAudienceInfoViaUpload = false;
                nextState.form.isBusinessObjectiveInfoViaUpload = false;
                nextState.form.isReasonToBelieveInfoViaUpload = false;
                nextState.form.isCompetitionInfoViaUpload = false;
                nextState.form.isBriefingInfoViaUpload = false;
                nextState.form.isKpiInfoViaUpload = false;
    
                return nextState;
    
            }, () => this.onSaving());

        });

    }


    render() {

        const { bPitch = {} } = this.props;

        const hasFile = bPitch && bPitch.files && bPitch.files.length > 0;

        return (
            <div>

                <h1 className="title-bpool medium">{I18n.get("Upload Arquivos")}</h1>

                <p>
                    {I18n.get("Se você tem seu próprio documento de briefing e/ou documentos de apoio, faça upload por aqui.")}
                </p>

                <div className="bp-event-section" style={{ marginTop: '2rem', maxWidth: '40rem' }}>

                    {bPitch.files && bPitch.files.map((item) => {

                        return (
                            <div className="columns" style={{ marginTop: '1rem', borderBottom: 'solid 1px #eeeeee' }}>

                                <div className="column is-5">

                                    <div className="field">
                                        <label className="label is-small">{I18n.get("Arquivo")}</label>
                                        <div className="control">
                                            <a href={item.url} target='_blank'>{item.name}</a>
                                        </div>
                                    </div>

                                </div>

                                <div className="column is-5">

                                    <div className="field">
                                        <label className="label is-small">{I18n.get("Descrição")}</label>
                                        <div className="control">
                                            {item.description}
                                        </div>
                                    </div>

                                </div>

                                <div className="column is-2 has-text-right">
                                    <div className="field is-narrow">
                                        <div className="control">
                                            <a onClick={this.onDelFile.bind(this, item)} className='button is-rounded is-black is-outlined'>
                                                <span className="icon is-small">
                                                    <i className="fa fa-times"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        );
                    })}

                    <br />

                    <UploadItemForm {...this.props} />

                    <br /><br /><br />

                    <h1 style={{ fontSize: '90%' }}>{I18n.get("Marque abaixo as informações que constam no arquivo para pular as seções correspondentes no questionário")}</h1>

                    <br /><br />

                    <CheckboxField
                        {...this.state.fields.isProductInfoViaUpload}
                        disabled={!hasFile}
                        label={I18n.get("As informações de 'Produto, Marca e Mercado' constam no arquivo, pular perguntas da seção.")}
                        checked={this.state.form.isProductInfoViaUpload}
                        error={this.state.errors.isProductInfoViaUpload} />

                    <br />


                    <CheckboxField
                        {...this.state.fields.isAudienceInfoViaUpload}
                        disabled={!hasFile}
                        label={I18n.get("As informações de 'Público e abrangência' constam no arquivo, pular perguntas da seção.")}
                        checked={this.state.form.isAudienceInfoViaUpload}
                        error={this.state.errors.isAudienceInfoViaUpload} />

                    <br />

                    <CheckboxField
                        {...this.state.fields.isBusinessObjectiveInfoViaUpload}
                        disabled={!hasFile}
                        label={I18n.get("As informações de 'Objetivos de Negócio e Comunicação' constam no arquivo, pular perguntas da seção.")}
                        checked={this.state.form.isBusinessObjectiveInfoViaUpload}
                        error={this.state.errors.isBusinessObjectiveInfoViaUpload} />

                    <br />

                    <CheckboxField
                        {...this.state.fields.isReasonToBelieveInfoViaUpload}
                        disabled={!hasFile}
                        label={I18n.get("As informações de 'Reason to Believe e Benefícios' constam no arquivo, pular perguntas da seção.")}
                        checked={this.state.form.isReasonToBelieveInfoViaUpload}
                        error={this.state.errors.isReasonToBelieveInfoViaUpload} />

                    <br />

                    <CheckboxField
                        {...this.state.fields.isCompetitionInfoViaUpload}
                        disabled={!hasFile}
                        label={I18n.get("As informações de 'Concorrência' constam no arquivo, pular perguntas da seção.")}
                        checked={this.state.form.isCompetitionInfoViaUpload}
                        error={this.state.errors.isCompetitionInfoViaUpload} />

                    <br />

                    <CheckboxField
                        {...this.state.fields.isBriefingInfoViaUpload}
                        disabled={!hasFile}
                        label={I18n.get("As informações de 'Detalhes do Projeto' constam no arquivo, pular perguntas da seção.")}
                        checked={this.state.form.isBriefingInfoViaUpload}
                        error={this.state.errors.isBriefingInfoViaUpload} />

                    <br />

                    <CheckboxField
                        {...this.state.fields.isKpiInfoViaUpload}
                        disabled={!hasFile}
                        label={I18n.get("As informações de 'Entregáveis e KPIs' constam no arquivo, pular perguntas da seção.")}
                        checked={this.state.form.isKpiInfoViaUpload}
                        error={this.state.errors.isKpiInfoViaUpload} />                    

                    <br /><br />

                </div>
            </div>
        );
    }
}

export default BudgetForm;
