import React from 'react';

import { I18n } from 'aws-amplify';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import ScheduleForm from './schedule-form';

import StepsCreative from './steps-creative';
import StepsBidding from './steps-bidding';

import { scrollToTop } from '../../utils/ui';

class ProjectDefintionMilestones extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    submitForm(e) {

    }

    componentDidMount() {

        scrollToTop();
    }

    render() {

        return (
            <FillForm>
                <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-6" style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
                                <p className="title">{I18n.get("Definições do Projeto")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-6">
                                <StepsCreative active={2} projectId={this.props.match.params.projectId} terms={this.props.clientTermsUpdatedOn} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-definition">

                    <div className="container client-container-1">

                        <ScheduleForm {...this.props} />

                        <br />
                        <br />


                    </div>

                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(ProjectDefintionMilestones);
