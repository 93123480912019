import React from "react";
import { I18n } from "aws-amplify";
import { withRouter } from "react-router-dom";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextAreaField from "../form/textarea-field";
import SubmitButtonField from "../form/submit-button-field";

import NakedModal from "../ui/naked-modal";

import QuestionItem from "../shared/question-item";

import { Icon, Button } from "antd";

class QuestionsForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      fields: {
        body: {
          label: I18n.get("Digite a pergunta"),
          onChange: this.onChangeForm.bind(this, "body"),
          successIcon: false,
          visited: true,
        },
        answer: {
          label: I18n.get("Digite a resposta"),
          onChange: this.onChangeForm.bind(this, "answer"),
          successIcon: false,
          visited: true,
        },
      },
      form: {},
      errors: {},
      task: {},
      submit_task: {},
      questionIsOpen: false,
      answerIsOpen: false,
    };

    this.itemRefs = {};
  }

  componentDidMount() {
    const data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_partners(data);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.fields).forEach((item) => {
        const value = this.props[item];

        nextState.form[item] = value;
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {}

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  refresh() {
    const data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_partners(data);
  }

  onOpenAnswer(item, e) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form.questionId = item.id;
      nextState.form.body = item.body;
      nextState.form.answer = item.answer;
      nextState.answerIsOpen = true;

      return nextState;
    });
  }

  onCreateQuestion(e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      body: this.state.form.body,
      answer: this.state.form.answer,
    };

    var submit_task = new Task();

    submit_task.start();

    this.setState({ task: submit_task });

    this.props.createQuestion(data).then(({ task, errors }) => {
      if (task.result) {
        this.setState({ task: task, errors: errors, questionIsOpen: false });
      } else {
        this.setState({ task: task, errors: errors });
      }
    });
  }

  onUpdateQuestion(e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      questionId: this.state.form.questionId,
      body: this.state.form.body,
      answer: this.state.form.answer,
    };

    var submit_task = new Task();

    submit_task.start();

    this.setState({ task: submit_task });

    this.props.updateQuestion(data).then(({ task, errors }) => {
      this.refresh();

      if (task.result) {
        this.setState({ task: task, errors: errors, answerIsOpen: false });
      } else {
        this.setState({ task: task, errors: errors });
      }
    });
  }

  onDeleteQuestion(id, e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      id: id,
    };

    var submit_task = new Task();

    submit_task.start();

    this.setState({ task: submit_task });

    this.props.deleteQuestion(data).then(({ task, errors }) => {
      this.refresh();

      if (task.result) {
        this.setState({ task: task, errors: errors, questionIsOpen: false });
      } else {
        this.setState({ task: task, errors: errors });
      }
    });
  }

  render() {
    let questionCount = 0;

    return (
      <div className="container">
        <form className="partnersApproval">
          <br />

          <p className="title is-5">{I18n.get("Perguntas & Respostas")}</p>
          <p class="subtitle is-6">
            {I18n.get(
              "O recurso de Perguntas & Respostas fica ativo durante a fase de propostas. Ele serve para que os parceiros possam tirar dúvidas ao longo do processo e cada resposta dada será enviada a todos os parceiros participantes."
            )}
          </p>

          <br />
          <br />

          <div className="columns">
            <div className="column is-10">
              {this.props.questions &&
                this.props.questions.map((question, index) => {
                  return (
                    <QuestionItem
                      question={question}
                      onDeleteQuestion={this.onDeleteQuestion.bind(
                        this,
                        question.id
                      )}
                    />
                  );
                })}

              <Button
                className="button bt-bpool black"
                loading={this.state.task.busy}
                onClick={(e) => this.setState({ questionIsOpen: true })}
              >
                {I18n.get("Cadastrar Pergunta & Resposta")}
              </Button>

              <br />
              <br />
              <br />
              <br />

              <h1 className="title-bpool medium">
                {I18n.get("Perguntas Novas")}
              </h1>

              <br />

              {this.props.partners &&
                this.props.partners.map((partner) => {
                  const pendingQuestions =
                    partner.pendingQuestions &&
                    partner.pendingQuestions.filter(
                      (item) =>
                        item.projectId == this.props.match.params.projectId
                    );

                  return (
                    <div>
                      {pendingQuestions &&
                        pendingQuestions.map((question, index) => {
                          const style = {
                            backgroundColor: "#ffffff",
                          };

                          if (this.props.highlightQuestionId == question.id) {
                            style["borderLeft"] = "solid 4px #E30404";

                            if (this.itemRefs[question.id]) {
                              this.itemRefs[question.id].scrollIntoView();
                            }
                          }

                          questionCount++;

                          return (
                            <>
                              <a
                                ref={(el) => (this.itemRefs[question.id] = el)}
                                id={question.id}
                                name={question.id}
                              ></a>

                              <QuestionItem
                                question={question}
                                onDeleteQuestion={this.onDeleteQuestion.bind(
                                  this,
                                  question.id
                                )}
                                partner={partner}
                                onOpenAnswer={this.onOpenAnswer.bind(
                                  this,
                                  question
                                )}
                              />
                            </>
                          );
                        })}
                    </div>
                  );
                })}

              {questionCount == 0 && (
                <p style={{ fontSize: "80", color: "#909090" }}>
                  {I18n.get("Nenhuma pergunta nova.")}
                </p>
              )}

              <br />
              <br />

              <div>
                <a
                  href="#"
                  className="button bt-bpool"
                  onClick={this.props.onHideQuestions}
                >
                  {I18n.get("< Voltar")}
                </a>
              </div>
            </div>
          </div>
        </form>

        <NakedModal isOpen={this.state.questionIsOpen}>
          <div className="card" style={{ margin: "0.5rem" }}>
            <header className="card-header">
              <p className="card-header-title">{I18n.get("Pergunta")}</p>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ questionIsOpen: false });
                }}
                className="card-header-icon"
                aria-label="more options"
              >
                <span className="icon">
                  <i className="fas fa-times" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div className="card-content" style={{ maxWidth: "960px" }}>
              <div className="content has-text-left">
                <form>
                  <p style={{ fontSize: "80%" }}>
                    {I18n.get(
                      "Você pode antecipar as dúvidas dos parceiros cadastrando aqui as perguntas & respostas mais frequentes."
                    )}
                  </p>
                  <br />
                  <br />

                  <TextAreaField
                    {...this.state.fields.body}
                    value={this.state.form.body}
                    error={this.state.errors.body}
                  />

                  <TextAreaField
                    {...this.state.fields.answer}
                    value={this.state.form.answer}
                    error={this.state.errors.answer}
                  />

                  <br />
                  <br />

                  <div className="columns">
                    <div className="column is-8"></div>
                    <div className="column is-4">
                      <SubmitButtonField
                        className="button bt-bpool black"
                        label={I18n.get("Salvar")}
                        onClick={this.onCreateQuestion.bind(this)}
                        task={this.state.task}
                        suppressLabel={true}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </NakedModal>

        <NakedModal isOpen={this.state.answerIsOpen}>
          <div className="card" style={{ margin: "0.5rem" }}>
            <header className="card-header">
              <p className="card-header-title">
                {I18n.get("Pergunta & Resposta")}
              </p>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ answerIsOpen: false });
                }}
                className="card-header-icon"
                aria-label="more options"
              >
                <span className="icon">
                  <i className="fas fa-times" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div className="card-content" style={{ maxWidth: "960px" }}>
              <div className="content has-text-left">
                <form>
                  <br />
                  <br />

                  <TextAreaField
                    {...this.state.fields.body}
                    value={this.state.form.body}
                    error={this.state.errors.body}
                  />

                  <TextAreaField
                    {...this.state.fields.answer}
                    value={this.state.form.answer}
                    error={this.state.errors.answer}
                  />

                  <br />
                  <br />

                  <div style={{ fontSize: "80%", fontWeight: "bold" }}>
                    {I18n.get(
                      "Ao responder, a pergunta se tonar pública no mural e os parceiros recebem um e-mail com a pergunta e a resposta."
                    )}
                  </div>

                  <br />
                  <br />

                  <div className="columns">
                    <div className="column is-8"></div>
                    <div className="column is-4">
                      <SubmitButtonField
                        className="button bt-bpool black"
                        label={I18n.get("Responder")}
                        onClick={this.onUpdateQuestion.bind(this)}
                        task={this.state.task}
                        suppressLabel={true}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </NakedModal>
      </div>
    );
  }
}

export default withRouter(QuestionsForm);
