import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { BP_IND_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const get_industries = (data) => {
  return (dispatch, getState) => {
    Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .get(`${Config.API_BASE_URL}/client-project/industries`, config)
          .then((response) => {
            dispatch({
              type: BP_IND_GET,
              payload: {
                industries: response.data.data,
                errors: {},
                task: {
                  result: true,
                },
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: BP_IND_GET,
              payload: {
                errors: {},
                task: {
                  result: false,
                },
              },
            });
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default get_industries;
