import styled from "styled-components";

export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;


export const SelectBox = styled.div`
  .select-references-culture-dropdown {
    margin-bottom: 5px;

    .ant-select-selection {
      background: #fff;
      border: 1px solid #999999;
      height: 50px;
      border-radius: 10px !important;

      .ant-select-selection__rendered {
        height: 100%;
        display: flex;
        align-items: center;
      }

      span {
        i {
          top: 16px;
        }
      }
    }
  }

  .select-references-culture-dropdown.error {
    .ant-select-selection {
      border: 1px solid #ff0000;
    }
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const TitleSection = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #010202;
  margin-bottom: 20px;
`;

export const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 15px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoImage = styled.div`
  width: 117px;
  height: 145px;
  border-radius: 50%;

  img {
    border: 1px solid #000000;
    border-radius: 50%;
  }

  .button-remove-upload {
    margin-left: 0;
    margin-top: 160px;
  }
`;

export const SizeLogo = styled.div`
  position: relative;
  left: 0;
  top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #999999;
  text-align: center;

  strong {
    font-size: 10px;
    color: #999999;
  }
`;

export const ErrorText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #ff0000;
  top: 145px;
  position: relative;
  line-height: 14px;
`;

export const InputsContent = styled.div`
  width: 100%;
  display: block;
  position: relative;
  float: left;
`;

export const RowInput = styled.div`
  width: calc(50% - 15px);
  height: 80px;
  position: relative;
  float: left;
`;
