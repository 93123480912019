import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { BP_API } from "../../actions/bp/actions";

import LoggedPage from "../page-templates/loggedAdmin";
import Loading from "../../components/pages/loading";

import ClientDetailsView from "./client/_details";

// import '../../Admin.css';

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_clients: (data) => {
      dispatch(BP_API.get_clients(data));
    },
    get_client: (id) => {
      return dispatch(BP_API.get_client(id));
    },
    create_client_settings_project_rule: (data) => {
      return dispatch(BP_API.create_client_settings_project_rule(data));
    },
    delete_client_settings_project_rule: (data) => {
      return dispatch(BP_API.delete_client_settings_project_rule(data));
    },
    create_client_settings_extra_rule: (data) => {
      return dispatch(BP_API.create_client_settings_extra_rule(data));
    },
    delete_client_settings_extra_rule: (data) => {
      return dispatch(BP_API.delete_client_settings_extra_rule(data));
    },
    delete_client_settings_bpay_rule: (data) => {
      return dispatch(BP_API.delete_client_settings_bpay_rule(data));
    },
    get_exceptions: (data) => {
      return dispatch(BP_API.get_partners_exception(data));
    },
    add_partners_exception: (data) => {
      return dispatch(BP_API.add_partners_exception(data));
    },
    add_product_exception: (data) => {
      return dispatch(BP_API.add_produtcs_exception(data));
    },
    remove_partners_exception: (data) => {
      return dispatch(BP_API.remove_partners_exception(data));
    },
    remove_product_exception: (data) => {
      return dispatch(BP_API.remove_product_exception(data));
    },
    update_types: (data) => {
      return dispatch(BP_API.update_types(data));
    },
    update_rules_proposal: (data) => {
      return dispatch(BP_API.update_rules_proposal(data));
    },
    update_rules_bpay: (data) => {
      return dispatch(BP_API.update_rules_bpay(data));
    },
    update_master: (data) => {
      return dispatch(BP_API.update_master(data));
    },
    create_client_settings_bpay_rule: (data) => {
      return dispatch(BP_API.create_client_settings_bpay_rule(data));
    },
  };
};

class ClientDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      clientId: props.match.params.clientId,
      loading: false,
    };
  }

  componentDidMount() {
    this.props.get_client(this.state.clientId).then(response => {
      this.setState({loading: true});
    });
    // this.props.get_exceptions(this.state.clientId);
  }

  render() {
    if(!this.state.loading) return <Loading />;
    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/bpool/clientes" className="is-black">
                {I18n.get("Clientes")}
              </NavLink>
            </li>
            <li className="is-active">
              <a href="#">
                {this.props.bp.client && this.props.bp.client.name}
              </a>
            </li>
          </ul>
        </nav>

        <div className="columns">
          <div className="column is-12">
            <ClientDetailsView {...this.props} />
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
