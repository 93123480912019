import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { PROJECT_API } from "../../actions/project/actions";
import { BP_API } from "../../actions/bp/actions";

import LoggedPage from "../page-templates/loggedAdmin";

import ClientProjectDetailsView from "./client-project/_details";

import Loading from "../pages/loading";

const mapStateToProps = (state) => {
  return Object.assign({}, state, state.bp && state.bp.project);
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_project: (data) => {
      dispatch(BP_API.get_project(data));
    },
    get_domain: () => {
      dispatch(BP_API.get_domain());
    },
    get_possible_matches: (data) => {
      dispatch(BP_API.get_possible_matches(data));
    },
    get_candidates: (data) => {
      dispatch(BP_API.get_candidates(data));
    },
    get_assets: (data) => {
      dispatch(BP_API.get_assets(data));
    },
    update_match: (data) => {
      dispatch(BP_API.update_match(data));
    },
    addEventQuotationItemComment: (data) => {
      return dispatch(BP_API.create_event_quotation_item_comment(data));
    },
    delEventQuotationItemComment: (data) => {
      return dispatch(BP_API.delete_event_quotation_item_comment(data));
    },
    sendEventQuotationItemNotification: (data) => {
      return dispatch(BP_API.send_event_quotation_item_notification(data));
    },
    updateEventQuotationItemStatus: (data) => {
      return dispatch(BP_API.update_event_quotation_item_status(data));
    },
    exportEventQuotation: (data) => {
      return dispatch(BP_API.export_event_quotation(data));
    },
    getPartnerResponsible: (data) => {
      return dispatch(PROJECT_API.get_partner_responsible(data));
    },
    updatePartnerResponsible: (data) => {
      return dispatch(PROJECT_API.update_partner_responsible(data));
    },
    update_settings: (data) => {
      return dispatch(BP_API.update_settings(data));
    },
    getStatusAdmin: () => {
      dispatch(BP_API.get_status_admin());
    },
    updateStatusProject: (data) => {
      dispatch(BP_API.update_status_project(data));
    },
    updateCommission: (data) => {
      dispatch(BP_API.update_commission(data));
    },
    updateBpCommission: (data) => {
      return dispatch(BP_API.update_bpcommission(data));
    },
    getClientResponsables: (data) => {
      dispatch(BP_API.get_responsable_client(data));
    },
    updateResponsableClient: (data) => {
      dispatch(BP_API.update_responsable_client(data));
    },
    getPartnerResponsables: (data) => {
      dispatch(BP_API.get_responsable_partner(data));
    },
    updateResponsablePartner: (data) => {
      dispatch(BP_API.update_responsable_partner(data));
    },
    updateCancelInvite: (data) => {
      dispatch(BP_API.update_cancel_invite(data));
    },
    refreshProject: (data) => {
      return dispatch(BP_API.refresh_project(data));
    },
    deleteExtra: (data) => {
      dispatch(BP_API.delete_extra(data));
    },
    fire_pre_match: (data) => {
      return dispatch(BP_API.fire_pre_match(data));
    },    
    refreshProjectCurrency: (data) => {
      return dispatch(PROJECT_API.refreshProject(data));
    },
    
  };
};

class ClientDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      projectId: props.match.params.projectId,
    };
  }

  componentDidMount() {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_assets(data);
    this.props.get_project(data);
    this.props.get_possible_matches(data);
    this.props.getStatusAdmin();
  }

  handleCandidates = () => {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_candidates(data);
  };

  render() {
    // if(!this.props.project?.project) return (<Loading />);

    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/bpool/projetos" className="is-black">
                {I18n.get("Projetos")}
              </NavLink>
            </li>
            <li className="is-active">
              <a href="#">{this.props.projectName}</a>
            </li>
          </ul>
        </nav>

        <div className="columns">
          <div className="column is-12">
            {this.props.project?.project && (
              <ClientProjectDetailsView
                {...this.props}
                loadCandidates={this.handleCandidates}
              />
            )}
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
