import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Modal } from "antd";
import LoggedPage from "../../../../components/page-templates/loggedAdmin";
import { useNotifications } from "../../../hooks/Admin/useNotification/useNotification.hook";
import { TableUsers } from "./components/TableUsers";
import { FormUser } from "./components/FormUser";

export const NotificationsAdmin = () => {
  const { getUsers, getById, getFilter, deleteUser } = useNotifications();
  const [loaderUsers, setLoaderUsers] = useState(false);
  const [loaderFilters, setLoaderFilters] = useState(false);
  const [users, setUsers] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    clients: [],
    partners: [],
    profiles: [],
    regions: [],
    triggers: [],
    contexts: [],
  });
  const [editingUser, setEditingUser] = useState({ id: "" });
  const [loadingEditUser, setLoadingEditUser] = useState(null);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInfos, setModalInfos] = useState({
    title: "",
    type: "",
    message: "",
    id: "",
  });
  const [loadersUsers, setLoadersUsers] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const handleOpenModal = async ({ title, type, message, id }) => {
    setModalInfos({
      title,
      type,
      message,
      id,
    });
    setIsModalVisible(true);
  };

  const handleCloseModal = async () => {
    setModalInfos({
      title: "",
      type: "",
      message: "",
      id: "",
    });
    setIsModalVisible(false);
  };

  const loadUsers = async () => {
    try {
      setLoaderUsers(true);
      const { data } = await getUsers();
      setUsers(data);
    } catch (error) {
      console.log("Error load users", error);
    } finally {
      setLoaderUsers(false);
    }
  };

  const loadFilters = async () => {
    try {
      setLoaderFilters(true);

      const { data } = await getFilter();

      const triggers = [];

      if (data?.triggers?.length) {
        data.triggers.map((role, index) =>
          triggers.push({ ...role, key: index + 1 })
        );
      }

      setDataFilter({
        clients: data?.clients,
        partners: data?.partners,
        profiles: data?.profiles,
        regions: data?.regions,
        triggers: triggers,
        contexts: data?.contexts,
      });
    } catch (error) {
      console.log("Error load filter", error);
    } finally {
      setLoaderFilters(false);
    }
  };

  useEffect(() => {
    loadUsers();
    loadFilters();
  }, []);

  const getEditUser = async (id) => {
    const response = await getById({ id });

    if (response.success) {
      return response.data;
    } else {
      return { id: "" };
    }
  };

  const handleEditUser = async (id, name, email) => {
    setLoadingEditUser(id);
    const user = await getEditUser(id);
    setLoadingEditUser(null);

    setEditingUser(user);
  };

  const handleDeleteUserApi = async () => {
    try {
      setLoadersUsers({ ...loadersUsers, delete: true });
      await deleteUser({ id: modalInfos.id });
      await handleCloseModal();
      await loadUsers();

      handleOpenModal({
        title: I18n.get("Sucesso"),
        type: "success",
        message: `${I18n.get("Usuário excluído com sucesso!")}`,
        id: "",
      });
    } catch (error) {
      console.log("Error in delete User", error);
    } finally {
      setLoadersUsers({ ...loadersUsers, delete: false });
    }
  };

  const handleDeleteUser = (id, name, email) => {
    handleOpenModal({
      title: I18n.get("Excluir"),
      type: "delete",
      message: `${I18n.get("Deseja excluir")} ${name}?`,
      id: id,
    });
  };

  return (
    <>
      <LoggedPage>
        <div style={{ marginTop: 48 }} />
        <section className="info-tiles">
          <div className="columns is-centered">
            <div className="column is-8">
              <div className="box">
                <TableUsers
                  loaderUsers={loaderUsers}
                  usersData={users}
                  loadUsers={loadUsers}
                  handleEditUser={handleEditUser}
                  handleDeleteUser={handleDeleteUser}
                  loadingEditUser={loadingEditUser}
                />

                <FormUser
                  loaderUsers={loaderUsers}
                  usersData={users}
                  loadUsers={loadUsers}
                  contextsData={dataFilter?.contexts}
                  regionsData={dataFilter?.regions}
                  profilesData={dataFilter?.profiles}
                  clientsData={dataFilter?.clients}
                  partnersData={dataFilter?.partners}
                  triggersData={dataFilter?.triggers}
                  loaderFilters={loaderFilters}
                  handleOpenModal={handleOpenModal}
                  loadersUsers={loadersUsers}
                  setLoadersUsers={setLoadersUsers}
                  editingUser={editingUser}
                  setEditingUser={setEditingUser}
                  name={name}
                  setName={setName}
                  mail={mail}
                  setMail={setMail}
                />
              </div>
            </div>
          </div>
        </section>
      </LoggedPage>

      <Modal
        title={modalInfos.title}
        centered
        visible={isModalVisible}
        onOk={
          modalInfos.type === "delete"
            ? () => handleDeleteUserApi()
            : () => handleCloseModal()
        }
        okText={
          modalInfos.type === "delete" ? I18n.get("Excluir") : I18n.get("Ok")
        }
        okButtonProps={modalInfos.type === "delete" ? { type: "danger" } : {}}
        onCancel={() => handleCloseModal()}
        cancelText={I18n.get("Cancelar")}
        confirmLoading={
          modalInfos.type === "delete" ? loadersUsers.delete : false
        }
      >
        <p>{modalInfos.message}</p>
      </Modal>
    </>
  );
};
