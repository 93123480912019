import React from 'react';

import { I18n } from 'aws-amplify';

const Steps = (props) => {



    if (!props.bp || !props.bp.project) return null;

    const item = props.bp.project;

    console.log("item.status", item.status);

    function getClassName(step) {

        let setpIndex = step === 2000 || step === 5000 ? 2000 : step;
        let itemIndex = item.status === 2000 || item.status === 5000 ? 2000 : step;


        if (itemIndex === setpIndex) {
            return 'is-active';
        }
        else if (itemIndex >= setpIndex) {
            return 'is-completed bp-is-completed';
        }
        else {
            return 'bp-is-pending';
        }
    }

    const items = [
        {
            index: 1000,
            title: I18n.get("Rascunho"),
            desc: 'Preenchendo o projeto.'
        },
        {
            index: 2000,
            title: I18n.get("Criado / Reprovado"),
            desc: 'Aguardando confirmação BPool.'
        },
        {
            index: 3000,
            title: I18n.get("Enviado Aprovação"),
            desc: 'Aguardando aceite dos Parceiros.'
        },
        {
            index: 4000,
            title: I18n.get("Aprovado"),
            desc: 'Aguardando escolha pelo Cliente.'
        }
    ];


    return (
        <div className="steps">
            {items.map((step, index) =>

                
                (<div key={index} className={'step-item ' + getClassName(step.index)}>
                    <div className="step-marker"></div>
                    <div className="step-details">
                        <p className="step-title">{step.title}</p>
                        <p>{step.desc}</p>
                    </div>
                </div>)
            )}
        </div>
    )
};

export default Steps;
