import React from 'react';

import { I18n } from 'aws-amplify';

const Modal = (props) => {

    const className = props.isOpen ? 'is-active' : '';

    return (
        <div className={'modal ' + className}>
            <div className="modal-background"></div>
            <div className="modal-card" style={{ width: '960px' }}>
                <section className="modal-card-body">

                    <button onClick={props.closeModal} className="delete is-pulled-right" aria-label="close"></button>

                    {props.children}

                </section>
            </div>
        </div>
    )
};

export default Modal;