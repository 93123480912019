import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { EyeOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";

import * as S from "./styles";

export const Attachments = ({ data }) => {
  const [showAttachments, setShowAttachments] = useState(false);

  const handleOpenAttachments = () => {
    if (showAttachments) {
      setShowAttachments(false);
    } else {
      setShowAttachments(true);
    }
  };

  if (data?.length === 0) {
    return (
      <S.InfoItem>
        <S.LabelItem>{I18n.get("Attachments: ")}</S.LabelItem>
        <S.ContentItem>{I18n.get("No files")}</S.ContentItem>
      </S.InfoItem>
    );
  } else {
    return (
      <>
        <S.InfoItem>
          <S.LabelItem>{I18n.get("Attachments: ")}</S.LabelItem>
          <S.ContentItem>
            <button onClick={handleOpenAttachments}>
              {`${data?.length} ${I18n.get("files")}`}
              {showAttachments ? <UpOutlined /> : <DownOutlined />}
            </button>
          </S.ContentItem>
        </S.InfoItem>

        <S.AttachmentsContent open={showAttachments} itemsRows={data?.length}>
          <ul>
            {data?.map((item) => (
              <li key={item?.id}>
                <a href={item?.url} target="_blank" rel="noopener noreferrer">{item?.name} <EyeOutlined /></a>
              </li>
            ))}
          </ul>
        </S.AttachmentsContent>
      </>
    );
  }
};
