import React from 'react';
import { I18n } from 'aws-amplify';
import { Redirect } from "react-router-dom";

import { Profile } from '../../model';

import MaskedField from '../form/masked-field';
import SubmitButtonField from '../form/submit-button-field';

import ErrorPane from '../error-pane';

import { Task } from '../../utils/task';

export default class PartnerEvaluation extends React.Component {

    constructor(props) {
        super();

        const evaluations = props.bp.partner.evaluations.filter((item) => item.username == props.bp.username);

        const items = evaluations.length && evaluations[0].items || [];

        this.state = {
            partnerId: props.match.params.partnerId,
            username: props.bp.username,
            items: items,
            task: {
                errors: {}
            }
        };

        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        this.props.get_users(Profile.BPool);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.bp.task || {};
        const prev = prevProps && prevProps.bp.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {
            this.setState({ task: task });
        }
    }

    getEvaluationItems(user) {

        if (this.isSelf(user)) {
            return this.state.items;
        }

        const evaluations = this.props.bp.partner.evaluations.filter((item) => item.username == user.username);

        return evaluations.length && evaluations[0].items || [];
    }

    getEvaluation(user, criteria) {

        var stub = { score: '-' };



        if (!this.props.bp.username) return stub;
        if (!this.props.bp.partner) return stub;

        const items = this.getEvaluationItems(user);

        if (!items) return stub;

        const item = items.find((e) => e.code == criteria.code);

        return item || stub;
    }

    getError() {
        return null;
    }

    isSelf(user) {
        return this.props.bp.username == user.username;
    }

    onChangeEvaluation(criteria, e) {

        const value = e.target.value;

        var score = parseInt(value);

        if (score < 0) return;
        if (score > 10) return;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState, {});

            const index = prevState.items.findIndex((item) => item.code == criteria.code);

            if (index > -1) {
                nextState.items[index].score = score;
            }
            else {
                var evaluation = Object.assign({}, criteria, { score: score });
                nextState.items.push(evaluation);
            }

            return nextState;
        });
    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.state);
        });
    }


    render() {

        const users = this.props.bp.users || [];
        const evaluationCriterias = this.props.bp.evaluationCriterias || [];

        return (
            <div className="card">
                <div className="card-content">

                    <h3 className="title is-4">{I18n.get("Avaliação")}</h3>

                    <div className="content">

                        <form onSubmit={this.submitForm}>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    {evaluationCriterias.map((criteria, col_index) =>
                                        (<th key={col_index}>
                                            {criteria.label} ({criteria.weight}%)
                                        </th>)
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, row_index) =>
                                    (<tr key={row_index}>
                                            <td>{user.email}</td>
                                            {evaluationCriterias.map((criteria, col_index) =>
                                                (<td key={col_index} className="has-text-centered">
                                                    {this.isSelf(user) &&

                                                        <input
                                                            type="number"
                                                            className="input has-text-centered"
                                                            value={this.getEvaluation(user, criteria).score}
                                                            onChange={this.onChangeEvaluation.bind(this, criteria)}
                                                        />

                                                    }
                                                    {!this.isSelf(user) && <span>{this.getEvaluation(user, criteria).score}</span>}
                                            </td>)
                                        )}
                                    </tr>)
                                )}
                            </tbody>
                        </table>

                            {this.state.task.errors && Object.keys(this.state.task.errors).length > 0 && <article className="message is-danger">
                                <div className="message-body">
                                    <ul className="has-text-danger">
                                        {Object.keys(this.state.task.errors).map((item) =>
                                            <li>{this.state.task.errors[item].errorMessage} </li>
                                        )}
                                    </ul>
                                </div>
                            </article>}

                        <div className="control">
                                <SubmitButtonField
                                    label={I18n.get("Salvar")}
                                    onClick={this.submitForm.bind(this)}
                                    task={this.state.task}
                                />
                        </div>

                        </form>

                    </div>
                </div>
            </div>
        );
    }
}
