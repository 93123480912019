import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { Tabs } from "react-tabs";
import * as S from "./styles";

const TabsBP = ({ tabItens, isClient }) => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const [tabDefault, setTabDefault] = useState("");

  const tabDefaultClient = () => {
    if (tabDefault === "?meetings") {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  };

  const tabDefaultPartner = () => {
    if (tabDefault === "?invitations") {
      setTabIndex(1);
    } else if (tabDefault === "?meetings") {
      setTabIndex(2);
    } else {
      setTabIndex(0);
    }
  };

  React.useEffect(() => {
    if (isClient) {
      tabDefaultClient()
    } else {
      tabDefaultPartner()
    }
  }, [isClient, tabDefault]);

  React.useEffect(() => {
    setTabDefault(location?.search)
  }, [location]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
      <S.TabList>
        {tabItens &&
          tabItens.map((item) => {
            return (
              <S.Tab tabItens={tabItens.length}>
                {item.icon && (
                  <S.Icon>
                    <img src={item.icon} />
                  </S.Icon>
                )}
                <S.Label>{item.label}</S.Label>
              </S.Tab>
            );
          })}
      </S.TabList>

      {tabItens &&
        tabItens.map((item) => {
          return <S.TabPanel>{item.component}</S.TabPanel>;
        })}
    </Tabs>
  );
};

export default TabsBP;
