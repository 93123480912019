
const Config = {
    WEBSOCKET: 'wss://2a5uucpkt1.execute-api.us-east-1.amazonaws.com/prod',

    AWS_COGNITO_REGION: 'us-east-1',
    AWS_COGNITO_USERPOOL_ID: 'us-east-1_FmEkCPnqx',
    AWS_COGNITO_CLIENT_ID: '5b8ar5blib716auo6q16s1mtmo',

    API_BASE_URL: 'https://zlgutf660c.execute-api.us-east-1.amazonaws.com/Prod/api', // APP



    UPLOAD_URL: "https://8766cyjp3b.execute-api.us-east-1.amazonaws.com/prod/geturl",
    UPLOAD_URL2: "https://8766cyjp3b.execute-api.us-east-1.amazonaws.com/prod/geturl2",

    URL_PLATFORM: "https://app.bpool.co",

    applyClientDiscount: false,

    moduloEvento: true,
    moduloCriacao: true,
    moduloFaturamento: true,
    moduloDahs2: true,
    moduloBPitch: false,
    moduloChangeLanguage: true,

    moduloEventoLatam: false,
    moduloBPitchLatam: false,
    moduloDashEventLatam: false,

    moduloWhatif: false,
    moduloMasterSenior: false,
    moduloSuggestProject: true,
    moduloSuggestProjectProposal: true,
    moduloSuggestAsset: true,
    moduloDashEvent: false,
    moduloNewRegister: true,
    moduloNewRegisterClient: true,
    moduloFaqs: false,

    showChat: true,
    showMeet: true,

    moduloNewBpay: true,
};

export default Config;
