import React from 'react';

import { I18n } from 'aws-amplify';

const Card = (props) => {

    if (!props.bp || !props.bp.client) return null;

    const item = props.bp.client;

    return (
        <div className="card">
            <div className="card-content">
                <h3 className="title is-4">{I18n.get("Termo")} {item.delegateTermsSentDate ? "delegado" : ""} </h3>

                <div className="content">
                    {item.delegateTermsSentDate &&
                        <table className="table-bpool no-opacity">
                            <tbody>
                                <tr>
                                    <th>{I18n.get("E-mail")}</th>
                                    <th>{I18n.get("Aceito")}</th>
                                    <th>IP</th>
                                </tr>
                                <tr>
                                    <td>
                                        {item.delegateTerms}
                                    </td>
                                    <td>
                                        {item.delegateTermsUpdatedOn}
                                    </td>
                                    <td>
                                        {item.delegateTermsIP}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    }
                    {!item.delegateTermsSentDate &&
                        <table className="table-bpool no-opacity">
                            <tbody>
                                <tr>
                                    <th>{I18n.get("E-mail")}</th>
                                    <th>IP</th>
                                </tr>
                                <tr>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                        {item.termsIP}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    }

                </div>
            </div>
        </div>
    );
};

export default Card;
