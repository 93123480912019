import React from "react";
import iconCheck from "../../../../image/check.svg";
import iconCheckDisabled from "../../../../image/check-disabled.svg";

import * as S from "./styles";

export const TabsHorizontal = ({ items, urlsPrefix, tabActive, keysDisableds }) => {
  const disableLink = ({ key }) => {
    if (keysDisableds?.length) {
      return keysDisableds?.find(item => item === key) ? true : false;
    } else {
      return false;
    }
  };

  return (
    <S.Wrapper>
      {items?.length &&
        <ul>
          {items?.map((item) => (
            <li key={item?.key} className={tabActive === item?.key ? "active" : disableLink({key: item?.key}) ? "disabled" : ""}>
              <a
                href={urlsPrefix ? `${urlsPrefix}${item?.link}` : item?.link}
              >
                <S.LinkContent>
                  {tabActive === item?.key ? <img alt="icon check" src={iconCheck} /> : <img alt="icon check" src={iconCheckDisabled} />}
                  <p>{item?.label}</p>
                </S.LinkContent>
              </a>
            </li>
          ))}
        </ul>}
    </S.Wrapper>
  );
};
