import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col, message } from "antd";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";
import { urlsFull as urls } from "../../utils/stepsUrls";
import { toUpdateFlowStorageSteps } from "../../utils/flowToStorage";

import { AwardsForm } from "./form/AwardsForm";
import { ListAwards } from "./list/ListAwards";
import { CertificatesForm } from "./formCertificates/CertificatesForm";
import * as S from "./styles";

const MESSAGE_ERROR_GET_PAGE = "There was an error, try again.";
const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const PAGE_INDEX = 13;

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  const { getAwards, deleteAward } = useRegistrationFlowFull();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [listAwardsData, setListAwardsData] = useState([]);
  const [isEditing, setIsEditing] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  const loadAwards = async () => {
    setIsLoading(true);
    const response = await getAwards({
      admin: contextPage === "admin" ? true : false,
    });
    if (response?.success) {
      setListAwardsData(response?.data);
    } else {
      console.log("erro no get");
    }
    setIsLoading(false);
  };

  const handleEditItem = async (id) => {
    const find = listAwardsData?.find((item) => item?.id === id);
    setIsEditing(find ? { ...find } : {});
  };

  const handleDeleteItem = async (id) => {
    setIsDeleting(true);
    await deleteAward({
      admin: contextPage === "admin" ? true : false,
      id,
    });
    if (contextPage === "edit" || contextPage === "admin") {
      sendOk(true);
    }
    setIsDeleting(false);
    await loadAwards();
  };

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const handleNextPage = async () => {
    const url = urls[PAGE_INDEX + 1];

    if (url) {
      await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadAwards();
  }, []);

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("Awards")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get("For each specialty selected add at least 1 award.")}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        <AwardsForm
          contextPage={contextPage}
          partnerId={partnerId}
          refreshListAwards={loadAwards}
          isEditing={isEditing}
          clearEditing={() => setIsEditing({})}
          isMartecAdtech={false}
          sendOk={sendOk}
        />

        {isLoading ? (
          <Row>
            <Col xs={24}>
              <S.ContentIsLoading>
                <Loading
                  text={I18n.get("Loading informations...")}
                  sizeText={14}
                  sizeIcon={16}
                />
              </S.ContentIsLoading>
            </Col>
          </Row>
        ) : (
          <>
            <ListAwards
              data={listAwardsData}
              onEdit={handleEditItem}
              onDelete={handleDeleteItem}
              isDeleting={isDeleting}
            />
          </>
        )}

        <CertificatesForm />

        <Row style={{ marginTop: 30 }}>
          {contextPage === "full" ? (
            <>
              <Col xs={24} sm={12}>
                <ButtonBpool
                  text={I18n.get("Back")}
                  theme="secondary"
                  full
                  onClick={handleBackPage}
                />
              </Col>

              <Col
                xs={{ span: 24, offset: 0 }}
                sm={
                  contextPage === "edit"
                    ? { span: 12, offset: 12 }
                    : { span: 12, offset: 0 }
                }
              >
                <ButtonBpool
                  text={
                    contextPage === "edit" ? I18n.get("Next") : I18n.get("Next")
                  }
                  theme="primary"
                  full
                  loading={false}
                  onClick={handleNextPage}
                />
              </Col>
            </>
          ) : null}
        </Row>
      </Main>
    </>
  );
};
