import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";

import LoggedPage from "../../components/page-templates/fillForm-logged";
import { Link } from "react-router-dom";

import ContainerClient from "./components/container-client";
import Loading from "../../components/pages/loading";

import constants from "../../constants";
import SubMenuHeader from "../components/SubHeaderMenu";

import { CLIENT_API } from "../../actions/client/actions";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_client: (data) => {
      return dispatch(CLIENT_API.get());
    },
  };
};

class ClientReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.props.get_client();
  }

  render() {
    if (!this.props.client.clientId && this.props.auth.logged_user) {
      return <Loading />;
    }

    //TODO: Find better way... GAMBI!
    let menu = [...constants.MENU_REPORT_CLIENT];
    for (let key in menu) {
      menu[key].label = I18n.get(menu[key].label);
    }
    return (
      <div className="PrintPage">
        <LoggedPage isReport>
          <div className="headerX has-background-white project-definition-header">
            <div className="container">
              <div className="columns is-mobile">
                <div className="column is-5">
                  <p className="title">
                    <Link style={{ color: "black" }} to="/cliente/dashboard">
                      {I18n.get("Dashboard")}
                    </Link>
                    <br />
                  </p>
                </div>
                <div className="column is-7">
                  <SubMenuHeader
                    {...this.props}
                    itens={menu}
                    activeItem={2}
                    isSaving={this.props.client?.hasOwnPriceRef}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard2">
            <div
              className="container client-list-container"
              style={{ paddingTop: "180px" }}
            >
              <ContainerClient
                {...this.props}
                prefix="client"
                idGet={this.props.client.clientId}
              />
            </div>
          </div>
        </LoggedPage>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientReport);
