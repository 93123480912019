import React, { useState } from "react";
import BpayContext from "./BpayContext";
import { useBpay } from "../../../hooks/useBpay/useBpay.hook";

const BpayProvider = ({ children }) => {
  const [sharedSetupBpay, setSharedSetupBpay] = useState({
    isLoading: false,
    error: false,
    setup: {
      brandsProducts: [],
      listLanguages: [],
      listCurrencies: [],
      listCurrenciesDefault: [],
      culture: null,
      billingCurrencyCode: null,
      paymentDefaultTermDays: null,
    },
  });
  const [sharedStateBpay, setSharedStateBpay] = useState({
    isLoading: false,
    error: false,
    data: { status: null },
  });
  const { getSetupBpay, getBpayById } = useBpay();

  const fetchSetupBpay = async () => {
    setSharedSetupBpay((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const response = await getSetupBpay();
      const brands = response?.data?.brands?.map((item) => ({
        code: item?.code,
        label: item?.label,
      }));
      const languages = response?.data?.languages?.map((item) => ({
        code: item?.code,
        label: item?.label,
      }));
      const currencies = response?.data?.currencies?.map((item) => ({
        code: item?.culture,
        label: item?.symbol,
      }));

      const objContextSetup = {
        brandsProducts: brands,
        listLanguages: languages,
        listCurrenciesDefault: response?.data?.currencies,
        listCurrencies: currencies,
        culture: response?.data?.signupLocale,
        billingCurrencyCode: response?.data?.billingPaymentCurrency?.culture,
        billingPaymentCurrency: response?.data?.billingPaymentCurrency,
        paymentDefaultTermDays: response?.data?.paymentDefaultTermDays,
      };
      setSharedSetupBpay((prevState) => ({
        ...prevState,
        setup: objContextSetup,
        isLoading: false,
      }));
      return objContextSetup;
    } catch (error) {
      setSharedSetupBpay((prevState) => ({
        ...prevState,
        error: true,
        isLoading: false,
      }));
    }
  };

  const fetchDataById = async ({ id, setup, query }) => {
    if (id) {
      setSharedStateBpay((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getBpayById({ id, query });
        setSharedStateBpay((prevState) => ({
          ...prevState,
          data,
          isLoading: false,
        }));
      } catch (error) {
        setSharedStateBpay((prevState) => ({
          ...prevState,
          error: true,
          isLoading: false,
        }));
      }
    } else {
      setSharedStateBpay((prevState) => ({
        ...prevState,
        error: false,
        isLoading: false,
        data: {
          culture: setup?.culture,
          billingCurrencyCode: setup?.billingCurrencyCode,
          paymentDefaultTermDays: setup?.paymentDefaultTermDays,
          status: null,
        },
      }));
    }
  };

  const fetchDataBpay = async ({ id, newBpay, query }) => {
    if (newBpay) {
      setSharedStateBpay((prevState) => ({
        ...prevState,
        error: false,
        isLoading: false,
        data: { status: null },
      }));
    }

    if (
      sharedStateBpay?.data?.id !== id ||
      (sharedStateBpay?.data?.id === undefined && id === undefined)
    ) {
      const setup = await fetchSetupBpay();
      await fetchDataById({ id, setup, query });
    }
  };

  const fetchDataBpayUpdate = async ({ id, query }) => {
    const setup = sharedSetupBpay?.setup;
    await fetchDataById({ id, setup, query });
  };

  return (
    <BpayContext.Provider
      value={{
        sharedSetupBpay,
        sharedStateBpay,
        fetchDataBpay,
        fetchSetupBpay,
        fetchDataById,
        fetchDataBpayUpdate,
      }}
    >
      {children}
    </BpayContext.Provider>
  );
};

export default BpayProvider;
