import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import ClientEditForm from './client/_edit-form';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_clients: data => {
            dispatch(BP_API.get_clients(data));
        },
        get_client: id => {
            dispatch(BP_API.get_client(id));
        },
    };
};

class ClientEdit extends React.Component {

    constructor(props) {

        super();

        this.state = {
            clientId: props.match.params.clientId,
        };
    }

    componentDidMount() {

        this.props.get_client(this.state.clientId);
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li><NavLink to="/bpool/clientes" className="is-black">{I18n.get("Clientes")}</NavLink></li>
                        <li><NavLink to={`/bpool/clientes/${this.props.match.params.clientId}`} className="is-black">{this.props.bp.client && this.props.bp.client.name}</NavLink></li>
                        <li className="is-active"><a href="#">{I18n.get("Editar")}</a></li>
                    </ul>
                </nav>

                <div className="columns">

                    <div className="column is-6">

                        <ClientEditForm {...this.props} />
                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientEdit);

