import styled from 'styled-components';

export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const TitleSectionForm = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
  width: max-content;
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const ItemInfo = styled.div``;

export const ItemLabel = styled.div`
  font-weight: bold;
`;

export const ItemValue = styled.div``;

export const WrapperItem = styled.div`
  margin: 30px 0 0 0;
`;

export const ItemFormEmpty = styled.div`
  font-size: 14px;
`;

export const ContentItem = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const TitleCheckboxes = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const ContentDatePicker = styled.div`
  width: 100%;

  span {
    width: 100%;

    .ant-calendar-picker-input {
      height: 50px;
      border-radius: 10px !important;
      background: #fff !important;
      border: solid 1px #999999 !important;
    }
  }
`;
export const Checkboxes = styled.div`
  display: flex;
  width: 100%;
`;

export const CheckboxItem = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  label {
    margin: 0;
  }

  p {
    margin: 0 0 0 10px;
  }
`;
