import React, { PureComponent, Fragment } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import NumberFormat from "react-number-format";
import Highlighter from "react-highlight-words";

import {
  formatMoney2,
  formatMoneyInput,
  formatMoneyGraph,
  formatCurrency,
} from "../../../utils/helpers";
import {
  Table,
  Popconfirm,
  Button,
  Input,
  Checkbox,
  Tooltip,
  Icon,
  Tag,
} from "antd";

import { ProjectTypeNames, ProjectExtraTypeNames } from "../../../model";

const BRL = ({ amount, symbol, tSeparator, dSeparator }) => (
  <NumberFormat
    value={amount ?? 0}
    displayType={"text"}
    thousandSeparator={tSeparator ?? "."}
    decimalSeparator={dSeparator ?? ","}
    decimalScale={0}
    fixedDecimalScale={false}
  />
);

const Btns = styled.p`
  width: 50px;
  displa: block;
  a {
    margin-left: 10px;
  }
`;

const TableEdit = styled(Table)`
  .ant-table-thead > tr > th * {
    font-size: 11px;
  }
`;

class tableCourse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSector = (sectorId) => {
    const items =
      this.props.sectorsFlat &&
      this.props.sectorsFlat.filter((item) => item.code == sectorId);

    if (items && items.length) {
      return items[0].label;
    } else {
      return {};
    }
  };

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return "";

    return (
      this.props.bp.clientProjectStatusesMap[value] &&
      this.props.bp.clientProjectStatusesMap[value].label
    );
  }

  handleSelectPo = (item) => {
    this.props.getPos(item);
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 300, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small">
          Cancelar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  render() {
    const columns = [
      {
        title: I18n.get("Tipo Cobrança"),
        dataIndex: "billingType",
        key: "billingType",
        width: 100,
        render: (billingType, record) => <Tag>{billingType}</Tag>,
      },
      {
        title: I18n.get("Cliente"),
        dataIndex: "clientName",
        key: "clientName",
        //...this.getColumnSearchProps('clientName'),
        width: 150,
        render: (clientName, record) => (
          <div style={{ fontSize: "11px" }}>{clientName}</div>
        ),
      },
      {
        title: I18n.get("Parceiro"),
        dataIndex: "partnerName",
        key: "partnerName",
        //...this.getColumnSearchProps('partnerName'),
        width: 150,
        render: (partnerName, record) => (
          <div style={{ fontSize: "11px" }}>{partnerName}</div>
        ),
      },
      {
        title: I18n.get("SKU"),
        dataIndex: "sku",
        key: "sku",
        width: 100,
        //...this.getColumnSearchProps('sku'),
        render: (sku) => <div style={{ fontSize: "11px" }}>{sku}</div>,
      },
      {
        title: "Tipo Projeto",
        dataIndex: "projectType",
        key: "projectType",
        width: 100,
        render: (projectType, record) => <Tag>{projectType}</Tag>,
      },
      {
        title: I18n.get("Projeto"),
        dataIndex: "projectName",
        key: "projectName",
        width: 150,
        //...this.getColumnSearchProps('projectName'),
        render: (projectName, record) => (
          <div style={{ fontSize: "11px" }}>{projectName}</div>
        ),
      },
      {
        title: I18n.get("Extra"),
        dataIndex: "extraName",
        key: "extraName",
        width: 120,
        render: (extraName) => (
          <div style={{ fontSize: "11px" }}>{extraName}</div>
        ),
      },
      {
        title: I18n.get("Data"),
        dataIndex: "date",
        key: "date",
        width: 100,
        render: (date) => <div style={{ fontSize: "11px" }}>{date}</div>,
      },
      {
        title: I18n.get("Valor"),
        dataIndex: "billingTotal",
        key: "billingTotal",
        width: 100,
        render: (billingTotal) => (
          <div style={{ fontSize: "11px" }}>{formatCurrency(billingTotal)}</div>
        ),
      },
      {
        title: "Qtd NF",
        dataIndex: "invoicesUploaded",
        key: "invoicesUploaded",
        width: 80,
        render: (invoicesUploaded) => (
          <div style={{ fontSize: "11px" }}>{invoicesUploaded}</div>
        ),
      },
      {
        title: I18n.get("Bundle"),
        dataIndex: "bundle",
        key: "bundle",
        width: 100,
        render: (bundle, record) => {
          if (bundle) {
            return (
              <a href={bundle} target="_blank">
                <Icon type="file-pdf" />
              </a>
            );
          } else {
            return "---";
          }
        },
      },
      {
        title: I18n.get("#PO"),
        dataIndex: "projectPO",
        key: "projectPO",
        width: 200,
        render: (projectPO, record) => {
          return (
            <div>
              {!record.extraId && (
                <>
                  <NavLink
                    className="button is-small is-black is-outlined"
                    to={`/bpool/faturamento/${record.projectId}/po/${record.item.billingId}`}
                  >
                    {I18n.get("#PO")} {projectPO || "S/N"}
                  </NavLink>
                  <Checkbox
                    style={{ marginTop: "5px", marginLeft: "10px" }}
                    onChange={() => this.handleSelectPo(record.item)}
                    disabled={record.item.invoicesUploaded === 0}
                  />
                </>
              )}

              {record.extraId && (
                <>
                  <NavLink
                    className="button is-small is-black is-outlined"
                    to={`/bpool/faturamento/${record.projectId}/extra/${record.extraId}/po/${record.item.billingId}`}
                  >
                    {I18n.get("#PO")} {projectPO || "S/N"}
                  </NavLink>
                  <Checkbox
                    style={{ marginTop: "5px", marginLeft: "10px" }}
                    onChange={() => this.handleSelectPo(record.item)}
                    disabled={record.item.invoicesUploaded === 0}
                  />
                </>
              )}
            </div>
          );
        },
      },
    ];

    let dataTable =
      this.props.data &&
      this.props.data.map((item, index) => {
        return {
          key: index,
          billingType: item.billingType,
          billingTotal: item.billingTotal,
          sku: item.projectSKU,
          clientName: item.clientName,
          partnerName: item.partnerName,
          projectType: item.projectType,
          projectName: item.projectName,
          extraName: item.extraName,
          extraType: item.extraType,
          date: item.createdOnFmt,
          //invoiceAmount: item.invoiceAmount,
          projectId: item.projectId,
          extraId: item.extraId,
          projectPO: item.projectPO,
          bundle: item?.pdfBundle,
          //invoiceNumber: item.invoiceNumber,
          invoicesUploaded: item.invoicesUploaded,
          item: item,
        };
      });

    return (
      <TableEdit
        pagination={this.props.pagination ? true : false}
        columns={columns}
        dataSource={dataTable}
        locale={{ emptyText: I18n.get("Sem informação") }}
        scroll={{ x: 1200 }}
      />
    );
  }
}

export default tableCourse;
