import axios from "axios";
import { Auth } from "aws-amplify";

import Config from "../../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const get_tmarket = (data) => {
  return Auth.currentSession()
    .then((session) => {
      const token = session && session.idToken.jwtToken;

      var config = { headers: { Authorization: "Bearer " + token } };

      var queryString = Object.keys(data)
        .map((key) => key + "=" + data[key])
        .join("&");

      return axios
        .get(`${Config.API_BASE_URL}/Client/GetTMarket?${queryString}`, config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return false;
        });
    })
    .catch((error) => {
      // dispatch({ type: EXPIRED });
    });
};
