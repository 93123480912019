import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import LandingPage from '../../../components/page-templates/landingForLogin';
import styled from 'styled-components';

import PageSuccess from '../components/PageSuccess';


let h = window.innerHeight;
const MainFix = styled.div`
    height: calc(${h}px - 260px);
    min-height: 600px;
`

class PartnerRegisterSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <LandingPage>
                <MainFix>
                    <PageSuccess>
                        <Fragment>
                            <h2>Estamos quase lá!</h2>
                            <p>Enviamos um e-mail para continuar seu cadastro.</p>
                        </Fragment>
                    </PageSuccess>
                </MainFix>
            </LandingPage>
        );
    }
}

export default PartnerRegisterSuccess;
