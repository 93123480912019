import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_PROJECTS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_projects = ({ q, status, fat }) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

                const token = session && session.idToken.jwtToken;

                var config = { headers: { Authorization: 'Bearer ' + token } };

                if (!fat)
                    fat = false;

                return axios.get(`${Config.API_BASE_URL}/bp/projects?q=${q}&status=${status}&fat=${fat}`, config)
                            .then(response => {
                                dispatch({
                                    type: BP_PROJECTS_GET,
                                    payload: {
                                        projects: response.data
                                    }
                                });
                            })
                            .catch(error => {

                                throw (error);
                            });

        }).catch(error => {

            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_projects;
