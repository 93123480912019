import React from "react";
import { connect } from "react-redux";

import { PROJECT_API } from "../actions/project/actions";
import { CLIENT_WHATIF_API } from "../actions/client-whatif/actions";
import { PARTNER_API } from "../actions/partner/actions";

import { scrollToTop } from "../utils/ui";

export default function ProjectPartnerContainer(Component) {
  const mapStateToProps = (state) => {
    return Object.assign(state, state.project);
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      get_project: (id) => {
        dispatch(PROJECT_API.get(id));
      },
      get_assets: (data) => {
        dispatch(PROJECT_API.get_assets(data));
      },
      get_bundles: (data) => {
        dispatch(PROJECT_API.get_bundles(data));
      },
      get_partners: (data) => {
        dispatch(PROJECT_API.get_partners(data));
      },
      checkout2: (data) => {
        return dispatch(PROJECT_API.checkout2(data));
      },
      checkoutv2: (data) => {
        return PROJECT_API.checkoutv2(data);
      },
      checkout: (data) => {
        return PROJECT_API.checkout(data);
      },
      addProject: (data) => {
        dispatch(PROJECT_API.create(data));
      },
      reset: () => {
        dispatch(PROJECT_API.reset());
      },
      update_definitions: (data) => {
        return dispatch(PROJECT_API.update_definitions(data));
      },
      create_checkpoint: (data) => {
        dispatch(PROJECT_API.create_checkpoint(data));
      },
      delete_checkpoint: (data) => {
        dispatch(PROJECT_API.delete_checkpoint(data));
      },
      update_checkpoint: (data) => {
        dispatch(PROJECT_API.update_checkpoint(data));
      },
      upload_file: (data) => {
        return PROJECT_API.upload_file(data);
      },
      delete_file: (data) => {
        dispatch(PROJECT_API.delete_file(data));
      },
      update_briefing: (data) => {
        dispatch(PROJECT_API.update_briefing(data));
      },
      end: (data) => {
        dispatch(PROJECT_API.end(data));
      },
      save: (data) => {
        dispatch(PROJECT_API.update(data));
      },
      update_custom_event: (data) => {
        return dispatch(PROJECT_API.update_custom_event(data));
      },
      addEventQuotationAsset: (data) => {
        return dispatch(PROJECT_API.create_event_quotation_asset(data));
      },
      delEventQuotationAsset: (data) => {
        dispatch(PROJECT_API.delete_event_quotation_asset(data));
      },
      updateEventQuotationAsset: (data) => {
        dispatch(PROJECT_API.update_event_quotation_asset(data));
      },
      addEventQuotationItem: (data) => {
        dispatch(PROJECT_API.create_event_quotation_item(data));
      },
      delEventQuotationItem: (data) => {
        dispatch(PROJECT_API.delete_event_quotation_item(data));
      },
      updateEventQuotationTax: (data) => {
        dispatch(PROJECT_API.update_event_quotation_tax(data));
      },
      uploadEventQuotationfile: (data) => {
        return PROJECT_API.upload_event_quotation_file(data);
      },
      uploadEventQuotationfile2: (data) => {
        return PROJECT_API.upload_event_quotation_file2(data);
      },
      deleteEventQuotationFile: (data) => {
        dispatch(PROJECT_API.delete_event_quotation_file(data));
      },
      create_event_quotation_milestone: (data) => {
        return dispatch(PROJECT_API.create_event_quotation_milestone(data));
      },
      delete_event_quotation_milestone: (data) => {
        dispatch(PROJECT_API.delete_event_quotation_milestone(data));
      },
      updateEventQuotation: (data) => {
        return dispatch(PROJECT_API.update_event_quotation(data));
      },
      updateEventPartners: (data) => {
        return dispatch(PROJECT_API.update_event_partners(data));
      },
      updateEventQuotationStatus: (data) => {
        return dispatch(PROJECT_API.update_event_quotation_status(data));
      },
      updateBilling: (data) => {
        return dispatch(PROJECT_API.update_project_billing(data));
      },
      uploadBillingFile: (data) => {
        return PROJECT_API.upload_billing_file(data);
      },
      deleteBillingFile: (data) => {
        return dispatch(PROJECT_API.delete_billing_file(data));
      },
      cancel: (data) => {
        dispatch(PROJECT_API.cancel(data));
      },
      add_project_idea: (data) => {
        dispatch(CLIENT_WHATIF_API.add_project_idea(data));
      },
      get_clients: (id) => {
        dispatch(PARTNER_API.get_clients(id));
      },
      get_clients_v2: () => {
        dispatch(PARTNER_API.get_clients_v2());
      },
      get_brands_client: (id) => {
        dispatch(PARTNER_API.get_brands_client(id));
      },
      save_projetc_step1: (data) => {
        dispatch(PARTNER_API.save_projetc_step1(data));
      },
      save_temp_cart: (data) => {
        dispatch(PARTNER_API.save_temp_cart(data));
      },
      sendUserMessage: (data) => {
        return dispatch(PROJECT_API.send_user_message(data));
      },
      create_project_proposal: (data) => {
        return dispatch(PARTNER_API.create_project_proposal(data));
      },
      create_suggestion: (data) => {
        return dispatch(PARTNER_API.create_suggestion(data));
      },
      update_suggestion: (data) => {
        return dispatch(PARTNER_API.update_suggestion(data));
      },
      upload_filev2: (data) => {
        dispatch(PROJECT_API.upload_filev2(data));
      },
      delete_filev2: (data) => {
        dispatch(PROJECT_API.delete_filev2(data));
      },
      get_milistones: (projectId) => {
        dispatch(PROJECT_API.get_milistones(projectId));
      },
    };
  };

  class ProjectContext extends React.Component {
    constructor(props) {
      super(props);
    }



    componentDidMount() {
      scrollToTop();

      // var data = {
      //     clientId: "5caf91cd800fc20002cad0fb",
      //     // projectId: this.props.match.params.projectId
      //     projectId: "5de96069f624a300022464cd",
      //     isPartner: true
      // };

      // this.props.get_project(data);
      // this.props.get_assets(data);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    componentWillUnmount() {}

    render() {
      return <Component {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ProjectContext);
}
