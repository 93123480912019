import React from "react";

import * as S from "./styles";

export const BoxContent = ({ children }) => {
  return (
    <S.Wrapper>
      <S.Content>
        {children}
      </S.Content>
    </S.Wrapper>
  );
};
