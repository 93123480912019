import styled from "styled-components";
import { Modal as ModalAntd } from "antd";

export const Wrapper = styled.div`
  .drop-container {
    position: relative;
    display: flex;
    gap: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 15px 30px;
    border-radius: 10px;
    border: 2px dashed #d9d9d9;
    color: #444;
    cursor: pointer;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out;

    :hover {
      background: #f5f5f5;
      border-color: #1984ff;
    }
    :hover .drop-title {
      color: #000;
    }
  }

  .is-dragging {
    background: #f5f5f5;
    border-color: #1984ff;
  }

  .drop-title {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
    transition: color 0.2s ease-in-out;
  }

  .drop-extensions {
    color: #8f8f8f;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    text-align: center;
    transition: color 0.2s ease-in-out;
  }

  button {
    margin-top: 10px;
  }

  .disabled-input {
    opacity: 0.5 !important;
    cursor: not-allowed;
  }
`;

export const FileList = styled.ul`
  position: relative;
  background-color: transparent;
  margin-top: 10px;

  li {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    background-color: #f5f5f5;
    margin-top: 5px;

    p {
      margin: 0;
      font-weight: 400;
      cursor: pointer;

      span {
        font-size: 12px;
        color: #1984ff;
        margin-left: 5px;
      }
    }

    button {
      margin: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;

      i {
        color: #979797;
        top: 2px;
        position: relative;
      }
    }
  }
`;

export const ModalViewImage = styled(ModalAntd)`
  .ant-modal-header,
  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    padding: 50px 15px 15px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  @media (max-width: 1365px) {
    width: 80% !important;
  }

  @media (min-width: 1366px) {
    width: 1300px !important;
  }
`;

export const ListActions = styled.div`
  display: flex;
  button {
    width: 30px;
  }
`;

export const NameFile = styled.p`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
