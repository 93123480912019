import React, { Fragment } from 'react';

import { I18n } from 'aws-amplify';

import LogoButton from '../../form/logo-button';
import Config from '../../../config';

import noLogo from '../../../image/noLogo.png';
import noBanner from '../../../image/noBanner.png';
import icoChamada from '../../../image/icochamada.svg';
import icoZap from '../../../image/ico-whatsapp.png';


import VideoCallIcon from '../../../svg/icon-videocall.svg';
import ChatIcon from '../../../svg/icon-chat.svg';
import DiversityIcon from '../../../svg/ico-diversidade.svg';

import ReactTooltip from 'react-tooltip';


import ReactPlayer from 'react-player';
import { stringify } from 'postcss';

import { Button, Tooltip } from 'antd';


const Banner = React.memo((props) => {

    if (props.company && props.company.introVideo) {
        return (<div>
            <ReactPlayer url={props.company.introVideo}
                playing={true}
                loop={true}
                controls={false}
                muted={true}
                pip={false}
                width='100%'
                style={{ backgroundColor: '#000', width: '100%', maxHeight: '180px', margin: '0 auto' }} />
        </div>);
    }
    else if (props.company && props.company.introImage) {
        return (<p className={`image ${props.isMiniThumb == true ? "imageFix" : ""}`}>
            <img src={props.company.introImage} alt={props.company.name} />
        </p>);
    }
    else {

        return (<p className="image">
            <img src={noBanner} />
        </p>);

    }
});

const Logo = React.memo((props) => {

    if (props.company && props.company.logoImage) {
        return (<figure className="media-left teste">
            <p className="image is-64x64">
                <img src={props.company.logoImage} style={{ width: "64px" }} alt={props.company.name} />
            </p>
        </figure>);
    }
    else {

        return (<figure className="media-left">
            <p className="image is-64x64">
                <img src={noLogo} />
            </p>
        </figure>);

    }
});

class PartnerSmallCard extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    static defaultProps = {
        isMini: false
    }

    getTeamSize(item) {

        if (this.props.bp) {
            var item = this.props.bp.partnerTeamSizeRanges.find((x => x.code === item));
            return item.label;
        }
        else
            return "";
    }

    getUrl(s) {

        if (!s.match(/^[a-zA-Z]+:\/\//)) {
            s = 'http://' + s;
        }

        return s;

    };

    getUrlDisplay(s) {

        return s.replace("https://", "").replace("http://", "").replace("www.", "").replace(/\/$/, "");

    };

    renderReason(reasons) {

    }

    render() {

        const isPreview = this.props.isPreview || false;

        const { grade, openScorecard } = this.props;

        const Site = (props) => {

            if (props.company && props.company.website) {
                return (<div>
                    <a href={props.company.website} >{props.company.website}</a>
                </div>);
            }

            return (<span></span>);
        }

        var style = {
        };

        if (!this.props.isAdmin && this.props.isSelected && this.props.isSelected && !this.props.isSelectedPartner) {
            style = { border: '5px solid #2F7709' };
        }

        if (!this.props.isAdmin && !this.props.isSelected && !this.props.isSelectedPartner && !this.props.isPreview) {
            style = { border: '5px solid #F40C0C' };
        }


        if (this.props.isAdmin && this.props.naoSelecionado) {
            style = { border: '5px solid #F40C0C' };
        }

        if (this.props.isAdmin && this.props.naoAceitou) {
            style = { border: '5px solid #ffbf00' };
        }

        if (this.props.isAdmin && this.props.selecionada === this.props.partnerId) {
            style = { border: '5px solid #2F7709' };
        }



        style['maxWidth'] = '367px';

        if (isPreview) {
            style['marginLeft'] = 'auto';
            style['marginRight'] = 'auto';
        }

        return (
            <Fragment>
                <div className="card card-partner-bpool" style={style} >
                    <div className="card-content">
                        <article className="media card-top">
                            <Logo {...this.props} />

                            <div className="media-content">
                                <div className="content">
                                    <div className="media-card-content-top">
                                        <strong className="companyName">{this.props.company?.name}</strong> {this.props.isAdmin === true && <span class="tag is-warning">{this.props?.company && this.props?.company.tMarket && this.props?.company?.tMarket.join()}  
                                        
                                        
                                        </span>}
                                        {(this.props.company?.diversityLeadership?.length > 0 || this.props.company?.diversityShareHolders?.length > 0)  &&
                                            <span className="iconDiv" style={{margin:"-30px 10px 0" ,float:"right"}}>
                                                <img className="pull-right" src={DiversityIcon} width="60px" />
                                            </span>
                                        } 

                                        {this.props.company
                                            && this.props.company?.website && !this.props.noSite
                                            && <div><a target="_blank" className="urlsite" href={this.getUrl(this.props.company.website)}>
                                                {/* {this.getUrlDisplay(this.props.company.website)} */}
                                                Visitar o site
                                            </a></div>}
                                        
                                            {!this.props.isListPartner &&
                                                <p className="pcity">{this.props.company && this.props.company.city} - {this.props.company && this.props.company.state}</p>
                                            }
                                        {this.props.isChat &&
                                            <p className="menuIcons">
                                                {Config.showMeet &&
                                                    <a onClick={this.props.showScheduling}>
                                                        <Tooltip title={I18n.get('Chemistry Meeting')}>
                                                            <img src={VideoCallIcon} className="" />
                                                        </Tooltip>
                                                    </a>
                                                }
                                                {Config.showChat &&

                                                    <a href="#/cliente/projetos?tab=2">
                                                        <Tooltip title={I18n.get('Chat_3')}>
                                                            <img src={ChatIcon} />
                                                        </Tooltip>
                                                    </a>
                                                }
                                            </p>
                                        }
                                        {(this.props.isAdmin && this.props.specialisms && this.props.specialisms.includes("LIVE_MKT")) && <small className="tag is-info">{I18n.get("L.MKT")}</small>}

                                        {(Config.moduloBPitch && this.props.isBPitch) &&
                                            <a onClick={openScorecard}>
                                                {grade && <>
                                                    <small className="tag is-warning" style={{ marginTop: '0.25rem' }}>{I18n.get('Scorecard')}: {grade} </small>
                                                </>}
                                                {!grade && <>
                                                    <span style={{ fontSize: '80%' }}>{I18n.get('Ir para Scorecard')}</span>
                                                </>}
                                            </a>
                                        }

                                    </div>
                                </div>
                            </div>
                        </article>

                        <div className="bannerMidia">
                            {this.props.isAdmin !== true && <Banner {...this.props} />}
                        </div>

                        <br />

                        <nav className="level is-mobile">
                            <div className="level-left">
                                <div className="level-item">
                                    {/* <span className="icon is-small"><i className="fas fa-star"></i></span> &nbsp;
                            <span className="icon is-small"><i className="fas fa-star"></i></span> &nbsp;
                            <span className="icon is-small"><i className="fas fa-star"></i></span> &nbsp;
                            <span className="icon is-small"><i className="fas fa-star"></i></span> &nbsp;
                            <span className="icon is-small"><i className="fas fa-star"></i></span> */}
                                </div>
                            </div>
                            <div className="level-right">
                                <div className="level-item">
                                    {/* <span className="is-small">X Reviews</span> */}
                                </div>
                            </div>
                        </nav>

                        {this.props.isMini && <div>

                            <br />
                            <br />
                            <br />

                        </div>}

                        {!this.props.isMini && <div>



                            <nav className="level is-mobile">
                                <div className="level-right">
                                    <div className="level-item">
                                        {this.props.openChat && <>
                                            <a onClick={this.props.openChat} style={{ color: '#444444' }} >
                                                <span className="icon">
                                                    <i className="fas fa-lg fa-comments"></i>
                                                </span>
                                            </a>
                                        </>}
                                    </div>
                                </div>
                            </nav>

                            {!this.props.isListPartner &&
                                <>
                                    <p className="smallCardInfo">

                                        {this.props.email && this.props.email} <br />
                                        {this.props.company && this.props.company.country && this.props.company.country.prefix} {this.props.company && this.props.company.phoneNumber} | {" "}
                                        {this.props.company && this.props.company.commercialCountry && this.props.company.commercialCountry.prefix} {this.props.company && this.props.company.commercialPhoneNumber} <br />
                                    </p>

                                    <br />
                                    <br />
                                </>
                            }


                            {this.props.showDetails && <div className="has-text-centered">

                                <button onClick={this.props.showDetails} type="button" className="button bt-bpool black">{I18n.get("Saiba mais")}</button>

                            </div>}

                        </div>}

                    </div>
                </div>
                {!this.props.isMini && !isPreview && <footer className="card-footer footer-card-select">
                    <Button disabled={this.props.isDisabled} className="button bt-bpool" onClick={this.props.selectPartner} loading={this.props.task.busy}>
                        {I18n.get("Quero este parceiro")}
                    </Button>
                </footer>}
                {/* {this.props.isAdmin &&
                    <div className="card" style={style}>
                        <div className="hcard">
                            <p>
                                <strong>{I18n.get("Convites:")}</strong>
                                <span className="itemP">{I18n.get("Aceitos")} <b>{this.props.projectInvite && this.props.projectInvite.accepted ? this.props.projectInvite.accepted : "0"}</b></span>
                                <span className="itemP">{I18n.get("Neg.")} <b>{this.props.projectInvite && this.props.projectInvite.declined ? this.props.projectInvite.declined : "0"}</b></span>
                                <span className="itemP">{I18n.get("Aguard.")} <b>{this.props.projectInvite && this.props.projectInvite.waiting ? this.props.projectInvite.waiting : "0"}</b></span>
                                <span className="itemP">{I18n.get("Exp.")} <b>{this.props.projectInvite && this.props.projectInvite.expired ? this.props.projectInvite.expired : "0"}</b></span>
                                <span className="itemP">{I18n.get("Total")} <b>{this.props.projectInvite && this.props.projectInvite.total ? this.props.projectInvite.total : "0"}</b></span>
                            </p>
                            <br />
                            <p>
                                <strong>{I18n.get("Projetos:")}</strong>
                                <span className="itemP">{I18n.get("Selec")} <b>{this.props.projectStatus && this.props.projectStatus.partnerSelected ? this.props.projectStatus.partnerSelected : "0"}</b></span>
                                <span className="itemP">{I18n.get("Def.")} <b>{this.props.projectStatus && this.props.projectStatus.definitionSet ? this.props.projectStatus.definitionSet : "0"}</b></span>
                                <span className="itemP">{I18n.get("Andam.")} <b>{this.props.projectStatus && this.props.projectStatus.started ? this.props.projectStatus.started : "0"}</b></span>
                                <span className="itemP">{I18n.get("Fin.")} <b>{this.props.projectStatus && this.props.projectStatus.finished ? this.props.projectStatus.finished : "0"}</b></span>
                                <span className="itemP">{I18n.get("Canc..")} <b>{this.props.projectStatus && this.props.projectStatus.canceled ? this.props.projectStatus.canceled : "0"}</b></span>
                                <span className="itemP">{I18n.get("Total")} <b>{this.props.projectStatus && this.props.projectStatus.accepted ? this.props.projectStatus.accepted : "0"}</b></span>
                            </p>
                        </div>
                    </div>
                } */}
            </Fragment>
        );
    }
}

export default PartnerSmallCard;
