import styled from "styled-components";

export const Wrapper = styled.div``;

export const ContentBpay = styled.div`
  display: grid;
  grid-template-columns: 1fr 270px;
  grid-column-gap: 15px;
`;

export const ButtonsPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px 285px 30px 0;

  button {
    margin: 0 15px;

    :last-of-type {
      margin-right: 0px;
    }
  }
`;
