import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { CLIENT_BRAND_DELETE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_client_brand_delete = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        const dataSend = {
            brandId: data.brandId,
            productId: data.productId,
        }

        return axios.put(`${Config.API_BASE_URL}/client/brand/delete`, dataSend, config)
            .then(response => {

                dispatch({
                    type: CLIENT_BRAND_DELETE,
                    payload: response.data,
                    errorsDelete: {},
                    errors: {}
                });

            })
            .catch(error => {

                dispatch({
                    type: CLIENT_BRAND_DELETE,
                    payload: {
                        errorsDelete: error.response.data
                    }
                });
            });
}).catch(error => { 
//  dispatch({ type: EXPIRED }); 
 }); 
 };
}

export default api_client_brand_delete;