import React, { useState, useEffect } from "react";

import * as S from "./styles";

const labelHtml = ({ req, label }) => {
  if (req) {
    return (
      <>
        <span className="required-input">*</span>
        {label}
      </>
    );
  } else {
    return label;
  }
};

export const Textarea = ({
  id,
  label,
  rows,
  maxLength,
  counterChar,
  defaultValue,
  onChange,
  required,
  error,
  clearError,
  disabled,
  loading,
}) => {
  const [countChar, setCountChar] = useState(0);

  useEffect(() => {
    if (defaultValue?.length) {
      const count = defaultValue?.length;
      setCountChar(count);
    }
  }, [defaultValue]);

  const changeInput = async (valueInternal) => {
    if (onChange) {
      onChange(valueInternal);
      if (valueInternal !== defaultValue) {
        if (clearError) {
          clearError();
        }
      }
    }
  };

  return (
    <>
      <S.Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-textarea",
          width: "100%",
        }}
      >
        <S.InputGroup>
          <textarea
            className={error ? "input-error" : ""}
            name={id}
            rows={rows}
            maxLength={maxLength}
            onChange={(e) => changeInput(e.target.value)}
            defaultValue={defaultValue}
            disabled={disabled}
            placeholder={label}
          />
          <label htmlFor={id}>
            {labelHtml({ req: required, label: label })}
          </label>
          <S.ErrorAndCounter>
            <>
              {error ? <S.InfoError>{error}</S.InfoError> : null}
              {counterChar ? (
                <S.Counter>{`${countChar}/${
                  maxLength ? maxLength : "---"
                }`}</S.Counter>
              ) : null}
            </>
          </S.ErrorAndCounter>
        </S.InputGroup>
      </S.Skeleton>
    </>
  );
};
