import React, { Component, Fragment } from 'react';

import { I18n } from 'aws-amplify';

import { Tag } from 'antd';
import { CardBorder } from '../../../components/Cards';
import styled from 'styled-components';
import { formatCurrency, formatMoney2 } from '../../../../utils/helpers'

const MainCard = styled.div`
margin-bottom: 3px;
    .BoxAsset {
        padding: 20px;
        color: #353535;

        .bt-bpool {
            margin-left: 10px;
            position: relative;
            top: -5px;
        }

        .Line {
            display: block;
            border-bottom: solid 1px #eaeaea;
            margin: 10px 0;
        }

        h5 {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;

            span {
                float: right;
                font-size: 12px;
            }
            .TagAsset {

                margin-right: 0;
                margin-left: 15px;
            }
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        .listTag i:last-child  {
            display: none;
        }

        .ContentAsset {
            overflow: hidden;
            max-height: 0px;
            transition: max-height 0.4s ease;
            margin-top: 10px;

            &.open {
                max-height: 500px;
            }
        }

        .Readmore {
            margin-top: 5px;
            overflow: hidden;

            a {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                float: right;
                border: solid 1px #000;
                padding: 3px 10px;
                transition: all 0!important;

                &:hover {
                    background: #000;
                    color: #fff;
                }
            }
        }
    }
`

class CardAsset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toogle: false
        }
    }

    getValue = (arr, value) => {
        if (!this.props[arr]) return '';
        const item = this.props[arr].find(el => el.code == value);
        return item.label;
    }

    render() {

        const {
            title,
            price,
            description,
            complexity,
            assetTypes,
            assetSpecialisms,
            durationDays,
            status

        } = this.props.asset;


        return (
            <MainCard>
                <CardBorder
                    Bcolor=""
                    BGcolor={this.props.bg || "#f7f7f7"}

                >
                    <div className="BoxAsset">
                        <h5>{title}  <span>{I18n.get("Status:")} {" "}<Tag className="button bt-bpool mini" color={this.props.statusColor || "#434343"}>{this.props.status}</Tag></span></h5>
                        <p><strong>{I18n.get("Preço:")}</strong> {formatMoney2(price)}</p>


                        <div className={`ContentAsset ${this.state.toggle ? 'open' : ''}`}>
                            <p><strong>{I18n.get("Descrição")}</strong></p>
                            <p>{description}</p>
                            <span className="Line" />
                            <p>
                                <strong>{I18n.get("Complexidade do asset:")}</strong>
                                {this.getValue("assetComplexity", complexity)}
                            </p>

                            <span className="Line" />
                            <p className="listTag">
                                <strong>{I18n.get("Especialidades:")}</strong>
                                {assetSpecialisms && assetSpecialisms.map((item, index) => {
                                    return (<Fragment><span key={index}>{this.getValue("assetSpecialisms", item)}</span><i>,</i> </Fragment>)
                                })}
                            </p>

                            <span className="Line" />
                            <p className="listTag">
                                <strong>{I18n.get("Tipo:")}</strong>
                                {assetTypes && assetTypes.map((item, index) => {
                                    return (<Fragment><span key={index}>{this.getValue("assetTypes", item)}</span><i>,</i> </Fragment>)
                                })}
                            </p>

                            <span className="Line" />
                            <p><strong>{I18n.get("Prazo:")}</strong> {durationDays} {I18n.get("dias")}</p>
                        </div>

                        <p className=""><a onClick={() => this.setState({ toggle: !this.state.toggle })} className="button bt-bpool bsmall pull-right">{I18n.get("Ver")} {this.state.toggle ? 'Menos' : 'Mais'}</a></p>
                    </div>
                </CardBorder>
            </MainCard>
        );
    }
}

export default CardAsset;
