import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";
import { v4 as uuid } from "uuid";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useRegistrationFlow = () => {
  const getPartnerIdUrl = async () => {
    const href = window.location.href;
    const hrefSplited = href?.split("/");
    const id = hrefSplited[hrefSplited.length - 1];

    return id;
  };

  const verifyCnpj = async (value) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/PartnerPreRegistration/company-already-registered/${value.number}/${value.locale}`
    );

    return data;
  };

  const registry = async (form) => {
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/PartnerPreRegistration/application`,
      form
    );

    return data;
  };

  const resendMail = async ({ registrationId, email }) => {
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/PartnerPreRegistration/email-resend`,
      registrationId ? { registrationId, email } : { email }
    );

    return data;
  };

  const getStatusBarPreApplication = async () => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/PartnerPreRegistration/status-bar-registration`
    );
    return data;
  };

  const getStatusBarPre = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/status-bar-registration`,
      config
    );
    return data;
  };

  const whatStatus = (status) => {
    if (status === 1) {
      return "process";
    } else if (status === 2) {
      return "error";
    } else if (status === 3) {
      return "wait";
    } else if (status === 4) {
      return "finish";
    }
  };

  const StatusBarPreRegistration = async () => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/PartnerPreRegistration/status-bar-registration`
    );

    if (data.success) {
      const dataSteps = [];

      data.data.statusBarRegistration.map((item) =>
        dataSteps.push({
          key: item?.step - 1,
          title: item?.title,
          description: item?.description,
          status: whatStatus(item.status),
        })
      );

      return dataSteps;
    } else {
      return data;
    }
  };

  const StatusBarRegistrationFlow = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/status-bar-registration`,
      config
    );

    if (data.success) {
      const dataSteps = [];

      data.data.statusBarRegistration.map((item) =>
        dataSteps.push({
          key: item?.step - 1,
          title: item?.title,
          description: item?.description,
          status: whatStatus(item.status),
        })
      );

      return dataSteps;
    } else {
      return data;
    }
  };

  const checkIsCompleted = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/status-bar-registration`,
      config
    );

    if (data.success) {
      return data.data.isCompleted;
    } else {
      return data.data.isCompleted;
    }
  };

  const updateAboutTheCompany = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };
    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Partner/about-the-company`,
      form,
      config
    );

    if (data.errors) {
      const errors = Object.keys(data.errors).map((item) => {
        const errorItem = data.errors[item];
        const fieldFormatted =
          errorItem.field.charAt(0).toLowerCase() + errorItem.field.slice(1);
        return {
          ...errorItem,
          field: fieldFormatted,
        };
      });

      return {
        ...data,
        errors,
      };
    } else {
      return data;
    }
  };

  const getAboutTheCompany = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/about-the-company`,
      config
    );

    return data;
  };

  const getDiversities = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/about-the-company/diversity`,
      config
    );

    return data;
  };

  const getDiversityAndInclusionSetup = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/diversity/setup`
        : `${Config.API_BASE_URL}/v2/Partner/diversity/setup`,
      config
    );

    return data;
  };

  const getDiversityAndInclusion = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/diversity`
        : `${Config.API_BASE_URL}/v2/Partner/diversity`,
      config
    );

    return data;
  };

  const putDiversityAndInclusion = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/diversity`
        : `${Config.API_BASE_URL}/v2/Partner/diversity`,
      form,
      config
    );

    return data;
  };

  const verifyPreRegistryToken = async (token) => {
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/PartnerPreRegistration/email-confirm`,
      { verificationToken: token }
    );

    return data;
  };

  const getIndustry = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/industries`
        : `${Config.API_BASE_URL}/v2/Partner/industries`,
      config
    );

    return data;
  };

  const updateIndustry = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/industries`
        : `${Config.API_BASE_URL}/v2/Partner/industries`,
      form,
      config
    );

    return data;
  };

  const getSpecialityProofGet = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/client-profile/type`,
      config
    );

    return data;
  };

  const getSpecialityProofType = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/client-profile`,
      config
    );

    return data;
  };

  const setSpecialityProofType = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Partner/client-profile-type`,
      form,
      config
    );

    return data;
  };

  const createReferral = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/Partner/reference`,
      form,
      config
    );

    return data;
  };

  const updateReferral = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Partner/reference`,
      form,
      config
    );

    return data;
  };

  const getListReferrals = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/reference`,
      config
    );

    return data;
  };

  const deleteReferre = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/Partner/reference`,
      {
        data: form,
        ...config,
      }
    );

    return data;
  };

  const getBusinessType = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/business-type`
        : `${Config.API_BASE_URL}/v2/Partner/business-type`,
      config
    );

    return data;
  };

  const updateBusinessType = async ({ admin, partnerId, dataSend }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/business-type`
        : `${Config.API_BASE_URL}/v2/Partner/business-type`,
      { ...dataSend },
      config
    );

    if (data.errors) {
      const errors = Object.keys(data.errors).map((item) => {
        const errorItem = data.errors[item];
        const fieldFormatted =
          errorItem.field.charAt(0).toLowerCase() + errorItem.field.slice(1);
        return {
          ...errorItem,
          field: fieldFormatted,
        };
      });

      return {
        ...data,
        errors,
      };
    } else {
      return data;
    }
  };

  const getBusinessTypeCases = async ({ partnerId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Admin/Partner/${partnerId}/business-type-cases`,
      config
    );

    return data;
  };

  const getSpecialityProofAndEndersement = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data: dataCases } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/speciality-proof-endorsement-list`
        : `${Config.API_BASE_URL}/v2/Partner/speciality-proof-endorsement-list`,
      config
    );

    const casesTemp = dataCases?.data?.map((itemCase) => {
      const arraysCases = itemCase?.cases?.map((item) => {
        const id = uuid();

        return {
          ...item,
          id,
          codeTab: itemCase.code,
        };
      });

      return arraysCases;
    });

    const joinCases = casesTemp.flat();

    const awardsTemp = dataCases?.data?.map((itemAward) => {
      const arraysAwards = itemAward?.awards?.map((item) => {
        const id = uuid();

        return {
          ...item,
          id,
          codeTab: itemAward.code,
        };
      });

      return arraysAwards;
    });

    const joinAwards = awardsTemp.flat();

    const filterCaseByCode = ({ cases, code }) => {
      const casesFiltered = cases?.filter((item) => item?.codeTab === code);
      return casesFiltered;
    };

    const filterAwardByCode = ({ awards, code }) => {
      const awardsFiltered = awards?.filter((item) => item?.codeTab === code);
      return awardsFiltered;
    };

    const { data: dataPage } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/speciality-proof-endorsement`
        : `${Config.API_BASE_URL}/v2/Partner/speciality-proof-endorsement`,
      config
    );

    const dataWithCases = dataPage?.data.map((itemData) => ({
      ...itemData,
      cases: filterCaseByCode({ cases: joinCases, code: itemData?.code }),
      awards: filterAwardByCode({ awards: joinAwards, code: itemData?.code }),
    }));

    const data = {
      ...dataPage,
      data: dataWithCases,
    };

    return data;
  };

  const postSpecialityProofAndEndersement = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/speciality-proof-endorsement`
        : `${Config.API_BASE_URL}/v2/Partner/speciality-proof-endorsement`,
      form,
      config
    );

    return data;
  };

  const finishPreRegister = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Partner/pre-register-finish`,
      form,
      config
    );

    return data;
  };

  return {
    verifyCnpj,
    registry,
    resendMail,
    getStatusBarPreApplication,
    getStatusBarPre,
    StatusBarPreRegistration,
    StatusBarRegistrationFlow,
    updateAboutTheCompany,
    putDiversityAndInclusion,
    verifyPreRegistryToken,
    getAboutTheCompany,
    getDiversityAndInclusionSetup,
    getDiversityAndInclusion,
    getDiversities,
    getIndustry,
    updateIndustry,
    getBusinessType,
    updateBusinessType,
    getBusinessTypeCases,
    getSpecialityProofGet,
    createReferral,
    getListReferrals,
    deleteReferre,
    updateReferral,
    getSpecialityProofAndEndersement,
    postSpecialityProofAndEndersement,
    checkIsCompleted,
    finishPreRegister,
    setSpecialityProofType,
    getSpecialityProofType,
  };
};
