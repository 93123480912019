import React from 'react';

import { I18n } from 'aws-amplify';

import constants from '../../constants';

import CheckboxField from '../form/checkbox-field';

export default class CasesList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            partnerId: props.partnerId,
            leaderId: props.leaderId,
            companyCases: props.companyCases,
            cases: props.cases
        };

        this.delCase = this.onDelCase.bind(this);
    }

    onDelCase(item, e) {
        e.preventDefault();

        const data = Object.assign({}, this.state, { id: item.id });

        this.props.delCase(data);
    }

    getCases() {
        return this.props.companyCases || [];
    }

    onChangeTeamCase(item, e) {

        const value = e.target.checked;

        const data = Object.assign({}, item, { leaderId: this.props.leaderId });

        if (value) {
            this.props.addCase(data);
        }
        else {
            this.props.delCase(data);
        }
    }

    isChecked(caseId) {

        if (!this.props.cases) return false;

        const items = this.props.cases.filter((item) => item.type == 1 && item.id == caseId);

        return items && items.length;
    }

    getSector(sectorId) {

        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }

    render() {

        const cases = this.getCases();

        return (
            <table className="table is-fullwidth">
                <tbody>
                    <tr>
                        <th>{I18n.get("Marca")}</th>
                        <th className="is-not-mobile">{I18n.get("Produto")}</th>
                        <th className="is-not-mobile">{I18n.get("Categoria")}</th>
                        <th className="is-not-mobile">{I18n.get("Ano")}</th>
                        <th>
                            &nbsp;
                        </th>
                    </tr>
                    {cases.map((item, index) => 
                        <tr key={index}>
                            <td>
                                {item.brand}
                            </td>
                            <td className="is-not-mobile">
                                {item.product}
                            </td>
                            <td className="is-not-mobile">
                                {/*item.category*/}
                                {this.getSector(item.category).label}
                            </td>
                            <td className="is-not-mobile">
                                {item.year}
                            </td> 
                            <td className="has-text-centered">  
                                <div className="checkmark-grey">
                                    <CheckboxField
                                        label={I18n.get("Sim")}
                                        value={true}
                                        checked={this.isChecked(item.id)}
                                        onChange={this.onChangeTeamCase.bind(this, item)}
                                    />
                                </div>
                            </td>
                        </tr>
                    )
                    }
                </tbody></table>
        );
    }
}