import styled from 'styled-components';

export const WrapperButtonsSelects = styled.div`
  margin: 30px 0 60px 0;

  ul {
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const ButtonSub = styled.button`
  cursor: pointer;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid;
  border-color: #2f2f2f;
  border-radius: 10px;
  padding: 8px 20px;

  background-color: ${(props) => (props.active ? '#1883ff' : '#ffffff')};
  border-color: ${(props) => (props.active ? '#1883ff' : '#2f2f2f')};

  span {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: ${(props) => (props.active ? '#ffffff' : '#2f2f2f')};
  }
`;

export const WrapperCaseForm = styled.div`
  border: 1px solid;
  border-color: #e9e9e9;
`;

export const Title = styled.div`
  padding: 15px;
  background-color: #e9e9e9;
  margin-bottom: 15px;

  span {
    font-weight: bold;
    font-size: 14px;
  }
`;

export const ContentForm = styled.div`
  padding-bottom: 15px;

  .select-industries-dropdown {
    margin-bottom: 30px;

    .ant-select-selection {
      background: #fff;
      border: 1px solid #999999;
      height: 50px;
      border-radius: 10px !important;

      .ant-select-selection__rendered {
        height: 100%;
        display: flex;
        align-items: center;
      }

      span {
        i {
          top: 16px;
        }
      }
    }
  }

  .ant-calendar-picker {
    width: 100%;
    height: 50px;

    div {
      height: 100%;

      input {
        padding: 0 15px;
        height: 100%;
        border: 1px solid #999999 !important;
        background: #fff !important;
        border-radius: 10px !important;
      }
    }
  }
`;

export const WrapperItemCaseForm = styled.div`
  margin: 30px 0 30px 0;
`;

export const ItemCaseFormEmpty = styled.div`
  font-size: 14px;
`;

export const ItemCaseLabel = styled.div`
  font-weight: bold;
`;

export const ItemCaseValue = styled.div``;

export const ContentItemCase = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const ItemCase = styled.div``;

export const CancelEditCase = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: flex-end;

  button {
    background: transparent;
    font-size: 14px;
    color: #999999;
    margin: 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid #999999;
    cursor: pointer;
  }
`;
