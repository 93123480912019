import React from 'react';

import { I18n } from 'aws-amplify';

const NakedModal = (props) => {

    const className = props.isOpen ? 'is-active' : '';

    const contentStyle = props.wide ? { width: '960px', maxWidth: '960px' } : {};

    return (
        <div className={'modal ' + className}>
            <div className="modal-background"></div>
            <div className="modal-content" style={contentStyle}>
                {props.children}
            </div>
            {props.onCloseModal &&
                <button onClick={props.onCloseModal} class="modal-close is-large" aria-label="close"></button>
            }
        </div>
    )
};

export default NakedModal;