import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

//import LandingPage from '../page-templates/landing';
import FillForm from '../page-templates/fillForm';


import CompanyForm from './company-form';

import Steps from './steps';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class PartnerSignupStep2 extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }

    scrollToTop() {
        const scrollDuration = 600;
        const scrollHeight = window.scrollY;
        const scrollStep = Math.PI / (scrollDuration / 15);
        const cosParameter = scrollHeight / 2;
        let scrollCount = 0;
        let scrollMargin;
        let scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                scrollCount = scrollCount + 1;  
                scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                window.scrollTo(0, (scrollHeight - scrollMargin));
            } else {
                clearInterval(scrollInterval); 
            }
        }, 15);
    }

    render() {

        return (
            <FillForm>
                <div className="hero-body has-background-white">
                    <div className="container">
                        <div className="column is-12">
                            <Steps active={2} />
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-2 content-client">

                    <div className="container client-container-2">

                        <div className="column is-4 is-offset-4">
                            <h1 className="title">
                                <span>2.</span>{I18n.get("Sobre a Empresa")}</h1>
                            <CompanyForm {...this.props} />      
                            <br />
                            <div className="field is-grouped is-grouped-centered">
                                <p className="control is-expanded" onClick={this.scrollToTop}>
                                    <Link to="/cliente/passo-1" className="button is-black is-outlined is-fullwidth previous">{I18n.get("Voltar")}</Link>
                                </p>
                                <p className="control is-expanded" onClick={this.scrollToTop}>
                                    <Link to="/cliente/passo-3" className="button is-black is-fullwidth next">{I18n.get("PRÓXIMO [ 3 de 6 ]")}</Link>
                                </p>
                            </div>
                        </div>
                    </div>
            </div>


         </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSignupStep2);
