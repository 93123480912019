import axios from "axios";
import COUNTRY_CODES from "./countryCodes.json";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const orgnizeInfosCep = (data) => {
  if (data) {
    const objCep = {
      addressLine1: `${data?.logradouro}, Nº , ${data?.bairro}`,
      state: data?.uf,
      city: data?.localidade,
      country: COUNTRY_CODES.find((i) => i.code == "BRA"),
    };

    return objCep;
  } else {
    return {};
  }
};

export const useCep = () => {
  const getDataFromCep = async (cep) => {
    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

    return { data, cepInfos: orgnizeInfosCep(data) };
  };

  return {
    getDataFromCep,
  };
};
