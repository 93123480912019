import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import { PARTNER_API } from '../../actions/partner/actions';

import CheckboxField from '../form/checkbox-field';

import MarketExpertiseCmp from './_expertise-cmp';
import NewCompany from './_new_company';
import LiveMarketingIncentive from './live-marketing-incentive';
import LiveMarketingBrandExperience from './live-marketing-brand-experience';
import LiveMarketingEvents from './live-marketing-events';
import LiveMarketingLabor from './live-marketing-labor';
import LiveMarketingShopper from './live-marketing-shopper';

import TooltipField from '../form/tooltip-field';

//import { Tabs } from 'antd';

import Config from '../../config';

//const { TabPane } = Tabs;

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp, marketExpertise: { errors: {} }, }, state.partner);
};

const mapDispatchToProps = dispatch => {
    return {
        addMarketExpertise: data => {
            dispatch(PARTNER_API.create_expertise(data));
        },
        delMarketExpertise: data => {
            dispatch(PARTNER_API.delete_expertise(data));
        },
        save: data => {
            dispatch(PARTNER_API.update_specialism(data));
        },
        new_company: data => {
            dispatch(PARTNER_API.new_company(data));
        }
    };
};

class SpecialismForm extends React.Component {
    constructor(props) {
        super();

        this.state = {
            form: {
                specialisms: props.specialisms || [],
                keyServices: props.keyServices || [],
                marketExpertises: props.marketExpertises || [],
                eventLiveMarketing: props.eventLiveMarketing || [],
                eventLiveMarketingBrandExperience: props.eventLiveMarketingBrandExperience || [],
                eventLiveMarketingBrandIncentive: props.eventLiveMarketingBrandIncentive || [],
                eventLiveMarketingEvents: props.eventLiveMarketingEvents || [],
                eventLiveMarketingLabor: props.eventLiveMarketingLabor || [],
                eventLiveMarketingShopper: props.eventLiveMarketingShopper || [],
                eventCat: props.eventCat || [],
                eventSub: props.eventSub || [],
                isNewCompany: props.isNewCompany || false
            },
            task: {},
        };

        this.submitForm = this.submitForm.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};



        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {
                if (FormUtils.isFormField(nextState.form[item])) {

                    nextState.form[item].error = this.props.errors[item];

                    if ('checked' in nextState.form[item]) {
                        nextState.form[item].checked = this.props[item];
                    }
                    else {
                        nextState.form[item].value = this.props[item];
                    }
                }
                else {
                    nextState.form[item] = this.props[item];
                }
            });

            this.setState(nextState);
        }
    }

    onChangeSpecialism(item, e) {

        const checked = e.target.checked;

        if (checked && this.state.form.specialisms && this.state.form.specialisms.length >= 4) return;

        var specialisms = checked ? [...this.state.form.specialisms, item.code] : this.state.form.specialisms.filter((v) => v != item.code);

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.specialisms = specialisms

            return nextState;

        }, function () {
            this.props.save(this.getData());
        });
    }

    onChangeLive(item, e) {

        let newlist = []

        if (this.state.form.eventLiveMarketing.indexOf(item) > -1) {
            newlist = this.state.form.eventLiveMarketing.filter(e => e !== item)
        } else {
            this.state.form.eventLiveMarketing.push(item);
            newlist = this.state.form.eventLiveMarketing;
        }


        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.eventLiveMarketing = newlist;

            return nextState;

        }, function () {
            this.props.save(this.getData());
        });

    }

    onSave(e) {
        if (e) e.preventDefault();

        if (this.propsisAdmin) return;

        this.props.save(this.state);
    }

    getData() {

        var data = {
            partnerId: this.props.partnerId,
        };

        Object.keys(this.state.form).forEach((item) => {

            if (FormUtils.isFormField(this.state.form[item])) {

                data[item] = this.state.form[item].value;
            }
            else {
                data[item] = this.state.form[item];
            }
        });


        return data;
    }

    submitForm(e) {
        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    render() {

        const isEvent = (item) => item.code.substring(0, 3) === 'EV_';

        const specsSorted = this.props.bp.specialisms && this.props.bp.specialisms.filter((item) => !isEvent(item))
            .sort((a, b) => (a.label > b.label) - (a.label < b.label));

        const specEvents = this.props.bp.specialisms && this.props.bp.specialisms.filter((item) => isEvent(item))
            .sort((a, b) => (a.label > b.label) - (a.label < b.label));

        const { moduloEvento } = Config;


        return (
            <form onSubmit={this.submitForm}>
                <h1 className="title-bpool medium">{I18n.get("Classifique sua empresa em até 4 especialidades da lista abaixo")}</h1>

                <div className="columns">

                    {moduloEvento === false && <>

                        <div className="column">

                            {specsSorted && specsSorted.map((item, index) =>
                                <CheckboxField
                                    key={index}
                                    label={item.label}
                                    value={item.code}
                                    checked={this.state.form.specialisms && this.state.form.specialisms.indexOf(item.code) > -1}
                                    onChange={this.onChangeSpecialism.bind(this, item)}
                                    tooltip={item.description}
                                    fieldStyle={{ marginBottom: '-1rem' }}
                                />
                            )}
                        </div>

                    </>}

                    {moduloEvento === true && <>

                        <div className="column is-12">

                            {/* <p className="is-size-4">Criativo</p> */}

                            {specsSorted && specsSorted.map((item, index) =>
                                <CheckboxField
                                    key={index}
                                    label={item.label}
                                    value={item.code}
                                    checked={this.state.form.specialisms && this.state.form.specialisms.indexOf(item.code) > -1}
                                    onChange={this.onChangeSpecialism.bind(this, item)}
                                    tooltip={item.description}
                                    fieldStyle={{ marginBottom: '-1rem' }}
                                />
                            )}
                        </div>

                        {/* <div className="column is-6">

                        <p className="is-size-4">Evento</p>

                        {specEvents && specEvents.map((item, index) =>
                                <CheckboxField
                                    key={index}
                                    label={item.label}
                                    value={item.code}
                                    checked={this.state.form.specialisms.indexOf(item.code) > -1}
                                    onChange={this.onChangeSpecialism.bind(this, item)}
                                    tooltip={item.description}
                                    fieldStyle={{marginBottom: '-1rem'}}
                                />
                            )}
                        </div> */}

                    </>}

                </div>


                {this.state.form.specialisms && this.state.form.specialisms.indexOf("LIVE_MKT") > -1 &&
                    <div>
                        <h1 className="subtitle no-margin">{I18n.get("Opções para Live Marketing")}</h1>

                        <div className="columns">
                            <div className="column is-12">
                                <CheckboxField
                                    label={I18n.get("Experiência de Marca")}
                                    value="Experiência de Marca"
                                    checked={this.state.form.eventLiveMarketing.indexOf("Experiência de Marca") > -1 ? true : false}
                                    onChange={this.onChangeLive.bind(this, "Experiência de Marca")}
                                    // tooltip={item.description}
                                    fieldStyle={{ marginBottom: '-1rem' }}
                                    disabled={
                                        this.state.form.eventLiveMarketing.indexOf("Incentivo") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Mão de Obra") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Shopper") > -1 ? true : false
                                    }
                                />

                                {this.state.form.eventLiveMarketing.indexOf("Experiência de Marca") > -1 &&
                                    <LiveMarketingBrandExperience {...this.props} />
                                }


                                <CheckboxField
                                    label={I18n.get("Eventos")}
                                    value="Eventos"
                                    checked={this.state.form.eventLiveMarketing.indexOf("Eventos") > -1 ? true : false}
                                    onChange={this.onChangeLive.bind(this, "Eventos")}
                                    // tooltip={item.description}
                                    fieldStyle={{ marginBottom: '-1rem' }}
                                    disabled={
                                        this.state.form.eventLiveMarketing.indexOf("Incentivo") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Mão de Obra") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Shopper") > -1 ? true : false
                                    }
                                />

                                {this.state.form.eventLiveMarketing.indexOf("Eventos") > -1 &&
                                    <LiveMarketingEvents {...this.props} infos={this.state.form} />
                                }



                                <CheckboxField
                                    label={I18n.get("Incentivo")}
                                    value="Incentivo"
                                    checked={this.state.form.eventLiveMarketing.indexOf("Incentivo") > -1 ? true : false}
                                    onChange={this.onChangeLive.bind(this, "Incentivo")}
                                    // tooltip={item.description}
                                    fieldStyle={{ marginBottom: '-1rem' }}
                                    disabled={
                                        this.state.form.eventLiveMarketing.indexOf("Experiência de Marca") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Mão de Obra") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Eventos") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Shopper") > -1 ? true : false
                                    }
                                />

                                {this.state.form.eventLiveMarketing.indexOf("Incentivo") > -1 &&
                                    <LiveMarketingIncentive {...this.props} />
                                }



                                <CheckboxField
                                    label={I18n.get("Mão de Obra")}
                                    value="Mão de Obra"
                                    checked={this.state.form.eventLiveMarketing.indexOf("Mão de Obra") > -1 ? true : false}
                                    onChange={this.onChangeLive.bind(this, "Mão de Obra")}
                                    // tooltip={item.description}
                                    fieldStyle={{ marginBottom: '-1rem' }}
                                    disabled={
                                        this.state.form.eventLiveMarketing.indexOf("Incentivo") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Experiência de Marca") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Eventos") > -1 ? true : false
                                    }
                                />

                                {this.state.form.eventLiveMarketing.indexOf("Mão de Obra") > -1 &&
                                    <LiveMarketingLabor {...this.props} />
                                }

                                <CheckboxField
                                    label={I18n.get("Shopper")}
                                    value="Shopper"
                                    checked={this.state.form.eventLiveMarketing.indexOf("Shopper") > -1 ? true : false}
                                    onChange={this.onChangeLive.bind(this, "Shopper")}
                                    // tooltip={item.description}
                                    fieldStyle={{ marginBottom: '-1rem' }}
                                    disabled={
                                        this.state.form.eventLiveMarketing.indexOf("Incentivo") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Experiência de Marca") > -1 ||
                                            this.state.form.eventLiveMarketing.indexOf("Eventos") > -1 ? true : false
                                    }
                                />
                                {this.state.form.eventLiveMarketing.indexOf("Shopper") > -1 &&
                                    <LiveMarketingShopper {...this.props} />
                                }
                            </div>
                        </div>
                    </div>
                }









                <h1 className="title-bpool medium">{I18n.get("Indique pelo menos 3 principais mercados de expertise da sua empresa")} <TooltipField tooltip={I18n.get(
                    "Indique os principais mercados de atuação da sua empresa. É assim que vamos encontrar projetos e clientes certos para você"
                )} />
                </h1>

                <NewCompany {...this.props} />

                {this.props.company && !this.props.company.isNewCompany &&
                    <MarketExpertiseCmp {...this.props} type={1} />

                }


                <h1 className="title-bpool medium">{I18n.get("Indique pelo menos 3 principais mercados de expertise em sua carreira")} <TooltipField tooltip={I18n.get("Acreditamos que um expertise de uma empresa é sempre mais completo quando soma o expertise de carreira do time")} />
                </h1>
                <MarketExpertiseCmp {...this.props} type={2} />


                <br />
                {/*this.props.isAdmin && <div className="control">
                    <SubmitButtonField
                        label="Salvar"
                        onClick={this.submitForm.bind(this)}
                        task={this.state.task}
                    />
                </div>*/}
            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialismForm);
