import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { PARTNER_API } from '../../../actions/partner/actions';

import LoggedPage from '../../page-templates/fillForm-logged';

import BillingDetails from './_billing-details';

const mapStateToProps = state => {

  return Object.assign({}, state, state.partner);
};

const mapDispatchToProps = (dispatch) => {

  return {
    get_partner: () => {
      dispatch(PARTNER_API.get());
    },
    get_project: data => {
      dispatch(PARTNER_API.get_project(data));
    },
    create_billing_invoice: data => {
      return dispatch(PARTNER_API.create_extra_billing_invoice(data));
    },
    upload_billing_invoice: data => {
      return PARTNER_API.upload_billing_invoice(data);
    }

  };
};


class BillingDetailsAdmin extends React.Component {

  constructor(props) {

    super();

    this.state = {};
  }


  componentDidMount() {

    const { projectId } = this.props.match.params;

    var data = {
      projectId: projectId
    };

    this.props.get_project(data);

  }


  render() {

    const { extraId, billingId } = this.props.match.params;

    const { project = {} } = this.props;

    const extra = project.extras && project.extras.find((e) => e.id == extraId) || {};

    const { billings = [] } = extra;

    const billing = billings.find((item) => item.id == billingId);

    return (
      <LoggedPage {...this.props}>

        <div className="uplift partner-projects client-project-list">
          <div className="container">
            <br />
            <h1><span>{I18n.get("Faturamento")}</span></h1>

            <br />

            <p>{project.projectName}</p>
            <p style={{ fontSize: '70%' }}>
              {project.clientName}
            </p>

            <br />

            <p style={{ fontSize: '80%' }}>
              <span style={{ fontWeight: 'bold' }}>{I18n.get("Extra:")}</span> {extra.extraName}
            </p>

            <br /><br />

            <BillingDetails {...this.props} billing={billing} />

            <br /><br />

          </div>

        </div>

      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetailsAdmin);
