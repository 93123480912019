import React, { PureComponent, Fragment } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Highlighter from "react-highlight-words";

import {
  Table,
  Popconfirm,
  Input,
  Button,
  Checkbox,
  Tooltip,
  Icon,
  Tag,
} from "antd";

import { ItemBillingInstallment } from "./itemBillingInstallment";

import FormatCurrency from "../../../components/ui/format-currency";

const Btns = styled.p`
  width: 50px;
  displa: block;
  a {
    margin-left: 10px;
  }
`;

const TableEdit = styled(Table)`
  .ant-table-thead > tr > th * {
    font-size: 11px;
  }
`;
class tableCourse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSector = (sectorId) => {
    const items =
      this.props.sectorsFlat &&
      this.props.sectorsFlat.filter((item) => item.code == sectorId);

    if (items && items.length) {
      return items[0].label;
    } else {
      return {};
    }
  };

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return "";

    return (
      this.props.bp.clientProjectStatusesMap[value] &&
      this.props.bp.clientProjectStatusesMap[value].label
    );
  }

  handleSelectPo = (item, id) => {
    this.props.getPos(item, id);
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 300, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small">
          Cancelar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  render() {
    const columns = [
      {
        title: I18n.get("País"),
        dataIndex: "country",
        key: "country",
        width: 70,
        render: (country) => {
          if (country) {
            return (
              <img
                src={`https://assets.bpool.co/country-flags/png24px/${country}.png`}
              />
            );
          } else {
            return "";
          }
        },
      },

      {
        title: I18n.get("Tipo"),
        dataIndex: "type",
        key: "type",
        width: 100,
        render: (type, record) => <Tag>{type}</Tag>,
      },
      {
        title: I18n.get("Nome"),
        dataIndex: "name",
        key: "name",
        width: 240,
        render: (name, record) => {
          return (
            <NavLink
              style={{ fontSize: "11px" }}
              to={`/bpool/projetos/${record.projectId}`}
            >
              {name || `S/N (${record.name} - ${record.email})`}
            </NavLink>
          );
        },
      },
      {
        title: I18n.get("Status"),
        dataIndex: "status",
        key: "status",
        width: 150,
        render: (status) => <div style={{ fontSize: "11px" }}>{status}</div>,
      },

      {
        title: I18n.get("Parceiro"),
        dataIndex: "partnerName",
        key: "partnerName",
        width: 200,
        ...this.getColumnSearchProps("partnerName"),
        render: (partnerName) => (
          <div style={{ fontSize: "11px" }}>{partnerName}</div>
        ),
      },
      {
        title: I18n.get("Valor"),
        dataIndex: "billingTotal",
        key: "billingTotal",
        width: 150,
        render: (billingTotal, record) => {
          return (
            <Fragment>
              <FormatCurrency
                style={{ fontSize: "11px" }}
                amount={billingTotal}
                currency={record.currency}
              />
            </Fragment>
          );
        },
      },
      {
        title: I18n.get("Data"),
        dataIndex: "itemDate",
        key: "itemDate",
        width: 100,
        render: (itemDate) => (
          <div style={{ fontSize: "11px" }}>{itemDate}</div>
        ),
      },
      {
        title: I18n.get("Código"),
        dataIndex: "code",
        key: "code",
        width: 100,
      },
      {
        title: I18n.get("Bundle"),
        dataIndex: "bundle",
        key: "bundle",
        width: 100,
        render: (bundle, record) => {
          if (bundle) {
            return (
              <a href={bundle} target="_blank">
                <Icon type="file-pdf" />
              </a>
            );
          } else {
            return "---";
          }
        },
      },
      {
        title: I18n.get("#PO"),
        dataIndex: "billings",
        key: "billings",
        width: 350,
        render: (billings, record) => {
          return (
            billings &&
            billings.map((billing, index) => {
              const data = {
                projectId: record?.projectId,
                extraId: record?.extraId,
                billingId: billing?.id,
              };

              var style = {
                marginTop: index > 0 ? "0.5rem" : "",
                display: "flex",
              };

              return (
                <>
                  <div style={style}>
                    <div
                      style={{
                        flex: "1 1 0%",
                        paddingTop: "0.25rem",
                        textAlign: "right",
                      }}
                    >
                      <FormatCurrency
                        amount={billing.total}
                        currency={record.billingCurrency}
                      />
                      <div
                        style={{
                          paddingTop: "0.5rem",
                          fontSize: "8pt",
                          color: "#999999",
                        }}
                      >
                        {I18n.get("Liberado")} {billing.balancePercFmt}
                      </div>
                    </div>
                    <div style={{ flex: "1 1 0%", paddingLeft: "1rem" }}>
                      {record.extraId && (
                        <>
                          <NavLink
                            className="button is-small is-black is-outlined"
                            to={`/bpool/faturamento/${record.projectId}/extra/${record.extraId}/po/${billing.id}`}
                          >
                            {I18n.get("#PO")} {billing.projectPO}{" "}
                            {!billing.projectPO && billing.authorize
                              ? "S/N"
                              : ""}
                          </NavLink>
                        </>
                      )}

                      {!record.extraId && (
                        <>
                          <NavLink
                            className="button is-small is-black is-outlined"
                            to={`/bpool/faturamento/${record.projectId}/po/${billing.id}`}
                          >
                            {I18n.get("#PO")} {billing.projectPO}{" "}
                            {!billing.projectPO && billing.authorize
                              ? "S/N"
                              : ""}
                          </NavLink>
                        </>
                      )}
                    </div>
                    <div style={{ flexBasis: "30px", textAlign: "left" }}>
                      {record.extraId && (
                        <>
                          <Checkbox
                            onChange={() =>
                              this.handleSelectPo(record.item, billing.id)
                            }
                          />
                        </>
                      )}

                      {!record.extraId && (
                        <>
                          <Checkbox
                            onChange={() =>
                              this.handleSelectPo(record.item, billing.id)
                            }
                          />
                        </>
                      )}
                    </div>

                    <div style={{ flexBasis: "40px", textAlign: "right" }}>
                      <Popconfirm
                        title={I18n.get(
                          "Deseja realmente excluir essa PO bem como qualquer repasse atrelado a ela?"
                        )}
                        onConfirm={() => this.props.deletePO(data)}
                        okText={I18n.get("SIM")}
                        cancelText={I18n.get("NÃO")}
                        placement="left"
                      >
                        <a className="info-invite-icon">
                          <Icon
                            type="delete"
                            theme="filled"
                            title={`#PO: ${billing.projectPO}`}
                          />
                        </a>
                      </Popconfirm>
                    </div>
                  </div>
                  <div
                    style={{
                      paddingTop: "0.5rem",
                      fontSize: "9pt",
                      color: "#999999",
                      paddingRight: "70px",
                    }}
                  >
                    {billing.installments &&
                      billing.installments.map(
                        (installment, installmentIdx) => {
                          return (
                            <ItemBillingInstallment
                              installment={installment}
                              index={installmentIdx}
                              billingCurrency={record.billingCurrency}
                              handleSetItem={this.props.handleSetItem}
                              billingId={billing?.id}
                              projectId={record?.projectId}
                            />
                          );
                        }
                      )}
                  </div>
                </>
              );
            })
          );
        },
      },
    ];

    let dataTable =
      this.props.data &&
      this.props.data.map((item, index) => {
        return {
          key: index,
          projectId: item.projectId,
          extraId: item.extraId,
          country:
            item.clientCountry &&
            item.clientCountry.isO2 &&
            item.clientCountry.isO2.toLowerCase(),
          type: item.itemType,
          sku: item.projectSKU,
          name: item.itemName,
          email: item.email,
          money: item.currency.name,
          clientName: item.clientName || "",
          status: this.getStatus(item.status),
          price: item.priceClientWithDiscount,
          partnerName: item.partnerName || "",
          date: item.createdOnFmt,
          billings: item.billings,
          currency: item.currency,
          billingTotal: item.billingTotal,
          itemDate: item.itemDate,
          code: item?.billings[0].code,
          item: item,
          bundle: item?.pdfBundle,
          billingCurrency: item.billingCurrency,
        };
      });

    return (
      <TableEdit
        pagination={this.props.pagination ? true : false}
        columns={columns}
        dataSource={dataTable}
        locale={{ emptyText: I18n.get("Sem informação") }}
        scroll={{ x: 1200 }}
      />
    );
  }
}

export default tableCourse;
