import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { I18n } from "aws-amplify";
import { useBpay } from "../../../../../hooks/useBpay/useBpay.hook";
import { Input } from "../../../../../components/UI/Input";
import { SelectOne } from "../../../../../components/UI/SelectOne";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";

import { createQueryString, parseQueryString } from "./utils/resolvers";
import * as S from "./styles";

export const Filters = () => {
  let history = useHistory();
  let location = useLocation();
  const { getBpaymentSetupInProgress } = useBpay();
  const [searchTerm, setSearchTerm] = useState("");
  const [listStatus, setListStatus] = useState([]);
  const [filters, setFilters] = useState({});
  const [searchValueDebounced] = useDebounce(searchTerm, 1000);

  const { data, isLoading, error, refetch } = useQuery(
    "setupBpaymentsInProgress",
    () => getBpaymentSetupInProgress(),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data) => {
        const listStatusInt = data?.data?.status?.map((item) => ({
          code: Number(item?.code),
          label: item?.label,
        }));
        setListStatus(
          listStatusInt?.length
            ? [{ code: 9999, label: I18n.get("Todos") }, ...listStatusInt]
            : []
        );
      },
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os itens de filtro"),
          duration: 3,
        });
      },
    }
  );

  const handleChangeStatus = (status) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (status === 9999) {
      delete objQueries.status;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, status });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleSearch = (search) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!search) {
      delete objQueries.search;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, search });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchValueDebounced]);

  useEffect(() => {
    const objQueries = parseQueryString(location?.search);

    setFilters(objQueries);
  }, [location]);

  return (
    <S.Wrapper>
      <div style={{}}>
        <SelectOne
          placeholder={I18n.get("Status")}
          options={listStatus}
          value={filters?.status ? Number(filters?.status) : 9999}
          onChange={(value) => handleChangeStatus(value)}
          error=""
          loading={isLoading}
        />
      </div>
      <div style={{}}>
        <Input
          placeholder={I18n.get("Nome do fornecedor")}
          label
          value={searchTerm}
          onChange={(value) => setSearchTerm(value)}
          searchInput
        />
      </div>
    </S.Wrapper>
  );
};
