import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../../config";

import { PROJECT_GET } from "..";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const api_create_project = (data) => {
  return Auth.currentSession()
    .then((session) => {
      const token = session && session.idToken.jwtToken;

      var config = { headers: { Authorization: "Bearer " + token } };
      return axios
        .post(`${Config.API_BASE_URL}/v2/client-project/client`, data, config)
        .then((response) => {
          return {
            projectStep1: response.data,
          };
        })
        .catch((error) => {
          console.log("ERROR", error);
          return {
            projectStep1: error.data,
          };
        });
    })
    .catch((error) => {});
};
