import React from "react";
import { I18n } from "aws-amplify";
import * as S from "./styles";

import { H3Border } from "../../../../../components/UI/Titles";
import { Button } from "../../../../../components/UI/Button";
import CloseBlack from "../../../../../../svg/close-black.svg";

const NewProject = ({ onClickCloseModal }) => {
  return (
    <S.CreatProject>
      <S.Close onClick={() => onClickCloseModal()}>
        <img src={CloseBlack} />
      </S.Close>
      <H3Border>{I18n.get("Novo Projeto")}</H3Border>

      <S.Text>
        {I18n.get(
          "Um novo projeto está nascendo! Escolha um dos nossos pré-escopos e agilize o processo, ou solicite um escopo do zero."
        )}
      </S.Text>

      <S.Buttons>
        <Button text={I18n.get("Novo escopo por áudio")} theme="primary" />
        <Button text={I18n.get("Escopo pré-existente")} theme="primary" />
      </S.Buttons>
    </S.CreatProject>
  );
};

export default NewProject;
