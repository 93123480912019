import React from 'react';
import { Spin } from 'antd';

import * as S from './styles';

export const Loading = ({ text = 'Carregando...', size = 'small' }) => {
  return (
    <S.Wrapper>
      <Spin size={size} />
      <S.Text size={size}>{text}</S.Text>
    </S.Wrapper>
  );
};
