import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink } from 'react-router-dom'

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import ProjectDetailsSimple from './project-details-simple';
import QuotationForm from '../partner-project-invitation/quotation-form';

import Steps from './steps';

import { scrollToTop } from '../../utils/ui';

import { ClientProjectStatus } from '../../model';


class ProjectResumeSimple extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount() {

        scrollToTop();
    }

    submitForm(e) {

    }

    render() {

        const quotation = this.props.eventQuotation || {};

        return (
            <FillForm>
                <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">

                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-6" style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
                                <p className="title">{I18n.get("Resumo")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-6">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="client-step client-step-1 project-ongoing">

                    <div className="container client-container-1 project-ongoing-review">


                        {this.props.isCreative && <ProjectDetailsSimple {...this.props} />}

                        {this.props.isEvent && <QuotationForm {...this.props} quotation={quotation} suppressSchedule={true} />}

                        <br />
                        <br />


                    </div>



                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(ProjectResumeSimple);
