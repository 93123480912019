import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { LEADER_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_leader_company_info = (id) => {
    return (dispatch, getState) => {

        return axios.get(`${Config.API_BASE_URL}/leader/${id}/company-info`)
            .then(response => {
                dispatch({
                    type: LEADER_GET,
                    payload: {
                        ...response.data
                    }
                });
            })
            .catch(error => {
                throw (error);
            });
    };
}

export default api_get_leader_company_info;