import React, { Component } from "react";
import { I18n } from 'aws-amplify';
import {
    Row,
    Col,
    Form,
    Icon,
    Spin,
    Input,
    Select,
    Button
} from 'antd';

import styled from 'styled-components';

const { Option } = Select;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`

const FormBox = styled(Form)`

`

class FormOriginSource extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.props.action(values);
            }
        });
    };

    hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { filters } = this.props
        
        if (!filters) {
            return (
                <BoxSpin>
                    <Spin />
                </BoxSpin>
            )
        }


        return (
            <FormBox onSubmit={this.handleSubmit}>
                <Row gutter={6}>
                    <Col sm={8}>
                        <Form.Item
                            label={I18n.get("Origem")}>
                            {getFieldDecorator('clientId', {
                                rules: [{ required: true, message: 'ops!' }],
                                initialValue: ""
                            })(
                                <Select
                                    className="SelectMin"
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {filters && filters.map((item, index) => {
                                        console.log(item.code);
                                        return <Option disabled={!item.enabled} className="SelectMin" value={item.code} key={index}>{item.label}</Option>
                                    })}

                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    
                    <Col sm={2}>
                        <Form.Item className="labelClean" label="&nbsp;">
                            <Button
                                className="button bt-bpool black"
                                shape="round"
                                htmlType="submit"
                                disabled={!filters || getFieldValue("clientId") == ""}
                                loading={this.props.task.busy}
                            >
                                {I18n.get("Buscar")}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </FormBox>
        )
    }
}



const FormFormOriginSource = Form.create({})(FormOriginSource);

export default FormFormOriginSource;

