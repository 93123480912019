import styled from 'styled-components';

export const Title = styled.div`
  position: relative;

  p {
    font-weight: 600;
    color: #000000;
    font-size: 30px;
    margin: 15px 0 45px 0;

    :after {
      position: absolute;
      content: '';
      width: 40px;
      height: 4px;
      background: #000000;
      bottom: -10px;
      left: 0;
    }
  }
`;

export const TableWrapper = styled.div`
  .ant-table-content {
    height: 380px;
    overflow-y: auto;
    position: relative;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 16px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;

  form {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
  }
`;

export const Form = styled.form``;

export const ContainerInputs = styled.div`
  width: 70%;
  display: flex;
`;

export const ContainerButtons = styled.div`
  width: 30%;
  display: flex;
`;

export const InputGroup = styled.div`
  width: 100%;
  margin: 0 15px;

  input,
  button {
    width: 100%;
    height: 40px;
  }

  input {
    border: 1px solid;
    border-radius: 5px;
    padding: 10px 15px;
  }

  button {
    cursor: pointer;
  }
`;

export const ActionsTable = styled.div`
  display: flex;

  button {
    padding: 5px 10px;
    :first-of-type {
      margin-right: 15px;
    }
  }
`;

export const ButtonAction = styled.button`
  cursor: pointer;
`;

export const ListErrors = styled.ul`
  li {
    height: 30px;
    display: flex;
    line-height: 30px;
    align-items: center;

    span {
      padding-top: 5px;
    }

    img {
      margin-right: 15px;
      height: 24px;
    }
  }
`;
