import React from "react";
import { Link } from "react-router-dom";

import { I18n } from "aws-amplify";

import { Modal } from "antd";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import QuotationBudget from "./quotation-budget";

import QuotationAssetSummary from "./quotation-asset-summary";
import QuotationItemSummary from "./quotation-item-summary";
import QuotationGenericItemSummary from "./quotation-generic-item-summary";
import QuotationPercItemSummary from "./quotation-perc-item-summary";
import QuotationHourItemSummary from "./quotation-hour-item-summary";

import QuotationSummaryForm from "./quotation-summary-form";
import QuotationGrandTotal from "./quotation-grandtotal";
import QuotationSchedule from "./quotation-schedule-form";
import QuotationUpload from "./quotation-upload";

import QuotationSectionCreateForm from "./quotation-section-create-form";
import QuotationSectionDeleteForm from "./quotation-section-delete-form";

import QuotationNotes from "./quotation-notes";
import SubmitButtonField from "../form/submit-button-field";

import { ClientProjectUniversalQuotationSectionType } from "../../model";

import AgreementForm from "./_agreement-form";

import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";

class QuotationForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {},
      task: {},
      approve_task: {},
      submit_task: {},
      errors: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      //console.dir({ task: task, prev: prev });

      var nextState = Object.assign({}, prevState, { task: task });

      const quotation = this.props.quotation || {};

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];

          if ("checked" in nextState.form[item]) {
            nextState.form[item].checked = quotation[item];
          } else {
            nextState.form[item].value = quotation[item];
          }
        } else {
          nextState.form[item] = quotation[item];
        }
      });

      // atualiza os arquivos
      if (task.result) {
        nextState.form.files = this.props.files || [];
      }

      this.setState(nextState);
    }
  }

  getData() {
    const quotation = this.props.quotation || {};

    var data = {
      projectId: this.props.match.params.projectId,
      quotationId: this.props.quotation.id,
      fee: quotation.fee,
      tax: quotation.tax,
      partnerNotes: quotation.partnerNotes,
      clientNotes: quotation.clientNotes,
    };

    return data;
  }

  submitForm(isSubmit, e) {
    e.preventDefault();

    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task }, function () {
      const data = Object.assign({}, this.getData(), { isSubmit: isSubmit });

      this.props.update_universal_quotation(data).then(({ task, errors }) => {
        this.setState({ submit_task: task, errors: errors });
      });
    });
  }

  onReject(e) {
    e.preventDefault();

    var approve_task = new Task();

    approve_task.start();

    this.setState({ approve_task: approve_task });

    const data = Object.assign({}, this.getData(), { isApproved: false });

    this.props
      .update_universal_quotation_status(data)
      .then(({ task, errors }) => {
        this.setState({ approve_task: task, errors: errors });
      });
  }

  onApproval(isApproved, e) {
    e.preventDefault();

    var approve_task = new Task();

    approve_task.start();

    this.setState({ approve_task: approve_task });

    const data = Object.assign({}, this.getData(), { isApproved: isApproved });

    this.props
      .update_universal_quotation_status(data)
      .then(({ task, errors }) => {
        this.setState({ approve_task: task, errors: errors });
      });
  }

  onShowAgreement(e) {
    this.setState({ showAgreement: true });
  }

  onApprove() {
    var approve_task = new Task();

    approve_task.start();

    this.setState({ approve_task: approve_task });

    const data = Object.assign({}, this.getData(), { isApproved: true });

    return this.props
      .update_universal_quotation_status(data)
      .then(({ task, errors }) => {
        this.setState({ approve_task: task, errors: errors });

        return { task, errors };
      });
  }

  onExport(e) {
    if (e) e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
      quotationId: this.props.quotation.id,
    };

    this.props
      .export_universal_quotation(data)
      .then(({ task, errors, exportUrl }) => {
        if (task.result) {
          window.location = exportUrl;
        }
      });
  }

  render() {
    const quotation = this.props.quotation || {};
    const currency = this.props.currency || {};

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    const canApprove =
      this.props.auth.is_client_or_client_user && quotation.requestApproval;

    if (this.props.auth.is_client_or_client_user && quotation.isOpen) {
      return (
        <div>
          <br />

          <div className="msg-info">
            <h4>{I18n.get("O Parceiro está elaborando a proposta.")}</h4>
            <span>
              {" "}
              {I18n.get(
                "Você será notificado assim que o Parceiro finaliza-la e você poderá aprovar ou declinar a proposta."
              )}
            </span>
          </div>
        </div>
      );
    }

    return (
      <>
        <div>
          <br />
          <br />

          <div className="columns">
            <div className="column is-12">
              <QuotationBudget
                {...this.props}
                quotation={quotation}
                currency={currency}
              />

              <br />
              <br />
              <br />

              <div style={{ display: "flex" }}>
                <div style={{ flex: "1", alignItems: "center" }}>
                  <p className="title is-4">{I18n.get("Proposta Comercial")}</p>
                  {/* {canEdit && <>
                                    <p class="subtitle is-6">{I18n.get('Adicione uma ou mais seções')}</p>
                                </>} */}
                </div>

                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {canEdit && (
                    <>
                      <ButtonBpool
                        text={I18n.get("Adicionar Seção")}
                        theme="primary"
                        onClick={(e) => this.setState({ showAddSection: true })}
                      />
                    </>
                  )}
                </div>
              </div>

              {quotation.sections &&
                quotation.sections.map((section) => {
                  const removeButton = (
                    <a
                      onClick={(e) => this.setState({ removeSection: section })}
                      style={{ fontWeight: "normal", fontSize: "0.9rem" }}
                    >
                      {I18n.get("Excluir Seção")}
                    </a>
                  );

                  if (
                    section.sectionType ==
                      ClientProjectUniversalQuotationSectionType.Assets &&
                    this.props.assetsCombo &&
                    this.props.assetsCombo.length > 0
                  ) {
                    return (
                      <div className="universal-quotation-summary">
                        <QuotationAssetSummary
                          {...this.props}
                          quotation={quotation}
                          currency={currency}
                          section={section}
                        />

                        {canEdit && <>{removeButton}</>}
                      </div>
                    );
                  }

                  if (
                    section.sectionType ==
                    ClientProjectUniversalQuotationSectionType.Items
                  ) {
                    return (
                      <div className="universal-quotation-summary">
                        <QuotationItemSummary
                          {...this.props}
                          quotation={quotation}
                          currency={currency}
                          section={section}
                        />

                        {canEdit && <>{removeButton}</>}
                      </div>
                    );
                  }

                  if (
                    section.sectionType ==
                    ClientProjectUniversalQuotationSectionType.GenericItems
                  ) {
                    return (
                      <div className="universal-quotation-summary">
                        <QuotationGenericItemSummary
                          {...this.props}
                          quotation={quotation}
                          currency={currency}
                          section={section}
                        />

                        {canEdit && <>{removeButton}</>}
                      </div>
                    );
                  }

                  if (
                    section.sectionType ==
                    ClientProjectUniversalQuotationSectionType.PercItems
                  ) {
                    return (
                      <div className="universal-quotation-summary">
                        <QuotationPercItemSummary
                          {...this.props}
                          quotation={quotation}
                          currency={currency}
                          section={section}
                        />

                        {canEdit && <>{removeButton}</>}
                      </div>
                    );
                  }

                  if (
                    section.sectionType ==
                    ClientProjectUniversalQuotationSectionType.HourItems
                  ) {
                    return (
                      <div className="universal-quotation-summary">
                        <QuotationHourItemSummary
                          {...this.props}
                          quotation={quotation}
                          currency={currency}
                          section={section}
                        />

                        {canEdit && <>{removeButton}</>}
                      </div>
                    );
                  }

                  return <div>{section.sectionName}</div>;
                })}

              <br />
              <br />
              <br />

              <QuotationSummaryForm
                ref={this.summaryForm}
                {...this.props}
                quotation={quotation}
                currency={currency}
                errors={this.state.errors}
              />

              <br />
              <br />

              <QuotationGrandTotal
                {...this.props}
                quotation={quotation}
                currency={currency}
              />

              {this.props.exportEventQuotation && (
                <>
                  <br />
                  <br />
                  {/* <a
                    className="button bt-bpool black pull-right"
                    style={{ fontSize: "90%" }}
                    onClick={this.onExport.bind(this)}
                  >
                    {I18n.get("Exportar para Excel")} 
                  </a> */}

                  <ButtonBpool
                    text={I18n.get("Exportar para Excel")}
                    className="pull-right"
                    theme="secundary"
                    onClick={this.onExport.bind(this)}
                  />
                </>
              )}

              <br />
              <br />

              <QuotationSchedule {...this.props} quotation={quotation} />

              <br />
              <br />

              <QuotationUpload {...this.props} quotation={quotation} />

              <br />
              <br />

              <QuotationNotes
                {...this.props}
                quotation={quotation}
                canEditPartner={canEdit}
                canEditClient={canApprove}
              />

              <p className="has-text-danger">
                <ul>
                  {Object.keys(this.state.errors).map((item) => {
                    if (!this.state.errors[item]) return null;

                    return <li>{this.state.errors[item].errorMessage}</li>;
                  })}
                </ul>
              </p>

              {canEdit && (
                <div className="columns">
                  <div className="column is-3">
                    {/* <SubmitButtonField
                      label={I18n.get("Enviar para cliente")}
                      className="button bt-bpool"
                      task={this.state.submit_task}
                      onClick={this.submitForm.bind(this, true)}
                    /> */}

                    <ButtonBpool
                      text={I18n.get("Enviar para cliente")}
                      theme="primary"
                      onClick={this.submitForm.bind(this, true)}
                      loading={this.state?.submit_task?.busy}
                    />
                  </div>

                  <div className="column is-3">
                    {/* <SubmitButtonField
                      label={I18n.get("Salvar e editar depois")}
                      className="button bt-bpool black"
                      task={this.state.submit_task}
                      onClick={this.submitForm.bind(this, false)}
                    /> */}

                    <ButtonBpool
                      text={I18n.get("Salvar e editar depois")}
                      theme="segunary"
                      onClick={this.submitForm.bind(this, false)}
                      loading={this.state?.submit_task?.busy}
                    />
                  </div>
                </div>
              )}

              <br />

              {this.props.auth.is_partner && quotation.submittedOn && (
                <>
                  <div className="msg-info">
                    <h4>
                      <i className="fas fa-check"></i>{" "}
                      {I18n.get("Orçamento enviado ao cliente em")}{" "}
                      {quotation.submittedOn}.
                    </h4>
                    {quotation.isApproved === null && (
                      <span>
                        {" "}
                        {I18n.get("Agora é aguardar o retorno do(a) cliente.")}
                      </span>
                    )}
                  </div>

                  <Link
                    to={`/parceiro/projetos/v2`}
                    className="button bt-bpool grey previous"
                  >
                    {I18n.get("Voltar a lista de Projetos")}
                  </Link>
                </>
              )}

              <br />
              <br />

              {canApprove && (
                <div className="columns">
                  <div className="column is-3 is-offset-2">
                    <SubmitButtonField
                      label={I18n.get("Recusar")}
                      className="button bt-bpool grey pull-right full"
                      task={this.state.approve_task}
                      onClick={this.onReject.bind(this)}
                    />
                  </div>

                  <div className="column is-4 is-offset-2">
                    <SubmitButtonField
                      label={I18n.get("Aprovar escopo final")}
                      className="button bt-bpool black full"
                      task={this.state.approve_task}
                      onClick={this.onShowAgreement.bind(this)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          visible={this.state.showAddSection}
          width={900}
          footer={null}
          onCancel={(e) => this.setState({ showAddSection: false })}
        >
          <QuotationSectionCreateForm
            {...this.props}
            quotation={quotation}
            closeModal={(e) => this.setState({ showAddSection: false })}
          />
        </Modal>

        <Modal
          visible={this.state.removeSection}
          width={900}
          footer={null}
          onCancel={(e) => this.setState({ removeSection: null })}
        >
          {this.state.removeSection && (
            <>
              <QuotationSectionDeleteForm
                {...this.props}
                quotation={quotation}
                section={this.state.removeSection}
                closeModal={(e) => this.setState({ removeSection: null })}
              />
            </>
          )}
        </Modal>

        <Modal
          visible={this.state.showAgreement}
          width={600}
          footer={null}
          onCancel={(e) => this.setState({ showAgreement: false })}
        >
          <AgreementForm
            {...this.props}
            onApprove={this.onApprove.bind(this)}
            closeModal={(e) => this.setState({ showAgreement: false })}
          />
        </Modal>
      </>
    );
  }
}

export default QuotationForm;
