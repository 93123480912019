import styled from "styled-components";

export const H3 = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
  margin: 32px 0;
`;

export const Label = styled.div`
  line-height: 20px;
  margin-bottom: 4px;
  font-weight: 600;
`;

export const Title = styled.div`
  margin-bottom: 24px;

  h3 {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
  }
`;

export const NfseWrapper = styled.div`
  padding-bottom: 2px;
  margin-bottom: 2px;
  width: max-content;
`;

export const NfseContent = styled.div`
  width: max-content;
  display: grid;
  grid-template-columns: 280px auto;
  column-gap: 16px;
`;
