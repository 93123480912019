import React from "react";

import { I18n } from "aws-amplify";

import numeral from "numeral";

import { Task } from "../../../utils/task";

import { Icon, Spin, Modal, Tooltip } from "antd";

import PercentageField from "../../form/percentage-field";
import CheckboxSwitchField from "../../form/checkbox-switch-field";

import QuotationItemSummary from "./quotation-item-summary";
import QuotationItemForm from "./quotation-item-form";

import FormatCurrency from "../../ui/format-currency";

require("./quotation-summary-form.css");

const updateForm = (quotation) => {
  const {
    costsAmount,
    itemsAmount,
    itemsAmountDoubleTax,
    fee = {},
    tax = {},
    breakdown,
    includeTaxOnAssets,
    includeTaxOnFee,
  } = quotation || {};

  return {
    costsAmount,
    itemsAmount,
    itemsAmountDoubleTax,
    fee: fee && fee.percentage,
    feeAmount: fee && fee.amount,
    tax: tax && tax.percentage,
    taxAmount: tax && tax.amount,
    breakdown: breakdown,
    includeTaxOnAssets,
    includeTaxOnFee,
  };
};

class QuotationSummaryForm extends React.Component {
  constructor(props) {
    const form = updateForm(props.quotation);

    super();

    this.state = {
      fields: {
        breakdown: {
          value: "Selecione...",
          onChange: this.onUpload.bind(this),
          onBlur: this.onSave.bind(this),
          error: props.errors.breakdown,
          visited: true,
        },
        costsAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "costsAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.costsAmount,
          successIcon: false,
          visited: true,
        },
        itemsAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "itemsAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.itemsAmount,
          successIcon: false,
          visited: true,
        },
        itemsAmountDoubleTax: {
          value: "",
          onChange: this.onChangeForm.bind(this, "itemsAmountDoubleTax"),
          onBlur: this.onSave.bind(this),
          error: props.errors.itemsAmountDoubleTax,
          successIcon: false,
          visited: true,
        },
        fee: {
          value: "",
          onChange: this.onChangeForm.bind(this, "fee"),
          onBlur: this.onSave.bind(this),
          error: props.errors.fee,
          successIcon: false,
          visited: true,
        },
        feeAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "feeAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.feeAmount,
          successIcon: false,
          visited: true,
        },
        tax: {
          value: "",
          onChange: this.onChangeForm.bind(this, "tax"),
          onBlur: this.onSave.bind(this),
          error: props.errors.tax,
          successIcon: false,
          visited: true,
        },
        taxAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "taxAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.taxAmount,
          successIcon: false,
          visited: true,
        },
        includeTaxOnAssets: {
          id: "includeTaxOnAssets",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnAssets"),
          error: props.errors.includeTaxOnAssets,
          successIcon: false,
        },
        includeTaxOnFee: {
          id: "includeTaxOnFee",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnFee"),
          error: props.errors.includeTaxOnFee,
          successIcon: false,
        },
      },
      file: {},
      form: {
        ...form,
      },
      errors: {},
      task: {},
      upload_task: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev && !this.props.isAdmin) {
      //console.dir({ task: task, prev: prev });

      var nextState = Object.assign({}, prevState, { task: task });

      const form = updateForm(this.props.quotation);

      nextState.form = {
        ...prevState.form,
        ...form,
      };

      nextState.errors = this.props.errors;

      this.setState(nextState);
    }
  }
  onChangeForm(prop, e) {
    const value = e.target.value;

    const quotation = this.props.quotation || { fee: {}, tax: {} };

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      if (prop == "fee") {
        const feePerc = numeral(value).value() / 100;
        const feeAmount = quotation.externalItemsSubTotal * (feePerc / 100);

        nextState.form.feeAmount = feeAmount;
      }

      if (prop == "tax") {
        let baseAmount = quotation.externalItemsWithDoubleTaxSubTotal;

        if (quotation.includeTaxOnAssets) {
          baseAmount += quotation.internalItemsSubTotal;
        }

        if (quotation.includeTaxOnFee) {
          baseAmount += quotation.fee.amount;
        }

        const taxPerc = numeral(value).value() / 100 / 100;

        const div = 1 - taxPerc;

        const taxAmount = (baseAmount / div) * taxPerc;

        nextState.form.taxAmount = taxAmount;
      }

      return nextState;
    });
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = checked;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => {
        this.onSave();
      }
    );
  }

  getDataUploadMult = (files) => {
    if (files.length > 0) {
      let file = files[0];

      delete file["uid"];

      var upload_task = new Task();

      upload_task.start();

      this.setState({ upload_task: upload_task }, function () {
        const data = {
          ...file,
          projectId: this.props.match.params.projectId,
          partnerId: this.props.quotation.id,
          fileCategory: "breakdown",
        };

        this.props
          .uploadEventQuotationfile2(data)
          .then((response) => {
            this.setState((prevState) => {
              var nextState = Object.assign({}, prevState);

              nextState.file = response.data;
              nextState.form.breakdown = file.name;
              nextState.upload_task = { result: true };

              return nextState;
            });
          })
          .catch((error) => {
            this.setState({ upload_task: { result: false } });
          });
      });
    } else {
      this.setState({
        file: {},
      });
    }

    // this.props.upload_filev2(data);
  };

  onUpload(e) {
    var file = e.target.files[0];

    if (!file) return;

    if (e) e.preventDefault();

    var reader = new FileReader();

    reader.onload = (e) => {
      var upload_task = new Task();

      upload_task.start();

      this.setState({ upload_task: upload_task }, () => {
        const data = {
          projectId: this.props.match.params.projectId,
          partnerId: this.props.partner.id,
          file_type: "breakdown",
          name: file.name,
          base64: e.target.result,
        };

        this.props
          .uploadEventQuotationfile(data)
          .then((response) => {
            this.setState((prevState) => {
              var nextState = Object.assign({}, prevState);

              nextState.file = response.data;
              nextState.form.breakdown = file.name;
              nextState.upload_task = { result: true };

              return nextState;
            });
          })
          .catch((error) => {
            this.setState({ upload_task: { result: false } });
          });
      });
    };
    reader.readAsDataURL(file);
  }

  delFile(item) {
    var data = {
      projectId: this.props.match.params.projectId,
      partnerId: this.props.partner.id,
      ...item,
    };

    this.setState({ file: {} }, function () {
      this.props.delEventQuotationFile(data);
    });
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      extraId: this.props.match.params.extraId,
      quotationId: this.props.quotation.id,
      costsAmount: this.state.form.costsAmount,
      itemsAmount: this.state.form.itemsAmount,
      itemsAmountDoubleTax: this.state.form.itemsAmountDoubleTax,
      fee: {
        percentage: this.state.form.fee,
        amount: this.state.form.feeAmount,
      },
      tax: {
        percentage: this.state.form.tax,
        amount: this.state.form.taxAmount,
      },
      includeTaxOnAssets: this.state.form.includeTaxOnAssets,
      includeTaxOnFee: this.state.form.includeTaxOnFee,
    };

    data.file = this.state.file;

    return data;
  }

  onSave() {
    const data = this.getData();

    var save_task = new Task();

    save_task.start();

    this.setState({ task: save_task }, () => {
      this.props.updateExtraQuotation(data).then(({ task, errors }) => {
        this.setState({ task: task, errors: errors });
      });
    });
  }

  render() {
    const quotation = this.props.quotation || {};
    const currency = this.props.currency || {};

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    return (
      <>
        <div className="quotation-summary">
          <form>
            <br />

            <p className="title is-4">{I18n.get("Proposta Comercial")}</p>
            {canEdit && (
              <>
                <p class="subtitle is-6">
                  {I18n.get("Adicione os itens do seu orçamento")}
                </p>
              </>
            )}

            <QuotationItemSummary {...this.props} quotation={quotation} />

            {canEdit && (
              <>
                <a
                  className="button bt-bpool"
                  onClick={(e) => this.setState({ showAddItem: true })}
                >
                  {I18n.get("Adicionar Item")}
                </a>
              </>
            )}

            <br />
            <br />
            <br />
            <br />

            <div className="columns head">
              <div className="column is-6 ">{I18n.get("Custos Gerais")}</div>

              <div className="column is-3">%</div>

              <div className="column is-3">{I18n.get("Valor")}</div>
            </div>

            <div className="columns assets">
              <div className="column is-6 tit">
                {I18n.get("Custos Internos")}
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 val">
                <FormatCurrency
                  amount={quotation.internalItemsSubTotal}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns items">
              <div className="column is-6 tit">
                {I18n.get("Custos Terceiros")}
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 sum">
                <span style={{ opacity: "0.5" }}>
                  {/* {ptBR.format(quotation && quotation.itemsAmountSubTotal)} */}
                </span>
              </div>
            </div>

            <div className="columns items-sub">
              <div className="column is-6 subtit">
                - {I18n.get("Itens com bi-tributação")}
                <Tooltip
                  placement="right"
                  title={
                    "Considerar quando seus fornecedores não puderem trabalhar com o prazo de pagamento do cliente"
                  }
                >
                  <i
                    className="fas fa-question-circle"
                    style={{ marginLeft: "10px" }}
                  />{" "}
                </Tooltip>
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 subval">
                <FormatCurrency
                  amount={quotation.externalItemsWithDoubleTaxSubTotal}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns items-sub">
              <div className="column is-6 subtit">
                - {I18n.get("Itens sem bi-tributação")}
                <Tooltip
                  placement="right"
                  title={
                    "Considerar quando seus fornecedores podem trabalhar com o prazo de pagamento do cliente. Faturamento direto via BPool."
                  }
                >
                  <i
                    className="fas fa-question-circle"
                    style={{ marginLeft: "10px" }}
                  />{" "}
                </Tooltip>
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 subval">
                <FormatCurrency
                  amount={quotation.externalItemsWithoutDoubleTaxSubTotal}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns fee">
              <div className="column is-6 tit">{I18n.get("Fee Comissão")}</div>

              {canEdit && (
                <>
                  <div className="column is-3 edit">
                    <PercentageField
                      {...this.state.fields.fee}
                      value={this.state.form.fee}
                      error={this.state.errors["fee.percentage"]}
                    />
                  </div>
                </>
              )}
              {!canEdit && (
                <>
                  <div className="column is-3 val">
                    <p>{quotation.fee && quotation.fee.percentageFmt}</p>
                  </div>
                </>
              )}

              <div className="column is-3 sum">
                <FormatCurrency
                  amount={this.state.form.feeAmount}
                  currency={currency}
                />
              </div>
            </div>

            {!this.props.hasAssetTax && (
              <>
                <div className="columns tax">
                  <div className="column is-6 tit">{I18n.get("Impostos")}</div>

                  {canEdit && (
                    <>
                      <div className="column is-3 edit">
                        <PercentageField
                          {...this.state.fields.tax}
                          value={this.state.form.tax}
                          error={this.state.errors["tax.percentage"]}
                        />
                      </div>
                    </>
                  )}
                  {!canEdit && (
                    <>
                      <div className="column is-3 val">
                        <p>{quotation.tax && quotation.tax.percentageFmt}</p>
                      </div>
                    </>
                  )}

                  <div className="column is-3 sum">
                    <FormatCurrency
                      amount={this.state.form.taxAmount}
                      currency={currency}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="columns total">
              <div className="column is-3 is-offset-6 sum">
                {I18n.get("Total")}
              </div>

              <div className="column is-3 sum">
                <Spin
                  delay={500}
                  spinning={this.state.task.busy === true}
                  style={{ color: "#ffffff", marginRight: "1rem" }}
                  indicator={<Icon type="loading" />}
                />

                <FormatCurrency
                  amount={quotation.totalExcludingBPCommission}
                  currency={currency}
                />
              </div>
            </div>

            <br />

            {!this.props.hasAssetTax && this.props.auth.is_partner && (
              <>
                <div className="field">
                  <label className="label is-small">
                    {I18n.get("Incluir impostos")} *
                    <div style={{ marginTop: "0.5rem", opacity: "0.5" }}>
                      {I18n.get(
                        "Consulte seu contrato e verifique se os preços acordados para itens internos são brutos ou líquidos"
                      )}
                    </div>
                  </label>
                </div>

                <br />

                <div className="columns">
                  <div className="column is-6" style={{ border: "none" }}>
                    <CheckboxSwitchField
                      {...this.state.fields.includeTaxOnAssets}
                      label={I18n.get("Itens Internos")}
                      checked={this.state.form.includeTaxOnAssets}
                      disabled={!canEdit}
                    />
                  </div>
                  <div className="column is-6" style={{ border: "none" }}>
                    <CheckboxSwitchField
                      {...this.state.fields.includeTaxOnFee}
                      label={I18n.get("Fee Comissão")}
                      checked={this.state.form.includeTaxOnFee}
                      disabled={!canEdit}
                    />
                  </div>
                </div>
              </>
            )}
          </form>
        </div>

        <Modal
          visible={this.state.showAddItem}
          width={900}
          footer={null}
          onCancel={(e) => this.setState({ showAddItem: false })}
        >
          <QuotationItemForm
            {...this.props}
            quotation={quotation}
            closeModal={(e) => this.setState({ showAddItem: false })}
          />
        </Modal>
      </>
    );
  }
}

export default QuotationSummaryForm;
