import axios from 'axios';

import Config from '../../config';

import { AUTH_API } from '../auth/actions';

import { PARTNER_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_confirm_partner = (data) => {
    return (dispatch, getState) => {

        var config = {};

        return axios.post(`${Config.API_BASE_URL}/partner/confirm`, data, config)
            .then(response => {

                const email = response.data.email;
                const password = response.data.password;

                delete response.data['password'];

                dispatch({
                    type: PARTNER_UPDATE,
                    payload: response.data
                });

                dispatch(AUTH_API.login({ email: email, password: password }));
            })
            .catch(error => {

                dispatch({
                    type: PARTNER_UPDATE,
                    payload: {
                        errors: error.response.data
                    }
                });
            });
    };
}
export default api_confirm_partner;