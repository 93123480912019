import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Modal from '../Modal';
import ChatPartner from './ChatPartner';
import ChatClient from './ChatClient';
import QuickMeeting from '../QuickMeeting';

import {
  openChatModal,
  closeChatModal,
} from '../../../store/actions/chatModal';

import ChatIcon from '../../../../svg/chat-icon-v2.svg';

// import * as Chat from "../../../../componenx ts/partner/chat-modal-partner"

import * as S from './styles';

const Chat = ({ typeUser }) => {
  const dispatch = useDispatch();

  const chatModal = useSelector((state) => state.chatModal);
  const quickMeetingState = useSelector((state) => state.quickMeeting);

  const { height, width } = useWindowDimensions();

  const openChat = () => {
    dispatch(openChatModal());
  };

  const onClickCloseModal = () => {
    dispatch(closeChatModal());
  };

  const renderChats = () => {
    if (typeUser === 'partner') {
      return <ChatPartner onClickCloseModal={onClickCloseModal} />;
    }

    if (typeUser === 'client') {
      return <ChatClient onClickCloseModal={onClickCloseModal} />;
    }
  };

  return (
    <>
      <S.FixedChat
        data-label="button-open-chat"
        height={height}
        onClick={() => openChat()}
      >
        <img src={ChatIcon} alt="Chat" />
      </S.FixedChat>

      {chatModal.open && (
        <>
          <Modal
            onClickCloseModal={onClickCloseModal}
            header={false}
            closeInBg={false}
          >
            {renderChats()}
          </Modal>
        </>
      )}
      <QuickMeeting
        visible={quickMeetingState.open}
        projectId={quickMeetingState.projectId}
        partnerId={quickMeetingState.partnerId}
      />
    </>
  );
};

export default Chat;
