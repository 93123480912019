import login from './login';
import login_success from './login-success';
import login_failure from './login-failure';
import reset_password from './reset-password';
import set_password from './set-password';
import forgot_password from './forgot-password';
import logout from './logout';
import set_locale from './set-locale';

export const LOGIN = 'auth:login';
export const AUTH_FORGOT_PASSWORD = 'auth:forgot_password';
export const LOGIN_SUCCESS = 'auth:success';
export const LOGIN_FAILURE = 'auth:faiure';
export const AUTH_PASSWORD_SET = 'auth:password_set';
export const AUTH_PASSWORD_RESET = 'auth:password_reset';
export const LOGOUT = 'auth:logout';
export const EXPIRED = 'auth:expired';
export const UPDATE = 'auth:update';


export const AUTH_API = {
    login: login,
    logout: logout,
    login_success: login_success,
    login_failure: login_failure,
    reset_password: reset_password,
    set_password: set_password,
    forgot_password: forgot_password,
    set_locale: set_locale
}

