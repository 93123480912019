import React, { Component } from "react";
import { I18n } from 'aws-amplify';
import {
    Row,
    Col,
    Form,
    Icon,
    Spin,
    Input,
    Select,
    Button
} from 'antd';

import styled from 'styled-components';

const { Option } = Select;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`

const FormBox = styled(Form)`

`

class FormSections extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    formState: values,
                })
                
                this.props.action(values);
            }
        });
    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        }
     }


     renderAction = (item) => {
        if(Object.keys(item).length > 0) {
            return I18n.get("Editar");
        } else {
            return I18n.get("Adcionar");
        }
     }


    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { scopes } = this.props

        const {item = {}} = this.props;
        
        // if (!filters) {
        //     return (
        //         <BoxSpin>
        //             <Spin />
        //         </BoxSpin>
        //     )
        // }


        return (
            <FormBox onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={12}>
                        <Form.Item
                            label={I18n.get("Nome")}>
                            {getFieldDecorator('name', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: item.name || "",
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={12}>
                        <Form.Item
                            label={I18n.get("Scope")}>
                            {getFieldDecorator('scopeId', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: item.scopeId || null
                            })(
                                <Select
                                    className=""
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                >
                                    {/* <Option className="" value={null}>{I18n.get("Selecione")}</Option> */}
                                    {scopes.map((scope,index) => {
                                        return <Option className="" key={index} value={scope.scopeId}>{scope.name} - {scope.target == "C" ? "Client" : "Partner"} - {scope.language}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>                    
                    <Col sm={12}>
                        <Form.Item
                            label={I18n.get("Ordem")}>
                            {getFieldDecorator('order', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: item.order || null
                            })(
                                <Select
                                    className=""
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    <Option className="" value={null}>{I18n.get("Selecione")}</Option>
                                    <Option className="" value={0}>0</Option>
                                    <Option className="" value={1}>1</Option>
                                    <Option className="" value={2}>2</Option>
                                    <Option className="" value={3}>3</Option>
                                    <Option className="" value={4}>4</Option>
                                    <Option className="" value={5}>5</Option>
                                    <Option className="" value={6}>6</Option>
                                    <Option className="" value={7}>7</Option>
                                    <Option className="" value={8}>8</Option>
                                    <Option className="" value={9}>9</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={12}>
                        <Form.Item className="labelClean" label="&nbsp;">
                            <Button
                                className="button bt-bpool black"
                                shape="round"
                                htmlType="submit"
                                // disabled={!filters || getFieldValue("clientId") == ""}
                                loading={this.props?.task?.busy}
                            >
                                {this.renderAction(item)}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </FormBox>
        )
    }
}



const FormFormSections = Form.create({})(FormSections);

export default FormFormSections;

