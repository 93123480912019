import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import {
  Icon,
  notification,
  Table,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Popconfirm,
  Form,
} from "antd";

const Btns = styled.p`
  width: 50px;
  displa: block;
  a {
    margin-left: 10px;
  }
`;
const { Option } = Select;
const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "check") {
      return (
        <Checkbox.Group defaultValue={this.props.record.tmarket}>
          <span>
            <Checkbox value={1}>1</Checkbox>
          </span>
          <span>
            <Checkbox value={2}>2</Checkbox>
          </span>
        </Checkbox.Group>
      );
    }
    if (this.props.inputType === "text") {
      return <Input />;
    }
    if (this.props.inputType === "select") {
      const ListCategorie =
        this.props.listCategorie &&
        this.props.listCategorie.sort(
          (a, b) => (a.label > b.label) - (a.label < b.label)
        );
      return (
        <Select
          style={{ width: "100%" }}
          showSearch={true}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          initialValue={this.props.record.categoryId}
        >
          {ListCategorie &&
            ListCategorie.map((item) => {
              return <Option value={item.code}>{item.label}</Option>;
            })}
        </Select>
      );
    }

    if (this.props.inputType === "selectBool") {
    
      return (
        <Select
          style={{ width: "100%" }}
          showSearch={true}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          initialValue={this.props.record.global}
        >
        <Option value={true}>{I18n.get("Sim")}</Option>
        <Option value={false}>{I18n.get("Não")}</Option>
        </Select>
      );
    }
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `${I18n.get("Campo obrigatório")}: ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "",
      editingKey: "",
    };
  }

  getSector = (sectorId) => {
    const items =
      this.props.sectorsFlat &&
      this.props.sectorsFlat.filter((item) => item.code == sectorId);

    if (items && items.length) {
      return items[0].label;
    } else {
      return false;
    }
  };

  callback = (key) => {
    this.setState({
      activeKey: key,
    });
  };

  isEditing = (record) => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  save = (form, record) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const checkCategory = this.getSector(row.category);
      let newCategory = "";

      if (checkCategory) {
        newCategory = row.category;
      } else {
        newCategory = record.categoryId;
      }

      const data = {
        brandId: record.item.id,
        brandName: row.brandName,
        productId: record.item.productId,
        productName: row.productName,
        global: row.global,
        categoryId: newCategory,
        clientId: this.props.clientId,
        tmarket: row.tmarket,
      };

      this.props.updateBrand(data).then((errors) => {
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item) => {
            notification.error({
              message: I18n.get("Ops algo deu errado!"),
              description: errors[item].errorMessage,
              duration: 5,
            });
          });

          // this.setState({ editingKey: '' });
        } else {
          notification.success({
            message: I18n.get("Atualizado com sucesso!"),
            description: "",
            duration: 5,
          });

          this.setState({ editingKey: "" });
          this.props.viewErros();
        }
      });
    });
  };

  edit(key) {
    this.setState({ editingKey: key });
  }

  deleteProduct = (data) => {
    this.props.delBrand(data);
    this.props.viewErros();
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    let itemFor = [];

    this.props.brands &&
      this.props.brands.map((item) => {
        item.products &&
          item.products.forEach((element) => {
            itemFor.push({
              key: element.id,
              brandName: item.name,
              brandId: item.id,
              productName: element.name,
              global: element.global,
              productId: element.id,
              category:
                item?.categoryName || this.getSector(element.categoryId),
              categoryId: element.categoryId,
              tmarket: element.tMarket.sort(),
              item: {
                ...item,
                productName: element.name,
                productId: element.id,
                global: element.global,
                categoryId: element.categoryId,
                category:
                  item.categoryName || this.getSector(element.categoryId),
              },
            });
          });
      });

    const columns = [
      {
        title: I18n.get("Nome"),
        dataIndex: "brandName",
        type: "text",
        key: "name",
        editable: true,
        width: 180,
      },
      {
        title: I18n.get("Produto ou Serviço"),
        dataIndex: "productName",
        type: "text",
        key: "productName",
        editable: true,
        width: 180,
      },
      {
        title: I18n.get("Categoria"),
        dataIndex: "category",
        type: "select",
        key: "category",
        editable: true,
      },
      {
        title: I18n.get("Mercados"),
        dataIndex: "tmarket",
        type: "check",
        key: "tmarket",
        width: 130,
        editable: true,
        hidden: this.props.activeMultipleMarket,
        render: (tm, record) => {
          return tm.join();
        },
      },
      {
        title: I18n.get("Global"),
        dataIndex: "global",
        type: "selectBool",
        key: "global",
        editable: true,
        width: 130,
        render: (tm, record) => {
          return record.global ? I18n.get("Sim") : I18n.get("Não");
        },
      },
      {
        title: "",
        dataIndex: "operation",
        width: 130,
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);

          const data = {
            brandId: record.item.id,
            productId: record.item.productId,
            clientId: this.props.clientId,
          };

          return editable ? (
            <div className="btsEditDelete">
              <EditableContext.Consumer>
                {(form) => (
                  <a
                    onClick={() => this.save(form, record)}
                    style={{ marginRight: 8 }}
                    className="button bt-bpool bsmall full"
                  >
                    {I18n.get("Salvar")}
                  </a>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title={I18n.get("Deseja cancelar a alteração?")}
                onConfirm={() => this.cancel(record.key)}
              >
                <a className="button bt-bpool bsmall black full">
                  {I18n.get("Cancelar")}
                </a>
              </Popconfirm>
            </div>
          ) : (
            <div className="btsEditDelete">
              <a
                disabled={editingKey !== ""}
                onClick={() => this.edit(record.key)}
              >
                <Icon type="edit" theme="filled" />
              </a>

              <a disabled={editingKey !== ""}>
                <Popconfirm
                  title={I18n.get("Deseja excluir esse produto?")}
                  icon={
                    <Icon type="question-circle-o" style={{ color: "red" }} />
                  }
                  onConfirm={() => this.deleteProduct(data)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Icon type="delete" theme="filled" />
                </Popconfirm>
              </a>
            </div>
          );
        },
      },
    ].filter((item) => !item.hidden);

    const columnsNem = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          listCategorie: col.type == "select" ? this.props.sectorsFlat : null,
          inputType: col.type,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    // let number = data && data.length - 1;

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          pagination={false}
          components={components}
          columns={columnsNem}
          dataSource={itemFor || []}
          locale={{ emptyText: I18n.get("Sem informação") }}
          expandedRowKeys={this.state.activeKey}
          onExpandedRowsChange={this.callback}
          scroll={{ x: 800 }}
          rowClassName={() => "editable-row"}
        />
      </EditableContext.Provider>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable;
// ReactDOM.render(<EditableFormTable />, mountNode);
