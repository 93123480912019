import React from "react";
import { Route, Switch } from "react-router-dom";

import { CreateProjectPartnerStep1 } from "../../pages/Partner/CreateProject/pages/Step1";
import { CreateProjectPartnerStep2 } from "../../pages/Partner/CreateProject/pages/Step2";
import { CreateProjectPartnerStep3 } from "../../pages/Partner/CreateProject/pages/Step3";
import { CreateProjectPartnerStep4 } from "../../pages/Partner/CreateProject/pages/Step4";

import { Briefing } from "../../pages/Definitions/Briefing";
import { Macros } from "../../pages/Definitions/Macros";
import { Review } from "../../pages/Definitions/Review";
import { PO } from "../../pages/Definitions/Po";
import ProjectsList from "../../pages/ProjectsList";
import { ProjectInvitationDetails } from "../../pages/ProjectsList/Invitations/ProjectDetails";

import { ReviewInProgress } from "../../pages/InProgress/Review";
import { BriefingInProgress } from "../../pages/InProgress/Briefing";
import { FilesInProgress } from "../../pages/InProgress/Files";
import { MilestonesInProgress } from "../../pages/InProgress/Milestones";
import { POInProgress } from "../../pages/InProgress/Po";
import { ExtrasInProgress } from "../../pages/InProgress/Extras";
import { NewExtra } from "../../pages/InProgress/Extras/pages/NewExtra";
import { NewExtraDetails } from "../../pages/InProgress/Extras/pages/NewExtraDetails";
import { NewExtraBudget } from "../../pages/InProgress/Extras/pages/NewExtraBudget";
import { FinalizePartner } from "../../pages/InProgress/FinalizePartner";

function ProjectsListRoutes() {
  return (
    <>
      <Switch>
        {/* PARTNER USER */}
        <Route
          exact
          path="/parceiro/projetos/v2/criar/informacoes"
          component={CreateProjectPartnerStep1}
        />
        <Route
          exact
          path="/parceiro/projetos/v2/criar/informacoes/:projectId"
          component={CreateProjectPartnerStep1}
        />
        <Route
          exact
          path="/parceiro/projetos/v2/criar/briefing/:projectId"
          component={CreateProjectPartnerStep2}
        />
        <Route
          exact
          path="/parceiro/projetos/v2/criar/tipo/:projectId"
          component={CreateProjectPartnerStep3}
        />
        <Route
          exact
          path="/parceiro/projetos/v2/criar/resumo/:projectId"
          component={CreateProjectPartnerStep4}
        />

        <Route exact path="/parceiro/projetos/v2" component={ProjectsList} />
        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/invitation-details"
          component={ProjectInvitationDetails}
        />
        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/briefing"
          component={Briefing}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/macros"
          component={Macros}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/review"
          component={Review}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/faturamento"
          component={PO}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/review"
          component={ReviewInProgress}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/briefing"
          component={BriefingInProgress}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/files"
          component={FilesInProgress}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/milestones"
          component={MilestonesInProgress}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/po"
          component={POInProgress}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/extras"
          component={ExtrasInProgress}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/extras/new"
          component={NewExtra}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/extras/:extraId/details"
          component={NewExtraDetails}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/extras/:extraId/budget"
          component={NewExtraBudget}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/in-progress/finalize"
          component={FinalizePartner}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/extras"
          component={ExtrasInProgress}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/extras/new"
          component={NewExtra}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/extras/:extraId/details"
          component={NewExtraDetails}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/extras/:extraId/budget"
          component={NewExtraBudget}
        />

        <Route
          exact
          path="/parceiro/projetos/v2/:projectId/finalize"
          component={FinalizePartner}
        />
      </Switch>
    </>
  );
}

export default ProjectsListRoutes;
