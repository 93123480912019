import styled from "styled-components";
import icoBack from '../../../image/ico-back.svg';
import * as MediaQuery from "../../../utils/mediaQuery";

export const ContainerNews = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    flex-direction: column;
    `}
`;

export const ContainerNewsContent = styled.div`
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    width: 80%;

    ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    width: 100%;
    `}
`;

export const HighlightedImage = styled.div`
    width: 100%;

    img {
        width: 100%;
    }
`;

export const ContentNews = styled.div`
    width: 80%;
    padding-bottom: 60px;
`;

export const ContentCategory = styled.p`
   font-size: 16px;
   line-height: 19px;
   color: #1883FF;
   font-weight: 600;
   margin: 60px 0 20px;
`;

export const ContentTitle = styled.h1`
   font-size: 48px;
   line-height: 56px;
   font-weight: 600;
   color: #2F2F2F ;
   margin-bottom: 25px;

   ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    font-size: 36px;
    line-height: 48px;
    `}
`;

export const ContentText = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-top: 25px;
`;

export const BackButton = styled.a`
    color: #1883FF;
    font-size: 16px;
    position: absolute;
    left: 0;
    font-weight: 600;

    &:before {
        content: '';
        width: 30px;
        height: 30px;
        background-image: url(${icoBack});
        display: inline-flex;
        top: 4px;
        position: relative;
    }

    &:hover, &:visited {
        color: #1883FF;
    }

    ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    position: relative;
    top: -20px;
    `}
`;

export const DivImageNews = styled.div`
   height: 460px;
   overflow: hidden;
   border-radius: 10px 10px 0 0;
   
   picture {
      display: flex;
      height: 100%;
      align-items: center;

      img {
         flex: 1;
         height: 100%;
         object-fit: cover;
         border-radius: 10px 10;
      }
   }
`;

export const LoaderDiv = styled.div`
   z-index: 99999999;
   position: absolute;
`;

