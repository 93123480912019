import React from 'react';

import { I18n } from 'aws-amplify';

class AwardFull extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    details(e) {

    }

    render() {

        return (<div>

            <figure className="media-left">
                <p className="image is-4by3">
                    <img src=" https://bulma.io/images/placeholders/empty.png" />
                </p>
            </figure>

            <p>
                <small>{this.props.product} </small> <br />
                <strong>{this.props.brand}</strong> <br />
            </p>
        </div>)
    }
}

export default AwardFull;