import React, { useEffect, useState } from "react";
import Button from "../Button";
import * as S from "./styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { strapiUrlsToDictionary } from "../../../utils/cmsImageUrlPacker";
import b1 from "../../../image/b1.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { I18n, Auth } from "aws-amplify";
import axios from "axios";

const BannerSlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <a href="#i">{i + 1}</a>;
    },
  };
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const { locale } = props.auth;
  const userType = props.auth.is_client ? "_is_client" : "_is_partner";

  useEffect(() => {
    async function getBanners() {
      Auth.currentSession().then((session) => {
        const token = session && session.accessToken.jwtToken;
        let authHeader = { headers: { Authorization: "Bearer " + token } };
        axios
          .get(
            locale == "pt"
              ? `https://cms.bpool.co/banners?_locale=pt-BR&${userType}=true`
              : `https://cms.bpool.co/banners?_locale=${locale}&${userType}=true`,
            authHeader
          )
          .then((res) => res.data)
          .then((data) => {
            setBanners(data);
            setLoading(false);
          });
      });
    }
    getBanners();
  }, []);

  function renderSlideImage(res, index) {
    let data = strapiUrlsToDictionary(res.image_highlight);
    return (
      <>
        <div key={index}>
          <S.SlideDiv bg={data.large}>
            <S.SlideDivImage>
              {/* <picture>
                <source
                  media="(min-width:1200px)"
                  srcSet={data.large}
                />
                <source
                  media="(min-width:768px)"
                  srcSet={data.medium}
                />
                <source
                  media="(min-width:465px)"
                  srcSet={data.small}
                />
                <img src={data.fallback} alt="" />
              </picture> */}
            </S.SlideDivImage>
            {res.news ? (
              <S.SlideDivTexts>
                <S.SlideDivTextsTitle>{res.news.title}</S.SlideDivTextsTitle>
                <S.SlideDivTextsDescription>
                  {res.news.description}
                </S.SlideDivTextsDescription>
                <S.SlideBtns>
                  {props.auth.is_client && (
                    <Button
                      text={I18n.get("Leia Mais")}
                      href={`/#/cliente/news/${res.news.slug}`}
                    />
                  )}
                  {props.auth.is_partner && (
                    <Button
                      text={I18n.get("Leia Mais")}
                      href={`/#/parceiro/news/${res.news.slug}`}
                    />
                  )}
                  {props.auth.is_client && (
                    <Button
                      text={I18n.get("Ver Todos")}
                      href="/#/cliente/news"
                    />
                  )}
                  {props.auth.is_partner && (
                    <Button
                      text={I18n.get("Ver Todos")}
                      href="/#/parceiro/news"
                    />
                  )}
                </S.SlideBtns>
              </S.SlideDivTexts>
            ) : (
              <S.SlideDivTexts>
                <S.SlideDivTextsTitle>{res.title}</S.SlideDivTextsTitle>
                <S.SlideDivTextsDescription>
                  {res.description}
                </S.SlideDivTextsDescription>
                <S.SlideBtns>
                  {res.has_link_button && (
                    <Button
                      text={I18n.get("Ver Mais")}
                      href={res.url}
                      target="_blank"
                    />
                  )}
                </S.SlideBtns>
              </S.SlideDivTexts>
            )}
          </S.SlideDiv>
        </div>
      </>
    );
  }

  // banners.forEach((item, index) => {
  //   console.log("Hightlight formats: ", item.image_highlight.formats);
  // });

  return (
    <>
      {!loading && banners && (
        <S.StyledSlider {...settings}>
          {banners.map((res, index) => renderSlideImage(res, index))}
        </S.StyledSlider>
      )}
      {/* {!loading && banners.length < 1 && (
        <S.SlideDiv>
          <S.SlideDivTextsDescription>{I18n.get("Nenhum banner a ser exibido")}</S.SlideDivTextsDescription>
        </S.SlideDiv>
      )} */}
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(BannerSlider));
