import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import LandingPage from '../page-templates/landingClean';

import bannerImage3 from '../../image/banner-image3.png';

export default class WallPage extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (
            <LandingPage>


                <div className="hero-body">

                    <div className="container">

                        <div className="columns">

                            <div className="column is-4">

                                <figure className="image">
                                    <img src={bannerImage3} alt="Banner" />
                                </figure>

                            </div>

                        </div>

                        <div className="columns">

                            <div className="column is-offset-3 is-6">

                                <h1 className="subtitle is-4 is-marginless">{I18n.get("Cadastro concluído, obrigado pelas informações!")}</h1>

                                <div className="colot-line"></div>

                                <br /> <br />

                                <div>
                                    <p>{I18n.get("Agora vamos disparar e-mails para as referências profissionais que você indicou e para os líderes (caso você tenha cadastrado) para que eles preencham a parte deles.")}</p>

                                    <br />

                                    <p>{I18n.get("Bem-vindo(a) a BPool!")}</p>

                                    <br />

                                    <p>
                                    <Link to="/login">{I18n.get("Clique aqui")}</Link>{I18n.get("para acessar a plataforma!")}</p>

                                    <br />


                                </div>



                                <br /> <br /> <br /> <br /> <br />
                                <br /> <br /> <br /> <br /> <br />
                                <br /> <br /> <br /> <br /> <br />

                            </div>

                        </div>

                    </div>
                </div>


            </LandingPage>
        );
    }
}

