import React, { useEffect } from "react";
import { I18n } from "aws-amplify";
import { useQuery } from "react-query";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useBpay } from "../../../../hooks/useBpay/useBpay.hook";
import Content from "../../../../components/UI/Content";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import { Breadcrumb } from "../../../../components/UI/Breadcrumb";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { NotificationBPool } from "../../../../components/UI/NotificationBPool";
import { BoxContent } from "../../components/BoxContent";
import { InformationsDetails } from "./InformationsDetails";
import { converData as InformationsDetailsData } from "./InformationsDetails/convertData";
import { BPaymentsData } from "./BPaymentsData";
import { converData as BPaymentsDataData } from "./BPaymentsData/convertData";
import { Filters } from "./Filters";
import { TableBPayments } from "./TableBPayments";

import * as S from "./styles";

export const BpayDetails = () => {
  let history = useHistory();
  let location = useLocation();
  let params = useParams();
  const { bpayDetailsInProgress } = useBpay();

  const { data, isLoading, error, refetch } = useQuery(
    `bpayDetail-id${params?.bpayId}`,
    () => bpayDetailsInProgress(params?.bpayId),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os dados do BPay"),
          duration: 3,
        });
      },
    }
  );

  const whatBreadCrumb = (url) => {
    const partes = url.split("/");
    const status = partes[3]; // "finalized" ou "details"
    return status;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LoggedPage>
      <Content style={{ paddingTop: 58 }}>
        <Breadcrumb
          title="BPay"
          breadcrumbs={[
            { name: "Home", route: "/" },
            { name: "BPay", route: "/cliente/bpay" },
            {
              name:
                whatBreadCrumb(location?.pathname) === "details"
                  ? "Detalhamento"
                  : "Finalizado",
              route:
                whatBreadCrumb(location?.pathname) === "details"
                  ? `/cliente/bpay/details/${params?.bpayId}`
                  : `/cliente/bpay/finalized/${params?.bpayId}`,
            },
          ]}
          buttons={
            <>
              <ButtonBpool
                text={I18n.get("Novo BPay")}
                theme="primary"
                onClick={() => history.push("/cliente/bpay/new/data")}
              />
            </>
          }
        />
        <div className="container">
          <S.ContentBpay>
            <BoxContent>
              <S.TitleContent>
                <h3>{`${I18n.get("Solicitação de BPay")} - ${I18n.get(
                  "PO Nº:"
                )} ${data?.data?.billing?.poNumber || `---`}`}</h3>
              </S.TitleContent>
              <InformationsDetails
                data={InformationsDetailsData(data?.data)}
                isLoading={isLoading}
              />
              <BPaymentsData
                data={BPaymentsDataData(data?.data)}
                isLoading={isLoading}
              />
              <Filters />
              <TableBPayments />
            </BoxContent>
          </S.ContentBpay>
        </div>
      </Content>
    </LoggedPage>
  );
};
