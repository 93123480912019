export const inputsState = {
  id: '',
  email: '',
  linkedin: '',
  name: '',
  nameAlias: '',
  phoneNumber: '',
  profilePicture: '',
  resume: '',
  timeZone: '',
};
