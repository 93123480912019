import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { Collapse, message, Tooltip } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  LoadingOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";

import * as S from "./styles";

const { Panel } = Collapse;

export const ItemTeam = ({
  textEmpty,
  data,
  profiles,
  onDelete,
  onEdit,
  isUser,
  partnerId,
  contextPage
}) => {
  const { resendEmailTeamRegistration } = useRegistrationFlowFull();

  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    id: null,
  });
  const [resending, setResending] = useState(false);

  const handleDeleteModal = (id) => {
    if (openDeleteModal.open) {
      setOpenDeleteModal({ open: false, id: null });
    } else {
      setOpenDeleteModal({ open: true, id });
    }
  };

  const modalConfirmDelete = () => {
    onDelete(openDeleteModal.id);
    setOpenDeleteModal({ open: false, id: null });
  };

  const onChange = (key) => {
    return;
  };

  const handleEdit = (id) => {
    onEdit(id);
  };

  const handleResend = async (id) => {
    setResending(true);
    const response = await resendEmailTeamRegistration({ admin: false, id, partnerId });

    if (response?.success) {
      message.success(I18n.get("Email resent successfully!"));
    } else {
      message.error(I18n.get("There was an error. Please try again!"));
    }
    setResending(false);
  };

  const handleClickCopy = async (link) => {
    try {
      await navigator.clipboard.writeText(link);
      message.success(I18n.get("Link copied to clipboard!"));
    } catch (err) {
      message.error(I18n.get("Failed to copy link. Please try again!"));
    }
  };

  const renderStatus = ({ completedOn, id, link }) => {
    if (completedOn) {
      return (
        <S.StatusDiv>
          <S.CircleConcluded />
          <S.StatusText>{I18n.get("Concluded")}</S.StatusText>
        </S.StatusDiv>
      );
    } else {
      return (
        <S.StatusDiv>
          <S.CirclePending />
          <S.StatusText>{I18n.get("Pending")}</S.StatusText>

          <S.HandleResend>
            {resending ? (
              <LoadingOutlined />
            ) : contextPage !== "full" ? (
              <SendOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  handleResend(id);
                }}
              />
            ) : null}
          </S.HandleResend>
          {contextPage !== "full" ? (
            <S.HandleResend>
              <Tooltip
                placement="top"
                title={link}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClickCopy(link);
                }}
              >
                <LinkOutlined />
              </Tooltip>
            </S.HandleResend>
          ) : null}
        </S.StatusDiv>
      );
    }
  };

  const genExtra = ({ id, completedOn, link }) => (
    <>
      {renderStatus({ completedOn, id, link })}
      <EditOutlined
        onClick={(event) => {
          event.stopPropagation();
          handleEdit(id);
        }}
      />
      <DeleteOutlined
        style={{ marginLeft: 15 }}
        onClick={(event) => {
          event.stopPropagation();
          handleDeleteModal(id);
        }}
      />
    </>
  );

  const renderInfo = ({ label, value }) => {
    return (
      <S.ItemInfo>
        <S.ItemLabel>{label}</S.ItemLabel>
        <S.ItemValue>{value}</S.ItemValue>
      </S.ItemInfo>
    );
  };

  const renderHeader = ({ profile, index, name }) => {
    if (!isUser) {
      return `${I18n.get("Leader")} ${index} - ${name}`;
    } else {
      return `${I18n.get("User")} ${index} - ${name}`;
    }
  };

  const renderProfilesName = ({ roles, ids }) => {
    if (roles.length) {
      const arrNames = roles?.map((role) =>
        ids?.map((id) => (id === role?.code ? role?.label : null))
      );
      const namesFlat = arrNames.flat();
      const names = namesFlat?.filter((name) => name !== null);

      if (names?.length > 0) {
        return names?.map((name) => name).join(", ");
      } else {
        return "---";
      }
    } else {
      return I18n.get("Not selected");
    }
  };

  return (
    <>
      <S.WrapperItem>
        {data.length ? (
          <Collapse onChange={onChange}>
            {data.map((item, index) => (
              <Panel
                key={item?.id}
                header={renderHeader({
                  profile: item.userProfile,
                  index: index + 1,
                  name: item.name,
                })}
                extra={genExtra({
                  id: item?.id,
                  completedOn: item?.completedOn,
                  link: item?.link,
                })}
              >
                <S.ContentItem>
                  {renderInfo({
                    label: I18n.get("Name"),
                    value: item?.name,
                  })}
                  {renderInfo({ label: I18n.get("Email"), value: item?.email })}
                  {isUser
                    ? renderInfo({
                      label: I18n.get("Assignment"),
                      value: renderProfilesName({
                        roles: profiles,
                        ids: item?.userProfiles,
                      }),
                    })
                    : null}
                </S.ContentItem>
              </Panel>
            ))}
          </Collapse>
        ) : (
          <S.ItemFormEmpty>{textEmpty}</S.ItemFormEmpty>
        )}
      </S.WrapperItem>

      <ModalBp
        visible={openDeleteModal.open}
        bodyText={I18n.get("Do you want to delete?")}
        subBodyText={I18n.get("You will lose this item.")}
        okText={I18n.get("Delete")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={modalConfirmDelete}
        onCancel={handleDeleteModal}
        typeModal="isConfirm"
        isDanger
      />
    </>
  );
};
