import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'aws-amplify';

import * as S from './styles';
import iconCheck from '../../imgs/check.svg';
import iconCheckDisabled from '../../imgs/check-disabled.svg';

const STEP1 = 33.333333;
const STEP2 = 66.666666;
const STEP3 = 100;

const steps = [
  {
    id: 1,
    label: 'Application',
    progress: 16.666666,
  },
  {
    id: 2,
    label: 'About the Company',
    progress: 33.333333,
  },
  {
    id: 3,
    label: 'Industry',
    progress: 50,
  },
  {
    id: 4,
    label: 'Business Type',
    progress: 66.666666,
  },
  {
    id: 5,
    label: 'Speciality proof & Endorsement',
    progress: 83.333333,
  },
  {
    id: 6,
    label: 'Speciality proof',
    progress: 100,
  },
];

export const StepsProgress = ({ stepActual, stepConcluded }) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.ProgressAndStep>
          <S.Progress
            percent={steps[stepConcluded - 1].progress}
            showInfo={false}
          />
          <S.Steps>
            {steps.map((step) => (
              <S.Step key={step.id}>
                <button onClick={() => true} disabled={true}>
                  {stepConcluded === step.id ? (
                    <img alt="icon check" src={iconCheck} />
                  ) : (
                    <img alt="icon check" src={iconCheckDisabled} />
                  )}
                  <p
                    style={{
                      fontWeight: stepActual === step.id ? '700' : '400',
                    }}
                  >
                    {I18n.get(step.label)}
                  </p>
                </button>
              </S.Step>
            ))}
          </S.Steps>
        </S.ProgressAndStep>
      </S.Container>
    </S.Wrapper>
  );
};
