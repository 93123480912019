import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { PROJECT_API } from '../../actions/project/actions';

import FillForm from '../page-templates/fillForm-logged';

import MessagesForm from './messages-form';

import Steps from './steps';

const mapStateToProps = state => {
    return Object.assign({}, state.project, state);
};

const mapDispatchToProps = dispatch => {
    return {
        get_project: data => {
            dispatch(PROJECT_API.get(data));
        },       
        reset: () => {
            dispatch(PROJECT_API.reset());
        }
    };
};

class ProjectOnGoingMessages extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount(){
        //this.props.get_assets({});

        this.scrollToTop();
    }

    submitForm(e) {

    }

    scrollToTop() {
        const scrollDuration = 600;
        const scrollHeight = window.scrollY;
        const scrollStep = Math.PI / (scrollDuration / 15);
        const cosParameter = scrollHeight / 2;
        let scrollCount = 0;
        let scrollMargin;
        let scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                scrollCount = scrollCount + 1;
                scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                window.scrollTo(0, (scrollHeight - scrollMargin));
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }

    render() {


        return (
            <FillForm>
                <div className="hero-body has-background-white project-ongoing-header">
                    <div className="container">
                        <div className="container-nav-progress">
                            <div className="col">
                                <p className="title">{I18n.get("Projeto em Andamento")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="col">
                                <Steps active={5} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []}  />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-ongoing">

                    <div className="container client-container-1 project-ongoing">

                    <MessagesForm {...this.props} />


                    </div>


                    <div className="columns">

                                <div className="column">
                                </div>

                                <div className="column">

                                                <div className="field is-grouped is-grouped-centered container-content">
                                                    <p className="control is-expanded" onClick={this.scrollToTop}>
                                                    <Link to={`/projetos/${this.props.match.params.projectId}/cronograma`} 
                                                        className="button is-black is-outlined is-fullwidth previous">{I18n.get("Voltar")}</Link>
                                                    </p>
                                                    <p className="control is-expanded" onClick={this.scrollToTop}>
                                                        <Link to={`/projetos/${this.props.match.params.projectId}/producao`} className="button is-black is-fullwidth next">{I18n.get("PRÓXIMO")}</Link>
                                                    </p>
                                                    </div>

                                </div>

                                <div className="column">
                                </div>


                    </div>


                </div>

         </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOnGoingMessages);
