import React from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
    return {
    };
};

class Menu extends React.Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div>
                <br />
                <br />
                <aside className="menu">
                    <p className="menu-label">{I18n.get("MENU")}</p>
                    <ul className="menu-list">
                        <li>
                            <Link to="/cliente/responsavel" className={`is-black ${this.props.active == 1 ? "active" : ""}`}>{I18n.get("Responsável")}</Link>
                        </li>
                        <li>
                            <Link to="/cliente/empresa" className={`is-black ${this.props.active == 2 ? "active" : ""}`}>{I18n.get("Empresa")}</Link>
                        </li>
                        {/* <li>
                            <Link to="/cliente/plano" className={`is-black ${this.props.active == 1 ? "active" : ""}`}>{I18n.get("Plano")}</Link>
                        </li> */}
                        <li>
                            <Link to="/cliente/marcas" className={`is-black ${this.props.active == 3 ? "active" : ""}`}>{I18n.get("Marcas e Produtos")}</Link>
                        </li>
                        <li>
                            <Link to="/cliente/master" className={`is-black ${this.props.active == 4 ? "active" : ""}`}>{I18n.get("Usuários e permissões")}</Link>
                        </li>
                        <li>
                            <Link to="/cliente/idioma" className="is-black">{I18n.get("Idioma")}</Link>
                        </li>
                    </ul>
                </aside></div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
