import React from "react";
import { Modal, Button } from "antd";
import "./modalconfirm-styles.css";

import Logo from "../../../media/img/logo.png";
import IcoConfirm from "../../../media/svg/ico-confirm-modal.svg";

export const ModalConfirm = ({
  visible,
  bodyText,
  subBodyText,
  okText,
  handleClickOk,
}) => {
  return (
    <Modal
      centered
      visible={visible}
      onOk={handleClickOk}
      okText={okText}
      onCancel={handleClickOk}
      cancelText="Voltar"
      wrapClassName="modalconfirm"
    >
      <div className="logo-modal">
        <img src={Logo} alt="Bpool" />
      </div>
      <div className="ico-modal">
        <img src={IcoConfirm} alt="Ico confirm modal" />
      </div>
      <div className="text-modal">{bodyText}</div>
      {subBodyText ? <div className="sub-text-modal">{subBodyText}</div> : null}
    </Modal>
  );
};
