import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
//import { Link, Redirect } from 'react-router-dom'

import { PARTNER_API } from '../../actions/partner/actions';

import LeaderCmp from './_leader-cmp';

import {
    Icon,
    Tooltip,
} from 'antd';

const mapStateToProps = state => {
    return Object.assign({},
        { bp: state.bp, leader: { errors: {} },  },
        state.partner);
};

const mapDispatchToProps = dispatch => {
    return {
        addLeader: data => {
            dispatch(PARTNER_API.create_leader(data));
        },
        delLeader: data => {
            dispatch(PARTNER_API.delete_leader(data));
        },
        submit: data => {
            dispatch(PARTNER_API.update(data));
        },
    };
};

class LeaderForm extends React.Component {
    constructor(props) {
        super();
        this.state = {
            partnerId: props.partnerId
        };

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm(e) {
        e.preventDefault();
        const data = Object.assign({}, this.state, { isSubmit: true });
        this.props.submit(data);
    }

    render() {

        return (
            <form onSubmit={this.submitForm}>

                <h1 className="title-bpool medium">{I18n.get("Além de você, quem são os líderes do seu negócio")} <Tooltip title={I18n.get("Outros(as) líderes poderão colaborar adicionando suas próprias informações no cadastro. Os(as) profissionais inseridos(as) aqui receberão um email com link assim que você finalizar o processo de cadastro.")}> <Icon type="question-circle" theme="filled" /></Tooltip></h1>

                

                <LeaderCmp {...this.props} />

            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderForm);
