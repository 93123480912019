import { I18n } from "aws-amplify";

export const weeksPT = [
  { code: 0, label: I18n.get("0 semanas") },
  { code: 1, label: I18n.get("1 semana") },
  { code: 2, label: I18n.get("2 semanas") },
  { code: 3, label: I18n.get("3 semanas") },
  { code: 4, label: I18n.get("4 semanas") },
  { code: 5, label: I18n.get("5 semanas") },
  { code: 6, label: I18n.get("6 semanas") },
];

export const weeksEN = [
  { code: 0, label: I18n.get("0 weeks") },
  { code: 1, label: I18n.get("1 week") },
  { code: 2, label: I18n.get("2 weeks") },
  { code: 3, label: I18n.get("3 weeks") },
  { code: 4, label: I18n.get("4 weeks") },
  { code: 5, label: I18n.get("5 weeks") },
  { code: 6, label: I18n.get("6 weeks") },
];
