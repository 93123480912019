import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import TextField from '../../form/text-field';
import TextAreaField from '../../form/textarea-field';
import MoneyField from '../../form/money-field';
import PercentageField from '../../form/percentage-field';
import SelectField from '../../form/select-field';
import RadioMultiField from '../../form/radio-multi-field';
import CheckboxMultiField from '../../form/checkbox-multi-field';
import SubmitButtonField from '../../form/submit-button-field';

import { BPCommissionType, BPCommissionTypeNames, ProjectType, ProjectTypeNames, BPCompensationType, BPCompensationTypeNames } from '../../../model';


class CustomProjectForm extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            fields: {
                commissionType: {
                    label: I18n.get("Tipo de Comissão"),
                    value: true,
                    onChange: this.onChangeForm.bind(this, 'commissionType'),
                    successIcon: false,
                    visited: true,
                    isHorizontal: true
                },
                commissionValue: {
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'commissionValue'),
                    visited: true
                },
                applyTo: {
                    label: I18n.get("Aplicar"),
                    value: true,
                    onChange: this.onChangeForm.bind(this, 'applyTo'),
                    successIcon: false,
                    visited: true,
                    isHorizontal: true,
                    values: [{ code: 'T', label: 'Tipos de Projetos' }, { code: 'P', label: 'Projeto Específico' }]
                },
                projectSKU: {
                    label: I18n.get("Aplicar ao Projeto Número/SKU"),
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'projectSKU'),
                    visited: true
                },
                projectTypes: {
                    label: I18n.get("Aplicar aos Tipos de Projeto"),
                    value: [],
                    onChange: this.onChangeFormCheckboxMulti.bind(this, 'projectTypes'),
                    visited: true,
                    isHorizontal: true
                },
                comment: {
                    label: I18n.get("Comentário"),
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'comment'),
                    visited: true
                },
                compensationType: {
                    label: I18n.get("Remuneração de Participação"),
                    value: true,
                    onChange: this.onChangeForm.bind(this, 'compensationType'),
                    successIcon: false,
                    visited: true,
                    isHorizontal: true
                },
                compensation2nd: {
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'compensation2nd'),
                    visited: true
                },
                compensation3rd: {
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'compensation3rd'),
                    visited: true
                },
            },
            form: {
                commissionType: BPCommissionType.Perc,
                applyTo: 'T',
                projectTypes: [],
                compensationType: BPCompensationType.None
            },
            errors: {},
            task: {},
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormRadio(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = [value];
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormCheckboxMulti(prop, e) {

        const value = e.target.value;
        const checked = e.target.checked;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            if (checked) {
                nextState.form[prop] = [...nextState.form[prop], value];
            }
            else {
                nextState.form[prop] = nextState.form[prop].filter((v) => v !== value);
            }

            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onSubmitForm(e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ task: submit_task });

        var data = {
            clientId: this.props.match.params.clientId,
            ...this.state.form
        };

        this.props.create_client_settings_project_rule(data)
            .then(({ task, errors }) => {

                const form = {
                    commissionType: BPCommissionType.Perc,
                    applyTo: 'T',
                    projectTypes: []
                };

                if (task.result) {

                    this.setState({ task: task, errors: errors, form: form });

                    this.props.closeModal();
                }
                else {
                    this.setState({ task: task, errors: errors });
                }

            });
    }

    render() {

        const commissionTypeValues = [BPCommissionType.Perc, BPCommissionType.Flat].map((commissionType) => ({ code: commissionType, label: I18n.get(BPCommissionTypeNames[commissionType]) }));

        const compensationTypeValues = [BPCompensationType.None, BPCompensationType.Perc, BPCompensationType.Flat].map((compensationType) => ({ code: compensationType, label: I18n.get(BPCompensationTypeNames[compensationType]) }));

        const projectTypesValues = [ProjectType.Creative, ProjectType.EventCustom, ProjectType.BPitch, ProjectType.Ideas].map((projectType) => ({ code: `${projectType}`, label: I18n.get(ProjectTypeNames[projectType]) }));

        return (
            <form style={{ textAlign: 'left', backgroundColor: '#f5f5f5', padding: '2rem', margin: '-0.5rem' }}>

                <p className="title-bpool medium">{I18n.get("Nova Regra de Projeto")}</p>

                <br /><br />

                <RadioMultiField {...this.state.fields.commissionType} value={this.state.form.commissionType} error={this.state.errors.commissionType} values={commissionTypeValues} />

                <div style={{ width: '50%' }}>

                    {this.state.form.commissionType == BPCommissionType.Perc && <>

                        <PercentageField
                            {...this.state.fields.commissionValue}
                            label={I18n.get("Percentual")}
                            value={this.state.form.commissionValue}
                            error={this.state.errors.commissionValue} />

                    </>}
                    {this.state.form.commissionType == BPCommissionType.Flat && <>

                        <MoneyField
                            {...this.state.fields.commissionValue}
                            label={I18n.get("Valor Fixo")}
                            value={this.state.form.commissionValue}
                            error={this.state.errors.commissionValue} />

                    </>}
                </div>

                <br />

                <RadioMultiField {...this.state.fields.applyTo} value={this.state.form.applyTo} error={this.state.errors.applyTo} />

                {this.state.form.applyTo == 'P' && <div style={{ width: '50%' }}>

                    <TextField {...this.state.fields.projectSKU} value={this.state.form.projectSKU} error={this.state.errors.projectSKU} />

                </div>}

                {this.state.form.applyTo == 'T' && <div>

                    <CheckboxMultiField
                        {...this.state.fields.projectTypes}
                        value={this.state.form.projectTypes}
                        error={this.state.errors.projectTypes}
                        values={projectTypesValues} />
                </div>}

                <RadioMultiField {...this.state.fields.compensationType} value={this.state.form.compensationType} error={this.state.errors.compensationType} values={compensationTypeValues} />

                <div style={{ width: '50%' }}>

                    {this.state.form.compensationType == BPCompensationType.Perc && <>

                        <PercentageField
                            {...this.state.fields.compensation2nd}
                            label={I18n.get("Remuneração Custo 2º Lugar")}
                            value={this.state.form.compensation2nd}
                            error={this.state.errors.compensation2nd} />


                        <PercentageField
                            {...this.state.fields.compensation3rd}
                            label={I18n.get("Remuneração Custo 3º Lugar")}
                            value={this.state.form.compensation3rd}
                            error={this.state.errors.compensation3rd} />

                    </>}
                    {this.state.form.compensationType == BPCompensationType.Flat && <>

                        <MoneyField
                            {...this.state.fields.compensation2nd}
                            label={I18n.get("Remuneração Custo 2º Lugar")}
                            value={this.state.form.compensation2nd}
                            error={this.state.errors.compensation2nd} />

                        <MoneyField
                            {...this.state.fields.compensation3rd}
                            label={I18n.get("Remuneração Custo 3º Lugar")}
                            value={this.state.form.compensation3rd}
                            error={this.state.errors.compensation3rd} />

                    </>}
                </div>

                {/* <TextAreaField {...this.state.fields.comment} value={this.state.form.comment} error={this.state.errors.comment} /> */}

                <br />
                <br />

                <SubmitButtonField
                    label={I18n.get("Salvar")}
                    onClick={this.onSubmitForm.bind(this)}
                    className="button bt-bpool"
                    task={this.state.task}
                />



            </form>
        );
    }
}

export default CustomProjectForm;
