import React from "react";
import { I18n } from "aws-amplify";
import FlagRS from "./flag-rs.jpg";
import * as S from "./styles";

export const SosRS = ({ visible }) => {
  if (visible) {
    return (
      <S.Wrapper>
        <div className="sos-flag">
          <img src={FlagRS} alt="Bandeira do Rio Grande do Sul" />
        </div>
        <div className="sos-infos">
          <h3>{I18n.get("SOS Rio Grande do Sul")}</h3>
          <p>
            {I18n.get(
              "Essa empresa reside do Rio Grande do Sul, selecionando ela você estará contribuindo para a reconstrução do estado."
            )}
          </p>
        </div>
      </S.Wrapper>
    );
  } else {
    return <></>;
  }
};
