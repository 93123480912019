import React from 'react';

import styled from 'styled-components';

const NoData = styled.div`
    padding: 50px;
    text-align: center;

    h3 {
        font-weight: 700;
        margin-bottom: 30px;
    }

    i {
        font-size: 40px;
    }

    .txt {
        position: relative;
        left: 30px;
        top: -15px;
    }
`;

export default NoData; 