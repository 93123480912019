import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import PartnerCard from "../partner/shared/_partner-card";
import PartnerFullCard from "../partner/shared/_partner-full-card";

import CheckboxField from "../form/checkbox-field";
import LogoButton from "../form/logo-button";

import { scrollToTop } from "../../utils/ui";

import QuotationForm from "../partner-project-invitation/quotation-form";

import ProjectMessaging from "../shared/_project-messaging";

import NakedModal from "../ui/naked-modal";

import Terms from "./_terms";

import SubmitButtonField from "../form/submit-button-field";

import PartnerInviteForm from "./_partner-invite-form";

import VideoCallIcon from "../../svg/icon-videocall.svg";
import ChatIcon from "../../svg/icon-chat.svg";

import { Icon, Button, Modal } from "antd";

import styled from "styled-components";

import Chat from "../client/chat-modal-client";
import SimpleModal from "../ui/simple-modal";
import PartnerScheduling from "../shared/partner-scheduling";
import Config from "../../config";

import PartnerDetailsView from "./partners-form-detailsView";

const Main = styled.div`
  padding-bottom: 100px;
  max-width: 1000px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  color: #353535;
`;

class PartnersForm extends React.Component {
  constructor(props) {
    super();

    const eventTerms = props.project && props.project.eventTerms;

    this.state = {
      fields: {
        quotationIdx: {
          onChange: this.onChangeQuotation.bind(this),
          successIcon: false,
        },
      },
      form: {
        partners: [],
        partnerId: "",
        isSubmit: false,
      },
      errors: {},
      tab: "P",
      termsIsOpen: false,
      submit_task: {},
      orderKey: "name",
      messaging: false,
      scheduling: false,
      confirmation: false,
      deleteConfirmation: false,
      success: false,
      chatisOpen: false,
      error: false,
      task: {},
      meetings: [],
      partnersSelected: [],
      activeProject: false,
      activePartner: false,
      loadingCards: false,
      loadingButtonSend: false,
    };

    this.closeModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.fields).forEach((item) => {
        const value = this.props[item];

        nextState.form[item] = value;
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {}

  onChangeQuotation(e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["quotationIdx"] = value;

      return nextState;
    });
  }

  handleInvite() {
    this.setState({ loadingButtonSend: true });

    const data = {
      invites: this.state.partnersSelected,
      projectId: this.props.projectId,
    };
    this.props
      .createMeetingInvite(data)
      .then((response) => {
        if (response.errors && Object.keys(response.errors).length !== 0) {
          this.setState({
            confirmation: false,
            error: true,
            loadingButtonSend: false,
          });
        } else {
          this.setState({
            confirmation: false,
            success: true,
            loadingButtonSend: false,
          });
        }
      })
      .catch(({ error }) => {
        this.setState({ error: true, loadingButtonSend: false });
      });
  }

  hideDetails(e) {
    e.preventDefault();

    this.setState({ detailsView: null });
  }

  showScheduling(e) {
    if (e) e.preventDefault();

    this.setState({ scheduling: true });
  }

  showSuccess() {
    this.hideAllModal();
    this.setState({ success: true });
  }

  showConfirmation() {
    this.hideAllModal();
    this.setState({ confirmation: true });
  }

  showChat(partner) {
    this.setState({
      activePartner: {
        partnerId: partner.partnerId,
        partnerName: partner.name,
        logo: "",
      },
      activeProject: {
        projectId: this.props.projectId,
        projectName: this.props.project.name,
      },
      chatIsOpen: true,
    });
  }

  hideMessaging(e) {
    if (e) e.preventDefault();

    this.setState({ messaging: null });
  }

  hideChat(e) {
    if (e) e.preventDefault();

    this.setState({ chatIsOpen: null });
  }

  hideConfirmation() {
    this.setState({ confirmation: false });
  }

  hideScheduling(e) {
    if (e) e.preventDefault();

    this.setState({ scheduling: null });
  }

  hideDeleteConfirmation() {
    this.setState({ deleteConfirmation: false });
  }

  hideSuccess() {
    this.setState({ success: false });
  }
  hideError() {
    this.setState({ error: false });
  }
  hideAllModal() {
    this.hideScheduling();
    this.hideConfirmation();
    this.hideDeleteConfirmation();
    this.hideSuccess();
    this.hideError();
  }

  handleDelete() {
    this.setState({ loadingButtonSend: true });

    this.state.meetings.forEach((meeting) => {
      const data = {
        projectId: this.props.projectId,
        meetingId: meeting.id,
      };
      this.props.delete_meeting(data).then((response) => {
        if (response.errors && Object.keys(response.errors).length !== 0) {
          this.setState({
            deleteConfirmation: false,
            error: true,
            loadingButtonSend: false,
          });
        } else {
          this.setState({
            deleteConfirmation: false,
            scheduling: true,
            loadingButtonSend: false,
          });
        }
      });
    });
  }

  onChangePartnerSelected(e, partnerId) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      const partner = this.props.partners2.find(
        (p) => p.partnerId == partnerId
      );

      const exists = prevState.partnersSelected.find(
        (p) => p.partnerId == partnerId
      );

      //console.log({ checked: checked, partner: partner, exists: exists });

      if (checked) {
        if (!exists) {
          nextState.partnersSelected = [...prevState.partnersSelected, partner];
        }
      } else {
        if (exists) {
          nextState.partnersSelected = prevState.partnersSelected.filter(
            (p) => p.partnerId != partnerId
          );
        }
      }

      return nextState;
    });
  }

  selectTab(tab, e) {
    e.preventDefault();

    this.setState({ tab: tab });
  }

  hideMessaging(e) {
    if (e) e.preventDefault();

    this.setState({ messaging: null });
  }

  showDetails(item, quotation, tab, e) {
    this.setState({
      detailsView: { partner: item, quotation: quotation, tab: tab },
    });
    scrollToTop();
  }

  getData() {
    var data = {
      ...this.props.project,
    };

    if (this.state.partnerId) {
      data["partnerId"] = this.state.partnerId;
    }

    return data;
  }

  selectPartner(item) {
    this.setState({ partnerId: item.partnerId }, function () {
      var task = new Task();

      task.start();

      this.setState({ task: task }, function () {
        // this.props.save(this.getData());

        localStorage.setItem("selectedPartnerId", item.partnerId);

        this.props.history.push(
          `/cliente/eventos/${this.props.projectId}/parceiro-passo-2`
        );
      });
    });
  }

  onInvitePartnerForm(e) {
    e.preventDefault();

    this.setState({ invitePartner: true });
  }

  onCopyLink(e) {
    window.navigator.clipboard.writeText(
      `${window.location.origin}/#/preview/projeto/${this.props.match.params.projectId}`
    );

    e.preventDefault();

    this.setState({ copyLinkFeedback: true });

    setTimeout(() => {
      this.setState({ copyLinkFeedback: false });
    }, 3000);
  }

  onInvitePartner({ partnerIds, quotationDeadline }) {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    const data = {
      projectId: this.props.match.params.projectId,
      eventTerms: true,
      quotationDeadline: quotationDeadline,
      partners: partnerIds,
    };

    this.props.updateEventPartners(data).then(({ task, errors }) => {
      this.setState((prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.submit_task = task;
        nextState.errors = errors;
        nextState.termsIsOpen = false;
        nextState.form.partners = [];

        if (task.result) {
          nextState.invitePartner = null;
        }

        return nextState;
      });

      // refresh dos parceiros

      const data = {
        projectId: this.props.match.params.projectId,
      };

      this.props.get_partners(data);
    });
  }

  onCloseModal(e) {
    if (e) e.preventDefault();

    this.setState({ termsIsOpen: false });
  }

  render() {
    const { isBPitch, bPitch = {}, partners = [], partners2 = [] } = this.props;

    const { partnerGrade = {} } = bPitch || {};

    var errorMap = {};

    Object.keys(this.state.errors).map((item) => {
      const error = this.state.errors[item];

      const key = error.step || "global";

      if (!errorMap[key]) {
        errorMap[key] = [];
      }

      errorMap[key].push(error.errorMessage);
    });

    let enableInvitationBtn = false;

    const enableMessaging =
      this.props.settings && this.props.settings.enableMessaging;

    let partnersSorted = partners2;

    let msgs = Config.showChat || Config.showMeet;

    return (
      <form className="partnersApproval">
        {!this.state.detailsView && (
          <div
            className="container client-container-1"
            style={{ marginTop: "200px" }}
          >
            {!this.state.detailsView && (
              <div>
                {
                  <>
                    <a
                      className="is-pulled-right button bt-bpool black"
                      style={{
                        possiton: "relative",
                        top: "20px",
                        zIndex: "100",
                      }}
                      onClick={this.props.onShowQuestions}
                    >
                      {I18n.get("Perguntas & Respostas")}
                    </a>
                  </>
                }
                <br />
                <h1 className="title title-1">
                  {I18n.get(
                    "Olha que legal. Nosso algoritmo fez o matching e encontrou os parceiros abaixo."
                  )}
                </h1>
                <br />

                <div>
                  <p style={{ fontWeight: "bold" }}>
                    {I18n.get("Passo 1: Chemistry Meetings")}
                  </p>
                  <p style={{ display: "flex", alignItems: "center" }}>
                    {I18n.get(
                      "Navegue pelo perfil dos parceiros e agende videocalls de chemistry meetings através do botões de agendamento"
                    )}{" "}
                    &nbsp;{" "}
                    <img
                      src={VideoCallIcon}
                      className=""
                      style={{ height: "25px" }}
                    />{" "}
                    &nbsp; {I18n.get("ou_o_or")} {I18n.get("chat")} &nbsp;{" "}
                    <img src={ChatIcon} style={{ height: "25px" }} />
                  </p>

                  <br />
                  <p style={{ fontWeight: "bold" }}>
                    {I18n.get("Passo 2: Convite para Propostas")}
                  </p>
                  <p>
                    {I18n.get(
                      "Quando terminar, convide até 3 parceiros para participarem da etapa de proposta através do botão ‘convidar para proposta’"
                    )}
                  </p>

                  <br />
                  <p style={{ fontWeight: "bold" }}>
                    {I18n.get("Passo 3: Avaliação de Propostas")}
                  </p>
                  <p>
                    {I18n.get(
                      "Avalie as propostas, preencha os scorecards e selecione o seu parceiro"
                    )}
                  </p>
                </div>
                {msgs && (
                  <div>
                    <br />

                    <div className="alert-bpool">
                      <p>
                        <strong>{I18n.get("Chemistry meetings?")}</strong>{" "}
                        {I18n.get(
                          "Uma oportunidade de conversa como os líderes por trás de cada parceiro sobre seu desafio, categoria e marca. Tão importante quanto a criatividade é encontrar o parceiro certo para o seu projeto em termos de perfil, portfólio, tamanho, equipe, afinidade e interesse em sua marca."
                        )}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.props?.project?.isEvent && (
              <p style={{ marginTop: "40px" }}>
                {I18n.get(
                  "Nosso parceiros vão receber o convite para participar do processo de seleção contigo. Você será notificado das respostas na sua caixa de email"
                )}
              </p>
            )}

            {!this.state.detailsView && (
              <Fragment>
                <div>
                  <br />
                  <br />

                  <div className="columns is-multiline">
                    {partnersSorted &&
                      partnersSorted.map((item) => {
                        const invitation = item.projectInvitations.find(
                          (a) => a.projectId == this.props.projectId
                        );

                        const quotation =
                          this.props.eventQuotations &&
                          this.props.eventQuotations.find(
                            (a) => a.id == item.id && !a.isOpen
                          );

                        const isRevising =
                          this.props.eventQuotations &&
                          this.props.eventQuotations.find(
                            (a) => a.id == item.id && a.revising == true
                          );

                        const grade = partnerGrade && partnerGrade[item.id];

                        if (!invitation) {
                          enableInvitationBtn = true;
                        }

                        const checked =
                          this.state.partnersSelected &&
                          this.state.partnersSelected.find(
                            (a) => a.partnerId == item.id
                          );

                        const viewCard =
                          this.props.project &&
                          this.props.project.partnerIdsOptedIn &&
                          this.props.project.partnerIdsOptedIn.find(
                            (a) => a == item.id
                          );

                        if (viewCard) {
                          return (
                            <div key={item.id} className="column is-one-third">
                              <PartnerCard
                                {...item}
                                isSelected={
                                  item.partnerId == this.props.partnerId
                                }
                                showDetails={this.showDetails.bind(
                                  this,
                                  item,
                                  quotation,
                                  "P"
                                )}
                                selectPartner={this.selectPartner.bind(
                                  this,
                                  item
                                )}
                                openChat={
                                  enableMessaging &&
                                  this.showDetails.bind(
                                    this,
                                    item,
                                    quotation,
                                    "M"
                                  )
                                }
                                task={this.state.task}
                                isPreview={true}
                                grade={grade}
                                openScorecard={this.showDetails.bind(
                                  this,
                                  item,
                                  quotation,
                                  "C"
                                )}
                                bp={this.props.bp}
                                isSelectedPartner={true}
                                isMiniThumb={true}
                                showScheduling={this.showScheduling.bind(this)}
                                showChat={() => this.showChat(item)}
                                scheduling={this.state.scheduling}
                                isChat={true}
                                noSite={true}
                              />
                              <br />

                              {invitation && !quotation && (
                                <div>
                                  {invitation.accepted === null &&
                                    invitation.expired === null && (
                                      <div
                                        className="has-text-centered"
                                        style={{
                                          padding: "1.5rem",
                                          margin: "0 2.05rem",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Icon type="file-done" />{" "}
                                        {I18n.get("Briefing enviado")}
                                        <div
                                          style={{
                                            fontSize: "80%",
                                            fontWeight: "normal",
                                            opacity: "0.6",
                                          }}
                                        >
                                          {I18n.get(
                                            "Agora é aguardar o retorno do parceiro"
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  {invitation.expired === true && (
                                    <div
                                      className="has-text-centered"
                                      style={{
                                        padding: "1.5rem",
                                        margin: "0 2.05rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Icon type="clock-circle" />{" "}
                                      {I18n.get("Convite expirou")}
                                    </div>
                                  )}
                                  {invitation.accepted === true &&
                                    !isRevising && (
                                      <div
                                        className="has-text-centered"
                                        style={{
                                          padding: "1.5rem",
                                          margin: "0 2.05rem",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Icon type="check-circle" />{" "}
                                        {I18n.get(
                                          "Aceitou! Elaborando proposta"
                                        )}
                                      </div>
                                    )}
                                  {invitation.accepted === false && (
                                    <div
                                      className="has-text-centered"
                                      style={{
                                        padding: "1.5rem",
                                        margin: "0 2.05rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Icon type="close-circle" />{" "}
                                      {I18n.get("Declinou")}
                                      <div
                                        style={{
                                          fontSize: "80%",
                                          fontWeight: "normal",
                                          lineHeight: "100%",
                                          opacity: "0.6",
                                        }}
                                      >
                                        {I18n.get(
                                          "Este parceiro mudou de ideia e recusou enviar a proposta"
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {invitation.accepted === true &&
                                    isRevising && (
                                      <div
                                        className="has-text-centered"
                                        style={{
                                          padding: "1.5rem",
                                          margin: "0 2.05rem",
                                          backgroundColor: "#2F7708",
                                          color: "#fff",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {I18n.get("REVISANDO PROPOSTA")}
                                      </div>
                                    )}
                                </div>
                              )}

                              <p className="pselect">
                                {quotation && (
                                  <Fragment>
                                    <Button
                                      className="button bt-bpool"
                                      onClick={this.showDetails.bind(
                                        this,
                                        item,
                                        quotation,
                                        "Q"
                                      )}
                                    >
                                      <Icon type="eye" />{" "}
                                      {I18n.get("Ver proposta")}
                                    </Button>

                                    <Button
                                      onClick={this.selectPartner.bind(
                                        this,
                                        item
                                      )}
                                      className="button bt-bpool black"
                                    >
                                      <Icon type="like" />{" "}
                                      {I18n.get("Gostei deste!")}
                                    </Button>
                                  </Fragment>
                                )}
                              </p>

                              {!invitation && (
                                <p
                                  className="pselect"
                                  style={{ padding: "1rem 2rem" }}
                                >
                                  {/* <Button onClick={this.onInvitePartnerForm.bind(this, item)} disabled={!isBiddingPhasePartnerInvite} loading={this.state.submit_task.busy} className="button bt-bpool">
                                                              {I18n.get("Convidar para proposta")}
                                                          </Button> */}
                                  <CheckboxField
                                    label={I18n.get("Selecionar Parceiro")}
                                    value={item.id}
                                    checked={checked}
                                    onChange={(e) =>
                                      this.onChangePartnerSelected(e, item.id)
                                    }
                                    checkmarkStyle={{
                                      backgroundColor: "#ffffff",
                                    }}
                                  />
                                </p>
                              )}
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </div>

                  {Object.keys(errorMap).length > 0 && (
                    <div className="content is-small">
                      <br />
                      <br />

                      {Object.keys(errorMap).map((item, index) => {
                        return (
                          <p key={index}>
                            <span className="icon has-text-danger">
                              <i className="fas fa-exclamation"></i>
                            </span>
                            <ul>
                              {errorMap[item].map((errorMessage) => (
                                <li>{errorMessage}</li>
                              ))}
                            </ul>
                          </p>
                        );
                      })}
                    </div>
                  )}

                  <div
                    style={{
                      marginTop: "2rem",
                      display: "flex",
                      alignContent: "center",
                    }}
                  >
                    <div
                      style={{ flex: "1", padding: "1rem", textAlign: "right" }}
                    >
                      <Button
                        onClick={this.showScheduling.bind(this)}
                        disabled={!enableInvitationBtn}
                        loading={this.state.submit_task.busy}
                        className="button bt-bpool"
                      >
                        {I18n.get("Convidar para Chemistry Meeting")}
                      </Button>
                    </div>

                    <div
                      style={{ flex: "1", padding: "1rem", textAlign: "left" }}
                    >
                      <Button
                        onClick={this.onInvitePartnerForm.bind(this)}
                        disabled={!enableInvitationBtn}
                        loading={this.state.submit_task.busy}
                        className="button bt-bpool"
                      >
                        {I18n.get("Convidar para Proposta")}
                      </Button>

                      <Link
                        target="_blank"
                        to={`/preview/projeto/${this.props.match.params.projectId}`}
                      >
                        <Button
                          className="button"
                          icon="share-alt"
                          style={{ marginLeft: "5rem" }}
                        >
                          {this.state.copyLinkFeedback
                            ? I18n.get("Link copiado")
                            : I18n.get("Compartilhar Link")}
                        </Button>
                      </Link>
                    </div>
                  </div>

                  {/* {showInvitationBtn && <div className="is-pulled-right" style={{ padding: '1rem' }}>

                                    <LogoButton onClick={this.onInvitePartners.bind(this)} label={I18n.get("CONVIDAR")} className="button is-black" disabled={!this.state.form.partners.length} task={this.state.submit_task} />

                                </div>} */}
                </div>

                <br style={{ clear: "both" }} />

                <br />

                <Link
                  to={`/cliente/projetos/v2`}
                  className="button bt-bpool grey previous"
                >
                  {I18n.get("Voltar a lista de Projetos")}
                </Link>
              </Fragment>
            )}
          </div>
        )}

        {this.state.detailsView && (
          <>
            <PartnerDetailsView
              {...this.props}
              {...this.state.detailsView}
              hideDetails={this.hideDetails.bind(this)}
            />
          </>
        )}

        <Chat
          isOpen={this.state.chatIsOpen}
          onCloseModal={this.hideChat.bind(this)}
          activeProject={this.state.activeProject}
          activePartner={this.state.activePartner}
          clearActiveChat={() =>
            this.setState({ activeProject: false, activePartner: false })
          }
        ></Chat>

        <SimpleModal
          className="is-round overflow"
          isOpen={this.state.scheduling}
          onCloseModal={this.hideScheduling.bind(this)}
          size="large"
        >
          <PartnerScheduling
            partners={this.props.partners}
            partnersSelected={this.state.partnersSelected}
            onClose={this.hideScheduling.bind(this)}
            onSuccess={this.showSuccess.bind(this)}
            onConfirmation={this.showConfirmation.bind(this)}
            onDeleteConfirmation={() =>
              this.setState({ scheduling: false, deleteConfirmation: true })
            }
            setMeetings={(meetings) => {
              if (meetings) this.setState({ meetings });
            }}
            setPartnersSelected={(partners) => {
              if (partners) this.setState({ partnersSelected: partners });
            }}
            partnerIdsOptedInProp={
              this.props?.project?.partnerIdsOptedIn &&
              this.props?.project?.partnerIdsOptedIn.length > 0
                ? this.props?.project?.partnerIdsOptedIn
                : this.props?.project?.acceptedPartnerIds
            }
          />
        </SimpleModal>

        <NakedModal
          className="is-round overflow"
          isOpen={this.state.success}
          size="large"
        >
          <a
            onClick={() => this.setState({ success: false })}
            className="button outside-modal-naked"
          >
            {I18n.get("Fechar X")}
          </a>
          <div
            style={{ padding: "0", borderRadius: "10px", overflow: "hidden" }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                padding: "2% 10%",
                height: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>
                {I18n.get("Convite enviado com sucesso.")}
                <br />
                {I18n.get("Por favor aguarde confirmação dos parceiros.")}
                <br />
              </h1>
            </div>
          </div>
        </NakedModal>

        <NakedModal
          className="is-round overflow"
          isOpen={this.state.error}
          size="large"
        >
          <a
            onClick={() => this.setState({ error: false })}
            className="button outside-modal-naked"
          >
            {I18n.get("Fechar X")}
          </a>
          <div
            style={{ padding: "0", borderRadius: "10px", overflow: "hidden" }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                padding: "2% 10%",
                height: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1 style={{ color: "red" }}>
                {I18n.get(
                  "Algo deu errado no processamento da sua requisição, por favor, tente novamente."
                )}
                <br />
                {I18n.get("Caso o erro persista, contate o suporte.")}
              </h1>
            </div>
          </div>
        </NakedModal>

        <NakedModal
          className="is-round overflow"
          isOpen={this.state.confirmation}
          size="large"
        >
          <a
            onClick={() => this.setState({ confirmation: false })}
            className="button outside-modal-naked"
          >
            {I18n.get("Fechar X")}
          </a>
          <div
            style={{ padding: "0", borderRadius: "10px", overflow: "hidden" }}
          >
            <div className="contentModalCall">
              <h1>
                {I18n.get(
                  "Enviar os seguintes horários de videocall para os parceiros:"
                )}{" "}
                {this.state.partnersSelected &&
                  this.state.partnersSelected
                    .map((partner) => partner.company.name)
                    .join(", ")}
                ?
              </h1>
              <ul>
                {this.state.meetings &&
                  this.state.meetings.map((meeting) => {
                    if (meeting.meetingStatus != 1) {
                      return (
                        <li style={{ listStyle: "none" }} key={meeting.id}>
                          {meeting.meetingDate} {meeting.startTime} -{" "}
                          {meeting.endTime}{" "}
                        </li>
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </ul>
              <SubmitButtonField
                label={I18n.get("Confirmar")}
                onClick={() => this.handleInvite()}
                className="button blue no-webkit"
                task={{ busy: this.state.loadingButtonSend }}
              />
            </div>
          </div>
        </NakedModal>

        <NakedModal
          className="is-round overflow"
          isOpen={this.state.deleteConfirmation}
          size="large"
        >
          <a
            onClick={() =>
              this.setState({ deleteConfirmation: false, scheduling: true })
            }
            className="button outside-modal-naked"
          >
            {I18n.get("Fechar X")}
          </a>
          <div
            style={{ padding: "0", borderRadius: "10px", overflow: "hidden" }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                padding: "2% 10%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1>{I18n.get("Deseja realmente deletar esta meeting?")}</h1>
              <ul>
                {this.state.meetings &&
                  this.state.meetings.map((meeting) => {
                    return (
                      <li style={{ listStyle: "none" }} key={meeting.id}>
                        {meeting.meetingDate} {meeting.startTime} -{" "}
                        {meeting.endTime}{" "}
                      </li>
                    );
                  })}
              </ul>
              <SubmitButtonField
                label={I18n.get("Confirmar")}
                onClick={() => this.handleDelete()}
                className="button blue no-webkit"
                task={{ busy: this.state.loadingButtonSend }}
              />
            </div>
          </div>
        </NakedModal>

        <Modal
          visible={this.state.invitePartner}
          footer={null}
          onCancel={(e) => this.setState({ invitePartner: null })}
        >
          <PartnerInviteForm
            ref={this.invitePartnerFormRef}
            {...this.props}
            task={this.state.submit_task}
            errors={this.state.errors}
            partner={this.state.invitePartner}
            onInvitePartner={this.onInvitePartner.bind(this)}
            partners={partnersSorted}
            partnersSelected={this.state.partnersSelected}
            partnerIdsOptedIn={this.props?.project?.partnerIdsOptedIn}
          />
        </Modal>
      </form>
    );
  }
}

export default PartnersForm;
