const whatTypeUser = (userRoles) => {
  if (userRoles.includes("Master") || userRoles.includes("Bpool")) {
    return "Admin";
  }
  // Verifica se o usuário é um líder
  if (userRoles.includes("PartnerLeader" || userRoles.includes("Leader"))) {
    return "Leader";
  }

   // Verifica se o usuário é um líder
   if (userRoles.includes("PartnerOperation" || userRoles.includes("PartnerUser"))) {
    return "PartnerUser";
  }

  // PartnerOperation || PartnerFinancial
  // Verifica se o usuário é um partner
  if (userRoles.includes("Partner" )) {
    return "Partner";
  }

  // Se não for nenhum dos anteriores, então é um cliente
  return "Client";
};

export const getProfileType = () => {
  const profileGroups = localStorage.getItem("@BPOOL-profileGroups");

  const typeUser = whatTypeUser(profileGroups);

  return typeUser;
};
