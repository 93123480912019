import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useFileRepository = () => {
  const getProjects = async (query) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/client-project/completed${query}`
        : `${Config.API_BASE_URL}/v2/client-project/completed`,
      config
    );

    let dataFormattedLinks = [];
    if (data?.data?.result?.length) {
      dataFormattedLinks = data.data.result.map((item) => {
        let urls = [];
        if (item.projectCompletionUrls.length) {
          item.projectCompletionUrls.map((url) =>
            urls.push({
              ...url,
              projectId: item?.id,
              projectName: item?.projectName,
            })
          );
          return { ...item, projectCompletionUrls: urls };
        } else {
          return { ...item, projectCompletionUrls: urls };
        }
      });
    }

    let dataFormatted = [];
    if (dataFormattedLinks?.length) {
      dataFormatted = dataFormattedLinks.map((item) => {
        let files = [];
        if (item.files.length) {
          item.files.map((file) =>
            files.push({
              ...file,
              projectId: item?.id,
              projectName: item?.projectName,
            })
          );
          return { ...item, files: files };
        } else {
          return { ...item, files: files };
        }
      });
    }

    const returnData = {
      ...data,
      data: { ...data.data, result: dataFormatted },
    };

    return returnData;
  };

  return {
    getProjects,
  };
};
