import React from "react";

import { I18n } from "aws-amplify";

import CheckboxSwitchField from "../form/checkbox-switch-field";

import FormatCurrency from "../ui/format-currency";

import { Tooltip } from "antd";
require("./event-quotation-summary.css");

const VatCmp = ({ vat, amount, currency, otherTax, amountOtherTax }) => {
  return (
    <div style={{ marginTop: "0.5rem", fontWeight: "normal", fontSize: "75%" }}>
      {otherTax && (
        <>
          +{I18n.get("Other Tax")} {otherTax}% <br />
          <FormatCurrency amount={amountOtherTax} currency={currency} />{" "}
        </>
      )}
      <br />
      {vat && (
        <>
          +{I18n.get("IVA")} {vat}%{" "}
          <FormatCurrency amount={amount} currency={currency} />
        </>
      )}
    </div>
  );
};

class QuotationSummaryForm extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  render() {
    const quotation = this.props.quotation || {};
    const currency = this.props.currency || {};

    return (
      <div className="event-quotation-summary">
        <p className="title is-5">
          {I18n.get("Resumo Proposta Comercial Inicial")}
        </p>

        <br />

        <div className="columns head">
          <div className="column is-5 ">{I18n.get("Custos Gerais")}</div>

          <div className="column is-3">%</div>

          <div className="column is-3">{I18n.get("Valor")}</div>
        </div>

        <div className="columns assets">
          <div className="column is-5 tit">{I18n.get("Custos Internos")}</div>

          <div className="column is-3"></div>

          <div className="column is-3 val">
            {/* {ptBR.format(quotation.costsAmount)} */}
            <FormatCurrency
              amount={quotation.costsAmount}
              currency={currency}
            />
          </div>
        </div>

        <div className="columns items">
          <div className="column is-5 tit">{I18n.get("Custos Terceiros")}</div>

          <div className="column is-3"></div>

          <div className="column is-3 sum">
            <span style={{ opacity: "0.5" }}>
              {/* {ptBR.format(quotation && quotation.itemsAmountSubTotal)} */}
            </span>
          </div>
        </div>

        <div className="columns items-sub">
          <div className="column is-5 subtit">
            - {I18n.get("Itens com bi-tributação")}
            <Tooltip
              placement="right"
              title={
                "Considerar quando seus fornecedores não puderem trabalhar com o prazo de pagamento do cliente"
              }
            >
              <i
                className="fas fa-question-circle"
                style={{ marginLeft: "10px" }}
              />{" "}
            </Tooltip>
          </div>

          <div className="column is-3"></div>

          <div className="column is-3 subval">
            {/* {ptBR.format(quotation.itemsAmountDoubleTax)} */}
            <FormatCurrency
              amount={quotation.itemsAmountDoubleTax}
              currency={currency}
            />
          </div>
        </div>

        <div className="columns items-sub">
          <div className="column is-5 subtit">
            - {I18n.get("Itens sem bi-tributação")}
            <Tooltip
              placement="right"
              title={
                "Considerar quando seus fornecedores podem trabalhar com o prazo de pagamento do cliente. Faturamento direto via BPool."
              }
            >
              <i
                className="fas fa-question-circle"
                style={{ marginLeft: "10px" }}
              />{" "}
            </Tooltip>
          </div>

          <div className="column is-3"></div>

          <div className="column is-3 subval">
            {/* {ptBR.format(quotation.itemsAmount)} */}
            <FormatCurrency
              amount={quotation.itemsAmount}
              currency={currency}
            />
          </div>
        </div>

        <div className="columns fee">
          <div className="column is-5 tit">{I18n.get("Fee Comissão")}</div>

          <div className="column is-3 val">
            <p>{quotation.fee && quotation.fee.percentageFmt}</p>
          </div>

          <div className="column is-3 sum">
            <p>
              {/* {quotation.fee && ptBR.format(quotation.fee.amount)} */}
              {quotation.fee && (
                <FormatCurrency
                  amount={quotation.fee.amount}
                  currency={currency}
                />
              )}
            </p>
          </div>
        </div>

        <div className="columns tax">
          <div className="column is-5 tit">{I18n.get("Impostos")}</div>

          <div className="column is-3 val">
            <p>{quotation.tax && quotation.tax.percentageFmt}</p>
          </div>

          <div className="column is-3 sum">
            {/* {quotation.tax && ptBR.format(quotation.tax.amount)} */}
            {quotation.tax && (
              <FormatCurrency
                amount={quotation.tax.amount}
                currency={currency}
              />
            )}
          </div>
        </div>

        <div className="columns total">
          {" "}
          <div className="column is-3 is-offset-5 sum">{I18n.get("Total")}</div>
          <div className="column is-3 sum">
            {/* MOSTRAR PTK */}

            {this.props?.auth?.is_client && this.props.auth?.is_client_user && (
              <div className="displayValoresWhite">
                {/* <p style={{ fontWeight: "normal" }}>
                    <span>{I18n.get("Parcial:")} </span>
                    <br />
                    <span>{this.props?.extraPriceClientWithDiscountFmt}</span>
                  </p> */}
                <p>
                  <span style={{ fontWeight: "normal" }}>
                    {I18n.get("Total")}: <br />
                  </span>
                  <FormatCurrency
                    amount={
                      quotation.estimateTotal + quotation.estimateTotalOtherTax
                    }
                    currency={currency}
                  />

                  <br />
                  <span
                    style={{
                      fontSize: 11,
                      marginTop: "-10px",
                      fontWeight: "normal",
                    }}
                  >
                    {quotation?.otherTax > 0
                      ? `(${I18n.get("incluyendo gross up")} ${
                          quotation?.otherTax
                        }%)`
                      : ""}
                  </span>
                </p>
                {quotation.vat > 0 && (
                  <p style={{ fontWeight: "normal", border: 0 }}>
                    <span>{I18n.get("Impostos")} </span> <br />
                    (IVA {quotation.vat}%):{" "}
                    <span>
                      <FormatCurrency
                        amount={quotation.estimateTotalVat}
                        currency={currency}
                      />
                    </span>
                  </p>
                )}
              </div>
            )}
            {!this.props?.auth?.is_client &&
              !this.props.auth?.is_client_user && (
                <>
                  <FormatCurrency
                    amount={quotation.estimateTotal}
                    currency={currency}
                  />
                  <VatCmp
                    vat={quotation.vat}
                    amount={quotation.estimateTotalVat}
                    currency={currency}
                    otherTax={quotation?.otherTax}
                    amountOtherTax={quotation?.estimateTotalOtherTax}
                  />
                </>
              )}
            {/* <VatCmp
              vat={quotation.vat}
              amount={quotation.estimateTotalExcludingBPCommissionVat}
              currency={currency}
            /> */}
          </div>
        </div>

        <br />
      </div>
    );
  }
}

export default QuotationSummaryForm;
