import React from 'react';
import { I18n } from 'aws-amplify';
import { Redirect } from 'react-router-dom';

import ProjectContainer from '../../containers/project-container';

import { ClientProjectStatus } from '../../model';

import FillForm from '../page-templates/fillForm-logged';
import Loading from '../pages/loading';

import ProjectDetailsSimple from '../client-project-ongoing/project-details-simple';

import PartnersForm from './partners-form';

import Steps from './steps';


class SelectPartnerResume extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount() {

        const data = {
            projectId: this.props.match.params.projectId
        };

        this.props.get_partners(data);
    }

    submitForm(e) {

    }

    render() {

        if (this.props.project && this.props.project.status >= ClientProjectStatus.PartnerSelected) {
            return (<Redirect to={`/cliente/projetos/${this.props.match.params.projectId}/parceiro-selecionado`} />);
        }

        return (
            <FillForm>
                <div className="hero-body has-background-white for-np">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-7">
                                <p className="title">{I18n.get("Parceiro Aprovado")}<br />
                                    <span className="tag is-warning tag-project-name">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-5">
                                <Steps active={1} projectId={this.props.match.params.projectId} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1">

                    <div className="container client-container-1">
                        <ProjectDetailsSimple isTitle {...this.props} />
                    </div>

                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(SelectPartnerResume);
