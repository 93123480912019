import {
  CHAT_NOTIFICATION,
  CHAT_MESSAGE,
  CHAT_UPDATE,
  CHAT_NOTIFICATION_RESET,
  CHAT_NOTIFICATION_MARK,
  CHAT_MESSAGE_RESET,
  CHAT_LIST,
  CHAT_UPDATE_STATUS,
  CHAT_TOPICS,
  CHAT_TOPIC_MESSAGES,
  CHAT_TOPIC_NEW_MESSAGE,
  RESET_STATE,
} from '../actions/chat/actions';

const initialState = {
  notifications: [],
  messages: [],
  activeChannel: '',
  chatTopics: [],
  chatActiveMessages: [],
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case CHAT_UPDATE:
      // sobrescreve o(s) array(s)
      return Object.assign({}, state, action.payload);

    case CHAT_NOTIFICATION:
      return {
        ...state,
        // insere no comeco do arrays
        notifications: [...action.payload, ...state.notifications],
      };

    case CHAT_NOTIFICATION_RESET:
      return {
        ...state,
        notifications: [],
      };

    case CHAT_NOTIFICATION_MARK:
      return {
        ...state,
        notifications: [],
      };

    case CHAT_MESSAGE:
      return {
        ...state,
        // insere no final do array
        messages: [...state.messages, ...action.payload],
      };

    case CHAT_LIST:
      return {
        ...state,
        activeChannel: action.payload,
      };

    case CHAT_UPDATE_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };

    case CHAT_TOPICS:
      return {
        ...state,
        chatTopics: action.payload,
      };

    case CHAT_TOPIC_MESSAGES:
      return {
        ...state,
        chatActiveMessages: action.payload,
      };

    case CHAT_TOPIC_NEW_MESSAGE:
      return {
        ...state,
        chatActiveMessages: [...state.chatActiveMessages, ...action.payload],
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
