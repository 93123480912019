import React, { PureComponent, Fragment } from "react";

import { I18n } from 'aws-amplify';
import { Route, Switch, Link } from "react-router-dom";

import { Layout, Menu } from 'antd';

class MenuWikis extends PureComponent {
    state = {  }

    render() { 
        const {itens = [], isClient } = this.props;
        let prefix = isClient ? "cliente":"parceiro";

        if(itens.length < 1) return null;

        return ( 
            <Menu defaultSelectedKeys={[this.props.match.params.categoryId || "all"]} mode="inline" className="menu-wikis">

                {this.props.itens.map((item,index) => {

                    if(item.categories.length > 0){
                        return (
                            <Menu.ItemGroup
                                key={item.id}
                                title={
                                    <h2 className="title-menu">{item.name}</h2>
                                }
                                >
                                {
                                    item.categories.map((i) => {
                                        if(i.contents.length > 0){

                                            return(
                                                <Menu.Item key={i.categoryId}>
                                                <Link to={`/${prefix}/wikis/${this.props.activeTab}/${i.categoryId}`}>
                                                    <span>{i.name}</span>
                                                </Link>
                                            </Menu.Item>
                                        )
                                        }
                                    })
                                }
                            </Menu.ItemGroup>
                        )
                    }
                })}


                {/* {this.props.itens.map((item,index) => {
                    return(
                        <Menu.ItemGroup
                            key={item.id}
                            title={
                                <h2 className="title-menu">{item.name}</h2>
                            }
                            >
                            {
                                item.children.map((i) => {
                                    return(
                                        <Menu.Item key={i.id}>
                                            <Link to={`/${prefix}/wikis/${this.props.activeTab}/${i.id}`}>
                                                <span>{i.label}</span>
                                            </Link>
                                        </Menu.Item>
                                    )
                                })
                            }
                        </Menu.ItemGroup>
                    )
                })} */}

                {this.props.activeTab == "faqs" &&
                    <Menu.Item key="all" className="menu-all">
                        <Link to={`/${prefix}/wikis/faqs`}>
                            {I18n.get("Ver todos os FAQs")}
                        </Link>
                    </Menu.Item>                               
                }
                {/* {this.props.activeTab == "mapas" &&
                    <Menu.Item key="all" className="menu-all">
                        <Link to={'/cliente/wikis/mapas'}>
                            {I18n.get("Ver todos os Mapas")}
                        </Link>
                    </Menu.Item>                               
                } */}
                
                {this.props.activeTab == "tutoriais" &&
                    <Menu.Item key="all" className="menu-all">
                        <Link to={`/${prefix}/wikis/tutoriais`}>
                            {I18n.get("Ver todos os Tutoriais")}
                        </Link>
                    </Menu.Item>                               
                }
                
                
            </Menu>
         );
    }
}
 
export default MenuWikis;