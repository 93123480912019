import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';


import { Task } from '../../utils/task';


import styled from 'styled-components';

import { CardSimple } from '../../modules/components/Cards';

import FormFilterPartner from '../../modules/Admin/components/filterPartnersAdmin';
import TableSupplier from '../../modules/Admin/components/tableSuppliersAdmin';


import {
    Row,
    Col,
    notification,
    Icon
} from 'antd';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_suppliers: data => {
            dispatch(BP_API.get_suppliers(data));
        },
       
    };
};


const NoData = styled.div`
    padding: 50px;
    text-align: center;

    h3 {
        font-weight: 700;
        margin-bottom: 30px;
    }

    i {
        font-size: 40px;
    }

    .txt {
        position: relative;
        left: 30px;
        top: -15px;
    }
`;



class SupplierAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
            task: {}
        };
    }

    // handleFilters = (data) => {
    //     var task = new Task();

    //     task.start();

    //     this.setState({ task: task, historySearch: data }, function () {
    //         this.props.get_partners_list(data);
    //     });

    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     const task = this.props.bp.task || {};
    //     const prev = prevProps && prevProps.bp.task || {};

    //     //console.dir({ task: task, prev: prev });

    //     if (task != prev) {
    //         this.setState({ task: task });
    //     }

    // }

   
    
    componentDidMount() {
        // this.props.reset();
        // this.props.get_filters_partners();
        this.props.get_suppliers();

    }


    render() {
        return (
            // <></>
            // <a onClick={() => this.teste()}>TESTE</a>
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        {/* <li className="is-active"><NavLink to="/bpool/parceiros" className="is-black">{I18n.get("Parceiros")}</NavLink></li> */}
                    </ul>
                </nav>

                {/* <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            <FormFilterPartner
                                filters={this.props.bp && this.props.bp.adminPartnerFilters}
                                action={this.handleFilters}
                                task={this.state.task}
                            />
                        </CardSimple>
                    </Col>
                </Row> */}
                <br />

                <Row gutter={6}>
                    <Col sm={24}>
                        <CardSimple>
                            {!this.props.bp?.suppliers &&
                                <NoData>
                                    <h3 className="title-bpool medium">{I18n.get("Escolha as informações para a busca!")}</h3>

                                    {!this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="search" />
                                        </p>
                                    }

                                    {this.state.task.busy &&
                                        <p className="icone">
                                            <Icon type="loading" />
                                        </p>
                                    }
                                </NoData>

                            }

                            {this.props.bp?.suppliers &&
                                <div>
                                    {this.state.task.busy &&
                                        <NoData>
                                            <p className="icone">
                                                <Icon type="loading" /> <span className="txt">Atualizando</span>
                                            </p>
                                        </NoData>
                                    }
                                    <TableSupplier
                                        data={this.props.bp?.suppliers && this.props.bp.suppliers}
                                        {...this.props}
                                    />
                                </div>
                            }
                        </CardSimple>
                    </Col>
                </Row>
            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierAdmin);
