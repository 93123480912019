import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { CLIENT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_dashboard = () => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.get(`${Config.API_BASE_URL}/client/dashboard`, config)
            .then(response => {
                dispatch({
                    type: CLIENT_UPDATE,
                    payload: {
                        dashboard: response.data
                    }
                });
            })
            .catch(error => {
                throw (error);
            });
}).catch(error => { 
 dispatch({ type: EXPIRED }); 
 }); 
 };
}

export default api_get_dashboard;