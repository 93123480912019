import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import { PARTNER_API } from '../../actions/partner/actions';
import SelectSearchField from '../form/select-search-field';

import TooltipField from '../form/tooltip-field';

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp, marketExpertise: { errors: {} }, }, state.partner);
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(PARTNER_API.update_specialism(data));
        }
    };
};

const EVENTSTYPE = [
    { "label": "Corporativos (B2B)", "code": "CORPORATEB2B" },
    { "label": "Artísticos", "code": "ARTISTIC" },
    { "label": "Culturais", "code": "CULTURAL" },
    { "label": "Religiosos", "code": "RELIGIOUS" },
    { "label": "Esportivos", "code": "SPORTS" },
    { "label": "Acadêmicos", "code": "ACADEMICS" },
    { "label": "Turísticos", "code": "TOURISTIC" },
    { "label": "Marca (B2C)", "code": "BRANDB2C" },
]

const CORPORATETYPE = [
    { "label": "Workshops", "code": "Workshops" },
    { "label": "Convenções", "code": "Convencoes" },
    { "label": "Ferias", "code": "Feiras" },
    { "label": "Festa corporativa", "code": "Festa corporativa" },
    { "label": "Entrevistas coletiva", "code": "Entrevistas coletiva" },
    { "label": "Conferências", "code": "Conferencias" },
    { "label": "Forum", "code": "Forum" },
    { "label": "RH", "code": "RH" },
    { "label": "Evento PR", "code": "Evento PR" },
    { "label": "Roadshow", "code": "Roadshow" },
]

const SIZE = [
    { "label": "Pequena < 100", "code": "SMALL" },
    { "label": "Médio >100 até 750", "code": "AVERAGE" },
    { "label": "Grande >750 até 1500", "code": "BIG" },
    { "label": "Macro >1500", "code": "MACRP" },
]

class LiveMarketingEvents extends React.Component {

    constructor(props) {
        super();



        this.state = {
            form: {
                'eventLiveMarketingEvents.type': {
                    label: I18n.get("Tipos de evento"),
                    value: props.eventLiveMarketingEvents && props.eventLiveMarketingEvents.type,
                    onChange: this.onChangeSize.bind(this),
                    error: props.errors['eventLiveMarketingEvents.type'],
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'eventLiveMarketingEvents.type')
                },
                'eventLiveMarketingEvents.corporate': {
                    label: I18n.get("Tipo do evento corporativo"),
                    value: props.eventLiveMarketingEvents && props.eventLiveMarketingEvents.corporate ? props.eventLiveMarketingEvents.corporate : [],
                    onChange: this.onChangeCorporate.bind(this),
                    error: props.errors['eventLiveMarketingEvents.corporate'],
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'eventLiveMarketingEvents.corporate')
                },
                'eventLiveMarketingEvents.size': {
                    label: I18n.get("Porte"),
                    value: props.eventLiveMarketingEvents && props.eventLiveMarketingEvents.corporate ? props.eventLiveMarketingEvents.corporate : [],
                    onChange: this.onChangeCorporateSize.bind(this),
                    error: props.errors['eventLiveMarketingEvents.size'],
                    successIcon: false,
                    visited: true,
                    onBlur: this.onBlur.bind(this, 'eventLiveMarketingEvents.size')
                },
                isSubmit: false,
            },
            task: {},
        };

        //this.onDrop = this.onDrop.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });



        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                const value = FormUtils.getNestedValue(this.props, item);



                if (FormUtils.isFormField(nextState.form[item])) {

                    nextState.form[item].error = this.props.errors[item];

                    if ('checked' in nextState.form[item]) {
                        nextState.form[item].checked = value;
                    }
                    else {
                        nextState.form[item].value = value;
                    }
                }
                else {
                    nextState.form[item] = value;
                }
            });

            this.setState(nextState);
        }
    }

    onChangeSelect(value) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['eventLiveMarketingEvents.corporate'].value = value;

            return nextState;
        }, function () {

            var data = this.getData();

            this.onSave(data);
        });
    }

    onChangeSize(value) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['eventLiveMarketingEvents.type'].value = value;

            return nextState;
        }, function () {

            var data = this.getData();



            this.onSave(data);
        });
    }

    onChangeCorporate(value) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['eventLiveMarketingEvents.corporate'].value = value;

            return nextState;
        }, function () {

            var data = this.getData();


            this.onSave(data);
        });
    }

    onChangeCorporateSize(value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['eventLiveMarketingEvents.size'].value = value;

            return nextState;
        }, function () {

            var data = this.getData();

            this.onSave(data);
        });
    }


    onBlur(item) {



        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            FormUtils.clearError(nextState, item);

            return nextState;

        });
    }

    onChangeForm(prop, e) {



        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData() {
        var data = {
            partnerId: this.props.partnerId,
            specialisms: this.props.specialisms,
            keyServices: this.props.keyServices,
            marketExpertises: this.props.marketExpertises,
            eventLiveMarketing: this.props.eventLiveMarketing,
            eventLiveMarketingBrandExperience: this.props.eventLiveMarketingBrandExperience,
            eventLiveMarketingBrandIncentive: this.props.eventLiveMarketingBrandIncentive,
            eventLiveMarketingEvents: this.props.eventLiveMarketingEvents,
            eventLiveMarketingLabor: this.props.eventLiveMarketingLabor,
            eventLiveMarketingShopper: this.props.eventLiveMarketingShopper,
            eventCat: this.props.eventCat,
            eventSub: this.props.eventSub,
            ...this.props.infos,
            eventLiveMarketingEvents: {
                type: this.state.form['eventLiveMarketingEvents.type'].value,
                corporate: this.state.form['eventLiveMarketingEvents.corporate'].value,
                size: this.state.form['eventLiveMarketingEvents.size'].value
            }
        };



        return data;
    }

    onSave() {
        if (this.props.isAdmin) return;

        this.props.save(this.getData());
    }


    submitForm(e) {

        const isSubmit = true;

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData(isSubmit));
        });
    }

    render() {

        const { form } = this.state;

        let disabledField = true;

        if (this.props.eventLiveMarketingEvents && this.props.eventLiveMarketingEvents.type == null) {
            disabledField = true
        } else if (this.props.eventLiveMarketingEvents && this.props.eventLiveMarketingEvents.type != null && this.props.eventLiveMarketingEvents.type.indexOf("CORPORATEB2B") == -1) {
            disabledField = true
        } else {
            disabledField = false
        }

        return (
            <form onSubmit={this.submitForm.bind(this)}>

                <div className="columns">
                    <div className="column is-4">
                        <SelectSearchField {...this.state.form['eventLiveMarketingEvents.type']} values={EVENTSTYPE} isMulti />
                    </div>
                    <div className="column is-4">
                        <SelectSearchField disabled={disabledField} {...this.state.form['eventLiveMarketingEvents.corporate']} values={CORPORATETYPE} isMulti />
                    </div>
                    <div className="column is-4">
                        <SelectSearchField {...this.state.form['eventLiveMarketingEvents.size']} values={SIZE} isMulti />
                    </div>
                </div>
            </form >
        )
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(LiveMarketingEvents);

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LiveMarketingEvents);
