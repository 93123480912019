import React, { PureComponent } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";

import { Table, Button, Tag } from "antd";



const TableEdit = styled(Table)`
  .ant-table-thead > tr > th * {
    font-size: 11px;
  }
`;



class tableCourse extends PureComponent {
  constructor(props) {
    super();
    this.state = {};
  }

  handleSelectPo = (item, id) => {
    this.props.getPos(item, id);
  };

  

  render() {
    const rowSelection = {
      onChange: async (selectedRowKeys, selectedRows) => {
    
        const ids = await selectedRows.map((item) => item.id);

        this.props.getPos(ids);
      },
     
    };
    const columns = [
      {
        title: I18n.get("Cliente"),
        dataIndex: "clientName",
      },
      {
        title: I18n.get("PO"),
        dataIndex: "poNumber",
      },
      {
        title: I18n.get("Fornecedor"),
        render: (record) => (
          <div>
            {record.supplierName} <br />
            {record.supplierEmail}
          </div>
        ),
      },
      {
        title: I18n.get("Projeto"),
        render: (record) => (
          <div>
            {record.projectName}
          </div>
        ),
      },
      {
        title: I18n.get("Marca"),
        dataIndex: "brandName",
      },
      {
        title: I18n.get("SKU"),
        render: (record) => (
          <div>
            {record.projectSKU} 
          </div>
        ),
        
      },
      {
        title: I18n.get("Valor Total"),
        dataIndex: "amountTotalFmt",
      },
      {
        title: I18n.get("Valor Fee"),
        dataIndex: "amountFeeFmt",
      },
      {
        title: I18n.get("Valor Parceiro"),
        render: (record) => (
          <div>
            {record.amountPartnerFmt}

            {record.invoiceMissing && (
              <Tag color="red" style={{ marginLeft: "1rem" }}>
                {I18n.get("Em aberto")}
              </Tag>
            )}
            {!record.invoiceMissing && (
              <Tag color="green" style={{ marginLeft: "1rem" }}>
                {I18n.get("OK")}
              </Tag>
            )}
          </div>
        ),
      },
      {
        title: I18n.get("Criado"),
        dataIndex: "createdOn",
      },
      {
        title: I18n.get("NFSe"),
        dataIndex: "bPoolNFSe",
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        width: "60px",
        render: (id, record) => (
          <div>
            <Button
              icon="search"
              shape="circle"
              onClick={(e) => this.props.selectAction(e, record)}
            ></Button>
            <Button
              icon="mail"
              shape="circle"
              disabled={record.invoicePresent}
              onClick={(e) => this.props.resendAction(e, record)}
            ></Button>
            <Button
              icon="delete"
              shape="circle"
              disabled={record.invoicePresent}
              onClick={(e) => this.props.deleteAction(e, record)}
            ></Button>
          </div>
        ),
      },
      
    ];

    let dataTable = this.props.data?.map((item, index) => {
      return {
        key: item.id,
        ...item,
        item: item,
      };
    });


    return (
      <>
        <TableEdit
        pagination={{
          pageSize: 30,
          total: this.props.items,
          onChange: (page) => {
            this.props.handleFilters({ ...this.props.historySearch, page: page });
          },
        }}
          columns={columns}
          dataSource={dataTable}
          locale={{ emptyText: I18n.get("Sem informação") }}
          scroll={{ x: 1200 }}
          rowSelection={rowSelection}
        />
      </>
    );
  }
}

export default tableCourse;
