import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PARTNER_BRANDS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_brands_client = (clientId) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/partner/projects/clientBrands/${clientId}`, config)
                .then(response => {
                    dispatch({
                        type: PARTNER_BRANDS_GET,
                        payload: {
                            clientBrands: [...response.data],
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    })
                })
                .catch(error => {
                    dispatch({
                        type: PARTNER_BRANDS_GET,
                        payload: {
                            errors: {},
                            task: {
                                result: false
                            }
                        }
                    })
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_brands_client;
