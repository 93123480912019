import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
`;

export const ListOfTotals = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  margin: 0 0 32px 0;
`;

export const Total = styled.div`
  padding: 16px;
  border-radius: 10px;
  background: #fff;
  height: 135px;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.05));
`;

export const TotalIcon = styled.div`
  margin-right: 15px;
`;

export const TotalInfos = styled.div`
  h4 {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #515151;
    margin-bottom: 5px;
  }

  p {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }

  p.sub-title {
    display: flex;
    margin: 0;
    font-size: 12.5px;
    line-height: 15px;
    font-weight: 400;
    color: #515151;
  }
`;

export const TotalSubTitle = styled.div``;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-total {
    height: 135px;

    li {
      height: 100%;
    }
  }
`;
