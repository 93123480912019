import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../../utils/task";

import Form from "./_form";

import BillingItem from "../../shared/billing-item";

import Installments from "./_installments";

import FormatCurrency from "../../ui/format-currency";

import Constants from "../../../constants";

export default class ProjectDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      detailsView: null,
      task: {},
    };
  }

  componentDidMount() {
    this.setState({ task: {} });
  }

  render() {
    const {
      billing = {},
      currency = {},
      billingCurrency = {},
      pricePartnerOtherTaxFmt = null,
    } = this.props;

    let projectType = this.props.projectType;

    let projectTypeName =
      Constants.PROJECT_TYPES.find((xx) => xx.code == projectType)?.label ??
      "ERROR";

    if (projectType == 2) {
      let proposalType = this.props.customEvent.briefingType;

      //let proposalTypeName = Constants.PROJECT_PROPOSAL_TYPES.find(xx=> xx.code == proposalType)?.label ?? "ERROR"

      let events = [0, 1, 2, 3]; //subtipos de proposal consideraros "Live Marketing"
      //Outros sao somente Proposal

      if (events.includes(proposalType) == true) {
        projectTypeName = `${projectTypeName} Live Marketing`;
      }
    }

    //0 MP, 2 Proposal, 4 BPitch

    return (
      <div className="content">
        <div className="title-bpool medium">
          <strong>{this.props.projectName}</strong>
          <div className="has-text-grey-light">{this.props.clientName}</div>
          <div className="is-size-7 is-uppercase">{projectTypeName}</div>
        </div>

        <BillingItem
          billing={billing}
          currency={billingCurrency}
          handleNF={this.props.handleNF}
          isAdmin={true}
          sendCommission={this.props.sendCommission}
          projectId={this.props?.projectId}
          pricePartnerOtherTaxFmt={pricePartnerOtherTaxFmt}
          projectType={this.props?.projectType}
        />

        <br />

        <div className="card">
          <div className="card-content">
            <p className="is-pulled-right">
              <strong>{I18n.get("Saldo:")}</strong>{" "}
              {
                <FormatCurrency
                  amount={billing.balance}
                  currency={billingCurrency}
                />
              }
            </p>

            <p className="title-bpool medium">
              <strong>{I18n.get("Parcelas")}</strong>
            </p>

            <Form {...this.props} />

            <br />
            <br />

            <Installments {...this.props} currency={billingCurrency} />
          </div>
        </div>
      </div>
    );
  }
}
