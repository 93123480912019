export const inputsState = {
  id: '',
  partnerId: '',
  verificationToken: '',
  name: '',
  nameAlias: '',
  email: '',
  linkedin: '',
  resume: '',
  timeZone: '',
  country: {
    bpRegion: '',
    code: '',
    isO2: '',
    isO3: null,
    name: '',
    capital: '',
    prefix: '',
    currencyCode: '',
    culture: '',
    assetTax: 0,
    enabled: true,
  },
  phoneNumber: '',
  countryCodePhoneNumber: '',
  passWord: '',
  confirmPassWord: '',
  profilePicture: '',
};
