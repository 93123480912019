import React from "react";

import { I18n } from "aws-amplify";

import FormatCurrency from "../ui/format-currency";

require("./quotation-grandtotal.css");

const VatCmp = ({ vat, amount, currency, otherTax, amountOtherTax }) => {
  if (!vat) return null;

  return (
    <div style={{ marginTop: "0.5rem", fontWeight: "normal", fontSize: "75%" }}>
      {otherTax && (
        <>
          +{I18n.get("Other Tax")} {otherTax}%{" "}
          <FormatCurrency amount={amountOtherTax} currency={currency} />{" "}
        </>
      )}
      {vat && (
        <>
          +{I18n.get("IVA")} {vat}%{" "}
          <FormatCurrency amount={amount} currency={currency} />
        </>
      )}
    </div>
  );
};

class QuotationGrandTotal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const currency = this.props.currency || {};

    const { quotation = {} } = this.props;

    return (
      <div className="quotation-grandtotal">
        <p className="title is-5">{I18n.get("Resumo da Proposta")}</p>

        <br />

        <div className="columns head">
          <div className="column is-3">{I18n.get("Resumo Proposta")}</div>

          <div className="column is-2">%</div>

          <div className="column is-2">{I18n.get("Valor")}</div>
        </div>

        <div className="columns bpcommission">
          <div className="column is-3 tit">
            {I18n.get("Retenção Plataforma")}
          </div>

          <div className="column is-2 has-text-right">
            {quotation.bpCommission &&
              quotation.bpCommission.percentage > 0 &&
              quotation.bpCommission.percentageFmt}
          </div>

          <div className="column is-2 has-text-right">
            <FormatCurrency
              amount={quotation.bpCommission && quotation.bpCommission.amount}
              currency={currency}
            />

            <br />

            {this.props?.auth?.is_client_or_client_user &&
              this.props.bpCommission &&
              this.props.bpCommission?.customProjectRule && (
                <span
                  style={{
                    fontSize: 11,
                    marginTop: "-10px",
                    fontWeight: "normal",
                  }}
                >
                  + {I18n.get("Outras Taxas")}{" "}
                  {this.props.bpCommission?.customProjectRule.perc}% (
                  <FormatCurrency
                    amount={quotation.bpCommission?.amountFee}
                    currency={currency}
                  />
                  )
                </span>
              )}
          </div>
        </div>

        <div className="columns amount">
          <div className="column is-3 tit">{I18n.get("Proposta Parceiro")}</div>

          <div className="column is-2"></div>

          <div className="column is-2 has-text-right">
            <FormatCurrency
              amount={quotation.estimateTotalExcludingBPCommission}
              currency={currency}
            />
          </div>
        </div>

        <div className="columns total">
          {" "}
          <div className="column is-3 tit">{I18n.get("Total Projeto")}</div>
          <div className="column is-2"></div>
          <div className="column is-2 has-text-right">
            {this.props?.auth?.is_client_or_client_user && (
              <div className="displayValoresWhite">
                <p>
                  <span style={{ fontWeight: "normal" }}>
                    {I18n.get("Total")}:
                    <br />
                  </span>
                  <FormatCurrency
                    amount={
                      quotation.estimateTotal +
                      quotation.estimateTotalOtherTax 
                    }
                    currency={currency}
                  />

                  <br />
                  <span
                    style={{
                      fontSize: 11,
                      marginTop: "-10px",
                      fontWeight: "normal",
                    }}
                  >
                    {quotation?.otherTax > 0
                      ? `(${I18n.get("incluyendo gross up")} ${
                          quotation?.otherTax
                        }%)`
                      : ""}
                  </span>
                </p>
                {quotation.vat > 0 && (
                  <p style={{ fontWeight: "normal", border: 0 }}>
                    <span>{I18n.get("Impostos")} </span> <br />
                    (IVA {quotation.vat}%):{" "}
                    <span>
                      <FormatCurrency
                        amount={quotation.estimateTotalVat}
                        currency={currency}
                      />
                    </span>
                  </p>
                )}
              </div>
            )}
            {!this.props.auth.is_client_or_client_user && (
              <>
                <FormatCurrency
                  amount={quotation.estimateTotal}
                  currency={currency}
                />
                <VatCmp
                  vat={quotation.vat}
                  amount={quotation.estimateTotalVat}
                  currency={currency}
                  otherTax={quotation?.otherTax}
                  amountOtherTax={quotation?.estimateTotalOtherTax}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default QuotationGrandTotal;
