import React from "react";
import { I18n } from "aws-amplify";
import { Tooltip, Icon } from 'antd'
import { useSelector } from "react-redux";
import * as S from "./styles";

export const TableValues = ({ dataValues, isClient, showTooltipOtherTax }) => {
  const auth = useSelector((state) => state.auth);

  const assetTaxCollumn = dataValues?.client?.assetTax ? 1 : 0;
  const otherTaxCollumn = dataValues?.client?.otherTax ? 1 : 0;

  const columns = 2 + assetTaxCollumn + otherTaxCollumn;

  return (
    <>
      <S.Wrapper>
        <S.Table columns={columns}>
          <S.HeaderCell>{I18n.get("Destinatário")}</S.HeaderCell>
          <S.HeaderCell>{I18n.get("Valor")}</S.HeaderCell>

          {dataValues?.client?.assetTax ? (
            <S.HeaderCell>
              {I18n.get("Impostos")}{" "}
              {dataValues?.client?.assetTax
                ? `(IVA ${dataValues?.client?.assetTax}%)`
                : ""}
            </S.HeaderCell>
          ) : null}
          {dataValues?.client?.otherTax ? (
            <S.HeaderCell>
              <div>
                <span>
                  {I18n.get("Taxas")} {""}
                  {dataValues?.client?.otherTax
                    ? `(${I18n.get("Outros")} ${dataValues?.client?.otherTax}%)`
                    : ""}
                </span>
                {auth?.is_partner && showTooltipOtherTax ? (
                  <span style={{ marginLeft: 5 }}>
                    <Tooltip placement="rightTop" title={I18n.get("Valores de Outras Taxas apresentados desmembrados somente para demonstração de representatividade em cadeia. Partners não precisarão faturar tais valores apresentados nesta coluna.")}>
                      <Icon type="question-circle" />
                    </Tooltip>
                  </span>
                ) : null}
              </div>
            </S.HeaderCell>
          ) : null}

          <>
            <S.Cell>{I18n.get("Cliente")}</S.Cell>
            <S.Cell>{dataValues?.client?.value || "--"}</S.Cell>
            {dataValues?.client?.assetTax ? (
              <S.Cell>{dataValues?.client?.taxesValue || "--"}</S.Cell>
            ) : null}
            {dataValues?.client?.otherTax ? (
              <S.Cell>{dataValues?.client?.fees || "--"}</S.Cell>
            ) : null}
          </>

          <>
            <S.Cell>{I18n.get("Parceiro")}</S.Cell>
            <S.Cell>
              {dataValues?.partner?.value || dataValues?.partner?.totalPrice}
            </S.Cell>
            {dataValues?.client?.assetTax ? (
              <S.Cell>{dataValues?.partner?.taxesValue}</S.Cell>
            ) : null}
            {dataValues?.client?.otherTax ? (
              <S.Cell>{dataValues?.partner?.fees}</S.Cell>
            ) : null}
          </>

          <>
            <S.Cell>BPool</S.Cell>
            <S.Cell>{dataValues?.bpool?.value}</S.Cell>
            {dataValues?.client?.assetTax ? (
              <S.Cell>{dataValues?.bpool?.taxesValue}</S.Cell>
            ) : null}
            {dataValues?.client?.otherTax ? (
              <S.Cell>{dataValues?.bpool?.fees}</S.Cell>
            ) : null}
          </>
        </S.Table>

        <S.InfoMessage>
          <p>
            <strong>{I18n.get("Importante:")}</strong>
            {` ${I18n.get("apenas para referência, a fatura é emitida em moeda local e o câmbio é o oficial do cliente na data do pagamento. Os valores em dólares podem variar entre o faturamento e o pagamento.")}`}
          </p>
        </S.InfoMessage>

        <S.ValueTotal>
          <div>
            <p className="text">{I18n.get("Valor total")}:</p>
            {isClient ? (
              <>
                <p className="value">{dataValues?.client?.totalPrice}</p>
              </>
            ) : (
              <p className="value">{dataValues?.partner?.totalPrice}</p>
            )}
          </div>

          {isClient && dataValues?.client?.otherTax ? (
            <span className="grossUp">
              ({I18n.get("incluyendo gross up")} ${dataValues?.client?.otherTax}
              %)
            </span>
          ) : null}
        </S.ValueTotal>
      </S.Wrapper>

      <S.ImportantMessage>
        <p>
          <span>{I18n.get("Nota:")}</span>
          {I18n.get(
            "os prazos estabelecidos nos descritivos são máximos, visando melhor planejamento de recursos e manutenção de preço justo. Não existe um prazo mínimo para conclusão de um projeto, o cronograma é acordado entre as partes."
          )}
        </p>
      </S.ImportantMessage>
    </>
  );
};
