import React, {PureComponent} from 'react';
import ReactPlayer from 'react-player';
import { Link } from "react-router-dom";

class PreviewVideo extends PureComponent {
    state = {  }
    render() { 
        const {item, target} = this.props;

        let videoOrigin = item.content;
        let convert = videoOrigin.split("https://www.loom.com/share/")[1];
       

        return (
            <div className="bp-video-preview">
                <Link to={`/${target}/wikis/${this.props.match.params.scope || item.scope}/${item.categoryId}/${item.contentId}`}>

                <div className="maskvideo"></div>
                    
                    <div className="loomDiv">
                        <img src={`https://cdn.loom.com/sessions/thumbnails/${convert}-with-play.gif`} />
                        {/* <iframe 
                            src={convert}
                            frameborder="0" 
                            webkitallowfullscreen 
                            mozallowfullscreen 
                            allowfullscreen 
                        /> */}
                    </div>
                    <br />
                    <span>{item.title}</span>
                </Link>
                
            </div>
        );
    }
}
 
export default PreviewVideo;