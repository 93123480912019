import React from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import { Table } from "antd";

import NumberFormat from "react-number-format";
import { Excel } from "antd-table-saveas-excel";

const BRL = ({ amount }) => (
  <NumberFormat
    value={amount}
    displayType={"text"}
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale={true}
  />
);

const TableCpm = styled(Table)`
  .ant-table {
    border: solid 1px #ccc;
  }

  .ant-table table {
    border: 0 !important;
  }
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
  .ant-table-thead > tr > th.ant-table-column-sort {
    background: #000;
  }
`;

export function TableProject(data, client) {
  function compareByAlph(a, b) {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }

  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataTable, {
        str2Percent: true,
      })
      .saveAs("Projects.xlsx");
  };

  const columns = [
    {
      title: I18n.get("Projeto"),
      dataIndex: "projectName",
      sorter: (a, b) => compareByAlph(a.projectName, b.projectName),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: I18n.get("Data Faturamento"),
      dataIndex: "installmentCreatedOn",
    },
    {
      title: I18n.get("Tipo"),
      dataIndex: "billingType",
    },
    {
      title: I18n.get("Tipo Projeto"),
      dataIndex: "projectTypeName",
    },
    {
      title: I18n.get("Subtipo"),
      dataIndex: "projectProposalTypeName",
    },
    {
      title: I18n.get("Tipo Extra"),
      dataIndex: "extraTypeName",
    },

    {
      title: I18n.get("Marca"),
      dataIndex: "projectBrandName",
      sorter: (a, b) => compareByAlph(a.projectBrandName, b.projectBrandName),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: I18n.get("Marca / Produto"),
      dataIndex: "projectProductName",
      sorter: (a, b) =>
        compareByAlph(a.projectProductName, b.projectProductName),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: I18n.get("Nota"),
      dataIndex: "feedbackClientRelatedToPartnerAvg",
      sorter: (a, b) =>
        compareByAlph(
          a.feedbackClientRelatedToPartnerAvg,
          b.feedbackClientRelatedToPartnerAvg
        ),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: client ? I18n.get("Parceiro") : I18n.get("Cliente"),
      dataIndex: "clientOrPartner",
      sorter: (a, b) => compareByAlph(a.clientOrPartner, b.clientOrPartner),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: I18n.get("Valor"),
      dataIndex: "billingAmount",
      sorter: (a, b) => compareByAlph(a.billingAmount, b.billingAmount),
      sortDirections: ["descend", "ascend"],
      with: 300,
    },
  ];

  let total = 0;

  for (let i in data) {
    if (client) {
      total += data[i].billingAmountClient;
    } else {
      total += data[i].billingAmountPartner;
    }
  }

  let dataFooter = [
    {
      brand: "",
      brandProduct: "",
      extra: "",
      finishedOn: null,
      marketing: "",
      name: data.length,
      partner: "",
      product: "",
      projectId: "",
      startedOn: "",
      billingAmount: <BRL amount={total} />,
    },
  ];

  let dataTable =
    data &&
    data.map((item, index) => {
      return {
        ...item,
        extra: "-",
        totalSum: item.billingAmountClient,
        billingAmount: client
          ? item.billingAmountClientFmt
          : item?.billingAmountPartnerFmt,
        clientOrPartner: client ? item.partnerName : item.projectClientName,
      };
    });

  return (
    <>
      <p style={{ marginBottom: "20px", overflow: "hidden" }}>
        <button
          className="button bt-bpool pull-right black"
          onClick={handleClick}
        >
          {I18n.get("Baixar")}
        </button>
      </p>
      <TableCpm
        columns={columns}
        dataSource={dataTable}
        scroll={{ y: 240 }}
        pagination={false}
        // footer={() => {
        //     let total = dataTable.reduce((acumulador, item, indice, original) => {
        //         return acumulador + item.totalSum;
        //     }, 0)

        //     return <p style={{textAlign: "right", padding: "0 40px"}}><strong>{I18n.get('Total')}:</strong>  <BRL amount={total} /></p>
        // }}
        footer={() => {
          return (
            <Table
              columns={columns}
              dataSource={dataFooter}
              showHeader={false}
              pagination={false}
            />
          );
        }}
      />
    </>
  );
}
