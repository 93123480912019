import React from 'react';

import { I18n } from 'aws-amplify';

export default class SubmitButtonField extends React.Component {

    constructor(props) {
        super();
    }

    static defaultProps = {
        successIcon: true,
        className: 'button bt-bpool black',
        label: I18n.get("Enviar"),
        suppressLabel: false,
        disabled: undefined
    }

    render() {

        const is_busy = this.props.task && this.props.task.busy;

        const className = is_busy ? [this.props.className, "is-loading"].join(' ') : this.props.className;

        const action = this.props.disabled ? e => e.preventDefault() : this.props.onClick;

        return (

            <div className="field is-narrow">
                {!this.props.suppressLabel && <label className="label is-small"></label>}
                <div className="control">

                    <a {...this.props} onClick={action} className={`${className} ${this.props.dir}`} disabled={this.props.disabled}>
                        {this.props.label}
                    </a>

                </div>
            </div>);
    }
}

