import React from 'react';

import { I18n } from 'aws-amplify';

import numeral from 'numeral';

import './quotation-perc-item-form.css';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import TextField from '../form/text-field';
import NumericField from '../form/numeric-field';
import MoneyField from '../form/money-field';
import PercentageField from '../form/percentage-field';
import SelectField from '../form/select-field';

import constants from "../../constants";

import FormatCurrency from '../ui/format-currency';

const ptBR = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2
});

class QuotationPercItemForm extends React.Component {

    constructor(props) {

        super();
        this.state = {
            fields: {
                itemType: {
                    onChange: this.onChangeForm.bind(this, 'itemType'),
                    successIcon: false,
                    visited: true,
                },
                description: {
                    onChange: this.onChangeForm.bind(this, 'description'),
                    successIcon: false,
                    visited: true,
                },
                percentage: {
                    onChange: this.onChangeForm.bind(this, 'percentage'),
                    successIcon: false,
                    visited: true,
                },
                baseAmount: {
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'baseAmount'),
                    successIcon: false,
                    visited: true,
                },
            },
            form: {
            },
            errors: {},
            task: {},
        };

        this.addItem = this.onAddItem.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);

            nextState.file = {};
        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormValue(prop, value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            quotationId: this.props.quotation.id,
            sectionId: this.props.section.id,
            ...this.state.form,
            baseAmount:  this.props.quotation.workingBudget,
        };

        data.file = this.state.file;

        return data;
    }

    onAddItem(e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.create_universal_quotation_perc_item(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

                if (task.result) {
                    this.props.closeModal();
                }

            });
    }


    onCancel() {

    }

    getTotal() {

        const quotation = this.props.quotation || {};

        let { percentage } = this.state.form;

        if (percentage) {

            const { workingBudget } = quotation;

            const perc = numeral(percentage).value() / 100;
            const total = numeral(workingBudget).value() * (perc / 100);

            return total;
        }
        else {
            return 0;
        }
    }

    render() {

        const quotation = this.props.quotation || {};
        const currency = this.props.currency || {};

        const canEdit = this.props.auth.is_partner && quotation.isOpen;

        return (
            <section className="quotation-item-form" style={{ paddingBottom: '1rem' }}>

                <p className="title is-5">{I18n.get("Adicionar Item Percentual")}</p>

                <div style={{ backgroundColor: '#fff', padding: '1rem' }}>

                    <div className="columns">

                        <div className="column is-4">
                            <SelectField
                                {...this.state.fields.itemType}
                                label={I18n.get("Tipo")}
                                value={this.state.form.itemType}
                                error={this.state.errors.itemType}
                                values={constants.GENERIC_ITEM_TYPE.map((item => ({ code: item.code, label: I18n.get(item.label) })))}
                            />
                        </div>
                        <div className="column is-8">
                            <TextField
                                {...this.state.fields.description}
                                label={I18n.get("Descrição")}
                                value={this.state.form.description}
                                error={this.state.errors.description} />
                        </div>
                    </div>

                    <div className="columns">

                        <div className="column is-4">
                            <PercentageField
                                {...this.state.fields.percentage}
                                label={I18n.get("Percentual")}
                                value={this.state.form.percentage}
                                error={this.state.errors.percentage} />
                        </div>

                        <div className="column is-4">
                            <MoneyField
                                {...this.state.fields.baseAmount}
                                currency={currency}
                                label={I18n.get("Valor Base")}
                                value={quotation.workingBudget}
                                error={this.state.errors.baseAmount}
                                readOnly={true}
                            />
                        </div>
                        <div className="column is-4">
                            <MoneyField
                                label={I18n.get('Total')}
                                value={this.getTotal()}
                                readOnly={true}
                                currency={currency}
                            />
                        </div>

                    </div>

                </div>

                <br />

                <button
                    type="button"
                    task={this.state.task}
                    onClick={this.addItem}
                    className="button bt-bpool is-pulled-right"
                >{I18n.get("Adicionar")}</button>

                <br className="is-clearfix" />

                <br />

            </section>
        );
    }
}

export default QuotationPercItemForm;
