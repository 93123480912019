import styled from "styled-components";

export const Expand = styled.div`
  background: #fafafa;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.101557);
  border-radius: 10px;
  padding: 30px 30px 30px 10px;

  .contentInfo {
    margin: 30px 0 60px;
  }

  .blueText {
    color: #1883ff;
  }

  .buttonsContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    a,
    button {
      margin-left: 20px;
    }
  }
`;
