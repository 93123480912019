import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { FormUtils } from "../../utils/form";

import ReviewStep1 from "./_project-review-1";
import ReviewStep2 from "../_project-review-2";
import ReviewStep3 from "./_project-review-3";
import ReviewStep4 from "./_project-review-4";
import EventQuotationSummary from "./event-quotation-summary";


import { Row, Col, Icon } from "antd";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class ReviewForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {
        isSubmit: true,
      },
      task: {},
    };

    //this.onDrop = this.onDrop.bind(this)
  }

  componentDidMount() {}

  componentcomponentWillUnmount() {}

  getData() {
    var data = {
      clientId: this.props.clientId,
      ...this.props.project,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (FormUtils.isFormField(this.state.form[item])) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  getBundle() {
    var bundle = this.props.bundle || {};

    return bundle;
  }

  getSelected() {
    return {
      bundleId: this.props.bundleId,
      variationId: this.props.variationId,
    };
  }

  submitForm(e) {
    e.preventDefault();

    var data = this.getData();

    this.props.save(data);
  }

  

  getProducts() {
    var items = [];

    this.props.client.brands &&
      this.props.client.brands.map((brand) =>
        brand.products.map((product) =>
          items.push({
            code: product.id,
            label: `${brand.name} - ${product.name}`,
          })
        )
      );

    return items;
  }

  render() {
    

    const brandSelected = this.props.client?.brands?.find(
      (b) => b.id === this.props?.brandId
      );
      
      const productSelected = brandSelected?.products?.find(
        (p) => p.id === this.props.productId
        );

    return (
      <form onSubmit={() => this.submitForm.bind(this)}>
        <Row>
          <Col sm={12}>
            <ReviewStep1 {...this.props} />

            {this.props.universalQuotation && (
              <>
                <br />

                <EventQuotationSummary
                  auth={this.props.auth}
                  quotation={this.props.eventQuotation}
                  currency={this.props.currency}
                />
              </>
            )}

            <ReviewStep2 {...this.props} />

            {this.props.pdfBundle && this.props.project?.status < 6 && (
              <a
                href={this.props.pdfBundle}
                target="_blank"
                className="button bt-bpool black"
                style={{ marginTop: "1rem" }}
              >
                {I18n.get("Download PDF")}
              </a>
            )}

            {this.props.pdfProject && this.props.project?.status >= 6 && (
              <a
                href={this.props.pdfProject}
                target="_blank"
                className="button bt-bpool black"
                style={{ marginTop: "1rem" }}
              >
                {I18n.get("Download PDF")}
              </a>
            )}
          </Col>

          <Col sm={12}>
            {(this.props.demand ||
              (this.props.demandFiles &&
                this.props.demandFiles.length > 0)) && (
              <div>
                <h1 className="title-bpool small">
                  {I18n.get("Demanda geral")}
                </h1>

                <div className="card-box">
                  <div className="card-row">
                    <div className="card">
                      <div className="card-content" style={{ width: "100%" }}>
                        {this.props.demand && <p>{this.props.demand}</p>}

                        {this.props.demandFiles &&
                          this.props.demandFiles.length > 0 && (
                            <div style={{ marginTop: "20px" }}>
                              <p>
                                <strong>{I18n.get("Arquivos:")}</strong>
                              </p>

                              <div style={{ padding: "20px 15px" }}>
                                {this.props.demandFiles &&
                                  this.props.demandFiles.map((item, index) => (
                                    <div
                                      key={index}
                                      class="columns columns itemFile line"
                                    >
                                      <div class="column is-clearfix upload-item">
                                        <a href={item.url} target="_blank">
                                          <Icon
                                            type="file-text"
                                            theme="filled"
                                          />{" "}
                                          &nbsp; {item.name}
                                        </a>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                {this.props.enableLinkButton && (
                  <>
                    <Link
                      to={`/cliente/projetos/${this.props.match.params.projectId}/passo-3`}
                      className="button is-link-to-return"
                    >
                      {I18n.get("Redefinir")}
                    </Link>
                  </>
                )}

                <br />
                <br />
              </div>
            )}

            <ReviewStep3 {...this.props} />

            <br />
            <br />

            <div className="uplift">
              <ReviewStep4 {...this.props} />
            </div>


            <div className="uplift" style={{marginTop: 30}}>
           
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ReviewForm);
