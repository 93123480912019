import { render } from "react-dom";

const renderValue = (value) => {
  return value ? value : null;
};

export const ConvertValuesCreativeScope = ({ data }) => {
  if (data) {
    return {
      partner: {
        totalPrice: renderValue(data?.pricePartnerFmt),
        value: renderValue(data?.pricePartnerFmt),
        taxesValue: renderValue(data?.pricePartnerTaxFmt),
        assetTax: data?.assetTax,
        otherTax: data?.otherTax,
        fees: renderValue(data?.pricePartnerOtherTaxFmt),
      },
      client: {
        totalPrice: renderValue(data?.priceClientWithDiscountWithOtherTaxFmt),
        value: renderValue(data?.priceClientWithDiscountFmt),
        taxesValue: renderValue(data?.priceClientWithDiscountTaxFmt),
        assetTax: data?.assetTax,
        otherTax: data?.otherTax,
        fees: renderValue(data?.priceClientWithDiscountOtherTaxFmt),
      },
      bpool: {
        value: renderValue(data?.priceBPoolComissionFmt),
        taxesValue: renderValue(data?.priceBPoolComissionTaxFmt),
        assetTax: data?.assetTax,
        otherTax: data?.otherTax,
        fees: renderValue(data?.priceBPoolComissionOtherTaxFmt),
      },
      currency: data?.currency,
      cartTotals: {
        priceClientWithDiscountTotalFmt: data?.priceClientWithDiscountWithOtherTaxFmt,
        priceClientWithDiscountFmt: data?.priceClientWithDiscountFmt,
        priceBPoolComissionFmt: data?.priceBPoolComissionFmt,
        priceClientWithDiscountTaxFmt: data?.priceClientWithDiscountTaxFmt,
        priceClientWithDiscountWithOtherTaxFmt: data?.priceClientWithDiscountWithOtherTaxFmt,
      },
      taxes: {
        assetTax: data?.assetTax,
        otherTax: data?.otherTax,
      },
    };
  } else {
    return {};
  }
};
