import { Auth } from 'aws-amplify';

import { AUTH_FORGOT_PASSWORD } from './actions';

import { localize } from '../../utils/errors';


export const api_forgot_password = ({ email }) => {
    return (dispatch, getState) => {

        // if (email && email.length > 0)
        //     email = email.trim().toLowerCase();

        Auth.forgotPassword(email)
            .then(data => {

                //console.dir(data);

                dispatch({
                    type: AUTH_FORGOT_PASSWORD,
                    payload: {
                        email: email,
                        password_reset_required: true,
                        task: {
                            result: true
                        }
                    }
                });

            })
            .catch(err => {

                var error = localize(err);

                dispatch({
                    type: AUTH_FORGOT_PASSWORD,
                    payload: {
                        email: email,
                        password_reset_required: false,
                        task: {
                            result: false,
                            error: error
                        }
                    }
                });

            });
    }

}

export default api_forgot_password;