import React from "react";
import { Tooltip } from "antd";
import * as S from "./styles";

export const ButtonBpool = ({
  text,
  icon,
  theme,
  disabled,
  full,
  loading,
  onClick,
  className,
  size,
  iconMustRenderInRightSide,
  htmlType,
  tooltip,
  tooltipPlacement = "topRight",
}) => {
  if (tooltip) {
    return (
      <Tooltip
        placement={tooltipPlacement}
        title={tooltip}
        overlayClassName="tooltip-status-bpay"
      >
        <S.Button
          className={`button-bpool ${className}`}
          theme={theme}
          disabled={disabled}
          full={full}
          loading={loading}
          onClick={onClick}
          size={size}
          htmlType={htmlType}
        >
          {!iconMustRenderInRightSide && icon && (
            <img src={icon} alt={`ícone de ${text}`} />
          )}

          {text}

          {iconMustRenderInRightSide && icon && (
            <img
              src={icon}
              alt={`ícone de ${text}`}
              style={{ paddingLeft: 10 }}
            />
          )}
        </S.Button>
      </Tooltip>
    );
  } else {
    return (
      <S.Button
        className={`button-bpool ${className}`}
        theme={theme}
        disabled={disabled}
        full={full}
        loading={loading}
        onClick={onClick}
        size={size}
        htmlType={htmlType}
      >
        {!iconMustRenderInRightSide && icon && (
          <img src={icon} alt={`ícone de ${text}`} />
        )}

        {text}

        {iconMustRenderInRightSide && icon && (
          <img
            src={icon}
            alt={`ícone de ${text}`}
            style={{ paddingLeft: 10 }}
          />
        )}
      </S.Button>
    );
  }
};
