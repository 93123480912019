import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const Menu = styled.ul`
  height: 65px;
  float: right;
  padding-right: 15px;

  @media (max-width: 800px) {
    width: 100%;
    // margin-bottom: 60px;
  }
`;

const MenuItem = styled.li`
  display: inline-block;
  margin-left: 40px;
  a {
      display: block;
      height: 63px;
      line-height: 65px;
      color: #000;
      font-weight: bold;
      border-bottom: solid 8px #fff;
      transition: all 0.3s ease;

      &:hover {
         border-bottom: solid 8px #ccc;
      }

      &.active {
         border-bottom: solid 8px #000;
      }
  }

  @media (max-width: 800px) {
      margin-left: 20px;
    a {
        font-size: 14px;
    }
  }
`;

class SubHeaderMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    renderItens = (itens, activeItem) => {

    }
    render() {
        const { itens, activeItem } = this.props;
        return (
            <Menu>
                {itens && itens.map(item => {
                    return (
                        <MenuItem>
                            <Link to={item.link} className={item.activeMenu == activeItem ? "active" : ""}>{item.label}</Link>
                        </MenuItem>
                    )
                })}
            </Menu>
        );
    }
}

export default SubHeaderMenu;
