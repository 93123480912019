import {
    LOGIN,
    LOGOUT
} from '../actions/auth/actions';

import {
    REPORT_GET,
    PROJECTS_GET,
    PROJECT_GET,
    SPECS_GET,
    SPEC_GET,
    BRIEFINGS_GET,
    BRIEFING_GET,
    ASSETS_GET,
    ASSET_GET,
    WARNING_GET,
    HISTORIC_GET,
    HISTORIC_MONTH_GET,
    BRANDS_GET,
    PARTNERS_GET,
    HISTORIC_ITEM_GET,
    ITENS_DELETE,
    PARTNER_GET,
    CLIENTS_GET,
    SAVINGS_GET,
    SAVING_GET,
    CLIENT_GET
} from '../actions/report/actions';

const initialState = {
    errors: {},
    task: {}
};

export default function reportReducer(state = initialState, action) {



    if (action.type == LOGIN || action.type == LOGOUT) return initialState;

    if (action.type.indexOf('report_') == -1) return state;

    switch (action.type) {

        case REPORT_GET:
            return Object.assign({}, state, action.payload);

        case PROJECTS_GET:
            return {
                ...state,
                ...action.payload
            }
        case PROJECT_GET:
            return {
                ...state,
                ...action.payload
            }
        case SPECS_GET:
            return {
                ...state,
                ...action.payload
            }
        case SPEC_GET:
            return {
                ...state,
                ...action.payload
            }
        case ASSETS_GET:
            return {
                ...state,
                ...action.payload
            }
        case ASSET_GET:
            return {
                ...state,
                ...action.payload
            }
        case BRIEFINGS_GET:
            return {
                ...state,
                ...action.payload
            }
        case BRIEFING_GET:
            return {
                ...state,
                ...action.payload
            }
        case SAVINGS_GET:
            return {
                ...state,
                ...action.payload
            }
        case SAVING_GET:
            return {
                ...state,
                ...action.payload
            }
        case WARNING_GET:
            return {
                ...state,
                ...action.payload
            }
        case HISTORIC_GET:
            return {
                ...state,
                ...action.payload
            }
        case HISTORIC_ITEM_GET:
            return {
                ...state,
                ...action.payload
            }
        case HISTORIC_MONTH_GET:
            return {
                ...state,
                ...action.payload
            }
        case BRANDS_GET:
            return {
                ...state,
                ...action.payload
            }
        case PARTNERS_GET:
            return {
                ...state,
                ...action.payload
            }
        case PARTNER_GET:
            return {
                ...state,
                ...action.payload
            }
        case CLIENTS_GET:
            return {
                ...state,
                ...action.payload
            }
        case CLIENT_GET:
            return {
                ...state,
                ...action.payload
            }
        case ITENS_DELETE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
