import React, { Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { PARTNER_API } from '../../actions/partner/actions';

import LandingPage from '../page-templates/landingClean';

import bannerImage3 from '../../image/banner-image3.png';

import AppWrapperSimple from '../../modules/components/AppWrapperRegister';
import FormAddReference from '../../modules/Register/components/formAddReference';
// import FormAddReference from '../components/formAddReference';

import {
    Row,
    Col,
    Input,
    Table,
    Icon,
    notification,
    Select,
    Tooltip,
    Modal,
    Button,
    Form,
    Drawer
} from 'antd';

import styled from 'styled-components';


const Btns = styled.p`
    displa: block;
    width: 80px;
    a {
        margin-left: 10px;
    }
`

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        addClient: data => {
            return dispatch(PARTNER_API.create_client(data));
        },
        updateClient: data => {
            return dispatch(PARTNER_API.update_client2(data));
        },
        delClient: data => {
            return dispatch(PARTNER_API.delete_client(data));
        },
        addReference: data => {
            dispatch(PARTNER_API.create_reference(data));
        },
        updateReference: data => {
            dispatch(PARTNER_API.update_reference(data));
        },
        delReference: data => {
            dispatch(PARTNER_API.delete_reference(data));
        },
        sendReference: data => {
            dispatch(PARTNER_API.send_reference(data));
        },
        update: data => {
            dispatch(PARTNER_API.update(data));
        },
        get: () => {
            dispatch(PARTNER_API.get());
        },
    };
};


class WallPage extends React.Component {

    constructor(props) {

        super();

        this.state = {
            verificationToken: props.match.params.verificationToken
        };
    }

    showEditReference = (item) => {
        this.setState({
            selectedItemReference: item,
            editReference: true,
            visibleReference: true
        });
    }

    addReference = (data) => {
        this.props.addReference(data);
    }

    updateReference = (data) => {
        this.props.updateReference(data);
    }

    closeClient = () => {
        this.setState({
            visibleReference: false,
            selectedItemReference: null,
            sendRegister: false
        })
    }

    componentDidMount = () => {
        this.props.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partner.references && prevProps.partner.references && this.props.partner.references.length > prevProps.partner.references.length) {

            notification.success({
                message: I18n.get(I18n.get('Adicionado com sucesso!')),
                description: I18n.get('A referência foi adicionada ao seu cadastro'),
                duration: 3
            });

            this.closeClient()
        }

        if (this.props.partner.references && prevProps.partner.references && this.props.partner.references.length < prevProps.partner.references.length) {

            notification.success({
                message: I18n.get('Removido com sucesso!'),
                description: I18n.get('A referência foi removida do seu cadastro'),
                duration: 3
            });
        }

        if (this.props.partner.references && prevProps.partner.references && this.props.partner.references != prevProps.partner.references && this.state.selectedItemReference) {

            notification.success({
                message: I18n.get('Atualizado com sucesso!'),
                description: I18n.get('A referência foi atualizada'),
                duration: 3,
            });

            this.setState({
                selectedItemReference: null
            })

            this.closeClient();
        }

    }

    render() {
        const columnsReferences = [
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: I18n.get("E-mail"),
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: I18n.get("Status"),
                dataIndex: 'submittedOn',
                key: 'submittedOn',
                render: (text, record) => {
                    const { item: { submittedOn } } = record;

                    if (submittedOn) {
                        return ("ok")
                    } else {
                        return ("Pendente")
                    }
                }
            },
            {
                title: I18n.get("Ações"),
                dataIndex: 'action',
                key: 'item',
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                        partnerId: this.props.partner.partnerId,
                    }

                    const { item: { submittedOn } } = record;


                    return (
                        <Btns>
                            {!submittedOn &&
                                <Fragment>
                                    <a onClick={() => this.props.sendReference(data)}>
                                        <Tooltip title={I18n.get("Re-enviar")}> <Icon type="retweet" /></Tooltip>
                                    </a>
                                    <a onClick={() => this.props.delReference(data)}>
                                        <Icon type="delete" theme="filled" />
                                    </a>
                                    <a onClick={() => this.showEditReference(record.item)}>
                                        <Icon type="edit" theme="filled" />
                                    </a>
                                </Fragment>
                            }
                        </Btns>
                    );
                }
            },
        ]


        let dataType2 = this.props.partner.references && this.props.partner.references.map((item, index) => {
            return {
                key: index,
                name: item.name,
                email: item.email,
                submittedOn: item.submittedOn,
                item: item
            }
        });

        let refeSubmitYes = this.props.partner.references && this.props.partner.references.filter((s) => s.submittedOn != null)

        return (
            <AppWrapperSimple>


                <div className="hero-body">

                    <div className="container">
                        <br />
                        <br />
                        <br />
                        <br />

                        <div className="columns">

                            <div className="column is-offset-2 is-8">

                                {this.props.partner.references && refeSubmitYes.length >= 3 &&
                                    <Fragment>
                                        <h1 className="subtitle is-4 is-marginless">{I18n.get("Suas referências já te avaliaram!")}</h1>

                                        <div className="colot-line" style={{ backgroundColor: "#000" }}></div>

                                        <br /><br />

                                        <div>
                                            <p>{I18n.get("Agora nossa equipe irá analisar suas informações e o acesso ao cadastro completo será liberado de acordo com a demanda de determinadas especialidades na plataforma.")}</p>

                                            <br />

                                            <p>{I18n.get("Obrigado!")}</p>
                                        </div>
                                    </Fragment>
                                }
                                {this.props.partner.references && refeSubmitYes.length < 3 &&
                                    <Fragment>
                                        <h1 className="subtitle is-4 is-marginless">{I18n.get("Ótimo! Você finalizou o pré-cadastro.")}</h1>

                                        <div className="colot-line" style={{ backgroundColor: "#000" }}></div>

                                        <br /><br />

                                        <div>
                                            <p>{I18n.get("Agora as pessoas apontadas como referências profissionais vão receber um email solicitando uma rápida avaliação do seu trabalho ou da sua empresa.")}</p>

                                            <br />

                                            <p>{I18n.get("Você pode acompanhar o andamento das avaliações pela tabela a seguir, bem como adicionar, alterar emails ou até mesmo re-enviar o convite novamente.")}</p>
                                        </div>
                                    </Fragment>
                                }



                                <br />
                                <br />

                                {this.props.partner.references && refeSubmitYes.length < 3 &&

                                    <div>
                                        <Row>
                                            <Col sm={24}>
                                                <p className="BtnAction">
                                                    <Button
                                                        type="primary"
                                                        onClick={() => this.setState({ visibleReference: true, sendRegister: false })}
                                                        shape="round"
                                                    disabled={false}
                                                    >{I18n.get("+ Adicionar referência")}</Button>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={24}>
                                                <Table scroll={{ x: 640 }} locale={{ emptyText: I18n.get('Sem informação') }} columns={columnsReferences} dataSource={dataType2} />
                                            </Col>
                                        </Row>
                                    </div>
                                }

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                            </div>

                        </div>

                    </div>
                </div>

                <Drawer
                    title={this.state.selectedItemReference ? I18n.get("Editar referência") : I18n.get("Adicionar referência")}
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ selectedItemReference: false, visibleReference: false, sendRegister: false })}
                    destroyOnClose={true}
                    visible={this.state.visibleReference}
                    width="50%"
                >
                    <FormAddReference
                        partner={this.props.partner && this.props.partner}
                        partnerId={this.props.partner.partnerId}
                        update={this.updateReference}
                        action={this.addReference}
                        onCancel={this.closeClient}
                        sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                        partnerReferenceRelation={this.props.bp.partnerReferenceRelation && this.props.bp.partnerReferenceRelation}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        item={this.state.selectedItemReference}
                    />
                </Drawer>


            </AppWrapperSimple>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WallPage);
