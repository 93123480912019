import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column: 16px;
  border: 1px solid;
  border-color: #e8e8e8;
  border-radius: 10px;
  padding: 16px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Infos = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    strong {
      font-weight: 600;
    }
    span {
      margin-left: 5px;
    }
  }
`;
