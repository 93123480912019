import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { PARTNER_API } from "../../../actions/partner/actions";
// import { BP_API } from '../../../actions/bp/actions';
import { PartnerStatus } from "../../../model";
import Loading from "../../../components/pages/loading";
import { QueryString } from "../../../utils/querystring";

const mapStateToProps = (state) => {
  return { partner: state.partner, auth: state.auth, bp: state.bp };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      dispatch(PARTNER_API.confirm(data));
    },
    // get_partner: id => {
    //     dispatch(BP_API.get_partner(id));
    // },
  };
};

class VerifyEmailPre extends React.Component {
  constructor(props) {
    super();

    this.state = {
      locale: "pt",
      verificationToken: props.match.params.verificationToken,
    };
  }

  componentWillMount() {
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    this.setState({
      locale: locale,
    });

    I18n.setLanguage(locale);

    let data = {
      verificationToken: this.props.match.params.verificationToken,
      locale: locale,
    };

    this.props.onSubmit(data);
  }

  render() {
    if (!this.props.partner || !this.props.partner.id)
      return <Loading></Loading>;

    if (
      this.props.partner &&
      this.props.partner.status == PartnerStatus.Approved
    ) {
      return <Redirect to="/login" />;
    }

    return <Redirect to={`/login`} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPre);
