import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

import { Task } from '../../utils/task';

import FillForm from '../page-templates/fillForm';

import SubmitButtonField from '../form/submit-button-field';

import ReviewForm from './review-form';

import Steps from './steps';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class PartnerSignupStep2 extends React.Component {

    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.reference && this.props.reference.task;

        if (!prevState) return;
        if (!task) return;

        var nextState = Object.assign({}, prevState);

        if (task.id != prevState.task.id) {

            nextState.task = task;

            this.setState(nextState);
        }
    }

    onSubmitForm(e) {

        e.preventDefault();

        const form = this.refs.form;

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            form.submitForm(e);
        });
    }

    scrollToTop() {
        const scrollDuration = 600;
        const scrollHeight = window.scrollY;
        const scrollStep = Math.PI / (scrollDuration / 15);
        const cosParameter = scrollHeight / 2;
        let scrollCount = 0;
        let scrollMargin;
        let scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                scrollCount = scrollCount + 1;  
                scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                window.scrollTo(0, (scrollHeight - scrollMargin));
            } else {
                clearInterval(scrollInterval); 
            }
        }, 15);
    }

    render() {

        if (this.props.reference && this.props.reference.submittedOn) {

            return (<Redirect to="/referencia/obrigado" />);
        }

        var errorMap = {
        };

        Object.keys(this.props.reference.errors).map((item) => {

            const error = this.props.reference.errors[item];

            const key = error.step || 'global';

            if (!errorMap[key]) {
                errorMap[key] = []
            }

            errorMap[key].push(error.errorMessage)
        });

        return (
            <FillForm>
                <div className="hero-body has-background-white">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-7">
                                <span className="title-box">{I18n.get("Referência")}</span>
                            </div>
                            <div className="column is-5">
                                <Steps active={2}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="partner-step partner-step-2 reference-step-2">

                    <div className="container reference-container-2">

                        <div className="column is-6 is-offset-3">

                            <h1 className="title">
                                <span>2.</span>{I18n.get("Avaliação sobre")} {this.props.reference && this.props.reference.partnerName}
                            </h1>

                            <ReviewForm ref="form" {...this.props} />      
                            <br />

                            {Object.keys(errorMap).length > 0 && <div className="content is-small">

                                <br /><br />

                                {Object.keys(errorMap).map((item) => {

                                    return (
                                        <p>
                                            <span class="icon has-text-danger">
                                                <i class="fas fa-exclamation"></i>
                                            </span>
                                            <Link to={'/referencia/passo-' + item}>{I18n.get("Passo")} {item}</Link>
                                            <ul>
                                                {errorMap[item].map((errorMessage) => (<li>{errorMessage}</li>))}
                                            </ul>
                                        </p>
                                    );
                                })}

                            </div>}

                            <div className="field is-grouped is-grouped-centered">
                                <p className="control is-expanded" onClick={this.scrollToTop}>
                                    <Link to="/referencia/passo-1" className="button is-black is-outlined is-fullwidth previous">{I18n.get("Voltar")}</Link>
                                </p>
                                <p className="control is-expanded">
                                    <SubmitButtonField
                                        className="button is-black is-fullwidth next"
                                        label={I18n.get("FINALIZAR")}
                                        onClick={this.onSubmitForm.bind(this)}
                                        task={this.state.task}
                                        suppressLabel={true}
                                    />
                                </p>
                            </div>

                        </div>
                    </div>
            </div>


         </FillForm>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSignupStep2);
