import React from 'react';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar';

import moment from 'moment'

import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import TableMeets from '../../v2/pages/Admin/components/TableMeets';




const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        meetings_invites: data => {
            return dispatch(BP_API.meeting_invites(data));
        },
    };
};

// moment.locale("EN"); 
// const localizer = momentLocalizer(moment)
// let allViews = Object.keys(Views).map(k => Views[k])

require('moment/locale/pt.js')
moment.locale('pt-BR');
const localizer = momentLocalizer(moment);

class MeetingsAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {
        const data = {
            date: new Date()
        }
        this.props.meetings_invites(data);
    }

    convertDateToUTC(date) { 
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
    }


    render() {

        let DataMeeting = this.props.bp && this.props.bp.meetingsInvites && this.props.bp.meetingsInvites.map((item) => {
            return {
                ...item,
                start: this.convertDateToUTC(new Date(item.start)), 
                end: this.convertDateToUTC(new Date(item.end))
            }
        });


        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/meetings" className="is-black">{I18n.get("Meetings")}</NavLink></li>
                    </ul>
                </nav>

                <div>
                    <h2 style={{marginBottom: 50}}>
                        {I18n.get("Meetings")}
                    </h2>
                    <TableMeets />
                </div>



                {/* <div className="mainCalendario">
                    {this.props.bp.meetingsInvites &&
                        <Calendar
                            culture='pt-BR'
                            views={['month', 'week']}
                            defaultView="month"
                            localizer={localizer}
                            events={DataMeeting}
                            onNavigate={date => { this.props.meetings_invites({ date: date })}}
                            startAccessor="start"
                            endAccessor="end"
                            eventPropGetter={
                            (event, start, end, isSelected) => {

                            let newStyle = {
                                backgroundColor: "lightgrey",
                                color: '#000',
                                borderRadius: "0px",
                                border: "none"
                            };

                            if (event.status == 1){
                                newStyle.backgroundColor = "#000";
                                newStyle.color = "#fff";
                            }

                            return {
                                className: "",
                                style: newStyle
                            };
                            }
                        }

                        />
                    }
                </div>

                 */}

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingsAdmin);
