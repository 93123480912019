import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_ASSETS_PARTNERS_GET } from './actions';

import { I18n } from 'aws-amplify';

import { notification } from 'antd';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const export_bundle = (data) => {
    return (dispatch, getState) => {


        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.post(`${Config.API_BASE_URL}/bp/client/asset-bundle-export`,data, config)
                .then(response => {
                    notification.success({
                        message: I18n.get("Sucesso!"),
                        description: I18n.get("Solicitação enviada com sucesso. Você irá receber a planilha em seu email"),
                        duration: 3
                    });
                })
                .catch(error => {
                    notification.success({
                        message: I18n.get("Algo deu errado"),
                        description: I18n.get("favor tentar novamente"),
                        duration: 3
                    });
                });

        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default export_bundle;
