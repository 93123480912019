import styled from "styled-components";

export const StyleForm = styled.form`
  width: 500px;
  margin: 60px auto;
`;

export const StyleMain = styled.div`
  max-width: 630px;
  margin: 50px auto 0;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  a,
  button {
    margin-left: 10px;
  }
`;

export const ContainerSelects = styled.div`
  padding: 0;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

export const SelectsTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 25px;
  padding-left: 15px;
`;

export const SelectsSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
`;

export const ContentSelect = styled.div`
  margin-top: 20px;

  .ant-radio-inner {
    border: solid 2px #d9d9d9;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff;
  }

  .ant-radio-inner::after {
    border-color: #1890ff;
    background-color: #1890ff;
  }
`;

export const DisplayErrors = styled.div`
  padding: 0 30px;
  h2 {
    font-weight: bold;
  }

  div {
    margin-top: 30px;
  }
`;

export const WrapperSelectOne = styled.div`
  .ant-select-selection--multiple {
    min-height: 30px !important;
    height: auto !important;
    padding: 10px 15px !important;
  }
`;

export const ContainerRadios = styled.div`
  padding: 30px 0;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 15px;
    }
  }

  .ant-radio-inner {
    border: solid 2px #d9d9d9;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff;
  }

  .ant-radio-inner::after {
    border-color: #1890ff;
    background-color: #1890ff;
  }
`;

export const ListPartnersEmpty = styled.div`
  width: 100%;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  padding: 15px 0;
`;

export const TextSOS = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  strong {
    display: flex;
    position: relative;
    top: 3px;
  }

  img {
    margin-left: 16px;
  }
`;
