import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 30px;
  background: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
`;

export const Content = styled.div`
  padding: 30px;
`;

export const RowInternal = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Code = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 150px;
  padding-right: 20px;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #2f2f2f;

    span {
      color: #1883ff;
    }
  }
`;

export const Divisor = styled.div`
  width: 60px;
  height: 2px;
  background: #000000;
  margin: 5px 0;
`;

export const Values = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const ValuePo = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  display: flex;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 15px;
    line-height: 28px;
    color: #000000;
    margin-left: 5px;
  }
`;

export const ValueFee = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  display: flex;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #000000;
    margin-left: 5px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const ActionCodeEdit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;

  button {
    padding: 10px;
    border: none;
    background: transparent;
    cursor: pointer;

    i {
      color: #1883ff;
    }
  }
`;

export const SubTitle = styled.div`
  margin: 15px 0 30px 0;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  span {
    width: 70px;
    height: 1px;
    background-color: #000000;
    display: block;
  }
`;

export const LabelItem = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
`;

export const ContentItem = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  margin-left: 5px;

  button {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    background-color: transparent;
    border: none;
    padding: 0 5px;
    height: 25px;

    span {
      margin-left: 5px;
    }
  }
`;

export const InfosLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
`;

export const InfosRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
`;

export const AttachmentsContent = styled.div`
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: ${(props) =>
    props.open ? `${props?.itemsRows * 40 + 30}px` : "0"};
  animation: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;

  ul {
    li {
      margin-top: 7.5px;

      a {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        background: #fafafa;
        border-radius: 4px;
        color: #262626;
        font-size: 14px;

        span {
          color: #1883ff;
          font-size: 18px;
        }
      }
    }
  }
`;
