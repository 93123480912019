import React from "react";

import { I18n } from "aws-amplify";

import numeral from "numeral";

import { Task } from "../../utils/task";

import { Icon, Spin, Tooltip } from "antd";

import PercentageField from "../form/percentage-field";
import CheckboxSwitchField from "../form/checkbox-switch-field";

import FormatCurrency from "../ui/format-currency";

require("./quotation-summary-form.css");

const ptBR = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
});

const updateForm = (quotation) => {
  const {
    fee = {},
    tax = {},
    includeTaxOnAssets,
    includeTaxOnFee,
    includeTaxOnItems,
    includeTaxOnGeneric,
    includeTaxOnPerc,
    includeTaxOnPerHour,
  } = quotation || {};

  return {
    fee: fee && fee.percentage,
    feeAmount: fee && fee.amount,
    tax: tax && tax.percentage,
    taxAmount: tax && tax.amount,
    includeTaxOnAssets,
    includeTaxOnFee,
    includeTaxOnItems,
    includeTaxOnGeneric,
    includeTaxOnPerc,
    includeTaxOnPerHour,
  };
};

class QuotationSummaryForm extends React.Component {
  constructor(props) {
    const form = updateForm(props.quotation);

    super();

    this.state = {
      fields: {
        fee: {
          value: "",
          onChange: this.onChangeForm.bind(this, "fee"),
          onBlur: this.onSave.bind(this),
          error: props.errors.fee,
          successIcon: false,
          visited: true,
        },
        feeAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "feeAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.feeAmount,
          successIcon: false,
          visited: true,
        },
        tax: {
          value: "",
          onChange: this.onChangeForm.bind(this, "tax"),
          onBlur: this.onSave.bind(this),
          error: props.errors.tax,
          successIcon: false,
          visited: true,
        },
        taxAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "taxAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.taxAmount,
          successIcon: false,
          visited: true,
        },
        includeTaxOnAssets: {
          id: "includeTaxOnAssets",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnAssets"),
          error: props.errors.includeTaxOnAssets,
          successIcon: false,
        },
        includeTaxOnFee: {
          id: "includeTaxOnFee",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnFee"),
          error: props.errors.includeTaxOnFee,
          successIcon: false,
        },
        includeTaxOnItems: {
          id: "includeTaxOnItems",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnItems"),
          error: props.errors.includeTaxOnItems,
          successIcon: false,
        },
        includeTaxOnGeneric: {
          id: "includeTaxOnGeneric",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnGeneric"),
          error: props.errors.includeTaxOnGeneric,
          successIcon: false,
        },
        includeTaxOnPerc: {
          id: "includeTaxOnPerc",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnPerc"),
          error: props.errors.includeTaxOnPerc,
          successIcon: false,
        },
        includeTaxOnPerHour: {
          id: "includeTaxOnPerHour",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnPerHour"),
          error: props.errors.includeTaxOnPerHour,
          successIcon: false,
        },
      },
      file: {},
      form: {
        ...form,
      },
      errors: {},
      task: {},
      upload_task: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      //console.dir({ task: task, prev: prev });

      var nextState = Object.assign({}, prevState, { task: task });

      const form = updateForm(this.props.quotation);

      nextState.form = {
        ...prevState.form,
        ...form,
      };

      nextState.errors = this.props.errors;

      this.setState(nextState);
    }
  }
  onChangeForm(prop, e) {
    const value = e.target.value;

    const quotation = this.props.quotation || { fee: {}, tax: {} };

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      if (prop == "fee") {
        const feePerc = numeral(value).value() / 100;
        const feeAmount = quotation.externalItemsSubTotal * (feePerc / 100);

        nextState.form.feeAmount = feeAmount;
      }

      if (prop == "tax") {
        let baseAmount = quotation.externalItemsWithDoubleTaxSubTotal;

        if (quotation.includeTaxOnAssets) {
          baseAmount += quotation.internalItemsSubTotal;
        }

        if (quotation.includeTaxOnFee) {
          baseAmount += quotation.fee.amount;
        }

        const taxPerc = numeral(value).value() / 100 / 100;

        const div = 1 - taxPerc;

        const taxAmount = (baseAmount / div) * taxPerc;

        nextState.form.taxAmount = taxAmount;
      }

      return nextState;
    });
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = checked;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => {
        this.onSave();
      }
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      quotationId: this.props.quotation.id,
      fee: {
        percentage: this.state.form.fee,
        amount: this.state.form.feeAmount,
      },
      tax: {
        percentage: this.state.form.tax,
        amount: this.state.form.taxAmount,
      },
      includeTaxOnAssets: this.state.form.includeTaxOnAssets,
      includeTaxOnFee: this.state.form.includeTaxOnFee,
      includeTaxOnItems: this.state.form.includeTaxOnItems,
      includeTaxOnGeneric: this.state.form.includeTaxOnGeneric,
      includeTaxOnPerc: this.state.form.includeTaxOnPerc,
      includeTaxOnPerHour: this.state.form.includeTaxOnPerHour,
    };

    data.file = this.state.file;

    return data;
  }

  onSave() {
    const data = this.getData();

    var save_task = new Task();

    save_task.start();

    this.setState({ task: save_task }, () => {
      this.props.update_universal_quotation(data).then(({ task, errors }) => {
        this.setState({ task: task, errors: errors });
      });
    });
  }

  render() {
    const quotation = this.props.quotation || {};
    const currency = this.props.currency || {};

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    return (
      <>
        <div className="universal-quotation-summary">
          <form>
            {/* <br />

                    <p className="title is-4">{I18n.get("Proposta Comercial")}</p>
                    {canEdit && <>
                        <p class="subtitle is-6">{I18n.get('Adicione os itens internos e os itens de terceiros do seu orçamento')}</p>
                    </>}

                     */}

            <div className="columns head">
              <div className="column is-4 ">{I18n.get("Custos Gerais")}</div>

              <div className="column is-2">{I18n.get("Incluir Impostos")}</div>

              <div className="column is-3">%</div>

              <div className="column is-3">{I18n.get("Valor")}</div>
            </div>

            <div className="columns assets">
              <div className="column is-4 tit">
                {I18n.get("Custos Internos")}
              </div>

              <div className="column is-2 has-text-centered">
                <CheckboxSwitchField
                  {...this.state.fields.includeTaxOnAssets}
                  checked={this.state.form.includeTaxOnAssets}
                  disabled={!canEdit}
                />
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 val">
                {/* {ptBR.format(quotation.internalItemsSubTotal)} */}
                <FormatCurrency
                  amount={quotation.internalItemsSubTotal}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns items">
              <div className="column is-6 tit">
                {I18n.get("Custos Terceiros")}
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 sum">
                <span style={{ opacity: "0.5" }}>
                  {/* {ptBR.format(quotation && quotation.itemsAmountSubTotal)} */}
                </span>
              </div>
            </div>

            <div className="columns items-sub">
              <div className="column is-4 subtit">
                - {I18n.get("Itens com bi-tributação")}
                <Tooltip
                  placement="right"
                  title={
                    "Considerar quando seus fornecedores não puderem trabalhar com o prazo de pagamento do cliente"
                  }
                >
                  <i
                    className="fas fa-question-circle"
                    style={{ marginLeft: "10px" }}
                  />{" "}
                </Tooltip>
              </div>

              <div className="column is-2 has-text-centered">
                <CheckboxSwitchField
                  {...this.state.fields.includeTaxOnItems}
                  checked={this.state.form.includeTaxOnItems}
                  disabled={!canEdit}
                />
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 subval">
                {/* {ptBR.format(quotation.externalItemsWithDoubleTaxSubTotal)} */}
                <FormatCurrency
                  amount={quotation.externalItemsWithDoubleTaxSubTotal}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns items-sub">
              <div className="column is-6 subtit">
                - {I18n.get("Itens sem bi-tributação")}
                <Tooltip
                  placement="right"
                  title={
                    "Considerar quando seus fornecedores podem trabalhar com o prazo de pagamento do cliente. Faturamento direto via BPool."
                  }
                >
                  <i
                    className="fas fa-question-circle"
                    style={{ marginLeft: "10px" }}
                  />{" "}
                </Tooltip>
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 subval">
                {/* {ptBR.format(quotation.externalItemsWithoutDoubleTaxSubTotal)} */}
                <FormatCurrency
                  amount={quotation.externalItemsWithoutDoubleTaxSubTotal}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns assets">
              <div className="column is-4 tit">{I18n.get("Itens Livres")}</div>

              <div className="column is-2 has-text-centered">
                <CheckboxSwitchField
                  {...this.state.fields.includeTaxOnGeneric}
                  checked={this.state.form.includeTaxOnGeneric}
                  disabled={!canEdit}
                />
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 val">
                <FormatCurrency
                  amount={quotation.genericItemsSubTotal}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns assets">
              <div className="column is-4 tit">
                {I18n.get("Itens Percentuais")}
              </div>

              <div className="column is-2 has-text-centered">
                <CheckboxSwitchField
                  {...this.state.fields.includeTaxOnPerc}
                  checked={this.state.form.includeTaxOnPerc}
                  disabled={!canEdit}
                />
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 val">
                <FormatCurrency
                  amount={quotation.percItemsSubTotal}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns assets">
              <div className="column is-4 tit">
                {I18n.get("Itens por Hora")}
              </div>

              <div className="column is-2 has-text-centered">
                <CheckboxSwitchField
                  {...this.state.fields.includeTaxOnPerHour}
                  checked={this.state.form.includeTaxOnPerHour}
                  disabled={!canEdit}
                />
              </div>

              <div className="column is-3"></div>

              <div className="column is-3 val">
                <FormatCurrency
                  amount={quotation.perHourItemsSubTotal}
                  currency={currency}
                />
              </div>
            </div>

            {/* <div className="columns assets">

                        <div className="column is-6 tit">{I18n.get("Demais Items")}</div>

                        <div className="column is-3">
                        </div>

                        <div className="column is-3 val">
                            <FormatCurrency amount={quotation.otherItemsSubTotal} currency={currency} />
                        </div>

                    </div> */}

            <div className="columns fee">
              <div className="column is-4 tit">{I18n.get("Fee Comissão")}</div>

              <div className="column is-2 has-text-centered">
                <CheckboxSwitchField
                  {...this.state.fields.includeTaxOnFee}
                  checked={this.state.form.includeTaxOnFee}
                  disabled={!canEdit}
                />
              </div>

              {canEdit && (
                <>
                  <div className="column is-3 edit">
                    <PercentageField
                      {...this.state.fields.fee}
                      value={this.state.form.fee}
                      error={this.state.errors["fee.percentage"]}
                    />
                  </div>
                </>
              )}
              {!canEdit && (
                <>
                  <div className="column is-3 val">
                    <p>{quotation.fee && quotation.fee.percentageFmt}</p>
                  </div>
                </>
              )}

              <div className="column is-3 sum">
                {/* {ptBR.format(this.state.form.feeAmount)} */}
                <FormatCurrency
                  amount={this.state.form.feeAmount}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns tax">
              <div className="column is-6 tit">{I18n.get("Impostos")}</div>

              {canEdit && (
                <>
                  <div className="column is-3 edit">
                    <PercentageField
                      {...this.state.fields.tax}
                      value={this.state.form.tax}
                      error={this.state.errors["tax.percentage"]}
                    />
                  </div>
                </>
              )}
              {!canEdit && (
                <>
                  <div className="column is-3 val">
                    <p>{quotation.tax && quotation.tax.percentageFmt}</p>
                  </div>
                </>
              )}

              <div className="column is-3 sum">
                {/* {ptBR.format(this.state.form.taxAmount)} */}
                <FormatCurrency
                  amount={this.state.form.taxAmount}
                  currency={currency}
                />
              </div>
            </div>

            <div className="columns total">
              {" "}
              <div className="column is-3 is-offset-6 sum">
                {I18n.get("Total")}
              </div>
              <div className="column is-3 sum">
                <Spin
                  delay={500}
                  spinning={this.state.task.busy === true}
                  style={{ color: "#ffffff", marginRight: "1rem" }}
                  indicator={<Icon type="loading" />}
                />
                {/* {ptBR.format(quotation.totalExcludingBPCommission)} */}

                <FormatCurrency
                  amount={quotation.totalExcludingBPCommission}
                  currency={currency}
                />
              </div>
            </div>

            <br />

            {this.props.auth.is_partner && (
              <>
                <div className="field">
                  <label className="label is-small">
                    {I18n.get("Incluir impostos")} *
                    <div style={{ marginTop: "0.5rem", opacity: "0.5" }}>
                      {I18n.get(
                        "Consulte seu contrato e verifique se os preços acordados para itens internos são brutos ou líquidos"
                      )}
                    </div>
                  </label>
                </div>

                <br />

                {/* <div className="columns">
                            <div className="column is-6" style={{ border: 'none' }}>

                                <CheckboxSwitchField
                                    {...this.state.fields.includeTaxOnAssets}
                                    label={I18n.get('Itens Internos')}
                                    checked={this.state.form.includeTaxOnAssets}
                                    disabled={!canEdit}
                                />
                            </div>
                            <div className="column is-6" style={{ border: 'none' }}>

                                <CheckboxSwitchField
                                    {...this.state.fields.includeTaxOnFee}
                                    label={I18n.get('Fee Comissão')}
                                    checked={this.state.form.includeTaxOnFee}
                                    disabled={!canEdit}
                                />

                            </div>

                        </div> */}
              </>
            )}
          </form>
        </div>
      </>
    );
  }
}

export default QuotationSummaryForm;
