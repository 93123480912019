import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import WhatifWrapper from '../components/WhatifWrapper';

import SubHeader from '../components/SubHeader';

import PageSuccess from '../components/PageSuccess';

class CounterProposalSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <WhatifWrapper>
                <SubHeader />
                <PageSuccess>
                    <Fragment>
                        <h2>Enviado!</h2>
                        <p>Sua proposta foi enviada com sucesso. Aguarde o feedback do Criativo. Notificaremos via e-mail e celular.</p>
                    </Fragment>
                </PageSuccess>
            </WhatifWrapper>
        );
    }
}

export default CounterProposalSuccess;
