import React, { useState } from 'react';
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { Icon, notification } from 'antd'
import { useMeetings } from '../../../.././../hooks/useMeetings/useMeetings.hook';
import { ButtonBpool } from '../../../../../components/UI/ButtonBpool'
import * as S from './styles';

import IcoUser from './icons/ico-user.svg';
import IcoCalendar from './icons/ico-calendar.svg';
import IcoConfirmada from './icons/ico.svg';
import IcoAguardando from './icons/ico-aguardando.svg';
import IcoRealizadas from './icons/ico-realizadas.svg';

const Card = ({ cardData, windowWidth, reload }) => {
  const auth = useSelector((state) => state.auth);
  const { partnerAcceptMeeting } = useMeetings();
  const [isLoadingAccepting, setIsLoadingAccepting] = useState(false)

  const renderIco = (status) => {
    if (status === 'Aguardando') {
      return <img src={IcoAguardando} alt="Icone Check" />;
    } else if (status === 'Realizadas') {
      return <img src={IcoRealizadas} alt="Icone Check" />;
    } else {
      return <img src={IcoConfirmada} alt="Icone Check" />;
    }
  };
  const renderLink = (status) => {
    if (status === 'Confirmada') {
      return (
        <a href={`https://meeting.bpool.co/${cardData.id}`}>
          <div>{I18n.get("Ir para Videocalls")}</div>
        </a>
      );
    } else {
      return <S.DisabledLink>{I18n.get("Ir para Videocalls")}</S.DisabledLink>;
    }
  };

  const renderStatus = (status) => {
    // Aguardando = 0
    // Confirmado = 1
    // Declinado = 2

    if (status === 0) {
      return 'Aguardando';
    }

    if (status === 1) {
      return 'Confirmada';
    }

    if (status === 2) {
      return 'Declinada';
    }
  };

  const acceptMeetingPartner = async () => {
    setIsLoadingAccepting(true)

    const dataSend = {
      meetingId: cardData?.id,
      inviteId: cardData?.invites[0]?.inviteId,
      projectId: cardData?.projectId,
      startTime: cardData?.startTime,
      endTime: cardData?.endTime,
      meetingDate: cardData?.meetingDate,
    }

    try {
      const response = await partnerAcceptMeeting(dataSend)
      notification.success({
        message: I18n.get('Aceito!'),
        description: I18n.get('Meeting aceito com sucesso.'),
        duration: 3
      });
      reload()
    } catch (error) {
      notification.error({
        message: I18n.get('ops! Algo deu errado.'),
        description: error?.response?.status === 400 ? error?.response?.data : I18n.get('Erro ao aceitar o meeting.'),
        duration: 3
      });
    }

    setIsLoadingAccepting(false)
  };

  return (
    <S.StyledCard
      style={{ width: 300 }}
      bordered
      cover={
        <img
          alt="example"
          src={cardData?.invites[0]?.partnerLogo}
        />
      }
      actions={[
        <S.ActionCard>
          {cardData?.invites[0]?.inviteStatus === 0 && auth?.is_partner ? (
            <ButtonBpool
              text={I18n.get('Aceitar meeting')}
              theme="primary"
              full
              onClick={acceptMeetingPartner}
              loading={isLoadingAccepting}
            />
          ) : (
            <>
              {renderLink(renderStatus(cardData?.invites[0]?.inviteStatus))}
            </>
          )}
        </S.ActionCard>
      ]}
    >
      <S.CardContent>
        <div className='title-partner'>
          <strong>{`${I18n.get("Parceiro")}:`}</strong><span>{cardData?.invites[0]?.partnerCompany}</span>
        </div>
        <div>
          <strong>{`${I18n.get("Projeto")}:`}</strong><span>{cardData?.projectName}</span>
        </div>
        <S.DateCard windowWidth={windowWidth}>
          <S.Ico>
            <img src={IcoCalendar} />
          </S.Ico>
          <div>
            <strong>{cardData.meetingDate}</strong>
            <div><span>{`${cardData.startTime} - ${cardData.endTime}`}</span></div>
          </div>
        </S.DateCard>
        <S.NameCard windowWidth={windowWidth}>
          <S.Ico>
            <img src={IcoUser} />
          </S.Ico>
          <div>
            <strong>{cardData?.invites[0]?.partnerName}</strong>
            <div><span>{cardData?.invites[0]?.partnerCompany}</span></div>
          </div>
        </S.NameCard>
        <S.StatusCard
          status={renderStatus(cardData?.invites[0]?.inviteStatus)}
          windowWidth={windowWidth}
        >
          <S.Ico>
            {renderIco(renderStatus(cardData?.invites[0]?.inviteStatus))}
          </S.Ico>
          <div>
            <p>{renderStatus(cardData?.invites[0]?.inviteStatus)}</p>
          </div>
        </S.StatusCard>
      </S.CardContent>
    </S.StyledCard>
  );
};

export default Card;
