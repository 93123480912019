import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { LEADER_EXPERTISE_CREATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_leader_create_expertise = (data) => {
    return (dispatch, getState) => {

        return axios.post(`${Config.API_BASE_URL}/leader/${data.PartnerMarketExpertise.leaderId}/market-expertise`, data)
            .then(response => {

                dispatch({
                    type: LEADER_EXPERTISE_CREATE,
                    payload: {
                        ...response.data,
                        errors: {},
                        task: {
                            result: true
                        }
                    }
                });

            })
            .catch(error => {

                dispatch({
                    type: LEADER_EXPERTISE_CREATE,
                    payload: {
                        errors: error.response.data,
                        task: {
                            result: false
                        }
                    }
                });
            });
    };
}

export default api_leader_create_expertise;
