import React from 'react';

import { I18n } from 'aws-amplify';

const FieldIsOptionalMark = (props) => {

    return (<span style={{ opacity: '0.5' }}> - <i>{I18n.get('opcional')}</i></span>)
};

export default FieldIsOptionalMark;

