import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Modal, message, Radio } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useBp } from '../../../../hooks/useBp/useBp.hook';
import { Input } from '../../../../components/UI/Input';
import { InputFlag } from '../../../../components/UI/InputFlag';
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { SelectOne } from "../../../../components/UI/SelectOne";
import Loading from "../../../../components/UI/Loading";
import { BP_API } from "../../../../../actions/bp/actions";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook"
import { hasError, clearErrorInput, errorObjToArr } from "../../../RegistrationFlow/utils/errorsInput";
import listMasterWillBe from "./listMasterWillBe.json";

import * as S from "./styles";

export const ChangeMaster = () => {
  const bp = useSelector((state) => state?.bp);
  const dispatch = useDispatch();
  const { changeUserMasterPartner } = useBp();
  const { getTeamRegistration } = useRegistrationFlowFull();
  const [usersList, setUsersList] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [typeCheckbox, setTypeCheckbox] = useState(1);
  const [isLoadingInputPhone, setIsLoadingInputPhone] = useState(false);
  const [isLoadingLists, setIsLoadingLists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [inputsForm, setInputsForm] = useState({});
  const [userSelect, setUserSelect] = useState(null);
  const [userMasterDetails, setUserMasterDetails] = useState({});
  const [masterWillBe, setMasterWillBe] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);

  const showModal = () => {
    setTypeCheckbox(1);
    setVisibleModal(visibleModal ? false : true);
  };

  const clearInputs = async () => {
    setInputsForm({});
    setUserSelect(null);
    setUserMasterDetails({});
    setMasterWillBe(null);
  }

  const handleCancel = async () => {
    setVisibleModal(false);
    setIsLoadingInputPhone(true);
    setIsLoadingLists(true);
    await clearInputs();
    setIsLoadingInputPhone(false);
    setIsLoadingLists(false);
    setTypeCheckbox(1);
  };

  const getPartner = async () => {
    dispatch(BP_API.get_partner(bp?.partner?.partnerId));
  };

  const handleSend = async () => {
    let dataForm = {};
    if (typeCheckbox === 1) {
      dataForm = {
        partnerId: bp?.partner?.partnerId,
        id: userMasterDetails.code,
        action: masterWillBe,
      };
    }

    if (typeCheckbox === 2) {
      dataForm = {
        partnerId: bp?.partner?.partnerId,
        name: inputsForm.name,
        email: inputsForm.email,
        nameAlias: inputsForm.nameAlias,
        phoneNumber: inputsForm.phoneNumber,
        countryCodePhoneNumber: inputsForm.countryCodePhoneNumber ||  "BRA",
        action: masterWillBe,
      };
    }

    const response = await changeUserMasterPartner({ form: dataForm });
    
    if (response.success) {
      setIsLoading(true);
      message.success(I18n.get("Updated successfully!"));
      setIsLoadingInputPhone(true);
      await handleCancel();
      await getPartner();
      setIsLoadingInputPhone(false);
      setIsLoading(false);
    } else {
      message.error(I18n.get("An error occurred while submitting."));
      const errs = await errorObjToArr(response?.errors);
      setInputsErrorsForm(errs);
    }
  };

  const handleOk = async () => {
    setIsSending(true);
    await handleSend();
    setIsSending(false);
  };

  const onChangeInput = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const onChangeInputPhone = ({ valuePhone, valuePhoneCountry }) => {
    setInputsForm({
      ...inputsForm,
      phoneNumber: valuePhone ? valuePhone : null,
      countryCodePhoneNumber: valuePhoneCountry ? valuePhoneCountry : null,
    });
  };

  const onChangeSelectType = (e) => {
    setTypeCheckbox(e.target.value);
    clearInputs();
  };

  const onChangeUserSelect = (user) => {
    const getUser = usersList.find((item) => item.code === user);
    if (getUser) {
      setUserMasterDetails(getUser)
    }
    setUserSelect(user);
  };

  const onChangeMasterWillBe = (master) => {
    setMasterWillBe(master);
  };

  const wasDisabledSave = () => {
    if (typeCheckbox === 1) {
      if (!userSelect || !masterWillBe) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    } else {
      if (!inputsForm?.name || !inputsForm?.email || !inputsForm?.phoneNumber || !inputsForm?.nameAlias || !masterWillBe) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
  };

  const loadLists = async () => {
    setIsLoadingLists(true);
    const response = await getTeamRegistration({ admin: true });

    if (response.success) {
      const list = response?.data?.map((item) => {
        if (item?.username || item?.completedOn !== null) {
          return {
            code: item?.id || null,
            label: item?.name || null,
            email: item?.email || null,
            phoneNumber: item?.phoneNumber || null,
            nameAlias: item?.nameAlias || null,
          }
        } else {
          return null;
        }
      }).filter((item) => item !== null);

      setUsersList(list);
      setIsLoadingLists(false);
    }
  };

  useEffect(() => {
    wasDisabledSave();
  }, [typeCheckbox, inputsForm, userSelect, masterWillBe]);

  useEffect(() => {
    if (visibleModal) {
      loadLists();
    }
  }, [visibleModal]);

  return (
    <>
      <div className="card">
        <div className="card-content">
          <Row gutter={1}>
            <Col sm={24}>
              <h3 className="title is-4">{I18n.get("Change user master")}</h3>
              <S.Content>
                <Row>
                  <Col xs={24} style={{ padding: 0 }}>
                    {isLoading ? (
                      <Loading
                        text={I18n.get("Loading...")}
                        sizeIcon={14}
                        sizeText={14}
                      />
                    ) : (
                      <S.Infos>
                        <p><strong>{I18n.get("Name")}:</strong>{bp?.partner?.name}</p>
                        <p><strong>{I18n.get("Email")}:</strong>{bp?.partner?.email}</p>
                        <p><strong>{I18n.get("Name alias")}:</strong>{bp?.partner?.nameAlias}</p>
                        <p><strong>{I18n.get("Phone number")}:</strong>{bp?.partner?.phoneNumber}</p>
                      </S.Infos>
                    )}
                  </Col>
                  <Col xs={24} style={{ padding: 0 }}>
                    <ButtonBpool
                      text={I18n.get('Change user master')}
                      theme="primary"
                      full={false}
                      onClick={showModal}
                    />
                  </Col>
                </Row>
              </S.Content>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        centered
        width={700}
        title={I18n.get('Change user master')}
        visible={visibleModal}
        onCancel={handleCancel}
        footer={[
          <S.ActionsButtons>
            <ButtonBpool
              text={I18n.get('Cancel')}
              theme="secondary"
              full={false}
              onClick={handleCancel}
            />
            <ButtonBpool
              text={I18n.get('Save')}
              theme="primary"
              full={false}
              onClick={handleOk}
              loading={isSending}
              disabled={buttonDisabled}
            />
          </S.ActionsButtons>
        ]}
      >
        <div>
          <Row>
            <Col xs={24} sm={24}>
              <S.Title>
                <p>{I18n.get("Is the new master a user or leader of the platform?")}</p>
              </S.Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24}>
              <S.ContentRadios>
                <Radio.Group onChange={onChangeSelectType} value={typeCheckbox}>
                  <Radio value={1}>{I18n.get("Yes")}</Radio>
                  <Radio value={2}>{I18n.get("No")}</Radio>
                </Radio.Group>
              </S.ContentRadios>
            </Col>
          </Row>

          {typeCheckbox === 1 ? (
            <Row>
              <Col xs={24} sm={24}>
                {isLoadingLists ? (
                  <S.ContentLoading>
                    <Loading
                      text={I18n.get("Loading...")}
                      sizeIcon={14}
                      sizeText={14}
                      align={"left"}
                    />
                  </S.ContentLoading>
                ) : usersList?.length === 0 ? (
                  <S.NotUsersList>
                    <p>{I18n.get("There are no users completeds to select.")}</p>
                    <CloseOutlined />
                  </S.NotUsersList>
                ) : (
                  <SelectOne
                    options={usersList}
                    value={userSelect}
                    onChange={(value) => onChangeUserSelect(value)}
                    placeholder={I18n.get('Select user')}
                    disabled={usersList?.length === 0}
                    error=""
                  />
                )}
              </Col>
              <Col xs={24} sm={24}>
                <S.Subtitle>
                  <p>
                    {I18n.get("You selected to be master")}:
                  </p>
                </S.Subtitle>
                <div>
                  <p><strong>{I18n.get("Name")}: </strong>{userMasterDetails?.label || "---"}</p>
                  <p><strong>{I18n.get("Email")}: </strong>{userMasterDetails?.email || "---"}</p>
                </div>
              </Col>
              <Col xs={24} sm={24}>
                <S.Divisor style={{ margin: "30px 0" }} />
              </Col>
              <Col xs={24} sm={24}>
                <S.Title>
                  <p>{I18n.get("The current master will be")}</p>
                </S.Title>
              </Col>
              <Col xs={24} sm={24} style={{ marginBottom: 30 }}>
                <SelectOne
                  options={listMasterWillBe}
                  value={masterWillBe}
                  onChange={(value) => onChangeMasterWillBe(value)}
                  placeholder={I18n.get('The current master will be')}
                  error=""
                  disabled={userSelect ? false : true}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={24} style={{ height: 80 }}>
                <Input
                  placeholder={I18n.get('Name')}
                  label
                  required
                  value={inputsForm?.name}
                  onChange={(value) =>
                    onChangeInput('name', value)
                  }
                  error=""
                />
              </Col>
              <Col xs={24} style={{ height: 80 }}>
                <Input
                  placeholder={I18n.get('E-mail')}
                  label
                  required
                  value={inputsForm?.email}
                  onChange={(value) =>
                    onChangeInput('email', value)
                  }
                  error={hasError({
                    field: "email",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "email",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              </Col>
              <Col xs={24} style={{ height: 80 }}>
                <Input
                  placeholder={I18n.get('Apelido')}
                  label
                  required
                  value={inputsForm?.nameAlias}
                  onChange={(value) =>
                    onChangeInput('nameAlias', value)
                  }
                  error=""
                />
              </Col>
              <Col xs={24} style={{ height: 80 }}>
                <InputFlag
                  id="phoneNumber"
                  label={I18n.get("Phone number")}
                  defaultValue={inputsForm?.phoneNumber}
                  flagCodeDefault={bp?.partner?.country?.code}
                  onChange={(value) => onChangeInputPhone({ valuePhone: value?.value, valuePhoneCountry: value?.country })}
                  required
                  loading={isLoadingInputPhone}
                  error=""
                />
              </Col>
              <Col xs={24} sm={24}>
                <S.Divisor style={{ margin: "30px 0" }} />
              </Col>
              <Col xs={24} sm={24}>
                <S.Title>
                  <p>{I18n.get("The current master will be")}</p>
                </S.Title>
              </Col>
              <Col xs={24} sm={24} style={{ marginBottom: 30 }}>
                <SelectOne
                  options={listMasterWillBe}
                  value={masterWillBe}
                  onChange={(value) => onChangeMasterWillBe(value)}
                  placeholder={I18n.get('The current master will be')}
                  error=""
                />
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </>
  );
};
