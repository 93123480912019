import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import { useRegistrationFlowFull } from "../../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { DataBank } from "../../../../../components/Shared/DataBank";

import * as S from "./styles";

export const BillingAccounts = ({ contextPage }) => {
  const state = useSelector((state) => state);
  const {
    getBillingData,
    addBillingData,
    updateBillingData,
    deleteBillingData,
  } = useRegistrationFlowFull();
  const [isLoading, setIsLoading] = useState(false);
  const [dataBilling, setDataBilling] = useState([]);

  const prioritizeMain = (array) => {
    if (array?.length) {
      array.sort((a, b) => {
        if (a.isMain && !b.isMain) {
          return -1;
        } else if (!a.isMain && b.isMain) {
          return 1;
        }
        return 0;
      });
      return array;
    }
  };

  const loadBillingAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await getBillingData({
        admin: false,
        partnerId: state?.partner?.id,
      });
      const sortData = prioritizeMain(response?.data?.data);
      setDataBilling(sortData);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadBillingAccounts();
  }, []);

  return (
    <>
      <Row id="bankDetails">
        <Col xs={24} style={{ marginTop: 30 }}>
          <S.TitleSectionForm style={{ marginBottom: 0 }}>
            {I18n.get("Dados Bancários")}
          </S.TitleSectionForm>
        </Col>
        <Col md={24}>
          <DataBank
            data={dataBilling}
            isLoading={isLoading}
            countryCode={
              state?.partner?.signupLocale !== "pt" ? "LATAM" : "BRA"
            }
            handleRefresh={loadBillingAccounts}
            isAdmin={false}
            addBillingData={addBillingData}
            updateBillingData={updateBillingData}
            deleteBillingData={deleteBillingData}
            isFullRegister={true}
          />
        </Col>
      </Row>
    </>
  );
};
