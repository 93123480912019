import React from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { getProfileType } from "../../../../utils/getProfileType";

import * as S from "./styles";

export const Menu = () => {
  const typeUser = getProfileType();

  return (
    <aside class="menu">
      <p
        class="menu-label"
        style={{
          fontSize: 15,
          marginTop: 40,
          padding: 10,
          color: "#fff",
          background: "#000",
        }}
      >
        {I18n.get("Sobre mim")}
      </p>
      <ul className="menu-list">
        {typeUser === "PartnerUser" && (
          <li>
            <a class="is-black" href="#/partner/edit-profile/user-profile">
              <span style={{ fontWeight: 400 }}>1.</span>
              <span
                className="title-menu"
                style={{
                  textTransform: "inherit",
                  fontWeight: 400,
                  paddingLeft: 5,
                }}
              >
                {I18n.get("My Profile")}
              </span>
            </a>
          </li>
        )}

        {typeUser !== "PartnerUser" && (
          <li>
            <a class="is-black" href="#/partner/edit-profile/my-profile">
              <span style={{ fontWeight: 400 }}>1.</span>
              <span
                className="title-menu"
                style={{
                  textTransform: "inherit",
                  fontWeight: 400,
                  paddingLeft: 5,
                }}
              >
                {I18n.get("My Profile")}
              </span>
            </a>
          </li>
        )}
      </ul>

      <p
        class="menu-label"
        style={{
          fontSize: 15,
          marginTop: 40,
          padding: 10,
          color: "#fff",
          background: "#000",
        }}
      >
        {I18n.get("Sobre a Empresa")}
      </p>

      <ul className="menu-list">
        <li>
          <a class="is-black" href="#/partner/edit-profile/company-profile">
            <span style={{ fontWeight: 400 }}>1.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Company Profile")}
            </span>
          </a>
        </li>
        <li>
          <a class="is-black" href="#/partner/edit-profile/company-details">
            <span style={{ fontWeight: 400 }}>2.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Company Details")}
            </span>
          </a>
        </li>
        <li>
          <a class="is-black" href="#/partner/edit-profile/billing-data">
            <span style={{ fontWeight: 400 }}>3.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Billing Data")}
            </span>
          </a>
        </li>
        <li>
          <a
            class="is-black"
            href="#/partner/edit-profile/diversity-and-inclusion"
          >
            <span style={{ fontWeight: 400 }}>4.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("D&I Qualification")}
            </span>
          </a>
        </li>
        <li>
          <a class="is-black" href="#/partner/edit-profile/business-type">
            <span style={{ fontWeight: 400 }}>5.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Business Type")}
            </span>
          </a>
        </li>
        <li>
          <a class="is-black" href="#/partner/edit-profile/industry">
            <span style={{ fontWeight: 400 }}>6.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Industry")}
            </span>
          </a>
        </li>
        <li>
          <a
            class="is-black"
            href="#/partner/edit-profile/speciality-proof-and-endersement"
          >
            <span style={{ fontWeight: 400 }}>7.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Speciality proof & Endersement")}
            </span>
          </a>
        </li>
        <li>
          <a class="is-black" href="#/partner/edit-profile/awards">
            <span style={{ fontWeight: 400 }}>8.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Awards and Certificates")}
            </span>
          </a>
        </li>
        <li>
          <a class="is-black" href="#/partner/edit-profile/customers">
            <span style={{ fontWeight: 400 }}>9.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Clients")}
            </span>
          </a>
        </li>
        <li>
          <a class="is-black" href="#/partner/edit-profile/team-registration">
            <span style={{ fontWeight: 400 }}>10.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Team Registration")}
            </span>
          </a>
        </li>

        <li>
          <a class="is-black" href="#/partner/edit-profile/summary">
            <span style={{ fontWeight: 400 }}>11.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Summary")}
            </span>
          </a>
        </li>
        <li>
          <a class="is-black" href="#/partner/edit-profile/language">
            <span style={{ fontWeight: 400 }}>12.</span>
            <span
              className="title-menu"
              style={{
                textTransform: "inherit",
                fontWeight: 400,
                paddingLeft: 5,
              }}
            >
              {I18n.get("Language")}
            </span>
          </a>
        </li>
      </ul>
    </aside>
  );
};
