import styled from "styled-components";

export const StyledTitleIcon = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;

  color: #000000;

  b {
    color: #1883ff;
  }
  span {
    padding: 5px 0 0 15px;
    display: inline-block;
    vertical-align: middle;
  }
`;

export const StyledBoxAssets = styled.div`
  padding-right: 10px;
  border-right: solid 1px #000;
  min-height: 500px;

  .ant-pagination {
    margin-top: 40px;
    text-align: center;
  }
`;

export const StyledAssetsScroll = styled.div`
  border-top: 1px solid #434343;
  max-height: 500px;
  overflow: auto;
  margin-top: 30px;
  padding: 10px 0;
  padding-right: 30px;
`;

export const StyleMain = styled.div`
  max-width: 630px;
  margin: 50px auto 0;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  a {
    margin-left: 10px;
  }
`;

export const StyledCart = styled.div`
  background: #d8d8d8;
  padding: 15px;
  margin-top: 20px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 500px;
`;

export const StyledCartFooter = styled.div``;
export const StyledCartTotal = styled.p`
  border-top: 1px solid #434343;
  text-align: right;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  padding-top: 20px;

  span {
    color: #1883ff;
  }
`;

export const StyledCartScroll = styled.div`
  height: 350px;
  overflow: auto;
  padding: 20px 20px 20px 0;
`;
export const StyledCartItem = styled.div`
  display: flex;
  justify-content: space-between;

  .close {
    width: 40px;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }

  .boxAsset {
    width: calc(100% - 40px);
  }
`;

export const StyledCartVat = styled.p`
  text-align: right;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 41px;
  color: #000000;

  span {
    color: #1883ff;
  }
`;

export const TopFilters = styled.div`
  border-bottom: solid 1px #000;
  padding-top: 60px;
  margin-bottom: 60px;
`;

export const BoxSelects = styled.div`
  border-bottom: solid 1px #434343;
  padding-bottom: 20px;
`;

export const ContentIsLoading = styled.div``;
