import { BP_LOGIN_DELETE_ITENS } from './actions';

export const delete_itens_logins = () => {

    return (dispatch, getState) => {

        dispatch({
            type: BP_LOGIN_DELETE_ITENS,
            payload: {
                loginClientsUsers: [],
                loginPartnersUsers: []
            }
        });
    }
}

export default delete_itens_logins;
