import React from "react";

import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { BP_API } from "../../actions/bp/actions";

import LoggedPage from "../page-templates/loggedAdmin";

import {
  Tabs,
  Row,
  Col,
  Select,
  Button,
  Table,
  Tag,
  Modal,
  Empty,
  Drawer,
} from "antd";

import FormContents from "../../modules/Admin/components/formContents";

import TableContent from "../../modules/Admin/components/tableContent";

import { Task } from "../../utils/task";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_knowledge: () => {
      return dispatch(BP_API.get_knowledge());
    },
    create_content: (data) => {
      return dispatch(BP_API.create_content(data));
    },
    update_content: (data) => {
      return dispatch(BP_API.update_content(data));
    },
    delete_content: (data) => {
      return dispatch(BP_API.delete_content(data));
    },
  };
};

class KnowledgeCategoryAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      task: {},
      visible: false,
      item: {},
    };
  }

  componentDidMount() {
    this.props.get_knowledge();
  }

  componentDidUpdate = (prevProps) => {
    const task = this.props.bp.task || {};
    const prev = (prevProps && prevProps.bp.task) || {};

    if (task != prev) {
      this.setState({ task: task });
    }

    if (
      this.props.bp &&
      this.props.bp.knowledgeBase != prevProps.bp.knowledgeBase
    ) {
      this.setState({
        task: {},
      });
    }
  };

  handleDelete = (data) => {
    this.props.delete_content(data).then((result) => {
      if (result) {
        this.props.get_knowledge();
        this.close();
      }
    });
  };

  handleAction = (data) => {
    var task = new Task();

    task.start();

    let dataSend = {
      ...data,
    };

    if (data.file) {
      dataSend.content = data.file[0].url;
    }

    this.setState(
      { task: task, id: data.clientId, updateList: true },
      function () {
        if (Object.keys(this.state.item).length > 0) {
          dataSend.scopeId = this.state.item.scopeId;
          dataSend.contentId = this.state.item.contentId;
          this.props.update_content(dataSend).then((result) => {
            if (result) {
              this.props.get_knowledge();
              this.close();
            }
          });
        } else {
          this.props.create_content(dataSend).then((result) => {
            if (result) {
              this.props.get_knowledge();
              this.close();
            }
          });
        }
      }
    );
  };

  close = () => {
    this.setState({
      visible: false,
      item: {},
    });
  };

  setItem = (item) => {
    this.setState({
      visible: true,
      item: item,
    });
  };

  render() {
    const {
      bp: { knowledgeBase = [] },
    } = this.props;

    let dataScopes = [];
    let dataCategories = [];
    let dataContents = [];
    let selectScopo = [];

    knowledgeBase.map((scope) => {
      if (scope.sections && scope.sections.length > 0) {
        scope.sections.map((section) => {
          if (section?.categories.length > 0) {
            section.categories.map((categorie) => {
              selectScopo.push({
                scopeId: scope.scopeId,
                categoryId: categorie.categoryId,
                sectionId: section.sectionId,
                type: scope.type,
              });

              dataCategories.push({
                ...categorie,
                scope: scope.name,
                scopeId: scope.scopeId,
                section: section.name,
                target: scope.target,
                language: scope.language,
                sectionId: section.sectionId,
                type: scope.type,
              });

              if (categorie?.contents && categorie?.contents.length > 0) {
                categorie.contents.map((content, index) => {
                  dataContents.push({
                    ...content,
                    scope: scope.name,
                    scopeId: scope.scopeId,
                    section: section.name,
                    target: scope.target,
                    sectionId: section.sectionId,
                    language: scope.language,
                  });
                });
              }
            });
          }
        });
      }
    });

    // let selectClean = selectScopo.filter(function(elem, pos, self) {
    //     return self.indexOf(elem) == pos;
    // })

    let selectClean = selectScopo.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));

    let dataCategoriesClean = dataCategories.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));

    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li className="is-active">
              <NavLink to="/bpool/wikis" className="is-black">
                {I18n.get("Wikis")}
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="card">
          <div className="card-container card-wikis">
            <p className="btpull">
              <Button
                onClick={() => this.setState({ visible: true, item: {} })}
                className="button bt-bpool black pull-right"
              >
                {I18n.get("Adiconar conteúdo")}
              </Button>
            </p>

            <TableContent
              data={dataContents || []}
              edit={this.setItem}
              delete={this.handleDelete}
            />
          </div>
        </div>

        <Drawer
          title={
            Object.keys(this.state.item).length > 0
              ? I18n.get("Editar")
              : I18n.get("Adicionar")
          }
          placement="right"
          closable={true}
          onClose={() => this.close()}
          destroyOnClose={true}
          visible={this.state.visible}
          width="50%"
        >
          <FormContents
            filterCategory={selectClean}
            categories={dataCategoriesClean || []}
            action={this.handleAction}
            task={this.state.task}
            errors={this.props?.bp?.errors}
            item={this.state.item}
          />
        </Drawer>
      </LoggedPage>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KnowledgeCategoryAdmin);
