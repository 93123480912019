import React from "react";

import { I18n } from "aws-amplify";

import numeral from "numeral";

import { Task } from "../../utils/task";

import { Icon, Spin, Tooltip } from "antd";

import FileUploadButton from "../../modules/components/FileUploadButton";

import MoneyField from "../form/money-field";
import PercentageField from "../form/percentage-field";
import UploadField from "../form/upload-field";
import CheckboxSwitchField from "../form/checkbox-switch-field";

import FormatCurrency from "../ui/format-currency";

require("./quotation-summary-form.css");

// const ptBR = new Intl.NumberFormat('pt-BR', {
//     minimumFractionDigits: 2
// });

const updateForm = (quotation) => {
  const {
    costsAmount,
    itemsAmount,
    itemsAmountDoubleTax,
    fee = {},
    tax = {},
    breakdown,
    includeTaxOnAssets,
    includeTaxOnFee,
  } = quotation || {};

  return {
    costsAmount,
    itemsAmount,
    itemsAmountDoubleTax,
    fee: fee && fee.percentage,
    feeAmount: fee && fee.amount,
    tax: tax && tax.percentage,
    taxAmount: tax && tax.amount,
    breakdown: breakdown,
    includeTaxOnAssets,
    includeTaxOnFee,
  };
};

class QuotationSummaryForm extends React.Component {
  constructor(props) {
    const form = updateForm(props.quotation);

    super();

    this.state = {
      fields: {
        breakdown: {
          value: "Selecione...",
          onChange: this.onUpload.bind(this),
          onBlur: this.onSave.bind(this),
          error: props.errors.breakdown,
          visited: true,
        },
        costsAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "costsAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.costsAmount,
          successIcon: false,
          visited: true,
        },
        itemsAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "itemsAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.itemsAmount,
          successIcon: false,
          visited: true,
        },
        itemsAmountDoubleTax: {
          value: "",
          onChange: this.onChangeForm.bind(this, "itemsAmountDoubleTax"),
          onBlur: this.onSave.bind(this),
          error: props.errors.itemsAmountDoubleTax,
          successIcon: false,
          visited: true,
        },
        fee: {
          value: "",
          onChange: this.onChangeForm.bind(this, "fee"),
          onBlur: this.onSave.bind(this),
          error: props.errors.fee,
          successIcon: false,
          visited: true,
        },
        feeAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "feeAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.feeAmount,
          successIcon: false,
          visited: true,
        },
        tax: {
          value: "",
          onChange: this.onChangeForm.bind(this, "tax"),
          onBlur: this.onSave.bind(this),
          error: props.errors.tax,
          successIcon: false,
          visited: true,
        },
        taxAmount: {
          value: "",
          onChange: this.onChangeForm.bind(this, "taxAmount"),
          onBlur: this.onSave.bind(this),
          error: props.errors.taxAmount,
          successIcon: false,
          visited: true,
        },
        includeTaxOnAssets: {
          id: "includeTaxOnAssets",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnAssets"),
          error: props.errors.includeTaxOnAssets,
          successIcon: false,
        },
        includeTaxOnFee: {
          id: "includeTaxOnFee",
          value: true,
          onChange: this.onChangeFormCheckbox.bind(this, "includeTaxOnFee"),
          error: props.errors.includeTaxOnFee,
          successIcon: false,
        },
      },
      file: {},
      form: {
        ...form,
      },
      errors: {},
      task: {},
      upload_task: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      //console.dir({ task: task, prev: prev });

      var nextState = Object.assign({}, prevState, { task: task });

      const form = updateForm(this.props.quotation);

      nextState.form = {
        ...prevState.form,
        ...form,
      };

      nextState.errors = this.props.errors;

      this.setState(nextState);
    }
  }
  onChangeForm(prop, e) {
    const value = e.target.value;

    const quotation = this.props.quotation || { fee: {}, tax: {} };

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      if (prop == "fee") {
        const feePerc = numeral(value).value() / 100;
        const feeAmount = quotation.itemsAmountSubTotal * (feePerc / 100);

        nextState.form.feeAmount = feeAmount;
      }

      if (prop == "tax") {
        let baseAmount = quotation.itemsAmountDoubleTax;

        if (quotation.includeTaxOnAssets) {
          baseAmount += quotation.costsAmount;
        }

        if (quotation.includeTaxOnFee) {
          baseAmount += quotation.fee.amount;
        }

        const taxPerc = numeral(value).value() / 100 / 100;

        const div = 1 - taxPerc;

        const taxAmount = (baseAmount / div) * taxPerc;

        nextState.form.taxAmount = taxAmount;
      }

      return nextState;
    });
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = checked;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => {
        this.onSave();
      }
    );
  }

  getDataUploadMult = (files) => {
    if (files.length > 0) {
      let file = files[0];

      delete file["uid"];

      var upload_task = new Task();

      upload_task.start();

      this.setState({ upload_task: upload_task }, function () {
        const data = {
          ...file,
          projectId: this.props.match.params.projectId,
          partnerId: this.props.quotation.id,
          fileCategory: "breakdown",
        };

        this.props
          .uploadEventQuotationfile2(data)
          .then((response) => {
            this.setState((prevState) => {
              var nextState = Object.assign({}, prevState);

              nextState.file = response.data;
              nextState.form.breakdown = file.name;
              nextState.upload_task = { result: true };

              return nextState;
            });
          })
          .catch((error) => {
            this.setState({ upload_task: { result: false } });
          });
      });
    } else {
      this.setState({
        file: {},
      });
    }

    // this.props.upload_filev2(data);
  };

  onUpload(e) {
    var file = e.target.files[0];

    if (!file) return;

    if (e) e.preventDefault();

    var reader = new FileReader();

    reader.onload = (e) => {
      var upload_task = new Task();

      upload_task.start();

      this.setState({ upload_task: upload_task }, () => {
        const data = {
          projectId: this.props.match.params.projectId,
          partnerId: this.props.partner.id,
          file_type: "breakdown",
          name: file.name,
          base64: e.target.result,
        };

        this.props
          .uploadEventQuotationfile(data)
          .then((response) => {
            this.setState((prevState) => {
              var nextState = Object.assign({}, prevState);

              nextState.file = response.data;
              nextState.form.breakdown = file.name;
              nextState.upload_task = { result: true };

              return nextState;
            });
          })
          .catch((error) => {
            this.setState({ upload_task: { result: false } });
          });
      });
    };
    reader.readAsDataURL(file);
  }

  delFile(item) {
    var data = {
      projectId: this.props.match.params.projectId,
      partnerId: this.props.partner.id,
      ...item,
    };

    this.setState({ file: {} }, function () {
      this.props.delEventQuotationFile(data);
    });
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      partnerId: this.props.partner.id,
      costsAmount: this.state.form.costsAmount,
      itemsAmount: this.state.form.itemsAmount,
      itemsAmountDoubleTax: this.state.form.itemsAmountDoubleTax,
      fee: {
        percentage: this.state.form.fee,
        amount: this.state.form.feeAmount,
      },
      tax: {
        percentage: this.state.form.tax,
        amount: this.state.form.taxAmount,
      },
      includeTaxOnAssets: this.state.form.includeTaxOnAssets,
      includeTaxOnFee: this.state.form.includeTaxOnFee,
    };

    data.file = this.state.file;

    return data;
  }

  onSave() {
    const data = this.getData();

    var save_task = new Task();

    save_task.start();

    this.setState({ task: save_task }, () => {
      this.props.updateEventQuotation(data).then(({ task, errors }) => {
        this.setState({ task: task, errors: errors });
      });
    });
  }

  render() {
    const currency = this.props.currency || {};

    const quotation = this.props.quotation || {};

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    console.log("QUOTATION", this.props);

    return (
      <div className="quotation-summary">
        <p className="title is-5">{I18n.get("Proposta Comercial")}</p>

        {canEdit && (
          <>
            <p class="subtitle is-6">
              {I18n.get(
                "Detalhe no quadro abaixo os macro números do seu orçamento."
              )}
            </p>
          </>
        )}

        <form>
          <br />

          <div className="columns head">
            <div className="column is-3 ">{I18n.get("Custos Gerais")}</div>

            <div className="column is-2">%</div>

            <div className="column is-2">{I18n.get("Valor")}</div>
          </div>

          {/* <div className="columns spreadsheet">

                        <div className="column is-2 tit">{I18n.get("Upload Planilha")}</div>

                        <div className="column is-2">
                        </div>

                        <div className="column is-2 val">
                            {canEdit &&
                                <FileUploadButton
                                    getDataUpload={this.getDataUploadMult}
                                    name="files"
                                    uploadId={this.props.project.projectId}
                                    get2={this.props.get2 || false}
                                    // fileListGet={filesNormalize || []}
                                    // direct={true}
                                    disabledList={true}
                                    limit={1}
                                    output="object"
                                />
                                // <UploadField {...this.state.fields.breakdown} value={this.state.form.breakdown} uploading={this.state.upload_task.busy} />
                            }

                            <p>{canEdit && quotation.breakdown && <a target="_blank" className="miniLink" href={this.state.file.url || quotation.breakdown.url}><Icon type="file" />  {this.state.file.name || quotation.breakdown.name}</a>}</p>

                            {!canEdit && quotation.breakdown && <a target="_blank" href={quotation.breakdown.url}> {quotation.breakdown.name}</a>}

                            {this.props.errors['breakdown.id'] && <p className="help is-danger">
                                {this.props.errors['breakdown.id'].errorMessage}
                            </p>}
                        </div>

                    </div> */}

          <div className="columns assets">
            <div className="column is-3 tit">{I18n.get("Custos Internos")}</div>

            <div className="column is-2"></div>

            {canEdit && (
              <>
                <div className="column is-2 edit">
                  <MoneyField
                    {...this.state.fields.costsAmount}
                    currency={currency}
                    value={this.state.form.costsAmount}
                    error={this.state.errors.costsAmount}
                  />
                </div>
              </>
            )}
            {!canEdit && (
              <>
                <div className="column is-2 val">
                  <FormatCurrency
                    amount={quotation.costsAmount}
                    currency={currency}
                  />
                  {/* {ptBR.format(quotation.costsAmount)} */}
                </div>
              </>
            )}
          </div>

          <div className="columns items">
            <div className="column is-3 tit">
              {I18n.get("Custos Terceiros")}
            </div>

            <div className="column is-2"></div>

            <div className="column is-2 sum">
              <span style={{ opacity: "0.5" }}>
                {/* {ptBR.format(quotation && quotation.itemsAmountSubTotal)} */}
              </span>
            </div>
          </div>

          <div className="columns items-sub">
            <div className="column is-3 subtit">
              - {I18n.get("Itens com bi-tributação")}{" "}
              <Tooltip
                placement="right"
                title={
                  "Considerar quando seus fornecedores não puderem trabalhar com o prazo de pagamento do cliente"
                }
              >
                <i
                  className="fas fa-question-circle"
                  style={{ marginLeft: "10px" }}
                />{" "}
              </Tooltip>
            </div>

            <div className="column is-2"></div>

            {canEdit && (
              <>
                <div className="column is-2 edit">
                  <MoneyField
                    {...this.state.fields.itemsAmountDoubleTax}
                    currency={currency}
                    value={this.state.form.itemsAmountDoubleTax}
                    error={this.state.errors.itemsAmountDoubleTax}
                  />
                </div>
              </>
            )}
            {!canEdit && (
              <>
                <div className="column is-2 subval">
                  <FormatCurrency
                    amount={quotation.itemsAmountDoubleTax}
                    currency={currency}
                  />

                  {/* {ptBR.format(quotation.itemsAmountDoubleTax)} */}
                </div>
              </>
            )}
          </div>

          <div className="columns items-sub">
            <div className="column is-3 subtit">
              - {I18n.get("Itens sem bi-tributação")}
              <Tooltip
                placement="right"
                title={
                  "Considerar quando seus fornecedores podem trabalhar com o prazo de pagamento do cliente. Faturamento direto via BPool."
                }
              >
                <i
                  className="fas fa-question-circle"
                  style={{ marginLeft: "10px" }}
                />{" "}
              </Tooltip>
            </div>

            <div className="column is-2"></div>

            {canEdit && (
              <>
                <div className="column is-2 edit">
                  <MoneyField
                    {...this.state.fields.itemsAmount}
                    currency={currency}
                    value={this.state.form.itemsAmount}
                    error={this.state.errors.itemsAmount}
                  />
                </div>
              </>
            )}
            {!canEdit && (
              <>
                <div className="column is-2 subval">
                  <FormatCurrency
                    amount={quotation.itemsAmount}
                    currency={currency}
                  />
                  {/* {ptBR.format(quotation.itemsAmount)} */}
                </div>
              </>
            )}
          </div>

          <div className="columns fee">
            <div className="column is-3 tit">{I18n.get("Fee Comissão")}</div>

            {canEdit && (
              <>
                <div className="column is-2 edit">
                  <PercentageField
                    {...this.state.fields.fee}
                    value={this.state.form.fee}
                    error={this.state.errors["fee.percentage"]}
                  />
                </div>
              </>
            )}
            {!canEdit && (
              <>
                <div className="column is-2 val">
                  <p>{quotation.fee && quotation.fee.percentageFmt}</p>
                </div>
              </>
            )}

            <div className="column is-2 sum">
              <FormatCurrency
                amount={this.state.form.feeAmount}
                currency={currency}
              />
              {/* {ptBR.format(this.state.form.feeAmount)} */}
            </div>
          </div>

          <div className="columns tax">
            <div className="column is-3 tit">{I18n.get("Impostos")}</div>

            {canEdit && (
              <>
                <div className="column is-2 edit">
                  <PercentageField
                    {...this.state.fields.tax}
                    value={this.state.form.tax}
                    error={this.state.errors["tax.percentage"]}
                  />
                </div>
              </>
            )}
            {!canEdit && (
              <>
                <div className="column is-2 val">
                  <p>{quotation.tax && quotation.tax.percentageFmt}</p>
                </div>
              </>
            )}

            <div className="column is-2 sum">
              <FormatCurrency
                amount={this.state.form.taxAmount}
                currency={currency}
              />
              {/* {ptBR.format(this.state.form.taxAmount)} */}
            </div>
          </div>

          <div className="columns total">
            {" "}
            <div className="column is-2 is-offset-3 sum">
              {I18n.get("Total")}
            </div>
            <div className="column is-2 sum">
              <Spin
                delay={500}
                spinning={this.state.task.busy === true}
                style={{ color: "#ffffff", marginRight: "1rem" }}
                indicator={<Icon type="loading" />}
              />

              <FormatCurrency
                amount={quotation.estimateTotalExcludingBPCommission}
                currency={currency}
              />
              {/* {ptBR.format(quotation.estimateTotalExcludingBPCommission)} */}
            </div>
          </div>

          <br />

          {this.props.auth.is_partner && (
            <>
              <div className="field">
                <label className="label is-small">
                  {I18n.get("Incluir impostos")} *
                  <div style={{ marginTop: "0.5rem", opacity: "0.5" }}>
                    {I18n.get(
                      "Consulte seu contrato e verifique se os preços acordados para itens internos são brutos ou líquidos"
                    )}
                  </div>
                </label>
              </div>

              <br />

              <div className="columns">
                <div className="column is-6" style={{ border: "none" }}>
                  <CheckboxSwitchField
                    {...this.state.fields.includeTaxOnAssets}
                    label={I18n.get("Itens Internos")}
                    checked={this.state.form.includeTaxOnAssets}
                    disabled={!canEdit}
                  />
                </div>
                <div className="column is-6" style={{ border: "none" }}>
                  <CheckboxSwitchField
                    {...this.state.fields.includeTaxOnFee}
                    label={I18n.get("Fee Comissão")}
                    checked={this.state.form.includeTaxOnFee}
                    disabled={!canEdit}
                  />
                </div>
              </div>
            </>
          )}

          {/*
                    <div className="columns has-background-white space-box" style={{ boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)' }}>

                        <div className="column is-10 has-text-right">
                            <strong>NF PARCEIRO CONTRA CLIENTE AOS CUIDADOS DA BPOOL</strong>
                        </div>

                        <div className="column is-2 has-text-right">
                            <strong><BRL amount={quotation.estimateTotal} /></strong>
                        </div>

                    </div> */}
        </form>
      </div>
    );
  }
}

export default QuotationSummaryForm;
