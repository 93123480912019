import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PROJECT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_project_terms = (data) => {
    return (dispatch, getState) => {

        return axios.put(`${Config.API_BASE_URL}/client-project/${data.verificationToken}/responsible-terms`, data, {})
            .then(response => {

                const payload = {
                    ...response.data,
                    task: {
                        result: true
                    }
                };

                dispatch({
                    type: PROJECT_UPDATE,
                    payload: payload
                })

                return payload;


            })
            .catch(error => {

                const payload = {
                    errors: error.response.data,
                    task: {
                        result: false
                    }
                };

                return payload;
            });
    };
}


export default api_update_project_terms;