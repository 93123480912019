import React from "react";

import { I18n } from "aws-amplify";

export default class RadioField extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const isError = !!this.props.value && this.props.error;

    return (
      <div className="field">
        <div className="control">
          <label className="radio">
            <input
              type="radio"
              name={this.props.name}
              value={this.props.value}
              data-checked={this.props.checked ? "checked" : "noChecked"}
              checked={this.props.checked}
              onChange={this.props.onChange}
              className={isError ? "is-danger" : ""}
              onBlur={this.props.onBlur}
              disabled={this.props.disabled || false}
            />
            <span className="radiomark"></span>
            <p>&nbsp;{this.props.label}</p>
          </label>
        </div>
        {isError && (
          <p className="help is-danger">{this.props.error.errorMessage}</p>
        )}
      </div>
    );
  }
}
