import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_PROJECT_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const delete_invoice = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.put(`${Config.API_BASE_URL}/bp/admin/billing-installment-invoice-delete`, data, config)
            .then(response => {
                dispatch({
                    type: BP_PROJECT_GET,
                    payload: {
                        project: response.data
                    }
                });
            })
            .catch(error => {
                throw (error);
            });

        }).catch(error => { 
            // dispatch({ type: EXPIRED }); 
        }); 
    };
}

export default delete_invoice;