import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";

import { Task } from "../../../utils/task";

import { BP_API } from "../../../actions/bp/actions";

import TextField from "../../form/text-field";
import MoneyField from '../../form/money-field';
import RadioMultiField from "../../form/radio-multi-field";
import PercentageField from "../../form/percentage-field";
import SelectField from "../../form/select-field";
import { AutoComplete, Select } from "antd";
import FractionField from "../../form/fraction-field";

import SubmitButtonField from "../../form/submit-button-field";

import FileUploadList from "../../../modules/components/FileUploadList";




const mapStateToProps = (state) => {
  return state.bp;
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (data) => {
      return dispatch(BP_API.update_client(data));
    },
  };
};

class ClientCustomizeForm extends React.Component {
  constructor(props) {
    const customization =
      (props.bp.client && props.bp.client.customization) || {};

    super();

    this.state = {
      fields: {
        disableNewProjectStep2BundleView: {
          onChange: this.onChangeForm.bind(
            this,
            "disableNewProjectStep2BundleView"
          ),
          values: [
            { code: true, label: I18n.get("Sim") },
            { code: false, label: I18n.get("Não") },
          ],
          successIcon: false,
          isHorizontal: true,
        },
        disableNewProjectStep2CartView: {
          onChange: this.onChangeForm.bind(
            this,
            "disableNewProjectStep2CartView"
          ),
          values: [
            { code: true, label: I18n.get("Sim") },
            { code: false, label: I18n.get("Não") },
          ],
          successIcon: false,
          isHorizontal: true,
        },
        cssUrl: {
          onChange: this.onChangeForm.bind(this, "cssUrl"),
          successIcon: false,
          className: "field is-narrow",
          tooltip: I18n.get(
            "Adicionar o arquivo CSS na pasta 'src\\custom-css' do projeto"
          ),
        },
        term: {
          onChange: this.onChangeForm.bind(this, "term"),
          successIcon: false,
          tooltip: I18n.get(
            "Termos próprio do cliente quando customizado. Deve-se colocar a url completa aqui."
          ),
        },
        assetTax: {
          onChange: this.onChangeForm.bind(this, "assetTax"),
          successIcon: false,
        },
        otherTax: {
          onChange: this.onChangeForm.bind(this, "otherTax"),
          successIcon: false,
        },
        currency: {
          onChange: this.onChangeForm.bind(this, "currency"),
          successIcon: false,
        },
        billingCurrency: {
          onChange: this.onChangeForm.bind(this, "billingCurrency"),
          successIcon: false,
        },
        clientBillingCountry: {
          onChange: this.onChangeForm.bind(this, "clientBillingCountry"),
          successIcon: false,
        },
        paymentCurrency: {
          onChange: this.onChangeForm.bind(this, "paymentCurrency"),
          successIcon: false,
        },
        fixedRate: {
          onChange: this.onChangeForm.bind(this, "fixedRate"),
          successIcon: false,
          tooltip: I18n.get(
            "Taxa Pré-Acordada de Conversão entre a Moeda Principal e a Moeda de Faturamento."
          ),
        },
        paymentOnlyAfterDelivery: {
          onChange: this.onChangeForm.bind(this, "paymentOnlyAfterDelivery"),
          successIcon: false,
        },
        bPoolOfficeId: {
          onChange: this.onChangeForm.bind(this, "bPoolOfficeId"),
          successIcon: false,
        },
        invoiceTarget: {
          onChange: this.onChangeForm.bind(this, "invoiceTarget"),
          successIcon: false,
          tooltip: I18n.get(
            "Padrão = Não selecionado = Fat contra cliente"
          ),
        },
        bpayCopyToEmail: { 
          onChange: this.onChangeForm.bind(this, "bpayCopyToEmail"),
          successIcon: false,
          tooltip: I18n.get(
            "Para mais de um email separar por vírgula"
          ),
        },
        invoiceType: {
          onChange: this.onChangeForm.bind(this, "invoiceType"),
          successIcon: false,
        },
        projectThreashold: {
          label: I18n.get("Threashold"),
          onChange: this.onChangeForm.bind(this, 'projectThreashold'),
          successIcon: false,
          visited: true,
      },
        allowMultipleMarket: {
          onChange: this.onChangeForm.bind(this, "allowMultipleMarket"),
          successIcon: false,
        },
      },
      form: {
        ...customization,
        currency: customization.currency && customization.currency.code,
        billingCurrency:
          customization.billingCurrency && customization.billingCurrency.code,
        paymentCurrency:
          customization?.paymentCurrency && customization.paymentCurrency?.code,
        paymentOnlyAfterDelivery:
          customization.paymentOnlyAfterDelivery &&
          customization.paymentOnlyAfterDelivery.code,
        bPoolOfficeId: customization.bPoolOfficeId && customization.bPoolOfficeId,
        invoiceTarget: customization.invoiceTarget && customization.invoiceTarget,
        invoiceType: customization.invoiceType && customization.invoiceType,
        allowMultipleMarket: customization.allowMultipleMarket || false,
        additionalRule: customization?.additionalRule,
        clientBillingCountry:
          customization.clientBillingCountry,
      },
      errors: {},
      submit_task: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const { client = {} } = this.props;

      const { customization = {} } = client;

      Object.keys(this.state.fields).forEach((item) => {
        if (item == "currency" && customization[item]) {
          nextState.form[item] = customization[item].code;
        } else if (item == "billingCurrency" && customization[item]) {
          nextState.form[item] = customization[item].code;
        } else if (item == "paymentCurrency" && customization[item]) {
          nextState.form[item] = customization[item].code;
        } else if (item == "additionalRule" && customization[item]) {
          nextState.form[item] = customization[item];
        } else if (customization[item]) {
          nextState.form[item] = customization[item];
        }
      });

      this.setState(nextState);
      //
    }

    // if(prevProps.client) {

    // }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeFormFile(e) {
    const value = e;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["additionalRule"] = value;
      nextState.errors["additionalRule"] = null;

      return nextState;
    });
  }

  onChangeFormRadio(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = [value];
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].checked = checked;
      nextState.form[prop].value = checked;

      return nextState;
    });
  }

  getData() {
    var data = {
      clientId: this.props.match.params.clientId,
      status: this.props.client.status,
      customization: {
        ...this.props.client.customization,
        ...this.state.form,
      },
    };
    

    if (typeof data.customization.currency == "string") {
      data.customization.currency = this.props.bp.currencies.find(
        (item) => item.code == data.customization.currency
      );
    }
    if (typeof data.customization.billingCurrency == "string") {
      data.customization.billingCurrency = this.props.bp.currencies.find(
        (item) => item.code == data.customization.billingCurrency
      );
    }
    if (typeof data.customization.paymentCurrency == "string") {
      data.customization.paymentCurrency = this.props.bp.currencies.find(
        (item) => item.code == data.customization.paymentCurrency
      );
    }
    if (typeof data.customization.paymentOnlyAfterDelivery == "string") {
      data.customization.billingCurrency = this.props.bp.currencies.find(
        (item) => item.code == data.customization.billingCurrency
      );

      if (data.customization.paymentOnlyAfterDelivery == "") {
        data.customization.paymentOnlyAfterDelivery = null;
      }

      if (data.customization.paymentOnlyAfterDelivery == "true") {
        data.customization.paymentOnlyAfterDelivery = true;
      }

      if (data.customization.paymentOnlyAfterDelivery == "false") {
        data.customization.paymentOnlyAfterDelivery = false;
      }
    }

    if (typeof data.customization.allowMultipleMarket == "string") {
      if (data.customization.allowMultipleMarket == "") {
        data.customization.allowMultipleMarket = null;
      }

      if (data.customization.allowMultipleMarket == "true") {
        data.customization.allowMultipleMarket = true;
      }

      if (data.customization.allowMultipleMarket == "false") {
        data.customization.allowMultipleMarket = false;
      }
    }

    return data;
  }

  submitForm(e) {
    e.preventDefault();

    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.save(this.getData()).then(({ task, errors }) => {
      //console.log({ task: task, errors: errors });

      this.setState({ submit_task: task, errors: errors });
    });
  }

  saveType = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        invoiceType: value,
      },
    });
  };

  

 

  render() {
    
    const currencyValues =
      this.props.bp.currencies &&
      this.props.bp.currencies.map((item) => ({
        code: item.code,
        label: `${item.code} - ${item.name}`,
      }));
      
      /*
      const countryCodes =
        this.props.bp.countryCodes &&
        this.props.bp.countryCodes.map((item) => ({
          code: item.code,
          label: `${item.code} - ${item.name}`,
        })) || [] ;
        console.log('Chegou aqui o ', this.props.bp.countryCodes)
        */
        //console.log('this.props.bp.countryCodes: ', this.props.bp.countryCodes)

        const countryCodes = [
          { label: "Argentina", code: "ARG" },
          { label: "Chile", code: "CHL" },
          { label: "Colombia", code: "COL" },
          { label: "Costa Rica", code: "CRI" },
          { label: "Ecuador", code: "ECU" },
          { label: "Mexico", code: "MEX" },
          { label: "Panama", code: "PAN" },
          { label: "Uruguay", code: "URY" },
          { label: "Portugal", code: "PRT" },
          
        ];

        const validCountry = (company) => {
          var ret = false;

          if (company && company?.country?.bpRegion === 'LATAM' || company?.country?.bpRegion === 'EUROPE' )
            ret = true;
          
            if (ret){ 
              switch(company.country.name) {
                case 'Argentina':       
                case 'Chile':
                case 'Colombia':
                case 'Costa Rica':
                case 'Ecuador':
                case 'Mexico':
                case 'Panama':
                case 'Portugal':
                case 'Uruguay':
                  ret = true;
                  break;
                default:
                  ret = false;
                  break;      
              }
            }
          return ret;
        };
      

      //console.log('Todos os Paises: ', countryCodes)

      let isBrazilInList =  false;

      if (countryCodes.length > 0){
        //isBrazilInList = validCountry(this.props.client.company.country.name)
        isBrazilInList = validCountry(this.props.client.company)
      }
      if (isBrazilInList){
        console.log('Paises LATAM tem o Brasil fora: ', isBrazilInList)
      }
      //console.log('this.props.client: ', this.props.client);

    const payments = [
      { code: null, label: "Selecione" },
      { code: false, label: "A partir da PO" },
      { code: true, label: "Após a entrega do projeto" },
    ];

    const officesValues = this.props.bPoolOffices?.map((item) => {
          return {
            code: item.id,
            label: `${item.registrationName} (${item?.country?.toUpperCase()})`
          };
        }) ?? [];

    const invoiceTargetValues = [
          { code: 'CLIENT', label: "CLIENT" },
          { code: 'BPOOL', label: "BPOOL" },
        ]; 

    const market = [
      { code: false, label: "Não" },
      { code: true, label: "Sim" },
    ];

    let allowed = [
      "8d985f12-c138-4a31-adab-d2f1605c649f", // Ptk
      "eeb23c4b-0b75-460d-a986-488631d85d4d", //Nuno
      "6ac74596-3fbe-4682-b6da-d3d6ed161480", //Davi
      "95f76846-7881-4509-b5fa-34ea693cc99e", //Cris
      "eb3c9f60-44f1-49a5-b681-dbc0219fe858", //Beto
      "d6911338-5722-4c78-ba06-a5bc9908d780", //Bruna
    ];

    let loggedUser = this.props.auth.username;

    let shouldBeEnabled =
      allowed.includes(loggedUser) ||
      window.location.href.toUpperCase().indexOf("DEV1") != -1 ||
      window.location.href.toUpperCase().indexOf("DEV2") != -1 ||
      window.location.href.toUpperCase().indexOf("DEV3") != -1 ||
      window.location.href.toUpperCase().indexOf("DEMO") != -1;

    const invoiceTypesArray =
      (this.props?.bp &&
        this.props?.bp?.invoiceTypes &&
        this.props?.bp?.invoiceTypes.map((item) => item.code)) ||
      [];

      

    return (
      <div>
        <div className="card">
          <div className="card-content">
            <h3 className="title is-6">{I18n.get("Customizar cliente")}</h3>

            <br />

            <form onSubmit={this.submitForm.bind(this)}>
              <div className="columns">
                <div className="column is-4">
                  <RadioMultiField
                    {...this.state.fields.disableNewProjectStep2BundleView}
                    label={I18n.get(
                      "Novo Projeto - Passo 2 - Desabilitar bundles"
                    )}
                    value={this.state.form.disableNewProjectStep2BundleView}
                    checked={this.state.form.disableNewProjectStep2BundleView}
                    error={this.state.errors.disableNewProjectStep2BundleView}
                  />
                </div>
                <div className="column is-4">
                  <RadioMultiField
                    {...this.state.fields.disableNewProjectStep2CartView}
                    label={I18n.get(
                      "Novo Projeto - Passo 2 - Desabilitar Carrinho"
                    )}
                    value={this.state.form.disableNewProjectStep2CartView}
                    checked={this.state.form.disableNewProjectStep2CartView}
                    error={this.state.errors.disableNewProjectStep2CartView}
                  />
                </div>

                <div className="column is-4">
                  <TextField
                    {...this.state.fields.cssUrl}
                    label={I18n.get("CSS")}
                    value={this.state.form.cssUrl}
                    error={this.state.errors.cssUrl}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-4">
                  <TextField
                    {...this.state.fields.term}
                    label={I18n.get("Termos Próprio")}
                    value={this.state.form.term}
                    error={this.state.errors.term}
                  />
                </div>

                <div className="column is-4">
                  <PercentageField
                    {...this.state.fields.otherTax}
                    label={I18n.get("Other Tax")}
                    value={this.state.form.otherTax}
                    error={this.state.errors.otherTax}
                    disabled={!shouldBeEnabled}
                  />
                </div>

                <div className="column is-4">
                  <PercentageField
                    {...this.state.fields.assetTax}
                    label={I18n.get("Asset Tax")}
                    value={this.state.form.assetTax}
                    error={this.state.errors.assetTax}
                    disabled={!shouldBeEnabled}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column is-4">
                  <SelectField
                    {...this.state.fields.currency}
                    label={I18n.get("Moeda Principal")}
                    value={this.state.form.currency}
                    error={this.state.errors.currency}
                    values={currencyValues}
                    disabled={!shouldBeEnabled}
                  />
                </div>
                <div className="column is-4">
                  <SelectField
                    {...this.state.fields.billingCurrency}
                    label={I18n.get("Moeda Faturamento")}
                    value={this.state.form.billingCurrency}
                    error={this.state.errors.billingCurrency}
                    values={currencyValues}
                    disabled={!shouldBeEnabled}
                  />
                </div>
                {/* <div className="column is-4">
                  <SelectField
                    {...this.state.fields.paymentCurrency}
                    label={I18n.get("Moeda Pagamento")}
                    value={this.state.form.paymentCurrency}
                    error={this.state.errors.paymentCurrency}
                    values={currencyValues}
                  />
                </div> */}
                
                <div className="column is-4">
                  <SelectField
                    {...this.state.fields.paymentOnlyAfterDelivery}
                    label={I18n.get("Prazo de pagamento")}
                    value={this.state.form.paymentOnlyAfterDelivery}
                    error={this.state.errors.paymentOnlyAfterDelivery}
                    values={payments}
                  />
                </div>
              </div>

              <div className="columns">
                
                <div className="column is-4">
                  <SelectField
                    {...this.state.fields.allowMultipleMarket}
                    label={I18n.get("liberar T2 em marketplace?")}
                    noNull={true}
                    value={this.state.form.allowMultipleMarket}
                    error={this.state.errors.allowMultipleMarket}
                    values={market}
                  />
                </div>
                <div className="column is-4">
                  <FractionField
                    {...this.state.fields.fixedRate}
                    label={`${I18n.get("Taxa Pré-Acordada de Conversão")} ${
                      this.state.form.currency
                    }/${this.state.form.billingCurrency}`}
                    disabled={!this.state.form.billingCurrency}
                    value={this.state.form.fixedRate}
                    error={this.state.errors.fixedRate}
                  />
                </div>
                <div className="column is-4">
                  <SelectField
                    {...this.state.fields.bPoolOfficeId}
                    label={I18n.get("Empresa BPool")}
                    value={this.state.form.bPoolOfficeId}
                    error={this.state.errors.bPoolOfficeId}
                    values={officesValues}
                  />
                </div>
              </div>
              <div className="columns">
                

                <div className="column is-4">
                  <SelectField
                    {...this.state.fields.invoiceTarget}
                    label={I18n.get("Invoice Target")}
                    value={this.state.form.invoiceTarget}
                    error={this.state.errors.invoiceTarget}
                    values={invoiceTargetValues}
                  />
                </div>

               
                <div className="column is-4">
                  <label class="label is-small">
                    {I18n.get("Invoice Type")}
                  </label>
                  {/* <AutoSuggestField
                    label={I18n.get("Invoice Type")}
                    error={this.state.errors.invoiceType}
                    values={(invoiceTypesArray && invoiceTypesArray) || []}
                  /> */}

                  <AutoComplete
                    // {...this.state.fields.invoiceType}
                    value={this.state.form.invoiceType}
                    dataSource={invoiceTypesArray || []}
                    // style={{ width: 200 }}
                    onSelect={(value) => this.saveType(value)}
                    onSearch={(value) => this.saveType(value)}
                    onChange={(value) => this.saveType(value)}
                    placeholder="control mode"
                  />
                </div>
                <div className="column is-4">
                  <MoneyField {...this.state.fields.projectThreashold} 
                    value={this.state.form.projectThreashold} 
                    currency={this.props?.bp?.client?.customization?.paymentCurrency} 
                    // disabled={true}
                  />
                </div>
                
              </div>
              <div className="columns">

                

                {isBrazilInList && <div className="column is-4">
                  <SelectField
                    {...this.state.fields.clientBillingCountry}
                    label={I18n.get("País de Faturamento")}
                    value={this.state.form.clientBillingCountry}
                    error={this.state.errors.clientBillingCountry}
                    values={countryCodes}
                  />
                </div>
                }

                  <div className="column is-4">
                  <TextField
                    {...this.state.fields.bpayCopyToEmail}
                    label={I18n.get("BPay Cópia e-mail")}
                    value={this.state.form.bpayCopyToEmail}
                    error={this.state.errors.bpayCopyToEmail}
                  />
                </div>

              </div>
                <div className="columns">

                <div className="column is-4">
                  <label class="label is-small">
                    {" "}
                    {I18n.get("Documento de regras adicionais")}
                  </label>

                  <FileUploadList
                    getDataUpload={(e) => this.onChangeFormFile(e[0])}
                    name="additionalRule"
                    uploadId={this.props.match.params.clientId}
                    get2={this.props.get2 || false}
                    fileListGet={
                      this.state?.form.additionalRule &&
                      Object.keys(this.state.form.additionalRule).length > 0
                        ? [
                            {
                              ...this.state.form.additionalRule,
                              uid: this.state?.form.additionalRule?.id,
                            },
                          ]
                        : []
                    }
                    limit={1}
                    output="object"
                  />
                </div>
              </div>

              <p className="has-text-danger">
                <ul>
                  {this.state.errors &&
                    Object.keys(this.state.errors).map((item) => {
                      if (this.state.errors[item]) {
                        return <li>{this.state.errors[item].errorMessage}</li>;
                      } else {
                        return null;
                      }
                    })}
                </ul>
              </p>

              <br />

              <div className="control">
                <SubmitButtonField
                  label={I18n.get("Salvar")}
                  onClick={this.submitForm.bind(this)}
                  task={this.state.submit_task}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCustomizeForm);
