import React from "react";
import { I18n } from "aws-amplify";
import FillForm from "../page-templates/fillForm-logged";
import ProjectContainer from "../../containers/project-container";
import { Link } from "react-router-dom";

import { scrollToTop } from "../../utils/ui";

class ThankYou extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    scrollToTop();
  }

  render() {
    return (
      <FillForm noFixed={true} noFullHeight={true}>
        <div className="hero-body uplift new-project-thankyou-image">
          <div className="container">
            <div className="columns">
              <div className="column is-offset-3 is-6">
                <h1 className="subtitle is-4 is-marginless">
                  {I18n.get("Muito bom!")}
                </h1>
                <div className="colot-line"></div>
                {this.props.selectPartnerId && (
                  <div>
                    <p>
                      {I18n.get(
                        "Seu projeto está criado e o parceiro foi notificado do seu convite. Aguarde e, em até dois dias úteis, a gente te conta qual foi a resposta do parceiro."
                      )}
                    </p>
                    <br />
                  </div>
                )}
                {!this.props.selectPartnerId && (
                  <div>
                    {this.props.isBPitch === false && (
                      <>
                        {this.props.isEvent && (
                          <p>
                            {I18n.get(
                              "Seu projeto está criado e já foi disparado para o parceiro definido. Agora é partir para o preenchimento do briefing."
                            )}
                          </p>
                        )}

                        {!this.props.isEvent && (
                          <>
                            <p>
                              {I18n.get(
                                "Seu projeto está criado. Agora, nossa curadoria e algoritmo irão buscar parceiros com o perfil, experiência e expertise necessários para a execução do projeto."
                              )}
                            </p>

                            <br />

                            <p>
                              {I18n.get(
                                "Aguarde. Em até dois dias úteis, você receberá uma notificação sobre os parceiros ideais que selecionamos para este projeto."
                              )}
                            </p>
                          </>
                        )}
                      </>
                    )}

                    {this.props.isBPitch === true && (
                      <>
                        <p>
                          {I18n.get(
                            "Seu pitch foi criado. Agora nossa curadoria e algoritmo irão buscar os parceiros com o perfil, experiência e expertise necessários para participar da sua concorrência."
                          )}
                        </p>
                        <p>
                          {I18n.get(
                            "Se você incluiu parceiros para o processo, os mesmos vão ser adicionados para  a fase inicial."
                          )}
                        </p>

                        <br />

                        <p>
                          {I18n.get(
                            "Aguarde. Em breve você receberá uma notificação sobre os parceiros indicados."
                          )}
                        </p>
                      </>
                    )}
                  </div>
                )}
                <p>
                  <br />
                  {I18n.get(
                    "Quer acompanhar o andamento deste e de outros projetos?"
                  )}{" "}
                  <Link to="/cliente/projetos">{I18n.get("Clique aqui")}</Link>{" "}
                  {I18n.get("para acessar a área de projetos.")}
                </p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(ThankYou);
