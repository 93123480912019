import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col, message } from "antd";
import { Loggedv2 } from "../components/Loggedv2";
import Main from "../../../components/UI/Main";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import { Input } from "../../../components/UI/Input";
import { Textarea } from "../../../components/UI/Textarea";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";
import { hasError, clearErrorInput } from "../utils/errorsInput";

import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from "../utils/flowToStorage";
import { useRegistrationFlow } from "../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";
import { statusIdToName } from "../components/Loggedv2/statusIdToName";

import { urls } from "../utils/stepsUrls";
import { inputsState } from "./inputsState";
import * as S from "./styles";

const MESSAGE_ERROR_GET_PAGE = "There was an error, try again.";
const PAGE_INDEX = 1;

export const AboutTheCompany = () => {
  let history = useHistory();
  const {
    getStatusBarPre,
    updateAboutTheCompany,
    getAboutTheCompany,
    getDiversities,
  } = useRegistrationFlow();

  const [inputsFormInitals, setInputsFormInitals] = useState(inputsState);
  const [inputsForm, setInputsForm] = useState(inputsState);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isLoadPage, setIsLoadPage] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarPre();
    console.log('1 responseStepbar', responseStepbar);
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    console.log('2 step', step);
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    console.log('3 statusStep', statusStep);
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);
    const formData = {
      ...inputsForm,
    };

    const response = await updateAboutTheCompany(formData);
    await newGetStepbar();

    if (response?.errors?.length) {
      setInputsErrorsForm(response?.errors);
      setOpenModalConfirm(true);
    } else {
      await toUpdateFlowStorageSteps({
        screen: PAGE_INDEX,
      });
      history.push({
        pathname: urls[PAGE_INDEX + 1],
      });
    }

    setIsSending(false);
  };

  const handleClickOkModalConfirm = async () => {
    setOpenModalConfirm(false);
    // await toUpdateFlowStorageSteps({
    //   screen: PAGE_INDEX,
    //   status: step?.status,
    // });
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const hasError = (fieldInput) => {
    if (inputsErrorsForm.length) {
      const err = inputsErrorsForm.find((item) => item.field === fieldInput);

      if (err) {
        return err.errorMessage;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const loadInit = async () => {
    setIsLoadPage(true);

    const response = await getAboutTheCompany();
    if (response.success) {
      const data = {
        ...response.data.company,
        portfolioURL: response.data.portfolioURL,
      };

      setInputsFormInitals(data);
      setInputsForm(data);
    }

    setIsLoadPage(false);
  };

  const insta = (
    <span style={{ color: "#000", fontSize: "18px" }}>
      <b style={{ position: "relative", top: "4px" }}>@</b>
    </span>
  );

  //validation
  useEffect(() => {
    if (
      inputsForm.description !== inputsFormInitals.description ||
      inputsForm.website !== inputsFormInitals.website ||
      inputsForm.portfolioURL !== inputsFormInitals.portfolioURL ||
      inputsForm.linkedin !== inputsFormInitals.linkedin ||
      inputsForm.instagram !== inputsFormInitals.instagram
    ) {
      setChangeInfo(true);
    } else {
      setChangeInfo(false);
    }
  }, [inputsForm]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  return (
    <Loggedv2
      pageIndex={PAGE_INDEX}
      statusBarStepsClickable
      statusChangeInfo={changeInfo}
      hookGetItems={getStatusBarPre}
      stepbarId="stepbar-pre"
      urls={urls}
      contextStepBar="pre"
    >
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("About the Company")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  "In this phase we need a brief overview on your company."
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={24}>
            <Textarea
              id="overview-company"
              label={I18n.get("Give us an overview on your company...")}
              rows={4}
              maxLength={500}
              counterChar
              defaultValue={inputsForm.description}
              onChange={(value) => onChangeInputForm("description", value)}
              required
              error={hasError("description")}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "description",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={24}>
            <Textarea
              id="problems-that-your-company-solves"
              label={I18n.get(
                "Briefly describe the main problems that your company solves."
              )}
              rows={4}
              maxLength={500}
              counterChar
              defaultValue={inputsForm.problemSolves}
              onChange={(value) => onChangeInputForm("problemSolves", value)}
              error={hasError("problemSolves")}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "problemSolves",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={24}>
            <Textarea
              id="competitive-differentiators"
              label={I18n.get("Describe the main competitive differentiators.")}
              rows={4}
              maxLength={500}
              counterChar
              defaultValue={inputsForm.competitiveDifferentiators}
              onChange={(value) =>
                onChangeInputForm("competitiveDifferentiators", value)
              }
              error={hasError("competitiveDifferentiators")}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "competitiveDifferentiators",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} style={{ marginTop: 30 }}>
            <S.TitleSectionForm>{I18n.get("Useful links")}</S.TitleSectionForm>
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Website")}
              label
              required
              value={inputsForm.website}
              onChange={(value) => onChangeInputForm("website", value)}
              error={hasError("website")}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "website",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Company portfolio link")}
              label
              required
              value={inputsForm.portfolioURL}
              onChange={(value) => onChangeInputForm("portfolioURL", value)}
              error={hasError("portfolioURL")}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "portfolioURL",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Company LinkedIn")}
              label
              // required
              value={inputsForm.linkedin}
              onChange={(value) => onChangeInputForm("linkedin", value)}
              error={hasError("linkedin")}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "linkedin",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Company Instagram")}
              label
              // required
              value={inputsForm.instagram}
              onChange={(value) => onChangeInputForm("instagram", value)}
              error={hasError("instagram")}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "instagram",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
              addonBefore={insta}
              widthLabel="40px"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={24} sm={12}>
            {/* <ButtonBpool
              text={I18n.get("Back")}
              theme="primary"
              full
              disabled
              onClick={() => true}
            /> */}
          </Col>
          <Col xs={24} sm={12}>
            <ButtonBpool
              text={I18n.get("Save and Next")}
              theme="primary"
              full
              loading={isSending}
              onClick={send}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get("Missing required information")}
        subBodyText={I18n.get(
          "Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?"
        )}
        okText={I18n.get("Yes")}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get("Close")}
        typeModal="isConfirm"
      // isDanger
      />
    </Loggedv2>
  );
};
