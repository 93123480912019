import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { Row, Col, message } from 'antd';
import LoggedPage from '../../../../components/page-templates/fillForm-logged';
import Content from '../../../components/UI/Content';
import Main from '../../../components/UI/Main';
import { ButtonBpool } from '../../../components/UI/ButtonBpool';
import Loading from '../../../components/UI/Loading';
import { StepsProgress } from '../components/StepsProgress';
import { ButtonExpandSelect } from '../components/ButtonExpandSelect';
import { ScrollUpDown } from '../components/ScrollUpDown';
import { StepsVertical } from '../../../components/UI/StepsVertical';
import { useRegistrationFlow } from '../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook';
import { getStatusBar } from '../../../store/actions/statusBarRegistrationFlow';

import * as S from './styles';
import { urls } from '../utils/stepsUrls';
import { flowToStorage, getFlowStorage } from '../utils/flowToStorage';

const MESSAGE_ERROR_GET_PAGE = 'There was an error, try again.';
const MESSAGE_ERROR_BACK_PAGE = 'Error in back page.';
const PAGE_INDEX = 6;

export const ReviewPage = () => {
  const { StatusBarRegistrationFlow } = useRegistrationFlow();
  let history = useHistory();
  const dispatch = useDispatch();
  const statusbar = useSelector((state) => state.statusbar);

  const [dataPage, setDataPage] = useState([]);
  const [isLoadingSteps, setIsLoadingSteps] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const loadSteps = async () => {
    setIsLoadingSteps(true);
    const response = await StatusBarRegistrationFlow();
    const flowStorage = await getFlowStorage();

    const toStorage = response.map((item, index) => ({
      ...item,
      url: urls[item.key],
      visited:
        item.title === 'Application'
          ? true
          : flowStorage
          ? flowStorage[index].visited
          : false,
      status: item.title === 'Application' ? 'finish' : item.status,
    }));

    await flowToStorage(toStorage);

    dispatch(getStatusBar(flowStorage));
    setIsLoadingSteps(false);
  };

  const loadInit = async () => {
    await loadSteps();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  const send = async () => {
    console.log('send');
  };

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 0 }}>
              <Main bgColor="#fff" padding="30px">
                {/* <ScrollUpDown /> */}

                <Row>
                  <Col xs={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get('Review')}</S.H4>
                      </S.TitleWithIcon>
                      <S.Paragraph>{I18n.get('Review page.')}</S.Paragraph>
                    </S.RowCenter>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} style={{ marginTop: 40 }}>
                    <div>Review</div>
                  </Col>
                </Row>

                <Row style={{ marginTop: 30 }}>
                  <Col xs={24} sm={12}>
                    <ButtonBpool
                      text={I18n.get('Back')}
                      theme="secondary"
                      full
                      onClick={handleBackPage}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <ButtonBpool
                      text={I18n.get('Save and Next')}
                      theme="primary"
                      full
                      loading={isSending}
                      onClick={send}
                    />
                  </Col>
                </Row>
              </Main>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }}>
              <Main bgColor="#fff" padding="30px">
                <StepsVertical
                  isLoading={isLoadingSteps}
                  steps={statusbar?.steps}
                  clickable
                  page={6}
                  urls={urls}
                  changedInfos={changeInfo}
                />
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
