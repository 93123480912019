import React, { useEffect, useState } from "react";
import { message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import * as S from "./styles";

const MAX_ITEMS = "You may select up to 4 specialities in each category.";

export const ButtonExpandSelect = ({
  data,
  expanded,
  onChange,
  error,
  full,
  isDisabled,
  isLimit,
}) => {
  const [expand, setExpand] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const [activedSubs, setActivedSubs] = useState([]);

  const handleClick = () => {
    if (expand) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  };

  const handleClickSubItem = ({ arr, index, addOrDelete }) => {
    arr[index] = addOrDelete;
    onChange({ code: data.code, arr });
  };

  // const handleClickSub = ({ addOrDelete, total }) => {
  //   const finded = activedSubs.find((item) => item === addOrDelete);

  //   if (finded) {
  //     setActivedSubs(activedSubs.filter((item) => item !== addOrDelete));
  //   } else {
  //     if (total >= 4) {
  //       message.error(MAX_ITEMS);
  //     } else {
  //       setActivedSubs([...activedSubs, addOrDelete]);
  //     }
  //   }
  // };

  useEffect(() => {
    if (expanded) {
      setExpand(expanded);
    }
  }, [expanded]);

  // useEffect(() => {
  //   console.log('data', data);
  //   const isActiveGeneral = data.disciplineChildren.some(
  //     (item) => item.selected === true
  //   );
  //   setButtonActive(isActiveGeneral);
  // }, [data]);

  return (
    <S.Wrapper>
      <S.Button active={data?.selected} onClick={handleClick} error={error}>
        <S.LabelButton active={data?.selected}>{data?.label}</S.LabelButton>
        {data?.description ? (
          <S.SubLabelButton active={data?.selected}>
            {data?.description}
          </S.SubLabelButton>
        ) : null}
      </S.Button>

      {expand || data?.selected ? (
        <S.Expanded>
          <ul>
            {data?.disciplineChildren?.map((item, index) => (
              <li key={item?.code}>
                <S.ButtonSub
                  active={item?.selected}
                  // disabled={full || isDisabled || (isLimit && !item?.selected)}
                  disabled={isDisabled || (isLimit && !item?.selected)}
                  onClick={() =>
                    handleClickSubItem({
                      arr: data?.disciplineChildren,
                      index,
                      addOrDelete: {
                        ...item,
                        selected: item?.selected ? false : true,
                      },
                    })
                  }
                >
                  <span>{item?.label}</span>
                  <PlusOutlined />
                </S.ButtonSub>
              </li>
            ))}
          </ul>
        </S.Expanded>
      ) : null}
    </S.Wrapper>
  );
};
