import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Select, Radio } from "antd";
import { useRegistrationFlowFull } from "../../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { hasError, clearErrorInput } from "../../../utils/errorsInput";
import { Input } from "../../../../../components/UI/Input";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { SelectOne } from "../../../../../components/UI/SelectOne";

import * as S from "./styles";

export const AwardsForm = ({
  contextPage,
  partnerId,
  refreshListAwards,
  isEditing,
  clearEditing,
  isMartecAdtech,
  sendOk,
}) => {
  const { postAward, putAward, getIndustriesSelect } =
    useRegistrationFlowFull();
  const [inputsForm, setInputsForm] = useState({
    name: null,
    jobTitle: null,
    brand: null,
    year: null,
    industry: null,
    product: null,
    video: null,
    link: null,
    type: null,
    id: null,
  });
  const [typeCheckbox, setTypeCheckbox] = useState(1);
  const [isValidLink, setIsValidLink] = useState(false);
  const [isValidVideo, setIsValidVideo] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [clearingInputs, setClearingInputs] = useState(false);

  useEffect(() => {
    if (isEditing?.id) {
      setInputsForm({
        id: isEditing?.id,
        name: isEditing?.name,
        jobTitle: isEditing?.jobTitle,
        brand: isEditing?.brand,
        year: isEditing?.year,
        industry: isEditing?.industry,
        product: isEditing?.product,
        video: isEditing?.video,
        link: isEditing?.link,
        type: isEditing?.type,
      });
      setTypeCheckbox(isEditing?.type);
      setInputsErrorsForm([]);
    }
  }, [isEditing]);

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const clearInputs = async () => {
    setInputsForm({
      name: null,
      jobTitle: null,
      brand: null,
      year: null,
      industry: null,
      product: null,
      video: null,
      link: null,
      type: null,
      id: null,
    });

    setTypeCheckbox(1);
    clearEditing();
  };

  const emptyInputs = async () => {
    setClearingInputs(true);
    await clearInputs();
    setClearingInputs(false);
  };

  const onChangeSelectIndustry = (value) => {
    onChangeInputForm("industry", value);
  };

  const onChangeSelectType = (e) => {
    setTypeCheckbox(e.target.value);
  };

  const send = async () => {
    setIsSending(true);
    const dataSend = {
      ...inputsForm,
      adminAction: contextPage === "admin" ? true : false,
      partnerId: contextPage === "admin" ? partnerId : null,
      type: typeCheckbox,
    };
    delete dataSend.id;
    const response = await postAward({
      admin: contextPage === "admin" ? true : false,
      form: dataSend,
    });
    if (response.success) {
      await emptyInputs();
      refreshListAwards();

      if (contextPage === "edit" || contextPage === "admin") {
        sendOk(true);
      }
    } else {
      const errors = [];
      Object.keys(response?.errors).forEach(function (key, index) {
        errors.push(response?.errors[key]);
      });
      setInputsErrorsForm(errors);
    }

    setIsSending(false);
  };

  const update = async () => {
    setIsSending(true);
    const dataSend = {
      ...inputsForm,
      adminAction: contextPage === "admin" ? true : false,
      partnerId: contextPage === "admin" ? partnerId : null,
    };
    const response = await putAward({
      admin: contextPage === "admin" ? true : false,
      form: dataSend,
    });
    if (response.success) {
      await emptyInputs();
      refreshListAwards();

      if (contextPage === "edit" || contextPage === "admin") {
        sendOk(true);
      }
    } else {
      const errors = [];
      Object.keys(response?.errors).forEach(function (key, index) {
        errors.push(response?.errors[key]);
      });
      setInputsErrorsForm(errors);
    }

    setIsSending(false);
  };

  const checkValidLink = (link) => {
    if (!link) return true;
    const regex =
      /^(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return regex.test(link);
  };

  const checkVideoLink = (link) => {
    if (!link) return true;
    if (link.indexOf("youtube.com") !== -1 || link.indexOf("youtu.be") !== -1) {
      return "youtube";
    } else if (link.indexOf("vimeo.com") !== -1) {
      return "vimeo";
    } else {
      return null;
    }
  };

  const getIndustries = async () => {
    const responseCategories = await getIndustriesSelect({
      admin: contextPage === "admin" ? true : false,
      type: typeCheckbox,
    });
    if (responseCategories.success) {
      const categories = responseCategories.data.map((category) => ({
        code: category.id,
        label: category.description,
      }));
      setIndustries(categories);
    }
  };

  const createDateArray = () => {
    var currentDate = new Date();
    var startDate = new Date(1900, 0, 1);
    var yearArray = [];

    while (startDate <= currentDate) {
      yearArray.push({
        code: startDate.getFullYear().toString(),
        label: startDate.getFullYear().toString(),
      });
      startDate.setFullYear(startDate.getFullYear() + 1);
    }

    const sortedArray = yearArray.sort((a, b) => {
      if (b.code > a.code) {
        return 1;
      } else if (b.code < a.code) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedArray;
  };

  useEffect(() => {
    getIndustries();
    console.log("isMartecAdtech", isMartecAdtech);
  }, [isMartecAdtech, typeCheckbox]);

  return (
    <S.WrapperForm>
      <S.Title>
        <span>{I18n.get("Award")}</span>
      </S.Title>
      <S.ContentForm>
        <Row>
          <Col xs={24} sm={12}>
            <Radio.Group onChange={onChangeSelectType} value={typeCheckbox}>
              <Radio value={1}>{I18n.get("Company")}</Radio>
              <Radio value={2}>{I18n.get("Leadership")}</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row style={{ marginTop: "30px" }}>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Name")}
              label
              required
              value={inputsForm.name}
              onChange={(value) => onChangeInputForm("name", value)}
              error={hasError({ field: "name", arr: inputsErrorsForm })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "name",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Job Title")}
              label
              required
              value={inputsForm.jobTitle}
              onChange={(value) => onChangeInputForm("jobTitle", value)}
              error={hasError({ field: "jobTitle", arr: inputsErrorsForm })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "jobTitle",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Brand")}
              label
              required
              value={inputsForm.brand}
              onChange={(value) => onChangeInputForm("brand", value)}
              error={hasError({ field: "brand", arr: inputsErrorsForm })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "brand",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <SelectOne
              options={createDateArray()}
              value={inputsForm.year}
              defaultValue={isEditing?.id ? inputsForm.year : ""}
              onChange={(value) => onChangeInputForm("year", value)}
              required
              placeholder={I18n.get("Select year")}
              error={hasError({ field: "year", arr: inputsErrorsForm })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "year",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <SelectOne
              options={industries}
              value={inputsForm.industry}
              defaultValue={isEditing?.id ? inputsForm.industry : ""}
              onChange={(value) => onChangeSelectIndustry(value)}
              required
              placeholder={I18n.get("Category")}
              error={hasError({ field: "industry", arr: inputsErrorsForm })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "industry",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Product")}
              label
              required
              value={inputsForm.product}
              onChange={(value) => onChangeInputForm("product", value)}
              error={hasError({ field: "product", arr: inputsErrorsForm })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "product",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Vídeo")}
              label
              value={inputsForm.video}
              onChange={(value) => onChangeInputForm("video", value)}
              onBlur={(value) =>
                checkVideoLink(value)
                  ? setIsValidVideo(null)
                  : setIsValidVideo(I18n.get("Invalid link video"))
              }
              error={hasError({ field: "video", arr: inputsErrorsForm })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "video",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Link")}
              label
              required
              value={inputsForm.link}
              onChange={(value) => {
                onChangeInputForm("link", value);
                setIsValidLink(null);
              }}
              onBlur={(value) =>
                checkValidLink(value)
                  ? setIsValidLink(null)
                  : setIsValidLink(I18n.get("Invalid link"))
              }
              error={hasError({ field: "link", arr: inputsErrorsForm })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "link",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}></Col>

          {isEditing?.id ? (
            <Col xs={5}>
              <S.ButtonCancel>
                <button onClick={emptyInputs}>{I18n.get("Cancel")}</button>
              </S.ButtonCancel>
            </Col>
          ) : null}
          <Col xs={{ span: 7, offset: isEditing?.id ? 0 : 5 }}>
            <ButtonBpool
              text={isEditing?.id ? I18n.get("Save award") : I18n.get("Add")}
              theme="primary"
              full
              loading={isSending}
              onClick={isEditing?.id ? () => update() : () => send()}
            />
          </Col>
        </Row>
      </S.ContentForm>
    </S.WrapperForm>
  );
};
