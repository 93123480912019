import {
    LOGIN,
    LOGOUT
} from '../actions/auth/actions';

const initialState = {};

export default function clientReducer(state = initialState, action) {

    if (action.type == LOGIN || action.type == LOGOUT) return initialState;
    
    if (action.type.indexOf('client_') == -1) return state;

    return Object.assign({}, state, action.payload);
}