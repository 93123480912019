import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { Radio } from 'antd';

import * as S from './styles';
import IcoInfo from '../../../../../media/svg/ico-info.svg';

const selects = [
  {
    id: 1,
    label: 'Chemistry',
    value: 4,
    disabled: true,
    textDisabled: {
      title: 'Chemistry já finalizado!',
      info: 'Nesta fase do seu projeto você só consegue agendar Briefing ou Presentation.',
    },
  },
  {
    id: 2,
    label: 'Briefing',
    value: 5,
    disabled: true,
    textDisabled: {
      title: 'Estapa de Briefing não habilitado!',
      info: 'Você precisa finalizar o Chemistry Meeting para ter acesso.',
    },
  },
  {
    id: 3,
    label: 'Presentation',
    value: 6,
    disabled: true,
    textDisabled: {
      title: 'Estapa de Presentation não habilitado!',
      info: 'Você precisa finalizar a etapa de Briefing para ter acesso.',
    },
  },
];

export const RadioSelectMeeting = ({
  valueSelect,
  setValueSelect,
  selectedProject,
  typeLabelMeetingSelect,
}) => {
  const [optsSelects, setOptsSelects] = useState(selects);

  useEffect(() => {
    if (valueSelect) {
      if (valueSelect === 4) {
        const newStateChemistry = selects.map((obj) =>
          obj.value === 4 ? { ...obj, disabled: false } : obj
        );

        setOptsSelects(newStateChemistry);
      } else {
        const newStateNotChemistry = selects.map((obj) =>
          obj.value !== 4 ? { ...obj, disabled: false } : obj
        );

        setOptsSelects(newStateNotChemistry);
      }
    }

    const labelMeetingSelect = selects.find(
      (item) => item.value === valueSelect
    );
    typeLabelMeetingSelect(labelMeetingSelect?.label || '');
  }, [valueSelect]);

  const onChange = (e) => {
    setValueSelect(e.target.value);
  };

  return (
    <S.Wrapper>
      <Radio.Group
        onChange={onChange}
        value={valueSelect}
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        {optsSelects.map((select) => (
          <S.ContentCheckbox>
            {!select.disabled ? (
              <Radio
                key={select.id}
                value={select.value}
                disabled={select.disabled}
              >
                {select.label}
              </Radio>
            ) : (
              <S.TooltipCard>
                <S.TooltipText>
                  <Radio
                    key={select.id}
                    value={select.value}
                    disabled={select.disabled}
                  >
                    {select.label}
                  </Radio>
                </S.TooltipText>
                {selectedProject ? (
                  <S.TooltipBox>
                    <S.TooltipContent>
                      <div>
                        <img src={IcoInfo} alt="Ico confirm tooltip" />
                      </div>
                      <S.TooltipContentInfos>
                        <strong>{I18n.get(select.textDisabled.title)}</strong>
                        <p>{I18n.get(select.textDisabled.info)}</p>
                      </S.TooltipContentInfos>
                    </S.TooltipContent>
                  </S.TooltipBox>
                ) : null}
              </S.TooltipCard>
            )}
          </S.ContentCheckbox>
        ))}
      </Radio.Group>
    </S.Wrapper>
  );
};
