import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { formatCurrency, formatMoney2, formatMoney } from '../../../utils/helpers'

import {
    Row,
    Col,
    Button,
    Table,
    Input,
    Switch,
    Icon,
    Select,
    Form
} from 'antd';


const { Search } = Input;
const { Option } = Select;


const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
      margin-right: 20px;
  }
`;


class FormBundles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            formState: [],
            variations: []
        }
    }

  

   

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.setState({
                    formState: values,
                })

                let data = {
                    ...values,
                    variations: this.state.variations
                }

                delete data['variationCode'];
                delete data['variationEnabled'];
                delete data['variationAssets'];
                this.props.action(data);
            }
        });

    };

    componentDidMount() {
        this.setState({
            loading: true
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {

                if(field == "variations"){
                    this.props.form.setFields({
                        variationAssets: {
                            value: this.state.formState[field],
                            errors: [new Error(this.props.errors[field].errorMessage)],
                        },
                    });
                } else {

                    this.props.form.setFields({
                        [field]: {
                            value: this.state.formState[field],
                            errors: [new Error(this.props.errors[field].errorMessage)],
                        },
                    });
                }
            });
        }
        
        if(this.props.item && this.state.loading != prevState.loading){

            let vitems = this.props.item.variations && this.props.item.variations.map((item,index) => {
                return {
                    key: index,
                    ...item
                }
            })

            this.setState({
                variations: vitems
            })
        }
    }

    delete = (item) => {
        const index = this.state.variations.indexOf(item);
        const newVariations = this.state.variations.slice();
        newVariations.splice(index, 1);

        this.setState({
            variations: newVariations,
        })
    }

    handleActive = (e,indice) => {


        let arrayObj = this.state.variations;

        arrayObj.forEach(item => {

            
            if(item.key == indice){

                item.enabled = e;
            }
        });



        this.setState({ variations: arrayObj })


    }

    handleAddVariations = () => {

        const code = this.props.form.getFieldValue("variationCode");
        const enabled = this.props.form.getFieldValue("variationEnabled");
        const assets = this.props.form.getFieldValue("variationAssets");
        const description = this.props.form.getFieldValue("variationDescription");


        let resolveAsset = assets.map((item,index) => {

            const asset = this.props.assets && this.props.assets.find((s) => s.code == item) || {};

            return asset;
        })

        let total = resolveAsset.reduce((acumulador, asset, indice, original) => {
            return acumulador + asset.price;
        }, 0)


        const dataVariation = {
            key: this.state.variations && this.state.variations.length + 1,
            code: code,
            enabled: enabled,
            description: description,
            assets: assets.map(item => {
                return {
                    asset: item
                }
            }),
            price: total
        }

        if(this.state.variations && this.state.variations.length > 0){
            this.setState({
                variations: [
                    ...this.state.variations,
                    dataVariation,
                ]
            });
        } else {
            this.setState({
                variations: [
                    dataVariation,
                ]
            });
        }


        setTimeout(() => {
            this.props.form.setFieldsValue({
                variationCode: "",
                variationDescription: "",
                variationEnabled: true,
                variationAssets: [],
            });            
        }, 200);


    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const columns = [

    {
        title: I18n.get("Ativo"),
        dataIndex: 'enabled',
        width: "100px",
        key: 'enabled',
        render: (enable, record) => {     
            
            // <Switch loading={this.props.task.busy} onChange={(e) => this.handleActive(e,record.key)} checkedChildren="SIM" unCheckedChildren="Não" checked={enable} />
            return(
                <Select defaultValue={enable} onChange={(e) => this.handleActive(e,record.key)}>
                    <Option value={true}>{I18n.get("SIM")}</Option>
                    <Option value={false}>{I18n.get("NÃO")}</Option>
                </Select>
            ) 
        }
    },    
    {
        title: I18n.get("Assets"),
        dataIndex: 'assets',
        key: 'assets',
        render: (assets) => {
            return(
                <p className="listAssets">
                    {assets.map((item,index) => {

                        const asset = this.props.assets && this.props.assets.find((s) => s.code == item.asset) || {};

                        return(
                            <span>
                                {asset.label}<i>, </i>{" "}
                            </span>
                        )
                    })}
                </p>
            )
        }
    },
    {
        title: I18n.get("Valor"),
        width: "150px",
        dataIndex: 'price',
        key: 'price',
        render: (price) => {
            return <p>{formatCurrency(price)}</p>
        },
    },
    {
        title: '',
        dataIndex: 'actions',
        key: 'item',
        width: "80px",
        render: (text, record) => {
            return (
                <a onClick={() => this.delete(record)}>
                    <Icon type="delete" theme="filled" />
                </a>
            );
        }
    }

]

        let dataTable = this.state.variations && this.state.variations.map((item,index) => {
            return {
                key: index,
                ...item
            }
        });

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row className="RowSection">
             
                    <Col sm={16}>
                        <Form.Item label={I18n.get("Título")}>
                            {getFieldDecorator('title', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.title ? this.props.item.title : ""
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={8}>
                        <Form.Item label={I18n.get("Ativo")}>
                            {getFieldDecorator('enabled', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: Object.keys(this.props.item).length > 0 ? this.props.item.enabled : true
                            })(
                                <Select>
                                    <Option value={true}>{I18n.get("SIM")}</Option>
                                    <Option value={false}>{I18n.get("NÃO")}</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                     
                    <Col sm={16}>
                        <Form.Item label={I18n.get("Tipo")}>
                            {getFieldDecorator('assetType', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.assetType ? this.props.item.assetType : []
                            })(
                                 <Select
                                    showSearch={true}
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    tokenSeparators={[',']}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.assetTypes && this.props.assetTypes.map((item,index) => {
                                        return <Option value={item.code} key={item.code}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row type="flex">
                    <Col sm={12}>
                        <Form.Item label={I18n.get("Especialidades")}>
                            {getFieldDecorator('specialism', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.specialism ? this.props.item.specialism : []
                            })(
                                 <Select
                                    showSearch={true}
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    tokenSeparators={[',']}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.assetSpecialisms && this.props.assetSpecialisms.map((item,index) => {
                                        return <Option value={item.code} key={item.code}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>  

                    <Col sm={12}>
                        <Form.Item label={I18n.get("Especialidades do parceiro")}>
                            {getFieldDecorator('partnerSpecialism', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.item && this.props.item.partnerSpecialism ? this.props.item.partnerSpecialism : []
                            })(
                                 <Select
                                    showSearch={true}
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    tokenSeparators={[',']}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.partnerSpecialismsArray && this.props.partnerSpecialismsArray.map((item,index) => {
                                        return <Option value={item.code} key={item.code}>{item.label}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>     
                </Row>



                <Row type="flex">
                    <Col sm={24}>
                        <h3>{I18n.get("Variações")}</h3>
                    </Col>
                    {/* <Col sm={5}>
                         <Form.Item label={I18n.get("Código")}>
                            {getFieldDecorator('variationCode', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                            })(
                                 <Input />
                            )}
                        </Form.Item>
                    </Col> */}
                    <Col sm={5}>
                        <Form.Item label={I18n.get("Ativo")}>
                            {getFieldDecorator('variationEnabled', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: true
                            })(
                                <Select>
                                    <Option value={true}>{I18n.get("SIM")}</Option>
                                    <Option value={false}>{I18n.get("NÃO")}</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={19}>
                        <Form.Item label={I18n.get("Assets")}>
                            {getFieldDecorator('variationAssets', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }]
                            })(
                                 <Select
                                    showSearch={true}
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    tokenSeparators={[',']}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.assets && this.props.assets.map((item,index) => {
                                        return <Option value={item.code} key={item.code}>{`${item.label} - ${item.price.toLocaleString('pt-br', {minimumFractionDigits: 2})} - ${item.unity}`}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <Form.Item label={I18n.get("Descrição")}>
                            {getFieldDecorator('variationDescription', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],                                
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Col> 
                    <Col sm={2}>
                            <Button
                            disabled={!getFieldValue("variationAssets") || !getFieldValue("variationDescription")}
                            className="button bt-bpool black" onClick={() => this.handleAddVariations()}>
                               + {I18n.get("Adicionar variação")}
                            </Button>  
                    </Col>
                </Row>
                 <Row type="flex">
                     <Col sm={24}>
                <br />
                <br />

                     </Col>
                 </Row>

                <Row type="flex">
                    <Col sm={24}>
                        <Table
                            columns={columns}
                            pagination={false}
                            dataSource={dataTable}
                            scroll={{ x: 640 }}
                            locale={{ emptyText: I18n.get('Sem informação') }}
                            footer={() => {
                                let total = this.state.variations && this.state.variations.reduce((acumulador, asset, indice, original) => {
                                    return acumulador + asset.price;
                                }, 0)

                                return <p><strong>{I18n.get('Total Variação')}:</strong> {formatCurrency(total)}</p>
                            }}
                        />
                    </Col>
                </Row>
               
                <RowSubmitModal>
                    <Col sm={24}>

                        <Button shape="round" onClick={() => this.props.onCancel()}>{I18n.get("Cancelar")}</Button>

                        <Button type="primary" shape="round" htmlType="submit" loading={this.props.task.busy}>
                            {this.props.item && Object.keys(this.props.item).length > 0 ? I18n.get("Atualizar") : I18n.get("Adicionar")}
                        </Button>
                    </Col>
                </RowSubmitModal>
            </Form>
        );
    }
}

const FormBundlesForm = Form.create({})(FormBundles);

export default FormBundlesForm;
