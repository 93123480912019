import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperSimple";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";
import TableCase from "../components/tableCases";
import TableAwards from "../components/tableAward";
import TableCause from "../components/tableCause";
import TablePortfolio from "../components/tablePortfolio";

import { LEADER_API } from "../../../actions/leader/actions";

import FormAddCase from "../components/formAddCase";
import FormEditCase from "../components/formEditCase";
import FormAwards from "../components/formAwards";
import FormCause from "../components/formCause";
import FormPortfolio from "../components/formPortfolio";

import { TitleBorder, TitleMiddleLine } from "../../components/Titles";

import styled from "styled-components";

import { PartnerStatus } from "../../../model";

import { QueryString } from "../../../utils/querystring";

import {
  Row,
  Col,
  Input,
  Select,
  Tag,
  Icon,
  notification,
  Button,
  Drawer,
  Form,
} from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .RowSection {
    margin-bottom: 60px;
  }
`;

const ContentAba = styled.div`
  .BtnAction {
    margin-bottom: 20px;
    text-align: right;
  }
`;

const BoxAba = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 30px 20px;
  background: #ececec;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s ease;
  border: solid 4px #ececec;
  position: relative;
  .ant-tag {
    position: absolute;
    right: -15px;
    top: -10px;
    font-weight: bold;
  }

  p {
    cursor: pointer;
  }

  &:hover,
  &.active {
    background: #dcdcdc;
    border-color: #777;
  }

  .icone {
    margin-bottom: 10px;
    font-size: 40px;
  }
`;

const RowAba = styled(Row)`
  margin: 50px 0;
`;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirm: (data) => {
      dispatch(LEADER_API.confirm(data));
    },
    save: (data) => {
      dispatch(LEADER_API.update(data));
    },
    get_company_info: (id) => {
      dispatch(LEADER_API.get_company_info(id));
    },
    addCase: (data) => {
      return dispatch(LEADER_API.create_case(data));
    },
    updateCase: (data) => {
      return dispatch(LEADER_API.update_case(data));
    },
    delCase: (data) => {
      return dispatch(LEADER_API.delete_case(data));
    },
    upload: (data) => {
      return LEADER_API.upload(data);
    },
    addAward: (data) => {
      dispatch(LEADER_API.create_award(data));
    },
    delAward: (data) => {
      dispatch(LEADER_API.delete_award(data));
    },
    updateAward: (data) => {
      dispatch(LEADER_API.update_award(data));
    },

    addPortfolio: (data) => {
      dispatch(LEADER_API.create_portfolio(data));
    },
    deletePortfolio: (data) => {
      dispatch(LEADER_API.delete_portfolio(data));
    },
    updatePortfolio: (data) => {
      dispatch(LEADER_API.update_portfolio(data));
    },
  };
};

class LeaderWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      activeTabResp: 1,
      showAddCase: false,
      showEditCase: false,
      showAddAwards: false,
      showAddCause: false,
      showAddPortfolio: false,
      editCaseItem: {},
      editAwardItem: {},
      editCauseItem: {},
      editPortfolioItem: {},
      showAwards: false,
      listImg: [],
      scape: "",
      editSelected: false,
      type: 1,
      sendRegister: false,
    };
  }

  onChangeTeamCase = (e, item, id) => {
    const value = e.target.checked && e.target.checked ? true : false;
    const data = Object.assign({}, item, {
      leaderId: this.props.match.params.leaderId,
    });

    const dataDelete = {
      leaderId: this.props.match.params.leaderId,
      id: item.id,
    };

    if (value) {
      let x = {
        Case: data,
        Locale: this.state.locale,
      };

      this.props.addCase(x);
    } else {
      this.props.delCase(dataDelete);
    }
  };

  onChangeTeamAward = (e, item, id) => {
    const value = e.target.checked && e.target.checked ? true : false;
    const data = Object.assign({}, item, {
      leaderId: this.props.match.params.leaderId,
    });

    const dataDelete = {
      leaderId: this.props.match.params.leaderId,
      id: item.id,
    };

    if (value) {
      let x = {
        Award: data,
        Locale: this.state.locale,
      };

      this.props.addAward(x);
    } else {
      this.props.delAward(dataDelete);
    }
  };

  showAddCase = (type) => {
    this.setState({
      showAddCase: true,
      type: type,
      sendRegister: false,
    });
  };

  showAddAwards = (type) => {
    this.setState({
      showAwards: true,
      type: type,
      sendRegister: false,
    });
  };

  showAddCause = (type) => {
    this.setState({
      showAddCause: true,
      type: type,
      sendRegister: false,
    });
  };

  showAddPortfolio = (type) => {
    this.setState({
      showAddPortfolio: true,
      type: type,
      sendRegister: false,
    });
  };

  closeDrawer = () => {
    this.setState({
      showAddCase: false,
      showEditCase: false,
      showAwards: false,
      showAddCause: false,
      showAddPortfolio: false,
      editAwardItem: {},
      editCauseItem: {},
      editPortfolioItem: {},
    });
  };

  showEditCase = () => {
    this.setState({
      showEditCase: true,
      sendRegister: false,
    });
  };

  hideEditCase = () => {
    this.setState({
      showEditCase: false,
      editCaseItem: null,
      sendRegister: false,
    });

    setTimeout(() => {
      this.setState({
        listImg: [],
        scape: "",
      });
    }, 400);
  };

  handleEditCase = (item) => {
    this.setState({
      editCaseItem: {
        ...item,
        scape: Math.random().toString(36).substring(7),
      },
      type: item.type,
      editSelected: true,
      sendRegister: false,
    });

    setTimeout(() => {
      this.showEditCase();
    }, 200);
  };
  handleEditAward = (item) => {
    this.setState({
      editAwardItem: item,
      type: item.type,
      sendRegister: false,
    });

    setTimeout(() => {
      this.showAddAwards();
    }, 500);
  };

  handleEditPortfolio = (item) => {
    this.setState({
      editPortfolioItem: item,
      type: item.type,
      sendRegister: false,
    });

    setTimeout(() => {
      this.showAddPortfolio();
    }, 200);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.leader.cases &&
      prevProps.leader.cases &&
      this.props.leader.cases.length > prevProps.leader.cases.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("Case foi adicionado ao seu cadastro"),
        duration: 3,
      });

      this.closeDrawer();
    }

    if (
      this.props.leader.cases &&
      prevProps.leader.cases &&
      this.props.leader.cases.length < prevProps.leader.cases.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("Case foi removido do seu cadastro"),
        duration: 3,
      });
    }

    if (
      this.props.leader.cases &&
      prevProps.leader.cases &&
      this.props.leader.cases != prevProps.leader.cases &&
      this.state.editSelected
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("Case foi atualizado"),
        duration: 3,
      });

      this.setState({
        editSelected: false,
      });

      this.hideEditCase();
    }

    // AWARDS

    if (
      this.props.leader.awards &&
      prevProps.leader.awards &&
      this.props.leader.awards.length > prevProps.leader.awards.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("O prêmio foi adicionado ao seu cadastro"),
        duration: 3,
      });

      this.closeDrawer();
    }

    if (
      this.props.leader.awards &&
      prevProps.leader.awards &&
      this.props.leader.awards.length < prevProps.leader.awards.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("O prêmio foi removido do seu cadastro"),
        duration: 3,
      });
    }

    if (
      this.props.leader.awards &&
      prevProps.leader.awards &&
      this.props.leader.awards != prevProps.leader.awards &&
      Object.keys(this.state.editAwardItem).length > 0
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("O prêmio foi atualizado"),
        duration: 3,
      });

      this.setState({
        editAwardItem: {},
      });

      this.closeDrawer();
    }

    // CAUSAS

    if (
      this.props.leader.causes &&
      prevProps.leader.causes &&
      this.props.leader.causes.length > prevProps.leader.causes.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("A causa foi adicionada ao seu cadastro"),
        duration: 3,
      });

      this.closeDrawer();
    }

    if (
      this.props.leader.causes &&
      prevProps.leader.causes &&
      this.props.leader.causes.length < prevProps.leader.causes.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("A causa foi removida do seu cadastro"),
        duration: 3,
      });
    }

    if (
      this.props.leader.causes &&
      prevProps.leader.causes &&
      this.props.leader.causes != prevProps.leader.causes &&
      Object.keys(this.state.editCauseItem).length > 0
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("A causa foi atualizada"),
        duration: 3,
      });

      this.setState({
        selectedItem: null,
      });

      this.closeDrawer();
    }

    // PORTFOLIO

    if (
      this.props.leader.portfolios &&
      prevProps.leader.portfolios &&
      this.props.leader.portfolios.length > prevProps.leader.portfolios.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get("Portfolio foi adicionado ao seu cadastro"),
        duration: 3,
      });

      this.setState({
        editPortfolioItem: {},
      });

      this.closeDrawer();
    }

    if (
      this.props.leader.portfolios &&
      prevProps.leader.portfolios &&
      this.props.leader.portfolios.length < prevProps.leader.portfolios.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get("Portfolio foi removido do seu cadastro"),
        duration: 3,
      });
    }

    if (
      this.props.leader.portfolios &&
      prevProps.leader.portfolios &&
      this.props.leader.portfolios != prevProps.leader.portfolios &&
      Object.keys(this.state.editPortfolioItem).length > 0
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("Portfolio foi atualizado"),
        duration: 3,
      });

      this.setState({
        editPortfolioItem: {},
      });

      this.closeDrawer();
    }
  }

  handleFinish = () => {
    const data = {
      active: 0,
      isSubmit: true,
      leaderId: this.props.match.params.leaderId,
    };

    this.props.save(data);

    setTimeout(() => {
      this.setState({
        sendRegister: true,
      });
    }, 50);
  };

  componentDidMount = () => {
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    this.setState({ locale: locale });

    I18n.setLanguage(locale);

    let data = {
      verificationToken: this.props.match.params.token,
      locale: locale,
    };

    this.props.confirm(data);

    this.props.get_company_info(this.props.match.params.leaderId);
  };

  render() {
    if (this.props.leader && this.props.leader.completedOn) {
      return <Redirect to="/lider/cadastro/sucesso" />;
    }

    const { leader, partner } = this.props;

    //TODO: Find better way... GAMBI!
    let menu = [...constants.MENU_REGISTER_LEADERS];
    for (let key in menu) {
      menu[key].label = I18n.get(menu[key].label);
    }

    return (
      <AppWrapperSimple>
        <SubHeader
          titlePage=""
          itensMenu={menu}
          urlId={
            this.props.match.params.leaderId +
            "/" +
            this.props.match.params.token
          }
          activeItem={3}
          locale={this.state.locale}
        />
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("Líder - Cases & Prêmios")}</TitleBorder>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <TitleMiddleLine>
                  {I18n.get("Trabalhos realizados pela empresa")}
                </TitleMiddleLine>
              </Col>
              <Col sm={24}>
                <p>
                  {I18n.get(
                    "Sua empresa já cadastrou os cases e/ou prêmios abaixo. Sinalize na lista se você participou de algum deles."
                  )}
                </p>
              </Col>
            </Row>
            <RowAba>
              <Col sm={6}>
                <BoxAba
                  onClick={() => this.setState({ activeTab: 1 })}
                  className={this.state.activeTab == 1 ? "active" : ""}
                >
                  <Tag color="#000">
                    {leader.companyCases &&
                      leader.companyCases.filter((item) => item.type == 1)
                        .length}
                  </Tag>
                  <p className="icone">
                    <Icon type="star" theme="filled" />
                  </p>
                  <p>{I18n.get("Cases")}</p>
                </BoxAba>
              </Col>

              <Col sm={6}>
                <BoxAba
                  onClick={() => this.setState({ activeTab: 3 })}
                  className={this.state.activeTab == 3 ? "active" : ""}
                >
                  <Tag color="#000">
                    {leader.companyAwards &&
                      leader.companyAwards.filter((item) => item.type == 1)
                        .length}
                  </Tag>
                  <p className="icone">
                    <Icon type="trophy" theme="filled" />
                  </p>
                  <p>{I18n.get("PRÊMIOS")}</p>
                </BoxAba>
              </Col>
            </RowAba>

            {this.state.activeTab == 1 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <TableCase
                      data={
                        leader.companyCases &&
                        leader.companyCases.filter((item) => item.type == 1)
                      }
                      dataLeader={leader.cases && leader.cases}
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                      partnerId={
                        this.props.partner && this.props.partner.partnerId
                      }
                      delete={this.props.delCase}
                      edit={this.handleEditCase}
                      specialismsBP={
                        this.props.bp.specialisms && this.props.bp.specialisms
                      }
                      specialisms={partner.specialisms && partner.specialisms}
                      isLeader={true}
                      leaderId={this.props.leader && this.props.leader.leaderId}
                      clone={this.onChangeTeamCase}
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            {this.state.activeTab == 2 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <TablePortfolio
                      data={
                        partner.portfolios &&
                        partner.portfolios.filter((item) => item.type == 1)
                      }
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                      partnerId={
                        this.props.partner && this.props.partner.partnerId
                      }
                      delete={this.props.deletePortfolio}
                      edit={this.handleEditPortfolio}
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            {this.state.activeTab == 3 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <TableAwards
                      data={
                        leader.companyAwards &&
                        leader.companyAwards.filter((item) => item.type == 1)
                      }
                      dataLeader={leader.awards && leader.awards}
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                      partnerId={
                        this.props.partner && this.props.partner.partnerId
                      }
                      delete={this.props.delAward}
                      edit={this.handleEditAward}
                      specialismsBP={
                        this.props.bp.specialisms && this.props.bp.specialisms
                      }
                      specialisms={partner.specialisms && partner.specialisms}
                      isLeader={true}
                      leaderId={this.props.leader && this.props.leader.leaderId}
                      clone={this.onChangeTeamAward}
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            {this.state.activeTab == 4 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <TableCause
                      data={partner.causes && partner.causes}
                      partnerId={
                        this.props.partner && this.props.partner.partnerId
                      }
                      delete={this.props.deleteCause}
                      edit={this.handleEditCause}
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            <Row className="RowSection">
              <Col sm={8}></Col>
            </Row>

            <Row>
              <Col sm={24}>
                <TitleMiddleLine>
                  {I18n.get("Trabalhos realizados. Insira pelo menos um")}
                </TitleMiddleLine>
              </Col>
              <Col sm={24}>
                <p>
                  {I18n.get(
                    "Você pode enriquecer o perfil da sua empresa com um pouco de material pessoal. Selecione os campos abaixo para adicionar seu material."
                  )}
                </p>
              </Col>
            </Row>
            <RowAba>
              <Col sm={6}>
                <BoxAba
                  onClick={() => this.setState({ activeTabResp: 1 })}
                  className={this.state.activeTabResp == 1 ? "active" : ""}
                >
                  <Tag color="#000">
                    {leader.cases &&
                      this.props.leader.cases.filter((item) => item.type == 2)
                        .length}
                  </Tag>
                  <p className="icone">
                    <Icon type="star" theme="filled" />
                  </p>
                  <p>{I18n.get("Cases")}</p>
                </BoxAba>
              </Col>
              <Col sm={6}>
                <BoxAba
                  onClick={() => this.setState({ activeTabResp: 2 })}
                  className={this.state.activeTabResp == 2 ? "active" : ""}
                >
                  <Tag color="#000">
                    {leader.portfolios &&
                      this.props.leader.portfolios.filter(
                        (item) => item.type == 2
                      ).length}
                  </Tag>
                  <p className="icone">
                    <Icon type="bulb" />
                  </p>
                  <p>{I18n.get("PORTFOLIO")}</p>
                </BoxAba>
              </Col>
              <Col sm={6}>
                <BoxAba
                  onClick={() => this.setState({ activeTabResp: 3 })}
                  className={this.state.activeTabResp == 3 ? "active" : ""}
                >
                  <Tag color="#000">
                    {leader.awards &&
                      this.props.leader.awards.filter((item) => item.type == 2)
                        .length}
                  </Tag>
                  <p className="icone">
                    <Icon type="trophy" theme="filled" />
                  </p>
                  <p>{I18n.get("PRÊMIOS")}</p>
                </BoxAba>
              </Col>
              {/* <Col sm={6}>
                                <BoxAba onClick={() => this.setState({ activeTab: 4 })} className={this.state.activeTab == 4 ? "active" : ""}>
                                    <Tag color="#000">
                                        {leader.causes && this.props.leader.causes.length}
                                    </Tag>
                                    <p className="icone">
                                        <Icon type="flag" theme="filled" />
                                    </p>
                                    <p>
                                        CAUSAS
                                    </p>
                                </BoxAba>
                            </Col> */}
            </RowAba>

            {this.state.activeTabResp == 1 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <p className="BtnAction">
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => this.showAddCase(1)}
                      >
                        {I18n.get("+ Adicionar case")}
                      </Button>
                    </p>
                    <TableCase
                      data={
                        leader.cases &&
                        leader.cases.filter((item) => item.type == 2)
                      }
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                      partnerId={
                        this.props.leader && this.props.leader.leaderId
                      }
                      delete={this.props.delCase}
                      edit={this.handleEditCase}
                      specialismsBP={
                        this.props.bp.specialisms && this.props.bp.specialisms
                      }
                      specialisms={leader.specialisms && leader.specialisms}
                      isLeaderAction={true}
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            {this.state.activeTabResp == 2 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <p className="BtnAction">
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => this.showAddPortfolio(1)}
                      >
                        {I18n.get("+ Adicionar portfolio")}
                      </Button>
                    </p>
                    <TablePortfolio
                      data={
                        leader.portfolios &&
                        leader.portfolios.filter((item) => item.type == 2)
                      }
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                      partnerId={
                        this.props.leader && this.props.leader.leaderId
                      }
                      delete={this.props.deletePortfolio}
                      edit={this.handleEditPortfolio}
                      isLeader={true}
                      specialismsBP={
                        this.props.bp.specialisms && this.props.bp.specialisms
                      }
                      specialisms={partner.specialisms && partner.specialisms}
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            {this.state.activeTabResp == 3 && (
              <Row>
                <Col sm={24}>
                  <ContentAba>
                    <p className="BtnAction">
                      <Button
                        type="primary"
                        onClick={() => this.showAddAwards(1)}
                        shape="round"
                      >
                        {I18n.get("+ Adicionar prêmio")}
                      </Button>
                    </p>
                    <TableAwards
                      data={
                        this.props.leader.awards && this.props.leader.awards
                      }
                      sectorsFlat={
                        this.props.bp.sectorsFlat && this.props.bp.sectorsFlat
                      }
                      partnerId={
                        this.props.leader && this.props.leader.leaderId
                      }
                      delete={this.props.delAward}
                      edit={this.handleEditAward}
                      specialismsBP={
                        this.props.bp.specialisms && this.props.bp.specialisms
                      }
                      specialisms={leader.specialisms && leader.specialisms}
                      isLeaderAction={true}
                    />
                  </ContentAba>
                </Col>
              </Row>
            )}

            <Row className="RowSection">
              <Col sm={8}></Col>
            </Row>

            {this.state.sendRegister &&
              this.props.leader.errors &&
              Object.keys(this.props.leader.errors).length > 0 && (
                <div>
                  <Row className="RowSection">
                    <Col sm={8}></Col>
                  </Row>

                  <Row className="">
                    <Col sm={24}>
                      <div className="ulErrors">
                        <ul>
                          {Object.keys(this.props.leader.errors).map((item) => {
                            const error =
                              this.props.leader.errors[item].errorMessage;

                            return (
                              <li>
                                <Icon type="exclamation-circle" /> {error}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

            <Row className="RowSection">
              <Col sm={8}></Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Link
                  to={`/lider/cadastro/expertise/${this.props.match.params.leaderId}/${this.props.match.params.token}?locale=${this.state.locale}`}
                >
                  <Button shape="round">{I18n.get("Voltar")}</Button>
                </Link>
              </Col>
              <Col sm={18}></Col>
              <Col sm={3}>
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => this.handleFinish()}
                >
                  {I18n.get("Finalizar")}
                </Button>
              </Col>
            </Row>
          </Main>
        </ContainerPage>

        <Drawer
          title={I18n.get("Adicionar Case")}
          placement="right"
          closable={true}
          onClose={() => this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.showAddCase}
          width="60%"
        >
          <FormAddCase
            specialisms={partner.specialisms && partner.specialisms}
            specialismsBP={
              this.props.bp.specialisms && this.props.bp.specialisms
            }
            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
            addCase={this.props.addCase}
            onCancel={this.closeDrawer}
            upload={this.props.upload}
            id={this.props.leader.leaderId}
            errors={this.props.leader.errors && this.props.leader.errors}
            type={2}
            isLeader={true}
            get2={true}
            locale={this.state.locale}
          />
        </Drawer>

        <Drawer
          title={I18n.get("Editar Case")}
          placement="right"
          forceRender={true}
          closable={true}
          onClose={() => this.hideEditCase()}
          destroyOnClose={true}
          visible={this.state.showEditCase}
          width="60%"
        >
          <FormEditCase
            specialisms={partner.specialisms && partner.specialisms}
            specialismsBP={
              this.props.bp.specialisms && this.props.bp.specialisms
            }
            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
            updateCase={this.props.updateCase}
            onCancel={() => this.hideEditCase()}
            upload={this.props.upload}
            id={this.props.leader.leaderId}
            errors={this.props.leader.errors && this.props.leader.errors}
            type={2}
            item={this.state.editCaseItem}
            listImg={this.state.listImg}
            isLeader={true}
            get2={true}
            locale={this.state.locale}
          />
        </Drawer>

        <Drawer
          title={
            Object.keys(this.state.editAwardItem).length > 0
              ? I18n.get("Editar prêmio")
              : I18n.get("Adicionar prêmio")
          }
          placement="right"
          closable={true}
          onClose={() => this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.showAwards}
          width="50%"
        >
          <FormAwards
            specialisms={leader.specialisms && leader.specialisms}
            specialismsBP={
              this.props.bp.specialisms && this.props.bp.specialisms
            }
            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
            action={this.props.addAward}
            cancel={this.closeDrawer}
            update={this.props.updateAward}
            partnerId={this.props.leader.leaderId}
            item={this.state.editAwardItem}
            errors={this.props.leader.errors && this.props.leader.errors}
            type={2}
            forceRender={true}
            isLeader={true}
            get2={true}
            locale={this.state.locale}
          />
        </Drawer>

        <Drawer
          title={
            Object.keys(this.state.editPortfolioItem).length > 0
              ? I18n.get("Editar portfolio")
              : I18n.get("Adicionar portfolio")
          }
          placement="right"
          closable={true}
          onClose={() => this.closeDrawer()}
          destroyOnClose={true}
          visible={this.state.showAddPortfolio}
          width="50%"
        >
          <FormPortfolio
            specialismsBP={
              this.props.bp.specialisms && this.props.bp.specialisms
            }
            specialisms={partner.specialisms && partner.specialisms}
            action={this.props.addPortfolio}
            cancel={this.closeDrawer}
            update={this.props.updatePortfolio}
            partnerId={this.props.leader.leaderId}
            item={this.state.editPortfolioItem}
            errors={this.props.leader.errors && this.props.leader.errors}
            type={2}
            forceRender={true}
            get2={true}
            isLeader={true}
            locale={this.state.locale}
          />
        </Drawer>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderWorks);
