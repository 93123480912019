import React from "react";
import { I18n } from "aws-amplify";
import { Icon } from "antd";

import * as S from "./styles";

export const CartTotals = ({ data, isLoading, otherTax, assetTax }) => {
  return (
    <S.Wrapper>
      {isLoading ? (
        <Icon sizeIcon={14} color="#000" type="loading" />
      ) : (
        <>
          {assetTax || otherTax ? (
            <S.ParcialText>
              {I18n.get("Valor parcial do projeto:")}{" "}
              <strong>{data?.priceClientWithDiscountFmt || "--"}</strong>
            </S.ParcialText>
          ) : null}

         
{/* 
          {assetTax || otherTax ? (
            <S.ParcialText>
              {I18n.get("Subtotal:")}{" "}
              <strong>
                {data?.priceClientWithDiscountWithOtherTaxFmt || "--"}
              </strong>
              <br />
              {otherTax ? (
                <span>
                  ({I18n.get("incluyendo gross up")} ${otherTax}%)
                </span>
              ) : null}
            </S.ParcialText>
          ) : null} */}

          <S.TotalText>
            <strong>
              {I18n.get("Valor total do projeto:")}{" "}
              <span>{data?.priceClientWithDiscountWithOtherTaxFmt || "--"}</span>
            </strong>
            {otherTax ? (
              <span>
                ({I18n.get("incluyendo gross up")} ${otherTax}%)
              </span>
            ) : null}
          </S.TotalText>

          {assetTax > 0 && (
            <S.IvaText>
              {I18n.get("Impostos")} <span>({`IVA ${assetTax}%`}</span>):{" "}
              <strong>{`${
                data?.priceClientWithDiscountTaxFmt || "--"
              }`}</strong>
            </S.IvaText>
          )}
        </>
      )}
    </S.Wrapper>
  );
};
