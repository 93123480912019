import styled from 'styled-components';
import { Radio } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  .ant-radio-group .ant-radio-wrapper {
    margin-right: 20px;
  }

  .ant-radio-group .ant-radio-inner {
    :after {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      top: 4px;
      left: 4px;
    }
  }

  .ant-radio-group .ant-radio-checked .ant-radio-inner {
    border-color: #000000;

    :after {
      background-color: #1883ff;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      top: 4px;
      left: 4px;
    }
  }

  .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
    font-size: 24px;
    width: 24px;
    height: 24px;
    background: #f3f3f3;
  }

  .ant-radio-group .ant-radio-wrapper span {
    font-family: 'Helvetica Neue LT Std';
    font-weight: 700;
    color: #000000;
    font-size: 24px;
  }

  .ant-radio-group .ant-radio-wrapper-checked span {
    color: #1883ff;
  }

  .ant-radio-group .ant-radio-wrapper-disabled span {
    color: #979797;
  }
`;

export const ContentCheckbox = styled.div``;

export const TooltipText = styled.div`
  width: auto;
  text-align: center;
  line-height: 44px;
  border-radius: 3px;
  cursor: pointer;

  h3 {
    color: #979797;
  }
`;
export const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + -140px);
  left: 0;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 460px;
  padding: 5px 5px;
  border-radius: 29px;

  transition: all 0.1s ease-in-out;
  z-index: 99;

  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 40px;
    bottom: -10px;
    position: absolute;

    border: 10px solid transparent;
    transform: rotate(315deg);
    transition: all 0.1s ease-in-out;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
`;

export const TooltipContentInfos = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    line-height: 23px;
  }

  strong {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    line-height: 23px;
  }
`;

export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 490px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`;
