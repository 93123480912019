import React from 'react';
import { I18n } from 'aws-amplify';
import { Table, Button } from 'antd';
import EditIcon from './icons/edit.png';
import DeleteIcon from './icons/delete.png';
import * as S from './styles';

export const TableUsers = ({
  loaderUsers,
  usersData,
  handleEditUser,
  handleDeleteUser,
  loadingEditUser,
}) => {
  const columns = [
    {
      title: I18n.get('Nome'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: I18n.get('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: I18n.get('Ações'),
      key: 'action',
      width: '150px',
      render: (record) => (
        <S.ActionsTable>
          <Button
            type="primary"
            loading={loadingEditUser === record.id ? true : false}
            onClick={() => handleEditUser(record.id, record.name, record.email)}
          >
            <img alt="edit user" src={EditIcon} />
          </Button>
          <Button
            type="danger"
            onClick={() =>
              handleDeleteUser(record.id, record.name, record.email)
            }
          >
            <img alt="delete user" src={DeleteIcon} />
          </Button>
        </S.ActionsTable>
      ),
    },
  ];

  return (
    <>
      <S.Title>
        <p>{I18n.get('Usuários')}</p>
      </S.Title>

      <S.TableWrapper>
        <Table
          loading={loaderUsers}
          dataSource={usersData}
          columns={columns}
          pagination={false}
        />
      </S.TableWrapper>
    </>
  );
};
