import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import { usePartners } from "../../../../../hooks/usePartners/usePartners.hook";
import { useSessionStorage } from "../../../../../hooks/useSessionStorage/useSessionStorage.hook";
import { useSelector, useDispatch } from "react-redux";
import { getPartners as getPartnersState } from "../../../../../store/actions/partners";
import { I18n } from "aws-amplify";
import { Row, Col, Tooltip } from "antd";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import Content from "../../../../../components/UI/Content";
import { H2Border } from "../../../../../components/UI/Titles";
import Loading from "../../../../../components/UI/Loading";
import Main from "../../components/Main";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { StepsSelectPartner } from "../components/StepsSelectPartner";
import { Card } from "../components/Card";
import infoIcon from "../imgs/info.png";
import GlobalFileBox from "../../../../Client/CreateProject/components/GlobalFileBox";

import {
  KEY_PARTNERS_RATES,
  KEY_PARTNER_WINNER,
} from "../constants/keys.storage";

import * as S from "./styles";

export const SelectPartnerStep1 = () => {
  let params = useParams();
  let history = useHistory();

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const { getPartners } = usePartners();
  const { putGlobal, getProjectById, removeGlobal } = useProjects();
  const { remove } = useSessionStorage();

  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { data: dataPartners } = useSelector((state) => state.partners);

  const loadPartners = async () => {
    try {
      setIsLoading(true);
      const projectView = await getProjectById({ projectId: params.projectId });

      setProject(projectView?.data);

      const response = await getPartners({ id: params.projectId });
      if (response?.success) {
        dispatch(getPartnersState(response?.data));
      } else {
        dispatch(getPartnersState([]));
        setError("Error.");
      }
      setIsLoading(false);
    } catch (error) {
      dispatch(getPartnersState([]));
      setError("Error.");
    }
  };

  const emptyStorage = async () => {
    await remove({ key: KEY_PARTNER_WINNER });
    await remove({ key: KEY_PARTNERS_RATES });
  };

  const saveGlobal = async (data) => {
    const dataSend = {
      file: { ...data },
      projectId: projectId,
    };

    if (data?.id) {
      const response = await putGlobal(dataSend);
      if (response?.success) {
        const projectView = await getProjectById({
          projectId: params.projectId,
        });

        setProject(projectView?.data);
      }
    } else {
      const response = await removeGlobal(dataSend);
      if (response?.success) {
        const projectView = await getProjectById({
          projectId: params.projectId,
        });

        setProject(projectView?.data);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!dataPartners?.length && projectId) {
      loadPartners();
      emptyStorage();
    }
  }, [projectId]);

  useEffect(() => {
    setProjectId(params.projectId);
  }, [params]);

  const text = (
    <div>
      <ul>
        <li>
          {I18n.get(
            "Passo 1: Navegue pelos perfis e escolha até 3 parceiros para os Chemistry Meetings."
          )}
        </li>
        <li style={{ marginTop: 10 }}>
          {I18n.get(
            "Passo 2: Ao finalizar, selecione seu parceiro e faça uma breve avaliação do perfil dos parceiros exibidos no match."
          )}
        </li>
      </ul>
    </div>
  );

  const visibleGlobal = project?.productRequiredGlobalAuthorization;
  const globalAuthorization = project?.globalAuthorization;

  const disabledSelected = visibleGlobal && !globalAuthorization;

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Projetos")}</H2Border>
            </Col>
            {/* <Col sm={12} justify="end">
              <ButtonBpool
                text={I18n.get("New Project")}
                className="pull-right"
                theme="primary"
                onClick={() =>
                  history.push(`/cliente/projetos/v2/criar-projeto`)
                }
                icon={IcoPlus}
              />
            </Col> */}
          </Row>

          <Row>
            <Col sm={24}>
              <S.WrapperSteps>
                <StepsSelectPartner
                  active={1}
                  projectId={params.projectId}
                  disabledTabs={[2, 3]}
                />
              </S.WrapperSteps>
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={12} offset={6}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Escolha os parceiros")}</S.H4>
                        <Tooltip placement="topLeft" title={text}>
                          <img alt="info" src={infoIcon} />
                        </Tooltip>
                      </S.TitleWithIcon>
                      <p>
                        {I18n.get(
                          "Nosso algoritmo fez o match e encontrou alguns parceiros que podem ajudar no seu projeto."
                        )}
                      </p>
                    </S.RowCenter>
                  </Col>
                </Row>

                {visibleGlobal && (
                  <Row>
                    <Col xs={24} sm={12} offset={6}>
                      <S.RowCenter>
                        <GlobalFileBox
                          title={I18n.get("Atenção!")}
                          description={I18n.get(
                            "Esta é uma marca Global. Anexe aqui a aprovação de global para este projeto."
                          )}
                          username={auth?.username}
                          action={(data) => saveGlobal(data)}
                          file={project?.globalAuthorization}
                        />
                      </S.RowCenter>
                    </Col>
                  </Row>
                )}

                <Row>
                  <S.WrapperCards>
                    {isLoading ? (
                      <div style={{ width: "100%" }}>
                        <Loading
                          text={I18n.get("Carregando...")}
                          sizeText={14}
                          sizeIcon={16}
                          align="center"
                        />
                      </div>
                    ) : (
                      dataPartners?.map((card) => (
                        <Card
                          key={card.id}
                          step={1}
                          dataCard={card}
                          projectId={params?.projectId}
                          disabledSelected={disabledSelected}
                        />
                      ))
                    )}
                  </S.WrapperCards>
                </Row>

                <Row>
                  <Col lg={24}>
                    <S.FooterButtons className="buttonsContainer">
                      <Link to={`/cliente/projetos/v2`}>
                        <ButtonBpool
                          text={I18n.get("Voltar")}
                          theme="secondary"
                          onClick={() => history.push(`/cliente/projetos/v2`)}
                        />
                      </Link>
                    </S.FooterButtons>
                  </Col>
                </Row>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
