import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PROJECT_RESPONSIBLE_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_partner_responsible = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            // alert();

            return axios.get(`${Config.API_BASE_URL}/client-project/${data.projectId}/partner-responsable/${data.partnerId}`, config)
                .then(response => {

                    dispatch({
                        type: PROJECT_RESPONSIBLE_GET,
                        payload: {
                            partnerResponsibles: response.data,
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    })
                })
                .catch(error => {
                    dispatch({
                        type: PROJECT_RESPONSIBLE_GET,
                        payload: {
                            errors: {},
                            task: {
                                result: false
                            }
                        }
                    })
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_partner_responsible;
