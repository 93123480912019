import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Link, Redirect } from 'react-router-dom';
import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import { PROJECT_API } from '../../actions/project/actions';

import { ClientProjectStatus } from '../../model';

import TextField from '../form/text-field';
import SubmitButtonField from '../form/submit-button-field';


const mapStateToProps = state => {
    return Object.assign({}, state, { ...state.project });
};

const mapDispatchToProps = dispatch => {
    return {
        update_nfse: data => {
            dispatch(PROJECT_API.update_nfse(data));
        },
        get_project: data => {
            dispatch(PROJECT_API.get(data));
        },
    };
};

class ProjectNfseForm extends React.Component {

    constructor(props) {

        super();

        this.state = {
            form: {
                projectNf: {
                    label: '',
                    value: props.projectNf,
                    onChange: this.onChangeForm.bind(this, 'projectNf'),
                    error: props.errors.projectNf,
                    successIcon: false,
                    placeholder: I18n.get("https://"),
                    visited: true
                },
            },
            task: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {



                if (FormUtils.isFormField(nextState.form[item])) {
                    nextState.form[item].error = this.props.errors[item];
                    nextState.form[item].value = this.props[item] || '';
                }
                else if (typeof nextState.form !== 'undefined') {
                    nextState.form[item] = this.props[item];
                }
            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    componentDidMount() {

        if (this.props.match.params.projectId) {

            var data = {
                projectId: this.props.match.params.projectId
            };

            var task = new Task();

            task.start();

            this.setState({ task: task }, function () {
                this.props.get_project(data);
            });
        }
    }


    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
        };

        Object.keys(this.state.form).forEach((item) => {

            if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
                data[item] = this.state.form[item].value
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.update_nfse(this.getData());
        });
    }


    render() {

        if (this.props.auth.is_partner === false) {
            return (<Redirect to={`/projetos/${this.props.match.params.projectId}/resumo`} />);
        }

        return (
            <div className="project-ongoing-review">


                    <h1 className="title title-1">{I18n.get("NFS-e")}</h1>

                    <br /><br />
                <br /><br />



                <h1 className="title-bpool medium">
                    <span>1.</span>{I18n.get("Link")}</h1>

                <p>{I18n.get(
                    "Carregue aqui link da sua NFS-e. Mas, antes disso, certifique-se que o corpo da nota foi preenchido conforme instruções enviadas para o seu e-mail, ok?"
                )}</p>

                <br /><br />

                 <TextField {...this.state.form.projectNf} />

                <br />

                <div className="columns">

                <div className="column is-1">

                    <div className="control">
                                <SubmitButtonField
                                    label={I18n.get("SALVAR")}
                                    className="button is-black"
                                    onClick={this.submitForm.bind(this)}
                                    task={this.state.task}
                                />
                    </div>

                </div>

                </div>

                <div className="columns">

                    <div className="column">

                        <div className="control">

                                    {Object.keys(this.props.errors).map((item) => {

                                    return (<p>

                                        <ul style={{width: "100%"}}>
                                            <li>
                                            <span className="icon has-text-danger">
                                            <i className="fas fa-exclamation"></i>
                                            </span> {this.props.errors[item].errorMessage}</li>
                                        </ul>
                                    </p>);
                                    })}

                            </div>

                    </div>

                </div>




            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNfseForm);
