import React from 'react';
import * as S from './styles';

const Modal = ({
  children,
  header,
  headerTitle,
  onClickCloseModal,
  closeInBg,
}) => {
  return (
    <>
      <S.DarkBg onClick={closeInBg ? () => onClickCloseModal() : () => true} />
      <S.Centered>
        <S.Modal>
          {header ? (
            <>
              <S.ModalHeader>
                <S.Heading>{headerTitle}</S.Heading>
              </S.ModalHeader>
              <S.CloseBtn onClick={() => onClickCloseModal()}>X</S.CloseBtn>
            </>
          ) : null}
          <S.ModalContent>{children}</S.ModalContent>
        </S.Modal>
      </S.Centered>
    </>
  );
};

export default Modal;
