import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';


const CardBorderDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 20px;
    height: 100%;
    border-radius: 20px;

    @media (max-width: 820px) {
        width: calc(100% - 20px);
        margin: 0 10px 15px;
        padding-left: 5px;
        padding-right: 10px;
    }
    `;

const CardSimpleDiv = styled.div`
    border-radius: 20px;
    background: #ffffff;
    padding: 20px;
    position: relative;
`;

export class CardBorder extends PureComponent {
    render() {
        return <CardBorderDiv style={{ background: `${this.props.BGcolor ? this.props.BGcolor : "#fff"}`, borderColor: `${this.props.Bcolor ? this.props.Bcolor : "#fff"}` }}>{this.props.children}</CardBorderDiv>;
    }
}

export class CardSimple extends PureComponent {
    render() {
        return <CardSimpleDiv>{this.props.children}</CardSimpleDiv>;
    }
}

