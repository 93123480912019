import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import TextField from '../../form/text-field';
import MoneyField from '../../form/money-field';
import FieldErrorMessage from '../../form/field-error-message';

import SubmitButtonField from '../../form/submit-button-field';

import { ProjectExtraType, ProjectExtraTypeNames } from '../../../model';

import FormatCurrency from '../../ui/format-currency';


class CreateForm extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            fields: {
                extraType: {
                    name: 'extraType',
                },
                extraName: {
                    label: I18n.get("Nome do Extra"),
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'extraName'),
                    visited: true
                },
                budget: {
                    label: I18n.get("Budget"),
                    successIcon: false,
                    onChange: this.onChangeForm.bind(this, 'budget'),
                    onBlur: this.onSave.bind(this),
                    visited: true
                },
            },
            form: {
                extraName: '',
                //extraType: ProjectExtraType.InternalCosts,
            },
            errors: {},
            task: {},
        }
    }

    onChangeType(value, e) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form['extraType'] = value;

            return nextState;
        }, () => this.onSave()
        );
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormRadio(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = [value];
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onUpload(file, e) {

        if (!file) return;

        if (e) e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            var upload_task = new Task();

            upload_task.start();

            this.setState({ upload_task: upload_task }, function () {

                const data = {
                    projectId: this.props.match.params.projectId,
                    name: file.name,
                    base64: e.target.result
                };

                this.props.upload_extra_file(data)
                    .then(response => {

                        this.setState((prevState) => {

                            var nextState = Object.assign({}, prevState);

                            nextState.form.files.push(response.data);
                            nextState.upload_task = { result: true };

                            return nextState;
                        });

                    })
                    .catch(error => {

                        this.setState({ upload_task: { result: false } });

                    });
            });
        };
        reader.readAsDataURL(file);
    }

    delFile(item) {

        var data = {
            projectId: this.props.match.params.projectId,
            ...item
        };

        const files = this.state.form.files.filter((f) => f.id != item.id);

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.files = files;

            return nextState;
        });

    }

    onDrop(files) {

        files = files.map(file => {

            this.onUpload(file);
        });
    }

    onSave() {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        // this.props.update_bpitch(this.getData())
        //     .then(({ task, errors }) => {

        //         this.setState({ submit_task: task, errors: errors });
        //     });
    }

    onSubmitForm(e) {

        e.preventDefault();

        const { auth = {} } = this.props;

        var submit_task = new Task();

        submit_task.start();

        this.setState({ task: submit_task });

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.state.form
        };

        this.props.create_extra(data)
            .then(({ extraId, task, errors }) => {

                this.setState({ task: task, errors: errors });

                if (task.result) {
                    this.setState({ form: {} });

                    if (auth.is_partner) {
                        this.props.history.push(`/projetos/${this.props.match.params.projectId}/extras/${extraId}/orcamento`);
                    }
                    else {
                        this.props.history.push(`/projetos/${this.props.match.params.projectId}/extras/${extraId}/editar`);
                    }
                }

            });
    }

    render() {

        const currency = this.props.currency || {};

        const types = [ProjectExtraType.InternalCosts, ProjectExtraType.ExternalCosts, ProjectExtraType.Media, ProjectExtraType.Tools, ProjectExtraType.Finance];

        const budgetTooltip = this.props.auth.is_client_or_client_user ?
            I18n.get("Insira aqui o budget máximo deste projeto. O parceiro não conseguirá submeter propostas acima do valor inserido aqui.") :
            I18n.get("Sua proposta não poderá ultrapassar o budget estipulado neste campo. Se você nao tiver uma estimativa de budget do cliente, deixe este campo em branco.");

        return (
            <form>

                <TextField {...this.state.fields.extraName} value={this.state.form.extraName} error={this.state.errors.extraName} />

                <div className="columns">

                    <div className="column is-6">

                        <MoneyField
                            {...this.state.fields.budget}
                            currency={currency}
                            value={this.state.form.budget}
                            error={this.state.errors.budget}
                            tooltip={budgetTooltip}
                        />

                    </div>

                </div>

                <div className="field">
                    <label className="label is-small">
                        {I18n.get("Escolha o Tipo de Extra")}
                    </label>
                    <br />
                    <div className="control bp-control-exatra">


                        {types.map((item) => {

                            const className = (this.state.form.extraType === item) ? 'is-selected' : 'is-outlined';

                            return (
                                <a onClick={this.onChangeType.bind(this, item)} className={'button bt-bpool black ' + className}>
                                    {I18n.get(ProjectExtraTypeNames[item])}
                                </a>
                            );

                        })}

                        <FieldErrorMessage fields={['extraType']} errors={this.state.errors} />
                    </div>
                </div>

                <div>



                </div>

                <br />
                <br />
                <br />

                <SubmitButtonField
                    label={I18n.get("Próximo")}
                    onClick={this.onSubmitForm.bind(this)}
                    className="button bt-bpool full"
                    task={this.state.task}
                    disabled={!this.state.form.extraName}
                />



            </form>
        );
    }
}

export default CreateForm;
