import React, { Component, Fragment } from "react";
import { I18n } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import {
    Row,
    Col,
    Form,
    Icon,
    Spin,
    Input,
    Select,
    Button
} from 'antd';

import styled from 'styled-components';

import FileUploadList from '../../components/FileUploadButton';

const { Option } = Select;
const { TextArea } = Input;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`

const FormBox = styled(Form)`

`

class FormContents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
            file: null
        }
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    formState: values,
                })

                let obgScope = {}

                obgScope = this.props.filterCategory.find(i => i.categoryId == values.categoryId);

                let data = {
                    ...values,
                    scopeId: obgScope?.scopeId || "" ,
                    sectionId: obgScope?.sectionId || "",
                    file: this.state.file
                }
                    
                this.props.action(data);
            }
        });
    };

    selectCategorie = (id) => {


        if(id){

            let obgScope = {}
            obgScope = this.props.filterCategory.find(i => i.categoryId == id);
            
            this.setState({
                type: obgScope.type
            })
        }


    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errors[field].errorMessage)],
                    },
                });
            });
        }
     }


     renderAction = (item) => {
        if(Object.keys(item).length > 0) {
            return I18n.get("Editar");
        } else {
            return I18n.get("Adcionar");
        }
     }

     getUrl = (url) => {
 
        if(url.length > 0) {

            this.setState({
                file: 
                {
                    ...url,
                    uid: uuidv4(),
                }
                
            })
        } else {
            this.setState({
                file: null
            })
        }
     }


    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { categories } = this.props

        const {item = {}} = this.props;

        // let fileArray = this.state.file

        let filesNormalize = [];

        if(this.state.file && Object.keys(this.state.file).length > 0) {
            filesNormalize = [
                {
                    ...this.state.file[0],
                    uid: uuidv4(),
                }
            ]
        }
        

        return (
            <FormBox onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={18}>
                        <Form.Item
                            label={I18n.get("Título")}>
                            {getFieldDecorator('title', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: item.title || "",
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={6}>
                        <Form.Item
                            label={I18n.get("Ordem")}>
                            {getFieldDecorator('order', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: item.order || null
                            })(
                                <Select
                                    className=""
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option className="" value={null}>{I18n.get("Selecione")}</Option>
                                    <Option className="" value={0}>0</Option>
                                    <Option className="" value={1}>1</Option>
                                    <Option className="" value={2}>2</Option>
                                    <Option className="" value={3}>3</Option>
                                    <Option className="" value={4}>4</Option>
                                    <Option className="" value={5}>5</Option>
                                    <Option className="" value={6}>6</Option>
                                    <Option className="" value={7}>7</Option>
                                    <Option className="" value={8}>8</Option>
                                    <Option className="" value={9}>9</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={14}>
                        <Form.Item
                            label={I18n.get("Categoria / Section / Escopo")}>
                            {getFieldDecorator('categoryId', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: item.categoryId || null
                            })(
                                <Select
                                    className=""
                                    style={{ width: "100%" }}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    onSelect={e => this.selectCategorie(e)}
                                >
                                    <Option className="" value={null}>{I18n.get("Selecione")}</Option>
                                    {categories.map((categorie,index) => {
                                        return <Option className="" key={index} value={categorie.categoryId}>{categorie.section} - {categorie.name} - {categorie.target == "c" || categorie.target == "C" ? "Client":"Partner" } - {categorie.scope} - {categorie.language}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>    

                    <Col sm={10}>
                        <Form.Item
                            label={I18n.get("Tags")}>
                            {getFieldDecorator('tag', {
                                rules: [{ required: false, message: 'ops!' }],
                                initialValue: item.tag || [],
                            })(
                                <Select
                                    showSearch={true}
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    tokenSeparators={[',']}
                                />
                            )}
                        </Form.Item>
                    </Col>                
                    
                    <Col sm={24}>
                        {this.state.type == 2 && 
                            <Form.Item
                                label={I18n.get("Resposta")}>
                                {getFieldDecorator('content', {
                                    rules: [{ required: false, message: 'ops!' }],
                                    initialValue: item.content || "",
                                })(
                                    <TextArea />
                                )}
                            </Form.Item>                        
                        }

                        {this.state.type == 1 && 
                             <Form.Item
                             label={I18n.get("Url Loom")}>
                             {getFieldDecorator('content', {
                                 rules: [{ required: false, message: 'ops!' }],
                                 initialValue: item.content || "",
                             })(
                                 <Input />
                             )}
                         </Form.Item>                        
                        }
                        {this.state.type == 3 && 
                        <Fragment>
                                <Form.Item
                                    label={I18n.get("Arquivo")}>
                                    {getFieldDecorator('content', {
                                        rules: [{ required: false, message: 'ops!' }],
                                        initialValue: item.content || "",
                                    })(
                                        <FileUploadList
                                            getDataUpload={this.getUrl}
                                            name="files"
                                            // uploadId={this.props.project.projectId}
                                            get2={true}
                                            fileListGet={filesNormalize || []}
                                            disabledList={false}
                                            limit={1}
                                            output="object"
                                        />
                                    )}
                                </Form.Item>   
                            
                         </Fragment>                     
                        }
                    </Col>
                    
                    <Col sm={12}>
                        <Form.Item className="labelClean" label="&nbsp;">
                            <Button
                                className="button bt-bpool black"
                                shape="round"
                                htmlType="submit"
                                // disabled={!filters || getFieldValue("clientId") == ""}
                                loading={this.props?.task?.busy}
                            >
                                {this.renderAction(item)}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </FormBox>
        )
    }
}



const FormFormContents = Form.create({})(FormContents);

export default FormFormContents;

