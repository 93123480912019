import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { I18n } from 'aws-amplify';

import NumberFormat from 'react-number-format';

import {
    Table,
    Icon
} from 'antd';

const Btns = styled.p`
width: 50px;
displa: block;
    a {
        margin-left: 10px;
    }
`
const BRL = ({ amount }) => <NumberFormat value={amount} displayType={'text'} thousandSeparator='.' decimalSeparator=',' prefix={'R$ '} decimalScale={2} fixedDecimalScale={true} />;



class tableProposalAsset extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderType = (type) => {
        switch (type) {
            case 1:
                return "Text";
                break;
            case 2:
                return "Vídeo";
                break;
            case 3:
                return "File";
                break;
        
            default:
                break;
        }
    }

    render() {
        const columns = [
            {
                title: I18n.get("Asset"),
                dataIndex: 'asset',
                key: 'asset'
            },
            {
                title: I18n.get("Quantidade"),
                dataIndex: 'qnt',
                key: 'qnt',
                render: (text, record) => {
                    return <p>{record.qnt} x <BRL amount={record.price} /></p>
                }
            },
            {
                title: I18n.get("Total"),
                dataIndex: 'total',
                key: 'total',
                render: (text, record) => {
                    return <BRL amount={record.price * record.total} />
                }
            },
            // {
            //     title: I18n.get("Ver / Status"),
            //     dataIndex: 'status',
            //     key: 'status',
            //     render: (text) => {
            //         return text == "C" ? "Client":"Partner";
            //      }
            // },
        ]

        let dataTable = this.props.data && this.props.data.map((item, index) => {
            return {
                key: index,
                asset: item.asset.label,
                total: item.total,
                qnt: item.total,
                price: item.asset.price,
            }
        });

        return (
            <Table
                columns={columns}
                dataSource={dataTable}
                scroll={{ x: 640 }}
                locale={{ emptyText: I18n.get('Sem informação') }}
                footer={() => <p className="totalTable">{I18n.get("Total:")} <BRL amount={this.props.totalPrice} /></p>}
                pagination={false}
            />
        );
    }
}

export default tableProposalAsset;
