import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 50px;

  .ant-calendar-picker {
    width: 100%;

    input:not(:placeholder-shown) {
    }

    input:placeholder-shown {
      padding-left: 15px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      text-align: left !important;
      color: #515152 !important;

      height: 50px;
      background: #ffffff !important;
      width: 100%;
      border: 1px solid #999999 !important;
      border-radius: 10px !important;
    }

    input:read-only {
      padding-left: 15px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      text-align: left !important;
      color: #515152 !important;

      height: 50px;
      background: #ffffff !important;
      width: 100%;
      border: 1px solid #999999 !important;
      border-radius: 10px !important;
    }
  }

  .label {
    position: absolute;
    top: -5px;
    left: 5px;
    font-size: 12px;
    font-weight: 700;
    padding: 0 5px;
    background-color: #ffffff;
    pointer-events: none;
    color: #515152;
    z-index: 2;

    span {
      color: #ff0000;
    }
  }

  .ant-calendar-picker.datepicker-error {
    input {
      border-color: #ff0000 !important;
    }
  }
`;

export const Required = styled.span`
  color: #ff0000;
  font-size: 16px;
  padding-right: 2px;
  position: absolute;
  top: 16px;
  z-index: 1;
  left: 20px;
`;

export const WrapperDisabled = styled.div`
  position: relative;
  cursor: not-allowed;

  .label {
    position: absolute;
    top: -5px;
    left: 5px;
    font-size: 12px;
    font-weight: 700;
    padding: 0 5px;
    background-color: #ffffff;
    pointer-events: none;
    color: #696969;
    z-index: 2;

    span {
      color: #ff0000;
    }
  }
`;

export const InputFakeDisabled = styled.div`
  cursor: not-allowed;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  color: #a4a4a4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  p {
    cursor: not-allowed;
    color: #a4a4a4;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const InfoError = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;