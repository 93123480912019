import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';

import BgSuccess from '../../../image/bg-success.jpg';

const MainSuccess = styled.div`
    background: url(${BgSuccess}) no-repeat;
    position: absolute;
    height: calc(100% - 125px);
    width: 100%;

    .InfoSuccess {
        width: 370px;
        margin: 260px auto 0;
        position: relative;
        left: 60px;

        a {
            color: #fff;
            background: #000;
            display: block;
            min-width: 172px;
            height: 38px;
            line-height: 38px;
            float: left;
            text-align: center;

            font-size: 12px;
            font-weight: 600;
            letter-spacing: 2.4px;
            text-transform: uppercase;
            margin: 30px 12px 0 0;
            transition: all .3s ease;

            &:hover {
                background: #444;
            }
        }

        h2 {
            font-size: 25px;
            font-weight: 600;
            position: relative;
            margin-bottom: 30px;

            &::after {
                position: absolute;
                width: 80px;
                height: 8px;
                background-color: #c7e5f2;
                bottom: -15px;
                content: "";
                display: block;
            }
        }

        @media (max-width: 768px) {
            max-width: 370px;
            width: 100%;
            margin: 280px auto 0;
            position: relative;
            left: 0px;
            box-sizing: border-box;
            padding: 0 20px;

        }
    }
`;

class PageSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <MainSuccess>
                {/* {this.props.children} */}
                <div className="InfoSuccess">
                    {this.props.children}
                </div>
            </MainSuccess>
        );
    }
}

export default PageSuccess;
