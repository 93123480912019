import styled from "styled-components";
import calendarIcon from '../../../svg/calendar-icon.svg'
import checkIcon from '../../../svg/check-icon.svg'
import lightIcon from '../../../svg/light-icon.svg'
import * as MediaQuery from "../../../utils/mediaQuery";

export const DashPendencieBoard = styled.div`
    background-color: #FFF;
    border-radius: 10px;
    padding: 40px 30px 50px;
    text-align: center;
    height: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    
    > div:first-child {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-self: stretch;
        
        ${() =>
        MediaQuery.IS_MOBILE() &&
        `
        flex-wrap: wrap;
        justify-content: center;
        `}
    }    
    .pendencie {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        margin: 30px 0 50px;
    }
    .dashboard {
        margin: 30px 0 0;
    }
`;

export const Pendencie = styled.div`
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        > p:before {
            content: '';
            width: 24px;
            height: 24px;
            background-image: url(${lightIcon});
            display: inline-flex;
            margin-right: 10px;
            position: relative;
            bottom: -5px;
        }

        div {
            display: flex;
            flex-direction: row;

            p{
                width: 50%;
                margin:0;

                &:first-child:before {
                    content: '';
                    width: 24px;
                    height: 24px;
                    background-image: url(${calendarIcon});
                    display: inline-flex;
                    margin-right: 10px;
                    position: relative;
                    bottom: -5px;
                }

                &:nth-child(2){
                    color: #FF0000;
                    
                    &:before {
                        content: '';
                        width: 24px;
                        height: 24px;
                        background-image: url(${checkIcon});
                        display: inline-flex;
                        margin-right: 10px;
                        position: relative;
                        bottom: -5px;
                    }
                }
            }
        }
`;


