import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink, Redirect } from "react-router-dom";

import constants from '../../../constants';

import { ClientStatus } from '../../../model';

import { QueryString } from '../../../utils/querystring';

export default class ClientList extends React.Component {

    constructor(props) {
        super();

        var queryString = QueryString.extract(props.location.search);

        this.state = {
            q: '',
            status: queryString.status,
            address_state: '',
        };

        this.changeFilter = this.onChangeFilter.bind(this);
        this.select = this.onSelect.bind(this);
    }

    componentDidMount() {
        this.props.get_clients(this.state);
    }

    onChangeFilter(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            return Object.assign({}, prevState, {
                ...prevState,
                [prop]: value
            });
        }, function () {
            this.props.get_clients(this.state)
        });
    }

    onSelect(item, e) {
        this.setState({ selected: item.id });
    }

    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientStatusesMap) return '';

        return this.props.bp.clientStatusesMap[value].label;
    }

    render() {

        if (this.state.selected) {
            return (<Redirect to={'/bpool/clientes/' + this.state.selected} />);
        }

        return (
            <div>

                <div className="field is-grouped table-mob">

                    <div className="control campo-table-mob">
                        <label className="label is-small">{I18n.get("Buscar")}</label>
                        <input onChange={this.changeFilter.bind(this, 'q')} className="input" type="text" placeholder={I18n.get("Nome, Razão ou CNPJ")} />
                    </div>

                    <div className="control campo-table-mob">
                        <label className="label is-small">{I18n.get("Status")}</label>
                        <div className="select">
                            <select value={this.state.status} onChange={this.changeFilter.bind(this, 'status')}>
                                <option value="">{I18n.get("Todos status")}</option>
                                {this.props.bp.clientStatuses && this.props.bp.clientStatuses.map((item) => (<option key={item.code} value={item.code}>{item.label}</option>))}
                            </select>
                        </div>
                    </div>

                    <div className="control campo-table-mob">
                        <label className="label is-small">{I18n.get("Estado")}</label>
                        <div className="select">
                            <select value={this.state.address_state} onChange={this.changeFilter.bind(this, 'address_state')}>
                                <option value="">{I18n.get("Todos estados")}</option>
                                {constants.STATES.map((item) => (<option key={item.code} value={item.code}>{item.label}</option>))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="">
                    <div className="table-main">

                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth hasTableCounter">
                            <thead>
                                <tr>
                                    <th>{I18n.get("Responsável")}</th>
                                    <th>{I18n.get("Nome")}</th>
                                    <th>{I18n.get("Razão")}</th>
                                    <th>{I18n.get("Cidade")}</th>
                                    <th>{I18n.get("Estado")}</th>
                                    <th>{I18n.get("Cadastro")}</th>
                                    <th>{I18n.get("Status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.bp.clients && this.props.bp.clients.map((item, index) =>
                                    (<tr key={index}>
                                        <td className="has-text-left">
                                            <NavLink to={`/bpool/clientes/${item.id}`} className="is-black">{item.name}</NavLink>
                                        </td>
                                        <td>
                                            {item.company && item.company.name}
                                        </td>
                                        <td onClick={this.select.bind(this, item)}>
                                            {item.company && item.company.registrationName}
                                        </td>
                                        <td onClick={this.select.bind(this, item)}>
                                            {item.company && item.company.city}
                                        </td>
                                        <td onClick={this.select.bind(this, item)}>
                                            {item.company && item.company.state}
                                        </td>
                                        <td onClick={this.select.bind(this, item)}>
                                            {item.createdOnFmt}
                                        </td>
                                        <td onClick={this.select.bind(this, item)}>
                                            {this.getStatus(item.status)}
                                        </td>
                                    </tr>)
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        );
    }
}
