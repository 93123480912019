import React, {PureComponent} from 'react';
import { I18n } from 'aws-amplify';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom'

class TabsMenu extends PureComponent {
    state = {  }
    render() { 
        const {activeTab, isClient, tabs} = this.props;

        let prefix = isClient ? "cliente":"parceiro";

        return (
            <div className="bp-tabsmanu">
                {tabs && tabs.map((item, index) => {

                    return(
                        <Link to={`/${prefix}/wikis/${item.name}`}><Button className={`bt-bpool button quad grey-blue ${activeTab == item.name ? "active":""}`}>{item.name}</Button></Link>
                    )
                })}
                {/* <Link to={`/${prefix}/wikis/faqs`}><Button className={`bt-bpool button quad grey-blue ${activeTab == "faqs" ? "active":""}`}>{I18n.get("FAQs")}</Button></Link>
                <Link to={`/${prefix}/wikis/mapas`}><Button className={`bt-bpool button quad grey-blue ${activeTab == "mapas" ? "active":""}`}>{I18n.get("Mapas de Processos")}</Button></Link> */}
            </div>
        );
    }
}
 
export default TabsMenu;