import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
`;

export const Content = styled.div`
  padding: 24px;
`;
