import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useDebounce } from "use-debounce";
import { Row, Col, Icon } from "antd";
import { useSelector } from "react-redux";
import { useReview } from "../../../hooks/useReview/useReview.hook";
import { H2Border, H3Border } from "../../../components/UI/Titles";
import { useParams, Link, useHistory } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";
import Content from "../../../components/UI/Content";
import { Textarea } from "../../../components/UI/Textarea";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import Loading from "../../../components/UI/Loading";
import { ConvertValuesCreativeScope } from "../../../../v2/pages/ProjectsList/Invitations/ProjectDetails/utils/convertValuesCreativeScope";
import { RequestProjectCompletion } from "../components/RequestProjectCompletion";
import { TabsProject } from "../../../components/UI/TabsProject";
import { ClientProjectStatus } from "../../../../../src/model";

import InfoCircle from "../svgs/info-circle.svg";
import {
  Informations,
  GeneralDemand,
  CreativeScope,
  Details,
  AboutPartner,
  CompletionFiles,
} from "./modules";

import * as S from "./styles";

export const ReviewInProgress = () => {
  let params = useParams();
  const auth = useSelector((state) => state.auth);
  const state = useSelector((state) => state);
  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState(null);
  const [listOfAssets, setListOfAssets] = useState([]);
  const [dataValuesCreativeScope, setDataValuesCreativeScope] = useState({});
  const { getReviewDefinition, getAssets, putNotes, putAssets, sendToClient } =
    useReview();
  const [notes, setNotes] = useState(null);
  const [loadAssets, setLoadAssets] = useState(false);
  const [loadSend, setLoadSend] = useState(false);
  const [visibleInclude, setVisibleInclude] = useState(false);
  const [visibleExclude, setVisibleExclude] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleSend, setVisibleSend] = useState(null);
  const [codeDelete, setCodeDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [visibleDecline, setVisibleDecline] = useState(false);
  const [visibleApprove, setVisibleApprove] = useState(false);

  const [successDecline, setSuccessDecline] = useState(false);
  const [successApprove, setSuccessApprove] = useState(false);

  const [projectErrors, setProjectErrors] = useState({});
  const [errorInGet, setErrorInGet] = useState(false);
  const [sendingNotes, setSendingNotes] = useState(false);
  const [init, setInit] = useState(false);
  const [currencyProject, setCurrencyProject] = useState({});

  const [searchValueDebounced] = useDebounce(notes, 1000);

  const [contactInformation, setContactInformation] = useState({});
  const [generalDemand, setGeneralDemand] = useState(null);
  const [creativeScope, setCreativeScope] = useState({});
  const [details, setDetails] = useState({});
  const [aboutPartner, setAboutPartner] = useState({});

  const history = useHistory();

  const getEstimatedEndDate = (data) => {
    let months = "";
    let weeks = "";

    if (data?.estimatedDurationInMonths) {
      months = `${data?.estimatedDurationInMonths} ${I18n.get(
        "mes(es) e"
      )}${" "}`;
    } else {
      months = `0 ${I18n.get("mes(es) e")}${" "}`;
    }

    if (data?.estimatedDurationInWeeks) {
      weeks = `${data?.estimatedDurationInWeeks} ${I18n.get("semana(s)")}`;
    } else {
      weeks = `0 ${I18n.get("semana(s)")}`;
    }

    return `${months} ${weeks}`;
  };

  const loadReview = async () => {
    setIsLoading(true);
    try {
      if (projectId) {
        const response = await getReviewDefinition({ id: projectId });
        console.log("REVIEW", response?.data);
        if (response?.success) {
          setProject(response?.data);
          setListOfAssets(response?.data?.bundle?.variations[0]?.assets || []);
          const objDataValuesCreativeScope = ConvertValuesCreativeScope({
            data: response?.data,
            isInvite: false,
          });
          setDataValuesCreativeScope(objDataValuesCreativeScope);

          setContactInformation({
            projectName: response?.data?.projectName,
            projectSKU: response?.data?.projectSKU,
            projectType: response?.data?.projectType,
            product: `${response?.data?.brandName} - ${response?.data?.productName}`,
          });
          setGeneralDemand({
            demand: response?.data?.demand,
            demandFiles: response?.data?.demandFiles,
          });
          setCreativeScope(response?.data?.bundle);
          setDetails({
            estimatedEndDate: getEstimatedEndDate(response?.data),
            estimatedStartDate: response?.data?.estimatedStartDate,
            estimatedDecisionInWeeks: response?.data?.estimatedDecisionInWeeks
              ? `${response?.data?.estimatedDecisionInWeeks} ${I18n.get(
                  "semana(s)"
                )}`
              : "---",
            paymentTermDays: `${response?.data?.paymentTermDays} ${I18n.get(
              "dias corridos"
            )}`,
            nonCompeteInDays: response?.data?.nonCompeteInDays,
            additionalRule: response?.data?.additionalRule,
            globalAuthorization: response?.data?.globalAuthorization,
            descriptiveHourMan: response?.data?.descriptiveHourMan,
          });
          setAboutPartner({
            partnerName: response?.data?.partnerName,
            partnerResponsibleEmail: response?.data?.partnerResponsibleEmail,
            partnerResponsibleName: response?.data?.partnerResponsibleName,
            partnerResponsiblePhoneNumber:
              response?.data?.partnerResponsiblePhoneNumber,
          });

          setLoadAssets(false);
          setCodeDelete(null);
          setSuccessDecline(false);
          setCurrencyProject(response?.data?.currency);
          if (auth?.is_client_or_client_user) {
            setNotes(response?.data?.clientNotes);
          } else {
            setNotes(response?.data?.partnerNotes);
          }
          setInit(true);
        }
      }
    } catch (error) {
      setErrorInGet(true);
    }
    setIsLoading(false);
  };

  const handleAproveOrDecline = async (approved) => {
    setLoadSend(true);

    const data = {
      projectId: params.projectId,
      clientNotes: project?.clientNotes || "",
      definitionApproved: approved,
    };

    const response = await sendToClient(data);

    if (response?.success) {
      if (approved) {
        setVisibleApprove(false);
        setSuccessApprove(true);
      } else {
        setSuccessDecline(true);
        setVisibleDecline(false);
      }
    }
  };

  const partnerSend = async () => {
    setLoadSend(true);
    const data = {
      projectId: params.projectId,
      partnerNotes: project?.partnerNotes || "",
    };

    const response = await sendToClient(data);

    if (response?.success) {
      setVisibleSend(true);
    } else {
      setLoadSend(false);
      setProjectErrors(response.errors);
    }
  };

  const cartArray =
    project?.bundle?.variations &&
    project?.bundle?.variations[0]?.assets &&
    project?.bundle?.variations[0]?.assets.map((item) => item.asset);

  const preDeleteAsset = (code) => {
    setCodeDelete(code);
    setVisibleDelete(true);
  };

  const handleDeleteItem = async (code) => {
    let indices = [];

    let idx = cartArray.indexOf(code);

    while (idx != -1) {
      indices.push(idx);
      idx = cartArray.indexOf(code, idx + 1);
    }

    let removeItem = indices[0];

    let cartFull = cartArray;

    cartFull.splice(removeItem, 1);

    let cart = cartFull;

    let data = {
      projectId: params.projectId,
      assetsCode: cart,
    };

    setLoadAssets(true);

    putAssets(data).then((response) => {
      if (response?.success) {
        loadReview();
        setVisibleExclude(true);
      }
    });
  };

  const handleAddAsset = (code) => {
    let cart = { assetsCode: [...cartArray, code] };

    let data = {
      projectId: params.projectId,
      ...cart,
    };

    setLoadAssets(true);

    putAssets(data).then((response) => {
      if (response?.success) {
        loadReview();
        setVisibleInclude(true);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth?.username) {
      loadReview();
    }
  }, [auth, projectId]);

  useEffect(() => {
    setProjectId(params.projectId);
  }, [params]);

  const putNotesSave = async () => {
    const data = {
      notes: notes,
      projectId: params.projectId,
    };

    if (auth?.is_partner) {
      if (
        notes !== null &&
        notes?.toLowerCase() !== project?.partnerNotes?.toLowerCase()
      ) {
        setSendingNotes(true);
        await putNotes(data);
        setSendingNotes(false);
      }
    } else {
      if (
        notes !== null &&
        notes?.toLowerCase() !== project?.clientNotes?.toLowerCase()
      ) {
        setSendingNotes(true);
        await putNotes(data);
        setSendingNotes(false);
      }
    }
  };

  useEffect(() => {
    if (init) {
      putNotesSave();
    }
  }, [searchValueDebounced, init]);

  const stepItens = [
    {
      key: 1,
      label: I18n.get("Briefing"),
      url: `/${
        auth?.is_client_or_client_user ? "cliente" : "parceiro"
      }/projetos/v2/${params.projectId}/briefing`,
    },
    {
      key: 2,
      label: I18n.get("Milestones"),
      url: `/${
        auth?.is_client_or_client_user ? "cliente" : "parceiro"
      }/projetos/v2/${params.projectId}/macros`,
    },
    {
      key: 3,
      label: I18n.get("Review"),
      url: `/${
        auth?.is_client_or_client_user ? "cliente" : "parceiro"
      }/projetos/v2/${params.projectId}/review`,
    },
  ];

  const getStepError = (step) => {
    let stepError = {};
    stepError = stepItens.find((i) => i.key == step);

    return stepError;
  };

  function getCategories() {
    if (!state?.bp?.sectorsFlat) return [];
    if (state?.sameCategoryOnly) return [];
    if (!state.categoryIds) return [];

    return (
      project?.categoryIds &&
      project?.categoryIds.map((categoryId) => {
        const category = state?.bp?.sectorsFlat.filter(
          (item) => item.code == categoryId
        )[0];

        return category.label;
      })
    );
  }

  const handleConvertedValuesCreativeScope = async ({ values, currency }) => {
    const data = {
      ...values,
      currency,
    };
    const objDataValuesCreativeScope = ConvertValuesCreativeScope({
      data,
      isInvite: false,
    });
    setDataValuesCreativeScope(objDataValuesCreativeScope);

    const listAssetsUpdated = listOfAssets.map((item1) => {
      const matchingItem = values?.bundle?.variationResponse?.assets?.find(
        (item2) => item2.code === item1.code
      );

      if (matchingItem) {
        return {
          ...item1,
          priceFmt: matchingItem.priceFmt,
          priceTotalFmt: matchingItem.priceTotalFmt,
        };
      }

      return item1;
    });
    setListOfAssets(listAssetsUpdated);
  };

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Projeto em andamento")}</H2Border>
            </Col>
            <Col sm={12}>
              <RequestProjectCompletion reloadPage={loadReview} />
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <TabsProject
                context={
                  project === null
                    ? "inProgress"
                    : project?.status === 7
                    ? "inProgress"
                    : "finalized"
                }
                page="review"
                externalLoading={isLoading}
                projectStatus={project?.status}
              />
            </Col>
          </Row>

          {errorInGet ? (
            <Row>
              <Col sm={24}>
                <S.TopHeader>
                  <S.Title>{I18n.get("Resumo do projeto")}</S.Title>
                  <S.Paragraph>
                    {I18n.get(
                      "Veja abaixo as informações mais relevantes do seu projeto"
                    )}
                  </S.Paragraph>
                  <S.ErrorInGetPage>
                    <strong>{I18n.get("Erro.")}</strong>
                    <p>
                      {I18n.get(
                        "Houve um erro ao trazer as informações desta página, tente novamente ou entre em contato conosco."
                      )}
                    </p>
                  </S.ErrorInGetPage>
                </S.TopHeader>
              </Col>
            </Row>
          ) : !isLoading ? (
            <>
              <Row>
                {auth?.is_client_or_client_user && project?.status === 9 ? (
                  <Col sm={24}>
                    <S.AlertContent>
                      <div>
                        <img src={InfoCircle} alt="Check Circle" />
                      </div>
                      <div>
                        <p className="title-alert-content">
                          {I18n.get("Aprovação solicitada")}
                        </p>
                        <p>
                          {I18n.get(
                            "Foi solicitada a aprovação do projeto, antes de confirmar a finalização do projeto, valide abaixo todas as informações arquivos e ou links anexados"
                          )}
                        </p>
                      </div>
                      <div>
                        <Link
                          to={`/cliente/projetos/v2/${projectId}/in-progress/files`}
                        >
                          <ButtonBpool
                            text={I18n.get("Verificar arquivos")}
                            theme="tertiary"
                            onClick={() => true}
                          />
                        </Link>
                      </div>
                    </S.AlertContent>
                  </Col>
                ) : null}
                <Col sm={24}>
                  <S.TopHeader>
                    <S.Title>{I18n.get("Resumo do projeto")}</S.Title>
                    <S.Paragraph>
                      {I18n.get(
                        "Veja abaixo as informações mais relevantes do seu projeto"
                      )}
                    </S.Paragraph>

                    {auth?.is_client_or_client_user &&
                      project?.clientTermsUpdatedOn && (
                        <>
                          <Row>
                            <Col sm={24}>
                              <div
                                style={{
                                  display: "flex",
                                  boxShadow:
                                    "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                                  backgroundColor: "#fff",
                                  padding: "1rem",
                                }}
                              >
                                <div
                                  style={{
                                    flexBasis: "2.5rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Icon
                                    type="check"
                                    style={{ fontSize: "150%", color: "#000" }}
                                  />
                                </div>

                                <div style={{ flex: "1", fontSize: "90%" }}>
                                  {I18n.get(
                                    "Veja abaixo os detalhes do projeto aprovado por"
                                  )}{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {project?.clientTermsUpdatedByEmail}
                                  </span>{" "}
                                  {I18n.get("em")}{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {project?.clientTermsUpdatedOn}
                                  </span>{" "}
                                  {I18n.get("com o parceiro")}{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {project?.partnerName}
                                  </span>
                                  .{" "}
                                  {I18n.get(
                                    "De agora em diante, qualquer adição de escopo será através dos “Extras” ou “Novo Projeto”."
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <br />
                        </>
                      )}

                    {auth?.is_client_or_client_user &&
                      project?.partnerHasSubmittedDefinitions &&
                      project?.status != ClientProjectStatus.DefinitionsSet &&
                      project?.status != ClientProjectStatus.Finished && (
                        <>
                          <S.ButtonFinalizeBox>
                            <Link
                              to={`/cliente/projetos/v2/${projectId}/in-progress/finalize`}
                            >
                              <ButtonBpool
                                text={I18n.get("Finalizar")}
                                theme="primary"
                                onClick={() => true}
                              />
                            </Link>
                            <a
                              href={project?.pdfBundle}
                              target="_blank"
                              style={{ textAlign: "right", marginLeft: 20 }}
                              className="pull-right"
                            >
                              <ButtonBpool
                                text={I18n.get("Download PDF")}
                                className="pull-right"
                                theme="primary"
                              />
                            </a>
                          </S.ButtonFinalizeBox>
                        </>
                      )}

                    {auth?.is_partner && (
                      <>
                        {project?.partnerHasSubmittedDefinitions && (
                          <>
                            <S.Paragraph>
                              {I18n.get(
                                "Parabéns! O escopo final do seu projeto foi submetido."
                              )}
                            </S.Paragraph>
                            <S.Paragraph>
                              {I18n.get(
                                "Se estiver tudo OK, o seu faturamento será liberado quando recebermos a aprovação do cliente."
                              )}
                            </S.Paragraph>
                            <br />
                          </>
                        )}
                        <S.Paragraph>
                          {I18n.get(
                            "Nessa etapa a revisão detalhada do briefing do cliente, esclarecimentos e ajustes deverão ter sido feitos, bem como a proposta de milestones do projeto. Você deve agora revisar o projeto e submeter o escopo final para aprovação do cliente. Caso precise editar o pacote de assets baseado no entendimento mais profundo do briefing e conversas com o cliente, isso pode ser feito abaixo."
                          )}
                        </S.Paragraph>
                      </>
                    )}
                  </S.TopHeader>
                </Col>
              </Row>

              <Row>
                <Col sm={24}>
                  <S.ContentReview>
                    <Row>
                      <Col sm={12} xs={12}>
                        <Informations
                          isLoading={isLoading}
                          data={contactInformation}
                        />
                      </Col>
                      <Col sm={12} xs={12}>
                        {project?.files?.filter(
                          (file) => file?.projectCompletionFile === true
                        )?.length || project?.projectCompletionUrls?.length ? (
                          <CompletionFiles
                            isLoading={isLoading}
                            data={{
                              files: project?.files?.length
                                ? project?.files
                                : [],
                              links: project?.projectCompletionUrls?.length
                                ? project?.projectCompletionUrls
                                : [],
                            }}
                          />
                        ) : null}
                        <GeneralDemand
                          isLoading={isLoading}
                          data={generalDemand}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} xs={12} style={{ marginTop: 30 }}>
                        <CreativeScope
                          isLoading={isLoading}
                          listOfAssets={listOfAssets}
                          dataValues={dataValuesCreativeScope}
                          isInvite={true}
                          convertedValues={handleConvertedValuesCreativeScope}
                          reload={loadReview}
                          data={creativeScope}
                          descriptiveHourMan={project?.descriptiveHourMan}
                          disabledEdit={true}
                          currencyProject={currencyProject}
                          isCart
                          isModalConfirm={true}
                          isClient={auth?.is_client_or_client_user}
                          showTooltipOtherTax
                        />
                        {project?.pdfProject && project?.status >= 6 && (
                          <div style={{ marginTop: 30 }}>
                            <a href={project?.pdfProject} target="_blank">
                              <ButtonBpool
                                text={I18n.get("Download PDF")}
                                className=""
                                theme="primary"
                              />
                            </a>
                          </div>
                        )}
                      </Col>
                      <Col sm={12} xs={12} style={{ marginTop: 30 }}>
                        <AboutPartner
                          isLoading={isLoading}
                          data={aboutPartner}
                        />

                        <Details
                          isLoading={isLoading}
                          data={details}
                          style={{ marginTop: 30 }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12}>
                        <S.WrapperReview>
                          <S.TitleReview>
                            {I18n.get("Observações importantes do Parceiro")}
                          </S.TitleReview>
                          <S.BoxReview>
                            <S.NotesPartnerOrClient>
                              <p>
                                {I18n.get(
                                  auth?.is_partner
                                    ? "Aqui você pode adicionar observações importantes para o cliente."
                                    : "Aqui você pode visualizar as observações importantes para o cliente."
                                )}
                              </p>
                              {auth?.is_partner ? (
                                <>
                                  {sendingNotes ? (
                                    <S.ContentLoadingSending>
                                      <Loading
                                        text={I18n.get(
                                          "Enviando comentário..."
                                        )}
                                        sizeText={14}
                                        sizeIcon={16}
                                        align="start"
                                      />
                                    </S.ContentLoadingSending>
                                  ) : (
                                    <Textarea
                                      label={I18n.get(
                                        "Observações importantes do Parceiro"
                                      )}
                                      rows={4}
                                      defaultValue={notes}
                                      onChange={(value) => setNotes(value)}
                                      // required
                                      // disabled={isClient}
                                    />
                                  )}
                                </>
                              ) : (
                                <S.NotesView>
                                  <p>{project?.partnerNotes}</p>
                                </S.NotesView>
                              )}
                            </S.NotesPartnerOrClient>
                          </S.BoxReview>
                        </S.WrapperReview>
                      </Col>
                      <Col xs={12} sm={12}>
                        <S.WrapperReview>
                          <S.TitleReview>
                            {I18n.get("Observações importantes do Cliente")}
                          </S.TitleReview>
                          <S.BoxReview>
                            <S.NotesPartnerOrClient>
                              <p>
                                {I18n.get(
                                  !auth?.is_partner
                                    ? "Aqui você pode adicionar observações importantes para o parceiro."
                                    : "Aqui você pode visualizar as observações importantes para o parceiro."
                                )}
                              </p>
                              {!auth?.is_partner ? (
                                <>
                                  {sendingNotes ? (
                                    <S.ContentLoadingSending>
                                      <Loading
                                        text={I18n.get(
                                          "Enviando comentário..."
                                        )}
                                        sizeText={14}
                                        sizeIcon={16}
                                        align="start"
                                      />
                                    </S.ContentLoadingSending>
                                  ) : (
                                    <Textarea
                                      label={I18n.get(
                                        "Observações importantes do Cliente"
                                      )}
                                      rows={4}
                                      defaultValue={notes}
                                      onChange={(value) => setNotes(value)}
                                      // required
                                      // disabled={isClient}
                                    />
                                  )}
                                </>
                              ) : (
                                <S.NotesView>
                                  <p>{project?.clientNotes}</p>
                                </S.NotesView>
                              )}
                            </S.NotesPartnerOrClient>
                          </S.BoxReview>
                        </S.WrapperReview>
                      </Col>
                    </Row>
                  </S.ContentReview>
                </Col>
              </Row>

              <Row style={{ marginBottom: 100 }}>
                <Col sm={24}>
                  <S.FooterMain>
                    {Object.keys(projectErrors).length > 0 && (
                      <Row>
                        <Col lg={24}>
                          {Object.keys(projectErrors).map((item, index) => {
                            let stepErrorItem = getStepError(
                              projectErrors[item]?.step
                            );

                            return (
                              <p>
                                <Link to={stepErrorItem?.url}>
                                  {" "}
                                  <span class="icon has-text-danger">
                                    <i class="fas fa-exclamation"></i>
                                  </span>
                                  {projectErrors[item].errorMessage} [
                                  {I18n.get("Editar")}]
                                </Link>
                              </p>
                            );
                          })}
                        </Col>
                      </Row>
                    )}

                    <S.RowFooter>
                      <S.ButtonsFooter></S.ButtonsFooter>
                      <S.ButtonsFooter>
                        <Link
                          to={`/${
                            auth?.is_partner ? "parceiro" : "cliente"
                          }/projetos/v2`}
                        >
                          <ButtonBpool
                            text={I18n.get("Back")}
                            theme="secondary"
                            className="btnPrev"
                            onClick={() => true}
                          />
                        </Link>
                        <Link
                          to={`/${
                            auth?.is_partner ? "parceiro" : "cliente"
                          }/projetos/v2/${projectId}/in-progress/files`}
                        >
                          <ButtonBpool
                            text={I18n.get("Next")}
                            theme="primary"
                            onClick={() => true}
                          />
                        </Link>
                      </S.ButtonsFooter>
                    </S.RowFooter>
                  </S.FooterMain>
                </Col>
              </Row>
            </>
          ) : (
            <Loading
              text={I18n.get("Loading...")}
              sizeText={14}
              sizeIcon={16}
              align="start"
            />
          )}
        </div>
      </Content>

      <ModalBp
        visible={successApprove}
        bodyText={I18n.get("Escopo aprovado com sucesso!")}
        okText={I18n.get("Ir para faturamento")}
        handleClickOk={() =>
          history.push(`/cliente/projetos/v2/${params.projectId}/faturamento`)
        }
        // isDanger={true}
      />

      <ModalBp
        visible={successDecline}
        bodyText={I18n.get("Escopo reprovado com sucesso!")}
        subBodyText={I18n.get("Aguarde as alterações do parceiro.")}
        okText={I18n.get("ok")}
        handleClickOk={() => loadReview()}
        // isDanger={true}
      />

      <ModalBp
        visible={visibleInclude}
        bodyText={I18n.get("Item Incluído")}
        okText={I18n.get("Confirmar")}
        handleClickOk={() => setVisibleInclude(false)}
        // isDanger={true}
      />

      <ModalBp
        visible={visibleExclude}
        bodyText={I18n.get("Item Apagado")}
        okText={I18n.get("Confirmar")}
        handleClickOk={() => setVisibleExclude(false)}
        // isDanger={true}
      />

      <ModalBp
        visible={visibleSend}
        bodyText={I18n.get("Submetido com sucesso!")}
        subBodyText={I18n.get(
          "O Cliente vai avaliar as informações, aprovar ou reprovar o escopo!"
        )}
        okText={I18n.get("OK!")}
        handleClickOk={() => history.push("/parceiro/projetos/v2")}
        // isDanger={true}
      />

      <ModalBp
        visible={visibleApprove}
        bodyText={I18n.get("Deseja aprovar o escopo do projeto?")}
        subBodyText={I18n.get(
          "Ao confirmar o projeto passará para o status EM ANDAMENTO, e o parceiro dará inicio ao projeto, deseja continuar?"
        )}
        okText={I18n.get("Aprovar")}
        cancelText={I18n.get("Cancelar")}
        onCancel={() => {
          setVisibleApprove(false);
        }}
        handleClickOk={() => {
          handleAproveOrDecline(true);
        }}
        typeModal="isConfirm"
      />

      <ModalBp
        visible={visibleDecline}
        bodyText={I18n.get("Deseja reprovar o escopo do projeto?")}
        subBodyText={I18n.get(
          "Ao confirmar o parceiro será notificado para fazer os ajustes necessarios, deseja continuar?"
        )}
        okText={I18n.get("Reprovar")}
        cancelText={I18n.get("Cancelar")}
        onCancel={() => {
          setVisibleDecline(false);
        }}
        handleClickOk={() => {
          handleAproveOrDecline(false);
        }}
        typeModal="isConfirm"
        isDanger={true}
      />

      <ModalBp
        visible={visibleDelete}
        bodyText={I18n.get("Apagar item selecionado?")}
        okText={I18n.get("Apagar")}
        cancelText={I18n.get("Cancelar")}
        onCancel={() => {
          setVisibleDelete(false);
        }}
        handleClickOk={() => {
          handleDeleteItem(codeDelete);
          setVisibleDelete(false);
        }}
        typeModal="isConfirm"
        isDanger={true}
      />
    </LoggedPage>
  );
};
