import React from 'react';

import { I18n } from 'aws-amplify';

import FormatCurrency from '../../ui/format-currency';

require('./quotation-budget.css');

class QuotationBudget extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {

        const { quotation = {}, currency = {} } = this.props;

        return (
            <div className="quotation-budget">
                


                <div className="columns head">

                    <div className="column is-6">{I18n.get("Descrição")}</div>

                    <div className="column is-3">
                        %
                    </div>

                    <div className="column is-3">{I18n.get("Valor")}</div>

                </div>

                {quotation.budget > 0 && <>

                    <div className="columns amount">

                        <div className="column is-6 tit">{I18n.get("Budget Projeto")}</div>

                        <div className="column is-3">
                        </div>

                        <div className="column is-3 has-text-right">
                            <FormatCurrency amount={quotation.budget} currency={currency} />
                        </div>

                    </div>

                </>}


                <div className="columns bpcommission">

                    <div className="column is-6 tit">{I18n.get("Platform Fee")}</div>

                    <div className="column is-3 has-text-right">
                        {quotation.bpCommission && quotation.bpCommission.percentageFmt}
                    </div>

                    <div className="column is-3 has-text-right">
                        <FormatCurrency amount={quotation.bpCommission && quotation.bpCommission.amount} currency={currency} />
                    </div>

                </div>

                {quotation.budget > 0 && <>

                    <div className="columns working">

                        <div className="column is-6 tit">{I18n.get("Budget Parceiro")}</div>

                        <div className="column is-3">
                        </div>

                        <div className="column is-3 has-text-right">
                            <FormatCurrency amount={quotation.workingBudget} currency={currency} />
                        </div>

                    </div>

                </>}

                <div className="columns total">

                    <div className="column is-6 tit">{I18n.get("Proposta Parceiro")}</div>

                    <div className="column is-3">
                    </div>

                    <div className="column is-3 has-text-right">
                        <FormatCurrency amount={quotation.totalExcludingBPCommission} currency={currency} />
                    </div>

                </div>

                {quotation.budget > 0 && <>

                    <div className="columns balance">

                        <div className="column is-6 tit">{I18n.get("Saldo disponivel")}</div>

                        <div className="column is-3">
                        </div>

                        <div className="column is-3 has-text-right">
                            <FormatCurrency amount={quotation.balance} currency={currency} />
                        </div>

                    </div>

                </>}

            </div>
        );
    }
}

export default QuotationBudget;
