import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #ffffff;
  margin-bottom: 15px;
`;

export const Content = styled.div`
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a.breadcrumb-link {
    color: #515151;
    font-size: 14px;
  }

  .ant-breadcrumb > span:last-child a.breadcrumb-link {
    color: #000;
    font-weight: 600;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: 700;
  font-size: 24px;
`;

export const ButtonsContent = styled.div`
  display: flex;

  button {
    margin: 0 15px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;
