import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useSupplier } from "../../../../../../../hooks/useSupplier/useSupplier.hook";
import { Drawer } from "../../../../../../../components/UI/Drawer";
import { NotificationBPool } from "../../../../../../../components/UI/NotificationBPool";
import { InfosDrawer } from "./InfosDrawer";
import { formatPaymentBeneficiaries } from "../DrawerStatus1/formatPaymentBeneficiaries";
import * as S from "../styles";

export const DrawerStatus4 = ({
  drawer,
  handleCloseDrawer,
  refetch,
  isPartner,
  bPaymentId,
}) => {
  const { getInfosInvoiceSupplier, getPaymentInformations } = useSupplier();
  const [isLoading, setIsLoading] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({});
  const [paymentBeneficiaries, setPaymentBeneficiaries] = useState([]);

  const handleOpenDrawer = async ({ bPaymentId }) => {
    console.log("open 2");
    try {
      setIsLoading(true);
      const response = await getInfosInvoiceSupplier(bPaymentId, isPartner);
      console.log("response", response);
      const responsePaymentInformations = await getPaymentInformations(
        isPartner
      );
      console.log("responsePaymentInformations", responsePaymentInformations);
      // setDataPaymentInformations({
      //   ...responsePaymentInformations?.data,
      //   registrationId: responsePaymentInformations?.data?.beneficiaryId,
      //   registrationName: responsePaymentInformations?.data?.beneficiaryName,
      // });
      const arrayPaymentBeneficiaries = formatPaymentBeneficiaries(
        responsePaymentInformations?.data?.paymentBeneficiaries
      );
      setPaymentBeneficiaries(arrayPaymentBeneficiaries);
      setDataDrawer(response?.data);
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Houve algum erro, tente novamente"),
        duration: 3,
      });
    } finally {
      setIsLoading(false);
      localStorage.removeItem("@BPOOL-supplier-bPaymentId");
      localStorage.removeItem("@BPOOL-accounts-dataSubirNf");
    }
  };

  useEffect(() => {
    if (drawer?.visible === true) {
      handleOpenDrawer({ bPaymentId: drawer?.bPaymentId });
    }
  }, [drawer?.visible]);

  return (
    <>
      <Drawer
        open={drawer.visible}
        handleClose={handleCloseDrawer}
        width={600}
        buttons={<></>}
      >
        <InfosDrawer
          data={dataDrawer}
          isLoading={isLoading}
          handleClose={handleCloseDrawer}
          refetch={refetch}
          isPartner={isPartner}
          bPaymentId={bPaymentId}
          paymentBeneficiaries={paymentBeneficiaries}
        />
      </Drawer>
    </>
  );
};
