import styled from "styled-components";

export const StyledSpanStatus = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  background: #1883ff;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;

  :focus,
  :active,
  :visited {
    background: #1883ff;
    color: #fff;
  }
  :hover {
    background: #1274e5;
    color: #fff;
  }
`;

export const Text = styled.div``;