import React, { Component } from "react";
import { Modal, Button } from 'antd';
import { I18n } from 'aws-amplify';


const style = {
    opacity: 1,
    backgroundColor: "rgba(0, 0, 0, 0.90)",
}

class ModalPdf extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <Modal
                title={I18n.get("Termos")}
                visible={true}
                onOk={this.handleOk}
                width="60%"
                footer={null}
                maskStyle={style}
                {...this.props}
                
            >
                <div style={{ height: "520px" }}>
                    <iframe src={`${this.props.pdf}`} height="500px" width="100%"></iframe>
                </div>
                <div>
                    {this.props.children}
                </div>
            </Modal>
        );
    }
}

export default ModalPdf;
