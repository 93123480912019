import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import RadioMultiField from "../form/radio-multi-field";

import { Input } from "../../v2/components/UI/Input";
import { Textarea } from "../../v2/components/UI/Textarea";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

class ServicesForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        setDesignType: {
          label: I18n.get(
            "Será necessário cenografia ou alguma estrutura específica?"
          ),
          onChange: this.onChangeForm.bind(this, "setDesignType"),
          // onBlur: this.onSave.bind(this),
        },
        setDesignDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "setDesignDesc"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
        furnitureType: {
          label: I18n.get("Mobiliário e paisagismo."),
          onChange: this.onChangeForm.bind(this, "furnitureType"),
          // onBlur: this.onSave.bind(this),
        },
        furnitureDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "furnitureDesc"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
        avEquipamentType: {
          label: I18n.get("Equipamento áudio & vídeo."),
          onChange: this.onChangeForm.bind(this, "avEquipamentType"),
          // onBlur: this.onSave.bind(this),
        },
        avEquipamentDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "avEquipamentDesc"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
        digitalFoodType: {
          label: I18n.get(
            "Será necessário pensarmos em algo de alimentos e bebida (Delivery)"
          ),
          onChange: this.onChangeForm.bind(this, "digitalFoodType"),
          // onBlur: this.onSave.bind(this),
        },
        digitalFoodDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "digitalFoodDesc"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckbox(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop].value = [...nextState.form[prop].value, value];
        } else {
          nextState.form[prop].value = nextState.form[prop].value.filter(
            (v) => v !== value
          );
        }

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};

    const isThemedCousineEnabled =
      Array.isArray(this.state.form.cateringType) &&
      this.state.form.cateringType.indexOf("THEMED_COUSINE") > -1;

    const cateringTypeTypeValues = constants.EVENT_CATERING_TYPES.map(
      (item) => {
        //Garante que o label está traduzido
        item.label = I18n.get(item.label);

        if (item.code == "THEMED_COUSINE") {
          item.field = (
            // <TextField
            //   className="field field-cond"
            //   {...this.state.fields.cateringDesc}
            //   value={this.state.form.cateringDesc}
            //   error={this.state.errors.cateringDesc}
            //   disabled={!isThemedCousineEnabled}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.cateringDesc.onChange(value)
              }
              value={this.state.form.cateringDesc}
              disabled={!isThemedCousineEnabled}
              error={this.state.errors.cateringDesc?.errorMessage}
            />
          );
        }

        return item;
      }
    );

    const setDesignTypeValues = constants.EVENT_SETDESIGN_TYPES.map((item) => {
      //Garante que o label está traduzido
      item.label = I18n.get(item.label);

      if (item.code == "YES") {
        item.field = (
          //   <TextField
          //     className="field field-cond"
          //     {...this.state.fields.setDesignDesc}
          //     value={this.state.form.setDesignDesc}
          //     error={this.state.errors.setDesignDesc}
          //     disabled={this.state.form.setDesignType != "YES"}
          //   />

          <Input
            onChange={(value) =>
              this.state?.fields?.setDesignDesc.onChange(value)
            }
            value={this.state.form.setDesignDesc}
            disabled={this.state.form.setDesignType != "YES"}
            error={this.state.errors.setDesignDesc?.errorMessage}
          />
        );
      }

      return item;
    });

    const furnitureTypeValues = constants.EVENT_FURNITURE_TYPES.map((item) => {
      //Garante que o label está traduzido
      item.label = I18n.get(item.label);

      if (item.code == "YES") {
        item.field = (
          //   <TextField
          //     className="field field-cond"
          //     {...this.state.fields.furnitureDesc}
          //     value={this.state.form.furnitureDesc}
          //     error={this.state.errors.furnitureDesc}
          //     disabled={this.state.form.furnitureType != "YES"}
          //   />

          <Input
            onChange={(value) =>
              this.state?.fields?.furnitureDesc.onChange(value)
            }
            value={this.state.form.furnitureDesc}
            disabled={this.state.form.furnitureType != "YES"}
            error={this.state.errors.furnitureDesc?.errorMessage}
          />
        );
      }

      return item;
    });

    const avEquipamentTypeValues = constants.EVENT_AVEQUIPAMENT_TYPES.map(
      (item) => {
        //Garante que o label está traduzido
        item.label = I18n.get(item.label);

        if (item.code == "YES") {
          item.field = (
            // <TextField
            //   className="field field-cond"
            //   {...this.state.fields.avEquipamentDesc}
            //   value={this.state.form.avEquipamentDesc}
            //   error={this.state.errors.avEquipamentDesc}
            //   disabled={this.state.form.avEquipamentType != "YES"}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.avEquipamentDesc.onChange(value)
              }
              value={this.state.form.avEquipamentDesc}
              disabled={this.state.form.avEquipamentType != "YES"}
              error={this.state.errors.avEquipamentDesc?.errorMessage}
            />
          );
        }

        return item;
      }
    );

    const digitalFoodTypeValues = constants.EVENT_DIGITAL_FOOD_TYPES.map(
      (item) => {
        //Garante que o label está traduzido
        item.label = I18n.get(item.label);

        if (item.code == "YES") {
          item.field = (
            // <TextField
            //   className="field field-cond"
            //   {...this.state.fields.digitalFoodDesc}
            //   value={this.state.form.digitalFoodDesc}
            //   error={this.state.errors.digitalFoodDesc}
            //   disabled={this.state.form.digitalFoodType != "YES"}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.digitalFoodDesc.onChange(value)
              }
              value={this.state.form.digitalFoodDesc}
              disabled={this.state.form.digitalFoodType != "YES"}
              error={this.state.errors.digitalFoodDesc?.errorMessage}
            />
          );
        }

        return item;
      }
    );

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Estrutura")}</h1>

        <div className="bp-event-section bpForce mini">
          <RadioMultiField
            {...this.state.fields.setDesignType}
            value={this.state.form.setDesignType}
            error={this.state.errors.setDesignType}
            values={setDesignTypeValues}
          />

          <br style={{ clear: "both" }} />
          <br />

          {customEvent.isDigital === true && (
            <>
              <RadioMultiField
                {...this.state.fields.digitalFoodType}
                value={this.state.form.digitalFoodType}
                error={this.state.errors.digitalFoodType}
                values={digitalFoodTypeValues}
              />

              <br style={{ clear: "both" }} />
              <br />
            </>
          )}

          {customEvent.isDigital === false && (
            <>
              <RadioMultiField
                {...this.state.fields.furnitureType}
                value={this.state.form.furnitureType}
                error={this.state.errors.furnitureType}
                values={furnitureTypeValues}
              />

              <br style={{ clear: "both" }} />
              <br />

              <RadioMultiField
                {...this.state.fields.avEquipamentType}
                value={this.state.form.avEquipamentType}
                error={this.state.errors.avEquipamentType}
                values={avEquipamentTypeValues}
              />

              <br style={{ clear: "both" }} />
              <br />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ServicesForm;
