import styled from "styled-components";
import { Card as CardAntd } from "antd";

export const StyledCard = styled(CardAntd)`
  border-radius: 10px;

  .ant-card-body {
    padding: 15px;
  }

  .ant-card-cover {
    min-height: 290px;

    img {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }

  .ant-card-actions {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    button {
      width: 100%;
      height: 50px;
      background: #1883ff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      box-shadow: none;

      span {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
      }

      :hover,
      :active,
      :focus {
        box-shadow: none;
      }
    }
  }

  .ant-card-actions > li {
    padding: 0 15px;
  }
  .ant-card-actions > li > span a:not(.ant-btn),
  .ant-card-actions > li > span > .anticon {
    display: flex;
    color: #fff;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  div > strong {
    font-size: 14px;
    line-height: 21px;
  }

  div > span {
    font-size: 14px;
    margin-left: 5px;
    font-weight: 400;
    line-height: 21px;
  }

  .title-partner {
    margin-bottom: 10px;

    strong,
    span {
      font-size: 16px;
    }
  }
`;

export const Card = styled.div`
  width: ${(props) => (props.windowWidth <= 425 ? "100%;" : "315px;")};
  height: ${(props) => (props.windowWidth <= 425 ? "420px;" : "470px;")};
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  margin-right: ${(props) => (props.windowWidth <= 1024 ? "10px;" : "25px;")};
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: #000000;
`;

export const HeaderCard = styled.div`
  padding: 0 0 25px 0;
  border-bottom: 1px solid;
  border-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 15px;
    max-height: 130px;
  }
`;

export const TitleHeaderCard = styled.div`
  font-size: ${(props) => (props.windowWidth <= 1024 ? "16px;" : "20px;")};
  line-height: ${(props) => (props.windowWidth <= 1024 ? "22px;" : "26px;")};
  font-weight: bold;
  color: #000;
`;

export const ContentCard = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-top: 25px;
`;

export const DateCard = styled.div`
  display: flex;
  color: #000;
  font-size: ${(props) => (props.windowWidth <= 1024 ? "12px;" : "16px;")};
  margin-top: 10px;

  strong {
    font-size: ${(props) => (props.windowWidth <= 1024 ? "12px;" : "16px;")};
    color: #000;
  }

  span {
    margin-left: 0 !important;
  }
`;

export const WrapperNameStatusAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const NameCard = styled.div`
  margin: 5px 0;
  display: flex;
  color: #000;
  font-size: ${(props) => (props.windowWidth <= 1024 ? "12px;" : "16px;")};

  strong {
    font-size: ${(props) => (props.windowWidth <= 1024 ? "12px;" : "16px;")};
    color: #000;
  }

  span {
    margin-left: 0 !important;
  }
`;

export const StatusCard = styled.div`
  display: flex;
  margin-top: 5px;

  p {
    font-size: ${(props) => (props.windowWidth <= 1024 ? "12px;" : "14px;")};
    margin: 0;
    padding: 0;
    font-weight: 600;
    color: ${(props) => (props.status === "Confirmada" ? "#1883FF" : null)};
    color: ${(props) => (props.status === "Aguardando" ? "#FCDF03" : null)};
    color: ${(props) => (props.status === "Declinada" ? "#f00" : null)};
  }
`;

export const ActionCard = styled.div`
  display: flex;
  /* margin-top: 75px; */

  a {
    width: 100%;
    height: 50px;
    background: #1883ff;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-weight: 700;

    div {
      text-align: center;
    }
  }
`;

export const DisabledLink = styled.div`
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  background-color: #e7e7e7;
  border-color: #e7e7e7;
  color: #979797;
  font-weight: 700;
  cursor: not-allowed;
`;

export const Ico = styled.div`
  margin-right: 15px;
`;
