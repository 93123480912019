import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import RadioMultiField from "../form/radio-multi-field";
import TextAreaField from "../form/textarea-field";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

import { CustomEventBriefingType } from "../../model";

import { Input } from "../../v2/components/UI/Input";

const evt = CustomEventBriefingType.Event;
const shp = CustomEventBriefingType.Shopper;
const ict = CustomEventBriefingType.Incentive;

class LocationForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        digitalPlatformType: {
          label: I18n.get("Tem preferencia de alguma plataforma"),
          name: "digitalPlatformType",
          onChange: this.onChangeForm.bind(this, "digitalPlatformType"),
          // onBlur: this.onSave.bind(this),
          values: [],
        },
        digitalPlatformName: {
          label: "",
          name: "digitalPlatformName",
          onChange: this.onChangeForm.bind(this, "digitalPlatformName"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
        digitalPlatformExamples: {
          label: "",
          name: "digitalPlatformExamples",
          onChange: this.onChangeForm.bind(this, "digitalPlatformExamples"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
        digitalPlatformIntegrationType: {
          label: I18n.get(
            "Precisamos integrar a plataforma do evento com alguma interna"
          ),
          name: "digitalPlatformIntegrationType",
          onChange: this.onChangeForm.bind(
            this,
            "digitalPlatformIntegrationType"
          ),
          // onBlur: this.onSave.bind(this),
          values: [],
        },
        digitalPlatformIntegrationDesc: {
          label: "",
          name: "digitalPlatformIntegrationDesc",
          onChange: this.onChangeForm.bind(
            this,
            "digitalPlatformIntegrationDesc"
          ),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
        digitalRestrictionType: {
          label: I18n.get(
            "Existe alguma restrição de internet em relação aos convidados que precisamos pensar"
          ),
          onChange: this.onChangeForm.bind(this, "digitalRestrictionType"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};

    const platformTypeValues = constants.EVENT_DIGITAL_PLATFORM_TYPES.map(
      (item) => {
        if (item.code == "YES") {
          item.field = (
            // <TextField
            //   className="field-cond"
            //   {...this.state.fields.digitalPlatformName}
            //   value={this.state.form.digitalPlatformName}
            //   error={this.state.errors.digitalPlatformName}
            //   disabled={this.state.form.digitalPlatformType != "YES"}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.digitalPlatformName.onChange(value)
              }
              value={this.state.form.digitalPlatformName}
              disabled={this.state.form.digitalPlatformType != "YES"}
              error={this.state.errors.digitalPlatformName?.errorMessage}
            />
          );
        }

        if (item.code == "SIMILAR_TO") {
          item.field = (
            // <TextField
            //   className="field-cond"
            //   {...this.state.fields.digitalPlatformExamples}
            //   value={this.state.form.digitalPlatformExamples}
            //   error={this.state.errors.digitalPlatformExamples}
            //   disabled={this.state.form.digitalPlatformType != "SIMILAR_TO"}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.digitalPlatformExamples.onChange(value)
              }
              value={this.state.form.digitalPlatformExamples}
              disabled={this.state.form.digitalPlatformType != "SIMILAR_TO"}
              error={this.state.errors.digitalPlatformExamples?.errorMessage}
            />
          );
        }

        return item;
      }
    );

    const platformIntegrationValues =
      constants.EVENT_DIGITAL_PLATFORM_INTEGRATIONS.map((item) => {
        if (item.code == "YES") {
          item.field = (
            // <TextField
            //   className="field-cond"
            //   {...this.state.fields.digitalPlatformIntegrationDesc}
            //   value={this.state.form.digitalPlatformIntegrationDesc}
            //   error={this.state.errors.digitalPlatformIntegrationDesc}
            //   disabled={this.state.form.digitalPlatformIntegrationType != "YES"}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.digitalPlatformIntegrationDesc.onChange(
                  value
                )
              }
              value={this.state.form.digitalPlatformIntegrationDesc}
              disabled={this.state.form.digitalPlatformIntegrationType != "YES"}
              error={
                this.state.errors.digitalPlatformIntegrationDesc?.errorMessage
              }
            />
          );
        }

        return item;
      });

    const platformRestrictionValues =
      constants.EVENT_DIGITAL_INTERNET_RESTRICTIONS.map((item) => {
        return item;
      });

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Plataforma")}</h1>

        <div className="bp-event-section bpForce mini">
          <RadioMultiField
            {...this.state.fields.digitalPlatformType}
            value={this.state.form.digitalPlatformType}
            error={this.state.errors.digitalPlatformType}
            values={platformTypeValues}
          />

          <br />

          <RadioMultiField
            {...this.state.fields.digitalPlatformIntegrationType}
            value={this.state.form.digitalPlatformIntegrationType}
            error={this.state.errors.digitalPlatformIntegrationType}
            values={platformIntegrationValues}
          />

          <br />

          <RadioMultiField
            {...this.state.fields.digitalRestrictionType}
            value={this.state.form.digitalRestrictionType}
            error={this.state.errors.digitalRestrictionType}
            values={platformRestrictionValues}
          />
        </div>
      </div>
    );
  }
}

export default LocationForm;
