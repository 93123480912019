import React from "react";
import Logo from "./images/logo.png";
import IcoConfirm from "./images/ico-confirm-modal.svg";
import IcoConfirmWhite from "./images/white-ico-confirm-modal.svg";
import IcoAlert from "./images/ico-alert-modal.svg";
import IcoAlertWhite from "./images/white-ico-alert-modal.svg";
import IcoAlertWhiteError from "./images/white-ico-alert-modal-error.svg";

import * as S from "./styles";

export const Dialog = ({
  visible,
  text,
  subText,
  loading,
  buttons,
  theme,
  error,
}) => {
  return (
    <S.MainModal
      theme={theme}
      isDanger
      centered
      visible={visible}
      okText={buttons?.ok?.text}
      onOk={buttons?.ok?.func}
      confirmLoading={loading}
      onCancel={buttons?.cancel?.func || buttons?.ok?.func}
      cancelText={buttons?.cancel?.text}
      wrapClassName={`dialog-bp`}
      cancelButtonProps={{
        style: {
          display: !buttons?.cancel?.func ? "none" : "",
        },
      }}
    >
      {theme === "white" ? null : (
        <S.Logo>
          <img src={Logo} alt="Bpool" />
        </S.Logo>
      )}
      <S.Icone>
        {buttons?.cancel?.text ? (
          theme === "white" ? (
            <img src={IcoAlertWhite} alt="Ico confirm modal" />
          ) : (
            <img src={IcoAlert} alt="Ico confirm modal" />
          )
        ) : theme === "white" ? (
          error ? (
            <img src={IcoAlertWhiteError} alt="Ico alert error modal" />
          ) : (
            <img src={IcoConfirmWhite} alt="Ico confirm modal" />
          )
        ) : (
          <img src={IcoConfirm} alt="Ico confirm modal" />
        )}
      </S.Icone>
      <S.Text theme={theme}>{text}</S.Text>
      {subText ? <S.SubText theme={theme}>{subText}</S.SubText> : null}
    </S.MainModal>
  );
};
