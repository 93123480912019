import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { Skeleton } from 'antd';
import styled from 'styled-components';


const SkeletonBox = styled(Skeleton)`
    margin-bottom: 30px;

    &.ultimo {
        margin-bottom: 100px;
    }
`;

class FormSkeleton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Fragment>
                <SkeletonBox active />
                <SkeletonBox active />
                <SkeletonBox active />
                <SkeletonBox className="ultimo" active />
            </Fragment>
        );
    }
}

export default FormSkeleton;




