import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { BP_API } from "../../actions/bp/actions";
import { EXTRAS_API } from "../../actions/extras/actions";

import LoggedPage from "../page-templates/loggedAdmin";

import BillingDetailsView from "./billing/_details-extra";

import Constants from "../../constants";

import { Button, notification } from "antd";

const mapStateToProps = (state) => {
  return Object.assign({}, state.bp && state.bp.project, state.extras, state);
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_project: (data) => {
      dispatch(BP_API.get_project(data));
    },
    updateCommission: (data) => {
      return dispatch(BP_API.update_commission(data));
    }, 
    create_billing_installment: (data) => {
      return dispatch(BP_API.create_extra_billing_installment(data));
    },
    delete_installment: (data) => {
      return dispatch(BP_API.delete_installment(data));
    },
    delete_invoice: (data) => {
      return dispatch(BP_API.delete_invoice(data));
    },
    update_bpfns: (data) => {
      return BP_API.update_bpnfs_extra(data);
    },
  };
};

class BillingDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      projectId: props.match.params.projectId,
    };
  }

  componentDidMount() {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_project(data);
  }

  handleNF = async (data) => {
    const dataSend = {
      projectId: this.props.match.params.projectId,
      extraId: this.props.match.params.extraId,
      ...data,
    };

    await this.props.update_bpfns(dataSend).then((response) => {
      if (response?.status == 200) {
        let data2 = {
          projectId: this.props.match.params.projectId,
        };

        notification.success({
          message: I18n.get("Atualizado com sucesso"),
        });
        this.props.get_project(data2);
      }
    });
    return true;
  };
  // 

  sendCommission = async (value) => {
    const { billingId, projectId, extraId} = this.props.match.params;
    const dataC = {
      bpCommissionAmount: value,
      billingId: billingId,
      projectId: projectId,
      extraId: extraId
    };


   const response = await this.props.updateCommission(dataC);

   var data = {
    projectId: this.props.match.params.projectId,
  };

  this.props.get_project(data);

   return response.data;
   
  };

  render() {
    const { extraId, billingId } = this.props.match.params;

    const { project = {} } = this.props.bp;

    const { extras = [] } = project;

    const extra = (extras && extras.find((e) => e.id == extraId)) || {};

    const { billings = [] } = extra;

    const billing = billings.find((item) => item.id == billingId);

    ////
    let projectType = this.props.projectType;

    let projectTypeName = Constants.PROJECT_TYPES.find(xx=> xx.code == projectType)?.label ?? "ERROR"

    if (projectType == 2) {
      
      let proposalType = this.props.customEvent.briefingType;
      
      //let proposalTypeName = Constants.PROJECT_PROPOSAL_TYPES.find(xx=> xx.code == proposalType)?.label ?? "ERROR"
      
      let events = [0,1,2,3]; //subtipos de proposal consideraros "Live Marketing"
      //Outros sao somente Proposal

      if (events.includes(proposalType) == true) {
        projectTypeName = `${projectTypeName} Live Marketing`;
      }
      
    }

    let extraTypeName;
    if (extra) {
      extraTypeName = Constants.PROJECT_EXTRA_TYPES.find(xx=> xx.code == extra.extraType)?.label ?? "ERROR"
    }

    return (
      <LoggedPage {...this.props}>
        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <NavLink to="/admin" className="is-black">
                {I18n.get("Admin")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/bpool/faturamento" className="is-black">
                {I18n.get("Faturamento")}
              </NavLink>
            </li>
            <li className="is-active">
              <a href="#">{this.props.projectName}</a>
            </li>
          </ul>
        </nav>

        <br />
        <br />

        <div className="columns">

          <div className="column is-12">

          <div class="columns">
              <div class="column is-3">

                    <div className="title-bpool medium">
                    <strong>{this.props.projectName}</strong>
                    <div className="has-text-grey-light">{this.props.clientName}</div>
                    <div className="is-size-7 is-uppercase">{projectTypeName}</div>
                  </div>
                
              </div>

              <div class="column is-4">

                      <div className="title-bpool medium">
                      <strong>{extra.extraName}</strong>
                      <div className="is-size-7 is-uppercase">Extra {extraTypeName}</div>
                    </div>

              </div>

              <div class="column"></div>
        </div>


            {billing?.id && (
              <BillingDetailsView
                {...this.props}
                billing={billing}
                handleNF={this.handleNF}
                sendCommission={this.sendCommission}
              />
            )}
          </div>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetails);
