import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_CLIENT_LIST_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_partners = ({ country = "", name = "", status = "", spec = "", market = "", amarket = ""}) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/bp/partners/list?country=${country}&name=${name}&status=${status}&spec=${spec}&market=${market}&amarket=${amarket}`, config)
                .then(response => {
                    dispatch({
                        type: BP_CLIENT_LIST_GET,
                        payload: {
                            adminPartnersList: response.data,
                            task: {
                                result: true
                            }
                        }
                    });
                })
                .catch(error => {

                    throw (error);
                });

        }).catch(error => {

            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_partners;
