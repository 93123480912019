import React from "react";
import * as S from "./styles";
import ErrorIco from "../../../../svg/Error.svg";

const ErrorField = ({ error }) => {
  return (
    <S.StyledError>
      <S.Icon>
        <img src={ErrorIco} />
      </S.Icon>
      <S.Label>{error}</S.Label>
    </S.StyledError>
  );
};

export default ErrorField;
