import React from 'react';

import { I18n } from 'aws-amplify';

import LeaderDetails from './_leader-details';

import TableLeaders from '../../modules/Admin/components/tableLeaders';

import Modal from '../ui/modal';

class Cmp extends React.Component {

    constructor(props) {

        super();

        this.state = {
            selected: null,
        };

        this.select = this.onSelect.bind(this);
        this.closeModal = this.onCloseModal.bind(this);
    }

    onSelect = (item) => {
        this.setState({ selected: item });
    }

    onCloseModal(e) {
        e.preventDefault();

        this.setState({ selected: null });
    }

    render() {

        if (!this.props.bp) return null;
        if (!this.props.bp.partner) return null;

        const item = this.props.bp.partner;
        const sf = this.props.bp.sectorsFlat;

        const className = this.state.selected ? 'is-active' : '';

        const itens = this.props.bp.partner.leaders && this.props.bp.partner.leaders.sort(function (a, b) {
            return a.order - b.order;
        })

        return (
            <section>
                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Líderes")}</h3>

                        <div className="content">

                            <TableLeaders
                                data={itens}
                                partnerId={this.props.partner && this.props.partner.partnerId}
                                order={this.props.updateOrderLeader}
                                edit={this.showEditLeaders}
                                delete={this.props.delete_leader}
                                viewItem={this.onSelect}
                            />
                        </div>
                    </div>
                </div>

                <Modal isOpen={!!this.state.selected} closeModal={this.closeModal}>

                    <LeaderDetails item={this.state.selected} sf={sf} />

                </Modal>

            </section>
        );
    }
}

export default Cmp;
