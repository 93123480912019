import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

import { LEADER_API } from '../../actions/leader/actions';

import MarketExpertiseCmp from './_expertise-cmp';

const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp, marketExpertise: { errors: {} }, }, state.leader);
};

const mapDispatchToProps = dispatch => {
    return {
        addMarketExpertise: data => {
            dispatch(LEADER_API.create_expertise(data));
        },
        delMarketExpertise: data => {
            dispatch(LEADER_API.delete_expertise(data));
        },
        save: data => {
        }
    };
};

class ExpertiseForm extends React.Component {
    constructor(props) {
        super();

        this.state = {
            leaderId: props.leaderId,
            marketExpertises: props.marketExpertises || []
        };

        this.submitForm = this.submitForm.bind(this);
    }

    onSave(e) {
        e.preventDefault();
        this.props.save(this.state);
    }

    submitForm(e) {
        e.preventDefault();
        var data = Object.assign({}, this.state, { isSubmit: true });
        this.props.save(data);
    }

    render() {
        return (<form onSubmit={this.submitForm}>

            <MarketExpertiseCmp {...this.props} type={2} />

        </form>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpertiseForm);
