import React from "react";
import * as S from "./styles";

export const Info = ({ label, value, isLoading, colorBlack }) => {
  const renderValue = (value) => {
    if (value === 0 || value === "0") {
      return value;
    } else if (value) {
      return value;
    } else {
      return "---";
    }
  };
  return (
    <S.Info colorBlack={colorBlack}>
      <label>{label}</label>
      <S.Skeleton
        loading={isLoading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-infos",
          width: "100px",
        }}
      >
        <p className="description">{renderValue(value)}</p>
      </S.Skeleton>
    </S.Info>
  );
};
