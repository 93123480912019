import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { Route, Switch } from "react-router-dom";

import PartnerVerifyEmailPre from "./partner/verify-email-pre";
import AboutBoutique from "./partner/about-boutique";
import Specialisms from "./partner/specialisms";
import Expertise from "./partner/expertise";
import Works from "./partner/works";
import Clients from "./partner/clients";

import FullCompany from "./partner/full-company";
import FullResponsible from "./partner/full-responsible";
import FullSpecialisms from "./partner/full-specialisms";
import FullExpertise from "./partner/full-expertise";
import FullWorks from "./partner/full-works";
import FullClients from "./partner/full-clients";
import FullLeaders from "./partner/full-leaders";
import PreviewPartner from "./partner/partner-profile-preview";
import PreviewPartnerCard from "./partner/partner-profile-preview-card";
import PartnerSuccess from "./partner/success";

import LeaderAbout from "./leaders/about";
import LeaderExpertise from "./leaders/expertise";
import WorksExpertise from "./leaders/works";
import LeaderSuccess from "./leaders/success";

import ReferenceAbout from "./reference/about";
import ReferenceSuccess from "./reference/success";

import UserPartnerVerifyEmail from "./partner-user/verify-email";
import UserAbout from "./partner-user/about";
import UserProfileSuccess from "./partner-user/success";

import ResponsibleClient from "./client/client-responsible";
import CompanyClient from "./client/client-company";
import BrandsClient from "./client/client-brands";
import AccessClient from "./client/client-access";
import ClientSuccess from "./client/success";

import UserClientVerifyEmail from "./client-user/verify-email";
import UserClientAbout from "./client-user/about";
import UserClientSuccess from "./client-user/success";

import { AboutYou as UserAboutYou } from "../../v2/pages/RegistrationFlow/User/AboutYou";
import { VerifyPreRegistry } from "../../v2/pages/RegistrationFlow/VerifyPreRegistry";

class Register extends Component {
  state = {};
  render() {
    return (
      <Switch>
        {/* PARTNER USER */}
        <Route
          exact
          path="/parceiro/usuario/convite/:verificationToken"
          component={UserAboutYou}
        />
        <Route
          exact
          path="/parceiro/usuario/cadastro/sobre-voce/:token"
          component={UserAbout}
        />
        <Route
          exact
          path="/parceiro/usuario/cadastro/sucesso"
          component={UserProfileSuccess}
        />

        {/* PARTNER PRÉ-CADASTRO */}
        <Route
          exact
          path="/parceiro/verifica-pre-cadastro/:verificationToken"
          component={VerifyPreRegistry}
        />

        <Route
          exact
          path="/parceiro/pre-cadastro/:partnerId"
          component={AboutBoutique}
        />
        <Route
          exact
          path="/parceiro/pre-cadastro/especialidades/:partnerId"
          component={Specialisms}
        />
        <Route
          exact
          path="/parceiro/pre-cadastro/expertises/:partnerId"
          component={Expertise}
        />
        <Route
          exact
          path="/parceiro/pre-cadastro/trabalhos/:partnerId"
          component={Works}
        />
        <Route
          exact
          path="/parceiro/pre-cadastro/clientes/:partnerId"
          component={Clients}
        />

        {/* PARTNER CADASTRO */}
        <Route
          exact
          path="/parceiro/cadastro/empresa/:partnerId"
          component={FullCompany}
        />
        <Route
          exact
          path="/parceiro/cadastro/especialidades/:partnerId"
          component={FullSpecialisms}
        />
        <Route
          exact
          path="/parceiro/cadastro/expertises/:partnerId"
          component={FullExpertise}
        />
        <Route
          exact
          path="/parceiro/cadastro/trabalhos/:partnerId"
          component={FullWorks}
        />
        <Route
          exact
          path="/parceiro/cadastro/clientes-e-referencias/:partnerId"
          component={FullClients}
        />
        <Route
          exact
          path="/parceiro/cadastro/usuarios/:partnerId"
          component={FullResponsible}
        />
        <Route
          exact
          path="/parceiro/cadastro/lideranca/:partnerId"
          component={FullLeaders}
        />
        <Route
          exact
          path="/parceiro/cadastro/preview/:partnerId"
          component={PreviewPartner}
        />
        <Route
          exact
          path="/parceiro/cadastro/preview-card/:partnerId"
          component={PreviewPartnerCard}
        />
        <Route
          exact
          path="/parceiro/cadastro/sucesso"
          component={PartnerSuccess}
        />

        {/* LEADER CADASTRO */}
        <Route
          exact
          path="/lider/cadastro/sobre-voce/:leaderId/:token"
          component={LeaderAbout}
        />
        <Route
          exact
          path="/lider/cadastro/expertise/:leaderId/:token"
          component={LeaderExpertise}
        />
        <Route
          exact
          path="/lider/cadastro/trabalhos/:leaderId/:token"
          component={WorksExpertise}
        />
        <Route exact path="/lider/cadastro/sucesso" component={LeaderSuccess} />

        {/* REFERENCIA CADASTRO */}
        <Route
          exact
          path="/referencia/cadastro/sobre-voce/:verificationToken"
          component={ReferenceAbout}
        />
        <Route
          exact
          path="/referencia/cadastro/sucesso"
          component={ReferenceSuccess}
        />

        {/* CLIENT */}
        <Route
          exact
          path="/cliente/cadastre-se/responsavel/:clientId"
          component={ResponsibleClient}
        />
        <Route
          exact
          path="/cliente/cadastre-se/empresa/:clientId"
          component={CompanyClient}
        />
        <Route
          exact
          path="/cliente/cadastre-se/marcas-e-usuarios/:clientId"
          component={BrandsClient}
        />
        <Route
          exact
          path="/cliente/cadastre-se/acesso-master/:clientId"
          component={AccessClient}
        />
        <Route
          exact
          path="/cliente/cadastre-se/sucesso"
          component={ClientSuccess}
        />

        {/* CLIENT USER */}
        <Route
          exact
          path="/cliente/usuario/verifica-email/:verificationToken"
          component={UserClientVerifyEmail}
        />
        <Route
          exact
          path="/cliente/usuario/cadastro/sobre-voce/:token"
          component={UserClientAbout}
        />
        <Route
          exact
          path="/cliente/usuario/cadastro/sucesso"
          component={UserClientSuccess}
        />
      </Switch>
    );
  }
}
export default Register;
