import axios from 'axios';

import Config from '../../config';

import { CLIENT_FEEDBACK } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_client_feedback = (data) => {
    return (dispatch) => {

        let msg = {
            comment: data.comment
        };

        return axios.put(`${Config.API_BASE_URL}/client/feedback/${data.projectId}/${data.feedbackId}/${data.grade}`, msg)
    };
}
export default api_client_feedback;
