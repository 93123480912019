import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-steps-item {
    .ant-steps-item-container {
      :hover {
        .icon-custom {
          background: #1890ff;

          span {
            color: #ffffff;
          }
        }
      }
    }
  }

  /* .ant-steps-item-error {
    .ant-steps-item-container {
      :hover {
        .icon-error-custom {
          background: #f5222d;

          span {
            color: #ffffff;
          }
        }

        .ant-steps-item-content {
          .ant-steps-item-title {
            color: #f5222d !important;
          }
          .ant-steps-item-description {
            color: #f5222d !important;
          }
        }
      }
    }
  } */

  .ant-steps-item-active {
    .ant-steps-item-container {
      .icon-custom {
        background: #1890ff;

        span {
          color: #ffffff;
        }
      }
    }
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: #000000;
      }
      .ant-steps-item-description {
        color: #000000;
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-container {
      .icon-custom {
        background: #1890ff;

        span {
          color: #ffffff;
        }
      }
    }
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: #1890ff;
      }
      .ant-steps-item-description {
        color: #1890ff;
      }
    }
  }

  .ant-steps-item-content {
    font-family: Helvetica, sans-serif;

    .ant-steps-item-title {
      font-size: 14px;
      font-weight: bold;
      font-family: 'Helvetica Neue LT Std';
    }

    .ant-steps-item-description {
      font-size: 14px;
    }
  }

  .ant-steps-item-error {
    span {
      :hover {
        color: #1890ff;
      }
    }
  }

  // inicio status process
  .ant-steps-vertical {
    .ant-steps-item-process {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          border-color: #1890ff;

          .ant-steps-icon {
            color: #fff;
          }
        }
        .ant-steps-item-title {
          color: #000000;
        }
        .ant-steps-item-description {
          color: #000000;
        }
      }
    }
  }
  // fim status process

  // status erro
  .ant-steps-vertical {
    .ant-steps-item-error {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background-color: #fff;
          border-color: #f5222d;

          .ant-steps-icon {
            color: #f5222d;
          }
        }

        .ant-steps-item-title {
          color: #f5222d;
        }
        .ant-steps-item-description {
          color: #f5222d;
        }

        :hover {
          .ant-steps-item-icon {
            background-color: #d21d26;
            border-color: #d21d26 !important;

            .ant-steps-icon {
              color: #fff !important;
            }
          }

          .ant-steps-item-title {
            color: #d21d26 !important;
          }
          .ant-steps-item-description {
            color: #d21d26 !important;
          }
        }
      }
    }
    .ant-steps-item-error.status-active {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background-color: #f5222d;
          color: #fff;

          .ant-steps-icon {
            color: #fff;
          }
        }

        :hover {
          .ant-steps-item-icon {
            background-color: #d21d26 !important;
            border-color: #d21d26 !important;

            .ant-steps-icon {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
  // fim status erro

  // inicio status visisted/wait
  .ant-steps-vertical {
    .ant-steps-item-wait.step-visited {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background-color: #fff;
          border-color: #000000;

          .ant-steps-icon {
            color: #000000;
          }
        }

        .ant-steps-item-title {
          color: #000000;
        }
        .ant-steps-item-description {
          color: #000000;
        }
      }
    }
  }
  // fim status visisted/wait

  /* .ant-steps-vertical .ant-steps-item.ant-steps-item-process.step-visited {
    .ant-steps-icon {
      color: #ffffff;
    }
    .ant-steps-item-icon {
      border-color: #1890ff;
    }
  } */
`;

export const IconError = styled.div`
  width: 32px;
  height: 32px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 32px;
  text-align: center;
  border: 1px solid #f5222d;
  border-radius: 16px;
  transition: background-color 0.3s, border-color 0.3s;

  span {
    position: relative;
    top: -1px;
    color: #f5222d;
    line-height: 1;
    font-size: 16px;
  }
`;

export const Icon = styled.div`
  width: 32px;
  height: 32px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 32px;
  text-align: center;
  border: 1px solid #1890ff;
  border-radius: 16px;
  transition: background-color 0.3s, border-color 0.3s;

  span {
    position: relative;
    top: -1px;
    color: #1890ff;
    line-height: 1;
    font-size: 16px;
  }
`;

export const IconFinish = styled.div`
  width: 32px;
  height: 32px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 32px;
  text-align: center;
  border: 1px solid #1890ff;
  border-radius: 16px;
  transition: background-color 0.3s, border-color 0.3s;

  span {
    position: relative;
    top: -1px;
    color: #1890ff;
    line-height: 1;
    font-size: 16px;
  }
`;
