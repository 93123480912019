import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { Task } from "../../../utils/task";
import { FormUtils } from "../../../utils/form";

import { PARTNER_API } from "../../../actions/partner/actions";

import LoggedPage from "../../page-templates/fillForm-logged";

import ProjectDetails from "./_project-details";

import CheckboxField from "../../form/checkbox-field";
import SubmitButtonField from "../../form/submit-button-field";

import ModalPdf from "../../../modules/components/ModalPdf";
import FormTerms from "./_form-terms";

import { scrollToTop } from "../../../utils/ui";

import { Row, Col, Input, Select } from "antd";

const { Option } = Select;

const mapStateToProps = (state) => {
  return Object.assign(
    state,
    state.partner && state.partner.project,
    state.partner
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_project: (data) => {
      dispatch(PARTNER_API.get_project(data));
    },
    get_userop: (data) => {
      dispatch(PARTNER_API.get_userop(data));
    },
    get_indemnity: (data) => {
      dispatch(PARTNER_API.get_indemnity(data));
    },
    save: (data) => {
      return dispatch(PARTNER_API.update_invitation(data));
    },
    update_invitation_withdraw: (data) => {
      return dispatch(PARTNER_API.update_invitation_withdraw(data));
    },
  };
};

class ProjectInviteAdmin extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {
        projectId: props.projectId,
        isNonCompeteAware: {
          label: "",
          value: false,
          checked: props.isNonCompeteAware,
          onChange: this.onChangeFormCheckbox.bind(this, "isNonCompeteAware"),
          error: props.errors.isNonCompeteAware,
          successIcon: false,
          visited: true,
        },
      },
      task: {},
      withdraw_task: {},
      errors: {},
      userOp: null,
      loading: false,
      visibleTerms: false,
    };

    this.submitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.projectId) {
      var data = {
        projectId: this.props.match.params.projectId,
      };

      // this.props.get_assets(data);

      // this.props.get_bundles(data);

      this.props.get_project(data);
    }

    scrollToTop();
  }

  componentWillUnmount() {}

  handleConfirm = () => {
    var data = {
      projectId: this.props.match.params.projectId,
      partnerId: this.props.partner.partnerId,
    };

    this.props.get_indemnity(data);

    // setTimeout(() => {
    //     this.props.get_project(data);
    // }, 1000);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ this: this.props, prev: prevProps });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value = this.props[item] || "";
        } else if (
          typeof nextState.form !== "undefined" &&
          typeof this.props[item] !== "undefined"
        ) {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }

    if (
      this.props.partner != prevProps.partner &&
      this.props.partner.partnerId &&
      !this.state.loading
    ) {
      this.props.get_userop(this.props.partner.partnerId);

      this.setState({
        loading: true,
      });
    }
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].checked = checked;
      nextState.form[prop].value = checked;

      return nextState;
    });
  }

  handleSelectUser = (value) => {
    this.setState({
      userOp: value,
    });
  };

  getData() {
    let operador = "";
    let userOpactive =
      this.props.usersOperationSelect &&
      this.props.usersOperationSelect.find(
        (s) => s.username == this.props.username
      );

    if (this.state.userOp) {
      operador = this.state.userOp;
    } else {
      operador = userOpactive
        ? userOpactive.username
        : this.props.usersOperationSelect[0].username;
    }

    var data = {
      projectId: this.props.match.params.projectId,
      partnerUsername: operador,
      accepted: true,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (
        typeof this.state.form[item] === "object" &&
        "value" in this.state.form[item]
      ) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  onSubmitForm(accepted, e) {
    e.preventDefault();

    var data = this.getData();

    var task = new Task();

    task.start();

    setTimeout(() => {
      this.setState({ task: task }, function () {
        this.props.save(data);
      });
    }, 100);
  }

  onWithdraw(e) {
    e.preventDefault();

    var data = {
      projectId: this.props.match.params.projectId,
    };

    var withdraw_task = new Task();

    withdraw_task.start();

    this.setState({ withdraw_task: withdraw_task });

    this.props.update_invitation_withdraw(data).then(({ task, errors }) => {
      this.setState({ withdraw_task: task, errors: errors });
    });
  }

  getNonCompete(days) {
    var txt = I18n.get("Nenhum");

    if (days == 0) txt = I18n.get("DURANTE O PROJETO");

    if (days > 0)
      txt = I18n.get("DURANTE O PROJETO @@days@@ dias").replace(
        "@@days@@",
        days
      );

    return txt;
  }

  render() {
    // if (!this.props.projectId) return <Loading />
    const invitation =
      (this.props.projectInvitations &&
        this.props.projectInvitations.filter(
          (item) => item.projectId == this.props.match.params.projectId
        )[0]) ||
      {};

    let inviteRates =
      (this.props.reasons2 &&
        this.props.reasons2.filter(
          (p) => p.partnerId == this.props.partnerId
        )) ||
      null;

    let textos = ["texto <b>1</b>", "texto <b>2</b>", "texto <b>3</b>"];

    const nonCompeteMessage = I18n.get(
      "Ao aceitar esse projeto, você automaticamente se comprometerá com um NON-COMPETE @@nonCompete@@ na categoria @@cat@@."
    )
      .replace(
        "@@nonCompete@@",
        this.getNonCompete(this.props.nonCompeteInDays)
      )
      .replace("@@cat@@", this.props.categoryName);

    // if (invitation.accepted === true){
    //     return (<Redirect to="/parceiro/projetos" />);
    // }

    // --- --- --- REPLACE WITH THE FINAL IMAGE URL FROM DB
    const cover =
      this.props.company && this.props.company.introImage
        ? this.props.company.introImage
        : "/img/project-cover.svg";
    const logo =
      this.props.company && this.props.company.logoImage
        ? this.props.company.logoImage
        : "/img/project-avatar.svg";
    // --- --- --- END

    console.log("this.props.usersOperationSelect", this.props.usersOperationSelect);

    // /
    let userOpactive =
      this.props.usersOperationSelect && this.props.usersOperationSelect.length > 0 &&
      this.props.usersOperationSelect.find(
        (s) => s.username == this.props.username
      );

    let hasConfirmedAlready =
      this.props.indemnityAgreements?.some(
        (p) => p.partnerId == this.props.partnerId
      ) || false;


     
    return (
      <LoggedPage>
        <div className="hero-body has-background-white project-definition-header container-content header-bpool">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-8">
                <p className="title">
                  {I18n.get("Convite de projeto")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-4">
                {/* <Steps active={1} projectId={this.props.match.params.projectId} /> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="client-step client-step-1 project-definition container-content project-invite uplift full-review-bg"
          style={{ backgroundColor: "#fafafa" }}
        >
          {/* <div className="opening-cover" style={{ backgroundImage: `url('${cover}')` }}>
              <div className="opening-cover-logo" style={{ backgroundImage: `url('${logo}')` }}></div>
            </div> */}

          <div className="container client-container-1">
            {/* <p>{I18n.get("Este cliente criou um projeto e você está convidado a participar da seleção. Verifique as informações e nos diga se aceita ou não participar deste projeto.")}</p> */}

            <ProjectDetails
              {...this.props}
              isInvite={true}
              statusInvite={invitation?.status}
              inviteRates={inviteRates && inviteRates[0]}
            />

            <Row className="checkInvite">
              <Col sm={12}>
                {invitation.accepted === true && (
                  <>
                    <p>
                      <i className="fas fa-check"></i>{" "}
                      {I18n.get("Você aceitou esse convite.")}
                    </p>

                    {/* <div>

                                        <p style={{ fontWeight: 'bold' }}>
                                            {I18n.get('Agora que você aceitou o convite, já pode começar a trabalhar na proposta!')}
                                        </p>
                                        <p style={{ marginTop: '0.50rem', fontSize: '90%' }}>
                                            {I18n.get('Clique abaixo se quiser enviar perguntas ao cliente ou começe a estruturar suas proposta agora mesmo.')}
                                        </p>

                                    </div> */}
                  </>
                )}

                {invitation.accepted === false && (
                  <p>
                    <i className="fas fa-ban"></i>{" "}
                    {I18n.get("Você recusou esse convite.")}
                  </p>
                )}

                <p className="has-text-danger" style={{ fontSize: "90%" }}>
                  <ul>
                    {Object.keys(this.state.errors).map((item) => {
                      return <li>{this.state.errors[item].errorMessage}</li>;
                    })}
                  </ul>
                </p>
              </Col>
            </Row>

            {invitation.actionRequired === true && (
              <div>
                {invitation.requiresNonCompete && (
                  <Row className="checkInvite">
                    <Col sm={12}>
                      <CheckboxField
                        {...this.state.form.isNonCompeteAware}
                        label={nonCompeteMessage}
                      />
                    </Col>
                  </Row>
                )}

                {this.props.usersOperationSelect && (
                  <Row>
                    <Col sm={12}>
                      <div className="card-row">
                        <div className="card">
                          <div className="card-content">
                            <p>{I18n.get("Usuário Responsável:")}</p>
                            <Select
                              defaultValue={
                                userOpactive
                                  ? userOpactive.username
                                  : this.props.usersOperationSelect[0].username
                              }
                              onChange={(e) => this.handleSelectUser(e)}
                            >
                              {this.props.usersOperationSelect &&
                                this.props.usersOperationSelect.map(
                                  (item, index) => {
                                    return (
                                      <Option
                                        key={item.username}
                                        value={item.username}
                                      >
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                            </Select>

                            {/* <p className="errop">

                                                    </p> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col sm={12}>
                    <br />
                    <br />
                    <div className="field is-grouped">
                      <p className="control">
                        <NavLink
                          to={`/parceiro/projetos/${this.props.match.params.projectId}/declinar-convite`}
                          className="button bt-bpool grey"
                        >
                          {I18n.get("Não aceito")}
                        </NavLink>
                      </p>
                      <p className="control">
                        <SubmitButtonField
                          className="button bt-bpool black"
                          label={I18n.get("Aceito participar da seleção")}
                          onClick={this.onSubmitForm.bind(this, true)}
                          task={this.state.task}
                          suppressLabel={true}
                        />
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            {invitation.canWithdraw &&
              invitation.status != 9 &&
              invitation.status != 4 && (
                <div style={{ marginTop: "2rem" }}>
                  <p>
                    <i className="fas fa-info-circle"></i>{" "}
                    <strong>
                      {I18n.get("Prazo para escolha do Parceiro expirou")}
                    </strong>
                    . <br />{" "}
                    <span style={{ fontSize: "90%" }}>
                      {I18n.get(
                        "A partir de agora você tem a opção de se retirar do processo se quiser."
                      )}
                    </span>
                  </p>

                  <br />
                  <br />

                  <div className="field is-grouped">
                    <p className="control">
                      <SubmitButtonField
                        className="button bt-bpool black"
                        label={I18n.get("Quero me retirar da seleção")}
                        onClick={this.onWithdraw.bind(this)}
                        task={this.state.withdraw_task}
                        suppressLabel={true}
                      />
                    </p>
                  </div>
                </div>
              )}

            {invitation.withdrawn === true && (
              <div style={{ marginTop: "3rem" }}>
                <p>
                  <i className="fas fa-info-circle"></i>{" "}
                  <strong>
                    {I18n.get("Você se retirou dessa seleção em")}{" "}
                    {invitation.withdrawnOn}
                  </strong>
                  .
                </p>
              </div>
            )}

            <Row>
              <Col sm={12}>
                {invitation.actionRequired === false && (
                  <div style={{ marginTop: "2rem" }}>
                    <div className="field is-grouped">
                      <p className="control">
                        <NavLink
                          to={`/parceiro/projetos`}
                          className="button bt-bpool black"
                        >
                          {I18n.get("Voltar")}
                        </NavLink>
                      </p>
                      <p className="control"></p>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
        {this.props.project.indemnityInvite && (
          <ModalPdf
            pdf={this.props.project.indemnityInvite}
            visible={this.props.usersOperationSelect && !hasConfirmedAlready}
          >
            <FormTerms confirm={this.handleConfirm} />
          </ModalPdf>
        )}
        <br />
        <br />
        <br />
        <br />
      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInviteAdmin);
