import styled from "styled-components";

export const ItemButton = styled.a`
  width: 100%;
  display: block;
  border: solid 4px #fff;
  border-radius: 15px;
  background: #eeeeee;
  text-align: center;
  padding: 30px 10px;
  min-height: 157px;
  color: #434343;
  font-size: 20px;
  font-weight: bold;
  font-family: "Helvetica Neue LT Std";
  transition: all 0.5s ease;
  margin-bottom: 20px;
  position: relative;

  &[disabled] {
    pointer-events: painted;
    cursor: not-allowed;
  }

  .Info {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &.pitch {
    img {
      width: 87px;
    }
  }

  &.live {
    img {
      width: 80px;
    }
  }

  img {
    margin-right: 20px;
    width: 60px;
  }

  &:hover {
    color: #434343;
    border: solid 4px #eee;
  }

  &.is-selected {
    border: solid 4px #1883ff;
  }

  @media screen and (max-width: 940px) {
    min-height: 80px;
    img {
      width: 35px;
    }

    &.pitch {
      img {
        width: 40px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    img {
      margin: 0 0 10px;
    }

    span {
      display: block;
    }
  }
`;
