import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import LoggedPage from '../page-templates/logged';

import { PARTNER_API } from '../../actions/partner/actions';

import Menu from './_menu';
import FormCountries from '../../modules/Register/components/formCountries';

import TableCountries from '../../modules/Register/components/tableCountries';
import TableCountriesEx from '../../modules/Register/components/tableCountriesEx';


import styled from 'styled-components';

import { TitleBorder, TitleMiddleLine } from '../../modules/components/Titles';
import { CardSimple } from '../../modules/components/Cards';


import {
    Row,
    Col,
    Input,
    Select,
    Tag,
    Icon,
    Tooltip,
    notification,
    Button,
    Drawer,
    Form
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;

const RowSpace = styled(Row)`
    margin: 20px 0;
`
const ContentAba = styled.div`
    .BtnAction {
        margin-bottom: 20px;
        text-align: right;
    }
`

const BoxAba = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 30px 20px;
    background: #ececec;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .4s ease;
    border: solid 4px #ececec;
    position: relative;
    .ant-tag {
        position: absolute;
        right: -15px;
        top: -10px;
        font-weight: bold;
    }

    p  {
        cursor: pointer;
    }

    &:hover, &.active {
        background: #dcdcdc;
        border-color: #777;
    }

    .icone {
        margin-bottom: 10px;
        font-size: 40px;
    }
`

const RowAba = styled(Row)`
    margin: 50px 0;
`
const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get: () => {
            return dispatch(PARTNER_API.get());
        },
        addCountry: data => {
            return dispatch(PARTNER_API.create_workabroad(data));
        },
        deleteCountry: data => {
            return dispatch(PARTNER_API.delete_workabroad(data));
        },
        addCompanyCountry: data => {
            return dispatch(PARTNER_API.create_companyabroad(data));
        },
        deleteCompanyCountry: data => {
            return dispatch(PARTNER_API.delete_companyabroad(data));
        },
    };
};

class CountriesServedAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddCountry: false,
            exterior: false
        }
    }

    showAddCountry = () => {
        this.setState({
            showAddCountry: true
        });
    }

    closeDrawer = () => {
        this.setState({
            showAddCountry: false,
            exterior: false
        });
    }

    handleAction = (data) => {
        
        if(this.state.exterior){
            this.props.addCompanyCountry(data).then(response => {
                if(response) {
                    this.closeDrawer();
                    this.props.get();
                    notification.success({
                        message: I18n.get('Adicionado com sucesso!'),
                        description: 'O país foi adicionado ao seu cadastro',
                    });
                } else {
                    notification.error({
                        message: 'Ops! Algo deu errado',
                        description: 'Tente novamente mais tarde',
                    });
                }
            });
        } else {
            this.props.addCountry(data)
        }
    }

    handleDelete = (data, ex) => {
        
        if(ex){
            this.props.deleteCompanyCountry(data).then(response => {
                if(response) {
                    this.props.get();

                    notification.success({
                        message: I18n.get('Deletado com sucesso!'),
                        description: 'O país foi removido ao seu cadastro',
                    });
                } else {
                    notification.error({
                        message: 'Ops! Algo deu errado',
                        description: 'Tente novamente mais tarde',
                    });
                }
            });
        } else {
            this.props.deleteCountry(data)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;


        if (this.props.partner.workAbroad && prevProps.partner.workAbroad && this.props.partner.workAbroad.length > prevProps.partner.workAbroad.length) {

            notification.success({
                message: I18n.get('Adicionado com sucesso!'),
                description: 'O país foi adicionado ao seu cadastro',
            });

            this.closeDrawer()
        }

        if (this.props.partner.workAbroad && prevProps.partner.workAbroad && this.props.partner.workAbroad.length < prevProps.partner.workAbroad.length) {

            notification.success({
                message: I18n.get('Deletado com sucesso!'),
                description: 'O país foi removido ao seu cadastro',
            });

            this.closeDrawer()
        }

    }
    render() {
        const { partner } = this.props;

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><Link to="/home">{I18n.get("Admin")}</Link></li>
                        <li className="is-active"><Link to="/cadastro">{I18n.get("Cadastro")}</Link></li>
                    </ul>
                </nav>

                <div className="columns company-admin">

                    <div className="column is-3">
                        <Menu {...this.props} />
                    </div>

                    <div className="column is-9">
                        <Row>

                            <Col sm={24}>
                                <TitleBorder>
                                    {I18n.get("Atendimento de clientes fora do seu país")}{" "}
                                    <Tooltip title={I18n.get("Esta área é destinada pra quem já tem experiência em atender clientes fora de seu país")}>
                                        <Icon type="question-circle" style={{ fontSize: "18px" }} theme="filled" />
                                    </Tooltip>
                                </TitleBorder>
                            </Col>
                        </Row>

                        <CardSimple className="auto">
                            <Row>
                                <Col sm={24}>
                                    <Button
                                        className="button bt-bpool pull-right"
                                        onClick={() => this.setState({ showAddCountry: true })}
                                    >
                                        {I18n.get("Adicionar país")}
                                    </Button>
                                </Col>
                                <Col sm={24}>
                                    <br />
                                    <TableCountries
                                        data={this.props.partner.workAbroad && this.props.partner.workAbroad}
                                        partnerId={this.props.partner && this.props.partner.partnerId}
                                        partnerWorkAbroad={this.props.bp.partnerWorkAbroad && this.props.bp.partnerWorkAbroad}
                                        delete={this.handleDelete}
                                    />
                                </Col>
                            </Row>
                        </CardSimple>

                        <br />
                        <br />
                        <br />
                        <Row>

                            <Col sm={24}>
                                <TitleBorder>
                                    {I18n.get("Possui empresa no exterior")}{" "}
                                    <Tooltip title={I18n.get("Se possui empresas para faturar no exterior, favor informar os países onde estão.")}>
                                        <Icon type="question-circle" style={{ fontSize: "18px" }} theme="filled" />
                                    </Tooltip>
                                </TitleBorder>
                            </Col>
                        </Row>

                        <CardSimple className="auto">
                            <Row>
                                <Col sm={24}>
                                    <Button
                                        className="button bt-bpool pull-right"
                                        onClick={() => this.setState({ exterior: true, showAddCountry: true })}
                                    >
                                        {I18n.get("Adicionar país")}
                                    </Button>
                                </Col>
                                <Col sm={24}>
                                    <br />
                                    <TableCountriesEx
                                        data={this.props.partner.companyAbroad && this.props.partner.companyAbroad}
                                        partnerId={this.props.partner && this.props.partner.partnerId}
                                        delete={this.handleDelete}
                                    />
                                </Col>
                            </Row>
                        </CardSimple>
                    </div>

                </div>

                <Drawer
                    title={I18n.get("Adicionar país")}
                    placement="right"
                    closable={true}
                    onClose={() => this.closeDrawer()}
                    destroyOnClose={true}
                    visible={this.state.showAddCountry}
                    width="40%"
                >
                    <FormCountries
                        countryCodes={this.props.bp.countryCodes && this.props.bp.countryCodes}
                        partnerWorkAbroad={this.props.bp.partnerWorkAbroad && this.props.bp.partnerWorkAbroad}
                        action={this.handleAction}
                        cancel={this.closeDrawer}
                        update={this.props.updateAward}
                        partnerId={this.props.partner.partnerId}
                        item={this.state.editAwardItem}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        type={this.state.type}
                        forceRender={true}
                        exterior={this.state.exterior}
                    />
                </Drawer>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CountriesServedAdmin);
