
import React from 'react';
import { I18n } from 'aws-amplify';

import {
    Row,
    Col,
    Form,
    notification,
    Input,
    DatePicker,
    Button
} from 'antd';

import locale from 'antd/es/date-picker/locale/pt_BR';

import { CardBorder } from '../../modules/components/Cards';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

class PartnerContactForm extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    createPartnerContact = e => {

        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {

            }

            this.setState({
                formState: values,
            })

            this.props.onCreate(values);
        });
    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        const fieldNames = ['companyName', 'name', 'email', 'phoneNumber' ];

        if (this.props.errors != prevProps.errors && this.props.errors) {
            Object.keys(this.props.errors).map(field => {

                if (fieldNames.indexOf(field) > -1) {
                    this.props.form.setFields({
                        [field]: {
                            value: this.state.formState[field],
                            errors: [new Error(this.props.errors[field].errorMessage)],
                        },
                    });
                }
            });
        }
    }

    render() {

        const { partnerContacts = [], disabled = false } = this.props;

        const { getFieldDecorator } = this.props.form;

        return (
            <CardBorder>

                <h1 className="title-bpool medium">{I18n.get("Adicionar Parceiro")}</h1>

                <div className="form">
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col>
                                <Form.Item label={I18n.get("Nome da Empresa")}>
                                    {getFieldDecorator('companyName', {
                                        rules: [{ required: true, message: I18n.get('Por favor informe o Nome da Empresa.') }],
                                    })(
                                        <Input placeholder={I18n.get("Nome da Empresa")} disabled={disabled} />,
                                    )}
                                </Form.Item>
                                <Form.Item label={I18n.get("Nome do Contato")}>
                                    {getFieldDecorator('name', {
                                        rules: [{ required: true, message: I18n.get('Por favor informe o Nome do contato.') }],
                                    })(
                                        <Input placeholder={I18n.get("Nome do Contato")} disabled={disabled} />,
                                    )}
                                </Form.Item>
                                <Form.Item label={I18n.get("E-mail do Contato")}>
                                    {getFieldDecorator('email', {
                                        rules: [{ required: true, message: I18n.get('Por favor informe o E-mail do contato.') }],
                                    })(
                                        <Input placeholder={I18n.get("E-mail do Contato")} disabled={disabled} />,
                                    )}
                                </Form.Item>
                                <Form.Item label={I18n.get("Telefone do Contato")}>
                                    {getFieldDecorator('phoneNumber', {
                                        rules: [{ required: true, message: I18n.get('Por favor informe o Telefone do contato.') }],
                                    })(
                                        <Input placeholder={I18n.get("Telefone do Contato")} disabled={disabled} />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form.Item className="labelClean" label="&nbsp;">
                                    <Button disabled={disabled} type="primary" onClick={this.createPartnerContact.bind(this)}>{I18n.get("Adicionar")}</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </CardBorder>
        );
    }
}

export default Form.create({})(PartnerContactForm);
