import React from 'react';

import { I18n } from 'aws-amplify';

import NumberFormat from 'react-number-format';

import ReviewStep1 from './_project-review-1';
import ReviewStep2 from './_project-review-2';
import ReviewStep3 from './_project-review-3';

class Review extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {

        return (
            <div className="project-review project-review-bp">
                <h1 className="title">{I18n.get("Review do Projeto")}</h1>

                <br /><br />

                <ReviewStep1 {...this.props} />

                <br /><br />

                <ReviewStep2 {...this.props} />

                <br /><br />

                {this.props.step > 3 && <ReviewStep3 {...this.props} />}

                <br /><br />

            </div>
        );
    }
}

export default Review;
