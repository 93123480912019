import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col, message, Select, Alert } from "antd";
import { Loggedv2 } from "../components/Loggedv2";
import Main from "../../../components/UI/Main";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import { Input } from "../../../components/UI/Input";
import { inputsState } from "./inputsState";
import { SelectOne } from "../../../components/UI/SelectOne";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";
import { ItemReferrers } from "./itemReferrers";
import { useRegistrationFlow } from "../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook";

import { urls } from "../utils/stepsUrls";
import {
  updateFlowStorage,
  toUpdateStatusInStep,
} from "../utils/flowToStorage";
import { statusIdToName } from "../components/Loggedv2/statusIdToName";

import * as S from "./styles";

const MESSAGE_ERROR_GET_PAGE = "There was an error, try again.";
const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const PAGE_INDEX = 6;

const { Option } = Select;

export const SpecialityProof = () => {
  const {
    getStatusBarPre,
    getSpecialityProofGet,
    createReferral,
    getListReferrals,
    deleteReferre,
    updateReferral,
    checkIsCompleted,
    finishPreRegister,
    setSpecialityProofType,
    getSpecialityProofType,
  } = useRegistrationFlow();
  let history = useHistory();

  const [inputsFormInitals, setInputsFormInitals] = useState(inputsState);
  const [inputsForm, setInputsForm] = useState(inputsState);
  const [itemReference, setItemReference] = useState(inputsState);
  const [sizesType, setSizesType] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [codeType, setCodeType] = useState(0);
  const [editId, setEditId] = useState(null);
  const [visibleModalEdit, setVisibleModalEdit] = useState(false);

  const [isLoadPage, setIsLoadPage] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState(null);
  const [errorsFinish, setErrorsFinish] = useState(null);
  const [errorEmail, setErrorEmail] = useState(false);
  const [clientProfileType, setClientProfileType] = useState(null);

  const loadInit = async () => {
    setIsLoadPage(true);

    // const response = await getSpecialityProofAndEndersement();

    // if (response.success) {
    //   setDataPage(response.data);
    // } else {
    //   message.error(I18n.get(MESSAGE_ERROR_GET_PAGE));
    // }

    setIsLoadPage(false);
  };

  const setType = async (id) => {
    const data = {
      clientProfileType: parseInt(id),
    };

    setClientProfileType(parseInt(id));

    const response = await setSpecialityProofType(data);
  };

  const loadSetType = async () => {
    const response = await getSpecialityProofType();
    setClientProfileType(response.data.clientProfileType);
  };

  const checkCompleted = async () => {
    const response = await checkIsCompleted();

    if (response) {
      setIsCompleted(response);
    }
  };

  const loadTypes = async () => {
    const response = await getSpecialityProofGet();
    setSizesType(response);
  };

  const loadList = async () => {
    setListLoading(true);
    const response = await getListReferrals();

    if (response?.success) {
      setReferrals(response?.data);
      setListLoading(false);
    } else {
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
    setErrors(null);
  };

  const onBlurInputForm = (id, value) => {
    if (validateEmail(value)) {
      setErrorEmail(null);
    } else {
      const error = {
        [id]: {
          errorMessage: I18n.get("E-mail inválido"),
        },
      };
      setErrors(error);
      setErrorEmail(true);
    }
  };

  const send = async () => {
    await updateFlowStorage({ screen: PAGE_INDEX });
  };

  const handleSendReferral = async () => {
    setListLoading(true);
    const response = await createReferral(inputsForm);

    if (response?.success) {
      loadList();
      setInputsForm(inputsState);
      setErrors(null);
      setErrorEmail(null);
      checkCompleted();
    } else {
      setErrors(response?.errors);
      setListLoading(false);
    }
  };

  const handleOpenModal = async (reference) => {
    const item = {
      culture: reference?.culture,
      name: reference?.name,
      email: reference?.email,
    };
    setItemReference(item);
    setEditId(reference?.id);
    setVisibleModalEdit(true);
  };

  const handleEdite = async (reference) => {
    const scrolled = document.getElementById("referrals");

    const topPos = scrolled.offsetTop;

    window.scrollTo({
      top: topPos - 260,
      behavior: "smooth",
    });

    const item = {
      id: reference.id,
      culture: reference?.culture,
      name: reference?.name,
      email: reference?.email,
    };

    setIsEdit(true);
    setInputsForm(item);
  };

  const handleUpdateReferral = async () => {
    const data = {
      ...inputsForm,
    };
    setListLoading(true);
    const response = await updateReferral(data);

    if (response?.success) {
      loadList();
      setIsEdit(false);
      setErrors(null);
      setInputsForm(inputsState);
      checkCompleted();
    } else {
      setErrors(response?.errors);
      setListLoading(false);
    }
  };

  const handleDelete = async () => {
    const data = {
      id: editId,
    };
    const response = await deleteReferre(data);

    if (response?.success) {
      loadList();

      setEditId(null);
      setVisibleModalEdit(false);
    } else {
    }
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarPre();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const handleFinish = async () => {
    setLoadingFinish(true);

    const response = await finishPreRegister();
    await newGetStepbar();

    if (response.success) {
      history.push("/references-partner/list");

      setLoadingFinish(false);
    } else {
      setLoadingFinish(false);
      setErrorsFinish(response.errors);
    }
  };

  useEffect(() => {
    getListReferrals();
    loadTypes();
    loadInit();
    loadList();
    checkCompleted();
    loadSetType();
  }, []);

  const validNull = Object.keys(inputsForm).filter(
    (i) => inputsForm[i] == null
  );

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const languages = [
    { code: "pt", label: I18n.get("Português") },
    { code: "en", label: I18n.get("Inglês") },
    { code: "es", label: I18n.get("Espanhol") },
  ];

  return (
    <Loggedv2
      pageIndex={PAGE_INDEX}
      statusBarStepsClickable
      statusChangeInfo={changeInfo}
      hookGetItems={getStatusBarPre}
      stepbarId="stepbar-pre"
      urls={urls}
      contextStepBar="pre"
    >
      <Main bgColor="#fff" padding="30px">
        {/* <ScrollUpDown /> */}

        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("Speciality proof")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  "Tell us about your typical client profile and give us some referral contacts"
                )}
              </S.Paragraph>
            </S.RowCenter>

            <S.RowCenter className="box-client">
              <S.H5>
                <strong>{I18n.get("Client Profile")}</strong>
              </S.H5>

              <S.Paragraph>
                {I18n.get(
                  "What type of client represents 50% of your revenue?"
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <S.WrapperTypes>
              {sizesType &&
                sizesType.map((item) => {
                  const active = clientProfileType == item.code;
                  return (
                    <div ket={item.code}>
                      <S.ButtonType
                        active={active}
                        onClick={() => setType(item.code)}
                      >
                        <S.LabelButton active={active}>
                          {item.label}
                        </S.LabelButton>
                      </S.ButtonType>
                    </div>
                  );
                })}
            </S.WrapperTypes>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24}>
            <S.RowCenter className="box-client">
              <S.H5>
                <strong>{I18n.get("Referrals")}</strong>
              </S.H5>

              <S.Paragraph>
                <span>
                  {I18n.get(
                    "Add at least 3 current or former clients that can vouch for your company."
                  )}
                </span>
                <br />
                <span>
                  {I18n.get(
                    "BPool will send them an e-mail asking to rate your companyin some evaluation criteria."
                  )}
                </span>
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        <Row id="referrals">
          <Col xs={24}>
            <S.TitleSectionForm>
              <span>*</span>
              {I18n.get("Referrals")}
            </S.TitleSectionForm>
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Referrer name")}
              label
              required
              value={inputsForm.name}
              onChange={(value) => onChangeInputForm("name", value)}
              error={errors && errors?.Name?.errorMessage}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Referrer email")}
              label
              required
              value={inputsForm.email}
              onChange={(value) => onChangeInputForm("email", value)}
              onBlur={(value) => onBlurInputForm("email", value)}
              error={
                (errors && errors?.EmailAlready?.errorMessage) ||
                errors?.Email?.errorMessage
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <S.SelectBox>
              <SelectOne
                options={languages}
                value={inputsForm.culture}
                onChange={(value) => onChangeInputForm("culture", value)}
                required
                placeholder={I18n.get("Idioma")}
                error={errors && errors?.Culture?.errorMessage}
              />
            </S.SelectBox>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}></Col>
          <Col xs={24} sm={12}>
            <S.Btns>
              {!isEdit && (
                <ButtonBpool
                  text={I18n.get("Save")}
                  theme="primary"
                  loading={listLoading}
                  onClick={() => handleSendReferral()}
                //disabled={validNull.length != 0 || errorEmail}
                />
              )}

              {isEdit && (
                <>
                  <ButtonBpool
                    text={I18n.get("Cancel")}
                    theme="secundary"
                    // full
                    loading={listLoading}
                    onClick={() => {
                      setIsEdit(false);
                      setInputsForm(inputsState);
                      setErrors(null);
                    }}
                  />
                  <ButtonBpool
                    text={I18n.get("Save")}
                    theme="primary"
                    loading={listLoading}
                    //disabled={validNull.length != 0}
                    onClick={() => handleUpdateReferral()}
                  />
                </>
              )}
            </S.Btns>
          </Col>
        </Row>

        <Row style={{ marginTop: 50, marginBottom: 50 }}>
          <Col xs={24} sm={24}>
            <ItemReferrers
              data={referrals}
              languages={languages}
              onDelete={(item) => handleOpenModal(item)}
              onEdit={(item) => handleEdite(item)}
            />
          </Col>
        </Row>

        {errorsFinish && (
          <Row>
            <Col lg={24}>
              {Object.keys(errorsFinish).map((item, index) => {
                return (
                  <Alert
                    message={errorsFinish[item].errorMessage}
                    type="error"
                    showIcon
                    style={{ marginBottom: 10 }}
                  />
                );
              })}
            </Col>
          </Row>
        )}

        <Row style={{ marginTop: 90 }}>
          <Col xs={24} sm={12}>
            <ButtonBpool
              text={I18n.get("Back")}
              theme="secondary"
              full
              onClick={handleBackPage}
            />
          </Col>
          <Col xs={24} sm={12}>
            <ButtonBpool
              text={I18n.get("Finish")}
              theme="primary"
              // disabled={!isCompleted}
              full
              loading={loadingFinish}
              onClick={handleFinish}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={visibleModalEdit}
        bodyText={`${I18n.get("Would you like to delete the")}: ${itemReference.email
          } ${I18n.get("reference?")}`}
        // subBodyText={I18n.get("Deseja apagar a referência?")}
        okText={I18n.get("Delete")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={() => handleDelete()}
        onCancel={() => {
          setEditId(null);
          setVisibleModalEdit(false);
        }}
        typeModal="isConfirm"
      // isDanger={true}
      />
    </Loggedv2>
  );
};
