import styled from "styled-components";
import { Drawer as DrawerAntd } from "antd";

export const Wrapper = styled.div`
  background-color: #f8f8f8;
  position: relative;
  min-height: 100vh;
  display: grid;
  grid-template-areas: "header" "content" "footer";
  grid-template-rows: 60px calc(100% - 160px) 100px;
`;

export const Header = styled.div`
  grid-area: header;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: #000000;
`;

export const HeaderContent = styled.div`
  padding: 0 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    max-height: 30px;
  }
`;

export const ButtonHeader = styled.button`
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  border: none;

  span {
    color: #ffffff;
  }

  img {
    width: 43px;
    height: 43px;
    max-height: 43px;
  }
`;

export const Drawer = styled(DrawerAntd)`
  .ant-drawer-header {
    padding: 50px 60px 50px 60px;
    border: none;

    .ant-drawer-title {
      height: 33px;

      a {
        cursor: pointer;
      }

      img {
        height: 100%;
      }
    }

    .ant-drawer-close {
      top: 40px;
      right: 30px;
    }
  }
  .ant-drawer-body {
    padding: 0 60px 50px 60px;
  }
`;

export const Menu = styled.ul`
  li {
    a,
    button {
      font-size: 18px;
      line-height: 24px;
      font-family: "HelveticaNeue", sans-serif;
      color: #000000;
      margin-bottom: 18px;

      img {
        margin-right: 10px;
      }
    }

    button {
      cursor: pointer;
      border: none;
      width: 100%;
      background-color: #ffffff;

      :hover,
      :active {
        background-color: #fafafa;
        color: #0a0a0a;
      }
    }
  }
`;

export const Content = styled.div`
  grid-area: content;
`;

export const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

export const ContentFooter = styled.div`
  position: relative;
  bottom: 0;
  background-color: #000000;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;

  span {
    color: #ffffff;
    font-weight: 600;
    padding: 0px 20px;
    margin: 0px;
  }
`;
