import React from "react";

import { I18n } from "aws-amplify";

import NumberFormat from "react-number-format";

import TooltipField from "./tooltip-field";

import MarkAsRequiredOrOptional from "./mark-as-required-or-optional";

export default class MoneyField extends React.Component {
  constructor(props) {
    super();

    this.state = {
      focused: false,
      visited: props.visited,
    };
  }

  static defaultProps = {};

  onBlur() {
    this.setState({ focused: false });

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  onFocus() {
    this.setState({ focused: true, visited: true });
  }

  onChange(e) {
    this.props.onChange(e);
  }

  render() {
    const className = this.props.className || "field";
    const inputClassName = this.props.inputClassName || "input has-text-right";
    const { currency = {} } = this.props;

    const { value = "" } = this.props;

    const isError =
      !this.state.focused && this.state.visited && this.props.error;

    const currencySymbol = (currency && currency.symbolOnly) || "R$";

    return (
      <div className={className} style={this.props.style}>
        {this.props.label && (
          <label className="label is-small">
            {this.props.label} <MarkAsRequiredOrOptional {...this.props} />{" "}
            <TooltipField {...this.props} />
          </label>
        )}
        <div className="control has-icons-left">
          {/* Sobrepoe o zIndex do bulma para icones que acaba gerando problemas de sobreposcao */}
          <span
            className="icon is-small is-left has-text-dark"
            style={{ zIndex: "1" }}
          >
            {currencySymbol}
          </span>
          <NumberFormat
            value={value}
            thousandSeparator="."
            decimalSeparator=","
            prefix={""}
            decimalScale={2}
            fixedDecimalScale={true}
            onChange={(e) => this.onChange(e)}
            onBlur={() => this.onBlur()}
            onFocus={() => this.onFocus()}
            className={isError ? inputClassName + " is-danger" : inputClassName}
            readOnly={this.props.readOnly || false}
            disabled={this.props.disabled || false}
          />
        </div>
        {isError && (
          <p className="help is-danger">{this.props.error.errorMessage}</p>
        )}
      </div>
    );
  }
}
