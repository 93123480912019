import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { Task } from '../../utils/task';

import { CLIENT_USER_API } from '../../actions/client-user/actions';

import TextField from '../form/text-field';
import MaskedField from '../form/masked-field';
import PasswordField from '../form/password-field';
import SubmitButtonField from '../form/submit-button-field';

const mapStateToProps = state => {
    return state.client_user;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(CLIENT_USER_API.update(data));
        }
    };
};

class PersonForm extends React.Component {
    constructor(props) {

        super();

        this.state = {
            form: {
                token: props.verificationToken,
                name: {
                    label: I18n.get("Nome Completo"),
                    value: props.name,
                    onChange: this.onChangeForm.bind(this, 'name'),
                    error: props.errors.name,
                    successIcon: true,
                    visited: true
                },
                email: {
                    label: I18n.get("E-mail"),
                    value: props.email,
                    onChange: this.onChangeForm.bind(this, 'email'),
                    error: props.errors.email,
                    successIcon: true,
                    readOnly: true,
                    visited: true
                },
                linkedin: {
                    label: I18n.get("Linkedin Pessoal"),
                    placeholder: I18n.get("https://"),
                    value: props.linkedin,
                    onChange: this.onChangeForm.bind(this, 'linkedin'),
                    error: props.errors.linkedin,
                    visited: true
                },
                phoneNumber: {
                    label: I18n.get("Celular (DDD)"),
                    value: props.phoneNumber,
                    onChange: this.onChangeForm.bind(this, 'phoneNumber'),
                    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                    error: props.errors.phoneNumber,
                    successIcon: true,
                    visited: true
                },
                password: {
                    label: I18n.get("Senha"),
                    value: props.password,
                    onChange: this.onChangeForm.bind(this, 'password'),
                    error: props.errors.password,
                    successIcon: false,
                    visited: true
                },
                password2: {
                    label: I18n.get("Confirmação senha"),
                    value: props.password2,
                    onChange: this.onChangeForm.bind(this, 'password2'),
                    error: props.errors.password2,
                    successIcon: false,
                    visited: true
                },
                nameAlias: {
                    label: I18n.get("Como você quer ser chamado?"),
                    value: props.nameAlias,
                    onChange: this.onChangeForm.bind(this, 'nameAlias'),
                    error: props.errors.nameAlias,
                    successIcon: true,
                    visited: true
                },
                isSubmit: !props.isAdmin,
            },
            task: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        //console.dir({ task: task, prev: prev });

        if (task != prev) {

            var nextState = Object.assign({}, prevState, { task: task });

            Object.keys(this.state.form).forEach((item) => {

                if (typeof nextState.form[item] === "object") {
                    nextState.form[item].error = this.props.errors[item];
                }

            });

            this.setState(nextState);
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop].value = value;

            return nextState;
        });
    }

    getData() {

        var data = {};

        Object.keys(this.state.form).forEach((item) => {

            if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
                data[item] = this.state.form[item].value
            }
            else {
                data[item] = this.state.form[item];
            }
        });

        return data;
    }

    onSave(item) {



        if (this.props.isAdmin) return;

        // zera o erro
        if (item && typeof this.state.form[item] === "object") {
            this.state.form[item].error = null;
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }


    submitForm(e) {

        e.preventDefault();

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.save(this.getData());
        });
    }

    render() {

        return (
            <form onSubmit={this.submitForm.bind(this)} className="form-mobile">

                <TextField {...this.state.form.name} />

                <TextField {...this.state.form.nameAlias} />

                <TextField {...this.state.form.email} />

                <MaskedField {...this.state.form.phoneNumber} />

                <TextField {...this.state.form.linkedin} />

                {!this.props.isAdmin && <div className="field is-horizontal">
                    <div className="field-body">

                        <PasswordField {...this.state.form.password} />

                        <PasswordField {...this.state.form.password2} />

                    </div>
                </div>}


                {this.props.isAdmin && <div className="control">
                    <SubmitButtonField
                        label={I18n.get("Salvar")}
                        onClick={this.submitForm.bind(this)}
                        task={this.state.task}
                    />
                </div>}

            </form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PersonForm);
