import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import { Tooltip } from "antd";

export const StyleMain = styled.div`
  max-width: 630px;
  margin: 50px auto 0;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  a,
  .next-bt {
    margin-left: 10px;
  }
`;

export const FiltersHead = styled.div`
  margin-top: 50px;
  padding-bottom: 15px;
`;

export const Separator = styled.hr`
  height: 1px;
  border-bottom: 1px solid;
  margin: 20px 0 0 0;
  padding: 0;
`;

export const ContainerSearchProjects = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerSearchInput = styled.div`
  display: flex;
  width: 345px;
  height: 52px;
  align-items: center;

  input {
    width: calc(100% - 40px);
    height: 52px;
    border: none;
    background: #e7e7e7;
    border-radius: 26px 0 0 26px;
    padding: 0 20px;
  }

  div {
    width: 40px;
    background: #e7e7e7;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 0 26px 26px 0;
  }
`;

export const Wrapper = styled.div``;

export const Title = styled.div`
  span {
    font-weight: bold;
    font-size: ${(props) => (props.windowWidth <= 1024 ? "16px;" : "24px;")};
    line-height: ${(props) => (props.windowWidth <= 1024 ? "22px;" : "33px;")};
    color: #000000;
  }

  hr {
    margin: ${(props) =>
      props.windowWidth <= 1024 ? "5px 0 0 0;" : "15px 0 0 0;"};
    width: ${(props) => (props.windowWidth <= 1024 ? "50px;" : "75px;")};
    border-bottom: ${(props) =>
      props.windowWidth <= 1024 ? "3px solid;" : "6px solid;"};
    border-color: #000000;
  }
`;

export const Cards = styled.div`
  display: flex;
  margin-top: 50px;
`;

const StyledCarousel = styled(Carousel)`
  margin: 0;

  .rec-slider-container {
    margin: 0 -10px;
  }

  .rec-slider {
    margin: 0;
  }

  .rec-item-wrapper {
    height: 100%;
    justify-content: flex-start;
  }

  .rec-dot {
    width: 22px;
    height: 22px;
    left: 0px;
    top: 0px;

    background: #c4c4c4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    :hover,
    :focus {
      box-shadow: 0 0 1px 3px #2f2f2f;
    }
  }

  .rec-dot_active {
    background: #2f2f2f;
  }

  .rec-pagination {
    margin-top: 30px;
  }
`;

export const Box = styled.div`
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #ccc;

  strong {
    font-size: 18px;
  }
`;

export const ContentDatePicker = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .ant-calendar-picker {
    width: 100%;
  }
`;

export const ContentSelectHours = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SelectClickButton = styled.button`
  border-radius: 20px;
  border: 0.5px solid;
  border-color: ${(props) => (props.active === true ? "#1883FF;" : "#000000;")};
  background: ${(props) =>
    props.active === true ? "#1883FF;" : "transparent;"};
  color: ${(props) => (props.active === true ? "#FFFFFF;" : "#000000;")};
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 10px 20px 0;
`;

export const ButtonSave = styled.button`
  width: 100%;
  font-size: 16px;
  padding: 16px 40px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  text-align: center;
  border: 1px solid;
  border-color: #1883ff;
  color: #fff;
  background: #1883ff;
  border-color: #1883ff;
  border-radius: 28px;

  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};

  span {
    margin-top: 3px;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 12px 35px;
  }
`;

export const ContentSelectProject = styled.div`
  width: 340px;
  margin: 10px 0;
`;

export const ContentSelectPartner = styled.div`
  width: 340px;
  margin: 10px 0 10px 10px;
`;

export const WrapperModal = styled.div`
  width: 500px;
  height: 170px;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyModal = styled.div`
  margin: 30px 0;

  span {
    font-size: 16px;
  }
`;

export const ActionsModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const ButtonYes = styled.div`
  width: 120px;
  border: none;
  border-radius: 10px;
  background: #32cd32;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
`;

export const ButtonNo = styled.div`
  width: 120px;
  border: none;
  border-radius: 10px;
  background: #ff0000;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
`;

export const ButtonCancel = styled.div`
  width: 120px;
  border: none;
  border-radius: 10px;
  background: #666666;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
`;

export const ButtonSaveEdit = styled.div`
  width: 120px;
  border: none;
  border-radius: 10px;
  background: #1883ff;
  color: #fff;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
`;

export const SpacingBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: start;
`;

export const TitleReview = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
`;

export const BoxReview = styled.div`
  border-radius: 10px;
  margin-top: 40px;
  padding: 25px 15px;
  border: 1px solid;
  border-color: #e7e7e7;

  ul {
    li {
      display: flex;
      align-items: baseline;
      margin: ${(props) =>
        props.spacing === "extra-large" ? "50px 0;" : "10px 0;"};

      :first-child {
        margin-top: 0;
      }

      :last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding-left: 5px;
      }
    }
  }
`;

export const RowValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const CartBox = styled.div`
  margin-top: 90px;
`;

export const Value = styled.div`
  color: #1883ff;
  font-weight: 600;
  font-size: 16px;
`;

export const H4 = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
`;

export const AddAsset = styled.div`
  margin-bottom: 40px;
  .ant-select-selection--single {
    height: 41px;
    border-radius: 30px;
  }

  .ant-select-selection__rendered {
    line-height: 38px;
  }

  .ant-select-arrow {
    top: 4px;
  }
`;

export const ModelsBoxes = styled.div`
  margin: 30px 0;

  ul {
    display: flex;

    li {
      padding: 10px 15px;
      border-radius: 5px;
      background-color: #1883ff;
      color: #ffffff;
      font-size: 14px;
      margin-right: 15px;

      :last-child {
        margin-right: 0;
      }
    }
  }
`;

export const ContentTooltip = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: end;
`;

export const StyleTooltip = styled(Tooltip)``;
export const Assets = styled.div`
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #e7e7e7;
  padding: 15px;
  background-color: #f2f2f2;

  p {
    font-weight: bold;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 14px;
      margin: 0 0 0 0;

      p {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }

      strong {
        font-weight: 600;
        font-size: 14px;
        color: #000000;

        span {
          font-weight: 600;
          font-size: 14px;
          color: #1883ff;
          margin-right: 15px;
        }

        a {
          margin-left: 20px;
        }
      }

      :first-child {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
`;
export const Important = styled.div`
  margin-top: 30px;

  span {
    font-weight: 600;
    padding-right: 5px;
  }
`;

export const StyledBoxAssets = styled.div`
  padding-right: 10px;
  border-right: solid 1px #000;
  min-height: 500px;

  .ant-pagination {
    margin-top: 40px;
    text-align: center;
  }
`;

export const StyledAssetsScroll = styled.div`
  border-top: 1px solid #434343;
  max-height: 500px;
  overflow: auto;
  margin-top: 30px;
  padding: 10px 0;
  padding-right: 30px;
`;

export const StyledTitleIcon = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;

  color: #000000;

  b {
    color: #1883ff;
  }
  span {
    padding: 5px 0 0 15px;
    display: inline-block;
    vertical-align: middle;
  }
`;

export const ButtonIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ContentIsLoading = styled.div``;

export { StyledCarousel as Carousel };
