import React from "react";
import { I18n } from "aws-amplify";
import { Link, Redirect } from "react-router-dom";

import { Task } from "../../utils/task";

import FillForm from "../page-templates/fillForm-logged";

import GlobalFileBox from "../../v2/pages/Client/CreateProject/components/GlobalFileBox";

import ProjectContainer from "../../containers/project-container";
import HeaderThumbCover from "../../modules/components/HeaderThumbCover";

import ReviewForm from "../shared/review-form";

import SubHeader from "./__sub-header";

import { ClientProjectStatus } from "../../model";

import SubmitButtonField from "../form/submit-button-field";

import { bPitchMenu } from "./bpitch/_menu";

import { eventMenuMap } from "./_event-menu";

import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";

class NewProjectStep5 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      task: {},
    };
  }

  componentDidMount() {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_project(data);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.project && this.props.project.task;

    if (!prevState) return;
    if (!task) return;

    var nextState = Object.assign({}, prevState);

    if (task.id != prevState.task.id) {
      nextState.task = task;

      this.setState(nextState);
    }
  }

  onSubmitForm(e) {
    e.preventDefault();

    const form = this.refs.form;
    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      form.submitForm(e);
    });
  }

  saveGlobal = async (data) => {
    const dataSend = {
      ...data,
      projectId: this.props.match.params.projectId,
    };

    const response = await this.props.update_global(dataSend);

    var data = {
      clientId: this.props.client.clientId,
      projectId: this.props.match.params.projectId,
    };

    await this.props.get_project(data);
  };

  render() {
    var errorMap = {};

    Object.keys(this.props.project.errors).map((item) => {
      const error = this.props.project.errors[item];

      const key = error.step || "global";

      if (!errorMap[key]) {
        errorMap[key] = [];
      }

      errorMap[key].push(error.errorMessage);
    });

    if (
      this.props.project &&
      this.props.project.status > ClientProjectStatus.Draft
    ) {
      return (
        <Redirect
          to={`/cliente/projetos/${this.props.match.params.projectId}/obrigado`}
        />
      );
    }

    // --- --- --- REPLACE WITH THE FINAL IMAGE URL FROM DB
    const cover = "/img/project-cover.svg";
    //const logo = '/img/project-avatar.svg';
    // --- --- --- END

    const logo = this.props.client?.company?.logo;
    const imgHeader = this.props.client?.company?.header;

    const {
      isCreative,
      isEvent,
      isBPitch,
      customEvent = {},
      bPitch = {},
      isCustomEvent,
    } = this.props;

    const subHeaderProps = {
      isCreative,
      isEvent,
      isBPitch,
      customEvent,
      bPitch,
      isCustomEvent,
    };

    let step = 0;

    if (this.props.isCustomEvent && customEvent.isDigital === true) {
      step = 4;
    } else if (this.props.isCustomEvent && customEvent.isDigital === false) {
      step = 5;
    } else {
      step = 6;
    }

    const errorMenuMap = {};

    if (isBPitch) {
      const bPitchSteps = bPitchMenu
        .map((item) => item.children)
        .reduce((acc, curr) => acc.concat(curr));

      bPitchSteps.map(
        (item) => (errorMenuMap[item] = bPitchSteps.indexOf(item))
      );
    }
    if (isCustomEvent) {
      const eventSteps = eventMenuMap[customEvent.briefingType] || [];

      eventSteps.map((item) => (errorMenuMap[item] = eventSteps.indexOf(item)));
    }

    const brandSelected = this.props.client?.brands?.find(
      (b) => b.id === this.props?.brandId
    );

    const productSelected = brandSelected?.products?.find(
      (p) => p.id === this.props.productId
    );

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-5 ">
          <SubHeader
            projectId={this.props.match.params.projectId}
            active={step}
            logo={logo}
            {...subHeaderProps}
          />
        </div>
        <div className="client-step client-step-1 client-step-np-review uplift full-review-bg full-review-bg">
          <div className="review-header">
            <HeaderThumbCover
              thumb={logo}
              bgHeader={imgHeader}
              title=""
              linkText={I18n.get("Voltar")}
              disabledThumb={true}
              disabledSubheader={true}
            />
          </div>
          <div className="container client-container-1 uplift-step-5 pagepreview">
            <ReviewForm ref="form" {...this.props} enableLinkButton={true} />

            <br />
            <br />

            {Object.keys(errorMap).length > 0 && (
              <div className="content is-small">
                <br />
                <br />

                <h4>{I18n.get("Falta mais um pouquinho...")}</h4>

                {Object.keys(errorMap).map((item) => {
                  return (
                    <p>
                      <span class="icon has-text-danger">
                        <i class="fas fa-exclamation"></i>
                      </span>
                      <Link
                        to={`/cliente/projetos/${this.props.match.params.projectId}/passo-${item}?s=2`}
                      >
                        {I18n.get("Passo")} {item}
                      </Link>
                      <ul>
                        {errorMap[item].map((errorMessage) => {
                          if (item == 2) {
                            var k = Object.keys(errorMenuMap).find((s) =>
                              errorMessage.startsWith(s)
                            );

                            var s = errorMenuMap[k] || 0;
                            return (
                              <li key={errorMessage.index}>
                                <Link
                                  to={`/cliente/projetos/${this.props.match.params.projectId}/passo-${item}?s=${s}`}
                                >
                                  {errorMessage}
                                </Link>
                              </li>
                            );
                          } else {
                            return (
                              <li key={errorMessage.index}>{errorMessage}</li>
                            );
                          }
                        })}
                      </ul>
                    </p>
                  );
                })}
              </div>
            )}

            <br />
            <br />
            <br />

            <div className="columns">
              <div className="column is-12">
                {this.props.projectType === 0 &&
                  productSelected &&
                  productSelected?.global &&
                  this.props.selectPartner && (
                    <GlobalFileBox
                      title={I18n.get("Atenção!")}
                      description={I18n.get(
                        "Para criar esse projeto, anexe a aprovação Global."
                      )}
                      username={this.props.auth.username}
                      action={this.saveGlobal}
                      file={this.props.globalAuthorization}
                    />
                  )}
              </div>
            </div>

            <div className="columns">
              <div className="column is-6 is-offset-3">
                <div className="field is-grouped is-grouped-centered">
                  <p className="control is-expanded" onClick={this.scrollToTop}>
                    <Link
                      to={`/cliente/projetos/${
                        this.props.match.params.projectId
                      }/passo-${step - 1}`}
                    >
                      <ButtonBpool
                        text={I18n.get("Voltar")}
                        theme="secondary"
                        full={true}
                      />
                    </Link>
                  </p>
                  <p className="control is-expanded" onClick={this.scrollToTop}>
                    {/* <SubmitButtonField
                      className="button bt-bpool is-fullwidth pull-right"
                      label={I18n.get("Criar projeto agora")}
                      onClick={this.onSubmitForm.bind(this)}
                      task={this.state.task}
                      suppressLabel={true}
                      disabled={this.props.projectType === 0 && productSelected?.global && !this.props.globalAuthorization && this.props.selectPartner}
                    /> */}

                    <ButtonBpool
                      text={I18n.get("Criar projeto agora")}
                      onClick={this.onSubmitForm.bind(this)}
                      className=""
                      theme="primary"
                      loading={this.state.task?.busy}
                      full={true}
                      disabled={
                        this.props.projectType === 0 &&
                        productSelected?.global &&
                        !this.props.globalAuthorization &&
                        this.props.selectPartner
                      }
                    />
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="field">
                            <p className="control is-centered">
                                <Link to={`/cliente/projetos/${this.props.match.params.projectId}/passo-${step - 1}`} className="pull-left button bt-bpool grey">{I18n.get("Voltar")}</Link>
                                <SubmitButtonField
                                    className="button bt-bpool pull-right"
                                    label={I18n.get("Criar projeto")}
                                    onClick={this.onSubmitForm.bind(this)}
                                    task={this.state.task}
                                    suppressLabel={true}
                                />
                            </p>
                        </div> */}

            <br />
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(NewProjectStep5);
