import axios from "axios";
import { Auth } from "aws-amplify";
import { I18n } from "aws-amplify";

import { Modal, notification } from "antd";

import Config from "../../config";

import { BP_PARTNERS_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const { error } = Modal;
const send_email_vip = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .post(
            `${Config.API_BASE_URL}/v2/Admin/Partner/vip-resend-invite`,
            data,
            config
          )
          .then((response) => {
            if (response.success) {
              return response.data;
            } else {
              dispatch({
                type: BP_PARTNERS_GET,
                payload: {
                  errors: response.data.errors,
                  task: {
                    result: false,
                  },
                },
              });

              return response.data;
            }
          })
          .catch((error) => {
            return error.data;
            throw error;
          });
      })
      .catch((error) => {});
  };
};

export default send_email_vip;
