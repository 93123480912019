import axios from 'axios';

import Config from '../../config';

import { CLIENT_CREATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_create_client = (data) => {
    return (dispatch) => {

        return axios.post(`${Config.API_BASE_URL}/client`, data)
            .then(response => {

                dispatch({
                    type: CLIENT_CREATE,
                    payload: {
                        email_sent: true,
                        errors: {}
                    }
                })
            })
            .catch(error => {

                dispatch({
                    type: CLIENT_CREATE,
                    payload: {
                        errors: error.response.data
                    }
                })
            });
    };
}

export default api_create_client;