import React from 'react';

const Terms = (props) => {

    return (
<div class="termsContainer">
   <div class="termsContent">
      <p>
         <strong>Boutique Pool</strong>
      </p>
      <p>
         <strong>TERMO </strong><strong>DE USO </strong><strong>CLIENTE</strong>
      </p>
      <p>
         &nbsp;
      </p>
      <p>
         Este Termo de Uso (“Termo”) rege o uso da plataforma online (“Plataforma”) criada e desenvolvida pela
         <strong>BOUTIQUE POOL
         INTERMEDIAÇÃO E SERVIÇOS DE MARKETING LTDA.</strong>, com sede na Av. Nove de Julho 5229, 1º andar na
         cidade e Estado de São
         Paulo, CEP 01.406-200, inscrita no CNPJ/MF sob o nº 31.391.925/0001-34 (“Boutique Pool”), pelas empresas
         que desejam
         contratar serviços criativos de marketing de terceiros por meio da Plataforma (“Clientes”).
      </p>
      <p>
         A CONCORDÂNCIA AO PRESENTE TERMO É ABSOLUTAMENTE INDISPENSÁVEL À UTILIZAÇÃO DA PLATAFORMA BOUTIQUE POOL E
         SEUS
         SERVIÇOS.
      </p>
      <p>
         O Cliente deverá ler, certificar-se de haver entendido e concordar com todas as condições estabelecidas
         neste Termo,
         antes da contratação de projetos na plataforma.
      </p>
      <p>
         <strong>1. Definições</strong> Para todos os fins e efeitos deste Termo, os seguintes termos e expressões iniciados
         em letra maiúscula,
         no singular ou no plural, terão os significados indicados a seguir, com a devida consideração a
         outros termos definidos
         ao logo deste instrumento:
      </p>
      <p>
         <em>“Parceiro”</em> significam tanto agências de marketing quanto empresas e
         estúdios de criação,
         planejamento, produção de conteúdo, design e/ou tecnologias em mídias diversas, de pequeno e médio
         porte, que prestem
         os serviços de marketing disponíveis à contratação pelos Clientes por meio da Plataforma;
      </p>
      <p>
         <em>“Matchmaking”</em> significam as etapas de seleção de Parceiros Criativos promovidas pela Plataforma,
         com base em algoritmo
         proprietário desenvolvido pela plataforma e critérios específicos e determinados de acordo com o
         Projeto solicitado
         pelo Cliente. Ao final da etapa de Matchmaking, a Plataforma indicará três Parceiros Criativos
         ideais à demanda
         solicitada pelo Cliente;
      </p>
      <p>
         “Curadoria" significa a aplicação de metodologias de análise e seleção de participantes da
         Plataforma de forma
         recorrente, utilizando intervenção humana da Boutique Pool, curadores e algoritmos de avaliação
         para a homologação de
         Parceiros que estejam dentro dos padrões exigidos pela Boutique Pool, para então serem indicados e
         conectados com os
         Clientes , respeitando-se as particularidades e expectativas necessárias para o bom desenvolvimento
         de negócios entre
         ambas as partes;
      </p>
      <p>
         <em>“Asset”</em> produto criativo precificado e com seu escopo detalhado, como por exemplo, land page, site,
         prancha conceito,
         conceito criativo, design de embalagem, design de material de ponto de venda, entre outros
      </p>
      <p>
         <em>“Asset Market”</em> lista de produtos criativos em um formato de market place categorizado por tipo e
         especialidade de
         serviços de comunicação e marketing apresentado ao Cliente, devidamente precificada com
         detalhamento completo de
         escopo. O Cliente pode selecionar projetos no Asset Market (pacotes de assets) ou pode monte montar
         seu próprio projeto
         montando seu asset individual.
      </p>
      <p>
         <em>“Serviço”</em> ou <em>“Projeto”</em> significam os serviços de marketing contratados pelo Cliente dos Parceiros
         Criativos
         selecionados ou projetos desenvolvidos para os Clientes pelos Parceiros, de forma específica,
         mediante o emprego de
         ferramentas da Plataforma. Soma de Assets agrupados. Os Serviços podem englobar serviços de
         comunicação em geral,
         eventos, treinamento e/ou pesquisa, bem como a criação de materiais impressos, eletrônicos,
         audiovisuais ou
         radiofônicos, sujeitos às proteções legais inerentes aos Direitos Autorais definidos ao longo deste
         Termo.
      </p>
      <p>
         <em>“Concorrência”</em> significam etapas de uma seleção específica, diferente do Matchmaking, por meio da
         qual Parceiros
         Criativos poderão ser convidados por Clientes a apresentarem propostas comerciais e criativas para
         um determinado
         Projeto. Nesses casos, Parceiro e Cliente deverão aderir a um termo específico, o qual
         conterá as regras da
         respectiva Concorrência.
      </p>
      <p>
         <strong>2. Cadastro.</strong> A Plataforma somente está disponível para pessoas jurídicas ou físicas que tenham
         capacidade legal para
         contratar nos termos da Lei brasileira. Não poderão utilizar a Plataforma as pessoas que não tenham
         esta capacidade, os
         menores de idade ou Clientes cujo cadastro esteja suspenso ou cancelado.
      </p>
      <p>
         2.1. Para ser um Cliente cadastrado na Plataforma, é necessário que se complete o formulário de
         cadastro disponível na
         Plataforma em todos os seus campos com dados válidos, de maneira exata, precisa e verdadeira
         (“Cadastro”). O Cliente
         deverá fornecer documentos e informações que comprovem a identidade e capacidade de contratar em
         nome desta pessoa
         jurídica, conforme lhe for solicitado para a realização do Cadastro. Outros documentos e
         informações complementares
         poderão ser solicitados pela Boutique Pool. O Cliente assume o compromisso de manter as informações
         incluídas no
         Cadastro atualizadas.
      </p>
      <p>
         2.2. A documentação fornecida pelo Cliente no Cadastro deverá ser, atual, legal e vigente. A
         Boutique Pool não assume
         qualquer responsabilidade de fiscalização ativa dos Clientes, de forma que prejuízos decorrentes de
         informações falsas
         ou imprecisas serão de exclusiva responsabilidade dos Clientes.
      </p>
      <p>
         2.3. No ato do Cadastro, o Cliente deverá informar um e-mail e uma senha provisória será fornecida
         pela Boutique Pool
         para acesso à Plataforma. A Boutique Pool não solicitará sua senha por quaisquer outros meios, ou
         para quaisquer outros
         motivos, que não seja para o acesso à Plataforma.
      </p>
      <p>
         2.4. O Cliente será exclusivamente responsável por todas as operações efetuadas no seu Cadastro,
         devendo guardar o
         sigilo de sua senha. A Boutique Pool não se responsabiliza por operações efetuadas em seu Cadastro.
         Caso perceba o uso
         ou o acesso não autorizado do seu cadastro, o Cliente deverá comunicar tal fato à Boutique Pool
         imediatamente para fins
         de suspensão do cadastro.
      </p>
      <p>
         2.5. O Cliente poderá solicitar o cancelamento do seu cadastro na Plataforma a qualquer momento,
         sem prejuízo das
         solicitações de Serviço já aceitas por Parceiros no momento do pedido de cancelamento, as quais
         deverão ser concluídas
         normalmente, como se permanecesse ativo o cadastro para tais fins, nos termos do Contrato, abaixo
         definido.
      </p>
      <p>
         <strong>3. Uso da Plataforma</strong> Mediante a conclusão de seu Cadastro, homologação dos valores da tabela padrão
         de projetos e
         Assets ou de seus valores específicos e Assets, desde que estejam dentro dos padrões entendidos
         pela Boutique Pool como
         adequados e, por fim, adesão a este Termo, o Cliente poderá usar a Plataforma para contratação de
         Projetos e/ou
         Serviços junto aos Parceiros Criativos selecionados, utilizando-se das ferramentas disponíveis na
         Plataforma, as quais
         incluem (i) acesso ao Asset Market para seleção de Assets ou Projetos conforme Asset e/ou Projetos
         homologados; (ii)
         matchmaking de possíveis Parceiros para a realização de Projetos por meio de algoritmo de matching,
         experiência
         específica de mercado e qualificação profissional; (iii) ambiente de definição de briefing,
         cronograma e escopo final;
         (iv) assinatura digital de Termo Específico de Projeto abaixo definido; (v) sistema de rating mútuo
         para avaliações
         parciais e finais de cada Projeto; (vi) centralização de pagamento de múltiplos parceiros
         contratados através da
         Plataforma; (vii) disponibilização de dashboard informativo com os investimentos realizados através
         da Plataforma em
         cada Projeto e média de avaliação de cada parceiro contratado.
      </p>
      <p>
         3.1. Ao utilizar a Plataforma, os Clientes receberão o direito de uso oneroso e não exclusivo do
         software que compõe a
         Plataforma, para utilização limitada a este Termo.
      </p>
      <p>
         3.2. A Boutique Pool, na qualidade de plataforma de negócios, envidará seus melhores esforços para
         fazer a curadoria
         dos melhores Parceiros possíveis com ética e qualidade criativa para os Serviços demandados pelo
         Cliente e assim
         promover o ambiente para que seja prestado o Serviço da melhor maneira possível, sendo responsável
         pela auditoria das
         relações estabelecidas entre Cliente e Parceiro, por meio da Plataforma, trocando e excluindo
         Parceiros Criativos que
         não prestem os Serviços de acordo com as regras da Plataforma e/ou quando solicitado pelo Cliente.
      </p>
      <p>
         3.3. A aplicação da Curadoria, do Matchmaking e/ou das Concorrências, e contratações devem ser
         realizadas de maneira
         ética e responsável pelas partes envolvidas, com a definição das premissas de contratação
         antecipadamente à contratação
         dos Serviços. Os Parceiros deverão respeitar os Códigos de Ética e Anticorrupção da Boutique Pool e
         dos Clientes.
      </p>
      <p>
         <strong>4. Fluxo da Plataforma.</strong> A Boutique Pool disponibilizará ao Cliente e aos Parceiros Criativos, o uso
         da Plataforma de
         acordo com o seguinte fluxo:
      </p>
      <p>
         4.1. Para usar a Plataforma, o Cliente (com seu login e senha) selecionará um pacote de Assets com
         valor pré-definido
         (homologado pela sua área de compras no cadastro da Plataforma) e o algoritmo de matching, conforme
         abaixo melhor
         definido, indicará três Parceiros Criativos que informarão se conseguem ou não atender à demanda
         solicitada de acordo
         com as condições definidas pelo Cliente. O Cliente então escolherá com qual Parceiro deseja
         trabalhar com base em seu
         portfólio, expertise e empatia.
      </p>
      <p>
         <strong>5. Concorrências.</strong> Em alguns casos caberá à Boutique Pool, através da inteligência da Plataforma,
         organizar as
         Concorrências entre Parceiros Criativos, as quais serão realizadas mediante a adesão pelos
         envolvidos de termos
         específicos a cada Concorrência. As Concorrências serão realizadas no ambiente da Plataforma e/ou
         presencialmente. A
         Boutique Pool envidará seus melhores esforços para realizar as Concorrências de forma justa,
         cabendo aos Clientes e
         Parceiros Criativos se adequarem às regras determinadas na Plataforma.
      </p>
      <p>
         <strong>6. Seleção para a Prestação do Serviço ou Projeto.</strong> Uma vez selecionado para desenvolver determinado
         Projeto ou prestar
         determinado Serviço, conforme os procedimentos de Curadoria e de Matchmaking realizados, o Parceiro
         Criativo receberá
         um e-mail convidando-o para participar de tal Projeto com todos os detalhes do Serviço e/ou
         Projeto, incluindo prazo de
         entrega e valores, dentre outras informações (“Convite”).
      </p>
      <p>
         6.1. O Parceiro terá a obrigação de indicar se deseja atender ou não um determinado Projeto em até
         1 (um) dia útil
         (entende-se até o mesmo horário do próximo dia útil) contado a partir do recebimento da mensagem de
         indicação da
         Boutique Pool, realizada no ambiente da Plataforma. O silêncio de um Parceiro será interpretado
         pela Boutique Pool como
         recusa para aceite de um Projeto. Caso o prazo estabelecido neste item caia em um final de semana,
         será considerado o
         primeiro dia útil subsequente.
      </p>
      <p>
         6.2. Os três Parceiros selecionados que aceitaram o Convite serão apresentados ao Cliente e a
         Plataforma auxiliará a
         escolha do Cliente exibindo o portfólio, histórico e credenciais de tais Parceiros, assim como
         estimulará conversas
         presenciais ou realização de calls para que o Cliente possa fazer a escolha a seu exclusivo
         critério.
      </p>
      <p>
         6.3. O Cliente terá que decidir dentre estes Parceiros qual é o mais adequado ao Serviço ou Projeto
         dentro do prazo que
         selecionou na Plataforma. Caso o Cliente não selecione o Parceiro dentro do respectivo prazo, o
         Parceiro fica
         autorizado a retirar-se daquele Matchmaking e/ou Concorrência específica.
      </p>
      <p>
         <strong>7. Termo de Especificação de Projeto (“TEP”).</strong> Uma vez realizado o Matchmaking e selecionado o
         Parceiro pelo Cliente,
         ajusta-se na plataforma o escopo final e as respectivas macro-etapas do Projeto, e mediante o
         aceite final é gerado o
         TEP especifico daquela contratação, o qual deverá ser assinado de forma digital entre Cliente e
         Parceiro, com a
         interveniência da Boutique Pool, estabelecendo os direitos e obrigações específicas do Cliente e do
         Parceiro com
         relação aos Serviços e/ou Projeto selecionado, detalhando o Projeto a ser desenvolvido ou Serviço a
         ser prestado ao
         Cliente, incluindo, os prazos e forma de pagamento da remuneração do Parceiro e regulamentando a
         relação entre estas
         partes.
      </p>
      <p>
         7.1. Qualquer Parceiro selecionado pelo Cliente para a prestação de Serviços ou a execução
         de Projetos nos
         termos deste Termo estará vinculado ao TEP. As obrigações do Cliente assumidas junto ao Parceiro
         Criativo são exigíveis
         diretamente pelo Parceiro frente ao Cliente.
      </p>
      <p>
         <strong>8. Avaliação.</strong> A Plataforma conterá ferramentas de avaliação do Serviço ou Projeto executado pelo
         Parceiro de acordo com
         cada uma das etapas definidas no início de cada Projeto/Serviço contratado entre os Clientes e os
         Parceiros. Em caso de
         avaliações inconsistentes com as expectativas e briefing de um Cliente, determinado Parceiro deverá
         adequar os
         materiais submetidos, refazendo-os de acordo com as instruções indicadas por escrito pelo
         respectivo Cliente. Enquanto
         tal adequação estiver sendo realizada, os pagamentos específicos a este Parceiro poderão ser
         suspensos, resguardados os
         valores relativos aos serviços concluídos. Uma vez que ocorra a conclusão de eventuais ajustes ou
         de uma determinada
         etapa de contratação e mediante a avaliação satisfatória do Cliente que contratou os Serviços, o
         pagamento específico a
         este Parceiro será efetuado.
      </p>
      <p>
         8.1. A Plataforma limitará a quantidade de vezes que determinado Projeto possa ser refeito ou
         adequado, evitando onerar
         demasiadamente os Parceiros. Nos casos em que determinado Cliente ultrapasse as quantidades de
         adequações permitidas ou
         altere muitas vezes o briefing e escopo dos Serviços em um Projeto, ficará a critério de cada
         Parceiro a negociação com
         o cliente de uma nova contratação na Plataforma, ou pela funcionalidade denominada “extensão de
         Projetos” disponível na
         Plataforma.
      </p>
      <p>
         8.2. As avaliações realizadas na Plataforma poderão conter informações públicas, desde que
         aprovadas previamente pelos
         Parceiros e Clientes, quando então estarão disponíveis a todos os inscritos na Plataforma. As
         avaliações deverão ser
         realizadas de boa-fé e de maneira objetiva, indicando feedback para os Parceiros de maneira
         concisa. A Plataforma não
         disponibilizará publicamente avaliações parciais de Parceiros Criativos enquanto determinados
         Projetos estiverem sendo
         realizados.
      </p>
      <p>
         8.3. Caso determinado Parceiro discorde de uma avaliação realizada, poderá solicitar sua revisão,
         pela Boutique Pool,
         bem como o esclarecimento de eventuais dúvidas.
      </p>
      <p>
         8.4. A Boutique Pool poderá excluir da Plataforma, os Parceiros que recebam 3 ou mais avaliações
         ruins, em contratações
         específicas, ao longo de um período de até 6 meses. Nestes casos, a Boutique Pool informará ao
         Parceiro excluído, por
         escrito, os motivos de sua exclusão. A Boutique Pool considerará avaliações ruins quando a nota
         geral conferida ao
         término do Projeto fica abaixo de 3, regular entre 3.0 e 3.99, boa entre 4.0 e 4.74 e excelente de
         4.75 a 5.
      </p>
      <p>
         <strong>9. Desenvolvimento e Manutenção da Plataforma.</strong> Cabe à Boutique Pool desenvolver e manter atualizada
         a Plataforma para
         que esta organize as relações entre Clientes e Parceiros, de forma segura e respeitando as
         premissas definidas em cada
         Projeto. A Boutique Pool poderá interromper a disponibilidade da Plataforma a qualquer tempo em
         paradas programadas,
         sem a necessidade de aviso prévio aos Clientes e Parceiros, esforçando-se, contudo, para que tais
         paradas sejam
         realizadas fora do horário comercial. A Boutique Pool não é obrigada a manter a Plataforma
         disponível indefinidamente,
         não havendo qualquer tipo de responsabilidade perante os Clientes e Parceiros em caso de uma
         eventual indisponibilidade
         não programada.
      </p>
      <p>
         9.1. A Boutique Pool não será responsável por qualquer vírus que possa infectar o equipamento do
         Cliente como
         consequência do acesso, uso ou verificação da Plataforma ou de qualquer transferência de dados,
         arquivos, imagens,
         textos, ou áudio contidos no mesmo. O Cliente não poderá imputar responsabilidade alguma para a
         Boutique Pool, nem
         exigir pagamento por lucro cessante, em virtude de prejuízos resultantes da inobservância às
         disposições aqui contidas,
         decorrentes de dificuldades técnicas ou falhas nos sistemas ou na Internet. A Boutique Pool não
         garante o acesso e uso
         continuado ou ininterrupto da Plataforma. O sistema pode eventualmente não estar disponível devido
         a dificuldades
         técnicas ou falhas de Internet, ou por qualquer outra circunstância alheia à vontade da Boutique
         Pool e em tais casos a
         Boutique Pool procurará restabelecer a acessibilidade à Plataforma com a maior brevidade possível,
         sem que isso possa
         imputar algum tipo de responsabilidade.
      </p>
      <p>
         9.2. É absolutamente proibido aos Clientes qualquer ação ou uso de dispositivo, software, ou outro
         meio com o propósito
         de interferir nas atividades e operações de Plataforma. Qualquer intromissão, atividade ou
         tentativa de violação ou que
         seja contrária às leis, incluindo, sem limitação, as que versem sobre direito de propriedade
         intelectual, e/ou as
         proibições estipuladas neste Termo serão passíveis das ações legais pertinentes.
      </p>
      <p>
         <strong>10. Não Competição.</strong> Quando houver solicitação de um Cliente, a Plataforma não listará como opção,
         os Parceiros que
         estiverem trabalhando com outros clientes de uma mesma categoria do Cliente. Para tanto, quando do
         seu cadastramento na
         Plataforma, cada um dos Parceiros Criativos deverá indicar as marcas de produtos e/ou serviços com
         as quais
         eventualmente já trabalhe (sendo o critério para tal, o faturamento de uma Nota Fiscal nos últimos
         3 meses) para que
         haja o estabelecimento de práticas de não competição e assim, um Parceiro não desenvolva
         qualquer tipo de
         trabalho para produtos e/ou serviços concorrentes.
      </p>
      <p>
         10.1. Para fins do estabelecimento das práticas de não concorrência, serão consideradas apenas as
         marcas dos produtos
         e/ou serviços dentro da determinada categoria, com que determinado Parceiro trabalhe, não
         as empresas que
         detenham e comercializem as marcas de tais produtos e/ou serviços. Deste modo, os Parceiros
         Criativos não poderão atuar
         apenas para marcas de produtos e/ou serviços concorrentes diretamente da mesma categoria, não para
         as empresas que
         sejam as fabricantes destes.
      </p>
      <p>
         10.2. Se um Parceiro já possuir acordos de não competição com um de seus clientes quando
         de seu cadastramento
         e utilização da Plataforma, deverá prestar esta informação quando do início de seu relacionamento
         com a Boutique Pool,
         durante seu cadastramento, assinalando a opção de “non-compete ativo’’.
      </p>
      <p>
         10.3. Os Parceiros Criativos devem manter atualizada a listagem de empresas, produtos e serviços
         com que mantenham ou
         tenham mantido um relacionamento comercial nos últimos 3 (três) meses, para que não haja conflitos
         entre estas e
         eventuais Clientes que possam ser indicados aos Parceiros Criativos, nos termos deste item.
      </p>
      <p>
         10.4. Caso seja exigido um período de não competição de um Parceiro para que este
         desenvolva serviços para um
         determinado Cliente, será considerado que tal Parceiro verificou e concordou com tal
         limitação ao aceitar
         iniciar os trabalhos com tal Cliente, não havendo qualquer tipo de responsabilidade da Boutique
         Pool na hipótese de
         haver demandas a respeito do período de não competição já aceito em determinado Projeto.
      </p>
      <p>
         <strong>11. Remuneração e Forma de Pagamento.</strong> O Cliente deverá remunerar os Parceiros Criativos nos termos
         definidos para cada
         Serviço ou Projeto contratado, de acordo com cada Convite e TEP celebrado entre Cliente e
         Parceiros, inclusive quando
         houver pagamentos escalonados (“Remuneração”). A Boutique Pool fará a gestão do fluxo de pagamentos
         dos Projetos
         contratados, liberando os pagamentos de acordo com a conclusão de etapas pré-definidas entre as
         Partes, retendo apenas
         os valores que lhe couberem pela intermediação dos Serviços contratados.
      </p>
      <p>
         11.1. Em caso de tentativas de alteração de escopo pelo Cliente após a aprovação de um Projeto,
         caberá aos Parceiros
         Criativos informar à Boutique Pool sobre tais mudanças para que se evite eventuais prejuízos.
         Extensão de escopos
         padrão da Plataforma também poderão ser contratados através da funcionalidade “Extensão de Projetos
         e Itens Não
         Padronizados” na plataforma, através do Menu “Produção” dentro de cada Projeto.
      </p>
      <p>
         11.2. Os pagamentos realizados pelo Cliente serão endereçados à Boutique Pool, que fará toda a
         gestão destes
         pagamentos, os repassando a cada Parceiro dentro das condições de cada Projeto. Em
         determinados Projetos, a
         Boutique Pool poderá gerir o pagamento em etapas à um Parceiro, conforme houver a
         conclusão de cada uma destas
         etapas. Os pagamentos aos Parceiros Criativos deverão observar, de todo modo, os prazos de
         pagamento dos Clientes.
      </p>
      <p>
         11.3. As Partes acordam que o pagamento da Remuneração pela Cliente ocorrerá conforme o fluxo
         descrito abaixo.
      </p>
      <p>
         (i) O Cliente deverá inserir o número de pedido ou ordem de compras (“Pedido”) quando aplicável ou,
         autorizar o
         Faturamento na plataforma;
      </p>
      <p>
         (ii) Após a inserção do Pedido na Plataforma ou autorização do faturamento, o Parceiro
         será notificado por
         e-mail com instruções e deverá: (1) emitir uma nota fiscal contra o Cliente no valor de Remuneração
         previsto para o
         Parceiro, conforme escopo final aprovado e/ou TEP aos cuidados da Boutique Pool, seguindo
         instruções de faturamento da
         notificação e especificando no corpo da referida nota fiscal que os valores serão em nome da
         intermediadora de serviços
         Boutique Pool, contra nota de intermediação; (2) fazer upload da referida nota fiscal emitida na
         Plataforma, e (3)
         Comunicar imediatamente a Boutique Pool através do e-mail suporte.parceiro@bpool.co em
         caso de qual(is)quer
         intercorrência técnica que que prejudique ou inviabilize a emissão da Nota Fiscal;;
      </p>
      <p>
         (iii) A Boutique Pool, por sua vez emitirá uma nota fiscal somando os Serviços prestados pelo
         Parceiro no
         campo de “Intermediação de Serviços” e o Fee Transacional, o qual estará descrito e forma
         transparente no Convite e no
         TEP (“Fee Transacional”), e enviará para o cliente conforme instruções inseridas na plataforma
         (e-mail, upload em
         sistema e/ou recebimento físico);
      </p>
      <p>
         (iv) O Cliente efetuará o pagamento integral da nota referida no item (iii) na conta corrente da
         Boutique Pool e esta
         repassará ao parceiro o valor de seus serviços, conforme escopo final e/ou TEP.
      </p>
      <p>
         Parágrafo Único: As Partes convencionam que, notadamente em relação às obrigações contidas na
         alínea (ii) acima,
         cumprirá ao Parceiro emitir a respectiva Nota Fiscal e realizar seu upload no link
         disponibilizado pela
         Boutique Pool em até 48 (quarenta e oito) horas contadas do recebimento do e-mail contendo as
         instruções neste sentido.
         Resguardado o disposto no item (3) da alínea (ii), fica desde já convencionado que o não
         atendimento ao referido prazo
         pelo Parceiro dará ensejo à aplicação de penalidade de 2% (dois por cento) sobre o valor
         dos serviços,
         percentual ao qual será acrescido 0,5% (zero vírgula cinco por cento) por dia de atraso, a título
         de mora, limitado a
         5% (cinco por cento).
      </p>
      <p>
         11.4. Em caso de atraso no pagamento da Remuneração por meio das notas fiscais enviadas, recebidas
         e não contestadas
         pelo Cliente, este estará sujeito à cobrança de multa de 2% (dois por cento), além de juros
         moratórios de 1% (um por
         cento) ao mês, calculadas pro rata die. A multa e os juros moratórios referidos neste item serão
         devidos ao Parceiro
         Criativo e à Boutique Pool, proporcionalmente às suas respectivas remunerações, de acordo com os
         termos de contratação
         de cada Projeto ou Serviço.
      </p>
      <p>
         11.5. Na data de adesão a este Termo, o Cliente estará isento do pagamento para a Boutique Pool do
         fee mensal pelo uso
         da Plataforma (“Fee Mensal”). Entretanto, o Cliente está ciente e declara concordar que a Boutique
         Pool poderá vir a
         cobrar do Cliente o Fee Mensal a qualquer momento após a adesão a este Termo, mediante comunicação
         prévia ao Cliente.
      </p>
      <p>
         11.6. Dependendo da quantidade de itens de criação a serem inseridos no sistema, bem como se houver
         necessidade de
         adequação dos critérios de Curadoria e de contratos, de acordo com exigências especificas do
         Cliente, o Cliente está
         ciente e declara concordar que um set-up fee pode vir a ser cobrado pela Boutique Pool, mediante
         comunicação prévia ao
         Cliente.
      </p>
      <p>
         <strong>12. Direitos Autorais.</strong> Para fins deste Termo, “Direitos Autorais” significam os direitos morais e
         patrimoniais das
         criações elaboradas durante a prestação de serviços de marketing em cada Projeto, desde que estas
         contenham criações
         originais e de espírito capazes de destacar uma ideia ou conceito para um produto ou serviço. Neste
         sentido, os
         Direitos Autorais podem englobar criações manifestadas em materiais impressos, eletrônicos,
         audiovisuais ou
         radiofônicos. Os Parceiros Criativos deverão deter a posse dos Direitos Autorais dos materiais a
         que venham a submeter
         para utilização na Plataforma.
      </p>
      <p>
         12.1. Os Parceiros deverão criar conceitos originais que serão ofertados para a contratação pelos
         Clientes. Em caso de
         verificação do uso de materiais não originais, os Parceiros poderão ser excluídos da Plataforma,
         ressalvado o direito
         de indenização que determinado Cliente que porventura seja prejudicado possa vir buscar contra o
         Parceiro. A Boutique
         Pool não possui qualquer responsabilidade sobre a violação de Direitos Autorais de terceiros na
         prestação dos Serviços,
         devendo a parte prejudicada buscar seus direitos junto ao Parceiro que violou tais direitos.
      </p>
      <p>
         12.2. Todas as marcas, nomes comerciais, nomes de domínio e demais sinais distintivos da Boutique
         Pool na Plataforma,
         assim como os programas, banco de dados, redes e arquivos, que permitem que o Parceiro acesse e use
         a Plataforma, são
         propriedade da Boutique Pool e estão protegidos pelas leis e tratados internacionais de direito
         autoral, marcas,
         patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos
         referidos conteúdos são
         proibidos, salvo a autorização expressa da Boutique Pool.
      </p>
      <p>
         12.3. Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de
         propriedade
         intelectual e/ou as proibições estipuladas neste Termo, tornarão o responsável passível das ações
         legais pertinentes,
         bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais
         sanções.
      </p>
      <p>
         <strong>13. Cessão de Direitos Autorais.</strong> Os materiais sujeitos a Direitos Autorais serão cedidos aos
         Clientes quando
         determinado Parceiro for selecionado em uma Curadoria, Matchmaking ou Concorrência específica e o
         Serviço prestado. Tal
         cessão é onerosa nos termos de cada contratação, específica para a utilização em cada Projeto
         contratado e limitada aos
         usos previstos em cada Projeto. Em caso de ausência de definição específica, a cessão será
         considerada onerosa e
         ilimitada, definitiva e abrangerá todos os materiais produzidos pelo Parceiro que sejam
         sujeitos a Direitos
         Autorais.
      </p>
      <p>
         13.1. A cessão dos Direitos Autorais deverá ser realizada pelo Parceiro com a garantia de que este
         obteve os direitos
         dos autores dos materiais contidos nos Serviços, pessoas físicas, que elaboraram estes materiais.
         Em caso de ausência
         na obtenção da cessão das pessoas físicas, caberá a cada Cliente definir entre obter a cessão
         destes Direitos Autorais
         de forma adicional ou cancelar a contratação de determinado Parceiro. À Boutique Pool caberá a
         exclusão deste Parceiro
         da Plataforma, mediante solicitação do Cliente, caso o Parceiro não apresente ao Cliente documentos
         que demonstrem que
         os Direitos Autorais lhe foram cedidos de maneira correta. Os direitos morais sobre os materiais de
         marketing não são
         cedidos, na forma do artigo 24 da Lei de Direitos Autorais (Lei 9810/98), sendo de responsabilidade
         dos Clientes nomear
         seus criadores na exibição dos materiais de marketing que foram criados.
      </p>
      <p>
         <strong>14. Atuação Boutique Pool.</strong> A Boutique Pool atua na conexão entre o Cliente e o Parceiro e sua
         responsabilidade é
         limitada aos serviços por ela prestados por meio da Plataforma, conforme descrito neste Termo.
      </p>
      <p>
         14.1. O Cliente está ciente e declara concordar que a Boutique Pool colocará o melhor time e
         tecnologia na realização
         da Curadoria, Matchmaking e Concorrência, envidando sempre seus melhores esforços para indicar os
         melhores Parceiros
         para a prestação dos Serviços aos Clientes, mas não poderá ser responsabilizada diretamente pelos
         danos eventualmente
         causados aos Clientes pelos Parceiros na prestação dos Serviços.
      </p>
      <p>
         14.2. Em qualquer hipótese de Serviço prestado de forma insatisfatória ao Cliente, a Boutique Pool
         tomará as medidas
         necessárias para solucionar qualquer inconsistência na prestação dos Serviços, na forma prevista no
         item 8 acima,
         promovendo a troca do Parceiro se for o caso, bem como a retenção e/ou devolução dos valores pagos
         pelo Cliente, de
         acordo com as regras da Plataforma aplicáveis ao caso vigentes à época do ocorrido.
      </p>
      <p>
         14.3. Em caso de conflito entre Cliente e Parceiro, a Boutique Pool envidará seus melhores esforços
         para resolver tal
         conflito de acordo com as regras pré-definidas da Plataforma e definições do TEP, não podendo,
         entretanto, ser
         responsabilizada pelo pagamento de indenizações por perdas e danos, incluindo, mas não se limitando
         a, danos materiais
         e morais e lucros cessantes.
      </p>
      <p>
         14.4. O Cliente poderá adotar as medidas legais necessárias diretamente perante o Parceiro, caso
         queira obter
         indenização por eventuais danos materiais e morais, causados, a si ou terceiros.
      </p>
      <p>
         14.5. Não obstante as disposições acima, em qualquer hipótese, a responsabilidade da Boutique Pool
         nos termos deste
         instrumento é limitada ao valor da Remuneração de cada Serviço/Projeto contratado na Plataforma.
      </p>
      <p>
         <strong>15. Exclusão da Plataforma.</strong> A Boutique Pool poderá excluir da Plataforma os Clientes e Parceiros
         que não atuem em
         consonância com estes Termos, a ética ou a lei brasileira. Nas hipóteses em que a Boutique Pool
         tomar conhecimento de
         negociação ou tentativa de negociação entre Clientes e Parceiros fora do ambiente da Plataforma,
         após seu início ter
         ocorrido no ambiente da mesma, a exclusão será sumariamente realizada.
      </p>
      <p>
         15.1. Em caso de exclusão durante um procedimento de Curadoria, Matchmaking ou Concorrência, a
         Boutique Pool poderá
         suspender estes procedimentos sem que haja a efetiva contratação de um Parceiro (em caso de
         exclusão de um Cliente), ou
         suspender a participação de um Parceiro em determinado procedimento de Curadoria, Matchmaking ou
         Concorrência, sem que
         haja a devolução de quaisquer valores incorridos pela Parte que vier a ser excluída da Plataforma.
      </p>
      <p>
         15.2. Para fins desta cláusula, nas hipóteses em que a Boutique Pool não tiver sido remunerada,
         será ainda devido ao
         seu comissionamento previsto para cada Projeto, pelo Cliente no caso do Projeto ainda não ter sido
         pago ou pelo
         Parceiro, caso este já tenha recebido os valores deste Projeto, sem prejuízo das demais perdas e
         danos que se fizerem
         exigidos.
      </p>
      <p>
         15.3. A única exceção à regra acima será nas hipóteses em que determinado Parceiro receba
         a oferta de um
         Projeto pela Boutique Pool e se descubra que este já manteve um relacionamento comercial com o
         Cliente selecionado pela
         Boutique Pool nos últimos 12 meses anteriores. Nesta hipótese, a Boutique Pool deverá ser informada
         de imediato para
         que possa alterar as condições do relacionamento direto entre o Cliente e o Parceiro,
         observando-se que nesta
         hipótese a remuneração da Plataforma somente será considerada para os Projetos que forem iniciados
         a partir da
         utilização da Plataforma. Caso haja a definição do Cliente para que todos os Projetos com este
         Parceiro sejam
         concentrados na Plataforma, a partir de então, a Boutique Pool deverá estabelecer valores
         específicos que contemplem
         tal cenário.
      </p>
      <p>
         <strong>16. Descumprimento contratual.</strong> Sem prejuízo de outras medidas, a Boutique Pool poderá advertir,
         suspender ou cancelar,
         temporária ou definitivamente, a conta de um Cliente na Plataforma, a qualquer tempo, e iniciar as
         ações legais
         cabíveis: a) se o Cliente (i) não cumprir qualquer dispositivo deste Termo, (ii) descumprir com
         seus deveres de Cliente
         junto à Boutique Pool e ao Parceiro, ou (iii) praticar atos fraudulentos ou dolosos; b) se qualquer
         informação
         fornecida pelo Cliente esteja incorreta; ou c) se a Boutique Pool entender que qualquer atitude do
         Cliente tenha
         causado algum dano a terceiros ou à Boutique Pool ou tenham a potencialidade de assim o fazer.
      </p>
      <p>
         16.1. O Cliente indenizará a Boutique Pool, suas filiais, empresas controladas ou controladoras,
         diretores,
         administradores, colaboradores, representantes e empregados por qualquer demanda promovida por
         outros Clientes,
         Parceiros ou terceiros, decorrentes de suas atividades na Plataforma ou por seu descumprimento
         deste Termo, pela
         violação de qualquer lei ou direitos de terceiro, notadamente aqueles vinculados à
         confidencialidade das informações
         recebidas do Cliente, à privacidade dos dados acessados em decorrência do presente instrumento, bem
         como à regulação
         das atividades de publicidade.
      </p>
      <p>
         <strong>17. Resolução de Conflitos.</strong> A Boutique Pool poderá mediar eventuais conflitos entre Clientes e
         Parceiros para que sejam
         solucionados de acordo os Termos aqui dispostos e a lei brasileira aplicável, adotando métodos que
         sejam transparentes
         para Clientes e Parceiros e poderá reter os valores envolvidos até que o conflito seja entre
         Clientes e Parceiros.
      </p>
      <p>
         17.1. Em todas as mediações, todos os envolvidos se esforçarão para que os procedimentos sejam
         realizados e concluídos
         em no máximo 30 dias a contar de seu início.
      </p>
      <p>
         17.2. Caso quaisquer conflitos não sejam resolvidos pela mediação prevista neste item no prazo
         máximo de 60 dias
         contados de seu início, tal(is) conflito(s) deverá(ã) ser resolvido(s) por meio de arbitragem, nos
         termos do
         Regulamento da ARBITRANET, por um tribunal arbitral composto por 3 (três) árbitros, os quais serão
         nomeados de acordo
         com o disposto no referido regulamento. A arbitragem será conduzida em língua portuguesa e deverá
         ser realizada na
         cidade de São Paulo, Estado de São Paulo, Brasil.
      </p>
      <p>
         17.3. Sem prejuízo do disposto na Cláusula 18.2. acima, as Partes reconhecem que qualquer uma delas poderá recorrer ao Poder Judiciário exclusivamente para as seguintes medidas, sendo que tais medidas não devem ser interpretadas como renúncia pelas Partes do procedimento de arbitragem: (i) para obter medidas liminares e cautelares previamente à confirmação do tribunal arbitral; (ii) para execução de qualquer decisão do tribunal arbitral, incluindo a sentença final; (iii) para a execução específica deste instrumento; e (iv) para outros procedimentos expressamente admitidos pela Lei nº 9.307/96, conforme alterada. Para fins do disposto neste item, fica eleito o foro da capital de São Paulo.
      </p>
      <p>
         <strong>18. Relações Trabalhistas.</strong> Sob nenhuma hipótese ou em qualquer situação, se presumirá a eventual
         existência, ou se
         estabelecerá a presunção de qualquer vínculo empregatício, ou obrigações de caráter trabalhista e
         previdenciário entre
         as Partes, por si e/com os prepostos ou empregados da outra Parte, nem qualquer das Partes será
         fiadora das obrigações
         e encargos trabalhistas e sociais da outra Parte a qual, assume, neste ato, integral
         responsabilidade por tais
         obrigações, inclusive civil e penal. As Partes serão integralmente responsáveis por serviços de
         terceiros que
         eventualmente sejam utilizados no cumprimento das obrigações assumidas na Plataforma. Neste
         sentido, responderão pelas
         obrigações impostas pela legislação trabalhista e previdenciária, responsabilizando-se pelo
         recolhimento de todos os
         encargos fiscais, trabalhistas, sociais e previdenciários de seus empregados alocadas na execução
         de suas atividades.
      </p>
      <p>
         <strong>19. Impostos.</strong> Os tributos de qualquer natureza que sejam devidos em decorrência direta dos Projetos
         prestados a partir
         de contratações através da Plataforma são de exclusiva responsabilidade do contribuinte do
         respectivo tributo, conforme
         definido na norma tributária.
      </p>
      <p>
         <strong>20. Acordo.</strong> Este Termo constitui o acordo integral entre as Partes, relativamente ao uso da
         Plataforma e prevalece
         sobre quaisquer acordos anteriores.
      </p>
      <p>
         <strong>21. Alterações.</strong> Este Termo será revisto periodicamente pela Boutique Pool, que poderá alterá-lo,
         excluindo, modificando
         ou inserindo cláusulas ou condições, a seu exclusivo critério. Não obstante, o Cliente receberá uma
         notificação sempre
         que houver uma alteração nas disposições deste Termo para que possa ter ciência das novas
         condições. Caso não concorde
         com as alterações, deverá cancelar sua conta e cessar toda e qualquer utilização da Plataforma.
      </p>
      <p>
         <strong>22. Anticorrupção.</strong> As Partes cumprirão e farão com que todos os seus empregados, subcontratados,
         consultores, agentes
         ou representantes cumpram a Lei 12.846/13, bem como os código de ética dos Clientes, quando houver.
         Nenhuma das Partes
         pagará, oferecerá, prometerá ou dará, direta ou indiretamente qualquer valor ou coisa de valor,
         incluindo quaisquer
         valores a ela pagos em decorrência dos serviços contratados, a qualquer funcionário ou oficial de
         um governo, empresa
         ou sociedade controlada pelo governo ou de propriedade do mesmo, partido político, candidato para
         cargo político, ou a
         qualquer outra pessoa estando ciente de ou acreditando que tal valor ou item de valor será
         transmitido a alguém, para
         influenciar qualquer ação ou decisão por tal pessoa ou por qualquer órgão governamental com a
         finalidade de obter,
         reter ou conduzir negócios para quaisquer das Partes.
      </p>
      <p>
         <strong>23. O Cliente</strong>, neste ato, declara e garante à Boutique Pool que leu, compreendeu e concorda com
         todos as disposições
         deste Termos.
      </p>
      <p>
         <strong>24. O Cliente</strong>, bem como os seus respectivos representantes legais, declaram que estão devidamente
         autorizados a
         aceitarem este Termo, na forma de seus respectivos instrumentos sociais.
      </p>

   </div>
    </div>
);
}

export default Terms;
