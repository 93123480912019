import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PARTNER_CREATE_ABROAD } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_partner_company_workAbroad = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        let dataSend = {
            country: data.country,
            workType: data.workType
        }
        

        return axios.post(`${Config.API_BASE_URL}/partner/${data.partnerId}/CompanyAbroad`, dataSend, config)
            .then(response => {

                return true;
                
            })
            .catch(error => {
                
                return false;
                
            });
}).catch(error => {
//  dispatch({ type: EXPIRED });

 });
 };
}

export default api_partner_company_workAbroad;
