import React, { useState } from "react";
import { I18n } from "aws-amplify";

import { Button, Icon, Tag, Input, Switch, InputNumber } from "antd";

import Modal from "../../ui/modal";

import {
  BPCommissionType,
  BPCommissionTypeNames,
  ProjectTypeNames,
  ProjectExtraType,
  ProjectExtraTypeNames,
  BPCompensationType,
  BPCompensationTypeNames,
  CustomEventBriefingType,
  CustomEventBriefingTypeNames,
  CustomEventBriefingRules,
} from "../../../model";

import CustomProjectRuleForm from "./_new-custom-project-rule";
import CustomExtraRuleForm from "./_new-custom-extra-rule";
import CustomBpayForm from "./_new-custom-bpay-rule";

import FormatCurrency from "../../ui/format-currency";

const YearlyRangeRuleTable = ({ children }) => {
  if (!children || children.length == 0) return null;

  return (
    <table style={{ border: "none", padding: "0" }}>
      <tbody>
        <tr>
          <th style={{ textAlign: "right", width: "40%", border: "none" }}>
            Faixa Anual
          </th>
          <th style={{ textAlign: "right", width: "30%", border: "none" }}>
            Perc.
          </th>
          <th style={{ textAlign: "right", width: "30%", border: "none" }}>
            Fixo
          </th>
        </tr>
        {children}
      </tbody>
    </table>
  );
};

const YearlyRangeRule = (item) => {
  return (
    <tr>
      <td style={{ textAlign: "right", border: "none" }}>
        <FormatCurrency amount={item.yearlyRange} />
      </td>
      <td style={{ textAlign: "right", border: "none" }}>{item.perc}%</td>
      <td style={{ textAlign: "right", border: "none" }}>
        <FormatCurrency amount={item.flat} />
      </td>
    </tr>
  );
};

const BudgetMaxRule = (item) => {
  return (
    <tr>
      <td style={{ textAlign: "right", border: "none" }}>
        <FormatCurrency amount={item.budgetMax} />
      </td>
      <td style={{ textAlign: "right", border: "none" }}>{item.perc}%</td>
      <td style={{ textAlign: "right", border: "none" }}>
        <FormatCurrency amount={item.flat} />
      </td>
    </tr>
  );
};

const BudgetMaxRuleTable = ({ children }) => {
  if (!children || children.length == 0) return null;

  return (
    <table style={{ border: "none", padding: "0" }}>
      <tbody>
        <tr>
          <th style={{ textAlign: "right", width: "40%", border: "none" }}>
            Budget Máx.
          </th>
          <th style={{ textAlign: "right", width: "30%", border: "none" }}>
            Perc.
          </th>
          <th style={{ textAlign: "right", width: "30%", border: "none" }}>
            Fixo
          </th>
        </tr>
        {children}
      </tbody>
    </table>
  );
};

const ActionProposal = ({
  item,
  handlePercProposal,
  handleFeeProposal,
  handleActiveProposal,
  clientSettings,
  loading,
}) => {
  let valueInit = item?.bpComissionRule?.perc;
  let feeInit = item?.bpComissionRule?.percFee;

  const [valueCheck, setValueCheck] = useState(item?.bpComissionRule?.perc);
 
  const [feeCheck, setFeeCheck] = useState(item?.bpComissionRule?.percFee);

  let viewBtn = valueInit != valueCheck;
  let viewFeeBtn = feeInit != feeCheck;

  return (
    <table style={{ border: "none", padding: "0" }}>
      <tbody>
        <tr>
          <th style={{ textAlign: "right", border: "none" }}>
            {I18n.get("Retenção")}
          </th>
          <th style={{ textAlign: "right", border: "none" }}>
            {I18n.get("Taxas")}
          </th>
          <th style={{ textAlign: "right", border: "none" }}>
            {I18n.get("Ativar")}
          </th>
        </tr>
        <tr>
          <td style={{ textAlign: "right", border: "none" }}>
            <div
              style={{
                width: 80,
                float: "right",
                position: "relative",
                left: 10,
              }}
            >
              <InputNumber
                defaultValue={item?.bpComissionRule?.perc}
                onChange={(e) => setValueCheck(e)}
                max={100}
              />
              {viewBtn && (
                <Button
                  className="button bt-bpool"
                  onClick={() =>
                    handlePercProposal(item, valueCheck, clientSettings)
                  }
                  loading={loading}
                  style={{ marginTop: 10 }}
                >
                  {I18n.get("Atualizar")}
                </Button>
              )}
            </div>
          </td>
          <td>
            <div
              style={{
                width: 80,
                float: "right",
                position: "relative",
                left: 10,
              }}
            >
              <InputNumber
                defaultValue={item?.bpComissionRule?.percFee}
                onChange={(e) => setFeeCheck(e)}
                max={100}
              />
              {viewFeeBtn && (
                <Button
                  className="button bt-bpool"
                  onClick={() =>
                    handleFeeProposal(item, feeCheck, clientSettings)
                  }
                  loading={loading}
                  style={{ marginTop: 10 }}
                >
                  {I18n.get("Atualizar")}
                </Button>
              )}
            </div>
          </td>

          <td style={{ textAlign: "right", border: "none" }}>
            <Switch
              defaultChecked={item?.bpComissionRule?.enabled}
              onChange={(e) => handleActiveProposal(item, e, clientSettings)}
              loading={loading}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

class Card extends React.Component {
  constructor(props) {
    super();

    this.state = {
      selected: null,
      loading: false,
      percBpay: 0
    };

    this.closeModal = this.onCloseModal.bind(this);
  }

  

  onCloseModal(e) {
    if (e) e.preventDefault();
    this.setState({ showCustomProjectForm: null, showCustomExtraForm: null, showCustomBpayForm: null });
  }

  removeCustomProjectRule(rule, e) {
    var data = {
      clientId: this.props.match.params.clientId,
      ruleId: rule.id,
    };

    this.props
      .delete_client_settings_project_rule(data)
      .then(({ task, errors }) => {});
  }

  removeCustomExtraRule(rule, e) {
    var data = {
      clientId: this.props.match.params.clientId,
      ruleId: rule.id,
    };

    this.props
      .delete_client_settings_extra_rule(data)
      .then(({ task, errors }) => {});
  }

  removeCustomBpayRule(rule, e) {
    var data = {
      clientId: this.props.match.params.clientId,
      ruleId: rule.id,
    };

    this.props
      .delete_client_settings_bpay_rule(data)
      .then(({ task, errors }) => {});
  }

  handlePercProposal(item, perc, clientSettings) {
    this.setState({ loading: true });
    const propItem =
      clientSettings[CustomEventBriefingRules[item.customEventBriefingType]];

    const data = {
      clientId: this.props.match.params.clientId,
      bpComissionRule: {
        perc: perc,
        percFee: propItem?.percFee,
        enabled: propItem?.enabled || false,
      },
      customEventBriefingType: item.customEventBriefingType,
    };

    this.props.update_rules_proposal(data).then((response) => {
      if (response) {
        this.setState({ loading: false });
      }
    });
  }

  handlePercBpay() {
    this.setState({ loading: true });

    const data = {
      clientId: this.props.match.params.clientId,
      bpComissionRule: {
        perc: this.state.percBpay || this.props.bp?.clientSettings?.bpCommissionBPayRule?.perc,
        enabled: true,
      }
    };

    this.props.update_rules_bpay(data).then((response) => {
      if (response) {
        this.setState({ loading: false });
      }
    });
  }

  

  handleFeeProposal(item, perc, clientSettings) {
    this.setState({ loading: true });
    const propItem =
      clientSettings[CustomEventBriefingRules[item.customEventBriefingType]];

    const data = {
      clientId: this.props.match.params.clientId,
      bpComissionRule: {
        perc: propItem?.perc,
        percFee: perc,
        enabled: propItem?.enabled || false,
      },
      customEventBriefingType: item.customEventBriefingType,
    };

    this.props.update_rules_proposal(data).then((response) => {
      if (response) {
        this.setState({ loading: false });
      }
    });
  }

  handleActiveProposal(item, active, clientSettings) {
    this.setState({ loading: true });
    const propItem =
      clientSettings[CustomEventBriefingRules[item.customEventBriefingType]];
    const data = {
      clientId: this.props.match.params.clientId,
      bpComissionRule: {
        perc: propItem?.perc,
        enabled: active,
      },
      customEventBriefingType: item.customEventBriefingType,
    };

    this.props.update_rules_proposal(data).then((response) => {
      if (response) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const { client = {}, clientSettings = {} } = this.props.bp;


    const {
      bpCommissionCreativeRules = [],
      bpCommissionEventRules = [],
      bpCommissionExtraInternalCostsRules = [],
      bpCommissionExtraExternalCostsRules = [],
      bpCommissionExtraFinanceRules = [],
      bpCommissionExtraMediaRules = [],
      bpCommissionExtraToolsRules = [],
      bpCommissionBPayRule = {},
    } = clientSettings;

    const {
      defaultCreativeRules = [],
      defaultEventRules1 = [],
      defaultExtraInternalCostsRules = [],
      defaultExtraExternalCostsRules = [],
      defaultExtraFinanceRules = [],
      defaultExtraMediaRules = [],
      defaultExtraToolsRules = [],
      defaultBPitchRules = [],
    } = clientSettings;

    const {
      creativeCommissionType,
      eventCommissionType,
      extraCommissionType,
      customProjectRules = [],
      customExtraRules = [],
      customBPayRules = [],
    } = clientSettings;

    const eventTypes = [
      CustomEventBriefingType.Communication,
      CustomEventBriefingType.Event,
      CustomEventBriefingType.Shopper,
      CustomEventBriefingType.Incentive,
      CustomEventBriefingType.Digital,
      CustomEventBriefingType.Promotional,
    ];

    return (
      <>
        <div className="card">
          <div className="card-content">
            <h3 className="title is-4">{I18n.get("Configuração")}</h3>

            <div className="content">
              <table
                className="table-bpool no-opacity"
                style={{ fontSize: "11pt" }}
              >
                <tbody>
                  <tr>
                    <th style={{ maxWidth: "5rem" }}>{I18n.get("Tipo")}</th>
                    <th style={{ maxWidth: "10rem" }}>{I18n.get("Escopo")}</th>
                    <th style={{ maxWidth: "5rem" }}>{I18n.get("Comissão")}</th>
                    <th style={{ maxWidth: "5rem" }}>{I18n.get("Valor")}</th>
                    <th style={{ textAlign: "center" }}>
                      {I18n.get("Faixa Customizada")}
                    </th>
                    <th style={{ textAlign: "center" }}>
                      {I18n.get("Faixa Padrão")}
                    </th>
                  </tr>
                  <tr style={{ backgroundColor: "#f5f5f5" }}>
                    <td style={{ verticalAlign: "middle" }}>
                      <Tag>{I18n.get("Regra de Cliente")}</Tag>
                    </td>
                    <td>{I18n.get("Projeto - Comunicação")}</td>
                    <td>
                      {I18n.get(BPCommissionTypeNames[creativeCommissionType])}
                    </td>
                    <td>-</td>
                    <td style={{ fontSize: "80%" }}>
                      <YearlyRangeRuleTable>
                        {bpCommissionCreativeRules &&
                          bpCommissionCreativeRules.map((item) => (
                            <YearlyRangeRule {...item} />
                          ))}
                      </YearlyRangeRuleTable>
                    </td>
                    <td style={{ fontSize: "80%", color: "#a1a1a1" }}>
                      <YearlyRangeRuleTable>
                        {defaultCreativeRules &&
                          defaultCreativeRules.map((item) => (
                            <YearlyRangeRule {...item} />
                          ))}
                      </YearlyRangeRuleTable>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f5f5f5" }}>
                    <td style={{ verticalAlign: "middle" }}>
                      <Tag>{I18n.get("Regra de Cliente")}</Tag>
                    </td>
                    <td>{I18n.get("Projeto - BPool Proposal")}</td>
                    <td>
                      {I18n.get(BPCommissionTypeNames[eventCommissionType])}
                    </td>
                    <td>-</td>
                    <td style={{ fontSize: "80%" }}>
                      <BudgetMaxRuleTable>
                        {bpCommissionEventRules &&
                          bpCommissionEventRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                    <td style={{ fontSize: "80%", color: "#a1a1a1" }}>
                      <BudgetMaxRuleTable>
                        {defaultEventRules1 &&
                          defaultEventRules1.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                  </tr>
                  {defaultBPitchRules &&
                    defaultBPitchRules.map((item) => (
                      <tr style={{ backgroundColor: "#f5f5f5" }}>
                        <td style={{ verticalAlign: "middle" }}>
                          <Tag>{I18n.get("Regra de Cliente")}</Tag>
                        </td>
                        <td>
                          {item.projectSKU && (
                            <span>SKU: {item.projectSKU}</span>
                          )}
                          {!item.projectSKU && (
                            <>
                              {item.projectTypes.map((projectType) => (
                                <div>{`${I18n.get("Projeto")} - ${I18n.get(
                                  ProjectTypeNames[projectType]
                                )}`}</div>
                              ))}
                            </>
                          )}
                        </td>
                        <td>
                          {I18n.get(BPCommissionTypeNames[item.commissionType])}
                        </td>
                        <td>
                          {item.commissionType == BPCommissionType.Perc && (
                            <>{item.commissionValue}%</>
                          )}

                          {item.commissionType == BPCommissionType.Flat && (
                            <>
                              <FormatCurrency amount={item.commissionValue} />
                            </>
                          )}
                        </td>
                        <td style={{ fontSize: "90%" }}>
                          <div>
                            <span style={{ color: "#696969" }}>
                              {I18n.get("Remuneração Participação")}: &nbsp;
                            </span>

                            {I18n.get(
                              BPCompensationTypeNames[item.compensationType]
                            )}
                          </div>
                          {item.compensationType != BPCommissionType.None && (
                            <>
                              <div>
                                <span style={{ color: "#696969" }}>
                                  {I18n.get("Remuneração Custo 2º Lugar")}:
                                  &nbsp;
                                </span>

                                {item.compensationType ==
                                  BPCommissionType.Perc && (
                                  <>{item.compensation2nd}%</>
                                )}

                                {item.compensationType ==
                                  BPCommissionType.Flat && (
                                  <>
                                    <FormatCurrency
                                      amount={item.compensation2nd}
                                    />
                                  </>
                                )}
                              </div>
                              <div>
                                <span style={{ color: "#696969" }}>
                                  {I18n.get("Remuneração Custo 3º Lugar")}:
                                  &nbsp;
                                </span>

                                {item.compensationType ==
                                  BPCommissionType.Perc && (
                                  <>{item.compensation3rd}%</>
                                )}

                                {item.compensationType ==
                                  BPCommissionType.Flat && (
                                  <>
                                    <FormatCurrency
                                      amount={item.compensation3rd}
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  <tr style={{ backgroundColor: "#f5f5f5" }}>
                    <td style={{ verticalAlign: "middle" }}>
                      <Tag>{I18n.get("Regra de Cliente")}</Tag>
                    </td>
                    <td>{I18n.get("Extra - Custos Internos")}</td>
                    <td>
                      {I18n.get(BPCommissionTypeNames[extraCommissionType])}
                    </td>
                    <td>-</td>
                    <td style={{ fontSize: "80%" }}>
                      <BudgetMaxRuleTable>
                        {bpCommissionExtraInternalCostsRules &&
                          bpCommissionExtraInternalCostsRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                    <td style={{ fontSize: "80%", color: "#a1a1a1" }}>
                      <BudgetMaxRuleTable>
                        {defaultExtraInternalCostsRules &&
                          defaultExtraInternalCostsRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f5f5f5" }}>
                    <td style={{ verticalAlign: "middle" }}>
                      <Tag>{I18n.get("Regra de Cliente")}</Tag>
                    </td>
                    <td>{I18n.get("Extra - Custos Externos")}</td>
                    <td>
                      {I18n.get(BPCommissionTypeNames[extraCommissionType])}
                    </td>
                    <td>-</td>
                    <td style={{ fontSize: "80%" }}>
                      <BudgetMaxRuleTable>
                        {bpCommissionExtraExternalCostsRules &&
                          bpCommissionExtraExternalCostsRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                    <td style={{ fontSize: "80%", color: "#a1a1a1" }}>
                      <BudgetMaxRuleTable>
                        {defaultExtraExternalCostsRules &&
                          defaultExtraExternalCostsRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f5f5f5" }}>
                    <td style={{ verticalAlign: "middle" }}>
                      <Tag>{I18n.get("Regra de Cliente")}</Tag>
                    </td>
                    <td>{I18n.get("Extra - Transações Financeiras")}</td>
                    <td>
                      {I18n.get(BPCommissionTypeNames[extraCommissionType])}
                    </td>
                    <td>-</td>
                    <td style={{ fontSize: "80%" }}>
                      <BudgetMaxRuleTable>
                        {bpCommissionExtraFinanceRules &&
                          bpCommissionExtraFinanceRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                    <td style={{ fontSize: "80%", color: "#a1a1a1" }}>
                      <BudgetMaxRuleTable>
                        {defaultExtraFinanceRules &&
                          defaultExtraFinanceRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f5f5f5" }}>
                    <td style={{ verticalAlign: "middle" }}>
                      {" "}
                      <Tag>{I18n.get("Regra de Cliente")}</Tag>
                    </td>
                    <td>{I18n.get("Extra - Compra Mídia/Influencers")}</td>
                    <td>
                      {I18n.get(BPCommissionTypeNames[extraCommissionType])}
                    </td>
                    <td>-</td>
                    <td style={{ fontSize: "80%" }}>
                      <BudgetMaxRuleTable>
                        {bpCommissionExtraMediaRules &&
                          bpCommissionExtraMediaRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                    <td style={{ fontSize: "80%", color: "#a1a1a1" }}>
                      <BudgetMaxRuleTable>
                        {defaultExtraMediaRules &&
                          defaultExtraMediaRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f5f5f5" }}>
                    <td style={{ verticalAlign: "middle" }}>
                      <Tag>{I18n.get("Regra de Cliente")}</Tag>
                    </td>
                    <td>{I18n.get("Extra - Ferramentas")}</td>
                    <td>
                      {I18n.get(BPCommissionTypeNames[extraCommissionType])}
                    </td>
                    <td>-</td>
                    <td style={{ fontSize: "80%" }}>
                      <BudgetMaxRuleTable>
                        {bpCommissionExtraToolsRules &&
                          bpCommissionExtraToolsRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                    <td style={{ fontSize: "80%", color: "#a1a1a1" }}>
                      <BudgetMaxRuleTable>
                        {defaultExtraToolsRules &&
                          defaultExtraToolsRules.map((item) => (
                            <BudgetMaxRule {...item} />
                          ))}
                      </BudgetMaxRuleTable>
                    </td>
                  </tr>

                  <tr style={{ backgroundColor: "#f5f5f5" }}>
                    <td style={{ verticalAlign: "middle" }}>
                      <Tag>{I18n.get("Regra de Cliente")}</Tag>
                    </td>
                    <td>{I18n.get("Bpay")}</td>
                    <td>{I18n.get("Retenção")}</td>
                    <td>-</td>
                    <td style={{ fontSize: "80%" }}></td>
                    <td style={{ fontSize: "80%", color: "#a1a1a1" }}>
                      <table style={{ border: "none", padding: "0" }}>
                        <tbody>
                          <tr>
                            <th
                              style={{ textAlign: "right", border: "none", width: 200 }}
                            ></th>
                            <th style={{ textAlign: "right", border: "none" }}>
                            
                            {I18n.get("Perc")}.
                            </th>
                            <th
                              style={{ textAlign: "right", border: "none" }}
                            ></th>
                          </tr>
                          <tr>
                              <td></td>
                            <td style={{ textAlign: "right", border: "none" }}>
                              <div
                                style={{
                                  width: 80,
                                  float: "right",
                                  position: "relative",
                                  left: 10,
                                }}
                              >
                                <InputNumber
                                  defaultValue={bpCommissionBPayRule?.perc}
                                  onChange={(e) => this.setState({ percBpay: e})}
                                  max={100}
                                />
                              </div>
                            </td>

                            <td
                              style={{ textAlign: "right", border: "none", width: 158 }}
                            >
                              <Button
                              className="button bt-bpool black"
                              onClick={() => this.handlePercBpay()}
                          >
                            {I18n.get("Atualizar")}
                          </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {/* NOVA LINHA */}

                  {eventTypes.map((item) => {
                    const propItem =
                      clientSettings[CustomEventBriefingRules[item]];

                    const itemFormat = {
                      bpComissionRule: {
                        perc: propItem?.perc,
                        percFee: propItem?.percFee,
                        enabled: propItem?.enabled,
                      },
                      customEventBriefingType: item,
                    };

                    return (
                      <tr style={{ backgroundColor: "#f5f5f5" }}>
                        <td style={{ verticalAlign: "middle" }}>
                          <Tag>{I18n.get("Regra de Cliente")}</Tag>
                        </td>
                        <td>
                          {I18n.get("Proposal")}
                          {" - "}
                          {I18n.get(CustomEventBriefingTypeNames[item])}
                        </td>
                        <td>
                          {I18n.get(BPCommissionTypeNames[extraCommissionType])}
                        </td>
                        <td>-</td>
                        <td style={{ fontSize: "80%" }}></td>
                        <td style={{ fontSize: "80%", color: "#a1a1a1" }}>
                          <ActionProposal
                            item={itemFormat}
                            handlePercProposal={this.handlePercProposal.bind(
                              this
                            )}
                            handleFeeProposal={this.handleFeeProposal.bind(
                              this
                            )}
                            handleActiveProposal={this.handleActiveProposal.bind(
                              this
                            )}
                            loading={this.state.loading}
                            clientSettings={clientSettings}
                          />
                        </td>
                      </tr>
                      //   <div style={{ marginTop: "2rem" }}>
                      //   <a
                      //     onClick={this.onChangeEventType.bind(this, item)}
                      //     className="button bt-bpool black is-dark"
                      //     style={{ width: "280px" }}
                      //   >
                      //     {I18n.get(CustomEventBriefingTypeNames[item])}
                      //   </a>
                      // </div>
                    );
                  })}

                  {/* FIM NOVA LINHA */}
                  {customProjectRules &&
                    customProjectRules.map((item) => (
                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          <Tag>{I18n.get("Regra de Projeto")}</Tag>
                        </td>
                        <td>
                          {item.projectSKU && (
                            <span>SKU: {item.projectSKU}</span>
                          )}
                          {!item.projectSKU && (
                            <>
                              {item.projectTypes.map((projectType) => (
                                <div>{`${I18n.get("Projeto")} - ${I18n.get(
                                  ProjectTypeNames[projectType]
                                )}`}</div>
                              ))}
                            </>
                          )}
                        </td>
                        <td>
                          {I18n.get(BPCommissionTypeNames[item.commissionType])}
                        </td>
                        <td>
                          {item.commissionType == BPCommissionType.Perc && (
                            <>{item.commissionValue}%</>
                          )}

                          {item.commissionType == BPCommissionType.Flat && (
                            <>
                              <FormatCurrency amount={item.commissionValue} />
                            </>
                          )}
                        </td>
                        <td style={{ fontSize: "90%" }}>
                          <div>
                            <span style={{ color: "#696969" }}>
                              {I18n.get("Remuneração Participação")}: &nbsp;
                            </span>

                            {I18n.get(
                              BPCompensationTypeNames[item.compensationType]
                            )}
                          </div>
                          {item.compensationType != BPCommissionType.None && (
                            <>
                              <div>
                                <span style={{ color: "#696969" }}>
                                  {I18n.get("Remuneração Custo 2º Lugar")}:
                                  &nbsp;
                                </span>

                                {item.compensationType ==
                                  BPCommissionType.Perc && (
                                  <>{item.compensation2nd}%</>
                                )}

                                {item.compensationType ==
                                  BPCommissionType.Flat && (
                                  <>
                                    <FormatCurrency
                                      amount={item.compensation2nd}
                                    />
                                  </>
                                )}
                              </div>
                              <div>
                                <span style={{ color: "#696969" }}>
                                  {I18n.get("Remuneração Custo 3º Lugar")}:
                                  &nbsp;
                                </span>

                                {item.compensationType ==
                                  BPCommissionType.Perc && (
                                  <>{item.compensation3rd}%</>
                                )}

                                {item.compensationType ==
                                  BPCommissionType.Flat && (
                                  <>
                                    <FormatCurrency
                                      amount={item.compensation3rd}
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <Button
                            type="link"
                            onClick={this.removeCustomProjectRule.bind(
                              this,
                              item
                            )}
                            title={I18n.get("Remover Regra de Projeto")}
                          >
                            <Icon type="delete" />
                          </Button>
                        </td>
                      </tr>
                    ))}

                  {customExtraRules &&
                    customExtraRules.map((item) => (
                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          <Tag>{I18n.get("Regra de Extra")}</Tag>
                        </td>
                        <td>
                          {item.projectSKU && (
                            <span>SKU: {item.projectSKU}</span>
                          )}
                          {!item.projectSKU && (
                            <>
                              {item.extraTypes.map((extraType) => (
                                <div>{`${I18n.get("Extra")} - ${I18n.get(
                                  ProjectExtraTypeNames[extraType]
                                )}`}</div>
                              ))}
                            </>
                          )}
                        </td>
                        <td>
                          {I18n.get(BPCommissionTypeNames[item.commissionType])}
                        </td>
                        <td>
                          {item.commissionType == BPCommissionType.Perc && (
                            <>{item.commissionValue}%</>
                          )}

                          {item.commissionType == BPCommissionType.Flat && (
                            <>
                              <FormatCurrency amount={item.commissionValue} />
                            </>
                          )}
                        </td>
                        <td></td>
                        <td style={{ textAlign: "right" }}>
                          <Button
                            type="link"
                            onClick={this.removeCustomExtraRule.bind(
                              this,
                              item
                            )}
                            title={I18n.get("Remover Regra de Extra")}
                          >
                            <Icon type="delete" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                    {customBPayRules &&
                    customBPayRules.map((item) => (
                      <tr>
                        <td style={{ verticalAlign: "middle" }}>
                          <Tag>{I18n.get("Regra de Bpay")}</Tag>
                        </td>
                        <td>
                          {item.sku && (
                            <span>SKU: {item.sku}</span>
                          )}
                          
                        </td>
                        <td>
                          {I18n.get(BPCommissionTypeNames[item.commissionType])}
                        </td>
                        <td>
                          {item.commissionType == BPCommissionType.Perc && (
                            <>{item.commissionValue}%</>
                          )}

                          {item.commissionType == BPCommissionType.Flat && (
                            <>
                              <FormatCurrency amount={item.commissionValue} />
                            </>
                          )}
                        </td>
                        <td></td>
                        <td style={{ textAlign: "right" }}>
                          <Button
                            type="link"
                            onClick={this.removeCustomBpayRule.bind(
                              this,
                              item
                            )}
                            title={I18n.get("Remover Regra de Extra")}
                          >
                            <Icon type="delete" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Button
                className="button bt-bpool black"
                onClick={(e) => this.setState({ showCustomProjectForm: true })}
              >
                {I18n.get("Nova Regra de Projeto")}
              </Button>
              &nbsp;&nbsp;
              <Button
                className="button bt-bpool black"
                onClick={(e) => this.setState({ showCustomExtraForm: true })}
              >
                {I18n.get("Nova Regra de Extra")}
              </Button>

              &nbsp;&nbsp;
              <Button
                className="button bt-bpool black"
                onClick={(e) => this.setState({ showCustomBpayForm: true })}
              >
                {I18n.get("Nova Regra de Bpay")}
              </Button>
            </div>
          </div>
        </div>

        <Modal
          isOpen={!!this.state.showCustomProjectForm}
          closeModal={this.closeModal}
        >
          <CustomProjectRuleForm {...this.props} closeModal={this.closeModal} />
        </Modal>

        <Modal
          isOpen={!!this.state.showCustomExtraForm}
          closeModal={this.closeModal}
        >
          <CustomExtraRuleForm {...this.props} closeModal={this.closeModal} />
        </Modal>


        <Modal
          isOpen={!!this.state.showCustomBpayForm}
          // isOpen={true}
          closeModal={this.closeModal}
        >
          <CustomBpayForm {...this.props} closeModal={this.closeModal} />
        </Modal>
      </>
    );
  }
}

export default Card;
