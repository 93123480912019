import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";

import * as S from "./styles";

export const ButtonsSelects = ({ data, defaultValues, isSingle, onChange }) => {
  const [activedSubs, setActivedSubs] = useState([]);

  const handleClickSub = ({ addOrDelete }) => {
    const finded = activedSubs.find((item) => item === addOrDelete);
    if (!isSingle) {
      if (finded) {
        onChange(activedSubs.filter((item) => item !== addOrDelete));
        setActivedSubs(activedSubs.filter((item) => item !== addOrDelete));
      } else {
        onChange([...activedSubs, addOrDelete]);
        setActivedSubs([...activedSubs, addOrDelete]);
      }
    } else {
      onChange(finded ? null : addOrDelete);
      setActivedSubs(finded ? [] : [addOrDelete]);
    }
  };

  const isActiveSub = (code) => {
    const finded = activedSubs.find((item) => item === code);

    return finded ? true : false;
  };

  useEffect(() => {
    if (defaultValues && defaultValues?.length) {
      setActivedSubs(defaultValues);
    }
  }, [defaultValues]);

  return (
    <S.WrapperButtonsSelects>
      <ul>
        {data?.map((item) => (
          <li key={item?.code}>
            <S.ButtonSub
              active={isActiveSub(item?.code)}
              onClick={() =>
                handleClickSub({
                  addOrDelete: item?.code,
                })
              }
            >
              <span>{item?.label}</span>
              <PlusOutlined />
            </S.ButtonSub>
          </li>
        ))}
      </ul>
    </S.WrapperButtonsSelects>
  );
};
