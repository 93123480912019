import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Form, Icon, Spin, Input, Select, Button } from "antd";
import { MaskedInput } from "antd-mask-input";

import styled from "styled-components";

const { Option } = Select;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`;

const FormBox = styled(Form)``;

class FormProjectAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          ...values,
          registrationId:
            values.signupLocale != "pt"
              ? values.registrationId.replace(/[\/.]/g, "")
              : values.registrationId,
        };

        this.setState({
          formState: data,
        });

        this.props.action(data);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (this.props.errors != prevProps.errors && this.props.errors) {
      Object.keys(this.props.errors).map((field) => {
        this.props.form.setFields({
          [field]: {
            value: this.state.formState[field],
            errors: [new Error(this.props.errors[field].errorMessage)],
          },
        });
      });
    }
  }

  render() {
    const {
      getFieldDecorator,
      getFieldValue,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;

    return (
      <FormBox onSubmit={this.handleSubmit}>
        <Row>
          <Col sm={12}>
            <Form.Item label={I18n.get("Região")}>
              {getFieldDecorator("signupLocale", {
                rules: [
                  { required: false, message: I18n.get("Campo obrigatório") },
                ],
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option className="SelectMin" value="pt">
                    {I18n.get("Brasil")}
                  </Option>
                  <Option className="SelectMin" value="es">
                    {I18n.get("LATAM")}
                  </Option>
                  <Option className="SelectMin" value="en">
                    {I18n.get("EUA")}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={12}>
            {getFieldValue("signupLocale") == "pt" && (
              <Form.Item label={I18n.get("CNPJ")}>
                {getFieldDecorator("registrationId", {
                  rules: [
                    { required: false, message: I18n.get("Campo obrigatório") },
                  ],
                })(<MaskedInput mask={"00.000.000/0000-00"} />)}
              </Form.Item>
            )}
            {getFieldValue("signupLocale") != "pt" && (
              <Form.Item label={I18n.get("CNPJ")}>
                {getFieldDecorator("registrationId", {
                  rules: [
                    { required: false, message: I18n.get("Campo obrigatório") },
                  ],
                })(<Input />)}
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Item label={I18n.get("Empresa")}>
              {getFieldDecorator("companyName", {})(<Input />)}
              <p style={{ fontSize: 12, color: "#f00" }}>
                {this.props.errors &&
                  this.props.errors?.companyName?.errorMessage}
              </p>
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item label={I18n.get("Responsável")}>
              {getFieldDecorator("responsableName", {})(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Item label={I18n.get("E-mail")}>
              {getFieldDecorator("responsableEmail", {})(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={12}>
            <Form.Item className="labelClean" label="&nbsp;">
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                loading={this.props.task.busy}
              >
                {I18n.get("Cadastrar")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </FormBox>
    );
  }
}

const FormFormProjectAdmin = Form.create({})(FormProjectAdmin);

export default FormFormProjectAdmin;
