import React from 'react';
import { I18n } from 'aws-amplify';
import { Link, NavLink } from 'react-router-dom';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import ProjectDetails from './project-details';
import ReviewForm from '../shared/review-form';

import { ClientProjectStatus } from "../../model";

import Steps from './steps';

import { scrollToTop } from '../../utils/ui';


class ProjectOnGoingSummary extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    componentDidMount() {

        scrollToTop();
    }

    submitForm(e) {

    }

    render() {

        const { project = {} } = this.props;

        const quotation = this.props.eventQuotation || {};

        return (
            <FillForm>
                <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">

                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-6" style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
                                <p className="title">{I18n.get("Projeto em Andamento")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-6">
                                {this.props.isCreative && <>
                                    <Steps active={1} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []} />
                                </>}
                                {this.props.isPartnerSelectionBidding && <>
                                    <Steps active={1} project={this.props.project} hidden={this.props.auth.is_partner ? [5] : []} />
                                </>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="client-step client-step-1 project-ongoing">

                    <div className="container client-container-1 project-ongoing-review" style={{ paddingBottom: "0" }}>


                        {this.props.isCreative && <>

                            <ProjectDetails {...this.props} />

                        </>}

                        {this.props.isPartnerSelectionBidding && <>

                            <div style={{ textAlign: 'right' }}>

                            {project.status == ClientProjectStatus.Started &&
                                this.props.auth.is_client_or_client_user && (
                                    <div className="field is-narrow">
                                    {" "}
                                    <NavLink
                                        to={`/projetos/${this.props.match.params.projectId}/finalizar`}
                                        className="button bt-bpool black"
                                    >
                                        {I18n.get("Finalizar projeto")}
                                    </NavLink>
                                    </div>
                            )}

                            </div>

                            <ReviewForm ref="form" {...this.props} />

                        </>}

                        <br />
                        <br />


                    </div>


                    <div className="columns">

                        <div className="column">
                        </div>

                        <div className="column">

                            <div className="field is-grouped is-grouped-centered container-content p-btns">
                                <p className="control is-expanded" onClick={this.scrollToTop}>
                                    <Link to={`/projetos/${this.props.match.params.projectId}/briefing`} className="button bt-bpool black is-fullwidth">{I18n.get("PRÓXIMO")}</Link>
                                </p>
                            </div>

                        </div>

                        <div className="column">
                        </div>


                    </div>





                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(ProjectOnGoingSummary);
