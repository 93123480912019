import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Switch } from "react-router-dom";

import { Application } from "../../pages/RegistrationFlow/Application";
import { ApplicationReceived } from "../../pages/RegistrationFlow/ApplicationReceived";
import { ApplicationReceivedLogin } from "../../pages/RegistrationFlow/ApplicationReceivedLogin";
import { AboutTheCompany } from "../../pages/RegistrationFlow/AboutTheCompany";
import { DiversityAndInclusion } from "../../pages/RegistrationFlow/DiversityAndInclusion";
import { Industry } from "../../pages/RegistrationFlow/Industry";
import { BusinessType } from "../../pages/RegistrationFlow/BusinessType";
import { SpecialityProof } from "../../pages/RegistrationFlow/SpecialityProof";
import { SpecialityProofAndEndersement } from "../../pages/RegistrationFlow/SpecialityProofAndEndersement";
import { ReviewPage } from "../../pages/RegistrationFlow/ReviewPage";
import { Login } from "../../pages/RegistrationFlow/Login";
import { VerifyPreRegistry } from "../../pages/RegistrationFlow/VerifyPreRegistry";

// FULL
import { CompanyProfile } from "../../pages/RegistrationFlow/Full/CompanyProfile";
import { CompanyDetails } from "../../pages/RegistrationFlow/Full/CompanyDetails";
import { DiversityAndInclusion as DiversityAndInclusionFull } from "../../pages/RegistrationFlow/Full/DiversityAndInclusion";
import { IndustryFull } from "../../pages/RegistrationFlow/Full/Industry";
import { Customers } from "../../pages/RegistrationFlow/Full/Customers";
import { TeamRegistration } from "../../pages/RegistrationFlow/Full/TeamRegistration";
import { BusinessType as BusinessTypeFull } from "../../pages/RegistrationFlow/Full/BusinessType";
import { MyProfile } from "../../pages/RegistrationFlow/Full/MyProfile";
import { Resume } from "../../pages/RegistrationFlow/Full/Resume";
import { SpecialityProofAndEndersement as SpecialityProofAndEndersementFull } from "../../pages/RegistrationFlow/Full/SpecialityProofAndEndersement";
import { Completed } from "../../pages/RegistrationFlow/Full/Completed";
import { Awards } from "../../pages/RegistrationFlow/Full/Awards";

//USER
import { AboutYou as UserAboutYou } from "../../pages/RegistrationFlow/User/AboutYou";
import { Completed as UserCompleted } from "../../pages/RegistrationFlow/User/Completed";

//LEADER
import { AboutYou as LeaderAboutYou } from "../../pages/RegistrationFlow/Leader/AboutYou";
import { CasesAndAwards as LeaderCasesAndAwards } from "../../pages/RegistrationFlow/Leader/CasesAndAwards";
import { Resume as LeaderResume } from "../../pages/RegistrationFlow/Leader/Resume";
import { LeaderIndustry } from "../../pages/RegistrationFlow/Leader/Industry";
import { LeaderCompleted } from "../../pages/RegistrationFlow/Leader/Completed";

const queryClient = new QueryClient();

function RegistrationFlow() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route
            exact
            path="/registration-flow/application"
            component={Application}
          />
          <Route exact path="/registration-flow/login" component={Login} />
          <Route
            exact
            path="/registration-flow/verify/:token"
            component={VerifyPreRegistry}
          />
          <Route
            exact
            path="/registration-flow/application/received"
            component={ApplicationReceived}
          />
          <Route
            exact
            path="/registration-flow/application/received/login"
            component={ApplicationReceivedLogin}
          />
          <Route
            exact
            path="/registration-flow/about-the-company"
            component={AboutTheCompany}
          />
          <Route
            exact
            path="/registration-flow/diversity-and-inclusion"
            component={DiversityAndInclusion}
          />
          <Route
            exact
            path="/registration-flow/industry"
            component={Industry}
          />
          <Route
            exact
            path="/registration-flow/business-type"
            component={BusinessType}
          />
          <Route
            exact
            path="/registration-flow/speciality-proof-and-endersement"
            component={SpecialityProofAndEndersement}
          />
          <Route
            exact
            path="/registration-flow/speciality-proof"
            component={SpecialityProof}
          />
          <Route
            exact
            path="/registration-flow/review"
            component={ReviewPage}
          />

          <Route
            exact
            path="/registration-flow/full/company-profile"
            component={CompanyProfile}
          />
          <Route
            exact
            path="/registration-flow/full/company-details"
            component={CompanyDetails}
          />
          <Route
            exact
            path="/registration-flow/full/diversity-and-inclusion"
            component={DiversityAndInclusionFull}
          />
          <Route
            exact
            path="/registration-flow/full/industry"
            component={IndustryFull}
          />
          <Route
            exact
            path="/registration-flow/full/customers"
            component={Customers}
          />
          <Route
            exact
            path="/registration-flow/full/team-registration"
            component={TeamRegistration}
          />
          <Route
            exact
            path="/registration-flow/full/business-type"
            component={BusinessTypeFull}
          />
          <Route
            exact
            path="/registration-flow/full/my-profile"
            component={MyProfile}
          />
          <Route
            exact
            path="/registration-flow/full/resume"
            component={Resume}
          />
          <Route
            exact
            path="/registration-flow/full/speciality-proof-and-endersement"
            component={SpecialityProofAndEndersementFull}
          />
          <Route
            exact
            path="/registration-flow/full/awards"
            component={Awards}
          />
          <Route
            exact
            path="/registration-flow/full/completed"
            component={Completed}
          />

          <Route
            exact
            path="/registration-flow/user/about-you/:token"
            component={UserAboutYou}
          />
          <Route
            exact
            path="/registration-flow/user/completed"
            component={UserCompleted}
          />

          <Route
            exact
            path="/registration-flow/leader/about-you/:token"
            component={LeaderAboutYou}
          />

          <Route
            exact
            path="/registration-flow/leader/industry/:token"
            component={LeaderIndustry}
          />

          <Route
            exact
            path="/registration-flow/leader/cases-and-awards/:token"
            component={LeaderCasesAndAwards}
          />
          <Route
            exact
            path="/registration-flow/leader/resume/:token"
            component={LeaderResume}
          />

          <Route
            exact
            path="/registration-flow/leader/completed"
            component={LeaderCompleted}
          />
        </Switch>
      </QueryClientProvider>
    </>
  );
}

export default RegistrationFlow;
