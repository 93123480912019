import styled from "styled-components";

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const InfoError = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;

  &.errorRating {
    position: relative;
    top: -20px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const TitleSectionForm = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
  width: max-content;
`;

export const TitleRate = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-bottom: 10px;
  width: 100%;
`;

export const WrapperRate = styled.div`
  padding: 15px 20px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  width: max-content;
  border-radius: 30px;
`;

export const LoadingPage = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
`;

export const InfosConfirm = styled.div``;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  margin-top: 30px;
  text-align: center;
`;

export const ContentLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

export const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  color: #979797;
`;
