import React, { PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';


const TitleH2Border = styled.h2`
    color: #000000;
    font-size: 25px;
    font-weight: 300;
    line-height: 32px;
    position: relative;
    display: block;
    height: 50px;
    margin-bottom: 30px;

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 80px;
        height: 8px;
        background-color: #353535;
    }
`;

const TitleH3 = styled.h3`
    color: #353535;
    font-size: 23px;
    font-weight: 600;
    line-height: 37px;
`;

const TitleH3Medium = styled.h3`
    color: #353535;
    font-size: 18px;
    font-weight: 700;
    line-height: 37px;
`;

const TitleH4 = styled.h4`
    color: #353535;
    font-size: 15px;
    font-weight: 700;
    line-height: 37px;
`;

const TitleH4Line = styled.h4`
    color: #353535;
    font-size: 15px;
    font-weight: 700;
    line-height: 37px;
    border-bottom: solid 1px #000;
    margin-bottom: 20px;
`;



export class TitleBorder extends PureComponent {
    render() {
        return <TitleH2Border>{this.props.children}</TitleH2Border>;
    }
}

export class TitleSimple extends PureComponent {
    render() {
        return <TitleH3>{this.props.children}</TitleH3>;
    }
}

export class TitleMiddle extends PureComponent {
    render() {
        return <TitleH4>{this.props.children}</TitleH4>;
    }
}

export class TitleMiddleLine extends PureComponent {
    render() {
        return <TitleH4Line>{this.props.children}</TitleH4Line>;
    }
}

export class TitleMedium extends PureComponent {
    render() {
        return <TitleH3Medium>{this.props.children}</TitleH3Medium>;
    }
}
