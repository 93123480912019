import React from 'react';

import { I18n } from 'aws-amplify';

import TooltipField from './tooltip-field';

//import './upload-field.css';

export default class UploadField extends React.Component {

    constructor(props) {
        super();

        this.state = {
            focused: false,
            visited: props.visited
        };
    }

    static defaultProps = {
        successIcon: true
    }

    onBlur() {
        this.setState({ focused: false });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onFocus() {
        this.setState({ focused: true, visited: true });
    }

    render() {

        const className = this.props.className || 'field';
        const inputClassName = this.props.inputClassName || 'file-input';

        const isError = !this.state.focused && this.state.visited && this.props.error;

        const isValid = !!this.props.value && !this.props.error;

        const icon = this.props.uploading ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fas fa-upload"></i>);
        //const label = this.props.uploading ? 'Aguarde...' : '';

        return (<div className={className}>
            <label className="label is-small">
                {this.props.label} <TooltipField {...this.props} />
            </label>
            <div className="file has-name is-fullwidth">
                <label className="file-label is-small">

                    <input
                        onChange={this.props.onChange}
                        onBlur={() => this.onBlur()}
                        onFocus={() => this.onFocus()}
                        className={isError ? inputClassName + " is-danger" : inputClassName}
                        type="file"
                        placeholder={this.props.placeholder || ''}
                        readOnly={this.props.readOnly || false}
                        disabled={this.props.disabled || false}
                        style={{ display: 'none' }}
                    />
                    <span style={{ padding: '0.5rem' }}>
                            {icon}
                    </span>
                    {this.props.value && <span style={{ padding: '0.5rem', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        {this.props.value}
                    </span>}
                </label>
            </div>
            {isError && <p className="help is-danger">
                {this.props.error.errorMessage}
            </p>}
        </div>)
    }
}

