import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import Main from "../../../../components/UI/Main";
import { DataBank } from "../../../../components/Shared/DataBank";

import * as S from "./styles";

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  let location = useLocation();
  let params = useParams();
  const state = useSelector((state) => state);
  const {
    getBillingData,
    addBillingData,
    updateBillingData,
    deleteBillingData,
  } = useRegistrationFlowFull();
  const [isLoading, setIsLoading] = useState(false);
  const [dataBilling, setDataBilling] = useState([]);

  const prioritizeMain = (array) => {
    if (array?.length) {
      array.sort((a, b) => {
        if (a.isMain && !b.isMain) {
          return -1;
        } else if (!a.isMain && b.isMain) {
          return 1;
        }
        return 0;
      });
      return array;
    }
  };

  const loadInit = async () => {
    try {
      setIsLoading(true);
      const response = await getBillingData({
        admin: contextPage === "admin" ? true : false,
        partnerId: partnerId,
      });
      const sortData = prioritizeMain(response?.data?.data);
      setDataBilling(sortData);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getParameterValue = (search, parameterName) => {
    const params = new URLSearchParams(search);
    return params.get(parameterName) || "";
  };

  useEffect(() => {
    loadInit();
  }, []);

  return (
    <>
      <S.Wrapper>
        <Main bgColor="#fff" padding="30px">
          <Row>
            <Col xs={24}>
              <S.RowCenter>
                <S.TitleWithIcon>
                  <S.H4>{I18n.get("Billing Data")}</S.H4>
                </S.TitleWithIcon>
                <S.Paragraph>
                  {I18n.get("Acompanhe suas contas cadastradas")}
                </S.Paragraph>
              </S.RowCenter>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <DataBank
                data={dataBilling}
                isLoading={isLoading}
                countryCode={
                  state?.partner?.country?.code
                    ? state?.partner?.country?.code
                    : "BRA"
                }
                handleRefresh={loadInit}
                isAdmin={contextPage === "admin" ? true : false}
                addBillingData={addBillingData}
                updateBillingData={updateBillingData}
                deleteBillingData={deleteBillingData}
              />
            </Col>
          </Row>
        </Main>
      </S.Wrapper>
    </>
  );
};
