import React from 'react';

import { I18n } from 'aws-amplify';

export default class PlusButtonField extends React.Component {

    constructor(props) {
        super();
    }

    static defaultProps = {
        successIcon: true,
        className: 'button is-rounded is-black',
    }

    render() {

        const is_processing = this.props.task && this.props.task.is_processing;

        const className = is_processing ? [this.props.className, "is-loading"].join(' ') : this.props.className;

        return (

            <div className="field is-narrow">
                <label className="label is-small">
                    &nbsp;
                </label>
                <p className="control">
                    <a onClick={this.props.onClick} className={className}>
                        <span className="icon is-small">
                            <i className="fa fa-plus"></i>
                        </span>
                    </a>
                </p>
            </div>);
    }
}

