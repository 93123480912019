import React from "react";

import { I18n } from "aws-amplify";

import "./quotation-asset-form.css";

import NumberFormat from "react-number-format";

import { Task } from "../../utils/task";

import SelectSearchField from "../form/select-search-field";

import ReactTooltip from "react-tooltip";

import FormatCurrency from "../ui/format-currency";

import iconRateCart from "../../image/ratecart.png";
import iconAssets from "../../image/ico-assets.png";
import iconHoraHomem from "../../image/ico-hourman.png";

import { Radio, Icon, Table } from "antd";

const BRL = ({ amount }) => (
  <NumberFormat
    value={amount}
    displayType={"text"}
    thousandSeparator="."
    decimalSeparator=","
    prefix={"R$ "}
    decimalScale={2}
  />
);

const CustomOption = React.memo(({ innerProps, data }) => {
  const asset = data.data;

  const props = asset.isDisabled
    ? { className: "is-disable" }
    : { ...innerProps };

  return (
    <div {...props} className="custom-option">
      <div style={{ display: "flex", fontSize: "80%", alignItems: "center" }}>
        <div style={{ flex: "2" }}>
          <p data-tip={asset.description} data-for={data.value}>
            {data.label}
          </p>
          <p style={{ fontSize: "80%", opacity: ".75" }}>
            {asset.typeLabel && asset.typeLabel.join(", ")}
          </p>
          <ReactTooltip
            id={data.value}
            place="right"
            type="dark"
            effect="solid"
          />
        </div>
        <div style={{ flex: "1" }} className="has-text-centered">
          <span>
            {asset.isRateCard && (
              <img src={iconRateCart} style={{ width: "20px" }} />
            )}
            {asset.isManHour && (
              <img src={iconHoraHomem} style={{ width: "20px" }} />
            )}

            {!asset.isManHour && !asset.isRateCard && (
              <img src={iconAssets} style={{ width: "20px" }} />
            )}
          </span>
        </div>
        <div style={{ flex: "1" }} className="has-text-centered">
          1
        </div>
        <div style={{ flex: "1" }} className="has-text-right">
          {/* <BRL amount={asset.price} /> &nbsp;&nbsp;&nbsp; */}
          <FormatCurrency amount={asset.price} currency={asset.currency} />
        </div>
      </div>
    </div>
  );
});

class QuotationItemForm extends React.Component {
  constructor(props) {
    super();

    let assetsClean = props?.assetsCombo.filter(
      (item) => item.isRateCard == false && item.isManHour == false
    );
    let assetsRateCart = props?.assetsCombo.filter(
      (item) => item.isRateCard === true
    );
    let assetsHourMan = props?.assetsCombo.filter(
      (item) => item.isManHour === true
    );

    let assets = props?.assetsCombo;

    this.state = {
      fields: {
        assetType: {
          label: I18n.get("Tipo"),
          value: [],
          defaultValue: [],
          checked: false,
          successIcon: false,
        },
        assetId: {
          label: "",
          value: "",
          onChange: this.onChangeAssetId.bind(this),
          successIcon: false,
          visited: true,
          components: { Option: CustomOption },
        },
        quantity: {
          onChange: this.onChangeForm.bind(this, "quantity"),
          successIcon: false,
          visited: true,
        },
        items: [],
      },
      form: {
        assetType: [],
        quantity: 1,
        items: [],
      },
      assets: assets,
      assetsHourMan: assetsHourMan,
      assetsRateCart: assetsRateCart,
      assetsClean: assetsClean,
      task: {},
      errors: {},
      showAssetList: false,
      filterNumber: 1,
    };
  }

  onChangeAssetTypeCheckbox(code, e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState, { page: 1 });

      nextState.form["assetType"] = prevState.form["assetType"].filter(
        (item) => item != code
      );

      if (checked) {
        nextState.form["assetType"].push(code);
      }

      //nextState.assetTypeShowAll = nextState.form['assetType'].value.length == 0;

      return nextState;
    });
  }

  onChangeAssetId(value) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["assetId"] = value;

      return nextState;
    });
  }

  delItem = (item, e) => {
    e.preventDefault();

    const data = {
      projectId: this.props.match.params.projectId,
      partnerId: this.props.partner.id,
      ...item,
    };

    this.props.delete_universal_quotation_asset(data);
  };

  changeQuantity = (item, e) => {
    const value = e.target.value;

    // const data = {
    //     projectId: this.props.match.params.projectId,
    //     partnerId: this.props.partner.id,
    //     ...item,
    //     quantity: value
    // }

    // this.props.updateEventQuotationAsset(data);
  };

  addItem = (e) => {
    e.preventDefault();

    const value = this.state.form.assetId;

    if (!value) return;

    const asset = this.props.project.assetsCombo.find(
      (item) => item.code == value
    );

    const data = {
      projectId: this.props.match.params.projectId,
      quotationId: this.props.quotation.id,
      sectionId: this.props.section.id,
      items: [
        {
          code: value,
          price: asset.price,
          quantity: 1,
        },
      ],
    };

    var task = new Task();

    task.start();

    this.props
      .create_universal_quotation_asset(data)
      .then(({ task, errors }) => {
        this.setState({ submit_task: task, errors: errors });

        if (task.result) {
          this.props.closeModal();

          this.setState((prevState) => {
            var nextState = Object.assign({}, prevState);

            nextState.form["assetId"] = null;

            return nextState;
          });
        }
      });
  };

  addItems = (e) => {
    e.preventDefault();

    const items = [];

    for (let i = 0; i < this.state.form.items.length; i++) {
      const value = this.state.form.items[i];

      const asset = this.props.project.assetsCombo.find(
        (item) => item.code == value
      );

      const item = {
        code: value,
        price: asset.price,
        quantity: 1,
      };

      items.push(item);
    }

    const data = {
      projectId: this.props.match.params.projectId,
      quotationId: this.props.quotation.id,
      sectionId: this.props.section.id,
      items: items,
    };

    this.props
      .create_universal_quotation_asset(data)
      .then(({ task, errors }) => {
        this.setState({ submit_task: task, errors: errors });

        if (task.result) {
          this.props.closeModal();
        }
      });
  };

  showAssetList(value) {
    this.setState({ showAssetList: value });
  }

  onChangeFormArrayToggle(prop, value) {
    this.setState((prevState) => {
      let arr = prevState.form[prop] || [];

      if (arr.indexOf(value) > -1) {
        arr = prevState.form[prop].filter((item) => item !== value);
      } else {
        arr = [...arr, value];
      }

      return {
        form: {
          ...prevState.form,
          [prop]: arr,
        },
      };
    });
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  setFilterNumber = (e) => {
    const value = e.target.value;

    switch (value) {
      case 1:
        this.setState({
          assets: this.props.project.assetsCombo,
          filterNumber: 1,
        });
        break;

      case 2:
        this.setState({ assets: this.state.assetsRateCart, filterNumber: 2 });
        break;
      case 3:
        this.setState({ assets: this.state.assetsHourMan, filterNumber: 3 });
        break;
      case 4:
        this.setState({ assets: this.state.assetsClean, filterNumber: 4 });
        break;

      default:
        break;
    }
  };

  render() {
    const quotation = this.props.quotation || {};
    const currency = this.props.currency || {};

    //if (quotation.internalType != ClientProjectEventQuotationInternalType.Asset) return null;

    const canEdit = this.props.auth.is_partner && quotation.isOpen;

    const assetTypes =
      this.props.project.assetTypes &&
      this.props.project.assetTypes
        .filter(
          (item) => item.code == "PL" || item.code == "GE" || item.code == "CR"
        )
        .sort((a, b) => (a.label > b.label) - (a.label < b.label));

    let assets = this.state.assets;

    let assetsHourMan = assets.filter((item) => {
      if (item.isManHour == true) {
        return item;
      }
    });

    // if (this.state.form.assetType.length) {

    //     assets = assets && assets.filter((item) => {

    //         const aCount = this.state.form.assetType.length;

    //         for (var a = 0; a < aCount; a++) {

    //             const value = this.state.form.assetType[a];

    //             if (item.type.indexOf(value) > -1) {

    //                 return true;
    //             }
    //         }

    //         return false;
    //     });
    // }

    assets =
      assets &&
      assets.map((asset) => {
        asset.isDisabled = !!(
          quotation.assets &&
          quotation.assets.find((item) => item.code === asset.code)
        );
        asset.currency = this.props.project.currency;

        return { code: asset.code, label: asset.label, data: asset };
      });

    const columns = [
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (text, record) => {
          const checked =
            this.state.form.items &&
            this.state.form.items.find((a) => a == record?.item.code);

          return (
            <label className="checkbox">
              <input
                type="checkbox"
                value={record?.item?.code}
                checked={checked}
                onChange={(e) =>
                  this.onChangeFormArrayToggle("items", record?.item.code)
                }
              />
              <span className="checkmark"></span>
            </label>
          );
        },
      },
      {
        title: I18n.get("Asset"),
        dataIndex: "asset",
        key: "asset",
        render: (asset, record) => {
          return (
            <>
              {record?.item.label}
              <span
                style={{
                  marginLeft: 20,
                  position: "relative",
                  top: "-3px",
                }}
              >
                {record?.item.isRateCard && (
                  <img src={iconRateCart} style={{ width: "20px" }} />
                )}
                {record?.item.isManHour && (
                  <img src={iconHoraHomem} style={{ width: "20px" }} />
                )}

                {!record?.item.isManHour && !record?.item.isRateCard && (
                  <img src={iconAssets} style={{ width: "20px" }} />
                )}
              </span>
            </>
          );
        },
      },
      {
        title: I18n.get("Info"),
        dataIndex: "info",
        key: "info",
        render: (info, record) => {
          return (
            <>
              <span
                className="icon"
                data-tip={record?.item?.description}
                data-for={record?.item?.id}
              >
                <Icon type="question-circle" theme="filled" />
              </span>
              <ReactTooltip
                id={record?.item?.id}
                place="right"
                type="dark"
                effect="solid"
              />
            </>
          );
        },
      },
      {
        title: I18n.get("R$"),
        dataIndex: "valor",
        key: "valor",
        render: (valor, record) => {
          return (
            <>
              {/* <BRL amount={record?.item?.price} /> */}
              <FormatCurrency
                amount={record?.item?.price}
                currency={currency}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </>
          );
        },
      },
    ];

    let dataSource =
      this.state.assets &&
      this.state.assets.map((item) => {
        console.log(">>", item);

        return {
          ...item,
          label: item?.label,
          item: item,
        };
      });

    return (
      <section
        className="quotation-asset-form"
        style={{ paddingBottom: "1rem" }}
      >
        <p className="title is-5">{I18n.get("Adicionar Entregável")}</p>

        <form>
          <div className="is-pulled-right">
            {this.state.showAssetList === false && (
              <>
                <a
                  className="button bt-bpool black"
                  onClick={(e) => this.setState({ showAssetList: true })}
                >
                  {I18n.get("Ver Lista Completa")}
                </a>
              </>
            )}
            {this.state.showAssetList === true && (
              <>
                <a
                  className="button bt-bpool black"
                  onClick={(e) => this.setState({ showAssetList: false })}
                >
                  {I18n.get("Ocultar Lista Completa")}
                </a>
              </>
            )}
          </div>

          {(this.state.assetsRateCart.length > 0 ||
            this.state.assetsHourMan.length > 0) && (
            <>
              <div className="filtersAssetsRate">
                <Radio.Group
                  onChange={(e) => this.setFilterNumber(e)}
                  value={this.state.filterNumber}
                >
                  <Radio value={1}>{I18n.get("Todos")}</Radio>
                  {this.state.assetsRateCart.length > 0 && (
                    <Radio value={2}>
                      {I18n.get("Itens Live Marketing")}{" "}
                      <img src={iconRateCart} style={{ width: "20px" }} />
                    </Radio>
                  )}
                  {this.state.assetsHourMan.length > 0 && (
                    <Radio value={3}>
                      {I18n.get("Hora Homem")}{" "}
                      <img src={iconHoraHomem} style={{ width: "20px" }} />
                    </Radio>
                  )}

                  <Radio value={4}>
                    {I18n.get("Assets")}{" "}
                    <img src={iconAssets} style={{ width: "20px" }} />
                  </Radio>
                </Radio.Group>
              </div>

              <div className="legendAssets">
                {this.state.assetsRateCart.length > 0 && (
                  <p>
                    <span className="icone">
                      <img src={iconRateCart} style={{ width: "20px" }} />
                    </span>
                    <span className="text">
                      <b>{I18n.get("Itens Live Marketing")}:</b>{" "}
                      {I18n.get(
                        "Itens pré-precificados para serviços de live marketing, como eventos, ativações, promoções e outros."
                      )}
                    </span>
                  </p>
                )}
                {this.state.assetsHourMan.length > 0 && (
                  <p>
                    <span className="icone">
                      <img src={iconHoraHomem} style={{ width: "20px" }} />
                    </span>
                    <span className="text">
                      <b>{I18n.get("Hora Homem")}:</b>{" "}
                      {I18n.get(
                        "Tabela referencial de valor da hora de profissionais da agência pré-estabelecido pelo cliente."
                      )}
                    </span>
                  </p>
                )}

                <p>
                  <span className="icone">
                    <img src={iconAssets} style={{ width: "20px" }} />
                  </span>
                  <span className="text">
                    <b>{I18n.get("Assets")}:</b>{" "}
                    {I18n.get(
                      "Itens pré-precificados para serviços criativos ou de capital intelectual como um todo."
                    )}
                  </span>
                </p>
              </div>
            </>
          )}

          {this.state.showAssetList === false && (
            <>
              <div>
                <p className="title is-6">{I18n.get("Tipo")}</p>
              </div>

              <br style={{ clear: "both" }} />

              {I18n.get(
                "Digite no campo abaixo para buscar o asset que você procura"
              )}

              <div className="columns" style={{ clear: "both" }}>
                <div className="column is-12">
                  <SelectSearchField
                    {...this.state.fields.assetId}
                    value={this.state.form.assetId}
                    error={this.state.errors.assetId}
                    values={assets}
                  />
                </div>
              </div>

              <br />

              <button
                onClick={(e) => this.addItem(e)}
                className="button bt-bpool is-pulled-right"
              >
                {I18n.get("Adicionar")}
              </button>
            </>
          )}

          {this.state.showAssetList === true && (
            <>
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={{ defaultPageSize: 30 }}
              />
              <button
                onClick={(e) => this.addItems(e)}
                className="button bt-bpool is-pulled-right"
              >
                {I18n.get("Adicionar")}
              </button>
            </>
          )}

          <br />
        </form>
      </section>
    );
  }
}

export default QuotationItemForm;
