import styled from "styled-components";

export const BoxItem = styled.div`
  width: 100%;
  padding: 30px;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
`;



export const BoxReview = styled.div`
  border-radius: 10px;
  margin-top: 15px;
  padding: 25px 15px;
  border: 1px solid;
  border-color: #e7e7e7;

  ul {
    li {
      display: flex;
      align-items: baseline;
      margin: ${(props) =>
        props.spacing === "extra-large" ? "50px 0;" : "10px 0;"};

      :first-child {
        margin-top: 0;
      }

      :last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        padding-left: 5px;
      }
    }
  }
`;


export const AttachmentSelected = styled.div`
  color: #1883ff;
  font-size: 16px;
  line-height: 24px;
  margin: 5px 0 0 10px;
  width: 100%;
  border-bottom: solid 1px #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 14px;
    color: #1883ff;
    padding: 5px 0;

    &:hover {
      color: #000;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const BoxItemTitle = styled.div`
  margin-bottom: 30px;

  p {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  }

  span {
    display: block;
    width: 140px;
    height: 5px;
    background-color: #000;
    margin-top: 5px;
  }
`;

export const MsgLatam = styled.div`
  margin: 20px 0px;
  color: #fff;
  background-color: #f8c6c6;
  padding: 15px 15px 15px;
  border-radius: 10px;
  text-align: left;
  color: #000;
`;

export const ItemsText = styled.div`
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 5px 10px; */
`;

export const ItemText = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 30px;

  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  span {
    line-height: 20px;
    font-size: 14px;
    margin-left: 5px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 26px;

  .blue {
    color: #1883ff;
  }

  .strong {
    font-weight: 700;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;

export const CreativeScopeHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const IcoRow = styled.div`
  display: flex;
  margin-top: 30px;
`;

export const Ico = styled.div`
  width: 30px;
`;

export const IcoInfos = styled.div`
  margin-left: 15px;
  width: calc(100% - 45px);

  p {
    font-size: 14px;
    line-height: 22px;

    span {
      font-weight: 600;
    }

    .small {
      font-weight: 400;
      font-size: 11px;
    }
  }
`;

export const AdditionalInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  background: #eeeeee;
  padding: 30px;
  border-radius: 10px;
`;

export const AdditionalInfosTexts = styled.div`
  margin: 15px 0;

  p {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  p.strong {
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    font-weight: 600;
  }
`;

export const AdditionalInfosAction = styled.div``;

export const StatusOrSendStatus = styled.div``;

export const StatusInvite = styled.div``;

export const SubMessageStatus = styled.div`
  font-size: 12px;
  margin-top: 5px;
`;

export const MessageStatus = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;

  span {
    font-weight: 400;
  }

  span.titleLabel {
    font-weight: 600;
    margin-right: 5px;
  }

  img {
    margin-right: 5px;
  }
`;

export const ContentCheckbox = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  margin-top: 25px;

  label {
    margin: 0;

    span {
      .ant-checkbox-inner {
        border-radius: 2px;
        border: solid 1px;
      }
    }
  }
`;

export const LabelCheckbox = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
`;

export const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ReasonTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 15px 0;
`;

export const MessageAccept = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
  }
`;

export const RowFooter = styled.div`
  margin-top: 60px;
  padding-top: 50px;
  border-top: solid 1px #000;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsFooter = styled.div`
  display: flex;
  .btnPrev {
    margin-right: 20px;
  }
`;
