import React from "react";

import { I18n } from "aws-amplify";

import FormatCurrency from "../../../../components/ui/format-currency";

import * as S from "./styles";

import { Collapse } from "antd";

const { Panel } = Collapse;

const TableBillingItem = ({
  billing,
  currency,
  paymentTermDays,
  category,
  project,
}) => {
  const { projectPO, billingDate, invoiceTo, invoiceType, availableAmount } =
    billing;

  const { bPoolOffice, invoiceTarget } = project;


    let invoiceToName;
    if (!invoiceTarget || invoiceTarget.length == 0 || invoiceTarget.toUpperCase() == "CLIENT") {
      invoiceToName = `${billing?.registrationName} (${billing?.registrationId})`
    } else {
      invoiceToName = `${bPoolOffice?.registrationName} (${bPoolOffice?.registrationId})` 
    }

  return (
    <>
      <S.WrapperTable>
        <S.ItemList>
          {I18n.get("Billing amount")}:{" "}
          <strong>
            <FormatCurrency amount={availableAmount} currency={currency} />
          </strong>
        </S.ItemList>
        <S.ItemList>
          {I18n.get("Type of Invoice")}: <strong>{invoiceType}</strong>
        </S.ItemList>
        {/* <S.ItemList>
        {I18n.get("Tax number")}: <strong>CMS #todo</strong>
      </S.ItemList> */}
        <S.ItemList>
          {I18n.get("Invoice to")}:{" "}
          <strong>{invoiceToName}</strong>
          <div className="invoiceResume">
            <div className="topResume">
              <p>
                <strong>{I18n.get("Informaçoes para faturamento")}</strong>
              </p>
              <p>
                <b>{I18n.get("Client PO number")}:</b> {billing?.projectPO}
              </p>
              <p>
                <b>{I18n.get("Brand/Project")}:</b> {project?.brandName} /{" "}
                {project?.projectName}
              </p>
            </div>

            {/*
            #############################################################################
            #############  Detalhes de pagamento e inserçao de NF  ######################
            #############################################################################
            */}

            {/* BPOOL INC */}
            {bPoolOffice?.id == "bpool_inc" && (
              <div className="resume">
                <p>
                  <strong>
                  Invoice instructions
                  </strong>
                </p>

                <p>
                <strong>Project information:</strong>
                </p>
                <p>
                - client PO number
                </p>
                <p>
                - brand and project name as per PO
                </p>
                <p>
                - job description as described in PO
                </p>
                <p>
                <strong>Your company:</strong>
                </p>
                <p>
                - Company registered name
                </p>
                <p>
                - Company address
                </p>
                <p>
                - Company EIN
                </p>
                <p>
                - Contact email
                </p>
                <p>
                - Contact phone #
                </p>
                <p>
                <strong>Payment instructions:</strong>
                </p>
                <p>
                company's bank account information - please strictily follow this instructions to avoid delays or payment issues. 
                </p>
                <p>
                - Bank name
                </p>
                <p>
                - Beneficiary name
                </p>
                <p>
                - Account #
                </p>
                <p>
                - ABA/Routing #
                </p>
              </div>
            )}


            {/* BPOOL mx */}
            {bPoolOffice?.id == "bp_mex" && (
              <div className="resume">
                <p>
                  <strong>
                    Por favor incluya en su factura
                  </strong>
                </p>

                <p>
                - número de orden de compra del cliente
                </p>
                <p>
                - marca y nombre del proyecto según PO
                </p>
                <p>
                - descripción del trabajo como se describe en PO
                </p>
                <p>
                Detalles de pago e inserción de NF
                </p>
                <p>
                los detalles de su cuenta bancaria en MXN: siga estrictamente estas instrucciones para evitar demoras o problemas de pago.
                </p>
                <p>
                impuestos: use sus métodos de facturación normales en México a empresas mexicanas su factura debe indicar su precio e indicar IVA encima de ella.
                </p>
              </div>
            )}

            {/* Boutique Pool */}
            {bPoolOffice?.id == "bp_intermediacao" && (
              <div className="resume">
                <p>
                  <strong>
                  Informações para faturamento
                  </strong>
                </p>

                <p>Favor incluir:</p>
                <p>- Número PO  </p>
                <p>- Marca e nome do projeto conforme PO </p>
                <p>- Descrição do job conforme PO </p>
                <p>Informações bancárias: </p>
                <p>Nome do banco, tipo e número de conta, CNPJ</p>

              </div>
            )}

            {/* COLOMBIA Intermediacao SAS */}
            {bPoolOffice?.id == "bp_inter_sas" && (
              <div className="resume">
                <p>
                  <strong>
                  Información a incluir en su factura: - número de orden de compra del cliente (PO)
                  </strong>
                </p>

                <p>
                  - Marca y nombre del proyecto según PO
                  </p>
                <p>
                  - Descripción del trabajo como se describe en PO
                </p>
                <p>
                  Dados para el  pago a incluir en su factura
                </p>
                <p>
                Los datos de su cuenta bancaria colombiana.  impuestos: aplicar e informar impuestos como lo hace normalmente al facturar.
                </p>
              </div>
            )}


            {/* Terandes SA */}
            {bPoolOffice?.id == "bp_terandes" && (
              <div className="resume">
                <p>
                  <strong>
                  Información para facturar: por favor incluya:
                  </strong>
                </p>

                <p>
                - número de orden de compra del cliente
                </p>
                <p>- marca y nombre del proyecto según PO</p>
                <p>- descripción del trabajo como se describe en PO</p>
                <p>
                En su factura por favor indique los detalles de su cuenta bancaria internacional; siga estrictamente estas instrucciones para evitar demoras o problemas de pago.
                </p>
                <p>
                - nombre del banco
                </p>
                <p>
                - cuenta bancaria
                </p>

                <p>
                 - código SWIFT
                </p>
                <p>
                - dirección completa de la sede del banco (con CÓDIGO POSTAL) utilizada para pagos internacionales - generalmente su sucursal - obtenga esta información vital con su gerente o el sitio web del banco
                </p>
                <p>
                - si su banco utiliza un banco intermediario, proporcione: nombre del banco, código ABA, dirección completa con código postal.
                </p>
                <p>
                - nombre completo del beneficiario (en caso de 2 beneficiarios, informe al beneficiario principal)
                </p>
                <p>
                Impuestos: no agregue ningún IVA en su precio ya que en Costa Rica nuestros clientes harán una autodeclaración de estos impuestos y pagarán ellos mismos.
                </p>
              </div>
            )}

            {/* Fiduciaria BPOOL  */}
            {bPoolOffice?.id == "bpool_fideicomiso " && (
              <div className="resume">
                <p>
                  <strong>
                  Información a incluir en su factura: - número de orden de compra del cliente
                  </strong>
                </p>

                <p>- marca y nombre del proyecto según PO</p>

                <p>
                - descripción del trabajo como se describe en PO
                </p>
                <p>
                Datos para el  pago a incluir en su factura
                </p>
                <p>
                Los datos de su cuenta bancaria ecuatoriana.  impuestos: aplicar e informar impuestos como lo hace normalmente al facturar.
                </p>
              </div>
            )}

            {/* Clan Colectora SA */}
            {bPoolOffice?.id == "bpool_clan " && (
              <div className="resume">
                <p>
                  <strong>
                  En su factura por favor incluya
                  </strong>
                </p>

                <p>- número de orden de compra del cliente</p>

                <p>
                - marca y nombre del proyecto según PO
                </p>

                <p>- descripción del trabajo como se describe en PO</p>

                <p>
                Por favor incluya en su factrua 
                </p>
                <p>
                los datos de su cuenta bancaria argentina:
                </p>
                <p>
                - Nombre del banco
                </p>
                <p>
                - Tipo y Número de Cuenta - CBU (22 dígitos) + Código ALIAS (opcional).
                </p>
                <p>
                Impuestos: CUIT (Código Único de Identificación Tributaria). Para Socios Responsables Inscripto en IVA, emite Factura A con IVA discriminado. Para Socios Responsables Monotributo, emita Factura C sin agregar IVA.
                </p>

              </div>
            )}
          </div>
        </S.ItemList>




        <S.ItemList>
          {I18n.get("Payment Term")}: <strong>{paymentTermDays}</strong>
        </S.ItemList>

        <S.ItemList>
          {I18n.get("PO number")}: <strong>{projectPO}</strong>
          <br />
          {billing?.authorize && (
            <span style={{ fontSize: "90%" }}>
              {I18n.get("Pagamento autorizado para")} {billingDate}
            </span>
          )}
        </S.ItemList>
        <S.ItemList>
          {I18n.get("Address")}:{" "}
          <strong>
            {billing.addressLine1 && billing.city && billing.state && (
              <>
                {billing.addressLine1}{" "}
                {billing.addressLine2 && <> &middot; {billing.addressLine2} </>}{" "}
                <br />
                {billing.city} - {billing.state} &middot; {billing.postcode}{" "}
                <br />
              </>
            )}
          </strong>
        </S.ItemList>
      </S.WrapperTable>
      <S.DescriptionInfo>
        <Collapse>

          {/*
            #############################################################################
            ######################  Payment instructions  ###############################
            #############################################################################
            */}
          <Panel header={I18n.get("Payment instructions")} key="1">
            <div className="invoiceResume">

               {/* BP INC */}
               {bPoolOffice?.id == "bpool_inc" && (
                <div className="resume">
                  <p>
                  Invoice instructions:
                  </p>

                  <p>
                  <strong>Project information:</strong>
                  </p>

                  <p>
                  - client PO number
                  </p>

                  <p>
                  - brand and project name as per PO
                    </p>
                  <p>
                  - job description as described in PO
                  </p>

                  <p>
                  <strong>Your company:</strong>
                  </p>
                  <p>
                  - Company registered name
                  </p>
                  <p>
                  - Company address
                  </p>
                  <p>
                  - Company EIN
                  </p>
                  <p>
                  - Contact email
                  </p>
                  <p>
                  - Contact phone #
                  </p>
                  <p>
                  <strong>Payment instructions:</strong>
                  </p>
                  <p>
                  company's bank account information - please strictily follow this instructions to avoid delays or payment issues. 
                  </p>
                  <p>
                  - Bank name
                  </p>
                  <p>
                  - Beneficiary name
                  </p>
                  <p>
                  - Account #
                  </p>
                  <p>
                  - ABA/Routing #
                  </p>
                  <p>
                  <strong>*BPOOL can't proceed with payment before receiving form W9</strong>
                  </p>

                </div>
              )}

              {/* BP MEX */}
              {bPoolOffice?.id == "bp_mex" && (
                <div className="resume">
                  <p>
                    Siga estrictamente estas instrucciones para evitar retrasos o problemas de pago.
                  </p>

                  <p>
                  En el cuerpo de la descripción de su factura, informe los detalles completos de su cuenta bancaria internacional
                  </p>

                  <p>
                  - Nombre completo del Banco (es decir, el nombre de Citibanamex es Banco Nacional de México SA)
                  </p>

                  <p>
                    - Cuenta bancaria
                    </p>
                  <p>
                    - Código SWIFT
                  </p>

                  <p>
                  - Dirección completa de la sede del banco utilizada para pagos internacionales (con CÓDIGO POSTAL) - Puede que no sea la dirección de su sucursal - obtenga esta información vital con su gerente o el sitio web del banco
                  </p>
                  <p>
                  - Si su banco utiliza un banco intermediario, proporcione: nombre del banco, código ABA, dirección completa con código postal.
                  </p>
                  <p>
                  - Nombre completo del beneficiario (en caso de 2 beneficiarios, informe al beneficiario principal)
                  </p>
                  <p>
                  Tenga en cuenta que informar solo la cuenta bancaria y el código SWIFT provocará demoras y problemas de pago.
                  </p>
                </div>
              )}

              {/* Boutique Pool */}
              {bPoolOffice?.id == "bp_intermediacao" && (
                <div className="resume">
                  <p>
                  Informe os detalhes da sua conta bancária brasileira no texto de descrição do corpo da Nota Fiscal. Nome do banco, tipo e número da conta, CNPJ
                  </p>
                </div>
              )}

              {/* BP Colombia Intermediacao SAS */}
              {bPoolOffice?.id == "bp_inter_sas" && (
                <div className="resume">
                  <p>
                  Por favor incluiya en el texto de su factura los datos de su cuenta bancaria en Colombia.	
                  </p>
                </div>
              )}

              {/* Terandes SA */}
              {bPoolOffice?.id == "bp_terandes" && (
                <div className="resume">
                  <p>
                    Siga estrictamente estas instrucciones para evitar retrasos o problemas de pago.
                  </p>

                  <p>
                    En el cuerpo de la descripción de su factura, informe los detalles completos de su cuenta bancaria internacional 
                  </p>

                  <p>
                    - Nombre completo del Banco (es decir, el nombre de Citibanamex es Banco Nacional de México SA)
                  </p>

                  <p>
                    - Cuenta bancaria
                    </p>
                  <p>
                    - Código SWIFT
                    </p>
                  <p>
                    - dirección completa de la sede del banco utilizada para pagos internacionales (con CÓDIGO POSTAL) 
                  </p>
                  <p>
                  - Puede que no sea la dirección de su sucursal 
                  </p>
                  <p>
                  - obtenga esta información vital con su gerente o el sitio web del banco
                  </p>
                  <p>
                  - si su banco utiliza un banco intermediario, proporcione: nombre del banco, código ABA, dirección completa con código postal.
                  </p>
                  <p>
                  - nombre completo del beneficiario (en caso de 2 beneficiarios, informe al beneficiario principal)
                  </p>

                  <p>
                  Tenga en cuenta que informar solo la cuenta bancaria y el código SWIFT provocará demoras y problemas de pago.
                  </p>
                </div>
              )}

              {/* Fiduciaria BPOOL  */}
              {bPoolOffice?.id == "bpool_fideicomiso" && (
                <div className="resume">
                  <p>
                  Por favor incluiya en el texto de su factura los datos de su cuenta bancaria. 	
                  </p>
                </div>
              )}

              {/* Clan Colectora SA */}
              {bPoolOffice?.id == "bpool_clan" && (
                <div className="resume">
                  <p>
                  Indique los detalles de su cuenta bancaria argentina en el texto de descripción del cuerpo de su factura. 
                  </p>
                  <p>
                  Nombre del banco, Tipo y Número de Cuenta - CBU (22 dígitos) + Código ALIAS (opcional). 
                  </p>
                  <p>
                  Impuestos: CUIT (Código Único de Identificación Tributaria).
                  </p>
                </div>
              )}
            </div>
          </Panel>

          

          {/*
            #############################################################################
            ##########################  Payment fluxe  ##################################
            #############################################################################
            */}
          <Panel header={I18n.get("Payment fluxe")} key="2">
            <div className="invoiceResume">

              {/* BP INC */}
              {bPoolOffice?.id == "bpool_inc" && (
                <div className="resume">
                  <p>
                  1) Your company will invoice BPOOL Inc
                  </p>
                  <p>
                  2) Upon client's payment to BPOOL, following client's payment terms, BPOOL will initiate your invoice payment within 72 hours.
                  </p>
                </div>
              )}


              {/* BP MEX */}
              {bPoolOffice?.id == "bp_mex" && (
                <div className="resume">
                  <p>
                  1) Su empresa facturará a BPOOL México
                  </p>
                  <p>
                  2) BPOOL factura cliente en México (Total factura)
                  </p>
                  <p>
                  3) Tras el pago del cliente a la cuenta de BPOOL en México de acuerdo con los términos de pago estándar acordados previamente con el cliente, BPOOL transferirá los recursos a su cuenta bancaria informada dentro de las 72 horas
                  </p>
                </div>
              )}


              {/* Boutique Pool */}
              {bPoolOffice?.id == "bp_intermediacao" && (
                <div className="resume">
                  <p>1) Sua empresa fatura contra o CNPJ do cliente, aos cuidados da BPool</p>
                  <p>2) BPool fatura contra o cliente </p>
                  <p>
                  3) Após o pagamento do cliente para as contas BPool, de acordo com os termos de pagamento padrão pré-acordados do cliente, a BPool transferirá os recursos para sua conta bancária informada dentro de 72 horas
                  </p>
                </div>
              )}

              {/* BP Colombia  Intermediacao SAS */}
              {bPoolOffice?.id == "bp_inter_sas" && (
                <div className="resume">
                  <p>
                    1) Su empresa facturará BPOOL COLOMBIA
                  </p>
                  <p>
                    2) BPOOL factura cliente en Colombia
                  </p>
                  <p>
                    3) Tras el pago del cliente a las cuentas de BPOOL, de acuerdo con los términos de pago estándar acordados previamente con el cliente, BPOOL transferirá los recursos a su cuenta bancaria informada dentro de las 72 horas.
                  </p>
                </div>
              )}



              {/* Terandes SA */}
              {bPoolOffice?.id == "bp_terandes" && (
                <div className="resume">
                  <p>
                  1) Su empresa facturará BPOOL LATAM (Terandes SA @ Uruguay)
                  </p>
                  <p>2) BPOOL factura cliente en Costa Rica</p>
                  <p>
                  3) Tras el pago del cliente a la cuenta de BPOOL en los EE. UU., de acuerdo con los términos de pago estándar acordados previamente con el cliente, BPOOL transferirá los recursos a su cuenta bancaria informada dentro de las 72 horas.
                  </p>
                </div>
              )}



              {/* Fiduciaria BPOOL  */}
              {bPoolOffice?.id == "bpool_fideicomiso" && (
                <div className="resume">
                  <p>
                    <strong>
                    1) Su empresa facturará BPOOL ECUADOR (Fideicomiso BPOOL@Ecuador)
                    </strong>
                  </p>

                  <p>2) BPOOL factura cliente en Ecuador</p>

                  <p>
                  3) Tras el pago del cliente a la cuenta bancaria de BPOOL @Ecuador, de acuerdo con los términos de pago estándar acordados previamente con el cliente, BPOOL transferirá los recursos a su cuenta bancaria informada dentro de las 72 horas.
                  </p>
                </div>
              )}


              {/* Clan Colectora SA */}
              {bPoolOffice?.id == "bpool_clan" && (
                <div className="resume">
                  <p>
                  1) Su empresa facturará a BPOOL Argentina (CLAN COLECTORA SA @ Argentina)
                  </p>
                  <p>2) BPOOL factura cliente en Argentina</p>
                  <p>
                  3) Tras el pago del cliente a las cuentas de BPOOL @Argentina, de acuerdo con los términos de pago estándar acordados previamente con el cliente, BPOOL transferirá los recursos a su cuenta bancaria informada dentro de las 72 horas
                  </p>
                </div>
              )}
            </div>
          </Panel>
        </Collapse>
      </S.DescriptionInfo>
    </>
  );
};

export default TableBillingItem;