import styled from 'styled-components';

export const WrapperFilters = styled.div`
  display: flex;
`;

export const Divisor = styled.div`
  height: 75px;
  width: 1px;
  background-color: #000;
  margin: 0 5px;
`;
