import { CHAT_UPDATE } from './actions';
import Config from '../../config';

const api_call = ({channelId, message}) => {

    return (dispatch, getState) => {

        const data = JSON.stringify({   "action":"sendMessage",
                                        "channelID":channelId,
                                        "message":message,
                                        "env": Config.URL_PLATFORM
                                    });

        window.sendWsMessage(data);
    };

}

export default api_call;
