import styled from "styled-components";

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const SubTitle = styled.div`
  margin: 15px 0 30px 0;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  span {
    width: 70px;
    height: 1px;
    background-color: #000000;
    display: block;
  }
`;

export const Text = styled.div`
  font-weight: ${(props) => (props.strongText ? "700;" : "400;")};
  font-size: ${(props) => (props.strongText ? "16px;" : "14px;")};
`;

export const ContentLoading = styled.div``;

export const ContentForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 30px 0;
`;

export const InputsForm = styled.div`
  display: flex;
  width: 100%;
`;

export const RowInput = styled.div`
  width: calc(33.333333% - 15px);
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentTypesOfExtras = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;

  button {
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    background-color: #2f2f2f;
    border-radius: 10px;
    border: none;
    margin-right: 15px;
    padding: 10px 15px;

    :last-of-type {
      margin-right: 0;
    }

    i {
      margin-left: 10px;
    }
  }

  .active {
    background-color: #1883ff;
  }
`;

export const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
`;

export const ErrorTypeRequredDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 30px;

  p {
    font-size: 14px;
    color: #ff0000;
  }
`;
