import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import constants from '../../../constants';
import { Link, Redirect } from 'react-router-dom';
import AppWrapperSimple from '../../components/AppWrapperRegister';
import ContainerPage from '../../components/ContainerPage';
import SubHeader from '../../components/SubHeader';

import { PARTNER_API } from '../../../actions/partner/actions';

import FormResponsible from '../components/formResponsible';
import FormUser from '../components/formUser';

import { TitleBorder, TitleMiddleLine } from '../../components/Titles';

import styled from 'styled-components';

import FormSkeleton from "../../components/FormSkeleton";


import {
    Row,
    Col,
    Input,
    notification,
    Select,
    Tag,
    Icon,
    Button,
    Drawer,
    Table,
    Tooltip,
    Form
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;

const Btns = styled.p`
    a {
        margin-left: 10px;
    }
`


const Main = styled.div`
    padding-top: 50px;
    padding-bottom: 90px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    .RowSection {
        margin-bottom: 60px;
    }
`
const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(PARTNER_API.update_person(data));
        },
        get_partner: id => {
            dispatch(PARTNER_API.get(id));
        },

        addUser: data => {
            dispatch(PARTNER_API.create_user(data));
        },
        updateUser: data => {
            dispatch(PARTNER_API.update_user(data));
        },
        deleteUser: data => {
            dispatch(PARTNER_API.delete_user(data));
        },
    };
};

class FullResponsible extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            show: false,
            userFlag: false
        }
    }


    showEditUser = (item) => {
        this.setState({
            selectedItem: item,
            edit: true,
            show: true,
            type: item.type,
            userFlag: true
        });
    }

    componentDidMount = () => {
        this.props.get_partner(this.props.match.params.partnerId);
    }

    showAddUser = () => {
        this.setState({
            show: true,
            userFlag: true
        });
    }

    hideUser = () => {
        this.setState({
            selectedItem: null,
            edit: false,
            show: false
        });
    }

    deleteUser = (data) => {

        this.setState({
            userFlag: true
        });

        this.props.deleteUser(data)
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partner.users && prevProps.partner.users && this.props.partner.users.length > prevProps.partner.users.length && this.state.userFlag) {

            notification.success({
                message: I18n.get("Adicionado com sucesso!"),
                description: I18n.get('O usuário foi adicionado ao seu cadastro'),
                duration: 3,
            });

            this.setState({
                userFlag: false
            });

            this.hideUser();
        }

        if (this.props.partner.users && prevProps.partner.users && this.props.partner.users.length < prevProps.partner.users.length && this.state.userFlag) {

            notification.success({
                message: I18n.get("Removido com sucesso!"),
                description: I18n.get('O usuário foi removido do seu cadastro'),
                duration: 3,
            });

            this.setState({
                userFlag: false
            });
        }

        if (this.props.partner.users && prevProps.partner.users && this.props.partner.users != prevProps.partner.users && this.state.selectedItem && this.state.userFlag) {

            notification.success({
                message: I18n.get("Atualizado com sucesso!"),
                description: I18n.get('O usuário foi atualizado'),
                duration: 3,
            });

            this.setState({
                selectedItem: null,
                userFlag: false
            })

            this.hideUser();
        }
    }



    render() {

        const { partner } = this.props;

        // if (this.props.partner && this.props.partner.status == 2) {
        //     return (<Redirect to={`/parceiro/obrigado-pre-cadastro`}></Redirect>);
        // }

        const columns = [
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: I18n.get("E-mail"),
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: I18n.get("Função"),
                dataIndex: 'roles',
                key: 'roles',
                render: roles => (
                    roles.map((item, index) => {
                        return (<Tag color="#000" key={index}>{item == "PartnerOperation" ? I18n.get("Operação") : I18n.get("Financeiro")}</Tag>);
                    })
                ),

            },
            {
                title: '',
                dataIndex: 'action',
                key: 'item',
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                        partnerId: this.props.match.params.partnerId,
                    }

                    return (
                        <Btns>
                            <a onClick={() => this.deleteUser(data)}>
                                <Icon type="delete" theme="filled" />
                            </a>
                            <a onClick={() => this.showEditUser(record.item)}>
                                <Icon type="edit" theme="filled" />
                            </a>
                        </Btns>
                    )
                }
            },
        ]

        let dataUser = this.props.partner.users && this.props.partner.users.map((item, index) => {
            return {
                key: index,
                name: item.name,
                email: item.email,
                roles: item.roles,
                item: item
            }
        });


        //TODO: Find better way... GAMBI!
        let menuRegister = [...constants.MENU_REGISTER];
        for (let key in menuRegister) {
            menuRegister[key].label = I18n.get(menuRegister[key].label);
        }

        return (
            <AppWrapperSimple>
                <SubHeader
                    titlePage=""
                    itensMenu={menuRegister}
                    urlId={this.props.match.params.partnerId}
                    activeItem={1}
                />
                <ContainerPage>
                    <Main>
                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Cadastro - Responsável")}</TitleBorder>
                                <p className="descriptionPage">
                                    {I18n.get("Para conhecer melhor você e outros usuários da plataforma, precisamos de algumas informações. Reserve um tempinho para preencher todos os campos e incluir detalhes importantes. Isso vai ajudar nosso algoritmo a encontrar projetos bacanas com seu perfil. Capricha aí =)")}
                                </p>
                            </Col>
                        </Row>

                        <FormResponsible
                            partner={this.props.partner && this.props.partner}
                            partnerId={this.props.match.params.partnerId}
                            action={this.props.save}
                            errors={this.props.partner.errors && this.props.partner.errors}
                            countryCodes={this.props.bp.countryCodes && this.props.bp.countryCodes}
                            locale={this.props.partner.signupLocale}
                        />

                        <Row>

                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Cadastro - Usuário")}</TitleBorder>

                                <p className="descriptionPage">{I18n.get("Você pode cadastrar usuários de Operação e Financeiro da sua empresa para acessar a plataforma.")}<br />{I18n.get("Insira abaixa os dados dos usuários.")}</p>
                                <TitleMiddleLine>

                                </TitleMiddleLine>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={24}>
                                <p className="BtnAction">
                                    <Button type="primary" shape="round" onClick={() => this.showAddUser()}>{I18n.get("+ Adicionar usuário")}</Button>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <Table scroll={{ x: 640 }} locale={{ emptyText: I18n.get('Sem informação') }} columns={columns} dataSource={dataUser} />
                            </Col>
                        </Row>

                        <Row className="RowSection">
                            <Col sm={8}>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={3}>

                            </Col>
                            <Col sm={18}>
                            </Col>
                            <Col sm={3}>
                                <Link to={`/parceiro/cadastro/empresa/${this.props.match.params.partnerId}`}><Button type="primary" shape="round">{I18n.get("Avançar")}</Button></Link>
                            </Col>
                        </Row>

                    </Main>

                </ContainerPage>

                <Drawer
                    title={this.state.selectedItem ? I18n.get("Editar usuário") : I18n.get("Adicionar usuário")}
                    placement="right"
                    forceRender={true}
                    closable={true}
                    onClose={() => this.hideUser()}
                    destroyOnClose={true}
                    visible={this.state.show}
                    width="40%"
                >
                    <FormUser
                        partner={this.props.partner && this.props.partner}
                        idContext={this.props.match.params.partnerId}
                        action={this.props.addUser}
                        update={this.props.updateUser}
                        cancel={() => this.hideUser()}
                        item={this.state.selectedItem}
                        errors={this.props.partner.errors && this.props.partner.errors}
                    />
                </Drawer>
            </AppWrapperSimple>
        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(FullResponsible);
