import styled from "styled-components";
import Slider from "react-slick";

import * as MediaQuery from "../../../utils/mediaQuery";

export const StyledSlider = styled(Slider)`
  & .slick-dots {
    margin-left: 25px;
    text-align: left;
    bottom: 35px;
    width: 500px;
    li {
      margin-right: 30px !important;
    }
    a {
      background-color: transparent;
      border: 1px solid #fff;
      font-size: 12px;
      color: white;
      border-radius: 50px;
      padding: 10px 15px;
      text-decoration: none;
      margin-right: 10px;
    }
    & .slick-active {
      a {
        background-color: #1883ff;
        border: 1px solid #1883ff;
      }
    }

    @media (max-width: 786px) {
      top: 25px;
      bottom: 0;
      width: unset;
      height: 100px;
    }
  }
`;

export const SlideDivImage = styled.div`
  flex: 1 1 auto;
  width: 50%;
  height: 560px;

  picture {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      flex: 1;
      height: 100%;
      object-fit: cover;
      border-radius: 10px 0px 0px 10px;

      ${() =>
        MediaQuery.IS_MOBILE() &&
        `
      border-radius: 10px 10px 0 0;
      `}
    }
  }

  ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    width: 100%;
      height: 460px;
    `}
`;

export const SlideBtns = styled.div`
  display: block;
  padding-left: 50px;
`;

export const SlideDivTexts = styled.div`
  padding-right: 50px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1 1 auto;
  width: 50%;
  height: 560px;
  background-color: rgba(0, 0, 0, 0.5);

  div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
  }

  ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    width: 90%;
    padding: 10px 20px 40px;
    `}
`;

export const SlideDivTextsTitle = styled.h1`
  color: #fff;
  font-size: 28px;
  line-height: 32px;
  margin-left: 50px;
  &::after {
    content: "";
    width: 75px;
    background-color: #fff;
    height: 7px;
    display: flex;
    margin-top: 20px;
  }

  ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    font-size: 24px;
    `}
`;

export const SlideDivTextsDescription = styled.p`
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  margin: 40px 50px 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-flex;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;

  ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    font-size: 12px;
    `}
`;
export const SlideDiv = styled.div`
  background-image: url(${(props) => props.bg});
  background-size: cover;
  color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 50px;
  overflow: hidden;

  ${() =>
    MediaQuery.IS_MOBILE() &&
    `
    gap: 10px;
    flex-direction: column;
    `}
`;
