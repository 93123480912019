import React, {PureComponent} from 'react';
import { I18n } from 'aws-amplify';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom'
import thumbFake from '../../../image/thumb-fake.png';

class FaqItem extends PureComponent {
    state = {
        open: false
    }
    render() { 
        const {item} = this.props;
        return (
            <div className={`bp-video-faq ${this.state.open ? "open" : ""}`}>
                <h2 onClick={() => this.setState({open: !this.state.open})}>{item.title}</h2>
                
                <div className={`faq-description`}>
                    <p>{item.content}</p>
                </div>
            </div>
        );
    }
}
 
export default FaqItem;