import { CLIENT_BRAND_UPDATE } from './actions';

export const resetErros = () => {

    return (dispatch, getState) => {
        dispatch({
            type: CLIENT_BRAND_UPDATE,
            payload: {
                errorsDelete: {},
                errors: {}

            }
        });
    };
}

export default resetErros;