import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { EXTRAS_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const upload_milestone = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            // [HttpGet("/api/client-project/{projectId}/milestones")]

            let files = data.files.map(item => {
                return({
                    name: item.name,
                    size: item.size,
                    url: item.url
                })
            });

            
            return axios.put(`${Config.API_BASE_URL}/milestones/${data.projectId}/extra/${data.extraId}/quotation/${data.quotationId}/${data.milestoneId}/upload`, files, config)
                .then(response => {

                    const payload = {
                        ...response.data,
                        task: {
                            result: true
                        }
                    };

                    dispatch({
                        type: EXTRAS_UPDATE,
                        payload: payload
                    })

                    return payload;
                })
                .catch(error => {

                    // Nao faz o dispatch no erro, tratamento direto na tela
                    const payload = {
                        errors: error.response.data,
                        task: {
                            result: false
                        }
                    };

                    return payload;
                });

        }).catch(error => {

            dispatch({ type: EXPIRED });
        });
    };
}

export default upload_milestone;
