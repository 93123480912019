import React from 'react';
import { I18n } from 'aws-amplify';
import { withRouter, Redirect } from 'react-router-dom';

import ProjectContainer from '../../containers/project-container';

import { ClientProjectStatus } from '../../model';

import FillForm from '../page-templates/fillForm-logged';

import PartnersForm from './partners-form';
import QuestionsForm from './questions-form';

import Steps from './steps';

class SelectPartnerStep1 extends React.Component {
    constructor(props) {
        super();
        this.state = {
            questions: false,
            task: {}
        };
    }

    componentDidMount() {

        const data = {
            projectId: this.props.match.params.projectId
        };

        this.props.get_partners(data);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // Recarrega os parceiros para atualizar carregar a nova pergunta
        if (prevProps.location.hash != this.props.location.hash) {

            const data = {
                projectId: this.props.match.params.projectId
            };

            this.props.get_partners(data);

        }
    }


    onShowQuestions(e) {

        e.preventDefault();

        const { url } = this.props.match;

        this.props.history.push(`${url}#qa`);
    }

    onHideQuestions(e) {

        e.preventDefault();

        const { url } = this.props.match;

        this.props.history.push(url);
    }

    submitForm(e) {

    }

    render() {

        if (this.props.project && this.props.project.status >= ClientProjectStatus.PartnerSelected) {
            return (<Redirect to={`/cliente/projetos/${this.props.match.params.projectId}/parceiro-selecionado`} />);
        }

        const { hash = '' } = this.props.location;

        const showQuestions = hash.startsWith('#qa');

        const highlightQuestionId = showQuestions ? hash.substring(3) : '';



        const { project = {} } = this.props;

        const disableReason = !project.partnerId;

        return (
            <FillForm>
                <div className="hero-body has-background-white">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-7">
                                <p className="title">{I18n.get("Seleção de Parceiros")}<br />
                                    <span className="tag is-warning tag-project-name">{project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-5">
                                <Steps active={1} projectId={this.props.match.params.projectId} disabled={disableReason ? [2] : []} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step-1 client-step-1">



                    {!showQuestions && <>

                        <PartnersForm {...this.props} onShowQuestions={this.onShowQuestions.bind(this)} />

                    </>}


                    {showQuestions && <>

                        <div style={{ marginBottom: '1rem', position: 'fixed', top: '170px', right: '20px' }}>

                            <a onClick={this.onHideQuestions.bind(this)} class="delete is-large"></a>

                        </div>


                        <QuestionsForm {...this.props} highlightQuestionId={highlightQuestionId} onHideQuestions={this.onHideQuestions.bind(this)} />

                    </>}

                    <br />
                    <br />



                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(withRouter(SelectPartnerStep1));
