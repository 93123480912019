import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../../../components/pages/loading';
import styled from 'styled-components';
import {
    Form,
    Select,
    Radio,
    Button,
    Icon,
    Input,
    TreeSelect,
    Checkbox,
    Row,
    Col,
} from 'antd';

import WhatifWrapper from '../components/WhatifWrapper';

import { CLIENT_WHATIF_API } from '../../../actions/client-whatif/actions';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import { TitleBorder, TitleSimple, TitleMiddle, TitleMiddleLine } from '../components/Titles';
import { CardBorder } from '../components/Cards'
import MultUploadFile from '../components/MultUploadFile';
import UploadFile from '../components/UploadFile';

import { MENU_CLIENT_WHATIF } from '../constants';

const { TextArea, Search } = Input;
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const FieldCustom = styled.div`

`

const BoxAddFild = styled(Search)`
    button {
        position:relative;

        i {
            display: none;
        }
    }
`;


const Main = styled.div`
  max-width: 820px;
  margin: 0 auto 100px;
  padding-top: 80px;
  width: 100%;
  box-sizing: border-box;
`;

const BoxTerm = styled.div`
  background: #fff;
  padding: 20px;
  height: 200px;
  overflow-x: hidden;

  p {
    color: #353535;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 15px;
  }
`;

const RowSubmit = styled(Row)`
  button {
      margin-right: 20px;
  }
`;


const genderOptions = ['Mulher', 'Homem', 'LGBT'];
const SocialIdeasOptions = ['Instagram', 'Insta Stories', 'Facebook', 'Twitter'];
const OtherIdeasOptions = ['Evento', 'Campanhas', 'Social', 'Banners', 'Experiência'];
const ageRangeOptions = ['18 - 24 anos', '25 - 30 anos'];

const estados = [
    {
        title: I18n.get("Norte"),
        value: 'Norte',
        key: 'norte',
        children: [
            {
                title: I18n.get("Manaus"),
                value: 'Manaus',
                key: 'manaus',
            },
            {
                title: I18n.get("Belem"),
                value: 'Belem',
                key: 'belem',
            },
        ],
    },
    {
        title: I18n.get("Nordeste"),
        value: 'Nordeste',
        key: 'nordeste',
        children: [
            {
                title: I18n.get("Recife"),
                value: 'Recife',
                key: 'recife',
            },
            {
                title: I18n.get("Fortaleza"),
                value: 'fortaleza',
                key: 'fortaleza',
            },
            {
                title: I18n.get("Maceio"),
                value: 'Maceio',
                key: 'maceio',
            },
        ],
    },
];

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        create_scope_idea: data => {
            dispatch(CLIENT_WHATIF_API.create_scope_idea(data));
        },
        upload: dataUpload => {
            return CLIENT_WHATIF_API.upload(dataUpload);
        },
        get_project: data => {
            dispatch(CLIENT_WHATIF_API.get_project(data));
        }
    };
};


class BrandProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            StateOrRegionValue: ['0-0-0'],
            referenceLinks: [],
            referenceVideos: [],
            referenceImages: [],
            imageLogo: "",
            imageHeader: "",
            statusBlur: false,
            loadingProject: false,
            listImg: [],
            isSubmit: false
        };
    }

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            statusBlur: false
        })

        this.props.form.validateFields((err, values) => {

            if (!err) {
                this.setState({
                    formState: values,
                    isSubmit: true
                })

                setTimeout(() => {
                    this.sendDataApi();
                }, 10);

            }
        });
    };

    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    onChangeRegion = value => {
        this.setState({ StateOrRegionValue: value });
    };

    getDataUploadMult = (files) => {
        this.setState({
            referenceImages: files,
            statusBlur: true
        });


        setTimeout(() => {
            this.sendDataApi();
        }, 1);
    }

    getDataUpload = (dataForm) => {
        const { field, url } = dataForm;

        switch (field) {
            case "imageLogo":
                this.setState({
                    imageLogo: url
                });
                break;

            case "imageHeader":
                this.setState({
                    imageHeader: url
                });
                break;

            default:
                break;
        }


        this.setState({
            statusBlur: true
        });


        setTimeout(() => {
            this.sendDataApi();
        }, 1);
    };

    sendDataApi = () => {
        const fieldsForm = this.props.form.getFieldsValue();

        const dataForm = {
            ...fieldsForm,
            clientId: this.props.client.clientId,
            whatIfId: this.props.match.params.whatIfId,
            imageLogo: this.state.imageLogo,
            imageHeader: this.state.imageHeader,
            referenceLinks: this.state.referenceLinks,
            referenceVideos: this.state.referenceVideos,
            referenceImages: this.state.referenceImages,
            listImg: [],
            fieldBlur: "",
            isSubmit: this.state.isSubmit
        }

        this.props.create_scope_idea(dataForm);
    }

    onBlur = (e, field) => {

        this.setState({
            statusBlur: true,
            fieldBlur: field
        });

        setTimeout(() => {
            this.sendDataApi();
        }, 1);
    };


    addUrl = (value) => {
        this.setState({
            statusBlur: true,
            referenceLinks: [...this.state.referenceLinks, value]
        })

        setTimeout(() => {
            this.sendDataApi();
        }, 20);
    }

    addVideo = (value) => {
        this.setState({
            statusBlur: true,
            referenceVideos: [...this.state.referenceVideos, value]
        })

        setTimeout(() => {
            this.sendDataApi();
        }, 20);
    }

    componentDidMount() {
        const data = {
            clientId: this.props.client.id,
            whatIfId: this.props.match.params.whatIfId
        }

        this.props.get_project(data);
    }

    componentWillMount() {
        this.setState({
            loadingProject: false
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.clientWhatIf.errors != prevProps.clientWhatIf.errors && !this.state.statusBlur) {
            Object.keys(this.props.clientWhatIf.errors).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.clientWhatIf.errors[field].errorMessage)],
                    },
                });
            });
        };

        if (this.props.clientWhatIf.errors != prevProps.clientWhatIf.errors && this.props.clientWhatIf.errors[this.state.fieldBlur] && this.state.statusBlur) {

            this.props.form.setFields({
                [this.state.fieldBlur]: {
                    errors: [new Error(this.props.clientWhatIf.errors[this.state.fieldBlur].errorMessage)],
                },
            });
        }

        if (this.props.client.id != prevProps.client.id && !this.state.loadingProject) {
            this.setState({
                loadingProject: true
            })
            const data = {
                clientId: this.props.client.clientId,
                whatIfId: this.props.match.params.whatIfId
            }

            this.props.get_project(data);
        }

        if (this.props.clientWhatIf != prevProps.clientWhatIf) {
            let listImg = this.props.clientWhatIf.referenceImages && this.props.clientWhatIf.referenceImages.map((item, index) => {
                return {
                    uid: index,
                    name: 'image.png',
                    status: 'done',
                    url: item
                }
            });

            this.setState({
                imageLogo: this.props.clientWhatIf.imageLogo,
                imageHeader: this.props.clientWhatIf.imageHeader,
                referenceLinks: this.props.clientWhatIf.referenceLinks,
                referenceVideos: this.props.clientWhatIf.referenceVideos,
                referenceImages: this.props.clientWhatIf.referenceImages,
                listImg: listImg ? listImg : []
            });
        };

    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const tProps = {
            treeData: estados,
            onChange: this.onChangeRegion,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            searchPlaceholder: 'Selecione...',
            style: {
                width: 300,
            },
        };

        const {
            match: {
                params: {
                    whatIfId
                }
            }
        } = this.props;



        if (!this.props.clientWhatIf) {
            return (<Loading />);
        }

        return (
            <WhatifWrapper>
                <SubHeader itensMenu={MENU_CLIENT_WHATIF} activeItem={0} />
                <ContainerPage>
                    <Main>
                        <CardBorder
                            Bcolor="#fff"
                        >
                            <TitleBorder>Brand Profile</TitleBorder>

                            <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                        </CardBorder>
                        <Form onSubmit={this.handleSubmit}>
                            <CardBorder
                                Bcolor="#e3fbb8"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <TitleSimple>Marca ou Produto:<br />{this.props.clientWhatIf.brandName} / {this.props.clientWhatIf.productName}
                                        </TitleSimple>

                                        <Form.Item label="Logotipo">
                                            {getFieldDecorator('imageLogo', {
                                                valuePropName: 'fileList'
                                            })(
                                                <Fragment>
                                                    <UploadFile
                                                        name="imageLogo"
                                                        actionUpload={this.props.upload}
                                                        getDataUpload={this.getDataUpload}
                                                        id={this.props.clientWhatIf.whatIfId}
                                                        imgUrl={this.props.clientWhatIf.imageLogo}
                                                    />
                                                </Fragment>
                                            )}
                                        </Form.Item>


                                        <Form.Item label="Imagem Destaque">
                                            {getFieldDecorator('imageHeader', {
                                                valuePropName: 'fileList',
                                            })(
                                                <Fragment>
                                                    <UploadFile
                                                        name="imageHeader"
                                                        showUploadList={false}
                                                        cover
                                                        actionUpload={this.props.upload}
                                                        getDataUpload={this.getDataUpload}
                                                        id={this.props.clientWhatIf.whatIfId}
                                                        imgUrl={this.props.clientWhatIf.imageHeader}
                                                    />
                                                </Fragment>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CardBorder>

                            <CardBorder
                                Bcolor="#bab1d1"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <Form.Item label="Brand Purpose">
                                            {getFieldDecorator('brandPurpose', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.brandPurpose ? this.props.clientWhatIf.brandPurpose : ""
                                            })(
                                                <TextArea
                                                    rows={4}
                                                    onBlur={e => this.onBlur(e, "brandPurpose")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CardBorder>

                            <CardBorder
                                Bcolor="#c7e5f2"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <Form.Item label="Descrição">
                                            {getFieldDecorator('description', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.description ? this.props.clientWhatIf.description : ""
                                            })(
                                                <TextArea rows={4}
                                                    onBlur={e => this.onBlur(e, "description")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Item label="Gênero">
                                            {getFieldDecorator('genre', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.genre ? this.props.clientWhatIf.genre : []
                                            })(
                                                <CheckboxGroup
                                                    options={genderOptions}
                                                    onChange={e => this.onBlur(e, "genre")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col sm={6}>
                                        <Form.Item label="Faixa etária">
                                            {getFieldDecorator('ageRange', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.ageRange ? this.props.clientWhatIf.ageRange : []
                                            })(
                                                <CheckboxGroup
                                                    options={ageRangeOptions}
                                                    onChange={e => this.onBlur(e, "ageRange")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Item label="Keywords">
                                            {getFieldDecorator('keywords', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.keywords ? this.props.clientWhatIf.keywords : []
                                            })(
                                                <Select
                                                    mode="tags"
                                                    style={{ width: '100%' }}
                                                    tokenSeparators={[',']}
                                                    onChange={e => this.onBlur(e, "keywords")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CardBorder>
                            <CardBorder
                                Bcolor="#ffc5b9"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <Form.Item label="Plataformas de Atuação">
                                            {getFieldDecorator('actuationPlatforms', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.actuationPlatforms ? this.props.clientWhatIf.actuationPlatforms : []
                                            })(
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Selecione..."
                                                    style={{ width: '100%' }}
                                                    onChange={e => this.onBlur(e, "actuationPlatforms")}
                                                >
                                                    <Option key="Música">Música</Option>
                                                    <Option key="Vídeo">Vídeo</Option>
                                                    <Option key="Etc">ETC</Option>
                                                </Select>
                                            )}
                                        </Form.Item>

                                    </Col>
                                </Row>
                            </CardBorder>
                            <CardBorder
                                Bcolor="#cbede0"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <Form.Item label="Área Foco para Ideias & Conteudo (Região e/ou Estado)">
                                            {getFieldDecorator('regionFocusArea', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.regionFocusArea ? this.props.clientWhatIf.regionFocusArea : []
                                            })(
                                                <TreeSelect
                                                    {...tProps}
                                                    onChange={e => this.onBlur(e, "regionFocusArea")}
                                                />
                                            )}
                                        </Form.Item>

                                    </Col>
                                </Row>
                            </CardBorder>
                            <CardBorder
                                Bcolor="#f0e2a1"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <Form.Item label="Datas Especiais para receber ideias de conteúdo">
                                            {getFieldDecorator('specialDates', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.specialDates ? this.props.clientWhatIf.specialDates : []
                                            })(
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Selecione..."
                                                    style={{ width: '100%' }}
                                                    onChange={e => this.onBlur(e, "specialDates")}
                                                >
                                                    <Option key="1" value="Carnaval">Carnaval</Option>
                                                    <Option key="2" value="Ano novo">Ano novo</Option>
                                                    <Option key="3" value="Etc">ETC</Option>
                                                </Select>
                                            )}
                                        </Form.Item>

                                    </Col>
                                </Row>
                            </CardBorder>
                            <CardBorder
                                Bcolor="#e3fbb8"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <Form.Item label="Don’ts">
                                            {getFieldDecorator('donts', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.donts ? this.props.clientWhatIf.donts : []
                                            })(
                                                <Select
                                                    mode="tags"
                                                    style={{ width: '100%' }}
                                                    onChange={e => this.onBlur(e, "donts")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CardBorder>
                            <CardBorder
                                Bcolor="#bab1d1"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <TitleMiddle>Quero receber</TitleMiddle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Item label="Ideias de Social">
                                            {getFieldDecorator('wantReceiveSocial', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.wantReceiveSocial ? this.props.clientWhatIf.wantReceiveSocial : []
                                            })(
                                                <CheckboxGroup
                                                    options={SocialIdeasOptions}
                                                    onChange={e => this.onBlur(e, "wantReceiveSocial")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Item label="Outras ideias">
                                            {getFieldDecorator('wantReceiveOthers', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.wantReceiveOthers ? this.props.clientWhatIf.wantReceiveOthers : []
                                            })(
                                                <CheckboxGroup
                                                    options={OtherIdeasOptions}
                                                    onChange={e => this.onBlur(e, "wantReceiveOthers")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CardBorder>
                            <CardBorder
                                Bcolor="#c7e5f2"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <TitleMiddleLine>Referências</TitleMiddleLine>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Row gutter={1}>
                                            <Col sm={22}>
                                                <FieldCustom>
                                                    <label className="label-bp">Links:</label>
                                                    <BoxAddFild
                                                        className="InputPlus"
                                                        placeholder="Ex.: www.google.com"
                                                        onSearch={value => { this.addUrl(value) }}
                                                        enterButton
                                                    />
                                                </FieldCustom>
                                            </Col>
                                        </Row>
                                        <div>
                                            <ul className="ListDinamic">
                                                {this.props.clientWhatIf.referenceLinks &&
                                                    this.props.clientWhatIf.referenceLinks.map(item => {
                                                        return <li><a href="#" target="_blank"><Icon type="link" /> {item}</a></li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <Row gutter={1}>
                                            <Col sm={22}>
                                                <FieldCustom>
                                                    <label className="label-bp">Vídeos:</label>
                                                    <BoxAddFild
                                                        className="InputPlus"
                                                        placeholder="Ex.: www.youtube.com"
                                                        onSearch={value => { this.addVideo(value) }}
                                                        enterButton
                                                    />
                                                </FieldCustom>
                                            </Col>
                                        </Row>
                                        <div>
                                            <ul className="ListDinamic">

                                                {this.props.clientWhatIf.referenceVideos &&
                                                    this.props.clientWhatIf.referenceVideos.map(item => {
                                                        return <li><a href="#" target="_blank"><Icon type="video-camera" /> {item}</a></li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={24}>
                                        <Form.Item label="Imagens">
                                            {getFieldDecorator('referenceImages', {
                                                rules: [{ required: false, message: 'ops!' }],
                                            })(
                                                <MultUploadFile
                                                    actionUpload={this.props.upload}
                                                    getDataUpload={this.getDataUploadMult}
                                                    name="referenceImages"
                                                    uploadId={this.props.clientWhatIf.whatIfId}
                                                    fileListGet={this.state.listImg}
                                                    isEdit

                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CardBorder>
                            <CardBorder
                                Bcolor="#ffc5b9"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <Form.Item label="Quero ser notificado">
                                            {getFieldDecorator('notification', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.notification ? this.props.clientWhatIf.notification : null
                                            })(
                                                <Radio.Group
                                                    onChange={e => this.onBlur(e, "notification")}
                                                >
                                                    <Radio value={1}>Sempre que enviarem quaisquer conteúdo & ideias para minha marca</Radio>
                                                    <Radio value={2}>Sempre que enviarem conteúdo & idéias para minha marca dentro dos critérios que selecionei</Radio>
                                                    <Radio value={3}>Não quero ser notificado, vou acessar a plataforma regularmente</Radio>

                                                </Radio.Group>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={24}>
                                        <Form.Item label="Adicionar outros colegas à notificação">
                                            {getFieldDecorator('notificationEmails', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.notificationEmails ? this.props.clientWhatIf.notificationEmails : null
                                            })(
                                                <Select
                                                    mode="tags"
                                                    style={{ width: '100%' }}
                                                    onChange={e => this.onBlur(e, "notificationEmails")}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CardBorder>
                            <CardBorder
                                Bcolor="#cbede0"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <TitleMiddle>Termos e Condições</TitleMiddle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={24}>
                                        <BoxTerm>
                                            <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                            <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                            <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                            <p>Texto explicativo: Nullam auctor lectus magna, at pellentesque arcu scelerisque at. Vestibulum felis lacus, lobortis eget purus non, euismod maximus urna. Vivamus convallis leo vitae velit scelerisque malesuada. Aliquam eu pharetra elit, sed congue ipsum. Nam id nunc condimentum, imperdiet purus sit amet, commodo lacus. \\Ut rhoncus urna id sodales pharetra.</p>
                                        </BoxTerm>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={24}>
                                        <Form.Item>
                                            {getFieldDecorator('termsAccepted', {
                                                rules: [{ required: false, message: 'ops!' }],
                                                initialValue: this.props.clientWhatIf.termsAccepted,
                                                valuePropName: 'checked'
                                            })(
                                                <Checkbox
                                                    onChange={e => this.onBlur(e, "termsAccepted")}
                                                >Li e aceito os termos de uso</Checkbox>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CardBorder>
                            <CardBorder>
                                <RowSubmit>
                                    <Col sm={24}>
                                        <Link to={`/cliente/ideias/brand-profile/${whatIfId}/preview/`}>
                                            <Button type="primary" shape="round" size="large">Visualizar</Button>
                                        </Link>
                                        <Button type="primary" htmlType="submit" shape="round" size="large">Salvar</Button>
                                    </Col>
                                </RowSubmit>
                            </CardBorder>

                        </Form>
                    </Main>
                </ContainerPage>
            </WhatifWrapper >
        );
    }
}

const BrandProfileForm = Form.create({})(BrandProfile);

export default connect(mapStateToProps, mapDispatchToProps)(BrandProfileForm);
