import React, { useState, useEffect } from 'react';
import * as S from './styles';
import NewsInfo from '../NewsInfo';
import readingTime from 'reading-time';
import ReactMarkdown from 'react-markdown';
import { I18n, Auth } from 'aws-amplify';
import { useParams } from 'react-router-dom';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingPage from '../../pages/loading';
import axios from 'axios';

const NewsInternal = ({auth}) => {
    const { slug } = useParams();    
    const [news, setNews] = useState([null]);
    const [loading, setLoading] = useState(true);
    const {locale} = auth;
    const history = useHistory();
    const userType = (auth.is_client ? '_is_client' : '_is_partner');

    useEffect (() => {
        setLoading(true)
        async function getNews() {
            Auth.currentSession().then((session) => {
                const token = session && session.accessToken.jwtToken;
                let authHeader = { headers: { Authorization: 'Bearer ' + token } };
                axios.get(locale == 'pt' ? `https://cms.bpool.co/news?_locale=pt-BR&_slug=${slug}&${userType}=true` : `https://cms.bpool.co/news?_locale=${locale}&_slug=${slug}&${userType}=true`, authHeader)
                .then(res => res.data)
                .then((data) => {
                    setNews(data)
                    setLoading(false);
                    window.scrollTo(0, 0);
                })
            })
        }
        getNews();
    }, [slug, locale])

    const goBack = () => {
        history.goBack();
    }

    return (
        <>
        {!loading && news != null &&
            news.map((res) => (
                <S.ContainerNews>
                    <S.BackButton onClick={goBack}>{I18n.get("Voltar")}</S.BackButton>
                    <S.ContainerNewsContent>
                        <S.HighlightedImage>
                            <S.DivImageNews>
                                <picture>
                                    <img src={res.image && res.image.url}/>
                                </picture>
                            </S.DivImageNews>
                        </S.HighlightedImage>
                        <S.ContentNews>
                            <S.ContentCategory>{res.category && (res.category.name)}</S.ContentCategory>
                            <S.ContentTitle>{res.title}</S.ContentTitle>
                            <NewsInfo date={res.published_at} time={Math.ceil(readingTime(res.content).minutes)} author={res.author}/>
                            <S.ContentText>
                                <ReactMarkdown children={res.content} />
                            </S.ContentText>
                        </S.ContentNews>
                    </S.ContainerNewsContent>
                </S.ContainerNews>
            ))
        }
        {news.length == 0 &&
            <S.ContainerNews>{I18n.get("Nada encontrado")}</S.ContainerNews>
        }
        {loading && 
            <S.LoaderDiv>
                <LoadingPage /> 
            </S.LoaderDiv>
        }
        </>
    )
}

const mapStateToProps = state => {
    return state;
};

export default connect(
    mapStateToProps,
)(withRouter(NewsInternal));