import React from "react";
import { Row, Col } from "antd";
import { I18n } from "aws-amplify";

import { Info } from "./info";
import { InfoFile } from "./infoFile";

import * as S from "./styles";

export const InformationsDetails = ({ data, isLoading }) => {
  const billToValue = ({ billTo, email }) => {
    let invoiceTo = "";

    if (billTo === 1) {
      invoiceTo = `CLIENT ${email ? `- ${email}` : ""}`;
    } else {
      // invoiceTo = `BPOOL (${data.billToCompany.registrationName})`;
      invoiceTo = `BPOOL (---)`;
    }
    return invoiceTo;
  };

  return (
    <S.Wrapper>
      <h3>{I18n.get("Dados do BPay")}</h3>
      <Row>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Projeto")}
            value={data?.bPayName}
            colorBlack
            isLoading={isLoading}
          />
        </Col>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Marca")}
            value={data?.brandName}
            colorBlack
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ paddingLeft: 0, marginTop: 8 }}>
          <Info
            label={I18n.get("#PO")}
            value={data?.poNumber}
            colorBlack
            isLoading={isLoading}
          />
        </Col>
        <Col md={6} style={{ paddingLeft: 0, marginTop: 8 }}>
          <Info
            label={I18n.get("Valor Total")}
            value={data?.totalFmt}
            colorBlack
            isLoading={isLoading}
          />
        </Col>
        {data?.poFile && (
          <Col md={6} style={{ paddingLeft: 0, marginTop: 8 }}>
            <InfoFile
              label={I18n.get("Arquivo")}
              value={data?.poFile}
              colorBlack
              isLoading={isLoading}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6} style={{ paddingLeft: 0, marginTop: 8 }}>
          <Info
            label={I18n.get("Faturar contra")}
            value={billToValue({
              billTo: data?.billTo,
              email: data?.createdByEmail,
            })}
            colorBlack
            isLoading={isLoading}
          />
        </Col>
        <Col md={6} style={{ paddingLeft: 0, marginTop: 8 }}>
          <Info
            label={I18n.get("SKU")}
            value={data?.sku}
            colorBlack
            isLoading={isLoading}
          />
        </Col>
      </Row>

      <S.Divider />

      <h3>{I18n.get("Faturamento")}</h3>
      <Row>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("CNPJ")}
            value={data?.clientRegistrationId}
            isLoading={isLoading}
          />
        </Col>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Empresa")}
            value={data?.clientRegistrationName}
            isLoading={isLoading}
          />
        </Col>
        <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Info
            label={I18n.get("Nome Fantasia")}
            value={data?.clientName}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col md={8} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Endereço de cobrança")}
            value={`${data?.clientAddressLine1}${
              data?.clientAddressLine2 ? `, ${data?.clientAddressLine2}` : ""
            }`}
            isLoading={isLoading}
          />
        </Col>
        <Col md={4} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Estado")}
            value={data?.clientState}
            isLoading={isLoading}
          />
        </Col>
        <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Info
            label={I18n.get("País")}
            value={I18n.get(data?.clientCountry?.name)}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col md={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Info
            label={I18n.get("Telefone")}
            value={data?.clientPhoneNumber}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </S.Wrapper>
  );
};
