import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { LEADER_CASE_DELETE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_leader_delete_case = (data) => {
    return (dispatch, getState) => {

        return axios.delete(`${Config.API_BASE_URL}/leader/${data.leaderId}/case/${data.id}`)
        .then(response => {

            dispatch({
                type: LEADER_CASE_DELETE,
                payload: response.data
            });

        })
        .catch(error => {

            dispatch({
                type: LEADER_CASE_DELETE,
                payload: {
                    case: {
                        errors: error.response.data
                    }
                }
            });
        });
    };
}


export default api_leader_delete_case;