import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import TextField from "../form/text-field";
import TextAreaField from "../form/textarea-field";

import CheckboxMultiBox from "../form/checkbox-multi-field";

import { scrollToTop } from "../../utils/ui";

import { CustomEventBriefingType } from "../../model";

import { Textarea } from "../../v2/components/UI/Textarea";
import { Input } from "../../v2/components/UI/Input";

const evt = CustomEventBriefingType.Event;
const shp = CustomEventBriefingType.Shopper;
const ict = CustomEventBriefingType.Incentive;
const dgt = CustomEventBriefingType.Digital;

class AboutForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        goal: {
          labels: {
            [evt]: I18n.get("Qual é o objetivo do evento?"),
            [shp]: I18n.get("Qual é o objetivo do projeto?"),
            [ict]: I18n.get("Qual é o objetivo do projeto?"),
            [dgt]: I18n.get("Qual é o objetivo do evento?"),
          },
          onChange: this.onChangeFormNew.bind(this, "goal"),
          // onBlur: this.onSave.bind(this, 'goal'),
          successIcon: false,
        },
        eventType: {
          labels: {
            [evt]: I18n.get("Qual o tipo de evento?"),
            [shp]: I18n.get("Qual o tipo de projeto?"),
            [ict]: I18n.get("Qual o tipo de projeto?"),
          },
          onChange: this.onChangeFormCheckboxMulti.bind(this, "eventType"),
          successIcon: false,
        },
        eventTypeDesc: {
          label: "",
          onChange: this.onChangeFormNew.bind(this, "eventTypeDesc"),
          // onBlur: this.onSave.bind(this, 'eventTypeDesc'),
          successIcon: false,
          visited: true,
        },
        message: {
          labels: {
            [evt]: I18n.get(
              "Qual é a mensagem que os participantes devem levar do evento?"
            ),
            [shp]: I18n.get(
              "Qual é a mensagem que os participantes devem levar do projeto?"
            ),
            [ict]: I18n.get(
              "Qual é a mensagem que os participantes devem levar do projeto?"
            ),
            [dgt]: I18n.get(
              "Qual é a mensagem que os participantes devem levar do evento?"
            ),
          },
          onChange: this.onChangeFormNew.bind(this, "message"),
          // onBlur: this.onSave.bind(this, 'message'),
          successIcon: false,
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    // console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormNew(prop, e) {
    const value = e;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};

    const isOtherEnabled =
      Array.isArray(this.state.form.eventType) &&
      this.state.form.eventType.indexOf("OUTR") > -1;

    const keys = {
      [CustomEventBriefingType.Event]: "eventCats",
      [CustomEventBriefingType.Shopper]: "shopperTypes",
      [CustomEventBriefingType.Incentive]: "incentiveTypes",
      [CustomEventBriefingType.Digital]: "digitalTypes",
    };

    const key = keys[customEvent.briefingType];

    const eventTypeValues =
      this.props.bp[key] &&
      this.props.bp[key].map((item) => {
        if (customEvent.isEvent && item.code === "OUTR") {
          return {
            ...item,
            field: (
              <Input
                onChange={(value) =>
                  this.state?.fields?.eventTypeDesc.onChange(value)
                }
                value={this.state.form.eventTypeDesc}
                disabled={!isOtherEnabled}
              />
              // <TextField
              //   {...this.state.form.eventTypeDesc}
              //   disabled={!isOtherEnabled}
              // />
            ),
          };
        }

        return item;
      });

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Sobre o evento")}</h1>

        <div className="bp-event-section">
          {/* <TextAreaField
                        {...this.state.fields.goal}
                        labelKey={customEvent.briefingType}
                        value={this.state.form.goal}
                        error={this.state.errors.goal} /> */}

          <Textarea
            label={this.state.fields.goal.labels[customEvent?.briefingType]}
            defaultValue={this.state.form.goal}
            value={this.state.form.goal}
            onChange={(value) => this.state?.fields?.goal.onChange(value)}
            error={this.state?.errors?.goal?.errorMessage}
          />

          <br />

          {!customEvent.isDigital && (
            <div className="bpForce">
              <CheckboxMultiBox
                {...this.state.fields.eventType}
                labelKey={customEvent.briefingType}
                value={this.state.form.eventType}
                error={this.state?.errors?.eventType}
                checked={this.state.form.eventType}
                values={eventTypeValues}
              />
              <br style={{ clear: "both" }} />
              <br />
            </div>
          )}

          {/* <TextAreaField
            {...this.state.fields.message}
            labelKey={customEvent.briefingType}
            value={this.state.form.message}
            error={this.state.errors.message}
          /> */}

          <Textarea
            label={this.state.fields.message.labels[customEvent.briefingType]}
            defaultValue={this.state.form.message}
            value={this.state.form.message}
            onChange={(value) => this.state.fields.message.onChange(value)}
            error={this.state?.errors?.message?.errorMessage}
          />
        </div>
      </div>
    );
  }
}

export default AboutForm;
