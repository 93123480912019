export const converData = (data) => {
  return {
    // Dados do BPay
    bPayName: data?.name,
    brandName: data?.brandName,
    poNumber: data?.billing?.poNumber,
    totalFmt: data?.totalFmt,
    billTo: data?.billTo,
    sku: data?.sku,
    createdByEmail: data?.createdByEmail,
    poFile: data?.billing?.fileAttached?.url,
    // Dados Faturamento
    clientRegistrationId: data?.billing?.clientCompany?.registrationId,
    clientRegistrationName: data?.billing?.clientCompany?.registrationName,
    clientName: data?.billing?.clientCompany?.name,
    clientAddressLine1: data?.billing?.clientCompany?.addressLine1,
    clientAddressLine2: data?.billing?.clientCompany?.addressLine2,
    clientCountry: data?.billing?.clientCompany?.country,
    clientState: data?.billing?.clientCompany?.state,
    clientPhoneNumber: data?.billing?.clientCompany?.phoneNumber,
  };
};
