import React, { Component } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";

import {
  Row,
  Col,
  Button,
  AutoComplete,
  Input,
  Select,
  Form,
  Checkbox,
} from "antd";

const { Option } = Select;

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
    margin-right: 20px;
  }
`;

class FormAddBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: [],
      viewErros: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formState: values,
          viewErros: true,
        });

        let data = {
          ...values,
          tMarket: this.props.tc == 1 ? [1, 2] : [2],
          clientId: this.props.clientId,
        };

        this.props.add(data);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.productsItens &&
      prevProps.productsItens &&
      this.props.productsItens.length > prevProps.productsItens.length
    ) {
      this.props.form.resetFields();

      this.setState({
        viewErros: false,
      });
    }

    if (
      this.props.errors != prevProps.errors &&
      this.props.errors &&
      this.state.viewErros
    ) {
      Object.keys(this.props.errors).map((field) => {
        if (field == "ProductName") {
          this.props.form.setFields({
            productName: {
              value: this.state.formState[field],
              errors: [new Error(this.props.errors[field].errorMessage)],
            },
          });
        } else {
          this.props.form.setFields({
            [field]: {
              value: this.state.formState[field],
              errors: [new Error(this.props.errors[field].errorMessage)],
            },
          });
        }
      });
    }
  }

  getCategoryCountValues = () => {
    var values = [],
      i = 0,
      len = 10;
    while (++i <= len) values.push({ code: i, label: i });

    return values;
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const ListCategorie =
      this.props.sectorsFlat &&
      this.props.sectorsFlat.sort(
        (a, b) => (a.label > b.label) - (a.label < b.label)
      );

    let brandNames =
      this.props.brands &&
      this.props.brands.map((item) => {
        return item.name;
      });

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row className="" gutter={12}>
          <Col sm={12}>
            <Form.Item label={I18n.get("Marca")}>
              {getFieldDecorator("brandName", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.name
                    ? this.props.item.name
                    : null,
              })(<AutoComplete dataSource={brandNames} />)}
            </Form.Item>
          </Col>
          <Col sm={12} className="errorSpace">
            <Form.Item label={I18n.get("Produto ou Serviço")}>
              {getFieldDecorator("productName", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.name
                    ? this.props.item.name
                    : null,
              })(<Input />)}
            </Form.Item>
          </Col>
          </Row>
          <Row className="" gutter={12}> 
          <Col sm={12}>
            <Form.Item label={I18n.get("Categoria")}>
              {getFieldDecorator("categoryId", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.categoryId
                    ? this.props.item.categoryId
                    : null,
              })(
                <Select
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ListCategorie &&
                    ListCategorie.map((item) => {
                      return <Option value={item.code}>{item.label}</Option>;
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label={I18n.get("Global")}>
              {getFieldDecorator("global", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.global
                    ? this.props.item.global
                    : null,
              })(
                <Select
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={null}>{I18n.get("Selecione")}</Option>
                  <Option value={true}>{I18n.get("Sim")}</Option>
                  <Option value={false}>{I18n.get("Não")}</Option>
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={2}>
            <Button
              style={{ position: "relative", top: "35px" }}
              type="primary"
              shape="round"
              htmlType="submit"
            >
              +
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const FormAddBrandForm = Form.create({})(FormAddBrand);

export default FormAddBrandForm;
