import React from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { I18n } from 'aws-amplify';

import {
    Icon,
    notification,
} from 'antd';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_PROJECT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_report = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.post(`${Config.API_BASE_URL}/bp/reports`, data, config);

        }).catch(error => {
             notification.open({
                message: I18n.get("Ops! Algo deu errado."),
                description: I18n.get("Tente novamente mais tarde."),
                icon: <Icon type="check" style={{ color: '#000' }} />,
            });
        });
    };
}

export default api_report;