import React from "react";
import { Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

// FULL
import { EditCompanyProfile } from "../../pages/PartnerEditProfile/pages/CompanyProfile";
import { EditUserProfile } from "../../pages/PartnerEditProfile/pages/UserProfile";
import { EditCompanyDetails } from "../../pages/PartnerEditProfile/pages/CompanyDetails";
import { EditBillingData } from "../../pages/PartnerEditProfile/pages/BillingData";
import { EditDiversityAndInclusion } from "../../pages/PartnerEditProfile/pages/DiversityAndInclusion";
import { EditBusinessType } from "../../pages/PartnerEditProfile/pages/BusinessType";
import { EditAwards } from "../../pages/PartnerEditProfile/pages/Awards";
import { EditIndustry } from "../../pages/PartnerEditProfile/pages/Industry";
import { EditSpecialityProofAndEndersement } from "../../pages/PartnerEditProfile/pages/SpecialityProofAndEndersement";
import { EditCustomers } from "../../pages/PartnerEditProfile/pages/Customers";
import { EditTeamRegistration } from "../../pages/PartnerEditProfile/pages/TeamRegistration";
import { EditMyProfile } from "../../pages/PartnerEditProfile/pages/MyProfile";
import { EditSummary } from "../../pages/PartnerEditProfile/pages/Summary";
import { EditLanguage } from "../../pages/PartnerEditProfile/pages/Language";

const queryClient = new QueryClient();

function PartnerEditProfile() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route
            exact
            path="/partner/edit-profile/user-profile"
            component={EditUserProfile}
          />
          <Route
            exact
            path="/partner/edit-profile/company-profile"
            component={EditCompanyProfile}
          />
          <Route
            exact
            path="/partner/edit-profile/company-details"
            component={EditCompanyDetails}
          />
          <Route
            exact
            path="/partner/edit-profile/billing-data"
            component={EditBillingData}
          />
          <Route
            exact
            path="/partner/edit-profile/diversity-and-inclusion"
            component={EditDiversityAndInclusion}
          />
          <Route
            exact
            path="/partner/edit-profile/business-type"
            component={EditBusinessType}
          />
          <Route
            exact
            path="/partner/edit-profile/awards"
            component={EditAwards}
          />
          <Route
            exact
            path="/partner/edit-profile/industry"
            component={EditIndustry}
          />
          <Route
            exact
            path="/partner/edit-profile/speciality-proof-and-endersement"
            component={EditSpecialityProofAndEndersement}
          />
          <Route
            exact
            path="/partner/edit-profile/customers"
            component={EditCustomers}
          />
          <Route
            exact
            path="/partner/edit-profile/team-registration"
            component={EditTeamRegistration}
          />
          <Route
            exact
            path="/partner/edit-profile/my-profile"
            component={EditMyProfile}
          />
          <Route
            exact
            path="/partner/edit-profile/summary"
            component={EditSummary}
          />
          <Route
            exact
            path="/partner/edit-profile/language"
            component={EditLanguage}
          />
        </Switch>
      </QueryClientProvider>
    </>
  );
}

export default PartnerEditProfile;
