import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import ClientProjectDetailsView from './client-project/_audio-details';


const mapStateToProps = state => {

    return Object.assign({}, state, state.bp && state.bp.project);
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_project: data => {
            dispatch(BP_API.get_project_audio(data));
        },
        get_assets: data => {
            dispatch(BP_API.get_assets(data));
        },
        update_proposal: data => {
            return dispatch(BP_API.update_proposal(data));
        }
    };
};

class ClientDetails extends React.Component {

    constructor(props) {

        super();

        this.state = {
            projectId: props.match.params.audioId,
        };
    }


    componentDidMount() {

        var data = {
            projectId: this.props.match.params.audioId
        };

       
        this.props.get_project(data);
        
        
    }

    handleCandidates = () => {
        var data = {
            projectId: this.props.match.params.audioId
        };

        this.props.get_candidates(data);
    }

    render() {

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li><NavLink to="/bpool/projetos" className="is-black">{I18n.get("Projetos")}</NavLink></li>
                        <li className="is-active"><a href="#">{this.props.projectName}</a></li>
                    </ul>
                </nav>

                <div className="columns">

                    <div className="column is-12">
                        {this.props.project?.project &&
                            <ClientProjectDetailsView {...this.props} loadCandidates={() => { this.handleCandidates() }} />
                        }
                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);

