import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_UPDATE_EVAL } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_evaluation = (data) => {

    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.put(`${Config.API_BASE_URL}/bp/partners/${data.partnerId}/${data.username}/evaluation`, data.items, config)
            .then(response => {

                dispatch({
                    type: BP_UPDATE_EVAL,
                    payload: {
                        partner: response.data,
                        task: { result: true }
                    }
                })
            })
            .catch(error => {

                dispatch({
                    type: BP_UPDATE_EVAL,
                    payload: {
                        task: { errors: error.response.data }
                    }
                })

            });
}).catch(error => { 
 dispatch({ type: EXPIRED }); 
 }); 
 };
}


export default api_update_evaluation;