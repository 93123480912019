import axios from "axios";
import { Auth } from "aws-amplify";

import Config from "../../config";

import { BP_CLIENT_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const api_update_rules_proposal = (data) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .put(
            `${Config.API_BASE_URL}/bp/client-settings/custom-project-evencustom-rules`,
            data,
            config
          )
          .then((response) => {
            dispatch({
              type: BP_CLIENT_GET,
              payload: {
                ...response.data.data,
                task: { result: true },
              },
            });

            return true;
          })
          .catch((error) => {
            // Nao faz o dispatch no erro, tratamento direto na tela

            return false;
          });
      })
      .catch((error) => {
        // dispatch({ type: EXPIRED });
      });
  };
};

export default api_update_rules_proposal;
