import { I18n } from 'aws-amplify';

export function getQuotationStatus(auth, item = {}) {

    const reviseStr = (item.revise === true) ? ` (${I18n.get("Revisar")})` : '';

    if (item.isApproved === true)
        return I18n.get('Aprovado');

    if (item.isApproved === false)
        return I18n.get("Não aprovado");

    if (auth.is_client || auth.is_client_user) {
        if (item.isOpen) {
            return `${I18n.get("Em Aberto")} ${reviseStr}`;
        }
        else {
            return I18n.get("Aguardando Aprovação");
        }
    }
    else {
        if (item.isOpen) {
            return I18n.get("Em Aberto");
        }
        else {
            return I18n.get("Aguardando Aprovação Cliente");
        }
    }

    return '--';
}