import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Steps } from "antd";
import { Tag } from "../../../../components/UI/Tag";
import { Input } from "../../../../components/UI/Input";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import * as S from "./styles";

const { Step } = Steps;

export const StatusBPayment = ({ data, emailPrimary, isLoading }) => {
  const [formResend, setFormResend] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [emailValueBackup, setEmailValueBackup] = useState("");

  useEffect(() => {
    if (emailPrimary) {
      setEmailValueBackup(emailPrimary);
      setEmailValue(emailPrimary);
    }
  }, [emailPrimary]);

  const whatStatus = (status) => {
    if (status === true) {
      return "finish";
    } else if (status === false) {
      return "process";
    } else {
      return "wait";
    }
  };

  const hasDifference = (string1, string2) => {
    return string1 !== string2;
  };

  const isDisabledBt = () => {
    const differences = hasDifference(emailValueBackup, emailValue);
    return differences ? false : true;
  };

  useEffect(() => {
    if (isLoading) {
      setFormResend(false);
    }
  }, [isLoading]);

  const renderSteps = () => {
    return (
      <Steps direction="vertical">
        <Step
          title={I18n.get("Faturamento liberado")}
          description={data?.faturamentoLiberadoMsg || I18n.get("Aguardando")}
          status={whatStatus(data?.isFaturamentoLiberado)}
        />
        <Step
          title={
            <div style={{ display: "flex" }}>
              <p className="title-custom" style={{ marginRight: 8 }}>
                {I18n.get("NF BPool")}
              </p>
              {data?.isNfBPool ? (
                <Tag text={I18n.get("Faturado")} theme="green" />
              ) : (
                <Tag text={I18n.get("Em andamento")} theme="yellow" />
              )}
            </div>
          }
          description={
            <div>
              <div>{data?.nfBPoolMsg || I18n.get("Aguardando")}</div>
              {/* <S.ButtonResend>
                <button onClick={() => setFormResend(true)}>
                  {I18n.get("Alterar e-mail")}
                </button>
              </S.ButtonResend> */}
              {formResend ? (
                <S.FormResend>
                  <Input
                    placeholder={I18n.get("E-mail")}
                    label
                    required
                    value={emailValue}
                    onChange={(value) => setEmailValue(value)}
                    error=""
                  />
                  <ButtonBpool
                    full
                    text={I18n.get("Enviar")}
                    theme="primary"
                    disabled={isDisabledBt()}
                    onClick={() => console.log("enviar")}
                  />
                </S.FormResend>
              ) : null}
            </div>
          }
          status={whatStatus(data?.isNfBPool)}
        />
        <Step
          title={I18n.get("NF Fornecedor")}
          description={data?.nfFornecedorMsg || I18n.get("Aguardando")}
          status={whatStatus(data?.isNfFornecedor)}
        />
        <Step
          title={I18n.get("Pagamento agendado")}
          description={data?.pgtAgendadoMsg || I18n.get("Aguardando")}
          status={whatStatus(data?.isPagamentoAgendado)}
        />
        <Step
          title={I18n.get("Pagamento realizado")}
          description={data?.pgtRealizadoMsg || I18n.get("Aguardando")}
          status={whatStatus(data?.isPagamentoRealizado)}
        />
      </Steps>
    );
  };

  return (
    <S.Wrapper>
      <S.Skeleton
        loading={isLoading}
        active
        title={false}
        paragraph={{
          rows: 10,
          className: "skeleton-step",
        }}
      >
        {renderSteps()}
      </S.Skeleton>
    </S.Wrapper>
  );
};
