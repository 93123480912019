import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'

import { Task } from '../../../utils/task';
import { FormUtils } from '../../../utils/form';

import { PARTNER_API } from '../../../actions/partner/actions';

import LoggedPage from '../../page-templates/logged';

import TextAreaField from '../../form/textarea-field';
import SubmitButtonField from '../../form/submit-button-field';


const mapStateToProps = state => {
  return Object.assign({}, state, state.partner && state.partner.project, state.partner);
};

const mapDispatchToProps = (dispatch) => {

  return {
    get_project: data => {
      dispatch(PARTNER_API.get_project(data));
    },
    save: (data) => {
      dispatch(PARTNER_API.update_invitation(data));
    },
  };
};

class ProjectInviteAdmin extends React.Component {

  constructor(props) {

    super();

    this.state = {
      form: {
        reason: {
          label: '',
          value: props.reason,
          onChange: this.onChangeForm.bind(this, 'reason'),
          error: props.errors.reason,
          successIcon: false,
          visited: true,
          maxLength: 500
        },
      },
      task: {}
    };

    this.submitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {

    // this.props.get_assets({});
    // this.props.get_bundles({});

    if (this.props.match.params.projectId) {

      var data = {
        projectId: this.props.match.params.projectId
      };

      this.props.get_project(data);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    const task = this.props.task || {};
    const prev = prevProps && prevProps.task || {};

    //console.dir({ this: this.props, prev: prevProps });

    if (task != prev) {

      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {

        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value = this.props[item] || '';
        }
        else if (typeof nextState.form !== 'undefined' && typeof this.props[item] !== 'undefined') {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }
  }

  onChangeForm(prop, e) {

    const value = e.target.value;

    this.setState((prevState) => {

      var nextState = Object.assign({}, prevState);

      nextState.form[prop].value = value;

      return nextState;
    });
  }

  getData() {

    var data = {
      projectId: this.props.match.params.projectId,
      accepted: false
    };

    Object.keys(this.state.form).forEach((item) => {

      if (typeof this.state.form[item] === "object" && 'value' in this.state.form[item]) {
        data[item] = this.state.form[item].value;
      }
      else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  onSubmitForm(e) {

    e.preventDefault();

    var data = this.getData();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(data);
    });
  }

  render() {

    const invitation = (this.props.projectInvitations && this.props.projectInvitations.filter((item) => item.projectId == this.props.match.params.projectId)[0]) || {};

    if (invitation.accepted === false) {
      return (<Redirect to={`/parceiro/projetos/${this.props.projectId}/convite`} />);
    }

    return (
      <LoggedPage {...this.props}>

        <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li><Link to="/admin">{I18n.get("Admin")}</Link></li>
            <li><Link to="/parceiro/projetos">{I18n.get("Projetos")}</Link></li>
            <li><Link to={`/parceiro/projetos/${this.props.projectId}/convite`}>{this.props.projectName}</Link></li>
            <li className="is-active"><Link to={`/parceiro/projetos/${this.props.projectId}/convite`}>{I18n.get("Convite")}</Link></li>
          </ul>
        </nav>

        <br />

        <div className="columns">

          <div className="column is-6 is-offset-3 decline-block">

            <p className="title">{I18n.get("Percebemos que você declinou sua participação.")}</p>

            <form onSubmit={this.submitForm.bind(this)}>

              <p className="title-bpool medium">{I18n.get("Por favor, comente o motivo de não ter aceito participar.")}</p>

              <TextAreaField {...this.state.form.reason} />

              <br /><br />

              <div className="control">
                <SubmitButtonField
                  label={I18n.get("SALVAR")}
                  onClick={this.submitForm.bind(this)}
                  task={this.state.task}
                />
              </div>

            </form>

          </div>

        </div>

      </LoggedPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInviteAdmin);
