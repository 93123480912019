import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col, Select, message } from "antd";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";
import { Input } from "../../../../components/UI/Input";
import { InputFlag } from "../../../../components/UI/InputFlag";
import { Textarea } from "../../../../components/UI/Textarea";
import { UploadLogo } from "../components/UploadLogo";
import { SelectOne } from "../../../../components/UI/SelectOne";
import { useUpload } from "../../../../hooks/useUpload/useUpload.hook";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { urlsFull as urls } from "../../utils/stepsUrls";
import { clearErrorInput, hasError } from "../../utils/errorsInput";
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from "../../utils/flowToStorage";
import { statusIdToName } from "../../components/Loggedv2/statusIdToName";
import { inputsState } from "./inputsState";
import COUNTRY_CODES from "./countryCodes.json";
import * as S from "./styles";

const { Option } = Select;
const MESSAGE_ERROR_GET_PAGE = "There was an error, try again.";
const ERROR_IMAGE_UP = I18n.get("Maximum limit 5mb");
const ERROR_IMAGE_NOT_UP = "Logo image is required with sizes (117 x 117px)";
const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const PAGE_INDEX = 16;

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  const listCountries = useSelector((state) => state.bp.countryCodes);
  let history = useHistory();
  const { uploadFile } = useUpload();
  const { getStatusBarFull, getMyProfile, putMyProfile, getTimezones } =
    useRegistrationFlowFull();

  const [inputsFormInitals, setInputsFormInitals] = useState(inputsState);
  const [inputsForm, setInputsForm] = useState(inputsState);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [selectCountry, setSelectCountry] = useState({
    countryLabel: "Brazil",
    phoneMask: "(00)00000-0000",
    countryCode: "BRA",
  });
  const [selectCountryPhone, setSelectCountryPhone] = useState({
    countryLabel: "Brazil",
    phoneMask: "(00)00000-0000",
    countryCode: "BRA",
  });
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [username, setUsername] = useState("");
  const [originalTimezones, setOriginalTimezones] = useState([]);
  const [timezoneSelected, setTimezoneSelect] = useState("");
  const [statusProfilePicture, setStatusProfilePicture] = useState("");
  const [fileProfilePictureToUpload, setFileProfilePictureToUpload] =
    useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const onChangeInputForm = (id, value, isCountry) => {
    if (isCountry) {
      const obj = listCountries.find((i) => i.code == value);

      setInputsForm({
        ...inputsForm,
        [id]: value ? obj : null,
      });
    } else {
      setInputsForm({
        ...inputsForm,
        [id]: value ? value : null,
      });
    }
  };

  const onChangeInputPhone = ({ valuePhone, valuePhoneCountry }) => {
    setInputsForm({
      ...inputsForm,
      phoneNumber: valuePhone ? valuePhone : null,
      countryCodePhoneNumber: valuePhoneCountry ? valuePhoneCountry : null,
    });
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarFull();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);

    let profilePictureSend = inputsFormInitals.profilePicture;
    if (fileProfilePictureToUpload) {
      const responseProfilePictureUpload = await uploadFile({
        file: fileProfilePictureToUpload,
        idUser: "usertestingnow",
        fieldId: `profilePicture`,
      });
      profilePictureSend = responseProfilePictureUpload.url;
    }

    const formData = {
      adminAction: contextPage === "admin" ? true : false,
      id: contextPage === "admin" ? partnerId : null,
      name: inputsForm.name,
      username: username,
      email: inputsForm.email,
      linkedin: inputsForm.linkedin,
      country: inputsForm.country,
      phoneNumber: inputsForm.phoneNumber,
      countryCodePhoneNumber: inputsForm.countryCodePhoneNumber,
      nameAlias: inputsForm.nameAlias,
      resume: inputsForm.resume,
      timeZone: inputsForm.timeZone,
      profilePicture:
        statusProfilePicture === "removed"
          ? null
          : profilePictureSend
          ? profilePictureSend
          : "",
    };

    const response = await putMyProfile({
      admin: contextPage === "admin" ? true : false,
      form: formData,
    });
    if (contextPage === "full") {
      await newGetStepbar();
    }

    if (!response?.success) {
      if (contextPage === "full") {
        setOpenModalConfirm(true);
      }
      const errors = [];
      Object.keys(response?.errors).forEach(function (key, index) {
        return errors.push(response?.errors[key]);
      });
      setInputsErrorsForm(errors);
      if (response?.errors?.ProfilePicture) {
        setStatusProfilePicture("errorNotUpload");
      }
    } else {
      if (contextPage === "full") {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: urls[PAGE_INDEX + 1],
        });
      }
      if (contextPage === "edit" || contextPage === "admin") {
        sendOk(true);
      }
    }

    setIsSending(false);
  };

  const loadInit = async () => {
    setIsLoading(true);

    const response = await getMyProfile({
      admin: contextPage === "admin" ? true : false,
    });
    if (response.success) {
      setInputsFormInitals(response.data);
      setInputsForm(response.data);
      setUsername(response.data.username);

      if (response?.data?.country) {
        const country = COUNTRY_CODES.find(
          (item) => item.code === response?.data?.country?.code
        );
        setSelectCountry({
          countryLabel: country
            ? country?.name
            : selectCountryPhone.countryLabel,
          phoneMask: country ? country.mask : selectCountryPhone.phoneMask,
          countryCode: country ? country?.code : selectCountryPhone.countryCode,
        });
      }
    } else {
      message.error(I18n.get(MESSAGE_ERROR_GET_PAGE));
    }

    setIsLoading(false);
  };

  const fileToUpload = ({ file }) => {
    setFileProfilePictureToUpload(file ? file : null);
  };

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const renderHasError = (status) => {
    if (status === "error") {
      return <S.ErrorText>{ERROR_IMAGE_UP}</S.ErrorText>;
    } else if (status === "errorNotUpload") {
      return <S.ErrorText>{ERROR_IMAGE_NOT_UP}</S.ErrorText>;
    } else {
      return null;
    }
  };

  const onChangeTimesonze = (time) => {
    setTimezoneSelect(time);
  };

  const loadTimezone = async () => {
    const response = await getTimezones();

    const selected = response.data.find((i) => i.selected == true);

    const timeFormat = response.data.map((item) => {
      return {
        code: item?.value,
        label: `${item?.text} - ${item?.value}`,
        selected: item.selected,
      };
    });

    setTimezones(timeFormat);
    setOriginalTimezones(response.data);
    setTimezoneSelect(selected ? selected.id : null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
    loadTimezone();
  }, []);

  // TODO: Fazer depois

  // const timezoneInit = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // const setTimezone = async () => {
  //   console.log("timeFormat", originalTimezones);

  //   const objetoEncontrado = originalTimezones.find((objeto) =>
  //     objeto.utcs.some((utc) => utc === timezoneInit)
  //   );

  //   console.log("objetoEncontrado", objetoEncontrado);

  //   return objetoEncontrado.value;
  // };

  // const teste = setTimezone().then((result) => result);

  // console.log("TESTE", teste);

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("Let's build your profile")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  "As a company contact, your profile is the main point of contact for brands"
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        {isLoading ? (
          <Row>
            <Col xs={24}>
              <S.ContentIsLoading>
                <Loading
                  text={I18n.get("Loading informations...")}
                  sizeText={14}
                  sizeIcon={16}
                />
              </S.ContentIsLoading>
            </Col>
          </Row>
        ) : (
          <>
            <Row style={{ marginTop: 30 }}>
              <Col xs={24}>
                <S.TitleSection>{I18n.get("About You")}</S.TitleSection>
              </Col>
              <Col xs={24} sm={6}>
                <S.Logo>
                  <S.LogoImage>
                    <UploadLogo
                      defaultImg={inputsFormInitals.profilePicture}
                      imgUploadStatus={(status) =>
                        setStatusProfilePicture(status)
                      }
                      fileToUpload={(file) => fileToUpload({ file })}
                      styleButton={{
                        width: "117px",
                        height: "117px",
                      }}
                    />
                  </S.LogoImage>
                  {statusProfilePicture === "" ||
                  statusProfilePicture === "removed" ? (
                    <S.SizeLogo>
                      117 x 117px
                      <br />
                      <strong>JPG or PNG</strong>
                    </S.SizeLogo>
                  ) : null}
                </S.Logo>
                {renderHasError(statusProfilePicture)}
              </Col>
              <Col xs={24} sm={18}>
                <S.InputsContent>
                  <S.RowInput>
                    <Input
                      placeholder={I18n.get("Name")}
                      label
                      required
                      value={inputsForm.name}
                      onChange={(value) => onChangeInputForm("name", value)}
                      error={hasError({
                        field: "name",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "name",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput style={{ marginLeft: 15 }}>
                    <Input
                      placeholder={I18n.get("Email")}
                      label
                      required
                      disabled
                      value={inputsForm.email}
                      onChange={(value) => onChangeInputForm("email", value)}
                      error={hasError({
                        field: "email",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "email",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput>
                    <Input
                      placeholder={I18n.get("How would you like to be called?")}
                      label
                      required
                      value={inputsForm.nameAlias}
                      onChange={(value) =>
                        onChangeInputForm("nameAlias", value)
                      }
                      error={hasError({
                        field: "nameAlias",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "nameAlias",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput style={{ marginLeft: 15 }}>
                    <Input
                      placeholder={I18n.get("Linkedin")}
                      label
                      // required
                      value={inputsForm.linkedin}
                      onChange={(value) => onChangeInputForm("linkedin", value)}
                      error={hasError({
                        field: "linkedin",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "linkedin",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput>
                    {/* <Input
                        placeholder={I18n.get("Country")}
                        required
                        value={selectCountry.countryLabel}
                        disabled
                        addonBefore={selectBefore}
                        notPlaceholder
                      /> */}

                    <SelectOne
                      options={listCountries || []}
                      value={inputsForm?.country?.code}
                      defaultValue={inputsForm?.country?.code}
                      isCountry
                      onChange={(value) =>
                        onChangeInputForm("country", value, true)
                      }
                      required
                      placeholder={I18n.get("Country")}
                      error={hasError({
                        field: "country",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "country",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput style={{ marginLeft: 15 }}>
                    <InputFlag
                      id="phoneNumber"
                      label={I18n.get("Phone number")}
                      defaultValue={inputsForm?.phoneNumber}
                      flagCodeDefault={
                        inputsForm?.countryCodePhoneNumber || "BRA"
                      }
                      onChange={(value) =>
                        onChangeInputPhone({
                          valuePhone: value?.value,
                          valuePhoneCountry: value?.country,
                        })
                      }
                      required
                      error={hasError({
                        field: "phoneNumber",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "phoneNumber",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </S.RowInput>
                  <S.RowInput style={{ width: "calc(100% - 15px)" }}>
                    <SelectOne
                      options={timezones}
                      value={inputsForm.timeZone}
                      placeholder={I18n.get("Fuso horário")}
                      onChange={(value) => onChangeInputForm("timeZone", value)}
                      required
                      error={hasError({
                        field: "timeZone",
                        arr: inputsErrorsForm,
                      })}
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "timeZone",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                      // error=""
                    />

                    {contextPage === "edit" && (
                      <p
                        style={{ fontSize: 12, color: "#7c7c7c", marginTop: 5 }}
                      >
                        {I18n.get(
                          "Ao alterar o fuso horário, será necessário refazer o login"
                        )}
                      </p>
                    )}
                  </S.RowInput>
                </S.InputsContent>
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col xs={24}>
                <Textarea
                  id="resume"
                  label={I18n.get("Mini Bio")}
                  rows={4}
                  maxLength={500}
                  counterChar
                  defaultValue={inputsForm.resume}
                  onChange={(value) => onChangeInputForm("resume", value)}
                  required
                  error={hasError({
                    field: "resume",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "resume",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        <Row style={{ marginTop: 30 }}>
          {contextPage === "full" ? (
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get("Back")}
                theme="secondary"
                full
                onClick={handleBackPage}
              />
            </Col>
          ) : null}
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={
              contextPage === "edit" || contextPage === "admin"
                ? { span: 12, offset: 12 }
                : { span: 12, offset: 0 }
            }
          >
            <ButtonBpool
              text={
                contextPage === "edit" || contextPage === "admin"
                  ? I18n.get("Save")
                  : I18n.get("Save and Next")
              }
              theme="primary"
              full
              loading={isSending}
              onClick={send}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get("Missing required information")}
        subBodyText={I18n.get(
          "Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?"
        )}
        okText={I18n.get("Yes")}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get("Close")}
        typeModal="isConfirm"

        // isDanger
      />
    </>
  );
};
