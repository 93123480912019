import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Task } from "../../utils/task";

import { CLIENT_API } from "../../actions/client/actions";

import LandingPage from "../page-templates/landingForLogin";

import ErrorPane from "../error-pane";

import TextField from "../form/text-field";
import PasswordField from "../form/password-field";
import SubmitButtonField from "../form/submit-button-field";

import { QueryString } from "../../utils/querystring";

import { Tooltip, Icon } from "antd";

const mapStateToProps = (state) => {
  return state.client;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      dispatch(CLIENT_API.create(data));
    },
  };
};

class CreateForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      locale: "pt",
      pdid: null,
      form: {
        name: {
          value: "",
          onChange: this.onChangeForm.bind(this, "name"),
          error: props?.errors?.name,
          successIcon: false,
          visited: true,
          onBlur: this.onBlur.bind(this, "name"),
        },
        email: {
          value: "",
          onChange: this.onChangeForm.bind(this, "email"),
          error: props?.errors?.email,
          successIcon: false,
          visited: true,
          onBlur: this.onBlur.bind(this, "email"),
        },
        password: {
          value: "",
          onChange: this.onChangeForm.bind(this, "password"),
          error: props?.errors?.password,
          successIcon: false,
          visited: true,
          onBlur: this.onBlur.bind(this, "password"),
        },
        password2: {
          value: "",
          onChange: this.onChangeForm.bind(this, "password2"),
          error: props?.errors?.password2,
          successIcon: false,
          visited: true,
          onBlur: this.onBlur.bind(this, "password2"),
        },
      },
      task: {},
    };

    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    var query = QueryString.extract(this.props.location.search);

    let locale = query.locale || "pt";

    let pdid = query.pdid;

    this.setState({
      locale: locale,
      pdid: pdid,
    });

    I18n.setLanguage(locale);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState) return;
    if (!this.props.task) return;

    var nextState = Object.assign({}, prevState);

    if (this.props.task.id != prevState.task.id) {
      nextState.task = this.props.task;

      Object.keys(this.props.errors).forEach(
        (item) => (nextState.form[item].error = this.props.errors[item])
      );

      this.setState(nextState);
    }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].value = value;

      return nextState;
    });
  }

  onBlur(item) {
    // zera o erro
    if (item && typeof this.state.form[item] === "object") {
      this.setState((prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[item].error = null;

        return nextState;
      });
    }
  }

  getData() {
    var data = {};

    Object.keys(this.state.form).forEach((item) => {
      if (
        typeof this.state.form[item] === "object" &&
        "value" in this.state.form[item]
      ) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    let newData = {
      Client: data,
      Locale: this.state["locale"],
      PdId: this.state["pdid"],
    };

    newData.task = this.state.task;

    return newData;
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.onSubmit(this.getData());
    });
  }

  render() {
    if (this.props.email_sent) {
      return <Redirect to="/cliente/email-enviado" />;
    }

    return (
      <LandingPage>
        <div className="middle">
          <section className="section-empty"></section>

          <section className="section-cadastro">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div className="columns paddin-right">
                    <div className="column">
                      <h3 className="title-bpool medium">
                        {I18n.get("Contratar o Pool")}
                      </h3>
                      <p className="form-para">
                        {I18n.get(
                          "BPool é a plataforma que vai conectar por projeto o marketing das empresas a boutiques de comunicação de uma forma como nunca se viu. Uma maneira de entregar um trabalho mais personalizado, elevar o nível de criatividade e inovação, com um preço justo e padronizado."
                        )}
                      </p>
                      <div className="colot-line2"></div>
                    </div>
                  </div>
                </div>

                <div className="column">
                  <div className="form-inner small-signup-form fix-form">
                    <form onSubmit={this.submitForm}>
                      <div className="form-group">
                        <TextField
                          {...this.state.form.name}
                          label={I18n.get("Nome Completo")}
                          inputClassName="form-input"
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          {...this.state.form.email}
                          label={I18n.get("Email")}
                          inputClassName="form-input"
                        />
                      </div>
                      <div className="columns">
                        <div className="column">
                          <div className="form-group">
                            <label className="label is-small" htmlFor="last">
                              <span>
                                {I18n.get("Senha")}
                                <Tooltip
                                  title={I18n.get(
                                    "Use oito ou mais caracteres com uma combinação de letras (maiúsculas e minúsculas), números e símbolos (!@#$%&=?*.:,<>-_+)"
                                  )}
                                >
                                  <Icon
                                    className="iconLabel"
                                    type="question-circle"
                                    theme="filled"
                                  />
                                </Tooltip>
                              </span>
                            </label>
                            <PasswordField
                              {...this.state.form.password}
                              inputClassName="form-input"
                            />
                          </div>
                        </div>
                        <div className="column">
                          <div className="form-group">
                            {/* <label className="form-label" htmlFor="last">{I18n.get("Confirmar senha")}</label> */}
                            <PasswordField
                              {...this.state.form.password2}
                              label={I18n.get("Confirmar senha")}
                              inputClassName="form-input"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <ErrorPane error={this.state.task.error}></ErrorPane>

                        <SubmitButtonField
                          className="button bt-bpool full black"
                          label={I18n.get("Enviar")}
                          onClick={this.submitForm.bind(this)}
                          task={this.state.task}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </LandingPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateForm);
