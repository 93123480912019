import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ContentPartnerStatus = styled.div`
  display: flex;

  .ant-tag {
    font-size: 14px;
    padding: 5px 10px;

    .anticon {
      padding-right: 5px;
    }
  }
`;

export const ButtonChangeStatus = styled.button`
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  margin-left: 15px;
  color: #000;
  cursor: pointer;
`;

export const ContentSelect = styled.div`
  padding: 0 0 30px 0;
`;

export const Errors = styled.div`
  h4 {
    margin: 10px 0 0 0;
    font-size: 14px;
    font-weight: 600;
    color: red;
  }

  ul {
    padding-left: 10px;

    li {
      color: red;
    }
  }
`;

export const SelectAndSave = styled.div`
  button {
    margin-top: 30px;
  }
`;