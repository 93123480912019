import React, { useState } from "react";
import { Unlogged } from "../../components/UI/Unlogged";
import Main from "../../components/UI/Main";
import { Input } from "../../components/UI/Input";
import * as S from "./styles";

import { Select, Icon, notification } from "antd";
import MetaLogo from "./meta.svg";
import GoogleLogo from "./google.svg";

import { NotificationBPool } from "../../components/UI/NotificationBPool";

const { Option } = Select;

const options = [
  {
    code: "meta",
    label: "Meta",
    image: <img src={MetaLogo} alt="Meta" style={{ maxHeight: 20 }} />,
  },
  {
    code: "google",
    label: "Google",
    image: <img src={GoogleLogo} alt="Meta" style={{ maxHeight: 20 }} />,
  },
  {
    divider: true,
    code: "outro",
    label: "Outro certificado",
    image: <Icon type="plus" />,
  },
];

export const PlaygroundComponents = () => {
  const [openNew, setOpenNew] = useState(false);
  const [input, setInput] = useState("");

  const handleChange = (value) => {
    if (value === "outro") {
      return setOpenNew(true);
    } else {
      return setOpenNew(false);
    }
  };

  const openNotificationBP = () => {
    NotificationBPool.open({
      duration: 0,
      type: "success",
      title: "Mensagem 123",
      description: "Descrição 123",
    });
  };

  const openNotificationBPError = () => {
    NotificationBPool.open({
      duration: 3,
      type: "error",
      title: "Error 123",
      description: "Descrição Error 123",
    });
  };

  const openNotificationBPWarning = () => {
    NotificationBPool.open({
      duration: 3,
      type: "warning",
      title: "Warning 123",
      description: "Descrição Warning 123",
    });
  };

  return (
    <Unlogged>
      <div className="container">
        <S.Title>Playground de Components</S.Title>
        <Main bgColor="#fff" padding="30px" style={{ marginBottom: 0 }}>
          <div>
            <Select
              style={{ width: 240 }}
              placeholder="custom dropdown render"
              onChange={handleChange}
            >
              {options?.map((option) => (
                <Option
                  value={option?.code}
                  label={option?.label}
                  style={
                    option?.divider
                      ? { borderTop: "1px solid #ccc", marginTop: 16 }
                      : {}
                  }
                >
                  {option?.image ? (
                    <span
                      role="img"
                      aria-label={option?.label}
                      style={{ marginRight: 8 }}
                    >
                      {option?.image}
                    </span>
                  ) : null}
                  {option?.label}
                </Option>
              ))}
            </Select>

            {openNew ? (
              <div style={{ marginTop: 16, maxWidth: 300 }}>
                <Input
                  placeholder={"Nome do certificado"}
                  label
                  value={input}
                  onChange={(value) => setInput(value)}
                />
              </div>
            ) : null}
          </div>

          <div style={{ marginTop: 20 }}>
            <button onClick={openNotificationBP}>
              ABRIR NOTIFICATION BP SUCCESS
            </button>
          </div>
          <div style={{ marginTop: 20 }}>
            <button onClick={openNotificationBPError}>
              ABRIR NOTIFICATION BP ERROR
            </button>
          </div>
          <div style={{ marginTop: 20 }}>
            <button onClick={openNotificationBPWarning}>
              ABRIR NOTIFICATION BP WARNING
            </button>
          </div>
        </Main>
      </div>
    </Unlogged>
  );
};
