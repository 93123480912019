import React from "react";
import * as S from "./styles";
import { I18n } from "aws-amplify";
import { Icon } from "antd";

export const InfoFile = ({ label, value, isLoading, colorBlack }) => {
  const renderValue = (value) => {
    if (value === 0 || value === "0") {
      return value;
    } else if (value) {
      return value;
    } else {
      return "---";
    }
  };
  return (
    <S.Info colorBlack={colorBlack}>
      <label>{label}</label>
      <S.Skeleton
        loading={isLoading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-infos",
          width: "100px",
        }}
      >
        <p>
          <a target="_blank" href={value} className="description">
            <span>
              <Icon type="file" theme="filled" />
            </span>{" "}
            {I18n.get("Ver arquivo anexo.")}
          </a>
        </p>
      </S.Skeleton>
    </S.Info>
  );
};
