import axios from 'axios';

import Config from '../../config';

import { openNotificationWithIcon } from '../../modules/whatif/helpers/utils';

import { PARTNER_CREATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_create_partner = (data) => {
    return (dispatch) => {

        return axios.post(`${Config.API_BASE_URL}/PartnerWhatIf`, data)
            .then(response => {

 

                dispatch({
                    type: PARTNER_CREATE,
                    payload: {
                        errors: {},
                        task: { result: true }
                    }
                })
            })
            .catch(error => {

                if (error.response.status != 400) {
                    openNotificationWithIcon(error.response.status)
                }

                dispatch({
                    type: PARTNER_CREATE,
                    payload: {
                        errors: error.response.data,
                        task: { result: false }
                    }
                })
            });
    };
}

export default api_create_partner;
