import axios from 'axios';
import { Auth } from 'aws-amplify';
import Config from '../../../config';

axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';

export const useReferences = () => {
  const getConfirmReference = async (tokenId) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/PartnerPreRegistration/reference?verificationToken=${tokenId}`
    );

    return data;
  };

  const getListReferences = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/reference`,
      config
    );

    return data;
  };

  const postReference = async (form) => {
    console.log('postReference', form);
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/Partner/reference`,
      form,
      config
    );

    return data;
  };

  const postReferenceByReference = async (form) => {
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/PartnerPreRegistration/reference`,
      form
    );

    return data;
  };

  const updateReference = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Partner/reference`,
      form,
      config
    );

    return data;
  };

  const deleteReference = async (id) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const dataSend = {
      id,
    };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/Partner/reference`,
      {
        data: dataSend,
        ...config,
      }
    );

    return data;
  };

  const resendEmailReference = async (id) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/Partner/reference-resend-email`,
      { id },
      config
    );

    return data;
  };

  return {
    getConfirmReference,
    getListReferences,
    postReference,
    postReferenceByReference,
    updateReference,
    deleteReference,
    resendEmailReference,
  };
};
