import React, { Fragment } from "react";
import { I18n } from "aws-amplify";
import { Redirect } from "react-router-dom";

import Config from "../../config";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";
import SelectSearchField from "../form/select-search-field";
import SubmitButtonField from "../form/submit-button-field";

import { SelectOne } from "../../v2/components/UI/SelectOne";
import { Input } from "../../v2/components/UI/Input";
import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";

import CreativeOrEvent from "./_creative_or_event";
import SelectTmarket from "./_tmarket";

import { ProjectType } from "../../model";

import styled from "styled-components";

import { Row, Col } from "antd";

const ContainerForm = styled.div`
  max-width: 400px;
  width: 100%;
  margin-top: 30px;

  .bp__value-container {
  }
`;

class BasicInfoForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      typesProduct: [],
      products: [],
      openTmarket: false,
      tMarket: props.tMarket,
      brandId: props.project.brandId,
      form: {
        projectName: {
          label: I18n.get("Nome do projeto"),
          value: props.projectName,
          onChange: this.onChangeForm.bind(this, "projectName"),
          error: props.errors.projectName,
          successIcon: false,
          visited: true,
        },
        productId: {
          label: I18n.get("Marca / Produto"),
          value: props.productId,
          onChange: this.onChangeProductId.bind(this),
          error: props.errors.productId,
          successIcon: false,
          visited: true,
          dataSelect: "productSelect",
        },

        projectType: props.projectType,
        partnerClass: props.partnerClass,
        eventType: props.eventType,
        isSubmit: false,
      },
      task: {},
    };
  }

  // componentDidMount() {
  //     this.onProjectTypeClick.bind(this, ProjectType.Creative)
  // }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];

          if ("checked" in nextState.form[item]) {
            nextState.form[item].checked = this.props[item];
          } else {
            nextState.form[item].value =
              item == "productId"
                ? `${this.props[item]}-${this.props.brandId}`
                : this.props[item];

            if (this.props.productId) {
              let data = {
                productId: this.props.productId,
                brandId: this.props.brandId,
              };

              this.props.get_types(data).then((response) => {
                this.setState({
                  typesProduct: response,
                });
              });

              data["allowMultipleMarket"] =
                this.props.client?.customization?.allowMultipleMarket;

              this.props.get_tmarket(data).then((response) => {
                switch (response) {
                  case "Both":
                    this.setState({
                      openTmarket: true,
                    });
                    break;
                  case "Premium":
                    this.setState({
                      tMarket: this.props.tMarket,
                    });
                    break;
                  case "Master":
                    this.setState({
                      tMarket: this.props.tMarket,
                    });
                    break;

                  default:
                    break;
                }
              });
            }
          }
        } else if (typeof nextState.form[item] == "undefined") {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].value = value;

      return nextState;
    });
  }

  onSelectMarket = (t) => {
    this.setState({
      tMarket: t,
    });
  };

  onChangeProductId(value, e) {
    let ids = value.split("-");

    let data = {
      productId: ids[0],
      brandId: ids[1],
    };

    this.setState({ brandId: ids[1] });

    this.props.get_types(data).then((response) => {
      this.setState({
        typesProduct: response,
      });
    });

    data["allowMultipleMarket"] =
      this.props.client.customization.allowMultipleMarket;

    this.props.get_tmarket(data).then((response) => {
      let type = "";

      switch (response) {
        case "Both":
          this.setState({
            openTmarket: true,
          });
          break;
        case "Premium":
          this.setState({
            tMarket: 1,
          });
          break;
        case "Master":
          this.setState({
            tMarket: 2,
          });
          break;

        default:
          break;
      }
    });

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["productId"].value = value;

      return nextState;
    });
  }

  onProjectTypeClick(value, e) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["projectType"] = value;

      return nextState;
    });
  }

  onPartnerClassClick(value, e) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form["partnerClass"] = value;

      return nextState;
    });
  }

  onBlur(item) {
    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      FormUtils.clearError(nextState, item);

      return nextState;
    });
  }

  getData() {
    var data = {
      clientId: this.props.clientId,
      ...this.props.project,
      tMarket: this.state.tMarket,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (FormUtils.isFormField(this.state.form[item])) {
        data[item] =
          item == "productId"
            ? this.state.form[item].value.split("-")[0]
            : this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  onSave(item) {
    if (this.props.isAdmin) return;

    // zera o erro
    if (item && typeof this.state.form[item] === "object") {
      this.state.form[item].error = null;
    }
  }

  submitForm(e) {
    var task = new Task();

    task.start();
    this.setState({ task: task, isSubmit: true }, function () {
      var data = this.getData();

      if (data.projectId) {
        this.props.save(data);
      } else {
        this.props.addProject(data);
      }
    });
  }

  getProducts() {
    var items = [];

    this.props.client.brands &&
      this.props.client.brands.map((brand) =>
        brand.products.map((product) => {
          if (product.categoryId) {
            items.push({
              code: `${product.id}-${brand.id}`,
              label: `${brand.name} - ${product.name}`,
            });
          }
        })
      );

    return items;
  }

  printModuleProject() {
    return (
      <Fragment>
        <CreativeOrEvent
          types={this.state?.typesProduct || []}
          projectType={this.state.form.projectType}
          onCreativeClick={this.onProjectTypeClick.bind(
            this,
            ProjectType.Creative
          )}
          onEventClick={this.onProjectTypeClick.bind(
            this,
            ProjectType.EventCustom
          )}
          onResetClick={this.onProjectTypeClick.bind(this, undefined)}
          onCustomClick={this.onProjectTypeClick.bind(
            this,
            ProjectType.EventCustom
          )}
          onBPitchClick={this.onProjectTypeClick.bind(this, ProjectType.BPitch)}
          locale={this.props.client.signupLocale || "pt"}
          disabled={this.props.projectId}
        />
        {/*
            {this.state.form.projectType === ProjectType.Creative && Config.moduloMasterSenior && <div>

                <MasterOrSenior
                    partnerClass={this.state.form.partnerClass}
                    onMasterClick={this.onPartnerClassClick.bind(this, 'Master')}
                    onSeniorClick={this.onPartnerClassClick.bind(this, 'Senior')} />
            </div>} */}

        {/* {(this.state.form.projectType == ProjectType.EventCustom || this.state.form.projectType == ProjectType.EventBundle) && <div>

                <CustomOrBundle
                    projectType={this.state.form.projectType}
                    onCustomClick={this.onProjectTypeClick.bind(this, ProjectType.EventCustom)}
                    onBundleClick={this.onProjectTypeClick.bind(this, ProjectType.EventBundle)} />
            </div>} */}
      </Fragment>
    );
  }

  render() {
    if (
      this.state.isSubmit &&
      this.props.clientWhatIf.whatIfId &&
      this.props.clientWhatIf.projectType == 3 &&
      Object.keys(this.props.clientWhatIf.errors).length === 0
    ) {
      return (
        <Redirect
          to={`/cliente/ideias/brand-profile/${this.props.clientWhatIf.whatIfId}`}
        />
      );
    }

    if (
      this.state.isSubmit &&
      this.props.projectId &&
      this.props.projectType != 3
    ) {
      return (
        <Redirect to={`/cliente/projetos/${this.props.projectId}/passo-2`} />
      );
    }

    const brandSelected = this.props.client.brands?.find(
      (b) => b.id === this.state.brandId
    );

    const productSelected = brandSelected?.products?.find(
      (p) => p.id === this.state.form.productId.value.split("-")[0]
    );

    console.log("this.state.task",this.state.task);

    return (
      <form>
        <Row>
          <Col sm={24}>
            <div className="np-basicFormInit">
              <p class="text">
                {I18n.get(
                  "Oba, um novo projeto está nascendo! Atribua um nome a ele e selecione para qual marca e produto este projeto será desenvolvido. Nas telas seguintes você irá selecionar o escopo desejado e critérios para seleção do parceiro."
                )}
              </p>

              <p class="text">
                {I18n.get(
                  "Não se preocupe, este ainda é um draft e o projeto só será aberto quando você concluir todas as definições do projeto. Vamos lá?"
                )}
              </p>

              {this.props.client.customization?.newProjectWarning && (
                <p class="text">
                  <b>{this.props.client.customization?.newProjectWarning}</b>
                </p>
              )}

              <ContainerForm>
                <div className="fields-form-space">
                  {this.state.form.projectType !== 3 && (
                    // <TextField
                    //   data-input="projectName"
                    //   {...this.state.form.projectName}
                    // />

                    <Input
                      label={I18n.get("Nome do projeto")}
                      value={this.state.form.projectName.value}
                      onChange={(e) => this.onChangeForm("projectName", e)}
                      placeholder={I18n.get("Nome do projeto")}
                      error={this.state.form.projectName.error}
                    />
                  )}
                  <br />
                  {/* <SelectSearchField
                    data-select="selectProduct"
                    {...this.state.form.productId}
                    values={this.getProducts()}
                    tooltip={
                      "Estas são as marcas e produtos cadastradas pela sua empresa na BPool.Se desejar adicionar outras marcas ou outros produtos, favor contactar o responsável pelo cadastro"
                    }
                  /> */}

                  <SelectOne
                    options={this.getProducts()}
                    value={this.state.brandId ? this.state.form.productId.value : null}
                    onChange={(value) => this.onChangeProductId(value)}
                    placeholder={I18n.get("Marca / Produto")}
                    error={this.state.form.productId.error}
                  />
                </div>
              </ContainerForm>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={24}>{this.printModuleProject()}</Col>
        </Row>

        {this.state.form.projectType === 0 &&
          this.props.client?.customization?.allowMultipleMarket &&
          this.state.openTmarket && (
            <Row>
              <Col lg={24}>
                <SelectTmarket
                  active={this.props?.tMarket || this.state.tMarket}
                  onSelectMarket={this.onSelectMarket}
                  disabled={this.props.projectId}
                />
              </Col>
            </Row>
          )}

        {productSelected &&
          productSelected.global &&
          this.state.form.projectType === 0 && (
            <div>
              <Row>
                <Col sm={24}>
                  <Row>
                    <Col sm={24}>
                      <div className="msgGlobal">
                        <h2>{I18n.get("Atenção")}</h2>

                        {this.props.client?.customization
                          ?.produtoGlobalMessage && (
                          <p>
                            {" "}
                            {
                              this.props.client?.customization
                                ?.produtoGlobalMessage
                            }
                          </p>
                        )}

                        {!this.props.client?.customization
                          ?.produtoGlobalMessage && (
                          <>
                            <p>
                              {I18n.get(
                                "Esta é uma marca Global. Lembre-se de solicitar a aprovação global para avançar com a agência escolhida"
                              )}
                            </p>
                            <p>
                              {I18n.get(
                                "Você deverá anexar a aprovação no momento da escolha do parceiro."
                              )}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}

        <Row>
          <Col sm={24}>
            <Row>
              <Col sm={24}>
                <div className="" onClick={this.scrollToTop}>
                
                <p style={{display: "flex", justifyContent: "end", marginTop: 30} }>
                <ButtonBpool
                        text={I18n.get("Próximo")}
                        className=""
                        theme="primary"
                        loading={this.state.task?.busy}
                        onClick={this.submitForm.bind(this)}
                        disabled={
                          (this.props.client?.customization?.allowMultipleMarket &&
                            this.state.form.projectType === 0 &&
                            !this.state.tMarket) ||
                          this.state.form.projectType === undefined ||
                          this.state.form.productId.value === undefined ||
                          this.state.form.projectName.value === undefined
                        }
                      />
                  </p>

                  {/* <SubmitButtonField
                    label={I18n.get("Próximo")}
                    data-button="next"
                    className={`button bt-bpool next`}
                    onClick={this.submitForm.bind(this)}
                    task={this.state.task}
                    disabled={
                      (this.props.client?.customization?.allowMultipleMarket &&
                        this.state.form.projectType === 0 &&
                        !this.state.tMarket) ||
                      this.state.form.projectType === undefined ||
                      this.state.form.productId.value === undefined ||
                      this.state.form.projectName.value === undefined
                    }
                  /> */}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    );
  }
}

export default BasicInfoForm;
