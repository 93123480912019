import React from "react";
import { I18n } from "aws-amplify";
import IconAlert from "../../../svgs/info-circle.svg";
import * as S from "./styles";

export const AlertBpayments = ({ data }) => {
  if (data?.anyInvoiceAwaitingAction === true) {
    return (
      <S.Wrapper>
        <S.Icon>
          <img src={IconAlert} alt="Alert icon" />
        </S.Icon>
        <S.Infos>
          <h3>
            {I18n.get("Alguns BPayments precisam de ajustes ou atualizações")}
          </h3>
          <p>
            <strong>{I18n.get("Atenção")}!</strong>
            <span>
              {I18n.get(
                "Os itens destacados em amarelo na tabela necessitam de ajustes ou atualizações, prossiga com eles para o andamento de seu pagamento."
              )}
            </span>
          </p>
        </S.Infos>
      </S.Wrapper>
    );
  } else {
    return <></>;
  }
};
