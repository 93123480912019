import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 465px;
  margin: 0 auto;
  min-height: 450px;

  img {
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 32px;
    text-align: center;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 32px;
    text-align: center;
  }
`;
