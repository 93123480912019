import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const get_brands = (clientId) => {
    
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };
            

            return axios.get(`${Config.API_BASE_URL}/bp/client/clientBrands/${clientId}`, config)
                .then(response => {

                 

                    return response;
                })
                .catch(error => {

                    // Nao faz o dispatch no erro, tratamento direto na tela
                    const payload = {
                        errors: error.response.data,
                        task: {
                            result: false
                        }
                    };

                    return payload;
                });

        }).catch(error => {

            dispatch({ type: EXPIRED });
        });
    };
}

export default get_brands;
