import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Amplify, { I18n } from 'aws-amplify'
import Config from './config';
import App from './App';
import { store } from './store';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost'
      || window.location.hostname === '[::1]'
      || window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  if (!isLocalhost) {


  }

Amplify.configure({
    Auth: {
        region: Config.AWS_COGNITO_REGION,
        userPoolId: Config.AWS_COGNITO_USERPOOL_ID,
        userPoolWebClientId: Config.AWS_COGNITO_CLIENT_ID,
    }
});

const vocabulary = require('./vocabulary.json');

I18n.putVocabularies(vocabulary);

I18n.setLanguage('pt');

//TODO: setar conforme o locale do usuário e fazer cache no localStorage

ReactDOM.render(<Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'));

//serviceWorker.register();

