export const statusIdToName = async ({ step, status }) => {
  if (status === 1) {
    return "process";
  } else if (status === 2) {
    return "error";
  } else if (status === 3) {
    return "wait";
  } else if (status === 4) {
    return "finish";
  }
};
