
import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TooltipField from '../form/tooltip-field';


const mapStateToProps = state => {
    return state;
};
const mapDispatchToProps = dispatch => {
    return {}
};

class Steps extends React.Component {
    constructor(props) {
        super();
        this.state = {
            items: [
                {
                    index: 1,
                    title: I18n.get("Briefing"),
                    color: "#FFC5B9",
                    link: `/parceiro/eventos/${props.projectId}/convite-passo-1`
                },
                {
                    index: 2,
                    title: I18n.get("Perguntas & Respostas"),
                    color: "#E3FBB8",
                    link: `/parceiro/eventos/${props.projectId}/convite-passo-2`
                },
                {
                    index: 3,
                    title: I18n.get("Proposta"),
                    color: "#E3FBB8",
                    link: `/parceiro/eventos/${props.projectId}/convite-passo-3`
                },
                {
                    index: 4,
                    title: I18n.get("Mensagens"),
                    color: "#E3FBB8",
                    link: `/parceiro/eventos/${props.projectId}/convite-passo-4`
                }
            ]
        };
    }

    render() {
        const { items } = this.state
        const { active, disabled = [], hidden = [] } = this.props



        let txt = '';



        return (
            <div>
                <ul id="partner-signup-steps" className="reference-signup-steps">
                    {items.map(function (item) {

                        if (hidden.indexOf(item.index) > -1) return null;

                        const enabled = disabled.indexOf(item.index) == -1;

                        const lineStyle = {
                            background: item.color,
                        };
                        return (<li key={item.index} className={(active && item.index === active && "progress-bar--item is-active") || "progress-bar--item"}>
                            <h3>{item.index}
                                {active && item.index === active && <TooltipField tooltip={txt} orientation="bottom" canClose={true} withoutquestionmark="true" isfixed="true" />}
                            </h3>

                            <Link to={enabled ? item.link : '#'} className={["progress-bar--menu-item", enabled ? '' : 'link-disable'].join(' ')} >
                                <span style={lineStyle}></span>
                                <strong className="is-not-mobile">{item.title}</strong>
                            </Link>
                        </li>)
                    })}
                </ul>
            </div>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
