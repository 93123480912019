import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { I18n } from "aws-amplify";
import { Row, Col, Select, Checkbox, message } from "antd";
import { InputMask } from "../../../../components/UI/InputMask";
import { InputFlag } from "../../../../components/UI/InputFlag";
import Main from "../../../../components/UI/Main";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { SelectOne } from "../../../../components/UI/SelectOne";
import { Input } from "../../../../components/UI/Input";
import { OutlineUpload } from "../../../../components/UI/OutlineUpload";
import constants from "../../../../../constants";
import COUNTRY_CODES from "./countryCodes.json";
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from "../../utils/flowToStorage";
import { statusIdToName } from "../../components/Loggedv2/statusIdToName";
import { useUpload } from "../../../../hooks/useUpload/useUpload.hook";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { BP_API } from "../../../../../actions/bp/actions";
import { urlsFull as urls } from "../../utils/stepsUrls";
import { hasError, clearErrorInput } from "../../utils/errorsInput";
import { inputsState } from "./inputsState";
import { BillingAccounts } from "./BillingAccounts";

import * as S from "./styles";

const MESSAGE_ERROR_GET_PAGE = "There was an error, try again.";
const ERROR_IMAGE_UP = "Please insert an image in the correct sizes.";
const MESSAGE_ERROR_BACK_PAGE = "Error in back page.";
const PAGE_INDEX = 8;
const { Option } = Select;

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  let params = useParams();
  const state = useSelector((state) => state);
  const listCountries = useSelector((state) => state.bp.countryCodes);
  let history = useHistory();
  const dispatch = useDispatch();
  const {
    getStatusBarFull,
    getCompanyInfos,
    getCompanyTeamSize,
    updateCompanyInfos,
  } = useRegistrationFlowFull();

  const { uploadFile } = useUpload();
  const [inputsFormInitals, setInputsFormInitals] = useState(inputsState);
  const [inputsForm, setInputsForm] = useState(inputsState);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [teamSizes, setTeamSizes] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isLoadPage, setIsLoadPage] = useState(false);
  const [valueMedia, setValueMedia] = useState(1);
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [fileDefaultGet, setFileDefaultGet] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);

  const onChangeInputsBilling = (form) => {
    setInputsForm({ ...inputsForm, ...form });
  };

  const handleCopyAddress = (checked) => {
    const newErrors = inputsErrorsForm.filter(
      (i) =>
        i.field != "commercialAddressLine1" &&
        i.field != "commercialAddressLine2" &&
        i.field != "commercialPostcode" &&
        i.field != "commercialCity" &&
        i.field != "commercialCountry" &&
        i.field != "commercialPhoneNumber" &&
        i.field != "commercialState"
    );

    setInputsErrorsForm(newErrors);

    if (inputsForm?.copyAddressToCommercialAddress) {
      setInputsForm({
        ...inputsForm,
        commercialPostcode: "",
        commercialAddressLine1: "",
        commercialAddressLine2: "",
        commercialCity: "",
        commercialCountry: "",
        commercialPhoneNumber: "",
        commercialState: "",
        copyAddressToCommercialAddress:
          !inputsForm?.copyAddressToCommercialAddress,
      });
    } else {
      setInputsForm({
        ...inputsForm,
        commercialPostcode: inputsForm?.postcode,
        commercialAddressLine1: inputsForm?.addressLine1,
        commercialAddressLine2: inputsForm?.addressLine2,
        commercialCity: inputsForm?.city,
        commercialCountry: inputsForm?.country,
        commercialPhoneNumber: inputsForm?.phoneNumber,
        commercialState: inputsForm?.state,
        copyAddressToCommercialAddress:
          !inputsForm?.copyAddressToCommercialAddress,
        commercialCountryCodePhoneNumber: inputsForm?.countryCodePhoneNumber,
      });
    }
  };

  const closeModalPreview = () => {
    setOpenModalPreview(false);
  };

  const onChangeInputForm = (id, value, isCountry) => {
    if (isCountry) {
      const obj = listCountries.find((i) => i.code == value);

      setInputsForm({
        ...inputsForm,
        [id]: value ? obj : null,
      });
    } else {
      setInputsForm({
        ...inputsForm,
        [id]: value ? value : null,
      });
    }

    if (inputsForm?.country?.code == "BRA" && id == "postcode") {
      if (value.length >= 8) {
        onPostcodeSearchClick(value, id);
      }
    }

    if (
      inputsForm?.commercialCountry?.code == "BRA" &&
      id == "commercialPostcode"
    ) {
      if (value.length >= 8) {
        onPostcodeSearchClick(value, id);
      }
    }
  };

  const onChangeInputPhone = ({ valuePhone, valuePhoneCountry }) => {
    setInputsForm({
      ...inputsForm,
      phoneNumber: valuePhone ? valuePhone : null,
      countryCodePhoneNumber: valuePhoneCountry ? valuePhoneCountry : null,
    });
  };

  const onChangeCommercialInputPhone = ({ valuePhone, valuePhoneCountry }) => {
    setInputsForm({
      ...inputsForm,
      commercialPhoneNumber: valuePhone ? valuePhone : null,
      commercialCountryCodePhoneNumber: valuePhoneCountry
        ? valuePhoneCountry
        : null,
    });
  };

  const fileToUpload = ({ field, file }) => {
    setInputsForm({
      ...inputsForm,
      [field]: file ? file : null,
    });
  };

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarFull();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);

    let responseFileDocCnpj = null;
    let responseFileDocNegativeCertificate = null;
    let responseFileDocAccountStatement = null;
    let responseFileDocSocialContract = null;

    if (inputsForm?.docCnpjCard?.name) {
      responseFileDocCnpj = await uploadFile({
        file: inputsForm?.docCnpjCard,
        idUser: "usertestingnow",
        fieldId: `logoImage`,
      });
    }

    if (inputsForm?.docAccountStatement?.name) {
      responseFileDocAccountStatement = await uploadFile({
        file: inputsForm?.docAccountStatement,
        idUser: "usertestingnow",
        fieldId: `logoImage`,
      });
    }

    if (inputsForm?.docNegativeCertificate?.name) {
      responseFileDocNegativeCertificate = await uploadFile({
        file: inputsForm?.docNegativeCertificate,
        idUser: "usertestingnow",
        fieldId: `logoImage`,
      });
    }

    if (inputsForm?.docSocialContract?.name) {
      responseFileDocSocialContract = await uploadFile({
        file: inputsForm?.docSocialContract,
        idUser: "usertestingnow",
        fieldId: `logoImage`,
      });
    }

    const formData = {
      adminAction: contextPage === "admin" ? true : false,
      id: contextPage === "admin" ? partnerId : null,
      ...inputsForm,
      docAccountStatement: responseFileDocAccountStatement
        ? responseFileDocAccountStatement
        : inputsForm?.docAccountStatement,
      docCnpjCard: responseFileDocCnpj
        ? responseFileDocCnpj
        : inputsForm?.docCnpjCard,
      docNegativeCertificate: responseFileDocNegativeCertificate
        ? responseFileDocNegativeCertificate
        : inputsForm?.docNegativeCertificate,
      docSocialContract: responseFileDocSocialContract
        ? responseFileDocSocialContract
        : inputsForm?.docSocialContract,
    };

    setInputsForm({
      ...inputsForm,
      docAccountStatement: formData?.docAccountStatement,
      docCnpjCard: formData?.docCnpjCard,
      docNegativeCertificate: formData?.docNegativeCertificate,
      docSocialContract: formData?.docSocialContract,
    });

    const response = await updateCompanyInfos({
      admin: contextPage === "admin" ? true : false,
      form: formData,
    });
    if (contextPage === "full") {
      await newGetStepbar();
    }

    if (response?.errors?.length) {
      if (contextPage === "full") {
        setOpenModalConfirm(true);
      }
      setInputsErrorsForm(response?.errors);
    } else {
      if (contextPage === "full") {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: urls[PAGE_INDEX + 1],
        });
      }
      if (contextPage === "edit" || contextPage === "admin") {
        sendOk(true);
      }
    }

    setIsSending(false);
  };

  const handleBackPage = () => {
    const url = urls[PAGE_INDEX - 1];

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  const loadTeamSize = async () => {
    const response = await getCompanyTeamSize();

    if (response.success) {
      const data = [...response.data];

      setTeamSizes(data);
    }
  };

  const loadInit = async () => {
    setIsLoadPage(true);

    const response = await getCompanyInfos({
      admin: contextPage === "admin" ? true : false,
    });

    if (response.success) {
      const data = {
        ...response.data.company,
      };

      setInputsFormInitals(data);
      setInputsForm(data);
    }

    setIsLoadPage(false);
  };

  const onChangeSelectCountry = (id, value) => {
    const country = COUNTRY_CODES.find((item) => item.name === value);

    setInputsForm({
      ...inputsForm,
      [id]: country ? country : null,
      phoneNumber: "",
    });
  };

  const removeFileDefault = (field) => {
    setInputsForm({
      ...inputsForm,
      [field]: null,
    });

    setFileUpload(null);
    setFileDefaultGet(null);
  };

  const onPostcodeSearchClick = (value, field) => {
    if (value) {
      return axios
        .get(`https://viacep.com.br/ws/${value}/json/`)
        .then((response) => {
          if (field == "postcode") {
            setInputsForm({
              ...inputsForm,
              postcode: value,
              addressLine1: response?.data?.logradouro,
              city: response?.data?.localidade,
              state: response?.data?.uf,
            });
          }

          if (field == "commercialPostcode") {
            setInputsForm({
              ...inputsForm,
              commercialPostcode: value,
              commercialAddressLine1: response?.data?.logradouro,
              commercialCity: response?.data?.localidade,
              commercialState: response?.data?.uf,
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  //validation
  useEffect(() => {
    if (inputsForm !== inputsFormInitals) {
      setChangeInfo(true);
      onChangeInfo(true);
    } else {
      setChangeInfo(false);
      onChangeInfo(false);
    }
  }, [inputsForm]);

  useEffect(() => {
    loadInit();
    window.scrollTo(0, 0);
    loadTeamSize();
  }, []);

  useEffect(() => {
    if (params.partnerId) {
      if (!state?.bp?.partner) {
        dispatch(BP_API.get_partner(params.partnerId));
      }
    }
  }, [state]);

  return (
    <>
      <S.Wrapper>
        <Main bgColor="#fff" padding="30px">
          <Row>
            <Col xs={24}>
              <S.RowCenter>
                <S.TitleWithIcon>
                  <S.H4>{I18n.get("Company Details")}</S.H4>
                </S.TitleWithIcon>
                <S.Paragraph>
                  {I18n.get(
                    "More details about your business to set up receiving payments"
                  )}
                </S.Paragraph>
              </S.RowCenter>
            </Col>
          </Row>
          <Row className="RowGroup">
            <Col xs={24} style={{ marginTop: 30 }}>
              <S.TitleSectionForm>
                {I18n.get("Company Information")}
              </S.TitleSectionForm>
            </Col>
            <Col xs={24} sm={12} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("CNPJ")}
                label
                required
                value={inputsForm?.registrationId}
                disabled={true}
                error={hasError({
                  field: "registrationId",
                  arr: inputsErrorsForm,
                })}
              />
            </Col>
            <Col xs={24} sm={12} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("Company name")}
                label
                required
                value={inputsForm?.name}
                onChange={(value) => onChangeInputForm("name", value)}
                error={hasError({ field: "name", arr: inputsErrorsForm })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "name",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>
            <Col xs={24} sm={12} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("Registration name")}
                label
                required
                value={inputsForm?.registrationName}
                onChange={(value) =>
                  onChangeInputForm("registrationName", value)
                }
                error={hasError({
                  field: "registrationName",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "registrationName",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>

            <Col xs={24} sm={12} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("Billing email")}
                label
                required
                value={inputsForm?.commercialEmail}
                onChange={(value) =>
                  onChangeInputForm("commercialEmail", value)
                }
                error={hasError({
                  field: "commercialEmail",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "commercialEmail",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>

            <Col xs={24} sm={12} style={{ height: 80 }}>
              <SelectOne
                options={teamSizes}
                value={inputsForm?.teamSizeRange}
                defaultValue={inputsForm?.teamSizeRange}
                onChange={(value) => onChangeInputForm("teamSizeRange", value)}
                required
                placeholder={I18n.get("Team size")}
                error={hasError({
                  field: "teamSizeRange",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "teamSizeRange",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>
          </Row>
          <Row id="AddressCompany" className="RowGroup">
            <Col xs={24} style={{ marginTop: 30, height: 80 }}>
              <S.TitleSectionForm>
                {I18n.get("Address Company")}
              </S.TitleSectionForm>
            </Col>

            <Col xs={24} sm={7} style={{ height: 80 }}>
              <SelectOne
                options={listCountries || []}
                value={inputsForm?.country?.code}
                defaultValue={inputsForm?.country?.code}
                isCountry
                onChange={(value) => onChangeInputForm("country", value, true)}
                required
                placeholder={I18n.get("Country")}
                error={hasError({
                  field: "country",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "country",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>

            <Col xs={24} sm={7} style={{ height: 80 }}>
              {inputsForm?.country?.code == "BRA" && (
                <InputMask
                  id="postcode"
                  label={I18n.get("CEP")}
                  defaultValue={inputsForm?.postcode}
                  onChange={(value) =>
                    onChangeInputForm("postcode", value?.value)
                  }
                  mask="#####-###"
                  required
                  error={hasError({
                    field: "postcode",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "postcode",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              )}

              {inputsForm?.country?.code != "BRA" && (
                <Input
                  id="postcode"
                  placeholder={I18n.get("CEP")}
                  label
                  value={inputsForm?.postcode}
                  onChange={(value) => onChangeInputForm("postcode", value)}
                  required
                  error={hasError({
                    field: "postcode",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "postcode",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              )}
            </Col>

            <Col xs={24} sm={10} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("Address")}
                label
                required
                value={inputsForm?.addressLine1}
                onChange={(value) => onChangeInputForm("addressLine1", value)}
                error={hasError({
                  field: "addressLine1",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "addressLine1",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>

            <Col xs={24} sm={7} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("Complement")}
                label
                required
                value={inputsForm?.addressLine2}
                onChange={(value) => onChangeInputForm("addressLine2", value)}
                error={hasError({
                  field: "addressLine2",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "addressLine2",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>

            <Col xs={24} sm={8} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("City")}
                label
                required
                value={inputsForm?.city}
                onChange={(value) => onChangeInputForm("city", value)}
                error={hasError({ field: "city", arr: inputsErrorsForm })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "city",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>
            <Col xs={24} sm={9} style={{ height: 80 }}>
              {inputsForm?.country?.code == "BRA" && (
                <SelectOne
                  options={constants.STATES}
                  value={inputsForm?.state}
                  defaultValue={inputsForm?.state}
                  onChange={(value) => onChangeInputForm("state", value)}
                  required
                  placeholder={I18n.get("State")}
                  error={hasError({
                    field: "state",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "state",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              )}
              {inputsForm?.country?.code != "BRA" && (
                <Input
                  placeholder={I18n.get("State")}
                  label
                  required
                  value={inputsForm?.state}
                  onChange={(value) => onChangeInputForm("state", value)}
                  error={hasError({
                    field: "state",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "state",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              )}
            </Col>

            <Col xs={24} sm={9} style={{ height: 80 }}>
              {/* <InputMask
                id="phoneNumber"
                label={I18n.get("Phone number")}
                defaultValue={inputsForm?.phoneNumber}
                onChange={(value) =>
                  onChangeInputForm("phoneNumber", value?.value)
                }
                mask="(##) #####-####"
                required
                error={hasError({
                  field: "phoneNumber",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "phoneNumber",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              /> */}
              <InputFlag
                id="phoneNumber"
                label={I18n.get("Phone number")}
                defaultValue={inputsForm?.phoneNumber}
                flagCodeDefault={inputsForm?.countryCodePhoneNumber || "BRA"}
                onChange={(value) =>
                  onChangeInputPhone({
                    valuePhone: value?.value,
                    valuePhoneCountry: value?.country,
                  })
                }
                required
                error={hasError({
                  field: "phoneNumber",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "phoneNumber",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>
          </Row>

          <Row id="CommercialAddress" className="RowGroup">
            <Col xs={24} style={{ marginTop: 30, height: 80 }}>
              <S.TitleSectionForm>
                {I18n.get("Commercial Address")}
              </S.TitleSectionForm>
            </Col>

            <Col sm={24}>
              <S.BoxCheck>
                <S.LabelForm>
                  {I18n.get("The same as the company")}:
                </S.LabelForm>

                <Checkbox
                  checked={inputsForm?.copyAddressToCommercialAddress}
                  onChange={(e) => {
                    handleCopyAddress(e);
                  }}
                >
                  {I18n.get("Yes, it is the same address as the CNPJ")}
                </Checkbox>
              </S.BoxCheck>
            </Col>

            <Col xs={24} sm={7} style={{ height: 80 }}>
              <SelectOne
                options={listCountries || []}
                value={inputsForm?.commercialCountry?.code}
                defaultValue={inputsForm?.commercialCountry?.code}
                isCountry
                onChange={(value) =>
                  onChangeInputForm("commercialCountry", value, true)
                }
                required
                placeholder={I18n.get("Country")}
                disabled={inputsForm?.copyAddressToCommercialAddress}
                error={hasError({
                  field: "commercialCountry",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "commercialCountry",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>

            <Col xs={24} sm={7} style={{ height: 80 }}>
              {inputsForm?.commercialCountry?.code == "BRA" && (
                <InputMask
                  id="commercialPostcode"
                  label={I18n.get("CEP")}
                  defaultValue={inputsForm?.commercialPostcode}
                  onChange={(value) =>
                    onChangeInputForm("commercialPostcode", value?.value)
                  }
                  mask="#####-###"
                  required
                  error={hasError({
                    field: "commercialPostcode",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "commercialPostcode",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              )}

              {inputsForm?.commercialCountry?.code != "BRA" && (
                <Input
                  id="commercialPostcode"
                  placeholder={I18n.get("CEP")}
                  label
                  value={inputsForm?.commercialPostcode}
                  onChange={(value) =>
                    onChangeInputForm("commercialPostcode", value)
                  }
                  required
                  error={hasError({
                    field: "commercialPostcode",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "commercialPostcode",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              )}
            </Col>

            <Col xs={24} sm={10} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("Address")}
                label
                required
                value={inputsForm?.commercialAddressLine1}
                onChange={(value) =>
                  onChangeInputForm("commercialAddressLine1", value)
                }
                error={hasError({
                  field: "commercialAddressLine1",
                  arr: inputsErrorsForm,
                })}
                disabled={inputsForm?.copyAddressToCommercialAddress}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "commercialAddressLine1",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>

            <Col xs={24} sm={7} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("Complement")}
                label
                required
                value={inputsForm?.commercialAddressLine2}
                onChange={(value) =>
                  onChangeInputForm("commercialAddressLine2", value)
                }
                error={hasError({
                  field: "commercialAddressLine2",
                  arr: inputsErrorsForm,
                })}
                disabled={inputsForm?.copyAddressToCommercialAddress}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "commercialAddressLine2",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>

            <Col xs={24} sm={8} style={{ height: 80 }}>
              <Input
                placeholder={I18n.get("City")}
                label
                required
                value={inputsForm?.commercialCity}
                onChange={(value) => onChangeInputForm("commercialCity", value)}
                error={hasError({
                  field: "commercialCity",
                  arr: inputsErrorsForm,
                })}
                disabled={inputsForm?.copyAddressToCommercialAddress}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "commercialCity",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>
            <Col xs={24} sm={9} style={{ height: 80 }}>
              {inputsForm?.commercialCountry?.code == "BRA" && (
                <SelectOne
                  options={constants.STATES}
                  value={inputsForm?.commercialState}
                  defaultValue={inputsForm?.commercialState}
                  onChange={(value) =>
                    onChangeInputForm("commercialState", value)
                  }
                  required
                  disabled={inputsForm?.copyAddressToCommercialAddress}
                  placeholder={I18n.get("State")}
                  error={hasError({
                    field: "commercialState",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "commercialState",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              )}
              {inputsForm?.commercialCountry?.code != "BRA" && (
                <Input
                  placeholder={I18n.get("State")}
                  label
                  required
                  value={inputsForm?.commercialState}
                  onChange={(value) =>
                    onChangeInputForm("commercialState", value)
                  }
                  disabled={inputsForm?.copyAddressToCommercialAddress}
                  error={hasError({
                    field: "commercialState",
                    arr: inputsErrorsForm,
                  })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "commercialState",
                      arr: inputsErrorsForm,
                    });
                    setInputsErrorsForm(errorsArr);
                  }}
                />
              )}
            </Col>

            <Col xs={24} sm={9} style={{ height: 80 }}>
              {/* <InputMask
                id="commercialPhoneNumber"
                label={I18n.get("Phone number")}
                defaultValue={inputsForm?.commercialPhoneNumber}
                onChange={(value) =>
                  onChangeInputForm("commercialPhoneNumber", value?.value)
                }
                mask="(##) #####-####"
                required
                error={hasError({
                  field: "commercialPhoneNumber",
                  arr: inputsErrorsForm,
                })}
                disabled={inputsForm?.commercialPhoneNumber}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "commercialPhoneNumber",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              /> */}
              <InputFlag
                id="commercialPhoneNumber"
                label={I18n.get("Phone number")}
                defaultValue={inputsForm?.commercialPhoneNumber}
                flagCodeDefault={
                  inputsForm?.commercialCountryCodePhoneNumber || "BRA"
                }
                onChange={(value) =>
                  onChangeCommercialInputPhone({
                    valuePhone: value?.value,
                    valuePhoneCountry: value?.country,
                  })
                }
                required
                error={hasError({
                  field: "commercialPhoneNumber",
                  arr: inputsErrorsForm,
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "commercialPhoneNumber",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
              />
            </Col>
          </Row>

          <BillingAccounts contextPage={contextPage} />

          <S.RowDocs>
            <Col xs={24} style={{ marginTop: 30 }}>
              <S.TitleSectionForm>
                {I18n.get("Essential Documents")}
              </S.TitleSectionForm>
            </Col>

            <Col sm={12} style={{ height: 90 }}>
              <OutlineUpload
                id="docCnpjCard"
                fileToUpload={(id, file) => {
                  fileToUpload({ field: id, file });
                  const errorsArr = clearErrorInput({
                    field: "docCnpjCard",
                    arr: inputsErrorsForm,
                  });
                  setInputsErrorsForm(errorsArr);
                }}
                label={I18n.get("Card CNPJ")}
                // file={inputsForm?.docCnpjCard}
                fileDefault={inputsForm?.docCnpjCard}
                hideIcon
                error={hasError({
                  field: "docCnpjCard",
                  arr: inputsErrorsForm,
                })}
                removeFileDefault={() => removeFileDefault("docCnpjCard")}
              />
            </Col>
            {state?.partner?.signupLocale == "pt" ||
            state?.bp?.partner?.signupLocale == "pt" ? (
              <>
                <Col sm={12} style={{ height: 90 }}>
                  <OutlineUpload
                    id="docNegativeCertificate"
                    fileToUpload={(id, file) => {
                      fileToUpload({ field: id, file });
                      const errorsArr = clearErrorInput({
                        field: "docNegativeCertificate",
                        arr: inputsErrorsForm,
                      });
                      setInputsErrorsForm(errorsArr);
                    }}
                    label={I18n.get("Negative certificate")}
                    helperText={I18n.get(
                      "Para a consulta da CND pessoa jurídica, basta acessar o portal da receita e informar o seu CNPJ. Caso não haja pendências, será gerada a certidão negativa."
                    )}
                    // file={inputsForm?.docNegativeCertificate}
                    removeFileDefault={() =>
                      removeFileDefault("docNegativeCertificate")
                    }
                    fileDefault={inputsForm?.docNegativeCertificate}
                    hideIcon
                    error={hasError({
                      field: "docNegativeCertificate",
                      arr: inputsErrorsForm,
                    })}
                  />
                </Col>
                <Col sm={12} style={{ height: 90 }}>
                  <OutlineUpload
                    id="docSocialContract"
                    fileToUpload={(id, file) => {
                      fileToUpload({ field: id, file });
                      const errorsArr = clearErrorInput({
                        field: "docSocialContract",
                        arr: inputsErrorsForm,
                      });
                      setInputsErrorsForm(errorsArr);
                    }}
                    label={I18n.get("Social contract")}
                    removeFileDefault={() =>
                      removeFileDefault("docSocialContract")
                    }
                    // file={inputsForm?.docSocialContract}
                    fileDefault={inputsForm?.docSocialContract}
                    hideIcon
                    error={hasError({
                      field: "docSocialContract",
                      arr: inputsErrorsForm,
                    })}
                  />
                </Col>
                <Col sm={12} style={{ height: 90 }}>
                  <OutlineUpload
                    id="docAccountStatement"
                    fileToUpload={(id, file) => {
                      fileToUpload({ field: id, file });
                      const errorsArr = clearErrorInput({
                        field: "docAccountStatement",
                        arr: inputsErrorsForm,
                      });
                      setInputsErrorsForm(errorsArr);
                    }}
                    helperText={I18n.get(
                      "Use a company PJ account document. It can be a statement or a letter from the bank manager."
                    )}
                    label={I18n.get("Account holder statement")}
                    // file={inputsForm?.docAccountStatement}
                    removeFileDefault={() =>
                      removeFileDefault("docAccountStatement")
                    }
                    fileDefault={inputsForm?.docAccountStatement}
                    hideIcon
                    error={hasError({
                      field: "docAccountStatement",
                      arr: inputsErrorsForm,
                    })}
                  />
                </Col>
              </>
            ) : null}
          </S.RowDocs>
          {/* {inputsErrorsForm && (
              <Row>
                <Col lg={24}>
                  {Object.keys(inputsErrorsForm).map((item, index) => {
                    return (
                      <p>
                        {' '}
                        <span class="icon has-text-danger">
                          <i class="fas fa-exclamation"></i>
                        </span>
                        {inputsErrorsForm[item].errorMessage}
                      </p>
                    );
                  })}
                </Col>
              </Row>
            )} */}
          <Row style={{ marginTop: 80 }}>
            {contextPage === "full" ? (
              <Col xs={24} sm={12}>
                <ButtonBpool
                  text={I18n.get("Back")}
                  theme="secondary"
                  full
                  onClick={handleBackPage}
                />
              </Col>
            ) : null}
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={
                contextPage === "edit" || contextPage === "admin"
                  ? { span: 12, offset: 12 }
                  : { span: 12, offset: 0 }
              }
            >
              <ButtonBpool
                text={
                  contextPage === "edit" || contextPage === "admin"
                    ? I18n.get("Save")
                    : I18n.get("Save and Next")
                }
                theme="primary"
                full
                loading={isSending}
                onClick={send}
              />
            </Col>
          </Row>
        </Main>
      </S.Wrapper>
      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get("Missing required information")}
        subBodyText={I18n.get(
          "Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?"
        )}
        okText={I18n.get("Yes")}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get("Close")}
        typeModal="isConfirm"
        // isDanger
      />
    </>
  );
};
