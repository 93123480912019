import get_extra from './get';

import create_extra from './create';
import update_extra from './update';

import update_status from './update-status';

import upload_file from './upload-file';
import delete_file from './delete-file';

import create_quotation_item from './create-quotation-item';
import delete_quotation_item from './delete-quotation-item';
import create_quotation_item_comment from './create-quotation-item-comment';
import delete_quotation_item_comment from './delete-quotation-item-comment';
import update_quotation_item_status from './update-quotation-item-status';

import update_quotation_tax from './update-quotation-tax';
import create_quotation_milestone from './create-quotation-milestone';
import delete_quotation_milestone from './delete-quotation-milestone';
import update_quotation from './update-quotation'
import update_quotation_status from './update-quotation-status';

import export_quotation from './export-quotation';

import upload_quotation_file from './upload-quotation-file';
import delete_quotation_file from './delete-quotation-file';

import create_billing from './create-billing';
import update_billing from './update-billing';
import delete_billing from './delete-billing';
import upload_billing_file from './upload-billing-file';
import delete_billing_file from './delete-billing-file';

import upload_milestone_extra from './upload_file_milestone_extra';
import delete_file_milestone_extra from './delete_file_milestone_extra';
import confirm_milestone_extra from './confirmMilestoneExtras';

import update_po from "./update_po";

export const EXTRAS_UPDATE = 'extras_update';

export const EXTRAS_API = {
    get: get_extra,
    create: create_extra,
    update_extra: update_extra,
    update_status: update_status,
    create_quotation_item: create_quotation_item,
    delete_quotation_item: delete_quotation_item,
    create_quotation_item_comment: create_quotation_item_comment,
    delete_quotation_item_comment: delete_quotation_item_comment,
    update_quotation_item_status: update_quotation_item_status,
    upload_file: upload_file,
    delete_file: delete_file,
    update_quotation_tax: update_quotation_tax,
    create_quotation_milestone: create_quotation_milestone,
    delete_quotation_milestone: delete_quotation_milestone,
    update_quotation: update_quotation,
    update_quotation_status: update_quotation_status,
    upload_quotation_file: upload_quotation_file,
    delete_quotation_file: delete_quotation_file,
    export_quotation: export_quotation,
    create_billing: create_billing,
    update_billing: update_billing,
    delete_billing: delete_billing,
    upload_billing_file: upload_billing_file,
    delete_billing_file: delete_billing_file,
    upload_milestone_extra: upload_milestone_extra,
    delete_file_milestone_extra: delete_file_milestone_extra,
    confirm_milestone_extra: confirm_milestone_extra,
    update_po: update_po

}

