import React from 'react';

import { I18n } from 'aws-amplify';

import Dropzone from 'react-dropzone';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import FileUploadList from '../../modules/components/FileUploadList';

import iconUpload from '../../svg/icon-upload.svg';

class QuotationUpload extends React.Component {
    constructor(props) {

        super();

        const quotation = props.quotation || {};

        this.state = {
            form: {
                files: quotation.files || [],
            },
            task: {},
            upload_task: {},
            errors: {}
        };

        this.upload = this.onUpload.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const task = this.props.task || {};
        const prev = prevProps && prevProps.task || {};

        if (task != prev) {

            //console.dir({ task: task, prev: prev });

            var nextState = Object.assign({}, prevState, { task: task });

            const quotation = this.props.quotation || {};

            Object.keys(this.state.form).forEach((item) => {



                if (FormUtils.isFormField(nextState.form[item])) {

                    nextState.form[item].error = this.props.errors[item];

                    if ('checked' in nextState.form[item]) {
                        nextState.form[item].checked = quotation[item];
                    }
                    else {
                        nextState.form[item].value = quotation[item];
                    }
                }
                else {
                    nextState.form[item] = quotation[item];
                }
            });

            // atualiza os arquivos
            if (task.result) {

                nextState.form.files = quotation.files || [];
            }

            this.setState(nextState);
        }
    }

    getDataUploadMult = (files) => {

        let file = files[0];

        delete file['uid'];

        var upload_task = new Task();

        upload_task.start();

        this.setState({ upload_task: upload_task }, function () {

            const data = {
                ...file,
                projectId: this.props.match.params.projectId,
                partnerId: this.props.quotation.id,
                fileCategory: 'big_idea'
            }

            this.props.uploadEventQuotationfile2(data)
                .then(response => {

                    this.setState((prevState) => {

                        var nextState = Object.assign({}, prevState);

                        nextState.form.files.push(response.data);
                        nextState.upload_task = { result: true };

                        return nextState;
                    });

                })
                .catch(error => {

                    this.setState({ upload_task: { result: false } });

                });
        });

        // this.props.upload_filev2(data);
    }


    onUpload(file, e) {

        if (!file) return;

        if (e) e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            var upload_task = new Task();

            upload_task.start();

            this.setState({ upload_task: upload_task }, function () {

                const data = {
                    projectId: this.props.match.params.projectId,
                    partnerId: this.props.quotation.id,
                    file_type: 'big_idea',
                    name: file.name,
                    base64: e.target.result
                };

                this.props.uploadEventQuotationfile(data)
                    .then(response => {

                        this.setState((prevState) => {

                            var nextState = Object.assign({}, prevState);

                            nextState.form.files.push(response.data);
                            nextState.upload_task = { result: true };

                            return nextState;
                        });

                    })
                    .catch(error => {

                        this.setState({ upload_task: { result: false } });

                    });
            });
        };
        reader.readAsDataURL(file);
    }

    delFile(item) {

        var data = {
            projectId: this.props.match.params.projectId,
            partnerId: this.props.quotation.id,
            ...item
        };

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.props.deleteEventQuotationFile(data);
        });


    }

    onDrop(files) {

        files = files.map(file => {

            this.upload(file);
        });
    }

    onCancel() {
    }


    render() {

        const quotation = this.props.quotation || {};

        const canEdit = this.props.auth.is_partner && quotation.isOpen;

        return (
            <div>

                <p className="title is-5">{I18n.get('Arquivos da Proposta')}</p>

                {canEdit && <>
                    <p class="subtitle is-6">{I18n.get('Faça upload de sua proposta técnica/criativa em PDF, do seu detalhamento de orçamento em excel e quaisquer documentos de apoio necessários.')}</p>
                </>}

                <div className="columns">

                    <div className="column is-12">

                        {canEdit && <>

                            <FileUploadList
                                getDataUpload={this.getDataUploadMult}
                                name="files"
                                uploadId={this.props.project.projectId}
                                get2={this.props.get2 || false}
                                // fileListGet={filesNormalize || []}
                                limit={1}
                                disabledList={true}
                                direct={true}
                            />

                        </>}

                        <div style={{ marginTop: '1rem' }} >
                            {this.state.form.files && this.state.form.files.map((item, index) => {
                                return (<div key={index} title={item.name} className="listIdeia">

                                    <a href={item.url} target="_blank">
                                        <span>
                                            <i className="fas fa-file" aria-hidden="true"></i> {item.name}
                                        </span>
                                    </a>

                                    {canEdit && <a className="delete" onClick={this.delFile.bind(this, item)} ></a>}
                                </div>
                                );
                            })}
                        </div>

                    </div>

                </div>




            </div>
        );

    }
}

export default QuotationUpload;
