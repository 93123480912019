import React from "react";
import ErrorField from "../ErrorField";
import * as S from "./styles";

const FieldInput = (props) => {
  return (
    <S.StyleField>
      {props.mask && <S.StyledInputMask {...props} />}

      {!props.mask && <S.StyledInput {...props} />}

      {props.error && <ErrorField error={props.error} />}
    </S.StyleField>
  );
};

export default FieldInput;
