import styled from 'styled-components';
import { Icon } from 'antd';

export const StyledIcon = styled(Icon)`
  font-size: ${(props) => (props.sizeIcon ? `${props.sizeIcon}px` : `40px`)};
  color: ${(props) => (props.color ? props.color : '#000000')};
`;

export const Text = styled.p`
  font-size: ${(props) => (props.sizeText ? `${props.sizeText}px` : `20px`)};
  color: ${(props) => (props.color ? props.color : '#000000')};
  margin: 0;
  padding: 0 0 0 10px;
`;

export const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${(props) => (props.align ? props.align : `center`)};
  padding: 5px 0;
`;
