import React from "react";

import { I18n } from "aws-amplify";

import TooltipField from "./tooltip-field";

import MarkAsRequiredOrOptional from "./mark-as-required-or-optional";

export default class SekectField extends React.Component {
  constructor(props) {
    super();

    this.state = {
      focused: false,
      visited: props.visited, //visited: false
    };
  }

  static defaultProps = {
    selectLabel: "",
  };

  onBlur() {
    this.setState({ focused: false });

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  onFocus() {
    this.setState({ focused: true, visited: true });
  }

  render() {
    const { value = "" } = this.props;

    const isError =
      !this.state.focused && this.state.visited && this.props.error;
    const className = this.props.className || "field";

    return (
      <div className={className}>
        <label className="label is-small">
          {this.props.label} <MarkAsRequiredOrOptional {...this.props} />{" "}
          <TooltipField {...this.props} />
        </label>
        <div className="control">
          <div
            className={
              isError ? "select is-fullwidth is-danger" : "select is-fullwidth"
            }
          >
            <select
              {...this.props}
              value={value}
              onChange={this.props.onChange}
              onBlur={() => this.onBlur()}
              onFocus={() => this.onFocus()}
              disabled={this.props.disabled || false}
            >
              {!this.props.noNull && (
                <option value="" className="firstItem">
                  {this.props.small ? "..." : this.props.selectLabel}
                </option>
              )}
              {this.props.values &&
                this.props.values.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.label}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {isError && (
          <p className="help is-danger">{this.props.error.errorMessage}</p>
        )}
      </div>
    );
  }
}
