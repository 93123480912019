import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { useSupplier } from "../../../../../../../hooks/useSupplier/useSupplier.hook";
import { ButtonBpool } from "../../../../../../../components/UI/ButtonBpool";
import { NotificationBPool } from "../../../../../../../components/UI/NotificationBPool";
import { Info } from "./Info";
import { FormNF } from "../../../../../components/FormNF";
import * as S from "../styles";

export const InfosDrawer = ({
  data,
  isLoading,
  handleClose,
  refetch,
  isPartner,
  bPaymentId,
  paymentBeneficiaries,
}) => {
  const { putBanckaccount } = useSupplier();
  const [formData, setFormData] = useState({});
  const [errorsInputs, setErrorsInputs] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const handleSave = async () => {
    try {
      setIsSending(true);
      const objSend = {
        bPaymentId: data?.bPaymentId,
        bPayId: data?.bPayId,
        paymentBeneficiaryId: formData?.paymentBeneficiaryId,
      };
      await putBanckaccount(objSend, isPartner);
      refetch();
      handleClose();
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("As informações foram enviadas"),
        duration: 3,
      });
    } catch (error) {
      setErrorsInputs(error?.response?.data?.errors);
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao salvar as informações"),
        duration: 3,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleChangeForm = (dataForm) => {
    setFormData(dataForm);
  };

  const handleChangeErrors = (errors) => {
    setErrorsInputs(errors);
  };

  return (
    <div>
      <S.TitleDrawer>{I18n.get("Corrigir conta")}</S.TitleDrawer>
      <S.Infos style={{ marginTop: 32 }}>
        <Info
          label={I18n.get("Razão social")}
          info={data?.beneficiaryName}
          loading={isLoading}
        />
        <Info
          label={I18n.get("CNPJ")}
          info={data?.beneficiaryId}
          loading={isLoading}
        />
      </S.Infos>
      <div>
        <Info
          label={I18n.get("Endereço")}
          info={
            data?.addressLine1
              ? `${data?.addressLine1}${
                  data?.addressLine2 ? `, ${data?.addressLine2}` : ""
                }`
              : "---"
          }
          loading={isLoading}
        />
      </div>
      <S.Infos>
        <Info
          label={I18n.get("Cidade")}
          info={data?.city}
          loading={isLoading}
        />
        <Info
          label={I18n.get("Estado")}
          info={data?.state}
          loading={isLoading}
        />
        <Info
          label={I18n.get("País")}
          info={data?.country?.name}
          loading={isLoading}
        />
      </S.Infos>
      <div style={{ marginTop: 16 }}>
        <S.TitleDrawer style={{ marginBottom: 8 }}>
          {I18n.get("Dados da nota")}
        </S.TitleDrawer>
        <S.Infos>
          <Info
            label={I18n.get("Número da nota")}
            info={data?.invoiceNumber}
            loading={isLoading}
          />
          <Info
            label={I18n.get("Valor da nota")}
            info={data?.totalFmt}
            loading={isLoading}
          />
        </S.Infos>
      </div>
      <div style={{ marginTop: 16 }}>
        <FormNF
          modules={["FormaDeRecebimento"]}
          onChangeForm={handleChangeForm}
          formError={errorsInputs}
          onChangeErrors={handleChangeErrors}
          defaultValues={data}
          isDrawer={true}
          bPaymentId={data?.bPaymentId}
          paymentBeneficiaries={paymentBeneficiaries}
        />
      </div>
      <div>
        <ButtonBpool
          theme="primary"
          full
          text={I18n.get("Salvar")}
          onClick={handleSave}
          loading={isSending}
        />
      </div>
    </div>
  );
};
