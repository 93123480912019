import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import {
    Table,
    Icon,
    Popconfirm,
    Select,
    Input,
    Form,
    notification,
    Tooltip,
    Tag
} from 'antd';

import constants from "../../../constants";

const { Option } = Select;
const EditableContext = React.createContext();

const Btns = styled.p`
width: 50px;
displa: block;
    a {
        margin-left: 10px;
    }
`

class EditableCell extends React.Component {

    
    getInput = () => {


      let isMaster =  false;
      
      if (this.props.inputType === 'text') {
        return <Input />;
      }

      if (this.props.inputType === 'roles') {
        return (
          <Select
              style={{ width: "100%" }}
              showSearch={false}
              filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              initialValue={this.props.record.roleCode}
          >
              {constants.PERMISSION_CLIENT.map((item) => {
                  if(this.props.record.isMaster){
                    return <Option value={item.code} key={item.code}>{item.label}</Option>
                  } else {
                      if(item.code != "Client"){
                          return <Option value={item.code} key={item.code}>{item.label}</Option>
                      }
                  }
              })}
        </Select> 
        );
      }
      if (this.props.inputType === 'products') {
        let products = []
        
        this.props.brands && this.props.brands.map((item) => {
            item.products && item.products.forEach((element) => {
                products.push(
                    {
                        key: element.id,
                        brandName: item.name,
                        brandId: item.id,
                        productName: element.name,
                        productId: element.id,
                    }
                )
            });
        });

        return (
          <Select
              style={{ width: "100%" }}
              showSearch={false}
              filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
          >
              {products.map((item) => {

              return <Option value={item.productId} key={item.productId}>
                  {item.productName} - {item.brandName}
              </Option>

              })}
        </Select> 


        );
      }
    };
  
    renderCell = ({ getFieldDecorator }) => {
      const {
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
      } = this.props;

      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `${title}`,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    };
  
    render() {
      return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
  }


class TableMaster extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "",
            editingKey: '',
        };


        this.columns = [
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name',
                editable: true,
                type:"text",
                width: 160,
                
              },
              {
                title: I18n.get("E-mail"),
                dataIndex: 'email',
                key: 'email',
                width: 200,
                editable: false,
                type:"text",
                
              },
              {
                title: I18n.get("Marca e Produto"),
                dataIndex: 'product',
                width: 220,
                key: 'product',
                editable: true,
                type:"products",
                render: (product, record) => {
                  if(product){

                    let text = `${product[0]} / ${product[1]}`

                    return text;
                  }
                }
            },
            {
                title: I18n.get("Permissão"),
                dataIndex: 'role',
                key: 'role',
                width: 140,
                editable: true,
                type:"roles",
            },
            {
                title: '',
                dataIndex: 'operation',
                // width: 110,
                render: (text, record) => {
                  const { editingKey } = this.state;
                  const editable = this.isEditing(record);

                  const data = {
                    userId: record.item.id,
                    clientId: this.props.clientId
                }

                  return editable ? (
                    <div className="btsEditDelete">
                      <EditableContext.Consumer>
                        {form => (
                          <a
                            onClick={() => this.save(form, record)}
                            className="button bt-bpool bsmall full"
                          >
                            {I18n.get("Salvar")}
                          </a>
                        )}
                      </EditableContext.Consumer>
                      <Popconfirm title={I18n.get("Deseja cancelar a alteração?")} onConfirm={() => this.cancel(record.key)}>
                        
                          <a
                            className="button bt-bpool bsmall black full"
                          >
                              {I18n.get("Cancelar")}
                          </a>
                      </Popconfirm>
                    </div>
                  ) : (
                    <div className="btsEditDelete">
                      <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                        <Icon type="edit" theme="filled" />
                      </a>

                      <a disabled={editingKey !== ''} >
                        <Popconfirm
                            title={I18n.get("Deseja excluir esse registro?")}
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                            onConfirm={() => this.props.delete(data)}
                            okText="Sim"
                            cancelText="Não"
                        >
                                <Icon type="delete" theme="filled" />
                        </Popconfirm>
                      </a>
                    </div>
                  );
                },
              },
        ]
    }
    // jobPosition

    getJobPosition(value) {
        return (this.props.clientJobPositionsMap && this.props.clientJobPositionsMap[value]) || {};
    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
      };
    
      save = (form, record) => {

        
        form.validateFields((error, row) => {
          if (error) {
            return;
          }          

          let newRole = "";
          let newProduct = "";

          if(row.role == I18n.get("Marketing") || row.role == I18n.get("Compras") || row.role == I18n.get("Master"))  {
            newRole = record.roleCode;
          } else {
            newRole = row.role;
          }



          if(typeof row.product === 'string'){
            newProduct = row.product;
          } else {
            newProduct = record.productId;
          }
          
          const data = {
            userId: record.item.id,
            productId: newRole != "Client" ? newProduct : "",
            name: row.name,
            role: newRole,
            clientId: this.props.clientId
          }


          this.props.updateUser(data).then((response) => {

            if(response.errorsUpdate && Object.keys(response.errorsUpdate).length > 0) {

              Object.keys(response.errorsUpdate).map(item => {
                notification.error({
                  message: I18n.get("Ops algo deu errado!"),
                  description: response.errorsUpdate[item].errorMessage,
                  duration: 3,
                });

              })

              // this.setState({ editingKey: '' });
            } else {
              notification.success({
                message: I18n.get("Atualizado com sucesso!"),
                description: "",
                duration: 5,
              });

              this.setState({ editingKey: '' });
            }
          });
        });
      }
    
      edit(key) {
        this.setState({ editingKey: key });
      }

    render() {
        const components = {
            body: {
              cell: EditableCell,
            },
          };
      
          const columns = this.columns.map(col => {

            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: record => ({
                record,
                listCategorie: col.type == "select" ? this.props.sectorsFlat : null,
                inputType: col.type,
                brands: this.props.brands,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: this.isEditing(record),
              }),
            };
          });

        let data = this.props.data && this.props.data.map((item, index) => {
            const product = this.props.products.find(p => p.id == item.productId);
            const brand = this.props.brands.find(b => b.id == product?.brandId);
            
            const role = constants.PERMISSION_CLIENT.find(r => r.code == item.role);

            return {
                key: index,
                name: item.name,
                email: item.email,
                product: product ? [product?.name,brand?.name] : false,
                productId: product?.id,
                role: role?.label,
                roleCode: role?.code,
                isMaster: this.props.isMaster,
                item: item
            }
        });

        
        return (
            <EditableContext.Provider value={this.props.form}>

            <Table
                components={components}
                columns={columns}
                scroll={{ x: 640 }}
                dataSource={data}
                locale={{ emptyText: I18n.get('Sem informação') }}
                pagination={false}
                />
            </EditableContext.Provider>
        );
    }
}


const EditableFormTable = Form.create()(TableMaster);
export default EditableFormTable;
