import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
    Button,
    Icon,
    Row,
    Col,
    Avatar
} from 'antd';

import Loading from '../../../components/pages/loading';

import WhatifWrapper from '../components/WhatifWrapper';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import HeaderThumbCover from '../components/HeaderThumbCover'
import Carousel from '../components/Carousel'
import { CardSimple } from '../components/Cards';

import icoTarget from '../../../image/ico-target.png';
import icoLuz from '../../../image/ico-luz.png';
import icoPlataformas from '../../../image/ico-plataforma.png';
import icoFoco from '../../../image/ico-foco.png';
import icoData from '../../../image/ico-datas.png';
import icoDonts from '../../../image/ico-donts.png';

import checkOrange from '../../../image/ico-check-orange.png';

import t1 from '../../../image/thumb-1.jpg';
import t2 from '../../../image/thumb-2.jpg';

import ThumbMarca from '../../../image/thumb-marca.png';

import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';


const Main = styled.div`
  padding-top: 150px;
  padding-bottom: 100px;

  .ItemImg {
    margin: 0 1px;
    overflow: hidden;

    img {
        max-width: 200px;
    }
    }

  .btnSend {
      float: right;
  }
  .JobDescription {
      color: #3d3d3d;
      margin-bottom: 10px;

      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 21px;
        margin-bottom: 20px;
      }

      p {
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 15px;
      }
  }


  .TargetIdeias,.OtherInformation {
      margin-bottom: 100px;
      color: #3d3d3d;
      h3 {
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 10px;

          figure {
              margin-right: 30px;
          }
          span {
              line-height: 30px;
          }
          figure, span {
              display: inline-block;
          }
      }

      p, li {
          margin-bottom: 10px;
          font-size: 13px;
          font-weight: 600;
      }

      ul {
          margin-top: 20px;
      }

      li {
          margin-bottom: 15px;

          span {
              margin-right: 5px;
          }
      }

      .ReceiveIdeas {
          div {
              height: 100%;
          }
      }
  }

  .OtherInformation {
      h3 {
          padding: 0 20px;
          span {
              line-height: 17px;
              top: 10px;
              position: relative;
          }

          &.oneLine {
            span {
                top: 5px;
                margin-bottom: 36px;
            }
          }
      }
  }

  .ReferencesFiles {
      div {
          margin: 0 1px;
      }

      h3 {
          color: #3d3d3d;
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          margin:0 0 30px 70px;
      }
  }

  .LinksAndVideos {
      padding: 50px 70px;

      h3 {
          color: #3d3d3d;
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          margin:0 0 10px;

        }
        a {
            color: #3d3d3d;
            font-size: 13px;
            font-weight: 600;
            line-height: 21px;
            transition: all 0.5s ease;

            &:hover,&:focus {
                color: #939393;
            }
        }
  }
`;


const TagSpan = styled.span`
  display: inline-block;
  padding: 4px 9px;
  background: #ccc;
  margin: 0 3px 10px;

  &.bluetag {
      background: #c7e5f2;
  }

  &.blue2tag {
      background: #cbede0;
  }

  &.pinktag {
      background: #ffc5b9;
  }

  &.yellowtag {
      background: #fff291;
  }

  &.greentag {
      background: #e3fbb8;
  }

`

const SlideMarca = styled.div`
  max-width: 800px;
  margin: 0 auto;

  .ItemMarca {
      padding: 0 35px;
      text-aling: center;
      width: ;
  }
`

const BannerSlide = styled.div`
  background: #ececec;
  padding: 30px 0;
`

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_products: () => {
            dispatch(PARTNER_WHATIF_API.get_products());
        },
        get_detail_products: id => {
            dispatch(PARTNER_WHATIF_API.get_detail_products(id))
        }
    };
};



class CreativeBrands extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstBrand: true,
        };
    }

    componentDidMount = () => {
        this.props.get_products();
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partnerWhatIf != prevProps.partnerWhatIf && this.props.partnerWhatIf.products && this.props.partnerWhatIf.products.length > 0 && this.state.firstBrand) {

            this.props.get_detail_products(this.props.partnerWhatIf.products[0].whatIfId);

            this.setState({
                firstBrand: false
            });
        }
    }

    getProduct = (id) => {
        this.props.get_detail_products(id)
    }



    render() {

        if (!this.props.partnerWhatIf.products && !this.props.partnerWhatIf.projectDetail) {
            return (<Loading />);
        }

        if (this.props.partnerWhatIf.products && !this.props.partnerWhatIf.projectDetail && this.props.partnerWhatIf.products.length == 0) {
            return (
                <WhatifWrapper
                    grey
                >
                    <SubHeader />
                    <ContainerPage>
                        <Main>
                            <Row>
                                <Col sm={24}>
                                    <p>Nenhuma marca cadastrada!</p>
                                </Col>
                            </Row>
                        </Main>
                    </ContainerPage>
                </WhatifWrapper>
            );
        }

        if (!this.props.partnerWhatIf.projectDetail) {
            return (<Loading />);
        }




        const {
            partnerWhatIf: {
                products,
                projectDetail,
                projectDetail: {
                    imageLogo,
                    imageHeader,
                    brandName,
                    brandPurpose,
                    description,
                    productName,
                    keywords,
                    genre,
                    ageRange,
                    wantReceiveSocial,
                    wantReceiveOthers,
                    actuationPlatforms,
                    regionFocusArea,
                    specialDates,
                    donts,
                    referenceImages,
                    referenceLinks,
                    referenceVideos

                }
            }

        } = this.props;

 
        return (
            <WhatifWrapper
                grey
            >
                {/* <SubHeader /> */}
                <BannerSlide>
                    <ContainerPage>
                        <SlideMarca>
                            <Row>
                                <Col sm={24}>
                                    <Carousel
                                        dots={false}
                                        infinite={false}
                                        slidesToShow={4}
                                        slidesToScroll={2}
                                        variableWidth={false}
                                    >

                                        {products && products.map((item, index) => {
                                            return (
                                                <div className="ItemMarca" key={index} onClick={() => this.props.get_detail_products(item.whatIfId)}>
                                                    <Avatar className="LogoAvatar" size={120} icon="user" src={item.imageLogo} />
                                                </div>
                                            )
                                        })}

                                    </Carousel>
                                </Col>
                            </Row>
                        </SlideMarca>
                    </ContainerPage>
                </BannerSlide>
                <HeaderThumbCover
                    thumb={imageLogo && imageLogo}
                    bgHeader={imageHeader && imageHeader}
                    isCreative={true}
                />
                <ContainerPage>
                    <Main>
                        <section className="JobDescription">
                            <Row>
                                <Col sm={18}>
                                    <h2>{brandName} / {productName}</h2>
                                    {/* item.brand + " / " + item.product */}
                                </Col>
                                <Col sm={6}>
                                    <Link to={`/parceiro-ideias/cadastro/ideia/${projectDetail.whatIfId}`}>
                                        <Button type="primary" shape="round" className="btnSend" >QUERO ENVIAR UMA IDEIA</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </section>

                        <section className="TargetIdeias">
                            <Row type="flex" justify="start">
                                <Col lg={15} md={24} className="BoxGrey">
                                    <CardSimple>
                                        <h3>
                                            <figure>
                                                <img src={icoTarget} />
                                            </figure>
                                            <span>Brand Purpose</span>
                                        </h3>
                                        <p>{brandPurpose}</p>

                                        <p>
                                            <strong>Descrição do Target:</strong><br />
                                            {description}
                                        </p>
                                        <p>
                                            <strong>Palavras-chaves:</strong><br />
                                            {keywords && keywords.map((item, index) => {
                                                return <TagSpan key={index} className="bluetag">{item}</TagSpan>
                                            })}


                                        </p>
                                        <p>
                                            <strong>Gênero:</strong> <br />
                                            {genre && genre.map((item, index) => {
                                                return <TagSpan key={index} className="blue2tag">{item}</TagSpan>
                                            })}
                                        </p>
                                        <p>
                                            <strong>Faixa etária:</strong> <br />
                                            {ageRange && ageRange.map((item, index) => {
                                                return <TagSpan key={index} className="yellowtag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                                <Col lg={9} md={24} sm={24} xs={24} className="BoxGrey ReceiveIdeas">
                                    <CardSimple >
                                        <h3>
                                            <figure>
                                                <img src={icoLuz} />
                                            </figure>
                                            <span>Quero receber ideias</span>
                                        </h3>

                                        <Row>
                                            <Col sm={12}>
                                                <p><strong>Ideias de Social</strong></p>

                                                <ul>
                                                    {wantReceiveSocial && wantReceiveSocial.map((item, index) => {
                                                        return <li key={index}><span><img src={checkOrange} /></span> {item}</li>
                                                    })}
                                                </ul>
                                            </Col>
                                            <Col sm={12}>
                                                <p><strong>Outras ideias</strong></p>

                                                <ul>
                                                    {wantReceiveOthers && wantReceiveOthers.map((item, index) => {
                                                        return <li key={index}><span><img src={checkOrange} /></span> {item}</li>
                                                    })}
                                                </ul>
                                            </Col>
                                        </Row>

                                    </CardSimple>
                                </Col>
                            </Row>
                        </section>

                        <section className="OtherInformation">
                            <Row type="flex" justify="start">
                                <Col md={12} sm={12} lg={6} xs={24}>
                                    <div className="topBox">
                                        <h3>
                                            <figure>
                                                <img src={icoPlataformas} />
                                            </figure>
                                            <span>Plataformas<br />de Atuação</span>
                                        </h3>
                                    </div>
                                    <CardSimple>
                                        <p>
                                            {actuationPlatforms && actuationPlatforms.map((item, index) => {
                                                return <TagSpan key={index} className="pinktag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                                <Col md={12} sm={12} lg={6} xs={24}>
                                    <div className="topBox">
                                        <h3>
                                            <figure>
                                                <img src={icoFoco} />
                                            </figure>
                                            <span>Área Foco para Ideias<br />& Conteudo</span>
                                        </h3>
                                    </div>
                                    <CardSimple>
                                        <p>
                                            {regionFocusArea && regionFocusArea.map((item, index) => {
                                                return <TagSpan key={index} className="blue2tag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                                <Col md={12} sm={12} lg={6} xs={24}>
                                    <div className="topBox">
                                        <h3 className="oneLine">
                                            <figure>
                                                <img src={icoData} />
                                            </figure>
                                            <span>Datas Especiais</span>
                                        </h3>
                                    </div>
                                    <CardSimple>
                                        <p>
                                            {specialDates && specialDates.map((item, index) => {
                                                return <TagSpan key={index} className="yellowtag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                                <Col md={12} sm={12} lg={6} xs={24}>
                                    <div className="topBox">
                                        <h3 className="oneLine">
                                            <figure>
                                                <img src={icoDonts} />
                                            </figure>
                                            <span>Dont’s</span>
                                        </h3>
                                    </div>
                                    <CardSimple>
                                        <p>
                                            {donts && donts.map((item, index) => {
                                                return <TagSpan key={index} className="pinktag">{item}</TagSpan>
                                            })}
                                        </p>
                                    </CardSimple>
                                </Col>
                            </Row>
                        </section>

                        <section className="ReferencesFiles">
                            <Row>
                                <Col sm={24}>
                                    <h3>Referências</h3>
                                </Col>

                                <Col sm={24}>
                                    <Carousel
                                        dots={false}
                                        infinite={true}
                                        speed={300}
                                        slidesToShow={referenceImages.length < 5 ? referenceImages.length : 5}
                                        centerMode={true}
                                        variableWidth={true}
                                    >
                                        {referenceImages && referenceImages.map((item, index) => {
                                            return (
                                                <div key={index} className="ItemImg">
                                                    <img src={item} />
                                                </div>
                                            )
                                        })}

                                    </Carousel>
                                </Col>

                            </Row>

                        </section>
                        <section className="LinksAndVideos">
                            <Row>
                                <Col sm={24} md={8}>
                                    <h3>Links</h3>

                                    <ul>
                                        {referenceLinks && referenceLinks.map((link, index) => {
                                            return (
                                                <li>
                                                    <a href={link} target="_blank"><Icon type="link" /> {link}</a>
                                                </li>

                                            )
                                        })}
                                    </ul>
                                </Col>
                                <Col sm={24} md={8} className="BoxVideos">
                                    <h3>Vídeos</h3>

                                    <ul>
                                        {referenceVideos && referenceVideos.map((video, index) => {
                                            return (
                                                <li>
                                                    <a href={video} target="_blank"><Icon type="play-circle" /> {video}</a>
                                                </li>

                                            )
                                        })}
                                    </ul>
                                </Col>
                            </Row>
                        </section>
                        <Row>
                            <Col sm={24}>
                                <Link to={`/parceiro-ideias/cadastro/ideia/${projectDetail.whatIfId}`}>
                                    <Button type="primary" shape="round" className="btnSend" >QUERO ENVIAR UMA IDEIA</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Main>
                </ContainerPage>
            </WhatifWrapper>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreativeBrands);
