import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import LoggedPage from '../page-templates/logged';

import { CLIENT_API } from '../../actions/client/actions';

import FormAddAccess from '../../modules/Register/components/formAddAccess';
import TableMaster from '../../modules/Register/components/tableMaster';

import Menu from './_menu';

import MasterUsersForm from './master-users-form';

import styled from 'styled-components';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        update_terms: data => {
            return dispatch(CLIENT_API.update_terms(data));
        },
        save: data => {
            dispatch(CLIENT_API.update_company(data));
        },
        update: data => {
            dispatch(CLIENT_API.update(data));
        },
        delUser: data => {
            dispatch(CLIENT_API.delete_user_v2(data));
        },
        updateUser: data => {
            return dispatch(CLIENT_API.update_user_v2(data));
        },
        get: data => {
            dispatch(CLIENT_API.get(data));
        },
        addUser: data => {
            dispatch(CLIENT_API.create_user_v2(data));
        },
    };
};

const Box = styled.div`
   background: #fff;
   padding: 20px;
`


class MasterUsersAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
            viewErros: false
        };
    }


    getUsers() {
        if(this.props.auth.profile == "Client") {
            return this.props.client && this.props.client.users;
        } else {
            return this.props.client && this.props.client.users && this.props.client.users.filter((item) => item.role != "Client");

        }
    }

    viewErrors = () => {
        this.setState({
            viewErros: true
        });
    }


    render() {

        let userClient = this.props.client && this.props.client.users && this.props.client.users.filter((i) => i.role == "Client");

        let dataTable = this.getUsers();

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><Link to="/home">{I18n.get("Admin")}</Link></li>
                        <li className="is-active"><Link to="/cadastro">{I18n.get("Cadastro")}</Link></li>
                    </ul>
                </nav>

                <div className="columns">

                    <div className="column is-3">
                        <Menu {...this.props} active={4} />
                    </div>

                    <div className="column is-8">

                        <h1 className="title">{I18n.get("Acesso Master")}</h1>

                        <Box>
                            <FormAddAccess
                                clientId={this.props.client.id}
                                add={this.props.addUser}
                                errors={this.props.client.errors && this.props.client.errors}
                                userClient={userClient}
                                brands={this.props.client?.brands}
                                users={this.props.client?.users}
                                isMaster={this.props.auth.profile == "Client"}
                            />

                            <br />
                            <br />
                            <TableMaster
                                data={dataTable || []}
                                delete={this.props.delUser}
                                clientId={this.props.client.id}
                                brands={this.props.client?.brands}
                                products={this.props.client?.products}
                                updateUser={this.props.updateUser}
                                users={this.props.client?.users}
                                isMaster={this.props.auth.profile == "Client"}
                            />
                        </Box>

                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterUsersAdmin);
