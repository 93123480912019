import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PARTNER_REFERENCE_DELETE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_partner_delete_reference = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        return axios.delete(`${Config.API_BASE_URL}/partner/${data.partnerId}/reference/${data.id}`, config)
            .then(response => {

                dispatch({
                    type: PARTNER_REFERENCE_DELETE,
                    payload: response.data
                });

            })
            .catch(error => {

                dispatch({
                    type: PARTNER_REFERENCE_DELETE,
                    payload: {
                        reference: {
                            errors: error.response.data
                        }
                    }
                });
            });
}).catch(error => { 
 dispatch({ type: EXPIRED }); 
 }); 
 };
}

export default api_partner_delete_reference;