import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from "react-router-dom";

import Loading from '../../../components/pages/loading';
import AppWrapperSimple from '../../components/AppWrapperRegister';

import ContainerPage from '../../components/ContainerPage';
import HeaderThumbCover from '../../components/HeaderThumbCover';

import PartnerCard from '../../../components/partner/shared/_partner-card';

// partner/shared/_partner-card


const Main = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  max-width: 1000px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  color: #353535;
`;

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {

    };
};



class PartnerProfilePreviewCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        };
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={`/parceiro/cadastro/preview/${this.props.partner && this.props.partner.partnerId}`} />
        }
        if (!this.props.partner && !this.props.partner.company) {
            return (<Loading />);
        }
        return (
            <AppWrapperSimple
                grey
            >
                {/* <SubHeader /> */}
                {/* <HeaderThumbCover
                    thumb={this.props.partner.company && this.props.partner.company.logoImage}
                    bgHeader={this.props.partner.company && this.props.partner.company.introImage}
                    title="Preview Profile"
                    link={`/parceiro/cadastro/preview/${this.props.partner && this.props.partner.partnerId}`}
                    linkText="Visualizar Completo"
                /> */}
                <ContainerPage>
                    <Main>
                        <PartnerCard {...this.props.partner} showDetails={() => this.setState({ redirect: true })} isSelected={false} isMiniThumb={true} isPreview={true} />
                    </Main>
                </ContainerPage>
            </AppWrapperSimple>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PartnerProfilePreviewCard);

