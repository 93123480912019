import React from 'react';

import { I18n } from 'aws-amplify';

import TextField from './form/text-field';

export default class VideosForm extends React.Component {

    constructor(props) {
        super();

        this.state = {
            link: '',
        };

        this.changeLink = this.onChangeLink.bind(this);
    }

    onChangeLink(e) {
        this.setState({ link: e.target.value });
    }

    render() {

        return (
            <div>

                <div className="field is-horizontal">
                    <div className="field-body field-video">

                        <TextField
                            label=""
                            value={this.state.link}
                            onChange={this.changeLink}
                            error={this.props.errors.videos}
                            placeholder={"Cole o link aqui"}
                        />


                        <div className="field is-narrow">
                            <p className="control">

                                <button
                                    type="button"
                                    onClick={(e) => this.props.add(this.state.link, e)}
                                    className="button is-leaked">{I18n.get("SALVAR")}<i style={{ display: "inline-block", marginLeft: 8 }} className="fa fa-plus"></i>
                                </button>

                            </p>
                        </div>
                    </div>
                </div>

                {this.props.videos && this.props.videos.map((item, index) =>
                    <figure style={{ textAlign: "right" }} key={index} className="image is-64x64">
                        <a onClick={(e) => this.props.del(index, e)}>
                            <i style={{ color: "#666", marginBottom: 7 }} className="fa fa-times"></i>   
                        </a>
                        <img src="https://cdn2.techadvisor.co.uk/cmsdata/features/3511087/how-to-fix-youtube-videos-that-wont-play_thumb800.jpg" />
                    </figure>
                )}

                {this.props.errors.videos && <p className="help is-danger">
                    {this.props.errors.videos.errorMessage}
                </p>}

            </div>
        );
    }
}