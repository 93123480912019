import React from 'react';
import { I18n } from 'aws-amplify';

export const bPitchMenu = [{
    label: I18n.get('Escopo do Pitch'),
    children: [
        //I18n.get('Tipo') + ` - ${bPitch && I18n.get(BPitchTypeNames[bPitch.pitchType])}`,
        I18n.get('Formato do Pitch'),
        I18n.get('Detalhes do Pitch'),
        I18n.get('Budget'),
        I18n.get('Pitch Cost'),
        I18n.get('Upload Arquivos'),
    ]
},
{
    label: I18n.get('Sobre a Marca & Produto'),
    children: [
        I18n.get('Produto, Marca e Mercado'),
        I18n.get('Público e Abrangência'),
        I18n.get('Objetivos de Negócio e Comunicação'),
        I18n.get('Reason to Believe e Benefícios'),
        I18n.get('Concorrência'),
        I18n.get('Informações Adicionais'),
    ]
},
{
    label: I18n.get('Briefing e Entregáveis'),
    children: [
        I18n.get('Detalhes de Projeto'),
        I18n.get('Entregáveis e KPIs'),
    ]
},
{
    label: I18n.get('Conclusão'),
    children: [
        I18n.get('Participantes BPitch'),
        I18n.get('Cronograma'),
        I18n.get('Scorecard'),
        I18n.get('Pitch Terms'),
        I18n.get('NDA Próprio'),
    ]
},
];