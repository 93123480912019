import { OPEN_CHAT_MODAL, CLOSE_CHAT_MODAL } from '..';

export const openChatModal = (payload) => ({
  type: OPEN_CHAT_MODAL,
  payload,
});

export const closeChatModal = () => ({
  type: CLOSE_CHAT_MODAL,
});
