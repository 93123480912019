import React, { Component } from "react";

import LayoutWikis from "./components/LayoutWikis";
import ContainerKnowledge from "./components/ContainerKnowledge";

import { Col, Row } from 'antd';

import TextItem from "./components/FaqItem";
import VideoItem from "./components/PreviewVideo";
import FileItem from "./components/FileItem";


class ListScope extends Component {

    state = {
        collapsed: false,
        menuActive: null,
        termActive: ""
    };

    renderTypeItem = (type, content) => {
        let target = this.props.auth.is_client ? "cliente":"parceiro";

        if(type == 1) {
            return (
                <Col sm={6}>
                    <VideoItem 
                        {...this.props}
                        target={target} 
                        item={content} 
                    />
                </Col>        
            )
        }

        if(type == 2) {
            return (
                <Col sm={24}>
                    <TextItem 
                        {...this.props}
                        target={target} 
                        item={content} 
                    />
                </Col>        
            )
        }

        if(type == 3) {
            return (
                <Col sm={24}>
                    <FileItem 
                        {...this.props}
                        target={target} 
                        item={content} 
                    />
                </Col>        
            )
        }
    }

    componentDidMount = () => {
        this.props.get_knowledge(this.props.target);
    }


    render() {

        let scopeSections = this.props?.wikis?.knowledgeBase && this.props?.wikis?.knowledgeBase.find(sec => sec.name == this.props.match.params.scope);

        return (
            <LayoutWikis
                {...this.props}
                scope={this.props?.wikis?.knowledgeBase || []}
            >
                <Row type="flex" gutter={36}>
                    {scopeSections?.sections.map((item,index) => {

                        return item.categories.map((cat) => {
                            return cat?.contents.map(content => {

                                if(this.props.match.params.categoryId) {
                                    if(content.categoryId == this.props.match.params.categoryId) {
                                        return this.renderTypeItem(scopeSections.type, content)                                
                                    }
                                } else {
                                    return this.renderTypeItem(scopeSections.type, content)                                
                                }
                            })
                        })
                    })}
                </Row>
           </LayoutWikis>
        );
    }
}

export default ContainerKnowledge(ListScope);