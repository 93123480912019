import styled from "styled-components";

import { Upload } from "antd";

const { Dragger } = Upload;

export const DraggerFiles = styled(Dragger)`
  &.ant-upload.ant-upload-drag {
    background: #e7e7e7;
    padding: 10px 30px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    border: 1px dashed #000000;
    border-radius: 10px;
    height: auto;
  }

  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

export const LabelText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding: 25px 0;
  text-align: center;

  color: #000000;
`;

export const Buttons = styled.div`
  width: 250px;
  margin: 0 auto;

  p {
    margin-top: 15px;
  }
`;
