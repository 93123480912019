import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import LandingPage from '../page-templates/landingForLogin';

import bannerImage3 from '../../image/banner-image3.png';

export default class WallOkPartner extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    render() {

        return (
            <LandingPage>
                <div className="middle">

                    <section className="section-empty"></section>

                    <section className="section-cadstart">
                        <div className="container">
                            <div className="columns">
                                <div className="column">
                                    <div className="columns paddin-right">

                                        <div className="column">
                                            <h3 className="title-bpool medium">{I18n.get("Cliente")}</h3>
                                            <p className="form-para">{I18n.get("BPool é a plataforma que vai conectar por projeto o marketing das empresas a boutiques de comunicação de uma forma como nunca se viu. Uma maneira de entregar um trabalho mais personalizado, elevar o nível de criatividade e inovação, com um preço justo e padronizado.")}</p>
                                            <div className="colot-line2"></div>
                                        </div>
                                    </div>


                                </div>

                                <div className="column">
                                    <div className="form-inner">
                                        <br /><br /><br />

                                        <h3 className="titel-bpool medium">
                                            {I18n.get("Obrigado")}
                                        </h3>
                                        <br />
                                        <p className="form-para">{I18n.get("Para dar continuidade ao cadastro, favor verificar a mensagem que acabamos de enviar para o e-mail informado.")}</p>
                                        <div class="colot-line"></div>

                                    </div>
                                </div>

                            </div>

                            <br /><br /><br />
                            <br /><br /><br />
                            <br /><br /><br />

                        </div>
                    </section>

                </div>
            </LandingPage>
        );
    }
}

