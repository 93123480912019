import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import AppWrapperSimple from '../../components/AppWrapperSimple';
import { Link } from 'react-router-dom'

import PageSuccess from '../../components/PageSuccess';

class UserProfileSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <AppWrapperSimple>
                <PageSuccess>
                    <Fragment>
                        <h2>{I18n.get("Obrigado pelas informações.")}</h2>
                        <p>{I18n.get("Sua empresa está cadastrada e já pode usar a BPool! Quer começar o seu primeiro projeto agora mesmo?")}</p>
                        <p></p>
                        <p>{I18n.get("Seja bem vindo a BPool.")}</p>
                        <br />
                        <p>
                            <Link to="/cliente/projetos">{I18n.get("Clique aqui!")}</Link>
                        </p>
                    </Fragment>
                </PageSuccess>
            </AppWrapperSimple>
        );
    }
}

export default UserProfileSuccess;
