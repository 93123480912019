import React from 'react';

const Terms = (props) => {

    return (<div class="termsContainer">
        <h2></h2>
        <div class="termsContent">
            <p><strong>I. OBJETO</strong></p>


            <p>1.1.	Este instrumento tem por objeto estabelecer as condições da prestação pelo Parceiro ao Cliente dos serviços profissionais e especializados selecionados na Plataforma (“Projeto”), conforme definido e descrito no Anexo I a este instrumento (“Serviços”). As condições especificas de cada Projeto como escopo, macro-etapas, preço e cronograma estão descritas no Anexo I desse Instrumento.</p>

            <p>1.1.1.	Em caso de alteração do escopo dos Serviços contratados por meio deste TEP, novos anexos poderão ser acordados entre as Partes e passarão automaticamente a fazer parte integrante deste TEP.</p>

            <p>1.2.	Este TEP e seu Anexo estabelecem as diretrizes para a consecução do Projeto e prestação dos Serviços. Em caso de qualquer divergência entre os termos e condições deste TEP e dos Termos de Uso, as condições deste TEP deverão prevalecer.</p>

            <p><strong>II.	REMUNERAÇÃO E FORMA DE PAGAMENTO</strong></p>

            <p>2.1.	Pela prestação dos Serviços, o Cliente pagará ao Parceiro o(s) valor(es) estabelecido(s) no Anexo I (“Remuneração”).</p>

            <p>2.2.	As Partes acordam que o pagamento da Remuneração pelo Cliente ao Parceiro ocorrerá conforme o seguinte fluxo:</p>

            <p>(i) O Cliente deverá inserir o número de pedido ou ordem de compras (“Pedido”) quando aplicável ou, autorizar o Faturamento na plataforma;</p>

            <p>(ii) Após a inserção do Pedido na Plataforma ou autorização do faturamento, o Parceiro será notificado por e-mail com instruções e deverá: (1) emitir uma nota fiscal contra o Cliente na porcentagem da Remuneração prevista para o Parceiro conforme consta no Anexo I, aos cuidados da Boutique Pool, especificando no corpo da referida nota fiscal que os valores serão em nome da intermediadora de serviços, qual seja, a Boutique Pool, contra nota de repasse; e (2) fazer upload da referida nota fiscal emitida na Plataforma;</p>

            <p>(iii) A Boutique Pool, por sua vez emitirá uma nota fiscal somando os Serviços prestados pelo Parceiro no campo de “Intermediação de Serviços” e o Fee Transacional especificado no Anexo I, e enviará para o Cliente conforme instruções inseridas na Plataforma (e-mail, upload em sistema e/ou recebimento físico); e </p>

            <p>(iv) O Cliente efetuará o pagamento integral da nota referida no item (iii) na conta corrente da Boutique Pool e esta repassará ao Parceiro o valor dos Serviços no prazo de 3 (três) dias após o efetivo recebimento, conforme consta no Anexo I.</p>

            <p>2.3. Em caso de atraso no pagamento da Remuneração por meio das notas fiscais enviadas, recebidas e não contestadas pelo Cliente, este estará sujeito à cobrança de multa de 2% (dois por cento), além de juros moratórios de 1% (um por cento) ao mês, calculadas pro rata die. A multa e os juros moratórios referidos neste item serão devidos ao Parceiro e à Boutique Pool, proporcionalmente às suas respectivas remunerações, de acordo com os termos de contratação de cada Projeto ou Serviço.</p>

            <p>2.4. A pedido do Cliente, o Parceiro poderá ser solicitado a realizar readequações e adaptações nos materiais que vierem a submeter com relação ao Projeto ora contratado. Enquanto uma readequação estiver sendo realizada, o Parceiro não fará jus ao pagamento total dos valores da sua Remuneração, que ainda não tenham sido pagos integralmente, o que ocorrerá somente após a conclusão dos trabalhos de readequação, desde que o Cliente não tenha ultrapassado o escopo do Projeto contratado e respectivo número de readequações. Caso tenha ultrapassado, o Cliente deverá negociar com Parceiro a contratação de extensão de Projeto. </p>

            <p><strong>III. VIGÊNCIA</strong></p>

            <p>3.1. O presente instrumento entrará em vigor na data de sua assinatura e manter-se-á em vigor por prazo indeterminado.</p>

            <p>3.2. O prazo de execução de cada Projeto estará estabelecido no Anexo I sendo que, em caso de rescisão deste TEP, o Serviço que ainda esteja sendo prestado deverá ser concluído e pago regularmente.</p>

            <p><strong>IV.	RESCISÃO CONTRATUAL </strong></p>

            <p>4.1. Este TEP e o Anexo I poderão ser rescindidos, por qualquer uma das Partes, a qualquer tempo e sem ônus, desde que a parte interessada em promover a rescisão comunique formalmente a sua intenção, mediante notificação escrita a outra Parte, entregue via e-mail, com 60 (sessenta) dias corridos de antecedência, sem prejuízo da realização do Projeto pelo Parceiro que já tenha sido contratado nesse período e do respectivo pagamento pelo Cliente dos Serviços que tiverem sido efetivamente contratados e prestados. </p>

            <p>4.2. Este TEP poderá ainda ser rescindido na ocorrência de qualquer uma das seguintes hipóteses:</p>

            <p>(i) descumprimento, pela outra Parte, de quaisquer de suas obrigações aqui estabelecidas, não sanadas dentro do prazo estabelecido na notificação enviada por escrito pela Parte inocente à Parte inadimplente sobre referido descumprimento;</p>

            <p>(ii) em caso de decretação de falência, início do processo de recuperação judicial ou extrajudicial ou de liquidação judicial ou extrajudicial de qualquer Parte;</p>

            <p>(iii) em caso de um evento de caso fortuito ou força maior que impeça a continuidade dos Serviços;</p>

            <p>(iv) por qualquer uma das Partes, caso uma Parte ceda ou transfira a terceiro este TEP, sem a anuência prévia da outra Parte.</p>

            <p>4.3. Em caso de rescisão deste TEP por justa causa, nos termos da Cláusula 4.3. acima, exceto pela alínea (iii), a parte culpada ficará obrigada ao pagamento à parte inocente de multa rescisória não compensatória correspondente de 10% (dez por cento) calculada sobre o valor da somatória da Remuneração devida no momento da rescisão, sem prejuízo (a) do direito do Cliente de receber todo e quaisquer materiais decorrentes dos Serviços prestados sob este TEP vigentes até aquele momento; e (b) do direito do Parceiro de receber a Remuneração devida nos termos deste TEP integralmente cumpridos e entregues até a data da efetiva rescisão.</p>

            <p>4.4. No caso de rescisão deste TEP seja por ou sem justa, nos termos desta Cláusula: (i) o Parceiro deverá devolver ao Cliente todos os documentos ou informações que lhe foram entregues em decorrência deste TEP, bem como todas as Informações Confidenciais (conforme abaixo definidas) transmitidas pelo Cliente ao Parceiro ou obtidas pelo Parceiro em decorrência deste TEP; (ii) o Parceiro deverá deletar de sua base eventuais dados a que tenha tido acesso de forma autorizada pelo Cliente; e (iii) os materiais e produtos produzidos ou desenvolvidos até aquele momento pelo Parceiro deverão ser entregues para o Cliente. As cláusulas de confidencialidade e propriedade intelectual permanecerão em vigor, sobrevivendo a qualquer término ou rescisão. </p>

            <p><strong>V. CONFIDENCIALIDADE DAS INFORMAÇÕES </strong></p>

            <p>5.1. As Partes obrigam-se a manter a mais absoluta confidencialidade o conteúdo deste instrumento, bem como todas as Informações Confidenciais conforme abaixo definidas, que receberem em decorrência do objeto deste TEP e da prestação dos Serviços, salvo mediante prévia autorização escrita da Parte proprietária das informações.</p>

            <p>5.2. Para os fins deste instrumento, “Informações Confidenciais” são todas aquelas informações, elementos, documentos e dados, verbais, escritos e/ou gravados por qualquer meio, inclusive eletrônico, que venham a ser trocados entre as Partes, que não sejam de conhecimento público, incluindo, porém sem a isto se limitar, aqueles relativos a investigações, invenções, fórmulas, receitas, processos industriais, "know-how", sistemas, contabilidade, engenharia, relações societárias, pesquisas mercadológicas, industrialização, comercialização, venda e lançamento de produtos, administração e gestão da sociedade empresária, estejam essas informações identificadas como “confidenciais” ou não.</p>

            <p>5.2.1. Excluem-se da definição de Informações Confidenciais, aquelas informações que:</p>

            <p>(i) Sejam de conhecimento do público ou venha assim se tornar por qualquer meio, que não por divulgação da Parte receptora das Informações Confidenciais;</p>

            <p>(ii) Já eram de conhecimento do público ou da Parte receptora antes da celebração deste instrumento;</p>

            <p>(iii) Tornem-se disponíveis ao público em geral, por ato de terceiro devidamente autorizado, desde que este terceiro não esteja violando qualquer obrigação de confidencialidade por ele estabelecida com as Partes ou outro terceiro; </p>

            <p>(iv) Tornem-se disponíveis ao público em geral em decorrência da prestação dos Serviços, desde que tal divulgação esteja restrita ao Projeto e a parte detentora da informação tenha autorizado previamente.</p>

            <p>5.3. Mediante solicitação da Parte proprietária das Informações Confidenciais, a Parte receptora deverá destruir, mediante certificação para a outra Parte, todo material, documento ou meio eletrônico que contenha Informação Confidencial, fornecido e utilizado para a elaboração de análises, compilações, extratos, previsões, sumários, estudos ou outros documentos e escritos preparados, elaborados ou desenvolvido em razão deste Termo,, incluindo qualquer informação eletrônica em programa de computador ou em bando de dados.</p>

            <p>5.4. Sempre que qualquer das Partes receber solicitação, notificação, citação ou interpelação de agente público, órgão administrativo e/ou judicial e/ou qualquer terceiro, envolvendo solicitação ou requisição de informações, dados, documentos, testemunho em ações judiciais ou administrativas, esclarecimentos relativos ao objeto do presente Termo, deverá imediatamente informar à outra Parte e aguardar instruções desta, antes de atender qualquer solicitação.</p>

            <p>5.5. Não configura infração à esta Cláusula, a divulgação de "press-releases" (notas/comunicação à mídia), desde que acordados prévia e expressamente entre as Partes.</p>

            <p>5.6. Da mesma forma, é vedada qualquer alusão, por qualquer das Partes, ao nome da outra de suas controladas, controladoras ou coligadas, suas divisões, produtos e/ou respectivas marcas em qualquer meio de comunicação ou suporte material, sob pena de aplicação das sanções penais cabíveis, como crime de violação de marca, salvo se de outro modo previsto neste TEP.</p>

            <p><strong>VI. DIREITOS AUTORAIS E DE IMAGEM</strong></p>

            <p>6.1. Para fins deste instrumento, “Direitos Autorais” significam os direitos morais e patrimoniais das criações elaboradas durante a prestação dos Serviços, desde que estas contenham criações originais e de espírito capazes de destacar uma ideia ou conceito para um produto ou serviço relacionado aos Projetos contratados. Neste sentido, os Direitos Autorais podem englobar criações manifestadas em materiais impressos, eletrônicos, audiovisuais ou radiofônicos. O Parceiros deverá deter a posse dos Direitos Autorais dos materiais a que venham a submeter para a prestação dos Serviços objeto deste TEP. </p>

            <p>6.2. Em caso de verificação do uso de materiais não originais na prestação dos Serviços, este TEP poderá ser rescindido por justa causa pelo Cliente, aplicando-se o quanto previsto na Cláusula Quarta acima. A Boutique Pool não possui qualquer responsabilidade sobre a violação de Direitos Autorais de terceiros na prestação dos Serviços, devendo o Cliente buscar seus direitos junto ao Parceiro que violou tais direitos.</p>

            <p>6.3. Os materiais sujeitos a Direitos Autorais relacionados aos Serviços prestados no âmbito deste TEP serão cedidos ao Cliente quando da conclusão e entrega do Projeto pelo Parceiro. Tal cessão é onerosa nos termos de cada contratação, específica para a utilização em cada Projeto contratado e limitada aos usos previstos em cada Projeto. Em caso de ausência de definição específica, a cessão será considerada onerosa e ilimitada, definitiva e abrangerá todos os materiais produzidos pelo Parceiro que sejam sujeitos a Direitos Autorais. </p>

            <p>6.4. A cessão dos Direitos Autorais deverá ser realizada pelo Parceiro com a garantia de que este obteve os direitos dos autores dos materiais contidos nos Serviços, pessoas físicas, que elaboraram estes materiais. Em caso de ausência na obtenção da cessão das pessoas físicas, caberá ao Cliente definir entre obter a cessão destes Direitos Autorais de forma adicional ou cancelar a contratação do Parceiro. Os direitos morais sobre os materiais de marketing não são cedidos, na forma do artigo 24 da Lei de Direitos Autorais (Lei 9810/98), sendo de responsabilidade do Cliente nomear seus criadores na exibição dos materiais de marketing que foram criados.</p>

            <p>6.5. O Parceiro declara e garante que os Projetos Criativos desenvolvidos durante a prestação dos Serviços não infringirão e não violarão quaisquer contratos celebrados com terceiros e/ou direitos de terceiros</p>

            <p>6.6. O Parceiro garante a autoria dos Serviços, assumindo a obrigação de manter o Cliente a todo o tempo, mesmo após o término do prazo de vigência deste TEP, livre de qualquer ação, reclamação ou indenização por parte de terceiros, por violação de direitos de imagem ou de Direitos Autorais.</p>

            <p>6.7. Caso o Cliente venha a ser demandado extrajudicialmente ou judicialmente por terceiros em razão de qualquer alegação de plágio, utilização indevida de obra autoral de terceiros, violação aos direitos de imagem, autorais e de propriedade industrial, o Parceiro obriga-se a indenizar, defender e manter isenta e pagar ou reembolsar o Cliente e seus respectivos sócios, diretores e funcionários por qualquer dano efetivamente incorrido e sofrido pelo Cliente.</p>

            <p>6.8. A Boutique Pool não possui qualquer responsabilidade sobre a violação de Direitos Autorais de terceiros na prestação dos Serviços, devendo a parte prejudicada buscar seus direitos junto ao Parceiro que violou tais direitos.</p>

            <p>6.9. O Parceiro é o único e exclusivo responsável pela obtenção de todas as autorizações de uso de imagem de terceiros em todo e qualquer material necessário à prestação dos Serviços. Tal autorização deverá abranger o uso da imagem do terceiro em todo território nacional e no exterior, sem que nada possa ser reclamado a título de direitos conexos à imagem do titular. </p>

            <p>6.9.1. Caso haja necessidade do pagamento de qualquer valor pelo uso de imagem de terceiros, este valor deverá estar incluído na Remuneração a ser paga pelo Cliente, não sendo devido nenhum montante adicional a este título.</p>

            <p>6.10. O Parceiro declara que possui e obterá junto a terceiros, quando for o caso, todas as licenças, permissões, autorizações e outros direitos necessários para a prestação dos Serviços.</p>

            <p><strong>VII. USO DOS NOMES E MARCAS DAS PARTES</strong></p>

            <p>7.1. As Partes não estão autorizadas a sublicenciar, ceder ou autorizar, seja total ou parcialmente, o nome empresarial e marcas da outra Parte. Nenhuma das Partes deverá contestar ou por qualquer forma prejudicar, direta ou indiretamente, a validade das marcas de propriedade da outra Parte, comprometendo-se a jamais discutir o direito de propriedade e de uso de tais marcas, tampouco requerer qualquer espécie de registro de marca, nome do domínio ou similar, no Brasil ou exterior, que contenha, reproduza ou imite as marcas, layouts e nome da outra Parte.</p>

            <p>7.2. As Partes não poderão usar os nomes e as marcas em questão de modo a causar prejuízo à outra Parte ou à sua reputação. É vedado qualquer outro uso das marcas. layouts e ou nomes de uma Parte pela outra, que não o uso autorizado neste instrumento, a não ser que haja prévio e expresso consentimento entre as Partes nesse sentido.</p>

            <p>7.3. Extinto este TEP, as Partes obrigam-se, imediatamente, a abster-se de usar, por qualquer modo, os nomes, marcas ou imitação gráfica, fonética, figurativa ou ideológica das Partes e todas e quaisquer referências dela oriundas, devendo suprimi-las onde quer que as venham utilizando e/ou expondo, inclusive papéis, notas fiscais, faturas, formulários, cartões e etc.</p>

            <p>7.4. O uso indevido ou inapropriado dos nomes, marcas e/ou produtos de qualquer das Partes sujeita a outra às medidas previstas nos Códigos Civil e Penal e qualquer outra legislação aplicável que ao caso couber, bem como a imediata rescisão deste TEP.</p>

            <p><strong>VIII. OBRIGAÇÕES DO PARCEIRO</strong></p>

            <p>8.1. Sem prejuízo de quaisquer outras obrigações previstas neste TEP, o Parceiro obriga-se a:</p>

            <p>(i) observar a legislação, regulamentos, resoluções e aplicáveis na prestação dos Serviços, sobretudo em relação às regulamentações envolvendo a legalidade de materiais publicitários produzidos, incluindo, mas sem se limitar, à Lei nº. 4.680/65, Decreto nº. 57.690/66, Lei nº. 8.069/90 (“Estatuto da Criança e do Adolescente”), Lei nº. 8.078/90 (“Código de Defesa do Consumidor”), Lei nº. 9.279/96 (“Lei da Propriedade Industrial”), Lei nº. 9.610/98 (“Lei de Direitos Autorais”), Código Brasileiro de Autorregulamentação Publicitária do Conselho Nacional de Autorregulamentação Publicitária – CONAR, entre outras aplicáveis, respeitando o direito dos consumidores, das crianças e adolescentes e dos idosos; os direitos de propriedade industrial e direitos autorais de terceiros; os direitos de imagem de terceiros; a concorrência leal; a moral e os bons costumes;</p>

            <p>(ii) não orientar terceiros a incluir nos materiais e conteúdos a serem desenvolvidos para o Cliente qualquer conteúdo inadequado, tais como ternos de baixo calão; material agressivo, obsceno e/ou pornográfico; material que possa causar danos a terceiros, através de difamação, injúria ou calúnia; informações que possam ser entendidas como apologia à prática de crimes ou contravenção penal; dados ou informações de cunho discriminatório, seja racial, sexual, étnico ou religioso; questões político-partidárias; violação a moral e bons costumes; o incentivo ao uso de álcool, drogas, tabaco ou armas de fogo; informações confidenciais ou sujeitas a contratos de confidencialidade; obras literárias, artísticas ou científicas protegidas pela Lei de Direitos Autorais em a autorização dos titulares de tais direitos, por meio de contrato celebrado com o Parceiro; imagem, voz ou nome de terceiros sem a autorização dos titulares de tais direitos através de contrato celebrado com o Parceiro; conteúdo capaz de violar quaisquer direitos de terceiros, incluindo, sem se limitar, direitos de propriedade industrial, direitos autorais, direitos de imagem, direitos de personalidade, ou que de qualquer forma possa causar danos a terceiros; e conteúdo ilícito e contrário à legislação;</p>

            <p>(iii) obedecer, na execução dos Serviços, estritamente os cronogramas que tenham sido previamente discutidos e acordados entre as Partes para a execução de determinados conteúdos, considerando que a publicidade deve ser planejada e veiculada dentro dos prazos acordados diante da sua importância e interação com momentos específicos, tais como promoções, datas comemorativas, marcos históricos do país, eventos esportivos entre outros.</p>

            <p><strong>IX. OBRIGAÇÕES DO CLIENTE</strong></p>

            <p>9.1. Sem prejuízo de quaisquer outras obrigações previstas neste instrumento, o Cliente obriga-se a:</p>

            <p>(i) efetuar o pagamento da Remuneração devida ao Parceiro nos termos deste TEP e do Anexo I;</p>

            <p>(ii) fornecer todas as informações e documentos necessários para o cumprimento dos Serviços, bem como todas as informações necessárias ao detalhamento dos Projetos;</p>

            <p>(iii) responsabilizar-se perante os consumidores, órgãos de defesa do consumidor, tais como PROCON, Ministério Público, ANVISA e outras entidades, pelas informações que prestar ao Parceiro sobre seus produtos e serviços para os materiais publicitários aprovados pelo Cliente, isentando o Parceiro de qualquer responsabilidade neste sentido, desde que o Parceiro não tenha alterado os materiais publicitários sem a autorização do Cliente ou não tenha utilizado tais informações de forma deturpada ou enganosa.</p>

            <p><strong>X. RELAÇÕES TRABALHISTAS</strong></p>

            <p>10.1. Sob nenhuma hipótese ou em qualquer situação, se presumirá a eventual existência, ou se estabelecerá a presunção de qualquer vínculo empregatício, ou obrigações de caráter trabalhista e previdenciário entre as Partes, por si e/com os prepostos ou empregados da outra Parte, nem qualquer das Partes será fiadora das obrigações e encargos trabalhistas e sociais da outra Parte a qual, assume, neste ato, integral responsabilidade por tais obrigações, inclusive civil e penal. As Partes serão integralmente responsáveis por serviços de terceiros que eventualmente sejam utilizados no cumprimento das obrigações assumidas neste TEP e no Anexo. Neste sentido, responderão pelas obrigações impostas pela legislação trabalhista e previdenciária, responsabilizando-se pelo recolhimento de todos os encargos fiscais, trabalhistas, sociais e previdenciários de seus empregados alocadas na execução de suas atividades.</p>

            <p>10.1.1.	O Cliente não tem qualquer responsabilidade sobre terceiros contratados pelo Parceiro para a prestação dos Serviços, sendo este o único e exclusivo responsável pelos termos de tal contratação, devendo excluir imediatamente o Cliente de qualquer demanda a que este for instado a responder no que se refere a este Parceiro.</p>

            <p><strong>XI. AVALIAÇÃO DE SATISFAÇÃO</strong></p>

            <p>11.1. 	A partir da definição das macro-etapas do Projeto e início da prestação de serviços por parte do Parceiro, as Partes declaram ter ciência que o Cliente receberá e-mails quinzenais ou mensais, conforme a natureza do Projeto, a serem disparados pela Plataforma Boutique Pool e através dos quais será instigado a se manifestar de maneira objetiva sobre a evolução dos serviços do Parceiro e sua satisfação com o Projeto.  </p>

            <p>11.1.1. Para os fins do quanto disposto no item 11.1. acima, serão consideradas avaliações ruins aquelas inferiores a 3, regular entre 3.0 e 3.99, boa entre 4.0 e 4.74 e excelente de 4.75 a 5.</p>

            <p>11.1.2.  Se as notas atribuídas pelo Cliente ao Parceiro forem inferiores a 3 nas duas primeiras avaliações, o Cliente poderá solicitar o cancelamento do Projeto ou a substituição do Parceiro. Na hipótese de cancelamento o Cliente poderá utilizar o saldo porventura existente para outros projetos contratados via Plataforma e, se optar pela substituição, avaliará outros Parceiros indicados pela Boutique Pool. </p>

            <p>11.1.3.  Nas hipóteses de cancelamento ou substituição, serão devidos ao Parceiro os valores relativos aos serviços efetivamente prestados, devendo ser observadas para fins de cálculo dos valores devidos as macro-etapas definidas pelo Cliente na Plataforma.  </p>

            <p><strong>XII. DISPOSIÇÕES GERAIS</strong></p>

            <p>12.1. Todas as notificações ou comunicações a serem trocadas entre as Partes serão feitas por escrito e enviadas, por Correios ou por e-mail, ambos com aviso de recebimento, para as pessoas responsáveis e aos endereços informados no preâmbulo acima e serão consideradas vigentes assim que forem efetivamente recebidas pela respectiva Parte, conforme documento hábil de comprovação de entrega:</p>

            <p>12.2. O Parceiro declara, expressamente, encontrar-se em ordem junto aos órgãos públicos e todas as autoridades competentes, possuindo todas as licenças, autorizações e registros necessários para o regular exercício de suas atividades, bem como possuir capacitação técnica adequada para o cumprimento deste TEP e do Anexo I, e, deste modo, declara ser a única responsável perante as autoridades competentes e quaisquer terceiros pelo cumprimento de todas as normas legais vigentes em decorrência do objeto deste TEP.</p>

            <p>12.3. Toda e qualquer alteração ou modificação deste TEP será objeto de termo aditivo firmado por escrito por ambas as Partes, que fará parte integrante e inseparável deste instrumento, para todos os fins de direito.</p>

            <p>12.4. O presente TEP não poderá ser cedido a terceiros, por qualquer das Partes, salvo mediante prévia e expressa anuência da outra Parte e obriga as Partes e seus herdeiros e/ou sucessores.</p>

            <p>12.5. A tolerância de quaisquer das Partes em relação a eventuais infrações da outra não importará modificação, novação ou renúncia a qualquer direito.</p>

            <p>12.6. As Partes, de comum acordo e de livre e espontânea vontade, declaram, neste ato, que todos os entendimentos e ajustes mantidos anteriormente, sejam de forma verbal ou escrita, estão integralmente traduzidos nas cláusulas deste instrumento e declaram terem sido devidamente assessoradas por profissionais capacitados a identificar e compreender o teor das cláusulas deste instrumento e demais documentos integrantes, bem como terem conhecimento das regras legais aplicáveis.</p>

            <p>12.7. Caso qualquer disposição deste instrumento seja considerada por lei ou tribunal de jurisdição competente, inválida, nula ou de outra forma inexequível, as disposições remanescentes permanecerão executáveis à máxima extensão permitida em lei.</p>

            <p>12.8. No caso de ocorrência de situações de caso fortuito e força maior que impeçam a execução dos Serviços, as Partes, de comum acordo, tomarão as medidas necessárias para atender e/ou restabelecer seus respectivos interesses.</p>

            <p>12.9. Toda e qualquer atividade desenvolvida por cada uma das Partes, será de inteira responsabilidade desta, que responderá individualmente perante as autoridades públicas competentes e perante terceiros, por todas as obrigações civis, administrativas, penais, ambientais, consumeristas, trabalhistas e tributárias que vier a assumir ou que por qualquer forma ou motivo venha a dar causa, em como as inerentes ou decorrentes da sua própria atividade.</p>

            <p>12.10. O presente instrumento não estabelece entre as Partes qualquer forma de sociedade, associação, responsabilidade solidária ou conjunta.</p>

            <p>12.11. O Parceiro declara ainda que fornece serviços para inúmeras outras empresas ou terceiros em geral, que possui capacidade empresarial plena e que, assim, não se estabelece por este instrumento nenhuma dependência econômica dela em relação ao Cliente. O Parceiro assume o compromisso de manter essa condição durante toda a vigência do presente TEP, isentando desde já o Cliente de qualquer responsabilidade neste sentido, em qualquer tempo ou lugar. </p>

            <p>12.12. A Boutique Pool assina este TEP na qualidade de interveniente anuente, ficando desde já estabelecido que sua atuação é limitada aos serviços por ela prestados por meio da Plataforma, conforme descrito neste instrumento e nos demais documentos que consubstanciam a relação da Boutique Pool com Cliente e Parceiro.</p>

            <p>12.12.1 Em caso de conflito entre Cliente e Parceiro, a Boutique Pool envidará seus melhores esforços para resolver tal conflito de acordo com as regras pré-definidas da Plataforma, não podendo, entretanto, ser responsabilizada por qualquer evento decorrente da referida relação, notadamente pelo pagamento de indenizações por perdas e danos, incluindo, mas não se limitando a, danos materiais e morais e lucros cessantes. </p>


            <p>12.12.2. O valor pago à Boutique Pool à título de Fee Transacional não implicará em qualquer garantia, pela Boutique Pool, de desempenho satisfatório, idoneidade, segurança, sucesso ou resultado quanto aos Serviços prestados pelo Parceiro no âmbito desse TEP. O Cliente poderá, sob sua exclusiva responsabilidade, adotar as medidas legais necessárias perante o Parceiro, caso queira obter indenização por eventuais danos materiais e morais, causados, a si ou terceiros.</p>

            <p>12.12.3. Não obstante as disposições acima, em qualquer hipótese, a responsabilidade da Boutique Pool nos termos deste instrumento é limitada ao valor da Remuneração do Serviço/Projeto contratado objeto deste TEP, contratado por meio da Plataforma. </p>

            <p>12.13. O Parceiro compromete-se a não realizar negociações, tais como, mas não limitadas a, ceder, caucionar, descontar, dar em pagamento, dar em garantia, de quaisquer duplicatas sacadas contra a Cliente em decorrência deste TEP , sendo nula de pleno direito qualquer negociação neste sentido, sob pena de indenizá-la pelos prejuízos a ela causados, sejam eles de que ordem for.</p>

            <p>12.14. As Partes, bem como os seus respectivos representantes legais, declaram que estão devidamente autorizados a aceitarem este Termo, na forma de seus respectivos instrumentos sociais.</p>

            <p>12.15. Os tributos de qualquer natureza que sejam devidos em decorrência direta dos Serviços são de exclusiva responsabilidade do contribuinte do respectivo tributo, conforme definido na norma tributária.</p>

            <p><strong>XIII. LEI DE REGÊNCIA</strong></p>

            <p>13.1. Este instrumento será regido e interpretado de acordo com as leis da República Federativa do Brasil.</p>

            <p><strong>XIV. RESOLUÇÃO DE DISPUTAS</strong></p>

            <p>14.1. Toda e qualquer controvérsia oriunda ou relacionada ao presente instrumento deverá ser resolvida por meio de arbitragem, nos termos do Regulamento da ARBITRANET, por um tribunal arbitral composto por 3 (três) árbitros, os quais serão nomeados de acordo com o disposto no referido regulamento. A arbitragem será conduzida em língua portuguesa e deverá ser realizada na cidade de São Paulo, Estado de São Paulo, Brasil. </p>

            <p>14.2. Sem prejuízo do disposto nesta Cláusula, as Partes reconhecem que qualquer uma delas poderá recorrer ao Poder Judiciário exclusivamente para as seguintes medidas, sendo que tais medidas não devem ser interpretadas como renúncia pelas Partes do procedimento de arbitragem: (i) para obter medidas liminares e cautelares previamente à confirmação do tribunal arbitral; (ii) para execução de qualquer decisão do tribunal arbitral, incluindo a sentença final; (iii) para a execução específica deste instrumento ; e (iv) para outros procedimentos expressamente admitidos pela Lei nº 9.307/96, conforme alterada. Para fins do disposto neste item, fica eleito o foro da capital de São Paulo.</p>

            <p>14.3. Quaisquer questionamentos referentes a esta cláusula compromissória serão também resolvidos por meio de arbitragem, na forma aqui disposta.</p>

        </div>
    </div>);
}

export default Terms;
