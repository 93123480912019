import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { connect } from 'react-redux';

import WhatifWrapper from '../components/WhatifWrapper';
import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import CardIdea from '../components/CardIdea';
import Loading from '../../../components/pages/loading';

import {
    Row,
    Col,
    Icon
} from 'antd';

import { IDEA_WHATIF_API } from '../../../actions/idea-whatif/actions';
import { PARTNER_WHATIF_API } from '../../../actions/partner-whatif/actions';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        api_get_my_ideas: id => {
            dispatch(IDEA_WHATIF_API.api_get_my_ideas(id));
        }
    };
};




const Main = styled.div`
  margin: 0 auto 100px;
  padding-top: 80px;

  .componentLoading {
      text-align: center;
      font-size: 40px;
      margin-top: 100px;
  }

  .noIdea {
      text-align: center;
  }

  .itemCard {
      margin-bottom: 30px;
  }

  .NavTabs {
      margin-bottom: 70px;
      border-top: solid 1px #d3d3d3;
      border-bottom: solid 1px #d3d3d3;
      display: inline-block;
      li {
          display: inline-block;

          a {
              display: block;
              color: #353535;
              height: 40px;
              font-size: 12px;
              font-weight: 700;
              line-height: 42px;
              backgorund: transparent;
              padding: 0 25px;
              transition: all .3s ease;

              &:hover, &:focus {
                background: #ccc;
              }

              &.active {
                  background: #353535;
                  color: #fff;
              }
          }
      }
  }
`;

class MyIdeas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ideasView: [],
            tabActive: "all",
            loadingIdeias: false
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partnerWhatIf != prevProps.partnerWhatIf || this.state.loadingIdeias != prevState.loadingIdeias) {
            this.props.api_get_my_ideas(this.props.partnerWhatIf.partnerWhatIfId);
        }

        if (this.props.ideaWhatIf.myIdeas != prevProps.ideaWhatIf.myIdeas) {
            this.setState({
                ideasView: this.props.ideaWhatIf.myIdeas
            });
        }
    }

    componentDidMount = () => {
        this.setState({
            loadingIdeias: true
        });
    }

    componentWillMount = () => {
        this.setState({
            loadingIdeias: false
        });
    }

    HandleFilterStatus = status => {
        this.setState({
            tabActive: status
        });

        switch (status) {
            case "all":
                this.setState({
                    ideasView: this.props.ideaWhatIf.myIdeas
                });
                break;
            case "create":
                this.setState({
                    ideasView: this.props.ideaWhatIf.myIdeas.filter(el => el.status == 2)
                });
                break;
            case "approved":
                this.setState({
                    ideasView: this.props.ideaWhatIf.myIdeas.filter(el => el.status == 15)
                });
                break;
            case "disapproved":
                this.setState({
                    ideasView: this.props.ideaWhatIf.myIdeas.filter(el => el.status == 18)
                });
                break;
            case "draft":
                this.setState({
                    ideasView: this.props.ideaWhatIf.myIdeas.filter(el => el.status == 1)
                });
                break;
            default:
                break;
        }
    }

    render() {
        if (!this.props.partnerWhatIf) {
            return (<Loading />);
        }

        const { ideaWhatIf: {
            myIdeas
        } } = this.props;

        const { ideasView, tabActive } = this.state;

 

        return (
            <WhatifWrapper
                grey
            >
                <SubHeader />
                <ContainerPage>
                    <Main>
                        <Row>
                            <Col sm={24}>
                                <ul className="NavTabs">
                                    <li><a onClick={() => this.HandleFilterStatus("all")} className={tabActive == "all" ? "active" : ""}>Todas</a></li>
                                    <li><a onClick={() => this.HandleFilterStatus("create")} className={tabActive == "create" ? "active" : ""}>Enviadas</a></li>
                                    <li><a onClick={() => this.HandleFilterStatus("approved")} className={tabActive == "approved" ? "active" : ""}>Aprovadas</a></li>
                                    <li><a onClick={() => this.HandleFilterStatus("disapproved")} className={tabActive == "disapproved" ? "active" : ""}>Recusadas</a></li>
                                    <li><a onClick={() => this.HandleFilterStatus("draft")} className={tabActive == "draft" ? "active" : ""}>Rascunho</a></li>
                                </ul>
                            </Col>
                        </Row>

                        {!myIdeas &&
                            <p className="componentLoading">
                                <Icon type="loading" />
                            </p>
                        }

                        {ideasView.length == 0 && myIdeas &&
                            <p className="noIdea">Não existe nenhuma ideia com esse status</p>
                        }

                        <Row type="flex" justify>
                            {ideasView && ideasView.map((item, index) => {
 
                                return (
                                    <Col sm={6} key={index} className="itemCard">
                                        <CardIdea
                                            status="draft"
                                            idea={item}
                                            path={`/parceiro-ideias/cadastro/ideia/${item.whatIfIdeaId}/view`}
                                        />
                                    </Col>
                                )
                            })}

                        </Row>
                    </Main>
                </ContainerPage>
            </WhatifWrapper >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyIdeas);
