import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { CLIENT_API } from '../../../actions/client/actions';
import { PROJECT_API } from '../../../actions/project/actions';
import styled from 'styled-components';
import LoggedPage from '../../page-templates/fillForm-logged';
import { Table, Icon } from 'antd';
import { formatMoney2 } from '../../../utils/helpers';

import { ProjectTypeNames } from '../../../model';

import FormatCurrency from '../../ui/format-currency';

class NdaList extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {
    }


    render() {

        const data = this.props.ndas && this.props.ndas.map((item) => {
            return {
                type: item.projectType,
                name: item.projectName,
                money: item.projectTotal,
                currency: item.projectCurrency,
                partnerName: item.partnerName,
                files: item
            }
        });

        const columns = [
            {
                title: I18n.get("Tipo"),
                dataIndex: 'type',
                key: 'type',
                render: v => ProjectTypeNames[v]
            },
            {
                title: I18n.get("Projeto"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: I18n.get("Valor"),
                key: 'money',
                render: (text, record) => (
                    <FormatCurrency amount={record.money} currency={record.currency} />
                )
            },
            {
                title: I18n.get("Parceiro"),
                dataIndex: 'partnerName',
                key: 'partnerName',
            },
            {
                title: I18n.get("PDF"),
                dataIndex: 'files',
                key: 'files',
                render: (record) => {

                    return (
                        <div>
                            {record.pdfProject &&
                                <a href={record.pdfProject} target="_blank" style={{ marginRight: '1rem' }}><Icon type="file-pdf" /> {I18n.get("Projeto")}</a>
                            }
                            {record.partnerNdaUploadedUrl &&
                                <a href={record.partnerNdaUploadedUrl} target="_blank"><Icon type="file-pdf" /> {I18n.get("Nda")}</a>
                            }
                        </div>
                    );
                }
            },

        ];

        return (<div>
            <h2 className="title-bpool medium">{I18n.get("NDA de Parceiros")}</h2>

            <div className="table-main">
                <Table scroll={{ x: 640 }} columns={columns} dataSource={data} />
            </div>
        </div>);
    }
}

export default (NdaList);
