import styled from "styled-components";
import { Skeleton as SkeletonAntd, Modal } from "antd";

export const Wrapper = styled.div``;

export const Form = styled.div`
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const CheckboxTerms = styled.div`
  .ant-checkbox-wrapper {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1890ff;
      border-color: #1890ff;
    }
    .ant-checkbox-inner {
      border: 1px solid #d9d9d9;
    }
  }
  span {
    font-weight: 400;
    font-size: 15px;
    color: #000000;
  }
  button {
    background: transparent;
    border: none;
    padding: 0;
    margin-left: 5px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const RadioGroups = styled.div`
  margin: 16px 0;

  .ant-radio-inner::after {
    top: 4px;
    left: 4px;
  }
`;

export const Actions = styled.div`
  margin-top: 24px;
  margin-bottom: 200px;
  display: flex;
  justify-content: flex-end;
`;

export const Info = styled.div`
  label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 600;
  }
  p {
    height: 40px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`;

export const ErrorMsg = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-infos {
    height: 20px;
    margin-bottom: 28px;

    li {
      height: 100%;
    }
  }
`;

export const WrapperModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 40px;
  }
`;

export const TermsWrapper = styled.div`
  height: 500px;
  overflow-x: hidden;
`;
