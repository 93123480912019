import React from "react";
import { I18n } from "aws-amplify";
import { Checkbox } from "antd";
import { useHistory } from "react-router-dom";
import { ButtonBpool } from "../../UI/ButtonBpool";
import { Drawer } from "../../UI/Drawer";
import { SelectOne } from "../../UI/SelectOne";
import { NotificationBPool } from "../../UI/NotificationBPool";
import { Transfer, Pix, Swift } from "./PaymentMethods";
import * as S from "./styles";
import { DrawerInfos } from "./drawerInfos";
import PlusIcon from "./svgs/plus-icon.svg";

const paymentsMethodsBR = [
  {
    code: "TRANSFER",
    label: I18n.get("Transferência"),
  },
  {
    code: "PIX",
    label: I18n.get("Pix"),
  },
];

const paymentsMethods = [
  {
    code: "SWIFT",
    label: I18n.get("Swift"),
  },
];

export const DataBank = ({
  data,
  isLoading,
  countryCode,
  handleRefresh,
  isAdmin,
  addBillingData,
  updateBillingData,
  deleteBillingData,
  isFullRegister,
}) => {
  let history = useHistory();
  const [openDrawer, setOpenDrawer] = React.useState({ open: false, id: "" });
  const [clearing, setClearing] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState("TRANSFER");
  const [inputsForm, setInputsForm] = React.useState({});
  const [errorsInputs, setErrorsInputs] = React.useState([]);
  const [checkboxIsMain, setCheckboxIsMain] = React.useState(false);
  const [dataSubirNf, setDataSubirNf] = React.useState({});

  React.useEffect(() => {
    if (countryCode === "BRA") {
      setPaymentMethod("TRANSFER");
    } else {
      setPaymentMethod("SWIFT");
    }
  }, [countryCode]);

  const onChangeCheckboxIsMain = (e) => {
    setCheckboxIsMain(e?.target?.checked);
  };

  const clearInputs = async () => {
    setPaymentMethod(countryCode === "BRA" ? "TRANSFER" : "SWIFT");
    setInputsForm({});
    setErrorsInputs([]);
    setCheckboxIsMain(false);
  };

  const handleCloseDrawer = async () => {
    setOpenDrawer({ open: false, id: "" });
    setClearing(true);
    await clearInputs();
    setClearing(false);
  };

  const handleOpenEditDrawer = (data) => {
    setPaymentMethod(data?.paymentMethod);
    setCheckboxIsMain(data?.isMain);
    setInputsForm({ ...data });
    setOpenDrawer({ open: true, id: data?.id });
  };

  const renderData = ({ item }) => {
    if (item?.paymentMethod === "TRANSFER") {
      return (
        <Transfer
          data={item}
          isLoading={isLoading}
          handleOpenEditDrawer={handleOpenEditDrawer}
          handleRefresh={handleRefresh}
          isAdmin={isAdmin}
          updateBillingData={updateBillingData}
          deleteBillingData={deleteBillingData}
        />
      );
    } else if (item?.paymentMethod === "PIX") {
      return (
        <Pix
          data={item}
          isLoading={isLoading}
          handleOpenEditDrawer={handleOpenEditDrawer}
          handleRefresh={handleRefresh}
          isAdmin={isAdmin}
          updateBillingData={updateBillingData}
          deleteBillingData={deleteBillingData}
        />
      );
    } else if (item?.paymentMethod === "SWIFT") {
      return (
        <Swift
          data={item}
          isLoading={isLoading}
          handleOpenEditDrawer={handleOpenEditDrawer}
          handleRefresh={handleRefresh}
          isAdmin={isAdmin}
          updateBillingData={updateBillingData}
          deleteBillingData={deleteBillingData}
        />
      );
    }
  };

  const onChangeInputForm = async (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const onChangePaymentMethod = async (value) => {
    setClearing(true);
    await clearInputs();
    setClearing(false);
    setPaymentMethod(value);
  };

  const addOrUpdateAccount = async () => {
    try {
      setIsSending(true);
      const objSend = {
        paymentMethod,
        ...inputsForm,
        isMain: isFullRegister ? true : checkboxIsMain,
      };
      openDrawer?.id
        ? await updateBillingData({
            admin: isAdmin,
            form: { ...objSend, id: openDrawer?.id },
          })
        : await addBillingData({ admin: isAdmin, form: objSend });
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: openDrawer?.id
          ? I18n.get("A conta foi atualizada.")
          : I18n.get("A conta foi cadastrada."),
        duration: 3,
      });
      handleCloseDrawer();
      await handleRefresh();
    } catch (error) {
      setErrorsInputs(error?.response?.data?.errors);
    } finally {
      setIsSending(false);
    }
  };

  React.useEffect(() => {
    const backSubirNf = localStorage.getItem("@BPOOL-accounts-dataSubirNf");
    if (backSubirNf) {
      setDataSubirNf(JSON.parse(backSubirNf));
    }
  }, []);

  return (
    <>
      <S.Wrapper>
        {dataSubirNf?.urlBack ? (
          <div style={{ marginTop: 32 }}>
            <ButtonBpool
              text={I18n.get("Voltar para subir nova nota")}
              onClick={() => history.push(dataSubirNf?.urlBack)}
              theme="primary"
              loading={false}
            />
          </div>
        ) : null}
        {data?.length ? (
          data?.map((item) => <div key={item?.id}>{renderData({ item })}</div>)
        ) : isFullRegister ? (
          <S.Empty>
            <p>
              {I18n.get(
                "Para prosseguir você deve adicionar um método de recebimento"
              )}
            </p>
          </S.Empty>
        ) : (
          <S.Empty>
            <p>{I18n.get("Nenhuma conta cadastrada até o momento.")}</p>
          </S.Empty>
        )}
        {isFullRegister && data?.length ? null : (
          <S.Actions isFullRegister={isFullRegister}>
            <ButtonBpool
              text={I18n.get(
                !isFullRegister
                  ? "Adicionar nova conta"
                  : "Adicionar forma de recebimento"
              )}
              icon={PlusIcon}
              onClick={() => setOpenDrawer({ open: true, id: "" })}
              theme="tertiary"
              loading={false}
            />
          </S.Actions>
        )}
      </S.Wrapper>

      <Drawer
        open={openDrawer.open}
        handleClose={handleCloseDrawer}
        width={600}
        buttons={
          <>
            <ButtonBpool
              text={I18n.get("Cancelar")}
              onClick={handleCloseDrawer}
              theme="tertiary"
            />
            <div style={{ marginLeft: 16 }}>
              <ButtonBpool
                text={I18n.get("Salvar dados")}
                onClick={addOrUpdateAccount}
                theme="primary"
                loading={isSending}
              />
            </div>
          </>
        }
      >
        <S.TitleContent style={{ marginTop: 32 }}>
          <h3>{I18n.get("Cadastrar Conta")}</h3>
        </S.TitleContent>
        <S.Form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ height: 80, width: "calc(50% - 15px)" }}>
              <SelectOne
                required
                options={
                  countryCode === "BRA" ? paymentsMethodsBR : paymentsMethods
                }
                value={paymentMethod}
                onChange={(value) => onChangePaymentMethod(value)}
                placeholder={I18n.get("Tipo")}
                error=""
              />
            </div>

            {isFullRegister ? null : (
              <div style={{ height: 80, width: "calc(50% - 15px)" }}>
                <S.CheckboxItem>
                  <Checkbox
                    checked={checkboxIsMain}
                    onChange={onChangeCheckboxIsMain}
                  >
                    <p>{I18n.get("Essa é minha conta principal")}</p>
                  </Checkbox>
                </S.CheckboxItem>
              </div>
            )}
          </div>

          <DrawerInfos
            paymentMethod={paymentMethod}
            inputsForm={inputsForm}
            clearing={clearing}
            handleChangeInput={onChangeInputForm}
            errorsInputs={errorsInputs}
            handleSetErrorsInputs={(errors) => setErrorsInputs(errors)}
          />
        </S.Form>
      </Drawer>
    </>
  );
};
