import React, { useState } from "react";
import Button from "../Button";
import MainTitle from "../MainTitle";
import * as S from "./styles";
import dashIcon from "../../../svg/dash-icon.svg";
import pendencieIcon from "../../../svg/pendencie-icon.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";

const DashAndPendencies = ({ auth, pendencies, dashboard }) => {
  const [onView, setOnView] = useState("pendencias");
  const cols = {
    percent: {
      formatter: (val) => {
        val = val * 100 + "%";
        return val;
      },
    },
  };

  const dashData =
    dashboard &&
    dashboard.map((i) => {
      return {
        name: i.monthAbbreviation,
        total: i.total,
      };
    });

  return (
    <div>
      <MainTitle text={I18n.get("Central de Pendências e Dashboard")} />
      <S.DashPendencieBoard>
        <div>
          <div onClick={() => setOnView("dashboard")}>
            <Button
              text={I18n.get("Dashboard")}
              icon={dashIcon}
              isDisabled={onView === "dashboard" ? false : true}
              padding="12px 24px"
            />
          </div>
          <div onClick={() => setOnView("pendencias")}>
            <Button
              text={I18n.get("Pendências")}
              icon={pendencieIcon}
              isDisabled={onView === "pendencias" ? false : true}
              padding="12px 24px"
            />
          </div>
        </div>
        {onView === "dashboard" ? (
          <div className="dashboard">
            <Chart
              height={240}
              width={350}
              data={dashData}
              scale={cols}
              forceFit
              className={`GraphCard ${dashboard ? "show" : "hidden"}`}
            >
              <Axis name="name" />
              <Axis name="total" />
              <Tooltip
                crosshairs={{
                  type: "y",
                }}
              />
              <Geom type="interval" position="name*total" />
            </Chart>
          </div>
        ) : (
          <div className="pendencie">
            {pendencies &&
              pendencies.map((res, index) => (
                <S.Pendencie key={index}>
                  <p>{res.message}</p>
                  <div>
                    <p>{res.date}</p>
                    <p>{I18n.get("Em andamento")}</p>
                  </div>
                </S.Pendencie>
              ))}
            {pendencies.length < 1 && (
              <S.Pendencie>{I18n.get("Nenhum dado a ser exibido")}</S.Pendencie>
            )}
          </div>
        )}

        {onView === "dashboard" ? (
          <>
            {auth.is_client && (
              <Button
                text={I18n.get("Ir para Página")}
                href="/#/cliente/dashboard"
              />
            )}
            {auth.is_partner && (
              <Button
                text={I18n.get("Ir para Página")}
                href="/#/parceiro/dashboard"
              />
            )}
          </>
        ) : (
          <>
            {auth.is_client && (
              <Button
                text={I18n.get("Ir para Página")}
                href="/#/cliente/central-de-pendencias"
              />
            )}
            {auth.is_partner && (
              <Button
                text={I18n.get("Ir para Página")}
                href="/#/parceiro/central-de-pendencias"
              />
            )}
          </>
        )}
      </S.DashPendencieBoard>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(DashAndPendencies));
