import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import TextField from '../../form/text-field';
import PercentageField from '../../form/percentage-field';
import PlusButtonField from '../../form/plus-button-field';

class BudgetItem extends React.Component {
    constructor(props) {

        super(props);

        const { item } = props;

        this.state = {
            fields: {
                name: {
                    label: I18n.get("Item"),
                    onChange: this.onChangeForm.bind(this, 'name'),
                    visited: true,
                    successIcon: false
                },
                perc: {
                    label: I18n.get("Percentual"),
                    onChange: this.onChangeForm.bind(this, 'perc'),
                    visited: true,
                    successIcon: false,
                    decimalScale: 0
                },
            },
            errors: {},
            form: {
                ...item
            },
            task: {},
            submit_task: {}
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onAddBudgetItem(e) {

        const { name, perc } = this.state.form;

        const fields = ['name', 'perc'];

        const validation = fields.map((field) => this.state.form[field] && this.state.form[field].length);

        const errors = {
            name: validation[0] ? null : { errorMessage: I18n.get('Por favor inforne o Item') },
            perc: validation[1] ? null : { errorMessage: I18n.get('Por favor inforne o Percentual') },
        };

        const valid = validation.reduce((acc, v) => acc && v);

        const nextState = {
            errors: errors,
        };

        if (valid) {

            this.props.onAddBudgetItem(this.state.form);

            nextState.form = {};
        };

        this.setState(nextState);

    }

    onDelBudgetItem(item, e) {

        this.props.onDelBudgetItem(item, e);
    }

    render() {

        const { item } = this.props;

        return (

            <form>

                <div className="columns">

                    <div className="column is-6">
                        <TextField {...this.state.fields.name} value={this.state.form.name} error={this.state.errors.name} />

                    </div>

                    <div className="column is-4">
                        <PercentageField {...this.state.fields.perc} value={this.state.form.perc} error={this.state.errors.perc} />
                    </div>

                    <div className="column is-2 has-text-right">
                        <div className="field is-narrow">
                            <label className="label is-small">
                                &nbsp;
                            </label>
                            <div className="control">
                                {!item && <>
                                    <a onClick={this.onAddBudgetItem.bind(this)} className='button is-rounded is-black'>
                                        <span className="icon is-small">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                    </a>
                                </>}
                                {item && <>
                                    <a onClick={this.onDelBudgetItem.bind(this, item)} className='button is-rounded is-black is-outlined'>
                                        <span className="icon is-small">
                                            <i className="fa fa-times"></i>
                                        </span>
                                    </a>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        );
    }
}

export default BudgetItem;
