import React from 'react';
import { I18n } from 'aws-amplify';

import { Table, Tag } from 'antd';

import FormatCurrency from '../../ui/format-currency';

import { ClientProjectStatus, ProjectExtraTypeNames } from '../../../model';

import { getQuotationStatus } from '../../../utils/extras';


import QuotationForm from '../../client-project-ongoing/extras/quotation-form';


export default class ExtraDetails extends React.Component {

    constructor(props) {
        super();

        this.state = {
        };
    }

    componentDidMount() {
    }


    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientProjectExtraStatusesMap) return '';

        return this.props.bp.clientProjectExtraStatusesMap[value].label;
    }

    render() {

        const { extraName, extraType, budget, dueDate, description, comments, files = [], bpCommission = {}, workingBudget } = this.props;

        const { quotation = {}, currency = {} } = this.props;

        return (
            <div>
                <p>
                    <strong>{I18n.get("Tipo")}:</strong> {I18n.get(ProjectExtraTypeNames[extraType])}
                </p>
                <p>
                    <strong>{I18n.get("Nome")}:</strong> {extraName}
                </p>
                <br />
                <p>
                    <strong>{I18n.get("Budget")}:</strong> <FormatCurrency amount={budget} currency={currency} />
                </p>
                <p>
                    <strong>{I18n.get("Platform Fee")}:</strong> {bpCommission && bpCommission.perc}%
                </p>
                <p>
                    <strong>{I18n.get("Budget Disponível")}:</strong> <FormatCurrency amount={workingBudget} currency={currency} />
                </p>
                <br />
                <p>
                    <strong>{I18n.get("Data de Entrega")}:</strong> {dueDate}
                </p>
                <br />

                <div className="field">
                    <label className="label">{I18n.get("Descrição")}</label>
                    <div className="control">
                        {description}
                    </div>
                </div>

                <br />

                <div className="field">
                    <label className="label">{I18n.get("Observações")}</label>
                    <div className="control">
                        {comments}
                    </div>
                </div>

                <br />
                <br />

                <div className="field">
                    <label className="label">{I18n.get("Anexos")}</label>
                </div>

                {files && files.map((item, index) => {
                    return (<div key={index} title={item.name} className="big-idea-box" style={{ width: '5rem', display: 'inline-block' }}>

                        <a href={item.url} target="_blank" style={{ color: '#ccc' }}>
                            <span className="icon is-large" style={{ marginTop: '1rem' }}>
                                <i className="fas fa-file fa-3x" aria-hidden="true"></i>
                            </span>
                        </a>

                        <div style={{ width: '5rem', padding: '0.5rem', fontSize: '80%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</div>
                    </div>
                    );
                })}

                {files && files.length == 0 && <p className="has-text-grey-light" style={{ fontSize: '80%' }}>{I18n.get("Nenhum anexo.")}</p>}

                <br />
                <br />
                <p style={{ fontSize: '80%' }}>{I18n.get("Última atualização:")} {this.props.updatedOn}
                </p>


                <br /><br />

                <h1>{I18n.get("Orçamento")}</h1>

                <QuotationForm {...this.props} auth={this.props.auth} bp={this.props.bp} errors={{}} quotation={quotation} currency={currency} />

            </div>
        );
    }
}
