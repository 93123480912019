import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../../utils/task";

import Form from "./_form";

import BillingItem from "../../shared/billing-item";

import Installments from "./_installments";

import FormatCurrency from "../../ui/format-currency";

export default class ProjectDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      detailsView: null,
      task: {},
    };
  }

  componentDidMount() {
    this.setState({ task: {} });
  }

  render() {
    const { billing = {}, currency = {}, billingCurrency = {} } = this.props;

    return (
      <div className="content">
        <BillingItem
          billing={billing}
          currency={billingCurrency}
          isAdmin={true}
          handleNF={this.props.handleNF}
          sendCommission={this.props.sendCommission}
        />

        <br />

        <div className="card">
          <div className="card-content">
            <p className="is-pulled-right">
              <strong>{I18n.get("Saldo:")}</strong>{" "}
              <FormatCurrency
                amount={billing.balance}
                currency={billingCurrency}
              />
            </p>

            <p className="title-bpool medium">
              <strong>{I18n.get("Parcelas")}</strong>
            </p>

            <Form {...this.props} />

            <br />
            <br />

            <Installments
              {...this.props}
              billing={billing}
              currency={billingCurrency}
            />
          </div>
        </div>
      </div>
    );
  }
}
