import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { Collapse } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import * as S from './styles';

const { Panel } = Collapse;

export const ItemReferrers = ({ data, languages, onDelete, onEdit }) => {
  console.log('data', data);

  const onChange = (key) => {
    return;
  };

  const handleEdit = (item) => {
    onEdit(item);
  };

  const handleDelete = (item) => {
    onDelete(item);
  };

  const genExtra = (item) => (
    <>
      <EditOutlined
        onClick={(event) => {
          event.stopPropagation();
          handleEdit(item);
        }}
      />
      <DeleteOutlined
        style={{ marginLeft: 15 }}
        onClick={(event) => {
          event.stopPropagation();
          handleDelete(item);
        }}
      />
    </>
  );

  const renderInfo = ({ label, value }) => {
    return (
      <S.ItemCase>
        <S.ItemCaseLabel>{label}</S.ItemCaseLabel>
        <S.ItemCaseValue>{value ? value : '---'}</S.ItemCaseValue>
      </S.ItemCase>
    );
  };

  const renderLanguageLabel = (id) => {
    const find = languages?.find((item) => item?.code === id);

    if (find) {
      return find?.label;
    } else {
      return id;
    }
  };

  return (
    <>
      <S.WrapperItemCaseForm>
        {data.length ? (
          <Collapse onChange={onChange}>
            {data.map((item, index) => (
              <Panel
                key={item?.id}
                header={`Referral ${index + 1} - ${item?.name}`}
                extra={genExtra(item)}
              >
                <S.ContentItemCase>
                  {renderInfo({
                    label: I18n.get('Referrer name'),
                    value: item?.name,
                  })}
                  {renderInfo({
                    label: I18n.get('Referrer email'),
                    value: item?.email,
                  })}
                  {renderInfo({
                    label: I18n.get('Referrer language'),
                    value: renderLanguageLabel(item?.culture),
                  })}
                </S.ContentItemCase>
              </Panel>
            ))}
          </Collapse>
        ) : (
          <S.ItemCaseFormEmpty>
            {/* {I18n.get('No cases added so far.')} */}
          </S.ItemCaseFormEmpty>
        )}
      </S.WrapperItemCaseForm>
    </>
  );
};
