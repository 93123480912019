import React, { Fragment } from 'react';

import { I18n } from 'aws-amplify';
import {
    Icon,
    Button,
    Row,
    Col
} from 'antd';

const Card = (props) => {

    if (!props.bp) return null;
    if (!props.bp.partner) return null;
    if (!props.bp.partner.company) return null;

    const item = props.bp.partner.company;

    return (
        <div className="card">
            <div className="card-content">
                {/* <h3 className="title is-4">{I18n.get("Endereço")}</h3> */}


                <Row gutter={1}>
                    <Col sm={12}>
                        <h2 className="title-bpool regular">{I18n.get("Endereço")} {I18n.get("CNPJ")}</h2>

                        <p>
                            <b>{I18n.get("Logradouro")}: </b> {item.addressLine1} {item.addressLine2}
                        </p>
                        <p>
                            {item.city}
                            {item.state &&
                                <Fragment> / {item.state}</Fragment>
                            }
                        </p>
                        <p>
                            <b>{I18n.get("CEP")}: </b> {item.postcode}
                        </p>
                    </Col>


                    <Col sm={12}>
                        <h2 className="title-bpool regular">{I18n.get("Endereço")} {I18n.get("Comercial")}</h2>

                        <p>
                            <b>{I18n.get("Logradouro")}: </b> {item.commercialAddressLine1} {item.commercialAddressLine2}
                        </p>
                        <p>
                            {item.commercialCity}
                            {item.commercialState &&
                                <Fragment> / {item.commercialState}</Fragment>
                            }
                        </p>
                        <p>
                            <b>{I18n.get("CEP")}: </b> {item.commercialPostcode}
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Card;