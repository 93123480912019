import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import { TitleMiddleLine } from '../../components/Titles';
import { connect } from 'react-redux';
import constants from "../../../constants";
import { PARTNER_API } from '../../../actions/partner/actions';

import FormSkeleton from "../../components/FormSkeleton";

import Config from '../../../config';

import {
    Row,
    Col,
    Button,
    Icon,
    Checkbox,
    Tooltip,
    Input,
    Select,
    Form
} from 'antd';


const mapStateToProps = state => {
    return Object.assign({}, { bp: state.bp, marketExpertise: { errors: {} }, }, state.partner);
};

const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            dispatch(PARTNER_API.update_specialism(data));
        },
    };
};

const { TextArea } = Input;
const { Option } = Select;

class FormAbout extends Component {
    constructor(props) {
        super(props);

    }

    state = {
        fields: [],
        specialismsSelected: this.props.partner.specialisms || [],
        eventLiveMarketing: this.props.partner.eventLiveMarketing || [],
        eventLiveMarketingBrandExperience: {},
        eventLiveMarketingBrandIncentive: {},
        eventLiveMarketingEvents: {},
        eventLiveMarketingLabor: {},
        eventLiveMarketingShopper: {},
    }

    chageMtk = (e) => {
        let eventChecks = this.state.eventLiveMarketing;

        if (this.state.eventLiveMarketing.indexOf(e.target.value) > -1) {
            eventChecks.splice(eventChecks.indexOf(e.target.value), 1);
        } else {
            eventChecks.push(e.target.value);
        }

        setTimeout(() => {
            this.setState({
                eventLiveMarketing: eventChecks,
                fields: this.props.form.getFieldsValue()
            });
        }, 50);

        this.getValuesForm()

    }

    getValuesForm = () => {
        const fieldsForm = this.props.form.getFieldsValue();


        setTimeout(() => {
            const data = {
                ...fieldsForm,
                partnerId: this.props.partnerId,
                specialisms: this.state.specialismsSelected,
                eventLiveMarketingBrandExperience: {
                    size: fieldsForm.eventLiveMarketingBrandExperienceSize
                },
                eventLiveMarketingBrandIncentive: {
                    size: fieldsForm.eventLiveMarketingBrandIncentiveSize
                },
                eventLiveMarketingEvents: {
                    type: fieldsForm.eventLiveMarketingEventsType,
                    corporate: fieldsForm.eventLiveMarketingEventsCorporate,
                    size: fieldsForm.eventLiveMarketingEventsSize,
                },
                eventLiveMarketingLabor: {
                    type: fieldsForm.eventLiveMarketingLaborType,
                    contract: fieldsForm.eventLiveMarketingLaborContract,
                    size: fieldsForm.eventLiveMarketingLaborSize,
                },
                eventLiveMarketingShopper: {
                    type: fieldsForm.eventLiveMarketingShopperType,
                    size: fieldsForm.eventLiveMarketingShopperSize,
                },
                eventLiveMarketing: this.state.eventLiveMarketing,
                marketExpertises: this.props.partner.marketExpertises && this.props.partner.marketExpertises
            };

            this.props.action(data)
        }, 3);


    }


    onChangeCheck = (values) => {
        this.setState({
            specialismsSelected: values
        });


        setTimeout(() => {
            if (this.state.specialismsSelected.indexOf("LIVE_MKT") == -1) {

                this.setState({
                    eventLiveMarketing: [],
                    eventLiveMarketingBrandExperience: {},
                    eventLiveMarketingBrandIncentive: {},
                    eventLiveMarketingEvents: {},
                    eventLiveMarketingLabor: {},
                    eventLiveMarketingShopper: {},
                });
            }
        }, 1);

        setTimeout(() => {
            this.getValuesForm();
        }, 2);


    }

    onChangeCheckEvent = () => {
        setTimeout(() => {
            this.getValuesForm();
        }, 50);
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partner.specialisms != prevProps.partner.specialisms) {
            this.setState({
                eventLiveMarketing: this.props.partner.eventLiveMarketing,
                specialismsSelected: this.props.partner.specialisms
            });
        };

        if (this.props.partner.eventLiveMarketing != prevProps.partner.eventLiveMarketing) {
            this.setState({
                eventLiveMarketing: this.props.partner.eventLiveMarketing,
            });
        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        if (!this.props.partner || !this.props.bp.specialisms) return <FormSkeleton />;

        const { partner } = this.props;

        const isEvent = (item) => item.code.substring(0, 3) === 'EV_';

        const specsSorted = this.props.bp.specialisms && this.props.bp.specialisms.filter((item) => !isEvent(item))
            .sort((a, b) => (a.label > b.label) - (a.label < b.label));

        const specEvents = this.props.bp.specialisms && this.props.bp.specialisms.filter((item) => isEvent(item))
            .sort((a, b) => (a.label > b.label) - (a.label < b.label));

        let dataSpec = specsSorted && specsSorted.map((item) => {
            return {
                ...item,
                value: item.code
            }
        });

        const { moduloEvento } = Config;

        //TODO: Find better way... GAMBI!
        let mktSize = [...constants.MKT_SIZE];
        for (let key in mktSize) {
            mktSize[key].label = I18n.get(mktSize[key].label);
        }

        let mktComplex = [...constants.MKT_COMPLEXITY];
        for (let key in mktComplex) {
            mktComplex[key].label = I18n.get(mktComplex[key].label);
        }

        let mktType = [...constants.MKT_EVENTSTYPE];
        for (let key in mktType) {
            mktType[key].label = I18n.get(mktType[key].label);
        }

        let mktCorporate = [...constants.MKT_CORPORATETYPE];
        for (let key in mktCorporate) {
            mktCorporate[key].label = I18n.get(mktCorporate[key].label);
        }

        let mktLaborType = [...constants.LABORTYPES];
        for (let key in mktLaborType) {
            mktLaborType[key].label = I18n.get(mktLaborType[key].label);
        }

        let mktContractType = [...constants.CONTRACTTYPES];
        for (let key in mktContractType) {
            mktContractType[key].label = I18n.get(mktContractType[key].label);
        }
        return (
            <Form onSubmit={this.handleSubmitSave}>
                <Row className="RowSection">
                    <Col sm={24}>
                        <TitleMiddleLine>{I18n.get("Classifique sua empresa em até 4 especialidades da lista abaixo:")}</TitleMiddleLine>
                    </Col>

                    <Col sm={124}>
                        <Form.Item label="">
                            {getFieldDecorator('specialisms', {
                                rules: [{ required: true, message: 'Campo obrigatório!' }],
                                initialValue: this.props.partner.specialisms ? this.props.partner.specialisms : []
                                // valuePropName: 'checked'
                            })(
                                <Checkbox.Group
                                    onChange={(e) => this.onChangeCheck(e)}

                                >
                                    {dataSpec && dataSpec.map((i) => {

                                        let disabledItem = this.state.specialismsSelected.length > 3 && this.state.specialismsSelected.indexOf(i.code) == -1;
                                        return <Col sm={12}><Checkbox disabled={disabledItem} value={i.code}>{i.label} <Tooltip title={i.description}><Icon type="question-circle" theme="filled" /></Tooltip></Checkbox></Col>
                                    })}
                                </Checkbox.Group>


                            )}
                        </Form.Item>
                    </Col>



                </Row>
                {this.state.specialismsSelected && this.state.specialismsSelected.indexOf("LIVE_MKT") > -1 &&
                    <Fragment>
                        <Row>
                            <Col sm={24}>
                                <TitleMiddleLine>{I18n.get("Opções para Live Marketing:")}</TitleMiddleLine>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <Checkbox
                                    onChange={e => this.chageMtk(e)}
                                    value="Experiência de Marca"
                                    checked={this.props.partner.eventLiveMarketing && this.props.partner.eventLiveMarketing.indexOf("Experiência de Marca") > -1}
                                    disabled={
                                        this.state.eventLiveMarketing.indexOf("Incentivo") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Mão de Obra") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Shopper") > -1 ? true : false
                                    }
                                >{I18n.get("Experiência de Marca")}</Checkbox>
                            </Col>
                        </Row>

                        {this.state.eventLiveMarketing.indexOf("Experiência de Marca") > -1 &&
                            <Row>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Porte")}>
                                        {getFieldDecorator('eventLiveMarketingBrandExperienceSize', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingBrandExperience?.size && this.props.partner.eventLiveMarketingBrandExperience.size ? this.props.partner.eventLiveMarketingBrandExperience.size : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}


                                            // onChange= {(e) => this.onChangeCheckEvent(e)}
                                            >
                                                {mktSize.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col sm={24}>
                                <Checkbox
                                    onChange={e => this.chageMtk(e)}
                                    value="Eventos"
                                    checked={this.props.partner.eventLiveMarketing && this.props.partner.eventLiveMarketing.indexOf("Eventos") > -1}
                                    disabled={
                                        this.state.eventLiveMarketing.indexOf("Incentivo") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Mão de Obra") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Shopper") > -1 ? true : false
                                    }
                                >{I18n.get("Eventos")}</Checkbox>
                            </Col>
                        </Row>
                        {this.state.eventLiveMarketing.indexOf("Eventos") > -1 &&
                            <Row>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Tipos de evento")}>
                                        {getFieldDecorator('eventLiveMarketingEventsType', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingEvents?.type && this.props.partner.eventLiveMarketingEvents.type ? this.props.partner.eventLiveMarketingEvents.type : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}
                                            >
                                                {mktType.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Tipos de evento")}>
                                        {getFieldDecorator('eventLiveMarketingEventsCorporate', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingEvents?.corporate && this.props.partner.eventLiveMarketingEvents.corporate ? this.props.partner.eventLiveMarketingEvents.corporate : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}
                                            >
                                                {mktCorporate.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Porte")}>
                                        {getFieldDecorator('eventLiveMarketingEventsSize', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingEvents?.size ? this.props.partner.eventLiveMarketingEvents.size : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}
                                            >
                                                {mktSize.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col sm={24}>
                                <Checkbox
                                    onChange={e => this.chageMtk(e)}
                                    value="Incentivo"
                                    checked={this.props.partner.eventLiveMarketing && this.props.partner.eventLiveMarketing.indexOf("Incentivo") > -1}
                                    disabled={
                                        this.state.eventLiveMarketing.indexOf("Experiência de Marca") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Mão de Obra") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Eventos") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Shopper") > -1 ? true : false
                                    }
                                >{I18n.get("Incentivo")}</Checkbox>
                            </Col>
                        </Row>
                        {this.state.eventLiveMarketing.indexOf("Incentivo") > -1 &&
                            <Row>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Complexibilidade")}>
                                        {getFieldDecorator('eventLiveMarketingBrandIncentiveSize', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingBrandIncentive?.size ? this.props.partner.eventLiveMarketingBrandIncentive.size : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}
                                            >
                                                {mktComplex.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col sm={24}>
                                <Checkbox
                                    onChange={e => this.chageMtk(e)}
                                    value="Mão de Obra"
                                    checked={this.props.partner.eventLiveMarketing && this.props.partner.eventLiveMarketing.indexOf("Mão de Obra") > -1}
                                    disabled={
                                        this.state.eventLiveMarketing.indexOf("Incentivo") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Experiência de Marca") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Eventos") > -1 ? true : false
                                    }
                                >{I18n.get("Mão de Obra")}</Checkbox>
                            </Col>
                        </Row>
                        {this.state.eventLiveMarketing.indexOf("Mão de Obra") > -1 &&
                            <Row>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Expertise")}>
                                        {getFieldDecorator('eventLiveMarketingLaborType', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingLabor?.type ? this.props.partner.eventLiveMarketingLabor.type : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}
                                            >
                                                {mktLaborType.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Contrato")}>
                                        {getFieldDecorator('eventLiveMarketingLaborContract', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingLabor?.contract ? this.props.partner.eventLiveMarketingLabor.contract : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}
                                            >
                                                {mktContractType.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Porte")}>
                                        {getFieldDecorator('eventLiveMarketingLaborSize', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingLabor?.size ? this.props.partner.eventLiveMarketingLabor.size : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}
                                            >
                                                {mktSize.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col sm={24}>
                                <Checkbox
                                    onChange={e => this.chageMtk(e)}
                                    value="Shopper"
                                    checked={this.props.partner.eventLiveMarketing && this.props.partner.eventLiveMarketing.indexOf("Shopper") > -1}
                                    disabled={
                                        this.state.eventLiveMarketing.indexOf("Incentivo") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Experiência de Marca") > -1 ||
                                            this.state.eventLiveMarketing.indexOf("Eventos") > -1 ? true : false
                                    }
                                >{I18n.get("Shopper")}</Checkbox>
                            </Col>
                        </Row>
                        {this.state.eventLiveMarketing.indexOf("Shopper") > -1 &&
                            <Row>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Expertise")}>
                                        {getFieldDecorator('eventLiveMarketingShopperType', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingShopper?.type ? this.props.partner.eventLiveMarketingShopper.type : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                onBlur={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}
                                            >
                                                {mktLaborType.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                                <Col sm={6}>
                                    <Form.Item label={I18n.get("Porte")}>
                                        {getFieldDecorator('eventLiveMarketingShopperSize', {
                                            rules: [{ required: true, message: 'Campo obrigatório!' }],
                                            initialValue: this.props.partner.eventLiveMarketingShopper?.size ? this.props.partner.eventLiveMarketingShopper.size : [],
                                        })(
                                            <Select
                                                mode="multiple"
                                                onSelect={(e) => { this.onChangeCheckEvent(e) }}
                                                onDeselect={(e) => { this.onChangeCheckEvent(e) }}
                                                showSearch={true}
                                            >
                                                {mktSize.map(item => {
                                                    return <Option key={item.code}>{item.label}</Option>
                                                })}
                                            </Select>


                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                    </Fragment>
                }

                {/* <Row className="RowSection">
                    <Col sm={8}>
                        <Button type="primary" shape="round" htmlType="submit">
                            Salvar
                        </Button>
                    </Col>
                </Row> */}
            </Form>
        );
    }
}

const AboutBoutiqueForm = Form.create({})(FormAbout);

// export default AboutBoutiqueForm;

export default connect(mapStateToProps, mapDispatchToProps)(AboutBoutiqueForm);
