import React from "react";

import { I18n } from "aws-amplify";

import moment from "moment";

import { Task } from "../../utils/task";
import { DatePicker } from "../../v2/components/UI/DatePicker";

import MaskedField from "../form/masked-field";

import { scrollToTop } from "../../utils/ui";

class QuotationForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        quotationDate: {
          label: I18n.get("Data limite"),
          onChange: this.onChangeForm.bind(this, "quotationDate"),
          mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
          successIcon: false,
          placeholder: I18n.get("DD/MM/AAAA"),
        },
        promotionalDate: {
          label: I18n.get("Data limite"),
          mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
          onChange: this.onChangeForm.bind(this, "promotionalDate"),
          // onBlur: this.onSave.bind(this),
          successIcon: false,
          placeholder: I18n.get("DD/MM/AAAA"),
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    console.log("onChangeForm", prop, e);
    // const value = e;
    const value = e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};
    const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

    console.log("this.state.form.quotationDate", this.state.form.quotationDate);

    return (
      <div>
        <h1 className="title-bpool medium">
          {I18n.get("Prazo para recebimento a proposta.")}
        </h1>

        <div className="bp-event-section">
          {/* <MaskedField
            {...this.state.fields.quotationDate}
            value={this.state.form.quotationDate}
            error={this.state.errors.quotationDate}
            style={{ maxWidth: "200px" }}
          /> */}

          <DatePicker
            value={moment(this.state.form.quotationDate, "YYYY-MM-DD")}
            defaultValue={
              this.state.form?.quotationDate
                ? moment(this.state.form?.quotationDate, "YYYY-MM-DD")
                : null
            }
            format={dateFormatList}
            // onChange={onChangeDate}
            onChange={(value) =>
              this.state.fields.quotationDate.onChange(value)
            }
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }}
            placeholder="__/__/____"
            label={this.state.fields.quotationDate.label}
            required
            error={this.state.errors?.quotationDate?.errorMessage}
          />
        </div>

        {customEvent.isPromotional && (
          <>
            <br />

            <h1 className="title-bpool medium">
              {I18n.get("Prazo para entrega do material.")}
            </h1>

            <div className="bp-event-section">
              {/* <MaskedField
                {...this.state.fields.promotionalDate}
                value={this.state.form.promotionalDate}
                error={this.state.errors.promotionalDate}
                style={{ maxWidth: "200px" }}
              /> */}

              <DatePicker
                value={moment(this.state.form.promotionalDate, "YYYY-MM-DD")}
                defaultValue={
                  this.state.form?.promotionalDate
                    ? moment(this.state.form?.promotionalDate, "YYYY-MM-DD")
                    : null
                }
                format={dateFormatList}
                // onChange={onChangeDate}
                onChange={(value) =>
                  this.state.fields.promotionalDate.onChange(value)
                }
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
                placeholder="__/__/____"
                label={this.state.fields.promotionalDate.label}
                required
                error={this.state.errors?.promotionalDate?.errorMessage}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default QuotationForm;
