import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { AUTH_API } from "../../../../../actions/auth/actions";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import { Menu } from "../../components/Menu";
import Main from "../../../../components/UI/Main";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { SelectOne } from "../../../../components/UI/SelectOne";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";

import * as S from "./styles";

const languages = [
  { code: "pt", label: "Portuguese" },
  { code: "en", label: "English" },
  { code: "es", label: "Spanish" },
];

export const EditLanguage = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isSending, setIsSending] = useState(false);
  const [visible, setVisible] = useState(false);
  const [language, setLanguage] = useState("");
  const [isSendingTime, setIsSendingTime] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [timezoneSelected, setTimezoneSelect] = useState("");
  const [loaderInit, setLoaderInit] = useState(false);

  const { getTimezones, putTimezones } = useRegistrationFlowFull();

  const handleOkInfos = (status) => {
    if (status) {
      setVisible(true);
    }
  };

  const dispatchLocale = async () => {
    dispatch(AUTH_API.set_locale({ locale: language }));
  };

  const loadTimezone = async () => {
    const response = await getTimezones();

    const selected = response.data.find((i) => i.selected == true);

    const timeFormat = response.data.map((item) => {
      return {
        code: item?.id,
        label: item?.id,
        selected: item.selected,
      };
    });

    setTimezones(timeFormat);
    setTimezoneSelect(selected ? selected.id : null);
  };

  const onChangeTimesonze = (time) => {
    setTimezoneSelect(time);
  };

  const dispatchLogout = async () => {
    dispatch(AUTH_API.logout());
  };

  const send = async () => {
    setIsSending(true);
    await dispatchLocale();
    setIsSending(false);

    handleOkInfos(true);
  };

  const sendTimezone = async () => {
    setIsSendingTime(true);
    const data = {
      id: timezoneSelected,
    };
    const response = await putTimezones(data);

    if (response.success) {
      setIsSendingTime(false);
      handleOkInfos(true);
    }
  };

  const onChangeLanguage = (lang) => {
    setLanguage(lang);
  };

  const changeLangInit = async (lang) => {
    setLanguage(lang);
  };

  const loadInit = async (locale) => {
    setLoaderInit(true);
    let lang = "";
    if (locale === "pt-BR") {
      lang = "pt";
    } else {
      lang = locale;
    }

    changeLangInit(lang);
    setLoaderInit(false);
  };

  const modalClickOk = async () => {
    dispatchLogout();
  };

  useEffect(() => {
    loadTimezone();
  }, []);

  useEffect(() => {
    if (!auth?.init) {
      loadInit(auth?.locale);
    }
  }, [auth]);

  return (
    <>
      <LoggedPage>
        <div className="hero-body">
          <div className="container">
            <Row style={{ marginTop: 58 }}>
              <Col xs={6}>
                <Menu />
              </Col>
              <Col xs={18}>
                <Main bgColor="#fff" padding="30px">
                  <Row>
                    <Col xs={24}>
                      <S.RowCenter>
                        <S.TitleWithIcon>
                          <S.H4>{I18n.get("Language")}</S.H4>
                        </S.TitleWithIcon>
                        <S.Paragraph>
                          {I18n.get("Change your profile language.")}
                        </S.Paragraph>
                      </S.RowCenter>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 40 }}>
                    <Col xs={24}>
                      <S.SelectBox>
                        {loaderInit ? (
                          <div>{I18n.get("Loading informations")}</div>
                        ) : (
                          <SelectOne
                            options={languages}
                            value={language}
                            onChange={(value) => onChangeLanguage(value)}
                            placeholder={I18n.get("Idioma")}
                            // error=""
                          />
                        )}
                      </S.SelectBox>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20, marginBottom: 100 }}>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 12, offset: 12 }}
                    >
                      <ButtonBpool
                        text={I18n.get("Save")}
                        theme="primary"
                        full
                        loading={isSending}
                        onClick={send}
                      />
                    </Col>
                  </Row>
                </Main>
              </Col>
            </Row>
          </div>
        </div>
      </LoggedPage>

      <ModalBp
        visible={visible}
        bodyText={I18n.get("Saved information.")}
        subBodyText={I18n.get(
          "Attention! For a better language switching experience, you will need to login again."
        )}
        okText={I18n.get("OK, logout now")}
        // cancelText={I18n.get("Cancelar")}
        handleClickOk={modalClickOk}
        onCancel={() => setVisible(false)}
        // typeModal="isConfirm"
        // isDanger={true}
      />
    </>
  );
};
