import axios from 'axios';
import { Auth } from 'aws-amplify';

import { EXPIRED } from '../auth/actions';
import Config from '../../config';
import { PARTNER_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_upload = (data) => {

    return Auth.currentSession().then((session) => {

        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: 'Bearer ' + token } };

        delete data.token;

        const id = data.partnerId || data.id;

        return axios.post(`${Config.API_BASE_URL}/partner/${id}/upload`, data, config)


    });

}

export default api_upload;