import React, { Component, Fragment } from "react";

import LayoutWikis from "./components/LayoutWikis";
import ContainerKnowledge from "./components/ContainerKnowledge";

import { Col, Row } from 'antd';

import TextItem from "./components/FaqItem";
import VideoItem from "./components/PreviewVideo";
import FileItem from "./components/FileItem";

import queryString from 'query-string'
import { I18n } from "@aws-amplify/core";


class ListSearch extends Component {

    state = {
        collapsed: false,
        menuActive: null,
        termActive: ""
    };

    renderTypeItem = (type, content) => {
        let target = this.props.auth.is_client ? "cliente":"parceiro";

        if(type == 2) {
            return (
                <Col sm={6}>
                    <VideoItem 
                        {...this.props}
                        target={target} 
                        item={content} 
                        scope={content.scope}
                    />
                </Col>        
            )
        }

        if(type == 1) {
            return (
                <Col sm={24}>
                    <TextItem 
                        {...this.props}
                        target={target} 
                        item={content} 
                        scope={content.scope}
                    />
                </Col>        
            )
        }

        if(type == 3) {
            return (
                <Col sm={6}>
                    <FileItem 
                        {...this.props}
                        target={target} 
                        item={content} 
                        scope={content.scope}
                    />
                </Col>        
            )
        }
    }

    componentDidMount = () => {
        this.props.get_knowledge(this.props.target);
    }


    render() {

        let scopeSections = this.props?.wikis?.knowledgeBase || [];
        let contentType1 = [];
        let contentType2 = [];
        let contentType3 = [];


        let params = queryString.parse(this.props.location.search)

        let searchTerm = Object.values(params)
        
        scopeSections.map(scope => {
            if(scope.sections && scope.sections.length > 0){
                scope.sections.map(section => {
                    if(section.categories && section.categories.length > 0){
                        section.categories.map(category => {
                            if(category.contents && category.contents.length > 0){
                                category.contents.map(content => {

                                        let newTags = content.tag.map(item => {
                                            return item.toUpperCase();
                                        })
                                    
                                        let checkTerm = newTags.includes(searchTerm[0].toUpperCase());

                                        
                                        if(checkTerm) {
                                            switch (scope.type) {
                                                case 1:
                                                    contentType1.push({
                                                        ...content,
                                                        target: scope.target,
                                                        scope: scope.name
                                                        
                                                    })
                                                    break;
                                                case 2:
                                                    contentType2.push({
                                                        ...content,
                                                        target: scope.target,
                                                        scope: scope.name
                                                    })
                                                    break;
                                                case 3:
                                                    contentType3.push({
                                                        ...content,
                                                        target: scope.target,
                                                        scope: scope.name
                                                    })
                                                    break;
                                            
                                                default:
                                                    break;
                                            }

                                        }
                                        
                                    });
                                }
                        });
                    }
                });
            }
        });

        let cont = contentType1.length + contentType2.length + contentType3.length;


        return (
            <LayoutWikis
                {...this.props}
                scope={this.props?.wikis?.knowledgeBase || []}
                noMenu={true}
            >
                <div className="container">
                    <Row type="flex" gutter={36}>
                       <Col sm={24}>
                           <h2>{I18n.get("Resultado da sua busca")}: </h2>
                       </Col>
                    </Row>

                    {cont == 0 && 
                    <Row type="flex" gutter={36}>
                       <Col sm={24}>
                           <br />
                           <p>{I18n.get("Não encontramos nenhum resultado para sua busca.")} </p>
                       </Col>
                    </Row>
                    }
                    <br />
                    <br />

                    {contentType2.length > 0 &&
                        <Fragment>
                            <Row type="flex" gutter={36}>
                                {contentType2.map(item => {
                                    return this.renderTypeItem(2,item)
                                })}
                            </Row>
                            <div className="lineSearch" />
                        </Fragment>
                    }

                    {contentType3.length > 0 &&
                        <Fragment>
                            <Row type="flex" gutter={36}>
                                {contentType3.map(item => {
                                    return this.renderTypeItem(3,item)
                                })}
                            </Row>
                            <div className="lineSearch" />
                        </Fragment>
                    }

                    {contentType1.length > 0 &&
                        <Row type="flex" gutter={36}>
                            {contentType1.map(item => {
                                return this.renderTypeItem(1,item)
                            })}
                        </Row>
                    }
                </div>
           </LayoutWikis>
        );
    }
}

export default ContainerKnowledge(ListSearch);