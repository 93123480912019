import styled from "styled-components";
import { Skeleton as SkeletonAntd, Collapse as CollapseAntd } from "antd";

export const Wrapper = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    margin: 32px 0;
  }
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 16px) calc(50% - 16px);
  column-gap: 32px;
`;

export const Info = styled.div`
  label {
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 600;
  }
  p {
    height: 40px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`;

export const Label = styled.div`
  line-height: 20px;
  margin-bottom: 4px;
  font-weight: 600;
`;

export const Collapse = styled(CollapseAntd)`
  border: none;
  margin-bottom: 32px;
  .ant-collapse-header {
    background: #fff;
    border-radius: 0 !important;
    padding-left: 20px !important;
  }
  .ant-collapse-arrow {
    left: 0 !important;
  }
  .ant-collapse-item.ant-collapse-item-active {
    border: none;
  }
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-infos {
    height: 20px;
    margin-bottom: 28px;

    li {
      height: 100%;
    }
  }
`;
