import styled from "styled-components";

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const H5 = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 15px;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }

  &.desTop {
    padding: 0 60px;
  }
`;

export const RowMain = styled.div`
  margin-top: 60px;
`;

export const isMensalFee = styled.div`
  text-align: center;
  margin: 30px 0;
  font-weight: bold;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const WrapperCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;

  div {
    :first-of-type {
      margin-left: 0;
    }
  }
`;

export const BoxRequired = styled.div`
  border-bottom: solid 1px #000;
  padding-bottom: 20px;
  margin-top: 40px;
  p {
    margin-bottom: 15px;
  }
`;

export const BoxNotRequired = styled.div`
  border-bottom: solid 1px #000;
  padding-bottom: 20px;
  margin-top: 40px;
  p {
    margin-bottom: 15px;
  }

  button {
    &.ant-btn {
      padding-left: 8px;
      padding-right: 8px;
      display: inline-block;
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 10px;

      img {
        margin-left: 5px;
        width: 22px;
      }
    }
  }
`;

export const ListBriefings = styled.div`
  margin-top: 20px;
  height: 830px;
  overflow: auto;
  padding-right: 10px;
`;

export const RowFooter = styled.div`
  padding-top: 50px;
  margin-top: 100px;
  border-top: solid 1px #000;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsFooter = styled.div`
  .btnPrev {
    margin-right: 20px;
  }
`;

export const CloseScope = styled.div`
  background: #2f2f2f;
  padding: 20px 30px;
  border-radius: 10px;
  margin: 0 0 60px;
  p {
    color: #fff;
  }
`;

export const BtnDownload = styled.a`
  margin-left: 40px;
  font-style: normal;

  &.disabled {
    color: #000;
    opacity: 0.4;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .labelText {
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    text-decoration-line: underline;
    margin-left: 15px;
    color: #1883ff;
    position: relative;
    top: 2px;
  }
`;

export const Item = styled.div`
  margin-bottom: 10px;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 150px;

  a,
  button {
    margin-left: 10px;
  }
`;

export const DisplayErrors = styled.div`
  h2 {
    font-weigth: bold;
  }

  div {
    margin-top: 30px;
  }
`;

export const SubTitle = styled.div`
  margin: 15px 0 30px 0;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  span {
    width: 70px;
    height: 1px;
    background-color: #000000;
    display: block;
  }
`;

export const TotalPo = styled.div`
  margin-bottom: 15px;

  p {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Text = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ButtonAddPo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ContentCheckboxCompany = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 15px 0;

  .text {
    margin-left: 7.5px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }
`;

export const Balance = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 22px;
  font-size: 16px;
  font-weight: 700;
`;

export const BillingsEmpty = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;

  p {
    line-height: 22px;
    font-size: 16px;
  }
`;

export const RowButtonsActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 50px;
  margin-top: 100px;
  border-top: solid 1px #000;
`;

export const ErrorSendImageRequired = styled.div`
  color: #ff0000;
  margin: 5px 0 0 5px;
  font-size: 12px;
`;

export const PaymentosInstructions = styled.p`
  margin: 15px 0;
`;

export const ButtonsModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;
