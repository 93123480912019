import React from 'react';

import { I18n } from 'aws-amplify';

import WYSIWYGEditor from './form/wysiwyg-editor';

export default class BriefingItem extends React.Component {
  constructor(props) {

    super();
  }


  render() {

    return (<div className="card" style={{ padding: '0.5rem' }}>
      <header className="card-header">
        <p className="card-header-title" style={{ borderBottom: 'solid 1px #cccccc' }}>
          <b>{this.props.label}</b>
        </p>
      </header>
      <div className="card-content" style={{ padding: '0' }}>
        <div className="content">
          <WYSIWYGEditor content={this.props.content} onChange={this.props.onChange} readOnly={true} />
        </div>
      </div>
      {this.props.file && <div style={{ padding: '1rem' }}>
        <a href={this.props.file.url} target="_blank">{this.props.file.name}</a>
      </div>}
    </div>)
  }
}