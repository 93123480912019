import styled from 'styled-components';

export const Wrapper = styled.div``;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  &.textLeft {
    text-align: left;
  }
`;

export const InputLabel = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 8px;
  display: flex;

  span {
    color: #ff0000;
    font-size: 22px;
  }
`;

export const TitleSectionForm = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 20px;
  display: flex;
  border-bottom: 1px solid;
  border-color: #000000;
  width: max-content;
`;
