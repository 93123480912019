import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useBpayAdmin } from "../../../../../../../hooks/useBpay/useBpayAdmin.hook";
import { Drawer } from "../../../../../../../components/UI/Drawer";
import { ButtonBpool } from "../../../../../../../components/UI/ButtonBpool";
import { Dialog } from "../../../../../../../components/UI/Dialog";
import { NotificationBPool } from "../../../../../../../components/UI/NotificationBPool";
import { InfosDrawer } from "./InfosDrawer";
import * as S from "../styles";

export const DrawerSendMail = ({ drawer, handleCloseDrawer }) => {
  const { getBpayDataInternalToDrawer, sendWarningUploadInvoiceInternal } =
    useBpayAdmin();
  const [isSendingMails, setIsSendingMails] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({});

  const handleOpenDrawer = async ({ bpayId, bPaymentId }) => {
    try {
      setIsLoadingDrawer(true);
      const response = await getBpayDataInternalToDrawer({
        bpayId,
        bPaymentId,
      });
      setDataDrawer(response?.data);
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao carregar as informações"),
        duration: 3,
      });
    } finally {
      setIsLoadingDrawer(false);
    }
  };

  const sendEmails = async () => {
    try {
      setIsSendingMails(true);
      const response = await sendWarningUploadInvoiceInternal({
        bPayId: drawer?.bpayId,
        bPaymentId: drawer?.bPaymentId,
      });
      setDialog({
        visible: true,
        text: I18n.get("E-mail enviado com sucesso"),
      });
      handleCloseDrawer();
    } catch (error) {
      const errors = error?.response?.data?.errors;
      setDialog({
        visible: true,
        error: true,
        text: I18n.get("Erro ao enviar e-mail"),
        subText: errors
          ? errors[0]?.errorMessage || ""
          : I18n.get("Erro ao tentar enviar, entre em contato conosco."),
      });
    } finally {
      setIsSendingMails(false);
    }
  };

  useEffect(() => {
    if (drawer?.open === true) {
      handleOpenDrawer({
        bpayId: drawer?.bpayId,
        bPaymentId: drawer?.bPaymentId,
      });
    }
  }, [drawer?.open]);

  return (
    <>
      <Drawer
        open={drawer.open}
        handleClose={handleCloseDrawer}
        buttons={
          <>
            <ButtonBpool
              text={I18n.get("Não, voltar")}
              onClick={handleCloseDrawer}
              theme="tertiary"
            />
            <div style={{ marginLeft: 16 }}>
              <ButtonBpool
                text={I18n.get("Sim, enviar")}
                onClick={sendEmails}
                theme="primary"
                loading={isSendingMails}
              />
            </div>
          </>
        }
      >
        <InfosDrawer
          data={{ ...dataDrawer, createdOn: drawer?.createdOn }}
          loading={isLoadingDrawer}
        />
      </Drawer>

      <Dialog
        visible={dialog.visible}
        text={dialog.text}
        subText={dialog.subText}
        loading={false}
        theme="white"
        error={dialog?.error}
        buttons={{
          ok: {
            text: I18n.get("Fechar"),
            func: () => setDialog({ visible: false }),
          },
          cancel: {},
        }}
      />
    </>
  );
};
