import React from "react";
import { I18n } from "aws-amplify";
import { Link, Redirect } from "react-router-dom";

import ProjectContainer from "../../containers/project-container";

import { Task } from "../../utils/task";

import { ClientProjectStatus } from "../../model";

import FillForm from "../page-templates/fillForm-logged";
import Loading from "../pages/loading";

import SubmitButtonField from "../form/submit-button-field";

import QuestionsForm from "./questions-form";

import Steps from "./steps";

import { scrollToTop } from "../../utils/ui";

class SelectPartnerStep2 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      task: {},
    };
  }

  componentDidMount() {
    const data = {
      projectId: this.props.match.params.projectId,
    };

    const dataLoading = {
      clientId: this.props.client.clientId,
      projectId: this.props.match.params.projectId,
    };

    this.props.get_project(dataLoading);

    this.props.get_partners(data);

    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.project && this.props.project.task;

    if (!prevState) return;
    if (!task) return;

    var nextState = Object.assign({}, prevState);

    if (task.id != prevState.task.id) {
      nextState.task = task;

      this.setState(nextState);
    }
  }

  onSubmitForm(e) {
    e.preventDefault();

    const form = this.refs.form;

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      form.submitForm(e);
    });
  }

  render() {
    return (
      <FillForm>
        <div className="hero-body has-background-white">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-7">
                <p className="title">
                  {I18n.get("Seleção de Parceiros")}
                  <br />
                  <span className="tag is-warning tag-project-name">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-5">
                <Steps
                  active={3}
                  projectId={this.props.match.params.projectId}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="client-step client-step-1 partnersApprovalReasons">
          <div className="container client-container-1">
            <div className="column is-11 is-offset-1">
              <QuestionsForm {...this.props} />
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(SelectPartnerStep2);
