import React from 'react';
import { I18n } from 'aws-amplify';
import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';

import 'draft-js/dist/Draft.css';
import './wysiwyg-editor.css';

const Button = (props) => {
    return (<a className="button" onClick={(e) => props.handleKeyCommand(props.commandName)} style={{ backgroundColor: '#ececec', color: '#000000' }}>
        {props.children}
    </a>);
  }


export default class WYSIWYGEditor extends React.Component {
  constructor(props) {
    
    super(props);

    this.state = {
    };

    if (props.content) {
        this.state.editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(props.content)));
    } 
    else {
        this.state.editorState = EditorState.createEmpty();
    }

    this.onChange = (editorState) => { 
        
            this.setState({editorState}); 

            const contentState = editorState.getCurrentContent();

            const content =  JSON.stringify(convertToRaw(contentState));

            const text = contentState.getPlainText();

            this.props.onChange(content, text);
        }
    }

  handleKeyCommand = (name, e) => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, name));
  }

  render() {

    return (<div>

        {!this.props.readOnly && <div className="field has-addons" style={{ backgroundColor: '#ececec' }}>
            <p className="control">
    
                <Button commandName="BOLD" handleKeyCommand={this.handleKeyCommand.bind(this)}>
                    <span className="icon bold-button">
                        <i className="fas fa-bold"></i>
                    </span>
                </Button>

                <Button commandName="ITALIC" handleKeyCommand={this.handleKeyCommand.bind(this)}>
                    <span className="icon italic-button">
                        <i className="fas fa-italic"></i>
                    </span>
                </Button>

                <Button commandName="UNDERLINE" handleKeyCommand={this.handleKeyCommand.bind(this)}>
                    <span className="icon underline-button">
                        <i className="fas fa-underline"></i>
                    </span>
                </Button>   
            </p>
        </div>}        

        <Editor 
            editorState={this.state.editorState} 
            onChange={this.onChange} 
            readOnly={this.props.readOnly}
        />

    </div>);
  }
}