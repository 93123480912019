import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid;
  border-radius: 10px;
  width: calc(100% - 300px);
  padding: 25px 0;
  font-weight: 700;
  font-size: 20px;

  background-color: ${(props) =>
    props.active ? "#1883ff" : props.error ? "#ffffff" : "#ffffff"};
  border-color: ${(props) =>
    props.active ? "#ffffff" : props.error ? "#ff0000" : "#1883ff"};
  color: ${(props) =>
    props.active ? "#ffffff" : props.error ? "#ff0000" : "#1883ff"};

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const Expanded = styled.div`
  position: relative;
  width: 100%;

  ul {
    width: 100%;
    margin-top: 30px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    li {
      display: inline;
    }
  }
`;

export const ButtonSub = styled.button`
  cursor: pointer;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid;
  border-color: #2f2f2f;
  border-radius: 10px;
  padding: 8px 20px;

  background-color: ${(props) => (props.active ? "#1883ff" : "#ffffff")};
  border-color: ${(props) => (props.active ? "#1883ff" : "#2f2f2f")};

  span {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: ${(props) => (props.active ? "#ffffff" : "#2f2f2f")};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const LabelButton = styled.div`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
`;

export const SubLabelButton = styled.div`
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 17px;
`;
