import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import TextField from "../form/text-field";

import CheckboxMultiField from "../form/checkbox-multi-field";

import constants from "../../constants";

import { scrollToTop } from "../../utils/ui";

import { Input } from "../../v2/components/UI/Input";

class StaffForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        attractionType: {
          label: I18n.get(
            "Você vai precisar de entretenimento ou alguma atração?"
          ),
          value: [],
          error: props.errors.attractionType,
          onChange: this.onChangeFormCheckboxMulti.bind(this, "attractionType"),
          // onBlur: this.onSave.bind(this, 'attractionType'),
        },
        attractionCelebrity: {
          label: "",
          onChange: this.onChangeForm.bind(this, "attractionCelebrity"),
          // onBlur: this.onSave.bind(this, 'attractionCelebrity'),
          successIcon: false,
        },
        attractionInfluencer: {
          label: "",
          onChange: this.onChangeForm.bind(this, "attractionInfluencer"),
          // onBlur: this.onSave.bind(this, 'attractionInfluencer'),
          successIcon: false,
        },
        attractionSpeaker: {
          label: "",
          onChange: this.onChangeForm.bind(this, "attractionSpeaker"),
          // onBlur: this.onSave.bind(this, 'attractionSpeaker'),
          successIcon: false,
        },
        attractionDesc: {
          label: "",
          onChange: this.onChangeForm.bind(this, "attractionDesc"),
          // onBlur: this.onSave.bind(this, 'attractionDesc'),
          successIcon: false,
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const attractionCelebrityIsEnabled =
      Array.isArray(this.state.form.attractionType) &&
      this.state.form.attractionType.indexOf("CELEBRITY") > -1;
    const attractionInfluencerIsEnabled =
      Array.isArray(this.state.form.attractionType) &&
      this.state.form.attractionType.indexOf("INFLUENCER") > -1;
    const attractionSpeakerIsEnabled =
      Array.isArray(this.state.form.attractionType) &&
      this.state.form.attractionType.indexOf("SPEAKER") > -1;
    const attractionDescIsEnabled =
      Array.isArray(this.state.form.attractionType) &&
      this.state.form.attractionType.indexOf("OTHER") > -1;

    const attractionTypeValues = constants.EVENT_ATTRACTION_TYPES.map(
      (item) => {
        if (item.code == "CELEBRITY") {
          item.field = (
            // <TextField
            //   className="field field-cond"
            //   {...this.state.fields.attractionCelebrity}
            //   value={this.state.form.attractionCelebrity}
            //   error={this.state.errors.attractionCelebrity}
            //   disabled={!attractionCelebrityIsEnabled}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.attractionCelebrity.onChange(value)
              }
              placeholder={this.state.fields.attractionCelebrity.label}
              value={this.state.form.attractionCelebrity}
              disabled={!attractionCelebrityIsEnabled}
              error={this.state.errors.attractionCelebrity?.errorMessage}
            />
          );
        }

        if (item.code == "INFLUENCER") {
          item.field = (
            // <TextField
            //   className="field field-cond"
            //   {...this.state.fields.attractionInfluencer}
            //   value={this.state.form.attractionInfluencer}
            //   error={this.state.errors.attractionInfluencer}
            //   disabled={!attractionInfluencerIsEnabled}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.attractionInfluencer.onChange(value)
              }
              placeholder={this.state.fields.attractionInfluencer.label}
              value={this.state.form.attractionInfluencer}
              disabled={!attractionInfluencerIsEnabled}
              error={this.state.errors.attractionInfluencer?.errorMessage}
            />
          );
        }

        if (item.code == "SPEAKER") {
          item.field = (
            // <TextField
            //   className="field field-cond"
            //   {...this.state.fields.attractionSpeaker}
            //   value={this.state.form.attractionSpeaker}
            //   error={this.state.errors.attractionSpeaker}
            //   disabled={!attractionSpeakerIsEnabled}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.attractionSpeaker.onChange(value)
              }
              placeholder={this.state.fields.attractionSpeaker.label}
              value={this.state.form.attractionSpeaker}
              disabled={!attractionSpeakerIsEnabled}
              error={this.state.errors.attractionSpeaker?.errorMessage}
            />
          );
        }

        if (item.code == "OTHER") {
          item.field = (
            // <TextField
            //   className="field field-cond"
            //   {...this.state.fields.attractionDesc}
            //   value={this.state.form.attractionDesc}
            //   error={this.state.errors.attractionDesc}
            //   disabled={!attractionDescIsEnabled}
            // />

            <Input
              onChange={(value) =>
                this.state?.fields?.attractionDesc.onChange(value)
              }
              placeholder={this.state.fields.attractionDesc.label}
              value={this.state.form.attractionDesc}
              disabled={!attractionDescIsEnabled}
              error={this.state.errors.attractionDesc?.errorMessage}
            />
          );
        }

        return item;
      }
    );

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Atrações")}</h1>

        <div className="bp-event-section bpForce mini">
          <CheckboxMultiField
            {...this.state.fields.attractionType}
            value={this.state.form.attractionType}
            error={this.state.errors.attractionType}
            values={attractionTypeValues}
          />

          <br style={{ clear: "both" }} />
          <br />
        </div>
      </div>
    );
  }
}

export default StaffForm;
