import React from 'react';

import { I18n } from 'aws-amplify';

import TooltipField from './tooltip-field';


export default class NumericField extends React.Component {

    constructor(props) {
        super();

        this.state = {
            focused: false,
            visited: props.visited
        };
    }

    static defaultProps = {
    }

    onBlur() {
        this.setState({ focused: false });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onFocus() {
        this.setState({ focused: true, visited: true });
    }

    onChange(e){

        const value = e.target.value;

        if (value && /^\d+$/.test(value) == false) return;

        this.props.onChange(e);
    }

    render() {

        const className = this.props.className || 'field';
        const inputClassName = this.props.inputClassName || 'input has-text-right';

        const isError = !this.state.focused && this.state.visited && this.props.error;

        return (<div className={className} style={this.props.style}>
            <label className="label is-small">
                {this.props.label} <TooltipField {...this.props} />
            </label>
            <div className="control">
                <input
                    onChange={(e) => this.onChange(e)}
                    onBlur={() => this.onBlur()}
                    onFocus={() => this.onFocus()}
                    value={this.props.value || ''}
                    className={isError ? inputClassName + " is-danger" : inputClassName}
                    type="text"
                    placeholder={this.props.placeholder || ''}
                    readOnly={this.props.readOnly || false}
                    disabled={this.props.disabled || false}
                />
            </div>
            {isError && <p className="help is-danger">
                {this.props.error.errorMessage}
            </p>}
        </div>)
    }
}

