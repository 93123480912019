import React, { Fragment } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Button, Icon, Table, Card } from "antd";
import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";
import SubmitButtonField from "../form/submit-button-field";

import FormatCurrency from "../ui/format-currency";

class AssetResumeForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {
        isSubmit: false,
      },
      specialismMap: {},
      assetTypeMap: {},
      page: 1,
      task: {},
      statusDelete: false,
      closeProject: false,
    };

    //this.onDrop = this.onDrop.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value =
            this.props[item] || nextState.form[item].defaultValue;
        } else if (typeof nextState.form !== "undefined") {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);

      if (task.result && this.state.closeProject) {
        this.props.history.push(
          `/cliente/projetos/${this.props.match.params.projectId}/passo-3`
        );

        this.setState({
          closeProject: false,
        });
      }
    }
  }

  getData() {
    var data = {
      clientId: this.props.clientId,
      projectId: this.props.match.params.projectId,
      assets: this.props.cartAssets,
    };

    return data;
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task, closeProject: true }, function () {
      this.props.checkout(this.getData());
    });
  }

  handleBundleBtnClick = () => {
    this.props.onBundleBtnClick();
  };

  handleDelete = (item, e) => {
    this.setState({
      statusDelete: true,
    });

    setTimeout(() => {
      this.props.delItem(item, e);
    }, 100);
  };

  render() {
    let currency = this.props.client.customization.currency;

    let assetTax = this.props.client.customization.assetTax || 0;
    let otherTax = this.props.client.customization.otherTax || 0;

    const applyDiscount = false; //this.props.applyDiscount;

    var specialismsBag = [];
    var assetTypesBag = [];

    this.props.project.bundles &&
      this.props.project.bundles.map((item) => {
        specialismsBag.push(...item.specialism);
        assetTypesBag.push(...item.assetType);
      });

    var totalItem = 0;
    var totalPrice = 0;

    var cart = [];
    const _cart = this.props.cartAssets;

    _cart.forEach((item) => {
      var index = cart.findIndex((_item) => {
        return _item.asset.id == item.id;
      });

      if (index > -1) {
        cart[index].total++;
      } else {
        cart.push({
          asset: item,
          total: 1,
        });
      }
      totalPrice += item.price;
      totalItem++;
    });

    const columns = [
      {
        title: I18n.get("Asset"),
        dataIndex: "",
        width: "50%",
        render: (record) => {
          if (record.key == 999) return;

          let name = record.item.asset.label;

          if (record.item.asset.complexityId) {
            name = `${name} (${record.item.asset.complexityId} ${record.item.asset.complexity})`;
          }

          return (
            <span
              className=""
              data-code={record.item.asset.code}
              data-label={record.item.asset.label}
              data-price={record.item.asset.price}
              data-multiply={record.item.total}
              data-complexity-id={record.item.asset.complexityId}
              data-complexity={record.item.asset.complexity}
            >
              {name}
            </span>
          );
        },
      },
      {
        title: I18n.get("Quantidade"),
        dataIndex: "quantidade",
        width: "120px",
      },
      {
        title: I18n.get("Valor"),
        width: "20%",
        dataIndex: "valor",
      },
      {
        title: I18n.get("Ação"),
        dataIndex: "",
        render: (record) => {
          if (record.key == 999) return;

          return (
            <Button
              type="primary"
              onClick={(e) => this.handleDelete(record.item.asset, e)}
            >
              <Icon type="delete" theme="filled" />
            </Button>
          );
        },
      },
    ];

    let totalQuantity = 0;

    let data = cart?.map((item, index) => {
      totalQuantity = totalQuantity + item.total;
      return {
        key: index,
        asset: item.asset.label,
        quantidade: item.total,
        valor: item.asset.priceFmt,
        item: item,
      };
    });

    data.push({
      key: 999,
      asset: null,
      quantidade: totalQuantity,
      valor: this.props.project.priceClientWithDiscountFmt,
      item: null,
    });

    const project = this.props.project;

    return (
      <Fragment>
        <form
          onSubmit={this.submitForm.bind(this)}
          className="form-resumo-client"
        >
          <div className="columns columns-for-np">
            <div className="column left-column-for-np is-8">
              <div className="resume-cart-heading">
                <h1>
                  {I18n.get("Resumo do Projeto:")}{" "}
                  <span> {this.props.project.projectName}</span>{" "}
                </h1>
                <a className="customize-cart">
                  <span className="customize-cart-items" data-items={totalItem}>
                    {totalItem}
                  </span>
                  <i className="fas fa-shopping-cart"></i>
                </a>
              </div>
              <hr style={{ backgroundColor: "#000000", height: "1px" }} />
              <div className="resume-cart-content">
                <Table
                  columns={columns}
                  dataSource={data}
                  scroll={{ x: 640 }}
                  pagination={false}
                  footer={() => (
                    <button
                      type="button"
                      className="button bt-bpool black"
                      onClick={this.props.onCustomBtnClick}
                    >
                      {I18n.get("Continuar contratando")}
                    </button>
                  )}
                />
              </div>
            </div>

            <div className="column right-column-for-np is-4">
              <div className="space-padding">
                <Card
                  className="TopCard"
                  type="inner"
                  title={I18n.get("Resumo")}
                >
                  <div>
                    <div className="displayValores">
                      <p>
                        <span>{I18n.get("Parcial:")} </span>
                        <span>
                          {this.props.project.priceClientWithDiscountFmt}
                        </span>
                      </p>

                      <p>
                        <span>
                          {I18n.get("Total")}: <br />
                          <span style={{ fontSize: 11, marginTop: "-10px" }}>
                            {otherTax > 0
                              ? `(${I18n.get(
                                  "incluyendo gross up"
                                )} ${otherTax}%)`
                              : ""}
                          </span>
                        </span>
                        <b>
                          {
                            this.props?.project
                              ?.priceClientWithDiscountWithOtherTaxFmt
                          }
                        </b>
                      </p>

                      <br />

                      {assetTax > 0 && (
                        <p>
                          <span>{I18n.get("Impostos")} </span>
                          (IVA {assetTax}%)
                          <span>
                            {this.props.project.priceClientWithDiscountTaxFmt}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>

                  <SubmitButtonField
                    disabled={totalItem == 0}
                    label={I18n.get("Fechar projeto")}
                    className="button bt-bpool black full"
                    onClick={this.submitForm.bind(this)}
                    task={this.state.task}
                  />
                </Card>
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default withRouter(AssetResumeForm);
