import { Tab, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";

const StyledTabList = styled(TabList)`
  border: 0;
  margin: 0 0 -15px;
  display: flex;
`;

const StyledTab = styled(Tab)`
  width: calc(
    (100% / ${(props) => props.tabItens}) - 1px * ${(props) => props.tabItens}
  );
  font-style: normal;
  font-size: 24px;
  line-height: 33px;
  color: rgba(47, 47, 47, 0.5);
  border: 0;
  text-align: center;
  padding: 15px 0 30px;
  font-weight: normal;
  background: #d8d8d8;
  border-radius: 10px 10px 0 0;
  margin: 0 1px;
  cursor: pointer;
  img {
    filter: brightness(0.4);
  }

  &.react-tabs__tab--selected {
    border-radius: 10px 10px 0 0;
    font-weight: bold;
    background: #1883ff;
    color: #ffffff;
    img {
      filter: brightness(1);
    }
  }
`;

const StyledTabPanel = styled(TabPanel)`
  position: relative;
  z-index: 10;
  background: #fff;
  border-radius: 10px;

  &.react-tabs__tab-panel--selected {
    padding: 20px;
    border: 1px solid #1883ff;
  }
`;

const Label = styled.span`
  margin-left: 15px;
  display: inline-block;
  position: relative;
  top: 2px;
  font-size: 20px;
`;

const Icon = styled.span`
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
`;

export {
  StyledTabList as TabList,
  StyledTab as Tab,
  StyledTabPanel as TabPanel,
  Label,
  Icon,
};
