import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Table, Icon } from 'antd';

import { CLIENT_API } from '../../../actions/client/actions';

import LoggedPage from '../../page-templates/fillForm-logged';

import { ProjectTypeNames, ClientProjectExtraStatus } from '../../../model';

import { getQuotationStatus } from '../../../utils/extras';


import { scrollToTop } from '../../../utils/ui';

import FormatCurrency from '../../ui/format-currency';

const Bts = styled.p`
    a {
        margin-right: 15px;
        margin-bottom: 5px;
    }
`
const Main = styled.div`
    background: #fff;
    padding-top: 130px;
    padding-bottom: 130px;

    h1 {
        font-size: 25px;
        position: relative;
        width: auto;
        font-weight: bold;
        margin: 0 0 48px;
        font-family: "Helvetica Neue LT Std";

        span{
            font-family: "Helvetica Neue LT Std";
            position: relative;
            font-weight: bold;
            &::after {
            background-color: #000;
            position: absolute;
            display: block;
            content: '';
            height: 8px;
            margin-top: 12px;
            top: 100%;
            width: 80%;
        }
    }
    }
`
const mapStateToProps = state => {
    return Object.assign({}, state.client, state);
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_extras: () => {
            dispatch(CLIENT_API.get_extras());
        },
    };
};


class ClientExtraListAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {
        this.props.get_extras();
        scrollToTop();
    }

    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientProjectExtraStatusesMap) return '';

        return this.props.bp.clientProjectExtraStatusesMap[value].label;
    }

    //Client Project List
    render() {

        const data = this.props.clientExtras && this.props.clientExtras.map((item) => {
            return {
                ...item
            }
        });

        const columns = [
            {
                title: I18n.get("Projeto"),
                dataIndex: 'projectName',
                key: 'projectName',
                width: '30%',
            },
            {
                title: I18n.get("Nome Extra"),
                dataIndex: 'extraName',
                key: 'extraName',
                width: '30%',
            },
            {
                title: I18n.get("Data Criação"),
                dataIndex: 'createdOn',
                key: 'createdOn',
            },
            {
                title: I18n.get("Budget"),
                key: 'budget',
                dataIndex: 'budget',
                render: (budget, record) => (
                    <FormatCurrency amount={budget} currency={record.currency} />
                )
            },
            {
                title: I18n.get("Orçamento"),
                dataIndex: 'quotation',
                key: 'quotation',
                render: (quotation) => getQuotationStatus(this.props.auth, quotation)
            },
            {
                title: I18n.get("Ver"),
                dataIndex: 'extraId',
                key: 'extraId',
                width: '5%',
                render: (extraId, record) => {

                    return (<NavLink to={`/projetos/${record.projectId}/extras/${record.extraId}/detalhes`}>
                        <Icon type='eye' />
                    </NavLink>);
                }
            }
        ];

        return (
            <LoggedPage>
                <Main className="uplift">

                    <div className="container">

                        <br />
                        <h1><span>{I18n.get("Extras")}</span></h1>
                        <Table scroll={{ x: 640 }} columns={columns} dataSource={data} />

                    </div>

                </Main>
            </LoggedPage>
        );


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientExtraListAdmin);
