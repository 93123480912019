import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid;
  border-color: #e9e9e9;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 0;
  background: #f2f2f2;
`;

export const ContentCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  height: 25px;
  cursor: pointer;

  label {
    margin: 0;

    span {
      .ant-checkbox-inner {
        border-radius: 2px;
        border: solid 1px;
      }
    }
  }
`;

export const LabelCheckbox = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
`;

export const Body = styled.div`
  padding: 30px 20px 10px 20px;
`;

export const Actions = styled.div`
  padding: 15px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
