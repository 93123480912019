import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { Row, Col, Radio, Icon } from "antd";
import { useBpay } from "../../../../../../../hooks/useBpay/useBpay.hook";
import { useCep } from "../../../../../../../hooks/useCep/useCep.hook";
import { ButtonBpool } from "../../../../../../../components/UI/ButtonBpool";
import { Input } from "../../../../../../../components/UI/Input";
import { InputMask } from "../../../../../../../components/UI/InputMask";
import { SelectOne } from "../../../../../../../components/UI/SelectOne";
import { Drawer } from "../../../../../../../components/UI/Drawer";
import { NotificationBPool } from "../../../../../../../components/UI/NotificationBPool";
import constants from "../../../../../../../../constants";
import { hasError, clearErrorInput } from "../../../../../utils/errorsInput";
import COUNTRY_CODES from "./countryCodes.json";
import IconPlus from "../../../../../svgs/plus.svg";
import * as S from "./styles";

export const BillingsData = ({
  billingData,
  isLoading,
  refetch,
  onChangeBillingData,
}) => {
  const state = useSelector((state) => state);
  const { postNewRegistrationId } = useBpay();
  const [modalPreviewCnpjVisible, setModalPreviewCnpjVisible] = useState(false);
  const [billingDataChecks, setBillingDataChecks] = useState(false);
  const [inputsFormNewCnpj, setInputsFormNewCnpj] = useState({});
  const [cnpjSelected, setCnpjSelected] = useState(null);
  const [openDrawer, setOpenDrawer] = useState({ open: false });
  const [isSending, setIsSending] = useState(false);
  const [errorsInputs, setErrorsInputs] = useState([]);
  const [clearing, setClearing] = useState(false);
  const { getDataFromCep } = useCep();
  const [cepData, setCepData] = useState({ cep: { zipCode: "", cep: null } });
  const [cepValueDebounced] = useDebounce(cepData?.cep?.cep, 1000);

  const getCepData = async (cep) => {
    try {
      const response = await getDataFromCep(cep.cep);
      if (response?.data?.erro) {
        setInputsFormNewCnpj({
          ...inputsFormNewCnpj,
          city: "",
          addressLine1: "",
          addressLine2: "",
          state: "",
          country: null,
          zipCode: cep.cep,
        });
      } else {
        setInputsFormNewCnpj({
          ...inputsFormNewCnpj,
          city: response?.cepInfos?.city,
          addressLine1: response?.cepInfos?.addressLine1,
          state: response?.cepInfos?.state,
          zipCode: cepData.cep.zipCode,
          country: response?.cepInfos?.country,
        });
      }
    } catch (error) {
      setInputsFormNewCnpj({
        ...inputsFormNewCnpj,
        city: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        country: null,
        zipCode: cep.cep,
      });
      setErrorsInputs([
        ...errorsInputs,
        {
          errorMessage: I18n.get("CEP deve ser informado."),
          field: "ZipCode",
        },
      ]);
    }
  };

  useEffect(() => {
    if (state?.client?.country?.code === "BRA") {
      if (cepValueDebounced) {
        getCepData(cepData.cep);
      }
    }
  }, [cepValueDebounced]);

  const handleChangeCep = (value) => {
    setCepData({
      ...cepData,
      cep: { zipCode: value?.value, cep: value?.value },
    });
  };

  const onChangeBillingDataChecks = (e) => {
    setBillingDataChecks(e.target.value);
    setCnpjSelected(null);
    if (e.target.value) {
      onChangeBillingData({
        value: e.target.value,
      });
    } else {
      onChangeBillingData({
        value: e.target.value,
        registrationId: billingData?.clientCompany?.registrationId,
        registrationName: billingData?.clientCompany?.registrationName,
      });
    }
  };

  const clearInputs = async () => {
    setInputsFormNewCnpj({});
    setErrorsInputs([]);
  };

  const handleCloseDrawer = async () => {
    setOpenDrawer({ open: false });
    setClearing(true);
    await clearInputs();
    setClearing(false);
  };

  const onChangeInputFormNewCnpj = (id, value, isCountry) => {
    if (isCountry) {
      const obj = COUNTRY_CODES.find((i) => i.code == value);
      setInputsFormNewCnpj({
        ...inputsFormNewCnpj,
        state: "",
        phoneNumber: "",
        [id]: value ? obj : null,
      });
    } else {
      setInputsFormNewCnpj({
        ...inputsFormNewCnpj,
        [id]: value ? value : null,
      });
    }
  };

  const addNewRegistrationId = async () => {
    const objSend = {
      ...inputsFormNewCnpj,
      countryCode: inputsFormNewCnpj?.country
        ? inputsFormNewCnpj?.country?.code
        : null,
    };
    if (objSend?.country) {
      delete objSend.country;
    }
    try {
      setIsSending(true);
      const response = await postNewRegistrationId(objSend);
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("Novo CNPJ cadastrado"),
        duration: 3,
      });
      handleCloseDrawer();
      refetch();
      setCnpjSelected(response);
      onChangeBillingData({
        value: true,
        registrationId: response?.registrationId,
        registrationName: response?.registrationName,
      });
    } catch (error) {
      let errorsBack = [];
      const errorsWithEmpresa = error?.response?.data?.errors?.find(
        (err) => err?.field === "Empresa"
      );
      if (errorsWithEmpresa) {
        const errorCnpj = {
          errorMessage: errorsWithEmpresa?.errorMessage,
          field: "RegistrationId",
          step: null,
        };
        const filteredArray = error?.response?.data?.errors?.filter(
          (obj) => obj.field !== "Empresa"
        );
        errorsBack = [...filteredArray, errorCnpj];
      } else {
        errorsBack = error?.response?.data?.errors;
      }
      setErrorsInputs(errorsBack);
    } finally {
      setIsSending(false);
    }
  };

  const handleSelectCnpj = (value) => {
    const finded = billingData?.clientBillingCompanies?.find(
      (item) => item?.registrationId === value
    );
    if (finded) {
      setCnpjSelected(finded);
      onChangeBillingData({
        value: billingDataChecks,
        registrationId: finded?.registrationId,
        registrationName: finded?.registrationName,
      });
    } else {
      setCnpjSelected(null);
    }
  };

  return (
    <>
      <Col md={24}>
        <S.BillingData>
          <S.SubBillingDataTitle>
            <h4>{I18n.get("Dados de faturamento diferentes do cadastro?")}</h4>
            <button onClick={() => setModalPreviewCnpjVisible(true)}>
              <Icon type="eye" />
            </button>
          </S.SubBillingDataTitle>
          <S.BillingDataCheckboxes>
            <Radio.Group
              onChange={onChangeBillingDataChecks}
              value={billingDataChecks}
            >
              <Radio value={false}>{I18n.get("Não")}</Radio>
              <Radio value={true}>{I18n.get("Sim")}</Radio>
            </Radio.Group>
          </S.BillingDataCheckboxes>

          {billingDataChecks ? (
            <>
              <S.SelectCnpj>
                <div>
                  <S.Skeleton
                    loading={isLoading}
                    active
                    title={false}
                    paragraph={{
                      rows: 1,
                      className: "skeleton-input",
                      width: "100%",
                    }}
                  >
                    <SelectOne
                      options={
                        billingData?.clientBillingCompanies ||
                        billingData?.clientBillingCompanies?.length
                          ? billingData?.clientBillingCompanies?.map(
                              (item) => ({
                                code: item?.registrationId,
                                label: item?.registrationId,
                              })
                            )
                          : []
                      }
                      value={cnpjSelected?.registrationId}
                      defaultValue={cnpjSelected?.registrationId}
                      onChange={handleSelectCnpj}
                      placeholder={I18n.get("Selecionar CNPJ")}
                    />
                  </S.Skeleton>
                </div>
                <div>
                  <ButtonBpool
                    text={I18n.get("Cadastrar novo CNPJ")}
                    theme="tertiary"
                    icon={IconPlus}
                    full
                    onClick={() => setOpenDrawer({ open: true })}
                  />
                </div>
              </S.SelectCnpj>

              {cnpjSelected ? (
                <S.CnpjSelectedContent>
                  <Row>
                    <Col md={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <strong>
                        {I18n.get("Dados de faturamento selecionado")}
                      </strong>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 16 }}>
                    <Col md={6} style={{ paddingLeft: 0 }}>
                      <strong>{I18n.get("CNPJ")}</strong>
                      <p>{cnpjSelected?.registrationId}</p>
                    </Col>
                    <Col md={10}>
                      <strong>{I18n.get("Razão social")}</strong>
                      <p>{cnpjSelected?.registrationName}</p>
                    </Col>

                    <Col md={8} style={{ paddingRight: 0 }}>
                      <strong>{I18n.get("Nome Fantasia")}</strong>
                      <p>{cnpjSelected?.name}</p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 8 }}>
                    <Col md={16} style={{ paddingLeft: 0 }}>
                      <strong>{I18n.get("Endereço de Cobrança")}</strong>
                      <p>
                        {`${cnpjSelected?.addressLine1} ${
                          cnpjSelected?.addressLine2 || ""
                        }`}
                      </p>
                    </Col>
                    <Col md={4}>
                      <strong>{I18n.get("Estado")}</strong>
                      <p>{cnpjSelected?.state}</p>
                    </Col>
                    <Col md={4} style={{ paddingRight: 0 }}>
                      <strong>{I18n.get("País")}</strong>
                      <p>{cnpjSelected?.country?.name}</p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 8 }}>
                    <Col md={12} style={{ paddingLeft: 0 }}>
                      <strong>{I18n.get("Telefone")}</strong>
                      <p>{cnpjSelected?.phoneNumber}</p>
                    </Col>
                  </Row>
                </S.CnpjSelectedContent>
              ) : null}
            </>
          ) : null}
        </S.BillingData>
      </Col>

      <Drawer
        open={openDrawer.open}
        handleClose={handleCloseDrawer}
        buttons={
          <>
            <ButtonBpool
              text={I18n.get("Cancelar")}
              onClick={handleCloseDrawer}
              theme="tertiary"
            />
            <div style={{ marginLeft: 16 }}>
              <ButtonBpool
                text={I18n.get("Salvar dados")}
                onClick={addNewRegistrationId}
                theme="primary"
                loading={isSending}
              />
            </div>
          </>
        }
      >
        <S.TitleContent style={{ marginTop: 32 }}>
          <h3>{I18n.get("Cadastrar novos dados de faturamento")}</h3>
        </S.TitleContent>
        <S.Form>
          <div style={{ height: 80 }}>
            {clearing ? (
              <div />
            ) : (
              <>
                {state?.client?.country?.code === "BRA" ? (
                  <InputMask
                    id="registrationId"
                    label={I18n.get("CNPJ")}
                    defaultValue={inputsFormNewCnpj?.registrationId}
                    onChange={(value) =>
                      onChangeInputFormNewCnpj(
                        "registrationId",
                        value?.formattedValue
                      )
                    }
                    mask="##.###.###/####-##"
                    required
                    error={hasError({
                      arr: errorsInputs,
                      field: "registrationId",
                    })}
                    clearError={() => {
                      const errorsArr = clearErrorInput({
                        field: "registrationId",
                        arr: errorsInputs,
                      });
                      setErrorsInputs(errorsArr);
                    }}
                  />
                ) : (
                  <Input
                    placeholder={I18n.get("CNPJ")}
                    label
                    required
                    value={inputsFormNewCnpj.registrationId}
                    onChange={(value) =>
                      onChangeInputFormNewCnpj("registrationId", value)
                    }
                    error={hasError({
                      arr: errorsInputs,
                      field: "registrationId",
                    })}
                    clearError={() => {
                      const errorsArr = clearErrorInput({
                        field: "registrationId",
                        arr: errorsInputs,
                      });
                      setErrorsInputs(errorsArr);
                    }}
                  />
                )}
              </>
            )}
          </div>
          <Input
            placeholder={I18n.get("Razão social")}
            label
            required
            value={inputsFormNewCnpj.registrationName}
            onChange={(value) =>
              onChangeInputFormNewCnpj("registrationName", value)
            }
            error={hasError({ arr: errorsInputs, field: "registrationName" })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "registrationName",
                arr: errorsInputs,
              });
              setErrorsInputs(errorsArr);
            }}
          />
          <Input
            placeholder={I18n.get("Nome fantasia")}
            label
            required
            value={inputsFormNewCnpj.name}
            onChange={(value) => onChangeInputFormNewCnpj("name", value)}
            error={hasError({ arr: errorsInputs, field: "name" })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "name",
                arr: errorsInputs,
              });
              setErrorsInputs(errorsArr);
            }}
          />
          <Input
            placeholder={I18n.get("E-mail de cobrança")}
            label
            required
            value={inputsFormNewCnpj.email}
            onChange={(value) => onChangeInputFormNewCnpj("email", value)}
            error={hasError({ arr: errorsInputs, field: "email" })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "email",
                arr: errorsInputs,
              });
              setErrorsInputs(errorsArr);
            }}
          />
          <S.SubBillingDataTitle style={{ marginBottom: 16 }}>
            <h4>{I18n.get("Endereço do CNPJ")}</h4>
          </S.SubBillingDataTitle>
          <div style={{ height: 80 }}>
            {clearing ? (
              <div />
            ) : (
              <>
                {state?.client?.country?.code === "BRA" ? (
                  <InputMask
                    id="zipCode"
                    label={I18n.get("CEP")}
                    defaultValue={inputsFormNewCnpj?.zipCode}
                    onChange={(value) => handleChangeCep(value)}
                    mask="#####-###"
                    required
                    error={hasError({ arr: errorsInputs, field: "zipCode" })}
                    clearError={() => {
                      const errorsArr = clearErrorInput({
                        field: "zipCode",
                        arr: errorsInputs,
                      });
                      setErrorsInputs(errorsArr);
                    }}
                  />
                ) : (
                  <Input
                    placeholder={I18n.get("CEP")}
                    label
                    required
                    value={inputsFormNewCnpj.zipCode}
                    onChange={(value) =>
                      onChangeInputFormNewCnpj("zipCode", value)
                    }
                    error={hasError({ arr: errorsInputs, field: "zipCode" })}
                    clearError={() => {
                      const errorsArr = clearErrorInput({
                        field: "zipCode",
                        arr: errorsInputs,
                      });
                      setErrorsInputs(errorsArr);
                    }}
                  />
                )}
              </>
            )}
          </div>
          <Input
            placeholder={I18n.get("Endereço (Rua, número e bairro)")}
            label
            required
            value={inputsFormNewCnpj.addressLine1}
            onChange={(value) =>
              onChangeInputFormNewCnpj("addressLine1", value)
            }
            error={hasError({ arr: errorsInputs, field: "addressLine1" })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "addressLine1",
                arr: errorsInputs,
              });
              setErrorsInputs(errorsArr);
            }}
          />
          <Input
            placeholder={I18n.get("Complemento")}
            label
            value={inputsFormNewCnpj.addressLine2}
            onChange={(value) =>
              onChangeInputFormNewCnpj("addressLine2", value)
            }
          />
          <Input
            placeholder={I18n.get("Cidade")}
            label
            required
            value={inputsFormNewCnpj.city}
            onChange={(value) => onChangeInputFormNewCnpj("city", value)}
            error={hasError({ arr: errorsInputs, field: "city" })}
            clearError={() => {
              const errorsArr = clearErrorInput({
                field: "city",
                arr: errorsInputs,
              });
              setErrorsInputs(errorsArr);
            }}
          />
          <div style={{ height: 80 }}>
            <SelectOne
              options={COUNTRY_CODES}
              value={inputsFormNewCnpj?.country?.code}
              defaultValue={inputsFormNewCnpj?.country?.code}
              isCountry
              onChange={(value) =>
                onChangeInputFormNewCnpj("country", value, true)
              }
              required
              placeholder={I18n.get("País")}
              error={hasError({ arr: errorsInputs, field: "countryCode" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "countryCode",
                  arr: errorsInputs,
                });
                setErrorsInputs(errorsArr);
              }}
            />
          </div>
          <div style={{ height: 80 }}>
            {inputsFormNewCnpj?.country?.code !== "BRA" ? (
              <Input
                placeholder={I18n.get("Estado")}
                label
                required
                value={inputsFormNewCnpj.state}
                onChange={(value) => onChangeInputFormNewCnpj("state", value)}
                error={hasError({ arr: errorsInputs, field: "state" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "state",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            ) : (
              <SelectOne
                options={constants.STATES}
                value={inputsFormNewCnpj?.state}
                defaultValue={inputsFormNewCnpj?.state}
                onChange={(value) => onChangeInputFormNewCnpj("state", value)}
                required
                placeholder={I18n.get("Estado")}
                error={hasError({ arr: errorsInputs, field: "state" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "state",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            )}
          </div>
          {clearing ? (
            <div />
          ) : inputsFormNewCnpj?.country?.code !== "BRA" ? (
            <Input
              placeholder={I18n.get("Telefone")}
              label
              required
              typeInput="number"
              value={inputsFormNewCnpj.phoneNumber}
              onChange={(value) =>
                onChangeInputFormNewCnpj("phoneNumber", value)
              }
              error={hasError({ arr: errorsInputs, field: "phoneNumber" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "phoneNumber",
                  arr: errorsInputs,
                });
                setErrorsInputs(errorsArr);
              }}
            />
          ) : (
            <InputMask
              id="phoneNumber"
              label={I18n.get("Telefone")}
              defaultValue={inputsFormNewCnpj?.phoneNumber}
              onChange={(value) =>
                onChangeInputFormNewCnpj("phoneNumber", value?.formattedValue)
              }
              mask="(##) #####-####"
              required
              error={hasError({ arr: errorsInputs, field: "phoneNumber" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "phoneNumber",
                  arr: errorsInputs,
                });
                setErrorsInputs(errorsArr);
              }}
            />
          )}
        </S.Form>
      </Drawer>

      <S.Modal
        width={650}
        title={I18n.get("Dados cadastrados")}
        visible={modalPreviewCnpjVisible}
        onOk={() => setModalPreviewCnpjVisible(false)}
        onCancel={() => setModalPreviewCnpjVisible(false)}
        centered
        footer={[
          <ButtonBpool
            theme="primary"
            onClick={() => setModalPreviewCnpjVisible(false)}
            text={I18n.get("Fechar")}
          />,
        ]}
      >
        <S.CnpjSelectedContent style={{ margin: 0 }}>
          <Row>
            <Col md={8}>
              <strong>{I18n.get("CNPJ")}</strong>
              <p>{billingData?.clientCompany?.registrationId || "---"}</p>
            </Col>
            <Col md={16}>
              <strong>{I18n.get("Razão social")}</strong>
              <p>{billingData?.clientCompany?.registrationName || "---"}</p>
            </Col>
          </Row>
          <Row style={{ marginTop: 16 }}>
            <Col md={8}>
              <strong>{I18n.get("Nome Fantasia")}</strong>
              <p>{billingData?.clientCompany?.name || "---"}</p>
            </Col>
            <Col md={16}>
              <strong>{I18n.get("Telefone")}</strong>
              <p>{billingData?.clientCompany?.phoneNumber || "---"}</p>
            </Col>
          </Row>
          <Row style={{ marginTop: 16 }}>
            <Col md={16}>
              <strong>{I18n.get("Endereço de Cobrança")}</strong>
              <p>{billingData?.clientCompany?.addressLine1 || "---"}</p>
              <strong>{I18n.get("Complemento")}</strong>
              <p>{billingData?.clientCompany?.addressLine2 || "---"}</p>
            </Col>
            <Col md={4}>
              <strong>{I18n.get("Estado")}</strong>
              <p>{billingData?.clientCompany?.state || "---"}</p>
            </Col>
            <Col md={4}>
              <strong>{I18n.get("País")}</strong>
              <p>{billingData?.clientCompany?.country?.name || "---"}</p>
            </Col>
          </Row>
        </S.CnpjSelectedContent>
      </S.Modal>
    </>
  );
};
