import styled from "styled-components";
import agendaIcon from '../../../svg/calendar-icon.svg';
import profileIcon from '../../../svg/profile-icon.svg';
import checkIcon from '../../../svg/check-icon.svg';

export const AgendaBoard = styled.div`
    background-color: #FFF;
    border-radius: 10px;
    padding: 40px 30px 50px;
    text-align: center; 
    height: calc(100% - 96px);   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const Call = styled.div`
    
    &:first-child{
        border-bottom: 1px solid #d9d9d9;
        padding: 0px 0 20px;
        align-self: flex-start;
    }
    &:nth-child(2){
        padding: 20px 0 40px;
        align-self: flex-start;
    }

    div {
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        margin-bottom: 12px;

        p {
            margin:0;
        }

        .date, .name, .status {
            font-weight: 600;
        }
        .date:before{
            content: '';
            width: 24px;
            height: 24px;
            background-image: url(${agendaIcon});
            display: inline-flex;
            margin-right: 10px;
            position: relative;
            bottom: -5px;
        }
        .name:before {
            content: '';
            width: 24px;
            height: 24px;
            background-image: url(${profileIcon});
            display: inline-flex;
            margin-right: 10px;
            position: relative;
            bottom: -5px;
        }
        .hour, .client {
            margin-left: 35px;
        }
        .status {
            color: ${props => props.color};
            &:before {
                content: '';
                width: 24px;
                height: 24px;
                background-image: url(${checkIcon});
                display: inline-flex;
                margin-right: 10px;
                position: relative;
                bottom: -5px;
            }
        }
    }
   
`;