import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { PROJECT_API } from '../../actions/project/actions';
import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';

import ClientProjectListFull from './client-project/_list-full';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = (dispatch) => {

    return {
        get_projects_full: data => {
            dispatch(BP_API.get_projects_full(data));
        },
        reset: () => {
            dispatch(PROJECT_API.reset());
        },
    };
};

class ClientProjectListFullAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {
        this.props.reset();
    }

    render() {



        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/projetos" className="is-black">{I18n.get("Projetos")}</NavLink></li>
                    </ul>
                </nav>

                <div className="columns">

                    <div className="column is-12">

                        <ClientProjectListFull {...this.props} />

                    </div>

                </div>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProjectListFullAdmin);
