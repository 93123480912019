import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col, Radio, message } from "antd";
import ReactPlayer from "react-player";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { Input } from "../../../../components/UI/Input";
import { Textarea } from "../../../../components/UI/Textarea";
import { UploadLogo } from "../components/UploadLogo";
import { UploadHeaderImage } from "../components/UploadHeaderImage";
import { UploadIntroImage } from "../components/UploadIntroImage";
import { ModalPreview } from "../components/ModalPreview";
import { useUpload } from "../../../../hooks/useUpload/useUpload.hook";
import { useRegistrationFlowFull } from "../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook";
import { ModalBp } from "../../../../components/UI/ModalBp/ModalBp";
import { hasError, clearErrorInput } from "../../utils/errorsInput";
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from "../../utils/flowToStorage";
import { statusIdToName } from "../../components/Loggedv2/statusIdToName";

import { urlsFull as urls } from "../../utils/stepsUrls";
import { youtubeAndVimeoPass } from "../../utils/validateUrlVideos";
import { inputsState } from "./inputsState";
import * as S from "./styles";

const MESSAGE_ERROR_GET_PAGE = "There was an error, try again.";
const ERROR_IMAGE_UP = "Please insert an image in the correct sizes.";
const ERROR_IMAGE_NOT_UP = "Logo image is required with sizes";
const PAGE_INDEX = 7;

export const ContentPage = ({
  contextPage,
  partnerId,
  onChangeInfo,
  sendOk,
}) => {
  let history = useHistory();
  const { uploadFile } = useUpload();
  const { getStatusBarFull, getCompanyProfile, updateCompanyProfile } =
    useRegistrationFlowFull();

  const [inputsFormInitals, setInputsFormInitals] = useState(inputsState);
  const [inputsForm, setInputsForm] = useState(inputsState);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isLoadPage, setIsLoadPage] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [valueMedia, setValueMedia] = useState(1);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [statusUploadImages, setStatusUploadImages] = useState({
    logoImage: "",
    headerImage: "",
    introImage: "",
  });
  const [filesToUpload, setFilesToUpload] = useState({
    logoImage: null,
    headerImage: null,
    introImage: null,
  });
  const [agencyName, setAgencyName] = useState("Name of your agency");
  const [errorLinkVideo, setErrorLinkVideo] = useState("");

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleClickOkModalConfirm = () => {
    setOpenModalConfirm(false);
    history.push({
      pathname: urls[PAGE_INDEX + 1],
    });
  };

  const onChangeInputVideoLink = (id, videoLink) => {
    onChangeInputForm(id, videoLink);
    if (videoLink) {
      const urlVideoPass = youtubeAndVimeoPass(videoLink);

      if (!urlVideoPass) {
        setErrorLinkVideo(
          I18n.get("Insert a url video correct (Youtube, Vimeo or Dropbox).")
        );
      }
    } else {
      setErrorLinkVideo("");
    }
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStatusBarFull();
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);

    let logoImageSend = inputsFormInitals.logoImage;
    if (filesToUpload.logoImage) {
      const responselogoImageUpload = await uploadFile({
        file: filesToUpload.logoImage,
        idUser: "usertestingnow",
        fieldId: `logoImage`,
      });
      logoImageSend = responselogoImageUpload.url;
    }

    let headerImageSend = inputsFormInitals.headerImage;
    if (filesToUpload.headerImage) {
      const responseHeaderImageUpload = await uploadFile({
        file: filesToUpload.headerImage,
        idUser: "usertestingnow",
        fieldId: `headerImage`,
      });
      headerImageSend = responseHeaderImageUpload.url;
    }

    let introImageSend = inputsFormInitals.introImage;
    if (filesToUpload.introImage) {
      const responseIntroImageUpload = await uploadFile({
        file: filesToUpload.introImage,
        idUser: "usertestingnow",
        fieldId: `introImage`,
      });
      introImageSend = responseIntroImageUpload.url;
    }

    const formData = {
      adminAction: contextPage === "admin" ? true : false,
      id: contextPage === "admin" ? partnerId : null,
      portfolioURL: inputsForm.portfolioURL,
      company: {
        description: inputsForm.description,
        problemSolves: inputsForm.problemSolves,
        competitiveDifferentiators: inputsForm.competitiveDifferentiators,
        website: inputsForm.website,
        linkedin: inputsForm.linkedin,
        instagram: inputsForm.instagram,
        logoImage: logoImageSend ? logoImageSend : "",
        headerImage: headerImageSend ? headerImageSend : "",
        introImage: inputsForm.introVideo ? "" : introImageSend,
        introVideo: inputsForm.introVideo ? inputsForm.introVideo : "",
      },
    };

    const response = await updateCompanyProfile({
      admin: contextPage === "admin" ? true : false,
      form: formData,
    });
    if (contextPage === "full") {
      await newGetStepbar();
    }

    if (response?.errors?.length) {
      if (contextPage === "full") {
        setOpenModalConfirm(true);
      }
      setInputsErrorsForm(response?.errors);
    } else {
      if (contextPage === "full") {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: urls[PAGE_INDEX + 1],
        });
      }
      if (contextPage === "edit" || contextPage === "admin") {
        sendOk(true);
      }
    }

    setIsSending(false);
  };

  const onChangeRadioGroup = (e) => {
    setValueMedia(e.target.value);
  };

  const closeModalPreview = () => {
    setOpenModalPreview(false);
  };

  const loadInit = async () => {
    setIsLoadPage(true);

    const response = await getCompanyProfile({
      admin: contextPage === "admin" ? true : false,
    });
    if (response.success) {
      const data = {
        ...response.data.company,
        portfolioURL: response.data.portfolioURL,
      };
      setAgencyName(response?.data?.company?.name || "Name of your agency");

      if (data.introVideo) {
        setValueMedia(2);
      } else {
        setValueMedia(1);
      }

      setInputsFormInitals(data);
      setInputsForm(data);
    }

    setIsLoadPage(false);
  };

  const fileToUpload = ({ key, file }) => {
    setFilesToUpload({
      ...filesToUpload,
      [key]: file ? file : null,
    });
  };

  //validation
  useEffect(() => {
    if (
      inputsForm.website !== inputsFormInitals.website ||
      inputsForm.portfolioURL !== inputsFormInitals.portfolioURL ||
      inputsForm.instagram !== inputsFormInitals.instagram ||
      inputsForm.description !== inputsFormInitals.description ||
      inputsForm.competitiveDifferentiators !==
        inputsFormInitals.competitiveDifferentiators ||
      inputsForm.problemSolves !== inputsFormInitals.problemSolves
    ) {
      setChangeInfo(true);
      onChangeInfo(true);
    } else {
      setChangeInfo(false);
      onChangeInfo(false);
    }
  }, [inputsForm]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  useEffect(() => {
    if (valueMedia === 1) {
      setInputsForm({ ...inputsForm, introVideo: "" });
    }
    if (valueMedia === 2) {
      setInputsForm({
        ...inputsForm,
        introImage: "",
        introVideo: inputsFormInitals.introVideo,
      });
      setStatusUploadImages({ ...statusUploadImages, introImage: "" });
    }
  }, [valueMedia]);

  const insta = (
    <span style={{ color: "#000", fontSize: "18px" }}>
      <b style={{ position: "relative", top: "4px" }}>@</b>
    </span>
  );

  const renderHasError = ({ status }) => {
    if (status) {
      return (
        <S.ErrorText>
          {status === "error" ? ERROR_IMAGE_UP : status}
        </S.ErrorText>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get("Company Profile")}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  "Now it's time to show your company's face. Upload visual elements with the company's identity below."
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <S.ContentImagesPreview>
              <S.HeaderImagePreview>
                <S.Label
                  className="header-label"
                  // statusUp={statusUploadImages.headerImage}
                >
                  <strong>Header:</strong>
                  1244 x 100 px JPG or PNG
                  {renderHasError({
                    status: hasError({
                      field: "headerImage",
                      arr: inputsErrorsForm,
                    }),
                  })}
                  {statusUploadImages.headerImage == "error" && (
                    <p style={{ color: "#ff0000" }}>
                      {I18n.get("Maximum limit 5mb")}
                    </p>
                  )}
                </S.Label>
                <S.ImgHeader>
                  <UploadHeaderImage
                    accept=".jpg,.jpeg,.png"
                    defaultImg={inputsFormInitals.headerImage}
                    imgUploadStatus={(status) =>
                      setStatusUploadImages({
                        ...statusUploadImages,
                        headerImage: status,
                      })
                    }
                    fileToUpload={(file) =>
                      fileToUpload({ key: "headerImage", file })
                    }
                  />
                </S.ImgHeader>
                <S.Label className="logo-label">
                  <div style={{ textIndent: "15px" }}>
                    <strong>Logo:</strong>117 x 117px JPG or PNG
                    {renderHasError({
                      status: hasError({
                        field: "logoImage",
                        arr: inputsErrorsForm,
                      }),
                    })}
                    {statusUploadImages.logoImage == "error" && (
                      <p style={{ color: "#ff0000" }}>
                        {I18n.get("Maximum limit 5mb")}
                      </p>
                    )}
                  </div>
                </S.Label>

                <S.Logo>
                  <S.LogoImage>
                    <UploadLogo
                      accept=".jpg,.jpeg,.png"
                      defaultImg={inputsFormInitals.logoImage}
                      imgUploadStatus={(status) =>
                        setStatusUploadImages({
                          ...statusUploadImages,
                          logoImage: status,
                        })
                      }
                      fileToUpload={(file) =>
                        fileToUpload({ key: "logoImage", file })
                      }
                    />
                  </S.LogoImage>
                </S.Logo>
              </S.HeaderImagePreview>

              <S.TitleSection>{agencyName}</S.TitleSection>

              <S.ChoiceUserImageOrVideo>
                <S.SeeExample>
                  {I18n.get(
                    "In the field below you can send a video or image."
                  )}
                </S.SeeExample>
                <S.Selects>
                  <Radio.Group onChange={onChangeRadioGroup} value={valueMedia}>
                    <Radio value={1}>{I18n.get("Image")}</Radio>
                    <Radio value={2}>
                      {I18n.get("Video")}{" "}
                      <span className="text-span">
                        (Youtube, Vimeo, Dropbox)
                      </span>
                    </Radio>
                  </Radio.Group>
                </S.Selects>
              </S.ChoiceUserImageOrVideo>

              {valueMedia === 1 ? (
                <S.BgImagePreview>
                  {/* <S.Label
                    className="label-introimage"
                    statusUp={statusUploadImages.introImage}
                    style={{
                      color: hasError({
                        field: "introImage",
                        arr: inputsErrorsForm,
                      })
                        ? "#ff0000"
                        : "",
                    }}
                  >
                    <div>
                       Error Message: "Please verify the type and size of the file"  
                      <strong>{I18n.get('Image must be PNG or JPG and have a maximum size of 1244 x 300px')}:</strong> 
                    </div>
                  </S.Label> */}
                  <S.ImgPresentation>
                    <UploadIntroImage
                      accept=".jpg,.jpeg,.png"
                      defaultImg={inputsFormInitals.introImage}
                      imgUploadStatus={(status) =>
                        setStatusUploadImages({
                          ...statusUploadImages,
                          introImage: status,
                        })
                      }
                      fileToUpload={(file) =>
                        fileToUpload({ key: "introImage", file })
                      }
                    />
                  </S.ImgPresentation>
                  <p
                    style={{
                      fontSize: 12,
                      marginTop: 15,
                      color:
                        hasError({
                          field: "introImage",
                          arr: inputsErrorsForm,
                        }) || statusUploadImages.introImage == "error"
                          ? "#ff0000"
                          : "",
                    }}
                  >
                    {I18n.get(
                      "Image must be PNG or JPG and have a maximum size of 1244 x 300px"
                    )}
                  </p>
                  {renderHasError({
                    status: hasError({
                      field: "introImage",
                      arr: inputsErrorsForm,
                    }),
                  })}

                  {statusUploadImages.introImage == "error" && (
                    <S.ErrorText>
                      {I18n.get("Please verify the type and size of the file")}
                    </S.ErrorText>
                  )}
                </S.BgImagePreview>
              ) : (
                <S.WrapperVideo>
                  <div style={{ height: 80 }}>
                    <Input
                      placeholder={I18n.get("Video link")}
                      label
                      value={inputsForm.introVideo}
                      onChange={(value) =>
                        onChangeInputVideoLink("introVideo", value)
                      }
                      error={
                        hasError({
                          field: "introVideo",
                          arr: inputsErrorsForm,
                        })
                          ? hasError({
                              field: "introVideo",
                              arr: inputsErrorsForm,
                            })
                          : errorLinkVideo || ""
                      }
                      clearError={() => {
                        const errorsArr = clearErrorInput({
                          field: "introVideo",
                          arr: inputsErrorsForm,
                        });
                        setInputsErrorsForm(errorsArr);
                      }}
                    />
                  </div>
                  <S.VideoContent>
                    <S.Label style={{ marginBottom: 10 }}>
                      {I18n.get("Video")}
                    </S.Label>
                    {inputsForm.introVideo ? (
                      !errorLinkVideo ? (
                        <ReactPlayer
                          // className="responsive-video"
                          url={inputsForm.introVideo}
                          playing={true}
                          loop={true}
                          controls={false}
                          muted={true}
                          style={{ margin: "0 auto" }}
                        />
                      ) : (
                        <S.EmptyVideo>
                          <p style={{ color: "#ff0000" }}>
                            {I18n.get(
                              "Insert a url video correct (Youtube or Vimeo)."
                            )}
                          </p>
                        </S.EmptyVideo>
                      )
                    ) : (
                      <S.EmptyVideo>
                        <p>{I18n.get("Insert a video for preview.")}</p>
                      </S.EmptyVideo>
                    )}
                  </S.VideoContent>
                </S.WrapperVideo>
              )}
            </S.ContentImagesPreview>
          </Col>
        </Row>
        <Row>
          <Col xs={24} style={{ marginTop: 30 }}>
            <S.TitleSectionForm>{I18n.get("Useful links")}</S.TitleSectionForm>
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Website")}
              label
              required
              value={inputsForm.website}
              onChange={(value) => onChangeInputForm("website", value)}
              error={hasError({ field: "website", arr: inputsErrorsForm })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "website",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Company portfolio link")}
              label
              value={inputsForm.portfolioURL}
              onChange={(value) => onChangeInputForm("portfolioURL", value)}
              required
              error={hasError({
                field: "portfolioURL",
                arr: inputsErrorsForm,
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "portfolioURL",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Input
              placeholder={I18n.get("Company Instagram")}
              label
              // required
              value={inputsForm.instagram}
              onChange={(value) => onChangeInputForm("instagram", value)}
              error={hasError("instagram")}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "instagram",
                  arr: inputsErrorsForm,
                });
                setInputsErrorsForm(errorsArr);
              }}
              addonBefore={insta}
              widthLabel="40px"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={24}>
            <Textarea
              id="overview-company"
              label={I18n.get("Give us an overview on your company...")}
              rows={4}
              maxLength={500}
              counterChar
              defaultValue={inputsForm.description}
              onChange={(value) => onChangeInputForm("description", value)}
              required
              error={hasError({
                field: "description",
                arr: inputsErrorsForm,
              })}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={24}>
            <Textarea
              id="problem-solves"
              label={I18n.get(
                "Briefly describe the main problems that your company solves."
              )}
              rows={4}
              maxLength={500}
              counterChar
              defaultValue={inputsForm.problemSolves}
              onChange={(value) => onChangeInputForm("problemSolves", value)}
              error=""
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={24}>
            <Textarea
              id="competitive-differentiators"
              label={I18n.get("Describe the main competitive differentiators.")}
              rows={4}
              maxLength={500}
              counterChar
              defaultValue={inputsForm.competitiveDifferentiators}
              onChange={(value) =>
                onChangeInputForm("competitiveDifferentiators", value)
              }
              error=""
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 12 }}>
            <ButtonBpool
              text={
                contextPage === "edit" || contextPage === "admin"
                  ? I18n.get("Save")
                  : I18n.get("Save and Next")
              }
              theme="primary"
              full
              loading={isSending}
              onClick={send}
            />
          </Col>
        </Row>
      </Main>

      <ModalBp
        visible={openModalConfirm}
        bodyText={I18n.get("Missing required information")}
        subBodyText={I18n.get(
          "Mandatory information is missing or the information is incorrect, do you want to go to the next page anyway?"
        )}
        okText={I18n.get("Yes")}
        handleClickOk={handleClickOkModalConfirm}
        onCancel={() => setOpenModalConfirm(false)}
        cancelText={I18n.get("Close")}
        typeModal="isConfirm"
        // isDanger
      />

      <ModalPreview
        open={openModalPreview}
        handleClose={closeModalPreview}
        type={valueMedia}
      />
    </>
  );
};
