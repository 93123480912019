import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_PROJECT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_match = (data) => {

    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.put(`${Config.API_BASE_URL}/bp/projects/${data.projectId}/match/${data.partnerId}`, data, config)
                .then(response => {

                    dispatch({
                        type: BP_PROJECT_UPDATE,
                        payload: {
                            project: response.data,
                            errors: {},
                            task: { result: true }
                        }
                    })
                })
                .catch(error => {

                    dispatch({
                        type: BP_PROJECT_UPDATE,
                        payload: {
                            errors: error.response.data,
                            task: { result: false }
                        }
                    })

                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_update_match;