import React from 'react';

import { I18n } from 'aws-amplify';

import ProjectContainer from '../../containers/project-container';
import ProjectCart from '../../containers/project-cart-container';

import FillForm from '../page-templates/fillForm-logged';

import AssetBundleForm from './asset-bundle-form';

import AssetCustomizeForm from './asset-customize-form';
import AssetResumeForm from './asset-resume-form';

import SubHeader from './__sub-header';

import EventCustomizeForm from './event-customize-form';
import BPitchCustomizeForm from './bpitch-customize-form';

import { ProjectType } from '../../model';

class NewProjectStep2 extends React.Component {
    constructor(props) {

        super();

        const disableNewProjectStep2BundleView = props.client.customization?.disableNewProjectStep2BundleView ?? false;

        const initialView = disableNewProjectStep2BundleView ? 'CART' : 'BUNDLE';

        this.state = {
            view: initialView,
            bundles: [],
        };
    }

    componentDidMount(){

        var data = {
            projectId: this.props.match.params.projectId,
          };

        this.props.get_assets(data);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const disableNewProjectStep2BundleView = this.props.client.customization?.disableNewProjectStep2BundleView ?? false;

        if (disableNewProjectStep2BundleView && prevState && prevState.view == 'BUNDLE') {
            this.setState({ view: 'CART' });
        }

    }

    setView = (viewName) => {
        this.setState({
            view: viewName
        });
    }

    handleResumeBtnClick = (cartAssets) => {
        this.setState({
            view: 'CHECKOUT',
            cartAssets: cartAssets,
        });
    }

    render() {

        const logo = this.props.client.company?.logo;

        const { isCreative, isEvent, isBPitch, customEvent = {}, bPitch = {}, isCustomEvent } = this.props;

        const subHeaderProps = { isCreative, isEvent, isBPitch, customEvent, bPitch, isCustomEvent };

        let applyDiscount = false;


        return (<FillForm>
            <div className="hero-body has-background-white for-np uplift-hero-2">
                <SubHeader projectId={this.props.match.params.projectId} active={2} logo={logo} {...subHeaderProps} />
            </div>
            <div className="client-step client-step-1">

                <div className="container client-container-1 client-container-build-project uplift-step-2">

                    <div className="column is-12">

                        {/* Normal */}

                        {this.props.projectType === ProjectType.Creative && this.state.view == 'BUNDLE' &&
                            <div className="build-closure"><AssetBundleForm
                                {...this.props}
                                onCustomBtnClick={this.setView.bind(this, 'CART')}
                                cartId={this.props.cartId}
                                createCart={this.props.createCart}
                                cartAssets={this.props.cartAssets}
                                addItem={this.props.addItem}
                                delItem={this.props.delItem}
                                clearCart={this.props.clearCart}
                                applyDiscount={applyDiscount}
                                currency={this.props.currency}
                            /></div>}

                        {/* Carrinho */}
                        {this.props.projectType === ProjectType.Creative && this.state.view == 'CART' &&
                            <AssetCustomizeForm
                                {...this.props}
                                onBundleBtnClick={this.setView.bind(this, 'BUNDLE')}
                                onResumeBtnClick={this.setView.bind(this, 'CHECKOUT')}
                                bundles={this.state.bundles}
                                cartAssets={this.props.cartAssets}
                                addItem={this.props.addItem}
                                applyDiscount={applyDiscount}
                                currency={this.props.currency}
                                
                            />}

                        {/* Resumo */}
                        {this.props.projectType === ProjectType.Creative && this.state.view == 'CHECKOUT' &&
                            <div className="build-closure"><AssetResumeForm
                                {...this.props}
                                onCustomBtnClick={this.setView.bind(this, 'CART')}
                                cartAssets={this.props.cartAssets}
                                delItem={this.props.delItem}
                                applyDiscount={applyDiscount}
                            currency={this.props.currency}
                            /></div>}

                        {/* Evento Customizado */}
                        {this.props.projectType === ProjectType.EventCustom &&
                            <EventCustomizeForm {...this.props} />
                        }

                        {/* Evento Pacote */}
                        {this.props.projectType === ProjectType.EventBundle &&
                            <AssetBundleForm
                                {...this.props}
                                onCustomBtnClick={this.setView.bind(this, 'CART')}
                                cartId={this.props.cartId}
                                createCart={this.props.createCart}
                                cartAssets={this.props.cartAssets}
                                addItem={this.props.addItem}
                                delItem={this.props.delItem}
                                clearCart={this.props.clearCart} />
                        }


                        {/* BPitch */}
                        {this.props.projectType === ProjectType.BPitch &&
                            <BPitchCustomizeForm {...this.props} />
                        }

                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </FillForm>)
    }
}

export default ProjectContainer(ProjectCart(NewProjectStep2));
