import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import SelectField from '../../form/select-field';
import MaskedField from '../../form/masked-field';

import moment from 'moment';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

class ScheduleItem extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            fields: {
                scheduleType: {
                    // label: I18n.get("Tipo"),
                    onChange: this.onChangeForm.bind(this, 'scheduleType'),
                    values: [
                        { code: 'PERIOD', label: I18n.get("Período") },
                        { code: 'DATE', label: I18n.get("Data Fixa") },
                    ]
                },
                // startDate: {
                //     label: 'Início',
                //     mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
                //     onChange: this.onChangeForm.bind(this, 'startDate'),
                //     successIcon: false,
                //     placeholder: I18n.get("DD/MM/AAAA")
                // },
                // endDate: {
                //     label: 'Término',
                //     mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
                //     onChange: this.onChangeForm.bind(this, 'endDate'),
                //     successIcon: false,
                //     placeholder: I18n.get("DD/MM/AAAA")
                // },
                // scheduleDate: {
                //     label: I18n.get("Data"),
                //     mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
                //     onChange: this.onChangeForm.bind(this, 'scheduleDate'),
                //     successIcon: false,
                //     placeholder: I18n.get("DD/MM/AAAA")
                // },
            },
            errors: {},
            form: {
                ...props
            },
            task: {},
            submit_task: {}
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeRange(range) {

        const startDate = moment(range[0]).format('DD/MM/YYYY');
        const endDate = moment(range[1]).format('DD/MM/YYYY');

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.startDate = startDate;
            nextState.errors.startDate = null;

            nextState.form.endDate = endDate;
            nextState.errors.endDate = null;

            nextState.form.scheduleDate = null;
            nextState.errors.scheduleDate = null;

            return nextState;
        }, () => {

            this.props.onChangeItem(this.state.form);
        });
    }

    onChangeDate(date) {

        const scheduleDate = moment(date).format('DD/MM/YYYY');

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form.scheduleDate = scheduleDate;
            nextState.errors.scheduleDate = null;

            nextState.form.startDate = null;
            nextState.errors.startDate = null;

            nextState.form.endDate = null;
            nextState.errors.endDate = null;

            return nextState;
        }, () => {

            this.props.onChangeItem(this.state.form);
        });
    }

    render() {

        const { locale } = this.props;

        const scheduleDate = this.state.form.scheduleDate ? moment(this.state.form.scheduleDate, "DD/MM/YYYY") : moment();
        const startDate = this.state.form.startDate ? moment(this.state.form.startDate, "DD/MM/YYYY") : moment().startOf('week');
        const endDate = this.state.form.endDate ? moment(this.state.form.endDate, "DD/MM/YYYY") : moment().endOf('week');


        const { index, errors } = this.props;

        const scheduleTypeError = errors[`schedule[${index}].scheduleType`];
        const scheduleDateError = errors[`schedule[${index}].scheduleDate`];
        const startDateError = errors[`schedule[${index}].scheduleStartDate`];
        const endDateError = errors[`schedule[${index}].scheduleEndDate`];

        return (
            <form>

                {/* x{JSON.stringify(errors)}x{index}y */}

                <div className="columns">

                    <div className="column is-4">
                        <SelectField {...this.state.fields.scheduleType} value={this.state.form.scheduleType} error={scheduleTypeError} />
                    </div>

                    {this.state.form.scheduleType == 'PERIOD' && <>

                        <div className="column is-8">

                            <div className="field">
                                {/* <label className="label is-small">{I18n.get("Período")}</label> */}
                                <div className="control bpitch-schedule-range">

                                    <RangePicker
                                        defaultValue={[startDate, endDate]}
                                        ranges={{
                                            [I18n.get('This Week')]: [moment().startOf('week'), moment().endOf('week')],
                                        }}
                                        onChange={this.onChangeRange.bind(this)}
                                        format="DD/MM/YYYY"
                                        separator='-'
                                        locale={locale}
                                    />

                                    {startDateError && startDateError.errorMessage && <>
                                        <p className="help is-danger">
                                            {startDateError.errorMessage}
                                        </p>
                                    </>}

                                    {endDateError && endDateError.errorMessage && <>
                                        <p className="help is-danger">
                                            {endDateError.errorMessage}
                                        </p>
                                    </>}

                                </div>
                            </div>

                        </div>


                        {/* 
                        <div className="column is-4">
                            <MaskedField {...this.state.fields.startDate} value={this.state.form.startDate} error={this.state.errors.startDate} />
                        </div>

                        <div className="column is-4">
                            <MaskedField {...this.state.fields.endDate} value={this.state.form.endDate} error={this.state.errors.endDate} />
                        </div> */}

                    </>}

                    {this.state.form.scheduleType == 'DATE' && <>

                        <div className="column is-8">

                            <div className="field">
                                {/* <label className="label is-small">{I18n.get("Data")}</label> */}
                                <div className="control bpitch-schedule-date">

                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        defaultValue={scheduleDate}
                                        locale={locale}
                                        onChange={this.onChangeDate.bind(this)} />

                                    {scheduleDateError && scheduleDateError.errorMessage && <>
                                        <p className="help is-danger">
                                            {scheduleDateError.errorMessage}
                                        </p>
                                    </>}
                                </div>
                            </div>

                            {/* <MaskedField {...this.state.fields.scheduleDate} value={this.state.form.scheduleDate} error={this.state.errors.scheduleDate} /> */}
                        </div>

                    </>}


                </div>

                {/* {JSON.stringify(this.state.form)} */}


            </form>
        );
    }
}

export default ScheduleItem;
