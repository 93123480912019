import React from 'react';

import { I18n } from 'aws-amplify';

import numeral from 'numeral';

import './quotation-generic-item-form.css';

import { Task } from '../../utils/task';
import { FormUtils } from '../../utils/form';

import TextField from '../form/text-field';
import NumericField from '../form/numeric-field';
import MoneyField from '../form/money-field';
import SelectField from '../form/select-field';

import constants from "../../constants";


class QuotationGenericItemForm extends React.Component {

    constructor(props) {

        super();

        const project = props.project || {};
        const customEvent = project.customEvent || {};

        const isPromotional = project.isCustomEvent && customEvent && customEvent.isPromotional;
        
        this.state = {
            fields: {
                itemType: {
                    onChange: this.onChangeForm.bind(this, 'itemType'),
                    successIcon: false,
                    visited: true,
                },
                description: {
                    onChange: this.onChangeForm.bind(this, 'description'),
                    error: props.errors.description,
                    successIcon: false,
                    visited: true,
                },
                quantity: {
                    onChange: this.onChangeForm.bind(this, 'quantity'),
                    error: props.errors.quantity,
                    successIcon: false,
                    visited: true,
                },
                recurrence: {
                    onChange: this.onChangeForm.bind(this, 'recurrence'),
                    error: props.errors.recurrence,
                    successIcon: false,
                    visited: true,
                },
                price: {
                    value: '',
                    onChange: this.onChangeForm.bind(this, 'price'),
                    error: props.errors.price,
                    successIcon: false,
                    visited: true,
                },
            },
            form: {
            },
            errors: {},
            task: {},
        };

        this.addItem = this.onAddItem.bind(this);

        if (isPromotional) {
            this.state.form.recurrence = 1;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!this.props.task) return;



        if (this.props.task.id == prevState.task.id) return;

        // atualiza a task
        var nextState = Object.assign({}, prevState, { task: this.props.task });

        // atualiza os erros do form
        FormUtils.updateErrors(nextState, this.props.errors);

        if (this.props.task.result) {

            // se task for sucesso, limpa os campos do form
            FormUtils.resetForm(nextState);

            nextState.file = {};
        }

        this.setState(nextState);
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onChangeFormValue(prop, value) {

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    getData() {

        var data = {
            projectId: this.props.match.params.projectId,
            quotationId: this.props.quotation.id,
            sectionId: this.props.section.id,
            ...this.state.form
        };

        data.file = this.state.file;

        return data;
    }

    onAddItem(e) {

        e.preventDefault();

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        this.props.create_universal_quotation_generic_item(this.getData())
            .then(({ task, errors }) => {

                this.setState({ submit_task: task, errors: errors });

                if (task.result) {
                    this.props.closeModal();
                }

            });
    }


    onCancel() {

    }

    getTotal() {

        let { quantity, recurrence, price } = this.state.form;

        if (quantity && recurrence && price) {

            price = price.replace(/[^\d]/g, '');

            const total = numeral(quantity).value() * numeral(recurrence).value() * (numeral(price).value() / 100);

            return total;
        }
        else {
            return 0;
        }
    }

    render() {

        const project = this.props.project || {};
        const customEvent = project.customEvent || {};

        const quotation = this.props.quotation || {};
        const currency = this.props.currency || {};

        const canEdit = this.props.auth.is_partner && quotation.isOpen;

        const isPromotional = project.isCustomEvent && customEvent && customEvent.isPromotional;

        return (
            <section className="quotation-item-form" style={{ paddingBottom: '1rem' }}>

                <p className="title is-5">{I18n.get("Adicionar Item Livre")}</p>

                <div style={{ backgroundColor: '#fff', padding: '1rem' }}>

                    <div className="columns">

                        <div className="column is-4">
                            <SelectField
                                {...this.state.fields.itemType}
                                label={I18n.get("Tipo")}
                                value={this.state.form.itemType}
                                error={this.state.errors.itemType}
                                values={constants.GENERIC_ITEM_TYPE.map((item => ({ code: item.code, label: I18n.get(item.label) })))}
                            />
                        </div>
                        <div className="column is-8">
                            <TextField
                                {...this.state.fields.description}
                                label={I18n.get("Descrição")}
                                value={this.state.form.description} 
                                error={this.state.errors.description} />
                        </div>
                    </div>

                    <div className="columns">

                        <div className="column is-4">
                            <NumericField 
                            {...this.state.fields.quantity} 
                            label={I18n.get("Quantidade")}
                            value={this.state.form.quantity} 
                            error={this.state.errors.quantity} />
                        </div>

                        {!isPromotional && <>

                            <div className="column is-4">
                                <NumericField 
                                {...this.state.fields.recurrence} 
                                label={I18n.get("Período")}
                                value={this.state.form.recurrence} 
                                error={this.state.errors.recurrence}
                                />
                            </div>

                        </>}

                        <div className="column is-4">
                            <MoneyField 
                            {...this.state.fields.price} 
                            currency={currency}
                            label={I18n.get("Preço Unitário")}
                            value={this.state.form.price} 
                            error={this.state.errors.price} />
                        </div>

                    </div>

                    <div className="columns">

                        <div className="column is-4">
                            <MoneyField
                                label={I18n.get('Total')}
                                value={this.getTotal()}
                                readOnly={true}
                                currency={currency}
                            />

                        </div>
                    </div>

                </div>

                <br />

                <button
                    type="button"
                    task={this.state.task}
                    onClick={this.addItem}
                    className="button bt-bpool is-pulled-right"
                >{I18n.get("Adicionar")}</button>

                <br className="is-clearfix" />

                <br />

            </section>
        );
    }
}

export default QuotationGenericItemForm;
