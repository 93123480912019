import React from 'react';

import { I18n } from 'aws-amplify';

const Card = (props) => {

    return (
        <div className="card">
            <div className="card-content">
                <h3 className="title is-6">{props.title}</h3>

                <div className="content">

                    {props.children}

                </div>
            </div>
        </div>
    )
};

export default Card;