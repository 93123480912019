import React from "react";
import { I18n } from "aws-amplify";

import moment from "moment";

import FieldDataPicker from "../../components/ui/field-datepicker";

import {
  Row,
  Col,
  Form,
  notification,
  Input,
  DatePicker,
  Button,
  Checkbox,
} from "antd";

import { CardBorder } from "../../modules/components/Cards";

const dateFormatList = ["DD/MM/YYYY"];

class PartnerInviteForm extends React.Component {
  constructor(props) {
    const partnerIds =
      props.partnersSelected && props.partnersSelected.map((p) => p.partnerId);

    super(props);

    this.state = {
      partnerIds: partnerIds || [],
      partnerIdsValidation: {},
    };
  }

  onInvitePartner = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (!this.state.partnerIds || this.state.partnerIds.length < 1) {
        this.setState({
          partnerIdsValidation: {
            validateStatus: "error",
            help: I18n.get("Por favor selecione pelo menos um parceiro."),
          },
        });
        return;
      }

      this.setState({
        formState: values,
      });

      const data = {
        partnerIds: this.state.partnerIds,
        ...values,
      };

      this.props.onInvitePartner(data);
    });
  };

  componentDidMount() {
    const { biddingPhaseQuotationDeadline } = this.props;

    if (biddingPhaseQuotationDeadline) {
      this.props.form.setFieldsValue({
        quotationDeadline: moment(biddingPhaseQuotationDeadline),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    const fieldNames = ["quotationDeadline"];

    if (this.props.errors != prevProps.errors && this.props.errors) {
      Object.keys(this.props.errors).map((field) => {
        if (fieldNames.indexOf(field) > -1) {
          this.props.form.setFields({
            [field]: {
              value: this.state.formState[field],
              errors: [new Error(this.props.errors[field].errorMessage)],
            },
          });
        }
      });
    }
  }

  onChangeCheckboxToggle(value) {
    console.log({ value: value });

    this.setState((prevState) => {
      let arr = prevState.partnerIds;

      if (arr.indexOf(value) > -1) {
        arr = prevState.partnerIds.filter((item) => item !== value);
      } else {
        arr = [...arr, value];
      }

      return {
        partnerIds: arr,
        partnerIdsValidation: {},
      };
    });
  }

  render() {
    const { task = {}, partner = {} } = this.props;

    const { getFieldDecorator } = this.props.form;

    return (
      <CardBorder>
        <h1 className="title-bpool medium">
          {I18n.get("Convidar para Proposta")}
        </h1>

        <p style={{ marginTop: "3rem" }}>
          {I18n.get(
            "O Parceiro receberá um convite e deverá enviar uma proposta até o prazo limite abaixo."
          )}
        </p>

        <br />

        <div className="form">
          <Form>
            <Form.Item label={I18n.get("Data Limite")}>
              {getFieldDecorator("quotationDeadline", {
                rules: [
                  {
                    required: true,
                    message: I18n.get("Por favor informe a Data Limite."),
                  },
                ],
              })(
                <FieldDataPicker
                  placeholder={I18n.get("Selecione")}
                  format={dateFormatList}
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>

            <br />

            <Form.Item {...this.state.partnerIdsValidation}>
              <div>
                {this.props.partners &&
                  this.props.partners.map((partner) => {
                    const viewCard =
                      this.props.partnerIdsOptedIn &&
                      this.props.partnerIdsOptedIn.length > 0 &&
                      this.props.partnerIdsOptedIn.find((a) => a == partner.id);

                    if (!viewCard) return null;

                    const invitation = partner.projectInvitations.find(
                      (a) => a.projectId == this.props.projectId
                    );

                    const checked =
                      this.state.partnerIds &&
                      this.state.partnerIds.find(
                        (partnerId) => partnerId == partner.id
                      );

                    return (
                      <div key={"container_partner_" + partner.partnerId}>
                        <Checkbox
                          checked={checked}
                          onChange={(e) =>
                            this.onChangeCheckboxToggle(partner.partnerId)
                          }
                          disabled={invitation?.accepted === false}
                        >
                          {partner.company.name}
                        </Checkbox>
                      </div>
                    );
                  })}
              </div>
            </Form.Item>

            <br />
            <br />

            <Button
              onClick={this.onInvitePartner.bind(this)}
              loading={task && task.busy}
              className="button bt-bpool"
            >
              {I18n.get("Enviar Convite")}
            </Button>
          </Form>
        </div>
      </CardBorder>
    );
  }
}

export default Form.create({})(PartnerInviteForm);
