import React from 'react';

import { Icon } from 'antd';


const Highlight = ({ title, description }) => {

    return (
        <div className="alert-bpool">
            <p>
                <strong><Icon type="bulb" /> {title} </strong> &nbsp;
                {description}
            </p>
        </div>
    )
}

export default Highlight;