import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import {
    Button,
    Icon,
    Row,
    Col,
    Input,
    Radio,
    Table,
    DatePicker,
    Form
} from 'antd';

import RatingPreview from '../components/RatingPreview'

import { TitleMiddle, TitleMedium } from '../components/Titles';
import { CardBorder } from '../components/Cards';
import ResumeTable from '../components/ResumeTable';

import icoLuz from '../../../image/ico-luz-2.png';
import icoRelogio from '../../../image/ico-relogio.png';
import icoCalc from '../../../image/ico-calculadora.png';

const { TextArea } = Input;

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];



const MainInfos = styled.div`
  color: #353535;

  .GroupTable {
      display: block;
  }

  .TableOption {
      margin-top: 30px;
  }

    .BtnView {
        margin-top: 10px;
    }

    .TitleP {
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        margin-bottom: 5px;
    }

    .tagYellow {
        background: #fff291;
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        padding: 2px 7px;
    }

    .tagPink {
        background: #ffe5e5;
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 7px;
    }

    .RowTitle {
        margin-bottom: 30px;
    }

    .BoxIcon {
        padding-left: 70px;
        background: no-repeat top left;
        min-height: 100px;

        &.Status {
            background-image:url(${icoLuz});
        }

        &.Relogio {
            background-image:url(${icoRelogio});
        }

        &.Proposta {
            background-image:url(${icoCalc});
            padding-left: 90px;
        }

        &.Vazio {
            padding-left: 90px;
        }
    }


  .ResumeIdea {
      background: #ececec;
      padding: 40px 0px;
      margin-top: 60px;
      color: #353535;
      margin-bottom: 50px;

      .TitleBorder {
          color: #3d3d3d;
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          border-left: 8px #bab1d1 solid;
          padding-left: 30px;
          margin-bottom: 30px;
      }



      .ListLinks {
          li {
              margin: 0 0 5px;

              a {
                  color: #3d3d3d;
                  font-size: 15px;
                  i {
                      margin-right: 5px;
                  }

                  &:hover, &:focus {
                      color: #bab1d1;
                  }
              }
          }
      }

      .RowSlide {
          margin-bottom: 30px;

      }

      .ItemImg {
          margin: 0 1px;
      }

      h2 {
          font-size: 25px;
          font-weight: 700;
      }

      p {
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 20px;
      }
      .Redes {
          margin-bottom: 20px;
          li {
              display: inline-block;
              font-size: 13px;
              font-weight: 700;
              line-height: 37px;
              padding-right: 30px;
              position: relative;

              &:last-child  {
                  &::after {
                      display: none;
                  }
              }

              &::after {
                  content: "/";
                  position: absolute;
                  right: 12px;
                  top: 0;
              }
          }
      }
  }
`;

const RowSubmit = styled(Row)`
  button {
      margin-right: 20px;
  }
`;


const columnsIdea = [
    {
        title: I18n.get("Tipo"),
        dataIndex: 'tipo',
        key: 'tipo',
        render: (title, record) => (
            <Radio value={record.money}>{title}</Radio>
        )
    },
    {
        title: I18n.get("Valor"),
        dataIndex: 'valor',
        key: 'valor'
    },
    {
        title: I18n.get("Info"),
        dataIndex: 'info',
        key: 'info',
        render: (info, record) => (
            <Fragment>
                <a data-tip='' data-for={`info-${record.key}`}>
                    <Icon type="question-circle" />
                </a>
                <ReactTooltip id={`info-${record.key}`}>
                    <div className="tooltip-bp-content">

                        <p>{info}{record.key}</p>
                    </div>
                </ReactTooltip>
            </Fragment>
        )
    }
];

const columnsProduction = [
    {
        title: I18n.get("Tipo"),
        dataIndex: 'tipo',
        key: 'tipo',
        render: (title, record) => (
            <Radio value={record.money}>{title}</Radio>
        )
    },
    {
        title: I18n.get("Valor"),
        dataIndex: 'valor',
        key: 'valor'
    },
    {
        title: I18n.get("Info"),
        dataIndex: 'info',
        key: 'info',
        render: (info, record) => (
            <Fragment>
                <a data-tip='' data-for={`info-${record.key}`}>
                    <Icon type="question-circle" />
                </a>
                <ReactTooltip id={`info-${record.key}`}>
                    <div className="tooltip-bp-content">

                        <p>{info}{record.key}</p>
                    </div>
                </ReactTooltip>
            </Fragment>
        )
    }
];

const dataValuesTypes = [
    {
        key: '1',
        tipo: 'Campanha',
        valor: 'R$ 50.000,00',
        info: "teste",
        money: 50000.00
    },
    {
        key: '2',
        tipo: 'Experiência',
        valor: 'R$ 20.000,00',
        info: "teste",
        money: 20000.00
    },
    {
        key: '3',
        tipo: 'Post',
        valor: 'R$ 5.000,00',
        info: "teste",
        money: 5000.00
    }
];

const dataProduct = [
    {
        key: '4',
        tipo: 'Platinum',
        valor: 'R$ 20.000,00',
        info: "teste",
        money: 20000.00
    },
    {
        key: '5',
        tipo: 'Gold',
        valor: 'R$ 15.000,00',
        info: "teste",
        money: 15000.00
    },
    {
        key: '6',
        tipo: 'Silver',
        valor: 'R$ 10.000,00',
        info: "teste",
        money: 10000.00
    },
    {
        key: '7',
        tipo: 'Bronze',
        valor: 'R$ 5.000,00',
        info: "teste",
        money: 5000.00
    }
];




class ProposalModalClient extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formState: {},
        };
    }



    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {


                this.setState({
                    formState: values
                })

                const dataForm = {
                    ...values,
                    jobOffer: this.props.jobOffert,
                    prefix: this.props.prefix,
                    whatIfIdeaId: this.props.whatIfIdeaId,
                    whatIfIdeaOfferId: this.props.whatIfIdeaOfferId
                }

                this.props.declineOffer(dataForm);
            }
        });
    };

    renderDataForm = () => {
        const { getFieldDecorator } = this.props.form;
        const { originalOffer } = this.props;

        return (
            <Fragment>
                <CardBorder
                    Bcolor="#fff290"
                >
                    <Row className="RowTitle">
                        <Col sm={24}>
                            <TitleMedium>Indique uma nova data:</TitleMedium>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={24}>
                            <Row>
                                <Col sm={10}>
                                    <div className="BoxIcon Relogio">
                                        <p className="TitleP">Data da entrega original:</p>
                                        <p>
                                            <span className="tagYellow">{originalOffer.deadlineDate}</span>
                                            <span className="tagYellow">{originalOffer.deadlineHour}h</span>
                                        </p>
                                    </div>
                                </Col>
                                <Col sm={14}>
                                    <Col sm={14}>
                                        <Form.Item label="Até a Data:">
                                            {getFieldDecorator('deadlineDate', {
                                                rules: [{ required: false, message: 'ops!' }],
                                            })(
                                                <DatePicker format={dateFormatList} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col sm={10}>
                                        <Form.Item label="Até o horário:">
                                            {getFieldDecorator('deadlineHour', {
                                                rules: [{ required: false, message: 'ops!' }],
                                            })(
                                                <Input placeholder="Ex.: 00:00" />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBorder>
            </Fragment>
        );
    }

    renderIdeaForm = () => {
        const { getFieldDecorator } = this.props.form;
        const { originalOffer } = this.props;
        return (
            <Fragment>
                <CardBorder
                    Bcolor="#fff290"
                >
                    <Row className="RowTitle">
                        <Col sm={24}>
                            <TitleMedium>Valor da ideia:</TitleMedium>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24}>
                            <Row gutter={1}>
                                <div className="">
                                    <Col sm={24}>
                                        <p className="TitleP">Valor da ideia(Original):</p>
                                        <p>
                                            <span className="tagYellow">R$ {originalOffer.jobOfferPriceIdea}</span>
                                        </p>

                                        <Form.Item label="Sugestão de valor (Ideia)" className="TableOption">

                                            {getFieldDecorator('jobOfferPriceIdea', {
                                                rules: [{ required: false, message: 'ops!' }],
                                            })(
                                                <Radio.Group className="GroupTable">
                                                    <Table
                                                        pagination={false}
                                                        columns={columnsIdea}
                                                        scroll={{ x: 640 }}
                                                        dataSource={dataValuesTypes}
                                                    />
                                                </Radio.Group>
                                            )}

                                        </Form.Item>

                                    </Col>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </CardBorder>
            </Fragment>
        );
    }

    renderProductionForm = () => {
        const { getFieldDecorator } = this.props.form;
        const { originalOffer } = this.props;
        return (
            <Fragment>
                <CardBorder
                    Bcolor="#fff290"
                >
                    <Row className="RowTitle">
                        <Col sm={24}>
                            <TitleMedium>Valor da Produção:</TitleMedium>
                        </Col>
                    </Row>
                    <Row>
                        <div className="">
                            <Col sm={24}>
                                <p className="TitleP">Produção:</p>
                                <p>
                                    <span className="tagYellow">R$ {originalOffer.jobOfferPriceProduction}</span>
                                </p>

                                <Form.Item label="Sugestão de valor (Produção)" className="TableOption">

                                    {getFieldDecorator('jobOfferPriceProduction', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <Radio.Group className="GroupTable">
                                            <Table
                                                pagination={false}
                                                columns={columnsProduction}
                                                scroll={{ x: 640 }}
                                                dataSource={dataProduct}
                                            />
                                        </Radio.Group>
                                    )}

                                </Form.Item>


                            </Col>
                        </div>
                    </Row>
                </CardBorder>

            </Fragment>
        );
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;



        if (this.props.errorsOffers != prevProps.errorsOffers) {
            Object.keys(this.props.errorsOffers).map(field => {
                this.props.form.setFields({
                    [field]: {
                        value: this.state.formState[field],
                        errors: [new Error(this.props.errorsOffers[field].errorMessage)],
                    },
                });
            });
        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { display } = this.props;



        return (
            <MainInfos>
                <Form onSubmit={this.handleSubmit}>

                    {display && display == "justIdea" &&
                        <Fragment>
                            {this.renderIdeaForm()}
                        </Fragment>
                    }

                    {display && display == "ideaProduced" &&
                        <Fragment>
                            {this.renderIdeaForm()}
                            {this.renderProductionForm()}
                        </Fragment>
                    }

                    {display && display == "interestInProducing" &&
                        <Fragment>
                            {this.renderDataForm()}
                            {this.renderIdeaForm()}
                            {this.renderProductionForm()}
                        </Fragment>
                    }

                    <CardBorder
                        Bcolor="#cbede0"
                    >
                        <Row>
                            <Col sm={24}>
                                <TitleMedium>Justifique sua contraproposta:</TitleMedium>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <Form.Item>
                                    {getFieldDecorator('comment', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <TextArea rows={4} />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>
                    </CardBorder>

                    <CardBorder>
                        <RowSubmit>
                            <Col sm={24}>
                                <Button type="primary" shape="round" size="large">Cancelar</Button>
                                <Button type="primary" htmlType="submit" shape="round" size="large">Salvar</Button>
                            </Col>
                        </RowSubmit>
                    </CardBorder>



                </Form>
            </MainInfos >
        );
    }
}

const ProposalModalClientForm = Form.create({})(ProposalModalClient);

export default ProposalModalClientForm;
