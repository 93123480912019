import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { useNotifications } from '../../../../../hooks/Admin/useNotification/useNotification.hook';
import { Select, Table, Checkbox, Button, Tooltip } from 'antd';
import { Loading } from '../Loading';
import InfoIco from './icons/info.png';
import ErrorIco from './icons/error.png';

import * as S from './styles';

export const FormUser = ({
  loaderUsers,
  usersData,
  loadUsers,
  contextsData,
  regionsData,
  profilesData,
  clientsData,
  partnersData,
  triggersData,
  loaderFilters,
  handleOpenModal,
  loadersUsers,
  setLoadersUsers,
  editingUser,
  setEditingUser,
  name,
  setName,
  mail,
  setMail,
}) => {
  const { Option } = Select;
  const { postUser, putUser } = useNotifications();
  const [dataWarnings, setDataWarnings] = useState([]);
  const [allEmailSelect, setAllEmailSelect] = useState(false);
  const [allSlackSelect, setAllSlackSelect] = useState(false);

  const [selectedContexts, setSelectedContexts] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);

  let mount = (arr, prop) =>
    arr.map((role) => {
      if (role[prop] === true) {
        return role[prop];
      }
    });

  let checker = (arr) => arr.every((v) => v === true);

  useEffect(() => {
    if (triggersData?.length) {
      setDataWarnings(triggersData);

      const arrEmails = mount(triggersData, 'email');
      const arrSlacks = mount(triggersData, 'slack');

      setAllEmailSelect(checker(arrEmails));
      setAllSlackSelect(checker(arrSlacks));
    }
  }, [triggersData]);

  const onChange = (e, column, key, name) => {
    const finded = dataWarnings.find((item) => item.key === key);
    const filteredItems = dataWarnings.filter((item) => item.key !== key);

    const data = [
      ...filteredItems,
      {
        ...finded,
        [column]: e.target.checked,
      },
    ];
    data.sort((a, b) => (a.key > b.key) - (a.key < b.key));

    setDataWarnings(data);
  };

  const columns = [
    {
      title: I18n.get('Gatilho'),
      key: 'name',
      render: (record) => (
        <S.WrapperNameAndTooltip>
          <S.TheName>{record.name}</S.TheName>
          <S.TooltipDescription>
            <Tooltip placement="right" title={record.description}>
              <img alt="info item" src={InfoIco} />
            </Tooltip>
          </S.TooltipDescription>
        </S.WrapperNameAndTooltip>
      ),
    },
    {
      title: I18n.get('Email'),
      key: 'email',
      render: (record) => (
        <Checkbox
          checked={record.email}
          onChange={(e) => onChange(e, 'email', record.key, record.name)}
        />
      ),
    },
    {
      title: I18n.get('Slack'),
      key: 'slack',
      render: (record) => (
        <Checkbox
          checked={record.slack}
          onChange={(e) => onChange(e, 'slack', record.key, record.name)}
        />
      ),
    },
  ];

  const mountRolesObject = (roles) => {
    const rolesObj = {};

    roles.map(
      (role) =>
        (rolesObj[role.triggerName] = { email: role.email, slack: role.slack })
    );

    return rolesObj;
  };

  const errorSend = async (response) => {
    const msgName = response?.errors?.name
      ? response?.errors?.name.errorMessage
      : '';
    const msgEmail = response?.errors?.email
      ? response?.errors?.email.errorMessage
      : '';
    handleOpenModal({
      title: I18n.get('Erro'),
      type: 'error',
      message: (
        <S.ListErrors>
          {msgName ? (
            <li>
              <img alt="edit user" src={ErrorIco} />
              <span>{msgName}</span>
            </li>
          ) : null}
          {msgEmail ? (
            <li>
              <img alt="edit user" src={ErrorIco} />
              <span>{msgEmail}</span>
            </li>
          ) : null}
        </S.ListErrors>
      ),
      id: '',
    });
  };

  const handleClearInputs = () => {
    setEditingUser({ id: '' });
    setName('');
    setMail('');
    setSelectedContexts([]);
    setSelectedRegions([]);
    setSelectedProfiles([]);
    setSelectedClients([]);
    setSelectedPartners([]);
    setDataWarnings(triggersData);

    const arrEmails = mount(triggersData, 'email');
    const arrSlacks = mount(triggersData, 'slack');

    setAllEmailSelect(checker(arrEmails));
    setAllSlackSelect(checker(arrSlacks));
  };

  const handleAddUser = async (objToSend) => {
    try {
      setLoadersUsers({ ...loadersUsers, add: true });
      const response = await postUser(objToSend);

      if (response.success) {
        await loadUsers();
        handleClearInputs();
        handleOpenModal({
          title: I18n.get('Sucesso'),
          type: 'success',
          message: `${I18n.get('Usuário cadastrado com sucesso!')}`,
          id: '',
        });
      } else {
        await errorSend(response);
      }
    } catch (error) {
      console.log('Error in add User', error);
    } finally {
      setLoadersUsers({ ...loadersUsers, add: false });
    }
  };

  const handleSaveUser = async (objToSend) => {
    try {
      setLoadersUsers({ ...loadersUsers, add: true });
      const response = await putUser({ id: editingUser.id, ...objToSend });

      if (response.success) {
        await loadUsers();
        handleClearInputs();
        handleOpenModal({
          title: I18n.get('Sucesso'),
          type: 'success',
          message: `${I18n.get('Usuário alterado com sucesso!')}`,
          id: '',
        });
      } else {
        await errorSend(response);
      }
    } catch (error) {
      console.log('Error in put User', error);
    } finally {
      setLoadersUsers({ ...loadersUsers, add: false });
      setEditingUser({ id: '' });
    }
  };

  const handleSave = async () => {
    const roles = mountRolesObject(dataWarnings);

    const objToSend = {
      name,
      email: mail,
      triggers: roles,
      filters: {
        regions: selectedRegions,
        clientIds: selectedClients,
        partnerIds: selectedPartners,
        profiles: selectedProfiles,
        contexts: selectedContexts,
      },
    };

    editingUser.id ? handleSaveUser(objToSend) : handleAddUser(objToSend);
  };

  const handleSelectInput = (e, select) => {
    if (select === 'regions') {
      setSelectedRegions(e);
    }
    if (select === 'profiles') {
      setSelectedProfiles(e);
    }
    if (select === 'clients') {
      setSelectedClients(e);
    }
    if (select === 'partners') {
      setSelectedPartners(e);
    }
    if (select === 'contexts') {
      setSelectedContexts(e);
    }
  };

  const handleUserEditInputs = (editingUser) => {
    setName(editingUser?.name);
    setMail(editingUser?.email);
    setSelectedRegions(
      editingUser?.filters?.regions?.length ? editingUser?.filters?.regions : []
    );
    setSelectedProfiles(
      editingUser?.filters?.profiles?.length
        ? editingUser?.filters?.profiles
        : []
    );
    setSelectedClients(
      editingUser?.filters?.clientIds?.length
        ? editingUser?.filters?.clientIds
        : []
    );
    setSelectedPartners(
      editingUser?.filters?.partnerIds?.length
        ? editingUser?.filters?.partnerIds
        : []
    );
    setSelectedContexts(
      editingUser?.filters?.contexts?.length
        ? editingUser?.filters?.contexts
        : []
    );

    function lowercaseFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    }

    const rolesUserEdit = triggersData?.map((trigger) => {
      const ind = lowercaseFirstLetter(trigger?.triggerName);
      const triggerEdit = editingUser?.triggers[ind];

      return {
        ...trigger,
        email: triggerEdit?.email,
        slack: triggerEdit?.slack,
      };
    });

    setDataWarnings(rolesUserEdit);

    const arrEmails = mount(rolesUserEdit, 'email');
    const arrSlacks = mount(rolesUserEdit, 'slack');

    setAllEmailSelect(checker(arrEmails));
    setAllSlackSelect(checker(arrSlacks));
  };

  useEffect(() => {
    if (editingUser.id) {
      handleUserEditInputs(editingUser);
    }
  }, [editingUser]);

  const handleAllEmails = () => {
    const allEmailsSelecteds = dataWarnings.map((item) => {
      return {
        ...item,
        email: !allEmailSelect,
      };
    });

    setAllEmailSelect(!allEmailSelect);
    setDataWarnings(allEmailsSelecteds);
  };

  const handleAllSlacks = () => {
    const allSlacksSelecteds = dataWarnings.map((item) => {
      return {
        ...item,
        slack: !allSlackSelect,
      };
    });

    setAllSlackSelect(!allSlackSelect);
    setDataWarnings(allSlacksSelecteds);
  };

  return (
    <>
      <S.Title>
        <p>
          {editingUser.id
            ? I18n.get('Editar usuário')
            : I18n.get('Cadastrar novo usuário')}
        </p>
      </S.Title>

      <S.FormWrapper>
        <S.Form>
          <S.ContainerInputs>
            <S.InputGroup style={{ marginLeft: 0 }}>
              <input
                type="text"
                placeholder={I18n.get('Nome')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </S.InputGroup>
            <S.InputGroup>
              <input
                type="text"
                placeholder={I18n.get('Email')}
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
            </S.InputGroup>
          </S.ContainerInputs>
        </S.Form>
      </S.FormWrapper>

      <S.Wrapper>
        <S.Label>{I18n.get('Regiões')}</S.Label>
        {loaderFilters ? (
          <Loading />
        ) : (
          <Select
            mode="multiple"
            showArrow
            placeholder={I18n.get('Regiões')}
            defaultValue={[]}
            value={selectedRegions}
            style={{ width: '100%' }}
            options={regionsData}
            onChange={(e) => handleSelectInput(e, 'regions')}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {regionsData.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        )}
      </S.Wrapper>

      <S.Wrapper>
        <S.Label>{I18n.get('Perfil')}</S.Label>
        {loaderFilters ? (
          <Loading />
        ) : (
          <Select
            mode="multiple"
            showArrow
            placeholder={I18n.get('Perfil')}
            defaultValue={[]}
            value={selectedProfiles}
            style={{ width: '100%' }}
            options={profilesData}
            onChange={(e) => handleSelectInput(e, 'profiles')}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {profilesData.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        )}
      </S.Wrapper>

      <S.Wrapper>
        <S.Label>{I18n.get('Contexto')}</S.Label>
        {loaderFilters ? (
          <Loading />
        ) : (
          <Select
            mode="multiple"
            showArrow
            placeholder={I18n.get('Contexto')}
            defaultValue={[]}
            value={selectedContexts}
            style={{ width: '100%' }}
            options={contextsData}
            onChange={(e) => handleSelectInput(e, 'contexts')}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {contextsData.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        )}
      </S.Wrapper>

      <S.Wrapper>
        <S.Label>{I18n.get('Clientes')}</S.Label>
        {loaderFilters ? (
          <Loading />
        ) : (
          <Select
            mode="multiple"
            showArrow
            placeholder={I18n.get('Clientes')}
            defaultValue={[]}
            value={selectedClients}
            style={{ width: '100%' }}
            options={clientsData}
            onChange={(e) => handleSelectInput(e, 'clients')}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {clientsData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        )}
      </S.Wrapper>

      <S.Wrapper>
        <S.Label>{I18n.get('Parceiros')}</S.Label>
        {loaderFilters ? (
          <Loading />
        ) : (
          <Select
            mode="multiple"
            showArrow
            placeholder={I18n.get('Parceiros')}
            defaultValue={[]}
            value={selectedPartners}
            style={{ width: '100%' }}
            options={partnersData}
            onChange={(e) => handleSelectInput(e, 'partners')}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {partnersData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        )}
      </S.Wrapper>

      <S.WrapperTable>
        <S.ContentSelectsAll>
          <div />
          <div>
            <button onClick={() => handleAllEmails()}>
              {allEmailSelect ? 'Desmarcar todos' : 'Selecionar todos'}
            </button>
          </div>
          <div>
            <button onClick={() => handleAllSlacks()}>
              {allSlackSelect ? 'Desmarcar todos' : 'Selecionar todos'}
            </button>
          </div>
        </S.ContentSelectsAll>
        <Table
          dataSource={dataWarnings}
          columns={columns}
          loading={loaderFilters}
          pagination={false}
        />
        <S.WrapperButtonSave
          style={{
            justifyContent: editingUser.id ? 'space-between' : 'flex-end',
          }}
        >
          {editingUser.id ? (
            <Button
              type="secondary"
              onClick={() => handleClearInputs()}
              style={{ width: editingUser.id ? 'calc(50% - 30px)' : '50%' }}
            >
              {I18n.get('Cancelar')}
            </Button>
          ) : null}

          <Button
            type="primary"
            loading={loadersUsers.add}
            onClick={handleSave}
            style={{ width: 'calc(50% - 30px)' }}
          >
            {editingUser.id ? I18n.get('Alterar') : I18n.get('Salvar')}
          </Button>
        </S.WrapperButtonSave>
      </S.WrapperTable>
    </>
  );
};
