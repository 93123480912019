import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ListScope from "./ListScope";
import ListSearch from "./ListSearch";
import ViewItem from "./ViewItem";



class Wikis extends Component {
    render() {
        return (
            <Switch>
                <Route path="/parceiro/wikis/resultados" component={ListSearch} />
                <Route exact path="/parceiro/wikis/:scope/" component={ListScope} />
                <Route exact path="/parceiro/wikis/:scope/:categoryId" component={ListScope} />
                <Route exact path="/parceiro/wikis/:scope/:categoryId/:contentId" component={ViewItem} />


                <Route path="/cliente/wikis/resultados" component={ListSearch} />
                <Route exact path="/cliente/wikis/:scope/" component={ListScope} />
                <Route exact path="/cliente/wikis/:scope/:categoryId" component={ListScope} />
                <Route exact path="/cliente/wikis/:scope/:categoryId/:contentId" component={ViewItem} />



            </Switch>
        );
    }
}

export default Wikis;