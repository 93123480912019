import React, { useState } from 'react';
import { UnloggedWithStepbar } from '../../components/UnloggedWithStepbar';
import { useRegistrationFlowLeader } from '../../../../hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook';
import { urlsLeader as urls } from '../../utils/stepsUrls';

import { ContentPage } from './content-page';

const PAGE_INDEX = 18;

export const AboutYou = () => {
  const { getStepBarLeader } = useRegistrationFlowLeader();

  const [changeInfo, setChangeInfo] = useState(false);

  return (
    <UnloggedWithStepbar
      pageIndex={PAGE_INDEX}
      statusBarStepsClickable
      statusChangeInfo={changeInfo}
      hookGetItems={getStepBarLeader}
      stepbarId="stepbar-leader"
      urls={urls}
      contextStepBar="leader"
    >
      <ContentPage onChangeInfo={(info) => setChangeInfo(info)} />
    </UnloggedWithStepbar>
  );
};
