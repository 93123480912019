import React, { PureComponent } from "react";
import { I18n } from 'aws-amplify';
import { Icon, Row, Col, Table, Button } from 'antd';

import { CardBorder } from "./Cards";
import { TitleSimple, TitleMiddle } from "./Titles";

import NotRegistered from "./NotRegistered";

import styled from "styled-components";


const MainTable = styled(Table)`

   .btndonwload {
    position: relative;
    color: #6d6d6d;
    left: 5px;
    font-size: 13px;

    span {
        float: left;
    }

    i {
        font-style: normal;
        margin-right: 5px;
        position: relative;
        top: 5px;
    }

    &:hover {
        color: #1883ff;
    }
   }

    .btExcluir {
        text-align: right;
    }

    tr:hover {
    }
    
    td {
        background: #f7f7f7!important;
        border-bottom: solid 1px #ccc!important;
    }
    .ant-table table {
        background: #000!important;
        border: 0!important;
        
        tr:last-child  {
            td {
                border: 0!important;
            }
        }
    }
    .ant-table-thead {
        display: none;
    }
`


class ItemFilesMacro extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {

        const columns = [
            // {
            //     dataIndex: 'null',
            //     key: 'null',
            //     width:160
            // },
            // {
            //     title: I18n.get("Nome"),
            //     dataIndex: 'name',
            //     key: 'name',
            // },
            // {
            //     title: I18n.get("Data"),
            //     dataIndex: 'date',
            //     key: 'date',
            // },
            {
                title: I18n.get("Arquivos"),
                dataIndex: 'files',
                key: 'files',
                render: (files, record) => {
                    return(
                        <a download className="btndonwload" href={record.item.url}>
                            <span class="material-icons">
                                download
                            </span>
                            <i>{record.item.name}</i>
                        </a>
                    )
                }
            },
            {
                title: I18n.get("Entrega do parceiro"),
                dataIndex: 'deliveryPartner',
                key: 'deliveryPartner'
            },
            {
                title: I18n.get("Aprovação do cliente"),
                dataIndex: 'approval',
                key: 'approval',
            },
            {
                title: '',
                dataIndex: 'item',
                key: 'item',
                render: (text, record) => {
                    if(this.props.username == record.item.uploadedByUsername){

                        const data = {
                            milestoneId: record.milestoneId,
                            fileId: record.item.id
                        }

                        if(this.props.disabled){

                            return (
                                <p className="btExcluir">
                                <a onClick={() => this.props.deleteFile(data)}>
                                    <Icon type="delete" theme="filled" /> {I18n.get("Excluir")}
                                </a>
                            </p>
    
                            )
                        }
                    }
                }
            },
            
        ];
        
        let dataSource = this.props.item && this.props.item.files.map((item,index) => {
            return(
                {
                    key: index,
                    item: item,
                    milestoneId: this.props.item.milestoneId
                }
            )
        });


        return (
           <div className="tableMilestoneFiles">
               <MainTable 
                locale={{ emptyText: I18n.get('Sem arquivos') }} 
                dataSource={dataSource} 
                columns={columns} 
                pagination={false}
                />
           </div>
        );
    }
}

export default ItemFilesMacro;
