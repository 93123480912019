import styled from "styled-components";

export const Wrapper = styled.div`
  input[type="checkbox"] {
    position: relative;
    width: 52px;
    height: 26px;
    -webkit-appearance: none;
    appearance: none;
    background: #ccc;
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
    box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
  }

  input[type="checkbox"]::before {
    content: "";
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid;
    border-color: #ccc;
    transition: 0.5s;
  }

  input[type="checkbox"]:checked::before {
    transform: translateX(100%);
    background: #fff;
  }

  input[type="checkbox"]:checked {
    background: #1883FF;
  }
`;
