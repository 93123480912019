import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Modal, Icon, notification } from "antd";
import { useExtras } from "../../../../../hooks/useExtras/useExtras.hook";
import { useUpload } from "../../../../../hooks/useUpload/useUpload.hook";
import Content from "../../../../../components/UI/Content";
import Main from "../../../../../components/UI/Main";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import { H2Border } from "../../../../../components/UI/Titles";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import { TabsProject } from "../../../../../components/UI/TabsProject";
import { SelectOne } from "../../../../../components/UI/SelectOne";
import { Input } from "../../../../../components/UI/Input";
import { CurrencyInput } from "../../../../../components/UI/CurrencyInput";
import { InputMask } from "../../../../../components/UI/InputMask";
import { Textarea } from "../../../../../components/UI/Textarea";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { UploadInternal } from "../../../../../components/UI/UploadInternal";
import { FormatCurrency } from "../../../../../components/UI/FormatCurrency";
import { AttachmentsItem } from "../../../../../components/UI/AttachmentsItem";
import Loading from "../../../../../components/UI/Loading";
import {
  hasError,
  clearErrorInput,
} from "../../../../RegistrationFlow/utils/errorsInput";
import { Tabs } from "../../components/Tabs";

import TabsItemsClient from "../../../urls/urlsClient.json";
import TabsItemsClientDefinition from "../../../urls/urlsClientDefinition.json";
import TabsItemsPartner from "../../../urls/urlsPartner.json";
import * as S from "./styles";

export const NewExtraDetails = () => {
  let params = useParams();
  let history = useHistory();
  const { getProjectById } = useProjects();
  const auth = useSelector((state) => state.auth);
  const { getExtraDetails, putDetailsExtra, getExtraTypes } = useExtras();
  const { uploadFile } = useUpload();
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetails, setDataDetails] = useState({});
  const [inputsForm, setInputsForm] = useState({});
  const [errorsInputs, setErrorsInputs] = useState([]);
  const [typeExtras, setTypeExtras] = useState(null);
  const [dataTypes, setDataTypes] = useState([]);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [files, setFiles] = useState([]);
  const [showModalEditDetails, setShowModalEditDetails] = useState(false);
  const [isLoadingSavingDetails, setIsLoadingSavingDetails] = useState(false);
  const [project, setProject] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const onChangeTypesExtrasSelect = (type) => {
    setTypeExtras(type);
  };

  const renderText = ({ label, value }) => {
    return (
      <S.TextInfo>
        <strong>{`${label}: `}</strong>
        <span>{value || "---"}</span>
      </S.TextInfo>
    );
  };

  const handleGoToBudget = () => {
    const urlPartner = `/projetos/${params?.projectId}/extras/${params?.extraId}/budget`;
    const urlClient = `/projetos/${params?.projectId}/extras`;

    history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
  };

  const handleBack = () => {
    const urlPartner = `/projetos/${params?.projectId}/extras`;
    const urlClient = `/projetos/${params?.projectId}/extras`;

    history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
  };

  const handleNext = () => {
    const urlPartner = `/projetos/${params?.projectId}/extras/new/budget`;
    const urlClient = `/projetos/${params?.projectId}/extras/new/budget`;

    history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
  };

  const loadInit = async () => {
    setIsLoading(true);
    try {
      const response = await getExtraDetails({
        projectId: params?.projectId,
        extraId: params?.extraId,
      });

      if (response?.statusCode === 200) {
        setDataDetails(response?.data);
        setTypeExtras(response?.data?.extraType);

        if (auth?.is_client_or_client_user) {
          setShowModalEditDetails(response?.data?.status === 0 ? true : false);
        }
      }
      const responseTypes = await getExtraTypes();
      const types = responseTypes?.data?.map((item) => ({
        code: Number(item?.code),
        label: item?.label,
        description: item?.description,
      }));
      setDataTypes(types);

      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });
      if (responseProject?.success) {
        setProject(responseProject?.data);
      }
    } catch (error) {
      notification.error({
        message: I18n.get("Erro!"),
        description: I18n.get(
          "Erro ao carregar as informações, tente novamente."
        ),
        duration: 3,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (auth) {
      loadInit();
    }
  }, [auth]);

  const loadEditInfosInputs = () => {
    setInputsForm({
      ...inputsForm,
      extraName: dataDetails?.extraName,
      fee: `${dataDetails?.bpCommissionPerc}%`,
      availableBudget: dataDetails?.workingBudget,
      budget: dataDetails?.budget,
      dueDate: dataDetails?.dueDate,
      description: dataDetails?.description,
      comments: dataDetails?.comments,
    });

    setTypeExtras(dataDetails?.extraType);
  };

  const toggleAccordion = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    loadEditInfosInputs();
  }, [dataDetails]);

  const renderForm = () => {
    if (isLoading) {
      return (
        <div style={{ height: 500 }}>
          <Loading text={I18n.get("Loading...")} sizeText={14} sizeIcon={16} />
        </div>
      );
    } else {
      return (
        <>
          <Row>
            <Col xs={24} sm={8}>
              <Input
                placeholder={I18n.get("Name of Extra")}
                label
                required
                value={inputsForm.extraName}
                onChange={(value) => onChangeInputForm("extraName", value)}
                error={hasError({ arr: errorsInputs, field: "extraName" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "extraName",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </Col>
            <Col xs={24} sm={8}>
              <SelectOne
                options={dataTypes}
                value={typeExtras}
                onChange={(value) => onChangeTypesExtrasSelect(value)}
                placeholder={I18n.get("Type of Extra")}
                error={hasError({ arr: errorsInputs, field: "extraType" })}
              />
            </Col>
            <Col xs={24} sm={8}>
              <CurrencyInput
                value={inputsForm.budget}
                onChange={(value) => onChangeInputForm("budget", value)}
                currency={dataDetails?.currency}
                placeholder={I18n.get("Budget")}
                error={hasError({ arr: errorsInputs, field: "budget" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "budget",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </Col>
            <Col xs={24} sm={8}>
              <Input
                placeholder={I18n.get("Platform Fee")}
                label
                disabled={true}
                value={inputsForm?.fee !== "undefined%" ? inputsForm?.fee : ""}
                onChange={(value) => onChangeInputForm("fee", value)}
                error=""
              />
            </Col>
            <Col xs={24} sm={8}>
              <CurrencyInput
                value={inputsForm.availableBudget}
                onChange={(value) => true}
                currency={dataDetails?.currency}
                placeholder={I18n.get("Available Budget")}
                disabled
              />
            </Col>
            <Col xs={24} sm={8}>
              <InputMask
                id="deliveryDate"
                label={I18n.get("Delivery date")}
                defaultValue={inputsForm?.dueDate}
                onChange={(value) =>
                  onChangeInputForm("dueDate", value?.formattedValue)
                }
                mask="##/##/####"
                error={hasError({ arr: errorsInputs, field: "dueDate" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "dueDate",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24}>
              <S.Text strongText style={{ marginBottom: 15 }}>
                {I18n.get("Description")}
              </S.Text>
            </Col>
            <Col xs={24} sm={24}>
              <Textarea
                id="description"
                label={I18n.get("Description")}
                rows={4}
                maxLength={500}
                counterChar
                defaultValue={inputsForm.description}
                onChange={(value) => onChangeInputForm("description", value)}
                error={hasError({ arr: errorsInputs, field: "description" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "description",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24}>
              <S.Text strongText style={{ marginBottom: 15 }}>
                {I18n.get("Observation")}
              </S.Text>
            </Col>
            <Col xs={24} sm={24}>
              <Textarea
                id="comments"
                label={I18n.get("Observation")}
                rows={4}
                maxLength={500}
                counterChar
                defaultValue={inputsForm.comments}
                onChange={(value) => onChangeInputForm("comments", value)}
                error={hasError({ arr: errorsInputs, field: "comments" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "comments",
                    arr: errorsInputs,
                  });
                  setErrorsInputs(errorsArr);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24}>
              <UploadInternal
                multiple
                cbFiles={(items) => setFiles(items)}
                filesDefault={dataDetails?.files}
              />
            </Col>
          </Row>
        </>
      );
    }
  };

  const renderDetails = () => {
    return (
      <>
        <Row>
          <Col xs={24} sm={8}>
            {renderText({
              label: I18n.get("Tipo do Extra"),
              value: dataDetails?.extraTypeFmt,
            })}
          </Col>
          <Col xs={24} sm={8}>
            {renderText({
              label: I18n.get("Nome do Extra"),
              value: dataDetails?.extraName,
            })}
          </Col>
          <Col xs={24} sm={8}>
            {renderText({
              label: I18n.get("Data de entrega"),
              value: dataDetails?.dueDate,
            })}
          </Col>
          <Col xs={24} sm={24}>
            {renderText({
              label: I18n.get("Descrição"),
              value: dataDetails?.description,
            })}
          </Col>
          <Col xs={24} sm={24}>
            {renderText({
              label: I18n.get("Observação"),
              value: dataDetails?.comments,
            })}
          </Col>
          <Col xs={24} sm={8}>
            <S.TextInfo>
              <strong style={{ marginRight: 5 }}>{`${I18n.get(
                "Orçamento"
              )}:`}</strong>
              <FormatCurrency
                amount={dataDetails?.budget}
                currency={dataDetails?.currency}
              />
            </S.TextInfo>
          </Col>
          <Col xs={24} sm={8}>
            {renderText({
              label: I18n.get("Taxa de plataforma"),
              value: dataDetails?.bpCommissionPerc
                ? `${dataDetails?.bpCommissionPerc}%`
                : "",
            })}
          </Col>
          <Col xs={24} sm={8}>
            <S.TextInfo>
              <strong style={{ marginRight: 5 }}>{`${I18n.get(
                "Orçamento disponível"
              )}:`}</strong>
              {dataDetails?.workingBudget ? (
                <FormatCurrency
                  amount={dataDetails?.workingBudget}
                  currency={dataDetails?.currency}
                />
              ) : (
                <span>---</span>
              )}
            </S.TextInfo>
          </Col>
          <Col xs={24} sm={8}>
            {renderText({ label: I18n.get("Valor do Orçamento"), value: "" })}
          </Col>
          <Col xs={24} sm={8}>
            {renderText({
              label: I18n.get("Status do Orçamento"),
              value: dataDetails?.statusFmt,
            })}
          </Col>
          <Col xs={24} sm={8}>
            {dataDetails?.files?.length ? (
              <S.AccordionWrapper>
                <S.AccordionTitle onClick={toggleAccordion}>
                  <div className="title">
                    <strong>
                      {dataDetails?.files?.length > 1
                        ? I18n.get("Anexos")
                        : I18n.get("Anexo")}
                      {": "}
                    </strong>
                    {`${dataDetails?.files?.length} ${
                      dataDetails?.files?.length > 1
                        ? I18n.get("arquivos")
                        : I18n.get("arquivo")
                    }`}
                  </div>
                  <div className="title-arrow">
                    {isActive ? <Icon type="up" /> : <Icon type="down" />}
                  </div>
                </S.AccordionTitle>
                {isActive && (
                  <S.AccordionContent>
                    {dataDetails?.files?.length
                      ? dataDetails?.files?.map((item) => (
                          <AttachmentsItem
                            key={item?.id}
                            attachment={item}
                            style={{ marginTop: 5 }}
                          />
                        ))
                      : null}
                  </S.AccordionContent>
                )}
              </S.AccordionWrapper>
            ) : (
              <>
                {renderText({
                  label: I18n.get("Anexos"),
                  value: "---",
                })}
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 16 }}>
            <S.LastUpdate>
              <strong>{`${I18n.get("Última atualização")}:`}</strong>
              {dataDetails?.updatedOnFmt ? (
                <span>{dataDetails?.updatedOnFmt}</span>
              ) : (
                <span>---</span>
              )}
            </S.LastUpdate>
          </Col>
        </Row>
      </>
    );
  };

  const clearInputs = () => {
    setInputsForm({});
    setFiles({});
  };

  const handleCancelModal = () => {
    setShowModalEditDetails(false);
    clearInputs();
  };

  const handleOkModal = async () => {
    setIsLoadingSavingDetails(true);

    try {
      let filesToUpload = [];
      if (files?.length > 0) {
        await Promise.all(
          files.map(async (file) => {
            const result = await uploadFile({
              file: file,
              idUser: "usertestingnow",
              fieldId: `${
                auth?.is_client_or_client_user ? "cliente" : "parceiro"
              }-${params?.projectId}-po`,
            });
            filesToUpload.push(result);
          })
        );
      }
      const obj = {
        projectId: params?.projectId,
        extraId: params?.extraId,
        extraName: inputsForm?.extraName,
        extraType: typeExtras,
        budget: inputsForm?.budget,
        dueDate: inputsForm?.dueDate,
        description: inputsForm?.description,
        comments: inputsForm?.comments,
        files: filesToUpload,
      };
      const response = await putDetailsExtra(obj);

      if (response?.status === 200) {
        notification.success({
          message: I18n.get("Sucesso!"),
          description: I18n.get("Detalhes atualizados com sucesso."),
          duration: 3,
        });
        loadInit();
        handleCancelModal();

        history.push(
          `/projetos/${params?.projectId}/extras/${params?.extraId}/orcamento`
        );
      }
    } catch (error) {
      setErrorsInputs(error?.response?.data?.errors);
    } finally {
      setIsLoadingSavingDetails(false);
    }
  };

  const handleOpenModal = () => {
    setShowModalEditDetails(true);
    loadEditInfosInputs();
  };

  let tabs =
    project?.status === 7 ? TabsItemsClient : TabsItemsClientDefinition;

  let tabActive = project?.status === 7 ? 5 : 4;

  let disableTabs = [];

  if (!project?.partnerHasSubmittedDefinitions) {
    disableTabs = [3, 4];
  } else if (project?.partnerHasSubmittedDefinitions && project?.status < 6) {
    disableTabs = [3];
  } else {
    disableTabs = [];
  }

  return (
    <>
      <Content>
        <div className="container">
          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Extras")}</S.H4>
                      </S.TitleWithIcon>
                    </S.RowCenter>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <Tabs
                      extraId={params?.extraId}
                      disableFinalize={dataDetails?.hasBillings ? false : true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <S.Content>
                      {isLoading ? (
                        <S.ContentIsLoading>
                          <Loading
                            text={I18n.get("Loading informations...")}
                            sizeText={14}
                            sizeIcon={16}
                          />
                        </S.ContentIsLoading>
                      ) : auth?.is_client_or_client_user ? (
                        <>
                          {renderDetails()}
                          <ButtonBpool
                            text={I18n.get("Edit")}
                            theme="primary"
                            onClick={handleOpenModal}
                            disabled={dataDetails?.status === 0 ? false : true}
                          />
                        </>
                      ) : (
                        renderDetails()
                      )}
                    </S.Content>
                  </Col>
                </Row>

                {!auth?.is_client_or_client_user ? (
                  <Row>
                    <Col xs={24} sm={24}>
                      <S.ButtonsFooter>
                        <ButtonBpool
                          text={I18n.get("Go to Budget")}
                          theme="primary"
                          onClick={handleGoToBudget}
                        />
                      </S.ButtonsFooter>
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col xs={24} sm={24}>
                    <S.ButtonsFooter>
                      <ButtonBpool
                        text={I18n.get("Back")}
                        theme="secondary"
                        onClick={handleBack}
                      />
                    </S.ButtonsFooter>
                  </Col>
                </Row>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>

      <Modal
        title={I18n.get("Edit Details")}
        visible={showModalEditDetails}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        centered
        width={1200}
        footer={[
          <S.ModalButtons>
            <ButtonBpool
              text={I18n.get("Cancel")}
              theme="secondary"
              full={false}
              onClick={handleCancelModal}
            />
            <ButtonBpool
              text={I18n.get("Salvar e enviar para o parceiro")}
              theme="primary"
              full={false}
              loading={isLoadingSavingDetails}
              onClick={handleOkModal}
            />
          </S.ModalButtons>,
        ]}
      >
        {renderForm()}
      </Modal>
    </>
  );
};
