import React, { Fragment } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";

import { Button, Icon, Table, Card, Row, Col } from "antd";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";
import SubmitButtonField from "../form/submit-button-field";

import FormatCurrency from "../ui/format-currency";

class AssetResumeForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {
        isSubmit: false,
      },
      specialismMap: {},
      assetTypeMap: {},
      page: 1,
      task: {},
      createOk: false,
      projectId: "",
      errors: [],
    };
  }

  getData() {
    var data = {
      ...this.props.project,
      assetsCanBeExcluded: [],
      assets: this.props.cartAssets,
      partnerId: this.props.partner.partnerId,
      clientResponsableUsername: this.props.createdByUsername,

      // clientNotes: "",
      // isSubmit: true,
      // partnerNotes: "",
      // projectId: response.data.projectId,
    };

    return data;
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props
        .checkout2(this.getData())
        .then((response) => {
          if (response.errors) {
            this.setState({
              errors: response.errors,
              task: {},
            });
          }

          if (response && response.projectId) {
            this.setState({
              projectId: response.projectId,
            });

            setTimeout(() => {
              this.setState({
                createOk: response.projectId ? true : false,
              });
            }, 50);
          }
        })
        .catch((response) => {
          // this.setState({
          //   errors: error.data,
          //   task: {},
          // });
        });
    });
  }

  handleBundleBtnClick = () => {
    this.props.onBundleBtnClick();
  };

  render() {
    const applyDiscount = false;

    var specialismsBag = [];
    var assetTypesBag = [];

    this.props.bp.bundles &&
      this.props.bp.bundles.map((item) => {
        specialismsBag.push(...item.specialism);
        assetTypesBag.push(...item.assetType);
      });

    const formatter = new Intl.NumberFormat("pt", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });

    var totalPrice = 0;
    var discountPrice = this.props.assetTax || 0;
    var finalPrice = 0;
    var totalItem = 0;

    const _cart = this.props.cartAssets;
    var cart = [];

    _cart.forEach((item) => {
      var index = cart.findIndex((_item) => {
        const idx = _item.asset.id || _item.asset.asset;
        return idx == item.id || idx == item.asset;
      });

      if (index > -1) {
        cart[index].total++;
      } else {
        cart.push({
          asset: item,
          total: 1,
        });
      }
      totalPrice += item.price;
      totalItem++;
    });

    if (applyDiscount) {
      // if (totalPrice > 400000) {
      //   discountPrice = 25;
      // } else if (totalPrice > 300000) {
      //   discountPrice = 20;
      // } else if (totalPrice > 200000) {
      //   discountPrice = 15;
      // } else if (totalPrice > 100000) {
      //   discountPrice = 10;
      // } else if (totalPrice > 200000) {
      //   discountPrice = 15;
      // } else {
      //   discountPrice = 0;
      // }

      finalPrice = (totalPrice * (100 - discountPrice)) / 100;
    } else {
      finalPrice = totalPrice;
    }

    const columns = [
      {
        title: I18n.get("Asset"),
        dataIndex: "asset",
      },
      {
        title: I18n.get("Quantidade"),
        dataIndex: "quantidade",
      },
      {
        title: I18n.get("Valor"),
        dataIndex: "valor",
        render: (price, record) => {
          return (
            <FormatCurrency
              amount={price * record?.item?.total}
              currency={this.props?.currency}
            />
          );
        },
      },
      {
        title: I18n.get("Ação"),
        dataIndex: "",
        render: (record) => {
          return (
            <Button
              type="primary"
              onClick={(e) => this.props.delItem(record.item.asset, e)}
            >
              <Icon type="delete" theme="filled" />
            </Button>
          );
        },
      },
    ];

    const data =
      cart &&
      cart.map((item, index) => {
        return {
          key: index,
          asset: item.asset.label,
          quantidade: item.total,
          valor: item.asset.price,
          item: item,
        };
      });

    if (this.state.createOk)
      return (
        <Redirect
          to={`/parceiro/criar-projeto/sucesso/${this.state.projectId}`}
        />
      );

    return (
      <Fragment>
        <form onSubmit={this.submitForm.bind(this)} className="form-resumo">
          <div className="columns columns-for-np">
            <div className="column left-column-for-np is-8">
              <div className="resume-cart-heading">
                <h1 className="title-bpool medium">
                  {I18n.get("Resumo do Projeto:")}{" "}
                  <span>
                    {" "}
                    {this.props.project.projectName ||
                      this.props.partner?.projectStep1?.projectName}
                  </span>{" "}
                </h1>
                <a className="customize-cart">
                  <span className="customize-cart-items">{totalItem}</span>
                  <i className="fas fa-shopping-cart"></i>
                </a>
              </div>
              <div className="resume-cart-content">
                <Table
                  columns={columns}
                  scroll={{ x: 640 }}
                  dataSource={data}
                  pagination={false}
                  footer={() => (
                    <button
                      type="button"
                      className="button bt-bpool"
                      onClick={this.props.onCustomBtnClick}
                    >
                      {I18n.get("Continuar contratando")}
                    </button>
                  )}
                />

                <br />
                <br />
                <br />
                <br />

                {this.state.errors &&
                  Object.keys(this.state.errors).length > 0 && (
                    <Row>
                      <Col sm={24}>
                        <div className="error-list-bpool">
                          <ul>
                            {this.state.errors &&
                              Object.keys(this.state.errors).map((item) => {
                                return (
                                  <li>
                                    <Link
                                      to={`/parceiro/criar-projeto/${
                                        item == "briefing"
                                          ? `briefing/${this.props.match.params.projectId}`
                                          : `milestones/${this.props.match.params.projectId}`
                                      }`}
                                    >
                                      <i className="fas fa-exclamation"></i>{" "}
                                      {this.props.errors[item].errorMessage}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  )}
              </div>
            </div>

            <div className="column right-column-for-np is-4">
              <div className="space-padding">
                <Card
                  className="TopCard"
                  type="inner"
                  title={I18n.get("Subtotal")}
                >
                  <div className="resume-subtotal-amount">
                    <span style={{ float: "left" }}>
                      {I18n.get("Total (")} {totalItem}{" "}
                      {totalItem > 1 && "assets"} {totalItem == 1 && "asset"} ")
                      :"
                    </span>
                    <br />{" "}
                    <FormatCurrency
                      amount={totalPrice}
                      currency={this.props?.currency}
                    />
                  </div>

                  {/* {applyDiscount && <div className="resume-subtotal-amount">
                                        <span style={{ float: 'left' }}>Desconto ({discountPrice}%):</span>
                                        {formatter.format(totalPrice * discountPrice / 100)}
                                    </div>} */}

                  <hr
                    className="hrNew"
                    style={{ backgroundColor: "#000000", height: "2px" }}
                  />

                  <div className="resume-subtotal-amount">
                    <span style={{ float: "left" }}>{I18n.get("Final:")}</span>

                    <FormatCurrency
                      amount={totalPrice}
                      currency={this.props?.currency}
                    />
                  </div>

                  <SubmitButtonField
                    label={I18n.get("Enviar sugestão")}
                    className="button bt-bpool full black"
                    onClick={this.submitForm.bind(this)}
                    task={this.state.task}
                  />
                </Card>
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default withRouter(AssetResumeForm);
