import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import Content from "../../../../../components/UI/Content";
import { H2Border } from "../../../../../components/UI/Titles";
import { StepsCreateProject } from "../../components/StepsCreateProject";

import { Briefing } from "../../../../Shared/Project/Briefing"

import * as S from "./styles";

export const CreateProjectPartnerStep2 = () => {
  let params = useParams();
  const [urlNextStep, setUrlNextStep] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setUrlNextStep(`/parceiro/projetos/v2/criar/tipo/${params.projectId}`);
  }, []);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Novo Projeto")}</H2Border>
            </Col>
            <Col sm={12} justify="end"></Col>
          </Row>

          <Row>
            <Col sm={24}>
              <S.StyleMain>
                <StepsCreateProject
                  active={2}
                  projectId={params.projectId || ""}
                />
              </S.StyleMain>
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Briefing urlNextStep={urlNextStep} />
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
