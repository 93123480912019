import styled from "styled-components";
import { Skeleton as SkeletonAntd } from "antd";

export const InputGroup = styled.div`
  position: relative;
  width: 100%;

  textarea {
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #515152;
    box-sizing: border-box;
    padding: 15px;
    border: solid 1px;
    border-color: #999999;
    width: 100%;
    margin: 0;
    transition: border-color 0.2s ease-in-out;

    :disabled,
    :read-only {
      border: solid 1px !important;
      border-color: #e7e7e7 !important;
      background-color: #ffffff !important;
      cursor: not-allowed !important;
    }

    ::-webkit-input-placeholder {
      color: transparent !important;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: transparent !important;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent !important;
    }
    :-ms-input-placeholder {
      color: transparent !important;
    }
  }

  .input-error {
    border-color: #ff0000;
  }

  label {
    background-color: #ffffff;
    color: #a4a4a4;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: -12px;
    left: 15px;
    padding: 0 5px;
    pointer-events: none;
    transform: translateY(30px);
    transition: top 0.2s ease-in-out, left 0.2s ease-in-out;

    span.required-input {
      color: #ff0000;
    }
  }

  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label {
    top: -35px;
    left: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #515152;
  }

  p.error-input {
    color: #ff0000;
    margin: 5px 0 0 5px;
    font-size: 12px;
    line-height: 12px;
  }
`;

export const InfoError = styled.div`
  color: #ff0000;
  margin: -5px 0 0 15px;
  font-size: 12px;
  width: 100%;
`;

export const ErrorAndCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Counter = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #979797;
  text-align: right;
  width: 100%;
  margin-top: 0;
`;

export const Skeleton = styled(SkeletonAntd)`
  .skeleton-textarea {
    margin-top: 0;
    height: 150px;

    li {
      height: 100%;
    }
  }
`;
