import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink } from 'react-router-dom'


export default class UserList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            profile: 0,
        };

        this.changeProfile = this.onChangeProfile.bind(this);
    }

    componentDidMount() {
        this.props.get_users(this.state.role);
    }

    onChangeProfile(e) {
        const value = e.target.value;

        this.setState({ profile: value });

        this.props.get_users(value);
    }

    render() {

        return (
            <div>

                <div className="field is-grouped table-mob">

                    <div className="control campo-table-mob">
                        <label className="label is-small">{I18n.get("Perfil")}</label>
                        <div className="select">
                            <select value={this.state.profile} onChange={this.changeProfile}>
                                {this.props.bp.profiles && this.props.bp.profiles.map((item) => (<option key={item.code} value={item.code}>{item.label}</option>))}
                            </select>
                        </div>
                    </div>

                    <div className="control no-mob">
                        &nbsp;
                    </div>

                    <div className="control campo-table-mob">
                        <label className="label is-small">&nbsp;</label>
                        <NavLink to={`/bpool/usuarios/novo`} className="button is-black">{I18n.get("Novo")}</NavLink>
                    </div>
                </div>

                <div className="">
                    <div className="table-main">

                        <table className="table is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    <th>{I18n.get("Name")}</th>
                                    <th>{I18n.get("E-mail")}</th>
                                    <th>{I18n.get("Ativo")}</th>
                                    <th>{I18n.get("Data criação")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.bp.users && this.props.bp.users.map((item, index) =>
                                    <tr key={index}>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            {item.email}
                                        </td>
                                        <td>
                                            {item.enabled ? 'Sim' : 'Não'}
                                        </td>
                                        <td>
                                            {item.createdOnFmt}
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
        );
    }
}
