import { Auth } from 'aws-amplify';

import { AUTH_PASSWORD_RESET } from './actions';

import { localize } from '../../utils/errors';

export const api_reset_password = ({ email, code, password }) => {
    return (dispatch, getState) => {

        // if (email && email.length > 0)
        //     email = email.trim().toLowerCase();

        // if (password && password.length > 0)
        //     password = password.trim();

        // if (code && code.length > 0)
        //     code = code.trim();

        Auth.forgotPasswordSubmit(email, code, password)
            .then(data => {

                dispatch({
                    type: AUTH_PASSWORD_RESET,
                    payload: {
                        password_reset_required: false,
                        task: {
                            result: true
                        }
                    }
                });
            })
            .catch(err => {

                var error = localize(err);

                dispatch({
                    type: AUTH_PASSWORD_RESET,
                    payload: {
                        task: {
                            result: false,
                            error: error
                        }
                    }
                });

            });
    }

}

export default api_reset_password;