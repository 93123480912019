import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import PartnerTopMenuSignip from '../partner/_top-menu-signup';
import ClientTopMenuSignup from '../client/_top-menu-signup';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class TopMenuSignup extends React.Component {

    constructor(props) {
        super();
    }

    render() {



        if (this.props.auth.is_partner) {
            return (<PartnerTopMenuSignip {...this.props} />);
        }

        if (this.props.auth.is_client || this.props.auth.is_client_user) {
            return (<ClientTopMenuSignup {...this.props} />);
        }


        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuSignup);
