import React, { Fragment } from "react";

import { I18n } from "aws-amplify";

import { Icon } from "antd";

import ReviewStep1 from "../../_project-review-1";
import AssetBundle from "../../_project-asset-bundle";
import ReviewStep4 from "../../_project-review-4";

import { Row, Col } from "antd";

export default class ProjectDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      q: "",
      status: "",
    };
  }

  componentDidMount() {}

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return "";

    return this.props.bp.clientProjectStatusesMap[value].label;
  }

  getProjectUrl(item) {
    var redirectUrl = (redirectUrl = `/parceiro/projetos/${item.projectId}`);

    return redirectUrl;
  }

  getSelected() {
    return {
      bundleId: this.props.bundleId,
      variationId: this.props.variationId,
    };
  }

  render() {
    const isInvite = this.props.isInvite || false;

    return (
      <Fragment>
        <Row>
          <Col sm={12}>
            <br />
            <br />

            <h1 className="title-bpool medium">{I18n.get("Informações")}</h1>

            <ReviewStep1 {...this.props} />

            <br />
            <br />
            <h1 className="title-bpool medium">
              {I18n.get("Escopo Criativo")}
            </h1>

            <div className="reviewed-media uplift-review listBundle">
              <AssetBundle
                {...this.props.bundle}
                {...this.props}
                bp={this.props.bp}
                selected={this.getSelected()}
                isExpanded={true}
                isInvite={isInvite}
                IsClient={this.props.auth.is_client_or_client_user}
                IsPartner={this.props.auth.is_partner}
                PricePartner={this.props.pricePartner}
                PricePartnerTax={this.props.pricePartnerTax}
                PricePartnerOtherTax={this.props.pricePartnerOtherTax}
                PriceClientWithDiscount={this.props.priceClientWithDiscount}
                PriceClientWithDiscountTax={
                  this.props.priceClientWithDiscountTax
                }
                PriceClientWithDiscountOtherTax={
                  this.props.priceClientWithDiscountOtherTax
                }
                PriceBPool={this.props.priceBPoolComission}
                PriceBPoolTax={this.props.priceBPoolComissionTax}
                PriceBPoolOtherTax={this.props.priceBPoolComissionOtherTax}
                currency={this.props.currency}
                assetTax={this.props.assetTax || 0}
                otherTax={this.props.otherTax || 0}
                isFull={true}
                projectStatus={this.props.status}
                projectAssets={this.props.project?.bundle?.variations}
                isEditing={false}
                showConvert={
                  this.props.currency && this.props.currency.code == "BRL"
                }
              />
            </div>
          </Col>

          <Col sm={12}>
            {(this.props.demand ||
              (this.props.demandFiles &&
                this.props.demandFiles.length > 0)) && (
              <div>
                <br />
                <br />
                <h1 className="title-bpool medium">
                  {I18n.get("Demanda geral")}
                </h1>

                <div className="card-box">
                  <div className="card-row">
                    <div className="card">
                      <div className="card-content" style={{ width: "100%" }}>
                        {this.props.demand && <p>{this.props.demand}</p>}

                        {this.props.demandFiles &&
                          this.props.demandFiles.length > 0 && (
                            <div style={{ marginTop: "20px" }}>
                              <p>
                                <strong>{I18n.get("Arquivos:")}</strong>
                              </p>

                              <div style={{ padding: "20px 15px" }}>
                                {this.props.demandFiles &&
                                  this.props.demandFiles.map((item, index) => (
                                    <div
                                      key={index}
                                      class="columns columns itemFile line"
                                    >
                                      <div class="column is-clearfix upload-item">
                                        <a href={item.url} target="_blank">
                                          <Icon
                                            type="file-text"
                                            theme="filled"
                                          />{" "}
                                          &nbsp; {item.name}
                                        </a>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.props.files && !this.props.isInvite && (
              <div>
                <br />
                <br />
                <h1 className="title-bpool medium">{I18n.get("Arquivos")}</h1>

                <div className="card-box">
                  <div className="card-row">
                    <div className="card">
                      <div className="card-content" style={{ width: "100%" }}>
                        {this.props.files && (
                          <div style={{ marginTop: "20px" }}>
                            <p>
                              <strong>{I18n.get("Arquivos:")}</strong>
                            </p>

                            <div style={{ padding: "20px 15px" }}>
                              {this.props.files &&
                                this.props.files.map((item, index) => (
                                  <div
                                    key={index}
                                    class="columns columns itemFile grey2"
                                  >
                                    <div class="column is-clearfix upload-item">
                                      <a href={item.url} target="_blank">
                                        <Icon type="file-text" theme="filled" />{" "}
                                        &nbsp; {item.name}
                                      </a>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/*
                            <Link to={`/cliente/projetos/${this.props.match.params.projectId}/passo-3`} className="button is-link-to-return">
                                Redefinir
                            </Link> */}
              </div>
            )}

            <br />
            <br />

            <h1 className="title-bpool medium">{I18n.get("Detalhes")}</h1>

            <div className="uplift">
              <ReviewStep4
                {...this.props}
                statusInvite={this.props?.statusInvite}
                inviteRates={this.props?.inviteRates}
              />
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
