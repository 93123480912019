import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import moment from "moment";
import { useHistory, useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBriefings } from "../../../hooks/useBriefings/useBriefings.hook";
import { Row, Col, message } from "antd";
import Content from "../../../components/UI/Content";
import Main from "../../../components/UI/Main";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import { H2Border } from "../../../components/UI/Titles";
import { BoxUpload } from "../../../components/UI/BoxUpload";
import ListFiles from "./components/ListFiles";
import { RichEditor } from "../../../components/UI/RichEditor";
import { DatePicker } from "../../../components/UI/DatePicker";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import Loading from "../../../components/UI/Loading";
import { TabsProject } from "../../../components/UI/TabsProject";
import { Input } from "../../../components/UI/Input";
import { RequestProjectCompletion } from "../components/RequestProjectCompletion";

import Download from "../svgs/download.svg";
import * as S from "./styles";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

export const BriefingInProgress = () => {
  let params = useParams();
  let history = useHistory();

  const auth = useSelector((state) => state.auth);

  const { getProjectById } = useProjects();
  const { getBriefing, uploadFile, updateBriefing, deleteFile } =
    useBriefings();
  const [isLoading, setIsLoading] = useState(false);
  const [briefings, setBriefings] = useState({});
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [statusProject, setStatusProject] = useState(null);
  const [Error, setError] = useState(null);
  const [filesIdsToSend, setFilesIdsToSend] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [errorInDate, setErrorInDate] = useState("");

  const [topicsProject, setTopicsProject] = useState({});
  const [partnerDeliveryEstimated, setPartnerDeliveryEstimated] =
    useState(null);

  const loadBriefing = async () => {
    setIsLoading(true);
    try {
      const response = await getBriefing({ id: params?.projectId });
      if (response?.success) {
        setBriefings(response?.data);

        if (response?.data?.briefing) {
          setTopicsProject({
            text: response?.data?.briefing?.content,
            plaintext: response?.data?.briefing?.plaintext,
          });
        }

        var dateGet = moment(
          response?.data?.partnerDeliveryEstimated,
          "DD/MM/YYYY"
        );
        let customDate = dateGet.format("YYYY-MM-DD");
        const dateFormatted = moment(customDate, "YYYY-MM-DD");

        setPartnerDeliveryEstimated(
          response?.data?.partnerDeliveryEstimated ? dateFormatted : null
        );

        setLoadingFiles(false);
      } else {
        // setError("Error.");
      }
      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });
      setStatusProject(responseProject?.data?.status);
      // setIsLoading(false);
    } catch (error) {
      // setError("Error.");
    }
    setIsLoading(false);
  };

  const uploadFileBriefing = async (file) => {
    setLoadingFiles(true);
    const data = {
      projectId: params?.projectId,
      name: file?.name,
      url: file?.url,
      type: file?.type,
      size: file?.size,
      uploadedDate: file?.uploadedDate,
    };
    try {
      const response = await uploadFile(data);
      await loadBriefing();
    } catch (error) {
      setLoadingFiles(false);
    }
  };

  const deleteFileBriefing = async (file) => {
    const info = {
      projectId: params?.projectId,
      id: file,
    };

    try {
      const response = await deleteFile(info);
      await loadBriefing();
    } catch (error) {}
  };

  const handleSendApi = async () => {
    if (!briefings?.partnerHasSubmittedDefinitions) {
      if (partnerDeliveryEstimated) {
        setIsSending(true);
        const data = {
          projectId: params?.projectId,
          partnerDeliveryEstimated: partnerDeliveryEstimated,
          briefing: {
            text: topicsProject?.text || "",
            plaintext: topicsProject?.plaintext || "",
            filesIds: filesIdsToSend,
          },
        };

        await updateBriefing(data).then((response) => {
          if (response.success) {
            history.push(
              `/${auth?.is_partner ? "parceiro" : "cliente"}/projetos/v2/${
                params?.projectId
              }/review`
            );
          } else {
            setError(response.errors.briefings.errorMessage);
          }
        });

        setIsSending(false);
      } else {
        message.error(I18n.get("Please, select the end of the project."));
        setErrorInDate(I18n.get("Please, select the end of the project."));
      }
    } else {
      history.push(
        `/${auth?.is_partner ? "parceiro" : "cliente"}/projetos/v2/${
          params?.projectId
        }/review`
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadBriefing();
  }, []);

  useEffect(() => {
    const filesIds = briefings?.files?.map((item) => item?.id);
    setFilesIdsToSend(filesIds);
  }, [briefings]);

  const handleChangeText = ({ content, text }) => {
    setTopicsProject({
      text: text,
      plaintext: content,
    });
  };

  const onChangeDate = (date, dateString) => {
    setPartnerDeliveryEstimated(date);
  };

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Projeto em andamento")}</H2Border>
            </Col>
            <Col sm={12}>
              <RequestProjectCompletion reloadPage={() => true} />
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <TabsProject
                context={
                  statusProject === null
                    ? "inProgress"
                    : statusProject === 7
                    ? "inProgress"
                    : "finalized"
                }
                page="briefing"
                externalLoading={isLoading}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={12} offset={6}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Briefing")}</S.H4>
                      </S.TitleWithIcon>
                      <S.Paragraph>
                        {!briefings?.partnerHasSubmittedDefinitions &&
                          I18n.get("Upload de documentos")}
                      </S.Paragraph>
                    </S.RowCenter>
                  </Col>
                </Row>

                {briefings?.partnerHasSubmittedDefinitions && (
                  <Row>
                    <Col xs={24}>
                      <S.CloseScope>
                        <p>
                          {I18n.get(
                            "Escopo fechado ou em análise, briefing não pode ser alterado."
                          )}
                        </p>
                      </S.CloseScope>
                    </Col>
                  </Row>
                )}

                {!briefings?.partnerHasSubmittedDefinitions && (
                  <>
                    <Row>
                      <Col sm={24}>
                        <BoxUpload
                          direct={true}
                          disabledList={true}
                          name="files"
                          sendUpload={(file) => uploadFileBriefing(file)}
                          uploadId={auth?.partnerId}
                          loadingFiles={loadingFiles}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24}>
                        <ListFiles
                          deleteFile={(id) => deleteFileBriefing(id)}
                          files={briefings?.files || []}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <S.RowMain>
                  <Row>
                    <Col sm={24}>
                      {isLoading ? (
                        <Loading
                          text={I18n.get("Loading...")}
                          sizeIcon={20}
                          sizeText={14}
                          align="left"
                        />
                      ) : (
                        <S.TextEdit>
                          <RichEditor
                            title={I18n.get(
                              "Describe below the topics you will deliver in the project"
                            )}
                            defaultValue={
                              briefings?.briefing?.content ||
                              briefings?.briefing?.plaintext ||
                              ""
                            }
                            disabled={true}
                            onChange={handleChangeText}
                          />
                        </S.TextEdit>
                      )}
                    </Col>
                  </Row>
                </S.RowMain>

                <S.RowMain>
                  <Row>
                    <Col sm={24}>
                      <S.TitleDates>
                        <p>
                          {I18n.get(
                            "Now the beginning of the project and the end/delivery of it."
                          )}
                        </p>
                      </S.TitleDates>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Row>
                      <Col sm={24}>
                        <Loading
                          text={I18n.get("Loading...")}
                          sizeIcon={20}
                          sizeText={14}
                          align="left"
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col sm={24}>
                        <S.TitleStartDate>
                          <p>
                            {I18n.get("The Project will begin on the date of:")}
                            <span>
                              {briefings?.estimatedStartDate || "---"}
                            </span>
                          </p>
                        </S.TitleStartDate>
                      </Col>
                      <Col sm={8}>
                        {briefings?.partnerDeliveryEstimated && (
                          <Input
                            value={briefings?.partnerDeliveryEstimated}
                            disabled={true}
                            placeholder={I18n.get(
                              "Select the end of the project"
                            )}
                            required
                          />
                        )}
                      </Col>
                    </Row>
                  )}
                </S.RowMain>

                <S.DisplayErrors>
                  <Row>
                    <Col lg={24}>
                      {Error && (
                        <>
                          <h3>
                            {I18n.get(
                              "Ops... Alguns campos precisam ser informados."
                            )}
                          </h3>
                          <br />
                          <p>
                            {" "}
                            <span class="icon has-text-danger">
                              <i class="fas fa-exclamation"></i>
                            </span>
                            {Error}
                          </p>
                        </>
                      )}
                    </Col>
                  </Row>
                </S.DisplayErrors>

                <S.RowFooter>
                  <S.ButtonsFooter>
                    <div>
                      <a href={briefings?.pdfBriefing} target="_blank">
                        <ButtonBpool
                          text={I18n.get("Download")}
                          className=""
                          icon={Download}
                          theme="secondary"
                        />
                      </a>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Link
                        to={`/${
                          auth?.is_partner ? "parceiro" : "cliente"
                        }/projetos/v2/${params?.projectId}/in-progress/files`}
                      >
                        <ButtonBpool
                          text={I18n.get("Back")}
                          theme="secondary"
                          onClick={() => true}
                        />
                      </Link>
                      <Link
                        to={`/${
                          auth?.is_partner ? "parceiro" : "cliente"
                        }/projetos/v2/${params?.projectId}/in-progress/po`}
                        style={{ marginLeft: "20px" }}
                      >
                        <ButtonBpool
                          text={I18n.get("Next")}
                          theme="primary"
                          onClick={() => true}
                        />
                      </Link>
                    </div>
                  </S.ButtonsFooter>
                </S.RowFooter>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
