import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col, message, Select } from "antd";
import Main from "../../../../components/UI/Main";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";
import { Input } from "../../../../components/UI/Input";
import { UploadLogo } from "../../Full/components/UploadLogo";
import { useRegistrationFlowLeader } from "../../../../hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook";
import { useRegistrationFlowUser } from "../../../../hooks/useRegistrationFlow/useRegistrtionFlowUser.hook";
import { useUpload } from "../../../../hooks/useUpload/useUpload.hook";
import { hasError, clearErrorInput } from "../../utils/errorsInput";
import { InputFlag } from "../../../../components/UI/InputFlag";
import { SelectOne } from "../../../../components/UI/SelectOne";
import PasswordChecklist from "react-password-checklist";

import * as S from "./styles";
import { inputsState } from "./inputsState";
import COUNTRY_CODES from "./countryCodes.json";

const MESSAGE_ERROR_TOKEN = "Error in get token.";
const ERROR_IMAGE_UP = I18n.get("Maximum limit 5mb");
const ERROR_IMAGE_NOT_UP = "Logo image is required with sizes (117 x 117px)";

export const ContentPage = ({
  contextPage,
  sendOk,
}) => {
  const location = useLocation();
  let history = useHistory();
  const { getTimezones } = useRegistrationFlowLeader();
  const { getAboutYou, postAboutYou, putAboutYouLogged, getAboutYouLogged } =
    useRegistrationFlowUser();
  const { uploadFile } = useUpload();

  const [token, setToken] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [inputsForm, setInputsForm] = useState(inputsState);
  const [inputsFormInitials, setInputsFormInitials] = useState(inputsState);
  const [inputsErrorsForm, setInputsErrorsForm] = useState([]);
  const [statusLogoUpload, setStatusLogoUpload] = useState("");
  const [timezones, setTimezones] = useState([]);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [fileLogoToUpload, setFileLogoToUpload] = useState(null);
  const [selectCountry, setSelectCountry] = useState({
    countryLabel: "Brazil",
    phoneMask: "(00)00000-0000",
    countryCode: "BRA",
  });
  
  const [controlPassword, setControlPassword] = useState({
    bothLowerUpper: false,
    numberAndSymbol: false,
    least8Char: false,
    complete: false,
    passwordsMatch: false,
  });

  const validPassword = () => {
    let min8chars = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    let min8charsBothLowerAndUpper =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    let min8charsAndNumberAndSymbol =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let complete =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (inputsForm.passWord.match(complete)) {
      setControlPassword({
        bothLowerUpper: true,
        numberAndSymbol: true,
        least8Char: true,
        complete: true,
        passwordsMatch: false,
      });
    } else if (inputsForm.passWord.match(min8charsAndNumberAndSymbol)) {
      setControlPassword({
        bothLowerUpper: false,
        numberAndSymbol: true,
        least8Char: true,
        complete: false,
        passwordsMatch: false,
      });
    } else if (inputsForm.passWord.match(min8charsBothLowerAndUpper)) {
      setControlPassword({
        bothLowerUpper: true,
        numberAndSymbol: false,
        least8Char: true,
        complete: false,
        passwordsMatch: false,
      });
    } else if (inputsForm.passWord.match(min8chars)) {
      setControlPassword({
        bothLowerUpper: false,
        numberAndSymbol: false,
        least8Char: true,
        complete: false,
        passwordsMatch: false,
      });
    } else {
      setControlPassword({
        bothLowerUpper: false,
        numberAndSymbol: false,
        least8Char: false,
        complete: false,
        passwordsMatch: false,
      });
    }
  };

  const onChangeInputForm = (id, value, isCountry) => {
    if (isCountry) {
      const obj = COUNTRY_CODES.find((i) => i.code == value);
      setInputsForm({
        ...inputsForm,
        [id]: value ? obj : null,
      });
    } else {
      setInputsForm({
        ...inputsForm,
        [id]: value ? value : null,
      });
    }
  };

  const onChangeInputPhone = ({ valuePhone, valuePhoneCountry }) => {
    setInputsForm({
      ...inputsForm,
      phoneNumber: valuePhone ? valuePhone : null,
      countryCodePhoneNumber: valuePhoneCountry ? valuePhoneCountry : null,
    });
  };

  const onChangePasswordInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : "",
    });
  };

  const fileToUpload = ({ file }) => {
    setFileLogoToUpload(file);

    const errorsArr = clearErrorInput({
      field: "profilePicture",
      arr: inputsErrorsForm,
    });
    setInputsErrorsForm(errorsArr);
  };

  const send = async () => {
    setIsSending(true);

    let profilePictureSend = inputsForm.profilePicture;
    if (fileLogoToUpload) {
      const responseprofilePictureUpload = await uploadFile({
        file: fileLogoToUpload,
        idUser: "usertestingnow",
        fieldId: `profilePicture-user`,
      });
      profilePictureSend = responseprofilePictureUpload.url;
    }

    const objToSend = {
      ...inputsForm,
      profilePicture: profilePictureSend,
    };

    if (contextPage === "edit") {
      delete objToSend.email;
      delete objToSend.passWord;
      delete objToSend.confirmPassWord;

      const responseLogged = await putAboutYouLogged(objToSend);

      if (responseLogged.success) {
        sendOk(true);
      } else {
        sendOk(false);
      }
      setIsSending(false);
    } else {
      const response = await postAboutYou(objToSend);

      if (response.success) {
        history.push({
          pathname: "/registration-flow/user/completed",
          state: { name: inputsForm.name },
        });
      } else {
        const errors = [];
        Object.keys(response?.errors).forEach(function (key, index) {
          errors.push(response?.errors[key]);
        });
        setInputsErrorsForm(errors);
      }
    }

    setIsSending(false);
  };

  const prepareToken = async (url) => {
    const arrayOfStrings = url.split("/");
    const tkn = arrayOfStrings[arrayOfStrings.length - 1];
    return tkn;
  };

  const trateString = (value) => {
    if (value) {
      return value;
    } else {
      return null;
    }
  };

  
  const loadInit = async () => {
    setIsLoadingPage(true);

    if (contextPage === "edit") {
      const response = await getAboutYouLogged();

      const dataForm = {
        id: trateString(response?.data?.users?.id),
        partnerId: trateString(response?.data?.id),
        name: trateString(response?.data?.users?.name),
        nameAlias: trateString(response?.data?.users?.nameAlias),
        email: trateString(response?.data?.users?.email),
        timeZone: trateString(response?.data?.users?.timeZone),
        linkedin: trateString(response?.data?.users?.linkedin),
        country: response?.data?.users?.country,
        phoneNumber: trateString(response?.data?.users?.phoneNumber),
        countryCodePhoneNumber:
          response?.data?.users?.countryCodePhoneNumber || "BRA",
        passWord: "",
        confirmPassWord: "",
        profilePicture: trateString(response?.data?.users?.profilePicture),
      };
      setInputsForm(dataForm);
      setInputsFormInitials(dataForm);
      setStatusLogoUpload(dataForm?.profilePicture || "");

     

      setIsLoadingPage(false);
    } else {
      const tokenUrl = await prepareToken(location?.pathname);
      if (tokenUrl) {
        setToken(tokenUrl);

        const response = await getAboutYou(tokenUrl);

        if (response.success) {
          const ll = response?.data?.users?.country?.bpRegion;

          switch (ll) {
            case "USA":
              I18n.setLanguage("en");
              break;
            case "LATAM":
              I18n.setLanguage("es");
              break;
            case "BRASIL":
              I18n.setLanguage("pt");
              break;

            default:
              break;
          }
          const dataForm = {
            id: trateString(response?.data?.users?.id),
            partnerId: trateString(response?.data?.id),
            verificationToken: tokenUrl,
            name: trateString(response?.data?.users?.name),
            timeZone: trateString(response?.data?.users?.timeZone),
            nameAlias: trateString(response?.data?.users?.nameAlias),
            email: trateString(response?.data?.users?.email),
            linkedin: trateString(response?.data?.users?.linkedin),
            country: null,
            phoneNumber: trateString(response?.data?.users?.phoneNumber),
            countryCodePhoneNumber:
              response?.data?.users?.countryCodePhoneNumber || "BRA",
            passWord: "",
            confirmPassWord: "",
            profilePicture: trateString(response?.data?.users?.profilePicture),
          };
          setInputsForm(dataForm);
          setInputsFormInitials(dataForm);
          setStatusLogoUpload(dataForm?.profilePicture || "");

          if (response?.data?.users?.country) {
            setSelectCountry({
              countryLabel: response?.data?.users?.country?.name,
              phoneMask: "",
              countryCode: response?.data?.users?.country?.code,
            });
          }
        }
      } else {
        message.error(I18n.get(MESSAGE_ERROR_TOKEN));
      }
    }

    setIsLoadingPage(false);
  };

  const loadTimezone = async () => {
    const response = await getTimezones();

    const timeFormat = response.data.map((item) => {
      return {
        code: item?.value,
        label: `${item?.text} - ${item?.value}`,
        selected: item.selected,
      };
    });

    setTimezones(timeFormat);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, [location]);

  useEffect(() => {
    loadTimezone();
  }, []);

  useEffect(() => {
    validPassword();

    if (inputsForm.confirmPassWord) {
      if (inputsForm.passWord === inputsForm.confirmPassWord) {
        setControlPassword({ ...controlPassword, passwordsMatch: true });
      } else {
        setControlPassword({ ...controlPassword, passwordsMatch: false });
      }
    }
  }, [inputsForm.passWord, inputsForm.confirmPassWord]);

  const renderHasError = (status) => {
    if (status === "error") {
      return <S.ErrorText>{ERROR_IMAGE_UP}</S.ErrorText>;
    } else if (status === "errorNotUpload") {
      return <S.ErrorText>{ERROR_IMAGE_NOT_UP}</S.ErrorText>;
    } else {
      return null;
    }
  };

  return (
    <div className="container" style={{ width: "100%" }}>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
          <Main bgColor="#fff" padding="30px">
            <Row>
              <Col xs={24}>
                <S.RowCenter>
                  <S.TitleWithIcon>
                    <S.H4>{I18n.get("About you")}</S.H4>
                  </S.TitleWithIcon>
                  <S.Paragraph>
                    {I18n.get("Our algorithm wants to know more about you")}
                  </S.Paragraph>
                </S.RowCenter>
              </Col>
            </Row>

            {isLoadingPage ? (
              <Row>
                <Col xs={24}>
                  <S.ContentIsLoading>
                    <Loading
                      text={I18n.get("Loading informations...")}
                      sizeText={14}
                      sizeIcon={16}
                    />
                  </S.ContentIsLoading>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginTop: 30 }}>
                <Col xs={24} sm={4}>
                  <S.Logo>
                    <S.LogoImage>
                      <UploadLogo
                        defaultImg={inputsFormInitials.profilePicture}
                        imgUploadStatus={(status) =>
                          setStatusLogoUpload(status)
                        }
                        fileToUpload={(file) => fileToUpload({ file })}
                        styleButton={{
                          width: "113px",
                          height: "113px",
                        }}
                      />
                    </S.LogoImage>
                    {statusLogoUpload === "" ||
                    statusLogoUpload === "removed" ? (
                      <S.SizeLogo>
                        117 x 117px
                        <br />
                        <strong>JPG or PNG</strong>
                      </S.SizeLogo>
                    ) : null}

                    <div style={{ marginTop: "20px" }}>
                      <S.ErrorText>
                        {hasError({
                          field: "profilePicture",
                          arr: inputsErrorsForm,
                        })}
                      </S.ErrorText>
                    </div>
                  </S.Logo>
                  {renderHasError(statusLogoUpload)}
                </Col>
                <Col xs={24} sm={20}>
                  <S.InputsContent>
                    <S.RowInput>
                      <Input
                        placeholder={I18n.get("Name")}
                        label
                        required
                        value={inputsForm.name}
                        onChange={(value) => onChangeInputForm("name", value)}
                        error={hasError({
                          field: "Name",
                          arr: inputsErrorsForm,
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "Name",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                      />
                    </S.RowInput>
                    <S.RowInput style={{ marginLeft: 15 }}>
                      <Input
                        placeholder={I18n.get(
                          "How would you like to be called?"
                        )}
                        label
                        required
                        value={inputsForm.nameAlias}
                        onChange={(value) =>
                          onChangeInputForm("nameAlias", value)
                        }
                        error={hasError({
                          field: "nameAlias",
                          arr: inputsErrorsForm,
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "nameAlias",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                      />
                    </S.RowInput>
                    <S.RowInput style={{ marginLeft: 15 }}>
                      <div style={{ marginBottom: "30px" }}>
                        <SelectOne
                          options={COUNTRY_CODES}
                          value={inputsForm?.country?.code}
                          defaultValue={inputsForm?.country?.code}
                          isCountry
                          onChange={(value) =>
                            onChangeInputForm("country", value, true)
                          }
                          required
                          placeholder={I18n.get("Country")}
                          error={hasError({
                            field: "country",
                            arr: inputsErrorsForm,
                          })}
                          clearError={() => {
                            const errorsArr = clearErrorInput({
                              field: "country",
                              arr: inputsErrorsForm,
                            });
                            setInputsErrorsForm(errorsArr);
                          }}
                        />
                      </div>
                    </S.RowInput>
                    <S.RowInput>
                      <Input
                        placeholder={I18n.get("Email")}
                        label
                        required
                        value={inputsForm.email}
                        onChange={(value) => onChangeInputForm("email", value)}
                        error={hasError("email")}
                        disabled={true}
                      />
                    </S.RowInput>
                    <S.RowInput style={{ marginLeft: 15 }}>
                      <Input
                        placeholder={I18n.get("Linkedin")}
                        label
                        value={inputsForm.linkedin}
                        onChange={(value) =>
                          onChangeInputForm("linkedin", value)
                        }
                        error={hasError({
                          field: "linkedin",
                          arr: inputsErrorsForm,
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "linkedin",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                      />
                    </S.RowInput>
                    <S.RowInput style={{ marginLeft: 15 }}>
                      <InputFlag
                        id="phoneNumber"
                        label={I18n.get("Phone number")}
                        defaultValue={inputsForm?.phoneNumber}
                        flagCodeDefault={
                          inputsForm?.countryCodePhoneNumber || "BRA"
                        }
                        onChange={(value) =>
                          onChangeInputPhone({
                            valuePhone: value?.value,
                            valuePhoneCountry: value?.country,
                          })
                        }
                        required
                        error={hasError({
                          field: "phoneNumber",
                          arr: inputsErrorsForm,
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "phoneNumber",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                      />
                    </S.RowInput>

                    <S.RowInput style={{ width: "calc(100% - 15px)" }}>
                      <SelectOne
                        options={timezones}
                        value={inputsForm.timeZone}
                        placeholder={I18n.get("Fuso horário")}
                        onChange={(value) =>
                          onChangeInputForm("timeZone", value)
                        }
                        required
                        error={hasError({
                          field: "timeZone",
                          arr: inputsErrorsForm,
                        })}
                        clearError={() => {
                          const errorsArr = clearErrorInput({
                            field: "timeZone",
                            arr: inputsErrorsForm,
                          });
                          setInputsErrorsForm(errorsArr);
                        }}
                        // error=""
                      />

                      {contextPage === "edit" && (
                        <p style={{fontSize: 12, color: "#7c7c7c", marginTop: 5}}>
                          {I18n.get(
                            "Ao alterar o fuso horário, será necessário refazer o login"
                          )}
                        </p>
                      )}
                    </S.RowInput>
                  </S.InputsContent>
                </Col>
              </Row>
            )}

            {contextPage !== "edit" && (
              <Row>
                <Col xs={24} style={{ marginTop: 30 }}>
                  <S.TitleSectionForm>
                    {I18n.get("Password registration")}
                  </S.TitleSectionForm>
                </Col>
                <Col xs={24} sm={12}>
                  <Input
                    password
                    placeholder={I18n.get("Password")}
                    required
                    value={inputsForm.passWord}
                    onChange={(value) =>
                      onChangePasswordInputForm("passWord", value)
                    }
                  />
                  <div />
                  <PasswordChecklist
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                    ]}
                    minLength={8}
                    value={inputsForm.passWord}
                    valueAgain={inputsForm.confirmPassWord}
                    messages={{
                      minLength: I18n.get(
                        "Password has at least 8 characters."
                      ),
                      specialChar: I18n.get("Password has special characters."),
                      number: I18n.get("Password has a number."),
                      capital: I18n.get("Password has a capital letter."),
                      match: I18n.get("Passwords match."),
                    }}
                    onChange={(isValid) => setPasswordIsValid(isValid)}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <Input
                    password
                    placeholder={I18n.get("Confirm password")}
                    required
                    value={inputsForm.confirmPassWord}
                    onChange={(value) =>
                      onChangePasswordInputForm("confirmPassWord", value)
                    }
                  />
                </Col>
              </Row>
            )}

            <Row style={{ marginTop: 30 }}>
              <Col xs={24} sm={12}></Col>
              <Col xs={24} sm={12}>
                <ButtonBpool
                  text={I18n.get("Save")}
                  theme="primary"
                  full
                  loading={isSending}
                  onClick={send}
                />
              </Col>
            </Row>
          </Main>
        </Col>
      </Row>
    </div>
  );
};
