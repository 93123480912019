import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import constants from "../../../constants";
import { Link, Redirect } from "react-router-dom";
import AppWrapperSimple from "../../components/AppWrapperRegister";
import ContainerPage from "../../components/ContainerPage";
import SubHeader from "../../components/SubHeader";

import { PARTNER_API } from "../../../actions/partner/actions";

import FormExpertise from "../components/formExpertise";

import { TitleBorder, TitleMiddleLine } from "../../components/Titles";

import { PartnerStatus } from "../../../model";

import styled from "styled-components";

import {
  Row,
  Col,
  Input,
  Table,
  Icon,
  Select,
  Button,
  Tooltip,
  Form,
  Drawer,
  notification,
} from "antd";

const { TextArea } = Input;
const { Option } = Select;

const Btns = styled.p`
  a {
    margin-left: 10px;
  }
`;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  .BtnAction {
    margin-bottom: 20px;
    text-align: right;
  }

  .RowSection {
    margin-bottom: 60px;
  }
`;
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMarketExpertise: (data) => {
      dispatch(PARTNER_API.create_expertise(data));
    },
    delMarketExpertise: (data) => {
      dispatch(PARTNER_API.delete_expertise(data));
    },
    updateMarketExpertise: (data) => {
      dispatch(PARTNER_API.update_expertise(data));
    },
  };
};

class Expertise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      edit: false,
      show: false,
      type: 1,
    };
  }

  hideExpertise = () => {
    this.setState({
      selectedItem: null,
      edit: false,
      show: false,
    });
  };

  showAddExpertise = (type) => {
    this.setState({
      selectedItem: null,
      edit: false,
      show: true,
      type: type,
    });
  };

  showEditExpertise = (item) => {
    this.setState({
      selectedItem: item,
      edit: true,
      show: true,
      type: item.type,
    });
  };

  getSector = (sectorId) => {
    const items =
      this.props.bp.sectorsFlat &&
      this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);

    if (items && items.length) {
      return items[0].label;
    } else {
      return {};
    }
  };

  updateExpertise = (data) => {
    this.props.updateMarketExpertise(data);
  };

  addExpertise = (data) => {
    this.props.addMarketExpertise(data);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.partner.marketExpertises &&
      prevProps.partner.marketExpertises &&
      this.props.partner.marketExpertises.length >
        prevProps.partner.marketExpertises.length
    ) {
      notification.success({
        message: I18n.get("Adicionado com sucesso!"),
        description: I18n.get(
          "O mercado de expertise foi adicionado ao seu cadastro"
        ),
        duration: 3,
      });

      this.hideExpertise();
    }

    if (
      this.props.partner.marketExpertises &&
      prevProps.partner.marketExpertises &&
      this.props.partner.marketExpertises.length <
        prevProps.partner.marketExpertises.length
    ) {
      notification.success({
        message: I18n.get("Removido com sucesso!"),
        description: I18n.get(
          "O mercado de expertise foi removido do seu cadastro"
        ),
        duration: 3,
      });
    }

    if (
      this.props.partner.marketExpertises &&
      prevProps.partner.marketExpertises &&
      this.props.partner.marketExpertises !=
        prevProps.partner.marketExpertises &&
      this.state.selectedItem
    ) {
      notification.success({
        message: I18n.get("Atualizado com sucesso!"),
        description: I18n.get("O mercado de expertise foi atualizado"),
        duration: 3,
      });

      this.setState({
        selectedItem: null,
      });

      this.hideExpertise();
    }
  }

  render() {
    if (
      this.props.partner &&
      this.props.partner.status > PartnerStatus.Incomplete
    ) {
      return (
        <Redirect
          to={`/parceiro/obrigado-pre-cadastro/${this.props.partner.partnerId}`}
        />
      );
    }

    let dataType1 =
      this.props.partner.marketExpertises &&
      this.props.partner.marketExpertises
        .filter((item) => item.type == 1)
        .map((item, index) => {
          return {
            key: index,
            expertise: item.sectorId,
            anos: item.years,
            meses: item.months,
            marcas: item.brands,
            item: item,
          };
        });

    let dataType2 =
      this.props.partner.marketExpertises &&
      this.props.partner.marketExpertises
        .filter((item) => item.type == 2)
        .map((item, index) => {
          return {
            key: index,
            expertise: item.sectorId,
            anos: item.years,
            meses: item.months,
            marcas: item.brands,
            item: item,
          };
        });

    const columns = [
      {
        title: I18n.get("Expertise"),
        dataIndex: "expertise",
        key: "expertise",
        render: (expertise) => this.getSector(expertise),
      },
      {
        title: I18n.get("Anos"),
        dataIndex: "anos",
        key: "anos",
      },
      {
        title: I18n.get("Meses"),
        dataIndex: "meses",
        key: "meses",
      },
      {
        title: I18n.get("Marcas"),
        dataIndex: "marcas",
        key: "marcas",
      },
      {
        title: "",
        dataIndex: "action",
        key: "item",
        render: (text, record) => {
          let data = {
            id: record.item.id,
            partnerId: this.props.match.params.partnerId,
          };

          return (
            <Btns>
              <a onClick={() => this.props.delMarketExpertise(data)}>
                <Icon type="delete" theme="filled" />
              </a>
              <a onClick={() => this.showEditExpertise(record.item)}>
                <Icon type="edit" theme="filled" />
              </a>
            </Btns>
          );
        },
      },
    ];

    //TODO: Find better way... GAMBI!
    let menu = [...constants.MENU_PRE_SIGNUP];
    for (let key in menu) {
      menu[key].label = I18n.get(menu[key].label);
    }

    return (
      <AppWrapperSimple>
        <SubHeader
          titlePage={I18n.get("Pré-cadastro")}
          itensMenu={menu}
          urlId={this.props.match.params.partnerId}
          activeItem={3}
        />
        <ContainerPage>
          <Main>
            <Row>
              <Col sm={24}>
                <TitleBorder>{I18n.get("3 de 5")}</TitleBorder>
              </Col>
            </Row>

            {this.props.partner.company &&
              !this.props.partner.company.isNewCompany && (
                <Fragment>
                  <Row>
                    <Col sm={24}>
                      <TitleMiddleLine>
                        {I18n.get(
                          "Indique pelo menos 3 principais mercados de expertise da sua empresa"
                        )}
                        <Tooltip
                          title={I18n.get(
                            "Indique os principais mercados de atuação da sua empresa. É assim que vamos encontrar projetos e clientes certos para você"
                          )}
                        >
                          <Icon type="question-circle" theme="filled" />
                        </Tooltip>
                      </TitleMiddleLine>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={24}>
                      <p className="BtnAction">
                        <Button
                          type="primary"
                          shape="round"
                          onClick={() => this.showAddExpertise(1)}
                        >
                          {I18n.get("+ Adicionar expertise")}
                        </Button>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24}>
                      <Table
                        scroll={{ x: 640 }}
                        locale={{ emptyText: I18n.get("Sem informação") }}
                        columns={columns}
                        dataSource={dataType1}
                      />
                    </Col>
                  </Row>

                  <Row className="RowSection">
                    <Col sm={8}></Col>
                  </Row>
                </Fragment>
              )}

            <Row>
              <Col sm={24}>
                <TitleMiddleLine>
                  {I18n.get(
                    "Indique pelo menos 3 principais mercados de expertise em sua carreira"
                  )}
                  <Tooltip
                    title={I18n.get(
                      "Acreditamos que um expertise de uma empresa é sempre mais completo quando soma o expertise de carreira do time"
                    )}
                  >
                    <Icon type="question-circle" theme="filled" />
                  </Tooltip>
                </TitleMiddleLine>
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <p className="BtnAction">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => this.showAddExpertise(2)}
                  >
                    {I18n.get("+ Adicionar expertise")}
                  </Button>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Table
                  scroll={{ x: 640 }}
                  locale={{ emptyText: I18n.get("Sem informação") }}
                  columns={columns}
                  dataSource={dataType2}
                />
              </Col>
            </Row>

            <Row className="RowSection">
              <Col sm={8}></Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Link
                  to={`/parceiro/pre-cadastro/especialidades/${this.props.match.params.partnerId}`}
                >
                  <Button shape="round">{I18n.get("Voltar")}</Button>
                </Link>
              </Col>
              <Col sm={18}></Col>
              <Col sm={3}>
                <Link
                  to={`/parceiro/pre-cadastro/trabalhos/${this.props.match.params.partnerId}`}
                >
                  <Button type="primary" shape="round">
                    {I18n.get("Avançar")}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Main>
        </ContainerPage>

        <Drawer
          title={
            this.state.selectedItem
              ? I18n.get("Editar expertise")
              : I18n.get("Adicionar expertise")
          }
          placement="right"
          forceRender={true}
          closable={true}
          onClose={() => this.hideExpertise()}
          destroyOnClose={true}
          visible={this.state.show}
          width="40%"
        >
          <FormExpertise
            partner={this.props.partner && this.props.partner}
            partnerId={this.props.match.params.partnerId}
            action={this.addExpertise}
            update={this.updateExpertise}
            cancel={() => this.hideExpertise()}
            type={this.state.type}
            item={this.state.selectedItem}
            sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
            errors={this.props.partner.errors && this.props.partner.errors}
          />
        </Drawer>
      </AppWrapperSimple>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Expertise);
