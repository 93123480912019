import React from 'react';
import { I18n } from 'aws-amplify';
import { Icon } from 'antd';

class ReviewStepFiles extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    render() {

        return (
            <div>
                <div className="card-box">
                    <div className="card-row">
                        <div className="card">
                            <div className="card-content" style={{ width: "100%" }}>
                                <div>
                                    <div style={{ padding: "20px 15px" }}>
                                        {this.props.pdfBriefing && <div class="columns itemFile line" >
                                            <div class="column is-two-thirds is-clearfix upload-item is-pulled-left" >
                                                <a href={this.props.pdfBriefing} target="_blank"><Icon type="file-text" theme="filled" />{I18n.get("BRIEFING")}</a>
                                            </div>
                                            <div class="column ">
                                                <div className="is-pulled-right">

                                                </div>
                                            </div>
                                        </div>}

                                        {this.props.pdfBundle && <div class="columns itemFile line" >
                                            <div class="column is-two-thirds is-clearfix upload-item is-pulled-left" >
                                                <a href={this.props.pdfBundle} target="_blank"><Icon type="file-text" theme="filled" />{I18n.get("BUNDLE")}</a>
                                            </div>
                                            <div class="column ">
                                                <div className="is-pulled-right">

                                                </div>
                                            </div>
                                        </div>}

                                        {this.props.pdfProject && <div class="columns itemFile line" >
                                            <div class="column is-two-thirds is-clearfix upload-item is-pulled-left" >
                                                <a href={this.props.pdfProject} target="_blank"><Icon type="file-text" theme="filled" />{I18n.get("PROJECT")}</a>
                                            </div>
                                            <div class="column ">
                                                <div className="is-pulled-right">

                                                </div>
                                            </div>
                                        </div>}

                                        {this.props.pdfTep && <div class="columns itemFile line" >
                                            <div class="column is-two-thirds is-clearfix upload-item is-pulled-left" >
                                                <a href={this.props.pdfTep} target="_blank"><Icon type="file-text" theme="filled" />{I18n.get("TEP")}</a>
                                            </div>
                                            <div class="column ">
                                                <div className="is-pulled-right">

                                                </div>
                                            </div>
                                        </div>}

                                        {this.props.files && this.props.files.map((item, index) =>
                                            (<div key={index} class="columns columns itemFile line" >
                                                <div class="column is-clearfix upload-item" >
                                                    <a href={item.url} target="_blank"><Icon type="file-text" theme="filled" /> &nbsp; {item.name}</a>
                                                </div>
                                            </div>)
                                        )}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReviewStepFiles;
