import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import { scrollToTop } from "../../utils/ui";

import { CustomEventBriefingType } from "../../model";

import { Textarea } from "../../v2/components/UI/Textarea";

class BriefingForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        briefing: {
          successIcon: true,
          onChange: this.onChangeForm.bind(this, "briefing"),
          label: I18n.get("Briefing"),
          // onBlur: this.onSave.bind(this),
        },
      },
      errors: {},
      form: { ...customEvent },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Briefing")}</h1>

        <div className="bp-event-section">
          <p>
            {I18n.get(
              "Nos ajude a entender melhor a sua necessidade para que nossos parceiros possam montar uma proposta comercial e criativa para você."
            )}
          </p>

          <br />

          {customEvent.briefingType ==
            CustomEventBriefingType.Communication && (
            <>
              <p>
                {I18n.get(
                  "Antes de mais nada, pode nos contar um pouquinho do seu desafio? Basta dar uma ideia geral, e depois a gente vai perguntando mais detalhes."
                )}
              </p>
            </>
          )}

          {customEvent.briefingType == CustomEventBriefingType.Promotional && (
            <>
              <p>
                {I18n.get(
                  "Dê um pouco de contexto, mas não esqueça de fornecer algumas informações práticas sobre:"
                )}
              </p>

              <p style={{ padding: "1rem" }}>
                &middot; {I18n.get("Material desejado")} <br />
                &middot; {I18n.get("Quantidade")} <br />
                &middot; {I18n.get("Descrição")} <br />
                &middot; {I18n.get("Formato")} <br />
              </p>

              <p>
                {I18n.get(
                  "Se você tiver imagens de referência ou arquivos de suporte, por favor, os adicione mais adiante no campo UPLOAD DE ARQUIVOS."
                )}
              </p>
            </>
          )}

          {customEvent.briefingType != CustomEventBriefingType.Communication &&
            customEvent.briefingType != CustomEventBriefingType.Promotional && (
              <>
                <p>
                  {I18n.get(
                    "Antes de mais nada, pode nos contar um pouquinho do seu evento? Basta dar uma ideia geral, e depois a gente vai perguntando mais detalhes."
                  )}
                </p>
              </>
            )}

          <br />
          <br />

          {/* <TextAreaField {...this.state.fields.briefing} value={this.state.form.briefing} error={this.state.errors.briefing} />
           */}

          <Textarea
            label={this.state.fields.briefing.label}
            defaultValue={this.state.form.briefing}
            value={this.state.form.briefing}
            onChange={(value) => this.state.fields.briefing.onChange(value)}
            error={this.state.errors.briefing?.error}
          />

          {/* <Textarea
            id="overview-company"
            label={I18n.get("Descritivo Hora/Homem")}
            rows={4}
            // counterChar
            defaultValue={defaultValue}
            onChange={(value) => setDescription(value)}
            required
            disabled={isClient}
            // error={hasError("description")}
            // clearError={() => {
            //   const errorsArr = clearErrorInput({
            //     field: "description",
            //     arr: inputsErrorsForm,
            //   });
            //   setInputsErrorsForm(errorsArr);
            // }}
        /> */}
        </div>
      </div>
    );
  }
}

export default BriefingForm;
