import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { BP_DOMAIN_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_domain = (locale) => {

    let endpoint = `${Config.API_BASE_URL}/bp/domain`;

    if (locale != undefined)
        endpoint = `${Config.API_BASE_URL}/bp/domain/${locale}`;

    return (dispatch, getState) => {

        return axios.get(endpoint)
            .then(response => {
                dispatch({
                    type: BP_DOMAIN_GET,
                    payload: response.data
                });
            })
            .catch(error => {
                throw (error);
            });

    };
}

export default api_get_domain;
