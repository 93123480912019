import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink, Redirect } from "react-router-dom";

import { ClientProjectStatus, ProjectExtraTypeNames } from '../../../model';

import { getQuotationStatus } from '../../../utils/extras';

import {
    Table,
    Popconfirm,
    Tooltip,
    Icon
} from 'antd';


import FormatCurrency from '../../ui/format-currency';

export default class ExtrasList extends React.Component {

    constructor(props) {
        super();

        this.state = {
            q: '',
            status: '',
        };

        this.selectProject = this.onSelectProject.bind(this);
    }

    componentDidMount() {

        if (this.props.match.params.projectId) {

            var data = {
                clientId: this.props.clientId,
                projectId: this.props.match.params.projectId
            };

            this.props.get_project(data);
        }
    }

    onSelectProject(item, e) {
        this.setState({ selected: item });
    }


    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientProjectExtraStatusesMap) return '';

        return this.props.bp.clientProjectExtraStatusesMap[value].label;
    }

    render() {

        const currency = this.props.currency || {};

        return (
            <div>

                <h1 className="title title-6">{I18n.get("Extras")}</h1>

                <div className="columns">

                    <div className="column is-12">



                        {this.props.auth.is_client_or_client_user && <div className="alert-bpool">
                            <p><strong><Icon type="bulb" /> {I18n.get('Solicite um Extra!')}</strong> {I18n.get('Se precisar de algum incremento de escopo, produção ou qualquer outro item ao seu projeto, use os campos abaixo para solicitar uma proposta do parceiro.')}</p>
                        </div>}

                        {this.props.auth.is_partner && <div className="alert-bpool">
                            <p><strong><Icon type="bulb" /> {I18n.get('Sugira um Extra!')}</strong> {I18n.get('Se precisar de algum incremento de escopo, produção ou qualquer outro item ao seu projeto, use os campos abaixo para enviar uma proposta ao cliente.')}</p>
                        </div>}


                    </div>

                </div>




                <div className="field is-grouped">


                    {this.props.status != ClientProjectStatus.Canceled && this.props.status != ClientProjectStatus.Finished &&
                        <div className="control">
                            <label className="label is-small">&nbsp;</label>
                            <NavLink to={`/projetos/${this.props.match.params.projectId}/extras/novo`} className="button bt-bpool black">{I18n.get("Novo Extra")}</NavLink>
                        </div>}


                </div>
                <p style={{ marginTop: '1rem' }}>{I18n.get("Na lista abaixo você encontra os itens extras.")}</p>

                <br />

                <div className="card">

                    <div className="card-content" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                        <div className="table-main">
                            <table className="table is-bordered is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>{I18n.get("Tipo")}</th>
                                        <th>{I18n.get("Nome")}</th>
                                        <th>{I18n.get("Budget")}</th>
                                        <th>{I18n.get("Orçamento")}</th>
                                        <th>{I18n.get("#PO")}</th>
                                        <th>{I18n.get("Status")}</th>
                                        <th>{I18n.get("Data Criação")}</th>
                                        <th>{I18n.get("PDF")}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(this.props.extras) && this.props.extras.map((item, index) => {

                                        const { quotation = {} } = item;


                                        const data = {
                                            projectId: this.props.match.params.projectId,
                                            extraId: item.extraId
                                        }

                                        return (
                                            <tr key={index}>
                                                <td onClick={this.selectProject.bind(this, item)}>
                                                    {I18n.get(ProjectExtraTypeNames[item.extraType])}
                                                </td>
                                                <td onClick={this.selectProject.bind(this, item)}>
                                                    {item.extraName}
                                                </td>
                                                <td onClick={this.selectProject.bind(this, item)}>
                                                    <FormatCurrency amount={item.budget} currency={currency} />
                                                </td>
                                                <td onClick={this.selectProject.bind(this, item)}>
                                                    {getQuotationStatus(this.props.auth, quotation)}
                                                </td>
                                                <td onClick={this.selectProject.bind(this, item)} style={{ textAlign: 'left', paddingLeft: '2rem' }}>

                                                    {item.billings && item.billings.map((billing, index) => (billing.projectPO || `Pagamento para ${billing.billingDate}`)).join(', ')}

                                                </td>
                                                <td onClick={this.selectProject.bind(this, item)}>
                                                    {this.getStatus(item.status)}
                                                </td>
                                                <td onClick={this.selectProject.bind(this, item)}>
                                                    {item.createdOn}
                                                </td>
                                                <td>
                                                    {quotation && quotation.isApproved && item.quotationPdf && <a href={item.quotationPdf} target="_blank"><Icon type="file-pdf"></Icon> </a>}
                                                </td>
                                                <td>
                                                    <NavLink to={`/projetos/${this.props.match.params.projectId}/extras/${item.extraId}/detalhes`} className="button bt-bpool quad full">{I18n.get("Detalhes")}</NavLink>
                                                </td>
                                                <td>
                                                    {item.status == 0 || item.status == 1 && quotation.isOpen &&



                                                        <Popconfirm
                                                            title={I18n.get("Deseja deletar esse projeto?")}
                                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                            onConfirm={() => this.props.delete_extra(data)}
                                                            okText={I18n.get("Sim")}
                                                            cancelText={I18n.get("Não")}
                                                        >
                                                            <a>
                                                                <Tooltip title={I18n.get("Deletar extra")}>
                                                                    <Icon type="delete" theme="filled" />
                                                                </Tooltip>
                                                            </a>
                                                        </Popconfirm>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
