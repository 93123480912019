import React from "react";
import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import TextField from "../form/text-field";
import MoneyField from "../form/money-field";
import MaskedField from "../form/masked-field";
import CheckboxField from "../form/checkbox-field";
import SubmitButtonField from "../form/submit-button-field";

import FileUploadList from "../../modules/components/FileUploadList";

import { getQuotationStatus } from "../../utils/extras";

import { Row, Col } from "antd";

import FormatCurrency from "../ui/format-currency";

class ProjectDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    const billing = props.billing || {};

    this.state = {
      fields: {
        authorize: {
          label: I18n.get(
            "Minha empresa não requer PO, autorizo o faturamento para pagamento na data e valor abaixo."
          ),
          onChange: this.onChangeFormCheckbox.bind(this, "authorize"),
          successIcon: false,
          visited: true,
        },
        billingDate: {
          label: I18n.get("Data Pagamento"),
          onChange: this.onChangeForm.bind(this, "billingDate"),
          successIcon: false,
          visited: true,
          mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
          placeholder: I18n.get("DD/MM/AAAA"),
        },
        total: {
          label: I18n.get("Valor"),
          onChange: this.onChangeForm.bind(this, "total"),
          successIcon: false,
          visited: true,
        },
        projectPO: {
          label: I18n.get("#PO"),
          onChange: this.onChangeForm.bind(this, "projectPO"),
          successIcon: false,
          placeholder: I18n.get("Número de pedido ou ordem de compra"),
          visited: true,
        },
        code: {
          label: I18n.get("Código"),
          onChange: this.onChangeForm.bind(this, "code"),
          successIcon: false,
          placeholder: I18n.get("Opcional"),
          visited: true,
        },
        registrationName: {
          label: I18n.get("Razão Social"),
          onChange: this.onChangeForm.bind(this, "registrationName"),
          successIcon: false,
          visited: true,
        },
        registrationId: {
          label: I18n.get("CNPJ"),
          onChange: this.onChangeForm.bind(this, "registrationId"),
          successIcon: false,
          visited: true,
        },
        addressLine1: {
          label: I18n.get("Endereço"),
          onChange: this.onChangeForm.bind(this, "addressLine1"),
          successIcon: false,
          visited: true,
        },
        addressLine2: {
          label: I18n.get("Complemento"),
          onChange: this.onChangeForm.bind(this, "addressLine2"),
          successIcon: false,
          visited: true,
        },
        city: {
          label: I18n.get("Cidade"),
          onChange: this.onChangeForm.bind(this, "city"),
          successIcon: false,
          visited: true,
        },
        state: {
          label: I18n.get("Estado"),
          onChange: this.onChangeForm.bind(this, "state"),
          successIcon: false,
          visited: true,
        },
        postcode: {
          label: I18n.get("CEP"),
          mask: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
          onChange: this.onChangeForm.bind(this, "postcode"),
          successIcon: false,
          visited: true,
        },
      },
      errors: {},
      form: {
        files: [],
      },
      task: {},
      upload_task: {},
      submit_task: {},
      flagUpdate: false,
    };

    this.upload = this.onUpload.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (
      task != prev ||
      this.state.flagUpdate != prevState.flagUpdate ||
      this.props.client != prevProps.client
    ) {
      var nextState = Object.assign({}, prevState, { task: task });

      const company = (this.props.client && this.props.client.company) || {};

      Object.keys(this.state.fields).forEach((item) => {
        const value = company[item];

        nextState.form[item] = value;
      });

      this.setState(nextState);
      this.setState({
        flagUpdate: false,
      });
    }
  }

  componentWillMount() {
    this.setState({
      flagUpdate: false,
    });
  }

  componentDidMount() {
    this.props.load_client();
    this.setState({
      flagUpdate: true,
    });
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeFormCheckbox(prop, e) {
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.fields[prop].checked = checked;
      nextState.form[prop] = checked;
      nextState.errors[prop] = null;

      // reset PO
      if (checked) {
        nextState.form["projectPO"] = "";
      }

      return nextState;
    });
  }

  getData() {
    const { projectId, productionId, extraId } = this.props.match.params;

    var data = {
      projectId: projectId,
      productionId: productionId,
      extraId: extraId,
      ...this.state.form,
    };

    return data;
  }

  onUpload(file, e) {
    const { projectId, productionId, extraId } = this.props.match.params;

    if (!file) return;

    if (e) e.preventDefault();

    var reader = new FileReader();

    reader.onload = (e) => {
      var upload_task = new Task();

      upload_task.start();

      this.setState({ upload_task: upload_task }, function () {
        const data = {
          projectId: projectId,
          productionId: productionId,
          extraId: extraId,
          name: file.name,
          file_type: "billing",
          base64: e.target.result,
        };

        this.props
          .upload_billing_file(data)
          .then((response) => {
            this.setState((prevState) => {
              var nextState = Object.assign({}, prevState);

              nextState.form.files.push(response.data);
              nextState.upload_task = { result: true };

              return nextState;
            });
          })
          .catch((error) => {
            this.setState({ upload_task: { result: false } });
          });
      });
    };
    reader.readAsDataURL(file);
  }

  delFile(item) {
    const { projectId, productionId, extraId } = this.props.match.params;

    var data = {
      projectId: projectId,
      productionId: productionId,
      extraId: extraId,
      ...item,
    };

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.delete_billing_file(data);
    });
  }

  onDrop(files) {
    files = files.map((file) => {
      this.upload(file);
    });
  }

  onCancel() {}

  submitForm(e) {
    e.preventDefault();

    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props
      .create_billing(this.getData())
      .then(({ task = {}, errors = {} }) => {
        this.setState({ submit_task: task, errors: errors });

        if (task.result == true && this.props.onClose) {
          this.props.onClose();
        }
      });
  }

  getDataUploadMult = (files) => {
    this.setState({
      form: {
        ...this.state.form,
        files: files,
      },
    });
  };

  render() {
    let filesNormalize =
      this.state.form.files &&
      this.state.form.files.map((item) => {
        return {
          ...item,
          uid: item.id,
        };
      });

    // let symbol = this.props.currency?.symbolOnly ?? 'R$';

    const billingCurrency = this.props.billingCurrency || {};

    const quotation = this.props.quotation || {};

    const { billingTotal } = this.props.extras;

    return (
      <div>
        {this.props.extraId && (
          <>
            <h4>{I18n.get("Orçamento")}</h4>

            <p>
              <strong>{I18n.get("Status")}:</strong>{" "}
              {getQuotationStatus(this.props.auth, quotation)}
            </p>

            <div
              className="displayValores"
              style={{ background: "#fff", margin: "15px 0 40px" }}
            >
              <p style={{ fontWeight: "normal" }}>
                <span>{I18n.get("Parcial:")} </span>
                <br />
                <span>{this.props?.extraPriceClientWithDiscountFmt}</span>
              </p>

              <p>
                <span style={{ fontWeight: "normal" }}>
                  {I18n.get("Total")}: <br />
                  <span
                    style={{
                      fontSize: 11,
                      marginTop: "-10px",
                      fontWeight: "normal",
                    }}
                  >
                    {this.props.otherTax > 0
                      ? `(${I18n.get("incluyendo gross up")} ${
                          this.props.otherTax
                        }%)`
                      : ""}
                  </span>
                </span>
                <b>{this.props?.extraPriceClientWithDiscountWithOtherTaxFmt}</b>{" "}
              </p>

              {this.props?.assetTax > 0 && (
                <p style={{ fontWeight: "normal", border: 0 }}>
                  <span>{I18n.get("Impostos")} </span>
                  (IVA {this.props?.assetTax}%):{" "}
                  <span>{this.props?.extraPriceClientWithDiscountTaxFmt}</span>
                </p>
              )}
            </div>
          </>
        )}

        <p
          style={{
            fontWeight: "bold",
            fontSize: "120%",
            lineHeight: "2rem",
            borderBottom: "solid 1px #dddddd",
          }}
        >
          {I18n.get("Dados de Faturamento")}
        </p>

        <br />
        <br />

        <div className="project-po-form">
          <Row>
            <Col sm={12}>
              <div className="card">
                <div className="card-content">
                  <CheckboxField
                    {...this.state.fields.authorize}
                    value={this.state.form.authorize}
                    error={this.state.errors.authorize}
                    checked={this.state.form.authorize}
                    // field={<MaskedField {...this.state.fields.billingDate} value={this.state.form.billingDate} error={this.state.errors.billingDate} disabled={!this.state.form.authorize} />}
                  />

                  {this.state.form.authorize && (
                    <>
                      <MaskedField
                        {...this.state.fields.billingDate}
                        value={this.state.form.billingDate}
                        error={this.state.errors.billingDate}
                      />
                    </>
                  )}

                  {!this.state.form.authorize && (
                    <>
                      <TextField
                        {...this.state.fields.projectPO}
                        value={this.state.form.projectPO}
                        error={this.state.errors.projectPO}
                      />
                    </>
                  )}

                  <MoneyField
                    {...this.state.fields.total}
                    currency={billingCurrency}
                    value={this.state.form.total}
                    error={this.state.errors.total}
                  />

                  <TextField
                    {...this.state.fields.code}
                    value={this.state.form.code}
                    error={this.state.errors.code}
                  />

                  <br />

                  <FileUploadList
                    getDataUpload={this.getDataUploadMult}
                    name="files"
                    uploadId={this.props.project.projectId}
                    get2={this.props.get2 || false}
                    fileListGet={filesNormalize || []}
                    output="object"
                  />

                  <br />
                  <br />

                  <p className="title-bpool medium">
                    {I18n.get("Faturamento")}
                  </p>

                  <p>
                    <strong>{I18n.get("Condição Pagamento:")}</strong>{" "}
                    {this.props.project && this.props.project.paymentTermDays}{" "}
                    {I18n.get("dias corridos")}
                  </p>
                </div>
              </div>
            </Col>

            <Col sm={12}>
              <div className="card">
                <div className="card-content">
                  <p className="title-bpool medium">
                    {I18n.get("Dados para Emissão")}
                  </p>

                  <TextField
                    {...this.state.fields.registrationName}
                    value={this.state.form.registrationName}
                    error={this.state.errors.registrationName}
                  />

                  <TextField
                    {...this.state.fields.registrationId}
                    value={this.state.form.registrationId}
                    error={this.state.errors.registrationId}
                  />

                  <TextField
                    {...this.state.fields.addressLine1}
                    value={this.state.form.addressLine1}
                    error={this.state.errors.addressLine1}
                  />

                  <TextField
                    {...this.state.fields.addressLine2}
                    value={this.state.form.addressLine2}
                    error={this.state.errors.addressLine2}
                  />

                  <TextField
                    {...this.state.fields.city}
                    value={this.state.form.city}
                    error={this.state.errors.city}
                  />

                  <TextField
                    {...this.state.fields.state}
                    value={this.state.form.state}
                    error={this.state.errors.state}
                  />

                  <MaskedField
                    {...this.state.fields.postcode}
                    value={this.state.form.postcode}
                    error={this.state.errors.postcode}
                  />

                  {(this.props.auth.is_client ||
                    this.props.auth.is_client_user) && (
                    <SubmitButtonField
                      label={I18n.get("Salvar")}
                      className="button bt-bpool black"
                      onClick={this.submitForm.bind(this)}
                      task={this.state.submit_task}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ProjectDetailsForm;
