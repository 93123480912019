import styled from "styled-components";

export const Wrapper = styled.div``;

export const Inputs = styled.div`
  display: flex;
`;

export const ColumnInput = styled.div``;

export const SubTitle = styled.div`
  margin: 15px 0 30px 0;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props?.disabled ? "#a4a4a4" : "#000000"};
  }

  span {
    width: 70px;
    height: 1px;
    background-color: ${props => props?.disabled ? "#a4a4a4" : "#000000"};
    display: block;
  }
`;