import React, { Component } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import logo from "../../image/logoWOtext.svg";
import { connect } from "react-redux";
import { PROJECT_API } from "../../actions/project/actions";
import { ButtonBpool } from "../../v2/components/UI/ButtonBpool";
import { Textarea } from "../../v2/components/UI/Textarea";

import {
  Form,
  Button,
  Input,
  Table,
  Modal,
  Alert,
  Switch,
  Row,
  Col,
  Rate,
} from "antd";

const ContentRate = styled.div`
  box-shadow: 0px 4px 0px rgb(0 0 0 / 10%);
  border-radius: 20px;
  border: 1px solid;
  border-color: #f2f2f2;
  width: 215px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  svg {
    font-size: 26px;
  }
`;

const HeaderVotes = styled.div`
  background: #000;
  height: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`;

const ContainerVotesHeader = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 60px;
  display: flex;
  height: 80px;

  .logo {
    width: 100px;
  }
`;

const ContainerText = styled.div`
  h2 {
    color: #000;

    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
    text-align: center;
  }

  p {
    color: #000;
    margin-bottom: 25px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 120% */
  }
`;

const ContainerVotes = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background: #fff;
  padding: 50px;
  border-radius: 10px;

  &.bottom {
    margin-bottom: 150px;
  }

  .pdesc {
    padding: 15px;
    display: block;
    border: #37ba00 solid 1px;
    background: #b2ffd1;
  }

  .logo {
    width: 120px;
    margin-top: 20px;
  }

  button {
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  font-size: 30px;
  color: #000;
  font-weight: bold;
  margin-top: 15%;
  padding-bottom: 20px;
  margin-bottom: 30px;
  position: relative;

  &.bottom {
    margin-top: 30px;
  }
  &::before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 8px #000 solid;
    width: 100px;
  }
`;

const RowStarts = styled(Row)`
  margin: 10px 0 30px;
  i {
    font-size: 50px;
  }
`;

const Flag = styled(Switch)`
  &.grey {
    background-color: #ccc;
  }

  &.red {
    background-color: #e80000;
  }

  &.green {
    background-color: #00af08;
  }
`;

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    send_feedback: (data) => {
      return dispatch(PROJECT_API.send_feedback(data));
    },
    get_milistones: (projectId) => {
      dispatch(PROJECT_API.get_milistones(projectId));
    },
    get_project: (data) => {
      dispatch(PROJECT_API.get(data));
    },
    delivery: (data) => {
      dispatch(PROJECT_API.delivery(data));
    },
  };
};

const { TextArea } = Input;

class VotesEmail extends Component {
  constructor(props) {
    super();
    this.state = {
      success: false,
      isSubmit: false,
      textComment: true,
      visible: true,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      statusBlur: false,
    });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data = {
          projectId: this.props.match.params.projectId,
          feedbackId: this.props.match.params.feedbackId,
          grade: values.grade || this.props.match.params.grade,
          prefix: "P",
          comment: values.comment || "",
          commentBpool: values.commentBpool || "",
        };

        setTimeout(() => {
          this.props
            .send_feedback(data)
            .then((response) => {
              if (
                response.milestones.milestones ||
                response.milestones.isMensalFee
              ) {
                this.setState({ success: true });
              } else {
                this.setState({ isSubmit: true });
              }
              this.setState({ visible: false });
            })
            .catch((error) => {
              // alert("erro")
            });
        }, 50);
      }
    });
  };

  confirModal = () => {
    let data = {
      projectId: this.props.match.params.projectId,
      feedbackId: this.props.match.params.feedbackId,
      prefix: "P",
      grade: this.props.match.params.grade,
      comment: "",
      commentBpool: "",
    };

    setTimeout(() => {
      this.props
        .send_feedback(data)
        .then((response) => {
          if (
            response.milestones.milestones ||
            response.milestones.isMensalFee
          ) {
            this.setState({ success: true });
          } else {
            this.setState({ isSubmit: true });
          }
          this.setState({ visible: false });
        })
        .catch((error) => {
          // alert("erro")
        });
    }, 50);
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  onChangeTxt = (value) => {
    setTimeout(
      this.setState({ textComment: value.length > 0 ? false : true }),
      1
    );
  };

  handleDelivery = (e, item) => {
    let data = {
      projectId: this.props.match.params.projectId,
      milestoneId: item.milestoneId,
      flag: e,
      prefix: "P",
    };

    this.props.delivery(data);
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    let dataSource = this.props.project?.milestones?.milestones?.map(
      (item, index) => {
        return {
          key: index,
          name: item.name,
          date: item.estimatedDate,
          item: item,
        };
      }
    );

    const columns = [
      {
        title: I18n.get("Nome"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: I18n.get("Data"),
        dataIndex: "date",
        key: "date",
      },
      {
        title: I18n.get("Parceiro"),
        dataIndex: "partner",
        key: "partner",
        render: (text, record) => {
          let color = "";

          if (record.item.partnerConfirmDelivered === null) {
            color = "grey";
          }

          if (record.item.partnerConfirmDelivered === false) {
            color = "red";
          }

          if (record.item.partnerConfirmDelivered === true) {
            color = "green";
          }

          return (
            <Flag
              className={color}
              checked={record.item.partnerConfirmDelivered}
              onChange={(e) => this.handleDelivery(e, record.item)}
            />
          );
        },
      },
      {
        title: I18n.get("Client"),
        dataIndex: "client",
        key: "client",
        render: (text, record) => {
          let color = "";

          if (record.item.clientConfirmDelivered === null) {
            color = "grey";
          }

          if (record.item.clientConfirmDelivered === false) {
            color = "red";
          }

          if (record.item.clientConfirmDelivered === true) {
            color = "green";
          }

          return (
            <Flag
              className={color}
              disabled={true}
              checked={record.item.clientConfirmDelivered}
              onChange={(e) => this.handleDelivery(e, record.item)}
            />
          );
        },
      },
    ];

    return (
      <div style={{ background: "#F3F3F3", height: "100vh" }}>
        <HeaderVotes>
          <ContainerVotesHeader>
            <img src={logo} className="logo" />
          </ContainerVotesHeader>
        </HeaderVotes>

        {this.state.success && (
          <ContainerVotes>
            <Row>
              <Col sm={24}>
                <ContainerText>
                  <h2>{I18n.get("Avaliação enviada com sucesso!")}</h2>

                  <p>
                    {I18n.get(
                      "Gostaríamos de informar que a sua avaliação foi enviada com sucesso. Agradecemos sinceramente o tempo dedicado para compartilhar suas opiniões e feedback conosco."
                    )}
                  </p>
                  <p>
                    {I18n.get(
                      "Valorizamos imensamente suas contribuições, pois elas são fundamentais para aprimorarmos nossos serviços/produtos e garantir uma experiência ainda melhor para você."
                    )}
                  </p>
                  <p>
                    {I18n.get(
                      "Caso tenha mais alguma observação ou queira fornecer informações adicionais, sinta-se à vontade para nos contatar a qualquer momento."
                    )}
                  </p>
                </ContainerText>
              </Col>
            </Row>
          </ContainerVotes>
        )}

        {!this.state.success && (
          <Form onSubmit={this.handleSubmit}>
            <ContainerVotes>
              {/* <Row>
                                <Col sm={24}>
                                    <Title>{I18n.get("Obrigado pela sua avaliação!")}</Title>
                                </Col>
                                <Col sm={24}>
                                    <p className="pdesc">{I18n.get("Obrigado, já recebemos o seu feedback!")}</p>
                                </Col>
                            </Row> */}

              <RowStarts>
                <Col sm={24}>
                  <ContentRate>
                    <Form.Item label={I18n.get("Avaliação:")}>
                      {getFieldDecorator("grade", {
                        rules: [{ required: false, message: "ops!" }],
                        initialValue: parseInt(
                          this.props.match && this.props.match.params.grade
                        ),
                      })(<Rate />)}
                    </Form.Item>
                  </ContentRate>
                </Col>
              </RowStarts>

              <Row>
                <Col sm={24}>
                  <p>
                    {I18n.get(
                      "Se quiser dar mais detalhes do seu feedback, preencha os campos abaixo de acordo com as atividades registradas no periodo."
                    )}
                  </p>
                  <br />
                </Col>
              </Row>

              <Row>
                <Col sm={24}>
                  <Form.Item>
                    {getFieldDecorator("comment", {
                      rules: [{ required: false, message: "ops!" }],
                      initialValue: "",
                    })(
                      //   <TextArea
                      //     rows={4}
                      //     onChange={(e) => this.onChangeTxt(e.target.value)}
                      //   />

                      <Textarea
                        label={I18n.get(
                          "Use o campo abaixo se quiser dar mais detalhes:"
                        )}
                        rows={4}
                        onChange={(e) => this.onChangeTxt(e)}
                        error=""
                      />
                    )}
                  </Form.Item>

                  <p></p>
                </Col>
              </Row>

              <Row>
                <Col sm={24}>
                  <Form.Item>
                    {getFieldDecorator("commentBpool", {
                      rules: [{ required: false, message: "ops!" }],
                      initialValue: "",
                    })(
                      //   <TextArea
                      //     rows={4}
                      //     onChange={(e) => this.onChangeTxt(e.target.value)}
                      //   />

                      <Textarea
                        label={I18n.get(
                          "Se preferir mandar um comentário privado para o tima da BPool, use este campo"
                        )}
                        rows={4}
                        onChange={(e) => this.onChangeTxt(e)}
                        error=""
                      />
                    )}
                  </Form.Item>

                  <p></p>
                </Col>
              </Row>

              <Row>
                <Col sm={24}>
                  <ButtonBpool
                    text={I18n.get("Enviar")}
                    className="pull-right"
                    theme="primary"
                    htmlType="submit"
                  />
                </Col>
              </Row>
            </ContainerVotes>
          </Form>
        )}
        {!this.state.success && this.state.isSubmit && (
          <ContainerVotes>
            <Row>
              <Col sm={24}>
                <Alert
                  message="Ops! Algo deu errado."
                  description="Tente novamente mais tarde."
                  type="error"
                />
              </Col>
            </Row>
          </ContainerVotes>
        )}
        {/* {this.props.project &&
          this.props.project.milestones &&
          !this.props.project.milestones.isMensalFee && (
            <ContainerVotes className="bottom">
              <Row>
                <Col sm={24}>
                  <Title className="bottom">{I18n.get("Macro Entregas")}</Title>
                  <p>
                    {I18n.get(
                      "Sinalize abaixo se alguma destas macro etapas já foi entregue. Note que apenas a sua coluna (parceiro ou cliente) estará ativa para você"
                    )}
                  </p>
                  <br />
                  <br />
                </Col>
                <Col sm={24}>
                  <Table
                    scroll={{ x: 640 }}
                    locale={{ emptyText: I18n.get("Sem informação") }}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </Col>
              </Row>
            </ContainerVotes>
          )} */}

        {/* <Modal
          title={I18n.get("Confirmar")}
          visible={this.state.visible}
          onOk={this.confirModal}
          onCancel={this.closeModal}
          okText={I18n.get("Sim, confirmo.")}
          cancelText={I18n.get("Não. Vou corrigir")}
        >
          <h2>
            {I18n.get("Você está avaliando com nota")}{" "}
            {this.props.match.params.grade}. {I18n.get("Confirma?")}
          </h2>
        </Modal> */}
      </div>
    );
  }
}

const VotesEmailForm = Form.create({})(VotesEmail);

// export default VotesEmailForm;

export default connect(mapStateToProps, mapDispatchToProps)(VotesEmailForm);
