import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const UPLOAD_ERROR = "Oops! something went wrong with the upload. Try again.";

export const useUpload = () => {
  const state = useSelector((state) => state);

  const uploadFile = async ({ file, idUser, fieldId }) => {
    // file: é o arquivo (único)
    // idUser: é partnerId ou clientId
    // const session = await Auth.currentSession();
    // const token = session && session.idToken.jwtToken;
    const get2 = "geturl2";

    const EndpointGetAuthToUpload = Config.UPLOAD_URL2;

    let idFolderUser = "";

    if (idUser) {
      idFolderUser = idUser;
    } else {
      idFolderUser =
        state?.auth?.clientId || state?.auth?.partnerId || "usernotlogged";
    }

    let fileSplit = file?.name.split(".");
    let fileSafe = file?.name.replace(/[^a-z0-9]/gi, "-");
    let fileNew = `${fileSafe}_${uuidv4()}`;
    if (fileSplit && fileSplit.length > 1) {
      fileNew = fileNew + "." + fileSplit[fileSplit.length - 1];
    }

    let key = `${idFolderUser}/${fileNew}`; //Caminho final do arquivo - contexto/nome-unico-arquivo.txt

    //EndPoint para pegar autorização para subir o arquivo. passar a chave (key) na queryString
    let preSignUrl = `${EndpointGetAuthToUpload}?k=${key}${
      get2 ? "&t=pleader" : ""
    }`;

    // const authHeader = { headers: { Authorization: "Bearer " + token } };

    const response = await axios.get(preSignUrl);

    if (response.status === 200) {
      let { url, fields } = response.data;
      let formData = new FormData();

      //Dados recebidos para autorizar o upload do arquivo
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });

      //arquivo
      formData.append("file", file);

      var config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      const resp = await axios.post(url, formData, config);

      if (resp.status === 204) {
        let data = new Date();
        let urlClean =
          "https://" + response.data.url.split("s3.amazonaws.com/")[1] + "/";

        let dataForm = {
          id: uuidv4(),
          field: fieldId + idFolderUser,
          url: urlClean + response.data.fields.key,
          name: file.name,
          type: file.type,
          // uploadedByProfile: authUser ? authUser.username : `user-not-logged-${idFolderUser}`,
          // uploadedByUsername: authUser
          //   ? authUser.username
          //   : `username-user-not-logged-${idFolderUser}`,
          // uploadedByName: authUser
          //   ? authUser.name
          //   : `name-user-not-logged-${idFolderUser}`,
          uploadedDate: data,
        };

        return dataForm;
      }
    }
  };

  return {
    uploadFile,
  };
};
