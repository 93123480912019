import styled from "styled-components";
import { Alert as AlertAntd } from "antd";

export const Alert = styled.div`
  span {
    color: rgba(0, 0, 0, 0.85);
  }

  button {
    position: absolute;
    top: 8px;
    right: 16px;
    padding: 0;
    overflow: hidden;
    font-size: 12px;
    line-height: 22px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .alert-icon {
    position: absolute;
    top: 10px;
    left: 16px;
  }

  .alert-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    .alert-icon {
      color: #52c41a;
    }
  }
  .alert-error {
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
    .alert-icon {
      color: #f5222d;
    }
  }
  .alert-warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    .alert-icon {
      color: #faad14;
    }
  }

  .alert-with-description {
    position: relative;
    padding: 15px 15px 15px 50px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .message-title {
      font-weight: 600;
    }

    .alert-icon {
      font-size: 22px;
      top: 15px;
      left: 16px;
    }
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  padding: 8px 15px 8px 37px;
  word-wrap: break-word;
  border-radius: 4px;
`;
