import React from "react";

import { I18n } from "aws-amplify";

import FormatCurrency from "../ui/format-currency";

require("./quotation-budget.css");

const ptBR = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
});

class QuotationBudget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { quotation = {}, currency = {}, customEvent = {} } = this.props;

    if (customEvent.budgetType == "NOTSET") {
      return (
        <div className="quotation-budget">
          <table>
            <tbody>
              <tr>
                <td className="item">
                  {I18n.get("Budget máximo autorizado pelo cliente")}:
                </td>
                <td className="amount">{I18n.get("Não informado")}</td>
              </tr>
              <tr>
                <td className="item">{I18n.get("Retenção máxima BPool")}:</td>
                <td className="amount">-</td>
              </tr>
              <tr>
                <td className="item">
                  {I18n.get("Budget máximo disponível para orçamento")}:
                </td>
                <td className="amount">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="quotation-budget">
        <table>
          <tbody>
            <tr>
              <td className="item">
                {I18n.get("Budget máximo autorizado pelo cliente")}:
              </td>
              <td className="amount">
                <FormatCurrency amount={quotation.budget} currency={currency} />
               
              </td>
            </tr>
            <tr>
              <td className="item">{I18n.get("Retenção máxima BPool")}:</td>
              <td className="amount">
                {/* {quotation.bpCommission && quotation.bpCommission.percentage > 0 && quotation.bpCommission.percentageFmt} &middot; */}

                <FormatCurrency
                  amount={
                    quotation.bpCommission && quotation.bpCommission.maxAmount
                  }
                  currency={currency}
                />
                 <br />
                {this.props?.auth?.is_client_or_client_user &&
                  this.props.bpCommission &&
                  this.props.bpCommission?.customProjectRule && (
                    <span
                      style={{
                        fontSize: 11,
                        marginTop: "-10px",
                        fontWeight: "normal",
                      }}
                    >
                      + {I18n.get("Outras Taxas")}{" "}
                      {this.props.bpCommission?.customProjectRule.perc}%
                    </span>
                  )}
              </td>
            </tr>
            <tr>
              <td className="item">
                {I18n.get("Budget máximo disponível para orçamento")}:
              </td>
              <td className="amount">
                <FormatCurrency
                  amount={quotation.workingBudgetMax}
                  currency={currency}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default QuotationBudget;
