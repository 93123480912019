import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import Content from "../../../../components/UI/Content";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import Main from "../../../../components/UI/Main";
import { H2Border } from "../../../../components/UI/Titles";

import { Filters } from "../../components/Filters";
import { ListAccordionsProjects } from "../../components/ListAccordionsProjects";

import * as S from "./styles";

export const FileRepositoryList = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Repositório de arquivos finais")}</H2Border>
            </Col>
          </Row>

          <Main bgColor="#fff" padding="30px">
            <S.HeaderTexts>
              {auth?.is_client_or_client_user ? (
                <>
                  <strong>
                    {I18n.get(
                      "Este repositório de arquivos tem como finalidade armazenar comprovações de entrega dos trabalhos de cada projeto finalizado."
                    )}
                  </strong>
                  <p>
                    {I18n.get(
                      "Não encontrou aqui algum projeto que procura? Provavelmente ele não foi finalizado."
                    )}
                  </p>
                  <p>
                    {I18n.get(
                      "Solicite ao parceiro que carregue as comprovações e, na sequência, finalize o projeto na tela Review dentro do projeto."
                    )}
                  </p>
                  <p>
                    {I18n.get(
                      "Use os campos abaixo para procurar projetos  (seus o de seus colegas) e visualizar links e arquivos."
                    )}
                  </p>
                </>
              ) : (
                <>
                  <strong>
                    {I18n.get(
                      "Este repositório de arquivos tem como finalidade armazenar comprovações de entrega dos trabalhos de cada projeto finalizado."
                    )}
                  </strong>
                  <p>
                    {I18n.get(
                      "Não encontrou aqui algum projeto que procura? Provavelmente ele não foi finalizado. Solicite a finalização ao cliente na tela Review do projeto."
                    )}
                  </p>
                  <p>
                    {I18n.get(
                      "Após a finalização do projeto você ainda pode adicionar material, como cases ou até mesmo material para inscrição de prêmios. Use os campos abaixo para buscar, visualizar ou adicionar arquivos e links."
                    )}
                  </p>
                </>
              )}
            </S.HeaderTexts>

            <Filters />

            <ListAccordionsProjects />
          </Main>
        </div>
      </Content>
    </LoggedPage>
  );
};
