import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import LoggedPage from '../page-templates/logged';

import Menu from './_menu';

import { PARTNER_API } from '../../actions/partner/actions';

import { TitleBorder, TitleMiddleLine } from '../../modules/components/Titles';
import { CardBorder } from '../../modules/components/Cards';

import FormAddReference from '../../modules/Register/components/formAddReference';
import FormAddClient from '../../modules/Register/components/formAddClient';

import ReferencesForm from './references-form';

import styled from 'styled-components';

import {
    Row,
    Col,
    Input,
    Table,
    Icon,
    notification,
    Select,
    Tooltip,
    Modal,
    Button,
    Form,
    Drawer
} from 'antd';


const { TextArea } = Input;
const { Option } = Select;


const Btns = styled.p`
    displa: block;
    width: 50px;
    a {
        margin-left: 10px;
    }
`

const Main = styled.div`
    padding-top: 50px;
    padding-bottom: 90px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    .RowSection {
        margin-bottom: 60px;
    }

    .BtnAction {
        float: right;
        margin-bottom: 15px;
    }
`

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        addClient: data => {
            return dispatch(PARTNER_API.create_client(data));
        },
        updateClient: data => {
            return dispatch(PARTNER_API.update_client2(data));
        },
        delClient: data => {
            return dispatch(PARTNER_API.delete_client(data));
        },
        addReference: data => {
            dispatch(PARTNER_API.create_reference(data));
        },
        updateReference: data => {
            dispatch(PARTNER_API.update_reference(data));
        },
        delReference: data => {
            dispatch(PARTNER_API.delete_reference(data));
        },
        update: data => {
            dispatch(PARTNER_API.update(data));
        },
    };
};

class ReferencesAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleClient: false,
            visibleReference: false,
            editReference: false,
            sendRegister: false,
            visibleTerms: false,
            selectedItemReference: null
        }
    }

    showModal = () => {
        this.setState({
            visibleTerms: true,
        });
    };

    handleCancel = e => {
        this.setState({
            visibleTerms: false,
        });
    };

    closeClient = () => {
        this.setState({
            visibleClient: false,
            visibleReference: false,
            selectedItemReference: null,
            selectedItemClient: null
        })
    }

    getSector = (sectorId) => {
        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }

    finishPreRegister = () => {
        this.setState({
            sendRegister: true
        });
        const data = {
            ...this.props.partner,
            isSubmit: true
        }

        this.props.save(data);
    }


    componentDidUpdate(prevProps, prevState) {
        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.partner.clients && prevProps.partner.clients && this.props.partner.clients.length > prevProps.partner.clients.length) {

            notification.success({
                message: I18n.get('Adicionado com sucesso!'),
                description: 'O cliente foi adicionado ao seu cadastro',
            });

            this.closeClient()
        }

        if (this.props.partner.clients && prevProps.partner.clients && this.props.partner.clients.length < prevProps.partner.clients.length) {

            notification.success({
                message: I18n.get('Removido com sucesso!'),
                description: I18n.get('O cliente foi removido do seu cadastro'),
                duration: 3
            });
        }

        if (this.props.partner.clients && prevProps.partner.clients && this.props.partner.clients != prevProps.partner.clients && this.state.selectedItemClient) {

            notification.success({
                message: I18n.get('Atualizado com sucesso!'),
                description: I18n.get('A cliente foi atualizado'),
                duration: 3,
            });

            this.setState({
                selectedItemClient: null
            })

            this.closeClient();
        }


        if (this.props.partner.references && prevProps.partner.references && this.props.partner.references.length > prevProps.partner.references.length) {

            notification.success({
                message: I18n.get(I18n.get('Adicionado com sucesso!')),
                description: I18n.get('A referência foi adicionada ao seu cadastro'),
                duration: 3
            });

            this.closeClient()
        }

        if (this.props.partner.references && prevProps.partner.references && this.props.partner.references.length < prevProps.partner.references.length) {

            notification.success({
                message: I18n.get('Removido com sucesso!'),
                description: I18n.get('A referência foi removida do seu cadastro'),
                duration: 3
            });
        }

        if (this.props.partner.references && prevProps.partner.references && this.props.partner.references != prevProps.partner.references && this.state.selectedItemReference) {

            notification.success({
                message: I18n.get('Atualizado com sucesso!'),
                description: I18n.get('A referência foi atualizada'),
                duration: 3,
            });

            this.setState({
                selectedItemReference: null
            })

            this.closeClient();
        }

    }

    showEditReference = (item) => {
        this.setState({
            selectedItemReference: item,
            editReference: true,
            visibleReference: true
        });
    }

    showEditClient = (item) => {
        this.setState({
            selectedItemClient: item,
            visibleClient: true
        });
    }

    updateClient = (data) => {
        this.props.updateClient(data);
    }

    addClient = (data) => {
        this.props.addClient(data);
    }

    updateReference = (data) => {
        this.props.updateReference(data);
    }

    addReference = (data) => {
        this.props.addReference(data);
    }

    render() {

        let dataType1 = this.props.partner.clients && this.props.partner.clients.map((item, index) => {
            return {
                key: index,
                name: item.name,
                brand: item.brand,
                product: item.product,
                category: this.getSector(item.category),
                billing: item.billing && item.billing.label,
                non: item.active ? "Sim" : "Não",
                item: item
            }
        });

        let dataType2 = this.props.partner.references && this.props.partner.references.map((item, index) => {
            return {
                key: index,
                name: item.name,
                email: item.email,
                linkedin: item.linkedInUrl,
                year: item.year,
                relation: item.relation && item.relation.label,
                item: item
            }
        });

        const columnsClients = [
            {
                title: I18n.get("Empresa"),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: I18n.get("Marca"),
                dataIndex: 'brand',
                key: 'brand',
            },
            {
                title: I18n.get("Produto"),
                dataIndex: 'product',
                key: 'product',
            },
            {
                title: I18n.get("Categoria"),
                dataIndex: 'category',
                key: 'category',
            },
            {
                title: I18n.get("Faturamento"),
                dataIndex: 'billing',
                key: 'billing',
            },
            {
                title: I18n.get("Non-compete"),
                dataIndex: 'non',
                key: 'non',
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'item',
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                        partnerId: this.props.partner && this.props.partner.partnerId,
                    }

                    return <Btns>
                        <a onClick={() => this.props.delClient(data)}>
                            <Icon type="delete" theme="filled" />
                        </a>
                        <a onClick={() => this.showEditClient(record.item)}>
                            <Icon type="edit" theme="filled" />
                        </a>
                    </Btns>
                }
                // render: (record) => this.props.delMarketExpertise(record.item.id),
            },
        ]

        const columnsReferences = [
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: I18n.get("E-mail"),
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: I18n.get("Linkedin"),
                dataIndex: 'linkedin',
                key: 'linkedin'
            },
            {
                title: I18n.get("Ano"),
                dataIndex: 'year',
                key: 'year'
            },
            {
                title: I18n.get("Relação"),
                dataIndex: 'relation',
                key: 'relation'
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'item',
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                        partnerId: this.props.partner && this.props.partner.partnerId,
                    }

                    return (<Btns>
                        <a onClick={() => this.props.delReference(data)}>
                            <Icon type="delete" theme="filled" />
                        </a>
                        <a onClick={() => this.showEditReference(record.item)}>
                            <Icon type="edit" theme="filled" />
                        </a>
                    </Btns>)
                }
            },
        ]

        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><Link to="/home">{I18n.get("Admin")}</Link></li>
                        <li className="is-active"><Link to="/cadastro">{I18n.get("Cadastro")}</Link></li>
                    </ul>
                </nav>

                <div className="columns references-admin company-admin">

                    <div className="column is-3">
                        <Menu {...this.props} />
                    </div>

                    <div className="column is-9">

                        <Row>
                            <Col sm={24}>
                                <TitleBorder>{I18n.get("Cadastro - clientes & referências")}</TitleBorder>
                            </Col>
                        </Row>


                        <Row gutter={1}>
                            <Col sm={24}>
                                <CardBorder>
                                    <Row>
                                        <Col sm={24}>
                                            <TitleMiddleLine>{I18n.get("Indique pelo menos 3 principais clientes com quem você ou sua empresa trabalhou nos últimos 2 anos")}</TitleMiddleLine>

                                        </Col>
                                        <Col sm={24}>
                                            <p className="BtnAction">
                                                <Button type="primary" onClick={() => this.setState({ visibleClient: true, sendRegister: false })} shape="round">{I18n.get("+ Adicionar cliente")}</Button>
                                            </p>
                                            <br />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={24}>
                                            <Table scroll={{ x: 640 }} locale={{ emptyText: I18n.get('Sem informação') }} columns={columnsClients} dataSource={dataType1} />
                                        </Col>
                                    </Row>
                                </CardBorder>
                            </Col>
                        </Row>

                        <Row className="RowSection">
                            <Col sm={8}>
                            </Col>
                        </Row>


                        <Row gutter={1}>
                            <Col sm={24}>
                                <CardBorder>

                                    <Row>
                                        <Col sm={24}>
                                            <TitleMiddleLine>{I18n.get("Quem fala bem da sua empresa · Insira pelo menos três referências")}<Tooltip title={I18n.get("Vamos enviar um e-mail para essas pessoas com algumas perguntas simples sobre você, considerando capacidades técnicas e relação interpessoal. Fica tranquilo que serão só 5 minutos porque não queremos incomodar ninguém =)")}><Icon type="question-circle" theme="filled" /></Tooltip>
                                            </TitleMiddleLine>
                                            <p className="textHelpSection">{I18n.get("DICA: você precisará de ao menos 3 avaliações pra passar pra próxima fase do cadastro, então insira quantas pessoas quiser. Assim, se alguém não responder a pesquisa, você não fica na mão.")}</p>
                                        </Col>
                                        <Col sm={24}>
                                            <p className="BtnAction">
                                                <Button type="primary" onClick={() => this.setState({ visibleReference: true, sendRegister: false })} shape="round">{I18n.get("+ Adicionar referência")}</Button>
                                            </p>
                                            <br />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={24}>
                                            <Table scroll={{ x: 640 }} locale={{ emptyText: I18n.get('Sem informação') }} columns={columnsReferences} dataSource={dataType2} />
                                        </Col>
                                    </Row>
                                </CardBorder>
                            </Col>
                        </Row>
                    </div>

                </div>

                <Drawer
                    title={this.state.selectedItemClient ? I18n.get("Editar Cliente") : I18n.get("Adicionar Cliente")}
                    placement="right"
                    closable={true}
                    onClose={() => this.closeClient()}
                    destroyOnClose={true}
                    visible={this.state.visibleClient}
                    width="50%"
                >
                    <FormAddClient
                        partner={this.props.partner && this.props.partner}
                        partnerId={this.props.partner && this.props.partner.partnerId}
                        action={this.addClient}
                        update={this.updateClient}
                        onCancel={this.closeClient}
                        sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                        partnerClientBilling={this.props.bp.partnerClientBilling && this.props.bp.partnerClientBilling}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        item={this.state.selectedItemClient}
                        forceRender={true}
                    />
                </Drawer>

                <Drawer
                    title={this.state.selectedItemReference ? I18n.get("Editar referência") : I18n.get("Adicionar referência")}
                    placement="right"
                    closable={true}
                    onClose={() => this.closeClient()}
                    destroyOnClose={true}
                    visible={this.state.visibleReference}
                    width="50%"
                >
                    <FormAddReference
                        partner={this.props.partner && this.props.partner}
                        partnerId={this.props.partner && this.props.partner.partnerId}
                        update={this.updateReference}
                        action={this.addReference}
                        onCancel={this.closeClient}
                        sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                        partnerReferenceRelation={this.props.bp.partnerReferenceRelation && this.props.bp.partnerReferenceRelation}
                        errors={this.props.partner.errors && this.props.partner.errors}
                        item={this.state.selectedItemReference}
                        forceRender={true}
                    />
                </Drawer>

            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesAdmin);
