import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import AppWrapperSimple from '../../components/AppWrapperRegister';
import { Link } from 'react-router-dom'

import PageSuccess from '../../components/PageSuccess';

class TemplateSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <AppWrapperSimple>
                <PageSuccess>
                    <Fragment>
                        <h2>{I18n.get("Cadastro concluído, obrigado pelas informações!")}</h2>
                        <p>{I18n.get("Agora vamos disparar e-mails para as referências profissionais que você indicou e para os líderes (caso você tenha cadastrado) para que eles preencham a parte deles.")}</p>
                        <p></p>
                        <p>{I18n.get("Seja bem vindo a BPool.")}</p>
                        <br />
                       <p>{I18n.get("Bem-vindo(a) a BPool!")}</p>

                        <br />

                        <p>
                        <Link to="/login">{I18n.get("Clique aqui")}</Link> {I18n.get("para acessar a plataforma!")}</p>
                    </Fragment>
                </PageSuccess>
            </AppWrapperSimple>
        );
    }
}

export default TemplateSuccess;
