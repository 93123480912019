import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";

import NumericField from "../form/numeric-field";
import TextAreaField from "../form/textarea-field";
import RadioMultiField from "../form/radio-multi-field";
import MoneyField from "../form/money-field";

import { scrollToTop } from "../../utils/ui";

import FormatCurrency from "../ui/format-currency";

import { Textarea } from "../../v2/components/UI/Textarea";
import { CurrencyInput } from "../../v2/components/UI/CurrencyInput";
import { Input } from "../../v2/components/UI/Input";

class BudgetForm extends React.Component {
  constructor(props) {
    super();

    const customEvent = props.customEvent || {};

    this.state = {
      fields: {
        budgetType: {
          label: I18n.get(
            "Por fim, pensando em investimento, nos ajude com budget total ou por pessoa."
          ),
          name: "budgetType",
          onChange: this.onChangeFormOld.bind(this, "budgetType"),
          // onBlur: this.onSave.bind(this, 'budgetType'),
          values: [],
        },
        budgetTotal: {
          label: I18n.get("Valor Total"),
          onChange: this.onChangeForm.bind(this, "budgetTotal"),
          // onBlur: this.onSave.bind(this, 'budgetTotal'),
        },
        budgetPerson: {
          label: I18n.get("Valor por Pessoa"),
          onChange: this.onChangeForm.bind(this, "budgetPerson"),
          // onBlur: this.onSave.bind(this, 'budgetPerson'),
        },
        participants: {
          label: I18n.get("Número de Participantes"),
          onChange: this.onChangeForm.bind(this, "participants"),
          // onBlur: this.onSave.bind(this, 'participants'),
        },
        budgetExclusions: {
          onChange: this.onChangeForm.bind(this, "budgetExclusions"),
          label: I18n.get("Label"),
          // onBlur: this.onSave.bind(this, 'budgetExclusions'),
        },
        budgetPerItem: {
          label: I18n.get("Valor por Item"),
          onChange: this.onChangeForm.bind(this, "budgetPerItem"),
          // onBlur: this.onSave.bind(this, 'budgetPerson'),
        },
      },
      errors: {},
      form: {
        ...customEvent,
      },
      task: {},
      submit_task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      const customEvent = this.props.customEvent || {};

      Object.keys(this.state.form).forEach((item) => {
        if (item in this.state.fields) {
          return; // local state is king
        }

        nextState.form[item] = customEvent[item];
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.update_custom_event(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e?.target ? e?.target?.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormOld(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        if (checked) {
          nextState.form[prop] = [...nextState.form[prop], value];
        } else {
          nextState.form[prop] = nextState.form[prop].filter(
            (v) => v !== value
          );
        }

        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
      ...this.props.customEvent,
      ...this.state.form,
    };

    if (
      this.props.customEvent.isCommunication &&
      !this.props.customEvent.budgetType
    ) {
      data.budgetType = "TOTAL";
    }

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    var submit_task = new Task();

    submit_task.start();

    this.setState({ submit_task: submit_task });

    this.props.update_custom_event(this.getData()).then(({ task, errors }) => {
      this.setState({ submit_task: task, errors: errors });
    });
  }

  render() {
    const customEvent = this.props.customEvent || {};
    const currency = this.props.currency || {};

    const isCommOrPromo =
      customEvent.isCommunication || customEvent.isPromotional;

    const budgetTypeValues = [
      { code: "NOTSET", label: I18n.get("Sem Budget"), enabled: isCommOrPromo },
      { code: "TOTAL", label: I18n.get("Budget total"), enabled: true },
      {
        code: "PER_PERSON",
        label: I18n.get("Budget por pessoa"),
        enabled: !isCommOrPromo,
      },
    ];

    let budgetExclusionsLabel = null;

    if (customEvent.isCommunication) {
      budgetExclusionsLabel = I18n.get(
        "Sinalize aqui caso haja itens que serão negociados diretamente pela sua empresa e que não entrarão na verba alocada acima (exemplo: itens de produção, veiculação de mídia e etc)"
      );
    } else if (customEvent.isPromotional) {
      budgetExclusionsLabel = I18n.get(
        "Sinalize aqui caso haja itens que serão negociados diretamente pela sua empresa e que não entrarão na verba alocada acima."
      );
    } else {
      budgetExclusionsLabel = I18n.get(
        "Sinalize aqui caso haja itens que serão negociados diretamente pela sua empresa e que não entrarão na verba alocada acima (exemplo: hospedagem, passagens áreas, etc)"
      );
    }

    return (
      <div>
        <h1 className="title-bpool medium">{I18n.get("Budget")}</h1>

        <div className="bp-event-section">
          <RadioMultiField
            {...this.state.fields.budgetType}
            value={this.state.form.budgetType}
            error={this.state.errors.budgetType}
            values={budgetTypeValues.filter((v) => v.enabled)}
          />

          <br style={{ clear: "both" }} />

          {this.state.form.budgetType == "TOTAL" && (
            <>
              {/* <MoneyField
                {...this.state.fields.budgetTotal}
                value={this.state.form.budgetTotal}
                error={this.state.errors.budgetTotal}
                currency={currency}
                style={{ maxWidth: "250px" }}
              /> */}

              <CurrencyInput
                placeholder={this.state.fields.budgetTotal.label}
                required
                value={this.state.form.budgetTotal}
                //   onChange={(value) => onChangeInputForm("total", value)}
                onChange={(value) =>
                  this.state.fields.budgetTotal.onChange(value)
                }
                currency={currency}
                error={this.state.errors?.budgetTotal?.errorMessage}
              />

              {customEvent.isPromotional && (
                <>
                  <MoneyField
                    {...this.state.fields.budgetPerItem}
                    value={this.state.form.budgetPerItem}
                    error={this.state.errors?.budgetPerItem?.errorMessage}
                    currency={currency}
                    style={{ maxWidth: "250px" }}
                  />
                </>
              )}
            </>
          )}

          {this.state.form.budgetType == "PER_PERSON" && (
            <>
              {/* <MoneyField
                {...this.state.fields.budgetPerson}
                value={this.state.form.budgetPerson}
                error={this.state.errors.budgetPerson}
                currency={currency}
                style={{ maxWidth: "250px" }}
              /> */}

              <div style={{ width: 250 }}>
                <CurrencyInput
                  placeholder={this.state.fields.budgetPerson.label}
                  required
                  value={this.state.form.budgetPerson}
                  //   onChange={(value) => onChangeInputForm("total", value)}
                  onChange={(value) =>
                    this.state.fields.budgetPerson.onChange(value)
                  }
                  currency={currency}
                  error={this.state.errors?.budgetPerson?.errorMessage}
                />
              </div>

              {/* <NumericField
                {...this.state.fields.participants}
                value={this.state.form.participants}
                error={this.state.errors.participants}
                style={{ maxWidth: "250px" }}
              /> */}

              <br />

              <div style={{ width: 250 }}>
                <Input
                  // label="teste"
                  placeholder={this.state?.fields?.participants?.label}
                  onChange={(value) =>
                    this.state?.fields?.participants.onChange(value)
                  }
                  value={this.state.form.participants}
                  error={this.state.errors.participants?.errorMessage}
                  type="number"
                />
              </div>

              <p>
                <br />
                <strong>{I18n.get("Budget Total:")}</strong>{" "}
                <FormatCurrency
                  amount={
                    this.props.customEvent &&
                    this.props.customEvent.budgetPersonTotal
                  }
                  currency={currency}
                />
              </p>
            </>
          )}

          <br />
          <br />

          {this.state.form.budgetType != "NOTSET" && (
            <>
              {/* <TextAreaField
                {...this.state.fields.budgetExclusions}
                label={budgetExclusionsLabel}
                value={this.state.form.budgetExclusions}
                error={this.state.errors.budgetExclusions}
              /> */}

              <Textarea
                label={budgetExclusionsLabel}
                defaultValue={this.state.form.budgetExclusions}
                value={this.state.form.briefing}
                onChange={(value) =>
                  this.state.fields.budgetExclusions.onChange(value)
                }
                error={this.state.errors.budgetExclusions}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default BudgetForm;
