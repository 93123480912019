import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PARTNER_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_partner_by_token = (token) => {
    return (dispatch) => {

        return axios.get(`${Config.API_BASE_URL}/partner/t/${token}`)
            .then(response => {
                dispatch({
                    type: PARTNER_GET,
                    payload: response.data
                })
            })
            .catch(error => {
                throw (error);
            });
    };
}

export default api_get_partner_by_token;