import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Modal, Icon, Radio, Tooltip, notification } from "antd";
import { useExtras } from "../../../../../../hooks/useExtras/useExtras.hook";
import { useUpload } from "../../../../../../hooks/useUpload/useUpload.hook";
import Loading from "../../../../../../components/UI/Loading";
import { ButtonBpool } from "../../../../../../components/UI/ButtonBpool";
import { SelectOne } from "../../../../../../components/UI/SelectOne";
import { Input } from "../../../../../../components/UI/Input";
import { CurrencyInput } from "../../../../../../components/UI/CurrencyInput";
import { Textarea } from "../../../../../../components/UI/Textarea";
import { UploadInternal } from "../../../../../../components/UI/UploadInternal";
import { FormatCurrency } from "../../../../../../components/UI/FormatCurrency";
import { ModalBp } from "../../../../../../components/UI/ModalBp/ModalBp";
import { hasError, clearErrorInput } from "../../../../../RegistrationFlow/utils/errorsInput";
import { ModalAssets } from "./components/ModalAssets";

import * as S from "./styles";

export const TableProposal = ({ loading, columns, data, isClient, reload, quoteHistory }) => {
  useEffect(() => {
    console.log('data', data);
  }, [data]);

  let history = useHistory();
  let params = useParams();
  const { postItemExtra, deleteItemExtra, deleteAssetTypeAssetExtra, putAddAssetsInExtra } = useExtras();
  const { uploadFile } = useUpload();
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [addItemValue, setAddItemValue] = useState(null);
  const [assetName, setAssetName] = useState(null);
  const [inputsForm, setInputsForm] = useState({});
  const [errorsInputsForm, setErrorsInputsForm] = useState([]);
  const [showModalDescription, setShowModalDescription] = useState({ open: false, description: '', item: '', file: '' });
  const [fileNewItem, setFileNewItem] = useState({});
  const [isLoadingNewItemExtra, setIsLoadingNewItemExtra] = useState(false);
  const [doubleTaxation, setDoubleTaxation] = useState(false);
  const [modalDelete, setModalDelete] = useState({ open: false, id: null });
  const [isDeleting, setIsDeleting] = useState(false);
  const [assetsToSaveTypeAsset, setAssetsToSaveTypeAsset] = useState([]);
  const [statusQuotation, setStatusQuotation] = useState({ isOpen: null, isApproved: null });

  const findQuotation = () => {
    const finded = data?.quotationHistory?.find((item) => item?.id === quoteHistory);

    if (finded) {
      setStatusQuotation({ isOpen: finded?.isOpen, isApproved: finded?.isApproved })
    }
  };

  useEffect(() => {
    findQuotation()
  }, [data, quoteHistory]);

  const listAddItem = [
    {
      code: 1,
      label: I18n.get("Livre")
    },
    {
      code: 2,
      label: data?.extraType !== 4 ? I18n.get("Externo") : I18n.get("Assets")
    }
  ];

  const listAddItemExternal = [
    // {
    //   code: 1,
    //   label: I18n.get("Livre")
    // },
    {
      code: 2,
      label: data?.extraType !== 4 ? I18n.get("Externo") : I18n.get("Assets")
    }
  ];

  const onChangeDoubleTaxation = (e) => {
    setDoubleTaxation(e.target.value);
  };

  const renderText = (text) => {
    if (text === 0) {
      return text
    } else if (text) {
      return text
    } else {
      return "---"
    }
  };

  const renderTooltipText = (text) => {
    if (text) {
      const tooltipHalf = text?.substring(0, 16);
      return (
        <Tooltip title={text}>
          <span>{`${tooltipHalf}${text?.length >= 16 ? "..." : ""}`}</span>
        </Tooltip>
      )
    } else {
      return "---"
    }
  };

  const handleModalAddItem = () => {
    setShowModalAdd(true)
  };

  const handleCancelModal = () => {
    setShowModalAdd(false);
    setAddItemValue(null);
    setFileNewItem({});
  };

  const handleOkModal = async () => {
    setIsLoadingNewItemExtra(true);

    if (addItemValue === 1 || data?.extraType !== 4 && addItemValue === 2) {
      if (!inputsForm?.quantity && !inputsForm?.unitValue && !inputsForm?.description) {
        const errors = [...errorsInputsForm];
        errors.push({ field: "quantity", errorMessage: I18n.get("Quantity is required") });
        errors.push({ field: "unitValue", errorMessage: I18n.get("Unit value is required") });
        errors.push({ field: "description", errorMessage: I18n.get("Description is required") });
        setErrorsInputsForm(errors);
      } else if (!inputsForm?.quantity) {
        const errors = [...errorsInputsForm];
        errors.push({ field: "quantity", errorMessage: I18n.get("Quantity is required") });
        setErrorsInputsForm(errors);
      } else if (!inputsForm?.unitValue) {
        const errors = [...errorsInputsForm];
        errors.push({ field: "unitValue", errorMessage: I18n.get("Unit value is required") });
        setErrorsInputsForm(errors);
      } else if (!inputsForm?.description) {
        const errors = [...errorsInputsForm];
        errors.push({ field: "description", errorMessage: I18n.get("Description is required") });
        setErrorsInputsForm(errors);
      } else {
        try {
          let fileUploaded = null;

          if (fileNewItem?.name) {
            fileUploaded = await uploadFile({
              file: fileNewItem,
              idUser: "usertestingnow",
              fieldId: `parceiro-${params?.projectId}-new-item-extra-${params.extraId}`,
            });
          }

          const dataNewItem = {
            description: inputsForm?.description,
            doubleTaxation: doubleTaxation,
            extraId: params?.extraId,
            file: fileUploaded,
            itemType: addItemValue === 1 ? "INT" : "EXT",
            price: inputsForm.unitValue,
            projectId: params?.projectId,
            quantity: Number(inputsForm?.quantity),
            quotationId: data?.quotationId,
          }

          await postItemExtra(dataNewItem);
          notification.success({
            message: I18n.get("Adicionado com sucesso!"),
            description: I18n.get("Item foi adicionado com sucesso!"),
            duration: 3,
          });
          handleCancelModal();
          reload();
        } catch (error) {
          notification.error({
            message: I18n.get("ops! Algo deu errado."),
            description: I18n.get("Houve algum erro, tente novamente."),
            duration: 3,
          });
        }
      }
    };

    if (data?.extraType === 4 && addItemValue === 2) {
      try {
        const dataToSend = {
          assets: assetsToSaveTypeAsset,
          projectId: params?.projectId,
          quotationId: data?.quotationId,
          extraId: params?.extraId,
        }

        await putAddAssetsInExtra(dataToSend);
        notification.success({
          message: I18n.get("Adicionado com sucesso!"),
          description: I18n.get("Item foi adicionado com sucesso!"),
          duration: 3,
        });
        handleCancelModal();
        reload();
      } catch (error) {
        notification.error({
          message: I18n.get("ops! Algo deu errado."),
          description: I18n.get("Houve algum erro, tente novamente."),
          duration: 3,
        });
        handleCancelModal();
      }
    };

    setIsLoadingNewItemExtra(false);
  };

  const changeAddItemValue = (item) => {
    setAddItemValue(item);
  };

  const handleDeleteItem = async ({ type, id }) => {
    setIsDeleting(true);
    try {
      if (type === "asset") {
        const dataSend = {
          extraId: params?.extraId,
          projectId: params?.projectId,
          quotationId: data?.quotationId,
          code: id
        };
        await deleteAssetTypeAssetExtra(dataSend);
      } else {
        const dataSend = {
          extraId: params?.extraId,
          projectId: params?.projectId,
          quotationId: data?.quotationId,
          id
        };
        await deleteItemExtra(dataSend);
      }
      reload();
      notification.success({
        message: I18n.get("Deletado com sucesso!"),
        description: I18n.get("Item foi deletado com sucesso!"),
        duration: 3,
      });
    } catch (error) {
      notification.error({
        message: I18n.get("ops! Algo deu errado."),
        description: I18n.get("Houve algum erro, tente novamente."),
        duration: 3,
      });
    }
    setIsDeleting(false);
    setModalDelete({ open: false, id: null });
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleChangeIncludedItems = (items) => {
    const assetsTypeAssetsDefault = data?.itemsTable?.filter((item => {
      if (item?.code) {
        return item
      }
    }));

    const newAssetsToSave = items?.map((item) => ({
      code: item?.code,
      quantity: Number(item?.quantity),
    }));

    let assetsToSave = [];

    if (assetsTypeAssetsDefault?.length) {
      assetsToSave = [...assetsTypeAssetsDefault, ...newAssetsToSave];
    } else {
      assetsToSave = [...newAssetsToSave];
    }

    setAssetsToSaveTypeAsset(assetsToSave);
  };

  return (
    <>
      <S.Table>
        <thead>
          <S.TableRow>
            {columns?.map((column, index) => (
              <S.TableHeader key={index}>{I18n.get(column)}</S.TableHeader>
            ))}
          </S.TableRow>
        </thead>
        <tbody>
          {loading ? (
            <div style={{ lineHeight: "50px" }}>
              <Loading
                text={I18n.get("Loading...")}
                sizeText={14}
                sizeIcon={16}
                color="#000000"
                align="left"
              />
            </div>
          ) : data?.itemsTable?.length ? data?.itemsTable?.map((item, index) => (
            <React.Fragment key={index}>
              <S.TableRow>
                <S.TableData style={{ width: 170 }}>{renderText(item?.itemType === "INT" ? "" : item?.code)}</S.TableData>
                <S.TableData style={{ width: 310 }}>
                  {renderTooltipText(item?.itemType === "INT" ? I18n.get("Item Livre") : item?.itemType === "EXT" ? I18n.get("Item Externo") : item?.label)}
                  <S.ButtonOpenDescription onClick={() => setShowModalDescription({
                    open: true,
                    description: item?.description,
                    item: item?.itemType === "INT" ? I18n.get("Item Livre") : item?.itemType === "EXT" ? I18n.get("Item Externo") : item?.label,
                    file: item?.file,
                  })}>
                    <Icon type="eye" />
                  </S.ButtonOpenDescription>
                </S.TableData>
                <S.TableData style={{ width: 100 }}>{renderText(item?.quantity)}</S.TableData>
                <S.TableData style={{ width: 180 }}>{FormatCurrency({ amount: item?.price, currency: data?.currency })}</S.TableData>
                <S.TableData style={{ width: 180 }}>{FormatCurrency({ amount: item?.priceSubTotal || item?.total, currency: data?.currency })}</S.TableData>
                {isClient ? null : statusQuotation?.isOpen ? (
                  <S.TableData>
                    {isDeleting && modalDelete?.id === item?.id || modalDelete?.id === item?.code ? (
                      <Loading
                        text={I18n.get("")}
                        sizeText={14}
                        sizeIcon={16}
                        color="#000000"
                        align="left"
                      />
                    ) : (
                      <S.ButtonDeleteItem onClick={() => setModalDelete({ open: true, id: item?.itemType ? item?.id : item?.code, type: item?.itemType ? "free" : "asset" })}>
                        <Icon type="delete" />
                      </S.ButtonDeleteItem>
                    )}
                  </S.TableData>
                ) : null}
              </S.TableRow>
            </React.Fragment>
          )) : (<div style={{ padding: "15px 0" }}>{I18n.get("No assets added yet.")}</div>)}
          <S.TableRow lastRow>
            <S.TableData>
              <strong />
            </S.TableData>
            {isClient ? null : <S.TableData>
              <strong />
            </S.TableData>}
            {isClient ? null : <S.TableData>
              <strong />
            </S.TableData>}
            {!isClient ? null : <S.TableData>
              <strong />
            </S.TableData>}
            <S.TableData>
              <strong />
            </S.TableData>
            {isClient ? null : (
              <S.TableData>
                <strong>
                  {FormatCurrency({ amount: data?.balance, currency: data?.currency })}
                </strong>
              </S.TableData>
            )}
            <S.TableData>
              <strong />
            </S.TableData>
            {!isClient ? null : (
              <S.TableData>
                <strong>
                  {FormatCurrency({ amount: data?.balance, currency: data?.currency })}
                </strong>
              </S.TableData>
            )}
          </S.TableRow>
        </tbody>
      </S.Table>

      {!isClient && statusQuotation?.isOpen ? (
        <S.ButtonContainer>
          <ButtonBpool
            text={I18n.get('Add Item')}
            theme="primary"
            full={false}
            onClick={handleModalAddItem}
          />
        </S.ButtonContainer>
      ) : null}

      <Modal
        title={I18n.get("Add Item")}
        visible={showModalAdd}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        centered
        width={addItemValue === 1 || addItemValue === null || data?.extraType !== 4 && addItemValue === 2 ? 800 : 1200}
        footer={[
          <S.ModalFooter style={{ display: addItemValue ? "flex" : "none" }}>
            <ButtonBpool
              text={I18n.get('Add')}
              theme="primary"
              full={false}
              loading={isLoadingNewItemExtra}
              onClick={handleOkModal}
            />
          </S.ModalFooter>
        ]}
      >
        <Row>
          <Col xs={addItemValue === 1 || addItemValue === null || data?.extraType !== 4 && addItemValue === 2 ? 12 : 8} span={addItemValue === 1 || addItemValue === null ? 12 : 8} style={{ height: 80, paddingLeft: 0 }}>
            <SelectOne
              options={data?.extraType === 5 ? listAddItemExternal : listAddItem}
              value={addItemValue}
              onChange={(value) => changeAddItemValue(value)}
              placeholder={I18n.get('Type')}
              error=""
            />
          </Col>
          {addItemValue === 1 && data?.extraType === 4 || data?.extraType !== 4 && addItemValue === 1 || data?.extraType !== 4 && addItemValue === 2 ? (
            <>
              <Col xs={12} span={12} style={{ paddingLeft: 0 }}>
                <Input
                  placeholder={I18n.get('Quantity')}
                  label
                  value={inputsForm.quantity}
                  onChange={(value) => onChangeInputForm('quantity', value)}
                  required
                  typeInput="number"
                  error={hasError({ arr: errorsInputsForm, field: "quantity" })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "quantity",
                      arr: errorsInputsForm,
                    });
                    setErrorsInputsForm(errorsArr);
                  }}
                />
              </Col>
              <Col xs={24} span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Textarea
                  id="description"
                  label={I18n.get("Description")}
                  rows={2}
                  maxLength={250}
                  counterChar
                  defaultValue={inputsForm.description}
                  onChange={(value) =>
                    onChangeInputForm("description", value)
                  }
                  required
                  error={hasError({ arr: errorsInputsForm, field: "description" })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "description",
                      arr: errorsInputsForm,
                    });
                    setErrorsInputsForm(errorsArr);
                  }}
                />
              </Col>
              <Col xs={12} span={12} style={{ paddingLeft: 0 }}>
                <CurrencyInput
                  placeholder={I18n.get('Unit value')}
                  required
                  value={inputsForm.unitValue}
                  onChange={(value) => onChangeInputForm("unitValue", value)}
                  currency={data?.currency}
                  error={hasError({ arr: errorsInputsForm, field: "unitValue" })}
                  clearError={() => {
                    const errorsArr = clearErrorInput({
                      field: "unitValue",
                      arr: errorsInputsForm,
                    });
                    setErrorsInputsForm(errorsArr);
                  }}
                />
              </Col>
              <Col xs={12} span={12} style={{ paddingRight: 0 }}>
                <CurrencyInput
                  placeholder={I18n.get('Total')}
                  value={inputsForm.quantity * inputsForm.unitValue}
                  onChange={(value) => onChangeInputForm("total", value)}
                  currency={data?.currency}
                  disabled={true}
                />
              </Col>
              {addItemValue === 2 ? (
                <Col xs={24} span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <S.DoubleTaxationContent>
                    <S.DoubleTaxationText>
                      <p>{I18n.get("Double Taxation")}</p>
                      <Tooltip placement="rightTop" title={I18n.get("Selecione 'Não' se o fornecedor for faturar direto. Se sua empresa precisar adiantar o pagamento ao fornecedor, por exemplo, selecione 'Sim'. Isso irá acarretar em bitributação.")}>
                        <Icon type="question-circle" />
                      </Tooltip>
                    </S.DoubleTaxationText>
                    <Radio.Group onChange={onChangeDoubleTaxation} value={doubleTaxation}>
                      <Radio value={true}>{I18n.get("Yes")}</Radio>
                      <Radio value={false}>{I18n.get("No")}</Radio>
                    </Radio.Group>
                  </S.DoubleTaxationContent>
                </Col>
              ) : null}
              <Col xs={24} span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <UploadInternal
                  multiple={false}
                  cbFiles={(item) => setFileNewItem(item)}
                />
              </Col>
            </>
          ) : null}
          {addItemValue === 2 && data?.extraType === 4 ? (
            <Col xs={24} span={24} style={{ paddingLeft: 0 }}>
              <ModalAssets
                handleChangeIncludedItems={handleChangeIncludedItems}
              />
            </Col>
          ) : null}
        </Row>
      </Modal>

      <Modal
        title={I18n.get("Asset Description")}
        visible={showModalDescription?.open}
        onOk={() => setShowModalDescription({ open: false, description: '', item: '', file: '' })}
        onCancel={() => setShowModalDescription({ open: false, description: '', item: '', file: '' })}
        centered
        width={800}
        footer={[
          <S.ModalFooter>
            <ButtonBpool
              text={I18n.get('Close')}
              theme="primary"
              full={false}
              onClick={() => setShowModalDescription({ open: false, description: '', item: '', file: '' })}
            />
          </S.ModalFooter>
        ]}
      >
        <S.ContentDescription style={{ marginTop: 0 }}>
          <div>
            <strong>
              {I18n.get("Description")}
            </strong>
            <p>
              {showModalDescription?.description}
            </p>
          </div>
          <div style={{ marginTop: 15 }}>
            <strong>
              {I18n.get("Item")}
            </strong>
            <p>
              {showModalDescription?.item}
            </p>
          </div>
          <div style={{ marginTop: 15 }}>
            <strong>
              {I18n.get("File")}
            </strong>
            <p>
              {showModalDescription?.file?.url ? (
                <div>
                  <a href={showModalDescription?.file?.url} target="_blank" rel="noopener noreferrer">
                    <Icon type="file-image" />
                    <span style={{ marginLeft: 5 }}>{showModalDescription?.file?.name}</span>
                  </a>
                </div>
              ) : (
                <div>
                  {I18n.get("Nenhum arquivo adicionado")}
                </div>
              )}
            </p>
          </div>
        </S.ContentDescription>
      </Modal>

      <ModalBp
        visible={modalDelete.open}
        bodyText={I18n.get("Do you want to delete?")}
        subBodyText={I18n.get("This action is irreversible.")}
        okText={I18n.get("Delete")}
        handleClickOk={() => handleDeleteItem({ type: modalDelete.type, id: modalDelete.id })}
        isDanger={true}
        typeModal="isConfirm"
        cancelText={I18n.get("Cancel")}
        onCancel={() => setModalDelete({ open: false, id: null })}
        isLoading={isDeleting}
      />
    </>
  );
};
