import React from "react";
import { I18n } from "aws-amplify";

import Steps from "../../../../../../components/UI/Steps";

export const StepsSelectPartner = ({ active, disabledTabs = [], projectId, partnerId }) => {
  const stepItens = [
    {
      key: 1,
      label: I18n.get("Escolha de Parceiros"),
      url: `/cliente/projetos/v2/selecionar-parceiro/${projectId}/passo-1`,
    },
    {
      key: 2,
      label: I18n.get("Confirmação"),
      url: `/cliente/projetos/v2/selecionar-parceiro/${projectId}/passo-2/${partnerId}`,
    },
    {
      key: 3,
      label: I18n.get("Justificativa"),
      url: `/cliente/projetos/v2/selecionar-parceiro/${projectId}/passo-3`,
    },
  ];

  return (
    <Steps itens={stepItens} active={active} disabledTabs={disabledTabs} />
  );
};
