import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BP_API } from '../../actions/bp/actions';

import LoggedPage from '../page-templates/loggedAdmin';
import { CardSimple } from '../../modules/components/Cards';
import { TitleBorder } from '../../modules/components/Titles';

import { Heatmap, Column } from '@ant-design/charts';

import { formatCurrency, formatMoneyGraph } from '../../utils/helpers'

import FormFilterDash from '../../modules/Admin/components/filterDashAdminA';

import styled from "styled-components";

import {
    Chart,
    Geom,
    Axis,
    Coord,
    Guide,
} from "bizcharts";

import {
    Row,
    Col,
    Card,
    Icon,
    Tooltip,
    Table,
    Tag,
    DatePicker,
    Drawer,
    Skeleton,
    Button,
    Checkbox
} from 'antd';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_countrys: () => {
            dispatch(BP_API.get_countrys());
        },
        status_summary: (data) => {
            dispatch(BP_API.status_summary(data));
        },
        feedback_summary: (data) => {
            dispatch(BP_API.feedback_summary(data));
        }        
    };
};


const CardDisplay = styled(Card)`
    margin-bottom: 20px;
    height: 100%;

    .DescriptionPage {
        font-size: 14px;
    }

    .LabelPeriodo {
        font-size: 12px;
        font-weight: bold;
    }

    &.TableBox {
        min-height: 335px;
    }

    .GraphCard {
        opacity: 1;
        transition: opacity 2s ease;
    }

    .hidden {
        opacity: 0;
    }

    .show {
        opacity: 1;
    }

    .LabelCard {
        text-transform: uppercase;
        font-size: 10px;
        margin-bottom: -10px;
    }

    h2 {
         font-size: 14px;
         text-transform: uppercase;
         margin-bottom: 20px;
         padding-left: 15px;
         border-left: solid 5px #000;
         span {
             display: block;
             color: #999;
             font-size: 11px;
         }

         &.title-span {
             line-height: 18px;
         }
    }

    .NumberCard {
        display: block;
        text-align: center;
        font-size: 22px;
        line-height: 60px;
    }

    .DescriptionCard {
        span {font-weight: bold;}
        border-top: solid 1px #b5b5b5;
        padding-top: 10px;
        margin-top: 15px;
        font-size: 12px;
    }

    .BtnCard {
        border-top: solid 1px #ccc;
        padding-top: 15px;
        margin-top: 10px;
        display: none;
    }

    .LoadingCard {
        text-align: center;
        margin: 36px 0 33px;

        .noDate {
            display: block;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
        }

        i {
            font-size: 50px;
            opacity: .4;
        }

        &.LabelG {
            position: absolute;
            top: 38%;
            left: 0;
            width: 100%;

            &.noDateView {
                top: 32%;
            }

        }
    }
`;

class DashboardAdmin extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount = () => {
        this.props.get_countrys();

        const dataInit = {
            countryCode: "BRA"
        }

        this.props.status_summary(dataInit);
        this.props.feedback_summary(dataInit);
        


    }

    renderCallBack(arr) {

        if (arr == undefined) {
            return (
                <p className="LoadingCard LabelG">
                    <Icon type="loading" />
                </p>
            )
        } else if (arr && arr.length == 0) {
            return (
                <p className="LoadingCard LabelG noDateView">
                    <Icon type="inbox" />
                    <span className="noDate">{I18n.get("Sem informações")}</span>
                </p>
            );
        }

    }

    handleFilters = (data) =>{
        const dataInit = {
            countryCode: data.country
        }

        this.props.status_summary(dataInit);
        this.props.feedback_summary(dataInit);
    }

    render() {
        const {
            statusSummary = [],
            feedbackSummary
        } = this.props.bp;

      
        const feedbackClientData = this.props.bp.feedbackSummary && this.props.bp.feedbackSummary.feedbackClient.map(i => {
            return ({
                name: i.name,
                value: i.value,
            })
        })

        const feedbackPartnerData = this.props.bp.feedbackSummary && this.props.bp.feedbackSummary.feedbackPartner.map(i => {
            return ({
                name: i.name,
                value: i.value,
            })
        })

        const feedbackClientBrandData = this.props.bp.feedbackSummary && this.props.bp.feedbackSummary.feedbackClientBrand.map(i => {
            return ({
                name: i.name,
                value: i.value,
            })
        })

        const feedbackClientUserData = this.props.bp.feedbackSummary && this.props.bp.feedbackSummary.feedbackClientUser.map(i => {
            return ({
                name: i.name,
                value: i.value,
            })
        })


        return (
            <LoggedPage {...this.props}>

                <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li><NavLink to="/admin" className="is-black">{I18n.get("Admin")}</NavLink></li>
                        <li className="is-active"><NavLink to="/bpool/dashboard" className="is-black">{I18n.get("Dashboard")} A</NavLink></li>
                    </ul>
                </nav>

                <Row>
                    <Col sm={24}>
                        <CardSimple>
                            <FormFilterDash
                                filters={this.props.bp && this.props.bp.adminCountrys}
                                action={this.handleFilters}
                                task={this.state.task}
                            />
                        </CardSimple>
                    </Col>
                </Row>
                <br />
                 <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>
                            {I18n.get("Status_3")}
                        </TitleBorder>
                    </Col>
                </Row>

                <Row type="flex" style={{ marginBottom: "40px" }}>
                    <Col sm={24}>
                        <CardDisplay>

                        <Column 
                            data={statusSummary || []}
                            xField= 'statusName'
                            yField= 'total'
                            tooltip= {{
                                customContent: (statusDescription, data) => {
                                    const text = data.map((item) => {
                                        return item.data.statusDescription;
                                    })

                                    return (
                                        <p>
                                            {text[0]}
                                        </p>
                                    )
                                }}
                            }
                            xAxis= {{
                                visible: true,
                                line: {
                                    visible: true
                                },
                                label: {
                                    // rotate:70,
                                    autoRotate: true,
                                    autoHide: false,
                                    style: {
                                        fontSize: 10
                                    },
                                }
                            }}
                            
                            label= {{
                                position:'middle',
                                content: function content(item) {
                                    return item.total;
                                },
                                style:{ fill: '#fff' },
                            }}  
                            />                 
                            </CardDisplay>
                    </Col>
                </Row>

                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>
                            {I18n.get("Feedback global")}
                        </TitleBorder>
                    </Col>
                </Row>

                <Row type="flex" style={{ marginBottom: "40px" }}>

                    <Col md={4} style={{marginBottom: "20px"}}>
                        <CardDisplay>
                            <h2 className="title-span">{I18n.get("Cliente")}</h2>

                            <p className="NumberCard">
                                {feedbackSummary && feedbackSummary.clientAvg.votedAverage}
                            </p>                                    
                        </CardDisplay>
                    </Col>

                    <Col md={4} style={{marginBottom: "20px"}}>
                        <CardDisplay>
                            <h2 className="title-span">{I18n.get("Parceiro")}</h2>

                            <p className="NumberCard">
                                {feedbackSummary && feedbackSummary.partnerAvg.votedAverage}
                            </p>                                    
                        </CardDisplay>
                    </Col>
                   
                    <Col md={4} style={{marginBottom: "20px"}}>
                        <CardDisplay>
                            <h2 className="title-span">{I18n.get("BPool")}</h2>

                            <p className="NumberCard">
                                {feedbackSummary && feedbackSummary.bPoolAvg.votedAverage}
                            </p>                                    
                        </CardDisplay>
                    </Col>
                </Row>
                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>
                            {I18n.get("Feedback por Cliente/Parceiro")}
                        </TitleBorder>
                    </Col>
                </Row>

                <Row>
                    <Col sm={24}>
                        <CardDisplay>
                            <h2>{I18n.get("Clientes")}</h2>
                            <Column 
                                data={feedbackClientData || []}
                                xField= 'name'
                                yField= 'value'
                                xAxis= {{
                                    visible: true,
                                    line: {
                                        visible: true
                                    },
                                    label: {
                                        // rotate:70,
                                        autoRotate: true,
                                        autoHide: false,
                                        style: {
                                            fontSize: 10
                                        },
                                    }
                                }}
                                label= {{
                                    position:'middle',
                                    content: function content(item) {
                                        return item.value;
                                    },
                                    style:{ fill: '#fff' },
                                }}  
                            />

                            {this.renderCallBack(feedbackClientData)}
                        </CardDisplay>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <CardDisplay>
                            <h2>{I18n.get("Parceiros")}</h2>
                            <Column 
                                data={feedbackPartnerData || []}
                                xField= 'name'
                                yField= 'value'
                                xAxis= {{
                                    visible: true,
                                    line: {
                                        visible: true
                                    },
                                    label: {
                                        // rotate:70,
                                        autoRotate: true,
                                        autoHide: false,
                                        style: {
                                            fontSize: 10
                                        },
                                    }
                                }}
                                label= {{
                                    position:'middle',
                                    content: function content(item) {
                                        return item.value;
                                    },
                                    style:{ fill: '#fff' },
                                }} 
                            />

                            {this.renderCallBack(feedbackPartnerData)}
                        </CardDisplay>
                    </Col>
                </Row>


                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>
                            {I18n.get("Feedback por Marcas")}
                        </TitleBorder>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <CardDisplay>
                            <h2>{I18n.get("Marcas")}</h2>
                            <Column 
                                data={feedbackClientBrandData || []}
                                xField= 'name'
                                yField= 'value'
                                xAxis= {{
                                    visible: true,
                                    line: {
                                        visible: true
                                    },
                                    label: {
                                        // rotate:70,
                                        autoRotate: true,
                                        autoHide: false,
                                        style: {
                                            fontSize: 10
                                        },
                                    }
                                }}
                                label= {{
                                    position:'middle',
                                    content: function content(item) {
                                        return item.value;
                                    },
                                    style:{ fill: '#fff' },
                                }}  
                            />

                            {this.renderCallBack(feedbackClientBrandData)}
                        </CardDisplay>
                    </Col>
                </Row>


                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>
                            {I18n.get("Feedback por usuário")}
                        </TitleBorder>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <CardDisplay>
                            <h2>{I18n.get("Usuários")}</h2>
                            <Column 
                                data={feedbackClientUserData || []}
                                xField= 'name'
                                yField= 'value'
                                xAxis= {{
                                    visible: true,
                                    line: {
                                        visible: true
                                    },
                                    label: {
                                        // rotate:70,
                                        autoRotate: true,
                                        autoHide: false,
                                        style: {
                                            fontSize: 10
                                        },
                                    }
                                }}
                                label= {{
                                    position:'middle',
                                    content: function content(item) {
                                        return item.value;
                                    },
                                    style:{ fill: '#fff' },
                                }}  
                            />

                            {this.renderCallBack(feedbackClientUserData)}
                        </CardDisplay>
                    </Col>
                </Row>


                <Row style={{marginTop:"20px"}}>
                    <Col sm={24}>
                        <TitleBorder>
                            {I18n.get("Clientes VS Parceiros")}
                        </TitleBorder>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} style={{minHeight: "600px"}}>
                        <CardDisplay>
                            {this.props.bp.feedbackSummary && this.props.bp.feedbackSummary.feedbackClientVsPartner.length > 0 &&
                             <Heatmap 
                             data={this.props.bp.feedbackSummary && this.props.bp.feedbackSummary.feedbackClientVsPartner || []}
                             xField='clientName'
                             yField='partnerName'
                             colorField= 'value'
                             shape= 'circle'
                             sizeRatio={0.9}
                             height={620}

                             color={value => {
                                 if (value >= 0 && value < 3){
                                    return "red";
                                 }
                                 if (value >= 3 && value < 4){
                                    return "orange";
                                 } 
                                 if (value >= 4) {
                                     return "green";
                                 }
                             }}
                              yAxis= {{

                                  label: {
                                      style: {
                                          fontSize: 10
                                        },
                                        autoHide: false,
                                        
                                    }
                                }
                            }
                            xAxis= {{

                                  label: {
                                      autoRotate: true,
                                      style: {
                                          fontSize: 10
                                        },
                                        autoHide: false,
                                        
                                    }
                                }
                            }

                             label={{
                                 offse:"-2",
                                 style:{
                                     fill: '#fff',
                                     shadowBlur: 2,
                                     shadowColor: 'rgba(0, 0, 0, .45)',
                                    }
                                    
                                }
                            }
                            />
                        }
                        </CardDisplay>
                    </Col>
                </Row>
            </LoggedPage>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdmin);
