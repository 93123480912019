import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useMeetings = () => {
  const getMeetings = async ({ meetingStatus, inviteStatus, meetingType }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project-meeting/invite?page=1&pageSize=10&meetingStatus=${meetingStatus}&inviteStatus=${inviteStatus}&meetingType=${meetingType}`,
      config
    );

    return data;
  };
  const getMeetingsAll = async ({ meetingType }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project-meeting/invites?meetingType=${meetingType}`,
      config
    );

    return data;
  };

  const partnerAcceptMeeting = async (dataSend) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project-meeting/partner/invites/accept`,
      { ...dataSend },
      config
    );

    return data;
  };

  const getMeetingsByProjectId = async (projectId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project-meeting/${projectId}`,
      config
    );

    return data;
  };

  const postMeeting = async ({
    projectId,
    startTime,
    endTime,
    meetingDate,
    meetingType,
  }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project-meeting`,
      { projectId, startTime, endTime, meetingDate, type: meetingType },
      config
    );

    return data;
  };

  const updateMeeting = async ({
    id,
    projectId,
    startTime,
    endTime,
    meetingDate,
  }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/client-project-meeting`,
      { id, projectId, startTime, endTime, meetingDate },
      config
    );

    return data;
  };

  const deleteMeeting = async (meetingId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/client-project-meeting/${meetingId}`,
      config
    );

    return data;
  };

  const getAvailablePartners = async (projectId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/client-project/${projectId}/partners`,
      config
    );

    return data;
  };

  const getMeetingsAdmin = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/bp/meeting-invites`,
      form,
      config
    );

    return data;
  };

  const toInviteMeeting = async (projectId, arrPartners) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/client-project-meeting/${projectId}/invite`,
      arrPartners,
      config
    );

    return data;
  };

  const getTimezones = async () => {
    const { data } = await axios.get(`${Config.API_BASE_URL}/v2/timezones`);
    return data;
  };

  return {
    getMeetings,
    getMeetingsAll,
    partnerAcceptMeeting,
    getMeetingsByProjectId,
    postMeeting,
    updateMeeting,
    deleteMeeting,
    getAvailablePartners,
    toInviteMeeting,
    getTimezones,
    getMeetingsAdmin,
  };
};
