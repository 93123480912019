import React from 'react';
import { I18n } from 'aws-amplify';
import { CHAT_API } from './../../actions/chat/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    chat: state.chat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    send_message: (channelId, msg) => {
      dispatch(CHAT_API.send_message(channelId, msg));
    },
  };
};

class MessagesForm extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  componentDidMount() {
    // this.props.join_project_chat(this.props.match.params.projectId);
    // this.sendMessage("TESTE FROM PROJETO");
  }

  sendMessage(msg) {
    const channelId = 'project_chat_' + this.props.match.params.projectId;
    this.props.send_message(channelId, msg);
  }

  render() {
    return (
      <div>
        <h1 className="title title-5">{I18n.get('Mensagens')}</h1>

        <br />
        <br />

        <div class="columns">
          <div class="column is-full"></div>
        </div>

        <div class="columns">
          <div class="column is-full"></div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesForm);

//export default MessagesForm;
