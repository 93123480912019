import React, { useCallback, useMemo, useState } from "react";
import { Input as InputAnt } from "antd";
import { MaskedInput } from "antd-mask-input";
import searchIcon from "../../svgs/search-icon.svg";

import * as S from "./styles";

const spanStyleActive = {
  color: "#ff0000",
  fontSize: "12px",
  paddingRight: "2px",
};

export const Input = ({
  id,
  mask,
  value,
  disabled,
  placeholder,
  required,
  error,
  suffix,
  debounceTime,
  onChange,
  onKeyDown,
  password,
  addonBefore,
  notPlaceholder,
  typeInput,
  onBlur,
  clearError,
  widthLabel,
  noShowError,
  sizeInput, //small, normal
  searchInput,
  loading,
}) => {
  const [valueTemp, setValueTemp] = useState("");
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, debounceTime);
    };
  };

  const changeInput = async (valueInternal) => {
    if (onChange) {
      onChange(valueInternal);
      setValueTemp(valueInternal);
      if (valueInternal !== value) {
        if (clearError) {
          clearError();
        }
      }
    }
  };

  const labelStyleActive = {
    top: "-5px",
    left: widthLabel ? widthLabel : "5px",
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
  };

  const optimizedFn = useCallback(debounce(changeInput), []);

  const masked = useMemo(
    () => [
      {
        mask,
        lazy: false,
      },
    ],
    []
  );

  const handleInputPass = (type, e) => {
    if (type === "blur" && e.target.value) {
      setValueTemp("focused");
    } else if (type === "focus") {
      setValueTemp("focused");
    } else {
      setValueTemp("");
    }
  };

  return (
    <S.WrapperInput
      error={error}
      widthLabel={widthLabel}
      sizeInput={sizeInput}
      searchInput={searchInput}
    >
      {password ? (
        <>
          <InputAnt.Password
            onFocus={(e) => handleInputPass("focus", e)}
            onBlur={(e) => handleInputPass("blur", e)}
            id={id}
            value={value ? value : ""}
            disabled={disabled}
            suffix={suffix ? suffix : ""}
            placeholder=" "
            status={error ? "error" : ""}
            required={required}
            onKeyDown={onKeyDown}
            onChange={
              debounceTime
                ? (e) => optimizedFn(e.target.value)
                : (e) => changeInput(e.target.value)
            }
          />
          {!addonBefore ? (
            <label className="label" style={valueTemp ? labelStyleActive : {}}>
              {required ? (
                <span style={valueTemp ? spanStyleActive : {}}>*</span>
              ) : null}
              {placeholder}
            </label>
          ) : null}
        </>
      ) : mask ? (
        <>
          <MaskedInput
            id={id}
            value={value ? value : ""}
            disabled={disabled}
            addonBefore={addonBefore ? addonBefore : ""}
            mask={masked}
            suffix={suffix ? suffix : ""}
            placeholder=" "
            status={error ? "error" : ""}
            required={required}
            onKeyDown={onKeyDown}
            onChange={
              debounceTime
                ? (e) => optimizedFn(e?.unmaskedValue)
                : (e) => changeInput(e?.unmaskedValue)
            }
          />
          {!addonBefore ? (
            <label className="label" style={valueTemp ? labelStyleActive : {}}>
              {required ? (
                <span style={valueTemp ? spanStyleActive : {}}>*</span>
              ) : null}
              {placeholder}
            </label>
          ) : !notPlaceholder ? (
            <label className="label label-addbefore">
              {required ? <span>*</span> : null}
              {placeholder}
            </label>
          ) : null}
        </>
      ) : (
        <>
          <S.Skeleton
            loading={loading}
            active
            title={false}
            paragraph={{
              rows: 1,
              className: "skeleton-input",
              width: "100%",
            }}
          >
            <InputAnt
              onBlur={onBlur ? (e) => onBlur(e.target.value) : null}
              id={id}
              value={value ? value : ""}
              disabled={disabled}
              addonBefore={addonBefore ? addonBefore : ""}
              suffix={suffix ? suffix : ""}
              placeholder=" "
              status={error ? "error" : ""}
              required={required ? required : false}
              onKeyDown={onKeyDown}
              onChange={
                debounceTime
                  ? (e) => optimizedFn(e.target.value)
                  : (e) => changeInput(e.target.value)
              }
              type={typeInput}
            />
            {!addonBefore ? (
              <label className="label">
                {required ? <span>*</span> : null}
                {placeholder}
              </label>
            ) : !notPlaceholder ? (
              <label className="label label-addbefore">
                {required ? <span>*</span> : null}
                {placeholder}
              </label>
            ) : null}
            {searchInput ? (
              <S.IconSearchInput>
                <img src={searchIcon} alt="Search" />
              </S.IconSearchInput>
            ) : null}
          </S.Skeleton>
        </>
      )}
      {noShowError ? null : error ? <S.InfoError>{error}</S.InfoError> : null}
    </S.WrapperInput>
  );
};
