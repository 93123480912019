import React from "react";

import { I18n } from "aws-amplify";

import WYSIWYGEditor from "../form/wysiwyg-editor";

import TextField from "../form/text-field";
import SelectField from "../form/select-field";

import { Icon, Tooltip } from "antd";

export default class BriefingEditor extends React.Component {
  constructor(props) {
    super();

    this.state = {
      label: props.label,
    };
  }

  onChangeLabel(e) {
    const value = e.target.value;

    this.setState({ label: value });

    //this.props.onChangeLabel(value);
  }

  render() {
    const canEditLabel = this.props.code.substring(0, 6) == "CUSTOM";

    const canDelete = this.props.canDelete;

    const fileValues =
      this.props.files &&
      this.props.files.map((item) => ({ code: item.id, label: item.name }));

    const file =
      this.props.fileId &&
      this.props.files.find((item) => item.id == this.props.fileId);

    return (
      <div className="card">
        <header className="card-header">
          <div className="card-header-title">
            <button className="card-mover">
              <span></span>
            </button>
            {canEditLabel && (
              <div
                style={{
                  flex: "1 1 0%",
                  marginTop: "2px",
                  marginRight: "20px",
                }}
              >
                <TextField
                  value={this.props.label}
                  onChange={(e) => this.props.onChangeLabel(e.target.value)}
                  successIcon={false}
                ></TextField>
              </div>
            )}
            {!canEditLabel && (
              <div style={{ flex: "1 1 0%" }}>{this.props.label}</div>
            )}

            <div style={{ textAlign: "right", flex: "1 1 0%" }}>
              {this.props.canEdit && this.props.files.length > 0 && (
                <SelectField
                  value={this.props.fileId}
                  onChange={(e) => this.props.onChangeFile(e.target.value)}
                  values={fileValues}
                  selectLabel="---"
                />
              )}

              {!this.props.canEdit && file && (
                <a href={file.url} target="_blank">
                  <Tooltip title={file.name}>
                    <span className="iconColor">
                      <Icon type="paper-clip" />
                    </span>
                  </Tooltip>
                </a>
              )}

              {canDelete && !this.props.mandatory && (
                <a
                  href="#"
                  onClick={this.props.delBriefing}
                  className="card-header-icon card-header-close"
                  aria-label="fechar"
                >
                  <span className="icon">
                    <i className="fas fa-trash-alt" aria-hidden="true"></i>
                  </span>
                </a>
              )}
            </div>
          </div>

          {/* {canDelete &&
                    <a href="#" onClick={this.props.delBriefing} className="card-header-icon card-header-close" aria-label="fechar">
                        <span className="icon">
                            <i className="fas fa-times" aria-hidden="true"></i>
                        </span>
                    </a>} */}
        </header>
        <div
          className="card-content"
          style={{ padding: "0", backgroundColor: "#ffffff" }}
        >
          <div className="content">
            <WYSIWYGEditor
              content={this.props.content}
              onChange={this.props.onChange}
              readOnly={!this.props.canEdit}
            />
          </div>
        </div>
      </div>
    );
  }
}
