import React from 'react';
import { I18n } from 'aws-amplify';
import { NavLink } from 'react-router-dom';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import bannerImage3 from '../../image/banner-image3.png';

import { scrollToTop } from '../../utils/ui';

class ThankYou extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {

        scrollToTop();
    }

    render() {

        return (
            <FillForm>

                <div className="container thankyou">

                    <div className="columns">

                        <div className="column">

                            <h1 className="subtitle is-4" style={{ marginTop: '25vh' }}>
                                {I18n.get("Parabéns, você mandou muito bem na escolha do parceiro para o projeto")} <b>{this.props.projectName}</b>!
                            </h1>
                                <br />
                            <p>{I18n.get("A partir de agora seu projeto pode ser acessado por você e pelo parceiro em uma área comum para ajustes finais de escopo e aprovação final.")} <NavLink to='/cliente/projetos'> {I18n.get("Clique aqui")} </NavLink> {I18n.get("para ir para projetos.")}</p>
                        </div>

                        <div className="column is-offset-1">

                            <br /><br /><br />

                            <p className="title-bpool medium">{I18n.get("PRÓXIMOS PASSOS")}</p>

                            <div className="timeline">
                                <div className="timeline-item">
                                    <div className="timeline-marker is-icon" style={{ fontWeight: 'bold' }}>
                                        <span>1</span>
                                    </div>
                                    <div className="timeline-content">
                                        <p><strong>{I18n.get("Review do Projeto")}</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-item">
                                    <div className="timeline-content">
                                        <p><strong>{I18n.get("Parceiro:")}</strong> {I18n.get("Insere proposta com os custos linha a linha.")}</p>
                                        <p><strong>{I18n.get("Cliente:")}</strong> {I18n.get("Aprovação final do projeto.")}</p>
                                    </div>
                                </div>
                                <div className="timeline-item">
                                    <div className="timeline-marker is-icon" style={{ fontWeight: 'bold' }}>
                                        <span>2</span>
                                    </div>
                                    <div className="timeline-content">
                                        <p><strong>{I18n.get("Inserção do #PO")}</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-item">
                                    <div className="timeline-content">
                                        <p><strong>{I18n.get("Cliente:")}</strong> {I18n.get("Fornecimento do número de PO (Pedido ou Ordem de Compra).")}</p>
                                    </div>
                                </div>
                                <div className="timeline-item">
                                    <div className="timeline-marker is-icon" style={{ fontWeight: 'bold' }}>
                                        <span>3</span>
                                    </div>
                                    <div className="timeline-content">
                                        <p><strong>{I18n.get("Jobs Ongoing")}</strong></p>
                                    </div>
                                </div>
                                <div className="timeline-item">
                                    <div className="timeline-content">
                                        <p>{I18n.get("Início do Projeto.")}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>


            </FillForm>
        );
    }
}


export default ProjectContainer(ThankYou);
