export const translations = {
  en: {
    active: "Active",
    error: "Missing required informations *",
    completed: "Completed",
    tobeCompleted: "To be completed",
  },
  pt: {
    active: "Ativo",
    error: "Faltando informações necessárias *",
    completed: "Completo",
    tobeCompleted: "Falta preencher",
  },
  es: {
    active: "Activo",
    error: "Falta información requerida *",
    completed: "Terminado",
    tobeCompleted: "A completar",
  }
};