import axios from 'axios';


import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PARTNER_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_get_partner = () => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.get(`${Config.API_BASE_URL}/partnerWhatIf`, config)
                .then(response => {

                    const partnerWhatIf = response.data || {};

                    dispatch({
                        type: PARTNER_GET,
                        payload: {
                            ...partnerWhatIf,
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    });

                    const user = {
                        user_id: partnerWhatIf.username,
                        email: partnerWhatIf.email,
                        name: partnerWhatIf.name,
                        phone: partnerWhatIf.phoneNumber
                    };

                })
                .catch(error => {
                    throw (error);
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_get_partner;
