import React, { useState, useEffect } from 'react';
import { Loggedv2 } from '../../components/Loggedv2';
import { useRegistrationFlowFull } from '../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook';
import { urlsFull as urls } from '../../utils/stepsUrls';

import { ContentPage } from './content-page';

const PAGE_INDEX = 11;

export const IndustryFull = () => {
  const { getStatusBarFull } = useRegistrationFlowFull();

  const [changeInfo, setChangeInfo] = useState(false);

  return (
    <Loggedv2
      pageIndex={PAGE_INDEX}
      statusBarStepsClickable
      statusChangeInfo={changeInfo}
      hookGetItems={getStatusBarFull}
      stepbarId="stepbar-full"
      urls={urls}
      contextStepBar="full"
    >
      <ContentPage
        contextPage="full"
        onChangeInfo={(info) => setChangeInfo(info)}
      />
    </Loggedv2>
  );
};
