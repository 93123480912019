import React from 'react';

import { I18n } from 'aws-amplify';

import { Task } from '../../../utils/task';

import TextField from '../../form/text-field';
import UploadField from '../../form/upload-field';
import SubmitButtonField from '../../form/submit-button-field';

import FileUploadList from '../../../modules/components/FileUploadButton';

class UploadItemForm extends React.Component {
    constructor(props) {

        super(props);

        const { item = {} } = props;

        const { description, name = I18n.get('Selecione...') } = item;

        this.state = {
            fields: {
                description: {
                    label: I18n.get("Descrição"),
                    onChange: this.onChangeForm.bind(this, 'description'),
                    visited: true,
                    successIcon: false
                },
                file: {
                    onChange: this.onUpload.bind(this),
                    visited: true,
                },
            },
            errors: {},
            form: {
                description,
                name // filename
            },
            task: {},
            upload_task: {},
            file: {},
        }
    }

    onChangeForm(prop, e) {

        const value = e.target.value;

        this.setState((prevState) => {

            var nextState = Object.assign({}, prevState);

            nextState.form[prop] = value;
            nextState.errors[prop] = null;

            return nextState;
        });
    }

    onAddFile(e) {

        var submit_task = new Task();

        submit_task.start();

        this.setState({ submit_task: submit_task });

        var data = {
            projectId: this.props.match.params.projectId,
            ...this.state.form,
            ...this.state.file
        };

        this.props.upload_bpitch_file(data)
            .then(({ task = {}, errors = {} }) => {

                if (task.result) {

                    this.setState({ submit_task: task, form: {}, errors: errors, file: {} });
                }
                else {

                    this.setState({ submit_task: task, errors: errors });
                }
            });

    }

    onUpload(e) {

        var file = e.target.files[0];

        if (!file) return;

        if (e) e.preventDefault();

        var reader = new FileReader();

        reader.onload = (e) => {

            const data = {
                name: file.name,
            };

            this.setState((prevState) => {

                var nextState = Object.assign({}, prevState);

                nextState.file = {
                    base64: e.target.result
                };
                nextState.form = { ...prevState.form, ...data };
                nextState.errors.base64 = null;

                return nextState;
            });

        };
        reader.readAsDataURL(file);
    }

    getDataUploadMult = (files) => {

        const { projectId, extraId } = this.props.match.params;

        let file = files[0];

        delete file['uid'];

        // const data = {

        //     name: file.name,
        //     base64: e.target.result
        // };


        const data = {
            // ...this.props.project,
            id: file.id,
            name: file.name,
            size: file.size,
            type: file.type,
            uploadedByName: file.uploadedByName,
            uploadedByUsername: file.uploadedByUsername,
            uploadedDate: file.uploadedDate,
            url: file.url
        }

        this.setState({
            file: data
        })
    }

    onDrop(files) {

        files = files.map(file => {

            this.upload(file);
        });
    }

    onCancel() {

    }

    render() {

        const { item } = this.props;

        let filesNormalize = this.props.files && this.props.files.map((item) => {
            return {
                ...item,
                uid: item.id,
            }
        })

        if (!this.state.file.id) {

            return (
                <form>

                    <div className="columns">

                        <div className="column is-4">

                            {/* <UploadField {...this.state.fields.file} value={this.state.form.name} error={this.state.errors.base64} uploading={this.state.upload_task.busy} /> */}
                            <label class="label is-small">{I18n.get("Selecione um Arquivo")}</label>
                            <FileUploadList
                                getDataUpload={this.getDataUploadMult}
                                name="files"
                                uploadId={this.props.project.projectId}
                                get2={this.props.get2 || false}
                                fileListGet={filesNormalize || []}
                                disabledList={true}
                                limit={1}
                                output="object"
                            />
                        </div>

                    </div>

                </form>
            )
        }

        return (


            <form >

                <p style={{ fontSize: '80%', opacity: '0.7' }}>
                    {I18n.get("Digite uma descrição para o arquivo e clique no botão Anexar")}
                </p>

                <br />

                <div className="columns">

                    <div className="column is-5">
                        <TextField label={I18n.get('Arquivo')} successIcon={false} value={this.state.file.name} readOnly={true} />
                    </div>

                    <div className="column is-5">
                        <TextField {...this.state.fields.description} value={this.state.form.description} error={this.state.errors.description} />
                    </div>

                    <div className="column is-2">
                        <div className="field is-narrow">
                            <div className="control">
                                <label class="label is-small">&nbsp;</label>

                                <SubmitButtonField
                                    label={I18n.get("Anexar")}
                                    className='button bt-bpool black'
                                    onClick={this.onAddFile.bind(this)}
                                    task={this.state.submit_task}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        );
    }
}

export default UploadItemForm;
