import axios from 'axios';
import { Auth } from 'aws-amplify';
import Config from '../../../config';

axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';

  const putAboutYouLogged = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Partner/user`,
      form,
      config
    );
    return data;
  };

  const getAboutYouLogged = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: 'Bearer ' + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/user`,
      config
    );
    return data;
  };

export const useRegistrationFlowUser = () => {
  const getAboutYou = async (token) => {
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/partner/user/verification-token/${token}`
    );
    return data;
  };

  const postAboutYou = async (form) => {
    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/partner/user`,
      form
    );

    return data;
  };

  return {
    getAboutYou,
    postAboutYou,
    putAboutYouLogged,
    getAboutYouLogged
  };
};
