import I18n from "aws-amplify";

export const LinkPdf = async (pdfUrl) => {
  if (pdfUrl) {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.log(I18n.get("Erro ao baixar o PDF."));
  }
};
