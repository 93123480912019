import React, { PureComponent } from "react";
import styled from "styled-components";
import { I18n } from "aws-amplify";

import NumberFormat from "react-number-format";

import {
  formatCurrency,
  formatMoney2,
  formatMoney,
} from "../../../utils/helpers";

import { Table, Icon } from "antd";

const BRL = ({ amount }) => (
  <NumberFormat
    value={amount}
    displayType={"text"}
    thousandSeparator="."
    decimalSeparator=","
    prefix={"R$ "}
    decimalScale={2}
    fixedDecimalScale={true}
  />
);

const Btns = styled.p`
  width: 50px;
  displa: block;
  a {
    margin-left: 10px;
  }
`;

const TableCpm = styled(Table)`
  border: solid 1px #ccc;

  .ant-table table {
    border: 0 !important;
  }
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
  .ant-table-thead > tr > th.ant-table-column-sort {
    background: #000;
  }
`;

function compareByAlph(a, b) {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
}
class tableScope extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getAssetType(code) {
    if (!code) return {};
    if (!this.props.bp.assetTypes) return {};

    const item = this.props.bp.assetTypes.filter((t) => t.code == code);

    if (item && item.length) {
      return item[0];
    } else {
      return {};
    }
  }

  render() {
    const columns = [
      // {
      //   title: I18n.get("Asset"),
      //   dataIndex: "asset",
      //   key: "asset",
      //   sorter: (a, b) => compareByAlph(a.asset, b.asset),
      //   // render: (asset) => {
      //   //     return this.getAssetType(asset);
      //   //  }
      // },
      {
        title: I18n.get("Projeto"),
        dataIndex: "project",
        key: "project",
        sorter: (a, b) => compareByAlph(a.project, b.project),
      },
      {
        title: I18n.get("Asset"),
        dataIndex: "asset",
        key: "asset",
        sorter: (a, b) => compareByAlph(a.asset, b.asset),
      },
      {
        title: I18n.get("Spec"),
        dataIndex: "spec",
        key: "spec",
        sorter: (a, b) => compareByAlph(a.spec, b.spec),
      },
      {
        title: I18n.get("R$ Ref"),
        dataIndex: "ref",
        key: "ref",
        sorter: (a, b) => compareByAlph(a.ref, b.ref),
        render: (text) => {
          return <BRL amount={text} />;
        },
      },
      {
        title: I18n.get("R$ Bpool"),
        dataIndex: "bpool",
        key: "bpool",
        sorter: (a, b) => compareByAlph(a.bpool, b.bpool),
        render: (text) => {
          return <BRL amount={text} />;
        },
      },
      {
        title: I18n.get("Qtd"),
        dataIndex: "qtd",
        sorter: (a, b) => compareByAlph(a.qtd, b.qtd),
        key: "qtd",
      },
      // {
      //   title: I18n.get("R$ Total"),
      //   dataIndex: "total",
      //   key: "total",
      //   sorter: (a, b) => compareByAlph(a.total, b.total),
      //   render: (text, record) => {
      //     let total = record?.bpool * record?.qtd;

      //     return <BRL amount={total} />;
      //   },
      // },
      {
        title: I18n.get("R$ Saving"),
        dataIndex: "saving",
        sorter: (a, b) => compareByAlph(a.saving, b.saving),
        key: "saving",
        render: (text) => {
          return <BRL amount={text} />;
        },
      },
    ];

    let dataTable =
      (this.props.data &&
        this.props.data?.length > 0 &&
        this.props.data?.map((item, index) => {
          return {
            key: index,
            project: item.projectName,
            asset: item.assetLabel,
            spec: item.specialismLabel,
            ref: item.totalRefRate,
            bpool: item.totalBPoolPaidRate,
            qtd: item.quantity,
            total: item.totalSaving * item.quantity,
            saving: item.totalSaving,
          };
        })) ||
      [];

    return (
      <TableCpm
        columns={columns}
        dataSource={dataTable}
        scroll={{ x: 640 }}
        locale={{ emptyText: I18n.get("Sem informação") }}
        scroll={{ y: 300 }}
        pagination={false}
        footer={() => {
          let total = dataTable.reduce((acumulador, item, indice, original) => {
            return acumulador + item.saving;
          }, 0);

          return (
            <p style={{ textAlign: "right", padding: "0 40px" }}>
              <strong>{I18n.get("Total")}:</strong> <BRL amount={total} />
            </p>
          );
        }}
      />
    );
  }
}

export default tableScope;
