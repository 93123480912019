import React from "react";
import { Route, Switch } from "react-router-dom";

import { PartnerDetails } from "../../pages/Bpool/pages/Partner/PartnerDetails";
import { AdministrativeTools } from "../../pages/Bpool/pages/Partner/AdministrativeTools";
// FULL
import { EditCompanyProfile } from "../../pages/Bpool/pages/Partner/CompanyProfile";
import { EditCompanyDetails } from "../../pages/Bpool/pages/Partner/CompanyDetails";
import { EditBillingData } from "../../pages/Bpool/pages/Partner/BillingData";
import { EditDiversityAndInclusion } from "../../pages/Bpool/pages/Partner/DiversityAndInclusion";
import { EditBusinessType } from "../../pages/Bpool/pages/Partner/BusinessType";
import { EditIndustry } from "../../pages/Bpool/pages/Partner/Industry";
import { EditSpecialityProofAndEndersement } from "../../pages/Bpool/pages/Partner/SpecialityProofAndEndersement";
import { EditAwards } from "../../pages/Bpool/pages/Partner/Awards";
import { EditCustomers } from "../../pages/Bpool/pages/Partner/Customers";
import { EditTeamRegistration } from "../../pages/Bpool/pages/Partner/TeamRegistration";
import { EditMyProfile } from "../../pages/Bpool/pages/Partner/MyProfile";
import { EditLanguage } from "../../pages/Bpool/pages/Partner/Language";

function BpoolPartnerAdmin() {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/bpool/partner/v2/:partnerId"
          component={PartnerDetails}
        />
        <Route
          exact
          path="/bpool/partner/v2/administrative-tools/:partnerId"
          component={AdministrativeTools}
        />

        <Route
          exact
          path="/bpool/partner/v2/company-profile/:partnerId"
          component={EditCompanyProfile}
        />
        <Route
          exact
          path="/bpool/partner/v2/company-details/:partnerId"
          component={EditCompanyDetails}
        />
        <Route
          exact
          path="/bpool/partner/v2/billing-data/:partnerId"
          component={EditBillingData}
        />
        <Route
          exact
          path="/bpool/partner/v2/diversity-and-inclusion/:partnerId"
          component={EditDiversityAndInclusion}
        />
        <Route
          exact
          path="/bpool/partner/v2/business-type/:partnerId"
          component={EditBusinessType}
        />
        <Route
          exact
          path="/bpool/partner/v2/industry/:partnerId"
          component={EditIndustry}
        />
        <Route
          exact
          path="/bpool/partner/v2/speciality-proof-and-endersement/:partnerId"
          component={EditSpecialityProofAndEndersement}
        />
        <Route
          exact
          path="/bpool/partner/v2/awards/:partnerId"
          component={EditAwards}
        />
        <Route
          exact
          path="/bpool/partner/v2/customers/:partnerId"
          component={EditCustomers}
        />
        <Route
          exact
          path="/bpool/partner/v2/team-registration/:partnerId"
          component={EditTeamRegistration}
        />
        <Route
          exact
          path="/bpool/partner/v2/my-profile/:partnerId"
          component={EditMyProfile}
        />
        <Route
          exact
          path="/bpool/partner/v2/language/:partnerId"
          component={EditLanguage}
        />
      </Switch>
    </>
  );
}

export default BpoolPartnerAdmin;
