import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { I18n } from "aws-amplify";
import { useBpayAdmin } from "../../../../../hooks/useBpay/useBpayAdmin.hook";
import { Input } from "../../../../../components/UI/Input";
import { SelectOne } from "../../../../../components/UI/SelectOne";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { Dialog } from "../../../../../components/UI/Dialog";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";

import {
  createQueryString,
  parseQueryString,
} from "../Filters/utils/resolvers";
import * as S from "./styles";

export const FiltersBPayments = ({ handleSelectedsBpays }) => {
  const {
    adminBpaymentsListSetup,
    exportRepasseBPayments,
    exportFinancialBPayments,
  } = useBpayAdmin();
  let history = useHistory();
  let location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValueDebounced] = useDebounce(searchTerm, 1000);
  const [filters, setFilters] = useState({});
  const [selectedsBpays, setSelectedsBpays] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const [isExporting, setIsExporting] = useState(false);

  const { data, isLoading, error, refetch } = useQuery(
    "setupBpaymentsAdmin",
    () => adminBpaymentsListSetup(),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os itens de filtro"),
          duration: 3,
        });
      },
    }
  );

  const handleChangeStatus = (status) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (status === 9999) {
      delete objQueries.status;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, status });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleSearch = (search) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!search) {
      delete objQueries.search;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({
        ...objQueries,
        search,
      });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleExportRepasseOrFinancial = async (type) => {
    try {
      setIsExporting(true);
      const bPaymentsIds = selectedsBpays?.map((item) => item?.id);
      if (type === "repasse") {
        await exportRepasseBPayments(bPaymentsIds);
      }
      if (type === "financial") {
        await exportFinancialBPayments(bPaymentsIds);
      }
      setDialog({
        visible: true,
        text: I18n.get("Solicitação realizada com sucesso"),
        subText: I18n.get("Você receberá a planilha por e-mail"),
      });
    } catch (error) {
      setDialog({
        visible: true,
        error: true,
        text: I18n.get("Erro na solicitação"),
        subText: I18n.get("Ocorreu algum erro, tente novamente"),
      });
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchValueDebounced]);

  useEffect(() => {
    const objQueries = parseQueryString(location?.search);
    setFilters(objQueries);
  }, [location]);

  useEffect(() => {
    setSelectedsBpays(handleSelectedsBpays);
  }, [handleSelectedsBpays]);

  useEffect(() => {
    const listStatusInt = data?.data?.status?.map((item) => ({
      code: Number(item?.status),
      label: item?.statusDescription,
    }));
    setListStatus(
      listStatusInt?.length
        ? [{ code: 9999, label: I18n.get("Todos") }, ...listStatusInt]
        : []
    );
  }, [data]);

  return (
    <>
      <S.Wrapper>
        <S.ContentFilters>
          <div style={{}}>
            <SelectOne
              placeholder={I18n.get("Status")}
              options={listStatus}
              value={filters?.status ? Number(filters?.status) : 9999}
              onChange={(value) => handleChangeStatus(value)}
              error=""
              loading={isLoading}
            />
          </div>
          <div style={{}}>
            <Input
              placeholder={I18n.get("Nome do fornecedor")}
              label
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
              searchInput
            />
          </div>
          <S.ContentButtons>
            <ButtonBpool
              text={I18n.get("Faturamento")}
              theme="tertiary"
              loading={isExporting}
              onClick={() => handleExportRepasseOrFinancial("financial")}
              disabled={selectedsBpays?.length ? false : true}
            />
            <div style={{ marginLeft: 16 }}>
              <ButtonBpool
                text={I18n.get("Realizar repasse")}
                theme="primary"
                loading={isExporting}
                onClick={() => handleExportRepasseOrFinancial("repasse")}
                disabled={selectedsBpays?.length ? false : true}
              />
            </div>
          </S.ContentButtons>
        </S.ContentFilters>
      </S.Wrapper>

      <Dialog
        visible={dialog.visible}
        text={dialog.text}
        subText={dialog.subText}
        loading={false}
        theme="white"
        error={dialog?.error}
        buttons={{
          ok: {
            text: I18n.get("Fechar"),
            func: () => setDialog({ visible: false }),
          },
          cancel: {},
        }}
      />
    </>
  );
};
