import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import { notification } from 'antd';

import Config from '../../config';

import { PARTNER_ASSETS_GET } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_partner_create_asset = (data) => {
    return (dispatch, getState) => {

        return Auth.currentSession().then((session) => {

            const token = session && session.idToken.jwtToken;

            var config = { headers: { Authorization: 'Bearer ' + token } };

            return axios.put(`${Config.API_BASE_URL}/partner/asset/create/${data.partnerId}`, data, config)
                .then(response => {

                    const payload = {
                        partnerAssets: response.data,
                        errors: {},
                        task: {
                            result: true
                        }
                    };

                    dispatch({
                        type: PARTNER_ASSETS_GET,
                        payload: payload
                    });

                    return payload;

                })
                .catch(error => {
                    const payload = {
                        errors: error.response.data,
                        task: {
                            result: false
                        }
                    };

                    dispatch({
                        type: PARTNER_ASSETS_GET,
                        payload: payload
                    });

                    return payload;
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_partner_create_asset;
