import axios from "axios";
import { Auth } from "aws-amplify";
import { EXPIRED } from "../auth/actions";

import Config from "../../config";

import { BP_PARTNER_GET } from "./actions";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

const api_get_partner = (id) => {
  return (dispatch, getState) => {
    return Auth.currentSession()
      .then((session) => {
        const token = session && session.idToken.jwtToken;

        var config = { headers: { Authorization: "Bearer " + token } };

        return axios
          .get(`${Config.API_BASE_URL}/bp/partners/${id}`, config)
          .then((response) => {
            dispatch({
              type: BP_PARTNER_GET,
              payload: {
                partner: response.data,
              },
            });
            return true;
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        dispatch({ type: EXPIRED });
      });
  };
};

export default api_get_partner;
