import styled from "styled-components";

export const StyleMain = styled.div`
  max-width: 630px;
  margin: 50px auto 0;
`;

export const Hr = styled.hr`
  height: 1px;
  background: #000;
  margin: 50px 0;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  a,
  button {
    margin-left: 10px;
  }
`;
