import React from "react";

import { I18n } from "aws-amplify";

import "./select-search-field.css";

import Select from "react-select";

import TooltipField from "./tooltip-field";

import MarkAsRequiredOrOptional from "./mark-as-required-or-optional";

export default class SelectSearchField extends React.Component {
  constructor(props) {
    super();

    this.state = {
      focused: false,
      visited: props.visited,
    };
  }

  static defaultProps = {
    successIcon: true,
    isSearchable: true,
  };

  onBlur() {
    this.setState({ focused: false });

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  onFocus() {
    this.setState({ focused: true, visited: true });
  }

  onChange(option) {
    if (!!option && option.value) {
      this.props.onChange(option.value);
    } else if (!!option && option.constructor === Array) {
      const values = option.map((item) => item.value);

      this.props.onChange(values);
    } else {
      this.props.onChange("");
    }
  }

  render() {
    const options =
      this.props.values &&
      this.props.values.map((item) => ({
        value: item.code || item.id,
        label: item.label || item.description,
        data: item.data,
      }));

    var selected = null;

    if (!!this.props.value && this.props.value.constructor === Array) {
      selected =
        options &&
        options.filter((item) => this.props.value.indexOf(item.value) > -1);
    } else {
      selected =
        options && options.filter((item) => item.value == this.props.value);
    }

    const className = this.props.className || "field searchInput";
    const inputClassName = this.props.inputClassName || "input";

    const isError =
      !this.state.focused && this.state.visited && this.props.error;

    const isValid = !!this.props.value && !this.props.error;

    return (
      <div
        className={`${className} ${this.props.isMulti ? "multiple-bpool" : ""}`}
      >
        <label className="label is-small">
          {this.props.label} <MarkAsRequiredOrOptional {...this.props} />{" "}
          <TooltipField {...this.props} />
        </label>
        <div className="control has-icons-right">
          <Select
            id={this.props.dataSelect || ""}
            isMulti={this.props.isMulti || false}
            defaultMenuIsOpen={false}
            isSearchable={true}
            value={selected}
            options={options}
            onChange={this.onChange.bind(this)}
            onBlur={() => this.onBlur()}
            onFocus={() => this.onFocus()}
            classNamePrefix="bp"
            noOptionsMessage={() => "Nenhuma opção encontrada."}
            // placeholder={I18n.get("Selecione")}
            placeholder=""
            isDisabled={this.props.disabled}
            components={this.props.components}
            isOptionDisabled={this.props.isOptionDisabled}
            isSearchable={this.props.isSearchable}
          />
          {this.props.successIcon && isValid && (
            <span className="icon is-small is-right form-field-valid">
              <i className="fa fa-check-circle"></i>
            </span>
          )}
        </div>
        {isError && (
          <p className="help is-danger">{this.props.error.errorMessage}</p>
        )}
      </div>
    );
  }
}
