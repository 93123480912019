import React from "react";
import { I18n } from "aws-amplify";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Config from "../../config";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

class Menu extends React.Component {
  constructor(props) {
    super();
    this.state = {
      submenu: false,
      submenuDash: false,
      submenuPartner: false,
      submenuAssets: false,
      submenuAssetsClient: false,
      submenuFinanceiro: false,
      submenuWikis: false,
    };
  }

  componentWillUnmount() {}

  render() {
    const { moduloFaturamento } = Config;

    return (
      <nav className="navbar is-white is-submenu">
        <div className="container">
          <div className="navbar-menu admin-menu-scrool">
            <div className="navbar-start is-white">
              <NavLink
                to="/bpool/dashboard"
                className="navbar-item"
                activeClassName="is-active"
              >
                {I18n.get("Dashboard")}
              </NavLink>

              <div className="nav-sub navbar-item">
                <a
                  onClick={() =>
                    this.setState({
                      submenuPartner: !this.state.submenuPartner,
                    })
                  }
                  className="navbar-item"
                  activeClassName="is-active"
                >
                  {I18n.get("Parceiros")}
                </a>
                {this.state.submenuPartner && (
                  <div
                    className="sumenu-admin"
                    onMouseLeave={() =>
                      this.setState({
                        submenuPartner: !this.state.submenuPartner,
                      })
                    }
                  >
                    <NavLink
                      to="/bpool/parceiros"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Lista")}
                    </NavLink>
                    <NavLink
                      to="/bpool/parceiros-vip"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("VIP")}
                    </NavLink>

                    <NavLink
                      to="/bpool/partner-score"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Score")}
                    </NavLink>

                    <NavLink
                      to="/bpool/fornecedores"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Fornecedores")}
                    </NavLink>
                  </div>
                )}
              </div>

              <NavLink
                to="/bpool/clientes"
                className="navbar-item"
                activeClassName="is-active"
              >
                {I18n.get("Clientes")}
              </NavLink>
              <div className="nav-sub navbar-item">
                <a
                  onClick={() =>
                    this.setState({ submenu: !this.state.submenu })
                  }
                  className="navbar-item"
                  activeClassName="is-active"
                >
                  {I18n.get("Projetos")}
                </a>
                {this.state.submenu && (
                  <div
                    className="sumenu-admin"
                    onMouseLeave={() =>
                      this.setState({ submenu: !this.state.submenu })
                    }
                  >
                    <NavLink
                      to="/bpool/projetos"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Geral")}
                    </NavLink>
                    {/* <NavLink to="/bpool/projetos-detalhados" className="navbar-item" activeClassName="is-active">{I18n.get("Detalhado")}</NavLink> */}
                    <NavLink
                      to="/bpool/extras"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Extras")}
                    </NavLink>
                    <NavLink
                      to="/bpool/audio"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Áudio")}
                    </NavLink>
                    {/* <NavLink to="/bpool/projetos-kanban" className="navbar-item" activeClassName="is-active">{I18n.get("Kanban")}</NavLink> */}
                    <NavLink
                      to="/bpool/milistone"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Milestones")}
                    </NavLink>
                  </div>
                )}
              </div>
              {/* <NavLink to="/bpool/usuarios" className="navbar-item" activeClassName="is-active">{I18n.get("Usuários")}</NavLink> */}
              {
                moduloFaturamento && (
                  <div className="nav-sub navbar-item">
                    <a
                      onClick={() =>
                        this.setState({
                          submenuFinanceiro: !this.state.submenuFinanceiro,
                        })
                      }
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Financeiro")}
                    </a>
                    {this.state.submenuFinanceiro && (
                      <div
                        className="sumenu-admin"
                        onMouseLeave={() =>
                          this.setState({
                            submenuFinanceiro: !this.state.submenuFinanceiro,
                          })
                        }
                      >
                        <NavLink
                          to="/bpool/faturamento"
                          className="navbar-item"
                          activeClassName="is-active"
                        >
                          {I18n.get("Faturamento")}
                        </NavLink>
                        <NavLink
                          to="/bpool/repasse"
                          className="navbar-item"
                          activeClassName="is-active"
                        >
                          {I18n.get("Repasse")}
                        </NavLink>

                        <NavLink
                          to="/bpool/extraordinario"
                          className="navbar-item"
                          activeClassName="is-active"
                        >
                          {I18n.get("Extraordinário")}
                        </NavLink>

                        <NavLink
                          to="/bpool/bpay"
                          className="navbar-item"
                          activeClassName="is-active"
                        >
                          {I18n.get("BPay")}
                        </NavLink>

                        <NavLink
                          to="/bpool/bpay/bpayments"
                          className="navbar-item"
                          activeClassName="is-active"
                        >
                          {I18n.get("BPayments")}
                        </NavLink>
                      </div>
                    )}
                  </div>
                )
                // <NavLink to="/bpool/faturamento" className="navbar-item" activeClassName="is-active">{I18n.get("Faturamento")}</NavLink>
              }
              <NavLink
                to="/bpool/logins"
                className="navbar-item"
                activeClassName="is-active"
              >
                {I18n.get("Logins")}
              </NavLink>

              {/* <NavLink to="/bpool/central-de-assets" className="navbar-item" activeClassName="is-active">{I18n.get("Assets")}</NavLink> */}
              <div className="nav-sub navbar-item">
                <a
                  onClick={() =>
                    this.setState({ submenuAssets: !this.state.submenuAssets })
                  }
                  className="navbar-item"
                  activeClassName="is-active"
                >
                  {I18n.get("Assets")}
                </a>
                {this.state.submenuAssets && (
                  <div
                    className="sumenu-admin"
                    onMouseLeave={() =>
                      this.setState({
                        submenuAssets: !this.state.submenuAssets,
                      })
                    }
                  >
                    <NavLink
                      to="/bpool/central-de-assets"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Parceiros")}
                    </NavLink>
                    <a
                      onClick={() =>
                        this.setState({
                          submenuAssetsClient: !this.state.submenuAssetsClient,
                        })
                      }
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Clientes")}
                    </a>

                    {this.state.submenuAssetsClient && (
                      <div
                        className="sumenu-admin"
                        style={{ left: "95px" }}
                        onMouseLeave={() =>
                          this.setState({
                            submenuAssetsClient:
                              !this.state.submenuAssetsClient,
                          })
                        }
                      >
                        <NavLink
                          to="/bpool/clientes-assets"
                          className="navbar-item"
                          activeClassName="is-active"
                        >
                          {I18n.get("Assets")}
                        </NavLink>
                        <NavLink
                          to="/bpool/clientes-combo"
                          className="navbar-item"
                          activeClassName="is-active"
                        >
                          {I18n.get("Combo")}
                        </NavLink>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <NavLink
                to="/bpool/relatorios"
                className="navbar-item"
                activeClassName="is-active"
              >
                {I18n.get("Relatórios")}
              </NavLink>
              <NavLink
                to="/bpool/meetings"
                className="navbar-item"
                activeClassName="is-active"
              >
                {I18n.get("Meetings")}
              </NavLink>
              {/* <NavLink to="/bpool/wikis" className="navbar-item" activeClassName="is-active">{I18n.get("Wikis")}</NavLink> */}

              <div className="nav-sub navbar-item">
                <a
                  onClick={() =>
                    this.setState({ submenuWikis: !this.state.submenuWikis })
                  }
                  className="navbar-item"
                  activeClassName="is-active"
                >
                  {I18n.get("Wikis")}
                </a>
                {this.state.submenuWikis && (
                  <div
                    className="sumenu-admin"
                    onMouseLeave={() =>
                      this.setState({ submenuWikis: !this.state.submenuWikis })
                    }
                  >
                    <NavLink
                      to="/bpool/wikis/abas"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Abas")}
                    </NavLink>
                    <NavLink
                      to="/bpool/wikis/secoes"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Seções")}
                    </NavLink>
                    <NavLink
                      to="/bpool/wikis/categorias"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Categorias")}
                    </NavLink>
                    <NavLink
                      to="/bpool/wikis/conteudo"
                      className="navbar-item"
                      activeClassName="is-active"
                    >
                      {I18n.get("Conteúdo")}
                    </NavLink>
                  </div>
                )}
              </div>
              <NavLink
                to="/bpool/notifications"
                className="navbar-item"
                activeClassName="is-active"
              >
                {I18n.get("Notificações")}
              </NavLink>

              {/* <div className="nav-sub navbar-item">
                                <a onClick={() => this.setState({ submenuDash: !this.state.submenuDash })} className="navbar-item" activeClassName="is-active">{I18n.get("Dash 2")}</a>
                                {this.state.submenuDash &&
                                    <div className="sumenu-admin" onMouseLeave={() => this.setState({ submenuDash: !this.state.submenuDash })}>
                                        <NavLink to="/bpool/dashboard-a" className="navbar-item" activeClassName="is-active">a</NavLink>
                                        <NavLink to="/bpool/dashboard-b" className="navbar-item" activeClassName="is-active">b</NavLink>
                                        <NavLink to="/bpool/dashboard-c" className="navbar-item" activeClassName="is-active">c</NavLink>
                                        <NavLink to="/bpool/dashboard-d" className="navbar-item" activeClassName="is-active">d</NavLink>
                                    </div>
                                }
                            </div> */}
            </div>
            <div className="navbar-end"></div>
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
