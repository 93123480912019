import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import LoggedPage from '../../../../components/page-templates/loggedAdmin';
import { usePartnerScore } from '../../../hooks/Admin/usePartnerScore/usePartnerScore.hook';
import { TablePartners } from './components/TablePartners';


export const PartnerScoreAdmin = () => {

  const { getPartners } = usePartnerScore();
  const [loaderPartners, setLoaderPartners] = useState(false);
  const [partners, setPartners] = useState([]);


  useEffect(() => {

    const fetchPartners = async () => {

      try {

        setLoaderPartners(true);
        const resp = await getPartners()
        setPartners(resp);
        setLoaderPartners(false);

      } catch (err) {

        console.log("Error Fetching")
        setLoaderPartners(false);
        
      }

    }

    fetchPartners()

  }, [])


  return (
    <>

      <LoggedPage>

        <div style={{ marginTop: 48 }} />
        <section className="info-tiles">
          <div className="columns is-centered">
            <div className="column is-10">
              <div className="box">
                <TablePartners
                  loaderPartners={loaderPartners}
                  partnersData={partners}
                />
              </div>
            </div>
          </div>
        </section>

      </LoggedPage>

     
    </>
  );
};
