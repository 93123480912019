import styled from "styled-components";

export const Wrapper = styled.div``;

export const TitleDownloadNF = styled.div`
  strong {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 4px 0;
  }
`;

export const EmptyFile = styled.div`
  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const File = styled.div`
  background-color: #fafafa;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: #1883ff;
    i {
      font-size: 16px;
    }
  }
`;
