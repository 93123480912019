import React from 'react';
import { I18n } from 'aws-amplify';
import Modal from '../ui/modal';
import Details from './_common-cases-details';

import TableCase from '../../modules/Register/components/tableCases';



class Cases extends React.Component {

    constructor(props) {

        super();

        this.state = {
            selected: null,
        };

        this.select = this.onSelect.bind(this);
        this.closeModal = this.onCloseModal.bind(this);
    }

    onSelect(item, e) {
        this.setState({ selected: item });
    }

    onCloseModal(e) {
        e.preventDefault();

        this.setState({ selected: null });
    }

    getSector(sectorId) {

        const items = this.props.bp.sectorsFlat && this.props.bp.sectorsFlat.filter((item) => item.code == sectorId);

        if (items && items.length) {
            return items[0];
        }
        else {
            return {};
        }

    }

    render() {

        if (!this.props.bp) return null;
        if (!this.props.bp.partner) return null;

        const items = this.props.bp.partner.cases.sort(function (a, b) {
            return a.order - b.order;
        })

        return (
            <section>

                <div className="card">
                    <div className="card-content">
                        <h3 className="title is-4">{I18n.get("Cases")}</h3>

                        <div className="content">

                            <TableCase
                                data={items}
                                sectorsFlat={this.props.bp.sectorsFlat && this.props.bp.sectorsFlat}
                                partnerId={this.props.partner && this.props.partner.partnerId}
                                specialismsBP={this.props.bp.specialisms && this.props.bp.specialisms}
                                specialisms={this.props.partner.specialisms && this.props.partner.specialisms}
                                viewAdmin={true}
                            />

                        </div>
                    </div>
                </div>
                <Modal isOpen={!!this.state.selected} closeModal={this.closeModal}>

                    <h3 className="title is-3">{I18n.get("Detalhes do case")}</h3>

                    <Details item={this.state.selected} sf={this.props.bp.sectorsFlat} />

                </Modal>
            </section>
        );
    }
}

export default Cases;
