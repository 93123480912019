import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useRegistrationFlowFull = () => {
  const getPartnerIdUrl = async () => {
    const href = window.location.href;
    const hrefSplited = href?.split("/");
    const id = hrefSplited[hrefSplited.length - 1];

    return id;
  };

  const getStatusBarFull = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/status-bar-full-registration`,
      config
    );
    return data;
  };

  const getCompanyProfile = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/company-profile`
        : `${Config.API_BASE_URL}/v2/Partner/company-profile`,
      config
    );

    return data;
  };

  const updateCompanyProfile = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };
    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/company-profile`
        : `${Config.API_BASE_URL}/v2/Partner/company-profile`,
      form,
      config
    );

    if (data.errors) {
      const errors = Object.keys(data.errors).map((item) => {
        const errorItem = data.errors[item];
        const fieldFormatted =
          errorItem.field.charAt(0).toLowerCase() + errorItem.field.slice(1);
        return {
          ...errorItem,
          field: fieldFormatted,
        };
      });

      return {
        ...data,
        errors,
      };
    } else {
      return data;
    }
  };

  const getCompanyInfos = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/company-details`
        : `${Config.API_BASE_URL}/v2/Partner/company-details`,
      config
    );

    return data;
  };

  const getCompanyTeamSize = async () => {
    const session = await Auth.currentSession();
    // ver se é admin, partner com o session
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/company-details-team-size-range`,
      config
    );

    return data;
  };

  const updateCompanyInfos = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };
    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/company-details`
        : `${Config.API_BASE_URL}/v2/Partner/company-details`,
      form,

      config
    );

    if (data.errors) {
      const errors = Object.keys(data.errors).map((item) => {
        const errorItem = data.errors[item];
        const fieldFormatted =
          errorItem.field.charAt(0).toLowerCase() + errorItem.field.slice(1);
        return {
          ...errorItem,
          field: fieldFormatted,
        };
      });

      return {
        ...data,
        errors,
      };
    } else {
      return data;
    }
  };

  const getCustomersInfos = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/client-profile`
        : `${Config.API_BASE_URL}/v2/Partner/client-profile`,
      config
    );

    return data;
  };

  const postCustomersInfos = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/client-profile`
        : `${Config.API_BASE_URL}/v2/Partner/client-profile`,
      form,
      config
    );

    return data;
  };

  const getCustomersTypeInfos = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const data = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/client-profile/type`,
      config
    );

    return data;
  };

  const getTeamRegistration = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/users`
        : `${Config.API_BASE_URL}/v2/Partner/users`,
      config
    );

    return data;
  };

  const getTeamRegistrationProfiles = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/users-profiles`,
      config
    );

    return data;
  };

  const postTeamRegistrationInfos = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/users`
        : `${Config.API_BASE_URL}/v2/Partner/users`,
      form,
      config
    );

    return data;
  };

  const postTeamRegistrationUser = async ({ form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/Admin/Partner/users`,
      form,
      config
    );

    return data;
  };

  const putTeamRegistrationUser = async ({ form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Admin/Partner/users`,
      form,
      config
    );

    return data;
  };

  const deleteTeamRegistrationUser = async ({ id, partnerId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    console.log("deleteTeamRegistrationUser", { id, partnerId });
    console.log("config", config);

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/Admin/Partner/users`,
      {
        data: { id, partnerId },
        ...config,
      }
    );

    return data;
  };

  const resendEmailTeamRegistration = async ({ admin, id, partnerId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/user-email-resend`
        : `${Config.API_BASE_URL}/v2/Partner/user-email-resend`,
      {
        id,
        partnerId,
      },
      config
    );

    return data;
  };

  const getIndustriesSelect = async ({ admin, type }) => {
    console.log("getIndustriesSelect", { admin, type });
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/industry-select?type=${type}`
        : `${Config.API_BASE_URL}/v2/Partner/industry-select?type=${type}`,
      config
    );

    return data;
  };

  const getMyProfile = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/responsable`
        : `${Config.API_BASE_URL}/v2/Partner/responsable`,
      config
    );

    return data;
  };

  const putMyProfile = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/responsable`
        : `${Config.API_BASE_URL}/v2/Partner/responsable`,
      form,
      config
    );

    return data;
  };

  const getResume = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/resume`,
      config
    );

    return data;
  };

  const putResume = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Partner/resume`,
      config
    );

    return data;
  };

  const getBillingAccounts = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/account`
        : `${Config.API_BASE_URL}/v2/Partner/account`,
      config
    );

    return data;
  };

  const postBillingAccount = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/account`
        : `${Config.API_BASE_URL}/v2/Partner/account`,
      form,
      config
    );

    return data;
  };

  const putBillingAccount = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/account`
        : `${Config.API_BASE_URL}/v2/Partner/account`,
      form,
      config
    );

    return data;
  };

  const deleteBillingAccount = async ({ admin, id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.delete(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/account`
        : `${Config.API_BASE_URL}/v2/Partner/account`,
      {
        data: { id, partnerId },
        ...config,
      }
    );

    return data;
  };

  const getAwards = async ({ admin }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/award`
        : `${Config.API_BASE_URL}/v2/Partner/award`,
      config
    );

    return data;
  };

  const postAward = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/award`
        : `${Config.API_BASE_URL}/v2/Partner/award`,
      form,
      config
    );

    return data;
  };

  const putAward = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/award`
        : `${Config.API_BASE_URL}/v2/Partner/award`,
      form,
      config
    );

    return data;
  };

  const deleteAward = async ({ admin, id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const partnerId = await getPartnerIdUrl();

    const { data } = await axios.delete(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/award`
        : `${Config.API_BASE_URL}/v2/Partner/award`,
      {
        data: { id, partnerId },
        ...config,
      }
    );

    return data;
  };

  const finishFullRegister = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/partner/full-register-finish`,
      {},
      config
    );
    return data;
  };

  const getTimezones = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/Partner/timezones`,
      config
    );

    console.log("DATA", data);
    return data;
  };

  const putTimezones = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/Partner/timezones`,
      form,
      config
    );
    return data;
  };

  const getTimezonesClient = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };
    const { data } = await axios.get(
      `${Config.API_BASE_URL}/client-user/timezones`,
      config
    );
    return data;
  };

  const putTimezonesClient = async (form) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;
    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/client-user/timezones`,
      form,
      config
    );
    return data;
  };

  const getBillingData = async ({ admin, partnerId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/${partnerId}/payment-beneficiary`
        : `${Config.API_BASE_URL}/v2/Partner/payment-beneficiary`,
      config
    );

    return data;
  };

  const addBillingData = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/payment-beneficiary`
        : `${Config.API_BASE_URL}/v2/Partner/payment-beneficiary`,
      form,
      config
    );

    return data;
  };

  const updateBillingData = async ({ admin, form }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/payment-beneficiary`
        : `${Config.API_BASE_URL}/v2/Partner/payment-beneficiary`,
      form,
      config
    );

    return data;
  };
  const deleteBillingData = async ({ admin, id }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      admin
        ? `${Config.API_BASE_URL}/v2/admin/Partner/payment-beneficiary`
        : `${Config.API_BASE_URL}/v2/Partner/payment-beneficiary`,
      {
        data: { id },
        ...config,
      }
    );

    return data;
  };

  return {
    getStatusBarFull,
    getCompanyProfile,
    updateCompanyProfile,
    getCompanyInfos,
    getCompanyTeamSize,
    updateCompanyInfos,
    getCustomersInfos,
    postCustomersInfos,
    getCustomersTypeInfos,
    getTeamRegistration,
    getTeamRegistrationProfiles,
    postTeamRegistrationInfos,
    postTeamRegistrationUser,
    putTeamRegistrationUser,
    deleteTeamRegistrationUser,
    resendEmailTeamRegistration,
    getIndustriesSelect,
    getMyProfile,
    putMyProfile,
    getResume,
    putResume,
    getBillingAccounts,
    postBillingAccount,
    putBillingAccount,
    deleteBillingAccount,
    getAwards,
    postAward,
    putAward,
    deleteAward,
    finishFullRegister,
    getTimezones,
    putTimezones,
    getTimezonesClient,
    putTimezonesClient,
    getBillingData,
    addBillingData,
    updateBillingData,
    deleteBillingData,
  };
};
