import React from "react";
import { I18n } from "aws-amplify";

import ProjectContainer from "../../containers/project-container";

import FillForm from "../page-templates/fillForm-logged";
import ProjectDetailsForm from "./project-details-form";

import { Link } from "react-router-dom";

import StepsCreative from "./steps-creative";
import StepsBidding from "./steps-bidding";

import QuotationForm from "./quotation-form";

import { scrollToTop } from "../../utils/ui";

import { Icon } from "antd";

class ProjectDefintionsThankYou extends React.Component {
  constructor(props) {
    super();
    this.state = {
      task: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  submitForm(e) {}

  render() {
    let useFlow =
      this.props.client &&
      this.props.client.customization &&
      this.props.client.customization.useSignFlow;

    const quotation = this.props.universalQuotation || {};

    const Content = (props) => {
      if (props.auth.is_client || props.auth.is_client_user) {
        return (
          <>
            <div className="BoxNewProposal">
              <h1 className="title-bpool big">{I18n.get("Parabéns!")}</h1>
              <p>
                <b>{I18n.get("O Escopo final do seu projeto está aprovado")}</b>
              </p>
              <p>
                {I18n.get(
                  "Você deve selecionar o botão de baixar orçamento antes de prosseguir. Esse arquivo contém os dados que você utilizará na próxima etapa."
                )}
              </p>

              <p className="actions">
                {quotation?.pdfPurchaseOrder && (
                  <a
                    href={quotation?.pdfPurchaseOrder}
                    className="button bt-bpool black"
                    download
                    target="_blank"
                  >
                    {I18n.get("Baixar Orçamento")}
                  </a>
                )}

                {props?.isCreative && props?.auth.is_client && (
                  <>
                    <Link
                      to={`/projetos/${props?.projectId}/detalhamento-passo-4`}
                      className="button bt-bpool black"
                    >
                      {I18n.get("Próxima etapa")}
                    </Link>
                  </>
                )}
                {props?.isPartnerSelectionBidding && (
                  <>
                    <Link
                      to={`/projetos/${props?.projectId}/detalhamento-passo-3`}
                      className="button bt-bpool black"
                    >
                      {I18n.get("Próxima etapa")}
                    </Link>
                  </>
                )}
              </p>
            </div>
            {/* <div className="columns">
              <div className="column is-3 is-offset-9">
                <div className="field is-grouped is-grouped-centered">
                  <p className="control is-expanded" onClick={this.scrollToTop}>
                    {props?.isCreative && props?.auth.is_client && (
                      <>
                        <Link
                          to={`/projetos/${props?.projectId}/detalhamento-passo-4`}
                          className="button bt-bpool black is-fullwidth  next"
                        >
                          {I18n.get("Próximo")}
                        </Link>
                      </>
                    )}
                    {props?.isPartnerSelectionBidding && (
                      <>
                        <Link
                          to={`/projetos/${props?.projectId}/detalhamento-passo-3`}
                          className="button bt-bpool black is-fullwidth  next"
                        >
                          {I18n.get("Próximo")}
                        </Link>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div> */}
          </>
        );
      }

      if (props.auth.is_partner) {
        return (
          <div>
            <br />
            <br />
            <h1 className="title-bpool big">{I18n.get("Parabéns!")}</h1>
            <br />
            <p>
              {I18n.get(
                "O ESCOPO FINAL DO SEU PROJETO FOI SUBMETIDO. SE ESTIVER TUDO OK, O SEU FATURAMENTO SERÁ LIBERADO QUANDO RECEBERMOS A APROVAÇÃO DO CLIENTE."
              )}
            </p>
            <p style={{ fontSize: "80%" }}></p>

            <div className="colot-line"></div>
          </div>
        );
      }
    };

    return (
      <FillForm>
        <div className="hero-body has-background-white for-np uplift-hero-1 mobile-steps">
          <div className="container">
            <div className="columns is-mobile">
              <div
                className="column is-6"
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="title">
                  {I18n.get("Definições do Projeto")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-6">
                {this.props.isCreative && (
                  <>
                    <StepsCreative
                      active={3}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                      useFlow={useFlow}
                      isClient={
                        this.props.auth.is_client ||
                        this.props.auth.is_client_user
                      }
                    />
                  </>
                )}
                {this.props.isPartnerSelectionBidding && (
                  <>
                    <StepsBidding
                      active={2}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                      useFlow={useFlow}
                      isClient={
                        this.props.auth.is_client ||
                        this.props.auth.is_client_user
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="client-step client-step-1 project-definition stepnew">
          <div className="container client-container-1">
            <Content {...this.props} />

            <br />
            <br />

            {this.props.clientTermsUpdatedOn && (
              <>
                <div
                  style={{
                    display: "flex",
                    boxShadow:
                      "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                    backgroundColor: "#fff",
                    padding: "1rem",
                  }}
                >
                  <div
                    style={{
                      flexBasis: "2.5rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      type="check"
                      style={{ fontSize: "150%", color: "#000" }}
                    />
                  </div>

                  <div style={{ flex: "1", fontSize: "90%" }}>
                    {I18n.get(
                      "Veja abaixo os detalhes do projeto aprovado por"
                    )}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.clientTermsUpdatedByEmail}
                    </span>{" "}
                    {I18n.get("em")}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.clientTermsUpdatedOn}
                    </span>{" "}
                    {I18n.get("com o parceiro")}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.partnerName}
                    </span>
                    .{" "}
                    {I18n.get(
                      "De agora em diante, qualquer adição de escopo será através dos “Extras” ou “Novo Projeto”."
                    )}
                  </div>
                </div>

                {/* <div>
                  <br />
                  <br />

                  {quotation?.pdfPurchaseOrder && (
                    <a
                      href={quotation?.pdfPurchaseOrder}
                      className="button bt-bpool black next"
                      download
                      target="_blank"
                    >
                      {I18n.get("Baixar PDF")}
                    </a>
                  )}
                </div> */}
              </>
            )}

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            {this.props.isCreative && (
              <>
                <ProjectDetailsForm {...this.props} success={true} />
              </>
            )}

            {this.props.isPartnerSelectionBidding && (
              <>
                <QuotationForm {...this.props} quotation={quotation} />
              </>
            )}

            <br />

            <div className="columns">
              <div className="column is-6 is-offset-3">
                <div className="field is-grouped is-grouped-centered">
                  <p className="control is-expanded" onClick={this.scrollToTop}>
                    {this.props.isCreative && (
                      <>
                        <Link
                          to={`/projetos/${this.props.projectId}/detalhamento-passo-2`}
                          className="button bt-bpool grey is-fullwidth  previous"
                        >
                          {I18n.get("Voltar")}
                        </Link>{" "}
                      </>
                    )}

                    {this.props.isPartnerSelectionBidding && (
                      <>
                        <Link
                          to={`/projetos/${this.props.projectId}/detalhamento-passo-1`}
                          className="button bt-bpool grey is-fullwidth  previous"
                        >
                          {I18n.get("Voltar")}
                        </Link>
                      </>
                    )}
                  </p>
                  <p className="control is-expanded" onClick={this.scrollToTop}>
                    {this.props.isCreative && this.props.auth.is_client && (
                      <>
                        <Link
                          to={`/projetos/${this.props.projectId}/detalhamento-passo-4`}
                          className="button bt-bpool black is-fullwidth  next"
                        >
                          {I18n.get("Próximo")}
                        </Link>
                      </>
                    )}
                    {this.props.isPartnerSelectionBidding && (
                      <>
                        <Link
                          to={`/projetos/${this.props.projectId}/detalhamento-passo-3`}
                          className="button bt-bpool black is-fullwidth  next"
                        >
                          {I18n.get("Próximo")}
                        </Link>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ProjectContainer(ProjectDefintionsThankYou);
