import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { Button, Modal } from 'antd';
import ReactPlayer from 'react-player';

import * as S from './styles';

import HeaderDefaultImg from './img/header-img.jpg';
import BgDefaultImg from './img/bg-img.jpg';
import LogoDefaultImg from './img/logo-img.jpg';

export const ModalPreview = ({ open, handleClose, type }) => {
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      handleClose();
    }, 3000);
  };

  return (
    <Modal
      open={open}
      visible={open}
      title={I18n.get('Example of how you will view your profile')}
      onOk={handleOk}
      centered
      width={1000}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          {I18n.get('Back')}
        </Button>,
      ]}
    >
      <S.ContentModalPreview>
        <S.HeaderModalPreview>
          <S.Label style={{ marginLeft: 10 }}>{I18n.get('Header')}</S.Label>
          <S.ImgHeader>
            <img src={HeaderDefaultImg} alt="Img Header Peview" />
          </S.ImgHeader>

          <S.Logo>
            <S.LogoImage>
              <img src={LogoDefaultImg} alt="Logo Peview" />
            </S.LogoImage>
            <S.Label>{I18n.get('Logo')}</S.Label>
          </S.Logo>
        </S.HeaderModalPreview>

        <S.TitleSection>{I18n.get('Name of your agency')}</S.TitleSection>

        {type === 1 ? (
          <S.BgModalPreview>
            <S.Label
              style={{ width: '100%', textAlign: 'center', marginBottom: 10 }}
            >
              {I18n.get('Presentation image')}
            </S.Label>
            <S.ImgPresentation>
              <img src={BgDefaultImg} alt="Img Presentation Peview" />
            </S.ImgPresentation>
          </S.BgModalPreview>
        ) : (
          <S.VideoContent>
            <S.Label style={{ marginBottom: 10 }}>{I18n.get('Video')}</S.Label>
            <ReactPlayer
              // className="responsive-video"
              url="https://www.youtube.com/watch?v=K4TOrB7at0Y"
              playing={true}
              loop={true}
              controls={false}
              muted={true}
              style={{ margin: '0 auto' }}
            />
          </S.VideoContent>
        )}
      </S.ContentModalPreview>
    </Modal>
  );
};
