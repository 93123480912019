import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PROJECT_CURRENCY_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

const api_update_project_currency = (data) => {
    return (dispatch, getState) => {

        Auth.currentSession().then((session) => {
            const token = session && session.idToken.jwtToken;
            var config = { headers: { Authorization: 'Bearer ' + token } };
            return axios.put(`${Config.API_BASE_URL}/client-project/${data.projectId}/project-refresh-currency`, null, config)
                .then(response => {
                    dispatch({
                        type: PROJECT_CURRENCY_UPDATE,
                        payload: {
                            ...response.data,
                            errors: {},
                            task: {
                                result: true
                            }
                        }
                    })
                })
                .catch(error => {
                    dispatch({
                        type: PROJECT_CURRENCY_UPDATE,
                        payload: {
                            errors: {},
                            task: {
                                result: false
                            }
                        }
                    })
                });
        }).catch(error => {
            dispatch({ type: EXPIRED });
        });
    };
}

export default api_update_project_currency;
