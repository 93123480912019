import React from "react";
import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";

import { Row, Col, Input, Select, Button, Form } from "antd";

import { ProjectTypeNames } from "../model";

const { TextArea } = Input;
const { Option } = Select;

class Review extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
    };
  }

  handleSelectUser = (value) => {
    let data = {
      projectId: this.props.projectId,
      newUsername: value,
    };

    setTimeout(() => {
      this.props.updatePartnerResponsible(data);
    }, 10);
  };

  render() {
    let partnerResponsibles =
      this.props.partnerResponsibles || this.props.project.partnerResponsibles;

    var isAdmin = window.location.href.includes("/bpool/projetos/");


    return (
      <div>
        <div className="card uplift ">
          <div className="card-content break-word">
            <h3>
              <strong>{I18n.get("Tipo:")}</strong>
              <span class="flag-green">
                {I18n.get(ProjectTypeNames[this.props.projectType])}
              </span>
            </h3>

            {isAdmin === true && (
              <h3>
                <strong>Tier / Market:</strong>
                <span class="flag-blue">
                  {this.props.tc} / {this.props.tMarket}
                </span>
              </h3>
            )}

            <p>
              <strong>{I18n.get("Nome do Projeto:")}</strong>{" "}
              {this.props.projectName}
            </p>
            <p>
              <strong>{I18n.get("Número do Projeto:")}</strong>{" "}
              {this.props.projectSKU}
            </p>
            <p>
              <strong>{I18n.get("Produto:")}</strong> {this.props.brandName} -{" "}
              {this.props.productName}
            </p>

            {(this.props.auth.is_partner || this.props.auth.is_master) &&
              this.props.partnerId &&
              !this.props.isInvite &&
              !this.props.auth.is_bpool && (
                <div className="boxUserCampoResume">
                  <p>
                    <strong>{I18n.get("Usuário Responsável:")}</strong>
                  </p>
                  <Select
                    defaultValue={this.props.partnerResponsibleName}
                    onChange={(e) => this.handleSelectUser(e)}
                  >
                    {partnerResponsibles &&
                      partnerResponsibles.map((item, index) => {
                        if (item.username) {
                          return (
                            <Option key={item.username}>{item.name}</Option>
                          );
                        }
                      })}
                  </Select>
                </div>
              )}
          </div>
        </div>

        <br />

        <Row gutter={1}>
          <Col sm={12}></Col>
        </Row>
      </div>
    );
  }
}

export default Review;
