import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Tabs, notification } from "antd";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import { useInProgress } from "../../../hooks/useInProgress/useInProgress.hook";
import { useUpload } from "../../../hooks/useUpload/useUpload.hook";
import Content from "../../../components/UI/Content";
import Main from "../../../components/UI/Main";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import { H2Border } from "../../../components/UI/Titles";
import { BoxUpload } from "../../../components/UI/BoxUpload";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import Loading from "../../../components/UI/Loading";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";
import ListFiles from "./components/ListFiles";
import { TabsProject } from "../../../components/UI/TabsProject";
import { FileUpload } from "../../../components/UI/FileUpload";
import { FileList } from "../../../components/UI/FileUpload/FileList";
import { LinksFiles } from "../../../components/UI/LinksFiles";
import { RequestProjectCompletion } from "../components/RequestProjectCompletion";

import * as S from "./styles";

const { TabPane } = Tabs;

export const FilesInProgress = () => {
  let params = useParams();
  const auth = useSelector((state) => state.auth);
  const { getProjectById } = useProjects();
  const { uploadFile: uploadFileS3 } = useUpload();
  const { getFiles, uploadFile, deleteFile } = useInProgress();
  const [projectId, setProjectId] = useState("");
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [files, setFiles] = useState({});
  const [statusProject, setStatusProject] = useState(null);
  const [urlsCompletionProject, setUrlsCompletionProject] = useState([]);
  const [projectCompletionFile, setProjectCompletionFile] = useState(true);

  const completionOrWorkFiles = (files) => {
    let filesCompletion = [];
    let filesWork = [];

    if (files) {
      files.forEach((file) => {
        if (file?.projectCompletionFile) {
          filesCompletion.push(file);
        } else {
          filesWork.push(file);
        }
      });
    }

    return { filesCompletion, filesWork };
  };

  const loadFiles = async () => {
    setLoadingFiles(true);
    if (params.projectId) {
      const response = await getFiles({ projectId: params.projectId });
      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });
      setStatusProject(responseProject?.data?.status);
      setUrlsCompletionProject(
        responseProject?.data?.projectCompletionUrls?.length
          ? responseProject?.data?.projectCompletionUrls
          : []
      );

      if (response?.success) {
        const filesFormatted = completionOrWorkFiles(response?.data);
        setFiles(filesFormatted);
      } else {
        notification.success({
          message: I18n.get("Erro"),
          description: I18n.get("Erro ao carregar os arquivos."),
          duration: 3,
        });
      }

      setLoadingFiles(false);
    }
  };

  useEffect(() => {
    console.log("files", files);
  }, [files]);

  const deleteFiles = async (id) => {
    setLoadingFiles(true);
    const info = {
      projectId: projectId,
      id: id,
    };

    const response = await deleteFile(info);

    if (response?.success) {
      notification.success({
        message: I18n.get("Sucesso!"),
        description: I18n.get("O arquivo foi deletado."),
        duration: 3,
      });
    } else {
      notification.success({
        message: I18n.get("Erro"),
        description: I18n.get("Erro ao deletar o arquivo."),
        duration: 3,
      });
    }
    setLoadingFiles(false);
    await loadFiles();
  };

  const uploadFiles = async (files) => {
    setLoadingFiles(true);

    try {
      let filesToUpload = [];
      if (files?.length > 0) {
        await Promise.all(
          files.map(async (file) => {
            const result = await uploadFileS3({
              file: file,
              idUser: auth?.is_client_or_client_user
                ? `${auth?.clientId}`
                : `${auth?.partnerId}`,
              fieldId: `${
                auth?.is_client_or_client_user ? "cliente" : "parceiro"
              }-${params?.projectId}-files`,
            });
            filesToUpload.push(result);
          })
        );
      }

      if (filesToUpload?.length > 0) {
        await Promise.all(
          filesToUpload.map(async (file) => {
            await uploadFile({
              projectId: projectId,
              name: file?.name,
              url: file?.url,
              type: file?.type,
              size: file?.size,
              uploadedDate: file?.uploadedDate,
              projectCompletionFile,
            });
          })
        );
      }
      notification.success({
        message: I18n.get("Sucesso!"),
        description: I18n.get("Os arquivos foram salvos."),
        duration: 3,
      });
    } catch (error) {
      notification.success({
        message: I18n.get("Erro"),
        description: I18n.get("Erro ao salvar os arquivos."),
        duration: 3,
      });
    } finally {
      setLoadingFiles(false);
    }
    await loadFiles();
  };

  const callbackTab = (key) => {
    setProjectCompletionFile(key === "1" ? true : false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadFiles();
  }, []);

  useEffect(() => {
    setProjectId(params.projectId);
  }, [params]);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Projeto em andamento")}</H2Border>
            </Col>
            <Col sm={12}>
              {loadingFiles ? (
                <div />
              ) : (
                <RequestProjectCompletion reloadPage={loadFiles} />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <TabsProject
                context={
                  statusProject === null
                    ? "inProgress"
                    : statusProject === 7
                    ? "inProgress"
                    : "finalized"
                }
                page="files"
                externalLoading={loadingFiles}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Arquivos")}</S.H4>
                      </S.TitleWithIcon>
                    </S.RowCenter>
                  </Col>
                </Row>
                <Row>
                  <Col sm={24}>
                    <S.Tabs
                      animated={false}
                      defaultActiveKey="1"
                      onChange={callbackTab}
                    >
                      <TabPane
                        tab={I18n.get("Arquivos de finalização")}
                        key="1"
                      >
                        <S.ContentTab>
                          <S.Title>
                            {I18n.get(
                              auth?.is_client_or_client_user
                                ? "No espaço abaixo você pode visualizar todos documentos e links essenciais que representam a conclusão do seu projeto."
                                : "No espaço abaixo você pode enviar todos os documentos e inserir os links essenciais que representam a conclusão do seu projeto."
                            )}
                          </S.Title>
                          <div style={{ marginTop: 15 }}>
                            {auth?.is_client_or_client_user ? (
                              <>
                                <FileList
                                  noDelete
                                  files={files?.filesCompletion}
                                  isLoading={loadingFiles}
                                />
                                {!files?.filesCompletion?.length ? (
                                  <div>
                                    {I18n.get("Nenhum arquivo adicionado")}
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <FileUpload
                                isLoading={loadingFiles}
                                cbFiles={(files) => uploadFiles(files)}
                                initialFiles={files?.filesCompletion}
                                cbDeleteFile={(fileId) => deleteFiles(fileId)}
                              />
                            )}
                          </div>

                          <div style={{ marginTop: 30 }}>
                            <LinksFiles
                              title={false}
                              urlsDefault={urlsCompletionProject}
                              isLoading={loadingFiles}
                              noAdd={
                                auth?.is_client_or_client_user ? true : false
                              }
                              noDelete={
                                auth?.is_client_or_client_user ? true : false
                              }
                              reload={loadFiles}
                            />
                            {auth?.is_client_or_client_user ? (
                              !urlsCompletionProject?.length ? (
                                <div>{I18n.get("Nenhum link adicionado.")}</div>
                              ) : null
                            ) : null}
                          </div>
                        </S.ContentTab>
                      </TabPane>
                      <TabPane tab={I18n.get("Arquivos de trabalho")} key="2">
                        <S.ContentTab>
                          <S.Title>
                            {I18n.get(
                              "O espaço abaixo  é coloborativo, onde Cliente e Parceiro podem trocar arquivos relacionados ao projeto."
                            )}
                          </S.Title>
                          <S.Text>
                            {I18n.get(
                              "Somente a pessoa que fez o upload do arquivo que pode excluir o mesmo."
                            )}
                          </S.Text>
                          <div style={{ marginTop: 15 }}>
                            <FileUpload
                              isLoading={loadingFiles}
                              cbFiles={(files) => uploadFiles(files)}
                              initialFiles={files?.filesWork}
                              cbDeleteFile={(fileId) => deleteFiles(fileId)}
                            />
                          </div>
                        </S.ContentTab>
                      </TabPane>
                    </S.Tabs>
                  </Col>
                </Row>

                <S.RowFooter style={{ border: "none" }}>
                  <S.ButtonsFooter></S.ButtonsFooter>
                  <S.ButtonsFooter>
                    <Link
                      to={`/${
                        auth?.is_partner ? "parceiro" : "cliente"
                      }/projetos/v2/${projectId}/in-progress/review`}
                    >
                      <ButtonBpool
                        text={I18n.get("Back")}
                        theme="secondary"
                        className="btnPrev"
                        onClick={() => true}
                      />
                    </Link>
                    <Link
                      to={`/${
                        auth?.is_partner ? "parceiro" : "cliente"
                      }/projetos/v2/${projectId}/in-progress/briefing`}
                    >
                      <ButtonBpool
                        text={I18n.get("Next")}
                        theme="primary"
                        onClick={() => true}
                      />
                    </Link>
                  </S.ButtonsFooter>
                </S.RowFooter>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
