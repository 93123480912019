import axios from 'axios';
import { Auth } from 'aws-amplify';
import { EXPIRED } from '../auth/actions';

import Config from '../../config';

import { PROJECT_UPDATE } from './actions';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

export const api_update_checkpoint_status = (data) => {

  return (dispatch, getState) => {

    return Auth.currentSession().then((session) => {

      const token = session && session.idToken.jwtToken;

      var config = { headers: { Authorization: 'Bearer ' + token } };

      return axios.put(`${Config.API_BASE_URL}/client-project/${data.projectId}/checkpoints/${data.checkpointId}/status`, data, config)
        .then(response => {

          const payload = {
            ...response.data,
            task: {
              result: true
            }
          };

          dispatch({
            type: PROJECT_UPDATE,
            payload: payload
          })

          return payload;
        })
        .catch(error => {

          const payload = {
            errors: error.response.data,
            task: {
              result: false
            }
          };

          return payload;

        });
    }).catch(error => {
      dispatch({ type: EXPIRED });
    });
  };
}

export default api_update_checkpoint_status;
