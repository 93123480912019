import React, { useState } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, message } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBp } from "../../../../hooks/useBp/useBp.hook";
import { SelectOne } from "../../../../components/UI/SelectOne";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import Loading from "../../../../components/UI/Loading";

import * as S from "./styles";

export const AddListToClient = ({ list, isLoadingList, reloadPage }) => {
  const { putClientAddPartnerWorkedWith } = useBp();
  const bp = useSelector((state) => state?.bp);
  const { partnerId } = useParams();
  const [changeValue, setChangeValue] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [errorInSend, setErrorInSend] = useState("");

  const handleSend = async () => {
    setIsSending(true);

    const dataSend = {
      partnerId,
      clientId: changeValue,
    };

    const response = await putClientAddPartnerWorkedWith({ form: dataSend });
    if (response?.status === 200) {
      setChangeValue(null);
      setErrorInSend("");

      message.success(
        I18n.get("Submission success, don't forget to update statistics.")
      );
      reloadPage();
    } else {
      message.error(I18n.get("An error occurred while submitting."));
      setErrorInSend("An error occurred while submitting.");
    }

    setIsSending(false);
  };

  const onChangeStatus = (handleStatus) => {
    setChangeValue(handleStatus);
  };

  return (
    <>
      <div className="card">
        <div className="card-content">
          <Row gutter={1}>
            <Col sm={24}>
              <h3 className="title is-4">{I18n.get("Add to customer list")}</h3>

              <S.SelectAndSave>
                {isLoadingList ? (
                  <Loading
                    sizeIcon={18}
                    text={I18n.get("Loading...")}
                    sizeText={14}
                    align="left"
                  />
                ) : (
                  <SelectOne
                    options={list}
                    value={changeValue}
                    onChange={(value) => onChangeStatus(value)}
                    placeholder={I18n.get("Add to customer list")}
                    defaultActiveFirstOption={true}
                    error={I18n.get(errorInSend)}
                  />
                )}

                <ButtonBpool
                  text={I18n.get("Save")}
                  theme="primary"
                  full={false}
                  loading={isSending}
                  onClick={handleSend}
                />
              </S.SelectAndSave>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
