import React from "react";

import { I18n } from "aws-amplify";

import * as S from "./styles";

import { Row, Col, Tooltip, Icon } from "antd";

const SelectTmarket = (props) => {
  return (
    <div className="selectProject" style={{ paddingTop: "90px" }}>
      <Row>
        <Col sm={6}>
          <S.ItemButton
            className={props.active == 1 ? "is-selected" : ""}
            disabled={props.disabled}
            onClick={() => props.onSelectMarket(1)}
          >
            <span>{I18n.get("Premium")}</span>

            <span className="Info">
              <Tooltip title={I18n.get("descrição")}>
                <Icon type="question-circle" theme="filled" />
              </Tooltip>
            </span>
          </S.ItemButton>
        </Col>

        <Col sm={6}>
          <S.ItemButton
            className={props.active == 2 ? "is-selected" : ""}
            disabled={props.disabled}
            onClick={() => props.onSelectMarket(2)}
          >
            <span>{I18n.get("Master")}</span>

            <span className="Info">
              <Tooltip title={I18n.get("descrição")}>
                <Icon type="question-circle" theme="filled" />
              </Tooltip>
            </span>
          </S.ItemButton>
        </Col>
      </Row>
    </div>
  );
};

export default SelectTmarket;
