import axios from "axios";
import { Auth } from "aws-amplify";
import Config from "../../../config";
import { statusFmtBpay } from "./utils";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

export const useBpayAdmin = () => {
  const getBpaysAdmin = async (query) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/admin/bpay${query}`
        : `${Config.API_BASE_URL}/v2/admin/bpay`,
      config
    );

    const dataFormatted = data?.data?.bPays?.map((item) => ({
      ...item,
      statusFmt: statusFmtBpay(item?.status),
    }));

    return {
      list: dataFormatted,
      total: data?.data?.totalRecords,
    };
  };

  const adminBpayListSetup = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/bpay/setup`,
      config
    );

    return data;
  };

  const adminBpaymentsListSetup = async () => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/bpay/setup-bpayment`,
      config
    );

    return data;
  };

  const exportRepasse = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/admin/bpay/export-repasse`,
      { bPayIds: infos },
      config
    );

    return data;
  };

  const exportFinancial = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/admin/bpay/export-financial`,
      { bPayIds: infos },
      config
    );

    return data;
  };

  const exportFinancialBPayments = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/admin/bpay/export-financial-by-bpayments`,
      { bPaymentsIds: infos },
      config
    );

    return data;
  };

  const exportRepasseBPayments = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/admin/bpay/export-repasse-by-bpayments`,
      { bPaymentsIds: infos },
      config
    );

    return data;
  };

  const sendEmailsBPayments = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/admin/bpay/send-payment-instructions-to-suppliers`,
      { bPaymentsIds: infos },
      config
    );

    return data;
  };

  const getBpayDataToDrawer = async (bpayId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/bpay/${bpayId}/details`,
      config
    );

    return data;
  };

  const getBpayDataInternalToDrawer = async ({ bpayId, bPaymentId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/bpay/${bpayId}/bpayments/${bPaymentId}/invoice`,
      config
    );

    return data;
  };

  const getBillingDataInternalToDrawer = async ({ bpayId, bPaymentId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/bpay/${bpayId}/bpayments/${bPaymentId}/invoice`,
      config
    );

    return data;
  };

  const putTextInfosSpecifics = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/admin/bpay/update-specific-informations-to-invoice`,
      infos,
      config
    );

    return data;
  };

  const putTextBankAccountWrong = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/admin/bpay/set-bank-account-wrong`,
      infos,
      config
    );

    return data;
  };

  const deleteNFWithDescription = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/admin/bpay/delete-invoice`,
      {
        data: infos,
        ...config,
      }
    );

    return data;
  };

  const getBpayById = async ({ bpayId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      `${Config.API_BASE_URL}/v2/admin/bpay/${bpayId}`,
      config
    );

    return data;
  };

  const getBpayByIdTable = async ({ bpayId, query }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/admin/bpay/${bpayId}/bpayments${query}`
        : `${Config.API_BASE_URL}/v2/admin/bpay/${bpayId}/bpayments`,
      config
    );

    return data;
  };

  const getBPaymentsTable = async ({ bpayId, query }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.get(
      query
        ? `${Config.API_BASE_URL}/v2/admin/bpay/bpayments${query}&size=100`
        : `${Config.API_BASE_URL}/v2/admin/bpay/bpayments?size=100`,
      config
    );

    return data;
  };

  const sendWarningUploadInvoice = async (bpayId) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/admin/bpay/billing/send-warning-upload-invoice-to-all-supplier`,
      { bpayId },
      config
    );

    return data;
  };

  const sendWarningUploadInvoiceInternal = async ({ bPayId, bPaymentId }) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.post(
      `${Config.API_BASE_URL}/v2/admin/bpay/billing/send-warning-upload-invoice-to-supplier`,
      { bPayId, bPaymentId },
      config
    );

    return data;
  };

  const updateNFSe = async (infos) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.put(
      `${Config.API_BASE_URL}/v2/admin/bpay/nfse`,
      infos,
      config
    );

    return data;
  };

  const deleteBPayAdmin = async (id) => {
    const session = await Auth.currentSession();
    const token = session && session.idToken.jwtToken;

    const config = { headers: { Authorization: "Bearer " + token } };

    const { data } = await axios.delete(
      `${Config.API_BASE_URL}/v2/admin/bpay`,
      {
        data: { id },
        ...config,
      }
    );

    return data;
  };

  return {
    getBpaysAdmin,
    adminBpayListSetup,
    adminBpaymentsListSetup,
    exportRepasse,
    exportFinancial,
    exportFinancialBPayments,
    exportRepasseBPayments,
    sendEmailsBPayments,
    getBpayDataToDrawer,
    getBpayDataInternalToDrawer,
    getBillingDataInternalToDrawer,
    putTextInfosSpecifics,
    putTextBankAccountWrong,
    deleteNFWithDescription,
    getBpayById,
    getBpayByIdTable,
    getBPaymentsTable,
    sendWarningUploadInvoice,
    sendWarningUploadInvoiceInternal,
    updateNFSe,
    deleteBPayAdmin,
  };
};
