export const useSessionStorage = () => {
  const set = async ({ key, data }) => {
    sessionStorage.setItem(key, JSON.stringify(data));
    return;
  };

  const get = async ({ key }) => {
    const item = sessionStorage.getItem(key);

    const itemParsed = JSON.parse(item);
    return itemParsed;
  };

  const remove = async ({ key }) => {
    sessionStorage.removeItem(key);
    return;
  };

  return {
    set,
    get,
    remove,
  };
};
